import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  CustodyInfos,
  IPosition,
} from '@shared/components/stock-chart/interface/stock-chart.interface';
import { TIGER_DOUBLE_STOP_ORDER_IDS } from '../enum';
import {
  ensurePositiveNumber,
  isNullOrUndefined,
} from 'src/app/utils/utils.functions';
import { ISearchStock } from '@core/interface';
import { formatNumber } from '@angular/common';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';

@Injectable({
  providedIn: 'root',
})
export class CustodyStopOrderService {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private dayTradeService: DaytradeService
  ) {
    //do nothing;
  }

  getCustodyInfos(custody: IPosition): CustodyInfos {
    const isDaytrade = this.dayTradeService.useDayTradeMode;
    /**
     * caso n envie se é daytrade ou swing
     * é feito uma logica para trazer quantidades
     * pois a qtty_final pode ser zero, mesmo que
     * exista qtty_swing e qtty_daytrade
     */
    if (isNullOrUndefined(isDaytrade)) {
      const custodyQtty =
        parseInt(custody.qtty_final_daytrade) !== 0
          ? parseInt(custody.qtty_final_daytrade)
          : parseInt(custody.qtty_final_swing);
      const abrQtty =
        parseInt(custody.net_day_daytrade) !== 0
          ? parseInt(custody.net_day_daytrade)
          : parseInt(custody.net_day_swing);
      return {
        custodyQtty,
        abrQtty,
        pnlValue: parseFloat(custody.pnl?.toString() || '0'),
        vlPriceAvg: parseFloat(custody.vl_price_avg),
        normalVlPriceAvg: parseFloat(custody.vl_price_avg),
        pnlDay: parseFloat(custody.pnl_day?.toString() || '0'),
        pnlOpen: parseFloat(custody.pnl_open?.toString() || '0'),
        plValue: parseFloat(custody.pl?.toString() || '0'),
        coveragePrice: parseFloat(custody.coverage_price?.toString() || '0'),
        coverageDiff: parseFloat(custody.coverage_diff?.toString() || '0'),
        coveragePercent: parseFloat(
          custody.coverage_percent?.toString() || '0'
        ),
        pnlDiff: parseFloat(custody.pnl_open_diff?.toString() || '0'),
        pnlPerc: parseFloat(custody.pnl_open_perc?.toString() || '0'),
      };
    }
    if (isDaytrade) {
      return {
        custodyQtty: parseInt(custody.qtty_final_daytrade),
        abrQtty: parseInt(custody.net_day_daytrade),
        pnlValue: parseFloat(custody.pnl_daytrade),
        vlPriceAvg: parseFloat(custody.vl_price_avg_daytrade),
        normalVlPriceAvg: parseFloat(custody.vl_price_avg_daytrade),
        pnlDay: parseFloat(custody.pnl_day_daytrade),
        pnlOpen: parseFloat(custody.pnl_open_daytrade),
        plValue: parseFloat(custody.pl_daytrade),
        coveragePrice: parseFloat(custody.coverage_price_day_trade || '0'),
        coverageDiff: parseFloat(custody.coverage_diff_day_trade || '0'),
        coveragePercent: parseFloat(custody.coverage_perc_day_trade || '0'),
        pnlDiff: parseFloat(custody.pnl_open_day_trade_diff?.toString() || '0'),
        pnlPerc: parseFloat(custody.pnl_open_day_trade_perc?.toString() || '0'),
      };
    }

    return {
      custodyQtty:
        custody.qtty_final_swing != undefined ? +custody.qtty_final_swing : 0,
      abrQtty: +custody.net_day_swing,
      pnlValue: +custody.pnl_swing,
      vlPriceAvg: +custody.vl_price_avg_swing,
      normalVlPriceAvg: parseFloat(custody.vl_price_avg_swing),
      pnlDay: +custody.pnl_day_swing,
      pnlOpen: +custody.pnl_open_swing,
      plValue: +custody.pl_swing,
      coveragePrice: parseFloat(custody.coverage_price_swing || '0'),
      coverageDiff: parseFloat(custody.coverage_diff_swing || '0'),
      coveragePercent: parseFloat(custody.coverage_perc_swing || '0'),
      pnlDiff: parseFloat(custody.pnl_open_swing_diff?.toString() || '0'),
      pnlPerc: parseFloat(custody.pnl_open_swing_perc?.toString() || '0'),
    };
  }

  getInfoCustodyHint(
    boxId: string,
    stock: ISearchStock,
    custodyQuantity: number,
    vlContractMultiplier: number,
    stopPrice: number,
    orderPrice: number,
    vlPriceAvg: number | undefined,
    yAxisLabelFormatter: any
  ): {
    isGain: boolean;
    qtty: string;
    price: string;
    stop: string;
    estimate: string | undefined;
    distance: string | undefined;
  } {
    const isGain =
      boxId == TIGER_DOUBLE_STOP_ORDER_IDS.GAIN || boxId.endsWith('_gain');
    const stop = yAxisLabelFormatter(stopPrice);
    const price = yAxisLabelFormatter(orderPrice);
    const diff = vlPriceAvg ? stopPrice - vlPriceAvg : 0;
    const negative = diff < 0 ? '-' : '';
    const difference = vlPriceAvg ? Math.abs(diff) : undefined;
    const custodyQt = ensurePositiveNumber(custodyQuantity);
    const qtty = formatNumber(custodyQt, this.locale, `1.0-0`);
    const estimate = difference
      ? yAxisLabelFormatter(
          difference * parseFloat(custodyQt.toString()) * vlContractMultiplier
        )
      : undefined;
    const distance = vlPriceAvg
      ? stock?.cd_security_type === 'FUT'
        ? `${yAxisLabelFormatter(difference)} pontos`
        : `${negative}R$ ${yAxisLabelFormatter(difference)}`
      : undefined;
    return { isGain, qtty, price, stop, estimate, distance };
  }
}
