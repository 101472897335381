import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';

export const TRADE_IDEA_COMPONENT: any = {
  name: 'TradeIdeas',
  id: COMPONENTS_NAMES_ENUM.TRADE_IDEA,
  icon: 'lightbulb',
  cod: 'TRADE_IDEA',
  data: null,
  close: true,
  fullscreen: true,
  link: true,
  unitOfMeasurement: 'px',
  height: 468,
  width: 832,
  minWidth: '775px',
  left: 'calc(15% + 5px)',
  top: '0px',
  label: 'TradeIdeas',
  noPublished: false,
  hasBorder: false,
  divider: false,
  iconSvg: 'TRADE_IDEA',
};
