import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ResetForm } from './base';
import { OnboardService } from '@shared/services/api/trademap/onboard';
import { ActivatedRoute, Router } from '@angular/router';
import { validateCpf } from '@shared/validators/functions';
import { RECOVERY_STEPS } from '@shared/services/api/trademap/onboard/data/recovery/recovery.configs';
import { deepClone } from '@shared/rocket-components/utils';
import { RecaptchaComponent } from '../recaptcha/recaptcha.component';
import { IInternationalNumber } from '@shared/rocket-components/components';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent extends ResetForm implements OnInit {
  isLoading!: boolean;
  @ViewChild('recaptcha') appRecaptcha!: RecaptchaComponent;
  constructor(
    fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private onboardService: OnboardService,
    private _cdr: ChangeDetectorRef
  ) {
    super(fb);
  }
  get isSmsRecovery() {
    return this.recoveryInfoType?.value === 'phone_mobile';
  }

  ngOnInit() {
    this.onboardService.clearRecoveryData();
    this.onVlDocumentChange.subscribe(() => {
      const isValidCPF = validateCpf(this.vlDocument.value);
      this.documentMask = isValidCPF ? '000.000.000-00' : '';
      this.documentMaxLength = isValidCPF ? 15 : 12;
    });
  }

  changeRecoveryInfoType(code: string) {
    this.recoveryInfo?.setValue('');
    this.recoveryInfoType?.setValue(code);
    this.updateRecoveryInfoValidator();
  }

  // PHONE
  public onUpdateCountryCode(value: IInternationalNumber): void {
    this.phoneCountrySelected = value;
    this.onboardService.updatePhoneInfos(value);
  }

  verification() {
    this.isLoading = true;
    this.onboardService
      .onRequestAccountRecoverWithType(this._recoveryData())
      .subscribe((res) => {
        if (res.isError) {
          this.isLoading = false;
          this.erroData.hasError = true;
          this.erroData.errorMessage = res.message;
          this._cdr.detectChanges();
          return;
        }
        this.onboardService.setRecoveryResendPincodeTimeout(res.seconds);
        this.onboardService.setRecoveryStatus(RECOVERY_STEPS.INITIAL);
        this.router.navigate(['../validate'], { relativeTo: this.route });
      });
  }

  private _recoveryData() {
    const params = deepClone(this.formValues);
    if (validateCpf(params.vlDocument))
      params.vlDocument = params.vlDocument.replace(/\D/g, '');
    return params;
  }

  onResolved(token: string) {
    this.recaptcha?.setValue(token);
    this.verification();
  }

  onError() {
    this.erroData.hasError = true;
    this.erroData.errorMessage =
      'Ocorreu um erro ao validar o reCAPTCHA, tente novamente.';
    this.recaptcha?.setValue(null);
  }

  makeRecaptchaExecute() {
    this.appRecaptcha.execute();
  }
}
