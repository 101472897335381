import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum/workspace.enum';
import { STOCK_TABLE_VIEW } from '@shared/components/stock-table-views';
import { IMoversPeriodMetadata } from '@shared/components/stock-table/interfaces/movers-period.interface';

export interface IMetadata<T = any> {
  componentResize?: {
    width: number;
    height: number;
  };
  layout: IWorkSpaceDocLayout;
  monitorConfig: IWorkSpaceDocMonitorConfig;
  headerOptions: IWorkSpaceHeader;
  footerOptions: IWorkSpaceFooter;
  component?: T | any;
  listStock?: IStockListPreferences;
  configuration?: T | any;
}

export interface IStockListPreferences {
  idList: number;
  isNotListPersonal: boolean | undefined;
  type: STOCK_TABLE_VIEW;
  moversIndex: string;
  auctionIndex: string;
  moversPeriodV2: IMoversPeriodMetadata;
  presetListName: string;
}

export interface IStock {
  cd_stock: string;
  id_exchange: number;
  exchange?: string;
}

export interface IWorkSpaceComponet<T = IMetadata> {
  workspace_id: string;
  id: string;
  name: string;
  type: COMPONENTS_NAMES_ENUM;
  visible: boolean;
  metadata: T;
  assets?: any[] | IStock[];
  link: boolean;
  bg?: string | undefined;
}

export interface IWorkSpaceComponetList {
  name: string;
  id: COMPONENTS_NAMES_ENUM | string;
  workspace_id: string;
  type: COMPONENTS_NAMES_ENUM | string;
  visible: boolean;
  data: any | null;
  close: boolean;
  fullscreen: boolean;
  link: boolean;
  hasFooter: boolean;
  noPublished?: boolean;
  minHeight: number | string;
  minWidth: number | string;
  width?: number | string;
  height?: number | string;
  divider?: any;
  isModal?: boolean | undefined;
  noPublishedText?: string | undefined;
}

export type WorkspaceType = 'WEB' | 'PLATAFORMA';

export enum WORKSPACE_WORKER_ACTION {
  SET_WORKSPACES = 'SET_WORKSPACES',
  CREATED_OR_UPDATE_WORKSPACE = 'CREATED_OR_UPDATE_WORKSPACE',
  CHANGE_ACTIVE_WORKSPACE = 'CHANGE_ACTIVE_WORKSPACE',
  REMOVE_WORKSPACE = 'REMOVE_WORKSPACE',
  HIDE_ACTIVE_WORKSPACE = 'HIDE_ACTIVE_WORKSPACE',
  CREATE_COMPONENT = 'CREATE_COMPONENT',
  UPDATE_COMPONENT = 'UPDATE_COMPONENT',
  REMOVE_COMPONENT = 'REMOVE_COMPONENT',
}

export interface IWorkerWorkspaceAction {
  action: WORKSPACE_WORKER_ACTION;
  workspacesData: any;
}

export interface IWorkerWorkspaceActionReturn {
  workspaces?: IWorkSpace[];
  visibleWorkspaces?: IWorkSpace[];
  activeWorkspace?: IWorkSpace;
  componentAdded?: IWorkSpaceComponet;
  componentRemovedIndex?: number;
  isComponentUpdate?: boolean;
  preventUpdate?: boolean;
  preventUpdateActive?: boolean;
}

export interface IWorkSpace<T = any> {
  id: string;
  name: string;
  user_id: string;
  active: boolean;
  default: boolean;
  components: IWorkSpaceComponet<T>[];
  editMode?: boolean;
  visible?: boolean;
  data_de_atualizacao: string;
  data_de_criacao: string;
  dh_last_update?: number;
  dh_insert?: number;
  type?: WorkspaceType;
}

export interface IWorkSpaceDocLayout {
  originalTransform?: { x: number; y: number };
  transform?: { x: number; y: number };
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  originalWidth: any;
  width: any;
  minWidth?: any;
  minHeight?: any;
  originalHeight: any;
  height: any;
  index?: string;
  showResizeLines?: boolean;
  isMaximized?: boolean;
}

export interface IWorkSpaceHeader {
  component?: {
    name?: string;
    data?: any;
    dataKey?: string;
  };
  link?: boolean | null;
  close?: boolean;
  fullscreen?: boolean;
  listOptions?: string[];
}
export interface IWorkSpaceFooter {
  hasFooter?: boolean;
}

export interface IWorkSpaceDocMonitorConfig {
  innerHeight: number;
  innerWidth: number;
}
