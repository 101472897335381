import { Inject, Injectable } from '@angular/core';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import { Subscription, auditTime } from 'rxjs';
import { TTextStyle } from 'scichart';

@Injectable({
  providedIn: 'root',
})
export class RocketDefaultAxisService {
  private _theme$!: Subscription;
  constructor(
    private themeService: ThemePreferencesService,
    @Inject('labelStyle') private labelStyle: TTextStyle
  ) {}

  subscribeTheme() {
    this._theme$ = this.themeService
      .themeActiveObservable()
      .pipe(auditTime(100))
      .subscribe(() => {
        this.setColor();
      });
  }

  setColor() {
    this.labelStyle.color = this.themeService.isDarkTheme()
      ? CHART_COLORS.NEUTRAL_MEDIUM
      : CHART_COLORS.NEUTRAL_STRONGER;
  }

  unsubscribeTheme() {
    this._theme$ && this._theme$.unsubscribe();
  }
}
