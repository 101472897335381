import { Injectable } from '@angular/core';
import { CHEETAH_CHANNEL } from '@shared/cheetah/cheetah.const';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { RxEvent, StreamReadResponse } from './rx-event';
import { MarketDispatcher } from '@shared/cheetah/dispatcher/market.dispatcher';

@Injectable({
  providedIn: 'root',
})
export class MoversChannel {
  private static instance: MoversChannel;

  constructor(
    private _rxEvent: RxEvent,
    private cheetahService: MarketDispatcher
  ) {
    return (MoversChannel.instance = MoversChannel.instance || this);
  }

  readEvents(): StreamReadResponse {
    return this._rxEvent.read(CHEETAH_CHANNEL.MOVERS);
  }

  subscribe(params: SubscribeParam) {
    const { itemsArray } = params;
    this.cheetahService.subscribe({
      channel_cheetah: CHEETAH_CHANNEL.MOVERS,
      itemsArray,
    });
  }

  unsubscribe(params: SubscribeParam) {
    if (!params) return;
    this.cheetahService.unsubscribe({
      channel_cheetah: CHEETAH_CHANNEL.MOVERS,
      itemsArray: params.itemsArray,
    });
  }
}
