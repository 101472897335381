<div>
  <div class="w-100">
    <!-- <div class="d-flex align-items-center justify-content-between mb-1">
      <div class="period-selector">
        <form #formElement [formGroup]="form">
          <fla-select
            *ngIf="years.length"
            #select
            formControlName="period"
            label=""
            bindLabel="label"
            bindValue="id"
            [placeholder]="''"
            [isSmall]="true"
            [searchable]="false"
            [list]="years"
            css="cursor-pointer w-100 period-selector"
            (flaChange)="changePeriod($event)"
          ></fla-select>
        </form>
      </div>
    </div> -->
    <div class="align-items-center d-flex flex-wrap justify-content-between">
      <ng-container *ngIf="topFive.length">
        <div
          class="bg-neutral-strong p-1 round text-center card-flow mb-1"
          *ngFor="let card of topFive"
        >
          <div class="fw-bolder fs-7 text-truncate" [title]="card.name">
            {{ card.name }}
          </div>
          <div
            class="fw-bolder fs-6"
            [ngClass]="{
              'text-feedback-positive': card.sum_vl_offer >= 0,
              'text-feedback-negative': card.sum_vl_offer < 0
            }"
          >
            {{ card.vl_formatted }}
          </div>
        </div>
      </ng-container>
      <div class="period-selector">
        <form #formElement [formGroup]="form">
          <fla-select
            *ngIf="years.length"
            #select
            formControlName="period"
            label=""
            bindLabel="label"
            bindValue="id"
            [placeholder]="''"
            [isSmall]="true"
            [searchable]="false"
            [list]="years"
            css="cursor-pointer w-100 period-selector"
            (flaChange)="changePeriod($event)"
          ></fla-select>
        </form>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-between mb-2">
    <div class="fs-7">Top 5 setores com maior volume de IPO no ano</div>
    <div class="fs-7">{{ totalQttyIPO }} IPOs em {{ yearSelected }}</div>
  </div>
</div>
