<div
  *ngIf="display"
  class="round bg-neutral-strong position-absolute context-container"
  [ngStyle]="{ top: position.top, left: position.left }"
>
  <div class="list-group list-group-flush">
    <div class="header-context-menu">
      <span>Lista de Ativos</span>
    </div>

    <div class="d-flex flex-column">
      <button
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="actionHandler(CONFIG_ACTIONS.ADD)"
        [disabled]="isMoversList"
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="add"
        ></fla-icon>
        Adicionar ativos
      </button>
      <button
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="actionHandler(CONFIG_ACTIONS.REMOVE)"
        [disabled]="!stock || isMoversList"
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="remove"
        ></fla-icon>
        Remover Ativo
      </button>
    </div>
    <button
      type="button"
      class="list-group-item list-group-item-action context-item-button"
      (click)="actionHandler(CONFIG_ACTIONS.EDIT)"
      [disabled]="isMoversList"
    >
      <fla-icon
        size="icon-size-tiny"
        css="position-absolute left-0 text-neutral-medium fs-4"
        name="edit"
        disabled
      ></fla-icon>
      {{ removeStockIsEnable ? 'Ocultar Edição' : 'Editar Lista' }}
    </button>
    <hr class="separator-context-menu" />
    <div class="d-flex flex-column">
      <button
        type="button"
        class="list-group-item list-group-item-action border-none fs-6 context-item-button"
        disabled
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="refresh"
        ></fla-icon>
        Restaurar
      </button>
      <button
        type="button"
        class="list-group-item list-group-item-action border-none fs-6 context-item-button"
        (click)="actionHandler(CONFIG_ACTIONS.HELP)"
        disabled
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="help_outline"
        ></fla-icon>
        Ajuda
      </button>
    </div>
  </div>
</div>
