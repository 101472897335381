<fla-card
  (click)="selectNews()"
  class="d-flex card-border"
  *ngIf="!news?.is_calendar"
>
  <fla-card-custom #card css="w-100 m-2 p-2 py-0 cursor-pointer">
    <div class="card-body">
      <news-card-header [news]="news"></news-card-header>

      <p
        class="fw-bold fs-5 mb-2 limit-text"
        [innerHTML]="
          news?.type === 'corporate_event' ? news?.texto : news?.headline
        "
      ></p>

      <news-card-footer
        [news]="news"
        [selectedTag]="selectedTag"
        (onTagSelected)="bubbleTagSelectedEvent($event)"
      ></news-card-footer>
    </div>
  </fla-card-custom>
</fla-card>

<fla-card class="d-flex card-border" *ngIf="news?.is_calendar">
  <fla-card-custom #card css="w-100 m-2 p-2 py-0">
    <div class="card-body default-cursor">
      <div class="d-flex">
        <div class="mr-2">
          <img class="image-country" [src]="news?.image_url" />
        </div>
        <div>
          <p
            [tooltip]="news.indicatorDescription"
            class="fw-bold fs-5 mb-2 limit-text"
          >
            {{ news?.headline }}
          </p>
          <p class="fw-bold fs-5 mb-2 limit-text fs-6 subtitle-height">
            <ng-container *ngIf="news?.previusLabel">
              {{ news.previusLabel }}
            </ng-container>
            <ng-container *ngIf="news?.currentLabelCompleted">
              {{ news.currentLabelCompleted }}
            </ng-container>
            <span *ngIf="news?.period"> ({{ news.period }}) </span>
          </p>
        </div>
      </div>
      <div class="fs-6">
        {{ news?.dateTransformed | date : "dd 'de' MMMM" }} |
        {{ news?.dateTransformed | date : 'HH:mm' }}
        <span *ngIf="news?.institution">| {{ news?.institution }}</span>
      </div>
    </div>
  </fla-card-custom>
</fla-card>
