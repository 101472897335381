import { FastLineRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastLineRenderableSeries';
import { XyDataSeries } from 'scichart/Charting/Model/XyDataSeries';
import {
  BaseIndicator,
  TMAPoints,
  TTigerChartIndicatorParameter,
  TTigerChartIndicatorRenderSeriesConfig,
  TTigerChartIndicatorCreateOptions,
  TigerChartIndicatorADXOptions,
} from '../indicators.types';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import {
  TIGER_INDICATORS_ENUM,
  TIGER_INDICATOR_PARAMETER_TYPE,
  TIGER_INDICATOR_SOURCES,
} from '../../enum/tiger-chart.enum';
import {
  TData,
  TTooltipIndicatorValue,
  TUpdateData,
} from '../../types/tiger-chart.types';
import { XyMovingAverageFilter } from 'scichart/Charting/Model/Filters/XyMovingAverageFilter';
import { CHART_COLORS } from '../../colors';
import { Subject } from 'rxjs';
import { TalibService } from '../../services/talib.service';
import { TIGER_INDICATOR_PRECISIONS } from '../indicators.constants';
import { setDefaultConfiguration } from '../indicators.functions';
import { TigerChartAverageDirectionalMovementIndex } from '../average-directional-x';

export class TigerChartIndicatorADXBase implements BaseIndicator {
  protected maLineDataSeries!: XyDataSeries;
  protected movingAverageDataSeries!: XyMovingAverageFilter;
  protected renderableSeries!: BaseRenderableSeries;
  protected options!: TigerChartIndicatorADXOptions;
  protected data: TData;
  protected timePeriod: number = 14;
  protected smoothing: number = 14;
  protected offset: number = 0;
  protected precision: number = 4;
  protected points: TMAPoints;
  protected source: TIGER_INDICATOR_SOURCES = TIGER_INDICATOR_SOURCES.CLOSE;
  protected lineColor = CHART_COLORS.FEEDBACK_WARNING;
  protected lineThickness = 1;
  protected adxData: any = [];

  private baseChart!: TWebAssemblyChart;
  lineNumber!: string;
  updateOffset: boolean = false;
  service: TalibService;
  type: TIGER_INDICATORS_ENUM = TIGER_INDICATORS_ENUM.AVERAGE_DIRECTIONAL_X;
  typesWithoutOffset = [TIGER_INDICATORS_ENUM.AVERAGE_DIRECTIONAL_X];
  typesWithoutSource = [TIGER_INDICATORS_ENUM.KAUFMAN_ADAPTIVE_MOVING_AVERAGE];
  //Os calculos da talib utilizam valores de lookback,
  //que ele "ignora" os valores inicias.
  //A forma de retorno no talib, exclui os pontos iniciais,
  //portanto é feito um offset, para adicionar ao inicio do array
  //os valores repetidos do 1 ponto do dia
  //logo é necessário retirar o primeiro ponto do output,
  //e ir até o final do id_point+1
  typesSlicesOne = [TIGER_INDICATORS_ENUM.MOVING_AVERAGE];
  //nos casos de Double e Triple, ele utiliza o EMA do TALIB,
  //porem ele faz uma multiplicacao (2, 3) para o ponto de inicio do array
  typesSlicesZero = [
    TIGER_INDICATORS_ENUM.MOMENTUM,
    TIGER_INDICATORS_ENUM.AVERAGE_DIRECTIONAL_X,
  ];
  typesSlicesTwo = [TIGER_INDICATORS_ENUM.EXPONENTIAL_MOVING_AVERAGE];

  yAxisId!: string;
  settings: TTigerChartIndicatorParameter[] = [];
  styles: TTigerChartIndicatorParameter[] = [];
  renderSeriesConfig: TTigerChartIndicatorRenderSeriesConfig[] = [];
  onChange = new Subject<null>();
  mainLineId = '';
  isNewOnChart = false;

  get isVisible(): boolean {
    return this.renderableSeries.isVisible;
  }

  set isVisible(visible: boolean) {
    this.renderableSeries.isVisible = visible;
  }

  get propertiesText(): string {
    // let offsetText = '',
    //   sourceText = '';
    // if (this.typesWithoutSource.indexOf(this.type) === -1) {
    //   sourceText = `, ${TIGER_INDICATOR_SOURCE_OBJECT[this.source].label}`;
    // }
    // if (this.typesWithoutOffset.indexOf(this.type) === -1) {
    //   offsetText = `, ${this.offset}`;
    // }
    return `(${this.smoothing}, ${this.timePeriod})`;
  }

  getValuesByIndex(index?: number): TTooltipIndicatorValue[] {
    const values = this.maLineDataSeries.getNativeYValues();
    const value = values.get(index || values.size() - 1);
    const precision = this.precision;
    return [{ value, precision, color: this.renderSeriesConfig[0].color }];
  }

  constructor(
    options: TigerChartIndicatorADXOptions,
    type: TIGER_INDICATORS_ENUM
  ) {
    this.data = options.data;
    this.service = options.service;
    this.type = type;
    this.mainLineId = `${this.type}-main-line`;
    this.precision = options.tickSize;
    this.options = options;
    if (options.timePeriod) this.timePeriod = options.timePeriod;
    if (options.smoothing) this.smoothing = options.smoothing;
    if (options.lineColor) this.lineColor = options.lineColor;
    if (options.precision) this.precision = options.precision;
    if (options.lineThickness) this.lineThickness = options.lineThickness;
    if (options.source) this.source = options.source;
    if (options.offset) {
      this.offset = options.offset;
      this.updateOffset = true;
    }

    this.points = {
      output: [],
    };
    this.settings = [
      {
        label: 'ADX Suavizado',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property:
          'smoothing' as keyof TigerChartAverageDirectionalMovementIndex,
      },
      {
        label: 'Comprimento DI',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property:
          'timePeriod' as keyof TigerChartAverageDirectionalMovementIndex,
      },
    ];
    // if (this.typesWithoutSource.indexOf(this.type) === -1) {
    //   this.settings.push({
    //     label: 'Fonte',
    //     type: TIGER_INDICATOR_PARAMETER_TYPE.SELECTION,
    //     values: TIGER_INDICATOR_SOURCE_LIST,
    //     property: 'source' as keyof TigerChartAverageDirectionalMovementIndex,
    //   });
    // }
    if (this.typesWithoutOffset.indexOf(this.type) === -1) {
      this.settings.push({
        label: 'Deslocamento',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property: 'offset' as keyof TigerChartAverageDirectionalMovementIndex,
      });
    }
    this.renderSeriesConfig = [
      {
        label: 'ADX',
        id: this.mainLineId,
        color: this.lineColor,
        propertyColor: 'lineColor',
        thickness: this.lineThickness,
        propertyThickness: 'lineThickness',
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
      },
    ];

    this.styles = [
      {
        type: TIGER_INDICATOR_PARAMETER_TYPE.SELECTION,
        property: 'precision',
        label: 'Precisão',
        values: TIGER_INDICATOR_PRECISIONS,
      },
    ];
    this.lineNumber = options.lineNumber;
  }

  create(options: TTigerChartIndicatorCreateOptions): BaseRenderableSeries[] {
    this.baseChart = options.base;
    const yValues = this.getOutput(this.data.id_point.length);
    const xValues = this.data.id_point;
    this.maLineDataSeries = new XyDataSeries(this.baseChart.wasmContext, {
      yValues,
      xValues,
    });
    this.maLineDataSeries.isSorted = true;
    /*this.movingAverageDataSeries = new XyMovingAverageFilter(
      this.maLineDataSeries,
      {
        length: this.timePeriod,
      }
    );*/
    if (this.updateOffset) {
      this.updateSettings();
    }
    return this.renderSeriesConfig.map((config) => {
      this.renderableSeries = new FastLineRenderableSeries(
        this.baseChart.wasmContext,
        {
          strokeThickness: config.thickness,
          stroke: config.color,
          dataSeries: this.maLineDataSeries,
          yAxisId: this.yAxisId,
          id: `${config.id}_${this.lineNumber}`,
          xAxisId: options.xAxisId,
        }
      );

      return this.renderableSeries;
    });
  }

  updatePoints(): void {
    //do nothing.
  }

  append(xValue: number, data: TUpdateData, fullData: TData) {
    this.maLineDataSeries.append(xValue, data[this.source]);
    this.data = fullData;
  }

  insertRange(xValues: number[], fullData: TData): void {
    this.data = fullData;
    this.updatePoints();
    this.maLineDataSeries.clear();
    this.maLineDataSeries.appendRange(
      this.data.id_point,
      this.getOutput(this.data.id_point.length)
    );
  }

  changeVisibility(): void {
    this.renderableSeries.isVisible = !this.renderableSeries.isVisible;
  }

  update(index: number, data: TUpdateData, fullData: TData) {
    const count = this.maLineDataSeries.count();
    if (index > count) {
      return;
    }
    //this.maLineDataSeries.update(index, data[this.source]);
    this.data = fullData;
    this.updatePoints();
    const yValues = this.getOutput(this.data.id_point.length);
    this.maLineDataSeries.update(index, yValues[index]);
  }

  updateSettings(): void {
    this.updateOffset = false;
    const yValues = this.getOutput(this.data.id_point.length);

    const s = this.baseChart.sciChartSurface.suspendUpdates(); // This locks the surface and prevents further drawing
    const maCount = this.maLineDataSeries.count();

    if (maCount > yValues.length) {
      this.maLineDataSeries.removeRange(
        yValues.length - 1,
        maCount - yValues.length
      );
    }

    try {
      if (this.offset < 0) {
        const xOffsetValues: number[] = [];
        const yOffsetValues = yValues.slice(0, this.offset * -1);
        const xValues = this.maLineDataSeries.getNativeXValues();
        const diff = xValues.get(1) - xValues.get(0);
        for (let index = this.offset * -1; index > 0; index--) {
          xOffsetValues.push(xValues.get(0) - diff * index);
        }
        this.maLineDataSeries.appendRange(xOffsetValues, yOffsetValues);
      }
      yValues.forEach((point, index) => {
        if (this.offset) {
          if (this.offset < 0) {
            // if (index > yValues.length - this.offset * -1) {
            //   this.maLineDataSeries.update(index - 1 + this.offset * -1, NaN);
            //   return;
            // }
            // console.log(index + this.offset * -1);

            // this.maLineDataSeries.update(index - 1 + this.offset * -1, point);
            return;
          }
          const offset = +this.offset;
          if (index < offset) {
            this.maLineDataSeries.update(index, NaN);
          }
          if (index + offset >= yValues.length) {
            this.maLineDataSeries.append(index + offset, point);
            return;
          }
          this.maLineDataSeries.update(index + offset, point);
          return;
        }
        this.maLineDataSeries.update(index, point);
      });
    } finally {
      s.resume();
    }
    this.onChange.next(null);
  }
  updateStyles(
    baseChart: TWebAssemblyChart,
    config: TTigerChartIndicatorRenderSeriesConfig
  ): void {
    this.renderableSeries.stroke = config.color;
    this.renderableSeries.strokeThickness = config.thickness;
    this.renderableSeries.isVisible = config.active;
    const renderSeries = baseChart.sciChartSurface.renderableSeries.getById(
      `${config.id}_${this.lineNumber}`
    );
    renderSeries.stroke = config.color;
    renderSeries.strokeThickness = config.thickness;
    renderSeries.isVisible = config.active;
    this.renderSeriesConfig[0].color = config.color;
    this.renderSeriesConfig[0].thickness = config.thickness;
    this.renderSeriesConfig[0].active = config.active;
    this.onChange.next(null);
  }

  delete() {
    this.maLineDataSeries.clear();
    this.baseChart.sciChartSurface.renderableSeries.remove(
      this.renderableSeries
    );
  }

  setValue(property: keyof this, value: any) {
    const prop = this[property];
    if (typeof this[property] == 'number') {
      this[property] = +value as unknown as typeof prop;
    } else {
      this[property] = value;
    }
    this.updateSettings();
  }

  private sliceIndex(end: number) {
    let sliceStart = this.timePeriod,
      sliceEnd = end + this.timePeriod;
    if (this.typesSlicesOne.indexOf(this.type) != -1) {
      (sliceStart = 1), (sliceEnd = end + 1);
    }
    if (this.typesSlicesZero.indexOf(this.type) != -1) {
      (sliceStart = 0), (sliceEnd = end);
    }
    if (this.typesSlicesTwo.indexOf(this.type) != -1) {
      (sliceStart = 2), (sliceEnd = end + 2);
    }
    return { sliceStart, sliceEnd };
  }

  resetConfiguration(): void {
    setDefaultConfiguration(
      this,
      this.baseChart,
      this.options,
      this.settings,
      this.renderSeriesConfig,
      this.styles,
      this.type
    );
  }

  private getOutput(length: number) {
    const { sliceStart, sliceEnd } = this.sliceIndex(length);
    return this.points.output.slice(sliceStart, sliceEnd);
  }
}
