import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { RestService } from '@shared/services/rest/rest.service';
import { ITreeMap, ITreeMapChildrens } from './interface/treemap.interface';
import { formatterNumber, sort } from 'src/app/utils/utils.functions';

@Injectable({
  providedIn: 'root',
})
export class TreemapService extends RestService {
  override _url: string = 'api/trademap';

  constructor(http: HttpClient) {
    super(http);
  }

  public getTreemap(
    cd_stock: string,
    id_exchange: number,
    filterByParticipation: boolean = false
  ): Observable<ITreeMap> {
    return this.post<{ success: boolean; result: ITreeMap }>(
      'v6/treemap/get-treemap',
      {
        cd_stock,
        id_exchange,
      }
    ).pipe(
      map((response) => {
        if (
          !response ||
          !response.data ||
          !response.data.success ||
          !response.data.result
        )
          throw new Error('');
        if (filterByParticipation) {
          const childrens = this._buildChildrenInfos(
            response.data.result.children
          );
          response.data.result.children = childrens;
          return response.data.result;
        }
        return response.data.result;
      }),
      catchError((error) => of(this.handlerServiceError(error)))
    );
  }

  private _buildChildrenInfos(
    stocks: ITreeMapChildrens[]
  ): ITreeMapChildrens[] {
    return sort(stocks, 'size').map((stock: ITreeMapChildrens) => {
      stock.idRow = `${stock.cd_stock}:${stock.id_exchange}`;
      stock.variacao_dia = 0;
      stock.nm_company = `${formatterNumber(stock.size)}%`;
      return stock;
    });
  }
}
