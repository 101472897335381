import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FlaTPopoversPosition } from '../types/popovers.types';
// @ts-expect-error omnitheme import
import { Popover } from '@omni/omnitheme';
import { randomId } from 'src/app/utils/utils.framework';

@Directive({
  selector: '[appPopover]',
})
export class PopoversDirective implements OnInit, OnDestroy {
  @Input('appPopover') title!: string;
  @Input() popoverPosition: FlaTPopoversPosition = 'left';
  @Input() popoverHtml: boolean = false;
  @Input() disabledClick: boolean = false;
  @Input() showOnStart: boolean = false;
  @Input() customClass: string = '';
  @Input() hideOnEsc: boolean = false;
  @Output() popoverClose = new EventEmitter<any>();
  private id: string = randomId('fla_popover');
  private popover: Popover;
  private element!: HTMLElement;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.element = this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    this.init();
    if(this.hideOnEsc) document.addEventListener('keyup', this._handleKeyUp)
  }
  ngOnDestroy(): void {
    this.popover.hide();
  }

  private init(): void {
    if (this.element.tagName.toLowerCase().includes('app')) {
      this.element.classList.add('d-inline-block');
    }

    this.element.id = this.id;

    this.popover = Popover.getOrCreateInstance(this.element, {
      placement: this.popoverPosition,
      title: this.title,
      html: this.popoverHtml,
      customClass: this.customClass,
      trigger: this.disabledClick ? 'manual' : 'click',
      container: 'body'
    });

    this.renderer.listen(this.element, 'hidden.bs.popover', ($event) =>
      this.popoverClose.next($event)
    );

    if(this.showOnStart){
      this.show()
    }
  }

  public show(): void {
    this.popover.show()
  }

  public hide(): void {
    this.popover.hide()
  }

  private _handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Escape') this.hide()
  }
}
