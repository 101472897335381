import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TIGER_INTERVAL_ENUM } from '@shared/tiger-chart/enum/tiger-chart.enum';
import { TData } from '@shared/tiger-chart/types/tiger-chart.types';
import { RestService } from '@shared/services/rest/rest.service';
import { map } from 'rxjs';
import {
  ISearchStock,
  ISearchStockPagination,
} from '@core/interface/stock.interface';
import {
  buildStockSearchLabel,
  getSinonimousByStock,
} from 'src/app/utils/utils.functions';
// import { PETR4_MOCK_PRICE_HISTORY } from './price-history.mock';

@Injectable({
  providedIn: 'root',
})
export class StockServiceRT extends RestService {
  override _url: string = 'api/nitro-ws/v1';

  constructor(http: HttpClient) {
    super(http);
  }

  getStocks = (stock: string) =>
    this.get(`stocks/${stock}`).pipe(map((res) => res.data));

  getPriceHistory = (
    stock: string,
    interval = TIGER_INTERVAL_ENUM.ONE_DAY,
    idPoint: number,
    idExchange: number,
    limit: number,
    dsAsset?: string
  ) => {
    const queryParams = new URLSearchParams();
    queryParams.set('cd_stock', stock);
    queryParams.set('interval', TIGER_INTERVAL_ENUM[interval]);
    queryParams.set('id_point', idPoint.toString());
    queryParams.set('id_exchange', idExchange.toString());
    queryParams.set('limit', limit.toString());
    if (dsAsset) {
      queryParams.set('ds_asset', dsAsset);
    }
    const url = `price-history?${queryParams.toString()}`;
    return this.get<TData>(url).pipe(map((res) => res.data));
  };

  public searchStock = (
    cdStock: string,
    typesOut: string[] = [],
    onlyExact?: boolean,
    isTerminalSearch: boolean = false,
    pagination?: ISearchStockPagination
  ) => {
    const urlPagination = isTerminalSearch ? '/pagination' : '';
    return this.post<any>(`stock/search-stock${urlPagination}`, {
      code: cdStock,
      showExpiredStocks: false,
      showInactiveStocks: false,
      ignoreTypesToRemove: isTerminalSearch,
      tipos_fora: typesOut, // ['US', 'CRYPTO', 'IDX', 'REITS-US', 'ADR', 'ETF-US', 'DEB', 'FIP', 'FIA', 'FDES']
      ...(pagination ? pagination : {}),
    }).pipe(
      map((res) => {
        if (res.data && res.data?.result) {
          const resp = (res.data.result as ISearchStock[]).map(
            (item: ISearchStock) =>
              this.standardizationStock(item, isTerminalSearch)
          );
          return !onlyExact
            ? resp
            : resp.filter(
                (stock) =>
                  stock.cd_stock === cdStock ||
                  stock.synonymous_nickname === cdStock ||
                  stock.cd_stock_order === cdStock
              );
        }
        return [];
      })
    );
  };

  public searchStockByStock = (stock: string) =>
    this.post<any>('stock/search-specific-stock', { stock }).pipe(
      map((res) => this.standardizationStock(res.data.result))
    );

  private standardizationStock(
    searchStock: ISearchStock,
    isTerminalSearch: boolean = false
  ) {
    const stock = getSinonimousByStock(searchStock);
    stock.stockLabel = buildStockSearchLabel(stock, isTerminalSearch);
    stock['stockVariation'] = {
      day_variation: 0,
      id_point: 0,
      id_stock: 0,
      vl_close: 0,
      vl_high: 0,
      vl_low: 0,
      vl_open: 0,
      volume: 0,
    };
    return stock;
  }
}
