import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IDebouncePositions } from '@shared/rocket-components/directives/auction-tooltip/auction-tooltip.directive';
import { Observable, Subject, auditTime } from 'rxjs';
import { FlaIconModule } from '../icon';
import {
  calculateTimeDifference,
  countdown,
  formatAuctionValues,
} from './constants/auction.constants';
import { deepClone } from '@shared/rocket-components/utils';

@Component({
  selector: 'app-auction-tooltip',
  templateUrl: './auction-tooltip.component.html',
  styles: [
    `
      .auction-tooltip-container {
        position: fixed;
        pointer-events: none;
        transition: opacity 1s;
        z-index: 9;

        &.left-position {
          right: 90px;
        }

        &.left-position-ag-grid {
          left: -90px !important;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FlaIconModule],
})
export class AuctionTooltipComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() public auctionInfos!: any;
  @Input() public componentId: string = '';
  @Input() isGridTooltip: boolean = false;

  public stockData!: any;
  public timer = '-';
  public topPosition: string = 'auto';
  public displayInLeftSide: boolean = false;
  public displayInLeftSideForAgGrid: boolean = false;
  public parentPositions!: IDebouncePositions;
  public position = { left: '0px', top: '0px' };
  public spacing = { left: 0, top: 0 };
  public auctionUpdateObservable!: Observable<any>;

  private _timerSubject = new Subject<void>();
  private _time = 0;

  constructor(private _cdr: ChangeDetectorRef) {
    this._timerSubject
      .pipe(auditTime(1000))
      .subscribe(() => this._handleAuctionTime());
  }

  ngAfterViewInit(): void {
    this._verifyTooltipPosition();
    this.auctionInfos && this.buildData(this.auctionInfos);
    this.auctionUpdateObservable.subscribe((params) => this.buildData(params));
  }

  ngOnChanges(changes: SimpleChanges): boolean {
    const { auctionInfos } = changes;
    if (auctionInfos?.currentValue) this.buildData(auctionInfos.currentValue);
    return true;
  }

  ngOnDestroy(): void {
    this._timerSubject.unsubscribe();
  }

  private _verifyTooltipPosition(): void {
    const leftPosPlusComponent =
      this.parentPositions.left + 250 + this.spacing.left;
    const leftPosition =
      leftPosPlusComponent > window.innerWidth
        ? this.parentPositions.left - 167 - this.spacing.left
        : this.parentPositions.left + this.spacing.left;

    this.position = {
      left: `${leftPosition}px`,
      top: `${this.parentPositions.top + this.spacing.top}px`,
    };
    this._cdr.detectChanges();
  }

  public buildData(params: any): void {
    this.stockData = deepClone(params);
    this._formatAuctionValues(this.stockData);
    if (this.isGridTooltip) this._verifyTooltipPosition();
    this._cdr.detectChanges();
  }

  private _formatAuctionValues = (params: any): void => {
    this.stockData = formatAuctionValues(params);
    this._time = calculateTimeDifference(params.hora_abert_program);
    this._handleAuctionTime();
    this._cdr.detectChanges();
  };

  private _handleAuctionTime(): void {
    if (this._timerSubject.closed) return;
    const { timer, timerFormatted } = countdown(this._time);
    this._time = timer;
    this.timer = timerFormatted;
    this._cdr.detectChanges();
    this._timerSubject.next();
  }
}
