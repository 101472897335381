import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionsComponent } from './options.component';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingModule } from '../loading/loading.module';
import { MarketSectorsService } from '@shared/services/api/trademap/v1/market-sectors.service';
import {
  FlaCheckModule,
  FlaIconModule,
  FlaInputModule,
  FlaSelectModule,
  FlaTabModule,
  FlaTagModule,
  RocketButtonModule,
  RocketModalModule,
} from '@shared/rocket-components/components/index';
import { OptionsHeaderComponent } from './parts/header/options-header.component';
import { SearchStockModule } from '../search-stock/search-stock.module';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { OptionsListComponent } from './parts/list/options-list.component';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';
import { RocketGridModule } from '@shared/rocket-grid';
import { ActionButtonsCellRendererComponent } from './parts/list/action-buttons-cell-renderer/action-buttons-cell-renderer.component';
import { OptionsComponentService } from './options-component.service';
import { OptionsStrategyComponent } from './parts/strategy/options-strategy.component';
import { StyleCellRendererComponent } from './parts/list/style-cell-renderer/style-cell-renderer.component';
import { RTInputCountModule } from '@shared/rocket-components/input-count/input-count.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BestOfferRenderer } from './parts/list/best-offer-renderer/best-offer-renderer.component';
import { OptionsSubheaderComponent } from './parts/subheader/options-subheader.component';
import { OptionsOperationsComponent } from './parts/operations/options-operations.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FlaBigNumberModule } from '@shared/rocket-components/pipes/big-number';
import { OptionsStrategyGraphComponent } from './parts/strategy/strategy-graph/options-strategy-graph.component';
import { OptionsSmileVolatilityComponent } from './parts/smile-volatility/options-smile-volatility.component';
import { OptionsHistoryGraphComponent } from './parts/smile-volatility/history-graph/options-history-graph.component';
import { OptionsSmileGraphComponent } from './parts/smile-volatility/smile-graph/options-smile-graph.component';
import { OptionsStrategyBookComponent } from './parts/strategy/strategy-book/options-strategy-book.component';
import { OptionsStrategyModalComponent } from './parts/strategy-modal/options-strategy-modal.component';
import { OptionsStrategyModalAnswersComponent } from './parts/strategy-modal/strategy-modal-answers/options-strategy-modal-answers.component';
import { OptionsStrategyModalStrategySelectorComponent } from './parts/strategy-modal/strategy-modal-strategy-selector/options-strategy-modal-strategy-selector.component';
import { OptionsTableContextMenuComponent } from './config/options-table-context-menu.component';
import { OptionsListPreferencesModalComponent } from './parts/list/list-preferences-modal/options-list-preferences-modal.component';
import { OptionsListTableComponent } from './parts/list/options-list-table/options-list-table.component';
import { OptionsListCallputComponent } from './parts/list/options-list-callput/options-list-callput.component';
import { CarouselScrollDirective } from '@shared/rocket-components/directives/carousel-scroll/carousel-scroll.directive';

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    FlaBigNumberModule,
    FlaCheckModule,
    FlaInputModule,
    FlaIconModule,
    RocketModalModule,
    FlaTabModule,
    FlaSelectModule,
    FlaWorkSpaceModule,
    FormsModule,
    LoadingModule,
    ReactiveFormsModule,
    RocketButtonModule,
    RocketModalModule,
    RocketGridModule,
    RTDropdownModule,
    RTInputCountModule,
    SearchStockModule,
    ScrollingModule,
    TooltipsModule,
    FlaTagModule,
    CarouselScrollDirective
  ],
  declarations: [
    ActionButtonsCellRendererComponent,
    BestOfferRenderer,
    OptionsTableContextMenuComponent,
    OptionsComponent,
    OptionsHeaderComponent,
    OptionsHistoryGraphComponent,
    OptionsListCallputComponent,
    OptionsListComponent,
    OptionsListPreferencesModalComponent,
    OptionsListTableComponent,
    OptionsOperationsComponent,
    OptionsSmileVolatilityComponent,
    OptionsSmileGraphComponent,
    OptionsStrategyComponent,
    OptionsStrategyBookComponent,
    OptionsStrategyGraphComponent,
    OptionsStrategyModalComponent,
    OptionsStrategyModalAnswersComponent,
    OptionsStrategyModalStrategySelectorComponent,
    OptionsSubheaderComponent,
    StyleCellRendererComponent,
  ],
  providers: [MarketSectorsService, OptionsComponentService],
  exports: [OptionsComponent],
})
export class OptionsModule {}
