<rocket-modal>
  <rocket-modal-header [hideCloseButton]="true">
    <div class="hstack justify-content-between w-100">
      <div class="gap-3 hstack">
        <app-broker-logo [logo]="brokerLogo"></app-broker-logo>
        <h4 class="modal-title fw-bold">
          Nossas boas-vindas ao RocketTrader! 🚀
        </h4>
      </div>
      <button
        type="button"
        class="btn btn-outline-primary btn-sm"
        (click)="logout()"
        [disabled]="isLoading"
      >
        <i class="icons">logout</i>
        Sair
      </button>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div class="fs-base gap-4 mb-4 vstack">
      <span>
        Chegou a hora o dar o seu próximo passo com uma plataforma de alta
        performance, estável, segura e feita por quem vive o mercado financeiro.
      </span>
      <span> Vimos que você já fez a conexão com um de nossos parceiros. </span>
      <span>
        Como presente de boas vindas, estamos te dando
        {{ data.trialDuration }} dias de trial para você testar todas as nossas
        ferramentas!
      </span>
    </div>

    <ng-container *ngIf="hasTerm">
      <span class="d-block fs-base mb-4 text-light">
        Ao clicar em <span class="fw-bold">Começar a usar</span> você terá
        concordado com os
        <a
          class="fs-base fw-bold text-brand-primary"
          target="_blank"
          [href]="broker.term?.content"
        >
          Termos de Condições de Contratação
        </a>
      </span>
    </ng-container>

    <ng-container *ngIf="broker?.brokerCondition?.length">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item border-none">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button collapsed pl-0 pt-0 text-left text-light w-100"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              (click)="showBrokerRequirements = !showBrokerRequirements"
            >
              <div class="hstack">
                <span class="text-brand-primary"> Condição de gratuidade </span>
                <span class="material-icons-outlined text-brand-primary ml-2">
                  {{ showBrokerRequirements ? 'expand_less' : 'expand_more' }}
                </span>
              </div>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body bg-neutral-strong p-3 round">
              <ul class="pl-4 mb-0 fs-5">
                <ng-container *ngFor="let rule of broker.brokerCondition">
                  <li class="mb-1" [innerHTML]="rule"></li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="hasTerm; else onlyButton">
      <div class="gap-3 hstack w-100 mb-2">
        <div class="mb-5 w-100">
          <label for="exampleInputPassword1" class="form-label required">
            Senha do RocketTrader
          </label>
          <div class="input-group group-suffix">
            <input
              [type]="showRTPassword ? 'text' : 'password'"
              class="form-control"
              required
              id="exampleInputPassword1"
              (keyup)="setRTPassword($event)"
            />
            <button
              class="btn btn-icon suffix text-muted pl-3"
              type="button"
              id="btnPass"
              (click)="showRTPassword = !showRTPassword"
            >
              <i class="icons pe-none">
                {{ showRTPassword ? 'visibility' : 'visibility_off' }}
              </i>
            </button>
          </div>
          <small class="invalid-feedback">Informe uma senha.</small>
        </div>
        <button
          type="button"
          class="btn btn-primary btn-min-width"
          (click)="handleConnection()"
          [disabled]="!password.length || isLoading"
        >
          Começar a usar
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="error">
      <span
        class="d-block fs-5 fw-bold py-3 text-center text-feedback-error w-100"
      >
        {{ errorMsg ? errorMsg : 'Erro ao processar conexão, tente novamente' }}
      </span>
    </ng-container>

    <ng-template #onlyButton>
      <div class="w-100 hstack justify-content-end mb-6 mt-4">
        <button
          type="button"
          class="btn btn-primary btn-min-width"
          (click)="handleConnection()"
        >
          Começar a usar
        </button>
      </div>
    </ng-template>
  </app-rocket-modal-body>
</rocket-modal>
