import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'fla-card-img[src]',
  templateUrl: './card-img.component.html',
})
export class FlaCardImgComponent implements OnChanges {
  @ViewChild(TemplateRef) content!: TemplateRef<any>;
  @Input() src!: string;
  @Input() height: string = 'auto';
  @Input('css') customClass: string = '';
  @Input() classImg: string = '';
  @Input() classBody: string = '';
  @Input('overlay') isImgOverlay: boolean = false;
  @Input('imgBottom') isImgBottom: boolean = false;
  public typeBody: string = 'card-body';
  public typeImg: string = 'card-img-top';

  ngOnChanges(changes: SimpleChanges): void {
    const { isImgOverlay } = changes;
    (isImgOverlay?.currentValue || isImgOverlay?.currentValue === false) &&
      (this.typeBody = isImgOverlay.currentValue
        ? 'card-img-overlay'
        : 'card-body') &&
      (this.typeImg = isImgOverlay.currentValue ? 'card-img' : 'card-img-top');
  }
}
