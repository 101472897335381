import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { StockListItemsModel } from '@shared/components/stock-table/models/stock-list-items.model';
import { CHART_COLORS } from '@shared/tiger-chart/colors';

@Component({
  selector: 'app-stock-card',
  templateUrl: './stock-card.component.html',
  styleUrls: ['./stock-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockCardComponent implements OnChanges {
  @Input() bgColor = CHART_COLORS.NEUTRAL_MEDIUM;
  @Input() textColor = CHART_COLORS.NEUTRAL_STRONGEST;
  @Input() variation = '0,00%';
  @Input() lastPrice = '0,00';
  @Input() vlCloseHistory!: number[];
  @Input() isClickable!: boolean;
  @Input() stock!: StockListItemsModel;

  @Output() clicked: EventEmitter<null> = new EventEmitter<null>();
  showImgError: boolean = false;
  isActive = false;
  public stockLabel: string = '';

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { stock } = changes;
    if (stock?.currentValue) this._setStockLabel(stock.currentValue);
  }

  private _setStockLabel(stock: StockListItemsModel): void {
    this.showImgError = false;
    this.stockLabel = stock.stockLabel
      ? stock.stockLabel
      : stock.synonymousNickname || stock.cdStock;
    this._cdr.detectChanges();
  }

  onClick() {
    this.clicked.emit();
  }

  setActiveStyles() {
    this.isActive = true;
    this._cdr.detectChanges();
  }

  setInactiveStyles() {
    this.isActive = false;
    this._cdr.detectChanges();
  }

  getLabel() {
    return this.stock.cdStock;
  }
}
