import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlaTagComponent } from './tag.component';
import { AuctionTooltipDirective } from '@shared/rocket-components/directives/auction-tooltip/auction-tooltip.directive';

@NgModule({
  imports: [CommonModule, AuctionTooltipDirective],
  declarations: [FlaTagComponent],
  exports: [FlaTagComponent],
})
export class FlaTagModule {}
