<rocket-modal>
  <rocket-modal-header [hideCloseButton]="true">
    <div class="w-100 d-flex align-items-center justify-content-between">
      <h3 class="modal-title">
        {{
          isEdit
            ? 'Alterar parâmetros TradeIdea'
            : !isEditMyTradeIdea
            ? 'Novo TradeIdea'
            : 'Editar TradeIdea'
        }}
      </h3>
      <div (click)="onClose()" class="cursor-pointer text-neutral-medium">
        <fla-icon size="icon-size-sm" name="close"></fla-icon>
      </div>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <form *ngIf="form" [formGroup]="form" class="d-flex flex-column gap-2">
      <span>
        {{
          isEdit
            ? 'Edite abaixo os parâmetros do TradeIdea e clique em executar para disparar a ordem'
            : 'Escolha os parâmetros abaixo do seu novo TradeIdea'
        }}</span
      >
      <div
        *ngIf="!isEdit"
        class="d-flex flex-column w-100 cursor-pointer position-relative"
      >
        <fla-input
          formControlName="description"
          label="Descrição"
          placeholder="Adicione sua descrição aqui"
          type="text"
          [maxLength]="340"
        >
        </fla-input>
      </div>
      <div class="toggle-div" *ngIf="!isEdit">
        <div
          class="w-100 d-flex border round gap-1 h-100 cursor-pointer p-1 fs-6"
        >
          <div
            [ngClass]="{
              'bg-multibroker-buy text-neutral-strongest':
                selectedSide === 'BUY'
            }"
            class="round button d-flex justify-content-center align-items-center"
            (click)="selectSide('BUY')"
          >
            <span class="fw-bold">Compra</span>
          </div>
          <div
            [ngClass]="{
              'bg-multibroker-sell text-neutral-strongest':
                selectedSide === 'SELL'
            }"
            class="round button d-flex justify-content-center align-items-center"
            (click)="selectSide('SELL')"
          >
            <span class="fw-bold">Venda</span>
          </div>
        </div>
      </div>
      <div class="d-flex gap-3">
        <div class="d-flex flex-column">
          <app-search-stock
            #searchStock
            label="Ativo:"
            [resetInput]="false"
            (selectStockChange)="selectStock($event)"
            [ref]="refComponent"
            [initStock]="stockSelected"
            css=""
            [isDisable]="isEdit"
            [headerOptions]="{ link: false }"
          ></app-search-stock>
        </div>
        <div class="d-flex flex-column w-100">
          <fla-select
            formControlName="type"
            label="Tipo:"
            bindLabel="desc"
            bindValue="id"
            margin="m-0"
            [isSmall]="true"
            [list]="orderTypes"
            [searchable]="false"
            [hasBorder]="true"
          ></fla-select>
        </div>
        <div
          *ngIf="form.get('type')?.value !== 1"
          class="d-flex flex-column w-100"
        >
          <app-input-count
            formControlName="price"
            [initialValue]="initialValuePrice"
            [incrementValue]="incrementPrice"
            [min]="0"
            [isSmall]="true"
            label="Preço:"
            [mask]="priceMask"
            [isModal]="true"
            margin="m-0"
            [enableScrollEvent]="true"
            [enableUpdateValueBtn]="true"
            [selectOnFocus]="true"
            (updateValue)="updateLastPrice()"
          ></app-input-count>
        </div>
        <div *ngIf="isEdit" class="d-flex flex-column w-100">
          <app-input-count
            formControlName="qtty"
            [initialValue]="configs.qtty"
            [incrementValue]="incrementValue"
            [min]="standardLot"
            [isSmall]="true"
            label="Qtd:"
            mask="separator.0"
            [enableScrollEvent]="true"
            [isModal]="true"
            [selectOnFocus]="true"
            margin="m-0"
          ></app-input-count>
        </div>
        <div
          *ngIf="!isEdit"
          class="d-flex flex-column w-100 cursor-pointer position-relative"
        >
          <fla-input
            formControlName="dateExpiration"
            label="Até quando vale?"
            type="text"
            (click)="datePicker()"
            [isSmall]="true"
            cssInput="cursor-pointer"
          >
          </fla-input>
          <app-datepicker
            [initialDate]="selectedDate"
            [useTimepicker]="true"
            [closeOnClick]="false"
            [isAbsolute]="true"
            [showDatePicker]="showDatePicker"
            positionAbsolute="RIGHT-CENTER"
            (flaClick)="datePickerDate($event)"
          ></app-datepicker>
        </div>
      </div>
      <div *ngIf="!isEdit">
        <app-tick-strategy
          [isNewTick]="true"
          [form]="form"
          [height]="140"
          [maxLegs]="4"
          [scroll]="2"
          [groupForm]="groupForm"
          (inputMaskChange)="inputMaskChange($event)"
          (groupHandler)="groupHandler($event)"
        ></app-tick-strategy>
      </div>
      <div *ngIf="isEdit" class="d-flex align-items-end">
        <div class="d-flex flex-column w-50">
          <fla-select
            formControlName="strategy"
            label="Estratégia:"
            bindLabel="nameStrategy"
            bindValue="idStrategy"
            margin="m-0"
            [isSmall]="true"
            [list]="strategies"
            [searchable]="false"
            [hasBorder]="true"
            (flaChange)="changeStrategy($event)"
          ></fla-select>
        </div>
        <rocket-button
          [isSmall]="true"
          label="+ Criar nova estratégia"
          type="fill"
          (rocketClick)="openNewStrategyModal()"
        >
        </rocket-button>
      </div>
      <div *ngIf="isEdit" class="mt-3">
        <div class="d-flex align-items-center gap-6">
          <span *ngIf="selectedStrategy" class="fs-6">
            Modo: {{ selectedStrategy.strategyType }}
          </span>
          <div>
            <rocket-button
              [isSmall]="true"
              label="Editar estratégia"
              type="fill"
              (rocketClick)="openEditStrategyModal()"
            >
            </rocket-button>
          </div>
        </div>
        <app-table-trade-idea-items
          *ngIf="selectedStrategy"
          [items]="selectedStrategy.strategyItems"
        ></app-table-trade-idea-items>
      </div>
      <div class="d-flex justify-content-end gap-3 pb-6">
        <rocket-button
          [isSmall]="true"
          [label]="isEdit ? 'Cancelar' : 'Voltar'"
          type="fill"
          (rocketClick)="onClose()"
        >
        </rocket-button>
        <rocket-button
          [isSmall]="true"
          [label]="
            isEdit ? 'Executar' : !isEditMyTradeIdea ? 'Criar' : 'Editar'
          "
          (rocketClick)="executeTradeIdea()"
          [isDisabled]="
            form.invalid || form.get('items')?.value.invalid || quantityInvalid
          "
        >
        </rocket-button>
      </div>
    </form>
  </app-rocket-modal-body>
</rocket-modal>
