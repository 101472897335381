import { FormGroup, ValidationErrors } from '@angular/forms';
import { DynamicControlService } from '@shared/rocket-components/dynamic-form/dynamic-control.service';
import {
  TYPE_ORDE_ENUM,
  TYPE_VALIDADE_ENUM,
} from '../../enum/stock-trade.enum';
import { LimitedForm } from '../../forms/limited-form';
import { MarketForm } from '../../forms/market-form';
import { StartStopForm } from '../../forms/start-stop-form';
import {
  formatByTick,
  isOrderBuy,
  startStopValidation,
} from 'src/app/utils/utils.functions';
import { SaleForm } from '../../forms/sale-form';
import { SaleCloseForm } from '../../forms/sale-close-form';
import { SaleOpenForm } from '../../forms/sale-open-form';

export class CreateForm {
  constructor(
    private dynamicControlService: DynamicControlService,
    private configs: any
  ) {}

  public updateConfig(update: any): void {
    this.configs = update;
  }

  public changeForm(
    typeOrder: string | number = '0',
    sideOrder: any,
    orderOcoOrDoubleStopValue: boolean = false,
    tempFormValues: any = null,
    isSimulator: boolean
  ): { form: FormGroup; fieldsForm: any[] } {
    let orderForm!: any;
    let fieldsForm!: any[];
    let form!: FormGroup;
    const precision = ['WDO' || 'DOL'].includes(this.configs.dsAsset)
      ? 1
      : this.configs.tickSize;
    const mask = `separator.${precision}`;
    switch (typeOrder) {
      case TYPE_ORDE_ENUM.LIMITADA:
      case `${TYPE_ORDE_ENUM.LIMITADA}`:
        orderForm = new LimitedForm(
          this.dynamicControlService,
          this.configs.standardLot,
          this.configs.incrementValue,
          this.configs.vlClosePrice,
          sideOrder,
          orderOcoOrDoubleStopValue,
          mask,
          isSimulator
        );
        fieldsForm = orderForm.createFields(tempFormValues);
        form = orderForm.createForm(tempFormValues);
        form.setValidators([
          this.priceRequired,
          this.checkMultiplePriceError,
          this.qtdeRequired,
          this.ocoValidation,
          this.checkMultiplePriceGainError,
          this.checkMultiplePriceLossError,
          this.checkTypeValidity,
        ]);
        this.checkOrderOCO(form);
        this.changeFieldsOrderOCO(orderOcoOrDoubleStopValue, fieldsForm);
        form.controls['typeOdrer'].setValue(TYPE_ORDE_ENUM.LIMITADA);
        break;
      case TYPE_ORDE_ENUM.MARKET:
        orderForm = new MarketForm(
          this.dynamicControlService,
          this.configs.standardLot,
          this.configs.incrementValue,
          sideOrder,
          isSimulator,
          this.configs.vlClosePrice,
          orderOcoOrDoubleStopValue,
          mask
        );
        fieldsForm = orderForm.createFields(tempFormValues);
        form = orderForm.createForm(tempFormValues);
        form.setValidators([
          this.qtdeRequired,
          this.checkTypeValidity,
          () => this.ocoValidation(form, this.configs.vlClosePrice),
          this.checkMultiplePriceGainError,
          this.checkMultiplePriceLossError,
        ]);
        this.checkOrderOCO(form);
        this.changeFieldsOrderOCO(orderOcoOrDoubleStopValue, fieldsForm);
        form.controls['typeOdrer'].setValue(TYPE_ORDE_ENUM.MARKET);
        break;
      case TYPE_ORDE_ENUM.START_STOP:
      case TYPE_ORDE_ENUM.DOUBLE_START_STOP:
        orderForm = new StartStopForm(
          this.dynamicControlService,
          this.configs.standardLot,
          this.configs.incrementValue,
          this.configs.vlClosePrice,
          sideOrder,
          orderOcoOrDoubleStopValue,
          mask
        );
        fieldsForm = orderForm.createFields(tempFormValues);
        form = orderForm.createForm(tempFormValues);
        form.setValidators([
          this.priceRequired,
          this.qtdeRequired,
          this.checkMultiplePriceError,
          this.startStopTriggerValidation,
          this.checkMultiplePriceStopError,
          this.validateFieldsStopDouble,
          this.checkMultiplePriceGainDoubleError,
          this.checkMultiplePriceLossDoubleError,
          this.checkMultiplePriceGainTriggerError,
          this.checkMultiplePriceLossTriggerError,
          this.checkTypeValidity,
        ]);
        this.checkOrderDoubleStop(form);
        this.changeFieldsOrderDoubleStop(orderOcoOrDoubleStopValue, fieldsForm);
        break;
      case TYPE_ORDE_ENUM.SALE:
        orderForm = new SaleForm(
          this.dynamicControlService,
          this.configs.standardLot,
          this.configs.incrementValue,
          this.configs.vlClosePrice,
          sideOrder,
          mask
        );
        fieldsForm = orderForm.createFields(tempFormValues);
        form = orderForm.createForm(tempFormValues);
        form.setValidators([this.qtdeRequired]);
        form.controls['typeOdrer'].setValue(TYPE_ORDE_ENUM.SALE);
        break;
      case TYPE_ORDE_ENUM.SALE_OF_OPEN:
        orderForm = new SaleOpenForm(
          this.dynamicControlService,
          this.configs.standardLot,
          this.configs.incrementValue,
          sideOrder
        );
        fieldsForm = orderForm.createFields(tempFormValues);
        form = orderForm.createForm(tempFormValues);
        form.setValidators([this.qtdeRequired]);
        form.controls['typeOdrer'].setValue(TYPE_ORDE_ENUM.SALE_OF_OPEN);
        break;
      case TYPE_ORDE_ENUM.SALE_OF_CLOSE:
        orderForm = new SaleCloseForm(
          this.dynamicControlService,
          this.configs.standardLot,
          this.configs.incrementValue,
          sideOrder
        );
        fieldsForm = orderForm.createFields(tempFormValues);
        form = orderForm.createForm(tempFormValues);
        form.setValidators([this.qtdeRequired]);
        form.controls['typeOdrer'].setValue(TYPE_ORDE_ENUM.SALE_OF_CLOSE);
        break;
    }
    this.configs.fieldsForm = fieldsForm;
    form.controls['typeValidade']?.valueChanges?.subscribe((value: any) => {
      const field = this.configs.fieldsForm.find(
        (item: any) => item.key === 'dueDate'
      );
      const space2 = this.configs.fieldsForm.find(
        (item: any) => item.key === 'space2'
      );
      field.controlType = '';
      space2 && (space2.controlType = 'freeContent');
      if (value === TYPE_VALIDADE_ENUM.UNTIL_THE_DAY) {
        space2 && (space2.controlType = '');
        field.controlType = 'inputText';
      }
    });
    return { form, fieldsForm };
  }

  private priceRequired = (formGroup: any): ValidationErrors | null => {
    if (formGroup.value?.orderDouble) return null;
    const message = formGroup.value.price > 0 ? '' : 'Preencha o campo.';
    return message
      ? {
          priceRequired: message,
        }
      : null;
  };

  private checkTypeValidity = (formGroup: any): ValidationErrors | null => {
    if (
      this.configs.isDayTrade &&
      (formGroup.value.typeValidade === TYPE_VALIDADE_ENUM.UNTIL_THE_DAY ||
        formGroup.value.typeValidade === TYPE_VALIDADE_ENUM.VALID_UNTIL_CANCEL)
    ) {
      return {
        incorrectValidityType: `Validade incorreta para o modo selecionado.`,
      };
    }
    return null;
  };

  setMarketValidations(form: FormGroup, vlClose: number) {
    form.setValidators([
      this.qtdeRequired,
      this.checkTypeValidity,
      () => this.ocoValidation(form, vlClose),
      this.checkMultiplePriceGainError,
      this.checkMultiplePriceLossError,
    ]);
    form.updateValueAndValidity();
  }

  private marketOcoValidation = (
    formGroup: any,
    vlClosePrice?: any
  ): { messageGainPrice: string; messageLossPrice: string } => {
    let messageGainPrice;
    let messageLossPrice;
    if (isOrderBuy(formGroup.value.orderSide)) {
      messageGainPrice =
        formGroup.value.gainPrice > vlClosePrice
          ? ''
          : 'O valor de ganho deve ser maior que o preço.';
      messageLossPrice =
        formGroup.value.lossPrice < vlClosePrice
          ? ''
          : 'Valor de perda deve ser menor que o preço.';
    } else {
      messageGainPrice =
        formGroup.value.gainPrice < vlClosePrice
          ? ''
          : 'O valor de ganho deve ser menor que o preço.';
      messageLossPrice =
        formGroup.value.lossPrice > vlClosePrice
          ? ''
          : 'O valor de ganho deve ser maior que o preço.';
    }
    return { messageGainPrice, messageLossPrice };
  };

  private ocoValidation = (
    formGroup: any,
    vlClosePrice?: any
  ): ValidationErrors | null => {
    if (!formGroup.value.orderOco) return null;
    const messageGain =
      formGroup.value.gainPrice > 0 ? '' : 'Preencha o campo.';
    const messageLoss =
      formGroup.value.lossPrice > 0 ? '' : 'Preencha o campo.';
    let messageGainPrice;
    let messageLossPrice;
    if (formGroup.value.orderType == TYPE_ORDE_ENUM.MARKET) {
      const marketValidation = this.marketOcoValidation(
        formGroup,
        vlClosePrice
      );
      messageGainPrice = marketValidation.messageGainPrice;
      messageLossPrice = marketValidation.messageLossPrice;
    } else if (isOrderBuy(formGroup.value.orderSide)) {
      messageGainPrice =
        formGroup.value.gainPrice > formGroup.value.price
          ? ''
          : 'O valor de ganho deve ser maior que o preço.';
      messageLossPrice =
        formGroup.value.lossPrice < formGroup.value.price
          ? ''
          : 'Valor de perda deve ser menor que o preço.';
    } else {
      messageGainPrice =
        formGroup.value.gainPrice < formGroup.value.price
          ? ''
          : 'O valor de ganho deve ser menor que o preço.';
      messageLossPrice =
        formGroup.value.lossPrice > formGroup.value.price
          ? ''
          : 'O valor de ganho deve ser maior que o preço.';
    }
    if (!messageGain && !messageLoss && !messageGainPrice && !messageLossPrice)
      return null;
    const error: any = {};
    messageGain && (error['gainRequired'] = messageGain);
    messageLoss && (error['lossRequired'] = messageLoss);
    messageGainPrice && (error['gainPrice'] = messageGainPrice);
    messageLossPrice && (error['lossPrice'] = messageLossPrice);
    return error;
  };

  private qtdeRequired = (formGroup: any): ValidationErrors | null => {
    const message = formGroup.value.qtde > 0 ? '' : 'Preencha a quantidade.';
    return message
      ? {
          qtdeRequired: message,
        }
      : null;
  };

  private checkMultiplePriceGainError = (
    formGroup: any
  ): ValidationErrors | null => {
    return this.checkMultiplePrice(
      formGroup.value.gainPrice,
      'multiplePriceGainError'
    );
  };

  private checkMultiplePriceLossError = (
    formGroup: any
  ): ValidationErrors | null => {
    return this.checkMultiplePrice(
      formGroup.value.lossPrice,
      'multiplePriceLossError'
    );
  };

  private checkMultiplePriceStopError = (
    formGroup: any
  ): ValidationErrors | null => {
    return this.checkMultiplePrice(
      formGroup.value.stopPx,
      'multiplePriceStopError'
    );
  };

  private checkMultiplePriceError = (
    formGroup: any
  ): ValidationErrors | null => {
    return this.checkMultiplePrice(formGroup.value.price, 'multiplePriceError');
  };

  private checkMultiplePriceGainDoubleError = (
    formGroup: any
  ): ValidationErrors | null => {
    if (!formGroup.value.orderDouble) return null;
    return this.checkMultiplePrice(
      formGroup.value.gainPrice,
      'multiplePriceGainDoubleError'
    );
  };

  private checkMultiplePriceLossDoubleError = (
    formGroup: any
  ): ValidationErrors | null => {
    if (!formGroup.value.orderDouble) return null;
    return this.checkMultiplePrice(
      formGroup.value.lossPrice,
      'multiplePriceLossDoubleError'
    );
  };

  private checkMultiplePriceGainTriggerError = (
    formGroup: any
  ): ValidationErrors | null => {
    if (!formGroup.value.orderDouble) return null;
    return this.checkMultiplePrice(
      formGroup.value.gainPriceTrigger,
      'multiplePriceGainTriggerError'
    );
  };

  private checkMultiplePriceLossTriggerError = (
    formGroup: any
  ): ValidationErrors | null => {
    if (!formGroup.value.orderDouble) return null;
    return this.checkMultiplePrice(
      formGroup.value.lossPriceTrigger,
      'multiplePriceLossTriggerError'
    );
  };

  private checkMultiplePrice(
    price: any,
    error: string
  ): ValidationErrors | null {
    if (this.configs.cdSegment != '9999') {
      return null;
    }
    const isDollar =
      this.configs.dsAsset == 'WDO' || this.configs.dsAsset == 'DOL';
    const divider = this.configs.vlMinPriceIncrement
      ? this.configs.vlMinPriceIncrement
      : isDollar
      ? 0.5
      : 5;
    const vallueError = formatByTick(divider);
    if (divider === 0.01 || Number.isInteger(price / divider)) {
      return null;
    }
    return {
      [error]: `Valor padrão ${vallueError}.`,
    };
  }

  private startStopTriggerValidation = (
    formGroup: any
  ): ValidationErrors | null => {
    if (formGroup.value?.orderDouble) return {};
    const message = startStopValidation(
      formGroup.value.orderSide,
      this.configs.lastPrice,
      formGroup.value.price,
      formGroup.value.stopPx
    );
    return message
      ? {
          startStopTriggerError: message,
        }
      : null;
  };

  private checkOrderOCO(form: FormGroup): void {
    form.controls['orderOco'].valueChanges.subscribe((value: boolean) =>
      this.changeFieldsOrderOCO(value)
    );
  }

  private changeFieldsOrderOCO(value: boolean, fieldsForm?: any[]): void {
    const fields = fieldsForm ?? this.configs.fieldsForm;
    fields.forEach((item: any) => {
      value && item.key === 'gainPrice' && (item.controlType = 'freeContent');
      value && item.key === 'lossPrice' && (item.controlType = 'freeContent');
      !value && item.key === 'gainPrice' && (item.controlType = '');
      !value && item.key === 'lossPrice' && (item.controlType = '');
    });
  }

  private checkOrderDoubleStop(form: FormGroup): void {
    form.controls['orderDouble'].valueChanges.subscribe((value: boolean) =>
      this.changeFieldsOrderDoubleStop(value)
    );
  }

  private changeFieldsOrderDoubleStop(
    value: boolean,
    fieldsForm?: any[]
  ): void {
    const fields = fieldsForm ?? this.configs.fieldsForm;
    fields.forEach((item: any) => {
      value && item.key === 'price' && (item.controlType = '');
      value && item.key === 'stopPx' && (item.controlType = '');
      value && item.key === 'gainPrice' && (item.controlType = 'freeContent');
      value &&
        item.key === 'gainPriceTrigger' &&
        (item.controlType = 'freeContent');
      value && item.key === 'lossPrice' && (item.controlType = 'freeContent');
      value &&
        item.key === 'lossPriceTrigger' &&
        (item.controlType = 'freeContent');

      !value && item.key === 'gainPrice' && (item.controlType = '');
      !value && item.key === 'gainPriceTrigger' && (item.controlType = '');
      !value && item.key === 'lossPrice' && (item.controlType = '');
      !value && item.key === 'lossPriceTrigger' && (item.controlType = '');
      !value && item.key === 'price' && (item.controlType = 'freeContent');
      !value && item.key === 'stopPx' && (item.controlType = 'freeContent');
    });
  }

  private validateFieldsStopDouble = (
    formGroup: any
  ): ValidationErrors | null => {
    if (!formGroup.value.orderDouble) return null;
    const messageGainPrice =
      formGroup.value.gainPrice > 0 ? '' : 'Preencha o campo.';
    const messageLossPrice =
      formGroup.value.lossPrice > 0 ? '' : 'Preencha o campo.';
    let messageGainPriceTrigger =
      formGroup.value.gainPriceTrigger > 0 ? '' : 'Preencha o campo.';
    let messageLossPriceTrigger =
      formGroup.value.lossPriceTrigger > 0 ? '' : 'Preencha o campo.';
    if (isOrderBuy(formGroup.value.orderSide)) {
      messageGainPriceTrigger =
        formGroup.value.gainPriceTrigger <= formGroup.value.gainPrice
          ? ''
          : 'O valor de disparo (Gain) deve ser menor ou igual ao preço.';
      messageLossPriceTrigger =
        formGroup.value.lossPriceTrigger <= formGroup.value.lossPrice
          ? ''
          : 'O valor de disparo (Loss) deve ser menor ou igual ao preço.';

      messageGainPriceTrigger =
        formGroup.value.gainPriceTrigger < this.configs.lastPrice
          ? messageGainPriceTrigger
          : 'O valor de disparo (Gain) deve ser menor que o preço atual.';
      messageLossPriceTrigger =
        formGroup.value.lossPriceTrigger > this.configs.lastPrice
          ? messageLossPriceTrigger
          : 'O valor de disparo (Loss) deve ser maior que o preço atual.';
    } else {
      messageGainPriceTrigger =
        formGroup.value.gainPriceTrigger >= formGroup.value.gainPrice
          ? ''
          : 'O valor de disparo (Gain) deve ser maior ou igual ao preço.';
      messageLossPriceTrigger =
        formGroup.value.lossPriceTrigger >= formGroup.value.lossPrice
          ? ''
          : 'O valor de disparo (Loss) deve ser maior ou igual ao preço.';

      messageGainPriceTrigger =
        formGroup.value.gainPriceTrigger > this.configs.lastPrice
          ? messageGainPriceTrigger
          : 'O valor de disparo (Gain) deve ser maior que o preço atual.';
      messageLossPriceTrigger =
        formGroup.value.lossPriceTrigger < this.configs.lastPrice
          ? messageLossPriceTrigger
          : 'O valor de disparo (Loss) deve ser menor que o preço atual.';
    }
    if (
      !messageGainPriceTrigger &&
      !messageLossPriceTrigger &&
      !messageGainPrice &&
      !messageLossPrice
    )
      return null;
    const error: any = {};
    messageGainPriceTrigger &&
      (error['errorGainPriceTrigger'] = messageGainPriceTrigger);
    messageLossPriceTrigger &&
      (error['errorLossPriceTrigger'] = messageLossPriceTrigger);
    messageGainPrice && (error['errorGainPrice'] = messageGainPrice);
    messageLossPrice && (error['errorLossPrice'] = messageLossPrice);
    return error;
  };
}
