import { ORDER_TYPES } from '@shared/components/stock-trade/constants/boleta.constants';
import { TYPE_ORDE_ENUM } from '@shared/components/stock-trade/enum/stock-trade.enum';
import { TYPE_ORDE_SIDE_ENUM } from '@shared/enum/buyOrSell.enum';
import { IAccountSelect } from '../interface';

export class ProcessSendOrderModel {
  public assinarTCD!: string;
  public assinarTCQ!: string;
  public cd_stock!: string;
  public conta!: string;
  public decimals!: string;
  public flag_valida_variacao!: string;
  public flag_valida_volume!: string;
  public id_broker!: string;
  public id_exchange!: string;
  public ip_machine!: string;
  public name!: string;
  public ord_type!: string;
  public order_qty!: string;
  public segment!: string;
  public side!: string;
  public time_in_force!: string;
  public tipo!: string;
  public validade!: string;
  public accept_split: boolean = false;
  public price?: string;
  public stopPx?: string;
  public priceString?: string;
  public total?: string;
  public value?: string;
  public tick_size_denominator?: string;
  public gainTriggerPrice?: string | number;
  public lossTriggerPrice?: string | number;
  public gainOrderPrice?: string | number;
  public lossOrderPrice?: string | number;
  public is_order_oco: boolean = false;
  public actionPosition?: 'INVERTER' | 'ZERAR' | null = null;
  public is_day_trade?: boolean = false;
  public id_strategy?: number = 0;
  public origin?: string = '';
  public expirate_date?: string | null = null;

  constructor(
    stock: any,
    orderValues: any,
    account: IAccountSelect,
    ordeSide: TYPE_ORDE_SIDE_ENUM,
    useSplit: boolean,
    idStrategy: number,
    origin: string
  ) {
    this.processOrder(
      stock,
      orderValues,
      account,
      ordeSide,
      useSplit,
      idStrategy,
      origin
    );
  }

  public processOrder(
    stock: any,
    orderValues: any,
    account: IAccountSelect,
    ordeSide: TYPE_ORDE_SIDE_ENUM,
    useSplit: boolean,
    idStrategy: number,
    origin: string
  ): void {
    this.accept_split = useSplit;
    this.flag_valida_volume = 'true';
    this.flag_valida_variacao = 'true';
    this.id_broker = `${account.id_broker}`;
    this.ip_machine = 'NO_IP';
    this.assinarTCQ = 'false';
    this.assinarTCD = 'false';
    this.cd_stock = stock.cd_stock_order;
    this.conta = `${account.account_number}`;
    this.decimals = `${stock.tick_size_denominator}`;
    this.id_exchange = `${stock.id_exchange}`;
    this.ord_type = '';
    this.order_qty = `${orderValues.order_qty}`;
    this.segment = `${stock.id_exchange_segment}`;
    this.side = `${ordeSide}`;
    this.time_in_force = `${orderValues.validade ?? 0}`;
    this.tipo = '';
    this.validade = this.typeValidade(orderValues.validade, orderValues.date);
    this.name = stock.cd_stock_order;
    this.tick_size_denominator = stock.tick_size_denominator;
    const typeOrder = ORDER_TYPES.find(
      (item) => item.id == orderValues.ord_type
    )!;
    this.is_day_trade = orderValues.is_day_trade;
    this.id_strategy = idStrategy;
    this.origin = origin;
    if (orderValues.validade == 6) {
      const dateSplit = orderValues.date.split('/');
      this.expirate_date = `${dateSplit[2]}${dateSplit[1]}${dateSplit[0]}`;
    }

    switch (orderValues.ord_type) {
      case TYPE_ORDE_ENUM.LIMITADA:
        this.ord_type = typeOrder.cod;
        this.tipo = typeOrder.desc;
        this.price = `${orderValues.price}`;
        this.priceString = `${orderValues.price}`;
        this.total = `${orderValues.price}`.replace(/\D/g, '');
        this.value = `${orderValues.price}`.replace(/\D/g, '');
        if (!!orderValues.gainPrice && !!orderValues.lossPrice) {
          this.gainOrderPrice = orderValues.gainPrice;
          this.lossOrderPrice = orderValues.lossPrice;
          this.is_order_oco = true;
        }
        break;
      case TYPE_ORDE_ENUM.MARKET:
        this.ord_type = typeOrder.cod;
        this.tipo = typeOrder.desc;
        if (!!orderValues.gainPrice && !!orderValues.lossPrice) {
          this.gainOrderPrice = orderValues.gainPrice;
          this.lossOrderPrice = orderValues.lossPrice;
          this.is_order_oco = true;
        }
        break;
      case TYPE_ORDE_ENUM.START_STOP:
        this.ord_type = typeOrder.cod;
        this.tipo = typeOrder.desc.toLocaleLowerCase();
        this.price = `${orderValues.price}`;
        this.stopPx = `${orderValues.stopPx}`;
        break;
      case TYPE_ORDE_ENUM.DOUBLE_START_STOP:
        this.ord_type = typeOrder.cod;
        this.tipo = typeOrder.desc;
        this.price = `${orderValues.price}`;
        this.gainOrderPrice = orderValues.gainOrderPrice;
        this.lossOrderPrice = orderValues.lossOrderPrice;
        this.gainTriggerPrice =
          orderValues.gainTriggerPrice || orderValues.gainOrderPrice;
        this.lossTriggerPrice =
          orderValues.lossTriggerPrice || orderValues.lossOrderPrice;
        break;
      case TYPE_ORDE_ENUM.RESET_POSITION:
        this.actionPosition = 'ZERAR';
        this.ord_type = TYPE_ORDE_ENUM.MARKET.toString();
        break;
      case TYPE_ORDE_ENUM.INVERT_POSITION:
        this.actionPosition = 'INVERTER';
        this.ord_type = TYPE_ORDE_ENUM.MARKET.toString();
        break;
      case TYPE_ORDE_ENUM.SALE:
        this.price = `${orderValues.price}`;
        this.priceString = `${orderValues.price}`;
        this.total = `${orderValues.price}`.replace(/\D/g, '');
        this.value = `${orderValues.price}`.replace(/\D/g, '');
        this.ord_type = typeOrder.type!;
        this.tipo = typeOrder.desc;
        this.time_in_force = typeOrder.cod;
        break;
      case TYPE_ORDE_ENUM.SALE_OF_OPEN:
      case TYPE_ORDE_ENUM.SALE_OF_CLOSE:
        this.ord_type = typeOrder.type!;
        this.tipo = typeOrder.desc;
        this.time_in_force = typeOrder.cod;
        break;
    }
  }

  private typeValidade(typeValidade: any, date?: string) {
    const validity: any = {
      0: 'Hoje',
      6: date,
      1: 'Até Cancelar',
      3: 'Executa ou Cancela',
      4: 'Tudo ou Nada',
    };
    return validity[typeValidade] ?? 'Hoje';
  }
}
