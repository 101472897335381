import { Injectable } from '@angular/core';
import { ISearchStock } from '@core/interface';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { BehaviorSubject, Subject, debounceTime, filter, tap } from 'rxjs';
import {
  cantProcessKeyboardEvent,
  isLetterNotCtrlShift,
  isLetterOrNumberNotCtrl,
  isLetterOrNumberNotCtrlShift,
} from 'src/app/utils/utils.keyboard';

@Injectable({
  providedIn: 'root',
})
export class SuperSearchService {
  private _words: any = '';
  private _modalOpened: boolean = false;

  isOpeningModal: boolean = false;
  openModal$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  wordSubbject$ = new BehaviorSubject<string>('');
  modalClose$ = new Subject<ISearchStock | undefined>();
  public isScreenLocked: boolean = false;

  get modalOpened() {
    return this._modalOpened;
  }

  set modalOpened(value: boolean) {
    this._modalOpened = value;
  }

  get words() {
    return this._words;
  }

  set words(value: string) {
    this._words += value;
    this.wordSubbject$.next(this._words);
  }

  private keyDownSubject = new Subject<KeyboardEvent>();

  constructor(private globalStock: GlobalSelectedStockSubscription) {
    this.keyDownSubject
      .pipe(debounceTime(50))
      .subscribe((event) => this.handleKeyDown(event));
  }

  onKeyDown = (event: KeyboardEvent) => {
    this.keyDownSubject.next(event);
  };

  handleKeyDown(event: KeyboardEvent) {
    const element: any = event.target;
    if (cantProcessKeyboardEvent(element) || this.isScreenLocked) return;

    const { code, ctrlKey, shiftKey } = event;
    this.isOpeningModal = this.isOpeningModal
      ? isLetterOrNumberNotCtrlShift(code, ctrlKey, shiftKey)
      : isLetterNotCtrlShift(code, ctrlKey, shiftKey);

    if (this.isOpeningModal) {
      this.incrementWord(event);
      if (this.modalOpened) return;
      this.open({ words: this._words, loadWord: this.wordSubbject$ });
    }
  }
  private incrementWord(event: KeyboardEvent) {
    const { code, key, ctrlKey } = event;
    const canIncrement =
      this.isOpeningModal && isLetterOrNumberNotCtrl(code, ctrlKey);
    if (canIncrement) {
      const char = key;
      this.setWord(this._words + `${char}`);
    }
  }

  onOpen = () =>
    this.openModal$.asObservable().pipe(
      filter((item) => item || item?.word),
      debounceTime(50)
    );

  open = (openParam: any) => {
    this.modalOpened = true;
    this.isOpeningModal = true;
    this.openModal$.next(openParam);
  };

  close = () => {
    this.modalOpened = false;
    this.isOpeningModal = false;
    this.setWord('');
  };

  listenToModalDismiss() {
    this.modalClose$
      .pipe(tap(() => this.close()))
      .subscribe((stock: ISearchStock | undefined) => {
        stock && this.globalStock.changeGlobalStock(stock);

        const doc = document.getElementById('workspace_base');
        if (doc) {
          const children = doc.children;
          for (let i = 0; i < children.length; i++) {
            const selectedDivId = children.item(i)!.id;
            const docChildren = document.getElementById(selectedDivId);
            if (
              docChildren &&
              docChildren.style.zIndex &&
              parseInt(docChildren.style.zIndex)
            ) {
              setTimeout(() => {
                docChildren.tabIndex = 1;
                docChildren.focus();
              }, 100);
            }
          }
        }
      });
  }

  setWord(word: string) {
    if (word === this._words) return;
    this._words = word;
    this.wordSubbject$.next(this._words);
  }
}
