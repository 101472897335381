<rocket-modal>
  <rocket-modal-header [hideCloseButton]="true">
    <div>
      <h3 class="modal-title">Alteração de Senha</h3>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <p>
      Para alterar sua senha, siga estas etapas: informe sua senha atual, defina
      uma nova senha, confirme a nova senha, insira o token e clique em
      "Continuar"
    </p>
    <form [formGroup]="form">
      <fla-input
        #inputToken
        formControlName="current_password"
        label="Senha atual"
        type="password"
        [margin]="'mb-4'"
        inputPassword
        iconSize="icon-size-sm"
        [isRequired]="true"
        requiredText="Verifique a senha atual informada"
        placeholder="Informe sua senha atual"
        [maxLength]="70"
      >
      </fla-input>

      <fla-input
        formControlName="new_password"
        label="Nova senha"
        type="password"
        [margin]="'mb-4'"
        inputPassword
        iconSize="icon-size-sm"
        [isRequired]="true"
        requiredText=""
        placeholder="Informe uma senha"
        [maxLength]="70"
      >
      </fla-input>
      <password-validation
        *ngIf="newPassword?.dirty || newPassword?.touched"
        [inputState]="newPassword.errors"
      ></password-validation>

      <fla-input
        formControlName="confirm_new_password"
        label="Confirmar nova senha"
        type="password"
        margin="mb-5"
        inputPassword
        iconSize="icon-size-sm"
        [isRequired]="true"
        [requiredText]="confirmNewPasswordError"
        placeholder="Confirme a nova senha"
        [maxLength]="70"
        [preventPaste]="true"
      >
      </fla-input>

      <div class="position-relative">
        <div class="position-absolute" [style.left.px]="50">
          <fla-icon
            name="info"
            iconType="icons-outlined"
            size="icon-size-tiny"
            [tooltip]="tokenTooltip"
            [tooltipIsHtml]="true"
          ></fla-icon>
        </div>
        <fla-input
          formControlName="token"
          label="Token"
          type="password"
          margin="mb-5"
          inputPassword
          iconSize="icon-size-sm"
          [isRequired]="true"
          placeholder="Informe aqui seu token"
          requiredText="Verifique o token"
          [maxLength]="6"
          [minLength]="6"
        >
        </fla-input>
      </div>

      <ng-container *ngIf="serviceResult.error">
        <error-card
          [message]="serviceResult.message"
          cardBorder="border-feedback-error"
        ></error-card>
      </ng-container>
    </form>
  </app-rocket-modal-body>

  <rocket-modal-footer>
    <rocket-button
      type="fill"
      label="Cancelar"
      css="mr-4"
      (rocketClick)="handleBtnAction('CANCEL')"
      btnType="button"
    ></rocket-button>
    <rocket-button
      label="Continuar"
      css="mr-4"
      (rocketClick)="handleBtnAction('SUBMIT')"
      [isDisabled]="disableSubmitButton"
      btnType="submit"
    ></rocket-button>
  </rocket-modal-footer>
</rocket-modal>
