import { AbstractControl, ValidationErrors } from '@angular/forms';

// Função para validar o formato do email usando uma expressão regular
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export function emailValidator(
  control: AbstractControl
): ValidationErrors | null {
  const valid = EMAIL_REGEX.test(control.value);
  return valid ? null : { invalidEmail: true };
}
