import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { CHART_THEME } from '@shared/components/stock-table-views';
import {
  deepClone,
  FLA_GRAPH_COLORS,
  randomId,
} from '@shared/rocket-components/utils';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import * as Highcharts from 'highcharts';
import { Subject, auditTime, debounceTime, filter, takeUntil, tap } from 'rxjs';
import { formatByTick } from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-chart-line',
  template: `<div [id]="ID_CHART" class="w-100 h-100"></div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartLineComponent implements OnDestroy {
  @Output() public mouseOver: EventEmitter<number> = new EventEmitter<number>();
  @Input() pointStart!: number;
  @Input() tickSize: number | string = 2;
  @Input() multipleData: boolean = false;
  @Input() highlightLastPointUpdate: boolean = false;
  @Input() stockAtAuction: boolean = false;
  @Input() yAxis!: any[];
  @Input() cdStock!: string;
  @Input() set data(value: number[]) {
    if (!value || !value.length) return;
    this._data = value;
    if (this._chartRef) {
      this._updateSeriesData(value);
      return;
    }
    this._subjectInitial.next();
  }
  @Input() set lineColor(value: string) {
    this._lineColor = value;
    if (this._chartRef) {
      this._chartRef.series[0].update(
        {
          type: 'line',
          color: value,
        },
        false
      );
      this._chartRef.redraw();
    }
  }
  @Input() set updateLastValue(value: number) {
    if (this._chartRef) {
      if (this.highlightLastPointUpdate) {
        this._updateLastPointWithHighlight.next(value);
        return;
      }
      this._chartRef.series[0].points[this._data.length - 1]?.update({
        y: value,
      });
    }
  }

  private _subjectInitial: Subject<void> = new Subject();
  private _updateLastPointWithHighlight = new Subject<number>();
  private _chartRef!: Highcharts.Chart;
  private _lineColor: string = CHART_COLORS.NEUTRAL_MEDIUM;
  private _data: any[] = [];
  private destroy$ = new Subject<void>();

  public ID_CHART = randomId('CHART_LINE');

  constructor(private _cdr: ChangeDetectorRef) {
    this.initChartSubject();
  }

  ngOnDestroy(): void {
    this._chartRef?.destroy();
    this._subjectInitial.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initChartSubject = () => {
    this._updateLastPointAndHighlight();
    this._subjectInitial
      .pipe(debounceTime(200), takeUntil(this.destroy$))
      .subscribe(() => this._createChart(this.ID_CHART, this._chartOptions()));
  };

  private _updateSeriesData(data: number[]): void {
    this._chartRef.series[0].update({ type: 'line', data: data });
  }

  private _createChart(
    element: string | HTMLElement,
    options: Highcharts.Options
  ) {
    Highcharts.setOptions(CHART_THEME);
    this._chartRef = Highcharts.chart(element, {
      ...options,
      credits: {
        enabled: false,
      },
    });
    this._cdr.detectChanges();
  }

  private _chartOptions(): Highcharts.Options {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const options: Highcharts.Options = {
      colors: FLA_GRAPH_COLORS['theme-trademap'],
      chart: {
        margin: [2, 2, 2, 2],
      },
      title: {
        text: '',
      },
      tooltip: {
        enabled: true,
        backgroundColor: CHART_COLORS.NEUTRAL_SMOOTHEST,
        borderColor: CHART_COLORS.BLACK,
        borderRadius: 4,
        borderWidth: 0,
        shape: 'square',
        hideDelay: 0,
        outside: true,
        style: {
          fontFamily: 'Metropolis',
        },
        formatter() {
          return formatByTick(this.y?.toString() ?? '0', +self.tickSize);
        },
      },
      subtitle: {
        text: '',
      },
      yAxis: {
        title: {
          text: '',
        },
        visible: false,
        endOnTick: true,
        startOnTick: true,
        max: null,
        min: null,
      },
      xAxis: {
        visible: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          animation: true,
          marker: {
            enabled: false,
          },
          label: {
            connectorAllowed: true,
          },
          dataLabels: {
            enabled: false,
          },
          dataGrouping: {
            enabled: false,
          },
          point: {
            events: {
              mouseOver: () => this.mouseOver.emit((this as any).y),
            },
          },
          events: {
            mouseOut: () => this.mouseOver.emit(0),
          },
          borderWidth: 0,
        },
      },
      series: [
        {
          type: 'line',
          allowPointSelect: true,
          name: 'name',
          data: deepClone(this._data),
          color: this._lineColor,
          lineWidth: 1,
          marker: {
            enabled: false,
          },
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 200,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
      accessibility: {
        enabled: false,
      },
    };
    if (this.yAxis) options.yAxis = this.yAxis;
    if (this.multipleData) options.series = this._data;
    if (this.pointStart) {
      options!.plotOptions!.series = {
        ...options!.plotOptions!.series,
        pointStart: this.pointStart,
      };
    }
    return options;
  }

  private _updateLastPointAndHighlight(): void {
    this._updateLastPointWithHighlight
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this._chartRef?.series?.length > 0),
        tap((value) => {
          const pointIndex = this._chartRef.series[0].points.length - 1;
          this._chartRef.series[0].points[pointIndex]?.update({
            y: value,
            marker: {
              enabled: true,
              fillColor: this._lineColor,
              radius: 3,
            },
          });
        }),
        auditTime(150)
      )
      .subscribe(() => {
        const pointIndex = this._chartRef.series[0].points.length - 1;
        this._chartRef.series[0].points[pointIndex]?.update({
          marker: { enabled: false },
        });
      });
  }
}
