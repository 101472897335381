import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
} from '@angular/core';
import { ALERT_MENU_ENUM, ALERT_STATUS_ENUM, IAlert } from '@core/interface';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';
import { OrdersHistoryContextMenuEvent } from '../../service/orders-history-config.service';
import { BaseContextMenuComponent } from '@shared/rocket-components/base-context-menu';

@Component({
  selector: 'app-alert-tab-context-menu',
  templateUrl: 'alert-tab-context-menu.component.html',
})
export class AlertTabContextMenuComponent
  extends BaseContextMenuComponent
  implements OnInit
{
  refComponent!: string;
  componentId!: string;
  isTour!: boolean;
  isVisible!: boolean;
  disabledReset!: boolean;
  filterIsActive!: boolean;
  isSorted!: boolean;
  isRowSelect!: boolean;
  isCompletedAlert: boolean = false;
  keyHeader!: string | null;
  rowSelect!: IAlert | null;
  menuOptions = ALERT_MENU_ENUM;
  parent_id!: string;
  isCardView: boolean = false;

  constructor(
    elementRef: ElementRef,
    cdr: ChangeDetectorRef,
    private contextMenuService: ContextMenuService
  ) {
    super(elementRef, cdr);
  }

  static openContextMenu(
    contextMenuService: ContextMenuService,
    parent_id: string,
    event: { clientX: number; clientY: number },
    isTour: boolean,
    isVisible: boolean,
    isRowSelect: boolean,
    filterIsActive: boolean,
    isSorted: boolean,
    keyHeader: string,
    disabledReset: boolean,
    isCardView: boolean
  ) {
    if (isTour) {
      const domRect = document
        .getElementsByTagName('app-orders-history')[0]
        .getClientRects()[0];
      event.clientX = domRect.x;
      event.clientY = domRect.y + 100;
    }
    contextMenuService.openContextMenu(parent_id, {
      type: 'AlertTabContextMenuComponent',
      event: event,
      configurationData: {
        isTour,
        isVisible,
        isRowSelect,
        filterIsActive,
        isSorted,
        disabledReset,
        keyHeader,
        isCardView
      },
    });
  }

  private emitEvent(action: OrdersHistoryContextMenuEvent) {
    this.contextMenuService.emitEvent(this.parent_id, action);
    this.onFocusOut();
  }

  onFocusOut() {
    this.contextMenuService.closeContextMenu(this.parent_id);
  }

  ngOnInit(): void {
    this._buildMenuOptions();
  }

  private _buildMenuOptions(): void {
    if (this.isRowSelect) {
      this.isCompletedAlert =
        this.rowSelect?.cd_status === ALERT_STATUS_ENUM.COMPLETED;
    }
  }

  handleAction(action: ALERT_MENU_ENUM): void {
    switch (action) {
      case ALERT_MENU_ENUM.EDIT_ALERT:
        this.emitEvent(OrdersHistoryContextMenuEvent.EditAlert);
        break;
      case ALERT_MENU_ENUM.REMOVE_ALERT:
        this.emitEvent(OrdersHistoryContextMenuEvent.RemoveAlert);
        break;
      case ALERT_MENU_ENUM.REOPEN_ALERT:
        this.emitEvent(OrdersHistoryContextMenuEvent.ReopenAlert);
        break;
      case ALERT_MENU_ENUM.HIDDEN_COLUMN:
        this.emitEvent(OrdersHistoryContextMenuEvent.SetInvisibleColumn);
        break;
      case ALERT_MENU_ENUM.RESET_FILTERS:
        this.emitEvent(OrdersHistoryContextMenuEvent.ResetFilter);
        break;
      case ALERT_MENU_ENUM.RESET_SORT:
        this.emitEvent(OrdersHistoryContextMenuEvent.RestartSort);
        break;
      case ALERT_MENU_ENUM.RESET_PRESET:
        this.emitEvent(OrdersHistoryContextMenuEvent.ResetDefault);
        break;
      case ALERT_MENU_ENUM.PREFERENCES:
        this.emitEvent(OrdersHistoryContextMenuEvent.OpenModalPreferences);
        break;
      case ALERT_MENU_ENUM.HELP:
        this.emitEvent(OrdersHistoryContextMenuEvent.TourHandler);
        break;
      case ALERT_MENU_ENUM.EXPORT_DATA:
        this.emitEvent(OrdersHistoryContextMenuEvent.ExportData);
        break;
      default:
        break;
    }
  }
}
