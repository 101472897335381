<ng-container *ngIf="authenticator.id !== 'TRADEMAP'; else trademapTemplate">
  <p class="fs-5">
    Fazendo isso você não poderá mais utilizar o {{ authenticator.name }} como
    segundo fator de autenticação.
  </p>
  <p class="fs-5">Para isso adicione abaixo sua senha do RocketTrader.</p>
  <fla-input
    placeholder="Informe a sua senha"
    label="Senha"
    css="my-2 m-0"
    decidePosition="suffix"
    [formControl]="userPassword"
    [autoComplete]="false"
    [requiredText]="'Informe a sua senha de acesso'"
    [type]="showPassword ? 'text' : 'password'"
  >
    <div class="suffix">
      <rocket-button type="icon" (rocketClick)="showPassword = !showPassword">
        <fla-icon
          [name]="showPassword ? 'visibility' : 'visibility_off'"
        ></fla-icon>
      </rocket-button>
    </div>
  </fla-input>
</ng-container>
<ng-template #trademapTemplate>
  <p class="fs-5">Para desconectar é preciso:</p>
  <ol class="pl-4">
    <li class="pb-3 fs-5">Entrar na sua conta no app do TradeMap</li>
    <li class="pb-3 fs-5">Clique na sua foto de perfil</li>
    <li class="pb-3 fs-5">Clique em token</li>
    <li class="pb-3 fs-5">Clique em Remover token</li>
  </ol>
</ng-template>
<div class="d-flex justify-content-end px-0">
  <ng-container *ngIf="authenticator.id !== 'TRADEMAP'; else trademapButton">
    <rocket-button
      type="fill"
      label="Talvez depois"
      (rocketClick)="goBack()"
    ></rocket-button>
    <rocket-button
      css="ml-3"
      label="Sim, desconectar"
      [loading]="loading"
      [isDisabled]="!userPassword.value || userPassword.value.length <= 6"
      (rocketClick)="disconnect()"
    ></rocket-button>
  </ng-container>
  <ng-template #trademapButton>
    <rocket-button
      css="ml-3"
      label="Ok, entendi"
      (rocketClick)="emitDisconnected()"
    ></rocket-button>
  </ng-template>
</div>
