import { ModuleWithProviders, NgModule } from '@angular/core';

import {
  config,
  INITIAL_CONFIG,
  initialConfig,
  NEW_CONFIG,
  optionsConfig,
} from './config';
import { MaskApplierService } from './mask-applier.service';
import { FlaMaskDirective } from './mask.directive';
import { FlaMaskPipe } from './mask.pipe';

/**
 * @internal
 */
export function _configFactory(
  initConfig: optionsConfig,
  configValue: optionsConfig | (() => optionsConfig)
): optionsConfig {
  return configValue instanceof Function
    ? { ...initConfig, ...configValue() }
    : { ...initConfig, ...configValue };
}
@NgModule({
  exports: [FlaMaskDirective, FlaMaskPipe],
  declarations: [FlaMaskDirective, FlaMaskPipe],
})
export class FlaMaskModule {
  public static forRoot(
    configValue?: optionsConfig | (() => optionsConfig)
  ): ModuleWithProviders<FlaMaskModule> {
    return {
      ngModule: FlaMaskModule,
      providers: [
        {
          provide: NEW_CONFIG,
          useValue: configValue,
        },
        {
          provide: INITIAL_CONFIG,
          useValue: initialConfig,
        },
        {
          provide: config,
          useFactory: _configFactory,
          deps: [INITIAL_CONFIG, NEW_CONFIG],
        },
        MaskApplierService,
      ],
    };
  }

  public static forChild(): ModuleWithProviders<FlaMaskModule> {
    return {
      ngModule: FlaMaskModule,
    };
  }
}
