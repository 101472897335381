export const adxTemplate = `
  <div class="fw-bolder">Definição</div>
  <div class="mb-3">
    O ADX é um indicador técnico utilizado na análise técnica de mercado para
    determinar a força e direção de uma tendência predominante. Além disso, o ADX
    também pode ser usado para identificar possíveis reversões de tendência,
    quando o valor do indicador começa a diminuir após atingir níveis elevados.
  </div>
  <div class="fw-bolder">Cálculo</div>
  <div class="mb-3">
    O ADX é calculado com base nos movimentos direcionais positivos e negativos de
    um ativo financeiro ao longo de um determinado período. Ele varia de 0 a 100,
    sendo que valores mais altos indicam uma tendência mais forte, enquanto
    valores mais baixos indicam uma tendência mais fraca ou ausência de tendência.
  </div>
  <div class="mb-3">
    O cálculo do ADX envolve várias etapas. Aqui está a fórmula geral para
    calcular o ADX:
  </div>
  <div class="mb-3">
    1. Calcule o True Range (TR) para cada período: <br />
    &bull; TR = máximo entre: (Alta atual - Baixa atual), |Alta atual - Fechamento
    anterior|, |Baixa atual - Fechamento anterior| <br />
    2. Calcule o Positive Directional Movement (+DM) e Negative Directional
    Movement (-DM) para cada período: <br />
    &bull; +DM = Alta atual - Alta anterior (se o resultado for positivo, caso
    contrário, 0) <br />
    &bull; -DM = Baixa anterior - Baixa atual (se o resultado for positivo, caso
    contrário, 0) <br />
    3. Suavize os valores do +DM, -DM e TR ao longo de um determinado período
    utilizando uma média móvel exponencial (EMA): <br />
    &bull; EMA de +DM = [(EMA de +DM período anterior) x (período - 1) + +DM
    atual] / período <br />
    &bull; EMA de -DM = [(EMA de -DM período anterior) x (período - 1) + -DM
    atual] / período <br />
    &bull; EMA de TR = [(EMA de TR período anterior) x (período - 1) + TR atual] /
    período <br />
    4. Calcule o Indice de Movimento Direcional Positivo (+DI) e o Indice de
    Movimento Direcional Negativo (-DI): <br />
    &bull; +DI = (EMA de +DM / EMA de TR) x 100 <br />
    &bull; -DI = (EMA de -DM / EMA de TR) x 100 <br />
    5. Calcule o Indice Direcional Médio (ADX): <br />
    &bull; DX = [(|+DI - -DI|) / (+DI + -DI)] x 100 <br />
    &bull; ADX = EMA do DX período anterior x (período - 1) + DX atual / período
  </div>
  <div class="mb-3">
    O período geralmente usado para o cálculo do ADX é 14, mas pode variar
    dependendo das preferências do trader ou das condições de mercado. O ADX é
    geralmente exibido como um único valor que varia de 0 a 100, indicando a força
    da tendência predominante. Quanto maior o valor do ADX, mais forte é a
    tendência.
  </div>
  <div class="fw-bolder">Como funciona</div>
  <div class="mb-3">
    O ADX é composto por três linhas principais: o ADX em si, que representa a
    força da tendência; a linha +DI (Positive Directional Indicator), que mostra a
    força da tendência de alta; e a linha -DI (Negative Directional Indicator),
    que mostra a força da tendência de baixa.
  </div>
  <div class="mb-3">
    Os traders e investidores utilizam o ADX para determinar se uma tendência é
    forte o suficiente para justificar uma negociação. Um valor acima de 25 é
    frequentemente considerado como um sinal de uma tendência significativa,
    enquanto valores abaixo de 20 indicam uma tendência fraca ou um mercado
    lateral.
  </div>
  <div class="mb-3">
    Além disso, o ADX também pode ser usado para identificar possíveis reversões
    de tendência, quando o valor do indicador começa a diminuir após atingir
    níveis elevados.
  </div>
  <div class="mb-3">
    É importante destacar que o ADX é um indicador de tendência e não fornece
    informações sobre a direção específica do preço. Portanto, é comumente
    utilizado em conjunto com outros indicadores e ferramentas de análise técnica
    para tomar decisões de negociação mais informadas.
  </div>
  <div class="mb-3">
    <img class="w-75" src="/assets/indicators/adx-como-funciona.png" />
  </div>
  <div class="fw-bolder">Como configurar</div>
  <div class="fw-bolder">ADX Suavizado</div>
  <div class="mb-3">
    A suavização é aplicada ao cálculo do ADX para tornar o indicador mais estável
    e reduzir o ruído nos sinais. Por padrão é 14.
  </div>
  <div class="fw-bolder">Comprimento DI</div>
  <div class="mb-3">
    O comprimento DI é uma configuração ajustável e pode variar de acordo com as
    preferências do trader ou as condições do mercado. O valor padrão comumente
    usados para o comprimento DI é 14.
  </div>
  <div class="mb-3">
    <img
      class="image-indicators-input"
      src="/assets/indicators/adx-entrada.png"
    />
  </div>
  <div class="fw-bolder">Estilo</div>
  <div class="text-decoration-underline">Linha</div>
  <div class="mb-3">
    Pode alternar a visibilidade do ADX, bem como a visibilidade de uma linha de
    preço que mostra o valor atual real da ADX. Também pode selecionar a cor, a
    espessura e o estilo da linha do ADX.
  </div>
  <div class="mb-3">
    <img
      class="image-indicators-input"
      src="/assets/indicators/adx-estilo.png"
    />
  </div>
`;
