import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ISearchStock } from '@core/interface';
import { QuoteData } from '@shared/channel/interface/quote.channel.interface';
import {
  bigValueFormatter,
  execFormatFinancial,
  formatterNumber,
} from 'src/app/utils/utils.functions';
import { ITigerChartMessage } from './tiger-chart-message.interface';
import { BODY_TEMPLATE } from './tiger-chart-message.enum';

@Component({
  selector: 'app-tiger-chart-message',
  templateUrl: './tiger-chart-message.component.html',
  styleUrls: ['./tiger-chart-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TigerChartMessageComponent {
  auctionVariation = '-';
  auctionPrice = '-';
  auctionSenseNotAnswered = '-';
  typeAuctionQttyNotAnswered = '-';
  auctionQttyNotAnswered = '-';
  auctionQtty = '-';
  openHourProgram = '-';
  auctionMessage = '-';
  public timer = '-';
  private time = 0;
  private timeClean!: any;
  private lastOpenHour!: string;
  public readonly templates = BODY_TEMPLATE;
  @Input() options!: ITigerChartMessage;
  @Input() stock!: ISearchStock;
  @Output() closeMessage: EventEmitter<any> = new EventEmitter();
  @Output() goToNextActiveStock: EventEmitter<any> = new EventEmitter();

  @Input() set quoteData(quote: QuoteData) {
    if (!this.stock || !quote) {
      return;
    }
    this.auctionVariation = `${formatterNumber(+quote.variacao_leilao)}%`;
    this.auctionPrice = execFormatFinancial(this.stock, quote.preco_leilao);
    this.auctionSenseNotAnswered = quote.sentido_leilao_nao_atendida;
    this.typeAuctionQttyNotAnswered = `${
      this.auctionSenseNotAnswered == 'A' ? 'C' : 'V'
    }NA `;
    this.auctionQttyNotAnswered = `${bigValueFormatter(
      quote.qtde_leilao_nao_atendida
    )}`;
    this.auctionQtty = bigValueFormatter(quote.qtde_leilao);
    this.openHourProgram = quote.hora_abert_program;
    this.auctionMessage = quote.motivo_leilao_headline
      ? `<div class="d-flex flex-column">
        <span class="fw-bolder">Motivo do Leilão</span>
        <span class="fw-normal">${quote.motivo_leilao_headline}</span>
      </div>`
      : '-';
    clearTimeout(this.timeClean);
    this.calculateTimeDifference();
    this.cdr.detectChanges();
  }

  constructor(private cdr: ChangeDetectorRef) {}

  private calculateTimeDifference(): void {
    if (this.openHourProgram && this.openHourProgram !== '-') {
      this.lastOpenHour = this.openHourProgram;
      this.setTime(this.openHourProgram);
    } else if (this.lastOpenHour) {
      this.setTime(this.lastOpenHour);
    }
    this.countdown();
  }

  private setTime(openHour: string) {
    if (!openHour) return;
    const { data, date1, date2 } = this.getDates(openHour);
    date2.setHours(+data[0], +data[1], +data[2]);
    this.time = (date2.getTime() - date1.getTime() + 1000) / 1000;
  }

  private countdown(): void {
    if (this.time > -1) {
      this.setTimer(this.time);
      this.timeClean = setTimeout(() => this.countdown(), 1000);
      this.time--;
    } else if (this.lastOpenHour) {
      const { data, date1, date2 } = this.getDates(this.lastOpenHour);
      date2.setHours(+data[0], +data[1], +data[2]);
      const time = (date1.getTime() - date2.getTime() + 1000) / 1000;
      this.setTimer(time);
      this.timeClean = setTimeout(() => this.countdown(), 1000);
    }
    this.cdr.detectChanges();
  }

  private setTimer(time: number) {
    let min: any = time / 60;
    let seg: any = time % 60;
    +min < 10 && (min = `0${min}`);
    +seg < 10 && (seg = `0${seg}`);
    min = `${min}`.substring(0, 2);
    seg = `${seg}`.substring(0, 2);
    this.timer = `${min}:${seg}`;
    this.cdr.detectChanges();
  }

  private getDates(dateToSplit: string) {
    const data = dateToSplit.split(':');
    const date1 = new Date();
    const date2 = new Date();
    date2.setHours(+data[0], +data[1], +data[2]);
    return { data, date1, date2 };
  }

  hideStockMessage() {
    clearTimeout(this.timeClean);
    this.closeMessage.emit();
  }

  goStock() {
    this.goToNextActiveStock.emit();
  }
}
