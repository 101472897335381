import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { HotkeysService } from '@shared/rocket-components/services/hotkeys/hotkeys.service';

@Component({
  selector: 'app-inactivity-alert',
  templateUrl: './inactivity-alert.component.html',
  styleUrls: ['./inactivity-alert.component.scss'],
})
export class InactivityAlertComponent implements OnChanges {
  @Input() show = false;
  @Output() resetInactivity: EventEmitter<null> = new EventEmitter();
  warningMonitor = false;

  constructor(
    private _hotkeysService: HotkeysService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { show } = changes;
    if (show && show.currentValue === true) {
      this.warningMonitor = true;
      this._hotkeysService.listener({
        key: ['esc', 'enter'],
        handlerDown: this.imHere,
        actions: 'keydown',
      });
      this._cdr.detectChanges();
    }
  }

  imHere = () => {
    this._hotkeysService.unBindEvent({
      key: ['esc', 'enter'],
      actions: 'keydown',
    });
    this.warningMonitor = false;
    this.resetInactivity.emit();
    this._cdr.detectChanges();
  };
}
