import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  ONBOARDING_STEPS,
  SUPERDOM_STEPS,
  TIMES_TRADES_STEPS,
  STOCK_TABLE_STEPS,
  BOOK_STEPS,
  STOCK_TRADE_STEPS,
  CHART_STEPS,
  ORDERS_HISTORY_STEPS,
} from './steps';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { ADD_IN_STEPS } from './steps/addin.step';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const introJS = require('intro.js/intro.js');

@Injectable({
  providedIn: 'root',
})
export class IntrojsService {
  private _displayingContext: boolean = false;
  private _displayingDropDown: boolean = false;

  private _introJS!: any;
  private _introStep$: Subject<any> = new Subject();
  private _defaultOptions = {
    hidePrev: true,
    exitOnOverlayClick: false,
    exitOnEsc: true,
    doneLabel: `<span class="text-black">Ok</span>`,
    nextLabel: `<span class="text-brand-primary">Próximo</span>`,
    prevLabel: `Voltar`,
  };

  public onStart = () => this._introStep$.asObservable();

  private _findStepIndex(stepEnum: string, steps: any[]): number {
    return steps.findIndex((step) => step.enum && step.enum === stepEnum);
  }

  exit() {
    this._introJS && this._introJS.exit(true);
  }

  private preChange(stepIndex: number) {
    !isNullOrUndefined(this._introJS._introItems[stepIndex].preChange) &&
      this._introJS._introItems[stepIndex].preChange();
    this._introJS.refresh();
  }

  private contextMenu(stepInfos: any, force: boolean = false) {
    if (
      stepInfos.enum === 'CONTEXT_MENU' &&
      (!this._displayingContext || force)
    ) {
      this._introStep$.next({ action: 'DISPLAY_CONTEXT' });
      this._displayingContext = true;
    } else {
      this._introStep$.next({ action: 'HIDE_CONTEXT' });
      this._displayingContext = false;
    }
  }

  private instanceIntroJS(steps: any) {
    this._introJS = introJS();
    this._introJS
      .onchange(() => this._introJS.refresh())
      .onafterchange(() => this._introJS.refresh())
      .onexit(() => this._introStep$.next({ action: 'CLOSED' }))
      .oncomplete(() => this._introStep$.next({ action: 'CLOSED' }))
      .setOptions(Object.assign(this._defaultOptions, { steps }));
  }

  private getDoc(componentID: string): Element {
    return document.querySelectorAll(`[id="${componentID}"].rt-component`)[0]!;
  }

  onboardingIntro() {
    this._introJS = introJS();
    this._introJS
      .onbeforechange(() => {
        const STEP_INDEX = this._introJS.currentStep()!;
        if (!ONBOARDING_STEPS[STEP_INDEX]) {
          this._introJS.exit(true);
          return;
        }
        this.preChange(STEP_INDEX);
      })
      .onexit(() =>
        this._introStep$.next({
          step: this._introJS.currentStep()!,
          concluded: false,
        })
      )
      .oncomplete(() =>
        this._introStep$.next({
          step: this._introJS.currentStep()!,
          concluded: true,
        })
      )
      .setOptions(
        Object.assign(this._defaultOptions, { steps: ONBOARDING_STEPS })
      )
      .start();
  }

  superDomIntro(
    componentID: string,
    forceDisplayContextMenu: any,
    handlerFunc: any
  ) {
    const STEPS = SUPERDOM_STEPS(
      this.getDoc(componentID),
      forceDisplayContextMenu,
      handlerFunc
    );
    this.instanceIntroJS(STEPS);
    this._introJS
      .onbeforechange(() => {
        const STEP_INDEX = this._introJS.currentStep()!;
        if (!STEPS[STEP_INDEX]) {
          this._introJS.exit(true);
          return;
        }
        // CONTEXT CONTROL
        this.contextMenu(STEPS[STEP_INDEX]);
        // UPDATE ELEMENTS
        this.preChange(STEP_INDEX);
      })
      .start();
  }

  timesAndTrade(componentID: string) {
    const STEPS = TIMES_TRADES_STEPS(this.getDoc(componentID));
    this.instanceIntroJS(STEPS);
    this._introJS
      .onbeforechange(() => {
        const STEP = this._introJS.currentStep();
        if (!STEPS[STEP]) {
          this._introJS.exit(true);
          return;
        }
        this.contextMenu(STEPS[STEP], true);
        this.preChange(STEP);
      })
      .start();
  }

  stockTable(componentID: string) {
    const STEPS = STOCK_TABLE_STEPS(this.getDoc(componentID));
    const OPEN_CHANGE_VIEW_DP = this._findStepIndex('CHANGE_VIEW', STEPS);
    const OPEN_LISTS = this._findStepIndex('LISTS', STEPS);
    this.instanceIntroJS(STEPS);
    this._introJS
      .onbeforechange(() => {
        const STEP = this._introJS.currentStep();
        if (!STEPS[STEP]) {
          this._introJS.exit(true);
          return;
        }
        // DROPDOWN LIST
        if (STEP === OPEN_CHANGE_VIEW_DP || STEP === OPEN_LISTS) {
          this._displayingDropDown = true;
          this._introStep$.next({
            action: 'DISPLAY_DROPDOWN',
            isChangeView: STEP === OPEN_CHANGE_VIEW_DP,
          });
        } else if (this._displayingDropDown) {
          this._displayingDropDown = false;
          this._introStep$.next({ action: 'HIDE_DROPDOWN' });
        }
        //CONTEXT
        this.contextMenu(STEPS[STEP]);
        // PRE CHANGE
        this.preChange(STEP);
      })
      .start();
  }

  book(componentID: string, forceDisplayContexMenu: any) {
    const STEPS = BOOK_STEPS(this.getDoc(componentID), forceDisplayContexMenu);
    this.instanceIntroJS(STEPS);
    this._introJS
      .onbeforechange(() => {
        const STEP = this._introJS.currentStep();
        if (!STEPS[STEP]) {
          this._introJS.exit(true);
          return;
        }
        //CONTEXT
        this.contextMenu(STEPS[STEP], true);
        this.preChange(STEP);
      })
      .start();
  }

  stocktrade(componentID: string) {
    const STEPS = STOCK_TRADE_STEPS(this.getDoc(componentID));
    this.instanceIntroJS(STEPS);
    this._introJS
      .onbeforechange(() => {
        const STEP = this._introJS.currentStep();
        if (!STEPS[STEP]) {
          this._introJS.exit(true);
          return;
        }
        if (this._introJS._introItems[STEP]?.toggleStockTradeType)
          this._introStep$.next({ action: 'TOGGLE_TYPE' });
        if (this._introJS._introItems[STEP]?.preChange)
          this._introJS._introItems[STEP].preChange();
        this._introJS.refresh();
      })
      .start();
  }

  chart(componentID: string, forceOpenContextMenu: any) {
    const STEPS = CHART_STEPS(this.getDoc(componentID), forceOpenContextMenu);
    this.instanceIntroJS(STEPS);
    this._introJS
      .onbeforechange(() => {
        const STEP = this._introJS.currentStep();
        if (!STEPS[STEP]) {
          this._introJS.exit(true);
          return;
        }
        this.contextMenu(STEPS[STEP], true);
        this.preChange(STEP);
      })
      .start();
  }

  ordersHistory(componentID: string): void {
    const STEPS: any[] = ORDERS_HISTORY_STEPS(this.getDoc(componentID));
    this.instanceIntroJS(STEPS);
    this._introJS
      .onbeforechange(() => {
        const STEP_INDEX = this._introJS.currentStep();
        if (!STEPS[STEP_INDEX]) {
          this._introJS.exit(true);
          return;
        }
        // CONTEXT CONTROL
        this.contextMenu(STEPS[STEP_INDEX]);
        // UPDATE ELEMENTS
        this.preChange(STEP_INDEX);
      })
      .start();
  }

  addIn(): void {
    const STEPS: any[] = ADD_IN_STEPS();
    this.instanceIntroJS(STEPS);
    this._introJS
      .onbeforechange(() => {
        const STEP_INDEX = this._introJS.currentStep();
        if (!STEPS[STEP_INDEX]) {
          this._introJS.exit(true);
          return;
        }
        // CONTEXT CONTROL
        this.contextMenu(STEPS[STEP_INDEX]);
        // UPDATE ELEMENTS
        this.preChange(STEP_INDEX);
      })
      .start();
  }
}
