import { Component, EventEmitter, Output } from '@angular/core';
import { TAB_FILTERS } from '../../consts/filters';
import { RankingBrokerService } from '../../services/ranking-broker.service';
import { RANKING_BROKER_FILTERS } from '../../enum/ranking-broker.enum';

@Component({
  selector: 'app-filter-ranking-broker',
  templateUrl: './filter-ranking.component.html',
})
export class FilterRankingComponent {
  @Output() changeTypeFilter = new EventEmitter<RANKING_BROKER_FILTERS>();
  public filterOptions = TAB_FILTERS;

  constructor(private _rankingBrokerService: RankingBrokerService) {}

  selectViewType(code: RANKING_BROKER_FILTERS) {
    this.filterOptions.map((view) => (view.active = code === view.code));
    this._rankingBrokerService.viewTypeSelected = code;
    this.changeTypeFilter.emit(code);
  }
}
