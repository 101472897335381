<div *ngIf="showComponent" class="d-flex account-selector-content">
  <app-strategy-selector></app-strategy-selector>
  <span class="vr align-self-center mx-2 bg-neutral-stronger"></span>
  <ng-container *ngIf="simulator.enabled || accounts.length">
    <app-daytrade-selector
      #daytradeSelector
      [account]="accountSelected"
    ></app-daytrade-selector>
    <span class="vr align-self-center mx-2 bg-neutral-stronger"></span>
  </ng-container>

  <app-dropdown
    *ngIf="accounts && isSeletorAccount && accountSelected"
    css="d-flex justify-content-center"
  >
    <a
      type="button"
      class="nav-link p-2 lh-sm round-top dropdown-toggle d-inline-block text-truncate"
      [style.max-width.px]="80"
      appDropdownButton
    >
      {{ accountSelected.account_number }}
    </a>
    <div class="list-max-height">
      <div *ngFor="let account of accountSelected.accounts">
        <a
          class="dropdown-item"
          type="button"
          (click)="selectAccountWhenMany(account)"
        >
          {{ account }}
        </a>
      </div>
    </div>
  </app-dropdown>

  <span id="stepSeletorConta" class="d-flex align-items-center">
    <app-dropdown
      menuAlign="dropdown-menu-left"
      css="d-flex justify-content-center"
      *ngIf="accounts && accountSelected && accountSelected?.label"
    >
      <a
        type="button"
        class="nav-link p-2 lh-sm round-top d-inline-block text-truncate"
        [ngClass]="{
          'btn btn-sm simulator-active default-cursor': simulator.enabled,
          'dropdown-toggle': !simulator.enabled
        }"
        [style.max-width.px]="160"
        appDropdownButton
        [tooltip]="simulator.enabled ? simulatorTooltip : accountSelected.label"
        [tooltipIsHtml]="true"
        [tooltipIsAdaptive]="true"
        tooltipPosition="bottom"
      >
        {{ accountSelected.label }}
      </a>

      <ng-container *ngIf="!simulator.enabled">
        <div class="list-max-height">
          <ng-container *ngFor="let account of accounts">
            <div *ngIf="account" [title]="account?.label">
              <a
                class="dropdown-item"
                type="button"
                (click)="selectAccount(account)"
              >
                {{ account?.label }}
              </a>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </app-dropdown>
  </span>

  <ng-container *ngIf="!simulator.enabled && displayConnectBroker">
    <button
      type="button"
      class="btn btn-fill-primary fs-6 mr-2 p-0 remove-shadow"
      [tooltipIsAdaptive]="false"
      [tooltipIsHtml]="true"
      tooltipPosition="bottom"
      [tooltip]="connectBrokersTooltip"
      (click)="openModalConnectBrokers()"
    >
      Conectar parceiro
    </button>
  </ng-container>

  <div id="stepSimulador">
    <rocket-button
      *ngIf="simulator && showSimulator"
      type="icon"
      (rocketClick)="changeSimulator()"
      [tooltip]="simulatorTooltip"
      [tooltipIsHtml]="true"
      [tooltipIsAdaptive]="true"
      tooltipPosition="bottom"
    >
      <fla-icon [name]="simulator.icon" [css]="simulator.class"></fla-icon>
    </rocket-button>
  </div>
</div>
