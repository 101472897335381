<app-market-summary-header
  [refComponent]="refComponent"
  [component]="component"
></app-market-summary-header>
<div
  class="bg-neutral-stronger w-100 overflow-auto px-1 pt-2 pb-4"
  [ngClass]="isDesktop ? 'component-height' : 'h-100'"
>
  <app-news-carousel></app-news-carousel>
  <app-list-stock-cards [refComponent]="refComponent"></app-list-stock-cards>
  <app-high-low-stocks
    [refComponent]="refComponent"
    [linked]="link"
  ></app-high-low-stocks>
  <div [style.height.px]="250" class="px-1">
    <div class="d-flex flex-column h-100">
      <div class="d-flex h-100 justify-content-between mt-2 w-100">
        <div class="col-events position-relative">
          <span class="align-items-center d-flex fw-bold">
            <span
              class="mr-2 cursor-pointer"
              [ngClass]="{ 'text-brand-primary': calendarSelected }"
              (click)="changeCalendarSelected(true)"
            >
              Calendário
            </span>
            <span
              class="cursor-pointer"
              [ngClass]="{ 'text-brand-primary': !calendarSelected }"
              (click)="changeCalendarSelected(false)"
            >
              Notícias
            </span>

            <fla-icon
              name="open_in_new"
              size="icon-size-micro"
              css="ml-1 cursor-pointer text-neutral-medium"
              (click)="openModal(calendarSelected ? 'calendar' : 'news')"
            ></fla-icon>
          </span>
          <ng-container *ngIf="calendarSelected; else newsSelected">
            <ng-container *ngIf="calendarList.length">
              <fla-card
                class="d-flex card-border"
                *ngFor="let _list of calendarList"
              >
                <fla-card-custom
                  #card
                  css="w-100 px-0 py-0 bg-neutral-strong mt-2"
                >
                  <div class="card-body">
                    <div class="d-flex pl-1 pt-1" [style.min-height.px]="46">
                      <div class="mr-2 mt-1">
                        <img class="image-country" [src]="_list.image_url" />
                      </div>
                      <div>
                        <p class="fw-bold fs-5 mb-0 limit-text">
                          {{ _list.headline }}
                        </p>
                        <p class="fw-bold limit-text fs-6 mb-0">
                          <ng-container *ngIf="_list.previusLabel">
                            {{ _list.previusLabel }}
                          </ng-container>
                          <ng-container *ngIf="_list?.currentLabelCompleted">
                            {{ _list.currentLabelCompleted }}
                          </ng-container>
                          <ng-container *ngIf="_list?.period">
                            ({{ _list.period }})
                          </ng-container>
                        </p>
                      </div>
                    </div>
                    <div class="fs-6 pl-1 pb-1">
                      {{ _list?.date }} |
                      {{ _list?.time | date : 'HH:mm' }}
                      <span *ngIf="_list.institution"
                        >| {{ _list.institution }}</span
                      >
                    </div>
                  </div>
                </fla-card-custom>
              </fla-card>
            </ng-container>
            <div
              class="bg-neutral-strong fs-6 mt-2 px-3 py-3 round-md"
              *ngIf="!calendarList.length"
            >
              Não há evento cadastrado.
            </div>
          </ng-container>
          <ng-template #newsSelected>
            <app-mini-cards-list
              [lists]="lastNewsList"
              [hasEvents]="!calendarSelected"
            ></app-mini-cards-list>
          </ng-template>
        </div>
        <div class="col-events position-relative">
          <span class="align-items-center d-flex fw-bold position-relative">
            Proventos
            <fla-icon
              name="open_in_new"
              size="icon-size-micro"
              css="ml-1 cursor-pointer text-neutral-medium"
              (click)="openModal('proceeds')"
            ></fla-icon>
            <div
              class="d-flex align-items-center fs-6 position-absolute right-0 top-0 h-100"
            >
              <span
                class="mr-1 cursor-pointer"
                [ngClass]="{ 'text-brand-primary': dtComSelected }"
                (click)="changeDtComSelected(true)"
              >
                Dt. Com
              </span>
              <span
                class="cursor-pointer"
                [ngClass]="{ 'text-brand-primary': !dtComSelected }"
                (click)="changeDtComSelected(false)"
              >
                Dt. Pagto
              </span>
            </div>
          </span>

          <app-mini-cards-list [lists]="proceedsList"></app-mini-cards-list>
        </div>
        <div class="col-events position-relative">
          <span class="align-items-center d-flex fw-bold">
            <span
              class="mr-2 cursor-pointer"
              [ngClass]="{ 'text-brand-primary': eventsSelected }"
              (click)="changeEventsSelected(true)"
            >
              Eventos
            </span>
            <span
              class="cursor-pointer"
              [ngClass]="{ 'text-brand-primary': !eventsSelected }"
              (click)="changeEventsSelected(false)"
            >
              CVM
            </span>
            <fla-icon
              name="open_in_new"
              size="icon-size-micro"
              css="ml-1 cursor-pointer text-neutral-medium"
              (click)="openModal(eventsSelected ? 'events' : 'cvm')"
            ></fla-icon>
          </span>
          <app-mini-cards-list
            [lists]="eventsSelected ? eventsList : cvmList"
            [hasEvents]="!eventsSelected"
          ></app-mini-cards-list>
        </div>
      </div>
    </div>
  </div>
</div>
