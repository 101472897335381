import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatTickSize } from './format-tick-size.pipe';

@NgModule({
  declarations: [FormatTickSize],
  imports: [CommonModule],
  exports: [FormatTickSize],
  providers: [],
})
export class FormatTickSizeModule {}
