<div
  class="w-100 bg-neutral-stronger"
  [ngClass]="isDesktop ? 'h-100' : 'grid-height'"
>
  <ng-container *ngIf="isLoading; else rentGridContent">
    <app-loading></app-loading>
  </ng-container>

  <ng-template #rentGridContent>
    <rocket-grid
      height="100%"
      [refComponent]="'RENT_GRID_' + gridRef"
      fieldIndexer="id_stock"
      [noRowsTemplate]="emptyDataMessage"
      rowSelection="single"
      [columnDefs]="columnDefs"
      [rowData]="rowData"
      [suppressDragLeaveHidesColumns]="false"
      [sortingOrder]="sortingOrder"
      [sortByField]="sortByField"
      (flaSelectRow)="onSelectedRow($event)"
      (onHeaderSortChange)="onSortChange($event)"
      (flaColumnResized)="onColumnResized($event)"
    ></rocket-grid>
  </ng-template>
</div>
