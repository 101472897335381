import { Subscription } from 'rxjs';

export function FlaUnsubscribe(target: any, propertyKey: string): void {
  let propertyValue: any;
  // tslint:disable-next-line: no-string-literal
  const original = target['ngOnDestroy'];

  // tslint:disable-next-line: no-string-literal
  target['ngOnDestroy'] = function (...args: any) {
    if (propertyValue) {
      if (propertyValue instanceof Array) {
        propertyValue.forEach((prop: any) => {
          prop.unsubscribe();
        });
      } else {
        propertyValue.unsubscribe();
      }
      // @ts-ignore
      original?.apply(this, args);
    }
  };

  function getter(): any {
    return propertyValue;
  }

  function setter(value: any): void {
    propertyValue = value;
  }

  Object.defineProperty(target, propertyKey, {
    get: getter,
    set: setter,
    enumerable: true,
    configurable: true,
  });
}

export class FlaUsubscribe {
  private until$: Subscription[] = [];
  public set set(value: Subscription) {
    this.until$.push(value);
  }

  unsubscribe(): void {
    this.until$.forEach((sub) => sub.unsubscribe());
  }
}
