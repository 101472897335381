import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { INewsGroup } from '@core/interface/news-agency.interface';

@Component({
  selector: 'app-news-search',
  templateUrl: './news-search.component.html',
  styleUrls: ['./news-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsSearchComponent implements OnChanges {
  @Input() newsGroup!: INewsGroup;
  @Input() selectedTagId!: string;
  @Input() searchText!: string;
  @Output() tagSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('scroll') scroll!: ElementRef;
  public formControl = new FormControl();

  ngAfterViewInit() {
    this._initialTagScroll();
    this.formControl.setValue(this.searchText);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { newsGroup } = changes;

    if (newsGroup && newsGroup.currentValue && !newsGroup.firstChange)
      this.scroll.nativeElement.scrollLeft = 0;
  }

  searchNews(event: any) {
    this.search.emit(event.target.value);
  }

  scrollBackward() {
    this.scroll.nativeElement.scrollLeft > 0 &&
      (this.scroll.nativeElement.scrollLeft -= 50);
  }
  scrollForward() {
    this.scroll.nativeElement.scrollLeft += 50;
  }

  selectTag(event: any, tag: string) {
    this.tagSelected.emit(tag);

    this._scrollToTag(event.srcElement);
  }

  private _initialTagScroll() {
    if (!this.selectedTagId) return;

    const tagId = `tag-${this.selectedTagId}`;
    for (
      let index = 0;
      index < this.scroll.nativeElement.children.length;
      index++
    ) {
      const tag = this.scroll.nativeElement.children[index];
      if (tag.id == tagId) {
        this._scrollToTag(tag);
        return;
      }
    }
  }

  private _scrollToTag(tagElement: HTMLElement) {
    const rect = this.scroll.nativeElement.getBoundingClientRect();

    const tagPosition = tagElement.offsetLeft;
    const clientPercentage = rect.width * 0.3;
    const widthPlusScroll = rect.width + this.scroll.nativeElement.scrollLeft;
    if (tagPosition > widthPlusScroll - clientPercentage) {
      this.scroll.nativeElement.scrollLeft =
        tagElement.offsetLeft - rect.width / 2;
    }

    const scrollMinusWidth =
      this.scroll.nativeElement.scrollLeft + clientPercentage;
    if (tagPosition < scrollMinusWidth) {
      this.scroll.nativeElement.scrollLeft =
        tagElement.offsetLeft - rect.width / 2;
    }
  }
}
