export const MIN_ACCEPTABLE_AGE = 14;
export const MAX_ACCEPTABLE_AGE = 100;

export function isValidDateFormat(dateString: string): boolean {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  return regex.test(dateString);
}

export function parseDate(dateString: string): Date | null {
  const [year, month, day] = dateString.split('-');
  const parsedYear = parseInt(year);
  const parsedMonth = parseInt(month) - 1;
  const parsedDay = parseInt(day);

  if (isNaN(parsedYear) || isNaN(parsedMonth) || isNaN(parsedDay)) {
    return null;
  }

  const currentYear = new Date().getFullYear();
  const minValidYear = 1900;

  if (parsedYear < minValidYear || parsedYear > currentYear) {
    return null;
  }

  return new Date(parsedYear, parsedMonth, parsedDay);
}

export function calculateAge(birthDate: Date, currentDate: Date): number {
  const diff = currentDate.getTime() - birthDate.getTime();
  const ageDate = new Date(diff);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function removeNonNumericChars(str: string) {
  const regex = /\D/g;
  return str.replace(regex, '');
}

export function isAdultAge(dateString: string): boolean {
  const currentDate = new Date();
  const inputDate = parseDate(dateString);
  if (!inputDate) return false;
  const age = calculateAge(inputDate, currentDate);
  return age >= MIN_ACCEPTABLE_AGE && age <= MAX_ACCEPTABLE_AGE;
}
