import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlaCollapseDirective } from './collapse.directive';

@NgModule({
  declarations: [FlaCollapseDirective],
  imports: [CommonModule],
  exports: [FlaCollapseDirective],
  providers: [],
})
export class FlaCollapseModule {}
