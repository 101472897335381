import { Injectable } from '@angular/core';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import * as Highcharts from 'highcharts';

@Injectable({
  providedIn: 'root',
})
export class PerformanceDonutHighchartService {
  initGraph(refComponent: string, callback?: Highcharts.ChartCallbackFunction) {
    const graph = Highcharts.chart(
      refComponent,
      {
        loading: {
          labelStyle: {
            opacity: 1,
          },
          style: {
            backgroundColor: '#353546',
          },
        },
        credits: {
          enabled: false,
          style: {
            display: 'none',
            margin: 0,
            padding: '0',
          },
        },
        tooltip: {
          enabled: false,
        },
        series: [],
        chart: {
          type: 'variablepie',
          backgroundColor: '#FFFFFF00',
          margin: [0, 0, 0, 0],
          height: 170,
        },
        plotOptions: {
          pie: {
            borderWidth: 0,
            dataLabels: {
              formatter: function () {
                return this.y
                  ? `
                ${this.key}<br/><b class="text-light">${this.y?.toFixed(
                      0
                    )} | ${(this.point as any).z
                      ?.toFixed(2)
                      .replace('.', ',')}%</b>`
                  : '';
              },
              color: CHART_COLORS.NEUTRAL_SMOOTHEST,
              distance: '5%',
              borderWidth: 0,
              style: {
                fontSize: '10px',
              },
            },
          },
        },
        title: this.getTitle(),
      },
      callback
    );
    return graph;
  }

  getTitle = (isPlural?: boolean): Highcharts.TitleOptions => ({
    text: `<br/><br/><span class="text-body">${
      isPlural ? 'Operações' : 'Operação'
    }</span>`,
    style: {
      textAlign: 'center',
      fontSize: '10px',
      color: CHART_COLORS.NEUTRAL_SMOOTHEST,
    },
    useHTML: true,
    verticalAlign: 'middle',
    floating: true,
  });
}
