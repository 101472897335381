import { Dictionary } from '@core/models';
import {
  IAuctionCountFields,
  IIndexStock,
  IQuoteFields,
} from '../interface/stocks-at-auction-by-index.interface';

export const AUCTIONING_CHEETAH_ITEM = 'AUCTIONING.COUNT';

export const INDEXES: IIndexStock[] = [
  { cdStock: 'GERAL', qtty: 0 },
  { cdStock: 'IBOV', qtty: 0 },
  { cdStock: 'IBXX', qtty: 0 },
  { cdStock: 'IFIX', qtty: 0 },
  { cdStock: 'IDIV', qtty: 0 },
  { cdStock: 'SMLL', qtty: 0 },
  { cdStock: 'IEEX', qtty: 0 },
  { cdStock: 'ISEE', qtty: 0 },
  { cdStock: 'IMOB', qtty: 0 },
  { cdStock: 'IBXL', qtty: 0 },
  { cdStock: 'ICON', qtty: 0 },
  { cdStock: 'IBSD', qtty: 0 },
  { cdStock: 'ICO2', qtty: 0 },
  { cdStock: 'IGCX', qtty: 0 },
  { cdStock: 'IGCT', qtty: 0 },
  { cdStock: 'ITAG', qtty: 0 },
  { cdStock: 'IGNM', qtty: 0 },
  { cdStock: 'MLCX', qtty: 0 },
  { cdStock: 'IFNC', qtty: 0 },
  { cdStock: 'INDX', qtty: 0 },
  { cdStock: 'IMAT', qtty: 0 },
  { cdStock: 'UTIL', qtty: 0 },
  { cdStock: 'BDRX', qtty: 0 },
  { cdStock: 'IBRA', qtty: 0 },
];

export const createIndexesListDict = () => {
  const dict = new Dictionary<IIndexStock>();
  dict.bulkData('cdStock', INDEXES);
  return dict;
};

export const MOVERS_AUCTIONING_COUNT_MOCK: IAuctionCountFields[] = [
  {
    command: 'ADD',
    key: 'IBOV',
    valor: '1',
  },
  {
    command: 'ADD',
    key: 'IBXX',
    valor: '0',
  },
  {
    command: 'ADD',
    key: 'UTIL',
    valor: '5',
  },
  {
    command: 'ADD',
    key: 'UTIL',
    valor: '5',
  },
  {
    command: 'ADD',
    key: 'EOS',
    valor: '',
  },
];

export const createMoversAuctioningCountDict = () => {
  const dict = new Map<string, IAuctionCountFields[]>();
  dict.set(AUCTIONING_CHEETAH_ITEM, MOVERS_AUCTIONING_COUNT_MOCK);
  return dict;
};

export const STOCKS_AT_AUCTION_BY_INDEX: IQuoteFields[] = [
  {
    item: 'LMED3',
    cd_stock: 'LMED3',
    channel: 'QUOTE',
    tick_size_denominator: '2',
    preco_ultimo: '0.0000',
    preco_fechamento: '0.0000',
    variacao_dia: '0',
    variacao_ant_dia: '0.00000',
    var_fech_anterior: '0.00',
    vl_fech_ant_essa_sem: '0.00000',
    vl_fech_ant_esse_mes: '0.00000',
    vl_fech_ant_esse_ano: '0.00000',
    vl_fech_ant_sem: '0.00000',
    vl_fech_ant_mes: '0.00000',
    vl_fech_ant_ano: '0.00000',
    volume: '0.0',
    arrow_font_hex: '#1A1B23',
    arrow_hex: '#F0960F',
    situacao: 'LEILAO',
    precoDiaAnt: '0.0000',
    qtde_negocios: '0',
    relatorio_forca_compra: '25.00',
    relatorio_forca_venda: '25.00',
    sentido_leilao_nao_atendida: '0',
    contract_multiplier: '1.0',
    valor_melhor_oferta_venda: '0',
    valor_melhor_oferta_compra: '0',
    min_price_increment: '0.01',
    lote_padrao: '100',
    nome_companhia: 'LIFEMED',
    auctionInfos: {
      situacao: 'LEILAO',
      sentido_leilao_nao_atendida: '0',
      variacao_dia: '0',
      hora_abert_program: '0',
      preco_leilao: '0',
      qtde_leilao: '0',
      qtde_leilao_nao_atendida: '0',
      variacao_leilao: '0',
    },
    preco_abertura: '0',
    preco_maximo: '0',
    preco_minimo: '0',
    hora_abert_program: '0',
    motivo_leilao_headline: '0',
    preco_leilao: '0',
    qtde_leilao: '0',
    qtde_leilao_nao_atendida: '0',
    variacao_leilao: '0',
  },
  {
    item: 'MMAQ3',
    cd_stock: 'MMAQ3',
    channel: 'QUOTE',
    tick_size_denominator: '2',
    preco_ultimo: '0.0000',
    preco_fechamento: '0.0000',
    variacao_dia: '0',
    variacao_ant_dia: '0.00000',
    var_fech_anterior: '0.00',
    vl_fech_ant_essa_sem: '0.00000',
    vl_fech_ant_esse_mes: '0.00000',
    vl_fech_ant_esse_ano: '0.00000',
    vl_fech_ant_sem: '0.00000',
    vl_fech_ant_mes: '0.00000',
    vl_fech_ant_ano: '0.00000',
    volume: '0.0',
    arrow_font_hex: '#1A1B23',
    arrow_hex: '#F0960F',
    situacao: 'LEILAO',
    precoDiaAnt: '0.0000',
    qtde_negocios: '0',
    relatorio_forca_compra: '25.00',
    relatorio_forca_venda: '25.00',
    sentido_leilao_nao_atendida: '0',
    contract_multiplier: '1.0',
    valor_melhor_oferta_venda: '0',
    valor_melhor_oferta_compra: '0',
    min_price_increment: '0.01',
    lote_padrao: '100',
    nome_companhia: 'MINASMAQUINA',
    auctionInfos: {
      situacao: 'LEILAO',
      sentido_leilao_nao_atendida: '0',
      variacao_dia: '0',
      hora_abert_program: '0',
      preco_leilao: '0',
      qtde_leilao: '0',
      qtde_leilao_nao_atendida: '0',
      variacao_leilao: '0',
    },
    preco_abertura: '0',
    preco_maximo: '0',
    preco_minimo: '0',
    hora_abert_program: '0',
    motivo_leilao_headline: '0',
    preco_leilao: '0',
    qtde_leilao: '0',
    qtde_leilao_nao_atendida: '0',
    variacao_leilao: '0',
  },
  {
    item: 'KLAS3',
    cd_stock: 'KLAS3',
    channel: 'QUOTE',
    tick_size_denominator: '2',
    preco_ultimo: '0.0000',
    preco_fechamento: '0.0000',
    variacao_dia: '0',
    variacao_ant_dia: '0.00000',
    var_fech_anterior: '0.00',
    vl_fech_ant_essa_sem: '0.00000',
    vl_fech_ant_esse_mes: '0.00000',
    vl_fech_ant_esse_ano: '0.00000',
    vl_fech_ant_sem: '0.00000',
    vl_fech_ant_mes: '0.00000',
    vl_fech_ant_ano: '0.00000',
    volume: '0.0',
    arrow_font_hex: '#1A1B23',
    arrow_hex: '#F0960F',
    situacao: 'LEILAO',
    precoDiaAnt: '0.0000',
    qtde_negocios: '0',
    relatorio_forca_compra: '25.00',
    relatorio_forca_venda: '25.00',
    sentido_leilao_nao_atendida: '0',
    contract_multiplier: '1.0',
    valor_melhor_oferta_venda: '0',
    valor_melhor_oferta_compra: '0',
    min_price_increment: '0.01',
    lote_padrao: '100',
    nome_companhia: 'KALLAS',
    auctionInfos: {
      situacao: 'LEILAO',
      sentido_leilao_nao_atendida: '0',
      variacao_dia: '0',
      hora_abert_program: '0',
      preco_leilao: '0',
      qtde_leilao: '0',
      qtde_leilao_nao_atendida: '0',
      variacao_leilao: '0',
    },
    preco_abertura: '0',
    preco_maximo: '0',
    preco_minimo: '0',
    hora_abert_program: '0',
    motivo_leilao_headline: '0',
    preco_leilao: '0',
    qtde_leilao: '0',
    qtde_leilao_nao_atendida: '0',
    variacao_leilao: '0',
  },
  {
    item: 'MMAQ4',
    cd_stock: 'MMAQ4',
    channel: 'QUOTE',
    tick_size_denominator: '2',
    preco_ultimo: '0.0000',
    preco_fechamento: '0.0000',
    variacao_dia: '0',
    variacao_ant_dia: '0.00000',
    var_fech_anterior: '0.00',
    vl_fech_ant_essa_sem: '0.00861',
    vl_fech_ant_esse_mes: '0.00861',
    vl_fech_ant_esse_ano: '0.00861',
    vl_fech_ant_sem: '0.00000',
    vl_fech_ant_mes: '0.00000',
    vl_fech_ant_ano: '0.00000',
    volume: '0.0',
    arrow_font_hex: '#1A1B23',
    arrow_hex: '#F0960F',
    situacao: 'LEILAO',
    precoDiaAnt: '0.0000',
    qtde_negocios: '0',
    relatorio_forca_compra: '25.00',
    relatorio_forca_venda: '25.00',
    sentido_leilao_nao_atendida: '0',
    contract_multiplier: '1.0',
    valor_melhor_oferta_venda: '0',
    valor_melhor_oferta_compra: '1.00',
    min_price_increment: '0.01',
    lote_padrao: '100',
    nome_companhia: 'MINASMAQUINA',
    auctionInfos: {
      situacao: 'LEILAO',
      sentido_leilao_nao_atendida: '0',
      variacao_dia: '0',
      hora_abert_program: '0',
      preco_leilao: '0',
      qtde_leilao: '0',
      qtde_leilao_nao_atendida: '0',
      variacao_leilao: '0',
    },
    preco_abertura: '0',
    preco_maximo: '0',
    preco_minimo: '0',
    hora_abert_program: '0',
    motivo_leilao_headline: '0',
    preco_leilao: '0',
    qtde_leilao: '0',
    qtde_leilao_nao_atendida: '0',
    variacao_leilao: '0',
  },
  {
    item: 'AESO3',
    cd_stock: 'AESO3',
    channel: 'QUOTE',
    tick_size_denominator: '2',
    preco_ultimo: '0.0000',
    preco_fechamento: '0.0000',
    variacao_dia: '0',
    variacao_ant_dia: '0.00000',
    var_fech_anterior: '0.00',
    vl_fech_ant_essa_sem: '11.22827',
    vl_fech_ant_esse_mes: '11.22827',
    vl_fech_ant_esse_ano: '11.22827',
    vl_fech_ant_sem: '0.00000',
    vl_fech_ant_mes: '0.00000',
    vl_fech_ant_ano: '0.00000',
    volume: '0.0',
    arrow_font_hex: '#1A1B23',
    arrow_hex: '#F0960F',
    situacao: 'LEILAO',
    precoDiaAnt: '0.0000',
    qtde_negocios: '0',
    relatorio_forca_compra: '25.00',
    relatorio_forca_venda: '25.00',
    sentido_leilao_nao_atendida: '0',
    contract_multiplier: '1.0',
    valor_melhor_oferta_venda: '0',
    valor_melhor_oferta_compra: '0',
    min_price_increment: '0.01',
    lote_padrao: '100',
    nome_companhia: 'AESOPERACOES',
    auctionInfos: {
      situacao: 'LEILAO',
      variacao_dia: '0',
      hora_abert_program: '0',
      preco_leilao: '0',
      qtde_leilao: '0',
      qtde_leilao_nao_atendida: '0',
      variacao_leilao: '0',
      sentido_leilao_nao_atendida: '0,',
    },
    preco_abertura: '0',
    preco_maximo: '0',
    preco_minimo: '0',
    hora_abert_program: '0',
    motivo_leilao_headline: '0',
    preco_leilao: '0',
    qtde_leilao: '0',
    qtde_leilao_nao_atendida: '0',
    variacao_leilao: '0',
  },
  {
    item: 'CATA4',
    cd_stock: 'CATA4',
    channel: 'QUOTE',
    tick_size_denominator: '2',
    preco_ultimo: '0.0000',
    preco_fechamento: '0.0000',
    variacao_dia: '0',
    variacao_ant_dia: '0.00000',
    var_fech_anterior: '0.00',
    vl_fech_ant_essa_sem: '0.00000',
    vl_fech_ant_esse_mes: '0.00000',
    vl_fech_ant_esse_ano: '0.00000',
    vl_fech_ant_sem: '0.00000',
    vl_fech_ant_mes: '0.00000',
    vl_fech_ant_ano: '0.00000',
    volume: '0.0',
    arrow_font_hex: '#1A1B23',
    arrow_hex: '#F0960F',
    situacao: 'LEILAO',
    precoDiaAnt: '0.0000',
    qtde_negocios: '0',
    relatorio_forca_compra: '25.00',
    relatorio_forca_venda: '25.00',
    sentido_leilao_nao_atendida: '0',
    contract_multiplier: '1.0',
    valor_melhor_oferta_venda: '0',
    valor_melhor_oferta_compra: '0',
    min_price_increment: '0.01',
    lote_padrao: '100',
    nome_companhia: 'IND CATAGUAS',
    auctionInfos: {
      situacao: 'LEILAO',
      variacao_dia: '0',
      hora_abert_program: '0',
      preco_leilao: '0',
      qtde_leilao: '0',
      qtde_leilao_nao_atendida: '0',
      variacao_leilao: '0',
      sentido_leilao_nao_atendida: '0,',
    },
    preco_abertura: '0',
    preco_maximo: '0',
    preco_minimo: '0',
    hora_abert_program: '0',
    motivo_leilao_headline: '0',
    preco_leilao: '0',
    qtde_leilao: '0',
    qtde_leilao_nao_atendida: '0',
    variacao_leilao: '0',
  },
  {
    item: 'MNZC3',
    cd_stock: 'MNZC3',
    channel: 'QUOTE',
    tick_size_denominator: '2',
    preco_ultimo: '0.0000',
    preco_fechamento: '0.0000',
    variacao_dia: '0',
    variacao_ant_dia: '0.00000',
    var_fech_anterior: '0.00',
    vl_fech_ant_essa_sem: '0.00000',
    vl_fech_ant_esse_mes: '0.00000',
    vl_fech_ant_esse_ano: '0.00000',
    vl_fech_ant_sem: '0.00000',
    vl_fech_ant_mes: '0.00000',
    vl_fech_ant_ano: '0.00000',
    volume: '0.0',
    arrow_font_hex: '#1A1B23',
    arrow_hex: '#F0960F',
    situacao: 'LEILAO',
    precoDiaAnt: '0.0000',
    qtde_negocios: '0',
    relatorio_forca_compra: '25.00',
    relatorio_forca_venda: '25.00',
    sentido_leilao_nao_atendida: '0',
    contract_multiplier: '1.0',
    valor_melhor_oferta_venda: '0',
    valor_melhor_oferta_compra: '0',
    min_price_increment: '0.01',
    lote_padrao: '100',
    nome_companhia: 'MENEZES CORT',
    auctionInfos: {
      situacao: 'LEILAO',
      variacao_dia: '0',
      hora_abert_program: '0',
      preco_leilao: '0',
      qtde_leilao: '0',
      qtde_leilao_nao_atendida: '0',
      variacao_leilao: '0',
      sentido_leilao_nao_atendida: '0,',
    },
    preco_abertura: '0',
    preco_maximo: '0',
    preco_minimo: '0',
    hora_abert_program: '0',
    motivo_leilao_headline: '0',
    preco_leilao: '0',
    qtde_leilao: '0',
    qtde_leilao_nao_atendida: '0',
    variacao_leilao: '0',
  },
  {
    item: 'DTCY4',
    cd_stock: 'DTCY4',
    channel: 'QUOTE',
    tick_size_denominator: '2',
    preco_ultimo: '0.0000',
    preco_fechamento: '0.0000',
    variacao_dia: '0',
    variacao_ant_dia: '0.00000',
    var_fech_anterior: '0.00',
    vl_fech_ant_essa_sem: '0.00000',
    vl_fech_ant_esse_mes: '0.00000',
    vl_fech_ant_esse_ano: '0.00000',
    vl_fech_ant_sem: '0.00000',
    vl_fech_ant_mes: '0.00000',
    vl_fech_ant_ano: '0.00000',
    volume: '0.0',
    arrow_font_hex: '#1A1B23',
    arrow_hex: '#F0960F',
    situacao: 'LEILAO',
    precoDiaAnt: '0.0000',
    qtde_negocios: '0',
    relatorio_forca_compra: '25.00',
    relatorio_forca_venda: '25.00',
    sentido_leilao_nao_atendida: '0',
    contract_multiplier: '1.0',
    valor_melhor_oferta_venda: '0',
    valor_melhor_oferta_compra: '0',
    min_price_increment: '0.01',
    lote_padrao: '100',
    nome_companhia: 'DTCOM-DIRECT',
    auctionInfos: {
      situacao: 'LEILAO',
      variacao_dia: '0',
      hora_abert_program: '0',
      preco_leilao: '0',
      qtde_leilao: '0',
      qtde_leilao_nao_atendida: '0',
      variacao_leilao: '0',
      sentido_leilao_nao_atendida: '0,',
    },
    preco_abertura: '0',
    preco_maximo: '0',
    preco_minimo: '0',
    hora_abert_program: '0',
    motivo_leilao_headline: '0',
    preco_leilao: '0',
    qtde_leilao: '0',
    qtde_leilao_nao_atendida: '0',
    variacao_leilao: '0',
  },
  {
    item: 'EMAE3',
    cd_stock: 'EMAE3',
    channel: 'QUOTE',
    tick_size_denominator: '2',
    preco_ultimo: '69.2800',
    preco_fechamento: '69.2800',
    variacao_dia: '0.00',
    variacao_ant_dia: '0.00000',
    var_fech_anterior: '0.00',
    vl_fech_ant_essa_sem: '69.28000',
    vl_fech_ant_esse_mes: '69.28000',
    vl_fech_ant_esse_ano: '14.51542',
    vl_fech_ant_sem: '0.00000',
    vl_fech_ant_mes: '69.28000',
    vl_fech_ant_ano: '0.00000',
    volume: '0.0',
    arrow_font_hex: '#1A1B23',
    arrow_hex: '#F0960F',
    situacao: 'LEILAO',
    precoDiaAnt: '69.2800',
    qtde_negocios: '0',
    relatorio_forca_compra: '25.00',
    relatorio_forca_venda: '25.00',
    sentido_leilao_nao_atendida: '0',
    contract_multiplier: '1.0',
    valor_melhor_oferta_venda: '69.00',
    valor_melhor_oferta_compra: '45.00',
    min_price_increment: '0.01',
    lote_padrao: '100',
    nome_companhia: 'EMAE',
    auctionInfos: {
      situacao: 'LEILAO',
      sentido_leilao_nao_atendida: '0',
      variacao_dia: '0.00',
      hora_abert_program: '0',
      preco_leilao: '0',
      qtde_leilao: '0',
      qtde_leilao_nao_atendida: '0',
      variacao_leilao: '0',
    },
    preco_abertura: '0',
    preco_maximo: '0',
    preco_minimo: '0',
    hora_abert_program: '0',
    motivo_leilao_headline: '0',
    preco_leilao: '0',
    qtde_leilao: '0',
    qtde_leilao_nao_atendida: '0',
    variacao_leilao: '0',
  },
  {
    item: 'COMR3',
    cd_stock: 'COMR3',
    channel: 'QUOTE',
    tick_size_denominator: '2',
    preco_ultimo: '0.0000',
    preco_fechamento: '0.0000',
    variacao_dia: '0',
    variacao_ant_dia: '0.00000',
    var_fech_anterior: '0.00',
    vl_fech_ant_essa_sem: '17.72000',
    vl_fech_ant_esse_mes: '17.72000',
    vl_fech_ant_esse_ano: '17.72000',
    vl_fech_ant_sem: '0.00000',
    vl_fech_ant_mes: '0.00000',
    vl_fech_ant_ano: '0.00000',
    volume: '0.0',
    arrow_font_hex: '#1A1B23',
    arrow_hex: '#F0960F',
    situacao: 'LEILAO',
    precoDiaAnt: '0.0000',
    qtde_negocios: '0',
    relatorio_forca_compra: '25.00',
    relatorio_forca_venda: '25.00',
    sentido_leilao_nao_atendida: '0',
    contract_multiplier: '1.0',
    valor_melhor_oferta_venda: '0',
    valor_melhor_oferta_compra: '0',
    min_price_increment: '0.01',
    lote_padrao: '100',
    nome_companhia: 'COMERC PAR',
    auctionInfos: {
      situacao: 'LEILAO',
      variacao_dia: '0',
      hora_abert_program: '0',
      preco_leilao: '0',
      qtde_leilao: '0',
      qtde_leilao_nao_atendida: '0',
      variacao_leilao: '0',
      sentido_leilao_nao_atendida: '0',
    },
    preco_abertura: '0',
    preco_maximo: '0',
    preco_minimo: '0',
    hora_abert_program: '0',
    motivo_leilao_headline: '0',
    preco_leilao: '0',
    qtde_leilao: '0',
    qtde_leilao_nao_atendida: '0',
    variacao_leilao: '0',
  },
];
