import { Component, Input } from '@angular/core';

@Component({
  selector: 'fla-input-suffix',
  templateUrl: './input-suffix.component.html',
  styles: [
    `
      :host(fla-input-suffix) {
        display: contents;
      }
    `,
  ],
})
export class FlaInputSuffixComponent {
  @Input() text!: string;
}
