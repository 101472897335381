import {
  Component,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  signal,
  WritableSignal,
  ViewChild,
} from '@angular/core';
import { Subject, Subscription, delay, tap } from 'rxjs';
import {
  IAllStockListSimple,
  IWorkSpaceComponet,
} from 'src/app/core/interface';
import { DYNAMIC_LISTS } from '@shared/components/stock-table/constants/stock-table.constants';
import {
  THeatmapConfig,
  TOrderBy,
  TPeriod,
  TSource,
} from '@shared/components/heatmap-chart/types';
import {
  HEATMAP_ORDER_BY,
  HEATMAP_PERIODS,
  HEATMAP_SOURCE_LABELS,
} from '@shared/components/heatmap-chart/enum';
import { RTListSelectorComponent } from '@shared/rocket-components/components/list-selector/list-selector.component';

@Component({
  selector: 'app-heatmap-header',
  templateUrl: './heatmap-header.component.html',
  styleUrls: ['./heatmap-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeatmapHeaderComponent implements OnDestroy {
  @Input() refComponent!: string;
  @Input() component!: IWorkSpaceComponet | undefined;
  @Input() isOpenListPersonal: boolean = false;
  @Input() set heatmapConfigs(heatmapConfigs: THeatmapConfig) {
    if (heatmapConfigs) this.heatmapConfigsSignal.set(heatmapConfigs);
  }

  heatmapConfigsSignal: WritableSignal<THeatmapConfig> = signal({
    period: 'ONEDAY',
  });
  @Input() linked!: boolean;

  @Output() changeListSelected: EventEmitter<IAllStockListSimple> =
    new EventEmitter<IAllStockListSimple>();
  @Output() addStocks: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() changeNotListPersonal: EventEmitter<IAllStockListSimple> =
    new EventEmitter<IAllStockListSimple>();
  @Output() changeOpenListPersonal: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() sourceSelected: EventEmitter<TSource> = new EventEmitter<TSource>();
  @Output() orderByChange: EventEmitter<TOrderBy> =
    new EventEmitter<TOrderBy>();
  @Output() periodChange: EventEmitter<TPeriod> = new EventEmitter<TPeriod>();
  @Output() displayWithoutPersonalListMessage = new EventEmitter<boolean>();
  @Output() selectedGhostList = new EventEmitter<void>();

  @ViewChild('listSelector') listSelector!: RTListSelectorComponent;
  listSelected!: IAllStockListSimple;
  orderByValues = HEATMAP_ORDER_BY;
  periodsValues = HEATMAP_PERIODS;
  periodsKeys = Object.keys(HEATMAP_PERIODS) as TPeriod[];
  listStocks: Array<IAllStockListSimple> = [];
  dropnAutoClose = false;
  public hashList!: any;
  public sourceLists: { type: TSource; name: string }[] = [
    { type: 'INDEX', name: 'Índices' },
    { type: 'BR', name: 'Setores' },
    { type: 'ETF', name: 'ETFs' },
    { type: 'FII', name: 'Fundos Imobiliários' },
    { type: 'BDR', name: 'BDR' },
    { type: 'US', name: 'US' },
  ];
  readonly dynamiclists = DYNAMIC_LISTS;
  private _closeDropdown$ = new Subject<void>();
  private _subscriptions = new Subscription();
  public currentSource!: TSource | undefined;

  get orderByLabel() {
    if (this.heatmapConfigsSignal()!!.orderBy)
      return HEATMAP_ORDER_BY[this.heatmapConfigsSignal()!!.orderBy!!];

    return this.heatmapConfigsSignal()!!.isNotListPersonal
      ? HEATMAP_ORDER_BY.variation
      : HEATMAP_ORDER_BY.volume;
  }

  constructor(private cdr: ChangeDetectorRef) {
    this._subscriptionsHandler();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private _subscriptionsHandler = (): void => {
    const dpSub = this._closeDropdown$
      .pipe(
        tap(() => {
          this.dropnAutoClose = true;
          this.cdr.detectChanges();
        }),
        delay(100)
      )
      .subscribe(() => {
        this.dropnAutoClose = false;
        this.cdr.detectChanges();
      });
    this._subscriptions.add(dpSub);
  };

  setOrderBy(orderBy: TOrderBy) {
    this.heatmapConfigsSignal()!!.orderBy = orderBy;
    this._closeDropdown();
    this.orderByChange.emit(orderBy);
    this.cdr.detectChanges();
  }

  setPeriod(period: TPeriod) {
    this.periodChange.emit(period);
  }

  selectSource(source: TSource | undefined) {
    if (!source || this.currentSource === source) return;
    this.currentSource = source;
    const list = {
      id_stock_list: 0,
      in_visibility: true,
      is_favorite: false,
      nm_stock_list: HEATMAP_SOURCE_LABELS[source],
      seq_priority: 0,
      isNotListPersonal: false,
      isSectors: true,
      isPresetList: false,
    };
    if (this.listSelector) {
      this.listSelector.setList(list);
      this.cdr.detectChanges();
    }
    this.sourceSelected.emit(source);
  }

  private _closeDropdown(): void {
    this._closeDropdown$.next();
  }

  public handleListSelected(
    list: IAllStockListSimple,
    isDynamic: boolean
  ): void {
    this.currentSource = undefined;
    if (isDynamic) {
      this.changeNotListPersonal.emit(list);
      return;
    }
    this.changeListSelected.emit(list);
  }

  public handleGhostListSelected(): void {
    this.currentSource = undefined;
    this.selectedGhostList.emit();
  }
}
