import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  IAllStockListSimple,
  IListStockDB,
  IWorkSpaceComponet,
  StockListItemsWithView,
} from '@core/interface';
import { HomeService } from '@modules/home/service/home.service';
import { StockService } from '@shared/services/api/trademap/v1/stock.service';
import { Subject, Subscription, debounceTime, takeUntil } from 'rxjs';
import { StockListRowModel } from '../stock-table/models/stock-table-row.model';
import { ToastService } from '@shared/services';
import { Dictionary } from '@core/models';
import { CheckConfigListMotPersonal } from '../stock-table/configurations-list/check-config-list-not-personal';
import { StockListStettingsService } from '@shared/services/api/nitro-ws/v1/stock-list-stettings.service';
import { MoversChannel } from '@shared/channel/movers.channel';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { RocketCreateComponentService } from '@shared/rocket-components/services';
import { IStockListItemsRow } from '../stock-table/interfaces/stock-table.interfaces';
import { StockAnalysisHeaderComponent } from './parts/header/stock-analysis-header.component';
import { AUTH_LOCAL_KEYS } from '@shared/services/core/const/auth_util.const';
import { TStockAnalysisConfig } from './types';
import { ListStocksService } from '@shared/services/core/list-stocks/list-stocks.service';
import { DYNAMIC_LISTS } from '../stock-table/constants/stock-table.constants';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { ActivatedRoute } from '@angular/router';
import { isWebViewContext } from 'src/app/desktop/integration.service';
import { ContextMenuService } from '../popup-root/context-menu.service';
import { StockAnalysisContextMenuComponent } from './config/stock-analysis-context-menu.component';

@Component({
  selector: 'app-stock-analysis',
  templateUrl: './stock-analysis.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockAnalysisComponent
  extends RocketComponentBase
  implements AfterViewInit, OnDestroy
{
  @Input() component!: IWorkSpaceComponet;
  @Input() refComponent!: string;
  @Input() width!: number;
  @Input() height!: number;
  @Input() link!: boolean;
  @ViewChild(StockAnalysisHeaderComponent)
  header!: StockAnalysisHeaderComponent;
  @ViewChild('stockAnalysis', { static: true }) stockAnalysis!: ElementRef;

  isOpenListPersonal: boolean = true;
  componentId!: string;
  stockAnalysisConfigs: TStockAnalysisConfig = {
    profitability: 'ONEDAY',
    volatility: '12M',
    variation: 'VARIATION',
  };
  configKey = '1';
  stockList: Dictionary<StockListRowModel> =
    new Dictionary<StockListRowModel>();
  graphData: StockListRowModel[] = [];
  isDesktop = false;

  private _onDestroy = new Subject<void>();
  private _chart!: Highcharts.Chart;
  private _moversParams: any;
  private _moversChannel$ = new Subscription();
  private _moversTypes: any = {
    [DYNAMIC_LISTS.MOVERS_HIGH.id]: 'ALTA.IBOV',
    [DYNAMIC_LISTS.MOVERS_LOW.id]: 'BAIXA.IBOV',
    [DYNAMIC_LISTS.MOVERS_VOLUME.id]: 'VOLUME.IBOV',
  };
  private snapshotDebounced = new Subject<void>();
  moverChannelSnapshot!: (item: any) => void;
  constructor(
    private _homeService: HomeService,
    private _stockService: StockService,
    private _toastService: ToastService,
    protected stockListStettingsService: StockListStettingsService,
    private _moversChannel: MoversChannel,
    private _customPreferencesService: CustomPreferencesService,
    private _createComponent: RocketCreateComponentService,
    private _listStocksService: ListStocksService,
    private cdr: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    protected contextMenuService: ContextMenuService
  ) {
    super(activatedRoute);
    this.isDesktop = isWebViewContext();
  }

  protected initComponent(component: any): void {
    if (component) this.component = component;

    this._moversChannel.readEvents().then(({ stream, snapshot }) => {
      this.moverChannelSnapshot = snapshot;
      this.readStream(stream, this._processMoversList);
    });
    this.snapshotDebounced.pipe(debounceTime(300)).subscribe(() => {
      this.moverChannelSnapshot(this._moversParams.itemsArray);
    });
    const configs = JSON.parse(
      this._customPreferencesService.getCustomPreference(
        AUTH_LOCAL_KEYS.STOCK_ANALYSIS_CONFIG
      )
    );
    if (configs && !configs.isNotListPersonal)
      this.stockAnalysisConfigs = configs;
  }

  ngAfterViewInit(): void {
    this.stockAnalysis.nativeElement.oncontextmenu = this._showConfig;
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public chartReference(chart: Highcharts.Chart): void {
    this._chart = chart;
  }

  private _showConfig = (event: any) => {
    StockAnalysisContextMenuComponent.openContextMenu(
      this.contextMenuService,
      this.component.id,
      { clientX: event.pageX, clientY: event.pageY }
    );

    return false;
  };

  onConfigChange(newConfig: TStockAnalysisConfig) {
    this.stockAnalysisConfigs = newConfig;
    this.cdr.detectChanges();
    this._saveConfig();
  }

  processList = (selectedList: IAllStockListSimple): void => {
    if (!selectedList) return;

    if (selectedList.isNotListPersonal) {
      this.changeNotListPersonal(selectedList);
      return;
    }

    this._unsubscribeMovers();
    this.stockAnalysisConfigs.isNotListPersonal = false;
    this.stockAnalysisConfigs.idList = selectedList.id_stock_list;

    this._saveConfig();
    this.isOpenListPersonal = true;
    this._stockService
      .getStockListItemsWithView(
        null,
        this.stockAnalysisConfigs.idList,
        this.refComponent
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((result: StockListItemsWithView) => {
        if (!result.stockListItemsRow || result.stockListItemsRow.length == 0) {
          this._toastService.showToast(
            'warning',
            'A lista selecionada está vazia.'
          );
          return;
        }

        this.stockList.clear();
        result.stockListItemsRow.forEach((stock: IStockListItemsRow) => {
          this.stockList.set(`${stock.cd_stock}:${stock.id_exchange}`, stock);
        });

        this.graphData = this.stockList.values();

        this.cdr.detectChanges();
      });
  };

  private _unsubscribeMovers() {
    if (!this._moversParams) return;
    const params = this._moversParams;
    this._moversParams = null;
    this._moversChannel.unsubscribe(params);
    this._moversChannel$.unsubscribe();
  }

  changeNotListPersonal(list: any) {
    this.stockAnalysisConfigs.profitability = 'ONEDAY';
    this.stockAnalysisConfigs.isNotListPersonal = true;
    this.stockAnalysisConfigs.idList = list.id_stock_list;
    this._saveConfig();

    this.stockList.clear();
    this.graphData = [];
    this.isOpenListPersonal = false;
    this.component.metadata.component = {
      ...this.component.metadata.component,
      isOpenListPersonal: false,
    };
    this._homeService.updateMeta(this.component);

    const checkConfigListNotPersonal = new CheckConfigListMotPersonal(
      this.stockListStettingsService,
      this._listStocksService
    );
    checkConfigListNotPersonal.check(list, this.mountedList);
  }

  private mountedList = (list: IListStockDB) => {
    this.stockList.clear();

    this._unsubscribeMovers();
    const type = this._moversTypes[list.idStockList];
    this._moversParams = {
      itemsArray: [type],
      header: this.refComponent,
    };
    this._moversChannel.subscribe(this._moversParams);
    this.snapshotDebounced.next();
  };

  private _processMoversList = (data: any) => {
    const stock: StockListRowModel = {
      idRow: `${data.key}:1`,
      cd_stock: data.key,
      ...data,
    };

    if (data.key === 'EOS') {
      return;
    }
    this._upsertStock(stock, data.command);
    this.graphData = this.stockList.values();
    this.cdr.detectChanges();
  };

  private _upsertStock(stock: StockListRowModel, command: string) {
    const hasStock = this.stockList.has(stock.idRow);
    if (hasStock && command === 'DELETE') {
      this.stockList.delete(stock.idRow);
      return;
    }

    this.stockList.set(stock.idRow, stock);
  }

  changeOpenListPersonal(data: boolean): void {
    this.component.metadata.component = {
      ...this.component.metadata.component,
      isOpenListPersonal: data,
    };
    this._homeService.updateMeta(this.component);
  }

  loadData() {
    this._chart.showLoading();
    this._unsubscribeMovers();
    this.isOpenListPersonal = false;
  }

  private _saveConfig() {
    this._customPreferencesService.customPreference = {
      key: AUTH_LOCAL_KEYS.STOCK_ANALYSIS_CONFIG,
      value: JSON.stringify({
        ...this.stockAnalysisConfigs,
        key: this.configKey,
      }),
    };
  }
}
