<div class="section-header-book bg-neutral-stronger">
  <div class="header-book-container">
    <div class="header-book-item bg-neutral-strong">
      <p class="mb-0" title="Atual">Atual</p>
      <span>{{ +precoUltimo | tickSize : tickSizeDenominator : dsType }}</span>
    </div>
    <div
      class="header-book-item"
      [appAuctionTooltip]="stockAuctionInfos"
      [situation]="situation"
    >
      <p class="mb-0" title="Variação">Var</p>
      <span [style.color]="arrowHex"> {{ variation }}% </span>
    </div>
    <div class="header-book-item">
      <p class="mb-0" title="Mínimo" [ngClass]="minimumPriceClass">Min</p>
      <span>{{ +precoMinimo | tickSize : tickSizeDenominator : dsType }}</span>
    </div>
    <div class="header-book-item">
      <p class="mb-0" title="Máximo" [ngClass]="maximumPriceClass">Max</p>
      <span>{{ +precoMaximo | tickSize : tickSizeDenominator : dsType }}</span>
    </div>
    <div class="header-book-item">
      <p class="mb-0" title="Volume">Vol</p>
      <span>{{ volume }}</span>
    </div>
  </div>
</div>
