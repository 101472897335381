<app-dropdown>
  <button
    *ngIf="selectedList"
    class="nav-link p-2 lh-sm round-top dropdown-toggle bg-transparent fs-6"
    appDropdownButton
    [rtDropnClose]="true"
  >
    {{ selectedList.nm_stock_list }}
  </button>
  <div class="list-max-height">
    <ng-template *ngTemplateOutlet="personal"></ng-template>
  </div>
  <ng-container *ngIf="displayMoversLists">
    <hr />
    <div>
      <ng-template *ngTemplateOutlet="movers"></ng-template>
    </div>
  </ng-container>
  <ng-content></ng-content>
</app-dropdown>

<ng-template #personal>
  <button
    class="dropdown-submenu dropdown-item arrow position-relative pr-0 bg-transparent fs-6"
    data-bs-toggle="collapse"
    href="#Personallist"
    [attr.aria-expanded]="false"
    aria-controls="MoversList"
    (click)="openListPersonal()"
  >
    Pessoais
  </button>
  <ng-container *ngFor="let item of stockLists">
    <div
      class="collapse"
      [ngClass]="{ show: isPersonalListOpen }"
      id="Personallist"
    >
      <ng-container *ngIf="item.isNotListPersonal === undefined">
        <button
          class="dropdown-item bg-transparent fs-6"
          (click)="selectList(item)"
          [ngClass]="{
            'text-brand-primary':
              selectedList?.id_stock_list === item.id_stock_list
          }"
        >
          {{ item.nm_stock_list }}
        </button>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #movers>
  <button
    class="dropdown-submenu dropdown-item arrow position-relative pr-0 bg-transparent fs-6"
    data-bs-toggle="collapse"
    href="#MoversList"
    aria-expanded="false"
    aria-controls="MoversList"
    (click)="isDynamicListOpenned = !isDynamicListOpenned"
  >
    Movers
  </button>
  <div
    class="collapse"
    id="MoversList"
    [ngClass]="{ show: isDynamicListOpenned }"
  >
    <ng-container *ngFor="let list of dynamicLists">
      <button
        class="dropdown-item bg-transparent fs-6"
        (click)="selectListNotPersonal(list.type)"
        [ngClass]="{
          'text-brand-primary': selectedList?.id_stock_list === list.id
        }"
      >
        {{ list.name }}
      </button>
    </ng-container>
  </div>
</ng-template>
