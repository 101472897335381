import { ColDef } from 'ag-grid-community';
import {
  CONFIG_HEADERS,
  CONFIG_HEADERS_AUCTION,
  HEADERS,
  HEADERS_DEFAULT,
} from '../constants/headers.constants';
import { MARKET_MOVERS_HEADERS_CONFIG } from '../constants/columns/market-movers';
import { objectAssign } from 'src/app/utils/utils.functions';
import { IMoversPeriod } from '../interfaces/movers-period.interface';
import { DYNAMIC_LISTS } from '../constants/stock-table.constants';

export enum LIST_CONFIG_TYPE {
  MOVERS,
  AUCTION,
}

export class MountHeaders {
  private _configHeader!: any[];
  private _objectConfigHeader: any = {};

  get isAuction() {
    return this.listConfigType === LIST_CONFIG_TYPE.AUCTION;
  }

  get variationFieldLabel() {
    if (
      !this.stockTablePeriod ||
      this.isAuction ||
      this.idStockList === DYNAMIC_LISTS.MOVERS_VOLUME.id
    )
      return 'Intradia';
    return this.stockTablePeriod.label;
  }

  constructor(
    private listConfigType?: LIST_CONFIG_TYPE,
    private metadataHeaders?: any,
    private stockTablePeriod?: IMoversPeriod,
    private idStockList?: number
  ) {
    this._configHeader = CONFIG_HEADERS;
    if (LIST_CONFIG_TYPE.MOVERS === listConfigType) {
      this._configHeader = MARKET_MOVERS_HEADERS_CONFIG(
        this.variationFieldLabel
      );
    }
    LIST_CONFIG_TYPE.AUCTION === listConfigType &&
      (this._configHeader = this.configHeadersAuction());
    this._configHeader.forEach((header) => {
      this._objectConfigHeader[header.field] = header;
    });
  }

  verifyHeader(metadataHeaders: any) {
    const data1 = HEADERS;
    const data2 = metadataHeaders;
    const assign = objectAssign(data1, data2);
    return Object.assign(metadataHeaders, assign);
  }

  public setHeaders(headers: any): Array<ColDef> {
    return [...HEADERS_DEFAULT, ...this.configHeaders(headers)];
  }

  getColumns = () => {
    return this._configHeader.map((columnDefault: any) => {
      return this.metadataHeaders[columnDefault.field]
        ? { ...columnDefault, ...this.metadataHeaders[columnDefault.field] }
        : columnDefault;
    });
  };

  public configHeadersOpenModalConfig(metadata: any): any {
    return this.configHeaders({ ...HEADERS, ...metadata });
  }

  public configHeaders(metadata: any): any {
    // HEADERS
    let configsHeader: any[] = [];
    if (metadata) {
      metadata['cd_stock']['hide'] = false;
      Object.keys(metadata).forEach((header) => {
        const headerConfig = this._objectConfigHeader[header];
        if (headerConfig) {
          configsHeader.push(headerConfig);
        }
      });
    }
    configsHeader = configsHeader.length ? configsHeader : this._configHeader;
    const headers: any[] = configsHeader.reduce((acc: any, column: any) => {
      const field = column.field || column.headerComponentParams?.[1];
      const metadataField = metadata[field] ?? HEADERS[column.field];
      if (metadataField != null) {
        const field =
          Object.keys(metadataField).length > 2
            ? { width: metadataField.width, hide: metadataField.hide }
            : metadataField;
        acc.push({ ...column, ...field });
      } else if (!this.isAuction && acc.length === 1) {
        acc.push(...CONFIG_HEADERS_AUCTION);
      }
      return acc;
    }, []);
    return headers;
  }

  private configHeadersAuction() {
    let headers: ColDef[] = [];
    CONFIG_HEADERS.forEach((column: ColDef) => {
      if (column.field === 'cd_stock') {
        headers = [column, ...CONFIG_HEADERS_AUCTION];
      } else {
        column.hide = true;
        headers.push(column);
      }
    });
    return headers;
  }
}
