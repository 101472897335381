import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import {
  RocketModalRef,
  RocketModalService,
  ROCKET_MODAL_DATA,
} from '@shared/rocket-components/components/index';
import { StockListService } from '@shared/services/api/trademap/V10/stock-list.service';
import { CreateList } from '../../configurations-list/create-list';
import { ToastService } from '@services/toast.service';
import { execFormatFinancial } from 'src/app/utils/utils.functions';
import { ISearchStock, IStockListItemsNewList } from 'src/app/core/interface';
import { ListStocksService } from '@shared/services/core/list-stocks/list-stocks.service';
import { StockSearchConfigModalComponent } from '@shared/components/stock-search-config-modal/stock-search-config-modal.component';
import { StockServiceRT } from '@shared/services/api/nitro-ws/v1/stock.service';

@Component({
  selector: 'app-new-list-stock-modal',
  templateUrl: './new-list-stock-modal.component.html',
  styles: [
    `
      :host {
        ::ng-deep .margin-bottom {
          margin-bottom: 0px !important;
        }

        .list-name {
          ::ng-deep {
            small {
              float: right;
              margin: 0px 0px 4px 0px !important;
            }
          }
        }

        ::ng-deep .modal-dialog {
          max-width: 450px !important;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewListStockModalComponent
  extends RocketModalRef
  implements AfterViewInit
{
  modalService!: RocketModalService;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public listStocksSelected: Array<any> = [];
  public newListForm!: FormGroup;
  public isSearch = false;
  public isLoading = false;
  public searchedStock: ISearchStock[] = [];
  public hashListStocksSelected: any = {};
  public noData: boolean = false;

  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: any,
    service: RocketModalService,
    private formBuilder: FormBuilder,
    private stockService: StockServiceRT,
    private stockListService: StockListService,
    private toastService: ToastService,
    private listStocksService: ListStocksService,
    private _cdr: ChangeDetectorRef,
    private _elementRef: ElementRef
  ) {
    super(service);
    this.modalService = service;
    this.createForm();
  }

  @HostListener('window:keydown.enter')
  handleKeyDown() {
    this.createdList();
  }

  ngAfterViewInit() {
    this.init();
  }

  openConfig() {
    this.modalService.open(StockSearchConfigModalComponent, {
      centered: true,
      keyboard: false,
      toggleBetweenModal: this._elementRef,
      data: { isSecondModal: true },
    });
  }

  private init(): void {
    this.newListForm.controls['search'].valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(300))
      .subscribe((text: string) => this.getStock(text));
  }

  private createForm() {
    this.newListForm = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(30),
      ]),
      search: new FormControl(undefined),
    });
  }

  private getStock(cdStock: string): void {
    if (!cdStock || !cdStock.length) {
      this.isSearch = false;
      this.noData = false;
      this.searchedStock = [];
      this._cdr.detectChanges();
      return;
    }
    this.isSearch = true;
    this.stockService
      .searchStock(cdStock, [], false, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: Array<ISearchStock>) => {
        this.isSearch = false;
        this.noData = !res?.length;
        !!res?.length && this.verifyExistStockInList(res);
      });
  }

  private verifyExistStockInList(list: any[]): void {
    this.searchedStock = structuredClone(list);
    this._cdr.detectChanges();
  }

  public onClose = (idStockList: number = 0) => {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.close(
      this.data.returnNameAndId
        ? {
            name: this.newListForm.controls['name'].value,
            idList: idStockList,
          }
        : idStockList,
      false
    );
  };

  public createdList(): void {
    if (!this.newListForm.valid || !this.listStocksSelected.length) return;
    this.isLoading = true;
    const stocks = this.listStocksSelected.map((item: ISearchStock) =>
      item.is_synonymous
        ? `${item.ds_asset}:${item.id_exchange}:SYN`
        : `${item.cd_stock}:${item.id_exchange}`
    );
    const createList = new CreateList(
      this.listStocksService,
      this.stockListService
    );
    createList
      .createdList(this.newListForm.controls['name'].value, stocks, false)
      .subscribe((idStockList: number) => {
        this.isLoading = false;
        if (idStockList) {
          this.toastService.showToast(
            'success',
            `Lista ${this.newListForm.controls['name'].value} criada com sucesso!`
          );
          this.onClose(idStockList);
        } else {
          this.toastService.showToast(
            'error',
            'Ocorreu um problema ao cria a lista, tente novamente.'
          );
        }
      });
  }

  public selectStock(data: {
    listStocksSelected: any[];
    hashListStocksSelected: any;
    item: IStockListItemsNewList;
  }): void {
    this.listStocksSelected = data.listStocksSelected;
    this.hashListStocksSelected = data.hashListStocksSelected;
    this._cdr.detectChanges();
  }

  public formatPrice(data: any, value: any): string {
    return execFormatFinancial(data, value);
  }
}
