import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable, map, take, tap, timer } from 'rxjs';

@Component({
  selector: 'app-timer-resend',
  templateUrl: './timer-resend.component.html',
  styleUrls: ['./timer-resend.component.scss'],
})
export class TimerResendComponent implements OnChanges {
  countdown$!: Observable<number>;
  @Input() seconds: number = 30;
  @Input() message: string = 'Receba um novo código em ';
  @Output() timerCompleted: EventEmitter<string> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    const { seconds } = changes;
    if (seconds && seconds.currentValue) {
      this.seconds = seconds.currentValue;
      this._buildRetryTime(seconds.currentValue);
    }
  }

  private _buildRetryTime(seconds: number): void {
    const interval$ = timer(0, 1000);
    this.countdown$ = interval$.pipe(
      take(seconds + 1),
      map((tick) => seconds - tick),
      tap((tick) => {
        if (tick === 0) {
          this.handleFinish();
        }
      })
    );
  }

  private handleFinish(): void {
    // lógica para executar ao final da contagem
    this.timerCompleted.emit('Contagem finalizada!');
  }
}
