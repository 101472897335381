import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TIGER_TYPE_CHART_ENUM } from '@shared/tiger-chart/enum';

@Component({
  selector: 'app-dropdown-types',
  template: `
    <app-dropdown menuAlign="dropdown-menu-right">
      <button appDropdownButton class="btn btn-icon min-w-0 mx-2">
        <fla-icon
          [tooltip]="tooltipText"
          tooltipPosition="top"
          [name]="icon"
        ></fla-icon>
      </button>
      <chart-types
        [options]="options"
        [currentOption]="currentOption"
        (onSelectOption)="handleSelectedOption.emit($event)"
      >
      </chart-types>
    </app-dropdown>
  `,
})
export class DropDownTypesComponent {
  @Input() tooltipText: string = '';
  @Input() icon: string | undefined = '';
  @Input() options: any[] = [];
  @Input() currentOption!: TIGER_TYPE_CHART_ENUM | undefined;
  @Output() handleSelectedOption: EventEmitter<TIGER_TYPE_CHART_ENUM> =
    new EventEmitter<TIGER_TYPE_CHART_ENUM>();
}
