<div class="w-100" [style.height.px]="gridHeight()">
  <div class="w-100 h-100 opt-border-top bg-neutral-strongest overflow-auto">
    <table class="w-100 text-center table">
      <thead class="fixed-header bg-neutral-strong">
        <tr>
          <th
            *ngFor="let header of headers"
            class="fw-bolder px-0"
            [tooltip]="header.tooltip"
          >
            {{ header.title }}
          </th>
        </tr>
      </thead>

      <tbody *ngIf="operations.length && !loading">
        <ng-container *ngFor="let operation of operations">
          <tr class="operation-row position-relative w-100">
            <td colspan="16" class="text-left py-1 fw-bold">
              <fla-icon
                css="cursor-pointer text-light"
                size="icon-size-nano"
                name="close"
                [tooltip]="'Cancelar operação'"
                (click)="deleteOperation(operation)"
              ></fla-icon>
              {{ operation.ds_operation }}
            </td>
          </tr>
          <tr *ngFor="let leg of operation.legs">
            <td class="py-1 px-1 bg-neutral-strongest text-right">
              {{ leg.cd_stock }}
            </td>
            <td
              class="py-1 px-1 bg-neutral-strongest text-right"
              [ngClass]="
                leg.option_type === 'C'
                  ? 'text-multibroker-buy'
                  : 'text-multibroker-sell'
              "
            >
              {{ typeNames[leg.option_type] }}
            </td>
            <td class="py-1 px-0 bg-neutral-strongest text-right">
              {{ leg.dt_expiration_option }}
            </td>
            <td class="py-1 px-0 bg-neutral-strongest text-right">
              {{ leg.option_days_to_expiration }}
            </td>
            <td
              class="py-1 px-0 bg-neutral-strongest text-right"
              [ngClass]="
                leg.side === 'C'
                  ? 'text-multibroker-buy'
                  : 'text-multibroker-sell'
              "
            >
              {{ leg.side }}
            </td>
            <td class="py-1 px-0 bg-neutral-strongest text-right">
              {{ leg.price }}
            </td>
            <td class="py-1 px-0 bg-neutral-strongest text-right">
              {{ leg.qtty_lot }}
            </td>
            <td class="py-1 px-0 bg-neutral-strongest text-right">
              {{ leg.qt_share_traded }}
            </td>
            <td class="py-1 px-0 bg-neutral-strongest text-right">
              {{ leg.strike_price }}
            </td>
            <td
              class="py-1 px-0 bg-neutral-strongest text-right"
              [ngClass]="colorMapping[leg.cd_stock].vl_delta"
            >
              {{ leg.vl_delta }}
            </td>
            <td
              class="py-1 px-0 bg-neutral-strongest text-right"
              [ngClass]="colorMapping[leg.cd_stock].vl_gamma"
            >
              {{ leg.vl_gamma }}
            </td>
            <td
              class="py-1 px-0 bg-neutral-strongest text-right"
              [ngClass]="colorMapping[leg.cd_stock].vl_rho"
            >
              {{ leg.vl_rho }}
            </td>
            <td
              class="py-1 px-0 bg-neutral-strongest text-right"
              [ngClass]="colorMapping[leg.cd_stock].vl_theta"
            >
              {{ leg.vl_theta }}
            </td>
            <td
              class="py-1 px-0 bg-neutral-strongest text-right"
              [ngClass]="colorMapping[leg.cd_stock].vl_vega"
            >
              {{ leg.vl_vega }}
            </td>
            <td
              class="py-1 px-0 bg-neutral-strongest text-right"
              [ngClass]="colorMapping[leg.cd_stock].vl_vol_implicitly"
            >
              {{ leg.vl_vol_implicitly }}%
            </td>
            <td class="py-1 px-0 bg-neutral-strongest text-right">
              {{ leg.volume }}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <div
      *ngIf="loading || !operations.length"
      class="w-100 h-100 bg-neutral-strongest d-flex align-items-sm-center justify-content-center"
    >
      <div *ngIf="loading; else elseTemplate">
        <app-loading></app-loading>
      </div>
      <ng-template #elseTemplate>
        <div>Nenhuma operação encontrada</div>
      </ng-template>
    </div>
  </div>
</div>
