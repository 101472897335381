<app-heatmap-header
  #header
  [refComponent]="refComponent"
  [component]="_component()"
  [isOpenListPersonal]="isOpenListPersonal"
  [heatmapConfigs]="heatmapConfigsSignal()"
  [linked]="link"
  (changeListSelected)="processList($event)"
  (changeNotListPersonal)="changeNotListPersonal($event)"
  (changeOpenListPersonal)="changeOpenListPersonal($event)"
  (sourceSelected)="onSourceSelected($event)"
  (orderByChange)="onOrderByChange($event)"
  (periodChange)="onPeriodChange($event)"
  (displayWithoutPersonalListMessage)="setIsWithoutPersonalList($event)"
  (selectedGhostList)="ghostListSelected()"
></app-heatmap-header>

<div
  #heatmap
  class="w-100"
  [ngClass]="isDesktop ? 'component-height' : 'h-100'"
>
  <ng-container *ngIf="isOpenListPersonal; else isMovers">
    <ng-container
      *ngIf="!stockList.size() || withoutPersonalLists; else chartContent"
    >
      <ng-container *ngIf="withoutPersonalLists">
        <div class="vstack gap-2 align-items-center fs-5 pt-3">
          <span>Você ainda não possui listas pessoais criadas.</span>
          <span>Crie sua primeira lista clicando no botão abaixo</span>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            (click)="openCreateStockList()"
          >
            Criar nova lista
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="!withoutPersonalLists">
        <span class="d-block text-center mt-3 fs-5">
          Essa lista não contém ativos
        </span>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #isMovers>
    <ng-container
      *ngIf="
        !isSectors &&
          (loadingMovers || (displayEmptyDynamicList && !moversList.size()));
        else chartContent
      "
    >
      <div
        [style.height.px]="height - 50"
        class="w-100 pt-3 d-flex flex-column align-items-center gap-2 w-100 position-absolute bg-neutral-strongest loading-index"
      >
        <ng-container *ngIf="loadingMovers; else emptyList">
          <span class="fs-6">Carregando Movers, por favor aguarde.</span>
          <div class="spinner-border text-brand-primary" role="status"></div>
        </ng-container>
        <ng-template #emptyList>
          <span class="d-block text-center mt-3 fs-5">
            O mercado está fechado, tente novamente mais tarde.
          </span>
        </ng-template>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #chartContent>
    <app-heatmap-chart
      [refComponent]="refComponent"
      [width]="width"
      [height]="height"
      [heatmapConfigs]="heatmapConfigsSignal()"
      (chartLoaded)="setChartRef($event)"
      (chartCallback)="chartCallBack()"
    ></app-heatmap-chart>
  </ng-template>
</div>
