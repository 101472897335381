<div
  class="confirm-container d-inline-flex align-items-center justify-content-center"
>
  <div
    class="confirm-card bg-neutral-stronger round d-flex flex-column align-items-center justify-content-center gap-5 text-center"
  >
    <h3 class="fw-bold" [class]="titleColor">
      {{ title }}
    </h3>

    <div
      *ngIf="isLoading; else redirCounter"
      class="spinner-border text-brand-primary"
      role="status"
    ></div>

    <ng-template #redirCounter>
      <span class="fs-6 text-white">
        Você será redirecionado em
        <span class="fs-6">{{ redirtTimeout }}</span> segundos(s)
      </span>
    </ng-template>
  </div>
</div>
