<rocket-modal>
  <rocket-modal-header>
    <div class="d-inline-flex align-items-center justify-content-start w-100">
      <button
        *ngIf="modalInfs.isHelper"
        type="button"
        (click)="backPrimaryModalContent($event)"
        class="mr-3 p-0 bg-transparent border-none text-light"
      >
        <fla-icon fla-icon name="arrow_back"></fla-icon>
      </button>
      <h3 class="modal-title">{{ modalInfs.title }}</h3>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <p
      class="mb-4"
      [innerHtml]="
        modalInfs.text(authenticator ? authenticator.name : undefined)
      "
    ></p>

    <ng-container
      *ngIf="!data.hasToken && !modalInfs.isHelper && authenticator"
    >
      <div class="d-flex align-items-baseline">
        <div>
          <form [formGroup]="tokenForm" (ngSubmit)="onConfirm()">
            <fla-input
              #inputToken
              formControlName="token"
              placeholder="Insira aqui o seu token"
              decidePosition="all"
              requiredText="Token inválido."
              margin="m-0"
              [autoComplete]="false"
              [type]="settings.inputType"
              [isRequired]="true"
              [maxLength]="6"
              (keyup)="validityToken($event)"
            >
              <div class="prefix">
                <img
                  [src]="authenticator.icon"
                  alt="Logo Vale Mobi"
                  width="32px"
                  class="mr-3"
                />
              </div>
              <div class="suffix">
                <rocket-button
                  type="icon"
                  (rocketClick)="toggleTokenVisibility()"
                  [title]="settings.title"
                >
                  <fla-icon
                    css="cursor-pointer"
                    [name]="settings.icon"
                    size="icon-size-sm"
                  ></fla-icon>
                </rocket-button>
              </div>
            </fla-input>
          </form>
        </div>
      </div>

      <div class="mt-4">
        <button
          type="button"
          class="text-brand-primary bg-transparent border-none pl-0"
          (click)="changeAuthenticator($event)"
        >
          Vincular outro aplicativo autenticador
        </button>
      </div>
    </ng-container>
  </app-rocket-modal-body>

  <rocket-modal-footer *ngIf="!hasToken && !modalInfs.isHelper">
    <rocket-button
      type="fill"
      label="Cancelar"
      (rocketClick)="onClose()"
    ></rocket-button>
    <rocket-button
      css="ml-2"
      label="Confirmar"
      (rocketClick)="onConfirm()"
      [isDisabled]="
        tokenForm.invalid || !tokenForm.value.token || disableConfirmBtn
      "
    ></rocket-button>
  </rocket-modal-footer>
</rocket-modal>
