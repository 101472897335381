import { isNullOrWhiteSpace } from '@shared/rocket-components/utils';

export class BrowserStorageBase implements Storage {
  constructor(private factory: Storage) {}

  setItemNullRemove(key: string, value: any) {
    value ? this.setItem(key, value) : this.removeItem(key);
  }

  setItem(key: string, value: any): void {
    this.factory.setItem(key, JSON.stringify(value));
  }

  removeItem(key: string): void {
    this.factory.removeItem(key);
  }

  hasKey(key: string) {
    return !isNullOrWhiteSpace(this.factory.getItem(key));
  }

  getItem(key: string) {
    let rep;
    try {
      const session_data: any = this.factory.getItem(key);
      rep = JSON.parse(session_data);
    } catch (error) {
      rep = {};
    } finally {
      return rep;
    }
  }
  // NOT IMPLEMENTED
  [name: string]: any;
  length!: number;

  clear(): void {
    throw new Error('Method not implemented.');
  }

  key(index: number): string | null {
    throw new Error('Method not implemented.');
  }
}
