import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { AuthComponent } from './auth.component';
import { RegisterModule } from './register/register.module';
import { TokenComponent } from './token/token.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'login/:partner', component: LoginComponent },
      { path: 'login/influencers/:id', component: LoginComponent },
      { path: 'token/:token', component: TokenComponent },
      { path: 'register', loadChildren: () => RegisterModule },
      { path: 'reset', loadChildren: () => RegisterModule },
      { path: 'influencers', redirectTo: 'login', pathMatch: 'full' }, // redireciona para o login caso n tenha id.
      { path: 'token', redirectTo: 'login', pathMatch: 'full' }, // redireciona para o login caso n tenha token.
      { path: '', redirectTo: 'login', pathMatch: 'full' }, // redireciona para o login se a rota for '/'
    ],
  },
];

export const AuthRoutes = RouterModule.forChild(routes);
