import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '@shared/services/rest/rest.service';
import { Subject, debounceTime, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DisconnectService extends RestService {
  override _url: string = 'api/nitro-ws/v1';
  disconnect$: Subject<any> = new Subject<any>();

  constructor(http: HttpClient) {
    super(http);
    this.disconnect$.pipe(take(1), debounceTime(1)).subscribe(this.disconnect);
  }

  private disconnect = (log: string): void => {
    if (!log) return;
    this.post<any>(`disconnect`, { log }).pipe(take(1)).subscribe();
  };

  performDisconnect = (logMessage: string) => this.disconnect(logMessage);
}
