<rocket-modal>
  <rocket-modal-header>
    <h2 class="modal-title">Configurações</h2>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <div class="row pt-3">
      <div
        *ngFor="let tab of tabGroups"
        class="col-16 mb-5"
        [style.width.px]="groupWidths[tab]"
      >
        <div
          class="position-relative border border-brand-primary round px-2 py-3"
        >
          <div
            class="bg-dark fw-bold position-absolute pr-2 round-sm position-top d-flex"
          >
            <app-check
              [label]="tab"
              css="cursor-pointer"
              [isChecked]="allCheck[tab]"
              (flaChange)="selectAll($event, tab)"
            ></app-check>
          </div>
          <div class="d-flex flex-wrap">
            <div *ngFor="let column of tabs[tab]" class="col-width ml-1 mr-2">
              <app-check
                [label]="column.headerTooltip"
                [isChecked]="!column.hide"
                [inline]="true"
                css="cursor-pointer flex-wrap "
                (flaChange)="setVisibleOrInvisible($event, column, tab)"
              ></app-check>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-rocket-modal-body>
  <rocket-modal-footer>
    <rocket-button
      type="fill"
      label="Cancelar"
      css="mr-4"
      [isSmall]="true"
      (rocketClick)="onClose()"
    ></rocket-button>
    <rocket-button
      label="Salvar"
      [isSmall]="true"
      (rocketClick)="onSave()"
    ></rocket-button>
  </rocket-modal-footer>
</rocket-modal>
