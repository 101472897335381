<div *ngIf="!loading; else loadingRef" class="d-flex h-100">
  <div *ngIf="companyInfo; else noInfo" class="d-flex flex-column w-100">
    <div class="details d-flex flex-column h-100">
      <div class="d-flex flex-column h-100">
        <div class="fw-bold tabs d-flex gap-2">
          <span
            [ngClass]="{
              'text-brand-primary': tab.cod === selectedTab.cod,
              'text-neutral-medium': tab.cod !== selectedTab.cod
            }"
            class="cursor-pointer"
            *ngFor="let tab of tabs"
            (click)="selectTab(tab)"
            >{{ tab.title }}</span
          >
        </div>
        <div class="contain h-100 mt-3">
          <ng-container [ngSwitch]="selectedTab.cod">
            <ng-container *ngSwitchCase="'ATIVO'">
              <app-stock-info [companyInfo]="companyInfo"></app-stock-info>
            </ng-container>
            <ng-container *ngSwitchCase="'INDICES'">
              <app-stock-indexes 
                [stockSelected]="_stockSelected"
                [refComponent]="refComponent"
              ></app-stock-indexes>
            </ng-container>
            <ng-container *ngSwitchCase="'EXECUTIVOS'">
              <executive-board
                [type]="selectedTab.cod"
                [stockSelected]="_stockSelected"
              ></executive-board>
            </ng-container>
            <ng-container *ngSwitchCase="'CONSELHO'">
              <executive-board
                [type]="selectedTab.cod"
                [stockSelected]="_stockSelected"
              ></executive-board>
            </ng-container>
            <ng-container *ngSwitchCase="'ACIONISTAS'">
              <app-acionistas-subsidiaria
                [type]="selectedTab.cod"
                [stockSelected]="_stockSelected"
              ></app-acionistas-subsidiaria>
            </ng-container>
            <ng-container *ngSwitchCase="'SUBSIDIARIA'">
              <app-acionistas-subsidiaria
                [type]="selectedTab.cod"
                [stockSelected]="_stockSelected"
              ></app-acionistas-subsidiaria>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loadingRef>
  <div class="h-100">
    <app-loading></app-loading>
  </div>
</ng-template>

<ng-template #noInfo>
  <div class="h-100 w-100 d-flex justify-content-center align-items-center">
    <app-no-info></app-no-info>
  </div>
</ng-template>
