import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  OnboardService,
  UpdateFieldOnboardInterface,
} from '@shared/services/api/trademap/onboard';
import { filter, debounceTime, Observable } from 'rxjs';
import { CreateForm } from './base';
import { validateCpf } from '@shared/validators/functions';
import { formatCPF } from 'src/app/utils/utils.functions';
import {
  MIN_ACCEPTABLE_AGE,
  isAdultAge,
} from '@shared/validators/functions/validateDates';
import { PartnersService } from '@shared/services/core/partners-services/partners.service';
import { ONBOARD_PARTNERS_ORIGIN } from '@shared/services/core/partners-services/partners.const';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styles: [
    `
      :host(app-create) {
        ::ng-deep input[type='date']::-webkit-calendar-picker-indicator {
          display: none;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateComponent extends CreateForm implements OnInit, OnDestroy {
  public maxDate!: any;
  public minDate!: any;
  public onboardError: { hasError: boolean; message: string } = {
    hasError: false,
    message: '',
  };

  public isLoading = false;
  public disableSubmitButton = false;
  public showDatePicker = false;
  public isUserRegistered = false;
  public defaultOrigin = ONBOARD_PARTNERS_ORIGIN['rocket'];
  public selectedDate = new Date();

  private _pageReloaded = false;
  private _navToNextStep = false;

  constructor(
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private _onboardService: OnboardService,
    private _cdr: ChangeDetectorRef,
    private _partnerService: PartnersService,
    fb: FormBuilder
  ) {
    super(fb);
    this._router.events
      .pipe(
        filter(
          (route): route is NavigationEnd => route instanceof NavigationEnd
        )
      )
      .subscribe((routeEvent) => {
        this._pageReloaded =
          routeEvent.id === 1 &&
          routeEvent.url === routeEvent.urlAfterRedirects;
      });
  }

  @HostListener('window:beforeunload')
  canDeactivate() {
    return this.isUserRegistered && !this._pageReloaded;
  }

  ngOnInit() {
    this._pageReloaded = false;
    this._setFormValuesUsingSessionData();
    this._setDateMinAndMax();
    this._fieldsObservable();
  }

  ngOnDestroy(): void {
    if (this.isUserRegistered || !this._navToNextStep)
      this._onboardService.clearOnboardData();
  }

  public redirToLogin(): void {
    this._router.navigateByUrl('auth/login');
  }

  get onboardSessionData(): boolean {
    return (
      this._onboardService.cdAccessToken &&
      this._onboardService.name &&
      this._onboardService.email
    );
  }

  private _setFormValuesUsingSessionData(): void {
    if (!this.onboardSessionData) return;
    const { cdAccessToken, name, email, document, vlBirthday, password } =
      this._onboardService;
    this._onboardService.onboardData.setInitialOnboard(cdAccessToken);
    this.name.setValue(name);
    this.email.setValue(email);
    this.document.setValue(formatCPF(document));
    this.vl_birthdate.setValue(this._VMDateToInputDate(vlBirthday));
    this.password.setValue(password);
  }

  private _VMDateToInputDate(date: string): string {
    //YYYYMMDD -> YYYY-MM-DD
    return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(
      6,
      8
    )}`;
  }

  private _fieldsObservable(): void {
    this._subscribeToValueChanges(this.onNameChanges, this.updateOnboardField);
    this._subscribeToValueChanges(
      this.onEmailChange,
      this.verifyDuplicityEmail
    );
    this._subscribeToValueChanges(
      this.onDocumentChange,
      this.onGetUserRegisteredHandler
    );
    this._subscribeToValueChanges(
      this.onDtNascimentoChanges,
      this.updateOnboardField
    );
    this._subscribeToValueChanges(
      this.onConfirmPasswordChanges,
      this.updateOnboardField
    );
  }

  private _setDateMinAndMax(): void {
    const currentDate = new Date();
    this.maxDate = currentDate.toISOString().split('T')[0];
    this.minDate = new Date(
      currentDate.setFullYear(new Date().getFullYear() - MIN_ACCEPTABLE_AGE)
    )
      .toISOString()
      .split('T')[0];
  }

  private _subscribeToValueChanges(
    field: Observable<UpdateFieldOnboardInterface>,
    callback: (params: any) => void
  ) {
    if (field) field.pipe(debounceTime(300)).subscribe(callback);
  }

  private updateOnboardField = (value: UpdateFieldOnboardInterface) => {
    this._subscribeToValueChanges(
      this._onboardService.onUpdateOnboardField(value),
      () => null
    );
  };

  private verifyDuplicityEmail = (value: any) => {
    if (!this.email?.valid) {
      this.isUserRegistered = false;
      return;
    }

    this._onboardService
      .onVerifyDuplicityEmail(value)
      .subscribe((isDuplicate: any) => {
        this.isUserRegistered = isDuplicate;
        if (!isDuplicate) {
          this.updateOnboardField({ field: 'email', value });
        }
      });
  };

  private onGetUserRegisteredHandler = (value: string) => {
    let isValidCPF: boolean = false;
    if (!this.document?.valid) {
      this.isUserRegistered = false;
      return;
    } else {
      isValidCPF = validateCpf(this.document.value);
      this.documentMask = isValidCPF ? '000.000.000-00' : '';
      this.documentMaxLength = isValidCPF ? 15 : 12;
    }

    const document = value.replace(/\D/g, '').padStart(11, '0');
    this._onboardService
      .onGetUserRegistered(document)
      .subscribe((isUserRegistered: any) => {
        this.isUserRegistered = isUserRegistered;
        if (!isUserRegistered) {
          this.updateOnboardField({
            field: 'cnpj_cpf',
            value: document,
            extra: 'CPF',
          });
        }
      });
  };

  public submitForm(): void {
    this.isLoading = true;
    this._savePasswordAndCreateAccount().subscribe({
      next: () => {
        this._navToNextStep = true;
        this._router.navigate(['../welcome'], {
          relativeTo: this._activeRoute,
        });
      },
      error: (err) => {
        this.onboardError = {
          hasError: true,
          message:
            err?.data?.message ??
            'Ocorreu um erro ao salvar a senha informada, tente novamente.',
        };
      },
    });
  }

  private _savePasswordAndCreateAccount(): Observable<any> {
    return this._onboardService.onUpdateOnboardField({
      field: 'password',
      value: this.password.value,
    });
  }

  datePicker() {
    this.showDatePicker = !this.showDatePicker;
    this._cdr.detectChanges();
  }

  datePickerDate(event: any) {
    if (!event.closeOutside) {
      const date = event.date.toJSON().split('T').shift();
      this.vl_birthdate.setValue(date);
      this.vl_birthdate.setErrors(isAdultAge(date) ? null : { invalid: true });
      this.selectedDate = event.date;
    }
    this.showDatePicker = false;
    this._cdr.detectChanges();
  }

  closeDatePicker() {
    this.showDatePicker = false;
    this._cdr.detectChanges();
  }
}
