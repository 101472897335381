import { Injectable } from '@angular/core';
import { system } from '@core/system/system.service';
import { WITHOUT_CONFIG_TOKEN_MSGS } from '@core/workspace';
import { isSimulatorOrLeague } from '@shared/constants/simulator-league.constant';
import { MultibrokerService } from '@shared/services/core/multibroker';
import { BrowserStorageBase } from '@shared/services/core/storage/browser-storage-base';
import { RtPopupService } from '@shared/services/popup.service';
import { Subject, debounceTime, filter, take } from 'rxjs';
import { getRandomInfoArray } from 'src/app/utils/utils.functions';
import { TradeTokenPopupComponent } from './trade-token-popup.component';
import { FlaToastService } from '@shared/rocket-components/toast';
import { TOKEN_AUTHENTICATORS_BY_ID } from '../modal-token-config/types';
import { OtpService } from '@shared/services/api/authentication/v3/otp.service';
import { TTokenPreferenceAuthenticator } from '../modal-token-config/types';

@Injectable({
  providedIn: 'root',
})
export class TradeTokenPopupService {
  private readonly _authentications: any = {
    GOOGLE: {
      name: 'Google',
      icon: './assets/svg/google-authenticator.svg',
      id: 'GOOGLE',
    },
    MICROSOFT: {
      name: 'Microsoft',
      icon: './assets/svg/microsoft-authenticator.svg',
      id: 'MICROSOFT',
    },
    TRADEMAP: {
      name: 'TradeMap',
      icon: './assets/svg/TradeMap.svg',
      id: 'TRADEMAP',
    },
    NITRO: TOKEN_AUTHENTICATORS_BY_ID.NITRO,
  };

  private storage = new BrowserStorageBase(sessionStorage);
  private _tokenPopupSubject = new Subject<any>();
  private _displayingToastToken = false;

  constructor(
    private _otpService: OtpService,
    private _multibrokerService: MultibrokerService,
    private _popupService: RtPopupService,
    private _toast: FlaToastService
  ) {
    this._tokenPopupSubject
      .pipe(
        filter(() => !this._displayingToastToken),
        debounceTime(250)
      )
      .subscribe((params) => this._openTokenPopup(params));
  }

  public validityTradeToken() {
    this._otpService
      .userTokenPreferences()
      .pipe(filter((token) => this._shouldDisplayToken(token)))
      .subscribe({
        next: (token) => this._onLoadAuthenticationMethod(token),
        error: () =>
          this._toast.show({
            isHtml: false,
            type: 'error',
            text: 'Erro ao buscar autenticador.',
          }),
      });
  }

  private _onLoadAuthenticationMethod(
    token: TTokenPreferenceAuthenticator
  ): void {
    if (token === 'UNDEFINED_TOKEN') {
      const msg = getRandomInfoArray(WITHOUT_CONFIG_TOKEN_MSGS);
      this._tokenPopupSubject.next({ msg, isConfig: true });
      return;
    }
    this._prepareTokenPopup(token);
  }

  private _shouldDisplayToken(token: TTokenPreferenceAuthenticator): boolean {
    if (token === 'TRADEMAP') return false;
    const accountSelected = this._multibrokerService.getAccountSelected();
    const saw = this.getSessionSaw();
    return (
      system.brokers?.length > 1 &&
      !saw &&
      accountSelected &&
      accountSelected.id_broker != -1 &&
      !isSimulatorOrLeague(accountSelected.id_broker)
    );
  }

  private _prepareTokenPopup(token: string): void {
    const msg =
      '<div class="fw-bolder">Valide o código do seu Token, para enviar</br>suas ordens com segurança.</div>';
    this._tokenPopupSubject.next({
      msg,
      isConfig: false,
      id: token,
      icon: this._authentications[token].icon,
    });
  }

  private _openTokenPopup(params: any): void {
    this._displayingToastToken = true;
    this._popupService
      .displayComponent(TradeTokenPopupComponent, params)
      .pipe(take(1))
      .subscribe(() => (this._displayingToastToken = false));
  }

  saveSessionSaw() {
    this.storage.setItem('SAW_TRADE_TOKEN_SESSION_KEY', '1');
  }

  getSessionSaw(): boolean {
    const saw = this.storage.getItem('SAW_TRADE_TOKEN_SESSION_KEY');
    return saw ? saw === '1' : false;
  }
}
