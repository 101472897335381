import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { YEARS } from '../../constants/datepicker.constants';

@Component({
  selector: 'fla-datepicker-year',
  template: `
    <div #body class="overflow-auto" [style.height.px]="240">
      <div class="datepicker-year">
        <button
          *ngFor="let year of years; let i = index"
          type="button"
          class="datepicker-year-name"
          [disabled]="(min && year <= min) || (max && year >= max)"
          (click)="onClick(year)"
          [ngClass]="{ selected: selectedYear === year }"
        >
          {{ year }}
        </button>
      </div>
    </div>
  `,
})
export class FlaDatepickerYearComponent implements OnInit {
  @Input() min?: number;
  @Input() max?: number;
  @Input() maxYear: number = YEARS.MAX;
  @Input() minYear: number = YEARS.MIN;
  @Input() selectedYear!: number;
  @ViewChild('body') body!: ElementRef;
  @Output() flaClick = new EventEmitter<any>();

  get years(): number[] {
    let list: number[] = [];
    for (let index = this.minYear; index <= this.maxYear; index++) {
      list.push(index);
    }
    return list;
  }
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const selected = this.body.nativeElement.querySelector('.selected');
      this.body.nativeElement.scrollTo(
        0,
        selected?.getBoundingClientRect().y! - 280
      );
    }, 100);
  }

  onClick(year: number) {
    let yearNumber: number;
    if (year < this.selectedYear) {
      yearNumber = this.selectedYear - year;
      yearNumber = -Math.abs(yearNumber);
    } else {
      yearNumber = year - this.selectedYear;
    }

    this.flaClick.emit(yearNumber);
  }
}
