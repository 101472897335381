import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';
import { OptionsComponents } from '../component-list';

export const BUSINESS_PROFILE_COMPONENT: any = (options?: OptionsComponents) => ({
  name: 'Raio-X',
  id: COMPONENTS_NAMES_ENUM.BUSINESS_PROFILE,
  icon: '',
  cod: 'BUSINESS_PROFILE_COMPONENT',
  data: null,
  close: true,
  fullscreen: true,
  link: true,
  widthDesk: 769,
  heightDesk: 530,
  unitOfMeasurement: options?.unit ?? 'px',
  height: options?.height ?? 530,
  width: options?.width ?? 769,
  minWidth: '769px',
  minHeight: '530px',
  left: options?.left ?? 'calc(15% + 5px)',
  top: options?.top ?? '0px',
  label: 'Raio-X',
  noPublished: false,
  borderClass: 'border-top border-neutral-stronger',
  iconSvg: 'RAIOX',
})
