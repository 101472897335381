import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from '@core/models';
import { IFundsService } from '@shared/components/rent/interface/rent.interface';
import { CompanyInfoCacheService } from '@shared/services/core/cache/company-info-cache.service';
import { CompanyMembersCacheService } from '@shared/services/core/cache/company-members-cache.service';
import { IndicatorHistoryWithAverageCacheService } from '@shared/services/core/cache/indicator-history-with-average-cache.service';
import { IndicatorsLayoutCacheService } from '@shared/services/core/cache/indicators-layout-cache.service';
import { RestService } from '@shared/services/rest';
import {
  Observable,
  catchError,
  firstValueFrom,
  map,
  of,
  shareReplay,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FundamentalService extends RestService {
  override _url: string = 'api/trademap/v2/fundamental';
  private _stockFunds: Dictionary<any> = new Dictionary<any>();

  constructor(
    private companyInfoCacheService: CompanyInfoCacheService,
    private indicatorsLayoutCacheService: IndicatorsLayoutCacheService,
    private indicatorHistoryWithAverageCacheService: IndicatorHistoryWithAverageCacheService,
    private companyMembersCacheService: CompanyMembersCacheService,
    http: HttpClient
  ) {
    super(http);
  }

  public findStockFunds(
    cdStock: string,
    strategy: 'long' | 'short',
    limit: number,
    offset: number
  ): Observable<IFundsService> {
    const dictKey = `${cdStock}_${strategy}_${limit}_${offset}`;
    if (this._stockFunds.has(dictKey)) {
      return of({
        data: this._stockFunds.get(dictKey),
        isCache: true,
      });
    }

    return this.get<IFundsService>(
      `${cdStock}/funds?orderBy=vl_final_market&full=false&strategy=${strategy}&limit=${limit}&offset=${offset}`
    ).pipe(
      catchError((error) => of(this.handlerServiceError(error))),
      map((response) => {
        if (response.error || !response.data.permission.allowed) throw response;
        this._stockFunds.set(dictKey, response.data.data);
        return response.data;
      })
    );
  }

  companyInfo(cd_stock: string, id_exchange: number = 1) {
    const key = `${cd_stock}:${id_exchange}`;
    let request$: any = this.companyInfoCacheService.getValue(key);
    if (!request$) {
      request$ = this.post('company-info', { cd_stock, id_exchange }).pipe(
        shareReplay(1)
      );
      this.companyInfoCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }

  public getIndicatorsLayout(cdStock: string, idExchange: number = 1) {
    const key = `${cdStock}:${idExchange}`;
    let request$: any = this.indicatorsLayoutCacheService.getValue(key);
    if (!request$) {
      request$ = this.get(
        `indicators/layout?cd_stock=${cdStock}&id_exchange=${idExchange}`
      ).pipe(shareReplay(1));
      this.indicatorsLayoutCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }

  public getIndicatorHistoryWithAverage(
    cd_stock: string,
    id_exchange: number,
    indicador: string,
    id_company_type: number = 2
  ) {
    const key = `${cd_stock}:${id_exchange}:${indicador}:${id_company_type}`;
    let request$: any =
      this.indicatorHistoryWithAverageCacheService.getValue(key);
    if (!request$) {
      request$ = this.post('get-indicator-history-with-average', {
        cd_stock,
        id_exchange,
        indicador,
        id_company_type,
      }).pipe(shareReplay(1));
      this.indicatorHistoryWithAverageCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }

  public getCompanyIndicesHistory(params: any) {
    const key = `${params.stock.toString()}:${params.id_company_type}:${
      params.indicadores
    }:${params.papeis}`;
    let request$: any =
      this.indicatorHistoryWithAverageCacheService.getValue(key);
    if (!request$) {
      request$ = this.post('company-indices-history', params).pipe(
        shareReplay(1)
      );
      this.indicatorHistoryWithAverageCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }

  public getCompanyMembers(cdStock: string) {
    const key = `${cdStock}`;
    let request$: any = this.companyMembersCacheService.getValue(key);
    if (!request$) {
      request$ = this.get(`company/members?cd_stock=${cdStock}`).pipe(
        shareReplay(1)
      );
      this.companyMembersCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }
}
