<div class="d-flex content-height overflow-hidden w-100">
  <cdk-virtual-scroll-viewport
    #scrollViewport
    itemSize="46"
    class="w-100"
    [ngClass]="css"
  >
    <fla-card class="pr-2 w-100">
      <fla-card-custom
        #card
        [css]="
          'p-1 bg-neutral-strong mt-2' + (hasEvents ? ' cursor-pointer' : '')
        "
        *cdkVirtualFor="let list of lists"
      >
        <div
          class="card-body justify-content-center align-items-center"
          (click)="documentOpen(list)"
        >
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center content-width">
              <div
                class="p-1 dateEventContainer bg-dark mr-2 round d-flex flex-column justify-content-center align-items-center"
                [style.min-width.px]="48"
              >
                <span class="fs-7">{{ list.dateString }}</span>
                <span class="fs-7 text-neutral-medium">{{ list.yearDoc }}</span>
              </div>
              <div class="d-flex w-100">
                <p
                  class="card-title fs-6 d-inline-block text-truncate"
                  [innerHTML]="list.body"
                ></p>
              </div>
            </div>
            <div
              *ngIf="hasEvents"
              class="d-flex align-items-center justify-content-end"
            >
              <fla-icon
                name="chevron_right"
                css="cursor-pointer text-brand-primary"
                size="icon-size-sm"
                [isVmIcon]="false"
              >
              </fla-icon>
            </div>
          </div>
        </div>
      </fla-card-custom>
    </fla-card>
  </cdk-virtual-scroll-viewport>
</div>
