<rocket-modal>
  <rocket-modal-header (onDimissClicked)="backToTheLastModal()">
    <ng-container *ngIf="loadedContent">
      <div class="vstack h-100 w-100 gap-3">
        <div class="align-items-center gap-3 hstack">
          <app-broker-logo
            [logo]="logo"
            [imageSize]="64"
            iconSize="icon-size-xxl"
          ></app-broker-logo>
          <span class="fs-4">+</span>
          <img
            src="../../../../../assets/icons/ico-svg.svg"
            alt="RocketTrader Logo"
            width="64"
            height="64"
          />
        </div>
        <h3 class="fs-2 fw-bold">Conexão Rocket e {{ broker.nmBroker }}</h3>
      </div>
    </ng-container>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <ng-container *ngIf="loadedContent; else loadingTemplate">
      <ng-container *ngIf="broker.stepList">
        <ul class="gap-3 m-0 p-0 pb-5 vstack">
          <ng-container *ngFor="let step of broker.stepList; let index = index">
            <li class="gap-3 hstack">
              <div
                class="bg-brand-primary fs-5 fw-bold round-lg text-center text-neutral-strongest"
                style="width: 24px; height: 24px"
              >
                {{ index + 1 }}
              </div>
              <span [innerHTML]="step"> </span>
            </li>
          </ng-container>
        </ul>
      </ng-container>

      <ng-container *ngIf="broker.termLink">
        <div class="pb-5 fs-6">
          Ao clicar em Quero conectar você terá concordado com os
          <a
            class="text-brand-primary fw-bold fs-6"
            [href]="broker.termLink"
            target="_blank"
          >
            Termos de Condições de Contratação
          </a>
        </div>
      </ng-container>

      <div class="w-100 align-items-center justify-content-between hstack pb-5">
        <ng-container *ngIf="broker.onboardingLink">
          <app-partner-connection-card
            message="Abra agora e depois conecte no Rocket!"
            [title]="'Ainda não tem conta ' + broker.nmBrokerNickname + '?'"
            [logo]="hasLogo ? logo : ''"
            (clicked)="openExternalPage(true)"
            [displayBorder]="true"
          ></app-partner-connection-card>
        </ng-container>

        <ng-container *ngIf="broker.isAvailableHire || broker.connectionLink">
          <button
            type="button"
            class="btn btn-primary"
            (click)="openExternalPage(false)"
          >
            Quero conectar
          </button>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #loadingTemplate>
      <div class="align-items-center gap-2 pb-7 vstack w-100">
        <span class="fw-bold text-center">
          Carregando informações, por favor aguarde.
        </span>
        <div class="spinner-border text-brand-primary" role="status"></div>
      </div>
    </ng-template>
  </app-rocket-modal-body>
</rocket-modal>
