import { GRID_CONFIG_POSITION_ENUM } from '@core/interface';

export class GridConfigOptions {
  constructor(
    handler: (param: GRID_CONFIG_POSITION_ENUM) => void,
    disableHiddenColumn: boolean,
    disableClearFilters: boolean,
    disableResetBtn: boolean,
    totalLineIsVisible: boolean,
    isSorted: boolean,
    isCardView?: boolean
  ) {
    return [
      {
        enum: GRID_CONFIG_POSITION_ENUM.HIDDEN_COLUMN,
        class: 'list-group-item list-group-item-action context-item-button',
        handler: handler,
        label: 'Ocultar Coluna',
        icon: 'visibility_off',
        disabled: disableHiddenColumn,
        bottomDivider: false,
        isShow: !disableHiddenColumn && !isCardView,
      },
      {
        enum: GRID_CONFIG_POSITION_ENUM.TOGGLE_TOTAL_LINE,
        class: 'list-group-item list-group-item-action context-item-button',
        handler: handler,
        label: totalLineIsVisible ? 'Ocultar Totais' : 'Exibir Totais',
        icon: totalLineIsVisible ? 'playlist_remove' : 'playlist_add',
        disabled: false,
        bottomDivider: disableHiddenColumn,
        isShow: true,
      },
      {
        enum: GRID_CONFIG_POSITION_ENUM.PREFERENCES,
        class: 'list-group-item list-group-item-action context-item-button',
        handler: handler,
        label: 'Preferências',
        icon: 'settings',
        disabled: false,
        bottomDivider: true,
        isShow: !disableHiddenColumn && !isCardView,
      },
      {
        enum: GRID_CONFIG_POSITION_ENUM.CLEAR_FILTERS,
        class: 'list-group-item list-group-item-action context-item-button',
        handler: handler,
        label: 'Limpar Filtros',
        icon: 'filter_alt_off',
        disabled: disableClearFilters,
        bottomDivider: false,
        isShow: !isCardView,
      },
      {
        enum: GRID_CONFIG_POSITION_ENUM.CLEAR_SORT,
        class: 'list-group-item list-group-item-action context-item-button',
        handler: handler,
        label: 'Limpar Ordenação',
        icon: 'sort',
        disabled: !isSorted,
        bottomDivider: true,
        isShow: !isCardView,
      },
      {
        enum: GRID_CONFIG_POSITION_ENUM.EXPORT_DATA,
        class: 'list-group-item list-group-item-action context-item-button',
        handler: handler,
        label: 'Exportar dados',
        icon: 'download',
        disabled: false,
        bottomDivider: true,
        isShow: !isCardView,
      },
      {
        enum: GRID_CONFIG_POSITION_ENUM.RESET,
        class: 'list-group-item list-group-item-action context-item-button',
        handler: handler,
        label: 'Restaurar',
        icon: 'refresh',
        disabled: disableResetBtn,
        bottomDivider: false,
        isShow: !isCardView,
      },
      {
        enum: GRID_CONFIG_POSITION_ENUM.HELP,
        class: 'list-group-item list-group-item-action context-item-button',
        handler: handler,
        label: 'Ajuda',
        icon: 'help_outline',
        disabled: true,
        bottomDivider: false,
        isShow: true,
      },
    ];
  }
}
