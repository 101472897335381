import {
  STOCK_TABLE_COMPONENT,
} from '@core/workspace/constants';
import { LayoutBase } from '../base';
import { HEATMAP_COMPONENT } from '@core/workspace/constants/components/heatmap';
import { STOCK_ANALYSIS_COMPONENT } from '@core/workspace/constants/components/stock-analysis';

export class AnaliseAtivos extends LayoutBase {
  override name = 'Análise de Ativos';
  override components = [
    STOCK_TABLE_COMPONENT({
      height: 100,
      width: 20,
    }),
    HEATMAP_COMPONENT({
      height: 100,
      width: 32.5,
      left: 'calc(20% + 2px)',
      unit: '%'
    }),
    STOCK_ANALYSIS_COMPONENT({
      unit: '%',
      height: 100,
      width: 47,
      left: 'calc(53% + 2px)'
    })
  ];
}
