import { Injectable, NgZone } from '@angular/core';
import { randomId } from '@shared/rocket-components/utils';
import { Subject } from 'rxjs';
import { debug } from 'src/app/utils/utils.functions';

@Injectable({
  providedIn: 'root',
})
export class TabControlService {
  private readonly tabKey = 'rkt-tab';
  private readonly tabIdKey = 'rkt-tab-id';
  private readonly tabOpenEvent = 'tab-opened';
  private readonly tabCloseEvent = 'tab-closed';
  private tabId!: string;

  isAnotherTabOpen = new Subject<boolean>();

  constructor(private ngZone: NgZone) {}

  initialize() {
    this.tabId = randomId('ROCKET_TAB');
    localStorage.setItem(this.tabIdKey, this.tabId);
    window.addEventListener('storage', this.handleStorageEvent.bind(this));

    this.setTabOpen();
    window.addEventListener('beforeunload', this.setTabClosed.bind(this));
  }

  private handleStorageEvent(event: StorageEvent) {
    if (event.key === this.tabKey) {
      this.ngZone.run(() => {
        const value = event.newValue;
        if (value === this.tabOpenEvent) {
          this.isAnotherTabOpen.next(true);
        } else if (value === this.tabCloseEvent) {
          this.isAnotherTabOpen.next(false);
        }
      });
    }
  }

  private setTabOpen() {
    this.clearOtherTabs();
    debug(`Opened a new system tab!`);
    localStorage.setItem(this.tabKey, this.tabOpenEvent);
    localStorage.setItem(this.tabIdKey, this.tabId);
  }

  private setTabClosed() {
    localStorage.setItem(this.tabKey, this.tabCloseEvent);
  }

  private clearOtherTabs() {
    localStorage.setItem(this.tabKey, this.tabCloseEvent);
  }

  public getTabId(): string {
    return this.tabId;
  }

  isActiveTab() {
    const tabActive = localStorage.getItem(this.tabIdKey);
    return this.tabId === tabActive;
  }
}
