export const invertedHammerTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  Martelo Invertido é um padrão de candlestick que pode sinalizar uma reversão
  de tendência de baixa para alta. O padrão é formado quando o preço de
  abertura, o preço de fechamento e a sombra superior de um candle estão perto
  do mesmo nível, enquanto a sombra inferior é longa. Esse padrão indica que os
  compradores entraram em cena e conseguiram empurrar o preço para cima, mesmo
  após uma forte queda anterior.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">
  O cálculo do Martelo Invertido é baseado nos preços de abertura, fechamento e
  sombras do candle. É necessário verificar se os preços de abertura e
  fechamento estão próximos um do outro, enquanto a sombra superior é longa e a
  sombra inferior é curta ou inexistente. Se essas condições forem atendidas,
  pode-se considerar que houve uma reversão de tendência de baixa para alta.
</div>
<div class="mb-3">
  A fórmula para identificar o padrão Martelo Invertido é a seguinte:
</div>
<div class="mb-3">
  &bull; O corpo do candle deve ser pequeno ou inexistente, com os preços de
  abertura e fechamento próximos um do outro.<br />
  &bull; A sombra superior deve ser pelo menos duas vezes maior do que o tamanho
  do corpo do candle. <br />
  &bull; A sombra inferior deve ser pequena ou inexistente.
</div>
<div class="mb-3">
  Se essas condições forem atendidas, pode-se considerar que há uma alta
  probabilidade de reversão de tendência de baixa para alta.
</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  Para utilizar o padrão Martelo Invertido, é necessário verificar se as
  condições para a formação do padrão são atendidas. Se o padrão estiver
  presente, pode ser um sinal de reversão de tendência de baixa para alta. No
  entanto, é importante observar outros indicadores e confirmar a mudança de
  tendência antes de tomar qualquer decisão de investimento.
</div>
<div class="mb-3">
  Os investidores podem utilizar o Martelo Invertido como parte de uma
  estratégia de análise técnica para tomar decisões de entrada e saída de
  mercado. No entanto, é importante lembrar que nenhum padrão ou indicador é
  infalível e que a análise técnica deve ser utilizada em conjunto com outras
  formas de análise, como a análise fundamentalista.
</div>
<div class="mb-3">
  <img
    class="w-75"
    src="/assets/indicators/inverted-hammer-como-funciona.png"
  />
</div>
<div class="mb-3">
  Em resumo, o padrão Martelo Invertido pode ser utilizado pelos investidores
  como um sinal de reversão de tendência de baixa para alta, mas é importante
  confirmar a mudança de tendência antes de tomar qualquer decisão de
  investimento.
</div>
<div class="fw-bolder">Como configurar</div>
<div class="fw-bolder">Valores</div>
<div class="mb-3">Sem valores pré-definidos para configurar.</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">Background</div>
<div class="mb-3">
  Pode alternar a visibilidade do Martelo Invertido. Também pode selecionar a
  cor, a espessura e o estilo da linha do Martelo Invertido.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/inverted-hammer-estilo.png"
  />
</div>
`;
