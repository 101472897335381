import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';

import {
  TData,
  TTooltipIndicatorValue,
  TUpdateData,
} from '@shared/tiger-chart/types/tiger-chart.types';
import {
  BaseIndicator,
  CandleCounterData,
  TigerChartIndicatorCandleCounterOptions,
  TTigerChartIndicatorCreateOptions,
  TTigerChartIndicatorParameter,
  TTigerChartIndicatorRenderSeriesConfig,
} from '../indicators.types';
import {
  TIGER_INDICATORS_ENUM,
  TIGER_INDICATOR_PARAMETER_TYPE,
  TIGER_INTERVAL_ENUM,
} from '@shared/tiger-chart/enum/tiger-chart.enum';
import { Subject, Subscription } from 'rxjs';
import { TalibService } from '@shared/tiger-chart/services/talib.service';
import { XyyScaleOffsetFilter } from 'scichart/Charting/Model/Filters/XyyScaleOffsetFilter';
import { setDefaultConfiguration } from '../indicators.functions';
import {
  EHorizontalTextPosition,
  EVerticalTextPosition,
  FastTextRenderableSeries,
  TextDataLabelProvider,
  Thickness,
  XyTextDataSeries,
} from 'scichart';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { CHART_COLORS, CHART_COLORS_LIGHT } from '@shared/tiger-chart/colors';
import { THEMES } from '@shared/services/core/custom-preferences/theme/theme-preferences.interface';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';

const COUNTER_YEAR_INTERVALS = [
  TIGER_INTERVAL_ENUM.ONE_DAY,
  TIGER_INTERVAL_ENUM.ONE_WEEK,
  TIGER_INTERVAL_ENUM.ONE_MONTH,
];

export class TigerChartIndicatorCandleCounterBase implements BaseIndicator {
  protected textDataSeries!: XyTextDataSeries;
  protected movingAverageDataSeries!: XyyScaleOffsetFilter;
  protected textRenderableSeries!: FastTextRenderableSeries;
  protected options!: TigerChartIndicatorCandleCounterOptions;
  protected data: TData;
  protected precision: number = 0;
  protected points: CandleCounterData;
  protected textColor: string = CHART_COLORS.NEUTRAL_SMOOTHER;
  protected lineThickness = 1;

  private baseChart!: TWebAssemblyChart;
  lineNumber!: string;
  type: TIGER_INDICATORS_ENUM = TIGER_INDICATORS_ENUM.CANDLE_COUNTER;
  yAxisId!: string;
  settings: TTigerChartIndicatorParameter[] = [];
  styles: TTigerChartIndicatorParameter[] = [];
  renderSeriesConfig: TTigerChartIndicatorRenderSeriesConfig[] = [];
  onChange = new Subject<null>();
  service: TalibService;
  themeSub$!: Subscription;
  mainLineId = '';
  isNewOnChart = false;
  isDarkTheme = false;
  intervalSelected = TIGER_INTERVAL_ENUM.ONE_MINUTE;
  intervalSub$!: Subscription;

  get defaultRenderConfigs() {
    return {
      label: 'Fonte',
      id: this.mainLineId,
      color: this.getColor,
      propertyColor: 'textColor',
      thickness: 0,
      active: true,
      type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
      dontShowTickness: true,
    } as any;
  }

  get isVisible(): boolean {
    return this.textRenderableSeries.isVisible;
  }

  set isVisible(visible: boolean) {
    this.textRenderableSeries.isVisible = visible;
  }

  get propertiesText(): string {
    return ``;
  }

  get getColor(): string {
    const themeColor = this.isDarkTheme
      ? CHART_COLORS.NEUTRAL_SMOOTHER
      : CHART_COLORS_LIGHT.NEUTRAL_SMOOTHER;
    if (this.isDefaultColor()) return themeColor;
    return this.textColor;
  }

  constructor(
    options: TigerChartIndicatorCandleCounterOptions,
    private themeService: ThemePreferencesService,
    private stockChartService: StockChartService
  ) {
    this.data = options.data;
    this.service = options.service;
    this.precision = 0;
    this.options = options;
    if(options.textColor) this.textColor = options.textColor;
    this.mainLineId = `${this.type}-text-candle-counter`;
    this.isDarkTheme = this.themeService.isDarkTheme();
    this.points = {
      output: [],
      count: [],
    };
    this.renderSeriesConfig = [
      {
        ...this.defaultRenderConfigs,
        id: this.mainLineId,
      },
    ];
    this.settings = [];
    this.styles = [];
    this.lineNumber = options.lineNumber;
    this.themeSub$ = this.themeService
      .themeActiveObservable()
      .subscribe((theme) => {
        this.isDarkTheme = theme === THEMES.dark;
        if (this.isDefaultColor()) {
          this.updateStyles(this.baseChart, this.defaultRenderConfigs);
        }
      });
    this.intervalSub$ = this.stockChartService.intervalSelected
      .asObservable()
      .subscribe((interval) => (this.intervalSelected = interval));
  }

  create(options: TTigerChartIndicatorCreateOptions): BaseRenderableSeries[] {
    this.baseChart = options.base;

    if (this.data.id_point.length > this.points.count.length) {
      const sizeDifference =
        this.data.id_point.length - this.points.count.length;
      for (let i = 0; i < sizeDifference; i++) {
        const lastIndex = this.points.count.length - 1;
        this.points.count.push(`${+this.points.count[lastIndex] + 1}`);
      }
    }

    const xValues = this.data.id_point;
    this.textDataSeries = new XyTextDataSeries(this.baseChart.wasmContext);
    this.textDataSeries.appendRange(
      xValues,
      [...this.points.output.slice(0)],
      [...this.points.count.slice(0)]
    );
    return this.renderSeriesConfig.map((config) => {
      this.textRenderableSeries = new FastTextRenderableSeries(
        this.baseChart.wasmContext,
        {
          id: `${config.id}_${this.lineNumber}`,
          xAxisId: options.xAxisId,
          dataSeries: this.textDataSeries,
          dataLabels: {
            color: this.getColor,
            horizontalTextPosition: EHorizontalTextPosition.Center,
            verticalTextPosition: EVerticalTextPosition.Below,
            style: {
              padding: new Thickness(10, 0, 0, 0),
            },
          },
        }
      );

      (
        this.textRenderableSeries.dataLabelProvider as TextDataLabelProvider
      ).onAfterGenerate = (dataLabels) => {
        for (let i = 0; i < dataLabels.length - 1; i++) {
          const label = dataLabels[i];
          // Shift this label down if it would overlap the next one
          if (
            label.rect.right > dataLabels[i + 1].rect.left &&
            label.rect.top <= dataLabels[i + 1].rect.bottom &&
            label.rect.bottom >= dataLabels[i + 1].rect.top
          ) {
            label.text = '';
          }
        }
      };

      return this.textRenderableSeries;
    });
  }

  updatePoints(): void {
    // empty
  }

  append(xValue: number, data: TUpdateData, fullData: TData) {
    const lastCandleIndex = this.points.count.length - 1;
    const lastCandleDate = new Date(this.data.id_point[lastCandleIndex]);
    const now = new Date(xValue);
    let lastCandleCounter = parseInt(this.points.count[lastCandleIndex]) + 1;

    if (COUNTER_YEAR_INTERVALS.includes(this.intervalSelected)) {
      if (now.getFullYear() != lastCandleDate.getFullYear()) {
        lastCandleCounter = 1;
      }
    } else if (now.getDate() != lastCandleDate.getDate()) {
      lastCandleCounter = 1;
    }

    this.data = fullData;
    this.updatePoints();
    this.points.count.push(lastCandleCounter.toString());
    this.textDataSeries.append(
      xValue,
      this.points.output[this.points.output.length - 1],
      lastCandleCounter.toString()
    );
  }

  insertRange(xValues: number[], fullData: TData): void {
    this.data = fullData;
    this.updatePoints();

    const s = this.baseChart.sciChartSurface.suspendUpdates();
    const arrayLength = xValues.length;
    try {
      this.textDataSeries.insertRange(
        0,
        xValues,
        [...this.points.output.slice(0, arrayLength)],
        [...this.points.count.slice(0, arrayLength)]
      );

      const indexInitialValue = 0;
      for (
        let index = indexInitialValue;
        index < this.points.output.length;
        index++
      ) {
        this.textDataSeries.update(
          index,
          this.points.output[index],
          this.points.count[index]
        );
      }
    } finally {
      s.resume();
    }
  }

  changeVisibility(): void {
    this.textRenderableSeries.isVisible = !this.textRenderableSeries.isVisible;
  }

  update(index: number, data: TUpdateData, fullData: TData) {
    this.data = fullData;
    this.updatePoints();
    this.textDataSeries.update(
      index,
      this.points.output[this.points.output.length - 1],
      this.points.count[this.points.count.length - 1]
    );
  }

  updateSettings(): void {
    const s = this.baseChart.sciChartSurface.suspendUpdates();
    const dataSeriesCount = this.textDataSeries.count();

    const lineValues = [
      ...this.points.output.slice(0, this.data.id_point.length),
    ];

    const candleCount = [
      ...this.points.count.slice(0, this.data.id_point.length),
    ];

    if (dataSeriesCount > lineValues.length) {
      this.textDataSeries.removeRange(
        lineValues.length - 1,
        dataSeriesCount - lineValues.length
      );
    }

    try {
      lineValues.forEach((linePointValue, index) => {
        this.textDataSeries.update(index, linePointValue, candleCount[index]);
      });
    } finally {
      s.resume();
    }
    this.onChange.next(null);
  }

  updateStyles(
    baseChart: TWebAssemblyChart,
    config: TTigerChartIndicatorRenderSeriesConfig
  ): void {
    switch (config.id) {
      case this.mainLineId:
        if(this.textRenderableSeries) {
          this.textColor = config.color;
          this.textRenderableSeries.dataLabelProvider.color = this.getColor;
          this.textRenderableSeries.isVisible = config.active;
          // eslint-disable-next-line no-case-declarations
          const textRenderSeries =
          baseChart.sciChartSurface.renderableSeries.getById(
            `${this.type}-text-candle-counter_${this.lineNumber}`
          );
          textRenderSeries.dataLabelProvider.color = this.getColor;
          textRenderSeries.isVisible = config.active;
        }
        break;
      default:
        break;
    }
    this.onChange.next(null);
  }

  delete() {
    this.baseChart.sciChartSurface.renderableSeries.remove(
      this.textRenderableSeries
    );
    this.themeSub$?.unsubscribe();
    this.intervalSub$?.unsubscribe();
  }

  setValue(property: keyof this, value: any) {
    const prop = this[property];
    if (typeof this[property] == 'number') {
      this[property] = +value as unknown as typeof prop;
    } else {
      this[property] = value;
    }
    this.updateSettings();
  }

  getValuesByIndex(index?: number): TTooltipIndicatorValue[] {
    const precision = this.precision;
    const textValues = this.textDataSeries.textValues;
    const textValue = {
      value: parseInt(textValues[index || textValues.length - 1]),
      precision,
      color: this.getColor,
    };
    return [textValue];
  }

  isDefaultColor = () =>
    this.textColor === CHART_COLORS.NEUTRAL_SMOOTHER ||
    this.textColor === CHART_COLORS_LIGHT.NEUTRAL_SMOOTHER;

  resetConfiguration(): void {
    setDefaultConfiguration(
      this,
      this.baseChart,
      this.options,
      this.settings,
      this.renderSeriesConfig,
      this.styles,
      this.type
    );
  }
}
