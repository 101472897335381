import { validStringValueOrDefault } from 'src/app/utils/utils.book';

export class QuoteFillerTransformer {
  private constructor(private stream: ReadableStream) {}
  private lastQuoteData: any = {};

  private transformer = new TransformStream({
    transform: (chunk, controller) => {
      const currentQuoteData = this.prepareData(chunk);
      controller.enqueue(currentQuoteData);
    },
  });

  private applyTransformer() {
    return this.stream.pipeThrough(this.transformer);
  }

  private prepareData(message: any) {
    const item = message.data.item;
    if (!this.lastQuoteData[item]) this.lastQuoteData[item] = {};
    for (const key in message.data) {
      message.data[key] = validStringValueOrDefault(
        message.data[key],
        this.lastQuoteData[item][key]
      );
    }
    this.lastQuoteData[item] = message.data.isEndOfSnap
      ? this.lastQuoteData[item]
      : message.data;
    return message;
  }

  static transformStream(stream: ReadableStream): ReadableStream {
    return new QuoteFillerTransformer(stream).applyTransformer();
  }
}
