import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TABS_TYPE } from '../../constants/orders-history.const';
import { getVolumeText } from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-orders-history-total-row',
  templateUrl: './orders-history-total-row.component.html',
  styles: [
    `
      :host(app-orders-history-total-row) {
        display: contents;
      }
    `,
  ],
})
export class OrdersHistoryTotalRowComponent implements OnChanges {
  @Input() tabSelected: any;
  @Input() componentKey!: string;
  @Input() disableCancelAllAlertsOrOrders!: boolean;
  @Input() disableCancelAllPositions!: boolean
  @Input() tooltipCancel!: string;
  @Input() set width(width: any) {
    this.isCompact = +width < 480;
  }
  @Output() cancelAllOrdersAndAlerts: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() resetCustodyPosition: EventEmitter<any> =
    new EventEmitter<any>(); 
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  ngOnChanges(changes: SimpleChanges) {
    const { tabSelected } = changes;
    if (tabSelected?.currentValue) {
      this.data = {};
    }
  }

  public isCompact = false;
  public data!: any;

  get isCustody() {
    return ['POSITION', 'SWINGTRADING', 'DAYTRADE'].includes(
      this.tabSelected?.component_type
    );
  }

  get isOrder() {
    return this.tabSelected?.component_type === 'ORDER';
  }

  get isAlert() {
    return this.tabSelected?.component_type === 'ALERTS';
  }

  cancelAll() {
    this.cancelAllOrdersAndAlerts.emit();
  }

  setTotalData = (data: any) => {
    this.data = data;
    if (this.isCustody) {
      switch (this.tabSelected.key) {
        case TABS_TYPE.INTRADIA:
          this.data.vlPriceAvg = getVolumeText(
            this.locale,
            this.data.vl_price_avg_daytrade,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          );
          this.data.pnlDay = this.data.pnl_day_daytrade;
          this.data.qttyFinal = this.data.qtty_final_daytrade;
          this.data.pnlTotal = this.data.pnl_daytrade;
          break;
        case TABS_TYPE.SWINGTRADING:
          this.data.vlPriceAvg = getVolumeText(
            this.locale,
            this.data.vl_price_avg,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          );
          this.data.pnlDay = this.data.pnl_day_swing;
          this.data.qttyFinal = this.data.qtty_final_swing;
          this.data.pnlTotal = this.data.pnl_swing;
          break;
        case TABS_TYPE.POSITION:
          this.data.vlPriceAvg = getVolumeText(
            this.locale,
            this.data.vl_price_avg,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          );
          this.data.pnlDay = this.data.pnl_day;
          this.data.qttyFinal = this.data.qtty_final;
          this.data.pnlTotal = this.data.pnl;
          break;
      }
      this.data.pnlColor = this.getColorByValue(this.data.pnlTotal)
      this.data.pnlDayColor = this.getColorByValue(this.data.pnlDayTotal)
    }

    if(this.isAlert){
      this.data.alertsQtty = `${this.data.dh_insert} Alerta${this.data.dh_insert > 1 ? 's' : ''}`
    }
  };

  resetAllPosition = (event: any) => this.resetCustodyPosition.emit(event)

  private getColorByValue = (value: number) => {
    return +value !== 0 ? (+value > 0 ? 'text-feedback-success' : 'text-feedback-error') : 'text-light';
  }
}
