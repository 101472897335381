import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlaTSize, FlaIconType } from './types/icon.types';

@Component({
  // eslint-disable-next-line
  selector: 'fla-icon[name]',
  templateUrl: './icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: contents;

        .flip {
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }

        .upside-down {
          -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
        }
      }
    `,
  ],
})
export class FlaIconComponent {
  @Input() name: string | undefined = undefined;
  @Input() css: string = '';
  @Input() size: FlaTSize = 'icon-size-sm';
  @Input() iconType: FlaIconType = 'icons';
  @Input() isVmIcon: boolean = false;
  @Input() flip: boolean = false;
  @Input() upsideDown: boolean = false;
}
