import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalAvisoComponent } from './modal-aviso.component';

import {
  FlaCheckModule,
  RocketButtonModule,
  RocketModalModule,
} from '@shared/rocket-components/components/index';

@NgModule({
  declarations: [ModalAvisoComponent],
  imports: [
    CommonModule,
    RocketModalModule,
    FlaCheckModule,
    RocketButtonModule,
  ],
  exports: [ModalAvisoComponent],
  providers: [],
})
export class ModalAvisoModule {}
