import { FlaIToast } from '../interface/toast.interface';

export class FlaToastConfig {
  private element: HTMLElement | undefined = undefined;
  private defautlConfig: FlaIToast = {
    autohide: true,
    animation: true,
    delay: 4000,
    horizintalPosition: 'bottom-0',
    verticalPosition: 'left-0',
    translatePosition: '',
    button: 'close',
    type: 'info',
    text: '',
    iconType: 'icons',
    isHtml: false,
  };

  private set default(config: FlaIToast) {
    this.defautlConfig = Object.assign(this.defautlConfig, config);
  }

  public get toastConfig(): FlaIToast {
    return this.defautlConfig;
  }

  constructor(element: HTMLElement, init?: FlaIToast) {
    this.element = element;
    if (init) {
      this.default = init;
    }
    this.autohide();
    this.delay();
  }

  autohide(): void {
    this.element!.setAttribute(
      'data-bs-autohide',
      this.toastConfig.autohide!.toString()
    );
  }

  delay(): void {
    this.element!.setAttribute(
      'data-bs-delay',
      this.toastConfig.delay!.toString()
    );
  }
}
