<div class="w-100 pb-2">
  <h5>{{ question }}</h5>
  <div class="d-flex pt-1">
    <rocket-button
      type="outline"
      [isSmall]="true"
      [css]="
        (answer != 'baixa' ? 'not-selected ' : '') +
        'mr-1 p-0 px-2 fs-6 min-w-0 fw-normal'
      "
      [label]="answers.baixa"
      (rocketClick)="toggleValue('baixa')"
    >
    </rocket-button>
    <rocket-button
      type="outline"
      [isSmall]="true"
      [css]="
        (answer != 'neutra' ? 'not-selected ' : '') +
        'mr-1 p-0 px-2 fs-6 min-w-0 fw-normal'
      "
      [label]="answers.neutra"
      (rocketClick)="toggleValue('neutra')"
    >
    </rocket-button>
    <rocket-button
      type="outline"
      [isSmall]="true"
      [css]="
        (answer != 'alta' ? 'not-selected ' : '') +
        'p-0 px-2 fs-6 min-w-0 fw-normal'
      "
      [label]="answers.alta"
      (rocketClick)="toggleValue('alta')"
    >
    </rocket-button>
  </div>
</div>
