// trade-row.component.ts
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  SimpleChanges,
  OnChanges,
  computed,
  signal,
} from '@angular/core';
import { TradeRowData } from './model/trade-row-data.model';
import { TimesTradesData } from '../../model/times-trades.model';
import { ISearchStock } from '@core/interface';
import { ITradesColorsHighlights } from '../interface/trades-filter.interface';

@Component({
  selector: '[app-trade-row]',
  templateUrl: './trade-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TradeRowComponent implements OnDestroy, OnChanges {
  @Input() data!: any;
  @Input() showMs: boolean = false;
  @Input() stock!: ISearchStock;
  @Input() highlightColors: ITradesColorsHighlights[] = [];
  @Input() displayHighlights: boolean = true;

  tradeData!: TradeRowData;
  formattedData: any;
  private _value = signal(0);
  private _hightlightColorsArray = signal<number[]>([]);
  private _hightlightColorsSignal = signal<ITradesColorsHighlights[]>([]);

  constructor(private cdr: ChangeDetectorRef) {
    this.cdr.detach();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] || changes['showMs']) {
      this.tradeData = this.formatData(this.data, this.showMs);
      this._value.set(+this.tradeData.qtty.replace(/\D/g, ''));
      this.cdr.detectChanges();
    }

    if (changes['highlightColors'] || changes['displayHighlights']) {
      this._configureHighlights();
    }
  }

  ngOnDestroy(): void {
    this.cdr.reattach();
  }

  private formatData(data: TradeRowData, showMilliseconds: boolean): any {
    // Perform your data formatting here based on showMilliseconds
    const formattedData = new TimesTradesData(
      data,
      this.stock,
      showMilliseconds
    );
    return formattedData;
  }

  private _configureHighlights(): void {
    if (!this.displayHighlights) {
      this._hightlightColorsSignal.set([]);
      this._hightlightColorsArray.set([]);
      this.cdr.detectChanges();
      return;
    }
    this._hightlightColorsSignal.set(this.highlightColors);
    this._hightlightColorsArray.set(
      this.highlightColors.map((item) => item.qtty)
    );
    this.cdr.detectChanges();
  }

  public styleBasedOnValue = computed(() => {
    if (!this._hightlightColorsArray().length) return;
    for (
      let index = this._hightlightColorsArray().length, len = 0;
      index >= len;
      index--
    ) {
      if (this._value() >= this._hightlightColorsArray()[index]) {
        const color = this._hightlightColorsSignal()[index].color;
        return {
          'background-color': color,
          color: this._getContrastingColor(color),
        };
      }
    }
    return {};
  });

  private _getContrastingColor(bgColor: string): string {
    if (!bgColor) return 'var(--vm-neutral-smoothest)';
    const r = parseInt(bgColor.slice(1, 3), 16);
    const g = parseInt(bgColor.slice(3, 5), 16);
    const b = parseInt(bgColor.slice(5, 7), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.5
      ? 'var(--vm-neutral-strongest)'
      : 'var(--vm-neutral-smoothest)';
  }
}
