export const typicalPriceTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  O indicador Typical Price, ou Preço Típico em português, é um indicador de
  análise técnica que utiliza a média ponderada do preço de cada vela em um
  período para indicar a tendência do mercado.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">
  O cálculo do Preço Típico é feito pela seguinte fórmula: (high + low + close)
  / 3, onde high é o preço mais alto, low é o preço mais baixo e close é o preço
  de fechamento da vela. Este indicador é útil para suavizar a volatilidade dos
  preços e revelar a tendência geral do mercado. Ele pode ser usado em conjunto
  com outros indicadores para confirmar sinais de compra ou venda.
</div>
<div class="mb-3">
  Um exemplo de cálculo do Preço Típico seria para uma vela com um preço mais
  alto de 50, um preço mais baixo de 40, e um preço de fechamento de 45. A
  fórmula seria: (50 + 40 + 45) / 3 = 45. Portanto, o Preço Típico para essa
  vela seria de 45.
</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  O Preço Típico é útil para suavizar a volatilidade dos preços e revelar a
  tendência geral do mercado. Ele pode ser usado em conjunto com outros
  indicadores para confirmar sinais de compra ou venda.
</div>
<div class="mb-3">
  Para usar o Preço Típico, você pode plotá-lo em um gráfico de preços junto com
  outros indicadores, como médias móveis ou bandas de Bollinger. É importante
  observar a direção do Preço Típico para determinar a tendência do mercado e,
  em seguida, usar outros indicadores para confirmar a direção e procurar
  oportunidades de compra ou venda quando a tendência é forte. Como com qualquer
  indicador de análise técnica, é importante fazer sua própria pesquisa e
  análise antes de tomar decisões de investimento.
</div>
<div class="mb-3">
  <img class="w-75" src="/assets/indicators/typical-price-como-funciona.png" />
</div>
<div class="fw-bolder">Como configurar</div>
<div class="fw-bolder">Valores</div>
<div class="mb-3">Sem valores pré-definidos para configurar.</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">Linha</div>
<div class="mb-3">
  Pode alternar a visibilidade do Preço Típico. Também pode selecionar a cor, a
  espessura e o estilo da linha do Preço Típico.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/typical-price-estilo.png"
  />
</div>
`;
