import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { FLA_COLORS, randomId } from '@shared/rocket-components/utils';
import { ScichartService } from '@shared/tiger-chart/services/scichart.service';
import {
  CategoryAxis,
  CursorModifier,
  CursorTooltipSvgAnnotation,
  EAutoRange,
  EAxisAlignment,
  ELegendOrientation,
  FastLineRenderableSeries,
  LegendModifier,
  NumberRange,
  NumericAxis,
  RolloverModifier,
  SeriesInfo,
  TWebAssemblyChart,
  XyDataSeries,
} from 'scichart';
import {
  CHART_TITLE_STYLES,
  STOCK_BALANCE_INFOS,
} from '../../constants/rent.constant';
import {
  formatDate,
  formatterNumber,
  getVolumeText,
  isNullOrUndefined,
} from 'src/app/utils/utils.functions';
import { AXIS_ENUM, RENT_PERIOD } from '../../enum/rent.enum';
import {
  ILoanBalance,
  ILoanBalanceService,
} from '../../interface/rent.interface';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import { RentService } from '../../service/rent.service';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  debounceTime,
  filter,
  map,
} from 'rxjs';
import { BusinessProfileService } from '@shared/components/business-profile/business-profile.service';
import { ISearchStock } from '@core/interface';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { THEMES } from '@shared/services/core/custom-preferences/theme/theme-preferences.interface';

@Component({
  selector: 'app-rent-chart',
  templateUrl: './rent-chart.component.html',
  styleUrls: ['./rent-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RentChartComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef;
  @Input() cdStock!: string;
  @Input() stockSelected!: ISearchStock;

  public _cdStock!: string;
  public refChart: string = randomId('RENT_CHART');
  public isLoading: boolean = true;
  public errorOnDisplayChart: boolean = false;
  public stockBalanceFields = STOCK_BALANCE_INFOS;
  private _scichartService!: ScichartService;
  private _baseChart!: TWebAssemblyChart;
  private _candleXAxis!: CategoryAxis;
  public stockBalanceData!: any; // IStockLoanBalance
  public CHART_Y_AXIS: any = {
    QUANTITY: null,
    VOLUME: null,
    RATE: null,
    IBOV: null,
    STOCK: null,
  };
  private xAxisValues: number[] = []
  public stockBalanceInfos!: ILoanBalanceService;
  private _subscription$!: Subscription;
  private start$ = new BehaviorSubject<boolean>(false);
  private started$!: Subscription;
  private _loadChartSubject = new Subject<void>();
  private theme$!: Subscription;
  // Periods
  public periods = [
    {
      label: '12M',
      value: RENT_PERIOD.one_year,
    },
    {
      label: '2A',
      value: RENT_PERIOD.two_year,
    },
    {
      label: '5A',
      value: RENT_PERIOD.five_year,
    },
    {
      label: 'Tudo',
      value: RENT_PERIOD.history,
    },
  ];
  public periodSelected: RENT_PERIOD = RENT_PERIOD.one_year;

  public isDarkMode: boolean = false;

  private _lineVisibilityPreference: any = {
    QUANTITY: true,
    STOCK: false,
    VOLUME: false,
    RATE: false,
    IBOV: false,
  };

  get chartLabelColor() {
    return this.isDarkMode
      ? CHART_COLORS.NEUTRAL_SMOOTHEST
      : CHART_COLORS.NEUTRAL_STRONGEST;
  }

  constructor(
    private _rentService: RentService,
    private stockChartService: StockChartService,
    private cdr: ChangeDetectorRef,
    private businessProfileService: BusinessProfileService,
    private _themeService: ThemePreferencesService
  ) {
    this._scichartService = new ScichartService(undefined, stockChartService);
    this._loadChartSubject.pipe(debounceTime(120)).subscribe(() => {
      this._getBalance();
      this.start$.next(true);
    });
  }

  ngAfterViewInit() {
    this.start$.next(true);
    this.theme$ = this._themeService
      .themeActiveObservable()
      .pipe(filter(() => this.start$.getValue()))
      .subscribe((theme: string) => {
        this._updateBrokerTooltipTheme(theme === THEMES.dark);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { cdStock, stockSelected } = changes;
    if (cdStock?.currentValue) this._initializeComponent(cdStock.currentValue);
    if (stockSelected?.currentValue)
      this._initializeComponent(stockSelected.currentValue.cd_stock);
  }

  ngOnDestroy(): void {
    this._baseChart.sciChartSurface.delete();
    this._subscription$ && this._subscription$.unsubscribe();
    this.started$ && this.started$.unsubscribe();
    this._loadChartSubject.unsubscribe();
    this.theme$ && this.theme$.unsubscribe();
  }

  private _initializeComponent(cdStock: string): void {
    this.started$ && this.started$.unsubscribe();
    this._loadChartSubject.next();
    this._cdStock = cdStock;
  }

  private _getBalance = (): void => {
    this._subscription$ = this._rentService
      .stockBalance(this._cdStock, this.periodSelected)
      .pipe(map((response) => response.result))
      .subscribe({
        next: (response) => {
          if (
            !response.result.length &&
            !response.resultIbov.length &&
            !response.resultSelectedStock.length
          ) {
            this.isLoading = false;
            this.errorOnDisplayChart = true;
            this.cdr.detectChanges();
            return;
          }
          this.stockBalanceInfos = response;
          this._buildStockBalanceInfos(
            response.result[response.result.length - 1]
          );
          this.started$ = this.start$.subscribe((data) => {
            if (data) {
              if (!this._baseChart) {
                this._buildChart();
              } else {
                this._baseChart.sciChartSurface.renderableSeries.clear();
                const {
                  maxQttyValue,
                  maxVolumeValue,
                  maxRateValue,
                  maxIBOVValue,
                  maxStockValue,
                } = this.addInfoChart();
                this._buildYAxis(
                  maxQttyValue,
                  maxVolumeValue,
                  maxRateValue,
                  maxIBOVValue,
                  maxStockValue
                );
              }
            }
          });
          this.cdr.detectChanges();
        },
        error: () => {
          this.isLoading = false;
          this.errorOnDisplayChart = true;
          this.cdr.detectChanges();
        },
      });
  };

  private _buildStockBalanceInfos(infos: ILoanBalance): void {
    this.stockBalanceData = {
      pc_max_interest_rate_taker: `${formatterNumber(
        infos.pc_max_interest_rate_taker
      )} %`,
      pc_min_interest_rate_giver: `${formatterNumber(
        infos.pc_min_interest_rate_giver
      )} %`,
      pc_average_interest_rate_taker: `${formatterNumber(
        infos.pc_average_interest_rate_taker
      )} %`,
      balance_qtty: getVolumeText('pt-BR', infos.balance_qtty),
      qtty_contracts: new Intl.NumberFormat('pt-BR').format(
        infos.qtty_contracts
      ),
      balance_value: getVolumeText('pt-BR', infos.balance_value),
      dt_trade: formatDate(infos.dt_trade),
    };
    this.cdr.detectChanges();
  }

  private addInfoChart(): {
    maxQttyValue: number;
    maxVolumeValue: number;
    maxRateValue: number;
    maxIBOVValue: number;
    maxStockValue: number;
  } {
    const xAxisValues: number[] = [];
    const quantityData: number[] = [];
    const volumeData: number[] = [];
    const rateData: number[] = [];
    const IBOVData: number[] = [];
    const stockData: number[] = [];

    let maxQttyValue: number = 0;
    let maxVolumeValue: number = 0;
    let maxRateValue: number = 0;
    let maxIBOVValue: number = 0;
    let maxStockValue: number = 0;
    this.stockBalanceInfos.result.forEach((data) => {
      xAxisValues.push(data.dt_trade);

      quantityData.push(data.balance_qtty);
      if (data.balance_qtty > maxQttyValue) maxQttyValue = data.balance_qtty;

      volumeData.push(data.balance_value);
      if (data.balance_value > maxVolumeValue)
        maxVolumeValue = data.balance_value;

      rateData.push(data.pc_average_interest_rate_taker);
      if (data.pc_average_interest_rate_taker > maxRateValue)
        maxRateValue = data.pc_average_interest_rate_taker;
    });
    this.stockBalanceInfos.resultIbov.forEach((data) => {
      IBOVData.push(data.vlClose);
      if (data.vlClose > maxIBOVValue) maxIBOVValue = data.vlClose;
    });
    this.stockBalanceInfos.resultSelectedStock.forEach((data) => {
      stockData.push(data.vlClose);
      if (data.vlClose > maxStockValue) maxStockValue = data.vlClose;
    });

    this.xAxisValues = xAxisValues

    this._buildChartInfo(
      AXIS_ENUM.QUANTITY,
      'Quantidade',
      FLA_COLORS.Trademap['pink'],
      xAxisValues,
      quantityData
    );
    this._buildChartInfo(
      AXIS_ENUM.STOCK,
      this._cdStock,
      FLA_COLORS.Trademap['red:'],
      xAxisValues,
      stockData
    );
    this._buildChartInfo(
      AXIS_ENUM.VOLUME,
      'Volume',
      FLA_COLORS.Trademap['brand-primary'],
      xAxisValues,
      volumeData
    );
    this._buildChartInfo(
      AXIS_ENUM.RATE,
      'Taxa',
      FLA_COLORS.Trademap['purple:'],
      xAxisValues,
      rateData
    );
    this._buildChartInfo(
      AXIS_ENUM.IBOV,
      'IBOV',
      FLA_COLORS.Trademap['orange:'],
      xAxisValues,
      IBOVData
    );
    return {
      maxQttyValue,
      maxVolumeValue,
      maxRateValue,
      maxIBOVValue,
      maxStockValue,
    };
  }

  private _buildYAxis(
    maxQttyValue: number,
    maxVolumeValue: number,
    maxRateValue: number,
    maxIBOVValue: number,
    maxStockValue: number
  ) {
    this._baseChart.sciChartSurface.yAxes.clear();
    this.CHART_Y_AXIS = {
      QUANTITY: null,
      VOLUME: null,
      RATE: null,
      IBOV: null,
      STOCK: null,
    };
    // QUANTITY
    this.CHART_Y_AXIS[AXIS_ENUM.QUANTITY] = this._buildChartYAxis(
      AXIS_ENUM.QUANTITY,
      'Quantidade',
      maxQttyValue
    );

    // STOCK
    this.CHART_Y_AXIS[AXIS_ENUM.STOCK] = this._buildChartYAxis(
      AXIS_ENUM.STOCK,
      this._cdStock,
      maxStockValue,
      EAxisAlignment.Right
    );

    // VOLUME
    this.CHART_Y_AXIS[AXIS_ENUM.VOLUME] = this._buildChartYAxis(
      AXIS_ENUM.VOLUME,
      'Volume',
      maxVolumeValue
    );

    // RATE
    this.CHART_Y_AXIS[AXIS_ENUM.RATE] = this._buildChartYAxis(
      AXIS_ENUM.RATE,
      'Taxa Média (%)',
      maxRateValue
    );

    // IBOV
    this.CHART_Y_AXIS[AXIS_ENUM.IBOV] = this._buildChartYAxis(
      AXIS_ENUM.IBOV,
      'IBOV',
      maxIBOVValue,
      EAxisAlignment.Right
    );

    this.isLoading = false;
    this.errorOnDisplayChart = false;
    this.cdr.detectChanges();
  }

  private _buildChart = () => {
    this._updateThemePreference();
    this._scichartService
      .initSciChart(this.refChart)
      .then((res) => {
        this._baseChart = res;
        this._baseChart.sciChartSurface.background = '#FFFFFF00';
        // DOTS
        this._baseChart.sciChartSurface.chartModifiers.add(
          new RolloverModifier({
            showTooltip: false,
            showRolloverLine: false,
          })
        );
        this.cdr.detectChanges();
        const {
          maxQttyValue,
          maxVolumeValue,
          maxRateValue,
          maxIBOVValue,
          maxStockValue,
        } = this.addInfoChart();
        // Candle X Axis
        this._candleXAxis = new CategoryAxis(this._baseChart.wasmContext, {
          drawMajorGridLines: false,
          drawMinorGridLines: false,
          drawMajorBands: false,
          drawMajorTickLines: false,
          drawMinorTickLines: false,
          autoRange: EAutoRange.Always,
          defaultXStep: 86400,
          labelStyle: {
            color: this.chartLabelColor,
          },
        });
        this._candleXAxis.labelProvider.formatLabel = (data: number) =>
          formatDate(data);
        this._candleXAxis.labelProvider.formatCursorLabel = (data: number) =>
          { 
            if(data > this.xAxisValues[this.xAxisValues.length - 1]){
              return formatDate(this.xAxisValues[this.xAxisValues.length - 1])
            }else if(data < this.xAxisValues[0]){
              return formatDate(this.xAxisValues[0])
            }else{
              return formatDate(parseInt(data.toString()))
            }
          };
        this._baseChart.sciChartSurface.xAxes.add(this._candleXAxis);
        this._buildYAxis(
          maxQttyValue,
          maxVolumeValue,
          maxRateValue,
          maxIBOVValue,
          maxStockValue
        );
        this._handlerTooltips();
        // LEGEND
        this._baseChart.sciChartSurface.chartModifiers.add(
          new LegendModifier({
            showCheckboxes: true,
            showSeriesMarkers: true,
            showLegend: true,
            orientation: ELegendOrientation.Horizontal,
            placementDivId: 'legend-div-id',
            modifierGroup: 'modifierGroup_ID',
            isCheckedChangedCallback: (series, isChecked) => {
              this._lineVisibilityPreference[series.yAxisId] = isChecked;
              this.CHART_Y_AXIS[series.yAxisId].isVisible = isChecked;
            },
          })
        );

        this.stockChartService.removeChartLoading$.next({
          remove: true,
          ref: this.refChart,
        });
        this.isLoading = false;
        this.errorOnDisplayChart = false;
        this.cdr.detectChanges();
      })
      .catch((error) => {
        console.error('BUILD_CHART', error);
        this.errorOnDisplayChart = true;
        this.cdr.detectChanges();
      });
  };

  private _buildChartYAxis(
    id: string,
    axisTitle: string,
    maxRange: number,
    axisAlignment: EAxisAlignment = EAxisAlignment.Left
  ): NumericAxis {
    try {
      const candle = new NumericAxis(this._baseChart.wasmContext, {
        id: id,
        axisTitle,
        axisAlignment,
        axisTitleStyle: {
          ...CHART_TITLE_STYLES,
          color: this.chartLabelColor,
        },
        visibleRange: new NumberRange(0, maxRange),
        drawMajorGridLines: false,
        drawMinorGridLines: false,
        drawMajorTickLines: false,
        drawMinorTickLines: false,
        drawMajorBands: false,
        autoTicks: true,
        autoRange: EAutoRange.Always,
        majorDelta: 3,
        minorDelta: 2,
        labelStyle: {
          ...CHART_TITLE_STYLES,
          color: this.chartLabelColor,
        },
        growBy: new NumberRange(0.1, 0.2),
        lineSpacing: 1.5,
        isVisible: this._lineVisibilityPreference[id],
      });
      candle.labelProvider.formatLabel = (param: number) =>
        this._formatValue(id, param);
      candle.labelProvider.formatCursorLabel = (param: number) =>
        this._formatValue(id, param);
      this._baseChart.sciChartSurface.yAxes.add(candle);
      this.cdr.detectChanges();

      return candle;
    } catch (error) {
      this.errorOnDisplayChart = true;
      console.error('Y_AXIS', error);
      this.cdr.detectChanges();
      return new NumericAxis(this._baseChart.wasmContext);
    }
  }

  private _buildChartInfo(
    yAxisId: string,
    dataSeriesName: string,
    stroke: string,
    xValues: number[],
    yValues: number[]
  ): FastLineRenderableSeries {
    try {
      const line = new FastLineRenderableSeries(this._baseChart.wasmContext, {
        dataSeries: new XyDataSeries(this._baseChart.wasmContext, {
          xValues,
          yValues,
          dataSeriesName,
          containsNaN: false,
          isSorted: true,
        }),
        yAxisId,
        stroke,
        strokeThickness: 2,
        opacity: 1,
        isVisible: this._lineVisibilityPreference[yAxisId],
      });
      this._baseChart.sciChartSurface.renderableSeries.add(line);
      this.cdr.detectChanges();
      return line;
    } catch (error) {
      this.errorOnDisplayChart = true;
      console.error('X_AXIS', error);
      this.cdr.detectChanges();
      return new FastLineRenderableSeries(this._baseChart.wasmContext);
    }
  }
  private _handlerTooltips(): void {
    this._baseChart.sciChartSurface.chartModifiers.add(
      new CursorModifier({
        showTooltip: true,
        tooltipContainerBackground: CHART_COLORS.NEUTRAL_STRONG,
        tooltipTextStroke: CHART_COLORS.NEUTRAL_SMOOTHEST,
        crosshairStroke: CHART_COLORS.NEUTRAL_MEDIUM,
        tooltipSvgTemplate: (
          seriesInfos: SeriesInfo[],
          svgAnnotation: CursorTooltipSvgAnnotation
        ) =>
          this.businessProfileService.tooltipSvgTemplate(
            seriesInfos,
            svgAnnotation,
            250,
            50,
            `rent-chart-tooltip-div-id_${this.refChart}`
          ),
        placementDivId: `rent-chart-tooltip-div-id_${this.refChart}`,
      })
    );
  }

  private _formatValue(
    axisEnum: string,
    value: number,
    index?: number
  ): string {
    if (['QUANTITY', 'VOLUME', 'IBOV'].includes(axisEnum))
      return getVolumeText('pt-BR', value, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });

    if (axisEnum === 'RATE') return `${formatterNumber(value)}%`;

    if ('STOCK' === axisEnum && isNullOrUndefined(index))
      return formatterNumber(value);

    if (['IBOV', 'STOCK'].includes(axisEnum) && !isNullOrUndefined(index)) {
      return `${formatterNumber(value)} | ${formatterNumber(
        this.stockBalanceInfos[
          axisEnum === 'IBOV' ? 'resultIbov' : 'resultSelectedStock'
        ][index!].variacao
      )}%`;
    }
    return value.toString();
  }

  public selectPeriod(period: { label: string; value: RENT_PERIOD }): void {
    if (period.value === this.periodSelected) return;
    this.periodSelected = period.value;
    this._loadChartSubject.next();
  }

  private _updateThemePreference() {
    const isDarkMode = this._themeService.isDarkTheme();
    this.isDarkMode = isDarkMode;
    this.cdr.detectChanges();
  }

  private _updateBrokerTooltipTheme(darkMode: boolean): void {
    this.isDarkMode = darkMode;
    this._candleXAxis.labelStyle = {
      color: this.chartLabelColor,
    };
    this._candleXAxis.axisBandsFill = darkMode ? 'red' : 'purple';
    this._baseChart.sciChartSurface.yAxes.asArray().forEach((axe) => {
      (axe.labelStyle = { color: this.chartLabelColor }),
        (axe.axisTitleStyle = { color: this.chartLabelColor });
    });
    this.cdr.detectChanges();
  }
}
