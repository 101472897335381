import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { deepClone, randomId } from '@shared/rocket-components/utils';
import { BarsDynamicColors, CHART_COLORS } from '@shared/tiger-chart/colors';
import { ScichartService } from '@shared/tiger-chart/services/scichart.service';
import {
  CursorModifier,
  CursorTooltipSvgAnnotation,
  EAxisAlignment,
  ELabelAlignment,
  FastColumnRenderableSeries,
  NumericAxis,
  SeriesInfo,
  TWebAssemblyChart,
  TextLabelProvider,
  Thickness,
  XyDataSeries,
} from 'scichart';
import { descendingSort, getVolumeText } from 'src/app/utils/utils.functions';
import { periodFields, periods } from '../../consts/period';
import { FlaSelectComponent } from '@shared/rocket-components/components';
import { Subscription, delay } from 'rxjs';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { THEME_TIMER } from '../../flow-investors.component';
import { BusinessProfileService } from '@shared/components/business-profile/business-profile.service';
import { THEMES } from '@shared/services/core/custom-preferences/theme/theme-preferences.interface';

@Component({
  selector: 'app-acumulado-b3',
  templateUrl: './acumulado-b3.component.html',
  styleUrls: ['./acumulado-b3.component.scss'],
})
export class AcumuladoB3Component implements OnDestroy {
  @ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef;
  @Input() public set data(data: any) {
    if (data) {
      this._data = data;
      this.startChart();
    }
  }
  private _data!: any;
  public periodSelected: string = 'oneweek';
  public periodSelectedLabel: string = '1 Semana';
  public periodFields: any = periodFields;
  public refChart: string = randomId('CHART_RANKING_BROKER');
  scichartService!: ScichartService;
  baseChart!: TWebAssemblyChart;
  private _candleYAxis!: NumericAxis;
  private _candleXAxis!: NumericAxis;
  @ViewChild('select') select!: FlaSelectComponent;
  public periodsSelector: any = periods;
  private _theme$!: Subscription;
  public showTooltip: boolean = true;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private stockChartService: StockChartService,
    private cdr: ChangeDetectorRef,
    private _themeService: ThemePreferencesService,
    private businessProfileService: BusinessProfileService
  ) {
    this.scichartService = new ScichartService(undefined, stockChartService);
    this._theme$ = this._themeService
      .themeActiveObservable()
      .pipe(delay(THEME_TIMER))
      .subscribe((data) => {
        this.scichartService.changeThemeHandler(
          this.baseChart,
          data === THEMES.dark
        );
      });
  }

  ngOnDestroy(): void {
    this._theme$ && this._theme$.unsubscribe();
  }

  public changePeriod(event: any) {
    this.periodSelected = event.id;
    this.periodSelectedLabel = event.label;
    this.showTooltip = false;
    this.cdr.detectChanges();
    this.startChart();
  }

  private startChart() {
    setTimeout(() => {
      this.scichartService.initSciChart(this.refChart).then((res) => {
        this.baseChart = res;

        const columnData: any = [];
        let tempData: any;
        let lastIndex: any;
        let deltaField: any;
        let biggestValue = 0;
        switch (this.periodSelected) {
          case 'oneweek':
          case 'onemonth':
          case 'oneyear':
          case 'year':
            this._data[this.periodFields[this.periodSelected]].map(
              (item: any) => {
                columnData.push({
                  cd_b3_investor_type: item.cd_b3_investor_type,
                  cd_type_investor: item.cd_b3_investor_type.replace(
                    'Pessoa',
                    'P.'
                  ),
                  color: item.sum_delta_volume >= 0 ? '#0fef83' : '#d30d48',
                  sum_delta_volume: item.sum_delta_volume,
                });
                biggestValue = Math.max(
                  biggestValue,
                  Math.abs(item.sum_delta_volume)
                );
              }
            );
            break;
          case 'month':
          case 'week':
            tempData = this._data[this.periodFields[this.periodSelected]];
            lastIndex = Object.keys(tempData);
            deltaField =
              this.periodSelected === 'week'
                ? 'delta_volume'
                : 'sum_delta_volume';

            tempData[lastIndex[lastIndex.length - 1]].map((item: any) => {
              columnData.push({
                cd_b3_investor_type: item.cd_b3_investor_type,
                cd_type_investor: item.cd_b3_investor_type.replace(
                  'Pessoa',
                  'P.'
                ),
                color: item[deltaField] >= 0 ? '#0fef83' : '#d30d48',
                sum_delta_volume: item[deltaField],
              });
              biggestValue = Math.max(biggestValue, Math.abs(item[deltaField]));
            });
            break;

          default:
            break;
        }
        columnData.sort((a: any, b: any) =>
          descendingSort(a.cd_b3_investor_type, b.cd_b3_investor_type)
        );
        const hashValues: any = {};
        columnData.forEach((element: any) => {
          //caso a coluna seja menor que 1% do total, ela nao aparece no scichart
          const negative = element.sum_delta_volume < 0 ? -1 : 1;
          const percent = Math.abs(element.sum_delta_volume) / biggestValue;
          if (percent < 0.01) {
            const newVal = biggestValue * 0.01 * negative;
            hashValues[newVal] = deepClone(element.sum_delta_volume);
            element.sum_delta_volume = newVal;
          }
        });
        const labels = columnData.map((item: any) => item.cd_b3_investor_type);
        const labelsDePara = columnData.map(
          (item: any) => item.cd_type_investor
        );
        const labelProvider: any = new TextLabelProvider({
          labels: labelsDePara,
        });

        this._candleXAxis = new NumericAxis(this.baseChart.wasmContext, {
          labelProvider,
          labelStyle: {
            fontSize: 9,
            padding: new Thickness(20, 0, 0, 0),
          },
          drawMajorGridLines: false,
          drawMinorGridLines: false,
          drawMajorBands: false,
          drawMajorTickLines: false,
          drawMinorTickLines: false,
        });

        this._candleYAxis = new NumericAxis(this.baseChart.wasmContext, {
          axisAlignment: EAxisAlignment.Left,
          labelStyle: {
            fontSize: 9,
            alignment: ELabelAlignment.Right,
            padding: new Thickness(0, 15, 0, 0),
          },
          drawMajorGridLines: true,
          drawMinorGridLines: true,
          drawMajorBands: false,
          drawMajorTickLines: false,
          drawMinorTickLines: false,
          maxAutoTicks: 6,
        });

        this._candleYAxis.labelProvider.formatCursorLabel = (value: any) => {
          let newValue = value;
          if (hashValues[value]) {
            newValue = hashValues[value];
          }
          return getVolumeText(this.locale, newValue);
        };

        this._candleYAxis.labelProvider.formatLabel = (value: any) => {
          return getVolumeText(this.locale, value);
        };

        this._candleXAxis.labelProvider.formatCursorLabel = (value: any) =>
          labels[Math.round(value)];

        this.baseChart.sciChartSurface.xAxes.add(this._candleXAxis);
        this.baseChart.sciChartSurface.yAxes.add(this._candleYAxis);
        this.baseChart.sciChartSurface.background = '#FFFFFF00';

        const yValues = columnData.map((item: any) => item.sum_delta_volume);

        const columnSeries: any = new FastColumnRenderableSeries(
          this.baseChart.wasmContext,
          {
            fill: '#00FF0000',
            stroke: '#11111100',
            strokeThickness: 0,
            cornerRadius: 0,
            dataPointWidth: 0.7,
            dataSeries: new XyDataSeries(this.baseChart.wasmContext, {
              xValues: labelsDePara.map((item: any, index: number) => index),
              yValues: yValues,
            }),
            paletteProvider: new BarsDynamicColors(columnData),
          }
        );
        this.showTooltip = true;
        this.cdr.detectChanges();
        this.baseChart.sciChartSurface.renderableSeries.add(columnSeries);
        this.baseChart.sciChartSurface.chartModifiers.add(
          new CursorModifier({
            showTooltip: true,
            tooltipContainerBackground: CHART_COLORS.NEUTRAL_STRONG,
            tooltipTextStroke: 'text-light',
            crosshairStroke: CHART_COLORS.NEUTRAL_MEDIUM,
            tooltipShadow: 'transparent',
            tooltipSvgTemplate: (
              seriesInfos: SeriesInfo[],
              svgAnnotation: CursorTooltipSvgAnnotation
            ) =>
              this.businessProfileService.tooltipSvgTemplate(
                seriesInfos,
                svgAnnotation,
                100,
                30,
                `chart-acumulado-tooltip-div-id_${this.refChart}`,
                {
                  isFlexWrap: false,
                  addZero: false,
                  qttyWrap: 0,
                  showCircle: false,
                  showSeriesName: false,
                }
              ),
          })
        );
        this.stockChartService.removeChartLoading$.next({
          remove: true,
          ref: this.refChart,
        });
      });
    }, 300);
  }
}
