import {
  TigerChartIndicatorTypicalPriceOptions,
  TTigerChartIndicatorCreateOptions,
} from './indicators.types';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { TypicalPriceBase } from './base/typical-price-base';

export class TigerChartIndicatorTypicalPrice extends TypicalPriceBase {
  constructor(options: TigerChartIndicatorTypicalPriceOptions) {
    super(options);
  }

  override create(
    options: TTigerChartIndicatorCreateOptions
  ): BaseRenderableSeries[] {
    this.updatePoints();
    return super.create(options);
  }

  override updatePoints(): void {
    this.points = this.service.call('TYPPRICE', {
      close: this.data.vl_close,
      high: this.data.vl_high,
      low: this.data.vl_low,
      open: this.data.vl_open
    });
  }

  override updateSettings() {
    this.points = this.service.call('TYPPRICE', {
      close: this.data.vl_close,
      high: this.data.vl_high,
      low: this.data.vl_low,
      open: this.data.vl_open
    });

    super.updateSettings();
  }
}