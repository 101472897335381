import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { system } from '@core/system/system.service';
import { BrowserStorageBase } from '@shared/services/core/storage/browser-storage-base';
import { RestService } from '@shared/services/rest';
import { catchError, map, Observable, of } from 'rxjs';

enum INFLUENCERS_ENUMS {
  INFLUENCER_ID = 'RKT_INFLUENCER_ID',
}

@Injectable({
  providedIn: 'root',
})
export class AdjustService extends RestService {
  override _url: string = 'api/adjust/v1/influencers';

  private _storage = new BrowserStorageBase(localStorage);
  private _influencerID: string | null = null;

  set influencerID(id: string | null) {
    this._storage.setItem(INFLUENCERS_ENUMS.INFLUENCER_ID, id);
    this._influencerID = id;
  }

  get influencerID(): string | null {
    if (this._influencerID) return this._influencerID;
    const sessionInfo = this._storage.getItem(INFLUENCERS_ENUMS.INFLUENCER_ID);
    if (!sessionInfo) return null;
    this._influencerID = sessionInfo;
    return sessionInfo;
  }

  constructor(http: HttpClient) {
    super(http);
  }

  public linkInfluencer(id: string): Observable<boolean> {
    return this.post<{ success: boolean; result: boolean }>(
      'handleAdjustEvent',
      {
        idInfluencerLink: id,
        idInvestor: system.userAuthenticated.investor.id_investor,
        idAdjust: '',
        signupStage: '',
        platform: 'WEB',
      }
    ).pipe(
      map((result) => {
        if (result.data && result.data.success) {
          this.clearInfluencerStorageIfExists();
          return true;
        }
        return false;
      }),
      catchError(() => of(false))
    );
  }

  public clearInfluencerStorageIfExists(): void {
    if (!this.influencerID) return;
    this.influencerID = null;
    this._storage.removeItem(INFLUENCERS_ENUMS.INFLUENCER_ID);
  }
}
