import { Routes, RouterModule } from '@angular/router';
import { ModalMeusDadosComponent } from '@core/layout/header/modal-meus-dados/modal-meus-dados.component';
import { getModalViewResolver } from './modal-view.resolver';
import { ModalTokenConfigComponent } from '@core/layout/header/modal-token-config/modal-token-config.component';

const routes: Routes = [
  {
    path: 'meus-dados',
    resolve: getModalViewResolver(ModalMeusDadosComponent),
    component: ModalMeusDadosComponent,
  },
  {
    path: 'token-config',
    resolve: getModalViewResolver(ModalTokenConfigComponent),
    component: ModalTokenConfigComponent,
  }
];

export const ModalViewRoutes = RouterModule.forChild(routes);
