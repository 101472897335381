import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StocksAtAuctionByIndexComponent } from './stocks-at-auction-by-index.component';
import { IndexesToShowStocksAtAuctionComponent } from './components/indexes-to-show-stocks-at-auction/indexes-to-show-stocks-at-auction.component';
import { AuctionStocksByIndexComponent } from './components/auction-stocks-by-index/auction-stocks-by-index.component';
import { NotificationCountComponent } from './components/notification-count/notification-count.component';

@NgModule({
  imports: [
    CommonModule,
    AuctionStocksByIndexComponent,
    IndexesToShowStocksAtAuctionComponent,
    NotificationCountComponent,
  ],
  declarations: [StocksAtAuctionByIndexComponent],
  exports: [StocksAtAuctionByIndexComponent],
})
export class StocksAtAuctionByIndexModule {}
