import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  BROKERAGE_ACTIVITY_DEFAULT_FILTERS,
  BROKERAGE_DETAIL_DEFAULT_FILTERS,
  BROKERAGE_TOP_DEFAULT_FILTERS,
  TAB_FILTERS,
  FLASH_FILTERS_FIELD,
  periods,
} from '../../consts/filters';
import {
  CONFIG_HEADERS,
  HEADERS,
  HEADERS_BROKER,
  HEADERS_CONFIG_STOCKS,
  HEADERS_DEFAULT,
  HEADERS_STOCKS,
} from '../../consts/headers';
import { ColDef } from 'ag-grid-community';
import { BrokerService } from '@shared/services/api/trademap/v1/broker.service';
import { ScichartService } from '@shared/tiger-chart/services/scichart.service';
import {
  CursorModifier,
  CursorTooltipSvgAnnotation,
  DateTimeNumericAxis,
  EAxisAlignment,
  ELabelAlignment,
  FastColumnRenderableSeries,
  FastLineRenderableSeries,
  ICursorModifierOptions,
  NumberRange,
  NumericAxis,
  RolloverModifier,
  SciChartJSDarkv2Theme,
  SeriesInfo,
  TWebAssemblyChart,
  TextLabelProvider,
  Thickness,
  XyDataSeries,
  XySeriesInfo,
  adjustTooltipPosition,
} from 'scichart';
import {
  formatDate,
  getKeyWithHighestValue,
  getVolumeText,
  sort,
} from 'src/app/utils/utils.functions';
import { GridIChangeField } from '@shared/rocket-grid';
import {
  BarsDynamicColors,
  CHART_COLORS,
  DEFAULT_THEME,
  LIGHT_THEME,
} from '@shared/tiger-chart/colors';
import { Subject, debounceTime, filter, map, takeUntil, tap } from 'rxjs';
import { ISearchStock, IWorkSpaceComponet } from '@core/interface';
import { RankingBrokerService } from '../../services/ranking-broker.service';
import { deepClone, randomId } from '@shared/rocket-components/utils';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { HighchartsService } from '../../services/highcharts.service';
import { RocketGridComponent } from '@shared/rocket-grid/rocket-grid.component';
import {
  NUMERIC_AXIS_PREFERENCES,
  SCICHART_TOOLTIP_PREFERENCES,
  TOOLTIP_INFO_FIELD,
} from '../../consts/ranking-broker-part.constant';
import {
  IBrokaregeTopPayload,
  IBrokerActivity,
  IBrokerage,
  IBrokerageActivityPayload,
  IBrokerageDetail,
  IBrokerageDetailPayload,
  IFlashTooltip,
} from '../../interface/ranking-broker.interface';
import {
  RANKING_BROKER_FILTERS,
  RANKING_BROKER_PERIODS,
  RANKING_TABS,
} from '../../enum/ranking-broker.enum';
import { Dictionary } from '@core/models';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { isWebViewContext } from 'src/app/desktop/integration.utils';
@Component({
  selector: 'app-ranking-broker-part',
  templateUrl: './ranking-broker-part.component.html',
  styleUrls: ['./ranking-broker-part.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RankingBrokerPartComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef;
  @ViewChild('tableRanking', { static: true }) tableRanking!: ElementRef;
  @ViewChild('rocketGrid') rocketGrid!: RocketGridComponent;

  @Output() changeStock = new EventEmitter<any>();
  @Output() explode = new EventEmitter<any>();
  @Output() periodUpdated = new EventEmitter<boolean>();

  @Input() public component!: IWorkSpaceComponet;
  @Input() public refComponent!: string;
  @Input() public typeRanking: RANKING_TABS = RANKING_TABS.FLASH;
  @Input() public periodSelected: RANKING_BROKER_PERIODS =
    RANKING_BROKER_PERIODS.TODAY;
  @Input() public stockInput!: ISearchStock | null;
  @Input() set width(value: number | string) {
    this._width = typeof value === 'string' ? parseInt(value) : value;
  }
  @Input() public layoutOptions!: any;
  public _width!: number;

  public columnDefs: ColDef[] = this.setHeaders(HEADERS_DEFAULT, HEADERS);

  public filterOptions = TAB_FILTERS;
  public viewTypeSelected: RANKING_BROKER_FILTERS =
    RANKING_BROKER_FILTERS.VL_NET;
  public paramsBrokerageTop: IBrokaregeTopPayload =
    BROKERAGE_TOP_DEFAULT_FILTERS;
  public paramsBrokerageActivity: IBrokerageActivityPayload =
    BROKERAGE_ACTIVITY_DEFAULT_FILTERS;
  public paramsBrokerageDetail: IBrokerageDetailPayload =
    BROKERAGE_DETAIL_DEFAULT_FILTERS;

  scichartService!: ScichartService;
  baseChart!: TWebAssemblyChart;
  private _stock: ISearchStock | null = null;

  public brokersSelected = new Dictionary<IBrokerage>();
  public percentSymbol: any = ['percentage_buy', 'percentage_sell'];
  public sortingOrder: ('asc' | 'desc' | null)[] = ['desc', 'asc', null];
  private _candleYAxis!: NumericAxis;
  private _candleXAxis!: DateTimeNumericAxis;
  onUpdateField!: GridIChangeField;
  public widthComponent: number = 0;
  public widthObserver!: ResizeObserver;
  public stock!: ISearchStock | null;
  public searchLabel: string = 'Pesquise por ativos';
  public refChart: string = randomId('CHART_RANKING_BROKER');
  public isInit: boolean = true;
  public brokerActivity!: IBrokerActivity;
  public flashFilter: any = FLASH_FILTERS_FIELD;
  public periods: any = periods;
  public brokerExplode: any = null;
  private _chart!: Highcharts.Chart;
  public loading: boolean = true;
  public withoutContent: boolean = false;
  public brokerActive: any = null;
  public rowData: any[] = [];
  public tooltipX: number = 0;
  public tooltipY: number = 0;
  public tooltipWidth: number = 250;
  public seriesToShow: IFlashTooltip[] = [];
  public loadingChart: boolean = false;
  private _initSciChartSubject = new Subject<void>();
  private _resizeGridSubject = new Subject<void>();
  private _getBrokerageTopSubject = new Subject<void>();
  private _buildBrokerageActivityForFlashTabSubject = new Subject<void>();
  public tableFieldIndexer: string = 'id_brokerage';
  public hideCurrentValue: boolean = false;
  public isDarkMode: boolean = false;
  public isBrokerView: boolean = false;
  private _lastActivatedTab!: RANKING_TABS;
  public dateTimeDefaultValue!: number | null;
  public tooltipDateTime: string = '';
  public divWidth = 224;
  public minDivWidth = 224;
  public transform = {
    x: 0,
    y: 0,
  };
  private destroy$ = new Subject<void>();
  private isIgnoreUnselectAll = false;
  isDesktop = false;

  get stockSelected(): ISearchStock {
    return this.component?.metadata?.component?.stock ?? {};
  }

  get statusLink() {
    return this.component.metadata?.headerOptions.link;
  }

  get chartTheme() {
    const theme = this.isDarkMode ? DEFAULT_THEME() : LIGHT_THEME();
    return Object.assign(new SciChartJSDarkv2Theme(), theme);
  }

  get highChartColors() {
    return this.isDarkMode
      ? {
          bg: CHART_COLORS.NEUTRAL_STRONG,
          color: CHART_COLORS.NEUTRAL_SMOOTHEST,
        }
      : {
          bg: CHART_COLORS.NEUTRAL_SMOOTHER,
          color: CHART_COLORS.NEUTRAL_STRONG,
        };
  }

  get periodLabel() {
    return this.periodSelected === RANKING_BROKER_PERIODS.TODAY
      ? 'Hoje -'
      : 'Ontem -';
  }

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    public _brokerService: BrokerService,
    private _rankingBrokerService: RankingBrokerService,
    private stockChartService: StockChartService,
    private cdr: ChangeDetectorRef,
    private _highchartsService: HighchartsService,
    private elementRef: ElementRef,
    private _themeService: ThemePreferencesService
  ) {
    this._initObservables();
    this.isDesktop = isWebViewContext();
    this.scichartService = new ScichartService(undefined, stockChartService);
  }

  private _initObservables = (): void => {
    this._rankingBrokerService.selectAllBrokers$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: { ref: string; selectedAll: boolean }) => {
        value.ref === this.refComponent &&
          this._handleSelectAll(value.selectedAll);
      });
    this._initSciChartSubject
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(() => this.buildSciChart());
    this._resizeGridSubject
      .pipe(
        filter(() => !this.loading),
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.typeRanking === 'BROKER' &&
          (this.widthComponent = deepClone(this.widthComponent + 0.01));
        this.cdr.detectChanges();
      });
    this._getBrokerageTopSubject
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(() => this._callBrokerageTop());
    this._buildBrokerageActivityForFlashTabSubject
      .pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe(() => this._buildBrokerageActivityForFlashTab());
    this._themeService
      .themeActiveObservable()
      .pipe(
        filter(() => !this.isInit),
        takeUntil(this.destroy$)
      )
      .subscribe((theme: string) =>
        this._updateBrokerTooltipTheme(theme === 'dark-theme')
      );
  };

  ngAfterViewInit() {
    this.isDarkMode = this._themeService.isDarkTheme();
    this.widthObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (this.typeRanking === 'BROKER') {
          const cr = entry.contentRect;
          this.widthComponent = cr.width;
          this._chart?.reflow();
        }
      }
    });
    this.widthObserver.observe(this.tableRanking.nativeElement);
    this._setTabColumnsAndFilters(this.typeRanking);
  }

  ngOnChanges(changes: SimpleChanges) {
    const { typeRanking, stockInput, periodSelected, width, layoutOptions } =
      changes;
    if (!this.isInit || !this.brokersSelected.size()) {
      if (typeRanking?.currentValue)
        this._setTabColumnsAndFilters(typeRanking.currentValue);
      if (periodSelected?.currentValue) {
        if (periodSelected.currentValue !== periodSelected?.previousValue)
          this.changePeriod();
        else this.periodUpdated.next(true);
      }
    }

    if (stockInput?.currentValue !== undefined) {
      this.selectStock(stockInput.currentValue);
    }
    if (width?.currentValue && this.isBrokerView) {
      this.divWidth = this._width - 5;
    }
    if (width?.currentValue && !this.isBrokerView) {
      this.updateSizeWidthTable();
    }
    layoutOptions?.currentValue &&
      layoutOptions?.currentValue?.isMaximized === false &&
      this.updateSizeWidthTable();
  }

  ngOnDestroy() {
    this.widthObserver.disconnect();
    this.widthObserver.unobserve(this.tableRanking.nativeElement);
    this.unsubscribeObservables();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private unsubscribeObservables(): void {
    this._resizeGridSubject.unsubscribe();
    this._getBrokerageTopSubject.unsubscribe();
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (
      this.typeRanking !== 'FLASH' ||
      this.loading ||
      this.loadingChart ||
      this.withoutContent ||
      !this.brokersSelected.size()
    )
      return;
    const chartContainer = this.elementRef.nativeElement.querySelector(
      `#${this.refChart}`
    );
    if (!chartContainer) {
      console.error('CHART_CONTAINER_NOT_FOUND');
      return;
    }
    const rect = chartContainer.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;

    if (
      mouseX >= 0 &&
      mouseX <= rect.width &&
      mouseY >= 0 &&
      mouseY <= rect.height
    ) {
      const space = 30;
      const totalRight = event.clientX + this.tooltipWidth + space;
      const isNegativeRight = totalRight >= window.innerWidth;

      if (isNegativeRight) {
        this.tooltipX = mouseX - (space + this.tooltipWidth);
      } else {
        this.tooltipX = mouseX + space;
      }
      if (event.screenY > window.innerHeight) {
        this.tooltipY = event.offsetY - 150;
      } else {
        this.tooltipY = event.offsetY + 15;
      }
    } else {
      this.seriesToShow = [];
    }
  }

  private setHeaders(headers: any, headersParams: any): Array<ColDef> {
    return [...headers, ...this.configHeaders(headersParams)];
  }

  private _setTabColumnsAndFilters(code: RANKING_TABS, force: boolean = false) {
    if (code === this._lastActivatedTab && !force) return;
    this.loading = true;
    this.loadingChart = true;
    this.withoutContent = false;
    this.isBrokerView = false;
    this.divWidth = this.minDivWidth;
    this.viewTypeSelected = RANKING_BROKER_FILTERS.VL_NET;
    if (this.brokerActive) this.goBackListBrokers(true);
    switch (code) {
      case 'FLASH':
        this.columnDefs = this.setHeaders(HEADERS_DEFAULT, HEADERS);
        this.filterOptions = this.filterOptions.filter(
          (item) => !this.percentSymbol.includes(item.code)
        );
        break;
      case 'RANKING':
        this.columnDefs = this.setHeaders(HEADERS_DEFAULT, HEADERS);
        this.filterOptions = TAB_FILTERS;
        this.paramsBrokerageTop.period = this.periodSelected;
        break;
      case 'BROKER':
        this.divWidth = this._width - 5;
        this.columnDefs = this.setHeaders(HEADERS_BROKER, HEADERS);
        this.filterOptions = TAB_FILTERS;
        this.paramsBrokerageTop.period = this.periodSelected;
        this.isBrokerView = true;
        break;
      default:
        break;
    }
    this._lastActivatedTab = code;
    this.cdr.detectChanges();
    this.selectViewType(this.viewTypeSelected, false, true);
    this._getBrokerageTopSubject.next();
  }

  public selectViewType(
    viewTypeCode: RANKING_BROKER_FILTERS,
    buildChart: boolean = false,
    changeTab: boolean = false
  ): void {
    if (viewTypeCode === this.viewTypeSelected && !changeTab) return;
    const data = structuredClone(this.rowData);
    if (
      this._lastActivatedTab === 'FLASH' ||
      this._lastActivatedTab === 'RANKING'
    ) {
      this.realignColumns(viewTypeCode);
    }
    this.filterOptions.map(
      (view) => (view.active = viewTypeCode === view.code)
    );
    this.viewTypeSelected = viewTypeCode;
    data.length && data.sort(this.toSort);

    if (
      this._lastActivatedTab === 'FLASH' ||
      this._lastActivatedTab === 'RANKING'
    ) {
      this._rankingBrokerService.selectAllBrokers$.next({
        ref: this.refComponent,
        selectedAll: false,
      });
      this.brokersSelected.clear();
      const totalBrokers = data.length;
      data.forEach((broker: any, index: number) => {
        let isSelected = index < 6;
        if (RANKING_BROKER_FILTERS.VL_NET === this.viewTypeSelected) {
          isSelected = index < 3 || index > totalBrokers - 4;
        }
        broker.selected = isSelected;
        isSelected && this.brokersSelected.set(broker.id_brokerage, broker);
      });
    }

    this.rowData = data;
    if (this.typeRanking === 'FLASH') {
      this.hideCurrentValue = ['vl_buuy', 'vl_sell'].includes(viewTypeCode);
      this._getBrokerageActivity();
      return;
    }
    this.filterByCol(buildChart);
  }

  private realignColumns(viewTypeCode: RANKING_BROKER_FILTERS): void {
    const columnDefs = this.setHeaders(HEADERS_DEFAULT, HEADERS);
    const index = columnDefs.findIndex(
      (column: any) => column.field === viewTypeCode
    );
    if (index > -1) {
      const column = columnDefs.splice(index, 1);
      columnDefs.splice(2, 0, column[0]);
    }
    this.columnDefs = columnDefs;
  }

  private configHeaders(metadata: any): any {
    const headers: any[] = [];
    const configHeaders: any = CONFIG_HEADERS;
    Object.keys(metadata).forEach((item: any) => {
      const header = configHeaders.find(
        (column: any) =>
          column.field === item || column.headerComponentParams[1] === item
      );
      if (header) {
        headers.push({ ...header, ...metadata[item] });
      }
    });
    return headers;
  }

  private _getBrokerageTop() {
    this.paramsBrokerageTop.period = this.periodSelected;
    return this._brokerService
      .getBrokerageTop(
        this.paramsBrokerageTop,
        this.viewTypeSelected,
        this.stock?.cd_segment === '9999'
      )
      .pipe(
        filter((response) => {
          if (response.brokers.length) return true;
          this._withoutBrokerageData();
          return false;
        }),
        map((response) => {
          const { brokers, selectedDict } = response;
          this.brokersSelected = selectedDict!;
          this.cdr.detectChanges();
          !this.divWidth && (this.divWidth = this.minDivWidth);
          return brokers;
        })
      );
  }

  private _getBrokeragesActivity() {
    this.paramsBrokerageActivity.corretoras = <number[]>(
      this.brokersSelected.keys()
    );
    this.paramsBrokerageActivity.period = this.periodSelected;
    this.cdr.detectChanges();
    return this._brokerService.getBrokerageActivity(
      this.paramsBrokerageActivity,
      this.stock?.cd_segment === '9999'
    );
  }

  private _getBrokeragesDetail(id_brokerage: number) {
    this.loading = true;
    this.withoutContent = false;
    this.paramsBrokerageDetail.idBrokerage = id_brokerage;
    this.paramsBrokerageDetail.period = this.periodSelected;
    this.cdr.detectChanges();
    return this._brokerService.getBrokerageDetail(this.paramsBrokerageDetail);
  }

  private _callBrokerageTop(): void {
    if (this.tableFieldIndexer !== 'id_brokerage') {
      this.rowData = [];
      this.tableFieldIndexer = 'id_brokerage';
      this.cdr.detectChanges();
    }

    this._getBrokerageTop()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.rowData = response;
          if (response.length) {
            this._getBrokerageActivity();
            return;
          }
          this.withoutContent = true;
          this.loadingChart = false;
          this.periodUpdated.emit(true);
          this.cdr.detectChanges();
        },
        error: () => this._withoutBrokerageData(),
        complete: () => {
          !!this.brokersSelected.size() && (this.isInit = false);
          this.loading = false;
          this.cdr.detectChanges();
          this._resizeGridSubject.next();
        },
      });
  }

  private _withoutBrokerageData() {
    this.withoutContent = true;
    this.loading = false;
    this.loadingChart = false;
    this.rowData = [];
    this.periodUpdated.emit(true);
    this.divWidth = 0;
    this.cdr.detectChanges();
  }

  private _getBrokerageActivity() {
    this.loadingChart = true;
    if (this.typeRanking === 'FLASH') {
      this._buildBrokerageActivityForFlashTabSubject.next();
      return;
    }
    this.periodUpdated.emit(true);
    this.startChart();
  }

  private _buildBrokerageActivityForFlashTab(): void {
    if (!this.brokersSelected.size() && !this.isInit) {
      this.loadingChart = false;
      this.periodUpdated.emit(true);
      this.cdr.detectChanges();
      return;
    }
    this._getBrokeragesActivity()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!res.result.brokerageActivityHead.length) {
          this.loadingChart = false;
          this.withoutContent = true;
          this.periodUpdated.emit(true);
          this.cdr.detectChanges();
          return;
        }
        this.brokerActivity = deepClone(res.result);
        this.withoutContent = false;
        this.periodUpdated.emit(true);
        this.cdr.detectChanges();
        this.startChart();
      });
  }

  private filterByCol(buildChart: boolean = false) {
    if (this.rowData.length && buildChart) this.startChart();
  }

  public selectRow(event: any) {
    if (this.typeRanking === 'BROKER') {
      this.brokerExplode = event.data;
      this.explodeChartBroker(event.data);
      return;
    }

    if (event.colDef.field === 'selected') {
      if (this._rankingBrokerService.selectAllBrokers$.value.selectedAll) {
        this.isIgnoreUnselectAll = true;
        this._rankingBrokerService.selectAllBrokers$.next({
          ref: this.refComponent,
          selectedAll: false,
        });
      }
      this._updateBrokersSelected(event.rowIndex, event.data);
    }
  }

  private _updateBrokersSelected(index: number, broker: IBrokerage) {
    const brokerSelected = deepClone(this.rowData[index]);
    brokerSelected.selected = !brokerSelected.selected;
    this.rowData[index] = brokerSelected;
    if (broker.selected) this.brokersSelected.delete(broker.id_brokerage);
    else this.brokersSelected.set(brokerSelected.id_brokerage, brokerSelected);
    this.updateField(brokerSelected);
    if (this.typeRanking === 'FLASH') {
      this._getBrokerageActivity();
      return;
    }
    this.startChart();
  }

  public selectStock(stock: ISearchStock) {
    this.loading = true;
    this.withoutContent = false;
    this.stock = stock ?? null;
    this.paramsBrokerageTop.cd_stock = stock ? stock.cd_stock : null;
    this.paramsBrokerageTop.id_exchange = stock ? stock.id_exchange : null;
    this.paramsBrokerageActivity.cd_stock = stock ? stock.cd_stock : null;
    this.paramsBrokerageActivity.id_exchange = stock ? stock.id_exchange : null;
    this.cdr.detectChanges();
    this._getBrokerageTopSubject.next();
  }

  private updateField(broker: IBrokerage) {
    this.onUpdateField = deepClone({
      idRow: broker.id_brokerage.toString(),
      propsCell: broker,
    });
    this.cdr.detectChanges();
  }

  private _handleSelectAll(selectedAll: boolean) {
    if (this.isInit || this.isIgnoreUnselectAll) {
      this.isIgnoreUnselectAll = false;
      return;
    }
    if (this.brokersSelected.size()) {
      this.brokersSelected.clear();
      this.cdr.detectChanges();
    }
    this.rowData.forEach((broker: IBrokerage) => {
      broker.selected = selectedAll;
      this.updateField(broker);
      if (selectedAll) this.brokersSelected.set(broker.id_brokerage, broker);
    });
    if (this.typeRanking === 'FLASH') {
      this._getBrokerageActivity();
      return;
    }
    this.startChart();
  }

  public changePeriod() {
    this.loading = true;
    this.isInit = true;
    if (this.brokersSelected.size()) this.brokersSelected.clear();
    this._getBrokerageTopSubject.next();
    this.cdr.detectChanges();
  }

  private startChart() {
    if (this.typeRanking === 'BROKER') {
      this._builderDonutChart();
      return;
    }
    if (!this.brokersSelected.size()) {
      this.loadingChart = false;
      return;
    }
    this._initSciChartSubject.next();
  }

  private buildSciChart(): void {
    this.scichartService.initSciChart(this.refChart).then((res) => {
      this.baseChart = res;
      this.baseChart.sciChartSurface.background =
        CHART_COLORS.OPACITY_BACKGROUND;
      let maxRangeValue = 0,
        minRangeValue = 0;

      if (this.typeRanking === 'RANKING') {
        const { minRange, maxRange } = this._brokerRange();
        minRangeValue = minRange - minRange * 0.1;
        maxRangeValue = maxRange * 0.1 + maxRange;
      }

      if (this.typeRanking === 'FLASH') {
        this.brokerActivity.brokerageActivityHead.map((item) => {
          if (this.flashFilter[this.viewTypeSelected]) {
            const xValues: number[] = [];
            const yValues = item.brokerageActivity.map((point: any) => {
              const date = this._pointDate(point.dt_trade, point.hm_trade);
              xValues.push(new Date(date).getTime() / 1000);
              const value = point[this.flashFilter[this.viewTypeSelected]];
              const rangeValue = value < 0 ? Math.abs(value) : value;
              rangeValue > maxRangeValue && (maxRangeValue = rangeValue);
              return value;
            });
            const dataSeries = new XyDataSeries(this.baseChart.wasmContext, {
              isSorted: true,
              containsNaN: false,
              xValues,
              yValues,
            });
            const lineSeries = new FastLineRenderableSeries(
              this.baseChart.wasmContext,
              {
                strokeThickness: 2,
                stroke: this.getColorBroker(
                  item.brokerageActivity[0].id_brokerage
                ),
                dataSeries,
              }
            );
            this.baseChart.sciChartSurface.renderableSeries.add(lineSeries);
          }
        });
        maxRangeValue = maxRangeValue + maxRangeValue / 4;
        minRangeValue = -maxRangeValue;
      }

      this._candleXAxis = new DateTimeNumericAxis(this.baseChart.wasmContext, {
        labelProvider: this._buildTextLabelProvider(),
        labelStyle: { fontSize: 9 },
        ...NUMERIC_AXIS_PREFERENCES,
      });

      if (this.typeRanking === 'FLASH') {
        this._candleXAxis.labelProvider.formatCursorLabel = () => '';
        this._candleXAxis.labelProvider.formatLabel = (date) =>
          this._flashFormatLabel(date);
      }

      this._candleYAxis = new NumericAxis(this.baseChart.wasmContext, {
        ...NUMERIC_AXIS_PREFERENCES,
        axisAlignment: EAxisAlignment.Left,
        labelStyle: {
          fontSize: 9,
          alignment: ELabelAlignment.Right,
          padding: new Thickness(0, 15, 0, 0),
        },
        visibleRange: new NumberRange(minRangeValue, maxRangeValue),
        growBy: new NumberRange(0.15, 0.15),
      });

      const isPercentageTab = this.percentSymbol.includes(
        this.viewTypeSelected
      );
      this._candleYAxis.labelProvider.formatCursorLabel = (value) => {
        return isPercentageTab
          ? `${value.toFixed(2)}%`
          : getVolumeText(this.locale, value);
      };
      this._candleYAxis.labelProvider.formatLabel = (value) => {
        return isPercentageTab
          ? `${value.toFixed(2)}%`
          : getVolumeText(this.locale, value);
      };

      this.baseChart.sciChartSurface.xAxes.add(this._candleXAxis);
      this.baseChart.sciChartSurface.yAxes.add(this._candleYAxis);

      if (this.typeRanking === 'RANKING') {
        const brokers: IBrokerage[] = structuredClone(
          this.brokersSelected.values()
        );
        const xValues: number[] = [];
        const yValues = brokers.map((broker, index: number) => {
          xValues.push(index);
          return broker[this.viewTypeSelected] ?? 0;
        });
        const columnSeries = new FastColumnRenderableSeries(
          this.baseChart.wasmContext,
          {
            fill: CHART_COLORS.OPACITY_BACKGROUND,
            stroke: CHART_COLORS.BLACK,
            strokeThickness: 1,
            cornerRadius: 4,
            dataPointWidth: 0.7,
            paletteProvider: new BarsDynamicColors(brokers),
            dataSeries: new XyDataSeries(this.baseChart.wasmContext, {
              xValues,
              yValues,
              containsNaN: false,
              isSorted: true,
            }),
          }
        );
        this.baseChart.sciChartSurface.renderableSeries.add(columnSeries);
      }

      const tooltipPreferences = this._buildTooltipTemplate();
      this.baseChart.sciChartSurface.chartModifiers.add(
        new CursorModifier(tooltipPreferences)
      );

      if (this.typeRanking === 'FLASH') {
        this.baseChart.sciChartSurface.chartModifiers.add(
          new RolloverModifier({
            showTooltip: false,
            showRolloverLine: false,
          })
        );
      }
      this.stockChartService.removeChartLoading$.next({
        remove: true,
        ref: this.refChart,
      });
      this.loadingChart = false;
      this.cdr.detectChanges();
    });
  }

  private _flashFormatLabel(value: number): string {
    const date = new Date(value * 1000);
    const params: any = { hour: '2-digit', minute: '2-digit' };
    if (this.periodSelected === RANKING_BROKER_PERIODS.TODAY)
      return date.toLocaleTimeString('pt-BR', params);
    if (this.periodSelected === RANKING_BROKER_PERIODS.YESTERDAY)
      return date.toLocaleDateString('pt-BR', params).replace(',', '');
    return date.toLocaleDateString('pt-BR');
  }

  private _pointDate(date: number, hour: string): string {
    const dt = date.toString();
    if (
      this.periodSelected === RANKING_BROKER_PERIODS.YESTERDAY ||
      this.periodSelected === RANKING_BROKER_PERIODS.TODAY
    ) {
      return `
      ${dt.slice(0, 4)}-${dt.slice(4, 6)}-${dt.slice(6, 8)},
      ${hour.slice(0, 2)}:${hour.slice(2, 4)}
      `;
    }
    return `
    ${dt.slice(0, 4)}-${dt.slice(4, 6)}-${dt.slice(6, 8)},
    ${dt.slice(8, 10)}:${dt.slice(10, 12)}
    `;
  }

  private _buildTextLabelProvider(): TextLabelProvider | undefined {
    if (this.typeRanking !== 'RANKING') return undefined;
    const field = this.flashFilter[this.viewTypeSelected];
    const brokers = this.brokersSelected
      .values()
      .sort((a: any, b: any) => b[field] - a[field])
      .map((item) => item.nm_brokerage_valemobi);
    return new TextLabelProvider({
      labels: brokers,
      cursorLabelFormat: undefined,
    });
  }

  private _brokerRange(): { minRange: number; maxRange: number } {
    return this.brokersSelected.values().reduce(
      (acc: any, curr) => {
        const value = curr[this.viewTypeSelected];
        if (acc.minRange === undefined || (value && value < acc.minRange)) {
          acc.minRange = value;
        }
        if (acc.maxRange === undefined || (value && value > acc.maxRange)) {
          acc.maxRange = value;
        }
        return acc;
      },
      { minRange: undefined, maxRange: undefined }
    );
  }

  private _buildTooltipTemplate = (): ICursorModifierOptions | undefined => {
    const preferences = deepClone(SCICHART_TOOLTIP_PREFERENCES);
    if (this.typeRanking === 'RANKING') {
      preferences.tooltipSvgTemplate = (
        seriesInfos: SeriesInfo[],
        svgAnnotation: CursorTooltipSvgAnnotation
      ): string => {
        const seriesInfo = seriesInfos[0];
        if (!seriesInfo?.isWithinDataBounds) return '<svg></svg>';
        const volumeTooltips = getVolumeText(
          this.locale,
          seriesInfos[0].yValue
        );
        adjustTooltipPosition(100, 50, svgAnnotation);
        return `<svg width="100" height="30">
        <rect x="0" width="100" height="30" y="0" rx="4" fill="transparent" rx="16" />
          <svg width="100%">
            <foreignobject class="node" width="100%" height="100%">
              <div class="round-sm w-100 h-100 bg-neutral-strong text-light d-flex align-items-center justify-content-center">
                <span class="fs-5">${volumeTooltips}</span>
              </div>
            </foreignobject>
          </svg>
        </svg>`;
      };
      return preferences;
    }

    if (this.typeRanking === 'FLASH') {
      preferences.tooltipSvgTemplate = (
        seriesInfos: SeriesInfo[],
        svgAnnotation: CursorTooltipSvgAnnotation
      ) => {
        this.tooltipDateTime = '';
        this.dateTimeDefaultValue = null;
        const xValues: any = {};
        const brokers: IFlashTooltip[] = [];
        seriesInfos.forEach((si, index) => {
          const xySeriesInfo = si as XySeriesInfo;
          brokers.push({
            params: this._buildFlashTooltipParams(
              this.brokerActivity.brokerageActivityHead[index]
                .brokerageActivity[xySeriesInfo.dataSeriesIndex]
            ),
            color: xySeriesInfo.stroke,
            xValue: xySeriesInfo.xValue,
          });
          xValues[xySeriesInfo.xValue] =
            (xValues[xySeriesInfo.xValue] || 0) + 1;
        });

        if (Object.keys(xValues).length > 1) {
          const xValue = getKeyWithHighestValue(xValues);
          this.dateTimeDefaultValue = xValue;
          this.tooltipDateTime = this._flashTooltipDateByXValue(xValue);
        } else if (brokers.length) {
          const { date, time } = brokers[0].params;
          this.tooltipDateTime = this._flashTooltipDate(time, date);
        }

        if (!brokers?.length) {
          this.seriesToShow = [];
          this.cdr.detectChanges();
          return '<svg></svg>';
        }
        const mousePosition = svgAnnotation.cursorModifier.getMousePosition();
        if (mousePosition === 'SeriesArea')
          this.seriesToShow = brokers.sort(
            (a: any, b: any) =>
              b.params.valueSortTooltip - a.params.valueSortTooltip
          );
        else this.seriesToShow = [];
        this.cdr.detectChanges();
        return '<svg></svg>';
      };
      return preferences;
    }
    return undefined;
  };

  private getColorBroker(id_brokerage: number): string {
    return (
      this.rowData.filter(
        (broker: any) => broker.id_brokerage === id_brokerage
      )[0]?.color ?? CHART_COLORS.NEUTRAL_SMOOTHEST
    );
  }

  private _builderDonutChart() {
    this.loadingChart = true;
    this._chart = this._highchartsService.initGraph(this.refChart, () => null);
    this._chart.addSeries(
      {
        type: 'pie',
        innerSize: '10%',
        name: 'Top Compradores',
        thickness: 30,
        data: this._buildDonutData('percentage_buy'),
        center: ['25%', '50%'],
      },
      false
    );
    this._chart.addSeries(
      {
        type: 'pie',
        innerSize: '10%',
        name: 'Top Vendedores',
        thickness: 30,
        data: this._buildDonutData('percentage_sell'),
        center: ['75%', '50%'],
      },
      false
    );
    this._chart.redraw();
    this.loadingChart = false;
    this.cdr.detectChanges();
  }

  private _buildDonutData = (field: 'percentage_buy' | 'percentage_sell') => {
    const cdStock = this._stock?.cd_stock ?? 'sem_ativo';
    const dictInfos = this._rankingBrokerService.getDonutDataStock(
      cdStock,
      field
    );
    let data!: IBrokerage[];
    if (dictInfos) data = dictInfos;
    else {
      data = sort(deepClone(this.rowData), field);
      this._rankingBrokerService.setDonutDataStock(cdStock, field, data);
    }
    return data.map((broker: IBrokerage) => {
      return {
        name: broker.nm_brokerage_valemobi,
        z: broker[field],
        y: broker[field],
        color: broker.color,
        dataLabels: this.getDataLabelsConfig(),
        events: {
          click: () => this.explodeChartBroker(broker),
        },
      };
    });
  };

  private getDataLabelsConfig() {
    return {
      color: this.isDarkMode
        ? CHART_COLORS.NEUTRAL_SMOOTHEST
        : CHART_COLORS.NEUTRAL_STRONGEST,
      borderWidth: 0,
      style: {
        fontSize: '8px',
      },
    };
  }

  private explodeChartBroker(broker: any) {
    if (this.typeRanking === 'BROKER' && broker?.id_brokerage) {
      this.loading = true;
      this.tableFieldIndexer = 'id_stock';
      this.columnDefs = this.setHeaders(HEADERS_STOCKS, HEADERS_CONFIG_STOCKS);
      this.brokerActive = broker;
      this.cdr.detectChanges();
      this.explode.emit(true);
      this._getBrokeragesDetail(broker.id_brokerage)
        .pipe(
          map((res) => res.result),
          tap((result) => {
            this.loading = false;
            if (!result.length) {
              this.withoutContent = true;
              this.rowData = [];
              this.cdr.detectChanges();
              return;
            }
            this.rowData = result;
            this._chart.destroy();
            this.cdr.detectChanges();
            this.selectViewType(
              RANKING_BROKER_FILTERS.QTTY_SHARES_BUY,
              false,
              false
            );
          }),
          takeUntil(this.destroy$)
        )
        .subscribe((response) => this._builBrokerExplodeChart(response));
    }
  }

  private _builBrokerExplodeChart(result: IBrokerageDetail[]): void {
    this._chart = this._highchartsService.initGraph(this.refChart, () => null);
    const brokers = result.splice(0, 20);
    this._chart.addSeries(
      {
        type: 'pie',
        innerSize: '50%',
        name: 'Top Ativos (Compra)',
        thickness: 30,
        data: this._buildBrokerExplodeData(brokers, 'percentage_buy'),
        center: ['25%', '50%'],
      },
      false
    );
    this._chart.addSeries(
      {
        type: 'pie',
        innerSize: '20%',
        name: 'Top Ativos (Venda)',
        thickness: 30,
        data: this._buildBrokerExplodeData(brokers, 'percentage_sell'),
        center: ['75%', '50%'],
      },
      false
    );
    this._chart.redraw();
    this._resizeGridSubject.next();
    this.cdr.detectChanges();
  }

  private _buildBrokerExplodeData = (
    data: IBrokerageDetail[],
    field: 'percentage_buy' | 'percentage_sell'
  ) => {
    const dictInfos = this._rankingBrokerService.getExplodedBrokerData(
      this.brokerActive.nm_broker,
      field
    );
    let infos!: IBrokerageDetail[];
    if (dictInfos) infos = dictInfos;
    else infos = sort(deepClone(data), field);
    this._rankingBrokerService.setExplodedBrokerData(
      this.brokerActive.nm_broker,
      field,
      infos
    );
    return infos.map((item) => {
      return {
        name: item.cd_stock,
        z: item[field],
        y: item[field],
        dataLabels: this.getDataLabelsConfig(),
      };
    });
  };

  public goBackListBrokers(onlyReset: boolean = false): void {
    this.brokerActive = null;
    this.brokerExplode = null;
    this._chart.destroy();
    this.cdr.detectChanges();
    this.explode.emit(false);
    if (!onlyReset) this._setTabColumnsAndFilters(RANKING_TABS.BROKER, true);
  }

  private _buildFlashTooltipParams(params: any) {
    const numberPreferences =
      this.viewTypeSelected === RANKING_BROKER_FILTERS.VL_BUSINESS
        ? { minimumFractionDigits: 0, maximumFractionDigits: 2 }
        : undefined;
    const vlNetField = TOOLTIP_INFO_FIELD[this.viewTypeSelected] ?? '';
    const vlNetSumField =
      TOOLTIP_INFO_FIELD[`${this.viewTypeSelected}_sum`] ?? '';
    const infos = {
      volume_net: vlNetField
        ? getVolumeText(this.locale, params[vlNetField] ?? 0, numberPreferences)
        : '',
      volume_net_sum: vlNetSumField
        ? getVolumeText(
            this.locale,
            params[vlNetSumField] ?? 0,
            numberPreferences
          )
        : '',
      broker: params.nickname_brokerage,
      date: params.dt_trade,
      time: params.hm_trade,
      valueSortTooltip: params[vlNetSumField],
    };
    return infos;
  }

  private _flashTooltipDateByXValue(xValue: number): string {
    const isTime =
      this.periodSelected === RANKING_BROKER_PERIODS.TODAY ||
      this.periodSelected === RANKING_BROKER_PERIODS.YESTERDAY;
    const date = new Date(xValue * 1000);
    if (isTime)
      return `${this.periodLabel} ${date.toLocaleTimeString().slice(0, 5)}`;
    return date.toLocaleDateString();
  }

  private _flashTooltipDate(hour: string, date: number): string {
    const isTime =
      this.periodSelected === RANKING_BROKER_PERIODS.TODAY ||
      this.periodSelected === RANKING_BROKER_PERIODS.YESTERDAY;
    if (isTime)
      return `${this.periodLabel} ${hour.substring(0, 2)}:${hour.substring(2)}`;
    return formatDate(date.toString().substring(0, 8));
  }

  private _updateBrokerTooltipTheme(darkMode: boolean): void {
    this.isDarkMode = darkMode;
    this.cdr.detectChanges();
    if (this.typeRanking !== 'BROKER') {
      if (this.baseChart)
        this.baseChart.sciChartSurface.applyTheme(this.chartTheme);
      return;
    }
    const { bg, color } = this.highChartColors;
    this._chart.tooltip.update({
      backgroundColor: bg,
      style: { color: color },
    });
    if (!this.brokerActive) {
      this._builderDonutChart();
    } else {
      this._builBrokerExplodeChart(this.rowData);
    }
  }

  public moveDivider(target: any): void {
    const maxDivWidth = this._width - this._width * 0.3;
    let width = this.divWidth - target.distance.x;
    width = width < this.minDivWidth ? this.minDivWidth : width;
    width = width > maxDivWidth ? maxDivWidth : width;
    this.divWidth = width;
    this.transform = {
      x: 0,
      y: 0,
    };
    this.cdr.detectChanges();
  }

  protected updateSizeWidthTable(): void {
    const maxDivWidth = this._width - this._width * 0.3;
    if (maxDivWidth > this.divWidth) return;
    this.divWidth = maxDivWidth;
    this.cdr.detectChanges();
  }

  private toSort = (itemA: any, itemB: any): number => {
    if (itemA[this.viewTypeSelected] < itemB[this.viewTypeSelected]) {
      return 1;
    }
    if (itemA[this.viewTypeSelected] > itemB[this.viewTypeSelected]) {
      return -1;
    }
    return 0;
  };
}
