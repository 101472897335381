import { DOCUMENT } from '@angular/common';
import {
  Directive,
  HostListener,
  Inject,
  Input,
  Renderer2,
} from '@angular/core';
import { IWorkSpaceComponet, IWorkSpaceDocLayout } from 'src/app/core/interface';
import { WorkSpaceConfigs } from '../config/workspace.configs';

import { TPositions } from '../types/workspace.types';
export const MIN_WIDTH_DEFAULT: number = 300;
export const MIN_HEIGHT_DEFAULT: number = 300;
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[workspace-rize]',
})
export class WorkSpaceDirectiveDirective {
  @Input('workspace-rize') workspace!: IWorkSpaceComponet;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('workspace-position') position!: TPositions;

  private unlistenerMouseOver!: () => void;
  private unlistenerMouseUp!: () => void;

  private originalX!: number;
  private originalY!: number;
  private originalRight!: number;
  private originalBottom!: number;
  private originalMouseX!: number;
  private originalMouseY!: number;
  private originalWidth!: number;
  private originalHeight!: number;
  private minHeightSize!: number;
  private minWidthSize!: number;
  private readonly containerPadding: number = 8;
  private readonly differLayout: number = 79; // CALC HEIGHT OF HEADER PLUS PADDING MAIN 8PX;
  private workSpaceBase!: DOMRect;
  private workSpaceDoc!: HTMLElement;

  get layout() {
    return this.workspace.metadata.layout;
  }

  set layout(data: IWorkSpaceDocLayout) {
    this.workspace.metadata.layout = data
  }

  constructor(
    private configs: WorkSpaceConfigs,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  @HostListener('mousedown', ['$event'])
  init(event: any) {
    event.preventDefault();
    event.stopPropagation();

    let layout = this.layout

    this.minWidthSize = layout.minWidth
      ? parseInt(layout.minWidth)
      : MIN_WIDTH_DEFAULT;

    this.minHeightSize = layout.minHeight
      ? parseInt(layout.minHeight)
      : MIN_HEIGHT_DEFAULT;

    this.workSpaceDoc = event.target.offsetParent as HTMLElement;
    this.workSpaceBase =
      this.workSpaceDoc.offsetParent!.getBoundingClientRect();
    this.originalWidth = parseInt(
      this.doc.defaultView!.getComputedStyle(this.workSpaceDoc).width
    );
    this.originalHeight = parseInt(
      this.doc.defaultView!.getComputedStyle(this.workSpaceDoc).height
    );
    this.originalX = this.workSpaceDoc.getBoundingClientRect().left;
    this.originalRight = this.workSpaceDoc.getBoundingClientRect().right;
    this.originalBottom = this.workSpaceDoc.getBoundingClientRect().bottom;
    this.originalY = this.workSpaceDoc.getBoundingClientRect().top;
    this.originalMouseX = event.pageX;
    this.originalMouseY = event.pageY;

    this.unlistenerMouseOver = this.renderer2.listen(
      this.doc,
      'mousemove',
      (e) => {
        let width;
        let height;
        let updated: boolean = false;

        //SET DEFAULT VALUES BEFORE INIT
        if (!this.position.includes('-')) {
          layout = this.initValues('XY');
        }

        layout.height =
          this.workSpaceDoc.getBoundingClientRect().height + 'px';
        layout.width =
          this.workSpaceDoc.getBoundingClientRect().width + 'px';
        layout.transform = {
          x: 0,
          y: 0,
        };
        const header = document.getElementById('navbar-header');
        switch (this.position) {
          case 'bottom-right':
            width = this.originalWidth + (e.pageX - this.originalMouseX);
            height = this.originalHeight + (e.pageY - this.originalMouseY);
            if (
              width > this.minWidthSize &&
              e.clientX < this.workSpaceBase.width
            ) {
              updated = true;
              layout = this.initValues('X');
              layout.width = width + 'px';
            }
            if (
              height > this.minHeightSize &&
              e.clientY < this.workSpaceBase.bottom
            ) {
              updated = true;
              layout = this.initValues('Y');
              layout.height = height + 'px';
            }
            if (!header) {
              return;
            }
            if (
              layout.top &&
              parseInt(layout.top) < header.getBoundingClientRect().y
            ) {
              layout.top = '0px';
            }
            break;

          case 'bottom-left':
            height = this.originalHeight + (e.pageY - this.originalMouseY);
            width = this.originalWidth - (e.pageX - this.originalMouseX);
            if (
              height > this.minHeightSize &&
              e.clientY < this.workSpaceBase.bottom
            ) {
              updated = true;
              layout = this.initValues('Y');
              layout.height = height + 'px';
            }
            if (width > this.minWidthSize && e.clientX > this.workSpaceBase.x) {
              updated = true;
              layout = this.initValues('X');
              layout.width = width + 'px';
              layout.left =
                this.originalX -
                this.containerPadding +
                (e.pageX - this.originalMouseX) +
                'px';
            }
            if (!header) {
              return;
            }
            if (
              layout.top &&
              parseInt(layout.top) < header.getBoundingClientRect().y
            ) {
              layout.top = '0px';
            }
            break;
          case 'top-right':
            width = this.originalWidth + (e.pageX - this.originalMouseX);
            height = this.originalHeight - (e.pageY - this.originalMouseY);
            if (
              width > this.minWidthSize &&
              e.clientX < this.workSpaceBase.width
            ) {
              updated = true;
              layout = this.initValues('X');
              layout.width = width + 'px';
            }
            if (
              height > this.minHeightSize &&
              e.clientY > this.workSpaceBase.top
            ) {
              updated = true;
              layout = this.initValues('Y');
              layout.height = height + 'px';
              layout.top =
                this.originalY -
                this.differLayout +
                (e.pageY - this.originalMouseY) +
                'px';
            }

            break;
          case 'top-left':
            width = this.originalWidth - (e.pageX - this.originalMouseX);
            height = this.originalHeight - (e.pageY - this.originalMouseY);
            if (width > this.minWidthSize && e.clientX > this.workSpaceBase.x) {
              updated = true;
              layout = this.initValues('X');
              layout.width = width + 'px';
              layout.left =
                this.originalX -
                this.containerPadding +
                (e.pageX - this.originalMouseX) +
                'px';
            }
            if (
              height > this.minHeightSize &&
              e.clientY > this.workSpaceBase.y
            ) {
              updated = true;
              layout = this.initValues('Y');
              layout.height = height + 'px';
              layout.top =
                this.originalY -
                this.differLayout +
                (e.pageY - this.originalMouseY) +
                'px';
            }

            break;
          case 'bottom':
            height = this.originalHeight + (e.pageY - this.originalMouseY);
            if (
              height > this.minHeightSize &&
              e.clientY < this.workSpaceBase.bottom
            ) {
              updated = true;
              layout.height = height + 'px';
            }
            if (!header) {
              return;
            }
            if (
              layout.top &&
              parseInt(layout.top) < header.getBoundingClientRect().y
            ) {
              layout.top = '0px';
            }
            break;
          case 'top':
            height = this.originalHeight - (e.pageY - this.originalMouseY);
            if (
              height > this.minHeightSize &&
              e.clientY > this.workSpaceBase.y
            ) {
              updated = true;
              layout.top =
                this.originalY -
                this.differLayout +
                (e.pageY - this.originalMouseY) +
                'px';
              layout.height = height + 'px';
            }
            break;
          case 'right':
            width = this.originalWidth + (e.pageX - this.originalMouseX);
            if (
              width >= this.minWidthSize &&
              e.clientX < this.workSpaceBase.width
            ) {
              updated = true;
              layout.width = width + 'px';
            }
            break;
          case 'left':
            width = this.originalWidth - (e.pageX - this.originalMouseX);
            if (width > this.minWidthSize && e.clientX > this.workSpaceBase.x) {
              updated = true;
              layout.left =
                this.originalX -
                this.containerPadding +
                (e.pageX - this.originalMouseX) +
                'px';
              layout.width = width + 'px';
            }
            break;
        }
        if (updated) {
          this.layout = layout
          this.configs.updateSizes([this.workspace], false)
        }
      }
    );

    this.unlistenerMouseUp = this.renderer2.listen(
      this.doc,
      'mouseup',
      () => {
        this.unlistenerMouseOver();
        this.unlistenerMouseUp();
      }
    );

  }

  private initValues(axis: 'X' | 'Y' | 'XY') {
    const layout = this.layout
    if (axis === 'X' || axis === 'XY') {
      layout.left = this.originalX - this.containerPadding + 'px';
      layout.right = this.originalRight - this.containerPadding + 'px';
    }
    if (axis === 'Y' || axis === 'XY') {
      layout.top = this.originalY - this.differLayout + 'px';
      layout.bottom = this.originalBottom - this.differLayout + 'px';
    }

    return layout
  }
}
