import { NgModule } from '@angular/core';

import { TimesTradeRoutes } from '../../../shared/components/times-trades/times-trade.routing';
import { TimesTradesModule } from '../../../shared/components/times-trades/times-trades.module';

@NgModule({
  declarations: [],
  imports: [TimesTradeRoutes, TimesTradesModule],
})
export class TimesTradesRouteModule {}
