import { Injectable } from '@angular/core';
import { THEMES } from '@shared/services/core/custom-preferences/theme/theme-preferences.interface';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import * as Highcharts from 'highcharts';

@Injectable({
  providedIn: 'root',
})
export class HighchartsService {
  private isDarkTheme: boolean = true;
  constructor(private _themeService: ThemePreferencesService) {
    this.isDarkTheme = this._themeService.isDarkTheme();
    this._themeService.themeActiveObservable().subscribe((theme) => {
      this.isDarkTheme = theme === THEMES.dark;
    });
  }

  initGraph(refComponent: string, callback?: Highcharts.ChartCallbackFunction) {
    const graph = Highcharts.chart(
      refComponent,
      {
        loading: {
          labelStyle: {
            opacity: 1,
          },
          style: {
            backgroundColor: '#353546',
          },
        },
        credits: {
          enabled: false,
          style: {
            display: 'none',
            margin: 0,
            padding: '0',
          },
        },
        tooltip: {
          headerFormat: '',
          backgroundColor: this.isDarkTheme
            ? CHART_COLORS.NEUTRAL_STRONG
            : CHART_COLORS.NEUTRAL_SMOOTHER,
          borderColor: 'transparent',
          borderRadius: 8,
          borderWidth: 0,
          style: {
            color: this.isDarkTheme
              ? CHART_COLORS.NEUTRAL_SMOOTHEST
              : CHART_COLORS.NEUTRAL_STRONG,
          },
          formatter: function () {
            return `
            <span style="color:${this.point.color}">\u25CF</span> 
            ${this.key}: 
            <b class="text-light">${this.y?.toFixed(2).replace('.', ',')}%</b>`;
          },
        },
        series: [],
        chart: {
          type: 'variablepie',
          backgroundColor: '#FFFFFF00',
          height: '23%',
          marginTop: 0,
        },
        title: {
          text: '',
        },
      },
      callback
    );
    return graph;
  }
}
