<rocket-modal>
  <rocket-modal-header>
    <h3 class="modal-title">{{ title }}</h3>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <div [innerHTML]="description"></div>

    <form class="vstack gap-3" [formGroup]="form" (keydown.enter)="submit()">
      <ng-container [ngSwitch]="currentStep">
        <ng-container *ngSwitchCase="switchOptions.DATA">
          <div>
            <label for="currentPhone" class="form-label">
              {{ fieldLabel }} atual
            </label>
            <input
              disabled
              readonly
              required
              type="text"
              class="form-control"
              id="currentPhone"
              mask="(00) 00000-0000"
              [value]="currentData"
            />
          </div>

          <ng-container *ngIf="isMobile; else email">
            <app-phone-input
              formControlName="value"
              label="Novo Telefone"
              [requiredText]="
                value.value ? 'Telefone inválido' : 'Esse campo é obrigatório!'
              "
              hasError="invalid"
              css="w-100"
              [isRequired]="true"
              [autoComplete]="false"
              [initialCountry]="phoneCountryCode.value.country"
              (countryHasChanged)="onUpdateCountryCode($event)"
            ></app-phone-input>
          </ng-container>

          <ng-template #email>
            <fla-input
              fla-focus
              formControlName="value"
              label="Novo E-mail"
              [requiredText]="
                value.value ? 'E-mail inválido' : 'Esse campo é obrigatório!'
              "
              hasError="invalid"
              type="text"
              placeholder="Insira o seu E-mail"
              css="w-100"
              margin="m-0"
              [isRequired]="true"
            >
            </fla-input>
          </ng-template>

          <fla-input
            formControlName="password"
            label="Senha"
            requiredText="Esse campo é obrigatório!"
            hasError="invalid"
            placeholder="Informe a sua senha"
            css="w-100"
            margin="m-0"
            decidePosition="suffix"
            [type]="viewPassword ? 'text' : 'password'"
            [isRequired]="true"
            [maxLength]="120"
          >
            <div class="suffix">
              <button
                class="btn btn-icon text-muted ml-3"
                type="button"
                id="btnPass"
                (click)="viewPassword = !viewPassword"
              >
                <i class="icons pe-none">
                  {{ viewPassword ? 'visibility' : 'visibility_off' }}
                </i>
              </button>
            </div>
          </fla-input>
        </ng-container>

        <ng-container *ngSwitchCase="switchOptions.PINCODE">
          <div class="w-100 vstack gap-4">
            <fla-input
              fla-focus
              formControlName="pincode"
              label="Código de verificação"
              requiredText="Esse campo é obrigatório!"
              hasError="invalid"
              [placeholder]="'Informe o código recebido via ' + fieldLabel"
              css="w-100"
              margin="m-0"
              decidePosition="suffix"
              [type]="viewPincode ? 'text' : 'password'"
              [isRequired]="true"
              [maxLength]="10"
            >
              <div class="suffix">
                <button
                  class="btn btn-icon text-muted ml-3"
                  type="button"
                  id="btnPass"
                  (click)="viewPincode = !viewPincode"
                >
                  <i class="icons pe-none">
                    {{ viewPincode ? 'visibility' : 'visibility_off' }}
                  </i>
                </button>
              </div>
            </fla-input>
            <button
              type="button"
              class="bg-transparent btn btn-fill-primary p-0"
              [ngClass]="{ 'pe-none opacity-md-medium': disableResendPincode }"
              (click)="resendPincode()"
              style="max-width: fit-content"
            >
              Reenviar código
              <ng-container *ngIf="disableResendPincode">
                <span> ({{ timeToResend }}s) </span>
              </ng-container>
            </button>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="switchOptions.AUTHENTICATION">
          <div class="hstack gap-3 align-items-center">
            <fla-input
              fla-focus
              formControlName="token"
              label="Token segundo fator de autenticação"
              requiredText="Esse campo é obrigatório!"
              hasError="invalid"
              placeholder="Informe aqui seu token"
              css="w-100"
              margin="m-0"
              decidePosition="suffix"
              [type]="viewToken ? 'text' : 'password'"
              [isRequired]="true"
              [maxLength]="10"
            >
              <div class="suffix">
                <button
                  class="btn btn-icon text-muted ml-3"
                  type="button"
                  id="btnPass"
                  (click)="viewToken = !viewToken"
                >
                  <i class="icons pe-none">
                    {{ viewToken ? 'visibility' : 'visibility_off' }}
                  </i>
                </button>
              </div>
            </fla-input>
            <div [tooltip]="tokenTooltip" [tooltipIsHtml]="true" class="mt-5">
              <i
                class="icons pe-none text-brand-primary icons-outlined icon-size-sm"
              >
                info
              </i>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="error">
        <span class="d-block py-2 text-feedback-error fs-5">
          {{ errorMessage }}
        </span>
      </ng-container>
    </form>
  </app-rocket-modal-body>
  <rocket-modal-footer>
    <rocket-button
      label="Anterior"
      type="fill"
      css="mr-2"
      (rocketClick)="goBack()"
    ></rocket-button>
    <rocket-button
      label="Continuar"
      (rocketClick)="submit()"
      [isDisabled]="disableSubmitBtn || isLoading"
      [loading]="isLoading"
    ></rocket-button>
  </rocket-modal-footer>
</rocket-modal>
