import { Injectable } from '@angular/core';
import { ToastService } from '@shared/services';
import { TokenService } from '@shared/services/api/trademap/v1/token.service';
import { TTokenAuthenticator } from './types';
import { Subject, map } from 'rxjs';
import { OtpService } from '@shared/services/api/authentication/v3/otp.service';

@Injectable({
  providedIn: 'root',
})
export class ModalTokenService {
  onReloadConfig$: Subject<string | undefined> = new Subject();
  onCloseModal$: Subject<void> = new Subject();

  constructor(
    private _tokenService: TokenService,
    private _toastService: ToastService,
    private _otpService: OtpService
  ) {}

  saveAuthenticator = (
    authenticator: TTokenAuthenticator,
    tokenCode?: string
  ) => {
    if (!authenticator) return;
    this._tokenService.saveAuthenticatorPreference(authenticator.id).subscribe({
      next: () => this._verifyActivatedToken(authenticator, tokenCode),
      error: (error) => {
        const msg = error.message
          ? error.message
          : 'Falha ao tentar conectar. Por favor, tentar novamente';
        this._toastService.showToast('error', msg);
      },
    });
  };

  private _verifyActivatedToken(
    lastTokenActivated: TTokenAuthenticator,
    tokenCode?: string
  ) {
    this._otpService
      .userTokenPreferences()
      .pipe(
        map((token) => {
          if (lastTokenActivated.id === token) return true;
          throw new Error('');
        })
      )
      .subscribe({
        next: () => {
          this.onReloadConfig$.next(tokenCode);
          this._toastService.showToast(
            'success',
            `Sua conexão com ${lastTokenActivated.name} foi feita com sucesso`
          );
        },
        error: () => {
          this.onReloadConfig$.next('');
          this._toastService.showToast(
            'error',
            `Ocorreu um erro ao ativa a conexão com ${lastTokenActivated.name}. Por favor, tente novamente.`
          );
        },
      });
  }
}
