<div>
  <div #showcase class="showcase mr-2">
    <div class="rt-products-carousel">
      <div class="d-flex w-100 flex-column align-items-center">
        <ul #itemList class="rt-carousel-container" *ngIf="stocks.length">
          <div
            #carouselItem
            class="rt-carousel-item organism-products-carousel-element"
            *ngFor="let stock of stocksDict.values(); let index = index"
            [style.order]="index"
          >
            <div class="mt-2 ml-2">
              <app-stock-table-view-card-and-chart
                [tableView]="cardView"
                [name]="stock.cd_stock"
                [companyName]="stock.cd_stock"
                [vlCloseHistory]="stock.vl_close_history!"
                [lastPrice]="stock.preco_ultimo"
                [variationBgColor]="stock.arrow_hex"
                [variationTextColor]="stock.arrow_font_hex"
                [tickSize]="stock.tick_size_denominator"
                [variation]="stock.day_variation || '0,00'"
                (stockSelected)="updateGlobalStock(stock)"
              >
              </app-stock-table-view-card-and-chart>
            </div>
          </div>
        </ul>
        <ng-container *ngIf="!hideButtons">
          <button class="rt-btn rt-prev" (click)="handleNavigation(false)">
            <fla-icon
              name="arrow_back_ios"
              size="icon-size-sm"
              css="text-brand-primary cursor-pointer"
            ></fla-icon>
          </button>
          <button class="rt-btn rt-next" (click)="handleNavigation(true)">
            <fla-icon
              name="arrow_forward_ios"
              size="icon-size-sm"
              css="text-brand-primary cursor-pointer"
            ></fla-icon>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
