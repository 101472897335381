import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  computed,
  signal,
} from '@angular/core';
import {
  HEADER_OPTIONS_OPERATIONS,
  OPTIONS_TYPES,
  TOptionsOperation,
  TOptionsOperationLeg,
} from '../../types';
import { ISearchStock } from '@core/interface';
import { OptionsService } from '@shared/services/api/trademap/v1/options.service';
import { Subject, takeUntil } from 'rxjs';
import { OptionsChannel } from '@shared/channel/options.channel';
import { ToastService } from '@shared/services';
import {
  VMDateToDate,
  bigValueFormatter,
  formatDate,
  formatterNumber,
} from 'src/app/utils/utils.functions';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { RocketStreamRead } from '@shared/channel/rx-event';

@Component({
  selector: 'app-options-operations',
  templateUrl: './options-operations.component.html',
  styleUrls: ['./options-operations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsOperationsComponent
  extends ReadStreamBase
  implements OnInit, OnChanges, OnDestroy
{
  @Output() enableChangeStock = new EventEmitter<void>();
  @Input() stock!: ISearchStock | undefined;
  @Input() refId!: string;
  @Input() set height(height: string) {
    if (height) {
      this._height.set(parseInt(height));
    }
  }
  private _height = signal(0);

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private _cheetahParams: any;
  private _optionsChannel$!: RocketStreamRead | undefined;

  typeNames = OPTIONS_TYPES;
  operations: TOptionsOperation[] = [];
  loading = true;
  colorMapping: any = {};
  headers = HEADER_OPTIONS_OPERATIONS;
  gridHeight = computed(() => {
    return this._height() - 76;
  });

  constructor(
    private _optionsService: OptionsService,
    private _optionsChannel: OptionsChannel,
    private _toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this._loadOperations();
    this._optionsChannel.onEvents().then((data) => {
      this._optionsChannel$ = data;
      this.readStream(data.stream, this._channelHandler);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { stock } = changes;
    stock && stock.currentValue && this._loadOperations();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this._unsubscribe();
    this._optionsChannel$ && this._optionsChannel$.close();
  }

  formatDate(value: number) {
    return formatDate(`${value}`);
  }

  deleteOperation(operation: TOptionsOperation) {
    this.loading = true;
    this._optionsService
      .deleteOperation(operation.id_operation)
      .subscribe((data: any) => {
        if (data) {
          this._toastService.showToast('success', 'Operação cancelada!');
          this._loadOperations();
        } else {
          this._toastService.showToast(
            'warning',
            'Não foi posivel cancelar a operação!'
          );
          this.loading = false;
          this.cdr.detectChanges();
        }
      });
  }

  private _loadOperations() {
    if (!this.stock) return;
    const { cd_stock, id_exchange } = this.stock;
    this._optionsService
      .getOperations(cd_stock)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: TOptionsOperation[]) => {
        this.operations = [];
        if (response) {
          const cheetahItems: string[] = [];
          const date = new Date();
          const time = date.getTime() - 1000 * 60 * 60 * 24;

          const oldOnes: TOptionsOperation[] = [];
          const newOnes: TOptionsOperation[] = [];

          response.map((row: TOptionsOperation) => {
            row.legsMap = {};
            row.legs = row.legs.map(
              (leg: TOptionsOperationLeg, idx: number) => {
                cheetahItems.push(`${leg.cd_stock}:${id_exchange}`);
                //leg.cd_stock = leg.cd_stock;
                leg.side = leg.side == 'B' ? 'C' : 'V';
                leg.price = formatterNumber(+leg.price);
                leg.qtty_lot = formatterNumber(+leg.qtty_lot, {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                });
                leg.qt_share_traded = bigValueFormatter(leg.qt_share_traded);
                leg.strike_price = formatterNumber(+leg.strike_price);
                leg.vl_delta = formatterNumber(+leg.vl_delta);
                leg.vl_gamma = formatterNumber(+leg.vl_gamma, {
                  maximumFractionDigits: 4,
                  minimumFractionDigits: 4,
                });
                leg.vl_rho = formatterNumber(+leg.vl_rho);
                leg.vl_theta = formatterNumber(+leg.vl_theta, {
                  maximumFractionDigits: 4,
                  minimumFractionDigits: 4,
                });
                leg.vl_vega = formatterNumber(+leg.vl_vega);
                leg.vl_vol_implicitly = formatterNumber(+leg.vl_vol_implicitly);
                leg.volume = bigValueFormatter(leg.volume);
                leg.dt_expiration_option = formatDate(
                  leg.dt_expiration_option,
                  true
                );

                this._setColorMapping(leg);
                row.legsMap[leg.cd_stock] = { ...leg, indexArray: idx };
                return leg;
              }
            );
            const operationDate = VMDateToDate(row.dt_operation);
            if (operationDate.getTime() < time) {
              oldOnes.push(row);
              return;
            }
            newOnes.push(row);
          });

          this.operations = [
            ...newOnes.sort(this._sortFunction),
            ...oldOnes.sort(this._sortFunction),
          ];

          this._subscribeOptionsChannel(cheetahItems);
        }
        this.loading = false;
        this.enableChangeStock.emit();
        this.cdr.detectChanges();
      });
  }

  private _sortFunction(a: TOptionsOperation, b: TOptionsOperation) {
    if (a.dt_operation == b.dt_operation) return 0;
    return a.dt_operation > b.dt_operation ? 1 : -1;
  }

  private _subscribeOptionsChannel(cheetahItems: string[]) {
    this._unsubscribe();
    if (!this.stock) return;
    this._cheetahParams = {
      itemsArray: cheetahItems,
    };
    this._optionsChannel.subscribe(this._cheetahParams);
    this._optionsChannel$?.snapshot(this._cheetahParams.itemsArray);
  }

  private _unsubscribe() {
    if (!this._cheetahParams) return;

    this._optionsChannel.unsubscribe(this._cheetahParams);
    this._cheetahParams = null;
  }

  private _channelHandler = (payload: Map<string, any>): void => {
    payload.forEach((data) => {
      if (!data) return;
      const item = data.item.split(':')[0];
      const cdStock = item.slice(0, 4);
      const cdStockOption = item.slice(4);
      this.operations = this.operations.map((row: TOptionsOperation) => {
        if (row.cd_stock_option.slice(0, 4) == cdStock) {
          const leg = row.legsMap[cdStockOption];
          if (leg) {
            this._setColorMapping({
              ...leg,
              option_days_to_expiration: +data.days_to_expiration,
              vl_delta: +data.delta,
              vl_gamma: +data.gamma,
              vl_rho: +data.rho,
              vl_theta: +data.theta,
              vl_vega: +data.vega,
              strike_price: data.strike_price
                ? +data.strike_price
                : leg.strike_price,
              price: data.vl_close ? +data.vl_close : leg.price,
              vl_vol_implicitly: data.vol_implicitly
                ? +data.vol_implicitly
                : leg.vl_vol_implicitly,
              volume: data.volume ? +data.volume : leg.volume,
            });
            data.days_to_expiration &&
              (leg.option_days_to_expiration = +data.days_to_expiration);
            data.delta && (leg.vl_delta = formatterNumber(+data.delta));
            data.gamma &&
              (leg.vl_gamma = formatterNumber(+data.gamma, {
                maximumFractionDigits: 4,
                minimumFractionDigits: 4,
              }));
            data.rho && (leg.vl_rho = formatterNumber(+data.rho));
            data.theta &&
              (leg.vl_theta = formatterNumber(+data.theta, {
                maximumFractionDigits: 4,
                minimumFractionDigits: 4,
              }));
            data.vega && (leg.vl_vega = formatterNumber(+data.vega));
            data.strike_price &&
              (leg.strike_price = formatterNumber(+data.strike_price));
            data.vl_close && (leg.price = formatterNumber(+data.vl_close));
            data.vol_implicitly &&
              (leg.vl_vol_implicitly = formatterNumber(+data.vol_implicitly));
            if (data.volume) {
              leg.volume = bigValueFormatter(+data.volume);
            }
            if (data.qtde_negocios) {
              leg.qt_share_traded = bigValueFormatter(+data.qtde_negocios);
            }
            row.legs[leg.indexArray] = leg;
            row.legsMap[cdStockOption] = leg;
            this.cdr.detectChanges();
          }
        }
        return row;
      });
    });
  };

  private _setColorMapping(leg: TOptionsOperationLeg) {
    this.colorMapping[leg.cd_stock] = {
      vl_delta:
        +leg.vl_delta >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
      vl_gamma:
        +leg.vl_gamma >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
      vl_rho:
        +leg.vl_rho >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
      vl_theta:
        +leg.vl_theta >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
      vl_vega:
        +leg.vl_vega >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
      vl_vol_implicitly:
        +leg.vl_vol_implicitly >= 0
          ? 'text-feedback-success'
          : 'text-feedback-negative',
    };
  }
}
