export const triangularMovingAverageTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  A Média Móvel Triangular (TRIMA) é uma média móvel duplamente suavizada. É
  calculada aplicando uma média móvel simples a uma média móvel simples.
  Primeiro, calcula-se a média móvel simples de um conjunto de preços. Em
  seguida, essa média móvel é suavizada novamente. O resultado é uma média móvel
  que tem menos "ruído" do que a média móvel simples original. A TRIMA é
  geralmente usada para identificar tendências de longo prazo.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">
  Para calcular a média móvel triangular, é necessário seguir os seguintes
  passos:
</div>
<div class="mb-3">
  1. Calcule a média móvel simples (SMA) de um conjunto de preços, utilizando um
  determinado período.
</div>
<div class="mb-3">
  2. Calcule a média móvel simples (SMA) novamente, utilizando o mesmo período
  utilizado no passo anterior.
</div>
<div class="mb-3">
  3. Calcule a média móvel simples (SMA) mais uma vez, utilizando o mesmo
  período utilizado nos passos anteriores.
</div>
<div class="mb-3">
  4. A média móvel triangular (TRIMA) é calculada pela média ponderada das
  médias móveis simples (SMA) calculadas nos passos anteriores, utilizando os
  pesos 1, 2 e 3.
</div>
<div class="mb-3">A fórmula para o cálculo da média móvel triangular é:</div>
<div class="mb-3">TRIMA = (SMA1 + 2 * SMA2 + 3 * SMA3) / 6</div>
<div class="mb-3">Onde:<br /></div>
<div class="mb-3">- TRIMA: Média Móvel Triangular</div>
<div class="mb-3">- SMA1: Média Móvel Simples calculada no primeiro passo</div>
<div class="mb-3">- SMA2: Média Móvel Simples calculada no segundo passo</div>
<div class="mb-3">- SMA3: Média Móvel Simples calculada no terceiro passo</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  A Média Móvel Triangular (TRIMA) é uma ferramenta útil para identificar
  tendências de longo prazo em um conjunto de preços. Ela pode ser usada para
  ajudar a determinar quando comprar ou vender um ativo.
</div>
<div class="mb-3">
  Quando o preço de um ativo está acima da TRIMA, isso pode indicar uma
  tendência de alta. Quando o preço está abaixo da TRIMA, isso pode indicar uma
  tendência de baixa. No entanto, é importante lembrar que a TRIMA é uma
  ferramenta de análise técnica e não leva em consideração fatores fundamentais
  que podem afetar o preço de um ativo.
</div>
<div class="mb-3">
  Para usar a TRIMA, é importante primeiro determinar o período que deseja usar
  para calcular a média móvel simples. Em geral, períodos mais longos são usados
  para identificar tendências de longo prazo, enquanto períodos mais curtos são
  usados para identificar tendências de curto prazo.
</div>
<div class="mb-3">
  Uma vez determinado o período, você pode calcular a TRIMA usando a fórmula
  fornecida acima. Em seguida, você pode plotar a TRIMA em um gráfico de preços
  e observar como o preço do ativo se comporta em relação a ela.
</div>
<div class="mb-3">
  Lembre-se de que a TRIMA é apenas uma ferramenta e não deve ser usada como a
  única base para tomar decisões de investimento. É importante considerar outros
  fatores, como fatores fundamentais e análises de mercado, ao tomar decisões de
  investimento.
</div>
<div class="mb-3">
  <img class="w-75" src="/assets/indicators/trima-como-funciona.png" />
</div>
<div class="fw-bolder">Como configurar</div>
<div class="fw-bolder">Valores</div>
<div class="text-decoration-underline">Período</div>
<div class="mb-3">
  O período a ser usado no cálculo da média móvel. 10 dias é o padrão.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/trima-entrada.png"
  />
</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">TRIMA</div>
<div class="mb-3">
  Pode alternar a visibilidade da TRIMA. Também pode selecionar a cor, a
  espessura e o estilo da linha do TRIMA.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/trima-estilo.png"
  />
</div>
`;
