import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';
import { OptionsComponents } from '../component-list';

export const RENT_BTC_COMPONENT: any = (options?: OptionsComponents) => ({
  name: 'Aluguel (BTC)',
  id: COMPONENTS_NAMES_ENUM.RENT_BTC,
  icon: 'table_chart',
  cod: 'RENT_BTC',
  data: null,
  close: true,
  fullscreen: true,
  link: false,
  widthDesk: 780,
  heightDesk: 530,
  unitOfMeasurement: options?.unit ?? 'px',
  height: options?.height ?? 530,
  width: options?.width ?? 625,
  minWidth: '625px',
  minHeight: '200px',
  left: options?.left ?? 'calc(15% + 5px)',
  top: options?.top ?? '0px',
  label: 'Aluguel (BTC)',
  borderClass: '',
  noPublished: false,
  hasBorder: true,
  divider: true,
  iconSvg: 'EDIT_NOTE',
});
