import { Subject } from 'rxjs';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import {
  TIGER_INDICATORS_ENUM,
  TIGER_INDICATOR_PARAMETER_TYPE,
  TIGER_INDICATOR_SOURCES,
} from '../enum/tiger-chart.enum';
import { TalibService } from '../services/talib.service';
import {
  TData,
  TTooltipIndicatorValue,
  TUpdateData,
} from '../types/tiger-chart.types';
import { IPointMarker } from 'scichart';

export interface BaseIndicator {
  type: TIGER_INDICATORS_ENUM;
  yAxisId: string;
  settings: TTigerChartIndicatorParameter[];
  styles: TTigerChartIndicatorParameter[];
  renderSeriesConfig: TTigerChartIndicatorRenderSeriesConfig[];
  onChange: Subject<null>;
  service: TalibService;
  lineNumber: string;
  get propertiesText(): string;
  get isVisible(): boolean;
  set isVisible(visible: boolean);
  mainLineId: string;
  isNewOnChart: boolean;
  create(
    options: TTigerChartIndicatorCreateOptions
  ): BaseRenderableSeries[] | void;
  updatePoints(): void;
  updateSettings(): void;
  updateStyles(
    baseChart: TWebAssemblyChart,
    config: TTigerChartIndicatorRenderSeriesConfig
  ): void;
  delete(): void;
  append(xValue: number, data: TUpdateData, fullData: TData): void;
  insertRange(xValues: number[], fullData: TData): void;
  changeVisibility(): void;
  update(index: number, data: TUpdateData, fullData: TData): void;
  setValue(property: keyof this, value: any): void;
  getValuesByIndex(index?: number): TTooltipIndicatorValue[];
  resetConfiguration(): void;
}
export interface TTigerChartIndicatorDefaultValues {
  timePeriod: number;
  offset: number;
  precision: number;
  source: TIGER_INDICATOR_SOURCES;
  lineColor: string;
  lineThickness: number;
  acceleration: number;
  maximum: number;
  fastTimePeriod: number;
  slowTimePeriod: number;
  signalTimePeriod: number;
  fastK_Period: number;
  slowK_Period: number;
  slowD_Period: number;
  upperHistogramColor: string;
  bottomHistogramColor: string;
  macdColor: string;
  signalColor: string;
  macdThickness: number;
  signalThickness: number;
  upperLimit: number;
  bottomLimit: number;
  upperLimitColor: string;
  bottomLimitColor: string;
  backgroundColor: string;
  upperLimitLineType: number;
  bottomLimitLineType: number;
  upperColor: string;
  baseColor: string;
  bottomColor: string;
  standardDeviation: number;
  upperThickness: number;
  baseThickness: number;
  bottomThickness: number;
  fastPeriod: number;
  slowPeriod: number;
  smoothing: number;
  volumeColorUp: string;
  volumeColorDown: string;
  priceSource: string;
  textColor: string;
  positiveBarColor: string;
  negativeBarColor: string;
  highlightColor: string;
  highlightOpacity: number;
  lineColor2: string;
  lineThickness2: number;
  timePeriod2: number;
  offset2: number;
  source2: TIGER_INDICATOR_SOURCES;
  upperVisible: boolean;
  baseVisible: boolean;
  bottomVisible: boolean;
  backgroundVisible: boolean;
  lineVisibleK: boolean;
  lineVisibleD: boolean;
}

export interface TTigerChartIndicatorCreateOptions {
  base: TWebAssemblyChart;
  yAxisId: string;
  xAxisId: string;
  tickSize: number;
}

export interface TTigerChartIndicatorRenderSeriesConfig {
  title?: string;
  label: string;
  id: string;
  color: string;
  thickness: number;
  active: boolean;
  property?: string;
  propertyColor?: string;
  propertyThickness?: string;
  propertyVisible?: string;
  propertyOpacity?: string;
  type: TIGER_INDICATOR_PARAMETER_TYPE;
  dontShowTickness?: boolean;
  showOpacity?: boolean;
  showTypeLine?: boolean;
  lineTypeId?: number;
  showLimit?: boolean;
  limit?: number;
  limitType?: TTigerChartIndicatorLimitTypes;
  values?: TTigerChartIndicatorParameterTypeList[];
  pointMarker?: IPointMarker;
  opacity?: number;
  staticColors?: boolean;
  min?: number;
  max?: number;
  hideCheckbox?: boolean;
}

export interface TTigerChartIndicatorParameter {
  title?: string;
  label: string;
  property: string;
  type: TIGER_INDICATOR_PARAMETER_TYPE;
  values?: TTigerChartIndicatorParameterTypeList[];
  valueSelected?: any;
  active?: boolean;
  min?: number;
  max?: number;
  increment?: number;
}

export interface TTigerChartIndicatorParameterTypeList {
  label: string;
  value: number | string;
}

export interface TTigerChartIndicatorMovingAverageOptions
  extends TigerChartIndicatorDefaultOptions {
  lineColor?: string;
  upperHistogramColor?: string;
  bottomHistogramColor?: string;
  macdColor?: string;
  signalColor?: string;
  timePeriod?: number;
  lineThickness?: number;
  macdThickness?: number;
  signalThickness?: number;
  offset?: number;
  source?: TIGER_INDICATOR_SOURCES;
  fastTimePeriod?: number;
  slowTimePeriod?: number;
  signalTimePeriod?: number;
}

export interface TMAPoints {
  output: number[];
}

export enum TTigerChartIndicatorSettingsTabs {
  SETTINGS = 'Entrada',
  STYLES = 'Estilos',
}

export enum TTigerChartIndicatorLimitTypes {
  UPPER = 'UPPER',
  BOTTOM = 'BOTTOM',
}

export enum MAType {
  SMA = 0,
  EMA = 1,
  WMA = 2,
  DEMA = 3,
  TEMA = 4,
  TRIMA = 5,
  KAMA = 6,
  MAMA = 7,
  T3 = 8,
}

export interface ITigerChartModalConfigsClose {
  save: boolean;
  tabName: TTigerChartIndicatorSettingsTabs;
}

export interface BollingerBandsOptions
  extends TigerChartIndicatorDefaultOptions {
  upperColor?: string;
  baseColor?: string;
  bottomColor?: string;
  backgroundColor?: string;
  upperVisible?: boolean;
  baseVisible?: boolean;
  bottomVisible?: boolean;
  backgroundVisible?: boolean;
  inReal: number[];
  timePeriod?: number;
  nbDevUp?: number;
  nbDevDn?: number;
  MAType?: MAType;
  upperThickness?: number;
  baseThickness?: number;
  bottomThickness?: number;
  standardDeviation?: number;
}

export interface BollingerBandsData {
  upperBand: number[];
  middleBand: number[];
  lowerBand: number[];
}

export interface TigerChartIndicatorMedianPriceOptions
  extends TigerChartIndicatorDefaultOptions {
  lineColor?: string;
  lineThickness?: number;
}

export interface MedianPriceData {
  output: number[];
}

export interface TigerChartIndicatorRelativeStrengthIndexOptions
  extends TigerChartIndicatorDefaultOptions {
  lineColor?: string;
  upperLimitColor?: string;
  bottomLimitColor?: string;
  backgroundColor?: string;
  timePeriod: number;
  upperLimit?: number;
  bottomLimit?: number;
  upperLimitLineType?: number;
  bottomLimitLineType?: number;
  lineThickness?: number;
}

export interface TigerChartIndicatorStochasticIndexOptions
  extends TigerChartIndicatorDefaultOptions {
  lineColorK?: string;
  lineColorD?: string;
  upperLimitColor?: string;
  bottomLimitColor?: string;
  backgroundColor?: string;
  fastK_Period: number;
  slowK_Period: number;
  slowD_Period: number;
  upperLimit?: number;
  bottomLimit?: number;
  upperLimitLineType?: number;
  bottomLimitLineType?: number;
  lineThickness?: number;
  lineVisibleK?: boolean;
  lineVisibleD?: boolean;
  upperVisible?: boolean;
  bottomVisible?: boolean;
  backgroundVisible?: boolean;
}

export interface RelativeStengthIndexData {
  output: number[];
}

export interface StochasticIndexData {
  slowK: number[];
  slowD: number[];
}

export interface TigerChartIndicatorParabolicSAROptions
  extends TigerChartIndicatorDefaultOptions {
  lineColor?: string;
  lineThickness?: number;
  acceleration: number;
  maximum: number;
}

export interface ParabolicSARData {
  output: number[];
}

export interface TigerChartIndicatorCdlhammerOptions
  extends TigerChartIndicatorDefaultOptions {
  backgroundColor?: string;
}

export interface CdlhammerData {
  output: number[];
}

export interface TigerChartIndicatorAveragePriceOptions
  extends TigerChartIndicatorDefaultOptions {
  lineColor?: string;
  lineThickness?: number;
}

export interface TTigerChartIndicatorMovingAverageColorOptions
  extends TigerChartIndicatorDefaultOptions {
  highlightOpacity?: number;
  highlightColor?: string;
  lineColor?: string;
  lineThickness?: number;
  lineColor2?: string;
  lineThickness2?: number;
  timePeriod: number;
  timePeriod2: number;
  source: TIGER_INDICATOR_SOURCES;
  source2: TIGER_INDICATOR_SOURCES;
  offset: number;
  offset2: number;
}

export interface TigerChartIndicatorDefaultOptions {
  data: TData;
  service: TalibService;
  tickSize: number;
  lineNumber: string;
  visible: boolean;
  precision?: number;
}

export interface AveragePriceData {
  output: number[];
}

export interface MACDData {
  MACD: number[];
  MACDHist: number[];
  MACDSignal: number[];
}

export interface TigerChartIndicatorTypicalPriceOptions
  extends TigerChartIndicatorDefaultOptions {
  lineColor?: string;
  lineThickness?: number;
}

export interface AverageTrueRangeData {
  output: number[];
}

export interface TigerChartIndicatorAverageTrueRangeOptions
  extends TigerChartIndicatorDefaultOptions {
  lineColor?: string;
  timePeriod?: number;
  lineThickness?: number;
}

export interface TigerChartIndicatorPercentagePriceOscillatorOptions
  extends TigerChartIndicatorDefaultOptions {
  lineColor?: string;
  lineThickness?: number;
  fastPeriod: number;
  slowPeriod: number;
  precision: number;
}

export interface TigerChartIndicatorADXOptions
  extends TigerChartIndicatorDefaultOptions {
  lineColor?: string;
  upperHistogramColor?: string;
  bottomHistogramColor?: string;
  macdColor?: string;
  signalColor?: string;
  timePeriod?: number;
  smoothing?: number;
  lineThickness?: number;
  macdThickness?: number;
  signalThickness?: number;
  offset?: number;
  source?: TIGER_INDICATOR_SOURCES;
  fastTimePeriod?: number;
  slowTimePeriod?: number;
  signalTimePeriod?: number;
}

export interface TigerChartIndicatorCandleCounterOptions {
  data: TData;
  service: TalibService;
  lineNumber: string;
  textColor: string;
}

export interface CandleCounterData {
  output: number[];
  count: string[];
}

export interface TTigerChartIndicatorTextSeriesConfig {
  label: string;
  id: string;
  color: string;
  thickness: number;
  active: boolean;
  property?: string;
  propertyColor?: string;
  propertyThickness?: string;
  type: TIGER_INDICATOR_PARAMETER_TYPE;
  dontShowTickness?: boolean;
  showTypeLine?: boolean;
  lineTypeId?: number;
  showLimit?: boolean;
  limit?: number;
  limitType?: TTigerChartIndicatorLimitTypes;
  values?: TTigerChartIndicatorParameterTypeList[];
  pointMarker?: IPointMarker;
}

export interface TigerChartIndicatorElephantBarOptions
  extends TigerChartIndicatorDefaultOptions {
  timePeriod?: number;
  positiveBarColor?: string;
  negativeBarColor?: string;
}
export interface TigerChartIndicatorWeisWaveOptions
  extends TigerChartIndicatorDefaultOptions {
  volumeColorUp?: string;
  volumeColorDown?: string;
  timePeriod?: number;
  priceSource?: string;
}
