<div class="h-100 d-flex flex-column">
  <ng-container *ngIf="!isCardView; else cardViewTemplate">
    <app-orders-history-table-view
      #ordersTable
      [refComponent]="refComponent"
      [componentType]="componentType"
      [componentId]="componentId"
      [fieldIndexer]="fieldIndexer"
      [globalCdStock]="globalCdStock"
      [noRowsTemplateMessage]="noRowsTemplateMessage"
      [onUpdateField]="onUpdateField"
      [topOptions]="topOptions"
      [bottomOptions]="bottomOptions"
      [ignoreRocketService]="ignoreRocketService"
      (removeAllAlertsAndOrders)="
        sendEventsToRemoveAlertInChartAndOrdersHistory($event)"
      (gridReadyHandler)="onGridReady($event)"
      (changeStockHandler)="changeStockHandler.emit($event)"
      (columnMovedHandler)="columnMovedHandler.emit($event)"
      (columnResizedHandler)="columnResizedHandler.emit($event)"
      (contextMenuHandler)="emitContextMenuHandler($event)"
      (gridSortChange)="gridSortChange.emit($event)"
      (gridColumnApiReference)="onColumnApi($event)"
      (gridFilterChange)="gridFilterChange.emit($event)"
      (resetPositionHandler)="getStockByResetPosition($event)"
      (editOrderHandler)="editOrder($event)"
      (editAlertHandler)="openModalChangeAlertPrice($event)"
    ></app-orders-history-table-view>
    <rocket-grid
      *ngIf="showTotalRow"
      class="footer-grid"
      (onGridReady)="onTotalGridReady($event)"
      [setColumnDefsDefault]="tableConfig?.columnDefs"
      [columnDefs]="tableConfig?.footerColumnDefs"
      [gridOptions]="bottomOptions"
      [fieldIndexer]="fieldIndexer"
      height="26px"
    ></rocket-grid>
    <ng-container *ngIf="showSideBarResetAllPositions">
      <app-reset-all-positions
        css="position-absolute bottom-0"
        [disableCancelAllPositions]="disableCancelAllPositions"
        (resetAllPosition)="resetAllPosition($event)"
      ></app-reset-all-positions>
    </ng-container>
  </ng-container>
  <ng-template #cardViewTemplate>
    <app-orders-history-card-list
      *ngIf="refComponent; else loadingTemplate"
      #ordersCards
      [refComponent]="refComponent"
      [componentType]="componentType"
      [componentId]="componentId"
      [data]="dataValues"
      [tabSelected]="tabSelected"
      [width]="width"
      [height]="height"
      [globalCdStock]="globalCdStock"
      (changeStockHandler)="changeStockHandler.emit($event)"
      (contextMenuHandler)="emitContextMenuHandler($event)"
      (editOrderHandler)="editOrder($event)"
      (editAlertHandler)="openModalChangeAlertPrice($event)"
      (resetPositionHandler)="getStockByResetPosition($event)"
      (removeAllAlertsAndOrders)="
      sendEventsToRemoveAlertInChartAndOrdersHistory($event)"
      (cardsReadyHandler)="onCardsReady()"
    ></app-orders-history-card-list>
    <app-orders-history-total-row
      #totalRow
      *ngIf="showTotalRow"
      [tabSelected]="tabSelected"
      [componentKey]="componentKey"
      [tooltipCancel]="tooltipCancel"
      [disableCancelAllPositions]="disableCancelAllPositions"
      [width]="width"
      [disableCancelAllAlertsOrOrders]="disableCancelAllAlertsOrOrders"
      (cancelAllOrdersAndAlerts)="cancelAllOrdersAndAlerts()"
      (resetCustodyPosition)="resetAllPosition($event)"
    ></app-orders-history-total-row>
  </ng-template>
</div>
<ng-template #loadingTemplate>
  <ng-container>
    <div class="h-100 d-flex align-items-sm-center justify-content-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-container>
</ng-template>
