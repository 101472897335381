import { Injectable } from '@angular/core';
import { NotificationChannel } from '@shared/channel/notification-channel';

@Injectable({
  providedIn: 'root',
})
export class ProcessAlertService {
  constructor(private _notificationChannel: NotificationChannel) {}

  getNotiticationObs() {
    return this._notificationChannel.onAlerts();
  }
}
