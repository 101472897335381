<app-graphic-screening-header
  #graphicHeader
  [stocks]="filters.filter.stocks"
  (changeTab)="changeTab($event)"
></app-graphic-screening-header>
<div class="bg-dark fs-6 d-flex justify-content-between align-items-center">
  <div class="align-items-center d-flex">
    <div class="border-right pt-1 px-2" tooltip="Filtro">{{ filterName }}</div>
    <div class="border-right pt-1 px-2" tooltip="Intervalo">
      {{ intervalName }}
    </div>
  </div>
  <rocket-button type="icon" css="mr-1" (rocketClick)="refresh(true)">
    <fla-icon
      name="sync"
      size="icon-size-micro"
      css="text-brand-primary"
      tooltip="Reiniciar"
    ></fla-icon>
  </rocket-button>
</div>
<app-graphic
  *ngIf="selectStock"
  [infosTickSize]="infosTickSize"
  [interval]="interval"
  [idExchange]="idExchange"
  [stock]="selectStock"
  [listPoints]="listPoints"
  [isRetart]="isRetart"
></app-graphic>
