import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlaButtonCloseComponent } from './button-close.component';

@NgModule({
  declarations: [FlaButtonCloseComponent],
  imports: [CommonModule],
  exports: [FlaButtonCloseComponent],
  providers: [],
})
export class FlaButtonCloseModule {}
