import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { GraphContainer } from '@shared/tiger-chart/directives/graph-container.directive';
import { TIGER_INDICATORS_ENUM } from '@shared/tiger-chart/enum';
import { BaseIndicator } from '@shared/tiger-chart/indicators';
import { TTooltipIndicatorValue } from '@shared/tiger-chart/types/tiger-chart.types';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { Subscription, auditTime, delay, filter } from 'rxjs';
import { TooltipMenu } from '../tooltip-menu.component';
import { TIGER_CHART_INDICATORS_ABREVIATIONS } from '@shared/tiger-chart/constants/tiger-chart.constants';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { THEMES } from '@shared/services/core/custom-preferences/theme/theme-preferences.interface';
import { SettingsModalService } from '@shared/tiger-chart/indicators/settings-modal/settings-modal.service';
import { TigerChartIndicatorElephantBarBase } from '@shared/tiger-chart/indicators/base/elephant-bar-base';

@Component({
  selector: 'app-tiger-chart-tooltip-indicator',
  templateUrl: './tooltip-indicator.component.html',
  styleUrls: ['./tooltip-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipIndicatorComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() indicator!: BaseIndicator;
  @Input() id!: string;
  @Input() graphContainer!: GraphContainer;
  @Input() tickSize!: number;
  @Input() mouseIndex!: number;
  @Output() indicatorSettings = new EventEmitter<{
    type: TIGER_INDICATORS_ENUM;
    lineNumber: string;
  }>();
  @Output() indicatorDelete = new EventEmitter<{
    type: TIGER_INDICATORS_ENUM;
    lineNumber: string;
  }>();
  @Output() indicatorVisibilityChange = new EventEmitter();
  private _mouseEventSub!: Subscription;
  private onMenuCloseSubscription!: Subscription;

  indicatorValues: TTooltipIndicatorValue[] = [];
  isHover: boolean = false;

  abreviations = TIGER_CHART_INDICATORS_ABREVIATIONS;
  locale = '';
  private _indicatorEventSub!: Subscription;
  private _themeActiveObservable$!: Subscription;
  isIndicatorClicked: boolean = false;
  isDarkTheme: boolean = true;
  private blockBackspace$!: Subscription;
  private blockBackspace: boolean = false;

  constructor(
    @Inject(LOCALE_ID) _locale: string,
    private stockChartService: StockChartService,
    private themeService: ThemePreferencesService,
    private cdr: ChangeDetectorRef,
    private settingsModalService: SettingsModalService
  ) {
    this.locale = _locale;
    this._mouseEventSub = this.stockChartService.mouseIndex$
      .pipe(filter((value) => value.ref === this.id))
      .subscribe((value) => {
        this.indicatorValues = this.indicator.getValuesByIndex(value.index);
        this.cdr.detectChanges();
      });
    this.blockBackspace$ = this.settingsModalService.blockBackspace$
      .pipe(auditTime(10))
      .subscribe((data) => {
        this.blockBackspace = data;
      });
    this.isDarkTheme = this.themeService.isDarkTheme();
  }

  ngOnInit() {
    this.indicatorValues = this.indicator.getValuesByIndex();
    this.checkIsClicked();
  }

  ngOnDestroy() {
    this._mouseEventSub && this._mouseEventSub.unsubscribe();
    this._themeActiveObservable$ && this._themeActiveObservable$.unsubscribe();
    this._indicatorEventSub && this._indicatorEventSub.unsubscribe();
    this.blockBackspace$ && this.blockBackspace$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { mouseIndex } = changes;
    if (mouseIndex && mouseIndex.currentValue) {
      this.indicatorValues = this.indicator.getValuesByIndex(
        mouseIndex.currentValue
      );
      this.cdr.detectChanges();
    }
  }

  ngAfterViewInit() {
    this._themeActiveObservable$ = this.themeService
      .themeActiveObservable()
      .pipe(delay(50))
      .subscribe((theme) => {
        this.isDarkTheme = theme === THEMES.dark;
        this.indicatorValues = this.indicator.getValuesByIndex();
        this.cdr.detectChanges();
      });
  }

  onMenuClick(event: any) {
    const { offsetLeft, offsetTop } = event.target;

    const component =
      this.graphContainer.viewContainerRef.createComponent<TooltipMenu>(
        TooltipMenu
      );
    component.instance.indicator = this.indicator;
    component.instance.top = offsetTop + 6;
    component.instance.left = offsetLeft + 10;
    component.instance.graphContainer = this.graphContainer;
    this.onMenuCloseSubscription = component.instance.onMenuClose.subscribe(
      () => {
        this.onMenuCloseSubscription.unsubscribe();
        component.destroy();
      }
    );
  }

  private validityVisibility() {
    this.indicator.changeVisibility();
    if (this.indicator.type === TIGER_INDICATORS_ENUM.BAR_ELEPHANT) {
      const indicator = this.indicator as TigerChartIndicatorElephantBarBase;
      if (indicator) {
        indicator.usePaletteElephant(this.indicator.isVisible);
      }
    }
  }

  onHide() {
    this.validityVisibility();
  }

  onSettings() {
    this.indicatorSettings.emit({
      type: this.indicator.type,
      lineNumber: this.indicator.lineNumber,
    });
  }

  onDelete() {
    this.indicator.delete();
    this.indicatorDelete.emit({
      type: this.indicator.type,
      lineNumber: this.indicator.lineNumber,
    });
  }

  changeVisibility(e: any) {
    this.validityVisibility();
    this.indicatorVisibilityChange.emit();
    if (
      e &&
      e.target &&
      e.target.parentNode &&
      (e.target.parentNode as ParentNode).nodeName === 'BUTTON'
    ) {
      const tooltipId = (e.target.parentNode as HTMLElement).getAttribute(
        'aria-describedby'
      );
      if (tooltipId) {
        const tooltip = document.getElementById(tooltipId);
        if (tooltip) {
          tooltip.innerHTML = `<div class="tooltip-inner">${this.visibilityTooltip()}</div>`;
        }
      }
    }
  }

  visibilityTooltip() {
    return this.indicator.isVisible ? 'Ocultar' : 'Exibir';
  }

  checkIsClicked() {
    this._indicatorEventSub =
      this.stockChartService.indicatorClicked$.subscribe((value) => {
        this.isIndicatorClicked = false;
        if (this.indicator.type) {
          const lineName = `${this.indicator.mainLineId}_${this.indicator.lineNumber}`;
          if (value.isAnnotation) {
            const [prefix, suffix] = value.name.split('_');
            value.id = `${prefix}-line-cdlhammer_${suffix}`;
          }
          this.isIndicatorClicked = lineName === value.id;
          if (value.type === 'DOUBLECLICK' && lineName == value.id) {
            this.onSettings();
          }
        }
        this.cdr.detectChanges();
      });
  }

  @HostListener('window:keydown.delete')
  delHandler() {
    if (this.isIndicatorClicked) {
      this.onDelete();
    }
  }

  @HostListener('window:keydown.backspace')
  backspaceHandler() {
    if (this.isIndicatorClicked && !this.blockBackspace) {
      this.onDelete();
    }
  }

  @HostListener('window:keydown.esc')
  escHandler() {
    this.isIndicatorClicked = false;
    this.cdr.detectChanges();
  }

  selectIndicator() {
    const indicator: any = this.indicator;
    if (indicator.renderableSeries || indicator.lineRenderableSeries)
      this.stockChartService.indicatorClick({
        type: 'CLICK',
        indicator: indicator.renderableSeries || indicator.lineRenderableSeries,
      });
  }
}
