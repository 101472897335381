import { Routes, RouterModule } from '@angular/router';
import { PublicComponent } from './public.component';
import { RocketTraderTermsComponent } from './components/terms-of-use-and-privacy-policy/rocket-trader-terms/rocket-trader-terms.component';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { BtgTermsComponent } from './components/terms-of-use-and-privacy-policy/btg-terms/btg-terms.component';

const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    children: [
      {
        path: 'termos-de-uso-e-politica-de-privacidade',
        component: RocketTraderTermsComponent,
      },
      {
        path: 'btg/termos-de-uso-e-politica-de-privacidade',
        component: BtgTermsComponent,
      },
      {
        path: 'confirmacao-email/:token',
        component: ConfirmEmailComponent,
      },
    ],
  },
];
export const PublicRoutes = RouterModule.forChild(routes);
