<div
  *ngIf="show"
  class="round bg-neutral-strong position-absolute"
  [style.width]="'200px'"
  [style.padding]="'10px'"
  [style.top.px]="position.top"
  [style.left.px]="position.left"
  [style.z-index]="9999"
  [style.display]="isTour ? 'none' : 'block'"
>
  <div class="list-group list-group-flush">
    <div class="header-context-menu">
      <span>Boleta</span>
    </div>
    <div id="BOLETA_CONTEXT_RESET_AND_HELP">
      <button
        type="button"
        class="list-group-item list-group-item-action border-none fs-6 context-item-button"
        disabled
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="refresh"
        ></fla-icon>
        Restaurar
      </button>
      <button
        type="button"
        class="list-group-item list-group-item-action border-none fs-6 context-item-button"
        (click)="handleTour()"
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="help_outline"
        ></fla-icon>
        Ajuda
      </button>
    </div>
  </div>
</div>
