export enum COLOR_RANGE_VARIATION_RANGE {
  NEGATIVE_HALF = 'negative_half',
  POSITIVE_HALF = 'positive_half',
  NEGATIVE = 'negative',
  POSITIVE = 'positive',
}

export interface TvariationRange {
  [COLOR_RANGE_VARIATION_RANGE.NEGATIVE]: number;
  [COLOR_RANGE_VARIATION_RANGE.POSITIVE]: number;
}
