export enum ExportDataIdEnum {
  INDICADORES = 'INDICADORES',
  BALANCO_PATRIMONIAL = 'BALANCO-PATRIMONIAL',
  DRE = 'DRE',
  FLUXO_CAIXA = 'FLUXO-CAIXA',
  LISTA_ATIVOS = 'LISTA',
  ORDENS_POSICOES = 'ORDENS-E-POSICAO',
  POSICOES = 'POSICOES',
  ALERTAS = 'ORDENS-E-POSICAO-ALERTAS'
}
