import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '@shared/services/rest/rest.service';
import {
  Subject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  of,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { RxEvent } from '@shared/channel/rx-event';
import { Router } from '@angular/router';
import { sharedSessionStorage } from '@shared/services/core/storage/shared-session.storage';

@Injectable({
  providedIn: 'root',
})
export class LogoutService extends RestService {
  override _url: string = 'api/authentication/v2/authentication';

  private logout$: Subject<any> = new Subject<any>();
  private logoutMessage!: LogoutReason;
  private subjectDebounced = new Subject<void>();

  constructor(
    http: HttpClient,
    private theme: ThemePreferencesService,
    private router: Router,
    private _rxEvent: RxEvent
  ) {
    super(http);
    this.initializeTotalSubscription();
  }

  private initializeTotalSubscription(): void {
    this.subjectDebounced
      .pipe(
        debounceTime(1000),
        switchMap(() =>
          this.post<any>('logout', this.logoutMessage).pipe(
            catchError((error) => of(error))
          )
        )
      )
      .subscribe(() => {
        this.theme.resetTheme();
        this._rxEvent.disconnect('logout');
        if (this.router.url.includes('already-open')) {
          this.router.navigate(['auth/login']);
        } else {
          location.reload();
        }
      });
  }

  private doLogout = async (message: LogoutReason) => {
    this.clearStorage();
    this.logoutMessage = message;
    this.subjectDebounced.next();
  };

  onLogout() {
    return this.logout$.asObservable().pipe(
      distinctUntilChanged(),
      skipWhile((data) => !data),
      tap((message: LogoutReason) => {
        this.doLogout(message);
      })
    );
  }

  exec = (
    reason: string = 'UNDEFINED',
    systemMessage: string = 'Sessão expirada. Faça login novamente.'
  ) => this.logout$.next({ reason, systemMessage });

  clearStorage() {
    sharedSessionStorage.clear();
    localStorage.clear();
  }
}

export interface LogoutReason {
  reason: string;
  systemMessage?: string;
}
