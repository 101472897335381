import { Injectable } from '@angular/core';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { AUTH_LOCAL_KEYS } from '../../const/auth_util.const';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';

@Injectable({
  providedIn: 'root',
})
export class ListStocksPreferences extends CustomPreferencesService {
  get allStockLists() {
    const allLists = this.storage.getItem(AUTH_LOCAL_KEYS.ALL_LIST_STOCKS);
    const result = isNullOrUndefined(allLists) ? null : allLists;
    return result;
  }

  set allStockLists(e: any) {
    this.storage.setItem(AUTH_LOCAL_KEYS.ALL_LIST_STOCKS, e);
  }
}
