import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RocketModalModule } from '@shared/rocket-components/components';
import { CardBrokerComponent } from './components/card-broker/card-broker.component';
import { CardListComponent } from './components/card-list/card-list.component';
import { ModalBrokersToConnectComponent } from './modals/modal-brokers-to-connect/modal-brokers-to-connect.component';
import { BrokerLogoComponent } from './components/broker-logo/broker-logo.component';

@NgModule({
  imports: [CommonModule, RocketModalModule, BrokerLogoComponent],
  declarations: [
    CardBrokerComponent,
    CardListComponent,
    ModalBrokersToConnectComponent,
  ],
})
export class BrokerConnectionModule {}
