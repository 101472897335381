<div
  class="d-flex flex-column gap-2 h-100 position-relative"
  *ngIf="indexes.length && !loading; else loadingRef"
>
  <div class="h-100">
    <div class="h-100">
      <table class="h-100 w-100">
        <thead>
          <tr class="w-100 d-flex p-2">
            <th class="text-left col fw-bold" scope="col">Índices</th>
            <th class="text-center col fw-bold" scope="col">Participação</th>
            <th class="col" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <cdk-virtual-scroll-viewport
            #scrollViewport
            itemSize="20"
            class="w-100 h-100"
          >
            <ng-container *cdkVirtualFor="let row of indexes">
              <tr class="text-white align-baseline w-100 d-flex cursor-pointer" (click)="indexClick(row)">
                <td class="p-2 text-left col">
                  <p class="fw-bold mb-0">{{row.cdIndex}}</p>
                  <small class="fs-6">{{row.dsIndexDescription || '-'}}</small>
                </td>
                <td class="text-center p-2 col">{{row.formattedComposition}}</td>
                <td class="p-2 col">
                  <div class="d-flex justify-content-end align-items-center">
                    <p class="mb-0 mr-2">{{row.lastPrice | number: '1.2-2'}}</p>
                    <app-tag class="d-contents" [variation]="(row.dayVariation | number: '1.2-2') + '%'" [isVariation]="true"
                      [bgColor]="row.arrow_hex" [color]="row.arrow_font_hex" [symbol]="' '" [useSmallTag]="true">
                    </app-tag>
                  </div>
                </td>
              </tr>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #loadingRef>
  <div *ngIf="loading; else noInfo" class="h-100">
    <app-loading></app-loading>
  </div>
</ng-template>
<ng-template #noInfo>
  <div class="h-100 w-100 d-flex justify-content-center align-items-center">
    <app-no-info></app-no-info>
  </div>
</ng-template>
<ng-template #noValue> - </ng-template>
