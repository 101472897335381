import { Component, Input } from '@angular/core';
import { IWorkSpaceComponet } from '@core/interface';
import { RankingBrokerService } from '../../services/ranking-broker.service';

@Component({
  selector: 'app-flash-market-part',
  templateUrl: './flash-market-part.component.html',
})
export class FlashMarketPartComponent {
  @Input() component!: IWorkSpaceComponet;
  @Input() refComponent!: string;

  constructor(private _rankingBrokerService: RankingBrokerService) {}

  public onChangeTypeFilter(event: string) {
    this._rankingBrokerService.viewTypeSelected = event;
  }
}
