<div #card class="card w-100 bg-neutral-strong py-2 cursor-pointer">
  <div class="card-body">
    <div class="d-flex justify-content-between">
      <div class="w-auto hstack">
        <app-stock-logo [image]="stockImage" class="mr-2"></app-stock-logo>
        <div>
          <p class="card-title fs-5" [ngClass]="{'text-brand-primary': isSelected }">{{ cdStock }}</p>
          <small class="card-subtitle fw-bold">
            {{ lastPrice }} |
            <span [style.color]="variationColor"> {{ variation }}% </span>
          </small>
        </div>
      </div>
      <div class="w-auto d-flex justify-content-end align-items-center">
        <div [style.width.px]="!isCompact ? 190 : 140">
          <app-candle-horizontal
            [valueOpen]="vlOpen"
            [valueClose]="vlClose"
            [valueMin]="vlLow"
            [valueMax]="vlHigh"
            [arrowColor]="variationBgColor"
            [avgPrice]="vlAvgPrice"
            [alertPrice]="alertPrice"
            [tickSize]="tickSize"
            [hideArrow]="hideArrow"
            [hideAlert]="false"
          ></app-candle-horizontal>
        </div>
        <div class="mx-3">
          <fla-icon name="edit" css="cursor-pointer text-feedback-warning no-select" (click)="editAlert($event)"></fla-icon>
        </div>
        <div>
          <fla-icon name="close" css="cursor-pointer text-feedback-negative no-select" (click)="cancelAlert($event)"></fla-icon>
        </div>
      </div>
    </div>
  </div>
</div>
