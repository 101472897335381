import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  firstValueFrom,
  map,
  Observable,
  of,
  shareReplay,
} from 'rxjs';
import { RestService } from '@shared/services/rest/rest.service';
import {
  formatDate,
  getSinonimousByStock,
  newDateToVMDate,
} from 'src/app/utils/utils.functions';
import {
  IStockAcquisitionsInfos,
  IStockIndexResponse,
  IStockListItems,
  IStockListItemsDynamic,
  IStockListItemsWithView,
  IStocksAcquisitions,
  IStockTypeConfig,
  StockListItemsWithView,
} from 'src/app/core/interface';
import { IStockListItemsRow } from '@shared/components/stock-table/interfaces/stock-table.interfaces';
import { StockListRowModel } from '@shared/components/stock-table/models/stock-table-row.model';
import { CorporateEventsCacheService } from '@shared/services/core/cache/corporate-events-cache';
import { FiiSummaryCacheService } from '@shared/services/core/cache/fii-summary-cache';
import { PERIODS_ENUM } from '@shared/components/business-profile/business-profile.enum';
import { Dictionary } from '@core/models';
import { bigValueFormatterWithoutDots } from '@shared/rocket-components/utils';
import { IAPIServiceResponse } from './interface/apiResponse';
import { IStockIndexesParticipation } from './interface/indexesParticipation';
import { StockIndexCacheService } from '@shared/services/core/cache/stock-index-cache.service';

@Injectable({
  providedIn: 'root',
})
export class StockService extends RestService {
  override _url: string = 'api';

  private _repurchaseDict = new Dictionary<IStockAcquisitionsInfos>();
  private _hasFilterOnStocksSearch = new BehaviorSubject<boolean | undefined>(
    undefined
  );

  get hasFilterOnSearchStock(): Observable<boolean> {
    if (this._hasFilterOnStocksSearch.getValue() === undefined)
      this.getHiddenStockTypesList().subscribe();
    return this._hasFilterOnStocksSearch.asObservable() as Observable<boolean>;
  }

  constructor(
    private corporateEventsCacheService: CorporateEventsCacheService,
    private fiiSummaryCacheService: FiiSummaryCacheService,
    private stockIndexCacheService: StockIndexCacheService,
    http: HttpClient
  ) {
    super(http);
  }

  public getStockListItemsWithView = (
    list: Array<string> | null = null,
    idStockList: number | null = null,
    refComp: string = ''
  ): Observable<StockListItemsWithView> =>
    this.post<any>('trademap/v9/stock-list/get-stock-list-items-with-view', {
      cd_stocklist_dynamic: null,
      id_market_sector: null,
      id_stock_list: idStockList,
      stocks: list,
      id_portfolio_rec: null,
      type_filter: null,
    }).pipe(
      map((res) => {
        const stockListItemsWithView: StockListItemsWithView = {
          stockListItemsRow: [],
          stocksMapped: {},
        };
        if (res.data && res.data?.success && res.data?.result) {
          const result = res.data.result;
          const stocksMapped: any = {};
          const list: Array<IStockListItemsRow> = [];
          result.forEach((stock: IStockListItemsWithView) => {
            stock.idStockList = idStockList!;
            stock = getSinonimousByStock(stock);
            const rowModel = new StockListRowModel(stock, refComp);
            list.push(rowModel);
            stocksMapped[rowModel.idRow] = rowModel;
          });
          stockListItemsWithView.stockListItemsRow = list;
          stockListItemsWithView.stocksMapped = stocksMapped;
          return stockListItemsWithView;
          // return (MOCK_LIST_ITEMS.result as IStockListItemsWithView[]).map(
          //   (item: IStockListItemsWithView) => getSinonimousByStock(item)
          // );
        }
        return stockListItemsWithView;
      }),
      catchError(() =>
        of({
          stockListItemsRow: [],
          stocksMapped: {},
        })
      )
    );

  public getStockListItemsDynamic = () =>
    this.post<any>('stock-list/v10/get-stock-list-items-dynamic', {
      group: 'Populares',
      cd_stocklist_dynamic: 'TRENDING_RT',
      type_filter: {
        limit: 30,
        offset: 0,
      },
    }).pipe(
      map((res) => {
        if (res.data) {
          return (res.data as IStockListItemsDynamic[]).map(
            (item: IStockListItemsDynamic) => getSinonimousByStock(item)
          );
        }
        return [];
      }),
      catchError(() => of([]))
    );

  public getStockListItems(
    idStockList: number
  ): Observable<Array<IStockListItems>> {
    return this.post<Array<IStockListItems>>(
      'trademap/v9/stock-list/get-stock-list-items',
      {
        id_stock_list: idStockList,
      }
    ).pipe(map((res) => this.filterStock(res.data)));
  }

  public getStockTypesList(): Observable<IStockTypeConfig[] | undefined> {
    return this.post<IStockTypeConfig[] | undefined>(
      'trademap/v9/stock/get-all-types-rocket',
      {}
    ).pipe(
      map((response: any) => {
        if (
          !response.data ||
          !response.data?.success ||
          !response.data?.result
        ) {
          return;
        }
        return response.data.result;
      })
    );
  }

  public getStockTypeOrder(): Observable<string[]> {
    return this.post<any>('trademap/v9/stock/get-tipos-order', {}).pipe(
      map((response: any) => {
        if (
          !response.data ||
          !response.data?.success ||
          !response.data?.result
        ) {
          return [];
        }
        return response.data.result;
      })
    );
  }

  public getHiddenStockTypesList(): Observable<string[]> {
    return this.post<IStockTypeConfig[] | undefined>(
      'trademap/v9/stock/get-tipos-list',
      {}
    ).pipe(
      map((response: any) => {
        this._verifyHasSomeStockTypeToIgnore(response.data.result);
        const hiddenStocksTypes = response.data.result;
        if (!hiddenStocksTypes || !hiddenStocksTypes.length) return [];
        if (hiddenStocksTypes[0] === 'TODOS') return [];
        return hiddenStocksTypes;
      })
    );
  }

  private _verifyHasSomeStockTypeToIgnore(items: string[]): void {
    if (!items?.length || items[0] === 'TODOS') {
      this._hasFilterOnStocksSearch.next(false);
      return;
    }
    this._hasFilterOnStocksSearch.next(true);
  }

  public saveHiddenStockTypesList(
    list: string[]
  ): Observable<IAPIServiceResponse> {
    this._hasFilterOnStocksSearch.next(list[0] !== 'TODOS' && list.length > 0);
    return this.post<IStockTypeConfig[] | undefined>(
      'trademap/v9/stock/salve-tipos-list',
      { tipos: list }
    ).pipe(
      map((response: any) => {
        if (!response.data || !response.data.success)
          return this.handlerServiceError(response);
        return {
          success: true,
          message: response.data.result.next_interaction,
        };
      }),
      catchError((error) => of(this.handlerServiceError(error)))
    );
  }

  public saveStockTypesOrder(list: string[]): Observable<IAPIServiceResponse> {
    return this.post<IStockTypeConfig[] | undefined>(
      'trademap/v9/stock/salve-tipos-order',
      { tipos: list }
    ).pipe(
      map((response: any) => {
        if (!response.data || !response.data.success)
          return this.handlerServiceError(response);
        return {
          success: true,
          message: response.data.result.next_interaction,
        };
      }),
      catchError((error) => of(this.handlerServiceError(error)))
    );
  }

  private filterStock(data: any): Array<IStockListItems> {
    if (data && data.success && data.result) {
      return (data.result as IStockListItems[]).map((item: IStockListItems) =>
        getSinonimousByStock(item)
      );
    }
    return [];
  }

  public getHistCorporateEventFull(cdStock: string, idExchange: number) {
    const key = `${cdStock}:${idExchange}`;
    let request$: any = this.corporateEventsCacheService.getValue(key);
    if (!request$) {
      request$ = this.post('trademap/v1/stock/get-hist-corporate-event-full', {
        cd_stock: cdStock,
        id_exchange: idExchange,
      }).pipe(shareReplay(1));
      this.corporateEventsCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }

  public fiiSummary(cdStock: string) {
    let request$: any = this.fiiSummaryCacheService.getValue(cdStock);
    if (!request$) {
      request$ = this.get(`trademap/v1/stock/summary/fiis?cdStock=${cdStock}`).pipe(
        shareReplay(1)
      );
      this.fiiSummaryCacheService.setValue(request$, cdStock);
    }
    return firstValueFrom(request$);
  }

  public getStockIndicatorsChart(stocks: string, period: PERIODS_ENUM) {
    const key = `${stocks}:${period}`;
    let request$: any = this.fiiSummaryCacheService.getValue(key);
    if (!request$) {
      request$ = this.get(
        `trademap/v3/stock/indicators/chart/${period}/?cdStocks=${stocks}`
      ).pipe(shareReplay(1));
      this.fiiSummaryCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }

  public getStocksAcquisitions(
    ds_asset: string
  ): Observable<IStockAcquisitionsInfos> {
    const key = `getStocksAcquisitions_${ds_asset}`;
    if (this._repurchaseDict.has(key))
      return of(this._repurchaseDict.get(key)!);
    return this.get(`trademap/v1/stocks/acquisitions?companyCode=${ds_asset}`).pipe(
      catchError((err) => this.handlerServiceError(err)),
      map((response: any) => {
        if (response && response.data) {
          if (response.data.length)
            return this._processStocksAcquisitionsResponse(response.data, key);
          return { active: [], concluded: [] };
        }
        throw new Error('');
      })
    );
  }

  private _processStocksAcquisitionsResponse(
    acquisitions: IStocksAcquisitions[],
    dictKey: string
  ): IStockAcquisitionsInfos {
    const infos: IStockAcquisitionsInfos = { active: [], concluded: [] };
    const currentDate = parseInt(newDateToVMDate());
    acquisitions.forEach((acquisition) => {
      const isFinalized = acquisition.endedAt > currentDate;
      acquisition.approveDateFormatted = formatDate(acquisition.approveDate);
      acquisition.startedAtFormatted = formatDate(acquisition.startedAt);
      acquisition.endedAtFormatted = formatDate(acquisition.endedAt);
      acquisition.quantityFormatted = bigValueFormatterWithoutDots(
        acquisition.quantity
      ).replace('.', ',');
      acquisition.status = isFinalized ? 'Ativo' : 'Finalizado';
      acquisition.statusColor = isFinalized
        ? 'text-feedback-success'
        : 'text-neutral-medium';
      if (isFinalized) infos.active.push(acquisition);
      else infos.concluded.push(acquisition);
    });
    this._repurchaseDict.set(dictKey, infos);
    return infos;
  }

  public getStockIndexList(): Promise<IStockIndexResponse> {
    let request$: any = this.stockIndexCacheService.getValue();
    if (!request$) {
      request$ = this.post<IStockIndexResponse>(
        'trademap/v1/stock/get-all-index-simple',
        {}
      ).pipe(
        map((res: any) => {
          return { success: true, data: res.data ? res.data.result : [] };
        }),
        catchError((error) => of(this.handlerServiceError(error))),
        shareReplay(1)
      );
      this.stockIndexCacheService.setValue(request$);
    }
    return firstValueFrom(request$);
  }

  public getStockIndexesParticipations(
    cdStock: string
  ): Observable<IStockIndexesParticipation[]> {
    return this.get(`trademap/v1/stock/${cdStock}/indexes/participations`).pipe(
      catchError((error) => of(this.handlerServiceError(error))),
      map((res: any) => {
        return res.data || [];
      })
    );
  }

  public getIndexDescription(
    cd_stock: string,
    id_exchange: number
  ): Observable<string> {
    return this.post<string>('trademap/v1/stock/index-description', {
      cd_stock,
      id_exchange,
    }).pipe(
      map((res: any) => {
        return res.data ? res.data.result : '';
      }),
      catchError((error) => of(this.handlerServiceError(error)))
    );
  }
}
