import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableTradeIdeaItemsComponent } from './table-trade-idea-items.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TableTradeIdeaItemsComponent],
  exports: [TableTradeIdeaItemsComponent],
})
export class TableTradeIdeaItemsModule {}
