<div
  *ngIf="isVisible"
  class="round bg-neutral-strong position-absolute overflow-y-auto sized-events"
  [style.top.px]="top"
  [style.left.px]="left"
>
  <div class="list-group list-group-flush">
    <div class="header-context-menu">
      <span>{{ eventHeader }}</span>
    </div>
    <section
      *ngFor="let event of eventsToShow"
      class="d-flex flex-column cursor-pointer"
      (click)="onClick(event)"
    >
      <div class="fs-6">{{ event.title }}</div>
      <section
        class="d-flex align-items-center"
        [tooltip]="event?.tooltip ?? event.description"
      >
        <span
          class="fs-5 text-neutral-medium text-truncate"
          [innerHTML]="event.description"
        ></span>
        <fla-icon
          name="chevron_right"
          size="icon-size-sm"
          css="text-neutral-medium"
        ></fla-icon>
      </section>
    </section>
  </div>
</div>
