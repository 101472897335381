export enum TIGER_INTERVAL_ENUM {
  ONE_MINUTE = 0,
  FIVE_MINUTE = 1,
  FIFTEEN_MINUTE = 2,
  THIRTY_MINUTE = 3,
  ONE_HOUR = 4,
  ONE_DAY = 5,
  THIS_WEEK = 6,
  ONE_MONTH = 7,
  TWO_MINUTE = 8,
  ONE_WEEK = 9,
  TEN_MINUTE = 10,
}

export enum TIGER_TYPE_CHART_ENUM {
  BAR = 'bar',
  LINE = 'line',
  AREA = 'area',
  CANDLE = 'candle',
}

export enum TIGER_INDICATORS_ENUM {
  MOVING_AVERAGE = 'Média Móvel',
  MOVING_AVERAGE_COLOR = 'Coloração de Médias Móveis',
  EXPONENTIAL_MOVING_AVERAGE = 'Média Móvel Exponencial',
  WEIGHTED_MOVING_AVERAGE = 'Média Móvel Ponderada',
  BOLLINGER_BANDS = 'Bandas de Bollinger',
  MEDIAN_PRICE = 'Mediana',
  RELATIVE_STRENGTH_INDEX = 'Índice de Força Relativa',
  STOCHASTIC = 'Estocástico',
  PARABOLIC_SAR = 'SAR Parabólico',
  WEIS_WAVE = 'Weis Wave',
  CDL_HAMMER = 'Martelo',
  CDL_INVERTED_HAMMER = 'Martelo Invertido',
  MOVING_AVERAGE_CONVERGENCE_DIVERGENCE = 'MACD',
  AVERAGE_PRICE = 'Preço Médio',
  CDL_DOJI = 'Doji',
  CDL_ENGULFING = 'Engulfing Pattern',
  DOUBLE_EXPONENTIAL_MOVING_AVERAGE = 'Média Móvel Exponencial Dupla',
  TRIPLE_EXPONENTIAL_MOVING_AVERAGE = 'Média Móvel Exponencial Tripla',
  KAUFMAN_ADAPTIVE_MOVING_AVERAGE = 'Média Móvel de Kaufman',
  TYPICAL_PRICE = 'Preço Típico',
  AVERAGE_TRUE_RANGE = 'Média de Amplitude de Variação',
  PERCENTAGE_PRICE_OSCILLATOR = 'Oscilador de Preço',
  TRIANGULAR_MOVING_AVERAGE = 'Média Móvel Triangular',
  MOMENTUM = 'Momentum',
  AVERAGE_DIRECTIONAL_X = 'Índice Médio Direcional',
  CANDLE_COUNTER = 'Contador de Candle',
  BAR_ELEPHANT = 'Barra de Elefante',
}

export enum TIGER_INDICATOR_PARAMETER_TYPE {
  NUMBER,
  SELECTION,
  COLOR,
}

export enum TIGER_INDICATORS_MENU_ENUM {
  VISUAL_ORDER,
  MOVE_TO,
  FIX_IN_SCALE,
  COPY,
  HIDE,
  DELETE,
  SETTINGS,
  ORDER_BRING_TO_FRONT,
  ORDER_SEND_TO_BACK,
  ORDER_BRING_FORWARD,
  ORDER_SEND_BACKWARD,
  MOVE_NEW_PANEL_ABOVE,
  MOVE_EXISTING_PANEL_ABOVE,
  MOVE_EXISTING_PANEL_BELOW,
  MOVE_NEW_PANEL_BELOW,
  SCALE_PIN_RIGHT,
  SCALE_PIN_A,
  SCALE_PIN_Z,
  SCALE_PIN_TO_NEW_RIGHT,
  SCALE_PIN_TO_NEW_LEFT,
  SCALE_NO_SCALE,
}

export enum TIGER_MOUSE_EVENT_DATA_TYPE {
  X_DATASERIES_HOVER,
  DOMCANVAS_2D_LEAVE,
  DOMCANVAS_2D_LEAVE_CANVAS,
  DOMCANVAS_2D_ENTER,
  DOMCANVAS_2D_MOUSE_CLICK,
  ANNOTATION_HOVER,
  DOMCANVAS_2D_GRAB,
  DOMCANVAS_2D_UNGRAB,
  XAXIS_HOVER,
  YAXIS_HOVER,
  INDICATOR_HOVER,
  INDICATOR_LEAVE,
  DOMCANVAS_2D_MIDDLE_MOUSE_CLICK,
  YAXIS_GROW,
}

export enum TIGER_KEYPRESS_ARROWS {
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  ZOOM_IN = 'ZoomIn',
  ZOOM_OUT = 'ZoomOut',
  REFRESH = 'Refresh',
}

export enum TIGER_INDICATOR_SOURCES {
  OPEN = 'vl_open',
  MAX = 'vl_high',
  MIN = 'vl_low',
  CLOSE = 'vl_close',
}

export enum TIGER_LINE_TYPE {
  CLOSE = 'close',
  ORDER = 'order',
  ALERT = 'alert',
  CUSTODY = 'custodia',
  ANNOTATION_EVENT = 'annotation_event',
  PREVIOUS_CLOSE = 'previous_close',
  BREAK_EVEN = 'break_even',
}

export enum TIGER_DOUBLE_STOP_ORDER_IDS {
  GAIN = 'custody_stop_gain',
  LOSS = 'custody_stop_loss',
}
