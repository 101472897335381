<div>
  <h3 class="fw-bold mb-3">Criar uma conta</h3>
  <div class="vstack gap-1 mb-3">
    <span>
      Para sua própria segurança, precisamos confirmar seu número de celular
    </span>
    <span> Informe o seu número de celular para receber o código </span>
  </div>

  <form [formGroup]="form">
    <app-phone-input
      #phoneInput
      [value]="phoneNumber.value"
      formControlName="phone_number"
      [label]="'Número do celular'"
      [requiredText]="
        phoneNumber.value ? 'Telefone inválido' : 'Esse campo é obrigatório!'
      "
      hasError="invalid"
      [isRequired]="true"
      css="w-100 mb-3"
      [initialCountry]="phoneCountryCode.value.country"
      (countryHasChanged)="onUpdateCountryCode($event)"
    ></app-phone-input>
  </form>

  <div class="form-check mb-5 d-flex align-items-center cursor-pointer terms">
    <app-check
      (flaChange)="acceptTerms()"
      css="mt-1 cursor-pointer"
      [isChecked]="acceptedTerms"
    ></app-check>
    <a class="fw-normal default-cursor form-check-label fs-base">
      Aceito os
      <a class="fs-base" target="_blank" [href]="termUrl">
        Termos de Uso e Política de Privacidade
      </a>
    </a>
  </div>

  <ng-container *ngIf="serviceError">
    <error-card
      cardMargin="mb-4"
      cardBorder="border-feedback-error"
      [message]="serviceError"
    ></error-card>
  </ng-container>

  <rocket-button
    label="Enviar código"
    type="default"
    btnType="submit"
    css="w-100 mb-3"
    [loading]="isLoading"
    [isDisabled]="this.disableSubmitBtn"
    (rocketClick)="onSetPhone()"
  >
  </rocket-button>

  <app-recaptcha
    #recaptcha
    size="invisible"
    (resolved)="onResolved($event)"
    (errored)="onError()"
  ></app-recaptcha>
</div>
