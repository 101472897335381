import { Injectable } from '@angular/core';
import { ALL_STOCKS_LABEL, CustodyService } from '@shared/services/api/nitro-custody-ws/v1/custody.service';
import { PERFORMANCE_FILTERS } from '../enum/performance-report.enum';
import { operationTypeHandler } from '../constants/performance-report.contants';
import { Observable } from 'rxjs';
import { ICustodyChartInfos } from '@shared/services/api/nitro-custody-ws/v1/interface/custody.interface';
import { ICheetahCustodyPerformance } from '../interface/performance-report.interface';
import { PerformanceReportData } from '../performance-report';

@Injectable({
  providedIn: 'root',
})
export class PerformanceReportService {
  constructor(private _custodyService: CustodyService) {}

  sumReports(
    performanceData: any,
    stock: string,
    typeOperationSelected: string,
    idBroker: number
  ) {
    const values: PerformanceReportData = new PerformanceReportData(idBroker);
    const stockValues: ICheetahCustodyPerformance =
      performanceData[idBroker]?.[typeOperationSelected]?.[stock] ?? null;

    if (!stockValues) {
      if (
        typeOperationSelected == 'Posições' &&
        !performanceData[idBroker]?.[typeOperationSelected]
      ) {
        return this._sumAllPositions(performanceData[idBroker], values);
      }

      if (stock == ALL_STOCKS_LABEL)
        return this._sumAllValues(
          performanceData[idBroker]?.[typeOperationSelected],
          values
        );
    }

    return this._sumValues(stockValues, values);
  }

  private _sumAllPositions(data: any, values: any) {
    if (!data) return null;

    if (data.Intradia) values = this._sumAllValues(data.Intradia, values);
    if (data.Swing) values = this._sumAllValues(data.Swing, values);

    return values;
  }

  private _sumAllValues(performanceData: any, values: any) {
    if (!performanceData) return values;

    const types = Object.keys(performanceData);
    if (!types.length) return values;

    let stocks: any[] = [];

    for (let index = 0; index < types.length; index++) {
      const element = types[index];
      stocks = [...stocks, performanceData[element]];
    }
    const newValues = stocks.reduce(
      (previous: any, current: any) => this._sumValues(current, previous),
      values
    );
    return newValues;
  }

  private _sumValues(stockValues: any, values: PerformanceReportData) {
    if (stockValues) {
      values.setValues(stockValues);
    }

    return values;
  }

  public getCustody(filters: any): Observable<ICustodyChartInfos[]> {
    return this._custodyService.custody(
      filters[PERFORMANCE_FILTERS.PERIOD],
      operationTypeHandler(filters[PERFORMANCE_FILTERS.OPERATION_TYPE]),
      filters[PERFORMANCE_FILTERS.BROKER].id_broker,
      filters[PERFORMANCE_FILTERS.STOCK]
    );
  }
}
