import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvalidDeviceComponent } from './invalid-device.component';
import { LogoModule } from '../logo/logo.module';
import { FlaIconModule } from '@shared/rocket-components/components';
import { InvalidDeviceRoutes } from './invalid-device.routing';
import { DeviceValidatorService } from '../../services/device-validator.service';

@NgModule({
  imports: [
    CommonModule,
    LogoModule,
    FlaIconModule,
    InvalidDeviceRoutes
  ],
  declarations: [
    InvalidDeviceComponent
  ],
  providers: [
    DeviceValidatorService
  ]
})
export class InvalidDeviceModule {}
