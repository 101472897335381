export enum RENT_CONTENT_ENUM {
  GRID = 'RENT_GRID',
  INFOS = 'STOCK_INFOS',
}

export enum AXIS_ENUM {
  QUANTITY = 'QUANTITY',
  VOLUME = 'VOLUME',
  RATE = 'RATE',
  IBOV = 'IBOV',
  STOCK = 'STOCK',
}

export enum RENT_ELEMENTS_ID {
  TABLE = 'RENT_FUNDS_TABLE',
}

export enum RENT_PERIOD {
  one_year = 'oneyear',
  two_year = 'twoyear',
  five_year = 'fiveyear',
  history = 'allyears',
}
