import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { filter, skipWhile, Subscription } from 'rxjs';
import { RightAlignedOuterVerticallyStackedAxisLayoutStrategy } from 'scichart/Charting/LayoutManager/RightAlignedOuterVerticallyStackedAxisLayoutStrategy';
import { CategoryAxis } from 'scichart/Charting/Visuals/Axis/CategoryAxis';
import { NumericAxis } from 'scichart/Charting/Visuals/Axis/NumericAxis';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { NumberRange } from 'scichart/Core/NumberRange';
import { EAutoRange } from 'scichart/types/AutoRange';
import { EAxisAlignment } from 'scichart/types/AxisAlignment';
import { EXyDirection } from 'scichart/types/XyDirection';
import { DisableMoveDecorationsModifier } from '../annotations/disable-move-decorations-modifier';
import { MouseEventsModifier } from '../annotations/mouse-events-modifier';
import { RTChartScichartBase } from '../chart-base-scichart';
import { TIGER_INDICATOR_CLICK } from '../constants/tiger-chart.constants';
import { GraphContainer } from '../directives/graph-container.directive';
import { TIGER_INDICATORS_ENUM, TIGER_MOUSE_EVENT_DATA_TYPE } from '../enum';
import { BaseIndicator } from '../indicators';
import { ICandleConfigs } from '../interface';
import { TigerChartTooltipComponent } from '../tooltip/tiger-chart-tooltip.component';
import {
  TData,
  TMouseEventData,
  TUpdateData,
} from '../types/tiger-chart.types';
import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { randomId } from '@shared/rocket-components/utils';
import { ClickEventsModifier } from '../annotations/indicator-click-modifier';
import { AxisService } from '../services/axis.service';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { ScichartService } from '../services/scichart.service';
import { StockChartModalService } from '@shared/components/stock-chart/parts/modal-more-options/service/stock-chart-modal.service';
import {
  AxisBase2D,
  ChartModifierBase2D,
  CustomAnnotation,
  EChart2DModifierType,
  EllipsePointMarker,
} from 'scichart';
import { TigerMiddleChartService } from '../services/tiger-middle-chart.service';
import { CHART_COLORS } from '../colors';
import { ISearchStock } from '@core/interface';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { RTTextLengthService } from '../services/text-length.service';

@Component({
  selector: 'app-sub-tiger-chart',
  templateUrl: './sub-tiger-chart.component.html',
  styleUrls: ['./sub-tiger-chart.component.scss'],
})
export class SubTigerChartComponent
  extends RTChartScichartBase
  implements OnChanges, AfterViewInit, OnDestroy
{
  constructor(
    textLengthService: RTTextLengthService,
    @Inject(LOCALE_ID) private locale: string,
    stockChartService: StockChartService,
    drawToolsService: DrawToolsService,
    private axisService: AxisService,
    stockChartModalService: StockChartModalService,
    middleChartService: TigerMiddleChartService,
    themeService: ThemePreferencesService
  ) {
    super(stockChartService);
    this.textLengthService = textLengthService;
    this._stockChartService = stockChartService;
    this.drawToolsService = drawToolsService;
    this.stockChartModalService = stockChartModalService;
    this.middleChartService = middleChartService;
    this._themeService = themeService;
  }
  @Input() set fastRuler(fastRuler: boolean) {
    this.started$.pipe(skipWhile((res) => !res)).subscribe(() => {
      this.showFastRuler = fastRuler;
      const chartModifiers =
        this.baseChart.sciChartSurface.chartModifiers.asArray();
      const zoomPan = chartModifiers.find(
        (mod) =>
          (mod as ChartModifierBase2D).type === EChart2DModifierType.ZoomPan
      );
      if (zoomPan) {
        zoomPan.isEnabled = !this.showFastRuler;
      }
    });
  }
  @Input() set selectedStock(stock: ISearchStock) {
    this.started$.pipe(skipWhile((res) => !res)).subscribe(() => {
      this.stock = stock;
    });
  }
  id: string = randomId('sub_chart_candle_vertical');
  private xAxisId = `xAxis_${this.id}`;
  private _axis2Id = randomId('SUB_CHART_AXIS2_ID');
  private _xAxis!: CategoryAxis;
  private _columnYAxis!: NumericAxis;
  private _candleYAxis!: NumericAxis;
  private _indicator!: BaseIndicator;
  private _stockChartService!: StockChartService;
  private updateSubchart = new Subscription();
  previousVRange!: NumberRange | undefined;
  @Output() indicatorVisibility = new EventEmitter<BaseIndicator>();
  @Output() newCursor = new EventEmitter<any>();
  @Output() subChartMouseHover = new EventEmitter<any>();
  @Output() indicatorSettings = new EventEmitter<{
    type: TIGER_INDICATORS_ENUM;
    lineNumber: string;
    refId: string;
    anotherBaseChart: TWebAssemblyChart;
  }>();
  @Output() indicatorDelete = new EventEmitter<{
    type: TIGER_INDICATORS_ENUM;
    lineNumber: string;
  }>();
  @Input() key!: string;
  @Input() declare subCharts: any;
  @Input() stockChartRef!: string;
  @Input() cdSegment!: string | undefined;
  @Input() dsAsset!: string | undefined;
  @Input() height!: number | undefined;
  @Input() width!: number | undefined;
  @Input() papel!: string;
  @Input() isMouseOverValue: boolean = false;
  @Input() showXGridLines: boolean = true;
  @Input() showYGridLines: boolean = true;
  @Input() set firstVisibleRange(value: NumberRange | undefined) {
    this._firstVisibleRange = value;
  }
  @Input() set previousVisibleRange(value: NumberRange | undefined) {
    this.previousVRange = value;
  }
  @Input() set settings(value: Partial<ICandleConfigs>) {
    this.applyConfigs = value;
  }
  @Input() set indicadores(value: BaseIndicator[]) {
    this.started$.pipe(skipWhile((res) => !res)).subscribe(() => {
      if (value) {
        const splitted = this.key.split('_');
        const indicator = value.find(
          (v) => v.type == splitted[0] && v.lineNumber == splitted[1]
        );
        if (!indicator) {
          return;
        }
        this.indicators = [indicator];
        this.getIndicators$.next(this.indicators);
      }
    });
  }
  @Input() set data(value: TData) {
    this.started$.pipe(skipWhile((res) => !res)).subscribe(() => {
      this._clearChart();
      if (value?.vl_close.length == 0) return;
      this._indicator = this.subCharts.get(this.key)!!.indicator;
      this.setInfoChart(this._indicator);
    });
  }
  @Input() set updateData(data: TUpdateData) {
    if (!data) {
      return;
    }
    if (
      !this.isMouseOverValue &&
      this.baseChart &&
      this.baseChart.sciChartSurface
    ) {
      this.tooltip.updateTextData(
        0,
        this.baseChart.sciChartSurface.renderableSeries
          .get(0)
          .getNativeXValues()
          .size() - 1,
        true
      );
    }
  }
  @Input() position!: number;
  @Input() set fatherXAxis(xAxis: AxisBase2D) {
    if (xAxis) {
      this.started$.pipe(skipWhile((res) => !res)).subscribe(() => {
        this.baseChart.sciChartSurface.xAxes.clear();
        this.setXaxis();
        this._xAxis.visibleRange = xAxis.visibleRange;
        this.setXAxisVisibleRangeChange();
      });
    }
  }
  @ViewChild(GraphContainer, { static: true }) graphContainer!: GraphContainer;
  @ViewChild('TigerChartTooltip') tooltip!: TigerChartTooltipComponent;

  ngAfterViewInit(): void {
    this._modifierGroup = this.stockChartRef;
    this.init(this.id, this.initConfig);
    this._stockChartService.updateTooltip$
      .pipe(filter((data) => data.refComponent === this.stockChartRef))
      .subscribe((data) => {
        this.tooltip.updateTextData(data.volume, data.eventData.data);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { showXGridLines, showYGridLines, previousVisibleRange } = changes;
    if (
      showXGridLines &&
      showXGridLines.currentValue != undefined &&
      this._xAxis
    ) {
      this._xAxis.drawMajorGridLines = showXGridLines.currentValue;
      this._xAxis.drawMajorTickLines = showXGridLines.currentValue;
    }
    if (
      showYGridLines &&
      showYGridLines.currentValue !== undefined &&
      this._candleYAxis
    ) {
      this._candleYAxis.drawMajorGridLines = showYGridLines.currentValue;
      this._candleYAxis.drawMajorTickLines = showYGridLines.currentValue;
    }

    if (
      previousVisibleRange &&
      previousVisibleRange.currentValue != undefined
    ) {
      this._firstVisibleRange = previousVisibleRange.currentValue;
      this.updateXaxisPosition();
    }
  }

  ngOnDestroy(): void {
    //this.baseChart.sciChartSurface.delete(true);
    this.subCharts.has(this.key) && this.subCharts.delete(this.key);
    this._stockChartService.updateSubchartXaxis$.next({ ref: this.id });
    if (this.updateSubchart) this.updateSubchart.unsubscribe();
    this.removeSubscriptions();
    this._xAxis.visibleRangeChanged?.unsubscribeAll();
  }

  private initConfig = () => {
    this.baseChart.sciChartSurface.layoutManager.rightOuterAxesLayoutStrategy =
      new RightAlignedOuterVerticallyStackedAxisLayoutStrategy();
    this.scichartService = new ScichartService(this.baseChart);
    this.setXaxis();
    this.setXAxisVisibleRangeChange();
    this.setYaxis(this._axis2Id);
    if (this.subCharts.has(this.key)) {
      this._indicator = this.subCharts.get(this.key)!!.indicator;
    }
    this.setModifiers();

    if (this.configs.enableZoom) {
      this.enableZoom([this.xAxisId], [this._axis2Id, 'DefaultAxisId']);
    }
    if (this._indicator.isNewOnChart) {
      this._stockChartService.updateSubchartXaxis$.next({ ref: this.id });
    }

    this._stockChartService.indicatorClicked$.subscribe((data) => {
      if (data && data.name) {
        this.setDataPointers(data);
        return;
      }
    });
  };

  setInfoChart(indicator: BaseIndicator) {
    const series = indicator.create({
      base: this.baseChart,
      yAxisId: this._axis2Id,
      xAxisId: this.xAxisId,
      tickSize: this.configs.yAxis.labelPrecision || 0,
    });
    if (series) {
      series.forEach((serie) => (this.renderableSeriesAdd = serie));
    }
    this.enableCursorModifier(
      1,
      EXyDirection.XyDirection,
      true,
      false,
      CHART_COLORS.NEUTRAL_MEDIUM
    );
    this.setTooltip();
  }

  private setXaxis() {
    this._xAxis = new CategoryAxis(this.baseChart.wasmContext, {
      id: this.xAxisId,
    });
    this._xAxis.drawMinorGridLines = false;
    this._xAxis.drawMinorTickLines = false;
    this._xAxis.drawMajorGridLines = this.showXGridLines;
    this._xAxis.drawMajorTickLines = this.showXGridLines;
    this._xAxis.visibleRangeLimit = new NumberRange(
      this.configs.xAxis.visibleRangeLimit?.min,
      this.configs.xAxis.visibleRangeLimit?.max
    );
    this._xAxis.growBy = new NumberRange(
      this.configs.xAxis.axisGrowBy,
      this.configs.xAxis.axisGrowBy
    );
    this._xAxis.visibleRange = new NumberRange(
      this.configs.xAxis.visibleRange?.min,
      this.configs.xAxis.visibleRange?.max
    );
    this._xAxis.autoRange = EAutoRange.Never;
    this._xAxis.offset = 20;
    this._xAxis.labelProvider.cursorNumericFormat =
      this.configs.xAxis.labelFormat!;
    this._xAxis.labelProvider.formatLabel = () => '';
    this._xAxis.labelProvider.formatCursorLabel = () => '';
    if (this.subCharts.size() - 1 == this.position) {
      this._xAxis.labelProvider.formatLabel = (value) =>
        this.axisService.xAxisLabelFormatter(
          this.configs,
          this.locale,
          value,
          false
        );
      this._xAxis.labelProvider.formatCursorLabel = (value) =>
        this.axisService.xAxisLabelFormatter(
          this.configs,
          this.locale,
          value,
          true,
          true
        );
    }
    this.baseChart.sciChartSurface.xAxes.add(this._xAxis);
  }

  private updateXaxisPosition() {
    if (this._xAxis) {
      this._xAxis.visibleRange = new NumberRange(
        this._firstVisibleRange?.min,
        this._firstVisibleRange?.max
      );
    }
  }

  private setXAxisVisibleRangeChange() {
    let lastValue = 0;
    this._xAxis.visibleRangeChanged?.unsubscribeAll();
    this.started$.pipe(skipWhile((res) => !res)).subscribe(() => {
      this._xAxis.visibleRangeChanged.subscribe((event) => {
        if (
          !event ||
          !event.visibleRange ||
          event?.visibleRange?.min == lastValue
        )
          return;

        lastValue = event?.visibleRange.min || 0;
        if (isNullOrUndefined(this.previousVRange?.min)) {
          return;
        }
        if (
          this.previousVRange?.min === event.visibleRange.min &&
          this.previousVRange?.max === event.visibleRange.max
        ) {
          return;
        }
        const notReload = this._stockChartService.notReload.get(this.id);
        if (!notReload) {
          this._stockChartService.visibleRange$.next({
            isSub: true,
            ref: this.id,
            visibleRange: event.visibleRange,
            interval: this.configs.series.interval,
            previousVisibleRange: this.previousVRange,
          });
        }
        this.previousVRange = event.visibleRange;
      });
    });
  }

  private setYaxis(id: string) {
    this._columnYAxis = new NumericAxis(this.baseChart.wasmContext, {
      id: id,
      axisAlignment: EAxisAlignment.Left,
    });
    this._columnYAxis.isVisible = false;
    this._columnYAxis.drawLabels = false;
    this._columnYAxis.labelProvider.formatCursorLabel = () => '';
    this._columnYAxis.autoRange = EAutoRange.Always;
    this._columnYAxis.growBy = new NumberRange(0, 5);
    this.baseChart.sciChartSurface.yAxes.add(this._columnYAxis);

    this._candleYAxis = new NumericAxis(this.baseChart.wasmContext);
    this._candleYAxis.drawMinorGridLines = false;
    this._candleYAxis.drawMinorTickLines = false;
    this._candleYAxis.drawMajorGridLines = this.showYGridLines;
    this._candleYAxis.drawMajorTickLines = this.showYGridLines;
    this._candleYAxis.growBy = new NumberRange(0.05, 0.009);
    this._candleYAxis.autoRange = EAutoRange.Always;
    this._candleYAxis.labelProvider.numericFormat =
      this.configs.yAxis.labelFormat!;
    this._candleYAxis.labelProvider.precision =
      this.configs.yAxis.labelPrecision!;
    this._candleYAxis.labelProvider.formatLabel = (value) =>
      this.yAxisLabelFormatter(value);
    this._candleYAxis.labelProvider.formatCursorLabel = (value) =>
      this.yAxisLabelFormatter(value);

    this.baseChart.sciChartSurface.yAxes.add(this._candleYAxis);
  }

  private yAxisLabelFormatter = (value: number) => {
    return this.axisService.yAxisLabelFormatter({
      value,
      cdSegment: this.cdSegment,
      configs: this.configs,
      dsAsset: this.dsAsset,
      papel: this.papel || '',
      hasPercentagePriceOscillator:
        this.tooltip.indicators[0]?.type ===
        TIGER_INDICATORS_ENUM.PERCENTAGE_PRICE_OSCILLATOR,
      indicators: this.tooltip.indicators,
    });
  };

  private setModifiers() {
    const mouseEventsModifier = new MouseEventsModifier(
      this.middleChartService,
      this.stockChartModalService,
      this._stockChartService,
      this.stockChartRef,
      this.xAxisId
    );
    mouseEventsModifier.onEvents().subscribe((e) => this.mouseEvents(e));
    mouseEventsModifier.onClickEvents().subscribe((e) => this.mouseEvents(e));
    mouseEventsModifier.onHitSeriesEvents().subscribe((e) => {
      return this.hitSeriesEvent(e);
    });
    mouseEventsModifier.onDrawAnnotationHoverEvents().subscribe((draw) => {
      this.annotationHoverEvents(draw.draw);
      this.newCursor.emit(this.cursor);
    });

    const clickIndicator = new ClickEventsModifier();
    clickIndicator.events$.subscribe((e: any) => {
      this.setDataPointers(e);
      return this._stockChartService.indicatorClick(e);
    });

    this.modifiersAdd = mouseEventsModifier;
    this.modifiersAdd = clickIndicator;
    this.modifiersAdd = new DisableMoveDecorationsModifier(this.id);
  }

  override mouseEvents(eventData: TMouseEventData | null) {
    this.chartHeight = this.height!!;
    super.mouseEvents(eventData, false);
    this.updateDraw(eventData);
    this.baseChart.sciChartSurface.yAxes.get(
      1
    ).labelProvider.formatCursorLabel = (value) =>
      this.yAxisLabelFormatter(value);
    if (
      eventData &&
      (eventData.type ===
        TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_LEAVE_CANVAS ||
        eventData.type === TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_LEAVE)
    ) {
      this.cursorModifier &&
        (this.cursorModifier.showYLine = this.configs.enableCursorModifier);
    } else {
      this.cursorModifier && (this.cursorModifier.showYLine = true);
    }
    this._stockChartService.clickRef = this.id;
    this.subChartMouseHover.emit(eventData);
  }

  openIndicatorSettings(event: any) {
    this.indicatorSettings.emit({
      type: event.type,
      lineNumber: event.lineNumber,
      refId: this.id,
      anotherBaseChart: this.baseChart,
    });
  }

  deleteIndicator(event: any) {
    this.indicatorDelete.emit(event);
  }

  private setTooltip() {
    this.tooltip.init(null, this._indicator);
  }

  private _clearChart() {
    this.baseChart.sciChartSurface.renderableSeries.clear();
    this.baseChart.sciChartSurface.annotations.clear();
  }

  private setDataPointers(e: any) {
    const renderableSeries = this.baseChart.sciChartSurface.renderableSeries;
    this.resetAllPointsMaker();

    const permitedAllLines = ['MACD'];
    const lineToSet: any = {
      MACD: ['-signal-line_', '-main-line_'],
    };

    if (
      TIGER_INDICATOR_CLICK.includes(e.type) &&
      !(e.indicator instanceof CustomAnnotation)
    ) {
      const objData = {
        width: 8,
        height: 8,
        strokeThickness: 2,
        stroke: e.indicator.stroke,
        fill: 'black',
      };

      const hasMatchPermited = permitedAllLines.some((word) =>
        e.indicator.id.includes(word)
      );

      if (hasMatchPermited) {
        const name = e.indicator.id.split('-')[0];
        const numberId = e.indicator.id.split('_')[1];

        lineToSet[name].forEach((indicador: any) => {
          const series = renderableSeries.getById(
            `${name}${indicador}${numberId}`
          );
          const pointMarkerTemp = this.createEllipsePointMarker(
            this.baseChart.wasmContext,
            objData
          );
          if (!isNullOrUndefined(series)) {
            series.pointMarker = pointMarkerTemp;
          }
        });

        return;
      }

      const selectedSeries = renderableSeries.getById(`${e.indicator.id}`);
      const pointMarker = this.createEllipsePointMarker(
        this.baseChart.wasmContext,
        objData
      );
      if (!isNullOrUndefined(selectedSeries)) {
        selectedSeries.pointMarker = pointMarker;
      }
    } else if (e.type === 'DONTCLICK') {
      this.resetAllPointsMaker();
    }
  }

  private resetAllPointsMaker() {
    const renderableSeries = this.baseChart.sciChartSurface.renderableSeries;

    renderableSeries['items'].forEach((series: any) => {
      const pointMarker = this.createEllipsePointMarker(
        this.baseChart.wasmContext,
        {
          width: 0,
          height: 0,
          strokeThickness: 0,
          stroke: 'transparent',
          fill: 'transparent',
        }
      );
      const annotationsBlock = ['Martelo', 'Martelo Invertido', 'Doji'];
      const seriesId = series.id;
      const hasMatch = annotationsBlock.some((word) => seriesId.includes(word));
      if (!hasMatch) {
        series.pointMarker = pointMarker;
      }
    });
  }

  private createEllipsePointMarker(wasmContext: any, options: any) {
    return new EllipsePointMarker(wasmContext, options);
  }

  private hitSeriesEvent(eventData: TMouseEventData) {
    const funcs: any = {
      [TIGER_MOUSE_EVENT_DATA_TYPE.INDICATOR_HOVER]: () => {
        if (!this.isGrabbing()) {
          this.updateChartCursor('pointer');
        }
      },
      [TIGER_MOUSE_EVENT_DATA_TYPE.INDICATOR_LEAVE]: () => {
        if (!this.isGrabbing()) {
          this.updateChartCursor('default');
        }
      },
    };

    const func = funcs[eventData.type];
    if (func) {
      func();
    }
    this.subChartMouseHover.emit(eventData);
  }
}
