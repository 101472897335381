<ng-container *ngIf="!withIcon; else icon">
  <svg:path
    [attr.fill]="themeSVG.fontColor"
    d="M 93.6009 7.533 L 85.1273 7.533 L 83.2977 11.8695 L 93.6009 11.8695 Z M 93.6009 7.533"
  />
  <svg:path
    [attr.fill]="themeSVG.fontColor"
    d="M 176.472 45.115 C 170.1814 45.115 165.0784 40.0079 165.0784 33.7122 C 165.0784 27.4165 170.1814 22.3094 176.472 22.3094 C 182.7627 22.3094 187.8657 27.4165 187.8657 33.7122 C 187.8657 40.0079 182.7627 45.115 176.472 45.115 Z M 176.472 17.7481 C 167.6775 17.7481 160.5201 24.8792 160.5201 33.7122 C 160.5201 42.5452 167.6461 49.6763 176.472 49.6763 C 185.298 49.6763 192.424 42.5452 192.424 33.7122 C 192.424 24.8792 185.298 17.7481 176.472 17.7481 Z M 176.472 17.7481"
  />
  <svg:path
    [attr.fill]="themeSVG.fontColor"
    d="M 215.1799 31.0777 L 215.1799 22.7904 L 224.3906 22.7904 C 226.6697 22.7904 228.5317 24.6544 228.5317 26.9345 C 228.5317 29.2147 226.6697 31.0777 224.3906 31.0777 Z M 233.089 26.9021 C 233.089 22.116 229.2051 18.2301 224.423 18.2301 L 210.654 18.2301 L 210.654 49.1619 L 215.1799 49.1619 L 215.1799 35.6076 L 222.3687 35.6076 L 229.5908 49.1619 L 234.7262 49.1619 L 227.2479 35.1256 C 230.6499 33.937 233.089 30.7243 233.089 26.9021 Z M 233.089 26.9021"
  />
  <svg:path
    [attr.fill]="themeSVG.fontColor"
    d="M 129.2268 35.6076 L 139.5937 35.6076 L 139.5937 38.0801 C 139.048 39.6221 138.1813 40.9718 137.026 42.0633 C 134.9727 44.0225 132.084 45.115 128.9058 45.115 C 122.6151 45.115 117.5121 40.0079 117.5121 33.7122 C 117.5121 27.4165 122.6151 22.3094 128.9058 22.3094 C 132.0516 22.3094 134.9403 23.5943 136.9947 25.6821 L 140.0757 22.3408 C 137.1871 19.5149 133.2707 17.7795 128.9058 17.7795 C 120.1123 17.7795 112.9549 24.9105 112.9549 33.7446 C 112.9549 42.5776 120.0799 49.7087 128.9058 49.7087 C 136.0318 49.7087 142.0977 45.6607 144.1196 38.884 L 144.1196 31.1101 L 129.2268 31.1101 L 129.2268 35.639 Z M 129.2268 35.6076"
  />
  <svg:path
    [attr.fill]="themeSVG.fontColor"
    d="M 260.2736 37.1486 L 265.6986 24.1724 L 271.1225 37.1486 Z M 268.1377 18.2301 L 263.2585 18.2301 L 250.3562 49.1619 L 255.2668 49.1619 L 258.3803 41.7099 L 273.0159 41.7099 L 276.1294 49.1619 L 281.04 49.1619 Z M 268.1377 18.2301"
  />
  <svg:path
    [attr.fill]="themeSVG.fontColor"
    d="M 77.7138 37.1486 L 83.1377 24.1724 L 88.5617 37.1486 Z M 80.6987 18.2301 L 67.7953 49.1619 L 72.706 49.1619 L 75.8194 41.7099 L 90.4551 41.7099 L 93.5685 49.1619 L 98.4792 49.1619 L 85.5769 18.2301 Z M 80.6987 18.2301"
  />
</ng-container>
<ng-template #icon>
  <svg:path
    [attr.fill]="themeSVG.fontColor"
    d="M 183.6009 7.533 L 175.1273 7.533 L 173.2977 11.8695 L 183.6009 11.8695 Z M 183.6009 7.533"
  />
  <svg:path
    [attr.fill]="themeSVG.fontColor"
    d="M 266.472 45.115 C 260.1814 45.115 255.0784 40.0079 255.0784 33.7122 C 255.0784 27.4165 260.1814 22.3094 266.472 22.3094 C 272.7627 22.3094 277.8657 27.4165 277.8657 33.7122 C 277.8657 40.0079 272.7627 45.115 266.472 45.115 Z M 266.472 17.7481 C 257.6775 17.7481 250.5201 24.8792 250.5201 33.7122 C 250.5201 42.5452 257.6461 49.6763 266.472 49.6763 C 275.298 49.6763 282.424 42.5452 282.424 33.7122 C 282.424 24.8792 275.298 17.7481 266.472 17.7481 Z M 266.472 17.7481"
  />
  <svg:path
    [attr.fill]="themeSVG.fontColor"
    d="M 305.1799 31.0777 L 305.1799 22.7904 L 314.3906 22.7904 C 316.6697 22.7904 318.5317 24.6544 318.5317 26.9345 C 318.5317 29.2147 316.6697 31.0777 314.3906 31.0777 Z M 323.089 26.9021 C 323.089 22.116 319.2051 18.2301 314.423 18.2301 L 300.654 18.2301 L 300.654 49.1619 L 305.1799 49.1619 L 305.1799 35.6076 L 312.3687 35.6076 L 319.5908 49.1619 L 324.7262 49.1619 L 317.2479 35.1256 C 320.6499 33.937 323.089 30.7243 323.089 26.9021 Z M 323.089 26.9021"
  />
  <svg:path
    [attr.fill]="themeSVG.fontColor"
    d="M 219.2268 35.6076 L 229.5937 35.6076 L 229.5937 38.0801 C 229.048 39.6221 228.1813 40.9718 227.026 42.0633 C 224.9727 44.0225 222.084 45.115 218.9058 45.115 C 212.6151 45.115 207.5121 40.0079 207.5121 33.7122 C 207.5121 27.4165 212.6151 22.3094 218.9058 22.3094 C 222.0516 22.3094 224.9403 23.5943 226.9947 25.6821 L 230.0757 22.3408 C 227.1871 19.5149 223.2707 17.7795 218.9058 17.7795 C 210.1123 17.7795 202.9549 24.9105 202.9549 33.7446 C 202.9549 42.5776 210.0799 49.7087 218.9058 49.7087 C 226.0318 49.7087 232.0977 45.6607 234.1196 38.884 L 234.1196 31.1101 L 219.2268 31.1101 L 219.2268 35.639 Z M 219.2268 35.6076"
  />
  <svg:path
    [attr.fill]="themeSVG.fontColor"
    d="M 350.2736 37.1486 L 355.6986 24.1724 L 361.1225 37.1486 Z M 358.1377 18.2301 L 353.2585 18.2301 L 340.3562 49.1619 L 345.2668 49.1619 L 348.3803 41.7099 L 363.0159 41.7099 L 366.1294 49.1619 L 371.04 49.1619 Z M 358.1377 18.2301"
  />
  <svg:path
    [attr.fill]="themeSVG.fontColor"
    d="M 167.7138 37.1486 L 173.1377 24.1724 L 178.5617 37.1486 Z M 170.6987 18.2301 L 157.7953 49.1619 L 162.706 49.1619 L 165.8194 41.7099 L 180.4551 41.7099 L 183.5685 49.1619 L 188.4792 49.1619 L 175.5769 18.2301 Z M 170.6987 18.2301"
  />
  <svg:path
    [attr.fill]="themeSVG.bgIconColor"
    d="M 60 0.081 L 127.1763 0.081 L 127.1763 67.311 L 60 67.311 Z M 60 0.081"
  />
  <svg:path
    [attr.fill]="themeSVG.iconColor"
    d="M 108.9463 49.1619 L 96.044 18.2301 L 91.1333 18.2301 L 78.1987 49.1619 L 83.1093 49.1619 L 93.5725 24.1076 L 104.0356 49.1619 Z M 108.9463 49.1619"
  />
</ng-template>
