import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TOOLS_TYPE_DRAW } from '@shared/tiger-chart/tiger-chart-tools/tiger-chart-tools.enum';

@Component({
  selector: 'app-opacity-slider',
  templateUrl: './opacity-slider.component.html',
  styleUrls: ['./opacity-slider.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule],
})
export class OpacitySliderComponent {
  @Input() opacityValue!: number;
  @Input() typeColorUpdate!: TOOLS_TYPE_DRAW | undefined;
  @Input() showLabel: boolean = true;
  @Input() showSlider: boolean = true;
  @Output() opacityEmit = new EventEmitter<number>();
  private hashKeys: any = {
    8: true,
    9: true,
    13: true,
    37: true,
    38: true,
    39: true,
    40: true,
    46: true,
    48: true,
    49: true,
    50: true,
    51: true,
    52: true,
    53: true,
    54: true,
    55: true,
    56: true,
    57: true,
  };

  opacityChange() {
    this.opacityEmit.emit(this.opacityValue);
  }
}
