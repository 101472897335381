export const RECOVERY_STEPS = {
  INITIAL: 'INITIAL',
  VALIDATED: 'VALIDATED',
  COMPLETED: 'COMPLETED',
  UNDEFINED: 'UNDEFINED',
};

export const RECOVERY_STEPS_MESSAGE = (step?: string | null) => {
  let message: string | null = null;
  switch (step) {
    case RECOVERY_STEPS.COMPLETED:
      message = 'Ok, pode fazer login com sua nova senha';
      break;
  }
  return message;
};

export const RECOVERY_STORAGE = {
  RECOVERY_DATA: 'RECOVERY_DATA',
  APPFLOW: 'appFlow',
  RECOVERYINFOTYPE: 'recoveryInfoType',
  RECOVERYINFO: 'recoveryInfo',
  DOCUMENTTYPE: 'documentType',
  OPERATINGSYSTEM: 'operatingSystem',
  VLDOCUMENT: 'vlDocument',
  TOKEN: 'token',
  RECOVERYSTATUS: 'recoveryStatus',
};

export const RECOVERY_TYPES = ['whatsapp', 'phone_mobile'];
