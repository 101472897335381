<div class="d-flex flex-column mt-2">
  <fla-card class="pr-2 w-100 d-flex flex-wrap gaped">
    <fla-card-custom
      #card
      [css]="'p-1 bg-neutral-strong mt-2'"
      *ngFor="let card of cards"
    >
      <div
        class="min-size-card card-body justify-content-center align-items-center"
      >
        <div
          class="d-flex flex-column align-items-center h-100 justify-content-center"
        >
          <span class="text-neutral-medium fs-5">{{ card.title }}</span>
          <span class="fs-5 text-center" [innerHTML]="card.value"></span>
        </div>
      </div>
    </fla-card-custom>
  </fla-card>
  <div class="about w-100 d-flex flex-column gap-2 mt-3">
    <span>Sobre</span>
    <span class="truncated fs-5">{{ companyInfo.description }}</span>
  </div>
</div>
