<form class="container">
  <div class="row mb-3" *ngFor="let setting of indicator.settings">
    <div class="col-8">
      <label [for]="setting.type" class="form-label col-16">
        {{ setting.label }}
      </label>
    </div>
    <div class="col-8">
      <input
        type="number"
        class="form-control form-control-sm"
        *ngIf="setting.type == parameterType.NUMBER"
        [id]="setting.type"
        [value]="getValue(setting.property)"
        (change)="onChange($event, setting)"
        [min]="setting.min ?? 0"
        [step]="setting.increment ?? 1"
      />
      <ng-select
        *ngIf="setting.type == parameterType.SELECTION"
        class="form-select form-select-sm form-control-sm"
        bindValue="value"
        [name]="setting.label"
        [id]="setting.type"
        [clearable]="false"
        [items]="setting.values || null"
        [ngModel]="getValue(setting.property)"
        (change)="onChange($event, setting)"
      >
      </ng-select>
    </div>
  </div>
</form>
