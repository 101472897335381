import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ISearchStock } from '@core/interface';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { MiniBookChannel } from '@shared/channel/minibook.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { buildWorkerMessage } from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-options-strategy-book',
  templateUrl: './options-strategy-book.component.html',
  styleUrls: ['./options-strategy-book.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsStrategyBookComponent
  extends ReadStreamBase
  implements OnInit
{
  @Input() refId!: string;
  @Input() cdStockOption!: string;
  @Input() stock!: ISearchStock | undefined;

  data: any[] = [];

  private _bookParams!: SubscribeParam;
  private _initData: any = {};
  private _worker!: Worker;

  constructor(
    private _bookChannel: MiniBookChannel,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this._createWorker();
    this._worker.postMessage({ itemsLength: 5 });
  }

  ngOnInit() {
    this._subscribe();
    const incomingStreamController = buildWorkerMessage(this._worker);
    this._bookChannel.onEvents().then((data) => {
      data.snapshot(this._bookParams.itemsArray);
      this.readStream(data.stream, (data) => {
        this._channelHandler(data, incomingStreamController);
      });
    });
  }

  private _subscribe() {
    this._bookParams = {
      itemsArray: [`${this.cdStockOption}:${this.stock?.id_exchange}`],
      options: { quantity: 5 },
    };
    this._bookChannel.subscribe(this._bookParams);
  }

  private _channelHandler(payload: any, incomingStreamController: any) {
    const data = payload.get(this._bookParams.itemsArray[0]);
    if (!data) return;

    const infos = {
      hideHighlightQuantity: true,
      hideAggressor: true,
      hideHighlightOffer: true,
      minimumPrice: 0,
      maximumPrice: 0,
      tickSizeDenominator: this.stock?.tick_size_denominator ?? 2,
      tickSizeQuantity: this.stock?.tick_size_quantity ?? 0,
    };
    this._initData = { ...this._initData, ...data, ...infos };
    incomingStreamController.enqueue(this._initData);
  }

  private _createWorker(): void {
    if (typeof Worker !== 'undefined') {
      this._worker = new Worker(
        new URL('../../../../../worker/table-book.worker', import.meta.url)
      );
      this._worker.onmessage = async (event: any) => {
        const data = event.data;
        if (data && data.outgoingStream) {
          this.readStream(data.outgoingStream, this._resultProcess);
        }
      };
    } else {
      alert('Atualize o navegador ou instale um mais moderno como o Chrome!');
    }
  }

  private _resultProcess = ({ data }: any): void => {
    console.log('options strategy');
    const { list } = data;
    this.data = list;
    this.cdr.detectChanges();
  };
}
