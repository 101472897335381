import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RocketIconsComponent } from './rocket-icons.component';

@NgModule({
  imports: [CommonModule],
  declarations: [RocketIconsComponent],
  exports: [RocketIconsComponent],
})
export class RocketIconsModule {}
