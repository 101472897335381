import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { deepClone } from '@shared/rocket-components/utils';
import { HomeService } from '@modules/home/service/home.service';
import { MiniBookChannel } from '@shared/channel/minibook.channel';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { TABLE_BOOK_CONTEXT_MENU_INIT } from './constants/table-book.constants';
import { ITableBookContextMenu } from './interface/table-book.interface';
import {
  IMultibrokerBar,
  ISearchStock,
  IWorkSpaceComponet,
} from 'src/app/core/interface';
import { IntrojsService } from '@core/introjs/introjs.service';
import { Subscription, Subject, auditTime, tap } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DataResume } from './data-resume';
import { IStockAuctionInfos } from '@shared/interfaces/stock-auction-infos.interface';
import {
  buildBookInfo,
  createArrayIndex,
  getAuctionInfos,
} from 'src/app/utils/utils.functions';
import { BookByOfferChannel } from '@shared/channel/book-by-offer.channel';
import { TABLE_BOOK_CONTEXT_MENU_ENUM } from './enum/table-book.enum';
import { RocketStreamRead } from '@shared/channel/rx-event';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { ActivatedRoute } from '@angular/router';
import { ContextMenuService } from '../popup-root/context-menu.service';
import { TabelBookContextMenuComponent } from './parts/table-book-context-menu/table-book-context-menu.component';
import { ContextMenuEventPayload } from '../popup-root/context-menu-event.model';
import { isWebViewContext } from 'src/app/desktop/integration.service';

@Component({
  selector: 'app-table-book',
  templateUrl: './table-book.component.html',
  styleUrls: ['./table-book.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableBookComponent
  extends RocketComponentBase
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() component!: IWorkSpaceComponet;
  @Input() height!: any;
  @Input() width!: any;
  @ViewChild('book', { static: true }) bookElementRef!: ElementRef;
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  cdkVirtualScrollViewport!: CdkVirtualScrollViewport;
  isDesktop = false;
  isTour: boolean = false;
  stockAuctionInfos!: IStockAuctionInfos;
  dataTable: any[] = [];
  dataResume!: DataResume;
  dataChartForce!: IMultibrokerBar;
  virtualScrollViewportHeigth = 300;
  snapshotReady = false;
  stubData: any = {};

  private contextMenuItems: ITableBookContextMenu[] = deepClone(
    TABLE_BOOK_CONTEXT_MENU_INIT
  );
  private qouteParamsResume!: SubscribeParam;
  private bookParamsTable!: SubscribeParam;
  private _introJSSubscription!: Subscription;
  private detectChangesSubject = new Subject<void>();
  private detectChangesRiseze = new Subject<void>();
  private event$: Subscription = new Subscription();
  private readonly _quantItemsTable = createArrayIndex(40, true);
  private closeStreamQoute!: () => void;
  private isChangeStock: boolean = false;

  bookChannelSnap!: (data: any) => void;
  bookOfferChannelSnap!: (data: any) => void;
  quoteChannelSnap!: (data: any) => void;
  currentSubscription!: RocketStreamRead;
  incomingStreamController: ReadableStreamDefaultController | any;

  get tickSizeDenominator() {
    return this.stock?.tick_size_denominator ?? 2;
  }
  get tickSizeQuantity() {
    return this.stock?.tick_size_quantity ?? 0;
  }
  get minimumPrice() {
    return this.dataResume?.vl_preco_minimo ?? 0;
  }
  get maximumPrice() {
    return this.dataResume?.vl_preco_maximo ?? 0;
  }
  get headerOptions() {
    return this.component.metadata?.headerOptions;
  }
  get itemsArraySelected() {
    return `${this.stock.cd_stock}:${this.stock.id_exchange}`;
  }

  get metadataConfiguration() {
    return (
      this.component.metadata?.configuration ?? {
        hidePressure: false,
        hideDetails: false,
        hideOffers: true,
        hideOrders: true,
        hideAggressor: false,
        hideHighlightQuantity: false,
        hideHighlightOffer: false,
        hidePrice: false,
      }
    );
  }
  set metadataConfiguration(data: any) {
    const config = { ...this.metadataConfiguration, ...data };
    this.component.metadata.configuration = config;
    this._updateMetadata();
  }

  get stock(): ISearchStock {
    return this.component.metadata.component?.stock;
  }
  set stock(stock: ISearchStock) {
    this.component.metadata.component.stock = stock;
  }

  get bookByOfferActive() {
    return !this.metadataConfiguration.hideOffers;
  }
  constructor(
    private _bookChannel: MiniBookChannel,
    private _bookByOfferChannel: BookByOfferChannel,
    private _homeService: HomeService,
    private _quoteChannel: QuoteChannel,
    private cdr: ChangeDetectorRef,
    private _introJS: IntrojsService,
    activatedRoute: ActivatedRoute,
    private _contextMenuService: ContextMenuService
  ) {
    super(activatedRoute);
    this.isDesktop = isWebViewContext();
    this.detectChangesSubject.pipe(auditTime(100)).subscribe(() => {
      this.cdr.detectChanges();
    });
    this.detectChangesRiseze
      .pipe(
        tap(() => this.resize()),
        auditTime(25)
      )
      .subscribe(() => {
        if (!this.cdkVirtualScrollViewport) return;
        this.cdkVirtualScrollViewport.checkViewportSize();
        this.cdr.detectChanges();
      });
  }

  protected initComponent(component: any): void {
    if (component) this.component = component;
    this.subscribeChannels();
    this._quoteChannel.readEvents().then((data: any) => {
      this.quoteChannelSnap = data.snapshot;
      this.closeStreamQoute = data.close;
      data.snapshot([this.itemsArraySelected]);
      this.readStream(data.stream, this.cheetahHandler);
    });

    this.bookElementRef.nativeElement.oncontextmenu = this.showConfig;

    this._listenContextMenuEvents();

    this.height =
      this.component.metadata.componentResize?.height ??
      parseInt(this.component.metadata.layout.height);
    this.width =
      this.component.metadata.componentResize?.width ??
      parseInt(this.component.metadata.layout.width);

    this.dataResume = new DataResume(this.stock, {}, {});
  }

  private _listenContextMenuEvents() {
    this.event$.add(
      this._contextMenuService
        .listenActionEvent<TableBookComponent>(this.component.id)
        .subscribe((payload: ContextMenuEventPayload<any>) => {
          if (payload.action === 'OPEN' || payload.action === 'CLOSE') return;

          if (payload.action == 'TOUR') {
            this.startTour();
            return;
          }

          const itemIndex = this.contextMenuItems.findIndex(
            (item: ITableBookContextMenu) => item.id === payload.action
          );

          this.contextMenuItems[itemIndex].enable =
            !this.contextMenuItems[itemIndex].enable;

          const data = {
            [this.contextMenuItems[itemIndex].configKey]:
              this.contextMenuItems[itemIndex].enable,
          };

          if (TABLE_BOOK_CONTEXT_MENU_ENUM.OFFER === payload.action) {
            this.metadataConfiguration = data;
            this.unsubscribeBookChannel();
            this._getCheetahBookTable();
            this.detectChanges();
            return;
          }
          this.metadataConfiguration = data;
          this.detectChangesRiseze.next();
          this.detectChanges();
        })
    );
  }

  private showConfig = (event: any): boolean => {
    const items = this.contextMenuItems.map((item: any) => {
      item.enable = this.metadataConfiguration[item.configKey];
      return item;
    });

    TabelBookContextMenuComponent.openContextMenu(
      this._contextMenuService,
      this.component.id,
      { clientX: event.clientX, clientY: event.clientY },
      this.isTour,
      items
    );
    return false;
  };

  ngAfterViewInit(): void {
    //Tratativa de fullscreen de componentes existentes antes da alteração
    this.disableFullScreen();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const height = changes['height'];
    height?.currentValue && this.detectChangesRiseze.next();
  }

  ngOnDestroy(): void {
    this.currentSubscription && this.currentSubscription.close();
    this.closeStreamQoute && this.closeStreamQoute();
    this.qouteParamsResume &&
      this._quoteChannel.unsubscribe(this.qouteParamsResume);
    this.bookParamsTable && this.unsubscribeBookChannel();
    this.event$ && this.event$.unsubscribe();
    this._introJSSubscription && this._introJSSubscription.unsubscribe();
    this.detectChangesSubject && this.detectChangesSubject.unsubscribe();
  }

  private subscribeChannels(): void {
    if (this.stock) {
      this._getCheetahResume();
      this._getCheetahBookTable();
    }
  }

  private getChannelByConfig() {
    return !this.metadataConfiguration.hideOffers
      ? this._bookByOfferChannel
      : this._bookChannel;
  }

  private unsubscribeBookChannel() {
    const params = deepClone(this.bookParamsTable);
    const chosenChannel = this.getChannelByConfig();
    chosenChannel.unsubscribe(params);
  }

  public stockChanges(stock: ISearchStock): void {
    if (!stock || stock.cd_stock === this.stock.cd_stock) return;
    this.isChangeStock = true;
    this._quoteChannel &&
      this._quoteChannel.unsubscribe(deepClone(this.qouteParamsResume));
    this.unsubscribeBookChannel();
    this.dataResume = new DataResume(this.stock, {}, {});
    this.stock = stock;
    this._updateMetadata();
    this.subscribeChannels();
  }

  private resultProcess = (data: any): void => {
    this.dataTable = data.list;
    this.dataChartForce = { buy: data.buy, sell: data.sell };
    this.detectChanges();
  };

  private cheetahHandler = (payload: any) => {
    const res = payload.get(this.itemsArraySelected);
    if (!res) return;
    const data = this.clearCheetahData(res);

    this.dataResume = new DataResume(
      this.stock,
      data,
      this.dataResume?.getRawValues() || {}
    );

    this.stockAuctionInfos = getAuctionInfos(data, this.stockAuctionInfos);
    this.detectChanges();
  };

  private detectChanges() {
    if (this.detectChangesSubject && !this.detectChangesSubject.closed) {
      this.detectChangesSubject.next();
    }
  }

  @HostListener('window:resize')
  private resize(): void {
    let temp = this.isDesktop
      ? window.innerHeight - 55
      : parseInt(this.height) - 61;
    (!this.metadataConfiguration.hidePressure || this.isTour) && (temp -= 12);
    (!this.metadataConfiguration.hideDetails || this.isTour) && (temp -= 30);
    this.virtualScrollViewportHeigth = temp;
  }

  private _getCheetahResume = () => {
    this.qouteParamsResume = {
      itemsArray: [this.itemsArraySelected],
    };
    this._quoteChannel.subscribe(this.qouteParamsResume);
    if (this.quoteChannelSnap)
      this.quoteChannelSnap(this.qouteParamsResume.itemsArray);
  };

  private _getCheetahBookTable() {
    this.dataTable = [];
    this.bookParamsTable = {
      itemsArray: [this.itemsArraySelected],
    };
    this.snapshotReady = false;

    const chosenChannel = this.getChannelByConfig();
    chosenChannel.subscribe(this.bookParamsTable);
    if (!this.isChangeStock) {
      this.processSubscription(chosenChannel);
    } else {
      this.currentSubscription.snapshot([this.itemsArraySelected]);
    }
    this.isChangeStock = false;
  }

  private _updateMetadata() {
    if (!this.component) return;
    this.component.metadata.component.stock = this.stock;
    this._homeService.updateMeta<ISearchStock>(this.component);
  }

  private processSubscription = async (channel: any) => {
    this.currentSubscription && this.currentSubscription.close();
    this.currentSubscription = await channel.onEvents();
    this.currentSubscription.snapshot([this.itemsArraySelected]);
    this.readStream(this.currentSubscription.stream, (payload) => {
      const stock = `${this.stock.cd_stock}:${this.stock.id_exchange}`;
      const data = payload.get(stock);
      if (!data) return;
      if (data.isEndOfSnap && !this.snapshotReady) {
        this.snapshotReady = true;
      } else {
        const resultData = this.buildData(data);
        this.resultProcess(resultData);
        this.stubData = data;
      }
    });
    this.currentSubscription.snapshot([this.itemsArraySelected]);
  };

  public toggleOfferConfig() {
    this.unsubscribeBookChannel();
    this.metadataConfiguration = {
      ...this.metadataConfiguration,
      hideOffers: this.bookByOfferActive,
      hidePrice: !this.bookByOfferActive,
    };
    this._getCheetahBookTable();
    this.detectChanges();
  }

  public startTour = (): void => {
    this.isTour = true;
    this._forceDisplayContexMenu();
    this._introJS.book(this.component.id, this._forceDisplayContexMenu);
    this.detectChangesRiseze.next();
    this._introJSSubscription = this._introJS.onStart().subscribe((res) => {
      if (['DISPLAY_CONTEXT', 'HIDE_CONTEXT'].includes(res.action)) {
        this._handleContextVisibilityOnTour(res.action === 'DISPLAY_CONTEXT');
      }
      res.action === 'CLOSED' && this._closeContext();
    });
  };

  private _forceDisplayContexMenu = (isVisible: boolean = true): void => {
    this.isTour = true;
    const position = document
      .getElementById(this.component.id)
      ?.getBoundingClientRect();
    const config = {
      clientY: position!.y * 2,
      clientX: position!.x + 10,
    };
    this.showConfig(config);
    if (!isVisible) {
      setTimeout(() => {
        this._handleContextVisibilityOnTour(isVisible);
      }, 100);
    }
  };

  private _handleContextVisibilityOnTour(displayContext: boolean): void {
    const doc = document.querySelectorAll<HTMLElement>(
      'app-table-book-context-menu > div'
    );
    if (!doc || !doc.length) return;
    const element = doc[0];
    element.style.zIndex = displayContext ? '99' : '-1';
    element.style.display = displayContext ? 'block' : 'none';
  }

  private _closeContext(): void {
    const findAll = document.querySelectorAll('app-table-book-context-menu');
    findAll && findAll.length && findAll.forEach((elem) => elem.remove());
    this._introJSSubscription && this._introJSSubscription.unsubscribe();
    this.isTour = false;
    this.detectChangesRiseze.next();
  }

  public trackItemBy(index: number, bar: any) {
    return bar.index;
  }

  public titleCVClass = () => {
    if (!this.metadataConfiguration?.hideOffers) return 'wd-10';
    if (!this.metadataConfiguration?.hideOrders) return 'wd-15';

    return 'wd-25';
  };

  private buildData(data: any) {
    return buildBookInfo(
      this._quantItemsTable,
      data,
      {
        ...this.metadataConfiguration,
        minimumPrice: this.dataResume?.vl_preco_minimo,
        maximumPrice: this.dataResume?.vl_preco_maximo,
      },
      this.tickSizeDenominator,
      this.tickSizeQuantity,
      this.stubData
    );
  }
  //Tratativa para desativar fullscreen do component de book criados antes da mudança
  private disableFullScreen() {
    if (this.component.metadata.headerOptions.fullscreen) {
      this.component.metadata.headerOptions.fullscreen = false;
      this._homeService.updateMeta<ISearchStock>(this.component);
    }
  }
}
