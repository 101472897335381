<form [formGroup]="resetForm">
  <h4 class="mb-3">Recuperar senha</h4>
  <h5 class="mb-5">Vamos enviar um código por SMS ou e-mail cadastrado</h5>
  <div class="mb-3">
    <fla-input
      fla-focus
      formControlName="vlDocument"
      label="CPF"
      [flaMask]="documentMask"
      [maxLength]="documentMaxLength"
      [requiredText]="errorMessages.document"
      hasError="invalid"
      type="text"
      [isRequired]="true"
    >
    </fla-input>
  </div>
  <nav class="nav nav-pills nav-justified bg-neutral-strong p-1 round-md">
    <a
      class="nav-link mx-1 round-md"
      [class.active]="isSmsRecovery"
      (click)="changeRecoveryInfoType('phone_mobile')"
      >SMS</a
    >
    <a
      class="nav-link mx-1 round-md"
      [class.active]="!isSmsRecovery"
      (click)="changeRecoveryInfoType('email')"
      >E-mail</a
    >
  </nav>
  <div class="mb-3">
    <app-phone-input
      *ngIf="isSmsRecovery; else emailInput"
      formControlName="recoveryInfo"
      [label]="'Número do celular'"
      [requiredText]="revoveryInfoMessage"
      hasError="invalid"
      [isRequired]="true"
      [initialCountry]="phoneCountrySelected.country"
      css="w-100"
      (countryHasChanged)="onUpdateCountryCode($event)"
    ></app-phone-input>
    <ng-template #emailInput>
      <fla-input
        formControlName="recoveryInfo"
        label="E-mail"
        hasError="invalid"
        [requiredText]="revoveryInfoMessage"
        type="text"
        [isRequired]="true"
      >
      </fla-input>
    </ng-template>
  </div>
  <app-recaptcha
    #recaptcha
    size="invisible"
    (resolved)="onResolved($event)"
    (errored)="onError()"
  ></app-recaptcha>
  <rocket-button
    label="Enviar código"
    type="default"
    btnType="submit"
    css="w-100"
    [loading]="isLoading"
    [isDisabled]="!isFormValid || isLoading"
    (rocketClick)="makeRecaptchaExecute()"
  >
  </rocket-button>
  <ng-container *ngIf="erroData.hasError">
    <error-card
      cardMargin="mt-3"
      [message]="erroData.errorMessage"
    ></error-card>
  </ng-container>
  <div class="mt-8">
    <h4>Mudou seu telefone e seu e-mail?</h4>
    <h5 class="fs-5">
      Clique
      <a
        class="fw-bold cursor-pointer text-brand-primary fs-5"
        href="mailto:contato@rockettrader.com.br?subject=Recupera%C3%A7%C3%A3o%20de%20senha&body=Ol%C3%A1!%20Estou%20tentando%20resgatar%20a%20minha%20senha%20do%20RocketTrader%2C%20mas%20mudei%20meus%20dados%20cadastrais.%0D%0A%0D%0AEnvie%20nessa%20mensagem%20as%20seguintes%20informa%C3%A7%C3%B5es%20para%20que%20possamos%20atualizar%20seu%20cadastro%20para%20voc%C3%AA%20recuperar%20sua%20senha%3A%20%0D%0A%0D%0A-%20Quer%20alterar%20e-mail%20ou%20telefone%3A%0D%0A-%20CPF%3A%0D%0A-%20Nome%20completo%3A%0D%0A-%20Telefone%2FE-mail%20antigo%3A%0D%0A-%20Telefone%2FE-mail%20novo%3A%0D%0A-%20Data%20de%20nascimento%3A%0D%0A-%20Por%20quest%C3%A3o%20de%20seguran%C3%A7a%2C%20precisamos%20que%20envie%20tamb%C3%A9m%20um%20documento%20com%20foto%20para%20comprovar%20sua%20identidade.%20Anexe%20no%20e-mail%0D%0A%0D%0AObrigado.%20em%20breve%20uma%20pessoa%20da%20nossa%20equipe%20te%20atender%C3%A1!"
        >aqui</a
      >
      que nossa equipe vai te ajudar a restaurar o acesso à sua conta!
    </h5>
  </div>
</form>
