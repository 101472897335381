import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { TIGER_INDICATOR_PARAMETER_TYPE } from '../../../enum/tiger-chart.enum';
import {
  BaseIndicator,
  TTigerChartIndicatorRenderSeriesConfig,
} from '../../indicators.types';
import { SettingsModalService } from '../settings-modal.service';
import { LINE_TYPES } from './styles-input.consts';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'RT-styles-input',
  templateUrl: './styles-input.component.html',
  styleUrls: ['./styles-input.component.scss'],
})
export class StylesInputComponent {
  @Input() indicator!: BaseIndicator;
  @Input() refComponent!: string;
  @Input() baseChart!: TWebAssemblyChart;

  @ViewChild('colorSelector') colorSelector!: NgSelectComponent;
  thicknessSize = [1, 2, 3, 4];
  typeLines = LINE_TYPES;

  formControl = new FormControl(undefined);

  get parameterType() {
    return TIGER_INDICATOR_PARAMETER_TYPE;
  }

  get styles() {
    return [...this.indicator.renderSeriesConfig, ...this.indicator.styles];
  }

  constructor(private settingsModalService: SettingsModalService) {}

  changeActive(config: TTigerChartIndicatorRenderSeriesConfig) {
    config.active = !config.active;
    //this.settingsModalService.updateTemporaryConfig(
    //  this.refComponent,
    //  this.indicator,
    //  undefined,
    //  undefined,
    //  {
    //    active: config.active,
    //    propertyColor: config.propertyColor,
    //    showTypeLine: config.showTypeLine,
    //    propertyThickness: config.propertyThickness,
    //  }
    //);
    this.indicator.updateStyles(
      this.baseChart,
      config as TTigerChartIndicatorRenderSeriesConfig
    );
  }

  changeColor(config: TTigerChartIndicatorRenderSeriesConfig, color: string) {
    config.color = color;
    //this.settingsModalService.updateTemporaryConfig(
    //  this.refComponent,
    //  this.indicator,
    //  undefined,
    //  undefined,
    //  {
    //    color: config.color,
    //    propertyColor: config.propertyColor,
    //    showTypeLine: config.showTypeLine,
    //    propertyThickness: config.propertyThickness,
    //  }
    //);
    this.indicator.updateStyles(this.baseChart, config);
  }

  changeThickness(
    config: TTigerChartIndicatorRenderSeriesConfig,
    thickness: number
  ) {
    config.thickness = thickness;
    //this.settingsModalService.updateTemporaryConfig(
    //  this.refComponent,
    //  this.indicator,
    //  undefined,
    //  undefined,
    //  {
    //    thickness: config.thickness,
    //    propertyColor: config.propertyColor,
    //    showTypeLine: config.showTypeLine,
    //    propertyThickness: config.propertyThickness,
    //  }
    //);
    this.indicator.updateStyles(this.baseChart, config);
  }

  changeTypeLine(
    config: TTigerChartIndicatorRenderSeriesConfig,
    lineId: number
  ) {
    config.lineTypeId = lineId;
    //this.settingsModalService.updateTemporaryConfig(
    //  this.refComponent,
    //  this.indicator,
    //  undefined,
    //  undefined,
    //  {
    //    lineTypeId: config.lineTypeId,
    //    propertyColor: config.propertyColor,
    //    showTypeLine: config.showTypeLine,
    //    propertyThickness: config.propertyThickness,
    //  }
    //);
    this.indicator.updateStyles(this.baseChart, config);
  }

  getValue(property: string) {
    return this.indicator[property as keyof typeof this.indicator];
  }

  onChange(event: any, property: string) {
    const value =
      typeof event.value != 'undefined' ? event.value : event.target.value;
    this.settingsModalService.updateTemporaryConfig(
      this.refComponent,
      this.indicator,
      value,
      property
    );
    this.indicator.setValue(property as keyof BaseIndicator, value);
  }
}
