<div
  class="floating-toolbar"
  cdkDrag
  [cdkDragBoundary]="'#' + parentRef"
  [cdkDragFreeDragPosition]="dragPosition"
>
  <div class="d-flex">
    <div
      class="handle d-flex justify-content-center align-items-center"
      cdkDragHandle
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 8 12"
        width="8"
        height="12"
        fill="currentColor"
      >
        <rect width="2" height="2" rx="1"></rect>
        <rect width="2" height="2" rx="1" y="5"></rect>
        <rect width="2" height="2" rx="1" y="10"></rect>
        <rect width="2" height="2" rx="1" x="6"></rect>
        <rect width="2" height="2" rx="1" x="6" y="5"></rect>
        <rect width="2" height="2" rx="1" x="6" y="10"></rect>
      </svg>
    </div>
    <div class="d-flex">
      <div *ngFor="let config of configs">
        <div
          [ngClass]="{ 'active-bg': config.active }"
          class="widgets-button cursor-pointer"
          [tooltip]="config.hint"
          tooltipPosition="top"
          (click)="selectConfig(config)"
        >
          <ng-container [ngSwitch]="config.type">
            <ng-container *ngSwitchCase="typeConfig.OPTIONS">
              <app-dropdown
                [ngClass]="{
                  'config-selected':
                    config.selected && config.selected.labelHtml
                }"
                title=""
              >
                <a
                  type="button"
                  class="nav-link lh-sm round-top h-100"
                  appDropdownButton
                  placement="bottom-start"
                  (rtDropdownClose)="resetActiveConfigs()"
                  title=""
                >
                  <span
                    *ngIf="config.svgIcon"
                    [innerHTML]="config.svgIcon | safeHtml"
                  ></span>
                  <div
                    *ngIf="config.selected && config.selected.labelHtml"
                    class="h-100 d-flex align-items-center gap-3 px-2"
                    [innerHTML]="config.selected.labelHtml | safeHtml"
                  ></div>
                  <div *ngIf="!config.svgIcon && !config.selected?.labelHtml">
                    {{ config.selected?.title }}
                  </div>
                </a>
                <div class="contents">
                  <div *ngFor="let option of config.options">
                    <li
                      [ngClass]="{ 'pr-2 pb-2': option.canDelete }"
                      class="d-flex"
                      *ngIf="
                        option.typeValue != typeValue.DIVIVER ||
                        (option.typeValue === typeValue.DIVIVER &&
                          models.length > 0)
                      "
                      (click)="selectOption(option, config)"
                    >
                      <a
                        *ngIf="!option.labelHtml"
                        title=""
                        type="button"
                        [ngClass]="{
                          'divider p-0 mb-2':
                            option.typeValue &&
                            option.typeValue === typeValue.DIVIVER
                        }"
                        class="dropdown-item d-flex align-items-center gap-2 px-2"
                        [tooltip]="option.hint"
                        tooltipPosition="bottom"
                      >
                        {{ option.title }}
                      </a>
                      <div
                        tooltip="Remover"
                        tooltipPosition="top"
                        *ngIf="option.canDelete"
                        (click)="removeModel($event, option)"
                      >
                        <span class="fs-4 material-icons-outlined">
                          close
                        </span>
                      </div>
                      <div
                        class="d-flex align-items-center gap-3 py-2 dropdown-item withIcons cursor-pointer"
                        *ngIf="option.labelHtml"
                        [innerHTML]="option.labelHtml | safeHtml"
                      ></div>
                    </li>
                  </div>
                </div>
              </app-dropdown>
            </ng-container>

            <ng-container *ngSwitchCase="typeConfig.CLICK">
              <span
                class="d-flex flex-column align-items-center"
                *ngIf="config.svgIcon"
                [innerHTML]="config.svgIcon | safeHtml"
                (click)="toolbarClick(config)"
              ></span>
            </ng-container>

            <ng-container *ngSwitchCase="typeConfig.COLOR_PICKER">
              <app-dropdown
                [ngClass]="{
                  'config-selected':
                    config.selected && config.selected.labelHtml
                }"
                title=""
              >
                <a
                  type="button"
                  class="nav-link lh-sm round-top h-100"
                  appDropdownButton
                  placement="bottom-start"
                  (rtDropdownClose)="resetActiveConfigs()"
                  title=""
                >
                  <span
                    class="d-flex flex-column align-items-center"
                    *ngIf="config.svgIcon"
                    [innerHTML]="config.svgIcon | safeHtml"
                  ></span>
                </a>
                <div class="contents">
                  <li>
                    <app-config-colorpicker
                      (selectedColor)="selectedColor($event)"
                      [selectedTool]="selectedTool"
                      [resetTool]="resetTool"
                      [typeColorUpdate]="config.typeColorUpdate"
                      #configColorPicker
                    ></app-config-colorpicker>
                  </li>
                </div>
              </app-dropdown>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
