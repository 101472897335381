<div class="w-100 d-block bg-neutral-stronger">
  <workspace-doc-header
    [css]="'w-100 d-flex header-wrapper' + (isDesktop ? ' border-bottom' : '')"
    [item]="component"
  >
    <span
      [style.zIndex]="3"
      class="fs-5 text-brand-primary d-inline-flex align-items-center px-3 border-right h-100"
      >Aluguel</span
    >
  </workspace-doc-header>

  <ng-container *ngIf="!hideGridContent">
    <div class="d-flex flex-column">
      <div class="d-inline-flex w-100 p-1">
        <ng-container *ngFor="let tab of sortTabs; let index = index">
          <a
            type="button"
            class="nav-link p-2 lh-sm round-top"
            [ngClass]="{ 'text-brand-primary fw-bolder': tab.active }"
            (click)="handlerFieldToSort(tab, index)"
          >
            {{ tab.label }}
          </a>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
