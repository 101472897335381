<rocket-button
  type="icon"
  (rocketClick)="openOlanTradingModal()"
  [tooltip]="tooltip"
  css="mx-1"
>
  <fla-icon
    name="admin_panel_settings"
    size="icon-size-tiny"
    [css]="iconColor"
  ></fla-icon>
</rocket-button>
