import { NgModule } from '@angular/core';

import { StockTradeRoutes } from '../../../shared/components/stock-trade/stock-trade.routing';
import { StockTradeModule } from '../../../shared/components/stock-trade/stock-trade.module';

@NgModule({
  declarations: [],
  imports: [StockTradeRoutes, StockTradeModule],
})
export class StockTradeRouteModule {}
