import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlaCheckComponent } from './check.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlaIconModule } from '../icon';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';

@NgModule({
  declarations: [FlaCheckComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlaIconModule,
    TooltipsModule,
  ],
  exports: [FlaCheckComponent],
})
export class FlaCheckModule {}
