import {
  TigerChartIndicatorCdlhammerOptions,
  TTigerChartIndicatorCreateOptions,
} from './indicators.types';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { CdlhammerBase } from './base/cdlhammer-base';
import { TIGER_INDICATORS_ENUM } from '../enum';

export class TigerChartIndicatorCdlEngulfing extends CdlhammerBase {
  constructor(options: TigerChartIndicatorCdlhammerOptions) {
    super(options, TIGER_INDICATORS_ENUM.CDL_ENGULFING);
  }

  override create(
    options: TTigerChartIndicatorCreateOptions
  ): BaseRenderableSeries[] {
    this.updatePoints();
    return super.create(options);
  }

  override updatePoints(): void {
    this.points = this.service.call('CDLENGULFING', {
      open: this.data.vl_open,
      high: this.data.vl_high,
      low: this.data.vl_low,
      close: this.data.vl_close,
    });
    console.log("CDLENGULFING", this.points);
  }

  override updateSettings() {
    this.points = this.service.call('CDLENGULFING', {
      open: this.data.vl_open,
      high: this.data.vl_high,
      low: this.data.vl_low,
      close: this.data.vl_close,
    });

    super.updateSettings();
  }
}
