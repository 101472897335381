<div
  [ngClass]="options.backgroundColor"
  class="fs-5 d-flex justify-content-space-between align-items-center message-chart"
>
  <div
    *ngIf="options.showCloseButton"
    tooltip="Fechar"
    tooltipPosition="bottom"
    [tooltipIsAdaptive]="false"
  >
    <rocket-button
      css="d-flex closeMessage"
      type="default"
      [isDisabled]="false"
      label=""
      (rocketClick)="hideStockMessage()"
    >
      <fla-icon
        css="d-flex justify-content-center align-items-center"
        name="remove"
        size="icon-size-nano"
      ></fla-icon>
    </rocket-button>
  </div>
  <div class="w-100 h-100">
    <ng-container [ngSwitch]="options.typeTemplate">
      <ng-container *ngSwitchCase="templates.LEILAO">
        <auction-template
          [auctionVariation]="auctionVariation"
          [auctionPrice]="auctionPrice"
          [typeAuctionQttyNotAnswered]="typeAuctionQttyNotAnswered"
          [auctionQttyNotAnswered]="auctionQttyNotAnswered"
          [auctionQtty]="auctionQtty"
          [timer]="timer"
          [auctionMessage]="auctionMessage"
        ></auction-template>
      </ng-container>
      <ng-container *ngSwitchCase="templates.MATURITY">
        <app-maturity-template
          [stock]="stock"
          [text]="options.text"
          (goStock)="goStock()"
        ></app-maturity-template>
      </ng-container>
    </ng-container>
  </div>
</div>
