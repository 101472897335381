import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[fla-focus]',
})
export class FlaFocusDirective implements OnInit {
  @Input('fla-focus') typeElement!: string;
  constructor(private elementRef: ElementRef) {}
  ngOnInit(): void {}

  ngAfterViewInit() {
    const element = this.elementRef.nativeElement as HTMLElement;
    setTimeout(() => {
      if (element.tagName === 'INPUT') {
        element.focus();
      } else {
        const type = this.typeElement === '' ? 'input' : this.typeElement;
        const findInput = this.elementRef.nativeElement.querySelector(type);
        findInput.focus();
      }
    }, 300);
  }
}
