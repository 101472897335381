import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartLineComponent } from './line/line.component';
import { FlaIconModule } from '@shared/rocket-components/components';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';

@NgModule({
  imports: [CommonModule, FlaIconModule, TooltipsModule],
  declarations: [ChartLineComponent],
  exports: [ChartLineComponent],
})
export class HighchartModule {}
