import { ModalRiskManagementComponent } from '@shared/modals/modal-risk-management/modal-risk-management.component';

export const MODALS: any = {
  RISK_MANAGEMENT: {
    instance: ModalRiskManagementComponent,
    settings: {
      size: 'lg',
      centered: true,
      keyboard: true,
      backdrop: true,
    },
  },
};
