import {
  ICheetahCustodyPerformance,
  IPerformanceDetailsData,
  IPerformanceMarketData,
  IPerformancePercentagesData,
} from './interface/performance-report.interface';

export class PerformanceReportData {
  id_broker!: number;

  details: IPerformanceDetailsData = {
    vl_total: 0,
    liquid_profit: 0,
    gross_profit: 0,
    gross_loss: 0,
    qtty_open: 0,
    h_open_qtty: 0,
    highest_won_operation: 0,
    highest_lose_operation: 0,
    highest_win_streak: 0,
    highest_loss_streak: 0,
    h_qtty_avg_price: 0,
  };

  marketDataDetails: IPerformanceMarketData = {
    mkt_canc_order_buy: 0,
    mkt_pcanc_order_buy: 0,
    mkt_exec_order_buy: 0,
    lmt_open_order_buy: 0,
    lmt_canc_order_buy: 0,
    lmt_pcanc_order_buy: 0,
    lmt_exec_order_buy: 0,
    stop_open_order_buy: 0,
    stop_canc_order_buy: 0,
    stop_pcanc_order_buy: 0,
    stop_exec_order_buy: 0,
    stop2_open_order_buy: 0,
    stop2_canc_order_buy: 0,
    stop2_pcanc_order_buy: 0,
    stop2_exec_order_buy: 0,
    mkt_canc_order_sell: 0,
    mkt_pcanc_order_sell: 0,
    mkt_exec_order_sell: 0,
    lmt_open_order_sell: 0,
    lmt_canc_order_sell: 0,
    lmt_pcanc_order_sell: 0,
    lmt_exec_order_sell: 0,
    stop_open_order_sell: 0,
    stop_canc_order_sell: 0,
    stop_pcanc_order_sell: 0,
    stop_exec_order_sell: 0,
    stop2_open_order_sell: 0,
    stop2_canc_order_sell: 0,
    stop2_pcanc_order_sell: 0,
    stop2_exec_order_sell: 0,
    mkt_open_order_buy: 0,
    mkt_open_order_sell: 0,
  };

  operationsPercents: IPerformancePercentagesData = {
    open_operation: 0,
    open_percent: 0,
    loss_percent: 0,
    zero_percent: 0,
    loss_operation: 0,
    zero_operation: 0,
    total_operation: 0,
    won_pnl: 0,
    loss_pnl: 0,
    win_operation: 0,
    win_percent: 0,
    open_pnl: 0,
    total_percent: 0,
    liquid_profit: 0,
    profit_day_perc: 0,
    loss_day_perc: 0,
    pnl_open: 0,
  };

  constructor(idBroker?: number) {
    this.id_broker = idBroker || 0;
  }

  setValues = (values: ICheetahCustodyPerformance) => {
    if (!values) return;

    this.details.vl_total += parseFloat(values.vl_total);
    this.details.liquid_profit += parseFloat(values.liquid_profit);
    this.details.gross_profit += parseFloat(values.gross_profit);
    this.details.gross_loss += parseFloat(values.gross_loss);
    this.details.qtty_open += parseFloat(values.qtty_open);
    this.details.h_open_qtty += parseFloat(values.h_open_qtty);
    this.details.highest_won_operation += parseFloat(
      values.highest_won_operation
    );
    this.details.highest_lose_operation += parseFloat(
      values.highest_lose_operation
    );
    this.details.highest_win_streak += parseFloat(values.highest_win_streak);
    this.details.highest_loss_streak += parseFloat(values.highest_loss_streak);
    this.details.h_qtty_avg_price += parseFloat(values.h_qtty_avg_price) || 0;
    this.marketDataDetails.mkt_canc_order_buy += parseFloat(
      values.mkt_canc_order_buy
    );
    this.marketDataDetails.mkt_pcanc_order_buy += parseFloat(
      values.mkt_pcanc_order_buy
    );
    this.marketDataDetails.mkt_exec_order_buy += parseFloat(
      values.mkt_exec_order_buy
    );
    this.marketDataDetails.lmt_open_order_buy += parseFloat(
      values.lmt_open_order_buy
    );
    this.marketDataDetails.lmt_canc_order_buy += parseFloat(
      values.lmt_canc_order_buy
    );
    this.marketDataDetails.lmt_pcanc_order_buy += parseFloat(
      values.lmt_pcanc_order_buy
    );
    this.marketDataDetails.lmt_exec_order_buy += parseFloat(
      values.lmt_exec_order_buy
    );
    this.marketDataDetails.stop_open_order_buy += parseFloat(
      values.stop_open_order_buy
    );
    this.marketDataDetails.stop_canc_order_buy += parseFloat(
      values.stop_canc_order_buy
    );
    this.marketDataDetails.stop_pcanc_order_buy += parseFloat(
      values.stop_pcanc_order_buy
    );
    this.marketDataDetails.stop_exec_order_buy += parseFloat(
      values.stop_exec_order_buy
    );
    this.marketDataDetails.stop2_open_order_buy += parseFloat(
      values.stop2_open_order_buy
    );
    this.marketDataDetails.stop2_canc_order_buy += parseFloat(
      values.stop2_canc_order_buy
    );
    this.marketDataDetails.stop2_pcanc_order_buy += parseFloat(
      values.stop2_pcanc_order_buy
    );
    this.marketDataDetails.stop2_exec_order_buy += parseFloat(
      values.stop2_exec_order_buy
    );
    this.marketDataDetails.mkt_canc_order_sell += parseFloat(
      values.mkt_canc_order_sell
    );
    this.marketDataDetails.mkt_pcanc_order_sell += parseFloat(
      values.mkt_pcanc_order_sell
    );
    this.marketDataDetails.mkt_exec_order_sell += parseFloat(
      values.mkt_exec_order_sell
    );
    this.marketDataDetails.lmt_open_order_sell += parseFloat(
      values.lmt_open_order_sell
    );
    this.marketDataDetails.lmt_canc_order_sell += parseFloat(
      values.lmt_canc_order_sell
    );
    this.marketDataDetails.lmt_pcanc_order_sell += parseFloat(
      values.lmt_pcanc_order_sell
    );
    this.marketDataDetails.lmt_exec_order_sell += parseFloat(
      values.lmt_exec_order_sell
    );
    this.marketDataDetails.stop_open_order_sell += parseFloat(
      values.stop_open_order_sell
    );
    this.marketDataDetails.stop_canc_order_sell += parseFloat(
      values.stop_canc_order_sell
    );
    this.marketDataDetails.stop_pcanc_order_sell += parseFloat(
      values.stop_pcanc_order_sell
    );
    this.marketDataDetails.stop_exec_order_sell += parseFloat(
      values.stop_exec_order_sell
    );
    this.marketDataDetails.stop2_open_order_sell += parseFloat(
      values.stop2_open_order_sell
    );
    this.marketDataDetails.stop2_canc_order_sell += parseFloat(
      values.stop2_canc_order_sell
    );
    this.marketDataDetails.stop2_pcanc_order_sell += parseFloat(
      values.stop2_pcanc_order_sell
    );
    this.marketDataDetails.stop2_exec_order_sell += parseFloat(
      values.stop2_exec_order_sell
    );
    this.marketDataDetails.mkt_open_order_buy += values.mkt_open_order_buy
      ? parseFloat(values.mkt_open_order_buy)
      : this.marketDataDetails.mkt_open_order_buy;
    this.marketDataDetails.mkt_open_order_sell += values.mkt_open_order_sell
      ? parseFloat(values.mkt_open_order_sell)
      : this.marketDataDetails.mkt_open_order_sell;
    this.operationsPercents.win_operation += parseFloat(values.win_operation);
    this.operationsPercents.win_percent += parseFloat(values.win_percent);
    this.operationsPercents.loss_percent += parseFloat(values.loss_percent);
    this.operationsPercents.zero_percent += parseFloat(values.zero_percent);
    this.operationsPercents.loss_operation += parseFloat(values.loss_operation);
    this.operationsPercents.zero_operation += parseFloat(values.zero_operation);
    this.operationsPercents.total_operation += parseFloat(
      values.total_operation
    );
    this.operationsPercents.won_pnl += parseFloat(values.won_pnl);
    this.operationsPercents.loss_pnl += parseFloat(values.loss_pnl);
    this.operationsPercents.open_operation += parseFloat(values.open_operation);
    this.operationsPercents.open_percent += parseFloat(values.open_percent);
    this.operationsPercents.total_percent += parseFloat(values.total_percent);
    this.operationsPercents.liquid_profit += parseFloat(values.liquid_profit);
    this.operationsPercents.open_pnl += parseFloat(values.open_pnl);
    this.operationsPercents.profit_day_perc += parseFloat(
      values.profit_day_perc
    );
    this.operationsPercents.loss_day_perc += parseFloat(values.loss_day_perc);
    this.operationsPercents.pnl_open = this.operationsPercents.pnl_open
      ? (this.operationsPercents.pnl_open += parseFloat(values.pnl_open))
      : parseFloat(values.pnl_open);
  };
}
