import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IStockChartInfos } from '../interfaces/stock-table-views.interface';
import { ChartService } from '@shared/services/api/trademap/V3/chart.service';

@Injectable({
  providedIn: 'root',
})
export class StockTableViewsService {
  private _closeLimitObservable: Subject<IStockChartInfos> =
    new Subject<IStockChartInfos>();

  get stocksCloseLimit$(): Observable<IStockChartInfos> {
    return this._closeLimitObservable.asObservable();
  }

  constructor(private _chart: ChartService) {}

  public chartVlClose(cdStocks: string[], refComponent: string): void {
    this._chart.vlClose(cdStocks).subscribe({
      next: (params) => {
        this._closeLimitObservable.next({
          data: params.close_limit_chart,
          isError: false,
          message: '',
          refComponent,
        });
      },
      error: (error) => {
        this._closeLimitObservable.next({
          data: null,
          isError: true,
          message: error.message,
          refComponent,
        });
      },
    });
  }
}
