import { Injectable } from '@angular/core';
import { FlaToastService, TFlaToast } from '@shared/rocket-components/toast';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private readonly position = 'right-0';
  constructor(private _service: FlaToastService) {}

  public showToast(type: TFlaToast, text: string, isHtml: boolean = false) {
    this._service.show({
      type,
      verticalPosition: this.position,
      text,
      isHtml,
    });
  }
}
