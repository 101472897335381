import { BROKER_TERMS_STATUS } from '@core/interface';
import { TERMS_STATUS } from '@shared/services/api/terms/v2/interface/terms.interface';

const TERMS_TITLE = {
  PERSON: 'USER_NAME, tivemos atualizações em nossos Termos de Uso',
  BROKER: 'USER_NAME, tivemos atualizações em nossos Termos com BROKER_NAME',
};

export const getModalTermTitle = (
  type: 'PERSON' | 'BROKER',
  name: string,
  brokerName: string
): string => {
  let title = TERMS_TITLE[type];
  title = title.replace('USER_NAME', name);
  if (type === 'BROKER') title = title.replace('BROKER_NAME', brokerName);
  return title;
};

export const TERMS_SUB_TITLE = {
  PERSON:
    'Para prosseguir leia e aceite os Termos de Uso e Política de Privacidade e coloque sua senha do RocketTrader',
  BROKER:
    'Para enviar ordens leia e aceite os <b>Termos de Condições de Contratação</b> e coloque sua senha do <b>RocketTrader</b>',
};

export const isRequiredTerm = (code: TERMS_STATUS | BROKER_TERMS_STATUS) =>
  code === BROKER_TERMS_STATUS.HASNT_APPROVED ||
  code === TERMS_STATUS.SEARCH_TERM_APPROVE_STATUS_HAST_APPROVED_ERROR;
