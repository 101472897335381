<svg
  [attr.width]="width"
  [attr.height]="height"
  [class]="css"
  [attr.viewBox]="viewBox"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g
    *ngIf="!isCorretora"
    app-path-rocket
    clip-path="url(#clip0_3802_25837)"
  ></g>
  <g
    *ngIf="isCorretora"
    app-path-agora
    [withIcon]="withIcon"
    clip-path="url(#clip0_3802_25837)"
  ></g>
  <defs>
    <clipPath id="clip0_3802_25837">
      <rect width="420" height="70.7169" fill="var(--vm-neutral-smoothest)" />
    </clipPath>
  </defs>
</svg>
