<form
  class="d-flex flex-column h-100 justify-content-between"
  [formGroup]="form"
  (ngSubmit)="cancelSubmit($event)"
>
  <div *ngIf="!requestSent">
    <p class="fs-5">
      Identificamos que a sua conta está sem telefone ativo. Para configurar seu
      token, é preciso ter um telefone ativo.
    </p>
    <p class="fs-5">
      Por favor, insira um telefone no campo abaixo e clique em “Continuar”
    </p>
    <fla-input
      placeholder="Insira o seu telefone"
      label="Telefone"
      css="my-2 m-0"
      formControlName="phone"
      decidePosition="prefix"
      [focusOnInit]="true"
      [autoComplete]="false"
      [flaMask]="phoneMask"
      [isRequired]="true"
      [requiredText]="'Informe o número do seu telefone'"
      (flaKeyUp)="checkFormStatus()"
    >
      <div class="prefix"><span>+55</span></div>
    </fla-input>
    <fla-input
      placeholder="Informe a sua senha"
      label="Senha"
      css="my-2 m-0"
      formControlName="userPassword"
      decidePosition="suffix"
      [autoComplete]="false"
      [requiredText]="'Informe a sua senha de acesso'"
      [type]="showPassword ? 'text' : 'password'"
      (flaKeyUp)="checkFormStatus()"
    >
      <div class="suffix">
        <rocket-button type="icon" (rocketClick)="showPassword = !showPassword">
          <fla-icon
            [name]="showPassword ? 'visibility' : 'visibility_off'"
          ></fla-icon>
        </rocket-button>
      </div>
    </fla-input>
  </div>
  <div *ngIf="requestSent">
    <p class="fs-5">
      Informe abaixo o PIN recebido via SMS e clique em “Confirmar”
    </p>
    <fla-input
      placeholder="Insira o token"
      label="Token"
      css="my-2 m-0"
      formControlName="token"
      [focusOnInit]="true"
      [autoComplete]="false"
      [isRequired]="true"
      [requiredText]="'Token incorreto. Por favor, tentar novamente.'"
      (flaKeyUp)="checkToken()"
    >
    </fla-input>
    <rocket-button
      css="resend-button fs-6"
      type="fill"
      [label]="resendLabel"
      [isDisabled]="resendDisabled"
      (rocketClick)="sendChangeRequest()"
    ></rocket-button>
  </div>
  <div class="w-100 d-flex justify-content-end align-items-end footer px-0">
    <rocket-button
      type="fill"
      label="Anterior"
      css="mr-4"
      (rocketClick)="emitReturn()"
    ></rocket-button>
    <rocket-button
      label="Confirmar"
      [loading]="loading"
      [isDisabled]="nextButtonDisabled"
      (rocketClick)="confirm()"
    ></rocket-button>
  </div>
</form>
