import { IWorkSpaceComponet } from '@core/interface';
import { CREATE_COMPONENT_LAYOUT } from '@core/workspace/constants';

export abstract class LayoutBase {
  cod!: string;
  name!: string;
  components!: any;
  id_workspace!: string;
  stockSelected!: any;
  constructor(id_workspace?: string, stockSelected?: any) {
    id_workspace && (this.id_workspace = id_workspace);
    stockSelected && (this.stockSelected = stockSelected);
  }

  private dataAssign(component: IWorkSpaceComponet) {
    const data: IWorkSpaceComponet<any> = Object.assign({}, component);
    if (typeof component.metadata === 'object')
      data.metadata = JSON.stringify(component.metadata);
    return data;
  }

  getComponents = () =>
    this.components.map((component: any) =>
      this.dataAssign(
        CREATE_COMPONENT_LAYOUT(
          this.id_workspace,
          component,
          this.stockSelected
        )
      )
    );
}
