<div class="recomendation-bars w-100 d-flex flex-column gap-2 mt-2">
  <div
    *ngFor="let bar of recommendations; let first = first; let last = last"
    class="d-flex w-100"
  >
    <span class="fw-bold min-width-bar-text">{{ bar.text }}</span>
    <div class="bg-neutral-strong w-100 mx-3 position-relative">
      <div
        class="h-100 position-relative"
        [ngClass]="bar.class"
        [ngStyle]="{ width: bar.width, 'z-index': bar.zIndex }"
      ></div>
      <div
        class="h-100 position-absolute top-0"
        [ngStyle]="{ width: bar.secondBarWidth }"
        [ngClass]="bar.secondBarClass"
        *ngIf="bar.secondBarClass"
      ></div>
      <div
        [style.left]="'calc(' + widthClose + '% - 1px)'"
        [ngClass]="{ last: last, first: first }"
        class="dashed position-absolute d-flex justify-content-center"
        *ngIf="bar.secondBarClass"
      >
        <div
          *ngIf="last"
          class="position-absolute d-flex justify-content-center"
        >
          <span class="fw-bold">Atual: {{ formattedClose }}</span>
        </div>
      </div>
    </div>
    <span class="fw-bold min-width-bar-quantity target-price">{{
      bar.quantity
    }}</span>
  </div>
</div>
