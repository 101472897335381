export function validateCpf(cpf: string): boolean {
  // Remove caracteres não numéricos
  const cleanedCpf = cpf.replace(/\D/g, '');

  // Verifica se o CPF tem 11 dígitos
  if (cleanedCpf.length !== 11) {
    return false;
  }

  // Verifica se todos os dígitos são iguais (CPF inválido)
  if (/^(\d)\1+$/.test(cleanedCpf)) {
    return false;
  }

  // Calcula o primeiro dígito verificador
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cleanedCpf.charAt(i), 10) * (10 - i);
  }
  let digit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (parseInt(cleanedCpf.charAt(9), 10) !== digit) {
    return false;
  }

  // Calcula o segundo dígito verificador
  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cleanedCpf.charAt(i), 10) * (11 - i);
  }
  digit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (parseInt(cleanedCpf.charAt(10), 10) !== digit) {
    return false;
  }

  // CPF válido
  return true;
}

export function removeLeadingZeros(input: string): string {
  const regex = /^0+/;
  return input.replace(regex, '');
}
