import { TIGER_INDICATORS_MENU_ENUM } from '../../enum/tiger-chart.enum';
import MenuItemBase from './menu-item-base';

export default class Settings extends MenuItemBase {
  constructor() {
    super();
    this.label = 'Preferências...';
    this.id = TIGER_INDICATORS_MENU_ENUM.SETTINGS;
  }

  override onCLick(): void {}
}
