import { Injectable } from '@angular/core';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { AUTH_LOCAL_KEYS } from '../../const/auth_util.const';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';

@Injectable({
  providedIn: 'root',
})
export class ListOrdersPreferences extends CustomPreferencesService {
  get allOrders() {
    const allOrders = this.storage.getItem(AUTH_LOCAL_KEYS.ALL_ORDERS);
    const result = isNullOrUndefined(allOrders) ? null : allOrders;
    return result;
  }

  set allOrders(e: any[]) {
    this.storage.setItem(AUTH_LOCAL_KEYS.ALL_ORDERS, e);
  }

  get allHashOrders() {
    const allOrders = this.storage.getItem(AUTH_LOCAL_KEYS.ALL_HASH_ORDERS);
    const result = isNullOrUndefined(allOrders) ? null : allOrders;
    return result;
  }

  set allHashOrders(e: any) {
    this.storage.setItem(AUTH_LOCAL_KEYS.ALL_HASH_ORDERS, e);
  }
}
