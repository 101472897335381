import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  ensurePositiveNumber,
  newBigValueFormatter,
} from 'src/app/utils/utils.functions';
import * as Highcharts from 'highcharts';
import Treemap from 'highcharts/modules/treemap';
import { THeatmapConfig, TOrderBy } from '../types';
import {
  HEATMAP_ORDER_BY_SECTOR_MAPPING,
  HEATMAP_SOURCE_LABELS,
} from '../enum';
import { formatNumber } from '@angular/common';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import { DATALABEL_STYLES } from '@shared/components/heatmap/constants/heatmap.constants';
import { ColorRangeServiceService } from '@shared/services/core/color-range/color-range.service';
import {
  COLOR_RANGE_VARIATION_RANGE,
  TvariationRange,
} from '@shared/services/core/color-range/color-range.interface';
import { Subject } from 'rxjs';

Treemap(Highcharts);

@Injectable({
  providedIn: 'root',
})
export class SectorsService {
  private _onClicked = new Subject<{
    event: Highcharts.SeriesClickEventObject;
    refComponent: string;
  }>();

  get onClick() {
    return this._onClicked.asObservable();
  }

  constructor(
    private _colorRangeService: ColorRangeServiceService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  plotSectors(
    chart: Highcharts.Chart,
    stocks: any[],
    sectors: { [key: string]: string },
    refComponent: string,
    heatmapConfigs: THeatmapConfig
  ) {
    const { source, orderBy } = heatmapConfigs;
    if (!source) return;

    const variation: TvariationRange = {
      [COLOR_RANGE_VARIATION_RANGE.NEGATIVE]: 0,
      [COLOR_RANGE_VARIATION_RANGE.POSITIVE]: 0,
    };
    const hasSubsectors = source == 'BR';
    const data: Highcharts.PointOptionsType[] = [];
    const stockValuesForSectors: { [key: string]: number } = {};
    const idKey = hasSubsectors ? 2 : 1;
    const processedSectors = hasSubsectors ? sectors : {};
    const sectorStocks: any = [];
    const isVariant = orderBy === 'variation';
    let total = 0;
    const parentAcm: any = {};

    for (let index = 0, len = stocks.length; index < len; index++) {
      const parent = stocks[index][idKey];
      if (!parent || (parentAcm[parent] && parentAcm[parent] > 49)) continue;

      if (!parentAcm[parent]) parentAcm[parent] = 1;
      else parentAcm[parent] = parentAcm[parent] + 1;

      const value = this._getStockValueForSectors(stocks[index], orderBy);
      total = total + value;

      if (+stocks[index][6] > variation[COLOR_RANGE_VARIATION_RANGE.POSITIVE])
        variation[COLOR_RANGE_VARIATION_RANGE.POSITIVE] = +stocks[index][6];
      if (+stocks[index][6] < variation[COLOR_RANGE_VARIATION_RANGE.NEGATIVE])
        variation[COLOR_RANGE_VARIATION_RANGE.NEGATIVE] = +stocks[index][6];

      const previousValue = stockValuesForSectors[parent] || 0;
      stockValuesForSectors[parent] = previousValue + value;

      if (!hasSubsectors) processedSectors[parent] = parent;

      const stockValues = {
        cd_stock: stocks[index][0],
        volume: value,
        variacao_dia: stocks[index][6],
      };

      if (sectorStocks[parent]) sectorStocks[parent].push(stockValues);
      else sectorStocks[parent] = [stockValues];
      const label = this._dataLabelValue(
        isVariant,
        stocks[index][6],
        value,
        this.locale
      );
      const labelHTML = this._buildDataLabel(
        stocks[index][0],
        label,
        stocks[index][6],
        this.locale
      );

      data.push({
        value: value ? value : 0.001,
        custom: {
          cd_stock: stocks[index][0],
          volume: value,
          variacao_dia: stocks[index][6],
        },
        parent: `ID_PARENT_${parent}`,
        id: `${parent}-${stocks[index][0]}`,
        name: stocks[index][0],
        color: this._colorRangeService.getColor(+stocks[index][6], variation),
        events: {
          click: (event: Highcharts.SeriesClickEventObject) =>
            this._onClicked.next({ event, refComponent }),
        },
        dataLabels: {
          enabled: true,
          useHTML: true,
          allowOverlap: false,
          crop: false,
          align: 'center',
          verticalAlign: 'middle',
          style: DATALABEL_STYLES,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          formatter(_: any) {
            const { height } = this.point.shapeArgs as Highcharts.SVGAttributes;
            if (height > 70) return labelHTML;
            return stocks[index][0];
          },
        },
      });
    }
    const sectorKeys = Object.keys(processedSectors);
    const minimunValue = total / sectorKeys.length;
    for (let index = 0, len = sectorKeys.length; index < len; index++) {
      data.unshift({
        name: processedSectors[sectorKeys[index]],
        id: `ID_PARENT_${sectorKeys[index]}`,
        value: this._getSectorValue(
          stockValuesForSectors[sectorKeys[index]],
          minimunValue
        ),
        dataLabels: {
          allowOverlap: false,
          inside: false,
          align: 'center',
          crop: false,
          verticalAlign: 'top',
          backgroundColor: CHART_COLORS.NEUTRAL_STRONG,
          color: CHART_COLORS.NEUTRAL_SMOOTHEST,
        },
        custom: sectorStocks[sectorKeys[index]],
      });
    }

    chart.update(
      {
        chart: {
          zooming: {
            type: undefined,
          },
        },
      },
      false,
      false,
      false
    );
    chart.series[0].update(
      {
        animation: false,
        type: 'treemap',
        allowTraversingTree: true,
        levelIsConstant: true,
        name: HEATMAP_SOURCE_LABELS[source],
        breadcrumbs: { events: { click: () => null } },
        levels: [
          {
            level: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
          },
          {
            level: 1,
            layoutAlgorithm: 'strip',
            borderWidth: 4,
            dataLabels: {
              zIndex: 10,
              formatter(options: Highcharts.DataLabelsOptions) {
                const width = `width:${options.style?.width || 30}px`;
                return `
              <div style="${width}; height: ${options.style?.height}px">
                <span class="fs-7 text-truncate">${this.point.name}</span>
                <i class="icons icon-size-nano">chevron_right</i>
              </div>`;
              },
              className: 'heatmap-level-label',
              padding: 0,
              align: 'left',
              verticalAlign: 'top',
              backgroundColor: CHART_COLORS.NEUTRAL_STRONGEST,
              color: CHART_COLORS.NEUTRAL_SMOOTHEST,
              enabled: true,
              useHTML: true,
            },
          },
          {
            level: 2,
            borderWidth: 1,
            layoutAlgorithm: 'squarified',
          },
        ],
        data,
      },
      true
    );
    chart.hideLoading();
  }

  private _getStockValueForSectors(stock: [][], orderBy?: TOrderBy): number {
    if (orderBy)
      return orderBy == 'variation'
        ? ensurePositiveNumber(+stock[6])
        : +stock[HEATMAP_ORDER_BY_SECTOR_MAPPING[orderBy]];

    return +stock[10];
  }

  private _getSectorValue(value: number, minimunValue: number) {
    if (!value) return 0.001;

    return value < minimunValue ? value + minimunValue / 2 : value;
  }

  private _dataLabelValue(
    isVariant: boolean,
    stockValue: number,
    sectorValue: number,
    locale: string
  ): string {
    if (isVariant) return formatNumber(stockValue, locale, `1.2-2`) + '%';
    return newBigValueFormatter(sectorValue);
  }

  private _buildDataLabel(
    cdStock: string,
    labelValue: string,
    percentageValue: number,
    locale: string
  ): string {
    return `
    <div class="mt-2 vstack align-items-center justify-content-center" style="width: 30px; height: 35px">
      <span class="fs-7">${cdStock}</span>
      <span class="fs-7">${labelValue}</span>
      <span class="fs-7">
      ${formatNumber(percentageValue, locale, `1.2-2`)}%
      </span>
    </div>
    `;
  }
}
