import { TIGER_INDICATORS_MENU_ENUM } from '../../enum/tiger-chart.enum';
import FixInScaleFullscreen from './fix-in-scale-fullscreen';
import FixInScalePinA from './fix-in-scale-pin-a';
import FixInScalePinLeft from './fix-in-scale-pin-left';
import FixInScalePinRight from './fix-in-scale-pin-right';
import FixInScalePinZ from './fix-in-scale-pin-z';
import MenuItemBase from './menu-item-base';

export default class FixInScale extends MenuItemBase {
  constructor() {
    super();
    this.label = 'Fixar na Escala';
    this.id = TIGER_INDICATORS_MENU_ENUM.FIX_IN_SCALE;
    this.divisor = true;
    this.submenu = [
      new FixInScalePinA(),
      new FixInScalePinRight(),
      new FixInScalePinZ(),
      new FixInScalePinLeft(),
      new FixInScaleFullscreen(),
    ];
  }

  override onCLick(): void {}
}
