import { inject, Injectable } from '@angular/core';
import { IAccountSelect, IOrders, ISearchStock } from '@core/interface';
import {
  TYPE_ORDE_ENUM,
  TYPE_VALIDADE_ENUM,
} from '@shared/components/stock-trade/enum/stock-trade.enum';
import {
  ORDER_CONFIRM_ACTIONS_ENUM,
  TYPE_ORDE_SIDE_ENUM,
  TYPE_ORDE_SIDE_STRING_ENUM,
} from '@shared/enum/buyOrSell.enum';
import { TFlaToast } from '@shared/rocket-components/toast';
import { OrsGatewayServiceV2 } from './api/trademap/V2/ors-gateway.service';
import { ToastService } from './toast.service';
import { ModalAvisoComponent } from '@shared/components/modal-aviso/modal-aviso.component';
import { CustomPreferencesService } from './api/nitro-ws/v1/custom-preferences.service';
import { RocketModalService } from '@shared/rocket-components/components';
import { OrsGatewayService } from './api/trademap/v1/ors-gateway.service';
import {
  calculatePriceOrderByOffset,
  removeNullInObject,
  groupBy,
} from 'src/app/utils/utils.functions';
import {
  MESSAGE_MODAL_CANCEL_ORDER,
  isTypeStockIndex,
} from '@shared/constants/general.contant';
import { OrderTokenService } from '@shared/rocket-components/modal-order-token/order-token.service';
import { CancelOrderModalComponent } from '@shared/modals/cancel-order-modal/cancel-order-modal.component';
import { ListOrdersService } from './core/list-orders/orders.service';
import { OrderModel } from '@core/models';
import { GlobalSelectedStockSubscription } from './core/subscription/global-stock.subscription';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { ConfigService } from '@core/config';
import { SharedWorkerEventsService } from '@shared/services/core/shared-worker-events/shared-worker-events.service';
import { SharedWorkerEventsActions } from '@shared/services/core/shared-worker-events/shared-worker-events.constants';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  private snapshot$ = new ReplaySubject<any[]>();
  private orderCountChannel$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private cancelOrdesByRestPosition$ = new Subject<any>();

  get cancelOrdesByRestPosition() {
    return this.cancelOrdesByRestPosition$.asObservable();
  }

  configService = inject(ConfigService);

  get ordersHistoryCardView() {
    return this._customPreferencesService.ordersHistoryCardView || false;
  }

  set ordersHistoryCardView(value: boolean) {
    this._customPreferencesService.ordersHistoryCardView = value;
  }

  constructor(
    private _orsGatewayService: OrsGatewayService,
    private _orsGatewayServiceV2: OrsGatewayServiceV2,
    private toastService: ToastService,
    private _customPreferencesService: CustomPreferencesService,
    private _rocketModalService: RocketModalService,
    private _orderToken: OrderTokenService,
    private _listOrdersService: ListOrdersService,
    private _globalStock: GlobalSelectedStockSubscription,
    private _dayTradeService: DaytradeService,
    private _sharedWorkerEventService: SharedWorkerEventsService
  ) {}

  public prepareOrder(
    stock: ISearchStock,
    orderValues: any,
    account: IAccountSelect,
    sideOrder: TYPE_ORDE_SIDE_ENUM,
    typeEvent: number,
    configOffset?: { offset: number; minPriceIncrement: number }
  ): void {
    if (isTypeStockIndex(stock.type)) {
      this.toastShow('Não é possível enviar ordens para índices.');
      return;
    }
    if (!account.id_broker || account.id_broker == -99) {
      this.toastShow('Selecione uma corretora.');
      return;
    }
    let order: any = {
      order_qty: orderValues['qtd'] || stock.standard_lot,
      validade: TYPE_VALIDADE_ENUM.TODAY,
      isFastOrder: orderValues['isFastOrder'],
      strategy: orderValues['isSelectedStrategy']
        ? orderValues['strategy']
        : undefined,
    };
    switch (typeEvent) {
      case TYPE_ORDE_ENUM.LIMITADA:
        order = {
          ...order,
          price: orderValues['price'],
          ord_type: TYPE_ORDE_ENUM.LIMITADA,
        };
        break;
      case TYPE_ORDE_ENUM.MARKET:
        order = { ...order, ord_type: TYPE_ORDE_ENUM.MARKET };
        break;
      case TYPE_ORDE_ENUM.START_STOP:
        order = {
          ...order,
          price: orderValues['price'],
          stopPx: orderValues['price'],
          ord_type: TYPE_ORDE_ENUM.START_STOP,
        };
        if (configOffset && configOffset.offset > 0) {
          order.price = calculatePriceOrderByOffset({
            price: order.price,
            minPriceIncrement: configOffset.minPriceIncrement,
            offset: configOffset.offset,
            side: sideOrder,
          });
        }
        break;
    }
    this._orsGatewayServiceV2
      .sendNewOrderSingle(stock, order!, account, sideOrder)
      .subscribe();
  }

  public toastShow(text: string, type: TFlaToast = 'warning'): void {
    this.toastService.showToast(type, text);
  }

  public handlePositionAction(
    account: IAccountSelect,
    stock: ISearchStock,
    position: any,
    action: ORDER_CONFIRM_ACTIONS_ENUM,
    askAgain: boolean = true,
    isResetAllPosition: boolean = false
  ) {
    if (isResetAllPosition) {
      this.resetOrReversePostition(action, account, stock, position);
      return;
    }
    if (!account?.id_broker || !stock?.cd_stock_order) {
      return;
    }

    if (!position) {
      position = { qtd: null };
    }

    const verifyToken = this._orderToken.hasTradingToken(account?.id_broker);
    if (!verifyToken.hasTrading) {
      this._orderToken.showTokenModal(() =>
        setTimeout(() => {
          this.handlePositionAction(account, stock, position, action, false);
        }, 100)
      );
      return;
    }

    if (
      askAgain &&
      this._skipConfirmattionModal(account, stock, position, action)
    )
      return;
    this._displayModalToConfirmAction(
      account,
      stock,
      position,
      action,
      askAgain
    );
  }

  private _skipConfirmattionModal(
    account: IAccountSelect,
    stock: ISearchStock,
    position: any,
    action: ORDER_CONFIRM_ACTIONS_ENUM
  ): boolean {
    const { reset, invert } =
      this._customPreferencesService.confirmattionInvertAndResetPosition;

    if (
      (!reset && !invert) ||
      (action === ORDER_CONFIRM_ACTIONS_ENUM.INVERT && !invert) ||
      (action === ORDER_CONFIRM_ACTIONS_ENUM.RESET && !reset)
    ) {
      this.resetOrReversePostition(action, account, stock, position);
      return true;
    }

    return false;
  }

  private resetOrReversePostition(
    action: ORDER_CONFIRM_ACTIONS_ENUM,
    account: IAccountSelect,
    stock: ISearchStock,
    position: any
  ): void {
    if (!account.id_broker || account.id_broker == -99) {
      this.toastShow('Selecione uma corretora.');
      return;
    }

    const order = {
      order_qty: position['qtd'] || stock.standard_lot,
      validade: TYPE_VALIDADE_ENUM.TODAY,
      ord_type:
        action === ORDER_CONFIRM_ACTIONS_ENUM.RESET
          ? TYPE_ORDE_ENUM.RESET_POSITION
          : TYPE_ORDE_ENUM.INVERT_POSITION,
      isFastOrder: position['isFastOrder'],
    };
    action === ORDER_CONFIRM_ACTIONS_ENUM.RESET &&
      this.cancelOrdesByRestPosition$.next(position);

    this._orsGatewayServiceV2
      .sendNewOrderSingle(stock, order!, account, TYPE_ORDE_SIDE_ENUM.SELL)
      .subscribe();
  }

  private _displayModalToConfirmAction(
    account: IAccountSelect,
    stock: ISearchStock,
    position: any,
    action: ORDER_CONFIRM_ACTIONS_ENUM,
    askAgain?: boolean
  ) {
    const ref = this._rocketModalService.open(ModalAvisoComponent, {
      keyboard: false,
      data: {
        title: 'Aviso',
        text: `Tem certeza que deseja ${
          action === ORDER_CONFIRM_ACTIONS_ENUM.RESET ? 'zerar' : 'inverter'
        } a sua posição de ${stock?.cd_stock_order}?`,
        confirmeButton: 'Sim',
        cancelButton: 'Não',
        typeAskAgain: 'Não perguntar novamente',
        askAgain: askAgain,
        showButtons: true,
        isSecondModal: false,
        positionActionEnum: action,
        stock: undefined,
        showPriceStock: false,
      },
    });

    const modalSub$ = ref.afterDismissed.subscribe((res) => {
      if (res && res.value) {
        this.resetOrReversePostition(action, account, stock, position);
      }
      modalSub$.unsubscribe();
    });
  }

  public cancelOrders(
    orders: IOrders[],
    orderOperationType?: TYPE_ORDE_SIDE_STRING_ENUM
  ): void {
    if (!orders || orders.length === 0) {
      this.toastShow('Você não tem ordens para este ativo ou corretora!');
      return;
    }
    this.checkTokenCancelOrders(orders, orderOperationType);
  }

  private checkTokenCancelOrders(
    orders: IOrders[],
    orderOperationType?: TYPE_ORDE_SIDE_STRING_ENUM
  ): void {
    const idBroker = orders[0].id_broker;
    const cdStock = orders[0].cd_stock;
    let action = MESSAGE_MODAL_CANCEL_ORDER.ONLY_ORDER;
    if (orders?.length > 1) {
      action = MESSAGE_MODAL_CANCEL_ORDER.ALL_ORDERS;
      let isUnicStock = true;
      orders.forEach((item: any) => {
        cdStock !== item.cd_stock && (isUnicStock = false);
      });
      isUnicStock && (action = MESSAGE_MODAL_CANCEL_ORDER.ALL_ORDERS_BY_STOCK);
    }
    const verifyToken = this._orderToken.hasTradingToken(idBroker);
    if (!verifyToken.hasTrading) {
      this._orderToken.showTokenModal(() =>
        setTimeout(() => {
          this.confirmationSendOrder(
            orders,
            action,
            true,
            cdStock,
            orderOperationType
          );
        }, 100)
      );
      return;
    }
    if (this._customPreferencesService.confirmationCancelOrder) {
      this.confirmationSendOrder(
        orders,
        action,
        false,
        cdStock,
        orderOperationType
      );
    } else {
      orders
        .filter((order) => {
          if (orderOperationType) {
            return order.side === orderOperationType;
          }
          return true;
        })
        .forEach((item) => {
          this.cancelOrder(item);
        });
    }
  }

  private confirmationSendOrder(
    orders: IOrders[],
    action: string,
    dontAskAgain: boolean,
    cdStock: string,
    orderOperationType?: TYPE_ORDE_SIDE_STRING_ENUM
  ): void {
    const ref = this._rocketModalService.open(CancelOrderModalComponent, {
      centered: true,
      keyboard: false,
      data: { action: action, dontAskAgain: dontAskAgain, cdStock: cdStock },
    });
    ref.afterDismissed.subscribe((data: any) => {
      if (data.confimation) {
        orders
          .filter((order: any) => {
            if (orderOperationType) {
              return order.side === orderOperationType;
            }
            return true;
          })
          .forEach((item: any) => {
            this.cancelOrder(item);
          });
      }
    });
  }

  private cancelOrder(orders: IOrders): void {
    this._orsGatewayService
      .setOrderCancelRequest(orders.id_order, orders.id_broker)
      .subscribe((res) => {
        if (res && !res.success) {
          this.toastShow(res.message);
        }
      });
  }

  getAllOrdersPaged(idBroker: number) {
    return this._orsGatewayService.getAllOrdersPagedCached(idBroker).pipe(
      tap((data: any) => {
        const orders = data.orders;
        for (let index = 0, len = orders.length; index < len; index++) {
          const model: any = orders[index];
          model.has_day_trade = model.is_day_trade;
          model.has_swing = !model.is_day_trade;

          const order = removeNullInObject(
            new OrderModel(
              model,
              this.configService.invertStopBroker(model.side)
            )
          );
          model.keyFilter = `${model.id_broker}_${model.cd_stock}`;
          order.filterStockIdBrokerStatus = `${model.cd_stock}_${model.tp_status}`;
          order.filterStockIdBrokerStatusIsDayTrade = `${model.cd_stock}_${model.tp_status}_${model.is_day_trade}`;
          order.filterStockIdBrokerIsDayTrade = `${model.cd_stock}_${model.is_day_trade}`;
          if (!this._listOrdersService.retrieveOrdersDict().has(order.id_order))
            this._sharedWorkerEventService.emitSharedWorkerEvent(
              SharedWorkerEventsActions.ORDERS,
              order
            );
          this._listOrdersService.setValueOrdersDict(order.id_order, order);
        }
        this.snapshot$.next(
          this._listOrdersService.retrieveOrdersDict().values()
        );
        this._sharedWorkerEventService.emitSharedWorkerEvent(
          SharedWorkerEventsActions.ORDERS_COUNT
        );
        return this._listOrdersService.retrieveOrdersDict().values();
      }),
      switchMap((data) => of(data))
    );
  }

  processOrderFromCheetah(order: any) {
    if (!order) return;
    const model = removeNullInObject(
      new OrderModel(order, this.configService.invertStopBroker(order.side))
    );
    if (model.ds_order_type == 'Stop Duplo') {
      if (this._listOrdersService.retrieveOrdersDict().has(model.id_order)) {
        const temp: any = this._listOrdersService
          .retrieveOrdersDict()
          .get(model.id_order);
        model.personalized_gain_trigger =
          model.personalized_gain_trigger ||
          temp.personalized_gain_trigger ||
          null;
        model.personalized_loss_trigger =
          model.personalized_loss_trigger ||
          temp.personalized_loss_trigger ||
          null;
      }
    }

    const globalStock = this._globalStock.getGlobalStockSelected();
    if (
      model.cd_stock == globalStock.cd_stock_order &&
      model.id_exchange == globalStock.id_exchange
    ) {
      model.tick_size_denominator = globalStock.tick_size_denominator;
    }
    model.keyFilter = `${model.id_broker}_${model.cd_stock}`;
    model.filterStockIdBrokerStatus = `${model.cd_stock}_${model.tp_status}`;
    model.filterStockIdBrokerStatusIsDayTrade = `${model.cd_stock}_${model.tp_status}_${model.is_day_trade}`;
    model.filterStockIdBrokerIsDayTrade = `${model.cd_stock}_${model.is_day_trade}`;
    model.is_day_trade && !model.has_day_trade && (model.has_day_trade = true);
    this._listOrdersService.setValueOrdersDict(model.id_order, model);
    this._sharedWorkerEventService.emitSharedWorkerEvent(
      SharedWorkerEventsActions.ORDERS,
      model
    );
    this._sharedWorkerEventService.emitSharedWorkerEvent(
      SharedWorkerEventsActions.ORDERS_COUNT
    );
    return model;
  }

  orderChannelFilter = (
    stock: ISearchStock,
    account: IAccountSelect,
    order: IOrders
  ) => {
    const isSameBroker = account?.id_broker === order?.id_broker;
    const isSameStock = stock?.cd_stock_order === order?.cd_stock;
    const isDayTrade =
      this._dayTradeService.useDayTradeMode === order?.is_day_trade;
    return !!order && isSameBroker && isSameStock && isDayTrade;
  };

  emitTotalRecount = () =>
    this._sharedWorkerEventService.emitSharedWorkerEvent(
      SharedWorkerEventsActions.ORDERS_COUNT
    );

  onOrdersCount = (keyFilter: string = 'id_broker') =>
    this._sharedWorkerEventService
      .listenSelectedAction(SharedWorkerEventsActions.ORDERS_COUNT, true, {})
      .pipe(
        map(() => {
          const data = this._listOrdersService.retrieveOrdersDict().values();
          return groupBy(data, (dt: any) => dt[keyFilter]);
        })
      );

  getOrdersStream = () =>
    this._sharedWorkerEventService
      .listenSelectedAction(SharedWorkerEventsActions.ORDERS)
      .pipe(map((payload: any) => payload.data));

  getOrdersCached = () => {
    this._listOrdersService
      .retrieveOrdersDict()
      .values()
      .forEach((item: OrderModel) =>
        this._sharedWorkerEventService.emitSharedWorkerEvent(
          SharedWorkerEventsActions.ORDERS,
          item
        )
      );
  };

  getSnapshot(): ReplaySubject<any[]> {
    return this.snapshot$;
  }

  filterOrders(field: string, values: string) {
    return [...this._listOrdersService.retrieveOrdersDict().values()].filter(
      (item: any) => item[field] === values
    );
  }
}
