<section
  *ngIf="dataTable"
  class="d-flex p-2 h-100 align-items-end justify-content-center position-absolute w-100"
>
  <section
    class="d-flex align-items-end justify-content-center h-100"
    [style.minWidth.%]="42"
  >
    <div
      class="h-100 w-25 d-flex flex-column justify-content-end ml-1"
      [style.width.px]="50"
      *ngFor="let item of dataTable?.buy"
    >
      <div class="opacity-0 bg-neutral-smooth text-center">
        <small class="fs-7 fw-bold text-dark">
          {{ item.qttyBuy }}
        </small>
      </div>
      <div class="position-relative h-100 d-flex align-items-end">
        <div class="bg-multibroker-buy w-100" [style.height.%]="item.uniqueBar">
          <div
            class="repos-bottom position-relative bg-neutral-smooth text-center"
          >
            <small class="fs-7 fw-bold text-dark">
              {{ item.qttyBuy }}
            </small>
          </div>
        </div>
      </div>
      <div class="bg-neutral-medium text-center">
        <small class="fs-7 fw-bolder">
          {{ item.priceBuy }}
        </small>
      </div>
    </div>
  </section>

  <div
    *ngIf="dataResume"
    class="mt-auto text-center mx-2 text-white"
    [style.minWidth.%]="16"
  >
    <p class="fs-7 fw-bold">{{ cdStock }}</p>
    <p class="fs-7 fw-bold">
      {{ lastPriceFormat() }} | {{ dataResume.variacao_dia_formatted }}%
    </p>
    <p class="fs-7 fw-bold">{{ volume }}</p>
  </div>

  <section
    class="d-flex align-items-end justify-content-center h-100"
    [style.minWidth.%]="42"
  >
    <div
      class="h-100 w-25 d-flex flex-column justify-content-end mr-1"
      [style.width.px]="50"
      *ngFor="let item of dataTable?.sell"
    >
      <div class="opacity-0 bg-neutral-smooth text-center">
        <small class="fs-7 fw-bold text-dark">
          {{ item.qttySell }}
        </small>
      </div>
      <div class="position-relative h-100 d-flex align-items-end">
        <div
          class="bg-multibroker-sell w-100"
          [style.height.%]="item.uniqueBar"
        >
          <div
            class="repos-bottom position-relative bg-neutral-smooth text-center"
          >
            <small class="fs-7 fw-bold text-dark">
              {{ item.qttySell }}
            </small>
          </div>
        </div>
      </div>
      <div class="bg-neutral-medium text-center">
        <small class="fs-7 fw-bolder">
          {{ item.priceSell }}
        </small>
      </div>
    </div>
  </section>
</section>
