import { ComponentRef, inject, Injectable, Type } from '@angular/core';
import { RocketModalConfig } from '@shared/rocket-components/components/modal/config/modal.config';
import { RocketIModal } from '@shared/rocket-components/components/modal/interfaces/modal.interface';
// @ts-expect-error omnitheme import
import { Modal } from '@omni/omnitheme';
import { DOCUMENT } from '@angular/common';
import { ROCKET_MODAL_DATA } from '@shared/rocket-components/components';

import {
  ViewRefReturn,
  RocketCreateComponentService,
} from '@shared/rocket-components/services';
import { SuperSearchService } from '@modules/home/super-search.service';
import { ModalOpenService } from './modal-open.service';

@Injectable()
export class RocketModalService {
  get isDisplayingSomeModal(): boolean {
    return this.element !== undefined;
  }

  private element: HTMLElement | undefined = undefined;
  private element2: HTMLElement | undefined = undefined;
  private modal: Modal;
  private modal2!: Modal;
  private document = inject(DOCUMENT);
  public data = inject(ROCKET_MODAL_DATA);
  private data2 = inject(ROCKET_MODAL_DATA);
  private configs!: RocketIModal;
  private configs2!: RocketIModal;
  private toggleBetweenModal: HTMLElement | null = null;
  public outsideEvent!: () => void;
  private arrayComponentRef: ComponentRef<any>[] = [];
  private arrayComponentRef2: ComponentRef<any>[] = [];
  constructor(
    private service: RocketCreateComponentService,
    private _superSearchService: SuperSearchService,
    private modalOpenService: ModalOpenService
  ) {}

  open<T>(component: Type<T>, settings?: RocketIModal): ViewRefReturn {
    if (settings?.toggleBetweenModal) {
      settings.backdrop = false;
      this.toggleBetweenModal = settings.toggleBetweenModal
        .nativeElement as HTMLElement;
      this.toggleBetweenModal.classList.add('d-none');
      return this.openSecondModal(component, settings);
    }
    const create = this.service.create(component);
    (create.componentRef.instance as any).standaloneClose = this.close;
    this.arrayComponentRef.push(create.componentRef);
    this.element = create.rootNodeElement.firstChild?.firstChild as HTMLElement;
    this.modalOpenService.dispatchDisplaying(true);
    this.configs = new RocketModalConfig(this.element!, settings).modalConfig;
    Object.assign(this.data, settings?.data);
    this.configs.appendTo!.append(create.rootNodeElement);
    if (this.configs.lockScreen) {
      this._superSearchService.isScreenLocked = true;
    }
    setTimeout(() => {
      this.modal = new Modal(this.element, {
        ...this.configs,
        element: this.configs.appendTo,
      });
      this.modal.show();
      this.closedEvent(this.element!);
      this.configs?.keyboard && this.listenForEscapeKey();
      this.configs?.backdrop &&
        this.configs?.backdrop !== 'static' &&
        this.clickOutsideEvent();
    }, 100);
    return {
      afterDismissed: (
        this.arrayComponentRef[this.arrayComponentRef.length - 1]!
          .instance as any
      ).afterClosed(),
    };
    // return { afterDismissed: (componentRef!.instance as any).afterClosed() };
  }

  close = (data?: any, isSecondModal = false): void => {
    document.removeEventListener('keyup', this.handleKeyUp);
    document.removeEventListener('click', this.outsideClickListener);
    const componentRef2 =
      this.arrayComponentRef2[this.arrayComponentRef2.length - 1];
    if (isSecondModal) {
      componentRef2?.instance.onClosed.next(data ?? { closed: true });
      this.modal2.hide();
      this.element2?.remove();
      this.element2 = undefined;
      this.service.destroy(componentRef2!);
      this.toggleBetweenModal?.classList.remove('d-none');
      this.arrayComponentRef2.pop();
      return;
    } else {
      this.arrayComponentRef[
        this.arrayComponentRef.length - 1
      ]?.instance.onClosed.next(data ?? { closed: true });
      this.modal.hide();
      this.element?.remove();
      this.element = undefined;
      this.modalOpenService.dispatchDisplaying(false);
      this.service.destroy(
        this.arrayComponentRef[this.arrayComponentRef.length - 1]!
      );
      this.arrayComponentRef.pop();
    }
    if (this.configs?.lockScreen || this.configs2?.lockScreen) {
      this._superSearchService.isScreenLocked = false;
    }

    const modal = document.getElementsByClassName('modal-backdrop');
    /*Array.from(modal).forEach((item: any) => {
      item.remove();
    });*/
    modal[modal.length - 1]?.remove();
  };

  private closedEvent(element: HTMLElement): void {
    const closeBtn = element?.querySelector('[data-bs-dismiss="modal"]');
    closeBtn?.addEventListener('click', () => this.close());
  }

  private handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      const modals = (event.currentTarget as any).querySelectorAll(
        'rocket-modal'
      );
      const child = modals[modals.length - 1].children[0];
      if (child.id === this.modal._element.id) {
        this.close();
      }
    }
  };

  private listenForEscapeKey() {
    document.addEventListener('keyup', this.handleKeyUp);
  }

  private outsideClickListener = (event: MouseEvent) => {
    if (this.element?.id === (event.target as HTMLElement).id) {
      //caso necessite utilizar um callback especifico
      if (this.outsideEvent) {
        this.outsideEvent();
        return;
      }
      this.close();
    }
  };

  private clickOutsideEvent(): void {
    // Não está reconhecendo os botoes do body como items internos do modal
    document.addEventListener('click', this.outsideClickListener);
  }

  private openSecondModal(
    component: Type<any>,
    settings?: RocketIModal
  ): ViewRefReturn {
    const create = this.service.create(component);
    const componentRef2 = create.componentRef;
    this.arrayComponentRef2.push(componentRef2);
    this.element2 = create.rootNodeElement.firstChild
      ?.firstChild as HTMLElement;

    this.configs2 = new RocketModalConfig(this.element2!, settings).modalConfig;

    Object.assign(this.data2, settings?.data);

    this.document.body.append(create.rootNodeElement);

    this.modal2 = new Modal(this.element2, this.configs2);

    this.modal2.show();
    this.closedEvent(this.element2);

    return { afterDismissed: componentRef2!.instance.afterClosed() };
  }

  public resetDataInfos(): void {
    try {
      this.data = inject(ROCKET_MODAL_DATA);
    } catch (err) {
      // ignore log
    }
  }

  dismissAll() {
    const modals = this.document.getElementsByTagName('rocket-modal');
    while (modals.length) {
      const parent = modals.item(0)?.parentElement;
      if (parent) {
        parent.remove();
      }
    }
  }
}
