<div [id]="idGrid" class="h-100">
  <ag-grid-angular
    #grid
    [style.width]="width"
    [style.height]="height"
    [class]="theme"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [animateRows]="animateRows"
    [cellFlashDelay]="cellFlashDelay"
    [cellFadeDelay]="cellFadeDelay"
    [rowDragManaged]="rowDragManaged"
    [rowDragEntireRow]="rowDragEntireRow"
    [rowDragText]="rowDragText"
    [suppressDragLeaveHidesColumns]="suppressDragLeaveHidesColumns"
    [suppressMoveWhenRowDragging]="suppressMoveWhenRowDragging"
    [navigateToNextCell]="navigateToNextCell"
    [rowSelection]="rowSelection"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [getRowId]="getRowId"
    [debug]="isDebug"
    [gridOptions]="gridOptions"
    [tooltipShowDelay]="tooltipShowDelay"
    [tooltipHideDelay]="tooltipHideDelay"
    [sortingOrder]="sortingOrder"
    [rowBuffer]="rowBuffer"
    [domLayout]="domLayout"
    [rowClassRules]="rowClassRules"
    [pinnedBottomRowData]="pinnedBottomRowData"
    [suppressChangeDetection]="suppressChangeDetection"
    [aggregateOnlyChangedColumns]="aggregateOnlyChangedColumns"
    (cellClicked)="onRowSelected($event)"
    (rowDragEnd)="rowMoved($event)"
    (columnResized)="onColumnResized($event)"
    (columnMoved)="columnMoved($event)"
    (gridReady)="onGridReadyHandler($event)"
    (sortChanged)="onGridSortChange($event)"
    (filterChanged)="onGridFilterChange($event)"
    (bodyScrollEnd)="triggerBodyScroll($event)"
  >
  </ag-grid-angular>
</div>
