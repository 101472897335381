import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { TFooterType } from './tiger-chart-footer.enum';
import {
  CHART_ALTER_SCALE_CONFIGS,
  STOCK_CHART_ELEMENT_IDS,
} from '@shared/components/stock-chart/enum/stock-chart.enum';
import { ICandleConfigs } from '../interface';
import { StockChartModalService } from '@shared/components/stock-chart/parts/modal-more-options/service/stock-chart-modal.service';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { filter, Subject, takeUntil } from 'rxjs';

interface clickDefaultInfo {
  value: any;
  save: boolean;
}

@Component({
  selector: 'app-tiger-chart-footer',
  templateUrl: './tiger-chart-footer.component.html',
  styleUrls: ['./tiger-chart-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TigerChartFooterComponent implements OnDestroy {
  log: boolean = false;
  freeScale: boolean = false;
  fastRuler: boolean = false;
  chartBook: boolean = false;
  chartVolumeAtPrice: boolean = false;
  fastOrder: boolean = false;
  zoomIn: boolean = false;
  volume: boolean = true;
  events: boolean = true;
  execOrders: boolean = false;
  previousClose: boolean = false;
  bidAsk: boolean = false;
  @Input() displayFastRuler: boolean = true;
  rulerSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 28 28" width="20" height="20">
    <path fill="currentColor" d="M2 9.75a1.5 1.5 0 0 0-1.5 1.5v5.5a1.5 1.5 0 0 0 1.5 1.5h24a1.5 1.5 0 0 0 1.5-1.5v-5.5a1.5 1.5 0 0 0-1.5-1.5zm0 1h3v2.5h1v-2.5h3.25v3.9h1v-3.9h3.25v2.5h1v-2.5h3.25v3.9h1v-3.9H22v2.5h1v-2.5h3a.5.5 0 0 1 .5.5v5.5a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-5.5a.5.5 0 0 1 .5-.5z" transform="rotate(-45 14 14)"></path>
  </svg>`;
  zoomInSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor">
    <path d="M17.646 18.354l4 4 .708-.708-4-4z"></path>
    <path d="M12.5 21a8.5 8.5 0 1 1 0-17 8.5 8.5 0 0 1 0 17zm0-1a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15z"></path>
    <path d="M9 13h7v-1H9z"></path>
    <path d="M13 16V9h-1v7z"></path>
  </svg>`;
  isLogTooltip: CHART_ALTER_SCALE_CONFIGS =
    CHART_ALTER_SCALE_CONFIGS.LOGARITHMIC;
  freeScaleTooltip: CHART_ALTER_SCALE_CONFIGS =
    CHART_ALTER_SCALE_CONFIGS.OFF_AUTO;
  fastRulerTooltip: CHART_ALTER_SCALE_CONFIGS =
    CHART_ALTER_SCALE_CONFIGS.OFF_FAST_RULER;
  chartBookTooltip: CHART_ALTER_SCALE_CONFIGS =
    CHART_ALTER_SCALE_CONFIGS.OFF_BOOK_CHART;
  chartVolumeAtPriceTooltip: CHART_ALTER_SCALE_CONFIGS =
    CHART_ALTER_SCALE_CONFIGS.OFF_VOLUME_AT_PRICE_CHART;
  fastOrderTooltip: CHART_ALTER_SCALE_CONFIGS =
    CHART_ALTER_SCALE_CONFIGS.ON_FAST_ORDER;
  volumeTooltip: CHART_ALTER_SCALE_CONFIGS =
    CHART_ALTER_SCALE_CONFIGS.OFF_VOLUME;
  eventsTooltip: CHART_ALTER_SCALE_CONFIGS =
    CHART_ALTER_SCALE_CONFIGS.ON_EVENTS;
  execOrdersTooltip: CHART_ALTER_SCALE_CONFIGS =
    CHART_ALTER_SCALE_CONFIGS.OFF_EXEC_ORDERS;
  previousCloseTooltip: CHART_ALTER_SCALE_CONFIGS =
    CHART_ALTER_SCALE_CONFIGS.OFF_PREVIOUS_CLOSE;
  bidAskTooltip: CHART_ALTER_SCALE_CONFIGS =
    CHART_ALTER_SCALE_CONFIGS.OFF_BID_ASK;
  zoomInTooltip: CHART_ALTER_SCALE_CONFIGS =
    CHART_ALTER_SCALE_CONFIGS.ON_ZOOM_IN;
  @Input() refComponent!: string;
  @Input() showBookButton: boolean = true;
  @Input() set isLog(log: boolean) {
    this.log = log;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.ARITHMETIC,
      false: CHART_ALTER_SCALE_CONFIGS.LOGARITHMIC,
    };
    this.universalTooltip('isLogTooltip', this.log, bools);
  }
  @Input() set isFreeScale(freeScale: boolean) {
    this.freeScale = freeScale;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_AUTO,
      false: CHART_ALTER_SCALE_CONFIGS.ON_AUTO,
    };
    this.universalTooltip('freeScaleTooltip', this.freeScale, bools);
  }
  @Input() set showChartBook(chartBook: boolean) {
    this.chartBook = chartBook;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_BOOK_CHART,
      false: CHART_ALTER_SCALE_CONFIGS.ON_BOOK_CHART,
    };
    this.universalTooltip('chartBookTooltip', this.chartBook, bools);
  }
  @Input() set showChartVolumeAtPrice(chartVolumeAtPrice: boolean) {
    this.chartVolumeAtPrice = chartVolumeAtPrice;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_VOLUME_AT_PRICE_CHART,
      false: CHART_ALTER_SCALE_CONFIGS.ON_VOLUME_AT_PRICE_CHART,
    };
    this.universalTooltip(
      'chartVolumeAtPriceTooltip',
      this.chartVolumeAtPrice,
      bools
    );
  }
  @Input() set showVolume(volume: boolean) {
    this.volume = volume;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_VOLUME,
      false: CHART_ALTER_SCALE_CONFIGS.ON_VOLUME,
    };
    this.universalTooltip('volumeTooltip', this.volume, bools);
  }
  @Input() set showFastOrder(fastOrder: boolean) {
    this.fastOrder = fastOrder;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_FAST_ORDER,
      false: CHART_ALTER_SCALE_CONFIGS.ON_FAST_ORDER,
    };
    this.universalTooltip('fastOrderTooltip', this.fastOrder, bools);
  }
  @Input() set showEvents(events: boolean) {
    this.events = events;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_EVENTS,
      false: CHART_ALTER_SCALE_CONFIGS.ON_EVENTS,
    };
    this.universalTooltip('eventsTooltip', this.events, bools);
  }
  @Input() set showExecOrders(execOrders: boolean) {
    this.execOrders = execOrders;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_EXEC_ORDERS,
      false: CHART_ALTER_SCALE_CONFIGS.ON_EXEC_ORDERS,
    };
    this.universalTooltip('execOrdersTooltip', this.execOrders, bools);
  }
  @Input() set showPreviousClose(previousClose: boolean) {
    this.previousClose = previousClose;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_PREVIOUS_CLOSE,
      false: CHART_ALTER_SCALE_CONFIGS.ON_PREVIOUS_CLOSE,
    };
    this.universalTooltip('previousCloseTooltip', this.previousClose, bools);
  }
  @Input() set showBidAsk(bidAsk: boolean) {
    this.bidAsk = bidAsk;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_BID_ASK,
      false: CHART_ALTER_SCALE_CONFIGS.ON_BID_ASK,
    };
    this.universalTooltip('bidAskTooltip', this.bidAsk, bools);
  }
  @Input() set showZoomIn(zoomIn: boolean) {
    this.zoomIn = zoomIn;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_ZOOM_IN,
      false: CHART_ALTER_SCALE_CONFIGS.ON_ZOOM_IN,
    };
    this.universalTooltip('zoomInTooltip', this.zoomIn, bools);
  }
  @Input() configs!: Partial<ICandleConfigs>;
  @Output() buttonClick = new EventEmitter<{ type: TFooterType }>();
  tFooterType = TFooterType;
  public elementIDS = STOCK_CHART_ELEMENT_IDS;
  private _onDestroy = new Subject<void>();
  readonly hashFunctions: any = {
    [TFooterType.RULER]: (data: clickDefaultInfo) => {
      this.useFastRuler({ value: data.value, save: data.save });
    },
    [TFooterType.EXEC_ORDERS]: (data: clickDefaultInfo) => {
      this.useExecOrder({ value: data.value, save: data.save });
    },
  };
  constructor(
    private cdr: ChangeDetectorRef,
    private stockChartModalService: StockChartModalService,
    private stockChartService: StockChartService
  ) {
    this.stockChartService.footerChartConfig$
      .pipe(
        takeUntil(this._onDestroy),
        filter(
          ({ ref, isFromParent }) => ref === this.refComponent && isFromParent
        )
      )
      .subscribe(({ cod, value }) => {
        const func = this.hashFunctions[cod];
        if (func) {
          func({ value: value, save: false });
        }
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngAfterViewInit() {
    this.setDefaultActiveButtons();
  }

  private setDefaultActiveButtons() {
    const configuration = this.stockChartModalService.getGlobalChartConfigs();
    this.useFastRuler({ value: configuration.showFastRuler, save: false });
    this.useExecOrder({ value: configuration.showExecOrders, save: false })
  }

  click(type: TFooterType) {
    this.buttonClick.emit({ type });
  }

  clickChartConfig(type: TFooterType, value: any) {
    const func = this.hashFunctions[type];
    if (func) {
      func({ value, save: true });
      return;
    }
  }

  private universalTooltip(
    property: string,
    boolean: boolean,
    bools: { true: CHART_ALTER_SCALE_CONFIGS; false: CHART_ALTER_SCALE_CONFIGS }
  ) {
    (this as any)[property] = boolean ? bools.true : bools.false;
    this.cdr.detectChanges();
  }

  private useFastRuler = (data: clickDefaultInfo) => {
    this.fastRuler = data.value;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.ON_FAST_RULER,
      false: CHART_ALTER_SCALE_CONFIGS.OFF_FAST_RULER,
    };
    this.universalTooltip('fastRulerTooltip', this.fastRuler, bools);
    if (data.save) {
      this.stockChartService.footerChartConfig$.next({
        value: this.fastRuler,
        ref: this.refComponent,
        cod: TFooterType.RULER,
        isFromParent: false,
      });
      this.stockChartModalService.updateGlobalChartConfigs({
        showFastRuler: this.fastRuler,
      });
    }
  };

  private useExecOrder = (data: clickDefaultInfo) => {
    this.execOrders = data.value;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.ON_EXEC_ORDERS,
      false: CHART_ALTER_SCALE_CONFIGS.OFF_EXEC_ORDERS,
    };
    this.universalTooltip('execOrdersTooltip', this.execOrders, bools);
    if (data.save) {
      this.stockChartService.footerChartConfig$.next({
        value: this.execOrders,
        ref: this.refComponent,
        cod: TFooterType.EXEC_ORDERS,
        isFromParent: false,
      });
      this.stockChartModalService.updateGlobalChartConfigs({
        showExecOrders: this.execOrders,
      });
    }
  };
}
