import { ARROW_TYPES } from '@shared/components/stock-chart/enum/stock-chart.enum';
import { CHART_COLORS } from '../colors';
import { deepClone } from '@shared/rocket-components/utils';
import { IOverrideCustodyBox, IOverrideCustodyBoxColor } from '../interface';

export function getArrow(typeArrow: ARROW_TYPES, cssClass: string) {
  const negative = `
  <svg xmlns="http://www.w3.org/2000/svg">
      <path class="${cssClass}" style="fill-opacity:0.34117648;stroke-width:1px;stroke-linecap:butt;
      stroke-linejoin:miter;stroke-opacity:1;transform:translate(34px, 14px) scale(0.6);" 
      d="M 0 0 L -7.5 -7.5 l 1.3125 -1.3125 l 6.1875 6.1875 l 6.1875 -6.1875 l 1.3125 1.3125 l -7.5 7.5 Z m 0 -7.9063 L -7.5 -15.4063 l 1.3125 -1.3125 l 6.1875 6.1875 l 6.1875 -6.1875 l 1.3125 1.3125 l -7.5 7.5 Z"/>
  </svg>`;
  const positive = `
  <svg xmlns="http://www.w3.org/2000/svg">
    <path class="${cssClass}" style="fill-opacity:0.34117648;stroke-width:1px;stroke-linecap:butt;
    stroke-linejoin:miter;stroke-opacity:1;transform:translate(30px, 14px) scale(0.6);" 
    d="m 0 0 l -1.3125 -1.3125 l 7.5 -7.5 l 7.5 7.5 l -1.3125 1.3125 l -6.1875 -6.1875 l -6.1875 6.1875 Z m 0 -7.9063 l -1.3125 -1.3125 l 7.5 -7.5 l 7.5 7.5 l -1.3125 1.3125 l -6.1875 -6.1875 l -6.1875 6.1875 Z"/>
  </svg>`;
  return typeArrow === ARROW_TYPES.POSITIVE ? positive : negative;
}

export function getAnnotationTemplate(text: string) {
  return `
    <svg xmlns="http://www.w3.org/2000/svg">
      <g inkscape:label="Layer 1" inkscape:groupmode="layer" transform="translate(-55.430212,-77.263552)">
        <rect 
          style="
            fill:#fff;
            fill-opacity:1;
            stroke:#333333;
            stroke-width:0.26458332;
            stroke-miterlimit:4;
            stroke-dasharray:none;
            stroke-opacity:0.6
          "
          width="16"
          height="16"
          x="55.562504"
          y="77.395844"
          rx="20"
          ry="20"
        />
        <text 
          xml:space="preserve"
          style="
            font-style:normal;
            font-weight:normal;
            font-size:10px;
            line-height:1;
            font-family:Metropolis;
            letter-spacing:0px;
            word-spacing:0px;
            fill:#333333;
            fill-opacity:1;
            stroke:none;
            stroke-width:0.26458332
          "
          x="57.688622"
          y="89.160347"
        >
          <tspan 
            sodipodi:role="line"
            x="57.688622"
            y="89.160347"
            style="
              font-style:normal;
              font-variant:normal;
              font-weight:bold;
              font-stretch:normal;
              font-family:Metropolis;
              fill:#333333;
              fill-opacity:1;
              stroke-width:0.26458332
            "
          >
            ${text}
          </tspan>
        </text>
      </g>
    </svg>
  `;
}

function getColorsCustody(
  pnl: number,
  overrideColors?: {
    positiveColor?: IOverrideCustodyBoxColor;
    negativeColor?: IOverrideCustodyBoxColor;
  }
) {
  return pnl == 0
    ? {
        bg: CHART_COLORS.BRAND_SUPPORT_PRIMARY,
        font: CHART_COLORS.NEUTRAL_SMOOTHEST,
      }
    : pnl < 0
    ? overrideColors?.negativeColor ?? {
        bg: CHART_COLORS.FEEDBACK_NEGATIVE,
        font: CHART_COLORS.NEUTRAL_SMOOTHEST,
      }
    : overrideColors?.positiveColor ?? {
        bg: CHART_COLORS.FEEDBACK_POSITIVE,
        font: CHART_COLORS.NEUTRAL_STRONGEST,
      };
}

export function getTextBoxTemplate(
  text: string,
  textLength: number,
  textColor: string,
  fillColor: string,
  id: string,
  isCustody: boolean,
  codeIcon?: string,
  showX: boolean = true,
  showArrow: boolean = false,
  typeArrow: ARROW_TYPES = ARROW_TYPES.POSITIVE,
  overrideCustody?: IOverrideCustodyBox
) {
  const originalLength = deepClone(textLength);
  const xPositionText = codeIcon ? 15 : 6;
  const iconOffset = codeIcon ? (originalLength ? 8 : 5) : 0;
  const bar = `<line
    x1="${originalLength + 20 + iconOffset}"
    y1="0"
    x2="${originalLength + 20 + iconOffset}"
    y2="18"
    style="stroke:${textColor};stroke-width:1;"
  />`;
  const iconRight = showArrow
    ? `${bar}${getArrow(typeArrow, 'arrow-custody-color')}`
    : `${bar}<text x="${
        originalLength + 24 + iconOffset
      }" y="12" fill="${textColor}" style="font-size: 11px;">x</text>`;
  const xButton = showX ? iconRight : '';
  const widthXButton = showX ? 38 : 12;
  const newId = id.replace(/\./g, '');
  let percentWidth = 0;
  let squareCustody = '';
  let textCustody = '';
  if (isCustody && text) {
    const pnl = parseFloat(
      overrideCustody?.pnlText
        .replace(/R\$|pts|%| |\./g, '')
        .replace(',', '.') ?? '0'
    );
    percentWidth = overrideCustody?.pnlText.includes('%') ? 10 : 0;
    const colors = getColorsCustody(pnl, overrideCustody?.colors);
    squareCustody = `<rect x="${
      xPositionText + overrideCustody!.qttyLength + iconOffset + 2
    }" width="${
      overrideCustody!.pnlLength + 2
    }" height="100%" rx="4" style="color:${colors.font};fill:${
      colors.bg
    };"></rect>`;
    textCustody = `<text x="${
      xPositionText + iconOffset + overrideCustody!.qttyLength + 4
    }" y="13" fill="${colors.font}" text-length="${
      overrideCustody!.pnlLength
    }" style="font-size: 11px;">${overrideCustody!.pnlText}</text>`;
    text = `${overrideCustody!.qttyText}`;
    textLength = overrideCustody!.qttyLength + percentWidth;
  }
  return `<svg width="${
    originalLength + widthXButton + iconOffset + percentWidth
  }px" height="18" id="${newId}" xmlns="http://www.w3.org/2000/svg">
      <g>
        <rect width="${
          originalLength + widthXButton + iconOffset
        }" height="100%" rx="4" style="color:white;fill:${fillColor};">
        </rect>
        ${codeIcon ? codeIcon : ''}
        ${squareCustody}
        <text x="${
          xPositionText + iconOffset
        }" y="13" fill="${textColor}" text-length="${textLength}" style="font-size: 11px;">${text}</text>
        ${textCustody}
        ${xButton}
      </g>
    </svg>`;
}

export function getStopOrderTemplate(
  text: string,
  textColor: string,
  fillColor: string
) {
  return `<svg width="40px" height="17px" xmlns="http://www.w3.org/2000/svg">
      <g>
        <rect width="100%" height="100%" rx="10" style="color:white;fill:${fillColor};">
        </rect>
        <text x="4" y="12" fill="${textColor}" style="font-size: 11px;">${text}</text>
      </g>
    </svg>`;
}

export function getNotificationTemplate(xPositionIcon?: number) {
  const xPosition = xPositionIcon ? `x="${xPositionIcon}"` : null;
  return `<svg ${
    xPosition ? xPosition : ''
  } xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="15px" fill="${
    CHART_COLORS.NEUTRAL_SMOOTHER
  }">
    <foreignObject x="0" y="-2" width="24" height="24">
      <span class="material-icons-outlined hovered">notifications</span>
    </foreignObject>
  </svg>`;
}

export function getTemplateHammer(color: string, text: string) {
  const xPosition = text.length === 2 ? '6.601' : '8.601';
  return `<svg id="annotation_${text}" width="24" height="30" viewBox="0 0 24 30" xmlns="http://www.w3.org/2000/svg">
  <path d="M 12.227 0.883 L 22.26 10.057 L 2.193 10.057 L 12.227 0.883 Z" style="fill: ${color}; stroke: none;" bx:shape="triangle 2.193 0.883 20.067 9.174 0.5 0 1@9b265842"></path>
  <rect width="24" height="20" style="fill: ${color}; stroke: none;" y="10"></rect>
  <text style="font-weight: bolder;white-space: pre; fill: #fff; font-size: 12px;" x="${xPosition}" y="19.687" transform="matrix(1, 0, 0, 1, -0.932817, 4.872631)">${text}<tspan x="8.60099983215332" dy="1em"></tspan></text>
</svg>`;
}

export function getChartArrow() {
  return `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="32" height="32" rx="4" fill="var(--vm-neutral-strong)"/>
  <path d="M24.3438 5.17782L6.95403 13.9753C6.26264 14.326 6.50142 15.3384 7.28191 15.3609L13.9926 15.5596C15.1242 15.5924 16.0383 16.4649 16.0917 17.562L16.5123 26.2092C16.5515 27.0143 17.7062 27.1801 17.9842 26.4199L25.4094 6.0745C25.6393 5.44561 24.9551 4.86856 24.3438 5.17782Z" fill="#1DA1F2"/>
  </svg>`;
}

export function getCardTravel(fillColor: string) {
  return `<svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="24px" fill="${fillColor}">
    <path stroke="null" id="svg_2" d="m3.04638,17.09863l0,2.23289l15.65321,0l0,-2.23289l-15.65321,0zm3.52198,-10.19761l0,-2.30194q0,-0.55247 0.41435,-0.96682q0.41435,-0.41435 0.96682,-0.41435l5.82391,0q0.55247,0 0.96682,0.41435q0.41435,0.41435 0.41435,0.96682l0,2.30194l3.545,0q0.55247,0 0.96682,0.41435q0.41435,0.41435 0.41435,0.96682l0,11.04932q0,0.55247 -0.41435,0.96682q-0.41435,0.41435 -0.96682,0.41435l-15.65321,0q-0.55247,0 -0.96682,-0.41435q-0.41435,-0.41435 -0.41435,-0.96682l0,-11.04932q0,-0.55247 0.41435,-0.96682q0.41435,-0.41435 0.96682,-0.41435l3.52198,0zm-3.52198,7.71151l15.65321,0l0,-6.33034l-3.545,0l0,1.72645l-1.38116,0l0,-1.72645l-5.82391,0l0,1.72645l-1.38116,0l0,-1.72645l-3.52198,0l0,6.33034zm4.90314,-7.71151l5.82391,0l0,-2.30194l-5.82391,0l0,2.30194zm-4.90314,12.43049l0,-11.04932l0,1.72645l0,-1.72645l0,1.72645l0,-1.72645l0,11.04932z"/>
  </svg>`;
}

export function getPlus() {
  return `
  <svg viewBox="0 0 14 14" width="14" height="14" xmlns="http://www.w3.org/2000/svg">
    <rect width="14" height="14" fill="${CHART_COLORS.NEUTRAL_STRONG}"/>
    <svg x="1" y="2" viewBox="0 0 24 24" width="12" height="10" xmlns="http://www.w3.org/2000/svg">
      <path fill="${CHART_COLORS.NEUTRAL_STRONG}" stroke="white" d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z"/>
    </svg>
  </svg>`;
}

export function getClose(
  stroke: string = CHART_COLORS.NEUTRAL_SMOOTHEST,
  fill: string = CHART_COLORS.NEUTRAL_STRONG
) {
  return `
  <svg viewBox="0 0 14 14" width="14" height="14" xmlns="http://www.w3.org/2000/svg">
    <rect width="14" height="14" fill="${fill}"/>
    <svg x="1" y="2" viewBox="0 0 24 24" width="12" height="10" xmlns="http://www.w3.org/2000/svg">
      <path fill="${fill}" stroke="${stroke}" d="M 19.6317 3.3317 c 4.488 4.488 4.488 11.7754 0 16.2635 s -11.7754 4.488 -16.2635 0 s -4.488 -11.7754 0 -16.2635 s 11.7754 -4.488 16.2635 0 z m -0.7071 0.7071 c 4.0977 4.0977 4.0977 10.7516 0 14.8492 s -10.7516 4.0977 -14.8492 0 s -4.0977 -10.7516 -0 -14.8492 s 10.7516 -4.0977 14.8492 -0 z m -6.7175 7.4246 l 4.2426 4.2426 l -0.7071 0.7071 l -4.2426 -4.2426 l -4.2426 4.2426 l -0.7071 -0.7071 l 4.2426 -4.2426 l -4.2426 -4.2426 l 0.7071 -0.7071 l 4.2426 4.2426 l 4.2426 -4.2426 l 0.7071 0.7071 l -4.2426 4.2426 z"/>
    </svg>
  </svg>`;
}

export function getTemplateEvent(
  color: string,
  fontColor: string,
  text: string,
  fontSize: number
): string {
  return `<svg height="100" width="100">
  <foreignObject x="0" y="0" width="22" height="22">
    <div class="w-100 h-100 d-flex justify-content-center align-items-center round-lg" 
         style="background:${color};font-size: ${fontSize}px;">
      <span class="pt-1" style="color: ${fontColor};">${text}</span>
    </div>
  </foreignObject>
</svg>`;
}
