import { DaytradeService } from "@core/layout/header/daytrade/daytrade.service";
import { system } from "@core/system/system.service";

export const TOOLTIP_COLUMNS: any = { 
  pnl_open: {
    title: "P&L aberto",
    description: "Lucro/prejuízo de operações {DayTrade} abertas, baseado no preço médio da operação e no preço do último negócio.",
    data: {
      subscriber: system.tradeModeLabelsSubscription,
      dynamicDescription: true,
      descriptionHandler: DaytradeService.getTextWithTradeMode
    }
  },
  pnl_day: {
    title: "P&L dia",
    description: "Representa o ganho da posição no dia, com base no preço de fechamento do dia anterior. No caso de {DayTrade}, seu lucro/prejuízo serão acumulados quando a operação for fechada.",
    data: {
      subscriber: system.tradeModeLabelsSubscription,
      dynamicDescription: true,
      descriptionHandler: DaytradeService.getTextWithTradeMode
    }
  },
  pnl: {
    title: "P&L total",
    description: "Retorna o lucro/prejuízo total da posição baseado no preço médio de aquisição, atualizado em tempo real com o último negócio."
  },
  pl: {
    title: "Valor total",
    description: "Valor total da posição, considerando quantidade total x preço último."
  },
}