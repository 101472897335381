import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ISearchStock } from '@core/interface';
import { FundamentalService } from '@shared/services/api/trademap/V2/fundamental.service';
import { ICompanyMembersDataDiretoriaConselho } from '../../business-profile.interface';
import { BusinessProfileService } from '../../business-profile.service';
import {
  EXECUTIVES_BOARD_CARDS,
  FIELDS_MODAL_EXECUTIVES_BOARD,
} from '../../business-profile.const';
import { RocketModalService } from '@shared/rocket-components/components';
import { ModalInfoComponent } from '../../modal-info/modal-info.component';

@Component({
  selector: 'executive-board',
  templateUrl: './executive-board.component.html',
  styleUrls: ['./executive-board.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExecutiveBoardComponent implements OnInit {
  @Input() type!: string;
  @Input() set stockSelected(stockSelected: ISearchStock) {
    if (stockSelected) {
      this.error = false;
      this._stockSelected = stockSelected;
      this.startExecutiveSection();
    }
  }
  _stockSelected!: ISearchStock;
  rows: ICompanyMembersDataDiretoriaConselho[] = [];
  remuneracao!: any | undefined;
  error = false;
  cards = EXECUTIVES_BOARD_CARDS;

  constructor(
    private fundamentalService: FundamentalService,
    private businessProfileService: BusinessProfileService,
    private modalService: RocketModalService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  private startExecutiveSection() {
    const { cd_stock } = this._stockSelected;
    if (this._stockSelected.type != 'VIS') {
      return;
    }
    this.fundamentalService
      .getCompanyMembers(cd_stock)
      .then((response: any) => {
        const data = response.data.result.data;
        const isExecutive = this.type === 'EXECUTIVOS';
        this.rows = isExecutive ? data.diretoria : data.conselho;
        this.rows = this.businessProfileService.formatMember(this.rows);
        this.remuneracao = isExecutive
          ? data.remuneracao_diretoria
          : data.remuneracao_conselho;
        this.remuneracao = this.businessProfileService.formatRemuneracao(
          isExecutive,
          this.remuneracao!!
        );
        this.cdr.detectChanges();
      })
      .catch(() => {
        this.remuneracao = undefined;
        this.rows = [];
        this.error = true;
        this.cdr.detectChanges();
      });
  }

  openResume(row: ICompanyMembersDataDiretoriaConselho) {
    this.modalService.open(ModalInfoComponent, {
      scrollable: true,
      data: {
        info: row,
        fields: FIELDS_MODAL_EXECUTIVES_BOARD,
        modalTitle: row.member_name,
      },
    });
  }
}
