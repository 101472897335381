import { Injectable } from '@angular/core';
import { ConfigService } from '@core/config';
import { COMPONENTS_LIST, TOOLS_LIST } from '../constants';
import { COMPONENTS_NAMES_ENUM } from '../enum';
import { Dictionary } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class ComponentsService {
  constructor(private configService: ConfigService) {}

  getComponentsList() {
    const lists = COMPONENTS_LIST;
    if (!this.configService.isBroker()) {
      return lists;
    }
    const hashComponentsToRemove: any = {
      [COMPONENTS_NAMES_ENUM.SUPER_DOM]: true,
    };
    const list = new Dictionary<any>();
    lists.keys().forEach((key) => {
      const componentsList = lists.get(key)!;
      const components: any[] = [];
      componentsList.forEach((component: any) => {
        if (!hashComponentsToRemove[component.id]) {
          components.push(component);
        }
      });
      if (components.length) {
        list.set(key, components);
      }
    });
    return list;
  }

  getToolsList() {
    const lists = TOOLS_LIST;
    if (!this.configService.isBroker()) {
      return lists;
    }
    const hashToolsToShow: any = {
      [COMPONENTS_NAMES_ENUM.MARKET_RESUME]: true,
      [COMPONENTS_NAMES_ENUM.NEWS]: true,
      [COMPONENTS_NAMES_ENUM.BUSINESS_PROFILE]: true,
      [COMPONENTS_NAMES_ENUM.OPTIONS]: true,
    };
    const list = new Dictionary<any>();
    lists.values().forEach((tool) => {
      if (hashToolsToShow[tool.id]) {
        list.set(tool.cod, tool);
      }
    });
    return list;
  }
}
