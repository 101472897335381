import { Component, Inject, OnInit } from '@angular/core';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { IFields } from '../business-profile.interface';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss'],
})
export class ModalInfoComponent extends RocketModalRef implements OnInit {
  info!: any;
  fields!: IFields[];
  modalTitle: string = '';

  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: any,
    flaModalService: RocketModalService
  ) {
    super(flaModalService);
  }
  onClose = () => {
    this.close('Event Before Close Modal');
  };

  ngOnInit() {
    this.info = this.data.info;
    this.fields = this.data.fields;
    this.modalTitle = this.data.modalTitle;
    console.log('data', this.data);
  }
}
