import { Component } from '@angular/core';
import { WhatsappService } from '@core/layout/header/whatsapp/whatsapp.service';

@Component({
  selector: 'app-helper-mini-modal',
  templateUrl: './helper-mini-modal.component.html',
  styleUrls: ['./helper-mini-modal.component.scss'],
})
export class HelperMiniModalComponent {
  public opened: boolean = false;

  constructor(private whatsAppService: WhatsappService) {}

  public openModal() {
    this.opened = !this.opened;
  }

  public goToWhats() {
    this.whatsAppService.openWhatsAppRocket();
    this.opened = false;
  }

  public goToMail() {
    window.open(
      'mailto:contato@rockettrader.com.br?subject=Cadastro%20%2F%20Atualiza%C3%A7%C3%A3o%20cadastral&body=Ol%C3%A1%21%20Preciso%20realizar%20uma%20altera%C3%A7%C3%A3o%20cadastral%20no%20RocketTrader.%0A%0AEnvie%20nessa%20mensagem%20as%20seguintes%20informa%C3%A7%C3%B5es%20para%20que%20possamos%20atualizar%20seu%20cadastro%3A%20%0A%0A-%20Quer%20alterar%20e-mail%20ou%20telefone%3A%0A-%20CPF%3A%0A-%20Nome%20completo%3A%0A-%20Telefone%2FE-mail%20antigo%3A%0A-%20Telefone%2FE-mail%20novo%3A%0A-%20Data%20de%20nascimento%3A%0A-%20Por%20quest%C3%A3o%20de%20seguran%C3%A7a%2C%20precisamos%20que%20envie%20tamb%C3%A9m%20um%20documento%20com%20foto%20para%20comprovar%20sua%20identidade.%20Anexe%20no%20e-mail%0A%0AObrigado%2C%20em%20breve%20uma%20pessoa%20da%20nossa%20equipe%20te%20atender%C3%A1%21',
      '_blank'
    );
    this.opened = false;
  }
}

export function openZendeskRocket() {
  window.open(
    'https://rockettrader.zendesk.com/hc/pt-br/requests/new',
    '_blank'
  );
}
