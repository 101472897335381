import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { IWorkSpaceComponet } from '@core/interface';
import { deepClone } from '@shared/rocket-components/utils';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import {
  IPerformanceFilters,
  IPerformanceMarketData,
  IPerformancePercentagesData,
  IPerformancePeriod,
} from './interface/performance-report.interface';
import {
  DEFAULT_PERFORMANCE_MARKETDATA_VALUES,
  DEFAULT_PERFORMANCE_PERCENTAGES_VALUES,
  DEFAULT_PERFORMANCE_VALUES,
  PERFORMANCE_CHART_DEFAULT_PERIOD,
} from './constants/performance-report.contants';
import {
  PERFORMANCE_CONTENT,
  PERFORMANCE_FILTERS,
} from './enum/performance-report.enum';
import { PerformanceReportService } from './services/performance-report.service';
import { map } from 'rxjs';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { ActivatedRoute } from '@angular/router';
import { isWebViewContext } from 'src/app/desktop/integration.service';
import { PerformanceReportData } from './performance-report';
import { PerformanceInfosData } from './constants/performance-infos-data';
import { ALL_STOCKS_LABEL } from '@shared/services/api/nitro-custody-ws/v1/custody.service';

@Component({
  selector: 'app-performance-report',
  templateUrl: './performance-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformanceReportComponent extends RocketComponentBase {
  @Input() component!: IWorkSpaceComponet;
  @Input() refComponent!: string;
  @Input() width!: number;
  @Input() height!: number;
  @Input() link!: boolean;

  public readonly PERFORMANCE_CONTENT = PERFORMANCE_CONTENT;
  public currentContent = PERFORMANCE_CONTENT.INFOS;
  public performanceDetails: PerformanceReportData | any =
    new PerformanceReportData();

  public marketDataDetails!: IPerformanceMarketData;
  public operations!: IPerformancePercentagesData;
  public filters: any = {
    PERFORMANCE_FILTERS_BROKER: null,
    PERFORMANCE_FILTERS_OPERATION_TYPE: 'Posições',
    PERFORMANCE_FILTERS_STOCK: ALL_STOCKS_LABEL,
    PERFORMANCE_FILTERS_PERIOD: PERFORMANCE_CHART_DEFAULT_PERIOD,
  };
  public useSubscriptionInfos: boolean = true;
  public performanceErrorMsg: string = 'Houve um erro inesperado.';
  isDesktop = false;

  constructor(
    private _performanceReportService: PerformanceReportService,
    private cdr: ChangeDetectorRef,
    activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
    this.isDesktop = isWebViewContext();
  }

  protected initComponent(component: any): void {
    if (component) this.component = component;

    if (this.isDesktop) {
      this.height =
        this.component.metadata.componentResize?.height ??
        parseInt(this.component.metadata.layout.height);
      this.width =
        this.component.metadata.componentResize?.width ??
        parseInt(this.component.metadata.layout.width);
    }
  }

  public performanceHandler = (item: PerformanceReportData) => {
    if (isNullOrUndefined(item)) {
      this._resetPerformanceInfos();
      return;
    }
    this.performanceDetails = item.details;
    this.marketDataDetails = item.marketDataDetails;
    this.operations = item.operationsPercents;
    this.cdr.detectChanges();
  };

  private _resetPerformanceInfos(): void {
    this.performanceDetails = deepClone(DEFAULT_PERFORMANCE_VALUES);
    this.cdr.detectChanges();
  }

  public handlerFilters(filter: IPerformanceFilters): void {
    const previusFilters = deepClone(this.filters);
    previusFilters[filter.type] = filter.value;
    this.filters = previusFilters;
    this.cdr.detectChanges();
    if (
      this.currentContent === PERFORMANCE_CONTENT.INFOS &&
      filter.type === PERFORMANCE_FILTERS.PERIOD
    ) {
      this._infosPeriodHandler(filter.value);
      return;
    }
    if (!this.useSubscriptionInfos) this._getPerformanceByPeriod();
  }

  private _infosPeriodHandler(filter: IPerformancePeriod): void {
    this._resetPerformanceInfos();
    const useCheetah = filter.value === 'today';
    this.useSubscriptionInfos = useCheetah;
    if (!useCheetah) this._getPerformanceByPeriod();
    this.cdr.detectChanges();
  }

  private _getPerformanceByPeriod(): void {
    this._performanceReportService
      .getCustody(this.filters)
      .pipe(map((response) => response[0]))
      .subscribe({
        next: (response) => {
          this.performanceDetails =
            new PerformanceInfosData()._buildPerfomanceData(response);
          this.marketDataDetails = deepClone(
            DEFAULT_PERFORMANCE_MARKETDATA_VALUES
          );
          this.operations = {
            ...deepClone(DEFAULT_PERFORMANCE_PERCENTAGES_VALUES),
            open_operation: response.qtty_open_order,
            win_operation: response.qtty_win_operation,
            win_percent: response.perc_win_operation,
            loss_operation: response.qtty_loss_operation,
            loss_percent: response.perc_loss_operation,
            zero_operation: response.qtty_zero_operation,
            zero_percent: response.perc_zero_operation,
          };
          this.cdr.detectChanges();
        },
        error: () => {
          this.performanceDetails = deepClone(DEFAULT_PERFORMANCE_VALUES);
          this.marketDataDetails = deepClone(
            DEFAULT_PERFORMANCE_MARKETDATA_VALUES
          );
          this.operations = deepClone(DEFAULT_PERFORMANCE_PERCENTAGES_VALUES);
          this.cdr.detectChanges();
        },
      });
  }
}
