<div [ngClass]="[css]">
  <label
    *ngIf="label"
    [for]="refId"
    class="form-label"
    [ngClass]="{ required: isRequired }"
  >
    {{ label }}
  </label>
  <input
    #telInput
    type="tel"
    [formControl]="formControl"
    [class]="'form-control' + (isInvalid ? ' text-danger' : '')"
    [id]="refId"
    [autocomplete]="autoComplete ? 'on' : 'new-password'"
    [maxlength]="maxLength"
  />
  <small
    class="ng-invalid invalid-feedback d-block"
    *ngIf="
      (isRequired &&
        formControl.enabled &&
        formControl.invalid &&
        formControl.touched &&
        !formControl.pristine) ||
      formControl.hasError(hasError)
    "
  >
    {{ requiredText }}
  </small>
</div>
