export enum PERFORMANCE_CONTENT {
  INFOS = 'PERFORMANCE_CONTENT_INFOS',
  CHART = 'PERFORMANCE_CONTENT_CHART',
}

export enum PERFORMANCE_FILTERS {
  BROKER = 'PERFORMANCE_FILTERS_BROKER',
  OPERATION_TYPE = 'PERFORMANCE_FILTERS_OPERATION_TYPE',
  STOCK = 'PERFORMANCE_FILTERS_STOCK',
  PERIOD = 'PERFORMANCE_FILTERS_PERIOD',
}
