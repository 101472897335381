import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GraphContainer } from '../directives/graph-container.directive';
import MenuItemBase from './menu-items/menu-item-base';
import VisualOrder from './menu-items/visual-order';
import MoveTo from './menu-items/move-to';
import FixInScale from './menu-items/fix-in-scale';
import Copy from './menu-items/copy';
import Hide from './menu-items/hide';
import Delete from './menu-items/delete';
import Settings from './menu-items/settings';
import { BaseIndicator } from '../indicators/indicators.types';
import { Subscription } from 'rxjs';
import { RTChartScichartBase } from '../chart-base-scichart';

@Component({
  templateUrl: './tooltip-menu.component.html',
  styleUrls: ['./tooltip-menu.component.scss'],
})
export class TooltipMenu implements OnInit, OnDestroy {
  @Input() chart!: RTChartScichartBase;
  @Input() indicator!: BaseIndicator;
  @Input() top = 0;
  @Input() left = 0;
  @Input() graphContainer!: GraphContainer;
  @Input() menu: MenuItemBase[] = [];
  @Output() onMenuClose = new EventEmitter<boolean>();

  private onMenuCloseSubscription!: Subscription;

  @ViewChild('TigerChartIndicatorMenu') rootElement!: ElementRef;
  style = '';
  private fontSize = 16;
  private wrapperPadding = 0;
  private submenu!: any;

  constructor() {
    this.menu = [
      new VisualOrder(),
      new MoveTo(),
      new FixInScale(),
      new Copy(),
      new Hide(),
      new Delete(),
      new Settings(),
    ];
  }

  ngOnInit() {
    this.style = `top: ${this.top}px; left: ${this.left}px;`;
    const size = window
      .getComputedStyle(document.body, null)
      .getPropertyValue('font-size');
    this.fontSize = parseInt(size) || 16;
    this.wrapperPadding = Math.ceil(this.fontSize * 0.5);
  }

  ngOnDestroy() {
    if (this.submenu) this.submenu.destroy();
  }

  onHover(item: MenuItemBase) {
    if (this.submenu) {
      this.submenu.destroy();
    }

    if (item.submenu) {
      const el = document.getElementById(`${item.id}`);
      const offsetTop = el?.offsetTop || 0;

      this.submenu =
        this.graphContainer.viewContainerRef.createComponent<TooltipMenu>(
          TooltipMenu
        );
      this.submenu.instance.chart = this.chart;
      this.submenu.instance.indicator = this.indicator;
      this.submenu.instance.top = this.top - this.wrapperPadding + offsetTop;
      this.submenu.instance.left =
        this.left + this.rootElement.nativeElement.offsetWidth;
      this.submenu.instance.menu = item.submenu;
      this.onMenuCloseSubscription =
        this.submenu.instance.onMenuClose.subscribe(() => {
          this.onMenuCloseSubscription.unsubscribe();
          this.onMenuClose.emit();
        });
    }
  }

  onMouseOut(event: any) {
    if (event?.relatedTarget?.classList.contains('Tiger-chart-menu')) {
      return;
    }

    this.onMenuClose.emit();
  }

  onClick(item: MenuItemBase) {
    item.onCLick(this.chart, this.indicator);
  }
}
