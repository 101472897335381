import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
} from '@angular/core';
import { LogoModule } from '@shared/components/logo/logo.module';
import {
  PARTNER_CUSTOM_CONTENT,
  PARTNER_WITH_CUSTOM_LAYOUT,
} from '@shared/services/core/partners-services/partners.const';
import { PartnersService } from '@shared/services/core/partners-services/partners.service';
import {
  CarouselComponent,
  ICarouselContent,
} from '@shared/standalone-components/carousel/carousel.component';

@Component({
  selector: 'app-login-background-content',
  templateUrl: './login-background-content.component.html',
  styleUrls: ['./login-background-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, LogoModule, CarouselComponent],
})
export class LoginBackgroundContentComponent implements AfterViewInit {
  @Input() set useCreateAccountLayout(value: boolean) {
    if (!this._createAccountLayout && !value) return;
    this.handleRegisterBackgroundContent(value);
  }

  private _createAccountLayout = false;

  public useDefaultLayout: boolean = true;
  public textRandomNumber: number = 0;
  public partnerLogo: string = '';
  public bdClass: string = '';
  public primaryText: string =
    'A plataforma de <strong>trading</strong><br /> para quem vive o mercado.';
  public messageWelcome: string[] = [
    'Negocie com <span class="fw-bold text-brand-primary">alta performance</span>,<br /> <span class="fw-bold text-brand-primary">estabilidade e segurança</span>',
    'Negocie com <span class="fw-bold text-brand-primary">alta performance</span>,<br /> <span class="fw-bold text-brand-primary">estabilidade e segurança</span>',
    'Aqui você se adianta aos<br /> <span class="fw-bold text-brand-primary">movimentos do mercado</span>',
    'Faça <span class="fw-bold text-brand-primary">análise técnica</span> de verdade,<br /> com mais de 20 indicadores',
    'Se você já possui uma conta no TradeMap, <br /> faça o login e experimente gratuitamente o RocketTrader. <br /> <br /> Aproveite a<span class="fw-bold text-brand-primary"> melhor experiência de negociação do mercado</span>.',
  ];
  public carouselContent!: ICarouselContent[];

  private _partnersService = inject(PartnersService);
  private _cdr = inject(ChangeDetectorRef);

  ngAfterViewInit() {
    if (this._partnersService.partnerBeforeAuth) {
      if (
        PARTNER_WITH_CUSTOM_LAYOUT[this._partnersService.partnerBeforeAuth.key!]
      ) {
        this.useCustomLayout();
        return;
      }
    }
    this.handleRegisterBackgroundContent();
  }

  handleRegisterBackgroundContent(isCreateAccountPage: boolean = false): void {
    if (isCreateAccountPage) {
      this.useCreateAccountContent();
      return;
    }

    if (!isCreateAccountPage && this._createAccountLayout) {
      this.setRandomBackgroundContent(true);
      return;
    }

    this.setRandomBackgroundContent();
  }

  setRandomBackgroundContent(useDefaultText: boolean = false): void {
    this.textRandomNumber = Math.floor(Math.random() * 4);
    this.bdClass = `bg-image-${this.textRandomNumber}`;
    if (useDefaultText)
      this.primaryText =
        'A plataforma de <strong>trading</strong><br /> para quem vive o mercado.';
    this._createAccountLayout = false;
    this._cdr.detectChanges();
  }

  useCreateAccountContent(): void {
    this.useDefaultLayout = true;
    this._createAccountLayout = true;
    this.bdClass = 'bg-image-signup';
    this.textRandomNumber = 4;
    this.primaryText =
      'O <span class="fw-bold">RocketTrader</span> e o <span class="fw-bold">TradeMap</span> compartilham <br /> as mesmas <span class="fw-bold text-brand-primary">credenciais de acesso!</span> <br /> <br /> ';
    this._cdr.detectChanges();
  }

  useCustomLayout(): void {
    const content =
      PARTNER_CUSTOM_CONTENT[this._partnersService.partnerBeforeAuth!.key!];
    this.useDefaultLayout = false;
    this.partnerLogo = `../../../../../assets/logo/${content.logo}`;
    this.carouselContent = content.carousel;
    this._cdr.detectChanges();
  }
}
