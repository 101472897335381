import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  inject,
} from '@angular/core';
import {
  FlaIconModule,
  InputCodeModule,
  RocketButtonModule,
  RocketModalService,
} from '@shared/rocket-components/components';
import { ModalTokenConfigComponent } from '../modal-token-config/modal-token-config.component';
import { IAPIServiceResponse } from '@shared/services/api/trademap/v1/interface/apiResponse';
import { ToastService } from '@shared/services';
import { OrderTokenService } from '@shared/rocket-components/modal-order-token/order-token.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TradeTokenPopupService } from './trade-token-popup.service';
import { OtpService } from '@shared/services/api/authentication/v3/otp.service';

@Component({
  selector: 'app-trade-token-popup',
  templateUrl: './trade-token-popup.component.html',
  styles: [
    `
      .toast-token {
        position: absolute;
        bottom: 32px;
        right: 16px;
        max-width: 460px;
        height: 120px;
        z-index: 1;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RocketButtonModule, FlaIconModule, InputCodeModule],
})
export class TradeTokenPopupComponent implements OnDestroy {
  @Input() public data!: any;
  public isDisabled: boolean = true;
  public showConfirm: boolean = false;
  public clearInputValues: boolean = false;
  public codeSize: number = 6;
  public closed = new Subject<any>();
  public fieldToFocus: number | undefined = 0;
  public code: string = '';

  private _onDestroy = new Subject<void>();
  private _rocketModalService = inject(RocketModalService);

  constructor(
    private cdr: ChangeDetectorRef,
    private _otpService: OtpService,
    private _toastService: ToastService,
    private _orderTokenService: OrderTokenService,
    private _toastTokenService: TradeTokenPopupService
  ) {
    this._otpService.activateAuthenticatorConfig$
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data) => {
        this.close({ confirm: true, isForce: data.isForce });
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.unsubscribe();
  }

  @HostListener('window:keydown.enter')
  handleKeyDown() {
    this.executeTokenValidation();
  }

  @HostListener('window:keydown.esc')
  handleKeyEsc() {
    this.closed.next(null);
  }

  @HostListener('window:keyup.backspace')
  private _onPressBackspace() {
    if (
      !this.isDisabled &&
      this.code?.length === 6 &&
      this.fieldToFocus !== 5
    ) {
      this.fieldToFocus = 5;
    }
  }

  openConfigTokenModal() {
    this.close();
    this.openTokenConfig();
  }

  openTokenConfig() {
    this._rocketModalService.open(ModalTokenConfigComponent, {
      size: 'lg',
      centered: true,
      backdrop: true,
      keyboard: true,
      scrollable: false,
    });
  }

  codeChanged(code: any) {
    const isValid = code.length === 6;
    if (!isValid) {
      this.code = '';
      this.fieldToFocus = undefined;
    }
    this.isDisabled = !isValid;
    this.cdr.detectChanges();
  }

  codeCompleted(code: any) {
    this.isDisabled = false;
    this.code = code;
    if (!this.showConfirm) this.executeTokenValidation();
    this.cdr.detectChanges();
  }

  executeTokenValidation() {
    if (this.isDisabled || !this.code) return;
    this._otpService
      .activateAuthenticatorConfig(this.code, this.data.id, true)
      .subscribe((res: IAPIServiceResponse) => {
        if (res && res.success) {
          this._saveTokenInfos();
          return;
        }
        this.code = '';
        this.isDisabled = true;
        this.clearInputValues = true;
        this._toastService.showToast(
          'error',
          res.message
            ? res.message
            : 'Ocorreu um erro ao validar o token. Por favor tente novamente!'
        );
        this.cdr.detectChanges();
      });
  }

  private _saveTokenInfos = (): void => {
    this._orderTokenService.tradingToken = this.code;
    this.handleKeyEsc();
    this._toastService.showToast(
      'success',
      'Segundo fator validado com sucesso!'
    );
  };

  public close(value?: any, isSecondModal?: boolean | undefined): void {
    if (!value || !value.isForce) this._toastTokenService.saveSessionSaw();
    this.closed.next({ value, isSecondModal });
  }
}
