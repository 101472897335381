<table
  [ngClass]="{ 'min-width-table-trade-idea': isSmall, 'width-table': !isSmall }"
  class="mt-3"
>
  <thead>
    <tr>
      <th class="text-right fw-bold fs-6 max-width-percent">%</th>
      <th class="text-right fw-bold fs-6 max-width-values">Gain</th>
      <th class="text-right fw-bold fs-6 max-width-values">Loss</th>
      <th *ngIf="false" class="text-right fw-bold fs-6">Offset (ticks)</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let event of items"
      [ngClass]="{
        'border-tradeidea': !isMediumBorder,
        'medium-border': isMediumBorder
      }"
    >
      <td class="text-right fs-6">{{ event.formattedQuantity }}%</td>
      <td class="text-right fs-6">{{ event.formattedGain }}</td>
      <td class="text-right fs-6">{{ event.formattedLoss }}</td>
      <td *ngIf="false" class="text-right fs-6">{{ event.formattedOffset }}</td>
    </tr>
  </tbody>
</table>
