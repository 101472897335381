import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ISearchStock } from '@core/interface';
import { OptionsComponentService } from '@shared/components/options/options-component.service';
import { TStrategyOptionChartSeries } from '@shared/components/options/types';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { formatterNumber, randomId } from '@shared/rocket-components/utils';
import { THEMES } from '@shared/services/core/custom-preferences/theme/theme-preferences.interface';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { DEFAULT_THEME, LIGHT_THEME } from '@shared/tiger-chart/colors';
import { ScichartService } from '@shared/tiger-chart/services/scichart.service';
import {
  CursorTooltipSvgAnnotation,
  FastLineRenderableSeries,
  SeriesInfo,
  TWebAssemblyChart,
  XyDataSeries,
  adjustTooltipPosition,
} from 'scichart';

@Component({
  selector: 'app-options-strategy-graph',
  templateUrl: './options-strategy-graph.component.html',
  styleUrls: ['./options-strategy-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsStrategyGraphComponent {
  @Input() stock!: ISearchStock | undefined;
  @Input() refId: string = randomId('options');
  @Input() SimpleChartSeries: TStrategyOptionChartSeries[] = [];
  @Input() SimulationChartSeries: TStrategyOptionChartSeries[] = [];
  scichartService!: ScichartService;
  theme!: any;
  chart!: TWebAssemblyChart | undefined;
  firstLine!: FastLineRenderableSeries | undefined;
  secondLine!: FastLineRenderableSeries | undefined;

  constructor(
    private _stockChartService: StockChartService,
    private themeService: ThemePreferencesService,
    private cdr: ChangeDetectorRef,
    private optionsService: OptionsComponentService
  ) {
    this.theme = this.getTheme(this.isDarkTheme());
    this.themeService
      .themeActiveObservable()
      .subscribe(this.changeThemeHandler);
    this.scichartService = new ScichartService(
      undefined,
      this._stockChartService
    );
  }

  getTheme = (isDark: boolean) => (isDark ? DEFAULT_THEME() : LIGHT_THEME());

  changeThemeHandler = (data: any) => {
    if (!this.chart?.sciChartSurface) return;
    this.theme = this.getTheme(data === THEMES.dark);
    this.chart = undefined;
    this.firstLine = undefined;
    this.secondLine = undefined;
    this.initGraph();
    this.cdr.detectChanges();
  };

  isDarkTheme = () => this.themeService.isDarkTheme();

  private _plotLine(
    data: TStrategyOptionChartSeries[],
    lineColor: string,
    name: string,
    lineSeries?: FastLineRenderableSeries
  ): FastLineRenderableSeries | undefined {
    if (!this.chart) return;
    const xValues: number[] = [];
    const yValues: number[] = [];
    data.forEach((point: TStrategyOptionChartSeries) => {
      xValues.push(point.price);
      yValues.push(point.result);
    });
    const dataSeries = new XyDataSeries(this.chart.wasmContext, {
      dataSeriesName: name,
      yValues,
      xValues,
    });
    if (lineSeries) {
      lineSeries.dataSeries = dataSeries;
      return lineSeries;
    }
    lineSeries = new FastLineRenderableSeries(this.chart.wasmContext, {});
    lineSeries.strokeThickness = 2;
    lineSeries.stroke = lineColor;

    lineSeries.dataSeries = dataSeries;
    this.chart.sciChartSurface.renderableSeries.add(lineSeries);
    return lineSeries;
  }

  resetLines() {
    this.firstLine = undefined;
    this.secondLine = undefined;
    this.chart?.sciChartSurface.renderableSeries.clear();
  }

  initGraph() {
    if (this.chart) {
      this._plotLines();
      return;
    }
    this.scichartService.initSciChart(this.refId).then((baseChart) => {
      this.chart = baseChart;
      const xAxis = this.optionsService.buildXNumericAxis(this.chart, 2);
      this.chart.sciChartSurface.xAxes.add(xAxis);
      const yAxis = this.optionsService.buildYAxis(this.chart);
      this.chart.sciChartSurface.yAxes.add(yAxis);
      this.chart.sciChartSurface.chartModifiers.add(
        this.optionsService.buildCursorModifier(
          this.theme,
          this._tooltipSvgTemplate
        )
      );
      this.chart.sciChartSurface.chartModifiers.add(
        this.optionsService.buildZoomPan()
      );
      this.chart.sciChartSurface.chartModifiers.add(
        this.optionsService.buildWheelZoom()
      );
      this.chart.sciChartSurface.chartModifiers.add(
        this.optionsService.buildZoomDoubleClick()
      );
      this.chart.sciChartSurface.chartModifiers.add(
        this.optionsService.buildLegend('option-graph-legend')
      );
      this._stockChartService.removeChartLoading$.next({
        remove: true,
        ref: this.refId,
      });
      this._plotLines();
      this.chart.sciChartSurface.zoomExtents();
    });
  }

  private _tooltipSvgTemplate(
    seriesInfos: SeriesInfo[],
    svgAnnotation: CursorTooltipSvgAnnotation
  ): string {
    const width = 84;
    const height = 66;
    const seriesInfo = seriesInfos[0];
    if (!seriesInfo?.isWithinDataBounds || !this.stock) {
      return '<svg></svg>';
    }

    const { cd_stock } = this.stock;
    const x = seriesInfo ? seriesInfo.formattedXValue : '';
    const y1 = seriesInfo ? seriesInfo.yValue : 0;
    const y2 = seriesInfos[1] ? seriesInfos[1].yValue : 0;

    adjustTooltipPosition(width, height, svgAnnotation);
    return `<svg width="${width}" height="${height}">
            <rect width="100%" height="100%" rx="4" style="fill:${
              this.theme.axisBandsFill
            };"></rect>
            <svg width="100%">
                <text y="5" font-size="11" dy="0" fill="${
                  this.theme.axisTitleColor
                }">
                    <tspan x="6" text-anchor="left" dy="1.2em" font-weight="bold">${cd_stock}:</tspan><tspan> ${x}</tspan>
                    <tspan x="6" text-anchor="left" dy="1.4em">Rentabilidade</tspan>
                    <tspan x="6" text-anchor="left" dy="1.2em">Venc: ${formatterNumber(
                      y1
                    )}</tspan>
                    <tspan x="6" text-anchor="left" dy="1.2em">Hoje: ${formatterNumber(
                      y2
                    )}</tspan>
                </text>
            </svg>
        </svg>`;
  }

  private _plotLines() {
    this.firstLine = this._plotLine(
      this.SimpleChartSeries,
      this.theme.brandSupportPrimary,
      'Venc.',
      this.firstLine
    );
    this.secondLine = this._plotLine(
      this.SimulationChartSeries,
      this.theme.axisTitleColor,
      'Hoje',
      this.secondLine
    );
    this.chart?.sciChartSurface.zoomExtents();
  }
}
