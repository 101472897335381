import { FlaFocusModule } from '@shared/directives/focus/focus.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { LogoModule } from '@shared/components/logo/logo.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderTabsComponent } from './header/tabs/header-tabs.component';
import { ModalGerenciarComponent } from './header/modal-gerenciar/modal-gerenciar.component';
import { ModalAvisoModule } from '@shared/components/modal-aviso/modal-aviso.module';
import { ModalCloneComponent } from './header/modal-clone/modal-clone.component';
import { AccountSelectorModule } from '@shared/components/account-selector/account-selector.module';
import { StatusPerformanceComponent } from './header/status-performance/status-performance.component';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';
import { ToastModule } from '@shared/rocket-components/toast';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { ContextMenuModule } from '@shared/components/context-menu/context-menu.module';
import { TitleCaseModule } from '@shared/directives/title-case/title-case.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ModalOrderTokenModule } from '@shared/rocket-components/modal-order-token/modal-order-token.module';
import {
  FlaIconModule,
  FlaTabModule,
  RocketButtonModule,
  RocketModalModule,
  FlaInputModule,
  FlaRadiosModule,
} from '@shared/rocket-components/components/index';
import { ModalStrategiesComponent } from './header/strategy/modal-strategies/modal-strategies.component';
import { RTInputCountModule } from '@shared/rocket-components/input-count/input-count.module';
import { ModalShortcutsComponent } from './header/modal-shortcuts/modal-shortcuts.component';
import { ToogleThemeComponent } from './header/toogle-theme/toogle-theme.component';
import { ModalMeusDadosComponent } from './header/modal-meus-dados/modal-meus-dados.component';
import { DataItemComponent } from './header/modal-meus-dados/data-item/data-item.component';
import { ModalDesktopVersionComponent } from './header/modal-desktop-version/modal-desktop-version.component';
import { ModalChangePasswordComponent } from './header/modal-meus-dados/modal-change-password/modal-change-password.component';
import { FormValidatorsModule } from '@shared/components/form-validators/form-validators.module';
import { InputPasswordModule } from '@shared/rocket-components/directives/input-password';
import { LockWorkspaceComponent } from './header/lock-workspace/lock-workspace.component';
import { RocketIconsModule } from '@shared/rocket-icons/rocket-icons.module';
import { ModalTokenConfigModule } from './header/modal-token-config/modal-token-config.module';
import { ModalAcceptTermsModule } from '@shared/modals/modal-accept-terms/modal-accept-terms.module';
import { ComponentsCountComponent } from '@shared/components/components-count/components-count.component';
import { TickStrategyModule } from './header/strategy/modal-strategies/tick-strategy/tick-strategy.module';
import { WhatsappComponent } from './header/whatsapp/whatsapp.component';
import { PopoversModule } from '@shared/rocket-components/popovers';
import { PopupRootModule } from '@shared/components/popup-root/popup-root.module';
import { StocksAtAuctionByIndexModule } from '@shared/components/stocks-at-auction-by-index/stocks-at-auction-by-index.module';
import { RiskManagementIconComponent } from '../../shared/modals/modal-risk-management/risk-management-icon/risk-management-icon.component';
import { PhoneInputConfigService } from '@shared/rocket-components/components/phone-input/service/phone-input-config.service';

@NgModule({
  declarations: [
    DataItemComponent,
    HeaderComponent,
    HeaderTabsComponent,
    LayoutComponent,
    LockWorkspaceComponent,
    ModalCloneComponent,
    ModalDesktopVersionComponent,
    ModalGerenciarComponent,
    ModalShortcutsComponent,
    ModalStrategiesComponent,
    StatusPerformanceComponent,
    ToogleThemeComponent,
    ModalChangePasswordComponent,
    LockWorkspaceComponent,
    WhatsappComponent,
    ModalMeusDadosComponent,
  ],
  imports: [
    FlaInputModule,
    FlaIconModule,
    FlaTabModule,
    FormsModule,
    ReactiveFormsModule,
    FlaFocusModule,
    RTInputCountModule,
    FlaRadiosModule,
    RocketButtonModule,
    RocketModalModule,
    CommonModule,
    RouterModule,
    ToastModule,
    TooltipsModule,
    ModalAvisoModule,
    LogoModule,
    TitleCaseModule,
    AccountSelectorModule,
    RTDropdownModule,
    ContextMenuModule,
    DragDropModule,
    ModalOrderTokenModule,
    FormValidatorsModule,
    InputPasswordModule,
    RocketIconsModule,
    ModalTokenConfigModule,
    ModalAcceptTermsModule,
    TickStrategyModule,
    ComponentsCountComponent,
    PopoversModule,
    PopupRootModule,
    StocksAtAuctionByIndexModule,
    RiskManagementIconComponent,
  ],
  exports: [LayoutComponent],
  providers: [PhoneInputConfigService],
})
export class LayoutModule {}
