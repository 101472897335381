import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FlaIconModule } from '@shared/rocket-components/components';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import { RTTextLengthService } from '@shared/tiger-chart/services/text-length.service';
import { formatByTick, isNullOrUndefined } from 'src/app/utils/utils.functions';

enum PRICE_TYPE {
  MIN = 'MIN',
  MAX = 'MAX',
}
@Component({
  selector: 'app-candle-horizontal',
  templateUrl: './candle-horizontal.component.html',
  styleUrls: ['./candle-horizontal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FlaIconModule, TooltipsModule],
})
export class CandleHorizontalComponent implements OnChanges {
  @Output() flaClink = new EventEmitter<void>();

  @Input() public avgPrice: string = '';
  @Input() public valueOpen: number | string = 0;
  @Input() public valueClose: number | string = 0;
  @Input() public valueMin: number | string = 0;
  @Input() public valueMax: number | string = 0;
  @Input() public containerHeight: string = '50px';
  @Input() public tickSize: string | number = '0';
  @Input() public dsAsset: string = '';
  @Input() public price: string = '';
  @Input() public arrowColor: string = CHART_COLORS.NEUTRAL_MEDIUM;
  @Input() public hideArrow: boolean = true;
  @Input() public hideAlert: boolean = true;
  @Input() public alertPrice: number | string = 0;

  private _currencySymbol: 'R$' | '$' = 'R$';

  public vlOpenTooltip = '';
  public minValueTooltip: string = '';
  public maxValueTooltip: string = '';
  public priceTooltip: string = '';
  public useInvertOpenVl: boolean = false;

  public arrowStartPosition: number = 0;
  public avgPricePosition: number = 0;
  public alertPricePosition: number = 0;
  public arrowWidth: number = 0;
  public arrowPoint: number = 0;
  public minValueFormatted: string = '0';
  public maxValueFormatted: string = '0';
  public arrowIcon: 'arrow_right' | 'arrow_left' = 'arrow_right';
  public alertColor: string = CHART_COLORS.NEUTRAL_MEDIUM;
  public alertLabelPosition: number = 0;
  public alertLabel: string = '0';

  constructor(
    private textLengthService: RTTextLengthService,
    private cdr: ChangeDetectorRef
  ) {
    //empty
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {
      valueClose,
      valueOpen,
      avgPrice,
      valueMin,
      valueMax,
      dsAsset,
      price,
      alertPrice,
      tickSize
    } = changes;
    this._updateCloseValue(
      valueClose?.currentValue ?? this.valueClose,
      valueOpen?.currentValue ?? this.valueOpen
    );
    if (avgPrice?.currentValue)
      this.avgPricePosition = this._getPosition(avgPrice.currentValue);
    if (alertPrice?.currentValue) this.processAlert(alertPrice.currentValue)
    if (valueMin?.currentValue)
      this._updateMinAndMaxPrice(PRICE_TYPE.MIN, valueMin.currentValue);
    if (valueMax?.currentValue)
      this._updateMinAndMaxPrice(PRICE_TYPE.MAX, valueMax.currentValue);
    if (tickSize?.currentValue){
      this._updateMinAndMaxPrice(PRICE_TYPE.MIN, this.valueMin as any);
      this._updateMinAndMaxPrice(PRICE_TYPE.MAX, this.valueMax as any);
    }
    if (dsAsset?.currentValue)
      this._currencySymbol = ['DOL', 'WDO'].includes(dsAsset.currentValue)
        ? '$'
        : 'R$';
    if (price?.currentValue)
      this.priceTooltip = `
    <span class="fs-6 fw-bold"> Atual: ${price.currentValue} </span>
    `;
  }

  private _updateCloseValue(closeValue: number, openValue: number): void {
    const porcentagemInicioDiv =
      ((openValue - +this.valueMin) / (+this.valueMax - +this.valueMin)) * 100;
    const porcentagemFinalDiv =
      ((closeValue - +this.valueMin) / (+this.valueMax - +this.valueMin)) * 100;
    const arrowBarWidth = porcentagemFinalDiv - porcentagemInicioDiv;
    this.vlOpenTooltip = `
        <span class="fs-6 fw-bold"> Abertura: ${formatByTick(openValue)} </span>
      `;
    if (arrowBarWidth >= 0) {
      this.arrowIcon = 'arrow_right';
      this.arrowWidth = Math.abs(arrowBarWidth - 1);
      this.arrowStartPosition = this._getPosition(+openValue);
      this.arrowPoint = this._getPosition(+closeValue) - 2;
      this.useInvertOpenVl = false;
    } else {
      this.arrowIcon = 'arrow_left';
      this.arrowWidth = Math.abs(arrowBarWidth + 1);
      this.arrowStartPosition = this._getPosition(+closeValue);
      this.arrowPoint = this._getPosition(+closeValue) + 2;
      this.useInvertOpenVl = true;
    }
    this._buildTooltipTemplate(PRICE_TYPE.MIN, this.valueMin);
    this._buildTooltipTemplate(PRICE_TYPE.MAX, this.valueMax);
    if(this.alertPrice) this.processAlert(this.alertPrice)
  }

  private _getPosition(value: string | number): number {
    return Math.round(
      ((+value - +this.valueMin) / (+this.valueMax - +this.valueMin)) * 100
    );
  }

  private _updateMinAndMaxPrice(type: PRICE_TYPE, price: string): void {
    const value = this._formatStockPrice(price);
    if (type === PRICE_TYPE.MIN) this.minValueFormatted = value;
    else this.maxValueFormatted = value;
    this._buildTooltipTemplate(type, price);
  }

  private _formatStockPrice(price: string): string {
    if (!price || price === '0' || isNullOrUndefined(price)) return '0';
    return formatByTick(price, +this.tickSize);
  }

  private _buildTooltipTemplate(
    type: PRICE_TYPE,
    price: string | number
  ): void {
    const vlOpen = this.valueOpen ? +this.valueOpen : 0;
    let variation = '0';
    if (+price && vlOpen)
      variation = new Intl.NumberFormat('pt-BR', {
        maximumFractionDigits: 2,
      }).format((+price / vlOpen - 1) * 100);

    const template = `
      <div class="gap-0 fs-6 fw-bold vstack text-left">
        [PRICE_BY_TYPE]
        <span>Variação: ${variation}%</span>
      </div>
    `;
    if (type === PRICE_TYPE.MIN) {
      this.minValueTooltip = template.replace(
        '[PRICE_BY_TYPE]',
        `<span>Mínimo: ${this._currencySymbol}${this.minValueFormatted}</span>`
      );
      return;
    }
    this.maxValueTooltip = template.replace(
      '[PRICE_BY_TYPE]',
      `<span>Máximo: ${this._currencySymbol}${this.maxValueFormatted}</span>`
    );
  }

  private processAlert(alertPrice: any) {
    const pos = this._getPosition(alertPrice);
    this.alertLabel = this._formatStockPrice(alertPrice)
    if (pos < 0) {
      this.alertPricePosition = -4;
      this.alertLabelPosition = 0;
    } else if (pos > 50) {
      this.alertPricePosition = pos > 100 ? 96 : pos; 
      this.alertLabelPosition = -this.textLengthService.getBoxLineTextLength(
        this.alertLabel,
        undefined,
        '10px'
      ) + 15;
    } else {
      this.alertPricePosition = pos;
      this.alertLabelPosition = 0
    }
    this.alertColor =
      this.alertPrice > this.valueClose
        ? CHART_COLORS.FEEDBACK_POSITIVE
        : CHART_COLORS.FEEDBACK_NEGATIVE;
    this.cdr.detectChanges()
  }
}
