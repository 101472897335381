import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlaFocusDirective } from './focus.directive';

@NgModule({
  declarations: [FlaFocusDirective],
  imports: [CommonModule],
  exports: [FlaFocusDirective],
  providers: [],
})
export class FlaFocusModule {}
