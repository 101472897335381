export enum TOOLS_ENUM {
  TEMPORARY = 'temporário',
  CURSORS = 'Cursores',
  TREND_LINES = 'Ferramentas de Linhas de Tendência',
  GANN_FIBONACCI = 'Ferramentas de Gann e Fibonacci',
  GEOMETRIC_SHAPES = 'Formas Geométricas',
  ICONS = 'Ícones',
  ANNOTATION_TOOLS = 'Ferramentas de Anotação',
  RULER = 'Régua',
  LOCKER = 'Travar todos os Desenhos',
  VISIBLE = 'Ocultar todos os Desenhos',
  TRASH = 'Remover Desenhos',
  HELP = 'Ajuda',
}

export enum TOOLS_TYPE_CALCULATION {
  MINUS = '-',
  PLUS = '+',
  DIVIDER = '/',
  MULTIPLY = '*',
  PERCENTAGE = '*',
}

export enum TOOLS_TYPE_DRAW {
  CENTER_LINE = 'CENTER_LINE',
  LINE = 'LINE',
  BACKGROUND = 'BACKGROUND',
  TEXT = 'TEXT',
  OUTLINE = 'OUTLINE',
}

export enum TOOLS_METHOD {
  DRAW_LINE = 'drawLine',
  DRAW_ARROW_LINE = 'drawArrowLine',
  CUSTOM_BOX_ANNOTATION = 'customBoxAnnotation',
  TREND_ANGLE_LINE = 'trendAngleLine',
  VERTICAL_LINE = 'verticalLine',
  HORIZONTAL_LINE = 'horizontalLine',
  BOX_ANNOTATION = 'boxAnnotation',
  CIRCLE_ANNOTATION = 'circleAnnotation',
  TEXT_ANNOTATION = 'textAnnotation',
  ICON = 'iconAnnotation',
  FLAG = 'flagAnnotation',
  FIBONACCI_RETRACEMENT = 'fibonacciRetracement',
}

export enum TOOLS_CALCULATION {
  FIBONACCI_RETRACEMENT = 'fibonacciRetracement',
}
