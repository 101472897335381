export enum STOCKS_AT_AUCTION_BY_INDEX_ELEMENTS_ID {
  container = 'STOCKS_AT_AUCTION_BY_INDEX_ENUM_container',
  icon = 'STOCKS_AT_AUCTION_BY_INDEX_ENUM_icon',
  content = 'STOCKS_AT_AUCTION_BY_INDEX_ENUM_content',
}

export enum AUCTION_STOCKS_BY_INDEX_ELEMENTS_ID {
  loading = 'AUCTION_STOCKS_BY_INDEX_loading',
  content = 'AUCTION_STOCKS_BY_INDEX_content',
  empty = 'AUCTION_STOCKS_BY_INDEX_empty',
}

export enum INDEXES_TO_SHOW_STOCKS_AT_AUCTION {
  content = 'INDEXES_TO_SHOW_STOCKS_AT_AUCTION_content',
  changeSelectedIndex = 'INDEXES_TO_SHOW_STOCKS_AT_AUCTION_changeSelectedIndex',
  changeIndexLabel = 'INDEXES_TO_SHOW_STOCKS_AT_AUCTION_changeIndexLabel',
  controls = 'INDEXES_TO_SHOW_STOCKS_AT_AUCTION_controls',
}
