<rocket-modal>
  <rocket-modal-header>
    <h2 class="modal-title">Configurações</h2>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <div class="row pt-3 border border-brand-primary round">
      <div
        class="bg-dark fw-bold position-absolute pr-2 round-sm position-top d-flex w-auto"
      >
        <app-check
          css="cursor-pointer"
          [isChecked]="isCheckAll"
          (flaChange)="selectAll()"
        ></app-check>
        <label class="ml-2"> Colunas </label>
      </div>
      <div
        class="col-auto mb-3"
        *ngFor="let item of configView"
        [style.width.px]="item.width"
      >
        <div class="d-inline-block">
          <app-check
            [label]="item.label"
            [isChecked]="item.isCheck"
            [inline]="true"
            css="cursor-pointer"
            (flaChange)="setVisibleOrInvisible($event, item)"
          ></app-check>
        </div>
      </div>
    </div>
  </app-rocket-modal-body>
  <rocket-modal-footer>
    <rocket-button
      type="fill"
      label="Cancelar"
      css="mr-4"
      [isSmall]="true"
      (rocketClick)="onClose()"
    ></rocket-button>
    <rocket-button
      label="Salvar"
      [isSmall]="true"
      (rocketClick)="onSave()"
    ></rocket-button>
  </rocket-modal-footer>
</rocket-modal>
