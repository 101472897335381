<form [formGroup]="form">
  <div
    [style.height.px]="24"
    class="w-100 mb-2 mt-1 d-inline-flex align-items-center justify-content-between"
    [ngClass]="{
      'disable-click opacity-medium':
        !form.get('nameStrategy')?.value && !isNewTick
    }"
  >
    <fla-radio-group
      formControlName="typeStrategy"
      (flaChange)="changeStrategyType($event)"
    >
      <fla-radio-button
        label="Ticks"
        name="typeStrategy"
        value="TICK"
        [isChecked]="form.get('typeStrategy')?.value === 'TICK'"
        [isInline]="true"
      ></fla-radio-button>

      <fla-radio-button
        label="Financeiro"
        name="typeStrategy"
        value="FINANCEIRO"
        [isChecked]="form.get('typeStrategy')?.value === 'FINANCEIRO'"
        [isInline]="true"
      ></fla-radio-button>

      <fla-radio-button
        label="Percentual (%)"
        name="typeStrategy"
        value="PERCENTUAL"
        [isChecked]="form.get('typeStrategy')?.value === 'PERCENTUAL'"
        [isInline]="true"
      ></fla-radio-button>
    </fla-radio-group>
  </div>
  <div
    formArrayName="items"
    [style.max-height.px]="height"
    [style.min-height.px]="height"
    [ngClass]="{
      'overflow-y-scroll': groups.controls.length > scroll
    }"
  >
    <div
      *ngFor="let group of groups.controls; let i = index"
      [formGroupName]="i"
      class="w-100 d-inline-flex align-items-center mb-2 position-relative"
      [ngClass]="{
        'disable-click opacity-medium': !form.get('typeStrategy')?.value
      }"
    >
      <fla-input
        formControlName="quantity"
        label="Qtd (%)"
        decidePosition="suffix"
        margin="mr-2"
        [min]="1"
        [max]="100"
        [isSmall]="true"
        flaMask="separator.0"
        (onRefIdCreated)="saveElementRef($event)"
        (keydown.Tab)="handleCountFocus($event)"
        (keydown.ArrowDown)="
          handleValue('MINUS', group?.get('quantity')!, true, true)
        "
        (keydown.ArrowUp)="
          handleValue('PLUS', group?.get('quantity')!, true, true)
        "
        (flaInput)="onSetStrategyQuantity()"
        [css]="indexInvalidQuantity === i ? 'invalid-quantity' : ''"
      >
        <div class="suffix pr-0">
          <div class="d-flex flex-column">
            <rocket-button
              type="icon"
              css="py-1 up"
              (rocketClick)="
                handleValue('PLUS', group?.get('quantity')!, true, true)
              "
            >
              <fla-icon name="arrow_drop_up" css="lh-none"></fla-icon>
            </rocket-button>

            <rocket-button
              type="icon"
              css="py-1 down"
              (rocketClick)="
                handleValue('MINUS', group?.get('quantity')!, true, true)
              "
            >
              <fla-icon name="arrow_drop_down" css="lh-none"></fla-icon>
            </rocket-button>
          </div>
        </div>
      </fla-input>

      <span
        *ngIf="indexInvalidQuantity === i"
        [style.top.px]="60"
        class="w-100 fs-6 position-absolute text-feedback-error"
      >
        A soma deve ser igual a 100%
      </span>

      <app-input-count
        formControlName="gain"
        label="Gain"
        margin="mr-2"
        [incrementValue]="incrementValue"
        [min]="1"
        [minLength]="1"
        [max]="999999999999999"
        [maxLength]="999999999999999"
        [isSmall]="true"
        [mask]="inputMask"
        (refIdCreated)="saveElementRef($event)"
        (keydown.Tab)="handleCountFocus($event)"
        (rtChange)="verifyDefaultValue($event)"
        (flaKeyUp)="checkInputIsEmpty($event)"
        [enableScrollEvent]="true"
        [isModal]="true"
        [symbolPrefix]="setPrefixOrSuffix('prefix')"
        [symbolSuffix]="setPrefixOrSuffix('suffix')"
        [selectOnFocus]="true"
      ></app-input-count>

      <app-input-count
        formControlName="loss"
        label="Loss"
        margin="mr-2"
        [incrementValue]="incrementValue"
        [min]="1"
        [minLength]="1"
        [max]="999999999999999"
        [maxLength]="999999999999999"
        [mask]="inputMask"
        [isSmall]="true"
        (keydown.Tab)="handleCountFocus($event)"
        (refIdCreated)="saveElementRef($event)"
        (rtChange)="verifyDefaultValue($event)"
        (flaKeyUp)="checkInputIsEmpty($event)"
        [enableScrollEvent]="true"
        [isModal]="true"
        [symbolPrefix]="setPrefixOrSuffix('prefix')"
        [symbolSuffix]="setPrefixOrSuffix('suffix')"
        [selectOnFocus]="true"
      ></app-input-count>

      <app-input-count
        formControlName="offset"
        label="Offset (ticks)"
        margin="mr-2"
        [min]="1"
        [minLength]="1"
        [isSmall]="true"
        [max]="999999999999999"
        [maxLength]="999999999999999"
        mask="separator.0"
        (keydown.Tab)="handleCountFocus($event)"
        (refIdCreated)="saveElementRef($event)"
        (rtChange)="verifyDefaultValue($event)"
        (flaKeyUp)="checkInputIsEmpty($event)"
        [enableScrollEvent]="true"
        [isModal]="true"
        [selectOnFocus]="true"
      ></app-input-count>

      <div class="mt-5">
        <ng-container *ngIf="i === 0; else removeStrategyItemsLine">
          <rocket-button
            type="icon"
            (rocketClick)="addStrategyItems()"
            [isDisabled]="disableAddCondition"
            [ngClass]="{
              'disable-click opacity-semi-opaque': disableAddCondition
            }"
            tooltip="Adicionar condição"
            [tooltipHidden]="disableAddCondition"
          >
            <fla-icon name="add" size="icon-size-micro"></fla-icon>
          </rocket-button>
        </ng-container>
        <ng-template #removeStrategyItemsLine>
          <rocket-button
            type="icon"
            (rocketClick)="removeStrategyItems(i)"
            tooltip="Remover condição"
          >
            <fla-icon name="remove" size="icon-size-micro"></fla-icon>
          </rocket-button>
        </ng-template>
      </div>
    </div>
  </div>
</form>
