import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  WritableSignal,
  computed,
  signal,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  OPTIONS_STATUS,
  OPTIONS_STYLES,
  OPTIONS_TYPES,
  TOptions,
  TOptionsForm,
  TOptionsStatus,
  TOptionsStrategyType,
  TOptionsStyle,
  TOptionsSubheaderTabs,
  TOptionsType,
  TStrategyOptionExpirationDate,
} from '../../types';
import { ColDef } from 'ag-grid-community';
import { ISearchStock, IWorkSpaceComponet } from '@core/interface';
import { OptionsService } from '@shared/services/api/trademap/v1/options.service';
import { OptionsChannel } from '@shared/channel/options.channel';
import { GridIChangeField, GridIColumnVisible } from '@shared/rocket-grid';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { RocketModalService } from '@shared/rocket-components/components';
import { OptionsStrategyModalComponent } from '../strategy-modal/options-strategy-modal.component';
import { AUTH_LOCAL_KEYS } from '@shared/services/core/const/auth_util.const';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { daysUntilDate } from 'src/app/utils/utils.functions';
import { OptionsListBase } from './options-list-base';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { IRowData } from '@shared/components/stock-table/interfaces/stock-table.interfaces';
import { RocketStreamRead } from '@shared/channel/rx-event';

@Component({
  selector: 'app-options-list',
  templateUrl: './options-list.component.html',
  styleUrls: ['./options-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsListComponent
  extends ReadStreamBase
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Output() enableChangeStock = new EventEmitter<void>();
  @Input() linked!: boolean;
  @Input() refId!: string;
  @Input() componentInfo!: IWorkSpaceComponet;
  @Input() set height(height: string) {
    if (height) {
      this._height.set(parseInt(height));
    }
  }
  get height() {
    return this._height().toString();
  }
  private _height = signal(0);
  @Input() loading = true;
  @Input() width!: string;
  @Input() stock!: ISearchStock | undefined;
  @Input() expirationDates!: TStrategyOptionExpirationDate[];
  @Input() expirationDatesHash!: any;
  @Input() set subheaderTab(subheaderTab: TOptionsSubheaderTabs) {
    if (subheaderTab) {
      this._subheaderTab.set(subheaderTab);
      this._loadData();
    }
  }
  private _subheaderTab: WritableSignal<TOptionsSubheaderTabs | undefined> =
    signal(undefined);
  @ViewChild('formElement') formElement!: ElementRef;
  @ViewChild('strategy') strategy!: ElementRef;
  @ViewChild('grid') grid!: OptionsListBase;

  form!: FormGroup;
  types: { id: TOptionsType; label: string }[] = [
    { id: 'C', label: OPTIONS_TYPES.C },
    { id: 'P', label: OPTIONS_TYPES.P },
    { id: 'ALL', label: OPTIONS_TYPES.ALL },
  ];
  styles: { id: TOptionsStyle; label: string }[] = [
    { id: 'A', label: OPTIONS_STYLES.A },
    { id: 'E', label: OPTIONS_STYLES.E },
    { id: 'ALL', label: OPTIONS_STYLES.ALL },
  ];
  statusList: { id: TOptionsStatus; label: string }[] = [
    { id: 'ATM', label: OPTIONS_STATUS.ATM },
    { id: 'ITM', label: OPTIONS_STATUS.ITM },
    { id: 'OTM', label: OPTIONS_STATUS.OTM },
    { id: 'ALL', label: OPTIONS_STATUS.ALL },
  ];
  columnDefs: Array<ColDef> = [];
  rowData: TOptions[] = [];
  onUpdateField!: GridIChangeField;
  ready = false;
  strategiesHasLegs = false;
  withoutData = false;
  workingDays: { [key: string]: number } = {};
  columnVisible!: GridIColumnVisible;
  configKey = '5';
  loadingGridContent = true;

  private _cheetahOptionsParams: any;
  private _cheetahQuotesParams: any;
  private _optionsStream: RocketStreamRead | undefined;
  private _quoteStream: RocketStreamRead | undefined;
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _optionsEvents$: Subscription = new Subscription();
  private _theme$: Subscription = new Subscription();
  private _strategiesWithLegs: TOptionsStrategyType[] = [];

  gridHeight = computed(() => {
    return this._height() - 76;
  });

  expirationDate = computed(() => {
    return this.expirationDateSignal();
  });

  expirationDateSignal = signal('0');
  tooltipDate = '';
  isDarkTheme = computed(() => {
    return this.isDarkThemeSignal();
  });

  isDarkThemeSignal = signal(true);

  isTable = computed(() => {
    return this._subheaderTab() == 'Tabela';
  });

  constructor(
    private _formBuilder: FormBuilder,
    private _optionsService: OptionsService,
    private _optionsChannel: OptionsChannel,
    private _quoteChannel: QuoteChannel,
    private _themeService: ThemePreferencesService,
    private _modalService: RocketModalService,
    private _customPreferencesService: CustomPreferencesService,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.form = this._formBuilder.group({
      type: new FormControl('ALL'),
      style: new FormControl('ALL'),
      status: new FormControl('ALL'),
      alreadNegotiated: new FormControl(false),
      date: new FormControl(null),
    });
    this.form.get('date')?.valueChanges.subscribe((value) => {
      this.expirationDateSignal.set(value);
      const expiration = this.expirationDatesHash[value.toString()];
      this.tooltipDate = ``;
      if (expiration) {
        this.tooltipDate = expiration.tooltip;
      }
      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    this._initiateConfigs();
  }

  ngAfterViewInit(): void {
    this._initiateSubscriptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { expirationDates, stock } = changes;
    if (expirationDates?.currentValue) {
      if (!expirationDates.currentValue?.length) {
        this._clearLastValues();
        return;
      }
      this.loadingGridContent = true;
      this.withoutData = false;
      const configs = this._getConfigs();
      const newValue = this.expirationDatesHash[configs?.form?.date.toString()]
        ? configs.form.date
        : this.expirationDates[0]?.id;
      this.form.get('date')?.setValue(newValue || null);
      this.filterChange();
      !this.ready && this._loadStrategies();
    }
    if (stock && !stock.previousValue && stock.currentValue) {
      this.ready = true;
      this.filterChange();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.unsubscribe();
    this._unsubscribeCheetah();
    this._theme$.unsubscribe();
    this._optionsEvents$.unsubscribe();
    this._optionsStream?.close();
    this._quoteStream?.close();
  }

  private _clearLastValues(): void {
    this._unsubscribeCheetah();
    this.grid && this.grid.processOptions(null);
    this.rowData = [];
    this.withoutData = true;
    this.loadingGridContent = false;
    this.cdr.detectChanges();
  }

  filterChange() {
    if (!this.stock || !this.columnDefs || !this.ready) {
      return;
    }
    this._loadData();
    this._loadStrategies();
    this._saveConfig();
  }

  private _initiateSubscriptions() {
    this._quoteChannel.readEvents().then(async (data) => {
      this._quoteStream = data;
      this.readStream(data.stream, (streamData: Map<string, IRowData>) => {
        streamData.forEach((quote: IRowData) => {
          if (
            !this.stock ||
            this.stock.cd_stock.slice(0, 3) !== quote.item.slice(0, 3)
          )
            return;
          this.grid.quotesChannelHandler(quote);
        });
      });
    });

    this._theme$ = this._themeService.themeActiveObservable().subscribe(() => {
      this.isDarkThemeSignal.set(this._themeService.isDarkTheme());
    });

    this._optionsChannel.onEvents().then((data) => {
      this._optionsStream = data;
      this.readStream(data.stream, this.grid.optionsChannelHandler);
    });
  }

  private _loadData() {
    if (!this.stock) return;
    this.loadingGridContent = true;
    const { cd_stock, id_exchange } = this.stock;
    const form = this.form.getRawValue();
    if (!this.isTable()) {
      form.style = null;
      form.type = null;
      form.alreadNegotiated = null;
    }
    this._optionsService
      .getStockOptions(cd_stock, id_exchange, form)
      .pipe(takeUntil(this._destroy$))
      .subscribe((response: any) => {
        this.rowData = [];
        if (!response?.length) {
          this.withoutData = true;
          this._onLoadOptions();
          return;
        }
        this.withoutData = false;
        let cheetahItems: string[] = [];
        if (response) cheetahItems = this.grid.processOptions(response);
        this._subscribeOptionsChannel(cheetahItems);
        this._subscribeQuotesChannel(cheetahItems);
        this._onLoadOptions();
      });
  }

  private _onLoadOptions(): void {
    this.ready = true;
    this.loadingGridContent = false;
    this.enableChangeStock.emit();
    this.cdr.detectChanges();
  }

  private _loadStrategies() {
    if (!this.stock) return;
    const { cd_stock, id_exchange } = this.stock;
    this.strategiesHasLegs = false;
    this._strategiesWithLegs = [];
    this._optionsService
      .getStrategies(cd_stock, id_exchange, this.expirationDate())
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response: any) => {
          const { workingDays } = daysUntilDate(this.expirationDate());
          response.strategies.forEach((strategy: any) => {
            if (strategy.legs.length == 0) {
              this._strategiesWithLegs.push(strategy);
              return;
            }

            this.strategiesHasLegs = true;
            strategy.trending_market =
              strategy.trending_market == 'neutro'
                ? 'neutra'
                : strategy.trending_market;

            strategy.legs = strategy.legs.map((leg: any) => {
              leg.side = leg.operation_type;
              leg.price = leg.vl_close;
              leg.id_exchange = id_exchange;
              leg.option_days_to_expiration = workingDays;
              return leg;
            });
            this._strategiesWithLegs.push(strategy);
          });
        },
        error: () => {
          this.strategiesHasLegs = false;
          this._strategiesWithLegs = [];
          this.cdr.detectChanges();
        },
      });
  }

  showStrategyWizard() {
    this.strategiesHasLegs &&
      this._modalService.open(OptionsStrategyModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: true,
        data: {
          stock: this.stock,
          strategies: this._strategiesWithLegs,
        },
      });
  }

  private _subscribeOptionsChannel(cheetahItems: string[]) {
    this._unsubscribeOptions();
    if (!this.stock) return;
    this._cheetahOptionsParams = {
      itemsArray: cheetahItems,
    };
    this._optionsChannel.subscribe(this._cheetahOptionsParams);
    this._optionsStream?.snapshot(this._cheetahOptionsParams.itemsArray);
  }

  private _unsubscribeCheetah() {
    this._unsubscribeOptions();
    this._unsubscribeQuotes();
  }

  private _unsubscribeOptions() {
    if (!this._cheetahOptionsParams) return;

    this._optionsChannel.unsubscribe(this._cheetahOptionsParams);
    this._cheetahOptionsParams = null;
  }

  private _subscribeQuotesChannel(cheetahItems: string[]) {
    this._unsubscribeQuotes();
    if (!this.stock) return;
    this._cheetahQuotesParams = {
      itemsArray: cheetahItems,
      header: this.refId,
    };
    this._quoteChannel.subscribe(this._cheetahQuotesParams);
    this._quoteStream?.snapshot(this._cheetahQuotesParams.itemsArray);
  }

  private _unsubscribeQuotes() {
    if (!this._cheetahQuotesParams) return;

    this._quoteChannel.unsubscribe(this._cheetahQuotesParams);
    this._cheetahQuotesParams = null;
  }

  private _initiateConfigs() {
    const configs = this._getConfigs();
    this._initiateForm(configs?.form);
    this._loadData();
  }

  private _initiateForm(values?: TOptionsForm) {
    values && this.form.setValue(values);
  }

  private _getConfigs() {
    return JSON.parse(
      this._customPreferencesService.getCustomPreference(
        AUTH_LOCAL_KEYS.OPTIONS_CONFIGURATION
      )
    );
  }

  private _saveConfig() {
    if (!this.ready) return;

    const configs = this._getConfigs();

    this._customPreferencesService.customPreference = {
      key: AUTH_LOCAL_KEYS.OPTIONS_CONFIGURATION,
      value: JSON.stringify({
        ...configs,
        form: this.form.getRawValue(),
        key: this.configKey,
      }),
    };
  }
}
