import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicControlService } from '@shared/rocket-components/dynamic-form/dynamic-control.service';
import { TigerChartOrdersForm } from './tiger-chart-orders.form';
import { IAccountSelect, ISearchStock } from '@core/interface';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { Dictionary } from '@core/models';
import { CHART_LOADING_KEYS } from '@shared/components/stock-chart/enum/stock-chart.enum';
import { TigerChartOrdersCustody } from './tiger-chart-orders.interface';
import { formatNumber } from '@angular/common';
import {
  formatByTick,
  getIncrementValue,
  isCrypto,
  isNullOrUndefined,
} from 'src/app/utils/utils.functions';
import { TYPE_CLICK } from './tiger-chart-oders.enum';
import { SUPER_DOM_TOOLTIPS } from '@shared/components/super-dom/constants/super-dom.constants';
import { Subscription, debounceTime, filter } from 'rxjs';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import { ICandleConfigs } from '../interface';
import { StockPreferencesService } from '@shared/services/stock-preferences.service';
import { OriginAnalysisOrderService } from '@shared/services/origin-analysis-order.service';
import { ORDER_PARAM_HELPER } from '@shared/constants/order-param-helper.const';
import { MultibrokerService } from '@shared/services/core/multibroker';
import { IPosition } from '@shared/components/stock-chart/interface/stock-chart.interface';

@Component({
  selector: 'app-tiger-chart-orders',
  templateUrl: './tiger-chart-orders.component.html',
  styleUrls: ['./tiger-chart-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TigerChartOrdersComponent implements OnInit, OnDestroy {
  @Input() public stockChartConfig!: ICandleConfigs;
  public form!: FormGroup;
  public fieldsForm!: any[];
  public configs!: any;
  public lastPrice: number = 0;
  public stock!: ISearchStock;
  public loadingKeys = CHART_LOADING_KEYS;
  public custodyDict = new Dictionary<TigerChartOrdersCustody>();
  public typeClicks = TYPE_CLICK;
  public accountSelected!: IAccountSelect | any;
  public disableButtons: boolean = false;
  private _subscriptions: Subscription = new Subscription();
  public invertAndResetTooltips: {
    invert: string;
    reset: string;
    cancel: string;
  } = {
    invert: DaytradeService.getTextWithTradeMode(
      SUPER_DOM_TOOLTIPS[`INVERT_DAYTRADE_ON`]
    ),
    reset: DaytradeService.getTextWithTradeMode(
      SUPER_DOM_TOOLTIPS[`RESET_DAYTRADE_ON`]
    ),
    cancel: DaytradeService.getTextWithTradeMode(
      SUPER_DOM_TOOLTIPS[`CANCEL_ORDERS_ON`]
    ),
  };
  private dayTradeModeIsEnable = false;
  public qttyChangeEnabled: boolean = false;
  labelOrderBuy = '';
  labelOrderSell = '';
  total = '';
  @Output() fastOrdersClick = new EventEmitter<{
    type: TYPE_CLICK;
    price: number;
    qtde: number;
    isStartStop: boolean;
    fastOrder: boolean;
  }>();
  @Input() set selectedStock(stock: ISearchStock) {
    if (stock) {
      if (!this.stock || this.stock.cd_stock !== stock.cd_stock) {
        this.stock = stock;
        this.instanceForm();
        if (this._userCustody) {
          this.makeInfoCustody();
        }
      }
    }
  }
  @Input() showFastOrderDaytradeStrategy!: boolean;
  @Input() refComponent!: string;
  @Input() width!: number;
  @Input() set closePrice(lastPrice: number | undefined) {
    if (lastPrice) {
      this.lastPrice = lastPrice;
      this.labelOrderBuy = this.labelOrder(TYPE_CLICK.BUY);
      this.labelOrderSell = this.labelOrder(TYPE_CLICK.SELL);
    }
  }
  @Input() set userCustody(userCustody: IPosition | undefined) {
    this._userCustody = userCustody;
    this.makeInfoCustody();
  }
  private _userCustody!: IPosition | undefined;

  @Input() minPriceIncrement: number | undefined;

  get priceControl() {
    return this.form.get('price')!;
  }
  get standardLot() {
    return this.stock?.standard_lot ?? 100;
  }
  get negotiationLot() {
    return this.stock?.standard_lot ?? 100;
  }
  get vlClosePrice() {
    return `${this.stock?.stockVariation?.vl_close || 0}`;
  }
  get incrementValue() {
    return this.stock?.standard_lot ?? 1;
  }
  get incrementPrice() {
    return getIncrementValue(this.stock);
  }
  get cdSegment() {
    return this.stock?.cd_segment ?? '';
  }
  get dsAsset() {
    return this.stock?.ds_asset ?? '';
  }
  get cdStock() {
    return this.stock?.cd_stock ?? '';
  }
  loading!: any;
  get tickSize(): number {
    if (this.stock && isCrypto(this.stock.ds_type!)) {
      return 0;
    }
    return this.stock ? this.stock.tick_size_denominator ?? 2 : 2;
  }
  public orderParamHelper = ORDER_PARAM_HELPER;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private dynamicControlService: DynamicControlService,
    private stockChartService: StockChartService,
    private dayTradeService: DaytradeService,
    private cdr: ChangeDetectorRef,
    private _stockPreferencesService: StockPreferencesService,
    private originAnalysisOrderService: OriginAnalysisOrderService,
    private _mbService: MultibrokerService
  ) {}

  ngOnInit() {
    this.loading = this.stockChartService.loading;
    this.stockChartService.loadingButton$.subscribe((data) => {
      if (data) {
        this.loading[data.type] = data.bool;
        this.cdr.detectChanges();
      }
    });
    this._initSubscription();
  }

  ngOnDestroy() {
    this._subscriptions && this._subscriptions.unsubscribe();
  }

  private makeInfoCustody() {
    const tickSizeDenominator = this.stock?.tick_size_denominator ?? 2;
    this.setDict(this._userCustody, 'custodyQtty', 'Custódia', 0, false);
    this.setDict(
      this._userCustody,
      'vlPriceAvg',
      'Médio',
      tickSizeDenominator,
      false
    );
    this.setDict(
      this._userCustody,
      'pnlOpen',
      'P&L abr',
      tickSizeDenominator,
      true
    );
    this.setDict(
      this._userCustody,
      'pnlDay',
      'P&L dia',
      tickSizeDenominator,
      true
    );
    this.setDict(
      this._userCustody,
      'pnlValue',
      'P&L tot',
      tickSizeDenominator,
      true
    );
    this.setDict(
      this._userCustody,
      'plValue',
      'Valor tot',
      tickSizeDenominator,
      true
    );
  }

  private instanceForm() {
    this.updateConfig();
    const precision =
      this.dsAsset === 'WDO' || this.dsAsset === 'DOL' ? 1 : this.tickSize;
    const mask = `separator.${precision}`;
    const orderForm = new TigerChartOrdersForm(
      this.dynamicControlService,
      this.configs.standardLot,
      this.configs.negotiationLot,
      this.configs.incrementValue,
      this.configs.vlClosePrice,
      mask
    );
    this.fieldsForm = orderForm.createFields();
    this.form = orderForm.createForm();
    this.form.valueChanges.pipe(debounceTime(20)).subscribe((data) => {
      this.fastOrdersClick.emit({ type: TYPE_CLICK.UPDATE_FORM, ...data });
      this.qttyChange(data);
      this.priceChange(data);
    });
    this.getPreferencesStock();
    this.cdr.detectChanges();
  }

  private updateConfig() {
    this.configs = {
      standardLot: this.standardLot,
      negotiationLot: this.negotiationLot,
      incrementValue: this.incrementValue,
      vlClosePrice: this.vlClosePrice,
      cdSegment: this.cdSegment,
      dsAsset: this.dsAsset,
      lastPrice: this.lastPrice,
      cdStock: this.cdStock,
      idBroker: '',
    };
  }

  sendClick(type: TYPE_CLICK, origin?: string) {
    if (this.disableButtons) return;
    origin && this.originAnalysisOrderService.setOriginOrder(origin);
    this.fastOrdersClick.emit({
      type,
      price: this.form.value.price,
      qtde: this.form.value.qtde,
      fastOrder: true,
      isStartStop: false,
    });
  }

  private labelOrder(sideOrders: TYPE_CLICK) {
    if (sideOrders === TYPE_CLICK.BUY) {
      // if (this.priceControl.value > this.lastPrice) {
      //   return 'Comprar Stop';
      // }
      // return 'Comprar Limitada';
      return 'Comprar';
    }
    // if (this.priceControl.value > this.lastPrice) {
    //   return 'Vender Limitada';
    // }
    // return 'Vender Stop';
    return 'Vender';
  }

  private setDict(
    userCustody: any,
    field: string,
    label: string,
    tickSize: number,
    useClass: boolean
  ) {
    this.custodyDict.delete(field);
    const value = !userCustody || !userCustody[field] ? 0 : userCustody[field];
    this.custodyDict.set(field, {
      label: label,
      value: formatNumber(value, this.locale, `1.${tickSize}-${tickSize}`),
      class: useClass ? this.getCustodyClass(value) : '',
    });
    this.cdr.detectChanges();
  }

  private getCustodyClass(value: number): string {
    let color = '';
    if (value > 0) {
      color = 'text-feedback-success';
    }
    if (value < 0) {
      color = 'text-feedback-error';
    }
    return color;
  }

  private _initSubscription(): void {
    this._subscriptions.add(
      this.dayTradeService.dayTradeMode
        .pipe(filter((isEnable) => isEnable !== this.dayTradeModeIsEnable))
        .subscribe(this.updateInvertAndResetTooltips)
    );
    this._subscriptions.add(
      this._mbService.onUpdateSelectedAccountChannel().subscribe((account) => {
        this.accountSelected = account;
        this.updateInvertAndResetTooltips(this.dayTradeModeIsEnable);
      })
    );
    this._subscriptions.add(
      this.stockChartService.blockChartButtons$
        .pipe(filter((data) => data.ref === this.refComponent))
        .subscribe((data) => {
          this.disableButtons = data.isBlock;
          this.cdr.detectChanges();
        })
    );
  }

  updateInvertAndResetTooltips = (isEnable: boolean) => {
    this.dayTradeModeIsEnable = isEnable;
    const label = isEnable ? 'ON' : 'OFF';
    this.invertAndResetTooltips = {
      invert: DaytradeService.getTextWithTradeMode(
        SUPER_DOM_TOOLTIPS[`INVERT_DAYTRADE_${label}`]
      ),
      reset: DaytradeService.getTextWithTradeMode(
        SUPER_DOM_TOOLTIPS[`RESET_DAYTRADE_${label}`]
      ),
      cancel: `${DaytradeService.getTextWithTradeMode(
        SUPER_DOM_TOOLTIPS[`CANCEL_ORDERS_${label}`]
      )} - ${this.cdStock}`,
    };
    this.cdr.detectChanges();
  };

  updatePrice(type: string) {
    const value = this.minPriceIncrement || 1;
    if (type === 'PLUS') {
      const plus = parseFloat(this.priceControl.value) + value;
      this.priceControl.patchValue(plus);
      return;
    }
    const minus = this.priceControl.value - value;
    this.priceControl.patchValue(minus < 0 ? 0 : minus);
  }

  private getPreferencesStock(): void {
    this.qttyChangeEnabled = false;
    const indexField = this.fieldsForm.findIndex(
      (field: any) => field.key === 'qtde'
    );
    this.fieldsForm[indexField].classComponent.replaceAll(
      ' color-modify-qtty',
      ''
    );
    if (this._stockPreferencesService.saveQttyStockEnabled) {
      setTimeout(() => {
        const value = this._stockPreferencesService.getStockSaved(
          this.stock.cd_stock_order,
          this.standardLot,
          'qtty'
        );
        if (this.standardLot !== value) {
          this.form.get('qtde')!.setValue(value);
          this.fieldsForm[indexField].classComponent += ' color-modify-qtty';
        }
        this.qttyChangeEnabled = true;
      }, 200);
    }
    this.updateInvertAndResetTooltips(this.dayTradeModeIsEnable);
  }

  public qttyChange(event: any) {
    if (!this.qttyChangeEnabled) return;
    const qtd = event.qtde;
    this._stockPreferencesService.setStockQtty(
      this.stock.cd_stock_order,
      qtd,
      this.standardLot
    );
    this.updateTotal();
  }

  private priceChange(event: any) {
    if (isNullOrUndefined(event) || isNullOrUndefined(event.price)) return;
    this.labelOrderBuy = this.labelOrder(TYPE_CLICK.BUY);
    this.labelOrderSell = this.labelOrder(TYPE_CLICK.SELL);
    this.updateTotal();
    this.cdr.detectChanges();
  }

  private updateTotal() {
    const contractMultiplier =
      this.stock.vl_contract_multiplier &&
      this.stock.vl_contract_multiplier != -1
        ? this.stock.vl_contract_multiplier
        : 1;
    this.total = `R$ ${formatByTick(
      this.form.get('qtde')!.value *
        this.priceControl.value *
        contractMultiplier
    )}`;
    this.cdr.detectChanges();
  }
}
