<div #stockTableHeader>
  <workspace-doc-header css="w-100" [item]="component">
    <fla-workspace-doc-header-item [enableContent]="true">
      <app-change-view
        [initialStockTableType]="initialStockTableType"
        [onlyIcon]="onlyStockTableIcon"
        [forceDisplayDP]="isChangeViewStep"
        (changeView)="handleView($event)"
        (updateCurrenViewBeforeFirstRender)="setTableView($event)"
      ></app-change-view>
    </fla-workspace-doc-header-item>

    <fla-workspace-doc-header-item [enableContent]="true">
      <app-dropdown
        [idElement]="ELEMENT_IDS.STOCK_TABLE_LISTS"
        [contentVisibility]="toggleDPVisibility ? 'hidden' : 'inherit'"
        (mouseenter)="focusInDP = true"
        (mouseleave)="focusInDP = false"
      >
        <a
          #stockListSelected
          *ngIf="listSelected"
          type="button"
          class="hide-arrow nav-link py-2 px-1 lh-sm round-top dropdown-toggle d-inline-flex align-items-center"
          appDropdownButton
          [rtDropnAutoClose]="false"
          [rtDropnEventOnClicked]="true"
          [rtDropnClose]="dropnAutoClose || !toggleDPVisibility"
          [rtDropnOpened]="toggleDPVisibility"
          [rtDropnForceClose]="forceCloseDP"
          [rtDropnFocusInComponent]="focusInDP"
          [rtDropdownPreventClose]="isChangeViewStep"
          (clickedOutside)="handleDropDownVS()"
        >
          <span
            class="fw-bold stock-list-name d-block text-truncate"
            tooltipPosition="bottom"
            [ngClass]="{ 'small-size': smallListName }"
            [tooltip]="listSelected.nm_stock_list"
            [tooltipHidden]="hideListNameTooltip"
          >
            {{ listSelected.nm_stock_list }}
          </span>
          <fla-icon
            [name]="expandIcon"
            size="icon-size-micro"
            css="text-white"
          ></fla-icon>
        </a>
        <ng-template *ngTemplateOutlet="personal"></ng-template>
        <ng-template *ngTemplateOutlet="movers"></ng-template>
        <ng-template *ngTemplateOutlet="auction"></ng-template>
        <ng-template *ngTemplateOutlet="stocks"></ng-template>
        <ng-container *ngFor="let item of actions">
          <a
            class="dropdown-item"
            type="button"
            (click)="handleListActions(item.enum)"
            [ngClass]="{
              'opacity-medium user-select-none':
                item.onlyPersonalList && (isNotListPersonal || !idList)
            }"
          >
            {{ item.label }}
          </a>
        </ng-container>
      </app-dropdown>
    </fla-workspace-doc-header-item>

    <ng-container *ngIf="displayIndexAndPeriodFilters">
      <fla-workspace-doc-header-item [enableContent]="true">
        <app-movers-header-options
          [idList]="idList"
          [refComponent]="refComponent"
          [currentMoversIndex]="indexValue"
          [currentMoversPeriod]="currentMoversPeriod"
          [useSmallVersion]="moversHeaderAtMinWidth"
          [moversIndexes]="indexList"
          (updateHeaderSize)="onMoversHeaderChangeWidth($event)"
        >
        </app-movers-header-options>
      </fla-workspace-doc-header-item>
    </ng-container>

    <ng-container *ngIf="displayToggleToIndexList">
      <fla-workspace-doc-header-item [enableContent]="true">
        <button
          type="button"
          class="btn btn-icon"
          [tooltip]="indexTooltip"
          (click)="toggleIndexStockListVisibility()"
        >
          <i class="icons text-light icon-size-micro expand-index-icon">
            {{ isStockIndexList ? 'unfold_less' : 'unfold_more' }}
          </i>
        </button>
      </fla-workspace-doc-header-item>
    </ng-container>
  </workspace-doc-header>

  <ng-template
    #dropdownHeader
    let-label="label"
    let-id="id"
    let-active="active"
  >
    <a
      class="dropdown-item arrow position-relative pr-0"
      data-bs-toggle="collapse"
      role="button"
      aria-expanded="false"
      [href]="'#' + id"
      [attr.aria-controls]="id"
    >
      <span [ngClass]="{ 'text-brand-primary': active }">
        {{ label }}
      </span>
    </a>
  </ng-template>

  <ng-template #personal>
    <ng-template
      *ngTemplateOutlet="
        dropdownHeader;
        context: {
          label: 'Pessoais',
          id: 'Personallist',
          active: currentListType === LIST_TYPES.PERSONAL
        }
      "
    ></ng-template>
    <div class="list-max-height">
      <div
        class="collapse"
        [ngClass]="{ show: isOpenListPersonal && !toggleDPVisibility }"
        id="Personallist"
      >
        <ng-container *ngFor="let item of listStocks">
          <ng-container *ngIf="!item?.isNotListPersonal">
            <a
              type="button"
              class="dropdown-item text-truncate"
              tooltipPosition="bottom"
              [tooltip]="item.nm_stock_list"
              [tooltipHidden]="item.nm_stock_list.length < 20"
              [ngClass]="{
                'text-brand-primary':
                  currentListType === LIST_TYPES.PERSONAL &&
                  item.id_stock_list === listSelected?.id_stock_list
              }"
              (click)="selectList(item)"
            >
              {{ item.nm_stock_list }}
            </a>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <hr />
  </ng-template>

  <ng-template #movers>
    <ng-template
      *ngTemplateOutlet="
        dropdownHeader;
        context: {
          label: 'Movers',
          id: 'MoversList',
          active: currentListType === LIST_TYPES.MOVERS
        }
      "
    ></ng-template>
    <div class="list-max-height">
      <div
        class="collapse"
        id="MoversList"
        [ngClass]="{ show: toggleDPVisibility }"
      >
        <ng-container *ngFor="let list of moversList">
          <a
            class="dropdown-item"
            type="button"
            (click)="selectListNotPersonal(list)"
            [ngClass]="{
              'text-brand-primary':
                currentListType === LIST_TYPES.MOVERS &&
                list.id === listSelected?.id_stock_list
            }"
          >
            {{ list.label }}
          </a>
        </ng-container>
      </div>
    </div>
    <hr />
  </ng-template>

  <ng-template #auction>
    <ng-template
      *ngTemplateOutlet="
        dropdownHeader;
        context: {
          label: 'Leilão',
          id: 'auctionList',
          active: currentListType === LIST_TYPES.AUCTION
        }
      "
    ></ng-template>
    <div class="collapse" id="auctionList">
      <ng-container *ngFor="let item of auctionIndexs">
        <a
          [ngClass]="{
            'text-brand-primary':
              currentListType === LIST_TYPES.AUCTION &&
              currentAuctionIndex.value === item.value
          }"
          class="dropdown-item"
          type="button"
          (click)="selectAuctionAndIndex(item)"
        >
          {{ item.value }}
        </a>
      </ng-container>
    </div>
    <hr />
  </ng-template>

  <ng-template #stocks>
    <ng-template
      *ngTemplateOutlet="
        dropdownHeader;
        context: {
          label: 'Ativos',
          id: 'stocksList',
          active: currentListType === LIST_TYPES.PRESET
        }
      "
    ></ng-template>
    <div class="list-max-height index-list">
      <div class="collapse" id="stocksList">
        <ng-container *ngFor="let label of stocksList">
          <a
            class="dropdown-item"
            type="button"
            [ngClass]="{
              'text-brand-primary':
                currentListType === LIST_TYPES.PRESET &&
                presetListSelected === label
            }"
            (click)="selectPresetList(label)"
          >
            {{ label }}
          </a>
        </ng-container>
      </div>
    </div>
    <hr />
  </ng-template>
</div>
