import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IMetadata,
  IWorkSpace,
  IWorkSpaceComponet,
  WorkspaceType,
} from '@core/interface';
import { RestService } from '@shared/services/rest/rest.service';
import { delay, firstValueFrom, map } from 'rxjs';
import { formatDateWorkspace } from 'src/app/utils/utils.framework';
import { sort } from 'src/app/utils/utils.functions';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceApi extends RestService {
  override _url: string = 'api/nitro-ws/v1';
  constructor(http: HttpClient) {
    super(http);
  }

  private serializeComponentMetadata = (item: any) =>
    (item.metadata = JSON.parse(item.metadata as string) as IMetadata<any>);

  update(workspace: Partial<IWorkSpace>) {
    // todo: testar passando componentes no workspace
    const tempWorkspace = { ...workspace, components: [] };
    return this.put<IWorkSpace>(`workspaces/${tempWorkspace.id}`, {
      ...tempWorkspace,
    }).pipe(
      map((res) => res.data),
      delay(250)
    );
  }

  create(data: {
    name: string;
    active: boolean;
    isDefault?: boolean;
    cod?: string;
    type: WorkspaceType;
  }) {
    return this.post<IWorkSpace>('workspaces', data).pipe(
      map((res) => res.data)
    );
  }

  clear(idWorkspace: string) {
    return firstValueFrom(
      this.delete<any>(`components/clear/${idWorkspace}`)
    ).then(() => {
      return true;
    });
  }

  remove(id: string) {
    return this.delete<IWorkSpace>(`workspaces/${id}`).pipe(
      map((res) => res.data)
    );
  }

  clone(id: string, name: string) {
    return this.post<IWorkSpace>(`workspaces/${id}/duplicate`, { name }).pipe(
      map((res) => {
        if (res.data?.components?.length) {
          res.data.components.forEach((component: IWorkSpaceComponet) => {
            if (typeof component.metadata === 'string') {
              component.metadata = JSON.parse(component.metadata);
            }
          });
        }
        return res.data;
      })
    );
  }

  getAllWorkspaces(type: WorkspaceType) {
    return this.get<IWorkSpace[]>('workspaces').pipe(
      map((res) => {
        const response = res.data.reduce((result: IWorkSpace[], item: any) => {
          if (item.type === type) {
            item.dh_insert = formatDateWorkspace(item.data_de_criacao);
            item.dh_last_update = formatDateWorkspace(item.data_de_atualizacao);
            try {
              item.components &&
                item.components.forEach(this.serializeComponentMetadata);
            } catch (error) {
              item.components = [];
            }
            result.push(item);
          }
          return result;
        }, []);
        return sort(response, 'dh_insert') ?? [];
      })
    );
  }
}
