import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { validateCpf, validateRne } from './functions/index';

export function cpfOrRneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const number = control.value;

    // Remove caracteres não numéricos
    const cleanedNumber = number.replace(/\D/g, '');

    // Verifica se é um CPF
    if (cleanedNumber.length === 11 && !validateCpf(cleanedNumber)) {
      return { cpfOrRne: true };
    }

    // Verifica se é um RNE
    if (cleanedNumber.length === 9 && !validateRne(cleanedNumber)) {
      return { cpfOrRne: true };
    }
    // Retorna o erro caso não seja CPF nem RNE válido
    if (cleanedNumber.length < 9 || cleanedNumber.length > 11) {
      return { cpfOrRneInvalid: true };
    }
    // Retorna o erro caso não seja CPF nem RNE válido
    return null;
  };
}
