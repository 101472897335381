import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlaDatePickerComponent } from './datepicker.component';
import { FlaDatepickerDirective } from './directive/datepicker.directive';
import { FlaDatepickerDaysComponent } from './template/days/days.component';
import { FlaDatepickerMonthComponent } from './template/month/month.component';
import { FlaDatepickerYearComponent } from './template/years/year.component';
import { FlaInputModule } from '../input';
import { RocketButtonModule } from '../button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const COMPONENTS = [
  FlaDatepickerDaysComponent,
  FlaDatepickerMonthComponent,
  FlaDatePickerComponent,
  FlaDatepickerDirective,
  FlaDatepickerYearComponent,
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FlaInputModule,
    RocketButtonModule,
  ],
  exports: [COMPONENTS],
  //providers: [RocketCreateComponentService],
})
export class FlaDatepickerModule {}
