import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthComponent } from './auth.component';
import {
  FlaCheckModule,
  FlaIconModule,
  FlaInputModule,
  RocketButtonModule,
} from '@shared/rocket-components/components';
import { FlaFocusModule } from '@shared/directives/focus/focus.module';
import { LogoModule } from '@shared/components/logo/logo.module';
import { AuthRoutes } from './auth.routing';
import { LoginComponent } from './login/login.component';
import { PendingChangesGuard } from '@core/guards/onboard-pending-changes.guard';
import { HelperMiniModalComponent } from '@shared/components/helper-mini-modal/helper-mini-modal.component';
import { TokenComponent } from './token/token.component';
import { LoadingModule } from '@shared/components/loading/loading.module';
import { LogoutMessageComponent } from './login/logout-message/logout-message.component';
import { RecaptchaModule } from './register/recaptcha/recaptcha.module';
import { PasswordBlockedComponent } from './login/password-blocked/password-blocked.component';
import { LoginInfoMessagesComponent } from './login/login-info-messages/login-info-messages.component';
import { LoginBackgroundContentComponent } from './login/login-background-content/login-background-content.component';

@NgModule({
  imports: [
    CommonModule,
    FlaIconModule,
    FlaCheckModule,
    FlaFocusModule,
    FlaInputModule,
    RocketButtonModule,
    FormsModule,
    ReactiveFormsModule,
    LogoModule,
    LoadingModule,
    AuthRoutes,
    RecaptchaModule,
    LoginBackgroundContentComponent,
    LoginInfoMessagesComponent,
  ],
  declarations: [
    AuthComponent,
    LoginComponent,
    LogoutMessageComponent,
    TokenComponent,
    HelperMiniModalComponent,
    PasswordBlockedComponent,
  ],
  exports: [LoginComponent, TokenComponent, HelperMiniModalComponent],
  providers: [PendingChangesGuard],
})
export class AuthModule {}
