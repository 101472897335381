import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[appRtImageFallback]',
})
export class RtImageFallbackDirective {
  @Input() fallbackTemplate!: TemplateRef<any>;

  constructor(
    private _elementRef: ElementRef,
    private viewContainer: ViewContainerRef
  ) {}

  @HostListener('error')
  setFallbackImage() {
    this._elementRef.nativeElement.style.display = 'none';
    this.viewContainer.createEmbeddedView(this.fallbackTemplate);
  }
}
