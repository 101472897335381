import { IHeatmapOptions } from '@shared/components/heatmap-chart/services/lists.service';

export const CHART_OPTIONS: IHeatmapOptions = {
  datalabels: {
    enabled: true,
    align: 'center',
    verticalAlign: 'middle',
    style: {
      textOutline: 'none',
      textOverflow: 'hidden',
      cursor: 'pointer',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontSize: '0.625rem',
      fontWeight: 'normal',
      textAlign: 'center',
    },
  },
  levels: [],
};
