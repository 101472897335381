<rocket-modal>
  <rocket-modal-header [hideCloseButton]="true">
    <h4 class="mb-2 fw-bold">Filtrar tipos de ativo</h4>
    <button (click)="onCloseModal()" class="btn-close"></button>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <p>
      Selecione abaixo os tipos de ativo que você gostaria que aparecesse na
      busca. Para mudar a ordem em que os ativos aparecerão basta clicar e
      arrastar cada um deles.
    </p>
    <div
      *ngIf="!isLoading; else loadingRef"
      class="my-4 drag-group"
      cdkDropListGroup
    >
      <ng-container *ngFor="let type of stockTypes; let index = index">
        <div
          *ngIf="type && type.ds_type"
          class="drag-list"
          cdkDropList
          cdkDropListOrientation="horizontal"
          [cdkDropListData]="{ item: type, index: index }"
          (cdkDropListDropped)="drop($event)"
        >
          <div
            class="drag-item"
            cdkDrag
            cdkDragPreviewContainer="parent"
            cdkDragBoundary=".drag-group"
          >
            <div *cdkDragPlaceholder></div>
            <div>
              <rocket-button
                [css]="type.hidden ? 'unselected-button' : ''"
                [type]="type.hidden ? 'outline' : 'default'"
                [label]="type.ds_type"
                [isSmall]="true"
                (rocketClick)="changeHiddenStatus($event, type)"
              >
              </rocket-button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </app-rocket-modal-body>
  <rocket-modal-footer>
    <rocket-button
      css="mr-3"
      type="fill"
      (rocketClick)="onCloseModal()"
      label="Cancelar"
    >
    </rocket-button>
    <rocket-button type="default" (rocketClick)="onSave()" label="Salvar">
    </rocket-button>
  </rocket-modal-footer>
</rocket-modal>

<ng-template #loadingRef>
  <div class="h-100 d-flex justify-content-center" [style.minHeight.px]="230">
    <app-loading></app-loading>
  </div>
</ng-template>
