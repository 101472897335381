import { Component, Input } from '@angular/core';
import { LOGOUT_REASON } from '@shared/services/core/const/local-storage.const';

@Component({
  selector: 'app-logout-message',
  templateUrl: './logout-message.component.html',
})
export class LogoutMessageComponent {
  @Input() message!: string | false;

  get logoutReason() {
    return localStorage.getItem(LOGOUT_REASON);
  }
}
