import { Component, Input } from '@angular/core';

@Component({
  selector: 'error-card',
  template: `
    <div class="card border" [ngClass]="[cardMargin, cardBorder]">
      <div class="card-body">
        <h5 class="card-title">{{ title }}</h5>
        <p class="card-text m-0">{{ message }}</p>
      </div>
    </div>
  `,
})
export class ErrorCardComponent {
  @Input() cardBorder: string = 'border-feedback-warning';
  @Input() cardMargin: string = 'mb-0';
  @Input() title: string = 'Ops! 🤔';
  @Input() message: string = 'Ocorreu um erro';

  constructor() {}
}
