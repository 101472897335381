export enum TYPE_CONFIG {
  CLICK = 'CLICK',
  OPTIONS = 'OPTIONS',
  COLOR_PICKER = 'COLOR_PICKER',
}

export enum TYPE_VALUE {
  STROKE_THICKNESS = 'STROKE_THICKNESS',
  STROKE_DASHARRAY = 'STROKE_DASHARRAY',
  STROKE_WIDTH = 'STROKE_WIDTH',
  MODELS = 'MODELS',
  DIVIVER = 'DIVIVER',
}

export enum TYPE_CLICK {
  DELETE = 'DELETE',
  CONFIG = 'CONFIG',
}

export enum TYPE_EVENT {
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
  EDIT = 'EDIT',
}

export enum CONFIG {
  MODELS = 'Modelos',
  LINE_COLOR = 'Cores das ferramentas de linha',
  CENTER_LINE_COLOR = 'Cores das ferramentas de linha central',
  BACKGROUND_COLOR = 'Fundo da ferramenta de linha',
  TEXT_COLOR = 'Cor do Texto',
  FONT_SIZE = 'Tamanho da fonte',
  LINE_WIDTH = 'Largura da linha',
  LINE_STYLE = 'Estilo',
  CONFIGURATION = 'Configurações',
  DELETE = 'Remover',
}
