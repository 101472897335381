import { TIGER_INTERVAL_ENUM } from '@shared/tiger-chart/enum';
import {
  IConditional,
  IScreeningFilter,
} from '../interfaces/indicator.interface';
import { randomId } from '@shared/rocket-components/utils';

export const RELATION = [
  {
    id: 1,
    label: '=',
    description: 'igual a',
  },
  {
    id: 2,
    label: '>',
    description: 'maior que',
  },
  {
    id: 3,
    label: '<',
    description: 'menor que',
  },
  {
    id: 4,
    label: '>=',
    description: 'maior ou igual a',
  },
  {
    id: 5,
    label: '<=',
    description: 'menor ou igual a',
  },
];

export const DATA_POINT = [
  {
    id: 'open',
    label: 'Abe',
    name: 'Abertura',
    checked: false,
  },
  {
    id: 'close',
    label: 'Fch',
    name: 'Fechamento',
    checked: false,
  },
  {
    id: 'high',
    label: 'Max',
    name: 'Máximo',
    checked: false,
  },
  {
    id: 'low',
    label: 'Min',
    name: 'Mínimo',
    checked: false,
  },
];

export const SELECT_CONDITIONS: string[] = [
  'QUOTATION',
  'INDICATOR',
  'CUSTOM',
  'CANDLE_PATTERNS',
];

export const CANDLE_PATTERNS: string[] = ['hammer', 'inv_hammer', 'doji'];

export const VIEW_SCREENING = ['CONDITIONS', 'EXECUTION'];

export const DEFAULT: IConditional = {
  leftSide: {
    value: '',
    description: '',
    filter: null,
    type: 'INDICATOR',
  },
  relation: '=',
  rightSide: {
    value: '',
    description: '',
    filter: null,
    type: 'QUOTATION',
  },
};

export const SCREENING_BASE: IScreeningFilter = {
  conditional: [],
  name: '',
  id: randomId('id'),
  isStart: false,
  stocks: [],
  idScreening: 0,
  intervalChart: '',
  tickSize: {},
};

export const FILTERS_INDICATORS_GROUP = [
  {
    label: 'Médias Móveis',
    open: true,
    indicators: [
      {
        id: 'Média Móvel',
        name: 'Média Móvel (MA)',
        descriptiom:
          'Provavelmente um dos indicadores mais utilizados. O indicador calcula a média dos dados entre a cotação atual e um período anterior.',
        type: 'ma',
        values: { time_period: 9, data_point: 'open', ma_type: 'sma' },
      },
      {
        id: 'Média Móvel Exponencial',
        name: 'Exponencial (EMA)',
        descriptiom:
          'Com base na Média Móvel (MA), a EMA dá mais importância aos preços recentes, mesmo que a taxa de queda entre o preço atual e o preço anterior seja inconsistente.',
        type: 'ema',
        values: { time_period: 9, data_point: 'close', ma_type: 'ema' },
      },
      {
        id: 'Média Móvel Ponderada',
        name: 'Ponderada (WMA)',
        descriptiom:
          'WMA (Weighted Moving Average) é uma média móvel ponderada que dá peso maior aos preços mais recentes e peso menor aos preços mais antigos.',
        type: 'wma',
        values: { time_period: 9, data_point: 'close', ma_type: 'wma' },
      },
      {
        id: 'Média Móvel Exponencial Dupla',
        name: 'Exp. Dupla (DEMA)',
        descriptiom:
          'A Média Móvel Exponencial Dupla (DEMA) foi desenvolvida com o objetivo de reduzir o atraso e aumentar a capacidade de resposta. Essa média móvel de ação rápida permite que os traders identifiquem as mudanças de tendência rapidamente, resultando em melhores entradas nas tendências recém-formadas.',
        type: 'dema',
        values: { time_period: 9, data_point: 'close', ma_type: 'dema' },
      },
      {
        id: 'Média Móvel Exponencial Tripla',
        name: 'Exp. Tripla (TEMA)',
        descriptiom:
          'O TEMA tem como marca registrada a suavização diante da tripla média móvel exponencial. Em uma primeira impressão, pode parecer semelhante ao MACD, mas que diferencia-se por filtrar oscilações e captar movimentos de tendência de forma antecipada.',
        type: 'tema',
        values: { time_period: 9, data_point: 'close', ma_type: 'tema' },
      },
      {
        id: 'Média Móvel de Kaufman',
        name: 'Adapt. de Kaufman (KAMA)',
        descriptiom:
          'O poderoso indicador de tendência KAMA baseia-se na Média Móvel Exponencial (EMA) e responde tanto à tendência quanto à volatilidade. Ele segue o preço quando o ruído é baixo e suaviza o ruído quando o preço flutua.',
        type: 'kama',
        values: { time_period: 21, data_point: 'close', ma_type: 'kama' },
      },
      {
        id: 'Média Móvel Triangular',
        name: 'Média Móvel Triangular (TRIMA)',
        descriptiom:
          'A Média Móvel triangular (TRIMA) é um indicador técnico semelhante a outras médias móveis. O TRIMA mostra o preço médio de um ativo em um número especificado de pontos de dados, geralmente várias barras de preços.No entanto, a média móvel triangular difere na medida em que é suavizada duas vezes, o que também significa média duas vezes.',
        type: 'trima',
        values: { time_period: 10, data_point: 'close', ma_type: 'trima' },
      },
    ],
  },
  {
    label: 'Tendências',
    open: true,
    indicators: [
      {
        id: 'SAR Parabólico',
        name: 'SAR Parabólico (SAR)',
        descriptiom:
          'O SAR Parabólico (SAR) é uma ferramenta de preço e tempo usada principalmente para identificar pontos de possíveis paradas e reversões. A palavra "SAR" significa "Stop and Reverse (Parada e Reversão)". Os cálculos do indicador criam uma parábola que está localizada abaixo do preço durante uma tendência de alta e acima do preço durante uma tendência de baixa.',
        type: 'sar',
        values: { acceleration: '0.02', data_point: 'close', maximum: 0.2 },
      },
    ],
  },
  {
    label: 'Osciladores',
    open: true,
    indicators: [
      {
        id: 'Índice de Força Relativa',
        name: 'Índice de Força Relativa (RSI)',
        descriptiom:
          'O Índice de Força Relativa (RSI) é um oscilador baseado em impulso bem versado que é usado para medir a rapidez (velocidade), bem como a mudança (magnitude) dos movimentos de preços direcionais.',
        type: 'rsi',
        values: { time_period: 14 },
      },
      {
        id: 'Média de Amplitude de Variação',
        name: 'Média de Amplitude de Variação (ATR)',
        descriptiom:
          'O Average True Range (ATR) é uma ferramenta usada na análise técnica para medir a volatilidade. Ao contrário de muitos dos indicadores populares de hoje, o ATR não é usado para indicar a direção do preço.',
        type: 'atr',
        values: { time_period: 14 },
      },
      {
        id: 'Oscilador de Preço',
        name: 'Oscilador de Preço (PPO)',
        descriptiom:
          'O Indicador do Oscilador Preço (PPO) é usada para medir o momentum que é muito semelhante ao MACD. O objetivo do oscilador de preços é derivar informações objetivas de operação através da relação de spread (diferença) entre duas médias móveis de períodos distintos.',
        type: 'ppo',
        values: { slow_period: 10, fast_period: 21 },
      },
      {
        id: 'Momentum',
        name: 'Momentum (MOM)',
        descriptiom:
          'Este indicador mede a velocidade com que os preços variam em relação aos níveis de preços atuais de um determinado ativo. Desta forma, o momentum é derivado da contínua diferença de preços para um determinado intervalo de tempo pré-definido.',
        type: 'mom',
        values: { time_period: 10, data_point: 'close' },
      },
    ],
  },
  {
    label: 'Padrões de Candle',
    open: true,
    indicators: [
      {
        id: 'Martelo',
        name: 'Martelo (Hammer)',
        descriptiom:
          'O Martelo é um padrão de alta que sinaliza a reversão de um movimento de baixa do preço para um movimento de alta.',
        type: 'hammer',
        values: { data_point: 'close' },
      },
      {
        id: 'Martelo Invertido',
        name: 'Martelo Invertido (Inverted Hammer)',
        descriptiom:
          'Ele ocorre sempre em uma tendência de baixa e sinaliza uma possível reversão para uma tendência de alta, seguindo alguns dos princípios básicos da Teoria de Dow.',
        type: 'inv_hammer',
        values: { data_point: 'close' },
      },
      {
        id: 'Doji',
        name: 'Doji (Doji)',
        descriptiom:
          'O doji é um candle, ou vela, que representa um momento de indecisão no mercado. Ou seja, nessa representação, o preço de fechamento de um ativo será igual ao de abertura do mesmo. Ele é representado por uma linha ou traço horizontal.',
        type: 'doji',
        values: { data_point: 'close' },
      },
    ],
  },
  {
    label: 'Preço',
    open: true,
    indicators: [
      {
        id: 'Preço Médio',
        name: 'Preço Médio (Average Price)',
        descriptiom:
          'Esse indicador é o preço médio de um ativo observado durante um período de tempo. É calculado encontrando a média aritmética simples dos preços de fechamento durante um período de tempo especificado.',
        type: 'avg_price',
        values: { data_point: 'close' },
      },
      {
        id: 'Mediana',
        name: 'Mediana (Median Price)',
        descriptiom:
          'O indicador Median Price é uma ferramenta que pode ser usada para medir a direção e a volatilidade do mercado. Ele forma um canal com base em como o ATR se desvia do meio do intervalo. O indicador exibe o valor mediano da média entre alto e baixo para o comprimento especificado.',
        type: 'median_price',
        values: { data_point: 'close' },
      },
      // {
      //   id: 'Preço Típico',
      //   name: 'Preço Típico (Typical Price)',
      //   descriptiom:
      //     'O preço típico é o resultado de se fazer uma média aritmética entre o preço máximo, o preço mínimo e o preço de fechamento de um ativo financeiro. O preço típico é um cálculo amplamente utilizado em indicadores técnicos.',
      //   type: 'typical_price',
      //   values: null,
      // },
    ],
  },
];

export const TYPES_MA: any = {
  sma: 'ma',
  ema: 'ma',
  wma: 'ma',
  dema: 'ma',
  tema: 'ma',
  kama: 'ma',
  trima: 'ma',
  mama: 'ma',
  t3: 'ma',
};

export const INTERVAL_SCREENING_CHART = [
  {
    id: TIGER_INTERVAL_ENUM.ONE_MINUTE,
    name: '1 minuto',
    short: '1m',
    apiKeyCode: '1m',
    limit: 500,
    repositionType: 'unit',
    repositionTimer: 1,
  },
  {
    id: TIGER_INTERVAL_ENUM.TWO_MINUTE,
    name: '2 minutos',
    short: '2m',
    apiKeyCode: '2m',
    limit: 500,
    new: false,
    repositionType: 'unit',
    repositionTimer: 2,
  },
  {
    id: TIGER_INTERVAL_ENUM.FIVE_MINUTE,
    name: '5 minutos',
    short: '5m',
    apiKeyCode: '5m',
    limit: 500,
    repositionType: 'unit',
    repositionTimer: 5,
  },
  {
    id: TIGER_INTERVAL_ENUM.FIFTEEN_MINUTE,
    name: '15 minutos',
    short: '15m',
    apiKeyCode: '15m',
    limit: 500,
    repositionType: 'unit',
    repositionTimer: 15,
  },
  {
    id: TIGER_INTERVAL_ENUM.THIRTY_MINUTE,
    name: '30 minutos',
    short: '30m',
    apiKeyCode: '30m',
    limit: 500,
    repositionType: 'unit',
    repositionTimer: 30,
  },
  {
    id: TIGER_INTERVAL_ENUM.ONE_HOUR,
    divider: true,
    name: '1 hora',
    short: '1h',
    apiKeyCode: '1h',
    limit: 800,
    repositionType: 'unit',
    repositionTimer: 60,
  },
  // {
  //   id: TIGER_INTERVAL_ENUM.ONE_HOUR,
  //   name: '4 hora',
  //   short: '4h',
  //   divider: true,
  //   date: {
  //     start: ISODateString(updateDate(new Date(), 1, 'month', 'remove')),
  //     end: ISODateString(new Date()),
  //   },
  // },
  {
    id: TIGER_INTERVAL_ENUM.ONE_DAY,
    name: '1 dia',
    short: '1d',
    apiKeyCode: '1d',
    limit: 1000,
    repositionType: 'day',
    repositionTimer: 1440,
  },
  {
    id: TIGER_INTERVAL_ENUM.ONE_WEEK,
    name: '1 semana',
    short: '1S',
    apiKeyCode: '1w',
    limit: 1000,
    repositionType: 'week',
    repositionTimer: 10080,
  },
  {
    id: TIGER_INTERVAL_ENUM.ONE_MONTH,
    name: '1 mês',
    short: '1M',
    apiKeyCode: '1M',
    limit: 1000,
    repositionType: 'month',
    repositionTimer: 43800,
  },
];
