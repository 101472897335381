<ng-template>
  <div class="card" [ngClass]="customClass">
    <div class="row g-0">
      <div class="mr-3" [ngClass]="col">
        <img [src]="src" class="img-fluid round" />
      </div>
      <div class="col">
        <ng-content select=".card-body"></ng-content>
      </div>
    </div>
  </div>
</ng-template>
