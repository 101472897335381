import { Injectable } from '@angular/core';
import { Dictionary } from '@core/models';
import * as Mousetrap from 'mousetrap';

@Injectable()
export class HotkeysService {
  private mouseTrap: Mousetrap.MousetrapInstance = new Mousetrap();
  hashMouseTrap = new Dictionary<Mousetrap.MousetrapInstance>();
  listener(data: IHotkeys) {
    if (data.handlerDown) {
      this.bindEvent(data.key, data.handlerDown, 'keydown', data.elementId);
    }
    if (data.handlerUp) {
      this.bindEvent(data.key, data.handlerUp, 'keyup', data.elementId);
    }
  }

  unBindEvent(data: IHotkeys) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (data.actions) {
      const actions = Array.isArray(data.actions)
        ? data.actions
        : [data.actions];
      actions.forEach((action) => {
        const mouseTrap = data.elementId
          ? this.hashMouseTrap.get(data.elementId)!
          : this.mouseTrap;
        mouseTrap.unbind(data.key, action);
      });
      return;
    }
  }

  unBindEvents(data: IHotkeys[]) {
    data.forEach((hotkey) => {
      this.unBindEvent(hotkey);
    });
  }

  bindEvents(data: IHotkeys[]) {
    data.forEach((hotkey) => {
      this.listener(hotkey);
    });
  }

  private bindEvent(
    key: string | string[],
    handler: any,
    eventType: 'keydown' | 'keyup',
    elementId?: string
  ) {
    if (elementId) {
      const doc = document.getElementById(elementId);
      const mouseTrap =
        this.hashMouseTrap.get(elementId) ?? new Mousetrap(doc!);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mouseTrap.bind(
        key,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (e) => {
          handler(e);
          return false;
        },
        eventType
      );
      doc?.setAttribute('tabindex', '0');
      this.hashMouseTrap.set(elementId, mouseTrap);
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.mouseTrap.bind(
      key,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (e) => {
        handler(e);
        return false;
      },
      eventType
    );
  }
}

export interface IHotkeys {
  key: string | string[];
  actions: string | string[];
  elementId?: string;
  handlerDown?: any;
  handlerUp?: any;
}
