import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BusinessProfileService } from '../../business-profile.service';
import { ISearchStock } from '@core/interface';
import * as Highcharts from 'highcharts';
import Treemap from 'highcharts/modules/treemap';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import { Subject, auditTime } from 'rxjs';

Treemap(Highcharts);

@Component({
  selector: 'app-distribution',
  templateUrl: './distribution.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DistributionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() refComponent!: string;
  @Input() set stockSelected(stockSelected: ISearchStock) {
    if (stockSelected) {
      this._stockSelected = stockSelected;
      const { stockVariation } = stockSelected;
      this.dayVariation = stockVariation?.day_variation ?? 0;
      this.getTreemap();
    }
  }
  @Input() height!: string | number;
  @Input() width!: string | number;
  private updateSizeGraphic$ = new Subject<void>();
  private _stockSelected!: ISearchStock;
  private dayVariation = 0;
  public loading = true;
  public companyInfo!: any[] | undefined;
  public widthGraphic!: number;
  public heightGraphic!: number;

  constructor(
    private cdr: ChangeDetectorRef,
    private businessProfileService: BusinessProfileService
  ) {}

  ngOnInit(): void {
    this.updateSizeGraphic$
      .pipe(auditTime(250))
      .subscribe(() => this.sizeGraphic());
  }

  ngOnChanges(): void {
    this.updateSizeGraphic$.next();
  }

  ngOnDestroy(): void {
    this.updateSizeGraphic$?.unsubscribe();
  }

  private sizeGraphic(): void {
    const temp = document.getElementsByClassName('distribution')[0];
    const values = temp.getClientRects()[0];
    this.heightGraphic = values.height;
    this.widthGraphic = values.width;
    this.initGraph();
  }

  private getTreemap() {
    this.businessProfileService
      .treemap(this._stockSelected)
      .subscribe((data: any) => {
        this.companyInfo = data.children.map((item: any) => {
          return {
            name: this.replaceName(item.name),
            value: item.variation,
            tooltip: item.tooltip,
            color: this.setColor(item),
          };
        });
        this.initGraph();
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  private replaceName(name: string): string {
    const reg: string[][] = [
      [' ', '<br>'],
      ['+', ''],
      ['.', ','],
    ];
    let value = name;
    for (let i = 0; reg.length > i; i++) {
      value = value.replace(reg[i][0], reg[i][1]);
    }
    return value;
  }

  private setColor(item: any): string {
    if (item.group === 'positive') {
      if (this.dayVariation < 0) {
        return '#0fef83';
      }
      return item.variation >= this.dayVariation ? '#0fef83' : '#86FFC5';
    }
    if (this.dayVariation > 0) {
      return '#d30d48';
    }
    return item.variation >= this.dayVariation ? '#d30d48' : '#e66f87';
  }

  private initGraph() {
    Highcharts.chart(this.refComponent, {
      loading: {
        labelStyle: {
          opacity: 0,
        },
        style: {
          backgroundColor: CHART_COLORS.NEUTRAL_STRONG,
        },
      },
      credits: {
        enabled: false,
        style: {
          display: 'none',
          margin: 0,
          padding: '0',
        },
      },
      tooltip: {
        animation: false,
        useHTML: true,
        className: 'heatmap-box-tooltip',
        backgroundColor: CHART_COLORS.NEUTRAL_STRONGEST,
        borderColor: CHART_COLORS.NEUTRAL_STRONGEST,
        borderRadius: 4,
        borderWidth: 1,
        padding: 0,
        hideDelay: 0,
        outside: true,
        style: {
          zIndex: 15000001,
          color: CHART_COLORS.NEUTRAL_SMOOTHEST,
          background: CHART_COLORS.NEUTRAL_STRONGEST,
          opacity: 1,
          padding: '0',
        },
        formatter() {
          const point: any = this.point;
          return `<div class="bg-neutral-strong text-body p-2">${point.tooltip}</div>`;
        },
      },
      series: [
        {
          type: 'treemap',
          dataLabels: {
            style: {
              textOutline: 'none',
              fontSize: '0.625rem',
              fontWeight: 'normal',
            },
          },
          allowTraversingTree: false,
          breadcrumbs: {
            showFullPath: false,
            useHTML: true,
            style: {
              paddingRight: 10,
              paddingLeft: 10,
            },
            buttonTheme: {
              color: CHART_COLORS.NEUTRAL_STRONGEST,
              fill: CHART_COLORS.BRAND_PRIMARY,
              style: {
                color: CHART_COLORS.NEUTRAL_STRONGEST,
              },
            },
            position: {
              y: 12,
            },
            floating: true,
          },
          states: {
            hover: {
              enabled: false,
            },
          },
          cursor: 'pointer',
          animation: false,
          borderColor: CHART_COLORS.NEUTRAL_STRONGEST,
          colorAxis: 0,
          layoutStartingDirection: 'horizontal',
          clip: true,
          layoutAlgorithm: 'squarified',
          data: this.companyInfo,
        },
      ],
      chart: {
        backgroundColor: CHART_COLORS.OPACITY_BACKGROUND,
        animation: false,
        marginBottom: 10,
        width: this.widthGraphic,
        height: this.heightGraphic,
        zooming: {
          type: 'xy',
          pinchType: 'x',
          resetButton: {
            theme: {
              fill: CHART_COLORS.BRAND_PRIMARY,
              stroke: CHART_COLORS.BRAND_PRIMARY,
              style: {
                color: CHART_COLORS.BLACK,
              },
              r: 5,
              states: {
                hover: {
                  fill: CHART_COLORS.BRAND_PRIMARY,
                  zIndex: 9999999,
                  style: {
                    color: CHART_COLORS.BLACK,
                  },
                },
              },
            },
          },
        },
      },
      title: {
        text: '',
      },
    });
  }
}
