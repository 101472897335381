import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsTabComponent } from './details-tab.component';
import { FlaBigNumberModule } from '@shared/rocket-components/pipes/big-number';
import { AuctionTooltipDirective } from '@shared/rocket-components/directives/auction-tooltip/auction-tooltip.directive';
import { FormatTickSizeModule } from '@shared/rocket-components/pipes/format-tick-size';

@NgModule({
  imports: [
    CommonModule,
    FlaBigNumberModule,
    AuctionTooltipDirective,
    FormatTickSizeModule,
  ],
  declarations: [DetailsTabComponent],
  exports: [DetailsTabComponent],
})
export class DetailsTabModule {}
