<div class="w-100 wrapper" [style.height.px]="gridHeight()">
  <ng-container *ngIf="withoutData">
    <div
      class="align-items-center bg-neutral-strongest hstack position-absolute w-100 mt-7 options-empty-state"
    >
      <span class="d-block text-center w-100">Sem dados para mostrar!</span>
    </div>
  </ng-container>

  <form
    #formElement
    [formGroup]="form"
    class="row mx-0 pt-1 pb-2"
    [ngClass]="{ 'dark-theme': isDarkTheme() }"
  >
    <div *ngIf="isTable()" class="col-2">
      <fla-select
        formControlName="type"
        bindLabel="label"
        bindValue="id"
        margin="m-0"
        [isSmall]="true"
        [list]="types"
        [searchable]="false"
        (flaChange)="filterChange()"
      ></fla-select>
    </div>
    <div *ngIf="isTable()" class="col-3">
      <fla-select
        formControlName="style"
        bindLabel="label"
        bindValue="id"
        margin="m-0"
        [isSmall]="true"
        [list]="styles"
        [searchable]="false"
        (flaChange)="filterChange()"
      ></fla-select>
    </div>
    <div class="col-3">
      <fla-select
        formControlName="status"
        bindLabel="label"
        bindValue="id"
        margin="m-0"
        [isSmall]="true"
        [list]="statusList"
        [searchable]="false"
        (flaChange)="filterChange()"
      ></fla-select>
    </div>
    <div [tooltip]="tooltipDate" class="col-3">
      <fla-select
        formControlName="date"
        bindLabel="twoDigitsYearLabel"
        bindValue="id"
        margin="m-0"
        [isSmall]="true"
        [list]="expirationDates"
        [searchable]="false"
        (flaChange)="filterChange()"
      ></fla-select>
    </div>
    <div *ngIf="isTable()" class="col-4 py-1">
      <app-check
        css="fs-5"
        label="Negociadas"
        formControlName="alreadNegotiated"
        [isChecked]="form.controls['alreadNegotiated'].value"
        (flaChange)="filterChange()"
      ></app-check>
    </div>
    <div
      [ngClass]="
        (isTable() ? 'col-1' : 'col-10') + ' d-flex justify-content-end'
      "
    >
      <rocket-button
        type="textIcon"
        css="min-w-0 pr-0 text-light"
        tooltipPosition="bottom"
        label="Estratégias"
        [isDisabled]="!strategiesHasLegs"
        [isSmall]="true"
        [tooltip]="
          strategiesHasLegs
            ? 'Nova estratégia'
            : 'Sem estratégias para a data selecionada'
        "
        (rocketClick)="showStrategyWizard()"
      >
        <fla-icon
          texticon
          css="text-light p-0"
          size="icon-size-nano"
          name="add_circle_outline"
        ></fla-icon>
      </rocket-button>
    </div>
  </form>

  <ng-container *ngIf="isTable(); else callPutContent">
    <app-options-list-table
      #grid
      class="h-100"
      [refId]="refId"
      [componentId]="componentInfo.id"
      [height]="height"
      [width]="width"
      [stock]="stock"
      [configKey]="configKey"
      [loading]="loadingGridContent || loading"
      [linked]="linked"
    ></app-options-list-table>
  </ng-container>

  <ng-template #callPutContent>
    <app-options-list-callput
      #grid
      class="h-100"
      [refId]="refId"
      [componentId]="componentInfo.id"
      [height]="height"
      [width]="width"
      [stock]="stock"
      [configKey]="configKey"
      [loading]="loadingGridContent || loading"
      [linked]="linked"
    ></app-options-list-callput>
  </ng-template>

  <div #strategy class="w-100 strategy-wrapper">
    <app-options-strategy
      [stock]="stock"
      [expirationDate]="expirationDate()"
      [parentHeight]="height"
    ></app-options-strategy>
  </div>
</div>
