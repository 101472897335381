import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { ORDERS_HISTORY_ELEMENT_IDS } from '../../enum/orders-history.enum';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';
import { OrdersHistoryContextMenuEvent } from '../../service/orders-history-config.service';
import { BaseContextMenuComponent } from '@shared/rocket-components/base-context-menu';

@Component({
  selector: 'app-order-history-header-config',
  template: `<div
    class="round bg-neutral-strong position-absolute fs-7"
    [id]="elementIDS.MENU_CONTEXT"
    [ngClass]="{ 'pr-none': isTour }"
    [style.width]="'210px'"
    [style.padding]="'10px'"
    [style.top.px]="position.top"
    [style.left.px]="position.left"
    [style.z-index]="9999"
    [style.display]="isTour ? 'none' : 'block'"
    (mouseleave)="onFocusOut()"
  >
    <div class="list-group list-group-flush">
      <div class="header-context-menu">
        <span>{{ title }}</span>
      </div>

      <button
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="hiddenTab()"
        [id]="elementIDS.HIDE_COLUMN"
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="visibility_off"
        ></fla-icon>
        {{ hiddenContentLabel }}
      </button>
      <button
        [id]="elementIDS.PREFERENCES"
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="openModalPreferences()"
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="settings"
        ></fla-icon>
        Configurações
      </button>

      <hr class="separator-context-menu" />

      <ng-container *ngIf="hasExportBtn">
        <button
          type="button"
          class="list-group-item list-group-item-action context-item-button"
          (click)="exportData()"
        >
          Exportar Dados
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="download"
          ></fla-icon>
        </button>
        <hr class="separator-context-menu" />
      </ng-container>

      <section class="d-flex flex-column">
        <button
          type="button"
          class="list-group-item list-group-item-action border-none fs-6 context-item-button"
          (click)="resetDefault()"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="refresh"
          ></fla-icon>
          Restaurar
        </button>

        <button
          type="button"
          class="list-group-item list-group-item-action border-none fs-6 context-item-button"
          [disabled]="true"
          (click)="tourHandler()"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="help_outline"
          ></fla-icon>
          Ajuda
        </button>
      </section>
    </div>
  </div>`,
})
export class OrderHistoryHeaderConfigComponent extends BaseContextMenuComponent {
  refComponent!: string;
  componentId!: string;
  isVisible!: boolean;
  tabInfs!: any;
  hasExportBtn = false;
  isTour: boolean = false;
  elementIDS = ORDERS_HISTORY_ELEMENT_IDS;
  title: string = '';
  hiddenContentLabel: string = '';
  parent_id!: string;

  constructor(
    elementRef: ElementRef,
    cdr: ChangeDetectorRef,
    private contextMenuService: ContextMenuService
  ) {
    super(elementRef, cdr);
  }

  static openContextMenu(
    contextMenuService: ContextMenuService,
    parent_id: string,
    event: { clientX: number; clientY: number },
    isTour: boolean,
    isVisible: boolean,
    tabInfs: any,
    hasExportBtn: boolean
  ) {
    if (isTour) {
      const domRect = document
        .getElementsByTagName('app-orders-history')[0]
        .getClientRects()[0];
      event.clientX = domRect.x;
      event.clientY = domRect.y + 100;
    }
    contextMenuService.openContextMenu(parent_id, {
      type: 'OrderHistoryHeaderConfigComponent',
      event: event,
      configurationData: {
        isTour,
        isVisible,
        hasExportBtn,
        title: `${tabInfs.label}`,
        hiddenContentLabel: `Ocultar ${tabInfs.label}`,
      },
    });
  }

  private emitEvent(action: OrdersHistoryContextMenuEvent) {
    this.contextMenuService.emitEvent(this.parent_id, action);
    this.onFocusOut();
  }

  onFocusOut() {
    this.contextMenuService.closeContextMenu(this.parent_id);
  }

  hiddenTab(): void {
    this.emitEvent(OrdersHistoryContextMenuEvent.hideTab);
  }

  resetDefault(): void {
    this.emitEvent(OrdersHistoryContextMenuEvent.resetTabsDefault);
  }

  openModalPreferences(): void {
    this.emitEvent(OrdersHistoryContextMenuEvent.openTabPreferences);
  }

  exportData(): void {
    this.emitEvent(OrdersHistoryContextMenuEvent.ExportData);
  }

  tourHandler(): void {
    this.emitEvent(OrdersHistoryContextMenuEvent.TourHandler);
  }
}
