import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ISearchStock } from '@core/interface';
import { SearchStockComponent } from '@shared/components/search-stock/search-stock.component';
import { ComponentsService } from '@shared/services/api/nitro-ws/v1/components.service';
import { HEADERS, MY } from './trade-idea-header.const';
import { system } from '@core/system/system.service';
import { IHeader } from './trade-idea-header.interface';
import { deepClone } from '@shared/rocket-components/utils';
import { WorkspaceHeader } from '@core/workspace/parts/header/workspace-header.service';
import { ConfigService } from '@core/config';

@Component({
  selector: 'app-trade-idea-header',
  templateUrl: './trade-idea-header.component.html',
  styleUrls: ['./trade-idea-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TradeIdeaHeaderComponent implements AfterViewInit {
  @Input() refComponent!: string;
  @Input() public component!: any;
  @Output() changeStock = new EventEmitter<ISearchStock | undefined>();
  @Output() changeMenu: EventEmitter<IHeader> = new EventEmitter<IHeader>();
  @ViewChild('searchStock') searchStock!: SearchStockComponent;
  public stockSelected!: ISearchStock | undefined;
  public headers = deepClone(HEADERS);
  public selectedHeader = this.headers[0];
  showSelectWindow: boolean;

  get statusLink() {
    return this.component.metadata?.headerOptions.link;
  }

  constructor(
    private componentService: ComponentsService,
    private cdr: ChangeDetectorRef,
    private _workspaceHeader: WorkspaceHeader,
    private config: ConfigService
  ) {
    this.showSelectWindow = !this.config.isWebAuthenticationChannel();
  }

  ngAfterViewInit() {
    if (system.packages['TRADEIDEA_CAN_CREATE' as any]) {
      this.headers.push(deepClone(MY));
    }
    if (this.component?.metadata?.component?.stock) {
      this.stockSelected = this.component?.metadata?.component?.stock;
      this.changeStock.emit(this.component?.metadata?.component?.stock);
      this.cdr.detectChanges();
      return;
    } else {
      this.changeStock.emit(undefined);
    }
  }

  public selectStock = (stock: ISearchStock | undefined) => {
    if (stock?.cd_stock === this.stockSelected?.cd_stock) {
      return;
    }
    if (stock) {
      this.stockSelected = stock;
      this.component.metadata.component.stock = stock;
    } else {
      this.stockSelected = undefined;
      this.searchStock.searchLabel = 'Ativo';
      this.component.metadata.component.stock = null;
      if (this.statusLink) {
        this._workspaceHeader.$linkHeaderButton.next(this.component.id);
      }
    }
    this.componentService.updateMetadata(
      this.component.id,
      this.component.metadata
    );
    this.changeStock.emit(stock);
    this.cdr.detectChanges();
  };

  selectView(header: IHeader) {
    this.selectedHeader = header;
    this.changeMenu.emit(header);
    this.cdr.detectChanges();
  }
}
