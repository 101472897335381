export enum TYPE_CHART_UPDATE {
  WATER_MARK_OPACITY = 'WATER_MARK_OPACITY',
  WATER_MARK = 'WATER_MARK',
  GRID_LINES = 'GRID_LINES',
  PRICE_CLOSE_TIMER = 'PRICE_CLOSE_TIMER',
  SHOW_CLOSE_LINE = 'SHOW_CLOSE_LINE',
  CANDLE_BORDER = 'CANDLE_BORDER',
  CANDLE_COLOR = 'CANDLE_COLOR',
  VOLUME_COLOR = 'VOLUME_COLOR',
  SHOW_VOLUME = 'SHOW_VOLUME',
  AUCTION_EVENTS = 'AUCTION_EVENTS',
  SHOW_NAVIGATION_CONTROLS = 'SHOW_NAVIGATION_CONTROLS',
  SHOW_FAST_RULER = 'SHOW_FAST_RULER',
  SHOW_MIN_MAX_VISIBLE = 'SHOW_MIN_MAX_VISIBLE',
  SHOW_TRADING_CHANGE = 'SHOW_TRADING_CHANGE',
  FAST_RULER_NAVIGATION = 'FAST_RULER_NAVIGATION',
  FAST_ORDER_DAYTRADE_STRATEGY = 'FAST_ORDER_DAYTRADE_STRATEGY',
  TRADING_OFFSET = 'TRADING_OFFSET',
  SHOW_EXEC_ORDER = 'SHOW_EXEC_ORDER',
  BOOK_VOLUME_AT_PRICE = 'BOOK_VOLUME_AT_PRICE',
  SHOW_FAST_ORDER = 'SHOW_FAST_ORDER',
  SHOW_EVENTS = 'SHOW_EVENTS',
  SHOW_FLAG_ORDERS = 'SHOW_FLAG_ORDERS',
  SHOW_ORDER_MARKER = 'SHOW_ORDER_MARKER',
  SHOW_OPEN_FLAG = 'SHOW_OPEN_FLAG',
  USE_SPLIT = 'USE_SPLIT',
  QTTY_GROUP_STOCK = 'QTTY_GROUP_STOCK',
  SHOW_BID_ASK = 'SHOW_BID_ASK',
  SHOW_CHART_LINES = 'SHOW_CHART_LINES',
}

export enum TYPE_CHART_LINES_UPDATE {
  SHOW_ALERT_LINE = 'SHOW_ALERT_LINE',
  SHOW_CUSTODY_LINE = 'SHOW_CUSTODY_LINE',
  SHOW_ORDER_LINE = 'SHOW_ORDER_LINE',
  SHOW_LAST_PRICE_LINE = 'SHOW_LAST_PRICE_LINE',
  SHOW_PREVIOUS_CLOSE = 'SHOW_PREVIOUS_CLOSE',
  BREAK_EVEN = 'BREAK_EVEN',
}

export enum INPUT_TYPES {
  CHECKBOX = 'CHECKBOX',
  SELECT = 'SELECT',
  INPUT_COUNT = 'INPUT_COUNT',
}

export enum FROM_TYPES {
  chart = 'chart',
  chart_fastOrder = 'chart_fastOrder',
  configuration = 'configuration',
  chart_line = 'chart_line',
  customPreferences = 'customPreferences',
}
