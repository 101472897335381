import { StockListStettingsService } from '@services/api/nitro-ws/v1/stock-list-stettings.service';
import { StockService } from '@services/api/trademap/v1/stock.service';
import { IListStockConfig, IListStockDB } from 'src/app/core/interface';
import { IStockListItemsRow } from '../interfaces/stock-table.interfaces';
import { SearchConfig } from './search-config';
import { SearchList } from './search-list';
import { BehaviorSubject } from 'rxjs';
import { ListStocksService } from '@shared/services/core/list-stocks/list-stocks.service';
import { HEADERS } from '../constants/headers.constants';

export class CheckConfigList {
  private isChange = false;
  private updateConfigList$ = new BehaviorSubject<{
    list: IListStockDB;
    refComponent: string;
  } | null>(null);

  constructor(
    private stockListStettingsService: StockListStettingsService,
    private stockService: StockService,
    private listStocksService: ListStocksService
  ) {}

  get getUpdateConfigList() {
    return this.updateConfigList$.asObservable();
  }

  public check(listSelected: IListStockDB, refComponent: string): void {
    if (!listSelected.configList) {
      this.getConfig(listSelected, refComponent);
    } else {
      this.checkStocks(listSelected, refComponent);
    }
  }

  private checkStocks(listSelected: IListStockDB, refComponent: string): void {
    if (!listSelected.stocks.length && !listSelected.isNotListPersonal) {
      this.getStockListItems(listSelected, refComponent);
    } else {
      this.updateConfigList(listSelected, refComponent);
    }
  }

  private getConfig(listSelected: IListStockDB, refComponent: string): void {
    this.isChange = true;
    const searchConfig = new SearchConfig(this.stockListStettingsService);
    searchConfig
      .getConfigListStock(listSelected.idStockList)
      .then((config: IListStockConfig) => {
        listSelected.configList = config;
        this.checkStocks(listSelected, refComponent);
      });
  }

  private getStockListItems(
    listSelected: IListStockDB,
    refComponent: string
  ): void {
    this.isChange = true;
    const searchList = new SearchList(this.stockService);
    searchList
      .getStockListItems(listSelected.idStockList, refComponent)
      .then((data: { list: IStockListItemsRow[]; stocksMapped?: any }) => {
        listSelected.stocks = data.list;
        listSelected.stocksMapped = data.stocksMapped;
        this.updateConfigList(listSelected, refComponent);
      });
  }

  getStocksListItems(listSelected: IListStockDB, refComponent: string) {
    const searchList = new SearchList(this.stockService);
    return searchList
      .getStockListItems(listSelected.idStockList, refComponent)
      .then((data: { list: IStockListItemsRow[]; stocksMapped?: any }) => {
        listSelected.stocks = data.list;
        listSelected.stocksMapped = data.stocksMapped;
        this.updateConfigList(listSelected, refComponent);
        return listSelected;
      });
  }

  private updateConfigList(list: IListStockDB, refComponent: string): void {
    list.defaultHeadersConfig = this._defaultHeadersConfig(
      list.configList?.metadata.headers
    );
    this.updateConfigList$.next({ list, refComponent });
    if (this.isChange) {
      this.listStocksService.updateStockList({
        idStockList: list.list.id_stock_list.toString(),
        value: list,
      });
    }
  }

  private _defaultHeadersConfig(headers: any): boolean {
    for (const key in HEADERS) {
      if (headers[key] && HEADERS[key].hide !== headers[key].hide) return false;
    }
    return true;
  }
}
