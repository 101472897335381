import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
  computed,
  signal,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { MiniBookChannel } from '@shared/channel/minibook.channel';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { Subscription } from 'rxjs';
import { IDayResume, ISearchStock } from 'src/app/core/interface';
import { findTwoLargestValues } from 'src/app/utils/utils.book';
import {
  execFormatFinancial,
  formatByTick,
  getVolumeText,
  getWidthOrHeight,
  bigFormatValueFormatter,
  isABR,
  formatABRByTickDenominator,
  removeNullInObject,
} from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-stock-trade-grafic',
  templateUrl: './stock-trade-grafic.component.html',
  styleUrls: ['./stock-trade-grafic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockTradeGraficComponent
  extends ReadStreamBase
  implements OnInit, OnDestroy
{
  @Input() refComponent!: string;
  @Input() set stock(value: ISearchStock) {
    !!value && this.stockChanges(value);
  }
  @Output() lastPrice = new EventEmitter<number>();
  @Output() minPriceIncrement = new EventEmitter<string>();
  @Output() standardLot = new EventEmitter<string>();
  public dataTable!: any;
  public dataResume!: IDayResume;
  public cdStock!: string;
  private qouteParams!: SubscribeParam;
  private minibookParams!: SubscribeParam;
  private worker!: Worker;
  private tickSizeDenominator = 0;
  private tickSizeQuantity = 0;
  private idExchange = 1;
  private min_price_increment = '1';
  private stardard_lot = '1';
  private preco_ultimo = signal(0);
  private bookItemsArray!: string[];
  private _quantItemsTable = [1, 2, 3, 4, 5];
  private _quantItemTableLength = this._quantItemsTable.length;
  private compraQtdeKeys = this._quantItemsTable.map(
    (index) => `compra_qtde${index}`
  );
  private compraValorKeys = this._quantItemsTable.map(
    (index) => `compra_valor${index}`
  );
  private vendaQtdeKeys = this._quantItemsTable.map(
    (index) => `venda_qtde${index}`
  );
  private vendaValorKeys = this._quantItemsTable.map(
    (index) => `venda_valor${index}`
  );

  lastPriceFormat = computed(() => {
    return this.formatPrice(this.preco_ultimo());
  });
  volume = '';
  private cacheInfosBook!: any;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    public sanitizer: DomSanitizer,
    private minibookChannel: MiniBookChannel,
    private _quoteChannel: QuoteChannel,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }
  private event$: Subscription = new Subscription();

  ngOnInit() {
    return;
  }

  private configureWorkerCommunication(): void {
    this.minibookChannel.onEvents().then(async (data) => {
      data.snapshot(this.bookItemsArray);
      if (data && data.stream) {
        this.readStream(data.stream, async (payload: any) => {
          const data = payload.get(this.bookItemsArray[0]);
          if (!data || data.isEndOfSnap) return;
          this.cacheInfosBook = {
            ...this.cacheInfosBook,
            ...removeNullInObject(data),
          };
          const transformed = await this.buildData(this.cacheInfosBook);
          this.resultProcessMinibook(transformed);
        });
      }
    });

    this._quoteChannel.readEvents().then(async (data) => {
      data.snapshot(this.bookItemsArray);
      this.readStream(data.stream, this._quoteHandler);
    });
  }

  ngOnDestroy(): void {
    this.cheetahDestroy();
    !!this.worker && this.worker.terminate();
    this.event$ && this.event$.unsubscribe();
  }

  // private createWorker(): void {
  //   if (typeof Worker !== 'undefined') {
  //     this.worker = new Worker(
  //       new URL('./stock-trade-grafic.worker', import.meta.url)
  //     );

  //     this.worker.onmessage = async (event: any) => {
  //       const transformed = this.buildData(event.data);
  //       this.resultProcessMinibook(transformed);
  //     };
  //   } else {
  //     alert('Atualize o navegador ou instale um mais moderno como o Chrome!');
  //   }
  // }

  private stockChanges = (res: ISearchStock) => {
    this.dataTable = null;
    this.cdStock = res.cd_stock;
    this.tickSizeDenominator = res.tick_size_denominator;
    this.tickSizeQuantity = res.tick_size_quantity ?? 0;
    this.idExchange = res.id_exchange;

    if (this.worker) {
      this.event$?.unsubscribe();
      this.event$ = new Subscription();
    }

    this.getCheetahQuote(res);
    this.getCheetahMinibook(res);
    this.cacheInfosBook = {};
    this.configureWorkerCommunication();

    this.cdr.detectChanges();
  };

  private getCheetahMinibook(value: ISearchStock): void {
    !!this.minibookParams &&
      this.minibookChannel.unsubscribe(this.minibookParams);
    this.bookItemsArray = [`${value.cd_stock}:${value.id_exchange}`];
    this.minibookParams = {
      itemsArray: this.bookItemsArray,
    };
    this.minibookChannel.subscribe(this.minibookParams);
  }

  private resultProcessMinibook = (data: any): void => {
    this.dataTable = data;
    this.cdr.detectChanges();
  };

  private getCheetahQuote(stock: ISearchStock): void {
    !!this.qouteParams && this._quoteChannel.unsubscribe(this.qouteParams);
    this.qouteParams = {
      itemsArray: [`${stock.cd_stock}:${stock.id_exchange}`],
    };
    this._quoteChannel.subscribe(this.qouteParams);
  }

  private _quoteHandler = (payload: any): void => {
    const res = payload.get(this.qouteParams.itemsArray[0]);
    if (!this.qouteParams || !res) return;

    const data = this.clearCheetahData(res);

    this.dataResume = { ...this.dataResume, ...data };
    this.dataResume.variacao_dia_formatted = formatByTick(
      this.dataResume.variacao_dia
    );
    this.preco_ultimo.set(this.dataResume.preco_ultimo);
    this.volume = getVolumeText(this.locale, this.dataResume.volume, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    this.cdr.detectChanges();
    this.lastPrice.emit(this.dataResume.preco_ultimo);
    if (this.dataResume.min_price_increment !== this.min_price_increment) {
      this.min_price_increment = this.dataResume.min_price_increment;
      this.minPriceIncrement.emit(this.min_price_increment);
    }
    if (this.dataResume.lotePadrao !== this.stardard_lot) {
      this.stardard_lot = this.dataResume.lotePadrao;
      this.standardLot.emit(this.stardard_lot);
    }
  };

  private cheetahDestroy(): void {
    !!this.qouteParams && this._quoteChannel.unsubscribe(this.qouteParams);
    !!this.minibookParams &&
      this.minibookChannel.unsubscribe(this.minibookParams);
  }

  private formatPrice(value: string | number): string {
    const data = {
      id_exchange: this.idExchange,
      tick_size_denominator: this.tickSizeDenominator,
    };
    return execFormatFinancial(data, value);
  }

  private buildData = (data: any) => {
    const buy: any[] = [];
    const sell: any[] = [];
    let maxQtty = 0;
    // Read and treat data from the stream and calculate maximum qtty
    const [maxBuyQtty, maxSellQtty] = findTwoLargestValues(data, 5);

    maxQtty = Math.max(maxBuyQtty, maxSellQtty);

    // Build buy and sell arrays to be used in the template
    for (let i = this._quantItemTableLength - 1; i >= 0; i--) {
      // Format buy
      const priceBuyRaw = data[this.compraValorKeys[i]];
      const qttyBuyRaw = data[this.compraQtdeKeys[i]];

      const buyIsABR = isABR('BUY', qttyBuyRaw, +priceBuyRaw);

      const priceBuy = formatABRByTickDenominator(
        priceBuyRaw,
        buyIsABR,
        this.tickSizeDenominator
      );

      const qttyBuy = bigFormatValueFormatter(
        formatByTick(qttyBuyRaw, this.tickSizeQuantity)
      );
      const barBuy = getWidthOrHeight(qttyBuyRaw, maxBuyQtty);
      const uniqueBarBuy = getWidthOrHeight(qttyBuyRaw, maxQtty);

      buy.push({
        priceBuy: priceBuy,
        qttyBuy: qttyBuy,
        barBuy: barBuy,
        uniqueBar: uniqueBarBuy,
      });

      // Format sell
      const sellIndex = this._quantItemTableLength - 1 - i;
      const priceSellRaw = data[this.vendaValorKeys[sellIndex]];
      const qttySellRaw = data[this.vendaQtdeKeys[sellIndex]];

      const sellIsABR = isABR('SELL', qttySellRaw, +priceSellRaw);

      const priceSell = formatABRByTickDenominator(
        priceSellRaw,
        sellIsABR,
        this.tickSizeDenominator
      );
      const qttySell = bigFormatValueFormatter(
        formatByTick(qttySellRaw, this.tickSizeQuantity)
      );
      const barSell = getWidthOrHeight(qttySellRaw, maxSellQtty);
      const uniqueBarSell = getWidthOrHeight(qttySellRaw, maxQtty);

      sell.push({
        priceSell: priceSell,
        qttySell: qttySell,
        barSell: barSell,
        uniqueBar: uniqueBarSell,
      });
    }
    return { buy, sell };
  };
}
