<rocket-modal>
  <rocket-modal-header>
    <h3 class="modal-title">{{ modalTitle }}</h3>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div class="fs-5 pb-5 flex flex-column fw-normal font-400">
      <p *ngFor="let field of fields" class="mb-2">
        <span *ngIf="field.title" class="fw-bold">{{ field.title }}: </span>
        {{ field.preffix }} {{ info[field.field] }}
      </p>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
