import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceComponent } from './workspace.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { WorkspaceDocComponent } from './parts/doc/workspace-doc.component';
import { WorkSpaceDirectiveDirective } from './directive/workspace-resize.directive';
import { WorkspaceDocHeaderItemComponent } from './parts/header/workspace-doc-header-item.component';
import { WorkSpaceHeaderComponent } from './parts/header/workspace-doc-header.component';

import { CountPipe } from '@shared/pipes/count-value.pipe';
import { NoSelectedAccountPipe } from '@shared/pipes/no-selected-account.pipe';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { WorspaceHeaderBlinkDirective } from './parts/header/directive/worspace-header-blink.directive';
import { ContextMenuModule } from '@shared/components/context-menu/context-menu.module';
import { TitleCaseDirective } from './directive/title-case.directive';
import { SafeUrlPipe } from '@shared/pipes/safe-url.pipe';
import { WorkspaceDocFooterComponent } from './parts/footer/workspace-doc-footer/workspace-doc-footer.component';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';
import { OutsideClickDirective } from './directive/outside-click.directive';
import { HeaderCommomButtonsComponent } from './parts/header-commom-buttons/header-commom-buttons.component';
import { WorkspaceHeader } from './parts/header/workspace-header.service';
import { PopupRootModule } from '@shared/components/popup-root/popup-root.module';

const COMPONENTS = [
  WorkspaceComponent,
  WorkspaceDocComponent,
  WorkSpaceHeaderComponent,
  WorkspaceDocFooterComponent,
  WorkSpaceDirectiveDirective,
  TitleCaseDirective,
  WorkspaceDocHeaderItemComponent,
  WorspaceHeaderBlinkDirective,
  HeaderCommomButtonsComponent,
];
const PIPES = [NoSelectedAccountPipe, SafeHtmlPipe, SafeUrlPipe];
@NgModule({
  declarations: [...COMPONENTS, ...PIPES],
  imports: [
    CommonModule,
    DragDropModule,
    RTDropdownModule,
    ContextMenuModule,
    TooltipsModule,
    PopupRootModule,
    CountPipe,
    OutsideClickDirective,
  ],
  exports: [COMPONENTS, ...PIPES],
  providers: [WorkspaceHeader],
})
export class FlaWorkSpaceModule {}
