import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '@shared/services/rest';
import { Observable, catchError, map, of } from 'rxjs';
import { IResponse } from '../V6/interface/loan-balance.interface';
import { Dictionary } from '@core/models';
import { IChartFund } from '@shared/components/rent/interface/rent.interface';

@Injectable({
  providedIn: 'root',
})
export class FundsService extends RestService {
  override _url: string = 'api/trademap/v1/fundos';

  private _fundsDict: Dictionary<any> = new Dictionary<any>();

  constructor(private http: HttpClient) {
    super(http);
  }

  public getFundData(
    id_fund: number,
    cdStock: string,
    period: number = 1
  ): Observable<IResponse<IChartFund[]>> {
    if (this._fundsDict.has(id_fund))
      return of(this._returnDataInDict(this._fundsDict.get(id_fund)));

    return this.post<IResponse<IChartFund[]>>('get-papel-portfolio', {
      id_fund: id_fund,
      papel: `${cdStock}:1`,
      period,
    }).pipe(
      catchError((response) => of(this.handlerServiceError(response))),
      map((response) => {
        if (!response || response.error || !response.data.success)
          throw response.data;
        this._fundsDict.set(id_fund, response.data.result);
        return response.data;
      })
    );
  }
}
