import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {
  IPartnerInfos,
  IPartnerLoginContent,
} from '@shared/services/core/partners-services/partner-preferences.interface';
import {
  PARTNER_CUSTOM_CONTENT,
  PARTNER_WITH_CUSTOM_LAYOUT,
} from '@shared/services/core/partners-services/partners.const';
import { PartnersService } from '@shared/services/core/partners-services/partners.service';

@Component({
  selector: 'app-login-info-messages',
  templateUrl: './login-info-messages.component.html',
  styles: [
    `
      .card-icon {
        width: 40px;
        min-width: 40px;
        max-width: 40px;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule],
})
export class LoginInfoMessagesComponent implements OnInit {
  public useDefaultLayout = true;
  public partnerContent!: IPartnerLoginContent;
  private _partnersService = inject(PartnersService);

  ngOnInit() {
    const partner = this._partnersService.partnerBeforeAuth;
    if (!partner || !partner.key || !PARTNER_WITH_CUSTOM_LAYOUT[partner.key])
      return;
    this.verifyPartnerCustomMessages(partner);
  }

  public verifyPartnerCustomMessages(partner: IPartnerInfos) {
    const content = PARTNER_CUSTOM_CONTENT[partner.key!];
    if (!content) return;
    this.useDefaultLayout = false;
    this.partnerContent = content;
  }
}
