import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { IStrategyCondition } from '../../interface/strategy.interface';
import { StrategyService } from '../../service/strategy.service';
import { InputMask } from '../../types/strategy.types';

@Component({
  selector: 'app-tick-strategy',
  templateUrl: './tick-strategy.component.html',
  styleUrls: ['./tick-strategy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TickStrategyComponent implements AfterViewInit {
  @Input() set groupForm(groupForm: FormArray<any> | undefined) {
    if (groupForm) {
      this.form.get('items')?.setValue(groupForm.value);
      this.setStrategyType();
    }
  }
  @Input() form!: FormGroup;
  @Input() isNewTick: boolean = false;
  @Input() height: number = 230;
  @Input() maxLegs: number = 7;
  @Input() scroll: number = 3;
  @Output() inputMaskChange: EventEmitter<InputMask> =
    new EventEmitter<InputMask>();
  @Output() groupHandler: EventEmitter<{
    groups: FormArray<any>;
    indexInvalidQuantity: number | null | undefined;
  }> = new EventEmitter<{
    groups: FormArray<any>;
    indexInvalidQuantity: number | null | undefined;
  }>();
  @Output() handleStrategyItemToDelete = new EventEmitter<number>();
  public inputMask: InputMask = '';
  public incrementValue: number = 1;
  public decidePosition: 'all' | 'suffix' = 'suffix';
  public disableAddCondition: boolean = false;
  public indexInvalidQuantity: number | null | undefined = null;
  private _inputCountElements: HTMLElement[] = [];
  get groups(): FormArray {
    return this.form.get('items') as FormArray;
  }
  constructor(
    private _formBuilder: FormBuilder,
    private strategyService: StrategyService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.form.valueChanges.subscribe(() => {
      this.cdr.detectChanges();
    });
    this.setStrategyType();
  }

  private setStrategyType() {
    if (this.form.get('typeStrategy') && this.form.get('typeStrategy')!.value)
      this.changeStrategyType(this.form.get('typeStrategy')!.value);
  }

  public changeStrategyType(type: 'TICK' | 'FINANCEIRO' | 'PERCENTUAL'): void {
    const { decidePosition, inputMask } =
      this.strategyService.changeStrategyType(type, this.form);
    this.decidePosition = decidePosition;
    this.inputMask = inputMask;
    this.incrementValue = type == 'PERCENTUAL' ? 0.1 : 1;
    this.cdr.detectChanges();
    this.inputMaskChange.emit(this.inputMask);
  }

  public handleCountFocus(event: any): void {
    if (event.key === 'Tab') {
      event.preventDefault();
      const currentInput = document.activeElement as HTMLElement;
      const currentIndex = this._inputCountElements.indexOf(currentInput);
      const nextIndex = (currentIndex + 1) % this._inputCountElements.length;
      const nextInput = this._inputCountElements[nextIndex];
      nextInput.focus();
    }
  }

  public saveElementRef(elementID: string): void {
    const element = document.getElementById(elementID);
    if (!element) return;
    this._inputCountElements.push(element);
  }

  public verifyDefaultValue(form: FormControl): void {
    const currentValue = form.value;
    if (
      isNullOrUndefined(currentValue) ||
      !this.strategyService.removeFormatter(currentValue, this.inputMask)
    )
      form.setValue(1);
  }

  public checkInputIsEmpty(form: FormControl) {
    if (String(form.value).includes('NaN')) {
      switch (this.inputMask) {
        case 'custom_percent_symbol':
          form.setValue('');
          break;
        case 'custom_remove_mask':
          form.setValue('');
          break;
        default:
          break;
      }
    }
  }

  public addStrategyItems(defaultValues?: IStrategyCondition): void {
    const info = this.strategyService.addStrategyItems(
      this.disableAddCondition,
      this.groups,
      this._formBuilder,
      this.inputMask,
      defaultValues
    );
    if (info) {
      this.disableAddCondition = info.disableAddCondition;
      this.indexInvalidQuantity = info.indexInvalidQuantity;
      this.form.get('items')?.setValue(info.groups.value);
    }
    this.updateControlsValidity();
    this.groupHandler.emit({
      groups: this.groups,
      indexInvalidQuantity: this.indexInvalidQuantity,
    });
  }

  private updateControlsValidity() {
    this.groups.controls.forEach((control) => {
      control.updateValueAndValidity();
    });
  }

  public removeStrategyItems(index: number): void {
    if (this.groups.controls[index].get('id_item')?.value) {
      this.handleStrategyItemToDelete.emit(
        this.groups.controls[index].get('id_item')?.value
      );
    }
    this.groups.removeAt(index);
    this.disableAddCondition = this.groups.controls.length >= this.maxLegs;
    this.strategyService.updateQuantityPercentValue(this.groups);
    this.onSetStrategyQuantity();
    this.updateControlsValidity();
  }

  public onSetStrategyQuantity(): void {
    let quantityTotalValue: number = 0;
    this.groups.controls.forEach((control) => {
      const value = parseFloat(
        String(control.get('quantity')?.value).replace(',', '.')
      );

      quantityTotalValue += value;
    });

    if (quantityTotalValue !== 100) {
      this.indexInvalidQuantity = this.groups.controls.length - 1;
    } else {
      this.disableAddCondition = this.groups.controls.length >= this.maxLegs;
      this.indexInvalidQuantity = null;
    }
    this.cdr.detectChanges();
    this.groupHandler.emit({
      groups: this.groups,
      indexInvalidQuantity: this.indexInvalidQuantity,
    });
  }

  public setPrefixOrSuffix(position: 'suffix' | 'prefix' | '' = ''): string {
    if (this.form.get('typeStrategy')?.value === 'FINANCEIRO') {
      if (position === 'prefix') return 'R$';
    } else if (this.form.get('typeStrategy')?.value === 'PERCENTUAL') {
      if (position === 'suffix') return '%';
    }
    return '';
  }

  // COUNT HANDLER
  public handleValue(
    action: 'PLUS' | 'MINUS',
    form: AbstractControl,
    validateQuantity: boolean = false,
    dontUseInputMask: boolean = false
  ): void {
    const value = this.strategyService.removeFormatter(
      form.value,
      this.inputMask
    );
    const counter =
      this.inputMask === 'separator.2' && !dontUseInputMask ? 0.01 : 1;
    const valueChanged =
      action === 'PLUS'
        ? value + counter
        : value - counter >= 1
        ? value - counter
        : 1;
    if (!dontUseInputMask && this.inputMask === 'separator.2') {
      form.setValue(String(valueChanged));
    } else if (
      !dontUseInputMask &&
      this.inputMask === 'custom_percent_symbol'
    ) {
      form.setValue(`${valueChanged}`);
    } else {
      form.setValue(`${valueChanged}`);
    }
    if (validateQuantity) this.onSetStrategyQuantity();
  }
}
