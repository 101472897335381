import { NgModule } from '@angular/core';

import { StockTableModule } from '@shared/components/stock-table/stock-table.module';
import { StockTableRoutes } from '@shared/components/stock-table/stock-table.routing';

@NgModule({
  declarations: [],
  imports: [StockTableRoutes, StockTableModule],
})
export class StockTableRouteModule {}
