import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'fla-workspace-doc-header-item',
  template: `
    <div
      [id]="idElement"
      [class]="'h-100 hstack ' + alignItem"
      [style.z-index]="2"
      [style.width]="width"
      [style.maxWidth]="'inherit'"
      [ngClass]="{
        'border-right': !hideBorder,
        active: active,
      }"
      [tooltip]="tooltipText"
      [tooltipHidden]="!tooltipText"
      tooltipPosition="bottom"
    >
      <button
        *ngIf="!enableContent"
        class="btn  min-w-0"
        [ngClass]="[
          name ? 'nav-link fs-6' : 'btn-icon',
          margin,
          active ? 'active' : '',
          tabIcon ? '' : 'px-1',
          btnClass
        ]"
        (click)="flaClick.emit($event)"
        appHeaderBlink
        [blinkQtty]="qtty"
        [blinkName]="name"
      >
        <i class="icons icon-size-micro" *ngIf="icon" [ngClass]="[iconColor]">
          {{ icon }}
        </i>
        <div
          class="d-inline-flex align-items-center"
          [ngStyle]="{ 'padding-top': tabIcon ? '4px' : '' }"
        >
          <span
            *ngIf="haveCircle"
            class="circle round-pill"
            [style.background]="colorCircle"
            style="height:8px; width: 8px; margin-right: 6px;"
          ></span>

          <span
            class="fs-5 material-icons-outlined"
            *ngIf="tabIcon"
            [style.color]="'#f96500'"
            style="margin-right: 4px;"
          >
            {{ tabIcon }}
          </span>
          <span> {{ name }} {{ qtty | count }} </span>
          <i *ngIf="isDropdown" class="icons icon-size-micro m-0">
            arrow_drop_down
          </i>
        </div>
      </button>
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      :host(fla-workspace-doc-header-item) {
        display: contents;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceDocHeaderItemComponent implements OnChanges {
  @Input() icon!: string;
  @Input() name!: string;
  @Input() idElement: string = 'NOID';
  @Input() margin: string = 'mx-2';
  @Input() alignItem: 'ml-auto' | 'mr-auto' | '' = '';
  @Input() hideBorder: boolean = false;
  @Input() active: boolean = false;
  @Input() enableContent: boolean = false;
  @Input() iconColor: string = 'text-neutral-medium';
  @Input() qtty!: string;
  @Input() tabIcon: string | null = null;
  @Input() haveCircle: boolean = false;
  @Input() colorCircle: string | null = null;
  @Input() tooltipText!: string;
  @Input() isDropdown: boolean = false
  @Input() btnClass: string = ''
  @Input() width: string | null = null;
  @Output() flaClick = new EventEmitter<any>();
  @ViewChild(TemplateRef) content!: TemplateRef<any>;
  public tabColor: string = '';

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['icon']?.currentValue)
      this._cdr.detectChanges()
  }
}
