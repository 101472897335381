import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { HomeGuard } from './core/guards/home.guard';
import { LayoutComponent } from './core/layout/layout.component';
import { HomeModule } from '@modules/home/home.module';
import { AuthModule } from '@modules/auth/auth.module';
import { SystemModule } from '@modules/system/system.module';
import { PublicModule } from '@modules/public/public.module';
import { InvalidDeviceModule } from '@shared/components/invalid-device/invalid-device.module';
import { StandaloneWindowComponent } from '@shared/components/standalone-window/standalone-window.component';
import { PopupRootComponent } from '@shared/components/popup-root/popup-root.component';
import { ViewModule } from '@modules/view/view.module';
import { BrowserGuard } from '@core/guards/browser.guard';
import { InvalidBrowserModule } from '@shared/components/invalid-browser/invalid-browser.module';
import { ModalViewModule } from '@modules/modal-view/modal-view.module';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => HomeModule,
        canActivate: [HomeGuard, BrowserGuard],
      },
    ],
  },
  {
    path: 'view',
    loadChildren: () => ViewModule,
  },
  {
    path: 'tools/:workspace_id/:component_id',
    component: StandaloneWindowComponent,
  },
  {
    path: 'modal',
    loadChildren: () => ModalViewModule,
  },
  {
    path: 'popup',
    component: PopupRootComponent,
  },
  {
    path: 'system',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => SystemModule,
      },
    ],
  },
  {
    path: 'login',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'public',
    loadChildren: () => PublicModule,
  },
  {
    path: 'auth',
    loadChildren: () => AuthModule,
  },
  {
    path: 'invalid-device',
    loadChildren: () => InvalidDeviceModule,
  },
  {
    path: 'invalid-browser',
    loadChildren: () => InvalidBrowserModule,
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
