import { LayoutBase } from '../base';
import { NEWS_COMPONENT, STOCK_TABLE_COMPONENT } from '@core/workspace/constants';
import { OPTIONS_COMPONENT } from '@core/workspace/constants/components/options';

export class Opcoes extends LayoutBase {
  override name = 'Opções';
  override components = [
    STOCK_TABLE_COMPONENT({
      height: 100,
      width: 20,
    }),
    OPTIONS_COMPONENT({
      unit: '%',
      height: 100,
      width: 47,
      left: '20.5%',
    }),
    NEWS_COMPONENT({
      unit: '%',
      height: 100,
      width: 31.5,
      left: '68.5%',
    })
  ];
}
