import {
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RocketModalService } from '@shared/rocket-components/components';
import { ModalEventPayload } from '@shared/rocket-components/components/modal/service/modal-event.model';

import { Subscription } from 'rxjs';
import { ModalViewByPath } from './modal-view.consts';

@Component({
  selector: 'app-modal-root',
  template: '<div></div>',
})
export class ModalRootComponent implements OnInit, OnDestroy {
  private componentRef!: ComponentRef<any>;
  private event$ = new Subscription();
  private isRoot: boolean = false;
  constructor(
    private modalService: RocketModalService,
    private route: ActivatedRoute
  ) {
    this.route.url.subscribe((url) => {
      this.isRoot = url[0]?.path === 'modalview';
    });
  }

  ngOnInit(): void {
    this.event$.add(
      this.modalService
        .listenOpenEvent()
        .subscribe(async (data: ModalEventPayload) => {

          const componentClass = ModalViewByPath.get(data.path);

          if (!componentClass) {
            this.modalService.emitCloseRxEvent();
            throw new Error(
              `Component ${data} not found!`
            );
          }
          this.modalService.openInRoute(componentClass, data.settings);
        })
    );

    this.event$.add(
      this.modalService.listenCloseEvent().subscribe(() => {
        this.modalService.closeWithoutEmitEvent();
      })
    );
  }

  ngOnDestroy(): void {
    this.componentRef?.destroy();
    this.event$.unsubscribe();
  }
}
