import { Injectable } from '@angular/core';
import { ConfigService } from '@core/config';
import { IWorkSpace } from '@core/interface';
import { WorkspaceComponentService } from '@modules/home/service/workspace-component.service';
import { WorkspacesPreferences } from '@shared/services/core/custom-preferences/workspace/workspaces-preferences.service';
import { Subject } from 'rxjs';
import {
  DEFAULT_HEADER_BUTTONS,
  DEFAULT_OPTIONS_INFO_USER,
} from './constants/header.contanst';
import {
  HEADER_BUTTONS_ENUM,
  INFO_USER_OPTIONS_ENUM,
} from './enum/header.enum';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private addWorkspace$ = new Subject<void>();
  private changeWorkspace$ = new Subject<IWorkSpace>();

  constructor(
    private preferences: WorkspacesPreferences,
    private workspaceComponentService: WorkspaceComponentService,
    private configService: ConfigService
  ) {}

  public addWorkspace(): void {
    this.addWorkspace$.next();
  }

  public addWorkspaceObservable() {
    return this.addWorkspace$.asObservable();
  }

  public changeWorkspace(workspace: IWorkSpace): void {
    this.changeWorkspace$.next(workspace);
  }

  public changeWorkspaceObservable() {
    return this.changeWorkspace$.asObservable();
  }

  public setVisibleTabs = (data: IWorkSpace[]) =>
    this.preferences.setVisibleWorkspaces(data);

  public getVisibleTabs = () =>
    this.preferences.loadPreference(
      this.workspaceComponentService.getVisiblesWorkspaces()
    );

  public isLockWorkspace(): boolean {
    return this.preferences.lockWorkspace;
  }

  public activeWorkspace(): string {
    return this.workspaceComponentService.getActiveWorkspace().id;
  }

  public getDefaultInfoUserOptions() {
    const profileInfoOptions = DEFAULT_OPTIONS_INFO_USER;
    if (this.configService.isBroker()) {
      profileInfoOptions.delete(INFO_USER_OPTIONS_ENUM.SPACE_1);
      profileInfoOptions.delete(INFO_USER_OPTIONS_ENUM.MANAGE_ACCOUNT);
      profileInfoOptions.delete(INFO_USER_OPTIONS_ENUM.CONNECTIONS);
      profileInfoOptions.delete(INFO_USER_OPTIONS_ENUM.SPACE_3);
      profileInfoOptions.delete(INFO_USER_OPTIONS_ENUM.THEME);
      profileInfoOptions.delete(INFO_USER_OPTIONS_ENUM.DOWNLOADPKG);
      profileInfoOptions.delete(INFO_USER_OPTIONS_ENUM.RATING);
    }
    return profileInfoOptions;
  }

  public getDefaultButtonsHeader() {
    const headerButtons = DEFAULT_HEADER_BUTTONS;
    if (this.configService.isBroker()) {
      headerButtons.delete(HEADER_BUTTONS_ENUM.WHATSAPP);
      headerButtons.delete(HEADER_BUTTONS_ENUM.AUCTION);
    }
    return headerButtons;
  }
}
