import { IOrderModification, IOrders, ISearchStock } from '@core/interface';
import { ORDER_TYPES } from '@shared/components/stock-trade/constants/boleta.constants';
import {
  ORDER_SIDE,
  isAuction,
  isStockFractional,
  isStockCashMarket,
} from '@shared/constants/general.contant';
import { ORDER_OPEN_STATUS_DIC } from '@shared/dictionary/order.dictionary';
import { deepClone } from '../shared/rocket-components/utils/functions';
import {
  TYPE_ORDE_SIDE_ENUM,
  TYPE_ORDE_SIDE_STRING_ENUM,
} from '@shared/enum/buyOrSell.enum';
import { defAgressorClass, findTwoLargestValues } from './utils.book';

export function realToDolar(num: string): string {
  if (!num) return '';
  return num.replace(/\./g, '').replace(/,/, '.');
}

export function ISODateString(date: Date) {
  return date.toISOString().slice(0, 19);
}

export function updateDate(
  date: Date,
  quant: number,
  interval: 'month' | 'day' | 'year' | 'minute',
  action: 'add' | 'remove'
) {
  const _date = new Date(date);
  switch (interval) {
    case 'month':
      return action === 'add'
        ? new Date(_date.setMonth(_date.getMonth() + quant))
        : new Date(_date.setMonth(_date.getMonth() - quant));

    case 'day':
      return action === 'add'
        ? new Date(_date.setDate(_date.getDate() + quant))
        : new Date(_date.setDate(_date.getDate() - quant));

    case 'minute':
      return action === 'add'
        ? new Date(_date.setMinutes(_date.getMinutes() + quant))
        : new Date(_date.setMinutes(_date.getMonth() - quant));

    case 'year':
      return action === 'add'
        ? new Date(_date.setFullYear(_date.getFullYear() + quant))
        : new Date(_date.setFullYear(_date.getFullYear() - quant));
  }
}

export function sort(arr: any, field: any) {
  return arr.sort((a: any, b: any) =>
    a[field] < b[field] ? 1 : b[field] < a[field] ? -1 : 0
  );
}

export function alphabeticalSort(arr: any[], field: any = null) {
  return arr.sort((a: any, b: any) => {
    const valueA: string = a[field] ?? a;
    const valueB: string = b[field] ?? b;
    return valueA.localeCompare(valueB);
  });
}

export function removeNullInObject(object: any): any {
  return Object.fromEntries(
    Object.entries(object).filter(
      // eslint-disable-next-line
      ([_, v]) => v != null && v != undefined && !Number.isNaN(v)
    )
  );
}

export function isCPF(value: string): boolean {
  // eslint-disable-next-line
  return /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}$/i.test(value);
}

export function getCdStockParam(
  stock: Partial<{
    cd_stock_order: string;
    is_synonymous: boolean;
    synonymous_nickname: string;
    cd_crypto?: string;
  }>
): string {
  return stock.cd_crypto
    ? stock.cd_crypto
    : stock.is_synonymous
    ? stock.synonymous_nickname!
    : stock.cd_stock_order!;
}

export function formatterNumber(
  value: number,
  options: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  },
  locale: string = 'pt-BR',
  symbol: string = ''
): string {
  if (value == undefined || value == null || Number.isNaN(value)) {
    return '-';
  }

  return `${value.toLocaleString(locale, options)}${symbol}`;
}

function formatVolumeText(
  locale: string,
  vl_volume: number,
  divisor: number,
  suffix: string,
  options: Intl.NumberFormatOptions = {
    maximumFractionDigits: 3,
    minimumFractionDigits: 3,
  }
): string {
  return formatterNumber(
    vl_volume / divisor,
    {
      maximumFractionDigits: options.maximumFractionDigits,
      minimumFractionDigits: options.minimumFractionDigits,
    },
    locale,
    suffix
  );
}

export function isNullOrUndefined(value: any): boolean {
  return value === null || value === undefined;
}
export const assignChannelObject = (
  previous: any = {},
  current: any,
  canBeUndefined: boolean = false
) => {
  previous = typeof previous === 'string' ? JSON.parse(previous) : previous;
  for (const key in current) {
    if (
      (!isNullOrUndefined(current[key]) || canBeUndefined) &&
      current[key] !== ''
    ) {
      previous[key] = current[key];
    }
  }
  return previous;
};
export function getVolumeText(
  locale: string,
  vl_volume: number,
  options?: Intl.NumberFormatOptions
): string {
  if (isNullOrUndefined(vl_volume)) vl_volume = 0;
  const isNegative = vl_volume < 0;
  const stringVolume = vl_volume.toString().split('.')[0];
  const stringVolumeLength = isNegative
    ? stringVolume.length - 1
    : stringVolume.length;
  if (stringVolumeLength >= 13) {
    return formatVolumeText(locale, vl_volume, 1000000000000, 'T', options);
  }
  if (stringVolumeLength >= 10) {
    return formatVolumeText(locale, vl_volume, 1000000000, 'B', options);
  }
  if (stringVolumeLength >= 7) {
    return formatVolumeText(locale, vl_volume, 1000000, 'M', options);
  }
  if (stringVolumeLength >= 4) {
    return formatVolumeText(locale, vl_volume, 1000, 'K', options);
  }
  return formatVolumeText(locale, vl_volume, 1, '', options);
}

export function objectAssign(target: any, source: any): any {
  for (const key in source) {
    if (key in source) {
      const propertyValue = source[key];
      if (typeof propertyValue === 'object' && propertyValue !== null) {
        target[key] = objectAssign(target[key] || {}, propertyValue);
      } else {
        target[key] = propertyValue;
      }
    }
  }
  return target;
}

export function nextDay(date: number, days: number): number {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result.getTime();
}

export function nextBusinessDay(date: number, days: number): number {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  const weekDay = result.getDay();
  if (weekDay == 0 || weekDay == 6) {
    return nextBusinessDay(result.getTime(), days);
  }
  return result.getTime();
}

//metodo para retornar o codigo do erro da chamada de serviço
export const getCodeByResp = (resp: any): string => {
  const code =
    resp.errorCode && resp.errorCode != '' ? resp.errorCode : resp.code;
  return code;
};

export const isCodeScheduleReq = (code: string) => code === 'SCHEDULE_REQUIRED';

export const isInvalidActiveToken = (code: string) =>
  code === 'INVALID_ACTIVE_TOKEN';

export const isTokenInvalid = (code: string) => code === 'TOKEN_CHECK_FAILED';

export const isCrypto = (value: string | number) => {
  if (typeof value === 'number') {
    return value === 8888;
  }
  return value === 'CRYPTO';
};

export const waitTimeout = async (timeoutLength: number = 0): Promise<void> => {
  return new Promise<void>((resolve) => setTimeout(resolve, timeoutLength));
};

export const isString = (val: any) => typeof val === 'string';

export const convertStringToBoolean = (val: string): any => {
  if (val.toLowerCase() === 'true') {
    return true;
  }
  if (val.toLowerCase() === 'false') {
    return false;
  }
  return val;
};

export const getWidthOrHeight = (quant: number | string = 0, max: number) => {
  if (typeof quant === 'string') {
    quant = parseFloat(quant);
  }

  let unit;
  if (quant == 0.0) {
    unit = 5;
  } else {
    if (max == 0.0) {
      unit = 95;
    } else {
      unit = (quant / max) * 95;
    }
  }
  return isNaN(unit) ? 0 : unit;
};

export function isEmpty(obj: object) {
  return !obj || !Object.keys(obj).length;
}

export function getSinonimousByStock(item: any) {
  if (!item) return item;
  item.cd_stock_order = deepClone(item.cd_stock);
  item.cd_stock = item.is_synonymous ? item.synonymous_nickname : item.cd_stock;
  return item;
}

export function normalizeString(item: string) {
  return item.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function lowerCaseAndNormalizeString(item: string) {
  return item
    .toLocaleLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

export function setPositionContextMenu(infos: {
  innerHeight: number;
  innerWidth: number;
  pageY: number;
  pageX: number;
  height: number;
  width: number;
}) {
  const { innerHeight, innerWidth, pageY, pageX, height, width } = infos;
  const top = innerHeight - pageY < height ? innerHeight - height : pageY;
  const left = innerWidth - pageX < width ? innerWidth - width : pageX;
  return { left, top };
}

export function newBigValueFormatter(
  num: number | string,
  digits: number | string = 2
) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'QA' }, //P
    { value: 1e18, symbol: 'QI' }, //E
  ];
  const item = lookup
    .slice()
    .reverse()
    .find((item) => {
      return +num >= item.value;
    });
  return item
    ? formatterNumber(+num / item.value, {
        minimumFractionDigits: +digits,
        maximumFractionDigits: +digits,
      }) + item.symbol
    : '0';
}

function splitValue(value: string): string {
  const formatted = formatterNumber(+value);
  const list = formatted.split('.');
  return `${list[0]},${(list[1] || '00').slice(0, 2)}`;
}

export function bigValueFormatter(
  value: number | string,
  options: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  },
  regex: RegExp = /[^0-9,.]/g,
  isGreaterThanFourDigits: boolean = false
): string {
  if (value == undefined || value == null || value == '-') {
    return '-';
  }
  const valueNum =
    typeof value === 'number' ? value : +value.replace(regex, '');
  const big = valueNum.toFixed();
  let bigFormatter = '-';
  const thousandsDigit = isGreaterThanFourDigits ? 5 : 4;
  big.length >= 24 && (bigFormatter = `${splitValue(big)}S`);
  big.length >= 20 &&
    big.length < 24 &&
    (bigFormatter = `${splitValue(big)}QI`);
  big.length >= 17 &&
    big.length < 20 &&
    (bigFormatter = `${splitValue(big)}QA`);
  big.length >= 14 && big.length < 17 && (bigFormatter = `${splitValue(big)}T`);
  big.length >= 10 && big.length < 14 && (bigFormatter = `${splitValue(big)}B`);
  big.length >= 7 && big.length < 10 && (bigFormatter = `${splitValue(big)}M`);
  big.length >= thousandsDigit &&
    big.length < 7 &&
    (bigFormatter = `${splitValue(big)}K`);
  if (big.length <= thousandsDigit - 1) {
    const decimal = value.toString().split('.')[1];
    if (decimal && +decimal > 0) {
      bigFormatter = formatterNumber(+value, options);
    } else {
      bigFormatter = formatterNumber(+value, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    }
  }
  return bigFormatter;
}

export function bigFormatValueFormatter(value: number | string): string {
  return bigValueFormatter(
    value,
    {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    },
    /[^0-9]/g
  );
}

export const isABR = (type: 'BUY' | 'SELL', qty: string, price: number) => {
  if (type === 'BUY') return +price === 0 && +qty !== 0;
  return (+price === 9.99999 || +price === 0) && +qty !== 0;
};

export const formatByTick = (
  value: string | number,
  tick_size_denominator: number = 2
) => {
  const tickSizeCorrect = tick_size_denominator;
  const vl = value ? parseFloat(value.toString()) : NaN;

  if (!isNaN(vl)) {
    const tick = isNaN(tickSizeCorrect) ? 2 : tickSizeCorrect;
    const options = {
      maximumFractionDigits: tick,
      minimumFractionDigits: tick,
    };
    return formatterNumber(vl, options);
  }
  return '-';
};

export const formatABRByTickDenominator = (
  value: any,
  isABR: boolean,
  tickSizeDenominator: number
) => (isABR ? 'ABR' : formatByTick(value, tickSizeDenominator));

export function isNullOrUndefinedOrEmpty(value: any): boolean {
  return value === null || value === undefined || value.length === 0;
}

export function isInvalidIdBroker(account: number): boolean {
  if (!account) return true;
  if (account <= 0) return true;
  return false;
}

export function descendingSort(a: any, b: any) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

export function ascendingSort(a: any, b: any) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

export const genDict = (arr: any[], field: string) =>
  arr.reduce((map: any, item: any) => {
    map[item[field]] = item;
    return map;
  }, {});

export function getTextByRegex(text: string, regex: RegExp): string {
  const found = text.match(regex);
  return found ? found[0] : '';
}

export function getOrdinaryTicker(stock: ISearchStock) {
  let cdStock = stock.cd_stock_order;
  if (isStockFractional(stock.cd_security_type)) {
    cdStock = getTextByRegex(cdStock, /.*[^F]/);
  } else if (isStockCashMarket(stock.cd_security_type)) {
    cdStock = `${stock.cd_stock_order}F`;
  }
  return cdStock;
}

export function toValue(valueString: string): number {
  // eslint-disable-next-line
  return parseFloat(valueString.replace(/\./g, '').replace(/\,/g, '.'));
}

export const smartFinancialTicksize = (value: number, tick: number) => {
  const valor = String(value).split('.')[1];
  if (!valor || (valor && valor.length < 2)) return 2;
  if (valor.length > tick) return tick;
  if (!+valor) return 2;
  if (valor.length <= tick) return valor.length;
  return 2;
};

export const execFormatFinancial = (data: any, value: any) => {
  const { cd_segment, id_exchange, tick_size_denominator } = data;
  if (!value || value.toString() == '0') {
    return '-';
  }
  let tickCorrect = id_exchange == 7777 ? 2 : tick_size_denominator ?? 2;
  if (tickCorrect > 2) tickCorrect = smartFinancialTicksize(value, tickCorrect);
  const vl = formatByTick(value, tickCorrect);
  const useTofix = cd_segment == 8888 || id_exchange == 7777;
  const suffix = useTofix && vl !== '-' ? '$' : '';
  const vlFmt = `${suffix}${vl}`;
  return vlFmt;
};

export function getDecimal(value: number | string): number {
  return value ? +value.toString().split('.')[1] : 0;
}

export const smartQtdTicksize = (value: string, tick: number) => {
  return tick > 0
    ? value
        // eslint-disable-next-line
        .replace(/^(\d*\,\d*?[1-9])0+$/g, '$1')
        // eslint-disable-next-line
        .replace(/(^0+(?=\d))|([1 - 9]?0+$)|(,?0+$)/g, '')
    : value;
};

export const execFormatQtd = (data: any, value: any) => {
  const { cd_segment, id_exchange, tick_size_quantity } = data;
  const useTofix = cd_segment == 8888 || id_exchange > 7000;
  const decimal = getDecimal(value);
  const tickSize = useTofix ? 8 : decimal > 0.0 ? tick_size_quantity : 0;
  const vl = formatByTick(value, tickSize);
  return smartQtdTicksize(vl, tickSize);
};

export const groupBy = (
  list: any,
  keyGetter: any,
  valueTypeArray: boolean = true
) => {
  const map = new Map();
  for (let index = 0, len = list.length; index < len; index++) {
    const item = list[index];
    const key = keyGetter(item);
    if (valueTypeArray) {
      const collection = map.get(key);
      !collection ? map.set(key, [item]) : collection.push(item);
    } else {
      map.set(key, item);
    }
  }
  return map;
};

export const startStopValidation = (
  orderSide: any,
  lastPrice: any,
  priceOrder: any,
  stopPx: any
): string => {
  let message = '';
  if (
    orderSide === TYPE_ORDE_SIDE_ENUM.BUY ||
    orderSide === TYPE_ORDE_SIDE_STRING_ENUM.BUY
  ) {
    lastPrice >= stopPx &&
      (message =
        'Sua ordem stop de compra deve ter o preço stop acima do preço atual do mercado.');
    +priceOrder < stopPx && (message = 'Preço deve ser maior que o stop.');
  } else {
    lastPrice <= stopPx &&
      (message =
        'Sua ordem stop de venda deve ter o preço stop abaixo do preço atual do mercado.');
    +priceOrder > stopPx && (message = 'Preço deve ser menor que o stop.');
  }
  return message;
};

export const DoubleStartStopValidation = (
  orderSide: any,
  lastPrice: any,
  stopGain: any,
  stopLoss: any
): string => {
  let message = '';

  if (
    orderSide === TYPE_ORDE_SIDE_ENUM.BUY ||
    orderSide === TYPE_ORDE_SIDE_STRING_ENUM.BUY
  ) {
    lastPrice <= stopGain &&
      (message = 'O gatilho de ganho deve ser menor ou igual o preço do ativo');
    lastPrice >= stopLoss &&
      (message =
        'O gatilho de perda deve ser maior ou igual o preço do ativo.');

    return message;
  }

  lastPrice >= stopGain &&
    (message = 'O gatilho de ganho deve ser maior ou igual o preço do ativo.');
  lastPrice <= stopLoss &&
    (message = 'O gatilho de perda deve ser menor ou igual o preço do ativo.');
  return message;
};

export const isDI = (stock: string) => {
  return stock?.startsWith('DI1');
};

export const ensurePositiveNumber = (number: number) => {
  return number < 0 ? number * -1 : +number;
};

export const isOrderBuy = (side: string | number): boolean => {
  return (
    side === TYPE_ORDE_SIDE_STRING_ENUM.BUY || side === TYPE_ORDE_SIDE_ENUM.BUY
  );
};

export const removeAccents = (str: string) => {
  const from = 'àáäâèéëêìíïîòóöôùúüûñç';
  const to = 'aaaaeeeiiioooouuuunc';
  let result = '';

  for (let i = 0; i < str.length; i++) {
    const index = from.indexOf(str[i]);
    result += index === -1 ? str[i] : to[index];
  }

  return result;
};
export const getLineBoxAnnotationId = (boxId: string): string => {
  return `${boxId}_box`;
};

export const getLineAuxAnnotationId = (boxId: string): string => {
  return `${boxId}_aux`;
};

export const objectValueOrTrue = (object: any, config: string) => {
  return object && object[config] != undefined ? object[config] : true;
};

export const differenceBetweenObjs = (
  previusData: any,
  currentData: any,
  fieldsToCompare: string[]
): { diff: any; hasChanged: boolean } => {
  const diffValues: any = {};
  let hasChanged = false;

  Object.keys(previusData).forEach((field: string) => {
    if (
      fieldsToCompare.includes(field) &&
      previusData[field] !== currentData[field]
    ) {
      hasChanged = true;
      diffValues[field] = +(
        parseFloat(currentData[field]) - parseFloat(previusData[field])
      );
    }
  });

  return { diff: diffValues, hasChanged };
};

export const maskBRLCurrency = (param: string | number) => {
  let value = param.toString();
  value = String(value);
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
  return `R$ ${value}`;
};

export const removeEmojis = (value: string) => {
  const regex =
    /[\u{1F600}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E0}-\u{1F1FF}\u{1F191}-\u{1F251}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E6}-\u{1F1FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F300}-\u{1F5FF}\u{1F900}-\u{1F9FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E6}-\u{1F1FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{1F1E6}-\u{1F1FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}]/gu;
  const output = value.replace(regex, '');
  return output;
};

//YYYYMMDD -> DD/MM/YYYY
export const formatDate = (
  date: string | number,
  twoDigitsYear?: boolean
): string => {
  const yearIndex = twoDigitsYear ? 2 : 0;
  const dt = String(date).padStart(8, '0');
  return `${dt.slice(6, 9)}/${dt.slice(4, 6)}/${dt.slice(yearIndex, 4)}`;
};

export const VMDateToMonthAndYear = (date: string | number): string => {
  const dt = String(date).padStart(8, '0');
  return `${dt.slice(4, 6)}/${dt.slice(0, 4)}`;
};

export const VMDateToYear = (date: string | number): string => {
  const dt = String(date).padStart(8, '0');
  return `${dt.slice(0, 4)}`;
};

export const VMDateToDayAndMonth = (date: string): string => {
  return date.toString().replace(/^(\d{4})(\d{2})(\d{2})$/, '$3/$2');
};

// YYYYMMDDHHMM -> HH:MM
export const VMDateToHoursAndMin = (date: string | number): string => {
  const dt = String(date).padStart(12, '0');
  return `${dt.slice(dt.length - 4, dt.length - 2)}:${dt.slice(
    dt.length - 2,
    dt.length
  )}`;
};

export const VMDateToDate = (date: string | number): Date => {
  const dt = String(date).padStart(8, '0');
  const day = dt.slice(6, 9);
  const month = dt.slice(4, 6);
  const year = dt.slice(0, 4);
  return new Date(+year, +month - 1, +day);
};

export const daysUntilDate = (
  date: string | number
): { days: number; workingDays: number } => {
  const expirationDate = VMDateToDate(date);
  const currentDate = new Date();
  const days =
    (expirationDate.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24 +
    1;

  let workingDays = 0;
  if (days > 0) {
    for (let i = 1; i <= days; i++) {
      currentDate.setDate(currentDate.getDate() + 1);

      if (currentDate.getDay() === 6 || currentDate.getDay() === 0) {
        continue;
      }
      workingDays++;
    }
  }
  return { days, workingDays };
};

export const VMDateToDayString = (
  date: string
): { value: string; changed: boolean; newsDate: Date } => {
  const currentDate = new Date();
  let stringDate = `${date}`;
  stringDate = stringDate.replace(/-/g, '');

  const year = stringDate.slice(0, 4);
  const month = stringDate.slice(4, 6);
  const day = stringDate.slice(6);
  const newsDate = new Date(+year, +month - 1, +day);

  const differenceInTime = currentDate.getTime() - newsDate.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  if (differenceInDays >= -1 && differenceInDays < 0) {
    return { value: 'Amanhã', changed: true, newsDate };
  } else if (differenceInDays >= 0 && differenceInDays < 1) {
    return { value: 'Hoje', changed: true, newsDate };
  } else if (differenceInDays >= 1 && differenceInDays < 2) {
    return { value: 'Ontem', changed: true, newsDate };
  } else return { value: '', changed: false, newsDate };
};

export const VMDateToFriendlyDate = (date: string): string => {
  const { value, changed } = VMDateToDayString(date);

  return changed ? value : VMDateToDayAndMonth(date);
};

export const VMDateToFriendlyLongDate = (
  date: string,
  locale: string
): string => {
  const { value, changed, newsDate } = VMDateToDayString(date);

  return changed
    ? value
    : newsDate.toLocaleString(locale, {
        month: 'long',
        day: 'numeric',
      });
};

export const formatCPF = (cpf: string): string => {
  cpf = cpf.replace(/\D/g, '');
  cpf = cpf.padStart(11, '0');
  cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  return cpf;
};

export const formatCNPJ = (cnpj: string): string => {
  cnpj = cnpj.replace(/\D/g, '');
  cnpj = cnpj.padStart(14, '0');
  cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  return cnpj;
};

export const handleDocumentMask = (
  document: string,
  type: 'CPF' | 'CNPJ'
): string => {
  if (type === 'CPF') return formatCPF(document);
  return formatCNPJ(document);
};

export const formatBrazilianNumber = (phone: string): string => {
  phone = phone.replace(/\D/g, '');
  if (phone.startsWith('55')) phone = phone.slice(2);
  phone = phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  return phone;
};

export const newDateToVMDate = (qtyDaysBeforeToday: number = 0): string => {
  const currentDate = new Date();
  if (qtyDaysBeforeToday > 0)
    currentDate.setDate(currentDate.getDate() - qtyDaysBeforeToday);
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  return `${year}${month}${day}`;
};

export const dateToVMDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}${month}${day}`;
};

export const calculatePriceOrderByOffset = (params: {
  price: number;
  minPriceIncrement: number;
  offset: number;
  side: string | number;
}): number => {
  let price;
  if (
    params.side === TYPE_ORDE_SIDE_ENUM.BUY ||
    ORDER_SIDE[params.side] === TYPE_ORDE_SIDE_ENUM.BUY
  ) {
    price = params.price + params.minPriceIncrement * params.offset;
  }
  if (
    params.side === TYPE_ORDE_SIDE_ENUM.SELL ||
    ORDER_SIDE[params.side] === TYPE_ORDE_SIDE_ENUM.SELL
  ) {
    price = params.price - params.minPriceIncrement * params.offset;
  }
  return price || params.price;
};

export const editOrderTypeAuction = (
  orderEdit: IOrders,
  order: IOrderModification
): void => {
  let typeOrder = ORDER_TYPES.find(
    (item) => item.cod === orderEdit.cd_order_time_enforce
  );
  if (typeOrder && typeOrder?.type) {
    if (
      typeOrder.cod === '7' &&
      (orderEdit.cd_order_type === 'K' || orderEdit.cd_order_type === '1')
    ) {
      typeOrder = ORDER_TYPES.find(
        (item) => item.cod === '7' && item.type === '1'
      )!;
    }
    order.time_in_force = orderEdit.cd_order_time_enforce;
    order.ord_type = typeOrder.type!;
    order.tipo = typeOrder.desc;
  }
};

export const getBrowserInfo = () => {
  const userAgent: any = navigator.userAgent;
  if (userAgent.indexOf('Chrome') !== -1) {
    return { name: 'Chrome', version: userAgent.match(/Chrome\/([\d.]+)/)[1] };
  }
  if (userAgent.indexOf('Firefox') !== -1) {
    return {
      name: 'Firefox',
      version: userAgent.match(/Firefox\/([\d.]+)/)[1],
    };
  }
  if (userAgent.indexOf('Safari') !== -1) {
    return { name: 'Safari', version: userAgent.match(/Version\/([\d.]+)/)[1] };
  }
  if (userAgent.indexOf('Edge') !== -1) {
    return { name: 'Edge', version: userAgent.match(/Edge\/([\d.]+)/)[1] };
  }
  if (userAgent.indexOf('OPR') !== -1 || userAgent.indexOf('Opera') !== -1) {
    return { name: 'Opera', version: userAgent.match(/OPR\/([\d.]+)/)[1] };
  }
  if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident') !== -1) {
    return {
      name: 'Internet Explorer',
      version: userAgent.match(/MSIE (\d+)/)[1],
    };
  }
  return { name: 'Unknown', version: 'Unknown' };
};

export const getOS = () => {
  const userAgent = navigator.userAgent;
  if (userAgent.indexOf('Win') != -1) return 'Windows';
  if (userAgent.indexOf('Mac') != -1) return 'Mac OS';
  if (userAgent.indexOf('Linux') != -1) return 'Linux';
  if (userAgent.indexOf('Android') != -1) return 'Android';
  if (userAgent.indexOf('iOS') != -1) return 'iOS';
  return 'Unknown';
};

export const isUserAgentDevice = () => {
  const userAgent = navigator.userAgent;
  const Android = () => userAgent.match(/Android/i);
  const BlackBerry = () => userAgent.match(/BlackBerry/i);
  const iOS = () => userAgent.match(/iPhone|iPad|iPod/i);
  const Opera = () => userAgent.match(/Opera Mini/i);
  const WindowsPhone = () =>
    userAgent.match(/IEMobile/i) || userAgent.match(/WPDesktop/i);
  return {
    ANDROID: Android(),
    BB: BlackBerry(),
    IOS: iOS(),
    OPERA: Opera(),
    WINDOWSPHONE: WindowsPhone(),
    ISMOBILE: Android() || BlackBerry() || iOS() || Opera() || WindowsPhone(),
  };
};

export function formatPrice(
  price: string,
  tickSizeDenominator: number
): string {
  if (tickSizeDenominator === 0) return price.split('.')[0];
  let priceFomated = price;
  const priceSplit = price.split('.');
  if (priceSplit.length > 1 && priceSplit[1].length > tickSizeDenominator) {
    priceFomated = `${priceSplit[0]}.${priceSplit[1].substring(
      0,
      tickSizeDenominator
    )}`;
  }
  return priceFomated;
}

export function getAuctionInfos(data: any, previousInfo: any): any {
  if (isAuction(data?.situacao)) {
    return {
      variacao_leilao: data.variacao_leilao || previousInfo?.variacao_leilao,
      preco_leilao: data.preco_leilao || previousInfo?.preco_leilao,
      situacao: data.situacao || previousInfo?.situacao,
      qtde_leilao_nao_atendida:
        data.qtde_leilao_nao_atendida || previousInfo?.qtde_leilao_nao_atendida,
      sentido_leilao_nao_atendida:
        data.sentido_leilao_nao_atendida ||
        previousInfo?.sentido_leilao_nao_atendida,
      qtde_leilao: data.qtde_leilao || previousInfo?.qtde_leilao,
      hora_abert_program:
        data.hora_abert_program || previousInfo?.hora_abert_program,
      variacao_dia: data.variacao_dia || previousInfo?.variacao_dia,
    };
  }
  return { situacao: data?.situacao || previousInfo?.situacao };
}

export function processOrdersFilterQtty(orders: any[], refComp: string = '') {
  let qttyOpen = 0;
  let dayTradeQttyOpen = 0;
  const data: IOrders[] = [];
  if (orders) {
    orders.forEach((item: any) => {
      if (ORDER_OPEN_STATUS_DIC.has(item.cd_order_status)) {
        data.push(item);
        if (item.is_day_trade && item.is_day_trade !== 'false') {
          dayTradeQttyOpen += +item.qtty_left;
        } else {
          qttyOpen += +item.qtty_left;
        }
      }
    });
  }
  return { data, qttyOpen, refComp, dayTradeQttyOpen };
}

export function formattedStockForFractional(stock: any): string {
  if (!stock) {
    return '';
  }
  return isStockFractional(stock?.cd_security_type)
    ? stock.cd_stock_order.replace(/F$/, '')
    : stock.cd_stock_order;
}

export function orderCheckIsFractionalStock(
  stock: ISearchStock,
  orderCdStock: string
): boolean {
  let isSameStock = stock?.cd_stock_order === orderCdStock;
  if (!isSameStock && isStockCashMarket(stock?.type)) {
    isStockCashMarket(stock?.cd_security_type) &&
      (isSameStock = `${stock?.cd_stock_order}F` === orderCdStock);
    isStockFractional(stock?.cd_security_type) &&
      (isSameStock = stock?.cd_stock_order === `${orderCdStock}F`);
  }
  return isSameStock;
}

export const getIncrementValue = (stock: any): number => {
  if (stock.vlMinPriceIncrement) {
    return parseFloat(stock.vlMinPriceIncrement);
  }
  if (stock.cd_segment != 9999) return 0.01;
  return stock.ds_asset == 'WDO' || stock.ds_asset == 'DOL' ? 0.5 : 5;
};

export function getKeyWithHighestValue(object: any): number {
  return +Object.entries(object).reduce(
    (max: any, [key, value]) =>
      <number>value > max.value ? { key, value } : max,
    { key: null, value: 0 }
  ).key;
}

export function hasSortColumn(
  columnApi: any,
  isValueBoolean: boolean = false
):
  | boolean
  | {
      colId: string;
      sort: string;
      sortIndex: number;
    }[] {
  const colState = columnApi.getColumnState();
  const sortState = colState
    .filter((item: any) => {
      return item.sort != null;
    })
    .map((item: any) => {
      return {
        colId: item.colId,
        sort: item.sort,
        sortIndex: item.sortIndex,
      };
    });
  return isValueBoolean ? !!sortState.length : sortState;
}

export function getRandomInfoArray(arr: any[]) {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

export function buildWorkerMessage(
  worker: Worker,
  data: any = {}
): ReadableStreamDefaultController {
  let incomingStreamController!: ReadableStreamDefaultController;
  const incomingStream = new ReadableStream({
    start(controller) {
      incomingStreamController = controller;
    },
  });
  worker.postMessage(
    {
      messageType: 'stream',
      stream: incomingStream,
      ...data,
    },
    [incomingStream]
  );
  return incomingStreamController;
}

export function createdListValues(
  side: TYPE_ORDE_SIDE_ENUM,
  price: string | number,
  qttyBuy: string | null,
  buyQttyBar: string | null,
  maxBuyQtty: number | null,
  sellQtty: string | null,
  sellQttyBar: string | null,
  maxSellQtty: number | null,
  config: any
) {
  const getClass = (price: any) =>
    config.hideAggressor
      ? defAgressorClass(+price, config.minimumPrice, config.maximumPrice)
      : '';

  return {
    sideList: side,
    compraQtde: qttyBuy,
    price: price,
    classAgg: getClass(price),
    vendaQtde: sellQtty,
    barGraphic: buyQttyBar
      ? getWidthOrHeight(buyQttyBar, maxBuyQtty!)
      : getWidthOrHeight(sellQttyBar!, maxSellQtty!),
  };
}

export function buildBookInfo(
  quantItemsTable: number[],
  data: any,
  metadataConfiguration: any,
  tickSizeDenominator: number,
  tickSizeQuantity: number,
  stubBook: any = {}
) {
  const DIFF_CLASSES: any = {
    STAYED: '',
    WENT_UP: 'text-feedback-positive',
    WENT_DOWN: 'text-feedback-negative',
  };
  const metadata = metadataConfiguration;
  const temp: any[] = [];
  const hashPrices: any = {};
  let biggestPrice = 0;
  let lowerPrice = 0;
  const [maxBuyQtty, maxSellQtty] = findTwoLargestValues(data);

  const getDiffString = (
    currentValue: number,
    previousValue: number | null
  ): string => {
    if (previousValue === null) {
      return DIFF_CLASSES.STAYED;
    } else if (currentValue > previousValue) {
      return DIFF_CLASSES.WENT_UP;
    } else if (currentValue < previousValue) {
      return DIFF_CLASSES.WENT_DOWN;
    } else {
      return DIFF_CLASSES.STAYED;
    }
  };

  const getQttyHighLightOffer = (value: any, valuePrev: any) =>
    metadata.hideHighlightOffer || metadata.hideHighlightQuantity
      ? ''
      : getDiffString(value, valuePrev);
  const getQttyHighLightQtty = (value: any, valuePrev: any) =>
    metadata.hideHighlightQuantity || metadata.hideHighlightOffer
      ? ''
      : getDiffString(value, valuePrev);
  const getClass = (price: number, isABR: boolean) =>
    metadata.hideAggressor || isABR
      ? ''
      : defAgressorClass(+price, metadata.minimumPrice, metadata.maximumPrice);
  const formatByTickQuantity = (value: any) =>
    formatByTick(value, tickSizeQuantity);
  const getWidthOrHeightFn = (value: any, maxValue: any) =>
    getWidthOrHeight(value, maxValue);
  const makeInfoToList = (
    index: number,
    buyPrice: number,
    buyIsABR: boolean,
    buyQtty: number,
    buyOffers: number,
    buyBroker: number,
    maxBar: number,
    sellPrice: number,
    sellIsABR: boolean,
    sellQtty: number,
    sellOffers: number,
    sellBroker: number,
    sellQttyDiffClass: string,
    buyQttyDiffClass: string,
    sellOfferDiffClass: string,
    buyOfferDiffClass: string
  ) => {
    return {
      index,
      priceBuy: formatABRByTickDenominator(
        buyPrice,
        buyIsABR,
        tickSizeDenominator
      ),
      classBuy: getClass(buyPrice, buyIsABR),
      qttyBuy: formatByTickQuantity(buyQtty),
      offerBuy: buyOffers,
      barBuy: getWidthOrHeightFn(buyQtty, maxBar),
      priceSell: formatABRByTickDenominator(
        sellPrice,
        sellIsABR,
        tickSizeDenominator
      ),
      buyBroker,
      classSell: getClass(sellPrice, sellIsABR),
      qttySell: formatByTickQuantity(sellQtty),
      offerSell: sellOffers,
      barSell: getWidthOrHeightFn(sellQtty, maxBar),
      sellBroker,
      sellQttyDiffClass,
      buyQttyDiffClass,
      sellOfferDiffClass,
      buyOfferDiffClass,
    };
  };
  const options: Intl.NumberFormatOptions = {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  };
  const isMinusString = (value: any) => (value == '-' ? 0 : value);
  quantItemsTable.forEach((index) => {
    const fieldBuyQtty = `compra_qtde${index}`;
    const fieldSellQtty = `venda_qtde${index}`;
    const fieldSellOffer = `venda_n_ofertas${index}`;
    const fieldBuyOffer = `compra_n_ofertas${index}`;
    const buyPrice = isMinusString(
      data[`compra_valor${index}`] || data[`compra_preco${index}`] || 0
    );
    const sellPrice = isMinusString(
      data[`venda_valor${index}`] || data[`venda_preco${index}`] || 0
    );
    if (!buyPrice && !sellPrice) {
      return;
    }
    const buyQtty = isMinusString(data[fieldBuyQtty] || 0);
    const sellQtty = isMinusString(data[fieldSellQtty] || 0);
    const buyOffers = isMinusString(data[fieldBuyOffer] || 0);
    const sellOffers = isMinusString(data[fieldSellOffer] || 0);
    const buyOfferDiffClass = getQttyHighLightOffer(
      buyOffers,
      stubBook[fieldBuyOffer]
    );
    const sellOfferDiffClass = getQttyHighLightOffer(
      sellOffers,
      stubBook[fieldSellOffer]
    );
    const sellQttyDiffClass = getQttyHighLightQtty(
      sellQtty,
      stubBook[fieldSellQtty]
    );
    const buyQttyDiffClass = getQttyHighLightQtty(
      buyQtty,
      stubBook[fieldBuyQtty]
    );
    const buyIsABR = isABR('BUY', buyQtty, buyPrice);
    const sellIsABR = isABR('SELL', sellQtty, sellPrice);
    const buyQttyFormatted = bigValueFormatter(+buyQtty, options);
    const sellQttyFormatted = bigValueFormatter(+sellQtty, options);
    const maxBar = maxSellQtty > maxBuyQtty ? maxSellQtty : maxBuyQtty;
    const buyBroker = data[`compra_nome_corretora${index}`];
    const sellBroker = data[`venda_nome_corretora${index}`];
    temp.unshift(
      makeInfoToList(
        index,
        buyPrice,
        buyIsABR,
        buyQtty,
        buyOffers,
        buyBroker,
        maxBar,
        sellPrice,
        sellIsABR,
        sellQtty,
        sellOffers,
        sellBroker,
        sellQttyDiffClass,
        buyQttyDiffClass,
        sellOfferDiffClass,
        buyOfferDiffClass
      )
    );
    const buyValues = createdListValues(
      TYPE_ORDE_SIDE_ENUM.BUY,
      buyPrice,
      buyQttyFormatted,
      buyQtty,
      maxBuyQtty,
      null,
      null,
      null,
      metadataConfiguration
    );
    hashPrices[buyPrice]
      ? (hashPrices[buyPrice] = {
          ...hashPrices[buyPrice],
          ...removeNullInObject(buyValues),
        })
      : (hashPrices[buyPrice] = buyValues);
    const sellValues = createdListValues(
      TYPE_ORDE_SIDE_ENUM.SELL,
      sellPrice,
      null,
      null,
      null,
      sellQttyFormatted,
      sellQtty,
      maxSellQtty,
      metadataConfiguration
    );
    hashPrices[sellPrice]
      ? (hashPrices[sellPrice] = {
          ...hashPrices[sellPrice],
          ...removeNullInObject(sellValues),
        })
      : (hashPrices[sellPrice] = sellValues);
    stubBook[fieldSellQtty] = sellQtty;
    stubBook[fieldBuyQtty] = buyQtty;
    stubBook[fieldBuyOffer] = buyOffers;
    stubBook[fieldSellOffer] = sellOffers;
    biggestPrice = +buyPrice > biggestPrice ? +buyPrice : biggestPrice;
    const lower =
      +buyPrice == 0
        ? +sellPrice
        : +sellPrice == 0
        ? +buyPrice
        : Math.min(+buyPrice, +sellPrice);
    lowerPrice =
      lower != 0
        ? lowerPrice == 0
          ? lower
          : Math.min(lower, lowerPrice)
        : lowerPrice;
  });
  if (metadata.isChart) {
    let lPrice = lowerPrice;
    while (lPrice <= biggestPrice) {
      if (!hashPrices[lPrice.toFixed(tickSizeDenominator)]) {
        hashPrices[lPrice] = createdListValues(
          TYPE_ORDE_SIDE_ENUM.SELL,
          lPrice,
          null,
          null,
          null,
          '0',
          '0',
          maxSellQtty,
          metadataConfiguration
        );
        temp.push(
          makeInfoToList(
            temp[temp.length - 1].index + 1,
            0,
            false,
            0,
            0,
            0,
            0,
            lPrice,
            false,
            0,
            0,
            0,
            '',
            '',
            '',
            ''
          )
        );
      }
      lPrice = parseFloat(
        (lPrice + (metadata.priceIncrement as number)).toFixed(
          tickSizeDenominator
        )
      );
    }
  }
  return {
    list: temp,
    buy: data.pressao_compradora,
    sell: data.pressao_vendedora,
    hashPrices,
    biggestPrice,
  };
}

export function createArrayIndex(size: number, isReverse: boolean) {
  const newArray = Array.from({ length: size }, (_, i) => i + 1);
  if (isReverse) {
    return newArray.reverse();
  }
  return newArray;
}

export function buildStockSearchLabel(
  item: ISearchStock,
  isTerminal: boolean = false
): string {
  if (item.ds_segment.includes('BM&F')) {
    const synonymous = item.is_synonymous ? item.synonymous_nickname : '';
    const venc = formatDate(item.dt_maturity, true);
    return `<span class="hstack align-items-center gap-1">${item.cd_stock_order} <span class="fs-7 text-neutral-medium">${synonymous} ${venc}</span></span>`;
  }
  if (item.ds_segment.includes('Opções')) {
    return `<span class="hstack align-items-center gap-1">${
      item.cd_stock_order
    } <span class="fs-7 text-neutral-medium">${item.opc_tipo} ${formatDate(
      item.dt_expiration_option,
      true
    )} ${formatterNumber(+item.opc_strike)}</span></span>`;
  }
  return `<span class="hstack align-items-center gap-1">${
    item.cd_stock_order
  } <span class="fs-7 text-neutral-medium">${
    isTerminal ? item.tp_specification : item.nm_instrument
  }</span></span>`;
}

export function debug(message: string): void {
  const currentDatetime = new Date();
  const formattedTime = currentDatetime.toLocaleTimeString('en-US', {
    hour12: false,
  });
  console.log(`[${formattedTime}] ${message}`);
}

export function getUserName(
  fullName: string,
  maxCharacteres: number = 0
): string {
  if (!fullName) return '';
  let name = fullName.split(' ')[0];
  if (!name || (maxCharacteres && name?.length > maxCharacteres)) {
    return '';
  }
  name = name.toLowerCase();
  name = name.charAt(0).toUpperCase() + name.slice(1, name.length);
  return name;
}

export function isRowSelectedStock(
  cdStock: string,
  globalCdStock: string,
  cdStockOrder: string,
  synonymousRelated: string
) {
  return (
    cdStock === globalCdStock ||
    cdStock === cdStockOrder ||
    cdStock === synonymousRelated
  );
}

export function groupMapKeys(map: any) {
  const grouped: any = {};

  map.forEach((_: any, key: any) => {
    const [channel, item] = key.split('-');

    if (!grouped[channel]) {
      grouped[channel] = [];
    }

    grouped[channel].push(item);
  });

  return Object.keys(grouped).map((channel) => ({
    channel,
    items: grouped[channel],
  }));
}
