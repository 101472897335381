<workspace-doc-header
  css="w-100 d-flex header-wrapper border-bottom"
  [item]="component"
>
  <fla-workspace-doc-header-item [enableContent]="true">
    <app-rt-list-selector
      [refComponent]="refComponent"
      [componentId]="componentId"
      [linked]="linked"
      [initialListId]="initialListId"
      [displayMoversLists]="false"
      (handlerListSelected)="handlerListChange($event)"
      (changeNotListPersonal)="notListPersonal($event)"
    ></app-rt-list-selector>
  </fla-workspace-doc-header-item>
  <ng-container *ngIf="!isMoversList">
    <fla-workspace-doc-header-item [enableContent]="true">
      <app-rocket-icons
        class="stock-analysis-selector-icon ml-2"
        name="stock_analysis_x_axis"
      ></app-rocket-icons>
      <app-dropdown>
        <button
          class="nav-link pl-1 p-2 lh-sm round-top dropdown-toggle bg-transparent fs-6"
          appDropdownButton
          [rtDropnClose]="true"
          title="Definir Rentabilidade"
        >
          {{ profitability[stockAnalysisConfigs.profitability] }}
        </button>
        <div class="list-max-height" *ngFor="let key of profitabilityKeys">
          <button
            class="dropdown-item bg-transparent fs-6"
            type="button"
            (click)="changeConfig(key, 'profitability')"
          >
            {{ profitability[key] }}
          </button>
          <hr *ngIf="key === 'THISYEAR'" />
        </div>
      </app-dropdown>
    </fla-workspace-doc-header-item>
    <fla-workspace-doc-header-item [enableContent]="true">
      <app-rocket-icons
        class="stock-analysis-selector-icon ml-2"
        name="stock_analysis_y_axis"
      ></app-rocket-icons>
      <app-dropdown>
        <button
          class="nav-link pl-1 p-2 lh-sm round-top dropdown-toggle bg-transparent fs-6"
          appDropdownButton
          [rtDropnClose]="true"
          title="Definir Volatilidade"
        >
          {{ volatility[stockAnalysisConfigs.volatility] }}
        </button>
        <div class="list-max-height" *ngFor="let key of volatilityKeys">
          <button
            class="dropdown-item fs-6"
            (click)="changeConfig(key, 'volatility')"
          >
            {{ volatility[key] }}
          </button>
        </div>
      </app-dropdown>
    </fla-workspace-doc-header-item>
    <fla-workspace-doc-header-item [enableContent]="true">
      <app-rocket-icons
        class="stock-analysis-selector-icon ml-2"
        name="stock_analysis_z_axis"
      ></app-rocket-icons>
      <app-dropdown>
        <button
          type="button"
          class="nav-link pl-1 p-2 lh-sm round-top dropdown-toggle bg-transparent fs-6"
          appDropdownButton
          [rtDropnClose]="true"
          title="Definir variação"
        >
          {{ variation[stockAnalysisConfigs.variation] }}
        </button>

        <div class="list-max-height">
          <button
            class="dropdown-item bg-transparent fs-6"
            (click)="changeConfig('VARIATION', 'variation')"
          >
            Variação 1 Dia
          </button>
        </div>
        <div class="list-max-height">
          <button
            class="dropdown-item bg-transparent fs-6"
            (click)="changeConfig('MCAP', 'variation')"
          >
            MCAP
          </button>
          <hr />
        </div>
        <div>
          <ng-template *ngTemplateOutlet="volumes"></ng-template>
        </div>
      </app-dropdown>
    </fla-workspace-doc-header-item>
  </ng-container>
</workspace-doc-header>

<ng-template #volumes>
  <button
    class="dropdown-submenu dropdown-item arrow position-relative pr-0 bg-transparent fs-6"
    data-bs-toggle="collapse"
    href="#volumesList"
    aria-expanded="false"
    aria-controls="volumesList"
  >
    Volumes
  </button>
  <div class="collapse" id="volumesList">
    <button
      class="dropdown-item bg-transparent fs-6"
      (click)="changeConfig('VOLUME', 'variation')"
    >
      Volume 1 Dia
    </button>
    <button
      class="dropdown-item bg-transparent fs-6"
      (click)="changeConfig('YESTERDAY_VOLUME', 'variation')"
    >
      Volume Ontem
    </button>
    <button
      class="dropdown-item bg-transparent fs-6"
      (click)="changeConfig('ONE_WEEK', 'variation')"
    >
      Volume 1 Semana
    </button>
    <button
      class="dropdown-item bg-transparent fs-6"
      (click)="changeConfig('ONE_MONTH', 'variation')"
    >
      Volume 1 Mês
    </button>
    <button
      class="dropdown-item bg-transparent fs-6"
      (click)="changeConfig('ONE_YEAR', 'variation')"
    >
      Volume 1 Ano
    </button>
  </div>
</ng-template>
