import { ToastService } from '@shared/services/toast.service';
import {
  DoubleStartStopValidation,
  startStopValidation,
} from 'src/app/utils/utils.functions';

export class EditingOrder {
  constructor(private toastService: ToastService) {}

  public orderDoubleStartStop(
    order: any,
    editprice: number,
    lastPrice: number
  ):
    | {
        personalized_gain_price: number | string;
        personalized_gain_trigger: number | string;
        personalized_loss_price: number | string;
        personalized_loss_trigger: number | string;
      }
    | undefined {
    let temp = 0;
    let personalized_gain_price: number | string;
    let personalized_gain_trigger: number | string;
    let personalized_loss_price: number | string;
    let personalized_loss_trigger: number | string;
    let fieldsOrder;
    if (order.isGain) {
      personalized_gain_trigger = editprice;
      temp = +order.personalized_gain_trigger - editprice;
      personalized_gain_price = +order.price - temp;
      personalized_loss_price = order.personalized_loss_price;
      personalized_loss_trigger = order.personalized_loss_trigger;
      this.doubleStartStopTriggerValidation(
        lastPrice,
        order.side,
        +personalized_gain_trigger,
        +personalized_loss_trigger
      ) &&
        (fieldsOrder = {
          personalized_gain_price,
          personalized_gain_trigger,
          personalized_loss_price,
          personalized_loss_trigger,
        });
    } else {
      personalized_loss_trigger = editprice;
      temp = +order.personalized_loss_trigger - editprice;
      personalized_loss_price = +order.price - temp;
      personalized_gain_price = order.personalized_gain_price;
      personalized_gain_trigger = order.personalized_gain_trigger;
      this.doubleStartStopTriggerValidation(
        lastPrice,
        order.side,
        +personalized_gain_trigger,
        +personalized_loss_trigger
      ) &&
        (fieldsOrder = {
          personalized_gain_price,
          personalized_gain_trigger,
          personalized_loss_price,
          personalized_loss_trigger,
        });
    }
    return fieldsOrder;
  }

  private doubleStartStopTriggerValidation(
    lastPrice: number,
    side: any,
    stopGain: number,
    stopLoss: number
  ): boolean {
    const message = DoubleStartStopValidation(
      side,
      lastPrice,
      stopGain,
      stopLoss
    );
    message && this.toastService.showToast('warning', message);
    return message ? false : true;
  }

  public orderStop(
    order: any,
    editprice: number,
    lastPrice: number
  ): {
    price: number;
    price_stop: number;
  } | null {
    let price;
    let price_stop;
    price_stop = editprice;

    const temp = +order.price_stop - +editprice;
    price = +order.price - temp;
    if (
      this.startStopTriggerValidation(lastPrice, price_stop, order.side, price)
    )
      return null;
    return { price, price_stop };
  }

  private startStopTriggerValidation(
    lastPrice: number,
    stopPx: any,
    side: any,
    priceOder: any
  ): boolean {
    const message = startStopValidation(side, lastPrice, priceOder, stopPx);
    message && this.toastService.showToast('warning', message);
    return message ? true : false;
  }
}
