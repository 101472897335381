import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-table-tooltip',
  template: `
    <span [tooltip]="nmCompany">
      {{ cdStock }}
    </span>
  `,
  standalone: true,
  imports: [CommonModule, TooltipsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableStockNameComponent implements ICellRendererAngularComp {
  public nmCompany: string = '';
  public cdStock: string = '';
  public isSelected: boolean = false;

  public agInit(params: ICellRendererParams): void {
    this.init(params);
  }

  public refresh(params: ICellRendererParams): boolean {
    this.init(params);
    return true;
  }

  private init(params: ICellRendererParams): void {
    if (params.data) {
      this.cdStock = params.data.cd_stock;
      this.nmCompany = params.data.nm_company;
    }
  }
}
