<div
  *ngIf="authenticator"
  class="h-100 d-flex flex-column justify-content-between"
>
  <div>
    <ng-container
      *ngIf="
        authenticator.id === 'NITRO' || authenticator.id === 'TRADEMAP';
        else elseTemplate
      "
    >
      <app-token-authenticator-nitro [authenticator]="authenticator">
        <ng-template *ngTemplateOutlet="actionButtons"></ng-template>
      </app-token-authenticator-nitro>
    </ng-container>
    <ng-template #elseTemplate>
      <p class="fs-5 pb-3">
        Para configurar seu segundo fator de autenticação baixe o
        <b>{{ authenticator.name }}</b> e siga os passos abaixo:
      </p>
      <app-token-authenticator-other-apps
        #otherApps
        [authenticator]="authenticator"
        (disableButtonChange)="onButtonDisabledChange($event)"
        (changeLoading)="onChangeLoading($event)"
      >
      </app-token-authenticator-other-apps>
    </ng-template>
  </div>
  <ng-container *ngIf="authenticator.id !== 'TRADEMAP'">
    <ng-template *ngTemplateOutlet="actionButtons"></ng-template>
  </ng-container>
</div>
<ng-template #actionButtons>
  <div
    class="w-100 h-auto d-flex justify-content-end align-items-end px-0"
    [ngClass]="{ footer: authenticator?.id === 'NITRO' }"
  >
    <rocket-button
      [label]="authenticator?.id === 'TRADEMAP' ? 'Ver depois' : 'Anterior'"
      type="fill"
      css="mr-4"
      (rocketClick)="emitReturn()"
    ></rocket-button>
    <rocket-button
      label="Confirmar"
      [loading]="loading"
      [isDisabled]="
        authenticator?.id !== 'NITRO' &&
        authenticator?.id !== 'TRADEMAP' &&
        buttonDisabled
      "
      (rocketClick)="confirm()"
    ></rocket-button>
  </div>
</ng-template>
