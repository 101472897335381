import { IOrderType } from '@core/interface';
import { Dictionary } from 'src/app/core/models/dictionary.model';

export const ORDER_ALL_STATUS_DIC = new Dictionary<string>();
ORDER_ALL_STATUS_DIC.set('AUTH', 'Pendente de Autorização');
ORDER_ALL_STATUS_DIC.set('PEND', 'Agendada');
ORDER_ALL_STATUS_DIC.set('AGUA', 'Confirmada');
ORDER_ALL_STATUS_DIC.set('PARC', 'Parcialmente executada');
ORDER_ALL_STATUS_DIC.set('ALTE', 'Alterada');
ORDER_ALL_STATUS_DIC.set('EXEC', 'Executada');
ORDER_ALL_STATUS_DIC.set('CANC', 'Cancelada');
ORDER_ALL_STATUS_DIC.set('REJE', 'Rejeitada');
ORDER_ALL_STATUS_DIC.set('SUSP', 'Suspensa');
ORDER_ALL_STATUS_DIC.set('PROC', 'Em processamento');
ORDER_ALL_STATUS_DIC.set('SALT', 'Alteração agendada');
ORDER_ALL_STATUS_DIC.set('SCAN', 'Cancelamento agendado');
ORDER_ALL_STATUS_DIC.set('PCAN', 'Parcialmente Cancelada');
ORDER_ALL_STATUS_DIC.set('PECA', 'Pendente de Cancelamento');
ORDER_ALL_STATUS_DIC.set('PEAL', 'Pendente de Alteração');
ORDER_ALL_STATUS_DIC.set('ACPT', 'Termo aceito');
ORDER_ALL_STATUS_DIC.set('PASS', 'Termo rejeitado');
ORDER_ALL_STATUS_DIC.set('ESTF', 'Estado Final');

export const ORDER_CONF_STATUS_DIC = new Dictionary<string>();
ORDER_CONF_STATUS_DIC.set('AGUA', 'AGUA');

export const ORDER_EXEC_STATUS_DIC = new Dictionary<string>();
ORDER_EXEC_STATUS_DIC.set('EXEC', 'EXEC');

export const ORDER_REJE_STATUS_DIC = new Dictionary<string>();
ORDER_REJE_STATUS_DIC.set('REJE', 'REJE');

export const ORDER_CHAN_STATUS_DIC = new Dictionary<string>();
ORDER_CHAN_STATUS_DIC.set('ALTE', 'ALTE');

export const ORDER_SUSP_STATUS_DIC = new Dictionary<string>();
ORDER_SUSP_STATUS_DIC.set('SUSP', 'SUSP');

export const ORDER_SCHE_STATUS_DIC = new Dictionary<string>();
ORDER_SCHE_STATUS_DIC.set('PEND', 'PEND');

export const ORDER_PEN_STATUS_DIC = new Dictionary<string>();
ORDER_PEN_STATUS_DIC.set('PECA', 'PECA');
ORDER_PEN_STATUS_DIC.set('PEAL', 'PEAL');

export const ORDER_CANCEL_STATUS_DIC = new Dictionary<string>();
ORDER_CANCEL_STATUS_DIC.set('CANC', 'CANC');

export const ORDER_PAR_STATUS_DIC = new Dictionary<string>();
ORDER_PAR_STATUS_DIC.set('PARC', 'PARC');
ORDER_PAR_STATUS_DIC.set('PCAN', 'PCAN');

export const ORDER_OPEN_STATUS_DIC = new Dictionary<string>();
ORDER_OPEN_STATUS_DIC.set('AUTH', 'AUTH');
ORDER_OPEN_STATUS_DIC.set('PEND', 'PEND');
ORDER_OPEN_STATUS_DIC.set('AGUA', 'AGUA');
ORDER_OPEN_STATUS_DIC.set('PARC', 'PARC');
ORDER_OPEN_STATUS_DIC.set('PROC', 'PROC');
ORDER_OPEN_STATUS_DIC.set('ALTE', 'ALTE');
ORDER_OPEN_STATUS_DIC.set('ACPT', 'ACPT');
ORDER_OPEN_STATUS_DIC.set('PEAL', 'PEAL');
ORDER_OPEN_STATUS_DIC.set('PECA', 'PECA');
ORDER_OPEN_STATUS_DIC.set('SALT', 'SALT');

export const ORDER_CLOSE_STATUS_DIC = new Dictionary<string>();
ORDER_CLOSE_STATUS_DIC.set('EXEC', 'EXEC');
ORDER_CLOSE_STATUS_DIC.set('REJE', 'REJE');
ORDER_CLOSE_STATUS_DIC.set('CANC', 'CANC');

export const ORDER_TYPE_DIC = new Dictionary<IOrderType>();

ORDER_TYPE_DIC.set('B', {
  type: 'B',
  name: 'COMPRA',
  description: 'Compra',
  color: '#1DA1F2',
  boxTextColor: '#FFFFFF',
});

ORDER_TYPE_DIC.set('S', {
  type: 'S',
  name: 'VENDA',
  description: 'Venda',
  color: '#FBBB00',
  boxTextColor: '#000000',
});

ORDER_TYPE_DIC.set('B4', {
  type: 'B4',
  name: 'STOP_COMPRA',
  description: 'Compra Stop',
  color: '#1DA1F2',
  boxTextColor: '#FFFFFF',
});

ORDER_TYPE_DIC.set('S4', {
  type: 'B4',
  name: 'STOP_VENDA',
  description: 'Venda Stop',
  color: '#FBBB00',
  boxTextColor: '#000000',
});
