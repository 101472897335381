import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentsService } from './components.service';
import { IWorkSpaceComponet, WorkspaceType } from '@core/interface';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { WorkspaceApi } from '../workspace/workspace-api.service';
import { WorkspaceComponentService } from '@modules/home/service/workspace-component.service';
import { ParaComecar } from '@core/workspace/layout/initial-workspaces';
import { Mercado } from '@core/workspace/layout/initial-workspaces/Mercado';
import { Pro } from '@core/workspace/layout/initial-workspaces/Pro';
import { RaioX } from '@core/workspace/layout/initial-workspaces/RaioX';
import { AnaliseAtivos } from '@core/workspace/layout/initial-workspaces/AnaliseAtivos';
import { AnaliseFluxo } from '@core/workspace/layout/initial-workspaces/AnaliseFluxo';
import { Opcoes } from '@core/workspace/layout/initial-workspaces/Opcoes';

export enum WORKSPACE_TEMPLATES {
  PARA_COMECAR = 'PARA_COMECAR',
  MERCADO = 'MERCADO',
  PRO = 'PRO',
  RAIO_X = 'RAIO_X',
  ANALISE_ATIVOS = 'ANALISE_ATIVOS',
  ANALISE_FLUXO = 'ANALISE_FLUXO',
  OPCOES = 'OPCOES',
}

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService extends WorkspaceApi {
  constructor(
    http: HttpClient,
    private _components: ComponentsService,
    private stock: GlobalSelectedStockSubscription,
    private workspaceComponentService: WorkspaceComponentService
  ) {
    super(http);
  }

  addWorkspace(data: {
    name: string;
    active: boolean;
    isDefault?: boolean;
    cod?: string;
    type: WorkspaceType;
  }) {
    return this.create(data);
  }

  removeWorkspace(id: string) {
    return this.remove(id).pipe();
  }

  cloneWorkspace(id: string, name: string) {
    return this.clone(id, name);
  }

  generateDefaultWorkspace(idWs: string, template: WORKSPACE_TEMPLATES) {
    const components = this._getWsComponentsByTemplate(template, idWs);
    this._components.createComponents(components).subscribe((res: any) => {
      const workspace: any =
        this.workspaceComponentService.getWorkspaceById(idWs);
      workspace.components = res;
      workspace.visible = true;
      this.workspaceComponentService.createdOrUpdateWorkspace(workspace, true);
      this.workspaceComponentService.changeActiveWorkspace(workspace);
    });
  }

  private _getWsComponentsByTemplate(
    template: WORKSPACE_TEMPLATES,
    idWs: string
  ) {
    const stock = this.stock.getGlobalStockSelected();
    if (template === WORKSPACE_TEMPLATES.PARA_COMECAR)
      return new ParaComecar(idWs, stock).getComponents();
    if (template === WORKSPACE_TEMPLATES.MERCADO)
      return new Mercado(idWs, stock).getComponents();
    if (template === WORKSPACE_TEMPLATES.PRO)
      return new Pro(idWs, stock).getComponents();
    if (template === WORKSPACE_TEMPLATES.RAIO_X)
      return new RaioX(idWs, stock).getComponents();
    if (template === WORKSPACE_TEMPLATES.ANALISE_ATIVOS)
      return new AnaliseAtivos(idWs, stock).getComponents();
    if (template === WORKSPACE_TEMPLATES.ANALISE_FLUXO)
      return new AnaliseFluxo(idWs, stock).getComponents();
    if (template === WORKSPACE_TEMPLATES.OPCOES)
      return new Opcoes(idWs, stock).getComponents();
    return new ParaComecar(idWs, stock).getComponents();
  }

  public removeWorkspaceComponents(components: IWorkSpaceComponet[]): void {
    this._components.removeComponents(components);
  }
}
