import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { EHorizontalTextPosition } from 'scichart/types/TextPosition';
import { FastColumnRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastColumnRenderableSeries';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { XyDataSeries } from 'scichart/Charting/Model/XyDataSeries';
import { CANDLE_IDS } from '../constants/tiger-chart.constants';
import {
  ascendingSort,
  descendingSort,
  toValue,
} from 'src/app/utils/utils.functions';
import { BookPaletteProvider } from '../colors';
import { IColumnBook } from '../interface/column-book.interface';
import { DataLabelProvider } from 'scichart/Charting/Visuals/RenderableSeries/DataLabels/DataLabelProvider';
import { DataLabelState } from 'scichart/Charting/Visuals/RenderableSeries/DataLabels/DataLabelState';
import { formatNumber } from '@angular/common';
import { ECoordinateMode } from 'scichart/Charting/Visuals/Annotations/AnnotationBase';
import {
  EHorizontalAnchorPoint,
  EVerticalAnchorPoint,
} from 'scichart/types/AnchorPoint';
import { HitTestInfo } from 'scichart/Charting/Visuals/RenderableSeries/HitTest/HitTestInfo';
import { TextAnnotation } from 'scichart/Charting/Visuals/Annotations/TextAnnotation';
import { StackedColumnCollection } from 'scichart/Charting/Visuals/RenderableSeries/StackedColumnCollection';
import { StackedColumnRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/StackedColumnRenderableSeries';
import { VolumeData } from '@shared/channel/interface/volume.channel.interface';
import { Dictionary } from '@core/models';
import { StackedColumnCollectionTiger } from './stacked-column-collection-tiger';
import { deepClone } from '@shared/rocket-components/utils';
import { Subject, map, tap } from 'rxjs';
import { ISearchStock } from '@core/interface';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
@Injectable()
export class ColumnBarService {
  public hashBook: any = {};
  private volumeAtPriceXValues: number[] = [];
  private volumeAtPriceYValues: any = {};
  volumeAtPriceObj = new Dictionary<{
    qtty_buyer: number;
    qtty_seller: number;
    qtty_rlp: number;
    qtty_direct: number;
    qtty_total: number;
    arrayIndex: number;
  }>();
  stackedColumnCollection = new Dictionary<StackedColumnCollection>();
  private instanceVolumeAtPrice$ = new Subject<{
    baseChart: TWebAssemblyChart;
    volumes: VolumeData[];
    xAxisId: string;
    yAxisId: string;
    refComponent: string;
    stock: ISearchStock;
    maxVolumeAtPrice: number;
    clearVAP: boolean;
  }>();
  private addDataVolumeAtPrice$ = new Subject<{
    baseChart: TWebAssemblyChart;
    volumes: VolumeData[];
    maxVolumeAtPrice: number;
  }>();

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private themeService: ThemePreferencesService
  ) {
    this.createArrayInObject();
    this.addDataVolumeAtPrice$
      .pipe(
        map((data) => {
          const volumes = data.volumes.sort((a, b) =>
            descendingSort(a.key, b.key)
          );
          return {
            volumes: deepClone(volumes),
            baseChart: data.baseChart,
            maxVolumeAtPrice: data.maxVolumeAtPrice,
          };
        }),
        map((data) => {
          data.volumes.forEach((volume: VolumeData) => {
            const { qtty_buyer, qtty_seller, qtty_rlp, qtty_direct } =
              this.getNewSizeQtty(volume, data.maxVolumeAtPrice);
            if (!qtty_buyer && !qtty_seller && !qtty_rlp && !qtty_direct)
              return;
            this.addVolumeAtPrice(deepClone(volume), data.maxVolumeAtPrice);
          });
          return { baseChart: data.baseChart };
        })
      )
      .subscribe((data) => {
        const columns = data.baseChart.sciChartSurface.renderableSeries.getById(
          CANDLE_IDS.VOLUME_X_PRICE_SERIES
        ) as StackedColumnCollectionTiger;
        console.log('columns', columns);
        console.log('??', this.volumeAtPriceXValues, this.volumeAtPriceYValues);
        columns.getVisibleSeries().forEach((serie) => {
          const dataSeries = serie.dataSeries as XyDataSeries;
          const val = this.volumeAtPriceYValues[dataSeries.id];
          dataSeries.appendRange(this.volumeAtPriceXValues, val);
        });
      });
    this.instanceVolumeAtPrice$
      .pipe(
        tap(({ clearVAP, baseChart }) => {
          if (clearVAP) {
            this.clearVAP(baseChart);
          }
        })
      )
      .subscribe((data) => {
        const columns = data.baseChart.sciChartSurface.renderableSeries.getById(
          CANDLE_IDS.VOLUME_X_PRICE_SERIES
        ) as StackedColumnCollectionTiger;
        console.log('columns', columns);
        if (!columns) {
          this.createChartVolumeAtPrice(
            data.baseChart,
            data.xAxisId,
            data.yAxisId,
            data.refComponent,
            data.volumes,
            data.maxVolumeAtPrice
          );
          return;
        }
        console.log('instance??');
        this.addDataVolumeAtPrice$.next({
          baseChart: data.baseChart,
          volumes: data.volumes,
          maxVolumeAtPrice: data.maxVolumeAtPrice,
        });
      });
  }

  private getNewSizeQtty(
    volume: VolumeData,
    maxVolumeAtPrice: number
  ): {
    qtty_buyer: number;
    qtty_seller: number;
    qtty_rlp: number;
    qtty_direct: number;
  } {
    const minSize = 1;
    const qttyHasValue = this.getQttyHasValue(volume);
    const newSize = minSize / qttyHasValue;
    const qtty_total = (volume.qtty_total / maxVolumeAtPrice) * 100;
    const isTooSmall = qtty_total < minSize;
    const qtty_buyer = !isTooSmall
      ? (volume.qtty_buyer / maxVolumeAtPrice) * 100
      : volume.qtty_buyer
      ? newSize
      : 0;
    const qtty_seller = !isTooSmall
      ? (volume.qtty_seller / maxVolumeAtPrice) * 100
      : volume.qtty_seller
      ? newSize
      : 0;
    const qtty_rlp = !isTooSmall
      ? (volume.qtty_rlp / maxVolumeAtPrice) * 100
      : volume.qtty_rlp
      ? newSize
      : 0;
    const qtty_direct = !isTooSmall
      ? (volume.qtty_direct / maxVolumeAtPrice) * 100
      : volume.qtty_direct
      ? newSize
      : 0;
    return { qtty_buyer, qtty_seller, qtty_rlp, qtty_direct };
  }

  private getQttyHasValue(volume: VolumeData): number {
    let qttyHasValue = 0;
    if (volume.qtty_buyer) {
      qttyHasValue++;
    }
    if (volume.qtty_seller) {
      qttyHasValue++;
    }
    if (volume.qtty_rlp) {
      qttyHasValue++;
    }
    if (volume.qtty_direct) {
      qttyHasValue++;
    }
    return qttyHasValue;
  }

  private makeHashBook(type: string, value: number, qtty: any, element: any) {
    if (!isNaN(value)) {
      this.hashBook[`${element.index}_${type}`] = {
        type,
        value,
        qtty: toValue(qtty),
      };
    } else if (
      this.hashBook[`${element.index}_${type}`] &&
      !isNaN(toValue(qtty))
    ) {
      this.hashBook[`${element.index}_${type}`] = {
        type,
        value: this.hashBook[`${element.index}_${type}`].value,
        qtty: toValue(qtty),
      };
    }
  }

  addBook(
    baseChart: TWebAssemblyChart,
    data: any,
    xAxisId: string,
    idBarYAxis: string,
    isVisible: boolean
  ): {
    dataSeries: XyDataSeries;
    yValuesObj: IColumnBook[];
    renderableSeries: FastColumnRenderableSeries;
  } {
    const { list }: any = data;
    const xValues: number[] = [];
    const yValuesObj: IColumnBook[] = [];
    const renderableSeries = baseChart.sciChartSurface.renderableSeries.getById(
      CANDLE_IDS.BOOK_SERIES
    );
    const allNaN = [];
    list.forEach((element: any) => {
      const priceBuy = toValue(element.priceBuy);
      const priceSell = toValue(element.priceSell);
      if (element.priceBuy === 'ABR' || element.priceSell === 'ABR') {
        return;
      }
      this.makeHashBook('sell', priceSell, element.qttySell, element);
      this.makeHashBook('buy', priceBuy, element.qttyBuy, element);
      if (isNaN(priceBuy) && isNaN(priceSell)) {
        allNaN.push(priceBuy);
        allNaN.push(priceSell);
      }
      if (!isNaN(priceBuy)) {
        xValues.push(priceBuy);
        yValuesObj.push(this.hashBook[`${element.index}_buy`]);
      }
      if (!isNaN(priceSell)) {
        xValues.push(priceSell);
        yValuesObj.push(this.hashBook[`${element.index}_sell`]);
      }
    });
    if (allNaN.length === xValues.length) {
      return {
        dataSeries: data,
        yValuesObj: yValuesObj,
        renderableSeries: renderableSeries as FastColumnRenderableSeries,
      };
    }
    xValues.sort((a, b) => ascendingSort(a, b));
    yValuesObj.sort((a, b) => ascendingSort(a.value, b.value));
    const yValues: number[] = yValuesObj.map((yValue) => {
      const val = yValue ? yValue.qtty : NaN;
      return val;
    });
    if (renderableSeries) {
      const data = renderableSeries.dataSeries as XyDataSeries;
      renderableSeries.paletteProvider = new BookPaletteProvider(
        yValuesObj,
        !renderableSeries.isHovered
      );
      data.clear();
      data.appendRange(xValues, yValues);
      return {
        dataSeries: data,
        yValuesObj: yValuesObj,
        renderableSeries: renderableSeries as FastColumnRenderableSeries,
      };
    }
    const bookDataSeries = new XyDataSeries(baseChart.wasmContext, {
      xValues,
      yValues,
    });
    const column = this.getColumnBook(
      baseChart,
      idBarYAxis,
      xAxisId,
      bookDataSeries,
      isVisible
    );
    (column.dataLabelProvider as DataLabelProvider).horizontalTextPosition =
      EHorizontalTextPosition.Right;
    (column.dataLabelProvider as DataLabelProvider).getText = (
      state: DataLabelState
    ) => {
      return formatNumber(state.yVal(), this.locale, `1.0-0`);
    };
    baseChart.sciChartSurface.renderableSeries.add(column);
    column.paletteProvider = new BookPaletteProvider(yValuesObj, true);
    return {
      dataSeries: bookDataSeries,
      yValuesObj: yValuesObj,
      renderableSeries: column,
    };
  }

  private getColumnBook(
    baseChart: TWebAssemblyChart,
    yAxisId: string,
    xAxisId: string,
    dataSeries: XyDataSeries,
    isVisible: boolean
  ): FastColumnRenderableSeries {
    return new FastColumnRenderableSeries(baseChart.wasmContext, {
      strokeThickness: 0,
      dataPointWidth: 0.8,
      yAxisId,
      xAxisId,
      dataSeries,
      isVisible,
      id: CANDLE_IDS.BOOK_SERIES,
    });
  }

  addAnnotation(
    baseChart: TWebAssemblyChart,
    hitTestInfo: HitTestInfo,
    xAxisId: string,
    formatter: any,
    textColor: string
  ) {
    const y = baseChart.sciChartSurface.yAxes.getById('DefaultAxisId');
    this.removeAnnotation(baseChart);
    const textAnnotation = new TextAnnotation({
      id: 'column_bar_text_value_annotation',
      xCoordinateMode: ECoordinateMode.Pixel,
      yCoordinateMode: ECoordinateMode.DataValue,
      y1: y.getCurrentCoordinateCalculator().getDataValue(hitTestInfo.xCoord),
      x1: hitTestInfo.yCoord,
      text: formatter(hitTestInfo.yValue),
      fontSize: 15,
      xAxisId,
      horizontalAnchorPoint: EHorizontalAnchorPoint.Right,
      verticalAnchorPoint: EVerticalAnchorPoint.Center,
      xCoordShift: -5,
      textColor,
    });
    baseChart.sciChartSurface.annotations.add(textAnnotation);
  }

  removeAnnotation(baseChart: TWebAssemblyChart) {
    const annotation = baseChart.sciChartSurface.annotations.getById(
      'column_bar_text_value_annotation'
    );
    if (annotation) {
      baseChart.sciChartSurface.annotations.remove(annotation);
    }
  }

  private addVolumeAtPrice(data: VolumeData, maxVolumeAtPrice: number) {
    data = deepClone(data);
    const previous = this.volumeAtPriceObj.get(data.key);
    if (previous) {
      this.volumeAtPriceObj.set(data.key, {
        qtty_buyer: data.qtty_buyer,
        qtty_seller: data.qtty_seller,
        qtty_rlp: data.qtty_rlp,
        qtty_direct: data.qtty_direct,
        qtty_total: data.qtty_total,
        arrayIndex: previous.arrayIndex,
      });
      return;
    }
    this.volumeAtPriceXValues.push(data.key);
    const { qtty_buyer, qtty_seller, qtty_rlp, qtty_direct } =
      this.getNewSizeQtty(data, maxVolumeAtPrice);
    const arrayIndex = this.volumeAtPriceYValues['qtty_buyer'].push(qtty_buyer);
    this.volumeAtPriceYValues['qtty_seller'].push(qtty_seller);
    this.volumeAtPriceYValues['qtty_rlp'].push(qtty_rlp);
    this.volumeAtPriceYValues['qtty_direct'].push(qtty_direct);
    this.volumeAtPriceObj.set(data.key, {
      qtty_buyer: data.qtty_buyer,
      qtty_seller: data.qtty_seller,
      qtty_rlp: data.qtty_rlp,
      qtty_direct: data.qtty_direct,
      qtty_total: data.qtty_total,
      arrayIndex: arrayIndex - 1,
    });
  }

  private previousVolumeAtPrice(data: VolumeData) {
    const previous = this.volumeAtPriceObj.get(data.key);
    return previous;
  }

  updateVolumeAtPriceObj(
    baseChart: TWebAssemblyChart,
    data: VolumeData,
    maxVolumeAtPrice: number
  ) {
    const previous = this.previousVolumeAtPrice(data);
    if (!previous) {
      this.addVolumeAtPrice(data, maxVolumeAtPrice);
    } else {
      this.volumeAtPriceObj.set(data.key, {
        qtty_buyer: data.qtty_buyer,
        qtty_seller: data.qtty_seller,
        qtty_rlp: data.qtty_rlp,
        qtty_direct: data.qtty_direct,
        qtty_total: data.qtty_total,
        arrayIndex: previous.arrayIndex,
      });
    }
    const hashsValues: any = {
      qtty_buyer: [],
      qtty_seller: [],
      qtty_rlp: [],
      qtty_direct: [],
    };
    const { qtty_buyer, qtty_seller, qtty_rlp, qtty_direct } =
      this.getNewSizeQtty(data, maxVolumeAtPrice);
    hashsValues['qtty_buyer'].push(qtty_buyer);
    hashsValues['qtty_seller'].push(qtty_seller);
    hashsValues['qtty_rlp'].push(qtty_rlp);
    hashsValues['qtty_direct'].push(qtty_direct);
    const columns = baseChart.sciChartSurface.renderableSeries.getById(
      CANDLE_IDS.VOLUME_X_PRICE_SERIES
    ) as StackedColumnCollectionTiger;
    if (columns) {
      columns.getVisibleSeries().forEach((serie) => {
        const dataSeries = serie.dataSeries as XyDataSeries;
        const val = hashsValues[dataSeries.id];
        if (!previous) {
          dataSeries.append(data.key, val);
        } else {
          dataSeries.update(previous.arrayIndex, val);
        }
      });
    }
  }

  private clearVAP(baseChart: TWebAssemblyChart) {
    const columns = baseChart.sciChartSurface.renderableSeries.getById(
      CANDLE_IDS.VOLUME_X_PRICE_SERIES
    ) as StackedColumnCollectionTiger;
    if (columns) {
      columns.getVisibleSeries().forEach((serie) => {
        const dataSeries = serie.dataSeries as XyDataSeries;
        dataSeries.clear();
      });
    }
  }

  instanceVolumeAtPrice(
    baseChart: TWebAssemblyChart,
    volumes: VolumeData[],
    xAxisId: string,
    yAxisId: string,
    refComponent: string,
    stock: ISearchStock,
    maxVolumeAtPrice: number,
    clearVAP: boolean = false
  ): { stacked: StackedColumnCollection } {
    this.instanceVolumeAtPrice$.next({
      baseChart,
      volumes,
      xAxisId,
      yAxisId,
      refComponent,
      stock,
      maxVolumeAtPrice,
      clearVAP,
    });
    return { stacked: this.stackedColumnCollection.get(refComponent)!! };
  }

  private createArrayInObject() {
    if (!this.volumeAtPriceYValues['qtty_buyer']) {
      this.volumeAtPriceYValues['qtty_buyer'] = [];
    }
    if (!this.volumeAtPriceYValues['qtty_seller']) {
      this.volumeAtPriceYValues['qtty_seller'] = [];
    }
    if (!this.volumeAtPriceYValues['qtty_rlp']) {
      this.volumeAtPriceYValues['qtty_rlp'] = [];
    }
    if (!this.volumeAtPriceYValues['qtty_direct']) {
      this.volumeAtPriceYValues['qtty_direct'] = [];
    }
  }

  getQttyByPrice(price: number) {
    return this.volumeAtPriceObj.get(price);
  }

  deleteColumnBar(refComponent: string) {
    this.stackedColumnCollection.delete(refComponent);
  }

  clearColumnBar(baseChart: TWebAssemblyChart) {
    if (baseChart) {
      const columns = baseChart.sciChartSurface.renderableSeries.getById(
        CANDLE_IDS.VOLUME_X_PRICE_SERIES
      ) as StackedColumnCollectionTiger;
      baseChart.sciChartSurface.renderableSeries.remove(columns);
      const book = baseChart.sciChartSurface.renderableSeries.getById(
        CANDLE_IDS.BOOK_SERIES
      );
      baseChart.sciChartSurface.renderableSeries.remove(book);
    }
    this.stackedColumnCollection.clear();
    this.volumeAtPriceXValues = [];
    this.volumeAtPriceYValues = {};
    this.volumeAtPriceObj.clear();
    this.createArrayInObject();
  }

  private createChartVolumeAtPrice(
    baseChart: TWebAssemblyChart,
    xAxisId: string,
    yAxisId: string,
    refComponent: string,
    volumes: VolumeData[],
    maxVolumeAtPrice: number
  ) {
    const stacked = new StackedColumnCollectionTiger(
      baseChart.wasmContext,
      CANDLE_IDS.VOLUME_X_PRICE_SERIES,
      {
        xAxisId,
        yAxisId,
        isVisible: false,
      }
    );
    const rendSeries1 = new StackedColumnRenderableSeries(
      baseChart.wasmContext,
      {
        dataSeries: new XyDataSeries(baseChart.wasmContext, {
          xValues: [],
          yValues: [],
          dataSeriesName: `qtty_buyer`,
          id: `qtty_buyer`,
          dataIsSortedInX: true,
        }),
        fill: '#5999f880',
        strokeThickness: 0,
        stroke: '#5999f880',
        stackedGroupId: `StackedGroupId`,
      }
    );
    const rendSeries2 = new StackedColumnRenderableSeries(
      baseChart.wasmContext,
      {
        dataSeries: new XyDataSeries(baseChart.wasmContext, {
          xValues: [],
          yValues: [],
          dataSeriesName: `qtty_seller`,
          id: `qtty_seller`,
          dataIsSortedInX: true,
        }),
        fill: '#fbbb0080',
        strokeThickness: 0,
        stroke: '#fbbb0080',
        stackedGroupId: `StackedGroupId`,
      }
    );

    const rendSeries3 = new StackedColumnRenderableSeries(
      baseChart.wasmContext,
      {
        dataSeries: new XyDataSeries(baseChart.wasmContext, {
          xValues: [],
          yValues: [],
          dataSeriesName: `qtty_rlp`,
          id: `qtty_rlp`,
          dataIsSortedInX: true,
        }),
        fill: this.themeService.isDarkTheme() ? '#E7EAEE80' : '#23242F80',
        strokeThickness: 0,
        stroke: this.themeService.isDarkTheme() ? '#E7EAEE80' : '#23242F80',
        stackedGroupId: `StackedGroupId`,
      }
    );

    const rendSeries4 = new StackedColumnRenderableSeries(
      baseChart.wasmContext,
      {
        dataSeries: new XyDataSeries(baseChart.wasmContext, {
          xValues: [],
          yValues: [],
          dataSeriesName: `qtty_direct`,
          id: `qtty_direct`,
          dataIsSortedInX: true,
        }),
        fill: '#35354680',
        strokeThickness: 0,
        stroke: '#35354680',
        stackedGroupId: `StackedGroupId`,
      }
    );
    stacked.add(rendSeries1, rendSeries2, rendSeries3, rendSeries4);
    stacked.dataPointWidth = 0.8;
    baseChart.sciChartSurface.renderableSeries.add(stacked);
    this.stackedColumnCollection.set(refComponent, stacked);
    this.addDataVolumeAtPrice$.next({ baseChart, volumes, maxVolumeAtPrice });
  }
}
