<rocket-modal>
  <rocket-modal-header class="pb-0">
    <div class="d-flex flex-column w-100">
      <h4 class="mb-2 fw-bolder">Selecionar índice</h4>
      <p class="fw-normal mb-0 fs-5">
        Selecione o índice que você gostaria de ver no maiores altas e baixas
      </p>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div class="w-100 mb-5">
      <div class="d-flex flex-wrap p-3 pt-0 pr-0">
        <div *ngIf="loading" class="h-100 w-100">
          <app-loading></app-loading>
        </div>
        <div class="pr-2 mt-2" *ngFor="let index of indexList">
          <rocket-button
            [type]="selectedIndex === index.cd_stock ? 'default' : 'outline'"
            tooltipPosition="bottom"
            [label]="index.cd_stock"
            [css]="
              selectedIndex !== index.cd_stock
                ? 'white-border text-neutral-smoothest'
                : 'text-neutral-stronger'
            "
            [isSmall]="true"
            [tooltip]="index.nm_index"
            (click)="selectIndex(index)"
          ></rocket-button>
        </div>
      </div>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
