<rocket-modal>
  <rocket-modal-header>
    <div *ngIf="!knowMore">
      <h3 class="modal-title">Indicadores</h3>
    </div>
    <div *ngIf="knowMore" class="d-flex">
      <div>
        <rocket-button
          (rocketClick)="goKnowMore($event)"
          type="icon"
          [title]="'Voltar para estudos'"
          css="text-center p-2 mr-4"
        >
          <fla-icon
            [css]="'btn-fill-primary'"
            name="arrow_back_ios_new"
            size="icon-size-micro"
          ></fla-icon>
        </rocket-button>
      </div>
      <div>
        <h3 class="modal-title">{{ nameIndicator }}</h3>
      </div>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body *ngIf="!knowMore">
    <fla-input
      class="col-7 mb-5"
      [placeholder]="'Pesquisar'"
      [isSmall]="false"
      [isRequired]="false"
      [formControl]="searchControl"
      decidePosition="prefix"
      (keyup)="searchIndicators($event)"
      #inputSearch
      autofocus
    >
      <div class="prefix">
        <fla-icon name="search" size="icon-size-sm"></fla-icon>
      </div>
    </fla-input>
    <div class="d-flex justify-content-between">
      <div
        id="indicators-modal-body"
        class="overflow-height mb-6 col-8"
        *ngIf="!nothingSearch"
      >
        <div *ngFor="let group of filteredIndicators">
          <button
            class="btn btn-fill-primary btn-sm w-100 text-left sized-font"
            type="button"
            [flaCollapse]="group.label + '_collapse'"
            [flaCollapseIsOpen]="group.open"
            (click)="openOrCloseGroup(group)"
          >
            <span class="material-icons-outlined">
              {{ group.open ? 'arrow_drop_up' : 'arrow_drop_down' }}
            </span>
            {{ group.label }}
          </button>
          <div [id]="group.label + '_collapse'">
            <div
              class="pl-favorites fs-6"
              *ngIf="!group.indicators.length && group.id == 'FAVORITES'"
            >
              <span>Você ainda não tem indicadores favoritos</span>
            </div>
            <div
              class="d-flex indicator-div"
              *ngFor="let indicator of group.indicators"
            >
              <rocket-button
                css="text-center"
                type="icon"
                (rocketClick)="favoriteIndicator(indicator)"
                [tooltip]="
                  !mapFavorites.has(indicator.id)
                    ? 'Adicionar aos Favoritos'
                    : 'Remover dos Favoritos'
                "
                tooltipPosition="top"
              >
                <fla-icon
                  *ngIf="!mapFavorites.has(indicator.id)"
                  css="uncolored-icon-star"
                  name="star_border"
                  size="icon-size-micro"
                ></fla-icon>
                <fla-icon
                  *ngIf="mapFavorites.has(indicator.id)"
                  css="colored-icon-star"
                  name="star"
                  size="icon-size-micro"
                ></fla-icon>
              </rocket-button>
              <div
                class="w-100 negative-margin-left align-items-center d-flex justify-content-between"
              >
                <button
                  (click)="selectIndicator(indicator, group)"
                  type="button"
                  [disabled]="indicator.checked"
                  class="btn btn-fill-primary btn-sm btn-without-hover"
                >
                  {{ indicator.name }}
                </button>

                <div class="d-flex text-wrap">
                  <span
                    *ngIf="indicatorsKeyQtty[indicator.id]"
                    class="bg-neutral-medium pl-1 pr-1 round-sm text-white"
                  >
                    <small>{{ indicatorsKeyQtty[indicator.id] }}</small>
                  </span>
                  <rocket-button
                    type="icon"
                    (rocketClick)="removeIndicator(indicator)"
                    [title]="'Remover indicador'"
                    *ngIf="indicatorsToAdd[indicator.id]?.qtty"
                    css="text-center"
                  >
                    <fla-icon
                      [css]="indicator.checked ? 'btn-fill-primary' : ''"
                      name="remove"
                      size="icon-size-micro"
                    ></fla-icon>
                  </rocket-button>

                  <span
                    *ngIf="indicatorsToAdd[indicator.id]?.qtty"
                    class="bg-brand-primary pl-1 pr-1 round-sm"
                  >
                    <small>{{ indicatorsToAdd[indicator.id]?.qtty }}</small>
                  </span>
                  <rocket-button
                    type="icon"
                    (rocketClick)="sumIndicator(indicator)"
                    [title]="'Adicionar indicador'"
                    css="text-center"
                    [isDisabled]="
                      indicator.max &&
                      (indicator.max == indicatorsKeyQtty[indicator.id] ||
                        indicator.max == indicatorsToAdd[indicator.id]?.qtty)
                    "
                  >
                    <fla-icon
                      [css]="indicator.checked ? 'btn-fill-primary' : ''"
                      name="add"
                      size="icon-size-micro"
                    ></fla-icon>
                  </rocket-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="pl-4 pb-6 d-flex flex-column justify-content-between text-helper w-100"
      >
        <div *ngIf="indicatorSelected">
          <h3 class="mb-2">{{ nameIndicator }}</h3>
          {{ descIndicator }}
          <p
            *ngIf="indicators[nameIndicator].haveKnowMore"
            class="text-brand-primary text-decoration-underline cursor-pointer mt-2"
            (click)="goKnowMore($event)"
          >
            Saiba mais
          </p>
          <div *ngIf="indicators[nameIndicator].video">
            <div id="player"></div>
            <!--<iframe
              [src]="indicators[nameIndicator].video | safeUrl"
              width="340"
              frameborder="0"
              scrolling="no"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; 
            gyroscope; picture-in-picture; web-share; fullscreen"
              allowfullscreen
              title="-"
            ></iframe>-->
            <div
              *ngIf="showMiniplayer"
              [ngClass]="{ 'text-right': !isUsingMiniPlayer }"
            >
              <span
                class="cursor-pointer text-brand-primary fs-5"
                (click)="useMiniPlayer()"
                >Miniplayer</span
              >
            </div>
          </div>
        </div>
        <div *ngIf="!indicatorSelected && !nothingSearch">
          <h3 class="mb-2">Nenhum indicador selecionado</h3>
          Selecione um indicador ao lado para mais detalhes.
        </div>
        <div *ngIf="nothingSearch">
          <h3 class="mb-2">Nenhum indicador encontrado</h3>
          Realize a busca novamente por outro indicador.
        </div>
        <div
          *ngIf="!['null', '{}'].includes(indicatorsToAdd | json)"
          class="d-flex justify-content-start"
        >
          <div>
            <rocket-button
              [label]="'Fechar'"
              type="fill"
              (rocketClick)="onClose()"
            ></rocket-button>
            <rocket-button
              [label]="'Aplicar'"
              css="ml-2"
              (rocketClick)="addIndicator()"
              [isSmall]="true"
            ></rocket-button>
          </div>
        </div>
      </div>
    </div>
  </app-rocket-modal-body>
  <app-rocket-modal-body *ngIf="knowMore">
    <div
      class="know-more pl-4"
      [innerHTML]="indicators[nameIndicator].html"
    ></div>
  </app-rocket-modal-body>
</rocket-modal>
