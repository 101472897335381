import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { Observable, ReplaySubject, map } from 'rxjs';
import { THEMES_ENUM, processThemeClass } from 'src/app/utils/change-theme';
import { THEMES } from './theme-preferences.interface';

@Injectable({
  providedIn: 'root',
})
export class ThemePreferencesService extends CustomPreferencesService {
  private active$ = new ReplaySubject<THEMES_ENUM>(1);
  override KEYS: any = {
    ACTIVE_THEME: 'ACTIVE_THEME',
  };

  get activeTheme() {
    const item = this.storage.getItem(this.KEYS.ACTIVE_THEME);
    return item ?? THEMES_ENUM.DARK;
  }

  set activeTheme(e: THEMES_ENUM) {
    this.setValueCustomPreferences(this.KEYS.ACTIVE_THEME, e);
    this.dispatchTheme(e);
  }

  constructor(http: HttpClient) {
    super(http);
  }

  themeActiveObservable = (): Observable<THEMES> =>
    this.active$.asObservable().pipe(map(processThemeClass));

  setActiveTheme(value: boolean) {
    this.activeTheme = value ? THEMES_ENUM.DARK : THEMES_ENUM.LIGHT;
  }

  isDarkTheme = () => this.activeTheme == THEMES_ENUM.DARK;

  initialTheme() {
    this.dispatchTheme(this.activeTheme);
  }

  resetTheme() {
    this.dispatchTheme(THEMES_ENUM.DARK);
  }

  forceLightTheme() {
    this.dispatchTheme(THEMES_ENUM.LIGHT);
  }

  dispatchTheme(theme: THEMES_ENUM) {
    this.active$.next(theme);
  }
}
