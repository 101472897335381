import { ColDef } from 'ag-grid-community';

import {
  HEADERS_ALERTS,
  HEADERS_ALERTS_ICONS,
  HEADERS_INTRADIA_DAYTRADE,
  HEADERS_SWING_TRADING,
  HEADERS_PORTFOLIO,
} from '../grid/columns/index';

import { IOrdersTabPreferences } from '../interfaces/orders-history.interface';
import { formatterNumber } from '@shared/rocket-components/utils';
import { HEADERS_ORDERS } from '../grid/columns/header-order';
import { HEADERS_ORDERS_OPEN } from '../grid/columns/header_order_open';

export const FOOTER_HEADERS_ORDERS_OPEN: Array<ColDef> = [
  {
    headerName: '',
    field: 'edit',
    minWidth: 32,
    maxWidth: 32,
    width: 32,
    lockPosition: 'left',
    pinned: 'left',
  },
  {
    headerName: '',
    field: 'close',
    minWidth: 32,
    maxWidth: 32,
    width: 32,
    lockPosition: 'left',
    pinned: 'left',
  },
];

const formatQttyExecuted = (value: string) => {
  if (!value) return '-';
  const valuesSplit = value.split(' / ');
  valuesSplit[0] = formatterNumber(+valuesSplit[0], {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  valuesSplit[1] = formatterNumber(+valuesSplit[1], {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  return `${valuesSplit[0]} / ${valuesSplit[1]}`;
};

export const FOOTER_HEADERS_ORDERS: Array<ColDef> = [
  {
    headerName: 'Data',
    field: 'dh_creation_time',
  },
  {
    headerName: 'Ativo',
    field: 'cd_stock',
  },
  {
    headerName: 'L',
    field: 'side',
  },
  {
    headerName: 'Qtd',
    field: 'qtty',
    type: 'numericColumn',
    valueFormatter: (params: any) =>
      params.value
        ? formatterNumber(+params.value, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })
        : '-',
  },
  {
    headerName: 'Preço',
    field: 'price',
    type: 'numericColumn',
  },
  {
    headerName: 'Stop',
    field: 'price_stop',
    type: 'numericColumn',
  },
  {
    headerName: 'Situação',
    field: 'ds_order_status',
  },
  {
    headerName: 'Tipo',
    field: 'ds_order_type',
  },
  {
    headerName: 'Exe/Tot',
    field: 'qtty_executed',
    type: 'numericColumn',
    valueFormatter: (params: any) => formatQttyExecuted(params?.value),
  },
  {
    headerName: 'Executado',
    field: 'price_average',
    type: 'numericColumn',
  },
  {
    headerName: 'Validade',
    field: 'ds_order_time_enforce',
  },
  {
    headerName: 'DT',
    field: 'is_day_trade',
  },
];

export const FOOTER_HEADERS_ALERTS: Array<ColDef> = [
  {
    headerName: 'Data',
    field: 'dh_insert',
  },
  {
    headerName: 'Ativo',
    field: 'cd_stock',
  },
  {
    headerName: 'Alerta',
    field: 'vl_value',
  },
  {
    headerName: 'Preço',
    field: 'vl_current_price',
  },
  {
    headerName: 'Situação',
    field: 'alert_status',
  },
];

export const COLUMNS_CONFIG: any = {
  ORDER: HEADERS_ORDERS,
  INTRADIA: HEADERS_INTRADIA_DAYTRADE,
  SWINGTRADING: HEADERS_SWING_TRADING,
  OPEN_ORDERS: [...HEADERS_ORDERS_OPEN, ...HEADERS_ORDERS],
  PORTFOLIO: HEADERS_PORTFOLIO,
  ALERTS: [...HEADERS_ALERTS_ICONS, ...HEADERS_ALERTS],
  FOOTER_OPEN_ORDERS: [...FOOTER_HEADERS_ORDERS_OPEN, ...FOOTER_HEADERS_ORDERS],
  FOOTER_ORDERS: FOOTER_HEADERS_ORDERS,
  FOOTER_ALERTS: [...HEADERS_ALERTS_ICONS, ...FOOTER_HEADERS_ALERTS],
};

export const CONFIG_KEYS: any = {
  ORDER: 'ORDER',
  PORTFOLIO: 'PORTFOLIO',
};

export const TAB_DEFAULT_PREFERENCES: IOrdersTabPreferences = {
  label: 'Janelas',
  key: 'ORDERS_HISTORY_TABS',
  allCheck: false,
  width: 100,
  columns: [
    {
      label: 'Posições',
      key: 'POSITION',
      isCheck: false,
      width: 140,
    },
    /*{
      label: 'Intradia',
      key: 'INTRADIA',
      isCheck: true,
      width: 140,
    },
    {
      label: 'Swing',
      key: 'SWINGTRADING',
      isCheck: true,
      width: 140,
    },*/
    {
      label: 'Abertas',
      key: 'ORDER_OPEN',
      isCheck: true,
      width: 140,
    },
    {
      label: 'Executadas',
      key: 'ORDER_EXEC',
      isCheck: true,
      width: 140,
    },
    {
      label: 'Fechadas',
      key: 'ORDER_CLOSE',
      isCheck: true,
      width: 140,
    },
    {
      label: 'Todas',
      key: 'ORDER_TOTAL',
      isCheck: true,
      width: 140,
    },
    {
      label: 'Alertas',
      key: 'ALERTS',
      isCheck: true,
      width: 140,
    },
  ],
};
