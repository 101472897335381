import { FormGroup } from '@angular/forms';
import { DynamicControlService } from '@shared/rocket-components/dynamic-form/dynamic-control.service';
import { FieldsBase } from '@shared/rocket-components/dynamic-form/fields-base';
import { VALIDADE_TYPES } from '../constants/boleta.constants';
import {
  STOCK_TRADE_ELEMENTS_ID,
  TYPE_ORDE_ENUM,
  TYPE_VALIDADE_ENUM,
} from '../enum/stock-trade.enum';

export class StartStopForm {
  constructor(
    private dynamicControlService: DynamicControlService,
    private standardLot: number,
    private incrementValue: number,
    private vlClosePrice: string | number,
    private vlorderSide: any,
    private orderStopDoubleValue: boolean,
    private priceMask: string
  ) {}

  private fields: any = (tempFormValues?: any) => [
    {
      controlType: 'freeContent',
      key: 'search',
      classLine: 'col-4 pr-2',
      tooltip: `<span class="fs-6">Ativo</span><br><span class="fs-7 line-heigth">Busque pelo código do ativo que deseja negociar.</span>`,
      tooltipDelay: 1000,
    },
    {
      controlType: 'freeContent',
      key: 'qtde',
      label: 'Qtde:',
      value: tempFormValues?.qtde || this.standardLot,
      initialValue: tempFormValues?.qtde || this.standardLot,
      isSmall: true,
      selectOnFocus: true,
      flaMask: 'separator.0',
      allowNegativeValue: false,
      incrementValue: this.incrementValue,
      classLine: 'col-4 pr-2',
      classComponent: 'margin-bottom',
      listErrors: ['qtdeRequired'],
      typeShowError: 'icon',
      tooltip: `<span class="fs-6">Quantidade</span><br><span class="fs-7 line-heigth">Número de ativos ou contratos que deseja negociar.</span>`,
      tooltipDelay: 1000,
      elementID: STOCK_TRADE_ELEMENTS_ID.QUANTITY,
      scrollEvent: true,
      isQttyInput: true,
      tabIndex: 0,
    },
    {
      controlType: 'freeContent',
      key: 'price',
      label: 'Preço:',
      value: tempFormValues?.price || this.vlClosePrice,
      isSmall: true,
      selectOnFocus: true,
      flaMask: this.priceMask || 'separator.2',
      classLine: 'col-4 pr-3',
      listErrors: ['multiplePriceError'],
      classComponent: 'margin-bottom',
      typeShowError: 'icon',
      tooltip: `<span class="fs-6">Preço</span><br><span class="fs-7 line-heigth">Valor pelo qual deseja negociar o ativo.</span>`,
      tooltipDelay: 1000,
      elementID: STOCK_TRADE_ELEMENTS_ID.PRICE,
      tabIndex: 0,
    },
    {
      controlType: 'freeContent',
      key: 'stopPx',
      label: 'Stop:',
      required: true,
      value: tempFormValues?.stopPx || this.vlClosePrice,
      isSmall: true,
      selectOnFocus: true,
      flaMask: this.priceMask || 'separator.2',
      classLine: 'col-4 pr-2',
      listErrors: ['startStopTriggerError', 'multiplePriceStopError'],
      classComponent: 'margin-bottom',
      typeShowError: 'icon',
      tooltip: `<span class="fs-6">Stop</span><br><span class="fs-7 line-heigth">Valor a ser atingido para a ordem entrar no livro de ofertas.</span>`,
      tooltipDelay: 1000,
      elementID: STOCK_TRADE_ELEMENTS_ID.ORDER_STOP,
    },
    {
      controlType: 'freeContent',
      key: 'gainPrice',
      label: 'Preço:',
      value: tempFormValues?.gainPrice || this.vlClosePrice,
      isSmall: true,
      selectOnFocus: true,
      flaMask: this.priceMask || 'separator.2',
      classLine: 'col-4 pr-3',
      listErrors: ['errorGainPrice', 'multiplePriceGainDoubleError'],
      classComponent: 'margin-bottom',
      typeShowError: 'icon',
      tooltip: `<span class="fs-6">Gain</span><br><span class="fs-7 line-heigth">Preço de ordem de saída para fechar a posição com perca.</span>`,
      tooltipDelay: 1000,
      tabIndex: 0,
    },
    {
      controlType: 'freeContent',
      key: 'gainPriceTrigger',
      label: 'Gain:',
      value: tempFormValues?.gainPriceTrigger || this.vlClosePrice,
      isSmall: true,
      selectOnFocus: true,
      flaMask: this.priceMask || 'separator.2',
      classLine: 'col-4 pr-3',
      listErrors: ['errorGainPriceTrigger', 'multiplePriceGainTriggerError'],
      classComponent: 'margin-bottom',
      typeShowError: 'icon',
      tooltip: `<span class="fs-6">Stop Gain</span><br><span class="fs-7 line-heigth">Valor a ser atingido para a ordem de ganho entrar no livro de ofertas.</span>`,
      tooltipDelay: 1000,
      tabIndex: 0,
    },
    {
      controlType: 'select',
      key: 'typeValidade',
      label: 'Validade:',
      value: `${TYPE_VALIDADE_ENUM.TODAY}`,
      isSmall: true,
      bindLabel: 'name',
      bindValue: 'id',
      options: VALIDADE_TYPES,
      classLine: 'col-4 pr-2',
      classComponent: 'margin-bottom',
      tooltip: `<span class="fs-6">Validade</span><br><span class="fs-7 line-heigth">Até quando a ordem pode ser executada.</span>`,
      tooltipDelay: 1000,
      elementID: STOCK_TRADE_ELEMENTS_ID.VALIDATE,
      listErrors: ['incorrectValidityType'],
      typeShowError: 'icon',
    },
    {
      controlType: 'freeContent',
      key: 'orderDouble',
      label: 'Stop Duplo',
      value: this.orderStopDoubleValue,
      classLine: 'col-4 pl-1',
      tooltip: `<span class="fs-6">Stop Duplo</span><br><span class="fs-7 line-heigth">As ordens Stop Duplo permitem que você monte estratégias de negociação em momentos de oscilação do papel para ter ganhos (Gain) ou perdas (Loss) máximos.</span>`,
      tooltipDelay: 1000,
      elementID: STOCK_TRADE_ELEMENTS_ID.DOUBLE_STOP,
    },
    {
      controlType: '',
      key: 'dueDate',
      label: 'Data Expiração:',
      value: '',
      isSmall: true,
      type: 'date',
      classLine: 'col-4 pr-2',
      classComponent: 'margin-bottom',
    },
    {
      controlType: 'freeContent',
      key: 'lossPrice',
      label: 'Preço:',
      value: tempFormValues?.lossPrice || this.vlClosePrice,
      isSmall: true,
      selectOnFocus: true,
      flaMask: this.priceMask || 'separator.2',
      classLine: 'col-4 pr-3',
      listErrors: ['errorLossPrice', 'multiplePriceLossDoubleError'],
      classComponent: 'margin-bottom',
      typeShowError: 'icon',
      tooltip: `<span class="fs-6">Loss</span><br><span class="fs-7 line-heigth">Preço de ordem de saída para fechar a posição com perca.</span>`,
      tooltipDelay: 1000,
      tabIndex: 0,
    },
    {
      controlType: 'freeContent',
      key: 'lossPriceTrigger',
      label: 'Loss:',
      value: tempFormValues?.lossPriceTrigger || this.vlClosePrice,
      isSmall: true,
      selectOnFocus: true,
      flaMask: this.priceMask || 'separator.2',
      classLine: 'col-4 pr-3',
      listErrors: ['errorLossPriceTrigger', 'multiplePriceLossTriggerError'],
      classComponent: 'margin-bottom',
      typeShowError: 'icon',
      tooltip: `<span class="fs-6">Stop Loss</span><br><span class="fs-7 line-heigth">Valor a ser atingido para a ordem de perda entrar no livro de ofertas.</span>`,
      tooltipDelay: 1000,
      tabIndex: 0,
    },
    {
      key: 'orderType',
      value: this.orderStopDoubleValue
        ? TYPE_ORDE_ENUM.DOUBLE_START_STOP
        : TYPE_ORDE_ENUM.START_STOP,
    },
    {
      key: 'orderSide',
      value: this.vlorderSide,
    },
    {
      key: 'is_day_trade',
      value: false,
    },
  ];

  public createFields(tempFormValues?: any): any[] {
    return this.fields(tempFormValues);
  }

  public createForm(tempFormValues?: any): FormGroup {
    return this.dynamicControlService.toFormGroup(
      this.fields(tempFormValues) as FieldsBase<string>[]
    );
  }
}
