import {
  TIGER_INDICATORS_ENUM,
  TIGER_INDICATOR_SOURCES,
} from '../enum/tiger-chart.enum';

export const TIGER_INDICATOR_SOURCE_LIST = [
  { label: 'Abe', value: TIGER_INDICATOR_SOURCES.OPEN },
  { label: 'Máx', value: TIGER_INDICATOR_SOURCES.MAX },
  { label: 'Mín', value: TIGER_INDICATOR_SOURCES.MIN },
  { label: 'Fch', value: TIGER_INDICATOR_SOURCES.CLOSE },
];

export const TIGER_INDICATOR_SOURCE_OBJECT: any =
  TIGER_INDICATOR_SOURCE_LIST.reduce((a, v) => ({ ...a, [v.value]: v }), {});

export const TIGER_STROKE_THICKNESS_LIST = ['1px', '2px', '3px', '4px'];

export const CANDLE_IDS = {
  MAIN_SERIE: 'TIGER-CHART-MAIN-SERIES',
  VOLUME_SERIES: 'VOLUME_DATA_SERIES',
  VOLUME_X_PRICE_SERIES: 'VOLUME_X_PRICE_SERIES',
  BOOK_SERIES: 'BOOK_DATA_SERIES',
  CURSOR_MODIFIER: 'CANDLE_CURSOR_MODIFIER',
};

export const TIGER_TOOLTIP_STUDIES_FONT_SIZE = 12;

export const TIGER_ANNOTATION_INDICATORS = [
  TIGER_INDICATORS_ENUM.CDL_HAMMER,
  TIGER_INDICATORS_ENUM.CDL_INVERTED_HAMMER,
  TIGER_INDICATORS_ENUM.CDL_DOJI,
  TIGER_INDICATORS_ENUM.CDL_ENGULFING,
];

export const TIGER_SUB_CHART_INDICATORS = [
  TIGER_INDICATORS_ENUM.RELATIVE_STRENGTH_INDEX,
  TIGER_INDICATORS_ENUM.STOCHASTIC,
  TIGER_INDICATORS_ENUM.MOVING_AVERAGE_CONVERGENCE_DIVERGENCE,
  TIGER_INDICATORS_ENUM.AVERAGE_TRUE_RANGE,
  TIGER_INDICATORS_ENUM.PERCENTAGE_PRICE_OSCILLATOR,
  TIGER_INDICATORS_ENUM.MOMENTUM,
  TIGER_INDICATORS_ENUM.AVERAGE_DIRECTIONAL_X,
  TIGER_INDICATORS_ENUM.WEIS_WAVE,
];

export const TIGER_INDICATOR_CLICK = ['CLICK', 'DOUBLECLICK'];

export const TIGER_CHART_INDICATORS_ABREVIATIONS = {
  [TIGER_INDICATORS_ENUM.MOVING_AVERAGE]: 'MA',
  [TIGER_INDICATORS_ENUM.EXPONENTIAL_MOVING_AVERAGE]: 'EMA',
  [TIGER_INDICATORS_ENUM.WEIGHTED_MOVING_AVERAGE]: 'WMA',
  [TIGER_INDICATORS_ENUM.BOLLINGER_BANDS]: 'BB',
  [TIGER_INDICATORS_ENUM.MEDIAN_PRICE]: 'Mediana',
  [TIGER_INDICATORS_ENUM.RELATIVE_STRENGTH_INDEX]: 'RSI',
  [TIGER_INDICATORS_ENUM.STOCHASTIC]: 'STOCH',
  [TIGER_INDICATORS_ENUM.PARABOLIC_SAR]: 'SAR',
  [TIGER_INDICATORS_ENUM.CDL_HAMMER]: 'Martelo',
  [TIGER_INDICATORS_ENUM.CDL_INVERTED_HAMMER]: 'Martelo Invertido',
  [TIGER_INDICATORS_ENUM.AVERAGE_PRICE]: 'Preço Médio',
  [TIGER_INDICATORS_ENUM.CDL_DOJI]: 'Doji',
  [TIGER_INDICATORS_ENUM.CDL_ENGULFING]: 'Engulfing',
  [TIGER_INDICATORS_ENUM.DOUBLE_EXPONENTIAL_MOVING_AVERAGE]: 'DEMA',
  [TIGER_INDICATORS_ENUM.TRIPLE_EXPONENTIAL_MOVING_AVERAGE]: 'TEMA',
  [TIGER_INDICATORS_ENUM.KAUFMAN_ADAPTIVE_MOVING_AVERAGE]: 'KAMA',
  [TIGER_INDICATORS_ENUM.MOVING_AVERAGE_CONVERGENCE_DIVERGENCE]: 'MACD',
  [TIGER_INDICATORS_ENUM.TYPICAL_PRICE]: 'Preço Típico',
  [TIGER_INDICATORS_ENUM.AVERAGE_TRUE_RANGE]: 'ATR',
  [TIGER_INDICATORS_ENUM.PERCENTAGE_PRICE_OSCILLATOR]: 'PPO',
  [TIGER_INDICATORS_ENUM.TRIANGULAR_MOVING_AVERAGE]: 'TRIMA',
  [TIGER_INDICATORS_ENUM.MOMENTUM]: 'Mom',
  [TIGER_INDICATORS_ENUM.AVERAGE_DIRECTIONAL_X]: 'ADX',
  [TIGER_INDICATORS_ENUM.CANDLE_COUNTER]: 'Contador de Candle',
  [TIGER_INDICATORS_ENUM.BAR_ELEPHANT]: 'BE',
  [TIGER_INDICATORS_ENUM.WEIS_WAVE]: 'WW',
};

export const minMaxIDS = {
  MAX: 'HORIZONTAL_MAX_PRICE',
  MIN: 'HORIZONTAL_MIN_PRICE',
};
