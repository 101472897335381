import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Inject,
  OnInit,
} from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { PartnerConnectionCardComponent } from '@shared/modals/partners/partner-connection-card/partner-connection-card.component';
import { StandaloneModalsService } from '@shared/modals/standalone-modals.service';
import {
  ROCKET_MODAL_DATA,
  RocketIModal,
  RocketModalModule,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { TrademapCDNService } from '@shared/services/api/cdn/trademap-cdn.service';
import { IBrokerConnectionInfos } from '@shared/services/api/trademap/V9/models/brokers-connection-infos.interface';
import { TrademapV9Service } from '@shared/services/api/trademap/V9/trademap.service';
import { filter, take, timer } from 'rxjs';
import { ModalConnectBrokerFormComponent } from '../modal-connect-broker-form/modal-connect-broker-form.component';
import { BrokerLogoComponent } from '@modules/broker-connection/components/broker-logo/broker-logo.component';
import { RocketTModalSize } from '@shared/rocket-components/components/modal/types/modal.types';

@Component({
  selector: 'app-modal-connect-broker',
  templateUrl: './modal-connect-broker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RocketModalModule,
    PartnerConnectionCardComponent,
    BrokerLogoComponent,
  ],
})
export class ModalConnectBrokerComponent
  extends RocketModalRef
  implements OnInit
{
  public broker!: IBrokerConnectionInfos;
  public logo!: SafeResourceUrl;
  public loadedContent: boolean = false;
  public hasLogo: boolean = false;

  private _trademapV9 = inject(TrademapV9Service);
  private _trademapCDNService = inject(TrademapCDNService);
  private _standaloneModalsService = inject(StandaloneModalsService);
  private _cdr = inject(ChangeDetectorRef);

  constructor(
    service: RocketModalService,
    @Inject(ROCKET_MODAL_DATA)
    private data: {
      idBroker: number;
      customComponentOnClose: boolean;
      customComponentInfos: {
        component: any;
        settings: RocketIModal;
        size: RocketTModalSize;
      };
    }
  ) {
    super(service);
  }

  ngOnInit() {
    if (!this.data?.idBroker) {
      this._onErrorOrEmptyInfos();
      return;
    }
    this._getBrokerStepsToConnection(this.data.idBroker);
  }

  private _getBrokerStepsToConnection(idBroker: number): void {
    this._trademapV9.getBrokerConnectionInfos(idBroker).subscribe({
      next: (response) => {
        if (response.brokerLogo) this._getBrokerLogo(response.brokerLogo);
        this.broker = response;
        this.loadedContent = true;
        this._cdr.detectChanges();
      },
      error: () => this._onErrorOrEmptyInfos(),
    });
  }

  private _onErrorOrEmptyInfos(backToBrokersModal: boolean = true): void {
    this._standaloneModalsService.close(false);
    if (!backToBrokersModal) return;
    this.backToTheLastModal();
  }

  public backToTheLastModal(): void {
    if (!this.data.customComponentOnClose) return;
    this._openOtherModal(
      this.data.customComponentInfos.component,
      {},
      this.data.customComponentInfos.settings,
      this.data.customComponentInfos.size
    );
    return;
  }

  private _getBrokerLogo(logo: string): void {
    this._trademapCDNService
      .getBrokerLogo(logo)
      .pipe(filter((logo) => logo !== null))
      .subscribe((logo) => {
        this.logo = logo!;
        this.hasLogo = true;
        this._cdr.detectChanges();
      });
  }

  public openExternalPage(openCreateAccount: boolean): void {
    if (!openCreateAccount && this.broker.hasQuestionnaire) {
      this._openModalFormToConnectBroker();
      return;
    }
    const url = openCreateAccount
      ? this.broker.onboardingLink
      : this.broker.connectionLink;
    window.open(url, '_blank');
  }

  private _openModalFormToConnectBroker(): void {
    this._onErrorOrEmptyInfos(false);
    const params = this.broker;
    if (this.hasLogo) params.brokerLogoUrl = this.logo;
    this._openOtherModal(ModalConnectBrokerFormComponent, {
      broker: this.broker,
    });
  }

  private _openOtherModal(
    component: any,
    params: any,
    settings: RocketIModal = {},
    size: RocketTModalSize = 'lg'
  ): void {
    timer(75)
      .pipe(take(1))
      .subscribe(() =>
        this._standaloneModalsService.openStandaloneModal(
          component,
          params,
          settings,
          '',
          size
        )
      );
  }
}
