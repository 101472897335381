<ng-container *ngIf="!stockChartConfigs && isLoading; else elseLoading">
  <app-loading></app-loading>
</ng-container>
<ng-template #elseLoading>
  <app-stock-chart-header
    #stockChartHeader
    *ngIf="stockChartConfigs"
    [component]="component"
    [configs]="stockChartConfigs"
    [standardLot]="configStock.standard_lot"
    [showFastOrder]="showFastOrder"
    (chageIndicator)="toggleIndicator($event, true)"
    (chageTypeGrafic)="changesTypes($event)"
    (changeInterval)="changeInterval($event)"
    (changeNegotiationLot)="changeNegotiationLot($event)"
    [refComponent]="component.id"
    [headerOptions]="headerOptions"
    [withTools]="withTools"
    (changeStock)="handlerStockChange($event)"
    [useDefaultHeader]="forceUseDefaultHeaderType"
    (openTool)="openTool($event)"
  ></app-stock-chart-header>

  <div
    #tigerChartWrapper
    class="w-100"
    [ngClass]="{ 'h-100': !isDesktop, 'desktop-height': isDesktop }"
  >
    <app-tiger-chart
      #candle
      [events]="events"
      [refComponent]="component.id"
      [tickSize]="tickSize"
      [idExchange]="idExchangeSelected"
      [removeLine]="removeLine"
      [indicator]="addIndicator"
      [draws]="addDraws"
      [alerts]="alerts"
      [parentRef]="refComponent"
      [settingsConfig]="settings"
      [log]="isLog"
      [freeScale]="isFreeScale"
      [isChartBook]="showChartBook"
      [isBookButton]="showBookButton"
      [userCustody]="userCustody"
      [isChartVolumeAtPrice]="showChartVolumeAtPrice"
      [isFastOrderDaytradeStrategy]="showFastOrderDaytradeStrategy"
      [isFastOrder]="showFastOrder"
      [isVolume]="showVolume"
      [isEvents]="showEvents"
      [isWithTools]="withTools"
      [defaultCursor]="defaultCursor"
      [isZoomIn]="zoomIn"
      [isExecOrders]="showExecOrders"
      [isFlagExecOrders]="showFlagOrders"
      [isOrderMarkerOrders]="showOrderMarker"
      [isOpenFlagOrders]="showOpenFlag"
      [minPriceIncrement]="minPriceIncrement"
      [chartTradingOffset]="chartTradingOffset"
      [fastOrderWidth]="fastOrderWidth"
      (chartReady)="onChartReady()"
      (chartUpdate)="onChartUpdate()"
      (indicatorDelete)="deleteIndicator($event)"
      (lineMoved)="lineMoved($event)"
      (boxLineDeleted)="lineDeleted($event)"
      (rightClickEvent)="handleRightClick($event)"
      (keypressEvent)="keypressEvent($event)"
      (freeScaleEmmitter)="setFreeScale($event)"
      (zoomInEmmitter)="zoomIn = $event"
      (changeNegotiationLot)="changeNegotiationLot($event)"
      (changeInterval)="changeInterval($event)"
      (changeActiveStock)="changeActiveStock($event)"
      (updateOrderWidth)="updateOrderWidth($event)"
      (tourChange)="handleTour($event)"
      (withToolsChange)="withToolsChange($event)"
      (defaultCursorChange)="defaultCursorChange($event)"
      (openIndicatorsModal)="openIndicatorsModal()"
    >
    </app-tiger-chart>
  </div>

  <app-tiger-chart-footer
    (buttonClick)="footerClick($event)"
    [refComponent]="component.id"
    [isLog]="isLog"
    [isFreeScale]="isFreeScale"
    [showChartBook]="showChartBook"
    [showChartVolumeAtPrice]="showChartVolumeAtPrice"
    [showBookButton]="showBookButton"
    [showVolume]="showVolume"
    [showPreviousClose]="showPreviousClose"
    [displayFastRuler]="displayFastRuler"
    [showFastOrder]="showFastOrder"
    [showEvents]="showEvents"
    [showExecOrders]="showExecOrders"
    [showZoomIn]="zoomIn"
    [showBidAsk]="showBidAsk"
    [configs]="settings"
  ></app-tiger-chart-footer>
</ng-template>
