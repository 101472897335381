import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@shared/services';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { AUTH_SESSION_KEYS } from '@shared/services/core/const/auth_util.const';
import { sharedSessionStorage } from '@shared/services/core/storage/shared-session.storage';
import { DeviceValidatorService } from '@shared/services/device-validator.service';

@Injectable({
  providedIn: 'root',
})
export class HomeGuard {
  constructor(
    private _auth: AuthService,
    private router: Router,
    private deviceValidator: DeviceValidatorService,
    private customPreferenceService: CustomPreferencesService
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      this._auth.isAuth().subscribe(async (isLogged) => {
        if (!isLogged) {
          this.router.navigate(['auth/login']);
        } else if (this.deviceValidator.isMobile()) {
          this.router.navigate(['invalid-device']);
        }
        const isNewTab =
          (sharedSessionStorage.getItem(AUTH_SESSION_KEYS.IS_NEW_TAB) ?? '0') == '1';
        if (isLogged && isNewTab) {
          this.customPreferenceService.getCustomPreferencesCached().then(() => {
            resolve(Boolean(isLogged));
          });
        } else {
          resolve(Boolean(isLogged));
        }
      });
    });
  }
}
