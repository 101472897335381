import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '@shared/services/rest';
import { Observable, map } from 'rxjs';
import { IProceedsCalendar } from './interface/calendars.interface';

@Injectable({
  providedIn: 'root',
})
export class TrademapCalendarsService extends RestService {
  override _url: string = 'api/trademap';

  constructor(http: HttpClient) {
    super(http);
  }

  public getEvents = (
    limit: number = 15,
    period: string = 'next'
  ): Observable<boolean> =>
    this.post<any>('v1/calendars/events', {
      period,
      actionTypes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
      cdStock: '',
      idExchange: '',
      offset: 0,
      limit: limit,
    }).pipe(
      map((response) => {
        return response.data;
      })
    );

  public getProceeds = (
    isHistoricalTable: boolean,
    limit: number = 12,
    period: string = 'next',
    cdStock: string = '',
    idExchange: string = ''
  ): Observable<IProceedsCalendar[]> =>
    this.post<any>('v1/calendars/proceeds', {
      period,
      actionTypes: [
        10, 11, 12, 13, 14, 16, 17, 18, 19, 20, 21, 22, 30, 40, 50, 51, 52, 53,
        60, 70, 80, 81, 90, 93, 94, 97,
      ],
      cdStock,
      idExchange,
      offset: 0,
      limit: limit,
      isHistoricalTable: isHistoricalTable,
    }).pipe(
      map((response) => {
        return response.data;
      })
    );
}
