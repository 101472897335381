import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-status-performance',
  template: ` <div
    class="round bg-neutral-strong position-absolute"
    [style.width.px]="164"
    [style.padding.px]="10"
  >
    <div class="list-group list-group-flush">
      <a class="list-group-item px-0 text-center">
        <span class="pr-2" tooltip="Média FPS" tooltipPosition="top"
          >FPS: <span [class]="classFPS">{{ fps }}</span>
        </span>
      </a>
      <a class="list-group-item px-0 text-center">
        <span class="pr-2">Inatividade: {{ timeToLogout }}</span>
      </a>
    </div>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusPerformanceComponent implements OnInit, OnDestroy {
  public fps = '60';
  public classFPS: string = '';
  private fpsTemp = 0;
  private count = 0;
  private cleanIntervalPFS!: any;
  private cleanInterval!: any;
  private lastTime = 0;

  @Input() timeToLogout: string = '';

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.startFPS();
  }

  ngOnDestroy(): void {
    clearInterval(this.cleanIntervalPFS);
    clearInterval(this.cleanInterval);
  }

  private startFPS(): void {
    this.loopFPS(0);
    this.cleanIntervalPFS = setInterval(() => {
      this.fpsTemp != Infinity &&
        (this.fps = (this.fpsTemp / this.count).toFixed(0));
      this.fpsTemp = 0;
      this.count = 0;
      this.setClass(+this.fps);
      this.cdr.detectChanges();
    }, 1000);
  }

  private setClass(fps: number): void {
    fps > 49 && (this.classFPS = 'text-feedback-success');
    fps > 39 && fps < 50 && (this.classFPS = 'text-feedback-warning');
    fps < 40 && (this.classFPS = 'text-feedback-error');
  }

  private loopFPS = (time: number) => {
    const temp = 1000 / (time - this.lastTime);
    temp != Infinity && (this.fpsTemp += temp);
    temp != Infinity && (this.lastTime = time);
    this.count++;
    requestAnimationFrame(this.loopFPS);
    this.cdr.detectChanges();
  };
}
