import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YoutubeMiniplayerComponent } from './youtube-miniplayer.component';
import { FlaIconModule } from '@shared/rocket-components/components';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [YoutubeMiniplayerComponent],
  imports: [CommonModule, FlaIconModule, DragDropModule],
  exports: [YoutubeMiniplayerComponent],
})
export class YoutubeMiniplayerModule {}
