<ng-container *ngIf="useDefaultLayout; else customContent">
  <h3 class="mb-2">Entre com seu usuário</h3>
  <span class="d-block align-items-center fs-5 mb-3">
    O <span class="fw-bold">RocketTrader</span> e o
    <span class="fw-bold">TradeMap</span> compartilham as mesmas credenciais de
    acesso! Faça seu login e aproveite a melhor experiência de negociação do
    mercado.
  </span>
</ng-container>

<ng-template #customContent>
  <div id="CUSTOM_LOGIN_CONTENT" class="vstack gap-3 mb-7">
    <h2 class="fs-2 fw-bold text-left mb-5">
      {{ partnerContent.title }}
    </h2>
    <ng-container *ngFor="let item of partnerContent.itens">
      <div class="align-items-center gap-2 hstack">
        <div class="card-icon hstack justify-content-center">
          <ng-container *ngIf="item.customIcon; else icon">
            <img
              [src]="item.customIcon"
              alt="Logo parceiro"
              width="85%"
              height="100%"
            />
          </ng-container>
          <ng-template #icon>
            <span class="material-icons icon-size-md"> {{ item.icon }} </span>
          </ng-template>
        </div>
        <span [innerHTML]="item.text"></span>
      </div>
    </ng-container>
  </div>
</ng-template>
