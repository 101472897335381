import { Injectable } from '@angular/core';
import { AccountPreferences } from '@shared/services/core/custom-preferences/account/account-preferences.services';
import { JoystickPreferencesService } from '@shared/services/core/custom-preferences/joystick/joystick-preferences.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JoystickService {
  private _isSimulator = false;
  constructor(
    private accPreferences: AccountPreferences,
    private _joystickPreferencesService: JoystickPreferencesService
  ) {
    this._isSimulator = this.accPreferences?.simulatorSelected;
    this.simulator$.next(this._isSimulator);
  }

  private visibility$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this.visibilitySimulatorMenu
  );
  private simulator$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  get isSimulator() {
    return this.accPreferences?.simulatorSelected;
  }

  set simulator(account: any) {
    this.accPreferences.simulatorSelected = account;
    this._isSimulator = account;
    this.simulator$.next(account);
  }

  set visibilitySimulatorMenu(display: boolean) {
    this._joystickPreferencesService.displayJoystick = display;
    this.visibility$.next(display);
  }

  get visibilitySimulatorMenu() {
    return this._joystickPreferencesService?.displayJoystick;
  }

  onSimulatorActivate = () => this.simulator$.asObservable();

  changesVisibilityMenuJoistick() {
    return this.visibility$.asObservable();
  }
}

export const IS_SIMULATOR_SELECTED = 'IS_SIMULATOR_SELECTED';
export const SHOW_SIMULATOR_MENU = 'SIMULATOR_MENU';

export enum JOYSTICK_STATE {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
}

export const JOYSTICK_DEF = {
  ON: {
    enabled: true,
    icon: 'videogame_asset',
    class: 'text-brand-primary',
    tooltip: 'Simulador Ativado',
  },
  OFF: {
    enabled: false,
    icon: 'videogame_asset_off',
    class: 'text-neutral-medium',
    tooltip: 'Simulador Desativado',
  },
};
