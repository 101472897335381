import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { TTokenAuthenticator, TTokenPreferenceAuthenticator } from './types';
import { ModalTokenService } from './modal-token.service';
import { Subject, take, takeUntil } from 'rxjs';
import { OrderTokenService } from '@shared/rocket-components/modal-order-token/order-token.service';
import { ModalAvisoComponent } from '@shared/components/modal-aviso/modal-aviso.component';
import { system } from '@core/system/system.service';
import { WhatsappService } from '../whatsapp/whatsapp.service';
import { OtpService } from '@shared/services/api/authentication/v3/otp.service';
import { StandaloneModalsService } from '@shared/modals/standalone-modals.service';
import { ModalRegisterPhoneComponent } from '@shared/modals/modal-register-phone/modal-register-phone.component';

interface IParams {
  shouldDisplayTMAlert: boolean;
  callback: undefined | ((params?: any) => any);
}
@Component({
  selector: 'app-token-config',
  templateUrl: './modal-token-config.component.html',
  styleUrls: ['./modal-token-config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalTokenConfigComponent
  extends RocketModalRef
  implements OnInit, OnDestroy
{
  tokenPreference!: TTokenPreferenceAuthenticator;
  data: IParams;
  selectedAuthenticator: TTokenAuthenticator | null = null;
  loading = true;
  disconnectingName = '';
  title = '';

  private _defaultTitle = 'Segundo fator de segurança 🔑';
  private _destroy = new Subject<void>();

  private _standaloneModalService = inject(StandaloneModalsService);
  constructor(
    @Inject(ROCKET_MODAL_DATA)
    data: IParams,
    service: RocketModalService,
    private _otpService: OtpService,
    private _elementRef: ElementRef,
    private _cdr: ChangeDetectorRef,
    private _modalTokenService: ModalTokenService,
    private _orderTokenService: OrderTokenService,
    private _modalService: RocketModalService,
    private _whatsAppService: WhatsappService
  ) {
    super(service);
    this.data = data;
    this.title = this._defaultTitle;
    this._onUpdateTokenPreferences();
  }

  private _onUpdateTokenPreferences = () => {
    this._modalTokenService.onReloadConfig$
      .pipe(takeUntil(this._destroy))
      .subscribe((token?: string) => {
        if (token) {
          this._orderTokenService.tradingToken = token;
          this._cdr.detectChanges();
        }

        if (this.data && this.data.callback) {
          this.close();
          return;
        }

        this.getTokenPreference();
      });

    this._modalTokenService.onCloseModal$
      .pipe(takeUntil(this._destroy))
      .subscribe(() => this.close());
  };

  ngOnInit() {
    this.getTokenPreference();
  }

  public getTokenPreference() {
    this.clearLastInfos();
    this._otpService
      .userTokenPreferences()
      .subscribe((token) => this._loadComponentInfos(token));
  }

  override ngOnDestroy(): void {
    this.data && this.data.callback && this.data.callback(true);
    this._destroy.next();
    this._destroy.complete();
    this._modalService.resetDataInfos();
    super.ngOnDestroy();
  }

  override close(value?: any, isSecondModal?: boolean): void {
    super.close(value, isSecondModal);
    this.data && this.data.callback && this.data.callback();
  }

  private _loadComponentInfos(token: TTokenPreferenceAuthenticator): void {
    if (this._modalTokenService.alreadySelectedAuthenticator) {
      this._handleStepsToConfigureToken();
      return;
    }

    if (this.data.shouldDisplayTMAlert && token === 'TRADEMAP') {
      this._warningToUpdateAuthenticatorMethod();
      return;
    }

    if (token !== 'UNDEFINED_TOKEN' && this.data.callback) {
      this.close();
      return;
    }

    this.tokenPreference = token;
    this.loading = false;
    this._cdr.detectChanges();
  }

  private _handleStepsToConfigureToken(): void {
    this.selectedAuthenticator =
      this._modalTokenService.alreadySelectedAuthenticator!.authenticator;
    this.loading = false;
    this._cdr.detectChanges();
  }

  selectAuthenticator(authenticator: TTokenAuthenticator | null) {
    if (!system.userAuthenticated.investor.phone_mobile) {
      if (system.userAuthenticated.investor.email)
        this._registerPhone(authenticator!.id);
      else this._emptyInfosToChangeToken();
      return;
    }
    this.selectedAuthenticator = authenticator;
  }

  clearSelectedAuth() {
    this.selectedAuthenticator = null;
    this._cdr.detectChanges();
  }

  onDisconnecting(disconnecting: string) {
    this.disconnectingName = disconnecting;
    this.title = disconnecting
      ? 'Tem certeza que deseja retirar seu vínculo com o ' + disconnecting
      : this._defaultTitle;
    this._cdr.detectChanges();
  }

  closeModal() {
    this.close();
  }

  private _emptyInfosToChangeToken(): void {
    this._modalService
      .open(ModalAvisoComponent, {
        toggleBetweenModal: this._elementRef,
        data: {
          title: 'Sem dados cadastrais ativos',
          text: 'Identificamos que a sua conta está sem telefone e e-mail ativo. Para realizar a alteração é preciso entrar em contato com o nosso time de atendimento e solicitar a alteração. <br/ > Clique em Entrar em contato com o atendimento para falar com a nossa equipe!',
          cancelButton: 'Cancelar',
          confirmeButton: 'Entre em contato com atendimento',
          showButtons: true,
        },
      })
      .afterDismissed.pipe(take(1))
      .subscribe((action: { type: string; value: boolean }) => {
        this.clearLastInfos(false);
        if (action.value === true) {
          this._whatsAppService.openWhatsAppRocket();
          return;
        }
        this.close();
      });
  }

  public clearLastInfos(loading: boolean = true): void {
    this.loading = loading;
    this.selectedAuthenticator = null;
    this._cdr.detectChanges();
  }

  private _warningToUpdateAuthenticatorMethod(): void {
    this.title = 'Atualize seu token';
    this.selectedAuthenticator = {
      name: 'Trademap',
      icon: '',
      id: 'TRADEMAP',
    };
    this.loading = false;
    this._cdr.detectChanges();
  }

  private _registerPhone(authSelected: string): void {
    this.close();
    this._standaloneModalService.openStandaloneModal(
      ModalRegisterPhoneComponent,
      {
        authMethod: authSelected,
      },
      {
        keyboard: false,
        scrollable: false,
        hideCloseButton: true,
      },
      '',
      'lg'
    );
  }
}
