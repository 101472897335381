import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';

export const GRAPHIC_SCREENING_COMPONENT: any = {
  cod: 'GSCR',
  label: '',
  borderClass: 'border-bottom border-neutral-stronger',
  name: 'Gráfico Screening',
  id: COMPONENTS_NAMES_ENUM.GRAPHIC_SCREENING,
  data: null,
  close: true,
  fullscreen: true,
  link: false,
  minWidth: '450px',
  width: 470,
  height: 470,
  minHeight: '200px',
  noPublished: false,

  unitOfMeasurement: 'px',
  left: 'calc(15% + 5px)',
  top: '0px',
};
