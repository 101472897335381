<ng-container *ngIf="name === 'BOOK'">
  <svg
    [attr.width]="width"
    [attr.height]="height"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 8.66732V11.334H11.3333V8.66732H4Z" [attr.fill]="color" />
    <path
      d="M0.667969 8.66732L0.667969 11.334H3.33464V8.66732H0.667969Z"
      [attr.fill]="color"
    />
    <path
      d="M2.66797 4.66732V7.33398H5.33464V4.66732H2.66797Z"
      [attr.fill]="color"
    />
    <path
      d="M4.66797 0.667318V3.33398H7.33464V0.667318H4.66797Z"
      [attr.fill]="color"
    />
    <path d="M8 0.667318V3.33398H11.3333V0.667318H8Z" [attr.fill]="color" />
    <path d="M6 4.66732V7.33398H11.3333V4.66732H6Z" [attr.fill]="color" />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'ROCKET'">
  <svg
    [attr.width]="width"
    [attr.height]="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3536 2.04731L2.23742 6.88592C1.83522 7.07882 1.97413 7.63566 2.42816 7.64801L6.332 7.75729C6.99024 7.77534 7.52202 8.25521 7.55311 8.85861L7.79775 13.6146C7.82056 14.0574 8.49228 14.1486 8.65399 13.7305L12.9735 2.54048C13.1072 2.1946 12.7092 1.87722 12.3536 2.04731Z"
      [attr.fill]="color"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'SUPER_DOM'">
  <svg
    [attr.width]="width"
    [attr.height]="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 1H1V15H15V1ZM8 4H14V2.7H8V4ZM2 8.65V7.35H14V8.65H2ZM8 6H14V4.7H8V6ZM8 11H2V9.7H8V11ZM2 13H8V11.7H2V13Z"
      [attr.fill]="color"
    />
    <mask
      id="mask0_6315_4864"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="14"
      height="14"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 1H1V15H15V1ZM8 4H14V2.7H8V4ZM2 8.65V7.35H14V8.65H2ZM8 6H14V4.7H8V6ZM8 11H2V9.7H8V11ZM2 13H8V11.7H2V13Z"
        [attr.fill]="color"
      />
    </mask>
    <g mask="url(#mask0_6315_4864)">
      <path
        d="M1 1V0H0V1H1ZM15 1H16V0H15V1ZM1 15H0V16H1V15ZM15 15V16H16V15H15ZM14 4V5H15V4H14ZM8 4H7V5H8V4ZM14 2.7H15V1.7H14V2.7ZM8 2.7V1.7H7V2.7H8ZM2 7.35V6.35H1V7.35H2ZM2 8.65H1V9.65H2V8.65ZM14 7.35H15V6.35H14V7.35ZM14 8.65V9.65H15V8.65H14ZM14 6V7H15V6H14ZM8 6H7V7H8V6ZM14 4.7H15V3.7H14V4.7ZM8 4.7V3.7H7V4.7H8ZM2 11H1V12H2V11ZM8 11V12H9V11H8ZM2 9.7V8.7H1V9.7H2ZM8 9.7H9V8.7H8V9.7ZM8 13V14H9V13H8ZM2 13H1V14H2V13ZM8 11.7H9V10.7H8V11.7ZM2 11.7V10.7H1V11.7H2ZM1 2H15V0H1V2ZM2 15V1H0V15H2ZM15 14H1V16H15V14ZM14 1V15H16V1H14ZM14 3H8V5H14V3ZM13 2.7V4H15V2.7H13ZM8 3.7H14V1.7H8V3.7ZM9 4V2.7H7V4H9ZM1 7.35V8.65H3V7.35H1ZM14 6.35H2V8.35H14V6.35ZM15 8.65V7.35H13V8.65H15ZM2 9.65H14V7.65H2V9.65ZM14 5H8V7H14V5ZM13 4.7V6H15V4.7H13ZM8 5.7H14V3.7H8V5.7ZM9 6V4.7H7V6H9ZM2 12H8V10H2V12ZM1 9.7V11H3V9.7H1ZM8 8.7H2V10.7H8V8.7ZM9 11V9.7H7V11H9ZM8 12H2V14H8V12ZM7 11.7V13H9V11.7H7ZM2 12.7H8V10.7H2V12.7ZM3 13V11.7H1V13H3Z"
        fill="transparent"
      />
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="name === 'RAIOX'">
  <svg
    [attr.width]="width"
    [attr.height]="height"
    viewBox="0 0 10 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.851562 0.849609H5.00156C5.2713 0.849609 5.53356 0.944645 5.74873 1.05471C5.97513 1.17051 6.2039 1.33098 6.41843 1.52219C6.84496 1.90235 7.25946 2.44398 7.48951 3.09578C7.72358 3.75898 7.765 4.53673 7.42903 5.33712C7.10492 6.10927 6.45556 6.84141 5.42321 7.50202L7.75156 9.83037L9.04194 8.53999L9.96118 9.45923L8.6708 10.7496L9.96118 12.04L9.04194 12.9592L7.75156 11.6688L6.46118 12.9592L5.54194 12.04L6.83232 10.7496L3.73232 7.64961H2.15156V10.9996H0.851562V0.849609ZM2.15156 6.34961H4.81079C5.63062 5.80755 6.0426 5.28125 6.23035 4.83397C6.42563 4.36874 6.40455 3.92773 6.26362 3.52844C6.11867 3.11774 5.84567 2.75311 5.55345 2.49266C5.4086 2.36355 5.27018 2.27012 5.15674 2.21209C5.04948 2.15723 4.99731 2.15052 4.9974 2.14971C4.99741 2.14964 4.99789 2.14962 4.99883 2.14961H2.15156V6.34961Z"
      [attr.fill]="color"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'VOLUME_X_PRICE'">
  <svg
    [attr.width]="width"
    [attr.height]="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66406 8.00195L3.66406 8.00195L3.66406 6.50195L6.66406 6.50195L6.66406 8.00195Z"
      [attr.fill]="color"
    />
    <path
      d="M11.6641 6.00195L3.66406 6.00195L3.66406 4.50195L11.6641 4.50195L11.6641 6.00195Z"
      [attr.fill]="color"
    />
    <path
      d="M9.66406 10L3.66406 10L3.66406 8.5L9.66406 8.5L9.66406 10Z"
      [attr.fill]="color"
    />
    <path
      d="M9.66797 4L3.66797 4L3.66797 2.5L9.66797 2.5L9.66797 4Z"
      [attr.fill]="color"
    />
    <path
      d="M13.332 12.334L3.66536 12.334L3.66536 10.834L13.332 10.834L13.332 12.334Z"
      [attr.fill]="color"
    />
    <path
      d="M1.5 14.5V2H2.99912V13H8.27185H13.9991V14.5H1.5Z"
      [attr.fill]="color"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'BUBBLE'">
  <svg
    [attr.width]="width"
    [attr.height]="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5800_3065)">
      <path
        d="M6.66732 4.00065C6.66732 5.47341 5.47341 6.66732 4.00065 6.66732C2.52789 6.66732 1.33398 5.47341 1.33398 4.00065C1.33398 2.52789 2.52789 1.33398 4.00065 1.33398C5.47341 1.33398 6.66732 2.52789 6.66732 4.00065Z"
        [attr.fill]="color"
      />
      <path
        d="M14.6667 4.66732C14.6667 6.50827 13.1743 8.00065 11.3333 8.00065C9.49238 8.00065 8 6.50827 8 4.66732C8 2.82637 9.49238 1.33398 11.3333 1.33398C13.1743 1.33398 14.6667 2.82637 14.6667 4.66732Z"
        [attr.fill]="color"
      />
      <path
        d="M9.66732 11.0007C9.66732 13.0257 8.0257 14.6673 6.00065 14.6673C3.97561 14.6673 2.33398 13.0257 2.33398 11.0007C2.33398 8.97561 3.97561 7.33398 6.00065 7.33398C8.0257 7.33398 9.66732 8.97561 9.66732 11.0007Z"
        [attr.fill]="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_5800_3065">
        <rect width="16" height="16" [attr.fill]="color" />
      </clipPath>
    </defs>
  </svg>
</ng-container>

<ng-container *ngIf="name === 'CARD_VIEW'">
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.95"
      d="M6.0013 1.33398H1.66797C1.39183 1.33398 1.16797 1.55784 1.16797 1.83399V6.16732C1.16797 6.44346 1.39183 6.66732 1.66797 6.66732H6.0013C6.27744 6.66732 6.5013 6.44346 6.5013 6.16732V1.83398C6.5013 1.55784 6.27744 1.33398 6.0013 1.33398Z"
      [attr.fill]="color"
    />
    <mask
      id="mask0_5938_15657"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="6"
      y="0"
      width="9"
      height="8"
    >
      <path d="M14.832 0H6.83203V8H14.832V0Z" [attr.fill]="color" />
    </mask>
    <g mask="url(#mask0_5938_15657)">
      <path
        d="M8 6.16398L10 4.15898L11.3333 5.49232L14.1667 2.30565L13.6967 1.83398L11.3333 4.49232L10 3.15898L7.5 5.66398L8 6.16398Z"
        [attr.fill]="color"
      />
    </g>
    <path
      opacity="0.95"
      d="M6.5013 9.33398H1.16797V14.6673H6.5013V9.33398Z"
      [attr.fill]="color"
    />
    <mask
      id="mask1_5938_15657"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="6"
      y="8"
      width="9"
      height="8"
    >
      <path d="M14.832 8H6.83203V16H14.832V8Z" [attr.fill]="color" />
    </mask>
    <g mask="url(#mask1_5938_15657)">
      <path
        d="M8 14.164L10 12.159L11.3333 13.4923L14.1667 10.3057L13.6967 9.83398L11.3333 12.4923L10 11.159L7.5 13.664L8 14.164Z"
        [attr.fill]="color"
      />
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="name === 'HORIZONTAL_CANDLE'">
  <svg
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.00130177 1.66732L1.33463 1.66732V0.333984L9.33464 0.333984V1.66732L10.668 1.66732V3.00065L9.33464 3.00065V4.33398L1.33463 4.33398V3.00065H0.00130177V1.66732ZM0.00130177 7.00065H3.33464V5.66732L8.0013 5.66732L8.0013 7.00065L10.668 7.00065V8.33398H8.0013V9.66732L3.33464 9.66732V8.33398H0.00130177V7.00065Z"
      [attr.fill]="color"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'RANKING_BROKER'">
  <svg
    [attr.width]="width"
    [attr.height]="height"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 9V4H7.7383L9.5 7.26783L11.2617 4H12V9H11.2617V5.44105L9.5 8.70888L7.7383 5.44105V9H7Z"
      [attr.fill]="color"
      [attr.fill]="color"
      stroke-width="0.3"
    />
    <path
      d="M6 1.86172H1.80122V4.49345H5.58418V5.35517H1.80122V9H1V1H6V1.86172Z"
      [attr.fill]="color"
      [attr.fill]="color"
      stroke-width="0.3"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'INDICATORS'">
  <svg
    [attr.width]="width"
    [attr.height]="height"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 11.3092 1.5847 H 10.1759 V 3.8544 H 7.9063 V 4.9908 H 10.1759 V 7.2604 H 11.3092 V 4.9908 H 13.5787 V 3.8544 H 11.3092 V 1.5847 Z"
      [attr.fill]="color"
    />
    <path
      d="M 6.176 0.2721 C 5.4203 0.2721 4.7199 0.7697 4.1485 1.3934 C 3.5771 2.0108 3.3774 2.6314 3.1901 3.5345 H 2.548 L 2.0073 4.0875 L 1.9951 4.6743 L 2.9382 4.6804 C 2.7446 5.8754 2.6187 6.625 2.336 8.2409 C 1.9459 10.5295 1.7493 11.104 1.4175 11.0702 C 1.2547 11.0517 1.0028 10.8736 0.7478 10.6585 C 0.5604 10.551 0.3423 10.6155 0.2532 10.6708 C -0.1062 10.8951 -0.2936 11.3651 -0.2967 11.5801 C -0.3059 12.0471 0.115 12.4526 0.5574 12.4526 C 0.8461 12.4526 1.2731 12.5816 2.2009 11.7829 C 3.1839 10.8521 3.6171 9.9335 4.0195 7.8569 C 4.2898 6.4253 4.3881 5.9031 4.6032 4.6374 C 4.9042 4.6374 5.62 4.5913 5.8197 4.576 L 6.2498 3.5438 H 4.7721 C 5.1377 1.7067 5.3405 1.3841 5.6384 1.5531 C 5.8258 1.6575 5.8842 1.6545 6.1699 1.9709 C 6.2559 2.06 6.5293 2.0723 6.6368 2.02 C 7.0516 1.8173 7.2912 1.2152 7.2912 1.0247 C 7.2912 0.7697 6.8765 0.269 6.1699 0.269 L 6.176 0.2721 Z"
      [attr.fill]="color"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'CORRETORAS'">
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.3131 3.68789V6.92021H19.8485V3.68789H26.3131ZM10.1515 3.68789V13.3849H3.68687V3.68789H10.1515ZM26.3131 16.6172V26.3142H19.8485V16.6172H26.3131ZM10.1515 23.0818V26.3142H3.68687V23.0818H10.1515ZM29.5455 0.455566H16.6162V10.1525H29.5455V0.455566ZM13.3838 0.455566H0.454544V16.6172H13.3838V0.455566ZM29.5455 13.3849H16.6162V29.5465H29.5455V13.3849ZM13.3838 19.8495H0.454544V29.5465H13.3838V19.8495Z"
      fill="white"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'stock_analysis_y_axis'">
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5763_2868)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.666 14.791L2.66602 14.791L2.66602 13.4577L14.666 13.4577L14.666 14.791Z"
        [attr.fill]="color"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 14.7923V3.45898H3.33333V14.7923H2Z"
        [attr.fill]="color"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.767578 4.79232L2.69208 1.45898L4.61658 4.79232H3.02541V4.95898H2.35875V4.79232H0.767578Z"
        [attr.fill]="color"
      />
      <path
        d="M7.70874 10.7923V9.3668L6 6.45898H7.43689L8.33657 8.21881L9.28803 6.45898H10.6667L8.9644 9.3668V10.7923H7.70874Z"
        [attr.fill]="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_5763_2868">
        <rect
          width="16"
          height="16"
          [attr.fill]="color"
          transform="translate(0 0.125)"
        />
      </clipPath>
    </defs>
  </svg>
</ng-container>

<ng-container *ngIf="name === 'stock_analysis_x_axis'">
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5763_2874)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.666015 14.1257L0.666016 1.45898L1.99935 1.45898L1.99935 14.1257L0.666015 14.1257Z"
        [attr.fill]="color"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.33268 12.791L12.666 12.791L12.666 14.1243L1.33268 14.1243L1.33268 12.791Z"
        [attr.fill]="color"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3327 11.459L14.666 13.3835L11.3327 15.308L11.3327 13.7168L11.166 13.7168L11.166 13.0502L11.3327 13.0502L11.3327 11.459Z"
        [attr.fill]="color"
      />
      <path
        d="M9.99935 10.1257H8.25372L7.31822 8.88L6.37307 10.1257H4.66602L6.46951 7.7517L4.75281 5.45898H6.4888L7.34715 6.59632L8.2055 5.45898H9.91255L8.19585 7.72462L9.99935 10.1257Z"
        [attr.fill]="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_5763_2874">
        <rect
          width="16"
          height="16"
          [attr.fill]="color"
          transform="translate(0 0.125)"
        />
      </clipPath>
    </defs>
  </svg>
</ng-container>

<ng-container *ngIf="name === 'stock_analysis_z_axis'">
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5760_3257)">
      <path
        d="M6.66732 4.12565C6.66732 5.59841 5.47341 6.79232 4.00065 6.79232C2.52789 6.79232 1.33398 5.59841 1.33398 4.12565C1.33398 2.65289 2.52789 1.45898 4.00065 1.45898C5.47341 1.45898 6.66732 2.65289 6.66732 4.12565Z"
        [attr.fill]="color"
      />
      <path
        d="M14.6667 4.79232C14.6667 6.63327 13.1743 8.12565 11.3333 8.12565C9.49238 8.12565 8 6.63327 8 4.79232C8 2.95137 9.49238 1.45898 11.3333 1.45898C13.1743 1.45898 14.6667 2.95137 14.6667 4.79232Z"
        [attr.fill]="color"
      />
      <path
        d="M9.66732 11.1257C9.66732 13.1507 8.0257 14.7923 6.00065 14.7923C3.97561 14.7923 2.33398 13.1507 2.33398 11.1257C2.33398 9.10061 3.97561 7.45898 6.00065 7.45898C8.0257 7.45898 9.66732 9.10061 9.66732 11.1257Z"
        [attr.fill]="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_5760_3257">
        <rect
          width="16"
          height="16"
          [attr.fill]="color"
          transform="translate(0 0.125)"
        />
      </clipPath>
    </defs>
  </svg>
</ng-container>
