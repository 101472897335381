<ng-container *ngIf="useDefaultLayout; else customLayout">
  <div
    class="h-100 d-flex flex-column justify-content-end align-items-center"
    [class]="bdClass"
  >
    <app-logo [width]="420" [height]="83" css="logo-position"></app-logo>
    <span class="margin-infos">
      <h4 [innerHTML]="primaryText"></h4>
      <h4 [innerHTML]="messageWelcome[textRandomNumber]"></h4>
    </span>
    <img
      src="./assets/logo/logo-trademap.svg"
      alt="Logo Valemobi"
      width="140px"
      class="mb-7"
    />
  </div>
</ng-container>

<ng-template #customLayout>
  <div
    class="h-100 d-flex flex-column justify-content-center align-items-center"
  >
    <div class="gap-3 hstack justify-content-center mb-3">
      <img
        src="../../../../../assets/icons/icon-96x96.png"
        alt="Logo RocketTrader"
        width="90px"
      />
      <img [src]="partnerLogo" alt="Logo parceiro" width="90px" />
    </div>
    <app-logo [width]="300" [height]="60"></app-logo>
    <div class="d-flex flex-column gap-2 mt-3">
      <h3 class="fw-bold fs-4">Bem-vindo a nova era do trading</h3>
      <div
        class="border border-bottom border-bottom-0 border-brand-primary text-brand-primary"
      ></div>
    </div>

    <ng-container *ngIf="carouselContent">
      <div class="hstack justify-content-center mt-8 w-100">
        <app-carousel [slides]="carouselContent"></app-carousel>
      </div>
    </ng-container>
  </div>
</ng-template>
