import { Component, Inject } from '@angular/core';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components/index';

@Component({
  selector: 'app-modal-order-token-helper',
  template: `
    <rocket-modal>
      <rocket-modal-header [hideCloseButton]="true">
        <div
          class="d-inline-flex align-items-start justify-content-start w-100"
        >
          <rocket-button type="icon" (rocketClick)="onClose()">
            <fla-icon fla-icon name="arrow_back"></fla-icon>
          </rocket-button>
          <h3 class="modal-title ml-3">
            Token é um recurso adicional de segurança
          </h3>
        </div>
      </rocket-modal-header>
      <app-rocket-modal-body>
        <p>
          Para obtê-lo, acesse o TradeMap em seu celular, clique em sua foto de
          Perfil, escolha a opção "Segurança", selecione "Token" e copie o
          código Token exibido.
        </p>
      </app-rocket-modal-body>
      <rocket-modal-footer> </rocket-modal-footer>
    </rocket-modal>
  `,
})
export class ModalOrderTokenHelperComponent extends RocketModalRef {
  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: { title: any },
    service: RocketModalService
  ) {
    super(service);
  }

  onClose = (): void => this.close(false, true);
}
