export const CHANNEL_STOCK_TRADE = {
  SEND_STOCK_TRADE: 'SEND_STOCK_TRADE',
  SELECT_TYPE_ORDER: 'SELECT_TYPE_ORDER',
};

export const CHANNEL_DB_EVENT = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};
