<rocket-grid
  class="h-100 w-100"
  *ngIf="refComponent; else loadingTemplate"
  #rocketGrid
  height="100%"
  addRowPosition="start"
  rowSelection="single"
  [fieldIndexer]="fieldIndexer"
  [isConfig]="true"
  [globalCdStock]="globalCdStock"
  [rowDragEntireRow]="true"
  [rowDragText]="rowDragText"
  [dropZoneParams]="dropZoneParams"
  [suppressMoveWhenRowDragging]="true"
  [refComponent]="refComponent"
  [columnDefs]="configColumnDefs"
  [setColumnDefsDefault]="tableConfig?.columnDefs"
  [noRowsTemplate]="noRowsTemplateMessage"
  [onUpdateField]="onUpdateField"
  [sortingOrder]="sortingOrder"
  [resetFilter]="resetFilter"
  [displayPinnedBottomLine]="displayPinnedLine"
  [gridOptions]="topOptions"
  [ignoreRocketService]="ignoreRocketService"
  [suppressDragLeaveHidesColumns]="true"
  [rowBuffer]="10"
  [suppressChangeDetection]="true"
  [aggregateOnlyChangedColumns]="true"
  (flaSelectRow)="selectRow($event)"
  (flaColumnMoved)="columnMoved($event)"
  (flaColumnResized)="columnResized($event)"
  (onGridReady)="onGridReady($event)"
  (emitContextMenu)="emitContext($event)"
  (filterChangedEvent)="onGridFilterChange($event)"
  (onHeaderSortChange)="onHeaderSortChange($event)"
  (columnApiReference)="columnApiReference($event)"
  >
</rocket-grid>
<ng-template #loadingTemplate>
  <ng-container>
    <div class="h-100 d-flex align-items-sm-center justify-content-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-container>
</ng-template>
