import {
  Component,
  ContentChildren,
  Input,
  QueryList,
  SimpleChanges,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { FlaTStyle, FlaTTab } from './types/tab.type';
import { FlaTabHeaderComponent } from './fla-tab-header/fla-tab-header.component';
import { FlaTabBodyComponent } from './fla-tab-body/fla-tab-body.component';
import { randomId } from '../../utils/functions';

@Component({
  selector: 'fla-tab',
  templateUrl: './tab.component.html',
})
export class FlaTabComponent implements OnChanges, AfterViewInit {
  @ContentChildren(FlaTabHeaderComponent)
  tabHeader!: QueryList<FlaTabHeaderComponent>;
  @ContentChildren(FlaTabBodyComponent)
  tabBody!: QueryList<FlaTabBodyComponent>;
  @Input() type!: FlaTTab;
  @Input('css') customClass: string = '';
  @Input('cssContent') customClassContent: string = '';
  @Input() styleTab: FlaTStyle = 'nav-tabs';
  public idTab = randomId('fla_tab_tab');
  public idContent = randomId('fla_tab_content');

  constructor(public cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
  }
}
