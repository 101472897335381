import { Subject, Subscription } from 'rxjs';
import {
  takeUntil,
  filter,
  tap,
  delay,
  debounceTime,
  bufferTime,
} from 'rxjs/operators';
import { ColDef, GridApi } from 'ag-grid-community';
import { RocketCreateComponentService } from '@shared/rocket-components/services';
import { StockService } from '@services/api/trademap/v1/stock.service';
import { HomeService } from '@modules/home/service/home.service';
import { ConfigHeaderModalComponent } from './modals/config-modal/config-header-modal.component';
import {
  GridIOpenConfigGrid,
  GridIColumnVisible,
  GridIColumnVisibleInService,
  RocketGridService,
  GridISelectRow,
} from '@shared/rocket-grid';
import {
  IAuctionChannelData,
  IAuctionCommand,
  IDynamicList,
  IGlobalStock,
  IMoversData,
  IMoversTransaction,
  IStockListItemsRow,
} from './interfaces/stock-table.interfaces';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { StockListStettingsService } from '@services/api/nitro-ws/v1/stock-list-stettings.service';
import { CheckConfigList } from './configurations-list/check-config-list';
import { UpdatesConfig } from './configurations-list/updates-config';
import { AuctionChannel } from '@shared/channel/auction.channel';
import { CheckConfigListMotPersonal } from './configurations-list/check-config-list-not-personal';
import {
  LIST_CONFIG_TYPE,
  MountHeaders,
} from './configurations-list/mount-headers';
import { StockTableCheetah } from './configurations-list/stock-table-cheetah';
import {
  DYNAMIC_LISTS,
  SORT_MOVERS_ROWS,
  STOCK_TABLE_ELEMENT_IDS,
  isAuctionList,
  isMoversList,
  isPresetListID,
} from './constants/stock-table.constants';
import { isAuction } from '@shared/constants/general.contant';
import {
  AUCTION_HEADERS,
  HEADERS_AUCTION,
  HEADERS_AUCTION_IGNORE,
  HIDDEN_HEADERS,
  VISIBLE_HEADERS,
} from './constants/headers.constants';
import { MoversChannel } from '@shared/channel/movers.channel';
import {
  IAllStockListSimple,
  IListStockConfig,
  IListStockDB,
  ISearchStock,
  IStockListPreferences,
  IWorkSpaceComponet,
} from 'src/app/core/interface';
import { RocketModalService } from '@shared/rocket-components/components/index';
import { IntrojsService } from '@core/introjs/introjs.service';
import { deepClone } from '@shared/rocket-components/utils';
import { Dictionary } from '@core/models';
import { QuoteData } from '@shared/channel/interface/quote.channel.interface';
import { ChangeDetectorRef } from '@angular/core';
import { ListStocksService } from '@shared/services/core/list-stocks/list-stocks.service';
import { TRIGGERED_THE_EVENT } from '@shared/services/core/list-stocks/list-stocks.const';
import { STOCK_TABLE_VIEW } from '../stock-table-views';
import {
  IMoversPeriod,
  IMoversPeriodMetadata,
} from './interfaces/movers-period.interface';
import { StockListService } from '@shared/services/api/trademap/V10/stock-list.service';
import { IStockTableIndex } from './interfaces/movers-index.interface';
import { MOVERS_PERIODS } from './constants/periods.contants';
import { MARKET_MOVER_HEADER } from './constants/columns/market-movers';
import { ExportDataConverterService } from '@shared/services/export-data-converter.service';
import { ExportDataIdEnum } from '../export-data/export-data.enum';
import { WorkSpaceConfigs } from '@core/workspace';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { ActivatedRoute } from '@angular/router';
import {
  isNullOrUndefined,
  isRowSelectedStock,
} from 'src/app/utils/utils.functions';
import { StockTableContextMenuComponent } from './grid-config/stock-table-context-menu.component';
import { ContextMenuService } from '../popup-root/context-menu.service';

const IS_AUCTION = 'IS_AUCTION';
const COMMAND_ADD = 'ADD';
const COMMAND_UPDATE = 'UPDATE';
const COMMAND_DELETE = 'DELETE';

const AUCTION_COMMANDS_TO_AGGRID: any = {
  ADD: 'add',
  UPDATE: 'update',
  DELETE: 'remove',
};

export abstract class StockTableHelper extends RocketComponentBase {
  public refComp!: string;
  public componentId!: string;
  public dynamiclists = DYNAMIC_LISTS;
  public listStockSelected!: IListStockDB;
  component!: IWorkSpaceComponet;
  protected destroy$ = new Subject<boolean>();
  protected getUpdateConfigList$ = new Subscription();
  protected moversChannel$ = new Subscription();
  public introJSSubscription!: Subscription;
  protected columnMovedDelay!: any;
  protected listStockInfo!: IListStockDB;
  protected CHANNEL = '';
  public columnDefs: Array<ColDef> = [];
  public rowData: Array<any> = [];
  public columnVisible!: GridIColumnVisible;
  public addRow!: any;
  public onUpdateField!: any;
  public gridApi!: GridApi;
  public idListSelect!: number;
  public isLoading: boolean = true;
  public showColumnsAuction: boolean = false;
  public isListAuction: boolean = false;
  public isNotListPersonal: boolean = false;
  public toggleDP: boolean = false;
  private _firstRender: boolean = true;
  protected _gridApiInitialized: boolean = false;
  private _loadedMoversSnap: boolean = false;
  public noRowsTemplate!: string;
  private isAuctionColumnsVisible = '';
  private headersAuction: string[] = HEADERS_AUCTION_IGNORE.split(',');
  public listSelected!: IAllStockListSimple | undefined;
  private checkConfigList!: CheckConfigList;
  private stockTableCheetah!: StockTableCheetah;
  protected quoteUnsubscribed: boolean = true;
  public globalItem: string = '';
  globalStockInfos: IGlobalStock = {
    cd_stock: '',
    synonymous_nickname: '',
    cd_stock_order: '',
    synonymous_related: '',
    id_exchange: '',
  };
  public stock!: ISearchStock;
  private _forceDisplayContexMenu$ = new Subject<{ refComponent: string }>();
  protected forceDisplayContexMenu$ = new Subscription();
  private _configVisibleOrInvisibleColumns$ = new Subject<{
    refComponent: string;
    stateHeaders: GridIColumnVisible[];
  }>();
  protected updateGlobalStockSubject = new Subject<ISearchStock | undefined>();
  protected configVisibleOrInvisibleColumns$ = new Subscription();
  public updateMetadataSubject = new Subject<void>();
  public clearStocksSubject = new Subject<void>();
  public hideTableHeaders = new Subject<{ headers: any; keys: string[] }>();
  public readonly STOCK_TABLE_VIEWS = STOCK_TABLE_VIEW;
  public isChangeViewStep: boolean = false;
  public displayEmptyDynamicList: boolean = false;
  keyHeader!: string | null;
  selectedRow!: GridISelectRow | undefined | null;
  isShowEdit!: boolean;

  // DICT TO SAVE STOCKS FROM LIST
  public stocksAtListDict = new Dictionary<IStockListItemsRow>();

  // VARIABLES TO HANDLER STOCK TABLE VIEW
  private _moversSortSubject = new Subject<void>();
  private _enableMoversSort: boolean = false;
  public setSnapshotOnChangeView = new Subject<string>();
  public updateMoversSort: boolean = false;
  public clearStocks: boolean = false;
  public movers = new Dictionary<IMoversData>();
  public initialStockTableType!: STOCK_TABLE_VIEW;
  public stockToUpdate!: IStockListItemsRow | null;
  public stockToRemove!: IStockListItemsRow | null;
  public moversCommand!: IMoversTransaction;
  public currentView: STOCK_TABLE_VIEW = STOCK_TABLE_VIEW.GRID;
  public dynamicInitialList!: IDynamicList | undefined;

  // Movers Indexes
  public moversIndex: IStockTableIndex = { value: 'IBOV' };
  public auctionIndex: IStockTableIndex = { value: 'IBOV' };

  // Movers Period
  public moversPeriod!: IMoversPeriod;
  public loadingMovers: boolean = false;

  // Auction variables
  private _stocksAtAuction = new Dictionary<{ idRow: string }>();
  private _stocksAtAuctionToUnsub = new Dictionary<{ idRow: string }>();
  private _stocksAtAuctionToSubInQuoteChannel = new Subject<void>();
  private _stocksAtAuctionToUnsubInQuoteChannel = new Subject<void>();
  private _loadedAuctionSnapShot: boolean = false;
  public auctionCommand!: IAuctionCommand;

  // Preset List Variables
  public presetListName!: string;

  // Index List
  public isIndexList: boolean = false;

  get isGridStockList(): boolean {
    return this.currentView === STOCK_TABLE_VIEW.GRID;
  }

  get isCandleView(): boolean {
    return this.currentView === STOCK_TABLE_VIEW.CANDLE;
  }

  get sendQuoteUpdate(): boolean {
    return !this.isGridStockList && !this.isCandleView;
  }

  get configMetadata() {
    return (this.listStockInfo.configList as IListStockConfig).metadata;
  }

  public get cdStockOrder() {
    if (!this.globalStockInfos.cd_stock_order) return '';
    return `${this.globalStockInfos.cd_stock_order}:${this.globalStockInfos.id_exchange}`;
  }

  public get synonymousRelated() {
    if (!this.globalStockInfos.synonymous_related) return '';
    return `${this.globalStockInfos.synonymous_related}:${this.globalStockInfos.id_exchange}`;
  }

  constructor(
    protected rocketGridService: RocketGridService,
    protected _rocketModalService: RocketModalService,
    protected stockService: StockService,
    protected homeService: HomeService,
    protected createComponent: RocketCreateComponentService,
    protected quoteChannel: QuoteChannel,
    protected stockListStettingsService: StockListStettingsService,
    protected auctionChannel: AuctionChannel,
    protected moversChannel: MoversChannel,
    protected introJSService: IntrojsService,
    protected listStocksService: ListStocksService,
    protected cdr: ChangeDetectorRef,
    protected stockListService: StockListService,
    protected exporter: ExportDataConverterService,
    protected workspaceConfig: WorkSpaceConfigs,
    activatedRoute: ActivatedRoute,
    protected contextMenuService: ContextMenuService
  ) {
    super(activatedRoute);
    this.checkConfigList = new CheckConfigList(
      this.stockListStettingsService,
      this.stockService,
      this.listStocksService
    );
    this.initializeTotalSubscription();
    this._sortMoversHandler();
    this.headersAuction.splice(0, 2);

    this.forceDisplayContexMenu$ = this._forceDisplayContexMenu$
      .pipe(
        filter((data) => data.refComponent === this.refComp),
        delay(20)
      )
      .subscribe(() => {
        this._forceDisplayContexMenu();
        this.toggleDP = true;
        this.cdr.detectChanges();
      });

    this.configVisibleOrInvisibleColumns$ =
      this._configVisibleOrInvisibleColumns$
        .pipe(
          filter((data) => data.refComponent === this.refComp),
          tap((data) => {
            this.columnVisible = data.stateHeaders.shift()!;
            this.cdr.detectChanges();
          }),
          delay(100)
        )
        .subscribe((data) => {
          this.columnVisible = data.stateHeaders.pop()!;
          this.cdr.detectChanges();
          this.listStockInfo.defaultHeadersConfig = false;
        });

    this.getUpdateConfigList$ = this.checkConfigList.getUpdateConfigList
      .pipe(
        filter(
          (params) =>
            Boolean(params) &&
            params!.refComponent === this.refComp &&
            Boolean(params!.list.idStockList)
        )
      )
      .subscribe((params) => {
        const { list } = params!;
        list.stocks = this.setSelectedStock(list.stocks);
        this.idListSelect = list.idStockList;
        this.listStockSelected = list!;
        this.listStockInfo = list!;
        this.cdr.detectChanges();
        const stocksUpdated = this.setStocksAtDictList('idRow', list.stocks);
        if (stocksUpdated) this.mountedRowData();
      });

    this.updateMetadataSubject.pipe(debounceTime(1000)).subscribe(() => {
      this.updateMetadata();
    });

    this.setSnapshotOnChangeView
      .pipe(
        debounceTime(75),
        tap(() => {
          this.rowData = [];
          this.cdr.detectChanges();
        })
      )
      .subscribe((type) => this._setSnapshotOnChangeView(type));

    this._stocksAtAuctionToSubInQuoteChannel
      .pipe(
        debounceTime(200),
        filter(() => this._stocksAtAuction.size() > 0)
      )
      .subscribe(() => this._stocksAtAuctionToSubInQuote());

    this._stocksAtAuctionToUnsubInQuoteChannel
      .pipe(
        debounceTime(200),
        filter(() => this._stocksAtAuctionToUnsub.size() > 0)
      )
      .subscribe(() =>
        this.unsubSomeStocksFromQuoteChannel(
          this._stocksAtAuctionToUnsub.values()
        )
      );

    this.clearStocksSubject
      .pipe(
        tap(() => {
          this.clearStocks = true;
          this.cdr.detectChanges();
        }),
        debounceTime(10)
      )
      .subscribe(() => {
        this.clearStocks = false;
        this.cdr.detectChanges();
      });
  }

  public onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
    this._gridApiInitialized = true;
    this.cdr.detectChanges();
  }

  protected contextMenuInitialSettings(): void {
    this.rocketGridService.showConfig
      .pipe(takeUntil(this.destroy$))
      .subscribe((config: GridIOpenConfigGrid) => {
        if (config.refComponent === this.refComp && !this._firstRender) {
          this.selectedRow = config.rowSelect?.rowData;
          this.keyHeader = config.keyHeader;
          this._showConfig(config);
        }
        this._firstRender = false;
      });
  }

  private _showConfig(config: GridIOpenConfigGrid) {
    const { event, refComponent } = config;
    StockTableContextMenuComponent.openContextMenu(
      this.contextMenuService,
      refComponent,
      { clientX: event.clientX, clientY: event.clientY },
      false,
      this.idListSelect,
      this.showColumnsAuction,
      this.isListAuction,
      this.isNotListPersonal,
      isPresetListID(this.idListSelect)
        ? true
        : this.listStockInfo.defaultHeadersConfig,
      this.keyHeader,
      this.isShowEdit,
      !!this.selectedRow
    );
  }

  public updateTableEmptyMessage(message: string): void {
    this.noRowsTemplate = message;
    this.cdr.detectChanges();
  }

  //******************************************************************************************************************************************************************************/
  protected startList(listSelected: IListStockDB): void {
    this.displayEmptyDynamicList = false;
    this.unSubscribeCheetah();
    this.updateTableEmptyMessage('Essa lista não contém ativos.');
    if (this._stocksAtAuction.size()) {
      this._stocksAtAuction.clear();
      this._handleAuctionPreferences({});
    }
    this.showColumnsAuction = false;
    this.isListAuction = false;
    this.isNotListPersonal = false;
    if (listSelected) {
      this.idListSelect = listSelected.idStockList;
      this.listStockInfo = listSelected;
    }
    this.checkConfigList.check(this.listStockInfo, this.refComp);
    this.updateMetadataSubject.next();
    this.cdr.detectChanges();
  }

  public mountedRowData(): void {
    this.showColumnsAuction = this._displayAuctionColumns();
    this.isAuctionColumnsVisible = '';
    this.mountHeaders();
    if (this.isLoading) {
      this.updateMetadataSubject.next();
      this.isLoading = false;
    }
    this.cdr.detectChanges();
    if (this.isCandleView) {
      this.unSubscribeCheetahQuote(this.getAllStocksAtDictList());
      return;
    }
    this.subscribeCheetahQuote();
  }

  private mountHeaders(): void {
    const mountHeaders = new MountHeaders();
    const headers = mountHeaders.verifyHeader(this._getListHeaders());
    this.columnDefs = mountHeaders.setHeaders(headers);
  }

  private _displayAuctionColumns(): boolean {
    if (isPresetListID(this.idListSelect)) return true;
    if (isNullOrUndefined(this.configMetadata?.isAuctions)) return true;
    return this.configMetadata?.isAuctions;
  }

  private _getListHeaders() {
    if (isPresetListID(this.idListSelect)) return {};
    if (isNullOrUndefined(this.configMetadata?.headers)) return {};
    return deepClone(this.configMetadata.headers);
  }

  //************ START LISTAS DINÂMICAS */
  public changeNotListPersonal(item: IAllStockListSimple): void {
    this.unSubscribeCheetah();
    this.loadingMovers = true;
    this.displayEmptyDynamicList = true;
    this.isNotListPersonal = true;
    this.idListSelect = item.id_stock_list;
    this.movers.clear();
    this.cdr.detectChanges();
    const checkConfigListMotPersonal = new CheckConfigListMotPersonal(
      this.stockListStettingsService,
      this.listStocksService
    );
    checkConfigListMotPersonal.check(item, this.mountedList);
    this.updateMetadataSubject.next();
  }

  private mountedList = (list: IListStockDB) => {
    this.listStockInfo = list;
    this.setStocksAtDictList('idRow', list.stocks);
    this.cdr.detectChanges();
    if (isAuctionList(list.idStockList)) {
      this.mountedRowDataAuction();
    } else {
      this.isListAuction = false;
      this.mountedRowDataMovers();
    }
    this.isLoading = false;
    this.cdr.detectChanges();
  };

  private mountedRowDataAuction(): void {
    this._subscribeCheetahAuction();
    this._updateAuctionEmptyStateMsg();
    if (this._stocksAtAuction.size()) {
      this._stocksAtAuction.clear();
      this._handleAuctionPreferences({});
    }
    this.showColumnsAuction = false;
    this.isListAuction = true;
    this.rowData = [];
    this.columnDefs = AUCTION_HEADERS();
    if (!this.isLoading) this.updateMetadataSubject.next();
    this.cdr.detectChanges();
  }

  private _updateAuctionEmptyStateMsg(): void {
    if (this.auctionIndex.value === 'GERAL') {
      this.updateTableEmptyMessage('Não há ativos em leilão');
      return;
    }
    this.updateTableEmptyMessage(
      `Não há ativos do índice ${this.auctionIndex.value} em leilão no momento`
    );
  }

  private mountedRowDataMovers(): void {
    this.updateTableEmptyMessage('Não há Movers no momento');
    this.columnDefs = [];
    this.rowData = [];
    this._setMoversColumns();
    if (this.isLoading) this.updateMetadataSubject.next();
    this.subscribeCheetahMovers();
    this._moversSortSubject.next();
    this.cdr.detectChanges();
  }

  private _setMoversColumns(): void {
    const mountHeaders = new MountHeaders(
      LIST_CONFIG_TYPE.MOVERS,
      deepClone(this.configMetadata.headers),
      this.moversPeriod,
      this.idListSelect
    );
    this.columnDefs = mountHeaders.getColumns();
  }
  //************ FIM START LISTAS DINÂMICAS */

  //************ START LISTAS PRÉ DEFINIDAS */
  public changePresetList(name: string): void {
    this.updateTableEmptyMessage(
      `Carregando ativos da lista ${name}, por favor aguarde.`
    );
    this.unSubscribeCheetah();
    this.stockListService.getStockListByGroup(name).subscribe({
      next: (stocks) => {
        if (!stocks.length) {
          this.rowData = [];
          this.updateTableEmptyMessage(
            `Não há ativos disponíveis na lista ${name}.`
          );
          return;
        }
        this.rowData = stocks;
        this.stocksAtListDict.bulkData('idRow', stocks as any);
        this.cdr.detectChanges();
        this.mountedRowData();
      },
      error: () => {
        this.updateTableEmptyMessage(
          `Ocorreu um erro ao carregar os ativos da lista ${name}, tente novamente.`
        );
      },
    });
  }

  //************ FIM LISTAS PRÉ DEFINIDAS */

  //************ METODOS DO CHEETAH */

  // MÉTODOS CHANNEL QUOTE
  protected subscribeCheetahQuote(): void {
    this.quoteUnsubscribed = false;
    this.stockTableCheetah.subscribeQuote(this.getAllStocksAtDictList());
    this.stockTableCheetah.quoteStream$.subscribe(this._channelHandler);
    this.cdr.detectChanges();
  }

  private _stocksAtAuctionToSubInQuote(): void {
    this.stockTableCheetah.subscribeQuote(this._stocksAtAuction.values());
  }

  protected unSubscribeCheetahQuote(stocks: IStockListItemsRow[]): void {
    if (
      !this.stockTableCheetah ||
      this.quoteUnsubscribed ||
      stocks.length === 0
    ) {
      return;
    }
    this.quoteUnsubscribed = true;
    this.stockTableCheetah.quoteStream$.unsubscribe();
    this.stockTableCheetah.unsubscribeQuote(stocks);
    this.cdr.detectChanges();
  }

  protected unsubSomeStocksFromQuoteChannel(stocks: { idRow: string }[]): void {
    this.stockTableCheetah.unsubscribeQuote(stocks);
  }

  private _channelHandler = (stockMap: Map<string, any>) => {
    stockMap?.forEach((stock, key) => {
      if (!this.stocksAtListDict.has(key)) return;
      stock.idRow = key;
      delete stock.cd_stock;
      this.stocksAtListDict.set(key, stock);
      this._processStockItem(this.stocksAtListDict.get(key));
    });
  };
  private subjectDebounced = new Subject<void>();

  private initializeTotalSubscription(): void {
    this.subjectDebounced
      .pipe(
        bufferTime(50),
        filter((batch) => batch.length > 0),
        takeUntil(this.destroy$)
      )
      .subscribe((batch: any) => {
        // Executa o que for necessário
        this.gridApi.applyTransaction({ update: batch });
      });
  }

  private _processStockItem(stock: QuoteData | any) {
    if (this.isGridStockList && this._gridApiInitialized) {
      this.subjectDebounced.next(stock);
      if (!this.isListAuction && stock.situacao)
        this._handleAuctionPreferences(stock);
      return;
    }
    if (this.sendQuoteUpdate) {
      this.stockToUpdate = structuredClone(stock);
      this.cdr.detectChanges();
    }
  }

  protected subscribeCheetahMovers = (): void => {
    if (this._enableMoversSort) return;
    this.quoteUnsubscribed = true;
    this.loadingMovers = true;
    this._enableMoversSort = true;
    this.stockTableCheetah.setMoversSubscriptionKey(
      this.idListSelect,
      this.moversIndex,
      this.moversPeriod.value
    );
    this.clearStocksSubject.next();
    this._resetMoversStocks();
    this.stockTableCheetah.subscribeMovers();
    this.stockTableCheetah.moversStream$.subscribe(this.channelHandlerMovers);
    this.cdr.detectChanges();
  };

  // MÉTODOS CHANNEL MOVERS
  protected unSubscribeCheetahMovers = (): void => {
    if (!this.stockTableCheetah || !this._enableMoversSort) return;
    this.stockTableCheetah.unSubscribeMovers();
    this.loadingMovers = false;
    this.displayEmptyDynamicList = false;
    this._enableMoversSort = false;
    this._resetMoversStocks();
  };

  channelHandlerMovers = (payload: Map<string, IMoversData[]>) => {
    const moversData = payload.get(this.stockTableCheetah.typeListMovers);
    if (!moversData?.length) return;
    moversData.forEach((data) => {
      if (data.isEndOfSnap) {
        this._loadMoversSnapShot();
        return;
      }
      const stock = this._setNewFields(data);
      const transaction: IMoversTransaction = {
        add:
          stock.command == COMMAND_ADD
            ? this._stockToAddInMoversList(stock)
            : [],
        update: stock.command == COMMAND_UPDATE ? [stock] : [],
        remove:
          stock.command == COMMAND_DELETE
            ? this._stockToRemoveFromMoversList(stock)
            : [],
      };
      this._updateMoversStockInDict(transaction);
      this.moversCommand = transaction;
      this.cdr.detectChanges();
      if (!this._loadedMoversSnap) return;
      if (!this.isCandleView) this._moversSortSubject.next();
      if (this.isGridStockList && this._gridApiInitialized) {
        this.gridApi?.applyTransactionAsync(transaction);
        this.cdr.detectChanges();
        return;
      }
    });
  };

  private _loadMoversSnapShot(): void {
    if (this._loadedMoversSnap) return;
    if (!this.movers.size() && !this.moversPeriod.value) {
      this._changeMoversPeriodToYesterday();
      return;
    }
    this.rowData = SORT_MOVERS_ROWS(this.idListSelect, this.movers.values());
    this._loadedMoversSnap = true;
    this.loadingMovers = false;
    this.cdr.detectChanges();
  }

  private _setNewFields = (data: IMoversData): IMoversData => {
    const info = data;
    info.idRow = `${data.key}:1`;
    info.isSelected = isRowSelectedStock(
      info.idRow,
      this.globalItem,
      this.cdStockOrder,
      this.synonymousRelated
    );
    info.cd_stock = data.key;
    info.is_movers = true;
    return info;
  };

  private _stockToAddInMoversList = (stock: IMoversData) => {
    if (this.movers.has(stock.idRow)) return [];
    return [stock];
  };

  private _stockToRemoveFromMoversList = (
    stock: IMoversData
  ): { idRow: string }[] => {
    if (this.movers.has(stock.idRow)) return [{ idRow: stock.idRow }];
    return [];
  };

  private _updateMoversStockInDict(commands: IMoversTransaction): void {
    if (commands.add.length || commands.update.length) {
      const value = commands.add.length ? commands.add : commands.update;
      this.movers.set(value[0].idRow, value[0]);
      this.cdr.detectChanges();
      return;
    }
    if (commands.remove.length) {
      this.movers.delete(commands.remove[0].idRow);
      this.cdr.detectChanges();
    }
  }

  private _sortMoversHandler = () => {
    this._moversSortSubject
      .pipe(
        tap(() => {
          this.updateMoversSort = false;
          this.cdr.detectChanges();
        }),
        debounceTime(300),
        filter(() => this._enableMoversSort && !this.isCandleView)
      )
      .subscribe(() => {
        if (this.isGridStockList) {
          this._sortMovers();
          return;
        }
        this.updateMoversSort = true;
        this.cdr.detectChanges();
      });
  };

  private _sortMovers(): void {
    if (!this.movers.size()) return;
    this.gridApi.setRowData(
      SORT_MOVERS_ROWS(this.idListSelect, this.movers.values())
    );
    this.cdr.detectChanges();
  }

  private _changeMoversPeriodToYesterday(): void {
    this.unSubscribeCheetahMovers();
    this.moversPeriod = MOVERS_PERIODS[1];
    this.cdr.detectChanges();
    this.subscribeCheetahMovers();
  }

  // MÉTODOS CHANNEL AUCTION
  private _subscribeCheetahAuction(): void {
    this.stockTableCheetah.subscribeAuction(this.auctionIndex.value);
    this.stockTableCheetah.auctionStream$.subscribe(this.channelHandlerAuction);
    this.loadingMovers = false;
    this.clearStocksSubject.next();
    this.cdr.detectChanges();
    this.subscribeCheetahQuote();
  }

  private _unSubscribeCheetahAuction(): void {
    if (!this.stockTableCheetah) return;
    this.stockTableCheetah.unSubscribeAuction();
    if (this._stocksAtAuction.size()) {
      this.unSubscribeCheetahQuote(this.getAllStocksAtDictList());
      this._stocksAtAuction.clear();
    }
    if (this.stocksAtListDict.size()) this.stocksAtListDict.clear();
    this.rowData = [];
    this.loadingMovers = false;
    this._loadedAuctionSnapShot = false;
    this.cdr.detectChanges();
    return;
  }

  private channelHandlerAuction = (
    stockMap: Map<string, IAuctionChannelData[]>
  ) => {
    if (!this.idListSelect || !isAuctionList(this.idListSelect)) return;
    const payload = stockMap.get(this.auctionIndex.value);
    if (!payload?.length) return;
    payload.forEach((data) => {
      if (data.isEndOfSnap) {
        this._loadedAuctionSnapShot = true;
        this.rowData = this.stocksAtListDict.values();
        this.cdr.detectChanges();
        if (!this.isCandleView) this._stocksAtAuctionToSubInQuoteChannel.next();
        return;
      }

      const stock = {
        idRow: `${data.key}:1`,
        cd_stock: data.key,
        situacao: 'LEILAO',
      };

      if (!this._loadedAuctionSnapShot) {
        this._stocksAtAuction.set(stock.idRow, stock);
        this.stocksAtListDict.set(stock.idRow, stock);
        return;
      }

      if (this.isGridStockList && this._gridApiInitialized) {
        if (
          data.command === COMMAND_ADD &&
          this._stocksAtAuction.has(stock.idRow)
        )
          return;
        this.gridApi?.applyTransactionAsync({
          [AUCTION_COMMANDS_TO_AGGRID[data.command]]: [stock],
        });
      }

      if (this.isCandleView) {
        this.auctionCommand = Object.assign(stock, data);
        this.cdr.detectChanges();
        return;
      }

      if (data.command === COMMAND_ADD) {
        this.stocksAtListDict.set(stock.idRow, stock);
        this._stocksAtAuction.set(stock.idRow, stock);
        this._stocksAtAuctionToSubInQuoteChannel.next();
        return;
      }

      if (
        data.command === COMMAND_DELETE &&
        this._stocksAtAuction.has(stock.idRow)
      ) {
        this.stocksAtListDict.delete(stock.idRow);
        this._stocksAtAuction.delete(stock.idRow);
        this._stocksAtAuctionToUnsub.set(stock.idRow, stock);
        this._stocksAtAuctionToUnsubInQuoteChannel.next();
      }
    });
  };

  protected unSubscribeCheetah(): void {
    if (this.stockTableCheetah) {
      const stocksToUnsub = this.getAllStocksAtDictList();
      this.clearStocksSubject.next();
      this.rowData = [];
      this.movers.clear();
      this.stocksAtListDict.clear();
      this._stocksAtAuction.clear();
      this._stocksAtAuctionToUnsub.clear();
      this.cdr.detectChanges();
      if (stocksToUnsub.length) this.unSubscribeCheetahQuote(stocksToUnsub);
      this._unSubscribeCheetahAuction();
      this.unSubscribeCheetahMovers();
    }
  }
  //************ FIM METODOS DO CHEETAH */

  private _handleAuctionPreferences(stock: QuoteData | any): void {
    const key = stock.idRow;
    const atAuction = isAuction(stock.situacao);
    const displayingAuctionColumns = this.isAuctionColumnsVisible;
    const previouslyAuctioned = this._stocksAtAuction.has(key);
    if (!atAuction && !this.isAuctionColumnsVisible && !previouslyAuctioned)
      return;
    if (atAuction && !previouslyAuctioned) {
      this._stocksAtAuction.set(key, { idRow: key });
    } else if (previouslyAuctioned && !atAuction) {
      this._stocksAtAuction.delete(key);
    }

    this.isAuctionColumnsVisible = this._stocksAtAuction.size()
      ? IS_AUCTION
      : '';
    if (
      this.isAuctionColumnsVisible !== displayingAuctionColumns &&
      this.showColumnsAuction
    ) {
      this._handleAuctionColumnDefs();
    }
    this.cdr.detectChanges();
  }

  public verifyAuctionStocksAfterRemove(stock: any): void {
    if (!this._stocksAtAuction.has(stock.item)) return;
    this._stocksAtAuction.delete(stock.item);
    if (!this._stocksAtAuction.size()) {
      this.isAuctionColumnsVisible = '';
      this._handleAuctionColumnDefs();
    }
    this.cdr.detectChanges();
  }

  private _handleAuctionColumnDefs(): void {
    const data = {
      keys: this.headersAuction,
      visible: false,
    };

    const auctionHeaders = isPresetListID(this.idListSelect)
      ? {}
      : this.listStockInfo.configList?.metadata.headers;
    if (this.isAuctionColumnsVisible === IS_AUCTION) {
      if (isPresetListID(this.idListSelect)) {
        this.columnDefs = AUCTION_HEADERS();
        this.cdr.detectChanges();
        return;
      }
      const mountHeaders = new MountHeaders(LIST_CONFIG_TYPE.AUCTION);
      this.columnDefs = mountHeaders.setHeaders({
        ...HEADERS_AUCTION,
        ...auctionHeaders,
      });
      data.visible = true;
      this.columnVisible = data;
    } else {
      const mountHeaders = new MountHeaders();
      this.columnDefs = mountHeaders.setHeaders(auctionHeaders);
      this.columnVisible = data;
    }
    this.cdr.detectChanges();
  }

  //************ ALTERAR PARA VISIVEL OU INVISIVEL AS COLUNAS */
  protected openConfigHeaders = () => {
    this.triggeredEvent();
    const listType = this.isListAuction ? LIST_CONFIG_TYPE.AUCTION : undefined;
    const mountHeaders = new MountHeaders(listType);
    const headers = this.isListAuction
      ? mountHeaders.configHeaders(this.configMetadata.headers)
      : mountHeaders.configHeadersOpenModalConfig(this.configMetadata.headers);
    const ref = this._rocketModalService.open(ConfigHeaderModalComponent, {
      size: 'xl',
      centered: true,
      backdrop: true,
      keyboard: true,
      scrollable: true,
      data: {
        columns: headers,
        isListAuction: this.isListAuction,
      },
    });
    ref.afterDismissed
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => !res.closed && this.configVisibleOrInvisibleColumns(res)
      );
  };

  protected configVisibleOrInvisibleColumns(columns: any[]): void {
    const updatesConfig = new UpdatesConfig(
      this.listStocksService,
      this.stockListStettingsService
    );
    const stateHeaders = updatesConfig.visibleOrInvisible(
      this.listStockInfo,
      columns,
      this.isListAuction
    );
    this._configVisibleOrInvisibleColumns$.next({
      refComponent: this.refComp,
      stateHeaders,
    });
  }

  protected configVisibleOrInvisibleSingleColumn(
    column: GridIColumnVisibleInService
  ): void {
    this.triggeredEvent();
    this.configMetadata.headers[column.keys[0]].hide = !column.visible;
    const updatesConfig = new UpdatesConfig(
      this.listStocksService,
      this.stockListStettingsService
    );
    updatesConfig.updateListConfig(this.listStockInfo);
    this.listStockInfo.defaultHeadersConfig = false;
  }

  public resetTableHeaders(): void {
    if (this.isNotListPersonal) {
      this.updateDefaultHeaders(
        this._resetColumnsPreferences(MARKET_MOVER_HEADER)
      );
      return;
    }
    this.updateDefaultHeaders(this._resetColumnsPreferences(VISIBLE_HEADERS));
    this.hideTableHeaders.next(this._resetColumnsPreferences(HIDDEN_HEADERS));
  }

  private _resetColumnsPreferences(defaultPreset: any): {
    headers: any;
    keys: string[];
  } {
    const headers = deepClone(this.configMetadata.headers);
    const keys: string[] = [];
    Object.keys(headers).forEach((field) => {
      if (defaultPreset[field]) {
        headers[field] = defaultPreset[field];
        keys.push(field);
      }
    });
    return { headers, keys };
  }

  protected updateDefaultHeaders(
    params: { headers: any; keys: string[] },
    visible: boolean = true
  ): void {
    this.configMetadata.headers = params.headers;
    this.listStockInfo.configList!.metadata.headers = params.headers;
    const updatesConfig = new UpdatesConfig(
      this.listStocksService,
      this.stockListStettingsService
    );
    updatesConfig.updateListConfig(this.listStockInfo);
    this.columnVisible = { keys: params.keys, visible };
    this.listStockInfo.defaultHeadersConfig = true;
    this.cdr.detectChanges();
  }
  //************ FIM ALTERAR PARA VISIVEL OU INVISIVEL AS COLUNAS */

  private updateMetadata(): void {
    this.component.metadata.listStock = {
      idList: this.idListSelect,
      isNotListPersonal: this.isNotListPersonal,
      type: this.currentView,
      moversIndex: this.moversIndex.value,
      auctionIndex: this.auctionIndex.value,
      moversPeriodV2: { period: this.moversPeriod, width: 0 },
      presetListName: this.presetListName,
    };
    this.homeService.updateMeta(this.component);
  }

  public onChangeAuctions = (): void => {
    this.triggeredEvent();
    this.showColumnsAuction = !this.showColumnsAuction;
    this.configMetadata.isAuctions = this.showColumnsAuction;
    const updatesConfig = new UpdatesConfig(
      this.listStocksService,
      this.stockListStettingsService
    );
    updatesConfig.updateListConfig(this.listStockInfo);
    if (!this.showColumnsAuction) {
      this.isAuctionColumnsVisible = '';
      this._handleAuctionColumnDefs();
    } else if (this._stocksAtAuction.size()) {
      this.isAuctionColumnsVisible = IS_AUCTION;
      this._handleAuctionColumnDefs();
    }
    this.cdr.detectChanges();
  };

  protected triggeredEvent(): void {
    localStorage.setItem(
      TRIGGERED_THE_EVENT,
      `${this.refComp}/${this.componentId}`
    );
  }

  // MÉTODOS PARA GERENCIAR O TOUR DA LISTA
  public handleComponentTour = (): void => {
    this._forceDisplayContexMenu$.next({ refComponent: this.refComp });
    this.introJSService.stockTable(this.componentId);
    this.introJSSubscription = this.introJSService
      .onStart()
      .subscribe((res) => {
        if (res.action === 'DISPLAY_DROPDOWN') {
          if (res.isChangeView) this.isChangeViewStep = true;
          else this._handleDropDownVisibility(true);
        } else if (res.action === 'HIDE_DROPDOWN') {
          if (this.isChangeViewStep) this.isChangeViewStep = false;
          this._handleDropDownVisibility(false);
        }
        if (['DISPLAY_CONTEXT', 'HIDE_CONTEXT'].includes(res.action))
          this._handleContextVisibilityOnTour(res.action === 'DISPLAY_CONTEXT');

        if (res.action === 'CLOSED') this._removeAllTourEvents();
        this.cdr.detectChanges();
      });
  };

  private _forceDisplayContexMenu(): void {
    const position = document
      .getElementById(this.component.id)
      ?.getBoundingClientRect();
    const config: GridIOpenConfigGrid = {
      refComponent: '',
      rowSelect: undefined,
      keyHeader: '',
      isShowEdit: false,
      isVisible: true,
      isTour: true,
      event: {
        view: {
          innerHeight: document.body.clientHeight,
          innerWidth: document.body.clientWidth,
        },
        pageY: position!.y + 50,
        pageX: position!.x + 50,
      },
      stockListId: 0,
      showColumnsAuction: false,
      isListAuction: false,
      callbackAuction: null,
      isNotListPersonal: false,
      callBackHelper: null,
      defaultHeadersConfig: false,
      exportDataCallback: null,
    };
    this._showConfig(config);
  }

  private _handleDropDownVisibility(displayDropDown: boolean): void {
    const element: HTMLElement | null = document
      .getElementById(this.componentId)!
      .querySelector(`#${STOCK_TABLE_ELEMENT_IDS.STOCK_TABLE_LISTS} > div`);
    if (element)
      element.style.visibility = displayDropDown ? 'inherit' : 'hidden';
  }

  private _handleContextVisibilityOnTour(displayContext: boolean): void {
    const element = document.querySelector<HTMLElement>(
      'app-grid-config > div'
    );
    if (element) {
      element.style.zIndex = displayContext ? '99' : '-1';
      element.style.display = displayContext ? 'block' : 'none';
    }
  }

  private _removeAllTourEvents(): void {
    const findAll = document.querySelectorAll('app-grid-config');
    if (findAll && findAll.length) findAll.forEach((elem) => elem.remove());
    if (this.introJSSubscription) this.introJSSubscription.unsubscribe();
    this.toggleDP = false;
    this.isChangeViewStep = false;
    this._handleDropDownVisibility(false);
    this.cdr.detectChanges();
  }

  makeStockTableCheetah() {
    this.stockTableCheetah = new StockTableCheetah(
      this.quoteChannel,
      this.auctionChannel,
      this.moversChannel,
      this.componentId,
      this.refComp
    );
  }

  // MÉTODOS PARA GERENCIAR OS ATIVOS DA LISTA
  public setStocksAtDictList(
    key: string,
    stocks: IStockListItemsRow[]
  ): boolean {
    const stocksCopy = stocks.length ? stocks : [];
    if (this.haveStocksAtDictList()) this.stocksAtListDict.clear();
    if (this.isGridStockList && this._gridApiInitialized)
      this.gridApi.setRowData(stocksCopy);
    this.rowData = stocksCopy;
    this.stocksAtListDict.bulkData(key, stocksCopy);
    this.cdr.detectChanges();
    return true;
  }

  public addStockAtListDict(key: string, value: IStockListItemsRow) {
    this.stocksAtListDict.set(key, value);
  }

  public getAllStocksAtDictList(): IStockListItemsRow[] {
    if (this.haveStocksAtDictList())
      return deepClone(this.stocksAtListDict.values());
    return [];
  }

  public getSpecificStocksAtDictList(
    key: string,
    ignoreVerification: boolean = false
  ): IStockListItemsRow | null {
    if (ignoreVerification) return this.stocksAtListDict.get(key)!;
    if (this.haveSpecificStockAtDictList(key))
      return this.stocksAtListDict.get(key)!;
    return null;
  }

  public removeStockFromDictList(keyToRemove: string): boolean {
    if (this.haveSpecificStockAtDictList(keyToRemove)) {
      this.stocksAtListDict.delete(keyToRemove);
      return true;
    }
    return false;
  }

  public haveSpecificStockAtDictList(key: string): boolean {
    return this.stocksAtListDict.has(key);
  }

  public haveStocksAtDictList(): boolean {
    return this.stocksAtListDict.size() > 0;
  }

  // MÉTODOS PARA ALTERAR O TIPO DA LISTA
  public verifyStockTableType(): void {
    if (
      !this.component.metadata?.listStock ||
      !this.component.metadata?.listStock?.type
    ) {
      if (this.component.metadata.component.initialOptions) {
        this._configureInitialOptions(
          this.component.metadata.component.initialOptions
        );
        return;
      }
      return;
    }
    this.changeStockTableView(this.component.metadata.listStock!!.type, true);
    this.initialStockTableType = this.component.metadata.listStock!!.type;
    this.cdr.detectChanges();
  }

  private _configureInitialOptions(options: any): void {
    if (options.initialView) {
      this.changeStockTableView(options.initialView, true);
      this.initialStockTableType = options.initialView;
    }
    if (options.dynamicList) {
      const list = DYNAMIC_LISTS[options.dynamicList];
      this.idListSelect = list.id;
      this.dynamicInitialList = list;
    }
    this.cdr.detectChanges();
  }

  public changeStockTableView(
    view: STOCK_TABLE_VIEW,
    initialChange: boolean = false
  ): void {
    if (view === this.currentView) return;
    const previousView = this.currentView;
    this.currentView = view;
    this._updateComponentSettingsOnChangeView();
    if (!initialChange) {
      this._verifyPreviousView(previousView);
      this._updateSettingsBeforeChangeView(view);
    }
    this.cdr.detectChanges();
  }

  private _updateComponentSettingsOnChangeView(): void {
    if (!this.isGridStockList) {
      this._gridApiInitialized = false;
      this.component.metadata.layout.isMaximized = false;
      this.component.metadata.headerOptions.fullscreen = false;
      const params = {
        ref: this.component.id,
        isMaximized: false,
      };
      this.workspaceConfig.maximizedComponent = params;
      this.cdr.detectChanges();
      return;
    }
    this.component.metadata.headerOptions.fullscreen = true;
    this.cdr.detectChanges();
  }

  public _verifyPreviousView(previousView: STOCK_TABLE_VIEW): void {
    if (
      previousView === STOCK_TABLE_VIEW.CANDLE &&
      (!this.isNotListPersonal || isPresetListID(this.idListSelect))
    )
      this.subscribeCheetahQuote();
  }

  private _updateSettingsBeforeChangeView(type: STOCK_TABLE_VIEW): void {
    this.stockToUpdate = null;
    this.stockToRemove = null;
    this._verifySnapshotBeforeChangeView();
    if (type === STOCK_TABLE_VIEW.CANDLE)
      this.unSubscribeCheetahQuote(this.getAllStocksAtDictList());
    this.cdr.detectChanges();
    if (this.isGridStockList) this.updateGlobalStockSubject.next(undefined);
    this.updateMetadataSubject.next();
    if (type === STOCK_TABLE_VIEW.GRID) {
      if (isMoversList(this.idListSelect)) {
        this._setMoversColumns();
      } else this.mountHeaders();
    }
  }

  private _verifySnapshotBeforeChangeView(): void {
    if (this.isNotListPersonal && isMoversList(this.idListSelect)) {
      this._moversSnapshot();
      return;
    }
    this.movers.clear();
    this.rowData = [];
    this.cdr.detectChanges();
    if (!this.haveStocksAtDictList()) return;
    this.setSnapshotOnChangeView.next('STOCKS');
  }

  private _moversSnapshot(): void {
    this.setSnapshotOnChangeView.next('MOVERS');
    if (!this._enableMoversSort) this.subscribeCheetahMovers();
  }

  private _setSnapshotOnChangeView(type: string): void {
    const stocks =
      type === 'MOVERS'
        ? SORT_MOVERS_ROWS(this.idListSelect, this.movers.values())
        : this.setSelectedStock(this.getAllStocksAtDictList());
    if (this._gridApiInitialized && this.isGridStockList)
      this.gridApi.setRowData(stocks);
    this.rowData = stocks;
    this.cdr.detectChanges();
  }

  // MÉTODOS PARA ALTERAR O INDICE DO MOVERS/LEILAO
  public setIndexSelected(
    listPreferences: IStockListPreferences | undefined
  ): void {
    if (!listPreferences) return;
    this.moversIndex = {
      value: listPreferences?.moversIndex
        ? listPreferences.moversIndex
        : 'IBOV',
    };
    this.auctionIndex = {
      value: listPreferences?.auctionIndex
        ? listPreferences.auctionIndex
        : 'IBOV',
    };
  }

  public updateListIndex(index: IStockTableIndex): void {
    this.updateIndexInMetadata(index);
    if (isMoversList(this.idListSelect)) {
      this._updateMoversIndex();
      return;
    }

    if (isAuctionList(this.idListSelect)) {
      this._updateAuctionEmptyStateMsg();
      this._unSubscribeCheetahAuction();
      this._subscribeCheetahAuction();
    }
  }

  public updateIndexInMetadata(index: IStockTableIndex): void {
    if (isAuctionList(this.idListSelect)) this.auctionIndex = index;
    else this.moversIndex = index;
    this.cdr.detectChanges();
    this.updateMetadataSubject.next();
  }

  private _updateMoversIndex(): void {
    this.unSubscribeCheetahMovers();
    this.subscribeCheetahMovers();
  }

  private setSelectedStock(rows: any[]) {
    if (!rows?.length) return [];
    return rows.map((row) => {
      row.isSelected = isRowSelectedStock(
        row.idRow,
        this.globalItem,
        this.cdStockOrder,
        this.synonymousRelated
      );
      return row;
    });
  }

  // MÉTODOS PARA ALTERAR O PERIODO DO MOVERS
  public setInitialPeriod(
    initialPeriod: IMoversPeriodMetadata | undefined
  ): void {
    if (!initialPeriod || !initialPeriod?.period) {
      this.moversPeriod = MOVERS_PERIODS[0];
      return;
    }
    this.moversPeriod = initialPeriod.period;
    this.cdr.detectChanges();
  }

  public updateMoversPeriod(params: IMoversPeriod): void {
    this.unSubscribeCheetahMovers();
    this.moversPeriod = params;
    this.updateMetadataSubject.next();
    this._setMoversColumns();
    this._resetMoversStocks();
    this.subscribeCheetahMovers();
    this._moversSortSubject.next();
  }

  private _resetMoversStocks(): void {
    this._loadedMoversSnap = false;
    this.movers.clear();
    this.rowData = [];
    this.cdr.detectChanges();
  }

  exportData = () => {
    const id = ExportDataIdEnum.LISTA_ATIVOS;
    const csv = this.exporter.exporterFunctions[id](this.gridApi);
    const stockListDocName = this.resolveStockListFileName();
    if (csv)
      this.exporter.exportData(stockListDocName, csv, id.toLowerCase(), false);
  };

  setInvisibleColumn(): void {
    const params: GridIColumnVisibleInService = {
      refComponent: this.refComp,
      visible: false,
      keys: [this.keyHeader!],
    };
    this.rocketGridService.setInvisibleColumn(params);
    this.cdr.detectChanges();
  }

  addStock(): void {
    this.rocketGridService.setOpenAddRow(this.refComp, this.selectedRow);
    this.cdr.detectChanges();
  }

  removeStock(): void {
    this.rocketGridService.setRemoveStockFromSameLists({
      idList: this.idListSelect ?? 0,
      rowData: this.selectedRow,
    });
    this.cdr.detectChanges();
  }

  editGrid(): void {
    this.isShowEdit = !this.isShowEdit;
    this.rocketGridService.setEdit({
      refComponent: this.refComp,
      value: this.isShowEdit,
    });
    this.cdr.detectChanges();
  }

  configColumns(): void {
    this.rocketGridService.setConfigColumns({
      refComponent: this.refComp,
      value: true,
    });
    this.cdr.detectChanges();
  }

  resetHeaders(): void {
    this.rocketGridService.resetTableHeaders({
      refComponent: this.refComp,
    });
    this.cdr.detectChanges();
  }

  private resolveStockListFileName = () =>
    this.listStockInfo.list.nm_stock_list
      .replaceAll('> ', '')
      .replaceAll(' ', '-');
}
