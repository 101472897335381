import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  ChangeDetectionStrategy,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'fla-card',
  templateUrl: './card.component.html',
})
export class CardComponent implements OnChanges, AfterViewInit {
  @ContentChildren('card')
  allCards!: QueryList<any>;
  @Input('base') isBase: boolean = false;
  @Input('css') customClass: string = '';

  constructor(public cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
  }
}
