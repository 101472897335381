import {
  Component,
  AfterViewInit,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
  ViewChild,
  Inject,
  LOCALE_ID,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { NumberRange } from 'scichart/Core/NumberRange';
import { easing } from 'scichart/Core/Animations/EasingFunctions';
import {
  TCandleLineEvent,
  TCandleLineOptions,
  TCorporateEvents,
  TData,
  TEvents,
  TMouseEventData,
  TNewsChartEvents,
  TRelevantEvents,
  TSecondaryChart,
  TTradeIdeaEvents,
  TUpdateData,
} from './types/tiger-chart.types';
import {
  TIGER_DOUBLE_STOP_ORDER_IDS,
  TIGER_INDICATORS_ENUM,
  TIGER_INDICATOR_SOURCES,
  TIGER_INTERVAL_ENUM,
  TIGER_KEYPRESS_ARROWS,
  TIGER_LINE_TYPE,
  TIGER_MOUSE_EVENT_DATA_TYPE,
  TIGER_TYPE_CHART_ENUM,
} from './enum/tiger-chart.enum';
import { OhlcDataSeries } from 'scichart/Charting/Model/OhlcDataSeries';
import { XyDataSeries } from 'scichart/Charting/Model/XyDataSeries';
import { ECoordinateMode } from 'scichart/Charting/Visuals/Annotations/AnnotationBase';
import { CustomAnnotation } from 'scichart/Charting/Visuals/Annotations/CustomAnnotation';
import { CategoryAxis } from 'scichart/Charting/Visuals/Axis/CategoryAxis';
import { NumericAxis } from 'scichart/Charting/Visuals/Axis/NumericAxis';
import { FastCandlestickRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastCandlestickRenderableSeries';
import { FastLineRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastLineRenderableSeries';
import { FastMountainRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastMountainRenderableSeries';
import { FastOhlcRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastOhlcRenderableSeries';
import { GradientParams } from 'scichart/Core/GradientParams';
import {
  EVerticalAnchorPoint,
  EHorizontalAnchorPoint,
} from 'scichart/types/AnchorPoint';
import { EAutoRange } from 'scichart/types/AutoRange';
import { EAxisAlignment } from 'scichart/types/AxisAlignment';
import {
  getCardTravel,
  getChartArrow,
  getNotificationTemplate,
  getTextBoxTemplate,
} from './templates/tiger-chart.template';
import { Point } from 'scichart/Core/Point';
import { HorizontalLineAnnotation } from 'scichart/Charting/Visuals/Annotations/HorizontalLineAnnotation';
import { DisableMoveDecorationsModifier } from './annotations/disable-move-decorations-modifier';
import { BaseIndicator } from './indicators/indicators.types';
import {
  auditTime,
  delay,
  filter,
  map,
  skipWhile,
  switchMap,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { Subject, Subscription, interval, of, debounceTime } from 'rxjs';
import { RTChartScichartBase } from './chart-base-scichart';
import { MouseEventsModifier } from './annotations/mouse-events-modifier';
import { TigerChartTooltipComponent } from './tooltip/tiger-chart-tooltip.component';
import { GraphContainer } from './directives/graph-container.directive';
import { formatNumber } from '@angular/common';
import { Thickness } from 'scichart/Core/Thickness';
import {
  IAddHammers,
  ICandleColorsCandle,
  ICandleConfigs,
  ITigerChartTourEventEmitter,
} from './interface/tiger-chart.interface';
import {
  CONTEXT_MENU_ITEM_ENUM,
  CONTEXT_MENU_ORDER_TYPE,
} from './enum/context-menu.enum';
import { TigerChartIndicatorVolume } from './indicators/volume';
import {
  CANDLE_IDS,
  TIGER_ANNOTATION_INDICATORS,
  TIGER_INDICATOR_CLICK,
  TIGER_SUB_CHART_INDICATORS,
  minMaxIDS,
} from './constants/tiger-chart.constants';
import { ICandleContextMenu } from './interface/context-menu.interface';
import { SettingsModalComponent } from './indicators/settings-modal/settings-modal.component';
import {
  ALERT_TEXT_COLOR,
  SIDE_TEXT_COLOR,
} from '@core/components/orders-history-core/constants/orders-history.const';
import { TextAnnotation } from 'scichart/Charting/Visuals/Annotations/TextAnnotation';
import { IAnnotation } from 'scichart/Charting/Visuals/Annotations/IAnnotation';
import {
  COVERAGE_PRICE_ID,
  HEADER_SIZES,
  NEW_ANNOTATION_INDICATOR,
  NEW_LINE_ID,
  UNIVERSAL_SUBCHART_PERCENT_HEIGHT,
  ZOOM_PAN_MODIFIER_ID,
  ZOOM_RUBBER_BAND_MODIFIER_ID,
  reOrderVisibleRange,
} from '@shared/components/stock-chart/constants/stock-chart.constant';
import { StockChartHelper } from '@shared/components/stock-chart/stock-chart.helper';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { EXyDirection } from 'scichart/types/XyDirection';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { RTTextLengthService } from './services/text-length.service';
import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { ModifierMouseArgs } from 'scichart/Charting/ChartModifiers/ModifierMouseArgs';
import { ELabelPlacement } from 'scichart/types/LabelPlacement';
import { ClickableAnnotation } from './annotations/clickable-annotation';
import {
  BookPaletteProvider,
  CHART_COLORS,
  VolumePaletteProvider,
} from './colors';
import { ToolbarDrawService } from './tiger-chart-tools/toolbar-draw/toolbar-draw.service';
import {
  IAccountSelect,
  IAlert,
  IOrders,
  ISearchStock,
} from 'src/app/core/interface';
import { CustodyStopOrder } from './custody-stop-orders/custodyStopOrder';
import { CustodyStopOrdersAlertComponent } from './custody-stop-orders/custody-stop-orders-alert/custody-stop-orders-alert.component';
import {
  isDoubleStartStop,
  isLimited,
  isStartStop,
  isTypeStockIndex,
} from '@shared/constants/general.contant';
import { ToastService } from '@shared/services';
import { saveConfiguration } from './tiger-chart-tools/tiger-chart-tools.interface';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { LogarithmicAxis } from 'scichart/Charting/Visuals/Axis/LogarithmicAxis';
import { Dictionary, TPOrderStatus } from '@core/models';
import { ORDER_TYPE_DIC } from '@shared/dictionary/order.dictionary';
import { ORDER_TYPES } from '@shared/components/stock-trade/constants/boleta.constants';
import { AxisService } from './services/axis.service';
import {
  buildBookInfo,
  createArrayIndex,
  getIncrementValue,
  getLineAuxAnnotationId,
  getLineBoxAnnotationId,
  isNullOrUndefined,
  objectValueOrTrue,
  processOrdersFilterQtty,
  toValue,
} from 'src/app/utils/utils.functions';
import { StockChartModalService } from '@shared/components/stock-chart/parts/modal-more-options/service/stock-chart-modal.service';
import { QuoteData } from '@shared/channel/interface/quote.channel.interface';
import { ESeriesType } from 'scichart/types/SeriesType';
import { EChart2DModifierType } from 'scichart/types/ChartModifierType';
import { ChartModifierBase2D } from 'scichart/Charting/ChartModifiers/ChartModifierBase2D';
import { FastColumnRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastColumnRenderableSeries';
import { RocketModalService } from '@rocket-ui/components/index';
import {
  deepClone,
  formatterNumber,
  hex2rgba,
  randomId,
  standardizeDate,
  stringToDate,
} from '@rocket-ui/utils';
import { EClipMode } from 'scichart/Charting/Visuals/Axis/AxisBase2D';
import { PlusLineService } from './services/plus-line.service';
import { ClickEventsModifier } from './annotations/indicator-click-modifier';
import { AltShiftOrderService } from './services/alt-shift-order.service';
import { IAltShiftOrder } from './interface/alt-shift-order.interface';
import { IYAxisLabelFormatter } from './interface/axis.interface';
import { TigerMiddleChartService } from './services/tiger-middle-chart.service';
import {
  AnnotationClickEventArgs,
  CategoryCoordinateCalculator,
  EllipsePointMarker,
} from 'scichart';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { MiniBookChannel } from '@shared/channel/minibook.channel';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { DataResume } from '@shared/components/table-book/data-resume';
import { ColumnBarService } from './services/column-bar.service';
import { IColumnBook } from './interface/column-book.interface';
import {
  HotkeysService,
  IHotkeys,
} from '@shared/rocket-components/services/hotkeys/hotkeys.service';
import {
  IVolumeEventData,
  VolumeChannel,
} from '@shared/channel/volume.channel';
import { VolumeData } from '@shared/channel/interface/volume.channel.interface';
import { StackedColumnCollection } from 'scichart/Charting/Visuals/RenderableSeries/StackedColumnCollection';
import { HitTestInfo } from 'scichart/Charting/Visuals/RenderableSeries/HitTest/HitTestInfo';
import { ColorsBaseService } from './tiger-chart-tools/toolbar-draw/colors-base.service';
import { TYPE_CLICK } from './tiger-chart-orders/tiger-chart-oders.enum';
import {
  ORDER_CONFIRM_ACTIONS_ENUM,
  TYPE_ORDE_SIDE_ENUM,
} from '@shared/enum/buyOrSell.enum';
import { TYPE_ORDE_ENUM } from '@shared/components/stock-trade/enum/stock-trade.enum';
import { OrdersService } from '@shared/services/orders.service';
import { MultibrokerService } from '@shared/services/core/multibroker';
import { OrderChannel } from '@shared/channel/order.channel';
import { TigerChartOrdersService } from './tiger-chart-orders/tiger-chart-orders.service';
import { CustodyStopOrderService } from './custody-stop-orders/custody-stop-order.service';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import { TigerChartEventsModalService } from './tiger-chart-events-modal/tiger-chart-events-modal.service';
import { EventsContextMenuService } from './tiger-chart-events-modal/events-context-menu/events-context-menu.service';
import { ARROW_TYPES } from '@shared/components/stock-chart/enum/stock-chart.enum';
import { TigerChartToolsComponent } from './tiger-chart-tools/tiger-chart-tools.component';
import { HomeService } from '@modules/home/service/home.service';
import { wheelZoomModifier } from './annotations/mouse-wheel-zoom-modifier';
import { ITigerChartMessage } from './tiger-chart-message/tiger-chart-message.interface';
import { BODY_TEMPLATE } from './tiger-chart-message/tiger-chart-message.enum';
import { IntrojsService } from '@core/introjs/introjs.service';
import { TOOLS_ENUM } from './tiger-chart-tools/tiger-chart-tools.enum';
import { CustomNumericTickProvider } from './provider/customNumericTickProvider';
import { CustomLogarithmicTickProvider } from './provider/customLogarithmicTickProvider';
import { TigerChartOrdersComponent } from './tiger-chart-orders/tiger-chart-orders.component';
import { GlobalChartConfiguration } from '@shared/components/stock-chart/parts/modal-stock/global-chart-configuration';
import { ITypeButton } from './annotations/annotation-tooltip/annotation-tooltip.interface';
import {
  ExecOrderAnnotation,
  ExecOrderFlagAnnotation,
} from './annotations/exec-order-annotation';
import {
  ExecutedOrdersService,
  execOrderId,
} from './services/executed-orders.service';
import { MinMaxAnnotationService } from './services/min-max-annotation.service';
import { FiltersService } from './services/filters.service';
import { SeparatorDateAnnotationService } from './services/separator-date-annotation.service';
import {
  ASK_LINE_ID,
  BID_LINE_ID,
  CreateLineService,
  PREVIOUS_LINE_ID,
} from './services/create-line.service';
import { WatermarkService } from './services/watermark.service';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import {
  IOrderChartData,
  OrderChartChannel,
} from '@shared/channel/order-chart.channel';
import { RocketStreamRead } from '@shared/channel/rx-event';
import { CustodyChannel } from '@shared/channel/custody.channel';
import { TigerChartContextMenuComponent } from './context-menu/tiger-chart-context-menu.component';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';
import { TOOLS } from './tiger-chart-tools/tiger-chart-tools.const';
import { HammersService } from './services/hammers.service';
import { StackedColumnCollectionTiger } from './services/stacked-column-collection-tiger';
import {
  CandlePaletteProvider,
  defaultNegativeElephantColor,
  defaultPositiveElephantColor,
} from './annotations/candle-palette-provider';
import { SuperSearchService } from '@modules/home/super-search.service';
import { StockChartChannelService } from '@shared/components/stock-chart/service/stock-chart-channel.service';
import { InputCountService } from '@shared/rocket-components/input-count/input-count.service';
import { TFooterType } from './tiger-chart-footer/tiger-chart-footer.enum';
import { IPosition } from '@shared/components/stock-chart/interface/stock-chart.interface';
import { TigerChartIndicatorElephantBar } from './indicators/elephant-bar';
import { HorizontalLine } from './annotations/horizontal-line-annotation';
import { TigerYAxisDragModifier } from './annotations/yaxis-drag-modifier';
import { VolumeAtPriceService } from './services/volume-at-price.service';

@Component({
  selector: 'app-tiger-chart',
  templateUrl: './tiger-chart.component.html',
  styleUrls: ['./tiger-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TigerChartComponent
  extends RTChartScichartBase
  implements AfterViewInit, OnDestroy
{
  ref: string = randomId('candle_vertical');
  idMainChart: string = `${this.ref}_main-tiger-chart`;
  private xAxisId: string = `xAxis_${this.ref}`;
  private watermarkId: string = `${this.ref}_watermark`;
  private watermarkBusinessId: string = `${this.watermarkId}_business`;
  private xAxisBookId: string = `xAxis_Book_${this.ref}`;
  private orderLines: TCandleLineOptions[] = [];
  private createAxis$ = new Subject<void>();
  @Input() parentRef!: string;
  @Input() refComponent!: string;
  @Input() userCustody!: IPosition | undefined;
  @Input() minPriceIncrement!: number;
  @Input() chartTradingOffset!: number;
  set width(value: number) {
    this.configs.width = value;
  }
  set height(value: number) {
    this.updateInfoCharts(value);
    this.configs.height = value;
  }

  @Input() set events(events: TEvents | undefined) {
    const func = () => {
      if (events) {
        this.tigerChartEventsModalService.addEvents(
          events,
          this.xAxis,
          this.data,
          this.configs.series.interval!
        );
      }
    };
    this.isStarted(func);
  }

  set settings(value: Partial<ICandleConfigs>) {
    this.applyConfigs = value;
    const func = () => {
      if (this.data?.vl_close.length <= 0) return;
      if (value?.yAxis?.labelPrecision !== undefined) {
        this.setCloseLine(
          this.data.vl_open[this.data.vl_open.length - 1],
          this.data.vl_close[this.data.vl_close.length - 1]
        );
        const vl_volume = this._volumeIndicator.getLastVolume();
        this.tooltip.updateTextData(
          vl_volume,
          this.data.vl_close.length - 1,
          true
        );
      }
      if (
        value?.series?.line?.sortBy !== undefined ||
        value?.series?.area?.sortBy !== undefined
      ) {
        this.setDataSeriesLine();
      }
      if (value?.series?.interval !== undefined) {
        this.setWatermark(undefined, this.nmCompanyValemobi);
        this.setXAxisVisibleRangeChange(value?.series?.interval);
      }
      if (value?.series !== undefined) this.changeTypeSeries();
    };
    this.isStarted(func);
  }
  @Input() set removeLine(boxId: string) {
    const func = () => {
      if (boxId) this.deleteLine(boxId);
    };
    this.isStarted(func);
  }
  @Input() set indicator(indicator: BaseIndicator[]) {
    const func = () => {
      if (indicator && indicator.length) {
        indicator.forEach((ind) => {
          this.addIndicator(ind);
        });
      }
    };
    this.isStarted(func);
  }
  @Input() set draws(draws: saveConfiguration[]) {
    const func = () => {
      this._addDraws$.next(draws);
    };
    this.isStarted(func);
  }
  @Input() set prependData(data: TData) {
    const func = () => {
      if (data) this.prependToData(data);
    };
    this.isStarted(func);
  }

  @Input() set alerts(data: IAlert[]) {
    const func = () => {
      if (data) this.insertAlerts(data);
    };
    this.isStarted(func);
  }
  @Input() set settingsConfig(settings: Partial<ICandleConfigs>) {
    this.applyConfigs = settings;
  }
  @Input() set log(isLog: boolean) {
    this.isLog = isLog;
    const func = () => {
      this.changeTypeAxis();
    };
    this.isStarted(func);
  }
  @Input() set freeScale(isFreeScale: boolean) {
    this.isFreeScale = isFreeScale;
    const func = () => {
      this.changeFreeScale();
    };
    this.isStarted(func);
  }
  @Input() set isChartBook(showChartBook: boolean) {
    this.showChartBook = showChartBook;
    const func = () => {
      this.chartBook();
    };
    this.isStarted(func);
  }
  @Input() set isFastOrder(showFastOrder: boolean) {
    this.showFastOrder = showFastOrder;
    const func = () => {
      this.canShowFastOrder = true;
      this.fastOrder();
      if (this.showFastOrder && this.userCustody) {
        this.userCustody = { ...this.userCustody };
      }
    };
    this.isStarted(func);
    this.searchPositionAndOrder();
  }
  @Input() set isChartVolumeAtPrice(showChartVolumeAtPrice: boolean) {
    this.showChartVolumeAtPrice = showChartVolumeAtPrice;
    const func = () => {
      this.chartVolumeAtPrice();
    };
    this.isStarted(func);
  }
  @Input() set isExecOrders(showExecOrders: boolean) {
    if (this.showExecOrders !== showExecOrders) {
      this.showExecOrders = showExecOrders;
      const func = () => {
        this.showExecOrderControl({
          showExecOrders: this.showExecOrders,
        });
      };
      this.isStarted(func);
    }
  }
  @Input() set isFlagExecOrders(showFlagOrders: boolean) {
    this.showFlagOrders = showFlagOrders;
    const func = () => {
      !this.showFlagOrders && this.clearOrderFlagAnnotation();
    };
    this.isStarted(func);
  }
  @Input() set isOrderMarkerOrders(showOrderMarker: boolean) {
    this.showOrderMarker = showOrderMarker;
    const func = () => {
      !this.showOrderMarker && this.clearOrderMarkerAnnotation();
    };
    this.isStarted(func);
  }
  @Input() set isOpenFlagOrders(showOpenFlag: boolean) {
    this.showOpenFlag = showOpenFlag;
    const func = () => {
      !this.showOpenFlag && this.clearOrderFlagOpenAnnotation();
    };
    this.isStarted(func);
  }
  @Input() set isEvents(showEvents: boolean) {
    this.showEvents = showEvents;
    this.started$
      .pipe(
        takeUntil(this._onDestroy),
        skipWhile((res) => !res)
      )
      .subscribe(() => {
        if (this.showEvents && this.xAxis) {
          this.tigerChartEventsModalService.dispatchUpdateVisibleEvents(
            this.xAxis.visibleRange
          );
        }
        this.tigerChartEventsModalService.displayEvents(this.showEvents);
      });
  }
  @Input() set isZoomIn(zoomIn: boolean) {
    this.zoomIn = zoomIn;
    const func = () => {
      this.updateZoomChart();
    };
    this.isStarted(func);
  }
  @Input() set isBookButton(showBookButton: boolean) {
    this.showBookButton = showBookButton;
  }
  @Input() set fastOrderWidth(fastOrderWidth: number | undefined) {
    this.divOrderWidth = fastOrderWidth || this.divOrderWidth;
  }
  @Input() set isFastOrderDaytradeStrategy(
    showFastOrderDaytradeStrategy: boolean
  ) {
    this.showFastOrderDaytradeStrategy = showFastOrderDaytradeStrategy;
    this.searchPositionAndOrder();
  }
  @Input() set isVolume(volume: boolean) {
    this.changeVolume();
  }
  @Input() tickSize!: number;
  @Input() idExchange!: number;
  @Input() isWithTools: boolean = true;
  @Input() defaultCursor!: string;
  @Output() openIndicatorsModal = new EventEmitter<void>();
  @Output() withToolsChange = new EventEmitter<boolean>();
  @Output() defaultCursorChange = new EventEmitter<string>();
  @Output() updateOrderWidth = new EventEmitter<number>();
  @Output() changeActiveStock = new EventEmitter<ISearchStock>();
  @Output() changeNegotiationLot = new EventEmitter<number>();
  @Output() freeScaleEmmitter = new EventEmitter<{
    isFreeScale: boolean;
    updateMetadata: boolean;
  }>();
  @Output() zoomInEmmitter = new EventEmitter<boolean>();
  @Output() keypressEvent = new EventEmitter<any>();
  @Output() lineMoved = new EventEmitter<TCandleLineEvent>();
  @Output() boxLineDeleted = new EventEmitter<TCandleLineEvent>();
  @Output() indicatorSettings = new EventEmitter<TIGER_INDICATORS_ENUM>();
  @Output() indicatorDelete = new EventEmitter<TIGER_INDICATORS_ENUM>();
  @Output() rightClickEvent = new EventEmitter<
    | ICandleContextMenu<{
        pointValueY: number;
        gainOrderPrice?: number | null;
        lossOrderPrice?: number | null;
        quantity?: number;
      }>
    | undefined
  >();
  @Output() chartReady = new EventEmitter();
  @Output() chartUpdate = new EventEmitter();
  @Output() changeInterval = new EventEmitter();
  @Output() tourChange: EventEmitter<ITigerChartTourEventEmitter> =
    new EventEmitter<ITigerChartTourEventEmitter>();
  @ViewChild(GraphContainer, { static: true }) graphContainer!: GraphContainer;
  @ViewChild('fastOrder') fastOrderComponent!: TigerChartOrdersComponent;
  @ViewChild('TigerChartTooltip') tooltip!: TigerChartTooltipComponent;
  @ViewChild(CustodyStopOrdersAlertComponent)
  custodyStopOrdersAlert!: CustodyStopOrdersAlertComponent;
  @ViewChild('tigerChartTools')
  tigerChartTools!: TigerChartToolsComponent;
  positions: Dictionary<IPosition> = new Dictionary();
  selectedIndicator: BaseIndicator | undefined;
  lineAlertData: any = {};
  subChartHeightRemove = 0;
  messageChartHeightRemove = 0;
  showStockMessage = new Dictionary<ITigerChartMessage>();
  forceHideStockMessage: boolean = false;
  showBookButton: boolean = true;
  showEvents: boolean = true;
  xAxis!: CategoryAxis;
  xAxisBook!: NumericAxis | LogarithmicAxis;
  updateDataSubChart!: TUpdateData;
  isMouseOverValue: boolean = false;
  data!: TData;
  cdSegment!: string | undefined;
  dsAsset!: string | undefined;
  showControlBack: boolean = false;
  controlBackRightPosition: number = 0;
  footerHeight = 30;
  divOrderWidth = 135;
  showAnnotationTooltip = false;
  createLineService!: CreateLineService;
  private timeoutRefCtrlHandler!: NodeJS.Timeout | number;
  private holdCtrl = false;
  private ctrlIsPress: boolean = false;
  private setSubscribeChartOrder$ = new Subject<void>();
  private redrawVolumeAtPrice: boolean = false;
  private _focused: boolean = false;
  private indicatorClicked!: any;
  private nmCompanyValemobi!: string | undefined;
  private _showMaxAndMinLines$ = new Subject<void>();
  private previousStock!: ISearchStock;
  private minDivOrderWidth = 135;
  private maxDivOrderWidth = 350;
  private _addDraws$ = new Subject<saveConfiguration[]>();
  private annotationClicked!: ClickableAnnotation | undefined;
  private _boxLineDeleted$ = new Subject<{
    line: HorizontalLineAnnotation;
    options: any;
  }>();
  transform = {
    x: 0,
    y: 0,
  };
  withTools: boolean = false;
  widthRemove: number = 50;
  annotationTooltipTop!: number;
  annotationTooltipLeft!: number;
  annotationTooltipLabel: string[] = [];
  annotationTooltipVisible: boolean = false;
  alreadyInit: boolean = false;
  isLog: boolean = false;
  scrollBegin: boolean = false;
  continueArrowClick!:
    | {
        value: number;
        typeArrow: ARROW_TYPES;
      }
    | undefined;
  papel!: string;
  showSubchartXLines = true;
  showSubchartYLines = true;
  pressShift = false;
  pressAlt = false;
  canShowFastOrder = false;
  displayFastRuler = true;
  showFastOrderDaytradeStrategy = false;
  updateView = false;
  quoteData!: QuoteData;
  _stockChartService!: StockChartService;
  closeline!: HorizontalLineAnnotation | undefined;
  vlCloseLine!: number | undefined;
  vlOpen!: number | undefined;
  forcedQtty: number = 0;
  bookData!: any | undefined;
  mousePoint!: Point | undefined;
  pointValueY!: number | undefined;
  stockTrade!: ISearchStock;
  private isMiddleClickHold: boolean = false;
  private middleClickPosition!: Point | undefined;
  private _candleTimeLeft!: Date | undefined;
  private _stockDraws!: saveConfiguration[];
  private _secondaryChartBelow: TSecondaryChart[] = [];
  private _axis2Id = randomId('AXIS2_ID');
  private _idBookBarYAxis = randomId('BOOKBARAXIS_ID');
  private _idVolumeAtPriceBarYAxis = randomId('VOLUMEATPRICEBARAXIS_ID');
  private _candleDataSeries!: OhlcDataSeries;
  private _bookValues!: IColumnBook[];
  private isFirstDataStreaming: boolean = false;
  private _lineDataSeries!: XyDataSeries;
  private _barBookYAxis!: NumericAxis;
  private _barVolumeAtPriceYAxis!: NumericAxis;
  private _columnYAxis!: NumericAxis;
  private _candleYAxis!: NumericAxis | LogarithmicAxis;
  private _closelineTimer!: HorizontalLineAnnotation | undefined;
  private _closelineBox!: HorizontalLineAnnotation | undefined;
  private _onDestroy = new Subject<void>();
  private _divFounded = new Subject<void>();
  private _isDivFound: boolean = false;
  private _lastArrowNavigationTimestamp: number = 0;
  private _lineData: any = {};
  private _closeLineId = 'Tiger-chart-close-line';
  private _closeLineIdTimer = 'Tiger-chart-close-line_timer';
  private _closeLineIdBox = 'Tiger-chart-close-line_box';
  private _watermarkId = 'Tiger-chart-watermark';
  private _newLineId = NEW_LINE_ID;
  private _newAnnotationIndicatorId = NEW_ANNOTATION_INDICATOR;
  private _volumeIndicator!: TigerChartIndicatorVolume;
  private _bookIndicator!: FastColumnRenderableSeries;
  private _volumeAtPriceIndicator!: StackedColumnCollection | undefined;
  private standardLot!: number;
  private _zoomingVisibleRange!: NumberRange | undefined;
  private _lineValueOnDragStart = 0;
  private columnBarService!: ColumnBarService;
  private tigerChartEventsModalService!: TigerChartEventsModalService;
  private globalIndicatorHammer = new Dictionary<CustomAnnotation[]>();
  private _mousePoint: Point | undefined;
  private _alertXOffset = 190;
  private _showClosePriceTimer: boolean = true;
  private isWheelDeltaY = false;
  private quoteParamsResume!: SubscribeParam | undefined;
  private bookParamsTable!: SubscribeParam | undefined;
  private volumeParamsTable!: SubscribeParam | undefined;
  orderChartParams!: SubscribeParam | undefined;
  private maxVolumeAtPrice = 0;
  public dataTable: any[] = [];
  private account!: IAccountSelect;
  private ordersOpen!: IOrders[];
  private zoomIn: boolean = false;
  private _updateVisibleEvents$: Subscription = new Subscription();
  public dataResume!: DataResume | undefined;
  private _isTour: boolean = false;
  private _tourSub!: Subscription;
  public typeButtons!: ITypeButton | undefined;
  public isEventExpanded: boolean = false;
  private _isDraggingOrder: boolean = false;
  private volumeAtPriceDict = new Dictionary<VolumeData>();
  private isEndendVolumeAtPrice: boolean = false;
  private showWatermark: boolean = true;
  private watermarkOpacity: number = 33;
  private _repositionXAxisBook$ = new Subject<{ visibleRange: NumberRange }>();
  private _addSeparatorDate$ = new Subject<{
    addNewPoint: boolean;
    clear: boolean;
  }>();
  private execOrdersAnnotation = new Dictionary<number>();
  private execOrdersAnnotationIndex = new Dictionary<ExecOrderAnnotation[]>();
  private execOrderFlagAnnotation = new Dictionary<ExecOrderFlagAnnotation>();
  private execOrderFlagParcialAnnotation =
    new Dictionary<ExecOrderFlagAnnotation>();
  private hiddenOrderFlags = new Dictionary<ExecOrderFlagAnnotation>();
  private isInit: boolean = false;
  private _repositionExecOrderTooltip$ = new Subject<{
    mouseEvent: MouseEvent;
    textWidth: number;
    execOrderAnnotations: ExecOrderAnnotation[];
  }>();
  private previousVlClose: number = 0;
  private textColor: string = CHART_COLORS.NEUTRAL_SMOOTHEST;
  private initCursorDefault$ = new Subject<void>();
  private redrawCustody$ = new Subject<void>();
  private get itemsArray() {
    return [`${this.stock.cd_stock}:${this.stock.id_exchange}`];
  }

  override set indicatorsAdd(indicator: BaseIndicator) {
    if (indicator.type === TIGER_INDICATORS_ENUM.BAR_ELEPHANT) {
      this.setCandleElephantBarColors();
    }
    this.tooltip.disableEvents = true;
    this.removeIndicator(
      {
        type: indicator.type,
        lineNumber: indicator.lineNumber,
      },
      true
    );
    if (TIGER_SUB_CHART_INDICATORS.indexOf(indicator.type) != -1) {
      const key = `${indicator.type}_${indicator.lineNumber}`;
      //como esta utilizando 25% do tamanho total, o position do ultimo divider precisa ser 74%
      //lembrando que ele esta utilizando a propriedade TOP do CSS,
      //com isso o tamanho total é de 100%, retirando 25%, ficaria 75%-1 = 74%
      let subChartHeight =
        this.configs.height * (UNIVERSAL_SUBCHART_PERCENT_HEIGHT / 100);
      this.subCharts.set(key, {
        indicator,
        height: subChartHeight,
        dividerPosition: 74,
      });
      if (this.subCharts.size() > 2) {
        subChartHeight =
          this.configs.height * (100 / (this.subCharts.size() + 1) / 100);
        this.subCharts.set(key, { height: subChartHeight });
      }
      this.subChartHeightRemove += subChartHeight;
      this.updateSubChartsConfigs(key, true, true, subChartHeight);
      this.baseChart.sciChartSurface.xAxes.get(0).drawLabels = false;
      this.baseChart.sciChartSurface.xAxes.get(
        0
      ).labelProvider.formatCursorLabel = () => '';
    } else {
      const series = indicator.create({
        base: this.baseChart,
        yAxisId: this._axis2Id,
        xAxisId: this.xAxisId,
        tickSize: this.configs.yAxis.labelPrecision || 0,
      });
      if (TIGER_ANNOTATION_INDICATORS.indexOf(indicator.type) == -1 && series) {
        series.forEach((serie) => (this.renderableSeriesAdd = serie));
      }
    }
    this.indicators.push(indicator);
    this.getIndicators$.next(this.indicators);
    this.tooltip.disableEvents = false;
    if (TIGER_ANNOTATION_INDICATORS.indexOf(indicator.type) != -1) {
      this.globalIndicatorHammer = this.hammersService.addHammerIndicator(
        this.baseChart,
        this.xAxisId,
        indicator,
        indicator.type,
        this.candleDataSeries,
        this._lineDataSeries,
        this.globalIndicatorHammer
      );
    }
    this.drawToolsService.updateSubCharts(this.subCharts);
  }

  get sortByLine(): TIGER_INDICATOR_SOURCES {
    return this.configs.series[TIGER_TYPE_CHART_ENUM.LINE]!.sortBy!;
  }

  get volume(): number {
    if (!this.data) return 0;

    return this.data.vl_volume[this.data.vl_volume.length - 1];
  }

  get candleDataSeries() {
    return this._candleDataSeries;
  }

  get tickSizeDenominator() {
    return this.stock?.tick_size_denominator ?? 2;
  }
  get tickSizeQuantity() {
    return this.stock?.tick_size_quantity ?? 0;
  }
  get minimumPrice() {
    return this.dataResume?.vl_preco_minimo ?? 0;
  }
  get maximumPrice() {
    return this.dataResume?.vl_preco_maximo ?? 0;
  }
  get xAxisCalculator() {
    return this.xAxis.getCurrentCoordinateCalculator() as CategoryCoordinateCalculator;
  }
  constructor(
    textLengthService: RTTextLengthService,
    @Inject(LOCALE_ID) private locale: string,
    private modalService: RocketModalService,
    stockChartService: StockChartService,
    private _chartHelper: StockChartHelper,
    drawToolsService: DrawToolsService,
    toolbarDrawService: ToolbarDrawService,
    _toastService: ToastService,
    private _customPreferencesService: CustomPreferencesService,
    private axisService: AxisService,
    stockChartModalService: StockChartModalService,
    plusLineService: PlusLineService,
    middleChartService: TigerMiddleChartService,
    private _bookChannel: MiniBookChannel,
    private _quoteChannel: QuoteChannel,
    private _volumeChannel: VolumeChannel,
    private altShiftOrderService: AltShiftOrderService,
    private hotkeysService: HotkeysService,
    private colorsBaseService: ColorsBaseService,
    private ordersService: OrdersService,
    private _mbService: MultibrokerService,
    private orderChannel: OrderChannel,
    private tigerChartOrdersService: TigerChartOrdersService,
    private custodyOrdersService: CustodyStopOrderService,
    private eventsContextMenuService: EventsContextMenuService,
    private homeService: HomeService,
    private _introJsService: IntrojsService,
    private executedOrdersService: ExecutedOrdersService,
    private minMaxAnnotationService: MinMaxAnnotationService,
    private filtersService: FiltersService,
    private cdr: ChangeDetectorRef,
    private watermarkService: WatermarkService,
    themeService: ThemePreferencesService,
    private custodyChannel: CustodyChannel,
    private orderChartChannel: OrderChartChannel,
    private hammersService: HammersService,
    private contextMenuService: ContextMenuService,
    private _dayTradeService: DaytradeService,
    private superSearchService: SuperSearchService,
    private stockChartChannelService: StockChartChannelService,
    private inputCountService: InputCountService,
    private volumeAtPriceService: VolumeAtPriceService
  ) {
    super(stockChartService);
    this.initializeReadStreams();
    this.textLengthService = textLengthService;
    this._stockChartService = stockChartService;
    this.drawToolsService = drawToolsService;
    this.toolbarDrawService = toolbarDrawService;
    this.stockChartModalService = stockChartModalService;
    this.plusLineService = plusLineService;
    this.middleChartService = middleChartService;
    this._themeService = themeService;
    this.separatorDateAnnotationService = new SeparatorDateAnnotationService(
      this.baseChart,
      this._themeService
    );
    this.createLineService = new CreateLineService(
      this.baseChart,
      this.xAxisId,
      this.ref,
      this.createLine,
      this.deleteLine,
      this.getAnnotationsById
    );
    this._custodyStopOrder = new CustodyStopOrder(
      this.baseChart,
      () => this._lineData,
      () => this.closeline?.y1 || 0,
      this.yAxisLabelFormatter,
      this._createAnnotationHoverFunction,
      this._updateHoverAnnotationPosition,
      this.updateLine,
      this.createLine,
      this._scrollingCustody,
      this.rightClickEvent,
      this.lineMoved,
      () => this.custodyStopOrdersAlert,
      _toastService,
      this.locale,
      this._chartHelper,
      this._customPreferencesService,
      this.custodyOrdersService,
      this._dayTradeService,
      this.drawToolsService
    );
    this.columnBarService = new ColumnBarService(
      this.locale,
      this._themeService
    );
    this.tigerChartEventsModalService = new TigerChartEventsModalService(
      this.baseChart,
      this.modalService,
      this.eventsContextMenuService,
      this.ref,
      this._createAnnotationHoverFunction,
      this.hideAnnotationTooltip
    );
    this.bindHotkeys();
    this.inputCountService.onHotkeyPress
      .pipe(takeUntil(this._onDestroy), auditTime(100))
      .subscribe((data) => {
        if (data.alt) {
          this.shiftKeyupHandler();
          this.altHandler();
        } else {
          this.altKeyupHandler();
        }
        if (data.shift) {
          this.altKeyupHandler();
          this.shiftHandler();
        } else {
          this.shiftKeyupHandler();
        }
      });
    this._stockChartService
      .getEnableOrDisableCursorModifier()
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (value: { cursor: string; isLine: boolean; refComponent: string }) => {
          if (this.refComponent !== value.refComponent) return;
          this.defaultCursorChange.emit(value.cursor);
          this.showOnlyAxisLabelsCursorModifier(
            value.isLine ? CHART_COLORS.NEUTRAL_MEDIUM : 'transparent'
          );
          this.cdr.detectChanges();
        }
      );
    this.initCursorDefault$
      .pipe(takeUntil(this._onDestroy), debounceTime(500))
      .subscribe(() => (this.toolCursor = this.getCursor(this.defaultCursor)));
    this.superSearchService
      .onOpen()
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.unbindHotkeys();
      });
    this.superSearchService.modalClose$
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.bindHotkeys();
      });
    this.setSubscribeChartOrder$
      .pipe(
        filter(() => !!this.stock && !!this.account),
        auditTime(100)
      )
      .subscribe(() => {
        this.unsubscribeChartOrder();
        if (this.showExecOrders) {
          this._setChartOrderParams();
          this._subscribeChartOrder();
        }
        this.resetOrderChartStream();
        this.cdr.detectChanges();
      });
    this._stockChartService
      .getCandleClick()
      .pipe(
        takeUntil(this._onDestroy),
        filter((event) => {
          return event.chartId === this.baseChart.sciChartSurface.id;
        }),
        map((event) => event.dataSeriesIndex)
      )
      .subscribe((dataSeriesIndex: number) => {
        if (this.showExecOrders) {
          this.recoverHiddenFlags(dataSeriesIndex);
        }
      });
  }

  private getCursor(cursor: string): string {
    if (
      !cursor ||
      cursor === `${TOOLS_ENUM.CURSORS}_CRUZ` ||
      cursor === `${TOOLS_ENUM.CURSORS}_PONTO`
    ) {
      this.showOnlyAxisLabelsCursorModifier(CHART_COLORS.NEUTRAL_MEDIUM);
    } else {
      this.showOnlyAxisLabelsCursorModifier('transparent');
    }
    let position = '12 12';
    cursor === `${TOOLS_ENUM.CURSORS}_BORRACHA` && (position = '4 4');
    const group = TOOLS.get(TOOLS_ENUM.CURSORS)!!;
    const defaultCursor = group.find((item: any) => item.codTool === cursor);
    const mouseCursor =
      defaultCursor?.mouseCursor ??
      TOOLS.get(TOOLS_ENUM.CURSORS)!![0].mouseCursor!!;
    return mouseCursor
      .replace(new RegExp(/\s/, 'g'), '')
      .replace('),', `) ${position},`);
  }

  ngAfterViewInit(): void {
    this.startChecking();
    this._listemContextMenuActions();
  }

  custodyCurrentSubscription!: RocketStreamRead;
  quoteCurrentSubscription!: RocketStreamRead;
  candleCurrentSubscription!: RocketStreamRead;
  volumeChannelSubscription!: RocketStreamRead | undefined;
  bookCurrentSubscription!: RocketStreamRead | undefined;

  private async initializeReadStreams() {
    this.quoteCurrentSubscription = await this._quoteChannel.readEvents();
    this.readStream(
      this.quoteCurrentSubscription.stream,
      this.channelQuoteHandler
    );
    this.quoteCurrentSubscription.snapshot(this.quoteParamsResume?.itemsArray);

    this.custodyCurrentSubscription = await this.custodyChannel.readEvents();
    this.readStream(
      this.custodyCurrentSubscription.stream,
      this.channelCustodyHandler
    );
    this.custodyCurrentSubscription.snapshot(
      this.quoteParamsResume?.itemsArray
    );
  }

  private _listemContextMenuActions() {
    this.contextMenuService
      .listenActionEvent<{
        action: CONTEXT_MENU_ITEM_ENUM;
        isInverse: boolean;
        typeOrder?: CONTEXT_MENU_ORDER_TYPE;
      }>(this.refComponent)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((payload) => {
        this.clickOnPlus = false;
        switch (payload.action.action) {
          case CONTEXT_MENU_ITEM_ENUM.TOGGLE_VOLUME:
            this.changeVolume();
            break;
          case CONTEXT_MENU_ITEM_ENUM.ADD_INDICATORS:
            this.openIndicatorsModal.emit();
            break;
          case CONTEXT_MENU_ITEM_ENUM.TOGGLE_CUSTODY_STOP_ORDER:
            this._custodyStopOrder.toggleVisibility();
            this._saveContextMenuConfig();
            break;
          case CONTEXT_MENU_ITEM_ENUM.DRAW_HORIZONTAL_LINE:
            if (this.mousePoint && this.pointValueY) {
              this.drawHorizontalLine(this.mousePoint, this.pointValueY);
            }
            break;
          case CONTEXT_MENU_ITEM_ENUM.HELP:
            this._startTour();
            break;
          default: {
            if (this.pointValueY)
              this.emitClickOrder(
                payload.action.action as CONTEXT_MENU_ITEM_ENUM,
                this.pointValueY,
                payload.action?.typeOrder,
                payload.action?.isInverse
              );
            break;
          }
        }
      });
  }

  startBookStreaming = async () => {
    if (!this.bookParamsTable) return;
    this.bookCurrentSubscription = await this._bookChannel.onEvents();
    this.bookCurrentSubscription.snapshot(this.bookParamsTable?.itemsArray);
    this.readStream(
      this.bookCurrentSubscription.stream,
      this.bookChannelHandler
    );
  };

  bookChannelHandler = (payload: any) => {
    const stock = `${this.papel}:${this.idExchange}`;
    const data = payload.get(stock);
    if (!data || data.isEndOfSnap) return;
    const resultData = buildBookInfo(
      createArrayIndex(40, true),
      data,
      {
        hideHighlightQuantity: false,
        hideAggressor: false,
        hideHighlightOffer: false,
        minimumPrice: this.minimumPrice,
        maximumPrice: this.maximumPrice,
        priceIncrement: getIncrementValue(this.stock),
        isChart: true,
      },
      this.tickSizeDenominator,
      this.tickSizeQuantity
    );
    this.resultProcess(resultData);
  };

  private startChecking(): void {
    this._divFounded.next();
    interval(250)
      .pipe(
        switchMap(() => of(document.getElementById(this.ref))),
        filter((element) => !!element),
        takeUntil(this._divFounded)
      )
      .subscribe(() => {
        if (!this._isDivFound) {
          this._isDivFound = true;
          this._modifierGroup = this.ref;
          this.terminateDivFounder();
          this.init(this.ref, this.initConfig);
        }
      });
  }

  private terminateDivFounder() {
    this._divFounded.next();
    this._divFounded.complete();
  }

  ngOnDestroy(): void {
    this.unbindHotkeys();
    this._onDestroy.next();
    this._onDestroy.complete();
    this.terminateDivFounder();
    this.columnBarService.deleteColumnBar(this.refComponent);
    this.removeSubscriptions();
    this.unsubscriberCheetah();
    this._unsubscribeVolumeChannel();
    this.unsubscriberUpdateEvents();
    this._removeStreamingBook();
    this.tigerChartEventsModalService.terminateWorker();
    const doc = document.getElementById(this.refComponent);
    if (doc && doc.removeAllListeners) {
      doc.removeAllListeners();
    }
    this._removeStreamingVolume();
    this._removeOrderChartStream();
  }

  private unsubscriberUpdateEvents() {
    this._updateVisibleEvents$ && this._updateVisibleEvents$.unsubscribe();
  }

  private updateInAuction(data: QuoteData) {
    if (data?.preco_ultimo) {
      this.stockTrade = {
        ...this.stock,
        stockVariation: {
          ...this.stock.stockVariation,
          vl_close: data.preco_ultimo,
        },
      };
    }
    const configuration = this.stockChartModalService.getGlobalChartConfigs();
    if (!configuration.showAuctionChartEvent || this.forceHideStockMessage) {
      return;
    }
    this.messageChartHeightRemove = 0;
    this.showStockMessage.delete(BODY_TEMPLATE.LEILAO);
    if (data.situacao === 'LEILAO') {
      this.showStockMessage.set(BODY_TEMPLATE.LEILAO, {
        typeTemplate: BODY_TEMPLATE.LEILAO,
        backgroundColor: 'bg-feedback-warning',
        showCloseButton: true,
      });
      this.messageChartHeightRemove = 20;
    }
    this.quoteData = { ...data };
    this.cdr.detectChanges();
  }

  updateData(data: TUpdateData) {
    if (!data || !this.data || !this._candleDataSeries) {
      return;
    }
    this.updateDataSubChart = data;
    const alerts = Object.values(this.lineAlertData);
    if (!this.isDragging) {
      if (alerts.length > 0) {
        alerts.forEach((alert: any) => {
          const box = this.baseChart.sciChartSurface.annotations.getById(
            getLineBoxAnnotationId(alert.firstBoxId)
          ) as ClickableAnnotation;

          if (!box) return;

          box.customHover = this._createAnnotationHoverFunction({
            type: TIGER_LINE_TYPE.ALERT,
            value: data.vl_close,
            tooltipLabel: this._chartHelper.getTooltipForType(
              TIGER_LINE_TYPE.ALERT,
              {
                value: box.y1,
                currentPrice: data.vl_close,
                cdSegment: this.cdSegment,
                labelPrecision: this.configs.yAxis.labelPrecision,
                dsAsset: this.dsAsset,
                cdStock: this.papel,
                indicators: this.tooltip.indicators,
              }
            ),
            boxId: '',
            color: '',
          });
          const x = this._mousePoint?.x || 0;
          const y = this._mousePoint?.y || 0;

          if (box.checkIsClickedOnAnnotation(x, y)) {
            box.customHover(box, x, y);
          }
        });
      }
    }
    if (data.updateBar) {
      const indexCandle = this._candleDataSeries.count() - 1;
      const indexLine = this._lineDataSeries.count() - 1;
      this.updatePrivateData(data, indexCandle);
      this._candleDataSeries.update(
        indexCandle,
        data.vl_open,
        data.vl_high,
        data.vl_low,
        data.vl_close
      );
      this.updateOrderFlagPosition(indexCandle, data.vl_high);
      this._lineDataSeries.update(indexLine, data[this.sortByLine]);
      this._volumeIndicator &&
        this._volumeIndicator.update(indexCandle, data, this.data);
      this.updateIndicators(indexCandle, data, this.data);
      this.hammersService.addHammers(this.makeHammersParams());
    } else {
      this.updatePrivateData(data);
      this._candleDataSeries.append(
        data.id_point,
        data.vl_open,
        data.vl_high,
        data.vl_low,
        data.vl_close
      );
      this._lineDataSeries.append(data.id_point, data[this.sortByLine]);
      this._volumeIndicator &&
        this._volumeIndicator.append(data.id_point, data, this.data);
      this.appendToIndicators(data.id_point, data, this.data);
      this.hammersService.addHammers(this.makeHammersParams());
      this.animation();
      this._custodyStopOrder.updateSeeCustody(
        this._candleYAxis.visibleRange,
        this.baseChart
      );
      this._addSeparatorDate$.next({ addNewPoint: true, clear: false });
      const custody = this.baseChart.sciChartSurface.annotations.getById(
        this._custodyStopOrder.custodyLineId
      ) as HorizontalLine;
      if (custody) {
        custody.updateCompleteLinePosition();
      }
    }
    if (!this.isMouseOverValue) {
      const vl_volume = this._volumeIndicator.getLastVolume();
      this.tooltip.updateDataSeries(this._candleDataSeries, vl_volume);
    }
    this._candleTimeLeft = this.createLineService.setCloseTimeLeft(
      data.remaining_time_millis,
      this.configs
    );
    this.setCloseLine(data.vl_open, data.vl_close);
    if (!this.isFreeScale) {
      const visibleRange = this._candleYAxis.visibleRange;
      const minData = Math.min(
        data.vl_close,
        data.vl_high,
        data.vl_low,
        data.vl_open
      );
      let min = Math.min(minData, visibleRange.min);
      const maxData = Math.max(
        data.vl_close,
        data.vl_high,
        data.vl_low,
        data.vl_open
      );
      let max = Math.max(maxData, visibleRange.max);
      if (min < visibleRange.min || max > visibleRange.max) {
        const diff = Math.abs(maxData - minData) / 2;
        min = min < visibleRange.min ? min - diff : min;
        max = max > visibleRange.max ? max + diff : max;
        min = min < 0 ? 0 : min;
        this._candleYAxis.autoRange = EAutoRange.Never;
        this._candleYAxis.animateVisibleRange(new NumberRange(min, max), 0);
      }
    }
    if (!this.updateView) {
      this.updateView = true;
      this.updateWindowedView();
    }

    if (this.showMinMaxVisible) {
      this._showMaxAndMinLines$.next();
    }
    if (this.execOrderNotPlotted.values().length) {
      this.plotExecOrders(this.execOrderNotPlotted.values());
    }
  }

  createBelowChart(indicator: BaseIndicator) {
    this.indicatorsRemove = indicator.type;
    const ref = `${this.ref}_below_${this._secondaryChartBelow.length}`;
    indicator.yAxisId = `${ref}_candle`;
    const secondaryChart: TSecondaryChart = {
      ref,
      indicators: [indicator],
      axis: this.setSecondaryYaxis(ref),
    };
    this._secondaryChartBelow.push(secondaryChart);
    this.indicatorsAdd = indicator;
  }

  createDoubleStopLine(
    userCustody: any,
    order: any,
    orderType: any,
    quantity: string
  ) {
    const hasLimited = Object.values(this._lineData).find(
      (lineData: any) =>
        isLimited(lineData.cd_order_type) &&
        lineData.tp_status == TPOrderStatus.ABERTA &&
        lineData.id_order == order.id_order
    );
    if (hasLimited) return;
    const multiplier = order.side === 'B' ? -1 : 1;
    const qttyStop = order.qtty * multiplier;
    const isBuying = qttyStop < 0;

    const vlContractMultiplier =
      this.stock?.vl_contract_multiplier != -1
        ? this.stock?.vl_contract_multiplier
        : 1;
    const lossInfo = this.custodyOrdersService.getInfoCustodyHint(
      TIGER_DOUBLE_STOP_ORDER_IDS.LOSS,
      this.stock,
      parseFloat(order.qtty),
      vlContractMultiplier,
      +order.personalized_loss_trigger,
      +order.personalized_loss_price,
      userCustody?.vlPriceAvg,
      this.yAxisLabelFormatter
    );
    const gainInfo = this.custodyOrdersService.getInfoCustodyHint(
      TIGER_DOUBLE_STOP_ORDER_IDS.GAIN,
      this.stock,
      parseFloat(order.qtty),
      vlContractMultiplier,
      +order.personalized_gain_trigger,
      +order.personalized_gain_price,
      userCustody?.vlPriceAvg,
      this.yAxisLabelFormatter
    );
    this.createLine({
      value:
        order.personalized_gain_trigger > 0
          ? parseFloat(order.personalized_gain_trigger.toString())
          : 0,
      color: orderType.color,
      boxLabel: `SG | ${quantity}`,
      boxId: `${order.id_order.toString()}_gain`,
      data: order,
      boxTextColor: orderType.boxTextColor,
      type: TIGER_LINE_TYPE.ORDER,
      tooltipLabel: this._chartHelper.getTooltipForType(TIGER_LINE_TYPE.ORDER, {
        isDoubleStartStop: true,
        isBuying,
        isGain: true,
        qtty: gainInfo.qtty,
        price: gainInfo.price,
        stop: gainInfo.stop,
        estimate: gainInfo.estimate,
        distance: gainInfo.distance,
      }),
    });
    this.createLine({
      value:
        order.personalized_loss_trigger > 0
          ? parseFloat(order.personalized_loss_trigger.toString())
          : 0,
      color: orderType.color,
      boxLabel: `SL | ${quantity}`,
      boxId: `${order.id_order.toString()}_loss`,
      data: order,
      boxTextColor: orderType.boxTextColor,
      type: TIGER_LINE_TYPE.ORDER,
      tooltipLabel: this._chartHelper.getTooltipForType(TIGER_LINE_TYPE.ORDER, {
        isDoubleStartStop: true,
        isBuying,
        isGain: false,
        qtty: lossInfo.qtty,
        price: lossInfo.price,
        stop: lossInfo.stop,
        estimate: lossInfo.estimate,
        distance: lossInfo.distance,
      }),
    });
  }

  createLine = (
    options: TCandleLineOptions
  ): HorizontalLineAnnotation | undefined => {
    if (!this.baseChart) return;
    options.stock = deepClone(this.stock);
    const annotation = this.getAnnotationsById<HorizontalLineAnnotation>(
      options.boxId
    );
    const custodyAnnotation =
      this.baseChart.sciChartSurface.annotations.getById(
        getLineBoxAnnotationId(this._custodyStopOrder.custodyLineId)
      ) as ClickableAnnotation;
    const doubleStartStop =
      options.data?.cd_order_type &&
      isDoubleStartStop(options.data?.cd_order_type);

    if (annotation && options.boxId === this._closeLineId) {
      this.updateCloseLine(annotation, options);
      this.closeline!.labelValue = this.yAxisLabelFormatter(options.value);
      this.updateTimerPosition(options.value, options.color);
      return annotation as HorizontalLineAnnotation;
    }
    if (annotation && options.boxId === this._closeLineId + '_timer') {
      return annotation as HorizontalLineAnnotation;
    }
    if (annotation && options.boxId === this._closeLineId + '_box') {
      return annotation as HorizontalLineAnnotation;
    }
    const isCustody = options.type == TIGER_LINE_TYPE.CUSTODY;
    const isAlert = options.type == TIGER_LINE_TYPE.ALERT;
    if (isAlert) {
      options.xOffset = this._alertXOffset;
      options.xCoordinateMode = ECoordinateMode.Pixel;
    }
    if (annotation) {
      if (doubleStartStop) {
        options.xOffset = custodyAnnotation?.getAnnotationBorders().x2 || 90;
        options.xCoordinateMode = ECoordinateMode.Pixel;
      }
      isCustody &&
        this._custodyStopOrder.updateSeeCustody(
          this._candleYAxis.visibleRange,
          this.baseChart,
          false
        );
      this.updateLine(annotation, options);
      if (isCustody) {
        this._custodyStopOrder.custodyQuantity = options.data.custodyQtty;
        this._custodyStopOrder.boxLabel = options.boxLabel;
        this._custodyStopOrder.custodyInfo = options.data;
        (annotation as HorizontalLine).rePlotCompleteLine();
        if (!this._custodyStopOrder.stopGainCheep) {
          this._custodyStopOrder.setCustodyOrderButtons(this.xAxisId, options);
        } else {
          this.updateCustodyCheeps();
        }
      }
      if (this._closelineBox) {
        this.annotationsRemove = this._closelineBox;
        this.annotationsAdd = this._closelineBox;
      }
      if (this.closeline) {
        this.annotationsRemove = this.closeline;
        this.annotationsAdd = this.closeline;
      }
      if (this._closelineTimer) {
        this.updateCloseLineTimer();
      }
      if (this._isDraggingOrder) {
        this._isDraggingOrder = false;
      }
      return annotation as HorizontalLineAnnotation;
    }
    if (options.boxId.includes(this._newLineId)) {
      this.lineAlertData[options.boxId] = options;
      this.lineAlertData[options.boxId].firstBoxId = options.boxId;
    }

    if (doubleStartStop) {
      this._custodyStopOrder.updateCustodyCheeps(true);
      this._custodyStopOrder.hideCustodyCheeps();
      options.xOffset = custodyAnnotation?.getAnnotationBorders().x2 || 90;
      options.xCoordinateMode = ECoordinateMode.Pixel;

      const qtty = formatterNumber(+options.data?.qtty, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
      const isBuying = this._custodyStopOrder.custodyQuantity < 0;
      const isGain = options.boxId.endsWith('_gain');
      const price =
        (!isBuying && !isGain) || (isBuying && isGain)
          ? options.data.personalized_loss_price
          : options.data.personalized_gain_price;
      if (custodyAnnotation) {
        const { estimate, distance } =
          this.custodyOrdersService.getInfoCustodyHint(
            options.boxId,
            this.stock,
            +options.data?.qtty,
            this._custodyStopOrder.contractMultiplier,
            options.value,
            price,
            custodyAnnotation.y1,
            this.yAxisLabelFormatter
          );
        options.tooltipLabel = this._chartHelper.getTooltipForType(
          TIGER_LINE_TYPE.ORDER,
          {
            isDoubleStartStop: true,
            isGain,
            qtty,
            price: this.yAxisLabelFormatter(price),
            stop: this.yAxisLabelFormatter(options.value),
            estimate,
            distance,
            cdSegment: this.cdSegment,
            labelPrecision: this.configs.yAxis.labelPrecision,
            dsAsset: this.dsAsset,
            cdStock: this.papel,
            indicators: this.tooltip.indicators,
          }
        );
      }
    }

    if (isLimited(options.data?.cd_order_type)) {
      if (this._lineData[`${options.data?.id_order}_gain`] != undefined) {
        this.deleteLine(`${options.data?.id_order}_gain`);
        this.deleteLine(`${options.data?.id_order}_loss`);
      }
    }

    if (custodyAnnotation) {
      options.xOffset = this.getOrderOffset(options, options.xOffset);
    }
    const line = new HorizontalLine(options.type, {
      labelPlacement: options.labelPlacement
        ? options.labelPlacement
        : ELabelPlacement.Axis,
      xCoordinateMode: options.xCoordinateMode
        ? options.xCoordinateMode
        : ECoordinateMode.DataValue,
      showLabel: options.showLabel ?? true,
      labelValue: options.label ?? this.yAxisLabelFormatter(options.value),
      stroke:
        options.boxId.includes('_timer') || options.boxId.includes('_box')
          ? 'transparent'
          : options.color,
      axisLabelFill: options.color,
      axisLabelStroke: options.boxTextColor,
      strokeThickness: options.strokeThickness,
      y1: options.value,
      x1: options.xOffset || 0,
      strokeDashArray: [2, 2],
      isEditable: false,
      id: options.boxId,
      annotationsGripsRadius: 0,
      annotationsGripsFill: 'transparent',
      annotationsGripsStroke: 'transparent',
      selectionBoxStroke: 'transparent',
      selectionBoxDelta: 0,
      selectionBoxThickness: 0,
      xAxisId: this.xAxisId,
    });

    let auxLine: HorizontalLineAnnotation | undefined = undefined;
    if (options.data) {
      auxLine = this.plotAuxHorizontalLine(options);
    }
    this.annotationsAdd = line;
    this.addBoxToLineAnnotation(line, options, auxLine);

    if (options.data) {
      this._lineData[options.boxId] = options.data;
    }
    return line;
  };

  getOrderOffset = (options: TCandleLineOptions, offset: number = 0) => {
    const custodyAnnotation =
      this.baseChart.sciChartSurface.annotations.getById(
        getLineBoxAnnotationId(this._custodyStopOrder.custodyLineId)
      ) as ClickableAnnotation;

    if (custodyAnnotation) {
      const custodyAnnBorder = custodyAnnotation.getAnnotationBorders();
      const box = this.getAnnotationsById<CustomAnnotation>(
        getLineBoxAnnotationId(options.boxId)
      );

      if (box) {
        const boxBorders = box.getAnnotationBorders();
        if (
          boxBorders.y1 <= custodyAnnBorder.y2 &&
          boxBorders.y2 >= custodyAnnBorder.y1
        ) {
          offset = custodyAnnotation?.getAnnotationBorders().x2 + 10;
        }
      } else {
        const y1 = this._candleYAxis
          .getCurrentCoordinateCalculator()
          .getDataValue(custodyAnnBorder.y1);
        const y2 = this._candleYAxis
          .getCurrentCoordinateCalculator()
          .getDataValue(custodyAnnBorder.y2);
        if (options.value <= y1 && options.value >= y2) {
          offset = custodyAnnotation?.getAnnotationBorders().x2 + 10;
        }
      }
    }
    return offset;
  };

  updateCustodyOrderPosition() {
    this.baseChart.sciChartSurface.annotations
      .asArray()
      .filter(
        (annotation) =>
          annotation instanceof HorizontalLineAnnotation &&
          this._lineData[annotation.id] &&
          annotation.id != this._closeLineId &&
          annotation.id != this._custodyStopOrder.custodyLineId &&
          !isDoubleStartStop(this._lineData[annotation.id])
      )
      .map((line) => {
        const options = this._stockChartService.getOrderOptions(
          this._lineData[line.id],
          this.locale,
          this.tickSize,
          this.stock,
          this._chartHelper
        );
        if (options) {
          this.updateLine(line as HorizontalLineAnnotation, {
            ...options,
            xOffset: this.getOrderOffset(options),
          });
        }
      });
  }

  clearLines(removeDraws: boolean = true) {
    const annotations = this.baseChart?.sciChartSurface.annotations
      .asArray()
      .filter((annotation) => annotation.id !== this._closeLineId);
    if (annotations) {
      annotations
        .filter(
          (annotation) =>
            (annotation instanceof HorizontalLineAnnotation ||
              annotation instanceof HorizontalLine) &&
            annotation.id != TIGER_DOUBLE_STOP_ORDER_IDS.GAIN &&
            annotation.id != TIGER_DOUBLE_STOP_ORDER_IDS.LOSS &&
            annotation.id != this._custodyStopOrder.custodyLineId &&
            annotation.id != PREVIOUS_LINE_ID &&
            annotation.id != BID_LINE_ID &&
            annotation.id != ASK_LINE_ID &&
            annotation.id != COVERAGE_PRICE_ID
        )
        .forEach((annotation) => {
          this.deleteLine(annotation.id);
        });
    }
    if (removeDraws) {
      this.removeDraws();
    }
    this._lineData = {};
    this._closelineTimer = undefined;
    this._closelineBox = undefined;
    if (
      !this._custodyStopOrder ||
      !this._custodyStopOrder.stock ||
      this._custodyStopOrder.stock.cd_stock !== this.stock.cd_stock
    ) {
      this.clearCustodyCheeps();
    }
  }

  clearCustodyCheeps() {
    this._custodyStopOrder && this._custodyStopOrder.clearCustodyCheeps();
  }

  updateCustodyCheeps(removeBox?: boolean) {
    this._custodyStopOrder &&
      this._custodyStopOrder.updateCustodyCheeps(removeBox);
  }

  addIndicator(indicator: BaseIndicator) {
    this.indicatorsAdd = indicator;
  }

  addDraw(draw: saveConfiguration) {
    this.drawToolsService.addDraw(
      this.baseChart,
      draw,
      this.ref,
      this.configs,
      this.chartHeight,
      this.stock,
      false
    );
  }

  removeIndicator(event: any, removeLegend: boolean) {
    if (removeLegend) {
      this.indicatorsRemove = event;
    }
    const key = `${event.type}_${event.lineNumber}`;
    const annotations = this.baseChart.sciChartSurface.annotations
      .asArray()
      .filter(
        (annotation) =>
          annotation.id === `${this._newAnnotationIndicatorId}-${key}`
      );
    annotations.forEach((annotation) => {
      this.annotationsRemove = annotation;
    });
    if (this.subCharts.has(key)) {
      this.subChartHeightRemove -= this.subCharts.get(key)!!.height;
      this.updateSubChartsConfigs(key);
      this.subCharts.delete(key);
    }
    if (this.subCharts.size() == 0) {
      this.baseChart.sciChartSurface.xAxes.get(0).drawLabels = true;
      this.baseChart.sciChartSurface.xAxes.get(
        0
      ).labelProvider.formatCursorLabel = (value) =>
        this.axisService.xAxisLabelFormatter(
          this.configs,
          this.locale,
          value,
          true,
          true
        );
    }
  }

  toggleVolume() {
    this._volumeIndicator && this._volumeIndicator.changeVisibility();
  }

  clearEvents() {
    this.tigerChartEventsModalService.clearDict();
  }

  updateIdPoint() {
    this.tigerChartEventsModalService.updateIdPoint(
      this.data,
      this.configs.series.interval!
    );
  }

  updateCorporateEvents(events: TCorporateEvents[]) {
    this.tigerChartEventsModalService.mergeEvents('corporateEvents', events);
  }

  updateNewsChartEvents(events: TNewsChartEvents[]) {
    this.tigerChartEventsModalService.mergeEvents('newsChartEvents', events);
  }

  updateRelevantEvents(events: TRelevantEvents[]) {
    this.tigerChartEventsModalService.mergeEvents('relevantEvents', events);
  }

  updateTradeIdeaEvents(events: TTradeIdeaEvents[]) {
    this.tigerChartEventsModalService.mergeEvents('tradeIdeaEvents', events);
  }

  prependToData(data: TData) {
    if (!data.id_point || data.id_point.length == 0) return;
    Object.keys(data).forEach((key) => {
      const typedKey = key as keyof TData;
      if (!this.data[typedKey]) return;
      this.data[typedKey].concat(data[typedKey], this.data[typedKey]);
    });

    this._candleDataSeries.insertRange(
      0,
      data.id_point,
      data.vl_open,
      data.vl_high,
      data.vl_low,
      data.vl_close
    );
    this._lineDataSeries.insertRange(0, data.id_point, data[this.sortByLine]);
    data.qtty_shares_traded = data.vl_volume;
    this.data.id_point.unshift(...data.id_point);
    this.data.vl_close.unshift(...data.vl_close);
    this.data.vl_high.unshift(...data.vl_high);
    this.data.vl_low.unshift(...data.vl_low);
    this.data.vl_open.unshift(...data.vl_open);
    this.data.vl_volume.unshift(...data.vl_volume);
    this.data.qtty_shares_traded.unshift(...data.qtty_shares_traded);
    this.data.candle_count.unshift(...data.candle_count);
    this.updateIdPoint();
    this.configs.xAxis.visibleRangeLimit!.max =
      this.configs.xAxis.visibleRangeLimit!.max + data.id_point.length;
    this._volumeIndicator && this._volumeIndicator.insertRange(data, this.data);
    this.insertToIndicators(data.id_point, this.data);
    this.hammersService.addHammers(this.makeHammersParams());
    if (this.scrollBegin) {
      this.drawToolsService.updateDrawPosition(data.id_point.length);
      this.executedOrdersService.updatePosition(
        this.baseChart,
        data.id_point.length
      );
      this.scrollBegin = false;
      setTimeout(() => {
        if (this.continueArrowClick) {
          const info = deepClone(this.continueArrowClick);
          this.continueArrowClick = undefined;
          this.makeZoom(info.typeArrow, info.value);
        }
      }, 15);
    }
    const shiftedRange = new NumberRange(
      Math.floor(this.xAxis.visibleRange.min + data.id_point.length),
      Math.floor(this.xAxis.visibleRange.max + data.id_point.length)
    );
    if (!this._firstVisibleRange) {
      this._firstVisibleRange = shiftedRange;
    } else {
      this._firstVisibleRange = new NumberRange(
        Math.floor(this._firstVisibleRange.min + data.id_point.length),
        Math.floor(this._firstVisibleRange.max + data.id_point.length)
      );
    }
    if (this.xAxis.visibleRange.max <= this.data.id_point.length) {
      if (this.isChanging) {
        this.isChanging++;
        this._stockChartService.clickRef = '';
      }
      this.xAxis.animateVisibleRange(shiftedRange, 0, easing.inOutQuad, () => {
        this.isChanging = 0;
      });
    }
    const vl_volume = this._volumeIndicator.getLastVolume();
    this.tooltip.updateDataSeries(this._candleDataSeries, vl_volume);
    this.repositionDraw();
    this._addSeparatorDate$.next({ addNewPoint: false, clear: true });
  }

  onIndicatorSettingsClose() {
    this.selectedIndicator = undefined;
  }

  volumeOrBook(isVisible: boolean) {
    if (!this.baseChart) return;
    const rend = this.baseChart.sciChartSurface.renderableSeries.getById(
      CANDLE_IDS.VOLUME_X_PRICE_SERIES
    ) as StackedColumnCollectionTiger;
    rend && (rend.isVisible = isVisible);
    const book = this.baseChart.sciChartSurface.renderableSeries.getById(
      CANDLE_IDS.BOOK_SERIES
    );
    book && (book.isVisible = isVisible);
  }

  changeStockAndSettings(settings: any, isChangeInterval: boolean = false) {
    this.configs.enableCursorModifier = true;
    this.enableCursorModifier(
      1,
      EXyDirection.XyDirection,
      this.configs.enableCursorModifier,
      this.configs.enableCursorModifier,
      CHART_COLORS.NEUTRAL_MEDIUM
    );
    this.removeLoader();
    this.updateView = false;
    this.annotationClicked = undefined;
    this._stockChartService.clickRef = '';
    this.showStockMessage.clear();
    this.isChanging++;
    this.applyConfigs = settings.settings;
    this.stock = settings.stock;
    this.hiddenOrderFlags.clear();
    this.updateCursorModifierStock();
    this.clickOnArrow = false;
    this.hideAnnotationTooltip(true);
    this.lineAlertData = {};
    if (this.isInit) {
      this.clearChart(isChangeInterval);
      this._addSeparatorDate$.next({
        addNewPoint: false,
        clear: true,
      });
    }
    this.indicators = [];
    const redrawColumn =
      !this.previousStock || this.previousStock.cd_stock != this.stock.cd_stock;
    if (redrawColumn) {
      this.deleteLine(COVERAGE_PRICE_ID);
      this.maxVolumeAtPrice = 0;
      this.unsubscriberCheetah();
    }
    this.previousStock = deepClone(this.stock);
    this._firstVisibleRange = undefined;
    this._previousVRange = undefined;
    this.data = settings.data;
    this.data &&
      (this.data.qtty_shares_traded = deepClone(this.data?.vl_volume));
    const now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    const today = now.getTime();
    if (this.stock.dt_maturity) {
      const standardizeDtMaturity = standardizeDate(
        this.stock.dt_maturity.toString()
      );
      const dtMaturity = stringToDate(standardizeDtMaturity)!!.getTime();
      if (dtMaturity < today) {
        this.showStockMessage.set(BODY_TEMPLATE.MATURITY, {
          typeTemplate: BODY_TEMPLATE.MATURITY,
          backgroundColor: 'bg-feedback-negative',
          showCloseButton: false,
        });
      } else if (dtMaturity === today) {
        const day = now.getDate().toString().padStart(2, '0');
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const year = now.getFullYear();
        this.showStockMessage.set(BODY_TEMPLATE.MATURITY, {
          typeTemplate: BODY_TEMPLATE.MATURITY,
          backgroundColor: 'bg-feedback-negative',
          showCloseButton: true,
          text: `Ativo próximo do vencimento (${day}/${month}/${year}).`,
        });
      }
    }
    this._custodyStopOrder.updateCustodyCheeps();
    this._custodyStopOrder.stock = this.stock;
    this.drawToolsService.setStock(this.stock);
    this.papel = settings.stock.cd_stock;
    this.cdSegment = settings.stock.cd_segment;
    this.dsAsset = settings.stock.ds_asset;
    this.standardLot = settings.stock.standard_lot;
    this.tickSize = settings.stock.tick_size_denominator;
    this.idExchange = settings.stock.id_exchange;
    this.subChartHeightRemove = 0;
    this._onConfigChange();
    this.setArrow();
    if (!this.data) {
      this.papel = '';
      this.setWatermark();
      return;
    }
    this.resetEditDraw();
    this.setDataSeries();
    this.setVolume();

    if (redrawColumn) {
      if (this.stock) {
        this._removeStreamingQuote();
      }
      this.quoteParamsResume = {
        itemsArray: this.itemsArray,
      };
      this.quoteCurrentSubscription &&
        this.quoteCurrentSubscription.snapshot(
          this.quoteParamsResume?.itemsArray
        );
      if (this.showBookButton) {
        this.setChartColumns();
      }
    }
    this.setWatermark(undefined, this.nmCompanyValemobi);
    this.addAxisDragModifier();
    if (this.configs.enableZoom) {
      this.enableZoom(
        [this.xAxisId, this._idBookBarYAxis, this._idVolumeAtPriceBarYAxis],
        [this._axis2Id, 'DefaultAxisId', this.xAxisBookId]
      );
    }
    if (this.configs.series !== undefined) this.changeTypeSeries();
    const shiftedRange = new NumberRange(
      this.data.id_point.length - 30,
      this.data.id_point.length + 10
    );
    this.xAxis.animateVisibleRange(shiftedRange, 0, easing.inOutQuad, () => {
      this._firstVisibleRange = shiftedRange;
      this.setXAxisVisibleRangeChange(this.configs.series.interval!);
      this.updateWindowedView();
      this.isInit = true;
      this.volumeOrBook(true);
      this.unsubscribeChartOrder();
      this.setSubscribeChartOrder();
      this.chartUpdate.emit();
    });
    this.updateZoomChartModifiers();
    this.changeZoomPanDirection();
    this.searchPositionAndOrder();
    const configuration = this.stockChartModalService.getGlobalChartConfigs();
    this.updateAxisGridLinesVisibility(configuration);
    this.changeVisibilityWatermark(
      configuration.showWatermark,
      configuration.watermarkOpacity
    );
    this.plotPreviousVlClose();
    this.updateYAxisVisibility(false);
    this.updateTooltipLastVolume();
    this.cdr.detectChanges();
  }

  private changeVisibilityWatermark(
    showWatermark: boolean,
    watermarkOpacity: number
  ) {
    this.showWatermark = showWatermark;
    this.watermarkOpacity = watermarkOpacity;
    const watermark = this.baseChart.sciChartSurface.annotations.getById(
      this.watermarkId
    );
    const watermarkBusiness =
      this.baseChart.sciChartSurface.annotations.getById(
        this.watermarkBusinessId
      );
    if (watermark) {
      watermark.isHidden = !this.showWatermark;
      watermark.opacity = this.watermarkOpacity / 100;
    }
    if (watermarkBusiness) {
      watermarkBusiness.isHidden = !this.showWatermark;
      watermarkBusiness.opacity = this.watermarkOpacity / 100;
    }
  }

  private subscribeVolumeChannel = async () => {
    if (!this.stock || this.volumeParamsTable) return;
    this._volumeAtPriceIndicator = undefined;
    this.volumeParamsTable = {
      itemsArray: [`ANALYSIS=${this.stock.cd_stock}:${this.stock.id_exchange}`],
    };
    if (!this.volumeChannelSubscription) await this.volumeAtPriceReadStream();
    this.volumeChannelSubscription &&
      this.volumeChannelSubscription.snapshot(
        deepClone(this.volumeParamsTable?.itemsArray)
      );
  };

  private volumeAtPriceReadStream = async () => {
    this.volumeChannelSubscription = await this._volumeChannel.onEvents();
    this.readStream(
      this.volumeChannelSubscription.stream,
      this.channelVolumeHandler
    );
  };

  private initConfig = () => {
    /*this.baseChart.sciChartSurface.layoutManager.rightOuterAxesLayoutStrategy =
      new RightAlignedOuterVerticallyStackedAxisLayoutStrategy();*/
    this.keyPressActions();
    this.rightClickActions();
    this.setTextColor();
    this.hammersService.removeIndicator$
      .pipe(
        takeUntil(this._onDestroy),
        filter((data) => data.refComponent === this.refComponent)
      )
      .subscribe((data) => {
        const { event, removeLegend } = data;
        this.removeIndicator(event, removeLegend);
      });
    this.hammersService.updateGlobalIndicatorChart$
      .pipe(
        takeUntil(this._onDestroy),
        filter((data) => data.refComponent === this.refComponent)
      )
      .subscribe(({ globalIndicatorHammer }) => {
        this.globalIndicatorHammer = globalIndicatorHammer;
      });
    this.createAxis$.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.addOrderLines();
    });
    this.redrawCustody$
      .pipe(
        takeUntil(this._onDestroy),
        auditTime(200),
        map(() => {
          const custBox = this.getAnnotationsById(
            getLineBoxAnnotationId(this._custodyStopOrder.custodyLineId)
          ) as CustomAnnotation;
          return custBox;
        }),
        filter((annotation) => !!annotation),
        tap(() => {
          const svgElement = document.getElementById(
            getLineBoxAnnotationId(
              this._custodyStopOrder.custodyLineId
            ).replace(/\./g, '')
          )! as Node;
          const parent = document.getElementById(this.ref + '_SVG');
          if (!parent || !svgElement) return;
          parent.removeChild(svgElement);
          parent.appendChild(svgElement);
          this.updateCustodyOrderPosition();
        })
      )
      .subscribe(() => {
        this._custodyStopOrder.updateSeeCustody(
          this._candleYAxis.visibleRange,
          this.baseChart,
          false,
          true
        );
      });

    this.executedOrdersService.addExecOrderAnnotation$
      .pipe(
        takeUntil(this._onDestroy),
        delay(30),
        filter(
          (data) =>
            this.showExecOrders &&
            data.refComponent === this.refComponent &&
            this.validityPositionExecOrder(data.annotation) &&
            this.redrawCustody$ &&
            !this.redrawCustody$.closed
        )
      )
      .subscribe(() => {
        this.redrawCustody$.next();
      });

    this.executedOrdersService.addExecOrderFlagAnnotation$
      .pipe(
        takeUntil(this._onDestroy),
        auditTime(30),
        filter(
          (data) =>
            this.showExecOrders &&
            (this.showFlagOrders || this.showOpenFlag) &&
            data.refComponent === this.refComponent
        )
      )
      .subscribe((data) => {
        this.fixFlagPosition(data.annotation);
      });
    this._themeService
      .themeActiveObservable()
      .pipe(takeUntil(this._onDestroy), auditTime(100))
      .subscribe(() => {
        this.separatorDateAnnotationService.changeColorAnnotationTheme();
        this.setTextColor();
        const colorInfo = this.watermarkService.stockAnnotationColor(
          this._themeService.isDarkTheme()
        );
        this.setWatermark(colorInfo.color, this.nmCompanyValemobi);
        const stacked = this.columnBarService.stackedColumnCollection.get(
          this.refComponent
        );
        if (this.showChartVolumeAtPrice && stacked) {
          stacked.getVisibleSeries().forEach((column) => {
            if (column.dataSeries.id === 'qtty_rlp') {
              column.fill = this._themeService.isDarkTheme()
                ? '#E7EAEE80'
                : '#23242F80';
              column.stroke = this._themeService.isDarkTheme()
                ? '#E7EAEE80'
                : '#23242F80';
            }
          });
        }
        if (this.showMinMaxVisible) {
          this.minMaxAnnotationService.updateColorMinMaxAnnotation(
            this.baseChart,
            minMaxIDS.MAX
          );
          this.minMaxAnnotationService.updateColorMinMaxAnnotation(
            this.baseChart,
            minMaxIDS.MIN
          );
        }
      });
    this._addSeparatorDate$
      .pipe(
        takeUntil(this._onDestroy),
        auditTime(300),
        tap((data) => {
          if (data.clear) {
            this.separatorDateAnnotationService.deleteAnnotations();
          }
        }),
        filter(() => this.showTradingChange)
      )
      .subscribe((data) => {
        this.separatorDateAnnotationService.addSeparatorDate(
          this.xAxis,
          this.data.id_point,
          this.configs.series.interval!,
          data.addNewPoint
        );
      });
    this._repositionExecOrderTooltip$
      .pipe(takeUntil(this._onDestroy), delay(200))
      .subscribe((data) => {
        const { mouseEvent } = data;
        const height =
          document
            .getElementById(`${this.ref}_annotation-tooltip`)
            ?.getBoundingClientRect().height ?? 0;
        this.annotationTooltipTop = mouseEvent.offsetY - height - 10;
        this.annotationTooltipVisible = true;
        if (this.annotationTooltipTop < 0) {
          this.annotationTooltipTop = this.annotationTooltipTop =
            mouseEvent.offsetY + 5;
        }
        this.cdr.detectChanges();
      });
    this._showMaxAndMinLines$
      .pipe(
        takeUntil(this._onDestroy),
        auditTime(200),
        map(() => {
          let biggerMax = 0;
          let menorMin = Number.MAX_SAFE_INTEGER;
          const range = this._candleDataSeries.getIndicesRange(
            new NumberRange(
              Math.ceil(this.xAxis.visibleRange.min),
              Math.floor(this.xAxis.visibleRange.max)
            ),
            true
          );
          let maxIndex = -1;
          let minIndex = -1;
          for (let i = range.min; i <= range.max; i++) {
            const max = this._candleDataSeries.getNativeHighValues().get(i);
            const min = this._candleDataSeries.getNativeLowValues().get(i);
            if (!isNaN(max) && !isNaN(min)) {
              if (max > biggerMax && max < this._candleYAxis.visibleRange.max) {
                biggerMax = max;
                maxIndex = i;
              }
              if (min < menorMin && min > this._candleYAxis.visibleRange.min) {
                menorMin = min;
                minIndex = i;
              }
            }
          }
          return { biggerMax, menorMin, maxIndex, minIndex };
        })
      )
      .subscribe((data) => {
        if (this.showMinMaxVisible) {
          this.showMinMaxAnnotation(data);
        } else {
          this.hideMinMaxAnnotation();
        }
      });
    this._stockChartService.orderSelectedByGrid$
      .pipe(
        takeUntil(this._onDestroy),
        map((data) => {
          const { order } = data;
          const indexChart = this.execOrdersAnnotation.get(order.id_order);
          let annotations: ExecOrderAnnotation[] = [];
          if (indexChart) {
            annotations = (
              this.execOrdersAnnotationIndex.get(indexChart) ?? []
            ).filter((annotation) => annotation.y1 === order.price_average);
          }
          return { annotations };
        }),
        filter((data) => this.showExecOrders && !!data.annotations.length),
        tap((data) => {
          const { annotations } = data;
          annotations.forEach((annotation) => {
            annotation.isHidden = true;
          });
        }),
        delay(300)
      )
      .subscribe((data) => {
        const { annotations } = data;
        annotations.forEach((annotation) => {
          annotation.isHidden = false;
        });
      });
    this._repositionXAxisBook$
      .pipe(
        takeUntil(this._onDestroy),
        auditTime(100),
        filter(() => this.showChartBook)
      )
      .subscribe((data) => {
        const { visibleRange } = data;
        if (this._volumeAtPriceIndicator) {
          this._volumeAtPriceIndicator.isVisible = false;
        }
        if (this._bookIndicator) {
          this._bookIndicator.isVisible = false;
        }
        if (this.xAxisBook) {
          this.xAxisBook.visibleRange = new NumberRange(
            visibleRange.min,
            visibleRange.max
          );
        }
        if (this._volumeAtPriceIndicator) {
          this._volumeAtPriceIndicator.isVisible = true;
        }
        if (this._bookIndicator) {
          this._bookIndicator.isVisible = true;
        }
      });
    this.tigerChartEventsModalService.expandEvent$
      .pipe(
        takeUntil(this._onDestroy),
        filter((data) => data.xAxisId === this.xAxis.id),
        auditTime(200)
      )
      .subscribe((data) => {
        if (
          this.annotationClicked &&
          this.annotationClicked.id === data.annotation.id
        ) {
          return;
        }
        if (this.isEventExpanded) {
          this.isEventExpanded = false;
          this.hideAnnotationTooltip();
          this.cdr.detectChanges();
          return;
        }
        if (!data.isExpand) {
          return;
        }
        this.annotationClicked = data.annotation;
        this.showAnnotationTooltip = true;
        this.annotationTooltipVisible = true;
        this.isEventExpanded = true;
        this.cdr.detectChanges();
        if (data.tooltipButtons) {
          this.typeButtons = data.tooltipButtons;
          const refAnotationTooltip = document.getElementById(
            `${this.ref}_annotation-tooltip`
          );
          setTimeout(() => {
            if (refAnotationTooltip) {
              const borders = data.annotation.getAdornerAnnotationBorders();
              const h = refAnotationTooltip.getBoundingClientRect().height!!;
              const top = borders.y1 - h;
              this.annotationTooltipTop = top + this.messageChartHeightRemove;
              const w = refAnotationTooltip.getBoundingClientRect().width!!;
              const left = borders.x2 - 10 - w / 2;
              this.annotationTooltipLeft = left < 0 ? 0 : left;
              this.cdr.detectChanges();
            }
          }, 10);
          if (Array.isArray(data.tooltip)) {
            this.annotationTooltipLabel.push(...deepClone(data.tooltip));
          } else {
            this.annotationTooltipLabel[0] += deepClone(data.tooltip);
          }
          if (data.isTradeIdea) {
            this.annotationTooltipLabel = [data.tooltip.toString()];
          }
        }
        this.cdr.detectChanges();
      });
    this._stockChartService.footerChartConfig$
      .pipe(
        takeUntil(this._onDestroy),
        filter(
          ({ ref, isFromParent }) => ref === this.refComponent && !isFromParent
        )
      )
      .subscribe((data) => {
        const { cod, value } = data;
        const hashFunctions: any = {
          [TFooterType.RULER]: () => {
            this.setFastRuler(value);
            this.ctrlIsPress = false;
            this.updateCursorToDefaultNotGrab();
          },
          [TFooterType.EXEC_ORDERS]: () => {
            this.showExecOrderControl({ showExecOrders: value });
          },
        };
        const func = hashFunctions[cod];
        if (func) {
          func();
        }
      });
    this._stockChartService.removeChartLoading$.subscribe((sub) => {
      if (!this.alreadyInit && sub.remove && sub.ref === this.ref) {
        const configuration =
          this.stockChartModalService.getGlobalChartConfigs();
        this.showSubchartXLines = configuration.showXGridLines;
        this.showSubchartYLines = configuration.showYGridLines;
        this.navigationControl = configuration.navigationControls;
        this.showFastRuler = configuration.showFastRuler;
        this.showChartBook = configuration.showChartBook;
        this.showBidAsk = configuration.showBidAsk;
        this.showChartVolumeAtPrice = configuration.showChartVolumeAtPrice;
        this.showMinMaxVisible = configuration.showMinMaxVisible;
        this.showTradingChange = configuration.showTradingChange;
        this.showPreviousClose = configuration.showPreviousClose;
        this.showExecOrders = configuration.showExecOrders;
        this.showFlagOrders = configuration.showFlagOrders;
        this.showOpenFlag = configuration.showOpenFlag;
        this.showOrderMarker = configuration.showOrderMarker;
        this.updateZoomChartModifiers();
        this.alreadyInit = true;
        this.setXaxis(configuration);
        const isLogarithmic = configuration.useLogarithmic;
        this.isLog = isLogarithmic;
        const isFreeScale = configuration.isFreeScale;
        this.isFreeScale = isFreeScale;
        this.changeZoomPanDirection();
        this._showClosePriceTimer = configuration.useTimerPriceClose;
        this.setYaxis(this._axis2Id, configuration, false);
        this.setModifiers();
        this.addAxis();
      }
    });
    this._addDraws$
      .pipe(
        takeUntil(this._onDestroy),
        filter((draws) => draws && draws.length != 0),
        auditTime(450)
      )
      .subscribe((draws) => {
        this._stockDraws = draws;
        this.redraw();
        this._stockDraws.forEach((draw) => {
          this.addDraw(draw);
        });
      });
    this._boxLineDeleted$
      .pipe(takeUntil(this._onDestroy), throttleTime(100))
      .subscribe((data) => {
        const { options, line } = data;
        this.boxLineDeleted.emit({
          type: options.type,
          id: options.boxId,
          value: line.y1,
          data: this._lineData[line.id] || options.data,
          idTradeSystem: this.lineAlertData[options.boxId]
            ? this.lineAlertData[options.boxId].idTradeSystem
            : null,
          boxIdAlert: this.lineAlertData[options.boxId]
            ? this.lineAlertData[options.boxId].firstBoxId
            : null,
        });
      });
    this.withTools = this.isWithTools;
    this._stockChartService.tools$.subscribe((tool) => {
      if (tool.refComponent === this.refComponent) {
        this.withTools = tool.show;
        this.withToolsChange.emit(this.withTools);
        this.showTools();
        this.cdr.detectChanges();
      }
    });

    this._stockChartService.updateQuoteData$
      .pipe(
        filter((data: any) => {
          return (
            data &&
            this.stock &&
            data.quoteData.cd_stock === this.stock.cd_stock
          );
        }),
        map((data) => data.quoteData)
      )
      .subscribe((quoteData) => {
        this.updateInAuction(quoteData);
      });
    this.createLineService.onEvents(this.ref).subscribe(() => {
      this.deletePreviousVlClose();
    });
    this.plusLineService.event$.subscribe((data) => {
      if (data.ref === this.ref) {
        const closeLineY = this.getAnnotationsById(this._closeLineId).y1;
        this.openContextMenu(
          data.mouseEvent,
          data.pointValueY,
          closeLineY,
          true,
          data.mousePoint
        );
        this.clickOnPlus = true;
      }
    });

    this.altShiftOrderService.clickEvents
      .pipe(
        takeUntil(this._onDestroy),
        filter(({ refComponent }) => refComponent === this.ref)
      )
      .subscribe((data) => {
        this.emitClickOrder(
          data.menuItem,
          data.pointValueY,
          data.typeOrder,
          data.isInverse
        );
      });

    this._stockChartService.indicatorClicked$.subscribe((data) => {
      if (data && data.name) {
        this.indicatorClicked = data;
        this.setDataPointers(data);
        this.clickOnIndicator = true;
        return;
      }
      this.clickOnIndicator = false;
    });
    this._custodyStopOrder.base = this.baseChart;
    this.separatorDateAnnotationService.base = this.baseChart;
    this.createLineService.base = this.baseChart;
    this.tigerChartEventsModalService.base = this.baseChart;
    this._mbService
      .onUpdateSelectedAccountChannel()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((account) => {
        this.account = account;
        this.isInit && this.setSubscribeChartOrder();
        this.searchPositionAndOrder();
      });
    this._dayTradeService.dayTradeMode
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.isInit && this.setSubscribeChartOrder();
        this.searchPositionAndOrder();
      });
    this.tigerChartOrdersService.configuration
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.searchPositionAndOrder();
      });
    this._stockChartService.addLine
      .pipe(
        takeUntil(this._onDestroy),
        filter((data) => data && data.refComponent === this.parentRef),
        tap((data) => {
          this.orderLines.push(data.line);
        })
      )
      .subscribe(() => {
        this.addOrderLines();
      });
    this._stockChartService.updateWaterMark$
      .pipe(takeUntil(this._onDestroy))
      .subscribe(({ showWatermark, watermarkOpacity }) => {
        this.changeVisibilityWatermark(
          showWatermark ?? this.showWatermark,
          watermarkOpacity ?? this.watermarkOpacity
        );
      });
    this.chartReady.emit(this);
    this.subscribeOrderChannel();
    if (this.initCursorDefault$ && !this.initCursorDefault$.closed) {
      this.initCursorDefault$.next();
    }
  };

  private addOrderLines = () => {
    if (this.xAxis && this._candleYAxis) {
      while (this.orderLines.length) {
        const line = this.orderLines[0];
        this.createLine(line);
        this.orderLines.shift();
      }
    }
  };

  private clearChart(isChangeInterval: boolean = false) {
    if (!this.baseChart || !this.xAxis) return;
    this.updateYAxisVisibility(false);
    const renderableToRemove =
      this.baseChart.sciChartSurface.renderableSeries.asArray();
    if (isChangeInterval) {
      /**
       * como ocorre o filter, a referencia dentro do scichart é perdida
       * sendo assim, o remove não decrementa a variavel renderableFiltered
       */
      const renderableFiltered = renderableToRemove.filter(
        (serie) =>
          serie.id != CANDLE_IDS.VOLUME_X_PRICE_SERIES &&
          serie.id != CANDLE_IDS.BOOK_SERIES
      );
      renderableFiltered.forEach((renderable) => {
        this.baseChart.sciChartSurface.renderableSeries.remove(renderable);
      });
    } else {
      /**
       * neste caso, como a referencia ainda existe,
       * o remove do scichart, tambem modifica a variavel renderableToRemove
       */
      while (renderableToRemove.length) {
        this.baseChart.sciChartSurface.renderableSeries.remove(
          renderableToRemove[0]
        );
      }
    }
    this.xAxis.visibleRangeChanged?.unsubscribeAll();
    this._candleYAxis.visibleRangeChanged?.unsubscribeAll();
    this.unsubscriberUpdateEvents();
    this.clearExecAnnotation();
    this.subCharts.clear();
    this.clearLines();
    this.separatorDateAnnotationService.deleteAnnotations();
    this.drawToolsService.clearAllDic();
  }

  private setFastRulerGlobal(globalShowFastRuler: boolean) {
    if (!globalShowFastRuler) {
      this.stockChartModalService.updateGlobalChartConfigs({
        showFastRuler: this.showFastRuler,
      });
    }
  }

  private updatePrivateData(data: TUpdateData, index?: number) {
    Object.keys(data).forEach((key) => {
      const typedKey = key as keyof TData;
      if (!this.data[typedKey]) return;

      if (index) {
        this.data[typedKey][index] = data[key as keyof TUpdateData] as number;
        return;
      }
      this.data[typedKey].push(data[key as keyof TUpdateData] as number);
    });
  }

  private _onConfigChange() {
    this.xAxis.labelProvider.cursorNumericFormat =
      this.configs.xAxis.labelFormat!;
    this._candleYAxis.labelProvider.numericFormat =
      this.configs.yAxis.labelFormat!;
    this._candleYAxis.labelProvider.precision = this._chartHelper._getTicksize({
      value: 0,
      cdSegment: this.cdSegment,
      labelPrecision: this.configs.yAxis.labelPrecision,
      dsAsset: this.dsAsset,
      cdStock: this.papel,
      indicators: this.tooltip.indicators,
    });
  }

  private setFastRuler(showFastRuler: boolean) {
    this.showFastRuler = showFastRuler;
    this.removeFastRuler();
    this.updateZoomChartModifiers();
  }

  private setXaxis(configs: GlobalChartConfiguration) {
    this.xAxis = new CategoryAxis(this.baseChart.wasmContext, {
      id: this.xAxisId,
    });
    this.xAxis.drawMinorGridLines = false;
    this.xAxis.drawMinorTickLines = false;
    const drawLines = configs.showXGridLines;
    this.xAxis.drawMajorGridLines = drawLines;
    this.xAxis.drawMajorTickLines = drawLines;
    this.xAxis.growBy = new NumberRange(
      this.configs.xAxis.axisGrowBy,
      this.configs.xAxis.axisGrowBy
    );
    this.xAxis.visibleRange = new NumberRange(
      this.configs.xAxis.visibleRange!.min,
      this.configs.xAxis.visibleRange!.max
    );
    this.xAxis.autoRange = EAutoRange.Never;
    this.xAxis.offset = 20;
    this.xAxis.labelProvider.cursorNumericFormat =
      this.configs.xAxis.labelFormat!;
    this.xAxis.labelProvider.formatLabel = (value) =>
      this.axisService.xAxisLabelFormatter(
        this.configs,
        this.locale,
        value,
        false
      );
    this.xAxis.labelProvider.formatCursorLabel = (value) =>
      this.axisService.xAxisLabelFormatter(
        this.configs,
        this.locale,
        value,
        true,
        true
      );
    this._barBookYAxis = new NumericAxis(this.baseChart.wasmContext, {
      id: this._idBookBarYAxis,
    });
    this._barBookYAxis.axisAlignment = EAxisAlignment.Bottom;
    this._barBookYAxis.drawMinorGridLines = false;
    this._barBookYAxis.drawMinorTickLines = false;
    this._barBookYAxis.drawMajorGridLines = false;
    this._barBookYAxis.drawMajorTickLines = false;
    this._barBookYAxis.isVisible = false;
    this._barBookYAxis.drawLabels = false;
    this._barBookYAxis.growBy = new NumberRange(0, 8);
    this._barBookYAxis.visibleRange = new NumberRange(
      this.configs.xAxis.visibleRange!.min,
      this.configs.xAxis.visibleRange!.max
    );
    this._barBookYAxis.autoRange = EAutoRange.Always;
    this._barBookYAxis.labelProvider.cursorNumericFormat =
      this.configs.xAxis.labelFormat!;
    this._barBookYAxis.labelProvider.formatLabel = () => '';
    this._barBookYAxis.labelProvider.formatCursorLabel = () => '';
    this._barVolumeAtPriceYAxis = new NumericAxis(this.baseChart.wasmContext, {
      id: this._idVolumeAtPriceBarYAxis,
    });
    this._barVolumeAtPriceYAxis.axisAlignment = EAxisAlignment.Bottom;
    this._barVolumeAtPriceYAxis.drawMinorGridLines = false;
    this._barVolumeAtPriceYAxis.drawMinorTickLines = false;
    this._barVolumeAtPriceYAxis.drawMajorGridLines = false;
    this._barVolumeAtPriceYAxis.drawMajorTickLines = false;
    this._barVolumeAtPriceYAxis.isVisible = false;
    this._barVolumeAtPriceYAxis.drawLabels = false;
    this._barVolumeAtPriceYAxis.growBy = new NumberRange(0, 8);
    this._barVolumeAtPriceYAxis.autoRange = EAutoRange.Never;
    this._barVolumeAtPriceYAxis.labelProvider.cursorNumericFormat =
      this.configs.xAxis.labelFormat!;
    this._barVolumeAtPriceYAxis.labelProvider.formatLabel = () => '';
    this._barVolumeAtPriceYAxis.labelProvider.formatCursorLabel = () => '';
    this.createAxis$.next();
  }

  private setXAxisVisibleRangeChange(interval: TIGER_INTERVAL_ENUM) {
    let lastValue = 0;
    this.xAxis.visibleRangeChanged?.unsubscribeAll();
    this._candleYAxis.visibleRangeChanged?.unsubscribeAll();
    this.unsubscriberUpdateEvents();
    this._updateVisibleEvents$ = this.tigerChartEventsModalService
      .onUpdateVisibleEvents()
      .subscribe(() => {
        this.tigerChartEventsModalService.clearToUpdate();
        this._updateVisibleEvents();
      });
    const func = () => {
      this.xAxis.visibleRangeChanged.subscribe((event) => {
        if (!event || !event.visibleRange) return;
        let visibleRange = event.visibleRange;
        if (!(visibleRange instanceof NumberRange)) {
          visibleRange = new NumberRange(
            event.visibleRange.min,
            event.visibleRange.max
          );
        }
        if (
          visibleRange.min &&
          visibleRange.min <= 0 &&
          visibleRange.min != lastValue
        ) {
          this.scrollBegin = true;
          this._stockChartService.scrollToBegin$.next(this.refComponent);
        }
        this._showMaxAndMinLines$.next();
        this.tigerChartEventsModalService.updateCoordinates(
          this.data,
          interval
        );
        if (this.showEvents) {
          this.tigerChartEventsModalService.dispatchUpdateVisibleEvents(
            event.visibleRange
          );
        }
        this.drawToolsService.axisChanged$.next({
          type: 'XAXIS',
          visibleRange,
        });
        this._candleYAxis.autoRange = EAutoRange.Once;
        if (!this.isFreeScale) {
          this._candleYAxis.autoRange = EAutoRange.Always;
          this.updateWindowedView(false);
        }
        this.xAxisBook.visibleRange = new NumberRange(
          this._candleYAxis.visibleRange.min,
          this._candleYAxis.visibleRange.max
        );
        if (!this._zoomingVisibleRange) {
          this._zoomingVisibleRange = this._firstVisibleRange!!;
        }
        if (!this._previousVRange) {
          this._previousVRange = this._firstVisibleRange!!;
        }
        this.configs.xAxis.visibleRange = visibleRange;
        lastValue = visibleRange.min || 0;
        if (visibleRange.max < this.data.id_point.length) {
          this.controlBackRightPosition =
            this._stockChartService.verticalGroup.groupRightSize / 1.2;
          this.showControlBack = true;
        }
        if (visibleRange.max >= this.data.id_point.length) {
          this.showControlBack = false;
        }
        if (!this._stockChartService.notReload.has(this.ref)) {
          this._stockChartService.visibleRange$.next({
            ref: this.ref,
            visibleRange: visibleRange,
            interval: this.configs.series.interval,
            zoomState: this.baseChart.sciChartSurface.zoomState,
            maxRange: this.baseChart.sciChartSurface.xAxes
              .get(0)
              .getMaximumRange(),
            previousVisibleRange: this._previousVRange,
            isChanging: this.isChanging,
            firstVisibleRange: this._firstVisibleRange,
          });
        }
        this._previousVRange = visibleRange;
        const diffAtual = visibleRange.max - visibleRange.min;
        if (this._zoomingVisibleRange) {
          const diffAnterior =
            this._zoomingVisibleRange.max - this._zoomingVisibleRange.min;
          if (diffAtual != diffAnterior) {
            this._zoomingVisibleRange = visibleRange;
          }
        }
        this.redrawCustody$.next();
      });
      this.subscriberCandleYAxisChanges();
    };
    this.isStarted(func);
  }

  private repositionDraw() {
    const redrawTools = this.drawToolsService.redrawTool;
    redrawTools.keys().forEach((key) => {
      const redraw = redrawTools.get(key)!;
      if (redraw.isRedraw) {
        const resp = this.drawToolsService.repositionConfigurationDraw(
          redraw.drawConfig.firstEventData,
          redraw.drawConfig.lastEventData,
          this.baseChart,
          this.ref,
          this.configs,
          redraw.configuration
        );
        const draw = this.baseChart.sciChartSurface.annotations.getById(
          key.toString()
        );
        draw.x1 = resp.configuration!.x1!;
        draw.x2 = resp.configuration!.x2!;
        if (!resp.return) {
          this.drawToolsService.redrawTool.delete(key);
        }
      }
    });
  }

  updateAxisGridLinesVisibility(axisConfigs: any) {
    if (!axisConfigs) return;
    const drawXLines = objectValueOrTrue(axisConfigs, 'showXGridLines');
    const drawYLines = objectValueOrTrue(axisConfigs, 'showYGridLines');
    this.xAxis.drawMajorGridLines = drawXLines;
    this.xAxis.drawMajorTickLines = drawXLines;
    this.xAxis.drawMajorBands = drawXLines;
    this._candleYAxis.drawMajorGridLines = drawYLines;
    this._candleYAxis.drawMajorTickLines = drawYLines;
    this._candleYAxis.drawMajorBands = drawYLines;
    this.showSubchartXLines = axisConfigs.showXGridLines;
    this.showSubchartYLines = axisConfigs.showYGridLines;
  }

  updateCandleColors(
    candleColors: ICandleColorsCandle,
    force: boolean = false
  ) {
    if (!candleColors) return;
    const rend = this.getRenderableSeriesById(CANDLE_IDS.MAIN_SERIE);
    if (rend.type === ESeriesType.CandlestickSeries) {
      const fastCandleStick = rend as FastCandlestickRenderableSeries;
      const elephantBar = this.getElephantBarIndicator();
      if (!elephantBar || force) {
        fastCandleStick.brushUp =
          candleColors.colorsVelaUp ?? CHART_COLORS.FEEDBACK_POSITIVE;
        fastCandleStick.brushDown =
          candleColors.colorsVelaDown ?? CHART_COLORS.FEEDBACK_NEGATIVE;
        fastCandleStick.strokeUp =
          candleColors.colorsBorderUp ?? CHART_COLORS.FEEDBACK_POSITIVE;
        fastCandleStick.strokeDown =
          candleColors.colorsBorderDown ?? CHART_COLORS.FEEDBACK_NEGATIVE;
      }
    }
  }

  updateVolumeColors(candleColors: any) {
    if (!candleColors) return;
    const rend = this.baseChart.sciChartSurface.renderableSeries.getById(
      CANDLE_IDS.VOLUME_SERIES
    ) as FastColumnRenderableSeries;
    rend.paletteProvider = new VolumePaletteProvider(
      this.stockChartModalService,
      this.candleDataSeries,
      candleColors.volumeUpColor,
      candleColors.volumeDownColor,
      true
    );
  }

  showNavigationControls(configs: any) {
    if (!configs) return;
    this.navigationControl = configs.navigationControls;
    this.updateZoomChartModifiers();
    this.cdr.detectChanges();
  }

  showExecOrderControl(configs: any) {
    if (!configs) return;
    this.showExecOrders = configs.showExecOrders;
    if (this.showFlagOrders) this.cleanHiddenFlags(false);
    if (this.showOpenFlag) this.cleanHiddenFlags(true);
    this.isInit && this.setSubscribeChartOrder();
  }

  minMaxVisible(configs: any) {
    if (!configs) return;
    this.showMinMaxVisible = configs.showMinMaxVisible;
    this._showMaxAndMinLines$.next();
  }

  tradingChange(configs: any) {
    if (!configs) return;
    this.showTradingChange = configs.showTradingChange;
    this._addSeparatorDate$.next({ addNewPoint: false, clear: true });
  }

  previousClose(configs: any) {
    if (!configs) return;
    this.showPreviousClose = configs.showPreviousClose;
    this.createLineService.deletePreviousVlClose();
    if (this.showPreviousClose) {
      this.plotPreviousVlClose();
    }
  }

  bidAsk(configs: any) {
    if (!configs) return;
    this.showBidAsk = configs.showBidAsk;
    this.deleteBidAsk();
    if (this.showBidAsk) {
      if (this.bookData) {
        this.resultProcess(this.bookData);
      }
      this.subscribeBookChannel();
    } else {
      this.unsubBookChannel();
    }
  }

  hideFastOrderDaytradeStrategy(configs: any) {
    if (!configs) return;
  }

  showChartBookControl(configs: any) {
    if (!configs) return;
    this.showChartBook = configs.showChartBook;
    const columns = this.baseChart.sciChartSurface.renderableSeries.getById(
      CANDLE_IDS.BOOK_SERIES
    );
    this.baseChart.sciChartSurface.renderableSeries.remove(columns);
    if (isTypeStockIndex(this.stock?.type)) {
      this.unsubBookChannel();
      return;
    }

    if (this.showChartBook) {
      if (this.bookData) {
        this.resultProcess(this.bookData);
      }
      this.subscribeBookChannel();
    } else {
      this.unsubBookChannel();
    }
  }

  showChartVolumeAtPriceControl(configs: any) {
    if (!configs || !this.isInit) return;
    this.showChartVolumeAtPrice = configs.showChartVolumeAtPrice;
    this.volumeAtPriceDict.clear();
    this.isEndendVolumeAtPrice = false;
    this.columnBarService.clearColumnBar(this.baseChart);
    if (isTypeStockIndex(this.stock?.type)) {
      this._removeStreamingVolume();
      return;
    }
    if (this.showChartVolumeAtPrice) {
      this.subscribeVolumeChannel();
    } else {
      this._removeStreamingVolume();
    }
  }

  private updateZoomChartModifiers() {
    const chartModifiers =
      this.baseChart.sciChartSurface.chartModifiers.asArray();
    const zoomPan = chartModifiers.find(
      (mod) =>
        (mod as ChartModifierBase2D).type === EChart2DModifierType.ZoomPan
    );
    const mouseWheelZoom = chartModifiers.find(
      (mod) =>
        (mod as ChartModifierBase2D).type ===
        EChart2DModifierType.MouseWheelZoom
    );
    if (zoomPan && this.displayFastRuler) {
      zoomPan.isEnabled = !this.showFastRuler;
    }
    if (mouseWheelZoom) {
      const doc = document.getElementById(`${this.ref}`);
      if (!doc) {
        return;
      }
      doc.removeEventListener('wheel', this.wheelEvents);
      mouseWheelZoom.isEnabled =
        this.navigationControl !== 'ALWAYS_SHOW' &&
        this.navigationControl !== 'ONLY_HOVER';
      if (
        this.navigationControl === 'ALWAYS_SHOW' ||
        this.navigationControl === 'ONLY_HOVER'
      ) {
        doc.addEventListener('wheel', this.wheelEvents);
      }
    }
  }

  wheelEvents = (event: any) => {
    event.preventDefault();
    const isTouchPad = Math.abs(event.wheelDeltaY) < 120;
    const delta =
      (!isTouchPad ? event.wheelDelta : event.wheelDeltaX) * 0.1 * -1;
    const deltaY = event.wheelDeltaY * 0.1 * -1;
    if (event.ctrlKey) {
      this.wheelDeltaY(deltaY);
      return;
    }
    if (Math.abs(delta) && !this.isWheelDeltaY) {
      this.xAxis.scroll(delta, EClipMode.None);
    } else if (isTouchPad && Math.abs(event.wheelDeltaY) != 0) {
      this.isWheelDeltaY = true;
      this.wheelDeltaY(deltaY);
      setTimeout(() => {
        this.isWheelDeltaY = false;
      }, 200);
    }
  };

  wheelDeltaY(deltaY: number) {
    const wheelModifier = this.getModifierById(
      this.mouseWheelId
    ) as wheelZoomModifier;
    wheelModifier.makeGrow(deltaY);
  }

  updatePriceCloseTimer(configs: any) {
    if (!configs) return;
    this._showClosePriceTimer = configs.useTimerPriceClose;
    if (this._closelineTimer) {
      this._closelineTimer.isHidden = !this._showClosePriceTimer;
    }
    if (this._closelineBox) {
      this._closelineBox.isHidden = !this._showClosePriceTimer;
    }
    if (this.closeline) {
      this.createCloseTimerAnnotation(
        this.closeline.y1,
        this.closeline.axisLabelFill
      );
      this.updateTimerPosition(this.closeline.y1, this.closeline.axisLabelFill);
    }
  }

  private updateWindowedView(updateXAxisBook: boolean = true) {
    if (!this._candleDataSeries || this._custodyStopOrder.isDragging) return;
    const windowed = this._candleDataSeries.getWindowedYRange(
      this.xAxis.visibleRange,
      true,
      true
    );
    if (
      !windowed ||
      isNullOrUndefined(windowed.max) ||
      isNullOrUndefined(windowed.min)
    ) {
      return;
    }
    const newWindowed = new NumberRange(windowed.min, windowed.max);
    this._candleYAxis.maxAutoTicks =
      windowed.max - windowed.min < 0.05
        ? (windowed.max - windowed.min) * 100 + 1
        : 10;
    this._candleYAxis.visibleRange = newWindowed;
    this._custodyStopOrder.updateSeeCustody(newWindowed, this.baseChart);
    updateXAxisBook && this.updateXAxisBook(newWindowed, false);
  }

  private updateXAxisBook(
    visibleRange: NumberRange,
    updateCandleY: boolean = true
  ) {
    this.repositionXAxisBook(visibleRange);
    updateCandleY && (this._candleYAxis.visibleRange = visibleRange);
  }

  private repositionXAxisBook(visibleRange: NumberRange) {
    this._repositionXAxisBook$.next({ visibleRange });
  }

  private setYaxis(
    id: string,
    configs: GlobalChartConfiguration,
    addY: boolean
  ) {
    if (this._candleYAxis) {
      this.axisRemove = this._candleYAxis;
      this.axisRemove = this._columnYAxis;
      this.axisRemove = this.xAxisBook;
    }
    this._columnYAxis = new NumericAxis(this.baseChart.wasmContext);
    if (this.isLog) {
      this.xAxisBook = new LogarithmicAxis(this.baseChart.wasmContext);
      this._candleYAxis = new LogarithmicAxis(this.baseChart.wasmContext);
      this._candleYAxis.tickProvider = new CustomLogarithmicTickProvider(
        this.baseChart.wasmContext
      );
      this._candleYAxis.logBase = 2;
    } else {
      this.xAxisBook = new NumericAxis(this.baseChart.wasmContext);
      this._candleYAxis = new NumericAxis(this.baseChart.wasmContext);
      this._candleYAxis.tickProvider = new CustomNumericTickProvider(
        this.baseChart.wasmContext
      );
    }
    this._columnYAxis.growBy = new NumberRange(0, 5);
    this._columnYAxis.id = id;
    this._columnYAxis.axisAlignment = EAxisAlignment.Left;
    this._columnYAxis.isVisible = false;
    this._columnYAxis.drawLabels = false;
    this._columnYAxis.drawMajorGridLines = false;
    this._columnYAxis.drawMajorTickLines = false;
    this._columnYAxis.labelProvider.formatCursorLabel = () => '';
    this._columnYAxis.autoRange = EAutoRange.Always;
    this._columnYAxis.visibleRangeLimit = new NumberRange(0, 10000000000000);
    this._candleYAxis.growBy = new NumberRange(0.15, 0.15);
    this._candleYAxis.labelProvider.numericFormat =
      this.configs.yAxis.labelFormat!;
    this._candleYAxis.labelProvider.precision =
      this.configs.yAxis.labelPrecision!;
    this._candleYAxis.drawMinorGridLines = false;
    this._candleYAxis.drawMinorTickLines = false;
    const showLines = configs.showYGridLines;
    this._candleYAxis.drawMajorGridLines = showLines;
    this._candleYAxis.drawMajorTickLines = showLines;
    this._candleYAxis.autoRange = EAutoRange.Once;
    this._candleYAxis.labelStyle.padding = new Thickness(1, 10, 1, -1);
    this._candleYAxis.labelProvider.formatLabel = (value: any) =>
      this.yAxisLabelFormatter(value);
    this._candleYAxis.labelProvider.formatCursorLabel = (value: any) =>
      this.yAxisLabelFormatter(value);
    this.xAxisBook.flippedCoordinates = true;
    this.xAxisBook.growBy = new NumberRange(0.15, 0.15);
    this.xAxisBook.id = this.xAxisBookId;
    this.xAxisBook.axisAlignment = EAxisAlignment.Right;
    this.xAxisBook.drawMajorGridLines = false;
    this.xAxisBook.drawMajorTickLines = false;
    this.xAxisBook.isVisible = false;
    this.xAxisBook.drawLabels = false;
    this.xAxisBook.labelProvider.formatLabel = (value: any) =>
      this.yAxisLabelFormatter(value);
    this.xAxisBook.labelProvider.formatCursorLabel = (value: any) =>
      this.yAxisLabelFormatter(value);
    this.xAxisBook.autoRange = EAutoRange.Once;
    this.addAxis(false, addY);
    this.createAxis$.next();
  }

  private setSecondaryYaxis(id: string): NumericAxis[] {
    const columnYAxis = new NumericAxis(this.baseChart.wasmContext, {
      id: id,
      axisAlignment: EAxisAlignment.Left,
      stackedAxisLength: '30%',
    });
    columnYAxis.isVisible = true;
    columnYAxis.drawLabels = false;
    columnYAxis.labelProvider.formatCursorLabel = () => '';
    columnYAxis.autoRange = EAutoRange.Never;
    columnYAxis.growBy = new NumberRange(0, 0.05);

    this.baseChart.sciChartSurface.yAxes.add(columnYAxis);

    const candleYAxis = new NumericAxis(this.baseChart.wasmContext, {
      id: `${id}_candle`,
      stackedAxisLength: '30%',
    });
    candleYAxis.growBy = new NumberRange(0, 0.05);
    candleYAxis.autoRange = EAutoRange.Always;
    candleYAxis.labelProvider.numericFormat = this.configs.yAxis.labelFormat!;
    candleYAxis.labelProvider.precision = this.configs.yAxis.labelPrecision!;
    candleYAxis.labelProvider.formatLabel = (value) =>
      this.yAxisLabelFormatter(value);
    candleYAxis.labelProvider.formatCursorLabel = (value) =>
      this.yAxisLabelFormatter(value);

    this.baseChart.sciChartSurface.yAxes.add(candleYAxis);

    return [columnYAxis, candleYAxis];
  }

  private setModifiers() {
    const mouseEventsModifier = new MouseEventsModifier(
      this.middleChartService,
      this.stockChartModalService,
      this._stockChartService,
      this.ref,
      this.xAxisId
    );
    mouseEventsModifier.onEvents().subscribe((e) => this.mouseEvents(e));
    mouseEventsModifier
      .onHitSeriesEvents()
      .subscribe((e) => this.hitSeriesEvent(e));
    mouseEventsModifier.onDrawAnnotationHoverEvents().subscribe((draw) => {
      this.annotationHoverEvents(draw.draw);
    });
    mouseEventsModifier.onExecOrderAnnotationHoverEvents().subscribe((data) => {
      if (data.execOrderAnnotations && data.execOrderAnnotations.length) {
        this.addExecOrderAnnotationTooltip(
          data.execOrderAnnotations,
          data.mouseEvent
        );
      } else if (!data.anotherHover) {
        this.hideAnnotationTooltip();
      } else {
        this.drawToolsService.blockEditDraw$.next(true);
      }
    });
    mouseEventsModifier.onClickEvents().subscribe((e) => {
      this.mouseClickEvents(e);
      const color =
        this.vlCloseLine || 0 >= (this.vlOpen || 0)
          ? this.configs.closeLine.color!.up
          : this.configs.closeLine.color!.down;
      this.createCloseTimerAnnotation(this.vlCloseLine || 0, color);
      this.updateCloseLineTimer();
    });
    const clickIndicator = new ClickEventsModifier(
      this.tigerChartEventsModalService,
      this.xAxisId,
      `DefaultAxisId`
    );
    clickIndicator.events$.subscribe((e) => {
      this.setDataPointers(e);
      return this._stockChartService.indicatorClick(e);
    });

    this.modifiersAdd = mouseEventsModifier;
    this.modifiersAdd = clickIndicator;
    this.modifiersAdd = new DisableMoveDecorationsModifier(this.ref);
  }

  private mouseClickEvents(eventData: TMouseEventData | null) {
    if (!eventData) return;
    super.mouseEvents(eventData);
    const funcs: any = {
      [TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_GRAB]: () => {
        this._stockChartService.notReload.clear();
        !this.zoomIn && this.updateChartCursor('grabbing');
        this.showAnnotationTooltip = false;
        this.annotationTooltipVisible = false;
        this.isEventExpanded = false;
        this.cdr.detectChanges();
        this.resetEditDraw(false);
        this.selectionDraw();
      },
      [TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_UNGRAB]: () => {
        this._stockChartService.notReload.clear();
        if (this.zoomIn) {
          this.zoomInEmmitter.emit(false);
          this.toolCursor = this.getCursor(this.defaultCursor);
        }
        this.updateChartCursor('grab');
        this.selectionDraw();
        this.cdr.detectChanges();
        this.isMiddleClickHold = false;
      },
      [TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_MIDDLE_MOUSE_CLICK]: () => {
        this.isMiddleClickHold = true;
        this.middleClickPosition = eventData.mousePoint;
      },
    };
    const func = funcs[eventData.type];
    if (func) {
      func();
    }
  }

  private setArrow() {
    const arrow = this.getAnnotationsById(this._watermarkId);
    if (arrow) {
      return;
    }
    this.annotationsAdd = new CustomAnnotation({
      id: this._watermarkId,
      svgString: getChartArrow(),
      horizontalAnchorPoint: EHorizontalAnchorPoint.Left,
      verticalAnchorPoint: EVerticalAnchorPoint.Top,
      isEditable: false,
      xCoordinateMode: ECoordinateMode.Relative,
      yCoordinateMode: ECoordinateMode.Relative,
      yCoordShift: -42,
      x1: 0,
      y1: 1,
      xAxisId: this.xAxisId,
    });
  }

  private hitSeriesEvent(eventData: TMouseEventData) {
    const funcs: any = {
      [TIGER_MOUSE_EVENT_DATA_TYPE.INDICATOR_HOVER]: () => {
        if (
          eventData.renderableSeries &&
          eventData.renderableSeries.type === ESeriesType.ColumnSeries &&
          eventData.renderableSeries.id === CANDLE_IDS.BOOK_SERIES
        ) {
          if (eventData.hitTestInfo?.isHit)
            this.hoverBookSeries(eventData.hitTestInfo!!);
        } else if (
          eventData.renderableSeries &&
          eventData.renderableSeries.type === ESeriesType.StackedColumnSeries
        ) {
          if (eventData.hitTestInfo?.isHit)
            this.hoverVolumeAtPriceSeries(eventData.hitTestInfo!!);
        } else if (!this.isGrabbing()) {
          this.updateChartCursor('pointer');
        }
      },
      [TIGER_MOUSE_EVENT_DATA_TYPE.INDICATOR_LEAVE]: () => {
        if (
          eventData.renderableSeries &&
          eventData.renderableSeries.type === ESeriesType.ColumnSeries &&
          eventData.renderableSeries.id === CANDLE_IDS.BOOK_SERIES
        ) {
          if (!eventData.hitTestInfo?.isHit) this.blurBookSeries();
        } else if (
          eventData.renderableSeries &&
          eventData.renderableSeries.type === ESeriesType.StackedColumnSeries
        ) {
          if (!eventData.hitTestInfo?.isHit) this.blurVolumeAtPriceSeries();
        }
        if (!this.isGrabbing()) {
          this.updateCursorToDefaultNotGrab();
        }
        this.blurBookSeries();
        this.blurVolumeAtPriceSeries();
      },
    };
    const func = funcs[eventData.type];
    if (func) {
      func();
    }
  }

  private domCanvasLeave(eventData: TMouseEventData | null) {
    this.hideAnnotationTooltip();
    this.updateTooltipLastVolume();
    this.updateDraw(eventData);
    this.isMouseOverValue = false;
  }

  private updateTooltipLastVolume() {
    if (this.tooltip && this.data) {
      this.tooltip.updateTextData(this._volumeIndicator.getLastVolume());
    }
  }

  private updateTooltipValue(eventData: TMouseEventData) {
    const vl_volume = this._volumeIndicator.getVolumeByIndex(eventData.data);
    if (this.tooltip) {
      this.tooltip.updateTextData(vl_volume, eventData.data);
    }
    this._stockChartService.updateTooltip$.next({
      refComponent: this.ref,
      eventData,
      volume: vl_volume,
    });
    this.isMouseOverValue = eventData.data ? true : false;
    this.cdr.detectChanges();
  }

  private doScalingYAxis(eventData: TMouseEventData) {
    const yDrag = this.getModifierById(
      'yaxis-drag-modifier'
    ) as TigerYAxisDragModifier;
    if (yDrag) {
      this.setChartFreeScale();
      yDrag.middleClickScaling(
        this.middleClickPosition!,
        eventData.mousePoint!,
        this.baseChart.sciChartSurface.yAxes.asArray()
      );
      this.middleClickPosition = eventData.mousePoint;
    }
  }

  override mouseEvents(eventData: TMouseEventData | null) {
    if (
      eventData &&
      eventData.mousePoint &&
      this.isMiddleClickHold &&
      this.middleClickPosition
    ) {
      this.doScalingYAxis(eventData);
      return;
    }
    if (
      eventData &&
      eventData.pointValues &&
      (this.pressAlt || this.pressShift)
    ) {
      const yAxisFormatter: IYAxisLabelFormatter = {
        value: 0,
        cdSegment: this.cdSegment,
        configs: this.configs,
        dsAsset: this.dsAsset,
        indicators: this.tooltip.indicators,
        papel: this.papel,
      };
      const data: IAltShiftOrder = {
        baseChart: this.baseChart,
        closeline: this.closeline!!,
        eventData,
        pressAlt: this.pressAlt,
        ref: this.ref,
        yAxisFormatter,
      };
      this.altShiftOrderService.makeShiftAltAux(data);
    }
    if (eventData && eventData.isFromPrincipalChart) {
      this._stockChartService.clickRef = this.ref;
      this.chartHeight = this.configs.height - this.subChartHeightRemove;
      this.cursorModifier &&
        (this.cursorModifier.showYLine = this.configs.enableCursorModifier);
      this.addPlus(eventData);
      if (!this.isGrabbing() && eventData.data && !eventData.data.hovered) {
        this.updateCursorToDefaultNotGrab();
      }
    } else {
      this.cursorModifier && (this.cursorModifier.showYLine = false);
    }
    if (eventData === null) {
      this.domCanvasLeave(eventData);
      return;
    }
    const funcs: any = {
      [TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_GRAB]: () => {
        this._stockChartService.notReload.clear();
        !this.zoomIn && this.updateChartCursor('grabbing');
        this.showAnnotationTooltip = false;
        this.annotationTooltipVisible = false;
        this.isEventExpanded = false;
        this.cdr.detectChanges();
        this.resetEditDraw(false);
      },
      [TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_UNGRAB]: () => {
        this._stockChartService.notReload.clear();
        if (this.zoomIn) {
          this.zoomInEmmitter.emit(false);
        }
        this.updateCursorToDefaultNotGrab();
      },
      [TIGER_MOUSE_EVENT_DATA_TYPE.XAXIS_HOVER]: () => {
        this.removePlus();
      },
      [TIGER_MOUSE_EVENT_DATA_TYPE.YAXIS_HOVER]: () => {
        this.removePlus();
      },
      [TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_LEAVE]: () => {
        this.domCanvasLeave(eventData);
      },
      [TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_LEAVE_CANVAS]: () => {
        if (this.isGrabbing()) return;
        this.domCanvasLeave(eventData);
        this.blurBookSeries();
        this.blurVolumeAtPriceSeries();
        this.removePlus();
      },
      [TIGER_MOUSE_EVENT_DATA_TYPE.X_DATASERIES_HOVER]: () => {
        this.updateDraw(eventData);
        this.updateTooltipValue(eventData);
      },
      [TIGER_MOUSE_EVENT_DATA_TYPE.ANNOTATION_HOVER]: () => {
        if (eventData.data.anotherHover) return;
        if (!eventData.data.hovered) {
          this.hideAnnotationTooltip();
        }
        this._mousePoint = eventData.mousePoint;
        if (eventData.data.isEditable) {
          this.updateChartCursor(
            eventData.data.hovered ? 'pointer' : 'default'
          );
        }
      },
      [TIGER_MOUSE_EVENT_DATA_TYPE.INDICATOR_HOVER]: () => {
        /**
         * se cair aqui veio do subchart
         */
        this.updateTooltipValue(eventData);
      },
      [TIGER_MOUSE_EVENT_DATA_TYPE.INDICATOR_LEAVE]: () => {
        /**
         * se cair aqui veio do subchart
         */
        this.updateTooltipValue(eventData);
      },
    };
    const func = funcs[eventData.type];
    if (func) {
      func();
    }
  }

  private getAnnotationArrow(value: number) {
    const visibleRange = this._candleYAxis.visibleRange;
    let arrow!: ARROW_TYPES;
    if (value < visibleRange.min) {
      arrow = ARROW_TYPES.NEGATIVE;
    }
    if (value > visibleRange.max) {
      arrow = ARROW_TYPES.POSITIVE;
    }
    return arrow;
  }

  private getCustodyTextInfo(boxLabel: string, isCustody: boolean) {
    let custodyText = [];
    let qtty = '';
    let pnl = '';
    if (isCustody) {
      custodyText = boxLabel.split('|');
      qtty = `|${custodyText[1]}|`;
      pnl = custodyText[2] ?? '';
    } else {
      custodyText = boxLabel.split('|');
      qtty = `${custodyText[0]}|`;
      pnl = custodyText[1] ?? '';
    }
    const pnlLength = this.textLengthService.getBoxLineTextLength(pnl);
    const qttyLength = this.textLengthService.getBoxLineTextLength(qtty);
    return { qtty, pnl, pnlLength, qttyLength };
  }

  private addBoxToLineAnnotation(
    line: HorizontalLineAnnotation,
    options: TCandleLineOptions,
    auxLine?: HorizontalLineAnnotation
  ) {
    if (
      !this._candleYAxis ||
      !options.boxLabel ||
      (this.stock && options?.stock?.cd_stock !== this.stock.cd_stock)
    ) {
      return;
    }
    let yCoordinateMode = ECoordinateMode.DataValue;
    const yVisibleRange = this._candleYAxis.visibleRange;
    let y1 = options.value;
    let createArrow = false;
    const isCustody = options.type == TIGER_LINE_TYPE.CUSTODY;
    const isBreakEven = options.type == TIGER_LINE_TYPE.BREAK_EVEN;
    if (isCustody) {
      if (options.value < yVisibleRange.min) {
        createArrow = true;
        y1 = 0.9;
        yCoordinateMode = ECoordinateMode.Relative;
      }
      if (options.value > yVisibleRange.max) {
        createArrow = true;
        y1 = 0.1;
        yCoordinateMode = ECoordinateMode.Relative;
      }
    }
    const onlyIcon = options.boxLabel.trim().length == 0 ? 2 : undefined;
    let icon = line.id.includes(this._newLineId)
      ? getNotificationTemplate(onlyIcon)
      : undefined;
    const boxLabel =
      yCoordinateMode === ECoordinateMode.Relative
        ? ''
        : options.boxLabel.trim();
    const textColor = options.boxTextColor ?? 'white';
    const textLength = this.textLengthService.getBoxLineTextLength(
      boxLabel,
      icon
    );
    if (
      isCustody &&
      options.boxId != TIGER_DOUBLE_STOP_ORDER_IDS.GAIN &&
      options.boxId != TIGER_DOUBLE_STOP_ORDER_IDS.LOSS
    ) {
      icon = getCardTravel(textColor);
    }
    const arrow = this.getAnnotationArrow(options.value);
    let pnlLength = 0,
      qttyLength = 0,
      pnl = '',
      qtty = '';
    if ((boxLabel && isCustody) || isBreakEven) {
      const getCustodyTextInfo = this.getCustodyTextInfo(
        boxLabel,
        isCustody || !isBreakEven
      );
      pnlLength = getCustodyTextInfo.pnlLength;
      qttyLength = getCustodyTextInfo.qttyLength;
      pnl = getCustodyTextInfo.pnl;
      qtty = getCustodyTextInfo.qtty;
    }
    const labelBox = new ClickableAnnotation({
      svgString: getTextBoxTemplate(
        boxLabel,
        textLength,
        textColor,
        options.color,
        getLineBoxAnnotationId(options.boxId),
        isCustody || isBreakEven,
        icon,
        isBreakEven ? false : true,
        createArrow,
        arrow,
        this._chartHelper.generateOverrideCustodyBox(
          isCustody,
          pnlLength,
          qttyLength,
          pnl,
          qtty
        )
      ),
      horizontalAnchorPoint: EHorizontalAnchorPoint.Left,
      verticalAnchorPoint: EVerticalAnchorPoint.Center,
      xCoordinateMode: options.xCoordinateMode || ECoordinateMode.Pixel,
      yCoordinateMode,
      x1: options.xOffset || 0,
      y1,
      isEditable: !options.undraggable,
      id: getLineBoxAnnotationId(options.boxId),
      annotationsGripsRadius: 0,
      annotationsGripsFill: 'transparent',
      annotationsGripsStroke: 'transparent',
      selectionBoxStroke: 'transparent',
      selectionBoxDelta: 0,
      selectionBoxThickness: 0,
      xAxisId: this.xAxisId,
      onClick: (args) => {
        this.drawToolsService.blockEditDraw$.next(true);
        this._lineValueOnDragStart = labelBox.y1;
        const x = args.mouseArgs.mousePoint.x;
        const borders = labelBox.getAdornerAnnotationBorders();
        if (isCustody) {
          const box = this.getAnnotationsById<CustomAnnotation>(
            getLineBoxAnnotationId(options.boxId)
          );
          const line = this.getAnnotationsById<HorizontalLineAnnotation>(
            options.boxId
          );
          const createArrow = box.yCoordinateMode === ECoordinateMode.Relative;
          if (createArrow) {
            const yVisibleRange = this._candleYAxis.visibleRange;
            const typeArrow =
              line.y1 < yVisibleRange.min
                ? ARROW_TYPES.NEGATIVE
                : ARROW_TYPES.POSITIVE;
            this._scrollingCustody(typeArrow, line.y1);
            setTimeout(() => {
              this.updateLine(line, options);
            }, 100);
            return;
          }
        }
        if (isBreakEven || isCustody) {
          const isBreakEvenClicked =
            !this._customPreferencesService.breakEvenIsClicked;
          this._customPreferencesService.breakEvenIsClicked =
            isBreakEvenClicked;
          this._stockChartService.clickOnCustody$.next(isBreakEvenClicked);
          this.createLineBreakEven(
            this.stock,
            this._custodyStopOrder.custodyInfo,
            isBreakEvenClicked
          );
          this.createLinePosition(
            this._custodyStopOrder.custodyInfo,
            isBreakEvenClicked,
            this.stock
          );
        }
        if (x && x > borders.x2 - 20) {
          const replacedId = options.boxId.replace('_line', '');
          if (
            replacedId == TIGER_DOUBLE_STOP_ORDER_IDS.GAIN ||
            replacedId == TIGER_DOUBLE_STOP_ORDER_IDS.LOSS
          ) {
            this.updateCustodyCheeps(true);
            return;
          }
          this._boxLineDeleted$.next({ line, options });
          return;
        }
      },
      onDrag: () => {
        if (options.undraggable) return;
        this.drawToolsService.blockEditDraw$.next(true);
        this._onLineDrag(line, labelBox, options, true, auxLine);
      },
      onDragEnded: () => {
        if (options.undraggable) return;
        this.drawToolsService.blockEditDraw$.next(false);
        this._onLineDragEnd(line, labelBox, options);
      },
      customClick: () => {
        this.baseChart.sciChartSurface.annotations.remove(labelBox);
        this.baseChart.sciChartSurface.annotations.remove(line);
        auxLine && this.baseChart.sciChartSurface.annotations.remove(auxLine);
        if (this._lineData[line.id]) delete this._lineData[line.id];
        if (isCustody) {
          this.clearCustody();
        }
        if (
          isDoubleStartStop(
            options.data?.cd_order_type &&
              this._custodyStopOrder.custodyQuantity
          )
        ) {
          this._custodyStopOrder.updateCustodyCheeps(true);
        }
      },
      customHover: this._createAnnotationHoverFunction(options),
    });
    labelBox.clickToSelect = (args: ModifierMouseArgs) => {
      const x = args.mousePoint.x;
      if (x && x > labelBox.getAdornerAnnotationBorders().x2 - 20) {
        return false;
      }
      return labelBox.checkIsClickedOnAnnotation(
        args.mousePoint.x,
        args.mousePoint.y
      );
    };
    line.clickToSelect = (args: ModifierMouseArgs) => {
      const x = args.mousePoint.x;
      const borders = labelBox.getAdornerAnnotationBorders();
      if ((x && x > borders.x2 - 20 && x < borders.x2) || line.isHidden) {
        return false;
      }

      return line.checkIsClickedOnAnnotation(
        args.mousePoint.x,
        args.mousePoint.y
      );
    };
    this.annotationsAdd = labelBox;
    if (isCustody) {
      let started = new Subscription();
      const handler = () => {
        this._custodyStopOrder.setCustodyOrderButtons(this.xAxisId, {
          ...options,
          xOffset: textLength + 40,
        });
        started && started.unsubscribe();
      };
      started = this.started$.subscribe(handler);
    }

    if (options.undraggable) return;

    this.setLineDragListeners(line, labelBox, options);
  }

  createLinePosition = (
    custodyInfo: IPosition,
    isBreakEvenClicked: boolean,
    stock: ISearchStock
  ) => {
    const pnl = custodyInfo.pnlOpen;
    const linePosition = this._chartHelper.createLinePosition(
      custodyInfo.normalVlPriceAvg,
      custodyInfo.custodyQtty,
      pnl,
      custodyInfo.vlPriceAvg,
      custodyInfo.item || '',
      custodyInfo,
      this.tickSize,
      stock,
      false,
      this._dayTradeService.useDayTradeMode,
      isBreakEvenClicked
    );
    this.createLine(linePosition);
  };

  createLineBreakEven = (
    stock: ISearchStock,
    custodyInfo: IPosition,
    isBreakEvenClicked: boolean
  ) => {
    const breakEvenLine = this._chartHelper.createLineBreakEven(
      custodyInfo,
      stock,
      isBreakEvenClicked
    );
    this.createLine(breakEvenLine);
  };

  hideAnnotationTooltip = (force: boolean = false) => {
    if (
      this.isGrabbing() ||
      ((this._custodyStopOrder.isDragging || this.isEventExpanded) && !force)
    ) {
      return;
    }
    this.showAnnotationTooltip = false;
    this.typeButtons = undefined;
    this.isEventExpanded = false;
    this.annotationClicked = undefined;
    setTimeout(() => {
      this.annotationTooltipVisible = false;
    }, 30);
    this.annotationTooltipLeft = 0;
    this.annotationTooltipTop = 0;
    this.annotationTooltipLabel = [];
    this._mousePoint = undefined;
    this.cdr.detectChanges();
    this.updateCursorToDefaultNotGrab();
  };

  private _updateHoverAnnotationPosition = (
    options: TCandleLineOptions,
    annotation: ClickableAnnotation
  ): void => {
    if (!annotation) {
      return;
    }
    const borders = annotation.getAdornerAnnotationBorders();
    this.annotationTooltipLeft = borders.x2 / 2 + (options.xHoverOffset || 0);
    this.annotationTooltipTop = borders.y2 + 2;
    this.annotationTooltipLabel = options.tooltipLabel || [];
  };

  private _scrollingCustody = (typeArrow: ARROW_TYPES, value: number): void => {
    this.clickOnArrow = true;
    const val =
      typeArrow === ARROW_TYPES.NEGATIVE ? value * 0.95 : value * 1.05;
    this.continueArrowClick = {
      value: val,
      typeArrow,
    };
    const timerIntervals = [
      TIGER_INTERVAL_ENUM.ONE_MINUTE,
      TIGER_INTERVAL_ENUM.TWO_MINUTE,
      TIGER_INTERVAL_ENUM.FIVE_MINUTE,
      TIGER_INTERVAL_ENUM.TEN_MINUTE,
      TIGER_INTERVAL_ENUM.FIFTEEN_MINUTE,
      TIGER_INTERVAL_ENUM.THIRTY_MINUTE,
      TIGER_INTERVAL_ENUM.ONE_HOUR,
    ];
    const isTimerInterval = timerIntervals.includes(
      this.configs.series.interval!!
    );
    if (isTimerInterval) {
      this.changeInterval.emit(TIGER_INTERVAL_ENUM.ONE_DAY);
      return;
    }
    const visibleRange =
      typeArrow === ARROW_TYPES.NEGATIVE
        ? new NumberRange(val, this._candleYAxis.visibleRange.max)
        : new NumberRange(this._candleYAxis.visibleRange.min, val);
    this._candleYAxis.visibleRange = visibleRange;
    this.setChartFreeScale();
    this.clickOnArrow = false;
  };

  makeZoom(typeArrow: ARROW_TYPES, value: number) {
    this.clickOnArrow = false;
    const visibleRange = this._candleYAxis.visibleRange;
    const condition =
      typeArrow === ARROW_TYPES.NEGATIVE
        ? value < visibleRange.min
        : value > visibleRange.max;
    const mouseWheel = this.baseChart.sciChartSurface.chartModifiers
      .asArray()
      .find((modif) => modif instanceof wheelZoomModifier) as wheelZoomModifier;
    this.continueArrowClick = undefined;
    if (!this.scrollBegin && condition) {
      mouseWheel.zoom(160);
      this.continueArrowClick = {
        value,
        typeArrow,
      };
      setTimeout(() => {
        this.makeZoom(typeArrow, value);
      }, 10);
    }
  }

  private tooltipCustody(): string | undefined {
    const box = this.getAnnotationsById<CustomAnnotation>(
      getLineBoxAnnotationId(this._custodyStopOrder.custodyLineId)
    );
    const daytrade = this._customPreferencesService.useDayTradeMode;
    const zero = daytrade
      ? 'Zerar posição {DayTrade}'
      : 'Zerar posição {Swing}';
    if (!box) {
      return DaytradeService.getTextWithTradeMode(zero);
    }
    const createArrow = box.yCoordinateMode === ECoordinateMode.Relative;
    return createArrow ? undefined : DaytradeService.getTextWithTradeMode(zero);
  }

  private _createAnnotationHoverFunction = (
    options: TCandleLineOptions
  ): ((annotation?: ClickableAnnotation, x?: number, y?: number) => void) => {
    return (annotation?: ClickableAnnotation, x?: number) => {
      if (this.isEventExpanded) return;
      if (!annotation) {
        this.showAnnotationTooltip = false;
        this.typeButtons = undefined;
        this.hideAnnotationTooltip();
        this.cdr.detectChanges();
        return;
      }
      const borders = annotation.getAdornerAnnotationBorders();
      const hitXButton = x && x >= borders.x2 - 20;

      if (!x || (x && x > borders.x2)) {
        this.showAnnotationTooltip = false;
        this.typeButtons = undefined;
        this.hideAnnotationTooltip();
        this.cdr.detectChanges();
        return;
      }
      const isBreakEven = options.type == TIGER_LINE_TYPE.BREAK_EVEN;
      const isCustody = options.type == TIGER_LINE_TYPE.CUSTODY;
      const isOrder = options.type == TIGER_LINE_TYPE.ORDER;
      const isAlert = options.type == TIGER_LINE_TYPE.ALERT;
      const isPreviousClose = options.type == TIGER_LINE_TYPE.PREVIOUS_CLOSE;
      if (isPreviousClose || isBreakEven) {
        this.updateChartCursor('pointer');
        this.cdr.detectChanges();
        return;
      }
      const isDragStop =
        options.boxId === `${TIGER_DOUBLE_STOP_ORDER_IDS.GAIN}_line` ||
        options.boxId === `${TIGER_DOUBLE_STOP_ORDER_IDS.LOSS}_line`;
      if (
        !isDragStop &&
        isOrder &&
        !isStartStop(options.data.cd_order_type) &&
        !isDoubleStartStop(options.data.cd_order_type)
      ) {
        const data = { ...deepClone(options.data), price: annotation.y1 };
        options.tooltipLabel = this._chartHelper.getTooltipForType(
          TIGER_LINE_TYPE.ORDER,
          this._stockChartService.generateTooltipOrder(
            data,
            this.stock,
            this.locale,
            this.tickSizeDenominator,
            this.closeline ? this.closeline.y1 : 0
          )
        );
      }
      if (options.tooltipLabel && options.tooltipLabel.length > 0) {
        if (borders.y2 < borders.y1) return;
        this.showAnnotationTooltip = true;
        let label = options.tooltipLabel;
        if (
          hitXButton &&
          options.boxId != TIGER_DOUBLE_STOP_ORDER_IDS.GAIN &&
          options.boxId != TIGER_DOUBLE_STOP_ORDER_IDS.LOSS
        ) {
          if (isCustody) {
            const tooltip = this.tooltipCustody();
            if (!tooltip) {
              this.showAnnotationTooltip = false;
            }
            label = !tooltip ? [] : [tooltip];
          }
          if (isOrder) {
            label = ['Cancelar ordem'];
          }
          if (isAlert) {
            label = ['Cancelar alerta'];
          }
        }
        this.annotationTooltipLabel = deepClone(label || []);
        this.annotationTooltipVisible = true;
        const refAnotationTooltip = document.getElementById(
          `${this.ref}_annotation-tooltip`
        );
        if (options.onCenter) {
          setTimeout(() => {
            if (refAnotationTooltip) {
              const w = refAnotationTooltip.getBoundingClientRect().width!!;
              const left = borders.x2 - 10 - w / 2;
              this.annotationTooltipLeft = left < 0 ? 0 : left;
            }
            this.cdr.detectChanges();
          }, 10);
        } else {
          this.annotationTooltipLeft =
            borders.x2 / 2 + (options.xHoverOffset || 0);
        }
        if (options.onTop) {
          setTimeout(() => {
            if (refAnotationTooltip) {
              const h = refAnotationTooltip.getBoundingClientRect().height!!;
              const top = borders.y1 - h;
              this.annotationTooltipTop = top + this.messageChartHeightRemove;
            }
            this.cdr.detectChanges();
          }, 10);
        } else {
          this.annotationTooltipTop =
            borders.y2 + 2 + this.messageChartHeightRemove;
        }
        this.updateChartCursor('pointer');
        this.cdr.detectChanges();
        return;
      }
      !this._custodyStopOrder.isDragging &&
        (this.annotationTooltipVisible = false);
      this.cdr.detectChanges();
    };
  };

  private setLineDragListeners(
    line: HorizontalLineAnnotation,
    box: ClickableAnnotation,
    options: TCandleLineOptions
  ) {
    line.dragStarted.subscribe(() => {
      if (line.isHidden) return;
      this._lineValueOnDragStart = line.y1;
    });
    line.dragDelta.subscribe(() => {
      if (line.y1 === this._lineValueOnDragStart || line.isHidden) return;
      this._onLineDrag(line, box, options);
    });
    line.dragEnded.subscribe(() => {
      if (line.y1 === this._lineValueOnDragStart || line.isHidden) return;
      this._onLineDragEnd(line, box, options);
    });
  }

  private _onLineDrag(
    line: HorizontalLineAnnotation,
    box: ClickableAnnotation,
    options: TCandleLineOptions,
    isDraggingBox?: boolean,
    auxLine?: HorizontalLineAnnotation
  ) {
    this.isDragging = true;
    const isAlert = options.type == TIGER_LINE_TYPE.ALERT;
    const isOrder = options.type == TIGER_LINE_TYPE.ORDER;
    box.x1 = isAlert ? this._alertXOffset : 0;
    if (isDraggingBox) {
      line.y1 = box.y1;
      if (auxLine) auxLine.y1 = box.y1;
    } else {
      box.y1 = line.y1;
      if (auxLine) auxLine.y1 = line.y1;
    }

    const formattedValue = this.yAxisLabelFormatter(line.y1);
    line.labelValue = formattedValue;

    const replacedId = options.boxId.replace('_line', '');
    if (
      replacedId == TIGER_DOUBLE_STOP_ORDER_IDS.GAIN ||
      replacedId == TIGER_DOUBLE_STOP_ORDER_IDS.LOSS ||
      (options.data && isDoubleStartStop(options.data?.cd_order_type))
    ) {
      this._custodyStopOrder.onLineDrag(options, line);
      return;
    }

    if (options.tooltipLabel) {
      if (isAlert) {
        options.tooltipLabel = this._chartHelper.getTooltipForType(
          TIGER_LINE_TYPE.ALERT,
          {
            value: line.y1,
            currentPrice: this.closeline?.y1 || 0,
            cdSegment: this.cdSegment,
            labelPrecision: this.configs.yAxis.labelPrecision,
            dsAsset: this.dsAsset,
            cdStock: this.papel,
            indicators: this.tooltip.indicators,
          }
        );
      }
      if (isOrder) {
        const temp = +options.data?.price_stop - line.y1;
        this._isDraggingOrder = true;
        const price = this.yAxisLabelFormatter(+options.data?.price - temp);
        const stopPrice = this.yAxisLabelFormatter(line.y1);
        const id = `${options.data?.side}${
          options.data?.cd_order_type == '4' ? '4' : ''
        }`;
        const orderType = ORDER_TYPE_DIC.get(id)!;
        const quantity = formatNumber(
          options.data?.qtty_left,
          this.locale,
          `1.0-0`
        );
        const typeOrder = ORDER_TYPES.find(
          (item: any) => item.cod == options.data?.cd_order_type
        )!;
        options.tooltipLabel = this._chartHelper.getTooltipForType(
          TIGER_LINE_TYPE.ORDER,
          {
            isStartStop: isStartStop(options.data?.cd_order_type),
            description: orderType.description,
            quantity,
            price,
            stopPrice,
            value: line.y1,
            currentPrice: this.closeline?.y1 || 0,
            cdSegment: this.cdSegment,
            labelPrecision: this.configs.yAxis.labelPrecision,
            dsAsset: this.dsAsset,
            cdStock: this.papel,
            type: typeOrder.desc,
            indicators: this.tooltip.indicators,
          }
        );
      }
      box.customHover = this._createAnnotationHoverFunction(options);
    }
  }

  private _onLineDragEnd(
    line: HorizontalLineAnnotation,
    box: CustomAnnotation,
    options: TCandleLineOptions
  ) {
    this.isDragging = false;
    const replacedId = options.boxId.replace('_line', '');
    if (
      replacedId == TIGER_DOUBLE_STOP_ORDER_IDS.GAIN ||
      replacedId == TIGER_DOUBLE_STOP_ORDER_IDS.LOSS ||
      (options.data && isDoubleStartStop(options.data?.cd_order_type))
    ) {
      this._custodyStopOrder.onLineDragEnd(line, {
        ...options,
        boxId: replacedId,
      });
      return;
    }
    if (options.type !== TIGER_LINE_TYPE.ALERT) {
      const id = `${this._lineData[line.id].side}${
        this._lineData[line.id].cd_order_type == '4' ? '4' : ''
      }`;
      const orderType = ORDER_TYPE_DIC.get(id)!;
      this.updateLine(line, {
        color: options.color + '98',
        boxLabel: `${orderType.name[0]} | ${parseFloat(
          this._lineData[line.id].qtty
        )}`,
        value: 0,
        type: options.type,
        boxId: options.boxId,
      });
    }
    this.lineMoved.emit({
      id: line.id,
      value: this.getUnformattedNumber(line.labelValue),
      data: this._lineData[line.id] || null,
      idTradeSystem: this.lineAlertData[line.id]
        ? this.lineAlertData[line.id].idTradeSystem
        : null,
      type: options.type,
    });
  }

  private getUnformattedNumber(valueString: string): number {
    return parseFloat(valueString.replace('.', '').replace(',', '.'));
  }

  private updateCloseLine(
    annotation: HorizontalLineAnnotation,
    options: TCandleLineOptions
  ) {
    annotation.y1 = options.value;
    annotation.showLabel = options.showLabel!;
    annotation.stroke = options.color;
    annotation.axisLabelFill = options.color;
    annotation.labelValue = this.yAxisLabelFormatter(options.value);
  }

  private updateLine = (
    annotation: HorizontalLineAnnotation,
    options: TCandleLineOptions
  ) => {
    if (!annotation) return;
    let box = this.getAnnotationsById<CustomAnnotation>(
      getLineBoxAnnotationId(options.boxId)
    );
    let auxLine = this.getAnnotationsById<CustomAnnotation>(
      getLineAuxAnnotationId(options.boxId)
    );
    const isStop =
      options.boxId.endsWith('_loss_line') ||
      options.boxId.endsWith('_gain_line');
    if (isStop && !box) {
      box = this.getAnnotationsById<CustomAnnotation>(
        getLineBoxAnnotationId(options.boxId.replace('_line', ''))
      );
      auxLine = this.getAnnotationsById<CustomAnnotation>(
        getLineAuxAnnotationId(options.boxId.replace('_line', ''))
      );
    }
    if (!box) return;

    if (options.value > 0) {
      annotation.y1 = options.value;
      annotation.labelValue = this.yAxisLabelFormatter(options.value);
      if (box.yCoordinateMode !== ECoordinateMode.Relative) {
        box.y1 = options.value;
        if (auxLine) auxLine.y1 = options.value;
      }
    }
    if (
      options.data &&
      options.data.cd_order_status === 'ALTE' &&
      auxLine &&
      this.createLineService.canPlotAuxLine(
        options.data.dh_last_update,
        this.configs.series.interval!
      )
    ) {
      const dhUpdate = standardizeDate(options.data.dh_last_update);
      const update = new Date(dhUpdate).setSeconds(0, 0);
      auxLine.x1 = this.createLineService.getCategoryAxisIndex(
        this.xAxis,
        update
      );
    }
    if (options.xOffset !== undefined) {
      box.x1 = options.xOffset;
      annotation.x1 = options.xOffset;
    }
    annotation.isHidden = options.hidden || false;
    box.isHidden = options.hidden || false;
    const id = options.boxId.replace('_line', '');
    const isCustody =
      options.type == TIGER_LINE_TYPE.CUSTODY &&
      id != TIGER_DOUBLE_STOP_ORDER_IDS.GAIN &&
      id != TIGER_DOUBLE_STOP_ORDER_IDS.LOSS;
    const isBreakEven = options.type == TIGER_LINE_TYPE.BREAK_EVEN;
    if (options.boxLabel) {
      const createArrow = box.yCoordinateMode === ECoordinateMode.Relative;
      const boxLabel = createArrow ? '' : options.boxLabel.trim();
      const textColor = options.boxTextColor ?? 'white';
      const textLength = this.textLengthService.getBoxLineTextLength(boxLabel);
      const icon =
        isCustody &&
        id != TIGER_DOUBLE_STOP_ORDER_IDS.GAIN &&
        id != TIGER_DOUBLE_STOP_ORDER_IDS.LOSS
          ? getCardTravel(textColor)
          : undefined;
      if (boxLabel.length) {
        this._custodyStopOrder.updateCustodyCheeps();
      }
      const idAnnotation = getLineBoxAnnotationId(options.boxId).replace(
        /\./g,
        ''
      );
      const arrow = this.getAnnotationArrow(options.value);
      let pnlLength = 0,
        qttyLength = 0,
        pnl = '',
        qtty = '';
      if ((boxLabel && isCustody) || isBreakEven) {
        const getCustodyTextInfo = this.getCustodyTextInfo(
          boxLabel,
          isCustody || !isBreakEven
        );
        pnlLength = getCustodyTextInfo.pnlLength;
        qttyLength = getCustodyTextInfo.qttyLength;
        pnl = getCustodyTextInfo.pnl;
        qtty = getCustodyTextInfo.qtty;
      }
      const svgString = getTextBoxTemplate(
        boxLabel,
        textLength,
        textColor,
        options.color,
        idAnnotation,
        isCustody || isBreakEven,
        icon,
        isBreakEven ? false : true,
        createArrow,
        arrow,
        this._chartHelper.generateOverrideCustodyBox(
          isCustody,
          pnlLength,
          qttyLength,
          pnl,
          qtty
        )
      );
      if (isCustody || isBreakEven) {
        this._stockChartService.updateCustodySVG(
          this.ref,
          idAnnotation,
          svgString
        );
      } else {
        box.svgString = svgString;
      }
      annotation.axisLabelStroke = textColor;
    }

    if (options.color) {
      annotation.stroke = options.color;
      annotation.axisLabelFill = options.color;
    }

    if (options.tooltipLabel && box instanceof ClickableAnnotation) {
      box.customHover = this._createAnnotationHoverFunction(options);
      const x = this._mousePoint?.x || 0;
      const y = this._mousePoint?.y || 0;
      if (isCustody && annotation.checkIsClickedOnAnnotation(x, y)) {
        box.customHover(box, x, y);
      }
    }

    if (options.data) {
      this._lineData[annotation.id] = {
        ...this._lineData[annotation.id],
        ...options.data,
      };
    }
  };

  deleteLine = (lineId: string) => {
    const lineBox = this.getAnnotationsById<ClickableAnnotation>(
      getLineBoxAnnotationId(lineId)
    );
    if (lineBox) {
      lineBox.customClick(0, 0, true);
      return;
    }
    const annotation = this.getAnnotationsById(lineId);
    const annotationAux = this.getAnnotationsById(
      getLineAuxAnnotationId(lineId)
    );
    if (annotation) {
      this.baseChart.sciChartSurface.annotations.remove(annotation);
    }
    if (annotationAux) {
      this.baseChart.sciChartSurface.annotations.remove(annotationAux);
    }
  };

  private setTooltip() {
    this.tooltip.init(this._candleDataSeries);
  }

  openIndicatorSettings(
    type: TIGER_INDICATORS_ENUM,
    lineNumber: string,
    refId?: string,
    anotherBaseChart?: TWebAssemblyChart
  ) {
    this.selectedIndicator = this.indicators.find(
      (indicator) =>
        indicator.type == type && indicator.lineNumber == lineNumber
    );
    const query = refId ? refId : this.ref;
    const element = document.querySelector(`#${query}_indicators`);
    const chart = anotherBaseChart ? anotherBaseChart : this.baseChart;
    this.modalService.open(SettingsModalComponent, {
      data: {
        indicator: this.selectedIndicator,
        baseChart: chart,
        styleModal:
          TIGER_SUB_CHART_INDICATORS.indexOf(type) != -1 ? 'resized-limit' : '',
        refComponent: this.parentRef,
      },
      appendTo: element as HTMLElement,
      backdrop: true,
      keyboard: false,
    });

    this.indicatorSettings.emit(type);
  }

  deleteIndicator(event: any) {
    if (event.type === TIGER_INDICATORS_ENUM.BAR_ELEPHANT) {
      this.resetCandleBarsToOriginalValue();
    }
    this.removeIndicator(event, true);
    this.indicatorDelete.emit(event);
  }

  resetCandleBarsToOriginalValue() {
    const configuration = this.stockChartModalService.getGlobalChartConfigs();
    this.updateCandleColors(
      {
        colorsVelaUp:
          configuration.colorsVelaUp ||
          this.configs.series.candle!.colorsVelaUp!,
        colorsVelaDown:
          configuration.colorsVelaDown ||
          this.configs.series.candle!.colorsVelaDown!,
        colorsBorderUp: this.configs.series.candle!.showBorder
          ? configuration.colorsBorderUp ||
            this.configs.series.candle!.colorsBorderUp!
          : 'transparent',
        colorsBorderDown: this.configs.series.candle!.showBorder
          ? configuration.colorsBorderDown ||
            this.configs.series.candle!.colorsBorderDown!
          : 'transparent',
      },
      true
    );
  }

  setWatermark = (color: string | null = null, nmCompanyValemobi?: string) => {
    this.nmCompanyValemobi = nmCompanyValemobi;
    this.watermarkService.setWatermark(
      this._stockChartService,
      this.baseChart,
      this.xAxisId,
      this.watermarkId,
      this.watermarkBusinessId,
      this.stock,
      this.papel,
      this.configs.series.interval!,
      color,
      this.nmCompanyValemobi
    );
    this.cdr.detectChanges();
  };

  private animation() {
    const latestXIndex = this._candleDataSeries.count() - 1;
    if (latestXIndex && latestXIndex < this.xAxis.visibleRange.max) {
      const shiftedRange = new NumberRange(
        this.xAxis.visibleRange.min + 1,
        this.xAxis.visibleRange.max + 1
      );
      this.xAxis.animateVisibleRange(shiftedRange, 0, easing.inOutQuad);
    }
  }

  private setDataSeries() {
    const idPointLength = this.data.id_point.length;
    if (idPointLength < 10) {
      /**
       * scichart com pouca informação, quebra o labelFormatter,
       * necessário adicionar mais informacoes com NaN.
       */
      let lastDate = this.data.id_point[0];
      for (let i = idPointLength; i < 10; i++) {
        const newDate = new Date(lastDate);
        this.data.id_point.unshift(newDate.setDate(newDate.getDate() - 1));
        this.data.vl_open.unshift(NaN);
        this.data.vl_high.unshift(NaN);
        this.data.vl_low.unshift(NaN);
        this.data.vl_close.unshift(NaN);
        this.data.vl_volume.unshift(NaN);
        this.data.qtty_shares_traded.unshift(NaN);
        this.data.candle_count.unshift(NaN);
        lastDate = newDate.getTime();
      }
    }
    this._candleDataSeries = new OhlcDataSeries(this.baseChart.wasmContext, {
      xValues: this.data.id_point,
      openValues: this.data.vl_open,
      highValues: this.data.vl_high,
      lowValues: this.data.vl_low,
      closeValues: this.data.vl_close,
    });
    this.setCloseLine(
      this.data.vl_open[this.data.vl_open.length - 1],
      this.data.vl_close[this.data.vl_close.length - 1]
    );

    this.setDataSeriesLine();
    this.setTooltip();
  }

  private setDataSeriesLine() {
    this._lineDataSeries = new XyDataSeries(this.baseChart.wasmContext, {
      yValues: this.data[this.sortByLine],
      xValues: this.data.id_point,
    });
  }

  private setVolume() {
    if (!this.data) return;
    const configs = JSON.parse(this._customPreferencesService.chartContextMenu);
    if (this._volumeIndicator) {
      const existSeries = this.getRenderableSeriesById(
        CANDLE_IDS.VOLUME_SERIES
      );
      this.renderableSeriesRemove = existSeries;
    }
    this._volumeIndicator = new TigerChartIndicatorVolume(
      this.data,
      this._candleDataSeries,
      this.stock,
      this.stockChartModalService
    );
    this._volumeIndicator.create({
      base: this.baseChart,
      yAxisId: this._axis2Id,
      xAxisId: this.xAxisId,
      tickSize: this.configs.yAxis.labelPrecision || 0,
    });
    this._volumeIndicator.isVisible = configs?.volumeVisibility;
  }

  private setChartColumns() {
    if (!this.data) {
      return;
    }
    this.processComponent();
  }

  private keyPressActions() {
    this.altShiftOrderService.listener(this.baseChart, this.ref);
  }

  private openContextMenu(
    event: MouseEvent,
    pointValueY: number,
    closeLineY: number,
    fromPlus: boolean = false,
    mousePoint?: Point
  ) {
    const typeOrder =
      pointValueY > closeLineY
        ? CONTEXT_MENU_ORDER_TYPE.SELL
        : CONTEXT_MENU_ORDER_TYPE.BUY;
    const typeOrderClass =
      pointValueY > closeLineY ? SIDE_TEXT_COLOR.S : SIDE_TEXT_COLOR.B;
    const typeAlertClass = ALERT_TEXT_COLOR;
    const formatted = this._chartHelper.yAxisLabelFormatter({
      value: pointValueY,
      cdSegment: this.cdSegment,
      labelPrecision: this.configs.yAxis.labelPrecision,
      dsAsset: this.dsAsset,
      cdStock: this.papel,
    });

    if (this.configs.enableContextMenu) {
      this.mousePoint = mousePoint;
      this.pointValueY = pointValueY;
      const { clientX, clientY } = event;

      TigerChartContextMenuComponent.openContextMenu(
        this.contextMenuService,
        this.refComponent,
        { clientX, clientY },
        this._isTour,
        true,
        this._volumeIndicator && this._volumeIndicator.isVisible,
        typeOrder,
        typeOrderClass,
        this.standardLot,
        formatted,
        +pointValueY.toFixed(this.tickSize),
        typeAlertClass,
        this._custodyStopOrder.custodyQuantity != 0 &&
          this._custodyStopOrder.canShowToggleVisibilityButton,
        !this._custodyStopOrder.hasHidden,
        isTypeStockIndex(this.stock.type),
        fromPlus
      );

      this.rightClickEvent.emit({ showModal: true });
      return;
    }
    this.rightClickEvent.emit({ mouseEvent: event });
  }

  private emitClickOrder(
    itemMenu: CONTEXT_MENU_ITEM_ENUM,
    pointValueY: number,
    typeOrder?: CONTEXT_MENU_ORDER_TYPE,
    isInverse: boolean = false
  ) {
    if (isInverse && typeOrder) {
      typeOrder =
        typeOrder === CONTEXT_MENU_ORDER_TYPE.BUY
          ? CONTEXT_MENU_ORDER_TYPE.SELL
          : CONTEXT_MENU_ORDER_TYPE.BUY;
    }
    const formatted = this._chartHelper.yAxisLabelFormatter({
      value: pointValueY,
      cdSegment: this.cdSegment,
      labelPrecision: this.configs.yAxis.labelPrecision,
      dsAsset: this.dsAsset,
      cdStock: this.papel,
    });
    let value = parseFloat(
      pointValueY.toFixed(this.configs.yAxis.labelPrecision)
    );

    if (this.cdSegment == '9999') {
      value = parseFloat(formatted.replace('.', '').replace(',', '.'));
    }

    let paramTypeOrder = typeOrder;
    if (itemMenu === CONTEXT_MENU_ITEM_ENUM.TYPE_STOP_ORDER) {
      paramTypeOrder =
        typeOrder == CONTEXT_MENU_ORDER_TYPE.SELL
          ? CONTEXT_MENU_ORDER_TYPE.BUY
          : CONTEXT_MENU_ORDER_TYPE.SELL;
    }

    this.rightClickEvent.emit({
      itemMenu,
      typeOrder: paramTypeOrder,
      data: {
        pointValueY: value,
      },
    });
  }

  private rightClickActions() {
    if (this.configs.enableRightClickActions) {
      this.baseChart.sciChartSurface.domCanvas2D.addEventListener(
        'contextmenu',
        (event) => {
          const closeLineY = this.getAnnotationsById(this._closeLineId).y1;
          const mainSerie = this.getRenderableSeriesById(CANDLE_IDS.MAIN_SERIE);
          const pointValueY = this.cursorModifier?.hitTestRenderableSeries(
            mainSerie,
            {
              y: event.offsetY,
              x: event.offsetX,
            }
          ).hitTestPointValues.y;
          this.openContextMenu(event, pointValueY!!, closeLineY);
        }
      );
    }
  }

  private _saveContextMenuConfig() {
    if (!this._volumeIndicator) return;
    this._customPreferencesService.chartContextMenu = JSON.stringify({
      volumeVisibility: this._volumeIndicator.isVisible,
      hideStopOrderCheeps: this._custodyStopOrder.isHidden,
    });
  }

  private arrowNavigate(key: string, velocity: number = 10) {
    const currentTimestamp = new Date().getTime();
    if (currentTimestamp - this._lastArrowNavigationTimestamp <= 300) return;
    this._lastArrowNavigationTimestamp = currentTimestamp;
    const diff = key == TIGER_KEYPRESS_ARROWS.ARROW_LEFT ? -velocity : velocity;
    const shiftedRange = new NumberRange(
      this.xAxis.visibleRange.min + diff,
      this.xAxis.visibleRange.max + diff
    );
    this.xAxis.animateVisibleRange(shiftedRange, 300, easing.inOutQuad);
  }

  private zoomNavigate(key: TIGER_KEYPRESS_ARROWS, diff: number = 5) {
    const delta = key == TIGER_KEYPRESS_ARROWS.ZOOM_IN ? -diff : diff;
    const wheelModifier = this.getModifierById(
      this.mouseWheelId
    ) as wheelZoomModifier;
    wheelModifier.makeGrow(delta);
  }

  private changeTypeSeries() {
    const id = CANDLE_IDS.MAIN_SERIE;
    const xAxisId = this.xAxisId;
    const index = this.baseChart.sciChartSurface.renderableSeries
      .asArray()
      .findIndex((series) => series.id == id);
    if (index > -1) {
      this.baseChart.sciChartSurface.renderableSeries.removeAt(index);
    }
    switch (this.configs.series.type) {
      case TIGER_TYPE_CHART_ENUM.BAR:
        this.renderableSeriesAdd = new FastOhlcRenderableSeries(
          this.baseChart.wasmContext,
          {
            strokeThickness: this.configs.series.bar!.strokeThickness,
            dataSeries: this._candleDataSeries as OhlcDataSeries,
            dataPointWidth: this.configs.series.bar!.dataPointWidth,
            strokeDown: this.configs.series.bar!.colorsDown,
            strokeUp: this.configs.series.bar!.colorsUp,
            id,
            xAxisId,
            paletteProvider: new CandlePaletteProvider(),
          }
        );
        break;

      case TIGER_TYPE_CHART_ENUM.LINE:
        this.renderableSeriesAdd = new FastLineRenderableSeries(
          this.baseChart.wasmContext,
          {
            strokeThickness: this.configs.series.line!.strokeThickness,
            dataSeries: this._lineDataSeries,
            stroke: this.configs.series.line!.color,
            id,
            xAxisId,
          }
        );
        break;
      case TIGER_TYPE_CHART_ENUM.AREA:
        this.renderableSeriesAdd = new FastMountainRenderableSeries(
          this.baseChart.wasmContext,
          {
            fill: hex2rgba(this.configs.series.area!.grandientHexColor!, 1),
            fillLinearGradient: new GradientParams(
              new Point(0, 0),
              new Point(0, 1),
              [
                {
                  color: hex2rgba(
                    this.configs.series.area!.grandientHexColor!,
                    this.configs.series.area!.grandientOpacityUp
                  ),
                  offset: 0,
                },
                {
                  color: hex2rgba(
                    this.configs.series.area!.grandientHexColor!,
                    this.configs.series.area!.grandientOpacityDown
                  ),
                  offset: 1,
                },
              ]
            ),
            stroke: this.configs.series.area!.lineColor,
            strokeThickness: this.configs.series.area!.strokeThickness,
            zeroLineY: this.isLog ? 0.01 : 0,
            dataSeries: this._lineDataSeries,
            id,
            xAxisId,
          }
        );
        break;
      default:
        {
          const configuration =
            this.stockChartModalService.getGlobalChartConfigs();
          this.renderableSeriesAdd = new FastCandlestickRenderableSeries(
            this.baseChart.wasmContext,
            {
              strokeThickness: this.configs.series.candle!.strokeThickness,
              dataSeries: this._candleDataSeries as OhlcDataSeries,
              dataPointWidth: this.configs.series.candle!.dataPointWidth,
              brushDown:
                configuration.colorsVelaDown ||
                this.configs.series.candle!.colorsVelaDown,
              brushUp:
                configuration.colorsVelaUp ||
                this.configs.series.candle!.colorsVelaUp,
              strokeDown: this.configs.series.candle!.showBorder
                ? configuration.colorsBorderDown ||
                  this.configs.series.candle!.colorsBorderDown
                : 'transparent',
              strokeUp: this.configs.series.candle!.showBorder
                ? configuration.colorsBorderUp ||
                  this.configs.series.candle!.colorsBorderUp
                : 'transparent',
              id,
              xAxisId,
              paletteProvider: new CandlePaletteProvider(),
            }
          );
          this.redrawElephantBars();
        }
        break;
    }
  }

  private redrawElephantBars() {
    const elephantIndicator = this.getElephantBarIndicator();
    if (elephantIndicator) {
      this.setCandleElephantBarColors();
      elephantIndicator.usePaletteElephant(elephantIndicator.isVisible);
      elephantIndicator.updatePoints();
    }
  }

  private getElephantBarIndicator() {
    return this.indicators.find(
      (indicator) => indicator.type === TIGER_INDICATORS_ENUM.BAR_ELEPHANT
    ) as TigerChartIndicatorElephantBar;
  }

  private setCloseLine(vl_open: number, vl_close: number) {
    if (!this.configs.closeLine.enable) {
      if (this.closeline) {
        this.annotationsRemove = this.closeline;
        this.closeline = undefined;
        this.vlCloseLine = undefined;
        this.vlOpen = undefined;
      }
      if (this._closelineTimer) {
        this.annotationsRemove = this._closelineTimer;
        this._closelineTimer = undefined;
      }
      if (this._closelineBox) {
        this.annotationsRemove = this._closelineBox;
        this._closelineBox = undefined;
      }
      return;
    }
    let color = CHART_COLORS.BRAND_PRIMARY;
    if (vl_close != vl_open) {
      color =
        vl_close > vl_open
          ? this.configs.closeLine.color!.up
          : this.configs.closeLine.color!.down;
    }
    this.createCloseTimerAnnotation(vl_close, color);
    this.vlCloseLine = vl_close;
    this.vlOpen = vl_open;
    this.cdr.detectChanges();
    this.closeline = this.createLine({
      value: vl_close,
      label: this.yAxisLabelFormatter(vl_close),
      color,
      type: TIGER_LINE_TYPE.CLOSE,
      strokeThickness: this.configs.closeLine.strokeThickness,
      boxTextColor: CHART_COLORS.BLACK,
      showLabel: this.configs.closeLine.showLabel,
      boxId: this._closeLineId,
      undraggable: true,
    });
  }

  deleteCloseLine() {
    if (this.closeline) this.annotationsRemove = this.closeline;
    if (this._closelineTimer) this.annotationsRemove = this._closelineTimer;
    if (this._closelineBox) this.annotationsRemove = this._closelineBox;
  }

  private insertAlerts(alerts: IAlert[]) {
    alerts.forEach((alert) => {
      const boxId = `${this._newLineId}_${alert.id_trade_system}`;
      this.lineAlertData[boxId] = this._chartHelper.createLineAlert(
        alert.vl_value,
        boxId,
        alert.id_trade_system,
        this.closeline?.y1 || 0,
        this.tickSize,
        this.cdSegment || '',
        this.dsAsset || '',
        this.papel,
        this.tooltip.indicators
      );
      this.lineAlertData[boxId].firstBoxId = boxId;
      this.createLine(this.lineAlertData[boxId]);
    });
  }

  updateLineAlertData(
    boxId: string,
    newAlert: IAlert,
    updateCharts: boolean = false
  ) {
    if (!this.lineAlertData[boxId]) {
      this.insertAlerts([newAlert]);
      return;
    }
    const newBoxId = `${this._newLineId}_${newAlert.id_trade_system}`;
    this.lineAlertData[newBoxId] = this.lineAlertData[boxId];
    this.lineAlertData[newBoxId].boxId = newBoxId;
    this.lineAlertData[newBoxId].idTradeSystem = newAlert.id_trade_system;
    if (updateCharts) {
      const newBox = this._chartHelper.createLineAlert(
        newAlert.vl_value,
        boxId,
        newAlert.id_trade_system,
        this.closeline?.y1 || 0,
        this.tickSize,
        this.cdSegment || '',
        this.dsAsset || '',
        this.papel,
        this.tooltip.indicators
      );
      const line = this.getAnnotationsById<HorizontalLineAnnotation>(
        this.lineAlertData[newBoxId].firstBoxId
      );
      line.y1 = newAlert.vl_value;
      line.labelValue = this.yAxisLabelFormatter(newAlert.vl_value);
      const box = this.getAnnotationsById<ClickableAnnotation>(
        getLineBoxAnnotationId(this.lineAlertData[newBoxId].firstBoxId)
      );
      box.customHover = this._createAnnotationHoverFunction(newBox);
      box.y1 = line.y1;
    }
  }

  resetVisibilityChart() {
    if (!this._firstVisibleRange) {
      return;
    }
    const xAxes = this.baseChart.sciChartSurface.xAxes.getById(this.xAxisId);
    const diffConfigs =
      this.configs.xAxis.visibleRange!!.max -
      this.configs.xAxis.visibleRange!!.min;
    const diffFirst = this._firstVisibleRange.max - this._firstVisibleRange.min;
    let newRange = new NumberRange(
      this._firstVisibleRange.min,
      this._firstVisibleRange.max
    );
    if (diffConfigs != diffFirst) {
      const grow = diffConfigs * this.configs.xAxis.axisGrowBy!!;
      let diff = diffConfigs;
      let plusMax = 10;
      if (diffConfigs < diffFirst) {
        diff = grow + diffConfigs;
        plusMax = -grow;
      }
      newRange = new NumberRange(
        this._firstVisibleRange.max - diff,
        this._firstVisibleRange.max + plusMax
      );
    }
    xAxes.animateVisibleRange(newRange, 0, easing.inOutQuad, () => {
      this.showControlBack = false;
    });
  }

  move(keyElement: string) {
    const dragRect = document
      .getElementById(`divider_${keyElement}`)!!
      .getBoundingClientRect();
    const targetRect = document
      .getElementById(`subchart-${keyElement}`)!!
      .getBoundingClientRect();
    const height = dragRect.top - targetRect.top + dragRect.height;
    this.subCharts.set(keyElement, {
      height: this.subCharts.get(keyElement)!!.height - height,
    });
    const subChartIndex = this.subCharts
      .keys()
      .findIndex((k) => k === keyElement);
    if (subChartIndex > 0) {
      const previousIndex = subChartIndex - 1;
      const subChartPrevious = this.subCharts.values()[previousIndex];
      const keyPrevious = this.subCharts.keys()[previousIndex];
      const newHeight = height < 0 ? Math.abs(height) : -height;
      this.subCharts.set(keyPrevious, {
        height: subChartPrevious.height - newHeight,
      });
    } else {
      this.subChartHeightRemove -= height;
    }
    this.transform = {
      x: 0,
      y: 0,
    };
  }

  updateSubChartsConfigs(
    keyPrincipal: string,
    updateHeightPrincipalChart?: boolean,
    newHeight?: boolean,
    height?: number
  ) {
    if (height) {
      this.updateSubChartsHeight(
        keyPrincipal,
        height,
        updateHeightPrincipalChart,
        newHeight
      );
    }
    /*
      A logica abaixo serve para atualizar a posicao dos divisores dos subcharts.
     */
    this.updateSubChartsDividerPosition(keyPrincipal);
  }

  private updateSubChartsDividerPosition(keyPrincipal: string) {
    let heightTotal = this.configs.height - this.subChartHeightRemove;
    this.subCharts.keys().forEach((key) => {
      if (key === keyPrincipal) {
        return;
      }
      const heightSubChart = this.subCharts.get(key)!!.height;
      const percent = (heightTotal * 100) / this.configs.height - 1;
      this.subCharts.set(key, { dividerPosition: percent });
      heightTotal += heightSubChart;
    });
    const percent = (heightTotal * 100) / this.configs.height - 1;
    this.subCharts.set(keyPrincipal, {
      dividerPosition: percent,
    });
  }

  private updateSubChartsHeight(
    keyPrincipal: string,
    height: number,
    updateHeightPrincipalChart?: boolean,
    newHeight?: boolean
  ) {
    this.subCharts.keys().forEach((key) => {
      if (key === keyPrincipal) {
        return;
      }
      if (newHeight) {
        if (updateHeightPrincipalChart) {
          this.subChartHeightRemove -=
            this.subCharts.get(key)!!.height - height;
        }
        this.subCharts.set(key, { height: height });
      } else {
        this.subCharts.set(key, {
          height: this.subCharts.get(key)!!.height - height,
        });
      }
    });
  }

  private updateInfoCharts(newGeralHeight: number) {
    this.subChartHeightRemove = 0;
    this.subCharts.keys().forEach((key) => {
      const subChart = this.subCharts.get(key)!!;
      const percent = (subChart.height * 100) / this.configs.height / 100;
      const height = percent * newGeralHeight;
      this.subCharts.set(key, { height: height });
      this.subChartHeightRemove += height;
    });
  }

  showTools() {
    this.widthRemove = this.withTools
      ? this.widthRemove + 50
      : this.widthRemove - 50;
    this._custodyStopOrder.drawToolWidth = this.withTools ? 50 : 0;
    if (!this.configsToolsToRedraw.length) {
      this.configsToolsToRedraw = deepClone(
        this.drawToolsService.getStockDraws()
      ) as saveConfiguration[];
    }
    setTimeout(() => {
      this.drawToolsService.drawResize = [];
      this.drawToolsService.drawDict.values().forEach((draw) => {
        if (draw.isSvgAnnotation) {
          this.drawToolsService.resizeChartRenewSVG(
            this.baseChart,
            draw,
            this.ref,
            this.configs,
            this.chartHeight,
            this.stock,
            this.configsToolsToRedraw
          );
        }
      });
    }, 10);
  }

  changeTypeAxis() {
    const configuration = this.stockChartModalService.getGlobalChartConfigs();
    this.setYaxis(this._axis2Id, configuration, true);
    this.subscriberCandleYAxisChanges();
    this._candleYAxis.labelProvider.numericFormat =
      this.configs.yAxis.labelFormat!;
    this._candleYAxis.labelProvider.precision = this._chartHelper._getTicksize({
      value: 0,
      cdSegment: this.cdSegment,
      labelPrecision: this.configs.yAxis.labelPrecision,
      dsAsset: this.dsAsset,
      cdStock: this.papel,
      indicators: this.tooltip.indicators,
    });
    this.xAxisBook.labelProvider.numericFormat =
      this.configs.yAxis.labelFormat!;
    this.xAxisBook.labelProvider.precision = this._chartHelper._getTicksize({
      value: 0,
      cdSegment: this.cdSegment,
      labelPrecision: this.configs.yAxis.labelPrecision,
      dsAsset: this.dsAsset,
      cdStock: this.papel,
      indicators: this.tooltip.indicators,
    });
    this.updateWindowedView();
    this.repositionXAxisBook(this._candleYAxis.visibleRange);
  }

  changeFreeScale() {
    this._candleYAxis.autoRange = this.isFreeScale
      ? EAutoRange.Once
      : EAutoRange.Always;
    if (!this.isFreeScale) {
      this.updateWindowedView(false);
    }
    this.changeZoomPanDirection();
  }

  updateTimerPosition(value: number, color: string) {
    const timerAnnotation = this.getAnnotationsById<HorizontalLineAnnotation>(
      this._closeLineIdTimer
    );
    const boxAnnotation = this.getAnnotationsById<HorizontalLineAnnotation>(
      this._closeLineIdBox
    );
    if (
      timerAnnotation &&
      boxAnnotation &&
      this._closelineTimer &&
      this._closelineBox &&
      this._candleTimeLeft
    ) {
      const y =
        this._candleYAxis
          .getCurrentCoordinateCalculator()
          .getCoordinate(value) + 14;
      const newY = this._candleYAxis
        .getCurrentCoordinateCalculator()
        .getDataValue(y);
      timerAnnotation.y1 = newY;
      timerAnnotation.axisLabelFill = color;
      boxAnnotation.axisLabelFill = color;
      const textEncoder = new TextEncoder();
      const labelValue = this.createLineService.getFormattedTimer(
        this._candleTimeLeft
      );
      const encoderClose = textEncoder.encode(this.closeline!.labelValue);
      const encoderTimer = textEncoder.encode(labelValue);
      const resultEncoderClose = encoderClose.reduce((a, b) => a + b);
      const resultEncoderTimer = encoderTimer.reduce((a, b) => a + b);
      if (resultEncoderTimer < resultEncoderClose) {
        boxAnnotation.labelValue = this.closeline!.labelValue;
        boxAnnotation.y1 = newY;
      } else if (resultEncoderTimer > resultEncoderClose) {
        boxAnnotation.labelValue = labelValue;
        boxAnnotation.y1 = value;
      } else {
        boxAnnotation.labelValue = this.closeline!.labelValue;
        boxAnnotation.y1 = newY;
      }
      timerAnnotation.labelValue = labelValue;
      this.annotationsRemove = this._closelineBox;
      this.annotationsAdd = this._closelineBox;
      this.annotationsRemove = this.closeline!;
      this.annotationsAdd = this.closeline!;
      this.updateCloseLineTimer();
    }
  }

  private updateCloseLineTimer() {
    if (this._closelineTimer) {
      this.annotationsRemove = this._closelineTimer;
      this.annotationsAdd = this._closelineTimer;
    }
  }

  createCloseTimerAnnotation(vlClose: number, color: string) {
    if (
      !isNullOrUndefined(vlClose) &&
      this._showClosePriceTimer &&
      this._candleTimeLeft
    ) {
      const timer = this.createLineService.getFormattedTimer(
        this._candleTimeLeft
      );
      const y =
        this._candleYAxis
          .getCurrentCoordinateCalculator()
          .getCoordinate(vlClose) + 14;
      const newY = this._candleYAxis
        .getCurrentCoordinateCalculator()
        .getDataValue(y);
      this._closelineTimer = this.createLine({
        value: newY,
        label: timer,
        color,
        type: TIGER_LINE_TYPE.CLOSE,
        strokeThickness: this.configs.closeLine.strokeThickness,
        boxTextColor: CHART_COLORS.BLACK,
        showLabel: this.configs.closeLine.showLabel,
        boxId: this._closeLineIdTimer,
        undraggable: true,
      });
      this._closelineBox = this.createLine({
        value: vlClose,
        label: this.yAxisLabelFormatter(vlClose),
        color,
        type: TIGER_LINE_TYPE.CLOSE,
        strokeThickness: this.configs.closeLine.strokeThickness,
        boxTextColor: 'transparent',
        showLabel: this.configs.closeLine.showLabel,
        boxId: this._closeLineIdBox,
        undraggable: true,
      });
    }
  }

  hideStockMessage() {
    this.showStockMessage.clear();
    this.forceHideStockMessage = true;
    this.messageChartHeightRemove = 0;
    this.cdr.detectChanges();
  }

  updateVisibleRangeChart(data: { control: TIGER_KEYPRESS_ARROWS }) {
    if (
      data.control === TIGER_KEYPRESS_ARROWS.ARROW_LEFT ||
      data.control === TIGER_KEYPRESS_ARROWS.ARROW_RIGHT
    ) {
      this.arrowNavigate(data.control, 1);
    }
    if (
      data.control === TIGER_KEYPRESS_ARROWS.ZOOM_IN ||
      data.control === TIGER_KEYPRESS_ARROWS.ZOOM_OUT
    ) {
      this.zoomNavigate(data.control);
    }
    if (data.control === TIGER_KEYPRESS_ARROWS.REFRESH) {
      this.freeScaleEmmitter.emit({ isFreeScale: false, updateMetadata: true });
      const newVisibleRange = new NumberRange(
        this._candleDataSeries.count() - 30,
        this._candleDataSeries.count() + 10
      );
      this.xAxis.animateVisibleRange(newVisibleRange, 0);
      this.updateWindowedView();
    }
  }

  private resultProcess = (data: any): void => {
    this.bookData = data;
    if (this.showChartBook && data) {
      if (!this.isFirstDataStreaming) {
        this.columnBarService.hashBook = {};
      }
      const book = this.columnBarService.addBook(
        this.baseChart,
        data,
        this.xAxisBookId,
        this._idBookBarYAxis,
        false
      );
      this._bookValues = book.yValuesObj;
      this._bookIndicator = book.renderableSeries;
      if (!this.isFirstDataStreaming) {
        this.isFirstDataStreaming = true;
        this.repositionXAxisBook(this._candleYAxis.visibleRange);
      }
    }
    const priceBuy = toValue(data.list[0].priceBuy);
    const priceSell = toValue(data.list[0].priceSell);
    if (this.showBidAsk && data && !isNaN(priceBuy) && !isNaN(priceSell)) {
      this.createLineService.plotBidAsk(priceBuy, priceSell);
    }
  };

  private processComponent() {
    if (this.stock) {
      if (this.showChartBook || this.showBidAsk) {
        //book
        this.subscribeBookChannel();
      }
      if (this.showChartVolumeAtPrice) {
        //volumeXPrice
        this.subscribeVolumeChannel();
      }
    }
  }

  private async subscribeBookChannel() {
    if (!this.stock || this.bookParamsTable) return;
    this.dataTable = [];
    this.bookParamsTable = {
      itemsArray: this.itemsArray,
    };
    if (!this.bookCurrentSubscription) await this.startBookStreaming();
    this.bookCurrentSubscription &&
      this.bookCurrentSubscription.snapshot(this.bookParamsTable.itemsArray);
  }

  private unsubBookChannel(force: boolean = false) {
    this._unsubscribeBookChannel(force);
  }

  private _unsubscribeBookChannel(force: boolean = false) {
    if ((this.showChartBook || this.showBidAsk) && !force) return;
    this.isFirstDataStreaming = false;
    this.bookParamsTable = undefined;
    this.bookData = undefined;
  }

  private _removeStreamingBook() {
    this.unsubBookChannel(true);
    this.bookCurrentSubscription && this.bookCurrentSubscription.close();
    this.bookCurrentSubscription = undefined;
  }

  private _removeStreamingQuote() {
    this.quoteParamsResume = undefined;
  }

  private _unsubscribeVolumeChannel() {
    if (!this.volumeParamsTable) return;
    this.isFirstDataStreaming = false;
    this.volumeParamsTable = undefined;
    this.volumeAtPriceDict.clear();
    this.columnBarService.clearColumnBar(this.baseChart);
    this.isEndendVolumeAtPrice = false;
    this._volumeAtPriceIndicator = undefined;
  }

  private _removeStreamingVolume() {
    this._unsubscribeVolumeChannel();
    this.volumeChannelSubscription && this.volumeChannelSubscription.close();
    this.volumeChannelSubscription = undefined;
  }

  protected subscribeOrderChannel() {
    this.ordersService
      .getOrdersStream()
      .pipe(
        takeUntil(this._onDestroy),
        filter((data) => {
          return this.filtersService.orderChannelFilter(
            data,
            this.stock,
            this.account
          );
        })
      )
      .subscribe(this.searchPositionAndOrder);
    this.ordersService.getOrdersCached();
  }

  private channelQuoteHandler = (payload: any) => {
    const itemSubscribed = this.quoteParamsResume?.itemsArray[0];
    const res = payload.get(itemSubscribed);

    if (!res || res.isSnap || res.isEndOfSnap || !this.quoteParamsResume) {
      return;
    }
    this.dataResume = new DataResume(
      this.stock,
      res,
      this.dataResume?.getRawValues() || {}
    );
    this._custodyStopOrder &&
      (this._custodyStopOrder.contractMultiplier = parseFloat(
        res.contract_multiplier
      ));
    this.previousVlClose = res.preco_fechamento
      ? res.preco_fechamento
      : this.previousVlClose;
    this.plotPreviousVlClose();
  };

  private channelCustodyHandler = (payload: any) => {
    const key = this.custodyChannel.itemsArray[0];
    const items = payload.get(key);

    items?.forEach((data: any) => {
      if (!data || data.isEndOfSnap) return;
      if (data.command === 'DELETE') {
        this.positions.delete(data.key);
        return;
      }

      this.positions.set(data.key, data);
    });
  };

  private plotPreviousVlClose() {
    if (
      this.baseChart &&
      this.previousVlClose &&
      parseFloat(this.previousVlClose.toString()) &&
      this.showPreviousClose
    ) {
      this.createLineService.plotPreviousVlClose(this.previousVlClose);
    }
  }

  deletePreviousVlClose() {
    this.createLineService.deletePreviousVlClose();
  }

  deleteBidAsk() {
    this.createLineService.deleteBidAsk();
  }

  private isValidPayload = (payload: any): any => {
    return payload.get(this.volumeParamsTable?.itemsArray[0]);
  };

  private createVolumeData(payload: any): VolumeData {
    payload = deepClone(payload);
    return {
      command: payload.command,
      item: payload.item,
      key: parseFloat(payload.key),
      qtty_buyer: parseFloat(payload.qtty_buyer),
      qtty_direct: parseFloat(payload.qtty_direct),
      qtty_rlp: parseFloat(payload.qtty_rlp),
      qtty_seller: parseFloat(payload.qtty_seller),
      qtty_total: parseFloat(payload.qtty_total),
      lastAdd: false,
    };
  }
  private updateMaxVolume(qttyTotal: number): void {
    if (qttyTotal > this.maxVolumeAtPrice) {
      this.redrawVolumeAtPrice = true;
    }
    this.maxVolumeAtPrice = isNaN(this.maxVolumeAtPrice)
      ? qttyTotal
      : Math.max(this.maxVolumeAtPrice, qttyTotal);
  }

  private updateYAxisVisibility(zoomCandle: boolean = true): void {
    if (
      this._barVolumeAtPriceYAxis &&
      this._barVolumeAtPriceYAxis.visibleRange.max === 10 &&
      this._barVolumeAtPriceYAxis.visibleRange.min === 0
    ) {
      this._barVolumeAtPriceYAxis.visibleRange = new NumberRange(0, 550);
      zoomCandle && this._candleYAxis.zoomBy(0, 1.5);
    }
  }

  private checkAndRepositionXAxisBook(volumeData: VolumeData): void {
    if (
      this._candleYAxis &&
      (!this.isFirstDataStreaming ||
        (!this.volumeAtPriceDict.has(volumeData.key) &&
          this.isFirstDataStreaming))
    ) {
      this.isFirstDataStreaming = true;
      this.repositionXAxisBook(this._candleYAxis.visibleRange);
    }
  }

  private channelVolumeHandler = (
    data: Map<string, IVolumeEventData[]>
  ): void => {
    const payload: IVolumeEventData[] | undefined = this.isValidPayload(data);
    if (!payload) return;
    for (let i = 0; i < payload.length; i++) {
      const item = payload[i];
      if (item.isEndOfSnap) {
        this.instanceVolumeAtPrice('CREATE');
        this.isEndendVolumeAtPrice = true;
        return;
      }
      const volumeData = this.createVolumeData(item);
      const qtty = isNaN(volumeData.qtty_total) ? 0 : volumeData.qtty_total;
      this.updateMaxVolume(qtty);
      this.updateYAxisVisibility();
      this.checkAndRepositionXAxisBook(volumeData);
      this.volumeAtPriceDict.set(volumeData.key, volumeData);
      if (this.redrawVolumeAtPrice && this.isEndendVolumeAtPrice) {
        this.redrawVolumeAtPrice = false;
        this.instanceVolumeAtPrice('UPDATE');
      }
      if (this.isEndendVolumeAtPrice) {
        this.instanceVolumeAtPrice('UPDATE', volumeData);
      }
    }
  };

  private instanceVolumeAtPrice(
    type: 'CREATE' | 'UPDATE',
    volumeData?: VolumeData
  ) {
    this.volumeAtPriceService.instance$.next({
      stock: this.stock,
      type,
      baseChart: this.baseChart,
      xAxisId: this.xAxisBookId,
      yAxisId: this._idVolumeAtPriceBarYAxis,
      volumeAtPriceDict: this.volumeAtPriceDict,
      ref: this.refComponent,
      maxVolumeAtPrice: this.maxVolumeAtPrice,
      volumeData,
    });
  }

  private tryRemoveParcialFlag(order: IOrderChartData) {
    const info = this.executedOrdersService.executedOrdersIndexes(
      order,
      this.xAxisCalculator,
      this.candleDataSeries,
      this.configs.series.interval! === TIGER_INTERVAL_ENUM.ONE_MONTH
    );
    const ann = this.baseChart.sciChartSurface.annotations.getById(
      `${execOrderId}_${info.index}_pnl_parcial`
    ) as ExecOrderFlagAnnotation;
    if (ann) {
      this.execOrderFlagParcialAnnotation
        .get(info.index)
        ?.annotationOrder.delete(order.idOrder!);
      ann.annotationOrder.delete(order.idOrder!);
      this.execOrderNotPlotted.delete(order.idOrder!);
      if (!ann.annotationOrder.size()) {
        this.baseChart.sciChartSurface.annotations.remove(ann);
        this.execOrderFlagParcialAnnotation.delete(info.index);
      }
    }
  }
  execOrderNotPlotted = new Dictionary<IOrderChartData>();
  private _channelOrderChartHandler = (
    payload: Map<any, IOrderChartData[]>
  ) => {
    const mainSeries = this.getRenderableSeriesById(CANDLE_IDS.MAIN_SERIE);
    if (!this.orderChartParams || !mainSeries) return;
    const items = payload.get(this.orderChartParams.itemsArray[0]) || [];
    this.plotExecOrders(items);
  };

  private execOrderAnnotationsUpdate(
    annotation: ExecOrderFlagAnnotation,
    updated: boolean,
    annotations: (ExecOrderFlagAnnotation | ExecOrderAnnotation)[],
    isOpen: boolean
  ) {
    if (
      annotation &&
      ((this.showFlagOrders && !isOpen) || (this.showOpenFlag && isOpen))
    ) {
      if (updated) {
        annotations = this.replacePreviousAnnotation(annotations, annotation);
      } else {
        annotations.push(annotation);
      }
    }
    return annotations;
  }

  plotExecOrders(orders: IOrderChartData[]) {
    let annotations: Array<ExecOrderAnnotation | ExecOrderFlagAnnotation> = [];
    orders.forEach((data: IOrderChartData) => {
      if (!this.showExecOrders || !data || data.isEndOfSnap) return;
      data.idOrder = data.key.split('.')[2];
      data.formatted_date = this.executedOrdersService.formatDate(
        data.dh_last_update.replace(/\D/g, '').substring(0, 14),
        this.configs.series.interval!
      );
      data.is_open =
        typeof data.is_open == 'string'
          ? data.is_open === 'true'
            ? true
            : false
          : data.is_open;
      if (data.command == 'ADD') {
        const annotation = this.addExecOrderAnnotation(data);
        if (!annotation) {
          this.execOrderNotPlotted.set(data.idOrder, data);
          return;
        }
        this.execOrderNotPlotted.delete(data.idOrder);
        if (this.showOrderMarker) annotations.push(annotation);
        if (parseFloat(data.pnl) || data.is_open) {
          const { annotation, updated } = data.is_open
            ? this.addExecOrderParcialFlagAnnotation(data)!
            : this.addExecOrderFlagAnnotation(data, false)!;
          annotations = this.execOrderAnnotationsUpdate(
            annotation,
            updated,
            annotations,
            data.is_open
          );
          if (data.is_open && parseFloat(data.pnl)) {
            const invertedPosition = this.addExecOrderFlagAnnotation(
              data,
              true
            )!;
            annotations = this.execOrderAnnotationsUpdate(
              invertedPosition.annotation,
              invertedPosition.updated,
              annotations,
              data.is_open
            );
          }
        }
      }
      if (data.command == 'UPDATE') {
        if (!data.is_open) {
          this.tryRemoveParcialFlag(data);
        } else if (this.showOpenFlag) {
          this.updateExecOrderParcialFlagAnnotation(data);
        }
      }
    });
    annotations = this.removeHiddenFlags(annotations);
    this.annotationsAdd = annotations;
  }

  removeHiddenFlags = (
    annotations: Array<ExecOrderAnnotation | ExecOrderFlagAnnotation>
  ): Array<ExecOrderAnnotation | ExecOrderFlagAnnotation> => {
    return annotations.map((item) => {
      if (
        item instanceof ExecOrderFlagAnnotation &&
        this.hiddenOrderFlags.get(item.id)
      ) {
        item.isHidden = true;
      }
      return item;
    });
  };

  private replacePreviousAnnotation = (
    annotations: Array<ExecOrderAnnotation | ExecOrderFlagAnnotation>,
    updatedAnnotation: ExecOrderFlagAnnotation
  ) => {
    const index = annotations.findIndex(
      (ann) =>
        ann instanceof ExecOrderFlagAnnotation &&
        ann.id === updatedAnnotation.id
    );
    if (index !== -1) {
      annotations[index] = updatedAnnotation;
    } else {
      const annOnChart = this.baseChart.sciChartSurface.annotations.getById(
        updatedAnnotation.id
      ) as ExecOrderFlagAnnotation;
      if (annOnChart) {
        annOnChart.svgString = updatedAnnotation.svgString;
        annOnChart.annotationOrder = updatedAnnotation.annotationOrder;
      }
    }
    return annotations;
  };

  private validityPositionExecOrder(annotation: ExecOrderAnnotation): boolean {
    const custBox = this.getAnnotationsById(
      getLineBoxAnnotationId(this._custodyStopOrder.custodyLineId)
    ) as CustomAnnotation;
    if (!custBox) return false;
    const ann = annotation;
    const annB = ann.getAnnotationBorders();
    const custBoxB = custBox.getAnnotationBorders();
    const annDiffY = annB.y2 - annB.y1;
    const annDiffX = annB.x2 - annB.x1;
    const x1 = custBoxB.x1 - annDiffX;
    return (
      annB.y1 >= custBoxB.y1 - annDiffY &&
      annB.y2 <= custBoxB.y2 + annDiffY &&
      annB.x1 >= x1 &&
      annB.x2 <= custBoxB.x2 + annDiffX
    );
  }

  private fixFlagPosition(annotation: ExecOrderFlagAnnotation) {
    if (!this.baseChart) return;
    const borders = annotation.getAnnotationBorders();
    let refresh = false;
    this.baseChart?.sciChartSurface.annotations
      .asArray()
      .filter(
        (ann) =>
          ann instanceof ExecOrderFlagAnnotation && ann.id != annotation.id
      )
      .sort((a, b) => a.x1 - b.x1)
      .forEach((item: IAnnotation) => {
        const ann = item as ExecOrderFlagAnnotation;
        const annBorders = ann.getAnnotationBorders();
        const conflictX =
          (borders.x1 >= annBorders.x1 && borders.x1 <= annBorders.x2) ||
          (borders.x2 >= annBorders.x1 && borders.x2 <= annBorders.x2);
        const conflictY =
          (borders.y1 >= annBorders.y1 && borders.y1 <= annBorders.y2) ||
          (borders.y2 >= annBorders.y1 && borders.y2 <= annBorders.y2);

        if (ann.x1 > annotation.x1) {
          if (conflictX && conflictY) {
            ann.flagOptions.conflicted = true;
            refresh = true;
            this.refreshOrderFlag(ann);
          } else if (ann.flagOptions.conflicted) {
            ann.flagOptions.conflicted = false;
            refresh = true;
            this.refreshOrderFlag(ann);
          }
        }
      });

    if (refresh) {
      this.refreshOrderFlag(annotation);
    } else {
      this.redrawCustody$.next();
    }
  }

  private refreshOrderFlag(annotation: ExecOrderFlagAnnotation) {
    const { flagOptions } = annotation;
    if (flagOptions.isOpenPnl)
      this.execOrderFlagParcialAnnotation.delete(annotation.x1);
    else this.execOrderFlagAnnotation.delete(annotation.x1);
    const annotationToUpdate =
      this.baseChart.sciChartSurface.annotations.getById(
        annotation.id
      ) as ExecOrderFlagAnnotation;
    if (!annotationToUpdate) return;
    annotation.annotationOrder.values().forEach((order, i) => {
      const newAnnotation =
        this.executedOrdersService.addExecOrderPlIndicatorAnnotation(
          order,
          this.xAxisCalculator,
          this.candleDataSeries,
          this.execOrderFlagAnnotation,
          this.execOrderFlagParcialAnnotation,
          this.drawToolsService,
          this.refComponent,
          this.xAxisId,
          false,
          false,
          flagOptions.conflicted,
          this.flagClickHandler
        );
      if (!newAnnotation) return;
      if (i == annotation.annotationOrder.values().length - 1) {
        annotationToUpdate.svgString = newAnnotation.annotation.svgString;
        annotationToUpdate.flagOptions = flagOptions;
        this.cdr.detectChanges();
        this.redrawCustody$.next();
        this.setDictionaryExecOrder(
          (order.is_open as boolean)
            ? this.execOrderFlagParcialAnnotation
            : this.execOrderFlagAnnotation,
          order.is_open as boolean
        );
      }
    });
  }

  private yAxisLabelFormatter = (value: number) => {
    return this.axisService.yAxisLabelFormatter({
      value,
      cdSegment: this.cdSegment,
      configs: this.configs,
      dsAsset: this.dsAsset,
      indicators: this.tooltip.indicators,
      papel: this.papel,
    });
  };

  unbindHotkeys() {
    this.hotkeysService.unBindEvents(this.hotkeys());
  }

  bindHotkeys() {
    window.onblur = () => {
      this.pressAlt = false;
      this.pressShift = false;
      this.altShiftOrderService.deleteAltShiftAux(this.baseChart);
    };
    this.hotkeysService.bindEvents(this.hotkeys());
  }

  shiftHandler = () => {
    if (isTypeStockIndex(this.stock?.type)) return;
    this.dispatchBlurAllElements();

    this.pressShift = true;
    this.pressAlt = false;
    return false;
  };

  altHandler = () => {
    if (isTypeStockIndex(this.stock?.type)) return;
    this.dispatchBlurAllElements();
    this.pressAlt = true;
    this.pressShift = false;
    return false;
  };

  shiftKeyupHandler = () => {
    if (isTypeStockIndex(this.stock?.type)) return;
    this.pressShift = false;
    this.altShiftOrderService.deleteAltShiftAux(this.baseChart);
  };

  altKeyupHandler = () => {
    if (isTypeStockIndex(this.stock?.type)) return;
    this.pressAlt = false;
    this.altShiftOrderService.deleteAltShiftAux(this.baseChart);
  };

  keydown = (event: any) => {
    if (!this.homeService.isSelectedComponent(this.ref)) return;
    if (event.altKey || event.ctrlKey || event.altKey) return;
    if (this.configs.enableKeyPressActions) {
      const key = Number(event.key);
      if (isNaN(key) || event.key === null || event.key === ' ') {
        return;
      }
      event.preventDefault();
      this.keypressEvent.emit(event);
      this._lastArrowNavigationTimestamp = 0;
    }
  };

  arrow = (event: any) => {
    if (!this.homeService.isSelectedComponent(this.ref)) return;
    if (this.configs.enableKeyPressActions) {
      this.dispatchBlurAllElements();
      event.preventDefault();
      const arrows = Object.values(TIGER_KEYPRESS_ARROWS) as string[];
      const { key } = event;
      if (arrows.indexOf(key) > -1) {
        if (!event.ctrlKey) {
          this.arrowNavigate(key, 1);
          return;
        }
        if (key === TIGER_KEYPRESS_ARROWS.ARROW_RIGHT) {
          this.resetVisibilityChart();
        }
      }
    }
  };

  delHandler = () => {
    this.dispatchBlurAllElements();
    this.deleteDraw();
    this.deleteSelectedAnnotation();
  };

  escHandler = () => {
    this.dispatchBlurAllElements();
    this.esc();
  };

  CTRL_Z = () => {
    this.dispatchBlurAllElements();
    this.deleteLastDraw();
  };

  CTRL_Y = () => {
    this.dispatchBlurAllElements();
    this.addDeletedLastDraw();
  };

  private chartBook() {
    this.isFirstDataStreaming = false;
    this.showChartBookControl({
      showChartBook: this.showChartBook,
      showChartVolumeAtPrice: false,
    });
  }

  private fastOrder() {
    this.widthRemove = this.showFastOrder
      ? this.widthRemove + this.divOrderWidth
      : this.widthRemove - this.divOrderWidth;
    this.widthRemove = this.widthRemove < 0 ? 0 : this.widthRemove;
  }

  private resizeWidth() {
    this.widthRemove = 0;
    this.widthRemove += this.withTools ? 50 : 0;
    this.widthRemove += this.showFastOrder ? this.divOrderWidth : 0;
  }

  private chartVolumeAtPrice() {
    this.isFirstDataStreaming = false;
    this.showChartVolumeAtPriceControl({
      showChartVolumeAtPrice: this.showChartVolumeAtPrice,
      showChartBook: false,
    });
  }

  private blurBookSeries() {
    if (this._bookIndicator) {
      this._bookIndicator.paletteProvider = new BookPaletteProvider(
        this._bookValues,
        true
      );
      this._bookIndicator.dataLabelProvider.color = 'transparent';
      this._bookIndicator.isHovered = false;
      this.columnBarService.removeAnnotation(this.baseChart);
    }
  }

  private hoverBookSeries(hitTestInfo: HitTestInfo) {
    if (this._bookIndicator) {
      this._bookIndicator.paletteProvider = new BookPaletteProvider(
        this._bookValues,
        false
      );
      this._bookIndicator.isHovered = true;
      this.columnBarService.addAnnotation(
        this.baseChart,
        hitTestInfo,
        this.xAxisId,
        (value: any) => {
          return formatNumber(value, this.locale, `1.0-0`);
        },
        this.textColor
      );
    }
  }

  private hoverVolumeAtPriceSeries(hitTestInfo: HitTestInfo) {
    if (this._volumeAtPriceIndicator) {
      this._volumeAtPriceIndicator.getVisibleSeries().forEach((serie) => {
        const rgba = this.colorsBaseService.hex2Rgba(serie.fill);
        const rgb = this.colorsBaseService.rgba2rgb(rgba);
        const newHex = this.colorsBaseService.rgba2hex(rgb);
        serie.fill = newHex;
      });
      this.columnBarService.addAnnotation(
        this.baseChart,
        hitTestInfo,
        this.xAxisId,
        () => {
          if (!this.xAxisBook) return;
          const val = parseFloat(
            hitTestInfo.xValue.toFixed(this.stock.tick_size_denominator)
          );
          const data = this.columnBarService.getQttyByPrice(val);
          return formatNumber(data!.qtty_total!!, this.locale, `1.0-0`);
        },
        this.textColor
      );
    }
  }

  private blurVolumeAtPriceSeries() {
    if (this._volumeAtPriceIndicator) {
      this._volumeAtPriceIndicator.getVisibleSeries().forEach((serie) => {
        const rgba = this.colorsBaseService.getRgbaObjectByHex(serie.fill);
        const newRgba = `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, 0.5)`;
        const newHex = this.colorsBaseService.rgba2hex(newRgba);
        serie.fill = newHex;
      });
      this.columnBarService.removeAnnotation(this.baseChart);
    }
  }

  private subscriberCandleYAxisChanges() {
    this._candleYAxis.visibleRangeChanged.subscribe((event) => {
      if (!event || !event.visibleRange) {
        return;
      }
      this.xAxisBook.visibleRange = new NumberRange(
        event.visibleRange.min,
        event.visibleRange.max
      );
      this._repositionXAxisBook$.next({ visibleRange: event.visibleRange });
      /**
       * Por algum motivo em alguns momentos o scichart inverte o min e max.
       */
      const tickRange = deepClone(event.visibleRange);
      const visibleRange = reOrderVisibleRange(tickRange);
      this._custodyStopOrder.updateSeeCustody(visibleRange, this.baseChart);
      this._showMaxAndMinLines$.next();
      this.drawToolsService.axisChanged$.next({
        type: 'YAXIS',
        visibleRange: visibleRange,
      });
      if (this.closeline && this.closeline.y1) {
        this.updateTimerPosition(
          this.closeline.y1,
          this.closeline.axisLabelFill
        );
      }
      this.redrawCustody$.next();
    });
  }

  unsubscriberCheetah(isChangeInterval: boolean = false) {
    this._unsubscribeBookChannel(true);
    !isChangeInterval && this._unsubscribeVolumeChannel();
    this._removeStreamingQuote();
  }

  private setDataPointers(e: any) {
    const renderableSeries = this.baseChart.sciChartSurface.renderableSeries;
    this.resetAllPointsMaker();
    const annotationsBlock = ['Martelo', 'Martelo Invertido', 'Doji'];
    const permitedAllLines = ['Bandas de Bollinger'];
    const lineToSet: any = {
      'Bandas de Bollinger': [
        '-bbands-lower-line_',
        '-bbands-line_',
        '-bbands-bands_',
        '-bbands-upper-line_',
      ],
    };

    if (!isNullOrUndefined(e.indicator)) {
      const seriesId = e.indicator?.id;
      const hasMatch = annotationsBlock.some((word) => seriesId.includes(word));
      const objData = {
        width: 8,
        height: 8,
        strokeThickness: 2,
        stroke: e.indicator.stroke,
        fill: 'black',
      };

      if (
        TIGER_INDICATOR_CLICK.includes(e.type) &&
        !(e.indicator instanceof CustomAnnotation) &&
        !hasMatch &&
        e.id != CANDLE_IDS.VOLUME_SERIES
      ) {
        const hasMatchPermited = permitedAllLines.some((word) =>
          e.indicator.id.includes(word)
        );

        if (hasMatchPermited) {
          const name = e.indicator.id.split('-')[0];
          const numberId = e.indicator.id.split('_')[1];
          lineToSet[name].forEach((indicador: any) => {
            const series = renderableSeries.getById(
              `${name}${indicador}${numberId}`
            );
            const pointMarkerTemp = this.createEllipsePointMarker(
              this.baseChart.wasmContext,
              objData
            );
            if (!isNullOrUndefined(series)) {
              series.pointMarker = pointMarkerTemp;
            }
          });
          return;
        }

        const selectedSeries = renderableSeries.getById(`${e.indicator.id}`);
        const pointMarker = this.createEllipsePointMarker(
          this.baseChart.wasmContext,
          objData
        );
        if (!isNullOrUndefined(selectedSeries)) {
          selectedSeries.pointMarker = pointMarker;
        }
      } else if (e.type === 'DONTCLICK') {
        this.resetAllPointsMaker();
      }
    }
  }

  private resetAllPointsMaker() {
    if (!this.baseChart) return;
    const renderableSeries =
      this.baseChart.sciChartSurface.renderableSeries.asArray();
    renderableSeries.forEach((series) => {
      if (series.isStacked) {
        return;
      }
      const annotationsBlock = ['Martelo', 'Martelo Invertido', 'Doji'];
      const seriesId = series.id;
      const isSarParabolico = seriesId.includes('SAR Parabólico');
      const isNotInAnnotationsBlock = !annotationsBlock.some((word) =>
        seriesId.includes(word)
      );

      if (isNotInAnnotationsBlock) {
        const pointMarkerOptions = {
          width: isSarParabolico ? 4 : 0,
          height: isSarParabolico ? 4 : 0,
          strokeThickness: isSarParabolico ? series.strokeThickness : 0,
          stroke: isSarParabolico ? series.stroke : 'transparent',
          fill: isSarParabolico ? series.stroke : 'transparent',
        };

        const pointMarker = this.createEllipsePointMarker(
          this.baseChart.wasmContext,
          pointMarkerOptions
        );

        series.pointMarker = pointMarker;
      }
    });
  }

  private createEllipsePointMarker(wasmContext: any, options: any) {
    return new EllipsePointMarker(wasmContext, options);
  }

  override emitFreeScale(): void {
    this._candleYAxis.autoRange = EAutoRange.Once;
    this.freeScaleEmmitter.emit({
      isFreeScale: this.isFreeScale,
      updateMetadata: false,
    });
  }

  fastOrdersClick(data: {
    type: TYPE_CLICK;
    price: number;
    qtde: number;
    isStartStop: boolean;
    fastOrder: boolean;
  }) {
    const funcsOrders: any = {
      [TYPE_CLICK.MARKET_BUY]: () =>
        this.sendOrder(
          TYPE_ORDE_SIDE_ENUM.BUY,
          TYPE_ORDE_ENUM.MARKET,
          data.qtde
        ),
      [TYPE_CLICK.MARKET_SELL]: () =>
        this.sendOrder(
          TYPE_ORDE_SIDE_ENUM.SELL,
          TYPE_ORDE_ENUM.MARKET,
          data.qtde
        ),
      [TYPE_CLICK.BUY]: () =>
        this.sendOrder(
          TYPE_ORDE_SIDE_ENUM.BUY,
          data.isStartStop
            ? TYPE_ORDE_ENUM.START_STOP
            : TYPE_ORDE_ENUM.LIMITADA,
          data.qtde,
          data.price
        ),
      [TYPE_CLICK.SELL]: () =>
        this.sendOrder(
          TYPE_ORDE_SIDE_ENUM.SELL,
          data.isStartStop
            ? TYPE_ORDE_ENUM.START_STOP
            : TYPE_ORDE_ENUM.LIMITADA,
          data.qtde,
          data.price
        ),
      [TYPE_CLICK.RESET]: () =>
        this.handlePositionAction(ORDER_CONFIRM_ACTIONS_ENUM.RESET),
      [TYPE_CLICK.INVERT]: () =>
        this.handlePositionAction(ORDER_CONFIRM_ACTIONS_ENUM.INVERT),
      [TYPE_CLICK.CANCEL]: () => this.cancelOrders(),
      [TYPE_CLICK.UPDATE_FORM]: () => {
        this.changeNegotiationLot.emit(data.qtde);
        this.forceQtty(data.qtde);
      },
    };
    const func = funcsOrders[data.type];
    if (func) {
      func();
    }
  }

  forceQtty(qtde: number) {
    this.forcedQtty = qtde;
    this.cdr.detectChanges();
  }

  private sendOrder(
    sideOrder: TYPE_ORDE_SIDE_ENUM,
    typeEvent: TYPE_ORDE_ENUM,
    qtde?: number,
    price?: number
  ) {
    const strategy = this.tigerChartOrdersService.getStrategy(
      this.refComponent
    );
    this.ordersService.prepareOrder(
      this.stock,
      {
        price,
        qtd: qtde,
        isFastOrder: this.showFastOrder && this.showFastOrderDaytradeStrategy,
        strategy: strategy.strategy,
        isSelectedStrategy: strategy.isSelected,
      },
      this.account,
      sideOrder,
      typeEvent,
      {
        offset: +this.chartTradingOffset,
        minPriceIncrement: this.minPriceIncrement,
      }
    );
  }

  private handlePositionAction(action: ORDER_CONFIRM_ACTIONS_ENUM) {
    const position = this.positions.get(
      this._stockChartService.getPositionKey(this.stock, this.account)
    );
    const orderValue = {
      ...position,
      isFastOrder: this.showFastOrder && this.showFastOrderDaytradeStrategy,
    };
    this.ordersService.handlePositionAction(
      this.account,
      this.stock,
      orderValue,
      action
    );
  }

  private cancelOrders = () => {
    this.ordersService.cancelOrders(this.ordersOpen);
  };

  private searchPositionAndOrder = (): void => {
    if (!this.stock || !this.account) {
      return;
    }
    const orders = this.orderChannel.ordersDict.values();
    const res = processOrdersFilterQtty(orders);
    this.ordersOpen = res.data.filter((order: IOrders) =>
      this.filtersService.orderChannelFilter(order, this.stock, this.account)
    );
  };

  moveDividerOrder(target: any) {
    const targetRect = document
      .getElementById(`${this.ref}_main-tiger-chart`)!!
      .getBoundingClientRect();
    const width = targetRect.right - target.dropPoint.x;
    const newWidth = width < 0 ? Math.abs(width) + 10 : -width - 10;
    this.divOrderWidth = this.divOrderWidth - newWidth;
    this.divOrderWidth =
      this.divOrderWidth < this.minDivOrderWidth
        ? this.minDivOrderWidth
        : this.divOrderWidth;
    this.divOrderWidth =
      this.divOrderWidth > this.maxDivOrderWidth
        ? this.maxDivOrderWidth
        : this.divOrderWidth;
    this.resizeWidth();
    this.updateOrderWidth.emit(this.divOrderWidth);
    this.transform = {
      x: 0,
      y: 0,
    };
  }

  private changeVolume() {
    if (!this._volumeIndicator) {
      this.setVolume();
    } else {
      this.toggleVolume();
    }
    this._saveContextMenuConfig();
  }

  private updateZoomChart() {
    const zoomPan = this.getModifierById(ZOOM_PAN_MODIFIER_ID);
    const zoomRubberBand = this.getModifierById(ZOOM_RUBBER_BAND_MODIFIER_ID);
    if (!zoomPan || !zoomRubberBand) return;
    zoomPan.isEnabled = !this.showFastRuler;
    zoomRubberBand.isEnabled = false;
    this.updateChartCursor(this.toolCursor);
    if (this.zoomIn) {
      zoomPan.isEnabled = false;
      zoomRubberBand.isEnabled = true;
      this.toolCursor = 'zoom-in';
      this.updateChartCursor('zoom-in');
    }
  }

  private addAxis(addX: boolean = true, addY: boolean = true) {
    const yAxis = [];
    const xAxis = [];
    if (addX) {
      yAxis.push(this._barBookYAxis);
      yAxis.push(this._barVolumeAtPriceYAxis);
      xAxis.push(this.xAxis);
    }
    if (addY) {
      yAxis.push(this._columnYAxis);
      yAxis.unshift(this._candleYAxis);
      xAxis.push(this.xAxisBook);
    }
    this.baseChart.sciChartSurface.yAxes.add(...yAxis);
    this.baseChart.sciChartSurface.xAxes.add(...xAxis);
  }

  private isStarted(func: any) {
    this.started$
      .pipe(
        takeUntil(this._onDestroy),
        skipWhile((res) => !res)
      )
      .subscribe(() => {
        func();
      });
  }

  override deSelectedTool(toolEnum?: TOOLS_ENUM) {
    this.tigerChartTools && this.tigerChartTools.deSelectTool(toolEnum);
  }

  private _updateVisibleEvents() {
    this.tigerChartEventsModalService.updateAllEvents(
      this.data,
      this.configs.series.interval!
    );
  }

  goToNextActiveStock() {
    this.changeActiveStock.emit(this.stock);
  }

  deleteCustody() {
    this._custodyStopOrder.custodyLineId &&
      this.deleteLine(this._custodyStopOrder.custodyLineId);
    this.deleteLine(COVERAGE_PRICE_ID);
  }

  deleteOrders() {
    const annotations = this.baseChart?.sciChartSurface.annotations
      .asArray()
      .filter(
        (annotation) =>
          annotation.id !== this._closeLineId &&
          !annotation.id.includes(this._newLineId) &&
          annotation.id !== this.userCustody?.item &&
          annotation.id !== PREVIOUS_LINE_ID &&
          annotation.id !== BID_LINE_ID &&
          annotation.id !== ASK_LINE_ID &&
          annotation.id !== COVERAGE_PRICE_ID
      );
    annotations &&
      annotations
        .filter(
          (annotation) =>
            annotation instanceof HorizontalLineAnnotation ||
            annotation instanceof HorizontalLine
        )
        .forEach((annotation) => this.deleteOrder(annotation.id));
  }

  private deleteOrder(lineId: string) {
    const lineBox = this.getAnnotationsById<ClickableAnnotation>(
      getLineBoxAnnotationId(lineId)
    );
    const annotation = this.getAnnotationsById(lineId);
    if (lineBox) {
      this.baseChart.sciChartSurface.annotations.remove(lineBox);
    }
    if (annotation) {
      this.baseChart.sciChartSurface.annotations.remove(annotation);
    }
  }

  private _calcContextPositions(event: MouseEvent, fromPlus: boolean = false) {
    if (this._isTour) return { left: 280, top: 20 };
    const top =
      event.view!.innerHeight < event.pageY + 252
        ? event.offsetY - (!fromPlus ? 252 : 52)
        : event.offsetY;
    const left =
      event.view!.innerWidth < event.pageX + 200
        ? event.offsetX - 200
        : event.offsetX - (fromPlus ? 200 : 0);
    return { left, top };
  }

  public _startTour(): void {
    this._isTour = true;
    this.tourChange.emit({ action: 'STARTED' });
    const chartElement = document.getElementById(this.refComponent)!;
    if (chartElement.offsetWidth < HEADER_SIZES.DEFAULT) {
      chartElement.classList.add('chart-container-during-tour');
      this.tourChange.emit({ action: 'USE_DEFAULT_HEADER' });
    }
    this._introJsService.chart(this.refComponent, this._forceOpenContextMenu);
    this._tourSub = this._introJsService
      .onStart()
      .subscribe((res: ITigerChartTourEventEmitter) => {
        if (res.action === 'CLOSED') this._onTourClosed(chartElement);
        if (['DISPLAY_CONTEXT', 'HIDE_CONTEXT'].includes(res.action)) {
          this._handleContextVisibilityOnTour(res.action === 'DISPLAY_CONTEXT');
        }
        if (res.action === 'TOGGLE_FUNC') this.tourChange.emit(res);
      });
  }

  private _forceOpenContextMenu = (isVisible: boolean = true): void => {
    const position = document
      .getElementById(this.refComponent)
      ?.getBoundingClientRect();
    const mouseEvent: any = {
      view: {
        innerHeight: document.body.clientHeight,
        innerWidth: document.body.clientWidth,
      },
      pageY: window.innerHeight,
      pageX: window.innerWidth,
      offsetY: position!.y,
      offsetX: position!.x,
    };
    this.openContextMenu(mouseEvent, 0, 0);
    if (!isVisible) {
      setTimeout(() => {
        this._handleContextVisibilityOnTour(isVisible);
      }, 100);
    }
  };

  private _handleContextVisibilityOnTour(displayContext: boolean): void {
    const doc = document.querySelectorAll<HTMLElement>(
      'app-tiger-chart-context-menu > div'
    );
    if (!doc || !doc.length) return;
    const element = doc[0];
    element.style.zIndex = displayContext ? '99' : '-1';
    element.style.display = displayContext ? 'block' : 'none';
  }

  private _onTourClosed(chartElement: Element): void {
    this.tourChange.emit({ action: 'CLOSED' });
    const contextElement = document.querySelectorAll(
      'app-tiger-chart-context-menu'
    );
    if (contextElement && contextElement.length)
      contextElement.forEach((elem) => elem.remove());
    chartElement.classList.remove('chart-container-during-tour');
    this._tourSub.unsubscribe();
    this._isTour = false;
  }

  private clearCustody() {
    this._custodyStopOrder.custodyQuantity = 0;
    this._custodyStopOrder.clearCustodyCheeps();
  }

  addLoader() {
    this._stockChartService.addChartLoading$.next({ ref: this.ref });
  }

  removeLoader() {
    this._stockChartService.removeChartLoading$.next({
      remove: true,
      ref: this.ref,
    });
  }

  private hideMinMaxAnnotation() {
    this.removeMinMaxAnnotation(minMaxIDS.MAX);
    this.removeMinMaxAnnotation(minMaxIDS.MIN);
  }

  private removeMinMaxAnnotation(id: string) {
    const annotation = this.getAnnotationsById(id);
    const annotationText = this.getAnnotationsById(
      `${id}_TEXT`
    ) as TextAnnotation;
    if (annotation) {
      annotation.isHidden = true;
      annotationText.isHidden = true;
    }
  }

  private showMinMaxAnnotation(data: {
    biggerMax: number;
    menorMin: number;
    maxIndex: number;
    minIndex: number;
  }) {
    const diff = 1;
    let maxX2 = data.maxIndex + diff;
    if (maxX2 > this.xAxis.visibleRange.max) {
      maxX2 = data.maxIndex - diff;
    }
    let minX1 = data.minIndex - diff;
    if (minX1 < this.xAxis.visibleRange.min) {
      minX1 = data.minIndex + diff;
    }
    this.minMaxAnnotationService.addMinMax(
      this.baseChart,
      this.xAxisId,
      {
        biggerMax: data.biggerMax,
        maxIndex: data.maxIndex,
        maxX2,
        menorMin: data.menorMin,
        minX1,
        minIndex: data.minIndex,
      },
      this.tickSizeDenominator,
      this.xAxis
    );
  }

  private addExecOrderAnnotationTooltip(
    execOrderAnnotations: ExecOrderAnnotation[],
    mouseEvent: MouseEvent
  ) {
    const { annotationTooltipLabel, annotationTooltipLeft, textWidth } =
      this.executedOrdersService.addExecOrderAnnotationTooltip(
        execOrderAnnotations
      );
    this.annotationTooltipLeft = annotationTooltipLeft;
    this.showAnnotationTooltip = true;
    this.annotationTooltipLabel = annotationTooltipLabel;
    this.typeButtons = undefined;
    this.isEventExpanded = false;
    this.forcedQtty = 0;
    this.cdr.detectChanges();
    this._repositionExecOrderTooltip$.next({
      mouseEvent,
      textWidth,
      execOrderAnnotations,
    });
  }

  addExecOrderAnnotation(order: IOrderChartData) {
    const addExecOrderAnnotation =
      this.executedOrdersService.addExecOrderAnnotation(
        order,
        this.xAxisCalculator,
        this.execOrdersAnnotation,
        this.execOrdersAnnotationIndex,
        this.candleDataSeries,
        this.drawToolsService,
        this.refComponent,
        this.xAxisId,
        this.configs.series.interval! === TIGER_INTERVAL_ENUM.ONE_MONTH
      );
    if (!addExecOrderAnnotation) return;
    const { annotation, execOrdersAnnotation, execOrdersAnnotationIndex } =
      addExecOrderAnnotation;
    this.dispatchAnnotationChange(
      annotation,
      execOrdersAnnotation,
      execOrdersAnnotationIndex
    );
    return annotation;
  }

  addExecOrderFlagAnnotation(
    order: IOrderChartData,
    isInvertPosition: boolean
  ) {
    const addExecOrderPlIndicatorAnnotation =
      this.executedOrdersService.addExecOrderPlIndicatorAnnotation(
        order,
        this.xAxisCalculator,
        this.candleDataSeries,
        this.execOrderFlagAnnotation,
        this.execOrderFlagParcialAnnotation,
        this.drawToolsService,
        this.refComponent,
        this.xAxisId,
        this.configs.series.interval! === TIGER_INTERVAL_ENUM.ONE_MONTH,
        isInvertPosition,
        false,
        this.flagClickHandler
      );
    if (!addExecOrderPlIndicatorAnnotation) return;
    const { annotation, execOrderFlagAnnotation, updated } =
      addExecOrderPlIndicatorAnnotation;
    this.dispatchFlagAnnotationChange(annotation, execOrderFlagAnnotation);
    return { annotation, updated };
  }

  addExecOrderParcialFlagAnnotation(order: IOrderChartData) {
    const addExecOrderPlIndicatorAnnotation =
      this.executedOrdersService.addExecOrderPlIndicatorAnnotation(
        order,
        this.xAxisCalculator,
        this.candleDataSeries,
        this.execOrderFlagAnnotation,
        this.execOrderFlagParcialAnnotation,
        this.drawToolsService,
        this.refComponent,
        this.xAxisId,
        false,
        false,
        false,
        this.flagClickHandler
      );
    if (!addExecOrderPlIndicatorAnnotation) return;
    const { annotation, execOrderFlagAnnotation, updated } =
      addExecOrderPlIndicatorAnnotation;
    this.dispatchFlagAnnotationChange(
      annotation,
      execOrderFlagAnnotation,
      true
    );
    return { annotation, updated };
  }

  updateExecOrderParcialFlagAnnotation(order: IOrderChartData) {
    if (!order.is_open) {
      return null;
    }

    const updatedAnnotation =
      this.executedOrdersService.updateParcialPlIndicatorAnnotation(
        this.baseChart,
        order,
        this.xAxisCalculator,
        this.candleDataSeries,
        this.execOrderFlagParcialAnnotation,
        this.configs.series.interval! === TIGER_INTERVAL_ENUM.ONE_MONTH
      );

    if (!updatedAnnotation) {
      const addExecOrderParcialFlagAnnotation =
        this.addExecOrderParcialFlagAnnotation(order);
      if (!addExecOrderParcialFlagAnnotation) {
        order.command = 'ADD';
        this.execOrderNotPlotted.set(order.idOrder!, order);
        return null;
      }
      this.execOrderNotPlotted.delete(order.idOrder!);
      return addExecOrderParcialFlagAnnotation.annotation;
    }
    this.execOrderNotPlotted.delete(order.idOrder!);
    const { execOrderFlagAnnotation, dontUpdate, oldAnnotation } =
      updatedAnnotation;
    if (dontUpdate) return null;

    this.dispatchFlagAnnotationChange(
      oldAnnotation,
      execOrderFlagAnnotation,
      true
    );
    return oldAnnotation;
  }

  private dispatchAnnotationChange = (
    ann: ExecOrderAnnotation,
    annotations: Dictionary<number>,
    annotationsIndex: Dictionary<ExecOrderAnnotation[]>
  ) => {
    this.executedOrdersService.addExecOrderAnnotation$.next({
      annotation: ann,
      refComponent: this.refComponent,
    });
    this.execOrdersAnnotation = annotations;
    this.execOrdersAnnotationIndex = annotationsIndex;
  };

  private dispatchFlagAnnotationChange = (
    ann: ExecOrderFlagAnnotation,
    annotations: Dictionary<ExecOrderFlagAnnotation>,
    isParcial: boolean = false
  ) => {
    this.executedOrdersService.addExecOrderFlagAnnotation$.next({
      annotation: ann,
      refComponent: this.refComponent,
    });
    this.setDictionaryExecOrder(annotations, isParcial);
  };

  private setDictionaryExecOrder(
    annotations: Dictionary<ExecOrderFlagAnnotation>,
    isParcial: boolean = false
  ) {
    if (isParcial) this.execOrderFlagParcialAnnotation = annotations;
    else this.execOrderFlagAnnotation = annotations;
  }

  private clearExecAnnotation() {
    this.execOrdersAnnotation.clear();
    this.execOrdersAnnotationIndex.clear();
    this.execOrderFlagAnnotation.clear();
    this.execOrderFlagParcialAnnotation.clear();
    this.baseChart?.sciChartSurface.annotations
      .asArray()
      .filter(
        (annotation) =>
          annotation instanceof ExecOrderAnnotation ||
          annotation instanceof ExecOrderFlagAnnotation
      )
      .forEach((annotation) => {
        this.annotationsRemove = annotation;
      });
  }

  private flagClickHandler = (args: AnnotationClickEventArgs) => {
    const ann = this.baseChart?.sciChartSurface.annotations.getById(
      args.sender.id
    ) as ExecOrderFlagAnnotation;
    if (ann) {
      this.hiddenOrderFlags.set(ann.id, ann);
      this.baseChart.sciChartSurface.annotations.getById(ann.id).isHidden =
        true;
    }
  };

  public clearOrderFlagAnnotation() {
    this.baseChart?.sciChartSurface.annotations
      .asArray()
      .filter(
        (annotation) =>
          annotation instanceof ExecOrderFlagAnnotation &&
          !annotation.flagOptions.isOpenPnl
      )
      .forEach((annotation) => {
        this.annotationsRemove = annotation;
      });
  }

  public clearOrderFlagOpenAnnotation() {
    this.baseChart?.sciChartSurface.annotations
      .asArray()
      .filter(
        (annotation) =>
          annotation instanceof ExecOrderFlagAnnotation &&
          annotation.flagOptions.isOpenPnl
      )
      .forEach((annotation) => {
        this.annotationsRemove = annotation;
      });
  }

  public clearOrderMarkerAnnotation() {
    this.baseChart?.sciChartSurface.annotations
      .asArray()
      .filter((annotation) => annotation instanceof ExecOrderAnnotation)
      .forEach((annotation) => {
        this.annotationsRemove = annotation;
      });
  }

  showOrderFlagOnChart() {
    if (this.showExecOrders && this.showFlagOrders) {
      this.cleanHiddenFlags(false);
      this.execOrderFlagAnnotation.values().forEach((annotation) => {
        annotation.annotationOrder.values().forEach((order, i) => {
          const flag = this.addExecOrderFlagAnnotation(
            order,
            order.is_open as boolean
          );
          if (!flag) return;
          if (i == annotation.annotationOrder.values().length - 1) {
            this.annotationsAdd = flag.annotation;
          }
        });
      });
    }
  }

  showOrderFlagOpenOnChart() {
    if (this.showExecOrders && this.showOpenFlag) {
      this.cleanHiddenFlags(true);
      this.execOrderFlagParcialAnnotation.values().forEach((annotation) => {
        annotation.annotationOrder.values().forEach((order, i) => {
          const flag = this.addExecOrderParcialFlagAnnotation(order);
          if (i == annotation.annotationOrder.values().length - 1) {
            if (!flag) return;
            this.annotationsAdd = flag.annotation;
          }
        });
      });
    }
  }

  showOrderMarkerOnChart() {
    if (this.showExecOrders && this.showOrderMarker) {
      this.execOrdersAnnotation.values().forEach((index) => {
        const annotation = this.execOrdersAnnotationIndex.get(index);
        if (annotation) {
          annotation.forEach((order) => {
            const marker = this.addExecOrderAnnotation(order.annotationOrder);
            if (!marker) return;
            this.annotationsAdd = marker;
          });
        }
      });
    }
  }

  cleanHiddenFlags(isOpen: boolean) {
    this.hiddenOrderFlags.keys().forEach((key) => {
      if (
        (isOpen && key.toString().includes('_parcial')) ||
        (!isOpen && !key.toString().includes('_parcial'))
      )
        this.hiddenOrderFlags.delete(key);
    });
  }

  recoverHiddenFlags(dataSeriesIndex: number) {
    this.hiddenOrderFlags
      .keys()
      .filter((key: any) => {
        return key.includes(`_${dataSeriesIndex}_`);
      })
      .map((key: any) => {
        if (
          (this.showOpenFlag && key.includes('_parcial')) ||
          (this.showFlagOrders && !key.includes('_parcial'))
        ) {
          const ann = this.hiddenOrderFlags.get(key);
          if (ann) {
            this.baseChart.sciChartSurface.annotations.getById(key).isHidden =
              false;
            this.hiddenOrderFlags.delete(key);
          }
        }
      });
  }

  private updateCursorModifierStock() {
    if (this.cursorCandleModifier) {
      this.cursorCandleModifier.updateStock(this.stock);
    }
  }

  private plotAuxHorizontalLine(
    options: TCandleLineOptions
  ): HorizontalLineAnnotation | undefined {
    let auxLine: HorizontalLineAnnotation | undefined = undefined;
    const dhCreation = standardizeDate(options.data.dh_creation_time);
    const creation = new Date(dhCreation).setSeconds(0, 0);
    if (
      this.createLineService.canPlotAuxLine(
        options.data.dh_creation_time,
        this.configs.series.interval!
      )
    ) {
      options.x1 = this.createLineService.getCategoryAxisIndex(
        this.xAxis,
        creation
      );
      auxLine = this.createLineService.createHorizontalLine(
        options,
        getLineAuxAnnotationId(options.boxId),
        this.xAxisId
      );
      this.annotationsAdd = auxLine;
    }
    return auxLine;
  }

  private setTextColor() {
    this.textColor = this._themeService.isDarkTheme()
      ? CHART_COLORS.NEUTRAL_SMOOTHEST
      : CHART_COLORS.NEUTRAL_STRONGEST;
  }

  //ORDER CHART EVENTS
  private _orderChartEvents!: RocketStreamRead | undefined;
  private waitingAsyncEvents: boolean = false;
  private _startOrderChartStreaming = async () => {
    if (!this.showExecOrders || this.orderChartParams || !this.xAxis) return;
    this.orderChartParams = this.getOrderChartParams();
    if (!this._orderChartEvents && !this.waitingAsyncEvents) {
      this.waitingAsyncEvents = true;
      this._orderChartEvents = await this.orderChartChannel.onEvents();
      this.waitingAsyncEvents = false;
      this._orderChartEvents.snapshot(this.orderChartParams);
      this.readStream(
        this._orderChartEvents.stream,
        this._channelOrderChartHandler
      );
    }
    this.orderChartSnapshot(this.orderChartParams);
  };

  orderChartSnapshot(orderChartParams: SubscribeParam | undefined) {
    this._orderChartEvents &&
      orderChartParams &&
      this._orderChartEvents.snapshot(orderChartParams?.itemsArray);
  }

  private _unsubscribeOrderChart() {
    if (!this.orderChartParams) return;
    this.orderChartParams = undefined;
  }

  private _removeOrderChartStream() {
    this._orderChartEvents && this._orderChartEvents.close();
    this._orderChartEvents = undefined;
  }

  private makeHammersParams(): IAddHammers {
    return {
      baseChart: this.baseChart,
      xAxisId: this.xAxisId,
      indicators: this.indicators,
      candleDataSeries: this.candleDataSeries,
      lineDataSeries: this._lineDataSeries,
      globalIndicatorHammer: this.globalIndicatorHammer,
      refComponent: this.refComponent,
    };
  }

  private async resetOrderChartStream() {
    if (!this.stock || !this.account) return;
    this._unsubscribeOrderChart();
    this.clearExecAnnotation();
    await this._startOrderChartStreaming();
  }

  deleteSelectedAnnotation() {
    if (this._focused && this.clickOnIndicator && this.indicatorClicked) {
      this.clickOnIndicator = false;
      this.indicatorClicked = undefined;
      const splitted = this.indicatorClicked.id.split('_');
      this.deleteIndicator({
        type: this.indicatorClicked.name,
        lineNumber: splitted[splitted.length - 1],
      });
    }
  }

  private resetEditDraw(deselection: boolean = true) {
    if (this.toolbarDrawService.hasElement()) {
      deselection && this.makeDeselectionAuxBox();
      this.toolbarDrawService.destroyComponent();
    }
  }

  focus(focused: boolean) {
    this._focused = focused;
    if (!focused) {
      this.clickOnIndicator = false;
      this.indicatorClicked = undefined;
      this.resetAllPointsMaker();
      this._stockChartService.indicatorClick(null);
      if (this.drawToolsService.openedModalDrawEdit) {
        this.drawToolsService.openedModalDrawEdit = false;
      } else {
        this.resetEditDraw();
      }
    }
  }

  updateOrderFlagPosition(indexCandle: number, vl_high: number) {
    const updateFlagHigh = (
      annotation: ExecOrderFlagAnnotation,
      list: Dictionary<ExecOrderFlagAnnotation>
    ) => {
      list.set(indexCandle, {
        ...annotation,
        y1: vl_high,
      });
      const annotationOpen = this.baseChart.sciChartSurface.annotations.getById(
        annotation.id
      );
      if (
        this.showExecOrders &&
        (this.showFlagOrders || this.showOpenFlag) &&
        annotationOpen
      ) {
        annotation.y1 = vl_high;
        annotation.x1 = indexCandle;
        annotationOpen.y1 = structuredClone(vl_high);
        annotationOpen.x1 = indexCandle;
      }
      return list;
    };
    if (this.execOrderFlagAnnotation.has(indexCandle)) {
      this.execOrderFlagAnnotation = updateFlagHigh(
        this.execOrderFlagAnnotation.get(indexCandle)!,
        this.execOrderFlagAnnotation
      );
    }

    if (this.execOrderFlagParcialAnnotation.has(indexCandle)) {
      this.execOrderFlagParcialAnnotation = updateFlagHigh(
        this.execOrderFlagParcialAnnotation.get(indexCandle)!,
        this.execOrderFlagParcialAnnotation
      );
    }
  }

  private _setChartOrderParams() {
    if (!this.stock || !this.account) return;
    this.orderChartParams = undefined;
    if (this.showExecOrders) {
      this.orderChartParams = this.getOrderChartParams();
    }
  }

  private getOrderChartParams() {
    return this._stockChartService.getOrderChartParams(
      this.stock,
      this.account,
      this._dayTradeService.useDayTradeMode
    );
  }

  public setSubscribeChartOrder() {
    this.setSubscribeChartOrder$.next();
  }

  public unsubscribeChartOrder() {
    this.stockChartChannelService.unsubscribeChannels({
      orderChart: this.orderChartParams ? this.orderChartParams : undefined,
    });
    this.orderChartParams = undefined;
    this.execOrderNotPlotted.clear();
  }

  private _subscribeChartOrder() {
    this.stockChartChannelService.subscribe({
      orderChart: this.orderChartParams,
    });
  }

  private dispatchBlurAllElements() {
    this.inputCountService.dispatchBlurAllElements();
  }

  private ctrlWasPress() {
    this.ctrlIsPress = false;
    this.updateCursorToDefaultNotGrab();
    this.showFastRuler = true;
    this.setFastRulerGlobal(false);
    this.updateZoomChartModifiers();
    this.dispatchFooterInfo(TFooterType.RULER, this.showFastRuler);
  }

  private ctrlNotPress() {
    this.ctrlIsPress = true;
    this.updateChartCursor('grab');
    this.showFastRuler = false;
    this.setFastRulerGlobal(false);
    this.removeFastRuler();
    this.updateZoomChartModifiers();
    this.dispatchFooterInfo(TFooterType.RULER, this.showFastRuler);
  }

  private ctrlKeyUpHandler = () => {
    if (!this.homeService.isSelectedComponent(this.ref)) return;
    if (this.holdCtrl) {
      this.holdCtrl = false;
      clearTimeout(this.timeoutRefCtrlHandler);
      return;
    }
    clearTimeout(this.timeoutRefCtrlHandler);
    if (this.ctrlIsPress || !this.showFastRuler) {
      this.ctrlWasPress();
      return;
    }
    this.ctrlNotPress();
  };

  private ctrlKeyDownHandler = () => {
    /**
     * caso tenha segurado o control por mais de 500 ms
     * define que não ativara a regua
     */
    this.timeoutRefCtrlHandler = setTimeout(() => {
      this.holdCtrl = true;
    }, 500);
  };

  dispatchFooterInfo(cod: TFooterType, value: any) {
    this._stockChartService.footerChartConfig$.next({
      cod,
      ref: this.refComponent,
      value,
      isFromParent: true,
    });
  }
  indicatorVisibility(indicator: BaseIndicator) {
    if (indicator.type === TIGER_INDICATORS_ENUM.BAR_ELEPHANT) {
      if (indicator.isVisible) {
        this.setCandleElephantBarColors(true);
      } else {
        this.resetCandleBarsToOriginalValue();
      }
    }
  }

  setCandleElephantBarColors(force: boolean = false) {
    this.updateCandleColors(
      {
        colorsVelaUp: defaultPositiveElephantColor,
        colorsVelaDown: defaultNegativeElephantColor,
        colorsBorderUp: defaultPositiveElephantColor,
        colorsBorderDown: defaultPositiveElephantColor,
      },
      force
    );
  }

  private hotkeys = (): IHotkeys[] => {
    return [
      {
        key: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
        actions: 'keydown',
        handlerDown: this.keydown,
        elementId: this.idMainChart,
      },
      {
        key: 'shift',
        actions: ['keydown', 'keyup'],
        handlerDown: this.shiftHandler,
        handlerUp: this.shiftKeyupHandler,
        elementId: this.idMainChart,
      },
      {
        key: 'ctrl',
        actions: ['keydown', 'keyup'],
        handlerDown: this.ctrlKeyDownHandler,
        handlerUp: this.ctrlKeyUpHandler,
        elementId: this.idMainChart,
      },
      {
        key: 'alt',
        actions: ['keydown', 'keyup'],
        handlerDown: this.altHandler,
        handlerUp: this.altKeyupHandler,
        elementId: this.idMainChart,
      },
      {
        key: ['left', 'right', 'ctrl+right'],
        actions: 'keydown',
        handlerDown: this.arrow,
      },
      {
        key: ['delete', 'del', 'backspace'],
        actions: 'keydown',
        handlerDown: this.delHandler,
      },
      {
        key: 'esc',
        actions: 'keydown',
        handlerDown: this.escHandler,
      },
      {
        key: 'ctrl+z',
        actions: 'keydown',
        handlerDown: this.CTRL_Z,
      },
      {
        key: ['ctrl+y', 'ctrl+shift+z'],
        actions: 'keydown',
        handlerDown: this.CTRL_Y,
      },
    ];
  };

  override updateCursorToDefaultNotGrab() {
    if (!this.ctrlIsPress && !this.showFastRuler) {
      this.updateChartCursor(this.toolCursor);
    } else if (!this.isGrabbing()) {
      this.updateChartCursor('grab');
    }
  }
}
