import { ChangeDetectorRef, Inject, inject } from '@angular/core';
import { system } from '@core/system/system.service';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { AuthService } from '@shared/services';
import { AccountPreferences } from '@shared/services/core/custom-preferences/account/account-preferences.services';
import { StandaloneModalsService } from '../standalone-modals.service';
import { InitializeBrokerConnectService } from '@shared/services/initialize-broker-connect.service';
import { PartnersService } from '@shared/services/core/partners-services/partners.service';
import { TrademapV9Service } from '@shared/services/api/trademap/V9/trademap.service';
import { WorkspacesPreferences } from '@shared/services/core/custom-preferences/workspace';

export class HandleTrialHelper extends RocketModalRef {
  public sevenDaysTrialEnabled: boolean = false;
  public isLoading: boolean = true;
  public someError: boolean = false;
  public onlyDisplay: boolean = false;
  private _authService = inject(AuthService);
  private _standaloneModalsService = inject(StandaloneModalsService);
  private _initializeBrokerConnectService = inject(
    InitializeBrokerConnectService
  );
  private _trademapV9Service = inject(TrademapV9Service);
  private _accountPreference = inject(AccountPreferences);
  private _partnersService = inject(PartnersService);
  private _cdr = inject(ChangeDetectorRef);
  private _wsPreference = inject(WorkspacesPreferences);

  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: { trialEnabled: boolean },
    protected modalService: RocketModalService
  ) {
    super(modalService);
  }

  public verifyCanEnableTrial(): void {
    const account = system.userAuthenticated.investor;
    if (account.alreadyUsedTrial || !account.isSessionWeak)
      this.onlyDisplayModal();
    else this.enableTrial();
  }

  public onlyDisplayModal(): void {
    this.sevenDaysTrialEnabled = false;
    this.onlyDisplay = true;
    this.isLoading = false;
    this._accountPreference.displayTMWelcomeModal = false;
  }

  public enableTrial() {
    this.someError = false;
    this._trademapV9Service.nitroSevenDaysContract().subscribe({
      error: () => {
        this.someError = true;
        this.isLoading = false;
        this._cdr.detectChanges();
      },
      next: () => {
        this._accountPreference.displayTMWelcomeModal = false;
        this.sevenDaysTrialEnabled = true;
        this.isLoading = false;
        this._cdr.detectChanges();
      },
    });
  }

  public initializeSystem(): void {
    const user = structuredClone(system.userAuthenticated);
    user.investor.isSessionWeak = false;
    user.investor.alreadyUsedTrial = true;
    system.userAuthenticated = user;
    const partner = this._partnersService.partner;
    this._partnersService.openConnectBrokersAfterLoadSystem = partner !== null;
    this._initializeBrokerConnectService.initializeBroker$.next(true);
    this._authService.loadPreferencesAndInitialize();
    partner && this._partnersService.savePartnerAfterAuth(partner);
    this.closeModalComponent();
  }

  public logout(): void {
    this._authService.performLogout({
      reason: 'USER_COMMAND',
      systemMessage: '',
    });
  }

  public closeModalComponent(params: any = null): void {
    this._standaloneModalsService.close(params);
    if (this._wsPreference.waitToDisplayInitialTour)
      this._wsPreference.waitToDisplayInitialTour = false;
  }
}
