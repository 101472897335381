<div
  [id]="refId"
  [class]="positionAbsolute"
  [ngClass]="{ 'position-absolute': isAbsolute }"
>
  <div class="datepicker-container d-flex flex-column" *ngIf="showDatePicker">
    <div class="datepicker-head">
      <button
        class="datepicker-button"
        [disabled]="minDate && state.year === minDate.getFullYear()"
        (click)="setYear(-1)"
      >
        <i class="icons">keyboard_double_arrow_left</i>
      </button>
      <button
        class="datepicker-button"
        [disabled]="minDate && state.month === minDate.getMonth()"
        (click)="setMonth(-1)"
      >
        <i class="icons">keyboard_arrow_left</i>
      </button>

      <a
        type="button"
        class="datepicker-title"
        [ngClass]="{ 'd-none': viewType === viewTypeEnum.MONTH }"
        (click)="changeView(viewTypeEnum.MONTH)"
      >
        {{ getMonthStr(state.month).slice(0, 3) }}
      </a>
      <a
        type="button"
        class="datepicker-title"
        (click)="changeView(viewTypeEnum.YEAR)"
      >
        {{ state.year }}</a
      >

      <button
        class="datepicker-button"
        [disabled]="maxDate && state.month === maxDate.getMonth()"
        (click)="setMonth(1)"
      >
        <i class="icons">keyboard_arrow_right</i>
      </button>
      <button
        class="datepicker-button"
        (click)="setYear(1)"
        [disabled]="maxDate && state.year === maxDate.getFullYear()"
      >
        <i class="icons">keyboard_double_arrow_right</i>
      </button>
    </div>

    <div class="datepicker-body">
      <ng-container [ngSwitch]="viewType">
        <app-datepicker-days
          *ngSwitchCase="viewTypeEnum.DAY"
          [monthDetails]="state.monthDetails"
          [todayTimestamp]="todayTimestamp"
          [selectedDay]="state.selectedDay"
          [daysMap]="daysMap"
          (flaClick)="onDateClick($event)"
        ></app-datepicker-days>
        <fla-datepicker-month
          *ngSwitchCase="viewTypeEnum.MONTH"
          [monthNumber]="state.month"
          [selectedMotnh]="getMonthStr(state.month)"
          [min]="this.minDate?.getMonth()"
          [max]="this.maxDate?.getMonth()"
          (flaClick)="updateView($event, viewTypeEnum.MONTH)"
        ></fla-datepicker-month>
        <fla-datepicker-year
          *ngSwitchCase="viewTypeEnum.YEAR"
          [selectedYear]="state.year"
          [min]="this.minDate?.getFullYear()"
          [max]="this.maxDate?.getFullYear()"
          (flaClick)="updateView($event, viewTypeEnum.YEAR)"
        ></fla-datepicker-year>
      </ng-container>
    </div>
    <div
      *ngIf="useTimepicker"
      class="timepicker-body m-2 d-flex gap-1 justify-content-center"
      [formGroup]="formTimer"
    >
      <div class="hours">
        <fla-input
          formControlName="hours"
          label=""
          [min]="0"
          [max]="24"
          type="number"
          [isSmall]="true"
          cssInput="cursor-pointer"
          margin="m-0"
          (flaChange)="hoursChanged($event)"
        >
        </fla-input>
      </div>
      :
      <div class="minutes">
        <fla-input
          formControlName="minutes"
          label=""
          [min]="-1"
          [max]="60"
          type="number"
          [isSmall]="true"
          cssInput="cursor-pointer"
          margin="m-0"
          (flaChange)="minutesChanged($event)"
        >
        </fla-input>
      </div>
    </div>
    <div *ngIf="showButtons" class="d-flex justify-content-end gap-3 pb-6">
      <rocket-button
        [isSmall]="true"
        label="Cancelar"
        type="outline"
        (rocketClick)="toggle()"
      >
      </rocket-button>
      <rocket-button
        [isSmall]="true"
        label="Aplicar"
        (rocketClick)="sendNewDate()"
      >
      </rocket-button>
    </div>
  </div>
</div>
