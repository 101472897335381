import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StockTradeComponent } from './stock-trade.component';
import { SearchStockModule } from '../search-stock/search-stock.module';

import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { StockTradeHeaderComponent } from './parts/header/stock-trade-header.component';
import { StockTradeService } from './service/stock-trade.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StockTradeGraficComponent } from './parts/grafic/stock-trade-grafic.component';
import { DynamicFormModule } from '@shared/rocket-components/dynamic-form/dynamic-form.module';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import {
  FlaCheckModule,
  FlaIconModule,
  FlaInputModule,
  FlaSelectModule,
  FlaTabModule,
  RocketButtonModule,
} from '@shared/rocket-components/components/index';
import { FlaMaskModule } from '@shared/rocket-components/directives';
import { FlaFocusAndBlurModule } from '@shared/rocket-components/directives';
import { RTInputCountModule } from '@shared/rocket-components/input-count/input-count.module';
import { FlaBigNumberModule } from '@shared/rocket-components/pipes/big-number';
import { StockTradeContextMenuComponent } from './parts/stock-trade-context-menu/stock-trade-context-menu.component';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';

const COMPONENTS = [
  StockTradeComponent,
  StockTradeHeaderComponent,
  StockTradeGraficComponent,
  StockTradeContextMenuComponent,
];
@NgModule({
  declarations: [COMPONENTS],
  imports: [
    CommonModule,
    SearchStockModule,
    FlaWorkSpaceModule,
    FlaInputModule,
    RTInputCountModule,
    FlaIconModule,
    FlaSelectModule,
    FlaTabModule,
    FlaMaskModule,
    RTDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    FlaBigNumberModule,
    FlaFocusAndBlurModule,
    DynamicFormModule,
    FlaCheckModule,
    TooltipsModule,
    RocketButtonModule,
  ],
  exports: [COMPONENTS],
  providers: [StockTradeService],
})
export class StockTradeModule {}
