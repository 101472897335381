import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { GraphicScreeningService } from './../graphic-screening.service';
import { randomId } from '@shared/rocket-components/utils/functions';
import {
  CategoryAxis,
  CustomAnnotation,
  EHorizontalAnchorPoint,
  EVerticalAnchorPoint,
  EXyDirection,
  FastCandlestickRenderableSeries,
  IRenderableSeries,
  MouseWheelZoomModifier,
  NumberRange,
  NumericAxis,
  TWebAssemblyChart,
  ZoomExtentsModifier,
  ZoomPanModifier,
} from 'scichart';
import { ScichartService } from '@shared/tiger-chart/services/scichart.service';
import { OhlcDataSeries } from 'scichart/Charting/Model/OhlcDataSeries';
import { BusinessProfileService } from '@shared/components/business-profile/business-profile.service';
import { TData } from '@shared/tiger-chart/types/tiger-chart.types';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { AxisService } from '@shared/tiger-chart/services/axis.service';
import { newBigValueFormatter } from 'src/app/utils/utils.functions';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-graphic',
  templateUrl: './graphic.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphicComponent implements OnInit, OnChanges, OnDestroy {
  @Input() idExchange!: string;
  @Input() stock!: string;
  @Input() interval!: number;
  @Input() listPoints!: any;
  @Input() isRetart: boolean = false;
  @Input() infosTickSize!: {
    cdSegment: string;
    dsAsset: string;
    papel: string;
  };
  public refId: string = randomId('graphic_screening');
  private scichartService!: ScichartService;
  private baseChart!: TWebAssemblyChart;

  private xAxis!: CategoryAxis;
  private yAxis!: NumericAxis;
  private candleDataSeries!: OhlcDataSeries;
  private data!: TData;
  private renderableSeriesAdd!: IRenderableSeries;
  private detectChangesubject = new Subject<void>();
  private detectChangesCreateGraphicSubject = new Subject<void>();

  constructor(
    private graphicScreeningService: GraphicScreeningService,
    private businessProfileService: BusinessProfileService,
    private stockChartService: StockChartService,
    @Inject(LOCALE_ID) private locale: string,
    private axisService: AxisService,
    private cdr: ChangeDetectorRef
  ) {
    this.scichartService = new ScichartService(
      undefined,
      this.stockChartService
    );
    this.detectChangesubject.pipe(debounceTime(20)).subscribe(this.initGraph);
    this.detectChangesCreateGraphicSubject
      .pipe(debounceTime(300))
      .subscribe(this.updateGraphic);
  }

  ngOnInit(): void {
    this.detectChangesubject.next();
  }

  ngOnChanges(change: SimpleChanges): void {
    const { stock } = change;
    if (stock?.currentValue || this.isRetart) {
      if (this.data) {
        this.initGraph();
      }
      this.graphicScreeningService
        .getIntervalGraphic(this.stock, +this.idExchange, this.interval)
        .subscribe((res: any) => {
          this.data = res;
          this.detectChangesCreateGraphicSubject.next();
        });
    }
  }
  ngOnDestroy(): void {
    !!this.detectChangesCreateGraphicSubject &&
      this.detectChangesCreateGraphicSubject.unsubscribe();
    !!this.detectChangesubject && this.detectChangesubject.unsubscribe();
  }

  private updateGraphic = () => {
    this.prepareGraphic();
    this.setDataSeries();
    this.createdAnnotations();
  };

  private initGraph = () => {
    this.scichartService
      .initSciChart(this.refId)
      .then((chart: TWebAssemblyChart) => {
        this.baseChart = chart;
        this.cdr.detectChanges();
      });
  };

  private prepareGraphic(): void {
    this.yAxis = this.businessProfileService.buildChartYAxis(
      this.baseChart,
      this.infosTickSize ? this.yAxisLabelFormatter : newBigValueFormatter
    );
    this.baseChart.sciChartSurface.yAxes.add(this.yAxis);

    this.xAxis = new CategoryAxis(this.baseChart.wasmContext, {
      visibleRangeLimit: new NumberRange(0, 550),
      growBy: new NumberRange(0.1, 0.2),
    });
    this.xAxis.labelProvider.formatLabel = this.formatLabel;
    this.baseChart.sciChartSurface.xAxes.add(this.xAxis);
    !!this.data && this.setDataSeries();
    this.cdr.detectChanges();
  }

  private yAxisLabelFormatter = (value: number) => {
    return this.axisService.yAxisLabelFormatter({
      value,
      cdSegment: this.infosTickSize.cdSegment,
      configs: undefined,
      dsAsset: this.infosTickSize.dsAsset,
      indicators: undefined,
      papel: this.infosTickSize.papel,
    });
  };

  private formatLabel = (dataValue: number) => {
    const configs: any = {
      series: { interval: this.interval },
    };
    return this.axisService.xAxisLabelFormatter(
      configs,
      this.locale,
      dataValue,
      false
    );
  };

  private setDataSeries(): void {
    this.candleDataSeries = new OhlcDataSeries(this.baseChart.wasmContext, {
      xValues: this.data.id_point,
      openValues: this.data.vl_open,
      highValues: this.data.vl_high,
      lowValues: this.data.vl_low,
      closeValues: this.data.vl_close,
    });
    this.renderableSeriesAdd = new FastCandlestickRenderableSeries(
      this.baseChart.wasmContext,
      {
        strokeThickness: 1,
        dataSeries: this.candleDataSeries as OhlcDataSeries,
        dataPointWidth: 0.7,
      }
    );
    this.baseChart.sciChartSurface.renderableSeries.add(
      this.renderableSeriesAdd
    );
    this.baseChart.sciChartSurface.chartModifiers.add(
      new MouseWheelZoomModifier({
        xyDirection: EXyDirection.XDirection,
      })
    );
    this.baseChart.sciChartSurface.chartModifiers.add(
      new ZoomPanModifier({
        xyDirection: EXyDirection.XyDirection,
        enableZoom: true,
      })
    );
    this.baseChart.sciChartSurface.chartModifiers.add(
      new ZoomExtentsModifier({
        xyDirection: EXyDirection.XDirection,
      })
    );
    this.stockChartService.removeChartLoading$.next({
      remove: true,
      ref: this.refId,
    });
    this.cdr.detectChanges();
    this.createdAnnotations();
  }

  private createdAnnotations(): void {
    if (!this.listPoints || !Object.keys(this.listPoints).length) return;
    this.listPoints[this.stock].forEach((item: string[]) => {
      const open = item[0];
      const close = item[1] || '0';
      const svgOpen = `<svg xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(-55.416083,-75.548914)">
           <path style="fill:#1cb61c;fill-opacity:0.34117647;stroke:#00b400;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
           d="m 55.47431,83.481251 c 7.158904,-7.408333 7.158904,-7.408333 7.158904,-7.408333 l 7.158906,7.408333 H 66.212668 V 94.593756 H 59.053761 V 83.481251 Z"
           />
           </g>
           </svg>`;
      const svgClose = `<svg xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(-55.416083,-75.548914)">
          <path style="fill:#b22020;fill-opacity:0.34117648;stroke:#990000;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
          d="m 55.47431,87.025547 c 7.158904,7.408333 7.158904,7.408333 7.158904,7.408333 L 69.79212,87.025547 H 66.212668 V 75.913042 h -7.158907 v 11.112505 z"
          />
          </g>
          </svg>`;
      this.mountedAnnotations(+open, svgOpen, false);
      this.mountedAnnotations(+close, svgClose, true);
    });
    this.cdr.detectChanges();
  }

  private mountedAnnotations(item: number, svg: string, isClose: boolean) {
    const x1 = this.data.id_point.findIndex((id: number) => id === item);
    let value = 0;
    if (x1 > -1) {
      !isClose && (value = this.data.vl_low[x1]);
      isClose && (value = this.data.vl_high[x1]);

      this.baseChart.sciChartSurface.annotations.add(
        new CustomAnnotation({
          x1,
          y1: value,
          verticalAnchorPoint: isClose
            ? EVerticalAnchorPoint.Bottom
            : EVerticalAnchorPoint.Top,
          horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
          svgString: svg,
        })
      );
      this.cdr.detectChanges();
    }
  }
}
