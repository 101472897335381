import { throwError } from 'rxjs';
import { IAnnotation } from 'scichart/Charting/Visuals/Annotations/IAnnotation';
import { IChartModifierBase } from 'scichart/Charting/ChartModifiers/ChartModifierBase';
import { IRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/IRenderableSeries';
import {
  SciChartSurface,
  TWebAssemblyChart,
} from 'scichart/Charting/Visuals/SciChartSurface';
import { AxisBase2D } from 'scichart/Charting/Visuals/Axis/AxisBase2D';
import { DpiHelper, SciChartJSDarkv2Theme, SciChartPieSurface } from 'scichart';
import { CustomChartLoader } from '../custom-chart-loader';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { DEFAULT_THEME, LIGHT_THEME } from '../colors';
export class ScichartService {
  private baseChart!: TWebAssemblyChart;
  theme!: any;
  getTheme = (isDark: boolean) => (isDark ? DEFAULT_THEME() : LIGHT_THEME());

  constructor(
    baseChart?: TWebAssemblyChart,
    private stockChartService?: StockChartService
  ) {
    if (baseChart) this.baseChart = baseChart;
  }

  set renderableSeriesAdd(item: IRenderableSeries) {
    try {
      this.validityBaseChart();
      this.baseChart.sciChartSurface.renderableSeries.add(item);
    } catch (e: any) {
      console.error(e);
    }
  }

  set renderableSeriesRemove(item: IRenderableSeries) {
    try {
      this.validityBaseChart();
      this.baseChart.sciChartSurface.renderableSeries.remove(item);
    } catch (e: any) {
      console.error(e);
    }
  }

  set annotationsAdd(item: IAnnotation | IAnnotation[]) {
    try {
      this.validityBaseChart();
      if (Array.isArray(item)) {
        this.baseChart.sciChartSurface.annotations.add(...item);
        return;
      }
      this.baseChart.sciChartSurface.annotations.add(item);
    } catch (e: any) {
      console.error(e);
    }
  }

  set annotationsRemove(item: IAnnotation) {
    try {
      this.validityBaseChart();
      this.baseChart.sciChartSurface.annotations.remove(item);
    } catch (e: any) {
      console.error(e);
    }
  }

  set modifiersAdd(modifier: IChartModifierBase) {
    try {
      this.validityBaseChart();
      this.baseChart.sciChartSurface.chartModifiers.add(modifier);
    } catch (e: any) {
      console.error(e);
    }
  }

  set modifiersRemove(modifier: IChartModifierBase) {
    try {
      this.validityBaseChart();
      this.baseChart.sciChartSurface.chartModifiers.remove(modifier);
    } catch (e: any) {
      console.error(e);
    }
  }

  set axisRemove(axis: AxisBase2D) {
    try {
      this.validityBaseChart();
      const xAxis = this.baseChart.sciChartSurface.xAxes.getById(axis.id);
      if (xAxis) {
        this.baseChart.sciChartSurface.xAxes.remove(axis);
        return;
      }
      this.baseChart.sciChartSurface.yAxes.remove(axis);
    } catch (e: any) {
      console.error(e);
    }
  }

  getRenderableSeriesById<T = IRenderableSeries>(id: string): T {
    let r!: T;
    try {
      this.validityBaseChart();
      r = this.baseChart.sciChartSurface.renderableSeries.getById(id) as any;
    } catch (e: any) {
      console.error(e);
    }
    return r;
  }

  getAnnotationsById<T = IAnnotation>(id: string): T {
    let r!: T;
    try {
      this.validityBaseChart();
      r = this.baseChart.sciChartSurface.annotations.getById(id) as any;
    } catch (e: any) {
      console.error(e);
    }
    return r;
  }

  getModifierById<T = IChartModifierBase>(id: string): T {
    let r!: T;
    try {
      this.validityBaseChart();
      r = this.baseChart.sciChartSurface.chartModifiers.getById(id) as any;
    } catch (e: any) {
      console.error(e);
    }
    return r;
  }

  private validityBaseChart() {
    if (!this.baseChart) {
      throwError(() => new Error('Basechart não especificado!'));
    }
  }

  setBaseChart(baseChart: TWebAssemblyChart) {
    this.baseChart = baseChart;
  }

  public initSciChart(idElement: string) {
    this.theme = this.getTheme(this.stockChartService?.isDarkTheme() ?? true);
    DpiHelper.IsDpiScaleEnabled = false;
    const loader = new CustomChartLoader(this.stockChartService!!, idElement);
    const theme = Object.assign(new SciChartJSDarkv2Theme(), this.theme);
    SciChartSurface.useWasmFromCDN();
    return SciChartSurface.create(idElement, { loader, theme });
  }

  public initPieSciChart(idElement: string, options?: any) {
    this.theme = this.getTheme(this.stockChartService?.isDarkTheme() ?? true);
    DpiHelper.IsDpiScaleEnabled = false;
    const loader = new CustomChartLoader(this.stockChartService!!, idElement);
    const theme = Object.assign(new SciChartJSDarkv2Theme(), this.theme);
    return SciChartPieSurface.create(idElement, {
      loader,
      theme,
      ...options,
    });
  }

  changeThemeHandler = (baseChart: TWebAssemblyChart, isDarkTheme: boolean) => {
    if (!baseChart?.sciChartSurface) return;
    this.theme = this.getTheme(isDarkTheme);
    const theme = Object.assign(new SciChartJSDarkv2Theme(), this.theme);
    baseChart.sciChartSurface.applyTheme(theme);
  };
}
