<td>{{ tradeData.hrTrade }}</td>
<td class="truncate-nm-brokerage">
  {{ tradeData.mnBrokerageBuy
  }}<span
    *ngIf="tradeData.brokerageBuyAsterisk"
    [ngClass]="tradeData.classAgressorSide"
    >*</span
  >
</td>
<td [ngClass]="tradeData.classPrice">{{ tradeData.price }}</td>
<td [ngClass]="tradeData.classQuantity" [ngStyle]="styleBasedOnValue()">
  {{ tradeData.qtty }}
</td>
<td class="truncate-nm-brokerage">
  {{ tradeData.mnBrokerageSell
  }}<span
    *ngIf="tradeData.brokerageSellAsterisk"
    [ngClass]="tradeData.classAgressorSide"
    >*</span
  >
</td>
<td [ngClass]="tradeData.classAgressorSide">
  {{ tradeData.agressorSideValue || '-' }}
</td>
