<ng-container *ngIf="displayIcon">
  <div class="position-absolute">
    <ng-container *ngTemplateOutlet="withoutImage"></ng-container>
  </div>
</ng-container>

<ng-container *ngIf="!errorOnLoad; else withoutImage">
  <img
    [src]="image"
    class="round-sm logo-container"
    [ngClass]="{ 'custom-index': displayIcon }"
    (load)="handleImage('LOADED')"
    (error)="handleImage('ERROR')"
  />
</ng-container>

<ng-template #withoutImage>
  <div
    class="logo-container align-items-center bg-neutral-medium d-flex justify-content-center round-sm"
  >
    <fla-icon name="business" css="text-dark"> </fla-icon>
  </div>
</ng-template>
