export const LINE_TYPES = [
  {id: 0, label: 'Linha', style: 'solid'},
  {id: 1, label: 'Linha Tracejada', style: 'dashed'},
  {id: 2, label: 'Linha Pontilhada', style: 'dotted'}
];

export const LINE_STYLES = {
  solid: {id: 0},
  dashed: {id: 1},
  dotted: {id: 2}
}