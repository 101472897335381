import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { SearchStockComponent } from '@shared/components/search-stock/search-stock.component';
import { DragService } from '@shared/rocket-components/services/ag-grid/drag.service';
import { Subscription } from 'rxjs';
import { ISearchStock, IWorkSpaceComponet } from 'src/app/core/interface';
import { formatByTick, isNullOrUndefined } from 'src/app/utils/utils.functions';
import { SUPER_DOM_ELEMENT_ID } from '../../enum/super-dom.enum';

@Component({
  selector: 'app-super-dom-header',
  templateUrl: './super-dom-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuperDomHeaderComponent implements AfterViewInit, OnDestroy {
  @ViewChild('searchStock') searchStock!: SearchStockComponent;
  @Input() public refComponent!: string;
  @Input() public headerOptions!: any;
  @Input() component!: IWorkSpaceComponet;
  @Input() public stockSelected!: ISearchStock;
  @Output() stockSelect: EventEmitter<ISearchStock> =
    new EventEmitter<ISearchStock>();
  private dragEvents$!: Subscription;
  public readonly SUPER_DOM_ELEMENT_ID = SUPER_DOM_ELEMENT_ID;
  @Input() set balance(balance: number) {
    balance = isNullOrUndefined(balance) ? 0 : balance;
    this._balance = `R$ ${formatByTick(balance.toString())}`;
  }
  public _balance = '';
  public typesOut = ['CRYPTO'];

  constructor(private dragService: DragService) {}

  ngAfterViewInit() {
    this.dragEvents$ = this.dragService
      .onEvents(this.refComponent)
      .subscribe((data) => {
        this.selectStockByCdStock(data.data.cd_stock);
      });
  }

  ngOnDestroy() {
    this.dragEvents$ && this.dragEvents$.unsubscribe();
  }

  selectStock = (stock: ISearchStock) => this.stockSelect.emit(stock);

  selectStockByCdStock(cdStock: string) {
    this.searchStock.selectStockByCdStock(cdStock);
  }
}
