<div
  class="overflow-auto pb-2 d-inline-flex gap-3 justify-content-between pt-2 w-100"
>
  <ng-container *ngFor="let broker of brokers">
    <div class="bg-neutral-strong card w-50 card-min-width">
      <div class="card-body">
        <div class="h-100 d-flex flex-column justify-content-between">
          <div class="align-items-center d-inline-flex fs-5 gap-3 pb-4">
            <app-broker-logo
              [logo]="broker.brokerLogoUrl"
              iconSize="icon-size-lg"
              [imageSize]="40"
            ></app-broker-logo>
            <div class="d-flex flex-column gap-2 justify-content-between w-100">
              <div
                class="d-inline-flex align-items-center justify-content-between gap-3 w-100"
              >
                <span class="fs-base fw-bold">
                  {{ broker.nmBrokerNickname }}
                </span>
                <ng-container
                  *ngIf="broker.idBroker === 29 || broker.isTrademapPlan"
                >
                  <div class="d-flex">
                    <span
                      class="tag bg-neutral-smooth text-neutral-strongest p-1 px-2 round-lg fs-6"
                    >
                      {{
                        broker.isTrademapPlan ? 'R$ 143,84/mês' : 'Gratuito*'
                      }}
                    </span>
                  </div>
                </ng-container>
              </div>
              <span class="fw-bold fs-6">{{ broker.trialDescription }}</span>
            </div>
          </div>

          <div class="d-flex flex-column gap-2 pb-2 h-100">
            <ng-container *ngFor="let condiction of broker.brokerCondition">
              <div
                class="align-items-center d-inline-flex gap-1 justify-content-start"
              >
                <span
                  class="material-icons-outlined icon-size-tiny text-feedback-success"
                >
                  done
                </span>
                <span class="fs-6">{{ condiction }}</span>
              </div>
            </ng-container>

            <ng-container *ngIf="broker.brokerAdvantages">
              <ul
                class="p-0 m-0"
                [ngClass]="{ 'pl-4': !broker.isTrademapPlan }"
              >
                <ng-container *ngFor="let advantage of broker.brokerAdvantages">
                  <li
                    [class]="
                      broker.isTrademapPlan
                        ? 'fs-7 mb-2 list-group'
                        : 'fs-6 mb-2'
                    "
                  >
                    {{ advantage }}
                  </li>
                </ng-container>
              </ul>
            </ng-container>
          </div>

          <ng-container *ngIf="broker.isTrademapPlan; else defaultButtons">
            <button
              type="button"
              class="btn btn-primary btn-sm w-100"
              (click)="handleBenefit('URL', broker.onboardingLink)"
            >
              Quero assinar
            </button>
          </ng-container>
          <ng-template #defaultButtons>
            <div
              class="w-100 d-inline-flex align-items-center justify-content-between gap-3"
            >
              <button
                type="button"
                class="btn btn-fill-primary btn-sm w-50"
                (click)="handleBenefit('URL', broker.onboardingLink)"
              >
                Abrir conta
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm w-50"
                (click)="handleBenefit('MODAL', broker.nmBrokerNickname)"
              >
                Quero me conectar
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="displayPaymentWarning">
  <span class="fs-7 text-neutral-smooth w-100 text-right d-block">
    *Será cobrado uma mensalidade caso os critérios não sejam atingidos
  </span>
</ng-container>
