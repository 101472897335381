import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  TTokenAuthenticator,
  TTokenAuthenticatorConfigurationStep,
  TTokenAuthenticatorConfigurationSteps,
} from '../../types';
import { FormControl } from '@angular/forms';
import { ToastService } from '@shared/services';
import { IAPIServiceResponse } from '@shared/services/api/trademap/v1/interface/apiResponse';
import { ModalTokenService } from '../../modal-token.service';
import { OtpService } from '@shared/services/api/authentication/v3/otp.service';

@Component({
  selector: 'app-token-authenticator-other-apps',
  templateUrl: './token-authenticator-other-apps.component.html',
  styleUrls: ['./token-authenticator-other-apps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TokenAuthenticatorOtherAppsComponent implements OnInit, OnDestroy {
  @Input() authenticator!: TTokenAuthenticator;
  @Output() resendConfigRequest: EventEmitter<null> = new EventEmitter();
  @Output() changeLoading: EventEmitter<boolean> = new EventEmitter();
  @Output() disableButtonChange: EventEmitter<boolean> = new EventEmitter();

  configRequestSendStatus = false;
  loading = false;
  resendLabel = `Reenviar código`;
  resendDisabled = true;
  showPassword = false;
  step: TTokenAuthenticatorConfigurationStep =
    TTokenAuthenticatorConfigurationSteps.TOKEN;
  steps = TTokenAuthenticatorConfigurationSteps;
  tokenCode: FormControl = new FormControl('');
  userPassword: FormControl = new FormControl('');
  smsCode: FormControl = new FormControl('');
  QRCodeUrl = '';
  numberMask: string = '000000';

  private _interval!: any;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _optService: OtpService,
    private _toastService: ToastService,
    private _modalTokenService: ModalTokenService
  ) {}

  ngOnInit(): void {
    if (this._modalTokenService.alreadySelectedAuthenticator) {
      this.userPassword.setValue(
        this._modalTokenService.alreadySelectedAuthenticator.password
      );
      this.sendNewSeed(true);
    }
  }

  ngOnDestroy(): void {
    this._modalTokenService.alreadySelectedAuthenticator = null;
  }

  checkFormStatus() {
    if (this.step === this.steps.TOKEN) {
      this.userPassword.value.length >= 6 &&
        this.disableButtonChange.emit(false);
      return;
    }
    if (this.step === this.steps.QRCODE) {
      this.smsCode.value.length >= 6 && this.disableButtonChange.emit(false);
      return;
    }

    this.tokenCode.value.length == 6 && this.disableButtonChange.emit(false);
  }

  confirm() {
    if (this.step === this.steps.TOKEN) {
      this.sendNewSeed();
      return;
    }

    if (this.step === this.steps.QRCODE) {
      this.sendQRCodeRequest();
      return;
    }

    this.sendValidationRequest();
  }

  sendNewSeed(ecryptedPassword: boolean = false) {
    this.changeLoading.emit(true);
    this._optService
      .sendAuthenticatorConfigMessage(
        this.userPassword.value,
        this.authenticator.id,
        ecryptedPassword
      )
      .subscribe((resp: IAPIServiceResponse) => {
        this.changeLoading.emit(false);
        if (!resp.success) {
          const msg = resp.message
            ? resp.message
            : 'Não foi possível enviar a solicitação de alteração. Por favor tente novamente';
          this._toastService.showToast('error', msg);
          this._cdr.detectChanges();
          this.userPassword.setValue('');
          return;
        }
        this.disableButtonChange.emit(true);
        this.step = this.steps.QRCODE;
        this._setTimer(+resp.message);
        this._cdr.detectChanges();
      });
  }

  sendQRCodeRequest() {
    this.changeLoading.emit(true);
    this._optService
      .getQRCodeUrl(this.smsCode.value, this.authenticator.id)
      .subscribe((resp: IAPIServiceResponse) => {
        this.changeLoading.emit(false);
        if (!resp.success) {
          const msg = resp.message
            ? resp.message
            : 'Não foi possível enviar a solicitação de validação do código. Por favor tente novamente';
          this._toastService.showToast('error', msg);
          this._cdr.detectChanges();
          return;
        }
        this.disableButtonChange.emit(true);
        this.QRCodeUrl = resp.message;
        this.step = this.steps.VALIDATION;
        this._cdr.detectChanges();
      });
  }

  private _setTimer(responseTime: number) {
    let time = responseTime;
    this.resendLabel = `Reenviar código (${time}s)`;
    this.resendDisabled = true;
    this._cdr.detectChanges();
    this._interval && clearInterval(this._interval);
    this._interval = setInterval(() => {
      if (!time) {
        this.resendLabel = 'Reenviar código';
        this.resendDisabled = false;
        clearInterval(this._interval);
        this._cdr.detectChanges();
        return;
      }
      this.resendLabel = `Reenviar código (${time}s)`;
      time = time - 1;
      this._cdr.detectChanges();
    }, 1000);
  }

  sendValidationRequest() {
    this.changeLoading.emit(true);
    this._cdr.detectChanges();
    this._optService
      .activateAuthenticatorConfig(
        this.tokenCode.value,
        this.authenticator.id,
        false
      )
      .subscribe((resp: IAPIServiceResponse) => {
        if (!resp.success) {
          const msg = resp.message
            ? resp.message
            : 'Erro ao tentar conectar. Por favor, tente novamente.';
          this._toastService.showToast('error', msg);
          this.changeLoading.emit(false);
          this._cdr.detectChanges();
          return;
        }
        this._modalTokenService.saveAuthenticator(
          this.authenticator,
          this.tokenCode.value
        );
      });
  }
}
