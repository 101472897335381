<button
  class="btn"
  [title]="titleTxt"
  [style.min-height.px]="minHeight"
  [ngClass]="{
    'w-100 btn-fill-primary fs-6 d-inline-flex align-items-center justify-content-start':
      label,
    'btn-icon fs-6 mx-2': !label
  }"
  (click)="headerClick.emit(true)"
>
  <div [style.min-width.px]="24">
    <ng-container *ngIf="useFlaIcon; else customIcon">
      <fla-icon
        [name]="iconName"
        [css]="flaCss"
        [style.min-width.px]="minWidth"
      ></fla-icon>
    </ng-container>
    <ng-template #customIcon>
      <app-rocket-icons [name]="iconName"> </app-rocket-icons>
    </ng-template>
  </div>

  <span *ngIf="label" class="ml-2 text-light text-nowrap fs-6">
    {{ label }}
  </span>
</button>
