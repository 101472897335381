import { Dictionary } from '@core/models';
import {
  BOOK_COMPONENT,
  ORDER_HISTORY_COMPONENT,
  STOCK_CHART_COMPONENT,
  STOCK_TABLE_COMPONENT,
  SUPER_DOM_COMPONENT,
  TIMES_TRADES_COMPONENT,
  BOLETA_COMPRA_BASECOMPONENT,
  BOLETA_VENDA_BASECOMPONENT,
} from './components';

export const COMPONENTS_LIST: Dictionary<any> = new Dictionary();

COMPONENTS_LIST.set('LISTAS', [
  STOCK_TABLE_COMPONENT(),
  ORDER_HISTORY_COMPONENT(),
]);

COMPONENTS_LIST.set('FERRAMENTAS', [
  STOCK_CHART_COMPONENT(),
  BOOK_COMPONENT(),
  SUPER_DOM_COMPONENT(),
  TIMES_TRADES_COMPONENT(),
]);

COMPONENTS_LIST.set('BOLETAS', [
  BOLETA_COMPRA_BASECOMPONENT(),
  BOLETA_VENDA_BASECOMPONENT(),
]);

export interface OptionsComponents {
  top?: string;
  left?: string;
  height?: number;
  width?: number;
  minWidth?: string;
  data?: any;
  link?: boolean;
  unit?: string;
}
