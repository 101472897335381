import { Injectable, WritableSignal, computed, signal } from '@angular/core';
import { deepClone } from '@shared/rocket-components/utils/functions';
import {
  ALERT_MENU_ENUM,
  ALERT_STATUS_ENUM,
  IAlert,
  IOrders,
  IWorkSpaceComponet,
} from 'src/app/core/interface';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';
import { WorkSpaceConfigs } from '@core/workspace';
import { OrderTabsContextMenuComponent } from '../grid/order-tabs-context-menu/order-tabs-context-menu.component';
import { OrdersHistoryService } from './orders-history.service';
import { OrdersService } from '@shared/services/orders.service';
import { RocketModalService } from '@shared/rocket-components/components';
import { Dictionary } from '@core/models';
import { OrderDetailsModalComponent } from '../modals/order-details-modal/order-details-modal.component';
import { TRIGGERED_THE_EVENT } from '@shared/services/core/list-stocks/list-stocks.const';
import { GridApi } from 'ag-grid-community';
import { Subject, Subscription, map } from 'rxjs';
import { OrdersTabService } from './orders-tab.service';
import { IntrojsService } from '@core/introjs/introjs.service';
import { ConfigHeaderColumnsModalComponent } from '../modals/config-header-columns-modal/config-header-columns-modal.component';
import { ExportDataIdEnum } from '@shared/components/export-data/export-data.enum';
import { ExportDataConverterService } from '@shared/services/export-data-converter.service';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import { TABS, TABS_TYPE } from '../constants/orders-history.const';
import { PositionTabContextMenuComponent } from '../grid/position-tab-context-menu/position-tab-context-menu.component';
import { AlertTabContextMenuComponent } from '../grid/alert-tab-context-menu/alert-tab-context-menu.component';
import { PinnedRowsConfig } from '../interfaces/orders-history.interface';
import { AlertService } from '@shared/services/api/trademap/v1/alert.service';
import { OrderHistoryHeaderConfigComponent } from '../orders-history-header/header-config/header-config.component';
import {
  GridIColumnVisibleInService,
  RocketGridService,
} from '@shared/rocket-grid';
import { ORDER_OPEN_STATUS_DIC } from '@shared/dictionary/order.dictionary';

interface OrderHistoryComponentConfig {
  isFiltered: boolean;
  isVisible: boolean;
  isTour: boolean;
  keyHeader: string;
  filterIsActive: boolean;
  isSorted: boolean;
  canHideTab: boolean;
  isDetailsOrder: boolean;
  totalLineIsVisible: boolean;
  showTotalRow: boolean;
  selectedRow: any | undefined;
  headerTabs: any[];
  tabInfs: any;
}

interface OrderHistoryComponentHandlers {
  selectedTab: any | undefined;
  gridApi: GridApi | undefined;
  columnApi: any | undefined;
}

export enum OrdersHistoryContextMenuEvent {
  SetInvisibleColumn = 'SETINVISIBLECOLUMN',
  EditOrder = 'EDITORDER',
  CancelOrder = 'CANCELORDER',
  DetailsOrder = 'DETAILSORDER',
  ResetDefault = 'RESETDEFAULT',
  ResetFilter = 'RESETFILTER',
  RestartSort = 'RESTARTSORT',
  OpenModalPreferences = 'OPENMODALPREFERENCES',
  ExportData = 'EXPORTDATA',
  TourHandler = 'TOURHANDLER',
  ToggleTotalLine = 'TOGGLETOTALLINE',
  EditAlert = 'EDITALERT',
  RemoveAlert = 'REMOVEALERT',
  ReopenAlert = 'REOPENALERT',
  hideTab = 'HIDETAB',
  resetTabsDefault = 'RESETTABDEFAULT',
  openTabPreferences = 'OPENTABPREFERENCES',
}

@Injectable({
  providedIn: 'root',
})
export class OrdersHistoryConfigService {
  private _configDict: Dictionary<WritableSignal<OrderHistoryComponentConfig>> =
    new Dictionary();
  private _handlersDict: Dictionary<OrderHistoryComponentHandlers> =
    new Dictionary();
  private _tourSub!: Subscription;
  tabsPositionTypes = [
    TABS_TYPE.INTRADIA,
    TABS_TYPE.SWINGTRADING,
    TABS_TYPE.POSITION,
  ];
  onColumnHide$: Subject<any> = new Subject();
  onTabHide$: Subject<{ tabInfs: any; componentRef: string }> = new Subject();

  constructor(
    private _contextMenuService: ContextMenuService,
    private _workSpaceConfigs: WorkSpaceConfigs,
    private _ordersHistoryService: OrdersHistoryService,
    private _ordersService: OrdersService,
    private _rocketModalService: RocketModalService,
    private _ordersTabService: OrdersTabService,
    private _introJsService: IntrojsService,
    private _exporter: ExportDataConverterService,
    private _alertService: AlertService,
    private _rocketGridService: RocketGridService
  ) {}

  addNewComponent(componentRef: string, values: OrderHistoryComponentConfig) {
    this._configDict.set(componentRef, signal(values));
  }

  setConfig(
    componentRef: string,
    config: Partial<OrderHistoryComponentConfig>
  ) {
    this._configDict
      .get(componentRef)
      ?.update((currentValue) => ({ ...currentValue, ...config }));
  }

  setHandler(
    componentRef: string,
    config: Partial<OrderHistoryComponentHandlers>
  ) {
    this._handlersDict.set(componentRef, config);
  }

  getComponentConfig(componentRef: string) {
    return this._configDict.get(componentRef);
  }

  openOrderTabsContextMenu(
    componentRef: string,
    config: any,
    gridApi?: GridApi,
    columnApi?: any,
    isCardView?: any
  ): void {
    const selectedRow: IOrders = isCardView
      ? config.selectedCard
      : config.rowSelect?.rowData;
    const canCancelOrEdit =
      selectedRow && ORDER_OPEN_STATUS_DIC.has(selectedRow.cd_order_status);
    this.setConfig(componentRef, {
      ...config,
      selectedRow,
    });
    if (!isCardView) {
      this._handlersDict.set(componentRef, {
        gridApi,
        columnApi,
      });
    }

    OrderTabsContextMenuComponent.openContextMenu(
      this._contextMenuService,
      componentRef,
      { clientX: config.event.clientX, clientY: config.event.clientY },
      false,
      config!.isVisible,
      selectedRow !== undefined,
      config!.isFiltered,
      config!.isSorted,
      config!.canHideTab,
      !!selectedRow && !!selectedRow.id_order,
      config!.keyHeader,
      isCardView,
      canCancelOrEdit
    );
  }

  openPositionTabContextMenu(
    componentRef: string,
    config: any,
    selectedTab?: any,
    gridApi?: GridApi,
    columnApi?: any,
    isCardView?: any
  ): void {
    const selectedRow = isCardView
      ? config.selectedCard
      : config.rowSelect?.rowData;
    this.setConfig(componentRef, {
      ...config,
      selectedRow,
    });

    if (!isCardView) {
      this._handlersDict.set(componentRef, {
        selectedTab,
        gridApi,
        columnApi,
      });
    } else {
      this._handlersDict.set(componentRef, {
        selectedTab,
      });
    }

    PositionTabContextMenuComponent.openContextMenu(
      this._contextMenuService,
      componentRef,
      { clientX: config.event.clientX, clientY: config.event.clientY },
      config!.isTour,
      config!.isVisible,
      selectedRow !== undefined,
      config!.isFiltered,
      config!.isSorted,
      config!.keyHeader,
      config!.disabledReset,
      config!.showTotalRow,
      isCardView
    );
  }

  openAlertTabContextMenu(
    componentRef: string,
    config: any,
    selectedTab?: any,
    gridApi?: GridApi,
    columnApi?: any,
    isCardView?: any
  ): void {
    const selectedRow = isCardView
      ? config.selectedCard
      : config.rowSelect?.rowData;
    this.setConfig(componentRef, {
      ...config,
      selectedRow,
    });
    if (!isCardView) {
      this._handlersDict.set(componentRef, {
        selectedTab,
        gridApi,
        columnApi,
      });
    } else {
      this._handlersDict.set(componentRef, {
        selectedTab,
      });
    }

    AlertTabContextMenuComponent.openContextMenu(
      this._contextMenuService,
      componentRef,
      { clientX: config.event.clientX, clientY: config.event.clientY },
      config!.isTour,
      config!.isVisible,
      selectedRow !== undefined,
      config!.isFiltered,
      config!.isSorted,
      config!.keyHeader,
      config!.disabledReset,
      isCardView
    );
  }

  openHeaderTabsContextMenu(
    componentRef: string,
    config: any,
    tabInfs: any
  ): void {
    this.setConfig(componentRef, {
      ...config,
      tabInfs,
    });

    OrderHistoryHeaderConfigComponent.openContextMenu(
      this._contextMenuService,
      componentRef,
      { clientX: config.event.clientX, clientY: config.event.clientY },
      config!.isTour,
      config!.isVisible,
      tabInfs,
      config!.hasExportBtn
    );
  }

  startContextMenuListener(
    componentRef: string,
    component: IWorkSpaceComponet
  ) {
    this.setConfig(componentRef, {
      isVisible: true,
      isFiltered: false,
      isTour: false,
      keyHeader: '',
      showTotalRow: true,
    });

    const config = computed(() => this._configDict.get(componentRef)!());

    return this._contextMenuService
      .listenActionEvent<OrdersHistoryContextMenuEvent>(componentRef)
      .subscribe((payload) => {
        const { action } = payload;
        const handlers = this._handlersDict.get(componentRef);
        const currentConfig = config();

        if (!currentConfig || !handlers) return;

        switch (action) {
          case OrdersHistoryContextMenuEvent.ToggleTotalLine:
            this._toggleTotalLineVisibility(
              !currentConfig.showTotalRow,
              component,
              handlers.selectedTab,
              componentRef
            );
            break;
          case OrdersHistoryContextMenuEvent.SetInvisibleColumn:
            this.setInvisibleColumn(
              config().keyHeader!,
              true,
              handlers.selectedTab,
              component,
              componentRef
            );
            break;
          case OrdersHistoryContextMenuEvent.EditOrder:
            this._workSpaceConfigs.openStockTrade = {
              order: config().selectedRow,
              isEditMode: true,
            };
            break;
          case OrdersHistoryContextMenuEvent.CancelOrder:
            this._ordersService.cancelOrders([config().selectedRow]);
            break;
          case OrdersHistoryContextMenuEvent.DetailsOrder:
            this._rocketModalService.open(OrderDetailsModalComponent, {
              centered: true,
              keyboard: true,
              size: 'xl',
              data: deepClone(config().selectedRow),
            });
            break;
          case OrdersHistoryContextMenuEvent.ResetDefault:
            handlers.gridApi &&
              this._setGridDefaultOptions(
                handlers.selectedTab,
                component,
                handlers.gridApi
              );
            break;
          case OrdersHistoryContextMenuEvent.ResetFilter:
            handlers.gridApi &&
              this._resetFilters(
                component,
                handlers.gridApi,
                handlers.selectedTab
              );
            break;
          case OrdersHistoryContextMenuEvent.RestartSort:
            this._resetSort(
              component,
              handlers.columnApi,
              handlers.selectedTab
            );
            break;
          case OrdersHistoryContextMenuEvent.OpenModalPreferences:
            this._openModalConfigHeaders(component, handlers.selectedTab);
            break;
          case OrdersHistoryContextMenuEvent.ExportData: {
            const gridApi = handlers?.gridApi;
            gridApi &&
              this.exportData(
                handlers.selectedTab,
                gridApi,
                handlers.columnApi
              );
            break;
          }
          case OrdersHistoryContextMenuEvent.TourHandler:
            this.tourHandler(component, componentRef);
            break;
          case OrdersHistoryContextMenuEvent.EditAlert:
            if (
              !this._verifyAlertDataIsValid(
                ALERT_MENU_ENUM.EDIT_ALERT,
                currentConfig.selectedRow
              )
            )
              return;
            this._alertService.displayAlertSetting(
              'EDIT',
              currentConfig.selectedRow
            );
            break;
          case OrdersHistoryContextMenuEvent.RemoveAlert:
            if (
              !this._verifyAlertDataIsValid(
                ALERT_MENU_ENUM.REMOVE_ALERT,
                currentConfig.selectedRow
              )
            )
              return;
            this._alertService.displayAlertSetting(
              'DELETE',
              currentConfig.selectedRow
            );
            break;
          case OrdersHistoryContextMenuEvent.ReopenAlert:
            if (
              !this._verifyAlertDataIsValid(
                ALERT_MENU_ENUM.REOPEN_ALERT,
                currentConfig.selectedRow
              )
            )
              return;
            this._alertService.displayAlertSetting(
              'REFRESH',
              currentConfig.selectedRow
            );
            break;
          case OrdersHistoryContextMenuEvent.hideTab:
            this.hideTab(currentConfig.tabInfs, componentRef);
            break;
          case OrdersHistoryContextMenuEvent.resetTabsDefault:
            this._ordersHistoryService.resetDefaultColumns(component.id);
            break;
          case OrdersHistoryContextMenuEvent.openTabPreferences:
            this._ordersTabService.handleListOrdersPreferences(componentRef);
            break;
        }
      });
  }

  hideTab(tabInfs: any, componentRef: string): void {
    this.onTabHide$.next({ tabInfs, componentRef });
  }

  resetDefault(refComponent: string): void {
    const tabs: string[] = [
      TABS.SWINGTRADING.key,
      TABS.ORDER_OPEN.key,
      TABS.ORDER_EXEC.key,
      TABS.ORDER_CLOSE.key,
      TABS.ORDER_TOTAL.key,
      TABS.ALERTS.key,
    ];
    this._ordersTabService.changeTabsHeader(tabs, refComponent);
  }

  openTabModalPreferences(refComponent: string): void {
    this._ordersTabService.handleListOrdersPreferences(refComponent);
  }

  protected _triggeredEvent(refComp: string, componentId: string): void {
    localStorage.setItem(TRIGGERED_THE_EVENT, `${refComp}/${componentId}`);
  }

  private _toggleTotalLineVisibility = (
    show: boolean,
    component: IWorkSpaceComponet,
    selectedTab: any,
    componentRef: string
  ) => {
    const defaultConfig: PinnedRowsConfig = {
      swingtrading: true,
      position: true,
      intradia: true,
      order_close: true,
      order_exec: true,
      order_open: true,
      order_total: true,
      alerts: true,
    };
    const key = selectedTab.key.toLowerCase();
    const metadata = this._getComponentMetadata(component);
    const pinned = metadata.pinnedRows || defaultConfig;
    pinned[key] = show;
    metadata.pinnedRows = pinned;
    this._configDict
      .get(componentRef)
      ?.update((currentValue) => ({ ...currentValue, showTotalRow: show }));
    this._ordersHistoryService.updateMetadata(component);
  };

  resetTotalRow(
    componentRef: string,
    component: IWorkSpaceComponet,
    gridTotalApi: GridApi,
    selectedTab: any
  ) {
    const config = this._configDict.get(componentRef);
    if (!config) return;

    const showTotalRow = selectedTab?.key
      ? this.hasPinnedRow(component, selectedTab?.key)
      : false;

    this._configDict
      .get(componentRef)
      ?.update((currentValue) => ({ ...currentValue, showTotalRow }));

    if (!showTotalRow && gridTotalApi && !gridTotalApi['destroyCalled']) {
      gridTotalApi.destroy();
    }
  }

  pinnedRowsConfig(component: IWorkSpaceComponet): PinnedRowsConfig {
    const defaultPinnedRows: PinnedRowsConfig = {
      swingtrading: true,
      position: true,
      intradia: true,
      order_close: true,
      order_exec: true,
      order_open: true,
      order_total: true,
      alerts: true,
    };
    const metadata = this._getComponentMetadata(component);

    metadata.pinnedRows = {
      ...defaultPinnedRows,
      ...metadata.pinnedRows,
    };
    return metadata.pinnedRows;
  }

  hasPinnedRow = (component: IWorkSpaceComponet, key: string) =>
    (this.pinnedRowsConfig(component) as any)[key.toLowerCase()];

  private setInvisibleColumn(
    filed: string,
    value: boolean,
    selectedTab: any,
    component: IWorkSpaceComponet,
    componentRef: string
  ): void {
    this._getComponentMetadata(component).gridConfigs[selectedTab.ref][
      filed
    ].hide = value;
    this._ordersHistoryService.updateMetadata(component);
    const params: GridIColumnVisibleInService = {
      refComponent: componentRef,
      visible: value,
      keys: [filed],
    };
    this._rocketGridService.setInvisibleColumn(params);
    this.onColumnHide$.next(selectedTab);
  }

  private _getComponentMetadata(component: IWorkSpaceComponet) {
    return component.metadata.component;
  }

  private _setGridDefaultOptions(
    selectedTab: any,
    component: IWorkSpaceComponet,
    gridApi: GridApi
  ) {
    const colDefinition = this.setGridConfigMetadata(
      selectedTab.ref,
      component
    );
    const columnsDefault = this._ordersHistoryService.getColumnsdefByRef(
      selectedTab.ref
    );
    const columnDef = this._customColumns(colDefinition, columnsDefault);
    gridApi.setColumnDefs(columnDef);
    this._updateMetadata(component);
  }

  setGridConfigMetadata(ref: string, component: IWorkSpaceComponet) {
    const metadata = this._getComponentMetadata(component);
    if (!metadata.gridConfigs) metadata.gridConfigs = {};
    const config = this._ordersHistoryService.getGridConfigByRef(ref);
    metadata.gridConfigs[ref] = config;
    return config;
  }

  private _customColumns(config: any, columnDefs: any[]): any[] {
    const columns: any[] = [];
    if (!config) return columnDefs;
    Object.keys(config).forEach((field: string) => {
      const col = columnDefs.find(
        (item: any) =>
          item.field === field ||
          (item?.headerComponentParams &&
            item?.headerComponentParams[1] === field)
      );
      !!col && columns.push({ ...col, ...config[field] });
    });
    return columns;
  }

  private _updateMetadata(component: IWorkSpaceComponet) {
    this._ordersHistoryService.updateMetadata(component);
  }

  public tourHandler(
    component: IWorkSpaceComponet,
    componentRef: string
  ): void {
    this._ordersTabService.tourTabs(componentRef);
    this._introJsService.ordersHistory(component.id);
    setTimeout(() => {
      const position = document
        .getElementById(component.id)
        ?.getBoundingClientRect();
      this.openOrderTabsContextMenu(componentRef, {
        clientX: position!.x * 2.5,
        clientY: position!.y - 25,
      });
    }, 200);

    this._tourSub = this._introJsService
      .onStart()
      .pipe(map((response) => response.action))
      .subscribe((action) => {
        if (['DISPLAY_CONTEXT', 'HIDE_CONTEXT'].includes(action))
          this._handleContextVisibilityOnTour(action === 'DISPLAY_CONTEXT');
        if (action === 'CLOSED') this._closeContext(componentRef);
      });
  }

  private _handleContextVisibilityOnTour(displayContext: boolean): void {
    const element = document.querySelectorAll<HTMLElement>(
      'app-grid-config-order > div'
    )[0];
    element.style.zIndex = displayContext ? '99' : '-1';
    element.style.display = displayContext ? 'block' : 'none';
  }

  private _closeContext(componentRef: string): void {
    const findAll = document.querySelectorAll('app-grid-config-order');
    findAll && findAll.length && findAll.forEach((elem) => elem.remove());
    this._ordersTabService.tourTabs(componentRef, true);
    this._tourSub.unsubscribe();
  }

  private _resetFilters = (
    component: IWorkSpaceComponet,
    gridApi: GridApi,
    selectedTab: any
  ) => {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged();
    this._getComponentMetadata(component).gridFilters[
      selectedTab.component_type
    ] = {};
    this._updateMetadata(component);
  };

  private _resetSort(
    component: IWorkSpaceComponet,
    columnApi: any,
    selectedTab: any
  ) {
    this._getComponentMetadata(component).gridSort[selectedTab.ref] = null;
    columnApi.applyColumnState({
      defaultState: { sort: null },
    });
    this._updateMetadata(component);
  }

  private _openModalConfigHeaders(
    component: IWorkSpaceComponet,
    selectedTab: any
  ): void {
    const modalRef = this._rocketModalService.open(
      ConfigHeaderColumnsModalComponent,
      {
        centered: true,
        keyboard: false,
        data: {
          columns: structuredClone(
            this._getComponentMetadata(component).gridConfigs[selectedTab.ref]
          ),
          type: selectedTab.component_type,
        },
        size: 'lg',
      }
    );

    modalRef.afterDismissed.subscribe(
      (res: any) =>
        !res.closed &&
        this._setColumnsVisibleInvisible(res, component, selectedTab)
    );
  }

  private _setColumnsVisibleInvisible(
    columns: any,
    component: IWorkSpaceComponet,
    selectedTab: any
  ): void {
    const columnsOld: any =
      this._getComponentMetadata(component).gridConfigs[selectedTab.ref];
    columns.forEach((item: any) => {
      columnsOld[item.field].hide = !item.isCheck;
    });
    this._updateMetadata(component);
    this.onColumnHide$.next(TABS[selectedTab.key]);
  }

  exportData(selectedTab: any, gridApi: GridApi, columnApi: any) {
    let dataToExport;
    let sufix = '';
    const exportId = ExportDataIdEnum.ORDENS_POSICOES;
    let parseData = true;

    if (selectedTab.component_type === 'ORDER') {
      const gridOrders: any[] = [];
      gridApi.forEachNode((e) => gridOrders.push(e.data));
      dataToExport = this._exporter.exporterFunctions[exportId](
        gridOrders,
        columnApi
      );
      sufix = selectedTab.title.toLowerCase();
    }
    if (this.isAnyPositionTab(selectedTab)) {
      dataToExport =
        this._exporter.exporterFunctions[ExportDataIdEnum.POSICOES](gridApi);
      sufix = DaytradeService.getTextWithTradeMode(
        selectedTab.title
      ).toLowerCase();
      parseData = false;
    }
    if (this._isOnTab(TABS_TYPE.ALERTS, selectedTab)) {
      dataToExport =
        this._exporter.exporterFunctions[ExportDataIdEnum.ALERTAS](gridApi);
      sufix = selectedTab.title.toLowerCase();
      parseData = false;
    }
    if (dataToExport)
      this._exporter.exportData(exportId, dataToExport, sufix, parseData);
  }

  private _isOnTab(key: string, selectedTab: any): boolean {
    return selectedTab.key === key;
  }

  isAnyPositionTab(selectedTab: any): boolean {
    return this.tabsPositionTypes.includes(selectedTab.component_type);
  }

  private _verifyAlertDataIsValid(action: string, alert: IAlert): boolean {
    if (!alert) return false;

    if (
      (action === ALERT_MENU_ENUM.EDIT_ALERT ||
        action === ALERT_MENU_ENUM.REMOVE_ALERT) &&
      alert.cd_status === ALERT_STATUS_ENUM.ACTIVE
    )
      return true;

    if (
      action === ALERT_MENU_ENUM.REOPEN_ALERT &&
      alert.cd_status === ALERT_STATUS_ENUM.COMPLETED
    )
      return true;

    return false;
  }
}
