import { Component, Inject, OnInit } from '@angular/core';
import {
  RocketModalRef,
  RocketModalService,
  ROCKET_MODAL_DATA,
  RocketButtonModule,
  RocketModalModule,
  FlaCheckModule,
} from '@shared/rocket-components/components/index';
import { ORDER, POSITION, ALERTS } from './config-columns-view.contants';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-config-header-columns-modal',
  templateUrl: './config-header-columns-modal.component.html',
  styles: [
    `
      .position-top {
        top: -14px;
      }
    `,
  ],
  standalone: true,
  imports: [
    CommonModule,
    RocketButtonModule,
    RocketModalModule,
    FlaCheckModule,
  ],
  providers: [RocketModalService],
})
export class ConfigHeaderColumnsModalComponent
  extends RocketModalRef
  implements OnInit
{
  private configViewsDefault: any = {
    ORDER: ORDER,
    INTRADIA: POSITION,
    SWINGTRADING: POSITION,
    POSITION: POSITION,
    ALERTS: ALERTS,
  };
  public configView = ORDER;
  public isCheckAll = false;
  public standaloneClose!: (params?: any) => void;

  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: any,
    private _rocketModalService: RocketModalService
  ) {
    super(_rocketModalService);
  }

  ngOnInit(): void {
    this.configView = this.configViewsDefault[this.data.type];
    this.configureFields();
  }

  private configureFields(): void {
    let isCheck = true;
    this.configView.forEach((column) => {
      const field = this.data.columns[column.field];
      column.isCheck = field ? !field.hide : column.isCheck ?? true;
      isCheck &&= column.isCheck;
    });
    this.isCheckAll = isCheck;
  }

  public onSave = () => this.standaloneClose(this.configView);
  public onClose = () => this.standaloneClose();

  public setVisibleOrInvisible(event: any, item: any): void {
    item.isCheck = event;
    const isCheck = !!this.configView.find((item: any) => !item.isCheck);
    this.isCheckAll = !isCheck;
  }

  public selectAll(): void {
    this.isCheckAll = !this.isCheckAll;
    this.configView.forEach((item: any) => {
      item.isCheck = this.isCheckAll;
    });
  }
}
