import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RTDropdownModule } from '@shared/rocket-components/dropdown';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingModule } from '../loading/loading.module';
import { MarketSectorsService } from '@shared/services/api/trademap/v1/market-sectors.service';
import {
  FlaIconModule,
  RocketButtonModule,
} from '@shared/rocket-components/components/index';

import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { StockAnalysisContextMenuComponent } from './config/stock-analysis-context-menu.component';
import { StockAnalysisComponent } from './stock-analysis.component';
import { StockAnalysisHeaderComponent } from './parts/header/stock-analysis-header.component';
import { StockAnalysisGraphComponent } from './parts/graph/stock-analysis-graph.component';
import { StockAnalysisTooltip } from './parts/graph/tooltip/stock-analysis-tooltip.component';
import { StockAnalysisControlsComponent } from './parts/graph/controls/stock-analysis-controls.component';
import { RTListSelectorModule } from '@shared/rocket-components/components/list-selector/list-selector.module';
import { RocketIconsModule } from '@shared/rocket-icons/rocket-icons.module';
import { StockChartService } from '../stock-chart/service/stock-chart.service';

@NgModule({
  imports: [
    CommonModule,
    RTDropdownModule,
    FlaWorkSpaceModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingModule,
    RocketButtonModule,
    RTListSelectorModule,
    FlaIconModule,
    TooltipsModule,
    RocketIconsModule,
  ],
  declarations: [
    StockAnalysisContextMenuComponent,
    StockAnalysisComponent,
    StockAnalysisControlsComponent,
    StockAnalysisGraphComponent,
    StockAnalysisHeaderComponent,
    StockAnalysisTooltip,
  ],
  providers: [MarketSectorsService, StockChartService],
  exports: [StockAnalysisComponent],
})
export class StockAnalysisModule {}
