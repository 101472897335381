<canvas
  #canvas
  class="color-slider"
  width="20"
  [height]="height"
  (mousedown)="onMouseDown($event)"
  (mouseup)="onMouseUp($event)"
  (mousemove)="onMouseMove($event)"
>
</canvas>
