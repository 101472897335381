import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { map, Observable } from 'rxjs';
import { RestService } from '@shared/services/rest/rest.service';
import {
  INews,
  INewsAgency,
  INewsGroup,
  INewsTrademapService,
} from '@core/interface/news-agency.interface';
import { flagsMapNews } from '@shared/components/search-stock/flags-map.const';
import {
  VMDateToFriendlyLongDate,
  formatterNumber,
  isNullOrUndefined,
} from 'src/app/utils/utils.functions';
import { ConfigService } from '@core/config';

@Injectable({
  providedIn: 'root',
})
export class TrademapNewsService extends RestService {
  public serviceFunctionMapping: { [key: string]: any } = {};
  override _url: string = 'api/trademap';

  constructor(
    http: HttpClient,
    @Inject(LOCALE_ID) private locale: string,
    private configService: ConfigService
  ) {
    super(http);
    this.serviceFunctionMapping = {
      AGENCY: this.getNews,
      AGENCY_PREMIUM: this.getNews,
      BDM: this.getBDMNews,
      CALENDAR: this.getBDMNews,
      CVM: this.getCVMNews,
      EVENTOS: this.getEventsNews,
      ULTIMAS: this.getNews,
      YESTERDAY: this.getNewsCalendar,
      TODAY: this.getNewsCalendar,
      TOMORROW: this.getNewsCalendar,
      THIS_WEEK: this.getNewsCalendar,
      NEXT_WEEK: this.getNewsCalendar,
      LATEST: this.getNewsCalendar,
      NEXT: this.getNewsCalendar,
    };
  }

  getNews = (
    currentPage: number,
    cd_stock?: string,
    agencias?: string[],
    tags_tm?: string[],
    text?: string
  ): Observable<INews[]> =>
    this.post<any>('v7/news/getNormalPremiumNewsOrder', {
      isFull: true,
      period: 'today',
      filters: {
        cd_stock,
        agencias,
        tags_tm,
        text,
        isRocket: true,
      },
      after_point: null,
      currentPage: currentPage,
      perPage: 20,
    }).pipe(map((response) => this._processNewsResponse(response)));

  getCVMNews = (
    currentPage: number,
    cd_stock?: string,
    agencias?: string[],
    tags_tm?: string[],
    text?: string
  ): Observable<INews[]> =>
    this.post<any>('v7/news/get-news-cvm', {
      isFull: true,
      period: 'today',
      filters: { isRocket: true, text },
      after_point: null,
      currentPage,
      perPage: 20,
    }).pipe(map((response) => this._processNewsResponse(response)));

  getBDMNews = (currentPage: number): Observable<INews[]> =>
    this.post<any>('v7/news/get-news-bdm', {
      isFull: true,
      period: this.configService.isBroker() ? 'three_days' : 'today',
      filters: { isRocket: true },
      after_point: null,
      currentPage,
      perPage: 20,
    }).pipe(map((response) => this._processNewsResponse(response)));

  getEventsNews = (
    currentPage: number,
    cd_stock?: string,
    agencias?: string[],
    tags_tm?: string[],
    text?: string
  ): Observable<INews[]> =>
    this.post<any>('v7/news/get-news-corporative-events', {
      isFull: true,
      period: 'today',
      filters: { isRocket: true, text },
      after_point: null,
      currentPage,
      perPage: 20,
    }).pipe(map((response) => this._processNewsResponse(response)));

  public getNewsDetails = (
    agency_type: string,
    id: number,
    id_news_agency: number
  ): Observable<INews | undefined> =>
    this.post<any>('v7/news/get-news-detail', {
      agency_type,
      id,
      id_news_agency,
      filters: { isRocket: true },
    }).pipe(
      map((response) => {
        if (
          !response.data ||
          !response.data?.success ||
          !response.data?.result
        ) {
          return;
        }
        const news: INewsTrademapService = response.data.result.dt_news
          ? response.data.result
          : response.data.result.data;

        return {
          copy_news:
            news.copy_news && news.copy_news.length > 0
              ? news.copy_news[0]
              : undefined,
          item: `${
            (news.data?.seq ? news.data?.seq : news.data.id_news,
            news.data.id_news)
          }`,
          agencia: '',
          agency_type: news.agency_type,
          id_news_agency: news.id_news_agency,
          id_agency: news.id_news_agency,
          cd_company: '',
          data_noticia: `${news.dt_news}`,
          hora_noticia: `${news.hr_news}`,
          prioridade: '0',
          nu_noticia: news.data?.id_news,
          headline: news.ds_headline,
          texto: news.data.ds_body,
          url: news.data.news_url,
          lingua: '',
          instrumento: '',
          tags: news.tag_agency_tm.map((tag) => ({
            id_agency_tag: tag.id_tag,
            ds_agency_tag: tag.ds_tag,
          })),
          type: news.type ? news.type : undefined,
        };
      })
    );

  public getPremiumAgenciesAvailable = (): Observable<boolean> =>
    this.post<any>('v8/publicoffer/check-permission-investor', {}).pipe(
      map((response) => {
        return response.data?.result;
      })
    );

  public getPremiumAgencies = (): Observable<INewsAgency[]> =>
    this.post<any>('v7/news/get-news-agency-premium', {}).pipe(
      map((response) => {
        if (response.data && response.data?.success && response.data?.result) {
          return response.data.result as INewsAgency[];
        }

        return [];
      })
    );

  public getNewsGroups = (): Observable<INewsGroup[]> =>
    this.post<any>('v7/news/getNewsGroups', {}).pipe(
      map((response) => {
        if (response.data && response.data?.success && response.data?.result) {
          const allowedGroups = [1, 2, 3, 4];
          return response.data.result.grupos
            .filter(
              (group: any) => allowedGroups.indexOf(group.id_news_group) > -1
            )
            .map((group: any) => ({
              id_news_group: group.id_news_group,
              itens: group.itens
                .filter((subGroup: any) => subGroup.tp_view_news != 'CALENDAR')
                .map((subGroup: any) => ({
                  id_news_group: subGroup.id_news_group,
                  nm_view_news: this._getNewsGroupName(subGroup),
                  seq: subGroup.seq,
                  tag_agg: this._getNewsGroupId(subGroup),
                  tp_view_news: subGroup.tp_view_news,
                  ui_color: subGroup.ui_color,
                  url_image: subGroup.url_image,
                })),
              nm_news_group: group.nm_news_group,
            }));
        }

        return [];
      })
    );

  private _processNewsResponse(response: any) {
    if (!(response?.data?.result?.length > 0)) {
      return [];
    }

    const { result } = response.data;
    const hasList = result[0].news;
    const newsList = hasList ? result[0].news : result;

    return newsList.map((news: INewsTrademapService) => ({
      copy_news:
        news.copy_news && news.copy_news.length > 0
          ? news.copy_news[0]
          : undefined,
      item: news.data?.seq ? news.data?.seq : news.data.id_news,
      agencia: hasList ? result[0].ds_news_agency : news.ds_news_agency,
      id_news_agency: hasList ? result[0].id_news_agency : news.id_news_agency,
      agency_type: hasList ? result[0].agency_type : news.agency_type,
      cd_company: news.data?.cd_stock,
      ds_channel: news.data?.cd_stock,
      data_noticia: `${news.dt_news}`,
      hora_noticia: `${news.hr_news}`,
      prioridade: 0,
      nu_noticia: news.data.id_news,
      headline: news.ds_headline,
      texto: news.data.ds_body,
      url: news.data.news_url,
      lingua: '',
      instrumento: '',
      tags: news.tag_agency_tm.map((tag) => ({
        id_agency_tag: tag.id_tag,
        ds_agency_tag: tag.ds_tag,
      })),
      id_cvm_company: news.data?.id_cvm_company
        ? news.data?.id_cvm_company
        : null,
      seq: news.data?.seq ? news.data?.seq : null,
      type: news.type ? news.type : null,
    }));
  }

  private _getNewsGroupName(group: any) {
    if (group.nm_view_news) return group.nm_view_news;
    if (
      group.id_news_agency &&
      group.id_news_agency[0] &&
      group.id_news_agency[0].nm_agency
    )
      return group.id_news_agency[0].nm_agency;
    if (
      group.id_agency_tag &&
      group.id_agency_tag[0] &&
      group.id_agency_tag[0].ds_agency_tag
    )
      return group.id_agency_tag[0].ds_agency_tag;

    return '';
  }

  private _getNewsGroupId(group: any) {
    if (group.tag_agg) return group.tag_agg;
    if (group.agencia_agg) return group.agencia_agg;
    if (
      group.id_news_agency &&
      group.id_news_agency[0] &&
      group.id_news_agency[0].id_news_agency
    )
      return group.id_news_agency[0].id_news_agency;
    if (
      group.id_agency_tag &&
      group.id_agency_tag[0] &&
      group.id_agency_tag[0].id_agency_tag
    )
      return group.id_agency_tag[0].id_agency_tag;

    if (group.tp_view_news) return group.tp_view_news;
    return '';
  }

  getNewsCalendar = (period: string = 'TODAY'): Observable<INews[]> => {
    return this.get<any>(`v1/news/economic-calendars?period=${period}`).pipe(
      map((response) => this._processNewsResponseCalendar(response))
    );
  };

  private _processNewsResponseCalendar(response: any) {
    if (!(response?.data?.length > 0)) {
      return [];
    }
    const { data } = response;
    return data.map((news: any) => ({
      headline: news.indicator,
      country: news.country,
      currency: news.currency,
      currentLabel: news.currentLabel,
      date: VMDateToFriendlyLongDate(news.date, this.locale),
      forecast: news.forecast,
      current: news.current,
      id: news.id,
      institution: news.institution,
      period: news.period,
      previous: news.previous,
      previousLabel: news.previousLabel,
      time: news.date + 'T' + news.time,
      is_calendar: true,
      image_url: `https://cdn.trademap.com.br/assets/flags/${
        flagsMapNews[news.country]
      }.imageset/${flagsMapNews[news.country]}.png`,
      indicatorDescription: news.indicatorDescription,
      measureUnit: news.measureUnit,
      suffix: news.suffix,
      previusLabel: this._buildPreviusLabel(news),
      currentLabelCompleted: this._buildCurrentLabel(news),
    }));
  }

  private _buildPreviusLabel(news: any): string {
    if (
      !isNullOrUndefined(news.previousLabel) ||
      !isNullOrUndefined(news.previous)
    ) {
      const period = news.previousLabel ?? 'Anterior';
      const symbol = news.previous >= 0 ? '' : '-';
      const value = formatterNumber(news.previous).replace('-', '');
      const prefix = news?.measureUnit ?? '';
      const suffix = news?.suffix ?? '';
      return `${period}: ${symbol}${prefix}${value}${suffix} |`;
    }
    return '';
  }

  private _buildCurrentLabel(news: any): string {
    if (!news?.currentLabel && isNullOrUndefined(news?.current)) return '';
    let label = news.currentLabel ?? 'Anterior' + ':';
    if (news?.previusLabel) label = `${news.previusLabel} ${label}`;
    if (
      !isNullOrUndefined(news?.current) &&
      !isNullOrUndefined(news?.previous)
    ) {
      const symbol = news.current >= 0 ? '' : '-';
      const prefix = news?.measureUnit ?? '';
      const value = formatterNumber(news.current).replace('-', '');
      const suffix = news?.suffix ?? '';
      label = `${label} ${symbol}${prefix}${value}${suffix}`;
    }
    return label;
  }

  updateNewsVote = (idNews: number, vote: number): Observable<any> =>
    this.post<any>('v7/news/update-news-vote', {
      id_news: idNews,
      vote,
    });
}
