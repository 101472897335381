import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import {
  CustomAnnotation,
  ICustomAnnotationOptions,
} from 'scichart/Charting/Visuals/Annotations/CustomAnnotation';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import {
  DrawToolConfiguration,
  TIGER_CHART_TOOL,
  TIGER_CHART_TOOL_CHILD,
} from '../tiger-chart-tools/tiger-chart-tools.interface';
import { TMouseEventData } from '../types/tiger-chart.types';
import {
  Axis,
  CustomBoxToolsOptions,
  DrawTools,
  DrawToolsReposition,
  StyleTool,
} from './draw-tools.interface';
import { CategoryCoordinateCalculator } from 'scichart/Charting/Numerics/CoordinateCalculators/CategoryCoordinateCalculator';
import { CoordinateCalculatorBase } from 'scichart/Charting/Numerics/CoordinateCalculators/CoordinateCalculatorBase';
import { deepClone } from '@shared/rocket-components/utils/functions';
import { Point } from 'scichart/Core/Point';
import { Subscription } from 'rxjs';
import { TOOLS_ENUM } from '../tiger-chart-tools/tiger-chart-tools.enum';

export class CustomBoxTools extends CustomAnnotation implements DrawTools {
  tool!: TIGER_CHART_TOOL;
  children = [];
  viewBoxWidth!: number;
  viewBoxHeight!: number;
  isDraw = true;
  startEdit = false;
  baseChart!: TWebAssemblyChart;
  isChildren = false;
  svgFillColor: string = '#915c1f38';
  lastPointValues!: Point;
  isDrawing!: boolean;
  lastXDiff: number = 0;
  style: StyleTool = {};
  dictIdChildren!: string;
  isAux: boolean = false;
  endDraw: boolean = false;
  lastEventData!: TMouseEventData;
  configuration!: DrawToolConfiguration | undefined;
  hasElements: boolean = false;
  axisSub!: Subscription;
  isWithinDataBounds: boolean | undefined = true;

  constructor(
    options: ICustomAnnotationOptions,
    private drawToolsService: DrawToolsService,
    tool: CustomBoxToolsOptions
  ) {
    super(options);
    this.isChildren = tool.isChildren;
    this.baseChart = tool.baseChart;
    this.tool = tool.tool;
    this.viewBoxWidth = tool.viewBoxWidth;
    this.viewBoxHeight = tool.viewBoxHeight;
    this.isAux = tool.isAux;
    this.lastEventData = tool.eventData;
    this.configuration = tool.configuration;
    this.isWithinDataBounds = tool.eventData.isWithinDataBounds;
    if (tool.svgFillColor) {
      this.svgFillColor = tool.svgFillColor;
    }
    this.dictIdChildren = tool.dictIdChildren;
    this.makeSvg();
    this.setTemporaryBoxInvisible();
  }

  initSubscriptions() {
    //do nothing;
  }

  unsubscriber(): void {
    //do nothing;
  }

  private setTemporaryBoxInvisible() {
    if (this.tool.group == TOOLS_ENUM.TEMPORARY) {
      this.selectionBoxStroke = 'transparent';
      this.annotationsGripsStroke = 'transparent';
      this.annotationsGripsFill = 'transparent';
    }
  }

  protected override setAnnotationBorders(
    x1: number,
    x2: number,
    y1: number,
    y2: number
  ): void {
    super.setAnnotationBorders(x1, x2, y1, y2);
    this.setStyleConfiguration();
  }

  override update(
    xCalc: CoordinateCalculatorBase,
    yCalc: CoordinateCalculatorBase,
    xCoordSvgTrans: number,
    yCoordSvgTrans: number
  ): void {
    super.update(xCalc, yCalc, xCoordSvgTrans, yCoordSvgTrans);
    const x = xCalc as CategoryCoordinateCalculator;
    const xDiff = x.indexMax - x.indexMin;
    if (this.isDrawing) {
      return;
    }
    this.lastXDiff = xDiff;
    if (this.tool.codTool !== `${TOOLS_ENUM.GEOMETRIC_SHAPES}_TRIANGLE`) {
      this.repositionZoomDraw(this.getAnnotationBorders());
    }
    if (!this.isEditable && !this.isSelected) {
      this.deleteAdorner();
    }
    if (this.style) {
      this.updateStyleTool(this.style);
    }
  }

  getName() {
    return this.constructor.name;
  }

  private setStyleConfiguration() {
    if (!this.configuration || this.hasElements) {
      return;
    }
    let id = `PATH_${this.tool.dictId!!}`;
    if (this.isChildren) {
      id = `PATH_${this.dictIdChildren!!}`;
    }
    const pathElement = document.getElementById(id);
    if (!pathElement) {
      return;
    } else {
      this.hasElements = true;
    }
    if (pathElement) {
      if (this.configuration.backgroundColor) {
        pathElement.setAttribute('fill', this.configuration.backgroundColor);
      }
    }
  }

  updateDrawTool(
    eventData: TMouseEventData,
    countStepClickTool: number,
    reposition?: DrawToolsReposition
  ) {
    this.lastEventData = eventData;
    this.lastPointValues = eventData.pointValues!!;
    if (
      reposition &&
      reposition.x1 &&
      reposition.x2 &&
      reposition.y1 &&
      reposition.y2
    ) {
      const annotation = this.baseChart.sciChartSurface.annotations.getById(
        reposition.id || this.tool.dictId!!
      );
      if (annotation) {
        annotation.x1 = reposition.x1;
        annotation.x2 = reposition.x2;
        annotation.y1 = reposition.y1;
        annotation.y2 = reposition.y2;
      }
      return;
    }
    this.x2 = eventData.pointValues!!.x;
    this.y2 = eventData.pointValues!!.y;
  }

  customClick = () => {
    //do nothing;
  };

  makeSvg() {
    this.tool.svgString = this.tool.svgString
      ? this.tool.svgString
      : `
    <svg style="overflow:auto;" viewBox="0 0 $WIDTH $HEIGHT" id="SVG_$CODTOOL" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="PATH_$CODTOOL" fill="${this.svgFillColor}" style="stroke:transparent;" />
    </svg>`;
    this.svgString = this.svgString
      ? this.svgString
      : this.drawToolsService.getSvgString(
          !this.isChildren
            ? this.tool
            : { ...this.tool, dictId: this.dictIdChildren },
          this.viewBoxWidth,
          this.viewBoxHeight
        );
  }

  customHover = () => {
    //do nothing.
  };

  repositionZoomDraw(borders: {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
  }): void {
    if (!this.lastPointValues) {
      return;
    }
    const { xAxis, yAxis } = this.getAxis();
    if (!xAxis || !yAxis) {
      return;
    }
    const calcX = xAxis.getCurrentCoordinateCalculator();
    const calcY = yAxis.getCurrentCoordinateCalculator();
    const actualPositionX = calcX.getCoordinate(this.lastPointValues.x);
    const actualPositionY = calcY.getCoordinate(this.lastPointValues.y);
    const id = this.isChildren
      ? `PATH_${this.dictIdChildren!!}`
      : this.tool.dictId!!;
    const element = document.getElementById(id);
    if (element) {
      const diffX = borders.x2 - actualPositionX;
      const x2 = deepClone(Math.abs(parseFloat(element.getAttribute('x2')!!)));
      const newX2 = x2 - diffX;
      const diffY = borders.y2 - actualPositionY;
      const y2 = deepClone(Math.abs(parseFloat(element.getAttribute('y2')!!)));
      const newY2 = y2 - diffY;
      element.setAttribute('x2', `${newX2}`);
      element.setAttribute('y2', `${newY2}`);
      if (this.isChildren) {
        this.updateBoxPositionWithFather();
      }
    }
  }

  private updateBoxPositionWithFather() {
    const { xAxis, yAxis } = this.getAxis();
    const element = document.getElementById(`PATH_${this.dictIdChildren!!}`);
    const SVGElement = document.getElementById(`SVG_${this.dictIdChildren!!}`);
    if (!xAxis || !yAxis || !element || !SVGElement) {
      return;
    }
    const calcX = xAxis.getCurrentCoordinateCalculator();
    const calcY = yAxis.getCurrentCoordinateCalculator();
    const father = this.baseChart.sciChartSurface.annotations.getById(
      this.tool.dictId!!
    ) as DrawTools;
    const firstChild = this.baseChart.sciChartSurface.annotations.getById(
      father.children[0]!!.id
    ) as DrawTools;
    const annoX1 = calcX.getCoordinate(firstChild.x1);
    const annoX2 = calcX.getCoordinate(firstChild.x2);
    const annoY1 = calcY.getCoordinate(firstChild.y1);
    const fatherY1 = calcY.getCoordinate(father.y1);
    const fatherY2 = calcY.getCoordinate(father.y2);
    const fatherY = fatherY1 - fatherY2;
    const diffX1X2 = (annoX1 - annoX2) * -1;
    const diffY1Y1 = fatherY1 - annoY1;
    //this.x1 = firstChild.x1;
    //this.x2 = father.x2;
    //this.y1 = firstChild.y1;
    //this.y2 = father.y2;
    const { width, height } = this.parentSurface.seriesViewRect;
    SVGElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
    element.setAttribute(
      'd',
      `M 0 0 L 0 ${diffY1Y1} L ${diffX1X2} ${
        diffY1Y1 + fatherY * -1
      } L ${diffX1X2} ${fatherY * -1}`
    );
  }

  updateStyleTool(style: StyleTool) {
    let childrenDraw!: TIGER_CHART_TOOL_CHILD;
    let id = this.tool.dictId!!;
    if (this.isChildren) {
      childrenDraw = this.tool.children!.find(
        (child) => child.dictIdChildren === this.dictIdChildren
      )!!;
      id = `PATH_${this.dictIdChildren!!}`;
    }
    const pathElement = document.getElementById(id);
    if (pathElement) {
      if (style.backgroundColor) {
        pathElement.setAttribute('fill', style.backgroundColor.rgbaHexColor);
        this.style.backgroundColor = style.backgroundColor;
        if (childrenDraw) {
          childrenDraw.backgroundColor = style.backgroundColor.rgbaHexColor;
        } else {
          this.tool.backgroundColor = style.backgroundColor.rgbaHexColor;
        }
      }
      if (style.color) {
        pathElement.setAttribute('stroke', style.color.rgbaHexColor);
        this.style.color = style.color;
        if (childrenDraw) {
          childrenDraw.color = style.color.rgbaHexColor;
        } else {
          this.tool.color = style.color.rgbaHexColor;
        }
      }
      if (style.strokeThickness) {
        pathElement.setAttribute(
          'stroke-width',
          style.strokeThickness.toString()
        );
        this.style.strokeThickness = style.strokeThickness;
        if (childrenDraw) {
          childrenDraw.lineThickness = style.strokeThickness;
        } else {
          this.tool.lineThickness = style.strokeThickness;
        }
      }
      if (style.strokeDashArray) {
        pathElement.setAttribute(
          'stroke-dasharray',
          style.strokeDashArray[0].toString()
        );
        this.style.strokeDashArray = style.strokeDashArray;
        if (childrenDraw) {
          childrenDraw.strokeDashArray = style.strokeDashArray;
        } else {
          this.tool.strokeDashArray = style.strokeDashArray;
        }
      }
    }
  }

  updateTool(tool: TIGER_CHART_TOOL) {
    this.tool = tool;
  }

  private getAxis(): Axis {
    const yAxis = this.baseChart.sciChartSurface.yAxes.getById(this.yAxisId);
    const xAxis = this.baseChart.sciChartSurface.xAxes.getById(this.xAxisId);
    if (!yAxis || !xAxis) {
      return { xAxis: undefined, yAxis: undefined };
    }
    try {
      yAxis.getCurrentCoordinateCalculator();
      xAxis.getCurrentCoordinateCalculator();
    } catch (e: any) {
      return { xAxis: undefined, yAxis: undefined };
    }
    return { yAxis, xAxis };
  }
}
