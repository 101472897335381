<rocket-modal>
  <rocket-modal-header>
    <h3 class="fs-2 mb-2">Detalhes da Ordem</h3>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <div class="row m-0 mb-4">
      <div class="col-8">
        <div class="fw-bolder">
          <span class="fw-lighter mr-1">Ativo: </span> {{ data.cd_stock }}
        </div>
        <div class="fw-bolder">
          <span class="fw-lighter mr-1">Operação: </span> {{ typeSideOrder }}
        </div>
        <div class="fw-bolder">
          <span class="fw-lighter mr-1">Tipo de Ordem: </span>
          {{ data?.orderType }}
        </div>
      </div>
      <div class="col pl-8 ml-7">
        <div class="fw-bolder">
          <span class="fw-lighter mr-1">Data/Hora: </span> {{ dateCreated }}
        </div>
        <div class="fw-bolder">
          <span class="fw-lighter mr-1">Validade: </span>
          {{ data?.orderTimeEnforce }}
        </div>
        <div class="fw-bolder">
          <span class="fw-lighter mr-1">Situação: </span>
          {{ data.ds_order_status }}
        </div>
      </div>
    </div>
    <rocket-grid [columnDefs]="columnDefs" [rowData]="rowData"></rocket-grid>
    <div class="d-flex justify-content-end fs-7 mb-6">
      Número da ordem: {{ idOrder }}
    </div>
  </app-rocket-modal-body>
</rocket-modal>
