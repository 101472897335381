import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  IAccountSelect,
  ISearchStock,
  IWorkSpaceComponet,
} from '@core/interface';
import { TradeIdeaService } from '@shared/services/api/trade-idea/trade-idea.service';
import { TTradeIdeaEvents } from '@shared/tiger-chart/types/tiger-chart.types';
import { Subject, Subscription, auditTime, filter, map, tap } from 'rxjs';
import { TradeIdeaHelperService } from './trade-idea-helper.service';
import { FormControl } from '@angular/forms';
import { MultibrokerService } from '@shared/services/core/multibroker';
import { StockPreferencesService } from '@shared/services/stock-preferences.service';
import { StockChartService } from '../stock-chart/service/stock-chart.service';
import { ModalTradeIdeaComponent } from './modal-trade-idea/modal-trade-idea.component';
import { RocketModalService } from '@shared/rocket-components/components';
import { IHeader } from './trade-idea-header/trade-idea-header.interface';
import { HEADERS } from './trade-idea-header/trade-idea-header.const';
import { HEADER_CODE } from './trade-idea-header/trade-idea-header.enum';
import { system } from '@core/system/system.service';
import { ModalAvisoComponent } from '../modal-aviso/modal-aviso.component';
import { ToastService } from '@shared/services';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { ActivatedRoute } from '@angular/router';
import { isWebViewContext } from 'src/app/desktop/integration.service';

@Component({
  selector: 'app-trade-idea',
  templateUrl: './trade-idea.component.html',
  styleUrls: ['./trade-idea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TradeIdeaComponent
  extends RocketComponentBase
  implements AfterViewInit, OnDestroy
{
  @Input() component!: IWorkSpaceComponet;
  @Input() refComponent!: string;
  tradeIdeas: TTradeIdeaEvents[] = [];
  infoToShow: TTradeIdeaEvents[] = [];
  selectedTradeIdea!: TTradeIdeaEvents | undefined;
  qttyControl = new FormControl(100);
  standardLot: number = 100;
  negotiationLot: number = 100;
  selectedStock!: ISearchStock | undefined;
  isLoading = true;
  protected defaultMsg: string =
    'Veja abaixo a recomendação dos analistas de mercado';
  protected emptyMsg: string = 'Não há recomendações disponíveis no momento.';
  protected emptyStockMsg: string =
    'Não há recomendações disponíveis para esse ativo no momento.';
  private _onAccountChange$!: Subscription;
  private _account!: IAccountSelect;
  private _endScroll$ = new Subject<{ event: any }>();
  private endScroll$!: Subscription;
  private page = 0;
  btnColor: string = 'bg-multibroker-buy';
  btnLabel: string = 'Comprar';
  selectedHeader = HEADERS[0];
  headerCodes = HEADER_CODE;
  isDesktop = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private tradeIdeaService: TradeIdeaService,
    private tradeIdeaHelperService: TradeIdeaHelperService,
    private multibrokerService: MultibrokerService,
    private stockPreferencesService: StockPreferencesService,
    private stockChartService: StockChartService,
    private rocketModalService: RocketModalService,
    private toastService: ToastService,
    activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
    this.isDesktop = isWebViewContext();
  }

  protected initComponent(component: any): void {
    if (component) this.component = component;
  }

  ngAfterViewInit(): void {
    if (this.component?.metadata?.component?.stock) {
      this.selectedStock = this.component?.metadata?.component?.stock;
    }
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this._onAccountChange$ && this._onAccountChange$.unsubscribe();
    this.endScroll$ && this.endScroll$.unsubscribe();
  }

  selectTradeIdea(tradeIdea: TTradeIdeaEvents) {
    if (tradeIdea.isNotInfo) {
      return;
    }
    this.selectedTradeIdea = tradeIdea;
    this.selectedTradeIdea.strategy.items =
      this.tradeIdeaHelperService.formatStrategyItemsTradeIdea(
        this.selectedTradeIdea.strategy.type_strategy,
        this.selectedTradeIdea.strategy.items
      );
    const value = this.stockPreferencesService.getStockSaved(
      tradeIdea.cd_stock,
      tradeIdea.standard_lot ?? 100,
      'qtty'
    );
    this.standardLot = this.selectedStock
      ? this.selectedStock.standard_lot
      : tradeIdea.standard_lot ?? 100;
    this.negotiationLot = value;
    this.qttyControl.setValue(this.negotiationLot);
    const isBuy = this.selectedTradeIdea.side === 'B';
    this.btnColor = isBuy ? 'bg-multibroker-buy' : 'bg-multibroker-sell';
    this.btnLabel = isBuy ? 'Comprar' : 'Vender';
    this.cdr.detectChanges();
  }

  private getAlltradeIdeas() {
    this.tradeIdeaService
      .getAllTradeIdeas(
        this.page,
        this.selectedHeader.code === HEADER_CODE.HISTORIC
      )
      .pipe(
        map((data) => {
          this.tradeIdeas.push(...data);
          return this.tradeIdeaHelperService.formatTradeIdeas(
            data,
            this.selectedHeader
          );
        }),
        tap(() => {
          this.isLoading = false;
          this.cdr.detectChanges();
        }),
        filter((data) => !!data && !!data.length)
      )
      .subscribe((data) => {
        this.addToShow(data);
      });
  }

  executeTradeIdea() {
    if (!this.selectedTradeIdea) return;
    this.tradeIdeaHelperService.executeTradeIdea(
      this.selectedTradeIdea,
      this._account,
      this.qttyControl.value!,
      this.stockChartService
    );
  }

  private initSubscriptions() {
    this.endScroll$ = this._endScroll$
      .pipe(
        auditTime(100),
        filter((data) => {
          const { event } = data;
          return (
            event.target &&
            event.target.offsetHeight + event.target.scrollTop >=
              event.target.scrollHeight
          );
        })
      )
      .subscribe(() => {
        this.getTradeIdea(this.selectedStock, true);
      });
    this._onAccountChange$ = this.multibrokerService
      .onUpdateSelectedAccountChannel()
      .subscribe((account) => {
        this._account = account;
      });
  }

  onScroll(event: any) {
    this._endScroll$.next({ event });
  }

  getTradeIdea(stock: ISearchStock | undefined, isFromScroll: boolean = false) {
    if (!isFromScroll) {
      this.infoToShow = [];
      this.tradeIdeas = [];
      this.page = 0;
      this.isLoading = true;
    }
    this.selectedStock = stock;
    if (this.selectedHeader.code === this.headerCodes.MY) {
      this.getAllTradeIdeasInvestor();
      return;
    }
    if (!stock) {
      this.getAlltradeIdeas();
      this.selectedStock = undefined;
      return;
    }
    if (isFromScroll) return;
    const dsAsset =
      stock.cd_security_type == 'FUT' ? stock.ds_asset : undefined;
    this.tradeIdeaService
      .getAllTradeIdeasByStock(
        stock.cd_stock,
        this.selectedHeader.code === HEADER_CODE.HISTORIC,
        dsAsset
      )
      .pipe(
        map((data) => {
          this.tradeIdeas.push(...data);
          return this.tradeIdeaHelperService.formatTradeIdeas(
            data,
            this.selectedHeader,
            stock.tick_size_denominator
          );
        }),
        tap(() => {
          this.isLoading = false;
          this.cdr.detectChanges();
        }),
        filter((data) => !!data && !!data.length)
      )
      .subscribe((data) => {
        this.addToShow(data);
      });
  }

  private addToShow(data: TTradeIdeaEvents[]) {
    this.infoToShow.push(...data);
    this.page++;
    this.selectTradeIdea(this.infoToShow[1]);
    this.cdr.detectChanges();
  }

  openTradeIdeaModal(isEdit: boolean, isEditMyTradeIdea: boolean = false) {
    const dsAsset = this.selectedTradeIdea?.cd_stock.substring(0, 3);
    const ref = this.rocketModalService.open(ModalTradeIdeaComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
      data: {
        stock:
          !isEdit && !isEditMyTradeIdea
            ? this.selectedStock
            : this.selectedStock ?? {
                cd_stock: this.selectedTradeIdea?.cd_stock,
                id_exchange: this.selectedTradeIdea?.id_exchange,
                tick_size_denominator:
                  this.selectedTradeIdea?.tick_size_denominator,
                ds_asset: dsAsset,
                //valor ficcional
                cd_segment: dsAsset == 'WDO' || dsAsset == 'DOL' ? 7 : 9999,
              },
        selectedTradeIdea:
          !isEdit && !isEditMyTradeIdea ? undefined : this.selectedTradeIdea,
        isEdit,
        isEditMyTradeIdea,
        isSecondModal: false,
        qtty: this.qttyControl.value,
      },
    });
    ref.afterDismissed.subscribe((data) => {
      if (data.isCreated) {
        this.getTradeIdea(this.selectedStock);
      }
    });
  }

  changeMenu(menu: IHeader) {
    this.selectedHeader = menu;
    this.cdr.detectChanges();
    this.getTradeIdea(this.selectedStock);
  }

  getAllTradeIdeasInvestor() {
    this.infoToShow = [];
    this.selectedTradeIdea = undefined;
    this.tradeIdeaService
      .getAllTradeIdeasByInvestor(system.idInvestor)
      .pipe(
        map((data) => {
          const filtered = this.selectedStock
            ? data.filter(
                (item: any) =>
                  item.cd_stock === this.selectedStock?.cd_stock_order
              )
            : data;
          this.tradeIdeas.push(...filtered);
          return this.tradeIdeaHelperService.formatTradeIdeas(
            filtered,
            this.selectedHeader
          );
        }),
        tap(() => {
          this.isLoading = false;
          this.cdr.detectChanges();
        }),
        filter((data) => !!data && !!data.length)
      )
      .subscribe((data) => {
        this.addToShow(data);
      });
  }

  deleteTradeIdea() {
    if (
      this.selectedHeader.code !== this.headerCodes.MY ||
      !this.selectedTradeIdea
    ) {
      return;
    }
    const ref = this.rocketModalService.open(ModalAvisoComponent, {
      data: {
        title: 'Tem certeza que deseja apagar?',
        text: `
      <p>Após apagar, seu TradeIdea não poderá ser mais acessado.</p>`,
        confirmeButton: 'Sim, apagar',
        askAgain: false,
        showButtons: true,
        cancelButton: 'Talvez depois',
        isSecondModal: false,
        stock: undefined,
        showPriceStock: false,
      },
    });
    ref.afterDismissed.subscribe((data) => {
      if (data && data.value) {
        this.tradeIdeaService
          .deleteTradeIdea(this.selectedTradeIdea!.id_trade_idea)
          .subscribe((data) => {
            const isNull = isNullOrUndefined(data);
            if (!isNull && !data.success) {
              this.toastService.showToast(
                'error',
                'Erro ao tentar apagar o TradeIdea. Tente novamente'
              );
            } else {
              this.getTradeIdea(this.selectedStock);
              this.toastService.showToast(
                'success',
                'TradeIdea apagado com sucesso!'
              );
            }
          });
      }
    });
  }
}
