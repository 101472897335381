import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'count',
  standalone: true,
})
export class CountPipe implements PipeTransform {
  private _setValue = (value: number, maxValue: number = 99) =>
    value > maxValue ? `+${maxValue}` : value == 0 ? '' : `${value}`;

  transform(
    value: string | number,
    maxValue: number = 99,
    withParentheses: boolean = true
  ): any {
    if (!value && typeof value !== 'number') return '';
    const result = this._setValue(value as number, maxValue);
    if (withParentheses && !!result) return `(${result})`;
    return result;
  }
}
