import { ISearchStock, ITradeData } from 'src/app/core/interface';
import {
  defAgressorSide,
  DEF_AGRESSOR,
} from '../constants/times-trades.constants';
import { execFormatFinancial } from 'src/app/utils/utils.functions';
import { Dictionary } from 'src/app/core/models/dictionary.model';

export function formatTimeWithMs(
  hrTrade: string,
  hrNegocioOrigin: string,
  showMs: boolean
) {
  let formattedTime = hrTrade;
  const msDigits: number = 3;
  if (showMs && hrNegocioOrigin) {
    const msLength = hrNegocioOrigin.length;
    const formattedMs = hrNegocioOrigin.substring(
      msLength - msDigits,
      msLength
    );
    formattedTime += `.${formattedMs}`;
  }
  return formattedTime;
}

// Define constant options outside of functions to avoid recreation
const DEFAULT_FORMAT_OPTIONS: Intl.NumberFormatOptions = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
};

export const COLOR_PRICE_AGGRESSOR = new Dictionary<string>();
COLOR_PRICE_AGGRESSOR.set('AY', 'text-feedback-warning');
COLOR_PRICE_AGGRESSOR.set('PG', 'text-feedback-success');
COLOR_PRICE_AGGRESSOR.set('PR', 'text-feedback-error');

export class TimesTradesData {
  public id!: number;
  public hrTrade!: string;
  public price!: string;
  public classPrice!: string;
  public qtty!: string;
  public mnBrokerageBuy!: string;
  public mnBrokerageSell!: string;
  public agressorSide!: string;
  public agressorSideValue!: string;
  public classAgressorSide!: string;
  public classQuantity!: string;
  public cdStock?: string;
  constructor(trade: ITradeData | any, stock: ISearchStock, showMs: boolean) {
    this.id = trade.id_trade; //?? +trade.nu_negocio;
    this.cdStock =
      trade.cd_stock ?? trade.cd_Stock ?? trade.item?.split(':')?.shift() ?? '';

    this.hrTrade = formatTimeWithMs(
      trade.hr_trade ?? trade.hr_negocio,
      trade.hr_negocio_origin,
      showMs
    );

    this.price = this.formatedPrice(
      trade.price ?? trade.preco,
      stock.cd_segment,
      stock.id_exchange,
      stock.tick_size_denominator
    );
    this.qtty = this.formatedQuantity(
      trade.qtty ?? trade.qtde_negociada,
      stock.cd_segment,
      stock.id_exchange,
      stock.tick_size_denominator
    );
    this.mnBrokerageBuy =
      trade.mn_brokerage_buy ?? trade.mn_broker_compra ?? 'Outros';
    this.mnBrokerageSell =
      trade.mn_brokerage_sell ?? trade.mn_broker_venda ?? 'Outros';
    this.agressorSide = this.defAgressorSide(
      trade.agressor_side,
      trade?.trade_condition
    );
    this.agressorSideValue = this.agressorSide;

    if (trade.multiple_executions === 'true') {
      if (this.agressorSide === 'C') this.mnBrokerageBuy += '*'; //'<span class="text-brand-primary">*</span>';
      if (this.agressorSide === 'V') this.mnBrokerageSell += '*'; //'<span class="text-multibroker-sell">*</span>';
      this.agressorSideValue += '*';
    }

    this.classQuantity = COLOR_PRICE_AGGRESSOR.get(`A${trade.arrow}`) || '';
    this.classPrice = COLOR_PRICE_AGGRESSOR.get(`P${trade.arrow}`) || '';
    this.classAgressorSide = DEF_AGRESSOR[this.agressorSide]?.class;
  }

  private formatedPrice(
    price: number,
    cdSegment: number,
    idExchange: number,
    tickSizeDenominator: number
  ): string {
    const params = {
      tick_size_denominator: tickSizeDenominator,
      id_exchange: idExchange,
      cd_segment: cdSegment,
    };
    return execFormatFinancial(params, price);
  }

  private formatedQuantity(
    qtty: string,
    cdSegment: number,
    idExchange: number,
    tickSizeDenominator: number
  ): string {
    const useTofix = cdSegment == 8888 || idExchange > 7000;
    const decimal = qtty ? +qtty.toString().split('.')[1] : 0;
    const tickSize = useTofix ? 8 : decimal > 0.0 ? tickSizeDenominator : 0;

    // Use the default options if tickSize is 2, otherwise create a new options object
    const options =
      tickSize === 2
        ? DEFAULT_FORMAT_OPTIONS
        : {
            maximumFractionDigits: tickSize,
            minimumFractionDigits: tickSize,
          };

    const value = parseFloat(qtty);
    let formatted =
      value == undefined || value == null || Number.isNaN(value)
        ? '-'
        : value.toLocaleString('pt-BR', options);

    if (tickSize > 0) {
      formatted = formatted
        .replace(/^(\d*,\d*?[1-9])0+$/g, '$1')
        .replace(/(^0+(?=\d))|([1 - 9]?0+$)|(,?0+$)/g, '');
    }
    return formatted;
  }

  private defAgressorSide(agressorSide: string, tradeCondition: string) {
    const params = {
      agressor_side: agressorSide,
      trade_condition: tradeCondition,
    };
    return defAgressorSide(params);
  }
}
