import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlaIconModule } from '../icon/icon.module';
import { PhoneInputComponent } from './phone-input.component';
import { PhoneInputConfigService } from './service/phone-input-config.service';
import { FlaMaskModule } from '@shared/rocket-components/directives';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    FlaIconModule,
    ReactiveFormsModule,
    FlaMaskModule,
  ],
  declarations: [PhoneInputComponent],
  exports: [PhoneInputComponent],
  providers: [PhoneInputConfigService],
})
export class PhoneInputModule {}
