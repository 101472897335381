<table class="w-100 text-center">
  <thead class="bg-neutral-strong fixed-header">
    <tr
      [style.maxHeight.px]="26"
      class="with-at-price"
      [ngClass]="{
        'atprice-graphic': type === 'GRAPHIC',
        'atprice-aggressors': type === 'AGGRESSORS',
        'atprice-aggressors-balance': type === 'AGGRESSORS_BALANCE',
        'atprice-resume': type === 'RESUME'
      }"
    >
      <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'GRAPHIC'">
          <th>Preço</th>
          <th>Volume</th>
          <th class="w-100">Vol x Preço</th>
        </ng-container>
        <ng-container *ngSwitchCase="'AGGRESSORS'">
          <th>Compra</th>
          <th>Qtd. Compra</th>
          <th>Preço</th>
          <th>Qtd. Venda</th>
          <th>Venda</th>
        </ng-container>
        <ng-container *ngSwitchCase="'AGGRESSORS_BALANCE'">
          <th>Compra</th>
          <th>Qtd. Compra</th>
          <th>Preço</th>
          <th>Qtd. Venda</th>
          <th>Venda</th>
        </ng-container>
        <ng-container *ngSwitchCase="'RESUME'">
          <th>Preço</th>
          <th>Quantidade</th>
          <th>Agr. Compra</th>
          <th>Agr. Venda</th>
          <th>Saldo</th>
        </ng-container>
      </ng-container>
    </tr>
  </thead>
</table>
<div
  id="AT_PRICE_NEGOTIATION"
  class="table-responsive default-cursor position-relative overflow-hidden w-100 graph-height"
  #table
>
  <cdk-virtual-scroll-viewport
    *ngIf="tableDefault.length && activeTable"
    itemSize="18"
    minBufferPx="18"
    maxBufferPx="18"
    class="w-100 position-relative"
    [style.height.px]="virtualScrollViewportHeigth"
    [ngClass]="{ 'disabled-scroll': isLineBlock }"
  >
    <table
      class="w-100 h-100 text-center"
      [ngClass]="{ 'custom-cursor': mouseClicked }"
    >
      <tbody class="position-relative">
        <tr
          *cdkVirtualFor="let item of tableDefault; let i = index"
          class="opacity-semi-opaque-hover with-at-price"
          [ngClass]="{
            'atprice-graphic': type === 'GRAPHIC',
            'atprice-aggressors': type === 'AGGRESSORS',
            'atprice-aggressors-balance': type === 'AGGRESSORS_BALANCE',
            'atprice-resume': type === 'RESUME'
          }"
        >
          <ng-container [ngSwitch]="type">
            <ng-container *ngSwitchCase="'GRAPHIC'">
              <ng-container
                *ngTemplateOutlet="
                  GRAPHIC;
                  context: {
                    item: item,
                    rangePrice: rangePrice,
                    precoFormatado: item.precoFormatado,
                    precoUltimo: precoUltimo,
                    maxVolumeAtPriceTotal: maxVolumeAtPriceTotal
                  }
                "
              ></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'AGGRESSORS'">
              <ng-container
                *ngTemplateOutlet="
                  AGGRESSORS;
                  context: {
                    item: item,
                    rangePrice: rangePrice,
                    precoFormatado: item.precoFormatado,
                    precoUltimo: precoUltimo,
                    maxVolumeAtPriceTotal: maxVolumeAtPriceTotal
                  }
                "
              ></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'AGGRESSORS_BALANCE'">
              <ng-container
                *ngTemplateOutlet="
                  AGGRESSORS_BALANCE;
                  context: {
                    item: item,
                    rangePrice: rangePrice,
                    precoFormatado: item.precoFormatado,
                    precoUltimo: precoUltimo,
                    maxVolumeAtPriceTotal: maxVolumeAtPriceTotal
                  }
                "
              ></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'RESUME'">
              <ng-container
                *ngTemplateOutlet="
                  RESUME;
                  context: {
                    item: item,
                    rangePrice: rangePrice,
                    precoFormatado: item.precoFormatado,
                    precoUltimo: precoUltimo,
                    maxVolumeAtPriceTotal: maxVolumeAtPriceTotal
                  }
                "
              ></ng-container>
            </ng-container>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </cdk-virtual-scroll-viewport>
  <div
    id="PADLOCK"
    class="position-absolute round-pill d-flex justify-content-center align-items-center btn-floating-lock"
  >
    <rocket-button type="icon" (rocketClick)="changeBlockOrUnblockLine()">
      <fla-icon [name]="lineBlockIcon" size="icon-size-tiny"></fla-icon>
    </rocket-button>
  </div>
  <div
    *ngIf="!isLineBlock"
    class="position-absolute round-pill d-flex justify-content-center align-items-center btn-floating right-0 mr-2 mb-1"
  >
    <rocket-button type="icon" (rocketClick)="setCentralizeLine(true)">
      <fla-icon name="vertical_align_center" size="icon-size-micro"></fla-icon>
    </rocket-button>
  </div>
</div>

<ng-template
  #GRAPHIC
  let-item="item"
  let-rangePrice="rangePrice"
  let-precoFormatado="precoFormatado"
  let-precoUltimo="precoUltimo"
  let-maxVolumeAtPriceTotal="maxVolumeAtPriceTotal"
>
  <td [ngClass]="precoFormatado === precoUltimo ? classPrice : ''">
    {{ precoFormatado }}
  </td>
  <td
    [ngClass]="{
      'opacity-intense': !rangePrice[item.preco]?.qtty_total
    }"
  >
    {{
      rangePrice[item.preco]?.qtty_total
        ? (rangePrice[item.preco]?.qtty_total | number)
        : ''
    }}
  </td>
  <td class="position-relative atprice d-flex w-100">
    <div
      *ngIf="rangePrice[item.preco]?.qtty_buyer"
      class="atprice atprice-buy white-space"
      [style.width.%]="
        (rangePrice[item.preco]?.qtty_buyer / maxVolumeAtPriceTotal) * 100
      "
    ></div>
    <div
      *ngIf="rangePrice[item.preco]?.qtty_seller"
      class="atprice atprice-sell white-space"
      [style.width.%]="
        (rangePrice[item.preco]?.qtty_seller / maxVolumeAtPriceTotal) * 100
      "
    ></div>
    <div
      *ngIf="rangePrice[item.preco]?.qtty_rlp"
      class="atprice atprice-rlp white-space"
      [style.width.%]="
        (rangePrice[item.preco]?.qtty_rlp / maxVolumeAtPriceTotal) * 100
      "
    ></div>
    <div
      *ngIf="rangePrice[item.preco]?.qtty_direct"
      class="atprice atprice-direct white-space"
      [style.width.%]="
        (rangePrice[item.preco]?.qtty_direct / maxVolumeAtPriceTotal) * 100
      "
    ></div>
    <div *ngIf="!rangePrice[item.preco]?.qtty_total" class="white-space"></div>
  </td>
</ng-template>

<ng-template
  #AGGRESSORS
  let-item="item"
  let-rangePrice="rangePrice"
  let-precoFormatado="precoFormatado"
  let-precoUltimo="precoUltimo"
  let-maxVolumeAtPriceTotal="maxVolumeAtPriceTotal"
>
  <td class="position-relative atprice d-flex w-100 justify-content-end">
    <div
      *ngIf="rangePrice[item.preco]?.qtty_buyer"
      class="atprice atprice-buy white-space"
      [style.width.%]="
        (rangePrice[item.preco]?.qtty_buyer / maxVolumeAtPriceTotal) * 100
      "
    ></div>
    <div *ngIf="!rangePrice[item.preco]?.qtty_buyer" class="white-space"></div>
  </td>
  <td
    [ngClass]="{
      'opacity-intense': !rangePrice[item.preco]?.qtty_buyer
    }"
  >
    {{
      rangePrice[item.preco]?.qtty_buyer
        ? (rangePrice[item.preco]?.qtty_buyer | number)
        : ''
    }}
  </td>
  <td [ngClass]="item.precoFormatado === precoUltimo ? classPrice : ''">
    {{ item.precoFormatado }}
  </td>
  <td
    [ngClass]="{
      'opacity-intense': !rangePrice[item.preco]?.qtty_seller
    }"
  >
    {{
      rangePrice[item.preco]?.qtty_seller
        ? (rangePrice[item.preco]?.qtty_seller | number)
        : ''
    }}
  </td>
  <td class="position-relative atprice d-flex w-100">
    <div
      *ngIf="rangePrice[item.preco]?.qtty_seller"
      class="atprice atprice-sell white-space"
      [style.width.%]="
        (rangePrice[item.preco]?.qtty_seller / maxVolumeAtPriceTotal) * 100
      "
    ></div>
    <div class="white-space" *ngIf="!rangePrice[item.preco]?.qtty_seller"></div>
  </td>
</ng-template>

<ng-template
  #AGGRESSORS_BALANCE
  let-item="item"
  let-rangePrice="rangePrice"
  let-precoFormatado="precoFormatado"
  let-precoUltimo="precoUltimo"
  let-maxVolumeAtPriceTotal="maxVolumeAtPriceTotal"
>
  <td class="position-relative atprice d-flex w-100 justify-content-end">
    <div
      *ngIf="
        rangePrice[item.preco]?.qtty_buyer > rangePrice[item.preco]?.qtty_seller
      "
      class="atprice atprice-buy white-space"
      [style.width.%]="
        (rangePrice[item.preco]?.qtty_buyer / maxVolumeAtPriceTotal) * 100
      "
    ></div>

    <div
      class="white-space"
      *ngIf="
        rangePrice[item.preco]?.qtty_buyer < rangePrice[item.preco]?.qtty_seller
      "
    ></div>
  </td>
  <td
    [ngClass]="{
      'opacity-intense':
        rangePrice[item.preco]?.qtty_seller >
          rangePrice[item.preco]?.qtty_buyer ||
        !rangePrice[item.preco]?.qtty_buyer
    }"
  >
    {{
      rangePrice[item.preco]?.qtty_buyer > rangePrice[item.preco]?.qtty_seller
        ? (rangePrice[item.preco]?.qtty_buyer -
            rangePrice[item.preco]?.qtty_seller | number)
        : ''
    }}
  </td>
  <td [ngClass]="item.precoFormatado === precoUltimo ? classPrice : ''">
    {{ item.precoFormatado }}
  </td>
  <td
    [ngClass]="{
      'opacity-intense':
        rangePrice[item.preco]?.qtty_buyer >
          rangePrice[item.preco]?.qtty_seller ||
        !rangePrice[item.preco]?.qtty_seller
    }"
  >
    {{
      rangePrice[item.preco]?.qtty_seller > rangePrice[item.preco]?.qtty_buyer
        ? (rangePrice[item.preco]?.qtty_seller -
            rangePrice[item.preco]?.qtty_buyer | number)
        : ''
    }}
  </td>
  <td class="position-relative atprice d-flex w-100">
    <div
      *ngIf="
        rangePrice[item.preco]?.qtty_seller > rangePrice[item.preco]?.qtty_buyer
      "
      class="atprice atprice-sell white-space"
      [style.width.%]="
        (rangePrice[item.preco]?.qtty_seller / maxVolumeAtPriceTotal) * 100
      "
    ></div>
    <div
      class="white-space"
      *ngIf="
        rangePrice[item.preco]?.qtty_seller < rangePrice[item.preco]?.qtty_buyer
      "
    ></div>
  </td>
</ng-template>

<ng-template
  #RESUME
  let-item="item"
  let-rangePrice="rangePrice"
  let-precoFormatado="precoFormatado"
  let-precoUltimo="precoUltimo"
  let-maxVolumeAtPriceTotal="maxVolumeAtPriceTotal"
>
  <td [ngClass]="item.precoFormatado === precoUltimo ? classPrice : ''">
    {{ item.precoFormatado }}
  </td>
  <td
    [ngClass]="{
      'opacity-intense': !rangePrice[item.preco]?.qtty_total
    }"
  >
    {{
      rangePrice[item.preco]?.qtty_total &&
      rangePrice[item.preco]?.qtty_total > 0
        ? (rangePrice[item.preco]?.qtty_total | number)
        : ''
    }}
  </td>
  <td
    [ngClass]="{
      'opacity-intense': !rangePrice[item.preco]?.qtty_buyer
    }"
  >
    {{
      rangePrice[item.preco]?.qtty_buyer &&
      rangePrice[item.preco]?.qtty_buyer > 0
        ? (rangePrice[item.preco]?.qtty_buyer | number)
        : ''
    }}
  </td>
  <td
    [ngClass]="{
      'opacity-intense': !rangePrice[item.preco]?.qtty_seller
    }"
  >
    {{
      rangePrice[item.preco]?.qtty_seller &&
      rangePrice[item.preco]?.qtty_seller > 0
        ? (rangePrice[item.preco]?.qtty_seller | number)
        : ''
    }}
  </td>
  <td
    [ngClass]="{
      'atprice-buy':
        rangePrice[item.preco]?.qtty_buyer >
        rangePrice[item.preco]?.qtty_seller,
      'atprice-sell':
        rangePrice[item.preco]?.qtty_seller > rangePrice[item.preco]?.qtty_buyer
    }"
  >
    <ng-container
      *ngIf="
        rangePrice[item.preco]?.qtty_seller > rangePrice[item.preco]?.qtty_buyer
      "
    >
      {{
        rangePrice[item.preco]?.qtty_seller -
          rangePrice[item.preco]?.qtty_buyer !==
        0
          ? (rangePrice[item.preco]?.qtty_seller -
              rangePrice[item.preco]?.qtty_buyer | number)
          : ''
      }}
    </ng-container>

    <ng-container
      *ngIf="
        rangePrice[item.preco]?.qtty_buyer > rangePrice[item.preco]?.qtty_seller
      "
    >
      {{
        rangePrice[item.preco]?.qtty_buyer -
          rangePrice[item.preco]?.qtty_seller !==
        0
          ? (rangePrice[item.preco]?.qtty_buyer -
              rangePrice[item.preco]?.qtty_seller | number)
          : ''
      }}
    </ng-container>
  </td>
</ng-template>
