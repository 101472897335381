import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { IStockChartConfig } from '../../interface/stock-chart.interface';
import { ChartBase } from '../../model/stock-chart.model';
import { RocketModalService } from '@shared/rocket-components/components/index';
import { deepClone, randomId } from '@shared/rocket-components/utils';
import {
  TIGER_INDICATORS_ENUM,
  TIGER_INTERVAL_ENUM,
  TIGER_TYPE_CHART_ENUM,
} from '@shared/tiger-chart/enum/tiger-chart.enum';
import { IndicatorsModalComponent } from './indicators-modal/indicators-modal.component';
import { StockChartService } from '../../service/stock-chart.service';
import { Subject, Subscription, auditTime, filter, map, tap } from 'rxjs';
import { CONTEXT_MENU_ORDER_TYPE } from '@shared/tiger-chart/enum';
import {
  CHART_LOADING_KEYS,
  STOCK_CHART_ELEMENT_IDS,
  HEADER_TYPES,
} from '../../enum/stock-chart.enum';
import { ISearchStock, IWorkSpaceComponet } from 'src/app/core/interface';
import { HEADER_SIZES } from '../../constants/stock-chart.constant';
import { SearchStockComponent } from '@shared/components/search-stock/search-stock.component';
import { DragService } from '@shared/rocket-components/services/ag-grid/drag.service';
import { StockPreferencesService } from '@shared/services/stock-preferences.service';
import { OriginAnalysisOrderService } from '@shared/services/origin-analysis-order.service';
import { ORDER_PARAM_HELPER } from '@shared/constants/order-param-helper.const';
import { isTypeStockIndex } from '@shared/constants/general.contant';
import { formatterNumber } from 'src/app/utils/utils.functions';
import { AUTH_LOCAL_KEYS } from '@shared/services/core/const/auth_util.const';

@Component({
  selector: 'app-stock-chart-header',
  templateUrl: 'stock-chart-header.component.html',
  styleUrls: ['./stock-chart-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockChartHeaderComponent
  extends ChartBase
  implements AfterViewInit, OnChanges, OnDestroy
{
  stockControl = new FormControl();
  negotiationLotControl = new FormControl(100);
  balance: number = 0;
  randomId = randomId('indicator');
  private _indicatorSelectedSub!: Subscription;
  private dragEvents$!: Subscription;
  private blockChartButtons$!: Subscription;
  private onChangePreference$!: Subscription;
  private _quoteHandler = new Subscription();
  private _resize$!: ResizeObserver;
  public sideOrders = CONTEXT_MENU_ORDER_TYPE;
  public loadingKeys = CHART_LOADING_KEYS;
  public disableButtons: boolean = false;
  public resetQttyValue: boolean = false;
  public headerType: HEADER_TYPES = HEADER_TYPES.NANO;
  public _previusHeaderType!: HEADER_TYPES | null;
  public allHeaderTypes = HEADER_TYPES;
  public negotiationLot = 0;
  public elementIDS = STOCK_CHART_ELEMENT_IDS;
  showTools: boolean = true;
  public qttyChangeEnabled: boolean = false;
  public qttyChangedColorEnabled: boolean = false;
  private previousStock!: ISearchStock;
  private detectChangesSubject = new Subject<void>();
  public dayVariation: string = '0,00';
  public colorVariation!: string;
  public bgVariation!: string;
  @ViewChild('searchStock') searchStock!: SearchStockComponent;
  @ViewChild('stockChartHeader') public stockChartHeader!: ElementRef;
  @Input() configs!: IStockChartConfig;
  @Input() component!: IWorkSpaceComponet;
  @Input() showFastOrder!: boolean;
  @Input() standardLot!: number;
  @Input() refComponent!: string;
  @Input() headerOptions!: any;
  @Input() withTools: boolean = true;
  @Input() set useDefaultHeader(useDefaultHeader: boolean) {
    if (useDefaultHeader) {
      this._previusHeaderType = this.headerType;
      this.headerType = HEADER_TYPES.DEFAULT;
    } else if (this._previusHeaderType && !useDefaultHeader) {
      this.headerType = this._previusHeaderType;
      this._previusHeaderType = null;
    }
  }
  @Output() changetAccount: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeStock = new EventEmitter<ISearchStock>();
  @Output() changeInterval = new EventEmitter<TIGER_INTERVAL_ENUM>();
  @Output() chageTypeGrafic = new EventEmitter<TIGER_TYPE_CHART_ENUM>();
  @Output() changeNegotiationLot = new EventEmitter<number>();
  @Output() chageIndicator = new EventEmitter<{
    value: TIGER_INDICATORS_ENUM;
    actives: TIGER_INDICATORS_ENUM[];
    action: 'add' | 'remove';
    lineNumber: number;
  }>();
  @Output() openTool = new EventEmitter<
    'BUSINESS_PROFILE_COMPONENT' | 'RANKING_BROKER'
  >();

  get intervals() {
    return this.intervalDic.values();
  }

  get indicators() {
    return this.indicatorsDic.values();
  }

  get types() {
    return this.typeDic.values();
  }

  get selectedInterval() {
    return this.configs.chart && this.configs.chart.series
      ? this.intervalDic.get(this.configs.chart.series!.interval!)?.short
      : '';
  }

  get stock() {
    return (this.configs?.stock as ISearchStock) ?? null;
  }

  get isBlockStockIndex() {
    return isTypeStockIndex(this.configs?.stock?.type || '');
  }

  loading!: any;
  public orderParamHelper = ORDER_PARAM_HELPER;
  private componentWidth = 0;

  constructor(
    private cdr: ChangeDetectorRef,
    private _rocketModalService: RocketModalService,
    private stockChartService: StockChartService,
    private dragService: DragService,
    private _stockPreferencesService: StockPreferencesService,
    private originAnalysisOrderService: OriginAnalysisOrderService
  ) {
    super();
    this.onChangePreference$ = this._stockPreferencesService
      .onChangePreferences()
      .pipe(
        tap(() => (this.resetQttyValue = true)),
        filter((data) => this._shouldUpdatePreferences(data))
      )
      .subscribe(() => {
        this.resetQttyValue = false;
        this.getPreferencesStock();
      });
    this.blockChartButtons$ = this.stockChartService.blockChartButtons$
      .pipe(filter((data) => data.ref === this.refComponent))
      .subscribe((data) => {
        this.disableButtons = data.isBlock;
        this.cdr.detectChanges();
      });
    this._indicatorSelectedSub = this.stockChartService.indicatorSelected$
      .pipe()
      .subscribe((value) => {
        if (value.fatherId === this.randomId) {
          this.indicator(value.indicatorId);
        }
      });
    this.detectChangesSubject.pipe(auditTime(100)).subscribe(() => {
      this.cdr.detectChanges();
    });
    this._quoteHandler = this.stockChartService.updateQuoteData$
      .pipe(
        filter((data: any) => {
          return (
            data &&
            this.stock &&
            data.quoteData.cd_stock === this.stock.cd_stock
          );
        }),
        map((data) => data.quoteData)
      )
      .subscribe((quoteData) => {
        if (quoteData.arrow_font_hex)
          this.colorVariation = quoteData.arrow_font_hex;
        if (quoteData.arrow_hex) this.bgVariation = quoteData.arrow_hex;
        if (quoteData.variacao_dia || quoteData.variacao_dia === 0)
          this.dayVariation = formatterNumber(+quoteData.variacao_dia);
        this.cdr.detectChanges();
      });
  }

  ngAfterViewInit() {
    this.dragEvents$ = this.dragService
      .onEvents(this.refComponent)
      .subscribe((data) => {
        this.selectStockByCdStock(data.data.cd_stock);
      });

    this._resize$ = new ResizeObserver((entries) => {
      if (this.useDefaultHeader) return;
      this.componentWidth = entries[0].contentRect.width;
      this.setHeaderType();
    });
    this._resize$.observe(this.stockChartHeader.nativeElement);
    this.getPreferencesStock();
    this.loading = this.stockChartService.loading;
    this.stockChartService.loadingButton$.subscribe((data) => {
      if (data) {
        this.loading[data.type] = data.bool;
        this.detectChanges();
      }
    });
    this.showTools = this.withTools;
  }

  private detectChanges() {
    if (this.detectChangesSubject && !this.detectChangesSubject.closed) {
      this.detectChangesSubject.next();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const { configs, standardLot, showFastOrder } = changes;
    if (showFastOrder && showFastOrder?.currentValue) {
      this.qttyChangeEnabled = false;
      this.setHeaderType();
    } else if (
      showFastOrder &&
      !showFastOrder?.currentValue &&
      showFastOrder?.previousValue
    ) {
      this.getPreferencesStock();
      this.setHeaderType();
    }

    if (configs && configs.currentValue) {
      this.stockControl.setValue(configs.currentValue.stock.cd_stock);
      const standard_lot = configs.currentValue.chart.negotiationLot
        ? configs.currentValue.chart.negotiationLot
        : configs.currentValue.stock.standard_lot;
      if (!this.negotiationLot) {
        this.negotiationLot = deepClone(standard_lot);
        this.detectChanges();
      }
      if (
        standard_lot === this.getNegotiationLot() &&
        this.previousStock?.cd_stock === configs.currentValue.stock.cd_stock
      ) {
        return;
      }
      this.previousStock = deepClone(configs.currentValue.stock);
      this.negotiationLot = deepClone(standard_lot);
      this.resizeNegotiationLotInput();
      this.getPreferencesStock();
      this.detectChanges();
    }

    if (standardLot && standardLot.currentValue) {
      if (standardLot.currentValue === this.getNegotiationLot()) return;
      this.resizeNegotiationLotInput();
    }
  }

  ngOnDestroy() {
    this.detectChangesSubject.unsubscribe();
    this._indicatorSelectedSub && this._indicatorSelectedSub.unsubscribe();
    this.dragEvents$ && this.dragEvents$.unsubscribe();
    this.stockChartHeader?.nativeElement &&
      this._resize$.unobserve(this.stockChartHeader.nativeElement);
    this.detectChangesSubject && this.detectChangesSubject.unsubscribe();
    this.blockChartButtons$ && this.blockChartButtons$.unsubscribe();
    this.onChangePreference$ && this.onChangePreference$.unsubscribe();
    this._quoteHandler.unsubscribe();
  }

  selectStock(value: ISearchStock) {
    this.changeStock.emit(value);
    this.getPreferencesStock();
    this.detectChanges();
  }

  onchangeInterval(value: TIGER_INTERVAL_ENUM) {
    this.changeInterval.emit(value);
  }

  onchageTypeGrafic(value: TIGER_TYPE_CHART_ENUM) {
    this.chageTypeGrafic.emit(value);
  }

  onNegotiationLotChange(event: FormControl) {
    this._qttyChange(event);
    if (!this.negotiationLotControl.value) {
      return;
    }
    this.resizeNegotiationLotInput();
    this.changeNegotiationLot.emit(this.negotiationLotControl.value);
  }

  resizeNegotiationLotInput() {
    if (!this.negotiationLotControl.value) {
      return;
    }
    const maxWidth =
      this.negotiationLotControl.value.toString().length <= 3
        ? '65px'
        : `${
            70 + this.negotiationLotControl.value.toString().length * 2 + 2
          }px`;
    document.querySelectorAll('.order-quantity-input div').forEach((field) => {
      (field as HTMLElement).style.maxWidth = maxWidth;
    });
  }

  indicator = (value: TIGER_INDICATORS_ENUM) => {
    this.configs.indicators = {
      value,
      actives: [],
      action: 'add',
    };
    this.chageIndicator.emit(this.configs.indicators as any);
    this.detectChanges();
  };

  openIndicatorsModal() {
    this._rocketModalService.open(IndicatorsModalComponent, {
      size: 'lg',
      data: {
        indicators: this.indicators,
        fatherId: this.randomId,
        chartRef: this.refComponent,
      },
      css: 'new-padding-body',
      backdrop: true,
    });
  }

  sendMarketOrder(type: CONTEXT_MENU_ORDER_TYPE, origin: string) {
    if (this.disableButtons) return;
    this.originAnalysisOrderService.setOriginOrder(origin);
    !this.loading.MARKET_BUY_BUTTON &&
      !this.loading.MARKET_SELL_BUTTON &&
      this.stockChartService.sendMarketOrder(type, this.refComponent);
  }

  dispatchTools() {
    this.showTools = !this.showTools;
    this.stockChartService.tools$.next({
      show: this.showTools,
      refComponent: this.refComponent,
    });
    this.detectChanges();
  }

  private setHeaderType(): void {
    let emptySpace = this.componentWidth - 90;
    if (this.showFastOrder) emptySpace = emptySpace + 150;
    let type = HEADER_TYPES.NANO;
    if (emptySpace >= HEADER_SIZES.DEFAULT) type = HEADER_TYPES.DEFAULT;
    else if (emptySpace >= HEADER_SIZES.SCALE_ONE)
      type = HEADER_TYPES.SCALE_ONE;
    else if (emptySpace >= HEADER_SIZES.MEDIUM) type = HEADER_TYPES.MEDIUM;
    else if (emptySpace >= HEADER_SIZES.SMALL) type = HEADER_TYPES.SMALL;
    else if (emptySpace >= HEADER_SIZES.MICRO) type = HEADER_TYPES.MICRO;
    this.headerType = type;
    this.detectChanges();
  }

  private getNegotiationLot(): number {
    const control: any = this.negotiationLotControl;
    return parseInt(
      control.value.toString().replace('.', '').replace(',', '.')
    );
  }

  updateStock(stock: ISearchStock) {
    this.searchStock.selectStock(stock);
  }

  selectStockByCdStock(cdStock: string) {
    this.searchStock.selectStockByCdStock(cdStock);
  }

  private _shouldUpdatePreferences(event: { key: string }): boolean {
    if (
      event.key === AUTH_LOCAL_KEYS.USE_SPLIT ||
      event.key === AUTH_LOCAL_KEYS.SAVE_QTTY_GROUP_STOCKS
    )
      return true;
    this.resetQttyValue = false;
    return false;
  }
  private getPreferencesStock(): void {
    this.qttyChangeEnabled = false;
    this.qttyChangedColorEnabled = false;
    if (this._stockPreferencesService.saveQttyStockEnabled) {
      this._setStockCustomQtty();
      return;
    }
    this.negotiationLot = deepClone(this.standardLot);
    this.negotiationLotControl.setValue(this.negotiationLot);
  }

  private _setStockCustomQtty(): void {
    const stocksCustomsQtty =
      this._stockPreferencesService.stocksCustomPreferences;
    let stockQtty = this.standardLot;
    const stockPreference = stocksCustomsQtty[this.stock.cd_stock_order];
    if (stockPreference && stockPreference.qtty) {
      this.qttyChangedColorEnabled = true;
      stockQtty = stockPreference.qtty;
    }
    this.qttyChangeEnabled = true;
    this.negotiationLot = stockQtty;
    this.negotiationLotControl.setValue(stockQtty);
  }

  private _qttyChange(field: FormControl) {
    if (
      !this.qttyChangeEnabled ||
      this.negotiationLotControl.value === field.value
    )
      return;
    this._stockPreferencesService.setStockQtty(
      this.stock.cd_stock_order,
      field.value,
      this.standardLot
    );
  }

  handleOpenTool(code: 'BUSINESS_PROFILE_COMPONENT' | 'RANKING_BROKER') {
    this.openTool.emit(code);
  }

  openMarketFlash() {
    console.log(true);
  }
}
