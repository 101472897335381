export enum FLA_DATE_PICKER_FORMAT {
  DD_MM_YYY = 'dd/MM/yyyy',
  YYYY_MM_DD = 'yyyy-MM-dd',
}

export enum DATE_PICKER_VIEW_TYPE {
  DAY = 'D',
  MONTH = 'M',
  YEAR = 'Y',
}

type positions = 'TOP' | 'BOTTOM';
type directions = 'LEFT' | 'RIGHT' | 'CENTER';
type centered = 'CENTER';
export declare type FLA_DATE_PICKER_POSITION =
  | `${positions}-${directions}`
  | `${directions}-${centered}`;
