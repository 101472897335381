import { Injectable } from '@angular/core';
import {
  EAnnotationLayer,
  ECoordinateMode,
  EHorizontalAnchorPoint,
  EVerticalAnchorPoint,
  NativeTextAnnotation,
  TWebAssemblyChart,
} from 'scichart';
import { TIGER_INTERVAL_ENUM } from '../enum';
import { INTERVAL_CHART } from '@shared/components/stock-chart/constants/stock-chart.constant';
import { CHART_COLORS } from '../colors';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { Dictionary } from '@core/models';
import { StockChartModalService } from '@shared/components/stock-chart/parts/modal-more-options/service/stock-chart-modal.service';
@Injectable({
  providedIn: 'root',
})
export class WatermarkService {
  constructor(private stockChartModalService: StockChartModalService) {}
  private watermarks = new Dictionary<{
    annotationStock: NativeTextAnnotation;
    annotationBusiness: NativeTextAnnotation;
  }>();
  setWatermark = (
    stockChartService: StockChartService,
    baseChart: TWebAssemblyChart,
    xAxisId: string,
    id: string,
    idBusiness: string,
    stockNmCompany: string,
    papel: string,
    interval: TIGER_INTERVAL_ENUM,
    color: string | null = null,
    nmCompanyValemobi?: string
  ) => {
    if (!baseChart || !papel) return;
    const intervalSelected = INTERVAL_CHART.find(
      (intervalC) => intervalC.id == interval
    );
    const text = papel ? `${papel}, ${intervalSelected?.short}` : '';
    const textBusiness = papel
      ? `‎\n${nmCompanyValemobi || stockNmCompany}`
      : '';
    const watermarks = this.watermarks.get(id);
    if (watermarks) {
      if (color && color != watermarks.annotationStock.textColor) {
        watermarks.annotationStock.textColor = color;
        watermarks.annotationBusiness.textColor = color;
      }
      if (
        watermarks.annotationStock.text == text &&
        watermarks.annotationBusiness.text == textBusiness
      ) {
        return;
      }
      watermarks.annotationStock.text = text;
      watermarks.annotationBusiness.text = textBusiness;
      return;
    }
    let textColor!: string;
    if (color) {
      if (watermarks) {
        baseChart.sciChartSurface.annotations.remove(
          (watermarks as any).annotationStock
        );
        baseChart.sciChartSurface.annotations.remove(
          (watermarks as any).annotationBusiness
        );
      }
      textColor = color;
    } else {
      const infoColor = this.stockAnnotationColor(
        stockChartService.isDarkTheme()
      );
      textColor = infoColor.color;
    }
    const annotationStock = this.generateWatermarkAnnotation(
      0.5,
      id,
      xAxisId,
      text,
      textColor,
      36
    );
    const annotationBusiness = this.generateWatermarkAnnotation(
      0.5,
      idBusiness,
      xAxisId,
      textBusiness,
      textColor,
      28
    );
    this.watermarks.set(id, { annotationStock, annotationBusiness });
    baseChart.sciChartSurface
      .registerFont(
        'staticOpenSans',
        `${window.location.origin}/assets/fonts/Open_Sans/static/OpenSans-Bold.ttf`
      )
      .then(() => {
        const watermarks = this.watermarks.get(id);
        baseChart.sciChartSurface.annotations.add(watermarks!.annotationStock);
        baseChart.sciChartSurface.annotations.add(
          watermarks!.annotationBusiness
        );
      });
  };

  stockAnnotationColor(isDarkTheme: boolean): {
    color: string;
  } {
    const hash: any = {
      true: {
        color: `${CHART_COLORS.NEUTRAL_SMOOTHER}45`,
      },
      false: {
        color: `${CHART_COLORS.NEUTRAL_STRONGER}60`,
      },
    };
    return hash[String(isDarkTheme)];
  }

  private generateWatermarkAnnotation(
    y1: number,
    id: string,
    xAxisId: string,
    text: string,
    textColor: string,
    fontSize: number
  ): NativeTextAnnotation {
    const configuration = this.stockChartModalService.getGlobalChartConfigs();
    return new NativeTextAnnotation({
      id,
      text,
      horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
      verticalAnchorPoint: EVerticalAnchorPoint.Center,
      x1: 0.5,
      y1,
      fontSize,
      opacity: configuration.watermarkOpacity / 100,
      isHidden: !configuration.showWatermark,
      fontFamily: 'staticOpenSans',
      textColor,
      xCoordinateMode: ECoordinateMode.Relative,
      yCoordinateMode: ECoordinateMode.Relative,
      annotationLayer: EAnnotationLayer.BelowChart,
      xAxisId,
      lineSpacing: 60,
    });
  }
}
