import { Subject } from 'rxjs';
import { Point } from 'scichart/Core/Point';
import {
  IYAxisDragModifierOptions,
  YAxisDragModifier,
} from 'scichart/Charting/ChartModifiers/YAxisDragModifier';
import { ModifierMouseArgs } from 'scichart/Charting/ChartModifiers/ModifierMouseArgs';
import { testIsInBounds } from 'scichart/utils/pointUtil';
import { TIGER_MOUSE_EVENT_DATA_TYPE } from '../enum';
import { AxisBase2D } from 'scichart';

export class TigerYAxisDragModifier extends YAxisDragModifier {
  events$ = new Subject<any>();
  lastPoint!: Point | undefined;

  constructor(options?: IYAxisDragModifierOptions) {
    super(options);
  }

  middleClickScaling(from: Point, to: Point, yAxis: AxisBase2D[]) {
    if (from.y == to.y) {
      return;
    }
    this.activeAxes = yAxis;
    this.pointFrom = from;
    this.makeGrowBy(to, 0.15);
  }

  protected override doScaling(pointTo: Point): void {
    this.makeGrowBy(pointTo, 0.05);
  }

  private makeGrowBy(pointTo: Point, scaling: number) {
    if (this.pointFrom) {
      const pointY = this.activeAxes[0].viewRect.height / 2;
      const pointFrom = this.lastPoint ?? this.pointFrom;
      const fraction = pointFrom?.y > pointTo.y ? -scaling : scaling;
      this.growBy(new Point(pointTo.x, pointY), this.activeAxes[0], fraction);
      this.lastPoint = pointTo;
    }
    this.events$.next({
      type: TIGER_MOUSE_EVENT_DATA_TYPE.YAXIS_GROW,
    });
  }

  override modifierMouseMove(args: ModifierMouseArgs): void {
    super.modifierMouseMove(args);
    const mousePoint = args.mousePoint;
    this.parentSurface?.yAxes.asArray().forEach((yAxis) => {
      const viewRect = yAxis.viewRect;
      if (!viewRect) {
        return;
      }
      if (
        testIsInBounds(
          mousePoint.x,
          mousePoint.y,
          viewRect.left,
          viewRect.bottom,
          viewRect.right,
          viewRect.top
        )
      ) {
        this.events$.next({
          type: TIGER_MOUSE_EVENT_DATA_TYPE.YAXIS_HOVER,
          data: null,
        });
      } else {
        this.events$.next({});
      }
    });
  }
}
