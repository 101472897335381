import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'fla-card-horizontal[src]',
  templateUrl: './card-horizontal.component.html',
})
export class FlaCardHorizontalComponent {
  @ViewChild(TemplateRef) content!: TemplateRef<any>;
  @Input() col: string = 'col';
  @Input() src!: string;
  @Input('css') customClass: string = '';
}
