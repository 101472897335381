import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { CARD_FIELDS } from './orders-history-fields.const';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { RocketStreamRead } from '@shared/channel/rx-event';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { Dictionary } from '@core/models';
import { IAlert, IOrders, IPosition } from '@core/interface';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { ChartService } from '@shared/services/api/trademap/V3/chart.service';
import { IosmaChannel } from '@shared/channel/iosma.channel';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { OrdersService } from '@shared/services/orders.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-orders-history-card-list',
  templateUrl: './orders-history-card-list.component.html',
  styles: [
    `
      :host(app-orders-history-card-list) {
        display: contents;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersHistoryCardListComponent
  extends ReadStreamBase
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @ViewChild('scrollViewport') viewport!: CdkVirtualScrollViewport;
  @Input() refComponent!: any;
  @Input() componentType!: string;
  @Input() componentId!: any;
  @Input() width!: any;
  @Input() height!: string;
  @Input() data!: any[];
  @Input() tabSelected!: any;
  @Input() globalCdStock!: string;
  @Output() contextMenuHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeStockHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() cardsReadyHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() editOrderHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() editAlertHandler: EventEmitter<IAlert> = new EventEmitter<IAlert>();
  @Output() resetPositionHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeAllAlertsAndOrders: EventEmitter<any> =
    new EventEmitter<any>();

  constructor(
    private quoteChannel: QuoteChannel,
    private iosmaChannel: IosmaChannel,
    private cdr: ChangeDetectorRef,
    private chartService: ChartService,
    private ordersService: OrdersService
  ) {
    super();
  }
  private onDestroy$ = new Subject<void>();
  private subscribeQuote$ = new Subject<void>();
  private unGetVlClose$ = new Subject<void>();
  private quoteCheetah$!: RocketStreamRead;
  private iosmaCheetah$!: RocketStreamRead;
  private quoteParams!: SubscribeParam;
  private iosmaParams!: SubscribeParam;
  public fields: any;
  public stockDict = new Dictionary<any>();
  public quoteDict: { [key: string]: any } = {};
  public alertDict: { [key: string]: any } = {};
  public vlCloseHistory = new Dictionary<any>();
  public cheetahStocks: string[] = [];
  public loading: boolean = false;
  get isCustody() {
    return ['POSITION', 'SWINGTRADING', 'INTRADIA'].includes(
      this.tabSelected?.component_type
    );
  }

  get isOrder() {
    return this.tabSelected?.component_type === 'ORDER';
  }

  get isAlert() {
    return this.tabSelected?.component_type === 'ALERTS';
  }

  get stocksToDisplay() {
    if (this.isOrder) {
      return this.stockDict
        .values()
        .sort((a, b) => (b.dh_creation_time || 0) - (a.dh_creation_time || 0));
    }
    return this.stockDict.values();
  }

  get tabSelectedRef() {
    return this.tabSelected?.ref;
  }

  get cardHeight() {
    if (this.isOrder) {
      return 61;
    }
    return 66;
  }

  ngOnInit() {
    this.subscribeCheetahHandler();
    this.processCheetah();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.unGetVlClose$.next();
    this.unGetVlClose$.complete();
    this.unsubscribeQuote(this.cheetahStocks);
    this.quoteCheetah$?.close();
    this.iosmaCheetah$?.close();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { data, tabSelected, width, height } = changes;
    if (tabSelected?.currentValue) {
      this.getFieldsByTab();
    }
    if (data?.currentValue){
      if (data?.previousValue?.length !== data?.currentValue?.length) {
        this.processCheetah();
      }else{
        this.updateData()
      }
    }
    if (width?.currentValue) {
      this.cdr.detectChanges();
    }
    if (height?.currentValue) {
      if (this.viewport) {
        this.viewport.checkViewportSize();
      }
    }
  }

  ngAfterViewInit() {
    this.cardsReadyHandler.emit();
  }

  public subscribeCheetahHandler = () => {
    this.subscribeQuote$
      .pipe(debounceTime(100), takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.cheetahStocks.length)
          this.startQuoteSubscription(this.cheetahStocks);
      });
  };

  public cancelOrder(order: IOrders) {
    this.ordersService.cancelOrders([order]);
  }

  private getFieldsByTab() {
    this.resetView();
    if (this.isCustody) {
      const key = this.tabSelected.key;
      this.fields =
        CARD_FIELDS[key as 'POSITION' | 'SWINGTRADING' | 'INTRADIA'];
    }
  }

  private updateData() {
    this.data.forEach((item) => {
      const key = this.getCode(item)
      const oldData = this.stockDict.get(key)
      if(oldData) this.stockDict.set(key, {...oldData, ...item})
    })
  }

  private resetView() {
    this.loading = true;
    this.stockDict.clear();
    this.unsubscribeIosma(
      this.cheetahStocks.map((item) => {
        const stock = item.split(':');
        return `${stock[0]}.ONE_DAY:${stock[1]}`;
      })
    );
    if (this.cheetahStocks.length) this.unsubscribeQuote(this.cheetahStocks);
    this.unGetVlClose$.next();
    this.unGetVlClose$.complete();
    this.cheetahStocks = [];
  }

  public processCheetah = () => {
    if (!this.iosmaCheetah$) {
      this.iosmaChannel.readEvents().then((response) => {
        this.iosmaCheetah$ = response;
        this.readStream(this.iosmaCheetah$.stream, this.iosmaHandler);
      });
    }

    this.getQuoteSubscription();
  };

  public getQuoteSubscription() {
    if (this.isCustody) this.custodyQuoteSubscription();
    if (this.isOrder) this.orderQuoteSubscription();
    if (this.isAlert) this.alertQuoteSubscription();
    this.loading = false;
  }

  private custodyQuoteSubscription = () => {
    const stocks = this.data.map((item) => this.getCode(item));
    const toRemove = this.cheetahStocks.filter(
      (item) => !stocks.includes(item)
    );
    const toAdd = stocks.filter((item) => !this.cheetahStocks.includes(item));
    if (toRemove.length) {
      this.unsubscribeQuote(toRemove);
      toRemove.forEach((key) => {
        this.vlCloseHistory.delete(key);
        this.stockDict.delete(key);
      });
    }
    if (toAdd.length) {
      toAdd.forEach((key) => {
        const stockItem = this.data.find((item) => this.getCode(item) === key);
        stockItem &&
          this.stockDict.set(key, {
            ...stockItem,
            code: this.getCode(stockItem),
          });
      });
      this.getVlCloseLimitChart(toAdd);
    }
    this.cheetahStocks = stocks;
    this.subscribeQuote$.next();
    this.cdr.detectChanges();
  };

  get stocksInOrderList() {
    return this.stockDict
      .values()
      .map((order) => this.getCode(order))
      .reduce((acc, item) => {
        if (acc.includes(item)) return acc;
        acc.push(item);
        return acc;
      }, [] as string[]);
  }

  private orderQuoteSubscription() {
    const orders = this.data.map((item) => item.id_order);
    const previous = this.stockDict.keys();

    const toRemove = previous.filter((item) => !orders.includes(item));
    const toAdd = orders.filter((item) => !previous.includes(item));
    if (toRemove.length) {
      toRemove.forEach((idOrder) => {
        const order = this.stockDict.get(idOrder);
        if (order) {
          this.stockDict.delete(idOrder);
          const key = this.getCode(order);
          if (!this.stocksInOrderList.includes(key)) {
            delete this.quoteDict[key];
            this.unsubscribeQuote([key]);
          }
        }
      });
    }
    if (toAdd.length) {
      toAdd.forEach((idOrder) => {
        const order = this.data.find((item) => item.id_order === idOrder);
        const key = this.getCode(order);
        order && this.stockDict.set(idOrder, { ...order, code: key });
      });
    }
    this.cheetahStocks = this.stocksInOrderList;
    this.subscribeQuote$.next();
  }

  private alertQuoteSubscription() {
    const alerts = this.data.map((item) => item.id_trade_system);
    const previous = this.stockDict.keys();
    const toRemove = previous.filter((item) => !alerts.includes(item));
    const toAdd = alerts.filter((item) => !previous.includes(item));
    if (toRemove.length) {
      toRemove.forEach((id_trade_system) => {
        const alert = this.stockDict.get(id_trade_system);
        if (alert) {
          this.stockDict.delete(id_trade_system);
          const key = this.getCode(alert);
          const iosmaKey = this.getIosmaKey(alert);
          if (!this.stocksInOrderList.includes(key)) {
            delete this.quoteDict[key];
            delete this.alertDict[iosmaKey];
            this.unsubscribeQuote([key]);
            this.unsubscribeIosma([iosmaKey]);
          }
        }
      });
    }
    if (toAdd.length) {
      toAdd.forEach((id_trade_system) => {
        const alert = this.data.find(
          (item) => item.id_trade_system === id_trade_system
        );
        const key = this.getCode(alert);
        const iosmaKey = this.getIosmaKey(alert);
        if (!this.stocksInOrderList.includes(key)) {
          this.subscribeIosma([iosmaKey]);
        }
        alert &&
          this.stockDict.set(id_trade_system, {
            ...alert,
            code: key,
            iosmaKey: iosmaKey,
          });
        this.alertDict[iosmaKey] = {};
      });
    }
    this.cheetahStocks = this.stocksInOrderList;
    this.subscribeQuote$.next();
  }

  getCode = (item: any) => {
    return `${item.cd_stock}:${item.id_exchange}`;
  };

  getIosmaKey = (item: any) => {
    return `${item.cd_stock}.ONE_DAY:${item.id_exchange}`;
  };

  private getVlCloseLimitChart = (stocks: string[]) => {
    this.unGetVlClose$.next();
    this.unGetVlClose$.complete();

    this.chartService
      .vlClose(stocks)
      .pipe(takeUntil(this.unGetVlClose$))
      .subscribe((data: any) => {
        stocks.forEach((stock) => {
          this.vlCloseHistory.set(stock, data.close_limit_chart[stock]);
        });
        this.cdr.detectChanges();
      });
  };

  private subscribeIosma = (stocks: string[]) => {
    this.iosmaParams = {
      header: this.refComponent + '_cards',
      itemsArray: stocks,
    };
    this.iosmaChannel.subscribe(this.iosmaParams);
    this.iosmaCheetah$ && this.iosmaCheetah$.snapshot(this.iosmaParams.itemsArray);
  };

  private unsubscribeIosma = (stocks: string[]) => {
    const unsubParams = {
      header: this.refComponent + '_cards',
      itemsArray: stocks,
    };
    this.iosmaChannel.unsubscribe(unsubParams);
  };

  private startQuoteSubscription = (stocks: string[]) => {
    this.quoteParams = {
      itemsArray: stocks,
    };

    if (!this.quoteCheetah$) {
      this.quoteChannel.readEvents().then((response) => {
        this.quoteCheetah$ = response;
        this.readStream(this.quoteCheetah$.stream, this.quoteHandler);
        this.subscribeQuote();
        this.cdr.detectChanges();
      });
      return;
    }
    this.subscribeQuote();
    this.cdr.detectChanges();
  };

  subscribeQuote() {
    this.quoteChannel.subscribe(this.quoteParams);
    this.quoteCheetah$?.snapshot(this.quoteParams.itemsArray);
  }

  private unsubscribeQuote = (stocks: string[]) => {
    const unsubParams = {
      itemsArray: stocks,
    };
    this.quoteChannel.unsubscribe(unsubParams);
  };

  private quoteHandler = (data: Map<string, any>) => {
    data.forEach((value, key) => {
      if (!value || value.isEndOfSnap || !this.cheetahStocks.includes(key))
        return;
      const {
        variacao_dia,
        arrow_font_hex,
        arrow_hex,
        situacao,
        tick_size_denominator,
        preco_ultimo,
      } = value;

      const quote = {
        variacao_dia,
        arrow_font_hex,
        arrow_hex,
        situacao,
        tick_size_denominator,
        preco_ultimo,
      };
      this.quoteDict[key] = quote;
      this.cdr.detectChanges();
    });
  };

  private iosmaHandler = (data: Map<string, any>) => {
    data.forEach((value, key) => {
      if (!isNullOrUndefined(this.alertDict[key])) {
        this.alertDict[key] = value;
        this.cdr.detectChanges();
      }
    });
  };

  public onContextMenu = (event: any, item: any) => {
    event.preventDefault();
    const configs = {
      event,
      refComponent: this.refComponent,
      selectedCard: item,
      isVisible: true,
      isFiltered: false,
      isSorted: false,
    };
    this.contextMenuHandler.emit(configs);
  };

  public resetPosition = (item: IPosition) =>
    this.resetPositionHandler.emit({
      position: item,
      isResetAllPosition: false,
    });
}
