import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TABLE_IDS } from '../../constants/table.constants';
import { TableCellAlign } from '../../types/table.type';
import { TableHelper } from '../../table-helper';
import { TableService } from '../../service/table.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-table-cell',
  templateUrl: './table-cell.component.html',
  styles: [
    `
      :host {
        display: contents;
      }

      .remove-btn-hover:hover {
        background-color: transparent !important;
      }

      .rotate {
        transform: rotate(90deg);
        transition: transform 0.3s ease-in-out;
      }

      .default-position {
        transform: rotate(0deg);
        transition: transform 0.3s ease-in-out;
      }
    `,
  ],
})
export class TableCellComponent
  extends TableHelper
  implements OnChanges, OnDestroy
{
  @Input() width!: string | undefined;
  @Input() height!: string | undefined;
  @Input() colspan: number = 1;
  @Input() css: string = '';
  @Input() cssContent: string = '';
  @Input() ref!: string | number | undefined;
  @Input() refParent!: string | number | undefined;
  @Input() text!: string | number;
  @Input() alignText: TableCellAlign = 'text-left';
  @Input() hasChildren: boolean = false;
  @Input() fixedColumn: boolean = false;
  @Input() visible: boolean = true;
  @Input() delayCollapse: number = 300;
  @Input() leftPadding: number = 40;
  @Input() forceCollapsed: boolean = false;
  @Input() collapsed: boolean = false;
  @Input() minCellWidth!: number;
  @Input() componentID!: string;
  @Output() Click = new EventEmitter<any>();

  @ViewChild('btnElement') btnElement!: ElementRef;
  expanded: boolean = false;

  private _visible: boolean = true;
  private _collapsedSubject = new Subject<void>();
  constructor(private _service: TableService) {
    super();
    this._collapsedSubject
      .pipe(debounceTime(this.delayCollapse))
      .subscribe(() => this.onClick(this.btnElement.nativeElement));
  }

  get idCellBtn() {
    return this.ref !== undefined ? TABLE_IDS.BTN + this.ref : '';
  }

  get idParentRow() {
    return this.ref !== undefined ? TABLE_IDS.ROW + this.refParent : '';
  }

  get allTrChildrens() {
    return document.querySelectorAll<HTMLElement>(
      `tr[data-bs-parent="${this.idParentRow}"]`
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { forceCollapsed, collapsed } = changes;
    if (forceCollapsed?.currentValue || collapsed?.currentValue)
      this._collapsedSubject.next();
  }

  ngOnDestroy(): void {
    this._collapsedSubject.unsubscribe();
  }

  onClick(btnElement: HTMLElement) {
    this.expanded = !this.expanded;
    this._visible = true;
    this.Click.emit({ expanded: this.expanded, btnElement });

    this._toggleTRShow();

    if (!this.expanded) {
      this._visible = false;
      this._closeAllChildren(btnElement);
      this._service.updateWidthCell(this.componentID, this.minCellWidth);
      return;
    }

    this._updateAttributes(btnElement.style.left);
    this._service.updateWidthCell(this.componentID, this.minCellWidth);
  }

  private _toggleTRShow() {
    const row = document.querySelector(
      `tr[data-bs-parent="${this.idParentRow}"]`
    );
    row?.classList.toggle('d-none');
  }
  private _closeAllChildren(btnElem: HTMLElement) {
    const tdChildrens = document.querySelectorAll<HTMLElement>(
      `tr[data-bs-parent=${this.idParentRow}] td[visible="true"]`
    );

    tdChildrens.forEach((td) => {
      this._updateVisibleAttribute(td);
    });

    const btnParentData = btnElem?.getAttribute('data-bs-parent');
    if (btnParentData === this.idParentRow) {
      const query = `tr[data-bs-parent="${this.idParentRow}"] .btn-sub-table[aria-expanded="true"]`;
      const btnELements = document.querySelectorAll<HTMLElement>(query);
      btnELements.forEach((btn) => btn.click());
    }
  }

  private _updateAttributes(leftValue: string) {
    if (leftValue !== '') this.leftPadding = parseFloat(leftValue) + 40;
    this.allTrChildrens.forEach((tr) => {
      const tdElement = tr.querySelector('td') as HTMLElement;
      const cellElement = tr.querySelector('.sticky-col > *') as HTMLElement;
      this._updateVisibleAttribute(tdElement);
      this._updateStyleLeft(cellElement);
      this._updateVisibleAttribute(cellElement);
    });
  }

  private _updateStyleLeft(element: HTMLElement) {
    element.style.left = this.leftPadding + 'px';
  }
  private _updateVisibleAttribute(element: HTMLElement) {
    element.setAttribute('visible', `${this._visible}`);
  }
}
