<ng-template>
  <div class="card" [ngClass]="customClass">
    <div class="hstack">
      <img [src]="src" width="44" height="44" class="round-md mr-2" />
      <div>
        <p class="card-title">{{ title }}</p>
        <ng-content select="small"></ng-content>
      </div>
    </div>
    <div class="card-body mt-2">
      <small class="text-neutral-smooth">{{ body }}</small>
    </div>
  </div>
</ng-template>
