<app-stock-trade-header
  *ngIf="_component"
  [component]="_component"
  [typeBuy]="_component.metadata.component?.type"
  [isEdit]="isEdit"
  (changeType)="changeType($event)"
></app-stock-trade-header>
<section
  #stockTradeRef
  [ngClass]="{
    'boleta-venda': orderSide === sideOrder.SELL,
    'boleta-compra': orderSide === sideOrder.BUY,
    'component-height': isDesktop,
    'h-100': !isDesktop
  }"
  [id]="ref"
>
  <div
    [id]="elementsID.BOOK_CHARTS"
    class="bg-body position-relative"
    [style.height.px]="166"
  >
    <app-stock-trade-grafic
      [refComponent]="refComponent"
      [stock]="stock"
      (lastPrice)="getLastPrice($event)"
      (minPriceIncrement)="getMinPriceIncrement($event)"
      (standardLot)="getStandardLot($event)"
    ></app-stock-trade-grafic>
  </div>
  <section *ngIf="form" class="height-form" [formGroup]="form">
    <div
      class="card mt-auto p-0 round-none border-top position-relative h-100"
      [ngClass]="{
        sell: orderSide === sideOrder.SELL,
        buy: orderSide === sideOrder.BUY
      }"
    >
      <div class="card-body px-3">
        <div class="row px-3">
          <app-dynamic-form
            *ngFor="let field of fieldsForm"
            [field]="field"
            [form]="form"
            [elementID]="field?.elementID"
          >
            <div
              *ngIf="field.key === 'search'"
              #search
              [id]="elementsID.STOCK_SELECTOR"
              [tooltip]="field.tooltip"
              [tooltipDelay]="field.tooltipDelay"
            >
              <app-search-stock
                #searchStock
                label="Ativo"
                css="mb-3"
                [resetInput]="false"
                [ref]="_component.id"
                [headerOptions]="headerOptions()"
                [initStock]="stock"
                [isDisable]="isEdit"
                css="w-100"
                (selectStockChange)="selectStock($event)"
                (openHandler)="eventSearchStock(true)"
                (closeHandler)="eventSearchStock(false)"
              ></app-search-stock>
            </div>
            <div
              *ngIf="field.key === 'qtde'"
              [tooltip]="field.tooltip"
              [tooltipDelay]="field.tooltipDelay"
            >
              <app-input-count
                #qtde
                [formControlName]="field.key"
                [initialValue]="field.initialValue"
                [incrementValue]="standardLot()"
                [min]="standardLot()"
                [isSmall]="field.isSmall"
                [isRequired]="field.required"
                [label]="field.label"
                [mask]="field.flaMask"
                [selectOnFocus]="field.selectOnFocus"
                [type]="field.type"
                css="margin-bottom"
                [enableScrollEvent]="true"
                (flaScroll)="updatePrice(field.key, $event)"
                [tabIndex]="field.tabIndex"
                [tabIndexIncrement]="-1"
              ></app-input-count>
            </div>
            <div
              *ngIf="field.key === 'price'"
              #price
              [tooltip]="field.tooltip"
              [tooltipDelay]="field.tooltipDelay"
            >
              <app-input-count
                [formControlName]="field.key"
                [initialValue]="+vlClosePrice"
                [incrementValue]="incrementPrice"
                [isSmall]="field.isSmall"
                [isRequired]="field.required"
                [label]="field.label"
                [mask]="field.flaMask"
                [selectOnFocus]="field.selectOnFocus"
                [type]="field.type"
                css="margin-bottom"
                [enableUpdateValueBtn]="true"
                [enableScrollEvent]="true"
                (flaScroll)="updatePrice(field.key, $event)"
                (updateValue)="updatePriceToCurrentMarketPrice()"
                (touched)="setWasTouchedWhenUpdatePriceValue()"
                [tabIndex]="field.tabIndex"
                [tabIndexIncrement]="-1"
              ></app-input-count>
            </div>

            <div
              *ngIf="
                priceCounterFields.includes(field.key) && field.controlType
              "
              [tooltip]="field.tooltip"
              [tooltipDelay]="field.tooltipDelay"
            >
              <app-input-count
                #gainOrLossPrice
                [formControlName]="field.key"
                [initialValue]="+hashStops[field.key] || +vlClosePrice"
                [incrementValue]="incrementPrice"
                [isSmall]="field.isSmall"
                [isRequired]="field.required"
                [label]="field.label"
                [mask]="field.flaMask"
                [selectOnFocus]="field.selectOnFocus"
                [type]="field.type"
                css="margin-bottom"
                [enableScrollEvent]="true"
                (flaScroll)="updatePrice(field.key, $event)"
                [tabIndex]="field.tabIndex"
                [tabIndexIncrement]="-1"
              ></app-input-count>
            </div>
            <div
              *ngIf="field.key === 'orderOco' || field.key === 'orderDouble'"
              class="mt-5"
              #orderOcoOrDouble
              [tooltip]="field.tooltip"
              [tooltipDelay]="field.tooltipDelay"
            >
              <button
                type="button"
                [attr.data-selected]="orderOcoOrDoubleControl()"
                class="btn btn-toggle"
                [disabled]="isEdit"
                (click)="checkIsOrderOCOOrDouble()"
                tabindex="-1"
              ></button>
              <span class="fs-6 fw-bold pl-1">{{ field.label }}</span>
            </div>
            <div
              *ngIf="field.key === 'space' || field.key === 'space2'"
              #space
            ></div>
          </app-dynamic-form>
        </div>
        <div
          class="card-footer d-flex bottom-0 position-absolute width-footer justify-content-between pr-3 align-items-center pb-2 mt-0"
        >
          <div
            [id]="elementsID.INFORMATIONS"
            class="mb-0 fs-6 fw-bold text-body pt-1"
          >
            <span tooltip="Valor total">
              Total:
              {{ total() }}
            </span>
            <span class="ml-2" tooltip="Saldo na corretora">
              Saldo:
              {{ brokerBalance }}
            </span>
            <span class="ml-2" tooltip="Corretora selecionada">
              {{ brokerSelect }}
            </span>
          </div>
          <rocket-button
            [label]="loading ? '' : propsBtnSend.text"
            [isSmall]="true"
            [isDisabled]="form.invalid || loading"
            [css]="propsBtnSend.bg + ' round-sm'"
            (rocketClick)="sendOrder()"
          >
            <fla-icon *ngIf="loading" class="spinner" name="loop"></fla-icon>
          </rocket-button>
        </div>
      </div>
    </div>
    <div
      class="align-content-center bg-neutral-smoother card d-flex footer-trade fs-6 fw-bold px-3 py-1 round-none"
      [ngClass]="{
        sell: orderSide === sideOrder.SELL,
        buy: orderSide === sideOrder.BUY
      }"
    >
      <ng-container *ngIf="isReseted">
        Ordem enviada e boleta resetada!
      </ng-container>
    </div>
  </section>
</section>
