<div
  (click)="stockSelected.emit()"
  class="minicard position-relative d-flex flex-column pointer"
  [ngClass]="{ 'with-remove-icon': displayRemoveStock }"
>
  <ng-container *ngIf="displayRemoveStock">
    <app-remove-stock-button
      [displayRemoveStock]="displayRemoveStock"
      (removeStock)="removeStock.emit()"
    ></app-remove-stock-button>
  </ng-container>
  <div
    class="w-100 d-flex flex-column align-items-center justify-content-center"
  >
    <div
      class="d-inline-flex align-items-centvger justify-content-center gap-1"
      style="line-height: 15px"
    >
      <app-status-stock
        [status]="situation"
        [isAuction]="auctionInfos !== undefined"
        [moveTop]="true"
        [auctionInfos]="auctionInfos"
      >
      </app-status-stock>
      <span class="fw-bold fs-7" [ngClass]="titleColor" [tooltip]="companyName">
        {{ name }}
      </span>
    </div>
    <app-tag
      [useSmallTag]="true"
      [isVariation]="true"
      [variation]="variation"
      [bgColor]="variationBgColor"
      [color]="variationTextColor"
      [auctionInfos]="auctionInfos"
      [situation]="situation"
    ></app-tag>
  </div>
</div>
