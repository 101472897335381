<div class="mt-5 alert d-flex" role="alert" *ngIf="message">
  <ng-container
    *ngIf="message !== 'inactivity' && logoutReason !== 'BLOCKED_PASSWORD'"
  >
    <img
      *ngIf="
        logoutReason === 'ACCESS_DENIED' || logoutReason === 'ALIVE FALSE';
        else iconTemplate
      "
      src="./assets/svg/login-blue-lock.svg"
      alt="Blue lock"
      width="30px"
      class="mr-4"
    />
    <ng-template #iconTemplate>
      <i class="icons pr-3 text-feedback-error">error</i>
    </ng-template>
    {{ message }}
  </ng-container>

  <ng-container *ngIf="logoutReason === 'BLOCKED_PASSWORD'">
    <app-password-blocked></app-password-blocked>
  </ng-container>

  <ng-container *ngIf="message === 'inactivity'">
    <img
      src="./assets/svg/login-blue-lock.svg"
      alt="Blue lock"
      width="30px"
      class="mr-4"
    />
    <div class="d-flex flex-column">
      <span class="w-100">Sessão expirada por inatividade.</span>
      <span class="w-100">Para sua segurança faça o login novamente.</span>
    </div>
  </ng-container>
</div>
