import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '@shared/services/rest';
import { Observable, Subject, catchError, map, of } from 'rxjs';
import {
  IModalTermsParams,
  ITermsStatus,
  TERMS_STATUS,
} from './interface/terms.interface';
import { RocketModalService } from '@shared/rocket-components/components';
import { ModalAcceptTermsComponent } from '@shared/modals/modal-accept-terms/modal-accept-terms.component';
import { Dictionary } from '@core/models';
import { system } from '@core/system/system.service';
import { isSimulatorOrLeague } from '@shared/constants/simulator-league.constant';
import { BROKER_TERMS_STATUS, IBroker } from '@core/interface';
import { SuperSearchService } from '@modules/home/super-search.service';
import { PartnersService } from '@shared/services/core/partners-services/partners.service';

@Injectable({
  providedIn: 'root',
})
export class TermsService extends RestService {
  protected override _url = 'api/terms/v2';
  private termsDict = new Dictionary<ITermInfos[]>();

  private _termsAccepted = new Subject<boolean>();

  constructor(
    private _httpClient: HttpClient,
    private _modalService: RocketModalService,
    private _superSearchService: SuperSearchService,
    private _partnersService: PartnersService
  ) {
    super(_httpClient);
  }

  get onTermsAccepted$(): Observable<boolean> {
    return this._termsAccepted.asObservable();
  }

  public verifyTermStatus(
    idTerm: number
  ): Observable<'AWAIT_FOR_LIBERATION' | 'ACCEPTED_ALL_TERMS'> {
    return this.get(`approve-status/${idTerm}`).pipe(
      catchError((err) => of(err.data)),
      map((response: ITermsStatus) => {
        if (!response.success && !response?.code) return 'ACCEPTED_ALL_TERMS';
        if (this._haveUnacceptedTerms(response.code)) {
          this._superSearchService.isScreenLocked = true;
          this.openTermsModal({
            isBrokerTerm: false,
            brokerName: '',
            code: response.code,
            content: response.message.content,
            idBrokerTerm: 0,
          });
          return 'AWAIT_FOR_LIBERATION';
        }
        return 'ACCEPTED_ALL_TERMS';
      })
    );
  }

  private _haveUnacceptedTerms(code: TERMS_STATUS): boolean {
    return (
      code === TERMS_STATUS.SEARCH_TERM_APPROVE_STATUS_HAST_APPROVED_ERROR ||
      code === TERMS_STATUS.SEARCH_TERM_APPROVE_STATUS_NOT_MOST_RECENT_ERROR
    );
  }

  public openTermsModal(terms: IModalTermsParams): Observable<any> | undefined {
    if (window.location.pathname !== '/' && !terms.isBrokerTerm) return;
    this.disableTerminal(true);
    const ref = this._modalService.open(ModalAcceptTermsComponent, {
      size: 'lg',
      centered: true,
      backdrop: false,
      keyboard: false,
      hideCloseButton: true,
      scrollable: false,
      data: terms,
    });

    if (terms.isBrokerTerm) return ref.afterDismissed;
    ref.afterDismissed.subscribe(() => {
      this._superSearchService.isScreenLocked = false;
      this._termsAccepted.next(true);
      this.disableTerminal(false);
      if (!terms.isBrokerTerm) this._shouldOpenSomeModalAfterSignTerm();
    });
    return;
  }

  public approveTerm(
    idTerm: number,
    token: string,
    isOnboarding: boolean,
    phoneNumber: string = '',
    ip: string = ''
  ): Observable<{ success: boolean; result: boolean; message?: string }> {
    return this.post('approve', {
      idTerm,
      token,
      isOnboarding,
      phoneNumber,
      ip,
    }).pipe(
      catchError((err) => of(this.handlerServiceError(err))),
      map((response) => {
        if (!response?.data?.success && !response?.success) throw response;
        return response.data;
      })
    );
  }

  public termsInfos(id_term: number): Observable<ITermInfos[]> {
    const key = `TERM_${id_term}`;
    if (this.termsDict.has(key))
      return of<ITermInfos[]>(this.termsDict.get(key)!);
    return this.get(`info/${id_term}`).pipe(
      catchError((err) => this.handlerServiceError(err.data)),
      map((result: any) => {
        if (!result.data.success || !result.data.result) throw <any>result;
        this.termsDict.set(key, result.data.result);
        return result.data.result;
      })
    );
  }

  public approveStatus(idTerm: number): Observable<ITermsStatus> {
    return this.get(`approve-status/${idTerm}`).pipe(
      catchError((err) => of(err.data)),
      map((response: ITermsStatus) => {
        return response;
      })
    );
  }

  public hasBrokerTermToAccept(idBroker: number): IBroker | null {
    if (isSimulatorOrLeague(idBroker)) return null;
    const broker = system.brokers.find(
      (broker) => broker.id_broker === idBroker
    );
    if (!broker) {
      console.warn('ERROR_ON_FIND_BROKER_BY_ID_BROKER');
      return null;
    }
    if (!broker.term) {
      console.warn('ERROR_ON_FIND_BROKER_TERMS');
      return null;
    }
    if (broker.term.status && this._needAcceptSomeTerm(broker.term.status))
      return broker;
    return null;
  }

  private _needAcceptSomeTerm(status: BROKER_TERMS_STATUS): boolean {
    return (
      status === BROKER_TERMS_STATUS.HASNT_APPROVED ||
      status === BROKER_TERMS_STATUS.NOT_MOST_RECENT
    );
  }

  public updateBrokerTermStatus(idBroker: number): void {
    const brokers = system.brokers.map((broker) => {
      if (broker.id_broker === idBroker)
        broker.term.status = BROKER_TERMS_STATUS.OK;
      return broker;
    });
    system.brokers = brokers;
  }

  public disableTerminal(disable: boolean): void {
    this._superSearchService.isScreenLocked = disable;
  }

  private _shouldOpenSomeModalAfterSignTerm(): void {
    if (!this._partnersService.openConnectBrokersAfterLoadSystem) return;
    this._partnersService.openConnectionModal();
    this._partnersService.openConnectBrokersAfterLoadSystem = false;
  }
}

export interface ITermInfos {
  idTerm: number;
  nmTerm: string;
  idVersion: number;
  content: string;
  contentType: 'LINK';
  expirationDate: string;
}
