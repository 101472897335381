import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeatmapChartComponent } from './heatmap-chart.component';
import { HeatmapService } from './services/heatmap.service';
import { HighchartsService } from './services/highcharts.service';
import { ListsService } from './services/lists.service';
import { SectorsService } from './services/sectors.service';

@NgModule({
  imports: [CommonModule],
  declarations: [HeatmapChartComponent],
  exports: [HeatmapChartComponent],
  providers: [HeatmapService, HighchartsService, ListsService, SectorsService],
})
export class HeatmapChartModule {}
