import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password-blocked',
  template: `
    <div class="d-inline-flex align-items-center justify-content-start">
      <i class="icons pr-3 text-feedback-error">error</i>
      <span>
        Sua senha foi bloqueada após muitas tentativas. Por favor,
        <button
          class="text-decoration-underline fw-bold bg-transparent border-none text-white p-0"
          (click)="navToChangePassword()"
        >
          crie outra senha :)
        </button>
      </span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordBlockedComponent {
  constructor(private router: Router) {}

  navToChangePassword = () => {
    this.router.navigateByUrl('auth/reset/password');
  };
}
