import { IStockTableIndex } from '@shared/components/stock-table/interfaces/movers-index.interface';
import { STOCK_TABLE_VIEW } from '../enum/stock-table-views.enum';
import { IStockTableView } from '../interfaces/stock-table-views.interface';

export const STOCK_TABLE_VIEWS: IStockTableView[] = [
  {
    name: 'Tabela',
    icon: 'view_list',
    enum: STOCK_TABLE_VIEW.GRID,
    custom_icon: false,
    width: 105,
  },
  {
    name: 'Mini Card',
    icon: 'view_module',
    enum: STOCK_TABLE_VIEW.MINICARD,
    custom_icon: false,
    width: 122,
  },
  {
    name: 'Gráfico',
    icon: 'CARD_VIEW',
    enum: STOCK_TABLE_VIEW.CARD,
    custom_icon: true,
    width: 104,
  },
  {
    name: 'Logo empresa',
    icon: 'ssid_chart',
    enum: STOCK_TABLE_VIEW.CHART,
    custom_icon: false,
    width: 148,
  },
  {
    name: 'Trader',
    icon: 'HORIZONTAL_CANDLE',
    enum: STOCK_TABLE_VIEW.CANDLE,
    custom_icon: true,
    width: 161,
  },
  {
    name: 'Heatmap',
    icon: 'view_quilt',
    enum: STOCK_TABLE_VIEW.HEATMAP,
    custom_icon: false,
    width: 120,
  },
];

export const buildStockToRemoveFromList = (
  stock: any,
  componentRef: string
) => {
  return Object.assign(stock, {
    refComponent: componentRef,
    rowData: stock,
    rowIndex: 1,
  });
};

export const CHART_THEME = {
  chart: {
    backgroundColor: 'transparent',
    style: { fontFamily: 'Metropolis' },
  },
  title: {
    style: {
      color: 'var(--vm-neutral-smoother)',
      font: 'bold 16px "Metropolis"',
    },
  },
  subtitle: {
    style: {
      color: 'var(--vm-neutral-smooth)',
      font: 'bold 12px "Metropolis"',
    },
  },
  legend: {
    itemStyle: {
      font: '10px "Metropolis"',
      color: 'var(--vm-neutral-smooth)',
    },
    itemHoverStyle: {
      color: 'gray',
    },
  },
  labels: {
    style: {
      font: '12px "Metropolis"',
      color: 'var(--vm-neutral-smooth)',
    },
  },
  xAxis: {
    title: {
      style: {
        font: '12px "Metropolis"',
        color: 'var(--vm-neutral-smooth)',
      },
    },
    labels: {
      style: {
        font: '12px "Metropolis"',
        color: 'var(--vm-neutral-smooth)',
      },
    },
  },
  yAxis: {
    title: {
      style: {
        font: '12px "Metropolis"',
        color: 'var(--vm-neutral-smooth)',
      },
    },
    labels: {
      style: {
        font: '12px "Metropolis"',
        color: 'var(--vm-neutral-smooth)',
      },
    },
    stackLabels: {
      style: {
        fontWeight: 'bold',
        font: '12px "Metropolis"',
        color: 'var(--vm-neutral-smooth)',
        textOutline: 'none',
      },
    },
  },
};

export const findContentSizes = (
  type: STOCK_TABLE_VIEW,
  displayRemoveStockButton: boolean = false
): { width: string; height: string } => {
  if (type === STOCK_TABLE_VIEW.MINICARD) {
    const height = displayRemoveStockButton ? 41 + 15 : 41;
    return { width: '72px', height: `${height}px` };
  }

  if (type === STOCK_TABLE_VIEW.CHART) {
    const height = displayRemoveStockButton ? 60 + 15 : 60;
    return { width: '240px', height: `${height}px` };
  }
  if (type === STOCK_TABLE_VIEW.CANDLE) {
    const height = displayRemoveStockButton ? 76 + 15 : 76;
    return { width: '240px', height: `${height}px` };
  }
  const height = displayRemoveStockButton ? 78 + 15 : 78;
  return { width: '135px', height: `${height}px` };
};

export const STOCK_TABLE_INDEXES: IStockTableIndex[] = [
  { value: 'GERAL' },
  { value: 'IBOV' },
  { value: 'IFIX' },
  { value: 'AGFS' },
  { value: 'BDRX' },
  { value: 'GPTW' },
  { value: 'IBRA' },
  { value: 'IBXL' },
  { value: 'IBXX' },
  { value: 'ICBIO' },
  { value: 'ICO2' },
  { value: 'ICON' },
  { value: 'IDIV' },
  { value: 'IEEX' },
  { value: 'IFBOI' },
  { value: 'IFIL' },
  { value: 'IFMILHO' },
  { value: 'IFNC' },
  { value: 'IGCT' },
  { value: 'IGCX' },
  { value: 'IGNM' },
  { value: 'IMAT' },
  { value: 'IMOB' },
  { value: 'INDX' },
  { value: 'ISEE' },
  { value: 'ITAG' },
  { value: 'MLCX' },
  { value: 'SMLL' },
  { value: 'UTIL' },
  { value: 'IVBX' },
];
