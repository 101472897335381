<div *ngIf="_component() && _component()!!.id !== undefined">
  <app-options-header
    #header
    [component]="_component()!!"
    [refComponent]="refComponent"
    [linked]="link"
    [selectedTab]="selectedTab"
    [disableChangeStock]="disableChangeStock"
    (tabChange)="onTabChange($event)"
    (stockChange)="onStockChange($event)"
  ></app-options-header>
  <div class="w-100 bg-neutral-stronger">
    <app-options-subheader
      [selectedTab]="selectedSubheaderTab"
      [stock]="selectedTab.stock"
      [disableChangeStock]="disableChangeStock"
      (tabChange)="onSubheaderTabChange($event)"
    ></app-options-subheader>
  </div>
  <div class="position-relative w-100 h-100 bg-neutral-stronger">
    <ng-container
      *ngIf="
        selectedSubheaderTab === 'Opções' || selectedSubheaderTab === 'Tabela'
      "
    >
      <app-options-list
        [refId]="_component()!!.id"
        [componentInfo]="_component()!!"
        [subheaderTab]="selectedSubheaderTab"
        [stock]="selectedTab.stock"
        [height]="height"
        [width]="width"
        [loading]="loading"
        [expirationDatesHash]="expirationDatesHash"
        [expirationDates]="expirationDates"
        [linked]="link"
        (enableChangeStock)="onLoadContent()"
      ></app-options-list>
    </ng-container>
    <ng-container *ngIf="selectedSubheaderTab === 'Operações'">
      <app-options-operations
        [refId]="_component()!!.id"
        [stock]="selectedTab.stock"
        [height]="height"
        (enableChangeStock)="onLoadContent()"
      ></app-options-operations>
    </ng-container>
    <ng-container *ngIf="selectedSubheaderTab === 'Curvas'">
      <app-smile-volatility
        [refId]="_component()!!.id"
        [stock]="selectedTab.stock"
        [height]="height"
        [expirationDates]="expirationDates"
        (enableChangeStock)="onLoadContent()"
      ></app-smile-volatility>
    </ng-container>
  </div>
</div>
