import { UserService } from '../../user.service';
import { IUserDetails } from '../interface/user-details.interface';

export class UserDetails implements IUserDetails {
  constructor(
    public uuid: string = '',
    public userId: number = 0,
    public externalId: string = '',
    public name: string = '',
    public nickname: string = '',
    public biography: string = '',
    public picture: string = UserService.defaultUserPicturePath,
    public verified: boolean = false,
    public roleName: string = '',
    public canCreateExclusive: boolean = false,
    public amISubscribed: boolean = false,
    public defaulter: boolean = false
  ) {}
}
