import { CommonModule } from '@angular/common';
import { Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ROCKET_MODAL_DATA,
  RocketIModal,
  RocketModalModule,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { BrokerTermComponent } from '@shared/standalone-components/broker-term/broker-term.component';
import { BenefitCardComponent } from '@shared/standalone-components/benefit-card/benefit-card.component';
import { StandaloneModalsService } from '@shared/modals/standalone-modals.service';
import { RocketIconsModule } from '@shared/rocket-icons/rocket-icons.module';
import { alphabeticalSort } from 'src/app/utils/utils.functions';
import { AuthService } from '@shared/services';
import { PartnersService } from '@shared/services/core/partners-services/partners.service';
import {
  IBrokerLogo,
  TrademapCDNService,
} from '@shared/services/api/cdn/trademap-cdn.service';
import { IBrokersToConnect } from '@shared/services/api/trademap/V9/models/brokers-to-connect.interface';
import { take } from 'rxjs';
import { ModalConnectBrokerComponent } from '@modules/broker-connection/modals/modal-connect-broker/modal-connect-broker.component';

interface IFirstAccesModalParams {
  brokers: IBrokersToConnect[];
  isAfterOnboarding: boolean;
  alreadyUsedTrial: boolean;
}

@Component({
  selector: 'app-first-access',
  templateUrl: './first-access.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RocketModalModule,
    BrokerTermComponent,
    BenefitCardComponent,
    RocketIconsModule,
  ],
})
export class FirstAccessComponent
  extends RocketModalRef
  implements OnInit, OnDestroy
{
  public brokers!: IBrokersToConnect[];
  public onlyDisplayPartners: boolean = false;

  private _trademapCDNService = inject(TrademapCDNService);
  private _authService = inject(AuthService);
  private _standaloneModalsService = inject(StandaloneModalsService);
  private _partnersService = inject(PartnersService);

  constructor(
    private _rocketModalService: RocketModalService,
    @Inject(ROCKET_MODAL_DATA)
    private data: IFirstAccesModalParams
  ) {
    super(_rocketModalService);
    this._trademapCDNService.onDownloadedLogos
      .pipe(take(1))
      .subscribe((logos) => this._addBrokerLogo(logos));
  }

  ngOnInit() {
    this.onlyDisplayPartners = this.data.isAfterOnboarding;
    this._buildPartnersToConnect();
  }

  private _downloadBrokersLogo(options: IBrokersToConnect[]): void {
    const logos = options.map((bkr) => bkr.brokerLogo).filter((logo) => logo);
    if (logos?.length) this._trademapCDNService.downloadManyBrokersLogo(logos);
  }

  private _buildPartnersToConnect(): void {
    const brokers = this.data.brokers.filter(
      (brk) => !this._partnersService.hideBroker(brk)
    );
    this._downloadBrokersLogo(brokers);
    this.brokers = alphabeticalSort(brokers, 'nmBrokerNickname');
  }

  private _addBrokerLogo(logos: IBrokerLogo): void {
    this.brokers = this.brokers.map((brk) => {
      if (logos[brk.brokerLogo]) brk.brokerLogoUrl = logos[brk.brokerLogo];
      return brk;
    });
  }

  public handleBenefits(params: { action: string; value: string }): void {
    if (params.action === 'URL') {
      window.open(params.value, '_blank');
      return;
    }
    const broker = this._getBroker(params.value);
    this._openConnectBroker(broker);
  }

  private _getBroker(nmBroker: string) {
    return this.brokers.find(
      (bkr) =>
        bkr.nmBrokerNickname === nmBroker || bkr.nmBrokerNickname === nmBroker
    )!;
  }

  public doLogout(): void {
    this._authService.performLogout({ reason: 'USER_COMMAND' });
  }

  private _openConnectBroker(broker: IBrokersToConnect) {
    this._closeModal();
    const modalSettings: RocketIModal = {
      keyboard: false,
      hideCloseButton: true,
      notClosable: true,
      backdrop: 'static',
    };
    this._standaloneModalsService.openStandaloneModal(
      ModalConnectBrokerComponent,
      {
        idBroker: broker.idBroker,
        customComponentOnClose: true,
        customComponentInfos: {
          component: FirstAccessComponent,
          settings: modalSettings,
        },
      },
      modalSettings,
      '',
      'lg'
    );
  }

  public openTrialModal() {
    this._closeModal();
    if (this._partnersService.partner?.key === 'xp') {
      this._partnersService.openXPModal();
      return;
    }
    this._partnersService.openTradeMapModal();
  }

  private _closeModal(): void {
    this._standaloneModalsService.close();
  }
}
