import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createTradeIdea } from '@shared/components/trade-idea/trade-idea.interface';
import { RestService } from '@shared/services/rest';
import { TIGER_INTERVAL_ENUM } from '@shared/tiger-chart/enum';
import { TTradeIdeaEvents } from '@shared/tiger-chart/types/tiger-chart.types';
import { catchError, map, of } from 'rxjs';
import { ascendingSort } from 'src/app/utils/utils.functions';

@Injectable({
  providedIn: 'root',
})
export class TradeIdeaService extends RestService {
  override _url: string = 'api/trades/ideas';

  constructor(http: HttpClient) {
    super(http);
  }

  getTradeIdeaEvents = (
    stock: string,
    interval = TIGER_INTERVAL_ENUM.ONE_DAY,
    idExchange: number,
    limit: number,
    dsAsset?: string
  ) =>
    this.get<{ result: TTradeIdeaEvents[]; success: boolean }>(
      `${stock}/${idExchange}/${TIGER_INTERVAL_ENUM[interval]}/${limit}${
        dsAsset ? '?ds_asset=' + dsAsset : ''
      }`
    ).pipe(map((res) => this.getOnlyActive(res.data.result)));

  getAllTradeIdeas = (
    page: number,
    isHistoric: boolean,
    perPage: number = 10,
    direction: 'asc' | 'desc' = 'desc'
  ) => {
    return this.get<any>(
      `?page=${page}&perPage=${perPage}&dir=${direction}&is_historic=${isHistoric}`
    ).pipe(map((res) => this.getOnlyActive(res.data.result?.items)));
  };

  getAllTradeIdeasByStock = (
    cd_stock: string,
    isHistoric: boolean,
    ds_asset?: string
  ) => {
    return this.get<any>(
      `stocks/${cd_stock}?is_date=false&is_active=true&is_historic=${isHistoric}${
        ds_asset ? `&ds_asset=${ds_asset}` : ''
      }`
    ).pipe(map((res) => this.getOnlyActive(res.data.result)));
  };

  getAllTradeIdeasByInvestor = (id_investor: number) => {
    return this.get<any>(`investors/${id_investor}`).pipe(
      map((res) => this.getOnlyActive(res.data.result))
    );
  };

  createTradeIdea = (tradeIdea: createTradeIdea) => {
    return this.post<any>('', tradeIdea).pipe(
      catchError((res) => of(res)),
      map((res) => res.data)
    );
  };

  private getOnlyActive(tradeIdeas: TTradeIdeaEvents[]): TTradeIdeaEvents[] {
    if (!tradeIdeas) return [];
    return tradeIdeas
      .filter((tradeIdea) => tradeIdea.cd_status.toUpperCase() === 'ATIVO')
      .sort((a, b) => {
        const dateA = a.dh_insert.split('+')[0];
        const defaultDtA = new Date(dateA).getTime();
        const dateB = b.dh_insert.split('+')[0];
        const defaultDtB = new Date(dateB).getTime();
        return ascendingSort(defaultDtA, defaultDtB);
      });
  }
  
  deleteTradeIdea = (idTradeIdea: number) => {
    return this.delete<any>(`${idTradeIdea}`).pipe(
      catchError((res) => of(res)),
      map((res) => res.data)
    );
  };

  updateTradeIdea = (idTradeIdea: number, tradeIdea: createTradeIdea) => {
    return this.put<any>(`${idTradeIdea}`, tradeIdea).pipe(
      catchError((res) => of(res)),
      map((res) => res.data)
    );
  };
}
