<div class="position-absolute chartControls">
  <div class="d-flex gap-2 justify-content-center">
    <rocket-button
      tooltip="Diminuir zoom"
      tooltipPosition="bottom"
      type="icon"
      [isDisabled]="false"
      label=""
      (rocketClick)="controlClick(controls.ZOOM_OUT)"
    >
      <fla-icon name="remove"></fla-icon>
    </rocket-button>
    <rocket-button
      tooltip="Aumentar zoom"
      tooltipPosition="bottom"
      type="icon"
      [isDisabled]="false"
      label=""
      (rocketClick)="controlClick(controls.ZOOM_IN)"
    >
      <fla-icon name="add"></fla-icon>
    </rocket-button>
    <rocket-button
      tooltip="Restaurar vista do gráfico"
      tooltipPosition="bottom"
      type="icon"
      [isDisabled]="false"
      label=""
      (rocketClick)="controlClick(controls.REFRESH)"
    >
      <fla-icon name="refresh"></fla-icon>
    </rocket-button>
  </div>
</div>
