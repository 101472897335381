import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deepClone } from '@shared/rocket-components/utils/functions';
import { map } from 'rxjs';
import { RestService } from '@shared/services/rest/rest.service';
import { IListStockConfig } from 'src/app/core/interface';

@Injectable({
  providedIn: 'root',
})
export class StockListStettingsService extends RestService {
  override _url: string = 'api/nitro-ws/v1';

  constructor(http: HttpClient) {
    super(http);
  }

  public getByIdConfigList = (idStockList: number) =>
    this.get<IListStockConfig | null>(
      `stock-list-settings/${idStockList}`
    ).pipe(
      map((res) => {
        if (res && res?.data) {
          return this.dataAssign(res.data as IListStockConfig);
        } else {
          return null;
        }
      })
    );

  public createConfig = (listConfig: IListStockConfig) =>
    this.post<IListStockConfig>(
      `stock-list-settings`,
      this.dataAssign(listConfig)
    ).pipe(
      map((res) => {
        if (res.data) {
          return this.dataAssign(res.data);
        } else {
          return false;
        }
      })
    );

  public updateConfig = (listConfig: IListStockConfig) =>
    this.put<IListStockConfig>(
      `stock-list-settings/${listConfig.id}`,
      this.dataAssign(listConfig)
    ).pipe(map((res) => res.data));

  public removeConfig = (id: number) =>
    this.delete<IListStockConfig>(`stock-list-settings/${id}`).pipe(
      map((res) => res.data)
    );

  private dataAssign(listConfig: IListStockConfig) {
    let data: IListStockConfig = deepClone(listConfig);
    if (typeof listConfig.metadata === 'object') {
      data.metadata = JSON.stringify(listConfig.metadata);
    } else {
      data.metadata = JSON.parse(listConfig.metadata);
    }

    return data;
  }
}
