import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WhatsappService {
  openWhatsAppRocket() {
    window.open(
      'https://wa.me/5511996794687?text=Ol%C3%A1%2C%20estou%20precisando%20de%20ajuda%20no%20RocketTrader.',
      '_blank'
    );
  }
}
