import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { newBigValueFormatter } from 'src/app/utils/utils.functions';
import * as Highcharts from 'highcharts';
import Treemap from 'highcharts/modules/treemap';
import { THeatmapConfig } from '../types';
import { formatNumber } from '@angular/common';
import { ListsService } from './lists.service';
import { PERIOD_KEY_MAPPING } from '../enum';
import { CHART_COLORS } from '@shared/tiger-chart/colors';

Treemap(Highcharts);

@Injectable({
  providedIn: 'root',
})
export class HighchartsService {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private _listServive: ListsService
  ) {}

  initGraph(
    refComponent: string,
    getConfig: () => THeatmapConfig,
    callback?: Highcharts.ChartCallbackFunction,
    useVolume: boolean = false
  ) {
    const formatterFunction = this._listServive.getStockValueForList;
    const locale = this.locale;
    const graph = Highcharts.chart(
      refComponent,
      {
        loading: {
          labelStyle: {
            opacity: 0,
          },
          style: {
            backgroundColor: CHART_COLORS.NEUTRAL_STRONG,
          },
        },
        credits: {
          enabled: false,
          style: {
            display: 'none',
            margin: 0,
            padding: '0',
          },
        },
        tooltip: {
          animation: false,
          useHTML: true,
          className: 'heatmap-box-tooltip',
          backgroundColor: CHART_COLORS.NEUTRAL_STRONGEST,
          borderColor: CHART_COLORS.NEUTRAL_STRONGEST,
          borderRadius: 4,
          borderWidth: 1,
          padding: 0,
          hideDelay: 0,
          outside: true,
          style: {
            zIndex: 15000001,
            color: CHART_COLORS.NEUTRAL_SMOOTHEST,
            background: CHART_COLORS.NEUTRAL_STRONGEST,
            opacity: 1,
            padding: '0',
          },
          formatter() {
            const point: any = this.point;
            if (
              !point?.custom ||
              (!point?.parent && Array.isArray(point?.custom))
            )
              return false;
            const { source, orderBy, period, isNotListPersonal } = getConfig();
            const value =
              source || useVolume
                ? point.custom.volume
                : formatterFunction(point?.custom, !isNotListPersonal, orderBy);
            let percentage = 0;
            if (period && point) {
              percentage = +point.custom[PERIOD_KEY_MAPPING[period]];
              if (!percentage && source) percentage = point.custom.variacao_dia;
              else if (!percentage) percentage = 0;
            } else percentage = point.custom.variacao_dia;

            return `
              <div>
                <p>${point.custom.cd_stock}</p>
                <p>${
                  orderBy === 'variation' && !useVolume
                    ? `${formatNumber(
                        point.custom.variacao_dia,
                        locale,
                        `1.2-2`
                      )}%`
                    : newBigValueFormatter(value)
                }
                </p>
                ${
                  orderBy === 'variation' && !useVolume
                    ? ''
                    : `<p>${formatNumber(percentage, locale, `1.2-2`)}%</p>`
                }
              <div>`;
          },
        },
        series: [
          {
            allowTraversingTree: false,
            breadcrumbs: {
              showFullPath: false,
              useHTML: true,
              style: {
                paddingRight: 10,
                paddingLeft: 10,
              },
              buttonTheme: {
                color: CHART_COLORS.NEUTRAL_STRONGEST,
                fill: CHART_COLORS.BRAND_PRIMARY,
                style: {
                  color: CHART_COLORS.NEUTRAL_STRONGEST,
                },
              },
              position: {
                y: 12,
              },
              floating: true,
            },
            states: {
              hover: {
                enabled: false,
              },
            },
            cursor: 'pointer',
            animation: false,
            borderColor: CHART_COLORS.NEUTRAL_STRONGEST,
            colorAxis: 0,
            type: 'treemap',
            layoutStartingDirection: 'horizontal',
            clip: true,
            layoutAlgorithm: 'squarified',
            data: [],
          },
        ],
        chart: {
          backgroundColor: CHART_COLORS.OPACITY_BACKGROUND,
          animation: false,
          marginBottom: 10,
          zooming: {
            type: 'xy',
            pinchType: 'x',
            resetButton: {
              theme: {
                fill: CHART_COLORS.BRAND_PRIMARY,
                stroke: CHART_COLORS.BRAND_PRIMARY,
                style: {
                  color: CHART_COLORS.BLACK,
                },
                r: 5,
                states: {
                  hover: {
                    fill: CHART_COLORS.BRAND_PRIMARY,
                    zIndex: 9999999,
                    style: {
                      color: CHART_COLORS.BLACK,
                    },
                  },
                },
              },
            },
          },
        },
        title: {
          text: '',
        },
      },
      callback
    );
    graph.showLoading();
    return graph;
  }
}
