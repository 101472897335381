import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';
import { OptionsComponents } from '../component-list';

export const STOCK_CHART_COMPONENT: any = (options?: OptionsComponents) => {
  return {
    name: 'Gráfico',
    id: COMPONENTS_NAMES_ENUM.GRAFICO,
    icon: 'candlestick_chart',
    cod: 'GRAFICO',
    data: options?.data ?? null,
    close: true,
    fullscreen: true,
    link: true,
    hasFooter: true,
    widthDesk: 470,
    heightDesk: 470,
    unitOfMeasurement: '%',
    height: options?.height ?? 70,
    width: options?.width ?? 40,
    left: options?.left ?? 'calc(15% + 70px)',
    top: options?.top ?? '0px',
    //minHeight: '520px',
    minWidth: '535px',
    iconSvg: '',
  };
};
