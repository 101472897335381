import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { delay, filter } from 'rxjs';
import { IThemeProvider } from 'scichart';
import { DefaultSciChartLoader } from 'scichart/Charting/Visuals/loader';

export class CustomChartLoader extends DefaultSciChartLoader {
  private loaderElement = new Map<string, HTMLElement>();
  constructor(
    private stockChartService: StockChartService,
    private idElement: string
  ) {
    super();
  }

  override removeChartLoader(domChartRoot: HTMLDivElement): void {
    const key = this.idElement;
    const previous = this.loaderElement.get(key)!;
    if (domChartRoot.contains(previous)) {
      domChartRoot.removeChild(previous);
      this.loaderElement.delete(key);
    }
  }

  override addChartLoader(
    domChartRoot: HTMLDivElement,
    theme: IThemeProvider
  ): HTMLElement {
    const key = this.idElement;
    this.addChartLoadingSub(domChartRoot, theme);
    this.removeChartLoadingSub(domChartRoot);
    this.loaderElement.set(key, super.addChartLoader(domChartRoot, theme));
    return this.loaderElement.get(key)!;
  }

  private removeChartLoadingSub(domChartRoot: HTMLDivElement) {
    this.stockChartService.removeChartLoading$
      .pipe(
        delay(30),
        filter((sub) => this.idElement === sub.ref)
      )
      .subscribe(() => {
        this.removeChartLoader(domChartRoot);
      });
  }

  private addChartLoadingSub(
    domChartRoot: HTMLDivElement,
    theme: IThemeProvider
  ) {
    const key = this.idElement;
    this.stockChartService.addChartLoading$
      .pipe(
        delay(5),
        filter(
          (sub) => this.idElement === sub.ref && !this.loaderElement.has(key)
        )
      )
      .subscribe(() => {
        this.loaderElement.set(key, super.addChartLoader(domChartRoot, theme));
      });
  }
}
