import { NgModule } from '@angular/core';
import { ConfigColorpickerComponent } from './config-colorpicker.component';
import { RTColorPickerModule } from '@shared/components/color-picker/color-picker.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';

@NgModule({
  declarations: [ConfigColorpickerComponent],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    RTDropdownModule,
    RTColorPickerModule,
  ],
  exports: [ConfigColorpickerComponent],
  providers: [],
})
export class ConfigColorpickerModule {}
