import { StockService } from '@services/api/trademap/v1/stock.service';
import { Subject, takeUntil } from 'rxjs';
import { StockListItemsWithView } from 'src/app/core/interface';
import { IStockListItemsRow } from '../interfaces/stock-table.interfaces';
export class SearchList {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private stockService: StockService) {}

  private onDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public getStockListItems(
    idStockList: number,
    refComp: string
  ): Promise<{ list: IStockListItemsRow[]; stocksMapped?: any }> {
    return new Promise((resolve) => {
      this.stockService
        .getStockListItemsWithView(null, idStockList, refComp)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: StockListItemsWithView) => {
          this.onDestroy();
          resolve({
            list: result.stockListItemsRow,
            stocksMapped: result.stocksMapped,
          });
        });
    });
  }
}
