<div
  class="auction-tooltip-container p-1 round d-inline-flex align-items-center bg-neutral-stronger"
  style="white-space: nowrap"
  [ngStyle]="{
    left: position.left,
    top: position.top
  }"
>
  <fla-icon
    name="gavel"
    size="icon-size-tiny"
    css="text-feedback-warning"
  ></fla-icon>
  <ng-container *ngIf="stockData">
    <div class="p-2 fs-6 text-feedback-warning hstack align-items-center">
      <span class="pr-1">{{ stockData?.variacao_leilao }}</span> |
      <span class="pl-1">{{ stockData?.preco_leilao }}</span>
      <span
        class="pl-2 pr-1"
        [innerHTML]="stockData?.qtde_leilao_nao_atendida"
      ></span>
      /
      <span class="pl-1">{{ stockData?.qtde_leilao }}</span>
      <span class="pl-1">{{ stockData?.variacao_dia }}</span>
      <fla-icon
        name="timer"
        size="icon-size-micro"
        css="text-feedback-warning pl-2 pr-1"
      ></fla-icon>
      {{ timer }}
    </div>
  </ng-container>
</div>
