<div
  class="w-100 news-footer d-flex justify-content-between"
  *ngIf="news"
  (click)="ignoreClick($event)"
>
  <div class="tag-wrapper mb-n1">
    <rocket-button
      *ngIf="showScrollButtons"
      type="icon"
      css="min-w-0 pr-0 "
      [isDisabled]="tagOffsetLeft == 0"
      (rocketClick)="decrementTagOffset()"
    >
      <fla-icon
        name="arrow_back_ios"
        size="icon-size-micro"
        css="p-0 pl-1 arrow"
      ></fla-icon>
    </rocket-button>
    <span
      *ngFor="let tag of tagsOnScroll"
      class="tag mr-2 mb-1 py-0 px-3"
      [ngClass]="{ 'text-brand-primary': selectedTag == tag.id_agency_tag }"
      (click)="selectTag(tag)"
    >
      {{ tag.ds_agency_tag }}
    </span>
    <rocket-button
      *ngIf="showScrollButtons"
      type="icon"
      css="min-w-0 pr-0 "
      [isDisabled]="tagOffsetRight == news.tags?.length"
      (rocketClick)="incrementTagOffset()"
    >
      <fla-icon
        name="arrow_forward_ios"
        size="icon-size-micro"
        css="p-0 pl-1 arrow"
      ></fla-icon>
    </rocket-button>
  </div>
</div>
