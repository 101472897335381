import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import {
  TData,
  TTooltipIndicatorValue,
  TUpdateData,
} from '@shared/tiger-chart/types/tiger-chart.types';
import {
  BaseIndicator,
  MedianPriceData,
  TigerChartIndicatorWeisWaveOptions,
  TTigerChartIndicatorCreateOptions,
  TTigerChartIndicatorParameter,
  TTigerChartIndicatorRenderSeriesConfig,
} from '../indicators.types';
import {
  TIGER_INDICATORS_ENUM,
  TIGER_INDICATOR_PARAMETER_TYPE,
} from '@shared/tiger-chart/enum/tiger-chart.enum';
import { Subject } from 'rxjs';
import { TalibService } from '@shared/tiger-chart/services/talib.service';
import { XyDataSeries } from 'scichart/Charting/Model/XyDataSeries';
import { TIGER_INDICATOR_PRECISIONS } from '../indicators.constants';
import { setDefaultConfiguration } from '../indicators.functions';
import {
  DefaultPaletteProvider,
  EFillPaletteMode,
  EStrokePaletteMode,
  FastColumnRenderableSeries,
  parseColorToUIntArgb,
} from 'scichart';

export class WeisWavePaletteProvider extends DefaultPaletteProvider {
  public override readonly strokePaletteMode: EStrokePaletteMode =
    EStrokePaletteMode.SOLID;
  public override readonly fillPaletteMode: EFillPaletteMode =
    EFillPaletteMode.SOLID;
  private directions: any[];
  private volumeUp: number;
  private volumeDown: number;

  constructor(
    data: any[],
    useOpacity: boolean,
    volumeUp: string = '#5999FB',
    volumeDown: string = '#FBBB00'
  ) {
    super();
    this.directions = data;
    volumeUp = useOpacity ? volumeUp + '80' : volumeUp;
    volumeDown = useOpacity ? volumeDown + '80' : volumeDown;
    this.volumeUp = parseColorToUIntArgb(volumeUp);
    this.volumeDown = parseColorToUIntArgb(volumeDown);
  }

  override overrideFillArgb(
    xValue: number,
    yValue: number,
    index: number
  ): number {
    if (!this.directions[index]) {
      this.volumeDown;
    }
    return this.directions[index]?.directionIsUp
      ? this.volumeUp
      : this.volumeDown;
  }

  override overrideStrokeArgb(
    xValue: number,
    yValue: number,
    index: number
  ): number {
    return this.overrideFillArgb(xValue, yValue, index);
  }

  updateDirections = (directions: any[]) => {
    this.directions = directions;
  };
}

export const source = [
  { label: 'Fechar', value: 'CLOSE' },
  { label: 'Abe / Fch', value: 'ABE_FCH' },
  { label: 'Máx / Min', value: 'MAX_MIN' },
];

export class WeisWaveBase implements BaseIndicator {
  protected columnDataSeries!: XyDataSeries;
  protected columnRenderableSeries!: FastColumnRenderableSeries;
  protected options!: TigerChartIndicatorWeisWaveOptions;
  protected data: TData;
  protected precision: number = 2;
  protected points: MedianPriceData;
  protected volumeColorUp = CHART_COLORS.FEEDBACK_POSITIVE;
  protected volumeColorDown = CHART_COLORS.FEEDBACK_NEGATIVE;
  protected lineThickness = 1;
  protected timePeriod = 14;
  protected priceSource = source[0].value;
  private baseChart!: TWebAssemblyChart;
  directions: { directionIsUp: boolean; directionIsDown: boolean }[] = [];
  lineNumber!: string;
  type: TIGER_INDICATORS_ENUM = TIGER_INDICATORS_ENUM.WEIS_WAVE;
  yAxisId!: string;
  settings: TTigerChartIndicatorParameter[] = [];
  styles: TTigerChartIndicatorParameter[] = [];
  renderSeriesConfig: TTigerChartIndicatorRenderSeriesConfig[] = [];
  onChange = new Subject<null>();
  service: TalibService;
  mainLineId = '';
  isNewOnChart = false;

  get isVisible(): boolean {
    return this.columnRenderableSeries.isVisible;
  }

  set isVisible(visible: boolean) {
    this.columnRenderableSeries.isVisible = visible;
  }

  get propertiesText(): string {
    const selectedSource = source.find((s) => s.value == this.priceSource);
    const sourceText = `, ${selectedSource?.label}`;
    return `${this.timePeriod}${sourceText}`;
  }

  constructor(options: TigerChartIndicatorWeisWaveOptions) {
    this.data = options.data;
    this.service = options.service;
    this.precision = options.tickSize;
    this.options = options;
    if (options.precision) this.precision = options.precision;
    if (options.timePeriod) this.timePeriod = options.timePeriod;
    if (options.volumeColorUp) this.volumeColorUp = options.volumeColorUp;
    if (options.volumeColorDown) this.volumeColorDown = options.volumeColorDown;
    if (options.price_source) this.priceSource = options.price_source;
    this.mainLineId = `${this.type}-column-volume`;

    this.points = {
      output: [],
    };
    this.renderSeriesConfig = [
      {
        label: 'Volume Up',
        id: this.mainLineId,
        color: this.volumeColorUp,
        propertyColor: 'volumeColorUp',
        thickness: this.lineThickness,
        propertyThickness: 'lineThickness',
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        dontShowTickness: true,
      },
      {
        label: 'Volume Down',
        id: this.mainLineId,
        color: this.volumeColorDown,
        propertyColor: 'volumeColorDown',
        thickness: this.lineThickness,
        propertyThickness: 'lineThickness',
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        dontShowTickness: true,
      },
    ];
    this.settings = [
      {
        label: 'Valor',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property: 'timePeriod' as keyof WeisWaveBase,
      },
      {
        label: 'Fonte',
        type: TIGER_INDICATOR_PARAMETER_TYPE.SELECTION,
        values: source,
        property: 'priceSource' as keyof WeisWaveBase,
      },
    ];
    this.styles = [
      {
        type: TIGER_INDICATOR_PARAMETER_TYPE.SELECTION,
        property: 'precision',
        label: 'Precisão',
        values: TIGER_INDICATOR_PRECISIONS,
      },
    ];
    this.styles[0].values![0].value = this.precision;
    this.lineNumber = options.lineNumber;
  }

  create(options: TTigerChartIndicatorCreateOptions): BaseRenderableSeries[] {
    this.baseChart = options.base;

    const xValues = this.data.id_point;
    this.columnDataSeries = new XyDataSeries(this.baseChart.wasmContext);
    this.columnDataSeries.appendRange(xValues, [
      ...this.points.output.slice(0),
    ]);
    const configSerie = this.renderSeriesConfig[0];
    this.columnRenderableSeries = new FastColumnRenderableSeries(
      this.baseChart.wasmContext,
      {
        dataSeries: this.columnDataSeries,
        id: `${configSerie.id}_${this.lineNumber}`,
        xAxisId: options.xAxisId,
      }
    );
    this.setColumnPalette();
    return [this.columnRenderableSeries];
  }

  private setColumnPalette() {
    this.columnRenderableSeries.paletteProvider = new WeisWavePaletteProvider(
      this.directions,
      true,
      this.volumeColorUp,
      this.volumeColorDown
    );
  }

  updatePoints(): void {
    //do nothing;
  }

  append(xValue: number, data: TUpdateData, fullData: TData) {
    this.data = fullData;

    this.updatePoints();

    this.columnDataSeries.append(
      xValue,
      this.points['output'][this.points['output'].length - 1]
    );
  }

  insertRange(xValues: number[], fullData: TData): void {
    this.data = fullData;
    this.updatePoints();
    this.setColumnPalette();
    const s = this.baseChart.sciChartSurface.suspendUpdates();
    const arrayLength = xValues.length;
    try {
      this.columnDataSeries.insertRange(0, xValues, [
        ...this.points.output.slice(0, arrayLength),
      ]);
      for (let index = 0; index < this.points.output.length; index++) {
        this.columnDataSeries.update(index, this.points.output[index]);
      }
    } finally {
      s.resume();
    }
  }

  changeVisibility(): void {
    this.columnRenderableSeries.isVisible =
      !this.columnRenderableSeries.isVisible;
  }

  update(index: number, data: TUpdateData, fullData: TData) {
    this.data = fullData;
    this.updatePoints();

    this.columnDataSeries.update(
      index,
      this.points['output'][this.points['output'].length - 1]
    );
  }

  updateSettings(): void {
    const s = this.baseChart.sciChartSurface.suspendUpdates();
    const dataSeriesCount = this.columnDataSeries.count();
    this.columnRenderableSeries.paletteProvider = new WeisWavePaletteProvider(
      this.directions,
      false,
      this.volumeColorUp,
      this.volumeColorDown
    );
    const lineValues = [
      ...this.points.output.slice(0, this.data.id_point.length),
    ];

    if (dataSeriesCount > lineValues.length) {
      this.columnDataSeries.removeRange(
        lineValues.length - 1,
        dataSeriesCount - lineValues.length
      );
    }

    try {
      lineValues.forEach((linePointValue, index) => {
        this.columnDataSeries.update(index, lineValues[index]);
      });
    } finally {
      s.resume();
    }
    this.onChange.next(null);
  }

  updateStyles(
    baseChart: TWebAssemblyChart,
    config: TTigerChartIndicatorRenderSeriesConfig
  ): void {
    switch (config.id) {
      case this.mainLineId: {
        this.volumeColorUp =
          config.propertyColor == 'volumeColorUp'
            ? config.color
            : this.volumeColorUp;
        this.volumeColorDown =
          config.propertyColor == 'volumeColorDown'
            ? config.color
            : this.volumeColorDown;
        this.columnRenderableSeries.paletteProvider =
          new WeisWavePaletteProvider(
            this.directions,
            true,
            this.volumeColorUp,
            this.volumeColorDown
          );
        const lineRenderSeries =
          baseChart.sciChartSurface.renderableSeries.getById(
            `${config.id}_${this.lineNumber}`
          );
        lineRenderSeries.stroke = config.color;
        lineRenderSeries.strokeThickness = config.thickness;
        lineRenderSeries.isVisible = config.active;
        break;
      }
      default:
        break;
    }
    this.onChange.next(null);
  }

  delete() {
    this.baseChart.sciChartSurface.renderableSeries.remove(
      this.columnRenderableSeries
    );
  }

  setValue(property: keyof this, value: any) {
    const prop = this[property];
    if (typeof this[property] == 'number') {
      this[property] = +value as unknown as typeof prop;
    } else {
      this[property] = value;
    }
    this.updateSettings();
  }

  getValuesByIndex(index?: number): TTooltipIndicatorValue[] {
    const precision = this.precision;
    const lineValues = this.columnDataSeries.getNativeYValues();
    const lineValue = {
      value: lineValues.get(index || lineValues.size() - 1),
      precision,
      color: this.renderSeriesConfig[0].color,
    };
    return [lineValue];
  }

  resetConfiguration(): void {
    setDefaultConfiguration(
      this,
      this.baseChart,
      this.options,
      this.settings,
      this.renderSeriesConfig,
      this.styles,
      this.type
    );
  }

  updatePaletteDirections() {
    if (!this.columnRenderableSeries) return;
    const palette = this.columnRenderableSeries
      .paletteProvider as WeisWavePaletteProvider;
    palette.updateDirections(this.directions);
  }
}
