import { STOCK_TABLE_ELEMENT_IDS } from '@shared/components/stock-table/constants/stock-table.constants';

const STOCK_TABLE_INTRO_STEPS = {
  INITIAL: (component: Element) => ({
    title: 'Conheça a Lista de ativos 🖖',
    intro:
      'Aqui, você consegue criar sua lista de acompanhamento de ativos ou visualizar nossas listas pré-definidas , como maiores altas, maiores baixas e volume. <br /><br /> Use as setas do teclado ou clique no próximo para começar.',
    element: component,
  }),
  CHANGE_VIEW: (component: Element) => ({
    enum: 'CHANGE_VIEW',
    title: 'Tipo de visualização',
    intro:
      'Escolha em qual formato você gostaria de ver a sua lista. Como <b>tabela</b>, <b>minicard</b>, <b>card</b>, <b>gráfico</b>, <b>candle horizontal</b> ou <b>heatmap</b> (mapa de calor).',
    element: component.querySelector(
      `#${STOCK_TABLE_ELEMENT_IDS.STOCK_TABLE_CHANGE_VIEW} + ul`
    ),
    position: 'right',
    preChange: function () {
      this.element = component.querySelector(
        `#${STOCK_TABLE_ELEMENT_IDS.STOCK_TABLE_CHANGE_VIEW} + ul`
      );
      this.position = 'right';
    },
  }),
  TABLE_VIEW: () => ({
    enum: 'CONTEXT_MENU',
    title: 'Visão tabela',
    intro:
      'Na visão tabela, ao clicar com o botão direito, é possível adicionar ou retirar as colunas para uma melhor análise. <br /><br /> Para ocultar uma coluna basta clicar em <b>Ocultar coluna.</b> <br /><br /> Para adicionar ou tirar as colunas de leilão, clique em <b>Habilitar leilão<b/> ou <b>Desabilitar leilão.</b>',
    element: document.querySelector(
      `#${STOCK_TABLE_ELEMENT_IDS.STOCK_TABLE_TABLE_VIEW}`
    ),
    preChange: function () {
      this.element = document.querySelector(
        `#${STOCK_TABLE_ELEMENT_IDS.STOCK_TABLE_TABLE_VIEW}`
      );
    },
  }),
  LIST: (component: Element) => ({
    enum: 'LISTS',
    title: 'Listas',
    intro:
      'Aqui você consegue escolher qual lista gostaria de ver entre as criadas por você (Pessoais) e as pré-definidas. <br /><br /> Ao clicar em duplicar lista, a lista que está selecionada será duplicada com os mesmos ativos que estão nela.',
    element: component.querySelector(
      `#${STOCK_TABLE_ELEMENT_IDS.STOCK_TABLE_LISTS} > div`
    ),
  }),
  END_TOUR: {
    title: 'Agora é com você! 🖖',
    intro:
      'Aproveite a funcionalidade do Listas! Se quiser rever esse tour clique em <b>Ajuda</b> no botão direito ou acesse nossa FAQ clicando no menu <b>Ajuda</b>.',
  },
};

export const STOCK_TABLE_STEPS = (component: Element) => [
  STOCK_TABLE_INTRO_STEPS.INITIAL(component),
  STOCK_TABLE_INTRO_STEPS.CHANGE_VIEW(component),
  STOCK_TABLE_INTRO_STEPS.TABLE_VIEW(),
  STOCK_TABLE_INTRO_STEPS.LIST(component),
  STOCK_TABLE_INTRO_STEPS.END_TOUR,
];
