import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './register.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { CreateComponent } from './create/create.component';
import { ValidateComponent } from './validate/validate.component';
import { ResetPasswordComponent } from './reset/reset-password.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { OnboardGuard } from '@core/guards/onboard.guard';
import { PendingChangesGuard } from '@core/guards/onboard-pending-changes.guard';
import { RegisterPhoneComponent } from './register-phone/register-phone.component';

const routes: Routes = [
  {
    path: '',
    component: RegisterComponent,
    children: [
      {
        path: '',
        redirectTo: 'create',
        pathMatch: 'full',
      },
      {
        path: 'phone',
        component: RegisterPhoneComponent,
      },
      {
        path: 'create',
        component: CreateComponent,
      },
      {
        path: 'validate',
        component: ValidateComponent,
        canActivate: [OnboardGuard],
      },
      {
        path: 'welcome',
        component: WelcomeComponent,
        canActivate: [OnboardGuard],
      },
      {
        path: 'password',
        component: ResetPasswordComponent,
      },
      {
        path: 'recovery',
        component: RecoveryComponent,
        canActivate: [OnboardGuard],
        canDeactivate: [PendingChangesGuard],
      },
    ],
  },
];

export const RegisterRoutes = RouterModule.forChild(routes);
