<!-- <input
  [(colorPicker)]="configs.chart.series!.candle!.colorsVelaUp!"
  [style.background]="configs.chart.series!.candle!.colorsVelaUp"
/>

<input
  [(colorPicker)]="configs.chart.series!.candle!.colorsVelaDown!"
  [style.background]="configs.chart.series!.candle!.colorsVelaDown"
/> -->

<div [formGroup]="form">
  <app-check
    *ngIf="false"
    label="Mostra Contorno"
    name="contorno"
    formControlName="showBorder"
    [isChecked]="form.get('showBorder')?.value"
    (flaChange)="showBorder($event)"
  >
  </app-check>
  <div class="mt-3">
    <div class="text-neutral-medium fs-5 mb-2">Candle</div>
    <div>
      <div class="d-flex align-items-center justify-content-between gap-3 mb-2">
        <div class="mt-3">Corpo</div>
        <div class="d-flex">
          <div class="d-flex flex-column align-items-center mr-1">
            <span class="fs-6">Alta</span>
            <app-dropdown title="">
              <a
                type="button"
                class="nav-link lh-sm round-top h-100"
                appDropdownButton
                placement="bottom-start"
                title=""
              >
                <div class="colorpicker">
                  <div class="cursor-pointer colorpickerWrapper">
                    <div
                      class="color d-flex align-items-center justify-content-center p-1"
                    >
                      <div class="opacitySwatch">
                        <div
                          id="colorsVelaUpColorpicker"
                          class="swatch"
                          style="background-color: rgb(255, 255, 255)"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <div class="contents">
                <li>
                  <app-config-colorpicker
                    (selectedColor)="selectedColor('colorsVelaUp', $event)"
                    [typeColorUpdate]="toolsTypeDraw.TEXT"
                    [useColor]="candleDefaultColors['colorsVelaUp']"
                    #configColorPicker
                  ></app-config-colorpicker>
                </li>
              </div>
            </app-dropdown>
          </div>
          <div class="d-flex flex-column align-items-center mr-1">
            <span class="fs-6">Baixa</span>
            <app-dropdown title="">
              <a
                type="button"
                class="nav-link lh-sm round-top h-100"
                appDropdownButton
                placement="bottom-start"
                title=""
              >
                <div class="colorpicker">
                  <div class="cursor-pointer colorpickerWrapper">
                    <div
                      class="color d-flex align-items-center justify-content-center p-1"
                    >
                      <div class="opacitySwatch">
                        <div
                          id="colorsVelaDownColorpicker"
                          class="swatch"
                          style="background-color: rgb(255, 255, 255)"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <div class="contents">
                <li>
                  <app-config-colorpicker
                    (selectedColor)="selectedColor('colorsVelaDown', $event)"
                    [typeColorUpdate]="toolsTypeDraw.TEXT"
                    [useColor]="candleDefaultColors['colorsVelaDown']"
                    #configColorPicker
                  ></app-config-colorpicker>
                </li>
              </div>
            </app-dropdown>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between gap-3 mb-2">
        <div class="mt-3">Contorno/Pavio</div>
        <div class="d-flex">
          <div class="mr-1">
            <span class="fs-6">Alta</span>
            <app-dropdown title="">
              <a
                type="button"
                class="nav-link lh-sm round-top h-100"
                appDropdownButton
                placement="bottom-start"
                title=""
              >
                <div class="colorpicker">
                  <div class="cursor-pointer colorpickerWrapper">
                    <div
                      class="color d-flex align-items-center justify-content-center p-1"
                    >
                      <div class="opacitySwatch">
                        <div
                          id="colorsBorderUpColorpicker"
                          class="swatch"
                          style="background-color: rgb(255, 255, 255)"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <div class="contents">
                <li>
                  <app-config-colorpicker
                    (selectedColor)="selectedColor('colorsBorderUp', $event)"
                    [typeColorUpdate]="toolsTypeDraw.TEXT"
                    [useColor]="candleDefaultColors['colorsBorderUp']"
                    #configColorPicker
                  ></app-config-colorpicker>
                </li>
              </div>
            </app-dropdown>
          </div>
          <div class="mr-1">
            <span class="fs-6">Baixa</span>
            <app-dropdown title="">
              <a
                type="button"
                class="nav-link lh-sm round-top h-100"
                appDropdownButton
                placement="bottom-start"
                title=""
              >
                <div class="colorpicker">
                  <div class="cursor-pointer colorpickerWrapper">
                    <div
                      class="color d-flex align-items-center justify-content-center p-1"
                    >
                      <div class="opacitySwatch">
                        <div
                          id="colorsBorderDownColorpicker"
                          class="swatch"
                          style="background-color: rgb(255, 255, 255)"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <div class="contents">
                <li>
                  <app-config-colorpicker
                    (selectedColor)="selectedColor('colorsBorderDown', $event)"
                    [typeColorUpdate]="toolsTypeDraw.TEXT"
                    [useColor]="candleDefaultColors['colorsBorderDown']"
                    #configColorPicker
                  ></app-config-colorpicker>
                </li>
              </div>
            </app-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
