import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { BrokerLogoComponent } from '@modules/broker-connection/components/broker-logo/broker-logo.component';

@Component({
  selector: 'app-partner-connection-card',
  templateUrl: './partner-connection-card.component.html',
  styles: [
    `
      .gradient-border {
        cursor: pointer;
        padding: 1px;
        background: linear-gradient(
          to right,
          #6842b9 0%,
          var(--vm-brand-primary) 100%
        );
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, BrokerLogoComponent],
})
export class PartnerConnectionCardComponent {
  @Output() public clicked = new EventEmitter<void>();
  @Input() public logo: string | SafeResourceUrl = '';
  @Input() public title = '';
  @Input() public message = '';
  @Input() public displayBorder = false;
}
