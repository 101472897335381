<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'vertical'">
    <ng-container *ngIf="styleTab !== 'nav-segmented'">
      <ng-template *ngTemplateOutlet="vertical"></ng-template>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-template *ngTemplateOutlet="switchDefault"></ng-template>
  </ng-container>
</ng-container>

<ng-template #vertical>
  <div class="d-flex align-items-left">
    <div
      class="nav flex-column"
      [id]="idTab"
      [ngClass]="[styleTab, customClass]"
      role="tablist"
      aria-orientation="vertical"
    >
      <ng-template [ngTemplateOutlet]="headerTemplate"></ng-template>
    </div>
    <ng-template [ngTemplateOutlet]="bodyTemplate"></ng-template>
  </div>
</ng-template>

<ng-template #switchDefault>
  <ul
    class="nav"
    [id]="idTab"
    [ngClass]="[styleTab, customClass]"
    role="tablist"
  >
    <ng-template [ngTemplateOutlet]="headerTemplate"></ng-template>
  </ul>
  <ng-template [ngTemplateOutlet]="bodyTemplate"></ng-template>
</ng-template>

<ng-template #headerTemplate>
  <ng-container
    *ngIf="styleTab === 'nav-segmented' && tabHeader; else elseTemplate"
  >
    <ng-container *ngTemplateOutlet="tabHeader.get(0)!.content"></ng-container>
    <ng-container *ngTemplateOutlet="tabHeader.get(1)!.content"></ng-container>
  </ng-container>
  <ng-template #elseTemplate>
    <ng-container *ngFor="let header of tabHeader">
      <ng-container *ngTemplateOutlet="header.content"></ng-container>
    </ng-container>
  </ng-template>
</ng-template>

<ng-template #bodyTemplate>
  <div class="tab-content" [id]="idContent" [ngClass]="customClassContent">
    <ng-container
      *ngIf="styleTab === 'nav-segmented' && tabBody; else elseTemplate"
    >
      <ng-container *ngTemplateOutlet="tabBody.get(0)!.content"></ng-container>
      <ng-container *ngTemplateOutlet="tabBody.get(1)!.content"></ng-container>
    </ng-container>
    <ng-template #elseTemplate>
      <ng-container *ngFor="let body of tabBody">
        <ng-container *ngTemplateOutlet="body.content"></ng-container>
      </ng-container>
    </ng-template>
  </div>
</ng-template>
