import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import {
  IAfterGuiAttachedParams,
  IDoesFilterPassParams,
  IFilterParams,
} from 'ag-grid-community';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { nextBusinessDay } from 'src/app/utils/utils.functions';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'generic-filter',
  template: `
    <div
      style="display: inline-block; background-color: rgba(var(--vm-neutral-strong-rgb), var(--vm-bg-opacity)) !important;"
    >
      <div *ngFor="let item of filterOptions; let i = index">
        <label style="margin: 10px; display: flex; align-items: center;">
          <input
            style="margin-right: 8px;"
            type="checkbox"
            name="value"
            [(ngModel)]="item.active"
            (ngModelChange)="updateFilter(item)"
            [value]="item.value"
          />
          {{ item.text }}
        </label>
        <hr
          style="background: var(--vm-neutral-stronger);"
          *ngIf="item.value === 'ALL'"
        />
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericFilterComponent implements IFilterAngularComp, OnDestroy {
  public params: any;
  public filterOptions: any = [];
  private tomorrow!: number;
  private today!: number;
  private yesterday!: number;
  private refreshComponent$!: Subscription;
  private _refreshComponent$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {
    const date = new Date();
    this.today = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    ).getTime();
    this.yesterday = nextBusinessDay(this.today, -1);
    this.tomorrow = nextBusinessDay(this.today, 1);
  }

  ngOnDestroy() {
    this.refreshComponent$ && this.refreshComponent$.unsubscribe();
  }

  agInit(params: IFilterParams): void {
    this.refreshComponent$ = this._refreshComponent$
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.refreshComponent();
        this.cdr.detectChanges();
      });
    this.params = params;
    this.refreshComponent();
    this.cdr.detectChanges();
  }

  refreshComponent() {
    let colId = this.params.column.colId;
    if (this.params.filterDefinitions.automaticFilter) {
      colId = this.params.filterDefinitions.replaceField
        ? this.params.filterDefinitions.replaceField
        : colId;
      this.params.column.colId = colId;

      const stockValues = this.params.api.rowModel.rowsToDisplay
        .map((row: any) => row.data)
        .filter(
          (value: any, index: any, self: any) =>
            index === self.findIndex((t: any) => t[colId] === value[colId])
        );

      const firstItem: any = this.params.filterOptions.filter(
        (row: any) => row.value[0] === 'ALL'
      );
      this.params.filterOptions = firstItem;
      const isDate = this.params.filterDefinitions.type == 'date';
      stockValues.map((value: any) => {
        let cellText = value[colId];
        const cellValue = [value[colId]];

        if (isDate) {
          cellText = this.formatDate(cellText);
        }
        this.params.filterOptions.push({
          value: cellValue,
          text: cellText,
        });
      });
      this.params.filterOptions = this.params.filterOptions.slice(1);
      this.params.filterOptions = this.getAllValuesInCell();
      if (!isDate) {
        this.params.filterOptions =
          this.params.filterDefinitions.sortBy == 'asc'
            ? this.params.filterOptions.sort((a: any, b: any) =>
                a.text === undefined || b.text === undefined
                  ? 0
                  : a.text.localeCompare(b.text)
              )
            : this.params.filterOptions.sort((a: any, b: any) =>
                a.text === undefined || b.text === undefined
                  ? 0
                  : b.text.localeCompare(a.text)
              );
      }
      this.params.filterOptions.unshift(firstItem[0]);
    } else {
      if (this.params.filterOptions[0].value[0] != 'ALL')
        this.params.filterOptions.unshift({
          text: 'Todos',
          value: ['ALL'],
        });
    }
    if (this.params.column.filterActive) {
      this.filterOptions.map((item1: any) => {
        const item2 = this.params.filterOptions.find(
          (item2: any) => item2.text === item1.text
        );
        return item2 ? Object.assign({}, item1, item2) : item1;
      });
    } else {
      this.refreshOptionsColumn();
    }
  }

  refreshOptionsColumn() {
    this.filterOptions = this.params.filterOptions.map((item: any) => {
      item.active = item.value[0] == 'ALL' ? true : false;
      return item;
    });
  }

  isFilterActive(): boolean {
    return this.filterOptions.some(
      (option: any) => option.active && option.value[0] !== 'ALL'
    );
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const side = params.data[this.params.column.colId];

    return this.filterOptions.some((option: any) => {
      if (option.active) {
        return option.value.includes(side);
      }
      return false;
    });
  }

  getModel() {
    //do nothing.
  }

  setModel(data: any) {
    if (!data) {
      this.updateAllFilter(true);
    } else {
      let hasFilter = false;
      this.filterOptions.forEach((option: any) => {
        option.active = false;
        if (data[option.value[0]]) {
          hasFilter = true;
          option.active = true;
        }
      });
      if (!hasFilter) {
        this.updateAllFilter(true);
      }
    }
    this._refreshComponent$.next();
  }

  updateFilter(event?: any) {
    if (event.value[0] == 'ALL') {
      this.updateAllFilter(event.active);
    } else {
      this.updateOtherFilter();
    }
    this._refreshComponent$.next();
    this.params.filterChangedCallback();
  }

  updateAllFilter(active: boolean) {
    this.filterOptions.forEach((row: any) => {
      if (row.value[0] == 'ALL') {
        row.active = active;
      } else if (active) {
        row.active = false;
      }
    });
  }

  updateOtherFilter() {
    this.filterOptions.forEach((row: any) => {
      if (row.value[0] == 'ALL') {
        row.active = false;
      }
    });
  }

  destroy(): void {
    //do nothing.
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    this.refreshComponent();
    this.cdr.detectChanges();
  }

  public formatDate(dateStr: string): string {
    const hasHyphen = dateStr.includes('-');
    const regex = hasHyphen
      ? /(\d{4})-(\d{2})-(\d{2}).*/
      : /(\d{4})(\d{2})(\d{2})/;
    const match = dateStr.match(regex);

    if (!match) {
      return '';
    }

    const year = match[1];
    const month = match[2];
    const day = match[3];
    const date = new Date(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day)
    ).getTime();
    if (this.params.filterDefinitions.tomorrowText && date == this.tomorrow) {
      return this.params.filterDefinitions.tomorrowText;
    }
    if (this.params.filterDefinitions.todayText && date == this.today) {
      return this.params.filterDefinitions.todayText;
    }
    if (this.params.filterDefinitions.yesterdayText && date == this.yesterday) {
      return this.params.filterDefinitions.yesterdayText;
    }

    return `${day}/${month}/${year.slice(2)}`;
  }

  public valueMergedData(data: any) {
    const temp = data.reduce((acc: any, cur: any) => {
      if (!acc[cur.text]) {
        acc[cur.text] = {
          text: cur.text,
          value: [...cur.value],
        };
      } else {
        for (const val of cur.value) {
          if (!acc[cur.text].value.includes(val)) {
            acc[cur.text].value.push(val);
          }
        }
      }
      return acc;
    }, {});

    return Object.values(temp);
  }

  public getAllValuesInCell() {
    const columnDef = this.params.colDef;
    const values: any = [];
    const filterFormatterActive =
      this.params.filterDefinitions.filterFormatterActive;

    if (filterFormatterActive) {
      this.params.rowModel.rowsToDisplay.map((row: any) => {
        const rowNode = row.data;
        const cellValue = rowNode[columnDef.field];
        const valueFormatter = columnDef.valueFormatter;
        const formattedValue = valueFormatter({
          data: rowNode,
          value: cellValue,
        });
        values.push({
          text: formattedValue,
          value: [cellValue],
        });
      });
      return this.valueMergedData(values);
    }

    return this.valueMergedData(this.params.filterOptions);
  }
}
