import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from 'src/app/core/layout/layout.module';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { FlaMaskModule } from '@shared/rocket-components/directives';
import { RocketButtonModule } from '@shared/rocket-components/components';
import { HotkeysService } from '@shared/rocket-components/services/hotkeys/hotkeys.service';
import { DeviceValidatorService } from '@shared/services/device-validator.service';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  WORKER_FACTORY_TOKEN,
  sharedWorkerFactory,
} from '@shared/channel/shared-worker-factory';
import * as Sentry from '@sentry/angular-ivy';
import { RtPopupService } from '@shared/services/popup.service';
import { ConfigService } from '@core/config';

registerLocaleData(localePt);

export function initializeApp(configService: ConfigService) {
  return () => configService.loadConfig();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    LayoutModule,
    NgSelectModule,
    FlaMaskModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    RocketButtonModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    { provide: WORKER_FACTORY_TOKEN, useValue: sharedWorkerFactory },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    HotkeysService,
    DeviceValidatorService,
    RtPopupService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
