import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { HomeService } from '@modules/home/service/home.service';
import { ModalAvisoComponent } from '../../../shared/components/modal-aviso/modal-aviso.component';
import { ModalGerenciarComponent } from './modal-gerenciar/modal-gerenciar.component';
import { WorkSpaceConfigs } from '@core/workspace/config/workspace.configs';
import { JoystickService } from '@shared/services/joystick.service';
import { StorageUtils } from '@services/core/storage/storage.utils';
import { getOS, isNullOrUndefined } from 'src/app/utils/utils.functions';
import { IOrders, IWorkSpace } from 'src/app/core/interface';
import { IntrojsService } from '@core/introjs/introjs.service';
import {
  BOLETA_COMPRA_BASECOMPONENT,
  BOLETA_VENDA_BASECOMPONENT,
} from '@core/workspace/constants';
import { TOOLTIPS } from './constants/header.contanst';
import { OrderTokenService } from '@shared/rocket-components/modal-order-token/order-token.service';
import { RocketModalService } from '@shared/rocket-components/components/index';
import { deepClone } from '@shared/rocket-components/utils';
import { ModalShortcutsComponent } from './modal-shortcuts/modal-shortcuts.component';
import {
  UserDetails,
  UserService,
} from '@shared/services/api/trademap/v1/user.service';
import { ModalMeusDadosComponent } from './modal-meus-dados/modal-meus-dados.component';
import { ModalDesktopVersionComponent } from './modal-desktop-version/modal-desktop-version.component';
import { MultibrokerService } from '@shared/services/core/multibroker';
import {
  BehaviorSubject,
  Subject,
  auditTime,
  debounceTime,
  takeUntil,
} from 'rxjs';
import { WorkspaceComponentService } from '@modules/home/service/workspace-component.service';
import { HeaderService } from './header.service';
import { system } from '../../system/system.service';
import { ModalTokenConfigComponent } from './modal-token-config/modal-token-config.component';
import { InitializeBrokerConnectService } from '@shared/services/initialize-broker-connect.service';
import { StandaloneModalsService } from '@shared/modals/standalone-modals.service';
import { AuthService, ToastService } from '@shared/services';
import { StockChartModalStockComponent } from '@shared/components/stock-chart/parts/modal-stock/stock-chart-modal-stock.component';
import { SuperSearchService } from '@modules/home/super-search.service';
import { ModalOrdensComponent } from '@shared/modals/modal-ordens/modal-ordens.component';
import { PopoversDirective } from '@shared/rocket-components/popovers';
import {
  BG_FEEDBACK_CHEETAH,
  BG_FEEDBACK_CHEETAH_TITLE,
} from '@shared/cheetah/cheetah.const';
import { OtpService } from '@shared/services/api/authentication/v3/otp.service';
import { PartnersService } from '@shared/services/core/partners-services/partners.service';
import { IPartnerInfos } from '@shared/services/core/partners-services/partner-preferences.interface';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { RxEvent, StreamReadResponse } from '@shared/channel/rx-event';
import { SSE_STATUS } from '@shared/services/core/subscription/const/events-name';
import { RatingSystemComponent } from '@shared/modals/rating-system/rating-system.component';
import {
  HEADER_BUTTONS_ENUM,
  INFO_USER_OPTIONS_ENUM,
} from './enum/header.enum';
import { ConfigService } from '@core/config';
import { IHeaderButtons, IHeaderOptions } from './interfaces/header.interfaces';
import { Dictionary } from '@core/models';
import { ComponentsService } from '@core/workspace/service/components.service';
import { ModalBrokersToConnectComponent } from '@modules/broker-connection/modals/modal-brokers-to-connect/modal-brokers-to-connect.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent
  extends ReadStreamBase
  implements OnInit, OnDestroy
{
  profileInfoOptions!: Dictionary<IHeaderOptions>;
  headerButtons!: Dictionary<IHeaderButtons>;
  @ViewChild(PopoversDirective) popover!: PopoversDirective;
  public notificationClass!: string;
  public statusTitle!: string;
  public popoverContent: string = '';
  public componentList!: Dictionary<any>;
  public toolMenu!: Dictionary<any>;
  public profileData: UserDetails | undefined;
  public cheetah_address: string = '';
  public startStatus = false;
  public showMenu = true;
  isSimulator = false;
  hasSessionToken = false;
  showProfileInfo: boolean = false;
  private _hasTradingTokenRegistered: boolean = false;
  public fullscreen: {
    enable: boolean;
    label: 'Minimizar' | 'Maximizar';
    icon: 'fullscreen' | 'fullscreen_exit';
  } = {
    enable: false,
    label: 'Maximizar',
    icon: 'fullscreen',
  };
  private subjectDetectChanges = new Subject<void>();
  private _onDestroy = new Subject<void>();
  public tradingTokenTooltip = TOOLTIPS.WITHOUT_TRADING_TOKEN;
  public workspaceComponentsQty: any = {};
  public listWorkspaces: IWorkSpace[] = [];
  public visiblesWorkspaces: IWorkSpace[] = [];
  public activeWorkspace!: IWorkSpace;
  public desktopDownloadPkg: boolean = false;
  public addInDownloadPkg: boolean = false;
  public partnerInfos: IPartnerInfos | null = null;

  constructor(
    public joystick: JoystickService,
    public orderTokenService: OrderTokenService,
    private _modalService: RocketModalService,
    private _homeService: HomeService,
    private _workSpaceConfigs: WorkSpaceConfigs,
    private authService: AuthService,
    private _rxEvent: RxEvent,
    private _intro: IntrojsService,
    private _userService: UserService,
    private _multibrokerService: MultibrokerService,
    private _cdr: ChangeDetectorRef,
    private _workspaceComponentService: WorkspaceComponentService,
    private _headerService: HeaderService,
    private _otpService: OtpService,
    private _initializeBrokerConnectService: InitializeBrokerConnectService,
    private _toastService: ToastService,
    private _standaloneModalsService: StandaloneModalsService,
    private _superSearch: SuperSearchService,
    private _partnersService: PartnersService,
    private configService: ConfigService,
    private componentsService: ComponentsService
  ) {
    super();
    this.initializeTotalSubscription();
  }

  private initializeCallbackOptions() {
    const isBroker = this.configService.isBroker();
    this.profileInfoOptions.set(INFO_USER_OPTIONS_ENUM.TOKEN, {
      cssLabel: isBroker ? '' : 'cursor-pointer',
      subTitle: isBroker ? 'RLP' : 'Token',
    });
    this.profileInfoOptions.set(INFO_USER_OPTIONS_ENUM.CONFIGS, {
      subTitle: isBroker ? 'Ordens' : 'Configurações',
      nameIcon: isBroker ? 'list' : 'settings',
    });
  }

  private initializeTotalSubscription(): void {
    this._workspaceComponentService
      .dispatchVisiblesWorkspaces()
      .subscribe(() => {
        this.visiblesWorkspaces = this._headerService.getVisibleTabs();
        this.listWorkspaces = this._workspaceComponentService.getWorkspaces();
        this._cdr.detectChanges();
      });
    this._workspaceComponentService
      .dispatchActiveWorkspace()
      .subscribe((activeWorkspaces: IWorkSpace) => {
        this.activeWorkspace = activeWorkspaces;
        this._cdr.detectChanges();
      });
    this.subjectDetectChanges.pipe(debounceTime(500)).subscribe(() => {
      this._cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    this.initializeComponentList();
    this.initializeToolList();
    this.initializeDefaultHeader();
    this.initializeWorkspace();
    this.initializeBroker();
    this.setMenuIsVisible();
    this._checkUserPackages();
    this.initializeEditOrder();
    this.initializeCheetahStatus();
    this.processComponentsActive();
    this._verifyTradingToken();
    this.initializeSelectedAccount();
  }

  private initializeComponentList() {
    this.componentList = this.componentsService.getComponentsList();
  }

  private initializeToolList() {
    this.toolMenu = this.componentsService.getToolsList();
  }

  private initializeDefaultHeader() {
    this.profileInfoOptions = this._headerService.getDefaultInfoUserOptions();
    this.headerButtons = this._headerService.getDefaultButtonsHeader();
    this.initializeCallbackOptions();
    this.subjectDetectChanges.next();
  }

  private initializeSelectedAccount() {
    this._multibrokerService
      .onUpdateSelectedAccountChannel()
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.isSimulator = this.joystick.isSimulator;
        this.updateSyncLockButton();
        this.subjectDetectChanges.next();
      });
  }

  private initializeCheetahStatus() {
    this.onCheetahStatusChange().then(({ stream }) => {
      this.readStream(stream, this.cheetahStatusHandler);
    });
  }

  private initializeEditOrder() {
    this._workSpaceConfigs.openStockTrade$
      .pipe(takeUntil(this._onDestroy), auditTime(100))
      .subscribe((data: any) => {
        if (data && data.order) this.checkTypeEditOrder(data);
      });
  }

  private initializeWorkspace() {
    this.activeWorkspace = this._workspaceComponentService.getActiveWorkspace();
    this.visiblesWorkspaces = this._headerService.getVisibleTabs();
  }

  private initializeBroker() {
    this._initializeBrokerConnectService.initializeBroker$
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data) => {
        this.showProfileInfo = data;
        this.processProfileInfo();
        this._verifyPartnerInfos();
        this._cdr.detectChanges();
      });
  }

  private setMenuIsVisible() {
    const MENU_IS_VISIBLE = StorageUtils.getItemLocalStorage('MENU_IS_VISIBLE');
    this.showMenu = !isNullOrUndefined(MENU_IS_VISIBLE)
      ? MENU_IS_VISIBLE
      : true;
    this.setMenuVisibilityOptions();
  }

  private setMenuVisibilityOptions() {
    this.profileInfoOptions.set(INFO_USER_OPTIONS_ENUM.MENU_VISIBILITY, {
      nameIcon: this.showMenu ? 'visibility' : 'visibility_off',
      subTitle: this.showMenu ? 'Ocultar Menu' : 'Exibir Menu',
    });
  }

  private cheetahStatusHandler = ({ cdStatus, status }: any) => {
    this.notificationClass = status;
    this.getStatusTitle(cdStatus);
  };

  private onCheetahStatusChange(): StreamReadResponse {
    return this._rxEvent.read(SSE_STATUS);
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private _checkUserPackages() {
    const dwnPkg = system.packages['ROCKET_DESKTOP_DOWNLOAD' as any];
    this.desktopDownloadPkg = (dwnPkg && !dwnPkg.is_defaulter) ?? false;
    const scrPkg = system.packages['ROCKET_SCREENING' as any];
    if (!scrPkg || scrPkg?.is_defaulter) {
      this.toolMenu.delete('SCR');
    }
    if (!this.desktopDownloadPkg) {
      this.profileInfoOptions.delete(INFO_USER_OPTIONS_ENUM.DOWNLOADPKG);
    }
  }

  private processProfileInfo() {
    this._userService.getInfos().subscribe((user: UserDetails) => {
      this.profileData = user;
      const isBroker = this.configService.isBroker();
      this.profileInfoOptions.set(INFO_USER_OPTIONS_ENUM.USER_INFO, {
        component: isBroker ? 'span' : 'img',
        src: this.profileData?.picture,
        title: isBroker ? 'this.investor.name' : this.profileData?.nickname,
        subTitle: isBroker ? '' : this.profileData?.name,
      });
      this.subjectDetectChanges.next();
    });
  }

  private getStatusTitle(status: string) {
    this.resetPopOver();
    if (BG_FEEDBACK_CHEETAH_TITLE[status])
      this.statusTitle = `
        <div>
          <span class="notification-status ${BG_FEEDBACK_CHEETAH[status]}"></span>&nbsp;
          ${BG_FEEDBACK_CHEETAH_TITLE[status]}
        </div
      `;
    this._cdr.detectChanges();
  }

  resetPopOver = () => {
    this.statusTitle = '';
    this._cdr.detectChanges();
  };

  @HostListener('window:resize')
  private async processComponentsActive() {
    this.validateFullscreen();
    this.subjectDetectChanges.next();
  }

  private validateFullscreen = () => {
    const fullScreenActive = Boolean(document.fullscreenElement);
    this.fullscreen = {
      enable: fullScreenActive,
      label: fullScreenActive ? 'Minimizar' : 'Maximizar',
      icon: fullScreenActive ? 'fullscreen_exit' : 'fullscreen',
    };
    this.headerButtons.set(HEADER_BUTTONS_ENUM.FULL_SCREEN, {
      tooltip: this.fullscreen.label,
      icon: this.fullscreen.icon,
    });
    this.subjectDetectChanges.next();
  };

  public logout = () =>
    this.authService.performLogout({ reason: 'USER_COMMAND' });

  public newWorkspace = () => this._headerService.addWorkspace();

  addComponentToHome(component: any) {
    this._homeService.addComponent(component);
  }

  public openSettings() {
    this._modalService.open(ModalGerenciarComponent, { keyboard: false });
  }

  public showTab(workspace: IWorkSpace) {
    workspace.active = true;
    workspace.visible = true;
    this._workspaceComponentService.changeActiveWorkspace(workspace);
    this._homeService.updateWorkspace(deepClone(workspace!)).subscribe(() => {
      this._workspaceComponentService.dispatchWorkspaceUpdate();
    });
  }

  @HostListener('window:keydown.control.F5', ['$event'])
  private openStockTradeBuy(event: any, isEdit: boolean = false) {
    console.log('openStockTradeBuy');

    event && event.preventDefault();
    const findStockTrade: any = BOLETA_COMPRA_BASECOMPONENT();
    if (isEdit) {
      findStockTrade.link = false;
    }
    this._homeService.addComponent(findStockTrade);
  }

  @HostListener('window:keydown.control.F9', ['$event'])
  private openStockTradeSell(event: any, isEdit: boolean = false) {
    event && event.preventDefault();
    const findStockTrade: any = BOLETA_VENDA_BASECOMPONENT();
    if (isEdit) {
      findStockTrade.link = false;
    }
    this._homeService.addComponent(findStockTrade);
  }

  @HostListener('window:keydown.control.F1', ['$event'])
  openTour(event?: any) {
    event && event.preventDefault();
    this._intro.onboardingIntro();
  }

  @HostListener('window:keydown.F11', ['$event'])
  fullscreenToggle(event?: any) {
    event && event.preventDefault();
    this.toggleFullscreenMode();
  }

  private checkTypeEditOrder(data: {
    order: Partial<IOrders>;
    isEditMode: boolean;
  }): void {
    const { order, isEditMode } = data;
    if (order.side == 'B') {
      this.openStockTradeBuy(null, isEditMode);
    } else {
      this.openStockTradeSell(null, isEditMode);
    }
    this.subjectDetectChanges.next();
  }

  public displayPerformanceMonitor(displayMonitor: boolean): void {
    this.startStatus = displayMonitor;
  }

  openMeusDados = () => {
    this._modalService.open(ModalMeusDadosComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: true,
      scrollable: false,
      data: this.profileData,
    });
  };

  openTokenConfig = () => {
    this._modalService.open(ModalTokenConfigComponent, {
      size: 'lg',
      centered: true,
      backdrop: true,
      keyboard: true,
      scrollable: false,
      data: { callback: undefined, tokenPreference: '' },
    });
  };

  public openShortCutsModal = () => {
    this._modalService.open(ModalShortcutsComponent, {
      size: 'lg',
      centered: true,
      backdrop: true,
      keyboard: false,
      scrollable: false,
    });
  };

  public openOrdensConfigModal = () => {
    this._standaloneModalsService.openStandaloneModal(ModalOrdensComponent);
  };

  public setMenuVisibility = () => {
    this.showMenu = !this.showMenu;
    StorageUtils.setItemLocalStorage('MENU_IS_VISIBLE', this.showMenu);
    this.setMenuVisibilityOptions();
    this.subjectDetectChanges.next();
  };

  onToolClick(tool: any) {
    if (tool.noPublished) {
      this._modalService.open(ModalAvisoComponent, {
        centered: true,
        backdrop: true,
        keyboard: true,
        data: {
          title: tool.label,
          text: `A ferramenta <b> ${tool.label}</b> não esta disponível ainda.<br />
          Estamos trabalhando para disponibiliza-lo em breve.`,
          showButtons: false,
          askAgain: false,
          stock: undefined,
          showPriceStock: false,
        },
      });
      return;
    }
    this._homeService.addComponent(tool);
  }

  preventClick = (event: Event) => event.stopPropagation();

  public _verifyTradingToken = (): void => {
    this.orderTokenService.onChangeSessionToken.subscribe(
      (response: boolean) => {
        this.hasSessionToken = this.orderTokenService.hasSessionToken;
        this._hasTradingTokenRegistered = response;
        this.tradingTokenTooltip = response
          ? TOOLTIPS.WITH_TRADING_TOKEN
          : TOOLTIPS.WITHOUT_TRADING_TOKEN;
        this.updateSyncLockButton();
        this.subjectDetectChanges.next();
      }
    );
  };

  private updateSyncLockButton() {
    this.headerButtons.set(HEADER_BUTTONS_ENUM.SYNC_LOCK, {
      tooltip: this.tradingTokenTooltip,
      css:
        !this.isSimulator && !this.hasSessionToken
          ? 'text-feedback-warning'
          : 'text-neutral-smoother',
      tooltipHidden: false,
    });
  }

  public openTradingTokenModal(): void {
    if (this.isSimulator) return;
    this._otpService.userTokenPreferences().subscribe((response: any) => {
      if (response === 'UNDEFINED_TOKEN') {
        this._modalService.open(ModalTokenConfigComponent, {
          size: 'lg',
          centered: true,
          backdrop: true,
          keyboard: true,
          scrollable: false,
          data: {
            callback: (modalDismissed: boolean) => {
              if (modalDismissed) return;
              this._openTradingTokenModal();
            },
            tokenPreference: '',
          },
        });
        return;
      }
      this._openTradingTokenModal();
    });
  }

  private _openTradingTokenModal() {
    this.orderTokenService.openTokenModal(
      this._hasTradingTokenRegistered,
      false,
      true
    );
  }

  public handleHeaderUnpublishedOptions(label: string): void {
    this.onToolClick({ label, noPublished: true });
  }

  public toggleFullscreenMode(): void {
    !this.fullscreen.enable && document.documentElement.requestFullscreen();
    this.fullscreen.enable &&
      document.fullscreenElement &&
      document.exitFullscreen();
  }

  public componentsQty(): void {
    this.workspaceComponentsQty = this._homeService.componentsQty();
  }

  public downloadDesktopVersion = () => {
    this._modalService.open(ModalDesktopVersionComponent, {
      size: 'lg',
      centered: true,
      backdrop: true,
      keyboard: false,
      scrollable: false,
    });
  };

  public openModalConnectBrokers = (openPartner: boolean = false) => {
    if (openPartner && this._modalService.isDisplayingSomeModal)
      this._modalService.close();
    this._modalService.open(ModalBrokersToConnectComponent, {
      size: 'xl',
      centered: true,
      backdrop: 'static',
      keyboard: false,
      scrollable: false,
    });
  };

  public downloadAddInExcel() {
    if (getOS() != 'Windows') {
      return this._toastService.showToast(
        'warning',
        'Recurso apenas disponível para sistema operacional Windows'
      );
    }

    const url =
      'https://cloud-front-distribution.s3.sa-east-1.amazonaws.com/ValemobiExcelAddIn/install/ValemobiExcelAddIn.msi';
    window.open(url, '_blank');
  }

  public openAddInHelp() {
    this._intro.addIn();
  }

  public openTerminal() {
    const word: BehaviorSubject<string> = new BehaviorSubject<string>('');
    const data = { word: word, ref: 'HOME', searchString: null };
    this._modalService.open<StockChartModalStockComponent>(
      StockChartModalStockComponent,
      {
        data,
        keyboard: true,
        backdrop: true,
        scrollable: false,
      }
    );
    this._superSearch.listenToModalDismiss();
  }

  private _verifyPartnerInfos() {
    return this._partnersService
      .shouldDisplayConnectBrokerButton()
      .subscribe((partner) => {
        if (!partner) return;
        const logoPath = partner.name.toLowerCase().replace(/ /g, '-');
        partner.logo = `../../../../assets/logo/logo-${logoPath}.svg`;
        partner.btnLabel = partner.key!.toString().toUpperCase();
        this.partnerInfos = partner;
        this._cdr.detectChanges();
      });
  }

  openRatingSystemModal = () => {
    this._standaloneModalsService.openStandaloneModal(
      RatingSystemComponent,
      null,
      {
        backdrop: 'static',
        keyboard: true,
      }
    );
  };

  optionInfoClicked(option: INFO_USER_OPTIONS_ENUM) {
    const hashFunctions: any = {
      [INFO_USER_OPTIONS_ENUM.MANAGE_ACCOUNT]: () => this.openMeusDados(),
      [INFO_USER_OPTIONS_ENUM.TOKEN]: () => this.openTokenConfig(),
      [INFO_USER_OPTIONS_ENUM.SHORTCUTS]: () => this.openShortCutsModal(),
      [INFO_USER_OPTIONS_ENUM.CONFIGS]: () => this.openOrdensConfigModal(),
      [INFO_USER_OPTIONS_ENUM.CONNECTIONS]: () =>
        this.openModalConnectBrokers(),
      [INFO_USER_OPTIONS_ENUM.RATING]: () => this.openRatingSystemModal(),
      [INFO_USER_OPTIONS_ENUM.LOGOUT]: () => this.logout(),
      [INFO_USER_OPTIONS_ENUM.DOWNLOADPKG]: () => this.downloadDesktopVersion(),
      [INFO_USER_OPTIONS_ENUM.MENU_VISIBILITY]: () => this.setMenuVisibility(),
    };
    const func = hashFunctions[option];
    if (func) {
      func();
    }
  }

  buttonHeaderClick(button: HEADER_BUTTONS_ENUM) {
    const hashFunctions: any = {
      [HEADER_BUTTONS_ENUM.SEARCH]: () => this.openTerminal(),
      [HEADER_BUTTONS_ENUM.FULL_SCREEN]: () => this.toggleFullscreenMode(),
      [HEADER_BUTTONS_ENUM.SYNC_LOCK]: () => this.openTradingTokenModal(),
    };
    const func = hashFunctions[button];
    if (func) {
      func();
    }
  }
}
