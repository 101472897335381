<div [formGroup]="form">
  <ng-select
    #ngSelectSaveOptions
    class="footer-configuser form-select form-select-sm form-control-sm"
    id="ng-select-settings"
    [clearable]="false"
    [items]="saveOptions"
    formControlName="optionConfig"
  >
    <ng-template ng-label-tmp let-item="item">
      {{ item.labelTmp }}
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index">
      <div
        [tooltip]="item.hint"
        tooltipPosition="bottom"
        (click)="dispatchItem(item)"
      >
        {{ item.label }}
      </div>
    </ng-template>
  </ng-select>
</div>
