import { Component, Inject, OnInit } from '@angular/core';
import { ROCKET_MODAL_DATA } from '@shared/rocket-components/components/modal/constants/modal.contants';
import { RocketModalRef } from '@shared/rocket-components/components/modal/modal-parts/modal-ref.component';
import { RocketModalService } from '@shared/rocket-components/components/modal/service/modal.service';
import { OrsGatewayService } from '@shared/services/api/trademap/v1/ors-gateway.service';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { HEADERS_DETAILS_ORDER } from '../../grid/columns/coldef-order';
import { SideDict } from '@shared/enum/buyOrSell.enum';
import { dateTimeFormatter } from '@shared/rocket-components/utils/functions';
import { RocketModalModule } from '@shared/rocket-components/components/modal/modal.module';
import { CommonModule } from '@angular/common';
import { RocketGridModule } from '@shared/rocket-grid/rocket-grid.module';
import {
  ORDER_AUCTION_TYPE,
  ORDER_AUCTION_VALIDITY,
} from '@shared/constants/general.contant';

@Component({
  selector: 'app-order-details-modal',
  templateUrl: './order-details-modal.component.html',
  standalone: true,
  imports: [RocketModalModule, CommonModule, RocketGridModule],
  providers: [RocketModalService],
  styles: [
    `
      :host(app-order-details-modal) {
        ::ng-deep .icon-position {
          &.new-position {
            padding-left: 2px;
            padding-right: 2px;
          }
          &.gain {
            color: #2f6d2f !important;
            border-color: #2f6d2f !important;
          }
          &.loss {
            color: #ac2423 !important;
            border-color: #ac2423 !important;
          }
          &.zeroing {
            color: var(--vm-feedback-warning) !important;
            border-color: var(--vm-feedback-warning) !important;
          }
          border: 1px solid;
          border-radius: 100%;
          padding-left: 3px;
          padding-right: 3px;
          padding-bottom: 0px !important;
          line-height: 11px;
          font-weight: bold;
          margin-top: 3px;
          font-size: 8px !important;
        }
      }
    `,
  ],
})
export class OrderDetailsModalComponent
  extends RocketModalRef
  implements OnInit
{
  public rowData: any[] = [];
  public columnDefs: ColDef[] = HEADERS_DETAILS_ORDER;
  public idOrder!: number;

  get typeSideOrder(): string {
    return this.data ? SideDict[this.data.side] : '';
  }

  get dateCreated(): string {
    return this.data
      ? dateTimeFormatter(this.data.dh_creation_time).replace(',', '')
      : '';
  }

  constructor(
    @Inject(ROCKET_MODAL_DATA)
    public data: any,
    private __rocketModalService: RocketModalService,
    private orsGatewayService: OrsGatewayService
  ) {
    super(__rocketModalService);
  }

  ngOnInit(): void {
    this.data['orderType'] = this.orderType(
      this.data.ds_order_type,
      this.data.cd_order_time_enforce,
      this.data.cd_order_type
    );
    this.data['orderTimeEnforce'] = this.typeValidade(
      this.data.cd_order_time_enforce || this.data.ds_order_time_enforce,
      this.data.cd_order_time_enforce,
      this.data.cd_order_type,
      this.data.dt_expirate_date
    );
    this.idOrder = this.data.id_order;
    this.orsGatewayService.getDetOrder(this.idOrder).subscribe((res: any[]) => {
      const data = res.reverse();
      this.rowData = data.map((item) => {
        item.tick_size_denominator = this.data.tick_size_denominator;
        item.cd_order_type = this.data.cd_order_type;
        item.side = this.data.side;
        return item;
      });
    });
  }

  private typeValidade(
    typeValidade: string,
    timeEnforce: string,
    orderType: string,
    dtExpirateDate: string
  ) {
    const validade =
      ORDER_AUCTION_VALIDITY[`${timeEnforce}${orderType}`] ?? typeValidade;
    switch (validade) {
      case '0':
        return 'Dia';
      case 'Até o vencimento':
      case '6':
        return dtExpirateDate;
      case '1':
      case 'Até cancelar':
        return 'VAC';
      case '3':
        return 'Executa ou Cancela';
      case '4':
        return 'Tudo ou Nada';
      default:
        return validade;
    }
  }

  private orderType(
    dsOrderType: string,
    timeEnforce: string,
    orderType: string
  ): string {
    return ORDER_AUCTION_TYPE[`${timeEnforce}${orderType}`] ?? dsOrderType;
  }
}
