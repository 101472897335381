import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'fla-button-close[dismiss]',
  templateUrl: './button-close.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class FlaButtonCloseComponent {
  @Input('css') customClass: string = '';
  @Input() dismiss!: string;
  @Input() isDisabled = false;
  @Output() flaClick = new EventEmitter<any>();
}
