import { Type, inject } from '@angular/core';
import { ResolveData } from '@angular/router';
import { ModalMeusDadosComponent } from '@core/layout/header/modal-meus-dados/modal-meus-dados.component';
import { ModalTokenConfigComponent } from '@core/layout/header/modal-token-config/modal-token-config.component';
import { RocketModalService } from '@shared/rocket-components/components';
import { UserDetails, UserService } from '@shared/services/api/trademap/v1/user.service';


export const getModalViewResolver = <T>(coomponentType: Type<T>): ResolveData => {
  return { component: () => modalViewResolver(coomponentType) };
}

const modalViewResolver = <T>(coomponentType: Type<T>): boolean => {
  const resolver = ResolverMap.get(coomponentType) ?? (() => false);
  return resolver();
}

const ResolverMap = new Map<Type<any>, () => boolean>([
  [
    ModalMeusDadosComponent, (): boolean => {
      const modalService = inject(RocketModalService);
      inject(UserService).getInfos().subscribe((user: UserDetails) => {
        modalService.open(ModalMeusDadosComponent, {
          size: 'lg',
          centered: true,
          backdrop: 'static',
          keyboard: true,
          scrollable: false,
          data: user,
        });
      });
      return true;
    }
  ],
  [
    ModalTokenConfigComponent, (): boolean => {
      inject(RocketModalService).open(ModalTokenConfigComponent, {
        size: 'lg',
        centered: true,
        backdrop: true,
        keyboard: true,
        scrollable: false,
        data: { callback: undefined, tokenPreference: '' },
      });
      return true;
    }
  ],

]);


