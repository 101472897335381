import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  ROCKET_MODAL_DATA,
  RocketButtonModule,
  RocketModalModule,
  RocketModalService,
} from '@shared/rocket-components/components';
import { HandleTrialHelper } from '../handle-trial.helper';
@Component({
  selector: 'app-trademap',
  templateUrl: './trademap.component.html',
  standalone: true,
  imports: [CommonModule, RocketModalModule, RocketButtonModule],
})
export class TrademapComponent extends HandleTrialHelper implements OnInit {
  constructor(
    @Inject(ROCKET_MODAL_DATA) override data: { trialEnabled: boolean },
    override modalService: RocketModalService
  ) {
    super(data, modalService);
  }

  public partnerBenefits = [
    'RocketTrader',
    'Conexão com múltiplas corretoras',
    'Excel Add-in',
    'Carteira investimentos completa no TradeMap',
    'Recomendação de compra e venda',
    'Histórico completo de indicadores fundamentalistas',
  ];

  ngOnInit(): void {
    this.verifyCanEnableTrial();
  }

  public doAuthenticationAfterEnableTrial(): void {
    if (this.sevenDaysTrialEnabled) {
      this.initializeSystem();
      return;
    }
    this.closeModalComponent();
  }
}
