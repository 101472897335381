<stock-analysis-tooltip
  [top]="tooltipTop"
  [left]="tooltipLeft"
  [visible]="tooltipVisible"
  [data]="tooltipData"
></stock-analysis-tooltip>
<div [id]="refChart" class="w-100 stock-analysis-chart"></div>
<stock-analysis-controls
  *ngIf="!isMoversList"
  [width]="width"
  (control)="controlAction($event)"
></stock-analysis-controls>
