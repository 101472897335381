import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StockTableHeatmapComponent } from './stock-table-heatmap.component';
import { HeatmapChartModule } from '../heatmap-chart/heatmap-chart.module';

@NgModule({
  imports: [CommonModule, HeatmapChartModule],
  declarations: [StockTableHeatmapComponent],
  exports: [StockTableHeatmapComponent],
})
export class StockTableHeatmapModule {}
