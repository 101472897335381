import { StockListStettingsService } from '@services/api/nitro-ws/v1/stock-list-stettings.service';
import {
  IAllStockListSimple,
  IListStockConfig,
  IListStockDB,
} from 'src/app/core/interface';
import { isAuctionList } from '../constants/stock-table.constants';
import { SearchConfig } from './search-config';
import { ListStocksService } from '@shared/services/core/list-stocks/list-stocks.service';
import { MARKET_MOVER_HEADER } from '../constants/columns/market-movers';
export class CheckConfigListMotPersonal {
  private isChange = false;
  private exeCallback!: (list: IListStockDB) => void;

  constructor(
    private stockListStettingsService: StockListStettingsService,
    private listStocksService: ListStocksService
  ) {}

  public check(
    item: IAllStockListSimple,
    callback: (list: IListStockDB) => void
  ): void {
    const list = {
      idStockList: item.id_stock_list,
      isNotListPersonal: true,
      configList: null,
      list: item,
      stocks: [],
    };
    this.exeCallback = callback;
    const listSelected = this.listStocksService.getStockList(
      list.idStockList.toString()
    );
    listSelected && this.updateConfigList(listSelected);
    !listSelected && this.getConfig(list);
  }

  private getConfig(listSelected: IListStockDB): void {
    this.isChange = true;
    const searchConfig = new SearchConfig(this.stockListStettingsService);
    searchConfig
      .getConfigListStock(
        listSelected.idStockList,
        isAuctionList(listSelected.idStockList)
      )
      .then((config: IListStockConfig) => {
        listSelected.configList = config;
        this.updateConfigList(listSelected);
      });
  }

  private updateConfigList(listSelected: IListStockDB): void {
    listSelected.defaultHeadersConfig = this._defaultHeadersConfig(
      listSelected.configList?.metadata.headers
    );
    if (this.isChange) {
      this.listStocksService.updateStockList({
        idStockList: (listSelected.idStockList ?? 0).toString(),
        value: listSelected,
      });
    }
    this.exeCallback(listSelected);
  }

  private _defaultHeadersConfig(headers: any): boolean {
    for (const key in MARKET_MOVER_HEADER) {
      if (headers[key] && MARKET_MOVER_HEADER[key].hide !== headers[key].hide) {
        return false;
      }
    }
    return true;
  }
}
