<div
  class="tooltip-fake"
  *ngIf="seriesToShow.length"
  [style.left.px]="tooltipX"
  [style.top.px]="tooltipY"
  [style.width.px]="tooltipWidth"
>
  <div class="fw-bolder">
    {{ seriesToShow[0].xTitle }}
  </div>
  <div class="d-flex flex-wrap">
    <div
      class="pr-2 pt-1 d-flex align-items-center"
      *ngFor="let serie of seriesToShow"
    >
      <div [style.background-color]="serie.fill" class="dot-tooltip mr-1"></div>
      {{ serie.tipo }}:
      <span class="fw-bolder">{{ serie.valueFormatted }}</span>
    </div>
  </div>
</div>
