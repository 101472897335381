import { BehaviorSubject } from 'rxjs';

export class ViewHistoryBase {
  start$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  onStart(component: any) {
    this.init(component);
    return this.start$.asObservable();
  }

  init(component: any) {
    this.start$.next(component);
  }
}
