import { IAppConfig } from './config.interface';

export const AppConfigInfo: Map<string, IAppConfig> = new Map<
  string,
  IAppConfig
>();
AppConfigInfo.set('core', { favicon: 'favicon.ico', title: 'RocketTrader' });
AppConfigInfo.set('agora', {
  favicon: 'assets/corretoras/agora/favicon.ico',
  title: 'Ágora Investimentos',
});
