<workspace-doc-header css="w-100 d-flex header-wrapper nav">
  <fla-workspace-doc-header-item
    [enableContent]="true"
    *ngFor="let item of list; let i = index"
  >
    <fla-workspace-doc-header-item
      margin="mx-2"
      [hideBorder]="true"
      [name]="item"
      [active]="i === indextab"
      (flaClick)="selectTab(i)"
    >
    </fla-workspace-doc-header-item>
  </fla-workspace-doc-header-item>
</workspace-doc-header>
