import { Injectable } from '@angular/core';
import { AbstractCacheService } from './AbstractCacheService';
import { IIndicatorCompany } from '@shared/components/business-profile/business-profile.interface';

@Injectable({
  providedIn: 'root',
})
export class IndicatorCompanyCacheService extends AbstractCacheService<
  IIndicatorCompany[]
> {}
