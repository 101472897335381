import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  CANDLE_PATTERNS,
  DATA_POINT,
  DEFAULT,
  RELATION,
  SELECT_CONDITIONS,
} from '../../constants/constants';
import { RocketModalService } from '@shared/rocket-components/components';
import {
  IConditional,
  IScreeningFilter,
  ISideConditional,
} from '../../interfaces/indicator.interface';
import { deepClone } from '@shared/rocket-components/utils';
import { EditParamsConditionsModalComponent } from '../edit-params-conditions-modal/edit-params-conditions-modal.component';
import { Subject, debounceTime, distinct } from 'rxjs';

@Component({
  selector: 'app-edit-filter',
  templateUrl: './edit-filter.component.html',
  styles: [
    `
      ::ng-deep .margin-bottom {
        margin-bottom: 0px !important;
      }
      .truncate {
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      :host(edit-filter) {
        .dropdown-toggle:not(.hide-arrow)::after {
          float: right;
          margin-top: 7px;
        }
        .dropdown {
          .dropdown-menu-left {
            min-width: 100% !important;
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditFilterComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('nameFilter', { static: false }) public nameFilter!: any;
  @Input() screeningFilter!: IScreeningFilter;
  @Input() selectionConditions!: any;
  @Output() save: EventEmitter<IScreeningFilter> =
    new EventEmitter<IScreeningFilter>();
  @Output() isSelectIndicators: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  public formFilter!: FormGroup;
  public nameError: string = 'Informe o nome do filtro';
  public relationList = RELATION;
  public isFormValid = false;
  public fieldSelect!: any;
  public screeningFilterTemp!: IScreeningFilter;
  public isCleanForm: boolean = false;
  private detectChangesFocusFieldSubject = new Subject<any>();

  get filtersForm(): FormArray {
    return this.formFilter.get('filters') as FormArray;
  }

  get isNameValid(): boolean {
    return !!this.formFilter.get('name')?.value;
  }

  constructor(
    private formBuilder: FormBuilder,
    private _rocketModalService: RocketModalService,
    private cdr: ChangeDetectorRef
  ) {
    this.formFilter = this.formBuilder.group({
      name: new FormControl(null, Validators.required),
      filters: this.formBuilder.array([]),
    });
  }

  ngOnInit(): void {
    this.detectChangesFocusFieldSubject
      .pipe(
        debounceTime(100),
        distinct(
          (select: { line: number; side: string }) => select.line && select.side
        )
      )
      .subscribe((select: { line: number; side: string }) => {
        if (!this.isNameValid) return;
        if (
          this.fieldSelect?.line === select.line &&
          this.fieldSelect?.side === select.side
        )
          return;
        this.selectField(select.line, select.side);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { selectionConditions, screeningFilter } = changes;
    selectionConditions?.currentValue && this.selectConditions();
    screeningFilter?.currentValue && this.cancelEdition();
  }

  ngOnDestroy(): void {
    !!this.detectChangesFocusFieldSubject &&
      this.detectChangesFocusFieldSubject.unsubscribe();
  }

  private restartForm(): void {
    this.formFilter = this.formBuilder.group({
      name: new FormControl(null, Validators.required),
      filters: this.formBuilder.array([]),
    });
    this.cdr.detectChanges();
  }

  private createdForm(): void {
    this.formFilter.get('name')?.valueChanges.subscribe((value: string) => {
      !!this.screeningFilterTemp && (this.screeningFilterTemp.name = value);
    });
    if (!this.screeningFilter || !this.screeningFilter.conditional.length) {
      this.screeningFilterTemp = deepClone(this.screeningFilter);
      this.screeningFilterTemp.conditional.push(deepClone(DEFAULT));
      const params = {
        leftSize: '',
        relation: '=',
        rightSize: '',
      };
      this.addItens(params);
      this.focusInputNameFilter();
      this.cdr.detectChanges();
      return;
    }
    this.screeningFilterTemp = deepClone(this.screeningFilter);
    this.formFilter.get('name')?.setValue(this.screeningFilterTemp.name);
    this.screeningFilterTemp.conditional.forEach(
      (conditional: IConditional, index: number) => {
        const params = {
          leftSize: this.prepareLabel(
            conditional.leftSide.type,
            index,
            'leftSide',
            conditional.leftSide
          ),
          relation: conditional.relation,
          rightSize: this.prepareLabel(
            conditional.rightSide.type,
            index,
            'rightSide',
            conditional.rightSide
          ),
        };
        this.addItens(params);
      }
    );
  }

  private prepareLabel(
    type: string,
    line: number,
    side: 'rightSide' | 'leftSide',
    conditions: any
  ): string {
    if (SELECT_CONDITIONS[1] === type) {
      let infosFilter = '';
      if (conditions.filter) {
        infosFilter = this.mountedLabel(line, side);
        return `${conditions.value.toLocaleUpperCase()} ${infosFilter}`;
      }
      return conditions.description.split(' (').shift();
    }
    return conditions.description;
  }

  private focusInputNameFilter(): void {
    setTimeout(() => {
      !!this.nameFilter?.refId &&
        document.getElementById(this.nameFilter.refId)?.focus();
    }, 100);
  }

  public addItens(params?: {
    leftSize: string;
    relation: string;
    rightSize: string;
  }): void {
    let paramsFiter;
    if (!params) {
      paramsFiter = {
        leftSize: '',
        relation: '=',
        rightSize: '',
      };
      this.screeningFilterTemp.conditional.push(deepClone(DEFAULT));
    } else {
      paramsFiter = params;
    }
    const filterItens = this.formBuilder.group({
      leftSide: new FormControl(paramsFiter.leftSize, Validators.required),
      relation: new FormControl(paramsFiter.relation, Validators.required),
      rightSide: new FormControl(paramsFiter.rightSize, Validators.required),
    });
    this.filtersForm.controls.push(filterItens);
    this.checkStatusForm();
    this.selectField(
      this.screeningFilterTemp.conditional.length - 1,
      'leftSide'
    );
    this.cdr.detectChanges();
  }

  public removeItems(index: number): void {
    this.filtersForm.controls.splice(index, 1);
    this.screeningFilterTemp.conditional.splice(index, 1);
    this.checkStatusForm();
    this.cdr.detectChanges();
  }

  public editConditions(
    indexFilter: number,
    side: 'rightSide' | 'leftSide'
  ): void {
    if (!this.isNameValid) return;
    const ref = this._rocketModalService.open(
      EditParamsConditionsModalComponent,
      {
        centered: true,
        keyboard: false,
        size: 'sm',
        data: deepClone(
          this.screeningFilterTemp.conditional[indexFilter][side]
        ),
      }
    );
    ref.afterDismissed.subscribe((resp: any) => {
      if (!resp.closed) {
        this.screeningFilterTemp.conditional[indexFilter][side] = deepClone(
          resp
        ) as ISideConditional;

        const value = this.screeningFilterTemp.conditional[indexFilter][side]
          .value as string;
        const checkType =
          this.screeningFilterTemp.conditional[indexFilter][side].type;

        const label =
          checkType === 'CUSTOM'
            ? value
            : `${value.toLocaleUpperCase()} ${this.mountedLabel(
                indexFilter,
                side
              )}`;

        this.filtersForm.get(`${indexFilter}`)?.get(side)?.setValue(label);
        this.checkStatusForm();
      }
      this.cdr.detectChanges();
    });
  }

  public changeRelation(
    event: { id: number; label: string },
    indexFilter: number
  ): void {
    this.filtersForm
      .get(`${indexFilter}`)
      ?.get('relation')
      ?.setValue(event.label);
    this.screeningFilterTemp.conditional[indexFilter].relation = event.label;
    this.checkStatusForm();
    this.cdr.detectChanges();
  }

  public getRelation(indexFilter: number): string {
    return this.filtersForm.get(`${indexFilter}`)?.get('relation')?.value || '';
  }

  private checkStatusForm(): void {
    let isValid = true;
    this.filtersForm.controls.forEach(
      (item: any) => isValid && (isValid = item.valid)
    );
    this.isFormValid = isValid;
  }

  public cancelEdition(): void {
    this.isCleanForm = true;
    this.fieldSelect = null;
    this.isSelectIndicators.emit(false);
    this.restartForm();
    this.createdForm();
    setTimeout(() => {
      this.isCleanForm = false;
      this.cdr.detectChanges();
    }, 10);
  }

  public saveEdition(): void {
    if (this.screeningFilterTemp.isStart) return;
    this.save.emit(deepClone(this.screeningFilterTemp));
    this.cdr.detectChanges();
  }

  public selectFieldFocus(line: number, side: string): void {
    this.detectChangesFocusFieldSubject.next({ line, side });
  }

  public selectField(line: number, side: string): void {
    if (!this.isNameValid || !this.formFilter.get('name')?.value) return;
    if (this.fieldSelect?.line === line && this.fieldSelect?.side === side) {
      this.fieldSelect = null;
      this.isSelectIndicators.emit(false);
      return;
    }
    this.fieldSelect = { line, side };
    this.isSelectIndicators.emit(true);
  }

  private selectConditions(): void {
    if (CANDLE_PATTERNS.includes(this.selectionConditions.value)) {
      this.conditionCandlePatterns();
      return;
    }
    if (!this.screeningFilterTemp.conditional[this.fieldSelect.line]) return;
    if (
      this.screeningFilterTemp.conditional[this.fieldSelect.line]['leftSide']
        .type === 'CANDLE_PATTERNS'
    ) {
      this.screeningFilterTemp.conditional[this.fieldSelect.line][
        'rightSide'
      ].value = '1';
      this.filtersForm
        .get(`${this.fieldSelect.line}`)
        ?.get('rightSide')
        ?.setValue('1');
    }
    const side: 'rightSide' | 'leftSide' = this.fieldSelect.side;
    this.screeningFilterTemp.conditional[this.fieldSelect.line][side].type =
      this.selectionConditions.type;
    this.screeningFilterTemp.conditional[this.fieldSelect.line][side].value =
      this.selectionConditions.value;
    this.screeningFilterTemp.conditional[this.fieldSelect.line][
      side
    ].description = this.selectionConditions.description;
    this.screeningFilterTemp.conditional[this.fieldSelect.line][side].filter =
      this.selectionConditions.filter;

    let value = this.selectionConditions.description;

    if (SELECT_CONDITIONS[1] === this.selectionConditions.type) {
      let infosFilter = '';
      if (this.selectionConditions.filter) {
        infosFilter = this.mountedLabel(this.fieldSelect.line, side);
        value = `${this.selectionConditions.value.toLocaleUpperCase()} ${infosFilter}`;
      } else {
        value = this.selectionConditions.description.split(' (').shift();
      }
    }

    this.filtersForm
      .get(`${this.fieldSelect.line}`)
      ?.get(side)
      ?.setValue(value);
    this.checkStatusForm();
    if (this.fieldSelect.side === 'rightSide') {
      if (this.screeningFilterTemp.conditional[this.fieldSelect.line + 1]) {
        this.selectField(this.fieldSelect.line + 1, 'leftSide');
      } else {
        this.selectField(this.fieldSelect.line, this.fieldSelect.side);
      }
    } else {
      this.selectField(this.fieldSelect.line, 'rightSide');
    }
    this.cdr.detectChanges();
  }

  private conditionCandlePatterns(): void {
    this.screeningFilterTemp.conditional[this.fieldSelect.line][
      'leftSide'
    ].type = 'CANDLE_PATTERNS';
    this.screeningFilterTemp.conditional[this.fieldSelect.line][
      'leftSide'
    ].value = this.selectionConditions.value;
    this.screeningFilterTemp.conditional[this.fieldSelect.line][
      'leftSide'
    ].description = this.selectionConditions.description;
    this.screeningFilterTemp.conditional[this.fieldSelect.line][
      'leftSide'
    ].filter = this.selectionConditions.filter;
    this.screeningFilterTemp.conditional[this.fieldSelect.line][
      'rightSide'
    ].type = 'CUSTOM';
    this.screeningFilterTemp.conditional[this.fieldSelect.line][
      'rightSide'
    ].value = '0';

    let value = this.selectionConditions.description;

    if (SELECT_CONDITIONS[1] === this.selectionConditions.type) {
      let infosFilter = '';
      if (this.selectionConditions.filter) {
        infosFilter = this.mountedLabel(this.fieldSelect.line, 'leftSide');
        value = `${this.selectionConditions.value.toLocaleUpperCase()} ${infosFilter}`;
      } else {
        value = this.selectionConditions.description.split(' (').shift();
      }
    }

    this.filtersForm
      .get(`${this.fieldSelect.line}`)
      ?.get('leftSide')
      ?.setValue(value);

    this.filtersForm
      .get(`${this.fieldSelect.line}`)
      ?.get('relation')
      ?.setValue('>');
    this.screeningFilterTemp.conditional[this.fieldSelect.line].relation = '>';

    this.filtersForm
      .get(`${this.fieldSelect.line}`)
      ?.get('rightSide')
      ?.setValue('0');
    this.checkStatusForm();
    if (this.screeningFilterTemp.conditional[this.fieldSelect.line + 1]) {
      this.selectField(this.fieldSelect.line + 1, 'leftSide');
    } else {
      this.selectField(this.fieldSelect.line, this.fieldSelect.side);
    }
    this.cdr.detectChanges();
  }

  private mountedLabel(line: number, side: 'rightSide' | 'leftSide'): string {
    let infosFilter = '(';
    Object.keys(
      this.screeningFilterTemp.conditional[line][side].filter
    ).forEach((item: string, index: number) => {
      !!index && (infosFilter += ',');
      if (item === 'data_point') {
        infosFilter += DATA_POINT.find(
          (point: any) =>
            point.id ===
            this.screeningFilterTemp.conditional[line][side].filter[item]
        )?.label;
      } else {
        infosFilter +=
          this.screeningFilterTemp.conditional[line][side].filter[item];
      }
    });
    return (infosFilter += ')');
  }
}
