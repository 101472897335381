import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { randomId } from '@shared/rocket-components/utils';
import { ScichartService } from '@shared/tiger-chart/services/scichart.service';
import {
  CursorModifier,
  EAxisAlignment,
  ELabelAlignment,
  FastColumnRenderableSeries,
  IRenderableSeries,
  NumericAxis,
  RolloverModifier,
  SeriesInfo,
  TWebAssemblyChart,
  Thickness,
  XyDataSeries,
} from 'scichart';
import { getVolumeText } from 'src/app/utils/utils.functions';
import { periodFields, periods, periodsEvolution } from '../../consts/period';
import { colorsAcumulados } from '../../consts/colors';
import {
  FlowInvestorsService,
  IForeignersTooltipsParams,
} from '../../flow-investors.service';
import { DATE_PICKER_CONFIGS } from '@shared/rocket-components/components/datepicker';
import { BusinessProfileService } from '@shared/components/business-profile/business-profile.service';
import { Subject, Subscription, debounceTime, delay, filter } from 'rxjs';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { THEME_TIMER } from '../../flow-investors.component';
import { CHART_COLORS } from '@shared/tiger-chart/colors';

@Component({
  selector: 'app-chart-foreigners',
  templateUrl: './chart-foreigners.component.html',
  styleUrls: ['./chart-foreigners.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartForeignersComponent implements OnChanges, OnDestroy {
  @ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef;

  @Input() containerWidth: number = 0;
  @Input() containerHeight: number = 0;
  @Input() updatePosition: boolean = false;
  @Input() public set data(data: any) {
    if (data) {
      this._data = data;
      this._mounted$.next();
    }
  }
  private _data!: any;
  private _mounted$ = new Subject<void>();
  private _mounted!: Subscription;
  @Input() public code: string = 'flow_b3';
  @Input() public title: string = '';

  public periodFields: any = periodFields;

  public periodsEvolution = periodsEvolution;
  public periodSelected: string = 'month';
  public periodSelectedLabel: string = 'Mensal';

  public refChart: string = randomId('CHART_EVOLUTION');
  scichartService!: ScichartService;
  baseChart!: TWebAssemblyChart;
  private _candleXAxis!: NumericAxis;
  public periodsSelector: any = periods;
  public colors: any = colorsAcumulados;
  public monthToFormate: any = DATE_PICKER_CONFIGS.months;
  private _theme$!: Subscription;
  private _containerSizes!: any;
  chartData: any = {
    'Futuros IBOV': [],
    'Futuros Dólar': [],
    B3: [],
  };

  public chatDataFields = {
    daily: {
      B3: 'delta_volume',
      'Futuros IBOV': 'delta_futuro',
      'Futuros Dólar': 'delta_futuro',
    },
    other_period: {
      B3: 'sum_delta_volume',
      'Futuros IBOV': 'delta_futuro',
      'Futuros Dólar': 'delta_futuro',
    },
  };

  public CHART_Y_AXIS: any = {
    'Futuros IBOV': null,
    'Futuros Dólar': null,
    B3: null,
  };

  public tooltipX = 0;
  public tooltipY = 0;
  public displayTooltip = false;
  public tooltipParams!: IForeignersTooltipsParams | null;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private stockChartService: StockChartService,
    private _flowInvestorsService: FlowInvestorsService,
    private cdr: ChangeDetectorRef,
    private businessProfileService: BusinessProfileService,
    private _themeService: ThemePreferencesService,
    private _elementRef: ElementRef
  ) {
    this.scichartService = new ScichartService(undefined, stockChartService);
    this._flowInvestorsService.hideTyper$
      .pipe(filter((id) => id !== null && this.baseChart !== undefined))
      .subscribe((id) => this.hideSerie(id));
    this._mounted = this._mounted$
      .pipe(debounceTime(250))
      .subscribe(() => this.mounted());
    this._theme$ = this._themeService
      .themeActiveObservable()
      .pipe(delay(THEME_TIMER))
      .subscribe((data) => {
        this.scichartService.changeThemeHandler(
          this.baseChart,
          data === 'dark-theme'
        );
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { updatePosition } = changes;
    if (updatePosition?.currentValue) this._getChartContainerInfos();
  }

  ngOnDestroy(): void {
    this._mounted && this._mounted.unsubscribe();
    this._theme$ && this._theme$.unsubscribe();
  }

  public changePeriod(event: any) {
    this.chartData = {
      'Futuros IBOV': [],
      'Futuros Dólar': [],
      B3: [],
    };
    this.periodSelected = event.id;
    this.periodSelectedLabel = event.label;
    this.cdr.detectChanges();
    this._mounted$.next();
  }

  private _initializeChart(): void {
    this.CHART_Y_AXIS = {
      'Futuros IBOV': null,
      'Futuros Dólar': null,
      B3: null,
    };

    this.scichartService.initSciChart(this.refChart).then((res) => {
      this.baseChart = res;
      this.baseChart.sciChartSurface.background = '#FFFFFF00';

      // UNIQUE X AXIS
      this._candleXAxis = new NumericAxis(this.baseChart.wasmContext, {
        labelStyle: {
          fontSize: 9,
          padding: new Thickness(20, 0, 0, 0),
        },
        drawMajorGridLines: false,
        drawMinorGridLines: false,
        drawMajorBands: false,
        drawMajorTickLines: false,
        drawMinorTickLines: false,
      });
      this._candleXAxis.labelProvider.formatCursorLabel = () => '';
      this._candleXAxis.labelProvider.formatLabel = (value: number) =>
        this._flowInvestorsService.formatDateXAxis(value, this.periodSelected);
      this.baseChart.sciChartSurface.xAxes.add(this._candleXAxis);
      this.baseChart.sciChartSurface.renderableSeries.clear();

      for (const key in this.CHART_Y_AXIS) {
        this.CHART_Y_AXIS[key] = this._buildChartYAxis(key);
      }

      this._buildChartData();
      this._getChartContainerInfos();

      // MODIFIERS
      this.baseChart.sciChartSurface.chartModifiers.add(
        this.businessProfileService.buildLegend(
          `legend-div-id_${this.refChart}`
        ),
        new RolloverModifier({
          showTooltip: false,
          showRolloverLine: false,
        }),
        new CursorModifier({
          showTooltip: true,
          tooltipContainerBackground: CHART_COLORS.NEUTRAL_STRONG,
          tooltipTextStroke: CHART_COLORS.NEUTRAL_SMOOTHEST,
          crosshairStroke: CHART_COLORS.NEUTRAL_MEDIUM,
          tooltipSvgTemplate: (seriesInfos: SeriesInfo[]) => {
            if (!seriesInfos || !seriesInfos.length) {
              this.displayTooltip = false;
              this.tooltipParams = null;
              this.cdr.detectChanges();
              return '<svg></svg>';
            }
            this.tooltipParams =
              this._flowInvestorsService.foreignersTooltip(seriesInfos);
            this.displayTooltip = true;
            return '<svg></svg>';
          },
        })
      );
      this.cdr.detectChanges();
      this.stockChartService.removeChartLoading$.next({
        remove: true,
        ref: this.refChart,
      });
    });
  }

  private _buildChartYAxis(id: string): NumericAxis {
    try {
      const yAxis = new NumericAxis(this.baseChart.wasmContext, {
        id,
        axisAlignment: id === 'B3' ? EAxisAlignment.Right : EAxisAlignment.Left,
        labelStyle: {
          fontSize: 9,
          alignment: ELabelAlignment.Right,
          padding: new Thickness(0, 15, 0, 0),
        },
        asyncLabels: true,
        drawMajorGridLines: true,
        drawMinorGridLines: true,
        drawMajorBands: false,
        drawMajorTickLines: false,
        drawMinorTickLines: false,
        lineSpacing: 1.5,
        isVisible: id === 'B3' || id === 'Futuros Dólar',
      });
      yAxis.labelProvider.formatLabel = (value: number) =>
        getVolumeText(this.locale, value);
      yAxis.labelProvider.formatCursorLabel = (value: number) =>
        getVolumeText(this.locale, value);
      this.baseChart.sciChartSurface.yAxes.add(yAxis);
      this.cdr.detectChanges();
      return yAxis;
    } catch (error) {
      console.error('Y_AXIS', error);
      this.cdr.detectChanges();
      return new NumericAxis(this.baseChart.wasmContext);
    }
  }

  private _buildChartData(): void {
    const fields: any =
      this.periodSelected === 'daily'
        ? this.chatDataFields.daily
        : this.chatDataFields.other_period;
    for (const key in fields) {
      const xValues: number[] = [];
      const yValues = this.chartData[key].map((item: any) => {
        xValues.push(this._getChartXValue(item?.id_point || item.dt_reference));
        return item && item[fields[key]] ? item[fields[key]] : 0;
      });
      this._addChartValues(key, xValues, yValues);
    }
  }

  private _addChartValues(
    yAxisId: string,
    xValues: number[],
    yValues: number[]
  ): void {
    try {
      const dataSeries = new XyDataSeries(this.baseChart.wasmContext, {
        xValues,
        yValues,
        containsNaN: false,
        isSorted: true,
        dataSeriesName: yAxisId,
      });
      const column = new FastColumnRenderableSeries(
        this.baseChart.wasmContext,
        {
          yAxisId: yAxisId === 'B3' ? 'B3' : 'Futuros Dólar',
          fill: this.colors[yAxisId],
          stroke: this.colors[yAxisId],
          strokeThickness: 0,
          dataSeries,
          onSelectedChanged: () => null,
          onHoveredChanged: () => null,
        }
      );
      this.baseChart.sciChartSurface.renderableSeries.add(column);
      this.cdr.detectChanges();
    } catch (error) {
      console.error('X_AXIS', error);
      this.cdr.detectChanges();
    }
  }

  private _getChartXValue(date: number): number {
    const dt = date.toString();
    if (this.periodSelected === 'month')
      return (
        new Date(`${dt.slice(0, 4)}-${dt.slice(4, 6)}-02`).getTime() / 1000
      );
    return (
      new Date(
        `${dt.slice(0, 4)}-${dt.slice(4, 6)}-${dt.slice(6, 8)}`
      ).getTime() / 1000
    );
  }

  private mounted() {
    const field = this._getPeriodField();

    const ibov = this._data[`ibov_${field}`];
    if (ibov) {
      Object.keys(ibov).forEach((item: any) => {
        const data = this._data[`ibov_${field}`][item].find(
          (filter: any) => filter?.cd_type_investor === 'Estrangeiros'
        );
        this.chartData['Futuros IBOV'].push({ ...data, dt_reference: item });
      });
    }

    const dolar = this._data[`dolar_${field}`];
    if (dolar) {
      Object.keys(dolar).forEach((item: any) => {
        const data = this._data[`dolar_${field}`][item].find(
          (filter: any) => filter?.cd_type_investor === 'Estrangeiros'
        );
        this.chartData['Futuros Dólar'].push({ ...data, dt_reference: item });
      });
    }

    const investor = this._data[`investor_chart_${this.periodSelected}`];
    if (investor) {
      Object.keys(investor).forEach((item: any) => {
        const infos = this._data[`investor_chart_${this.periodSelected}`][
          item
        ].find((filter: any) => filter?.cd_b3_investor_type === 'Estrangeiros');
        if (infos) this.chartData['B3'].push(infos);
      });
    }
    this._initializeChart();
  }

  private _getPeriodField(): string {
    if (this.periodSelected === 'week') return 'chart_semana';
    if (this.periodSelected === 'month') return 'chart_mes';
    return 'daily';
  }

  public hideSerie = (id: string) => {
    if (!id || !id.length) return;
    const tempIdCode = id.split('_');
    if (this.code === `${tempIdCode[0]}_${tempIdCode[1]}`) {
      const serie: IRenderableSeries =
        this.baseChart.sciChartSurface.renderableSeries.getById(id);
      serie.isVisible = !serie.isVisible;
    }
    this.cdr.detectChanges();
  };

  // TOOLTIP CONTROLLER
  private _getChartContainerInfos(): void {
    const chartContainer = this._elementRef.nativeElement.querySelector(
      `#${this.refChart}`
    );
    if (!chartContainer) {
      console.error('CHART_CONTAINER_NOT_FOUND');
      return;
    }
    this._containerSizes = chartContainer.getBoundingClientRect();
    this.cdr.detectChanges();
  }

  public onMouseMove(event: MouseEvent) {
    if (!this._containerSizes) return;
    const mouseX = event.clientX - this._containerSizes.left + 10;
    const mouseY = event.clientY - this._containerSizes.top;
    if (
      mouseX < 1 ||
      mouseY < 1 ||
      mouseX > this._containerSizes.width ||
      mouseY > this._containerSizes.height
    ) {
      return;
    }
    const limitReached = event.clientX + 250 >= window.innerWidth;
    let leftPosition = mouseX;
    if (limitReached) leftPosition = leftPosition - 220;
    this.tooltipX = leftPosition;
    this.tooltipY = event.offsetY + 80;
  }
}
