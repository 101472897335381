import { CHART_MODAL_CONFIGS_TAB_NAME } from '@shared/components/stock-chart/enum/stock-chart.enum';
import { INPUT, ITab } from '../interface/stock-chart-modal.interface';
import { TFooterType } from '@shared/tiger-chart/tiger-chart-footer/tiger-chart-footer.enum';
import {
  FROM_TYPES,
  INPUT_TYPES,
  TYPE_CHART_UPDATE,
} from '../enum/stock-chart-modal.enum';

export const TABS: ITab[] = [
  {
    title: 'Estilo',
    active: true,
    ref: CHART_MODAL_CONFIGS_TAB_NAME.ESTILO,
  },
  {
    title: 'Negociação',
    active: false,
    ref: CHART_MODAL_CONFIGS_TAB_NAME.NEGOCIACAO,
  },
  {
    title: 'Eventos',
    active: false,
    ref: CHART_MODAL_CONFIGS_TAB_NAME.EVENTOS,
  },
  {
    title: 'Atalhos',
    active: false,
    ref: CHART_MODAL_CONFIGS_TAB_NAME.ATALHOS,
  },
];

export const SHORTCUTS: INPUT[] = [
  {
    cod: TFooterType.CHART_BOOK,
    title: 'Book ou Volume x Preço',
    type: INPUT_TYPES.SELECT,
    options: [
      { cod: 'BOOK', label: 'Book' },
      { cod: 'VOL_PRICE', label: 'Volume x Preço' },
      { cod: 'NOTHING', label: 'Nenhum' },
    ],
    formControlName: 'showChartVolumeAtPrice',
    css: 'max-width-chart-book',
    chartUpdate: TYPE_CHART_UPDATE.BOOK_VOLUME_AT_PRICE,
    from: FROM_TYPES.configuration,
  },
  {
    cod: TFooterType.FAST_ORDER,
    title: 'Boleta rápida',
    type: INPUT_TYPES.CHECKBOX,
    formControlName: 'showFastOrder',
    chartUpdate: TYPE_CHART_UPDATE.SHOW_FAST_ORDER,
    from: FROM_TYPES.chart_fastOrder,
  },
  {
    cod: TFooterType.EXEC_ORDERS,
    title: 'Ordens Executadas',
    type: INPUT_TYPES.CHECKBOX,
    formControlName: 'showExecOrders',
    chartUpdate: TYPE_CHART_UPDATE.SHOW_EXEC_ORDER,
    from: FROM_TYPES.configuration,
    defaultValue: true,
    collapseChildren: true,
    collapseIsOpen: false,
    saveGlobalChartConfig: true,
    children: [{
      cod: TFooterType.EXEC_ORDERS_MARKER,
      title: 'Marcador de Ordem',
      type: INPUT_TYPES.CHECKBOX,
      formControlName: 'showOrderMarker',
      chartUpdate: TYPE_CHART_UPDATE.SHOW_ORDER_MARKER,
      defaultValue: true,
      from: FROM_TYPES.configuration,
      css: 'text-neutral-medium fs-5',
      cssDiv: 'border-0',
      saveGlobalChartConfig: true,
      isDisable: false
    },
    {
      cod: TFooterType.EXEC_ORDERS_FLAG,
      title: 'Lucro/Prejuízo',
      type: INPUT_TYPES.CHECKBOX,
      formControlName: 'showFlagOrders',
      chartUpdate: TYPE_CHART_UPDATE.SHOW_FLAG_ORDERS,
      defaultValue: true,
      from: FROM_TYPES.configuration,
      css: 'text-neutral-medium fs-5',
      cssDiv: 'border-0',
      saveGlobalChartConfig: true,
      isDisable: false
    },
    {
      cod: TFooterType.EXEC_ORDERS_OPEN_FLAG,
      title: 'Estimativa',
      type: INPUT_TYPES.CHECKBOX,
      formControlName: 'showOpenFlag',
      chartUpdate: TYPE_CHART_UPDATE.SHOW_OPEN_FLAG,
      defaultValue: true,
      from: FROM_TYPES.configuration,
      css: 'text-neutral-medium fs-5',
      saveGlobalChartConfig: true,
      isDisable: false
    }],
  },
  {
    cod: TFooterType.EVENTS,
    title: 'Eventos (Fato relevante, proventos e notícias)',
    type: INPUT_TYPES.CHECKBOX,
    formControlName: 'showEvents',
    chartUpdate: TYPE_CHART_UPDATE.SHOW_EVENTS,
    defaultValue: true,
    from: FROM_TYPES.chart,
  },
];
export const LABELS_QTTY_GROUP_STOCK = {
  title: 'Salvar quantidade por ativo',
  subTitle: 'Salvar a quantidade inserida para todos os componentes por ativo',
};
const fastOrderHint = `Use essa configuração para exibir o campo Estratégia na mini boleta. Assim será possível ter estratégias diferentes do geral.`;
export const NEGOTIATIONS: INPUT[] = [
  {
    chartUpdate: TYPE_CHART_UPDATE.QTTY_GROUP_STOCK,
    cod: TFooterType.QTTY_GROUP_STOCK,
    title: LABELS_QTTY_GROUP_STOCK.title,
    subTitle: LABELS_QTTY_GROUP_STOCK.subTitle,
    formControlName: 'saveQttyGroupStocks',
    type: INPUT_TYPES.CHECKBOX,
    notGlobal: true,
    from: FROM_TYPES.customPreferences,
  },
  {
    chartUpdate: TYPE_CHART_UPDATE.USE_SPLIT,
    cod: TFooterType.USE_SPLIT,
    title: 'Ordem fracionada (split)',
    subTitle: 'Ative ou desative o envio de ordens fracionadas',
    formControlName: 'useSplit',
    type: INPUT_TYPES.CHECKBOX,
    notGlobal: true,
    from: FROM_TYPES.customPreferences,
  },
  {
    chartUpdate: TYPE_CHART_UPDATE.FAST_ORDER_DAYTRADE_STRATEGY,
    cod: TFooterType.FAST_ORDER_DAYTRADE_STRATEGY,
    title: 'Exibir Estratégia na boleta do gráfico',
    subTitle: fastOrderHint,
    formControlName: 'showFastOrderDaytradeStrategy',
    type: INPUT_TYPES.CHECKBOX,
    from: FROM_TYPES.chart,
  },
  {
    chartUpdate: TYPE_CHART_UPDATE.TRADING_OFFSET,
    cod: TFooterType.TRADING_OFFSET,
    title: 'Chart Trading - Offset (ticks)',
    subTitle: `Usado para configurar as ordens stop via gráfico`,
    formControlName: 'chartTradingOffset',
    type: INPUT_TYPES.INPUT_COUNT,
    from: FROM_TYPES.chart,
    hint: `<p class="mb-3">O tick é usado para determinar a distância limite de execução em comparação com o preço da ordem enviada.</p>
    <p class="mb-1">Veja abaixo o valor de 1 tick para ativos a vista e futuros.</p>
    <p class="mb-1">Valor de 1 tick para:</p>
    <div class="text-left">
      <ul>
        <li>A vista (ex: PETR4) é R$ 0,01</li>
        <li>WINFUT é 5 pontos</li>
        <li>WDOFUT é 0,5 ponto</li>
      </ul>
    </div>`,
  },
];

export const EVENTS: INPUT[] = [
  {
    chartUpdate: TYPE_CHART_UPDATE.PRICE_CLOSE_TIMER,
    cod: TFooterType.PRICE_CLOSE_TIMER,
    title: 'Contagem regressiva para novo candle',
    formControlName: 'useTimerPriceClose',
    type: INPUT_TYPES.CHECKBOX,
    from: FROM_TYPES.configuration,
    saveGlobalChartConfig: true,
  },
  {
    chartUpdate: TYPE_CHART_UPDATE.AUCTION_EVENTS,
    cod: TFooterType.AUCTION_EVENTS,
    title: 'Informações de Leilão',
    formControlName: 'showAuctionChartEvent',
    type: INPUT_TYPES.CHECKBOX,
    from: FROM_TYPES.configuration,
    saveGlobalChartConfig: true,
  },
  {
    chartUpdate: TYPE_CHART_UPDATE.SHOW_MIN_MAX_VISIBLE,
    cod: TFooterType.SHOW_MIN_MAX_VISIBLE,
    title: 'Mínima e máxima do período visível',
    formControlName: 'showMinMaxVisible',
    type: INPUT_TYPES.CHECKBOX,
    from: FROM_TYPES.configuration,
    saveGlobalChartConfig: true,
  },
  {
    chartUpdate: TYPE_CHART_UPDATE.SHOW_BID_ASK,
    cod: TFooterType.SHOW_BID_ASK,
    title: 'Bid e Ask',
    subTitle:
      'Veja a oferta mais alta do lado de compra (bid) e a mais baixa de venda (ask) do book de ofertas',
    formControlName: 'showBidAsk',
    type: INPUT_TYPES.CHECKBOX,
    from: FROM_TYPES.configuration,
    saveGlobalChartConfig: true,
  },
  {
    chartUpdate: TYPE_CHART_UPDATE.SHOW_TRADING_CHANGE,
    cod: TFooterType.SHOW_TRADING_CHANGE,
    title: 'Mudança de dia, mês e ano',
    formControlName: 'showTradingChange',
    type: INPUT_TYPES.CHECKBOX,
    from: FROM_TYPES.configuration,
    saveGlobalChartConfig: true,
  },
];

export const STYLES: INPUT[] = [
  {
    chartUpdate: TYPE_CHART_UPDATE.SHOW_NAVIGATION_CONTROLS,
    cod: TFooterType.SHOW_NAVIGATION_CONTROLS,
    title: 'Controles de navegação',
    subTitle: 'Botões de navegação na parte inferior do gráfico',
    formControlName: 'navigationControls',
    type: INPUT_TYPES.SELECT,
    css: 'max-width-chart-navigation',
    options: [
      { cod: 'ALWAYS_SHOW', label: 'Sempre mostrar' },
      { cod: 'ONLY_HOVER', label: 'Mostrar ao passar mouse' },
      { cod: 'NOTHING', label: 'Não mostrar' },
    ],
    from: FROM_TYPES.configuration,
    saveGlobalChartConfig: true,
  },
  {
    divTitle: 'Fundo do gráfico',
    chartUpdate: TYPE_CHART_UPDATE.GRID_LINES,
    cod: TFooterType.GRID_LINES,
    title: 'Grid vertical',
    formControlName: 'showXGridLines',
    type: INPUT_TYPES.CHECKBOX,
    from: FROM_TYPES.configuration,
    saveGlobalChartConfig: true,
    cssDiv: 'w-50 pr-3',
    css: 'pt-2',
  },
  {
    chartUpdate: TYPE_CHART_UPDATE.GRID_LINES,
    cod: TFooterType.GRID_LINES,
    title: 'Grid horizontal',
    formControlName: 'showYGridLines',
    type: INPUT_TYPES.CHECKBOX,
    from: FROM_TYPES.configuration,
    saveGlobalChartConfig: true,
    cssDiv: 'w-50 mt-5',
    css: 'pt-2',
  },
  {
    chartUpdate: TYPE_CHART_UPDATE.WATER_MARK,
    cod: TFooterType.WATER_MARK,
    title: "Marca d'água",
    formControlName: 'showWatermark',
    type: INPUT_TYPES.CHECKBOX,
    from: FROM_TYPES.configuration,
    saveGlobalChartConfig: true,
    cssDiv: 'w-50 pr-3',
    css: 'pt-2',
    defaultValue: true,
    children: [{
      chartUpdate: TYPE_CHART_UPDATE.WATER_MARK_OPACITY,
      cod: TFooterType.WATER_MARK,
      title: 'Opacidade',
      formControlName: 'watermarkOpacity',
      type: INPUT_TYPES.INPUT_COUNT,
      from: FROM_TYPES.configuration,
      defaultValue: 30,
      saveGlobalChartConfig: true,
      cssDiv: 'w-50 opacity-watermark',
      css: 'pt-2',
      hint: `Opacidade da marca d'água`,
      suffix: '%',
      incrementValue: 10,
      max: 100,
      isDisable: false,
    }],
  },
];