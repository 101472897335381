import { ColDef } from 'ag-grid-community';
import { financialFormatterFunction } from 'src/app/utils/cell-format.functions';
import { newBigValueFormatter } from 'src/app/utils/utils.functions';
import { StatusStockRendererComponent } from '../../stock-table-rendlers/status-stock/status-stock-renderer.component';
import { TableStockNameComponent } from '../../stock-table-rendlers/table-tooltip/table-tooltip.component';
import { formatterNumber } from '@shared/rocket-components/utils';

export const MARKET_MOVER_HEADER: any = {
  cd_stock: {
    hide: false,
    width: 70,
  },
  preco_ultimo: {
    hide: false,
    width: 60,
  },
  variacao: {
    hide: false,
    width: 76,
  },
  volume: {
    hide: false,
    width: 80,
  },
};

export const MARKET_MOVERS_HEADERS_CONFIG = (
  variationFieldLabel: string = 'Intradia'
): Array<ColDef> => [
  {
    headerName: '',
    field: 'situacao',
    minWidth: 32,
    maxWidth: 32,
    width: 32,
    lockPosition: 'left',
    pinned: 'left',
    cellRenderer: StatusStockRendererComponent,
    valueGetter: (params) => params.data.situacao,
  },
  {
    headerName: 'Ativo',
    width: 70,
    field: 'cd_stock',
    cellClassRules: {
      'text-brand-primary': (params) => params.data.isSelected,
    },
    cellRenderer: TableStockNameComponent,
    valueGetter: (params) => {
      return [params.data.cd_stock, params.data.nm_company];
    },
  },
  {
    headerName: 'Atual',
    width: 60,
    field: 'preco_ultimo',
    headerComponentParams: ['Preços', 'preco_ultimo'],
    type: 'numericColumn',
    valueFormatter: financialFormatterFunction,
  },
  {
    headerName: variationFieldLabel,
    field: 'variacao',
    headerComponentParams: ['Variações', 'variacao_dia', 'period_variation'],
    width: 76,
    minWidth: 76,
    cellClass: ['tag', 'intraday-pill', 'text-center'],
    enableCellChangeFlash: true,
    valueFormatter: (params) => formatterNumber(parseFloat(params.value)) + '%',
    cellClassRules: {
      red: (params) => params.data.arrow_hex === '#D30D48',
      'smooth-red': (params) => params.data.arrow_hex === '#E66F87',
      green: (params) => params.data.arrow_hex === '#0FEF83',
      'smooth-green': (params) => params.data.arrow_hex === '#86FFC5',
      neutral: (params) => params.data.arrow_hex === '#0056FF',
      auction: (params) => params.data.arrow_hex === '#F0960F',
    },
  },
  {
    headerName: 'Volume',
    width: 80,
    field: 'volume',
    headerComponentParams: ['Volume', 'volume'],
    type: 'numericColumn',
    valueFormatter: (params: any) => newBigValueFormatter(params.value),
  },
];
