import { Injectable } from '@angular/core';
import { StockListService } from '@shared/services/api/trademap/V10/stock-list.service';
import { IAllStockListSimple, IListStockDB } from 'src/app/core/interface';
import { ListStocksService } from '../list-stocks/list-stocks.service';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProcessListStockService {
  private _loadedStockLists = new BehaviorSubject(false);
  private _haveSomeProcessActive = false;

  constructor(
    private stockListService: StockListService,
    private listStocksService: ListStocksService
  ) {}

  checkExistLists = (): Observable<boolean> => {
    if (this._loadedStockLists.getValue() || this._haveSomeProcessActive) {
      return this._loadedStockLists;
    }
    this._haveSomeProcessActive = true;
    this._verifyStockLists();
    return this._loadedStockLists;
  };

  private _verifyStockLists(): void {
    if (this.listStocksService.allStockLists) {
      this._loadedStockLists.next(true);
      return;
    }
    this._getForUpdateLists();
  }

  private _getForUpdateLists(): void {
    this.stockListService
      .getAllStockListSimple()
      .pipe(
        map((list: IAllStockListSimple[]) => {
          const listsHash: any = {};
          for (let i = 0; i < list.length; i++) {
            const newList = { ...this._newListStock(list[i]), id: i };
            listsHash[`${list[i].id_stock_list}`] = newList;
          }
          return listsHash;
        })
      )
      .subscribe((listsHash: any) => {
        this.listStocksService.insertAllStockList(listsHash);
        this._loadedStockLists.next(true);
      });
  }

  private _newListStock(item: IAllStockListSimple): IListStockDB {
    return {
      idStockList: item.id_stock_list,
      list: item,
      configList: null,
      stocks: [],
      isNotListPersonal: false,
    };
  }

  public getStockListAfterCreateNewOne(): void {
    this._getForUpdateLists();
  }
}
