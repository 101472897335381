import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlaRemoveHostDirective } from './remove-host.directive';

@NgModule({
  declarations: [FlaRemoveHostDirective],
  imports: [CommonModule],
  exports: [FlaRemoveHostDirective],
  providers: [],
})
export class FlaRemoveHostModule {}
