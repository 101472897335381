<div [ngClass]="css">
  <rocket-button
    type="icon"
    css="bg-feedback-error text-black round-sm"
    [isDisabled]="disableCancelAllPositions"
    (rocketClick)="resetBtn()"
    tooltip="Zerar todas as posições"
  >
    <fla-icon name="lock_reset" size="icon-size-micro"></fla-icon>
  </rocket-button>
</div>
