import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';

export const RISK_MANAGEMENT_MODAL: any = {
  cod: 'RM',
  label: 'Gerenciamento de risco',
  name: 'Gerenciamento de risco',
  id: COMPONENTS_NAMES_ENUM.RISK_MANAGEMENT,
  icon: 'admin_panel_settings',
  isModal: true,
  noPublished: false,
  noPublishedText:
    'A ferramenta <b>Gerenciamento de risco</b> esta disponível somente para o modo DayTrade.',
};
