<workspace-doc-header
  css="w-100 d-flex header-wrapper border-bottom"
  [item]="component"
>
  <fla-workspace-doc-header-item [enableContent]="true">
    <app-rt-list-selector
      *ngIf="heatmapConfigsSignal() && component"
      #listSelector
      [refComponent]="refComponent"
      [componentId]="component.id"
      [linked]="linked"
      [initialListId]="heatmapConfigsSignal().idList"
      [useSourceListWithInitialList]="
        heatmapConfigsSignal().source !== undefined
      "
      (changeOpenListPersonal)="changeOpenListPersonal.emit($event)"
      (withoutPersonalList)="displayWithoutPersonalListMessage.emit($event)"
      (handlerListSelected)="handleListSelected($event, false)"
      (changeNotListPersonal)="handleListSelected($event, true)"
      (selectedGhostList)="handleGhostListSelected()"
    >
      <hr />
      <ng-container *ngFor="let list of sourceLists">
        <button
          class="dropdown-item bg-transparent fs-6"
          (click)="selectSource(list.type)"
          [ngClass]="{
            'text-brand-primary': currentSource === list.type
          }"
        >
          {{ list.name }}
        </button>
      </ng-container>
    </app-rt-list-selector>
  </fla-workspace-doc-header-item>
  <fla-workspace-doc-header-item [enableContent]="true">
    <fla-icon
      name="aspect_ratio"
      size="icon-size-micro"
      css="ml-2 text-neutral-medium"
    ></fla-icon>
    <app-dropdown>
      <a
        type="button"
        class="nav-link pl-1 p-2 lh-sm round-top dropdown-toggle"
        appDropdownButton
        [rtDropnAutoClose]="false"
        [rtDropnClose]="dropnAutoClose"
        title="Definir Tamanho e Ordenação"
      >
        {{ orderByLabel }}
      </a>
      <div class="list-max-height">
        <a
          class="dropdown-item"
          type="button"
          (click)="setOrderBy('variation')"
        >
          Variação 1 Dia
        </a>
      </div>
      <div
        *ngIf="!heatmapConfigsSignal().isNotListPersonal"
        class="list-max-height"
      >
        <a class="dropdown-item" type="button" (click)="setOrderBy('mcap')">
          MCAP
        </a>
        <hr />
      </div>
      <div
        *ngIf="
          heatmapConfigsSignal().isNotListPersonal &&
          !heatmapConfigsSignal().source
        "
        class="list-max-height"
      >
        <a class="dropdown-item" type="button" (click)="setOrderBy('volume')">
          Volume 1 Dia
        </a>
      </div>
      <div *ngIf="!heatmapConfigsSignal().isNotListPersonal">
        <ng-template *ngTemplateOutlet="volumes"></ng-template>
      </div>
    </app-dropdown>
  </fla-workspace-doc-header-item>
  <fla-workspace-doc-header-item
    [enableContent]="true"
    *ngIf="!heatmapConfigsSignal().isNotListPersonal"
  >
    <img
      src="assets/svg/colored-squares.svg"
      alt="Seletor de períodos"
      class="period-icon ml-2"
    />
    <app-dropdown>
      <a
        *ngIf="heatmapConfigsSignal() && heatmapConfigsSignal()!.period"
        type="button"
        class="nav-link pl-1 p-2 lh-sm round-top dropdown-toggle"
        appDropdownButton
        [rtDropnClose]="dropnAutoClose"
        title="Definir Variação"
      >
        {{ periodsValues[heatmapConfigsSignal()!.period] }}
      </a>
      <div class="list-max-height" *ngFor="let key of periodsKeys">
        <a
          class="dropdown-item"
          type="button"
          (click)="setPeriod(key)"
          *ngIf="
            heatmapConfigsSignal().source ||
            (key !== 'AFTERMARKET' && key !== 'GAPOPENING')
          "
        >
          {{ periodsValues[key] }}
        </a>
        <hr *ngIf="key === 'THISYEAR'" />
      </div>
    </app-dropdown>
  </fla-workspace-doc-header-item>
  <fla-workspace-doc-header-item
    [enableContent]="true"
    *ngIf="listSelected?.isSectors"
    class="w-100 d-flex justify-content-end"
  >
    <rocket-button
      [isSmall]="true"
      type="icon"
      label="Atualizar"
      tooltip="Atualizar"
      tooltipPosition="bottom"
      (rocketClick)="selectSource(heatmapConfigsSignal().source)"
    >
      <fla-icon name="refresh" css="text-brand-primary"></fla-icon>
    </rocket-button>
  </fla-workspace-doc-header-item>
</workspace-doc-header>

<ng-template #volumes>
  <a
    class="dropdown-item arrow position-relative pr-0"
    data-bs-toggle="collapse"
    href="#volumesList"
    role="button"
    aria-expanded="false"
    aria-controls="volumesList"
  >
    Volumes
  </a>
  <div class="collapse" id="volumesList">
    <a class="dropdown-item" type="button" (click)="setOrderBy('volume')">
      Volume 1 Dia
    </a>
    <a
      class="dropdown-item"
      type="button"
      (click)="setOrderBy('yesterday_volume')"
    >
      Volume Ontem
    </a>
    <a class="dropdown-item" type="button" (click)="setOrderBy('one_week')">
      Volume 1 Semana
    </a>
    <a class="dropdown-item" type="button" (click)="setOrderBy('one_month')">
      Volume 1 Mês
    </a>
    <a class="dropdown-item" type="button" (click)="setOrderBy('one_year')">
      Volume 1 Ano
    </a>
  </div>
</ng-template>
