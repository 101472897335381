import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnChanges,
  Output,
} from '@angular/core';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { TIGER_INDICATORS_ENUM } from '../../enum/tiger-chart.enum';
import {
  BaseIndicator,
  ITigerChartModalConfigsClose,
  TTigerChartIndicatorParameter,
  TTigerChartIndicatorRenderSeriesConfig,
  TTigerChartIndicatorSettingsTabs,
} from '../indicators.types';
import { SaveConfigurationOptions } from './settings-modal.interfaces';
import { SettingsModalService } from './settings-modal.service';
import {
  RocketModalRef,
  RocketModalService,
  ROCKET_MODAL_DATA,
} from '@shared/rocket-components/components/index';
import { deepClone } from '@shared/rocket-components/utils';

@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss'],
})
export class SettingsModalComponent
  extends RocketModalRef<ITigerChartModalConfigsClose>
  implements OnChanges, AfterViewInit
{
  @Output() closeHandler = new EventEmitter();
  @Output() addIndicator = new EventEmitter<BaseIndicator>();
  @Output() removeIndicator = new EventEmitter<TIGER_INDICATORS_ENUM>();
  tabNameEnum = TTigerChartIndicatorSettingsTabs;
  tabName: TTigerChartIndicatorSettingsTabs =
    TTigerChartIndicatorSettingsTabs.SETTINGS;
  selectedSaveConfiguration!: SaveConfigurationOptions;
  defaultStyleConfig: TTigerChartIndicatorRenderSeriesConfig[] = [];
  defaultConfig: TTigerChartIndicatorParameter[] = [];
  forceAbreviation: any = {
    [TIGER_INDICATORS_ENUM.MOMENTUM]: 'Mom',
    [TIGER_INDICATORS_ENUM.STOCHASTIC]: 'Stoch',
  };
  @HostListener('document:keydown.escape')
  @HostListener('window:keydown.esc')
  onKeyScapePress() {
    this.closeModal();
  }
  get show() {
    if (!this.data.indicator) return false;

    return true;
  }

  get indicatorAbbreviation() {
    if (!this.data.indicator) return '';

    const index = Object.values(TIGER_INDICATORS_ENUM).findIndex(
      (value) => value == this.data.indicator?.type
    );

    const searchText: any = Object.values(TIGER_INDICATORS_ENUM)[index];
    if (searchText in this.forceAbreviation) {
      return this.forceAbreviation[searchText];
    }

    const key = Object.keys(TIGER_INDICATORS_ENUM)[index];
    const splitedKey = key.split('_');
    return splitedKey.map((word) => word[0]).join('');
  }

  constructor(
    private _cdr: ChangeDetectorRef,
    service: RocketModalService,
    @Inject(ROCKET_MODAL_DATA)
    public data: {
      indicator: BaseIndicator;
      baseChart: TWebAssemblyChart;
      styleModal: string;
      refComponent: string;
    },
    private settingsModalService: SettingsModalService
  ) {
    super(service);
  }

  ngOnChanges(): void {
    this._cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.settingsModalService.blockBackspace$.next(true);
    this.defaultStyleConfig = deepClone(this.data.indicator.renderSeriesConfig);
    for (let i = 0; i < this.data.indicator.settings.length; i++) {
      const setting = this.data.indicator.settings[i];
      const property = setting.property;
      setting.valueSelected =
        this.data.indicator[property as keyof typeof this.data.indicator];
      this.defaultConfig.push(deepClone(setting));
    }

    this._cdr.detectChanges();
  }

  tabChanges(value: string) {
    this.tabName = value as TTigerChartIndicatorSettingsTabs;
  }

  updateConfigUser(e: SaveConfigurationOptions) {
    this.selectedSaveConfiguration = e;
    this.settingsModalService.saveIndicatorConfig(
      e,
      this.data.indicator,
      this.data.refComponent
    );
  }

  closeModal() {
    this.settingsModalService.blockBackspace$.next(false);
    for (let i = 0; i < this.defaultStyleConfig.length; i++) {
      const config = this.defaultStyleConfig[i];
      this.data.indicator.updateStyles(this.data.baseChart, config);
    }
    for (let i = 0; i < this.defaultConfig.length; i++) {
      const config = this.defaultConfig[i];
      const property = config.property;
      this.data.indicator.setValue(
        property as keyof typeof this.data.indicator,
        config.valueSelected
      );
    }
    this.close();
  }

  saveChangeIndicator() {
    if (this.selectedSaveConfiguration && this.selectedSaveConfiguration.cod) {
      this.updateConfigUser(this.selectedSaveConfiguration);
    }
    this.saveConfig();
    this.close();
  }

  private saveConfig() {
    for (let i = 0; i < this.data.indicator.renderSeriesConfig.length; i++) {
      const style = this.data.indicator.renderSeriesConfig[i];
      const active = style.active;
      const propertyColor = style.propertyColor;
      const showTypeLine = style.showTypeLine;
      const propertyThickness = style.propertyThickness;
      const propertyVisible = style.propertyVisible;
      const lineTypeId = style.lineTypeId;
      const color = style.color;
      const thickness = style.thickness;
      this.settingsModalService.updateTemporaryConfig(
        this.data.refComponent,
        this.data.indicator,
        undefined,
        undefined,
        {
          lineTypeId,
          color,
          thickness,
          active,
          propertyColor,
          showTypeLine,
          propertyThickness,
          propertyVisible,
        }
      );
    }
    for (let j = 0; j < this.data.indicator.settings.length; j++) {
      const setting = this.data.indicator.settings[j];
      this.settingsModalService.updateTemporaryConfig(
        this.data.refComponent,
        this.data.indicator,
        setting.valueSelected,
        setting.property
      );
    }
  }
}
