import {
  SWING_REPLACE_KEY,
  DAY_TRADE_REPLACE_KEY,
} from '@core/layout/header/constants/daytrade.constant';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import { isDoubleStartStop } from '@shared/constants/general.contant';
import { ORDER_REJE_STATUS_DIC } from '@shared/dictionary/order.dictionary';
import { deepClone } from '@shared/rocket-components/utils';
import { ColDef } from 'ag-grid-community';
import {
  dateOrTimeFormatter,
  financialFormatDoublePrice,
  financialFormatterFunctionPriceDouble,
  tooltipDoublePrice,
  financialFormatDoubleTrigger,
  financialFormatterFunctionStopDouble,
  tooltipDoubleTrigger,
  verifyDirectionStopDouble,
  verifyResetPosition,
  financialFormatterFunction,
} from 'src/app/utils/cell-format.functions';
import {
  SIDE,
  SIDE_TEXT_COLOR,
  SIDE_CHARACTER,
} from '../../constants/orders-history.const';
import { CustomTooltipComponent } from '../custom-tooltip/custom-tooltip.component';
import { GenericFilterComponent } from '../generic-filter/generic-filter.component';
import {
  checkExecutedPriceSameSend,
  getOrderTypeRenderer,
  getValidadeLabel,
  proccessQttyByTotal,
  qtdeFormatter,
  setColorBackgroundByStatusOrder,
} from './coldef-order';
import { FILTER_OPTIONS_ORDER_GRID } from './filter-options';

export const HEADERS_ORDERS: Array<ColDef> = [
  {
    headerName: 'Data',
    field: 'dh_creation_time',
    headerTooltip: 'Data/Hora',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    valueFormatter: (params: any) => dateOrTimeFormatter(params.value),
    filter: GenericFilterComponent,
    filterParams: {
      ...deepClone(FILTER_OPTIONS_ORDER_GRID.DATE),
      filterDefinitions: {
        ...deepClone(FILTER_OPTIONS_ORDER_GRID.DATE.filterDefinitions),
        replaceField: 'dh_creation_time_filter',
        sortBy: 'desc',
        todayText: 'Hoje',
        yesterdayText: 'Ontem',
      },
    },
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Data/Hora' },
    headerComponentParams: ['', 'dh_creation_time'],
    tooltipValueGetter: (params: any) =>
      dateOrTimeFormatter(params.value, 'time'),
  },
  {
    headerName: 'Ativo',
    field: 'cd_stock',
    headerTooltip: 'Ativo',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    filter: GenericFilterComponent,
    filterParams: deepClone(FILTER_OPTIONS_ORDER_GRID.TEXT),
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Ativo' },
    cellClassRules: {
      'text-brand-primary': (params) => params.data.isSelected,
    },
    headerComponentParams: ['', 'stock_name'],
  },
  {
    headerName: 'L',
    field: 'side',
    headerTooltip: 'Lado',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    tooltipValueGetter: (params: any) => SIDE[params.value],
    cellClass: (params: any) => SIDE_TEXT_COLOR[params.value],
    valueFormatter: (params: any) => SIDE_CHARACTER[params.value],
    filter: GenericFilterComponent,
    filterParams: FILTER_OPTIONS_ORDER_GRID.SIDE,
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Lado' },
    headerComponentParams: ['', 'side'],
  },
  {
    headerName: 'Qtd',
    field: 'qtty',
    headerTooltip: 'Quantidade',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    type: 'numericColumn',
    valueFormatter: qtdeFormatter,
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Quantidade' },
    headerComponentParams: ['', 'qtty'],
  },
  {
    headerName: 'Preço',
    field: 'price',
    headerTooltip: 'Preço da Ordem',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    type: 'numericColumn',
    headerClass: 'ag-right-aligned-header',
    cellRenderer: (params: any) => {
      if (isDoubleStartStop(params.data.cd_order_type)) {
        return financialFormatDoublePrice(params.data);
      }
      return financialFormatterFunctionPriceDouble(params);
    },
    cellClass: (params: any) =>
      checkExecutedPriceSameSend(
        params.value,
        params.data.price_average,
        params.data.cd_order_status
      ),
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Preço da Ordem' },
    tooltipValueGetter: (params: any) =>
      isDoubleStartStop(params.data.cd_order_type)
        ? tooltipDoublePrice(params.data)
        : '',
    headerComponentParams: ['', 'price'],
  },
  {
    headerName: 'Stop',
    field: 'price_stop',
    headerTooltip: 'Preço Stop',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    type: 'numericColumn',
    headerClass: 'ag-right-aligned-header',
    cellRenderer: (params: any) => {
      if (isDoubleStartStop(params.data.cd_order_type)) {
        return financialFormatDoubleTrigger(params.data);
      }
      return financialFormatterFunctionStopDouble(params);
    },
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Preço Stop' },
    tooltipValueGetter: (params: any) =>
      isDoubleStartStop(params.data.cd_order_type)
        ? tooltipDoubleTrigger(params.data)
        : '',
    headerComponentParams: ['', 'price_stop'],
  },
  {
    headerName: 'Sit.',
    field: 'ds_order_status',
    headerTooltip: 'Situação',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    cellStyle: (params) => setColorBackgroundByStatusOrder(params.data),
    cellRenderer: (params: any) =>
      `${params.value} ${
        ORDER_REJE_STATUS_DIC.has(params.data.cd_order_status)
          ? '<i class="icons icon-size-micro position-absolute right-0 fs-6 p-1"> info </i>'
          : ''
      } `,
    tooltipValueGetter: (params: any) =>
      params.data.ds_text
        ? params.data.ds_text
        : params.data.ds_order_reject_reason,
    filter: GenericFilterComponent,
    filterParams: deepClone(FILTER_OPTIONS_ORDER_GRID.TEXT),
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Situação' },
    headerComponentParams: ['', 'ds_order_status'],
  },
  {
    headerName: 'Tipo',
    field: 'ds_order_type',
    headerTooltip: 'Tipo',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    valueGetter: (params) => {
      return [params.data.ds_order_type, params.data.cd_order_status];
    },
    cellRenderer: (params: any) => {
      return getOrderTypeRenderer(params);
    },
    filter: GenericFilterComponent,
    filterParams: deepClone(FILTER_OPTIONS_ORDER_GRID.TEXT),
    tooltipValueGetter: (params: any) => {
      return verifyDirectionStopDouble(params.data) == 'GAIN'
        ? '<span> Ordem executada pelo <span class="text-hint-gain">Gain</span></span>'
        : verifyDirectionStopDouble(params.data) == 'LOSS'
        ? '<span>Ordem executada pelo <span class="text-hint-loss">Loss</span></span>'
        : verifyResetPosition(params.data)
        ? '<span>Ordem de <span class="text-feedback-warning">zeragem</span> executada</span>'
        : '';
    },
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Tipo' },
    headerComponentParams: ['', 'ds_order_type'],
  },
  {
    headerName: 'Exe/Tot',
    field: 'qtty_executed',
    headerTooltip: 'Quantidade Executada / Total',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    type: 'numericColumn',
    cellRenderer: (params: any) => proccessQttyByTotal(params.data),
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Quantidade Executada / Total' },
    headerComponentParams: ['', 'qtty_executed'],
  },
  {
    headerName: 'Exe',
    field: 'price_average',
    headerTooltip: 'Preço Executado',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    type: 'numericColumn',
    valueFormatter: financialFormatterFunction,
    cellClass: (params: any) =>
      checkExecutedPriceSameSend(
        params.value,
        params.data.price,
        params.data.cd_order_status
      ),
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Preço Executado' },
    headerComponentParams: ['', 'price_average'],
  },
  {
    headerName: 'V',
    field: 'ds_order_time_enforce',
    headerTooltip: 'Validade',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    tooltipValueGetter: (params: any) => {
      let tooltip = '';
      if (params.value == '1' || params.value == 'Até cancelar') {
        tooltip = 'Até cancelar';
      }
      return tooltip;
    },
    valueFormatter: (params: any) => getValidadeLabel(params),
    filter: GenericFilterComponent,
    filterParams: {
      ...deepClone(FILTER_OPTIONS_ORDER_GRID.TEXT),
      filterDefinitions: {
        ...deepClone(FILTER_OPTIONS_ORDER_GRID.TEXT.filterDefinitions),
        filterFormatterActive: true,
      },
    },
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Validade' },
    headerComponentParams: ['', 'ds_order_time_enforce'],
  },
  {
    headerName: 'Modo',
    field: 'is_day_trade',
    headerTooltip: DaytradeService.getTextWithTradeMode(
      `${SWING_REPLACE_KEY} ou ${DAY_TRADE_REPLACE_KEY}`
    ),
    sortingOrder: ['asc', 'desc', null],
    cellClass: ['text-center'],
    sortable: true,
    valueFormatter: (params: any) =>
      DaytradeService.getTextWithTradeMode(
        params.data.is_day_trade ? DAY_TRADE_REPLACE_KEY : SWING_REPLACE_KEY
      ),
    //cellRenderer: (params: any) => checkIsDayTrade(params?.value),
    tooltipComponent: CustomTooltipComponent,
    tooltipValueGetter: (params) => {
      const label = DaytradeService.getTextWithTradeMode(
        params.data.is_day_trade ? DAY_TRADE_REPLACE_KEY : SWING_REPLACE_KEY
      );
      return label;
    },
    headerComponentParams: ['', 'is_day_trade'],
    //filter: GenericFilterComponent,
    //filterParams: deepClone(FILTER_OPTIONS_ORDER_GRID.YESORNOT),
  },
];
