import { IXyDataSeriesOptions, TSciChart, XyDataSeries } from 'scichart';

export class XyDataSeriesRocket extends XyDataSeries {
  constructor(webAssemblyContext: TSciChart, options?: IXyDataSeriesOptions) {
    super(webAssemblyContext, options);
  }

  override findIndex(xValue: number): number {
    let index = -1;
    for (let i = 0; i < this.xValues.size(); i++) {
      if (xValue == this.xValues.get(i)) {
        index = i;
      }
    }
    return index;
  }
}
