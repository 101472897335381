import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ISearchStock, IStockIndex } from '@core/interface';
import { Dictionary } from '@core/models';
import { MoversChannel } from '@shared/channel/movers.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { RocketModalService } from '@shared/rocket-components/components';

import { Subject, Subscription, debounceTime, takeUntil } from 'rxjs';
import { formatByTick } from 'src/app/utils/utils.functions';
import { IndexesModalComponent } from '../indexes-modal/indexes-modal.component';
import { MarketSummaryService } from '../../market-summary.service';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { AUTH_LOCAL_KEYS } from '@shared/services/core/const/auth_util.const';
import { IndexChange } from '../../types';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { IMoversData } from '@shared/components/stock-table/interfaces/stock-table.interfaces';
import {
  DYNAMIC_LISTS,
  SORT_MOVERS_ROWS,
} from '@shared/components/stock-table/constants/stock-table.constants';
import { RocketStreamRead } from '@shared/channel/rx-event';

@Component({
  selector: 'app-high-low-stocks',
  templateUrl: './high-low-stocks.component.html',
  styleUrls: ['./high-low-stocks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HighLowStocksComponent
  extends ReadStreamBase
  implements OnInit, OnDestroy
{
  @Input() refComponent!: string;
  @Input() linked!: boolean;

  public areaExpanded: boolean = false;
  public globalCdStock: string = '';
  stockListAltas: Dictionary<any> = new Dictionary();
  stockListBaixas: Dictionary<any> = new Dictionary();
  selectedIndex: IStockIndex = {
    cd_stock: 'IBOV',
    id_exchange: 1,
    id_stock: 0,
    nm_index: 'Bovespa',
  };

  private destroy$ = new Subject<void>();
  private _moversSortSubject = new Subject<void>();
  private _indexChangeSubscription!: Subscription;
  private _moversParams!: SubscribeParam | undefined;
  private COMMAND_ADD = 'ADD';
  private COMMAND_UPDATE = 'UPDATE';
  private COMMAND_DELETE = 'DELETE';
  private _typesMovers: string[] = ['ALTA.IBOV', 'BAIXA.IBOV'];
  private _loadedHighStocks = false;
  private _loadedLowerStocks = false;
  private moversStreamRead!: RocketStreamRead;

  constructor(
    private _moversChannel: MoversChannel,
    private _cdr: ChangeDetectorRef,
    private _modalService: RocketModalService,
    private _marketSummaryService: MarketSummaryService,
    private _customPreferencesService: CustomPreferencesService,
    private _globalStock: GlobalSelectedStockSubscription
  ) {
    super();
    this._moversEvents();
    this._sortMoversHandler();
    this._globalStock.onGlobalStockChange().then(async (data) => {
      this.readStream(data.stream, (stock: ISearchStock) => {
        this.globalCdStock = stock.cd_stock;
      });
    });
  }

  ngOnInit() {
    this.globalCdStock = this._globalStock.getGlobalStockSelected().cd_stock;
    this._loadCustomPreferences();
    this._subscribeMovers();
    this._subscribeIndexChange();
  }

  ngOnDestroy() {
    this._unSubscribeMovers();
    this._indexChangeSubscription &&
      this._indexChangeSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
    this.moversStreamRead.close();
  }

  openIndexModal() {
    this._cdr.detectChanges();
    this._modalService.open(IndexesModalComponent, {
      centered: true,
      backdrop: true,
      keyboard: false,
      scrollable: false,
      data: {
        selectedIndex: this.selectedIndex,
        component: this.refComponent,
        linked: this.linked,
      },
    });
  }

  private _subscribeMovers() {
    this._typesMovers = [
      `ALTA.${this.selectedIndex.cd_stock}`,
      `BAIXA.${this.selectedIndex.cd_stock}`,
    ];
    this._moversParams = {
      itemsArray: this._typesMovers,
      header: this.refComponent,
    };
    this._moversChannel.subscribe(this._moversParams);
  }

  private _moversEvents = async () => {
    this.moversStreamRead = await this._moversChannel.readEvents();
    this.moversStreamRead.snapshot(this._typesMovers);
    this.readStream(this.moversStreamRead.stream, this._channelHandlerMovers);
  };

  private _unSubscribeMovers() {
    if (this._moversParams) {
      this._loadedHighStocks = false;
      this._loadedLowerStocks = false;
      this._moversChannel.unsubscribe(this._moversParams);
      this._moversParams = undefined;
    }
  }

  private _subscribeIndexChange() {
    this._indexChangeSubscription =
      this._marketSummaryService.selectedIndex$.subscribe(
        (data: IndexChange) => {
          if (
            (!this.linked || !data.linked) &&
            data.component !== this.refComponent
          )
            return;

          this.selectedIndex = data.index;
          this._unSubscribeMovers();
          this.stockListAltas.clear();
          this.stockListBaixas.clear();
          this._subscribeMovers();
          this.moversStreamRead.snapshot(this._typesMovers);
          this._cdr.detectChanges();
          this._customPreferencesService.customPreference = {
            key: AUTH_LOCAL_KEYS.MARKET_SUMMARY_CONFIG,
            value: JSON.stringify({
              selectedIndex: this.selectedIndex,
            }),
          };
        }
      );
  }

  private _channelHandlerMovers = (payload: Map<string, IMoversData[]>) => {
    payload.forEach((data, key) => {
      if (!this._moversParams?.itemsArray.includes(key)) return;
      const isHigh = key === this._typesMovers[0];
      data.forEach((value) => {
        if (value.isEndOfSnap) {
          this._loadMoversSnapShot(isHigh);
          return;
        }
        if (
          value.command === this.COMMAND_ADD ||
          value.command === this.COMMAND_UPDATE
        ) {
          value.custom_variation = formatByTick(value.variacao);
          if (isHigh) this.stockListAltas.set(value.key, value);
          else this.stockListBaixas.set(value.key, value);
          this._cdr.detectChanges();
          return;
        }

        if (value.command === this.COMMAND_DELETE) {
          if (isHigh) this.stockListAltas.delete(value.key);
          else this.stockListBaixas.delete(value.key);
          this._cdr.detectChanges();
        }
      });
    });
  };

  private _loadMoversSnapShot(isHigher: boolean): void {
    if (isHigher) {
      if (this._loadedHighStocks) return;
      this._sortHighStocks();
      this._loadedHighStocks = true;
      this._moversSortSubject.next();
      this._cdr.detectChanges();
      return;
    }
    if (this._loadedLowerStocks) return;
    this._sortLowerStocks();
    this._loadedLowerStocks = true;
    this._moversSortSubject.next();
    this._cdr.detectChanges();
    return;
  }

  private _sortHighStocks(): void {
    const stocks = SORT_MOVERS_ROWS(
      DYNAMIC_LISTS.MOVERS_HIGH.id,
      this.stockListAltas.values()
    );
    this.stockListAltas.clear();
    this.stockListAltas.bulkData('key', stocks);
    this._cdr.detectChanges();
  }

  private _sortLowerStocks(): void {
    const stocks = SORT_MOVERS_ROWS(
      DYNAMIC_LISTS.MOVERS_LOW.id,
      this.stockListBaixas.values()
    );
    this.stockListBaixas.clear();
    this.stockListBaixas.bulkData('key', stocks);
    this._cdr.detectChanges();
  }

  private _sortMoversHandler = () => {
    this._moversSortSubject
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(() => {
        if (this._loadedHighStocks) this._sortHighStocks();
        if (this._loadedLowerStocks) this._sortLowerStocks();
        if (this._moversParams) this._moversSortSubject.next();
      });
  };

  private _loadCustomPreferences() {
    const configs = JSON.parse(
      this._customPreferencesService.getCustomPreference(
        AUTH_LOCAL_KEYS.MARKET_SUMMARY_CONFIG
      )
    );

    if (configs && configs.selectedIndex) {
      this.selectedIndex = configs.selectedIndex;
    }
  }

  public updateGlobalStock = (stock: IMoversData) => {
    if (this.linked) {
      stock.cd_stock = stock.key;
      this._globalStock.researchToStandardizeGlobalStock(stock, true);
    }
  };
}
