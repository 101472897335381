<ng-template>
  <div class="card" [ngClass]="customClass">
    <img
      *ngIf="!isImgBottom"
      [src]="src"
      [height]="height"
      [ngClass]="[typeImg, classImg]"
    />
    <div [ngClass]="[typeBody, classBody]">
      <ng-content select=".card-title"></ng-content>
      <ng-content select=".card-text"></ng-content>
    </div>
    <img
      *ngIf="isImgBottom"
      [src]="src"
      [height]="height"
      class="card-img"
      [ngClass]="classImg"
    />
    <ng-content select=".card-footer" *ngIf="!isImgBottom"></ng-content>
  </div>
</ng-template>
