import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportDataComponent } from './export-data.component';
import { FlaIconModule, RocketButtonModule } from '@shared/rocket-components/components';
import { ExportDataConverterService } from '@shared/services/export-data-converter.service';

@NgModule({
  imports: [
    CommonModule,
    RocketButtonModule,
    FlaIconModule
  ],
  declarations: [ExportDataComponent],
  exports: [ExportDataComponent],
  providers: [ExportDataConverterService]
})
export class ExportDataModule { }
