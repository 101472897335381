import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import { TABS } from '@core/components/orders-history-core/constants/orders-history.const';
import { ListOrdersService } from '@shared/services/core/list-orders/orders.service';
import { Subject, Subscription, delay } from 'rxjs';

@Directive({
  selector: '[appHeaderBlink]',
})
export class WorspaceHeaderBlinkDirective
  implements OnDestroy, AfterViewInit, OnChanges
{
  @Input() blinkQtty: any;
  @Input() blinkName: any;
  public SUCCESS_CLASS = 'text-feedback-success';
  public ERROR_CLASS = 'text-feedback-error';
  public isInit: boolean = true;
  private _checkExecStatus$ = new Subject<void>();
  private checkExecStatus$!: Subscription;
  private _removeFeedbackClasses$ = new Subject<void>();
  private removeFeedbackClasses$!: Subscription;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private listOrdersService: ListOrdersService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy() {
    this.checkExecStatus$ && this.checkExecStatus$.unsubscribe();
    this.removeFeedbackClasses$ && this.removeFeedbackClasses$.unsubscribe();
  }

  ngAfterViewInit() {
    this.isInit = false;
    this.checkExecStatus$ = this._checkExecStatus$
      .pipe(delay(1000))
      .subscribe(() => {
        const orders = this.listOrdersService.allOrders;
        const allOrders = orders.sort(this.listOrdersService.sortOrders);
        if (allOrders.length && allOrders[0].cd_order_status !== 'EXEC') {
          this.renderer.addClass(
            this.elementRef.nativeElement,
            this.ERROR_CLASS
          );
          this.cdr.detectChanges();
        }
      });
    this.removeFeedbackClasses$ = this._removeFeedbackClasses$
      .pipe(delay(2000))
      .subscribe(() => {
        this.removeFeedbackClasses();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isInit) return;
    if (changes['blinkQtty']) {
      const { previousValue, currentValue } = changes['blinkQtty'];

      if (
        currentValue > previousValue &&
        currentValue !== 0 &&
        previousValue - currentValue === -1
      ) {
        if (
          this.blinkName === TABS.POSITION.title ||
          this.blinkName === TABS.ORDER_OPEN.title ||
          this.blinkName === TABS.ORDER_EXEC.title ||
          this.blinkName === TABS.ORDER_CLOSE.title ||
          this.blinkName === TABS.ORDER_TOTAL.title ||
          this.blinkName === TABS.ALERTS.title ||
          this.blinkName ===
            DaytradeService.getTextWithTradeMode(TABS.INTRADIA.title) ||
          this.blinkName ===
            DaytradeService.getTextWithTradeMode(TABS.SWINGTRADING.title)
        ) {
          this.renderer.addClass(
            this.elementRef.nativeElement,
            this.SUCCESS_CLASS
          );
        }
      } else if (currentValue < previousValue) {
        if (this.blinkName === TABS.ORDER_OPEN.title) {
          this.checkExecStatus();
        }

        if (
          this.blinkName === TABS.POSITION.title ||
          this.blinkName === TABS.ALERTS.title ||
          this.blinkName ===
            DaytradeService.getTextWithTradeMode(TABS.INTRADIA.title) ||
          this.blinkName ===
            DaytradeService.getTextWithTradeMode(TABS.SWINGTRADING.title)
        ) {
          this.renderer.addClass(
            this.elementRef.nativeElement,
            this.ERROR_CLASS
          );
        }
      }
      this._removeFeedbackClasses$.next();
      this.cdr.detectChanges();
    }
  }

  checkExecStatus() {
    this._checkExecStatus$.next();
  }

  removeFeedbackClasses() {
    this.renderer.removeClass(
      this.elementRef.nativeElement,
      this.SUCCESS_CLASS
    );
    this.renderer.removeClass(this.elementRef.nativeElement, this.ERROR_CLASS);
  }
}
