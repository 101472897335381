import { Injectable } from '@angular/core';
import { AbstractCacheService } from './AbstractCacheService';
import { IShareHoldersHistory } from '@shared/components/business-profile/business-profile.interface';

@Injectable({
  providedIn: 'root',
})
export class ShareHoldersHistoryCacheService extends AbstractCacheService<
  IShareHoldersHistory[]
> {}
