export const THEME_COLOR = {
  LIGHT: {
    fontColor: '#01464d',
    bgIconColor: '#38b88e',
    iconColor: '#05464d',
  },
  DARK: {
    fontColor: '#fff',
    bgIconColor: '#38b88e',
    iconColor: '#05464d',
  },
};
