<app-performance-report-header
  [refComponent]="refComponent"
  [component]="component"
  [performanceSubscription]="useSubscriptionInfos"
  [linked]="link"
  (performanceHandler)="performanceHandler($event)"
  (changeTab)="currentContent = $event"
  (performanceFiltersChange)="handlerFilters($event)"
></app-performance-report-header>
<div
  [id]="refComponent"
  class="position-relative w-100"
  [ngClass]="isDesktop ? 'component-height' : 'h-100'"
>
  <ng-container [ngSwitch]="currentContent">
    <ng-container *ngSwitchCase="PERFORMANCE_CONTENT.INFOS">
      <div class="fs-5 d-flex w-100 h-100 gap-1 overflow-auto">
        <app-performance-details
          [performance]="performanceDetails"
          [marketPerformanceData]="marketDataDetails"
          [operations]="operations"
          [width]="width"
        ></app-performance-details>
        
        <ng-template #errorOnLoadPerformance>
          <span class="fs-base w-100 d-block text-center">
            {{ performanceErrorMsg }}
          </span>
        </ng-template>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="PERFORMANCE_CONTENT.CHART">
      <performance-info-chart
        [serviceFilters]="filters"
      ></performance-info-chart>
    </ng-container>
  </ng-container>
</div>
