import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  OnInit,
} from '@angular/core';
import {
  RocketModalRef,
  RocketModalService,
  ROCKET_MODAL_DATA,
  RocketModalModule,
  FlaCheckModule,
  RocketButtonModule,
} from '@shared/rocket-components/components/index';
import { CommonModule } from '@angular/common';
import { MESSAGE_MODAL_CANCEL_ORDER } from '@shared/constants/general.contant';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';

@Component({
  selector: 'app-cancel-order-modal',
  templateUrl: './cancel-order-modal.component.html',
  styles: [
    `
      ::ng-deep .margin-bottom {
        margin-bottom: 0px !important;
      }
    `,
  ],
  standalone: true,
  imports: [
    CommonModule,
    RocketModalModule,
    FlaCheckModule,
    RocketButtonModule,
  ],
  providers: [RocketModalService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelOrderModalComponent
  extends RocketModalRef
  implements OnInit
{
  public dontAskAgain: boolean = false;
  public message = '';
  public standaloneClose!: (params?: any) => void;

  constructor(
    @Inject(ROCKET_MODAL_DATA)
    public data: { action: string; dontAskAgain: boolean; cdStock: string },
    private _rocketModalService: RocketModalService,
    private customPreferenceService: CustomPreferencesService
  ) {
    super(_rocketModalService);
  }

  ngOnInit(): void {
    switch (this.data.action) {
      case MESSAGE_MODAL_CANCEL_ORDER.ALL_ORDERS:
        this.message = 'Tem certeza que deseja cancelar todas as suas ordens?';
        break;
      case MESSAGE_MODAL_CANCEL_ORDER.ALL_ORDERS_BY_STOCK:
        this.message = `Tem certeza que deseja cancelar todas as suas ordens de ${this.data.cdStock}?`;
        break;
      default:
        this.message = 'Tem certeza que deseja cancelar a sua ordem?';
        break;
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    (event.code === 'Enter' ||
      event.code === 'NumpadEnter' ||
      event.code === 'Space') &&
      this.cancelOrder();
  }

  public cancelOrder(): void {
    this.dontAskAgain &&
      (this.customPreferenceService.diplayCancelOrderConfirmattion = false);
    this.standaloneClose({ confimation: true });
  }

  public closeModal(): void {
    this.standaloneClose();
  }
}
