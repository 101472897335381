import { ELabelAlignment, TTextStyle, Thickness } from 'scichart';
import { IRentFieldsToSort } from '../interface/rent.interface';

export const FIELDS_TO_SORT: IRentFieldsToSort[] = [
  {
    enum: 'QUANTITY',
    label: 'Quantidade',
    active: false,
    field: 'balance_qtty',
  },
  {
    enum: 'VOLUME',
    label: 'Volume',
    active: false,
    field: 'balance_value',
  },
  {
    enum: 'CONTRACT',
    label: 'Contratos',
    active: false,
    field: 'qtty_contracts',
  },
  {
    enum: 'TAX',
    label: 'Taxa',
    active: false,
    field: 'pc_average_interest_rate_giver',
  },
  {
    enum: 'RENT_MCAP',
    label: 'Aluguel/MCAP',
    active: false,
    field: 'rent_mcap',
  },
  {
    enum: 'MCAP',
    label: 'MCAP',
    active: false,
    field: 'mcap',
  },
  {
    enum: 'BALANCE_PC_TOTAL',
    label: 'Aluguel/Free Float',
    active: false,
    field: 'balancePcTotal',
  },
  {
    enum: 'DAYS_SHORT',
    label: 'Dias Short',
    active: false,
    field: 'days_short',
  },
];

export const DEFAULT_SORT = {
  enum: 'RENT_MCAP',
  label: 'Aluguel/MCAP',
  active: true,
  field: 'rent_mcap',
};

export const DEFAULT_HEADER_FILTER = {
  enum: 'RENT_MCAP',
  label: 'Aluguel/MCAP',
  active: true,
  field: 'rent_mcap',
};

export const CHART_TOOLTIPS_SETTINGS: any = {
  QUANTITY: {
    dot_fill: '#0099FF',
    label: 'Quantidade',
    textX: 109,
  },
  VOLUME: {
    dot_fill: '#F289DA',
    label: 'Volume',
    textX: 82,
  },
  RATE: {
    dot_fill: '#9C00FF',
    label: 'Taxa',
    textX: 66,
  },
  IBOV: {
    dot_fill: '#FE8F00',
    label: 'IBOV',
    textX: 66,
  },
  STOCK: {
    dot_fill: '#FF3F59',
    label: 'Ativo',
    textX: 80,
  },
};

export const CHART_TITLE_STYLES: TTextStyle = {
  alignment: ELabelAlignment.Center,
  color: '#F0F0F0F0',
  fontFamily: 'Metropolis',
  fontSize: 9,
  padding: Thickness.fromString('1 16 1 16'), // Fix pads on toggle line visibility
};

export const TOOLTIP_Y_POSITION = [30, 50, 70, 90, 110];

export const STOCK_BALANCE_INFOS = [
  {
    label: 'Tx Mínima',
    field: 'pc_min_interest_rate_giver',
    tooltip: 'Taxa Mínima',
  },
  {
    label: 'Tx Média',
    field: 'pc_average_interest_rate_taker',
    tooltip: 'Taxa Média',
  },
  {
    label: 'Tx Máxima',
    field: 'pc_max_interest_rate_taker',
    tooltip: 'Taxa Máxima',
  },
  {
    label: 'Ações',
    field: 'balance_qtty',
    tooltip: 'Quantidade de ações',
  },
  {
    label: 'Contratos',
    field: 'qtty_contracts',
    tooltip: 'Quantidade de contratos',
  },
  {
    label: 'Volume',
    field: 'balance_value',
    tooltip: 'Volume',
  },
  {
    label: 'Data Base',
    field: 'dt_trade',
    tooltip: 'Data Base',
  },
];
