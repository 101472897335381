import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { INDICATOR_COMPANY_INFO_DICT } from '../business-profile.const';
import { ISearchStock } from '@core/interface';
import { FundamentalService } from '@shared/services/api/trademap/v1/fundamental.service';
import { formatByTick } from 'src/app/utils/utils.functions';
import { IndicatorsService } from '@shared/services/api/trademap/v1/indicators.service';
import {
  bigValueFormatterWithoutDots,
  deepClone,
  isNullOrWhiteSpace,
} from '@shared/rocket-components/utils';
import {
  IBalanceSheet,
  IBalanceSheetChildrens,
  IConsolidatedData,
  IIndicatorsConsolidated,
  IIndicatorsTabs,
} from './interface/indicators.interface';
import {
  INDICATORS_COMPANY_TYPE,
  INDICATORS_TABS,
  INDICATOR_PERIOD,
} from './enum/indicators.enum';
import {
  DROPDOWN_INDICATORS_PERIOD,
  INDICATOR_TABS,
  TABLE_HEADERS,
  indicatorDropDownData,
} from './constant/indicators.constant';
import { Subject, debounceTime } from 'rxjs';
import { ExportDataIdEnum } from '@shared/components/export-data/export-data.enum';
import { TableService } from '@shared/rocket-components/table';
import { BusinessProfileService } from '../business-profile.service';

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndicatorsComponent implements OnDestroy {
  @Input() refComponent!: string;
  @Input() componentID: string = 'BUSINESS_PROFILE_CONTAINER';
  @Input() set stockSelected(stockSelected: ISearchStock) {
    if (stockSelected) this._updateStockSubject.next(stockSelected);
  }

  private _stockSelected!: ISearchStock;
  private _lastTabActive: number = 0;
  private _tableScrollRef!: ElementRef;
  private _lastElementObserved!: HTMLElement;
  private _loadedTableInfosSubject = new Subject<any>();
  private _scrollToEndSubject = new Subject<void>();
  private _updateStockSubject = new Subject<ISearchStock>();
  private _heightObservable!: ResizeObserver;
  private _loadedIds: string[] = [];
  private _loadedChildrens: string[] = [];

  public cards = INDICATOR_COMPANY_INFO_DICT;
  public loadingCards: boolean = true;
  public loadingTable: boolean = true;
  public isIndicators: boolean = true;
  public collapseFirstLine: boolean = false;
  public tableContentHeight: string = '100px';
  public cdStock!: string;
  public exporterId = ExportDataIdEnum.INDICADORES;

  public tableData!: any;
  public dropdownData = indicatorDropDownData(
    INDICATORS_COMPANY_TYPE.CONSOLIDATED
  );
  public dropdownPeriod = DROPDOWN_INDICATORS_PERIOD;
  public tableTypes = deepClone(INDICATOR_TABS);
  public fixedHeaders = TABLE_HEADERS;

  get currentTabRef(): INDICATORS_TABS {
    return this.tableTypes[this._lastTabActive].ref;
  }

  get childrenField(): string {
    if (this.currentTabRef === INDICATORS_TABS.INDICATORS) return 'indicators';
    return 'childrens';
  }

  get childrenFieldValue(): string {
    if (this.currentTabRef === INDICATORS_TABS.INDICATORS) return 'namePt';
    return 'description';
  }

  get fixedHeaderWidth(): string {
    switch (this.currentTabRef) {
      case INDICATORS_TABS.INDICATORS:
        return '300px';
      case INDICATORS_TABS.BALANCE:
        return '190px';
      case INDICATORS_TABS.DRE:
        return '350px';
      case INDICATORS_TABS.CASH_FLOW:
        return '370px';
      default:
        return '350px';
    }
  }

  constructor(
    private fundamentalService: FundamentalService,
    private _cdr: ChangeDetectorRef,
    private _tableService: TableService,
    private _indicatorsService: IndicatorsService,
    private businessProfileService: BusinessProfileService
  ) {
    this._loadedTableInfosSubject.pipe(debounceTime(150)).subscribe((data) => {
      data.rows = this.businessProfileService.createInfoWithoutDate(
        data.headers,
        data.rows
      );
      this.tableData = data;
      this.loadingTable = false;
      this.collapseFirstLine = this.isIndicators;
      this._cdr.detectChanges();
      this._resizeTable();
    });

    this._scrollToEndSubject.pipe(debounceTime(20)).subscribe(() => {
      this._tableScrollRef.nativeElement.scrollTo({ left: 9999 });
      this._cdr.detectChanges();
    });

    this._updateStockSubject.pipe(debounceTime(100)).subscribe((stock) => {
      this.loadingTable = true;
      this._stockSelected = stock;
      this.cdStock = stock.cd_stock;
      this._cdr.detectChanges();
      this._resetLoadedChildrens();
      this._getResumeIndicators();
      this._updateTableInfos(this.currentTabRef);
    });
  }

  ngOnDestroy(): void {
    this._loadedTableInfosSubject.unsubscribe();
    this._scrollToEndSubject.unsubscribe();
    this._updateStockSubject.unsubscribe();
    this._heightObservable.unobserve(this._lastElementObserved);
  }

  public setTableScrollRef(ref: ElementRef): void {
    this._tableScrollRef = ref;
    this._tableService.updateWidthCell(
      this.componentID,
      parseInt(this.fixedHeaderWidth.replace(/\D/, ''))
    );
    this._cdr.detectChanges();
    if (this.currentTabRef !== INDICATORS_TABS.INDICATORS)
      this._scrollToEndSubject.next();
  }

  private _resizeTable() {
    const container = document.getElementById(this.componentID);
    if (!container) {
      console.error('TABLE_CONTAINER_NOT_FOUND');
      return;
    }
    if (this._lastElementObserved)
      this._heightObservable.unobserve(this._lastElementObserved);
    this._heightObservable = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.tableContentHeight = `${entry.contentRect.height - 150}px`;
        this._cdr.detectChanges();
      }
    });
    this._lastElementObserved = container;
    this._heightObservable.observe(container);
  }

  public onChangeTab(tab: IIndicatorsTabs, index: number): void {
    if (this.loadingTable || index === this._lastTabActive) return;
    this.tableTypes[this._lastTabActive].active = false;
    this.tableTypes[index].active = true;
    this._lastTabActive = index;
    this.isIndicators = tab.ref === INDICATORS_TABS.INDICATORS;
    this.fixedHeaders[1].display = tab.ref !== INDICATORS_TABS.BALANCE;
    this._resetLoadedChildrens();
    this._updateTableInfos(tab.ref);
    this._cdr.detectChanges();
  }

  public updateFilter(
    dropdownType: 'DATA' | 'PERIOD',
    value: 'Consolidado' | 'Individual'
  ): void {
    this._resetLoadedChildrens();
    if (dropdownType === 'DATA') {
      this.dropdownData.data.active = value;
      this._updateDropDownValueEnum(value);
      this._cdr.detectChanges();
      return;
    }
    this.dropdownPeriod.data.active = value;
    this._updateTableInfos(this.currentTabRef);
    this._cdr.detectChanges();
  }

  private _resetLoadedChildrens(): void {
    this._loadedChildrens = [];
    this._loadedIds = [];
  }

  private _updateDropDownValueEnum(value: 'Consolidado' | 'Individual'): void {
    const tab = this.currentTabRef;
    this.dropdownData.data.activeEnum =
      value === 'Consolidado'
        ? INDICATORS_COMPANY_TYPE.CONSOLIDATED
        : INDICATORS_COMPANY_TYPE.INDIVIDUAL;
    this._updateTableInfos(tab);
  }

  public toggleRow(indicator: IIndicatorsConsolidated[]): void {
    const indicatos = this._removeLoadedData(indicator);
    if (!isNullOrWhiteSpace(indicatos)) this._appendChildrenData(indicatos);
  }

  private _removeLoadedData(indicator: IIndicatorsConsolidated[]) {
    return indicator.filter(
      (item) => !this._loadedIds.includes(`${item.id}${this.refComponent}`)
    );
  }

  public toggleChildren(children: IBalanceSheetChildrens[]): void {
    const value = this._removeDataHasLoaded(children, this._loadedChildrens);
    if (!isNullOrWhiteSpace(value)) {
      this._appendChildrenData(value, true);
      this._tableService.updateWidthCell(this.componentID, 190);
    }
  }

  private _removeDataHasLoaded(
    list: IBalanceSheetChildrens[],
    listLoadedData: string[]
  ) {
    return list.filter((item) => {
      return !listLoadedData.includes(`${item.id}${this.refComponent}`);
    });
  }

  private _appendChildrenData(
    value: IIndicatorsConsolidated[] | IBalanceSheetChildrens[],
    isChildren: boolean = false
  ) {
    value.forEach((item) => {
      if (isChildren)
        this._loadedChildrens.push(`${item.id}${this.refComponent}`);
      else this._loadedIds.push(`${item.id}${this.refComponent}`);
      item.values.forEach((value) => {
        this._tableService.insertCellElement(`${item.id}${this.refComponent}`, {
          height: '40px',
          width: '140px',
          alignText: 'text-right',
          cssContent: 'border-none',
          css: 'border-none',
          text: this.valueFormatter(value.value),
        });
      });
    });
    this._scrollToEndSubject.next();
    this._cdr.detectChanges();
  }

  public valueFormatter(value: number): string {
    if (!value) return '-';
    return bigValueFormatterWithoutDots(value).replace('.', ',');
  }

  private _getPeriodEnum(): INDICATOR_PERIOD {
    const period: '3 Meses' | '12 Meses' | 'Exercício' = <any>(
      this.dropdownPeriod.data.active
    );
    if (period === '3 Meses') return INDICATOR_PERIOD.THREE_MONTHS;
    if (period === '12 Meses') return INDICATOR_PERIOD.ANNUAL;
    return INDICATOR_PERIOD.EXERCISE;
  }

  // SERVICES
  private _getResumeIndicators() {
    this.fundamentalService
      .getIndicatorCompany([
        `${this._stockSelected.cd_stock}:${this._stockSelected.id_exchange}`,
      ])
      .then((response: any) => {
        this.loadingCards = false;
        const data = response ? response[0] : {};
        Object.keys(data).forEach((key) => {
          const c = this.cards.get(key);
          if (c) {
            const value = data[key] || '-';
            c.value = formatByTick(value);
          }
        });
        this._cdr.detectChanges();
      });
  }

  private _updateTableInfos(tab: INDICATORS_TABS): void {
    this.loadingTable = true;
    switch (tab) {
      case INDICATORS_TABS.INDICATORS:
        this.exporterId = ExportDataIdEnum.INDICADORES;
        this._indicators();
        break;
      case INDICATORS_TABS.BALANCE:
        this.exporterId = ExportDataIdEnum.BALANCO_PATRIMONIAL;
        this._balanceSheet();
        break;
      case INDICATORS_TABS.DRE:
        this.exporterId = ExportDataIdEnum.DRE;
        this._incomesStatement();
        break;
      case INDICATORS_TABS.CASH_FLOW:
        this.exporterId = ExportDataIdEnum.FLUXO_CAIXA;
        this._cashFlow();
        break;
      default:
        break;
    }
  }

  private _indicators(): void {
    this._indicatorsService
      .getConsolidatedIndicators(
        this._stockSelected.cd_stock,
        this._stockSelected.id_exchange,
        this._getPeriodEnum(),
        this.dropdownData.data.activeEnum
      )
      .subscribe({
        next: (response: IConsolidatedData) => {
          this._loadedTableInfosSubject.next(response);
        },
        error: (error) => {
          console.error('INDICATORS_ERROR', error);
          this._loadedTableInfosSubject.next(null);
        },
      });
  }

  private _balanceSheet(): void {
    this._indicatorsService
      .getBalanceSheet(
        this._stockSelected.cd_stock,
        this._stockSelected.id_exchange,
        this.dropdownData.data.activeEnum
      )
      .subscribe({
        next: (response: IBalanceSheet) => {
          this._loadedTableInfosSubject.next(response);
        },
        error: (error) => {
          console.error('BALANCE_ERROR', error);
          this._loadedTableInfosSubject.next(null);
        },
      });
  }

  private _incomesStatement(): void {
    this._indicatorsService
      .getIncomesStatements(
        this._stockSelected.cd_stock,
        this._stockSelected.id_exchange,
        this.dropdownData.data.activeEnum,
        this._getPeriodEnum()
      )
      .subscribe({
        next: (response: IBalanceSheet) => {
          this._loadedTableInfosSubject.next(response);
        },
        error: (error) => {
          console.error('STATEMENT_ERROR', error);
          this._loadedTableInfosSubject.next(null);
        },
      });
  }

  private _cashFlow(): void {
    this._indicatorsService
      .getCashFlow(
        this._stockSelected.cd_stock,
        this._stockSelected.id_exchange,
        this.dropdownData.data.activeEnum,
        this._getPeriodEnum()
      )
      .subscribe({
        next: (response: IBalanceSheet) => {
          this._loadedTableInfosSubject.next(response);
        },
        error: (error) => {
          console.error('STATEMENT_ERROR', error);
          this._loadedTableInfosSubject.next(null);
        },
      });
  }
}
