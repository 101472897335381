import { Injectable } from '@angular/core';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { ReplaySubject } from 'rxjs';
import { DAY_TRADE_REPLACE_KEY, SWING_REPLACE_KEY } from '../constants/daytrade.constant';
import { system } from '@core/system/system.service';
@Injectable({
  providedIn: 'root',
})
export class DaytradeService {
  private _useDayTradeMode: boolean = false;

  get dayTradeMode() {
    return this._useDayTradeMode$.asObservable();
  }

  set useDayTradeMode(enableDayTradeMode: boolean) {
    this._customPreferencesService.useDayTradeMode = enableDayTradeMode;
    this._useDayTradeMode = enableDayTradeMode;
  }

  get useDayTradeMode() {
    return this._useDayTradeMode;
  }

  constructor(private _customPreferencesService: CustomPreferencesService) {
    this._useDayTradeMode = this._customPreferencesService.useDayTradeMode;
    this._useDayTradeMode$.next(this._useDayTradeMode);
  }

  private _useDayTradeMode$: ReplaySubject<boolean> =
    new ReplaySubject<boolean>();

  public toggleUseDayTradeMode(): void {
    const currentValue = this.useDayTradeMode;
    this.useDayTradeMode = !currentValue;
    this._useDayTradeMode$.next(!currentValue);
  }

  /**
  * Função para trocar nomes de Swing e DayTrade pelo nome usado
  * pela corretora. Padrão usado é substituir textos com {DayTrade},
  * {Swing} pelos valores usados para dayTrade e swing respectivamente.
  * @param text Texto com chaves a serem substituidas. Caso não passado nada, retorna a label do modo selecionado
  */
  public static getTextWithTradeMode = (text: string) => {
    const labels = system.tradeModeLabels
    text = text.replaceAll(DAY_TRADE_REPLACE_KEY, labels.dayTrade)
    text = text.replaceAll(SWING_REPLACE_KEY, labels.swing)
    return text
  }
}