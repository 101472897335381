import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  STOCK_TABLE_VIEWS,
  IStockTableView,
  STOCK_TABLE_VIEW,
} from '@shared/components/stock-table-views';
import { deepClone } from '@shared/rocket-components/utils';
import { STOCK_TABLE_ELEMENT_IDS } from '../../../constants/stock-table.constants';

@Component({
  selector: 'app-change-view',
  templateUrl: './change-view.component.html',
  styles: [
    `
      .dp-position {
        top: 24px;
        left: 0px;
      }

      .view-icon-min-width {
        min-width: 20px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeViewComponent implements OnChanges {
  @Output() changeView = new EventEmitter<IStockTableView>();
  @Output() updateCurrenViewBeforeFirstRender =
    new EventEmitter<IStockTableView>();

  @Input() public initialStockTableType!: STOCK_TABLE_VIEW;
  @Input() public onlyIcon: boolean = false;
  @Input() public forceDisplayDP: boolean = false;

  public currentView: IStockTableView = STOCK_TABLE_VIEWS[0];
  public views: IStockTableView[] = deepClone(STOCK_TABLE_VIEWS);
  public readonly ELEMENT_IDS = STOCK_TABLE_ELEMENT_IDS;

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { initialStockTableType } = changes;
    if (initialStockTableType?.currentValue)
      this._updateCurrentView(initialStockTableType.currentValue);
  }

  private _updateCurrentView(newView: STOCK_TABLE_VIEW): void {
    const viewIndex = this.views.findIndex(
      (view: IStockTableView) => view.enum === newView
    );
    if (viewIndex < 0) return;
    this.currentView = this.views[viewIndex];
    this.updateCurrenViewBeforeFirstRender.emit(this.currentView);
    this._cdr.detectChanges();
  }

  public stockTypeHandler(view: IStockTableView): void {
    if (view.enum === this.currentView.enum) return;
    this.currentView = view;
    this.changeView.emit(this.currentView);
    this._cdr.detectChanges();
  }
}
