import { IOrders } from '../interface/order.interface';

export class ProcessModificationOrderModel {
  public id_order!: number;
  public order_qty!: number;
  public price!: number | null;
  public stopPx!: number | null;
  public time_in_force!: string;
  public flag_valida_volume!: boolean;
  public flag_valida_variacao!: boolean;
  public id_broker!: number;
  public ord_type!: string;
  public tipo!: string;
  public cd_stock!: string;
  public validade!: string;
  public assinarTCQ!: boolean;
  public assinarTCD!: boolean;
  public id_exchange!: number;
  public personalized_gain_trigger!: string;
  public personalized_gain_price!: string;
  public personalized_loss_trigger!: string;
  public personalized_loss_price!: string;
  public is_day_trade!: boolean;

  public gainOrderPrice!: string | number;
  public gainTriggerPrice!: string | number;
  public lossOrderPrice!: string | number;
  public lossTriggerPrice!: string | number;
  public origin!: string;
  public expirate_date?: string | null = null;

  constructor(orderValues: IOrders, invertStopBroker: boolean) {
    this.id_order = orderValues.id_order;
    this.order_qty = orderValues.qtty;
    this.price = orderValues.price;
    this.stopPx = orderValues.price_stop;
    this.flag_valida_volume = true;
    this.flag_valida_variacao = true;
    this.id_broker = orderValues.id_broker;
    this.ord_type = orderValues.cd_order_type;
    this.tipo = orderValues.ds_order_type;
    this.cd_stock = orderValues.cd_stock;
    this.validade = orderValues.ds_order_time_enforce;
    this.time_in_force = orderValues.cd_order_time_enforce ?? '0';
    this.assinarTCQ = false;
    this.assinarTCD = false;
    this.id_exchange = orderValues.id_exchange;
    this.is_day_trade = orderValues.is_day_trade;
    /**
     * na regra da corretora caso seja uma ordem de compra inverte o gain e loss.
     */
    this.gainOrderPrice = invertStopBroker
      ? orderValues.personalized_loss_price
      : orderValues.personalized_gain_price;
    this.gainTriggerPrice = invertStopBroker
      ? orderValues.personalized_loss_trigger
      : orderValues.personalized_gain_trigger;
    this.lossOrderPrice = invertStopBroker
      ? orderValues.personalized_gain_price
      : orderValues.personalized_loss_price;
    this.lossTriggerPrice = invertStopBroker
      ? orderValues.personalized_gain_trigger
      : orderValues.personalized_loss_trigger;
    this.origin = '';
    this.expirate_date = null;
  }
}
