import { Routes, RouterModule } from '@angular/router';
import { SystemComponent } from './system.component';
import { ChangelogModule } from './changelog/changelog.module';

const routes: Routes = [
  {
    path: '',
    component: SystemComponent,
    children: [
      {
        path: '',
        redirectTo: 'changelog',
        pathMatch: 'full',
      },
      {
        path: 'changelog',
        loadChildren: () => ChangelogModule,
      },
    ],
  },
];

export const SystemRoutes = RouterModule.forChild(routes);
