<form class="container">
  <div
    class="row flex-nowrap mb-3"
    *ngFor="let style of indicator.renderSeriesConfig"
  >
    <div class="col-8 d-flex flex-nowrap">
      <div class="form-check form-check-inline">
        <label [for]="style.type" class="form-label">
          <input
            *ngIf="style.type == parameterType.COLOR"
            class="form-check-input"
            type="checkbox"
            [id]="style.type"
            [checked]="style.active"
            (change)="changeActive(style)"
          />
          {{ style.label }}
        </label>
      </div>
    </div>
    <div [ngClass]="{ 'd-flex': style.showLimit }" class="col-8">
      <div
        [ngClass]="{ 'justify-content-end': style.dontShowTickness }"
        class="input-group"
        *ngIf="style.type == parameterType.COLOR"
      >
        <div
          role="button"
          class="form-select form-select-sm RT-styles-color-select form-control-sm col-6 col-sm-5 max-width-fit-content"
          ngDefaultControl
          [formControl]="formControl"
          rtColorPicker
          [height]="100"
          [width]="120"
          [type]="'Hex'"
          [color]="style.color"
          [closeClickOutside]="true"
          [hideTypeButton]="true"
          [closeOnClick]="false"
          [rtColorPickerAlign]="'center'"
          (rtColor)="changeColor(style, '' + $event)"
        >
          <span
            class="color-box"
            [style]="{ 'background-color': style.color }"
          ></span>
        </div>
        <ng-select
          *ngIf="!style.dontShowTickness"
          #thicknessSelector
          class="form-select form-select-sm RT-styles-color-select form-control-sm col-6 col-sm-5 ml-negative"
          bindValue="value"
          [id]="style.id + '_thickness'"
          [name]="style.id + '_thickness'"
          [clearable]="false"
          [closeOnSelect]="false"
          [items]="[style]"
          [ngModel]="style.thickness"
          [disabled]="!style.active"
        >
          <ng-template ng-label-tmp let-item="item">
            <span
              class="color-line"
              [style]="{
                'background-color': style.color,
                height: style.thickness + 'px'
              }"
            ></span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <div class="p-1 pt-2 thickness-wrapper">
              <div class="btn-group" role="group">
                <button
                  type="button"
                  class="btn btn-sm"
                  *ngFor="let thickness of thicknessSize"
                  [disabled]="thickness === style.thickness"
                  (click)="changeThickness(style, thickness)"
                >
                  <span
                    class="RT-thickness-line"
                    [style.height]="thickness + 'px'"
                  ></span>
                </button>
              </div>
              <div class="d-flex justify-content-end mt-2">
                <button class="btn btn-sm" (click)="thicknessSelector.close()">
                  Fechar
                </button>
              </div>
            </div>
          </ng-template>
        </ng-select>
        <ng-select
          *ngIf="style.showTypeLine"
          #typeLineSelector
          class="form-select form-select-sm RT-styles-color-select form-control-sm col-6 col-sm-5 ml-negative"
          bindValue="value"
          [id]="style.id + '_line_type'"
          [name]="style.id + '_line_type'"
          [clearable]="false"
          [closeOnSelect]="false"
          [items]="[style]"
          [ngModel]="style.lineTypeId"
          [disabled]="!style.active"
        >
          <ng-template ng-label-tmp let-item="item">
            <span
              class="color-line type-lines"
              [ngClass]="typeLines[style.lineTypeId!!].style"
              [style]="{
                'border-color': style.color,
                height: '1px'
              }"
            ></span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <div class="type-line-wrapper">
              <div class="d-flex flex-column">
                <div
                  class="d-flex align-items-center height-line-type gap-3 p-3"
                  *ngFor="let type of typeLines"
                  [ngClass]="{ active: type.id === style.lineTypeId }"
                  (click)="changeTypeLine(style, type.id)"
                >
                  <span [ngClass]="type.style" class="RT-type-line"></span>
                  <span>{{ type.label }}</span>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-2">
                <button class="btn btn-sm" (click)="typeLineSelector.close()">
                  Fechar
                </button>
              </div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div *ngIf="style.showLimit" class="col-7">
        <input
          type="number"
          class="form-control form-control-sm"
          [id]="style.limitType"
          [value]="getValue(style.property!!)"
          (change)="onChange($event, style.property!!)"
          min="1"
        />
      </div>
    </div>
  </div>
  <div class="row flex-nowrap mb-3" *ngFor="let style of indicator.styles">
    <div class="col-8">
      <label [for]="style.type" class="form-label">
        {{ style.label }}
      </label>
    </div>
    <div class="col-8">
      <input
        type="number"
        class="form-control form-control-sm col-6 col-sm-5"
        *ngIf="style.type == parameterType.NUMBER"
        [id]="style.type"
        [value]="getValue(style.property)"
        (change)="onChange($event, style.property)"
      />
      <ng-select
        *ngIf="style.type == parameterType.SELECTION"
        class="form-select form-select-sm form-control-sm col-6 col-sm-5"
        bindValue="value"
        [name]="style.label"
        [id]="style.type"
        [clearable]="false"
        [items]="style.values || null"
        [ngModel]="getValue(style.property)"
        (change)="onChange($event, style.property)"
      >
      </ng-select>
    </div>
  </div>
</form>
