import { DATE_PICKER_CONFIGS } from './../../constants/datepicker.constants';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fla-datepicker-month',
  template: `
    <div class="datepicker-month">
      <button
        *ngFor="let month of months; let i = index"
        type="button"
        class="datepicker-month-name"
        [disabled]="(min && i <= min) || (max && i >= max)"
        (click)="onClick(i)"
        [ngClass]="{ selected: selectedMotnh === month }"
      >
        {{ month }}
      </button>
    </div>
  `,
})
export class FlaDatepickerMonthComponent implements OnInit {
  months = DATE_PICKER_CONFIGS.months;

  @Input() selectedMotnh!: string;
  @Input() monthNumber!: number;
  @Input() min?: number;
  @Input() max?: number;
  @Output() flaClick = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  onClick(index: number) {
    let monthNumber: number;
    if (index < this.monthNumber) {
      monthNumber = this.monthNumber - index;
      monthNumber = -Math.abs(monthNumber);
    } else {
      monthNumber = index - this.monthNumber;
    }

    this.flaClick.emit(monthNumber);
  }
}
