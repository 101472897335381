import { IMoversPeriod } from '../interfaces/movers-period.interface';

export const MOVERS_PERIODS: IMoversPeriod[] = [
  {
    label: 'Intradia',
    value: '',
    abbreviation: 'I',
  },
  {
    label: 'Ontem',
    value: 'YESTERDAY',
    abbreviation: 'O',
  },
  {
    label: '1 semana',
    value: 'WEEK',
    abbreviation: '1S',
  },
  {
    label: 'Na semana',
    value: 'THIS_WEEK',
    abbreviation: 'NS',
  },
  {
    label: '1 mês',
    value: 'MONTH',
    abbreviation: '1M',
  },
  {
    label: 'No mês',
    value: 'THIS_MONTH',
    abbreviation: 'NM',
  },
  {
    label: '12 meses',
    value: 'YEAR',
    abbreviation: '12M',
  },
  {
    label: 'No ano',
    value: 'THIS_YEAR',
    abbreviation: 'NA',
  },
  {
    label: '5 anos',
    value: 'FIVE_YEARS',
    abbreviation: '5A',
  },
  {
    label: '10 anos',
    value: 'TEN_YEARS',
    abbreviation: '10A',
  },
  {
    label: '20 anos',
    value: 'TWENTY_YEARS',
    abbreviation: '20A',
  },
];

export const IOSMA_PERIODS: any = {
  one_day: 'ONE_DAY',
  yeasterday: 'YESTERDAY',
  one_week: 'ONE_WEEK',
  this_week: 'THIS_WEEK',
  onemonth: 'ONE_MONTH',
  this_month: 'THIS_MONTH',
  oneyear: 'ONE_YEAR',
  thisyear: 'THIS_YEAR',
  fiveyear: 'FIVE_YEAR',
  tenyear: 'TEN_YEAR',
  twentyyear: 'TWENTY_YEAR',
};
