import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CustomPreferencesService } from '@services/api/nitro-ws/v1/custom-preferences.service';
import { ORDER_CONFIRM_ACTIONS_ENUM } from '@shared/enum/buyOrSell.enum';
import {
  ORDERS_PERSMISSIONS,
  SOUND_LABELS,
} from '../../../core/layout/header/constants/header.contanst';
import {
  FlaCheckModule,
  FlaIconModule,
  FlaTabModule,
  ROCKET_MODAL_DATA,
  RocketButtonModule,
  RocketModalModule,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components/index';
import { CommonModule } from '@angular/common';
import { StandaloneModalsService } from '../standalone-modals.service';
import { JoystickService } from '@shared/services/joystick.service';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';
import { RTInputCountModule } from '@shared/rocket-components/input-count/input-count.module';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { ModalOrdensService } from './modal-ordens.service';
import {
  IConfigTabs,
  INegotiationPermissions,
} from '@core/layout/header/interfaces/header.interfaces';

@Component({
  selector: 'app-modal-ordens',
  templateUrl: './modal-ordens.component.html',
  styleUrls: ['./modal-ordens.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RocketModalModule,
    FlaTabModule,
    FlaCheckModule,
    RocketButtonModule,
    FormsModule,
    ReactiveFormsModule,
    RTDropdownModule,
    FlaIconModule,
    RTInputCountModule,
    TooltipsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalOrdensComponent extends RocketModalRef implements OnInit {
  confirmationsForm!: FormGroup;
  negotiationForm!: FormGroup;

  public invertOrReserPostionEnum = ORDER_CONFIRM_ACTIONS_ENUM;
  public ordersPermissions = ORDERS_PERSMISSIONS;
  public negotiationsPermissions!: INegotiationPermissions[];
  public modalTabs!: IConfigTabs[];
  public selectTab = 'NEGOTIATION';
  public soundLabels = SOUND_LABELS;
  public soundSelect = ['0', '1', '2', '3'];
  public sounds = [
    { label: 'Som 1', sound: 'order_executed' },
    { label: 'Som 2', sound: 'confirmed_order' },
    { label: 'Som 3', sound: 'canceled_order' },
    { label: 'Som 4', sound: 'extra' },
  ];
  public isResetConfigNotifiations = false;
  public labelButtonConfirm = 'Confirmar';
  public labelButtonCancel = 'Cancelar';
  public audioNotification = true;

  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: any,
    service: RocketModalService,
    private _fb: FormBuilder,
    public _customPreferencesService: CustomPreferencesService,
    private _joystickService: JoystickService,
    private _standaloneModalsService: StandaloneModalsService,
    private modalOrdensService: ModalOrdensService
  ) {
    super(service);
  }

  get simulatorVisibility() {
    return this.negotiationForm?.get('simulator_visibility')?.value;
  }

  onClose = (isCancel: boolean = false) => {
    if (
      this.selectTab.includes('SOUND_SETTINGS') &&
      this.isResetConfigNotifiations
    ) {
      isCancel && this.resetLabelsButtons();
      !isCancel && this.resetSounds();
      return;
    }

    !isCancel && this.saveConfigs();
    this._standaloneModalsService.close();
  };

  private saveConfigs(): void {
    this.selectTab.includes('SOUND_SETTINGS') && this.saveSounds();
    !!this.negotiationForm &&
      Object.keys(this.negotiationForm.value).forEach((item: any) =>
        this.handlePreference(item, this.negotiationForm)
      );
    !!this.confirmationsForm &&
      Object.keys(this.confirmationsForm.value).forEach((item: any) =>
        this.handlePreference(item, this.confirmationsForm)
      );
  }

  ngOnInit() {
    this.setConfigTabs();
    this.setNegotiationPermissions();
    this.labelButtonConfirm = 'Confirmar';
    this.labelButtonCancel = 'Cancelar';
    this.isResetConfigNotifiations = false;
    this._buildNegotiationForm();
  }

  private setNegotiationPermissions() {
    this.negotiationsPermissions =
      this.modalOrdensService.getNegotiationPermissions();
  }

  private setConfigTabs() {
    this.modalTabs = this.modalOrdensService.getConfigTabs();
  }

  private _buildConfirmationForm(): void {
    const { reset, invert } =
      this._customPreferencesService.confirmattionInvertAndResetPosition;
    this.confirmationsForm = this._fb.group({
      invert: [invert],
      reset: [reset],
      cancel: [this._customPreferencesService.confirmationCancelOrder],
      schedule_order: [
        this._customPreferencesService.displayScheduleOrderConfirmation,
      ],
      display_split_confirmation: [
        !this._customPreferencesService.skipSplitConfirmation,
      ],
      audio_notification: [this._customPreferencesService.audioNotification],
    });
  }

  private _buildNegotiationForm(): void {
    this.negotiationForm = this._fb.group({
      simulator_visibility: [this._joystickService.visibilitySimulatorMenu],
      use_split: [this._customPreferencesService.useSplit],
      qtty_group_stock: [this._customPreferencesService.saveQttyGroupStocks],
      qtty_trading_offset: [this._customPreferencesService.tradingOffset],
    });
  }

  private handlePreference(
    type: ORDER_CONFIRM_ACTIONS_ENUM,
    form: FormGroup
  ): void {
    const value = form.get(type)?.value;
    switch (type) {
      case ORDER_CONFIRM_ACTIONS_ENUM.SCHEDULE:
        this._customPreferencesService.displayScheduleOrderConfirmation = value;
        break;
      case ORDER_CONFIRM_ACTIONS_ENUM.INVERT:
        this._customPreferencesService.diplayInvertPositionConfirmattion =
          value;
        break;
      case ORDER_CONFIRM_ACTIONS_ENUM.CANCEL:
        this._customPreferencesService.diplayCancelOrderConfirmattion = value;
        break;
      case ORDER_CONFIRM_ACTIONS_ENUM.RESET:
        this._customPreferencesService.diplayResetPositionConfirmattion = value;
        break;
      case ORDER_CONFIRM_ACTIONS_ENUM.SIMULATOR_VISIBILITY:
        this.handleSimulator(value);
        break;
      case ORDER_CONFIRM_ACTIONS_ENUM.USE_SPLIT:
        this._customPreferencesService.useSplit = value;
        break;
      case ORDER_CONFIRM_ACTIONS_ENUM.DISPLAY_SPLIT_CONFIRMATION:
        this._customPreferencesService.skipSplitConfirmation = !value;
        break;
      case ORDER_CONFIRM_ACTIONS_ENUM.QTTY_TRADING_OFFSET:
        this._customPreferencesService.tradingOffset = value;
        break;
      case ORDER_CONFIRM_ACTIONS_ENUM.QTTY_GROUP_STOCK:
        this.handleQttyGroupStock(value);
        break;
      default:
        break;
    }
  }

  public onChangeTab(tab: string): void {
    this.selectTab = tab;
    if (this.selectTab.includes('SOUND_SETTINGS')) {
      this.audioNotification = this._customPreferencesService.audioNotification;
      this.soundSelect =
        this._customPreferencesService.configSoundsNotifications.split(',');
    }
    !tab.includes('NEGOTIATION') &&
      !this.confirmationsForm &&
      this._buildConfirmationForm();
  }

  public handleSimulator(displaySimulator: boolean): void {
    this._joystickService.visibilitySimulatorMenu = displaySimulator;
    !displaySimulator && (this._joystickService.simulator = false);
  }

  public handleQttyGroupStock(enableQttyGroupStocks: boolean): void {
    this._customPreferencesService.saveQttyGroupStocks = enableQttyGroupStocks;
  }

  public selectSound(sound: number, position: number): void {
    this.soundSelect[position] = `${sound}`;
  }

  public resetDefaultSounds(): void {
    this.isResetConfigNotifiations = true;
    this.labelButtonConfirm = 'Sim, restaurar padrão';
    this.labelButtonCancel = 'Talvez depois';
    for (let i = 0; this.modalTabs.length > i; i++) {
      i < 2 && (this.modalTabs[i].active = false);
      i === 2 && (this.modalTabs[i].active = true);
    }
  }

  public resetSounds(): void {
    this.audioNotification = true;
    for (let i = 0; this.soundSelect.length > i; i++) {
      this.soundSelect[i] = `${i}`;
    }
    this.resetLabelsButtons();
  }

  private resetLabelsButtons(): void {
    this.labelButtonConfirm = 'Confirmar';
    this.labelButtonCancel = 'Cancelar';
    this.isResetConfigNotifiations = false;
  }

  private saveSounds(): void {
    this._customPreferencesService.audioNotification = this.audioNotification;
    this._customPreferencesService.configSoundsNotifications =
      this.soundSelect.toString();
  }

  public async playSound(name: string): Promise<void> {
    const sound = new Audio(`assets/sons/${name}.mp3`);
    sound.load();
    await sound.play();
  }

  public disabledEnabledAudio(event: any): void {
    this.audioNotification = event;
  }
}
