import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipsModule } from '../tooltips/tooltips.module';
import { RocketModelSaveComponent } from './model-save.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    TooltipsModule,
  ],
  declarations: [RocketModelSaveComponent],
  exports: [RocketModelSaveComponent],
})
export class RocketModelSaveModule {}
