import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OutsideClickDirective } from '@core/workspace/directive/outside-click.directive';
import { RTColorPickerModule } from '@shared/components/color-picker/color-picker.module';
import { StandaloneModalsService } from '@shared/modals/standalone-modals.service';
import {
  FlaTagModule,
  ROCKET_MODAL_DATA,
  RocketButtonModule,
  RocketModalModule,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { RTInputCountModule } from '@shared/rocket-components/input-count/input-count.module';
import { Subject, debounceTime, take, timer } from 'rxjs';
import { ITradesColorsHighlights } from '../interface/trades-filter.interface';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { ISearchStock } from '@core/interface';
import { TradesFilterService } from '../service/trades-filter.service';
import { FlaToastService } from '@shared/rocket-components/toast';

@Component({
  selector: 'app-trades-filter-modal',
  templateUrl: './trades-filter-modal.component.html',
  styles: [
    `
      :host {
        ::ng-deep.input-group {
          width: 100px;
        }

        .colors-rules {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 8px;
        }

        .color-preview {
          width: 32px;
          height: 32px;
          cursor: pointer;
        }

        .color-picker-content {
          bottom: 0px;
          z-index: 1;
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
        }
      }
    `,
  ],
  standalone: true,
  imports: [
    CommonModule,
    RocketModalModule,
    RocketButtonModule,
    FlaTagModule,
    RTInputCountModule,
    RTColorPickerModule,
    OutsideClickDirective,
    TooltipsModule,
  ],
})
export class TradesFilterModalComponent
  extends RocketModalRef
  implements OnInit, OnDestroy
{
  private newQttyFilter = 1;
  private _updateColorSubject = new Subject<{ index: number; color: string }>();
  private _updateQttyFilterSubject = new Subject<{
    isColorQtty: boolean;
    index: number;
    value: number;
  }>();
  private readonly _defaultColorFilter: ITradesColorsHighlights = {
    qtty: 100,
    color: '#13141B',
    displayColorPicker: false,
  };

  public qttyFilter = 100;
  public hasSomePicketVisible = false;
  public canCreateMoreColor = true;
  public canReset = false;
  public loteError = false;
  public errorOnColorsHighlights = false;
  public errorOnColorsQtty = false;

  public colorsHighlights: ITradesColorsHighlights[] = [];

  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: { stock: ISearchStock },
    private modalService: RocketModalService,
    private _standaloneModalService: StandaloneModalsService,
    private _tradesFilterService: TradesFilterService,
    private _toast: FlaToastService
  ) {
    super(modalService);
    this._updateColorSubject
      .pipe(debounceTime(500))
      .subscribe((value) => this._updateColor(value));
    this._updateQttyFilterSubject
      .pipe(debounceTime(500))
      .subscribe((params) => {
        if (params.isColorQtty) {
          this._onUpdateColorLot(params.index, params.value);
          return;
        }
        this._onUpdateLotQtty(params.value);
      });
  }

  ngOnInit() {
    this._getStockTradesConfig();
  }

  override ngOnDestroy(): void {
    this._updateColorSubject.unsubscribe();
    this._updateQttyFilterSubject.unsubscribe();
    super.ngOnDestroy();
  }

  public onClose(callCloseModal: boolean = true): void {
    if (callCloseModal) this._standaloneModalService.close();
  }

  private _getStockTradesConfig(): void {
    const config = structuredClone(
      this._tradesFilterService.getConfigByStock(
        this.data.stock.cd_stock,
        this.data.stock.cd_stock_order
      )
    );
    if (!config?.filters && !config?.colors) {
      this._prepareDefaultOptions();
      return;
    }
    if (config.colors) {
      this.colorsHighlights = config.colors;
      this.canReset = true;
      this._checkColorsQtty();
    }

    if (config.filters) {
      this.qttyFilter = config.filters[0].qtty;
      this.newQttyFilter = config.filters[0].qtty;
      this.canReset = true;
      return;
    }
    this.qttyFilter = this.data.stock.standard_lot;
    this.newQttyFilter = this.data.stock.standard_lot;
  }

  private _prepareDefaultOptions(): void {
    this.qttyFilter = this.data.stock.standard_lot;
    this.newQttyFilter = this.data.stock.standard_lot;
    this.colorsHighlights = [
      this._getNewDefaultHighlight(),
      this._getNewDefaultHighlight(),
      this._getNewDefaultHighlight(),
    ];
  }

  private _getNewDefaultHighlight(): ITradesColorsHighlights {
    return structuredClone({
      qtty: this.data.stock.standard_lot,
      color: '#13141B',
      displayColorPicker: false,
      isDefaultOption: true,
    });
  }

  // Filter
  public updateLotQtty(form: FormControl): void {
    this._updateQttyFilterSubject.next({
      value: form.value,
      isColorQtty: false,
      index: 0,
    });
  }

  private _onUpdateLotQtty(value: number): void {
    this.loteError = value < this.data.stock.standard_lot;
    this.newQttyFilter = value;
  }

  // Color
  public addColor(): void {
    if (!this.canCreateMoreColor) return;
    this.colorsHighlights.push(this._getNewDefaultHighlight());
    this._checkColorsQtty();
  }

  public removeColor(indexToRemove: number): void {
    this.colorsHighlights = this.colorsHighlights.filter(
      (_, index) => index !== indexToRemove
    );
    this.errorOnColorsHighlights = false;
    this._checkColorsQtty();
  }

  public updateColorLot(index: number, form: FormControl): void {
    this._updateQttyFilterSubject.next({
      value: form.value,
      isColorQtty: true,
      index,
    });
  }

  private _onUpdateColorLot(index: number, value: number): void {
    this.colorsHighlights[index].qtty = value;
    this.colorsHighlights[index].isDefaultOption = false;
    this.colorsHighlights[index].invalidQttyValue =
      value < this.data.stock.standard_lot;
    this.errorOnColorsQtty = this._haveSomeInvalidColorQtty();
    if (this.errorOnColorsHighlights) this.errorOnColorsHighlights = false;
  }

  private _haveSomeInvalidColorQtty(): boolean {
    return this.colorsHighlights.some((item) => item.invalidQttyValue === true);
  }

  public displayColorPicker(index: number): void {
    this.colorsHighlights[index].displayColorPicker = true;
    timer(100)
      .pipe(take(1))
      .subscribe(() => (this.hasSomePicketVisible = true));
  }

  public onUpdateColor(index: number, color: string): void {
    this._updateColorSubject.next({ index, color });
  }

  public closeAndSaveColor(index: number): void {
    this.colorsHighlights[index].displayColorPicker = false;
    this.hasSomePicketVisible = false;
  }

  private _checkColorsQtty(): void {
    this.canCreateMoreColor = this.colorsHighlights.length < 6;
  }

  private _updateColor(value: { index: number; color: string }): void {
    this.colorsHighlights[value.index].color = value.color;
    this.colorsHighlights[value.index].isDefaultOption = false;
    this.errorOnColorsHighlights = false;
  }

  // Save
  public onSave(): void {
    if (this.newQttyFilter < this.data.stock.standard_lot)
      this.loteError = true;
    if (this._hasDuplicateQtty()) this.errorOnColorsHighlights = true;
    if (this.loteError || this.errorOnColorsHighlights) return;
    this._buildObjectAndSave();
  }

  public resetConfigs(): void {
    this._prepareDefaultOptions();
    this._tradesFilterService.deleteConfigsByStock(
      this.data.stock.cd_stock,
      this.data.stock.cd_stock_order
    );
    this.canReset = false;
  }

  private _hasDuplicateQtty(): boolean {
    const qttySet = new Set<number>();
    const colorsSet = new Set<string>();
    for (const item of this.colorsHighlights) {
      if (item.isDefaultOption) continue;
      if (qttySet.has(item.qtty) || colorsSet.has(item.color)) return true;
      qttySet.add(item.qtty);
      colorsSet.add(item.color);
    }
    return false;
  }

  private _buildObjectAndSave(): void {
    let config: any = {},
      filterObj,
      colorsObj = {};
    if (this.newQttyFilter > this.data.stock.standard_lot)
      filterObj = { filters: [{ qtty: this.newQttyFilter, type: '>=' }] };
    if (this.colorsHighlights.length) {
      const colors = this._getColorsHighlightItems();
      if (colors.length) colorsObj = { colors };
    }
    config = {
      ...filterObj,
      ...colorsObj,
    };
    this._tradesFilterService.saveConfigs(
      this.data.stock.cd_stock,
      this.data.stock.cd_stock_order,
      config
    );
    this._toast.show({
      isHtml: false,
      type: 'success',
      text: 'Configuração salva com sucesso.',
    });
    this._standaloneModalService.close();
  }

  private _getColorsHighlightItems(): { color: string; qtty: number }[] {
    return this.colorsHighlights
      .filter((rule) => !rule.isDefaultOption)
      .map((rule) => ({
        color: rule.color,
        qtty: rule.qtty,
      }))
      .sort((a, b) => a.qtty - b.qtty);
  }
}
