<workspace-doc-footer css="height-28" *ngIf="configs">
  <div class="h-100 w-100 px-3">
    <div class="d-flex h-100 align-items-center">
      <div class="d-flex justify-content-start w-100 ml-n2">
        <app-logo [height]="30" [width]="133"></app-logo>
      </div>
      <div
        class="d-flex justify-content-end align-items-center w-100 h-100 gap-2"
      >
        <div
          class="h-100"
          *ngIf="displayFastRuler"
          [tooltip]="fastRulerTooltip"
          [tooltipIsAdaptive]="false"
        >
          <button
            class="p-0 btn-svg log-button btn btn-icon btn-sm btn-sized"
            [ngClass]="{
              'text-brand-primary': fastRuler,
              'text-white': !fastRuler
            }"
            [innerHtml]="svgButtons.ruler | safeHtml"
            (click)="clickChartConfig(tFooterType.RULER, !fastRuler)"
          ></button>
        </div>
        <div
          class="border-right pr-1 h-100"
          [tooltip]="zoomInTooltip"
          [tooltipIsAdaptive]="false"
        >
          <button
            class="p-0 btn-svg log-button btn btn-icon btn-sm btn-sized"
            [ngClass]="{
              'text-brand-primary': zoomIn,
              'text-white': !zoomIn
            }"
            [innerHtml]="svgButtons.zoom | safeHtml"
            (click)="click(tFooterType.ZOOM_IN)"
          ></button>
        </div>

        <section
          *ngFor="let key of sectionButtons.keys()"
          [id]="key"
          class="d-inline-flex gap-2 h-100"
        >
          <div
            *ngFor="let buttonKey of objectKeys(sectionButtons.get(key)!)!"
            class="pr-1 h-100"
            [tooltip]="sectionButtons.get(key)[buttonKey].tooltip"
            [tooltipIsAdaptive]="false"
            [ngClass]="sectionButtons.get(key)[buttonKey].css"
          >
            <button
              class="p-0 btn-svg log-button btn btn-icon btn-sm btn-sized small"
              [ngClass]="{
                'text-brand-primary': sectionButtons.get(key)[buttonKey].bool,
                'text-white': !sectionButtons.get(key)[buttonKey].bool
              }"
              (click)="click(sectionButtons.get(key)[buttonKey].event)"
              [innerHtml]="sectionButtons.get(key)[buttonKey].svg | safeHtml"
            ></button>
          </div>
        </section>
        <section class="d-inline-flex gap-2">
          <div
            class="h-100"
            [tooltip]="freeScaleTooltip"
            [tooltipIsAdaptive]="false"
          >
            <rocket-button
              label="AUTO"
              [css]="
                'log-button p-0 ' +
                (!freeScale ? 'text-brand-primary' : 'text-white')
              "
              type="fill"
              (rocketClick)="click(tFooterType.SCALE)"
            >
            </rocket-button>
          </div>
          <div
            class="h-100"
            [tooltip]="isLogTooltip"
            [tooltipIsAdaptive]="false"
          >
            <rocket-button
              label="LOG"
              [css]="
                'log-button p-0 ' + (log ? 'text-brand-primary' : 'text-white')
              "
              type="fill"
              (rocketClick)="click(tFooterType.LOG)"
            ></rocket-button>
          </div>
        </section>
      </div>
    </div>
  </div>
</workspace-doc-footer>
