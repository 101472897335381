import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
  Renderer2,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  WritableSignal,
  signal,
  computed,
  AfterViewInit,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { ISearchStock, IWorkSpaceComponet } from '@core/interface';
import { DEFAULT_OPTIONS_TABS, TOptionsTab } from '../../types';
import { SearchStockComponent } from '@shared/components/search-stock/search-stock.component';
import { DragService } from '@shared/rocket-components/services/ag-grid/drag.service';
import { Subject, Subscription, delay, tap } from 'rxjs';
import { HomeService } from '@modules/home/service/home.service';

@Component({
  selector: 'app-options-header',
  templateUrl: './options-header.component.html',
  styleUrls: ['./options-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsHeaderComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() set component(component: IWorkSpaceComponet) {
    if (component) {
      this._component.set(component);
    }
  }
  _component: WritableSignal<IWorkSpaceComponet | undefined> =
    signal(undefined);
  @Input() refComponent!: string;
  @Input() linked!: boolean;
  @Input() selectedTab!: TOptionsTab;
  @Input() disableChangeStock: boolean = true;
  @Output() tabChange: EventEmitter<TOptionsTab> =
    new EventEmitter<TOptionsTab>();
  @Output() stockChange: EventEmitter<ISearchStock> =
    new EventEmitter<ISearchStock>();
  @ViewChild('searchStock') searchStock!: SearchStockComponent;
  @ViewChild('dropList') dropList!: ElementRef<HTMLUListElement>;
  private dragEvents$!: Subscription;
  tabs: TOptionsTab[] = [];
  ignoreInitStock = false;
  editMode = false;
  private _newTab$ = new Subject<void>();
  private newTab$!: Subscription;
  private _ignoreInitStock$ = new Subject<void>();
  private ignoreInitStock$!: Subscription;
  private _selectStockByCdStock$ = new Subject<{
    cdStock: string;
  }>();
  private selectStockByCdStock$!: Subscription;
  private fixSelectDropdownPosition$!: Subscription;

  headerOptions = computed(() => {
    return this._component()?.metadata?.headerOptions;
  });

  constructor(
    private dragService: DragService,
    private renderer: Renderer2,
    private _homeService: HomeService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._initiateTabs();
    this.renderer.listen('window', 'click', (e: Event) => this._checkClick(e));
  }

  ngAfterViewInit() {
    this.dragEvents$ = this.dragService
      .onEvents(this._component()!!.id)
      .subscribe((data) => {
        this.selectStockByCdStock(data.data.cd_stock);
      });

    this.newTab$ = this._newTab$
      .pipe(
        tap(() => {
          const newId = this.tabs.reduce(
            (currentValue: number, tab: TOptionsTab) =>
              tab.id >= currentValue ? tab.id + 1 : currentValue,
            1
          );
          const newTab = { id: newId, editMode: true };
          this.tabs.push(newTab);
          this.tabChange.emit(newTab);
          this.cdr.detectChanges();
        }),
        delay(1)
      )
      .subscribe(() => {
        this.searchStock && this.searchStock.focus();
        this.fixSelectDropdown()
        this.cdr.detectChanges();
      });

    this.ignoreInitStock$ = this._ignoreInitStock$
      .pipe(
        tap(() => {
          this.ignoreInitStock = true;
          this.editMode = true;
          this.cdr.detectChanges();
        }),
        delay(0)
      )
      .subscribe(() => {
        this.searchStock.focus();
        this.ignoreInitStock = false;
        this.fixSelectDropdown()
        this.cdr.detectChanges();
      });

    this.selectStockByCdStock$ = this._selectStockByCdStock$
      .pipe(delay(2))
      .subscribe((data) => {
        this.searchStock.selectStockByCdStock(data.cdStock);
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.dragEvents$ && this.dragEvents$.unsubscribe();
    this.newTab$ && this.newTab$.unsubscribe();
    this.ignoreInitStock$ && this.ignoreInitStock$.unsubscribe();
    this.selectStockByCdStock$ && this.selectStockByCdStock$.unsubscribe();
  }

  newTab() {
    this._newTab$.next();
  }

  removeEmptyNewTab() {
    this.tabs = this.tabs.filter(item => item.stock);
    this.tabChange.emit(this.tabs[0]);
    this._saveTabs()
  }

  //Função para corrigir posicionamento do dropdown-panel do ng-select por conta do scroll
  fixSelectDropdown() {
    this.fixSelectDropdownPosition$ = this.searchStock.select.selectInput.openEvent
    .asObservable()
    .pipe(delay(0))
    .subscribe(() => {
      const id = this.searchStock.select.selectInput.dropdownId;
      const dropdownPanel = document.getElementById(id);
      if (dropdownPanel) {
        dropdownPanel.style.left =
          this.searchStock.el.nativeElement.offsetLeft -
          this.dropList.nativeElement.scrollLeft +
          'px';
      }
      this.cdr.detectChanges();
    });
  }

  onItemClick(tab: TOptionsTab) {
    if (tab.id !== this.selectedTab.id) {
      this.tabChange.emit(tab);
      this.editMode = false;
      this.fixSelectDropdownPosition$?.unsubscribe()
      return;
    }
    this._ignoreInitStock$.next();
  }

  close(tabIndex: any) {
    if (this.tabs[tabIndex - 1]) {
      this.tabChange.emit(this.tabs[tabIndex - 1]);
    }
    this.tabs.splice(tabIndex, 1);
    this.tabChange.emit(this.tabs[0]);
    this._saveTabs();
    this.cdr.detectChanges();
  }

  selectStock(value: ISearchStock, tabIndex: number) {
    if (value.cd_stock == this.selectedTab.stock?.cd_stock) return;
    this.editMode = false;
    this.tabs[tabIndex].stock = value;
    this.fixSelectDropdownPosition$?.unsubscribe();
    this.stockChange.emit(value);
    this._saveTabs();
  }

  private _initiateTabs() {
    if (this._component()!!?.metadata?.component?.tabs) {
      this.tabs = this._component()!!.metadata.component.tabs;
      this.tabChange.emit(
        this._component()!!.metadata.component.selectedTab
          ? this._component()!!.metadata.component.selectedTab
          : this.tabs[0]
      );
      this.cdr.detectChanges();
      return;
    }
    this.tabs = structuredClone(DEFAULT_OPTIONS_TABS);
    this.tabChange.emit(this.tabs[0]);
    this.cdr.detectChanges();
  }

  private _checkClick(e: Event) {
    if (!this.editMode) return;
    const element = e.target as HTMLElement;
    if (!element.classList.contains('opt-tab-label')) {
      this.editMode = false;
      this.fixSelectDropdownPosition$?.unsubscribe()
      this.cdr.detectChanges();
    }
  }

  selectStockByCdStock(cdStock: string) {
    this.newTab();
    this._selectStockByCdStock$.next({
      cdStock,
    });
  }

  private _saveTabs() {
    this._component()!!.metadata.component = {
      ...this._component()!!.metadata.component,
      tabs: this.tabs,
    };
    this._homeService.updateMeta(this._component()!!);
    this.cdr.detectChanges();
  }
}
