import { Injectable } from '@angular/core';
import {
  CHEETAH_CHANNEL,
  EXTERNAL_NEWS_CHEETAH_FIELDS,
  NEWS_FIELDS,
} from '@shared/cheetah/cheetah.const';
import { MarketDispatcher } from '@shared/cheetah/dispatcher/market.dispatcher';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { RxEvent } from './rx-event';

@Injectable({
  providedIn: 'root',
})
export class NewsChannel {
  static instance: NewsChannel;

  constructor(
    private _rxEvent: RxEvent,
    private cheetahService: MarketDispatcher
  ) {
    return (NewsChannel.instance = NewsChannel.instance || this);
  }

  onEvents() {
    return this._rxEvent.read(CHEETAH_CHANNEL.NEWS);
  }

  subscribe(params: SubscribeParam) {
    params.fields = `${NEWS_FIELDS},${EXTERNAL_NEWS_CHEETAH_FIELDS}`;
    params.channel_cheetah = CHEETAH_CHANNEL.NEWS;
    this.cheetahService.subscribe(params);
  }

  unsubscribe(params: SubscribeParam) {
    if (!params) return;
    this.cheetahService.unsubscribe({
      channel_cheetah: CHEETAH_CHANNEL.NEWS,
      itemsArray: params.itemsArray,
    });
  }
}
