import { IHeatmapOptions } from '@shared/components/heatmap-chart/services/lists.service';
import { CSSObject } from 'highcharts';
import { THeatmapConfig } from '../../heatmap-chart/types';

export const DATALABEL_STYLES: CSSObject = {
  textOutline: 'none',
  cursor: 'pointer',
  textDecoration: 'none',
  fontFamily: 'Metropolis',
  fontSize: '9px',
  fontWeight: 'normal',
  textAlign: 'center',
};

export const CHART_OPTIONS: IHeatmapOptions = {
  datalabels: {
    enabled: true,
    align: 'center',
    verticalAlign: 'middle',
    style: DATALABEL_STYLES,
  },
  levels: [],
};

export const HEATMAP_PREFERENCES_MARKET_WS: THeatmapConfig = {
  period: 'ONEDAY',
  source: 'BR',
  isNotListPersonal: false,
  idList: undefined,
};
