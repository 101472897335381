import { formatterNumber } from '@shared/rocket-components/utils';
import { IStockListItemsDynamic } from 'src/app/core/interface';
import { execFormatFinancial } from 'src/app/utils/utils.functions';

export class StockListItemsModel {
  cdStock!: string;
  dayVariation!: string;
  idExchange!: number;
  isSynonymous!: boolean;
  nmCompany!: string;
  synonymousNickname!: string;
  idSecurityType!: string;
  dsAsset!: string;
  valueClose!: string;
  isCheck!: boolean;
  arrowFont!: string;
  arrowHex!: string;
  logoCompany!: string;
  tickSizeDenominator!: number;
  valueCloseFormatted!: string;
  formatPrice!: {
    cd_stock: any;
    id_exchange: any;
    tick_size_denominator: any;
  };
  cdStockOrder!: string;
  stockLabel!: string;

  constructor(stock: IStockListItemsDynamic | any, isCheck: boolean) {
    this.cdStock = stock.cd_stock;
    this.dayVariation = this.parseDayVariation(
      stock?.stockVariation || stock?.day_variation
    );
    this.idExchange = stock.id_exchange;
    this.isSynonymous = stock?.is_synonymous;
    this.nmCompany = stock?.nm_company || stock?.nm_company_exchange;
    this.synonymousNickname = stock?.synonymous_nickname;
    this.valueClose = stock?.stockVariation?.vl_close ?? stock?.vl_close;
    this.isCheck = isCheck ?? false;
    this.arrowFont = stock.arrowFont ?? '';
    this.arrowHex = stock.arrowHex ?? '';
    this.logoCompany = stock?.logo_company || stock?.company_logo;
    this.tickSizeDenominator = stock?.tick_size_denominator;
    this.idSecurityType = stock.id_security_type ?? '';
    this.dsAsset = stock.ds_asset ?? '';
    this.formatPrice = {
      cd_stock: stock.cd_stock,
      id_exchange: stock.id_exchange,
      tick_size_denominator: stock?.tick_size_denominator,
    };
    this.valueCloseFormatted = execFormatFinancial(
      this.formatPrice,
      this.valueClose
    );
    this.cdStockOrder = stock?.is_synonymous ? stock.cd_stock_order : '';
    this.stockLabel = stock?.stockLabel;
  }

  private parseDayVariation(variation: any): string {
    if (typeof variation === 'object') {
      return formatterNumber(+variation.day_variation);
    }
    return variation ? formatterNumber(+variation) : '0,00';
  }
}
