import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ICandleConfigs } from '@shared/tiger-chart/interface/tiger-chart.interface';
import { ChartBase } from '@shared/components/stock-chart/model/stock-chart.model';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { objectAssign } from 'src/app/utils/utils.functions';
import { TYPE_CHART_UPDATE } from '../enum/stock-chart-modal.enum';
import { TOOLS_TYPE_DRAW } from '@shared/tiger-chart/tiger-chart-tools/tiger-chart-tools.enum';
import { color } from '@shared/tiger-chart/tiger-chart-tools/toolbar-draw/toolbar-draw.interface';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import { StockChartModalService } from '../service/stock-chart-modal.service';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { COLORS_CHART } from '../interface/stock-chart-modal.interface';
@Component({
  selector: 'app-stock-chart-modal-config-volume',
  templateUrl: './stock-chart-modal-config-volume.component.html',
})
export class StockChartModalConfigVolumeComponent
  extends ChartBase
  implements OnInit, AfterViewInit
{
  color: EventEmitter<COLORS_CHART> = new EventEmitter<COLORS_CHART>();
  @Input() refComponent!: string;
  @Input() configs!: Partial<ICandleConfigs>;
  form!: FormGroup;
  toolsTypeDraw = TOOLS_TYPE_DRAW;
  volumeDefaultColors: any = {
    volumeUpColor: CHART_COLORS.FEEDBACK_POSITIVE + '80',
    volumeDownColor: CHART_COLORS.FEEDBACK_NEGATIVE + '80',
  };
  showVolume: boolean = false;
  constructor(
    private _fb: FormBuilder,
    private service: StockChartService,
    private stockChartModalService: StockChartModalService,
    private _customPreferencesService: CustomPreferencesService
  ) {
    super();
  }

  ngOnInit(): void {
    const configs = JSON.parse(this._customPreferencesService.chartContextMenu);
    this.showVolume = configs?.volumeVisibility;
    this.form = this._fb.group({
      volumeUpColor: [''],
      volumeDownColor: [''],
      showVolume: [this.showVolume],
    });
  }

  ngAfterViewInit() {
    const configuration: any =
      this.stockChartModalService.getGlobalChartConfigs();
    Object.keys(this.volumeDefaultColors).forEach((key) => {
      let value = this.volumeDefaultColors[key];
      if (key in configuration && configuration[key]) {
        const configValue = (configuration as any)[key];
        if (configValue != '') {
          value = (configuration as any)[key];
          //this.volumeDefaultColors[key] = value;
        }
      }
      this.form.controls[key].setValue(value);
      setTimeout(() => {
        const element = document.getElementById(`${key}Colorpicker`);
        if (element) {
          element.style.backgroundColor = value;
        }
      }, 1);
    });
  }

  showBorder(value: boolean) {
    this.configs = objectAssign(this.configs, {
      series: { candle: { showBorder: value } },
    });
    this._sendConfigs(TYPE_CHART_UPDATE.CANDLE_BORDER);
  }

  private _sendConfigs(type: TYPE_CHART_UPDATE) {
    this.service.eventConfigs$.next({
      ref: this.refComponent,
      config: {
        ...this.configs,
        ...{
          volumeUpColor: this.form.value['volumeUpColor'],
          volumeDownColor: this.form.value['volumeDownColor'],
          showVolume: this.form.value['showVolume'],
        },
      },
      save: false,
      global: true,
      typeUpdate: type,
    });
  }

  selectedColor(
    field: string,
    event: { color: color; typeColorUpdate?: TOOLS_TYPE_DRAW }
  ) {
    this.form.controls[field].setValue(event.color.rgbaHexColor);
    this.updateElementColor(field);
    this.stockChartModalService.updateGlobalChartConfigs({
      [field]: event.color.rgbaHexColor,
    });
    this._sendConfigs(TYPE_CHART_UPDATE.VOLUME_COLOR);
    this.color.emit({ field, event });
  }

  private updateElementColor(field: string) {
    const element = document.getElementById(`${field}Colorpicker`);
    if (element) {
      element.style.backgroundColor = this.form.value[field];
    }
  }

  updateAllElements() {
    Object.keys(this.volumeDefaultColors).forEach((key) => {
      this.updateElementColor(key);
    });
  }

  changeVisibleVolume() {
    this._sendConfigs(TYPE_CHART_UPDATE.SHOW_VOLUME);
  }
}
