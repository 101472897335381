import { assignChannelObject } from 'src/app/utils/utils.functions';

export class Dictionary<T> {
  private items = new Map<string | number, T>();
  public has(key: string | number) {
    return this.items.has(key);
  }
  public values() {
    return Array.from(this.items.values());
  }

  public filter(param: string, values: any) {
    return Array.from(this.items.values()).filter(
      (i: any) => i[param] === values
    );
  }
  public size() {
    return this.items.size;
  }
  public keys() {
    return Array.from(this.items.keys());
  }
  public nextItem(key: string | number) {
    const t = Array.from(this.items.entries());
    const id: string | number = t.find((i) => i[0] !== key)![0];
    return this.get(id);
  }
  public clear() {
    return this.items.clear();
  }

  public set(
    key: string | number,
    value: Partial<T>,
    canBeUndefined: boolean = false
  ) {
    const exist = this.get(key);
    const obj = value as T;
    this.items.set(
      key,
      exist ? assignChannelObject(exist, value, canBeUndefined) : obj
    );
  }

  bulkData(keyField: string, data: T[]) {
    data.map((item: any) => {
      this.set(item[keyField], item);
    });
  }

  public get(key: string | number) {
    return this.items.get(key);
  }

  public delete(key: string | number) {
    if (this.has(key)) {
      this.items.delete(key);
      return true;
    }
    return false;
  }

  toJSON() {
    return JSON.stringify(Object.fromEntries(this.items));
  }

  JSONtoMap(json: string) {
    const obj = JSON.parse(json);
    this.items = new Map(Object.entries(obj));
  }
}
