import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '@shared/services/rest/rest.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvestorParticipationService extends RestService {
  override _url: string = 'api/trademap';

  constructor(http: HttpClient) {
    super(http);
  }

  public getAtualB3InvestorStatus = (): Observable<any> =>
    this.post<any>(
      'v9/investorParticipation/get-atual-b3-investor-status',
      {}
    ).pipe(
      map((res) => {
        if (res.data && res.data?.success && res.data?.result) {
          return res.data.result;
        }
        return null;
      })
    );

  public getFuturos = (): Observable<any> =>
    this.post<any>('v9/investorParticipation/get-futuros', {}).pipe(
      map((res) => {
        if (res.data && res.data?.success && res.data?.result) {
          return res.data.result;
        }
        return null;
      })
    );

  public getIpoData = (): Observable<any> =>
    this.post<any>('v9/investorParticipation/get-ipo-data', {}).pipe(
      map((res) => {
        if (res.data && res.data?.success && res.data?.result) {
          return res.data.result;
        }
        return null;
      })
    );
}
