import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appOutsideClick]',
  standalone: true,
})
export class OutsideClickDirective {
  @Input() isFocused: boolean = false;
  @Output() outsideClick = new EventEmitter<void>();

  constructor(private renderer: Renderer2, private _element: ElementRef) {
    this.renderer.listen('window', 'click', (event: Event) => {
      if (
        this.isFocused &&
        !this._element.nativeElement.contains(event.target)
      ) {
        this.outsideClick.emit();
        return;
      }
    });
  }
}
