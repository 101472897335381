import { Injectable } from '@angular/core';
import { CHEETAH_CHANNEL } from '@shared/cheetah/cheetah.const';
import { MarketDispatcher } from '@shared/cheetah/dispatcher/market.dispatcher';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { RxEvent } from './rx-event';

@Injectable({
  providedIn: 'root',
})
export class OrderChartChannel {
  instanciated!: boolean;

  static instance: OrderChartChannel;
  static channel = CHEETAH_CHANNEL.ORDER_CHART;

  constructor(
    private _rxEvent: RxEvent,
    private cheetahService: MarketDispatcher
  ) {
    return (OrderChartChannel.instance = OrderChartChannel.instance || this);
  }

  async onEvents() {
    const data = await this._rxEvent.read(OrderChartChannel.channel);
    return data;
  }

  subscribe(params: SubscribeParam) {
    this.cheetahService.subscribe({
      channel_cheetah: OrderChartChannel.channel,
      itemsArray: params.itemsArray,
    });
  }

  unsubscribe(params: SubscribeParam) {
    if (!params) return;
    this.cheetahService.unsubscribe({
      channel_cheetah: OrderChartChannel.channel,
      itemsArray: params.itemsArray,
    });
  }
}

export interface IOrderChartData {
  item: string;
  key: string;
  command: 'ADD' | 'UPDATE';
  qtty: string;
  side: string;
  action: string;
  tipo_ordem: string;
  exec_price: string;
  dh_creation: string;
  dh_last_update: string;
  pnl: string;
  pnl_open: string;
  is_open: string | boolean;
  formatted_date: Date;
  delta_tempo: string;
  idOrder?: string;
  isEndOfSnap?: boolean;
}
