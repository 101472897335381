import { COLUMNS_CONFIG } from './headers.const';
import { ORDERS_HISTORY_ELEMENT_IDS } from '../enum/orders-history.enum';
import { COLORS } from '@shared/constants/colors.const';

export const SIDE: any = {
  B: 'Compra',
  S: 'Venda',
};

export const SIDE_CHARACTER: any = {
  B: 'C',
  S: 'V',
};

export const SIDE_TEXT_COLOR: any = {
  B: 'text-multibroker-buy',
  S: 'text-multibroker-sell',
};

export const keyCustody: any = {
  INTRADIA: 'has_day_trade',
  SWINGTRADING: 'has_swing',
};

export const ALERT_TEXT_COLOR: any = 'text-brand-support-secondary';

export const FIELDS_CHEETAH: string = 'preco_ultimo';

export enum TP_STATUS {
  ABERTA = 'ABERTA',
  FECHADA = 'FECHADA',
  // Total não vem do serviço
  TOTAL = 'TOTAL',
  EXECUTADA = 'EXECUTADA',
}

export const ORDER_STATE: any = {
  ABERTA: [
    'AUTH',
    'PEND',
    'AGUA',
    'PARC',
    'PROC',
    'ALTE',
    'ACPT',
    'PEAL',
    'PECA',
    'SALT',
  ],
  EXECUTADA: ['EXEC'],
  FECHADA: ['PCAN', 'EXEC', 'CANC', 'REJE'],
};

export const TABS_REF: any = {
  POSITION: 'position',
  SWINGTRADING: 'swingtrading',
  INTRADIA: 'intradia',
  ORDER_OPEN: 'openOrder',
  ABERTA: 'openOrder',
  ORDER_EXEC: 'execOrder',
  EXECUTADA: 'execOrder',
  ORDER_CLOSE: 'closeOrder',
  FECHADA: 'closeOrder',
  ORDER_TOTAL: 'total',
  ALERTS: 'alerts',
};

export const GRID_EMPTY_MESSAGE: any = {
  PORTFOLIO: 'Não existe custódia.',
  INTRADIA: 'Não existe posição intradia.',
  SWINGTRADING: 'Não existe posição swing.',
  ORDER: 'Não existem ordens.',
  ALERTS: 'Não existem alertas.',
};

export enum TABS_TYPE {
  POSITION = 'POSITION',
  INTRADIA = 'INTRADIA',
  SWINGTRADING = 'SWINGTRADING',
  ORDER = 'ORDER',
  ALERTS = 'ALERTS',
}

export const TABS: any = {
  // this.fieldIndexer = (tab !== TABS.POSITION) ? 'id_order' :
  POSITION: {
    idTab: ORDERS_HISTORY_ELEMENT_IDS.POSITION,
    ref: TABS_REF.POSITION,
    title: 'Posições',
    fieldIndexer: 'cd_stock',
    filterAccount: true,
    component_type: 'POSITION',
    type: 'POSITION',
    key: 'POSITION',
    isPosition: true,
    active: false,
    noRowsTemplate: GRID_EMPTY_MESSAGE.PORTFOLIO,
    columnDefs: COLUMNS_CONFIG.PORTFOLIO,
    lockColumns: ['key'],
    haveCircle: true,
    colorCircle: COLORS.CUSTODY,
  },
  INTRADIA: {
    idTab: ORDERS_HISTORY_ELEMENT_IDS.INTRADAY,
    ref: TABS_REF.INTRADIA,
    title: '{DayTrade}',
    fieldIndexer: 'cd_stock',
    type: 'INTRADIA',
    key: 'INTRADIA',
    component_type: 'INTRADIA',
    active: false,
    noRowsTemplate: GRID_EMPTY_MESSAGE.INTRADIA,
    columnDefs: COLUMNS_CONFIG.INTRADIA,
    lockColumns: ['key'],
    haveCircle: true,
    colorCircle: COLORS.CUSTODY,
    dayTradeText: true,
  },
  SWINGTRADING: {
    idTab: ORDERS_HISTORY_ELEMENT_IDS.SWING,
    ref: TABS_REF.SWINGTRADING,
    title: '{Swing}',
    fieldIndexer: 'cd_stock',
    type: 'SWINGTRADING',
    key: 'SWINGTRADING',
    component_type: 'SWINGTRADING',
    active: false,
    noRowsTemplate: GRID_EMPTY_MESSAGE.SWINGTRADING,
    columnDefs: COLUMNS_CONFIG.SWINGTRADING,
    lockColumns: ['key'],
    haveCircle: true,
    colorCircle: COLORS.CUSTODY,
    dayTradeText: true,
  },
  ORDER_OPEN: {
    idTab: ORDERS_HISTORY_ELEMENT_IDS.OPEN_ORDERS,
    ref: TABS_REF.ORDER_OPEN,
    title: 'Abertas',
    type: 'ABERTA',
    component_type: 'ORDER',
    key: 'ORDER_OPEN',
    fieldIndexer: 'id_order',
    active: false,
    noRowsTemplate: GRID_EMPTY_MESSAGE.ORDER,
    columnDefs: COLUMNS_CONFIG.OPEN_ORDERS,
    footerColumnDefs: COLUMNS_CONFIG.FOOTER_OPEN_ORDERS,
  },
  ORDER_EXEC: {
    idTab: ORDERS_HISTORY_ELEMENT_IDS.EXEC_ORDERS,
    ref: TABS_REF.ORDER_EXEC,
    title: 'Executadas',
    type: 'EXECUTADA',
    component_type: 'ORDER',
    key: 'ORDER_EXEC',
    fieldIndexer: 'id_order',
    active: false,
    noRowsTemplate: GRID_EMPTY_MESSAGE.ORDER,
    columnDefs: COLUMNS_CONFIG.ORDER,
    footerColumnDefs: COLUMNS_CONFIG.FOOTER_ORDERS,
  },
  ORDER_CLOSE: {
    idTab: ORDERS_HISTORY_ELEMENT_IDS.CLOSED_ORDERS,
    ref: TABS_REF.ORDER_CLOSE,
    title: 'Fechadas',
    type: 'FECHADA',
    component_type: 'ORDER',
    key: 'ORDER_CLOSE',
    fieldIndexer: 'id_order',
    active: false,
    noRowsTemplate: GRID_EMPTY_MESSAGE.ORDER,
    columnDefs: COLUMNS_CONFIG.ORDER,
    footerColumnDefs: COLUMNS_CONFIG.FOOTER_ORDERS,
  },
  ORDER_TOTAL: {
    idTab: ORDERS_HISTORY_ELEMENT_IDS.ALL_ORDERS,
    ref: TABS_REF.ORDER_TOTAL,
    title: 'Todas',
    type: 'TOTAL',
    component_type: 'ORDER',
    key: 'ORDER_TOTAL',
    fieldIndexer: 'id_order',
    active: false,
    noRowsTemplate: GRID_EMPTY_MESSAGE.ORDER,
    columnDefs: COLUMNS_CONFIG.ORDER,
    footerColumnDefs: COLUMNS_CONFIG.FOOTER_ORDERS,
  },
  ALERTS: {
    idTab: ORDERS_HISTORY_ELEMENT_IDS.ALERT,
    ref: TABS_REF.ALERTS,
    title: 'Alertas',
    fieldIndexer: 'cd_stock',
    type: 'ALERTS',
    key: 'ALERTS',
    component_type: 'ALERTS',
    active: false,
    noRowsTemplate: GRID_EMPTY_MESSAGE.ALERTS,
    columnDefs: COLUMNS_CONFIG.ALERTS,
    footerColumnDefs: COLUMNS_CONFIG.FOOTER_ALERTS,
    tabIcon: 'notifications',
  },
};

export const COLUMNS_TYPE = {
  PORTFOLIO: [TABS.POSITION],
  INTRADIA: [TABS.INTRADIA],
  SWINGTRADING: [TABS.SWINGTRADING],
  ORDER: [TABS.ORDER_OPEN, TABS.ORDER_EXEC, TABS.ORDER_CLOSE, TABS.ORDER_TOTAL],
  ALERTS: [TABS.ALERTS],
};

export const TAB_ORDER_STATUS: any = {
  openOrder: ORDER_STATE.ABERTA,
  execOrder: ORDER_STATE.EXECUTADA,
  closeOrder: ORDER_STATE.FECHADA,
};

export const POSITIONS_TOTAL_LINE: any = {
  key: 'Total',
  custodian_name: '0 Ativos',
  vl_price_avg: '',
  preco_ultimo: '',
  qtty_final: '0.0',
  net_day: '0.0',
  pnl_open: '0.0',
  pnl_day: '0.0',
  pnl: '0.0',
  pl: '0.0',
};

export const INTRADIA_TOTAL_LINE: any = {
  key: 'Total',
  custodian_name: '0 Ativos',
  vl_price_avg_daytrade: '',
  preco_ultimo: '',
  qtty_final_daytrade: '0.0',
  net_day_daytrade: '0.0',
  pnl_open_daytrade: '0.0',
  pnl_day_daytrade: '0.0',
  pnl_daytrade: '0.0',
  pl_daytrade: '0.0',
};

export const SWINGTRADE_TOTAL_LINE: any = {
  key: 'Total',
  custodian_name: '0 Ativos',
  vl_price_avg_swing: '',
  preco_ultimo: '',
  qtty_final_swing: '0.0',
  net_day_swing: '0.0',
  pnl_open_swing: '0.0',
  pnl_day_swing: '0.0',
  pnl_swing: '0.0',
  pl_swing: '0.0',
};

export const swingOrDayTradeFieldFilter = (
  isSwing: boolean,
  isQttyFinal: boolean
):
  | 'has_swing'
  | 'has_day_trade'
  | 'qtty_final_daytrade'
  | 'qtty_final_swing' => {
  if (isQttyFinal) return isSwing ? 'qtty_final_swing' : 'qtty_final_daytrade';
  return isSwing ? 'has_swing' : 'has_day_trade';
};

export const FIELDS_TO_CALC = {
  INTRADIA: [
    'qtty_final_daytrade',
    'net_day_daytrade',
    'pnl_open_daytrade',
    'pnl_day_daytrade',
    'pnl_daytrade',
    'pl_daytrade',
  ],
  SWINGTRADING: [
    'qtty_final_swing',
    'net_day_swing',
    'pnl_open_swing',
    'pnl_day_swing',
    'pnl_swing',
    'pl_swing',
  ],
  POSITION: ['qtty_final', 'net_day', 'pnl_open', 'pnl_day', 'pnl', 'pl'],
};

export const TOTAL_LINES: any = {
  INTRADIA: INTRADIA_TOTAL_LINE,
  SWINGTRADING: SWINGTRADE_TOTAL_LINE,
  POSITION: POSITIONS_TOTAL_LINE,
};

export const TYPE_TO_KEY = {
  ABERTA: 'ORDER_OPEN',
  EXECUTADA: 'ORDER_EXEC',
  FECHADA: 'ORDER_CLOSE',
  TOTAL: 'ORDER_TOTAL',
};

export const FIELD_INDEXER: any = {
  SWINGTRADING: 'key',
  POSITION: 'key',
  INTRADIA: 'key',
  ORDER: 'id_order',
  ALERTS: 'id_trade_system',
};

export interface CountUpdate {
  direction: string;
  tp_status: TP_STATUS;
  cd_order_status: string;
}

export enum COUNT_DIRECTIONS {
  UP = 'UP',
  DOWN = 'DOWN',
}

export function numberComparator(valueA: string, valueB: string) {
  if (!valueA && !valueB) {
    return 0;
  }
  if (!valueA) {
    return -1;
  }
  if (!valueB) {
    return 1;
  }
  return +valueA - +valueB;
}
