<app-at-price-header
  [refComponent]="refComponent"
  [component]="component"
  [headerOptions]="headerOptions"
  [stockSelected]="stockSelected"
  (changeStock)="stockChanges($event)"
  (changeViewType)="selectViewType($event)"
></app-at-price-header>
<section
  #atPrice
  [ngClass]="isDesktop ? 'component-height' : 'h-100'"
  class="d-flex flex-column"
>
  <div class="d-flex bg-menu">
    <ng-container *ngFor="let viewType of viewTypes">
      <a
        type="button"
        class="nav-link p-2 lh-sm round-top"
        [ngClass]="{ 'text-brand-primary': viewType.active }"
        (click)="selectViewType(viewType.code)"
      >
        {{ viewType.label }}
      </a>
    </ng-container>
  </div>
  <ng-container *ngIf="showDetail && stockPrices">
    <app-details-tab
      [cdStock]="stockSelected.cd_stock"
      [arrowHex]="arrow_hex"
      [precoUltimo]="preco_ultimo"
      [variacaoDia]="variacao_dia"
      [precoMinimo]="preco_minimo"
      [precoMaximo]="preco_maximo"
      [volume]="volume"
      [tickSizeDenominator]="tick_size_denominator"
      [dsType]="stockSelected.ds_type"
      [stockAuctionInfos]="stockAuctionInfos"
    >
    </app-details-tab>
  </ng-container>
  <div [id]="refComponent" class="position-relative w-100 h-100">
    <app-graphic-at-price
      *ngIf="viewTypeSelected"
      [cdStock]="stockSelected.cd_stock"
      [idExchange]="stockSelected.id_exchange"
      [tickSizeDenominator]="stockSelected.tick_size_denominator"
      [precoMinimo]="preco_minimo"
      [precoMaximo]="preco_maximo"
      [precoUltimo]="
        preco_ultimo | tickSize : tick_size_denominator : stockSelected.ds_type
      "
      [price]="preco_ultimo"
      [height]="height - 96"
      [stock]="stockSelected"
      [showDetail]="showDetail"
      [type]="viewTypeSelected"
      [isDesktop]="isDesktop"
    ></app-graphic-at-price>
  </div>
</section>
