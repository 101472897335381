import {
  Directive,
  ElementRef,
  HostListener,
  OnInit,
  Optional,
  Input,
  ComponentRef,
  OnDestroy,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { NgControl, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { RTColorPickerSelectorComponent } from '../color-picker.component';
import { RocketCreateComponentService } from '@shared/rocket-components/services';
import { isNullOrWhiteSpace } from '@shared/rocket-components/utils';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[rtColorPicker]',
})
export class RTColorpickerDirective
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() closeClickOutside: boolean = true;
  @Input() closeOnClick: boolean = true;
  @Input() color!: string;
  @Input() type: 'Hex' | 'RGB' = 'RGB';
  @Input() width: number = 180;
  @Input() height: number = 180;
  @Input() hideTypeButton: boolean = false;
  @Input() showColorPicker: boolean = false;
  @Input('rtColorPickerAlign') align: 'left' | 'right' | 'center' = 'right';
  @Input('rtColorPickerPosition') position: 'top' | 'bottom' | 'auto' = 'auto';
  formControl!: FormControl;
  @Output() rtColor = new EventEmitter<string>();

  private _initDatePicker: boolean = false;
  private _colorPicker!: RTColorPickerSelectorComponent;
  private _element!: HTMLElement;
  private _rootElement!: HTMLElement;
  private _componentRef!: ComponentRef<RTColorPickerSelectorComponent>;
  private _onClose$ = new Subject<void>();
  constructor(
    @Optional() private readonly ngControl: NgControl,
    private elementRef: ElementRef,
    private createCompService: RocketCreateComponentService
  ) {}

  ngOnInit(): void {
    if (this.ngControl) {
      this.formControl = this.ngControl.control as FormControl;
      this._registreControl();
    }
  }

  ngAfterViewInit() {
    this._rootElement = this.elementRef.nativeElement as HTMLElement;
  }

  ngOnDestroy(): void {
    this._colorPicker?.closeColorPicker();
    this._onClose$.complete();
  }

  @HostListener('click', ['$event'])
  toggle() {
    if (!this._initDatePicker) {
      this._initDatepicker();
    }
    this._initDatePicker = !this._initDatePicker;
    this._colorPicker.toggle();
  }

  private _initDatepicker() {
    const findAll = document.querySelectorAll('app-rt-color-picker');
    if (findAll) {
      findAll.forEach((picker) => picker.remove());
    }

    const create = this.createCompService.create(
      RTColorPickerSelectorComponent
    );
    this._componentRef = create.componentRef;
    this._colorPicker = this._componentRef.instance;
    this._element = create.rootNodeElement as HTMLElement;
    this._colorPicker.element = this._rootElement;
    this._colorPicker.closeClickOutside = this.closeClickOutside;
    this._colorPicker.closeOnClick = this.closeOnClick;
    this._colorPicker.color = this.color;
    this._colorPicker.type = this.type;
    this._colorPicker.hideTypeButton = this.hideTypeButton;
    if (this.width) this._colorPicker.width = this.width;
    if (this.height) this._colorPicker.height = this.height;
    if (this.showColorPicker)
      this._colorPicker.showColorPicker = this.showColorPicker;
    if (this.formControl.value) {
      this._colorPicker.setColor(this.formControl.value);
    }

    this._colorPicker.updateColor.subscribe((color) => {
      this.rtColor.emit(color);
      this.formControl.setValue(color);
    });

    this._colorPicker.onClose.subscribe((data) => {
      if (data?.value) {
        this.rtColor.emit(data.value.hex);
        this.formControl.setValue(
          this._colorPicker.type === 'RGB' ? data.value.rgb : data.value.hex
        );
      }
      if (data?.closed) {
        this._destroyDatepicker();
      }
    });

    this._setPosition();
  }

  private _destroyDatepicker() {
    this._element.remove();
    this.formControl.setValue(undefined);
    this.createCompService.destroy(this._componentRef);
    this._initDatePicker = false;
  }

  private _registreControl() {
    this.formControl.valueChanges
      .pipe(
        skipWhile((res: any) => !isNullOrWhiteSpace(res)),
        takeUntil(this._onClose$)
      )
      .subscribe((value: any) => {
        if (isNaN(value)) return;
        this._colorPicker.setColor(value);
      });
  }

  private _setPosition() {
    const root = this._rootElement.getBoundingClientRect();
    const picker = this._element.firstChild as HTMLElement;
    const bodyView = document.body;
    picker.style.position = 'absolute';
    picker.style.zIndex = '1050';

    if (this.align === 'right') {
      picker.style.left = root.right - this.width + 'px';
    } else if (this.align === 'left') {
      picker.style.left = root.left + 'px';
    } else {
      picker.style.left = root.left - this.width / 2 + 'px';
    }

    switch (this.position) {
      case 'auto':
        if (this.height + root.height + root.top >= bodyView.clientHeight) {
          picker.style.top = `${root.top - this.height}px`;
          break;
        }
        picker.style.top = `${root.bottom}px`;
        break;

      case 'bottom':
        picker.style.top = `${root.bottom + 6}px`;
        break;

      case 'top':
        picker.style.top = `${root.top - this.height}px`;
        break;
    }

    document.body.append(this._element);
  }
}
