import { formatNumber } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { Point } from 'scichart/Core/Point';
import {
  ILineAnnotationOptions,
  LineAnnotation,
} from 'scichart/Charting/Visuals/Annotations/LineAnnotation';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import {
  TOOLS_CALCULATION,
  TOOLS_ENUM,
  TOOLS_METHOD,
  TOOLS_TYPE_DRAW,
} from '../tiger-chart-tools/tiger-chart-tools.enum';
import {
  TIGER_CHART_TOOL,
  TIGER_CHART_TOOL_CHILD,
} from '../tiger-chart-tools/tiger-chart-tools.interface';
import { TMouseEventData } from '../types/tiger-chart.types';
import {
  Axis,
  DrawTools,
  DrawToolsReposition,
  EventSubject,
  LineToolsOptions,
  StyleTool,
} from './draw-tools.interface';
import { CANDLE_IDS } from '../constants/tiger-chart.constants';
import { ICandleConfigs } from '../interface';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { deepClone } from '@shared/rocket-components/utils/functions';
import { ModifierMouseArgs } from 'scichart/Charting/ChartModifiers/ModifierMouseArgs';
import { TextAnnotation } from 'scichart/Charting/Visuals/Annotations/TextAnnotation';
import {
  EHorizontalAnchorPoint,
  EVerticalAnchorPoint,
} from 'scichart/types/AnchorPoint';
import { TYPE_RESIZE } from './draw-tools.enum';
import { ToolbarDrawService } from '../tiger-chart-tools/toolbar-draw/toolbar-draw.service';
import { EDraggingGripPoint } from 'scichart/Charting/Visuals/Annotations/AnnotationBase';
import {
  Subject,
  Subscription,
  auditTime,
  debounceTime,
  delay,
  filter,
  takeUntil,
} from 'rxjs';
import { CHART_COLORS } from '../colors';
import { TIGER_INTERVAL_ENUM } from '../enum';
import { ISearchStock } from '@core/interface';
import { CustomBoxTools } from './custom-box-tools';

interface TemporaryLine {
  eventData: TMouseEventData;
  countStepClickTool: number;
  reposition?: DrawToolsReposition;
}

export class LineTools extends LineAnnotation implements DrawTools {
  tool!: TIGER_CHART_TOOL;
  children: DrawTools[] = [];
  isDraw = true;
  fiboYDiff?: number;
  mainY1?: number;
  mainY2?: number;
  maxYValue?: number;
  minYValue?: number;
  viewBoxWidth?: number;
  tickSize: number = 0;
  startEdit = false;
  baseChart!: TWebAssemblyChart;
  isChildren = false;
  lastPointValues!: Point;
  limitGetBoxElement: number = 10;
  dictIdChildren!: string;
  lastEventData!: TMouseEventData;
  isDrawing!: boolean;
  lastPosition!: { x1: number; x2: number; y1: number; y2: number };
  lastBorders!: { x1: number; x2: number; y1: number; y2: number };
  reposition!: DrawToolsReposition | undefined;
  configs!: ICandleConfigs;
  isAux: boolean = false;
  svgString?: string | undefined;
  endDraw: boolean = false;
  axisSub!: Subscription;
  isWithinDataBounds: boolean | undefined = true;
  private _onDestroy = new Subject<void>();
  private refComponent: string = '';
  private isNotHorzVerzLine: boolean = true;
  private updateFather$!: Subscription;
  private _updateFather$ = new Subject<EventSubject>();
  private updateChildrenParalelChannel$!: Subscription;
  private _updateChildrenParalelChannel$ = new Subject<EventSubject>();
  private repositionTriangleBackground$!: Subscription;
  private _repositionTriangleBackground$ = new Subject<EventSubject>();
  private repositionCurveLine$!: Subscription;
  private _repositionCurveLine$ = new Subject<EventSubject>();
  private moveLine$!: Subscription;
  private _moveLine$ = new Subject<EventSubject>();
  private isDrawing$!: Subscription;
  private _isDrawing$ = new Subject<EventSubject>();
  private blockEditDraw$!: Subscription;
  private blockEdit: boolean = false;
  private drawLocked$!: Subscription;
  private _repositionTemporaryBox$ = new Subject<EventSubject>();
  private _updateTemporaryLine$ = new Subject<TemporaryLine>();
  private _repositionZoomDraw$ = new Subject<EventSubject>();
  temporaryLastMouseValues!: Point;
  stock!: ISearchStock;

  constructor(
    options: ILineAnnotationOptions,
    private drawToolsService: DrawToolsService,
    private toolbarDrawService: ToolbarDrawService,
    tool: LineToolsOptions,
    @Inject(LOCALE_ID) private locale: string
  ) {
    super(options);
    this.initSubscriptions();
    this.isChildren = tool.isChildren;
    this.baseChart = tool.baseChart;
    this.tool = tool.tool;
    this.viewBoxWidth = tool.viewBoxWidth;
    this.tickSize = tool.stock.tick_size_denominator;
    this.dictIdChildren = tool.dictIdChildren;
    this.configs = tool.configs;
    this.isAux = tool.isAux;
    this.isDrawing = true;
    this.lastEventData = tool.eventData;
    this.isWithinDataBounds = tool.eventData.isWithinDataBounds;
    this.refComponent = tool.refComponent;
    this.stock = tool.stock;
    this.isNotHorzVerzLine =
      this.tool.codTool !== `${TOOLS_ENUM.TREND_LINES}_LINHA_VERTICAL` &&
      this.tool.codTool !== `${TOOLS_ENUM.TREND_LINES}_LINHA_HORIZONTAL`;
    this._updateFather$.next({
      function: () => {
        this.updateFather(TYPE_RESIZE.INITIAL);
      },
    });
    this.temporaryLineGrips();
  }

  private temporaryLineGrips() {
    if (this.tool.codTool === `${TOOLS_ENUM.TEMPORARY}_LINHA_TEMPORARIA`) {
      this.isSelected = true;
      this.isEditable = false;
      this.annotationsGripsRadius = 5;
      this.selectionBoxStroke = 'transparent';
    }
  }

  private updateByAxis() {
    const funcs: any = {
      [`${TOOLS_ENUM.TEMPORARY}_LINHA_TEMPORARIA`]: () => {
        this._repositionZoomDraw$.next({
          function: () => {
            this.repositionTemporaryBox();
          },
        });
      },
      [`${TOOLS_ENUM.TREND_LINES}_LINHA_COM_INFORMACOES`]: () => {
        this.moveLineInformation(undefined, false);
        this.updateLineInfoBoxPosition();
      },
      [`${TOOLS_ENUM.TREND_LINES}_CANAL_PARALELO`]: () => {
        this._updateChildrenParalelChannel$.next({
          function: () => {
            this.updateChildrenParalelChannel();
          },
        });
      },
      [`${TOOLS_ENUM.GEOMETRIC_SHAPES}_TRIANGLE`]: () => {
        this.tool.children?.forEach((child) => {
          const drawChild = this.baseChart.sciChartSurface.annotations.getById(
            child.dictIdChildren!!
          ) as DrawTools;
          if (!drawChild) {
            return;
          }
          drawChild.isDrawing = true;
          if (child.method === TOOLS_METHOD.CUSTOM_BOX_ANNOTATION) {
            this._repositionTriangleBackground$.next({
              function: () => {
                this.repositionTriangleBackground(child.dictIdChildren!!);
              },
            });
          }
        });
      },
      [`${TOOLS_ENUM.GEOMETRIC_SHAPES}_CURVE`]: () => {
        this._repositionCurveLine$.next({
          function: () => {
            this.repositionCurveLine();
          },
        });
      },
    };
    const func = funcs[this.tool.codTool];
    if (func) {
      func();
    }
  }

  private yAxisEvent() {
    this._updateFather$.next({
      function: () => {
        this.updateFather(TYPE_RESIZE.VISIBLE_RANGE);
      },
    });
    this.updateByAxis();
  }

  private xAxisEvent() {
    this._updateFather$.next({
      function: () => {
        this.updateFather(TYPE_RESIZE.VISIBLE_RANGE);
      },
    });
    this.updateByAxis();
  }

  unsubscriber(): void {
    this.axisSub.unsubscribe();
  }

  initSubscriptions() {
    this._updateTemporaryLine$
      .pipe(takeUntil(this._onDestroy), auditTime(1))
      .subscribe((data) => {
        this.updateTemporaryLine(data);
        this.temporaryLineGrips();
      });
    this._repositionTemporaryBox$
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data) => {
        data.function();
      });
    this._repositionZoomDraw$
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data) => {
        data.function();
        this.temporaryLineGrips();
      });
    this.blockEditDraw$ = this.drawToolsService.blockEditDraw$
      .pipe(auditTime(100))
      .subscribe((data) => {
        this.blockEdit = data;
      });
    this.updateFather$ = this._updateFather$
      .pipe(debounceTime(50))
      .subscribe((data) => {
        data.function();
      });
    this.updateChildrenParalelChannel$ = this._updateChildrenParalelChannel$
      .pipe(debounceTime(200))
      .subscribe((data) => {
        data.function();
      });
    this.repositionTriangleBackground$ = this._repositionTriangleBackground$
      .pipe(debounceTime(10))
      .subscribe((data) => {
        data.function();
      });
    this.repositionCurveLine$ = this._repositionCurveLine$
      .pipe(debounceTime(50))
      .subscribe((data) => {
        data.function();
      });
    this.moveLine$ = this._moveLine$
      .pipe(debounceTime(100))
      .subscribe((data) => {
        data.function();
      });
    this.isDrawing$ = this._isDrawing$.pipe(delay(50)).subscribe((data) => {
      data.function();
    });
    this.axisSub = this.drawToolsService.axisChanged$.subscribe((data) => {
      if (data.type === 'XAXIS') {
        this.xAxisEvent();
        return;
      }
      this.yAxisEvent();
    });
    this.drawLocked$ = this.drawToolsService.isLocked$
      .pipe(filter((isLocked: boolean) => !isLocked))
      .subscribe(() => {
        this.updateByAxis();
      });
  }

  private destroySubscriptions() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.updateFather$ && this.updateFather$.unsubscribe();
    this.updateChildrenParalelChannel$ &&
      this.updateChildrenParalelChannel$.unsubscribe();
    this.repositionTriangleBackground$ &&
      this.repositionTriangleBackground$.unsubscribe();
    this.repositionCurveLine$ && this.repositionCurveLine$.unsubscribe();
    this.moveLine$ && this.moveLine$.unsubscribe();
    this.isDrawing$ && this.isDrawing$.unsubscribe();
    this.blockEditDraw$ && this.blockEditDraw$.unsubscribe();
    this.drawLocked$ && this.drawLocked$.unsubscribe();
  }

  override onDetach(): void {
    super.onDetach();
    this.destroySubscriptions();
  }

  override delete(): void {
    super.delete();
    this.destroySubscriptions();
  }

  override onDragEnded(): void {
    if (this.isDrawing && this.isNotHorzVerzLine) {
      this.isDrawing = false;
    }
    this.drawToolsService.saveAnnotationStock(
      this.baseChart,
      this,
      this.refComponent
    );
    super.onDragEnded();
  }

  override onDragAdorner(args: ModifierMouseArgs): void {
    if (!this.isEditable) {
      return;
    }
    if (this.isDrawing && this.isNotHorzVerzLine) {
      this.isDrawing = false;
    }
    super.onDragAdorner(args);
    const funcs: any = {
      [`${TOOLS_ENUM.TREND_LINES}_LINHA_COM_INFORMACOES`]: () => {
        this.resizeLineInfo();
      },
      [`${TOOLS_ENUM.TREND_LINES}_LINHA_VERTICAL`]: () => {
        this.resizeFather(TYPE_RESIZE.DRAG, undefined, args.mousePoint);
      },
      [`${TOOLS_ENUM.TREND_LINES}_LINHA_HORIZONTAL`]: () => {
        this.resizeFather(TYPE_RESIZE.DRAG, undefined, args.mousePoint);
      },
      [`${TOOLS_ENUM.GEOMETRIC_SHAPES}_TRIANGLE`]: () => {
        this.resizeTriangle();
      },
      [`${TOOLS_ENUM.GEOMETRIC_SHAPES}_SEQUENCE`]: () => {
        this.repositionSequence();
      },
      [`${TOOLS_ENUM.GEOMETRIC_SHAPES}_CURVE`]: () => {
        this.repositionCurveLine();
      },
    };
    const func = funcs[this.tool.codTool];
    if (func) {
      func();
    }
    if (
      this.tool.calculation &&
      this.tool.calculation === TOOLS_CALCULATION.FIBONACCI_RETRACEMENT
    ) {
      if (this.isChildren) {
        return;
      }
    }
  }

  override clickToSelect(args: ModifierMouseArgs): boolean {
    if (this.blockEdit) return false;
    if (this.toolbarDrawService.hasElement()) {
      const drawToolbar = this.toolbarDrawService.getDrawTool()!!;
      if (drawToolbar.id === this.id && this.drawToolsService.isLocked) {
        return false;
      }
    }
    return super.clickToSelect(args);
  }

  override click(args: ModifierMouseArgs, selectOnClick: boolean): boolean {
    if (this.blockEdit) return false;
    if (
      !this.isNotHorzVerzLine ||
      (this.tool.codTool === `${TOOLS_ENUM.GEOMETRIC_SHAPES}_SEQUENCE` &&
        this.endDraw) ||
      this.tool.group === TOOLS_ENUM.GEOMETRIC_SHAPES
    ) {
      return super.click(args, true);
    }
    if (
      this.tool.codTool ===
        `${TOOLS_ENUM.GANN_FIBONACCI}_RETRACAO_DE_FIBONACCI` &&
      !selectOnClick
    ) {
      return false;
    }
    if (this.toolbarDrawService.hasElement()) {
      const drawToolbar = this.toolbarDrawService.getDrawTool()!!;
      if (drawToolbar.id === this.id && this.drawToolsService.isLocked) {
        return false;
      }
    }
    return super.click(args, selectOnClick);
  }

  protected override notifyPropertyChanged(propertyName: string): void {
    super.notifyPropertyChanged(propertyName);
    if (!this.isAux && this.startEdit) {
      return;
    }
    if (this.isDrawing) {
      return;
    }

    if (
      propertyName === 'X1' ||
      propertyName === 'X2' ||
      propertyName === 'Y1' ||
      propertyName === 'Y2'
    ) {
      this.updateFather(TYPE_RESIZE.DRAG);
    }
  }

  getName() {
    return this.constructor.name;
  }

  updateDrawTool(
    eventData: TMouseEventData | undefined,
    countStepClickTool: number,
    reposition?: DrawToolsReposition
  ) {
    if (eventData) {
      if (countStepClickTool === 1) {
        this.lastEventData = eventData;
      }
      this.lastPointValues = eventData.pointValues!!;
    }
    const updateFunctions: any = {
      [`${TOOLS_ENUM.TEMPORARY}_LINHA_TEMPORARIA`]: {
        func: () =>
          this._updateTemporaryLine$.next({
            eventData: eventData!!,
            countStepClickTool,
            reposition,
          }),
        return: true,
      },
      [`${TOOLS_ENUM.TREND_LINES}_CANAL_PARALELO`]: {
        func: () =>
          this.moveParalelChannel(eventData!!, countStepClickTool, reposition),
        return: true,
      },
      [`${TOOLS_ENUM.TREND_LINES}_LINHA_COM_INFORMACOES`]: {
        func: () => this.moveLineInformation(eventData, true, reposition),
        return: true,
      },
      [`${TOOLS_ENUM.GEOMETRIC_SHAPES}_TRIANGLE`]: {
        func: () => this.moveTriangle(eventData!!, countStepClickTool),
        return: true,
      },
      [`${TOOLS_ENUM.GEOMETRIC_SHAPES}_SWIVEL_RECTANGLE`]: {
        func: () => this.moveSwivelRectangle(eventData!!, countStepClickTool),
        return: true,
      },
      [`${TOOLS_ENUM.GEOMETRIC_SHAPES}_SEQUENCE`]: {
        func: () => this.moveSequence(eventData!!, countStepClickTool),
        return: true,
      },
      [`${TOOLS_ENUM.GEOMETRIC_SHAPES}_BOW`]: {
        func: () => this.moveBow(eventData!!, countStepClickTool),
        return: true,
      },
      [`${TOOLS_ENUM.GEOMETRIC_SHAPES}_CURVE`]: {
        func: () => this.moveCurveLine(eventData!!),
        return: false,
      },
    };
    this.reposition = reposition;
    const updateFunction = updateFunctions[this.tool.codTool];
    if (updateFunction) {
      updateFunction.func();
      if (updateFunction.return) {
        return;
      }
    }
    this.moveLine(eventData, reposition);
    if (this.tool.calculation === TOOLS_CALCULATION.FIBONACCI_RETRACEMENT) {
      this.mainY1 = deepClone(this.y1);
      this.mainY2 = deepClone(this.y2);
    }
  }

  private moveCurveLine(eventData: TMouseEventData) {
    const x2 = (this.tool.mouseValues!!.x - eventData.mousePoint!!.x) * -1;
    const y2 = (this.tool.mouseValues!!.y - eventData.mousePoint!!.y) * -1;
    const borders = this.getAnnotationBorders();
    const childBox = this.tool.children?.find(
      (c) => !c.isAux && c.method === TOOLS_METHOD.CUSTOM_BOX_ANNOTATION
    );
    const childLine = this.tool.children?.find(
      (c) => c.isAux && c.method === TOOLS_METHOD.DRAW_LINE
    );
    if (childBox) {
      const lineElement = document.getElementById(
        `LINE_${childBox.dictIdChildren!!}`
      );
      const pathElement = document.getElementById(
        `PATH_${childBox.dictIdChildren!!}`
      );
      const drawLine = this.baseChart.sciChartSurface.annotations.getById(
        childLine!!.dictIdChildren!!
      ) as DrawTools;
      let p = Math.abs(y2) + 30;
      if (y2 < 0) {
        p = 30;
      }
      if (pathElement) {
        pathElement.setAttribute('d', `M 0 0 Q ${x2 / 2} ${p} ${x2} ${y2}`);
      }
      if (lineElement) {
        const teste = p * 2 * -1;
        lineElement.setAttribute('x1', `${x2 / 2 + p}`);
        lineElement.setAttribute('x2', `${x2}`);
        lineElement.setAttribute('y1', `${teste}`);
        lineElement.setAttribute('y2', `${y2}`);
      }
      const { xAxis } = this.getAxis();
      drawLine.isDrawing = true;
      drawLine.x1 = this.x1;
      const medianX = (borders.x2 + borders.x1) / 2;
      if (xAxis) {
        drawLine.x2 = xAxis
          .getCurrentCoordinateCalculator()
          .getDataValue(medianX + p);
      }
    }
  }

  private repositionCurveLine() {
    if (this.isAux) {
      return;
    }
    const borders = this.getAnnotationBorders();
    const diffX = borders.x2 - borders.x1;
    const diffY = borders.y2 - borders.y1;
    const childBox = this.tool.children?.find(
      (c) => !c.isAux && c.method === TOOLS_METHOD.CUSTOM_BOX_ANNOTATION
    );
    if (childBox) {
      const drawBox = this.baseChart.sciChartSurface.annotations.getById(
        childBox.dictIdChildren!!
      );
      if (!drawBox) {
        return;
      }
      const pathElement = document.getElementById(
        `PATH_${childBox.dictIdChildren!!}`
      );
      drawBox.x1 = this.x1;
      drawBox.x2 = this.x2;
      drawBox.y1 = this.y1;
      drawBox.y2 = this.y2;
      let p = Math.abs(diffY) + 30;
      if (diffY < 0) {
        p = 30;
      }
      if (pathElement) {
        pathElement.setAttribute(
          'd',
          `M 0 0 Q ${diffX / 2} ${p} ${diffX} ${diffY}`
        );
      }
    }
  }

  private repositionBox(
    eventData?: TMouseEventData,
    reposition?: DrawToolsReposition
  ) {
    const child = this.tool.children!.find(
      (c) => c.method === TOOLS_METHOD.CUSTOM_BOX_ANNOTATION
    )!!;
    const rectElement = document.getElementById(`RECT_${child.dictIdChildren}`);
    const textVerticalElement = document.getElementById(
      `TEXT_VERTICAL_DISTANCE_${child.dictIdChildren}`
    );
    const iconVerticalElement = document.getElementById(
      `ICON_VERTICAL_DISTANCE_${child.dictIdChildren}`
    );
    const textBarElement = document.getElementById(
      `TEXT_BAR_${child.dictIdChildren}`
    );
    const iconBarElement = document.getElementById(
      `ICON_BAR_${child.dictIdChildren}`
    );
    const textDegreeElement = document.getElementById(
      `TEXT_DEGREE_${child.dictIdChildren}`
    );
    const iconDegreeElement = document.getElementById(
      `ICON_DEGREE_${child.dictIdChildren}`
    );
    const x1 = this.getAdornerAnnotationBorders().x1;
    const x2 = this.getAdornerAnnotationBorders().x2;
    const y1 = this.getAdornerAnnotationBorders().y1;
    const y2 = this.getAdornerAnnotationBorders().y2;
    if (rectElement) {
      let x = 0;
      let y = 0;
      if (eventData) {
        x = (eventData.mousePoint!!.x - this.tool.mouseValues!!.x) / 2;
        y = 0;
        if (eventData.mousePoint!!.y < this.tool.mouseValues!!.y) {
          y = (eventData.mousePoint!!.y - this.tool.mouseValues!!.y) / 2;
        } else {
          y = -(90 + (y1 - y2) / 2);
        }
      } else {
        x = (x2 - x1) / 2;
        if (y2 < y1) {
          y = (y2 - y1) / 2;
        } else {
          y = -(90 + (y1 - y2) / 2);
        }
      }
      rectElement.setAttribute('width', '250');
      rectElement.setAttribute('height', '85');
      rectElement.setAttribute('x', `${x}`);
      rectElement.setAttribute('y', `${y}`);
      textVerticalElement!!.setAttribute('x', `${x + 30}`);
      textVerticalElement!!.setAttribute('y', `${y + 21}`);
      iconVerticalElement!!.setAttribute('x', `${x + 10}`);
      iconVerticalElement!!.setAttribute('y', `${y + 10}`);
      textBarElement!!.setAttribute('x', `${x + 30}`);
      textBarElement!!.setAttribute('y', `${y + 47}`);
      iconBarElement!!.setAttribute('x', `${x + 10}`);
      iconBarElement!!.setAttribute('y', `${y + 35}`);
      textDegreeElement!!.setAttribute('x', `${x + 30}`);
      textDegreeElement!!.setAttribute('y', `${y + 72}`);
      iconDegreeElement!!.setAttribute('x', `${x + 10}`);
      iconDegreeElement!!.setAttribute('y', `${y + 60}`);
      const radians = Math.atan2(y1 - y2, x2);
      const degrees = formatNumber(
        (radians * 180) / Math.PI,
        this.locale,
        `1.0-0`
      );
      let date1!: Date;
      if (eventData) {
        date1 = new Date(eventData.xCategoryValue!!);
      } else {
        date1 = new Date(this.lastEventData.xCategoryValue!!);
      }
      const date2 = new Date(this.tool.xCategoryValue!!);
      const diffDates = date1.getTime() - date2.getTime();
      const minutes = Math.round(diffDates / 1000 / 60);
      const minutesToHour = minutes / 60;
      const hours = parseInt(minutesToHour.toString());
      const hourMinutes = (minutesToHour - hours) * 60;
      const hourMinutesText = ` ${parseInt(hourMinutes.toString())}m`;
      const time =
        hours > 0 ? `${hours}h${hourMinutesText}` : `${hourMinutesText.trim()}`;
      let percent = 100 - (this.y1 * 100) / this.y2;
      percent = percent > 1 ? percent - 1 : percent;
      const distance = formatNumber(x2 - x1, this.locale, `1.0-0`);
      const difference = this.y2 - this.y1;
      let diffCalc = difference * 100;
      if (
        reposition?.stock.ds_asset === 'DOL' ||
        reposition?.stock.ds_asset === 'WDO'
      ) {
        diffCalc = difference / 0.5;
      }
      if (reposition?.stock.ds_asset === 'WIN') {
        diffCalc = difference / 5;
      }
      textVerticalElement!!.textContent = `${formatNumber(
        difference,
        this.locale,
        `1.${this.tickSize}-${this.tickSize}`
      )} (${formatNumber(percent, this.locale, `1.2-2`)}%), ${formatNumber(
        diffCalc,
        this.locale,
        `1.0-0`
      )}`;
      textBarElement!!.textContent = `Barras: ${formatNumber(
        this.x2 - this.x1 + 1,
        this.locale,
        `1.0-0`
      )} (${time}), distância: ${distance} px`;
      textDegreeElement!!.textContent = `${degrees}°`;
      if (hours > 0) {
        rectElement.setAttribute('width', `285`);
      } else {
        rectElement.setAttribute('width', `250`);
      }
    }
  }

  private moveBow(eventData: TMouseEventData, countStepClickTool: number) {
    if (countStepClickTool === 1) {
      this.moveLine(eventData);
      return;
    }
    const borders = this.getAnnotationBorders();
    const x = borders.x2 - borders.x1;
    const y = borders.y2 - borders.y1;
    this.tool.children?.forEach((child, k) => {
      const drawChild = this.baseChart.sciChartSurface.annotations.getById(
        child.dictIdChildren!!
      );
      if (!child.isAux) {
        const pathElement = document.getElementById(
          `PATH_${child.dictIdChildren!!}`
        );
        if (pathElement) {
          const firstAuxDraw =
            this.baseChart.sciChartSurface.annotations.getById(
              this.tool.children!![0].dictIdChildren!!
            ) as DrawTools;
          const diff =
            (firstAuxDraw.getAnnotationBorders().x2 -
              firstAuxDraw.getAnnotationBorders().x1) *
            2;
          const yMiddle = y / 2;
          pathElement.setAttribute('d', `M 0 0 Q ${diff} ${yMiddle} ${x} ${y}`);
        }
      } else {
        const medianX = (this.x1 + this.x2) / 2;
        const a = (medianX * 100) / this.x1 / 100 - 1;
        const diffY = (this.y2 - this.y1) * a;
        if (k == 0) {
          drawChild.y1 = this.y1;
          drawChild.y2 = this.y1 - diffY;
        } else {
          drawChild.y1 = this.y2;
          drawChild.y2 = this.y2 - diffY;
          drawChild.x1 = this.x2;
        }
        drawChild.x2 = eventData!!.pointValues!!.x;
      }
    });
  }

  private moveSequence(eventData: TMouseEventData, countStepClickTool: number) {
    if (countStepClickTool === 1) {
      this.moveLine(eventData);
      return;
    }
    this.endDraw = true;
    const size = countStepClickTool - 2;
    const child = this.tool.children!![size];
    if (!child) return;
    const draw = this.baseChart.sciChartSurface.annotations.getById(
      child.dictIdChildren!!
    ) as LineTools;
    if (draw) {
      let x1 = this.x2;
      let y1 = this.y2;
      if (size > 0) {
        const previousChild = this.tool.children!![size - 1];
        const previousDraw = this.baseChart.sciChartSurface.annotations.getById(
          previousChild.dictIdChildren!!
        ) as LineTools;
        x1 = previousDraw.x2;
        y1 = previousDraw.y2;
        previousDraw.endDraw = true;
      }
      const x2 = eventData!!.pointValues!!.x;
      const y2 = eventData!!.pointValues!!.y;
      draw.x1 = x1;
      draw.y1 = y1;
      draw.x2 = x2;
      draw.y2 = y2;
      draw.lastPosition = { x1: x1, x2: x2, y1: y1, y2: y2 };
    }
  }

  private repositionSequence() {
    const diffX1 = this.lastPosition.x1 - this.x1;
    const diffX2 = this.lastPosition.x2 - this.x2;
    const diffY1 = this.lastPosition.y1 - this.y1;
    const diffY2 = this.lastPosition.y2 - this.y2;
    if (!this.isChildren) {
      if (this.adornerDraggingPoint === EDraggingGripPoint.Body) {
        this.tool.children?.forEach((child) => {
          const drawChild = this.baseChart.sciChartSurface.annotations.getById(
            child.dictIdChildren!!
          ) as LineTools;
          drawChild.isDrawing = true;
          drawChild.x1 = drawChild.x1 - diffX1;
          drawChild.x2 = drawChild.x2 - diffX2;
          drawChild.y1 = drawChild.y1 - diffY1;
          drawChild.y2 = drawChild.y2 - diffY2;
          drawChild.lastPosition = {
            x1: drawChild.x1,
            x2: drawChild.x2,
            y1: drawChild.y1,
            y2: drawChild.y2,
          };
        });
      } else {
        if (this.tool.children && this.tool.children[0]) {
          const child = this.tool.children[0];
          const drawChild = this.baseChart.sciChartSurface.annotations.getById(
            child.dictIdChildren!!
          );
          drawChild.x1 = this.x2;
          drawChild.y1 = this.y2;
        }
      }
    } else {
      const father = this.baseChart.sciChartSurface.annotations.getById(
        this.tool.dictId!!
      ) as DrawTools;
      if (this.adornerDraggingPoint === EDraggingGripPoint.Body) {
        const children = father.tool.children?.filter(
          (child) => child.dictIdChildren !== this.dictIdChildren
        );
        const fatherDraw = this.baseChart.sciChartSurface.annotations.getById(
          this.tool.dictId!!
        ) as LineTools;
        fatherDraw.isDrawing = true;
        fatherDraw.x1 = fatherDraw.x1 - diffX1;
        fatherDraw.x2 = fatherDraw.x2 - diffX2;
        fatherDraw.y1 = fatherDraw.y1 - diffY1;
        fatherDraw.y2 = fatherDraw.y2 - diffY2;
        fatherDraw.lastPosition = {
          x1: fatherDraw.x1,
          x2: fatherDraw.x2,
          y1: fatherDraw.y1,
          y2: fatherDraw.y2,
        };
        children?.forEach((child) => {
          const drawChild = this.baseChart.sciChartSurface.annotations.getById(
            child.dictIdChildren!!
          ) as LineTools;
          drawChild.isDrawing = true;
          drawChild.x1 = drawChild.x1 - diffX1;
          drawChild.x2 = drawChild.x2 - diffX2;
          drawChild.y1 = drawChild.y1 - diffY1;
          drawChild.y2 = drawChild.y2 - diffY2;
          drawChild.lastPosition = {
            x1: drawChild.x1,
            x2: drawChild.x2,
            y1: drawChild.y1,
            y2: drawChild.y2,
          };
        });
      } else {
        const childIndex = father.tool.children!!.findIndex(
          (child) => child.dictIdChildren === this.dictIdChildren
        );
        const nextChild = father.tool.children!![childIndex!! + 1];
        if (nextChild) {
          const drawNextChild =
            this.baseChart.sciChartSurface.annotations.getById(
              nextChild.dictIdChildren!!
            );
          drawNextChild.x1 = this.x2;
          drawNextChild.y1 = this.y2;
        }
      }
    }
    this.lastPosition = {
      x1: this.x1,
      x2: this.x2,
      y1: this.y1,
      y2: this.y2,
    };
  }

  private moveSwivelRectangle(
    eventData: TMouseEventData,
    countStepClickTool: number
  ) {
    if (countStepClickTool === 1) {
      this.moveLine(eventData);
      this.mainY1 = deepClone(this.y1);
      this.mainY2 = deepClone(this.y2);
    }
    if (countStepClickTool === 2) {
      const diffY1 = eventData.pointValues!!.y - this.mainY2!!;
      this.y2 = eventData.pointValues!!.y;
      this.y1 = this.mainY1!! + diffY1;
      this.tool.children?.forEach((child) => {
        if (child.method === TOOLS_METHOD.DRAW_LINE) {
          const drawChild = this.baseChart.sciChartSurface.annotations.getById(
            child.dictIdChildren!!
          );
          drawChild.x1 = this.x1;
          drawChild.x2 = this.x2;
        }
      });
    }
  }

  private moveTriangle(eventData: TMouseEventData, countStepClickTool: number) {
    if (countStepClickTool === 1) {
      this.moveLine(eventData);
    }
    if (countStepClickTool === 2) {
      this.tool.children?.forEach((child, k) => {
        const drawChild = this.baseChart.sciChartSurface.annotations.getById(
          child.dictIdChildren!!
        ) as DrawTools;
        drawChild.isDrawing = true;
        drawChild.lastEventData = eventData;
        if (child.method === TOOLS_METHOD.DRAW_LINE) {
          if (!child.isAux) {
            if (k === 1) {
              drawChild.x1 = this.x1;
              drawChild.y1 = this.y1;
              drawChild.x2 = eventData.pointValues!!.x;
              drawChild.y2 = eventData.pointValues!!.y;
            } else {
              drawChild.x1 = this.x2;
              drawChild.y1 = this.y2;
              drawChild.x2 = eventData.pointValues!!.x;
              drawChild.y2 = eventData.pointValues!!.y;
            }
          } else {
            drawChild.x1 = eventData.pointValues!!.x;
            drawChild.x2 = eventData.pointValues!!.x;
            drawChild.y1 = this.y1;
            drawChild.y2 = eventData.pointValues!!.y;
          }
        }
        if (child.method === TOOLS_METHOD.CUSTOM_BOX_ANNOTATION) {
          drawChild.y1 = eventData.pointValues!!.y;
          drawChild.x1 = eventData.pointValues!!.x;
          this.repositionTriangleBackground(child.dictIdChildren!!);
        }
        if (child.method === TOOLS_METHOD.BOX_ANNOTATION) {
          let y1 = this.y2 > this.y1 ? this.y1 : this.y2;
          if (eventData.pointValues!!.y < y1) {
            y1 = this.y2 > this.y1 ? this.y2 : this.y1;
          }
          drawChild.x1 = this.x1;
          drawChild.x2 = this.x2;
          drawChild.y1 = y1;
          drawChild.y2 = eventData.pointValues!!.y;
        }
      });
    }
    if (countStepClickTool === 3) {
      this.tool.children?.forEach((child) => {
        const drawChild = this.baseChart.sciChartSurface.annotations.getById(
          child.dictIdChildren!!
        ) as DrawTools;
        drawChild.isDrawing = true;
        drawChild.lastEventData = eventData;
        if (child.method === TOOLS_METHOD.CUSTOM_BOX_ANNOTATION) {
          const firstLine = this.baseChart.sciChartSurface.annotations.getById(
            this.tool.children!![1].dictIdChildren!!
          ) as DrawTools;
          drawChild.y1 = firstLine.y2;
          drawChild.x1 = firstLine.x2;
          this.repositionTriangleBackground(child.dictIdChildren!!);
        }
      });
    }
  }

  private resizeLineInfo() {
    const series = this.baseChart.sciChartSurface.renderableSeries.getById(
      CANDLE_IDS.MAIN_SERIE
    );
    const native = series.getNativeXValues();
    const firstIndex = Math.round(this.x1);
    const secondIndex = Math.round(this.x2);
    const firstDate = native.get(firstIndex);
    const secondDate = native.get(secondIndex);
    this.tool.xCategoryValue = firstDate;
    this.lastEventData.xCategoryValue = secondDate;
    this.moveLineInformation(undefined, false);
    this.updateLineInfoBoxPosition();
  }

  private resizeTriangle() {
    const father = this.baseChart.sciChartSurface.annotations.getById(
      this.tool.dictId!!
    ) as DrawTools;
    this.tool.children?.forEach((child, k) => {
      const drawChild = this.baseChart.sciChartSurface.annotations.getById(
        child.dictIdChildren!!
      ) as DrawTools;
      drawChild.isDrawing = true;
      if (child.method === TOOLS_METHOD.DRAW_LINE) {
        const first = this.baseChart.sciChartSurface.annotations.getById(
          this.tool.children!![0].dictIdChildren!!
        ) as DrawTools;
        if (!child.isAux) {
          if (k === 1) {
            drawChild.x1 = father.x1;
            drawChild.y1 = father.y1;
          } else {
            drawChild.x1 = father.x2;
            drawChild.y1 = father.y2;
          }
          drawChild.y2 = first.y2;
          drawChild.x2 = first.x2;
        }
      }
      if (child.method === TOOLS_METHOD.CUSTOM_BOX_ANNOTATION) {
        this.repositionTriangleBackground(child.dictIdChildren!!);
      }
      if (child.method === TOOLS_METHOD.BOX_ANNOTATION) {
        drawChild.x1 = this.x1;
        const first = this.baseChart.sciChartSurface.annotations.getById(
          this.tool.children!![0].dictIdChildren!!
        ) as DrawTools;
        drawChild.x2 = this.x2;
        if (this.x2 < first.x2) {
          drawChild.x2 = first.x2;
          drawChild.x1 = this.x2;
        }
        const y = this.y1 > this.y2 ? this.y2 : this.y1;
        drawChild.y1 = y;
      }
    });
  }

  private repositionTriangleBackground(dictIdChildren: string) {
    const pathElement = document.getElementById(`PATH_${dictIdChildren}`);
    const lineAux = this.baseChart.sciChartSurface.annotations.getById(
      this.tool.children!![0].dictIdChildren!!
    ) as DrawTools;
    const firstLine = this.baseChart.sciChartSurface.annotations.getById(
      this.tool.children!![1].dictIdChildren!!
    ) as DrawTools;
    const father = this.baseChart.sciChartSurface.annotations.getById(
      this.tool.dictId!!
    ) as DrawTools;
    const box = this.baseChart.sciChartSurface.annotations.getById(
      dictIdChildren
    ) as DrawTools;
    const firstBorders = firstLine.getAnnotationBorders();
    const borders = father.getAnnotationBorders();
    const height = firstBorders.y1 - firstBorders.y2;
    const fatherYDiff = borders.y2 - borders.y1;
    const left = firstBorders.x1 - firstBorders.x2;
    const right = borders.x2 - borders.x1 - (firstBorders.x2 - firstBorders.x1);
    if (pathElement) {
      box.y1 = lineAux.y2;
      box.x1 = lineAux.x2;
      pathElement.setAttribute(
        'points',
        `0,0 ${left}, ${height} ${right}, ${height + fatherYDiff}`
      );
    }
  }

  private moveLineInformation(
    eventData: TMouseEventData | undefined,
    moveLine: boolean,
    reposition?: DrawToolsReposition
  ) {
    if (moveLine && eventData) {
      this.moveLine(eventData, reposition);
    }
    this.repositionBox(eventData, reposition);
    this.updateLineInfoBoxPosition();
  }

  private moveParalelChannel(
    eventData: TMouseEventData,
    countStepClickTool: number,
    reposition?: DrawToolsReposition
  ) {
    if (countStepClickTool === 1) {
      this.moveLine(eventData);
    }
    if (countStepClickTool === 2) {
      const drawChildren = this.drawToolsService.drawChildrenDic.get(
        this.tool.dictId!!
      );
      if (drawChildren) {
        const fatherAnnotation =
          this.baseChart.sciChartSurface.annotations.getById(
            this.tool.dictId!!
          ) as DrawTools;
        let y1 =
          fatherAnnotation.y1 -
          (fatherAnnotation.y2 - eventData.pointValues!!.y);
        let y2 =
          fatherAnnotation.y2 -
          (fatherAnnotation.y2 - eventData.pointValues!!.y);
        let previousId = '';
        drawChildren.forEach((child, k) => {
          if (child.getName() === this.getName()) {
            const annotation =
              this.baseChart.sciChartSurface.annotations.getById(child.id);
            const previousAnnotation =
              this.baseChart.sciChartSurface.annotations.getById(
                previousId
              ) as DrawTools;
            if (annotation) {
              if (
                this.tool.children &&
                this.tool.children[k] &&
                this.tool.children[k].relativePosition
              ) {
                if (this.tool.children[k].relativePosition === 'middle') {
                  y1 = (fatherAnnotation.y1 + previousAnnotation.y1) / 2;
                  y2 = (fatherAnnotation.y2 + previousAnnotation.y2) / 2;
                }
              }
              annotation.y1 = y1;
              annotation.y2 = y2;
              annotation.x2 = fatherAnnotation.x2;
            }
            previousId = child.id;
          } else {
            const childTool = this.tool.children?.find(
              (c) => c.dictIdChildren === child.id
            );
            if (childTool?.isAux) {
              if (this.tool.hasBoxAux && this.tool.children) {
                const previousAnnotation =
                  this.baseChart.sciChartSurface.annotations.getById(
                    this.tool.children[0].dictIdChildren!!
                  );
                child.x1 = this.x1;
                child.x2 = this.x2;
                child.y1 = this.y1;
                child.y2 = previousAnnotation.y2;
              }
              return;
            }
            const previousAnnotation =
              this.baseChart.sciChartSurface.annotations.getById(
                previousId
              ) as DrawTools;
            const annotation =
              this.baseChart.sciChartSurface.annotations.getById(child.id);
            const { yAxis, xAxis } = this.getAxis();
            if (!xAxis || !yAxis) {
              return;
            }
            const calcX = xAxis.getCurrentCoordinateCalculator();
            const calcY = yAxis.getCurrentCoordinateCalculator();
            const annoX1 = calcX.getCoordinate(previousAnnotation.x1);
            const annoX2 = calcX.getCoordinate(previousAnnotation.x2);
            const annoY1 = calcY.getCoordinate(previousAnnotation.y1);
            const fatherY1 = calcY.getCoordinate(fatherAnnotation.y1);
            const fatherY2 = calcY.getCoordinate(fatherAnnotation.y2);
            const diffX1X2 = (annoX1 - annoX2) * -1;
            const diffY1Y1 = fatherY1 - annoY1;
            const fatherY = fatherY1 - fatherY2;
            this.getBoxElement(annotation.id, 0).then((boxElement) => {
              this.setBoxElementAttributes(
                fatherAnnotation,
                previousAnnotation,
                diffY1Y1,
                diffX1X2,
                fatherY,
                child,
                eventData,
                countStepClickTool,
                boxElement,
                reposition
              );
            });
          }
        });
      }
    }
    if (countStepClickTool === 3) {
      this._updateChildrenParalelChannel$.next({
        function: () => {
          this.updateChildrenParalelChannel();
        },
      });
    }
  }

  private updateChildrenParalelChannel() {
    this.children.forEach((child) => {
      if (child.getName() !== this.getName()) {
        this.updateBoxParalelChannel(child);
      }
    });
  }

  private moveLine(
    eventData: TMouseEventData | undefined,
    reposition?: DrawToolsReposition
  ) {
    //let id = this.tool.dictId!!;
    const x2 = reposition?.x2 ? reposition.x2 : eventData!!.pointValues!!.x;
    const y2 = reposition?.y2 ? reposition.y2 : eventData!!.pointValues!!.y;
    const x1 = reposition?.x1;
    const y1 = reposition?.y1;
    /*if (reposition && reposition.id) {
      id = reposition.id;
    }*/
    this.x2 = x2;
    if (!this.tool.calculation) {
      this.y2 = y2;
    } else if (
      this.tool.calculation === TOOLS_CALCULATION.FIBONACCI_RETRACEMENT &&
      reposition &&
      reposition.id
    ) {
      this.y2 = y2;
    }
    this.x1 = x1 ? x1 : this.x1;
    this.y1 = y1 ? y1 : this.y1;
    this.lastPosition = { x1: this.x1, x2: this.x2, y1: this.y1, y2: this.y2 };
    this._moveLine$.next({
      function: () => {
        this.lastBorders = this.getAnnotationBorders();
      },
    });
  }

  customClick = () => {
    //do nothing.
  };

  repositionZoomDraw(): void {
    //do nothing.
  }

  customHover = () => {
    //do nothing.
  };

  private updateStyleBow(style: StyleTool) {
    this.tool.children?.forEach((child) => {
      if (!child.isAux) {
        const pathElement = document.getElementById(
          `PATH_${child.dictIdChildren!!}`
        );
        if (pathElement) {
          if (style.color || style.centerLineColor) {
            const color =
              style.centerLineColor!.rgbaHexColor!! ||
              style.color!.rgbaHexColor!!;
            pathElement.setAttribute('stroke', color!!);
          }
          if (style.strokeThickness) {
            pathElement.setAttribute(
              'stroke-width',
              `${style.strokeThickness}`
            );
          }
          if (style.backgroundColor) {
            pathElement.setAttribute(
              'fill',
              `${style.backgroundColor.rgbaHexColor}`
            );
          }
        }
      }
    });
  }

  updateStyleTool(style: StyleTool) {
    let childrenDraw!: TIGER_CHART_TOOL_CHILD;
    if (this.isChildren) {
      childrenDraw = this.tool.children!.find(
        (child) => child.dictIdChildren === this.dictIdChildren
      )!!;
    }
    if (style.typeColorUpdate && childrenDraw) {
      if (childrenDraw.typeDraw != style.typeColorUpdate) {
        return;
      }
    } else if (style.typeColorUpdate) {
      if (this.tool.typeDraw != style.typeColorUpdate) {
        return;
      }
    }
    if (this.tool.codTool === `${TOOLS_ENUM.GEOMETRIC_SHAPES}_BOW`) {
      this.updateStyleBow(style);
    }
    if (style.color || style.centerLineColor) {
      const color = style.centerLineColor
        ? style.centerLineColor.rgbaHexColor!!
        : style.color!.rgbaHexColor!!;
      this.stroke = color;
      if (childrenDraw) {
        childrenDraw.color = color;
        if (style.typeColorUpdate === TOOLS_TYPE_DRAW.CENTER_LINE) {
          this.tool.centerLineColor = style.centerLineColor!.rgbaHexColor!!;
        }
      } else {
        this.tool.color = color;
      }
    }
    if (style.strokeThickness) {
      this.strokeThickness = style.strokeThickness;
      if (childrenDraw) {
        if (!childrenDraw.dontUpdateLineThickness) {
          childrenDraw.lineThickness = style.strokeThickness;
        }
      } else {
        this.tool.lineThickness = style.strokeThickness;
      }
    }
    if (style.strokeDashArray) {
      this.strokeDashArray = style.strokeDashArray;
      if (childrenDraw) {
        if (!childrenDraw.dontUpdateStrokeDash) {
          childrenDraw.strokeDashArray = style.strokeDashArray;
        }
      } else {
        this.tool.strokeDashArray = style.strokeDashArray;
      }
    }
    this.strokePenCache.create({
      stroke: this.stroke,
      strokeDashArray: this.strokeDashArray,
      strokeThickness: this.strokeThickness,
      opacity: 1,
    });
    super.notifyPropertyChanged('strokeProperty');
  }

  private getBoxElement(id: string, tentativa: number): Promise<any> {
    return new Promise((resolve) => {
      const boxElement = document.getElementById(`PATH_${id}`);
      if (!boxElement) {
        if (tentativa < this.limitGetBoxElement) {
          return resolve(this.getBoxElement(id, ++tentativa));
        }
      } else {
        return resolve(boxElement);
      }
    });
  }

  private setBoxElementAttributes(
    fatherAnnotation: DrawTools,
    annotation: DrawTools,
    diffY1Y1: number,
    diffX1X2: number,
    fatherY: number,
    child: DrawTools,
    eventData: TMouseEventData,
    countStepClickTool: number,
    boxElement?: HTMLElement,
    reposition?: DrawToolsReposition
  ) {
    if (boxElement) {
      //boxElement.setAttribute(
      //  'd',
      //  `M 0 0 L 0 ${diffY1Y1} L ${diffX1X2} ${
      //    diffY1Y1 + fatherY * -1
      //  } L ${diffX1X2} ${fatherY * -1}`
      //);
      const childDraw = this.baseChart.sciChartSurface.annotations.getById(
        child.id
      ) as DrawTools;
      childDraw.updateDrawTool(eventData, countStepClickTool, {
        id: child.id,
        x1: fatherAnnotation.x1,
        x2: fatherAnnotation.x2,
        y1: fatherAnnotation.y1 - (fatherAnnotation.y1 - annotation.y1),
        y2: fatherAnnotation.y2 + (fatherAnnotation.y2 - annotation.y2),
        stock: reposition?.stock,
      });
    }
  }

  updateTool(tool: TIGER_CHART_TOOL) {
    this.tool = tool;
  }

  resizeSVG(
    typeResize: TYPE_RESIZE,
    childId: string,
    svgPosition?: { x1: number; x2: number; y1: number; y2: number },
    position?: { x1: number; x2: number; y1: number; y2: number }
  ) {
    if (this.isDrawing) {
      if (!this.mainY1) {
        this.mainY1 = deepClone(this.y1);
      }
      if (!this.mainY2) {
        this.mainY2 = deepClone(this.y2);
      }
      if (!this.maxYValue) {
        this.maxYValue = deepClone(this.tool.maxYValue!!);
      }
      return;
    }
    if (!position) {
      return;
    }
    if (this.tool.codTool === `${TOOLS_ENUM.GEOMETRIC_SHAPES}_TRIANGLE`) {
      const boxAux = this.tool.children?.find(
        (child) =>
          child.isAux &&
          childId === child.dictIdChildren &&
          child.method === TOOLS_METHOD.BOX_ANNOTATION
      );
      const children = this.tool.children?.filter(
        (child) => childId != child.dictIdChildren
      );
      if (boxAux && children) {
        children.sort((c) => {
          return !c.isAux ? 1 : -1;
        });
        this.x1 = position.x1;
        this.x2 = position.x2;
        const diffY = Math.abs(this.y2 - this.y1);
        if (this.y1 < this.y2) {
          this.y1 = position.y1;
          this.y2 = position.y1 + diffY;
        } else {
          this.y2 = position.y1;
          this.y1 = position.y1 + diffY;
        }
        let diffX2: number | undefined = undefined;
        children.forEach((child, k) => {
          const drawChild = this.baseChart.sciChartSurface.annotations.getById(
            child.dictIdChildren!!
          ) as DrawTools;
          drawChild.isDrawing = true;
          if (child.method === TOOLS_METHOD.DRAW_LINE) {
            if (!diffX2) {
              diffX2 = position.x2 - drawChild.x2;
            }
            const diffY2 = drawChild.y2 - drawChild.y1;
            if (!child.isAux) {
              if (k === 1) {
                drawChild.x1 = this.x1;
                drawChild.x2 = this.x1 + diffX2;
                drawChild.y1 = this.y1;
                drawChild.y2 = this.y1 + diffY2;
              } else {
                drawChild.x1 = this.x2;
                drawChild.x2 = this.x1 + diffX2;
                drawChild.y1 = this.y2;
                drawChild.y2 = this.y2 + diffY2;
              }
            } else {
              drawChild.x1 = this.x1 + diffX2;
              drawChild.x2 = this.x1 + diffX2;
              drawChild.y1 = this.y1;
              drawChild.y2 = this.y1 + diffY2;
            }
          }
          if (child.method === TOOLS_METHOD.CUSTOM_BOX_ANNOTATION) {
            drawChild.y1 = this.y2;
            drawChild.x1 = this.x1;
            this.repositionTriangleBackground(child.dictIdChildren!!);
          }
        });
      }
      return;
    }
    if (
      this.tool.calculation &&
      this.tool.calculation === TOOLS_CALCULATION.FIBONACCI_RETRACEMENT
    ) {
      const children = this.tool.children?.filter(
        (child) => child.method === TOOLS_METHOD.DRAW_LINE
      );
      if (!this.maxYValue) {
        this.maxYValue = deepClone(this.tool.maxYValue!!);
      }
      this.y2 = this.mainY1!! - (this.maxYValue!! - position.y1);
      this.y1 = deepClone(this.y2);
      this.tool.maxYValue = position.y1;
      this.tool.minYValue = deepClone(this.y2);
      this.x1 = position.x1;
      this.x2 = position.x2;
      this.updateFibonacciRetracementChildren(this, false);
      const auxLine = children?.find((child) => child.isAux);
      if (children && auxLine) {
        const lastChild = children[children.length - 2];
        const drawLine = this.baseChart.sciChartSurface.annotations.getById(
          auxLine.dictIdChildren!!
        ) as DrawTools;
        const lastDraw = this.baseChart.sciChartSurface.annotations.getById(
          lastChild.dictIdChildren!!
        ) as DrawTools;
        drawLine.isDrawing = true;
        drawLine.x1 = this.x1;
        drawLine.x2 = this.x2;
        drawLine.y1 = this.y1;
        drawLine.y2 = lastDraw.y2;
        this._isDrawing$.next({
          function: () => {
            drawLine.isDrawing = false;
          },
        });
      }
    }
    if (this.tool.codTool === `${TOOLS_ENUM.TREND_LINES}_CANAL_PARALELO`) {
      const diffY1 = this.y1 - position.y1;
      const diffX1 = this.x1 - position.x1;
      const diffX2 = this.x2 - position.x2;
      this.y1 = this.y1 - diffY1;
      this.y2 = this.y2 - diffY1;
      this.x1 = this.x1 - diffX1;
      this.x2 = this.x2 - diffX2;
      this.children.forEach((child, k) => {
        if (child.getName() === this.getName()) {
          const diffChild = child.y2 - child.y1;
          let y1 = child.y1 - diffY1;
          let y2 = child.y2 - diffY1;
          const x1 = child.x1 - diffX1;
          const x2 = child.x2 - diffX2;
          if (child.y2 != position.y2) {
            y2 = position.y2;
            y1 = position.y2 - diffChild;
          }
          if (
            this.tool.children &&
            this.tool.children[k] &&
            this.tool.children[k].relativePosition
          ) {
            if (this.tool.children[k].relativePosition === 'middle') {
              y1 = (this.y1 + this.children[0].y1) / 2;
              y2 = (this.y2 + this.children[0].y2) / 2;
            }
          }
          child.y1 = y1;
          child.y2 = y2;
          child.x1 = x1;
          child.x2 = x2;
        } else {
          this.updateBoxParalelChannel(child);
        }
      });
    }
  }

  private updateBoxParalelChannel(child: DrawTools) {
    const childTool = this.tool.children?.find(
      (c) => c.dictIdChildren === child.id
    );
    if (!childTool?.isAux) {
      const annoX1 = this.children[0].getAnnotationBorders().x1;
      const annoX2 = this.children[0].getAnnotationBorders().x2;
      const annoY1 = this.children[0].getAnnotationBorders().y1;
      const fatherY1 = this.getAnnotationBorders().y1;
      const fatherY2 = this.getAnnotationBorders().y2;
      const diffX1X2 = (annoX1 - annoX2) * -1;
      const diffY1Y1 = fatherY1 - annoY1;
      const fatherY = fatherY1 - fatherY2;
      const boxElement = document.getElementById(
        `PATH_${childTool?.dictIdChildren}`
      )!!;
      this.setBoxElementAttributes(
        this,
        this.children[0],
        diffY1Y1,
        diffX1X2,
        fatherY,
        child,
        this.lastEventData,
        2,
        boxElement
      );
    }
  }

  private resizeFather(
    typeResize: TYPE_RESIZE,
    dictIdChildren?: string,
    mouseValues?: Point
  ) {
    const dictId = dictIdChildren || this.dictIdChildren;
    const childrenConfig = this.tool.children?.find(
      (child) => child.dictIdChildren === dictId
    );
    if (
      this.isChildren &&
      childrenConfig &&
      childrenConfig.isAux &&
      this.tool.hasBoxAux
    ) {
      const father = this.baseChart.sciChartSurface.annotations.getById(
        this.tool.dictId!!
      ) as DrawTools;
      const borders = this.getAnnotationBorders();
      if (father && father.resizeSVG) {
        if (
          this.tool.calculation &&
          this.tool.calculation === TOOLS_CALCULATION.FIBONACCI_RETRACEMENT
        ) {
          this.refreshFibonacciRetracement();
          const line = father as LineTools;
          line.mainY1 = deepClone(this.y1);
          return;
        } else {
          father.tool.mouseValues = mouseValues;
          father.resizeSVG(
            typeResize,
            dictId,
            {
              x1: 0,
              x2: borders.x2 - borders.x1,
              y1: 0,
              y2: borders.y2 - borders.y1,
            },
            {
              x1: deepClone(this.x1),
              x2: deepClone(this.x2),
              y1: deepClone(this.y1),
              y2: deepClone(this.y2),
            }
          );
        }
      }
    }
  }

  updateFather(typeResize: TYPE_RESIZE, dictIdChildren?: string) {
    if (this.isDrawing) {
      return;
    }
    this.resizeFather(typeResize, dictIdChildren);
  }

  private refreshFibonacciRetracement() {
    const fatherDraw = this.baseChart.sciChartSurface.annotations.getById(
      this.tool.dictId!!
    ) as DrawTools;
    if (fatherDraw.x1 != this.x1 || fatherDraw.x2 != this.x2) {
      fatherDraw.x1 = this.x1;
      fatherDraw.x2 = this.x2;
    }
    if (fatherDraw.y1 != this.y1 || fatherDraw.y1 != this.y1) {
      fatherDraw.y1 = this.y1;
      fatherDraw.y2 = this.y1;
    }
    this.updateFibonacciRetracementChildren(fatherDraw, true);
    const box = this.tool.children?.find(
      (child) => child.isAux && child.method === TOOLS_METHOD.BOX_ANNOTATION
    );
    if (box) {
      const children = this.tool.children?.filter(
        (child) => child.method === TOOLS_METHOD.DRAW_LINE
      );
      if (children) {
        const lastChild = children[children.length - 2];
        const annotations = this.baseChart.sciChartSurface.annotations;
        const drawBox = this.baseChart.sciChartSurface.annotations.getById(
            box.dictIdChildren!!
          ) as DrawTools,
          firstDraw = annotations.getById(
            this.tool.children!![0].dictIdChildren!!
          ),
          lastDraw = annotations.getById(lastChild.dictIdChildren!!);
        drawBox.isDrawing = true;
        drawBox.x1 = this.x1;
        drawBox.x2 = this.x2;
        drawBox.y1 = lastDraw.y1;
        drawBox.y2 = firstDraw.y2;
        this._isDrawing$.next({
          function: () => {
            drawBox.isDrawing = false;
          },
        });
      }
    }
  }

  private updateFibonacciRetracementChildren(
    fatherDraw: DrawTools,
    updateMaxYValue: boolean
  ) {
    if (this.tool.children) {
      if (updateMaxYValue) {
        this.tool.maxYValue = this.y2;
        this.tool.minYValue = this.y1;
        const line = fatherDraw as LineTools;
        line.maxYValue = this.y2;
        line.minYValue = this.y1;
        fatherDraw.updateTool(this.tool);
      }
      this.tool.children.forEach((child) => {
        if (child.isAux) {
          return;
        }
        let x1 = this.x1;
        const x2 = this.x2;
        let y1 = !isNullOrUndefined(child.y1Position)
          ? eval(`${this.y2} ${child.typeCalculation} ${child.y1Position!!}`)
          : undefined;
        let y2 = !isNullOrUndefined(child.y2Position)
          ? eval(`${this.y2} ${child.typeCalculation} ${child.y2Position!!}`)
          : undefined;
        let text = undefined;
        const UR =
          this.tool.maxYValue!! -
          (this.tool.maxYValue!! - this.tool.minYValue!!) * child.y1Position!!;
        y1 = UR;
        y2 = UR;
        if (child.method === TOOLS_METHOD.BOX_ANNOTATION) {
          y2 =
            this.tool.maxYValue!! -
            (this.tool.maxYValue!! - this.tool.minYValue!!) *
              child.y2Position!!;
        }
        const formattedUR = formatNumber(
          UR,
          this.locale,
          `1.${this.configs.yAxis.labelPrecision || 0}-${
            this.configs.yAxis.labelPrecision || 0
          }`
        );
        const draw = this.baseChart.sciChartSurface.annotations.getById(
          child.dictIdChildren!!
        );
        if (child.method === TOOLS_METHOD.TEXT_ANNOTATION) {
          x1 = fatherDraw.x1;
          if (this.x2 < this.x1) {
            (draw as TextAnnotation).verticalAnchorPoint =
              EVerticalAnchorPoint.Center;
            (draw as TextAnnotation).horizontalAnchorPoint =
              EHorizontalAnchorPoint.Left;
            (draw as TextAnnotation).xCoordShift = 10;
          } else {
            (draw as TextAnnotation).verticalAnchorPoint =
              EVerticalAnchorPoint.Center;
            (draw as TextAnnotation).horizontalAnchorPoint =
              EHorizontalAnchorPoint.Right;
            (draw as TextAnnotation).xCoordShift = -10;
          }
        }
        text = `${child.y1Position!!}(${formattedUR})`;
        (draw as DrawTools).updateDrawTool(undefined, this.tool.stepClick, {
          id: child.dictIdChildren,
          x1,
          x2,
          y1,
          y2,
          text,
          stock: this.reposition?.stock,
        });
      });
    }
  }

  private updateLineInfoBoxPosition() {
    const childAnnotation = this.baseChart.sciChartSurface.annotations.getById(
      this.tool.children!![0].dictIdChildren!!
    );
    childAnnotation.x1 = this.x1;
    childAnnotation.x2 = this.x2;
    childAnnotation.y1 = this.y1;
    childAnnotation.y2 = this.y2;
  }

  private getAxis(): Axis {
    const yAxis = this.baseChart.sciChartSurface.yAxes.getById(this.yAxisId);
    const xAxis = this.baseChart.sciChartSurface.xAxes.getById(this.xAxisId);
    if (!yAxis || !xAxis) {
      return { xAxis: undefined, yAxis: undefined };
    }
    try {
      yAxis.getCurrentCoordinateCalculator();
      xAxis.getCurrentCoordinateCalculator();
    } catch (e: any) {
      return { xAxis: undefined, yAxis: undefined };
    }
    return { yAxis, xAxis };
  }

  private updateTemporaryLine(data: TemporaryLine) {
    const { eventData, reposition } = data;
    this.lastEventData = eventData;
    if (eventData) {
      this.moveLine(eventData, reposition);
    }
    this.repositionTemporaryBox(eventData);
  }

  private getElement(idElement: string) {
    const id = `#${this.parentSurface.domChartRoot.id}`;
    return document.querySelector(`${id} ${idElement}`) as HTMLElement;
  }

  private repositionTemporaryBox(eventData?: TMouseEventData) {
    if (!this || !this.getAdornerAnnotationBorders() || !this.tool.children) {
      return;
    }
    const child = this.tool.children.find(
      (c) => c.method === TOOLS_METHOD.CUSTOM_BOX_ANNOTATION
    );
    if (!child) {
      return;
    }

    const rectElement = this.getElement(`#FOREIGN_${child.dictIdChildren}`);
    const svgElement = this.getElement(`#SVG_${child.dictIdChildren}`);
    const variationTextElement = this.getElement(
      `#VARIATION_${child.dictIdChildren}`
    );
    const diffTextElement = this.getElement(`#DIFF_${child.dictIdChildren}`);
    const p1TextElement = this.getElement(`#P1_${child.dictIdChildren}`);
    const p2TextElement = this.getElement(`#P2_${child.dictIdChildren}`);
    const intervalTextElement = this.getElement(
      `#INTERVAL_${child.dictIdChildren}`
    );
    const candlesTextElement = this.getElement(
      `#CANDLES_${child.dictIdChildren}`
    );
    const x1 = Math.abs(this.getAdornerAnnotationBorders().x1);
    const x2 = Math.abs(this.getAdornerAnnotationBorders().x2);
    const y1 = Math.abs(this.getAdornerAnnotationBorders().y1);
    const y2 = Math.abs(this.getAdornerAnnotationBorders().y2);
    if (rectElement) {
      let x = 0;
      let y = 0;
      if (eventData) {
        x = eventData.mousePoint!!.x - x1 + 15;
        y = eventData.mousePoint!!.y - y1 - 30;
      } else {
        x = x2 - x1 + 15;
        if (y2 < y1) {
          y = y2 - y1 + 15;
        } else {
          y = -(y1 - y2 + 15);
        }
      }
      let width = 100;
      rectElement.setAttribute('height', '160');
      rectElement.setAttribute('x', `${x}`);
      rectElement.setAttribute('y', `${y}`);
      let date1!: Date;
      if (eventData) {
        date1 = new Date(eventData.xCategoryValue!!);
      } else {
        date1 = new Date(this.lastEventData.xCategoryValue!!);
      }
      let date2 = new Date(this.tool.xCategoryValue!!);
      const series = this.baseChart.sciChartSurface.renderableSeries;
      const serie = series.getById(CANDLE_IDS.MAIN_SERIE) ?? series.get(0);
      const seriesCount = serie.dataSeries.count();
      const lastPoint = serie.getNativeXValues().get(seriesCount - 1);
      if (date1.getTime() > lastPoint) date1 = new Date(lastPoint);
      if (date2.getTime() > lastPoint) date2 = new Date(lastPoint);
      const diffDates = date1.getTime() - date2.getTime();
      const minutes = Math.round(diffDates / 1000 / 60);
      const minutesToHour = minutes / 60;
      let hours = parseInt(minutesToHour.toString());
      const hourMinutes = Math.abs((minutesToHour - hours) * 60);
      let hourMinutesText = ` ${parseInt(hourMinutes.toString())} minutos`;
      let typeTimer = ' horas';
      const days = Math.round(hours / 24);
      const hashTime: any = {
        ONE_MINUTE: minutes / 1,
        TWO_MINUTE: minutes / 2,
        FIVE_MINUTE: minutes / 5,
        TEN_MINUTE: minutes / 10,
        FIFTEEN_MINUTE: minutes / 15,
        THIRTY_MINUTE: minutes / 30,
        ONE_HOUR: hours / 1,
        ONE_DAY: days / 1,
        THIS_WEEK: days / 7,
        ONE_MONTH: Math.round(days / 30),
      };
      const objectInterval = TIGER_INTERVAL_ENUM;
      const qttyTime = hashTime[objectInterval[this.configs.series.interval!!]];
      if (!objectInterval[this.configs.series.interval!!].includes('MINUTE')) {
        hourMinutesText = '';
      }
      if (
        objectInterval[this.configs.series.interval!!].includes('YEAR') ||
        objectInterval[this.configs.series.interval!!].includes('MONTH') ||
        objectInterval[this.configs.series.interval!!].includes('WEEK') ||
        objectInterval[this.configs.series.interval!!].includes('DAY')
      ) {
        hours = days;
        typeTimer = ' dias';
      }
      const time =
        Math.abs(hours) > 0
          ? `${Math.abs(hours)}${typeTimer}${hourMinutesText}`
          : `${hourMinutesText.trim()}`;
      const tickSizeDenominator = this.stock.tick_size_denominator;
      const firstY = parseFloat(
        this.tool.pointValues!!.y.toFixed(tickSizeDenominator)
      );
      const secondY = parseFloat(
        this.lastEventData!!.pointValues!!.y.toFixed(tickSizeDenominator)
      );
      const percent = (secondY / firstY - 1) * 100;
      const points = this.y2 - this.y1;
      let textColor = '';
      if (this.stock.cd_security_type === 'FUT') {
        textColor = this.updateTemporaryColor(
          parseFloat(formatNumber(points, 'en-US', `1.0-0`))
        );
        variationTextElement!!.innerHTML = `Variação: <span style='color: ${
          this.stroke
        }'>${formatNumber(points, this.locale, `1.0-0`)} pts | ${formatNumber(
          percent,
          this.locale,
          `1.2-2`
        )}%</span>`;
      } else {
        textColor = this.updateTemporaryColor(
          parseFloat(formatNumber(percent, 'en-US', `1.2-2`))
        );
        variationTextElement!!.innerHTML = `Variação: <span style='color: ${
          this.stroke
        }'>${formatNumber(percent, this.locale, `1.2-2`)}%</span>`;
      }
      this.temporaryLineGrips();
      this.annotationsGripsFill = this.stroke;
      this.annotationsGripsStroke = this.stroke;
      const digitsStock = `1.${this.stock.tick_size_denominator}-${this.stock.tick_size_denominator}`;
      const event = eventData ?? this.lastEventData;
      diffTextElement!!.textContent = `Diferença: ${formatNumber(
        event.pointValues!!.y - this.tool.pointValues!!.y,
        this.locale,
        digitsStock
      )} `;
      p1TextElement!!.textContent = `Ponto 1: ${formatNumber(
        this.tool.pointValues!!.y,
        this.locale,
        digitsStock
      )}`;
      p2TextElement!!.textContent = `Ponto 2: ${formatNumber(
        event.pointValues!!.y,
        this.locale,
        digitsStock
      )}`;
      intervalTextElement!!.textContent = `Intervalo: ${time}`;
      candlesTextElement!!.textContent = `Candles: ${formatNumber(
        Math.abs(qttyTime),
        this.locale,
        `1.0-0`
      )}`;
      intervalTextElement!!.style.color = textColor;
      variationTextElement!!.style.color = textColor;
      diffTextElement!!.style.color = textColor;
      p1TextElement!!.style.color = textColor;
      p2TextElement!!.style.color = textColor;
      candlesTextElement!!.style.color = textColor;
      width =
        Math.max(
          intervalTextElement!!.getBoundingClientRect().width,
          variationTextElement!!.getBoundingClientRect().width,
          diffTextElement!!.getBoundingClientRect().width,
          p1TextElement!!.getBoundingClientRect().width,
          p2TextElement!!.getBoundingClientRect().width,
          candlesTextElement!!.getBoundingClientRect().width
        ) + 20;
      rectElement.setAttribute('width', `${width}`);
      const customBoxId = this.tool.children![0].dictIdChildren;
      const customBox = this.baseChart.sciChartSurface.annotations.getById(
        customBoxId!
      ) as CustomBoxTools;
      customBox!.svgString = svgElement!.outerHTML!;
      this._repositionTemporaryBox$.next({
        function: () => {
          this.updateStyleTool({
            color: {
              rgbaHexColor: this.stroke,
              hexColor: '',
              rgbaColor: '',
              rgbColor: '',
              selected: false,
            },
          });
        },
      });
    }
  }

  private updateTemporaryColor(value: number) {
    const textColor = 'white';
    if (value > 0) {
      this.stroke = CHART_COLORS.FEEDBACK_POSITIVE;
      //textColor = 'black';
    } else if (value < 0) {
      this.stroke = CHART_COLORS.FEEDBACK_NEGATIVE;
    } else {
      this.stroke = '#1DA1F2';
    }
    return textColor;
  }
}
