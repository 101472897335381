import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { RestService } from '@shared/services/rest/rest.service';
import {
  INews,
  INewsDetails,
  INewsService,
  INewsV8,
} from '@core/interface/news-agency.interface';
import { Dictionary } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class TrademapNewsService extends RestService {
  private _newsCacheResponse = new Dictionary();
  override _url: string = 'api/trademap/v8/news';

  constructor(http: HttpClient) {
    super(http);
  }

  getCVMNews = (
    offset: number,
    limit: number = 20,
    period: string = 'next'
  ): Observable<INews[]> =>
    this.post<any>('get-news-cvm', {
      period,
      filters: { isRocket: true },
      offset,
      limit,
    }).pipe(
      map((res) => {
        if (res.data && res.data?.success && res.data?.result) {
          return res.data.result;
        }
        return null;
      }),
      catchError((error: any) => of(error))
    );

  getNewsDetail = (
    id: number,
    id_news_agency: number,
    agency_type: string
  ): Observable<INewsDetails> =>
    this.post<any>('get-news-detail', {
      id,
      id_news_agency,
      agency_type,
    }).pipe(
      map((res) => {
        if (res.data && res.data?.success && res.data?.result)
          return res.data.result.data;
        throw new Error('');
      }),
      catchError((error: any) => of(error))
    );

  public getNews(cdStock: string): Observable<INewsV8[]> {
    const key = `getNewsService_${cdStock}`;
    if (this._newsCacheResponse.has(key))
      return of(this._newsCacheResponse.get(key) as INewsV8[]);
    return this.post<INewsService>('get-news', {
      filters: { cd_stock: cdStock, tags: null, tags_tm: [] },
      cd_stock: cdStock,
      period: 'onemonth',
      after_point: null,
      tags: null,
      limit: null,
      offset: null,
      isFull: false,
      tags_tm: [],
    }).pipe(
      catchError((error) => of(error)),
      map((res) => {
        if (res.data && res.data?.success && res.data?.result) {
          this._newsCacheResponse.set(key, res.data.result.data);
          return res.data.result.data;
        }
        throw new Error('');
      })
    );
  }
}
