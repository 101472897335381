import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlaSelectComponent } from './select.component';
import { FlaIconModule } from '../icon/icon.module';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    FlaIconModule,
    ReactiveFormsModule,
  ],
  declarations: [FlaSelectComponent],
  exports: [FlaSelectComponent],
})
export class FlaSelectModule {}
