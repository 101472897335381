<app-times-trades-header
  *ngIf="component"
  [refComponent]="component.id"
  [component]="component"
  [stockSelected]="stockSelected"
  [headerOptions]="headerOptions"
  [tradeByOrderActive]="useTradeByOrders"
  [filterAndHighlightActive]="
    filtersAndHighlightEnabled && shouldDisableShortCutFilter
  "
  [haveCustomFilters]="haveCustomFiltersAndHighlights"
  (changeStock)="stockChanges($event)"
  (changeTradeByOrder)="handleTradeByOrderCallback()"
  (handleOpenTradeSettings)="openTradeFilterSettings()"
  (useSmallShortCutSize)="updateFooterHeight($event)"
></app-times-trades-header>
<div
  class="position-relative font-monospace d-flex flex-column"
  [ngClass]="isDesktop ? 'component-height' : 'h-100'"
  #timesTrade
>
  <div
    *ngIf="(dataMultibroker && metadataConfiguration.showPressure) || isTour"
    class="force-chart-height"
  >
    <app-force-chart
      [small]="true"
      [data]="dataMultibroker"
      [width]="width"
      [toFixed]="0"
    >
    </app-force-chart>
  </div>
  <app-details-tab
    *ngIf="(metadataConfiguration.showDetails && stockPrices) || isTour"
    [cdStock]="stockSelected.cd_stock"
    [arrowHex]="arrow_hex"
    [precoUltimo]="preco_ultimo"
    [variacaoDia]="variacao_dia"
    [precoMinimo]="preco_minimo"
    [precoMaximo]="preco_maximo"
    [volume]="volume"
    [tickSizeDenominator]="tick_size_denominator"
    [dsType]="stockSelected.ds_type"
    [stockAuctionInfos]="stockAuctionInfos"
  >
  </app-details-tab>

  <div class="position-relative h-100">
    <table class="w-100">
      <thead class="bg-neutral-strong">
        <tr id="TIMES_TRADES_TRADE_INFOS" [style.maxHeight.px]="26">
          <th>Hora</th>
          <th
            tooltip="Comprador"
            tooltipPosition="bottom"
            class="ag-center-aligned-header text-multibroker-buy"
          >
            C
          </th>
          <th>Preço</th>
          <th
            tooltip="Quantidade"
            tooltipPosition="bottom"
            class="ag-center-aligned-header"
          >
            Qtd
          </th>
          <th
            tooltip="Vendedor"
            tooltipPosition="bottom"
            class="ag-center-aligned-header text-multibroker-sell"
          >
            V
          </th>
          <th
            tooltip="Agressor"
            tooltipPosition="bottom"
            class="ag-center-aligned-header"
          >
            A
          </th>
        </tr>
      </thead>
    </table>

    <cdk-virtual-scroll-viewport
      itemSize="17"
      minBufferPx="0"
      maxBufferPx="0"
      class="viewport"
      [style.height.px]="virtualScrollViewportHeigth"
    >
      <table id="TIMES_TRADES_TABLE_TRADES" class="w-100 h-100">
        <tbody>
          <ng-container *cdkVirtualFor="let row of data; trackBy: trackByFn">
            <tr
              class="virtual-scroll-item"
              app-trade-row
              [data]="row"
              [showMs]="showMilliseconds"
              [stock]="stockSelected"
              [displayHighlights]="filtersAndHighlightEnabled"
              [highlightColors]="highlightColors"
            ></tr>
          </ng-container>
        </tbody>
      </table>
    </cdk-virtual-scroll-viewport>

    <app-trades-filter-shortcut
      [stockStandardLot]="stockSelected.standard_lot"
      [enableFiltersAndHighlights]="filtersAndHighlightEnabled"
      [disableShortCutFilter]="shouldDisableShortCutFilter"
      [initialFastTradeID]="fastTradeID"
      (updateFastFilter)="handleFastFilter($event)"
      (updateFiltersAndHighlightsVisibility)="
        onChangeFiltersPreferences($event)
      "
    ></app-trades-filter-shortcut>

    <ng-container *ngIf="withoutPreviusTrades">
      <div
        [style.top.px]="16"
        class="w-100 position-absolute d-inline-flex justify-content-center my-3 fs-5 text-center"
      >
        Nenhum trade encontrado
      </div>
    </ng-container>
  </div>
</div>
