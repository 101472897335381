import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';
import { OptionsComponents } from '../component-list';

export const SUPER_DOM_COMPONENT: any = (options?: OptionsComponents) => {
  return {
    name: 'SuperDOM',
    id: COMPONENTS_NAMES_ENUM.SUPER_DOM,
    icon: '',
    cod: 'SUPER_DOM',
    data: null,
    close: true,
    fullscreen: true,
    link: true,
    widthDesk: 410,
    heightDesk: 415,
    unitOfMeasurement: '%',
    width: options?.width ?? 0,
    height: options?.height ?? 100,
    top: options?.top ?? '0px',
    left: options?.left ?? 'calc(100% - 326px)',
    minHeight: '370px', //poderia ser 50
    minWidth: '326px',
    iconSvg: 'SUPER_DOM',
  };
};
