import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NoInfoComponent } from './no-info.component';

@NgModule({
  imports: [CommonModule],
  declarations: [NoInfoComponent],
  exports: [NoInfoComponent],
})
export class NoInfoModule {}
