<div class="h-100 w-100 d-flex justify-content-center align-items-center">
  <span>Ativo em Leilão</span>
  <div class="p-1 d-flex align-items-center">
    <span
      tooltip="Variação no leilão"
      tooltipPosition="bottom"
      [tooltipIsAdaptive]="false"
      class="pr-1"
      >{{ auctionVariation }}
    </span>
    |
    <span
      tooltip="Preço atual do leilão"
      tooltipPosition="bottom"
      [tooltipIsAdaptive]="false"
      class="pl-1"
      >{{ auctionPrice }}
    </span>
    <span
      [tooltip]="
        typeAuctionQttyNotAnswered.includes('C')
          ? 'Compra não atendida'
          : 'Venda não atendida'
      "
      tooltipPosition="bottom"
      [tooltipIsAdaptive]="false"
      class="pl-2 pr-1"
      >{{ typeAuctionQttyNotAnswered }}
    </span>
    <span
      tooltip="Quantidade não atendida do leilão"
      tooltipPosition="bottom"
      [tooltipIsAdaptive]="false"
      class="pr-1"
      >{{ auctionQttyNotAnswered }}
    </span>
    /
    <span
      tooltip="Quantidade negociada do leilão"
      tooltipPosition="bottom"
      [tooltipIsAdaptive]="false"
      class="pl-1"
      >{{ auctionQtty }}
    </span>
    <div
      tooltip="Tempo para encerrar leilão"
      tooltipPosition="bottom"
      [tooltipIsAdaptive]="false"
      class="pl-1 pr-1 d-flex align-items-center"
    >
      <span class="pr-1 icon-sized user-select-none material-icons-outlined">
        timer
      </span>
      {{ timer }}
    </div>
  </div>
  <span
    [tooltip]="auctionMessage"
    tooltipPosition="bottom"
    [tooltipIsAdaptive]="false"
    class="icon-sized user-select-none material-icons-outlined"
  >
    info
  </span>
</div>
