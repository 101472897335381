import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Dictionary } from '@core/models';
import { randomId } from '@shared/rocket-components/utils';
import { RTColorPickerSelectorComponent } from '@shared/components/color-picker/color-picker.component';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import {
  getTextByRegex,
  isNullOrUndefined,
} from 'src/app/utils/utils.functions';
import {
  TOOLS_CALCULATION,
  TOOLS_TYPE_DRAW,
} from '../../tiger-chart-tools.enum';
import { TIGER_CHART_TOOL } from '../../tiger-chart-tools.interface';
import { ColorsBaseService } from '../colors-base.service';
import { color } from '../toolbar-draw.interface';
import { ColorsBase } from '../toolbar-draw.model';

@Component({
  selector: 'app-config-colorpicker',
  templateUrl: './config-colorpicker.component.html',
  styleUrls: [
    './config-colorpicker.component.scss',
    `./config-colorpicker2.component.scss`,
  ],
})
export class ConfigColorpickerComponent
  extends ColorsBase
  implements AfterViewInit
{
  rtDropnOpened: boolean = false;
  isTool: boolean = true;
  isNewColor: boolean = false;
  tool!: TIGER_CHART_TOOL;
  color!: color | undefined;
  id: string = randomId('config-colorpicker');
  colorSettingsDict = new Dictionary<boolean>();
  @Output() selectedColor = new EventEmitter<{
    color: color;
    typeColorUpdate?: TOOLS_TYPE_DRAW;
  }>();
  @Input() typeColorUpdate?: TOOLS_TYPE_DRAW;
  @Input() set selectedTool(data: TIGER_CHART_TOOL) {
    if (data) {
      this.tool = data;
      this.makeToolInfo(data);
    }
  }
  @Input() set resetTool(data: boolean) {
    if (data) {
      this.color = undefined;
      this.makeToolInfo(this.tool);
    }
  }
  @Input() set useColor(hexColor: string) {
    if (hexColor) {
      this.isTool = false;
      this.color = undefined;
      this.configureColor(hexColor);
    }
  }
  @ViewChild('colorpicker', { static: false })
  private _colorPicker!: RTColorPickerSelectorComponent;
  private _customPreferencesService!: CustomPreferencesService;
  private _colorsBaseService!: ColorsBaseService;
  opacityValue = 100;
  constructor(
    customPreferencesService: CustomPreferencesService,
    colorsBaseService: ColorsBaseService
  ) {
    super(customPreferencesService, colorsBaseService);
    this._customPreferencesService = customPreferencesService;
    this._colorsBaseService = colorsBaseService;
  }

  ngAfterViewInit() {
    if (!this.tool) {
      return;
    }
    if (this.typeColorUpdate === TOOLS_TYPE_DRAW.LINE) {
      this.setOpacityColor(this.tool.color!!);
    }
    if (this.typeColorUpdate === TOOLS_TYPE_DRAW.BACKGROUND) {
      if (this.tool.backgroundColor) {
        const rgba = this.hex2Rgba(this.tool.backgroundColor);
        const rgb = rgba
          .replace('rgba', 'rgb')
          .replace(/,\s\d?\.?\d{1,}\)/g, `)`);
        const hex = this.rgba2hex(rgb);
        this.setOpacityColor(hex);
      }
    }
    if (this.typeColorUpdate === TOOLS_TYPE_DRAW.TEXT) {
      if (this.tool.textColor) {
        const rgba = this.hex2Rgba(this.tool.textColor);
        const rgb = rgba
          .replace('rgba', 'rgb')
          .replace(/,\s\d?\.?\d{1,}\)/g, `)`);
        const hex = this.rgba2hex(rgb);
        this.setOpacityColor(hex);
      }
    }
    if (this.typeColorUpdate === TOOLS_TYPE_DRAW.OUTLINE) {
      if (this.tool.outlineColor) {
        const rgba = this.hex2Rgba(this.tool.outlineColor);
        const rgb = rgba
          .replace('rgba', 'rgb')
          .replace(/,\s\d?\.?\d{1,}\)/g, `)`);
        const hex = this.rgba2hex(rgb);
        this.setOpacityColor(hex);
      }
    }
  }

  addNewColor(e: MouseEvent) {
    e.stopPropagation();
    this.isNewColor = true;
    setTimeout(() => {
      const ref = this._colorPicker.onClose.subscribe((data) => {
        this.isNewColor = false;
        if (!data || !data.value) {
          return;
        }
        const newColor: color = {
          rgbColor: data.value.rgb.replace(/,/g, ', ').replace(', 1)', ')'),
          rgbaColor: data.value.rgb.replace('rgb', 'rgba').replace(/,/g, ', '),
          rgbaHexColor: this.rgba2hex(data.value.rgb.replace(/,/g, ', ')),
          hexColor: data.value.hex,
          selected: true,
        };
        const stringColors: any =
          this._customPreferencesService.userColorDrawTools;
        let userColors: Array<color> = [];
        if (!isNullOrUndefined(stringColors) && stringColors != '') {
          userColors = JSON.parse(stringColors) as Array<color>;
          userColors.forEach((color) => {
            if (color.selected) {
              color.selected = false;
            }
          });
        }
        userColors.push(newColor);
        this._customPreferencesService.userColorDrawTools =
          JSON.stringify(userColors);
        setTimeout(() => {
          this.selectColor(newColor);
        }, 50);
        this.userColors = userColors;
        ref.unsubscribe();
      });
    }, 50);
  }

  colorPickerMouseEvent(e: MouseEvent) {
    const hasClass = (e.target as any)['classList'].contains('swatch');
    if (hasClass) {
      return;
    }
    e.stopPropagation();
  }

  selectColor(color: color) {
    this.resetSelectedColor();
    color.selected = true;
    this.color = color;
    const opacity = this.opacityValue / 100;
    this.color.rgbaColor = this.color.rgbaColor.replace(
      /\d?\.?\d{1,}\)/g,
      `${opacity})`
    );
    this.color.rgbaHexColor = this.rgba2hex(this.color.rgbaColor);
    if (
      this.tool &&
      this.tool.calculation &&
      this.tool.calculation === TOOLS_CALCULATION.FIBONACCI_RETRACEMENT
    ) {
      this.tool.isMultiColors = false;
      this.setTool(this.color.rgbaHexColor);
    }
    setTimeout(() => {
      this.setOpacityColor(color.hexColor);
      this.selectedColor.emit({ color, typeColorUpdate: this.typeColorUpdate });
    }, 50);
  }

  setOpacityColor(hex: string) {
    const opacitySlider = document.querySelector(
      `#${this.id} #opacitySlider_${this.typeColorUpdate}.opacitySlider`
    ) as HTMLElement;
    const rangePointer = document.querySelector(
      `#${this.id} #rangePointer_${this.typeColorUpdate}.rangePointer`
    ) as HTMLElement;
    if (!opacitySlider || !rangePointer) {
      return;
    }
    opacitySlider.style.color = hex;
    rangePointer.style.backgroundImage = `linear-gradient(90deg, transparent, ${hex})`;
  }

  opacityChange() {
    if (this.color) {
      const opacity = this.opacityValue / 100;
      this.color.rgbaColor = this.color.rgbaColor.replace(
        /\d?\.?\d{1,}\)/g,
        `${opacity})`
      );
      this.color.rgbaHexColor = this.rgba2hex(this.color.rgbaColor);
      this.selectedColor.emit({
        color: this.color,
        typeColorUpdate: this.typeColorUpdate,
      });
    }
  }

  setTool(hex: string) {
    const rgbaColor = this.hex2Rgba(hex);
    const rgbColor = this.rgba2rgb(rgbaColor);
    const hexColor = this.rgba2hex(rgbColor);
    //this.resetSelectedColor();
    if (!this.tool.isMultiColors) {
      const { principal, mono, user } = this.setSelectedColor(hexColor);
      this.opacityValue = parseInt(
        (
          parseFloat(
            getTextByRegex(rgbaColor, /\d*.?\d*\)/g)
              .replace(')', '')
              .trim()
          ) * 100
        ).toFixed(0)
      );
      if (principal) {
        this.color = principal;
      }
      if (mono) {
        this.color = mono;
      }
      if (user) {
        this.color = user;
      }
      if (!this.color) {
        this.color = {
          hexColor: hex,
          rgbaColor,
          rgbColor,
          rgbaHexColor: hex,
          selected: false,
        };
      }
      this.setOpacityColor(this.color.hexColor);
    } else {
      this.resetSelectedColor();
    }
  }

  deleteColor(color: color) {
    const dColor = this.userColors.findIndex(
      (uColor) => uColor.rgbColor.toUpperCase() === color.rgbColor.toUpperCase()
    );
    if (dColor != -1) {
      this.userColors.splice(dColor, 1);
    }
    this._customPreferencesService.userColorDrawTools = JSON.stringify(
      this.userColors
    );
  }

  openSettings(evt: MouseEvent, color: color) {
    evt.stopPropagation();
    this.rtDropnOpened = true;
    this.colorSettingsDict.set(color.rgbColor.toUpperCase(), true);
  }

  mouseLeaveDeleteDict(color: color) {
    const element = document.querySelector('.swatch.opened');
    const elementHover = document.querySelector('.swatch:hover');
    if (element) {
      if (!elementHover) {
        this.colorSettingsDict.delete(color.rgbColor.toUpperCase());
        this.rtDropnOpened = false;
      }
    }
  }

  mouseEnterValidity(evt: MouseEvent) {
    const element = document.querySelector('.swatch.opened') as HTMLElement;
    if (element) {
      const enterElement = evt.target as HTMLElement;
      const openedClass = enterElement.classList.contains('opened');
      if (!openedClass) {
        const color = element.style.color;
        this.colorSettingsDict.delete(color.toUpperCase());
        this.rtDropnOpened = false;
      }
    }
  }

  private makeToolInfo(tool: TIGER_CHART_TOOL) {
    setTimeout(() => {
      if (this.typeColorUpdate === TOOLS_TYPE_DRAW.LINE) {
        if (tool.color) {
          let color = tool.color;
          if (tool.alwaysTransparent) {
            const lineChild = tool.children?.find(
              (child) => !child.isAux && child.typeDraw === TOOLS_TYPE_DRAW.LINE
            );
            if (lineChild) {
              color = lineChild.color!!;
            }
          }
          this.setTool(color);
        }
      }
      if (this.typeColorUpdate === TOOLS_TYPE_DRAW.BACKGROUND) {
        if (tool.backgroundColor) {
          this.setTool(tool.backgroundColor);
        }
      }
      if (this.typeColorUpdate === TOOLS_TYPE_DRAW.CENTER_LINE) {
        if (tool.centerLineColor) {
          this.setTool(tool.centerLineColor);
        }
      }
      if (this.typeColorUpdate === TOOLS_TYPE_DRAW.TEXT) {
        if (tool.textColor) {
          this.setTool(tool.textColor);
        }
      }
      if (this.typeColorUpdate === TOOLS_TYPE_DRAW.OUTLINE) {
        if (tool.outlineColor) {
          this.setTool(tool.outlineColor);
        }
      }
    }, 100);
  }

  private configureColor(hex: string) {
    const rgbaColor = this.hex2Rgba(hex);
    const rgbColor = this.rgba2rgb(rgbaColor);
    const hexColor = this.rgba2hex(rgbColor);
    const { principal, mono, user } = this.setSelectedColor(hexColor);
    this.opacityValue = parseInt(
      (
        parseFloat(
          getTextByRegex(rgbaColor, /\d*.?\d*\)/g)
            .replace(')', '')
            .trim()
        ) * 100
      ).toFixed(0)
    );
    if (principal) {
      this.color = principal;
    }
    if (mono) {
      this.color = mono;
    }
    if (user) {
      this.color = user;
    }
    if (!this.color) {
      this.color = {
        hexColor: hex,
        rgbaColor,
        rgbColor,
        rgbaHexColor: hex,
        selected: false,
      };
    }
    setTimeout(() => {
      this.setOpacityColor(this.color!!.hexColor);
    }, 100);
  }
}
