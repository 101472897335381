import { Dictionary } from '@core/models';
import { COMPONENTS_LIST } from '@core/workspace';
import {
  TIGER_INDICATORS_ENUM,
  TIGER_INTERVAL_ENUM,
  TIGER_TYPE_CHART_ENUM,
} from '@shared/tiger-chart/enum/tiger-chart.enum';
import { NumberRange } from 'scichart';

export const INTERVAL_CHART = [
  {
    id: TIGER_INTERVAL_ENUM.ONE_MINUTE,
    name: '1 minuto',
    short: '1m',
    keyCode: '1',
    limit: 500,
    repositionType: 'unit',
    repositionTimer: 1,
  },
  {
    id: TIGER_INTERVAL_ENUM.TWO_MINUTE,
    name: '2 minutos',
    short: '2m',
    keyCode: '2',
    limit: 500,
    new: false,
    repositionType: 'unit',
    repositionTimer: 2,
  },
  {
    id: TIGER_INTERVAL_ENUM.FIVE_MINUTE,
    name: '5 minutos',
    short: '5m',
    keyCode: '5',
    limit: 500,
    repositionType: 'unit',
    repositionTimer: 5,
  },
  {
    id: TIGER_INTERVAL_ENUM.TEN_MINUTE,
    name: '10 minutos',
    short: '10m',
    keyCode: '10',
    limit: 500,
    repositionType: 'unit',
    repositionTimer: 10,
  },
  {
    id: TIGER_INTERVAL_ENUM.FIFTEEN_MINUTE,
    name: '15 minutos',
    short: '15m',
    keyCode: '15',
    limit: 500,
    repositionType: 'unit',
    repositionTimer: 15,
  },
  {
    id: TIGER_INTERVAL_ENUM.THIRTY_MINUTE,
    name: '30 minutos',
    short: '30m',
    keyCode: '30',
    limit: 500,
    repositionType: 'unit',
    repositionTimer: 30,
  },
  {
    id: TIGER_INTERVAL_ENUM.ONE_HOUR,
    divider: true,
    name: '1 hora',
    short: '1h',
    keyCode: '1H',
    limit: 800,
    repositionType: 'unit',
    repositionTimer: 60,
  },
  {
    id: TIGER_INTERVAL_ENUM.ONE_DAY,
    name: '1 dia',
    short: '1d',
    keyCode: '1D',
    limit: 1000,
    repositionType: 'day',
    repositionTimer: 1440,
  },
  {
    id: TIGER_INTERVAL_ENUM.THIS_WEEK,
    name: '1 semana',
    short: '1S',
    keyCode: '1S',
    limit: 1000,
    repositionType: 'week',
    repositionTimer: 10080,
  },
  {
    id: TIGER_INTERVAL_ENUM.ONE_MONTH,
    name: '1 mês',
    short: '1M',
    keyCode: '1M',
    limit: 1000,
    repositionType: 'month',
    repositionTimer: 43800,
  },
];

export const MAP_INTERVAL_CHART = new Dictionary<any>();
MAP_INTERVAL_CHART.bulkData('id', INTERVAL_CHART);

export const TYPE_CHART = [
  { id: TIGER_TYPE_CHART_ENUM.AREA, name: 'Área', icon: 'area_chart' },
  { id: TIGER_TYPE_CHART_ENUM.BAR, name: 'Barras', icon: 'waterfall_chart' },
  {
    id: TIGER_TYPE_CHART_ENUM.CANDLE,
    name: 'Candles',
    icon: 'candlestick_chart',
  },
  { id: TIGER_TYPE_CHART_ENUM.LINE, name: 'Linha', icon: 'timeline' },
];

export const INDICATORS = [
  {
    id: TIGER_INDICATORS_ENUM.MOVING_AVERAGE,
    name: 'Média Móvel',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.MOVING_AVERAGE_COLOR,
    name: 'Color. de Médias Móveis',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.EXPONENTIAL_MOVING_AVERAGE,
    name: 'Média Móvel Exponencial',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.WEIGHTED_MOVING_AVERAGE,
    name: 'Média Móvel Ponderada',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.BOLLINGER_BANDS,
    name: 'Bandas de Bollinger',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.MEDIAN_PRICE,
    name: 'Mediana',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.RELATIVE_STRENGTH_INDEX,
    name: 'Índice de Força Relativa',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.STOCHASTIC,
    name: 'Estocástico',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.PARABOLIC_SAR,
    name: 'SAR Parabólico',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.AVERAGE_PRICE,
    name: 'Preço Médio',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.CDL_HAMMER,
    name: 'Martelo',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.CDL_INVERTED_HAMMER,
    name: 'Martelo Invertido',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.MOVING_AVERAGE_CONVERGENCE_DIVERGENCE,
    name: 'MACD',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.CDL_DOJI,
    name: 'Doji',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.CDL_ENGULFING,
    name: 'Engulfing Pattern',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.DOUBLE_EXPONENTIAL_MOVING_AVERAGE,
    name: 'Exp. Dupla (DEMA)',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.TRIPLE_EXPONENTIAL_MOVING_AVERAGE,
    name: 'Exp. Tripla (TEMA)',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.KAUFMAN_ADAPTIVE_MOVING_AVERAGE,
    name: 'Adapt. de Kaufman (KAMA)',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.TYPICAL_PRICE,
    name: 'Preço Típico (Typical Price)',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.AVERAGE_TRUE_RANGE,
    name: 'Média de Amplitude de Variação (ATR)',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.PERCENTAGE_PRICE_OSCILLATOR,
    name: 'Oscilador de Preço (PPO)',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.TRIANGULAR_MOVING_AVERAGE,
    name: 'Média Móvel Triangular (TRIMA)',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.MOMENTUM,
    name: 'Momentum (MOM)',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.AVERAGE_DIRECTIONAL_X,
    name: 'Índice Médio Direcional (ADX)',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.CANDLE_COUNTER,
    name: 'Contador de Candle',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.BAR_ELEPHANT,
    name: 'Barra de Elefante (BE)',
    checked: false,
    qtty: 0,
  },
  {
    id: TIGER_INDICATORS_ENUM.WEIS_WAVE,
    name: 'Weis Wave (WW)',
    checked: false,
    qtty: 0,
  },
];

export enum GROUP {
  MOVING_AVERAGES = 'MOVING_AVERAGES',
  TREND = 'TREND',
  OSCILLATORS = 'OSCILLATORS',
  PRICE = 'PRICE',
  OTHER = 'OTHER',
  CANDLE_PATTERNS = 'CANDLE_PATTERNS',
  FAVORITES = 'FAVORITES',
}

export const GROUP_INDICATORS = [
  {
    label: 'Favoritos',
    open: true,
    id: GROUP.FAVORITES,
    indicators: [],
  },
  {
    label: 'Médias Móveis',
    open: true,
    id: GROUP.MOVING_AVERAGES,
    indicators: [
      {
        id: TIGER_INDICATORS_ENUM.MOVING_AVERAGE,
        name: 'Média Móvel (MA)',
        checked: false,
        qtty: 0,
      },
      {
        id: TIGER_INDICATORS_ENUM.MOVING_AVERAGE_COLOR,
        name: 'Color. de Médias Móveis',
        checked: false,
        qtty: 0,
        max: 1,
      },
      {
        id: TIGER_INDICATORS_ENUM.EXPONENTIAL_MOVING_AVERAGE,
        name: 'Exponencial (EMA)',
        checked: false,
        qtty: 0,
      },
      {
        id: TIGER_INDICATORS_ENUM.WEIGHTED_MOVING_AVERAGE,
        name: 'Ponderada (WMA)',
        checked: false,
        qtty: 0,
      },
      {
        id: TIGER_INDICATORS_ENUM.DOUBLE_EXPONENTIAL_MOVING_AVERAGE,
        name: 'Exp. Dupla (DEMA)',
        checked: false,
        qtty: 0,
      },
      {
        id: TIGER_INDICATORS_ENUM.TRIPLE_EXPONENTIAL_MOVING_AVERAGE,
        name: 'Exp. Tripla (TEMA)',
        checked: false,
        qtty: 0,
      },
      {
        id: TIGER_INDICATORS_ENUM.KAUFMAN_ADAPTIVE_MOVING_AVERAGE,
        name: 'Adapt. de Kaufman (KAMA)',
        checked: false,
        qtty: 0,
      },
      {
        id: TIGER_INDICATORS_ENUM.TRIANGULAR_MOVING_AVERAGE,
        name: 'Média Móvel Triangular (TRIMA)',
        checked: false,
        qtty: 0,
      },
    ],
  },
  {
    label: 'Tendências',
    open: true,
    id: GROUP.TREND,
    indicators: [
      {
        id: TIGER_INDICATORS_ENUM.BOLLINGER_BANDS,
        name: 'Bandas de Bollinger (BB)',
        checked: false,
        qtty: 0,
      },
      {
        id: TIGER_INDICATORS_ENUM.PARABOLIC_SAR,
        name: 'SAR Parabólico (SAR)',
        checked: false,
        qtty: 0,
      },
      {
        id: TIGER_INDICATORS_ENUM.MOVING_AVERAGE_CONVERGENCE_DIVERGENCE,
        name: 'MACD',
        checked: false,
        qtty: 0,
      },
      {
        id: TIGER_INDICATORS_ENUM.WEIS_WAVE,
        name: 'Weis Wave (WW)',
        checked: false,
        qtty: 0,
      },
    ],
  },
  {
    label: 'Osciladores',
    open: true,
    id: GROUP.OSCILLATORS,
    indicators: [
      {
        id: TIGER_INDICATORS_ENUM.RELATIVE_STRENGTH_INDEX,
        name: 'Índice de Força Relativa (RSI)',
        checked: false,
        qtty: 0,
      },
      {
        id: TIGER_INDICATORS_ENUM.AVERAGE_TRUE_RANGE,
        name: 'Média de Amplitude de Variação (ATR)',
        checked: false,
        qtty: 0,
      },
      {
        id: TIGER_INDICATORS_ENUM.PERCENTAGE_PRICE_OSCILLATOR,
        name: 'Oscilador de Preço (PPO)',
        checked: false,
        qtty: 0,
      },
      {
        id: TIGER_INDICATORS_ENUM.MOMENTUM,
        name: 'Momentum (MOM)',
        checked: false,
        qtty: 0,
      },
      {
        id: TIGER_INDICATORS_ENUM.AVERAGE_DIRECTIONAL_X,
        name: 'Índice Médio Direcional (ADX)',
        checked: false,
        qtty: 0,
      },
      {
        id: TIGER_INDICATORS_ENUM.STOCHASTIC,
        name: 'Estocástico (STOCH)',
        checked: false,
        qtty: 0,
      },
    ],
  },
  {
    label: 'Padrões de Candle',
    open: true,
    id: GROUP.CANDLE_PATTERNS,
    indicators: [
      {
        id: TIGER_INDICATORS_ENUM.CDL_HAMMER,
        name: 'Martelo (Hammer)',
        checked: false,
        qtty: 0,
        max: 1,
      },
      {
        id: TIGER_INDICATORS_ENUM.CDL_INVERTED_HAMMER,
        name: 'Martelo Invertido (Inverted Hammer)',
        checked: false,
        qtty: 0,
        max: 1,
      },
      {
        id: TIGER_INDICATORS_ENUM.CDL_DOJI,
        name: 'Doji (Doji)',
        checked: false,
        qtty: 0,
        max: 1,
      },
      {
        id: TIGER_INDICATORS_ENUM.BAR_ELEPHANT,
        name: 'Barra de Elefante (BE)',
        checked: false,
        qtty: 0,
        max: 1,
      },
      /*{
        id: TIGER_INDICATORS_ENUM.CDL_ENGULFING,
        name: 'Engulfing Pattern',
        checked: false,
        qtty: 0
      },*/
    ],
  },
  {
    label: 'Preço',
    open: true,
    id: GROUP.PRICE,
    indicators: [
      {
        id: TIGER_INDICATORS_ENUM.AVERAGE_PRICE,
        name: 'Preço Médio (Average Price)',
        checked: false,
        qtty: 0,
      },
      {
        id: TIGER_INDICATORS_ENUM.MEDIAN_PRICE,
        name: 'Mediana (Median Price)',
        checked: false,
        qtty: 0,
      },
      {
        id: TIGER_INDICATORS_ENUM.TYPICAL_PRICE,
        name: 'Preço Típico (Typical Price)',
        checked: false,
        qtty: 0,
      },
    ],
  },
  {
    label: 'Outros',
    open: true,
    id: GROUP.OTHER,
    indicators: [
      {
        id: TIGER_INDICATORS_ENUM.CANDLE_COUNTER,
        name: 'Contador de Candle',
        checked: false,
        qtty: 0,
        max: 1,
      },
    ],
  },
];

export const INIT_DEFAULT_CONFIG_CHART = {
  chart: {
    enableCursorModifier: false,
    fastOrder: {
      showFastOrder: false,
    },
    series: {
      interval: TIGER_INTERVAL_ENUM.ONE_MINUTE,
      type: TIGER_TYPE_CHART_ENUM.CANDLE,
    },
    xAxis: {
      visibleRange: {
        min: 0,
        max: 100,
      },
    },
    yAxis: {
      labelPrecision: 0,
    },
    width: COMPONENTS_LIST.get('FERRAMENTAS')[0].width,
    height: COMPONENTS_LIST.get('FERRAMENTAS')[0].height,
    negotiationLot: undefined,
    chartTradingOffset: 30,
  },
  indicators: {
    value: undefined,
    actives: undefined,
    action: undefined,
  },
  stock: {
    cd_stock: 'WINFUT',
    id_exchange: 1,
  },
};
export const CROSSHAIR_ID = {
  Y: 'crosshair-y',
  X: 'crosshair-x',
};
export const ZOOM_RUBBER_BAND_MODIFIER_ID = 'zoom-rubber-band-modifier';
export const ZOOM_PAN_MODIFIER_ID = 'zoom-pan-modifier';
export const NEW_LINE_ID = 'Tiger-chart-new-line';
export const NEW_ANNOTATION_INDICATOR = 'Tiger-chart-annotation-indicator';
export const NEW_LINE_ALT_SHIFT = 'Tiger-chart-new-line-alt-shift';
export const COVERAGE_PRICE_ID = 'coverage-price';
export const HEADER_NOTIFICATION_CHEETAH = 'NOTIFICATION_AUTH';
export const ORDER_ERROS_STATUS_CODE = [
  'INVALID_ORDER_QTTY',
  'STANDARD_LOT_ERROR',
  'INVALID_ORDER_QTYY',
];
export const UNIVERSAL_SUBCHART_PERCENT_HEIGHT = 25;

export const HEADER_SIZES = {
  DEFAULT: 635,
  SCALE_ONE: 586,
  MEDIUM: 484,
  SMALL: 440,
  MICRO: 390,
  NANO: 418,
};

export const reOrderVisibleRange = (visibleRange: NumberRange): NumberRange => {
  /**
   * Por algum motivo em alguns momentos o scichart inverte o min e max.
   */
  const min =
    visibleRange.min > visibleRange.max ? visibleRange.max : visibleRange.min;
  const max =
    visibleRange.max < visibleRange.min ? visibleRange.min : visibleRange.max;
  return new NumberRange(min, max);
};
