export enum TABLE_BOOK_CONTEXT_MENU_ENUM {
  ORDER,
  OFFER,
  PRICE,
  DETAILS,
  FORCE_CHART,
  HIGHLIGHT_AGRESSOR,
  HIGHLIGHT_QUANTITY,
  HIGHLIGHT_OFFER
}
