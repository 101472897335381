export const weisWaveTemplate = `
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">Identificação da Direção:</div>
<div class="mb-3">
   Determine a direção atual do mercado (alta ou baixa) com base na comparação dos preços de fechamento dos candles.
</div>
<div class="mb-3">Acumulação de Volume:<br /></div>
<div class="mb-3">
  Volume acumulado da onda de alta:
  <img src="/assets/indicators/calculo-weis-wave.png" />
</div>
<div class="mb-3">
  Mudança de Direção:
</div>
<div class="mb-3">
 Quando a direção do preço muda (de alta para baixa ou vice-versa), iniciar uma nova onda e começar a acumular o volume novamente.
</div>
<div class="fw-bolder">Como configurar</div>
<br />
<div class="fw-bolder">Valores</div>
<div class="text-decoration-underline">Período</div>
<div class="mb-3">
  O valor a ser usado no cálculo do Wies Wave. 14 é o padrão.
</div>
<div class="text-decoration-underline">Fonte</div>
<div class="mb-3">
  Determina quais dados de cada barra serão usados nos cálculos. Fechamento é o
  padrão.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/weis-wave-entrada.png"
  />
</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">WW</div>
<div class="mb-3">
  Pode alternar a visibilidade da WW. Também pode selecionar a cor para cada grupo de volume.
</div>
<div class="mb-3">
  <img class="image-indicators-input" src="/assets/indicators/weis-wave-estilo.png" />
</div>
`;
