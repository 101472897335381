import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TTokenPreferenceAuthenticator } from '@core/layout/header/modal-token-config/types';
import { RestService } from '@shared/services/rest/rest.service';
import { Observable, catchError, map, of } from 'rxjs';
import { formatDate, handleDocumentMask } from 'src/app/utils/utils.functions';

@Injectable({
  providedIn: 'root',
})
export class UserService extends RestService {
  override _url: string = 'api/trademap';

  public static readonly defaultUserPicturePath: string =
    'assets/user-profile.png';

  constructor(http: HttpClient) {
    super(http);
  }

  getInfos = () =>
    this.get<any>('v1/user-details/info').pipe(
      map(
        (res: any) =>
          new UserDetails(
            res.data.uuid,
            res.data.userId,
            res.data.externalId,
            res.data.name,
            res.data.nickname,
            res.data.biography,
            res.data.picture,
            res.data.verified,
            res.data.roleName,
            res.data.canCreateExclusive,
            res.data.amISubscribed,
            res.data.defaulter
          )
      )
    );

  getInvestorData = () =>
    this.get('v1/portal/get-investor-data').pipe(
      map((res: any) => {
        const { data } = res;
        const investor = data.result[0];
        return new InvestorData(
          investor.cnpj_cpf,
          investor.document_type,
          investor.dt_birthday,
          investor.email,
          investor.name,
          investor.phone_mobile,
          investor.username
        ).getInvestorData();
      })
    );

  updateFieldByToken = (
    param: IUpdateInfoParams
  ): Observable<IServiceResponse> => {
    return this.post('v1/portal/do-update-by-token', { ...param }).pipe(
      map((res: any) => {
        if (!res.data || !res.data.success)
          return this._handleServiceError(res);
        return { isError: false, message: '' };
      }),
      catchError((error) => of(this._handleServiceError(error)))
    );
  };

  updateInfoRequest = (params: {
    field: string;
    value: string;
    password: string;
  }): Observable<IServiceResponse> => {
    return this.post('v1/portal/update-info-request', { ...params }).pipe(
      map((res: any) => {
        if (!res.data || !res.data.success) throw res;
        return res.data.result;
      })
    );
  };

  checkPhoneUpdatePinCode = (
    param: ICheckPincode
  ): Observable<IServiceResponse> => {
    return this.post('v1/portal/check-request-pincode', { ...param }).pipe(
      map((res: any) => {
        if (!res.data || !res.data.success)
          return this._handleServiceError(res);
        return { isError: false, message: res.data.result.next_interaction };
      }),
      catchError((error) => of(this._handleServiceError(error)))
    );
  };

  private _handleServiceError(res: any): IServiceResponse {
    let message = res.error ?? res.message;
    const code = res.errorCode ?? 'ERROR';
    message.includes('No eligible members for the call') &&
      (message = 'Houve um erro inesperado');
    return { isError: true, message, code };
  }
}

export interface UserDetailsInterface {
  uuid: string;
  userId: number;
  externalId: string;
  name: string;
  nickname: string;
  biography: string;
  picture: string;
  verified: boolean;
  roleName: string;
  canCreateExclusive: boolean;
  amISubscribed: boolean;
  defaulter: boolean;
}

export class UserDetails implements UserDetailsInterface {
  constructor(
    public uuid: string = '',
    public userId: number = 0,
    public externalId: string = '',
    public name: string = '',
    public nickname: string = '',
    public biography: string = '',
    public picture: string = UserService.defaultUserPicturePath,
    public verified: boolean = false,
    public roleName: string = '',
    public canCreateExclusive: boolean = false,
    public amISubscribed: boolean = false,
    public defaulter: boolean = false
  ) {}
}

export interface InvestorDataInterface {
  cnpj_cpf: string;
  document_type: string;
  dt_birthday: number | string;
  email: string;
  name: string;
  phone_mobile: string;
  username: string;
  password?: string;
  raw_phone_mobile?: string;
}

export interface IUpdateInfoParams {
  field: string;
  value: string;
  password: string;
  token: string;
  cdOrigin: TTokenPreferenceAuthenticator;
}

export interface IServiceResponse {
  isError: boolean;
  message: string;
  result?: any;
  code?: string;
}

export interface ICheckPincode {
  field: string;
  pincode: string;
  value: string;
}

export class InvestorData implements InvestorDataInterface {
  constructor(
    public cnpj_cpf: string = '',
    public document_type: 'CPF' | 'CNPJ' = 'CPF',
    public dt_birthday: string = '',
    public email: string = '',
    public name: string = '',
    public phone_mobile: string = '',
    public username: string = ''
  ) {}

  getInvestorData(): InvestorDataInterface {
    return {
      cnpj_cpf: handleDocumentMask(this.cnpj_cpf, this.document_type),
      dt_birthday: formatDate(String(this.dt_birthday)),
      raw_phone_mobile: this.phone_mobile,
      phone_mobile: this.phone_mobile,
      document_type: this.document_type,
      email: this.email,
      name: this.name,
      username: this.username,
      password: '********',
    };
  }
}
