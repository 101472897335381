import { Directive, ElementRef, Input } from '@angular/core';
import { FlaTSize } from '@shared/rocket-components/components/icon/types/icon.types';

@Directive({
  selector: '[inputPassword]',
})
export class InputPasswordDirective {
  @Input() iconSize: FlaTSize = 'icon-size-micro';
  private _shown = false;

  get input() {
    return this.el.nativeElement.querySelector('input');
  }
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.setup();
  }

  private getBtn(icon: string) {
    return `<i class="icons ${this.iconSize}">${icon} </i> `;
  }

  toggle(span: HTMLElement) {
    this._shown = !this._shown;
    const type = this._shown ? 'text' : 'password';
    this.input.setAttribute('type', type);
    span.innerHTML = this.getBtn(this._shown ? 'visibility_off' : 'visibility');
  }

  setup() {
    const parent = this.input.parentNode;
    const span = document.createElement('span');
    span.classList.add('icon-input-password');
    span.innerHTML = this.getBtn('visibility');
    span.addEventListener('click', () => this.toggle(span));
    parent.appendChild(span);
  }
}
