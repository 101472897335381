import { ModuleWithProviders, NgModule } from '@angular/core';
import { InputCodeComponent } from './input-code.component';
import { CommonModule } from '@angular/common';
import {
  InputCodeComponentConfig,
  InputCodeComponentConfigToken,
} from './input-code.component.config';

@NgModule({
  imports: [CommonModule],
  declarations: [InputCodeComponent],
  exports: [InputCodeComponent],
})
export class InputCodeModule {
  static forRoot(
    config: InputCodeComponentConfig
  ): ModuleWithProviders<InputCodeModule> {
    return {
      ngModule: InputCodeModule,
      providers: [{ provide: InputCodeComponentConfigToken, useValue: config }],
    };
  }
}
