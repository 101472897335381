export const relativeStrengthIndexTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  O Índice de Força Relativa (RSI, do inglês Relative Strength Index) é um
  indicador de análise técnica usado para medir a força e a velocidade das
  mudanças de preço de um ativo financeiro. Ele compara o número de dias em que
  o preço do ativo subiu com o número de dias em que ele caiu, e gera um valor
  entre 0 e 100 que indica se o ativo está em sobrecompra ou sobrevenda.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">
  O RSI é calculado com base em uma comparação entre as médias das mudanças
  positivas e negativas dos preços de fechamento de um ativo financeiro em um
  determinado período de tempo. A fórmula do RSI é dada por:
</div>
<div class="mb-3">RSI = 100 - [100 / (1 + RS)]</div>
<div class="mb-3">Onde:<br /></div>
<div class="mb-3">
  RS (Relative Strength) é a média das mudanças positivas dividida pela média
  das mudanças negativas em um determinado período de tempo. A fórmula do RS é
  dada por:
</div>
<div class="mb-3">
  RS = Média das mudanças positivas / Média das mudanças negativas
</div>
<div class="mb-3">
  As mudanças positivas são as diferenças entre os preços de fechamento atuais e
  anteriores que são maiores que zero. As mudanças negativas são as diferenças
  entre os preços de fechamento atuais e anteriores que são menores que zero.
</div>
<div class="mb-3">
  O RSI é um indicador oscilante que varia entre 0 e 100. Valores acima de 70
  indicam que o ativo financeiro está sobrecomprado, ou seja, os preços estão
  subindo rapidamente e podem estar perto do topo. Valores abaixo de 30 indicam
  que o ativo financeiro está sobrevendido, ou seja, os preços estão caindo
  rapidamente e podem estar perto do fundo.
</div>
<div class="mb-3">
  O RSI é usado para identificar pontos de reversão de tendência e gerar sinais
  de compra e venda. Quando o RSI cruza acima de 30, isso é visto como um sinal
  de compra, enquanto um cruzamento abaixo de 70 é visto como um sinal de venda.
  No entanto, é importante usar o RSI em conjunto com outros indicadores e
  análises para confirmar os sinais de compra e venda e evitar falsos sinais.
</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  O Índice de Força Relativa (RSI) é definido como um oscilador bastante
  utilizado na Análise Técnica. Com ele, é possível medir o termômetro do
  cansaço do mercado, checando se ele precisa tomar fôlego antes de continuar na
  mesma tendência.
</div>
<div class="mb-3">
  O RSI é um dos indicadores mais populares na hora de vender ou comprar ações.
  Ele é exibido como um oscilador e pode ter uma leitura de 0 a 100. Sendo
  baseado nos preços de fechamento.
</div>
<div class="mb-3">
  <img class="w-75" src="/assets/indicators/rsi-como-funciona.png" />
</div>
<div class="fw-bolder">Altas e baixas</div>
<br />
<div class="mb-3">
  A interpretação desse indicador se baseia nos valores de seus extremos, sendo
  acima de 70 indicam que um ativo está se tornando supervalorizado e pode ser
  preparado para uma reversão de tendência.
</div>
<div class="mb-3">
  Por outro lado, uma leitura abaixo de 30 indica uma condição de
  subvalorização.
</div>
<div class="mb-3">
  Um RSI entre 30 e 70 deveria ser considerado neutro e um RSI em torno de 50
  significava “sem tendência”.
</div>
<div class="mb-3">
  Alguns traders acreditam que as faixas de sobrecompra/sobrevenda do indicador
  são muito amplas e optam por alterar essas faixas. Por exemplo, alguém pode
  considerar qualquer número acima de 80 como sobrecomprado e qualquer número
  abaixo de 20 como sobrevendido. Isso fica inteiramente a critério do
  investidor.
</div>
<div class="fw-bolder">Divergência no RSI</div>
<br />
<div class="mb-3">
  A divergência pode ocorrer quando os preços dos ativos estão registrando nova
  máxima, mas o mesmo não ocorre no RSI. Especificamente, existem dois tipos de
  divergências, de baixa e de alta.
</div>
<div class="mb-3">
  &bull; Divergência de alta do RSI - quando o preço atinge uma nova baixa, mas
  o RSI faz uma baixa mais alta.<br />
  &bull; Divergência de baixa do RSI - Quando o preço atinge uma nova máxima,
  mas o RSI atinge uma máxima menor.
</div>
<div class="mb-3">
  O indicador indica que a divergência de baixa cria uma oportunidade de venda,
  enquanto a divergência de alta cria uma oportunidade de compra. A Divergência
  deve ser usada como uma forma de confirmar tendências e não necessariamente
  antecipar reversões.
</div>
<div class="fw-bolder">Como configurar</div>
<div class="fw-bolder">Valores</div>
<div class="text-decoration-underline">Período</div>
<div class="mb-3">
  O período a ser usado no cálculo do RSI. 14 dias é o padrão.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/rsi-entrada.png"
  />
</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">Linha</div>
<div class="mb-3">
  Pode alternar a visibilidade do RSI, bem como a visibilidade de uma linha de
  preço mostrando o preço atual real do RSI. Também pode selecionar a cor do
  RSI, a espessura da linha e o estilo da linha.
</div>
<div class="text-decoration-underline">Limite Superior</div>
<div class="mb-3">
  Pode alternar a visibilidade do Limite Superior, bem como definir o limite, na
  escala de 1 a 100, para a Linha Superior (70 é o padrão). A cor, a espessura
  da linha e o estilo da linha também podem ser determinadas.
</div>
<div class="text-decoration-underline">Limite Inferior</div>
<div class="mb-3">
  Pode alternar a visibilidade da Linha Inferior, bem como definir o limite, na
  escala de 1 a 100, para a Limite Inferior (30 é o padrão). A cor, a espessura
  da linha e o estilo da linha também podem ser determinadas.
</div>
<div class="text-decoration-underline">Background</div>
<div class="mb-3">
  Alterna a visibilidade de uma cor de fundo dentro dos limites do RSI. Também
  pode alterar a cor em si, bem como a opacidade.
</div>
<div class="text-decoration-underline">Precisão</div>
<div class="mb-3">
  Define o número de casas decimais a serem deixadas no valor do indicador
  antes do arredondamento. Quanto maior esse número, mais casas decimais haverá
  no valor do indicador.
</div>
<div class="mb-3">
  <img class="image-indicators-input" src="/assets/indicators/rsi-estilo.png" />
</div>
`;
