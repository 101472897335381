<div
  class="bg-neutral-strong d-flex flex-column h-100 p-2 round w-100 position-relative"
>
  <app-remove-stock-button
    [displayRemoveStock]="displayRemoveStock"
    (removeStock)="removeStock.emit()"
  ></app-remove-stock-button>
  <div class="pointer w-100 h-100 vstack" (click)="stockSelected.emit()">
    <div class="align-items-center hstack justify-content-between w-100">
      <div
        class="align-items-center gap-2 hstack justify-content-center header-height"
      >
        <div class="d-block mb-1">
          <app-status-stock
            [status]="situation"
            [isAuction]="auctionInfos !== undefined"
            [hideSituationTooltip]="false"
          >
          </app-status-stock>
        </div>
        <span
          class="fs-6 fw-bold"
          [ngClass]="{ 'text-brand-primary': isGlobalStocks }"
          [tooltip]="companyName"
        >
          {{ cdStock }}
        </span>
        <span
          class="fs-6 text-truncate price-max-width"
          [tooltip]="price"
          [tooltipHidden]="disablePriceTooltip"
        >
          {{ price }}
        </span>
        <div class="bg-neutral-smoothest vr custom-vr"></div>
        <span
          class="fs-6 fw-bold"
          [style.color]="variationBgColor"
          [appAuctionTooltip]="auctionInfos"
          [situation]="situation"
        >
          {{ variation }}%
        </span>
      </div>

      <ng-container *ngIf="enableSendOrder">
        <app-input-count
          tooltip="Quantidade a ser negociada"
          margin="m-0"
          mask="separator.0"
          class="candle-view-order-quantity-input"
          [isSmall]="true"
          [clearable]="false"
          [formControl]="orderQtty"
          [incrementValue]="defaultLot"
          [min]="defaultLot"
          [initialValue]="lotValue"
          [alwaysShowIncrementButton]="false"
          [alwaysShowDecrementButton]="false"
          [enableScrollEvent]="false"
          [selectOnFocus]="true"
          [isQttyInput]="true"
          [css]="customInitialLot ? 'color-modify-qtty' : ''"
          [isStockTableUse]="true"
          [stock]="cdStock"
          [globalStockSelected]="stockToEnableArrowEvent"
          (isFocused)="blockKeyboardEvent.emit($event)"
        ></app-input-count>
      </ng-container>
    </div>

    <ng-container *ngIf="enableSendOrder; else onlyCandleHorizontal">
      <div
        class="w-100 hstack align-items-center justify-content-between gap-1"
      >
        <button
          type="button"
          class="btn btn-primary btn-sm order-button bg-multibroker-buy"
          (click)="sendOrder(SIDE_ORDER.BUY)"
        >
          CM
        </button>
        <div class="candle-width">
          <app-candle-horizontal
            [valueOpen]="vlOpen"
            [valueClose]="vlClose"
            [valueMin]="vlMin"
            [valueMax]="vlMax"
            [arrowColor]="variationBgColor"
            [avgPrice]="avgPrice"
            [hideArrow]="hideCandleArrow"
            [tickSize]="tickSize"
            [containerHeight]="containerHeight"
            [dsAsset]="dsAsset"
            [price]="price"
          ></app-candle-horizontal>
        </div>
        <button
          type="button"
          class="btn btn-primary btn-sm order-button bg-multibroker-sell"
          (click)="sendOrder(SIDE_ORDER.SELL)"
        >
          VM
        </button>
      </div>
    </ng-container>

    <ng-template #onlyCandleHorizontal>
      <app-candle-horizontal
        [valueOpen]="vlOpen"
        [valueClose]="vlClose"
        [valueMin]="vlMin"
        [valueMax]="vlMax"
        [arrowColor]="variationBgColor"
        [avgPrice]="avgPrice"
        [hideArrow]="hideCandleArrow"
        [tickSize]="tickSize"
        [containerHeight]="containerHeight"
      ></app-candle-horizontal>
    </ng-template>
  </div>
</div>
