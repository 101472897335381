<div
  *ngIf="field.controlType"
  [formGroup]="form"
  [ngSwitch]="field.controlType"
  [ngClass]="field.classLine"
  class="position-relative"
  [id]="elementID"
>
  <fla-input
    *ngSwitchCase="'inputText'"
    [formControlName]="field.key"
    [isSmall]="field.isSmall"
    [isRequired]="field.required"
    [label]="field.label"
    [flaMask]="field.flaMask"
    [selectOnFocus]="field.selectOnFocus"
    [type]="field.type"
    [css]="classComponent"
    [tooltip]="field.tooltip"
    [tooltipDelay]="field.tooltipDelay || 0"
    [labelPlacement]="field.labelPlacement || 'vertical'"
  >
  </fla-input>

  <app-input-count
    *ngSwitchCase="'inputCount'"
    [formControlName]="field.key"
    [isSmall]="field.isSmall"
    [isRequired]="field.required"
    [label]="field.label"
    [selectOnFocus]="field.selectOnFocus"
    [allowNegativeValue]="field.allowNegativeValue"
    [incrementValue]="field.incrementValue"
    [min]="field.incrementValue"
    [css]="classComponent"
    [tooltip]="field.tooltip"
    [tooltipDelay]="field.tooltipDelay || 0"
    [initialValue]="field.initialValue || 0"
    [labelPlacement]="field.labelPlacement || 'vertical'"
    [isModal]="field.isModal || false"
    [mask]="field.flaMask || defaultMax"
    [enableScrollEvent]="field.scrollEvent"
    [isQttyInput]="field.isQttyInput"
  >
  </app-input-count>

  <fla-select
    *ngSwitchCase="'select'"
    class="w-100"
    [formControlName]="field.key"
    [list]="field.options"
    [label]="field.label"
    [isSmall]="field.isSmall"
    [bindLabel]="field.bindLabel"
    [bindValue]="field.bindValue"
    [css]="classComponent"
    [tooltip]="field.tooltip"
    [tooltipDelay]="field.tooltipDelay || 0"
    [labelPlacement]="field.labelPlacement || 'vertical'"
  ></fla-select>

  <div *ngSwitchCase="'freeContent'">
    <ng-content [select]="field.key"></ng-content>
  </div>

  <span
    *ngIf="!field.typeShowError && error"
    class="ng-invalid invalid-feedback d-block"
    [style.top.px]="68"
    >{{ error }}</span
  >

  <span
    *ngIf="field.typeShowError === 'icon' && error"
    class="d-block invalid-feedback ng-invalid position-absolute right-0 cursor-pointer"
    [style.top.px]="26"
    [tooltip]="error"
  >
    <fla-icon name="info" size="icon-size-micro"></fla-icon>
  </span>
</div>
