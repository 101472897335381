import { Injectable } from '@angular/core';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { GlobalStockPreferencesInterface } from './global-stock-preferences.interface';
import { HttpClient } from '@angular/common/http';
import { GLOBAL_STOCK_SELECTED } from '../../subscription/const/events-name';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';

export const PETR4OBJ = {
  id_stock: 9363,
  cd_stock: 'PETR4',
  synonymous_nickname: '',
  ds_asset: 'PETR',
  nm_company_exchange: 'PETROBRAS',
  nm_company_valemobi: 'PETROBRAS',
  cd_segment: '01',
  tick_size_denominator: 2,
  tick_size_quantity: 0,
  standard_lot: 100,
  cd_status_stock: 'A',
  cd_security_type: 'VIS',
  nm_instrument: 'PETROBRAS PN',
  is_synonymous: false,
  id_exchange: 1,
  cd_stock_order: 'PETR4',
  ds_type: 'PN',
  type: 'VIS',
  type_nearest: 'VIS',
};
export const WINFUTOBJ = {
  cd_stock: 'WINFUT',
  synonymous_nickname: 'WINFUT',
  ds_asset: 'WIN',
  nm_company_exchange: 'IBOVESPA MINI',
  nm_company_valemobi: 'IBOVESPA MINI',
  cd_segment: '9999',
  tick_size_denominator: 0,
  tick_size_quantity: 0,
  standard_lot: 1,
  cd_status_stock: 'A',
  cd_security_type: 'FUT',
  is_synonymous: true,
  id_exchange: 1,
  ds_type: 'BMF',
  type: 'BMF',
  type_nearest: 'BMF',
};

@Injectable({
  providedIn: 'root',
})
export class GlobalStockPreferences
  extends CustomPreferencesService
  implements GlobalStockPreferencesInterface
{
  constructor(http: HttpClient) {
    super(http);
  }

  get globalStockSelected() {
    const stock = JSON.parse(this.storage.getItem(GLOBAL_STOCK_SELECTED));
    const stockSelected = !isNullOrUndefined(stock) ? stock : PETR4OBJ;
    return stockSelected;
  }

  set globalStockSelected(e: any) {
    this.setValueCustomPreferences(GLOBAL_STOCK_SELECTED, JSON.stringify(e));
  }
}
