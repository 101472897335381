import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  concatMap,
  filter,
  from,
  Observable,
  of,
  Subject,
  switchMap,
} from 'rxjs';

export interface IBrokerLogo {
  [key: string]: SafeResourceUrl;
}
@Injectable({
  providedIn: 'root',
})
export class TrademapCDNService {
  private _url = 'https://cdn.trademap.com.br/multibroker_logos/';
  private _logoDict: IBrokerLogo = {};
  private _downloadedLogosSubject = new Subject<IBrokerLogo>();

  get onDownloadedLogos() {
    return this._downloadedLogosSubject.asObservable();
  }

  private _http = inject(HttpClient);
  private _sanitizer = inject(DomSanitizer);

  public getBrokerLogo(logo: string): Observable<SafeResourceUrl | null> {
    if (this._logoDict[logo])
      return of(this._logoDict[logo]) as Observable<SafeResourceUrl>;
    return this._http
      .get(`${this._url}${logo}.svg`, { responseType: 'blob' })
      .pipe(
        switchMap((response) => {
          return new Observable<SafeResourceUrl | null>((observer) => {
            const reader = new FileReader();
            reader.readAsDataURL(response);
            reader.onloadend = () => {
              const base64data = reader.result as string | null;
              if (!base64data) {
                observer.next(null);
                observer.complete();
                return;
              }
              const svgString = base64data.replace(
                'data:image/svg;',
                'data:image/svg+xml;'
              );
              const sanitizedSvg =
                this._sanitizer.bypassSecurityTrustResourceUrl(svgString);
              this._logoDict[logo] = sanitizedSvg;
              observer.next(sanitizedSvg as string);
              observer.complete();
            };
          });
        })
      );
  }

  public downloadManyBrokersLogo(brokers: string[]) {
    from(brokers)
      .pipe(
        filter((broker) => !this._logoDict[broker]),
        concatMap((broker) => this.getBrokerLogo(broker))
      )
      .subscribe({
        complete: () => this._sendDownloadedLogo(),
      });
  }

  private _sendDownloadedLogo() {
    this._downloadedLogosSubject.next(this._logoDict);
  }
}
