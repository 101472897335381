import { FormGroup } from '@angular/forms';
import { DynamicControlService } from '@shared/rocket-components/dynamic-form/dynamic-control.service';
import { FieldsBase } from '@shared/rocket-components/dynamic-form/fields-base';
import { ORDER_TYPES } from '../constants/boleta.constants';
import { TYPE_ORDE_ENUM } from '../enum/stock-trade.enum';
import { deepClone } from '@shared/rocket-components/utils/functions';

export class SaleOpenForm {
  private orderTypes!: any[];
  private orderTypesInit!: number;

  constructor(
    private dynamicControlService: DynamicControlService,
    private standardLot: number,
    private incrementValue: number,
    private vlorderSide: any
  ) {
    this.orderTypes = deepClone(ORDER_TYPES).splice(0, 5);
    this.orderTypesInit = ORDER_TYPES[3].id;
  }

  private fields: any = (tempFormValues?: any) => [
    {
      controlType: 'freeContent',
      key: 'search',
      classLine: 'col-4 pr-2',
      tooltip: `<span class="fs-6">Ativo</span><br><span class="fs-7 line-heigth">Busque pelo código do ativo que deseja negociar.</span>`,
      tooltipDelay: 1000,
    },
    {
      controlType: 'select',
      key: 'typeOdrer',
      label: 'Tipo:',
      value: `${this.orderTypesInit}`,
      isSmall: true,
      bindLabel: 'desc',
      bindValue: 'id',
      options: this.orderTypes,
      classLine: 'col-5 pr-2',
      classComponent: 'margin-bottom',
      tooltip: `<span class="fs-6">Tipo da Ordem</span><br><span class="fs-7 line-heigth">Tipo da ordem a ser enviada.</span>`,
      tooltipDelay: 1000,
    },
    {
      controlType: 'freeContent',
      key: 'qtde',
      label: 'Qtde:',
      value: tempFormValues?.qtde || this.standardLot,
      initialValue: tempFormValues?.qtde || this.standardLot,
      isSmall: true,
      selectOnFocus: true,
      flaMask: 'separator.0',
      allowNegativeValue: false,
      incrementValue: this.incrementValue,
      classLine: 'col-4 pr-2',
      classComponent: 'margin-bottom',
      listErrors: ['qtdeRequired'],
      typeShowError: 'icon',
      tooltip: `<span class="fs-6">Quantidade</span><br><span class="fs-7 line-heigth">Número de ativos ou contratos que deseja negociar.</span>`,
      tooltipDelay: 1000,
      isQttyInput: true,
      tabIndex: 0,
    },
    {
      key: 'orderSide',
      value: this.vlorderSide,
    },
    {
      key: 'orderType',
      value: TYPE_ORDE_ENUM.SALE_OF_OPEN,
    },
  ];

  public createFields(tempFormValues?: any): any[] {
    return this.fields(tempFormValues);
  }

  public createForm(tempFormValues?: any): FormGroup {
    return this.dynamicControlService.toFormGroup(
      this.fields(tempFormValues) as FieldsBase<string>[]
    );
  }
}
