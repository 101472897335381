import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { config, IConfig } from './config';
import { formatNumber } from '@angular/common';
import { maskBRLCurrency, realToDolar } from 'src/app/utils/utils.functions';

@Injectable()
export class MaskApplierService {
  public dropSpecialCharacters: IConfig['dropSpecialCharacters'];

  public hiddenInput: IConfig['hiddenInput'];

  public showTemplate!: IConfig['showTemplate'];

  public clearIfNotMatch!: IConfig['clearIfNotMatch'];

  public maskExpression: string = '';

  public actualValue: string = '';

  public shownMaskExpression: string = '';

  public maskSpecialCharacters!: IConfig['specialCharacters'];

  public maskAvailablePatterns!: IConfig['patterns'];

  public prefix!: IConfig['prefix'];

  public suffix!: IConfig['suffix'];

  public thousandSeparator!: IConfig['thousandSeparator'];

  public decimalMarker!: IConfig['decimalMarker'];

  public customPattern!: IConfig['patterns'];

  public ipError?: boolean;

  public cpfCnpjError?: boolean;

  public showMaskTyped!: IConfig['showMaskTyped'];
  public selectOnFocus!: IConfig['selectOnFocus'];

  public placeHolderCharacter!: IConfig['placeHolderCharacter'];

  public validation: IConfig['validation'];

  public separatorLimit: IConfig['separatorLimit'];

  public allowNegativeNumbers: IConfig['allowNegativeNumbers'];

  public leadZeroDateTime: IConfig['leadZeroDateTime'];

  public isCustomMask: boolean = false;

  private _shift!: Set<number>;

  public constructor(
    @Inject(config) protected _config: IConfig,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this._shift = new Set();
    this.clearIfNotMatch = this._config.clearIfNotMatch;
    this.dropSpecialCharacters = this._config.dropSpecialCharacters;
    this.maskSpecialCharacters = this._config.specialCharacters;
    this.maskAvailablePatterns = this._config.patterns;
    this.prefix = this._config.prefix;
    this.suffix = this._config.suffix;
    this.thousandSeparator = this._config.thousandSeparator;
    this.decimalMarker = this._config.decimalMarker;
    this.hiddenInput = this._config.hiddenInput;
    this.showMaskTyped = this._config.showMaskTyped;
    this.placeHolderCharacter = this._config.placeHolderCharacter;
    this.validation = this._config.validation;
    this.selectOnFocus = this._config.selectOnFocus;
    this.separatorLimit = this._config.separatorLimit;
    this.allowNegativeNumbers = this._config.allowNegativeNumbers;
    this.leadZeroDateTime = this._config.leadZeroDateTime;
  }

  public applyMaskWithPattern(
    inputValue: string,
    maskAndPattern: [string, IConfig['patterns']]
  ): string {
    const [mask, customPattern] = maskAndPattern;
    this.customPattern = customPattern;
    return this.applyMask(inputValue, mask);
  }

  public applyMask(
    inputValue: string | object | boolean | null | undefined,
    maskExpression: string,
    position: number = 0,
    justPasted: boolean = false,
    backspaced: boolean = false,
    // eslint-disable-next-line
    cb: Function = () => {}
  ): string {
    this.isCustomMask = false;
    if (!maskExpression || typeof inputValue !== 'string') {
      return '';
    }
    let cursor = 0;
    let result = '';
    let multi = false;
    let backspaceShift = false;
    let shift = 1;
    let stepBack = false;
    if (inputValue.slice(0, this.prefix.length) === this.prefix) {
      // eslint-disable-next-line no-param-reassign
      inputValue = inputValue.slice(this.prefix.length, inputValue.length);
    }
    if (!!this.suffix && inputValue?.length > 0) {
      // eslint-disable-next-line no-param-reassign
      inputValue = this.checkAndRemoveSuffix(inputValue);
    }
    const inputArray: string[] = inputValue.toString().split('');
    if (maskExpression === 'IP') {
      const valuesIP = inputValue.split('.');
      this.ipError = this._validIP(valuesIP);
      // eslint-disable-next-line no-param-reassign
      maskExpression = '099.099.099.099';
    }
    const arr: string[] = [];
    for (let i = 0; i < inputValue.length; i++) {
      if (inputValue[i]?.match('\\d')) {
        arr.push(inputValue[i]!);
      }
    }
    if (maskExpression === 'CPF_CNPJ') {
      this.cpfCnpjError = arr.length !== 11 && arr.length !== 14;
      if (arr.length > 11) {
        // eslint-disable-next-line no-param-reassign
        maskExpression = '00.000.000/0000-00';
      } else {
        // eslint-disable-next-line no-param-reassign
        maskExpression = '000.000.000-00';
      }
    }
    if (maskExpression.startsWith('percent')) {
      if (
        inputValue.match('[a-z]|[A-Z]') ||
        // eslint-disable-next-line no-useless-escape
        inputValue.match(/[-!$%^&*()_+|~=`{}\[\]:";'<>?,\/.]/)
      ) {
        // eslint-disable-next-line no-param-reassign
        inputValue = this._stripToDecimal(inputValue);
        const precision: number = this.getPrecision(maskExpression);
        // eslint-disable-next-line no-param-reassign
        inputValue = this.checkInputPrecision(
          inputValue,
          precision,
          this.decimalMarker
        );
      }
      if (
        inputValue.indexOf('.') > 0 &&
        !this.percentage(inputValue.substring(0, inputValue.indexOf('.')))
      ) {
        const base: string = inputValue.substring(
          0,
          inputValue.indexOf('.') - 1
        );
        // eslint-disable-next-line no-param-reassign
        inputValue = `${base}${inputValue.substring(
          inputValue.indexOf('.'),
          inputValue.length
        )}`;
      }
      if (this.percentage(inputValue)) {
        result = inputValue;
      } else {
        result = inputValue.substring(0, inputValue.length - 1);
      }
    } else if (maskExpression.startsWith('currency')) {
      const mask = maskExpression.split('_')[1];
      return formatNumber(
        parseFloat(realToDolar(inputValue)),
        this.locale,
        `${mask}`
      );
    } else if (maskExpression.startsWith('separator')) {
      const precision: number = this.getPrecision(maskExpression);
      const values = inputValue.replace(/[^\d.,]/g, '').split(',');

      if (precision === 0) {
        inputValue = values[0];
      } else {
        if (
          inputValue.match('[wа-яА-Я]') ||
          inputValue.match('[ЁёА-я]') ||
          inputValue.match('[a-z]|[A-Z]') ||
          // eslint-disable-next-line no-useless-escape
          inputValue.match(/[-@#!$%\\^&*()_£¬'+|~=`{}\[\]:";<>.?\/]/) ||
          inputValue.match('[^A-Za-z0-9,]')
        ) {
          // eslint-disable-next-line no-param-reassign
          inputValue = this._stripToDecimal(inputValue);
        }

        if (!inputValue) {
          return '0,00';
        }

        if (values.length === 1) {
          inputValue = `0,${inputValue.padStart(precision, '0')}`;
          return inputValue;
        } else {
          if (values[1].length < precision) {
            if (backspaced) {
              const rest = values[0].at(values[0].length - 1);
              values[1] = rest + values[1];
              values[0] = values[0].substring(0, values[0].length - 1);
              if (!values[0].length) {
                values[0] = '0';
              }

              inputValue = values.join(',');
            } else {
              inputValue = `${values[0]},${values[1].padEnd(precision, '0')}`;
            }
          } else {
            values[1].padEnd(precision, '0');
            if (values[1].length > precision) {
              const rest = values[1].at(0);
              values[0] += rest;
              values[1] = values[1].substring(1);
              if (values[0].startsWith('0') && values[0].length > 1)
                values[0] = values[0].substring(1);
            }
            inputValue = values.join(',');
          }
        }
      }

      const thousandSeparatorCharEscaped: string = this._charToRegExpExpression(
        this.thousandSeparator
      );
      const strForSep: string = inputValue.replace(
        new RegExp(thousandSeparatorCharEscaped, 'g'),
        ''
      );
      result = this._formatWithSeparators(
        strForSep,
        this.thousandSeparator,
        this.decimalMarker,
        precision
      );
      const commaShift: number = result.indexOf(',') - inputValue.indexOf(',');
      const shiftStep: number = result.length - inputValue.length;

      if (shiftStep > 0 && result[position] !== ',') {
        backspaceShift = true;
        let _shift = 0;
        do {
          this._shift.add(position + _shift);
          _shift++;
        } while (_shift < shiftStep);
      } else if (
        (commaShift !== 0 &&
          position > 0 &&
          !(result.indexOf(',') >= position && position > 3)) ||
        (!(result.indexOf('.') >= position && position > 3) && shiftStep <= 0)
      ) {
        this._shift.clear();
        backspaceShift = true;
        shift = shiftStep;
        // eslint-disable-next-line no-param-reassign
        position += shiftStep;
        this._shift.add(position);
      } else {
        this._shift.clear();
      }
    } else if (maskExpression.startsWith('custom_currency')) {
      this.isCustomMask = true;
      return maskBRLCurrency(inputValue);
    } else if (maskExpression.startsWith('custom_percent_symbol')) {
      this.isCustomMask = true;
      result = this.getCustomPercentage(inputValue);
      result = result.replace(' ', '');
      return `${result} %`;
    } else if (maskExpression.startsWith('custom_percent')) {
      this.isCustomMask = true;
      result = this.getCustomPercentage(inputValue);
      result = result.replace(' ', '');
      return `${result}`;
    } else if (maskExpression.startsWith('custom_remove_mask')) {
      const valueFinal = parseInt(String(inputValue).replace(/\D/g, ''));
      return isNaN(valueFinal) ? '' : valueFinal.toString();
    } else {
      for (
        // eslint-disable-next-line
        let i: number = 0, inputSymbol: string = inputArray[0]!;
        i < inputArray.length;
        i++, inputSymbol = inputArray[i]!
      ) {
        if (cursor === maskExpression.length) {
          break;
        }
        if (
          this._checkSymbolMask(inputSymbol, maskExpression[cursor]!) &&
          maskExpression[cursor + 1] === '?'
        ) {
          result += inputSymbol;
          cursor += 2;
        } else if (
          maskExpression[cursor + 1] === '*' &&
          multi &&
          this._checkSymbolMask(inputSymbol, maskExpression[cursor + 2]!)
        ) {
          result += inputSymbol;
          cursor += 3;
          multi = false;
        } else if (
          this._checkSymbolMask(inputSymbol, maskExpression[cursor]!) &&
          maskExpression[cursor + 1] === '*'
        ) {
          result += inputSymbol;
          multi = true;
        } else if (
          maskExpression[cursor + 1] === '?' &&
          this._checkSymbolMask(inputSymbol, maskExpression[cursor + 2]!)
        ) {
          result += inputSymbol;
          cursor += 3;
        } else if (
          this._checkSymbolMask(inputSymbol, maskExpression[cursor]!)
        ) {
          if (maskExpression[cursor] === 'H') {
            if (Number(inputSymbol) > 2) {
              cursor += 1;
              this._shiftStep(maskExpression, cursor, inputArray.length);
              i--;
              if (this.leadZeroDateTime) {
                result += '0';
              }
              continue;
            }
          }
          if (maskExpression[cursor] === 'h') {
            if (result === '2' && Number(inputSymbol) > 3) {
              cursor += 1;
              i--;
              continue;
            }
          }
          if (maskExpression[cursor] === 'm') {
            if (Number(inputSymbol) > 5) {
              cursor += 1;
              this._shiftStep(maskExpression, cursor, inputArray.length);
              i--;
              if (this.leadZeroDateTime) {
                result += '0';
              }
              continue;
            }
          }
          if (maskExpression[cursor] === 's') {
            if (Number(inputSymbol) > 5) {
              cursor += 1;
              this._shiftStep(maskExpression, cursor, inputArray.length);
              i--;
              if (this.leadZeroDateTime) {
                result += '0';
              }
              continue;
            }
          }
          const daysCount = 31;
          if (maskExpression[cursor] === 'd') {
            if (
              (Number(inputSymbol) > 3 && this.leadZeroDateTime) ||
              Number(inputValue.slice(cursor, cursor + 2)) > daysCount ||
              inputValue[cursor + 1] === '/'
            ) {
              cursor += 1;
              this._shiftStep(maskExpression, cursor, inputArray.length);
              i--;
              if (this.leadZeroDateTime) {
                result += '0';
              }
              continue;
            }
          }
          if (maskExpression[cursor] === 'M') {
            const monthsCount = 12;
            // mask without day
            const withoutDays: boolean =
              cursor === 0 &&
              (Number(inputSymbol) > 2 ||
                Number(inputValue.slice(cursor, cursor + 2)) > monthsCount ||
                inputValue[cursor + 1] === '/');
            // day<10 && month<12 for input
            const day1monthInput: boolean =
              inputValue.slice(cursor - 3, cursor - 1).includes('/') &&
              ((inputValue[cursor - 2] === '/' &&
                Number(inputValue.slice(cursor - 1, cursor + 1)) >
                  monthsCount &&
                inputValue[cursor] !== '/') ||
                inputValue[cursor] === '/' ||
                (inputValue[cursor - 3] === '/' &&
                  Number(inputValue.slice(cursor - 2, cursor)) > monthsCount &&
                  inputValue[cursor - 1] !== '/') ||
                inputValue[cursor - 1] === '/');
            // 10<day<31 && month<12 for input
            const day2monthInput: boolean =
              Number(inputValue.slice(cursor - 3, cursor - 1)) <= daysCount &&
              !inputValue.slice(cursor - 3, cursor - 1).includes('/') &&
              inputValue[cursor - 1] === '/' &&
              (Number(inputValue.slice(cursor, cursor + 2)) > monthsCount ||
                inputValue[cursor + 1] === '/');
            // day<10 && month<12 for paste whole data
            const day1monthPaste: boolean =
              Number(inputValue.slice(cursor - 3, cursor - 1)) > daysCount &&
              !inputValue.slice(cursor - 3, cursor - 1).includes('/') &&
              !inputValue.slice(cursor - 2, cursor).includes('/') &&
              Number(inputValue.slice(cursor - 2, cursor)) > monthsCount;
            // 10<day<31 && month<12 for paste whole data
            const day2monthPaste: boolean =
              Number(inputValue.slice(cursor - 3, cursor - 1)) <= daysCount &&
              !inputValue.slice(cursor - 3, cursor - 1).includes('/') &&
              inputValue[cursor - 1] !== '/' &&
              Number(inputValue.slice(cursor - 1, cursor + 1)) > monthsCount;

            if (
              (Number(inputSymbol) > 1 && this.leadZeroDateTime) ||
              withoutDays ||
              day1monthInput ||
              day2monthInput ||
              day1monthPaste ||
              day2monthPaste
            ) {
              cursor += 1;
              this._shiftStep(maskExpression, cursor, inputArray.length);
              i--;
              if (this.leadZeroDateTime) {
                result += '0';
              }
              continue;
            }
          }
          result += inputSymbol;
          cursor++;
        } else if (inputSymbol === ' ' && maskExpression[cursor] === ' ') {
          result += inputSymbol;
          cursor++;
        } else if (
          this.maskSpecialCharacters.indexOf(maskExpression[cursor]!) !== -1
        ) {
          result += maskExpression[cursor];
          cursor++;
          this._shiftStep(maskExpression, cursor, inputArray.length);
          i--;
        } else if (
          this.maskSpecialCharacters.indexOf(inputSymbol) > -1 &&
          this.maskAvailablePatterns[maskExpression[cursor]!] &&
          this.maskAvailablePatterns[maskExpression[cursor]!]?.optional
        ) {
          if (
            !!inputArray[cursor] &&
            maskExpression !== '099.099.099.099' &&
            maskExpression !== '000.000.000-00' &&
            maskExpression !== '00.000.000/0000-00' &&
            !maskExpression
          ) {
            result += inputArray[cursor];
          }
          cursor++;
          i--;
        } else if (
          this.maskExpression[cursor + 1] === '*' &&
          this._findSpecialChar(this.maskExpression[cursor + 2]!) &&
          this._findSpecialChar(inputSymbol) ===
            this.maskExpression[cursor + 2] &&
          multi
        ) {
          cursor += 3;
          result += inputSymbol;
        } else if (
          this.maskExpression[cursor + 1] === '?' &&
          this._findSpecialChar(this.maskExpression[cursor + 2]!) &&
          this._findSpecialChar(inputSymbol) ===
            this.maskExpression[cursor + 2] &&
          multi
        ) {
          cursor += 3;
          result += inputSymbol;
        } else if (
          this.showMaskTyped &&
          this.maskSpecialCharacters.indexOf(inputSymbol) < 0 &&
          inputSymbol !== this.placeHolderCharacter
        ) {
          stepBack = true;
        }
      }
    }
    if (
      result.length + 1 === maskExpression.length &&
      this.maskSpecialCharacters.indexOf(
        maskExpression[maskExpression.length - 1]!
      ) !== -1
    ) {
      result += maskExpression[maskExpression.length - 1];
    }

    let newPosition: number = position + 1;

    while (this._shift.has(newPosition)) {
      shift++;
      newPosition++;
    }

    let actualShift: number =
      justPasted && !maskExpression.startsWith('separator')
        ? cursor
        : this._shift.has(position)
        ? shift
        : 0;
    if (stepBack) {
      actualShift--;
    }

    cb(actualShift, backspaceShift);
    if (shift < 0) {
      this._shift.clear();
    }
    let onlySpecial = false;
    if (backspaced) {
      onlySpecial = inputArray.every((char) =>
        this.maskSpecialCharacters.includes(char)
      );
    }
    let res = `${this.prefix}${onlySpecial ? '' : result}${this.suffix}`;
    if (result.length === 0) {
      res = `${this.prefix}${result}`;
    }
    if (maskExpression.startsWith('separator') && res && res != '') {
      const precision: number = this.getPrecision(maskExpression);
      const mask = `1.${precision}-${precision}`;
      res = formatNumber(parseFloat(realToDolar(res)), this.locale, `${mask}`);
    }
    return res;
  }

  public _findSpecialChar(inputSymbol: string): undefined | string {
    return this.maskSpecialCharacters.find(
      (val: string) => val === inputSymbol
    );
  }

  protected _checkSymbolMask(inputSymbol: string, maskSymbol: string): boolean {
    this.maskAvailablePatterns = this.customPattern
      ? this.customPattern
      : this.maskAvailablePatterns;
    return (
      this.maskAvailablePatterns[maskSymbol]! &&
      this.maskAvailablePatterns[maskSymbol]!.pattern &&
      this.maskAvailablePatterns[maskSymbol]!.pattern.test(inputSymbol)
    );
  }

  private _formatWithSeparators = (
    str: string,
    thousandSeparatorChar: string,
    decimalChars: string | string[],
    precision: number
  ) => {
    let x: string[] = [];
    let decimalChar: string = '';
    if (Array.isArray(decimalChars)) {
      const regExp = new RegExp(
        decimalChars
          .map((v) => ('[\\^$.|?*+()'.indexOf(v) >= 0 ? `\\${v}` : v))
          .join('|')
      );
      x = str.split(regExp);
      decimalChar = str.match(regExp)?.[0] ?? '';
    } else {
      x = str.split(decimalChars);
      decimalChar = decimalChars;
    }
    const decimals: string = x.length > 1 ? `${decimalChar}${x[1]}` : '';
    let res: string = x[0]!;
    const separatorLimit: string = this.separatorLimit.replace(/\s/g, '');
    if (separatorLimit && +separatorLimit) {
      if (res[0] === '-') {
        res = `-${res.slice(1, res.length).slice(0, separatorLimit.length)}`;
      } else {
        res = res.slice(0, separatorLimit.length);
      }
    }
    const rgx: RegExp = /(\d+)(\d{3})/;

    while (thousandSeparatorChar && rgx.test(res)) {
      res = res.replace(rgx, '$1' + thousandSeparatorChar + '$2');
    }

    if (precision === undefined) {
      return res + decimals;
    } else if (precision === 0) {
      return res;
    }
    return res + decimals.substr(0, precision + 1);
  };

  private getCustomPercentage = (inputValue: string) => {
    let result = inputValue;
    if (
      inputValue.match('[a-z]|[A-Z]') ||
      // eslint-disable-next-line no-useless-escape
      inputValue.match(/[-!$%^&*()_+|~=`{}\[\]:";'<>?,\/.]/)
    ) {
      result = this._stripToDecimal(inputValue);
      result = this.checkInputPrecision(result, 2, ',');
    }
    if (
      result.indexOf(',') > 0 &&
      !this.percentage(result.substring(0, result.indexOf(',')))
    ) {
      const base: string = result.substring(0, result.indexOf(','));
      result = `${base}${result.substring(result.indexOf(','), result.length)}`;
    }
    return result;
  };

  private percentage = (str: string): boolean => {
    return Number(str) >= 0 && Number(str) <= 100;
  };

  private percentageLimit = (str: string): string => {
    if (Number(str) < 0) return '0';
    if (Number(str) > 100) return '100';
    return str;
  };

  public getPrecision = (
    maskExpression: string,
    separator: string = '.'
  ): number => {
    const x: string[] = maskExpression.split(separator);
    if (x.length > 1) {
      return Number(x[x.length - 1]);
    }

    return Infinity;
  };

  private checkAndRemoveSuffix = (inputValue: string): string => {
    for (let i = this.suffix?.length - 1; i >= 0; i--) {
      const substr = this.suffix.substr(i, this.suffix?.length);
      if (
        inputValue.includes(substr) &&
        (i - 1 < 0 ||
          !inputValue.includes(this.suffix.substr(i - 1, this.suffix?.length)))
      ) {
        return inputValue.replace(substr, '');
      }
    }
    return inputValue;
  };

  private checkInputPrecision = (
    inputValue: string,
    precision: number,
    decimalMarker: IConfig['decimalMarker']
  ): string => {
    if (precision < Infinity) {
      // TODO need think about decimalMarker
      if (Array.isArray(decimalMarker)) {
        const marker = decimalMarker.find(
          (dm) => dm !== this.thousandSeparator
        );
        // eslint-disable-next-line no-param-reassign
        decimalMarker = marker ? marker : decimalMarker[0];
      }
      const precisionRegEx: RegExp = new RegExp(
        this._charToRegExpExpression(decimalMarker) + `\\d{${precision}}.*$`
      );

      const precisionMatch: RegExpMatchArray | null =
        inputValue.match(precisionRegEx);
      if (precisionMatch && precisionMatch[0]!.length - 1 > precision) {
        const diff = precisionMatch[0]!.length - 1 - precision;
        // eslint-disable-next-line no-param-reassign
        inputValue = inputValue.substring(0, inputValue.length - diff);
      }
      if (
        precision === 0 &&
        this._compareOrIncludes(
          inputValue[inputValue.length - 1],
          decimalMarker,
          this.thousandSeparator
        )
      ) {
        // eslint-disable-next-line no-param-reassign
        inputValue = inputValue.substring(0, inputValue.length - 1);
      }
    }
    return inputValue;
  };

  private _stripToDecimal(str: string): string {
    return str
      .split('')
      .filter((i: string, idx: number) => {
        return (
          i.match('^-?\\d') ||
          i.match('\\s') ||
          i === '.' ||
          i === ',' ||
          (i === '-' && idx === 0 && this.allowNegativeNumbers)
        );
      })
      .join('');
  }

  private _charToRegExpExpression(char: string): string {
    // if (Array.isArray(char)) {
    // 	return char.map((v) => ('[\\^$.|?*+()'.indexOf(v) >= 0 ? `\\${v}` : v)).join('|');
    // }
    if (char) {
      const charsToEscape = '[\\^$.|?*+()';
      return char === ' '
        ? '\\s'
        : charsToEscape.indexOf(char) >= 0
        ? `\\${char}`
        : char;
    }
    return char;
  }

  private _shiftStep(
    maskExpression: string,
    cursor: number,
    inputLength: number
  ) {
    const shiftStep: number = /[*?]/g.test(maskExpression.slice(0, cursor))
      ? inputLength
      : cursor;
    this._shift.add(shiftStep + this.prefix.length || 0);
  }

  protected _compareOrIncludes<T>(
    value: T,
    comparedValue: T | T[],
    excludedValue: T
  ): boolean {
    return Array.isArray(comparedValue)
      ? comparedValue.filter((v) => v !== excludedValue).includes(value)
      : value === comparedValue;
  }

  private _validIP(valuesIP: string[]): boolean {
    return !(
      valuesIP.length === 4 &&
      !valuesIP.some((value: string, index: number) => {
        if (valuesIP.length !== index + 1) {
          return value === '' || Number(value) > 255;
        }
        return value === '' || Number(value.substring(0, 3)) > 255;
      })
    );
  }
}
