export const AVAILABLE_LETTERS: string[] = [
  'KeyA',
  'KeyB',
  'KeyC',
  'KeyD',
  'KeyE',
  'KeyF',
  'KeyG',
  'KeyH',
  'KeyI',
  'KeyJ',
  'KeyK',
  'KeyL',
  'KeyM',
  'KeyN',
  'KeyO',
  'KeyP',
  'KeyQ',
  'KeyR',
  'KeyS',
  'KeyT',
  'KeyU',
  'KeyV',
  'KeyW',
  'KeyX',
  'KeyY',
  'KeyZ',
];

export const AVAILABLE_NUMBERS: string[] = [
  'Digit0',
  'Digit1',
  'Digit2',
  'Digit3',
  'Digit4',
  'Digit5',
  'Digit6',
  'Digit7',
  'Digit8',
  'Digit9',
];

// a	65	a	'KeyA'
// b	66	b	'KeyB'
// c	67	c	'KeyC'
// d	68	d	'KeyD'
// e	69	e	'KeyE'
// f	70	f	'KeyF'
// g	71	g	'KeyG'
// h	72	h	'KeyH'
// i	73	i	'KeyI'
// j	74	j	'KeyJ'
// k	75	k	'KeyK'
// l	76	l	'KeyL'
// m	77	m	'KeyM'
// n	78	n	'KeyN'
// o	79	o	'KeyO'
// p	80	p	'KeyP'
// q	81	q	'KeyQ'
// r	82	r	'KeyR'
// s	83	s	'KeyS'
// t	84	t	'KeyT'
// u	85	u	'KeyU'
// v	86	v	'KeyV'
// w	87	w	'KeyW'
// x	88	x	'KeyX'
// y	89	y	'KeyY'
// z	90	z	'KeyZ'
