import { Injectable } from '@angular/core';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { ClickableAnnotation } from '../annotations/clickable-annotation';
import {
  EHorizontalAnchorPoint,
  EVerticalAnchorPoint,
} from 'scichart/types/AnchorPoint';
import { ECoordinateMode } from 'scichart/Charting/Visuals/Annotations/AnnotationBase';
import { TMouseEventData } from '../types/tiger-chart.types';
import { getPlus } from '../templates/tiger-chart.template';
import { Subject } from 'rxjs';
import { Point } from 'scichart/Core/Point';
import { Dictionary } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class PlusLineService {
  private id = 'plus_icon';
  private annotation = new Dictionary<ClickableAnnotation>();
  event$ = new Subject<{
    ref: string;
    pointValueY: number;
    mouseEvent: MouseEvent;
    mousePoint: Point;
  }>();
  constructor() {}

  addPlus(
    baseChart: TWebAssemblyChart,
    eventData: TMouseEventData,
    ref: string
  ) {
    if (!eventData.pointValues) {
      return;
    }
    const annotation = this.annotation.get(ref);
    if (annotation) {
      annotation.y1 = eventData.pointValues.y;
      annotation.isHidden = false;
      return;
    }
    this.annotation.set(
      ref,
      new ClickableAnnotation({
        svgString: getPlus(),
        horizontalAnchorPoint: EHorizontalAnchorPoint.Right,
        verticalAnchorPoint: EVerticalAnchorPoint.Center,
        xCoordinateMode: ECoordinateMode.Relative,
        x1: 1,
        y1: eventData.pointValues.y,
        isEditable: false,
        id: this.id,
        annotationsGripsRadius: 0,
        annotationsGripsFill: 'transparent',
        annotationsGripsStroke: 'transparent',
        selectionBoxStroke: 'transparent',
        selectionBoxDelta: 0,
        selectionBoxThickness: 0,
        xAxisId: `xAxis_${ref}`,
        onClick: (args) => {
          args.mouseArgs.nativeEvent.stopPropagation();
          args.mouseArgs.nativeEvent.stopImmediatePropagation();
          args.mouseArgs.nativeEvent.preventDefault();
          this.event$.next({
            ref,
            pointValueY: args.sender.y1,
            mouseEvent: args.mouseArgs.nativeEvent,
            mousePoint: args.mouseArgs.mousePoint,
          });
          return;
        },
      })
    );
    baseChart.sciChartSurface.annotations.add(this.annotation.get(ref)!!);
  }

  removePlus(ref: string) {
    const annotation = this.annotation.get(ref);
    if (annotation) {
      annotation.isHidden = true;
    }
  }
}
