import { Injectable } from '@angular/core';
import { ModalStrategiesComponent } from '../modal-strategies/modal-strategies.component';
import { RocketModalService } from '@shared/rocket-components/components';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import {
  IActiveStrategy,
  StrategyPreferencesService,
} from '@shared/services/core/custom-preferences/strategy';
import { IStrategy, IStrategyCondition } from '../interface/strategy.interface';
import { ACTIVE_STRATEGY_TYPE } from '../enum/strategy.enum';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { InputMask } from '../types/strategy.types';

@Injectable({
  providedIn: 'root',
})
export class StrategyService {
  private _strategyInfos$: Subject<IActiveStrategy | undefined> = new Subject<
    IActiveStrategy | undefined
  >();
  private isActiveStrategy: boolean = false;
  private activeStrategy!: IActiveStrategy | undefined;
  private updateSelectors$ = new ReplaySubject<IStrategy[]>();

  constructor(
    private _rocketModalService: RocketModalService,
    private _strategyPreferencesService: StrategyPreferencesService
  ) {
    this.isActiveStrategy = this._strategyPreferencesService.enableStrategy;
    this.activeStrategy = this._strategyPreferencesService.activeStrategy;
  }

  get handleStrategy$(): Observable<IActiveStrategy | undefined> {
    return this._strategyInfos$.asObservable();
  }

  get updateObservable() {
    return this.updateSelectors$.asObservable();
  }

  dispatchUpdate(strategies: IStrategy[]) {
    this.updateSelectors$.next(strategies);
  }

  public openStrategiesModal(
    data: any = {
      toggleBetweenModal: undefined,
      isSecondModal: false,
      showAllStrategies: true,
      newStrategy: false,
      selectedStrategy: undefined,
      onlyEdit: false,
    }
  ) {
    return this._rocketModalService.open(ModalStrategiesComponent, {
      size: 'lg',
      data,
    });
  }

  public setActiveStrategy(strategy: IStrategy | undefined): void {
    const activeStrategy = strategy
      ? this.buildStrategyInfoTosaveInStorage(strategy)
      : strategy;
    this.activeStrategy = activeStrategy;
    this._strategyInfos$.next(activeStrategy);
    this._strategyPreferencesService.activeStrategy = activeStrategy;
  }

  private buildStrategyInfoTosaveInStorage(
    strategy: IStrategy
  ): IActiveStrategy {
    return {
      name: strategy.nameStrategy,
      idStrategy: strategy.idStrategy,
      type: ACTIVE_STRATEGY_TYPE[strategy.strategyType],
      items: strategy.strategyItems,
      isDisabled: strategy.isDisabled,
    };
  }

  public findActiveStrategy = (): IActiveStrategy | undefined =>
    this.activeStrategy;

  public setEnableStrategy(enableStrategy: boolean): void {
    this.isActiveStrategy = enableStrategy;
    this._strategyPreferencesService.enableStrategy = enableStrategy;
  }

  public strategyIsEnabled = (): boolean => this.isActiveStrategy;

  public findIdStrategyToSendOrder = (): number =>
    this._strategyPreferencesService.idStrategyToSendOrder;

  public changeStrategyType(
    type: 'TICK' | 'FINANCEIRO' | 'PERCENTUAL',
    formGroup: FormGroup
  ): {
    decidePosition: 'all' | 'suffix';
    inputMask: InputMask;
  } {
    const decidePosition = type === 'FINANCEIRO' ? 'all' : 'suffix';
    let inputMask: InputMask = 'separator.0';
    const hash: any = {
      FINANCEIRO: () => {
        inputMask = 'separator.2';
        this.changeStrategyValuesFormat(type, formGroup, inputMask);
      },
      PERCENTUAL: () => {
        inputMask = 'separator.1';
      },
      TICK: () => {
        inputMask = 'separator.0';
        this.changeStrategyValuesFormat(type, formGroup, inputMask);
      },
    };
    const func = hash[type];
    if (func) {
      func();
    }
    return { decidePosition, inputMask };
  }

  private changeStrategyValuesFormat(
    type: 'TICK' | 'FINANCEIRO' | 'PERCENTUAL',
    formGroup: FormGroup,
    inputMask: InputMask
  ): void {
    const form: any = formGroup.controls['items'];
    form.controls.forEach((group: any) => {
      const gainControl = group.get('gain');
      const lossControl = group.get('loss');
      const newGainValue = this.removeFormatter(gainControl.value, inputMask);
      const newLossValue = this.removeFormatter(lossControl.value, inputMask);
      if (type === 'FINANCEIRO') {
        gainControl.setValue(newGainValue.toFixed(2).replace('.', ','));
        lossControl.setValue(newLossValue.toFixed(2).replace('.', ','));
      } else if (type === 'TICK') {
        gainControl.setValue(newGainValue);
        lossControl.setValue(newLossValue);
      }
    });
  }

  removeFormatter(value: any, inputMask: InputMask, mask?: string): number {
    const masked = mask ?? inputMask;
    if (masked === 'custom_remove_mask') {
      return parseInt(String(value).replace(/\D/g, ''));
    }
    if (masked === 'custom_percent_symbol') {
      return parseFloat(String(value).replace(',', '.'));
    }
    if (typeof value === 'number') return value;

    if (value.includes('R$')) {
      return parseFloat(
        String(value)
          .replace(/[^0-9,-]/g, '')
          .replace(',', '.')
      );
    } else {
      return parseFloat(String(value).replace(',', '.'));
    }
  }

  public addStrategyItems(
    disableAddCondition: boolean,
    groups: FormArray<any>,
    formBuilder: FormBuilder,
    inputMask: InputMask,
    defaultValues?: IStrategyCondition
  ) {
    if (disableAddCondition) return;
    const quantityDefaultValue = groups.controls.length ? 1 : 100;
    const strategyItems = formBuilder.group({
      id_item: [defaultValues?.id_item ?? null, []],
      gain: [
        this._buildConditionInfo(inputMask, defaultValues?.gain),
        [Validators.required],
      ],
      loss: [
        this._buildConditionInfo(inputMask, defaultValues?.loss),
        [Validators.required],
      ],
      offset: [
        this._buildConditionInfo(inputMask, defaultValues?.offset, false),
        [Validators.required],
      ],
      quantity: [
        defaultValues?.quantity ?? quantityDefaultValue,
        [Validators.required],
      ],
    });
    groups.push(strategyItems);
    disableAddCondition = groups.controls.length >= 7;
    if (!defaultValues) {
      return {
        groups,
        disableAddCondition,
        ...this.updateQuantityPercentValue(groups),
      };
    }
    return { groups, disableAddCondition };
  }

  private _buildConditionInfo(
    inputMask: InputMask,
    value?: number,
    useInputMask: boolean = true
  ): string {
    if (isNullOrUndefined(value)) return '1';
    if (useInputMask && inputMask === 'separator.2') return value!.toFixed(2);
    return value!.toString().replace('.', ',');
  }

  updateQuantityPercentValue(groups: FormArray<any>) {
    const wasDivided: boolean = false;
    const size = groups.controls.length;
    const divided = Math.floor(100 / size);
    const totalDivided = divided * size;
    groups.controls.forEach((control, index) => {
      const total =
        totalDivided < 100 && index == groups.controls.length - 1
          ? divided + 1
          : divided;
      control.get('quantity')?.setValue(total);
    });
    const disableAddCondition = false;
    const indexInvalidQuantity = null;
    if (wasDivided) return;
    return { disableAddCondition, indexInvalidQuantity };
  }

  formatItemsToCreate(
    items: any[],
    inputMask: InputMask,
    hasIdItem: boolean = true
  ) {
    return items.map((item: any) => {
      item.quantity = parseFloat(String(item.quantity).replace(',', '.'));
      item.gain = this.removeFormatter(item.gain, inputMask);
      item.loss = this.removeFormatter(item.loss, inputMask);
      item.offset = parseInt(String(item.offset).replace(/\D/g, ''));
      if (!hasIdItem) {
        delete item.id_item;
      }
      return item;
    });
  }
}
