import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerformanceReportComponent } from './performance-report.component';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingModule } from '../loading/loading.module';
import { PerformanceReportService } from './services/performance-report.service';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';
import {
  FlaIconModule,
  RocketButtonModule,
} from '@shared/rocket-components/components';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { PerformanceReportHeaderComponent } from './parts/header/performance-report-header.component';
import { PerformanceDetailsComponent } from './parts/performance-details/performance-details.component';
import { PerformanceInfoChartComponent } from './parts/performance-info-chart/performance-info-chart.component';
import { ChartPerformanceComponent } from './parts/chart-performance/chart-performance.component';
import { ProfitBarChartModule } from './parts/profit-bar-chart/profit-bar-chart.module';
import { NoInfoModule } from '../business-profile/no-info/no-info.module';

@NgModule({
  imports: [
    CommonModule,
    RTDropdownModule,
    FlaWorkSpaceModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingModule,
    RocketButtonModule,
    FlaIconModule,
    TooltipsModule,
    ProfitBarChartModule,
    NoInfoModule,
  ],
  declarations: [
    PerformanceReportComponent,
    PerformanceReportHeaderComponent,
    PerformanceInfoChartComponent,
    PerformanceDetailsComponent,
    ChartPerformanceComponent,
  ],
  exports: [PerformanceReportComponent],
  providers: [PerformanceReportService],
})
export class PerformanceReportModule {}
