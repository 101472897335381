import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'fla-card-price[title][value][subtitle]',
  templateUrl: './card-price.component.html',
})
export class FlaCardPriceComponent {
  @ViewChild(TemplateRef) content!: TemplateRef<any>;
  @Input() title!: string;
  @Input() value!: string;
  @Input() subtitle!: string;
  @Input('css') customClass: string = '';
  @Output() flaClick: EventEmitter<any> = new EventEmitter<any>();

  public clickButton(event: any): void {
    this.flaClick.emit(event);
  }
}
