import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
} from '@angular/core';
import { OPTIONS_CONTEXT_MENU_ENUM } from '../types';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';
import { delay, of, take } from 'rxjs';
import { BaseContextMenuComponent } from '@shared/rocket-components/base-context-menu';

@Component({
  selector: 'app-options-table-context-menu',
  template: `
    <div
      class="round bg-neutral-strong position-absolute d-block"
      [style.top.px]="position.top"
      [style.left.px]="position.left"
      [style.z-index]="9999"
      [style.width]="'200px'"
      [style.padding]="'10px'"
      (mouseleave)="onFocusOut()"
    >
      <div class="list-group list-group-flush">
        <div class="header-context-menu">
          <span>Opções</span>
        </div>

        <button
          type="button"
          class="list-group-item list-group-item-action context-item-button"
          (click)="handleAction(menuOptions.PREFERENCES)"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="settings"
          ></fla-icon>
          Preferências
        </button>

        <hr class="separator-context-menu" />

        <button
          type="button"
          class="list-group-item list-group-item-action border-none fs-6 context-item-button"
          (click)="handleAction(menuOptions.RESET)"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="refresh"
          ></fla-icon>
          Restaurar
        </button>
        <button
          type="button"
          class="list-group-item list-group-item-action border-none fs-6 context-item-button"
          disabled
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="help_outline"
          ></fla-icon>
          Ajuda
        </button>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsTableContextMenuComponent extends BaseContextMenuComponent {
  show: boolean = false;
  menuOptions = OPTIONS_CONTEXT_MENU_ENUM;
  parent_id!: string;

  constructor(
    elementRef: ElementRef,
    cdr: ChangeDetectorRef,
    private contextMenuService: ContextMenuService
  ) {
    super(elementRef, cdr);
  }

  static openContextMenu(
    contextMenuService: ContextMenuService,
    parent_id: string,
    event: { clientX: number; clientY: number }
  ) {
    contextMenuService.openContextMenu(parent_id, {
      type: 'OptionsTableContextMenuComponent',
      event: event,
      configurationData: {},
    });
  }

  private emitEvent(action: OPTIONS_CONTEXT_MENU_ENUM) {
    this.contextMenuService.emitEvent(this.parent_id, action);
    of()
      .pipe(take(1), delay(150))
      .subscribe(() => this.onFocusOut());
  }

  onFocusOut() {
    this.contextMenuService.closeContextMenu(this.parent_id);
  }

  handleAction(action: OPTIONS_CONTEXT_MENU_ENUM) {
    this.emitEvent(action);
  }
}
