import { TTigerChartIndicatorCreateOptions } from './indicators.types';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { TIGER_INDICATORS_ENUM } from '../enum/tiger-chart.enum';
import { BollingerBandsBase } from './base/bollinger-bands-base';

export class BollingerBands extends BollingerBandsBase {
  constructor(options: any) {
    super(options);
    this.type = TIGER_INDICATORS_ENUM.BOLLINGER_BANDS;
  }

  override create(
    options: TTigerChartIndicatorCreateOptions
  ): BaseRenderableSeries[] {
    this.updatePoints();
    return super.create(options);
  }

  override updatePoints() {
    const ticks = this.data[this.source] as number[];
    const offset: number[] = Array(this.timePeriod).fill(ticks[0]);
    this.points = this.service.call('BBANDS', {
      inReal: [...offset, ...ticks],
      timePeriod: this.timePeriod,
      nbDevDn: this.standardDeviation,
      nbDevUp: this.standardDeviation,
    });
  }

  override updateSettings() {
    this.updatePoints();

    super.updateSettings();
  }
}
