import { Injectable } from '@angular/core';
import { GROUP, GROUP_INDICATORS } from '../constants/stock-chart.constant';
import { ConfigService } from '@core/config';
import { TIGER_INDICATORS_ENUM } from '@shared/tiger-chart/enum';

@Injectable({
  providedIn: 'root',
})
export class GroupIndicatorsService {
  constructor(private configService: ConfigService) {}

  getGroupIndicators() {
    const groupsIndicators = GROUP_INDICATORS;
    const isBroker = this.configService.isBroker();
    if (!isBroker) {
      return groupsIndicators;
    }
    const inBrokerIndicators: any = {
      [TIGER_INDICATORS_ENUM.MOVING_AVERAGE]: true,
      [TIGER_INDICATORS_ENUM.EXPONENTIAL_MOVING_AVERAGE]: true,
      [TIGER_INDICATORS_ENUM.BOLLINGER_BANDS]: true,
      [TIGER_INDICATORS_ENUM.RELATIVE_STRENGTH_INDEX]: true,
      [TIGER_INDICATORS_ENUM.MOMENTUM]: true,
    };
    const brokerGroups: any[] = [];
    groupsIndicators.forEach((group) => {
      if (group.id == GROUP.FAVORITES) {
        brokerGroups.push(group);
      } else {
        const brokerIndicators: any[] = [];
        group.indicators.forEach((indicator) => {
          if (inBrokerIndicators[indicator.id]) {
            brokerIndicators.push(indicator);
          }
        });
        if (brokerIndicators.length) {
          group.indicators = brokerIndicators;
          brokerGroups.push(group);
        }
      }
    });
    return brokerGroups;
  }
}
