import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { isAuction } from '@shared/constants/general.contant';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Subject, auditTime } from 'rxjs';

@Component({
  selector: 'app-timer-auction-renderer',
  template: ` <fla-icon
      name="timer"
      size="icon-size-micro"
      css="text-feedback-warning"
    ></fla-icon>
    <span class="ml-1">{{ timer }}</span>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimerAuctionComponent
  implements ICellRendererAngularComp, OnDestroy
{
  public timer = '-';
  private time = 0;
  private timerSubject = new Subject<void>();

  constructor(private _cdr: ChangeDetectorRef) {
    this.timerSubject.pipe(auditTime(1000)).subscribe(() => this.countdown());
  }

  ngOnDestroy(): void {
    this.timerSubject?.unsubscribe();
  }

  public agInit(params: ICellRendererParams): void {
    isAuction(params.data?.situacao) &&
      params.value &&
      this.calculateTimeDifference(params.value);
  }

  public refresh(params: ICellRendererParams): boolean {
    isAuction(params.data?.situacao) &&
      params.value &&
      this.calculateTimeDifference(params.value);
    return true;
  }

  private calculateTimeDifference(value: string): void {
    const data = value.split(':');
    const date1 = new Date();
    const date2 = new Date();
    date2.setHours(+data[0], +data[1], +data[2]);
    this.time = (date2.getTime() - date1.getTime()) / 1000;
    this.countdown();
  }

  private countdown(): void {
    if (this.time > -1) {
      let min: any = this.time / 60;
      let seg: any = this.time % 60;
      +min < 10 && (min = `0${min}`);
      +seg < 10 && (seg = `0${seg}`);
      min = `${min}`.substring(0, 2);
      seg = `${seg}`.substring(0, 2);
      this.timer = `${min}:${seg}`;
      !this.timerSubject?.closed && this.timerSubject.next();
      this.time--;
    } else {
      this.timer = '00:00';
    }
    this._cdr.detectChanges();
  }
}
