import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceValidatorService } from '@shared/services/device-validator.service';

@Injectable({
  providedIn: 'root'
})
export class BrowserGuard {
  constructor(private router: Router, private deviceValidator: DeviceValidatorService) {}

  canActivate(): boolean {
    const isChromium = this.deviceValidator.isChromium()
    if(!isChromium){
      this.router.navigate(['invalid-browser']);
    }
    return isChromium
  }
}
