export const DEFAULT_GROUP_NEWS = {
  id_news_group: 999,
  itens: [
    {
      id_news_group: 999,
      nm_view_news: 'Hoje',
      seq: 2,
      tag_agg: 'TODAY',
      tp_view_news: 'TODAY',
    },
    {
      id_news_group: 999,
      nm_view_news: 'Ontem',
      seq: 1,
      tag_agg: 'YESTERDAY',
      tp_view_news: 'YESTERDAY',
    },
    {
      id_news_group: 999,
      nm_view_news: 'Amanhã',
      seq: 3,
      tag_agg: 'TOMORROW',
      tp_view_news: 'TOMORROW',
    },
    {
      id_news_group: 999,
      nm_view_news: 'Esta semana',
      seq: 4,
      tag_agg: 'THIS_WEEK',
      tp_view_news: 'THIS_WEEK',
    },
    {
      id_news_group: 999,
      nm_view_news: 'Próxima semana',
      seq: 5,
      tag_agg: 'NEXT_WEEK',
      tp_view_news: 'NEXT_WEEK',
    },
    {
      id_news_group: 999,
      nm_view_news: 'Próximos',
      seq: 6,
      tag_agg: 'NEXT',
      tp_view_news: 'NEXT',
    },
    {
      id_news_group: 999,
      nm_view_news: 'Últimos',
      seq: 7,
      tag_agg: 'LATEST',
      tp_view_news: 'LATEST',
    },
  ],
  nm_news_group: 'Calendário',
};
