import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { EFooterEvent, TFooterType } from './tiger-chart-footer.enum';
import {
  CHART_ALTER_SCALE_CONFIGS,
  STOCK_CHART_ELEMENT_IDS,
} from '@shared/components/stock-chart/enum/stock-chart.enum';
import { ICandleConfigs } from '../interface';
import { StockChartModalService } from '@shared/components/stock-chart/parts/modal-more-options/service/stock-chart-modal.service';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { filter, Subject, takeUntil } from 'rxjs';
import { FOOTER_SVG, SECTION_BUTTONS } from './tiger-chart-footer.consts';

interface clickDefaultInfo {
  value: any;
  save: boolean;
}

@Component({
  selector: 'app-tiger-chart-footer',
  templateUrl: './tiger-chart-footer.component.html',
  styleUrls: ['./tiger-chart-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TigerChartFooterComponent implements OnDestroy {
  log: boolean = false;
  freeScale: boolean = false;
  fastRuler: boolean = false;
  zoomIn: boolean = false;
  @Input() displayFastRuler: boolean = true;
  svgButtons = FOOTER_SVG;
  isLogTooltip: CHART_ALTER_SCALE_CONFIGS =
    CHART_ALTER_SCALE_CONFIGS.LOGARITHMIC;
  freeScaleTooltip: CHART_ALTER_SCALE_CONFIGS =
    CHART_ALTER_SCALE_CONFIGS.OFF_AUTO;
  fastRulerTooltip: CHART_ALTER_SCALE_CONFIGS =
    CHART_ALTER_SCALE_CONFIGS.OFF_FAST_RULER;
  zoomInTooltip: CHART_ALTER_SCALE_CONFIGS =
    CHART_ALTER_SCALE_CONFIGS.ON_ZOOM_IN;
  objectKeys = Object.keys;
  @Input() refComponent!: string;
  @Input() showBookButton: boolean = true;
  @Input() set isLog(log: boolean) {
    this.log = log;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.ARITHMETIC,
      false: CHART_ALTER_SCALE_CONFIGS.LOGARITHMIC,
    };
    this.fixedUniversalTooltip('isLogTooltip', this.log, bools);
  }
  @Input() set isFreeScale(freeScale: boolean) {
    this.freeScale = freeScale;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_AUTO,
      false: CHART_ALTER_SCALE_CONFIGS.ON_AUTO,
    };
    this.fixedUniversalTooltip('freeScaleTooltip', this.freeScale, bools);
  }
  @Input() set showChartBook(chartBook: boolean) {
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_BOOK_CHART,
      false: CHART_ALTER_SCALE_CONFIGS.ON_BOOK_CHART,
    };
    this.universalTooltip(
      STOCK_CHART_ELEMENT_IDS.FOOTER_BOOK_AND_VOLUME_X_PRICE,
      EFooterEvent.BOOK,
      chartBook,
      bools
    );
  }
  @Input() set showChartVolumeAtPrice(chartVolumeAtPrice: boolean) {
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_VOLUME_AT_PRICE_CHART,
      false: CHART_ALTER_SCALE_CONFIGS.ON_VOLUME_AT_PRICE_CHART,
    };
    this.universalTooltip(
      STOCK_CHART_ELEMENT_IDS.FOOTER_BOOK_AND_VOLUME_X_PRICE,
      EFooterEvent.VOLUME_AT_PRICE,
      chartVolumeAtPrice,
      bools
    );
  }
  @Input() set showVolume(volume: boolean) {
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_VOLUME,
      false: CHART_ALTER_SCALE_CONFIGS.ON_VOLUME,
    };
    this.universalTooltip(
      STOCK_CHART_ELEMENT_IDS.STOCK_TRADE_EVENTS_AND_VOLUME,
      EFooterEvent.VOLUME,
      volume,
      bools
    );
  }
  @Input() set showFastOrder(fastOrder: boolean) {
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_FAST_ORDER,
      false: CHART_ALTER_SCALE_CONFIGS.ON_FAST_ORDER,
    };
    this.universalTooltip(
      STOCK_CHART_ELEMENT_IDS.STOCK_TRADE_EVENTS_AND_VOLUME,
      EFooterEvent.FAST_ORDER,
      fastOrder,
      bools
    );
  }
  @Input() set showEvents(events: boolean) {
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_EVENTS,
      false: CHART_ALTER_SCALE_CONFIGS.ON_EVENTS,
    };
    this.universalTooltip(
      STOCK_CHART_ELEMENT_IDS.STOCK_TRADE_EVENTS_AND_VOLUME,
      EFooterEvent.EVENTS,
      events,
      bools
    );
  }
  @Input() set showExecOrders(execOrders: boolean) {
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_EXEC_ORDERS,
      false: CHART_ALTER_SCALE_CONFIGS.ON_EXEC_ORDERS,
    };
    this.universalTooltip(
      STOCK_CHART_ELEMENT_IDS.STOCK_TRADE_EVENTS_AND_VOLUME,
      EFooterEvent.EXEC_ORDERS,
      execOrders,
      bools
    );
  }
  @Input() set showPreviousClose(previousClose: boolean | undefined) {
    if(previousClose === undefined) return;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_PREVIOUS_CLOSE,
      false: CHART_ALTER_SCALE_CONFIGS.ON_PREVIOUS_CLOSE,
    };
    this.universalTooltip(
      STOCK_CHART_ELEMENT_IDS.STOCK_TRADE_EVENTS_AND_VOLUME,
      EFooterEvent.PREVIOUS_CLOSE,
      previousClose,
      bools
    );
  }
  @Input() set showBidAsk(bidAsk: boolean) {
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_BID_ASK,
      false: CHART_ALTER_SCALE_CONFIGS.ON_BID_ASK,
    };
    this.universalTooltip(
      STOCK_CHART_ELEMENT_IDS.STOCK_TRADE_EVENTS_AND_VOLUME,
      EFooterEvent.BID_ASK,
      bidAsk,
      bools
    );
  }
  @Input() set showZoomIn(zoomIn: boolean) {
    this.zoomIn = zoomIn;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.OFF_ZOOM_IN,
      false: CHART_ALTER_SCALE_CONFIGS.ON_ZOOM_IN,
    };
    this.fixedUniversalTooltip('zoomInTooltip', this.zoomIn, bools);
  }
  @Input() configs!: Partial<ICandleConfigs>;
  @Output() buttonClick = new EventEmitter<{ type: TFooterType }>();
  tFooterType = TFooterType;
  public elementIDS = STOCK_CHART_ELEMENT_IDS;
  public sectionButtons = SECTION_BUTTONS;
  private _onDestroy = new Subject<void>();
  readonly hashFunctions: any = {
    [TFooterType.RULER]: (data: clickDefaultInfo) => {
      this.useFastRuler({ value: data.value, save: data.save });
    },
    [TFooterType.EXEC_ORDERS]: (data: clickDefaultInfo) => {
      this.useExecOrder({ value: data.value, save: data.save });
    },
  };
  constructor(
    private cdr: ChangeDetectorRef,
    private stockChartModalService: StockChartModalService,
    private stockChartService: StockChartService
  ) {
    this.stockChartService.footerChartConfig$
      .pipe(
        takeUntil(this._onDestroy),
        filter(
          ({ ref, isFromParent }) => ref === this.refComponent && isFromParent
        )
      )
      .subscribe(({ cod, value }) => {
        const func = this.hashFunctions[cod];
        if (func) {
          func({ value: value, save: false });
        }
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngAfterViewInit() {
    this.setDefaultActiveButtons();
  }

  private setDefaultActiveButtons() {
    const configuration = this.stockChartModalService.getGlobalChartConfigs();
    this.useFastRuler({ value: configuration.showFastRuler, save: false });
    this.useExecOrder({ value: configuration.showExecOrders, save: false });
  }

  click(type: TFooterType) {
    this.buttonClick.emit({ type });
  }

  clickChartConfig(type: TFooterType, value: any) {
    const func = this.hashFunctions[type];
    if (func) {
      func({ value, save: true });
      return;
    }
  }

  private fixedUniversalTooltip(
    property: string,
    boolean: boolean,
    bools: { true: CHART_ALTER_SCALE_CONFIGS; false: CHART_ALTER_SCALE_CONFIGS }
  ) {
    (this as any)[property] = boolean ? bools.true : bools.false;
    this.cdr.detectChanges();
  }

  private universalTooltip(
    elementIds: STOCK_CHART_ELEMENT_IDS,
    id: EFooterEvent,
    boolean: boolean,
    bools: { true: CHART_ALTER_SCALE_CONFIGS; false: CHART_ALTER_SCALE_CONFIGS }
  ) {
    const buttons = this.sectionButtons.get(elementIds);
    if (buttons) {
      buttons[id].tooltip = boolean ? bools.true : bools.false;
      buttons[id].bool = boolean;
      this.cdr.detectChanges();
    }
  }

  private useFastRuler = (data: clickDefaultInfo) => {
    this.fastRuler = data.value;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.ON_FAST_RULER,
      false: CHART_ALTER_SCALE_CONFIGS.OFF_FAST_RULER,
    };
    this.fixedUniversalTooltip('fastRulerTooltip', this.fastRuler, bools);
    if (data.save) {
      this.stockChartService.footerChartConfig$.next({
        value: this.fastRuler,
        ref: this.refComponent,
        cod: TFooterType.RULER,
        isFromParent: false,
      });
      this.stockChartModalService.updateGlobalChartConfigs({
        showFastRuler: this.fastRuler,
      });
    }
  };

  private useExecOrder = (data: clickDefaultInfo) => {
    const execOrders = data.value;
    const bools = {
      true: CHART_ALTER_SCALE_CONFIGS.ON_EXEC_ORDERS,
      false: CHART_ALTER_SCALE_CONFIGS.OFF_EXEC_ORDERS,
    };
    this.fixedUniversalTooltip('execOrdersTooltip', execOrders, bools);
    if (data.save) {
      this.stockChartService.footerChartConfig$.next({
        value: execOrders,
        ref: this.refComponent,
        cod: TFooterType.EXEC_ORDERS,
        isFromParent: false,
      });
      this.stockChartModalService.updateGlobalChartConfigs({
        showExecOrders: execOrders,
      });
    }
  };
}
