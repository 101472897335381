import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RocketButtonComponent } from './button.component';
import { FlaIconModule } from '../icon';

@NgModule({
  imports: [CommonModule, FlaIconModule],
  declarations: [RocketButtonComponent],
  exports: [RocketButtonComponent],
})
export class RocketButtonModule {}
