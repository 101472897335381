import { Injectable } from '@angular/core';
import { ComponentsService } from '@services/api/nitro-ws/v1/components.service';
import { HEADERS_PORTFOLIO_CONFIG } from '../grid/columns/coldef-position';
import { deepClone } from '@shared/rocket-components/utils/functions';
import {
  HEADERS_ORDERS_CONFIG,
  HEADERS_ORDERS_OPEN_CONFIG,
} from '../grid/columns/coldef-order';
import { MultibrokerService } from '@services/core/multibroker/multibroker.service';
import { TP_STATUS } from '../constants/orders-history.const';
import { Observable, Subject } from 'rxjs';
import {
  IMetadata,
  ISearchStock,
  IWorkSpaceComponet,
} from 'src/app/core/interface';
import { HEADERS_ALERTS_CONFIG } from '../grid/columns/coldef-alerts';
import { HEADERS_INTRADIA_DAYTRADE_CONFIG } from '../grid/columns/coldef-intradia';
import { HEADERS_SWING_TRADE_CONFIG } from '../grid/columns/coldef-swingTrading';
import { COLUMNS_CONFIG } from '../constants/headers.const';
import { WorkspaceComponentService } from '@modules/home/service/workspace-component.service';
import {
  ORDER_OPEN_STATUS_DIC,
  ORDER_PAR_STATUS_DIC,
} from '@shared/dictionary/order.dictionary';
import { OrdersService } from '@shared/services/orders.service';
import { ORDER_CONFIRM_ACTIONS_ENUM } from '@shared/enum/buyOrSell.enum';

export interface OrdersByBrokerFilter {
  id_broker: any;
  type: any;
}

export const becameClosed = (prevValue: string, curValue: string) =>
  prevValue === TP_STATUS.ABERTA && curValue === TP_STATUS.FECHADA;

@Injectable()
export class OrdersHistoryService {
  private configGrids: any = {};
  private tabRef!: string;
  private columnMovedDelay!: any;
  private _resetTabContent$: Subject<boolean> = new Subject<boolean>();
  private _clearGrid$: Subject<{ ref: string }> = new Subject<{
    ref: string;
  }>();
  public stockInResetPosition: any = {};

  private invisibleColumn$: Subject<{
    filed: string;
    value: boolean;
    componentId: string;
  }> = new Subject<{ filed: string; value: boolean; componentId: string }>();

  get getConfigGrids() {
    return this.configGrids;
  }

  get resetGridContent$(): Observable<boolean> {
    return this._resetTabContent$.asObservable();
  }

  get clearGrid$(): Observable<{ ref: string }> {
    return this._clearGrid$.asObservable();
  }

  get invisibleColumn(): Observable<{
    filed: string;
    value: boolean;
    componentId: string;
  }> {
    return this.invisibleColumn$.asObservable();
  }

  setClearGrid$(ref: string) {
    this._clearGrid$.next({ ref });
  }

  constructor(
    private _mbService: MultibrokerService,
    private componentService: ComponentsService,
    private _workspaceComponentService: WorkspaceComponentService,
    private ordersService: OrdersService
  ) {}

  public setTabRef(ref: string) {
    this.tabRef = ref;
  }

  getSelectedAccount() {
    return this._mbService.getAccountSelected();
  }

  public setConfigGrids(configs: any) {
    this.configGrids = configs;
  }

  public setConfigGridsForTab(componentId: string, columns: any) {
    this.configGrids[this.tabRef] = columns;
    this.saveConfig(componentId);
  }

  private saveConfig(componentId: string): void {
    const component =
      this._workspaceComponentService.getComponentById(componentId);
    component.metadata.component = this.configGrids;
    this._workspaceComponentService.updateComponent(component);
    this.updateMetadata(component);
  }

  updateMetadata<T = any>(component: IWorkSpaceComponet<IMetadata<T>>): void {
    this.componentService.updateMetadata(component.id, component.metadata);
  }

  public columnMoved(event: Array<any>, componentId: string): void {
    clearTimeout(this.columnMovedDelay);
    this.columnMovedDelay = setTimeout(() => {
      const reorderHeaders: any = {};
      event.forEach((item: any) => {
        reorderHeaders[item.colId] = this.configGrids[this.tabRef][item.colId];
      });
      this.setConfigGridsForTab(componentId, reorderHeaders);
    }, 500);
  }

  getColumnsdefByRef(tabRef: any) {
    const configMap: any = {
      position: COLUMNS_CONFIG.PORTFOLIO,
      openOrder: {
        ...HEADERS_ORDERS_OPEN_CONFIG,
        ...COLUMNS_CONFIG.OPEN_ORDERS,
      },
      alerts: COLUMNS_CONFIG.ALERTS,
      intradia: COLUMNS_CONFIG.INTRADIA,
      swingtrading: COLUMNS_CONFIG.SWINGTRADING,
    };
    return configMap[tabRef] || HEADERS_ORDERS_CONFIG;
  }

  getGridConfigByRef(tabRef: any) {
    const configMap: any = {
      position: HEADERS_PORTFOLIO_CONFIG,
      openOrder: { ...HEADERS_ORDERS_OPEN_CONFIG, ...HEADERS_ORDERS_CONFIG },
      alerts: HEADERS_ALERTS_CONFIG,
      intradia: HEADERS_INTRADIA_DAYTRADE_CONFIG,
      swingtrading: HEADERS_SWING_TRADE_CONFIG,
    };

    return deepClone(configMap[tabRef] || HEADERS_ORDERS_CONFIG);
  }

  public createdConfig(componentId: string): void {
    const tabConfig = this.getGridConfigByRef(this.tabRef);
    this.setConfigGridsForTab(componentId, deepClone(tabConfig));
  }

  public columnResized(event: any, componentId: string): void {
    this.configGrids[this.tabRef][event.colId].width = event.actualWidth;
    this.setConfigGridsForTab(componentId, this.configGrids[this.tabRef]);
  }

  public resetDefaultColumns(componentId: string): void {
    this.createdConfig(componentId);
    this._resetTabContent$.next(true);
  }

  private showOrdersExecutedTab(
    type: string,
    previousValue: any,
    tp_status: string,
    previousStatus: string = ''
  ): boolean {
    return (
      type === TP_STATUS.EXECUTADA &&
      ((ORDER_OPEN_STATUS_DIC.has(previousStatus) &&
        !ORDER_PAR_STATUS_DIC.has(previousStatus)) ||
        (previousValue === TP_STATUS.FECHADA &&
          tp_status === TP_STATUS.FECHADA))
    );
  }

  getCorrectFlow(
    type: string,
    previousValue: any,
    tp_status: string,
    cd_status: string = '',
    previousStatus: string = ''
  ) {
    let flow = '';

    if (type === TP_STATUS.ABERTA && !ORDER_OPEN_STATUS_DIC.has(cd_status)) {
      flow = 'remove';
    } else if (
      !previousValue ||
      (type === TP_STATUS.FECHADA && becameClosed(previousValue, tp_status)) ||
      (type === TP_STATUS.EXECUTADA &&
        becameClosed(previousValue, tp_status) &&
        cd_status === 'EXEC') ||
      this.showOrdersExecutedTab(type, previousValue, tp_status, previousStatus)
    ) {
      flow = 'add';
    } else if (
      type === TP_STATUS.EXECUTADA &&
      ORDER_PAR_STATUS_DIC.has(cd_status)
    ) {
      flow = 'update';
    } else {
      const isRemoveEvent = this.canRemoveByTpStatus(
        type,
        previousValue,
        tp_status
      );
      flow = isRemoveEvent ? 'remove' : 'update';
    }
    return flow;
  }

  private canRemoveByTpStatus = (
    type: string,
    previousValueStatus: string,
    currentValueStatus: string
  ) => {
    return (
      type !== TP_STATUS.TOTAL &&
      becameClosed(previousValueStatus, currentValueStatus)
    );
  };

  public resetOrdersHistoryPreferences(componentID: string): void {
    this.createdConfig(componentID);
    this._resetTabContent$.next(true);
  }

  public resetPosition(
    stock: ISearchStock,
    position: any,
    isResetAllPosition: boolean = false
  ): void {
    const account = this._mbService.getAccountSelected();
    this.ordersService.handlePositionAction(
      account,
      stock,
      structuredClone(position),
      ORDER_CONFIRM_ACTIONS_ENUM.RESET,
      true,
      isResetAllPosition
    );
  }

  public getColorStatus(status: string) {
    switch (status) {
      case 'EXEC':
      case 'PARC':
        return 'text-feedback-success';
      case 'AGUA':
      case 'ALTE':
        return 'text-feedback-positive'
      case 'REJE':
      case 'CANC':
        return 'text-feedback-error';
      case 'PCAN':
      case 'AUTH':
        return 'text-feedback-warning';
      default:
        return 'invisible';
    }
  }
}
