<table class="w-100 mx-0 mb-1 text-center table bg-neutral-stronger">
  <thead>
    <tr>
      <th scope="col" class="col text-center wd-50" title="Quantidade Compra">
        Qtd
      </th>
      <th
        scope="col"
        class="col fw-bolder text-multibroker-buy text-right w-15"
        title="Compra"
      >
        C
      </th>
      <th
        scope="col"
        class="col fw-bolder text-multibroker-sell text-left w-15"
        title="Venda"
      >
        V
      </th>
      <th scope="col" class="col text-center wd-50" title="Quantidade Venda">
        Qtd
      </th>
    </tr>
  </thead>
  <tbody class="position-relative">
    <tr
      *ngFor="let item of data"
      class="position-relative w-100 vm-neutral-strongest"
    >
      <td colspan="2" class="position-relative wd-40">
        <div class="w-100 d-flex">
          <div class="wd-85" [ngClass]="item.buyQttyDiffClass">
            {{ item.qttyBuy }}
          </div>
          <div class="text-right wd-15" [ngClass]="item.classBuy">
            {{ item.priceBuy }}
          </div>
        </div>
        <div
          id="BOOK_QUANTITY_VOLUME"
          class="bg-multibroker-buy opacity-light position-absolute top-0 right-0 h-100 round-left"
          [style.width.%]="item.barBuy"
        ></div>
      </td>

      <td colspan="2" class="position-relative wd-40">
        <div class="w-100 d-flex">
          <div class="text-left wd-15" [ngClass]="item.classSell">
            {{ item.priceSell }}
          </div>
          <div class="wd-85" [ngClass]="item.sellQttyDiffClass">
            {{ item.qttySell }}
          </div>
        </div>
        <div
          class="bg-multibroker-sell opacity-light position-absolute top-0 left-0 h-100 round-right"
          [style.width.%]="item.barSell"
        ></div>
      </td>
    </tr>
  </tbody>
</table>
