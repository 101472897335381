import { IWorkSpaceDocLayout } from 'src/app/core/interface';

export class WorkSpaceDocLayout implements IWorkSpaceDocLayout {
  transform?: { x: number; y: number } | undefined;
  top?: string | undefined;
  right?: string | undefined;
  bottom?: string | undefined;
  left?: string | undefined;
  width: any;
  originalWidth: any;
  minWidth?: string;
  minHeight?: string;
  height: any;
  originalHeight: any;
  index?: string | undefined;
  showResizeLines?: boolean | undefined;
  unitOfMeasurement?: string | undefined;
  constructor(component: any) {
    const layout = component;
    this.unitOfMeasurement = component.unitOfMeasurement ?? 'px';
    this.width = `${component.width}${this.unitOfMeasurement}`;
    this.height = `${component.height}${this.unitOfMeasurement}`;
    this.originalWidth = `${component.width}${this.unitOfMeasurement}`;
    this.originalHeight = `${component.height}${this.unitOfMeasurement}`;
    this.minWidth = `${component.minWidth}`;
    this.minHeight = `${component.minHeight}`;
    this.top = layout.top ?? '0px';
    this.left = layout.left ?? '0px';
    this.index = '2';
    this.right = undefined;
    this.bottom = undefined;
    this.transform = component.transform ?? { x: 0, y: 0 };
  }
}
