export function findTwoLargestValues(
  data: any,
  length: number = 40
): [number, number] {
  let maxBuyQty = 0;
  let maxSellQty = 0;

  for (let i = 1; i <= length; i++) {
    const buyQty = +data[`compra_qtde${i}`] || 0;
    const sellQty = +data[`venda_qtde${i}`] || 0;

    if (buyQty > maxBuyQty) {
      maxBuyQty = buyQty;
    }

    if (sellQty > maxSellQty) {
      maxSellQty = sellQty;
    }
  }

  return [maxBuyQty, maxSellQty];
}

export const defAgressorClass = (
  price: number,
  minimumPrice: number,
  maximumPrice: number
) => {
  return price > maximumPrice || price < minimumPrice
    ? 'text-multibroker-sell fw-bolder '
    : '';
};

export const validStringValueOrDefault = (
  maybeValue: string,
  defaultValue: string
) => {
  return maybeValue === '' || maybeValue === 'EOS' || maybeValue === undefined
    ? defaultValue
    : maybeValue;
};
