import {
  Routes,
  RouterModule,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { HomeComponent } from './home.component';
import { inject } from '@angular/core';
import { PreventLoginReturnGuard } from '@core/guards/prevent-login-return.guard';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canDeactivate: [
      (
        component: any,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
      ) =>
        inject(PreventLoginReturnGuard).canDeactivate(
          component,
          currentRoute,
          currentState,
          nextState
        ),
    ],
  },
];

export const HomeRoutes = RouterModule.forChild(routes);
