import {
  IModalToken,
  IModalTokenSettings,
} from '../interface/modal-order-token.interface';

export const WITHOUT_TOKEN: IModalToken = {
  title: 'Segundo fator de autenticação',
  text: (name?: string) =>
    `Insira o token de segurança do <b>${name}</b> para enviar ordens`,
  displayButtons: true,
  isHelper: false,
};

export const TOKEN_REGISTERED: IModalToken = {
  title: 'Segundo fator de autenticação',
  text: () =>
    'Oba! Você já validou o seu segundo fator de autenticação para operar durante esta sessão.',
  displayButtons: false,
  isHelper: false,
};

export const TOKEN_VISIBILITY_ON: IModalTokenSettings = {
  inputType: 'text',
  title: 'Ocultar token',
  icon: 'visibility_off',
};

export const TOKEN_VISIBILITY_OFF: IModalTokenSettings = {
  inputType: 'password',
  title: 'Exibir token',
  icon: 'visibility',
};

export const HELPER_FIND_TRADING_TOKEN: IModalToken = {
  title: 'Segundo fator de autenticação é um recurso adicional de segurança',
  text: () => `Para obtê-lo, acesse o Rocket em seu celular, clique em sua foto de
  Perfil, escolha a opção "Segurança", selecione "Token" e copie o
  código Token exibido.`,
  displayButtons: false,
  isHelper: true,
};
