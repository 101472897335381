<ng-container *ngIf="filterOptions.length">
  <div
    class="bg-neutral-stronger gap-1 hstack justify-content-between px-1 py-2 w-100"
  >
    <div class="hstack w-100 gap-1">
      <ng-container *ngFor="let filter of filterOptions">
        <button
          class="border border-neutral-strong border-thin btn btn-fill-primary btn-sm fs-6 m-0 min-w-0 p-0 px-1 round-sm"
          [ngClass]="{ 'bg-brand-primary text-dark': filter.active }"
          (click)="setQttyFilter(filter)"
        >
          <span> >= {{ filter.qtty }} </span>
        </button>
      </ng-container>
    </div>

    <div
      [tooltip]="switchFunctionalityTooltip"
      class="switch-size align-items-center form-check form-switch justify-content-end m-0 overflow-hidden p-0"
    >
      <input
        class="custom-size form-check-input"
        type="checkbox"
        role="switch"
        id="flexSwitchCheckDefault"
        [ngModel]="enableFiltersAndHighlights"
        (click)="onChangeFiltersAndHighlights(!enableFiltersAndHighlights)"
      />
    </div>
  </div>
</ng-container>
