import { Injectable } from '@angular/core';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';

@Injectable({
  providedIn: 'root',
})
export class AtPriceService {
  private _colors = {
    negative_half: { dark: 0xed3965, light: 0xe6a1b1 },
    positive_half: { dark: 0x26ab58, light: 0xbef7dd },
    negative: { dark: 0xf2003b, light: 0xe61c4d },
    positive: { dark: 0x03ff61, light: 0x04b546 },
  };

  constructor(private globalStock: GlobalSelectedStockSubscription) {}
}
