import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ISearchStock, IWorkSpaceComponet } from '@core/interface';
import { deepClone } from '@shared/rocket-components/utils';
import { RANKING_TABS } from './enum/ranking-broker.enum';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { ActivatedRoute } from '@angular/router';
import { isWebViewContext } from 'src/app/desktop/integration.utils';

@Component({
  selector: 'app-ranking-broker',
  templateUrl: './ranking-broker.component.html',
  styleUrls: ['./ranking-broker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RankingBrokerComponent extends RocketComponentBase {
  @ViewChild('rankingBroker', { static: true }) rankingBroker!: ElementRef;
  @Input() component!: IWorkSpaceComponet;
  @Input() refComponent!: string;
  @Input() width!: number;
  @Input() height!: number;

  typeRanking: RANKING_TABS = RANKING_TABS.FLASH;
  stock!: ISearchStock | null;
  period: any = 'today';
  isExplode: boolean = false;
  enableChangePeriod: boolean = true;
  isDesktop = false;

  @Output() flaLink = new EventEmitter<{
    key: string;
    comp: IWorkSpaceComponet;
  }>();

  get headerOptions() {
    return this.component.metadata?.headerOptions;
  }

  get layoutOptions() {
    return this.component.metadata?.layout;
  }

  constructor(private cdr: ChangeDetectorRef, activatedRoute: ActivatedRoute) {
    super(activatedRoute);
    this.isDesktop = isWebViewContext();
  }

  protected initComponent(component: any): void {
    if (component) this.component = component;
  }

  public onChangeView(tab: RANKING_TABS) {
    this.typeRanking = tab;
    this.cdr.detectChanges();
  }

  public onChangePeriod(event: any) {
    this.period = deepClone(event);
    this.enableChangePeriod = false;
    this.cdr.detectChanges();
  }

  public onChangeStock(stock: ISearchStock | null) {
    this.stock = deepClone(stock);
    this.cdr.detectChanges();
  }

  public onExplode(isExplodeEvent: boolean) {
    this.isExplode = isExplodeEvent;
    this.cdr.detectChanges();
  }

  public clickLink(event: any) {
    this.flaLink.emit(event);
    this.cdr.detectChanges();
  }

  public setEnableChangePeriod() {
    this.enableChangePeriod = true;
    this.cdr.detectChanges();
  }
}
