import { TIGER_INTERVAL_ENUM } from '../enum';

export const EVENTS = 'Tiger-chart-annotation-events';
export const CORPORATE_EVENTS = `CORPORATE_${EVENTS}`;
export const RELEVANT_EVENTS = `RELEVANT_${EVENTS}`;
export const NEWS_CHART_EVENTS = `NEWS_CHART_${EVENTS}`;
export const TRADE_IDEA_EVENTS = `TRADE_IDEA_${EVENTS}`;
export const EVENTS_COLOR = {
  CORPORATE: '#FFF4A3',
  RELEVANT: '#F0960F',
  NEWS_CHART: '#3FAFF4',
  TRADE_IDEA: '#F96500',
};
export const INTERVAL_HASH: any = {
  [TIGER_INTERVAL_ENUM.ONE_DAY]: {
    timer: 86400000,
    type: 'day',
    qtty: 1,
    minutes: 1440,
  },
  [TIGER_INTERVAL_ENUM.FIFTEEN_MINUTE]: {
    timer: 900000,
    type: 'minute',
    qtty: 15,
    minutes: 15,
  },
  [TIGER_INTERVAL_ENUM.FIVE_MINUTE]: {
    timer: 300000,
    type: 'minute',
    qtty: 5,
    minutes: 5,
  },
  [TIGER_INTERVAL_ENUM.TEN_MINUTE]: {
    timer: 600000,
    type: 'minute',
    qtty: 10,
    minutes: 10,
  },
  [TIGER_INTERVAL_ENUM.ONE_HOUR]: {
    timer: 3600000,
    type: 'minute',
    qtty: 60,
    minutes: 60,
  },
  [TIGER_INTERVAL_ENUM.ONE_MINUTE]: {
    timer: 60000,
    type: 'minute',
    qtty: 1,
    minutes: 1,
  },
  [TIGER_INTERVAL_ENUM.ONE_MONTH]: {
    timer: 2629800000,
    type: 'month',
    qtty: 1,
    minutes: 43800,
    startDate: 1,
  },
  [TIGER_INTERVAL_ENUM.THIRTY_MINUTE]: {
    timer: 1800000,
    type: 'minute',
    qtty: 30,
    minutes: 30,
  },
  [TIGER_INTERVAL_ENUM.THIS_WEEK]: {
    timer: 604800016.56,
    type: 'day',
    qtty: 7,
    minutes: 10080,
  },
  [TIGER_INTERVAL_ENUM.TWO_MINUTE]: {
    timer: 120000,
    type: 'minute',
    qtty: 2,
    minutes: 2,
  },
};
