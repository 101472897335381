import { Injectable } from '@angular/core';
import { AccountPreferences } from '@shared/services/core/custom-preferences/account/account-preferences.services';
import { MultibrokerService } from '@shared/services/core/multibroker';
import { isIdBrokerSimulator } from '@shared/constants/simulator-league.constant';
import { system } from '@core/system/system.service';
import { OrdersService } from '@shared/services/orders.service';
import { OrderChannel } from '@shared/channel/order.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { CustodyChannel } from '@shared/channel/custody.channel';
import { MarketDispatcher } from '@shared/cheetah/dispatcher/market.dispatcher';
import { filter, take } from 'rxjs';
import { CheetahChannelState, STATE } from '@shared/channel/base/cheetah-channel.base';
import { JoystickService } from '@shared/services/joystick.service';
import { IAccountSelect } from '@core/interface';

@Injectable({
  providedIn: 'root',
})
export class AccountSelectorService extends ReadStreamBase {
  
  private orderParams: SubscribeParam | undefined;
  private isInitCheetah: boolean = false;
  private custodyParams: SubscribeParam | undefined;

  constructor(
    private accPreferences: AccountPreferences,
    private multiBrokerService: MultibrokerService,
    private ordersService: OrdersService,
    private orderChannel: OrderChannel,
    private custodyChannel: CustodyChannel,
    private marketDispatcher: MarketDispatcher,
    private joystick: JoystickService,
  ) {
    super();
    this.marketDispatcher
      .onChangeState()
      .pipe(
        filter((state: CheetahChannelState) => state.value != STATE.ERROR),
        take(1)
      )
      .subscribe(() => {
        this.isInitCheetah = true;
        this.subscribeCustodyChannel(null);
      });
  }

  public selectBroker(account: IAccountSelect, isSimulator: boolean) {
    const accountSelected = account;
    this.joystick.simulator = isSimulator;
    this.saveAccountSelectedInDB(accountSelected, isSimulator);
    this.getAllOrders(accountSelected);
    this.isInitCheetah && this.subscribeCustodyChannel(accountSelected);
  }

  // todo: forceIsSimulator adicionado aqui ja que nao é possivel de forcar a alteracao do this.joystick.isSimulator 
  // no joystick de forma sincrona por conta do request interno de storeCustomPreferences. O fluxo deve ser ajustado.
  public saveAccountSelectedInDB(accountSelected: IAccountSelect, forceIsSimulator: boolean | null = null) {
    const accountDb = { idTable: 1, ...accountSelected };

    const accountJson = JSON.stringify(accountDb);

    const isSimulator = forceIsSimulator ?? (this.joystick.isSimulator && this.joystick.visibilitySimulatorMenu);

    if (isSimulator) {
      this.accPreferences.simulatorAccountSelected = accountJson;
    } else if (!isIdBrokerSimulator(accountSelected.id_broker)) {
      this.accPreferences.multiBrokerAccountSelected = accountJson;
    }

    this.multiBrokerService.updateAccountSelected(accountDb);
  }

  private getKeyChannel(accountSelected: IAccountSelect) {
    return `${system.idInvestor}.${accountSelected.id_broker}`;
  }

  public getAllOrders(accountSelected: IAccountSelect) {
    this.orderParams && this.orderChannel.unsubscribe(this.orderParams);
    this.ordersService
      .getAllOrdersPaged(accountSelected.id_broker)
      .subscribe({
        next: () => {
          this.subscribeOrderChannel(accountSelected);
        },
        error: () => {
          this.subscribeOrderChannel(accountSelected);
        },
      });
  }

  private subscribeOrderChannel(accountSelected: any) {
    this.orderParams = {
      itemsArray: [this.getKeyChannel(accountSelected)],
    };
    this.orderChannel.subscribe(this.orderParams);
    this.orderChannel.readEvents().then(async (data) => {
      this.readStream(data.stream, (data: any) => {
        const orders = data.get(this.getKeyChannel(accountSelected));
        for (const order of orders) {
          this.ordersService.processOrderFromCheetah(order);
        }
      });
    });
  }

  public subscribeCustodyChannel(accountSelected: any) {
    if (
      !accountSelected ||
      (this.custodyParams &&
        this.custodyParams.itemsArray[0] === `ID_INVESTOR_${this.getKeyChannel(accountSelected)}`)
    )
      return;
    this.custodyParams && this.custodyChannel.unsubscribe(this.custodyParams);
    this.custodyParams = {
      itemsArray: [`ID_INVESTOR_${this.getKeyChannel(accountSelected)}`],
    };
    this.custodyChannel.subscribe(this.custodyParams);
  }
}