import { RTColorpickerDirective } from './directive/color-picker.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RTColorPaletteComponent } from './color-palette/color-palette.component';
import { RTColorPickerSelectorComponent } from './color-picker.component';
import { RTColorSliderComponent } from './color-slider/color-slider.component';
import { RocketCreateComponentService } from '@shared/rocket-components/services';

@NgModule({
  declarations: [
    RTColorPickerSelectorComponent,
    RTColorPaletteComponent,
    RTColorSliderComponent,
    RTColorpickerDirective,
  ],
  imports: [CommonModule],
  exports: [
    RTColorPickerSelectorComponent,
    RTColorpickerDirective,
    RTColorPaletteComponent,
    RTColorSliderComponent,
  ],
  providers: [RocketCreateComponentService],
})
export class RTColorPickerModule {}
