<div
  class="round bg-neutral-strong position-absolute"
  [style.width]="'200px'"
  [style.padding]="'10px'"
  [style.top.px]="position.top"
  [style.left.px]="position.left"
  [style.z-index]="9999"
  [style.display]="isTour ? 'none' : 'block'"
  (mouseleave)="onFocusOut()"
>
  <div class="list-group list-group-flush">
    <div *ngIf="!fromPlus" class="header-context-menu">
      <span>Gráfico</span>
    </div>

    <!-- <button type="button" class="list-group-item list-group-item-action fs-6" (click)="lockVerticalLine()">
        Travar Linha Vertical Neste Eixo de Tempo
      </button>
      <button type="button" class="list-group-item list-group-item-action fs-6" (click)="objectList()">
        Lista de Objetos
      </button>
      <button type="button" class="list-group-item list-group-item-action fs-6" (click)="removeAllTools()">
        Remover Todas as Ferramentas de Desenho
      </button>
      <button type="button" class="list-group-item list-group-item-action fs-6" (click)="removeALlIndicators()">
        Remover Todos os Indicadores
      </button> -->
    <section [id]="elementsID.CONTEXT_NEGOTIATION_SHORTCUT">
      <button
        type="button"
        class="pr-1 list-group-item list-group-item-action context-item-button"
        (click)="onClick(contextMenuEnumItem.FAST_TYPE_ORDER)"
        [disabled]="disableOrderButtons || isTypeStockIndex"
      >
        <fla-icon
          size="icon-size-tiny"
          [css]="typeOrderClass + ' position-absolute left-0'"
          name="flash_on"
        ></fla-icon>
        <span> {{ typeOrder }} a {{ currentValue }}</span>
      </button>
      <button
        type="button"
        class="pr-1 list-group-item list-group-item-action context-item-button"
        (click)="onClick(contextMenuEnumItem.TYPE_STOP_ORDER)"
        [disabled]="disableOrderButtons || isTypeStockIndex"
      >
        <fla-icon
          size="icon-size-tiny"
          [css]="typeOrderStopClass + ' position-absolute left-0'"
          name="flash_on"
        ></fla-icon>
        <span> {{ typeOrderStop }} Stop </span>
      </button>
      <button
        type="button"
        class="pr-1 list-group-item list-group-item-action context-item-button"
        (click)="onClick(contextMenuEnumItem.FAST_TYPE_ORDER, true)"
        [disabled]="disableOrderButtons || isTypeStockIndex"
      >
        <fla-icon
          size="icon-size-tiny"
          [css]="inverseTypeOrderClass + ' position-absolute left-0'"
          name="flash_on"
        ></fla-icon>
        <span> {{ inverseTypeOrder }} a {{ currentValue }}</span>
      </button>
    </section>
    <button
      *ngIf="!fromPlus"
      type="button"
      class="list-group-item list-group-item-action context-item-button"
      (click)="onClick(contextMenuEnumItem.TYPE_ORDER)"
      [disabled]="disableOrderButtons || isTypeStockIndex"
    >
      <fla-icon
        size="icon-size-tiny"
        [css]="typeOrderClass + ' position-absolute left-0'"
        name="flash_on"
      ></fla-icon>
      <span> {{ typeOrderBoleta }} </span>
    </button>
    <section>
      <button
        [id]="elementsID.CONTEXT_ALERTS"
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="onClick(contextMenuEnumItem.CREATE_ALERT)"
        [disabled]="disableOrderButtons"
      >
        <fla-icon
          size="icon-size-tiny"
          [css]="typeAlertClass + ' position-absolute left-0 fs-4'"
          name="notifications_none"
        ></fla-icon>
        <span> Criar alerta </span>
      </button>
      <button
        [id]="elementsID.INDICATORS"
        type="button"
        class="list-group-item list-group-item-action context-item-button position-relative pr-0"
        (click)="onClick(contextMenuEnumItem.ADD_INDICATORS)"
      >
        <div class="rocket-icons-div position-absolute">
          <app-rocket-icons name="INDICATORS"></app-rocket-icons>
        </div>
        <span> Adicionar Indicadores </span>
      </button>
    </section>
    <section *ngIf="fromPlus">
      <button
        type="button"
        class="p-0 list-group-item list-group-item-action context-item-button d-flex flex-row gap-2"
        (click)="onClick(contextMenuEnumItem.DRAW_HORIZONTAL_LINE)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28 28"
          width="36"
          height="36"
        >
          <g fill="currentColor" fill-rule="nonzero">
            <path d="M4 15h8.5v-1h-8.5zM16.5 15h8.5v-1h-8.5z"></path>
            <path
              d="M14.5 16c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z"
            ></path>
          </g>
        </svg>
        <span> Desenhar Linha Horizontal em {{ currentValue }}</span>
      </button>
    </section>
    <section *ngIf="!fromPlus">
      <!--<button
        *ngIf="false"
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="onClick(contextMenuEnumItem.TOGGLE_VOLUME)"
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          [name]="isVolumeVisible ? 'visibility_off' : 'visibility'"
        ></fla-icon>
        <span> {{ isVolumeVisible ? 'Ocultar' : 'Exibir' }} volume </span>
      </button>-->
      <button
        type="button"
        class="list-group-item list-group-item-action context-item-button pr-0"
        (click)="onClick(contextMenuEnumItem.TOGGLE_CUSTODY_STOP_ORDER)"
        *ngIf="hasCustody"
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          [name]="isCustodyCheepsVisible ? 'visibility' : 'visibility_off'"
        ></fla-icon>
        <span> SGain/SLoss </span>
      </button>
      <button
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="onClick(contextMenuEnumItem.MORE_OPTIONS)"
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="settings"
        ></fla-icon>
        Configurações
      </button>
    </section>
    <section *ngIf="!fromPlus">
      <button
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="reset()"
        disabled
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="refresh"
        ></fla-icon>
        <span> Restaurar </span>
      </button>
      <button
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="onClick(contextMenuEnumItem.HELP)"
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="help_outline"
        ></fla-icon>
        <span> Ajuda </span>
      </button>
    </section>
  </div>
</div>
