import { ElementRef, Injectable, OnDestroy, inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { RocketModalService } from '@shared/rocket-components/components/index';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { MinimalModalInterface } from './minimal-modal.model';

@Injectable()
export abstract class RocketModalRef<T = any>
  extends ReadStreamBase
  implements OnDestroy, MinimalModalInterface {
  private onClosed = new Subject<T | { closed: boolean }>();
  constructor(private service: RocketModalService) {
    super();
  }

  private _elemRef = inject(ElementRef);

  getPath(): string {
    return this._elemRef.nativeElement.tagName.toLowerCase();
  }

  ngOnDestroy(): void {
    this.onClosed.complete();
  }

  afterClosed(): Observable<T | { closed: boolean }> {
    return this.onClosed.asObservable();
  }

  close(value?: any, isSecondModal?: boolean): void {
    this.service.close(value, isSecondModal);
  }

  outsideEvent(outsideEvent: () => void): void {
    this.service.outsideEvent = outsideEvent;
  }
}
