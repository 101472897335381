import { formatNumber } from '@angular/common';
import { OhlcDataSeries } from 'scichart/Charting/Model/OhlcDataSeries';
import { GraphContainer } from '../directives/graph-container.directive';
import { BaseIndicator } from '../indicators/indicators.types';
import { TSecondaryChart, TTooltipTextData } from '../types/tiger-chart.types';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  Output,
} from '@angular/core';
import { ReplaySubject, Subscription, filter } from 'rxjs';
import { ICandleTheme } from '../interface';
import { TIGER_SUB_CHART_INDICATORS } from '../constants/tiger-chart.constants';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { randomId } from '@shared/rocket-components/utils';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import {
  getVolumeText,
  isNullOrUndefined,
} from 'src/app/utils/utils.functions';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { THEMES } from '@shared/services/core/custom-preferences/theme/theme-preferences.interface';

@Component({
  selector: 'app-tiger-chart-tooltip',
  templateUrl: './tiger-chart-tooltip.component.html',
  styleUrls: ['./tiger-chart-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TigerChartTooltipComponent implements OnDestroy {
  @Input() theme!: ICandleTheme;
  @Input() graphContainer!: GraphContainer;
  @Input() tickSize!: number;
  @Input() vl_volume!: number;
  @Input() secondaryChart?: TSecondaryChart;
  @Input() getIndicators$!: ReplaySubject<BaseIndicator[]>;
  @Input() isSubChart: boolean = false;
  @Output() changeIndicatorSettings = new EventEmitter<any>();
  @Output() changeIndicatorDelete = new EventEmitter<any>();
  @Output() indicatorVisibility = new EventEmitter<BaseIndicator>();
  template: string = '';
  private _candleDataSeries!: OhlcDataSeries;
  tooltipRef: string = randomId('tiger_chart_tooltip');

  private lastTextDataIndex!: number;
  isVisible = true;
  isVisibleTooltipIndicators = true;
  isVisibleIndicators = true;
  indicators: BaseIndicator[] = [];
  mouseIndex!: number;

  disableEvents = false;
  highlightColor = '';
  data: TTooltipTextData = {
    open: '',
    max: '',
    min: '',
    close: '',
    variation: '',
    volume: '',
  };
  private _mouseEventSub!: Subscription;
  private _themeActiveObservable$!: Subscription;
  private isDarkTheme: boolean = true;
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private stockChartService: StockChartService,
    private customPreferencesService: CustomPreferencesService,
    private cdr: ChangeDetectorRef,
    private themeService: ThemePreferencesService
  ) {
    this.isDarkTheme = this.themeService.isDarkTheme();
    this._themeActiveObservable$ = this.themeService
      .themeActiveObservable()
      .subscribe((theme) => {
        this.isDarkTheme = theme === THEMES.dark;
        this.createHeaderText(this.lastTextDataIndex);
      });
    this.isVisibleTooltipIndicators = isNullOrUndefined(
      this.customPreferencesService.showChartIndicators
    )
      ? true
      : this.customPreferencesService.showChartIndicators;
  }

  init(
    candleDataSeries: OhlcDataSeries | null,
    subChartIndicator?: BaseIndicator
  ) {
    if (!this.isSubChart && candleDataSeries) {
      this._candleDataSeries = candleDataSeries;
      const index = this._candleDataSeries.count();
      this.createHeaderText(index);
    }
    this.createIndicators(subChartIndicator);
    this._mouseEventSub = this.stockChartService.mouseIndex$
      .pipe(filter((value) => value.ref == this.tooltipRef))
      .subscribe((value) => {
        this.mouseIndex = value.index;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this._mouseEventSub?.unsubscribe();
    this._themeActiveObservable$ && this._themeActiveObservable$.unsubscribe();
  }

  updateTextData(vl_volume: number, index?: number, force?: boolean) {
    if (!force && index == this.lastTextDataIndex) return;

    if (!index) {
      index = this._candleDataSeries.count() - 1;
    }

    this.vl_volume = vl_volume;
    if (!this.isSubChart) {
      this.createHeaderText(index);
    }
    this.mouseIndex = index;
    this.stockChartService.mouseIndex$.next({
      ref: this.tooltipRef,
      index: this.mouseIndex,
    });
    this.cdr.detectChanges();
  }

  updateDataSeries(dataSeries: OhlcDataSeries, vl_volume: number) {
    this._candleDataSeries = dataSeries;
    this.updateTextData(vl_volume, dataSeries.count() - 1, true);
  }

  private createHeaderText(index: number) {
    if (!this._candleDataSeries) return;
    this.lastTextDataIndex = index;
    const closeValues = this._candleDataSeries.getNativeCloseValues();
    const openValues = this._candleDataSeries.getNativeOpenValues();
    const highValues = this._candleDataSeries.getNativeHighValues();
    const lowValues = this._candleDataSeries.getNativeLowValues();
    if (!closeValues || !closeValues.size()) {
      return;
    }
    const currentClosingPrice = closeValues?.get(index) || 0;
    const currentOpenPrice = openValues?.get(index) || 0;
    const lastClosingPrice = closeValues?.get(index - 1) || 1;

    this.highlightColor =
      currentClosingPrice == currentOpenPrice
        ? 'equal'
        : currentClosingPrice > currentOpenPrice
        ? 'up'
        : 'down';

    this.data = {
      open: `${formatNumber(
        openValues?.get(index) || 0,
        this.locale,
        `1.${this.tickSize}-${this.tickSize}`
      )}`,
      max: `${formatNumber(
        highValues?.get(index) || 0,
        this.locale,
        `1.${this.tickSize}-${this.tickSize}`
      )}`,
      min: `${formatNumber(
        lowValues?.get(index) || 0,
        this.locale,
        `1.${this.tickSize}-${this.tickSize}`
      )}`,
      close: `${formatNumber(
        currentClosingPrice,
        this.locale,
        `1.${this.tickSize}-${this.tickSize}`
      )}`,
      variation: `${formatNumber(
        currentClosingPrice - lastClosingPrice,
        this.locale,
        `1.${this.tickSize}-${this.tickSize}`
      )} (${formatNumber(
        (currentClosingPrice / lastClosingPrice - 1) * 100,
        this.locale,
        `1.2-2`
      )}%)`,
      volume: getVolumeText(this.locale, this.vl_volume),
    };
    const fontColor = this.isDarkTheme
      ? 'text-neutral-smoothest'
      : 'text-neutral-strongest';
    this.template = this.isVisible
      ? `
    <div class="text-wrap ${fontColor}">
      Abr <span class="${this.highlightColor}">${this.data.open}</span> Máx
      <span class="${this.highlightColor}">${this.data.max}</span> Min
      <span class="${this.highlightColor}">${this.data.min}</span> Fch
      <span class="${this.highlightColor}"
        >${this.data.close} ${this.data.variation}</span
      >
      V <span class="${this.highlightColor}">${this.data.volume}</span>
    </div>`
      : '';
    this.cdr.detectChanges();
  }

  toggleHide() {
    this.isVisible = !this.isVisible;
  }

  hideTooltipIndicators() {
    this.isVisibleTooltipIndicators = !this.isVisibleTooltipIndicators;
    this.customPreferencesService.showChartIndicators =
      this.isVisibleTooltipIndicators;
  }

  removeAllIndicators() {
    this.indicators.forEach((indicator) => {
      indicator.delete();
      this.deleteIndicator({
        type: indicator.type,
        lineNumber: indicator.lineNumber,
      });
    });
  }

  hideAllIndicators() {
    this.isVisibleIndicators = !this.isVisibleIndicators;
    this.indicators.forEach((indicator) => {
      indicator.isVisible = this.isVisibleIndicators;
    });
  }

  private createIndicators(subChartIndicator?: BaseIndicator) {
    this.getIndicators$.subscribe((indicators) => {
      this.indicators = indicators;
      if (!this.isSubChart) {
        this.indicators = this.indicators.filter(
          (ind) => TIGER_SUB_CHART_INDICATORS.indexOf(ind.type) == -1
        );
      } else {
        this.indicators = this.indicators.filter(
          (ind) =>
            TIGER_SUB_CHART_INDICATORS.indexOf(ind.type) != -1 &&
            (!subChartIndicator || subChartIndicator.type == ind.type)
        );
      }
    });
    this.cdr.detectChanges();
  }

  indicatorSettings(event: any) {
    this.changeIndicatorSettings.emit(event);
  }

  deleteIndicator(event: any) {
    this.changeIndicatorDelete.emit(event);
  }

  indicatorVisibilityChange(indicator: BaseIndicator) {
    const shows = this.indicators.filter((indicator) => indicator.isVisible);
    const hides = this.indicators.filter((indicator) => !indicator.isVisible);
    if (shows.length === this.indicators.length) {
      this.isVisibleIndicators = true;
    }
    if (hides.length === this.indicators.length) {
      this.isVisibleIndicators = false;
    }
    this.indicatorVisibility.emit(indicator);
  }
}
