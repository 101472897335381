<rocket-modal>
  <rocket-modal-header>
    <div>
      <h3 class="modal-title">Estratégias</h3>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div
      class="flex-column flex-md-row gap-4 pb-4 align-items-center justify-content-center"
    >
      <div class="w-100 d-flex">
        <div class="col-5">
          <options-strategy-modal-answers
            question="Preço da Ação"
            [answer]="price"
            (onChange)="onAnswerChange($event, 'price')"
          ></options-strategy-modal-answers>
        </div>
        <div class="col-5">
          <options-strategy-modal-answers
            question="Volatilidade"
            [answer]="volatility"
            (onChange)="onAnswerChange($event, 'volatility')"
          ></options-strategy-modal-answers>
        </div>
      </div>
      <div class="w-100 d-flex pt-3">
        <div class="col-6 pr-2">
          <fla-input
            requiredText="Informe o nome da workspace."
            placeholder="Pesquisar"
            css="pr-2 pt-1"
            margin="mb-2"
            decidePosition="prefix"
            [isSmall]="true"
            [formControl]="searchField"
            (flaKeyUp)="filterStrategies()"
          >
            <span class="prefix pl-1 cursor-pointer">
              <fla-icon name="search" size="icon-size-sm"></fla-icon>
            </span>
          </fla-input>
          <div class="strategies-list">
            <app-options-strategy-modal-strategy-selector
              *ngFor="let strategy of filteredStrategies"
              [strategy]="strategy"
              [selectedStrategy]="selectedStrategy.ds_title"
              (strategySelected)="setStrategy($event)"
            ></app-options-strategy-modal-strategy-selector>
          </div>
        </div>
        <div
          *ngIf="selectedStrategy; else elseTemplate"
          class="col-10 pl-2 d-flex flex-column justify-content-between selected-strategy"
        >
          <div>
            <h4 class="pb-2">{{ selectedStrategy.ds_title }}</h4>
            <p>{{ selectedStrategy.ds_strategy }}</p>
            <div class="d-flex justify-content-center">
              <img
                [src]="src"
                [alt]="selectedStrategy.ds_strategy"
                class="selected-strategy-img pr-2 d-flex justify-content-center"
              />
            </div>
          </div>

          <div class="w-100 d-flex justify-content-end mt-3">
            <rocket-button
              type="default"
              label="Selecionar Operação"
              tooltipPosition="bottom"
              [tooltip]="'Selecionar operação ' + selectedStrategy.ds_title"
              (rocketClick)="selectOperation()"
            >
            </rocket-button>
          </div>
        </div>
        <ng-template #elseTemplate>
          <div class="col-10 pl-2 d-flex flex-column justify-content-between">
            Sem estratégias para o filtro informado!
          </div>
        </ng-template>
      </div>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
