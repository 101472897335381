export const FLA_COLORS = {
  Valemobi: {
    'brand-primary': '#fab14b',
    brown: '#594f4f',
    'dark-blue': '#547980',
    torquoise: '#45ada8',
    'army-green': '#7fa684',
    'light-green': '#9de0ad',
    gossip: '#d6f4a4',
    orange: '#f89e29',
    'safron-mango': '#fabd63',
    'light-yellow': '#feff94',
    'golden-fizz': '#fdfa43',
    'light-red': '#cc7878',
    'light-gray': '#d4d4d4',
  },
  Trademap: {
    'brand-primary': '#0099FF',
    pink: '#F289DA',
    'purple:': '#9C00FF',
    'orange:': '#FE8F00',
    'red:': '#FF3F59',
    'yellow:': '#FFE000',
    'light-blue': '#B6D7ED',
    'light-yellow': '#FFF4A3',
    'light-gray': '#8DCC98',
    'dark-blue': '#003599',
  },
};

export const FLA_GRAPH_COLORS = {
  'theme-default': Object.values(FLA_COLORS.Valemobi) as Array<string>,
  'theme-poliface': Object.values(FLA_COLORS.Valemobi) as Array<string>,
  'theme-trademap': Object.values(FLA_COLORS.Trademap) as Array<string>,
  funds: [
    '#A1D0A9',
    '#FEEE5D',
    '#71A3D8',
    '#F18561',
    '#FABC64',
    '#547980',
    '#45ada8',
    '#9de0ad',
    '#7fa684',
    '#d6f4a4',
    '#ffff94',
    '#fffa43',
    '#febd62',
    '#ff9e29',
    '#1b4a53',
    '#83b8c0',
    '#80b24b',
    '#a2d376',
    '#b9ef7f',
    '#ea7a0a',
    '#c45d00',
    '#ed6348',
    '#ff8c7d',
  ],
};

export const TOOLTIP_STYLE = {
  backgroundColor: '#FFFFFF',
  borderColor: 'black',
  borderRadius: 4,
  borderWidth: 0,
  shape: 'rect',
};

export const AXIS_LABELS_STYLE = {
  color: 'var(--vm-neutral-smooth)',
};

export const AXIS_STYLE = {
  lineColor: 'var(--vm-neutral-medium)',
  gridLineColor: 'var(--vm-neutral-strong)',
};

export const LEGEND_DEFAULT_STYLE = {
  color: 'var(--vm-neutral-smooth)',
};
