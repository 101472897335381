import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableBookComponent } from './table-book.component';
import { TableBookHeaderComponent } from './parts/header/table-book-header.component';
import { ForceChartModule } from '../force-chart/force-chart.module';
import { SearchStockModule } from '../search-stock/search-stock.module';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { TabelBookContextMenuComponent } from './parts/table-book-context-menu/table-book-context-menu.component';
import { DetailsTabModule } from '../details-tab/details-tab.module';
import { FlaIconModule } from '@shared/rocket-components/components';
import { FlaBigNumberModule } from '@shared/rocket-components/pipes/big-number';
import { FlaFormatNumberModule } from '@shared/rocket-components/pipes/format-number';
@NgModule({
  declarations: [
    TableBookComponent,
    TableBookHeaderComponent,
    TabelBookContextMenuComponent,
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    ForceChartModule,
    SearchStockModule,
    FlaBigNumberModule,
    FlaWorkSpaceModule,
    FlaFormatNumberModule,
    FlaIconModule,
    DetailsTabModule,
  ],
  exports: [TableBookComponent, TableBookHeaderComponent],
})
export class TableBookModule {}
