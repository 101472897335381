export enum TYPE_VALIDADE_ENUM {
  TODAY = 0,
  UNTIL_THE_DAY = 6,
  VALID_UNTIL_CANCEL = 1,
  EXECUTE_OR_CANCEL = 3,
  EVERYTHING_OR_NOTHING = 4,
  AUCTION_OEPN = 'A',
  AUCTION_CLOSE = 7,
  AUCTION_LIMITED = 7,
}

export enum TYPE_ORDE_ENUM {
  LIMITADA,
  MARKET,
  LIMITED_MARKET,
  SALE,
  START_STOP,
  SALE_OF_CLOSE,
  DOUBLE_START_STOP,
  RESET_POSITION,
  INVERT_POSITION,
  SALE_OF_OPEN,
}

export enum STOCK_TRADE_ELEMENTS_ID {
  HEADER_ORDER_TYPE = 'STOCK_TRADE_HEADER_ORDER_TYPE',
  BOOK_CHARTS = 'STOCK_TRADE_BOOK_CHARTS',
  STOCK_SELECTOR = 'STOCK_TRADE_STOCK_SELECTOR',
  ORDER_TYPE = 'STOCK_TRADE_ORDER_TYPE',
  QUANTITY = 'STOCK_TRADE_QUANTITY',
  PRICE = 'STOCK_TRADE_PRICE',
  MARKET_PRICE = 'STOCK_TRADE_MARKET_PRICE',
  VALIDATE = 'STOCK_TRADE_VALIDATE',
  ORDER_OCO = 'STOCK_TRADE_ORDER_OCO',
  INFORMATIONS = 'STOCK_TRADE_INFORMATIONS',
  ORDER_STOP = 'STOCK_TRADE_ORDER_STOP',
  DOUBLE_STOP = 'STOCK_TRADE_DOUBLE_STOP',
}

export enum STOCK_TRADES_TYPE {
  BUY = 'boleta-compra',
  BUY_START_STOP = 'boleta-compra-start-stop',
  SELL = 'boleta-venda',
  SELL_START_STOP = 'boleta-venda-start-stop',
}
