<rocket-modal>
  <rocket-modal-header>
    <div class="d-flex flex-column">
      <h3 class="modal-title">Estratégia</h3>
      <span
        *ngIf="currentBodyContent === bodyContent.USER_STRATEGIES"
        class="text-muted fs-5"
      >
        Aqui você pode criar, editar ou remover suas estratégias de negociação.
      </span>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div>
      <ng-container [ngSwitch]="currentBodyContent">
        <ng-container *ngSwitchCase="bodyContent.USER_STRATEGIES">
          <div class="w-100 d-inline-flex">
            <div
              class="w-100 d-flex flex-column border-right border-neutral-strong"
              [style.max-width.px]="250"
              *ngIf="showAllStrategies"
            >
              <span class="fw-bold mb-3">Nome da estratégia</span>
              <div
                [style.max-height.px]="270"
                [ngClass]="{ 'overflow-y-scroll': strategies.length > 8 }"
              >
                <ng-container *ngFor="let strategy of strategies">
                  <div
                    class="w-100 mb-2 d-inline-flex align-items-center justify-content-between"
                  >
                    <div class="d-inline-flex align-items-center">
                      <label
                        for="strategyOption"
                        class="cursor-pointer text-truncate"
                        (click)="handleIconAction('EDIT', strategy)"
                        [style.max-width.px]="
                          strategy.idStrategy === idStrategyActive ? 160 : 205
                        "
                      >
                        <input
                          class="form-check-input mr-1"
                          type="checkbox"
                          name="strategyOption"
                          [ngModel]="
                            strategy.idStrategy === strategySelected?.idStrategy
                          "
                          [style.min-width.px]="18"
                        />
                        {{ strategy.nameStrategy }}
                      </label>

                      <div
                        *ngIf="strategy.idStrategy === idStrategyActive"
                        class="round bg-brand-primary p-1 mx-1 d-block fs-6 text-black"
                      >
                        Ativa
                      </div>
                    </div>
                    <rocket-button
                      type="icon"
                      (rocketClick)="handleIconAction('DELETE', strategy)"
                    >
                      <fla-icon name="delete" size="icon-size-micro"></fla-icon>
                    </rocket-button>
                  </div>
                </ng-container>
                <ng-container *ngIf="isCreatingStartegy">
                  <div class="mw-100 d-inline-flex mb-2">
                    <input
                      class="form-check-input cursor-pointer mr-2"
                      type="checkbox"
                      name="new_strategy"
                      [ngModel]="false"
                      [style.min-width.px]="18"
                      disabled
                    />
                    <span
                      class="cursor-pointer text-truncate"
                      [style.max-width.px]="179"
                    >
                      {{ form.get('nameStrategy')?.value }}
                    </span>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="w-100 px-3" [style.min-height.px]="305">
              <ng-container *ngIf="strategySelected || isCreatingStartegy">
                <form [formGroup]="form">
                  <fla-input
                    #inputToken
                    fla-focus
                    formControlName="nameStrategy"
                    placeholder="Digite o nome da sua estratégia."
                    margin="mb-3"
                    [requiredText]="strategyNameError"
                    [isRequired]="!form.get('nameStrategy')!.pristine"
                    [max]="70"
                    [maxLength]="70"
                  >
                  </fla-input>
                  <app-tick-strategy
                    [form]="form"
                    [groupForm]="groupForm"
                    (inputMaskChange)="inputMaskChange($event)"
                    (groupHandler)="groupHandler($event)"
                    (handleStrategyItemToDelete)="setItemToDelete($event)"
                  ></app-tick-strategy>
                </form>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="bodyContent.DELETE_STRATEGY">
          <span>
            Deseja deletar a estratégia
            <span class="fw-bold">{{ strategySelected!.nameStrategy }}</span>
            ?
          </span>
        </ng-container>
      </ng-container>
    </div>

    <div
      class="py-6 w-100 d-inline-flex align-items-center"
      [class]="buttonsSettings.class"
    >
      <div
        *ngIf="
          currentBodyContent === bodyContent.USER_STRATEGIES &&
          !data.newStrategy &&
          !data.onlyEdit
        "
        class="d-inline-flex"
      >
        <rocket-button
          label="Criar estratégia"
          (rocketClick)="handleIconAction('CREATE', null)"
        ></rocket-button>
      </div>

      <div class="d-inline-flex">
        <rocket-button
          type="fill"
          [label]="buttonsSettings.secundary"
          [isDisabled]="buttonsSettings.disableSecundary"
          (rocketClick)="cancelAction()"
        ></rocket-button>
        <rocket-button
          css="ml-2"
          [label]="buttonsSettings.submit"
          [isDisabled]="buttonsSettings.disableSubmit"
          (rocketClick)="saveStragies()"
        ></rocket-button>
      </div>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
