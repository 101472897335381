import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectionStrategy,
  signal,
  computed,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { SuperDomService } from './service/super-dom.service';
import { HomeService } from '@modules/home/service/home.service';
import { SuperDomHelper } from './super-dom.helper';
import {
  ORDER_CONFIRM_ACTIONS_ENUM,
  TYPE_ORDE_SIDE_ENUM,
} from '@shared/enum/buyOrSell.enum';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { ElementRef } from '@angular/core';
import { RocketCreateComponentService } from '@shared/rocket-components/services';
import { OrderChannel } from '@shared/channel/order.channel';
import { TYPE_ORDE_ENUM } from '../stock-trade/enum/stock-trade.enum';
import { CustodyChannel } from '@shared/channel/custody.channel';
import {
  IPosition,
  ISearchStock,
  IStock,
  IWorkSpaceComponet,
} from 'src/app/core/interface';
import { formatterNumber } from '@shared/rocket-components/utils';
import { SUPER_DOM_TOOLTIPS } from './constants/super-dom.constants';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import { Subject, Subscription, delay, filter, takeUntil } from 'rxjs';
import { IntrojsService } from '@core/introjs/introjs.service';
import { OrdersService } from '@shared/services/orders.service';
import { isSimulatorOrConsolidated } from '@shared/constants/simulator-league.constant';
import { BalanceService } from '@shared/services/api/trademap/v1/balance.service';
import { MultibrokerService } from '@shared/services/core/multibroker';
import { StockPreferencesService } from '@shared/services/stock-preferences.service';
import { SUPER_DOM_ELEMENT_ID } from './enum/super-dom.enum';
import { OriginAnalysisOrderService } from '@shared/services/origin-analysis-order.service';
import { ORDER_PARAM_HELPER } from '@shared/constants/order-param-helper.const';
import { system } from '@core/system/system.service';
import { getIncrementValue } from 'src/app/utils/utils.functions';
import { AUTH_LOCAL_KEYS } from '@shared/services/core/const/auth_util.const';
import { ActivatedRoute } from '@angular/router';
import { ContextMenuService } from '../popup-root/context-menu.service';
import { SuperDomContextMenuEvent } from './parts/context-menu/super-dom-context-menu.component';
import { ContextMenuEventPayload } from '../popup-root/context-menu-event.model';
import { isWebViewContext } from 'src/app/desktop/integration.utils';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';

@Component({
  selector: 'app-super-dom',
  templateUrl: './super-dom.component.html',
  styleUrls: ['./super-dom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuperDomComponent
  extends SuperDomHelper
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('superDom', { static: true }) superDom!: ElementRef;
  @Input() set component(component: IWorkSpaceComponet) {
    if (component) {
      this.headerComponent = component;
      this._component.set(component);
    }
  }
  @Input() refComponent: string = '';
  @Input() height!: string | number;
  @Input() width!: string | number;

  onAccountChange$!: Subscription;
  balance: number = 0;
  vlClose: number = 0;
  qttyChangeEnabled: boolean = false;
  qttyChangedColorEnabled: boolean = false;
  isDesktop = false;

  private qttyDayTradeMode = signal('');
  private _selectValues$ = new Subject<void>();
  private _getPreferencesStock$ = new Subject<void>();

  readonly SUPER_DOM_ELEMENT_ID = SUPER_DOM_ELEMENT_ID;
  orderParamHelper = ORDER_PARAM_HELPER;
  headerOptions = computed(() => {
    return this._component()!!.metadata?.headerOptions;
  });
  headerComponent!: IWorkSpaceComponet<any>;

  qttyPositionFormated = computed(() => {
    const pos = this.position();
    const position = pos
      ? +pos!![this.qttyDayTradeMode() as keyof IPosition]
      : undefined;
    return position
      ? formatterNumber(position, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })
      : '0';
  });

  qttyOpenFormated = computed(() => {
    const qtty = this.qttyOpenDayTradeMode();
    return qtty
      ? formatterNumber(qtty, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })
      : '0';
  });

  get componentStock() {
    return this._component()!!.metadata.component?.stock;
  }

  get metadataConfiguration() {
    return (
      this._component()!!.metadata?.configuration ?? {
        showPowerBar: true,
        showVolume: true,
        showDetail: true,
        showDetailCustody: true,
        isLineBlock: false,
        hideAggressor: true,
        showAtPrice: true,
        showColumnBook: false,
        showPosition: true,
      }
    );
  }

  set metadataConfiguration(data: any) {
    const config = { ...this.metadataConfiguration, ...data };
    this._component()!!.metadata.configuration = config;
    this.updateMetadata();
  }

  get labelMultibrokerBuy() {
    return +this.width < 334 ? 'CM' : 'Comprar a mercado';
  }

  get labelMultibrokerSell() {
    return +this.width < 334 ? 'VM' : 'Vender a mercado';
  }

  constructor(
    private _fb: FormBuilder,
    protected override superDomService: SuperDomService,
    private _homeService: HomeService,
    protected override quoteChannel: QuoteChannel,
    protected override createComponent: RocketCreateComponentService,
    protected override orderChannel: OrderChannel,
    protected override custodyChannel: CustodyChannel,
    protected override introJsService: IntrojsService,
    private _dayTradeService: DaytradeService,
    protected override ordersService: OrdersService,
    private _balanceService: BalanceService,
    private _stockPreferencesService: StockPreferencesService,
    private _multibrokerService: MultibrokerService,
    protected override cdr: ChangeDetectorRef,
    private _originAnalysisOrderService: OriginAnalysisOrderService,
    activatedRoute: ActivatedRoute,
    contextMenuService: ContextMenuService,
    private customPreferencesService: CustomPreferencesService
  ) {
    super(
      quoteChannel,
      createComponent,
      orderChannel,
      custodyChannel,
      introJsService,
      cdr,
      superDomService,
      ordersService,
      activatedRoute,
      contextMenuService
    );
    this.isDesktop = isWebViewContext();
    this.form = this._fb.group({
      qtd: new FormControl(undefined),
      price: new FormControl(undefined),
    });
    this.form.controls['qtd'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.qtdSig.set(
          parseFloat(
            this.form.controls['qtd'].value.toString().replace('.', '')
          )
        );
      });
    this.form.controls['price'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.priceSig.set(+this.form.controls['price'].value);
      });
    this.detectChangesSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.cdr.detectChanges();
    });
    this._stockPreferencesService
      .onChangePreferences()
      .pipe(
        takeUntil(this.destroy$),
        filter((data) => {
          return (
            data.key === AUTH_LOCAL_KEYS.USE_SPLIT ||
            data.key === AUTH_LOCAL_KEYS.SAVE_QTTY_GROUP_STOCKS
          );
        })
      )
      .subscribe(() => {
        this.getPreferencesStock();
      });
  }

  protected initComponent(component: any): void {
    if (component) this.component = component;
    this.startEventChangeAccount();
    this.attConfigVars();
    this.componentInit();
    this.onConfigUpdate()
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.onConfigUpdateHandler);

    this._selectValues$
      .pipe(
        takeUntil(this.destroy$),
        filter(() => {
          return (
            !this.componentStock ||
            this.componentStock.cd_stock !== this.stock?.cd_stock
          );
        }),
        delay(20)
      )
      .subscribe(() => {
        this.selectValues(this.componentStock, true);
      });

    this._getPreferencesStock$
      .pipe(takeUntil(this.destroy$), delay(500))
      .subscribe(() => {
        this.qttyChangeEnabled = true;
        this.detectChangesSubject.next();
      });

    system.tradeModeLabelsSubscription
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateTooltips();
      });

    this.subscribeCustodyChannel();

    this.contextMenuService
      .listenActionEvent<SuperDomContextMenuEvent>(this._component()!!.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((payload) => this._handleContextMenuEvent(payload));
  }

  ngAfterViewInit() {
    this._selectValues$.next();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.stock && this.unSubscribeCheetah();
    this.untilDestroy$ && this.untilDestroy$.unsubscribe();
    this.quoteCurrentSubscription?.close();
    this.custodyCurrentSubscription?.close();
  }

  private _handleContextMenuEvent(
    payload: ContextMenuEventPayload<SuperDomContextMenuEvent>
  ) {
    if (payload.action == SuperDomContextMenuEvent.Tour) {
      this.handleComponentTour();
      return;
    }

    switch (payload.action) {
      case SuperDomContextMenuEvent.Detail:
        this.showDetail = !this.showDetail;
        this.metadataConfiguration = { showDetail: this.showDetail };
        break;
      case SuperDomContextMenuEvent.CustodyDetail:
        this.showDetailCustody = !this.showDetailCustody;
        this.metadataConfiguration = {
          showDetailCustody: this.showDetailCustody,
        };
        break;
      case SuperDomContextMenuEvent.PowerBar:
        this.showPowerBar = !this.showPowerBar;
        this.metadataConfiguration = { showPowerBar: this.showPowerBar };
        break;
      case SuperDomContextMenuEvent.Volume:
        this.showColumnBook = false;
        this.showVolume = false;
        this.metadataConfiguration = {
          showColumnBook: this.showColumnBook,
          showVolume: this.showVolume,
        };
        break;
      case SuperDomContextMenuEvent.Aggressor:
        this.hideAggressor = !this.hideAggressor;
        this.metadataConfiguration = { hideAggressor: this.hideAggressor };
        break;
      case SuperDomContextMenuEvent.AtPrice:
        this.showAtPrice = !this.showAtPrice;
        this.metadataConfiguration = { showAtPrice: this.showAtPrice };
        break;
      case SuperDomContextMenuEvent.ColumnBook:
        this.showColumnBook = true;
        this.showVolume = true;
        this.metadataConfiguration = {
          showVolume: this.showVolume,
          showColumnBook: this.showColumnBook,
        };
        break;
      case SuperDomContextMenuEvent.ColumnBookLeft:
        this.showColumnBook = false;
        this.showVolume = true;
        this.metadataConfiguration = {
          showVolume: this.showVolume,
          showColumnBook: this.showColumnBook,
        };
        break;
      case SuperDomContextMenuEvent.Position:
        this.showPosition = !this.showPosition;
        this.metadataConfiguration = { showPosition: this.showPosition };
        break;
    }
    this.attConfigVars();
  }

  attConfigVars() {
    if (this.startedComponentTour) return;
    this.showDetail = this.metadataConfiguration.showDetail;
    this.showDetailCustody = this.metadataConfiguration.showDetailCustody;
    this.showPowerBar = this.metadataConfiguration.showPowerBar;
    this.showVolume = this.metadataConfiguration.showVolume;
    this.isLineBlock = this.metadataConfiguration.isLineBlock;
    this.hideAggressor = this.metadataConfiguration.hideAggressor;
    this.showAtPrice = this.metadataConfiguration.showAtPrice;
    this.showColumnBook = this.metadataConfiguration.showColumnBook;
    this.showPosition = this.metadataConfiguration.showPosition;
    this.detectChangesSubject.next();
  }

  onConfigUpdateHandler = (data: any) => {
    if (this.startedComponentTour) return;
    this.metadataConfiguration = data;
    this.attConfigVars();
  };

  private async componentInit() {
    this.refComp = this.refComponent;
    this.componentId = this._component()!!.id;
    this.superDom.nativeElement.oncontextmenu = this.showConfig;
    this._subscribeDayTradeMode();
    this.changeOrdersAndPositions();
    this.incrementValue = getIncrementValue(this.componentStock);

    this.height =
      this._component()!!.metadata.componentResize?.height ??
      parseInt(this._component()!!.metadata.layout.height);
    this.width =
      this._component()!!.metadata.componentResize?.width ??
      parseInt(this._component()!!.metadata.layout.width);
  }

  public handlePositionAction(
    action: ORDER_CONFIRM_ACTIONS_ENUM,
    origin: string
  ) {
    this._originAnalysisOrderService.setOriginOrder(origin);
    this.ordersService.handlePositionAction(
      this.account,
      this.stock,
      this.position(),
      action
    );
  }

  public sendOrder(
    type: TYPE_ORDE_ENUM,
    sideOrder: TYPE_ORDE_SIDE_ENUM,
    origin: string
  ): void {
    this._originAnalysisOrderService.setOriginOrder(origin);
    this.ordersService.prepareOrder(
      this.stock,
      {
        qtd: this.qtdSig(),
        price: this.form.get('price')!.value,
      },
      this.account,
      sideOrder,
      type
    );
  }

  public sendOrderBook = (order: any): void => {
    if (!order) {
      return;
    }
    const price =
      typeof order.price == 'number'
        ? order.price
        : order.price?.replace(',', '.');
    let offset;
    if (order.typeOrder === TYPE_ORDE_ENUM.START_STOP) {
      offset = {
        offset: this.customPreferencesService.tradingOffset,
        minPriceIncrement: this.stock.vlMinPriceIncrement,
      };
    }
    this.ordersService.prepareOrder(
      this.stock,
      { price: +price, qtd: order?.qtd },
      this.account,
      order.sideOrder,
      order.typeOrder,
      offset
    );
  };

  public selectValues = (
    stock: ISearchStock,
    isInit: boolean = false
  ): void => {
    this.isFirstTimeLastPrice = true;
    this.vlClose = stock?.stockVariation
      ? stock?.stockVariation.vl_close
      : +stock.last_price!;
    this.stock && this.hasQuoteSubscription && this.unSubscribeCheetah();
    this.cleanInfosVariations();
    this.stock = stock;
    this.incrementValue = getIncrementValue(this.stock);
    this.positions.clear();
    this.price = this.formatPrice(`${this.vlClose}`);
    this.tickSizeDenominator = stock.tick_size_denominator;
    this.priceMask = `separator.${this.tickSizeDenominator}`;
    this.standardLot = stock.standard_lot ?? 1;
    this.form.get('price')?.setValue(this.vlClose);
    this.stock && !this.hasQuoteSubscription && this.subscribeCheetah();
    !isInit && this.updateMetadataStock();
    this.getPreferencesStock();
    this.changeOrdersAndPositions();
    this.detectChangesSubject.next();
  };

  private changeOrdersAndPositions(): void {
    if (this.stock && this.account) {
      this.destroyObservable();
      this.searchPositionAndOrder();
      this.subscribeOrderChannel();
      this.custodyCurrentSubscription &&
        this.custodyCurrentSubscription.snapshot(
          this.custodyChannel.itemsArray
        );
    }
    this.detectChangesSubject.next();
  }

  private searchPositionAndOrder(): void {
    const key = this.positionKey;
    const positionData = this.positions.get(key);
    this.position.set(positionData);
    this.checkHasPositionByMode(positionData);
    this.processAndMountedOrders();
  }

  private processAndMountedOrders(): void {
    const orders = this.processOrders(this.stock);
    this.filterOrders(orders);
    this.changingValuesAccordingMode();
  }

  private changingValuesAccordingMode(): void {
    const qttyDayTradeMode = this.usingDayTradeMode
      ? 'qtty_final_daytrade'
      : 'qtty_final_swing';
    const qty = this.usingDayTradeMode
      ? +this.ordersOpenInDayTradeMode
      : +this.qttyOpen;
    const avgPriceField = this.usingDayTradeMode
      ? 'vl_price_avg_daytrade'
      : 'vl_price_avg_swing';

    this.qttyDayTradeMode.set(qttyDayTradeMode);
    this.qttyOpenDayTradeMode.set(qty);
    this.vlPriceAvgSig.set(avgPriceField);
    this.updateTooltips();
    this.cdr.detectChanges();
  }

  private updateTooltips = () => {
    const label = this.usingDayTradeMode ? 'ON' : 'OFF';

    this.invertAndResetTooltips = {
      invert: DaytradeService.getTextWithTradeMode(
        SUPER_DOM_TOOLTIPS[`INVERT_DAYTRADE_${label}`]
      ),
      reset: DaytradeService.getTextWithTradeMode(
        SUPER_DOM_TOOLTIPS[`RESET_DAYTRADE_${label}`]
      ),
    };
    this.detectChangesSubject.next();
  };

  private updateMetadataStock = () => {
    if (
      this.stock &&
      this._component()!!.metadata.component &&
      this._component()!!.metadata.component.stock.cd_stock ===
        this.stock.cd_stock
    ) {
      return;
    }
    this.updateMetadata();
  };

  private updateMetadata(): void {
    if (!this._component) return;
    this._component()!!.metadata.component = { stock: this.stock };
    this._homeService.updateMeta<IStock>(this._component()!!);
  }

  private _subscribeDayTradeMode(): void {
    this._dayTradeService.dayTradeMode
      .pipe(takeUntil(this.destroy$))
      .subscribe((isEnable: boolean) => {
        this.usingDayTradeMode = isEnable;
        this.changeOrdersAndPositions();
      });
  }

  private accountSelect = (detail: any): void => {
    if (detail && detail.id_broker) {
      this.account = detail;
      this.changeOrdersAndPositions();
      if (isSimulatorOrConsolidated(+detail.id_broker)) {
        this.balance = 0;
      } else {
        this._balanceService
          .getBalance(detail.id_broker)
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => (this.balance = res || 0));
      }
      this.detectChangesSubject.next();
    } else {
      console.error(
        'O SuperDom recebeu a conta com um valor inválido: ',
        detail
      );
    }
  };

  private startEventChangeAccount(): void {
    this._multibrokerService
      .onUpdateSelectedAccountChannel()
      .pipe(takeUntil(this.destroy$))
      .subscribe((account) => {
        this.accountSelect(account);
      });
  }

  private getPreferencesStock(): void {
    this.qttyChangeEnabled = false;
    this.qttyChangedColorEnabled = false;
    if (this._stockPreferencesService.saveQttyStockEnabled) {
      const value = this._stockPreferencesService.getStockSaved(
        this.stock.cd_stock_order,
        this.standardLot,
        'qtty'
      );
      this.form.get('qtd')?.setValue(value);
      if (value !== this.standardLot) {
        this.qttyChangedColorEnabled = true;
      }
      this.detectChangesSubject.next();
      this._getPreferencesStock$.next();
    } else {
      this.form.get('qtd')?.setValue(this.standardLot);
    }
  }

  public qttyChange(event: any) {
    if (!this.qttyChangeEnabled || !event.value) return;
    const qtd = event.value;
    this._stockPreferencesService.setStockQtty(
      this.stock.cd_stock_order,
      qtd,
      this.standardLot
    );
  }

  getPositionField(info: any): keyof IPosition {
    return this.usingDayTradeMode ? info.dayTradeField : info.field;
  }
}
