import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { THeatmapConfig } from '../heatmap-chart/types';
import { Dictionary } from '@core/models';
import { ListsService } from '../heatmap-chart/services/lists.service';
import { CHART_OPTIONS } from './constants/stock-table-heatmap.constants';
import { IMoversTransaction } from '../stock-table/interfaces/stock-table.interfaces';
import { StockListRowModel } from '../stock-table/models/stock-table-row.model';
import { QuoteData } from '@shared/channel/interface/quote.channel.interface';
import { Subject, auditTime, filter, map, takeUntil } from 'rxjs';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { isMoversList } from '../stock-table/constants/stock-table.constants';

@Component({
  selector: 'app-stock-table-heatmap',
  template: `
    <div class="h-100 w-100">
      <ng-container *ngIf="haveStocksInList || isMovers; else withoutStocks">
        <app-heatmap-chart
          [refComponent]="refComponent"
          [width]="width"
          [height]="height"
          [heatmapConfigs]="heatmapConfigs"
          [useVolumeInMoversValue]="isMovers"
          (chartLoaded)="onHeatmapLoad($event)"
        ></app-heatmap-chart>
      </ng-container>
      <ng-template #withoutStocks>
        <span
          class="ag-theme-alpine-dark w-100 position-absolute d-block text-center center-empty-message"
        >
          {{ noDataTemplate }}
        </span>
      </ng-template>
    </div>
  `,
  styles: [
    `
      .center-empty-message {
        top: 50%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockTableHeatmapComponent implements OnChanges, OnDestroy {
  @Input() public refComponent!: string;
  @Input() public idStockList!: number;
  @Input() public handlerStockListByCommand!: any;
  @Input() set updateStock(updateStock: any) {
    !!updateStock && this._updateStockInfos(updateStock);
  }
  @Input() public width!: number;
  @Input() public height!: number;
  @Input() public linked: boolean = false;
  @Input() public isNotListPersonal: boolean = false;
  @Input() public stockList!: any[];
  @Input() public clearStocks: boolean = false;
  @Input() public noDataTemplate!: string;

  private _chart!: Highcharts.Chart | undefined;
  private _stocks = new Dictionary<StockListRowModel>();
  private _onDestroy = new Subject<void>();
  private _updateChartDataSubject = new Subject<void>();

  public heatmapConfigs: THeatmapConfig = {
    orderBy: 'volume',
    period: 'ONEDAY',
    source: undefined,
  };
  public isMovers: boolean = false;
  public haveStocksInList: boolean = false;

  constructor(
    private _listsService: ListsService,
    private _cdr: ChangeDetectorRef,
    private _globalStock: GlobalSelectedStockSubscription
  ) {
    this._listsService.onClick
      .pipe(
        takeUntil(this._onDestroy),
        filter(
          (params) => this.linked && params.refComponent === this.refComponent
        ),
        map((params) => params.event.point.options.custom)
      )
      .subscribe((stock) => {
        const globalStock = this._globalStock.getGlobalStockSelected();
        if (stock && stock['cd_stock'] !== globalStock?.cd_stock)
          this._globalStock.researchToStandardizeGlobalStock(stock);
      });

    this._updateChartDataSubject
      .pipe(
        takeUntil(this._onDestroy),
        filter(() => this.haveStocksInList && this._chart !== undefined),
        auditTime(500)
      )
      .subscribe(() => this._updateChartData());
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { idStockList, handlerStockListByCommand, stockList, clearStocks } =
      changes;
    if (clearStocks?.currentValue) this._clearStocks();
    if (stockList?.currentValue) this._setSnapshot(stockList.currentValue);
    if (idStockList?.currentValue)
      this._onStockListChange(idStockList.currentValue);
    if (handlerStockListByCommand?.currentValue)
      this._commandHandler(handlerStockListByCommand.currentValue);
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private _clearStocks(): void {
    if (this._stocks.size()) {
      this.haveStocksInList = false;
      this._stocks.clear();
      this._cdr.detectChanges();
    }
  }

  public onHeatmapLoad(chart: Highcharts.Chart): void {
    this._chart = chart;
    this._cdr.detectChanges();
    if (this.idStockList) {
      const isMover = this._verifyIsMoverList(this.idStockList);
      this._configChart(isMover);
      if (isMover) return;
    }
    this._updateChartDataSubject.next();
  }

  private _onStockListChange(idStockList: number): void {
    this.isMovers = isMoversList(idStockList);
    this.heatmapConfigs.idList = idStockList;
    this.heatmapConfigs.orderBy = 'volume';
    this._cdr.detectChanges();
    if (!this._chart) return;
    this._configChart(this._verifyIsMoverList(idStockList));
  }

  private _verifyIsMoverList(idList: number): boolean {
    const isMovers = isMoversList(idList);
    this.isMovers = isMovers;
    this.haveStocksInList = true;
    this._cdr.detectChanges();
    if (!isMovers) return false;
    this.heatmapConfigs.orderBy =
      idList === 1000000003 ? 'volume' : 'variation';
    return true;
  }

  private _configChart(isMovers: boolean): void {
    if (isMovers) {
      this._configAndInitializeMoversChart();
      return;
    }
  }

  private _configAndInitializeMoversChart = (): void => {
    this.heatmapConfigs.isNotListPersonal = true;
    this._updateChartDataSubject.next();
  };

  private _updateChartData = (): void => {
    if (!this._chart || !this._chart.series?.length) return;
    this._listsService.plotData(
      this._chart,
      this._stocks,
      this.refComponent,
      !this.heatmapConfigs.isNotListPersonal,
      CHART_OPTIONS,
      this.heatmapConfigs.orderBy,
      this.heatmapConfigs.period,
      this.isMovers
    );
  };

  private _updateStockInfos(stock: QuoteData): void {
    stock.variacao_dia = +this._variationValue(stock);
    this._stocks.set(stock.item!, <any>stock);
    this._cdr.detectChanges();
    this._updateChartDataSubject.next();
  }

  private _commandHandler(commands: IMoversTransaction): void {
    if (commands.add.length) {
      const value = commands.add[0];
      value.variacao_dia = value.variacao;
      this._stocks.set(value.idRow, <any>value);
      this._cdr.detectChanges();
      this._updateChartDataSubject.next();
      return;
    }

    if (commands.update.length) {
      const value = commands.update[0];
      value.variacao_dia = value.variacao;
      this._stocks.set(value.idRow, <any>value);
      this._cdr.detectChanges();
      this._updateChartDataSubject.next();
      return;
    }

    if (commands.remove.length) {
      this._stocks.delete(commands.remove[0].idRow);
      this._cdr.detectChanges();
      this._updateChartDataSubject.next();
      return;
    }
  }

  private _setSnapshot(stocks: StockListRowModel[]): void {
    this._clearStocks();
    if (!stocks?.length) return;
    for (let i = 0; i < stocks.length; i++) {
      stocks[i].variacao_dia = +this._variationValue(stocks[i]);
      this._stocks.set(stocks[i].idRow, stocks[i]);
    }
    this.haveStocksInList = true;
    this._cdr.detectChanges();
    this._updateChartData();
  }

  private _variationValue(stock: any): string {
    if (stock.variacao_dia) return stock.variacao_dia;
    if (stock.variacao) return stock.variacao;
    return '0';
  }
}
