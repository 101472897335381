import {
  ColDef,
  GridApi,
  RowClassParams,
  RowClassRules,
} from 'ag-grid-community';
import {
  OPTIONS_CALLPUT_CONFIG_HEADERS,
  OPTIONS_CONTEXT_MENU_ENUM,
  OPTIONS_TABLE_CONFIG_HEADERS,
} from '../../types';
import { GridIColumnVisible } from '@shared/rocket-grid';
import { RocketGridComponent } from '@shared/rocket-grid/rocket-grid.component';
import { OptionsListPreferencesModalComponent } from './list-preferences-modal/options-list-preferences-modal.component';
import { AUTH_LOCAL_KEYS } from '@shared/services/core/const/auth_util.const';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { RocketModalService } from '@shared/rocket-components/components';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { RocketCreateComponentService } from '@shared/rocket-components/services';
import { OptionsComponentService } from '../../options-component.service';
import { Subject, Subscription, debounceTime, filter, tap } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import { OptionsTableContextMenuComponent } from '../../config/options-table-context-menu.component';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';
import { ContextMenuEventPayload } from '@shared/components/popup-root/context-menu-event.model';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { OptionsService } from '../../options.service';

export class OptionsListHelper {
  linked: boolean = false;
  columnDefs: Array<ColDef> = [];
  ready = false;
  columnVisible!: GridIColumnVisible;
  gridApi!: GridApi<any>;
  grid!: RocketGridComponent | undefined;
  configKey!: string;
  subheaderTab = '';
  _width!: number;
  callWidth = 0;
  callWidthWithScroll = 0;
  putWidthWithScroll = 0;
  putWidth = 0;
  gridScroll = 0;
  componentId = '';
  private _columnsQtty = 0;
  private onSelectOption$!: Subscription;
  private _onSettingsSubscription!: Subscription;
  private _contextMenu$!: Subscription;
  private _onResizeSubject = new Subject<void>();
  private _lastElementObserved!: HTMLElement;
  private _resizeObsRef!: ResizeObserver;

  get defaultTableConfigs() {
    return this.subheaderTab == 'table'
      ? OPTIONS_TABLE_CONFIG_HEADERS
      : OPTIONS_CALLPUT_CONFIG_HEADERS;
  }

  constructor(
    protected _themeService: ThemePreferencesService,
    protected _modalService: RocketModalService,
    protected _customPreferencesService: CustomPreferencesService,
    protected _createComponent: RocketCreateComponentService,
    protected _optionsComponentService: OptionsComponentService,
    protected _cdr: ChangeDetectorRef,
    protected contextMenuService: ContextMenuService,
    private _globalStock: GlobalSelectedStockSubscription,
    private optionsService: OptionsService
  ) {}

  onGridReady = (gridApi: any) => {
    this.gridApi = gridApi;
    this._resizeTable();
  };

  onColumnMoved(event: any) {
    const columns: ColDef[] = [];
    event.forEach((column: ColDef) => {
      const { colId } = column;

      if (!colId || colId == 'id_stock' || +colId > -1) return;

      const colDef = this.columnDefs.find((col: any) => col.field == colId);
      if (!colDef) return;

      columns.push(colDef);
    });

    this.columnDefs = columns;
    this._setFixedColumns();
    this._calcColumnsQtty();
    this._saveConfig();
  }

  onColumnResized(event: { colId: string | number; actualWidth: number }) {
    const { colId, actualWidth } = event;
    if (Number.isInteger(+colId)) return;

    const columns: any[] = [];
    this.columnDefs.forEach((column: ColDef) => {
      const { field, width, hide, headerTooltip, headerName } = column;
      if (!field || field == 'id_stock' || +field > -1) return;

      if (field == colId) {
        column.width = actualWidth;
        columns.push({
          field,
          width: actualWidth,
          hide,
          headerTooltip,
          headerName,
        });
        return;
      }

      columns.push({ field, width, hide, headerTooltip, headerName });
    });

    this._saveConfig(columns);
  }

  protected _initiateConfigs() {
    const configs = this._getConfigs();

    this._initiateColumns(configs?.[this.subheaderTab]?.columns);

    this._contextMenu$ = this.contextMenuService
      .listenActionEvent<OPTIONS_CONTEXT_MENU_ENUM>(this.componentId)
      .subscribe(
        (payload: ContextMenuEventPayload<OPTIONS_CONTEXT_MENU_ENUM>) => {
          const { action } = payload;
          action == OPTIONS_CONTEXT_MENU_ENUM.PREFERENCES &&
            this._showPreferencesModal();
          action == OPTIONS_CONTEXT_MENU_ENUM.RESET && this._resetPreferences();
          this._cdr.detectChanges();
        }
      );

    this._onSettingsSubscription =
      this._optionsComponentService.onSettingsUpdate$.subscribe(
        (settings: any) => this._updateColumns(settings)
      );
    this.onSelectedOptionSubscribe();
  }

  protected onDestroy() {
    this._onSettingsSubscription && this._onSettingsSubscription.unsubscribe();
    this._contextMenu$ && this._contextMenu$.unsubscribe();
    this._resizeObsRef.unobserve(this._lastElementObserved);
    this._onResizeSubject.unsubscribe();
    this.onSelectOption$ && this.onSelectOption$.unsubscribe();
  }

  protected _initiateColumns(columns?: any) {
    if (!columns) {
      this.columnDefs = Object.values(this.defaultTableConfigs);
    } else {
      this.columnDefs = columns.map((column: ColDef<any>) => {
        const index = column.field as string;
        const { width, hide } = column;
        return { ...this.defaultTableConfigs[index], width, hide };
      });
    }
    this._setFixedColumns();
    this._calcColumnsQtty();
    this.updateHeaderGroupsWidth();
  }

  protected _getConfigs(ignoreKey?: boolean) {
    const configs = JSON.parse(
      this._customPreferencesService.getCustomPreference(
        AUTH_LOCAL_KEYS.OPTIONS_CONFIGURATION
      )
    );

    if (!configs) return null;

    if (!ignoreKey && configs.key !== this.configKey) {
      this._resetPreferences();
      return null;
    }

    return configs;
  }

  protected _saveConfig(newColumns?: any[], ignoreKey?: boolean) {
    if (!this.ready) return;

    const columns = this._getColumnsConfigs(newColumns);
    const configs = this._getConfigs(ignoreKey);

    this._customPreferencesService.customPreference = {
      key: AUTH_LOCAL_KEYS.OPTIONS_CONFIGURATION,
      value: JSON.stringify({
        ...configs,
        [this.subheaderTab]: { columns },
        key: this.configKey,
      }),
    };
    this.updateHeaderGroupsWidth();
  }

  protected _getColumnsConfigs(newColumns?: any[]) {
    const columns: any[] = newColumns || [];
    if (!newColumns) {
      this.columnDefs.forEach((column: ColDef) => {
        const { field, width, hide, headerTooltip, headerName } = column;
        if (!field || field == 'id_stock' || +field > -1) return;

        columns.push({ headerTooltip, headerName, field, width, hide });
      });
    }

    return columns;
  }

  protected _showConfig = (event: any) => {
    OptionsTableContextMenuComponent.openContextMenu(
      this.contextMenuService,
      this.componentId,
      { clientX: event.pageX, clientY: event.pageY }
    );
    return false;
  };

  protected _showPreferencesModal() {
    const configs = this._getConfigs();
    const columns = this._getColumnsConfigs(
      configs?.[this.subheaderTab]?.columns
    );

    this._modalService.open(OptionsListPreferencesModalComponent, {
      centered: true,
      backdrop: true,
      keyboard: true,
      scrollable: true,
      size: 'xxl',
      data: { columns, tab: this.subheaderTab, configKey: this.configKey },
    });
  }

  protected _updateColumns = (settings: any) => {
    if (!this.grid) return;
    const hidden: string[] = [];
    const visible: string[] = [];

    settings.columns.forEach((column: any) => {
      const index = this.columnDefs.findIndex(
        (colDef: ColDef) => colDef.field == column.field
      );

      if (this.columnDefs[index]) {
        this.columnDefs[index].hide = column.hide;
        this.columnDefs[index].width = column.width;
      }

      if (column.hide) {
        hidden.push(column.field);
        return;
      }

      visible.push(column.field);
    });

    this.grid.setColumnsVisible({
      keys: hidden,
      visible: false,
    });
    this.grid.setColumnsVisible({
      keys: visible,
      visible: true,
    });
    this.updateHeaderGroupsWidth();
    this._onResizeSubject.next();
  };

  protected _resetPreferences = () => {
    const columns = Object.values(this.defaultTableConfigs);
    this.gridApi.setColumnDefs(columns);
    this._saveConfig(columns, true);
    this._updateColumns({ columns });
    this._onResizeSubject.next();
  };

  private _setFixedColumns() {
    const fixedHeaders = this.optionsService.getFixedTableConfigs(
      this.subheaderTab,
      this._width
    );
    if (fixedHeaders && fixedHeaders.length) {
      this.columnDefs.push(fixedHeaders[0]);
      this.columnDefs.push(fixedHeaders[1]);
    }
  }

  protected updateHeaderGroupsWidth() {
    let calls = 0;
    let strikePrice = 0;
    this.columnDefs.forEach((colDef: ColDef) => {
      if (colDef.hide) return;
      if (colDef.field?.endsWith('_call')) {
        calls += colDef.width || 0;
        return;
      }
      colDef.field == 'strike_price' && (strikePrice = colDef.width || 0);
    });
    this.callWidth = calls;
    this.putWidth = this._width - strikePrice - calls;

    this.callWidthWithScroll = this.callWidth - this.gridScroll;
    this.putWidthWithScroll = this.putWidth + this.gridScroll;
    this._cdr.detectChanges();
  }

  rowClassRule: RowClassRules = {
    'bg-brand-primary': (params: RowClassParams) =>
      this.subheaderTab != 'table' &&
      !params.data.cd_stock_call &&
      !params.data.cd_stock_put,
  };

  private _calcColumnsQtty(): void {
    this._columnsQtty = this.columnDefs.filter((c) =>
      this._shouldCountCol(c)
    ).length;
  }

  private _shouldCountCol(column: ColDef): boolean {
    const defaultComparation =
      column.resizable !== false && !column.hide && !column.suppressSizeToFit;
    if (this.subheaderTab == 'table') return defaultComparation;

    return (
      defaultComparation &&
      Boolean(
        column.field && !['option_last_cell', 'id_stock'].includes(column.field)
      )
    );
  }

  private _detectResizeEvents(): void {
    this._onResizeSubject
      .pipe(
        filter(() => this.columnDefs?.length > 0),
        debounceTime(700),
        tap(() => {
          this.columnDefs = this.columnDefs.filter(
            (c) =>
              c.field && !['option_last_cell', 'id_stock'].includes(c.field)
          );
          this._cdr.detectChanges();
        })
      )
      .subscribe(() => {
        const width = document.getElementById(this.componentId)!.offsetWidth;
        this.gridApi.sizeColumnsToFit({
          defaultMinWidth: Math.floor(width / this._columnsQtty),
        });
        this.columnDefs = this.gridApi.getColumnDefs() as ColDef[];
        this._setFixedColumns();
        this.updateHeaderGroupsWidth();
      });
  }

  private _resizeTable() {
    const container = document.getElementById(this.componentId);
    if (!container) return;
    if (this._lastElementObserved) {
      this._resizeObsRef.unobserve(this._lastElementObserved);
      this._onResizeSubject.unsubscribe();
      this._onResizeSubject = new Subject<void>();
    }
    this._resizeObsRef = new ResizeObserver(() => this._onResizeSubject.next());
    this._lastElementObserved = container;
    this._resizeObsRef.observe(container);
    this._detectResizeEvents();
  }

  private onSelectedOptionSubscribe = () => {
    this.onSelectOption$ =
      this._optionsComponentService.onSelectOption$.subscribe((data) => {
        const { type, option } = data;
        const cdStock = option[`cd_stock_${type}`] ?? option.cd_stock;
        this.linked &&
          this._globalStock.researchToStandardizeGlobalStock(
            { cd_stock: cdStock, id_exchange: 1 },
            true
          );
      });
  };
}
