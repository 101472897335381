import { XyDataSeries } from 'scichart/Charting/Model/XyDataSeries';
import { TTigerChartIndicatorCreateOptions } from './indicators.types';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { TData, TUpdateData } from '../types/tiger-chart.types';
import { FastColumnRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastColumnRenderableSeries';
import { OhlcDataSeries } from 'scichart/Charting/Model/OhlcDataSeries';
import { VolumePaletteProvider } from '../colors';
import { CANDLE_IDS } from '../constants/tiger-chart.constants';
import { StockChartModalService } from '@shared/components/stock-chart/parts/modal-more-options/service/stock-chart-modal.service';
import { ISearchStock } from '@core/interface';

export class TigerChartIndicatorVolume {
  protected stock!: ISearchStock;
  protected dataSeries!: XyDataSeries;
  protected candleDataSeries!: OhlcDataSeries;
  protected renderableSeries!: BaseRenderableSeries;
  protected data: TData;

  get isVisible(): boolean {
    return this.renderableSeries.isVisible;
  }

  set isVisible(visible: boolean) {
    this.renderableSeries.isVisible = visible;
  }

  private get isBMF(): boolean {
    return this.stock.ds_type === 'BMF';
  }

  constructor(
    data: TData,
    candleDataSeries: OhlcDataSeries,
    stock: ISearchStock,
    private stockChartModalService: StockChartModalService
  ) {
    this.stock = stock;
    this.data = data;
    this.candleDataSeries = candleDataSeries;
  }

  create(options: TTigerChartIndicatorCreateOptions): void {
    this.dataSeries = new XyDataSeries(options.base.wasmContext, {
      yValues: this.data.vl_volume,
      xValues: this.data.id_point,
    });

    this.renderableSeries = new FastColumnRenderableSeries(
      options.base.wasmContext,
      {
        strokeThickness: 0,
        yAxisId: options.yAxisId,
        dataSeries: this.dataSeries,
        paletteProvider: new VolumePaletteProvider(
          this.stockChartModalService,
          this.candleDataSeries,
        ),
        id: CANDLE_IDS.VOLUME_SERIES,
        xAxisId: options.xAxisId,
      }
    );

    options.base.sciChartSurface.renderableSeries.add(this.renderableSeries);
  }

  append(xValue: number, data: TUpdateData, fullData: TData) {
    this.data = fullData;
    const value = this.getVolumeValue(data);
    this.dataSeries.append(xValue, value);
  }

  insertRange(data: TData, fullData: TData): void {
    this.data = fullData;
    const value = this.getVolumeValue(data);
    this.dataSeries.insertRange(0, data.id_point, value);
  }

  changeVisibility(): void {
    this.renderableSeries.isVisible = !this.renderableSeries.isVisible;
  }

  update(index: number, data: TUpdateData, fullData: TData) {
    if (!this.dataSeries) {
      return;
    }
    this.data = fullData;
    const count = this.dataSeries.count() - 1;
    const value = this.getVolumeValue(data);
    if (index > count) {
      this.dataSeries.append(data.id_point, value);
      return;
    }
    this.dataSeries.update(index, value);
  }

  getLastVolume(): number {
    const values = this.getVolumeValue(this.data);
    return values[values.length - 1];
  }

  getVolumeByIndex(index: number): number {
    const values = this.getVolumeValue(this.data);
    return values[index];
  }

  private getVolumeValue(data: any): any {
    return this.isBMF ? data.qtty_shares_traded : data.vl_volume;
  }
}
