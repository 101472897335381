<rocket-modal>
  <rocket-modal-header>
    <h2 class="modal-title">Configurações</h2>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div class="row pt-3" *ngIf="tabSelected">
      <div
        class="col-auto mb-5"
        *ngFor="let tab of orderHistoryPreferences"
        [style.width.%]="tab.width"
      >
        <div
          class="position-relative border border-brand-primary round px-2 py-3"
        >
          <div
            class="bg-dark fw-bold position-absolute pr-2 round-sm position-top d-flex"
          >
            <app-check
              css="cursor-pointer"
              [isChecked]="tab.allCheck"
              (flaChange)="toggleAllTabContent($event)"
            ></app-check>
            <label class="ml-2">
              {{ tab.label }}
            </label>
          </div>
          <div
            *ngFor="let tabColumn of tab.columns"
            class="d-inline-block"
            [style.width.px]="tabColumn.width"
          >
            <app-check
              css="cursor-pointer"
              [label]="tabColumn.label"
              [isChecked]="tabSelected.has(tabColumn.key)"
              [inline]="true"
              [disabled]="
                this.tabSelected.size === 1 && tabSelected.has(tabColumn.key)
              "
              (flaChange)="setVisibleOrInvisible($event, tabColumn.key)"
            ></app-check>
          </div>
        </div>
      </div>
    </div>
  </app-rocket-modal-body>

  <rocket-modal-footer>
    <rocket-button
      type="fill"
      label="Cancelar"
      css="mr-4"
      [isSmall]="true"
      (rocketClick)="closeModal()"
    ></rocket-button>
    <rocket-button
      label="Salvar"
      [isSmall]="true"
      (rocketClick)="confirmChanges()"
    ></rocket-button>
  </rocket-modal-footer>
</rocket-modal>
