import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@rocket-ui/components/modal';
import { OrsGatewayService } from '@shared/services/api/trademap/v1/ors-gateway.service';
import { Subject, Subscription, auditTime, delay, filter, tap } from 'rxjs';
import { BODY_CONTENT, STRATEGY_TYPE_LABEL } from '../enum/strategy.enum';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IStrategy, IStrategyCondition } from '../interface/strategy.interface';
import { StrategyService } from '../service/strategy.service';
import { InputMask } from '../types/strategy.types';

@Component({
  selector: 'app-modal-strategies',
  templateUrl: './modal-strategies.component.html',
  styles: [
    `
      .cursor-pointer {
        cursor: pointer !important;
      }

      .default-cursor {
        cursor: default !important;
      }

      :host(app-modal-strategies) {
        app-input-count {
          ::ng-deep.suffix {
            padding-right: 0px !important;
          }
        }

        .btn-toggle::before {
          background-color: #ffffff;
        }

        ::ng-deep.invalid-quantity {
          .group-suffix-prefix.form-control,
          .suffix {
            border-color: var(--vm-feedback-error) !important;
          }
        }

        ::ng-deep fla-input {
          .input-group {
            .prefix {
              padding-bottom: 2px;
            }
          }
        }
      }
    `,
  ],
})
export class ModalStrategiesComponent
  extends RocketModalRef
  implements OnInit, OnDestroy
{
  @ViewChild('inputToken', { static: false }) public inputToken!: any;
  groupForm!: FormArray<any> | undefined;
  private _strategyItemsToDelete: number[] = [];
  private addStrategyItems$ = new Subject<any>();
  public strategies: IStrategy[] = [];

  public strategySelected!: IStrategy | null;

  public idStrategyActive: number = 0;
  public indexInvalidQuantity: number | null | undefined = null;

  public isSecondContentModal: boolean = false;
  public disableAddCondition: boolean = false;
  public isCreatingStartegy: boolean = false;
  public showAllStrategies: boolean = true;
  public nameStrategyControlValidators: boolean = false;
  public _subscriptions$!: Subscription[];
  public form!: FormGroup;

  public readonly bodyContent = BODY_CONTENT;
  public currentBodyContent: BODY_CONTENT = BODY_CONTENT.USER_STRATEGIES;

  public strategyNameError: string = 'Informe o nome da sua estratégia';
  public inputMask: InputMask = '';
  public decidePosition: 'all' | 'suffix' = 'suffix';

  private isSave: boolean = false;
  private strategyEdit!: any;
  public buttonsSettings = {
    submit: 'Salvar',
    disableSubmit: true,
    secundary: 'Cancelar',
    disableSecundary: false,
    class: 'justify-content-between',
  };

  get groups(): FormArray {
    return this.form.get('items') as FormArray;
  }

  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: any,
    _rocketModalService: RocketModalService,
    private _orsGatewayService: OrsGatewayService,
    private _formBuilder: FormBuilder,
    private _strategyService: StrategyService
  ) {
    super(_rocketModalService);
  }

  ngOnInit() {
    this._buildStrategyForm();
    this._findStrategies();
    this.showAllStrategies = this.data.showAllStrategies;
    this.addStrategyItems$
      .pipe(
        auditTime(100),
        filter((data) => data),
        tap(() => {
          this.groupForm = undefined;
        }),
        delay(100)
      )
      .subscribe((data) => {
        const { disableAddCondition, indexInvalidQuantity, groups } = data;
        this.form.get('items')?.setValue(groups.value);
        this.disableAddCondition = disableAddCondition;
        this.indexInvalidQuantity = indexInvalidQuantity;
        this.groupForm = groups;
        this.form.updateValueAndValidity();
      });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.addStrategyItems$.unsubscribe();
  }

  private _focusOnFieldText(): void {
    setTimeout(() => {
      document.getElementById(this.inputToken.refId)?.focus();
    }, 400);
  }

  private _setSelectedStrategy(): void {
    if (this.data.selectedStrategy) {
      this.idStrategyActive = this.data.selectedStrategy.idStrategy;
      return;
    }
    const strategy = this._strategyService.findActiveStrategy();
    if (!strategy) return;
    this.idStrategyActive = strategy.idStrategy;
  }

  private _buildStrategyForm = (): void => {
    this.form = this._formBuilder.group({
      idStrategy: [0, []],
      nameStrategy: ['', []],
      typeStrategy: ['', [Validators.required]],
      items: this._formBuilder.array([]),
    });

    const formSubscription$ = this.form.valueChanges.subscribe((form) => {
      const nameStrategyControl = this.form.get('nameStrategy');
      if (
        !nameStrategyControl?.pristine &&
        !this.nameStrategyControlValidators
      ) {
        this.nameStrategyControlValidators = true;
        nameStrategyControl?.setValidators([
          Validators.required,
          Validators.maxLength(70),
        ]);
        nameStrategyControl?.updateValueAndValidity();
      }
      this.buttonsSettings.disableSubmit =
        !form.nameStrategy ||
        !form.typeStrategy ||
        Boolean(form.nameStrategy.length > 70) ||
        Number.isInteger(this.indexInvalidQuantity);

      this.strategyNameError =
        form.nameStrategy && form.nameStrategy.length > 70
          ? 'O nome da estratégia pode conter até 70 caracteres.'
          : 'Informe o nome da sua estratégia';
    });

    this._subscriptions$ = [formSubscription$];
  };

  private _resetForm(): void {
    this._strategyItemsToDelete = [];
    this.groups.controls = [];
    this.form && this.form.reset();
  }

  private _findStrategies(): void {
    if (!this.data.newStrategy) {
      this._setSelectedStrategy();
      const allStrategies$ = this._orsGatewayService
        .getAllStrategies()
        .pipe((strategy) => strategy)
        .subscribe((strategies: IStrategy[]) => {
          if (strategies && strategies.length) {
            this.strategies = strategies.map((strategy) => {
              strategy.selected = false;
              STRATEGY_TYPE_LABEL[strategy.strategyType];
              if (strategy.idStrategy === this.idStrategyActive) {
                strategy.selected = true;
                this.strategySelected = strategy;
              }
              return strategy;
            });
            this.handleIconAction('EDIT', this.strategySelected);
            return;
          }
          this.strategies = [];
          this.handleIconAction('CREATE');
        });
      this._subscriptions$ = [...this._subscriptions$, allStrategies$];
    } else {
      this.strategies = [];
      this.handleIconAction('CREATE');
    }
  }

  public handleIconAction(
    action: 'CREATE' | 'EDIT' | 'DELETE',
    strategy?: IStrategy | null
  ): void {
    strategy && (this.strategySelected = strategy);
    this._changeButtonParams(action);
    this.isCreatingStartegy = action === 'CREATE';
    if (['CREATE', 'EDIT'].includes(action)) {
      this._resetForm();
      this.currentBodyContent = BODY_CONTENT.USER_STRATEGIES;
      this.disableAddCondition = false;
      if (action === 'CREATE') {
        this.addStrategyItems();
        this._focusOnFieldText();
      }
      action === 'EDIT' && this._setFormData();
    } else if (action === 'DELETE') {
      this.currentBodyContent = BODY_CONTENT.DELETE_STRATEGY;
    }
  }

  private _changeButtonParams(action: 'CREATE' | 'EDIT' | 'DELETE'): void {
    if (['CREATE', 'EDIT'].includes(action)) {
      this.buttonsSettings = {
        submit: 'Salvar',
        disableSubmit: action === 'CREATE',
        secundary: 'Cancelar',
        disableSecundary: false,
        class:
          this.data.newStrategy || this.data.onlyEdit
            ? 'justify-content-end'
            : 'justify-content-between',
      };
    } else if (action === 'DELETE') {
      this.buttonsSettings = {
        submit: 'Deletar',
        disableSubmit: false,
        secundary: 'Cancelar',
        disableSecundary: false,
        class: 'justify-content-end',
      };
    }
  }

  private _setFormData(): void {
    if (!this.strategySelected || this.data.newStrategy) return;
    this.form.get('typeStrategy')?.setValue(this.strategySelected.strategyType);
    this.form.get('idStrategy')?.setValue(this.strategySelected.idStrategy);
    this.form.get('nameStrategy')?.setValue(this.strategySelected.nameStrategy);
    const { decidePosition, inputMask } =
      this._strategyService.changeStrategyType(
        this.strategySelected.strategyType,
        this.form
      );
    this.decidePosition = decidePosition;
    this.inputMask = inputMask;
    this.form.markAsTouched();
    this.strategySelected.strategyItems.forEach(
      (strategyItem: IStrategyCondition) => {
        this.addStrategyItems(strategyItem);
      }
    );
  }

  private addStrategyItems(defaultValues?: IStrategyCondition) {
    const info = this._strategyService.addStrategyItems(
      this.disableAddCondition,
      this.groups,
      this._formBuilder,
      this.inputMask,
      defaultValues
    );
    this.addStrategyItems$.next(info);
  }

  private _updateActiveStrategy(strategy: IStrategy): void {
    this._strategyService.setActiveStrategy(strategy);
    this.idStrategyActive = strategy.isDisabled ? 0 : strategy.idStrategy;
  }

  public cancelAction(): void {
    if (
      (!this.isSecondContentModal && this.form.get('idStrategy')?.value) ||
      this.data.newStrategy ||
      this.data.onlyEdit ||
      !this.strategies.length
    ) {
      this.close(undefined, this.data.isSecondModal);
      this._subscriptions$.forEach((sub) => sub.unsubscribe());
      return;
    }
    this.handleIconAction('EDIT');
  }

  public saveStragies(): void {
    if (this.buttonsSettings.disableSubmit) return;
    if (this.currentBodyContent === BODY_CONTENT.USER_STRATEGIES) {
      this.isSave = true;
      this.form.touched && this._createOrEditStrategy();
      if (
        !this.data.newStrategy &&
        !this.data.onlyEdit &&
        this.strategySelected
      ) {
        this._updateActiveStrategy(this.strategySelected!);
      }
      if (!this.form.touched) {
        this.closeModal();
      }
    }

    this.currentBodyContent === BODY_CONTENT.DELETE_STRATEGY &&
      this._deleteStrategy();
  }

  public closeModal(strategyCreated?: IStrategy): void {
    this.close(
      {
        isSave: this.isSave,
        strategyCreated: strategyCreated ?? this.strategyEdit,
      },
      this.data.isSecondModal
    );
    this._subscriptions$ &&
      this._subscriptions$.forEach((sub) => sub.unsubscribe());
  }

  private _createOrEditStrategy(): void {
    const idStrategy = Boolean(this.form.get('idStrategy')?.value);
    !idStrategy && this._createStrategy();
    idStrategy && this._editStrategy();
  }

  // SERVICES
  private _createStrategy(): void {
    const strategyParams = this.form.value;
    strategyParams.items = this._strategyService.formatItemsToCreate(
      strategyParams.items,
      this.inputMask
    );
    strategyParams.idStrategy = 0;
    strategyParams.strategyItems = strategyParams.items;
    strategyParams.strategyType = strategyParams.typeStrategy;
    this._orsGatewayService.createStrategy(strategyParams).subscribe((res) => {
      if (res && res.success) {
        if (!this.data.newStrategy) {
          this._updateActiveStrategy(
            Object.assign(strategyParams, { idStrategy: res.result })
          );
        }
        this.closeModal(
          Object.assign(strategyParams, { idStrategy: res.result })
        );
      }
    });
  }

  private _createStrategyItems(newItems: IStrategyCondition[]): void {
    this._orsGatewayService
      .createStrategyItem(this.strategySelected!.idStrategy, newItems)
      .subscribe();
  }

  private _editStrategy(): void {
    const strategyParams = this.form.value;
    strategyParams.strategyItems = strategyParams.items;
    strategyParams.strategyType = strategyParams.typeStrategy;
    this.strategyEdit = strategyParams;
    this._orsGatewayService
      .editStrategyInfos(strategyParams)
      .subscribe((res) => {
        if (res && res.success) {
          const items = (<IStrategyCondition[]>strategyParams.items).filter(
            (item) => !this._strategyItemsToDelete.includes(item.id_item)
          );

          this._editStrategyItems(items.filter((item) => item.id_item));

          const newItems = items.filter((item) => !item.id_item);
          if (this.inputMask == 'custom_percent_symbol') {
            newItems.map((item) => {
              item.gain = this._strategyService.removeFormatter(
                item.gain,
                this.inputMask
              );
              item.loss = this._strategyService.removeFormatter(
                item.loss,
                this.inputMask
              );
              item.offset = parseInt(String(item.offset).replace(/\D/g, ''));
            });
          }
          newItems && newItems.length && this._createStrategyItems(newItems);
          if (!this.data.onlyEdit && !this.data.newStrategy) {
            this._updateActiveStrategy(Object.assign(strategyParams));
          }
        }
      });

    this._strategyItemsToDelete.length &&
      this._deleteStrategyItems(this._strategyItemsToDelete);
  }

  private _editStrategyItems(strategyItems: IStrategyCondition[]): void {
    strategyItems.forEach((strategy) => {
      strategy.loss = this._strategyService.removeFormatter(
        strategy.loss,
        this.inputMask
      );
      strategy.gain = this._strategyService.removeFormatter(
        strategy.gain,
        this.inputMask
      );
      strategy.offset = parseInt(String(strategy.offset).replace(/\D/g, ''));

      this._orsGatewayService
        .editStrategyItems({
          ...strategy,
          ...{ id_strategy: this.strategySelected!.idStrategy },
        })
        .subscribe((res) => res && res.success && this.closeModal());
    });
  }

  private _deleteStrategy(): void {
    this._orsGatewayService
      .deleteStrategy(this.strategySelected!)
      .subscribe((res) => {
        if (res && res.success) {
          this.strategySelected!.idStrategy === this.idStrategyActive &&
            this._updateActiveStrategy(
              Object.assign(this.strategySelected!, { isDisabled: true })
            );

          this._deleteStrategyItems(
            this.strategySelected!.strategyItems.map((item: any) => {
              return item.id_item;
            })
          );
        }
      });
  }

  private _deleteStrategyItems(idItemsToDelete: number[]): void {
    idItemsToDelete.forEach((id) => {
      this._orsGatewayService
        .deleteStrategyItems(id)
        .subscribe((res) => res && res.success && this.closeModal());
    });
  }

  inputMaskChange(inputMask: InputMask) {
    if (this.isCreatingStartegy && this.inputMask != inputMask) {
      this.groupForm?.clear();
      this.addStrategyItems();
    }
    this.inputMask = inputMask;
  }

  groupHandler(data: any) {
    this.indexInvalidQuantity = data.indexInvalidQuantity;
    this.form.get('items')?.setValue(data.groups.value);
    this.form.get('items')?.updateValueAndValidity();
  }

  public setItemToDelete(itemId: number): void {
    this._strategyItemsToDelete.push(itemId);
  }
}
