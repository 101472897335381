<rocket-modal>
  <rocket-modal-header>
    <h3 class="modal-title">Conexões</h3>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div [style.min-height.px]="200" class="pb-6">
      <h4 class="pb-4">
        Adicione sua conta nas corretoras parceiras para começar a negociar.
      </h4>
      <ng-container *ngIf="isLoading; else content">
        <div class="align-items-center gap-2 vstack w-100">
          <span class="fw-bold text-center">
            Carregando corretoras, por favor aguarde.
          </span>
          <div class="spinner-border text-brand-primary" role="status"></div>
        </div>
      </ng-container>

      <ng-template #content>
        <app-card-list
          [brokers]="brokers"
          (brokerSelected)="openConnectionBrokerModal($event)"
          (brokerDisconnect)="brokerDisconnect($event)"
        ></app-card-list>
      </ng-template>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
