<ng-container *ngIf="isBase; else printCards">
  <div class="card" [ngClass]="[customClass]">
    <div class="card-body">
      <ng-template [ngTemplateOutlet]="printCards"></ng-template>
    </div>
  </div>
</ng-container>

<ng-template #printCards>
  <ng-container *ngFor="let card of allCards">
    <ng-container *ngTemplateOutlet="card.content"></ng-container>
  </ng-container>
</ng-template>
