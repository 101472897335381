import { Dictionary } from '@core/models';
import { TOOLS_TYPE_DRAW } from '../tiger-chart-tools.enum';
import {
  CONFIG,
  TYPE_CLICK,
  TYPE_CONFIG,
  TYPE_VALUE,
} from './toolbar-draw.enum';
import { ToolbarDrawColor, ToolbarDrawConfigs } from './toolbar-draw.interface';

export const REGEX_GET_RGBA_ALPHA = /, \d?\.?\d{1,}\)/g;

export const FONTSIZES: Array<ToolbarDrawConfigs> = [
  { title: '10', value: 10, hint: '', typeValue: TYPE_VALUE.STROKE_WIDTH },
  { title: '11', value: 11, hint: '', typeValue: TYPE_VALUE.STROKE_WIDTH },
  { title: '12', value: 12, hint: '', typeValue: TYPE_VALUE.STROKE_WIDTH },
  { title: '14', value: 14, hint: '', typeValue: TYPE_VALUE.STROKE_WIDTH },
  { title: '16', value: 16, hint: '', typeValue: TYPE_VALUE.STROKE_WIDTH },
  { title: '20', value: 20, hint: '', typeValue: TYPE_VALUE.STROKE_WIDTH },
  { title: '24', value: 24, hint: '', typeValue: TYPE_VALUE.STROKE_WIDTH },
  { title: '28', value: 28, hint: '', typeValue: TYPE_VALUE.STROKE_WIDTH },
  { title: '32', value: 32, hint: '', typeValue: TYPE_VALUE.STROKE_WIDTH },
  { title: '40', value: 40, hint: '', typeValue: TYPE_VALUE.STROKE_WIDTH },
];

export const TOOLBAR = new Dictionary<ToolbarDrawConfigs>();
TOOLBAR.set(CONFIG.MODELS, {
  title: '',
  hint: 'Modelos',
  options: [
    {
      title: 'Salvar modelo de desenho como...',
      hint: '',
      typeValue: TYPE_VALUE.MODELS,
      value: 'SAVE',
    },
    {
      title: 'Restaurar padrão',
      hint: 'Restaurar padrão original',
      typeValue: TYPE_VALUE.MODELS,
      value: 'RESET',
    },
    {
      title: '',
      hint: '',
      typeValue: TYPE_VALUE.DIVIVER,
    },
  ],
  svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none" stroke="currentColor">
  <path stroke-linecap="round" d="M15.5 18.5h6m-3 3v-6"></path>
  <rect width="6" height="6" rx="1.5" x="6.5" y="6.5"></rect>
  <rect width="6" height="6" rx="1.5" x="15.5" y="6.5"></rect>
  <rect width="6" height="6" rx="1.5" x="6.5" y="15.5"></rect>
  </svg>`,
  type: TYPE_CONFIG.OPTIONS,
  typeConfiguration: CONFIG.MODELS,
});
TOOLBAR.set(CONFIG.LINE_COLOR, {
  title: '',
  hint: 'Cores das ferramentas de linha',
  svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" width="17" height="17" fill="none">
  <path stroke="currentColor" d="M1.5 11.5l-.7.7a1 1 0 0 0-.3.71v3.59h3.59a1 1 0 0 0 .7-.3l.71-.7m-4-4l9-9m-9 9l2 2m2 
  2l9-9m-9 9l-2-2m11-7l1.3-1.3a1 1 0 0 0 0-1.4l-2.6-2.6a1 1 0 0 0-1.4 0l-1.3 1.3m4 4l-4-4m-7 11l9-9"></path>
  </svg>
  <div class="colorBg">
    <div class="color" style="background-color: rgb(41, 98, 255);"></div>
  </div>
  `,
  type: TYPE_CONFIG.COLOR_PICKER,
  typeColorUpdate: TOOLS_TYPE_DRAW.LINE,
  typeConfiguration: CONFIG.LINE_COLOR,
});
TOOLBAR.set(CONFIG.CENTER_LINE_COLOR, {
  title: '',
  hint: 'Cores das ferramentas de linha central',
  svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" width="17" height="17" fill="none">
  <path stroke="currentColor" d="M1.5 11.5l-.7.7a1 1 0 0 0-.3.71v3.59h3.59a1 1 0 0 0 .7-.3l.71-.7m-4-4l9-9m-9 9l2 2m2 
  2l9-9m-9 9l-2-2m11-7l1.3-1.3a1 1 0 0 0 0-1.4l-2.6-2.6a1 1 0 0 0-1.4 0l-1.3 1.3m4 4l-4-4m-7 11l9-9"></path>
  </svg>
  <div class="colorBg-centerline">
    <div class="color" style="background-color: rgb(41, 98, 255);"></div>
  </div>
  `,
  type: TYPE_CONFIG.COLOR_PICKER,
  typeColorUpdate: TOOLS_TYPE_DRAW.CENTER_LINE,
  typeConfiguration: CONFIG.CENTER_LINE_COLOR,
});
TOOLBAR.set(CONFIG.BACKGROUND_COLOR, {
  title: '',
  hint: 'Fundo da ferramenta de linha',
  svgIcon: `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20" fill="none">
  <path stroke="currentColor" d="M13.5 6.5l-3-3-7 7 7.59 7.59a2 2 0 0 0 2.82 0l4.18-4.18a2 2 0 0 0 0-2.82L13.5 6.5zm0 0v-4a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v6"></path>
  <path fill="currentColor" d="M0 16.5C0 15 2.5 12 2.5 12S5 15 5 16.5 4 19 2.5 19 0 18 0 16.5z"></path>
  <circle fill="currentColor" cx="9.5" cy="9.5" r="1.5"></circle>
  </svg>
  <div class="colorBg-background">
    <div class="color" style="background-color: rgb(41, 98, 255);"></div>
  </div>
  `,
  type: TYPE_CONFIG.COLOR_PICKER,
  typeColorUpdate: TOOLS_TYPE_DRAW.BACKGROUND,
  typeConfiguration: CONFIG.BACKGROUND_COLOR,
});
TOOLBAR.set(CONFIG.TEXT_COLOR, {
  title: '',
  hint: 'Cor do Texto',
  svgIcon: `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" width="13" height="15" fill="none">
  <path stroke="currentColor" d="M4 14.5h2.5m2.5 0H6.5m0 0V.5m0 0h-5a1 1 0 0 0-1 1V4m6-3.5h5a1 1 0 0 1 1 1V4"></path>
  </svg>
  <div class="colorBg-text">
    <div class="color" style="background-color: rgb(41, 98, 255);"></div>
  </div>
  `,
  type: TYPE_CONFIG.COLOR_PICKER,
  typeColorUpdate: TOOLS_TYPE_DRAW.TEXT,
  typeConfiguration: CONFIG.TEXT_COLOR,
});
TOOLBAR.set(CONFIG.FONT_SIZE, {
  title: '',
  hint: 'Tamanho da fonte',
  options: FONTSIZES,
  selected: FONTSIZES[0],
  type: TYPE_CONFIG.OPTIONS,
  typeValue: TYPE_VALUE.STROKE_WIDTH,
  typeConfiguration: CONFIG.FONT_SIZE,
});
TOOLBAR.set(CONFIG.LINE_WIDTH, {
  title: '',
  hint: 'Largura da linha',
  options: [
    {
      title: '1px',
      hint: '',
      labelHtml: `
        <span class="border-h round"></span>
        <span>1px</span>
      `,
      value: 1,
      typeValue: TYPE_VALUE.STROKE_THICKNESS,
    },
    {
      title: '2px',
      hint: '',
      labelHtml: `
      <span class="border-h two round"></span>
      <span>2px</span>
    `,
      value: 2,
      typeValue: TYPE_VALUE.STROKE_THICKNESS,
    },
    {
      title: '3px',
      hint: '',
      labelHtml: `
      <span class="border-h three round"></span>
      <span>3px</span>
    `,
      value: 3,
      typeValue: TYPE_VALUE.STROKE_THICKNESS,
    },
    {
      title: '4px',
      hint: '',
      labelHtml: `
      <span class="border-h four round"></span>
      <span>4px</span>
    `,
      value: 4,
      typeValue: TYPE_VALUE.STROKE_THICKNESS,
    },
  ],
  selected: {
    title: '1px',
    hint: '',
    labelHtml: `
      <span class="border-h round"></span>
      <span>1px</span>
    `,
    value: 1,
  },
  type: TYPE_CONFIG.OPTIONS,
  typeValue: TYPE_VALUE.STROKE_THICKNESS,
  typeConfiguration: CONFIG.LINE_WIDTH,
});

TOOLBAR.set(CONFIG.LINE_STYLE, {
  title: '',
  hint: 'Estilo',
  options: [
    {
      title: 'Linha',
      hint: '',
      labelHtml: `
      <span class="border-h"></span>
      <span>Linha</span>
    `,
      labelHtmlShow: `
        <span class="border-h"></span>
      `,
      typeValue: TYPE_VALUE.STROKE_DASHARRAY,
      value: [0, 0],
    },
    {
      title: 'Linha Tracejada',
      hint: '',
      labelHtml: `
      <span class="border-h dashed"></span>
      <span>Linha Tracejada</span>
    `,
      labelHtmlShow: `
      <span class="border-h dashed"></span>
    `,
      typeValue: TYPE_VALUE.STROKE_DASHARRAY,
      value: [5, 5],
    },
    {
      title: 'Linha Pontilhada',
      hint: '',
      labelHtml: `
      <span class="border-h dotted"></span>
      <span>Linha Pontilhada</span>
    `,
      labelHtmlShow: `
      <span class="border-h dotted"></span>
    `,
      typeValue: TYPE_VALUE.STROKE_DASHARRAY,
      value: [2, 2],
    },
  ],
  selected: {
    title: 'Linha',
    hint: '',
    labelHtml: `
    <span class="border-h"></span>
  `,
  },
  type: TYPE_CONFIG.OPTIONS,
  typeValue: TYPE_VALUE.STROKE_DASHARRAY,
  typeConfiguration: CONFIG.LINE_STYLE,
});
TOOLBAR.set(CONFIG.CONFIGURATION, {
  title: '',
  hint: 'Configurações',
  svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none"><path fill="currentcolor" fill-rule="evenodd" clip-rule="evenodd" d="M13 5.5c0-.28.22-.5.5-.5h1c.28 0 .5.22.5.5V7.05l.4.09c.9.18 1.73.53 2.46 1.02l.34.23.29-.3.81-.8c.2-.2.52-.2.71 0l.7.7.36-.35-.35.35c.2.2.2.51 0 .7l-.82.82-.29.29.23.34c.49.73.84 1.57 1.02 2.46l.08.4H22.5c.28 0 .5.22.5.5v1a.5.5 0 0 1-.5.5H20.95l-.09.4c-.18.9-.53 1.73-1.02 2.46l-.23.34.3.29.8.81c.2.2.2.52 0 .71l-.7.7a.5.5 0 0 1-.7 0l-.82-.8-.29-.3-.34.23c-.73.49-1.57.84-2.46 1.02l-.4.08V22.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V20.95l-.4-.09a6.96 6.96 0 0 1-2.46-1.02l-.34-.23-.29.3-.81.8.35.36-.35-.35a.5.5 0 0 1-.71 0l-.7-.71a.5.5 0 0 1 0-.7l-.36-.36.35.35.82-.81.29-.29-.23-.34a6.96 6.96 0 0 1-1.02-2.46l-.08-.4H5.5a.5.5 0 0 1-.5-.5v-1c0-.28.22-.5.5-.5H7.05l.09-.4c.18-.9.53-1.73 1.02-2.46l.23-.34-.3-.29-.8-.81a.5.5 0 0 1 0-.71l.7-.7c.2-.2.51-.2.7 0l.82.8.29.3.34-.23a6.96 6.96 0 0 1 2.46-1.02l.4-.08V5.5zm.5-1.5c-.83 0-1.5.67-1.5 1.5v.75c-.73.2-1.43.48-2.06.86l-.54-.53a1.5 1.5 0 0 0-2.12 0l-.7.7a1.5 1.5 0 0 0 0 2.12l.53.54A7.95 7.95 0 0 0 6.25 12H5.5c-.83 0-1.5.67-1.5 1.5v1c0 .83.67 1.5 1.5 1.5h.75c.2.73.48 1.43.86 2.06l-.53.54a1.5 1.5 0 0 0 0 2.12l.7.7a1.5 1.5 0 0 0 2.12 0l.54-.53c.63.38 1.33.67 2.06.86v.75c0 .83.67 1.5 1.5 1.5h1c.83 0 1.5-.67 1.5-1.5v-.75a7.95 7.95 0 0 0 2.06-.86l.54.53a1.5 1.5 0 0 0 2.12 0l.7-.7a1.5 1.5 0 0 0 0-2.12l-.53-.54c.38-.63.67-1.33.86-2.06h.75c.83 0 1.5-.67 1.5-1.5v-1c0-.83-.67-1.5-1.5-1.5h-.75a7.95 7.95 0 0 0-.86-2.06l.53-.54a1.5 1.5 0 0 0 0-2.12l-.7-.7a1.5 1.5 0 0 0-2.12 0l-.54.53A7.95 7.95 0 0 0 16 6.25V5.5c0-.83-.67-1.5-1.5-1.5h-1zM12 14a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm2-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"></path></svg>`,
  type: TYPE_CONFIG.CLICK,
  click: TYPE_CLICK.CONFIG,
  typeConfiguration: CONFIG.CONFIGURATION,
});
TOOLBAR.set(CONFIG.DELETE, {
  title: '',
  hint: 'Remover',
  svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" fill-rule="evenodd" d="M11.5 6a.5.5 0 0 0-.5.5V8h6V6.5a.5.5 0 0 0-.5-.5h-5zM18 8V6.5c0-.83-.67-1.5-1.5-1.5h-5c-.83 0-1.5.67-1.5 1.5V8H5.5a.5.5 0 0 0 0 1H7v12.5A2.5 2.5 0 0 0 9.5 24h9a2.5 2.5 0 0 0 2.5-2.5V9h1.5a.5.5 0 0 0 0-1H18zm2 1H8v12.5c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V9zm-8.5 3c.28 0 .5.22.5.5v7a.5.5 0 0 1-1 0v-7c0-.28.22-.5.5-.5zm5.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"></path></svg>`,
  type: TYPE_CONFIG.CLICK,
  click: TYPE_CLICK.DELETE,
  typeConfiguration: CONFIG.DELETE,
});
export const CONFIGS: Array<ToolbarDrawConfigs> = [
  /*TOOLBAR.get(CONFIG.MODELS)!!,
  TOOLBAR.get(CONFIG.LINE_COLOR)!!,
  TOOLBAR.get(CONFIG.BACKGROUND_COLOR)!!,
  TOOLBAR.get(CONFIG.LINE_WIDTH)!!,
  TOOLBAR.get(CONFIG.LINE_STYLE)!!,
  TOOLBAR.get(CONFIG.CONFIGURATION)!!,*/
  TOOLBAR.get(CONFIG.DELETE)!!,
];

export const COLORS: Array<ToolbarDrawColor> = [
  { rgbColor: `rgb(255, 255, 255)` },
  { rgbColor: `rgb(209, 212, 220)` },
  { rgbColor: `rgb(178, 181, 190)` },
  { rgbColor: `rgb(149, 152, 161)` },
  { rgbColor: `rgb(120, 123, 134)` },
  { rgbColor: `rgb(93, 96, 107)` },
  { rgbColor: `rgb(67, 70, 81)` },
  { rgbColor: `rgb(42, 46, 57)` },
  { rgbColor: `rgb(19, 23, 34)` },
  { rgbColor: `rgb(0, 0, 0)` },
  { rgbColor: `rgb(242, 54, 69)` },
  { rgbColor: `rgb(255, 152, 0)` },
  { rgbColor: `rgb(255, 235, 59)` },
  { rgbColor: `rgb(76, 175, 80)` },
  { rgbColor: `rgb(8, 153, 129)` },
  { rgbColor: `rgb(0, 188, 212)` },
  { rgbColor: `rgb(41, 98, 255)` },
  { rgbColor: `rgb(103, 58, 183)` },
  { rgbColor: `rgb(156, 39, 176)` },
  { rgbColor: `rgb(233, 30, 99)` },
];

export const MONO_COLORS: Array<ToolbarDrawColor> = [
  { rgbColor: `rgb(252, 203, 205)` },
  { rgbColor: `rgb(255, 224, 178)` },
  { rgbColor: `rgb(255, 249, 196)` },
  { rgbColor: `rgb(200, 230, 201)` },
  { rgbColor: `rgb(172, 229, 220)` },
  { rgbColor: `rgb(178, 235, 242)` },
  { rgbColor: `rgb(187, 217, 251)` },
  { rgbColor: `rgb(209, 196, 233)` },
  { rgbColor: `rgb(225, 190, 231)` },
  { rgbColor: `rgb(248, 187, 208)` },
  { rgbColor: `rgb(250, 161, 164)` },
  { rgbColor: `rgb(255, 204, 128)` },
  { rgbColor: `rgb(255, 245, 157)` },
  { rgbColor: `rgb(165, 214, 167)` },
  { rgbColor: `rgb(112, 204, 189)` },
  { rgbColor: `rgb(128, 222, 234)` },
  { rgbColor: `rgb(144, 191, 249)` },
  { rgbColor: `rgb(179, 157, 219)` },
  { rgbColor: `rgb(206, 147, 216)` },
  { rgbColor: `rgb(244, 143, 177)` },
  { rgbColor: `rgb(247, 124, 128)` },
  { rgbColor: `rgb(255, 183, 77)` },
  { rgbColor: `rgb(255, 241, 118)` },
  { rgbColor: `rgb(129, 199, 132)` },
  { rgbColor: `rgb(66, 189, 168)` },
  { rgbColor: `rgb(77, 208, 225)` },
  { rgbColor: `rgb(91, 156, 246)` },
  { rgbColor: `rgb(149, 117, 205)` },
  { rgbColor: `rgb(186, 104, 200)` },
  { rgbColor: `rgb(240, 98, 146)` },
  { rgbColor: `rgb(247, 82, 95)` },
  { rgbColor: `rgb(255, 167, 38)` },
  { rgbColor: `rgb(255, 238, 88)` },
  { rgbColor: `rgb(102, 187, 106)` },
  { rgbColor: `rgb(34, 171, 148)` },
  { rgbColor: `rgb(38, 198, 218)` },
  { rgbColor: `rgb(49, 121, 245)` },
  { rgbColor: `rgb(126, 87, 194)` },
  { rgbColor: `rgb(171, 71, 188)` },
  { rgbColor: `rgb(236, 64, 122)` },
  { rgbColor: `rgb(178, 40, 51)` },
  { rgbColor: `rgb(245, 124, 0)` },
  { rgbColor: `rgb(251, 192, 45)` },
  { rgbColor: `rgb(56, 142, 60)` },
  { rgbColor: `rgb(5, 102, 86)` },
  { rgbColor: `rgb(0, 151, 167)` },
  { rgbColor: `rgb(24, 72, 204)` },
  { rgbColor: `rgb(81, 45, 168)` },
  { rgbColor: `rgb(123, 31, 162)` },
  { rgbColor: `rgb(194, 24, 91)` },
  { rgbColor: `rgb(128, 25, 34)` },
  { rgbColor: `rgb(230, 81, 0)` },
  { rgbColor: `rgb(245, 127, 23)` },
  { rgbColor: `rgb(27, 94, 32)` },
  { rgbColor: `rgb(0, 51, 42)` },
  { rgbColor: `rgb(0, 96, 100)` },
  { rgbColor: `rgb(12, 50, 153)` },
  { rgbColor: `rgb(49, 27, 146)` },
  { rgbColor: `rgb(74, 20, 140)` },
  { rgbColor: `rgb(136, 14, 79)` },
];
