// or, for npm, import { NumericTickProvider, ... } from "scichart"

import {
  NumberRange,
  NumericTickProvider,
  TSciChart,
  TSciChart3D,
} from 'scichart';

// Custom TickProvider implementation
//
export class CustomNumericTickProvider extends NumericTickProvider {
  private webContext!: TSciChart | TSciChart3D;
  constructor(webAssemblyContext: TSciChart | TSciChart3D) {
    super(webAssemblyContext);
    this.webContext = webAssemblyContext;
  }

  // returns an array of major gridline positions in data space
  // Called once per draw. Can be dynamic
  override getMajorTicks(
    minorDelta: number,
    majorDelta: number,
    visibleRange: NumberRange
  ): number[] {
    const deltaRange = new NumberRange(minorDelta, majorDelta);
    const tickRange = visibleRange;
    return this.calculateTicks(tickRange, deltaRange.max, deltaRange.max);
  }

  protected override calculateTicks(
    visibleRange: NumberRange,
    delta: number,
    majorDelta: number
  ): number[] {
    const results: number[] = [];
    const min = visibleRange.min;
    const max = visibleRange.max;
    let current = min;
    const calcMajorTicks = delta === majorDelta;
    const numberUtil = this.webContext.NumberUtil;
    if (!numberUtil.IsDivisibleBy(current, delta)) {
      current = numberUtil.RoundUp(current, delta);
    }
    const start = current;
    let tickCount = 0;
    while (current <= max) {
      // TRUE if major ticks are calculated && Current is divisible by MajorDelta
      // or if minor ticks are calculated && Current is NOT divisible by MajorDelta
      if (!(numberUtil.IsDivisibleBy(current, majorDelta) !== calcMajorTicks)) {
        results.push(current);
      }
      current = start + ++tickCount * delta;
    }
    if (!results.length) {
      results.push(...[min, max]);
    }
    return results;
  }
}
