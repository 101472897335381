<div class="bg-neutral-stronger p-2 round border-neutral-strong">
  <div class="vstack gap-1 mb-3">
    <div class="hstack w-100 justify-content-between">
      <h1 class="fs-3 fw-bold">índice</h1>
      <ng-container *ngIf="!isSelectingIndex">
        <button
          [id]="elementsID.changeSelectedIndex"
          type="button"
          class="btn btn-outline-primary btn-sm"
          (click)="isSelectingIndex = true"
        >
          Alterar índice selecionado
        </button>
      </ng-container>
    </div>

    <h3 class="fs-5 text-neutral-medium">
      Escolha o índice que gostaria de ver as informações
    </h3>

    <ng-container *ngIf="isSelectingIndex">
      <span
        [id]="elementsID.changeIndexLabel"
        class="w-100 text-center d-block fw-bold fs-base text-light pt-2"
      >
        Selecione o índicie que deseja favoritar
      </span>
    </ng-container>
  </div>

  <div
    [id]="elementsID.content"
    class="grid-content align-items-center justify-items-center gap-2"
  >
    <ng-container *ngFor="let item of indexes; let index = index">
      <div class="form-check form-selection position-relative">
        <input
          class="form-check-input pointer-cursor"
          type="radio"
          name="selectionGroup"
          [id]="item.cdStock"
          (click)="onSelectIndex(item)"
          [checked]="indexSelected && indexSelected.cdStock === item.cdStock"
        />
        <label class="form-check-label" [for]="item.cdStock">
          <div class="align-items-center gap-2 hstack justify-content-center">
            <span> {{ item.cdStock }} </span>
          </div>
        </label>
        <ng-container *ngIf="item.qtty">
          <app-notification-count [value]="item.qtty"></app-notification-count>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="isSelectingIndex">
    <div
      [id]="elementsID.controls"
      class="align-items-center gap-2 hstack justify-content-end pt-3 w-100"
    >
      <button
        type="button"
        class="btn btn-fill-primary btn-sm"
        (click)="isSelectingIndex = false"
      >
        Cancelar
      </button>

      <button
        type="button"
        class="btn btn-primary btn-sm"
        (click)="changeIndexSelected()"
        [disabled]="!newIndexSelected"
      >
        Confirmar
      </button>
    </div>
  </ng-container>
</div>
