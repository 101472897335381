import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { RENT_ELEMENTS_ID } from '../../enum/rent.enum';

@Component({
  selector: 'rent-details',
  templateUrl: './rent-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RentDetailsComponent implements OnDestroy {
  @ViewChild('rentDetailsHeaderContent')
  public rentDetailsHeaderContent!: ElementRef;
  @Input() cdStock!: string;
  @Output() onBackToGrid: EventEmitter<void> = new EventEmitter<void>();
  private _resize$!: ResizeObserver;
  public headerHeight: number = 110;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this._resize$ = new ResizeObserver((entries) => {
      this.headerHeight = entries[0].contentRect.height;
    });
    this._resize$.observe(this.rentDetailsHeaderContent.nativeElement);
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.rentDetailsHeaderContent &&
      this.rentDetailsHeaderContent.nativeElement &&
      this._resize$.unobserve(this.rentDetailsHeaderContent.nativeElement);
  }

  public scrollToFundsTable(): void {
    const element = document.getElementById(RENT_ELEMENTS_ID.TABLE);
    if (!element) {
      console.error('FUNDS_TABLE_NOT_FOUND');
      return;
    }
    element?.scrollIntoView({
      behavior: 'smooth',
    });
    this.cdr.detectChanges();
  }
}
