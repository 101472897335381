import { Injectable } from '@angular/core';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { AUTH_LOCAL_KEYS } from '@shared/services/core/const/auth_util.const';
import {
  ITradesConfig,
  ITradesFiltersAndColors,
} from '../interface/trades-filter.interface';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TradesFilterService extends CustomPreferencesService {
  private _allConfigs!: ITradesConfig;

  private _onChangePreferences = new Subject<{
    cd_stock: string;
    cd_stock_order: string;
    configs: ITradesFiltersAndColors;
  }>();

  get filtersAndHighlights(): ITradesConfig | null {
    if (this._allConfigs) return this._allConfigs;
    const config = this.storage.getItem(AUTH_LOCAL_KEYS.TIMES_TRADES_FILTERS);
    if (!config) return null;
    const pasedValue = JSON.parse(config);
    this._allConfigs = pasedValue;
    return pasedValue;
  }

  get onUpdateConfigs$(): Observable<{
    cd_stock_order: string;
    configs: ITradesFiltersAndColors;
  }> {
    return this._onChangePreferences.asObservable();
  }

  public saveConfigs(
    cdStock: string,
    cdStockOrder: string,
    stockConfig: ITradesFiltersAndColors
  ): void {
    const configs: any = this.filtersAndHighlights
      ? this.filtersAndHighlights
      : {};
    configs[cdStock] = stockConfig;
    this._onChangePreferences.next({
      cd_stock: cdStock,
      cd_stock_order: cdStockOrder,
      configs: stockConfig,
    });
    this._saveUpdate(configs);
  }

  public deleteConfigsByStock(cdStock: string, cdStockOrder: string): void {
    const configs: any = this.filtersAndHighlights
      ? this.filtersAndHighlights
      : {};
    configs[cdStock] = {};
    this._onChangePreferences.next({
      cd_stock: cdStock,
      cd_stock_order: cdStockOrder,
      configs: configs[cdStock],
    });
    this._saveUpdate(configs);
  }

  public deleteFilterByStock(cdStock: string, cdStockOrder: string): void {
    const configs = this.filtersAndHighlights
      ? this.getFilter(this.filtersAndHighlights, cdStock, cdStockOrder)
      : {};
    const preferences: any = configs?.colors ? { colors: configs.colors } : {};
    this._onChangePreferences.next({
      cd_stock: cdStock,
      cd_stock_order: cdStockOrder,
      configs: preferences,
    });
    this._saveUpdate(preferences);
  }

  public getConfigByStock(
    cdStock: string,
    cdStockOrder: string
  ): ITradesFiltersAndColors | null {
    if (this.filtersAndHighlights)
      return this.getFilter(this.filtersAndHighlights, cdStock, cdStockOrder);
    const savedInfos = this.filtersAndHighlights;
    if (!savedInfos) return null;
    return this.getFilter(savedInfos, cdStock, cdStockOrder);
  }

  private _saveUpdate(config: ITradesConfig) {
    this._allConfigs = config;
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.TIMES_TRADES_FILTERS,
      JSON.stringify(config)
    );
  }

  private getFilter(filter: any, cdStock: string, cdStockOrder: string) {
    if (filter[cdStock]) {
      return filter[cdStock];
    }
    if (filter[cdStockOrder]) {
      return filter[cdStockOrder];
    }
    return null;
  }
}
