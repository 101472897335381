import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { HomeRoutes } from './home.routing';
import { SharedModule } from '@shared/shared.module';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { TableBookModule } from '@shared/components/table-book/table-book.module';
import { TimesTradesModule } from '@shared/components/times-trades/times-trades.module';
import { SuperDomModule } from '@shared/components/super-dom/super-dom.module';
import { LoadingModule } from '@shared/components/loading/loading.module';
import { ModalAddComponentWorkspaceComponent } from './workspace-component/modal-add-component-workspace/modal-add-component-workspace.component';
import { ContextMenuModule } from '@shared/components/context-menu/context-menu.module';
import {
  RocketModalModule,
  RocketButtonModule,
  FlaInputModule,
  FlaCardModule,
  FlaIconModule,
  FlaTabModule,
} from '@shared/rocket-components/components';
import { RocketIconsModule } from '@shared/rocket-icons/rocket-icons.module';
import { PopupRootModule } from '../../shared/components/popup-root/popup-root.module';
import { YoutubeMiniplayerModule } from './youtube-miniplayer/youtube-miniplayer.module';
import { InactivityAlertComponent } from './inactivity-alert/inactivity-alert.component';
import { OrdersHistoryCoreModule } from '@core/components/orders-history-core/orders-history.module';

@NgModule({
  declarations: [
    HomeComponent,
    ModalAddComponentWorkspaceComponent,
    InactivityAlertComponent,
  ],
  imports: [
    FlaWorkSpaceModule,
    FlaInputModule,
    FlaCardModule,
    FlaIconModule,
    FlaTabModule,
    RocketButtonModule,
    RocketModalModule,
    CommonModule,
    HomeRoutes,
    SharedModule,
    TableBookModule,
    OrdersHistoryCoreModule,
    TimesTradesModule,
    SuperDomModule,
    LoadingModule,
    ContextMenuModule,
    RocketIconsModule,
    PopupRootModule,
    YoutubeMiniplayerModule,
  ],
})
export class HomeModule {}
