import {
  BaseIndicator,
  TTigerChartIndicatorCreateOptions,
  TTigerChartIndicatorParameter,
  TTigerChartIndicatorRenderSeriesConfig,
  MACDData,
  TTigerChartIndicatorMovingAverageOptions,
} from './indicators.types';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import {
  TIGER_INDICATORS_ENUM,
  TIGER_INDICATOR_PARAMETER_TYPE,
  TIGER_INDICATOR_SOURCES,
} from '../enum/tiger-chart.enum';
import { TigerChartIndicatorMovingAverage } from './moving-average';
import {
  TIGER_INDICATOR_SOURCE_LIST,
  TIGER_INDICATOR_SOURCE_OBJECT,
} from '../constants/tiger-chart.constants';
import { TIGER_INDICATOR_PRECISIONS } from './indicators.constants';
import { XyDataSeries } from 'scichart/Charting/Model/XyDataSeries';
import { FastLineRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastLineRenderableSeries';
import {
  TData,
  TTooltipIndicatorValue,
  TUpdateData,
} from '../types/tiger-chart.types';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { TalibService } from '../services/talib.service';
import { Subject } from 'rxjs';
import { FastColumnRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastColumnRenderableSeries';
import { CHART_COLORS, NegativeBarsPaletteProvider } from '../colors';
import { setDefaultConfiguration } from './indicators.functions';

export class TigerChartIndicatorMovingAverageConvergenceDivergence
  implements BaseIndicator
{
  mainLineId = '';
  isNewOnChart = false;
  protected fastTimePeriod: number = 12;
  protected slowTimePeriod: number = 26;
  protected signalTimePeriod: number = 9;
  protected source: TIGER_INDICATOR_SOURCES = TIGER_INDICATOR_SOURCES.CLOSE;

  protected MACDDataSeries!: XyDataSeries;
  protected signalDataSeries!: XyDataSeries;
  protected histogramDataSeries!: XyDataSeries;
  protected MACDlineRenderableSeries!: FastLineRenderableSeries;
  protected signalLineRenderableSeries!: FastLineRenderableSeries;
  protected histogramRenderableSeries!: FastColumnRenderableSeries;
  protected data: TData;
  protected precision: number = 4;
  protected points: MACDData;
  protected offset: number = 0;
  protected upperHistogramColor = CHART_COLORS.FEEDBACK_POSITIVE;
  protected bottomHistogramColor = CHART_COLORS.FEEDBACK_NEGATIVE;
  protected macdColor = '#0200A6';
  protected signalColor = '#ffeb3c';
  protected macdThickness = 1;
  protected signalThickness = 1;

  private baseChart!: TWebAssemblyChart;
  lineNumber!: string;
  type: TIGER_INDICATORS_ENUM =
    TIGER_INDICATORS_ENUM.MOVING_AVERAGE_CONVERGENCE_DIVERGENCE;
  yAxisId!: string;
  settings: TTigerChartIndicatorParameter[] = [];
  styles: TTigerChartIndicatorParameter[] = [];
  renderSeriesConfig: TTigerChartIndicatorRenderSeriesConfig[] = [];
  onChange = new Subject<null>();
  service: TalibService;

  get isVisible(): boolean {
    return this.MACDlineRenderableSeries.isVisible;
  }

  set isVisible(visible: boolean) {
    this.MACDlineRenderableSeries.isVisible = visible;
    this.signalLineRenderableSeries.isVisible = visible;
    this.histogramRenderableSeries.isVisible = visible;
  }

  get propertiesText(): string {
    return `(${this.fastTimePeriod}, ${this.slowTimePeriod}, ${
      TIGER_INDICATOR_SOURCE_OBJECT[this.source].label
    }, ${this.signalTimePeriod})`;
  }

  constructor(options: TTigerChartIndicatorMovingAverageOptions) {
    this.data = options.data;
    this.service = options.service;
    this.mainLineId = `${this.type}-main-line`;
    if (options.upperHistogramColor)
      this.upperHistogramColor = options.upperHistogramColor;
    if (options.bottomHistogramColor)
      this.bottomHistogramColor = options.bottomHistogramColor;
    if (options.macdColor) this.macdColor = options.macdColor;
    if (options.signalColor) this.signalColor = options.signalColor;
    if (options.precision) this.precision = options.precision;
    if (options.macdThickness) this.macdThickness = options.macdThickness;
    if (options.signalThickness) this.signalThickness = options.signalThickness;
    if (options.source) this.source = options.source;
    if (options.fastTimePeriod) this.fastTimePeriod = options.fastTimePeriod;
    if (options.slowTimePeriod) this.slowTimePeriod = options.slowTimePeriod;
    if (options.signalTimePeriod)
      this.signalTimePeriod = options.signalTimePeriod;

    this.points = {
      MACD: [],
      MACDHist: [],
      MACDSignal: [],
    };

    this.settings = [
      {
        label: 'Período Rápido',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property:
          'fastTimePeriod' as keyof TigerChartIndicatorMovingAverageConvergenceDivergence,
      },
      {
        label: 'Período Lento',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property:
          'slowTimePeriod' as keyof TigerChartIndicatorMovingAverageConvergenceDivergence,
      },
      {
        label: 'Fonte',
        type: TIGER_INDICATOR_PARAMETER_TYPE.SELECTION,
        values: TIGER_INDICATOR_SOURCE_LIST,
        property: 'source' as keyof TigerChartIndicatorMovingAverage,
      },
      {
        label: 'Período Sinal',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property:
          'signalTimePeriod' as keyof TigerChartIndicatorMovingAverageConvergenceDivergence,
      },
    ];
    this.renderSeriesConfig = [
      {
        label: 'Histograma acima',
        id: `${this.type}-histogram-up`,
        color: this.upperHistogramColor,
        propertyColor: 'upperHistogramColor',
        thickness: 1,
        dontShowTickness: true,
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
      },
      {
        label: 'Histograma abaixo',
        id: `${this.type}-histogram-down`,
        color: this.bottomHistogramColor,
        propertyColor: 'bottomHistogramColor',
        thickness: 1,
        dontShowTickness: true,
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
      },
      {
        label: 'MACD',
        id: this.mainLineId,
        color: this.macdColor,
        propertyColor: 'macdColor',
        thickness: this.macdThickness,
        propertyThickness: 'macdThickness',
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
      },
      {
        label: 'Sinal',
        id: `${this.type}-signal-line`,
        color: this.signalColor,
        propertyColor: 'signalColor',
        thickness: this.signalThickness,
        propertyThickness: 'signalThickness',
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
      },
    ];

    this.styles = [
      {
        type: TIGER_INDICATOR_PARAMETER_TYPE.SELECTION,
        property: 'precision',
        label: 'Precisão',
        values: TIGER_INDICATOR_PRECISIONS,
      },
    ];
    this.styles[0].values![0].value = this.precision;
    this.lineNumber = options.lineNumber;
  }

  updatePoints(): void {
    const offset: number[] = Array(
      this.signalTimePeriod + this.slowTimePeriod - 2
    ).fill(this.data[this.source][0]);
    this.points = this.service.call('MACD', {
      inReal: [...offset, ...this.data[this.source]],
      fastPeriod: this.fastTimePeriod,
      signalPeriod: this.signalTimePeriod,
      slowPeriod: this.slowTimePeriod,
      endIdx: offset.length + this.data[this.source].length - 1,
    });
  }

  create(options: TTigerChartIndicatorCreateOptions): BaseRenderableSeries[] {
    this.updatePoints();
    this.baseChart = options.base;

    const xValues = this.data.id_point;
    let macdOffset: number[] = [];
    let signalOffset: number[] = [];
    let macdYValues: number[] = new Array(xValues.length).fill(NaN);
    let signalYValues: number[] = new Array(xValues.length).fill(NaN);
    let histogramYValues: number[] = new Array(xValues.length).fill(NaN);
    if (
      this.points.MACD.length >
      this.slowTimePeriod + this.signalTimePeriod - 2
    ) {
      macdYValues = [...this.points.MACD.slice(0, this.data.id_point.length)];
      signalYValues = [
        ...this.points.MACDSignal.slice(0, this.data.id_point.length),
      ];
      histogramYValues = [
        ...this.points.MACDHist.slice(0, this.data.id_point.length),
      ];
    }
    const macd = this._createRenderableDataSeries(
      this.MACDDataSeries,
      this.MACDlineRenderableSeries,
      xValues,
      macdYValues,
      this.mainLineId,
      options.xAxisId,
      this.lineNumber
    );
    this.MACDDataSeries = macd.dataSeries;
    this.MACDlineRenderableSeries =
      macd.renderableDataSeries as FastLineRenderableSeries;

    const signal = this._createRenderableDataSeries(
      this.signalDataSeries,
      this.signalLineRenderableSeries,
      xValues,
      signalYValues,
      `${this.type}-signal-line`,
      options.xAxisId,
      this.lineNumber
    );
    this.signalDataSeries = signal.dataSeries;
    this.signalLineRenderableSeries =
      signal.renderableDataSeries as FastLineRenderableSeries;

    this.histogramDataSeries = new XyDataSeries(this.baseChart.wasmContext);
    this.histogramDataSeries.appendRange(xValues, [
      ...histogramYValues.slice(0),
    ]);
    const config = this.renderSeriesConfig.find(
      (config) => config.id == `${this.type}-histogram-up`
    );
    this.histogramRenderableSeries = new FastColumnRenderableSeries(
      options.base.wasmContext,
      {
        strokeThickness: config?.thickness,
        dataSeries: this.histogramDataSeries,
        paletteProvider: new NegativeBarsPaletteProvider(
          this.renderSeriesConfig[0].color,
          this.renderSeriesConfig[1].color
        ),
        id: `${this.type}-histogram_${this.lineNumber}`,
        xAxisId: options.xAxisId,
      }
    );
    return [
      this.MACDlineRenderableSeries,
      this.signalLineRenderableSeries,
      this.histogramRenderableSeries,
    ];
  }

  private _createRenderableDataSeries(
    dataSeries: XyDataSeries,
    renderableDataSeries: BaseRenderableSeries,
    xValues: number[],
    yValues: number[],
    id: string,
    xAxisId: string,
    lineNumber: string
  ) {
    dataSeries = new XyDataSeries(this.baseChart.wasmContext);
    dataSeries.appendRange(xValues, [...yValues.slice(0)]);
    const config = this.renderSeriesConfig.find((config) => config.id == id);
    renderableDataSeries = new FastLineRenderableSeries(
      this.baseChart.wasmContext,
      {
        dataSeries: dataSeries,
        strokeThickness: config?.thickness,
        stroke: config?.color,
        id: `${id}_${lineNumber}`,
        xAxisId: xAxisId,
      }
    );
    return { dataSeries, renderableDataSeries };
  }

  append(xValue: number, data: TUpdateData, fullData: TData) {
    this.data = fullData;
    this.updatePoints();
    this.MACDDataSeries.append(
      xValue,
      this.points['MACD'][
        this.points['MACD'].length -
          (this.slowTimePeriod + this.signalTimePeriod - 2)
      ]
    );
    this.signalDataSeries.append(
      xValue,
      this.points['MACDSignal'][
        this.points['MACDSignal'].length -
          (this.slowTimePeriod + this.signalTimePeriod - 2)
      ]
    );
    this.histogramDataSeries.append(
      xValue,
      this.points['MACDHist'][
        this.points['MACDHist'].length -
          (this.slowTimePeriod + this.signalTimePeriod - 2)
      ]
    );
  }

  insertRange(xValues: number[], fullData: TData): void {
    this.data = fullData;
    this.updatePoints();

    if (
      this.points.MACD.length <=
      this.slowTimePeriod + this.signalTimePeriod
    ) {
      return;
    }

    const macdOffset = new Array(
      this.slowTimePeriod + this.signalTimePeriod - 2
    ).fill(NaN);
    const s = this.baseChart.sciChartSurface.suspendUpdates();
    const arrayLength =
      xValues.length <= this.slowTimePeriod + this.signalTimePeriod
        ? xValues.length
        : xValues.length - (this.slowTimePeriod + this.signalTimePeriod) + 2;
    try {
      if (xValues.length <= this.slowTimePeriod) {
        this.MACDDataSeries.insertRange(0, xValues, [
          ...macdOffset.slice(0, arrayLength),
        ]);
        this.signalDataSeries.insertRange(0, xValues, [
          ...macdOffset.slice(0, arrayLength),
        ]);
        this.histogramDataSeries.insertRange(0, xValues, [
          ...macdOffset.slice(0, arrayLength),
        ]);
      } else {
        this.MACDDataSeries.insertRange(0, xValues, [
          ...macdOffset,
          ...this.points.MACD.slice(0, arrayLength),
        ]);
        this.signalDataSeries.insertRange(0, xValues, [
          ...macdOffset,
          ...this.points.MACDSignal.slice(0, arrayLength),
        ]);
        this.histogramDataSeries.insertRange(0, xValues, [
          ...macdOffset,
          ...this.points.MACDHist.slice(0, arrayLength),
        ]);
      }
      const indexInitialValue =
        xValues.length <= this.slowTimePeriod + this.signalTimePeriod
          ? this.slowTimePeriod + this.signalTimePeriod
          : xValues.length;
    } finally {
      s.resume();
    }
  }

  changeVisibility(): void {
    this.MACDlineRenderableSeries.isVisible =
      !this.MACDlineRenderableSeries.isVisible;
    this.signalLineRenderableSeries.isVisible =
      !this.signalLineRenderableSeries.isVisible;
    this.histogramRenderableSeries.isVisible =
      !this.histogramRenderableSeries.isVisible;
  }

  update(index: number, data: TUpdateData, fullData: TData) {
    if (!this.MACDDataSeries) {
      return;
    }
    const count = this.MACDDataSeries.count();
    if (index > count) {
      return;
    }

    this.data = fullData;
    this.updatePoints();
    this.MACDDataSeries.update(
      index,
      this.points['MACD'][
        this.points['MACD'].length -
          (this.slowTimePeriod + this.signalTimePeriod - 2)
      ]
    );
    this.signalDataSeries.update(
      index,
      this.points['MACDSignal'][
        this.points['MACDSignal'].length -
          (this.slowTimePeriod + this.signalTimePeriod - 2)
      ]
    );
    this.histogramDataSeries.update(
      index,
      this.points['MACDHist'][
        this.points['MACDHist'].length -
          (this.slowTimePeriod + this.signalTimePeriod - 2)
      ]
    );
  }

  updateSettings(): void {
    this.updatePoints();

    const macdOffset = new Array(
      this.slowTimePeriod + this.signalTimePeriod - 2
    ).fill(NaN);
    const arrayLength =
      this.data.id_point.length <= this.slowTimePeriod + this.signalTimePeriod
        ? this.data.id_point.length
        : this.data.id_point.length -
          (this.slowTimePeriod + this.signalTimePeriod) +
          2;

    const s = this.baseChart.sciChartSurface.suspendUpdates();

    try {
      this._updateDataSeries(this.MACDDataSeries, [
        ...macdOffset,
        ...this.points.MACD.slice(0, arrayLength),
      ]);
      this._updateDataSeries(this.signalDataSeries, [
        ...macdOffset,
        ...this.points.MACDSignal.slice(0, arrayLength),
      ]);
      this._updateDataSeries(this.histogramDataSeries, [
        ...macdOffset,
        ...this.points.MACDHist.slice(0, arrayLength),
      ]);
    } finally {
      s.resume();
    }
    this.onChange.next(null);
  }

  private _updateDataSeries(dataSeries: XyDataSeries, points: number[]) {
    const dataSeriesCount = dataSeries.count();
    const lineValues = [...points.slice(0, this.data.id_point.length)];
    if (dataSeriesCount > lineValues.length) {
      dataSeries.removeRange(
        lineValues.length - 1,
        dataSeriesCount - lineValues.length
      );
    }
    lineValues.forEach((_, index) => {
      dataSeries.update(index, lineValues[index]);
    });
  }

  updateStyles(
    baseChart: TWebAssemblyChart,
    config: TTigerChartIndicatorRenderSeriesConfig
  ): void {
    this._updateStyles(baseChart, config);

    this.onChange.next(null);
  }

  private _updateStyles(
    baseChart: TWebAssemblyChart,
    config: TTigerChartIndicatorRenderSeriesConfig
  ) {
    if (
      config.id == `${this.type}-histogram-up` ||
      config.id == `${this.type}-histogram-down`
    ) {
      this.histogramRenderableSeries.paletteProvider =
        new NegativeBarsPaletteProvider(
          this.renderSeriesConfig[0].color,
          this.renderSeriesConfig[1].color
        );
      this.histogramRenderableSeries.strokeThickness = config.thickness;
      this.histogramRenderableSeries.isVisible = config.active;
      const lineRenderSeries =
        baseChart.sciChartSurface.renderableSeries.getById(
          `${this.type}-histogram_${this.lineNumber}`
        );
      lineRenderSeries.stroke = config.color;
      lineRenderSeries.strokeThickness = config.thickness;
      lineRenderSeries.isVisible = config.active;
      return;
    }
    if (config.id == this.mainLineId) {
      this.histogramRenderableSeries.stroke = config.color;
      this.MACDlineRenderableSeries.strokeThickness = config.thickness;
      this.MACDlineRenderableSeries.isVisible = config.active;
      const lineRenderSeries =
        baseChart.sciChartSurface.renderableSeries.getById(
          `${this.mainLineId}_${this.lineNumber}`
        );
      lineRenderSeries.stroke = config.color;
      lineRenderSeries.strokeThickness = config.thickness;
      lineRenderSeries.isVisible = config.active;
      return;
    }
    if (config.id == `${this.type}-signal-line`) {
      this.signalLineRenderableSeries.stroke = config.color;
      this.signalLineRenderableSeries.strokeThickness = config.thickness;
      this.signalLineRenderableSeries.isVisible = config.active;
      const lineRenderSeries =
        baseChart.sciChartSurface.renderableSeries.getById(
          `${this.type}-signal-line_${this.lineNumber}`
        );
      lineRenderSeries.stroke = config.color;
      lineRenderSeries.strokeThickness = config.thickness;
      lineRenderSeries.isVisible = config.active;
      return;
    }
  }

  delete() {
    this.baseChart.sciChartSurface.renderableSeries.remove(
      this.MACDlineRenderableSeries
    );
  }

  setValue(property: keyof this, value: any) {
    const prop = this[property];
    if (typeof this[property] == 'number') {
      this[property] = +value as unknown as typeof prop;
    } else {
      this[property] = value;
    }
    this.updateSettings();
  }

  getValuesByIndex(index?: number): TTooltipIndicatorValue[] {
    if (!this.MACDDataSeries || this.MACDDataSeries.count() == 0) return [];

    const precision = this.precision;
    const lineValues = this.MACDDataSeries.getNativeYValues();
    const lineValue = {
      value: lineValues.get(index || lineValues.size() - 1),
      precision,
      color: this.renderSeriesConfig[2].color,
    };

    const signalValues = this.signalDataSeries.getNativeYValues();
    const signalValue = {
      value: signalValues.get(index || signalValues.size() - 1),
      precision,
      color: this.renderSeriesConfig[3].color,
    };

    const histogramValues = this.histogramDataSeries.getNativeYValues();
    const value = histogramValues.get(index || histogramValues.size() - 1);
    const histogramValue = {
      value: value,
      precision,
      color:
        value >= 0
          ? this.renderSeriesConfig[0].color
          : this.renderSeriesConfig[1].color,
    };

    return [histogramValue, lineValue, signalValue];
  }

  resetConfiguration(): void {
    setDefaultConfiguration(
      this,
      this.baseChart,
      {},
      this.settings,
      this.renderSeriesConfig,
      this.styles,
      this.type
    );
  }
}
