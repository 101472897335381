import { TIGER_INDICATORS_MENU_ENUM } from '../../enum/tiger-chart.enum';
import MenuItemBase from './menu-item-base';

export default class FixInScalePinLeft extends MenuItemBase {
  constructor() {
    super();
    this.label = 'Fixar a esquerda';
    this.id = TIGER_INDICATORS_MENU_ENUM.SCALE_PIN_TO_NEW_LEFT;
  }

  override onCLick(): void {}
}
