import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  WritableSignal,
  signal,
} from '@angular/core';
import { ISearchStock, IWorkSpaceComponet } from '@core/interface';
import {
  TOptionsExpirationDatesResponse,
  TOptionsSubheaderTabs,
  TOptionsTab,
  TStrategyOptionExpirationDate,
} from './types';
import { HomeService } from '@modules/home/service/home.service';
import { OptionsComponentService } from './options-component.service';
import { OptionsService } from '@shared/services/api/trademap/v1/options.service';
import { Subject, takeUntil } from 'rxjs';
import { daysUntilDate, formatDate } from 'src/app/utils/utils.functions';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { ActivatedRoute } from '@angular/router';
import { isWebViewContext } from 'src/app/desktop/integration.utils';
import { deepClone } from '@shared/rocket-components/utils';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsComponent extends RocketComponentBase implements OnDestroy {
  @Input() set component(component: IWorkSpaceComponet) {
    if (component) {
      this._component.set(component);
    }
  }
  _component: WritableSignal<IWorkSpaceComponet | undefined> =
    signal(undefined);
  @Input() refComponent!: string;
  @Input() height!: string;
  @Input() width!: string;
  @Input() link!: boolean;
  selectedTab!: TOptionsTab;
  selectedSubheaderTab!: TOptionsSubheaderTabs;
  expirationDates: TStrategyOptionExpirationDate[] = [];
  expirationDatesHash: any = {};
  loading = true;
  isDesktop = false;
  disableChangeStock = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private cdr: ChangeDetectorRef,
    private _homeService: HomeService,
    private _optionsService: OptionsService,
    private _optionsComponentService: OptionsComponentService,
    activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
    this.isDesktop = isWebViewContext();
  }

  protected initComponent(component: any): void {
    if (component) this.component = component;

    if (this.isDesktop) {
      this.height =
        this._component()?.metadata.componentResize?.height + '' ??
        this._component()?.metadata.layout.height;
      this.width =
        this._component()?.metadata.componentResize?.width + '' ??
        this._component()?.metadata.layout.width;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onTabChange(tab: TOptionsTab) {
    this.disableChangeStock = true;
    this.selectedTab = tab;
    if(tab.stock) this._saveSelectedTab();
    this._optionsComponentService.clearStrategyOptions();
    this._loadStockDExpirationates();
    this.cdr.detectChanges();
  }

  onSubheaderTabChange(tab: TOptionsSubheaderTabs) {
    this.disableChangeStock = true;
    this.selectedSubheaderTab = tab;
    this.cdr.detectChanges();
  }

  onStockChange(stock: ISearchStock) {
    this.disableChangeStock = true;
    this.selectedTab.stock = deepClone(stock);
    this.cdr.detectChanges();
    this._saveSelectedTab();
    this._optionsComponentService.clearStrategyOptions();
    this._loadStockDExpirationates();
  }

  private _saveSelectedTab() {
    this._component()!!.metadata.component = {
      ...this._component()!!.metadata.component,
      selectedTab: this.selectedTab,
    };
    this._homeService.updateMeta(this._component()!!);
  }

  private _clearValues(enableLoading: boolean = false): void {
    this.expirationDates = [];
    this.expirationDatesHash = {};
    this.disableChangeStock = false;
    this.loading = enableLoading;
    enableLoading && this._optionsComponentService.clearStrategyOptions();
    this.cdr.detectChanges();
  }

  private _loadStockDExpirationates() {
    if (!this.selectedTab?.stock) return;
    this._clearValues(true);
    const { cd_stock, id_exchange } = this.selectedTab.stock;
    this._optionsService
      .getStockExpirationDates(cd_stock, id_exchange)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: TOptionsExpirationDatesResponse) => {
          if (!response.expiration_dates) {
            this._clearValues();
            return;
          }
          this.expirationDates = deepClone(
            response.expiration_dates.map((date) => {
              const workingDays = daysUntilDate(
                date.dt_expiration_option
              ).workingDays;
              const formattedDate = formatDate(date.dt_expiration_option, true);
              const infoExpiration = {
                id: date.dt_expiration_option,
                label: formatDate(date.dt_expiration_option),
                twoDigitsYearLabel: `${workingDays} | ${formattedDate}`,
                tooltip: `<div class="d-flex flex-column gap-1"><span>Dias para o vencimento: ${workingDays}</span><span>Data do vencimento: ${formattedDate}</span></div>`,
              };
              this.expirationDatesHash[date.dt_expiration_option.toString()] =
                infoExpiration;
              return infoExpiration;
            })
          );
          this.loading = false;
          this.cdr.detectChanges();
        },
        error: () => this._clearValues(),
      });
  }

  public onLoadContent(): void {
    this.disableChangeStock = false;
    this.cdr.detectChanges();
  }
}
