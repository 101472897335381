import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { system } from '@core/system/system.service';
import { InitializeBrokerConnectService } from '@shared/services/initialize-broker-connect.service';
import { isWebViewContext } from 'src/app/desktop/integration.service';
import { RocketStreamRead } from '@shared/channel/rx-event';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent
  extends ReadStreamBase
  implements AfterViewInit, OnDestroy
{
  isWebOnly: boolean = true;
  private stockStreamActive!: RocketStreamRead;
  constructor(
    private initializeBrokerConnectService: InitializeBrokerConnectService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    if (
      system.userAuthenticated &&
      !system.userAuthenticated.investor.isSessionWeak
    ) {
      this.initializeBrokerConnectService.initializeBroker$.next(true);
    }
    this.isWebOnly = !isWebViewContext();
  }

  ngOnDestroy(): void {
    this.stockStreamActive && this.stockStreamActive.close();
  }
}
