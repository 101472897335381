import { Injectable } from '@angular/core';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import {
  COLOR_RANGE_VARIATION_RANGE,
  TvariationRange,
} from './color-range.interface';

@Injectable({
  providedIn: 'root',
})
export class ColorRangeServiceService {
  private _colors = {
    negative_half: { dark: 0xed3965, light: 0xe6a1b1 },
    positive_half: { dark: 0x26ab58, light: 0xbef7dd },
    negative: { dark: 0xf2003b, light: 0xe61c4d },
    positive: { dark: 0x03ff61, light: 0x04b546 },
  };

  constructor() {}

  getColor(variation: number, variationRange: TvariationRange) {
    if (!variation) return CHART_COLORS.BRAND_PRIMARY;

    const absoluteValue = Math.abs(variation);
    const { variationValue, variationColorKey } = this._getColorKeys(
      variation,
      variationRange
    );

    let color = Math.round((absoluteValue * 255) / Math.abs(variationValue));
    color = ((100 / 255) * color) / 100;

    const interpolatedColor =
      '#' +
      this.interpolateColor(
        this._colors[variationColorKey].light,
        this._colors[variationColorKey].dark,
        color
      );
    return interpolatedColor;
  }

  interpolateColor(fromColor: any, toColor: any, progress: number) {
    const q = 1 - progress;

    const fromA = (fromColor >> 24) & 0xff;
    const fromR = (fromColor >> 16) & 0xff;
    const fromG = (fromColor >> 8) & 0xff;
    const fromB = fromColor & 0xff;
    const toA = (toColor >> 24) & 0xff;
    const toR = (toColor >> 16) & 0xff;
    const toG = (toColor >> 8) & 0xff;
    const toB = toColor & 0xff;
    const resultA = fromA * q + toA * progress;
    const resultR = fromR * q + toR * progress;
    const resultG = fromG * q + toG * progress;
    const resultB = fromB * q + toB * progress;
    const resultColor =
      (resultA << 24) | (resultR << 16) | (resultG << 8) | resultB;

    return ('000000' + resultColor.toString(16)).substr(-6);
  }

  private _getColorKeys(
    variation: number,
    variationRange: TvariationRange
  ): {
    variationValue: number;
    variationColorKey: COLOR_RANGE_VARIATION_RANGE;
  } {
    if (variation > 0) {
      const isSmall = variation < variationRange.positive / 3;
      return {
        variationValue: isSmall
          ? variationRange[COLOR_RANGE_VARIATION_RANGE.POSITIVE] / 3
          : variationRange[COLOR_RANGE_VARIATION_RANGE.POSITIVE],
        variationColorKey: isSmall
          ? COLOR_RANGE_VARIATION_RANGE.POSITIVE_HALF
          : COLOR_RANGE_VARIATION_RANGE.POSITIVE,
      };
    }

    const isSmall = variation > variationRange.negative / 3;
    return {
      variationValue: isSmall
        ? variationRange[COLOR_RANGE_VARIATION_RANGE.NEGATIVE] / 3
        : variationRange[COLOR_RANGE_VARIATION_RANGE.NEGATIVE],
      variationColorKey: isSmall
        ? COLOR_RANGE_VARIATION_RANGE.NEGATIVE_HALF
        : COLOR_RANGE_VARIATION_RANGE.NEGATIVE,
    };
  }
}
