import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { RestService } from '@shared/services/rest';
import { catchError, map, Observable, of } from 'rxjs';
import { ToastService } from '@shared/services/toast.service';
import { IBrokersToConnect } from './models/brokers-to-connect.interface';
import { IBrokerConnectionInfos } from './models/brokers-connection-infos.interface';
import { IBrokerServiceResponse } from './models/rocket-weak-brokers-response.interface';
import { AdjustSdkService } from '@shared/services/core/adjust/adjust-sdk.service';

@Injectable({
  providedIn: 'root',
})
export class TrademapV9Service extends RestService {
  override _url: string = 'api/trademap/v9';
  private _brokersToConnect: IBrokersToConnect[] = [];
  private _brokerInfos: { [idBroker: string]: IBrokerConnectionInfos } = {};
  private _adjustSdkService = inject(AdjustSdkService);
  private _toast = inject(ToastService);

  constructor(private http: HttpClient) {
    super(http);
  }

  // ROCKET WEAK PATH
  public nitroSevenDaysContract(): Observable<boolean> {
    return this.post('rocket-weak/seven-days-contract', {}).pipe(
      catchError((err) => of(this.handlerServiceError(err))),
      map((response) => {
        if (
          !response ||
          !response?.data ||
          !response.data.success ||
          !response.data.result
        )
          throw response;
        return true;
      })
    );
  }

  public getBrokersToConnect(
    updateBrokersAfterConnection: boolean = false
  ): Observable<IBrokersToConnect[]> {
    if (!updateBrokersAfterConnection && this._brokersToConnect.length)
      return of(this._brokersToConnect);
    return this.get<IBrokerServiceResponse<any>>(
      'rocket-weak/get-brokers-to-connect'
    ).pipe(
      map((res) => {
        this._brokersToConnect = res.data?.result || [];
        return res.data?.result || [];
      }),
      catchError(() => {
        this._toast.showToast(
          'error',
          'Ocorreu um erro ao buscar as corretoras disponíveis, tente novamente.'
        );
        return of([]);
      })
    );
  }

  public getBrokerConnectionInfos(
    idBroker: number
  ): Observable<IBrokerConnectionInfos> {
    if (this._brokerInfos[idBroker]) return of(this._brokerInfos[idBroker]);
    return this.post<IBrokerServiceResponse<IBrokerConnectionInfos>>(
      'rocket-weak/get-broker-connection-details',
      { idBroker }
    ).pipe(
      map((res) => {
        this._brokerInfos[idBroker] = res.data.result;
        return res.data.result;
      }),
      catchError(() => {
        this._toast.showToast(
          'error',
          'Ocorreu um erro ao buscar as informações de conexão da corretora, tente novamente.'
        );
        return of();
      })
    );
  }

  // ANALYTICS
  public saveDeepLinkEvents(origin: 'NEW_LOGIN' | 'NEW_REGISTER'): void {
    const attr = this._adjustSdkService.getAttribuitionInfos();
    if (!attr || !attr?.tracker_token) return;
    const params = this._adjustSdkService.getEvents(attr.tracker_token, origin);
    this.post<void>('analytics/save-deep-link', {
      requests: [params],
    }).subscribe(() => this._adjustSdkService.resetSdkIndexDBAfterSendEvent());
  }
}
