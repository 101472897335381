import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { IShortcutFastFilter } from '../interface/trades-filter.interface';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-trades-filter-shortcut',
  templateUrl: './trades-filter-shortcut.component.html',
  styles: [
    `
      .switch-size {
        width: 40px;
        height: 20px;

        .custom-size {
          width: 32px;
          height: 16px;
          cursor: pointer;
        }
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, TooltipsModule, FormsModule],
})
export class TradesFilterShortcutComponent implements OnChanges {
  @Input() stockStandardLot: number = 100;
  @Input() public enableFiltersAndHighlights = false;
  @Input() public disableShortCutFilter = false;
  @Input() public initialFastTradeID = -1;

  @Output() updateFiltersAndHighlightsVisibility = new EventEmitter<boolean>();
  @Output() updateFastFilter = new EventEmitter<IShortcutFastFilter | null>();

  public filterOptions: IShortcutFastFilter[] = [];
  public switchFunctionalityTooltip = 'Ativar filtro e destaques';
  private _lastFilterSelected: IShortcutFastFilter | null = null;

  ngOnChanges(changes: SimpleChanges) {
    const {
      stockStandardLot,
      disableShortCutFilter,
      initialFastTradeID,
      enableFiltersAndHighlights,
    } = changes;
    if (stockStandardLot?.currentValue) this._configureFiltersByStockLot();
    if (disableShortCutFilter?.currentValue) this._clearLastOption(true, false);
    if (initialFastTradeID?.currentValue >= 0)
      this._setInitialFastTrade(initialFastTradeID.currentValue);
    if (enableFiltersAndHighlights?.currentValue !== undefined)
      this.onChangeFiltersAndHighlights(
        enableFiltersAndHighlights.currentValue,
        false
      );
  }

  private _configureFiltersByStockLot(): void {
    const lot = this.stockStandardLot ?? 100;
    this.filterOptions = [
      { id: 0, qtty: lot * 1, active: false, type: '>=' },
      { id: 1, qtty: lot * 3, active: false, type: '>=' },
      { id: 2, qtty: lot * 5, active: false, type: '>=' },
      { id: 3, qtty: lot * 10, active: false, type: '>=' },
    ];
  }

  public onChangeFiltersAndHighlights(value: boolean, emitEvent = true): void {
    if (value === this.enableFiltersAndHighlights) return;
    this.enableFiltersAndHighlights = value;
    this.switchFunctionalityTooltip = `${
      value ? 'Desativar' : 'Ativar'
    } filtro e destaques`;
    if (value && this._lastFilterSelected)
      this.filterOptions[this._lastFilterSelected.id].active = true;
    else this._clearLastOption(false);
    if (!emitEvent) return;
    this.updateFiltersAndHighlightsVisibility.emit(value);
  }

  public setQttyFilter(option: IShortcutFastFilter): void {
    if (option.id === this._lastFilterSelected?.id) {
      this._clearLastOption(true);
      return;
    }
    this._clearLastOption(false);
    this.filterOptions[option.id].active = true;
    this._lastFilterSelected = option;
    this.updateFastFilter.emit(option);
  }

  private _clearLastOption(
    clearVariable: boolean = true,
    emitEvent: boolean = true
  ): void {
    if (this._lastFilterSelected) {
      this.filterOptions[this._lastFilterSelected.id].active = false;
      if (clearVariable) this._lastFilterSelected = null;
      if (emitEvent) this.updateFastFilter.emit(null);
    }
  }

  private _setInitialFastTrade(index: number): void {
    this.filterOptions[index].active = true;
    this._lastFilterSelected = this.filterOptions[index];
  }
}
