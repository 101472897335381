import { GridIColumnVisible } from '@shared/rocket-grid';
import { StockListStettingsService } from '@services/api/nitro-ws/v1/stock-list-stettings.service';
import { IListStockConfig, IListStockDB } from 'src/app/core/interface';
import {
  HEADERS,
  HEADERS_AUCTION_IGNORE,
} from '../constants/headers.constants';
import { ListStocksService } from '@shared/services/core/list-stocks/list-stocks.service';

export class UpdatesConfig {
  constructor(
    private listStocksService: ListStocksService,
    private stockListStettingsService: StockListStettingsService
  ) {}

  public updateListConfig(listSelected: IListStockDB): void {
    this.stockListStettingsService
      .updateConfig(listSelected.configList!)
      .subscribe();
    this.listStocksService.updateStockList({
      idStockList: listSelected.idStockList.toString(),
      value: listSelected,
    });
  }

  public columnMoved(listSelected: IListStockDB, event: any[]): void {
    const reorderHeaders: any = {};
    const headers = (listSelected.configList as IListStockConfig).metadata
      .headers;
    event.forEach((item: any) => {
      const header = headers[item.colId];
      if (!header && HEADERS[item.colId]) {
        reorderHeaders[`${item.colId}`] = HEADERS[item.colId];
      } else if (header) {
        reorderHeaders[`${item.colId}`] = header;
      }
    });
    (listSelected.configList as IListStockConfig).metadata.headers =
      reorderHeaders;
    this.updateListConfig(listSelected);
  }

  public visibleOrInvisible(
    listSelected: IListStockDB,
    columns: any[],
    isListAuction: boolean = false
  ): GridIColumnVisible[] {
    const visible: GridIColumnVisible = { keys: [], visible: true };
    const invisible: GridIColumnVisible = { keys: [], visible: false };
    const headers = (listSelected.configList as IListStockConfig).metadata
      .headers;
    columns.forEach((item: any) => {
      if (!isListAuction && HEADERS_AUCTION_IGNORE.includes(item.field)) {
        return;
      }
      if (item.hide) {
        headers[item.field] && (headers[item.field].hide = item.hide);
        !headers[item.field] &&
          (headers[item.field] = { width: item.width, hide: item.hide });
        invisible.keys.push(item.field);
      } else {
        headers[item.field] && (headers[item.field].hide = item.hide);
        !headers[item.field] &&
          (headers[item.field] = { width: item.width, hide: item.hide });
        visible.keys.push(item.field);
      }
    });
    (listSelected.configList as IListStockConfig).metadata.headers = headers;
    this.updateListConfig(listSelected);
    return [visible, invisible];
  }
}
