import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[appCarouselScroll]',
  standalone: true,
})
export class CarouselScrollDirective implements AfterViewInit, OnDestroy, OnChanges {
  @Input() carouselMaxWidth: string = '100%';
  @Input() carouselDelay: number = 0;
  @Input() carouselBtnColor: string = 'bg-neutral-stronger';
  @Input() itemsLength: number = 0

  private element!: HTMLElement | null;
  private parent!: HTMLElement | null | undefined;
  private btnPrev!: HTMLElement
  private btnNext!: HTMLElement
  private BTN_SIZE: string = '20px';
  private resizeObserver!: ResizeObserver
  constructor(
    private el: ElementRef,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.element = this.el.nativeElement;
    this.parent = this.element?.parentElement
  }

  ngAfterViewInit(): void {
    setTimeout(() => this._init(), this.carouselDelay);
    this.resizeObserver = new ResizeObserver(() => {
      this.checkSize()
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    const { itemsLength } = changes 
    if(itemsLength?.previousValue !== itemsLength?.currentValue){
      setTimeout(() => {
        this.checkSize();
        if(itemsLength?.currentValue > itemsLength?.previousValue){
          this.element?.scrollTo({left: this.element.scrollWidth - this.element.offsetWidth})
        }
        if(itemsLength?.previousValue > itemsLength?.currentValue){
          this.element?.scrollTo({left: 0})
        }
      }, this.carouselDelay);
    }
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect()
  }

  _init() {
    if (!this.element) return;
    this._changeContainer();
    const iconPrev = this._document.createElement('i');
    iconPrev.classList.add('icons', 'icon-size-nano', 'opacity-semi-opaque', 'opacity-semi-transparent');
    iconPrev.textContent = 'arrow_back_ios';

    const iconNext = this._document.createElement('i');
    iconNext.classList.add('icons', 'icon-size-nano', 'opacity-semi-opaque', 'opacity-semi-transparent');
    iconNext.textContent = 'arrow_forward_ios';

    this.btnPrev = this._buildBtn(iconPrev);
    this.btnPrev.id = 'CAR_PREV_BTN';
    this.btnPrev.style.left = '0px';

    this.btnNext = this._buildBtn(iconNext);
    this.btnNext.id = 'CAR_NEXT_BTN';
    this.btnNext.style.right = '0px';

    this.parent?.appendChild(this.btnPrev);  
    this.parent?.appendChild(this.btnNext);
    this.resizeObserver.observe(this.element);
    this.checkSize()
    this.setButtonOpacity()
  }

  _changeContainer = () => {
    if (!this.element) return;
    this.element.classList.add(
      'd-flex',
      'flex-nowrap',
      'overflow-hidden'
    );
    this.element.style.width = '100%';
    this.element.addEventListener('scrollend', () => {
      this.setButtonOpacity()
    })
    if(this.parent){
      this.parent.classList.add('position-relative')
      this.parent.style.paddingLeft = this.BTN_SIZE;
      this.parent.style.paddingRight = this.BTN_SIZE;
      this.parent.style.width = this.carouselMaxWidth;
    }
  };

  _buildBtn = (icon?: Element) => {
    const btn = this._document.createElement('div');
    btn.classList.add(
      'd-flex',
      'align-items-center',
      'justify-content-center',
      'h-100',
      'cursor-pointer',
      'user-select-none',
      this.carouselBtnColor
    );
    btn.style.position = 'absolute';
    btn.style.width = this.BTN_SIZE;
    btn.addEventListener('mouseenter', (event: any) => {
      if(event.currentTarget.lastChild.classList.contains('opacity-semi-opaque')){
        event.currentTarget.lastChild.classList.add('opacity-100');
      }
    });
    btn.addEventListener('mouseleave', (event: any) => {
      event.currentTarget.lastChild.classList.remove('opacity-100');
    });
    icon && btn.appendChild(icon);
    btn.addEventListener('click', (event: any) => {
      this._scroll(event.currentTarget as HTMLDivElement)
    });
    return btn;
  };

  _scroll(el: HTMLDivElement) {
    if(el.id === 'CAR_NEXT_BTN'){
      this.element?.scrollBy({
        left: 120,
        behavior: 'smooth'
      })
    }else if(el.id === 'CAR_PREV_BTN'){
      this.element?.scrollBy({
        left: -120,
        behavior: 'smooth'
      })
    }
  }

  checkSize() {
    if(!this.element || !this.btnPrev || !this.btnNext) return;

    if(this.element?.scrollWidth > this.element?.offsetWidth){
      this.btnPrev.classList.remove('d-none')
      this.btnNext.classList.remove('d-none')
      if(this.parent){
        this.parent.style.paddingLeft = this.BTN_SIZE;
        this.parent.style.paddingRight = this.BTN_SIZE;
      }
    }else{
      this.btnPrev.classList.add('d-none')
      this.btnNext.classList.add('d-none')
      if(this.parent){
        this.parent.style.paddingLeft = '0px';
        this.parent.style.paddingRight = '0px';
      }
    }
  }

  setButtonOpacity = () => {
    if(this.element){
      if(this.element.scrollLeft === 0){
        (this.btnPrev.lastChild as HTMLButtonElement).classList.remove('opacity-semi-opaque');
      }else{
        (this.btnPrev.lastChild as HTMLButtonElement).classList.add('opacity-semi-opaque');
      }
      if(this.element.scrollLeft === this.element.scrollWidth - this.element.offsetWidth){
        (this.btnNext.lastChild as HTMLButtonElement).classList.remove('opacity-semi-opaque')
      }else{
        (this.btnNext.lastChild as HTMLButtonElement).classList.add('opacity-semi-opaque');
      }
    }
  }
}
