<a [href]="url" [target]="urlTarget" [ngClass]="customClass">
  <ng-container [ngSwitch]="iconPosition">
    <ng-container *ngSwitchCase="'left'">
      <ng-content *ngTemplateOutlet="icon"></ng-content>
      {{ text }}
    </ng-container>

    <ng-container *ngSwitchCase="'right'">
      {{ text }}
      <ng-content *ngTemplateOutlet="icon"></ng-content>
    </ng-container>

    <ng-container *ngSwitchDefault>
      {{ text }}
    </ng-container>
  </ng-container>
</a>

<ng-template #icon>
  <ng-content select="fla-icon"></ng-content>
</ng-template>
