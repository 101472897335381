import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'fla-tab-body[ref]',
  templateUrl: './fla-tab-body.component.html',
})
export class FlaTabBodyComponent {
  @ViewChild(TemplateRef) content!: TemplateRef<any>;
  @Input('ref') refId!: string;
  @Input() active!: boolean;
  @Input('css') customClass: string = '';

  constructor(public cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
  }
}
