import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '@shared/services/rest';
import { catchError, map, Observable, of } from 'rxjs';
import { ToastService } from '@shared/services/toast.service';
import { IBrokerServiceResponse } from '../V9/models/rocket-weak-brokers-response.interface';
import { IBrokersToConnectV10 } from './models/brokers-to-connect.interface';

@Injectable({
  providedIn: 'root',
})
export class TrademapV10Service extends RestService {
  override _url: string = 'api/trademap/v10/rocket-weak';

  constructor(private http: HttpClient, private _toast: ToastService) {
    super(http);
  }

  public getBrokersToConnect(): Observable<IBrokersToConnectV10> {
    const emptyValue = { brokerList: [], hasBrokerAccount: false };
    return this.get<IBrokerServiceResponse<any>>('get-brokers-to-connect').pipe(
      map((res) => {
        if (res.data?.result) return res.data?.result;
        return emptyValue;
      }),
      catchError(() => {
        this._toast.showToast(
          'error',
          'Ocorreu um erro ao buscar as corretoras disponíveis, tente novamente.'
        );
        return of(emptyValue);
      })
    );
  }
}
