<ng-template let-refComponent="refComponent">
  <input type="hidden" [attr.data-bs-ref]="id" [value]="refComponent" />
  <div
    class="form-check"
    [ngClass]="{
      'form-check-inline': isInline,
      'form-selection': isSelection || isTag,
      'form-selection-sm': isSmallSelection
    }"
  >
    <input
      class="form-check-input"
      type="radio"
      [id]="id"
      [name]="name"
      [checked]="checked"
      [readonly]="readonly"
      [value]="value"
      [disabled]="disabled"
      (change)="changes(value)"
    />
    <label
      class="form-check-label"
      [ngClass]="[isTag ? 'tag' : '', customClass]"
      [for]="id"
      *ngIf="showLabel"
    >
      {{ label }}
    </label>
  </div>
</ng-template>
