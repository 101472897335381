import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlaTabBodyComponent } from './fla-tab-body/fla-tab-body.component';
import { FlaTabHeaderComponent } from './fla-tab-header/fla-tab-header.component';
import { FlaTabComponent } from './tab.component';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';

@NgModule({
  imports: [CommonModule, TooltipsModule],
  declarations: [FlaTabComponent, FlaTabHeaderComponent, FlaTabBodyComponent],
  exports: [FlaTabComponent, FlaTabHeaderComponent, FlaTabBodyComponent],
})
export class FlaTabModule {}
