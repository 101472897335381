import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-graphic-screening-header',
  templateUrl: './graphic-screening-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphicScreeningHeaderComponent implements OnChanges {
  @Input() stocks!: string[];
  @Output() changeTab: EventEmitter<string> = new EventEmitter<string>();
  public list!: string[];
  public indextab!: number;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(): void {
    this.list = this.stocks.map((item: string) =>
      item.split(':').shift()
    ) as string[];
    this.indextab = 0;
    this.cdr.detectChanges();
  }

  public selectTab(index: number, externalChange: boolean = false): void {
    if (index === this.indextab) return;
    this.indextab = index;
    this.cdr.detectChanges();
    !externalChange && this.changeTab.emit(this.stocks[index]);
  }
}
