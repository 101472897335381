import { Injectable } from '@angular/core';
import { ConfigService } from '@core/config';
import { SHORT_CUTS_TABS } from '../constants/header.contanst';
import { IShortcutTabs } from '../interfaces/header.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ModalShortcutsService {
  constructor(private configService: ConfigService) {}

  getShortcutTabs() {
    const shortCutTabs = SHORT_CUTS_TABS;
    if (!this.configService.isBroker()) {
      return shortCutTabs;
    }
    const hashBrokerTabs: any = {
      GERAL: true,
      CHART: true,
    };
    const shortcuts: IShortcutTabs[] = [];
    shortCutTabs.forEach((shortcut) => {
      if (hashBrokerTabs[shortcut.ref]) {
        shortcuts.push(shortcut);
      }
    });
    return shortcuts;
  }
}
