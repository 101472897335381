import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { HeaderService } from '@core/layout/header/header.service';
import { ComponentsService } from '@core/workspace/service/components.service';
import { HomeService } from '@modules/home/service/home.service';
import { deepClone } from '@shared/rocket-components/utils';
import { Subject, debounceTime } from 'rxjs';

export interface ContextMenuModel {
  menuText: string;
  menuEvent: string;
}

export interface IContextMenuOptions {
  title?: string;
  type?: 'DUPLICATE' | 'TOOLS' | 'WORKSPACE_TAB';
  position: { top: number; left: number };
  data?: any;
}

export enum WS_COMMANDS {
  EDIT_NAME,
  DUPLICATE_WS,
  DELETE_WS,
  REFRESH_WS,
}

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('workspaceContext') public workspaceContext!: ElementRef;

  @Input() options!: IContextMenuOptions;
  @Input() show: boolean = false;

  @Output() changes = new EventEmitter<{
    event: any;
    show: boolean;
  }>();

  @Output() menuClose = new EventEmitter<{
    show: boolean;
  }>();

  componentsId = 'COMPONENTS';
  toolsId = 'TOOLS';
  public leftPosition: number = -999;
  public topPosition: number = -999;
  private _contextPositionSubject = new Subject<{
    top: number;
    left: number;
  }>();

  menuTab: any = [];
  selectedComponentsMenu = this.componentsId;
  public componentsQty: any = {};

  get menuTool() {
    return this.selectedComponentsMenu === this.componentsId
      ? this.componentsService.getComponentsList()
      : this.componentsService.getToolsList();
  }

  get isLastWs() {
    return this.data?.isLastWs;
  }

  get data() {
    return this.options?.data;
  }

  get menuWorkspaceTabs() {
    return [
      {
        icon: 'edit',
        label: 'Editar Nome',
        cod: WS_COMMANDS.EDIT_NAME,
        isDisabled: this.headerService.isLockWorkspace(),
      },
      {
        icon: 'library_add',
        label: 'Duplicar',
        cod: WS_COMMANDS.DUPLICATE_WS,
        isDisabled: false,
      },
      {
        icon: 'delete',
        label: 'Apagar',
        cod: WS_COMMANDS.DELETE_WS,
        isDisabled: this.headerService.isLockWorkspace(),
      },
      {
        icon: 'refresh',
        label: 'Redefinir workspace',
        cod: WS_COMMANDS.REFRESH_WS,
        isDisabled: this.headerService.isLockWorkspace(),
      },
    ];
  }

  constructor(
    private homeService: HomeService,
    private headerService: HeaderService,
    private _cdr: ChangeDetectorRef,
    private componentsService: ComponentsService
  ) {
    this._contextPositionSubject
      .pipe(debounceTime(100))
      .subscribe(this._verifyContextPosition);
  }

  ngOnInit(): void {
    this.componentsQty = this.homeService.componentsQty();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { options } = changes;
    if (this.options.type === 'WORKSPACE_TAB') this.refresMenuTab();
    if (options?.currentValue)
      this._contextPositionSubject.next(options.currentValue.position);
  }

  ngOnDestroy(): void {
    this._contextPositionSubject.unsubscribe();
  }

  refresMenuTab() {
    if (
      this.options.data.isLastWs ||
      this.headerService.activeWorkspace() === this.data.ws.id
    ) {
      const menuWS = deepClone(this.menuWorkspaceTabs);
      menuWS[
        menuWS.findIndex((option: any) => option.cod === WS_COMMANDS.DELETE_WS)
      ].isDisabled = true;
      this.menuTab = menuWS;
      return;
    }
    this.menuTab = this.menuWorkspaceTabs;
    this._cdr.detectChanges();
  }

  @HostListener('document:click', ['$event'])
  documentClick(e: any): void {
    const element = e.target as HTMLElement;
    if (!element.classList.contains('prevent-close')) {
      this.close();
    }
  }

  close() {
    this.show = false;
    this.menuClose.emit({ show: this.show });
  }

  emitEventDuplicate(event: any) {
    event.stopPropagation();
    this.changes.emit({
      show: false,
      event: 'DUPLICATE',
    });
  }

  addComponent(component?: any) {
    const newComp = { ...component };
    this.homeService.addComponent(newComp);
  }

  runCommand(command?: any) {
    this.homeService.execComponent$.next({ command, data: this.data.ws });
  }

  private _verifyContextPosition = (position: {
    top: number;
    left: number;
  }): void => {
    const container = document.getElementById('workspace_base');
    const contextRef = this.workspaceContext.nativeElement;
    if (!container || !contextRef) {
      this.topPosition = position.top;
      this.leftPosition = position.left;
      console.error('ELEMENT_NOT_FOUND');
      return;
    }
    this.leftPosition =
      position.left + 210 < container.offsetWidth
        ? position.left
        : container.offsetWidth - 210;

    this.topPosition =
      position.top + contextRef.offsetHeight < container.offsetHeight
        ? position.top
        : container.offsetHeight - contextRef.offsetHeight;
    this._cdr.detectChanges();
  };
}
