import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { RestService } from '@shared/services/rest';
import { IAPIServiceResponse } from '../../trademap/v1/interface/apiResponse';
import { Observable, Subject, catchError, map, of } from 'rxjs';
import { TTokenPreferenceAuthenticator } from '@core/layout/header/modal-token-config/types';
import { system } from '@core/system/system.service';
import { CustomPreferencesService } from '../../nitro-ws/v1/custom-preferences.service';
import { AUTH_LOCAL_KEYS } from '@shared/services/core/const/auth_util.const';
import { ModalTokenConfigComponent } from '@core/layout/header/modal-token-config/modal-token-config.component';
import { RocketModalService } from '@shared/rocket-components/components';

@Injectable({
  providedIn: 'root',
})
export class OtpService extends RestService {
  override _url: string = 'api/authentication/v3/otp';
  public activateAuthenticatorConfig$ = new Subject<{ isForce: boolean }>();
  private _totalTimesDisplayed!: number;
  private _customPreferencesService = inject(CustomPreferencesService);
  private _modalService = inject(RocketModalService);

  constructor(http: HttpClient) {
    super(http);
  }

  public sendAuthenticatorConfigMessage = (
    password: string,
    cdOrigin: TTokenPreferenceAuthenticator
  ): Observable<IAPIServiceResponse> => {
    return this.post('createAndSendPinCode', {
      password: btoa(password),
      cdOrigin,
    }).pipe(
      map((res: any) => {
        if (!res.data || !res.data.success)
          return this.handlerServiceError(res);
        return { success: true, message: res.data.result.next_interaction };
      }),
      catchError((error) => of(this.handlerServiceError(error)))
    );
  };

  public activateAuthenticatorConfig = (
    code: string,
    cdOrigin: TTokenPreferenceAuthenticator,
    isActiveSeed: boolean
  ): Observable<IAPIServiceResponse> => {
    return this.post('validateInvestorCode', {
      code,
      cdOrigin,
      otpPreference: cdOrigin,
      isActiveSeed,
    }).pipe(
      catchError((error) => of(this.handlerServiceError(error))),
      map((res: any) => {
        if (!res.data || !res.data.success)
          return this.handlerServiceError(res);
        this.activateAuthenticatorConfig$.next({ isForce: false });
        return {
          success: res.data.success,
          message: res.data.result,
        };
      })
    );
  };

  public getQRCodeUrl = (
    code: string,
    cdOrigin: TTokenPreferenceAuthenticator
  ): Observable<IAPIServiceResponse> => {
    return this.post('getQRCodeUrl', {
      code,
      cdOrigin,
    }).pipe(
      map((res: any) => {
        if (!res.data || !res.data.success)
          return this.handlerServiceError(res);
        return { success: res.data.success, message: res.data.result };
      }),
      catchError((error) => of(this.handlerServiceError(error)))
    );
  };

  public userTokenPreferences(): Observable<TTokenPreferenceAuthenticator> {
    if (system.authenticationMethod !== undefined)
      return of(system.authenticationMethod).pipe(map((method) => method));
    return this.post('getInvestorOtpTokenPreference', {}).pipe(
      map((res: any) => {
        if (!res.data || !res.data.success) {
          system.authenticationMethod = 'UNDEFINED_TOKEN';
          return 'UNDEFINED_TOKEN';
        }
        this.verifyTokenPreference(res.data.result);
        system.authenticationMethod = res.data.result;
        return res.data.result;
      })
    );
  }

  verifyTokenPreference(token: string) {
    if (token !== 'TRADEMAP' || this._totalTimesDisplayed) return;
    this._totalTimesDisplayed =
      +this._customPreferencesService.getCustomPreference(
        AUTH_LOCAL_KEYS.COUNT_TRADEMAP_TOKEN_ALERT
      ) || 0;
    if (this._totalTimesDisplayed >= 3) return;
    this._totalTimesDisplayed = this._totalTimesDisplayed + 1;

    this._modalService.open(ModalTokenConfigComponent, {
      size: 'lg',
      centered: true,
      backdrop: false,
      keyboard: false,
      scrollable: false,
      notClosable: true,
      data: {
        tokenPreference: 'TRADEMAP',
      },
    });

    const shouldIncrease =
      this._customPreferencesService.storage.getItem(
        AUTH_LOCAL_KEYS.SHOULD_INCREASE_TRADEMAP_TOKEN_ALERT
      ) !== '0';
    if (!shouldIncrease) return;

    this._customPreferencesService.customPreference = {
      key: AUTH_LOCAL_KEYS.COUNT_TRADEMAP_TOKEN_ALERT,
      value: this._totalTimesDisplayed,
    };

    this._customPreferencesService.storage.setItem(
      AUTH_LOCAL_KEYS.SHOULD_INCREASE_TRADEMAP_TOKEN_ALERT,
      '0'
    );
  }
}
