<app-token-authenticator-disconnect
  *ngIf="authenticatorToDisconnect; else listTemplate"
  [authenticator]="authenticatorToDisconnect"
  (cancel)="cancelDisconnection()"
  (disconnected)="disconnected()"
></app-token-authenticator-disconnect>

<ng-template #listTemplate>
  <div *ngIf="displayTitle" class="fs-5">
    <p class="fs-5">
      Em um cenário digital cada vez mais desafiador, <b>garantir</b> a
      <b>segurança</b> de suas contas é uma <b>prioridade</b> crucial.
    </p>
    <p class="fs-5">
      Uma maneira eficaz de reforçar a segurança de suas contas é adotar o
      <b>segundo fator de autenticação.</b>
    </p>
    <p class="fs-5">
      Assim você garante <b>segurança</b> para <b>envio de ordens</b> e
      <b>mudanças de dados pessoais</b> da sua conta aqui conosco.
    </p>
  </div>
  <p class="fs-5">
    Escolha uma das opções abaixo e configure o seu segundo fator de
    autenticação.
  </p>
  <div class="grid-list gap-3 mt-3">
    <div
      *ngFor="let authenticator of tokenAuthenticators"
      class="gap-1 px-2 py-3 bg-neutral-strong d-flex justify-content-between align-items-center authenticator-card"
      [ngClass]="{ 'cursor-pointer': authenticator.id !== tokenPreference }"
      (click)="selectAuthenticator(authenticator)"
    >
      <div class="d-flex">
        <img
          [src]="authenticator.icon"
          alt="Logo Vale Mobi"
          width="32px"
          class="mr-2"
        />
        <div>
          <h5 class="fs-5 fw-bold">{{ authenticator.name }}</h5>
          <p
            class="fs-6 mb-0"
            *ngIf="tokenPreference !== authenticator.id; else connectedTemplate"
          >
            Baixe o app e siga o passo a passo
          </p>
          <ng-template #connectedTemplate>
            <p class="fs-6 mb-0 text-brand-primary">Conectado</p>
          </ng-template>
        </div>
      </div>
      <div>
        <fla-icon
          *ngIf="tokenPreference !== authenticator.id; else disconnectButton"
          name="chevron_right"
          size="icon-size-tiny"
          css="p-0 text-brand-primary"
        ></fla-icon>
        <ng-template #disconnectButton>
          <rocket-button
            type="outline"
            label="Desconectar"
            (rocketClick)="disconnect(authenticator)"
            [isSmall]="true"
          ></rocket-button>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>
