import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { system } from '@core/system/system.service';
import { HandleTrialHelper } from '@shared/modals/welcome-modals/handle-trial.helper';
import {
  ROCKET_MODAL_DATA,
  RocketButtonModule,
  RocketModalModule,
  RocketModalService,
} from '@shared/rocket-components/components';
import { getUserName } from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-welcome-xp-modal',
  templateUrl: './welcome-xp-modal.component.html',
  styles: [
    `
      :host {
        ::ng-deep {
          .modal-dialog {
            max-width: 975px;
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RocketModalModule, RocketButtonModule],
})
export class WelcomeXpModalComponent
  extends HandleTrialHelper
  implements OnInit
{
  public name: string = '';
  public benefits = [
    {
      title: 'Estabilidade incomparável',
      subTitle:
        'Você precisa de uma plataforma que confie para operar seus tradings',
    },
    {
      title: 'Leveza e versatilidade impecáveis',
      subTitle:
        'Opere de qualquer lugar. Windows, Macs, máquinas mais leves...',
    },
    {
      title: 'Segurança de ponta',
      subTitle: 'Nossa diretriz absoluta é a segurança das suas informações',
    },
    {
      title: 'Navegação moderna, simples e amigável',
      subTitle:
        'Sabemos como é difícil usar algumas plataformas. Viemos pra resolver isso também',
    },
  ];

  constructor(
    @Inject(ROCKET_MODAL_DATA) override data: { trialEnabled: boolean },
    override modalService: RocketModalService
  ) {
    super(data, modalService);
  }

  ngOnInit(): void {
    this._getUserAccountName();
    this.verifyCanEnableTrial();
  }

  private _getUserAccountName(): void {
    const name = getUserName(system.userAuthenticated.investor.name);
    this.name = name ? `, ${name}` : '';
  }

  public openConnectBroker(): void {
    if (this.sevenDaysTrialEnabled) {
      this.initializeSystem();
      return;
    }
    this.closeModalComponent({ openConnectBroker: true });
  }
}
