import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { RestService } from '@shared/services/rest/rest.service';
import { ToastService } from '@shared/services/toast.service';

@Injectable({
  providedIn: 'root',
})
export class ScreeningService extends RestService {
  override _url: string = 'api/nitro-ws/v1';

  constructor(http: HttpClient, private toastService: ToastService) {
    super(http);
  }

  public save(filter: { json: string }): Observable<any> {
    return this.post<any>('screening/save', filter).pipe(
      map((res) => res.data),
      catchError((error: any) => {
        this.toastService.showToast('error', 'Ocorreu um erro no filtro.');
        return error;
      })
    );
  }

  public remove(id: number): Observable<any> {
    return this.delete<any>('screening/' + id).pipe(
      map((res) => res.data),
      catchError((error: any) => {
        this.toastService.showToast(
          'error',
          'Ocorreu um erro ao remover o filtro.'
        );
        return error;
      })
    );
  }

  public backtest(jsonFilter: string): Observable<any> {
    const filter = JSON.parse(jsonFilter);
    filter['investor_id'] = 'subscriber0';
    filter['request_id'] = 'req0';
    return this.post<any>('screening/backtest', filter).pipe(
      map((res) => res.data),
      catchError(() => {
        this.toastService.showToast('error', 'Ocorreu um erro no filtro.');
        return null as any;
      })
    );
  }
}
