<workspace-doc-header
  css="w-100 d-flex header-wrapper border-bottom"
  [item]="component"
>
  <app-search-stock
    #searchStock
    [resetInput]="false"
    (selectStockChange)="selectStock($event)"
    [ref]="refComponent"
    [headerOptions]="headerOptions"
    [initStock]="stockSelected"
  ></app-search-stock>
</workspace-doc-header>
