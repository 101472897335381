import { InjectionToken } from '@angular/core';

export const sharedWorkerFactory = (): SharedWorker => {
  return new SharedWorker(
    new URL('src/app/shared/shared.worker', import.meta.url),
    { name: 'rocketworker', type: 'module' }
  );
};

export const WORKER_FACTORY_TOKEN = new InjectionToken<() => SharedWorker>(
  'WorkerFactory'
);
