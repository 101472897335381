import { formatNumber } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Dictionary } from '@core/models';
import { standardizeDate } from '@shared/rocket-components/utils';
import {
  ExecOrderAnnotation,
  ExecOrderFlagAnnotation,
  OrderFlagOptions,
} from '../annotations/exec-order-annotation';
import { RTTextLengthService } from './text-length.service';
import { CHART_COLORS } from '../colors';
import {
  CategoryCoordinateCalculator,
  OhlcDataSeries,
  TWebAssemblyChart,
  IRenderableSeries,
  AnnotationClickEventArgs,
} from 'scichart';
import { TIGER_INTERVAL_ENUM } from '../enum';
import { INTERVAL_HASH } from '../tiger-chart-events-modal/tiger-chart-events-modal.const';
import { IOrderChartData } from '@shared/channel/order-chart.channel';
import { ORDER_CHART_TYPE } from '@shared/constants/general.contant';
import { Subject } from 'rxjs';
import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { ISearchStock } from '@core/interface';
export const HASH_EXEC_SHOW: any = {
  EXEC: true,
  PARC: true,
  PCAN: true,
};
export const execOrderId = 'EXEC_ORDER_ID';
@Injectable({
  providedIn: 'root',
})
export class ExecutedOrdersService {
  addExecOrderAnnotation$ = new Subject<{
    annotation: ExecOrderAnnotation;
    refComponent: string;
  }>();
  addExecOrderFlagAnnotation$ = new Subject<{
    annotation: ExecOrderFlagAnnotation;
    refComponent: string;
  }>();
  readonly execText: any = {
    PARC: 'parcialmente executada',
    PCAN: 'parcialmente cancelada',
  };
  readonly execFlagPosition = 21;
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private textLengthService: RTTextLengthService
  ) {}

  addExecOrderAnnotationTooltip(execOrderAnnotations: ExecOrderAnnotation[]): {
    biggerText: string;
    annotationTooltipLabel: string[];
    annotationTooltipLeft: number;
    newText: string;
    textWidth: number;
  } {
    let biggerText = '';
    let annotationTooltipLabel: string[] = [];
    const anyBorders = execOrderAnnotations[0].getAnnotationBorders();
    const dictMsg = new Dictionary<string[]>();
    const hashHasBuyAndSell: any = {};
    execOrderAnnotations.forEach((execOrderAnnotation, k) => {
      const { annotationOrder } = execOrderAnnotation;
      const {
        side,
        tipo_ordem,
        action,
        pnl,
        dh_last_update,
        qtty,
        exec_price,
        delta_tempo,
      } = annotationOrder;
      const dsSide = side === 'B' ? 'Compra' : 'Venda';
      const dsOrderType = ORDER_CHART_TYPE[tipo_ordem];
      const formattedQtty = formatNumber(
        parseFloat(qtty),
        this.locale,
        `1.0-0`
      );
      const formattedPrice = formatNumber(
        parseFloat(exec_price),
        this.locale,
        `1.2-2`
      );
      const firstLine = `${dsSide} ${dsOrderType} de ${formattedQtty}`;
      const secondLine = action;
      const pnlValue = parseFloat(pnl) ?? 0;
      const pnlSymbol = pnlValue > 0 ? '' : '-';
      const pnlStatus = pnlValue
        ? pnlValue > 0
          ? '<span class="text-feedback-positive">Lucro</span>'
          : '<span class="text-feedback-negative">Prejuízo</span>'
        : '';
      const thirdLine = pnlValue
        ? `${pnlStatus} de ${pnlSymbol}R$ ${formatNumber(
            Math.abs(pnlValue),
            this.locale,
            `1.2-2`
          )} em ${delta_tempo}`
        : null;
      const time = dh_last_update.replace(/\D/g, '').substring(8, 14);
      const hours = time.substring(0, 2);
      const minutes = time.substring(2, 4);
      const seconds = time.substring(4, 6);
      const fourthLine = `${hours}:${minutes}:${seconds} a ${formattedPrice}`;

      if (!hashHasBuyAndSell[fourthLine]) {
        hashHasBuyAndSell[fourthLine] = {
          [side]: true,
        };
      }
      hashHasBuyAndSell[fourthLine][side] = true;

      dictMsg.set(annotationOrder.key, [
        `<div class="text-left">${firstLine}</div>`,
        `<div class="text-left">${secondLine}</div>`,
        thirdLine ? `<div class="text-left">${thirdLine}</div>` : '',
        `<div class="text-left mb-1">${fourthLine}</div>`,
      ]);

      dictMsg.get(annotationOrder.key)?.forEach((line: string) => {
        if (line.length > biggerText.length) biggerText = line;
      });

      if (k === execOrderAnnotations.length - 1) {
        annotationTooltipLabel = dictMsg.values().flat();
      }
    });
    const newText = annotationTooltipLabel.join();
    let textWidth = this.textLengthService.getBoxLineTextLength(biggerText);
    if (textWidth > 285) textWidth = 285;
    const annotationTooltipLeft = anyBorders.x1 - textWidth / 2 + 20;
    return {
      biggerText,
      annotationTooltipLabel,
      annotationTooltipLeft,
      newText,
      textWidth,
    };
  }

  addExecOrderAnnotation(
    order: IOrderChartData,
    categoryCalculator: CategoryCoordinateCalculator,
    execOrdersAnnotation: Dictionary<number>,
    execOrdersAnnotationIndex: Dictionary<ExecOrderAnnotation[]>,
    candleDataSeries: OhlcDataSeries,
    drawToolsService: DrawToolsService,
    refComponent: string,
    xAxisId: string,
    isMonthInterval: boolean
  ):
    | {
        annotation: ExecOrderAnnotation;
        execOrdersAnnotation: Dictionary<number>;
        execOrdersAnnotationIndex: Dictionary<ExecOrderAnnotation[]>;
      }
    | undefined {
    const { index, lastCandleIndex } = this.executedOrdersIndexes(
      order,
      categoryCalculator,
      candleDataSeries,
      isMonthInterval
    );
    if (index > lastCandleIndex || index < 0) {
      //se cair aqui, o candle nao existe.
      return;
    }
    let color =
      order.side === 'B'
        ? CHART_COLORS.MULTIBROKER_BUY
        : CHART_COLORS.MULTIBROKER_SELL;

    if (order.action === 'Inversão da posição') {
      color = CHART_COLORS.BRAND_SUPPORT_SECONDARY;
    }
    if (order.action === 'Zerou posição') {
      color = CHART_COLORS.RESET_POSITION;
    }

    const annotation = new ExecOrderAnnotation(
      order,
      drawToolsService,
      this,
      refComponent,
      {
        y1: parseFloat(order.exec_price),
        x1: Math.floor(
          index > categoryCalculator.indexMax ? lastCandleIndex : index
        ),
        svgString: this.getCircleExecOrder(color, color),
        id: `${execOrderId}_${order.idOrder}`,
        xAxisId,
      }
    );
    execOrdersAnnotation.set(order.idOrder!, index);
    const previous = execOrdersAnnotationIndex.get(index) || [];
    previous.push(annotation);

    execOrdersAnnotationIndex.set(index, previous);
    const samePrices = previous.filter(
      (pAnnotation) => pAnnotation.y1 === annotation.y1
    );
    if (samePrices.length > 1) {
      const buy = samePrices.find(
        (annotation) => annotation.annotationOrder.side === 'B'
      );
      const sell = samePrices.find(
        (annotation) => annotation.annotationOrder.side === 'S'
      );
      if (buy && sell) {
        annotation.svgString = this.getCircleExecOrder(
          CHART_COLORS.MULTIBROKER_SELL,
          CHART_COLORS.MULTIBROKER_BUY
        );
      }
    }
    return { annotation, execOrdersAnnotation, execOrdersAnnotationIndex };
  }

  updateExecOrderAnnotation(
    order: IOrderChartData,
    categoryCalculator: CategoryCoordinateCalculator,
    execOrdersAnnotation: Dictionary<number>,
    execOrdersAnnotationIndex: Dictionary<ExecOrderAnnotation[]>,
    drawToolsService: DrawToolsService,
    series: IRenderableSeries,
    refComponent: string
  ): {
    oldAnnotation: ExecOrderAnnotation;
    newAnnotation: ExecOrderAnnotation;
    execOrdersAnnotation: Dictionary<number>;
    execOrdersAnnotationIndex: Dictionary<ExecOrderAnnotation[]>;
  } | null {
    const annotationIndex = execOrdersAnnotation.get(order.idOrder!) || -1;
    const oldAnnotationIndex =
      execOrdersAnnotationIndex.get(annotationIndex) || undefined;
    if (!oldAnnotationIndex) return null;

    const oldIndex = oldAnnotationIndex.findIndex(
      (item) => item.id === `${execOrderId}_${order.idOrder}`
    );
    if (oldIndex === -1) return null;
    const lastPoint = series
      .getNativeXValues()
      .get(series.dataSeries.count() - 1);
    const lastCandleIndex =
      categoryCalculator.transformDataToIndex(lastPoint) + 1;
    const oldAnnotation = oldAnnotationIndex[oldIndex];
    const date = order.formatted_date;
    const index = categoryCalculator.transformDataToIndex(date.getTime());
    oldAnnotationIndex.splice(oldIndex, 1);
    execOrdersAnnotationIndex.set(annotationIndex, oldAnnotationIndex);

    const newAnnotation = new ExecOrderAnnotation(
      order,
      drawToolsService,
      this,
      refComponent,
      {
        y1: parseFloat(order.exec_price),
        x1: Math.floor(
          index > categoryCalculator.indexMax ? lastCandleIndex : index
        ),
        svgString: oldAnnotation?.svgString,
        id: `${execOrderId}_${order.idOrder}`,
      }
    );

    execOrdersAnnotation.set(order.idOrder!, index);

    const previous = execOrdersAnnotationIndex.get(index) || [];
    previous.push(newAnnotation);
    execOrdersAnnotationIndex.set(index, previous);

    const samePrices = previous.filter(
      (pAnnotation) => pAnnotation.y1 === newAnnotation.y1
    );
    if (samePrices.length > 1) {
      const buy = samePrices.find(
        (annotation) => annotation.annotationOrder.side === 'B'
      );
      const sell = samePrices.find(
        (annotation) => annotation.annotationOrder.side === 'S'
      );
      if (buy && sell) {
        newAnnotation.svgString = this.getCircleExecOrder(
          CHART_COLORS.MULTIBROKER_SELL,
          CHART_COLORS.MULTIBROKER_BUY
        );
      }
    }

    return {
      oldAnnotation,
      newAnnotation,
      execOrdersAnnotation,
      execOrdersAnnotationIndex,
    };
  }

  addExecOrderPlIndicatorAnnotation(
    order: IOrderChartData,
    stock: ISearchStock,
    xAxisCategoryCalculator: CategoryCoordinateCalculator,
    candleDataSeries: OhlcDataSeries,
    pnlAnnotation: Dictionary<ExecOrderFlagAnnotation>,
    parcialPnlAnnotation: Dictionary<ExecOrderFlagAnnotation>,
    drawToolsService: DrawToolsService,
    refComponent: string,
    xAxisId: string,
    isMonthInterval: boolean,
    isInvertPosition: boolean,
    conflicted = false,
    clickHandler: (args: AnnotationClickEventArgs) => void
  ):
    | {
        annotation: ExecOrderFlagAnnotation;
        execOrderFlagAnnotation: Dictionary<ExecOrderFlagAnnotation>;
        updated: boolean;
      }
    | undefined {
    const isOpenPnl = !!order.is_open && !isInvertPosition;
    let pnl = parseFloat((isOpenPnl ? order.pnl_open : order.pnl) || '0.0');
    const { index, lastCandleIndex } = this.executedOrdersIndexes(
      order,
      xAxisCategoryCalculator,
      candleDataSeries,
      isMonthInterval
    );
    if (index > lastCandleIndex || index < 0) return;
    const annotationsList = isOpenPnl ? parcialPnlAnnotation : pnlAnnotation;
    const otherFlagsList = isOpenPnl ? pnlAnnotation : parcialPnlAnnotation;
    const y = candleDataSeries.getNativeHighValues().get(index);
    let positionIndex = 1;
    const annotationExists = annotationsList.get(index);
    const orders =
      annotationExists?.annotationOrder ?? new Dictionary<IOrderChartData>();
    if (annotationExists) {
      orders.values().forEach((ord) => {
        if (ord.idOrder == order.idOrder) return;
        pnl += parseFloat((isOpenPnl ? ord.pnl_open : ord.pnl) || '0.0');
      });
      positionIndex = annotationExists.flagOptions.positionIndex;
    } else {
      positionIndex = this.getPLExecPositionIndex(
        conflicted,
        otherFlagsList.has(index)
      );
    }
    orders.set(order.idOrder!, order);
    const flagOptions: OrderFlagOptions = {
      conflicted,
      isOpenPnl,
      positionIndex,
      totalPnl: pnl,
    };
    const id = this.getPnlFlagId(index, isOpenPnl);
    const topPosition = this.execFlagPosition * positionIndex;
    const annotation = new ExecOrderFlagAnnotation(
      orders,
      drawToolsService,
      this,
      refComponent,
      flagOptions,
      {
        y1: y,
        x1: Math.floor(index),
        svgString: this.getPLExecOrder(
          pnl,
          stock.tick_size_denominator,
          positionIndex,
          isOpenPnl
        ),
        id,
        xAxisId,
        isEditable: false,
        onClick: clickHandler,
        yCoordShift: -topPosition,
      }
    );
    annotationsList.set(index, annotation);
    return {
      annotation,
      execOrderFlagAnnotation: annotationsList,
      updated: !!annotationExists,
    };
  }

  executedOrdersIndexes(
    order: IOrderChartData,
    categoryCalculator: CategoryCoordinateCalculator,
    candleDataSeries: OhlcDataSeries,
    isMonthInterval: boolean
  ) {
    let index = -1;
    const orderDate = order.formatted_date.getTime();
    const lastPoint = candleDataSeries
      .getNativeXValues()
      .get(candleDataSeries.count() - 1);
    const monthOrder = order.formatted_date.getMonth();
    const yearOrder = order.formatted_date.getFullYear();
    const dateLastPoint = new Date(lastPoint);
    const monthLastPoint = dateLastPoint.getMonth();
    const yearLatPoint = dateLastPoint.getFullYear();
    if (
      orderDate <= lastPoint ||
      (isMonthInterval &&
        monthOrder === monthLastPoint &&
        yearOrder === yearLatPoint)
    ) {
      index = Math.floor(categoryCalculator.transformDataToIndex(orderDate));
    }
    const lastCandleIndex =
      categoryCalculator.transformDataToIndex(lastPoint) + 1;
    return { index, lastPoint, lastCandleIndex };
  }

  updateParcialPlIndicatorAnnotation(
    baseChart: TWebAssemblyChart,
    stock: ISearchStock,
    order: IOrderChartData,
    xAxisCategoryCalculator: CategoryCoordinateCalculator,
    candleDataSeries: OhlcDataSeries,
    parcialPnlAnnotation: Dictionary<ExecOrderFlagAnnotation>,
    isMonthInterval: boolean
  ):
    | {
        annotation: ExecOrderFlagAnnotation;
        oldAnnotation: ExecOrderFlagAnnotation;
        execOrderFlagAnnotation: Dictionary<ExecOrderFlagAnnotation>;
        dontUpdate?: boolean;
      }
    | undefined {
    const { index, lastCandleIndex } = this.executedOrdersIndexes(
      order,
      xAxisCategoryCalculator,
      candleDataSeries,
      isMonthInterval
    );
    if (index > lastCandleIndex || index < 0) {
      //se cair aqui, o candle nao existe.
      return;
    }
    const annotation = parcialPnlAnnotation.get(index);
    if (!annotation) return;
    const baseChartAnnotation = baseChart.sciChartSurface.annotations.getById(
      annotation.id
    ) as ExecOrderFlagAnnotation;
    if (!baseChartAnnotation) return;
    if (annotation.flagOptions.isOpenPnl && !order.is_open) {
      return {
        annotation,
        oldAnnotation: baseChartAnnotation,
        execOrderFlagAnnotation: parcialPnlAnnotation,
        dontUpdate: true,
      };
    }
    baseChartAnnotation.annotationOrder.set(order.idOrder!, order);
    let pnl = 0;
    baseChartAnnotation.annotationOrder.values().forEach((ord) => {
      pnl += parseFloat(ord.pnl_open || '0');
    });
    const y = candleDataSeries.getNativeHighValues().get(index);
    const topPosition =
      this.execFlagPosition * baseChartAnnotation.flagOptions.positionIndex;
    baseChartAnnotation.y1 = y;
    baseChartAnnotation.yCoordShift = -topPosition;
    baseChartAnnotation.svgString = this.getPLExecOrder(
      pnl,
      stock.tick_size_denominator,
      baseChartAnnotation.flagOptions.positionIndex,
      !!order.is_open
    );
    parcialPnlAnnotation.set(index, annotation);
    return {
      annotation,
      oldAnnotation: baseChartAnnotation,
      execOrderFlagAnnotation: parcialPnlAnnotation,
    };
  }

  public getPnlFlagId = (index: number, isOpenPnl: boolean = false) => {
    return `${execOrderId}_${index}_pnl${isOpenPnl ? '_parcial' : ''}`;
  };

  private getCircleExecOrder(firstColor: string, secondColor: string): string {
    return `
    <svg style="overflow:auto;" viewBox="0 0 100 100" height="100" width="100">
      <path fill="${firstColor}" d="M 0 0 a 5.4001 5.4001 90 0 0 0 -13"/>
      <path fill="${secondColor}" d="M 0 -13 a 5.4001 5.4001 90 0 0 0 13"/>
    </svg>`;
  }

  private getPLExecOrder(
    pnlValue: number,
    tickSize: number,
    positionIndex: number = 1,
    noColorFlag: boolean = false
  ): string {
    const color: string = !noColorFlag
      ? pnlValue < 0
        ? CHART_COLORS.FEEDBACK_NEGATIVE
        : CHART_COLORS.FEEDBACK_POSITIVE
      : CHART_COLORS.NEUTRAL_SMOOTH;
    const value =
      (pnlValue < 0 ? '-' : '') +
      formatNumber(
        Math.abs(pnlValue),
        this.locale,
        `1.${tickSize}-${tickSize}`
      );
    const width =
      this.textLengthService.getBoxLineTextLength(value, undefined, '12px') +
      25;
    const height = 20 * positionIndex;
    return `
    <svg style="overflow:auto;" viewBox="0 0 ${width} ${height}" height="${height}" width="${width}">
      <foreignobject class="node" width="100%" height="20px">
        <div style="background-color: ${color}; opacity: 75%" class="fs-6 round-sm w-100 h-100 text-black d-flex 
        align-items-center">
          <span class="material-icons-outlined icon-size-micro">flag</span> | 
          <span>${value}</span>
        </div>
      </foreignobject>
      <line x1="0" y1="0" x2="0" y2="0" stroke="${color}" stroke-width="2" 
      stroke-linecap="round" stroke-opacity="0.5"></line>
    </svg>`;
  }

  private getPLExecPositionIndex(
    flagConflict: boolean,
    otherFlagGroup: boolean
  ) {
    if (flagConflict && otherFlagGroup) return 3;
    if (flagConflict || otherFlagGroup) return 2;
    return 1;
  }

  formatDate(dhLastUpdate: string, interval: TIGER_INTERVAL_ENUM) {
    const date = new Date(standardizeDate(dhLastUpdate));
    date.setSeconds(0, 0);
    const zeroHours: any = {
      [TIGER_INTERVAL_ENUM.ONE_DAY]: true,
      [TIGER_INTERVAL_ENUM.THIS_WEEK]: true,
      [TIGER_INTERVAL_ENUM.ONE_WEEK]: true,
      [TIGER_INTERVAL_ENUM.ONE_MONTH]: true,
    };
    const week: any = {
      [TIGER_INTERVAL_ENUM.THIS_WEEK]: true,
      [TIGER_INTERVAL_ENUM.ONE_WEEK]: true,
    };
    const funcs: any = {
      [TIGER_INTERVAL_ENUM.TWO_MINUTE]: true,
      [TIGER_INTERVAL_ENUM.FIVE_MINUTE]: true,
      [TIGER_INTERVAL_ENUM.TEN_MINUTE]: true,
      [TIGER_INTERVAL_ENUM.FIFTEEN_MINUTE]: true,
      [TIGER_INTERVAL_ENUM.THIRTY_MINUTE]: true,
      [TIGER_INTERVAL_ENUM.ONE_HOUR]: true,
      [TIGER_INTERVAL_ENUM.ONE_DAY]: true,
      [TIGER_INTERVAL_ENUM.THIS_WEEK]: true,
      [TIGER_INTERVAL_ENUM.ONE_WEEK]: true,
      [TIGER_INTERVAL_ENUM.ONE_MONTH]: true,
    };
    const func = funcs[interval];
    if (func) {
      const group = INTERVAL_HASH[interval]!;
      return this.roundToNearestGroup(
        date,
        group.minutes,
        zeroHours[interval],
        week[interval]
      );
    }
    return date;
  }

  private roundToNearestGroup = (
    date: Date,
    group: number,
    zeroHours: boolean,
    isWeek: boolean
  ) => {
    const minutes = date.getMinutes();
    const roundedMinutes = Math.floor(minutes / group) * group;
    date.setMinutes(roundedMinutes, 0, 0);
    if (zeroHours) {
      date.setHours(0, 0);
    }
    if (isWeek) {
      const day = date.getDay(),
        diff = date.getDate() - day + (day == 0 ? -6 : 1);
      date = new Date(date.setDate(diff));
    }
    return date;
  };

  updatePosition(baseChart: TWebAssemblyChart, length: number) {
    baseChart.sciChartSurface.annotations.asArray().forEach((annotation) => {
      if (
        annotation instanceof ExecOrderAnnotation ||
        annotation instanceof ExecOrderFlagAnnotation
      ) {
        annotation.x1 = annotation.x1 + length;
      }
    });
  }
}
