import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import {
  formatByTick,
  formatterNumber,
  isNullOrUndefined,
} from 'src/app/utils/utils.functions';
import {
  IStockAuctionInfos,
  ITraderViewOrder,
} from '../../interfaces/stock-table-views.interface';
import { IMoversPeriod } from '@shared/components/stock-table/interfaces/movers-period.interface';
import { Subscription, debounceTime, filter } from 'rxjs';
import { FormControl } from '@angular/forms';
import { TYPE_ORDE_SIDE_ENUM } from '@shared/enum/buyOrSell.enum';
import { ORDER_PARAM_HELPER } from '@shared/constants/order-param-helper.const';

@Component({
  selector: 'app-stock-table-view-candle-horizontal',
  templateUrl: './stock-table-view-candle-horizontal.component.html',
  styleUrls: ['./stock-table-view-candle-horizontal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockTableViewCandleHorizontalComponent
  implements OnChanges, OnDestroy
{
  @Output() public stockSelected: EventEmitter<void> = new EventEmitter();
  @Output() public removeStock = new EventEmitter();
  @Output() public sendOrderEvent = new EventEmitter<ITraderViewOrder>();
  @Output() public blockKeyboardEvent = new EventEmitter<boolean>();
  @Output() public stockQtty = new EventEmitter<{
    qtty: number;
    standardLot: number;
  }>();

  @Input() public cdStock: string = '';
  @Input() public companyName: string = '';
  @Input() public price: string = '0';
  @Input() public avgPrice: string = '0';
  @Input() public tickSize: string = '0';
  @Input() public variation: string = '0';
  @Input() public componentId: string = '';
  @Input() public dsAsset: string = '';
  @Input() public vlOpen: any = 0;
  @Input() public vlClose: any = 0;
  @Input() public vlMin: any = 0;
  @Input() public vlMax: any = 0;
  @Input() public variationTextColor: string = CHART_COLORS.NEUTRAL_MEDIUM;
  @Input() public variationBgColor: string = CHART_COLORS.NEUTRAL_MEDIUM;
  @Input() public globalStock!: string;
  @Input() public situation!: string;
  @Input() public stockToEnableArrowEvent!: string;
  @Input() public displayRemoveStock: boolean = false;
  @Input() public enableSendOrder: boolean = false;
  @Input() public auctionInfos!: IStockAuctionInfos | null;
  @Input() public period?: IMoversPeriod;

  @Input() set standardLot(value: number | undefined) {
    if (!value) return;
    this.defaultLot = value;
    if (!this.lotValue) {
      this.lotValue = value;
      this.orderQtty.setValue(value);
    }
    this._cdr.detectChanges();
  }
  @Input() set lotInitialValue(initialValue: number) {
    if (!initialValue) return;
    this.lotValue = initialValue;
    this.customInitialLot = this.lotValue !== this.defaultLot;
    this.orderQtty.setValue(this.lotValue);
    this._cdr.detectChanges();
  }

  public readonly SIDE_ORDER = TYPE_ORDE_SIDE_ENUM;

  public containerHeight: string = '30px';
  public hideCandleArrow: boolean = true;
  public disablePriceTooltip: boolean = true;
  public isGlobalStocks: boolean = false;
  public customInitialLot: boolean = false;
  public lotValue: number = 0;
  public defaultLot: number = 0;
  public orderQtty = new FormControl(0);

  private _formChangeSubsciption!: Subscription;

  constructor(private _cdr: ChangeDetectorRef) {
    this._formChangeSubsciption = this.orderQtty.valueChanges
      .pipe(
        debounceTime(300),
        filter((qtty) => qtty !== null && this.lotValue !== qtty)
      )
      .subscribe((qtty) => {
        if (qtty) {
          this.stockQtty.emit({ qtty, standardLot: this.defaultLot });
        }else {
          this.orderQtty.setValue(this.defaultLot)
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { price, variation, globalStock, cdStock, auctionInfos } = changes;
    if (price?.currentValue && !this.auctionInfos?.preco_leilao)
      this._formatStockPrice(price.currentValue);
    if (variation?.currentValue && !this.period) {
      this.hideCandleArrow = +this.variation === 0;
      this.variation = formatterNumber(parseFloat(variation.currentValue));
    }
    if (globalStock?.currentValue || cdStock?.currentValue)
      this._verifyIsGlobalStock();
    if (auctionInfos?.currentValue) {
      this._buildAuctionInfos(auctionInfos.currentValue);
    }
  }

  ngOnDestroy(): void {
    this._formChangeSubsciption.unsubscribe();
  }

  private _formatStockPrice(price: string): void {
    if (!price || price === '0' || isNullOrUndefined(price)) {
      this.price = '0,00';
      return;
    }
    const formattedPrice = formatByTick(price, +this.tickSize);
    this.disablePriceTooltip = formattedPrice.length < 7;
    this.price = formattedPrice;
  }

  private _verifyIsGlobalStock(): void {
    if (this.globalStock && this.cdStock)
      this.isGlobalStocks = this.cdStock === this.globalStock;
    else this.isGlobalStocks = false;
    this._cdr.detectChanges();
  }

  private _buildAuctionInfos(auctionInfos: {
    variacao_leilao: string;
    preco_leilao: string;
  }): void {
    if (auctionInfos.preco_leilao)
      this._formatStockPrice(auctionInfos.preco_leilao);
    if (!isNullOrUndefined(auctionInfos.variacao_leilao))
      this.variation = formatterNumber(
        parseFloat(auctionInfos.variacao_leilao)
      );
    this._cdr.detectChanges();
  }

  public sendOrder(side: TYPE_ORDE_SIDE_ENUM): void {
    const origin =
      side === TYPE_ORDE_SIDE_ENUM.BUY
        ? ORDER_PARAM_HELPER.STOCK_TABLE_TRADER_VIEW_BUY
        : ORDER_PARAM_HELPER.STOCK_TABLE_TRADER_VIEW_SELL;
    const qtty = this.orderQtty.value!;
    this.sendOrderEvent.emit({ side, qtty, price: this.price, origin });
  }
}
