<rocket-modal>
  <rocket-modal-header [hideCloseButton]="true">
    <div class="hstack align-items-center justify-content-between w-100">
      <h1 class="fs-2 fw-bold">
        Bem-vindo a nova era do trading{{ name }}! 🚀
      </h1>
      <div class="hstack align-items-center justify-content-center gap-2">
        <img
          src="../../../../../assets/logo/logo-xp-investimentos.svg"
          alt="Logo parceiro"
          width="24"
          height="24"
        />
        <span class="fw-light">+</span>
        <img
          src="../../../../../assets/icons/ico-svg.svg"
          alt="RocketTrader Logo"
          width="24"
          height="24"
        />
      </div>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div class="align-items-start vstack gap-5 pb-5">
      <span class="fs-4">
        Estamos aqui para atender os mais <b>altos níveis</b> de exigência que
        <b>você precisa</b>
      </span>

      <div
        class="hstack gap-1 w-100 align-items-center justify-content-between"
        style="height: 200px"
      >
        <img
          src="../../../../../assets/logo/partners-rt-logo.png"
          alt="Logo RocketTrader"
          width="260px"
          height="200px"
        />
        <div class="h-100 justify-content-between vstack">
          <ng-container *ngFor="let item of benefits">
            <div class="hstack align-items-center gap-2">
              <span
                class="material-icons-round text-brand-primary icon-size-tiny"
              >
                check_circle
              </span>
              <div class="vstack">
                <span class="fs-base">{{ item.title }}</span>
                <span class="fw-bold fs-6 text-neutral-medium">
                  {{ item.subTitle }}
                </span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <span>
        <b>Experimente gratuitamente</b>, faça testes com
        <b>nosso simulador</b> e esteja pronto para o <b>seu próximo passo!</b>
      </span>

      <div class="hstack align-items-center justify-content-between gap-2">
        <span class="fs-5">
          Ao clicar em<b> Começar a experimentar</b> agora você terá concordado
          com os
          <a
            target="_blank"
            href="public/termos-de-uso-e-politica-de-privacidade"
            class="text-brand-primary"
          >
            Termos de Condições
          </a>
        </span>
        <rocket-button
          type="default"
          label="Começar a experimentar"
          css="mr-3"
          [loading]="isLoading"
          [isDisabled]="someError"
          (rocketClick)="openConnectBroker()"
        >
        </rocket-button>
      </div>

      <ng-container *ngIf="someError">
        <div class="hstack justify-content-between">
          <span class="d-block fs-5 my-3 text-feedback-error">
            Ocorreu um erro ao habilitar o período de teste, tente novamente ou
            entre em contato conosco
          </span>

          <button
            class="btn btn-fill-primary mr-3 text-brand-primary"
            (click)="logout()"
          >
            Sair
          </button>
        </div>
      </ng-container>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
