<rocket-modal>
  <rocket-modal-header>
    <div class="w-100 d-flex flex-column justify-content-between">
      <h3 class="modal-title">Ajuda - Desenhos</h3>
      <small class="modal-subtitle">
        Veja abaixo a explicação de cada um dos desenhos disponíveis
      </small>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div class="d-flex flex-column mb-6">
      <div class="d-flex mt-2">
        <div class="d-flex flex-column gap-2">
          <ng-container *ngFor="let tool of groupTools">
            <div
              [ngClass]="{
                'text-brand-primary': selectedTool.title == tool.title
              }"
              class="d-flex gap-2 align-items-center cursor-pointer"
              *ngIf="tool.helpTitle"
              (click)="clickTool(tool)"
            >
              <span [innerHtml]="tool.helpIcon!! | safeHtml"></span>
              <span class="fs-5 text-nowrap">{{ tool.helpTitle }}</span>
            </div>
          </ng-container>
        </div>
        <div class="w-75 pl-3">
          <div
            class="fs-5"
            [innerHtml]="selectedTool.helpText!! | safeHtml"
          ></div>
        </div>
      </div>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
