import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';
import { BaseContextMenuComponent } from '@shared/rocket-components/base-context-menu';

export enum AtPriceContextMenuEvent {
  Detail = 'DETAIL',
}

@Component({
  selector: 'app-at-price-context-menu',
  template: `
    <div
      class="round bg-neutral-strong position-absolute"
      [style.width]="'210px'"
      [style.padding]="'10px'"
      [style.top.px]="position.top"
      [style.left.px]="position.left"
      [style.z-index]="9999"
      [style.display]="'block'"
      (mouseleave)="onFocusOut()"
    >
      <div class="list-group list-group-flush">
        <div class="header-context-menu">
          <span>Volume x Preço</span>
        </div>
        <div id="AT_PRICE_TOGGLE_VISIBILITY" class="d-flex flex-column">
          <button
            id="AT_PRICE_CONTEXT_DETAILS"
            type="button"
            class="list-group-item list-group-item-action context-item-button"
            (click)="showOrCloseDetalhes()"
          >
            <fla-icon
              size="icon-size-tiny"
              css="position-absolute left-0 text-neutral-medium fs-4"
              [name]="!showDetail ? 'visibility_off' : 'visibility'"
            ></fla-icon>
            Detalhes
          </button>
        </div>
      </div>
    </div>
  `,
})
export class AtPriceContextMenuComponent extends BaseContextMenuComponent {
  show = false;
  parent_id!: string;
  showDetail: boolean = true;

  constructor(
    elementRef: ElementRef,
    cdr: ChangeDetectorRef,
    private contextMenuService: ContextMenuService
  ) {
    super(elementRef, cdr);
  }

  static openContextMenu(
    contextMenuService: ContextMenuService,
    parent_id: string,
    event: { clientX: number; clientY: number },
    isTour: boolean,
    showDetail: boolean
  ) {
    if (isTour) {
      const domRect = document
        .getElementsByTagName('app-at-price')[0]
        .getClientRects()[0];
      event.clientX = domRect.x;
      event.clientY = domRect.y + 100;
    }
    contextMenuService.openContextMenu(parent_id, {
      type: 'AtPriceContextMenuComponent',
      event: event,
      configurationData: {
        showDetail,
      },
    });
  }

  private emitEvent(action: AtPriceContextMenuEvent) {
    this.contextMenuService.emitEvent(this.parent_id, action);
  }

  onFocusOut() {
    this.contextMenuService.closeContextMenu(this.parent_id);
  }

  public showOrCloseDetalhes(): void {
    this.showDetail = !this.showDetail;
    this.emitEvent(AtPriceContextMenuEvent.Detail);
    this.onFocusOut();
  }
}
