import { Dictionary } from '@core/models';

export enum WORKSPACE_LAYOUT {
  FULL,
  ONE_ONE,
  ONE_THREE,
  TWO_ONE,
  ONE_ONE_TWO,
}

export enum COMPONENTS_NAMES_ENUM {
  BOOK = 'TABLE_BOOK',
  PAPEIS = 'LIST_STOCK',
  GRAFICO = 'STOCK_CHART',
  HEATMAP = 'HEATMAP',
  SUPER_DOM = 'SUPER_DOM',
  LISTA_ORDENS = 'ORDERS_HISTORY',
  TIMES_TRADES = 'TIMES_TRADES',
  BOLETA = 'BOLETA',
  NEWS = 'NEWS',
  SCREENING = 'SCREENING',
  GRAPHIC_SCREENING = 'GRAPHIC_SCREENING',
  BUSINESS_PROFILE = 'BUSINESS_PROFILE',
  PERFORMANCE_REPORT = 'PERFORMANCE_REPORT',
  AT_PRICE = 'AT_PRICE',
  OPTIONS = 'OPTIONS',
  RANKING_BROKER = 'RANKING_BROKER',
  RENT_BTC = 'RENT_BTC',
  FLOW_INVESTOR = 'FLOW_INVESTOR',
  MARKET_RESUME = 'MARKET_RESUME',
  STOCK_ANALYSIS = 'STOCK_ANALYSIS',
  TRADE_IDEA = 'TRADE_IDEA',
  RISK_MANAGEMENT = 'RISK_MANAGEMENT',
}

export const COMPONENTS_ROUTES: Dictionary<any> = new Dictionary();

COMPONENTS_ROUTES.set('STOCK_CHART', { path: '/tools' });
COMPONENTS_ROUTES.set('HEATMAP', { path: '/tools' });
COMPONENTS_ROUTES.set('NEWS', { path: '/tools' });
COMPONENTS_ROUTES.set('LIST_STOCK', { path: '/tools' });
COMPONENTS_ROUTES.set('TIMES_TRADES', { path: '/tools' });
COMPONENTS_ROUTES.set('TABLE_BOOK', { path: '/tools' });
COMPONENTS_ROUTES.set('SUPER_DOM', { path: '/tools' });
COMPONENTS_ROUTES.set('ORDERS_HISTORY', { path: '/tools' });
COMPONENTS_ROUTES.set('BOLETA', { path: '/tools' });
COMPONENTS_ROUTES.set('SCREENING', { path: '/tools' });
COMPONENTS_ROUTES.set('GRAPHIC_SCREENING', { path: '/tools' });
COMPONENTS_ROUTES.set('OPTIONS', { path: '/tools' });
COMPONENTS_ROUTES.set('PERFORMANCE_REPORT', { path: '/tools' });
COMPONENTS_ROUTES.set('AT_PRICE', { path: '/tools' });
COMPONENTS_ROUTES.set('RANKING_BROKER', { path: '/tools' });
COMPONENTS_ROUTES.set('RENT_BTC', { path: '/tools' });
COMPONENTS_ROUTES.set('FLOW_INVESTOR', { path: '/tools' });
COMPONENTS_ROUTES.set('MARKET_RESUME', { path: '/tools' });
COMPONENTS_ROUTES.set('STOCK_ANALYSIS', { path: '/tools' });
COMPONENTS_ROUTES.set('TRADE_IDEA', { path: '/tools' });
