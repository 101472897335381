export function validateRne(rne: string): boolean {
  // Remove caracteres não numéricos
  const cleanedRne = rne.replace(/\D/g, '');

  // Verifica se o RNE tem 9 dígitos
  if (cleanedRne.length !== 9) {
    return false;
  }

  // Verifica se o RNE inicia com "RNE" ou "RN"
  if (!/^RNE(RN)?/i.test(cleanedRne)) {
    return false;
  }

  // RNE válido
  return true;
}
