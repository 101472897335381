<div
  class="rkt-carousel-container bg-brand-primary bg-opacity-semi-transparent overflow-hidden position-relative round"
>
  <div class="content">
    <ng-container *ngFor="let slide of slides; let i = index">
      <div
        class="align-items-center d-flex flex-column gap-1 justify-content-center left-0 opacity-0 opacity-100 p-3 position-absolute slide top-0 w-100"
        [ngClass]="{ 'opacity-100': i === currentIndex }"
      >
        <h3 class="fs-4 fw-bold text-brand-primary w-100">{{ slide.title }}</h3>
        <p
          class="fs-base fw-bold m-0 text-left text-light w-100 hstack align-items-center"
        >
          {{ slide.content }}
        </p>
      </div>
    </ng-container>
  </div>

  <div class="hstack mt-2 pl-3 position-absolute rkt-dot-container">
    <ng-container *ngFor="let slide of slides; let i = index">
      <div
        class="bg-opacity-0 border border-thin border-neutral-smoothest mr-1 rkt-dot round"
        [ngClass]="{
          'bg-neutral-smoothest bg-opacity-100 active': i === currentIndex
        }"
        (click)="goToSlide(i)"
      ></div>
    </ng-container>
  </div>
</div>
