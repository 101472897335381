<div
  class="text-center d-inline-block"
  [appAuctionTooltip]="auctionInfos"
  [situation]="situation"
>
  <small
    *ngIf="title"
    class="d-block fw-bold lh-xl"
    [ngClass]="titleSize"
    [class]="titleColor"
  >
    {{ title }}
  </small>
  <div
    [class]="styleTag + ' tag'"
    [ngClass]="{ 'small-tag ': useSmallTag }"
    #tag
  >
    <ng-container *ngIf="isVariation && variation">
      <ng-template [ngTemplateOutlet]="showIcon"></ng-template>
    </ng-container>
    <ng-container *ngIf="isVariation; else notVariation">
      <span> {{ variation }}{{ symbol }}</span>
    </ng-container>
    <ng-container *ngIf="!isVariation">
      <ng-template [ngTemplateOutlet]="showIcon"></ng-template>
    </ng-container>
    <span *ngIf="closeBtn" id="tagCloseBtn" [ngClass]="closeBtnClass">
      <i class="icons icon-size-micro ml-1 cursor-pointer">close</i>
    </span>
  </div>
</div>

<ng-template #notVariation>
  <span [class]="colorNotVariation">{{ text }}</span>
</ng-template>

<ng-template #showIcon>
  <ng-content select="fla-icon"></ng-content>
</ng-template>
