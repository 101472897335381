import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import { SUPER_DOM_ELEMENT_ID } from '@shared/components/super-dom/enum/super-dom.enum';

const SUPERDOM_INTRO_STEPS = {
  INITIAL: (component: Element) => ({
    title: 'Conheça o SuperDOM 🖖',
    intro:
      'O SuperDOM é uma ferramenta que você acompanha todas as intenções de compra e venda dos players do mercado (book de ofertas) e consegue enviar e acompanhar suas ordens de uma maneira prática e rápida. <br/><br/> Use as setas do teclado ou clique no próximo para começar.',
    element: component,
  }),
  EXCHANGE_BALANCE: (component: Element) => ({
    title: 'Saldo na corretora',
    intro:
      'Aqui você vê o saldo disponível na sua corretora selecionada para negociar.',
    element: component.querySelector(
      `#${SUPER_DOM_ELEMENT_ID.EXCHANGE_BALANCE}`
    ),
  }),
  STOCK_DETAILS: (component: Element) => ({
    title: 'Detalhes do ativo',
    intro:
      'Tenha acesso as informações do ativo como cotação atual, variação, preço mínimo e máximo e o volume negociado.',
    element: component.querySelector('app-details-tab'),
    position: 'bottom',
    preChange: function () {
      this.element = component.querySelector('app-details-tab');
      this.position = 'bottom';
    },
  }),
  POSITIONS_INFO: (component: Element) => ({
    title: 'Detalhes da sua posição',
    intro:
      'Aqui, serão exibidas as suas posições <b>{DayTrade}</b> ou <b>{Swing}</b>. Para trocar a visão, selecione um desses modos no topo direito da tela. <br /><br /> <b>Zerar</b>: irá zerar a posição de acordo com o modo que estiver ativado (<b>{DayTrade}</b> ou <b>{Swing}</b>). <br /><br /> <b>Inverter</b>: irá inverter a posição de acordo com o modo ativado.',
    element: component.querySelector(`#${SUPER_DOM_ELEMENT_ID.POSITIONS_INFO}`),
    preChange: function () {
      this.intro = DaytradeService.getTextWithTradeMode(this.intro);
    },
  }),
  COMMANDS: (component: Element, handlerFunc: any) => ({
    title: 'Envio de ordens',
    intro:
      'Nesta seção você fará suas negociações com precisão e rapidez. Basta digitar a quantidade, o preço de negociação e enviar sua ordem de compra ou de venda. <br /><br /> Outra maneira mais ágil de envio de ordens é clicando duas vezes na coluna <b>Quantidade</b>, tanto na venda quanto na compra. A ordem enviada manterá a quantidade adicionada nessa seção. ',
    element: component.querySelector(`#${SUPER_DOM_ELEMENT_ID.COMMANDS}`),
    preChange: function () {
      handlerFunc(true);
    },
  }),
  NEGOTIATION_LINE: (component: Element, forceDisplayContextMenu: any) => ({
    title: 'Atalho envio de ordens',
    intro:
      'Também dá para enviar ordens ainda mais rapidamente! Clique duas vezes em cima da coluna <b>Ordens</b> e, na caixa de seleção, adicione a quantidade que você quer negociar e confirme apertando <b>Enter</b>.',
    element: component.querySelectorAll(`#${SUPER_DOM_ELEMENT_ID.BUY_AREA}`)[0],
    preChange: function () {
      forceDisplayContextMenu(false);
      this.element = component.querySelectorAll(
        `#${SUPER_DOM_ELEMENT_ID.BUY_AREA}`
      )[1];
    },
  }),
  CONTEXT_MENU: (handlerFunc: any) => ({
    enum: 'CONTEXT_MENU',
    title: 'Funcionalidades',
    intro:
      'Clique com o botão direito e exiba ou oculte as exibições, como pressão, detalhes, book, agressor, volume x preço. <br /> <br /> <b>Agressor</b>: ao ativar essa exibição, você verá uma marcação em amarelo na coluna <b>Preço</b>, servindo para identificar mudanças nos preços máximos e mínimos do ativo.',
    element: document.querySelector(`app-super-dom-context-menu > div`),
    preChange: function () {
      handlerFunc(false);
      this.element = document.querySelector(`app-super-dom-context-menu > div`);
    },
  }),
  PADLOCK: (component: Element) => ({
    title: 'Cadeado',
    intro:
      'O cadeado permite bloquear a movimentação da linha da oferta. Assim, ela ficará travada no meio da tabela. Para desbloquear basta clicar no cadeado novamente.',
    element: component.querySelector(`#${SUPER_DOM_ELEMENT_ID.PADLOCK}`),
  }),
  TOUR_END: {
    title: 'Agora é com você! 🖖',
    intro:
      'Aproveite a funcionalidade do SuperDOM! Se quiser rever esse tour clique em <b>Ajuda</b> no botão direito ou acesse nossa FAQ clicando no menu <b>Ajuda</b>.',
  },
};

export const SUPERDOM_STEPS = (
  superDomElement: Element,
  forceDisplayContextMenu: any,
  handlerFunc: any
) => [
  SUPERDOM_INTRO_STEPS.INITIAL(superDomElement),
  SUPERDOM_INTRO_STEPS.EXCHANGE_BALANCE(superDomElement),
  SUPERDOM_INTRO_STEPS.STOCK_DETAILS(superDomElement),
  SUPERDOM_INTRO_STEPS.POSITIONS_INFO(superDomElement),
  SUPERDOM_INTRO_STEPS.COMMANDS(superDomElement, handlerFunc),
  SUPERDOM_INTRO_STEPS.NEGOTIATION_LINE(
    superDomElement,
    forceDisplayContextMenu
  ),
  SUPERDOM_INTRO_STEPS.CONTEXT_MENU(handlerFunc),
  SUPERDOM_INTRO_STEPS.PADLOCK(superDomElement),
  SUPERDOM_INTRO_STEPS.TOUR_END,
];
