import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  RocketModalRef,
  RocketModalService,
  ROCKET_MODAL_DATA,
} from '@shared/rocket-components/components/index';
import { FlaUnsubscribe } from '@shared/rocket-components/decorators';
import { isNullOrWhiteSpace } from '@shared/rocket-components/utils';
import { debounceTime, Subscription } from 'rxjs';
import { Dictionary } from 'src/app/core/models';
import { IInterval } from '../../interface/stock-chart.interface';
import { StockChartService } from '../../service/stock-chart.service';
import { StockChartModalIntervalService } from './stock-chart-modal-interval.service';

@Component({
  selector: 'app-stock-chart-modal-interval',
  templateUrl: './stock-chart-modal-interval.component.html',
  styleUrls: ['./stock-chart-modal-interval.component.scss'],
})
export class StockChartModalIntervalComponent
  extends RocketModalRef
  implements OnInit, AfterViewInit
{
  refId = '';
  intervalControl = new FormControl();
  message: string = 'Não aplicável';
  intervals!: Dictionary<IInterval>;
  tooltip: string =
    'Digite o número do agrupamento que deseja inserir no gráfico. Você também pode navegar com as setas do teclado para alterar o agrupamento do gráfico.<br/>Exemplo: 1S para 1 semana.';

  @ViewChild('inputInterval') input!: ElementRef;

  @FlaUnsubscribe
  private sub: Subscription[] = [];
  private findValue!: IInterval;
  constructor(
    service: RocketModalService,
    @Inject(ROCKET_MODAL_DATA)
    private data: {
      interval: Dictionary<IInterval>;
      ref: string;
    },
    private chartService: StockChartService,
    private stockChartModalIntervalService: StockChartModalIntervalService
  ) {
    super(service);
    this._eventKeyPress();
    this.stockChartModalIntervalService.modalOpened = true;
  }

  ngOnInit(): void {}

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.stockChartModalIntervalService.modalOpened = false;
  }

  @HostListener('window:keydown.arrowleft', ['$event'])
  @HostListener('window:keydown.arrowright', ['$event'])
  arrowEvents(event: KeyboardEvent) {
    if (event.keyCode === 39) {
      return this.changeInterval('next');
    }
    this.changeInterval('previous');
  }

  ngAfterViewInit(): void {
    this.intervals = this.data.interval;
    this.refId = this.data.ref;
    this._controlChanges();
    setTimeout(() => {
      this.intervalControl.markAsTouched();
      //this.input.nativeElement.focus();
    }, 300);
  }

  onSave() {
    if (this.intervalControl.valid) this.close(this.findValue.id);
  }

  updateService() {
    this.chartService.updateIntervalModalValue(this.intervalControl.value);
  }

  private _eventKeyPress() {
    this.sub.push(
      this.chartService.eventKey$.pipe(debounceTime(200)).subscribe((res) => {
        if (res.ref === this.data.ref) {
          this.intervalControl.setValue(res.word.toUpperCase());
        }
      })
    );
  }
  private _controlChanges() {
    this.sub.push(
      this.intervalControl.valueChanges
        .pipe(debounceTime(300))
        .subscribe((value) => {
          this.intervalControl.patchValue(value.toUpperCase(), {
            emitEvent: false,
          });
          this._validValue(value);
          this.intervalControl.markAsTouched();
        })
    );
  }

  private _validValue(value: string, intervals = this.intervals) {
    this.findValue = intervals.filter('keyCode', value.toUpperCase())[0];
    if (isNullOrWhiteSpace(this.findValue) || isNullOrWhiteSpace(value)) {
      this.intervalControl.setErrors({ invalid: true });
      this.message = 'Não aplicável';
    } else {
      this.message = this.findValue.name;
      this.intervalControl.setErrors(null);
    }
  }

  changeInterval(type: 'next' | 'previous') {
    let newInterval: IInterval =
      this.stockChartModalIntervalService.changeIntervalByArrow(
        type,
        this.intervals,
        this.intervalControl,
        this.findValue
      );
    if (newInterval) {
      this.intervalControl.setValue(newInterval.keyCode);
    }
    this.updateService();
  }

  closeModal() {
    if (this.findValue) {
      this.close(this.findValue.id);
      return;
    }
    this.close();
  }
}
