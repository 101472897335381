<workspace-doc-header
  css="w-100 overflow-visible position-relative"
  [item]="component"
>
  <fla-workspace-doc-header-item
    *ngIf="!isEdit"
    [enableContent]="true"
    alignItem="mr-auto"
  >
    <ul
      [id]="elementsID.HEADER_ORDER_TYPE"
      class="w-100 nav nav-segmented tab-trade fs-6"
      role="tablist"
    >
      <li
        *ngFor="let item of listType; let i = index"
        class="nav-item"
        [ngClass]="{ 'border-right': i < 3 }"
        role="presentation"
      >
        <button
          class="nav-link h-100 round-none d-flex align-items-center justify-content-center"
          [ngClass]="{
            active: item.active,
            'bg-multibroker-buy': !item.isBgSell && item.active,
            'bg-multibroker-sell': item.isBgSell && item.active
          }"
          type="button"
          role="tab"
          [attr.aria-selected]="item.active"
          (click)="onChangeType(item)"
        >
          <span
            *ngIf="!item.active"
            class="circle round-pill"
            [ngClass]="{
              'bg-multibroker-buy': !item.isBgSell,
              'bg-multibroker-sell': item.isBgSell
            }"
          ></span
          >{{ item.name }}
        </button>
      </li>
    </ul>
  </fla-workspace-doc-header-item>
  <fla-workspace-doc-header-item *ngIf="isEdit" [enableContent]="true">
    <ul class="nav nav-segmented tab-trade fs-6" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="active nav-link h-100 round-none text-bg-brand-support-secondary"
          type="button"
          role="tab"
          aria-selected="true"
        >
          Editando
        </button>
      </li>
      <li class="nav-item border-right" role="presentation">
        <button
          class="active nav-link h-100 round-none text-bg-brand-support-secondary"
          type="button"
          role="tab"
          aria-selected="true"
        >
          {{ typeTrade?.name }}
        </button>
      </li>
    </ul>
  </fla-workspace-doc-header-item>
</workspace-doc-header>
