import { Injectable } from '@angular/core';
import { ConfigService } from '@core/config';
import {
  OPTIONS_CALLPUT_CONFIG_HEADERS_FIXED_COLUNS,
  OPTIONS_TABLE_CONFIG_HEADERS_FIXED_COLUNS,
  TOptionsSubheaderTabs,
} from './types';

@Injectable({
  providedIn: 'root',
})
export class OptionsService {
  constructor(private configService: ConfigService) {}

  getFixedTableConfigs(subheaderTab: string, width: number) {
    if (this.configService.isBroker()) {
      return [];
    }
    width = +width - 10;
    return subheaderTab == 'table'
      ? OPTIONS_TABLE_CONFIG_HEADERS_FIXED_COLUNS(width)
      : OPTIONS_CALLPUT_CONFIG_HEADERS_FIXED_COLUNS(width);
  }

  getSubheaderTabs(): TOptionsSubheaderTabs[] {
    let tabs: TOptionsSubheaderTabs[] = [
      'Opções',
      'Tabela',
      'Operações',
      'Curvas',
    ];
    if (this.configService.isBroker()) {
      tabs = ['Tabela'];
    }
    return tabs;
  }

  getDefaultOptionSubheaderTab(): TOptionsSubheaderTabs {
    let defaultOption: TOptionsSubheaderTabs = 'Opções';
    if (this.configService.isBroker()) {
      defaultOption = 'Tabela';
    }
    return defaultOption;
  }
}
