import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { TableCellAlign } from '../../types/table.type';
import { TableService } from '../../service/table.service';
import { randomId } from '@shared/rocket-components/utils';
@Component({
  selector: 'app-table-header',
  template: `
    <ng-template>
      <ng-container *ngIf="isBtn; else simpleHeader">
        <th
          [scope]="scope"
          [attr.colspan]="colspan"
          [class]="css"
          [ngClass]="{ 'sticky-col': fixedColumn }"
          [ngStyle]="{ width: width, height: height }"
        >
          <button
            class="btn btn-fill-primary bg-transparent text-neutral-smoothest 
            dropdown-toggle p-0 fs-5 text-wrap"
            [ngClass]="[alignText]"
            type="button"
            (click)="_callDropdown($event)"
          >
            {{ text }}
          </button>
        </th>
      </ng-container>
      <ng-template #simpleHeader>
        <th
          [class]="alignText + ' ' + css"
          [scope]="scope"
          [attr.colspan]="colspan"
          [ngClass]="{ 'sticky-col': fixedColumn }"
          [ngStyle]="{ width: width, height: height }"
        >
          {{ text }}
          <ng-content></ng-content>
        </th>
      </ng-template>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class TableHeaderComponent {
  @Input() text!: string;
  @Input() width: string = 'auto';
  @Input() height: string = '40px';
  @Input() scope: string = 'col';
  @Input() alignText: TableCellAlign = 'text-left';
  @Input() colspan: number = 1;
  @Input() css: string = '';
  @Input() fixedColumn: boolean = false;
  @Input() isBtn: boolean = false;
  @ViewChild(TemplateRef) content!: TemplateRef<any>;
  constructor(private _service: TableService) {}

  _callDropdown(e: Event) {
    const eventKey = randomId('th_click');
    this._service.showTableDropdown({ element: e.target, eventId: eventKey });
  }
}
