import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  TOKEN_AUTHENTICATORS,
  TTokenAuthenticator,
  TTokenPreferenceAuthenticator,
} from '../types';

@Component({
  selector: 'app-token-authenticators-list',
  templateUrl: './token-authenticators-list.component.html',
  styleUrls: ['./token-authenticators-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TokenAuthenticatorsListComponent {
  @Input() tokenPreference!: TTokenPreferenceAuthenticator;
  @Input() displayTitle: boolean = true;
  @Output() changeAutenticator: EventEmitter<TTokenAuthenticator> =
    new EventEmitter();
  @Output() reloadConfig: EventEmitter<TTokenAuthenticator> =
    new EventEmitter();
  @Output() disconnecting: EventEmitter<string> = new EventEmitter();

  tokenAuthenticators = TOKEN_AUTHENTICATORS;
  authenticatorToDisconnect!: TTokenAuthenticator | undefined;

  constructor(private _cdr: ChangeDetectorRef) {}

  selectAuthenticator(authenticator: TTokenAuthenticator) {
    if (authenticator.id === this.tokenPreference) return;

    this.changeAutenticator.emit(authenticator);
  }

  disconnect(authenticator: TTokenAuthenticator) {
    this.authenticatorToDisconnect = authenticator;
    this.disconnecting.emit(authenticator.name);
    this._cdr.detectChanges();
  }

  cancelDisconnection() {
    this.authenticatorToDisconnect = undefined;
    this.disconnecting.emit('');
    this._cdr.detectChanges();
  }

  disconnected() {
    this.authenticatorToDisconnect = undefined;
    this.disconnecting.emit('');
    this.reloadConfig.emit();
    this._cdr.detectChanges();
  }
}
