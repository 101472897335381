import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ConfigService } from '@core/config';
declare let grecaptcha: any;
@Component({
  selector: 'app-recaptcha',
  template: ` <div
    class="d-flex justify-content-center pb-3"
    #recaptchaContainer
  ></div>`,
})
export class RecaptchaComponent implements OnInit, OnDestroy {
  @ViewChild('recaptchaContainer', { static: true })
  recaptchaContainer!: ElementRef;
  @Input() size: string = 'normal';
  @Output() resolved: EventEmitter<any> = new EventEmitter<any>();
  @Output() errored: EventEmitter<any> = new EventEmitter<any>();

  constructor(private config: ConfigService) {}

  ngOnInit(): void {
    this.loadRecaptcha();
  }

  ngOnDestroy(): void {
    this.resetRecaptcha();
  }

  execute() {
    if (!this.config.isRecapcthaEnabled()) {
      this.resolved.emit('Recaptcha bypassed in development');
    } else {
      grecaptcha.enterprise.execute();
    }
  }

  private loadRecaptcha(): void {
    if (!grecaptcha || !grecaptcha.enterprise) {
      console.error('grecaptcha is not loaded yet');
      return;
    }
    grecaptcha.enterprise.ready(() => {
      try {
        grecaptcha.enterprise.render(this.recaptchaContainer.nativeElement, {
          sitekey: this.config.getConfig()?.recaptchaKey,
          size: this.size,
          theme: 'dark',
          type: 'checkbox',
          callback: this.onRecaptchaResolved.bind(this),
          'expired-callback': this.onRecaptchaExpired.bind(this),
        });
      } catch (error) {
        console.error(error);
        this.errored.emit(error);
      }
    });
  }

  private onRecaptchaResolved(token: string): void {
    this.resolved.emit(token);
  }

  private onRecaptchaExpired(): void {
    this.errored.emit(true);
  }

  private resetRecaptcha(): void {
    const recaptchaContainer = this.recaptchaContainer.nativeElement;
    while (recaptchaContainer.firstChild) {
      recaptchaContainer.removeChild(recaptchaContainer.firstChild);
    }
  }
}
