import { formatDate, handleDocumentMask } from 'src/app/utils/utils.functions';
import { InvestorDataInterface } from '../interface/investor-data.interface';

export class InvestorData implements InvestorDataInterface {
  constructor(
    public cnpj_cpf: string = '',
    public document_type: 'CPF' | 'CNPJ' = 'CPF',
    public dt_birthday: string = '',
    public email: string = '',
    public name: string = '',
    public phone_mobile: string = '',
    public username: string = ''
  ) {}

  getInvestorData(): InvestorDataInterface {
    return {
      cnpj_cpf: handleDocumentMask(this.cnpj_cpf, this.document_type),
      dt_birthday: formatDate(String(this.dt_birthday)),
      raw_phone_mobile: this.phone_mobile,
      phone_mobile: this.phone_mobile,
      document_type: this.document_type,
      email: this.email,
      name: this.name,
      username: this.username,
      password: '********',
    };
  }
}
