import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CtrlClickDirective } from './ctrl-click.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [CtrlClickDirective],
  exports: [CtrlClickDirective],
})
export class CtrlClickModule {}
