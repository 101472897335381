import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatisticsCacheService } from '@shared/services/core/cache/statistics-cache.service';
import { RestService } from '@shared/services/rest';
import { firstValueFrom, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SummaryService extends RestService {
  override _url: string = 'api/trademap/v2/stock/summary';

  constructor(
    http: HttpClient,
    private _statisticsCacheService: StatisticsCacheService
  ) {
    super(http);
  }

  public getStatistics(cdStock: string, idExchange: number = 1) {
    const key = `${cdStock}:${idExchange}`;
    let request$: any = this._statisticsCacheService.getValue(key);
    if (!request$) {
      request$ = this.get(
        `statistics?cdStock=${cdStock}&idExchange=${idExchange}`
      ).pipe(shareReplay(1));
      this._statisticsCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }
}
