import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TIGER_INTERVAL_ENUM } from '@shared/tiger-chart/enum/tiger-chart.enum';
import { RestService } from '@shared/services/rest/rest.service';
import { map } from 'rxjs';
import {
  TCorporateEvents,
  TNewsChartEvents,
  TRelevantEvents,
} from '@shared/tiger-chart/types/tiger-chart.types';

@Injectable({
  providedIn: 'root',
})
export class EventsService extends RestService {
  override _url: string = 'api/nitro-ws/v1';

  constructor(http: HttpClient) {
    super(http);
  }

  getRelevantEvents = (
    stock: string,
    interval = TIGER_INTERVAL_ENUM.ONE_DAY,
    idPoint: number,
    idExchange: number,
    limit: number,
    dsAsset?: string
  ) =>
    this.get<TRelevantEvents[]>(
      `events/relevant?cd_stock=${stock}&id_exchange=${idExchange}&interval=${
        TIGER_INTERVAL_ENUM[interval]
      }&id_point=${idPoint}&limit=${limit}
    ${dsAsset ? '&ds_asset=' + dsAsset : ''}`
    ).pipe(map((res) => res.data));

  getCorporateEvents = (
    stock: string,
    interval = TIGER_INTERVAL_ENUM.ONE_DAY,
    idPoint: number,
    idExchange: number,
    limit: number,
    dsAsset?: string
  ) =>
    this.get<TCorporateEvents[]>(
      `events/corporate?cd_stock=${stock}&id_exchange=${idExchange}&interval=${
        TIGER_INTERVAL_ENUM[interval]
      }&id_point=${idPoint}&limit=${limit}
    ${dsAsset ? '&ds_asset=' + dsAsset : ''}`
    ).pipe(map((res) => res.data));

  getNewsChart = (
    stock: string,
    interval = TIGER_INTERVAL_ENUM.ONE_DAY,
    idPoint: number,
    idExchange: number,
    limit: number,
    dsAsset?: string
  ) =>
    this.get<TNewsChartEvents[]>(
      `events/news-chart?cd_stock=${stock}&id_exchange=${idExchange}&interval=${
        TIGER_INTERVAL_ENUM[interval]
      }&id_point=${idPoint}&limit=${limit}
    ${dsAsset ? '&ds_asset=' + dsAsset : ''}`
    ).pipe(map((res) => res.data));
}
