<rocket-modal>
  <rocket-modal-header>
    <div>
      <h3 class="modal-title">Baixe o RocketTrader</h3>
      <h4 *ngIf="!downloadNotAvailable">
        Desperte seu potencial de negociação: Baixe agora a versão instálavel da
        nossa plataforma e alcance novos patamares de sucesso!
      </h4>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div *ngIf="downloadNotAvailable" class="pb-3">
      A plataforma para Windows não está disponível ainda.<br />
      Estamos trabalhando para disponibiliza-la em breve.
    </div>
    <div
      *ngIf="!downloadNotAvailable"
      [style.min-height.px]="200"
      class="d-flex justify-content-between"
    >
      <div *ngFor="let _system of downloads" class="col-5 text-center">
        <div class="mb-4 mt-4">
          <img [src]="_system.image_icon" />
        </div>
        <rocket-button
          class="w-100"
          [isSmall]="false"
          [tooltip]="'Baixar versão ' + _system.os_name"
          tooltipPosition="bottom"
          [label]="'Baixar versão ' + _system.os_name"
          css="w-100"
          [css]="!_system.pre_selected ? 'btn-outline-primary' : ''"
          (rocketClick)="goToDownload(_system.link)"
        >
        </rocket-button>
      </div>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
