import { StockListService } from '@shared/services/api/trademap/V10/stock-list.service';
import { IListStockDB } from 'src/app/core/interface';
import { Observable, map, tap } from 'rxjs';
import { ListStocksService } from '@shared/services/core/list-stocks/list-stocks.service';

export class CreateList {
  constructor(
    private listStocksService: ListStocksService,
    private stockListService: StockListService
  ) {}
  newList = (
    idStockList: number,
    name: string,
    isNotListPersonal: boolean
  ): IListStockDB =>
    ({
      idStockList: idStockList,
      list: {
        arquivos: [],
        id_stock_list: idStockList,
        in_visibility: false,
        is_favorite: false,
        nm_stock_list: name,
        isPresetList: false,
      },
      configList: null,
      stocks: [],
      isNotListPersonal: isNotListPersonal,
    } as IListStockDB);

  public createdList(
    name: string,
    stocks: string[],
    isNotListPersonal: boolean
  ): Observable<number> {
    return this.stockListService.createStockList(name, stocks).pipe(
      map((data: any) => data),
      tap((id_stock_list: number) => {
        this.listStocksService.insertStockList({
          [`${id_stock_list}`]: this.newList(
            id_stock_list,
            name,
            isNotListPersonal
          ),
        });
      })
    );
  }
}
