import {
  BOOK_COMPONENT,
  ORDER_HISTORY_COMPONENT,
  STOCK_CHART_COMPONENT,
  STOCK_TABLE_COMPONENT,
} from '@core/workspace/constants';
import { LayoutBase } from '../base';
import { STOCK_TABLE_VIEW } from '@shared/components/stock-table-views';

export class ParaComecar extends LayoutBase {
  override name = 'Para começar';
  override components = [
    STOCK_TABLE_COMPONENT({
      height: 100,
      width: 21,
      data: { initialOptions: { initialView: STOCK_TABLE_VIEW.CARD } },
    }),
    STOCK_CHART_COMPONENT({
      left: 'calc(21% + 5px)',
      height: 70,
      width: 58,
      data: { initialOptions: { showFastOrder: true } },
    }),
    ORDER_HISTORY_COMPONENT({
      left: 'calc(21% + 5px)',
      top: 'calc(70% + 3px)',
      height: 29.5,
      width: 78.5,
    }),
    BOOK_COMPONENT({
      left: 'calc(79% + 10px)',
      height: 70,
      width: 20,
    }),
  ];
}
