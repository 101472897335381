import { Injectable } from '@angular/core';
import {
  CategoryAxis,
  CategoryCoordinateCalculator,
  EVerticalAnchorPoint,
  LineAnnotation,
  NativeTextAnnotation,
  TWebAssemblyChart,
} from 'scichart';
import { formatByTick } from 'src/app/utils/utils.functions';
import { ITigerChartMinMax } from '../interface';
import { minMaxIDS } from '../constants/tiger-chart.constants';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';

@Injectable({
  providedIn: 'root',
})
export class MinMaxAnnotationService {
  constructor(private themeService: ThemePreferencesService) {}

  addMinMax(
    baseChart: TWebAssemblyChart,
    xAxisId: string,
    data: ITigerChartMinMax,
    tickSizeDenominator: number,
    xAxis: CategoryAxis
  ) {
    this.addMinMaxAnnotation(
      baseChart,
      xAxisId,
      data.biggerMax,
      data.maxIndex,
      data.maxX2,
      minMaxIDS.MAX,
      tickSizeDenominator,
      xAxis
    );
    this.addMinMaxAnnotation(
      baseChart,
      xAxisId,
      data.menorMin,
      data.minX1,
      data.minIndex,
      minMaxIDS.MIN,
      tickSizeDenominator,
      xAxis
    );
  }

  private addMinMaxAnnotation(
    baseChart: TWebAssemblyChart,
    xAxisId: string,
    yValue: number,
    x1: number,
    x2: number,
    id: string,
    tickSizeDenominator: number,
    xAxis: CategoryAxis
  ) {
    let annotation = baseChart.sciChartSurface.annotations.getById(
      id
    ) as LineAnnotation;
    let annotationText = baseChart.sciChartSurface.annotations.getById(
      `${id}_TEXT`
    ) as NativeTextAnnotation;
    const isMin = id.includes('MIN');
    let textPositionX1 = x2;
    const xAxisVisible = xAxis.visibleRange;
    if (!isMin) {
      if (textPositionX1 + 3 > xAxisVisible.max) {
        x2 = x1 - 1;
        let diff = 0;
        if (annotationText) {
          const xcalc =
            xAxis.getCurrentCoordinateCalculator() as CategoryCoordinateCalculator;
          diff =
            xcalc.getDataValue(annotationText.getAnnotationBorders().x2) -
            xcalc.getDataValue(annotationText.getAnnotationBorders().x1);
        }
        textPositionX1 = x2 - diff;
      }
    } else {
      if (textPositionX1 - 3 < xAxisVisible.min) {
        x1 = x2 + 1;
        textPositionX1 = x1;
      } else {
        let diff = 0;
        if (annotationText) {
          const xcalc =
            xAxis.getCurrentCoordinateCalculator() as CategoryCoordinateCalculator;
          diff =
            xcalc.getDataValue(annotationText.getAnnotationBorders().x2) -
            xcalc.getDataValue(annotationText.getAnnotationBorders().x1);
        }
        textPositionX1 = x1 - diff;
      }
    }
    if (annotation) {
      annotation.y1 = yValue;
      annotation.y2 = yValue;
      annotation.x1 = x1;
      annotation.x2 = x2;
      annotation.isHidden = false;
      annotationText.y1 = yValue;
      annotationText.text = `${formatByTick(yValue, tickSizeDenominator)}`;
      annotationText.x1 = textPositionX1;
      annotationText.isHidden = false;
      return;
    }
    annotation = new LineAnnotation({
      stroke: this.themeService.isDarkTheme() ? 'white' : 'black',
      strokeThickness: 1,
      y1: yValue,
      y2: yValue,
      x1,
      x2,
      xAxisId,
      id,
    });
    annotationText = new NativeTextAnnotation({
      text: `${formatByTick(yValue, tickSizeDenominator)}`,
      textColor: this.themeService.isDarkTheme() ? 'white' : 'black',
      y1: yValue,
      x1: textPositionX1,
      xAxisId,
      id: `${id}_TEXT`,
      verticalAnchorPoint: EVerticalAnchorPoint.Center,
    });
    baseChart.sciChartSurface.annotations.add(annotation);
    baseChart.sciChartSurface.annotations.add(annotationText);
  }

  updateColorMinMaxAnnotation(baseChart: TWebAssemblyChart, id: string) {
    const annotation = baseChart.sciChartSurface.annotations.getById(
      id
    ) as LineAnnotation;
    const annotationText = baseChart.sciChartSurface.annotations.getById(
      `${id}_TEXT`
    ) as NativeTextAnnotation;
    if (annotation && annotationText) {
      annotation.stroke = this.themeService.isDarkTheme() ? 'white' : 'black';
      annotationText.textColor = this.themeService.isDarkTheme()
        ? 'white'
        : 'black';
    }
  }
}
