<div class="d-flex flex-column">
  <ng-container *ngIf="!loadingCards; else loadingContent">
    <fla-card class="w-100 fs-6 d-flex gap-3">
      <fla-card-custom
        #card
        *ngFor="let card of cards.values()"
        css="p-1 bg-neutral-strong mt-2 w-100"
      >
        <div
          class="card-body d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-neutral-medium">
            {{ card.title }}
          </span>
          <div class="d-flex flex-column fw-bold">
            <span class="value">
              {{ card.value }}
            </span>
          </div>
        </div>
      </fla-card-custom>
    </fla-card>
  </ng-container>

  <div
    class="d-flex justify-content-between align-items-center my-2"
    [ngClass]="{ 'disable-click': loadingTable }"
  >
    <fla-tab>
      <ng-container *ngFor="let item of tableTypes; let index = index">
        <fla-tab-header
          [ref]="item.ref"
          [title]="item.title"
          [active]="item.active"
          (flaChange)="onChangeTab(item, index)"
        ></fla-tab-header>
      </ng-container>
    </fla-tab>
    <app-export-data
      *ngIf="tableData"
      [id]="exporterId"
      [cdStock]="cdStock"
      [data]="{
        data: tableData,
        companyType: dropdownData.data.active,
        period: dropdownPeriod.data.active
      }"
      type="fill"
    ></app-export-data>
  </div>
  <div [ngStyle]="{ height: tableContentHeight }" class="table-z-index">
    <ng-container *ngIf="!loadingTable; else loadingContent">
      <app-table
        *ngIf="tableData; else withoutData"
        [tableRef]="'FUNDAMENTAL_TABLE_REF' + refComponent"
        containerCss="table-container simple p-0 pr-0 border-radius-5"
        layout="fixed"
        height="100%"
        (scrollElementRef)="setTableScrollRef($event)"
        [componentID]="componentID"
      >
        <!-- FIXED HEADERS -->
        <ng-container *ngFor="let header of fixedHeaders; let index = index">
          <ng-container *ngIf="header.display">
            <app-table-row
              appRemoveHost
              [ref]="index + refComponent"
              [fixedHeader]="true"
              [css]="index === 1 ? 'custom-stick-header' : ''"
            >
              <app-table-header
                height="40px"
                [width]="fixedHeaderWidth"
                scope="col"
                [fixedColumn]="true"
                [css]="header.css"
                [text]="header.label"
              >
                <ng-template
                  *ngTemplateOutlet="
                    dropdown;
                    context: header.field === 'quarter'
                      ? dropdownData
                      : dropdownPeriod
                  "
                ></ng-template>
              </app-table-header>
              <app-table-header
                *ngFor="let headerData of tableData?.headers"
                height="40px"
                width="140px"
                scope="col"
                css="px-2 fw-bold text-white fs-5"
                alignText="text-right"
                [text]="headerData[header.field]"
              >
              </app-table-header>
            </app-table-row>
          </ng-container>
        </ng-container>

        <ng-container *ngFor="let row of tableData.rows; let index = index">
          <!-- LEFT SECTION FIXED -->
          <app-table-row
            appRemoveHost
            [ref]="'TABLE_LAYOUT' + row.id + refComponent"
          >
            <ng-container *ngIf="isIndicators; else headerOtherTabs">
              <app-table-cell
                appRemoveHost
                height="40px"
                css="py-1 border-0 outline-border custom-index"
                cssContent="fw-bold fs-5 text-brand-primary"
                [fixedColumn]="true"
                [visible]="true"
                [minCellWidth]="190"
                [text]="row.description"
                [hasChildren]="row[childrenField]?.length"
                [ref]="row.id + refComponent"
                [refParent]="'TABLE_LAYOUT' + row.id + refComponent"
                [delayCollapse]="10"
                [forceCollapsed]="index === 0 && collapseFirstLine"
                [componentID]="componentID"
                (Click)="toggleRow(row[childrenField])"
              >
              </app-table-cell>
            </ng-container>
            <ng-template #headerOtherTabs>
              <ng-template
                *ngTemplateOutlet="
                  cellFixed;
                  context: {
                    data: row,
                    ref: row.id + refComponent,
                    refParent: row.id + refComponent,
                    visible: true
                  }
                "
              >
              </ng-template>

              <ng-container *ngFor="let item of row.values">
                <ng-template
                  *ngTemplateOutlet="cellValues; context: { cell: item }"
                ></ng-template>
              </ng-container>
            </ng-template>
          </app-table-row>

          <!-- TOGGLE CONTENT -->
          <ng-container *ngIf="isIndicators && row[childrenField].length">
            <ng-template
              *ngTemplateOutlet="
                subTable;
                context: {
                  item: row[childrenField],
                  parent: 'TABLE_LAYOUT' + row.id + refComponent
                }
              "
            >
            </ng-template>
          </ng-container>

          <!-- CHILDRENS -->
          <ng-container *ngIf="row?.childrens?.length">
            <ng-template
              *ngTemplateOutlet="
                rowChildren;
                context: {
                  item: row.childrens,
                  parent: 'TABLE_LAYOUT' + row.id + refComponent
                }
              "
            >
            </ng-template>
          </ng-container>
        </ng-container>
      </app-table>
    </ng-container>

    <ng-template #withoutData>
      <div class="w-100 my-2 d-flex flex-column">
        <h3>Não há dados</h3>
        <span>
          Infelizmente, não possuimos dados de fundamentos deste ativo. Por
          favor, selecione outro ativo para analisar seus fundamentos.
        </span>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #loadingContent>
  <div
    class="align-items-center d-flex flex-column gap-3 h-100 justify-content-start my-2 w-100"
  >
    <span class="fs-5 text-white fw-bold">
      Carregando conteúdo, por favor aguarde.
    </span>
    <div class="spinner-border text-brand-primary" role="status"></div>
  </div>
</ng-template>

<ng-template #dropdown let-data="data">
  <div class="dropdown dropdown-position">
    <a
      [ngClass]="{ 'small-dropdown': data.useSmall }"
      [id]="'dropdownMenuLink' + refComponent"
      class="dropdown-custom-size border btn btn-primary dropdown-toggle fw-bold p-1 round-lg text-white bg-transparent fs-6"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ data.active }}
    </a>

    <ul
      class="dropdown-menu cursor-pointer"
      [attr.aria-labelledby]="'dropdownMenuLink' + refComponent"
    >
      <li *ngFor="let item of data.items">
        <a
          class="dropdown-item"
          [ngClass]="{ 'text-brand-primary': item === data.active }"
          (click)="updateFilter(data.type, item)"
        >
          {{ item }}
        </a>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #subTable let-value="item" let-parent="parent">
  <app-table-row appRemoveHost [refParent]="parent" [visible]="false">
    <app-table-cell
      appRemoveHost
      height="40px"
      css="p-0 border-none custom-font-size"
      [colspan]="tableData.headers + 1"
      [componentID]="componentID"
    >
      <app-table
        css="table m-0"
        containerCss="table-container simple p-0 custom-font-size"
        layout="fixed"
        height="auto"
        [isChildren]="true"
      >
        <ng-container *ngFor="let item of value">
          <app-table-row
            appRemoveHost
            [ref]="item.id + refComponent"
            [refParent]="parent"
          >
            <app-table-cell
              height="40px"
              appRemoveHost
              css="pl-6 fs-5 border-0 outline-border custom-index py-0"
              [minCellWidth]="300"
              [fixedColumn]="true"
              [visible]="false"
              [text]="item[childrenFieldValue]"
              [ref]="item.id + refComponent"
              [hasChildren]="item[childrenField]?.length"
              [cssContent]="
                item[childrenField]?.length
                  ? 'fw-bold fs-5 text-brand-primary'
                  : ''
              "
              [componentID]="componentID"
              (Click)="toggleRow(item[childrenField])"
            >
            </app-table-cell>
          </app-table-row>
        </ng-container>
      </app-table>
    </app-table-cell>
  </app-table-row>
</ng-template>

<ng-template #cellValues let-cell="cell">
  <app-table-cell
    appRemoveHost
    height="40px"
    alignText="text-right"
    cssContent="fs-5 text-white"
    css="border-none fs-5"
    [text]="valueFormatter(cell.value)"
    [componentID]="componentID"
  >
  </app-table-cell>
</ng-template>

<ng-template #rowChildren let-value="item" let-parent="parent">
  <app-table-row appRemoveHost [refParent]="parent" [visible]="false">
    <app-table-cell
      appRemoveHost
      height="40px"
      [colspan]="tableData.headers.length + 1"
      [componentID]="componentID"
    >
      <app-table
        layout="fixed"
        height="auto"
        containerCss="table-container simple p-0"
        css="table m-0 custom-font-size"
        cssContent="custom-font-size"
        [isChildren]="true"
        [componentID]="componentID"
      >
        <ng-container *ngFor="let item of value">
          <app-table-row
            appRemoveHost
            [ref]="item.id + refComponent"
            [refParent]="parent"
          >
            <ng-template
              *ngTemplateOutlet="
                cellFixed;
                context: {
                  data: item,
                  ref: item.id + refComponent,
                  refParent: item.id + refComponent,
                  visible: false
                }
              "
            >
            </ng-template>
          </app-table-row>

          <ng-container *ngIf="item.childrens.length">
            <ng-template
              *ngTemplateOutlet="
                rowChildren;
                context: {
                  item: item.childrens,
                  parent: 'TABLE_LAYOUT' + item.id + refComponent
                }
              "
            >
            </ng-template>
          </ng-container>
        </ng-container>
      </app-table>
    </app-table-cell>
  </app-table-row>
</ng-template>

<ng-template
  #cellFixed
  let-data="data"
  let-ref="ref"
  let-refParent="refParent"
  let-visible="visible"
>
  <app-table-cell
    appRemoveHost
    height="40px"
    [minCellWidth]="190"
    cssContent="fw-bold"
    css="py-1 border-0 outline-border custom-index"
    cssContent="fw-bold fs-5 text-brand-primary"
    [ref]="ref"
    [fixedColumn]="true"
    [visible]="visible"
    [text]="data.description"
    [hasChildren]="data.childrens.length"
    [refParent]="'TABLE_LAYOUT' + refParent"
    [componentID]="componentID"
    (Click)="toggleChildren(data.childrens)"
  >
  </app-table-cell>
</ng-template>
