import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
// @ts-expect-error omnitheme import
import { Tooltip } from '@omni/omnitheme';
import { randomId } from 'src/app/utils/utils.framework';
import { TToltipsPosition } from '../types/tooltips.types';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
@Directive({
  // eslint-disable-next-line
  selector: '[tooltip]',
})
export class TooltipsDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Output() tooltipClose = new EventEmitter<any>();

  @Input() tooltip!: string | number;
  @Input() tooltipPosition: TToltipsPosition = 'top';
  @Input() tooltipIsHtml: boolean = true;
  @Input() tooltipIsAdaptive: boolean = true;
  @Input() tooltipContainer: string | HTMLElement | false = false;
  @Input() tooltipDelay: number = 0;
  @Input() tooltipHidden: boolean = false;

  private id: string = randomId('rocket_tooltip');
  private tooltipRef: Tooltip;
  private element!: HTMLElement;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.element = this.elementRef.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { tooltip, tooltipHidden } = changes;
    if (tooltip?.currentValue) this.changeTitle();
    if (!isNullOrUndefined(tooltipHidden?.currentValue)) {
      if (tooltipHidden.currentValue) this._preventTooltip();
      else {
        this.init();
        this.changeTitle();
        this.tooltipRef?.enable();
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.tooltipHidden) return;
    this.init();
  }

  ngOnDestroy(): void {
    this.tooltipRef?.hide();
  }

  private changeTitle(): void {
    if (this.tooltipHidden) return;
    this.tooltipRef?.dispose();
    this.init();
  }

  private init(): void {
    if (/(rt|fla|rocket)/i.test(this.element.tagName)) {
      this.element.classList.add('d-inline-block');
    }

    this.element.id = this.id;
    const adaptiveModifier = this.getAdaptive();
    const modifiers = adaptiveModifier ? adaptiveModifier.modifiers : undefined;
    const popperConfig = modifiers ? { modifiers } : {};
    if (this.tooltip) {
      this.tooltipRef = Tooltip.getOrCreateInstance(this.element, {
        title: this.tooltip,
        placement: this.tooltipPosition,
        html: this.tooltipIsHtml,
        container: this.tooltipContainer,
        delay: { show: this.tooltipDelay, hide: 0 },
        popperConfig,
      });
    }

    this.renderer.listen(this.element, 'hidden.bs.tooltip', ($event) =>
      this.tooltipClose.next($event)
    );
    this.renderer.listen(this.element, 'click', () => this.tooltipRef?.hide());
  }

  private getAdaptive() {
    return !this.tooltipIsAdaptive
      ? {
          modifiers: [
            {
              name: 'computeStyles',
              options: {
                adaptive: this.tooltipIsAdaptive,
              },
            },
          ],
        }
      : undefined;
  }

  private _preventTooltip(): void {
    if (this.tooltipRef) {
      this.tooltipRef.disable();
      this.tooltipRef.hide();
    }
  }
}
