<div #stockChartHeader>
  <workspace-doc-header *ngIf="configs" css="w-100 " [item]="component">
    <div class="hstack w-100">
      <app-search-stock
        #searchStock
        [resetInput]="false"
        [ref]="refComponent"
        [headerOptions]="headerOptions"
        [initStock]="stock"
        (selectStockChange)="selectStock($event)"
      ></app-search-stock>

      <ng-container [ngSwitch]="headerType">
        <ng-container *ngSwitchCase="allHeaderTypes.DEFAULT">
          <fla-workspace-doc-header-item
            [idElement]="elementIDS.HEADER_PERIOD_AND_STYLE"
            [enableContent]="true"
          >
            <ng-container *ngTemplateOutlet="stockVariation"></ng-container>
            <ng-container
              *ngTemplateOutlet="
                chartPeriod;
                context: { classes: 'border-right' }
              "
            ></ng-container>
            <ng-container *ngTemplateOutlet="chartType"></ng-container>
          </fla-workspace-doc-header-item>

          <fla-workspace-doc-header-item
            [idElement]="elementIDS.HEADER_INDICATORS_AND_DRAW"
            [enableContent]="true"
          >
            <ng-container *ngTemplateOutlet="indicators"></ng-container>
            <ng-container *ngTemplateOutlet="drawnToolts"></ng-container>
          </fla-workspace-doc-header-item>

          <fla-workspace-doc-header-item
            [idElement]="elementIDS.HEADER_X_RAY"
            [enableContent]="true"
          >
            <ng-container *ngTemplateOutlet="xRay"></ng-container>
          </fla-workspace-doc-header-item>

          <fla-workspace-doc-header-item [enableContent]="true">
            <ng-container *ngTemplateOutlet="marketFlash"></ng-container>
          </fla-workspace-doc-header-item>
        </ng-container>

        <ng-container *ngSwitchCase="allHeaderTypes.SCALE_ONE">
          <fla-workspace-doc-header-item [enableContent]="true">
            <ng-container *ngTemplateOutlet="stockVariation"></ng-container>
            <ng-container *ngTemplateOutlet="chartPeriod"></ng-container>
          </fla-workspace-doc-header-item>

          <fla-workspace-doc-header-item [enableContent]="true">
            <ng-container *ngTemplateOutlet="chartType"></ng-container>
          </fla-workspace-doc-header-item>

          <fla-workspace-doc-header-item
            [idElement]="elementIDS.HEADER_INDICATORS_AND_DRAW"
            [enableContent]="true"
          >
            <ng-container *ngTemplateOutlet="indicators"></ng-container>
            <ng-container *ngTemplateOutlet="drawnToolts"></ng-container>
          </fla-workspace-doc-header-item>

          <fla-workspace-doc-header-item [enableContent]="true">
            <app-dropdown menuAlign="dropdown-menu-right">
              <button
                appDropdownButton
                class="btn btn-icon fs-6 mx-2 border-right-0"
              >
                <fla-icon name="more_vert" css="text-light" tooltip="Mais">
                </fla-icon>
              </button>
              <li>
                <ng-container
                  *ngTemplateOutlet="xRay; context: { label: 'Raio-X' }"
                ></ng-container>
              </li>
              <li>
                <ng-container
                  *ngTemplateOutlet="
                    marketFlash;
                    context: { label: 'Flash de mercado' }
                  "
                ></ng-container>
              </li>
            </app-dropdown>
          </fla-workspace-doc-header-item>
        </ng-container>

        <ng-container *ngSwitchCase="allHeaderTypes.MEDIUM">
          <fla-workspace-doc-header-item [enableContent]="true">
            <ng-container *ngTemplateOutlet="stockVariation"></ng-container>
            <ng-container *ngTemplateOutlet="chartPeriod"></ng-container>
          </fla-workspace-doc-header-item>

          <fla-workspace-doc-header-item [enableContent]="true">
            <ng-container *ngTemplateOutlet="chartType"></ng-container>
          </fla-workspace-doc-header-item>

          <fla-workspace-doc-header-item [enableContent]="true">
            <app-dropdown menuAlign="dropdown-menu-right">
              <button
                appDropdownButton
                class="btn btn-icon fs-6 mx-2 border-right-0"
              >
                <fla-icon name="more_vert" css="text-light" tooltip="Mais">
                </fla-icon>
              </button>

              <li>
                <ng-container
                  *ngTemplateOutlet="
                    indicators;
                    context: { label: 'Indicadores' }
                  "
                ></ng-container>
              </li>

              <li>
                <ng-container
                  *ngTemplateOutlet="
                    drawnToolts;
                    context: { label: 'Desenhos' }
                  "
                ></ng-container>
              </li>

              <li class="border-neutral-stronger border-top">
                <ng-container
                  *ngTemplateOutlet="
                    xRay;
                    context: { label: 'Raio-X', minHeight: 40 }
                  "
                ></ng-container>
              </li>
              <li>
                <ng-container
                  *ngTemplateOutlet="
                    marketFlash;
                    context: { label: 'Flash de mercado', minHeight: 40 }
                  "
                ></ng-container>
              </li>
            </app-dropdown>
          </fla-workspace-doc-header-item>
        </ng-container>

        <ng-container *ngSwitchCase="allHeaderTypes.SMALL">
          <fla-workspace-doc-header-item [enableContent]="true">
            <ng-container *ngTemplateOutlet="stockVariation"></ng-container>
            <ng-container *ngTemplateOutlet="chartPeriod"></ng-container>
          </fla-workspace-doc-header-item>
          <fla-workspace-doc-header-item [enableContent]="true">
            <app-dropdown menuAlign="dropdown-menu-right">
              <button
                appDropdownButton
                class="btn btn-icon fs-6 mx-2 border-right-0"
              >
                <fla-icon name="more_vert" css="text-light" tooltip="Mais">
                </fla-icon>
              </button>

              <li class="stock-header-custom-selection">
                <ng-container *ngTemplateOutlet="chartTypeDropDown">
                </ng-container>
              </li>

              <li>
                <ng-container
                  *ngTemplateOutlet="
                    indicators;
                    context: {
                      label: 'Indicadores',
                      minWidth: 24,
                      minHeight: 40
                    }
                  "
                ></ng-container>
              </li>

              <li>
                <ng-container
                  *ngTemplateOutlet="
                    drawnToolts;
                    context: { label: 'Desenhos' }
                  "
                ></ng-container>
              </li>

              <li class="border-neutral-stronger border-top">
                <ng-container
                  *ngTemplateOutlet="
                    xRay;
                    context: { label: 'Raio-X', minHeight: 40 }
                  "
                ></ng-container>
              </li>
              <li>
                <ng-container
                  *ngTemplateOutlet="
                    marketFlash;
                    context: { label: 'Flash de mercado', minHeight: 40 }
                  "
                ></ng-container>
              </li>
            </app-dropdown>
          </fla-workspace-doc-header-item>
        </ng-container>

        <ng-container *ngSwitchCase="allHeaderTypes.MICRO">
          <fla-workspace-doc-header-item [enableContent]="true">
            <ng-container *ngTemplateOutlet="stockVariation"></ng-container>
            <app-dropdown menuAlign="dropdown-menu-right">
              <button
                appDropdownButton
                class="btn btn-icon fs-6 mx-2 border-right-0"
              >
                <fla-icon name="more_vert" css="text-light" tooltip="Mais">
                </fla-icon>
              </button>

              <li class="stock-header-custom-selection">
                <ng-container *ngTemplateOutlet="chartPeriodDropDown">
                </ng-container>
              </li>

              <li class="stock-header-custom-selection">
                <ng-container *ngTemplateOutlet="chartTypeDropDown">
                </ng-container>
              </li>

              <li>
                <ng-container
                  *ngTemplateOutlet="
                    indicators;
                    context: {
                      label: 'Indicadores',
                      minWidth: 24,
                      minHeight: 40
                    }
                  "
                ></ng-container>
              </li>

              <li>
                <ng-container
                  *ngTemplateOutlet="
                    drawnToolts;
                    context: { label: 'Desenhos' }
                  "
                ></ng-container>
              </li>

              <li class="border-neutral-stronger border-top">
                <ng-container
                  *ngTemplateOutlet="
                    xRay;
                    context: { label: 'Raio-X', minHeight: 40 }
                  "
                ></ng-container>
              </li>
              <li>
                <ng-container
                  *ngTemplateOutlet="
                    marketFlash;
                    context: { label: 'Flash de mercado', minHeight: 40 }
                  "
                ></ng-container>
              </li>
            </app-dropdown>
          </fla-workspace-doc-header-item>
        </ng-container>

        <ng-container *ngSwitchCase="allHeaderTypes.NANO">
          <fla-workspace-doc-header-item [enableContent]="true">
            <app-dropdown menuAlign="dropdown-menu-right">
              <button
                appDropdownButton
                class="btn btn-icon fs-6 mx-2 border-right-0"
              >
                <fla-icon name="more_vert" css="text-light" tooltip="Mais">
                </fla-icon>
              </button>

              <li class="stock-header-custom-selection">
                <ng-container *ngTemplateOutlet="chartPeriodDropDown">
                </ng-container>
              </li>

              <li class="stock-header-custom-selection">
                <ng-container *ngTemplateOutlet="chartTypeDropDown">
                </ng-container>
              </li>

              <li>
                <ng-container
                  *ngTemplateOutlet="
                    indicators;
                    context: {
                      label: 'Indicadores',
                      minWidth: 24,
                      minHeight: 40
                    }
                  "
                ></ng-container>
              </li>

              <li>
                <ng-container
                  *ngTemplateOutlet="
                    drawnToolts;
                    context: { label: 'Desenhos' }
                  "
                ></ng-container>
              </li>

              <li class="border-neutral-stronger border-top">
                <ng-container
                  *ngTemplateOutlet="
                    xRay;
                    context: { label: 'Raio-X', minHeight: 40 }
                  "
                ></ng-container>
              </li>
              <li>
                <ng-container
                  *ngTemplateOutlet="
                    marketFlash;
                    context: { label: 'Flash de mercado', minHeight: 40 }
                  "
                ></ng-container>
              </li>
            </app-dropdown>
          </fla-workspace-doc-header-item>
        </ng-container>
      </ng-container>

      <div class="d-inline-flex">
        <section
          [id]="elementIDS.HEADER_BUY_AT_MARKET"
          class="d-inline-flex"
          *ngIf="!isBlockStockIndex"
        >
          <fla-workspace-doc-header-item
            *ngIf="!showFastOrder"
            [enableContent]="true"
          >
            <rocket-button
              [isSmall]="true"
              tooltip="Comprar a mercado"
              label="CM"
              [isDisabled]="loading?.MARKET_BUY_BUTTON || disableButtons"
              css="order-button bg-multibroker-buy"
              (rocketClick)="
                sendMarketOrder(
                  sideOrders.BUY,
                  orderParamHelper.STOCK_CHART_HEADER_BUTTON_BUY_MARKET
                )
              "
            >
            </rocket-button>
          </fla-workspace-doc-header-item>
          <fla-workspace-doc-header-item
            *ngIf="!showFastOrder"
            [enableContent]="true"
          >
            <app-input-count
              tooltip="Quantidade a ser negociada"
              margin="m-1"
              class="order-quantity-input"
              [isSmall]="true"
              [clearable]="false"
              [formControl]="negotiationLotControl"
              [incrementValue]="standardLot"
              [min]="standardLot"
              [initialValue]="negotiationLot"
              (rtChange)="onNegotiationLotChange($event)"
              [alwaysShowIncrementButton]="false"
              [alwaysShowDecrementButton]="false"
              mask="separator.0"
              [css]="qttyChangedColorEnabled ? 'color-modify-qtty' : ''"
              [enableScrollEvent]="true"
              [selectOnFocus]="true"
              [isQttyInput]="true"
              [emitEventOnBackToSourceValue]="true"
            ></app-input-count>
          </fla-workspace-doc-header-item>
          <fla-workspace-doc-header-item
            *ngIf="!showFastOrder"
            [enableContent]="true"
          >
            <rocket-button
              [isSmall]="true"
              tooltip="Vender a mercado"
              label="VM"
              [isDisabled]="loading?.MARKET_SELL_BUTTON || disableButtons"
              css="order-button bg-multibroker-sell last-button"
              (rocketClick)="
                sendMarketOrder(
                  sideOrders.SELL,
                  orderParamHelper.STOCK_CHART_HEADER_BUTTON_SELL_MARKET
                )
              "
            >
            </rocket-button>
          </fla-workspace-doc-header-item>
        </section>
      </div>
    </div>
  </workspace-doc-header>
</div>

<ng-template #stockVariation>
  <fla-workspace-doc-header-item [enableContent]="true">
    <div class="position-relative px-2" style="top: -2px">
      <app-tag
        [useSmallTag]="true"
        [isVariation]="true"
        [variation]="dayVariation"
        [bgColor]="bgVariation"
        [color]="colorVariation"
      ></app-tag>
    </div>
  </fla-workspace-doc-header-item>
</ng-template>

<ng-template #chartPeriod let-classes="classes">
  <app-dropdown-interval
    [class]="classes"
    tooltip="Selecione um agrupamento"
    [label]="selectedInterval"
    [options]="intervals"
    [currentOption]="configs.chart && configs.chart.series!.interval!"
    (handleSelectedOption)="onchangeInterval($event)"
  ></app-dropdown-interval>
</ng-template>

<ng-template #chartPeriodDropDown>
  <stock-chart-select
    [txtIcon]="selectedInterval"
    label="Agrupamento"
    tooltip="Selecione um agrupamento"
  >
    <ul class="content">
      <interval-options
        [options]="intervals"
        [currentID]="configs.chart && configs.chart.series!.interval!"
        (onSelectOption)="onchangeInterval($event)"
      ></interval-options>
    </ul>
  </stock-chart-select>
</ng-template>

<ng-template #chartType>
  <app-dropdown-types
    tooltipText="Escolha o tipo de gráfico"
    [icon]="
      configs.chart && configs.chart.series
        ? typeDic.get(configs.chart.series!.type!)?.icon
        : ''
    "
    [options]="types"
    [currentOption]="configs.chart.series!.type"
    (handleSelectedOption)="onchageTypeGrafic($event)"
  ></app-dropdown-types>
</ng-template>

<ng-template #chartTypeDropDown>
  <stock-chart-select
    label="Tipo do gráfico"
    [flaIcon]="
      configs.chart && configs.chart.series
        ? typeDic.get(configs.chart.series!.type!)?.icon
        : ''
    "
    flaIconCss="text-light m-0"
    tooltip="Escolha o tipo de gráfico"
  >
    <ul class="content">
      <chart-types
        [options]="types"
        [currentOption]="configs.chart.series!.type"
        (onSelectOption)="onchageTypeGrafic($event)"
      >
      </chart-types>
    </ul>
  </stock-chart-select>
</ng-template>

<ng-template #drawnToolts let-label="label">
  <app-stock-chart-header-button
    [label]="label"
    class="border-left w-100"
    iconName="edit"
    flaCss="text-light m-0"
    [tooltip]="showTools ? 'Ocultar Desenhos' : 'Exibir Desenhos'"
    [minWidth]="24"
    (headerClick)="dispatchTools()"
  ></app-stock-chart-header-button>
</ng-template>

<ng-template
  #indicators
  let-label="label"
  let-minWidth="minWidth"
  let-minHeight="minHeight"
>
  <app-stock-chart-header-button
    iconName="INDICATORS"
    tooltip="Adicionar Indicadores"
    class="w-100"
    [label]="label"
    [useFlaIcon]="false"
    [width]="16"
    [height]="16"
    [minWidth]="minWidth"
    [minHeight]="minHeight"
    (headerClick)="openIndicatorsModal()"
  ></app-stock-chart-header-button>
</ng-template>

<ng-template #xRay let-label="label" let-minHeight="minHeight">
  <app-stock-chart-header-button
    [label]="label"
    [useFlaIcon]="false"
    tooltip="Raio-X"
    label="Raio-X"
    (headerClick)="handleOpenTool('BUSINESS_PROFILE_COMPONENT')"
    iconName="RAIOX"
    flaCss="text-light m-0"
    [minWidth]="24"
    [minHeight]="minHeight"
    class="w-100"
  ></app-stock-chart-header-button>
</ng-template>

<ng-template #marketFlash let-label="label" let-minHeight="minHeight">
  <app-stock-chart-header-button
    [label]="label"
    [useFlaIcon]="false"
    tooltip="Flash de mercado"
    (headerClick)="handleOpenTool('RANKING_BROKER')"
    iconName="RANKING_BROKER"
    flaCss="text-light m-0"
    [minWidth]="24"
    [minHeight]="minHeight"
  ></app-stock-chart-header-button>
</ng-template>
