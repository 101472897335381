import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IBrokersToConnect } from '@shared/services/api/trademap/V9/models/brokers-to-connect.interface';
import { alphabeticalSort } from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styles: [
    `
      :host(app-card-list) {
        .height-div {
          min-height: 150px;
        }
      }
    `,
  ],
})
export class CardListComponent implements OnChanges {
  @Input() public brokers: IBrokersToConnect[] = [];
  @Output() brokerSelected: EventEmitter<any> = new EventEmitter();
  @Output() brokerDisconnect: EventEmitter<any> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    const { brokers } = changes;
    if (brokers?.currentValue?.length) {
      const response = alphabeticalSort(
        brokers.currentValue,
        'nmBrokerNickname'
      );
      this.brokers = response;
    }
  }
}
