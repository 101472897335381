import { Injectable } from '@angular/core';
import { CHEETAH_CHANNEL, VOLUME_FIELDS } from '@shared/cheetah/cheetah.const';
import { MarketDispatcher } from '@shared/cheetah/dispatcher/market.dispatcher';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { RxEvent } from './rx-event';

@Injectable({
  providedIn: 'root',
})
export class VolumeChannel {
  static instance: VolumeChannel;

  constructor(
    private _rxEvent: RxEvent,
    private cheetahService: MarketDispatcher
  ) {
    return (VolumeChannel.instance = VolumeChannel.instance || this);
  }

  async onEvents() {
    return await this._rxEvent.read(CHEETAH_CHANNEL.VOLUME);
  }

  subscribe(params: SubscribeParam) {
    params.fields = VOLUME_FIELDS;
    params.channel_cheetah = CHEETAH_CHANNEL.VOLUME;
    this.cheetahService.subscribe({
      channel_cheetah: CHEETAH_CHANNEL.VOLUME,
      itemsArray: params.itemsArray,
      fields: VOLUME_FIELDS,
    });
  }

  unsubscribe(params: SubscribeParam) {
    this.cheetahService.unsubscribe({
      channel_cheetah: CHEETAH_CHANNEL.VOLUME,
      itemsArray: params.itemsArray,
    });
  }
}

export interface IVolumeEventData {
  channel: string;
  command: string;
  item: string;
  key: string;
  qtty_buyer: string;
  qtty_direct: string;
  qtty_rlp: string;
  qtty_seller: string;
  qtty_total: string;
  isEndOfSnap?: boolean;
}
