import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';
import { OptionsComponents } from '../component-list';

export const ORDER_HISTORY_COMPONENT: any = (options?: OptionsComponents) => {
  return {
    name: 'Ordens & Posição',
    id: COMPONENTS_NAMES_ENUM.LISTA_ORDENS,
    icon: 'dynamic_form',
    cod: 'LISTA_ORDENS',
    data: null,
    close: true,
    fullscreen: true,
    link: true,
    divider: true,
    widthDesk: 945,
    heightDesk: 268,
    unitOfMeasurement: '%',
    height: options?.height ?? 29,
    width: options?.width ?? Math.round((995 * 100) / window.innerWidth),
    left: options?.left ?? 'calc(15% + 5px)',
    top: options?.top ?? 'calc(70% + 5px)',
    minWidth: '435px',
    minHeight: '150px',
    iconSvg: '',
  };
};
