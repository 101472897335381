import { Component, Inject, OnInit } from '@angular/core';
import { Dictionary } from '@core/models';
import { HomeService } from '@modules/home/service/home.service';
import { waitTimeout } from 'src/app/utils/utils.functions';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components/index';
import { ComponentsService } from '@core/workspace/service/components.service';

@Component({
  selector: 'app-modal-add-component-workspace',
  templateUrl: './modal-add-component-workspace.component.html',
  styleUrls: ['./modal-add-component-workspace.component.scss'],
})
export class ModalAddComponentWorkspaceComponent
  extends RocketModalRef
  implements OnInit
{
  selecteds: Dictionary<any> = new Dictionary<any>();
  tabs = [
    {
      ref: 'components',
      title: 'Componentes',
      active: true,
    },
    { ref: 'tools', title: 'Ferramentas', active: false },
  ];
  tabsComponents: { [key: string]: any[] } = {
    components: [],
    tools: [],
  };

  constructor(
    @Inject(ROCKET_MODAL_DATA)
    private data: {
      title: string;
      idWorkspace: any;
      text: any;
      showButtons?: boolean;
      cancelButton?: string;
      confirmeButton?: string;
      isSecondModal?: boolean;
      askAgain?: boolean;
      typeAskAgain?: string;
    },
    service: RocketModalService,
    private homeService: HomeService,
    private componentsService: ComponentsService
  ) {
    super(service);
  }

  ngOnInit() {
    this.initializeTabComponents();
  }

  private initializeTabComponents() {
    this.tabsComponents['components'] = this.componentsService
      .getComponentsList()
      .values()
      .flat();
    this.tabsComponents['tools'] = this.componentsService
      .getToolsList()
      .values()
      .flat();
  }

  selectComponentToAdd(item: any) {
    if (this.selecteds.get(item.cod)) this.selecteds.delete(item.cod);
    else this.selecteds.set(item.cod, item);
  }

  onClose = () => {
    this.close();
  };

  onSave = () => {
    this.onClose();
    waitTimeout(250).then(() => {
      const modal = document.getElementsByClassName('modal-backdrop');
      modal[modal.length - 1]?.remove();
      this.homeService.addComponents(this.selecteds.values());
    });
  };

  changeTab(ref: string) {
    this.tabs = this.tabs.map((tab: any) => ({
      ...tab,
      active: tab.ref == ref,
    }));
  }
}
