import { system } from '@core/system/system.service';
import {
  dateTimeFormatter,
} from '@shared/rocket-components/utils/functions';
import {
  ORDER_AUCTION_VALIDITY,
  isDoubleStartStop,
} from '@shared/constants/general.contant';
import {
  ORDER_ALL_STATUS_DIC,
  ORDER_EXEC_STATUS_DIC,
} from '@shared/dictionary/order.dictionary';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import {
  dec2hex,
  financialFormatDoublePrice,
  financialFormatDoubleTrigger,
  financialFormatterFunctionPriceDouble,
  financialFormatterFunctionStopDouble,
  orderTypeFormatterFunction,
  qtdeFormatterFunction,
  tooltipDoublePrice,
  tooltipDoubleTrigger,
  verifyDirectionStopDouble,
  verifyResetPosition,
} from 'src/app/utils/cell-format.functions';
import {
  SIDE_CHARACTER,
} from '../../constants/orders-history.const';
import { CustomTooltipComponent } from '../custom-tooltip/custom-tooltip.component';
import {
  DAY_TRADE_REPLACE_KEY,
  SWING_REPLACE_KEY,
} from '@core/layout/header/constants/daytrade.constant';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import { execFormatFinancial } from 'src/app/utils/utils.functions';

export function qtdeFormatter(params: ValueFormatterParams) {
  ORDER_EXEC_STATUS_DIC.has(params.data.cd_order_status) &&
    (params.value = params.data.qtty_executed);
  return qtdeFormatterFunction(params);
}

export const checkExecutedPriceSameSend = (
  price: string,
  priceExecuted: string,
  cdOrderStatus: string
): string => {
  return price &&
    priceExecuted &&
    cdOrderStatus &&
    ORDER_EXEC_STATUS_DIC.has(cdOrderStatus) &&
    parseFloat(price) != parseFloat(priceExecuted)
    ? 'text-feedback-warning text-right'
    : 'text-right';
};

export const proccessQttyByTotal = (data: any) => {
  return `${parseInt(data.qtty_executed).toLocaleString()} / ${parseInt(
    data.qtty
  ).toLocaleString()}`;
};

export const setColorBackgroundByStatusOrder = (data: any) => {
  if (!data || !data.cd_order_status) return null;

  const orderStatusColorString = system.ordersStatusColor;
  if (!orderStatusColorString) return null;

  return {
    'background-color': `${dec2hex(
      orderStatusColorString[data.cd_order_status].color
    )}`,
  };
};

export const getValidadeLabel = (params: ValueFormatterParams): string => {
  const { value } = params;
  const { cd_order_time_enforce, cd_order_type, dt_expirate_date } =
    params.data;
  const dsOrderTimeEnforce =
    ORDER_AUCTION_VALIDITY[`${cd_order_time_enforce}${cd_order_type}`] ?? value;
  switch (dsOrderTimeEnforce) {
    case '0':
      return 'Dia';
    case 'Até o vencimento':
    case '6':
      return dt_expirate_date;
    case '1':
    case 'Até cancelar':
      return 'VAC';
    case '3':
      return 'Executa ou Cancela';
    case '4':
      return 'Tudo ou Nada';
    default:
      return dsOrderTimeEnforce;
  }
};

export const getOrderTypeRenderer = (params: ValueFormatterParams) => {
  return `${orderTypeFormatterFunction(params)}${
    verifyDirectionStopDouble(params.data) == 'GAIN'
      ? ' <span class="position-absolute right-0 icon-position new-position gain">G</span>'
      : verifyDirectionStopDouble(params.data) == 'LOSS'
      ? ' <span class="position-absolute right-0 icon-position loss">L</span>'
      : ''
  }${
    verifyResetPosition(params.data)
      ? ' <span class="position-absolute right-0 icon-position zeroing">Z</span>'
      : ''
  }`;
};

export const HEADERS_ORDERS_OPEN_CONFIG: any = {
  edit: {
    hide: false,
  },
  close: {
    hide: false,
  },
};

export const everythingOrNothing = (data: any) => {
  return (
    data.ds_order_time_enforce === '4' ||
    data.ds_order_time_enforce === 'Tudo ou Nada'
  );
};


export const HEADERS_ORDERS_CONFIG: any = {
  dh_creation_time: {
    hide: false,
    width: 90,
  },
  cd_stock: {
    hide: false,
    width: 70,
  },
  side: {
    hide: false,
    width: 50,
  },
  qtty: {
    hide: false,
    width: 60,
  },
  price: {
    hide: false,
    width: 100,
  },
  price_stop: {
    hide: false,
    width: 100,
  },
  ds_order_status: {
    hide: false,
    width: 80,
  },
  ds_order_type: {
    hide: false,
    width: 80,
  },
  qtty_executed: {
    hide: false,
    width: 80,
  },
  price_average: {
    hide: false,
    width: 80,
  },
  ds_order_time_enforce: {
    hide: false,
    width: 68,
  },
  is_day_trade: {
    hide: false,
    width: 70,
  },
};

export const HEADERS_DETAILS_ORDER: Array<ColDef> = [
  {
    headerName: 'Dt. Criação',
    field: 'dh_creation_event',
    headerTooltip: 'Data Criação',
    valueFormatter: (params: any) =>
      new Date(params.value).toLocaleString().replace(',', ''),
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Data Criação' },
    width: 120,
    suppressMovable: true,
  },
  {
    headerName: 'Situação',
    field: 'cd_order_status',
    headerTooltip: 'Situação',
    cellStyle: (params) => setColorBackgroundByStatusOrder(params.data),
    valueFormatter: (params: any) =>
      ORDER_ALL_STATUS_DIC.get(params.value) as string,
    tooltipValueGetter: (params: any) =>
      params.data.ds_text
        ? params.data.ds_text
        : params.data.ds_order_reject_reason,
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Situação' },
    width: 140,
    suppressMovable: true,
  },
  {
    headerName: 'Qtd Executada',
    field: 'qtty_executed',
    headerTooltip: 'Quantidade Executada',
    type: 'numericColumn',
    valueFormatter: (params) => qtdeFormatterFunction(params),
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Quantidade Executada' },
    width: 118,
    suppressMovable: true,
  },
  {
    headerName: 'Qtd Pendente',
    field: 'qtty_left',
    headerTooltip: 'Quantidade Pendente',
    type: 'numericColumn',
    valueFormatter: (params) => qtdeFormatterFunction(params),
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Quantidade Pendente' },
    width: 118,
    suppressMovable: true,
  },
  {
    headerName: 'Preço',
    field: 'price',
    headerTooltip: 'Preço da Ordem',
    type: 'numericColumn',
    headerClass: 'ag-right-aligned-header',
    width: 80,
    cellRenderer: (params: any) => {
      if (isDoubleStartStop(params.data.cd_order_type)) {
        return financialFormatDoublePrice(params.data);
      }
      return financialFormatterFunctionPriceDouble(params);
    },
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Preço da Ordem' },
    tooltipValueGetter: (params: any) =>
      isDoubleStartStop(params.data.cd_order_type)
        ? tooltipDoublePrice(params.data)
        : '',
    headerComponentParams: ['', 'price'],
  },
  {
    headerName: 'Stop',
    field: 'price_stop',
    headerTooltip: 'Preço Stop',
    type: 'numericColumn',
    headerClass: 'ag-right-aligned-header',
    width: 80,
    cellRenderer: (params: any) => {
      if (isDoubleStartStop(params.data.cd_order_type)) {
        return financialFormatDoubleTrigger(params.data);
      }
      return financialFormatterFunctionStopDouble(params);
    },
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Preço Stop' },
    tooltipValueGetter: (params: any) =>
      isDoubleStartStop(params.data.cd_order_type)
        ? tooltipDoubleTrigger(params.data)
        : '',
    headerComponentParams: ['', 'price_stop'],
  },
  {
    headerName: 'Tipo Execução',
    field: 'ds_order_exec_type',
    headerTooltip: 'Tipo Execução',
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Tipo da Execução' },
    width: 112,
    suppressMovable: true,
  },
  {
    headerName: 'Motivo Rejeição',
    field: 'ds_text',
    headerTooltip: 'Motivo da Rejeição',
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Motivo da Rejeição' },
    suppressMovable: true,
    width: 308,
    tooltipValueGetter: (params: any) => params.data.ds_text,
  },
];

export const getOrderRowToExport = (order: any) => {
  const valueData = (field: string) =>
    ({ value: order[field], data: order } as ValueFormatterParams);

  const price = isDoubleStartStop(order.cd_order_type)
    ? financialFormatDoublePrice(order)
    : financialFormatterFunctionPriceDouble(valueData('price'));

  const stop = isDoubleStartStop(order.cd_order_type)
    ? financialFormatDoubleTrigger(order)
    : financialFormatterFunctionStopDouble(valueData('price_stop'));

  const row = [
    dateTimeFormatter(order.dh_creation_time).replace(',', ''),
    order.cd_stock,
    order.nm_broker_nickname || '-',
    SIDE_CHARACTER[order.side] || '-',
    qtdeFormatter(valueData('qtty')),
    price,
    stop,
    order.ds_order_status,
    getOrderTypeRenderer({
      value: [order.ds_order_type, order.cd_order_status],
      data: order,
    } as ValueFormatterParams),
    proccessQttyByTotal(order),
    execFormatFinancial(order, order.price_average) || '-',
    getValidadeLabel(valueData('ds_order_time_enforce')),
    DaytradeService.getTextWithTradeMode(
      order.is_day_trade ? DAY_TRADE_REPLACE_KEY : SWING_REPLACE_KEY
    ),
  ];

  return row;
};
