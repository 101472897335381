import { ClickableAnnotation } from '../annotations/clickable-annotation';
import {
  TCandleLineOptions,
  TData,
  TEvents,
  TRelevantEvents,
} from '../types/tiger-chart.types';
import { Dictionary } from '@core/models';
import { TIGER_INTERVAL_ENUM, TIGER_LINE_TYPE } from '../enum';
import { getTemplateEvent } from '../templates/tiger-chart.template';
import { AxisBase2D } from 'scichart/Charting/Visuals/Axis/AxisBase2D';
import { ECoordinateMode } from 'scichart/Charting/Visuals/Annotations/AnnotationBase';
import {
  EHorizontalAnchorPoint,
  EVerticalAnchorPoint,
} from 'scichart/types/AnchorPoint';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { RocketModalService } from '@shared/rocket-components/components';
import { EventsContextMenuService } from './events-context-menu/events-context-menu.service';
import { NumberRange } from 'scichart/Core/NumberRange';
import {
  ascendingSort,
  getTextByRegex,
  nextDay,
} from 'src/app/utils/utils.functions';
import { deepClone, randomId } from '@shared/rocket-components/utils';
import { Subject, auditTime } from 'rxjs';
import {
  CORPORATE_EVENTS,
  EVENTS,
  INTERVAL_HASH,
  RELEVANT_EVENTS,
} from './tiger-chart-events-modal.const';
import { DocumentsModalComponent } from '@shared/documents-modal/documents-modal.component';
import { ITypeButton } from '../annotations/annotation-tooltip/annotation-tooltip.interface';
import { CategoryCoordinateCalculator } from 'scichart';
import { TypeButtonEnum } from '../annotations/annotation-tooltip/annotation-tooltip.enum';
import {
  div,
  formatMsDateToHumanDate,
  span,
} from 'src/app/utils/utils-events-worker.functions';
import { inject, NgZone } from '@angular/core';

export class TigerChartEventsModalService {
  ngZone = inject(NgZone);
  private updateVisibleEvents$ = new Subject<{
    refComponent: string;
    visibleRange: NumberRange;
  }>();
  private clickAnnotationEvents$ = new Subject<boolean>();
  private eventsAdded$ = new Subject<string>();
  corporateEventsDict: any = {};
  relevantEventsDict: any = {};
  newsChartEventsDict: any = {};
  tradeIdeaEventsDict: any = {};
  private eventDraw = new Dictionary<any>();
  private events: TEvents = {
    corporateEvents: [],
    relevantEvents: [],
    newsChartEvents: [],
    tradeIdeaEvents: [],
  };
  private eventsOnChart: any = {
    corporateEvents: [],
    relevantEvents: [],
    newsChartEvents: [],
    tradeIdeaEvents: [],
  };
  private worker!: Worker;
  private annotationEventsDict: any = {};
  private idPointsHash: any = {};
  private coordinatesX1: any = {};
  private minDate: number = 0;
  private maxDate: number = 0;
  private allEvents: any[] = [];
  expandEvent$ = new Subject<{
    xAxisId: string;
    tooltipButtons: ITypeButton;
    annotation: ClickableAnnotation;
    tooltip: string | string[];
    isExpand: boolean;
    isTradeIdea: boolean;
  }>();
  setBase = (base: TWebAssemblyChart) => {
    this.baseChart = base;
  };
  private showEvents: boolean = true;

  onUpdateVisibleEvents = () => this.updateVisibleEvents$.pipe(auditTime(300));

  onClickAnnotationEvents = () => this.clickAnnotationEvents$;

  dispatchUpdateVisibleEvents = (
    refComponent: string,
    visibleRange: NumberRange
  ) => this.updateVisibleEvents$.next({ refComponent, visibleRange });

  constructor(
    private baseChart: TWebAssemblyChart,
    private modalService: RocketModalService,
    private eventsContextMenuService: EventsContextMenuService,
    private ref: string,
    private _createAnnotationHoverFunction: (
      options: TCandleLineOptions
    ) => (annotation?: ClickableAnnotation) => void,
    private _hideAnnotationTooltip: () => void
  ) {
    this.createWorker();
  }

  addEvents(
    events: TEvents,
    xAxis: AxisBase2D,
    data: TData,
    interval: TIGER_INTERVAL_ENUM
  ) {
    if (!xAxis || !data) {
      return;
    }
    this.updateIdPoint(data, interval);
    this.events = events;
    const eventsToRemove = [
      ...deepClone(this.eventsOnChart.corporateEvents),
      ...deepClone(this.eventsOnChart.relevantEvents),
      ...deepClone(this.eventsOnChart.newsChartEvents),
      ...deepClone(this.eventsOnChart.tradeIdeaEvents),
    ];
    this.allEvents = [
      ...events.relevantEvents,
      ...events.corporateEvents,
      ...events.newsChartEvents,
      ...events.tradeIdeaEvents,
    ];
    this.removeAllEvents(eventsToRemove);
    this.addAllEvents(data, this.allEvents, interval);
  }

  clearDict() {
    const eventsToRemove = [
      ...deepClone(this.eventsOnChart.corporateEvents),
      ...deepClone(this.eventsOnChart.relevantEvents),
      ...deepClone(this.eventsOnChart.newsChartEvents),
      ...deepClone(this.eventsOnChart.tradeIdeaEvents),
    ];
    this.removeAllEvents(eventsToRemove);
    this.eventDraw.values().forEach((event) => {
      event.draw = false;
    });
    this.relevantEventsDict = {};
    this.corporateEventsDict = {};
    this.annotationEventsDict = {};
    this.tradeIdeaEventsDict = {};
    this.allEvents = [];
    this.events = {
      corporateEvents: [],
      relevantEvents: [],
      newsChartEvents: [],
      tradeIdeaEvents: [],
    };
  }

  updateIdPoint(data: TData, interval: TIGER_INTERVAL_ENUM) {
    this.coordinatesX1 = {};
    this.idPointsHash = {};
    for (let i = 0; i < data.id_point.length; i++) {
      const date = data.id_point[i];
      this.coordinatesX1[date] = this.getCoordinate(i);
      this.idPointsHash[date] = i;
    }
    this.minDate = Math.min(...data.id_point);
    this.maxDate = Math.max(...data.id_point);
    this.makeNextDates(data, interval);
  }

  addAllEvents(data: TData, events: any[], interval: TIGER_INTERVAL_ENUM) {
    if (!data || !data.id_point) {
      return;
    }
    const xAxisId = `xAxis_${this.ref}`;
    const visibleRange = this.visibleRange(xAxisId);
    const r = randomId('EVENTR');
    const templateSize = 22;
    const groupLimit = 60;
    this.worker.postMessage({
      dicts: [
        this.corporateEventsDict,
        this.relevantEventsDict,
        this.newsChartEventsDict,
        this.tradeIdeaEventsDict,
      ],
      coordinatesX1: this.coordinatesX1,
      events,
      visibleRange: { min: visibleRange.min, max: visibleRange.max },
      minDate: this.minDate,
      maxDate: this.maxDate,
      xAxisId,
      r,
      templateSize,
      groupLimit,
      idPointsHash: this.idPointsHash,
      showEvents: this.showEvents,
      annotationEventsDict: this.annotationEventsDict,
      interval,
    });
  }

  removeCorporateEvents() {
    this.baseChart &&
      this.baseChart.sciChartSurface.annotations
        .asArray()
        .filter((annotation) => annotation.id.includes(CORPORATE_EVENTS))
        .forEach((annotation) =>
          this.baseChart.sciChartSurface.annotations.remove(annotation)
        );
  }

  removeRelevantEvents() {
    this.baseChart &&
      this.baseChart.sciChartSurface.annotations
        .asArray()
        .filter((annotation) => annotation.id.includes(RELEVANT_EVENTS))
        .forEach((annotation) =>
          this.baseChart.sciChartSurface.annotations.remove(annotation)
        );
  }

  removeAllEvents(eventsToRemove: any[]) {
    if (this.baseChart) {
      eventsToRemove.forEach((key) => {
        const annotation =
          this.baseChart.sciChartSurface.annotations.getById(key);
        this.baseChart.sciChartSurface.annotations.remove(annotation);
      });
    }
  }

  private getAllEvents() {
    return (
      this.baseChart &&
      this.baseChart.sciChartSurface.annotations
        .asArray()
        .filter((annotation) => annotation.id.includes(EVENTS))
    );
  }

  mergeEvents(type: string, newEvents: any[]): any[] {
    const hashUnique: any = {
      corporateEvents: 'description',
      relevantEvents: 'idCvmFile',
      newsChartEvents: 'idNews',
      tradeIdeaEvents: 'id_trade_idea',
    };
    const hashDate: any = {
      corporateEvents: 'dtEvent',
      relevantEvents: 'dtDocument',
      newsChartEvents: 'dtEvent',
      tradeIdeaEvents: 'id_point',
    };
    const unique = hashUnique[type];
    const date = hashDate[type];
    const previousEvents = this.events[type as keyof TEvents] as any[];
    const hashPreviousEvents = previousEvents.reduce(
      (a: any, v: any) => ({ ...a, [v[unique]]: v }),
      {}
    );
    const hashNewEvents = newEvents.reduce(
      (a, v) => ({ ...a, [v[unique]]: v }),
      {}
    );
    const hash: any = { ...hashPreviousEvents, ...hashNewEvents };
    const keys = Object.keys(hash);
    const news: any[] = [];
    const values = keys.map(function (v) {
      if (!(v in hashPreviousEvents)) {
        news.push(hash[v]);
      }
      return hash[v];
    });
    values.sort((a, b) => ascendingSort(a[date], b[date]));
    this.events[type as keyof TEvents] = values;
    return news;
  }

  openEventsModal(
    mouseEvent: MouseEvent,
    annotation: ClickableAnnotation,
    tooltip: string | string[]
  ) {
    const key = annotation.id;
    if (
      (!this.relevantEventsDict[key] &&
        !this.tradeIdeaEventsDict[key] &&
        !this.newsChartEventsDict[key]) ||
      this.corporateEventsDict[key]
    ) {
      return;
    }
    this.clickAnnotationEvents$.next(true);
    const events =
      this.relevantEventsDict[key]?.events ||
      this.tradeIdeaEventsDict[key]?.events ||
      this.newsChartEventsDict[key]?.events;
    if (events && events.length > 1) {
      const top =
        mouseEvent.view!.innerHeight < mouseEvent.pageY + 252
          ? mouseEvent.offsetY - 252
          : mouseEvent.offsetY;
      const left =
        mouseEvent.view!.innerWidth < mouseEvent.pageX + 200
          ? mouseEvent.offsetX - 200
          : mouseEvent.offsetX;
      const ref = this.eventsContextMenuService.show({
        left,
        top,
        events,
        ref: `${this.ref}_main-tiger-chart`,
        isRelevant: !!this.relevantEventsDict[key],
        isTradeIdea: !!this.tradeIdeaEventsDict[key],
      });
      const unsub = ref.onClose.subscribe((res) => {
        if (!res || !res.event) return;
        if (this.relevantEventsDict[key]) {
          this.openEventsContextMenu(key, res.event);
        }
        if (this.tradeIdeaEventsDict[key]) {
          const side = res.event.side === 'B' ? 'Compra' : 'Venda';
          const first = div(
            `${formatMsDateToHumanDate(res.event.id_point)} - ${side}`,
            'fs-7 mb-3'
          );
          const second = span(res.event.description);
          const fullText = `${first}\n${second}`;
          this.dispatchExpandEvent(key, annotation, fullText, res.event);
        }
        this.clickAnnotationEvents$.next(false);
        unsub.unsubscribe();
      });
    } else if (events) {
      if (this.relevantEventsDict[key]) {
        this.openEventsContextMenu(key, events[0]);
        this._hideAnnotationTooltip();
      }
      if (this.tradeIdeaEventsDict[key]) {
        const fullText = `${div(tooltip[0], 'fs-7 mb-3')}\n${tooltip[1]}`;
        this.dispatchExpandEvent(key, annotation, fullText, events[0]);
        this._hideAnnotationTooltip();
      }
      if (this.newsChartEventsDict[key]) {
        this.dispatchExpandEvent(key, annotation, tooltip, events[0]);
      }
    }
  }

  private openEventsContextMenu(key: string, event: TRelevantEvents) {
    const ref = this.modalService.open(DocumentsModalComponent, {
      data: {
        ...this.relevantEventsDict[key],
        event: event,
      },
      centered: true,
      backdrop: true,
      keyboard: false,
      scrollable: false,
    });
    ref.afterDismissed.subscribe(() => {
      this.clickAnnotationEvents$.next(false);
    });
  }

  displayEvents(showEvents: boolean) {
    const events = this.getAllEvents();
    this.showEvents = showEvents;
    if (!this.showEvents) {
      events.forEach((event) => {
        this.baseChart.sciChartSurface.annotations.remove(event);
      });
    }
  }

  private makeAnnotationsDict = (firstKey: string, secondKey: string) => {
    if (!(firstKey in this.annotationEventsDict)) {
      this.annotationEventsDict[firstKey] = {
        qttyEvents: {},
        qtty: 0,
      };
    }
    if (!(secondKey in this.annotationEventsDict[firstKey].qttyEvents)) {
      this.annotationEventsDict[firstKey].qttyEvents[secondKey] = 0;
      this.annotationEventsDict[firstKey].qtty++;
    }
    this.annotationEventsDict[firstKey].qttyEvents[secondKey]++;
  };

  private getAnnotation = (
    id: string,
    color: string,
    letter: string,
    xAxisId: string,
    tooltipLabel: string[],
    x1: number,
    y1: number
  ): ClickableAnnotation => {
    const onlyL = getTextByRegex(letter, /[a-zA-Z]+/g);
    const isNews = onlyL === 'N';
    let tooltip = deepClone(tooltipLabel);
    let tooltipMoreNew = '';
    if (isNews && tooltipLabel.length > 1) {
      tooltip = [tooltipLabel[0]];
      for (let i = 1; i < tooltipLabel.length; i++) {
        const moreNews = tooltipLabel[i].split('|&|');
        if (moreNews) {
          tooltip.push(deepClone(moreNews[0]));
          tooltipMoreNew = moreNews[1] ? moreNews[1] : '';
        }
      }
    }
    let tooltipLabelIndicator = deepClone(tooltip);
    if (isNews && tooltip.length > 1) {
      let text = `${deepClone([tooltip[0]])} <br/> `;
      for (let j = 1; j < tooltip.length; j++) {
        const space = j != tooltip.length - 1 ? ' <br/> ' : '';
        text += `${deepClone(tooltip[j])}${space}`;
      }
      tooltipLabelIndicator = [text];
    }
    const hashTypes: any = {
      N: 'THUMBS',
    };
    const fontSize = 13;
    const annotation = new ClickableAnnotation({
      id,
      x1,
      y1,
      verticalAnchorPoint: EVerticalAnchorPoint.Center,
      horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
      yCoordinateMode: ECoordinateMode.Relative,
      xCoordinateMode: ECoordinateMode.DataValue,
      svgString: getTemplateEvent(color, 'black', letter, fontSize),
      xAxisId,
      isHidden: !this.showEvents,
      onClick: (args) => {
        annotation.isSelected = false;
        const annotationTooltip = isNews ? tooltipMoreNew : tooltip;
        this.openEventsModal(
          args.mouseArgs.nativeEvent,
          annotation,
          annotationTooltip
        );
      },
      customHover: this._createAnnotationHoverFunction({
        type: TIGER_LINE_TYPE.ANNOTATION_EVENT,
        value: 0,
        onTop: true,
        onCenter: true,
        tooltipLabel: tooltipLabelIndicator,
        boxId: '',
        color: '',
        tooltipButtons: hashTypes[onlyL],
      }),
    });
    return annotation;
  };

  updateAllEvents(data: TData, interval: TIGER_INTERVAL_ENUM) {
    this.addAllEvents(data, this.allEvents, interval);
  }

  clearToUpdate() {
    this.annotationEventsDict = {};
    this.eventDraw.clear();
  }

  private getKeys = (dtEvent: number, dsCorporativeEventType: string) => {
    const firstKey = dtEvent.toString(),
      secondKey = dsCorporativeEventType;
    return { firstKey, secondKey };
  };

  private nextBusinessDay = (date: number, days: number): number => {
    const time = nextDay(date, days);
    const x1 = this.idPointsHash[time];
    if (!x1) {
      return this.nextBusinessDay(time, days);
    }
    return time;
  };

  private visibleRange(xAxisId: string): NumberRange {
    const xAxis = this.xAxis(xAxisId);
    return xAxis.visibleRange;
  }

  private xAxis = (xAxisId: string): AxisBase2D => {
    return this.baseChart.sciChartSurface.xAxes.getById(xAxisId);
  };

  private getCoordinate = (i: number) => {
    const xAxisId = `xAxis_${this.ref}`;
    const xAxis = this.xAxis(xAxisId);
    const xAxisCalc = xAxis.getCurrentCoordinateCalculator();
    return xAxisCalc.getCoordinate(i);
  };

  private transformDataToIndex(xCategoryValue: number) {
    const xAxisId = `xAxis_${this.ref}`;
    const xAxis = this.xAxis(xAxisId);
    const calculator =
      xAxis.getCurrentCoordinateCalculator() as CategoryCoordinateCalculator;
    return calculator.transformDataToIndex(xCategoryValue);
  }

  private createWorker(): void {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(
        new URL('../../worker/events.worker', import.meta.url)
      );
      this.worker.onmessage = this.resultProcess;
    } else {
      alert('Atualize o navegador ou instale um mais moderno como o Chrome!');
    }
  }

  private resultProcess = ({ data }: any): void => {
    const { eventsDict, annotationEventsDict } = data;
    this.corporateEventsDict = data.dicts[0];
    this.relevantEventsDict = data.dicts[1];
    this.newsChartEventsDict = data.dicts[2];
    this.tradeIdeaEventsDict = data.dicts[3];
    this.annotationEventsDict = {
      ...this.annotationEventsDict,
      ...annotationEventsDict,
    };
    this.eventsAdded$.next(data.type);
    const eventsToRemove = [...deepClone(this.eventsOnChart[data.type])];
    Object.keys(eventsDict).forEach((key) => {
      const event = eventsDict[key];
      this.eventsOnChart[data.type].push(event.id);
      const annotation = this.getAnnotation(
        event.id,
        event.color,
        event.letter,
        event.xAxisId,
        event.tooltipLabel,
        event.x1,
        event.y1
      );
      this.baseChart.sciChartSurface.annotations.add(annotation);
    });
    this.removeAllEvents(eventsToRemove);
  };

  updateCoordinates = (data: TData, interval: TIGER_INTERVAL_ENUM) => {
    data.id_point.forEach((date, i) => {
      this.coordinatesX1[date] = this.getCoordinate(i);
    });
    this.makeNextDates(data, interval);
  };

  private makeNextDates(data: TData, interval: TIGER_INTERVAL_ENUM) {
    this.ngZone.runOutsideAngular(() => {
      const xAxisId = `xAxis_${this.ref}`;
      const hashs = INTERVAL_HASH;
      const func = hashs[interval];
      let lastDate = data.id_point[data.id_point.length - 1];
      const x = this.baseChart.sciChartSurface.xAxes.getById(xAxisId);
      const categoryCalculator =
        x.getCurrentCoordinateCalculator() as CategoryCoordinateCalculator;
      const lastDateVisible = Math.round(
        categoryCalculator.transformIndexToData(x.visibleRange.max)
      );
      if (func) {
        while (lastDate <= lastDateVisible) {
          lastDate = lastDate + func.timer;
          this.coordinatesX1[lastDate] = this.transformDataToIndex(lastDate);
          this.idPointsHash[lastDate] = this.coordinatesX1[lastDate];
        }
      }
      this.maxDate = lastDate;
    });
  }

  terminateWorker() {
    !!this.worker && this.worker.terminate();
  }

  private dispatchExpandEvent(
    key: string,
    annotation: ClickableAnnotation,
    tooltip: string | string[],
    event: any
  ) {
    const isExpand =
      !!this.newsChartEventsDict[key] || !!this.tradeIdeaEventsDict[key];
    const tooltipKeyButton = this.newsChartEventsDict[key]
      ? 'NEWS'
      : this.tradeIdeaEventsDict[key]
      ? 'TRADE_IDEA'
      : '';
    const tooltipButtons: any = {
      NEWS: {
        cod: TypeButtonEnum.THUMBS,
        idNews: isExpand && event ? event : undefined,
      },
      TRADE_IDEA: {
        cod: TypeButtonEnum.BUTTON,
        tradeIdea: isExpand && event ? event : undefined,
        isTradeIdea: true,
        buttonLabel: 'Executar',
        hasQtty: true,
      },
    };
    this.expandEvent$.next({
      xAxisId: annotation.xAxisId,
      tooltipButtons: tooltipButtons[tooltipKeyButton],
      annotation,
      tooltip: isExpand ? tooltip : [],
      isExpand,
      isTradeIdea: !!this.tradeIdeaEventsDict[key],
    });
  }
}
