import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import {
  STOCK_STATUS_BACKGROUND,
  STOCK_STATUS_LABEL,
} from '@shared/components/stock-table/constants/stock-table.constants';

@Component({
  selector: 'app-status-stock',
  template: `
    <ng-container *ngIf="currentStatus">
      <div
        tooltipPosition="right"
        class="w-100 h-100 d-inline-flex align-items-center justify-content-center"
        [tooltip]="tooltip"
        [tooltipHidden]="isAuction && hideSituationTooltip"
        [ngClass]="{ 'move-top': moveTop }"
        [appAuctionTooltip]="auctionInfos"
        [situation]="currentStatus"
      >
        <ng-container *ngIf="!isAuction; else auctionStatus">
          <div class="stock-status" [ngClass]="statusColor"></div>
        </ng-container>
        <ng-template #auctionStatus>
          <fla-icon
            name="gavel"
            size="icon-size-nano"
            css="text-feedback-warning"
          ></fla-icon>
        </ng-template>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .stock-status {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        line-height: 8px;
        background-color: var(--vm-neutral-medium);
      }

      .move-top {
        position: relative;
        top: -2px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusStockComponent {
  @Input() isAuction: boolean = false;
  @Input() moveTop: boolean = false;
  @Input() hideSituationTooltip: boolean = true;
  @Input() auctionInfos!: any;
  @Input() set status(status: string) {
    if (status) {
      this.statusColor = STOCK_STATUS_BACKGROUND[status];
      this.tooltip = STOCK_STATUS_LABEL[status];
      this.currentStatus = status;
      this._cdr.detectChanges();
      return;
    }
    this.tooltip = '';
    this.statusColor = 'var(--vm-neutral-medium)';
    this.currentStatus = '';
    this._cdr.detectChanges();
  }

  constructor(private _cdr: ChangeDetectorRef) {}

  public tooltip: string = '';
  public currentStatus!: string;
  public statusColor: string = 'var(--vm-neutral-medium)';
}
