import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlowInvestorsComponent } from './flow-investors.component';
import { FlowHeaderComponent } from './flow-header/flow-header.component';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { FlowCardsComponent } from './parts/flow-cards/flow-cards.component';
import {
  FlaIconModule,
  FlaSelectModule,
  RocketButtonModule,
} from '@shared/rocket-components/components';
import { AcumuladoB3Component } from './parts/acumulado-b3/acumulado-b3.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartEvolutionComponent } from './parts/chart-evolution/chart-evolution.component';
import { IpoVolumeComponent } from './parts/ipo-volume/ipo-volume.component';
import { IposCardsComponent } from './parts/ipos-cards/ipos-cards.component';
import { FlowInvestorsService } from './flow-investors.service';
import { FakeTooltipScichartModule } from '../fake-tooltip-scichart/fake-tooltip-scichart.module';
import { FlowInvestorsTabComponent } from './tabs/flow-investors-tab/flow-investors-tab.component';
import { VolumeIpoTabComponent } from './tabs/volume-ipo-tab/volume-ipo-tab.component';
import { FlowB3TabComponent } from './tabs/flow-b3-tab/flow-b3-tab.component';
import { ChartForeignersComponent } from './parts/chart-foreigners/chart-foreigners.component';
import { ForeignersChartTooltipComponent } from './parts/foreigners-chart-tooltip/foreigners-chart-tooltip.component';
import { StockChartService } from '../stock-chart/service/stock-chart.service';

@NgModule({
  imports: [
    CommonModule,
    FlaWorkSpaceModule,
    FlaIconModule,
    FlaSelectModule,
    FormsModule,
    ReactiveFormsModule,
    RocketButtonModule,
    FakeTooltipScichartModule,
    ForeignersChartTooltipComponent,
  ],
  declarations: [
    FlowInvestorsComponent,
    FlowHeaderComponent,
    FlowCardsComponent,
    AcumuladoB3Component,
    ChartEvolutionComponent,
    IpoVolumeComponent,
    IposCardsComponent,
    FlowInvestorsTabComponent,
    VolumeIpoTabComponent,
    FlowB3TabComponent,
    ChartForeignersComponent,
  ],
  exports: [FlowInvestorsComponent],
  providers: [FlowInvestorsService, StockChartService],
})
export class FlowInvestorsModule {}
