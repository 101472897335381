import {
  Component,
  Input,
  EventEmitter,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { randomId } from '../../../utils';
import { FlaRadioService } from '../service/radio.service';

@Component({
  selector: 'fla-radio-button',
  templateUrl: './radio-button.component.html',
})
export class FlaRaioButtonComponent {
  @Input('css') customClass: string = '';
  @Input() label!: string;
  @Input() name!: string;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() isSelection: boolean = false;
  @Input() isSmallSelection: boolean = false;
  @Input() isTag: boolean = false;
  @Input() isInline: boolean = false;
  @Input() showLabel: boolean = true;
  @Input('isChecked') checked: boolean = false;
  @Input() value: any;
  @Output() flaChanges = new EventEmitter<any>();
  @ViewChild(TemplateRef) content!: TemplateRef<any>;
  public id: string = randomId('fla_radio_button');
  constructor(private flaRadioService: FlaRadioService) {}

  public changes(value: any): void {
    const id = document.querySelector<HTMLInputElement>(
      `input[data-bs-ref="${this.id}"]`
    )!.value;
    this.flaRadioService.valuesChanges$.next({ id, value });
    this.flaChanges.emit(value);
  }
}
