import { HEADER_CODE } from './trade-idea-header.enum';
import { IHeader } from './trade-idea-header.interface';

export const HEADERS: IHeader[] = [
  {
    label: 'Ativas',
    code: HEADER_CODE.ACTIVE,
  },
  {
    label: 'Histórico',
    code: HEADER_CODE.HISTORIC,
  },
];

export const MY: IHeader = {
  label: 'Minhas',
  code: HEADER_CODE.MY,
};
