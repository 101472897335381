import { Injectable } from '@angular/core';
import {
  IWorkSpace,
  IWorkerWorkspaceActionReturn,
  IWorkSpaceComponet,
  WORKSPACE_WORKER_ACTION,
} from '@core/interface/workspace.interfaces';
import { RxEvent } from '@shared/channel/rx-event';
import { INFINITY_READ_STREAM } from '@shared/constants/general.contant';
import { CHANGE_WORKSPACE } from '@shared/services/core/subscription/const/events-name';
import { Observable, Subject, debounceTime } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceComponentService {
  private workspaces: IWorkSpace[] = [];
  private visibleWorkspaces: IWorkSpace[] = [];
  private activeWorkspace!: IWorkSpace;
  private dispatchWorkspaceUpdate$ = new Subject<void>();
  private dispatchVisiblesWorkspaces$ = new Subject<IWorkSpace[]>();
  private dispatchChangeActiveWorkspace$ = new Subject<IWorkSpace>();
  private initiated = false;
  private componentOpened$ = new Subject<string>();
  private componentRemoved$ = new Subject<string>();

  constructor(private rxEvent: RxEvent) {
    this._workspaceWorkerListener();
    this.dispatchWorkspaceUpdate$
      .asObservable()
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.dispatchVisiblesWorkspaces$.next(this.getVisiblesWorkspaces());
      });
  }

  onComponentOpened(): Observable<string> {
    return this.componentOpened$.asObservable();
  }

  onComponentRemoved(): Observable<string> {
    return this.componentRemoved$.asObservable();
  }

  public dispatchWorkspaceUpdate(): void {
    this.dispatchWorkspaceUpdate$.next();
  }

  public dispatchVisiblesWorkspaces(): Observable<IWorkSpace<any>[]> {
    return this.dispatchVisiblesWorkspaces$.asObservable();
  }

  public dispatchActiveWorkspace(): Observable<IWorkSpace<any>> {
    return this.dispatchChangeActiveWorkspace$.asObservable();
  }

  public dispatchActiveWorkspaceUpdate(): void {
    this.dispatchChangeActiveWorkspace$.next(this.activeWorkspace);
  }

  public getWorkspaces(): IWorkSpace[] {
    return this.workspaces;
  }

  public getVisiblesWorkspaces(): IWorkSpace[] {
    return this.visibleWorkspaces;
  }

  public getActiveWorkspace(): IWorkSpace {
    return this.activeWorkspace;
  }

  public getWorkspaceById(id: string): IWorkSpace {
    return this.workspaces.find((workspace: IWorkSpace) => workspace.id == id)!;
  }

  public setWorkspaces(workspaces: IWorkSpace[]): void {
    this._emitWorkerAction(WORKSPACE_WORKER_ACTION.SET_WORKSPACES, {
      workspaces,
    });
  }

  public createdOrUpdateWorkspace(
    workspace: IWorkSpace,
    isActive: boolean = true,
    preventUpdate: boolean = false
  ): void {
    this._emitWorkerAction(
      WORKSPACE_WORKER_ACTION.CREATED_OR_UPDATE_WORKSPACE,
      {
        workspace,
        isActive,
        preventUpdate,
      }
    );
  }

  public changeActiveWorkspace(workspace: IWorkSpace): void {
    // se o workspace for o mesmo que ja esta ativo, não faz nada
    if (workspace.id === this.activeWorkspace?.id) {
      return;
    }

    this._emitWorkerAction(WORKSPACE_WORKER_ACTION.CHANGE_ACTIVE_WORKSPACE, {
      workspace,
    });
  }

  private _emitWorkerAction(
    action: WORKSPACE_WORKER_ACTION,
    workspacesData: any
  ) {
    if (!this.initiated) {
      setTimeout(() => {
        this._emitWorkerAction(action, workspacesData);
      }, 100);
      return;
    }
    this.rxEvent.emitCommand('workspaceCommand', CHANGE_WORKSPACE, {
      channel: CHANGE_WORKSPACE,
      action,
      workspacesData,
    });
  }

  private _workspaceWorkerListener() {
    this.rxEvent.read(CHANGE_WORKSPACE).then(async (data) => {
      this.initiated = true;
      const reader = data.stream.getReader();
      let ret;

      do {
        ret = await reader.read();
        if (!ret.done) {
          this._processWorkerResponse(ret.value.data);
        }
      } while (INFINITY_READ_STREAM);
    });
  }

  private _processWorkerResponse(data: IWorkerWorkspaceActionReturn) {
    const {
      workspaces,
      visibleWorkspaces,
      activeWorkspace,
      componentAdded,
      componentRemovedIndex,
      isComponentUpdate,
      preventUpdate,
      preventUpdateActive,
    } = data;
    if (workspaces && componentAdded) {
      !this.activeWorkspace.components &&
        (this.activeWorkspace.components = []);
      this.activeWorkspace.components.push(componentAdded);
      this.workspaces = workspaces;
      this.dispatchChangeActiveWorkspace$.next(this.activeWorkspace);
      this.componentOpened$.next(componentAdded.id);
      return;
    }

    if (
      workspaces &&
      activeWorkspace &&
      componentRemovedIndex &&
      componentRemovedIndex > -1
    ) {
      !this.activeWorkspace.components &&
        (this.activeWorkspace.components = []);
      const id = this.activeWorkspace.components[componentRemovedIndex].id;
      this.componentRemoved$.next(id);
      this.activeWorkspace.components.splice(componentRemovedIndex, 1);
      this.workspaces = workspaces;
      this.dispatchChangeActiveWorkspace$.next(this.activeWorkspace);
      return;
    }

    visibleWorkspaces && (this.visibleWorkspaces = visibleWorkspaces);

    if (isComponentUpdate) return;

    if (workspaces) {
      this.workspaces = workspaces;
      !preventUpdate && this.dispatchWorkspaceUpdate$.next();
    }
    if (activeWorkspace) {
      this.activeWorkspace = activeWorkspace;
      !preventUpdate &&
        !preventUpdateActive &&
        this.dispatchChangeActiveWorkspace$.next(this.activeWorkspace);
    }
  }

  public removeWorkspace(
    workspace: IWorkSpace,
    preventUpdateActive?: boolean
  ): void {
    this._emitWorkerAction(WORKSPACE_WORKER_ACTION.REMOVE_WORKSPACE, {
      workspace,
      preventUpdateActive,
    });
  }

  public hideActiveWorkspace(): void {
    this._emitWorkerAction(WORKSPACE_WORKER_ACTION.HIDE_ACTIVE_WORKSPACE, {
      activeWorkspace: this.activeWorkspace,
      workspaces: this.workspaces,
    });
  }

  // ************************** COMPONENTS *****************************************************************************
  public getComponents(): IWorkSpaceComponet[] {
    return this.activeWorkspace?.components ?? [];
  }

  public createComponent(component: IWorkSpaceComponet): void {
    this._emitWorkerAction(WORKSPACE_WORKER_ACTION.CREATE_COMPONENT, {
      component,
    });
  }

  public updateComponent(component: IWorkSpaceComponet): void {
    // todo: set ativa workspace quando vindo da rota de unico componente
    this._emitWorkerAction(WORKSPACE_WORKER_ACTION.UPDATE_COMPONENT, {
      component,
    });
  }

  public getComponentById(id: string): IWorkSpaceComponet {
    const components = this.activeWorkspace?.components;
    return components.find((comp: IWorkSpaceComponet) => comp.id === id)!;
  }

  public removeComponent(idComponent: string): void {
    this._emitWorkerAction(WORKSPACE_WORKER_ACTION.REMOVE_COMPONENT, {
      idComponent,
    });
  }
}
