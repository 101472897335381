import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { TRelevantEvents } from '@shared/tiger-chart/types/tiger-chart.types';
import { Observable, Subject } from 'rxjs';
import { formatMsDateToHumanDate } from 'src/app/utils/utils-events-worker.functions';

@Component({
  selector: 'app-events-context-menu',
  templateUrl: './events-context-menu.component.html',
  styleUrls: ['./events-context-menu.component.scss'],
})
export class EventsContextMenuComponent implements OnInit, OnDestroy {
  top!: number;
  left!: number;
  refComponent!: string;
  isVisible!: boolean;
  closeClickOutside: boolean = true;
  isRelevant: boolean = false;
  isTradeIdea: boolean = false;
  events: any[] = [];
  countClick = 0;
  eventsToShow: any[] = [];
  eventHeader = 'Eventos';
  get onClose(): Observable<any | undefined> {
    return this._onClose$.asObservable();
  }
  private _onClose$ = new Subject<any | undefined>();
  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}
  ngOnInit(): void {
    this.countClick = 0;
    this._clickOutside();
    this.formatEvents();
  }

  ngOnDestroy(): void {
    this._onClose$.complete();
  }

  reset(): void {
    this._onClose$.next(undefined);
  }

  onClick(value: TRelevantEvents) {
    this._onClose$.next(value);
  }

  private _clickOutside(): void {
    if (this.closeClickOutside) {
      this.listener();
    }
  }

  private listener() {
    this._renderer.listen('window', 'click', (event: any) => {
      if (!this._elementRef.nativeElement.contains(event.target)) {
        if (this.isVisible) {
          /**
           * Quando o clique vem pelo icone de "mais" do gráfico
           * é disparado o evento para abrir o context menu
           * e por essa razao, ele fica ouvindo o evento click,
           * que também ocorre ao mesmo tempo no clique do mais,
           * para contornar isso, adicionei um contador,
           * para só começar a usar o click do mouse no caso do mais
           * na segunda vez.
           */
          if (this.countClick === 0) {
            this.countClick++;
            return;
          }
          this.isVisible = false;
          this._onClose$.next(undefined);
        }
      }
    });
  }

  private formatEvents() {
    this.events.forEach((event) => {
      if (this.isRelevant) {
        this.eventHeader = 'Eventos';
        const formattedDate = formatMsDateToHumanDate(event.dtDocument, false);
        this.eventsToShow.push({
          title: `Fato Relevante - ${formattedDate}`,
          description: event.dsDocumentDescription,
          event,
        });
      }
      if (this.isTradeIdea) {
        this.eventHeader = 'TradeIdeas';
        const formattedDate = formatMsDateToHumanDate(event.id_point);
        const side = event.side === 'B' ? 'Compra' : 'Venda';
        this.eventsToShow.push({
          title: `${formattedDate} - ${side}`,
          description: event.description,
          event,
        });
      }
      if (!this.isRelevant && !this.isTradeIdea) {
        this.eventHeader = 'Eventos';
        this.eventsToShow.push({
          title: event.headline,
          description: event.body.replaceAll(
            /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g,
            ''
          ),
          tooltip: event.body,
          event,
        });
      }
    });
  }
}
