import {
  TIGER_INDICATORS_ENUM,
  TIGER_TYPE_CHART_ENUM,
} from '@shared/tiger-chart/enum/tiger-chart.enum';
import { Dictionary } from 'src/app/core/models';
import {
  INDICATORS,
  INTERVAL_CHART,
  TYPE_CHART,
} from '../constants/stock-chart.constant';
import { IInterval } from '../interface/stock-chart.interface';

export class ChartBase {
  intervalDic = new Dictionary<IInterval>();
  typeDic = new Dictionary<{
    id: TIGER_TYPE_CHART_ENUM;
    name: string;
    icon: string;
  }>();

  indicatorsDic = new Dictionary<{
    id: TIGER_INDICATORS_ENUM;
    name: string;
    checked: boolean;
    qtty?: number;
  }>();

  constructor() {
    INTERVAL_CHART.forEach((i) => this.intervalDic.set(i.id, i));
    TYPE_CHART.forEach((i) => this.typeDic.set(i.id, i));
    INDICATORS.forEach((i) => {
      if (!i.id) return;
      this.indicatorsDic.set(i.id, { ...i });
    });
  }
}
