import { isNullOrUndefined } from "src/app/utils/utils.functions";
import { CHART_LINES_CONFIGURATION, CHART_LINE_FIELD_CONFIG } from "../modal-more-options/interface/stock-chart-modal.interface";

export class ChartLinesConfiguration {
  alerts: CHART_LINE_FIELD_CONFIG = {
    enabled: true, rightSide: false
  }
  custody: CHART_LINE_FIELD_CONFIG = {
    rightSide: false
  }
  orders: CHART_LINE_FIELD_CONFIG = {
    rightSide: false
  }
  breakEven: CHART_LINE_FIELD_CONFIG = {
    enabled: false, rightSide: false
  }
  lastPrice: CHART_LINE_FIELD_CONFIG = {
    enabled: true
  }
  showPreviousClose: CHART_LINE_FIELD_CONFIG = {
    enabled: false
  }
  constructor(values?: CHART_LINES_CONFIGURATION) {
    if (values) {
      this.setGlobals(values, 'alerts');
      this.setGlobals(values, 'custody');
      this.setGlobals(values, 'orders');
      this.setGlobals(values, 'breakEven');
      this.setGlobals(values, 'lastPrice');
      this.setGlobals(values, 'showPreviousClose');
    }
  }

  private setGlobals(values: CHART_LINES_CONFIGURATION, field: string) {
    const anyThis = this as any;
    const anyValues = values as any;
    const value = anyValues[field];
    if (!isNullOrUndefined(value)) {
      anyThis[field] = value;
    }
  }
}
