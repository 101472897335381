/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { hex2rgba } from '@shared/rocket-components/utils';
import {
  EFillPaletteMode,
  EStrokePaletteMode,
  IFillPaletteProvider,
  IStrokePaletteProvider,
} from 'scichart/Charting/Model/IPaletteProvider';
import { OhlcDataSeries } from 'scichart/Charting/Model/OhlcDataSeries';
import { IRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/IRenderableSeries';
import { parseColorToUIntArgb } from 'scichart/utils/parseColor';
import { ICandleTheme } from './interface/tiger-chart.interface';
import { StockChartModalService } from '@shared/components/stock-chart/parts/modal-more-options/service/stock-chart-modal.service';
import { ColorsBaseService } from './tiger-chart-tools/toolbar-draw/colors-base.service';
import { DefaultPaletteProvider } from 'scichart/Charting/Model/IPaletteProvider';
import { COLORS_BROKER } from '@shared/components/ranking-broker/consts/colors';

export const CHART_COLORS = {
  NEUTRAL_SMOOTHEST: '#ffffff',
  NEUTRAL_SMOOTHER: '#e7eaee',
  NEUTRAL_SMOOTH: '#caced4',
  NEUTRAL_MEDIUM: '#8f8f9a',
  NEUTRAL_STRONG: '#353546',
  NEUTRAL_STRONGER: '#23242f',
  NEUTRAL_STRONGEST: '#13141b',
  BRAND_PRIMARY: '#1DA1F2',
  BRAND_SUPPORT_PRIMARY: '#0056FF',
  BRAND_SUPPORT_SECONDARY: '#f96500',
  FEEDBACK_SUCCESS: '#0fef83',
  FEEDBACK_POSITIVE: '#86FFC5',
  FEEDBACK_WARNING: '#f0960f',
  FEEDBACK_NEGATIVE: '#e66f87',
  FEEDBACK_ERROR: '#d30d48',
  MULTIBROKER_BUY: '#5999f8',
  MULTIBROKER_SELL: '#fbbb00',
  PAPER_MENU_CLICK_FILL: '#FF191955',
  PAPER_MENU_CLICK_STROKE: '#FF1919',
  BLACK: '#000000',
  OPACITY_BACKGROUND: '#FFFFFF00',
  RESET_POSITION: '#FF7D25',
};

export const CHART_COLORS_LIGHT = {
  NEUTRAL_SMOOTHEST: '#13141b',
  NEUTRAL_SMOOTHER: '#23242f',
  NEUTRAL_SMOOTH: '#353546',
  NEUTRAL_MEDIUM: '#8f8f9a',
  NEUTRAL_STRONG: '#E4E7EC',
  NEUTRAL_STRONGER: '#F2F4F8',
  NEUTRAL_STRONGEST: '#ffffff',
  BRAND_PRIMARY: '#1DA1F2',
  BRAND_SUPPORT_PRIMARY: '#0056FF',
  BRAND_SUPPORT_SECONDARY: '#f96500',
  FEEDBACK_SUCCESS: '#0fef83',
  FEEDBACK_POSITIVE: '#86FFC5',
  FEEDBACK_WARNING: '#f0960f',
  FEEDBACK_NEGATIVE: '#e66f87',
  FEEDBACK_ERROR: '#d30d48',
  MULTIBROKER_BUY: '#5999f8',
  MULTIBROKER_SELL: '#fbbb00',
  PAPER_MENU_CLICK_FILL: '#FF191955',
  PAPER_MENU_CLICK_STROKE: '#FF1919',
  BLACK: '#000000',
  OPACITY_BACKGROUND: '#FFFFFF00',
  RESET_POSITION: '#FF7D25',
};

export const DEFAULT_THEME = (): ICandleTheme => {
  return {
    annotationsGripsBackroundBrush: 'white',
    annotationsGripsBorderBrush: 'white',
    axis3DBandsFill: 'red',
    axisPlaneBackgroundFill: 'red',
    cursorLineBrush: 'red',
    defaultColorMapBrush: [
      { offset: 0, color: 'red' },
      { offset: 0.5, color: 'red' },
      { offset: 1, color: 'red' },
    ],
    downBandSeriesFillColor: 'red',
    downBandSeriesLineColor: 'red',
    gridBackgroundBrush: 'white',
    gridBorderBrush: 'red',
    labelBackgroundBrush: 'red',
    labelBorderBrush: 'red',

    loadingAnimationBackground: 'Transparent',
    mountainAreaBrush: 'white',
    mountainLineColor: 'red',
    overviewFillBrush: 'red',
    planeBorderColor: 'red',
    rolloverLineBrush: 'red',
    rubberBandFillBrush: 'red',
    rubberBandStrokeBrush: 'red',
    sciChartBackground: 'Transparent',
    scrollbarBackgroundBrush: 'white',
    scrollbarBorderBrush: 'white',
    scrollbarGripsBackgroundBrush: 'white',
    scrollbarViewportBackgroundBrush: 'white',
    scrollbarViewportBorderBrush: 'white',
    shadowEffectColor: 'white',
    textAnnotationBackground: 'red',
    textAnnotationForeground: 'red',
    upBandSeriesFillColor: 'white',
    upBandSeriesLineColor: 'white',

    // OmniTheme
    labelForegroundBrush: CHART_COLORS.NEUTRAL_SMOOTHEST,
    legendBackgroundBrush: CHART_COLORS.NEUTRAL_STRONGER,
    axisTitleColor: CHART_COLORS.NEUTRAL_SMOOTHEST,
    loadingAnimationForeground: CHART_COLORS.BRAND_PRIMARY,
    tickTextBrush: CHART_COLORS.NEUTRAL_SMOOTH,
    upBodyBrush: CHART_COLORS.FEEDBACK_POSITIVE,
    upWickColor: CHART_COLORS.FEEDBACK_POSITIVE,
    downBodyBrush: CHART_COLORS.FEEDBACK_NEGATIVE,
    downWickColor: CHART_COLORS.FEEDBACK_NEGATIVE,
    lineSeriesColor: CHART_COLORS.FEEDBACK_WARNING,

    columnFillBrush: CHART_COLORS.NEUTRAL_STRONG,
    columnLineColor: CHART_COLORS.NEUTRAL_STRONG,

    majorGridLineBrush: CHART_COLORS.NEUTRAL_STRONG,
    minorGridLineBrush: CHART_COLORS.NEUTRAL_STRONGER,

    axisBandsFill: CHART_COLORS.NEUTRAL_STRONGEST,
    axisBorder: CHART_COLORS.NEUTRAL_STRONGEST,
    xAxisTooltipColor: CHART_COLORS.BLACK,
    brandSupportPrimary: CHART_COLORS.BRAND_SUPPORT_PRIMARY,
    multibrokerBuy: CHART_COLORS.MULTIBROKER_BUY,
    multibrokerSell: CHART_COLORS.MULTIBROKER_SELL,
    neutralMedium: CHART_COLORS.NEUTRAL_MEDIUM,
    neutralSmoother: CHART_COLORS.NEUTRAL_SMOOTHER,
    neutralStrong: CHART_COLORS.NEUTRAL_STRONG,
  };
};
export const LIGHT_THEME = (): ICandleTheme => {
  return {
    annotationsGripsBackroundBrush: 'white',
    annotationsGripsBorderBrush: 'white',
    axis3DBandsFill: 'red',
    axisPlaneBackgroundFill: 'red',
    cursorLineBrush: 'red',
    defaultColorMapBrush: [
      { offset: 0, color: 'red' },
      { offset: 0.5, color: 'red' },
      { offset: 1, color: 'red' },
    ],
    downBandSeriesFillColor: 'red',
    downBandSeriesLineColor: 'red',
    gridBackgroundBrush: 'white',
    gridBorderBrush: 'red',
    labelBackgroundBrush: 'red',
    labelBorderBrush: 'red',

    loadingAnimationBackground: 'Transparent',
    mountainAreaBrush: 'white',
    mountainLineColor: 'red',
    overviewFillBrush: 'red',
    planeBorderColor: 'red',
    rolloverLineBrush: 'red',
    rubberBandFillBrush: 'red',
    rubberBandStrokeBrush: 'red',
    sciChartBackground: '#f2f0efB3',
    scrollbarBackgroundBrush: 'white',
    scrollbarBorderBrush: 'white',
    scrollbarGripsBackgroundBrush: 'white',
    scrollbarViewportBackgroundBrush: 'white',
    scrollbarViewportBorderBrush: 'white',
    shadowEffectColor: 'white',
    textAnnotationBackground: 'red',
    textAnnotationForeground: 'red',
    upBandSeriesFillColor: 'white',
    upBandSeriesLineColor: 'white',

    // OmniTheme
    labelForegroundBrush: CHART_COLORS_LIGHT.NEUTRAL_SMOOTHEST,
    legendBackgroundBrush: CHART_COLORS_LIGHT.NEUTRAL_STRONGER,
    axisTitleColor: CHART_COLORS_LIGHT.NEUTRAL_SMOOTHEST,
    loadingAnimationForeground: CHART_COLORS_LIGHT.BRAND_PRIMARY,
    tickTextBrush: CHART_COLORS_LIGHT.NEUTRAL_SMOOTH,
    upBodyBrush: CHART_COLORS_LIGHT.FEEDBACK_POSITIVE,
    upWickColor: CHART_COLORS_LIGHT.FEEDBACK_POSITIVE,
    downBodyBrush: CHART_COLORS_LIGHT.FEEDBACK_NEGATIVE,
    downWickColor: CHART_COLORS_LIGHT.FEEDBACK_NEGATIVE,
    lineSeriesColor: CHART_COLORS_LIGHT.FEEDBACK_WARNING,

    columnFillBrush: CHART_COLORS_LIGHT.NEUTRAL_STRONG,
    columnLineColor: CHART_COLORS_LIGHT.NEUTRAL_STRONG,

    majorGridLineBrush: CHART_COLORS_LIGHT.NEUTRAL_STRONG,
    minorGridLineBrush: CHART_COLORS_LIGHT.NEUTRAL_STRONGER,

    axisBandsFill: '#f2f0efB3',
    axisBorder: CHART_COLORS_LIGHT.NEUTRAL_STRONGEST,
    xAxisTooltipColor: CHART_COLORS_LIGHT.BLACK,
    brandSupportPrimary: CHART_COLORS_LIGHT.BRAND_SUPPORT_PRIMARY,
    multibrokerBuy: CHART_COLORS_LIGHT.MULTIBROKER_BUY,
    multibrokerSell: CHART_COLORS_LIGHT.MULTIBROKER_SELL,
    neutralMedium: CHART_COLORS_LIGHT.NEUTRAL_MEDIUM,
    neutralSmoother: CHART_COLORS.NEUTRAL_SMOOTHER,
    neutralStrong: CHART_COLORS.NEUTRAL_STRONG,
  };
};

export class BookPaletteProvider extends DefaultPaletteProvider {
  public override readonly strokePaletteMode: EStrokePaletteMode =
    EStrokePaletteMode.SOLID;
  public override readonly fillPaletteMode: EFillPaletteMode =
    EFillPaletteMode.SOLID;
  private priceData: any[];
  private buyArgb: number;
  private sellArgb: number;

  constructor(
    data: any[],
    useOpacity: boolean,
    buyColor: string = '#5999FB',
    sellColor: string = '#FBBB00'
  ) {
    super();
    this.priceData = data;
    buyColor = useOpacity ? buyColor + '80' : buyColor;
    sellColor = useOpacity ? sellColor + '80' : sellColor;
    this.buyArgb = parseColorToUIntArgb(buyColor);
    this.sellArgb = parseColorToUIntArgb(sellColor);
  }

  override overrideFillArgb(
    xValue: number,
    yValue: number,
    index: number
  ): number {
    if (!this.priceData[index]) {
      this.sellArgb;
    }
    return this.priceData[index]?.type === 'buy' ? this.buyArgb : this.sellArgb;
  }

  override overrideStrokeArgb(
    xValue: number,
    yValue: number,
    index: number
  ): number {
    return this.overrideFillArgb(xValue, yValue, index);
  }
}

export class VolumePaletteProvider
  implements IStrokePaletteProvider, IFillPaletteProvider
{
  public readonly strokePaletteMode: EStrokePaletteMode =
    EStrokePaletteMode.SOLID;
  public readonly fillPaletteMode: EFillPaletteMode = EFillPaletteMode.SOLID;
  private priceData: OhlcDataSeries;
  private volumeUpArgb: number;
  private volumeDownArgb: number;
  highlightColor = 0;
  constructor(
    private stockChartModalService: StockChartModalService,
    priceData: OhlcDataSeries,
    volumeUpColor: string = `${CHART_COLORS.FEEDBACK_POSITIVE}80`,
    volumeDownColor: string = `${CHART_COLORS.FEEDBACK_NEGATIVE}80`,
    newColor: boolean = false
  ) {
    this.highlightColor = parseColorToUIntArgb(
      `${CHART_COLORS.BRAND_PRIMARY}80`
    );
    const configuration = this.stockChartModalService.getGlobalChartConfigs();
    this.priceData = priceData;
    const colorsBase = new ColorsBaseService();
    const volumeUpArgb = newColor
      ? volumeUpColor
      : configuration.volumeUpColor || volumeUpColor;
    const volumeDownArgb = newColor
      ? volumeDownColor
      : configuration.volumeDownColor || volumeDownColor;
    this.volumeUpArgb = parseColorToUIntArgb(colorsBase.hex2Rgba(volumeUpArgb));
    this.volumeDownArgb = parseColorToUIntArgb(
      colorsBase.hex2Rgba(volumeDownArgb)
    );
  }

  onAttached(parentSeries: IRenderableSeries): void {}

  onDetached(): void {}

  overrideFillArgb(xValue: number, yValue: number, index: number): number {
    return this.overrideColorAt(index);
  }

  overrideStrokeArgb(xValue: number, yValue: number, index: number): number {
    return this.overrideFillArgb(xValue, yValue, index);
  }

  overrideColorAt(index: number) {
    const close = this.priceData.getNativeCloseValues().get(index);
    const open = this.priceData.getNativeOpenValues().get(index);
    if (open == close) {
      return this.highlightColor;
    }
    return close <= open ? this.volumeDownArgb : this.volumeUpArgb;
  }
}

export class NegativeBarsPaletteProvider
  implements IStrokePaletteProvider, IFillPaletteProvider
{
  public readonly strokePaletteMode: EStrokePaletteMode =
    EStrokePaletteMode.SOLID;
  public readonly fillPaletteMode: EFillPaletteMode = EFillPaletteMode.SOLID;
  private volumeUpArgb: number;
  private volumnDownArgb: number;

  constructor(
    volumeUpColor: string = CHART_COLORS.FEEDBACK_POSITIVE,
    volumeDownColor: string = CHART_COLORS.FEEDBACK_NEGATIVE
  ) {
    this.volumeUpArgb = parseColorToUIntArgb(hex2rgba(volumeUpColor, 0.5));
    this.volumnDownArgb = parseColorToUIntArgb(hex2rgba(volumeDownColor, 0.5));
  }

  onAttached(parentSeries: IRenderableSeries): void {}

  onDetached(): void {}

  overrideFillArgb(xValue: number, yValue: number, index: number): number {
    return yValue < 0 ? this.volumnDownArgb : this.volumeUpArgb;
  }

  overrideStrokeArgb(xValue: number, yValue: number, index: number): number {
    return this.overrideFillArgb(xValue, yValue, index);
  }
}

export class BarsDynamicColors
  implements IStrokePaletteProvider, IFillPaletteProvider
{
  public readonly strokePaletteMode: EStrokePaletteMode =
    EStrokePaletteMode.SOLID;
  public readonly fillPaletteMode: EFillPaletteMode = EFillPaletteMode.SOLID;
  private brokers: any = null;
  private colors: string[] = COLORS_BROKER;

  constructor(brokers: any = null) {
    this.brokers = brokers;
  }

  onAttached(parentSeries: IRenderableSeries): void {}

  onDetached(): void {}

  overrideFillArgb(xValue: number, yValue: number, index: number): number {
    const color = this.brokers[index]?.color;
    return this.colorStringToArgb(color || '#FFFFFF');
  }

  overrideStrokeArgb(xValue: number, yValue: number, index: number): number {
    return this.overrideFillArgb(xValue, yValue, index);
  }

  private colorStringToArgb(color: any): number {
    const hexColor = color.replace('#', '');
    const alpha = 'FF';
    const argb = alpha + hexColor;
    return parseInt(argb, 16);
  }
}

export class NegativeLines extends DefaultPaletteProvider {
  constructor() {
    super();
    this.strokePaletteMode = EStrokePaletteMode.GRADIENT;
    this.fillPaletteMode = EFillPaletteMode.GRADIENT;
  }

  public override overrideFillArgb(
    xValue: number,
    yValue: number,
    index: number
  ) {
    return yValue > 0
      ? parseColorToUIntArgb(hex2rgba(CHART_COLORS.FEEDBACK_POSITIVE, 0.8))
      : parseColorToUIntArgb(hex2rgba(CHART_COLORS.FEEDBACK_NEGATIVE, 0.8));
  }

  public override overrideStrokeArgb() {
    return parseColorToUIntArgb(CHART_COLORS.OPACITY_BACKGROUND);
  }
}
