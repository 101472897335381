<rocket-modal>
  <rocket-modal-header>
    <div *ngIf="!isRemove; else removedHeader">
      <h4 class="modal-title">
        {{ !existModel ? 'Salvar modelo de desenho' : 'Confirmação' }}
      </h4>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div *ngIf="!isRemove; else removed">
      <div class="mb-6">
        <form *ngIf="!existModel; else validityOverwriter" [formGroup]="form">
          <label for="models">Nome do modelo:</label>
          <ng-select
            #ngSelectModel
            [ngClass]="{
              'without-models': savedModels.length === 0 || !showArrow
            }"
            id="ng-select-model-save"
            class="form-select form-select-sm form-control-sm"
            [clearable]="false"
            [items]="savedModels"
            formControlName="model"
            labelForId="models"
            [searchFn]="customSearchFn"
            [addTag]="addTag"
            (search)="onSearch($event)"
            (change)="onChange($event)"
            (blur)="onBlur($event)"
            (click)="onOpen($event)"
            [isOpen]="isOpen"
          >
            <ng-template ng-option-tmp let-item="item">
              <div>{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </form>
      </div>
      <div
        class="mb-6 d-flex justify-content-end"
        *ngIf="!existModel; else validityOverwriterFooter"
      >
        <rocket-button
          type="fill"
          label="Cancelar"
          css="mr-4 btn-sm"
          (rocketClick)="onClose()"
          [isSmall]="true"
        ></rocket-button>
        <rocket-button
          [isDisabled]="!isTyping"
          [isSmall]="true"
          label="Salvar"
          (rocketClick)="addModel()"
        ></rocket-button>
      </div>
    </div>
  </app-rocket-modal-body>
</rocket-modal>

<ng-template #validityOverwriter>
  <div>
    O template de desenho '{{ form.value['model'].label }}' já existe. Você
    realmente quer substitui-lo?
  </div>
</ng-template>
<ng-template #validityOverwriterFooter>
  <div class="mb-6 d-flex justify-content-end">
    <rocket-button
      type="fill"
      label="Não"
      css="mr-4 btn-sm"
      (rocketClick)="existModel = false"
      [isSmall]="true"
    ></rocket-button>
    <rocket-button
      [isSmall]="true"
      label="Sim"
      (rocketClick)="addModel(true)"
    ></rocket-button>
  </div>
</ng-template>
<ng-template #removed>
  <div class="mb-6">
    <div>
      Você quer realmente deletar o template de desenho '{{
        selectedModel.title
      }}' ?
    </div>
    <div class="mb-6 d-flex justify-content-end">
      <rocket-button
        type="fill"
        label="Não"
        css="mr-4 btn-sm"
        (rocketClick)="onClose()"
        [isSmall]="true"
      ></rocket-button>
      <rocket-button
        [isSmall]="true"
        label="Sim"
        (rocketClick)="onClose(true)"
      ></rocket-button>
    </div>
  </div>
</ng-template>

<ng-template #removedHeader>
  <div>
    <h4 class="modal-title">Excluir modelo de desenho</h4>
  </div>
</ng-template>
