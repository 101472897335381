<workspace-doc-header
  css="w-100 d-flex header-wrapper border-bottom"
  [item]="_component()!!"
  [inheritMaxWidth]="true"
  [autoMaxWidth]="false"
  maxWidth="calc(100% - 70px)"
>
  <fla-workspace-doc-header-item [enableContent]="true" [hideBorder]="true" width="100%">
    <div class="d-flex justify-content-between w-100">
      <section class="hstack">
        <ul
          #dropList
          class="tabs nav nav-navbar align-items-center ml-1 inline-scroll"
          cdkDropList
          cdkDropListOrientation="horizontal"
          appCarouselScroll
          [carouselDelay]="150"
          [itemsLength]="tabs.length"
        >
          <li
            cdkDrag
            class="nav-item drag-box"
            *ngFor="let item of tabs; let i = index"
          >
            <span
              class="hstack py-0 px-1 tab"
              [ngClass]="{
                active: selectedTab && item.id === selectedTab.id
              }"
            >
              <button
                *ngIf="
                  item.stock && (item.id !== selectedTab?.id || !editMode);
                  else elseEdit
                "
                [tooltip]="item.stock.cd_stock || ''"
                tooltipPosition="bottom"
                class="nav-link py-2 p-0 ml-0 mr-2 lh-sm cursor-pointer text-truncate d-inline-block opt-tab-label fs-6"
                [ngClass]="{
                  'text-brand-primary':
                    selectedTab && item.id === selectedTab.id,
                  'pe-none opacity-semi-opaque': disableChangeStock
                }"
                (click)="onItemClick(item)"
              >
                {{ item.stock.cd_stock }}
              </button>

              <ng-template #elseEdit>
                <app-search-stock
                  #searchStock
                  css="opt-tab-label ml-2 border-right"
                  [resetInput]="false"
                  [ref]="refComponent"
                  [headerOptions]="headerOptions()"
                  [initStock]="item.stock"
                  [ignoreInitStock]="ignoreInitStock"
                  (selectStockChange)="selectStock($event, i)"
                  (openHandler)="onItemClick(item)"
                ></app-search-stock>
              </ng-template>
              <rocket-button
                [isDisabled]="tabs.length === 1"
                type="icon"
                css="p-0 min-w-0"
                (rocketClick)="close(i)"
              >
                <fla-icon
                  *ngIf="selectedTab?.id === item.id"
                  name="close"
                  size="icon-size-micro"
                  css="text-neutral-medium"
                ></fla-icon>
              </rocket-button>
              <span class="vr align-self-center bg-neutral-stronger"></span>
            </span>
          </li>
          <li class="nav-item d-flex">
            <rocket-button
            type="icon"
            css="p-0 min-w-0 bg-neutral-stronger round-sm"
            (rocketClick)="newTab()"
            >
              <fla-icon
                name="add"
                size="icon-size-micro"
                css="text-neutral-medium"
              ></fla-icon>
            </rocket-button>
          </li>
        </ul>
      </section>

    </div>
  </fla-workspace-doc-header-item>
</workspace-doc-header>
