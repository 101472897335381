<ng-template>
  <div class="card flex-row mb-3" [ngClass]="customClass">
    <ng-content select="fla-icon"></ng-content>
    <div class="w-100">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <p class="card-title">{{ title }}</p>
        <a
          class="card-link fs-6 cursor-pointer text-brand-primary"
          type="button"
          (click)="flaClick.emit($event)"
        >
          {{ link }}
        </a>
      </div>
      <div class="card-body">
        <p class="mb-0">{{ body }}</p>
      </div>
    </div>
  </div>
</ng-template>
