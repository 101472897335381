import { Observable, throwError } from 'rxjs';
import { ApiResponse } from './api-response';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ErrorCode } from './error-code';
import { AUTH_TOKEN_NITRO_WS } from '@shared/services/core/const/session-storage.const';
import { getErrorMessage } from './api-error';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';

export class RestService {
  protected apiEndPoint: string = '';

  protected _url: string = '';

  protected httpOptions = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: localStorage.getItem(AUTH_TOKEN_NITRO_WS) ?? '',
  });

  get headers() {
    return { headers: this.httpOptions };
  }

  constructor(private _http: HttpClient) { }

  getCookie = (name: string) => {
    const ca: Array<string> = document.cookie.split(';');
    const cookieName = `${name}=`;
    let c: string;
    for (let i = 0, caLen = ca.length; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  };

  authCheetahToken = () => this.getCookie('cfid');

  protected getAll<T>(
    method: string,
    httpOptions: HttpHeaders = this.httpOptions
  ): Observable<ApiResponse<T[]>> {
    this.httpOptions = httpOptions;
    return this.mapAndCatchError(
      this._http.get<ApiResponse<T[]>>(
        `${this.apiEndPoint}${this._url}/${method}`,
        {
          headers: httpOptions,
          withCredentials: true,
        }
      )
    );
  }

  protected get<T>(
    id: any,
    httpOptions: HttpHeaders = this.httpOptions
  ): Observable<ApiResponse<T>> {
    this.httpOptions = httpOptions;
    return this.mapAndCatchError(
      this._http.get<ApiResponse<T>>(`${this.apiEndPoint + this._url}/${id}`, {
        headers: httpOptions,
        withCredentials: true,
      })
    );
  }

  protected put<T>(
    method: string,
    resource: object,
    httpOptions: HttpHeaders = this.httpOptions
  ): Observable<ApiResponse<T>> {
    const finalUrl = `${this.apiEndPoint}${this._url}/${method}`;
    return this.mapAndCatchError(
      this._http.put<ApiResponse<T>>(finalUrl, resource, {
        headers: httpOptions,
        withCredentials: true,
      })
    );
  }

  protected post<T>(
    method: string,
    resource: object,
    httpOptions: HttpHeaders = this.httpOptions
  ): Observable<ApiResponse<T>> {
    const finalUrl = `${this.apiEndPoint}${this._url}/${method}`;
    this.httpOptions = httpOptions;
    return this.mapAndCatchError(
      this._http.post<ApiResponse<T>>(finalUrl, resource, {
        headers: httpOptions,
        withCredentials: true,
      })
    );
  }

  protected delete<T>(
    method: any,
    httpOptions: HttpHeaders = this.httpOptions
  ): Observable<ApiResponse<T>> {
    this.httpOptions = httpOptions;
    return this.mapAndCatchError(
      this._http.delete<ApiResponse<T>>(
        `${this.apiEndPoint + this._url}/${method}`,
        {
          headers: httpOptions,
          withCredentials: true,
        }
      )
    );
  }

  protected add<T>(
    resource: object,
    httpOptions: HttpHeaders = this.httpOptions
  ): Observable<ApiResponse<T>> {
    const finalUrl = this.apiEndPoint + this._url;
    this.httpOptions = httpOptions;
    return this.mapAndCatchError(
      this._http.post<ApiResponse<T>>(finalUrl, resource, this.headers)
    );
  }
  // update and remove here...

  // common method
  protected makeRequest<TData>(
    method: string,
    url: string,
    data: any
  ): Observable<ApiResponse<TData>> {
    let finalUrl: string = this.apiEndPoint + url;
    let body: any = null;
    if (method.toUpperCase() == 'GET') {
      finalUrl += '?' + this.objectToQueryString(data);
    } else {
      body = data;
    }
    return this.mapAndCatchError<TData>(
      this._http.request<ApiResponse<TData>>(method.toUpperCase(), finalUrl, {
        body: body,
        ...this.headers,
        withCredentials: true,
      })
    );
  }

  public handlerServiceError(res: any): any {
    let message = res.error ?? res.message;
    if (
      message.includes('No eligible members for the call') ||
      isNullOrUndefined(message) ||
      message === ''
    )
      message = 'Houve um erro inesperado';
    return { success: false, message, code: res?.errorCode ?? '' };
  }

  public _returnDataInDict(data: any) {
    return {
      success: true,
      result: data,
      message: '',
    };
  }

  /////// private methods
  private mapAndCatchError<T>(
    response: Observable<ApiResponse<T>>
  ): Observable<ApiResponse<T>> {
    return response.pipe(
      map((r: any) => {
        const result = new ApiResponse<T>();
        result.data = r as T;
        return result;
      }),
      catchError((err: HttpErrorResponse) => {
        const { error, status } = err;
        const errorMessage =
          error?.message ||
          (status > 500 && status < 600 && 'Service Unavailable');
        const { msg, code } = getErrorMessage(errorMessage);
        return throwError(() => ({
          data: error,
          error: msg,
          errorCode: error?.code,
          code: code || ErrorCode.UnknownError,
          errors: [{ code: code || ErrorCode.UnknownError, text: msg }],
        }));
      })
    );
  }

  private objectToQueryString(obj: any): string {
    const str = [];
    for (const p in obj)
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  }
}
