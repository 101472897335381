<div id="newsList" class="d-flex h-100 overflow-auto w-100">
  <cdk-virtual-scroll-viewport
    #scrollViewport
    itemSize="46"
    class="w-100 overflow-hidden w-100"
  >
    <fla-card class="pr-2 w-100">
      <fla-card-custom
        #card
        css="p-1 bg-neutral-strong mt-2 cursor-pointer"
        *cdkVirtualFor="let new of news"
      >
        <div
          class="card-body justify-content-center align-items-center w-100"
          (click)="documentOpen(new)"
        >
          <div class="d-flex justify-content-between w-100">
            <div class="d-flex align-items-center">
              <div
                class="p-1 dateEventContainer bg-dark mr-2 round d-flex flex-column justify-content-center align-items-center"
                [style.min-width.px]="41"
              >
                <span class="fs-7">{{ new.dateReleased }}</span>
                <span class="fs-7 text-neutral-medium">
                  {{ new.yearReleased }}
                </span>
              </div>
            </div>
            <span
              class="card-title fs-6 flex-grow-1 mt-2 text-truncate"
              [innerHTML]="new.ds_headline"
            >
            </span>
            <div class="d-flex align-items-center justify-content-end">
              <fla-icon
                name="chevron_right"
                css="cursor-pointer text-brand-primary"
                size="icon-size-sm"
                [isVmIcon]="false"
              >
              </fla-icon>
            </div>
          </div>
        </div>
      </fla-card-custom>
    </fla-card>
  </cdk-virtual-scroll-viewport>
</div>
