import {
  TigerChartIndicatorElephantBarOptions,
  TTigerChartIndicatorCreateOptions,
} from './indicators.types';
import { TigerChartIndicatorElephantBarBase } from './base/elephant-bar-base';
import { Dictionary } from '@core/models';

export class TigerChartIndicatorElephantBar extends TigerChartIndicatorElephantBarBase {
  constructor(options: TigerChartIndicatorElephantBarOptions) {
    super(options);
  }

  override create(options: TTigerChartIndicatorCreateOptions) {
    super.create(options);
    this.updatePoints();
  }

  override updatePoints(): void {
    this.setElephantBars();
  }

  override updateSettings() {
    this.setElephantBars();
    super.updateSettings();
  }

  public setElephantBars() {
    const avgBodyPercent = 70;
    const timePeriod = 14;
    const offsetHigh: number[] = Array(timePeriod).fill(this.data.vl_high[0]);
    const offsetLow: number[] = Array(timePeriod).fill(this.data.vl_low[0]);
    const offsetClose: number[] = Array(timePeriod).fill(this.data.vl_close[0]);
    const atr = this.service.call('ATR', {
      close: [...offsetClose, ...this.data.vl_close],
      high: [...offsetHigh, ...this.data.vl_high],
      low: [...offsetLow, ...this.data.vl_low],
      timePeriod,
    });
    const listATR = atr.output.slice(0, atr.output.length - timePeriod);
    const elephantCandles: IElephantCandles = {
      positive: new Dictionary<boolean>(),
      negative: new Dictionary<boolean>(),
    };
    for (let i = 0; i < this.data.id_point.length; i++) {
      const open = this.data.vl_open[i];
      const close = this.data.vl_close[i];
      const high = this.data.vl_high[i];
      const low = this.data.vl_low[i];
      const closeIsBiggerThanOpen = close > open;
      let isCandleGreenBiggerMedian!: boolean;
      let isCandleRedBiggerMedian!: boolean;
      let isCandleGreenBiggerPrevious!: boolean;
      let isCandleRedBiggerPrevious!: boolean;
      const diffOpenClose = Math.abs(open - close);
      const diffHighLow = Math.abs(high - low);
      // verifica se o corpo é maior que a média;
      if (closeIsBiggerThanOpen) {
        isCandleGreenBiggerMedian =
          (diffOpenClose * 100) / diffHighLow >= avgBodyPercent;
      } else {
        isCandleRedBiggerMedian =
          (diffOpenClose * 100) / diffHighLow >= avgBodyPercent;
      }
      //verifica se o corpo é maior que o candle anterior;
      const isBiggerPrevious =
        diffOpenClose >= (i == 0 ? 0 : listATR[i - 1]) * this.timePeriod;
      if (isCandleGreenBiggerMedian) {
        isCandleGreenBiggerPrevious = isBiggerPrevious;
      }
      if (isCandleRedBiggerMedian) {
        isCandleRedBiggerPrevious = isBiggerPrevious;
      }
      if (isCandleGreenBiggerPrevious) {
        elephantCandles.positive.set(i, true);
      }
      if (isCandleRedBiggerPrevious) {
        elephantCandles.negative.set(i, true);
      }
    }
    this.updateCandlePalleteProvider(elephantCandles);
  }
}

export interface IElephantCandles {
  positive: Dictionary<boolean>;
  negative: Dictionary<boolean>;
}
