import { Component, Input } from '@angular/core';
import { RTTPadding } from '@shared/rocket-components/types';

@Component({
  selector: 'rocket-modal-footer',
  template: ` <div class="modal-footer" [ngClass]="padding">
    <ng-content select="rocket-button"></ng-content>
  </div>`,
  styles: [
    `
      :host(rocket-modal-footer) {
        display: contents;
      }
    `,
  ],
})
export class RocketModalFooterComponent {
  @Input() padding: RTTPadding = 'pt-7';
  constructor() {}
}
