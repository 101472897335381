import { TigerChartComponent } from '../../tiger-chart.component';
import { TIGER_INDICATORS_MENU_ENUM } from '../../enum/tiger-chart.enum';
import { BaseIndicator } from '../../indicators/indicators.types';
import MenuItemBase from './menu-item-base';

export default class MoveToNewPanelBelow extends MenuItemBase {
  constructor() {
    super();
    this.label = 'Novo painel abaixo';
    this.id = TIGER_INDICATORS_MENU_ENUM.MOVE_NEW_PANEL_BELOW;
  }

  override onCLick(
    candleComponent: TigerChartComponent,
    indicator: BaseIndicator
  ): void {
    candleComponent.createBelowChart(indicator);
  }
}
