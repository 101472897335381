<div
  class="align-items-center hstack justify-content-around mb-3 overflow-x-hidden pb-1"
>
  <button
    class="btn btn-fill-primary p-0 m-0 min-w-initial"
    [disabled]="disableArrowLeft"
    (click)="prevContent()"
  >
    <fla-icon name="arrow_back_ios" size="icon-size-micro"> </fla-icon>
  </button>
  <div
    #scroll
    class="align-items-center d-inline-flex overflow-hidden"
    style="width: 380px"
  >
    <ng-container *ngFor="let tab of tabs">
      <button
        style="white-space: nowrap"
        class="btn nav-link fs-5 fw-bold min-w-initial pr-3"
        [ngClass]="{ 'text-brand-primary': tab === selectedTab }"
        (click)="changeTab(tab)"
      >
        {{ tab }}
      </button>
    </ng-container>
  </div>
  <button
    class="btn btn-fill-primary p-0 m-0 min-w-initial"
    [disabled]="disableArrowRight"
    (click)="nextContent()"
  >
    <fla-icon name="arrow_forward_ios" size="icon-size-micro"> </fla-icon>
  </button>
</div>
<div
  (scrollend)="scrollend($event)"
  class="d-flex flex-column overflow-auto"
  [style.height]="height"
>
  <ng-container *ngIf="!loading; else loadingRef">
    <ng-container *ngIf="hashToShow.size() && !noData; else emptyState">
      <ng-container *ngFor="let stock of hashToShow.values()">
        <app-stock-card
          [stock]="stock"
          [bgColor]="stock.arrowHex"
          [textColor]="stock.arrowFont"
          [variation]="stock.dayVariation"
          [lastPrice]="stock.valueCloseFormatted"
          [vlCloseHistory]="
            vlCloseHistory[stock.cdStock + ':' + stock.idExchange]
          "
          [isClickable]="true"
          (clicked)="selectStock(stock)"
        >
          <rocket-button *ngIf="selectToStockList" type="icon">
            <fla-icon
              [name]="stock?.isCheck ? 'done' : 'add'"
              size="icon-size-sm"
              [css]="
                'ml-1 cursor-pointer' +
                (!stock?.isCheck ? ' text-brand-primary' : '')
              "
            ></fla-icon>
          </rocket-button>
        </app-stock-card>
      </ng-container>
    </ng-container>

    <ng-template #emptyState>
      <span class="w-100 text-center d-block fs-5 py-3">
        {{ emptyStateMessage }}
      </span>
    </ng-template>
  </ng-container>

  <ng-template #loadingRef>
    <div class="h-100">
      <app-loading></app-loading>
    </div>
  </ng-template>
</div>
