import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { INews, INewsTag } from '@core/interface/news-agency.interface';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsCardComponent {
  @Input() news!: INews | any;
  @Input() selected = false;
  @Input() selectedTag!: number | null;
  @Output() outputClick: EventEmitter<INews> = new EventEmitter<INews>();
  @Output() outputTagSelected: EventEmitter<INewsTag> =
    new EventEmitter<INewsTag>();
  @Output() outputGoBack: EventEmitter<null> = new EventEmitter();

  selectNews() {
    if (this.selected) return;
    this.outputClick.emit(this.news);
  }

  goBack() {
    this.outputGoBack.emit();
  }

  bubbleTagSelectedEvent(tag: INewsTag) {
    this.outputTagSelected.emit(tag);
  }
}
