<div class="box-select">
  <form #formElement [formGroup]="form">
    <fla-select
      #select
      formControlName="period"
      label=""
      [placeholder]="''"
      bindLabel="label"
      bindValue="id"
      [isSmall]="true"
      [searchable]="false"
      [list]="periodsSelector"
      css="border-right cursor-pointer"
      (flaChange)="handlePeriod($event)"
    ></fla-select>
  </form>
</div>
