import {
  AVAILABLE_LETTERS,
  AVAILABLE_NUMBERS,
} from '@shared/constants/keyboard.const';

export const cantProcessKeyboardEvent = (element: any) =>
  element.tagName == 'INPUT' ||
  element.tagName == 'SELECT' ||
  element.tagName == 'TEXTAREA' ||
  element.isContentEditable;

export const isLetterNotCtrlShift = (
  code: string,
  ctrlKey: boolean,
  shiftKey: boolean
) => {
  return AVAILABLE_LETTERS.includes(code) && !ctrlKey && !shiftKey;
};

export const isLetterOrNumberNotCtrl = (code: string, ctrlKey: boolean) => {
  return (
    [...AVAILABLE_LETTERS, ...AVAILABLE_NUMBERS].includes(code) && !ctrlKey
  );
};

export const isLetterOrNumberNotCtrlShift = (code: string, ctrlKey: boolean, shiftKey: boolean) => {
  return (
    [...AVAILABLE_LETTERS, ...AVAILABLE_NUMBERS].includes(code) && !ctrlKey && !shiftKey
  )
}
