import { IPartnersWithCustomLoginContent } from './partner-preferences.interface';

export const ONBOARD_PARTNERS_ORIGIN = {
  btg: 'BTG',
  xp: 'XP',
  trademappro: 'TRADEMAPPRO',
  rocket: 'WEBROCKETTRADER',
};

export const AUTH_PARTNER_NICKNAME = {
  btg: 'BTG',
  xp: 'XP Investimentos',
  trademappro: 'Trademap Pro',
  rocket: 'RocketTrader',
};

export const BROKER_PARTNER_CAMPAING = {
  xp: true,
  btg: false,
  trademappro: false,
  rocket: false,
};

export const AVAILABLE_PARTNERS_TO_LINK = {
  xp: true,
  btg: true,
  trademappro: false,
  rocket: false,
  clear: false,
};

export type AVAILABLE_PARTNERS_ENUM =
  | 'btg'
  | 'xp'
  | 'trademappro'
  | 'rocket'
  | null;

export const PARTNER_WITH_CUSTOM_LAYOUT = {
  xp: true,
  btg: false,
  trademappro: false,
  rocket: false,
};

export const PARTNER_CUSTOM_CONTENT: IPartnersWithCustomLoginContent = {
  xp: {
    title: 'Para começar sua jornada',
    logo: 'logo-xp-investimentos.svg',
    usernameInputLabel: 'CPF',
    usernameInputPlaceholder: 'Digite seu CPF',
    itens: [
      {
        icon: 'mail',
        customIcon: '',
        text: '<span class="fw-bold fs-5">Verifique em seu e-mail as <span class="text-brand-primary">instruções para seu primeiro acesso<br/></span>Lembre-se de verificar sua <span class="text-brand-primary">caixa de spam</span></span>',
      },
      {
        icon: 'vpn_key',
        customIcon: '',
        text: '<span class="fw-bold fs-5">Utilize sua senha provisória, enviada por e-mail,<span class="text-brand-primary">  para realizar o seu primeiro acesso</span></span>',
      },
      {
        icon: 'videogame_asset',
        customIcon: '',
        text: '<span class="fw-bold fs-5">Teste suas habilidades de trading com <span class="fw-bold text-brand-primary">nosso simulador</span></span>',
      },
    ],
    carousel: [
      {
        title: 'Estabilidade incomparável',
        content: 'Viemos atingir os seus mais altos níveis de exigência',
      },
      {
        title: 'Leveza, versatilidade e performance',
        content:
          'Opere de qualquer lugar de forma simples e com a melhor performance do mercado',
      },
      {
        title: 'Segurança',
        content: 'A segurança e proteção dos seus dados é nossa prioridade',
      },
      {
        title: 'Navegação e usabilidade modernas',
        content: 'Negocie com praticidade e eficiência',
      },
      {
        title: 'Tecnologia de ponta',
        content:
          'Tenha uma estabilidade incomparável e baixa latência para as suas negociações',
      },
    ],
  },
};
