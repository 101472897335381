<div class="box-notice cursor-pointer h-100">
  <fla-card>
    <fla-card-img
      #card
      [src]="cardNotice.image_url"
      height="110"
      css="p-0 h-100 bg-neutral-strong"
      [imgBottom]="false"
      classImg="round"
      classBody="undefined"
      [overlay]="false"
    >
      <p class="card-text px-1" [innerText]="cardNotice.ds_headline"></p>
    </fla-card-img>
  </fla-card>
</div>
