import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';
import { AuthService } from '@shared/services';
import { DeviceValidatorService } from '@shared/services/device-validator.service';
import { PartnersService } from '@shared/services/core/partners-services/partners.service';
import { PARTNER_WITH_CUSTOM_LAYOUT } from '@shared/services/core/partners-services/partners.const';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, AfterViewInit, OnDestroy {
  private untilDestroy$ = new Subject<void>();
  public isLoginRoute: boolean = false;
  public isCreateAccountFormPage: boolean = false;
  public useRegisterContent: boolean = false;
  public isToken: boolean = false;
  public showBrowserErrorMsg = false;
  public browserMessage = '';
  public useSmallPadding: boolean = false;

  private _authRoutes = [
    '/auth/login',
    '/auth/register/welcome',
    '/auth/register/create',
    '/auth/reset/recovery',
  ];
  private _partnersService = inject(PartnersService);
  private _cdr = inject(ChangeDetectorRef);

  constructor(
    private location: Location,
    private router: Router,
    private _auth: AuthService,
    private _deviceValidator: DeviceValidatorService
  ) {
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.untilDestroy$)
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const url = this.router.url;
          this.isCreateAccountFormPage = url.includes('auth/register/create');
          this.useRegisterContent = url.includes('auth/register');
          this.isToken = url.includes('auth/token/');
          this.isLoginRoute = this._authRoutes.some((route) =>
            url.includes(route)
          );
        }
      });
  }

  navigateBackward() {
    this.location.back();
  }

  ngOnInit() {
    if (this._auth.isAuthenticated() && this.router.url !== '/')
      this.router.navigateByUrl('/');
    if (!this._deviceValidator.isChromium()) {
      this.browserMessage = this._deviceValidator.browserMessage;
      this.showBrowserErrorMsg = true;
    }
  }

  ngAfterViewInit(): void {
    if (this._partnersService.partnerBeforeAuth) {
      this.useSmallPadding =
        PARTNER_WITH_CUSTOM_LAYOUT[
          this._partnersService.partnerBeforeAuth.key!
        ];
      this._cdr.detectChanges();
    }
  }

  ngOnDestroy() {
    this.untilDestroy$.next();
    this.untilDestroy$.complete();
  }
}
