import { ISearchStock } from '@core/interface';

export type TOptionsTab = {
  id: number;
  editMode: boolean;
  stock?: ISearchStock;
};

export type TOptionsFilter = {
  type: TOptionsType;
  style: TOptionsStyle;
  status: TOptionsStatus;
  alreadNegotiated: boolean;
  date: string;
};

export type TOptionsType = 'C' | 'P' | 'ALL';
export type TOptionsStyle = 'A' | 'E' | 'ALL';
export type TOptionsStatus = 'ITM' | 'ATM' | 'OTM' | 'ALL';
export type TOptionsSubheaderTabs =
  | 'Opções'
  | 'Tabela'
  | 'Operações'
  | 'Curvas';
export type TOptionsStrateyAnswer = 'alta' | 'neutra' | 'baixa' | '';
export type TOptionsTableConfigTab =
  | 'OPTION'
  | 'PRICE'
  | 'VOLATILITY'
  | 'GREEk';

export const OPTIONS_TYPES = {
  C: 'Call',
  P: 'Put',
  ALL: 'Call e Put',
};
export const OPTIONS_STYLES = {
  A: 'Americana',
  E: 'Europeia',
  ALL: 'Amer e Euro',
};
export const OPTIONS_STATUS = {
  ITM: 'In The Money',
  ATM: 'At The Money',
  OTM: 'Out The Money',
  ALL: 'Todos',
};
export const OPTIONS_STRATEGY_ANSWERS = {
  alta: 'Subir',
  baixa: 'Cair',
  neutra: 'Se Manter',
};
export const OPTIONS_TABLE_CONFIG_TABS = {
  OPTION: 'Opção',
  PRICE: 'Preço',
  VOLATILITY: 'Volatilidade',
  GREEk: 'Gregas',
};

export const OPTIONS_CONFIG_MAPPING: { [key: string]: any } = {
  label: OPTIONS_TABLE_CONFIG_TABS.OPTION,
  strike_state: OPTIONS_TABLE_CONFIG_TABS.OPTION,
  in_option: OPTIONS_TABLE_CONFIG_TABS.OPTION,
  strike_status: OPTIONS_TABLE_CONFIG_TABS.OPTION,
  kind_option: OPTIONS_TABLE_CONFIG_TABS.OPTION,
  day_variation: OPTIONS_TABLE_CONFIG_TABS.OPTION,
  uncovered_qty: OPTIONS_TABLE_CONFIG_TABS.OPTION,
  covered_qty: OPTIONS_TABLE_CONFIG_TABS.OPTION,
  strike_price: OPTIONS_TABLE_CONFIG_TABS.PRICE,
  valor_melhor_oferta_compra: OPTIONS_TABLE_CONFIG_TABS.PRICE,
  valor_melhor_oferta_venda: OPTIONS_TABLE_CONFIG_TABS.PRICE,
  vl_close: OPTIONS_TABLE_CONFIG_TABS.PRICE,
  qt_trades: OPTIONS_TABLE_CONFIG_TABS.VOLATILITY,
  qt_share_traded: OPTIONS_TABLE_CONFIG_TABS.VOLATILITY,
  vl_vol_implicitly: OPTIONS_TABLE_CONFIG_TABS.VOLATILITY,
  volume: OPTIONS_TABLE_CONFIG_TABS.VOLATILITY,
  vl_delta: OPTIONS_TABLE_CONFIG_TABS.GREEk,
  vl_gamma: OPTIONS_TABLE_CONFIG_TABS.GREEk,
  vl_theta: OPTIONS_TABLE_CONFIG_TABS.GREEk,
  vl_rho: OPTIONS_TABLE_CONFIG_TABS.GREEk,
  vl_vega: OPTIONS_TABLE_CONFIG_TABS.GREEk,
};

export type TOptionsExpirationDatesResponse = {
  cd_stock: string;
  id_stock: number;
  expiration_dates: { dt_expiration_option: string }[];
};

export type TOptions = {
  id_stock: number;
  cd_stock: string;
  label?: string;
  option_days_to_expiration: number;
  dt_expiration_option: number;
  in_option: TOptionsType;
  kind_option: string;
  strike_price: number;
  vl_close: number;
  qt_trades: number;
  day_variation: number;
  volume: number;
  qt_share_traded: number;
  strike_state: string;
  vl_vol_implicitly: number;
  vl_theta: number;
  vl_delta: number;
  vl_rho: number;
  vl_gamma: number;
  vl_vega: number;
  vl_close_option: number;
  vl_close_base: number;
  uncovered_qty?: number;
  covered_qty?: number;
  valor_melhor_oferta_compra: number;
  valor_melhor_oferta_venda: number;
};

export type TCallPutOptions = {
  label_call: string;
  kind_option_call: string;
  day_variation_call: number;
  vl_vol_implicitly_call: number;
  vl_delta_call: number;
  qt_trades_call: number;
  qt_share_traded_call: number;
  valor_melhor_oferta_compra_call: number;
  valor_melhor_oferta_venda_call: number;
  vl_close_call: number;
  option_days_to_expiration_call: number;
  id_stock_call: number;
  cd_stock_call: string;
  strike_price: number;
  label_put: string;
  kind_option_put: string;
  day_variation_put: number;
  vl_vol_implicitly_put: number;
  vl_delta_put: number;
  qt_trades_put: number;
  qt_share_traded_put: number;
  valor_melhor_oferta_compra_put: number;
  valor_melhor_oferta_venda_put: number;
  vl_close_put: number;
  option_days_to_expiration_put: number;
  id_stock_put: number;
  cd_stock_put: string;
  isCurrentPrice?: boolean;
  dt_expiration_option_call: number;
  in_option_call: TOptionsType;
  volume_call: number;
  strike_state_call: string;
  vl_theta_call: number;
  vl_rho_call: number;
  vl_gamma_call: number;
  vl_vega_call: number;
  vl_close_option_call: number;
  vl_close_base_call: number;
  dt_expiration_option_put: number;
  in_option_put: TOptionsType;
  volume_put: number;
  strike_state_put: string;
  vl_theta_put: number;
  vl_rho_put: number;
  vl_gamma_put: number;
  vl_vega_put: number;
  uncovered_qty_call?: number;
  covered_qty_call?: number;
  uncovered_qty_put?: number;
  covered_qty_put?: number;
};

export type TOptionsForm = {
  type: string;
  style: string;
  status: string;
  alreadNegotiated: boolean;
  date: string;
};

export type TOptionsOperation = {
  cd_option_status: string;
  cd_stock_option: string;
  ds_operation: string;
  dt_operation: number;
  id_exchange: number;
  id_investor: number;
  id_operation: number;
  id_stock: number;
  legs: TOptionsOperationLeg[];
  legsMap: any;
};

export type TOptionsOperationLeg = {
  cd_stock: string;
  id_exchange: number;
  id_stock: number;
  option_days_to_expiration: number;
  dt_expiration_option: string | number;
  strike_price: string | number;
  volume: string | number;
  qt_share_traded: number | string;
  vl_vol_implicitly: number | string;
  vl_theta: number | string;
  vl_delta: number | string;
  vl_rho: number | string;
  vl_gamma: number | string;
  vl_vega: number | string;
  option_type: TOptionsType;
  price: number | string;
  qtty_lot: number | string;
  side: string;
};

export type TOptionsStrategies = {
  options_call: any[];
  options_put: any[];
  strategies: any[];
};

export type TStrategyOption = {
  cd_stock: string;
  option_type: TOptionsType;
  strike_price: number;
  price: number;
  option_days_to_expiration: number;
  side: string;
  qtty_lot?: number;
  expanded?: boolean;
};

export type TStrategyOptionChartSeries = {
  price: number;
  result: number;
};

export type TStrategyOptionExpirationDate = {
  id: string;
  label: string;
  twoDigitsYearLabel: string;
  tooltip: string;
};

export type TOptionSmileResponse = {
  candles: TOptionSmileChartSeries[];
};

export type TOptionSmileChartSeries = {
  cd_stock: string;
  option_type: string;
  vl_vol_implicitly: number;
  strike_price: number;
};

export type TOptionsStrategyType = {
  default_lot_size: number;
  ds_strategy: string;
  ds_title: string;
  icon: string;
  id_strategy: number;
  lot_increment: number;
  qtty_lot: number;
  trending_market: string;
  trending_vol: string;
  legs: TOptionsOperationLeg[];
};

export const DEFAULT_STOCK_VALUES = {
  cd_country: '',
  cd_cvm: '',
  cd_security_type: '',
  cd_segment: '',
  cd_status_stock: '',
  cd_stock_option: '',
  cd_stock_order: '',
  dividendAnnual: 0,
  dividendLastYear: 0,
  dividendMonth: 0,
  ds_asset: '',
  ds_index_description: '',
  ds_segment: '',
  ds_type: '',
  dt_expiration_option: 0,
  dt_maturity: 0,
  id_company: 0,
  id_company_tb_cvm_company: 0,
  id_exchange_segment: 0,
  id_instrument: 0,
  id_isin: '',
  id_market_sector: 0,
  id_market_sector_type: 0,
  id_security_sub_type: '',
  id_security_type: '',
  id_stock: 0,
  in_option: '',
  in_option_auth_cblc: '',
  is_synonymous: false,
  kind_option: '',
  location: '',
  logo_company: '',
  medidor: 0,
  nm_company_exchange: '',
  nm_company_valemobi: '',
  nm_instrument: '',
  opc_ano: '',
  opc_dia: '',
  opc_mes: '',
  opc_strike: '',
  opc_tipo: '',
  option_days_to_expiration: 0,
  order_1: 0,
  order_2: 0,
  pc_composition: 0,
  quotation_form: 0,
  standard_lot: 100,
  stockVariation: {
    day_variation: 0,
    id_point: 0,
    id_stock: 0,
    vl_close: 0,
    vl_high: 0,
    vl_low: 0,
    vl_open: 0,
    volume: 0,
  },
  strike_price: 0,
  synonymous_nickname: '',
  tick_size_denominator: 2,
  tick_size_quantity: 0,
  tp_specification: '',
  type: '',
  type_nearest: '',
  vl_contract_multiplier: 0,
  vl_volume: 0,
  country_image_url: 'BRA',
  stockLabel: '',
  synonymous_related: '',
};

export enum OPTIONS_CONTEXT_MENU_ENUM {
  PREFERENCES = 'PREFERENCES',
  RESET = 'RESET',
}

export const NumberComparator = (
  valueA: any,
  valueB: any,
  nodeA: any,
  nodeB: any,
  isDescending: boolean
) => {
  const intA = parseFloat(valueA);
  const intB = parseFloat(valueB);

  if (intA == intB) return 0;

  if (isDescending) return intA < intB ? -1 : 1;

  return intA > intB ? 1 : -1;
};

export const OptionComparator = (
  valueA: any,
  valueB: any,
  nodeA: any,
  nodeB: any,
  isDescending: boolean
) => {
  const intA = parseInt(valueA.slice(1));
  const intB = parseInt(valueB.slice(1));

  if (valueA == valueB) return 0;

  if (valueA[0] != valueB[0]) {
    if (isDescending) return valueA[0] < valueB[0] ? -1 : 1;

    return valueA[0] > valueB[0] ? 1 : -1;
  }

  if (isDescending) return intA < intB ? -1 : 1;

  return intA > intB ? 1 : -1;
};

export const DEFAULT_OPTIONS_TABS = [
  {
    id: 1,
    editMode: false,
    stock: { cd_stock: 'PETR4', id_exchange: 1, ...DEFAULT_STOCK_VALUES },
  },
  {
    id: 2,
    editMode: false,
    stock: { cd_stock: 'VALE3', id_exchange: 1, ...DEFAULT_STOCK_VALUES },
  },
  {
    id: 3,
    editMode: false,
    stock: { cd_stock: 'BOVA11', id_exchange: 1, ...DEFAULT_STOCK_VALUES },
  },
  {
    id: 4,
    editMode: false,
    stock: { cd_stock: 'ITUB4', id_exchange: 1, ...DEFAULT_STOCK_VALUES },
  },
];

export const HEADER_OPTIONS_OPERATIONS = [
  { title: 'Opção', tooltip: 'Opção' },
  { title: 'Tipo', tooltip: 'Tipo' },
  { title: 'Dt Exp', tooltip: 'Data de vencimento' },
  { title: 'D Exp', tooltip: 'Dias para o vencimento' },
  { title: 'Lado', tooltip: 'Lado' },
  { title: 'Preço', tooltip: 'Preço' },
  { title: 'Qtd', tooltip: 'Quantidade' },
  { title: 'Qtd Neg', tooltip: 'Quantidade negociada' },
  { title: 'Strike', tooltip: 'Preço Strike' },
  { title: 'Delta', tooltip: 'Delta' },
  { title: 'Gamma', tooltip: 'Gamma' },
  { title: 'Rho', tooltip: 'Rho' },
  { title: 'Theta', tooltip: 'Theta' },
  { title: 'Vega', tooltip: 'Vega' },
  { title: 'V.I.', tooltip: 'Volatilidade implicita' },
  { title: 'Volume', tooltip: 'Volume' },
];
