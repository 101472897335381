import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { randomId } from '@shared/rocket-components/utils';
import { RocketTButton, RocketTTypes } from './types/button-type.types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rocket-button',
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host(rocket-button) {
        display: contents;
      }
    `,
  ],
})
export class RocketButtonComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('label') buttonText!: string;
  @Input() type: RocketTButton = 'default';
  @Input() btnType: RocketTTypes = 'button';
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('css') customClass: string = '';
  @Input() isDisabled = false;
  @Input() isSmall = false;
  @Input() loading = false;
  @Input() responsive = false;
  @Input() refId?: string = randomId('rocket_button');
  @Input() tabIndex!: number;
  @Output() rocketClick = new EventEmitter<any>();
  public readonly config = {
    default: 'btn-primary',
    textIcon: 'btn-outline-secondary',
    outline: 'btn-outline-primary',
    fill: 'btn-fill-primary',
    icon: 'btn-icon',
  };
  public readonly responsiveClass = 'pl-2 d-none d-lg-inline';

  public execClick(event: any): void {
    this.rocketClick.emit(event);
  }
}
