import { Component, Input } from '@angular/core';
import { IResumeList } from '../../business-profile.interface';
import { RocketModalService } from '@shared/rocket-components/components';
import { DocumentsModalComponent } from '@shared/documents-modal/documents-modal.component';

@Component({
  selector: 'app-resume-list',
  templateUrl: './resume-list.component.html',
  styleUrls: ['./resume-list.component.scss'],
})
export class ResumeListComponent {
  @Input() lists: IResumeList[] = [];
  @Input() hasEvents: boolean = false;
  @Input() css = 'overflow-y-auto w-100';
  constructor(private modalService: RocketModalService) {}

  documentOpen(doc: any) {
    if (!this.hasEvents) return;
    const ref = this.modalService.open(DocumentsModalComponent, {
      data: {
        event: doc,
      },
      centered: true,
      backdrop: true,
      keyboard: false,
      scrollable: false,
    });

    return ref
  }
}
