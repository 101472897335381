import {
  ApplicationRef,
  ComponentRef,
  EmbeddedViewRef,
  EnvironmentInjector,
  Injectable,
  Type,
  createComponent,
} from '@angular/core';

@Injectable()
export class RocketCreateComponentService {
  constructor(
    private appRef: ApplicationRef,
    private injector: EnvironmentInjector
  ) {}

  create<T>(component: Type<T>) {
    const componentRef = createComponent(component, {
      environmentInjector: this.injector,
    });
    this.appRef.attachView(componentRef.hostView);
    const root = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    componentRef.location.nativeElement.oncontextmenu = () => false;
    return {
      componentRef: componentRef as ComponentRef<T>,
      rootNodeElement: root,
    };
  }

  destroy<T>(componentRef: ComponentRef<T>, calback?: any): void {
    this.appRef.detachView(componentRef.hostView);
    componentRef?.onDestroy(() => {
      if (calback) {
        calback();
      }
    });
    componentRef?.destroy();
    componentRef.hostView?.destroy();
  }
}
