export const FREQUENCY = 10;
export const CFID = '&cfid=f18f19b9-5221-41de-b1f3-e4e96b73faa5';
export const SECRET_KEY = '34e076fd-CHEETAH-PROD-SECRET-99de';
export const CHEETAH_DNS_PROD = 'https://cheetah-pro.rockettrader.com.br';
export const CHEETAH_DNS_STAGE = 'https://cheetah-stage.trademap.com.br';
export const CHEETAH_DNS_PLATAFORMA =
  'https://cheetah-plataforma.trademap.com.br';

export const RECONNECT_ATTEMPTS_TIME = [10000, 30000, 100000];
export const CHEETAH_MAX_DELAY_MESSAGE = 5;
export const CHEETAH_CHANNEL = {
  QUOTE: 'QUOTE',
  INDEX: 'INDEX',
  AUCTION: 'AUCTION',
  ORDER: 'ORDER',
  PORTFOLIO: 'PORTFOLIO',
  MINIBOOK: 'MINIBOOK',
  BOOK_BY_OFFER: 'BOOK_BY_OFFER',
  CANDLE: 'CANDLE',
  MOVERS: 'DEDODURO',
  LIGA: 'LIGA',
  IOSMA: 'IOSMA',
  CUSTODY: 'CUSTODIA',
  TRADE: 'TRADE',
  TRADE_BY_ORDER: 'TRADE_BY_ORDER',
  BOOK: 'BOOK',
  GENERAL: 'GENERAL',
  NOTIFICATION: 'NOTIFICATION',
  CUSTODY_PERFORMANCE: 'CUSTODY_PERFORMANCE',
  NEWS: 'NEWS',
  OPTIONS: 'OPTIONS',
  VOLUME: 'VOLUME',
  EXTERNAL_NEWS: 'EXTERNAL-NEWS',
  SCREENING: 'SCREENING',
  ORDER_CHART: 'ORDER_CHART',
};
export const CHEETAH_STATUS_MESSAGE = {
  CONNECT: 'Online - stream conectado!',
  RECONNECT: 'Conectando - stream pendente!',
  AUTH: 'Conectando - stream conectado!',
  AUTH_ERROR: 'Não authenticado',
  OFFLINE: 'Offline - stream desconectado',
};

export const CHEETAH_STATUS_CLASS = {
  CONNECT: 'cheetah-status connected',
  RECONNECT: 'cheetah-status reconnected',
  AUTH: 'cheetah-status connected',
  AUTH_ERROR: 'cheetah-status auth-error',
  OFFLINE: 'cheetah-status default',
};

export const CHEETAH_CONNECTION_STATUS = {
  CONNECT: 'CONNECT',
  RECONNECT: 'RECONNECT',
  SSE_CREATED: 'SSE_CREATED',
  SSE_CREATED_ERROR: 'SSE_CREATED_ERROR',
  AUTH: 'AUTH',
  AUTH_ERROR: 'AUTH_ERROR',
  OFFLINE: 'OFFLINE',
};

export const BG_FEEDBACK_CHEETAH: any = {
  CONNECT: 'bg-feedback-warning',
  RECONNECT: 'bg-feedback-warning',
  SSE_CREATED: 'bg-feedback-success',
  SSE_CREATED_ERROR: 'bg-feedback-error',
  AUTH: 'bg-feedback-warning',
  AUTH_ERROR: 'bg-feedback-error',
  OFFLINE: 'bg-feedback-negative',
};

export const BG_FEEDBACK_CHEETAH_TITLE: any = {
  CONNECT: 'Reconectando...',
  RECONNECT: 'Reconectando...',
  SSE_CREATED_ERROR: 'Desconectando...',
  AUTH: 'Reconectando...',
  AUTH_ERROR: 'Desconectando...',
  OFFLINE: 'Desconectando...',
};

export const QUOTE_FIELDS_CHEETAH: string =
  'cd_stock,tick_size_denominator,preco_ultimo,preco_fechamento,variacao_dia,variacao_ant_dia,var_fech_anterior,vl_fech_ant_essa_sem,vl_fech_ant_esse_mes,vl_fech_ant_esse_ano,vl_fech_ant_sem,vl_fech_ant_mes,vl_fech_ant_ano,volume,arrow_font_hex,arrow_hex,situacao,preco_maximo,preco_minimo,preco_abertura,precoDiaAnt,qtde_negocios,relatorio_forca_compra,relatorio_forca_venda,tick_size_quantity,variacao_leilao,preco_leilao,qtde_leilao,qtde_leilao_nao_atendida,sentido_leilao_nao_atendida,hora_abert_program,motivo_leilao_headline,vl_var_essa_semana,vl_var_esse_mes,vl_var_esse_ano,vl_var_uma_semana,vl_var_um_mes,vl_var_um_ano,contract_multiplier,valor_melhor_oferta_venda,qtde_melhor_oferta_venda,valor_melhor_oferta_compra,qtde_melhor_oferta_compra,min_price_increment,lote_padrao,nome_companhia';

export const ORDER_CHEETAH_FIELDS =
  'id_ordem,id_investidor,id_corretora,id_conta,seq_valemobi,id_bolsa,id_papel,cd_papel,side,tipo_ordem,preco,preco_ultima_execucao,qtty,qtty_restante,qtty_executada,max_floor,canal,ip,dh_criacao,dh_ultima_atualizacao,time_enforce,status_ordem,price_average,last_price,id_advisor,mercado,personalized_stop_triggered,personalized_new_order_scheduled,personalized_cancel_replace_scheduled,nm_broker_mnemonic,nm_broker,tipo_execucao,preco_medio_exec,data_expiracao,tp_status,ds_order_reject_reason,preco_stop,personalized_loss_trigger,personalized_loss_price,personalized_gain_trigger,personalized_gain_price,is_day_trade,is_reset_position,standard_lot,tick_size_denominator';

export const CANDLE_CHEETAH_FIELDS =
  'id_point,vl_open,vl_high,vl_low,vl_close,vl_volume,tp_bar,remaining_time_millis,qtty_shares_traded';

export const AUCTION_ITEMS = ['LEILAO', 'BMF.LEILAO'];

export const AUCTION_FIELDS = 'key,command';

export const MOVERS_FIELDS =
  'key,command,preco_ultimo,volume,variacao,arrow,arrow_hex,arrow_font_hex,mcap,week_volume,month_volume,year_volume,yesterday_volume,nm_company,company_logo,situacao,valor';

export const BOOK_CHEETAH_FIELDS =
  'preco_ultimo,variacao_dia,preco_abertura,preco_maximo,preco_minimo,volume,relatorio_forca_compra,relatorio_forca_venda,nome';

export const CUSTODIA_CHEETAH_FIELDS =
  'command,cd_stock,qtty_final,key,pnl,pnl_day,pl,id_broker,qtty_buy_day,qtty_sell_day,net_day,open_buy_order,open_sell_order,id_portfolio,vl_price_avg,portfolio_name,id_custodian,custodian_name,id_fund,cd_instrument_type,id_exchange,preco_ultimo,pnl_open,vl_price_avg_daytrade,qtty_final_daytrade,net_day_daytrade,pl_daytrade,pnl_daytrade,pnl_day_daytrade,pnl_open_daytrade,qtty_final_swing,net_day_swing,vl_price_avg_swing,pnl_open_swing,pl_swing,pnl_swing,pnl_day_swing,has_day_trade,has_swing,coverage_price_day_trade,coverage_price_swing,coverage_diff_day_trade,coverage_diff_swing,coverage_price,coverage_percent,coverage_diff,coverage_perc_swing,coverage_perc_day_trade,pnl_open_day_trade_diff,pnl_open_day_trade_perc,pnl_day_swing_perc,pnl_day_swing_diff,pnl_open_perc,pnl_open_diff,pnl_open_swing_diff,pnl_open_swing_perc';

export const RX_CHEETAH_STATUS_CHANNEL = 'CHEETAH_STATUS';

export const TIMES_TRADES_CHEETAH_FIELDS =
  'hr_negocio,hr_negocio_origin,qtde_negociada,preco,mn_broker_compra,mn_broker_venda,trade_condition,agressor_side,multiple_executions,arrow';

export const NOTIFICATION_CHEETAH_FIELDS =
  'id_notification,cd_priority,ds_title,dh_creation,dh_notification,notification_type,status';

export const CUSTODY_PERFORMANCE_FIELDS =
  'key,command,liquid_profit,gross_profit,gross_loss,win_percent,win_operation,loss_operation,zero_operation,total_operation,loss_percent,zero_percent,avg_profit,avg_loss,qtty_open,last_price,avg_price,diff_price,diff_price_perc,open_pnl,vl_total,h_open_qtty,closed_oper,won_pnl,highest_won_operation,loss_pnl,highest_lose_operation,highest_win_streak,highest_loss_streak,mkt_canc_order_buy,mkt_pcanc_order_buy,mkt_exec_order_buy,lmt_open_order_buy,lmt_canc_order_buy,lmt_pcanc_order_buy,lmt_exec_order_buy,stop_open_order_buy,stop_canc_order_buy,stop_pcanc_order_buy,stop_exec_order_buy,stop2_open_order_buy,stop2_canc_order_buy,stop2_pcanc_order_buy,stop2_exec_order_buy,mkt_canc_order_sell,mkt_pcanc_order_sell,mkt_exec_order_sell,lmt_open_order_sell,lmt_canc_order_sell,lmt_pcanc_order_sell,lmt_exec_order_sell,stop_open_order_sell,stop_canc_order_sell,stop_pcanc_order_sell,stop_exec_order_sell,stop2_open_order_sell,stop2_canc_order_sell,stop2_pcanc_order_sell,stop2_exec_order_sell,open_order,open_order_perc,closed_order_perc,executed_order_perc,parc_canceled_order_perc,canceled_order_perc,won_order,loss_order,h_qtty_avg_price,open_operation,open_percent,open_won,total_percent,profit_day_perc,loss_day_perc';

export const VOLUME_FIELDS =
  'key,command,qtty_buyer,qtty_seller,qtty_rlp,qtty_direct,qtty_total';

export const NEWS_FIELDS =
  'agencia,cd_company,data_noticia,hora_noticia,prioridade,nu_noticia,headline,texto,url,lingua,instrumento,ds_channel';

export const OPTIONS_FIELDS =
  'cd_stock,cd_stock_option,days_to_expiration,strike_price,vl_close,in_option,delta,rho,theta,vol_implicitly,gamma,vega,qtde_negocios,qtde_papel_negociado,variacao_dia,volume';

export const EXTERNAL_NEWS_CHEETAH_FIELDS =
  'ds_headline,ds_agency,hr_news,company,dt_news,tp_agency,tags,id_news,id_news_agency,dt_news_formatted';

export const SCREENING_CHEETAH_FIELDS =
  'price,id_point,cd_stock,id_exchange,id_strategy';

export const IOSMA_FIELDS_CHEETAH =
  'vl_open,vl_high,vl_low,vl_close,vl_volume,variation,avg_price,arrow_font_hex,arrow_hex';

export const ORDER_CHART_CHEETAH_FIELDS =
  'key,command,qtty,side,action,tipo_ordem,exec_price,dh_creation,dh_last_update,pnl,pnl_open,is_open,delta_tempo';

export const CHEETAH_CHANNEL_COMMANDS = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};
const pression = ',pressao_compradora,pressao_vendedora';
export const MINI_BOOK_CHEETAH_FIELDS = (limitFilds = 40) => {
  let value: string = '';
  for (let index = 1; index <= limitFilds; index++) {
    value += `compra_qtde${index},compra_valor${index},compra_n_ofertas${index},venda_qtde${index},venda_valor${index},venda_n_ofertas${index},`;
  }
  return `${value.substring(0, value.length - 1)}${pression}`;
};

export const BOOK_BY_OFFER_CHEETAH_FIELDS = (limitFilds = 40) => {
  let value: string = '';
  for (let index = 1; index <= limitFilds; index++) {
    value += `compra_data${index},compra_hora${index},compra_preco${index},compra_qtde${index},compra_id_order${index},compra_nome_corretora${index},compra_id_corretora${index},venda_data${index},venda_hora${index},venda_preco${index},venda_qtde${index},venda_id_order${index},venda_nome_corretora${index},venda_id_corretora${index}`;
  }
  return `${value.substring(0, value.length - 1)}${pression}`;
};

export const TABLE_BOOK_CHEETAH_FIELDS = () => {
  return 'key,command,side,valor,qtde_papel,qtde_acumulada,n_ofertas,hora';
};

// Define a static mapping outside the function.
const FIELDS_MAP: Map<string, any> = new Map([
  ['QUOTE', QUOTE_FIELDS_CHEETAH.split(',')],
  ['CUSTODIA', CUSTODIA_CHEETAH_FIELDS.split(',')],
  ['ORDER', ORDER_CHEETAH_FIELDS.split(',')],
  ['CANDLE', CANDLE_CHEETAH_FIELDS.split(',')],
  ['AUCTION', AUCTION_FIELDS.split(',')],
  ['DEDODURO', MOVERS_FIELDS.split(',')],
  ['MINIBOOK', MINI_BOOK_CHEETAH_FIELDS().split(',')],
  ['BOOK_BY_OFFER', BOOK_BY_OFFER_CHEETAH_FIELDS().split(',')],
  ['NEWS', NEWS_FIELDS.split(',')],
  ['CUSTODY_PERFORMANCE', CUSTODY_PERFORMANCE_FIELDS.split(',')],
  ['TRADE', TIMES_TRADES_CHEETAH_FIELDS.split(',')],
  ['TRADE_BY_ORDER', TIMES_TRADES_CHEETAH_FIELDS.split(',')],
  ['NOTIFICATION', NOTIFICATION_CHEETAH_FIELDS.split(',')],
  ['VOLUME', VOLUME_FIELDS.split(',')],
  ['OPTIONS', OPTIONS_FIELDS.split(',')],
  ['IOSMA', IOSMA_FIELDS_CHEETAH.split(',')],
  ['SCREENING', SCREENING_CHEETAH_FIELDS.split(',')],
  ['ORDER_CHART', ORDER_CHART_CHEETAH_FIELDS.split(',')],
]);

export const getFieldsByChannel = (channel: string = '') => {
  return FIELDS_MAP.get(channel) || '';
};

export function buildServerUrl(baseAddress: any) {
  return `${baseAddress}/streaming`;
}

export function splitMessages(data: any) {
  return data.split('\n');
}

export function serializeMessage(messageData: string) {
  const [channel, item, ...messageParts] = messageData.split('|');
  const dataObject: Record<string, any> = { channel, item };

  if (messageParts.length === 1 && messageParts[0] === 'EOS') {
    return { ...dataObject, key: 'EOS', isEndOfSnap: true };
  }

  const fieldNames = getFieldsByChannel(channel as string);
  messageParts.forEach((part, index) => {
    const fieldName = fieldNames[index];
    if (fieldName) {
      // Garante que o fieldName exista para evitar atribuir undefined
      dataObject[fieldName] = part;
    }
  });

  return dataObject;
}

export function formatSubscribedItems(dataMap: any) {
  const channelItemsMap: any = new Map();
  for (const [key] of dataMap) {
    const [channel, item] = key.split('-');
    if (channelItemsMap.has(channel)) {
      channelItemsMap.get(channel).push(item);
    } else {
      channelItemsMap.set(channel, [item]);
    }
  }
  for (const [channel, items] of channelItemsMap) {
    channelItemsMap.set(channel, [...new Set(items)]);
  }
  return channelItemsMap;
}

export type CheetahDebugState = 'DISABLED' | 'ENABLED';
