import {
  TigerChartIndicatorAveragePriceOptions,
  TTigerChartIndicatorCreateOptions,
} from './indicators.types';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { AveragePriceBase } from './base/average-price-base';

export class TigerChartIndicatorAveragePrice extends AveragePriceBase {
  constructor(options: TigerChartIndicatorAveragePriceOptions) {
    super(options);
  }

  override create(
    options: TTigerChartIndicatorCreateOptions
  ): BaseRenderableSeries[] {
    this.updatePoints();
    return super.create(options);
  }

  override updatePoints(): void {
    this.points = this.service.call('AVGPRICE', {
      close: this.data.vl_close,
      high: this.data.vl_high,
      low: this.data.vl_low,
      open: this.data.vl_open
    });
  }

  override updateSettings() {
    this.points = this.service.call('AVGPRICE', {
      close: this.data.vl_close,
      high: this.data.vl_high,
      low: this.data.vl_low,
      open: this.data.vl_open
    });

    super.updateSettings();
  }
}
