import { TableCellAlign } from '../types/table.type';

export const TABLE_IDS = {
  ROW: 'rt_table_row_',
  CELL: 'rt_table_cell_',
  BTN: 'rt_table_btn_',
};

export const DEFAULT_INIT_CELL = {
  text: '',
  width: 'auto',
  height: '40px',
  ref: undefined,
  refParent: undefined,
  colspan: 1,
  css: '',
  cssContent: '',
  alignText: 'text-left' as TableCellAlign,
  hasChildren: false,
  fixedColumn: false,
  delay: 300,
};
