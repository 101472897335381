export const weightedMovingAverageTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  A média móvel ponderada (WMA) é uma ferramenta de análise técnica que suaviza
  os dados de preços de um ativo financeiro, como a média móvel exponencial
  (MME) e a média móvel simples (MA). No entanto, a WMA atribui um peso
  diferente a cada ponto de dados na série, com base em um esquema de
  ponderação.
</div>
<div class="mb-3">
  Ao contrário da EMA, que dá mais peso aos preços mais recentes usando um fator
  de suavização, a WMA usa uma abordagem de ponderação mais flexível que permite
  que o investidor atribua pesos diferentes aos preços, dependendo da
  importância que deseja dar a cada período.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">A fórmula matemática para a WMA é dada por:</div>
<div class="mb-3">
  WMA = (P1 x w1 + P2 x w2 + ... + Pn x wn) / (w1 + w2 + ... + wn)
</div>
<div class="mb-3">Onde:<br /></div>
<div class="mb-3">
  P1, P2, ..., Pn são os preços dos n períodos usados no cálculo da MMP;<br />
  w1, w2, ..., wn são os pesos correspondentes a cada período.
</div>
<div class="mb-3">
  Os pesos são atribuídos de acordo com o esquema de ponderação escolhido. Por
  exemplo, em uma WMA com esquema de ponderação triangular, os pesos são
  atribuídos em uma progressão aritmética, sendo que o peso mais alto é
  atribuído ao preço mais recente. Em uma WMA com esquema de ponderação
  exponencial, os pesos são atribuídos de acordo com uma progressão geométrica.
</div>
<div class="mb-3">
  A escolha dos pesos depende da estratégia do investidor e pode variar de
  acordo com a volatilidade do ativo e o horizonte de tempo. Por exemplo, se o
  investidor quiser dar mais peso aos preços mais recentes, pode usar uma
  ponderação decrescente, como 1, 2, 3, 4, 5. Se o investidor quiser dar mais
  peso aos preços mais antigos, pode usar uma ponderação crescente, como 5, 4,
  3, 2, 1.
</div>
<div class="mb-3">
  A WMA é útil para suavizar os dados de preços e identificar tendências de
  longo prazo. É comumente usada em análise técnica em combinação com outros
  indicadores, como o Índice de Força Relativa (RSI) e as Bandas de Bollinger,
  para gerar sinais de compra e venda.
</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  A Média Móvel Ponderada (WMA) é uma variação da exponencial. Sua diferença em
  relação a esta está no fato que ela atribui diferentes pesos aos valores que
  fazem parte do cálculo. Isso é feito dando um peso maior aos valores mais
  recentes, o que traz dinamicidade para a visualização de dados. Com isso,
  torna-se mais fácil identificar suportes e resistência ao longo do gráfico.
</div>
<div class="mb-3">
  Com a Média Móvel Ponderada (WMA), os usuários podem ajustar o peso atribuído
  ao indicador, de modo que ele mantenha um peso maior ou menor em relação ao
  preço do dia mais recente. Esse peso é então adicionado a uma porcentagem do
  valor do dia anterior, e a soma dos dois será igual a 100.
</div>
<div class="mb-3">
  <img class="w-75" src="/assets/indicators/wma-como-funciona.png" />
</div>
<div class="fw-bolder">Como configurar</div>
<br />
<div class="fw-bolder">Valores</div>
<div class="text-decoration-underline">Período</div>
<div class="mb-3">
  O período a ser usado no cálculo da média móvel. 9 dias é o padrão.
</div>
<div class="text-decoration-underline">Fonte</div>
<div class="mb-3">
  Determina quais dados de cada barra serão usados nos cálculos. Fechamento é o
  padrão.
</div>
<div class="text-decoration-underline">Deslocamento</div>
<div class="mb-3">
  A alteração desse número moverá a média móvel para frente ou para trás em
  relação ao mercado atual. 0 é o padrão.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/wma-entrada.png"
  />
</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">WMA</div>
<div class="mb-3">
  Pode alternar a visibilidade da WMA. Também pode selecionar a cor, a espessura
  e o estilo da linha do WMA.
</div>
<div class="mb-3">
  <img class="image-indicators-input" src="/assets/indicators/wma-estilo.png" />
</div>
`;
