import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';

import { CHART_COLORS } from '@shared/tiger-chart/colors';
import {
  TData,
  TTooltipIndicatorValue,
  TUpdateData,
} from '@shared/tiger-chart/types/tiger-chart.types';
import {
  BaseIndicator,
  AverageTrueRangeData,
  TigerChartIndicatorAverageTrueRangeOptions,
  TTigerChartIndicatorCreateOptions,
  TTigerChartIndicatorParameter,
  TTigerChartIndicatorRenderSeriesConfig,
} from '../indicators.types';
import {
  TIGER_INDICATORS_ENUM,
  TIGER_INDICATOR_PARAMETER_TYPE,
  TIGER_INDICATOR_SOURCES,
} from '@shared/tiger-chart/enum/tiger-chart.enum';
import { Subject } from 'rxjs';
import { TalibService } from '@shared/tiger-chart/services/talib.service';
import { XyyScaleOffsetFilter } from 'scichart/Charting/Model/Filters/XyyScaleOffsetFilter';
import { XyDataSeries } from 'scichart/Charting/Model/XyDataSeries';
import { FastLineRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastLineRenderableSeries';
import { TIGER_INDICATOR_PRECISIONS } from '../indicators.constants';
import { HorizontalLineAnnotation } from 'scichart/Charting/Visuals/Annotations/HorizontalLineAnnotation';
import { BoxAnnotation } from 'scichart/Charting/Visuals/Annotations/BoxAnnotation';
import { LINE_CONFIG_DICTIONARY } from '../settings-modal/styles-input/styles-input.dictionary';
import { setDefaultConfiguration } from '../indicators.functions';

export class AverageTrueRangeBase implements BaseIndicator {
  protected lineDataSeries!: XyDataSeries;
  protected movingAverageDataSeries!: XyyScaleOffsetFilter;
  protected lineRenderableSeries!: FastLineRenderableSeries;
  protected options!: TigerChartIndicatorAverageTrueRangeOptions;
  protected data: TData;
  protected precision: number = 2;
  protected timePeriod: number = 14;
  protected source: TIGER_INDICATOR_SOURCES = TIGER_INDICATOR_SOURCES.CLOSE;
  protected points: AverageTrueRangeData;
  protected lineColor = CHART_COLORS.FEEDBACK_WARNING;
  protected lineThickness = 1;

  private baseChart!: TWebAssemblyChart;
  lineNumber!: string;
  type: TIGER_INDICATORS_ENUM = TIGER_INDICATORS_ENUM.AVERAGE_TRUE_RANGE;
  yAxisId!: string;
  settings: TTigerChartIndicatorParameter[] = [];
  styles: TTigerChartIndicatorParameter[] = [];
  renderSeriesConfig: TTigerChartIndicatorRenderSeriesConfig[] = [];
  onChange = new Subject<null>();
  service: TalibService;
  mainLineId = 'atr-line';
  isNewOnChart = false;

  get isVisible(): boolean {
    return this.lineRenderableSeries.isVisible;
  }

  set isVisible(visible: boolean) {
    this.lineRenderableSeries.isVisible = visible;
  }

  get propertiesText(): string {
    return `(${this.timePeriod})`;
  }

  constructor(options: TigerChartIndicatorAverageTrueRangeOptions) {
    this.data = options.data;
    this.service = options.service;
    this.precision = options.tickSize;
    this.options = options;
    if (options.timePeriod) this.timePeriod = options.timePeriod;
    if (options.lineColor) this.lineColor = options.lineColor;
    if (options.precision) this.precision = options.precision;
    if (options.lineThickness) this.lineThickness = options.lineThickness;

    this.points = {
      output: [],
    };
    this.renderSeriesConfig = [
      {
        label: 'Linha',
        id: `${this.type}-line-atr`,
        color: this.lineColor,
        propertyColor: 'lineColor',
        thickness: this.lineThickness,
        propertyThickness: 'lineThickness',
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
      },
    ];
    this.settings = [
      {
        label: 'Período',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property: 'timePeriod' as keyof AverageTrueRangeBase,
      },
    ];
    this.styles = [
      {
        type: TIGER_INDICATOR_PARAMETER_TYPE.SELECTION,
        property: 'precision',
        label: 'Precisão',
        values: TIGER_INDICATOR_PRECISIONS,
      },
    ];
    this.lineNumber = options.lineNumber;
  }

  create(options: TTigerChartIndicatorCreateOptions): BaseRenderableSeries[] {
    this.baseChart = options.base;
    const xValues = this.data.id_point;
    const offset = new Array(this.timePeriod).fill(NaN);

    this.lineDataSeries = new XyDataSeries(this.baseChart.wasmContext);
    /* O  TALIB RSI 
      É necessário retirar no final do array do OUTPUT o tamanho do timePeriod,
      pois eles retornam 0 por conta do calculo.
      e como o slice extrai até o tamanho, nao incluindo o fim, nao precisa subtrair 1 do timePeriod.
    */
    this.lineDataSeries.appendRange(xValues, [
      ...offset,
      ...this.points.output.slice(
        0,
        this.data.id_point.length - this.timePeriod
      ),
    ]);

    const lineConfig = this.renderSeriesConfig.find(
      (config) => config.id == `${this.type}-line-atr`
    );
    this.lineRenderableSeries = new FastLineRenderableSeries(
      this.baseChart.wasmContext,
      {
        dataSeries: this.lineDataSeries,
        strokeThickness: lineConfig?.thickness,
        stroke: lineConfig?.color,
        id: `${this.mainLineId}_${this.lineNumber}`,
        xAxisId: options.xAxisId,
      }
    );
    return [this.lineRenderableSeries];
  }

  updatePoints(): void {
    //do nothing.
  }

  append(xValue: number, data: TUpdateData, fullData: TData) {
    this.data = fullData;
    this.updatePoints();
    this.lineDataSeries &&
      this.lineDataSeries.append(
        xValue,
        this.points.output[this.points.output.length - this.timePeriod - 1]
      );
  }

  insertRange(xValues: number[], fullData: TData): void {
    const offset = new Array(this.timePeriod).fill(NaN);
    this.data = fullData;
    this.updatePoints();

    const s = this.baseChart.sciChartSurface.suspendUpdates();
    const arrayLength =
      xValues.length <= this.timePeriod
        ? xValues.length
        : xValues.length - this.timePeriod;
    try {
      if (xValues.length <= this.timePeriod) {
        this.lineDataSeries.insertRange(0, xValues, [
          ...offset.slice(0, arrayLength),
        ]);
      } else {
        this.lineDataSeries.insertRange(0, xValues, [
          ...offset,
          ...this.points.output.slice(0, arrayLength),
        ]);
        for (let index = xValues.length - 1; index < this.timePeriod; index++) {
          this.lineDataSeries.update(index, NaN);
        }
      }

      const indexInitialValue =
        xValues.length <= this.timePeriod
          ? this.timePeriod
          : xValues.length - this.timePeriod;
      for (
        let index = indexInitialValue;
        index < this.points.output.length - this.timePeriod;
        index++
      ) {
        this.lineDataSeries.update(index, this.points.output[index]);
      }
    } finally {
      s.resume();
    }
  }

  changeVisibility(): void {
    this.lineRenderableSeries.isVisible = !this.lineRenderableSeries.isVisible;
  }

  update(index: number, data: TUpdateData, fullData: TData) {
    if (!this.lineDataSeries) {
      return;
    }
    const count = this.lineDataSeries.count();
    if (index > count) {
      return;
    }
    this.data = fullData;
    this.updatePoints();
    this.lineDataSeries.update(
      index,
      this.points.output[this.points.output.length - this.timePeriod - 1]
    );
  }

  updateSettings(): void {
    const s = this.baseChart.sciChartSurface.suspendUpdates();
    const dataSeriesCount = this.lineDataSeries.count();

    const offset = new Array(this.timePeriod).fill(NaN);

    const lineValues = [
      ...offset,
      ...this.points.output.slice(
        0,
        this.data.id_point.length - this.timePeriod
      ),
    ];
    /**
     * o offset padrão(timePeriod) vai como NaN no inicio dos arrays
     *
     */

    if (dataSeriesCount > lineValues.length) {
      this.lineDataSeries.removeRange(
        lineValues.length - 1,
        dataSeriesCount - lineValues.length
      );
    }

    try {
      lineValues.forEach((linePointValue, index) => {
        this.lineDataSeries.update(index, lineValues[index]);
      });
    } finally {
      s.resume();
    }
    this.onChange.next(null);
  }

  private setStyle(
    type: 'line' | 'limit' | 'background',
    id: string,
    config: TTigerChartIndicatorRenderSeriesConfig
  ) {
    const hash: any = {
      line: () => {
        const lineRenderSeries =
          this.baseChart.sciChartSurface.renderableSeries.getById(id);
        if (lineRenderSeries) {
          lineRenderSeries.stroke = !config.active
            ? 'transparent'
            : config.color;
          lineRenderSeries.strokeThickness = config.thickness;
        }
      },
      limit: () => {
        const annotationLimit = this.getHorizontalLine(id);
        if (annotationLimit) {
          annotationLimit.isHidden = !config.active;
          annotationLimit.stroke = config.color;
          if (config.lineTypeId) {
            const lineConfig = LINE_CONFIG_DICTIONARY.get(config.lineTypeId);
            if (lineConfig) {
              annotationLimit.strokeDashArray = lineConfig.dashArray;
            }
          }
        }
      },
      background: () => {
        const annotationBackground = this.getBackgroundBox(id);
        if (annotationBackground) {
          annotationBackground.isHidden = !config.active;
          annotationBackground.fill = `${config.color}21`;
        }
      },
    };
    const func = hash[type];
    if (func) {
      func();
    }
  }

  updateStyles(
    baseChart: TWebAssemblyChart,
    config: TTigerChartIndicatorRenderSeriesConfig
  ): void {
    const hashFunc: any = {
      [`${this.type}-line-atr`]: () => {
        this.setStyle('line', `${this.mainLineId}_${this.lineNumber}`, config);
      },
      [`${this.type}-upper-limit-atr`]: () => {
        this.setStyle('limit', config.id, config);
      },
      [`${this.type}-bottom-limit-atr`]: () => {
        this.setStyle('limit', config.id, config);
      },
      [`${this.type}-background-limit-atr`]: () => {
        this.setStyle('background', config.id, config);
      },
    };
    const func = hashFunc[config.id];
    if (func) {
      func();
    }
    this.onChange.next(null);
  }

  private getHorizontalLine(id: string): HorizontalLineAnnotation {
    return this.baseChart.sciChartSurface.annotations.getById(
      id
    ) as HorizontalLineAnnotation;
  }

  private getBackgroundBox(id: string): BoxAnnotation {
    return this.baseChart.sciChartSurface.annotations.getById(
      id
    ) as BoxAnnotation;
  }

  delete() {
    this.baseChart.sciChartSurface.renderableSeries.remove(
      this.lineRenderableSeries
    );
  }

  setValue(property: keyof this, value: any) {
    const prop = this[property];
    if (typeof this[property] == 'number') {
      this[property] = +value as unknown as typeof prop;
    } else {
      this[property] = value;
    }
    this.updateSettings();
  }

  getValuesByIndex(index?: number): TTooltipIndicatorValue[] {
    if (!this.lineDataSeries) {
      return [];
    }
    const precision = this.precision;
    const lineValues = this.lineDataSeries.getNativeYValues();
    const lineValue = {
      value: lineValues.get(index || lineValues.size() - 1),
      precision,
      color: this.renderSeriesConfig[0].color,
    };
    return [lineValue];
  }

  resetConfiguration(): void {
    setDefaultConfiguration(
      this,
      this.baseChart,
      this.options,
      this.settings,
      this.renderSeriesConfig,
      this.styles,
      this.type
    );
  }
}
