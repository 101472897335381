<ng-container *ngIf="!isNotListPersonal && !rowData.length">
  <div
    class="bg-neutral-strongest d-flex justify-content-center position-absolute w-100"
    [style.height.px]="height - 41"
    [style.z-index]="2"
  >
    <div class="m-auto">
      <rocket-button type="icon" (rocketClick)="addStockOrCreateList()">
        <fla-icon
          name="add"
          size="icon-size-md"
          css="text-brand-primary fw-bold"
        ></fla-icon>
      </rocket-button>
    </div>
  </div>
</ng-container>

<ng-container
  *ngIf="
    !isListAuction &&
    (loadingMovers || (displayEmptyDynamicList && !movers.size()))
  "
>
  <div
    class="pt-3 d-flex flex-column align-items-center gap-2 w-100 position-absolute bg-neutral-strongest loading-index"
    [style.height.px]="height - 50"
  >
    <ng-container *ngIf="loadingMovers; else emptyList">
      <span class="fs-6">Carregando Movers, por favor aguarde.</span>
      <div class="spinner-border text-brand-primary" role="status"></div>
    </ng-container>
    <ng-template #emptyList>
      <span class="d-block text-center mt-3 fs-5">
        O mercado está fechado, tente novamente mais tarde.
      </span>
    </ng-template>
  </div>
</ng-container>

<ng-container *ngIf="withoutList && !isNotListPersonal">
  <div
    class="text-center pt-3 px-1 d-flex flex-column align-items-center gap-2 w-100 position-absolute bg-neutral-strongest without-list-index"
    [style.height.px]="height - 50"
  >
    <span class="fs-5"> Você ainda não possui listas pessoais criadas. </span>
    <span class="fs-5 my-1">
      Crie sua primeira lista clicando no botão abaixo
    </span>
    <button
      type="button"
      class="btn btn-primary btn-sm"
      (click)="sendEventToOpenCreateListModal()"
      [disabled]="openCreateList"
    >
      Criar nova lista
    </button>
  </div>
</ng-container>

<app-stock-table-header
  [refComponent]="refComp"
  [componentId]="componentId"
  [component]="component"
  [idList]="idListSelect"
  [isNotListPersonal]="isNotListPersonal"
  [linked]="link"
  [toggleDPVisibility]="toggleDP"
  [listStocks]="listStocks"
  [listSelected]="listSelected"
  [listStockInfo]="listStockInfo"
  [list]="list"
  [initialStockTableType]="initialStockTableType"
  [isChangeViewStep]="isChangeViewStep"
  [dynamicInitialList]="dynamicInitialList"
  [openCreateList]="openCreateList"
  [currentMoversIndex]="moversIndex"
  [currentMoversPeriod]="moversPeriod"
  [currentAuctionIndex]="auctionIndex"
  [presetListName]="presetListName"
  [isIndex]="isIndex"
  [globalStockInfos]="globalStockInfos"
  (changeListSelected)="onListSelected($event)"
  (changeNotListPersonal)="selectListNotPersonal($event)"
  (changePresetList)="selectPresetList($event)"
  (changeName)="onChangeName($event)"
  (getAllLists)="getAllLists($event, false)"
  (changeStockTableView)="changeStockTableView($event)"
  (updateAuctionIndex)="handleAuctionIndex($event)"
  (displayIndexStocks)="handleIndexStocks($event)"
></app-stock-table-header>

<div [ngClass]="isDesktop ? 'component-height' : 'h-100'">
  <ng-container *ngIf="currentView === STOCK_TABLE_VIEWS.GRID">
    <rocket-grid
      *ngIf="dropZoneParams"
      height="100%"
      [refComponent]="refComp"
      [columnDefs]="columnDefs"
      [rowData]="rowData"
      [columnVisible]="columnVisible"
      [addRow]="addRow"
      [onUpdateField]="onUpdateField"
      [animateRows]="true"
      [rowDragManaged]="!isNotListPersonal"
      [rowDragEntireRow]="true"
      [rowDragText]="rowDragText"
      [dropZoneParams]="dropZoneParams"
      [suppressDragLeaveHidesColumns]="true"
      [isConfig]="true"
      [noRowsTemplate]="noRowsTemplate"
      [globalCdStock]="globalStockInfos.cd_stock"
      fieldIndexer="idRow"
      rowSelection="single"
      (onGridReady)="onGridReady($event)"
      (flaRowMoved)="rowMoved($event)"
      (flaColumnMoved)="columnMoved($event)"
      (flaColumnResized)="columnResized($event)"
      (flaSelectRow)="selectRow($event)"
    ></rocket-grid>
  </ng-container>

  <ng-container
    *ngIf="
      currentView !== STOCK_TABLE_VIEWS.GRID &&
      currentView !== STOCK_TABLE_VIEWS.HEATMAP
    "
  >
    <app-stock-table-views
      [link]="link"
      [tableView]="currentView"
      [refComponent]="refComp"
      [componentId]="componentId"
      [stockListId]="idListSelect"
      [isNotListPersonal]="isNotListPersonal"
      [stocks]="rowData"
      [globalStock]="globalStockInfos"
      [handlerQuoteUpdate]="stockToUpdate"
      [stockToRemoveFromList]="stockToRemove"
      [moversCommand]="moversCommand"
      [updateMoversSort]="updateMoversSort"
      [clearStocks]="clearStocks"
      [period]="moversPeriod"
      [noDataTemplate]="noRowsTemplate"
      [focused]="focused"
      [auctionCommand]="auctionCommand"
      (stockClicked)="selectRow($event)"
      (removeStock)="removeRow($event)"
      (updateStocksPosition)="onStockPositionChange($event)"
    >
    </app-stock-table-views>
  </ng-container>

  <ng-container *ngIf="currentView === STOCK_TABLE_VIEWS.HEATMAP">
    <app-stock-table-heatmap
      [refComponent]="refComp"
      [width]="width"
      [height]="height"
      [linked]="link"
      [stockList]="rowData"
      [idStockList]="idListSelect"
      [updateStock]="stockToUpdate"
      [isNotListPersonal]="isNotListPersonal"
      [handlerStockListByCommand]="moversCommand"
      [clearStocks]="clearStocks"
      [noDataTemplate]="noRowsTemplate"
    ></app-stock-table-heatmap>
  </ng-container>
</div>
