import { ChartModifierBase2D } from 'scichart/Charting/ChartModifiers/ChartModifierBase2D';
import { EChart2DModifierType } from 'scichart/types/ChartModifierType';
import { ModifierMouseArgs } from 'scichart/Charting/ChartModifiers/ModifierMouseArgs';
import { testIsInBounds } from 'scichart/utils/pointUtil';
import { ClickableAnnotation } from './clickable-annotation';
import { Subject, Subscription, auditTime, filter } from 'rxjs';
import { TMouseEventData } from '../types/tiger-chart.types';
import { TIGER_MOUSE_EVENT_DATA_TYPE } from '../enum/tiger-chart.enum';
import { CANDLE_IDS } from '../constants/tiger-chart.constants';
import { IRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/IRenderableSeries';
import { Point } from 'scichart/Core/Point';
import { HitTestInfo } from 'scichart/Charting/Visuals/RenderableSeries/HitTest/HitTestInfo';
import { DrawTools } from '../draw-tools/draw-tools.interface';
import { TigerMiddleChartService } from '../services/tiger-middle-chart.service';
import { StockChartModalService } from '@shared/components/stock-chart/parts/modal-more-options/service/stock-chart-modal.service';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { TIGER_CHART_TOOL } from '../tiger-chart-tools/tiger-chart-tools.interface';
import { DpiHelper } from 'scichart/Charting/Visuals/TextureManager/DpiHelper';
import { BaseStackedCollection } from 'scichart/Charting/Visuals/RenderableSeries/BaseStackedCollection';
import { EVENTS } from '../tiger-chart-events-modal/tiger-chart-events-modal.const';
import { CategoryCoordinateCalculator, IAnnotation } from 'scichart';
import { ExecOrderAnnotation } from './exec-order-annotation';
import { TOOLS } from '../tiger-chart-tools/tiger-chart-tools.const';
import { TOOLS_ENUM } from '../tiger-chart-tools/tiger-chart-tools.enum';

export class MouseEventsModifier extends ChartModifierBase2D {
  readonly type: EChart2DModifierType = EChart2DModifierType.Custom;
  private events$ = new Subject<TMouseEventData | null>();
  private cursorEvents$ = new Subject<{
    cursor: string;
    inBoundsY: boolean;
    inBoundsX: boolean;
  }>();
  private clickEvents$ = new Subject<TMouseEventData | null>();
  private drawAnnotationHoverEvents$ = new Subject<{ draw: DrawTools }>();
  private execOrderAnnotationHoverEvents$ = new Subject<{
    execOrderAnnotations: ExecOrderAnnotation[];
    anotherHover: boolean;
    mouseEvent: MouseEvent;
  }>();
  private hitSeriesEvents$ = new Subject<TMouseEventData>();
  private _xAxisId = '';
  private _annotatioHovered!: ClickableAnnotation | null;
  private mouseDown: boolean = false;
  private timeoutRef!: NodeJS.Timeout | number;
  private _toolSelectedSub!: Subscription;
  private _drawTool!: Subscription;
  private selectedTool!: TIGER_CHART_TOOL | null;
  private selectedDrawAnnotation!: DrawTools | undefined;
  private hitStacked: boolean = false;
  private readonly EVENT_DELAY = 90;
  private readonly DEFAULT_DELAY = 80;
  private _refComponent!: string;

  constructor(
    private middleChartService: TigerMiddleChartService,
    private stockChartModalService: StockChartModalService,
    private stockChartService: StockChartService,
    _refComponent: string,
    xAxisId: string
  ) {
    super();
    this._xAxisId = xAxisId;
    this._refComponent = _refComponent;
    this._toolSelectedSub = this.stockChartService.toolSelected$.subscribe(
      (tool) => {
        if (tool.componentRef === this._refComponent) {
          if (!tool.toolEnum) {
            this.selectedTool = null;
          }
          if (tool.tool) {
            this.selectedTool = tool.tool;
          }
        }
      }
    );
    this._drawTool = this.stockChartService.drawToolHover$
      .pipe(filter((data) => data.componentRef === this._refComponent))
      .subscribe((data) => {
        this.selectedDrawAnnotation = data.draw;
      });
  }

  onEvents = () => this.events$.pipe(auditTime(this.EVENT_DELAY));
  onCursorEvents = () => this.cursorEvents$.pipe(auditTime(this.EVENT_DELAY));
  onClickEvents = () => this.clickEvents$.pipe(auditTime(0));
  onDrawAnnotationHoverEvents = () =>
    this.drawAnnotationHoverEvents$.pipe(auditTime(this.EVENT_DELAY));
  onExecOrderAnnotationHoverEvents = () =>
    this.execOrderAnnotationHoverEvents$.pipe(auditTime(this.EVENT_DELAY / 2));
  onHitSeriesEvents = () =>
    this.hitSeriesEvents$.pipe(auditTime(this.DEFAULT_DELAY));

  override modifierMouseWheel(args: ModifierMouseArgs): void {
    super.modifierMouseWheel(args);
    this.dispatchEvents(TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_UNGRAB);
  }

  override modifierMouseMove(args: ModifierMouseArgs) {
    super.modifierMouseMove(args);
    if (!this.isAttached) {
      throw new Error(
        'Should not call DetectClicksOnChartPartsModifier.modifierMouseDown if not attached'
      );
    }
    const mousePoint = args.mousePoint;
    const yAxis = this.parentSurface.yAxes.getById('DefaultAxisId');
    const xAxis = this.parentSurface.xAxes.getById(this._xAxisId);
    const rectYAxis = yAxis?.viewRect;
    const rectXAxis = xAxis?.viewRect;
    if (!yAxis || !xAxis || !rectYAxis || !rectXAxis) return;
    const inBoundsY = testIsInBounds(
      mousePoint.x,
      mousePoint.y,
      rectYAxis.left,
      rectYAxis.bottom,
      rectYAxis.right,
      rectYAxis.top
    );
    const inBoundsX = testIsInBounds(
      mousePoint.x,
      mousePoint.y,
      rectXAxis.left,
      rectXAxis.bottom,
      rectXAxis.right,
      rectXAxis.top
    );
    if (inBoundsY || inBoundsX) {
      this.cursorEvents$.next({
        cursor: inBoundsY ? 'ns-resize' : 'ew-resize',
        inBoundsX,
        inBoundsY,
      });
      return;
    }
    const rSeriesMain = this.parentSurface?.renderableSeries.getById(
      CANDLE_IDS.MAIN_SERIE
    );
    if (!this.parentSurface) return;

    const { left, right, top, bottom } = this.parentSurface.seriesViewRect;
    if (testIsInBounds(mousePoint.x, mousePoint.y, left, bottom, right, top)) {
      let isHit = false;
      let testDataHit!: HitTestInfo;
      let series!: IRenderableSeries;
      const mouseEvent = args.nativeEvent;
      const premultipliedX = mouseEvent.offsetX * DpiHelper.PIXEL_RATIO;
      const premultipliedY = mouseEvent.offsetY * DpiHelper.PIXEL_RATIO;
      let clickableAnnotation!: ClickableAnnotation;
      let drawAnnotation!: DrawTools;
      let execOrderAnnotations!: ExecOrderAnnotation[];
      if (rSeriesMain) {
        const annotations = this.getAnnotations(args);
        clickableAnnotation = annotations.clickableAnnotation;
        drawAnnotation = annotations.drawAnnotation;
        execOrderAnnotations = annotations.execOrderAnnotations;
      }

      this.parentSurface?.renderableSeries
        .asArray()
        .filter((rSeries) => rSeries.isVisible)
        .forEach((rSeries: any) => {
          if (
            rSeries.id !== CANDLE_IDS.MAIN_SERIE &&
            !this._xAxisId.includes('sub_chart')
          ) {
            if (rSeries.isStacked) {
              this.hitStacked = false;
              const stacked = rSeries as BaseStackedCollection<any>;
              stacked.getVisibleSeries().forEach((item) => {
                const testData = item.hitTestProvider.hitTest(
                  premultipliedX,
                  premultipliedY
                );
                if (testData.isHit) {
                  testDataHit = testData;
                  series = item;
                }
                isHit = testData.isHit || isHit;
              });
            } else {
              const testData = rSeries.hitTestProvider.hitTest(
                premultipliedX,
                premultipliedY
              );
              if (testData.isHit) {
                testDataHit = testData;
                series = rSeries;
              }
              isHit = testData.isHit || isHit;
            }
          } else {
            try {
              this.hitTestXSlice(rSeries, mousePoint);
            } catch (e: any) {
              this.hitTestDataPoint(rSeries, mousePoint);
            }
          }
        });
      let type = TIGER_MOUSE_EVENT_DATA_TYPE.INDICATOR_LEAVE;
      if (isHit) {
        type = TIGER_MOUSE_EVENT_DATA_TYPE.INDICATOR_HOVER;
        this.hitStacked = true;
      } else {
        this.hitStacked = false;
      }
      if (
        !clickableAnnotation &&
        !drawAnnotation &&
        (!execOrderAnnotations || !execOrderAnnotations.length)
      ) {
        this.hitSeriesEvents$.next({
          type,
          data: testDataHit?.dataSeriesIndex,
          renderableSeries: series,
          hitTestInfo: testDataHit,
        });
      }

      const rSeries = this.parentSurface?.renderableSeries.getById(
        CANDLE_IDS.MAIN_SERIE
      );
      if (!rSeries) {
        return;
      }
      if (!clickableAnnotation) {
        this.cursorEvents$.next({
          cursor: inBoundsY ? 'ns-resize' : 'ew-resize',
          inBoundsX,
          inBoundsY,
        });
        this.drawAnnotationHoverEvents$.next({ draw: drawAnnotation });
      }
      this.execOrderAnnotationHoverEvents$.next({
        execOrderAnnotations,
        anotherHover: !!clickableAnnotation,
        mouseEvent,
      });

      if (clickableAnnotation) {
        clickableAnnotation.customHover(
          clickableAnnotation,
          mousePoint.x,
          mousePoint.y
        );
        this._annotatioHovered = clickableAnnotation;
        this.dispatchEvents(
          TIGER_MOUSE_EVENT_DATA_TYPE.ANNOTATION_HOVER,
          {
            hovered: true,
            usePointer: clickableAnnotation.id.includes(EVENTS),
            isEditable:
              clickableAnnotation.isEditable ||
              clickableAnnotation.id.includes(EVENTS) ||
              (clickableAnnotation.id.startsWith('ID_INVESTOR') &&
                mousePoint.x >
                  clickableAnnotation.getAnnotationBorders().x2 - 10),
          },
          undefined,
          mousePoint,
          rSeries.id
        );
      } else if (this._annotatioHovered) {
        this.dispatchEvents(
          TIGER_MOUSE_EVENT_DATA_TYPE.ANNOTATION_HOVER,
          {
            hovered: false,
            isEditable:
              this._annotatioHovered.isEditable ||
              (this._annotatioHovered.id.startsWith('ID_INVESTOR') &&
                mousePoint.x >
                  this._annotatioHovered.getAnnotationBorders().x2 - 10),
            anotherHover: !!execOrderAnnotations,
          },
          undefined,
          mousePoint,
          rSeries.id
        );
        this._annotatioHovered.customHover();
        this._annotatioHovered = null;
      }
    }
  }

  override modifierMouseEnter(args: ModifierMouseArgs): void {
    this.dispatchEvents(TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_ENTER, {
      id: args.target?.id,
    });
  }

  override modifierMouseLeave(args: ModifierMouseArgs) {
    super.modifierMouseLeave(args);
    if (!this.isAttached) {
      throw new Error(
        'Should not call DetectClicksOnChartPartsModifier.modifierMouseLeave if not attached'
      );
    }
    const mousePoint = args.mousePoint;
    const { left, right, top, bottom } = this.parentSurface.seriesViewRect;
    if (!testIsInBounds(mousePoint.x, mousePoint.y, left, bottom, right, top)) {
      this.dispatchEvents(
        TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_LEAVE_CANVAS,
        true
      );
    }
  }

  override modifierMouseUp(args: ModifierMouseArgs): void {
    super.modifierMouseUp(args);
    this.mouseDown &&
      this.dispatchClickEvents(TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_UNGRAB);
    clearTimeout(this.timeoutRef);
    //const configuration = this.stockChartModalService.getGlobalChartConfigs();
    //if (configuration.fastRulerNavigation && !this.mouseDown) {
    if (!this.mouseDown) {
      this.holdClick(args);
    }
    this.mouseDown = false;
  }

  override modifierMouseDown(args: ModifierMouseArgs): void {
    super.modifierMouseDown(args);

    const configuration = this.stockChartModalService.getGlobalChartConfigs();
    if (!this.selectedTool && configuration.showFastRuler) {
      this.selectedTool = TOOLS.get(TOOLS_ENUM.TEMPORARY)![0];
    }
    //if (configuration.fastRulerNavigation && !this.selectedTool) {
    //if (!this.selectedTool && !configuration.showFastRuler) {
    if (!this.selectedTool) {
      this.timeoutRef = setTimeout(() => {
        this.mouseDown = true;
        const annotation = this.clickedOnAnnotation(args);
        if (!annotation) {
          let event = TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_GRAB;
          let hitTestInfo!: HitTestInfo;
          //https://developer.mozilla.org/en-US/docs/Web/API/Pointer_events#determining_button_states
          if (args.button === 1) {
            const rSeriesMain = this.parentSurface?.renderableSeries.getById(
              CANDLE_IDS.MAIN_SERIE
            );
            const mouseEvent = args.nativeEvent;
            const premultipliedX = mouseEvent.offsetX * DpiHelper.PIXEL_RATIO;
            const premultipliedY = mouseEvent.offsetY * DpiHelper.PIXEL_RATIO;
            hitTestInfo = rSeriesMain.hitTestProvider.hitTest(
              premultipliedX,
              premultipliedY
            );
            event = TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_MIDDLE_MOUSE_CLICK;
          }
          this.dispatchClickEvents(
            event,
            undefined,
            hitTestInfo,
            args.mousePoint
          );
        }
      }, 50);
    } else {
      this.holdClick(args);
    }
  }

  private holdClick(args: ModifierMouseArgs) {
    const configuration = this.stockChartModalService.getGlobalChartConfigs();
    if (
      !configuration.showFastRuler &&
      !this.selectedDrawAnnotation &&
      !this.selectedTool
    ) {
      this.mouseDown = true;
      const annotation = this.clickedOnAnnotation(args);
      if (!annotation) {
        this.dispatchClickEvents(TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_GRAB);
      }
    } else {
      if (args.button !== 0 || isNullOrUndefined(this.middleChartService)) {
        return;
      }
      const configuration = this.stockChartModalService.getGlobalChartConfigs();
      const mousePoint = args.mousePoint;
      const annotation = this.getClickableAnnotation(mousePoint);
      if (!this.mouseDown) {
        this.mouseDown = true;
        if ((configuration.showFastRuler || this.selectedTool) && !annotation) {
          const { hitTestMiddleXInfo, hitTestMiddleYInfo, rSeries } =
            this.middleChartService.getMiddleChart(
              this.parentSurface,
              mousePoint
            );
          if (!hitTestMiddleXInfo || !hitTestMiddleYInfo || !rSeries) {
            return;
          }
          const hitTestInfo = rSeries.hitTestProvider.hitTestXSlice(
            mousePoint.x,
            mousePoint.y
          );
          this.dispatchClickEvents(
            TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_MOUSE_CLICK,
            undefined,
            hitTestInfo,
            mousePoint,
            '',
            hitTestMiddleXInfo.hitTestPointValues,
            hitTestMiddleYInfo.hitTestPointValues
          );
          return;
        }
        this.clickedOnAnnotation(args);
      } else {
        this.dispatchClickEvents(
          TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_UNGRAB
        );
      }
    }
  }

  private hitTestXSlice(rSeries: IRenderableSeries, mousePoint: Point) {
    const hitTestInfo = rSeries.hitTestProvider.hitTestXSlice(
      mousePoint.x,
      mousePoint.y
    );
    this.hitTestInfo(hitTestInfo, rSeries, mousePoint);
  }

  private hitTestDataPoint(rSeries: IRenderableSeries, mousePoint: Point) {
    const hitTestInfo = rSeries.hitTestProvider.hitTestDataPoint(
      mousePoint.x,
      mousePoint.y
    );
    this.hitTestInfo(hitTestInfo, rSeries, mousePoint);
  }

  private hitTestInfo(
    hitTestInfo: HitTestInfo,
    rSeries: IRenderableSeries,
    mousePoint: Point
  ) {
    if (hitTestInfo.isHit) {
      this.dispatchEvents(
        TIGER_MOUSE_EVENT_DATA_TYPE.X_DATASERIES_HOVER,
        hitTestInfo.dataSeriesIndex,
        hitTestInfo,
        mousePoint,
        rSeries.id
      );
    } else {
      this.dispatchEvents(
        TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_LEAVE,
        undefined,
        hitTestInfo,
        mousePoint,
        rSeries.id
      );
    }
  }

  private getAnnotations(args: ModifierMouseArgs): {
    drawAnnotation: DrawTools;
    clickableAnnotation: ClickableAnnotation;
    execOrderAnnotations: ExecOrderAnnotation[];
  } {
    const mouseEvent = args.nativeEvent;
    const premultipliedX = mouseEvent.offsetX * DpiHelper.PIXEL_RATIO;
    const premultipliedY = mouseEvent.offsetY * DpiHelper.PIXEL_RATIO;
    let drawAnnotation!: DrawTools;
    let clickableAnnotation!: ClickableAnnotation;
    const execOrderAnnotations: ExecOrderAnnotation[] = [];
    const mousePoint = args.mousePoint;
    const rSeries = this.parentSurface?.renderableSeries.getById(
      CANDLE_IDS.MAIN_SERIE
    );
    const testData = rSeries.hitTestProvider.hitTest(
      premultipliedX,
      premultipliedY
    );
    const annotationArray = this.parentSurface?.annotations.asArray();
    for (let i = annotationArray.length - 1; i >= 0; i--) {
      const annotation = annotationArray[i];
      if (
        !clickableAnnotation &&
        this.isClickableAnnotation(annotation, mousePoint)
      ) {
        clickableAnnotation = annotation as ClickableAnnotation;
      }
      if (this.isExecOrderAnnotation(annotation, testData)) {
        execOrderAnnotations.push(annotation as ExecOrderAnnotation);
      }
      if (
        !drawAnnotation &&
        this.isDrawAnnotation(annotation, testData, mousePoint)
      ) {
        drawAnnotation = annotation as DrawTools;
      }
    }
    return { drawAnnotation, clickableAnnotation, execOrderAnnotations };
  }

  private getClickableAnnotation(mousePoint: Point): ClickableAnnotation {
    return this.parentSurface?.annotations
      .asArray()
      .reverse()
      .find((annotation) => {
        if (!(annotation instanceof ClickableAnnotation) || annotation.isHidden)
          return;

        return annotation.checkIsClickedOnAnnotation(
          mousePoint.x,
          mousePoint.y
        );
      }) as ClickableAnnotation;
  }

  private isDrawAnnotation(
    annotation: IAnnotation,
    testData: HitTestInfo,
    mousePoint: Point
  ): boolean {
    if (!(annotation as DrawTools).isDraw) return false;
    if (annotation.isSvgAnnotation && annotation.type != 'SVGTextAnnotation') {
      if ((annotation as DrawTools).tool.hasBoxAux) {
        return false;
      }
      const x1 = Math.min(annotation.x1, annotation.x2);
      const y1 = Math.min(annotation.y1, annotation.y2);
      const x2 = Math.max(annotation.x1, annotation.x2);
      const y2 = Math.max(annotation.y1, annotation.y2);
      return (
        testData.hitTestPointValues.x >= x1 &&
        testData.hitTestPointValues.x <= x2 &&
        testData.hitTestPointValues.y >= y1 &&
        testData.hitTestPointValues.y <= y2
      );
    }
    return annotation.checkIsClickedOnAnnotation(mousePoint.x, mousePoint.y);
  }

  private isClickableAnnotation(
    annotation: IAnnotation,
    mousePoint: Point
  ): boolean {
    if (!(annotation instanceof ClickableAnnotation) || annotation.isHidden) {
      return false;
    }
    return annotation.checkIsClickedOnAnnotation(mousePoint.x, mousePoint.y);
  }

  private isExecOrderAnnotation(
    annotation: IAnnotation,
    testData: HitTestInfo
  ): boolean {
    if (!(annotation instanceof ExecOrderAnnotation) || annotation.isHidden) {
      return false;
    }
    const borders = annotation.getAnnotationBorders();
    const diffY = Math.abs(borders.y1 - borders.y2);
    const diffX = Math.abs(borders.x1 - borders.x2);
    return (
      testData.hitTestPoint.x >= borders.x1 - diffX &&
      testData.hitTestPoint.x <= borders.x2 &&
      testData.hitTestPoint.y >= borders.y1 - diffY &&
      testData.hitTestPoint.y <= borders.y2
    );
  }

  private indicatorTest(args: ModifierMouseArgs, series: IRenderableSeries) {
    const mouseEvent = args.nativeEvent;
    const premultipliedX = mouseEvent.offsetX * DpiHelper.PIXEL_RATIO;
    const premultipliedY = mouseEvent.offsetY * DpiHelper.PIXEL_RATIO;
    let testData!: HitTestInfo;
    try {
      testData = series.hitTestProvider.hitTest(premultipliedX, premultipliedY);
    } catch (e: any) {
      return;
    }
    let type = TIGER_MOUSE_EVENT_DATA_TYPE.INDICATOR_LEAVE;
    if (testData.isHit) {
      this.hitStacked = series.isStacked;
      type = TIGER_MOUSE_EVENT_DATA_TYPE.INDICATOR_HOVER;
    } else {
      this.hitStacked = false;
    }
    this.hitSeriesEvents$.next({
      type,
      data: testData.dataSeriesIndex,
      renderableSeries: series,
      hitTestInfo: testData,
    });
  }

  private clickedOnAnnotation(args: ModifierMouseArgs) {
    const mousePoint = args.mousePoint;
    if (isNullOrUndefined(this.middleChartService)) return;
    const { hitTestMiddleXInfo, hitTestMiddleYInfo, rSeries } =
      this.middleChartService.getMiddleChart(this.parentSurface, mousePoint);
    if (!hitTestMiddleXInfo || !hitTestMiddleYInfo || !rSeries) {
      return;
    }
    const hitTestInfo = rSeries.hitTestProvider.hitTestXSlice(
      mousePoint.x,
      mousePoint.y
    );
    const annotation = this.getClickableAnnotation(mousePoint);
    if (annotation) {
      this.dispatchEvents(
        TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_MOUSE_CLICK,
        { annotation, isAnnotation: true, mouseEvent: args.nativeEvent },
        hitTestInfo,
        mousePoint,
        ''
      );
      return annotation;
    } else {
      this.dispatchClickEvents(
        TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_MOUSE_CLICK
      );
    }
    return;
  }

  private dispatchEvents(
    type: TIGER_MOUSE_EVENT_DATA_TYPE,
    data?: any,
    hitTestInfo?: HitTestInfo,
    mousePoint?: Point,
    id?: string
  ) {
    if (hitTestInfo && hitTestInfo.hitTestPointValues) {
      hitTestInfo = this.updateXCategoryHitTest(hitTestInfo);
    }
    this.events$.next({
      type,
      data,
      pointValues: hitTestInfo?.hitTestPointValues,
      point: hitTestInfo?.hitTestPoint,
      mousePoint,
      hitTestInfo: hitTestInfo,
      id,
      isFromPrincipalChart: id === CANDLE_IDS.MAIN_SERIE,
      xCategoryValue: hitTestInfo?.xCategoryValue,
    });
  }

  private dispatchClickEvents(
    type: TIGER_MOUSE_EVENT_DATA_TYPE,
    data?: any,
    hitTestInfo?: HitTestInfo,
    mousePoint?: Point,
    id?: string,
    middleXPointValues?: Point,
    middleYPointValues?: Point
  ) {
    if (hitTestInfo && hitTestInfo.hitTestPointValues) {
      hitTestInfo = this.updateXCategoryHitTest(hitTestInfo);
    }
    this.clickEvents$.next({
      type,
      data,
      pointValues: hitTestInfo?.hitTestPointValues,
      point: hitTestInfo?.hitTestPoint,
      mousePoint,
      hitTestInfo: hitTestInfo,
      id,
      isFromPrincipalChart: id === CANDLE_IDS.MAIN_SERIE,
      xCategoryValue: hitTestInfo?.xCategoryValue,
      middleXPointValues,
      middleYPointValues,
    });
  }

  private updateXCategoryHitTest(hitTestInfo: HitTestInfo): HitTestInfo {
    const xAxis = this.parentSurface.xAxes.getById(this._xAxisId);
    const calculator =
      xAxis.getCurrentCoordinateCalculator() as CategoryCoordinateCalculator;
    hitTestInfo.xCategoryValue = parseInt(
      calculator
        .transformIndexToData(hitTestInfo.hitTestPointValues.x)
        .toString()
    );
    return hitTestInfo;
  }
}
