import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IStockAuctionInfos } from '../../interfaces/stock-table-views.interface';
import { IGlobalStock } from '@shared/components/stock-table/interfaces/stock-table.interfaces';
import { isRowSelectedStock } from 'src/app/utils/utils.functions';
@Component({
  selector: 'app-stock-table-view-minicard',
  templateUrl: './stock-table-view-minicard.component.html',
  styles: [
    `
      .minicard {
        width: 100%;
        height: 41px;
        border-radius: 4px;

        &.with-remove-icon {
          height: 55px;
        }

        &:hover {
          background-color: rgba(var(--vm-neutral-medium-rgb), 0.5);
        }
      }

      .pointer {
        cursor: pointer;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockTableViewMinicardComponent implements OnChanges {
  @Output() public stockSelected: EventEmitter<void> = new EventEmitter();
  @Output() public removeStock: EventEmitter<void> = new EventEmitter();

  @Input() public displayRemoveStock: boolean = false;
  @Input() public name: string = '';
  @Input() public cdStock: string = '';
  @Input() public variation: string = '';
  @Input() public variationBgColor: string = '';
  @Input() public variationTextColor: string = '';
  @Input() public globalStock: string = '';
  @Input() public componentId: string = '';
  @Input() public companyName: string = '';
  @Input() public situation!: string;
  @Input() public auctionInfos!: IStockAuctionInfos | null;
  @Input() globalStockSelected!: IGlobalStock;
  public titleColor: 'text-brand-primary' | 'text-white' = 'text-white';

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { name, globalStock } = changes;
    if (globalStock?.currentValue || name?.currentValue)
      this._verifyIsGlobalStock();
  }

  private _verifyIsGlobalStock(): void {
    if (this.globalStock && this.name)
      this.titleColor = isRowSelectedStock(
        this.cdStock,
        this.globalStockSelected.cd_stock,
        this.globalStockSelected.cd_stock_order,
        this.globalStockSelected.synonymous_related
      )
        ? 'text-brand-primary'
        : 'text-white';
    else this.titleColor = 'text-white';
    this._cdr.detectChanges();
  }
}
