import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import {
  ISearchStock,
  IStockAcquisitionsInfos,
  IStocksAcquisitions,
} from '@core/interface';
import { StockService } from '@shared/services/api/trademap/v1/stock.service';

@Component({
  selector: 'app-repurchase',
  templateUrl: './repurchase.component.html',
  styleUrls: ['./repurchase.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepurchaseComponent {
  @Input() set stockSelected(stockSelected: ISearchStock) {
    if (stockSelected) {
      this.loading = true;
      this.getAcquisitions(stockSelected.ds_asset);
    }
  }

  public acquisitions: IStocksAcquisitions[] = [];
  public loading: boolean = true;
  public emptyMessage: string = 'A empresa não tem recompra programada';
  public tabs = [
    {
      ref: 'active',
      title: 'Ativo',
      active: true,
    },
    {
      ref: 'concluded',
      title: 'Finalizado',
      active: false,
    },
  ];

  private _lastActiveTab: string = 'active';
  private _allAcquisitions: IStockAcquisitionsInfos = {
    active: [],
    concluded: [],
  };

  constructor(
    private stockService: StockService,
    private _cdr: ChangeDetectorRef
  ) {}

  private getAcquisitions(dsAsset: string) {
    this.stockService.getStocksAcquisitions(dsAsset).subscribe({
      next: (response) => {
        this._allAcquisitions = response;
        this.acquisitions =
          this._lastActiveTab === 'active'
            ? response.active
            : response.concluded;
        this.loading = false;
        this._cdr.detectChanges();
      },
      error: (error) => {
        this.loading = false;
        this._allAcquisitions = { active: [], concluded: [] };
        this._cdr.detectChanges();
        console.error('ACQUISITIONS_ERROR:', error);
      },
    });
  }

  public changeTab(tabRef: string): void {
    if (tabRef === this._lastActiveTab) return;
    this._lastActiveTab = tabRef;
    const isActive = tabRef === 'active';
    this.emptyMessage = isActive
      ? 'A empresa não tem recompra programada'
      : 'A empresa não tem recompra finalizada';
    this.acquisitions = isActive
      ? this._allAcquisitions.active
      : this._allAcquisitions.concluded;
    this._cdr.detectChanges();
  }
}
