<nav id="navbar-header" class="navbar navbar-expand p-0 bg-neutral-stronger">
  <div class="container-fluid py-0 pl-3 pr-0">
    <div class="d-flex flex-column flex-grow-1">
      <div class="hstack pr-3">
        <span class="navbar-brand mr-3 fs-7">
          <app-logo [height]="30" [width]="133"></app-logo>
        </span>
        <ul class="nav nav-navbar align-items-center">
          <li class="nav-item" id="stepMenuWorkspace">
            <app-dropdown>
              <a
                type="button"
                class="nav-link p-2 lh-sm round-top d-flex align-items-center"
                appDropdownButton
              >
                <div class="d-flex align-items-center gap-1">
                  <fla-icon
                    name="space_dashboard"
                    css="text-white"
                    size="icon-size-micro"
                  >
                  </fla-icon>
                  <span>Workspace</span>
                </div>
              </a>
              <ul
                class="nav flex-column flex-nowrap overflow-auto"
                [style.maxHeight.rem]="18"
              >
                <li
                  class="nav-item"
                  *ngFor="let item of listWorkspaces; let i = index"
                >
                  <button
                    class="dropdown-item fs-6 px-2"
                    type="button"
                    [ngClass]="{
                      'text-brand-primary': item.id === activeWorkspace.id
                    }"
                    [disabled]="item.visible"
                    (click)="showTab(item)"
                  >
                    {{ item.name }}
                  </button>
                </li>
              </ul>
              <hr />
              <li>
                <a
                  class="dropdown-item px-2 d-flex align-items-center"
                  type="button"
                  (click)="newWorkspace()"
                >
                  <div class="d-flex align-items-center gap-1">
                    <fla-icon
                      name="add"
                      css="text-white"
                      size="icon-size-micro"
                    >
                    </fla-icon>
                    <span>Novo workspace</span>
                  </div>
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item px-2 d-flex align-items-center"
                  type="button"
                  (click)="openSettings()"
                >
                  <div class="d-flex align-items-center gap-1">
                    <fla-icon
                      name="assignment"
                      css="text-white"
                      size="icon-size-micro"
                    >
                    </fla-icon>
                    <span>Gerenciar workspace</span>
                  </div>
                </a>
              </li>
            </app-dropdown>
          </li>
          <li class="nav-item" id="stepMenuComponents">
            <app-dropdown>
              <a
                type="button"
                class="nav-link p-2 lh-sm round-top d-flex align-items-center"
                appDropdownButton
                (click)="componentsQty()"
              >
                <div class="d-flex align-items-center gap-1">
                  <fla-icon
                    name="extension"
                    css="text-white"
                    size="icon-size-micro"
                  >
                  </fla-icon>
                  <span>Componentes</span>
                </div>
              </a>
              <li *ngFor="let key of componentList.keys()">
                <section>
                  <a
                    *ngFor="let component of componentList.get(key)"
                    class="dropdown-item px-2"
                    type="button"
                    (click)="addComponentToHome(component)"
                  >
                    <div
                      class="w-100 d-inline-flex align-items-center justify-content-between"
                    >
                      <div class="d-flex align-items-center">
                        <ng-template
                          *ngTemplateOutlet="
                            componentIcon;
                            context: { data: component }
                          "
                        ></ng-template>
                        <span class="text-truncate mr-2">
                          {{ component.name }}
                        </span>
                      </div>
                      <ng-container
                        *ngIf="workspaceComponentsQty[component.name]"
                      >
                        <app-components-count
                          [qty]="workspaceComponentsQty[component.name].qty"
                        ></app-components-count>
                      </ng-container>
                    </div>
                  </a>
                </section>
              </li>
            </app-dropdown>
          </li>
          <li class="nav-item" id="stepMenuTools">
            <app-dropdown>
              <a
                type="button"
                class="nav-link p-2 lh-sm round-top d-flex align-items-center"
                appDropdownButton
                (click)="componentsQty()"
              >
                <div class="d-flex align-items-center gap-2">
                  <fla-icon
                    name="build"
                    css="text-white"
                    size="icon-size-micro"
                    [flip]="true"
                  >
                  </fla-icon>
                  <span>Ferramentas</span>
                </div>
              </a>
              <li *ngFor="let tool of toolMenu.values()">
                <a
                  *ngIf="tool.label"
                  class="dropdown-item d-inline-flex align-items-center justify-content-between px-2"
                  [class]="tool.borderClass"
                  (click)="onToolClick(tool)"
                  type="button"
                >
                  <div class="d-flex align-items-center">
                    <ng-template
                      *ngTemplateOutlet="componentIcon; context: { data: tool }"
                    ></ng-template>
                    <span>{{ tool.label }}</span>
                  </div>
                  <ng-container *ngIf="workspaceComponentsQty[tool.name]">
                    <app-components-count
                      [qty]="workspaceComponentsQty[tool.name].qty"
                    ></app-components-count>
                  </ng-container>
                </a>
              </li>
            </app-dropdown>
          </li>
          <li class="nav-item">
            <app-dropdown>
              <a
                type="button"
                class="nav-link p-2 lh-sm round-top ml-0 d-flex align-items-center"
                appDropdownButton
              >
                <div class="d-flex align-items-center gap-2">
                  <fla-icon name="help" css="text-white" size="icon-size-micro">
                  </fla-icon>
                  <span>Ajuda</span>
                </div>
              </a>
              <li>
                <a
                  class="dropdown-item px-2 d-flex align-items-center"
                  type="button"
                  href="https://rockettrader.com.br/"
                  target="_blank"
                >
                  <div class="pr-2">
                    <app-rocket-icons
                      name="ROCKET"
                      color="var(--vm-light)"
                    ></app-rocket-icons>
                  </div>
                  Sobre
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item px-2 d-flex align-items-center"
                  type="button"
                  href="https://rockettrader.zendesk.com/hc/pt-br/requests/new"
                  target="_blank"
                >
                  <div class="d-flex align-items-center gap-2">
                    <fla-icon
                      name="mail"
                      css="text-white"
                      size="icon-size-micro"
                    >
                    </fla-icon>
                    <span>Preciso de ajuda</span>
                  </div>
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item px-2 d-flex align-items-center"
                  type="button"
                  href="https://rockettrader.zendesk.com/hc/pt-br"
                  target="_blank"
                >
                  <div class="d-flex align-items-center gap-2">
                    <fla-icon
                      name="question_answer"
                      css="text-white"
                      size="icon-size-micro"
                    >
                    </fla-icon>
                    <span>FAQ</span>
                  </div>
                </a>
              </li>
              <hr />
              <li>
                <a
                  (click)="openTour()"
                  class="dropdown-item px-2 d-flex align-items-center"
                  type="button"
                >
                  <div class="d-flex align-items-center gap-2">
                    <fla-icon
                      name="location_on"
                      css="text-white"
                      size="icon-size-micro"
                    >
                    </fla-icon>
                    <span>Tour guiado</span>
                  </div>
                </a>
              </li>
            </app-dropdown>
          </li>
          <ng-container *ngIf="partnerInfos">
            <li class="nav-item">
              <div class="gradient-bg"></div>
              <button
                class="align-items-center bg-transparent fs-6 fw-bold gap-2 hstack justify-content-between px-3 py-1 text-light btn-border-gradient"
                (click)="openModalConnectBrokers(true)"
              >
                <img
                  [src]="partnerInfos.logo"
                  alt="Logo corretora"
                  width="16"
                  height="16"
                />
                <span> Conectar {{ partnerInfos.btnLabel }} </span>
              </button>
            </li>
          </ng-container>
        </ul>
        <ul class="nav nav-navbar align-items-center ml-auto" *ngIf="showMenu">
          <li
            [ngClass]="{ 'pr-3': i < headerButtons.values().length - 1 }"
            class="nav-item"
            *ngFor="let button of headerButtons.values(); let i = index"
          >
            <a
              *ngIf="button.hasEventClick"
              type="button"
              [class]="'nav-link py-0 m-0 ' + button.css"
              (click)="buttonHeaderClick(button.id)"
              [tooltip]="button.tooltip ?? ''"
              [tooltipHidden]="!!button.tooltipHidden"
            >
              <i *ngIf="button.component == 'icon'" class="icons">
                {{ button.icon }}
              </i>
              <app-stocks-at-auction-by-index
                *ngIf="button.component == 'auction'"
              ></app-stocks-at-auction-by-index>
            </a>
            <lock-workspace *ngIf="button.component == 'lock'"></lock-workspace>
            <app-whatsapp *ngIf="button.component == 'whatsapp'"></app-whatsapp>
          </li>
        </ul>
        <ul
          *ngIf="showProfileInfo"
          class="nav nav-navbar align-items-center"
          [ngClass]="{ 'ml-3': showMenu, 'ml-auto': !showMenu }"
        >
          <li class="nav-item">
            <app-dropdown
              [css]="'config-dropdown-menu'"
              menuAlign="dropdown-menu-right"
            >
              <a
                appDropdownButton
                type="button"
                class="profile profile-sm bg-transparent"
              >
                <span class="notificaton" [class]="notificationClass"></span>
                <img
                  [src]="profileData?.picture"
                  alt="Foto do Usuário"
                  class="round-pill"
                  width="24"
                  height="24"
                />
                <span
                  *ngIf="statusTitle"
                  [appPopover]="statusTitle"
                  popoverPosition="bottom"
                  [disabledClick]="true"
                  [popoverHtml]="true"
                  [showOnStart]="true"
                  [hideOnEsc]="true"
                  (popoverClose)="resetPopOver()"
                ></span>
              </a>
              <ng-container *ngFor="let option of profileInfoOptions.values()">
                <label
                  *ngIf="!option.isSpace"
                  [class]="
                    'list-group-item d-flex gap-3 align-items-center dropdown-item ' +
                    option.cssLabel
                  "
                  (click)="
                    option.isPreventClick
                      ? preventClick($event)
                      : optionInfoClicked(option.id)
                  "
                >
                  <img
                    *ngIf="option.component == 'img'"
                    [src]="option.src"
                    [alt]="option.alt"
                    class="round-pill"
                    [width]="option.width"
                    [height]="option.height"
                  />
                  <fla-icon
                    *ngIf="option.component == 'icon'"
                    [name]="option.nameIcon"
                    size="icon-size-tiny"
                    css="p-0 pl-1 arrow"
                  ></fla-icon>
                  <span [class]="'pt-1 form-checked-content ' + option.cssSpan">
                    <strong *ngIf="option.title">{{ option.title }}</strong>
                    <small
                      *ngIf="option.subTitle"
                      class="d-block text-body-secondary"
                    >
                      {{ option.subTitle }}
                    </small>
                  </span>
                  <toogle-theme
                    *ngIf="option.nameIcon == 'dark_mode'"
                  ></toogle-theme>
                </label>
                <hr *ngIf="option.isSpace" class="my-1 mx-3" />
              </ng-container>
            </app-dropdown>
          </li>
        </ul>
      </div>
      <app-header-tabs
        [visiblesWorkspaces]="visiblesWorkspaces"
        [workspaceActive]="activeWorkspace"
      ></app-header-tabs>
    </div>
  </div>
</nav>

<ng-template #componentIcon let-data="data">
  <div class="pr-2">
    <ng-container *ngIf="data.icon; else customIcon">
      <fla-icon
        size="icon-size-micro"
        [name]="data.icon"
        [css]="data.iconColor ? data.iconColor : 'text-white'"
        [iconType]="data.iconType ? data.iconType : 'icons'"
      >
      </fla-icon>
    </ng-container>
    <ng-template #customIcon>
      <app-rocket-icons
        [name]="data.iconSvg"
        [color]="data.iconColor ? data.iconColor : 'var(--vm-light)'"
      ></app-rocket-icons>
    </ng-template>
  </div>
</ng-template>
