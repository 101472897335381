import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ascendingSort, getVolumeText } from 'src/app/utils/utils.functions';

@Component({
  selector: 'ipos-cards',
  templateUrl: './ipos-cards.component.html',
  styleUrls: ['./ipos-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IposCardsComponent implements OnInit {
  @Input() public data: any;
  public topFive: any = [];
  public yearSelected: number = 2021;
  public years: any = [];
  public totalQttyIPO: number = 0;
  public totalVolIPO: string = '';
  form!: FormGroup;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.form = this.formBuilder.group({
      period: new FormControl(2021),
    });
  }

  ngOnInit() {
    this.years = Object.keys(this.data)
      .map((item: any) => {
        let temp = {
          id: parseInt(item),
          label: item,
        };
        return temp;
      })
      .sort((a: any, b: any) => ascendingSort(a.id, b.id));
    this.getTopFive();
    this.cdr.detectChanges();
  }

  private getTopFive() {
    let tempSectors = Object.keys(this.data[this.yearSelected]);
    const tempResult: any = [];
    let totalQtty: number = 0;
    let totalSum: number = 0;

    tempSectors.forEach((sector: any, index: number) => {
      let obj: any = this.data[this.yearSelected][sector];
      obj.vl_formatted = getVolumeText(
        this.locale,
        this.data[this.yearSelected][sector].sum_vl_offer
      );
      obj.name = sector;

      totalQtty += obj.total_ipo_segment;
      totalSum += obj.sum_vl_offer;
      tempResult.push(obj);
    });

    this.totalQttyIPO = totalQtty;
    this.totalVolIPO = getVolumeText(this.locale, totalSum);

    this.topFive = tempResult
      .sort((a: any, b: any) => ascendingSort(a.sum_vl_offer, b.sum_vl_offer))
      .splice(0, 5);
    this.cdr.detectChanges();
  }

  public changePeriod(period: any) {
    this.cdr.detectChanges();
    this.yearSelected = period.id;
    this.getTopFive();
  }
}
