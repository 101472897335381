import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { GROUP_TOOLS } from '../tiger-chart-tools.const';
import { TIGER_CHART_GROUP_TOOL } from '../tiger-chart-tools.interface';

@Component({
  selector: 'app-help-draws',
  templateUrl: './help-draws.component.html',
  styleUrls: ['./help-draws.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpDrawsComponent extends RocketModalRef {
  groupTools = GROUP_TOOLS;
  selectedTool = this.groupTools[0];
  constructor(
    @Inject(ROCKET_MODAL_DATA) private data: any,
    service: RocketModalService,
    private cdr: ChangeDetectorRef
  ) {
    super(service);
  }

  clickTool(tool: TIGER_CHART_GROUP_TOOL) {
    this.selectedTool = tool;
    this.cdr.detectChanges();
  }
}
