import { FormControl } from '@angular/forms';
import {
  Component,
  OnInit,
  ElementRef,
  HostListener,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components/index';
import { HomeService } from '@modules/home/service/home.service';
import { IWorkSpace } from 'src/app/core/interface';
import { WorkspaceComponentService } from '@modules/home/service/workspace-component.service';

@Component({
  selector: 'app-modal-gerenciar',
  templateUrl: './modal-gerenciar.component.html',
  styles: [
    `
      .ws-list-height {
        min-height: 200px;
        max-height: 65vh;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalGerenciarComponent extends RocketModalRef implements OnInit {
  public editControl: FormControl = new FormControl(undefined);
  public currentWorkspace: IWorkSpace[] = [];
  public workspaces: IWorkSpace[] | any[] = [];
  public wsIndexDefault: number = 0;
  public isEdit: boolean = false;

  get isDisabled() {
    return (
      this.currentWorkspace.length > 1 ||
      this.currentWorkspace.length === 0 ||
      this.isEdit
    );
  }

  get isDisabledDelete() {
    return this.currentWorkspace.length === 0 || this.isEdit;
  }

  constructor(
    service: RocketModalService,
    private _elementRef: ElementRef,
    private _homeService: HomeService,
    private workspaceComponentService: WorkspaceComponentService,
    private cdr: ChangeDetectorRef
  ) {
    super(service);
    this.currentWorkspace = [];
  }

  ngOnInit(): void {
    this.workspaceComponentService
      .dispatchVisiblesWorkspaces()
      .subscribe(this._findWorkspaces);
    this._findWorkspaces();
  }

  private _findWorkspaces = (): void => {
    this.workspaces = this.workspaceComponentService.getWorkspaces();
    this.selectWorkspaceActive();
  };

  private selectWorkspaceActive(): void {
    this.currentWorkspace.push(
      this.workspaceComponentService.getActiveWorkspace()
    );
    const wsIndex = this.workspaces.findIndex(
      (ws) => ws.id === this.currentWorkspace[0].id
    );
    this.workspaces[wsIndex]['select'] = true;
    this.wsIndexDefault = wsIndex;
    this.cdr.detectChanges();
  }

  public handleCheckedWorkspace(wsIndex: number) {
    if (this.isEdit) return;
    this.workspaces[wsIndex].select = !this.workspaces[wsIndex].select;
    this.currentWorkspace = this.workspaces.filter((item: any) => item.select);
  }

  public handleEditMode(wsIndex: number, enableEditMode: boolean): void {
    this.workspaces[wsIndex]['editMode'] = enableEditMode;
    this.workspaces[wsIndex]['select'] = true;
    this.isEdit = false;
  }

  public enableEditWorkspaceName() {
    const workspace = this.workspaces.find((workspaces) => workspaces.select);
    workspace['editMode'] = true;
    this.editControl.setValue(workspace.name);
    this.isEdit = true;
  }

  public confirmEditingWorkspaceName(wsIndex: number) {
    const workspace = this.workspaces[wsIndex];
    if (!this.editControl.value) {
      workspace.editMode = false;
      this.editControl.reset();
      this.isEdit = false;
      return;
    }
    delete (workspace as any)['select'];
    const wsComponents = workspace.components;
    workspace.name = (this.editControl.value ?? '') as string;
    workspace.editMode = false;
    this._homeService.updateWorkspace(workspace).subscribe(() => {
      this.workspaces[wsIndex] = structuredClone(workspace);
      this.currentWorkspace = [];
      this.editControl.reset();
      this.isEdit = false;
      workspace.components = wsComponents;
      this.workspaceComponentService.createdOrUpdateWorkspace(workspace);
    });
  }

  public deleteWorkspace() {
    if (this.workspaces.length === 1) return;
    this._homeService.processRemoveWorkspace(
      this.currentWorkspace,
      this._elementRef
    );
    this.currentWorkspace = [];
  }

  public cloneWorkspace() {
    const index = this.workspaces.findIndex((workspaces) => workspaces.select);
    this.currentWorkspace = [];
    this._homeService
      .openCloneModal(this.workspaces[index], this._elementRef)
      .subscribe((res) => {
        if (!res || res.closed || !res.wsDuplicated) {
          this.currentWorkspace = [this.workspaces[index]];
          this.cdr.detectChanges();
          return;
        }
        if (!this.workspaces[index].components) {
          this.close();
          return;
        }
        this.handleActiveWorkspace(res.workspace);
        this.workspaces[index] = Object.assign(res.workspace, {
          active: true,
        });
        this.workspaces[index]['select'] = true;
        this.cdr.detectChanges();
      });
  }

  private handleActiveWorkspace(newActiveWs: IWorkSpace): void {
    this.workspaces = this.workspaces.map((ws) => {
      ws.editMode = false;
      ws.active = ws.id === newActiveWs.id;
      ws['select'] = false;
      return ws;
    });
  }

  @HostListener('window:keyup.esc')
  closeModal() {
    !this.isEdit && this.close();
  }

  resetDefaultWorkspace() {
    this.closeModal();
    const workspace = structuredClone(
      this.workspaces.find((workspaces) => workspaces.select)
    );
    delete (workspace as any)['select'];
    this._homeService.resetWorkspace(workspace);
  }
}
