import { Injectable } from '@angular/core';
import { BrokerService } from '@shared/services/api/trademap/v1/broker.service';
import {
  ID_BROKER_LEAGUE,
  isIdBrokerSimulator,
  isSimulatorOrLeague,
} from '@shared/constants/simulator-league.constant';
import { BehaviorSubject, filter, map } from 'rxjs';
import {
  ACCOUNT_WORKER_ACTION,
  IAccountSelect,
  IBroker,
  IWorkerAccountActionReturn,
} from 'src/app/core/interface';
import { BrokerAccountModel, Dictionary } from 'src/app/core/models';
import { normalizeString } from 'src/app/utils/utils.functions';
import { system } from '@core/system/system.service';
import { AccountPreferences } from '../custom-preferences/account/account-preferences.services';
import { RxEvent } from '@shared/channel/rx-event';
import { CHANGE_ACCOUNT } from '../subscription/const/events-name';
import { INFINITY_READ_STREAM } from '@shared/constants/general.contant';

@Injectable({
  providedIn: 'root',
})
export class MultibrokerService {
  static _instance: MultibrokerService;
  account$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  refreshAccounts$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private _brokerAccuounts = new Dictionary<IBroker>();
  private _selectedAccount!: IAccountSelect;
  private accounstSimulatorAndLeague: any[] = [];
  private initiated = false;
  private selectedAccount$: BehaviorSubject<any> = new BehaviorSubject(null);

  set brokerAccount(brokerAccounts: Dictionary<IBroker>) {
    this._emitWorkerAction(ACCOUNT_WORKER_ACTION.SET_BROKER_ACCOUNTS, {
      brokerAccounts: brokerAccounts.values(),
    });
  }

  get brokerAccount(): Dictionary<IBroker> {
    return system.brokers.reduce((map: any, item: IBroker) => {
      map.set(item.id_broker, item);
      return map;
    }, new Dictionary<IBroker>());
  }

  constructor(
    private brokerService: BrokerService,
    private accPreferences: AccountPreferences,
    private rxEvent: RxEvent
  ) {
    if (MultibrokerService._instance == null) {
      MultibrokerService._instance = this;
    } else {
      return MultibrokerService._instance;
    }
    this._accountWorkerListener();
  }

  getAccountSelected(): any {
    return this.accPreferences.simulatorSelected
      ? this.accPreferences.simulatorAccountSelected
      : this.accPreferences.multiBrokerAccountSelected;
  }

  updateSelectedAccount(account: any) {
    this._emitWorkerAction(ACCOUNT_WORKER_ACTION.UPDATE_SELECTED_ACCOUNT, {
      account,
    });
  }

  onUpdateSelectedAccountChannel = () => {
    return this.selectedAccount$.asObservable().pipe(
      filter((item) => item),
      map((item) => item)
    );
  };

  public getBrokerAccount = (id_broker: any) =>
    this._brokerAccuounts.get(id_broker)?.nm_broker;

  private getAllBrokerAccounts() {
    return this._brokerAccuounts;
  }

  updateAccountSelected(account: any) {
    this._brokerAccuounts.set(account.id_broker, account);
    this._emitWorkerAction(ACCOUNT_WORKER_ACTION.UPDATE_SELECTED_ACCOUNT, {
      account,
    });
  }

  deleteAccountMultibroker(account: any) {
    this._emitWorkerAction(ACCOUNT_WORKER_ACTION.DELETE_ACCOUNT_MULTIBROKER, {
      account,
    });
  }

  insertAccountSelectedOnResponse(accounts: BrokerAccountModel[]) {
    const accountSelected = this._selectedAccount
      ? this._selectedAccount
      : accounts[0];
    return {
      accountSelected,
      accounts,
    } as AccountFunctionResponse;
  }

  private _emitWorkerAction(
    action: ACCOUNT_WORKER_ACTION,
    workspacesData: any
  ) {
    if (!this.initiated) {
      setTimeout(() => {
        this._emitWorkerAction(action, workspacesData);
      }, 100);
      return;
    }
    this.rxEvent.emitCommand('accountCommand', CHANGE_ACCOUNT, {
      channel: CHANGE_ACCOUNT,
      action,
      workspacesData,
    });
  }

  private _accountWorkerListener() {
    this.rxEvent.read(CHANGE_ACCOUNT).then(async (data) => {
      this.initiated = true;
      const reader = data.stream.getReader();
      let ret;

      do {
        ret = await reader.read();
        if (!ret.done) {
          this._processWorkerResponse(ret.value.data);
        }
      } while (INFINITY_READ_STREAM);

      this._emitWorkerAction(ACCOUNT_WORKER_ACTION.GET_BROKER_ACCOUNTS, {});
      this._emitWorkerAction(ACCOUNT_WORKER_ACTION.GET_SELECTED_ACCOUNT, {});
    });
  }

  private _processWorkerResponse(data: IWorkerAccountActionReturn) {
    const { brokerAccounts, selectedAccount } = data;

    brokerAccounts &&
      this._brokerAccuounts.bulkData('id_broker', brokerAccounts);

    if (selectedAccount) {
      this._selectedAccount = selectedAccount;
      this.selectedAccount$.next(this._selectedAccount);
    }
  }

  private getMBAccountsOnDB() {
    const contas: BrokerAccountModel[] = this.getAllBrokerAccounts()
      .values()
      .filter((account: any) => !isSimulatorOrLeague(account.id_broker))
      .map((account: any) => new BrokerAccountModel(account))
      .sort(this.sortAccount);
    return this.insertAccountSelectedOnResponse(contas);
  }

  private getSimulatorAndLeagueAccountsOnDB() {
    const contas: BrokerAccountModel[] = this.getAllBrokerAccounts()
      .values()
      .filter((account: any) => isIdBrokerSimulator(account.id_broker))
      .map((account: any) => new BrokerAccountModel(account))
      .sort(this.sortAccount);
    return this.getAllAccountsSimulateds(contas).then((accounts: any) => {
      return this.insertAccountSelectedOnResponse(accounts);
    });
  }

  getBrokers = () =>
    this.getAllBrokerAccounts()
      .values()
      .map((item: any) => item.id_broker)
      .filter((id_broker: any) => !isSimulatorOrLeague(id_broker));

  filterMultibrokerAccounts = () =>
    this.brokerAccount
      .values()
      .filter((account: any) => !isSimulatorOrLeague(account.id_broker))
      .map((account: any) => new BrokerAccountModel(account));

  getAccounstMultibroker = () =>
    new Promise((resolve) => {
      return resolve(this.getMBAccountsOnDB());
    });

  getAccounstSimulatorAndLeague = () =>
    new Promise((resolve) => {
      const accounts = this.getSimulatorAndLeagueAccountsOnDB();
      accounts.then((account) => {
        return resolve(account);
      });
    });

  private sortAccount = (
    accountA: IAccountSelect,
    accountB: IAccountSelect
  ) => {
    const fieldA = normalizeString(accountA.label);
    const fieldB = normalizeString(accountB.label);
    if (fieldA < fieldB) {
      return -1;
    }
    if (fieldA > fieldB) {
      return 1;
    }
    return 0;
  };

  private getAllAccountsSimulateds(contas: BrokerAccountModel[]) {
    return this.getSimulatedsAccounts(contas);
  }

  private getSimulatedsAccounts(contas: BrokerAccountModel[]) {
    return new Promise((resolve) => {
      this.getInvestorBrokerAccountsSimulatorOrLeague(ID_BROKER_LEAGUE).then(
        (data) => {
          const league = data;
          const leagueSort = league.sort(this.sortAccount);
          this.accounstSimulatorAndLeague = [...contas, ...leagueSort];
          return resolve(this.accounstSimulatorAndLeague);
        }
      );
    });
  }

  private getInvestorBrokerAccountsSimulatorOrLeague(
    idBroker: number,
    isSimulator: boolean = false
  ): Promise<IAccountSelect[]> {
    return new Promise((resolve) => {
      this.brokerService
        .getInvestorBrokerAccounts(idBroker)
        .subscribe((data: any) => {
          if (!data.length) {
            resolve([]);
            return;
          }
          const list: IAccountSelect[] = [];
          data.forEach(
            (item: any) =>
              (item.is_active || isSimulator) &&
              list.push(new BrokerAccountModel(item))
          );
          resolve(list);
        });
    });
  }
}

export interface AccountFunctionResponse {
  accountSelected: IAccountSelect;
  accounts: BrokerAccountModel[];
}
