import { CustomAnnotation } from 'scichart/Charting/Visuals/Annotations/CustomAnnotation';
import { TClickableAnnotationOptions } from '../types/tiger-chart.types';

export class ClickableAnnotation extends CustomAnnotation {
  constructor(options?: TClickableAnnotationOptions) {
    super(options);
    if (options?.customClick) this.customClick = options.customClick;
    if (options?.customHover) this.customHover = options.customHover;
  }

  customClick = (x?: number, y?: number, remove?: boolean) => {};

  customHover = (
    annotation?: ClickableAnnotation,
    x?: number,
    y?: number
  ) => {};
}
