<div class="height-div d-flex flex-wrap justify-content-between gap-3">
  <ng-container *ngFor="let broker of brokers">
    <app-card-broker
      [broker]="broker"
      (brokerSelected)="brokerSelected.emit($event)"
      (brokerDisconnect)="brokerDisconnect.emit($event)"
    ></app-card-broker>
  </ng-container>
  <h4 *ngIf="!brokers?.length">Nenhuma corretora disponível</h4>
</div>
