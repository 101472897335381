import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  RocketModalRef,
  RocketModalService,
  ROCKET_MODAL_DATA,
} from '@shared/rocket-components/components/index';
import { deepClone } from '@shared/rocket-components/utils';
import { TIGER_INDICATORS_ENUM } from '@shared/tiger-chart/enum';
import { INDICATORS_EXPLICATION } from '@shared/tiger-chart/indicators/indicators-explication.constants';
import { CustomPreferencesService } from '@services/api/nitro-ws/v1/custom-preferences.service';
import { GROUP_INDICATORS } from '@shared/components/stock-chart/constants/stock-chart.constant';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import {
  isNullOrUndefined,
  removeAccents,
} from 'src/app/utils/utils.functions';
import { KEY_CODE } from './indicators-modal.enum';
import { WorkspaceComponentService } from '@modules/home/service/workspace-component.service';
import { Dictionary } from '@core/models';
import { FavoriteIndicatorPreferencesService } from '@shared/services/core/custom-preferences/favorite-indicator/favorite-indicator-preferences.service';
import { Subject, Subscription, auditTime } from 'rxjs';
import { HomeService } from '@modules/home/service/home.service';
import { GroupIndicatorsService } from '@shared/components/stock-chart/service/group-indicators.service';

@Component({
  selector: 'app-indicators-modal',
  templateUrl: './indicators-modal.component.html',
  styleUrls: ['./indicators-modal.component.scss'],
})
export class IndicatorsModalComponent
  extends RocketModalRef
  implements OnInit, OnDestroy
{
  groupIndicators!: any;
  searchControl!: FormControl;
  indicatorSelected: any = null;
  nameIndicator: string = '';
  nameGroup: string = '';
  descIndicator: string = '';
  filteredIndicators: any = [];
  nothingSearch: boolean = false;
  indicatorsToAdd: any = {};
  keysBlocked: any = [16, 17, 18, 20, 37, 38, 39, 40, 144, 225, 13];
  indicatorsKeyQtty: any = {};
  knowMore: boolean = false;
  mapFavorites = new Dictionary<any>();
  @ViewChild('inputSearch', { static: false }) public inputSearchControl!: any;
  private _updateFavorites$ = new Subject<void>();
  private updateFavorites$!: Subscription;
  private _onMiniplayerComponent$!: Subscription;
  public player: any;
  showMiniplayer: boolean = false;
  isUsingMiniPlayer: boolean = false;
  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: any,
    service: RocketModalService,
    private stockChartService: StockChartService,
    private customPreferencesService: CustomPreferencesService,
    private workspaceComponentService: WorkspaceComponentService,
    private favoriteIndicatorPreferencesService: FavoriteIndicatorPreferencesService,
    private homeService: HomeService,
    private groupIndicatorsService: GroupIndicatorsService
  ) {
    super(service);
  }
  onClose = () => this.close('Event Before Close Modal');

  indicators: any = INDICATORS_EXPLICATION;

  ngOnInit() {
    //this.disableIndicators();
    this.initSubscription();
    this.searchControl = new FormControl(undefined);
    this._focusOnFieldText();
    this.groupIndicators = deepClone(
      this.groupIndicatorsService.getGroupIndicators()
    );
    this.filteredIndicators = deepClone(this.groupIndicators);
    this.clearCheckedIndicators();
    this.selectIndicator(
      this.filteredIndicators[1].indicators[0],
      this.filteredIndicators[1]
    );
    this.getQttyIndicators();
    this.setFavorites();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.updateFavorites$ && this.updateFavorites$.unsubscribe();
    this._onMiniplayerComponent$ && this._onMiniplayerComponent$.unsubscribe();
    this.destroyPlayer();
  }

  private destroyPlayer() {
    this.player && this.player.destroy();
  }

  private _focusOnFieldText(): void {
    setTimeout(() => {
      document.getElementById(this.inputSearchControl.refId)?.focus();
    }, 400);
  }

  /*private disableIndicators() {
    let checkedIndicators = this.data.indicators.filter((indicator: any) => indicator.checked);
    const hashChecked: any = {};
    checkedIndicators.forEach((indicator: any) => {
      hashChecked[indicator.id] = indicator;
    });
    let hashIndicators: any = this.getHashCollapseGroup();
    this.groupIndicators.forEach(group => {
      if(group.id in hashIndicators) {
        group.open = hashIndicators[group.id].open;
      }
      group.indicators.forEach(indicator => {
        indicator.checked = false;
        if(indicator.id in hashChecked) {
          indicator.checked = true;
        }
      });
    })
  }*/

  openOrCloseGroup(group: any) {
    group.open = !group.open;
    const hashIndicators: any = this.getHashCollapseGroup();
    if (group.id in hashIndicators) {
      hashIndicators[group.id].open = group.open;
    } else {
      hashIndicators[group.id] = group;
    }
    this.customPreferencesService.indicatorsModalCollapse =
      JSON.stringify(hashIndicators);
  }

  selectIndicator(indicator: any, groupIndicator: any) {
    this.nameIndicator = indicator.id;
    this.nameGroup = groupIndicator.id;
    const index = Object.values(TIGER_INDICATORS_ENUM).findIndex(
      (value) => value == indicator.id
    );

    const searchText: any = Object.values(TIGER_INDICATORS_ENUM)[index];

    if (searchText in INDICATORS_EXPLICATION) {
      this.descIndicator = INDICATORS_EXPLICATION[searchText].text;
    }
    this.indicatorSelected = indicator;
    this.clearCheckedIndicators();
    this.initIframe();
  }

  initIframe() {
    if ((window as any)['YT']) {
      this.showMiniplayer = false;
      this.destroyPlayer();
      this.startVideo();
      return;
    }
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag!.parentNode!.insertBefore(tag, firstScriptTag);
    (window as any)['onYouTubeIframeAPIReady'] = () => this.startVideo();
  }

  private getHashCollapseGroup(): object {
    const stringHashIndicators =
      this.customPreferencesService.indicatorsModalCollapse;
    let hashIndicators: any = {};
    if (!isNullOrUndefined(stringHashIndicators)) {
      hashIndicators = JSON.parse(stringHashIndicators);
    }
    return hashIndicators;
  }

  private getQttyIndicators() {
    this.filteredIndicators.map((group: any) => {
      group.indicators.map((item: any) => {
        item.qtty = 0;
        this.indicatorsKeyQtty[item.id] = 0;
      });
    });
    const component = this.workspaceComponentService.getComponentById(
      this.data.chartRef
    );
    const indicators = component.metadata.component.indicators.actives;
    this.filteredIndicators.map((group: any) => {
      indicators.map((indicator: any) => {
        const tempIndicator = this.getSplitIndicatorName(indicator);
        const index = group.indicators.findIndex(
          (indicador: any) => indicador.id == tempIndicator
        );
        if (index != -1) {
          group.indicators[index].qtty += 1;
          this.indicatorsKeyQtty[group.indicators[index].id] += 1;
        }
      });
    });
    if (this.searchControl.value)
      this.searchIndicators(this.searchControl.value);
  }

  private getSplitIndicatorName(indicator: any): string {
    const indicatorName =
      typeof indicator === 'string' ? indicator : indicator.indicator;
    return indicatorName.split('_')[0];
  }

  public addIndicator() {
    const toAdd = Object.keys(this.indicatorsToAdd);

    toAdd.map((indicator: any) => {
      for (let i = 0; i < this.indicatorsToAdd[indicator].qtty; i++) {
        setTimeout(() => {
          this.stockChartService.indicatorSelected$.next({
            indicatorId: this.indicatorsToAdd[indicator].id,
            fatherId: this.data.fatherId,
          });
        }, 500);
      }
    });
    this.onClose();
  }

  searchIndicators(event: any) {
    const searchText = event?.target ? event.target.value : event;
    //Ignora teclas que não são letras e numeros
    if (this.keysBlocked.includes(event.keyCode)) return;
    if (
      event.keyCode === 8 &&
      (!searchText || searchText == '') &&
      !this.nothingSearch
    ) {
      this.filteredIndicators = deepClone(this.groupIndicators);
      this.nothingSearch = false;
      this.indicatorSelected = null;
      this.nameIndicator = '';
      this.nameGroup = '';
      this.clearCheckedIndicators();
      this.updateFavorites(true, false);
      return;
    }

    //Verifica se o campo de busca é vazio
    if (!searchText || searchText == '') {
      this.filteredIndicators = deepClone(this.groupIndicators);
      this.nothingSearch = false;
      this.indicatorSelected = null;
      this.nameIndicator = '';
      this.nameGroup = '';
      this.clearCheckedIndicators();
      this.updateFavorites(true, false);
      return;
    }

    this.indicatorSelected = null;
    this.filteredIndicators = [];
    const filteredGroups: { [key: string]: any } = {}; // Objeto auxiliar para armazenar grupos já adicionados
    const favIndicators: any[] = [];
    this.groupIndicators.forEach((group: any) => {
      const groupName = removeAccents(group.label.toLowerCase());
      const queryWithoutAccents = removeAccents(searchText.toLowerCase());

      if (groupName.includes(queryWithoutAccents)) {
        // Adiciona o grupo inteiro se o nome der match com o grupo
        if (!filteredGroups[groupName]) {
          filteredGroups[groupName] = { ...group };
          this.filteredIndicators.push(filteredGroups[groupName]);
        }
        for (const indicator of group.indicators) {
          if (this.mapFavorites.has(indicator.id)) {
            favIndicators.push(indicator);
          }
        }
      } else {
        const filteredIndicators = [];

        for (const indicator of group.indicators) {
          const indicatorName = removeAccents(indicator.name.toLowerCase());
          const indicatorId = removeAccents(indicator.id.toLowerCase());

          if (
            indicatorName.includes(queryWithoutAccents) ||
            indicatorId.includes(queryWithoutAccents)
          ) {
            filteredIndicators.push(indicator);
            if (this.mapFavorites.has(indicator.id)) {
              favIndicators.push(indicator);
            }
          }
        }

        if (filteredIndicators.length > 0) {
          // Adiciona o grupo filtrado
          const filteredGroup = {
            ...group,
            label: group.label,
            indicators: filteredIndicators,
          };

          // Verifica se o grupo já foi adicionado e se não foi, adiciona ao objeto de apoio e ao array de resultados
          const filteredGroupName = removeAccents(
            filteredGroup.label.toLowerCase()
          );
          if (!filteredGroups[filteredGroupName]) {
            filteredGroups[filteredGroupName] = { ...filteredGroup };
            this.filteredIndicators.push(filteredGroups[filteredGroupName]);
          }
        }
      }
    });
    if (favIndicators.length) {
      this.filteredIndicators.unshift({
        ...this.groupIndicators[0],
        indicators: favIndicators,
      });
    }

    if (!this.filteredIndicators.length) {
      this.nothingSearch = true;
      this.indicatorSelected = null;
      this.nameIndicator = '';
      this.nameGroup = '';
    } else {
      this.nothingSearch = false;
    }
    this.clearCheckedIndicators();
    //this.updateFavorites(false);
  }

  private clearCheckedIndicators() {
    this.filteredIndicators.map((group: any) => {
      group.indicators.map((indicator: any) => {
        if (
          this.nameIndicator === indicator.id &&
          this.nameGroup === group.id
        ) {
          indicator.checked = true;
        } else {
          indicator.checked = false;
        }
      });
    });
  }

  public sumIndicator = (indicator: any) => {
    if (
      indicator.max &&
      (indicator.max == this.indicatorsKeyQtty[indicator.id] ||
        indicator.max == this.indicatorsToAdd[indicator.id]?.qtty)
    ) {
      return;
    }
    if (this.indicatorsToAdd[indicator.id]) {
      this.indicatorsToAdd[indicator.id].qtty += 1;
    } else {
      indicator = deepClone(indicator);
      indicator.qtty = 0;
      this.indicatorsToAdd[indicator.id] = indicator;
      this.indicatorsToAdd[indicator.id].qtty += 1;
    }
  };

  public removeIndicator = (indicator: any) => {
    if (this.indicatorsToAdd[indicator.id]) {
      if (this.indicatorsToAdd[indicator.id].qtty > 1) {
        this.indicatorsToAdd[indicator.id].qtty -= 1;
      } else {
        delete this.indicatorsToAdd[indicator.id];
      }
    }
  };

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ENTER && this.indicatorSelected) {
      this.sumIndicator(this.indicatorSelected);
    }

    if (event.keyCode === KEY_CODE.BOTTOM_ARROW) {
      const idIndicator =
        this.indicatorSelected?.id ||
        this.filteredIndicators[0].indicators[0].id;

      const groupIndex = this.filteredIndicators.findIndex((group: any) =>
        group.indicators.find((i: any) => i.id === idIndicator)
      );
      const indicatorIndex = this.filteredIndicators[
        groupIndex
      ].indicators.findIndex((i: any) => i.id === idIndicator);

      const totalGroup = this.filteredIndicators.length - 1;
      const totalIndicators =
        this.filteredIndicators[groupIndex].indicators.length - 1;

      let indicator = null;

      if (
        !this.indicatorSelected &&
        this.filteredIndicators[0].indicators.length
      ) {
        this.selectIndicator(
          this.filteredIndicators[groupIndex].indicators[indicatorIndex],
          this.filteredIndicators[groupIndex]
        );
        return;
      }
      let group!: any;
      if (totalGroup >= groupIndex && totalIndicators > indicatorIndex) {
        group = this.filteredIndicators[groupIndex];
        indicator = group.indicators[indicatorIndex + 1];
      } else if (indicatorIndex == totalIndicators && groupIndex < totalGroup) {
        group = this.filteredIndicators[groupIndex + 1];
        indicator = group.indicators[0];
      }
      if (indicator && group) this.selectIndicator(indicator, group);
    } else if (event.keyCode === KEY_CODE.TOP_ARROW) {
      const groupIndex = this.filteredIndicators.findIndex((group: any) =>
        group.indicators.find((i: any) => i.id === this.indicatorSelected.id)
      );
      const indicatorIndex = this.filteredIndicators[
        groupIndex
      ].indicators.findIndex((i: any) => i.id === this.indicatorSelected.id);
      const totalIndicators =
        this.filteredIndicators[groupIndex].indicators.length - 1;

      let indicator = null;
      let group = null;
      if (groupIndex == 0 && indicatorIndex == 0) return;

      if (
        groupIndex >= 0 &&
        indicatorIndex <= totalIndicators &&
        indicatorIndex > 0
      ) {
        group = this.filteredIndicators[groupIndex];
        indicator = group.indicators[indicatorIndex - 1];
      }
      if (groupIndex > 0 && indicatorIndex == 0) {
        group = this.filteredIndicators[groupIndex - 1];
        indicator = group.indicators[group.indicators.length - 1];
      }
      if (indicator && group) this.selectIndicator(indicator, group);
    }
  }

  public goKnowMore(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.knowMore = !this.knowMore;
    this.startVideo();
  }

  favoriteIndicator(indicator: any) {
    if (this.mapFavorites.has(indicator.id)) {
      this.mapFavorites.delete(indicator.id);
      const idx = this.filteredIndicators[0].indicators.findIndex(
        (favIndicator: any) => favIndicator.id === indicator.id
      );
      this.filteredIndicators[0].indicators.splice(idx, 1);
      if (
        this.searchControl.value &&
        this.searchControl.value != '' &&
        !this.filteredIndicators[0].indicators.length
      ) {
        this.filteredIndicators.splice(0, 1);
      }
      this.updateFavorites(false, true);
      return;
    }
    indicator = deepClone(indicator);
    indicator.checked = false;
    this.mapFavorites.set(indicator.id, indicator);
    if (this.filteredIndicators[0].id != 'FAVORITES') {
      this.filteredIndicators.unshift(GROUP_INDICATORS[0]);
    }
    this.filteredIndicators[0].indicators.push(indicator);
    this.updateFavorites(false, true);
  }

  private updateFavorites(updateArray: boolean, update: boolean = true) {
    if (updateArray) {
      this.filteredIndicators[0].indicators = deepClone(
        this.mapFavorites.values()
      );
    }
    if (update) {
      this._updateFavorites$.next();
    }
  }

  private setFavorites() {
    const favorites =
      this.favoriteIndicatorPreferencesService.favoriteIndicators;
    if (!favorites) return;
    favorites.forEach((favorite) => {
      this.mapFavorites.set(favorite.id, favorite);
    });
    this.updateFavorites(true, false);
  }

  private initSubscription() {
    this.updateFavorites$ = this._updateFavorites$
      .pipe(auditTime(150))
      .subscribe(() => {
        this.favoriteIndicatorPreferencesService.favoriteIndicators =
          this.filteredIndicators[0].indicators;
      });
    this._onMiniplayerComponent$ = this.homeService
      .onMiniplayerComponent()
      .subscribe((data) => {
        if (!data) {
          this.isUsingMiniPlayer = false;
        } else {
          this.isUsingMiniPlayer = true;
        }
      });
  }

  startVideo() {
    setTimeout(() => {
      this.player = new (window as any)['YT'].Player('player', {
        videoId: this.indicators[this.nameIndicator].videoId,
        width: '340',
        height: '150',
        modestbranding: 1,
        controls: 1,
        disablekb: 1,
        rel: 0,
        showinfo: 0,
        fs: 0,
        playsinline: 1,
        origin: window.location.host,
        events: {
          onReady: () => {
            this.showMiniplayer = true;
          },
        },
      });
    }, 500);
  }

  useMiniPlayer() {
    this.player.pauseVideo();
    this.homeService.miniplayerComponent = {
      autoplay: this.player.playerInfo.playerState === 1,
      startAt: this.player.getCurrentTime(),
      videoId: this.player.playerInfo.videoData.video_id,
    };
    this.isUsingMiniPlayer = true;
  }
}
