import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';

import { CHART_COLORS } from '@shared/tiger-chart/colors';
import {
  TData,
  TTooltipIndicatorValue,
  TUpdateData,
} from '@shared/tiger-chart/types/tiger-chart.types';
import {
  BaseIndicator,
  CdlhammerData,
  TigerChartIndicatorCdlhammerOptions,
  TTigerChartIndicatorCreateOptions,
  TTigerChartIndicatorParameter,
  TTigerChartIndicatorRenderSeriesConfig,
} from '../indicators.types';
import {
  TIGER_INDICATORS_ENUM,
  TIGER_INDICATOR_PARAMETER_TYPE,
} from '@shared/tiger-chart/enum/tiger-chart.enum';
import { Subject } from 'rxjs';
import { TalibService } from '@shared/tiger-chart/services/talib.service';
import { XyyScaleOffsetFilter } from 'scichart/Charting/Model/Filters/XyyScaleOffsetFilter';
import { XyDataSeries } from 'scichart/Charting/Model/XyDataSeries';
import { FastLineRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastLineRenderableSeries';
import { NEW_ANNOTATION_INDICATOR } from '@shared/components/stock-chart/constants/stock-chart.constant';
import { CustomAnnotation } from 'scichart/Charting/Visuals/Annotations/CustomAnnotation';
import { setDefaultConfiguration } from '../indicators.functions';

export class CdlhammerBase implements BaseIndicator {
  lineDataSeries!: XyDataSeries;
  protected movingAverageDataSeries!: XyyScaleOffsetFilter;
  protected lineRenderableSeries!: FastLineRenderableSeries;
  protected data: TData;
  protected backgroundColor = CHART_COLORS.FEEDBACK_WARNING;
  protected points: CdlhammerData;
  private _newAnnotationIndicatorId = NEW_ANNOTATION_INDICATOR;
  private baseChart!: TWebAssemblyChart;
  lineNumber!: string;
  type: TIGER_INDICATORS_ENUM = TIGER_INDICATORS_ENUM.CDL_HAMMER;
  yAxisId!: string;
  settings: TTigerChartIndicatorParameter[] = [];
  styles: TTigerChartIndicatorParameter[] = [];
  renderSeriesConfig: TTigerChartIndicatorRenderSeriesConfig[] = [];
  onChange = new Subject<null>();
  service: TalibService;
  mainLineId = '';
  isNewOnChart = false;

  get isVisible(): boolean {
    return this.lineRenderableSeries.isVisible;
  }

  set isVisible(visible: boolean) {
    const annotations = this.baseChart.sciChartSurface.annotations
      .asArray()
      .filter(
        (annotation) =>
          annotation.id ===
          `${this._newAnnotationIndicatorId}-${this.type}_${this.lineNumber}`
      );
    annotations.forEach((annotation) => {
      annotation.isHidden = visible;
      (annotation as CustomAnnotation).svg.style.visibility =
        annotation.isHidden ? 'hidden' : 'visible';
    });
    this.lineRenderableSeries.isVisible = visible;
  }

  get propertiesText(): string {
    return ``;
  }

  constructor(
    options: TigerChartIndicatorCdlhammerOptions,
    type: TIGER_INDICATORS_ENUM
  ) {
    this.data = options.data;
    this.service = options.service;
    this.type = type;
    if (options.backgroundColor) {
      this.backgroundColor = options.backgroundColor;
    }

    this.mainLineId = `${this.type}-line-cdlhammer`;

    this.points = {
      output: [],
    };
    this.renderSeriesConfig = [
      {
        label: 'Background',
        id: this.mainLineId,
        color: this.backgroundColor,
        propertyColor: 'backgroundColor',
        thickness: 1,
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        dontShowTickness: true,
      },
    ];
    this.settings = [];
    this.styles = [];
    this.lineNumber = options.lineNumber;
  }

  create(options: TTigerChartIndicatorCreateOptions): BaseRenderableSeries[] {
    this.baseChart = options.base;

    const xValues = this.data.id_point;
    this.lineDataSeries = new XyDataSeries(this.baseChart.wasmContext);
    this.lineDataSeries.appendRange(xValues, [...this.points.output.slice(0)]);
    return this.renderSeriesConfig.map((config) => {
      this.lineRenderableSeries = new FastLineRenderableSeries(
        this.baseChart.wasmContext,
        {
          strokeThickness: config.thickness,
          stroke: config.color,
          dataSeries: this.lineDataSeries,
          id: `${config.id}_${this.lineNumber}`,
          isVisible: true,
          xAxisId: options.xAxisId,
        }
      );

      return this.lineRenderableSeries;
    });
  }

  updatePoints(): void {}

  append(xValue: number, data: TUpdateData, fullData: TData) {
    this.data = fullData;

    this.updatePoints();

    this.lineDataSeries.append(
      xValue,
      this.points['output'][this.points['output'].length - 1]
    );
  }

  insertRange(xValues: number[], fullData: TData): void {
    this.data = fullData;
    this.updatePoints();

    const s = this.baseChart.sciChartSurface.suspendUpdates();
    const arrayLength = xValues.length;
    try {
      this.lineDataSeries.insertRange(0, xValues, [
        ...this.points.output.slice(0, arrayLength),
      ]);

      const indexInitialValue = 0;
      for (
        let index = indexInitialValue;
        index < this.points.output.length;
        index++
      ) {
        this.lineDataSeries.update(index, this.points.output[index]);
      }
    } finally {
      s.resume();
    }
  }

  changeVisibility(): void {
    const annotations = this.baseChart.sciChartSurface.annotations
      .asArray()
      .filter(
        (annotation) =>
          annotation.id ===
          `${this._newAnnotationIndicatorId}-${this.type}_${this.lineNumber}`
      );
    annotations.forEach((annotation) => {
      annotation.isHidden = !annotation.isHidden;
      const typeVisibility = (annotation as CustomAnnotation).svg.style
        .visibility;
      (annotation as CustomAnnotation).svg.style.visibility =
        typeVisibility != 'visible' ? 'visible' : 'hidden';
    });
    this.lineRenderableSeries.isVisible = !this.lineRenderableSeries.isVisible;
  }

  update(index: number, data: TUpdateData, fullData: TData) {
    this.data = fullData;
    this.updatePoints();

    this.lineDataSeries.update(
      index,
      this.points['output'][this.points['output'].length - 1]
    );
  }

  updateSettings(): void {
    const s = this.baseChart.sciChartSurface.suspendUpdates();
    const dataSeriesCount = this.lineDataSeries.count();

    const lineValues = [
      ...this.points.output.slice(0, this.data.id_point.length),
    ];

    if (dataSeriesCount > lineValues.length) {
      this.lineDataSeries.removeRange(
        lineValues.length - 1,
        dataSeriesCount - lineValues.length
      );
    }

    try {
      lineValues.forEach((linePointValue, index) => {
        this.lineDataSeries.update(index, lineValues[index]);
      });
    } finally {
      s.resume();
    }
    this.onChange.next(null);
  }

  updateStyles(
    baseChart: TWebAssemblyChart,
    config: TTigerChartIndicatorRenderSeriesConfig
  ): void {
    switch (config.id) {
      case this.mainLineId:
        const annotations = this.baseChart.sciChartSurface.annotations
          .asArray()
          .filter(
            (annotation) =>
              annotation.id ===
              `${this._newAnnotationIndicatorId}-${this.type}_${this.lineNumber}`
          );
        annotations.forEach((annotation) => {
          (annotation as CustomAnnotation).svg.getElementsByTagName(
            'path'
          )[0].style.fill = config.color;
          (annotation as CustomAnnotation).svg.getElementsByTagName(
            'rect'
          )[0].style.fill = config.color;
        });
        break;
      default:
        break;
    }
    this.onChange.next(null);
  }

  delete() {
    this.baseChart.sciChartSurface.renderableSeries.remove(
      this.lineRenderableSeries
    );
  }

  setValue(property: keyof this, value: any) {
    const prop = this[property];
    if (typeof this[property] == 'number') {
      this[property] = +value as unknown as typeof prop;
    } else {
      this[property] = value;
    }
    this.updateSettings();
  }

  getValuesByIndex(index?: number): TTooltipIndicatorValue[] {
    const lineValues = this.lineDataSeries.getNativeYValues();
    const lineValue = {
      value: lineValues.get(index || lineValues.size() - 1),
      color: this.renderSeriesConfig[0].color,
    };
    return [];
  }

  resetConfiguration(): void {
    setDefaultConfiguration(
      this,
      this.baseChart,
      {},
      this.settings,
      this.renderSeriesConfig,
      this.styles,
      this.type
    );
  }
}
