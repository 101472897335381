import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { IActiveStrategy } from './strategy-preferences.interface';

@Injectable({
  providedIn: 'root',
})
export class StrategyPreferencesService extends CustomPreferencesService {
  public STRATEGY_STORAGE_KEYS = {
    ACTIVE_ID_STRATEGY: 'ACTIVE_STRATEGY',
    USER_ENABLE_STRATEGY: 'USER_ENABLE_STRATEGY',
  };

  set activeStrategy(params: IActiveStrategy | undefined) {
    this.setValueCustomPreferences(
      this.STRATEGY_STORAGE_KEYS.ACTIVE_ID_STRATEGY,
      params ? JSON.stringify(params) : JSON.stringify({})
    );
  }

  get activeStrategy(): IActiveStrategy {
    const strategy = JSON.parse(
      this.storage.getItem(this.STRATEGY_STORAGE_KEYS.ACTIVE_ID_STRATEGY)
    );
    if (strategy <= 0 || !strategy) return <any>null;
    return strategy;
  }

  set enableStrategy(enableStrategy: boolean) {
    this.setValueCustomPreferences(
      this.STRATEGY_STORAGE_KEYS.USER_ENABLE_STRATEGY,
      enableStrategy ? '1' : '0'
    );
  }

  get enableStrategy(): boolean {
    const strategyIsEnable = JSON.parse(
      this.storage.getItem(this.STRATEGY_STORAGE_KEYS.USER_ENABLE_STRATEGY)
    );
    if (isNullOrUndefined(strategyIsEnable)) return false;
    return strategyIsEnable === 1;
  }

  get idStrategyToSendOrder(): number {
    if (!this.enableStrategy) return 0;
    if (!this.activeStrategy) return 0;
    return this.activeStrategy.idStrategy;
  }

  constructor(public http: HttpClient) {
    super(http);
  }
}
