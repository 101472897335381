import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ContentChildren,
  ElementRef,
  OnDestroy,
  QueryList,
  ViewChild,
} from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { IContextMenuOptions } from '@shared/components/context-menu/context-menu.component';
import { WorkSpaceConfigs } from './config/workspace.configs';
import { WorkspaceDocComponent } from './parts/doc/workspace-doc.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceComponent implements OnInit, OnDestroy {
  @ViewChild('workSpaceBase', { static: true })
  private workSpaceBase!: ElementRef;

  @ContentChildren(WorkspaceDocComponent)
  workspaceItems!: QueryList<WorkspaceDocComponent>;

  isDisplayContextMenu!: boolean;
  contextMenuOptions!: IContextMenuOptions;

  private onUpdateComponentsInWS$!: Subscription;

  constructor(
    private configs: WorkSpaceConfigs,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.configs.workSpaceBase = this.workSpaceBase.nativeElement;
    /**
     * Isso é necessário pois como a variavel workspaceItems é atualizada
     * baseada no home.component, e com a estratégia OnPush
     * não ocorre a detecção automatica desse update.
     */
    this.onUpdateComponentsInWS$ =
      this.configs.onUpdateComponentsInWS.subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.onUpdateComponentsInWS$ && this.onUpdateComponentsInWS$.unsubscribe();
  }

  openModalAddComponentStart() {
    console.log('openModalAddComponentStart');
  }

  onCloseContextMenu = () => (this.isDisplayContextMenu = false);

  openAddComponentContextMenu = (event: MouseEvent | any) => {
    event.preventDefault();
    const position = {
      top: event.offsetY,
      left: event.offsetX,
    };
    this.contextMenuOptions = {
      type: 'TOOLS',
      title: 'Componentes',
      position,
    };
    this.isDisplayContextMenu = true;
  };
}
