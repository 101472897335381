import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-tooltip',
  template: `
    <div class="custom-tooltip">
      <p class="fs-6 title">{{ title }}</p>
      <span
        class="fs-7 description line-heigth"
        [innerHTML]="description"
      ></span>
    </div>
  `,
  styles: [
    `
      :host {
        position: absolute;
        max-width: 276px;
        overflow: hidden;
        pointer-events: none;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip {
        text-align: center;
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        background-color: var(--vm-neutral-strong);
        color: var(--vm-light);
        font-family: var(--vm-body-font-family);

        .title {
          font-weight: bold;
          line-height: 17px !important;
        }

        .description {
          font-weight: 600 !important;
          overflow-wrap: break-word;
          line-height: 17px;
          font-size: 10px;
        }

        p {
          margin: 0 !important;
        }
      }
    `,
  ],
})
export class CustomTooltipComponent implements ITooltipAngularComp {
  public params!: ITooltipParams;
  public title!: string;
  public description!: string;
  private dynamicDescriptionSubscription: Subscription = new Subscription()

  agInit(params: any): void {
    this.params = params;
    if (params.title != params.value) {
      this.description = this.params.value;
      return;
    }

    this.title = params.title || '';
    if(params.data?.dynamicDescription){
      this.setDynamicDescriptionHandler(params)
    }else{
      this.description = params.description || '';
    }
  }

  setDynamicDescriptionHandler = (params: any) => {
    this.description = this.params.data.descriptionHandler(params.description)
  }

}
