import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-info',
  templateUrl: './no-info.component.html',
  styles: [`
    :host(app-no-info) {
      display: contents;
    }
  `],
})
export class NoInfoComponent {
  @Input() text: string = 'Não há dados para esse ativo.';
  @Input() css: string = '';
}
