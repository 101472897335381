import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IWorkSpaceComponet } from '@core/interface';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { InvestorParticipationService } from '@shared/services/api/trademap/V9/investor-participation.service';
import { Subject, debounceTime, tap } from 'rxjs';
import { isWebViewContext } from 'src/app/desktop/integration.utils';
export const THEME_TIMER = 100;
@Component({
  selector: 'app-flow-investors',
  templateUrl: './flow-investors.component.html',
  styleUrls: ['./flow-investors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowInvestorsComponent
  extends RocketComponentBase
  implements OnChanges, OnDestroy
{
  @Input() component!: IWorkSpaceComponet;
  @Input() refComponent!: string;
  @Input() width!: number;
  @Input() height!: number;
  @Input() position!: { x: number; y: number } | undefined;

  public menuSelected: string = 'FLOW_B3';
  public atualB3Investor: any = null;
  public futuros: any = null;
  public data: any = null;
  public updatePosition: boolean = false;
  isDesktop = false;

  private _componentPositionChanged = new Subject<void>();

  constructor(
    private _investorParticipationService: InvestorParticipationService,
    private cdr: ChangeDetectorRef,
    activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
    this.isDesktop = isWebViewContext();
    this.getInfos();
    this._componentPositionChanged
      .pipe(
        debounceTime(300),
        tap(() => {
          this.updatePosition = true;
          this.cdr.detectChanges();
        })
      )
      .subscribe(() => {
        this.updatePosition = false;
        this.cdr.detectChanges();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { position } = changes;
    if (position?.currentValue) this._componentPositionChanged.next();
  }

  ngOnDestroy(): void {
    this._componentPositionChanged.unsubscribe();
  }

  protected initComponent(component: any): void {
    if (component) this.component = component;

    if (this.isDesktop) {
      this.height =
        this.component.metadata.componentResize?.height ??
        parseInt(this.component.metadata.layout.height);
      this.width =
        this.component.metadata.componentResize?.width ??
        parseInt(this.component.metadata.layout.width);
    }
  }

  public onChangeMenu(code: string) {
    this.menuSelected = code;
    this.cdr.detectChanges();
  }

  private getInfos(): void {
    this._investorParticipationService.getFuturos().subscribe((res) => {
      this.futuros = res;
      this.data = { ...res, ...this.data };
      this.cdr.detectChanges();
    });
    this._investorParticipationService
      .getAtualB3InvestorStatus()
      .subscribe((res) => {
        this.atualB3Investor = res;
        this.data = { ...res, ...this.data };
        this.cdr.detectChanges();
      });
  }
}
