<div class="h-100 overflow-y-hidden">
  <div class="d-flex justify-content-between p-2 h-100">
    <ng-container *ngFor="let flow of typesFlows; let i = index">
      <ng-container *ngIf="!isExpandedView || flowExpanded?.code === flow.code">
        <div
          class="col-8"
          [ngClass]="{
            'w-100': isExpandedView,
            'col-8': !isExpandedView,
            'border-right pr-3': i < typesFlows.length - 1 && !isExpandedView,
            'pl-3': i > 0 && !isExpandedView
          }"
        >
          <div class="d-flex align-items-center justify-content-between mb-1">
            <ng-container *ngIf="isExpandedView; else tabTitle">
              <button
                type="button"
                class="btn btn-fill-primary cursor-pointer fs-5 gap-2 hstack text-white"
                (click)="flowToExpand(null)"
              >
                <i class="icons text-brand-primary"> arrow_back_ios </i>
                {{ flow.label }}
              </button>
            </ng-container>
            <ng-template #tabTitle>
              <h5 class="fw-bolder">{{ flow.label }}</h5>
              <button
                type="button"
                class="btn btn-icon cursor-pointer bg-transparent p-0 text-right"
                (click)="flowToExpand(flow)"
              >
                <i class="icons icon-size-micro text-neutral-medium">
                  open_in_new
                </i>
              </button>
            </ng-template>
          </div>

          <ng-container *ngIf="cards?.length">
            <app-flow-cards
              [cards]="cards"
              type="flow_b3"
              [field]="flow.field"
              [isFilter]="flow.code === 'flow_b3'"
              [expanded]="isExpandedView"
            ></app-flow-cards>
          </ng-container>

          <ng-container *ngIf="flow.code === 'flow_b3'">
            <app-chart-evolution
              [data]="atualB3"
              [code]="flow.code"
              [title]="flow.titleChart"
              [containerWidth]="width"
              [containerHeight]="height"
              [updatePosition]="updatePosition || isExpandedView"
              [initialPeriod]="flowB3InitialPeriod"
            ></app-chart-evolution>
          </ng-container>

          <ng-container *ngIf="flow.code === 'accumulated_b3'">
            <app-acumulado-b3 [data]="acumuladoB3"></app-acumulado-b3>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
