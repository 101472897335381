import { Injectable } from '@angular/core';

import { filter, Observable, startWith, Subject, Subscription } from 'rxjs';
import { RxEvent } from '@shared/channel/rx-event';
import { SHARED_WORKER_EVENT_CHANNEL } from './shared-worker-events.constants';
import { SharedWorkerEventPayload } from './shared-worker-event.model';
import { StorageUtils } from '@shared/services/core/storage/storage.utils';

@Injectable({
  providedIn: 'root',
})
export class SharedWorkerEventsService {
  private componentConfigEvent$ = new Subject<SharedWorkerEventPayload>();
  static readonly INFINITY_READ_STREAM = true;
  private finalizer = new Subscription();
  private static readonly ACTION_PREFIX = 'SharedWorkerEvent_'; 
  constructor(
    private _rxEvent: RxEvent,
  ) {
    this.streamInit();
    this.setupCacheValueListener();
  }

  private setupCacheValueListener() {
    this.listenAllActions().subscribe((payload) => {
      if (payload.cacheLastValue) {
        StorageUtils.setItemSessionStorage(SharedWorkerEventsService.ACTION_PREFIX + payload.action, payload.data);
      }
    });
  }

  public emitSharedWorkerEvent(action: string, data: any = {}, cacheLastValue = false) {
    this._rxEvent.emit(SHARED_WORKER_EVENT_CHANNEL, {
      channel: SHARED_WORKER_EVENT_CHANNEL,
      action: action,
      cacheLastValue: cacheLastValue,
      data
    } as SharedWorkerEventPayload);
  }

  /* 
    "startWithIfNotCached" para tratar casos que deve possuir um valor default antes de qualquer emissao 
    "value" sera emitido caso ainda nao haja nenhum outro valor default e startWithIfNotCached = true 
  */
  public listenSelectedAction(action: string, startWithIfNotCached: boolean = false, value: any = undefined): Observable<SharedWorkerEventPayload> {
    const cachedValue = StorageUtils.getItemSessionStorage(SharedWorkerEventsService.ACTION_PREFIX + action);
    const obs = this.componentConfigEvent$
      .asObservable()
      .pipe(
        filter(
          (payload: SharedWorkerEventPayload) =>
            payload.action === action
        )
      );

    if (cachedValue || startWithIfNotCached) {
      return obs.pipe(
        startWith({
          channel: SHARED_WORKER_EVENT_CHANNEL,
          action: action,
          cacheLastValue: true,
          data: cachedValue ?? value
        } as SharedWorkerEventPayload)
      );
    }
    return obs;
  }

  private listenAllActions(): Observable<SharedWorkerEventPayload> {
    return this.componentConfigEvent$.asObservable();
  }

  private async streamInit() {
    try {
      const streamResult = await this._rxEvent.read(
        SHARED_WORKER_EVENT_CHANNEL
      );
      this.readStream(streamResult.stream);
      this.finalizer.add(streamResult.close);
    } catch (error) {
      console.error('Error reading context menu event', error);
    }
  }

  private async readStream(stream: ReadableStream) {
    const reader = stream.getReader();
    let ret;
    do {
      ret = await reader.read();
      if (!ret.done) {
        this.componentConfigEvent$.next(ret.value.data);
      }
    } while (SharedWorkerEventsService.INFINITY_READ_STREAM);
  }
}