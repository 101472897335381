<div class="h-100 overflow-x-hidden overflow-y-auto bg-neutral-stronger">
  <section
    *ngIf="form"
    [formGroup]="form"
    class="height-form p-2 border-bottom"
  >
    <app-dynamic-form
      *ngFor="let field of fieldsForm"
      [field]="field"
      [form]="form"
    >
      <div *ngIf="field.key === 'price'" #price>
        <div class="d-flex gap-2">
          <rocket-button
            class="w-100"
            [isSmall]="true"
            tooltip="Comprar a mercado"
            tooltipPosition="bottom"
            label="CM"
            [isDisabled]="loading?.MARKET_BUY_BUTTON || disableButtons"
            css="order-button bg-multibroker-buy w-100"
            (rocketClick)="
              sendClick(
                typeClicks.MARKET_BUY,
                orderParamHelper.STOCK_CHART_TRADE_BUTTON_BUY_MARKET
              )
            "
          >
          </rocket-button>
          <rocket-button
            class="w-100"
            [isSmall]="true"
            tooltip="Vender a mercado"
            tooltipPosition="bottom"
            label="VM"
            [isDisabled]="loading?.MARKET_SELL_BUTTON || disableButtons"
            css="order-button bg-multibroker-sell w-100"
            (rocketClick)="
              sendClick(
                typeClicks.MARKET_SELL,
                orderParamHelper.STOCK_CHART_TRADE_BUTTON_SELL_MARKET
              )
            "
          >
          </rocket-button>
        </div>
        <app-input-count
          [formControlName]="field.key"
          [isSmall]="field.isSmall"
          [isRequired]="field.required"
          [label]="field.label"
          [mask]="field.flaMask"
          [initialValue]="field.value"
          [incrementValue]="incrementPrice"
          [selectOnFocus]="field.selectOnFocus"
          [type]="field.type"
          css="margin-bottom"
          [tooltip]="field.tooltip"
          [tooltipDelay]="field.tooltipDelay"
          class="col-16"
          [enableScrollEvent]="true"
          [enableUpdateValueBtn]="true"
          (flaScroll)="updatePrice($event)"
          (updateValue)="priceControl.setValue(lastPrice)"
        >
        </app-input-count>
      </div>
      <div *ngIf="field.key === 'space' || field.key === 'space2'" #space></div>
    </app-dynamic-form>
    <div
      class="d-flex bottom-0 justify-content-end align-items-center pb-2 mt-0"
    >
      <div class="mb-0 fs-6 fw-bold text-body">
        <span tooltip="Valor total">
          Total:
          {{ total }}
        </span>
      </div>
    </div>
    <div class="buttons d-flex flex-column">
      <rocket-button
        class="w-100"
        [isSmall]="true"
        [label]="labelOrderBuy"
        [isDisabled]="loading?.MARKET_BUY_BUTTON || disableButtons"
        css="order-button bg-multibroker-buy w-100 buy"
        (rocketClick)="
          sendClick(
            typeClicks.BUY,
            orderParamHelper.STOCK_CHART_TRADE_BUTTON_BUY
          )
        "
      >
      </rocket-button>
      <rocket-button
        class="w-100"
        [isSmall]="true"
        [label]="labelOrderSell"
        [isDisabled]="loading?.MARKET_SELL_BUTTON || disableButtons"
        css="order-button bg-multibroker-sell w-100 sell"
        (rocketClick)="
          sendClick(
            typeClicks.SELL,
            orderParamHelper.STOCK_CHART_TRADE_BUTTON_SELL
          )
        "
      >
      </rocket-button>
      <div class="d-flex gap-2">
        <rocket-button
          class="w-100"
          [isSmall]="true"
          label="Zerar"
          css="order-button bg-brand-reset-position w-100"
          [isDisabled]="disableButtons"
          (rocketClick)="
            sendClick(
              typeClicks.RESET,
              orderParamHelper.STOCK_CHART_TRADE_BUTTON_RESET
            )
          "
          [tooltip]="invertAndResetTooltips.reset!"
        >
        </rocket-button>
        <rocket-button
          class="w-100"
          [isSmall]="true"
          label="Inverter"
          css="order-button bg-brand-support-secondary w-100"
          [isDisabled]="disableButtons"
          (rocketClick)="
            sendClick(
              typeClicks.INVERT,
              orderParamHelper.STOCK_CHART_TRADE_BUTTON_INVERT
            )
          "
          [tooltip]="invertAndResetTooltips.invert!"
        >
        </rocket-button>
      </div>
      <rocket-button
        class="w-100"
        [isSmall]="true"
        label="Cancelar Ordens"
        css="order-button bg-neutral-medium w-100"
        [isDisabled]="disableButtons"
        (rocketClick)="sendClick(typeClicks.CANCEL)"
        [tooltip]="invertAndResetTooltips.cancel!"
      >
      </rocket-button>
    </div>
    <div
      *ngIf="showFastOrderDaytradeStrategy"
      class="d-flex flex-column strategy align-items-center"
    >
      <app-strategy-selector
        [universalStrategy]="false"
        [isDropdown]="true"
        [isSelectable]="true"
        [isFastOrder]="true"
        [refComponent]="refComponent"
        [width]="width"
        customClass="fs-5"
      ></app-strategy-selector>
    </div>
  </section>
  <section class="d-flex flex-wrap row-gap mt-1 fs-6">
    <div
      *ngFor="let key of custodyDict.keys()"
      class="d-flex justify-content-center w-50"
    >
      <div
        *ngIf="custodyDict.get(key)"
        class="d-flex flex-column align-items-center"
      >
        <span>{{ custodyDict.get(key)!!.label }}</span>
        <span [class]="custodyDict.get(key)!!.class">{{
          custodyDict.get(key)!!.value
        }}</span>
      </div>
    </div>
  </section>
</div>
