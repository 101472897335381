import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { RentService } from '../../service/rent.service';
import {
  IStockFunds,
  ITableFundsStrategies,
} from '../../interface/rent.interface';
import { deepClone } from '@shared/rocket-components/utils';
import { RENT_ELEMENTS_ID } from '../../enum/rent.enum';
import {
  CdkVirtualScrollViewport,
  ScrollDispatcher,
} from '@angular/cdk/scrolling';
import { Subscription, filter } from 'rxjs';

@Component({
  selector: 'app-funds-table',
  templateUrl: './funds-table.component.html',
  styleUrls: ['./funds-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundsTableComponent
  implements OnChanges, AfterViewInit, OnDestroy
{
  @ViewChild('fundsChartContainer', { static: true })
  fundsChartContainer!: ElementRef;
  @ViewChild('scrollViewport') virtualScroll!: CdkVirtualScrollViewport;
  @Input() cdStock!: string;
  @Input() heightToAdjust: number = 110;
  @Input() css: string = 'px-2';
  @Input() cssContainer: string = 'p-2';
  @Input() limit: number = 8;
  @Input() isInfinityScroll = true;
  @Input() showTitle = true;
  public readonly elementsID = RENT_ELEMENTS_ID;
  public fundsStatus: any = {
    loading: true,
    error: false,
    message: '',
  };
  public fundsStrategies: ITableFundsStrategies[] = [
    {
      ref: 'long',
      title: 'Long',
      active: true,
    },
    {
      ref: 'short',
      title: 'Short',
      active: false,
    },
  ];
  private strategySelected: 'long' | 'short' = 'long';
  public stockFunds: IStockFunds[] = [];
  private _lineIndex: number = 0;
  private offset = 0;
  private scrollDispatcher$!: Subscription;
  loading = true;

  constructor(
    private scrollDispatcher: ScrollDispatcher,
    private _rentService: RentService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.addTableClass();
    if (this.isInfinityScroll) {
      this.scrollDispatcher$ = this.scrollDispatcher
        .scrolled()
        .pipe(
          filter(() => this.virtualScroll?.measureScrollOffset('bottom') === 0)
        )
        .subscribe(() => {
          this.offset++;
          this._getStockFunds(this.strategySelected);
          this.cdr.detectChanges();
        });
    }
  }

  ngOnDestroy() {
    this.scrollDispatcher$ && this.scrollDispatcher$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { cdStock } = changes;
    const strategySelected = this.fundsStrategies[0].active ? 'long' : 'short';
    if (cdStock && cdStock.currentValue) this._getStockFunds(strategySelected);
  }

  private _getStockFunds(strategy: 'long' | 'short' = 'long'): void {
    this._rentService
      .stockFunds(this.cdStock, strategy, this.limit, this.offset)
      .subscribe({
        next: (response) => {
          if (this.isInfinityScroll) {
            this.stockFunds = this.stockFunds.concat(deepClone(response));
          } else {
            this.stockFunds = deepClone(response);
          }
          this.fundsStatus.loading = false;
          this.fundsStatus.error = false;
          this.fundsStatus.message = '';
          this.loading = false;
          this.addTableClass();
          this.cdr.detectChanges();
        },
        error: () => {
          this.stockFunds = [];
          this.fundsStatus.error = true;
          this.loading = false;
          this.fundsStatus.message = 'Houve um erro inesperado';
          this.cdr.detectChanges();
        },
      });
  }

  public handlerTableFunds(newStrategy: 'long' | 'short'): void {
    this.stockFunds = [];
    this.offset = 0;
    this.fundsStrategies = this.fundsStrategies.map((strategy) => {
      strategy.active = strategy.ref === newStrategy;
      return strategy;
    });
    this.loading = true;
    this.fundsStatus.loading = true;
    this.fundsStatus.error = false;
    this.strategySelected = newStrategy;
    this._getStockFunds(newStrategy);
    this.cdr.detectChanges();
  }

  public displayColumnChart(fund: IStockFunds, lineIndex: number): void {
    const expandFund = !this.stockFunds[lineIndex].expanded;
    this.stockFunds[lineIndex].expanded = expandFund;
    if (expandFund) {
      this._lineIndex = lineIndex;
      this.stockFunds[lineIndex].loading = true;
      this.stockFunds[lineIndex].error = false;
      this._getFundData(fund.id_fund);
    }
    this.cdr.detectChanges();
  }

  private _getFundData(idFund: number): void {
    this._rentService.fundData(idFund, this.cdStock).subscribe({
      next: (response) => {
        this.stockFunds[this._lineIndex].loading = false;
        this.stockFunds[this._lineIndex].error = false;
        this.stockFunds[this._lineIndex].fundsInfo = response.result;
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.error('COLUMN_SERVICE_ERROR', err);
        this.stockFunds[this._lineIndex].loading = false;
        this.stockFunds[this._lineIndex].error = true;
        this.cdr.detectChanges();
      },
    });
  }

  private addTableClass() {
    if (!this.virtualScroll) return;
    const nativeElement =
      this.virtualScroll.elementRef.nativeElement.querySelector(
        '.cdk-virtual-scroll-content-wrapper'
      );
    if (!nativeElement?.classList.contains('table')) {
      nativeElement?.classList.add('table');
    }
  }
}
