import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { FlaTSize } from '@shared/rocket-components/components/icon/types/icon.types';

@Component({
  selector: 'app-broker-logo',
  template: `
    <ng-container *ngIf="logo; else emptyLogo">
      <img [src]="logo" [height]="imageSize" [width]="imageSize" [ngClass]="{'filter-invert': filterInvert}"/>
    </ng-container>
    <ng-template #emptyLogo>
      <i [class]="iconSize + ' icons'">sync</i>
    </ng-template>
  `,
  styles: [`
    .filter-invert {
      filter: invert(1);
    }
  `],
  standalone: true,
  imports: [CommonModule],
})
export class BrokerLogoComponent {
  @Input() logo: string | SafeResourceUrl | undefined = '';
  @Input() imageSize: number = 32;
  @Input() iconSize: FlaTSize = 'icon-size-md';
  @Input() filterInvert: boolean = false;
}
