import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlaIconComponent } from './icon.component';

@NgModule({
  declarations: [FlaIconComponent],
  exports: [FlaIconComponent],
  imports: [CommonModule],
})
export class FlaIconModule {}
