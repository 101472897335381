<fla-card class="d-flex">
  <fla-card-custom #card css="w-100 m-2 p-2">
    <div class="card-body">
      <div class="w-100 d-flex">
        <div class="mr-2">
          <rocket-button
            type="icon"
            css="min-w-0 pr-0"
            tooltipPosition="bottom"
            [tooltip]="'Voltar'"
            (rocketClick)="goBack()"
          >
            <fla-icon
              name="arrow_back_ios"
              size="icon-size-micro"
              css="text-brand-primary p-0 pl-1"
            ></fla-icon>
          </rocket-button>
        </div>

        <div class="w-100 pr-3">
          <news-card-header [news]="news"></news-card-header>
          <p
            class="fw-bold fs-5 mb-2"
            [innerHTML]="
              news?.type == 'corporate_event' ? news?.texto : news?.headline
            "
          ></p>
          <div
            *ngIf="!isDocument && news?.type != 'corporate_event'"
            class="news-text"
            [innerHTML]="news?.texto"
          ></div>

          <div class="w-100 mb-2">
            <ngx-extended-pdf-viewer
              *ngIf="isDocument && document"
              [showDrawEditor]="true"
              [showTextEditor]="true"
              [showPrintButton]="true"
              [showDownloadButton]="true"
              [textLayer]="false"
              [src]="document"
              [height]="height - 180 + 'px'"
              [showHandToolButton]="true"
              [showOpenFileButton]="false"
              [showPresentationModeButton]="true"
              [showPropertiesButton]="false"
              [showRotateButton]="false"
              [handTool]="true"
              [showSecondaryToolbarButton]="false"
              [showSidebarButton]="false"
              [showSpreadButton]="false"
            ></ngx-extended-pdf-viewer>
          </div>
        </div>
      </div>

      <div
        class="w-100 d-flex justify-content-between position-fixed nav-buttons"
      >
        <div class="d-flex flex-column justify-content-center">
          <rocket-button
            type="icon"
            css="min-w-0 pr-0 "
            tooltip="Anterior"
            tooltipPosition="bottom"
            [isDisabled]="selectedNewsIndex == 0"
            (rocketClick)="previousNews()"
          >
            <fla-icon
              name="arrow_back_ios"
              size="icon-size-md"
              css="p-0 pl-1 arrow"
            ></fla-icon>
          </rocket-button>
        </div>

        <div class="d-flex flex-column justify-content-center">
          <rocket-button
            type="icon"
            css="min-w-0 pr-0"
            tooltip="Próxima"
            tooltipPosition="bottom"
            [style.marginRight.px]="2"
            [isDisabled]="selectedNewsIndex == newsListLength - 1"
            (rocketClick)="nextNews()"
          >
            <fla-icon
              name="arrow_forward_ios"
              size="icon-size-md"
              css="p-0 pl-1 arrow"
            ></fla-icon>
          </rocket-button>
        </div>
      </div>
    </div>
  </fla-card-custom>
</fla-card>
