<fla-tab css="mb-3">
  <ng-container *ngFor="let tab of tabs">
    <fla-tab-header
      [ref]="tab.ref"
      [title]="tab.title"
      [active]="tab.active"
      (flaChange)="changeTab(tab.ref)"
    ></fla-tab-header>
  </ng-container>
</fla-tab>

<ng-container *ngIf="!loading; else loadingRef">
  <ng-container *ngIf="acquisitions.length; else noInfo">
    <div class="d-flex flex-column w-100 h-100 my-3">
      <cdk-virtual-scroll-viewport itemSize="110" class="h-100">
        <ng-container *ngFor="let item of acquisitions">
          <div class="mb-2 h-100">
            <app-repurchase-card [repushaseInfo]="item"></app-repurchase-card>
          </div>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </div>
  </ng-container>
</ng-container>

<ng-template #loadingRef>
  <div class="h-100">
    <app-loading></app-loading>
  </div>
</ng-template>

<ng-template #noInfo>
  <div class="w-100 d-flex justify-content-center align-items-center">
    <app-no-info [text]="emptyMessage"></app-no-info>
  </div>
</ng-template>
