import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  TTokenAuthenticatorConfigurationStatus,
  TTokenPreferenceAuthenticator,
} from '@core/layout/header/modal-token-config/types';
import { RestService } from '@shared/services/rest/rest.service';
import { Observable, catchError, map, of } from 'rxjs';
import { IAPIServiceResponse } from './interface/apiResponse';
import { system } from '@core/system/system.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService extends RestService {
  override _url: string = 'api/authentication';

  constructor(http: HttpClient) {
    super(http);
  }

  sendAuthenticatorConfigMessage = (
    password: string,
    authenticator: TTokenPreferenceAuthenticator
  ): Observable<IAPIServiceResponse> => {
    return this.post('v3/otp/create-and-send-pin-code', {
      password: btoa(password),
      cdOrigin: authenticator,
    }).pipe(
      map((res: any) => {
        if (!res.data || !res.data.success)
          return this.handlerServiceError(res);
        return { success: true, message: res.data.result.next_interaction };
      }),
      catchError((error) => of(this.handlerServiceError(error)))
    );
  };

  getQRCodeUrl = (
    code: string,
    authenticator: TTokenPreferenceAuthenticator
  ): Observable<IAPIServiceResponse> => {
    return this.post('v3/otp/get-qr-code-url', {
      code,
      cdOrigin: authenticator,
    }).pipe(
      map((res: any) => {
        if (!res.data || !res.data.success)
          return this.handlerServiceError(res);
        return { success: res.data.success, message: res.data.result };
      }),
      catchError((error) => of(this.handlerServiceError(error)))
    );
  };

  activateAuthenticatorConfig = (
    code: string,
    isActiveSeed: boolean,
    cdOrigin?: string
  ): Observable<IAPIServiceResponse> => {
    return this.post('v3/otp/validate-investor-code', {
      code,
      isActiveSeed,
      cdOrigin,
    }).pipe(
      map((res: any) => {
        if (!res.data || !res.data.success)
          return this.handlerServiceError(res);
        // this.activateAuthenticatorConfig$.next({ isForce: false });
        return {
          success: res.data.success,
          message: res.data.result,
        };
      }),
      catchError((error) => of(this.handlerServiceError(error)))
    );
  };

  saveAuthenticatorPreference = (
    authenticator: TTokenPreferenceAuthenticator
  ): Observable<IAPIServiceResponse> => {
    system.authenticationMethod = undefined;
    return this.post('v3/otp/save-otp-token-preference', {
      otpOrigin: authenticator,
    }).pipe(
      map((res: any) => {
        if (!res.data || !res.data.success) throw res;
        return res;
      })
    );
  };

  disconnectCurrentToken = (
    password: string,
    authenticator: TTokenPreferenceAuthenticator
  ): Observable<IAPIServiceResponse> => {
    return this.post('v3/otp/inactivate-investor-seed', {
      password: btoa(password),
      cdOrigin: authenticator,
    }).pipe(
      catchError((error) => of(this.handlerServiceError(error))),
      map((res: any) => {
        if (!res.data || !res.data.success || !res.data.result) throw res;
        system.authenticationMethod = undefined;
        return { success: res.data.success, message: res.data.result };
      })
    );
  };

  checkAuthenticatorConfiguration =
    (): Observable<TTokenAuthenticatorConfigurationStatus> => {
      return this.post('v3/otp-remote-validation/consult', {}).pipe(
        map((res: any) => {
          if (!res.data || !res.data.success) return 'WAITING';
          return res.data.result;
        })
      );
    };
}
