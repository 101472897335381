<div *ngIf="data" class="d-flex w-100 justify-content-between fs-6 p-1">
  <ng-container *ngIf="isOrder">
    <div class="d-flex align-items-center">
      <ng-container *ngIf="componentKey === 'ORDER_OPEN'">
        <ng-container *ngTemplateOutlet="cancelAllTemplate"></ng-container>
      </ng-container>
      <div class="px-2">{{ data.dh_creation_time }}</div>
      <div class="px-2">{{ data.cd_stock }}</div>
    </div>
    <div class="d-flex align-items-center">
      <div class="px-2">{{ data.qtty | number : '1.0-0' }} Qtd</div>
      <div class="px-2">{{ data.qtty_executed }} Exec</div>
    </div>
  </ng-container>
  <ng-container *ngIf="isCustody">
    <div class="d-flex align-items-center">
      <app-reset-all-positions
        [disableCancelAllPositions]="disableCancelAllPositions"
        (resetAllPosition)="resetAllPosition($event)"
      ></app-reset-all-positions>
      <div class="px-2">{{ data.cd_stock }}</div>
      <div class="px-2">{{ data.vlPriceAvg }} Vl. Total</div>
      <div *ngIf="!isCompact" class="px-2">
        {{ data.qttyFinal | number : '1.2-2' }} Qtd
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div *ngIf="!isCompact" class="px-2">
        <span [ngClass]="data.pnlDayColor">
          {{ data.pnlDay | number : '1.2-2' }}
        </span>
        P&L Dia
      </div>
      <div class="px-2">
        <span [ngClass]="data.pnlColor">
          {{ data.pnlTotal | number : '1.2-2' }}</span>
        P&L Tot
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isAlert">
    <div class="d-flex align-items-center">
      <ng-container *ngTemplateOutlet="cancelAllTemplate"></ng-container>
      <div class="px-2">{{ data.alertsQtty }}</div>
      <div class="px-2">{{ data.cd_stock }}</div>
    </div>
  </ng-container>
</div>

<ng-template #cancelAllTemplate>
  <div>
    <rocket-button
      [tooltip]="tooltipCancel"
      label="Cancelar"
      [isSmall]="true"
      [isDisabled]="disableCancelAllAlertsOrOrders"
      css="fs-7 btn-cancel-all bg-feedback-error text-light"
      (rocketClick)="cancelAll()"
    ></rocket-button>
  </div>
</ng-template>
