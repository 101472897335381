<rocket-modal>
  <rocket-modal-header>
    <h4 class="mb-2 fw-bold">Adicionar ativos</h4>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <section class="d-flex mb-4">
      <form [formGroup]="newStockForm" class="w-100">
        <div class="position-relative">
          <fla-input
            fla-focus
            css="margin-bottom"
            formControlName="search"
            placeholder="Digite aqui a sua busca"
            type="text"
            decidePosition="prefix"
            [autoComplete]="false"
          >
            <div class="prefix cursor-pointer">
              <fla-icon name="search" size="icon-size-sm"></fla-icon>
            </div>
          </fla-input>
          <div class="position-absolute pr-3 right-0 custom-suffix">
            <button
              type="button"
              class="btn btn-icon"
              [ngClass]="{ 'text-brand-primary': hasSomeStockFilter }"
              (click)="openConfig()"
            >
              <span
                class="material-icons-outlined icon-size-sm"
                title="Configurações de listagem"
              >
                filter_list
              </span>
            </button>
          </div>
        </div>
      </form>
    </section>

    <app-most-search
      [isSearching]="isSearch"
      [searchedStock]="searchedStock"
      [listStocksSelected]="stockList"
      [noData]="noData"
      (selected)="selectStock($event)"
      (searchAgain)="searchAgain()"
    ></app-most-search>
  </app-rocket-modal-body>
  <rocket-modal-footer>
    <rocket-button
      css="mr-3"
      type="fill"
      (rocketClick)="onClose()"
      label="Cancelar"
    >
    </rocket-button>
    <rocket-button
      type="default"
      [isDisabled]="!listStocksSelected.length"
      (rocketClick)="onSave()"
      label="Salvar"
    >
    </rocket-button>
  </rocket-modal-footer>
</rocket-modal>
