import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ISearchStock } from '@core/interface';
import { BusinessProfileService } from '../business-profile.service';
import { ABOUT_TABS } from '../business-profile.const';
import { IBusinessProfileTab } from '../business-profile.interface';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutComponent {
  @Input() set stockSelected(stockSelected: ISearchStock) {
    if (stockSelected) {
      this._stockSelected = stockSelected;
      this.getCompanyInfoByTypeStock();
    }
  }
  @Input() refComponent!: string;
  _stockSelected!: ISearchStock;
  companyInfo!: any | undefined;
  tabs = ABOUT_TABS;
  selectedTab = ABOUT_TABS[0];
  loading = true;

  constructor(
    private cdr: ChangeDetectorRef,
    private businessProfileService: BusinessProfileService
  ) {}

  private getCompanyInfoByTypeStock() {
    this.businessProfileService
      .getCompanyInfoByTypeStock(this._stockSelected)
      .then((data) => {
        this.companyInfo = data;
        this.loading = false;
        this.cdr.detectChanges();
      })
      .catch(() => {
        this.loading = false;
        this.companyInfo = undefined;
        this.cdr.detectChanges();
      });
  }

  selectTab(tab: IBusinessProfileTab) {
    this.selectedTab = tab;
    this.cdr.detectChanges();
  }
}
