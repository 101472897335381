import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { RestService } from '@shared/services/rest/rest.service';
import {
  TOptions,
  TOptionsExpirationDatesResponse,
  TOptionsFilter,
  TOptionSmileResponse,
  TOptionsOperation,
  TOptionsStrategies,
  TStrategyOption,
} from '@shared/components/options/types';

@Injectable({
  providedIn: 'root',
})
export class OptionsService extends RestService {
  override _url: string = 'api/trademap';

  constructor(http: HttpClient) {
    super(http);
  }

  public getStockOptions = (
    cdStockOption: string,
    id_exchange: number,
    filter: TOptionsFilter
  ): Observable<TOptions[] | undefined> =>
    this.post<any>('v3/option/find-options', {
      cdStockOption,
      id_exchange,
      filters: {
        strike_state: this._removeAll(filter.status),
        in_option: this._removeAll(filter.type),
        kind_option: this._removeAll(filter.style),
        already_traded: filter.alreadNegotiated,
        dt_expiration_option: filter.date,
        qt_limit: '300',
      },
    }).pipe(
      map((response) => {
        if (
          !response.data ||
          !response.data?.success ||
          !response.data?.result
        ) {
          return;
        }
        return response.data.result;
      })
    );

  public getStrategies = (
    cd_stock_option: string,
    id_exchange: number,
    dt_expiration_option: string
  ): Observable<TOptionsStrategies[] | undefined> =>
    this.post<any>('v1/option/find-strategies', {
      cd_stock_option,
      id_exchange,
      dt_expiration_option,
    }).pipe(
      map((response) => {
        if (
          !response.data ||
          !response.data?.success ||
          !response.data?.result
        ) {
          return;
        }
        return response.data.result;
      })
    );

  public getOperations = (cdStock: string): Observable<TOptionsOperation[]> =>
    this.post<any>('v2/option/list-operations', {}).pipe(
      map((response) => {
        if (
          !response.data ||
          !response.data?.success ||
          !response.data?.result
        ) {
          return [];
        }

        return response.data.result.filter(
          (operation: any) => operation.cd_stock_option == cdStock
        );
      })
    );

  public deleteOperation = (
    id_operation: number
  ): Observable<TOptionsOperation[]> =>
    this.post<any>('v1/option/delete-operation', { id_operation }).pipe(
      map((response) => {
        return response.data?.result;
      })
    );

  public getStockExpirationDates = (
    cd_stock_option: string,
    id_exchange: number
  ): Observable<TOptionsExpirationDatesResponse> =>
    this.post<any>('v1/option/get-expiration-dates', {
      cd_stock_option,
      id_exchange,
    }).pipe(
      map((response) => {
        if (!response || !response.data || !response?.data?.success)
          throw new Error('');
        return response.data?.result;
      })
    );

  public getSmileAndVolatility = (
    dt_expiration_option: number,
    id_exchange: number,
    cd_stock: string
  ): Observable<TOptionSmileResponse> =>
    this.post<any>('v1/option/get-smile-volatility', {
      dt_expiration_option,
      id_exchange,
      cd_stock,
    }).pipe(
      map((response) => {
        return response.data?.result;
      })
    );

  public getVolatilityHistory = (
    dt_expiration_option: number,
    id_exchange: number,
    cd_stock: string
  ): Observable<TOptionsExpirationDatesResponse> =>
    this.post<any>('v1/option/get-volatility-history', {
      dt_expiration_option,
      id_exchange,
      cd_stock,
    }).pipe(
      map((response) => {
        return response.data?.result;
      })
    );

  public addOption = (
    cdStockOption: string,
    legs: TStrategyOption[]
  ): Observable<TOptionsExpirationDatesResponse> =>
    this.post<any>('v1/option/add-operation', {
      cdStockOption,
      legs,
    }).pipe(
      map((response) => {
        return response.data?.result;
      })
    );

  public createOption = (
    cdStockOption: string,
    legs: TStrategyOption[],
    ds_operation: string,
    vl_cost: number
  ): Observable<TOptionsExpirationDatesResponse> =>
    this.post<any>('v1/option/create-operation-exchange', {
      cdStockOption,
      legs,
      ds_operation,
      vl_cost,
    }).pipe(
      map((response) => {
        return response.data?.result;
      })
    );

  private _removeAll(value: string) {
    return value !== 'ALL' ? value : null;
  }
}
