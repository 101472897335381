<rocket-modal>
  <rocket-modal-header [hideCloseButton]="true">
    <div class="w-100 d-flex justify-content-between">
      <h2 class="modal-title">Gerenciar Lista</h2>
      <rocket-button (rocketClick)="onClose(false)" type="icon">
        <fla-icon fla-icon name="clear"></fla-icon>
      </rocket-button>
    </div>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <div class="d-flex flex-column gap-3">
      <div class="max-height-manage-list d-flex flex-column gap-2">
        <div *ngFor="let l of list">
          <div
            class="d-flex justify-content-between align-items-center border p-2 round mr-2"
            *ngIf="!l.isDeleted"
          >
            <input
              class="border-none bg-transparent text-white"
              (click)="startEdit(l)"
              [(ngModel)]="l.list.nm_stock_list"
              type="text"
            />
            <rocket-button
              (rocketClick)="removeList(l)"
              type="icon"
              [isDisabled]="disableDeleteList"
            >
              <fla-icon fla-icon name="delete"></fla-icon>
            </rocket-button>
          </div>
        </div>
      </div>
      <div>
        <rocket-button
          (rocketClick)="createList()"
          label="Criar Lista"
        ></rocket-button>
      </div>
    </div>
  </app-rocket-modal-body>
  <rocket-modal-footer>
    <rocket-button
      type="fill"
      label="Cancelar"
      css="mr-4"
      (rocketClick)="onClose(false)"
    ></rocket-button>
    <rocket-button label="Salvar" (rocketClick)="onSave()"></rocket-button>
  </rocket-modal-footer>
</rocket-modal>
