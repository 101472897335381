import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rocket-icons',
  templateUrl: './rocket-icons.component.html',
  styleUrls: ['./rocket-icons.component.scss'],
})
export class RocketIconsComponent {
  @Input() name!: string;
  @Input() color: string = 'var(--vm-light)';
  @Input() width: number = 16;
  @Input() height: number = 16;
}
