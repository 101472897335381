<div
  class="super-dom-config round bg-neutral-strong position-absolute"
  [style.top.px]="position.top"
  [style.left.px]="position.left"
  [style.z-index]="99"
  [style.display]="isTour ? 'none' : 'block'"
  (mouseleave)="onFocusOut()"
>
  <div class="list-group list-group-flush">
    <div class="header-context-menu">
      <span>Super Dom</span>
    </div>
    <div class="d-flex flex-column">
      <section [id]="parent_id" class="border-none">
        <button
          type="button"
          class="list-group-item list-group-item-action context-item-button"
          (click)="showOrColsePowerBar()"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            [name]="!showPowerBar ? 'visibility_off' : 'visibility'"
          ></fla-icon>
          Pressão
        </button>
        <button
          type="button"
          class="list-group-item list-group-item-action context-item-button"
          (click)="showOrColseDetalhes()"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            [name]="!showDetail ? 'visibility_off' : 'visibility'"
          ></fla-icon>
          Detalhes ativo
        </button>
        <button
          type="button"
          class="list-group-item list-group-item-action context-item-button"
          (click)="showOrColseDetalhesCustody()"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            [name]="!showCustodyDetail ? 'visibility_off' : 'visibility'"
          ></fla-icon>
          Detalhes custódia
        </button>
        <button
          type="button"
          class="list-group-item list-group-item-action context-item-button"
          (click)="toggleSubContext()"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            [name]="!showVolume ? 'visibility_off' : 'visibility'"
          ></fla-icon>
          Book
          <div class="h-100 mt-1 position-absolute right-0 top-0">
            <fla-icon
              *ngIf="!showSubContextBook"
              name="expand_more"
              css=""
              size="icon-size-sm"
            ></fla-icon>
            <fla-icon
              *ngIf="showSubContextBook"
              name="expand_less"
              css=""
              size="icon-size-sm"
            ></fla-icon>
          </div>
        </button>
        <div *ngIf="showSubContextBook">
          <button
            type="button"
            class="list-group-item list-group-item-action context-item-button"
            [disabled]="showVolume && !showColumnBook"
            (click)="selectViewBookType('BOOK_CENTER')"
          >
            Centralizado
          </button>
          <button
            type="button"
            class="list-group-item list-group-item-action context-item-button"
            [disabled]="showVolume && showColumnBook"
            (click)="selectViewBookType('BOOK_COLUMN')"
          >
            À esquerda
          </button>
          <button
            type="button"
            class="list-group-item list-group-item-action context-item-button"
            [disabled]="!showVolume"
            (click)="disableVolume()"
          >
            {{ !showVolume ? 'Oculto' : 'Ocultar' }}
          </button>
        </div>
        <button
          type="button"
          class="list-group-item list-group-item-action context-item-button"
          (click)="showAggressor()"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            [name]="!hideAggressor ? 'visibility_off' : 'visibility'"
          ></fla-icon>
          Agressor
        </button>
        <button
          type="button"
          class="list-group-item list-group-item-action context-item-button"
          (click)="showOrCloseAtPrice()"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            [name]="!showAtPrice ? 'visibility_off' : 'visibility'"
          ></fla-icon>
          Vol x Preço
        </button>
        <button
          type="button"
          class="list-group-item list-group-item-action context-item-button"
          (click)="positionShow()"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            [name]="!showPosition ? 'visibility_off' : 'visibility'"
          ></fla-icon>
          Posição
        </button>
      </section>
      <hr class="separator-context-menu" />
      <div class="d-flex flex-column">
        <button
          type="button"
          class="list-group-item list-group-item-action border-none fs-6 context-item-button"
          disabled
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="refresh"
          ></fla-icon>
          Restaurar
        </button>
        <button
          type="button"
          class="list-group-item list-group-item-action border-none fs-6 context-item-button"
          (click)="onTour()"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="help_outline"
          ></fla-icon>
          Ajuda
        </button>
      </div>
    </div>
  </div>
</div>
