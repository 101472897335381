import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TermsService } from '@shared/services/api/terms/v2/terms.service';
import { OnboardService } from '@shared/services/api/trademap/onboard';
import { ONBOARD_STEPS } from '@shared/services/api/trademap/onboard/data';
import { catchError, of, tap } from 'rxjs';
import { AuthService } from '@shared/services';
import { RecaptchaComponent } from '../recaptcha/recaptcha.component';
import { AccountPreferences } from '@shared/services/core/custom-preferences/account/account-preferences.services';
import { FlaToastService } from '@shared/rocket-components/toast';
import { WorkspacesPreferences } from '@shared/services/core/custom-preferences/workspace';

interface IOnboardError {
  error: boolean;
  createError: boolean;
  message: string;
}

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styles: [
    `
      :host(app-welcome) {
        ::ng-deep.grecaptcha-badge {
          display: none !important;
        }
      }
      .pointer {
        cursor: pointer;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeComponent implements OnInit, OnDestroy {
  @ViewChild('recaptcha') public recaptchaElement!: RecaptchaComponent;
  public concluded: boolean = false;
  public authenticating: boolean = false;
  public displayCountDown: boolean = true;
  public timeRedirect: number = 45;
  public errorOnCreateAccount: IOnboardError = {
    error: false,
    createError: false,
    message: '',
  };

  private _authenticated: boolean = false;

  constructor(
    private _onboardService: OnboardService,
    private _authService: AuthService,
    private _termService: TermsService,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    private _accountPreferences: AccountPreferences,
    private _toaster: FlaToastService,
    private _workspacesPreferences: WorkspacesPreferences
  ) {}

  ngOnInit(): void {
    this._onboardGogogo();
  }

  ngOnDestroy(): void {
    this._onboardService.clearOnboardData();
  }

  private _onboardGogogo() {
    this._onboardService.onOnboardGogogo().subscribe({
      next: () => {
        this._onboardService.setOnboardStatus(ONBOARD_STEPS.COMPLETED);
        this.concluded = true;
        this._cdr.detectChanges();
      },
      error: () => {
        this.errorOnCreateAccount = {
          error: true,
          createError: true,
          message: '',
        };
        this._cdr.detectChanges();
      },
    });
  }

  public authenticate(recaptcha: string): void {
    this._authService
      .authenticate(
        {
          username: this._onboardService.document.replace(/\D/g, ''),
          password: this._onboardService.password,
          recaptcha,
        },
        true
      )
      .pipe(tap(() => (this._authService.origin = 'NEW_REGISTER')))
      .subscribe({
        next: () => {
          this._accountPreferences.displayTMWelcomeModal = true;
          this._workspacesPreferences.displayInitialTour = true;
          this._approveTerms();
        },
        error: () => {
          this.authenticating = false;
          this._authenticated = false;
          this._toaster.show({
            text: 'No momento, não foi possível acessar a sua conta. Por favor, tente novamente através da página de login.',
            isHtml: false,
            type: 'error',
          });
          this._router.navigateByUrl('/');
          this._cdr.detectChanges();
        },
      });
  }

  private _approveTerms(): void {
    this._termService
      .approveTerm(
        1,
        this._onboardService.password,
        true,
        this._onboardService.phoneNumber.replace(/^55/, '')
      )
      .pipe(catchError(() => of(null)))
      .subscribe(() => {
        this._authenticated = true;
        this.authenticating = false;
        this.redirAfterAuth();
        this._cdr.detectChanges();
      });
  }

  public redirAfterAuth(): void {
    if (this._authenticated) {
      this._authService.appInit();
      return;
    }
    this.redirToLogin();
  }

  public redirToLogin(): void {
    this._router.navigate(['/auth/login']);
  }

  public backToForm(): void {
    this._router.navigate(['auth/register/create']);
  }

  public doRecaptcha(action: 'CLICK' | 'COUNT_DOWN'): void {
    this.authenticating = true;
    if (action === 'CLICK') {
      this.displayCountDown = false;
      this._cdr.detectChanges();
    }
    this.recaptchaElement.execute();
  }

  public onRecaptchaError(): void {
    this._authenticated = false;
    this.authenticating = false;
    this._cdr.detectChanges();
  }
}
