import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';
import { OptionsComponents } from '../component-list';

export const RANKING_BROKER_COMPONENT: any = (options?: OptionsComponents) => ({
  name: 'Flash de mercado',
  id: COMPONENTS_NAMES_ENUM.RANKING_BROKER,
  icon: '',
  cod: 'RANKING_BROKER',
  data: null,
  close: true,
  fullscreen: true,
  link: true,
  unitOfMeasurement: options?.unit ?? 'px',
  height: options?.height ?? 468,
  width: options?.width ?? 832,
  minWidth: '745px',
  left: options?.left ?? 'calc(15% + 5px)',
  top: options?.top ?? '0px',
  label: 'Flash de mercado',
  noPublished: false,
  hasBorder: false,
  divider: false,
  iconSvg: 'RANKING_BROKER',
});
