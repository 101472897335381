import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { randomId } from '@shared/rocket-components/utils';
import { ScichartService } from '@shared/tiger-chart/services/scichart.service';
import {
  CursorModifier,
  CursorTooltipSvgAnnotation,
  EAxisAlignment,
  ELabelAlignment,
  FastColumnRenderableSeries,
  IRenderableSeries,
  NumericAxis,
  SeriesInfo,
  TWebAssemblyChart,
  TextLabelProvider,
  Thickness,
  XyDataSeries,
} from 'scichart';
import { getVolumeText } from 'src/app/utils/utils.functions';
import {
  periodFields,
  periods,
  periodsEvolution,
  periodsEvolutionFuture,
} from '../../consts/period';
import { FlaSelectComponent } from '@shared/rocket-components/components';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { colorsAcumulados } from '../../consts/colors';
import { FlowInvestorsService } from '../../flow-investors.service';
import { DATE_PICKER_CONFIGS } from '@shared/rocket-components/components/datepicker';
import {
  Subject,
  Subscription,
  auditTime,
  debounceTime,
  delay,
  filter,
} from 'rxjs';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { THEME_TIMER } from '../../flow-investors.component';
import { CHART_COLORS } from '@shared/tiger-chart/colors';

@Component({
  selector: 'app-chart-evolution',
  templateUrl: './chart-evolution.component.html',
  styleUrls: ['./chart-evolution.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartEvolutionComponent implements OnChanges, OnDestroy {
  @ViewChild('select') select!: FlaSelectComponent;

  @Input() containerWidth: number = 0;
  @Input() containerHeight: number = 0;
  @Input() isLeftSideChart: boolean = false;
  @Input() updatePosition: boolean = false;

  @Input() public set data(data: any) {
    if (data) {
      this._data = data;
      this._formatField$.next();
    }
  }
  @Input() public set code(code: string) {
    this.refChart = `${code}_${this.refChart}`;
    this._code = code;
    this.cdr.detectChanges();
  }
  @Input() public title: string = '';

  @Input() set initialPeriod(period: { id: string; label: string }) {
    this.periodSelectedLabel = period.label;
    this.periodSelected = period.id;
    this.form.get('period')?.setValue(period.id);
  }

  public periodSelectedLabel: string = 'Diário';
  public periodSelected: string = 'daily';
  public periodsEvolution = periodsEvolution;
  public periodsSelector: any = periods;

  public periodFields: any = periodFields;
  public refChart: string = randomId('CHART_EVOLUTION');
  scichartService!: ScichartService;
  baseChart!: TWebAssemblyChart;
  private _candleYAxis!: NumericAxis;
  private _candleXAxis!: NumericAxis;
  form!: FormGroup;
  public colors: any = colorsAcumulados;
  public fieldEvolution = 'investor_chart_';
  public fieldDelta = 'delta_volume';
  public monthToFormate: any = DATE_PICKER_CONFIGS.months;

  public tooltipX = 0;
  public tooltipY = 0;
  public displayTooltip = false;

  private _formatField$ = new Subject<void>();
  private _updateChartContainerSizes = new Subject<void>();
  private _formatField!: Subscription;
  private _theme$!: Subscription;
  private _code = 'flow_b3';
  private _data!: any;
  private _containerSizes!: any;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private stockChartService: StockChartService,
    private formBuilder: FormBuilder,
    private _flowInvestorsService: FlowInvestorsService,
    private cdr: ChangeDetectorRef,
    private _themeService: ThemePreferencesService,
    private _elementRef: ElementRef
  ) {
    this.scichartService = new ScichartService(undefined, stockChartService);
    this.form = this.formBuilder.group({
      period: new FormControl('daily'),
    });
    this._flowInvestorsService.hideTyper$
      .pipe(filter((id) => id !== null && this.baseChart !== undefined))
      .subscribe((id) => this.hideSerie(id));
    this._formatField = this._formatField$
      .pipe(auditTime(100))
      .subscribe(() => this.formatField());
    this._theme$ = this._themeService
      .themeActiveObservable()
      .pipe(delay(THEME_TIMER))
      .subscribe((data) => {
        this.scichartService.changeThemeHandler(
          this.baseChart,
          data === 'dark-theme'
        );
      });
    this._updateChartContainerSizes
      .pipe(debounceTime(150))
      .subscribe(() => this._getChartContainerInfos());
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { containerWidth, containerHeight, updatePosition } = changes;
    if (
      containerWidth?.currentValue ||
      containerHeight?.currentValue ||
      updatePosition?.currentValue
    ) {
      this._updateChartContainerSizes.next();
    }
  }

  ngOnDestroy(): void {
    this._formatField && this._formatField.unsubscribe();
    this._theme$ && this._theme$.unsubscribe();
    this._updateChartContainerSizes.unsubscribe();
  }

  private formatField() {
    this.periodsEvolution =
      this._code === 'flow_b3' ? periodsEvolution : periodsEvolutionFuture;

    switch (this._code) {
      case 'flow_b3':
        this.fieldEvolution = 'investor_chart_';
        this.fieldDelta =
          this.periodSelected === 'daily' ? 'delta_volume' : 'sum_delta_volume';
        break;
      case 'future_ibov':
        this.fieldEvolution = 'ibov_chart_';
        this.fieldDelta = 'delta_futuro';
        if (this.periodSelected === 'daily') this.fieldEvolution = 'ibov_';
        break;
      case 'future_dolar':
        this.fieldEvolution = 'dolar_chart_';
        this.fieldDelta = 'delta_futuro';
        if (this.periodSelected === 'daily') this.fieldEvolution = 'dolar_';
        break;

      default:
        break;
    }
    this.cdr.detectChanges();
    this.startChart();
  }

  public changePeriod(event: any) {
    this.periodSelected = event.id;
    this.periodSelectedLabel = event.label;
    this.cdr.detectChanges();
    this.formatField();
  }

  private startChart() {
    const totalItems =
      this._data[`${this.fieldEvolution}${this.periodSelected}`];
    if (!totalItems) return;
    const chartDates: any = Object.keys(
      this._data[`${this.fieldEvolution}${this.periodSelected}`]
    );
    this.scichartService.initSciChart(this.refChart).then((res) => {
      this.baseChart = res;

      const typesB3: any = {
        Financeiras: [],
        Estrangeiros: [],
        Institucionais: [],
        'Pessoa Física': [],
        'Pessoa Jurídica': [],
      };

      chartDates.map((dt: any) => {
        for (let i = 0; i < totalItems[dt].length; i++) {
          const field =
            totalItems[dt][i].cd_b3_investor_type ||
            totalItems[dt][i].cd_type_investor;
          if (typesB3[field])
            typesB3[field].push({
              cd_b3_investor_type:
                totalItems[dt][i].cd_b3_investor_type ||
                totalItems[dt][i].cd_type_investor,
              delta_volume: totalItems[dt][i][this.fieldDelta],
              color:
                this.colors[totalItems[dt][i].cd_b3_investor_type] ||
                this.colors[totalItems[dt][i].cd_type_investor],
              date: dt,
            });
        }
      });
      const labels = chartDates.map((item: any) => {
        return this.periodSelected !== 'month' && this.periodSelected !== 'mes'
          ? item.replace(/^(\d{2})(\d{2})(\d{2})(\d{2})$/, '$4/$3/$2')
          : this.formatMonthToLabel(item);
      });
      const labelProvider: any = new TextLabelProvider({
        labels: labels,
      });

      this._candleXAxis = new NumericAxis(this.baseChart.wasmContext, {
        labelProvider,
        labelStyle: {
          fontSize: 9,
          padding: new Thickness(20, 0, 0, 0),
        },
        drawMajorGridLines: false,
        drawMinorGridLines: false,
        drawMajorBands: false,
        drawMajorTickLines: false,
        drawMinorTickLines: false,
        maxAutoTicks: 5,
      });

      this._candleYAxis = new NumericAxis(this.baseChart.wasmContext, {
        axisAlignment: EAxisAlignment.Left,
        labelStyle: {
          fontSize: 9,
          alignment: ELabelAlignment.Right,
          padding: new Thickness(0, 15, 0, 0),
        },
        drawMajorGridLines: true,
        drawMinorGridLines: true,
        drawMajorBands: false,
        drawMajorTickLines: false,
        drawMinorTickLines: false,
        maxAutoTicks: 6,
      });

      this._candleYAxis.labelProvider.formatCursorLabel = (value: any) =>
        getVolumeText(this.locale, value);
      this._candleYAxis.labelProvider.formatLabel = (value: any) =>
        getVolumeText(this.locale, value);

      this.baseChart.sciChartSurface.xAxes.add(this._candleXAxis);
      this.baseChart.sciChartSurface.yAxes.add(this._candleYAxis);
      this.baseChart.sciChartSurface.background = '#FFFFFF00';

      Object.keys(typesB3).map((type: any) => {
        const yValues = typesB3[type].map((item: any) => item.delta_volume);

        while (yValues.length < labels.length) {
          yValues.push(0);
        }

        const columnSeries: any = new FastColumnRenderableSeries(
          this.baseChart.wasmContext,
          {
            fill: this.colors[type],
            stroke: this.colors[type],
            strokeThickness: 0,
            cornerRadius: 0,
            dataPointWidth: 0.7,
            dataSeries: new XyDataSeries(this.baseChart.wasmContext, {
              xValues: labels.map((item: any, index: number) => index),
              yValues: yValues,
              dataSeriesName: type,
            }),
            id: `${this._code}_${type.replace('Pessoa', 'P.')}`,
          }
        );

        this.baseChart.sciChartSurface.renderableSeries.add(columnSeries);
      });

      this.baseChart.sciChartSurface.chartModifiers.add(
        new CursorModifier({
          showTooltip: true,
          tooltipContainerBackground: CHART_COLORS.NEUTRAL_STRONG,
          crosshairStroke: CHART_COLORS.NEUTRAL_MEDIUM,
          tooltipTextStroke: 'text-light',
          placementDivId: `chart-evolution-tooltip-div-id_${this.refChart}`,
          tooltipSvgTemplate: (
            seriesInfos: SeriesInfo[],
            svgAnnotation: CursorTooltipSvgAnnotation
          ) => {
            this.displayTooltip = seriesInfos[0] !== undefined;
            return this._flowInvestorsService.tooltipSvgTemplate(
              seriesInfos,
              svgAnnotation,
              200,
              20,
              {
                addZero: true,
                isFlexWrap: false,
              }
            );
          },
        })
      );

      this._getChartContainerInfos();

      this.stockChartService.removeChartLoading$.next({
        remove: true,
        ref: this.refChart,
      });
      this.cdr.detectChanges();
    });
  }

  public hideSerie = (id: string) => {
    if (!id.length) return;
    const tempIdCode = id.split('_');
    if (this._code === `${tempIdCode[0]}_${tempIdCode[1]}`) {
      const serie: IRenderableSeries =
        this.baseChart.sciChartSurface.renderableSeries.getById(id);
      serie.isVisible = !serie.isVisible;
    }
    this.cdr.detectChanges();
  };

  private formatMonthToLabel(date: any): string {
    const temp = `${this.monthToFormate[
      parseInt(date.replace(/^(\d{4})(\d{2})$/, '$2')) - 1
    ].slice(0, 3)}${date.replace(/^(\d{4})(\d{2})$/, '/$1')}`;
    return temp;
  }

  // TOOLTIP CONTROLLER
  private _getChartContainerInfos(): void {
    const chartContainer = this._elementRef.nativeElement.querySelector(
      `#${this.refChart}`
    );
    if (!chartContainer) {
      console.error('CHART_CONTAINER_NOT_FOUND');
      return;
    }
    this._containerSizes = chartContainer.getBoundingClientRect();
    this.cdr.detectChanges();
  }

  public onMouseMove(event: MouseEvent) {
    if (!this._containerSizes) return;
    const mouseX = event.clientX - this._containerSizes.left + 15;
    const mouseY = event.clientY - this._containerSizes.top;
    if (
      mouseX < 1 ||
      mouseY < 1 ||
      mouseX > this._containerSizes.width ||
      mouseY > this._containerSizes.height
    ) {
      return;
    }
    const limitReached = event.clientX + 250 >= window.innerWidth;
    const adjustLeftPosition = this.isLeftSideChart
      ? this.containerWidth / 2 + 10
      : 0;
    let leftPosition = mouseX + adjustLeftPosition;
    if (limitReached) leftPosition = leftPosition - 220;
    this.tooltipX = leftPosition;
    this.tooltipY = event.offsetY + 180;
  }
}
