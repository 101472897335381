export const bollingerBandsTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  As bandas de Bollinger são uma ferramenta de análise técnica que consiste em
  três linhas traçadas no gráfico de preços de um ativo financeiro. As linhas
  representam a volatilidade dos preços e são usadas para determinar os limites
  superior e inferior dos preços.
</div>
<div class="mb-3">
  As bandas são calculadas usando uma média móvel simples e um desvio padrão dos
  preços do ativo. A média móvel é normalmente definida em 20 dias, mas pode ser
  ajustada de acordo com as preferências do trader. O desvio padrão é uma medida
  de quão dispersos são os preços em relação à média móvel.
</div>
<div class="mb-3">As três linhas que formam as bandas de Bollinger são:</div>
<div class="mb-3">
  &bull; A banda superior: que é a média móvel mais duas vezes o desvio padrão
  dos preços.
  <br />
  &bull; A banda inferior: que é a média móvel menos duas vezes o desvio padrão
  dos preços.
  <br />
  &bull; A banda base (central): que é a média móvel simples.
</div>
<div class="mb-3">
  Quando a volatilidade do preço é alta, as bandas se expandem e quando a
  volatilidade é baixa, as bandas se contraem. Quando os preços estão próximos
  da banda superior, isso pode indicar que o ativo está sobrecomprado, ou seja,
  os preços estão subindo muito rapidamente e podem estar prestes a corrigir.
  Por outro lado, quando os preços estão próximos da banda inferior, isso pode
  indicar que o ativo está sobrevendido, ou seja, os preços estão caindo
  rapidamente e podem estar prestes a se recuperar.
</div>
<div class="mb-3">
  As bandas de Bollinger são uma ferramenta útil para traders que procuram
  identificar oportunidades de negociação com base na volatilidade dos preços.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">
  Para realizar o cálculo das Bandas de Bollinger é simples. Para fazer a
  configuração das Bandas de Bollinger, o trader deve utilizar a Média Móvel
  Simples (MA) ou Aritmética (MMA). Ambas as bandas são calculadas com base em
  uma média móvel simples (MA) O padrão que costuma ser utilizado para realizar
  o cálculo é o período de 20 dias, mas pode ser alterado de acordo com a
  análise que deseja fazer.
</div>
<div class="mb-3">Aqui está a fórmula completa:</div>
<div class="mb-3">
  Banda Superior = Média Móvel Simples (MA) + (Desvio Padrão x Fator)
  <br />
  Banda Inferior = Média Móvel Simples (MA) - (Desvio Padrão x Fator)
</div>
<div class="mb-3">
  Onde:
  <br />
  Média Móvel Simples = soma dos preços de fechamento / período
  <br /><br />
  Desvio Padrão = raiz quadrada da soma dos quadrados das diferenças entre o
  preço de fechamento e a média móvel simples, dividido pelo período - 1.
  <br /><br />
  Fator = geralmente 2
</div>
<div class="mb-3">
  Os valores das Bandas de Bollinger podem ser plotados em um gráfico para
  ajudar a visualizar a volatilidade e a tendência do preço de um ativo
  financeiro.
  <br /><br />
  Longo Prazo: usar 2.1 desvios padrões com 50 períodos;
  <br />
  Curto Prazo: usar 1.9 desvios padrões com 10 períodos.
</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  A principal função das Bandas de Bollinger é tentar prever como um ativo vai
  se comportar no seu movimento de preço. Para isso são determinados o preço
  médio, mínimo e máxima de um período.
</div>
<div class="mb-3">
  De forma simples, as bandas criam uma “área” onde é possível tentar determinar
  se o preço vai sofrer uma valorização ou uma queda, baseado no histórico deste
  ativo em um determinado período.
</div>
<div class="mb-3">
  <img class="w-75" src="/assets/indicators/bollinger-bands-como-funciona.png" />
</div>
<div class="text-decoration-underline">Preço acima ou abaixo das bandas</div>
<div class="mb-3">
  Essa é a parte mais fácil ao analisar as Bandas de Bollinger, saber se o valor
  está em uma área onde ainda há fôlego para a venda, se ainda há espaço para
  uma queda antes de encontrar suporte e mais.
</div>
<div class="mb-3">
  Sempre que o preço se aproxima dessa banda superior, a tendência é que as
  próximas movimentações sejam sempre de correção, ou seja, de queda.
</div>
<div class="mb-3">
  No caminho oposto, sempre que o preço chegou perto ou alcançou a banda
  inferior a tendência foi seguida por um momento de alta.
</div>
<div class="mb-3">
  Com isso, é possível analisar quando o preço está chegando no limite inferior,
  é um bom momento para entrar com investimento no ativo. Da mesma forma, quando
  está alcançando a banda superior, é hora de realizar os lucros.
</div>
<div class="mb-3">
  A partir das três bandas do indicador, há como pintar um possível cenário para
  tentar determinar se o ativo está em um momento de sobre-compra ou
  sobre-venda.
</div>
<div class="text-decoration-underline">Possíveis Vendas</div>
<div class="mb-3">
  Quando da formação de topos duplos, ou seja, duas altas seguidas de
  intensidade semelhante, desenhando um "M" no gráfico, isso pode ser um sinal
  de esgotamento da força de uma tendência de alta.
  <br /><br />
  &bull; Topo M ou teto duplo, figura que indica a mudança de tendência
  ascendente para outra de baixa.
</div>
<div class="mb-3">
  Quando os preços continuamente tocam a linha superior das Bandas de Bollinger,
  são ditos como sobre-compra, sinalizando venda.
</div>
<div class="text-decoration-underline">Possíveis Compras</div>
<div class="mb-3">
  Já quando há a formação de fundos duplos, isto é, duas quedas seguidas
  formando a figura de um "W", pode ser uma oportunidade de estudar uma compra
  do ativo, pois representará possivelmente um indicativo de perda de força da
  tendência de baixa.
</div>
<div class="mb-3">
  &bull; Fundo W ou chão duplo, figura que indica a mudança de tendência à baixa
  para tendência crescente.
</div>
<div class="mb-3">
  Então, quando repetidamente batem na inferior, são caracterizados como
  sobrevenda, indicando compra.
</div>
<div class="mb-3">
  Durante a interpretação é preciso analisar a posição do preço em relação às
  bandas, o estreitamento das linhas limites e se há padrões visíveis.
</div>
<div class="text-decoration-underline">Cruzamento de médias</div>
<div class="mb-3">
  Como mencionado anteriormente, os traders costumam estipular as bandas
  superiores e inferiores como alvos de preço. Se o preço desvia para fora da
  banda inferior e cruza acima da média de 20 dias (banda do meio), a banda
  superior representa a meta de preço superior.
</div>
<div class="mb-3">
  Em uma forte tendência de alta, os preços geralmente oscilam entre a banda
  superior e a média móvel de 20 dias. Quando isso acontece, um cruzamento
  abaixo da média móvel de 20 dias adverte de uma inversão da tendência para o
  lado negativo.
</div>
<div class="mb-3">
  Comprar quando os preços das ações cruzam abaixo da banda inferior muitas
  vezes ajuda os traders a tirar proveito das condições de sobrevenda e lucrar
  quando o preço do ativo negociado vai em direção à banda central da média
  móvel.
</div>

<div class="fw-bolder">Como configurar</div>
<div class="fw-bolder">Valores</div>
<br />
<div class="text-decoration-underline">Período</div>
<br />
<div class="mb-3">
  O período a ser usado no cálculo do Média Móvel Simples (MA) que cria a base
  para as bandas superior e inferior. 20 dias é o padrão.
</div>

<div class="text-decoration-underline">Mult</div>
<div class="mb-3">
  É o número de desvios padrão longe da Média Móvel Simples (MA) que as faixas
  superior e inferior. O valor padrão é 2.
</div>
<div class="mb-3">
  <img class="image-indicators-input" src="/assets/indicators/bollinger-bands-entrada.png" />
</div>
<div class="fw-bolder">Estilo</div>
<div class="mb-3">
<div class="text-decoration-underline">Superior</div>
  <br />
  Pode alternar a visibilidade da banda superior, bem como a visibilidade de uma
  linha de preço que mostra o preço atual real da banda superior. Também pode
  selecionar a cor, a espessura e o estilo da linha da banda superior.
  <br /><br />
  <div class="text-decoration-underline">Base</div>
  <br />
  Pode alternar a visibilidade da Base, bem como a visibilidade de uma linha de
  preço que mostra o preço atual real da Base. Também pode selecionar a cor, a
  espessura da linha e o estilo da linha.
  <br /><br />
  <div class="text-decoration-underline">Inferior</div>
  <br />
  Pode alternar a visibilidade da banda inferior, bem como a visibilidade de uma
  linha de preço mostrando o preço atual real da banda inferior. Também pode
  selecionar a cor, a espessura e o estilo da linha da banda inferior.
  <br /><br />
  <div class="text-decoration-underline">Fundo</div>
  <br />
  Alterna a visibilidade de uma cor de fundo nas bandas. Também pode alterar a
  própria cor, bem como a opacidade.
</div>
<div class="mb-3">
  <img class="image-indicators-input" src="/assets/indicators/bollinger-bands-estilo.png" />
</div>
`;
