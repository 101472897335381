export const hammerTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  O indicador Martelo é um padrão de análise técnica que pode ajudar a
  identificar reversões de tendência em um mercado financeiro. Ele é formado por
  um único candlestick que apresenta uma sombra inferior longa e um corpo
  pequeno na parte superior da vela. O corpo da vela deve estar próximo ou
  dentro da faixa de negociação do dia anterior.
</div>
<div class="mb-3">
  O Martelo pode ser interpretado como um sinal de que a pressão de venda
  anterior está perdendo força e que os compradores podem estar prontos para
  assumir o controle do mercado. Isso ocorre porque o preço abriu perto do preço
  mais alto do dia, caiu significativamente durante o dia, mas acabou fechando
  próximo ao preço de abertura.
</div>
<div class="mb-3">
  É importante lembrar que o Martelo é apenas um indicador e não deve ser usado
  sozinho para tomar decisões de investimento. É sempre recomendável usar o
  Martelo em conjunto com outras ferramentas de análise técnica para obter uma
  visão mais completa do mercado.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">
  A fórmula para o cálculo do indicador Martelo é simplesmente a identificação
  do padrão no gráfico de preços. O Martelo é um padrão de candlestick que pode
  ser reconhecido visualmente no gráfico de preços. A identificação do padrão
  Martelo é um processo subjetivo que envolve a análise de vários fatores, como
  a posição da sombra inferior em relação ao corpo da vela, a presença de uma
  tendência de baixa anterior e a confirmação por outros indicadores técnicos.
</div>
<div class="mb-3">A fórmula de cálculo do indicador Martelo é a seguinte:</div>
<div class="mb-3">
  Martelo = High - Low > 2 * (Open - Close) and Close > Open and (High - Close)
  <= 2 * (Open - Low) and (High - Close) < (Close - Open)
</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  O indicador Martelo é usado para identificar reversões de tendência em um
  gráfico de candlestick. Ele é formado por um candlestick com um pequeno corpo
  e uma longa sombra inferior, e pode indicar uma possível reversão de uma
  tendência de baixa para uma tendência de alta.
</div>
<div class="mb-3">
  Para usar o indicador Martelo, é necessário primeiro identificar uma tendência
  de baixa no gráfico. Quando um candlestick Hammer aparece após uma tendência
  de baixa, pode ser um sinal de que a tendência está prestes a se reverter. Um
  trader pode então considerar abrir uma posição longa, com um stop loss abaixo
  da sombra do Martelo.
</div>
<div class="mb-3">
  No entanto, é importante lembrar que nenhum indicador é infalível e que é
  sempre necessário fazer uma análise completa do mercado antes de tomar
  qualquer decisão de negociação.
</div>
<div class="mb-3">
  <img class="w-75" src="/assets/indicators/hammer-como-funciona.png" />
</div>
<div class="fw-bolder">Como configurar</div>
<div class="fw-bolder">Valores</div>
<div class="mb-3">Sem valores pré-definidos para configurar.</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">Background</div>
<div class="mb-3">
  Pode alternar a visibilidade do Martelo. Também pode selecionar a cor, a
  espessura e o estilo da linha do Martelo.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/hammer-estilo.png"
  />
</div>
`;
