import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from '@core/models';
import { AUTH_LOCAL_KEYS } from '@shared/services/core/const/auth_util.const';
import { BrowserStorageBase } from '@shared/services/core/storage/browser-storage-base';
import { sharedSessionStorage } from '@shared/services/core/storage/shared-session.storage';
import { RestService } from '@shared/services/rest/rest.service';
import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  map,
  shareReplay,
  tap,
} from 'rxjs';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';

@Injectable({
  providedIn: 'root',
})
export class CustomPreferencesService extends RestService {
  override _url: string = 'api/nitro-ws/v1';
  KEYS: any;
  storage = new BrowserStorageBase(sharedSessionStorage);
  private preferenceChannel$ = new BehaviorSubject<any>(null);

  constructor(http: HttpClient) {
    super(http);
  }

  onChangePreference = () =>
    this.preferenceChannel$.pipe(filter((data) => data));

  set customPreference(e: customPreference) {
    this.setValueCustomPreferences(e.key, e.value);
  }

  set skipSplitConfirmation(value: boolean) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.DONT_ASK_SPLIT,
      value ? '1' : '0'
    );
  }

  get skipSplitConfirmation() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.DONT_ASK_SPLIT) === '1';
  }

  set audioNotification(value: boolean) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.AUDIO_NOTIFICATION,
      value ? '1' : '0'
    );
  }

  get audioNotification() {
    const audioNitification = this.storage.getItem(
      AUTH_LOCAL_KEYS.AUDIO_NOTIFICATION
    );
    if (!audioNitification) return true;
    return this.storage.getItem(AUTH_LOCAL_KEYS.AUDIO_NOTIFICATION) === '1';
  }

  set configSoundsNotifications(value: string) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.CONFIG_SOUNDS_NOTIFICATIONS,
      value,
      false,
      false
    );
  }

  get configSoundsNotifications() {
    let config = this.storage.getItem(
      AUTH_LOCAL_KEYS.CONFIG_SOUNDS_NOTIFICATIONS
    );
    config && config.split(',').length < 4 && (config += ',3');
    return config ?? '0,1,2,3';
  }

  set useSplit(value: boolean) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.USE_SPLIT,
      value ? '1' : '0'
    );
  }

  get useSplit() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.USE_SPLIT) === '1';
  }

  set diplayInvertPositionConfirmattion(diplayConfirmAction: boolean) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.CONFIRMATION_INVERT_POSITION,
      diplayConfirmAction ? '1' : '0'
    );
  }

  get confirmattionInvertAndResetPosition(): {
    reset: boolean;
    invert: boolean;
  } {
    let displayInvertConfirmattion = this.storage.getItem(
      AUTH_LOCAL_KEYS.CONFIRMATION_INVERT_POSITION
    );
    displayInvertConfirmattion =
      displayInvertConfirmattion === '1' ||
      isNullOrUndefined(displayInvertConfirmattion);

    let displayResetConfirmattion = this.storage.getItem(
      AUTH_LOCAL_KEYS.CONFIRMATION_RESET_POSITION
    );
    displayResetConfirmattion =
      displayResetConfirmattion === '1' ||
      isNullOrUndefined(displayResetConfirmattion);

    return {
      reset: displayResetConfirmattion,
      invert: displayInvertConfirmattion,
    };
  }

  get confirmationCancelOrder() {
    let displayCancelConfirmattion = this.storage.getItem(
      AUTH_LOCAL_KEYS.CONFIRMATION_CANCEL_POSITION
    );
    displayCancelConfirmattion =
      displayCancelConfirmattion === '1' ||
      isNullOrUndefined(displayCancelConfirmattion);

    return displayCancelConfirmattion;
  }

  set diplayCancelOrderConfirmattion(diplayConfirmAction: boolean) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.CONFIRMATION_CANCEL_POSITION,
      diplayConfirmAction ? '1' : '0'
    );
  }

  set diplayResetPositionConfirmattion(diplayConfirmAction: boolean) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.CONFIRMATION_RESET_POSITION,
      diplayConfirmAction ? '1' : '0'
    );
  }

  set simulatorOn(e: string) {
    this.setValueCustomPreferences(AUTH_LOCAL_KEYS.SIMULATOR_ON, e);
  }

  get simulatorOn() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.SIMULATOR_ON);
  }

  set useDayTradeMode(enableDayTradeMode: boolean) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.USE_DAYTRADE_MODE,
      enableDayTradeMode ? '1' : '0'
    );
  }

  get useDayTradeMode() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.USE_DAYTRADE_MODE) === '1';
  }

  set indicatorsModalCollapse(e: string) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.INDICATORS_MODAL_COLLAPSE,
      e
    );
  }

  get indicatorsModalCollapse() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.INDICATORS_MODAL_COLLAPSE);
  }

  set chartTempConfig(e: string | any) {
    this.storage.setItem(AUTH_LOCAL_KEYS.CHART_TEMP_CONFIG, e);
    this.preferenceDict.set(AUTH_LOCAL_KEYS.CHART_TEMP_CONFIG, JSON.parse(e));
  }

  get chartTempConfig() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.CHART_TEMP_CONFIG);
  }

  set chartIndicatorsConfig(e: string) {
    this.setValueCustomPreferences(AUTH_LOCAL_KEYS.CHART_INDICATORS_CONFIG, e);
  }

  get chartIndicatorsConfig() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.CHART_INDICATORS_CONFIG);
  }

  set userColorDrawTools(e: string) {
    this.setValueCustomPreferences(AUTH_LOCAL_KEYS.USER_COLOR_DRAW_TOOLS, e);
  }

  get userColorDrawTools() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.USER_COLOR_DRAW_TOOLS);
  }

  set userDrawStock(e: string) {
    this.setValueCustomPreferences(AUTH_LOCAL_KEYS.USER_DRAW_STOCK, e);
  }

  get userDrawStock() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.USER_DRAW_STOCK);
  }

  set drawToolModel(e: string) {
    this.setValueCustomPreferences(AUTH_LOCAL_KEYS.DRAW_TOOL_MODEL, e);
  }

  get drawToolModel() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.DRAW_TOOL_MODEL);
  }
  set chartContextMenu(e: string) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.CHART_CONTEXT_MENU_CONFIG,
      e
    );
  }

  get chartContextMenu() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.CHART_CONTEXT_MENU_CONFIG);
  }

  set chartGlobalConfiguration(e: string) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.CHART_GLOBAL_CONFIGURATION,
      e
    );
  }

  get chartGlobalConfiguration() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.CHART_GLOBAL_CONFIGURATION);
  }

  set chartLinesConfiguration(e: string) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.CHART_LINES_CONFIGURATION,
      e
    );
  }

  get chartLinesConfiguration() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.CHART_LINES_CONFIGURATION);
  }

  set showChartIndicators(e: boolean) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.SHOW_CHART_INDICATORS,
      e ? '1' : '0'
    );
  }

  get showChartIndicators() {
    const SHOW_CHART_INDICATORS = isNullOrUndefined(
      this.storage.getItem(AUTH_LOCAL_KEYS.SHOW_CHART_INDICATORS)
    )
      ? '1'
      : this.storage.getItem(AUTH_LOCAL_KEYS.SHOW_CHART_INDICATORS);
    return SHOW_CHART_INDICATORS === '1';
  }

  set displayScheduleOrderConfirmation(enableScheduleOrder: boolean) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.CONFIRMATION_SCHEDULE_ORDER_AFTER_MARKET_CLOSE,
      enableScheduleOrder ? '1' : '0'
    );
  }

  get displayScheduleOrderConfirmation() {
    const displayConfirmScheduleOrder = this.storage.getItem(
      AUTH_LOCAL_KEYS.CONFIRMATION_SCHEDULE_ORDER_AFTER_MARKET_CLOSE
    );
    return (
      displayConfirmScheduleOrder === '1' ||
      isNullOrUndefined(displayConfirmScheduleOrder)
    );
  }

  set newsConfiguration(e: string) {
    this.setValueCustomPreferences(AUTH_LOCAL_KEYS.NEWS_CONFIGURATION, e);
  }

  get newsConfiguration() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.NEWS_CONFIGURATION);
  }

  set screeningFilters(e: string) {
    this.setValueCustomPreferences(AUTH_LOCAL_KEYS.SCREENING_FILTERS, e);
  }

  get screeningFilters() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.SCREENING_FILTERS);
  }

  set saveQttyGroupStocks(e: boolean) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.SAVE_QTTY_GROUP_STOCKS,
      e ? '1' : '0'
    );
  }

  get saveQttyGroupStocks() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.SAVE_QTTY_GROUP_STOCKS) === '1';
  }

  set breakEvenIsClicked(e: boolean) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.BREAK_EVEN_IS_CLICKED,
      e ? '1' : '0'
    );
  }

  get breakEvenIsClicked() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.BREAK_EVEN_IS_CLICKED) === '1';
  }

  get listStocksQtty() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.LIST_STOCK_QTTY_GROUP);
  }

  set listStocksQtty(e: any) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.LIST_STOCK_QTTY_GROUP,
      JSON.stringify(e)
    );
  }

  get countInitializedByTour() {
    return this.storage.getItem(AUTH_LOCAL_KEYS.COUNT_INITIALIZED_BY_TOUR);
  }

  set countInitializedByTour(value: string) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.COUNT_INITIALIZED_BY_TOUR,
      value
    );
  }

  get tradingOffset() {
    const value = this.storage.getItem(AUTH_LOCAL_KEYS.TRADING_OFFSET);
    return value ? +value : 30;
  }

  set tradingOffset(e: any) {
    this.setValueCustomPreferences(AUTH_LOCAL_KEYS.TRADING_OFFSET, `${e}`);
  }

  get ordersHistoryCardView() {
    return (
      this.storage.getItem(AUTH_LOCAL_KEYS.ORDERS_HISTORY_CARD_VIEW) === '1'
    );
  }

  set ordersHistoryCardView(e: boolean) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.ORDERS_HISTORY_CARD_VIEW,
      e ? '1' : '0'
    );
  }

  getCustomPreference(key: string) {
    return this.storage.getItem(key);
  }

  async getCustomPreferencesCached() {
    return this.getCustomPreferences()
      .then(this.storeFirstCustomPreference)
      .catch(() => {
        return this.preferenceDict.size();
      });
  }

  public getCustomPreferences() {
    return firstValueFrom(
      this.get<any>('workspace/getCustomPreferences').pipe(
        map(async (res) => {
          this.checkInitializedByTour(
            res?.data?.values['INITIAL_TOUR'],
            res?.data?.values['COUNT_INITIALIZED_BY_TOUR']
          );
          return res.data.values;
        }),
        shareReplay(1)
      )
    );
  }

  private checkInitializedByTour(
    initialTour: string | null,
    initializedByTour: string | null
  ): void {
    if (!initializedByTour || initializedByTour !== '2') {
      if (initialTour === '1' && initializedByTour === '1') {
        this.countInitializedByTour = '2';
      } else {
        this.countInitializedByTour = '1';
      }
    }
  }

  protected setValueCustomPreferences(
    key: string,
    value: any,
    parsedStorage: boolean = false,
    isParceValue: boolean = true
  ) {
    this.storeCustomPreference(key, value, parsedStorage, isParceValue);
  }

  private async storeCustomPreference(
    key: string,
    value: any,
    parsedStorage: boolean,
    isParceValue: boolean = true
  ) {
    return this.post<any>('workspace/storeCustomPreference', { key, value })
      .pipe(
        map((res) => {
          return res.data;
        }),
        tap(() => {
          const parsedValue = isParceValue ? JSON.parse(value) : value;
          this.storage.setItem(key, parsedStorage ? parsedValue : value);
          this.preferenceDict.set(key, parsedValue);
          this.preferenceChannel$.next({ key, value: parsedValue });
        })
      )
      .subscribe();
  }
  preferenceDict = new Dictionary<customPreference>();
  storeFirstCustomPreference = (resp: any) => {
    const returns: any = {
      [AUTH_LOCAL_KEYS.ALL_LIST_STOCKS]: true,
      [AUTH_LOCAL_KEYS.ALL_ORDERS]: true,
      [AUTH_LOCAL_KEYS.ALL_HASH_ORDERS]: true,
    };
    const parse: any = {
      [AUTH_LOCAL_KEYS.USER_DRAW_STOCK]: true,
      [AUTH_LOCAL_KEYS.CHART_GLOBAL_CONFIGURATION]: true,
      [AUTH_LOCAL_KEYS.CHART_LINES_CONFIGURATION]: true,
      [AUTH_LOCAL_KEYS.SCREENING_FILTERS]: true,
    };
    resp &&
      Object.keys(resp).forEach((key) => {
        if (returns[key]) return;
        this.storage.setItem(key, resp[key]);
        const keyValue = parse[key] ? JSON.parse(resp[key]) : resp[key];
        this.preferenceDict.set(key, keyValue);
      });
    return this.preferenceDict.size();
  };
}

export interface customPreference {
  key: string;
  value: any;
}
