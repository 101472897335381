<div
  [id]="elementsID.TABLE"
  [ngClass]="css"
  class="p-0 w-100 h-100 custom-table-bg"
  *ngIf="stockFunds.length || loading; else noInfo"
>
  <div
    [ngClass]="cssContainer"
    class="table-container simple round-none d-flex flex-column h-100"
  >
    <span *ngIf="showTitle" class="fs-base fw-bold pb-2 d-block">Fundos</span>

    <div class="p-0 mb-1 d-flex gap-2">
      <span
        (click)="handlerTableFunds(type.ref)"
        class="fs-5 cursor-pointer"
        [ngClass]="{ 'text-brand-primary': type.active }"
        *ngFor="let type of fundsStrategies"
        >{{ type.title }}</span
      >
    </div>

    <div class="w-100 h-100 d-flex flex-column min-height-funds-table">
      <table class="h-100 d-flex flex-column">
        <thead class="fixed-header">
          <tr class="w-100 d-flex">
            <th
              scope="col"
              [style.width.%]="55"
              class="text-left fs-6 fw-bold"
            ></th>
            <th
              scope="col"
              [style.width.%]="15"
              class="fs-6 fw-bold text-center"
            >
              Financeiro
            </th>
            <th
              scope="col"
              [style.width.%]="15"
              class="fs-6 fw-bold text-center px-0"
            >
              % Fundo
            </th>
            <th
              scope="col"
              [style.width.%]="15"
              class="fs-6 fw-bold text-center px-0"
            >
              % Companhia
            </th>
          </tr>
        </thead>
        <tbody class="h-100 d-flex">
          <cdk-virtual-scroll-viewport
            #scrollViewport
            itemSize="25"
            minBufferPx="25"
            maxBufferPx="100"
            class="w-100"
            [style.overflow-y]="'auto'"
          >
            <ng-container
              *cdkVirtualFor="let fund of stockFunds; let lineIndex = index"
            >
              <tr
                data-bs-toggle="collapse"
                [attr.data-bs-target]="'#FUNDS_CHART' + lineIndex"
                id="collapseItem"
                (click)="displayColumnChart(fund, lineIndex)"
                class="text-white cursor-pointer align-baseline w-100 d-flex"
              >
                <td
                  [style.width.%]="55"
                  class="py-1 text-white p-0 pl-0 text-left text-nowrap cursor-pointer"
                >
                  <fla-icon
                    fla-icon
                    [name]="fund.expanded ? 'expand_less' : 'expand_more'"
                    css="text-white"
                    size="icon-size-micro"
                  ></fla-icon>
                  {{ fund.namevalefund }}
                </td>
                <td [style.width.%]="15" class="py-1 text-white text-right p-0">
                  {{ fund.vl_final_market }}
                </td>
                <td [style.width.%]="15" class="py-1 text-white text-right p-0">
                  {{ fund.pc_net_worth }}
                </td>
                <td [style.width.%]="15" class="py-1 text-white text-right p-0">
                  {{ fund.pc_net_worth_company }}
                </td>
              </tr>

              <tr
                [id]="'FUNDS_CHART' + lineIndex"
                class="collapse w-100 d-flex expanded-tr"
                [ngClass]="{ 'opacity-0 h-0 d-none': !fund.expanded }"
              >
                <td colspan="5" class="sub-table w-100">
                  <ng-container
                    *ngIf="fund.loading || fund.error; else fundsChart"
                  >
                    <app-loading *ngIf="fund.loading"> </app-loading>
                    <span
                      *ngIf="fund.error"
                      class="pb-4 d-block h-100 w-100 text-center fs-5 muted"
                    >
                      Ocorreu um erro ao carregar os dados do fundo
                    </span>
                  </ng-container>
                  <ng-template #fundsChart>
                    <app-funds-chart
                      [cdStock]="cdStock"
                      [fundData]="fund.fundsInfo!"
                      [deltaMesPerc]="fund.delta_mes_perc"
                      [lineIndex]="lineIndex"
                    ></app-funds-chart>
                  </ng-template>
                </td>
              </tr>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </tbody>
      </table>

      <div class="w-100 text-center">
        <span class="d-block pb-4">{{ fundsStatus.message }}</span>
      </div>
    </div>
  </div>
</div>
<ng-template #noInfo>
  <div class="h-100 w-100 d-flex justify-content-center align-items-center">
    <app-no-info></app-no-info>
  </div>
</ng-template>
