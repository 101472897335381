import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RegisterComponent } from './register.component';
import {
  FlaCheckModule,
  FlaIconModule,
  FlaInputModule,
  InputCodeModule,
  PhoneInputModule,
  RocketButtonModule,
} from '@shared/rocket-components/components';
import { FlaFocusModule } from '@shared/directives/focus/focus.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterRoutes } from './register.routing';
import { WelcomeComponent } from './welcome/welcome.component';
import { CreateComponent } from './create/create.component';
import { ValidateComponent } from './validate/validate.component';
import { TimerResendComponent } from './validate/timer-resend/timer-resend.component';
import { FormatTimeModule } from '@shared/rocket-components/pipes/format-time';
import { UserRegisteredComponent } from './create/user-registered/user-registered.component';
import { InputPasswordModule } from '@shared/rocket-components/directives/input-password';
import { ResetPasswordComponent } from './reset/reset-password.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { FlaDatepickerModule } from '@shared/rocket-components/components/datepicker';
import { FormValidatorsModule } from '@shared/components/form-validators/form-validators.module';
import { RecaptchaModule } from './recaptcha/recaptcha.module';
import { FormatTickSizeModule } from '@shared/rocket-components/pipes/format-tick-size';
import { RegisterPhoneComponent } from './register-phone/register-phone.component';

@NgModule({
  imports: [
    CommonModule,
    FlaIconModule,
    FlaCheckModule,
    FlaFocusModule,
    FlaInputModule,
    RocketButtonModule,
    FormsModule,
    ReactiveFormsModule,
    FormatTimeModule,
    FormatTickSizeModule,
    InputCodeModule,
    InputPasswordModule,
    FlaDatepickerModule,
    RegisterRoutes,
    FormValidatorsModule,
    RecaptchaModule,
    PhoneInputModule
  ],
  declarations: [
    RegisterComponent,
    WelcomeComponent,
    CreateComponent,
    ValidateComponent,
    TimerResendComponent,
    UserRegisteredComponent,
    ResetPasswordComponent,
    RecoveryComponent,
    RegisterPhoneComponent,
  ],
  exports: [RegisterComponent],
})
export class RegisterModule {}
