import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'stock-chart-select',
  template: `
    <div
      [style.min-height.px]="40"
      class="w-100 btn btn-fill-primary fs-6 d-inline-flex align-items-center justify-content-start text-light text-nowrap"
    >
      <span *ngIf="txtIcon" class="fs-6" [style.min-width.px]="24">
        {{ txtIcon }}
      </span>
      <fla-icon *ngIf="flaIcon" [name]="flaIcon" [css]="flaIconCss"></fla-icon>
      <span class="mx-2">{{ label }}</span>
      <fla-icon
        *ngIf="displayNavNext"
        name="navigate_next"
        css="w-100 text-light text-right"
        size="icon-size-tiny"
      ></fla-icon>
    </div>
    <ng-content></ng-content>
  `,
})
export class StockChartSelect {
  @Input() txtIcon: string | undefined = '';
  @Input() flaIcon: string | undefined = '';
  @Input() label: string = '';
  @Input() flaIconCss: string = '';
  @Input() displayNavNext: boolean = true;

  @Output() openIndicatorModal: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor() {}
}
