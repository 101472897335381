import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IBrokersToConnect } from '@shared/services/api/trademap/V9/models/brokers-to-connect.interface';
import { BrokerLogoComponent } from '@modules/broker-connection/components/broker-logo/broker-logo.component';

@Component({
  selector: 'app-benefit-card',
  templateUrl: './benefit-card.component.html',
  styles: [
    `
      .card-min-width {
        min-width: 348px;
        min-height: 254px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, BrokerLogoComponent],
})
export class BenefitCardComponent {
  @Output() handleButtonClicked = new EventEmitter<{
    action: string;
    value: string;
  }>();

  @Input() brokers: IBrokersToConnect[] = [];
  @Input() displayPaymentWarning: boolean = false;

  public handleBenefit(action: string, value: string): void {
    this.handleButtonClicked.emit({ action, value });
  }
}
