import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { formatterNumber, randomId } from '@shared/rocket-components/utils';
import { ScichartService } from '@shared/tiger-chart/services/scichart.service';
import {
  EAutoRange,
  EVerticalTextPosition,
  EXyDirection,
  EllipsePointMarker,
  FastBubbleRenderableSeries,
  NumberRange,
  NumericAxis,
  TWebAssemblyChart,
  XyzDataSeries,
  ZoomPanModifier,
  easing,
} from 'scichart';
import {
  TProfitability,
  TStockAnalysisMouseEvent,
  TTooltipData,
  TVariation,
  TVolatility,
} from '../../types';
import {
  STOCK_ANALYSIS_MOUSE_EVENT_DATA_TYPE,
  STOCK_ANALYSIS_NAVIGATION_CONTROLS,
  STOCK_ANALYSIS_PROFITABILITY,
  STOCK_ANALYSIS_PROFITABILITY_FIELDS,
  STOCK_ANALYSIS_RENDERABLE_SERIES_ID,
  STOCK_ANALYSIS_VARIATION_FIELDS,
  STOCK_ANALYSIS_VOLATILITY,
  STOCK_ANALYSIS_VOLATILITY_FIELDS,
} from '../../enum';
import { IStockListItemsRow } from '@shared/components/stock-table/interfaces/stock-table.interfaces';
import { wheelZoomModifier } from '@shared/tiger-chart/annotations/mouse-wheel-zoom-modifier';
import {
  bigFormatValueFormatter,
  getVolumeText,
} from 'src/app/utils/utils.functions';
import StockAnalysisPaletteProvider from './stock-analysis-palete-provider';
import {
  COLOR_RANGE_VARIATION_RANGE,
  TvariationRange,
} from '@shared/services/core/color-range/color-range.interface';
import { ColorRangeServiceService } from '@shared/services/core/color-range/color-range.service';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import themeHelper from 'src/app/utils/theme-helper';
import { StockAnalysisMouseEventsModifier } from './stock-analysis-mouse-events-modifier';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';

@Component({
  selector: 'app-stock-analysis-graph',
  templateUrl: './stock-analysis-graph.component.html',
  styleUrls: ['./stock-analysis-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockAnalysisGraphComponent
  extends themeHelper
  implements AfterViewChecked, OnChanges
{
  @Input() stockList!: IStockListItemsRow[];
  @Input() profitability: TProfitability = 'ONEDAY';
  @Input() volatility: TVolatility = '12M';
  @Input() variation: TVariation = 'VOLUME';
  @Input() width = 0;
  @Input() linked!: boolean;
  @Input() isMoversList!: boolean;

  private _scichartService!: ScichartService;
  private _chart!: TWebAssemblyChart;
  private _dataSeries!: XyzDataSeries;
  private _xAxis!: NumericAxis;
  private _yAxis!: NumericAxis;
  private _variation: TvariationRange = {
    [COLOR_RANGE_VARIATION_RANGE.NEGATIVE]: 0,
    [COLOR_RANGE_VARIATION_RANGE.POSITIVE]: 0,
  };
  private _bubbleSeries!: FastBubbleRenderableSeries;
  private _maxX = 0;
  private _minX = Number.POSITIVE_INFINITY;
  private _maxY = 0;
  private _minY = Number.POSITIVE_INFINITY;
  private _zoomModifier = new wheelZoomModifier({
    xyDirection: EXyDirection.XyDirection,
  });

  refChart: string = randomId('STOCK_ANALYSIS');
  tooltipTop = 0;
  tooltipLeft = 0;
  tooltipVisible = false;
  tooltipData: TTooltipData = {
    stock: '',
    profitability: '',
    volatility: '',
    variation: '',
  };

  constructor(
    private stockChartService: StockChartService,
    private cdr: ChangeDetectorRef,
    @Inject(LOCALE_ID) private locale: string,
    private _colorRangeService: ColorRangeServiceService,
    protected themeService: ThemePreferencesService,
    private _globalStock: GlobalSelectedStockSubscription
  ) {
    super(themeService);
    this._scichartService = new ScichartService(undefined, stockChartService);
    this.onThemeChange.subscribe(this._handleChartTheme);
  }

  ngAfterViewChecked() {
    const div = document.getElementById(this.refChart);
    !this._chart && div && this._buildChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { stockList, profitability, volatility, variation, isMoversList } =
      changes;
    ((stockList && stockList.currentValue) ||
      (profitability && profitability.currentValue) ||
      (volatility && volatility.currentValue) ||
      (variation && variation.currentValue)) &&
      this.plotData();
    isMoversList && this._chart && this._setZoom();
  }

  controlAction(data: { control: STOCK_ANALYSIS_NAVIGATION_CONTROLS }) {
    if (
      data.control === STOCK_ANALYSIS_NAVIGATION_CONTROLS.ZOOM_IN ||
      data.control === STOCK_ANALYSIS_NAVIGATION_CONTROLS.ZOOM_OUT
    ) {
      this.zoomNavigate(data.control);
    }
    if (data.control === STOCK_ANALYSIS_NAVIGATION_CONTROLS.REFRESH) {
      this._setDefaultVisibleRange();
    }
  }

  private _handleChartTheme = (): void => {
    if (this._bubbleSeries)
      this._bubbleSeries.dataLabelProvider.color = this.theme.axisTitleColor;
    if (this._chart)
      this._scichartService.changeThemeHandler(this._chart, this.isDarkTheme);
  };

  private _buildChart() {
    this._scichartService.initSciChart(this.refChart).then((baseChart) => {
      this._chart = baseChart;

      this._yAxis = new NumericAxis(baseChart.wasmContext, {
        drawMajorGridLines: false,
        drawMinorGridLines: false,
        drawMajorTickLines: false,
        drawMinorTickLines: false,
        drawMajorBands: false,
        autoTicks: true,
        autoRange: EAutoRange.Once,
        growBy: new NumberRange(0, 1),
        lineSpacing: 1.5,
        axisTitle: ['Volatilidade'],
        axisTitleStyle: { fontSize: 14 },
      });
      this._yAxis.labelProvider.formatLabel = formatterNumber;
      this._yAxis.labelProvider.formatCursorLabel = formatterNumber;
      this._chart.sciChartSurface.yAxes.add(this._yAxis);

      this._xAxis = new NumericAxis(baseChart.wasmContext, {
        drawMajorGridLines: false,
        drawMinorGridLines: false,
        drawMajorTickLines: false,
        drawMinorTickLines: false,
        drawMajorBands: false,
        autoTicks: true,
        autoRange: EAutoRange.Once,
        growBy: new NumberRange(0, 1),
        lineSpacing: 1.5,
        axisTitle: ['Rentabilidade'],
        axisTitleStyle: { fontSize: 14 },
      });
      this._xAxis.labelProvider.formatLabel = formatterNumber;
      this._xAxis.labelProvider.formatCursorLabel = formatterNumber;
      this._chart.sciChartSurface.xAxes.add(this._xAxis);
      this.stockChartService.removeChartLoading$.next({
        remove: true,
        ref: this.refChart,
      });
      this._chart.sciChartSurface.chartModifiers.add(
        new ZoomPanModifier({
          xyDirection: EXyDirection.XyDirection,
        })
      );
      this._setZoom();

      const mouseEventsModifier = new StockAnalysisMouseEventsModifier();
      mouseEventsModifier.onEvents().subscribe((e) => this.mouseEvents(e));
      this._chart.sciChartSurface.chartModifiers.add(mouseEventsModifier);

      this.stockList && this.plotData();

      this.cdr.detectChanges();
    });
  }

  mouseEvents(e: TStockAnalysisMouseEvent) {
    const { type, data } = e;

    if (
      type == STOCK_ANALYSIS_MOUSE_EVENT_DATA_TYPE.SERIES_ELEMENT_MOUSE_CLICK
    ) {
      this.linked &&
        data &&
        this._globalStock.researchToStandardizeGlobalStock(data.metadata.data);
      return;
    }

    if (!data || !data.isHit) {
      this._setChartCursor('default');
      this.tooltipVisible = false;
      this.cdr.detectChanges();
      return;
    }

    this.tooltipTop = data.hitTestPoint.y || 0;
    this.tooltipLeft = data.hitTestPoint.x + 25 || 0;
    this.tooltipVisible = e && data.isHit;

    this.tooltipData = {
      stock: data.metadata.data.cd_stock,
      profitability: formatterNumber(data.metadata.data.x),
      volatility: formatterNumber(data.metadata.data.y),
      variation: bigFormatValueFormatter(data.metadata.data.z),
    };
    this.linked && this._setChartCursor('pointer');
    this.cdr.detectChanges();
  }

  plotData() {
    if (
      !this.stockList ||
      this.stockList.length == 0 ||
      !this.profitability ||
      !this.volatility ||
      !this.variation ||
      !this._chart
    )
      return;

    const s = this._chart.sciChartSurface.suspendUpdates(); // This locks the surface and prevents further drawing

    this._setAxisLabels();

    try {
      this._chart.sciChartSurface.renderableSeries.clear();
      this._chart.sciChartSurface.annotations.clear();

      const xValues: number[] = [];
      const yValues: number[] = [];
      const zValues: number[] = [];
      this._maxX = 0;
      this._minX = Number.POSITIVE_INFINITY;
      this._maxY = 0;
      this._minY = Number.POSITIVE_INFINITY;
      let maxZ = 0;

      this._variation = {
        [COLOR_RANGE_VARIATION_RANGE.NEGATIVE]: 0,
        [COLOR_RANGE_VARIATION_RANGE.POSITIVE]: 0,
      };

      this.stockList.forEach((stock: any) => {
        const axisValues = this._getAxisValuesFromStock(stock);
        const { x, y } = axisValues;
        let { z } = axisValues;

        z < 0 && (z = z * -1);

        x > this._variation[COLOR_RANGE_VARIATION_RANGE.POSITIVE] &&
          (this._variation[COLOR_RANGE_VARIATION_RANGE.POSITIVE] = x);
        x < this._variation[COLOR_RANGE_VARIATION_RANGE.NEGATIVE] &&
          (this._variation[COLOR_RANGE_VARIATION_RANGE.NEGATIVE] = x);

        this._maxX < x && (this._maxX = x);
        this._minX > x && (this._minX = x);
        this._maxY < y && (this._maxY = y);
        this._minY > y && (this._minY = y);
        maxZ < z && (maxZ = z);

        xValues.push(x);
        yValues.push(y);
        zValues.push(z);
      });

      this._dataSeries = new XyzDataSeries(this._chart.wasmContext, {
        xValues,
        yValues,
        zValues: this.stockList.map((stock: any) => {
          const size = this.isMoversList
            ? stock.volume
            : stock[STOCK_ANALYSIS_VARIATION_FIELDS[this.variation]];
          return this._normalizeSize(size, maxZ);
        }),
        containsNaN: false,
        isSorted: true,
        metadata: this.stockList.map(
          (stock: IStockListItemsRow, index: number) => ({
            isSelected: false,
            type: '',
            data: {
              cd_stock: stock.cd_stock,
              synonymous_nickname: stock.synonymous_nickname,
              size: getVolumeText(this.locale, zValues[index]),
              z: zValues[index],
              x: xValues[index],
              y: yValues[index],
            },
          })
        ),
      });
      this._bubbleSeries = new FastBubbleRenderableSeries(
        this._chart.wasmContext,
        {
          id: STOCK_ANALYSIS_RENDERABLE_SERIES_ID,
          dataSeries: this._dataSeries,
          dataLabels: {
            metaDataSelector: (point: any) => {
              return point?.data?.cd_stock;
            },
            style: {
              fontFamily: 'Arial',
              fontSize: 12,
            },
            verticalTextPosition: EVerticalTextPosition.Below,
            color: this.theme.axisTitleColor,
          },
          pointMarker: new EllipsePointMarker(this._chart.wasmContext, {
            width: 50,
            height: 50,
            strokeThickness: 0,
            opacity: 1,
          }),
          zMultiplier: 1,
          paletteProvider: new StockAnalysisPaletteProvider(
            this._colorRangeService,
            this._variation
          ),
        }
      );

      this._chart.sciChartSurface.renderableSeries.add(this._bubbleSeries);
      this._setDefaultVisibleRange();
    } finally {
      s.resume();
    }
  }

  private _normalizeSize(size: number, maxZ: number): number {
    if (size == 0) return 20;
    const positiveSize = size < 0 ? size * -1 : size;
    const sizePercentage = positiveSize / maxZ;
    const ballSize = 40 * sizePercentage;
    const minSize = 20;

    return minSize + ballSize;
  }

  private _getAxisValuesFromStock(stock: any): {
    x: number;
    y: number;
    z: number;
  } {
    if (this.isMoversList) {
      const x = +stock.preco_ultimo || 0;
      const y = +stock.variacao || 0;
      const z = +stock.volume || 0;
      return { x, y, z };
    }

    const x =
      stock[STOCK_ANALYSIS_PROFITABILITY_FIELDS[this.profitability]] || 0;
    const y = stock[STOCK_ANALYSIS_VOLATILITY_FIELDS[this.volatility]] || 0;
    const z = stock[STOCK_ANALYSIS_VARIATION_FIELDS[this.variation]] || 0;

    return { x, y, z };
  }

  private _setAxisLabels() {
    if (this.isMoversList) {
      this._xAxis.axisTitle = 'Rentabilidade | 1 dia';
      this._yAxis.axisTitle = 'Volatilidade | 12m';
      return;
    }

    this._xAxis.axisTitle = `Rentabilidade | ${
      STOCK_ANALYSIS_PROFITABILITY[this.profitability]
    }`;
    this._yAxis.axisTitle = `Volatilidade | ${STOCK_ANALYSIS_VOLATILITY[
      this.volatility
    ].replace('Volatilidade ', '')}`;
  }

  private zoomNavigate(key: STOCK_ANALYSIS_NAVIGATION_CONTROLS) {
    this._setAxisVisibility(key, this._xAxis);
    this._setAxisVisibility(key, this._yAxis);
  }

  private _setAxisVisibility(
    key: STOCK_ANALYSIS_NAVIGATION_CONTROLS,
    axis: NumericAxis
  ) {
    const axisRange = axis.visibleRange.max - axis.visibleRange.min;
    const diff = this._getAxisZoom(axisRange);
    const diffValue =
      key == STOCK_ANALYSIS_NAVIGATION_CONTROLS.ZOOM_IN ? -diff : diff;

    let min = Math.floor(axis.visibleRange.min - diffValue);
    const max = Math.floor(axis.visibleRange.max + diffValue);
    if (min >= max) {
      if (axis.isHorizontalAxis) {
        return;
      }
      min = max - diffValue;
      if (min >= max) return;
    }

    const shiftedRange = new NumberRange(min, max);

    axis.animateVisibleRange(shiftedRange, 300, easing.inOutQuad);
  }

  private _getAxisZoom(axisRange: number): number {
    if (axisRange > 1000) {
      const length = `${Math.floor(axisRange)}`.length;
      const strValue = `1${new Array(length).join('0')}`;

      return +strValue;
    }
    if (axisRange > 100) {
      return 10;
    }
    if (axisRange > 10) return 5;
    if (axisRange > 1) return 1;
    return 0.5;
  }

  private _setDefaultVisibleRange() {
    this._xAxis.visibleRange = new NumberRange(this._minX - 3, this._maxX + 3);
    this._yAxis.visibleRange = new NumberRange(this._minY - 3, this._maxY + 3);
  }

  private _setChartCursor(cursor: string) {
    const element = document.getElementById(`${this.refChart}_2D`);
    if (element) {
      element.style.cursor = cursor;
    }
  }

  private _setZoom() {
    if (this.isMoversList) {
      this._chart.sciChartSurface.chartModifiers.remove(this._zoomModifier);
      return;
    }

    !this._zoomModifier.isAttached &&
      this._chart.sciChartSurface.chartModifiers.add(this._zoomModifier);
  }
}
