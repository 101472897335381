import { Routes, RouterModule } from '@angular/router';
import { InvalidDeviceComponent } from './invalid-device.component';

const routes: Routes = [
  {
    path: '',
    component: InvalidDeviceComponent,
  },
];

export const InvalidDeviceRoutes = RouterModule.forChild(routes);
