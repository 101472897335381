import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicControlService } from './dynamic-control.service';
import { DynamicFormComponent } from './dynamic-form.component';

import { TooltipsModule } from '../tooltips/tooltips.module';
import { FlaIconModule, FlaInputModule, FlaSelectModule } from '../components';
import { RTInputCountModule } from '../input-count/input-count.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlaInputModule,
    RTInputCountModule,
    FlaSelectModule,
    FlaIconModule,
    TooltipsModule,
  ],
  declarations: [DynamicFormComponent],
  exports: [DynamicFormComponent],
  providers: [DynamicControlService],
})
export class DynamicFormModule {}
