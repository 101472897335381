export const USER_DATA_FIELDS = [
  {
    label: 'Nome',
    key: 'name',
  },
  {
    label: 'Nome de usuário',
    key: 'nickname',
  },
  {
    label: 'CPF',
    key: 'cnpj_cpf',
    width: '50%',
  },
  {
    label: 'Data de Nascimento',
    key: 'dt_birthday',
    width: '50%',
  },
  {
    label: 'E-mail',
    key: 'email',
    width: '50%',
    displayButton: true,
    buttonLabel: 'Trocar e-mail',
  },
  {
    label: 'Telefone',
    key: 'phone_mobile',
    width: '50%',
    displayButton: true,
    buttonLabel: 'Trocar telefone',
  },
  {
    label: 'Senha',
    key: 'password',
    displayButton: true,
    buttonLabel: 'Trocar senha',
    width: '50%',
  },
];

export const getTokenTooltip = (): string => {
  return `
  <div class="w-100 d-flex flex-column fs-6">
    <span class="fw-bold mb-2 text-center">Token</span>
    <span class="mb-2 text-left">O Token é um recurso adicional de segurança.</span>
    <span class="text-left">
      Para obtê-lo, acesse seu segundo fator de segurança.
    </span>
  </div>
`;
};
