<div
  [id]="'chart-ipo-volume-tooltip-div-id_' + refChart"
  class="position-absolute scichart-tooltip-div-cl bg-neutral-strong"
  [ngClass]="{ 'd-none': !displayTooltip }"
  [style.left.px]="tooltipX"
  [style.top.px]="tooltipY"
></div>

<h5 class="fw-bolder">Histórico de IPOs</h5>
<div class="chart-parent">
  <div
    (mousemove)="onMouseMove($event)"
    [id]="refChart"
    #chartContainer
    class="chart-container"
  ></div>
</div>
