import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-path-rocket]',
  templateUrl: './path-rocket.component.html',
  styleUrls: ['./path-rocket.component.scss'],
})
export class PathRocketComponent {
  @Input() withIcon: boolean = false;
}
