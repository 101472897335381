import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  QueryList,
  ViewChildren,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  HostListener,
  inject,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  RocketModalRef,
  RocketModalService,
  ROCKET_MODAL_DATA,
  FlaInputComponent,
} from '@shared/rocket-components/components/index';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  auditTime,
  concat,
  debounceTime,
  delay,
  filter,
  skip,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { ISearchStock } from 'src/app/core/interface';
import { StockListItemsModel } from '@shared/components/stock-table/models/stock-list-items.model';
import { StockCardComponent } from '@shared/rocket-components/components/stock-card/stock-card.component';
import { SuperSearchService } from '@modules/home/super-search.service';
import { StockSearchConfigModalComponent } from '@shared/components/stock-search-config-modal/stock-search-config-modal.component';
import { StockServiceRT } from '@shared/services/api/nitro-ws/v1/stock.service';
import { MostSearchComponent } from '@shared/modals/most-search/most-search.component';
import { StockService } from '@shared/services/api/trademap/v1/stock.service';

@Component({
  selector: 'app-stock-chart-modal-stock',
  templateUrl: './stock-chart-modal-stock.component.html',
  styles: [
    `
      :host {
        ::ng-deep .modal-dialog {
          max-width: 450px !important;
        }

        .custom-suffix {
          top: 3px;
          z-index: 3;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockChartModalStockComponent
  extends RocketModalRef
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('inputStock') input!: FlaInputComponent;
  @ViewChild('mostSearch') mostSearch!: MostSearchComponent;
  @ViewChildren(StockCardComponent) items!: QueryList<StockCardComponent>;

  modalService!: RocketModalService;
  stockControl = new FormControl();
  stockList: ISearchStock[] = [];
  stockSearched!: string;
  page: number = 1;
  vlCloseHistory: any = {};
  loading = true;
  noData = false;
  public isSearching: boolean = false;
  public showHelp = false;
  public modalConfigOpened: boolean = false
  public hasSomeStockFilter = false;
  private lastValueSearch: string = '';
  private controlChanges$ = new Subject<{ value: string; isInit: boolean }>();
  private word$!: Subscription;
  private _searchRequest$!: Subscription | null;
  private isDestroy = false;
  private isInit = false;
  private onKeyUp$ = new Subject<void>();
  private _destroy = new Subject<void>();
  private setDivFocus$ = new Subject<{ div: HTMLElement }>();
  private reselectDivAfterSearch$ = new Subject<void>();
  private lastPositionArrow: number = 0;
  private _stockService = inject(StockService);

  get inputStock() {
    return this.stockControl;
  }

  set inputStock(value: any) {
    this.inputStock.setValue(value);
  }

  constructor(
    service: RocketModalService,
    private stockService: StockServiceRT,
    @Inject(ROCKET_MODAL_DATA)
    private data: {
      ref: string;
      word?: string;
      loadWord: BehaviorSubject<string>;
      component: any;
      searchString?: string;
    },
    private _cdr: ChangeDetectorRef,
    private _superSearch: SuperSearchService,
    private _elementRef: ElementRef
  ) {
    super(service);
    this.modalService = service;
    this.onKeyUp$.pipe(delay(10)).subscribe(() => {
      const div = document.querySelector(
        'app-most-search div.active'
      ) as HTMLElement;
      this.setDivFocus(div);
    });
    this.setDivFocus$
      .pipe(
        filter(({ div }) => !!div),
        tap(({ div }) => {
          div.tabIndex = 0;
        }),
        delay(50)
      )
      .subscribe(({ div }) => {
        div.focus();
      });
    this.reselectDivAfterSearch$.pipe(delay(10)).subscribe(() => {
      const allDivs = document.querySelectorAll(
        'app-most-search app-stock-card>div'
      );
      const div = allDivs.item(this.lastPositionArrow);
      if (div) {
        this.setDivFocus(div as HTMLElement);
        this.mostSearch.setKeyManagerItem(this.lastPositionArrow);
      }
    });
  }

  @HostListener('window:keydown.arrowup', ['$event'])
  @HostListener('window:keydown.arrowdown', ['$event'])
  onKeyUp(event: any) {
    if (event.keyCode == 38) {
      this.lastPositionArrow =
        this.lastPositionArrow == 0
          ? this.stockList.length - 1
          : this.lastPositionArrow - 1;
    } else {
      this.lastPositionArrow += 1;
    }
    this.onKeyUp$.next();
  }

  private setDivFocus(div: HTMLElement) {
    this.setDivFocus$.next({ div });
  }

  observerWordChanges() {
    const firstEvent$ = this.data.loadWord.pipe(take(1), debounceTime(100));
    const remainingEvents$ = this.data.loadWord.pipe(skip(1));
    return concat(firstEvent$, remainingEvents$);
  }

  ngOnInit(): void {
    document.addEventListener('keyup', this._handleKeyUp);
    if (!this.data?.loadWord?.value) {
      this.showHelp = true;
    }
    this.controlChanges$.pipe(auditTime(500)).subscribe((data) => {
      const { isInit } = data;
      let { value } = data;
      if (isInit) {
        value = this.stockControl.value;
      }
      if (!value) {
        this.stockList = [];
        this.noData = false;
        this.loading = false;
        this.mostSearch.setItensScroll(this.stockList);
        this._cdr.detectChanges();
        return;
      }
      this.stockSearched = value;
      this._searchData(value);
      this._superSearch.setWord(value);
    });
    this.controlChanges$.next({ value: '', isInit: true });
  }

  override ngOnDestroy(): void {
    this.isDestroy = true;
    this.isInit = false;
    this.word$ && this.word$.unsubscribe();
    this._superSearch.modalClose$.next(undefined);
    this.inputStock = '';
    this.lastValueSearch = '';
    this.controlChanges$ && this.controlChanges$.unsubscribe();
    this.onKeyUp$ && this.onKeyUp$.unsubscribe();
    this.setDivFocus$ && this.setDivFocus$.unsubscribe();
    this.reselectDivAfterSearch$ && this.reselectDivAfterSearch$.unsubscribe();
    this._destroy.next();
    this._destroy.unsubscribe();
  }

  override close(item?: ISearchStock) {
    this.lastValueSearch = '';
    this._superSearch.modalClose$.next(item);
    super.close();
  }

  ngAfterViewInit(): void {
    this._stockService.hasFilterOnSearchStock
      .pipe(
        takeUntil(this._destroy),
        filter((value) => value !== undefined)
      )
      .subscribe((hasFilter) => {
        this.hasSomeStockFilter = hasFilter;
        this._cdr.detectChanges();
      });
    if(this.data.loadWord){
      this.word$ = this.observerWordChanges()
        ?.pipe(
          filter(() => !this.isInit),
          tap(() => {
            this.isSearching = true;
            this._cdr.detectChanges();
          }),
          debounceTime(500)
        )
        .subscribe((word: any) => {
          this.stockControl.setValue(word);
          this.stockControl.markAsTouched();
          this.input.setFocus();
          this._cdr.detectChanges();
          this.isInit = true;
          if (word) {
            this._searchData(word);
          } else {
            this.isSearching = false;
            this._cdr.detectChanges();
          }
        });
    }

    this.stockControl.valueChanges
    .pipe(debounceTime(200))
    .subscribe((value: any) => {
      this.controlChanges(value)
    }) 
  }

  openConfig() {
    this.modalConfigOpened = true
    this._cdr.detectChanges();
    this.modalService.open(StockSearchConfigModalComponent, {
      centered: true,
      backdrop: true,
      keyboard: false,
      toggleBetweenModal: this._elementRef,
      data: { isSecondModal: true },
    }).afterDismissed
      .subscribe(() => {
        this.modalConfigOpened = false
        this.controlChanges$.next({ value: this.stockControl.value, isInit: true });
        this._cdr.detectChanges();
      });
  }

  parseStock(stock: ISearchStock): StockListItemsModel {
    return new StockListItemsModel(stock, false);
  }

  onSave(item: ISearchStock) {
    this.close(item);
  }

  private _handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this._superSearch.modalClose$.next(undefined);
    }
  };

  controlChanges = (value: any) => {
    if (value == this.lastValueSearch) {
      return;
    }
    this.page = 1;
    this.lastPositionArrow = 0;
    this.controlChanges$.next({ value, isInit: false });
  };

  private _searchData = (value: string) => {
    if (this.isDestroy) return;
    if (this._searchRequest$) {
      this._searchRequest$.unsubscribe();
      this._searchRequest$ = null;
    }
    if (this.page <= 1) {
      this.loading = true;
      this.noData = false;
      this.stockList = [];
    }
    this.lastValueSearch = value;
    this._searchRequest$ = this.stockService
      .searchStock(value, [], false, true, { perPage: 15, page: this.page })
      .subscribe((resp: any) => {
        if (this.page > 1) {
          this.stockList.push(...resp);
          this.reselectDivAfterSearch$.next();
        } else {
          this.stockList = resp;
          this.noData = !this.stockList.length;
          this.loading = false;
        }
        this.mostSearch.setItensScroll(this.stockList);
        this._cdr.detectChanges();
        setTimeout(() => {
          this.mostSearchSearching();
        }, 300);
      });
  };

  private mostSearchSearching() {
    this.isSearching = false;
    this._cdr.detectChanges();
  }

  scrollEnd() {
    if (!this.stockSearched) return;
    this.page++;
    this._searchData(this.stockSearched);
  }
}
