import { Dictionary } from '@core/models';
import { SCREENING_COMPONENT } from './components/screening';
import { HEATMAP_COMPONENT } from './components/heatmap';
import { PERFORMANCE_REPORT_COMPONENT } from './components/performance-report';
import { NEWS_COMPONENT } from './components';
import { AT_PRICE_COMPONENT } from './components/at-price';
import { OPTIONS_COMPONENT } from './components/options';
import { RANKING_BROKER_COMPONENT } from './components/ranking-broker';
import { RENT_BTC_COMPONENT } from './components/rent-btc';
import { FLOW_INVESTOR_COMPONENT } from './components/flow-investor';
import { BUSINESS_PROFILE_COMPONENT } from './components/business-profile';
import { MARKET_RESUME_COMPONENT } from './components/market-resume';
import { STOCK_ANALYSIS_COMPONENT } from './components/stock-analysis';
import { TRADE_IDEA_COMPONENT } from './components/trade-idea';
import { RISK_MANAGEMENT_MODAL } from './modals/risk-management';

const tools = [
  MARKET_RESUME_COMPONENT(),
  NEWS_COMPONENT(),
  TRADE_IDEA_COMPONENT,

  BUSINESS_PROFILE_COMPONENT(),
  AT_PRICE_COMPONENT(),
  RANKING_BROKER_COMPONENT(),
  RENT_BTC_COMPONENT(),
  FLOW_INVESTOR_COMPONENT(),

  HEATMAP_COMPONENT(),
  STOCK_ANALYSIS_COMPONENT(),
  SCREENING_COMPONENT,
  OPTIONS_COMPONENT(),
  RISK_MANAGEMENT_MODAL,
  PERFORMANCE_REPORT_COMPONENT,
];

export const TOOLS_LIST: Dictionary<any> = new Dictionary();
TOOLS_LIST.bulkData('cod', tools);
