import { Injectable } from '@angular/core';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import { orderCheckIsFractionalStock } from 'src/app/utils/utils.functions';
import { IAccountSelect, IOrders, ISearchStock } from '@core/interface';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  constructor(private daytradeService: DaytradeService) {}

  orderChannelFilter = (
    order: IOrders | any,
    stock: ISearchStock,
    account: IAccountSelect,
    validityFractional: boolean = true
  ) => {
    if (order?.isSnap || order?.isReconnect) return true;
    const useDayTradeMode = this.daytradeService.useDayTradeMode;
    const isSameBroker = account?.id_broker === order?.id_broker;
    const isSameStock = validityFractional
      ? orderCheckIsFractionalStock(stock, order?.cd_stock)
      : stock.cd_stock === order?.cd_stock;
    const isDayTrade = useDayTradeMode === order?.is_day_trade;
    return !!order && isSameBroker && isSameStock && isDayTrade;
  };
}
