import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TickStrategyComponent } from './tick-strategy.component';
import {
  FlaIconModule,
  FlaInputModule,
  FlaRadiosModule,
  RocketButtonModule,
} from '@shared/rocket-components/components';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { RTInputCountModule } from '@shared/rocket-components/input-count/input-count.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlaIconModule,
    RocketButtonModule,
    TooltipsModule,
    RTInputCountModule,
    FlaInputModule,
    FlaRadiosModule,
  ],
  declarations: [TickStrategyComponent],
  exports: [TickStrategyComponent],
})
export class TickStrategyModule {}
