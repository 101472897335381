<ng-container *ngIf="!flowExpanded">
  <div class="h-100 overflow-y-auto">
    <div class="d-flex justify-content-between p-2">
      <ng-container *ngFor="let flow of typesFlows; let i = index">
        <div
          class="col-8"
          [ngClass]="{
            'border-right pr-3': i < typesFlows.length - 1,
            'pl-3': i > 0
          }"
        >
          <div class="d-flex align-items-center justify-content-between mb-1">
            <h5 class="fw-bolder">{{ flow.label }}</h5>
            <div
              class="cursor-pointer text-neutral-medium d-flex align-items-center"
              (click)="flowToExpand(flow)"
            >
              <fla-icon name="open_in_new" size="icon-size-micro"></fla-icon>
            </div>
          </div>

          <ng-container *ngIf="cards[flow.code]?.length">
            <app-flow-cards
              [cards]="cards[flow.code]"
              [type]="flow.code"
            ></app-flow-cards>
          </ng-container>

          <app-chart-evolution
            [data]="futures"
            [code]="flow.code"
            [title]="flow.titleChart"
            [containerWidth]="width"
            [containerHeight]="height"
            [updatePosition]="updatePosition"
            [isLeftSideChart]="i > 0"
          ></app-chart-evolution>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="flowExpanded">
  <div class="h-100 overflow-y-auto p-2">
    <div class="d-flex pb-2">
      <rocket-button
        [label]="flowExpanded.label"
        type="fill"
        (rocketClick)="flowToExpand(null)"
        css="text-white fs-5 m-0 p-2"
      >
        <fla-icon
          fla-icon
          name="arrow_back_ios"
          css="text-brand-primary"
        ></fla-icon>
      </rocket-button>
    </div>
    <div>
      <app-flow-cards
        [cards]="cards[flowExpanded.code]"
        [type]="flowExpanded.code"
        [expanded]="true"
        *ngIf="cards[flowExpanded.code]?.length"
      ></app-flow-cards>
    </div>
    <div class="mt-1">
      <ng-container>
        <app-chart-evolution
          [data]="futures"
          [code]="flowExpanded.code"
          [title]="flowExpanded.titleChart"
        ></app-chart-evolution>
      </ng-container>
    </div>
  </div>
</ng-container>
