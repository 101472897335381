import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  signal,
} from '@angular/core';
import { OptionsComponentService } from '@shared/components/options/options-component.service';
import { TOptionSmileChartSeries } from '@shared/components/options/types';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { formatterNumber, randomId } from '@shared/rocket-components/utils';
import { ScichartService } from '@shared/tiger-chart/services/scichart.service';
import {
  CursorTooltipSvgAnnotation,
  FastLineRenderableSeries,
  SeriesInfo,
  TWebAssemblyChart,
  XyDataSeries,
  adjustTooltipPosition,
} from 'scichart';

@Component({
  selector: 'app-smile-graph',
  templateUrl: './options-smile-graph.component.html',
  styleUrls: ['./options-smile-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsSmileGraphComponent implements AfterViewInit, OnChanges {
  @Input() set height(height: string) {
    if (height) {
      this.componentHeight.set(parseInt(height));
    }
  }
  @Input() theme!: any;
  @Input() data!: TOptionSmileChartSeries[];
  @Input() refId!: string;
  @Input() loading = true;
  @Output() chartReady = new EventEmitter();

  componentHeight = signal(0);
  legendId: string = randomId('options-smile-legend');
  noDataId: string = randomId('options-smile-no-data');
  loadingGraph = true;
  chart!: TWebAssemblyChart | undefined;
  callLine!: FastLineRenderableSeries | undefined;
  putLine!: FastLineRenderableSeries | undefined;
  callName = '';
  putName = '';
  scichartService!: ScichartService;

  constructor(
    private _stockChartService: StockChartService,
    private optionsService: OptionsComponentService
  ) {
    this.scichartService = new ScichartService(
      undefined,
      this._stockChartService
    );
  }

  ngAfterViewInit() {
    this._initGraph();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { theme, data } = changes;
    if ((theme && theme.currentValue) || (data && data.currentValue)) {
      this._plotData();
    }
  }

  private _initGraph() {
    this.scichartService.initSciChart(this.refId).then((baseChart) => {
      this.chart = baseChart;
      const xAxis = this.optionsService.buildXNumericAxis(this.chart, 2);
      this.chart.sciChartSurface.xAxes.add(xAxis);
      const yAxis = this.optionsService.buildYAxis(this.chart);
      this.chart.sciChartSurface.yAxes.add(yAxis);
      const { callName, putName } = this;
      this.chart.sciChartSurface.chartModifiers.add(
        this.optionsService.buildCursorModifier(
          this.theme,
          this._tooltipSvgTemplate,
          { callName, putName }
        )
      );
      this.chart.sciChartSurface.chartModifiers.add(
        this.optionsService.buildZoomPan()
      );
      this.chart.sciChartSurface.chartModifiers.add(
        this.optionsService.buildWheelZoom()
      );
      this.chart.sciChartSurface.chartModifiers.add(
        this.optionsService.buildZoomDoubleClick()
      );
      this.chart.sciChartSurface.chartModifiers.add(
        this.optionsService.buildLegend(this.legendId)
      );
      this._stockChartService.removeChartLoading$.next({
        remove: true,
        ref: this.refId,
      });
      this.loadingGraph = false;
      this._plotData();
      this.chartReady.emit(null);
    });
  }

  private _plotData() {
    if (!this.chart) return;
    const xCallValues: number[] = [];
    const yCallValues: number[] = [];
    const xPutValues: number[] = [];
    const yPutValues: number[] = [];

    for (let index = 0; index < this.data.length; index++) {
      const element = this.data[index];
      if (element.option_type == 'C') {
        yCallValues.push(element.vl_vol_implicitly * 100);
        xCallValues.push(element.strike_price);
        this.callName = element.cd_stock;
        continue;
      }
      yPutValues.push(element.vl_vol_implicitly * 100);
      xPutValues.push(element.strike_price);
      this.putName = element.cd_stock;
    }

    this.callLine = this._plotLine(
      xCallValues,
      yCallValues,
      this.theme.multibrokerBuy,
      'Call',
      this.callLine
    );
    this.putLine = this._plotLine(
      xPutValues,
      yPutValues,
      this.theme.multibrokerSell,
      'Put',
      this.putLine
    );
    this.chart.sciChartSurface.zoomExtents();
  }

  private _plotLine(
    xValues: number[],
    yValues: number[],
    lineColor: string,
    name: string,
    lineSeries?: FastLineRenderableSeries
  ): FastLineRenderableSeries | undefined {
    if (!this.chart) return;
    const dataSeries = new XyDataSeries(this.chart.wasmContext, {
      dataSeriesName: name,
      yValues: yValues,
      xValues: xValues,
      containsNaN: false,
    });
    if (lineSeries) {
      lineSeries.dataSeries = dataSeries;
      return lineSeries;
    }
    lineSeries = new FastLineRenderableSeries(this.chart.wasmContext, {});
    lineSeries.strokeThickness = 2;
    lineSeries.stroke = lineColor;

    lineSeries.dataSeries = dataSeries;
    this.chart.sciChartSurface.renderableSeries.add(lineSeries);

    return lineSeries;
  }

  private _tooltipSvgTemplate(
    seriesInfos: SeriesInfo[],
    svgAnnotation: CursorTooltipSvgAnnotation,
    theme: any,
    data?: any
  ): string {
    const width = 120;
    const height = 100;
    const seriesInfo = seriesInfos[0];
    if (!seriesInfo?.isWithinDataBounds) {
      return '<svg></svg>';
    }

    const x = seriesInfo ? seriesInfo.formattedXValue : '';
    const y1 = seriesInfo ? formatterNumber(seriesInfo.yValue) : '';
    const y2 = seriesInfos[1] ? formatterNumber(seriesInfos[1].yValue) : '';

    adjustTooltipPosition(width, height, svgAnnotation);
    return `<svg width="${width}" height="${height}">
            <rect width="100%" height="100%" rx="4" style="fill:${theme.axisBandsFill};"></rect>
            <svg width="100%">
                <text y="5" font-size="11" dy="0" fill="${theme.axisTitleColor}">
                    <tspan x="6" text-anchor="left" dy="1.4em" font-weight="bold">${data.callName}</tspan>
                    <tspan x="6" text-anchor="left" dy="1.2em">Strike: ${x}</tspan>
                    <tspan x="6" text-anchor="left" dy="1.2em">Vol. Imp.: ${y1}%</tspan>
                    <tspan x="6" text-anchor="left" dy="1.8em" font-weight="bold">${data.putName}</tspan>
                    <tspan x="6" text-anchor="left" dy="1.2em">Strike: ${x}</tspan>
                    <tspan x="6" text-anchor="left" dy="1.2em">Vol. Imp.: ${y2}%</tspan>
                </text>
            </svg>
        </svg>`;
  }
}
