import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlaTListGroup } from './types/list-group-types.types';

@Component({
  selector: 'fla-list-group',
  templateUrl: './list-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlaListGroupComponent {
  @Input('tag') typeTag!: FlaTListGroup;
  @Input('css') customClass!: string;
}
