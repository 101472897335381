import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { FlaIconModule } from '@shared/rocket-components/components';

@Component({
  selector: 'app-stock-logo',
  templateUrl: './stock-logo.component.html',
  styles: [
    `
      .logo-container {
        width: 32px;
        height: 32px;
        object-fit: contain;
      }

      .custom-index {
        z-index: -1;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, FlaIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockLogoComponent {
  @Input() public image: string = '';

  public displayIcon: boolean = true;
  public errorOnLoad: boolean = false;

  constructor(private _cdr: ChangeDetectorRef) {}

  public handleImage(type: 'LOADED' | 'ERROR'): void {
    if (type === 'LOADED') {
      this.displayIcon = false;
      this.errorOnLoad = false;
      this._cdr.detectChanges();
      return;
    }
    this.displayIcon = false;
    this.errorOnLoad = true;
    this._cdr.detectChanges();
  }
}
