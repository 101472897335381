import { FlaInputPrefixComponent } from './input-parts/input-prefix.component';
import { FlaInputComponent } from './input.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlaInputSuffixComponent } from './input-parts/input-suffix.component';
import { FlaInputSplitComponent } from './input-parts/input-split.component';
import { FlaMaskModule } from '@shared/rocket-components/directives';
const COMPONENTS = [
  FlaInputComponent,
  FlaInputSuffixComponent,
  FlaInputPrefixComponent,
  FlaInputSplitComponent,
];
@NgModule({
  declarations: [COMPONENTS],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlaMaskModule.forChild(),
  ],
  exports: [COMPONENTS],
})
export class FlaInputModule {}
