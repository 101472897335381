import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  RocketModalRef,
  RocketModalService,
  ROCKET_MODAL_DATA,
} from '@shared/rocket-components/components/index';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { TIGER_CHART_TOOL } from '../../tiger-chart-tools.interface';
import { ToolbarDrawConfigs, ToolbarModel } from '../toolbar-draw.interface';

@Component({
  selector: 'app-modal-model-save',
  templateUrl: './modal-model-save.component.html',
  styleUrls: ['./modal-model-save.component.scss'],
})
export class ModalModelSaveComponent extends RocketModalRef implements OnInit {
  savedModels: ToolbarModel[] = [];
  form!: FormGroup;
  isTyping: boolean = false;
  existInSearch: boolean = false;
  @ViewChild('ngSelectModel') ngSelectModel!: NgSelectComponent;
  isOpen: boolean = false;
  showArrow: boolean = true;
  existModel: boolean = false;
  selectedTool!: TIGER_CHART_TOOL;
  isRemove: boolean = false;
  selectedModel!: ToolbarDrawConfigs;

  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: any,
    service: RocketModalService,
    private _fb: FormBuilder,
    private customPreferencesService: CustomPreferencesService,
    private drawToolsService: DrawToolsService
  ) {
    super(service);
    this.form = this._fb.group({
      model: new FormControl(null),
    });
  }

  ngOnInit() {
    this.selectedTool = this.data.tool;
    this.savedModels = this.data.models;
    this.isRemove = this.data.isRemove;
    this.selectedModel = this.data.model;
  }

  addTagFn(indexModel: number, model: ToolbarModel) {
    if (!this.savedModels) {
      this.savedModels = [];
    }
    if (indexModel > -1) {
      this.savedModels.splice(indexModel, 1, model);
    } else {
      this.savedModels.push(model);
    }
    const drawModels = this.drawToolsService.getDrawModels();
    drawModels[this.selectedTool.codTool] = this.savedModels;
    this.customPreferencesService.drawToolModel = JSON.stringify(drawModels);
  }

  onSearch(e: any) {
    if (e.term === '') {
      this.isOpen = false;
      this.isTyping = false;
      this.showArrow = true;
      const val = { label: e.term };
      this.ngSelectModel.select(val);
      this.form.controls['model'].setValue(val);
    } else {
      this.isTyping = true;
      const val = { label: e.term };
      this.form.controls['model'].setValue(val);
    }
  }

  onChange(e: any) {
    if (e) {
      this.isOpen = false;
      this.isTyping = true;
    }
  }

  onBlur(e: any) {
    if (e) {
      this.isOpen = false;
    }
  }

  onOpen(e: any) {
    if (e) {
      const target = e.target as HTMLElement;
      if (target.classList.contains('ng-arrow-wrapper')) {
        this.isOpen = true;
        this.ngSelectModel.focus();
      }
    }
  }

  customSearchFn = (term: string, item: any) => {
    term = term.toLowerCase();
    const validity = item.label.toLowerCase().indexOf(term) > -1;
    this.showArrow = false;
    this.isOpen = false;
    if (validity) {
      this.isOpen = true;
      this.showArrow = true;
    }
    this.isTyping = true;
    setTimeout(() => {
      const options = document.getElementsByClassName('ng-option')!!;
      for (let i = 0; i < options.length; i++) {
        if (!options[i].id.includes(this.ngSelectModel.dropdownId)) {
          const addItem = options[i] as HTMLElement;
          addItem.style.display = 'none';
        }
      }
    }, 1);
    const val = { label: term };
    this.form.controls['model'].setValue(val);
    return validity;
  };

  addModel(validated: boolean = false) {
    if (!this.isTyping) {
      return;
    }
    const indexModel = this.savedModels.findIndex(
      (model) => model.label === this.form.value['model'].label
    );
    this.existModel = indexModel > -1;
    if (this.existModel && !validated) {
      return;
    }
    const model: ToolbarModel = {
      label: this.form.value['model'].label,
      color: !this.selectedTool.isMultiColors
        ? this.selectedTool.color
        : undefined,
      backgroundColor: this.selectedTool.backgroundColor,
      centerLineColor: this.selectedTool.centerLineColor,
      textColor: this.selectedTool.textColor,
      outlineColor: this.selectedTool.outlineColor,
      textBold: this.selectedTool.textBold,
      textItalic: this.selectedTool.textItalic,
      textSize: this.selectedTool.textSize,
      useTextBackground: this.selectedTool.useTextBackground,
      useTextOutline: this.selectedTool.useTextOutline,
      strokeDashArray: this.selectedTool.strokeDashArray,
      strokeThickness: this.selectedTool.lineThickness,
    };
    this.addTagFn(indexModel, model);
    this.close(model);
  }

  addTag(name: string) {
    return { name: name, tag: true };
  }

  onClose = (removeModel: boolean = false) => this.close({ removeModel });
}
