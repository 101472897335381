import { Injectable } from '@angular/core';
import {
  CANDLE_CHEETAH_FIELDS,
  CHEETAH_CHANNEL,
} from '@shared/cheetah/cheetah.const';
import { MarketDispatcher } from '@shared/cheetah/dispatcher/market.dispatcher';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { RxEvent } from './rx-event';
/**
 * Inicio de criação para o padrão global no sistema
 * vai sem implementado no novo padrão de eventos e channels
 */
@Injectable({
  providedIn: 'root',
})
export class CandleChannel {
  static instance: CandleChannel;

  constructor(
    private _rxEvent: RxEvent,
    private cheetahService: MarketDispatcher
  ) {
    return (CandleChannel.instance = CandleChannel.instance || this);
  }

  onEvents() {
    return this._rxEvent.read(CHEETAH_CHANNEL.CANDLE);
  }

  subscribe(params: SubscribeParam) {
    params.fields = CANDLE_CHEETAH_FIELDS;
    params.channel_cheetah = CHEETAH_CHANNEL.CANDLE;
    this.cheetahService.subscribe(params);
  }

  processUnsubscribeChannel(canal: any) {
    this.cheetahService.unsubscribe(canal);
  }

  unsubscribe(params: SubscribeParam) {
    if (!params) return;
    this.cheetahService.unsubscribe({
      channel_cheetah: CHEETAH_CHANNEL.CANDLE,
      itemsArray: params.itemsArray,
    });
  }
}
