import { FLA_DATE_PICKER_FORMAT } from '../enum/datepickker.enum';
import { IDatePickerConfig } from '../interface/datepicker.interface';

export const DATE_PICKER_CONFIGS: IDatePickerConfig = {
  weekend: [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ],
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  format: FLA_DATE_PICKER_FORMAT.DD_MM_YYY,
  locale: 'pt-BR',
  monthAbrev: {
    0: 'Jan',
    1: 'Fev',
    2: 'Mar',
    3: 'Abr',
    4: 'Mai',
    5: 'Jun',
    6: 'Jul',
    7: 'Ago',
    8: 'Set',
    9: 'Out',
    10: 'Nov',
    11: 'Dez',
  },
  daysMap: {
    1: 'Domingo',
    2: 'Segunda',
    3: 'Terça',
    4: 'Quarta',
    5: 'Quinta',
    6: 'Sexta',
    7: 'Sábado',
  },
};

export const YEARS = {
  MAX: 2100,
  MIN: 1900,
};
