import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rocket-modal-header',
  template: ` <div class="modal-header">
    <ng-content></ng-content>
    <button
      *ngIf="!hideCloseButton"
      type="button"
      class="btn-close"
      aria-label="Close"
      data-bs-dismiss="modal"
      (click)="onDimissClicked.emit(true)"
    ></button>
  </div>`,
  styles: [
    `
      :host(rocket-modal-header) {
        display: contents;
      }
    `,
  ],
})
export class RocketModalHeaderComponent {
  @Input() hideCloseButton: boolean = false;
  @Output() onDimissClicked: EventEmitter<boolean> = new EventEmitter();
  constructor() {}
}
