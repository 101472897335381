<rocket-modal>
  <rocket-modal-header>
    <div>
      <ng-container *ngIf="!isResetConfigNotifiations; else resetTitle">
        <h3 class="modal-title">Configurações</h3>
      </ng-container>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <ng-container *ngIf="!isResetConfigNotifiations; else reset">
      <fla-tab>
        <ng-container *ngFor="let tab of modalTabs">
          <fla-tab-header
            [ref]="tab.ref"
            [title]="tab.title"
            [active]="tab.active"
            (flaChange)="onChangeTab($event)"
          ></fla-tab-header>
        </ng-container>

        <fla-tab-body ref="CONFIRMATIONS" [active]="modalTabs[1].active">
          <div *ngIf="confirmationsForm" class="d-flex flex-column">
            <form
              [formGroup]="confirmationsForm"
              class="card d-block py-3 px-0"
            >
              <div *ngFor="let permission of ordersPermissions">
                <app-check
                  css="w-100 justify-content-between mt-2"
                  [label]="permission.label"
                  [formControlName]="permission.form"
                  [isChecked]="
                    confirmationsForm.controls[permission.form].value
                  "
                  [isRequired]="false"
                  [inline]="false"
                  [isSwitch]="true"
                  [disabled]="false"
                  [isTextLeft]="true"
                  [subLabel]="permission.subLabel"
                ></app-check>
              </div>
            </form>
          </div>
        </fla-tab-body>

        <fla-tab-body ref="NEGOTIATION" [active]="modalTabs[0].active">
          <ng-container *ngIf="negotiationForm">
            <form [formGroup]="negotiationForm" class="card d-block py-3 px-0">
              <div *ngFor="let permission of negotiationsPermissions">
                <app-check
                  css="w-100 justify-content-between mt-2"
                  [formControlName]="permission.form"
                  [label]="permission.label"
                  [isChecked]="negotiationForm.controls[permission.form].value"
                  [isRequired]="false"
                  [inline]="false"
                  [isSwitch]="true"
                  [disabled]="false"
                  [isTextLeft]="true"
                  [subLabel]="permission.subLabel"
                ></app-check>
              </div>
            </form>
          </ng-container>
        </fla-tab-body>

        <fla-tab-body ref="SOUND_SETTINGS" [active]="modalTabs[2].active">
          <section class="mb-3 mt-5">
            <app-check
              css="w-100 justify-content-between mt-2"
              label="Notificação com áudio"
              [isChecked]="audioNotification"
              [isRequired]="false"
              [inline]="false"
              [isSwitch]="true"
              [disabled]="false"
              (flaChange)="disabledEnabledAudio($event)"
              [isTextLeft]="true"
              subLabel="Ative ou desative som ao receber uma notificação"
            ></app-check>
          </section>
          <div
            class="row"
            [ngClass]="{ 'opacity-intense': !audioNotification }"
          >
            <div *ngFor="let label of soundLabels; let i = index">
              <ng-template
                *ngTemplateOutlet="
                  dropdown;
                  context: {
                    label: label,
                    select: +soundSelect[i],
                    position: i
                  }
                "
              ></ng-template>
            </div>
          </div>
          <a
            class="mr-4 fixed-btn fs-5 cursor-pointer"
            (click)="resetDefaultSounds()"
            >Restaurar padrão</a
          >
        </fla-tab-body>
      </fla-tab>
    </ng-container>
  </app-rocket-modal-body>

  <rocket-modal-footer>
    <rocket-button
      label="Cancelar"
      type="fill"
      [label]="labelButtonCancel"
      css="mr-4"
      (rocketClick)="onClose(true)"
    ></rocket-button>
    <rocket-button
      [label]="labelButtonConfirm"
      (rocketClick)="onClose()"
    ></rocket-button>
  </rocket-modal-footer>
</rocket-modal>

<ng-template #resetTitle>
  <h3 class="modal-title">Tem certeza que deseja prosseguir?</h3>
</ng-template>

<ng-template #reset>
  <h4 class="fs-5">
    Caso você prossiga as configurações padrões seram estabelecidas e as suas
    alterações serão perdidas.
  </h4>
</ng-template>

<ng-template
  #dropdown
  let-label="label"
  let-select="select"
  let-position="position"
>
  <div class="col-16 d-flex justify-content-between px-3 py-3">
    <div>{{ label }}</div>
    <div class="d-flex align-items-center">
      <rocket-button
        type="icon"
        [isDisabled]="!audioNotification"
        (rocketClick)="playSound(sounds[select].sound)"
      >
        <fla-icon
          name="play_circle"
          css="text-white mr-2 cursor-pointer"
          size="icon-size-micro"
        >
        </fla-icon>
      </rocket-button>
      <app-dropdown css="d-flex justify-content-center">
        <a
          type="button"
          class="nav-link p-1 lh-sm dropdown-toggle d-inline-block text-truncate border round-sm"
          [ngClass]="{ disabled: !audioNotification }"
          [style.max-width.px]="80"
          appDropdownButton
        >
          {{ sounds[select].label }}
        </a>
        <div class="list-max-height">
          <div *ngFor="let sound of sounds; let i = index">
            <a
              class="dropdown-item"
              type="button"
              (click)="selectSound(i, position)"
            >
              {{ sound.label }}
            </a>
          </div>
        </div>
      </app-dropdown>
    </div>
  </div>
</ng-template>
