import { formatterNumber } from '@shared/rocket-components/utils';
import { ColDef } from 'ag-grid-community';
import { getVolumeText } from 'src/app/utils/utils.functions';

export const RENT_HEADER_DEFAULT_WIDTH = {
  expand_stock: {
    minWidth: 32,
    maxWidth: 32,
    width: 32,
  },
  cd_stock: {
    width: 58,
  },
  balance_qtty: {
    width: 72,
  },
  balance_value: {
    width: 72,
  },
  qtty_contracts: {
    width: 60,
  },
  pc_average_interest_rate_giver: {
    width: 67,
  },
  rent_mcap: {
    width: 68,
  },
  mcap: {
    width: 70,
  },
  balancePcTotal: {
    width: 47,
  },
  days_short: {
    width: 65,
  },
};

export const RENT_DEFAULT_HEADERS: ColDef[] = [
  {
    headerName: '',
    field: 'expand_stock',
    headerTooltip: '',
    minWidth: 32,
    maxWidth: 32,
    width: 32,
    lockPosition: 'left',
    pinned: 'left',
    sortingOrder: ['asc', 'desc', null],
    sortable: false,
    suppressMovable: true,
    resizable: false,
    cellRenderer: () =>
      `<i class="icons icon-size-micro ml-1 cursor-pointer">open_in_new</i>`,
  },
  {
    headerName: 'Ativo',
    field: 'cd_stock',
    headerTooltip: 'Ativo',
    width: 72,
    sortingOrder: ['desc', 'asc', null],
    sortable: true,
    suppressMovable: true,
  },
  {
    headerName: 'Qtd',
    field: 'balance_qtty',
    headerTooltip: 'Quantidade',
    width: 72,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    sortingOrder: ['desc', 'asc', null],
    sortable: true,
    suppressMovable: true,
  },
  {
    headerName: 'Vol',
    field: 'balance_value',
    headerTooltip: 'Volume',
    width: 72,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    sortingOrder: ['desc', 'asc', null],
    sortable: true,
    suppressMovable: true,
  },
  {
    headerName: 'Contr',
    field: 'qtty_contracts',
    headerTooltip: 'Contratos',
    width: 60,
    cellClass: ['text-right'],
    sortingOrder: ['desc', 'asc', null],
    sortable: true,
    suppressMovable: true,
  },
  {
    headerName: 'Taxa',
    field: 'pc_average_interest_rate_giver',
    headerTooltip: 'Taxa',
    width: 67,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => `${formatterNumber(params.value)} %`,
    sortingOrder: ['desc', 'asc', null],
    sortable: true,
    suppressMovable: true,
  },
  {
    headerName: 'A/MCAP',
    field: 'rent_mcap',
    headerTooltip: 'Aluguel/MCAP',
    width: 68,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => `${Math.round(params.value).toFixed(0)} %`,
    sortingOrder: ['desc', 'asc', null],
    sortable: true,
    suppressMovable: true,
  },
  {
    headerName: 'MCAP',
    field: 'mcap',
    headerTooltip: 'MCAP',
    width: 70,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    sortingOrder: ['desc', 'asc', null],
    sortable: true,
    suppressMovable: true,
  },
  {
    headerName: 'A/FF',
    field: 'balancePcTotal',
    headerTooltip: 'Aluguel/Free Float',
    width: 47,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => `${Math.round(params.value).toFixed(0)} %`,
    sortingOrder: ['desc', 'asc', null],
    sortable: true,
    suppressMovable: true,
  },
  {
    headerName: 'D. Short',
    headerTooltip: 'Dias Short',
    field: 'days_short',
    width: 65,
    cellClass: ['text-right'],
    valueFormatter: (params: any) =>
      params.value ? Math.round(params.value).toFixed(0) : '0',
    sortingOrder: ['desc', 'asc', null],
    sortable: true,
    suppressMovable: true,
  },
];
