import { Injectable } from '@angular/core';
import { RxEvent } from '@shared/channel/rx-event';
import { HttpClient } from '@angular/common/http';
import { CheetahChannelBase } from '@shared/channel/base/cheetah-channel.base';

@Injectable({
  providedIn: 'root',
})
export class MarketDispatcher extends CheetahChannelBase {
  constructor(http: HttpClient, rxEvent: RxEvent) {
    super(http, rxEvent);
  }

  instanciate() {
    this.start();
    return this.onChangeState();
  }
}
