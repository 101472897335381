import { ComponentRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { RocketCreateComponentService } from '@shared/rocket-components/services';
import { randomId, deepClone } from '@shared/rocket-components/utils';
import {
  ToolbarDraw,
  ToolbarDrawConfigs,
  ToolbarModel,
} from './toolbar-draw.interface';
import { ToolbarDrawComponent } from './toolbar-draw.component';
import {
  DrawTools,
  StyleTool,
} from '@shared/tiger-chart/draw-tools/draw-tools.interface';
import { TIGER_CHART_TOOL } from '../tiger-chart-tools.interface';
import { CONFIG, TYPE_VALUE } from './toolbar-draw.enum';
import { ModalModelSaveComponent } from './modal-model-save/modal-model-save.component';
import { TOOLS } from '../tiger-chart-tools.const';
import { RocketModalService } from '@shared/rocket-components/components/index';

@Injectable()
export class ToolbarDrawService {
  private _component!: ToolbarDrawComponent;
  private _element!: HTMLElement | undefined;
  private _componentRef!: ComponentRef<ToolbarDrawComponent> | undefined;
  private onClose$ = new Subject<any>();
  private toolbarDrawRefId = randomId('toolbar_draw');
  showDrawConfigModal$ = new Subject<any>();

  constructor(
    private _createComponent: RocketCreateComponentService,
    private _rocketModalService: RocketModalService
  ) {}

  show(options: ToolbarDraw): {
    onClose: Observable<any>;
  } {
    this._removeBeforeInit();

    const create = this._createComponent.create(ToolbarDrawComponent);
    this._componentRef = create.componentRef;
    this._component = this._componentRef.instance;
    this._element = create.rootNodeElement as HTMLElement;
    this._element.id = this.toolbarDrawRefId;

    this._bindValuesComponent(options);
    setTimeout(() => {
      this._component.setInitConfig();
    }, 1);
    this._setComponentView(options.ref);
    this._component.onClose.subscribe(() => {
      //this.onClose$.next(value);
      this.destroyComponent();
    });

    return { onClose: this.onClose$.asObservable() };
  }

  private _bindValuesComponent(value: ToolbarDraw) {
    this._component.parentRef = value.ref;
    this._component.selectedDraw = value.selectedDraw;
    this._component.selectedTool = value.selectedDraw.tool;
    this._component.dragPosition = value.position;
    if (value.selectedDraw.tool.configurationOptions) {
      this._component.configs = value.selectedDraw.tool.configurationOptions;
    }
    this._component._cdr.detectChanges();
  }

  updateSelectedTool(tool: TIGER_CHART_TOOL) {
    this._component.selectedTool = tool;
    this._component.setInfoConfig();
  }

  selectModelOption(option: ToolbarDrawConfigs) {
    this._component.selectOption(option);
  }

  private _setComponentView(id: string) {
    const rootComponent = document.getElementById(id)!;
    rootComponent.append(this._element!!);
  }

  private _removeBeforeInit() {
    const findAll = document.querySelectorAll('toolbar-draw');
    if (findAll) {
      findAll.forEach((elem) => elem.remove());
    }
  }

  destroyComponent() {
    if (this._rocketModalService.isDisplayingSomeModal) return;
    this._element!!.remove();
    this._element = undefined;
    this._createComponent.destroy(this._componentRef!!);
    this._componentRef = undefined;
  }

  hasElement(): boolean {
    return !!this._element;
  }

  getDrawTool(): DrawTools | undefined {
    if (this.hasElement()) {
      return this._component.selectedDraw;
    }
    return;
  }

  drawModelsReset(selectedDraw: DrawTools, selectedTool: TIGER_CHART_TOOL) {
    this.resetStyleDraw(selectedDraw, selectedTool);
  }

  drawModelsSave(
    selectedTool: TIGER_CHART_TOOL,
    models: ToolbarModel[],
    configs: Array<ToolbarDrawConfigs>
  ) {
    const ref = this._rocketModalService.open(ModalModelSaveComponent, {
      centered: true,
      backdrop: true,
      keyboard: true,
      scrollable: false,
      data: {
        tool: selectedTool,
        models: models,
        configs: configs,
        isRemove: false,
      },
      css: 'modal-sized',
    });
    ref.afterDismissed.subscribe((res) => {
      this.showDrawConfigModal$.next(true);
      if (res && res.label) {
        const config = this.getConfigModel(configs);
        if (config && config.options) {
          config.options!!.push({
            title: res.label,
            hint: '',
            typeValue: TYPE_VALUE.MODELS,
            value: 'UPDATE_DRAW',
            canDelete: true,
          });
        }
      }
    });
  }

  private getConfigModel(
    configs: Array<ToolbarDrawConfigs>
  ): ToolbarDrawConfigs | undefined {
    return configs.find((c) => c.typeConfiguration === CONFIG.MODELS);
  }

  resetStyleDraw(draw: DrawTools, selectedTool: TIGER_CHART_TOOL) {
    const selectedDraw = draw;
    const tool = deepClone(
      TOOLS.get(selectedTool.group)!!.find(
        (t) => t.codTool === selectedTool.codTool
      )!!
    ) as TIGER_CHART_TOOL;
    selectedTool.isMultiColors = tool.isMultiColors;
    selectedTool.isMultiBackgroundColors = tool.isMultiBackgroundColors;
    selectedTool.isMultiCenterLineColors = tool.isMultiCenterLineColors;
    selectedTool.isMultiOutlineColors = tool.isMultiOutlineColors;
    selectedTool.isMultiTextColors = tool.isMultiTextColors;
    selectedTool.color = tool.color;
    selectedTool.backgroundColor = tool.backgroundColor;
    selectedTool.textColor = tool.textColor;
    selectedTool.outlineColor = tool.outlineColor;
    selectedTool.textSize = tool.textSize;
    selectedTool.textBold = tool.textBold;
    selectedTool.textItalic = tool.textItalic;
    selectedTool.useTextBackground = tool.useTextBackground;
    selectedTool.useTextOutline = tool.useTextOutline;
    selectedTool.lineThickness = tool.lineThickness;
    selectedTool.strokeDashArray = tool.strokeDashArray;
    const style: StyleTool = {};
    style.strokeDashArray = selectedTool.strokeDashArray;
    style.strokeThickness = selectedTool.lineThickness;
    style.textSize = selectedTool.textSize;
    style.textBold = selectedTool.textBold;
    style.textItalic = selectedTool.textItalic;
    style.useTextBackground = selectedTool.useTextBackground;
    style.useTextOutline = selectedTool.useTextOutline;
    if (selectedTool.color) {
      style.color = {
        selected: true,
        hexColor: selectedTool.color,
        rgbaHexColor: selectedTool.color,
        rgbColor: '',
        rgbaColor: '',
      };
    }
    if (selectedTool.backgroundColor) {
      style.backgroundColor = {
        selected: true,
        hexColor: selectedTool.backgroundColor,
        rgbaHexColor: selectedTool.backgroundColor,
        rgbaColor: '',
        rgbColor: '',
      };
    }
    if (selectedTool.textColor) {
      style.textColor = {
        selected: true,
        hexColor: selectedTool.textColor,
        rgbaHexColor: selectedTool.textColor,
        rgbaColor: '',
        rgbColor: '',
      };
    }
    if (selectedTool.outlineColor) {
      style.outlineColor = {
        selected: true,
        hexColor: selectedTool.outlineColor,
        rgbaHexColor: selectedTool.outlineColor,
        rgbaColor: '',
        rgbColor: '',
      };
    }
    selectedDraw.updateStyleTool(style);
    selectedDraw.children.forEach((child, k) => {
      if (child.isAux) return;
      const styleChild: StyleTool = {};
      styleChild.strokeDashArray = selectedTool.strokeDashArray;
      styleChild.strokeThickness = selectedTool.lineThickness;
      styleChild.textSize = selectedTool.textSize;
      styleChild.textBold = selectedTool.textBold;
      styleChild.textItalic = selectedTool.textItalic;
      styleChild.useTextBackground = selectedTool.useTextBackground;
      styleChild.useTextOutline = selectedTool.useTextOutline;
      const draw = tool.children!![k];
      if (!draw.dontUpdateStyle) {
        if (draw.color) {
          styleChild.color = {
            selected: true,
            hexColor: draw.color,
            rgbaHexColor: draw.color,
            rgbaColor: '',
            rgbColor: '',
          };
        }
        if (draw.backgroundColor) {
          styleChild.backgroundColor = {
            selected: true,
            hexColor: draw.backgroundColor,
            rgbaHexColor: draw.backgroundColor,
            rgbaColor: '',
            rgbColor: '',
          };
        }
        if (draw.textColor) {
          styleChild.textColor = {
            selected: true,
            hexColor: draw.textColor,
            rgbaHexColor: draw.textColor,
            rgbaColor: '',
            rgbColor: '',
          };
        }
        if (draw.outlineColor) {
          styleChild.outlineColor = {
            selected: true,
            hexColor: draw.outlineColor,
            rgbaHexColor: draw.outlineColor,
            rgbaColor: '',
            rgbColor: '',
          };
        }
        styleChild.textSize = draw.textSize;
        styleChild.textBold = draw.textBold;
        styleChild.textItalic = draw.textItalic;
        const childAnnotationId = child.id;
        styleChild.childAnnotationId = childAnnotationId;
        child.updateStyleTool(styleChild);
      }
    });
  }
}
