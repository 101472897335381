import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { IFlowTab } from '../../interfaces/flow-investors.interface';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-flow-b3-tab',
  templateUrl: './flow-b3-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowB3TabComponent {
  @Input() set atualB3Investor(atualB3Investor: any) {
    if (atualB3Investor) {
      this.atualB3 = atualB3Investor;
      this.getAtualB3InvestorStatus();
    }
  }
  @Input() acumuladoB3: any = null;
  @Input() width!: number;
  @Input() height!: number;
  @Input() updatePosition: boolean = false;

  public atualB3!: any;
  public cards: any = [];
  public isExpandedView: boolean = false;

  public flowExpanded!: IFlowTab | null;
  public typesFlows: IFlowTab[] = [
    {
      label: 'Fluxo de Investidores na B3',
      code: 'flow_b3',
      titleChart: 'Evolução na B3',
      field: 'cd_type_investor',
    },
    {
      label: 'Acumulado B3',
      code: 'accumulated_b3',
      titleChart: 'Acumulado B3',
      field: 'cd_type_investor',
    },
  ];

  public flowB3InitialPeriod = { id: 'month', label: 'Mensal' };

  constructor(private cdr: ChangeDetectorRef) {}

  private getAtualB3InvestorStatus() {
    this.cards = this.atualB3.top_week.map((item: any) => {
      item.cd_type_investor = item.cd_b3_investor_type.replace('Pessoa', 'P.');
      return item;
    });
    this.cdr.detectChanges();
  }

  public flowToExpand(tab: IFlowTab | null) {
    this.flowExpanded = tab;
    this.isExpandedView = !isNullOrUndefined(tab);
    this.cdr.detectChanges();
  }
}
