<app-stock-screening-header
  (changeTab)="tabSelect = $event"
></app-stock-screening-header>
<section class="bg-dark w-100 h-100">
  <ng-container [ngSwitch]="tabSelect">
    <ng-container *ngSwitchCase="viewScreening[0]">
      <app-screening-filters
        [filtersString]="filters"
        (sendAndSave)="saveFilters($event)"
      ></app-screening-filters>
    </ng-container>
    <ng-container *ngSwitchCase="viewScreening[1]">
      <app-screening-list-filters
        [filtersString]="filters"
        [headerOptions]="component.metadata.headerOptions"
        [refComponent]="component.id"
        [component]="component.metadata.component"
        (updateComponent)="updateComponent($event)"
        (sendAndSave)="saveFilters($event)"
        (openGraphic)="openGraphic($event)"
      ></app-screening-list-filters>
    </ng-container>
  </ng-container>
</section>
