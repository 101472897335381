import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TradeIdeaComponent } from './trade-idea.component';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  FlaIconModule,
  RocketButtonModule,
} from '@shared/rocket-components/components';
import { RTInputCountModule } from '@shared/rocket-components/input-count/input-count.module';
import { TradeIdeaHeaderComponent } from './trade-idea-header/trade-idea-header.component';
import { SearchStockModule } from '../search-stock/search-stock.module';
import { LoadingModule } from '../loading/loading.module';
import { ModalTradeIdeaModule } from './modal-trade-idea/modal-trade-idea.module';
import { TableTradeIdeaItemsModule } from './table-trade-idea-items/table-trade-idea-items.module';
import { StockChartService } from '../stock-chart/service/stock-chart.service';

@NgModule({
  imports: [
    CommonModule,
    FlaWorkSpaceModule,
    FormsModule,
    ReactiveFormsModule,
    RocketButtonModule,
    RTInputCountModule,
    FlaIconModule,
    SearchStockModule,
    LoadingModule,
    ModalTradeIdeaModule,
    TableTradeIdeaItemsModule,
  ],
  declarations: [TradeIdeaComponent, TradeIdeaHeaderComponent],
  exports: [TradeIdeaComponent, TradeIdeaHeaderComponent],
  providers: [StockChartService],
})
export class TradeIdeaModule {}
