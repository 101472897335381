<rocket-modal>
  <rocket-modal-header (onDimissClicked)="onClose(false)">
    <h3 class="modal-title fs-2">Configurações de negócios</h3>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div class="vstack gap-4">
      <div>
        <h2 class="d-block fs-4 mb-2">Filtro</h2>
        <div class="hstack justify-content-between w-100">
          <span>Filtre os negócios por lotes</span>
          <div class="hstack justify-content-end">
            <app-tag typeTag="outline" text="Maior ou igual a"></app-tag>
            <app-input-count
              labelPlacement="horizontal"
              margin="mb-0"
              css="fs-7"
              paddingSmallCustom="py-1"
              mask="separator.0"
              [isSmall]="true"
              [isQttyInput]="true"
              [allowNegativeValue]="false"
              [incrementValue]="data.stock.standard_lot"
              [initialValue]="qttyFilter"
              [min]="data.stock.standard_lot"
              [selectOnFocus]="true"
              [emitEventOnBackToSourceValue]="true"
              (rtChange)="updateLotQtty($event)"
            ></app-input-count>
          </div>
        </div>

        <ng-container *ngIf="loteError">
          <span
            class="d-block fs-6 fw-bold text-center text-feedback-error w-100"
          >
            Valor do lote inválido
          </span>
        </ng-container>
      </div>

      <div>
        <h2 class="d-block fs-4 mb-2">Cores</h2>
        <div class="vstack justify-content-between w-100 gap-2">
          <div class="hstack w-100 justify-content-between">
            <span>Destaque a cor dos lotes que você deseja visualizar</span>
            <ng-container *ngIf="canCreateMoreColor">
              <button
                type="button"
                class="btn btn-fill-primary btn-sm"
                (click)="addColor()"
              >
                <span class="material-icons-outlined">add</span> Nova condição
              </button>
            </ng-container>
          </div>

          <div class="colors-rules">
            <ng-container
              *ngFor="let rule of colorsHighlights; let index = index"
            >
              <div class="position-relative card bg-neutral-strong">
                <div class="hstack gap-2 justify-content-between w-100">
                  <button
                    type="button"
                    class="btn btn-fill-primary min-w-0 p-0"
                    tooltip="Remover destaque"
                    (click)="removeColor(index)"
                  >
                    <span
                      class="hstack icon-size-micro material-icons-outlined text-feedback-error"
                    >
                      delete
                    </span>
                  </button>
                  <div
                    tooltip="Alterar cor"
                    class="color-preview round"
                    [style.background-color]="rule.color"
                    (click)="displayColorPicker(index)"
                  ></div>
                  <p class="fs-5 card-title">Lote maior ou igual a</p>
                  <app-input-count
                    labelPlacement="horizontal"
                    margin="mb-0"
                    css="fs-7"
                    paddingSmallCustom="py-1"
                    mask="99999999"
                    [isSmall]="true"
                    [allowNegativeValue]="false"
                    [incrementValue]="data.stock.standard_lot"
                    [initialValue]="rule.qtty"
                    [min]="1"
                    [enableScrollEvent]="true"
                    [selectOnFocus]="true"
                    (rtChange)="updateColorLot(index, $event)"
                  ></app-input-count>
                </div>
                <ng-container *ngIf="rule.displayColorPicker">
                  <ng-container
                    *ngTemplateOutlet="
                      colorPicker;
                      context: { color: rule.color, ruleIndex: index }
                    "
                  >
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="errorOnColorsHighlights">
            <span
              class="d-block fs-6 fw-bold text-center text-feedback-error w-100"
            >
              Há destaques com as mesmas quantidades ou cores.
            </span>
          </ng-container>
          <ng-container *ngIf="errorOnColorsQtty">
            <span
              class="d-block fs-6 fw-bold text-center text-feedback-error w-100"
            >
              Valor do lote inválido
            </span>
          </ng-container>
        </div>
      </div>
    </div>
  </app-rocket-modal-body>

  <rocket-modal-footer>
    <rocket-button
      *ngIf="canReset"
      css="mr-3"
      type="fill"
      (rocketClick)="resetConfigs()"
      label="Restaurar padrão"
    >
    </rocket-button>
    <rocket-button
      css="mr-3"
      type="outline"
      (rocketClick)="onClose()"
      label="Cancelar"
    >
    </rocket-button>
    <rocket-button
      type="default"
      [isDisabled]="false"
      (rocketClick)="onSave()"
      label="Salvar"
      [isDisabled]="loteError || errorOnColorsHighlights || errorOnColorsQtty"
    >
    </rocket-button>
  </rocket-modal-footer>
</rocket-modal>

<ng-template #colorPicker let-color="color" let-ruleIndex="ruleIndex">
  <div
    class="left-0 position-absolute color-picker-content"
    appOutsideClick
    [isFocused]="hasSomePicketVisible"
    (outsideClick)="closeAndSaveColor(ruleIndex)"
  >
    <app-rt-color-picker
      type="Hex"
      [width]="170"
      [height]="184"
      [showColorPicker]="true"
      [closeOnClick]="true"
      [hideTypeButton]="true"
      [isEditable]="true"
      [color]="color"
      defaultColor="#13141B"
      (updateColor)="onUpdateColor(ruleIndex, $event)"
    ></app-rt-color-picker>
  </div>
</ng-template>
