import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISearchStock } from '@core/interface';

@Component({
  selector: 'app-maturity-template',
  templateUrl: './maturity-template.component.html',
  styleUrls: [
    './maturity-template.component.scss',
    '../tiger-chart-message.component.scss',
  ],
})
export class MaturityTemplateComponent {
  @Input() stock!: ISearchStock;
  @Input() text!: string | undefined;
  @Output() goStock: EventEmitter<boolean> = new EventEmitter<boolean>();

  goToStock() {
    this.goStock.emit(true);
  }
}
