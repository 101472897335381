export const exponentialMovingAverageTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  A média móvel exponencial (MME ou EMA, do inglês Exponential Moving Average) é
  uma ferramenta de análise técnica usada para suavizar os dados de preços de um
  ativo financeiro e identificar a tendência do mercado.
</div>
<div class="mb-3">
  Ao contrário da média móvel simples (MMS), que atribui o mesmo peso a cada
  ponto de dados na série, a MME dá mais peso aos preços mais recentes. Isso
  significa que a MME responde mais rapidamente às mudanças de preço do que a
  MMS.
</div>
<div class="mb-3">
  A fórmula da MME é calculada a partir da média móvel simples (MMS) e usa um
  fator de suavização que dá mais peso aos preços mais recentes. O fator de
  suavização é determinado pela escolha do período da MME.
</div>
<div class="mb-3">
  As médias móveis exponenciais são amplamente usadas em análise técnica para
  gerar sinais de compra ou venda com base na interação entre a MME e o preço do
  ativo. Quando o preço do ativo cruza acima da MME, isso é visto como um sinal
  de alta, enquanto um cruzamento abaixo da MME é visto como um sinal de baixa.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">
  A fórmula matemática da média móvel exponencial (MME ou EMA) é dada por:
</div>
<div class="mb-3">
  EMA = (Preço atual - EMA anterior) x (2 / (n + 1)) + EMA anterior
</div>
<div class="mb-3">Onde:<br /></div>
<div class="mb-3">
  Preço atual é o preço mais recente do ativo financeiro;<br />
  EMA anterior é o valor da MME no período anterior;
</div>
<div class="mb-3">
  n é o período da MME, ou seja, o número de dias ou períodos usados no cálculo
  da média móvel.
</div>
<div class="mb-3">
  A fórmula acima é uma forma simplificada da fórmula completa da MME, que leva
  em conta o fator de suavização (alpha). A fórmula completa é dada por:
</div>
<div class="mb-3">
  EMA = (Preço atual - EMA anterior) x alpha + EMA anterior
</div>
<div class="mb-3">Onde:<br /></div>
<div class="mb-3">
  alpha = 2 / (n + 1) é o fator de suavização, que determina o peso dado aos
  preços mais recentes.
</div>
<div class="mb-3">
  A escolha do período da MME depende do horizonte de tempo do investidor e da
  volatilidade do ativo. Geralmente, períodos mais curtos são usados para
  negociações de curto prazo, enquanto períodos mais longos são usados para
  negociações de longo prazo.
</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  A Média Móvel Exponencial (EMA) dá maior peso aos preços recentes, enquanto a
  Média Móvel Simples (MA) dá pesos iguais a todos os valores. Como a EMA dá
  mais peso aos dados recentes que aos antigos, ela reage mais às últimas
  variações de preço se comparada à MA. É por isso que alguns traders optam por
  este tipo de Média Móvel.
</div>
<div class="mb-3">
  Com isso, o objetivo principal da EMA é acompanhar a mudança do preço de um
  ativo com mais rapidez.
</div>
<div class="mb-3">
  O único parâmetro que deve ser definido para o cálculo da Média Móvel
  Exponencial é a quantidade de períodos anteriores que serão considerados. Este
  número irá influenciar o comportamento do indicador: quanto mais períodos,
  menos sensível ele será aos novos movimentos.
</div>
<div class="mb-3">
  Embora não exista uma regra fixa para os valores que devemos utilizar na EMA,
  quanto maior o número mais de longo prazo será a análise e, portanto, o número
  de períodos considerados deve variar de acordo com o objetivo da análise:
  curto, médio ou longo prazo.
</div>
<div class="mb-3">
  <img class="w-75" src="/assets/indicators/ema-como-funciona.png" />
</div>
<div class="fw-bolder">Como configurar</div>
<br />
<div class="fw-bolder">Valores</div>
<div class="text-decoration-underline">Período</div>
<div class="mb-3">
  O período a ser usado no cálculo da média móvel. 9 dias é o padrão.
</div>
<div class="text-decoration-underline">Fonte</div>
<div class="mb-3">
  Determina quais dados de cada barra serão usados nos cálculos. Fechamento é o
  padrão.
</div>
<div class="text-decoration-underline">Deslocamento</div>
<div class="mb-3">
  A alteração desse número moverá a média móvel para frente ou para trás em
  relação ao mercado atual. 0 é o padrão.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/ema-entrada.png"
  />
</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">EMA</div>
<div class="mb-3">
  Pode alternar a visibilidade da EMA. Também pode selecionar a cor, a espessura
  e o estilo da linha do EMA.
</div>
<div class="mb-3">
  <img class="image-indicators-input" src="/assets/indicators/ema-estilo.png" />
</div>
`;
