import {
  IStackedColumnCollectionOptions,
  StackedColumnCollection,
  TSciChart,
} from 'scichart';

export class StackedColumnCollectionTiger extends StackedColumnCollection {
  override id = '';
  constructor(
    webAssemblyContext: TSciChart,
    idStacked: string,
    options?: IStackedColumnCollectionOptions
  ) {
    super(webAssemblyContext, options);
    this.id = idStacked;
  }
}
