import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-components-count',
  template: `
    <div
      class="count-content align-items-center bg-brand-primary d-inline-flex justify-content"
    >
      <span
        class="count-content-qty align-items-center d-flex fs-7 h-100 justify-content-center text-light w-100"
      >
        {{ qty }}
      </span>
    </div>
  `,
  styles: [
    `
      .count-content {
        width: 16px;
        height: 16px;
        border-radius: 100%;
      }

      .count-content-qty {
        padding-top: 1px;
        box-sizing: border-box;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule],
})
export class ComponentsCountComponent {
  @Input() qty: number = 0;
}
