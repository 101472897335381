import {
  Component,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import {
  IAllStockListSimple,
  IWorkSpaceComponet,
} from 'src/app/core/interface';
import {
  TProfitability,
  TStockAnalysisConfig,
  TVolatility,
  TVariation,
} from '@shared/components/stock-analysis/types';
import {
  STOCK_ANALYSIS_PROFITABILITY,
  STOCK_ANALYSIS_VOLATILITY,
  STOCK_ANALYSIS_VARIATION,
} from '@shared/components/stock-analysis/enum';

@Component({
  selector: 'app-stock-analysis-header',
  templateUrl: './stock-analysis-header.component.html',
  styleUrls: ['./stock-analysis-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockAnalysisHeaderComponent implements OnChanges, OnDestroy {
  @Input() refComponent!: string;
  @Input() componentId!: string;
  @Input() component!: IWorkSpaceComponet;
  @Input() isOpenListPersonal: boolean = false;
  @Input() stockAnalysisConfigs!: TStockAnalysisConfig;
  @Input() linked!: boolean;
  @Input() initialListId!: number | undefined;
  @Input() isMoversList!: boolean;
  @Output() handlerListSelected: EventEmitter<IAllStockListSimple> =
    new EventEmitter<IAllStockListSimple>();
  @Output() handlerAddStocks: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  @Output() changeNotListPersonal: EventEmitter<IAllStockListSimple> =
    new EventEmitter<IAllStockListSimple>();
  @Output() changeOpenListPersonal: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() handlerConfigChange: EventEmitter<TStockAnalysisConfig> =
    new EventEmitter<TStockAnalysisConfig>();

  profitability = STOCK_ANALYSIS_PROFITABILITY;
  volatility = STOCK_ANALYSIS_VOLATILITY;
  variation = STOCK_ANALYSIS_VARIATION;
  profitabilityKeys = Object.keys(
    STOCK_ANALYSIS_PROFITABILITY
  ) as TProfitability[];
  volatilityKeys = Object.keys(STOCK_ANALYSIS_VOLATILITY) as TVolatility[];
  variationKeys = Object.keys(STOCK_ANALYSIS_VARIATION) as TVariation[];

  private destroy$: Subject<boolean> = new Subject<boolean>();

  get classIconAddStcok(): string {
    return this.stockAnalysisConfigs.isNotListPersonal
      ? 'mx-1 disabled'
      : 'mx-1';
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  changeConfig(value: TProfitability | TVariation | TVolatility, key: string) {
    this.handlerConfigChange.emit({
      ...this.stockAnalysisConfigs,
      [key]: value,
    });
  }

  public handlerListChange(event: any): void {
    this.handlerListSelected.emit(event);
    this.cdr.detectChanges();
  }

  public notListPersonal(event: any): void {
    this.changeNotListPersonal.emit(event);
    this.cdr.detectChanges();
  }
}
