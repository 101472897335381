<div class="d-flex align-items-center mr-auto nav" #tabs>
  <workspace-doc-header css="w-100" [item]="component">
    <div
      *ngIf="!isCompactMode; else compactDropdownTemplate"
      cdkDropList
      cdkDropListOrientation="horizontal"
      class="d-inline-flex align-items-center"
      [style.z-index]="3"
      (cdkDropListDropped)="handleOnChangeTabsOrdenation($event)"
    >
      <ng-container *ngFor="let item of getHeaderTabs; let tabIndex = index">
        <div
          cdkDrag
          style="height: 32px !important; overflow: hidden"
          class="drag-box bg-neutral-stronger"
          (contextmenu)="onRightClick($event, item)"
          *ngIf="!item.isHidden"
        >
          <fla-workspace-doc-header-item
            [idElement]="item.idTab"
            [name]="item.label ?? item.title"
            [qtty]="qtty[item.ref]"
            [margin]="tabIndex === 0 ? 'mr-2' : 'mx-2'"
            [active]="selectedTabIndex === tabIndex"
            [tabIcon]="item?.tabIcon"
            [haveCircle]="item?.haveCircle"
            [colorCircle]="item?.colorCircle"
            (flaClick)="changeTab(item, tabIndex)"
          >
          </fla-workspace-doc-header-item>
        </div>
      </ng-container>
    </div>
    <fla-workspace-doc-header-item
      (flaClick)="changeOrderHistoryChange()"
      icon="view_list"
      [iconColor]="isCardView ? 'text-neutral-medium' : 'text-brand-primary'"
      idElement="TOGGLE_ORDER_TABLE_VIEW"
      [tooltipText]="isCardView ? 'Visão por tabela' : 'Logo empresa'"
      alignItem="ml-auto"
    ></fla-workspace-doc-header-item>
    <fla-workspace-doc-header-item
      idElement="TOGGLE_PLAN_TRADING"
      [enableContent]="true"
    >
      <app-risk-management-icon></app-risk-management-icon>
    </fla-workspace-doc-header-item>
    <fla-workspace-doc-header-item
      (flaClick)="openPerformanceReport()"
      icon="flag"
      idElement="TOGGLE_PERFORMANCE_REPORT"
      tooltipText="Abrir o relatório de performance"
    ></fla-workspace-doc-header-item>
  </workspace-doc-header>
</div>

<ng-template #compactDropdownTemplate>
  <div class="d-inline-flex align-items-center" [style.z-index]="3">
    <app-dropdown menuAlign="dropdown-menu-left">
      <div appDropdownButton *ngIf="selectedHeaderTab">
        <fla-workspace-doc-header-item
          [idElement]="selectedHeaderTab.idTab"
          [name]="selectedHeaderTab.label ?? selectedHeaderTab.title"
          [qtty]="qtty[selectedHeaderTab.ref]"
          [active]="false"
          [margin]="'mx-2'"
          [tabIcon]="selectedHeaderTab?.tabIcon"
          [haveCircle]="selectedHeaderTab?.haveCircle"
          [colorCircle]="selectedHeaderTab?.colorCircle"
          [isDropdown]="true"
        >
        </fla-workspace-doc-header-item>
      </div>
      <div
        cdkDropList
        cdkDropListOrientation="vertical"
        (cdkDropListDropped)="handleOnChangeTabsOrdenation($event)"
      >
        <ng-container *ngFor="let item of getHeaderTabs; let tabIndex = index">
          <li
            cdkDrag
            *ngIf="!item.isHidden"
            (contextmenu)="onRightClick($event, item)"
          >
            <fla-workspace-doc-header-item
              [idElement]="item.idTab"
              [name]="item.label ?? item.title"
              [qtty]="qtty[item.ref]"
              [active]="false"
              [margin]="'mx-2'"
              [tabIcon]="item?.tabIcon"
              [haveCircle]="item?.haveCircle"
              [colorCircle]="item?.colorCircle"
              [hideBorder]="true"
              btnClass="text-light"
              (flaClick)="changeTab(item, tabIndex)"
            >
            </fla-workspace-doc-header-item>
          </li>
        </ng-container>
      </div>
    </app-dropdown>
  </div>
</ng-template>
