import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FakeTooltipScichartComponent } from './fake-tooltip-scichart.component';

@NgModule({
  imports: [CommonModule],
  declarations: [FakeTooltipScichartComponent],
  exports: [FakeTooltipScichartComponent],
})
export class FakeTooltipScichartModule {}
