export enum STOCK_TABLE_VIEW {
  GRID = 'STOCK_TABLE_VIEW_GRID',
  HEATMAP = 'STOCK_TABLE_VIEW_HEATMAP',
  MINICARD = 'STOCK_TABLE_VIEW_MINICARD',
  CARD = 'STOCK_TABLE_VIEW_CARD',
  CHART = 'STOCK_TABLE_VIEW_CHART',
  CANDLE = 'STOCK_TABLE_VIEW_CANDLE',
}

export enum CONFIG_ACTIONS {
  ADD = 'CONFIG_ACTIONS_ADD',
  REMOVE = 'CONFIG_ACTIONS_REMOVE',
  EDIT = 'CONFIG_ACTIONS_EDIT',
  RESET = 'CONFIG_ACTIONS_RESET',
  HELP = 'CONFIG_ACTIONS_HELP',
  MOVERS_INDEX = 'CONFIG_ACTIONS_MOVERS_INDEX',
}
