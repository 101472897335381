import { Injectable } from '@angular/core';
import { TIGER_INDICATORS_ENUM } from '@shared/tiger-chart/enum';
import {
  BaseIndicator,
  TigerChartIndicatoKaufmanAdaptiveMovingAverage,
  TigerChartIndicatorAveragePrice,
  TigerChartIndicatorCdlDoji,
  TigerChartIndicatorCdlEngulfing,
  TigerChartIndicatorCdlhammer,
  TigerChartIndicatorDoubleExponentialMovingAverage,
  TigerChartIndicatorExponentialMovingAverage,
  TigerChartIndicatorInvertedCdlhammer,
  TigerChartIndicatorMedianPrice,
  TigerChartIndicatorMovingAverage,
  TigerChartIndicatorParabolicSAR,
  TigerChartIndicatorRelativeStrengthIndex,
  TigerChartIndicatorTripleExponentialMovingAverage,
  TigerChartIndicatorWeightedMovingAverage,
} from '@shared/tiger-chart/indicators';
import { BollingerBands } from '@shared/tiger-chart/indicators/bollinger-bands';
import { TigerChartIndicatorMovingAverageConvergenceDivergence } from '@shared/tiger-chart/indicators/moving-average-convergence-divergence';
import { TalibService } from '@shared/tiger-chart/services/talib.service';
import { StockChartService } from './stock-chart.service';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { CustomPreferencesService } from '@services/api/nitro-ws/v1/custom-preferences.service';
import { TigerChartIndicatorTypicalPrice } from '@shared/tiger-chart/indicators/typical-price';
import { TigerChartIndicatorAverageTrueRange } from '@shared/tiger-chart/indicators/average-true-range';
import { TigerChartIndicatorPercentagePriceOscillator } from '@shared/tiger-chart/indicators/percentage-price-oscillator';
import { TigerChartIndicatorTriangularMovingAverage } from '@shared/tiger-chart/indicators/triangular-moving-average';
import { TigerChartIndicatorMomentum } from '@shared/tiger-chart/indicators/momentum';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import { TigerChartAverageDirectionalMovementIndex } from '@shared/tiger-chart/indicators/average-directional-x';
import { TigerChartStochasticIndex } from '@shared/tiger-chart/indicators/stochastic-index';
import { TigerChartIndicatorCandleCounter } from '@shared/tiger-chart/indicators/candle-counter';
import { TigerChartIndicatorElephantBar } from '@shared/tiger-chart/indicators/elephant-bar';
import { TigerChartIndicatorWeisWave } from '@shared/tiger-chart/indicators/weis-wave';
import { TigerChartIndicatorMovingAverageColor } from '@shared/tiger-chart/indicators/moving-average-color';
import { ColorsBaseService } from '@shared/tiger-chart/tiger-chart-tools/toolbar-draw/colors-base.service';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';

@Injectable()
export class ToggleIndicatorService {
  constructor(
    private _talibService: TalibService,
    private stockChartService: StockChartService,
    private customPreferencesService: CustomPreferencesService,
    private colorsBaseService: ColorsBaseService,
    private themeService: ThemePreferencesService
  ) {}

  resetIndicators() {
    this.stockChartService.indicatorsLineNumber.clear();
  }

  setActiveIndicators(
    indicator: BaseIndicator,
    refComponent: string,
    config?: any
  ) {
    const actives = this.stockChartService.indicatorsActive.has(refComponent)
      ? this.stockChartService.indicatorsActive.get(refComponent)
      : [];
    /**
     * Validação para saber se ja existe o indicador na lista,
     * caso exista muito provavelmente é uma mudança de ativo.
     */
    const exist =
      actives!!.findIndex((active) => {
        const ind = typeof active === 'string' ? active : active.indicator;
        return ind === `${indicator.type}_${indicator.lineNumber}`;
      }) != -1;
    if (!exist) {
      const push = config
        ? { indicator: `${indicator.type}_${indicator.lineNumber}`, config }
        : `${indicator.type}_${indicator.lineNumber}`;
      actives!!.push(push);
    }
    this.stockChartService.indicatorsActive.set(refComponent, actives!!);
  }

  getActiveIndicators(refComponent: string) {
    return this.stockChartService.indicatorsActive.has(refComponent)
      ? this.stockChartService.indicatorsActive.get(refComponent)
      : [];
  }

  removeActiveIndicators(
    refComponent: string,
    type: TIGER_INDICATORS_ENUM,
    lineNumber: string
  ) {
    if (this.stockChartService.indicatorsActive.has(refComponent)) {
      const actives =
        this.stockChartService.indicatorsActive.get(refComponent)!!;
      const activeIndex = actives.findIndex((active) => {
        const indicator =
          typeof active === 'string' ? active : active.indicator;
        return indicator === `${type}_${lineNumber}`;
      });
      if (activeIndex != -1) {
        actives.splice(activeIndex, 1);
        this.stockChartService.indicatorsActive.set(refComponent, actives);
      }
    }
  }

  removeTempConfigIndicator(
    refComponent: string,
    type: TIGER_INDICATORS_ENUM,
    lineNumber: string
  ) {
    const key = `${refComponent}_${type}`;
    if (this.stockChartService.indicatorsTempConfig.has(key)) {
      const configs = this.stockChartService.indicatorsTempConfig.get(key)!!;
      const configIndex = configs.findIndex(
        (config) => config.indicator === `${type}_${lineNumber}`
      );
      if (configIndex != -1) {
        configs.splice(configIndex, 1);
        let tempConfigSession: any =
          this.customPreferencesService.chartTempConfig;
        if (!isNullOrUndefined(tempConfigSession) && tempConfigSession != '') {
          tempConfigSession = JSON.parse(tempConfigSession);
          tempConfigSession[key] = configs;
          this.customPreferencesService.chartTempConfig =
            JSON.stringify(tempConfigSession);
        }
        this.stockChartService.indicatorsTempConfig.set(key, configs);
      }
    }
  }

  private getIndicator(
    cond: TIGER_INDICATORS_ENUM,
    indicatorData: any,
    tickSize: any,
    refComponent: string,
    lineNumber?: number,
    config?: any
  ): BaseIndicator | undefined {
    const key = `${refComponent}_${cond}`;
    if (!this.stockChartService.indicatorsLineNumber.has(key)) {
      this.stockChartService.indicatorsLineNumber.set(key, {
        qtty: lineNumber ? lineNumber : 0,
      });
    } else {
      this.stockChartService.indicatorsLineNumber.set(key, {
        qtty: lineNumber
          ? lineNumber
          : ++this.stockChartService.indicatorsLineNumber.get(key)!!.qtty,
      });
    }
    if (isNullOrUndefined(config)) {
      config = {};
    }
    const indicatorConfig = this.stockChartService.indicatorsConfig.get(cond);
    let globalIndicatorConfig = {};
    let actualIndicatorConfig = {};
    if (indicatorConfig) {
      globalIndicatorConfig = JSON.parse(indicatorConfig.config);
    }
    if (!isNullOrUndefined(lineNumber)) {
      const configs = this.stockChartService.indicatorsTempConfig.get(key);
      if (configs) {
        const actualConfig = configs.find(
          (c) =>
            c.indicator ===
            `${cond}_${
              this.stockChartService.indicatorsLineNumber.get(key)!!.qtty
            }`
        );
        if (actualConfig) {
          actualIndicatorConfig = JSON.parse(actualConfig.config);
        }
      }
    }
    config = Object.assign(
      globalIndicatorConfig,
      actualIndicatorConfig,
      config
    );
    const lineNumberString = this.stockChartService.indicatorsLineNumber
      .get(key)!!
      .qtty.toString();
    let indicador!: BaseIndicator;
    switch (cond) {
      case TIGER_INDICATORS_ENUM.MOVING_AVERAGE:
        indicador = new TigerChartIndicatorMovingAverage({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          lineNumber: lineNumberString,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.MOVING_AVERAGE_COLOR:
        indicador = new TigerChartIndicatorMovingAverageColor(
          {
            data: indicatorData,
            service: this._talibService,
            tickSize: tickSize,
            lineNumber: lineNumberString,
            ...config,
          },
          this.colorsBaseService
        );
        break;
      case TIGER_INDICATORS_ENUM.EXPONENTIAL_MOVING_AVERAGE:
        indicador = new TigerChartIndicatorExponentialMovingAverage({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          lineNumber: lineNumberString,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.WEIGHTED_MOVING_AVERAGE:
        indicador = new TigerChartIndicatorWeightedMovingAverage({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          lineNumber: lineNumberString,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.BOLLINGER_BANDS:
        indicador = new BollingerBands({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          timePeriod: 20,
          lineNumber: lineNumberString,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.BAR_ELEPHANT:
        indicador = new TigerChartIndicatorElephantBar({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          lineNumber: lineNumberString,
          timePeriod: 1,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.MEDIAN_PRICE:
        indicador = new TigerChartIndicatorMedianPrice({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          lineNumber: lineNumberString,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.RELATIVE_STRENGTH_INDEX:
        indicador = new TigerChartIndicatorRelativeStrengthIndex({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          timePeriod: 14,
          lineNumber: lineNumberString,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.STOCHASTIC:
        indicador = new TigerChartStochasticIndex({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          fastK_Period: 14,
          slowK_Period: 1,
          slowD_Period: 3,
          lineNumber: lineNumberString,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.PARABOLIC_SAR:
        indicador = new TigerChartIndicatorParabolicSAR({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          acceleration: 0.02,
          maximum: 0.2,
          lineNumber: lineNumberString,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.CDL_HAMMER:
        indicador = new TigerChartIndicatorCdlhammer({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          lineNumber: lineNumberString,
          backgroundColor: CHART_COLORS.BRAND_SUPPORT_PRIMARY,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.CDL_INVERTED_HAMMER:
        indicador = new TigerChartIndicatorInvertedCdlhammer({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          lineNumber: lineNumberString,
          backgroundColor: CHART_COLORS.MULTIBROKER_BUY,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.CDL_DOJI:
        indicador = new TigerChartIndicatorCdlDoji({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          lineNumber: lineNumberString,
          backgroundColor: CHART_COLORS.FEEDBACK_WARNING,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.CDL_ENGULFING:
        indicador = new TigerChartIndicatorCdlEngulfing({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          lineNumber: lineNumberString,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.AVERAGE_PRICE:
        indicador = new TigerChartIndicatorAveragePrice({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          lineNumber: lineNumberString,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.DOUBLE_EXPONENTIAL_MOVING_AVERAGE:
        indicador = new TigerChartIndicatorDoubleExponentialMovingAverage({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          lineNumber: lineNumberString,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.MOVING_AVERAGE_CONVERGENCE_DIVERGENCE:
        indicador = new TigerChartIndicatorMovingAverageConvergenceDivergence({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          lineNumber: lineNumberString,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.TRIPLE_EXPONENTIAL_MOVING_AVERAGE:
        indicador = new TigerChartIndicatorTripleExponentialMovingAverage({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          lineNumber: lineNumberString,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.KAUFMAN_ADAPTIVE_MOVING_AVERAGE:
        indicador = new TigerChartIndicatoKaufmanAdaptiveMovingAverage({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          timePeriod: 21,
          lineNumber: lineNumberString,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.TYPICAL_PRICE:
        indicador = new TigerChartIndicatorTypicalPrice({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          lineNumber: lineNumberString,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.AVERAGE_TRUE_RANGE:
        indicador = new TigerChartIndicatorAverageTrueRange({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          timePeriod: 14,
          lineNumber: lineNumberString,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.PERCENTAGE_PRICE_OSCILLATOR:
        indicador = new TigerChartIndicatorPercentagePriceOscillator({
          data: indicatorData,
          service: this._talibService,
          fastPeriod: 10,
          slowPeriod: 21,
          lineNumber: lineNumberString,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.TRIANGULAR_MOVING_AVERAGE:
        indicador = new TigerChartIndicatorTriangularMovingAverage({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          lineNumber: lineNumberString,
          timePeriod: 10,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.MOMENTUM:
        indicador = new TigerChartIndicatorMomentum({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          lineNumber: lineNumberString,
          timePeriod: 10,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.AVERAGE_DIRECTIONAL_X:
        indicador = new TigerChartAverageDirectionalMovementIndex({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          lineNumber: lineNumberString,
          timePeriod: 14,
          smoothing: 14,
          ...config,
        });
        break;
      case TIGER_INDICATORS_ENUM.CANDLE_COUNTER:
        indicador = new TigerChartIndicatorCandleCounter(
          {
            data: indicatorData,
            service: this._talibService,
            lineNumber: lineNumberString,
            ...config
          },
          this.themeService,
          this.stockChartService
        );
        break;
      case TIGER_INDICATORS_ENUM.WEIS_WAVE:
        indicador = new TigerChartIndicatorWeisWave({
          data: indicatorData,
          service: this._talibService,
          tickSize: tickSize,
          lineNumber: lineNumberString,
          timePeriod: 14,
          ...config,
        });
        break;
      default:
        console.error('Tipo nao especificado para indicador');
        break;
    }
    return indicador;
  }

  processIndicator = (
    value: TIGER_INDICATORS_ENUM,
    indicatorData: any,
    tickSize: any,
    refComponent: string,
    lineNumber?: number,
    config?: any
  ) =>
    this.getIndicator(
      value,
      indicatorData,
      tickSize,
      refComponent,
      lineNumber,
      config
    );
}
