import { EventEmitter } from '@angular/core';
import {
  CONTEXT_MENU_ITEM_ENUM,
  CONTEXT_MENU_ORDER_TYPE,
  TIGER_DOUBLE_STOP_ORDER_IDS,
  TIGER_LINE_TYPE,
} from '@shared/tiger-chart/enum';
import {
  getArrow,
  getStopOrderTemplate,
} from '@shared/tiger-chart/templates/tiger-chart.template';
import {
  TCandleLineEvent,
  TCandleLineOptions,
} from '@shared/tiger-chart/types/tiger-chart.types';
import {
  DOUBLE_START_STOP,
  isDoubleStartStop,
} from '@shared/constants/general.contant';
import { ORDER_TYPE_DIC } from '@shared/dictionary/order.dictionary';
import { ToastService } from '@shared/services';
import { ECoordinateMode } from 'scichart/Charting/Visuals/Annotations/AnnotationBase';
import { HorizontalLineAnnotation } from 'scichart/Charting/Visuals/Annotations/HorizontalLineAnnotation';
import { VisibleRangeChangedArgs } from 'scichart/Charting/Visuals/Axis/VisibleRangeChangedArgs';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import {
  EHorizontalAnchorPoint,
  EVerticalAnchorPoint,
} from 'scichart/types/AnchorPoint';
import { ClickableAnnotation } from '../annotations/clickable-annotation';
import { CustodyStopOrdersAlertComponent } from './custody-stop-orders-alert/custody-stop-orders-alert.component';
import { CHART_COLORS } from '../colors';
import { ensurePositiveNumber } from 'src/app/utils/utils.functions';
import { ICandleContextMenu } from '../interface';
import { formatNumber } from '@angular/common';
import {
  IPosition,
  IStockChartDoubleStopOrderValues,
} from '@shared/components/stock-chart/interface/stock-chart.interface';
import { StockChartHelper } from '@shared/components/stock-chart/stock-chart.helper';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { ISearchStock } from '@core/interface';
import { CustodyStopOrderService } from './custody-stop-order.service';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import { NumberRange } from 'scichart/Core/NumberRange';
import { ARROW_TYPES } from '@shared/components/stock-chart/enum/stock-chart.enum';
import { Subject, auditTime } from 'rxjs';
import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { CHART_LINE_FIELD_CONFIG } from '@shared/components/stock-chart/parts/modal-more-options/interface/stock-chart-modal.interface';

export class CustodyStopOrder {
  custodyQuantity: number = 0;
  custodyInfo!: IPosition;
  boxLabel!: string | undefined;
  custodyLineId!: string;
  isHidden = false;
  private updatePositionCheep$ = new Subject<void>();
  private _drawToolWidth = 50;
  private vlContractMultiplier = 1;
  private data!: any;
  private _stock!: ISearchStock;
  stopGainCheep!: ClickableAnnotation | undefined;
  stopLossCheep!: ClickableAnnotation | undefined;
  private _custodyArrow!: ClickableAnnotation | undefined;
  private _isDragging = false;
  private _isClickedOnBall = false;
  private _cheepLabel: any = {
    [`${TIGER_DOUBLE_STOP_ORDER_IDS.GAIN}`]: 'Gain',
    [`${TIGER_DOUBLE_STOP_ORDER_IDS.LOSS}`]: 'Loss',
  };

  set drawToolWidth(drawToolWidth: number) {
    this._drawToolWidth = drawToolWidth;
  }

  get drawToolWidth() {
    return this._drawToolWidth;
  }

  set base(base: TWebAssemblyChart) {
    this._base = base;
  }

  set stock(stock: ISearchStock) {
    this._stock = stock;
  }

  get stock(): ISearchStock {
    return this._stock;
  }

  get contractMultiplier(): number {
    return this.vlContractMultiplier;
  }

  set contractMultiplier(contractMultiplier: number) {
    if (!contractMultiplier) {
      return;
    }
    if (contractMultiplier != this.vlContractMultiplier) {
      this.updateHintContractMultiplier(TIGER_DOUBLE_STOP_ORDER_IDS.GAIN);
      this.updateHintContractMultiplier(TIGER_DOUBLE_STOP_ORDER_IDS.LOSS);
    }
    this.vlContractMultiplier = contractMultiplier;
  }

  get isDragging(): boolean {
    return this._isDragging;
  }

  get isClickedOnBall(): boolean {
    return this._isClickedOnBall;
  }

  get canShowToggleVisibilityButton(): boolean {
    const gainLine = this._base.sciChartSurface.annotations.getById(
      `${TIGER_DOUBLE_STOP_ORDER_IDS.GAIN}_line`
    );
    const lossLine = this._base.sciChartSurface.annotations.getById(
      `${TIGER_DOUBLE_STOP_ORDER_IDS.LOSS}_line`
    );
    if ((gainLine && !gainLine.isHidden) || (lossLine && !lossLine.isHidden)) {
      return false;
    }

    const hasDoubleStopLine = Object.values(this._getLineData()).find(
      (line) => {
        return isDoubleStartStop(line.cd_order_type);
      }
    );
    if (hasDoubleStopLine) return false;

    if (!this.stopGainCheep || !this.stopLossCheep) {
      return false;
    }

    return true;
  }

  get hasHidden(): boolean {
    if (!this.stopGainCheep || !this.stopLossCheep) return true;

    return this.stopGainCheep.isHidden || this.stopLossCheep?.isHidden;
  }

  constructor(
    private _base: TWebAssemblyChart,
    private _getLineData: () => object,
    private _getCurrentValue: () => number,
    private _yAxisLabelFormatter: (value: number) => string,
    private _createAnnotationHoverFunction: (
      options: TCandleLineOptions
    ) => (annotation?: ClickableAnnotation) => void,
    private _updateHoverAnnotationPosition: (
      options: TCandleLineOptions,
      annotation: ClickableAnnotation
    ) => void,
    private _updateLine: (
      annotaion: HorizontalLineAnnotation,
      options: TCandleLineOptions
    ) => void,
    private _createLine: (
      options: TCandleLineOptions
    ) => HorizontalLineAnnotation | undefined,
    private _scrollingCustody: (arrowType: ARROW_TYPES, value: number) => void,
    private _rightClickEvent: EventEmitter<
      | ICandleContextMenu<{
          pointValueY: number;
          gainOrderPrice?: number | null;
          lossOrderPrice?: number | null;
          gainTriggerPrice?: number | null;
          lossTriggerPrice?: number | null;
          quantity?: number;
          isBuying?: boolean;
        }>
      | undefined
    >,
    private _lineMoved: EventEmitter<TCandleLineEvent>,
    private _getCustodyStopOrdersAlert: () => CustodyStopOrdersAlertComponent,
    private _toastService: ToastService,
    private _locale: string,
    private _chartHelper: StockChartHelper,
    private _customPreferencesService: CustomPreferencesService,
    private _custodyStopOrderService: CustodyStopOrderService,
    private _dayTradeService: DaytradeService,
    private _drawToolsService: DrawToolsService,
    private _getOrderAndCustodyConfigs: () => {
      orders: CHART_LINE_FIELD_CONFIG;
      custody: CHART_LINE_FIELD_CONFIG;
    },
    private _startStopOrderOffset: (custodyAnn: ClickableAnnotation) => number,
    private dragAnnotationCursorGrabbing: (isEnabled: boolean) => void
  ) {
    const configs = JSON.parse(this._customPreferencesService.chartContextMenu);
    this.isHidden = configs && configs.hideStopOrderCheeps;
    this.updatePositionCheep$.pipe(auditTime(100)).subscribe(() => {
      const custodyBoxAnnotation =
        this._base.sciChartSurface.annotations.getById(
          `${this.custodyLineId}_box`
        ) as ClickableAnnotation;
      if (this.stopLossCheep) {
        this.stopLossCheep.x1 = custodyBoxAnnotation.getAnnotationBorders().x2;
      }
      if (this.stopGainCheep) {
        this.stopGainCheep.x1 = custodyBoxAnnotation.getAnnotationBorders().x2;
      }
    });
  }

  updateCustodyCheeps(removeBox?: boolean, hidden?: boolean) {
    const configs = JSON.parse(this._customPreferencesService.chartContextMenu);
    if (
      (configs && configs.hideStopOrderCheeps) ||
      this._isDragging ||
      !this._base
    )
      return;
    const custodyAnnotation = this._base.sciChartSurface.annotations.getById(
      this.custodyLineId
    ) as ClickableAnnotation;
    const custodyBoxAnnotation = this._base.sciChartSurface.annotations.getById(
      `${this.custodyLineId}_box`
    ) as ClickableAnnotation;
    if (custodyBoxAnnotation?.yCoordinateMode === ECoordinateMode.Relative) {
      return;
    }
    const doubleStopAnnotations = Object.values(this._getLineData()).filter(
      (data: any) => isDoubleStartStop(data?.cd_order_type)
    );

    if (!custodyAnnotation || doubleStopAnnotations.length > 0 || hidden) {
      if (this.stopGainCheep) {
        this.stopGainCheep.isHidden = true;
        this.stopGainCheep.y1 = 0;
      }
      if (this.stopLossCheep) {
        this.stopLossCheep.isHidden = true;
        this.stopLossCheep.y1 = 0;
      }
      if (custodyAnnotation) {
        if (doubleStopAnnotations.length > 0) {
          doubleStopAnnotations.forEach((annotation: any) => {
            this._updateStopOrderLineX1(
              `${annotation.id_order}_gain`,
              custodyBoxAnnotation
            );
            this._updateStopOrderLineX1(
              `${annotation.id_order}_loss`,
              custodyBoxAnnotation
            );
          });
        }
      }
      return;
    }

    const yAxis = this._base.sciChartSurface.yAxes.getById('DefaultAxisId');
    const yVisibleRange = yAxis.visibleRange;
    const difference = ((yVisibleRange.max - yVisibleRange.min) / 100) * 8;
    const isBuying = this.custodyQuantity < 0;

    if (this.stopGainCheep) {
      const gainY1 = isBuying
        ? custodyAnnotation.y1 + difference * -1
        : custodyAnnotation.y1 + difference;
      this._updateCheeps(
        TIGER_DOUBLE_STOP_ORDER_IDS.GAIN,
        gainY1,
        this.stopGainCheep,
        custodyBoxAnnotation,
        removeBox
      );
    }

    if (this.stopLossCheep) {
      const lossY1 = isBuying
        ? custodyAnnotation.y1 + difference
        : custodyAnnotation.y1 + difference * -1;
      this._updateCheeps(
        TIGER_DOUBLE_STOP_ORDER_IDS.LOSS,
        lossY1,
        this.stopLossCheep,
        custodyBoxAnnotation,
        removeBox
      );
    }
  }

  private _updateStopOrderLineX1(
    lineId: string,
    custodyBoxAnnotation: ClickableAnnotation
  ) {
    if (custodyBoxAnnotation.getAnnotationBorders().x2 == 0) return;

    const { orders, custody } = this._getOrderAndCustodyConfigs();

    if (orders.rightSide !== custody.rightSide) return;

    const box = this._base?.sciChartSurface.annotations.getById(
      `${lineId}_box`
    ) as ClickableAnnotation;
    const line = this._base?.sciChartSurface.annotations.getById(lineId);
    if (line) line.x1 = custodyBoxAnnotation.getAnnotationBorders().x2;
    if (box) box.x1 = custodyBoxAnnotation.getAnnotationBorders().x2;
  }

  private _updateCheeps(
    id: string,
    y1: number,
    annotation: ClickableAnnotation,
    custodyAnnotation: ClickableAnnotation,
    removeBox?: boolean
  ) {
    const lineId = `${id}_line`;
    const box = this._base?.sciChartSurface.annotations.getById(
      `${lineId}_box`
    ) as ClickableAnnotation;
    const line = this._base?.sciChartSurface.annotations.getById(
      lineId
    ) as HorizontalLineAnnotation;
    annotation.horizontalAnchorPoint = custodyAnnotation.horizontalAnchorPoint;
    if (box && !box.isHidden && !removeBox) {
      box.x1 = custodyAnnotation.getAnnotationBorders().x2;
      line.x1 = custodyAnnotation.getAnnotationBorders().x2;
      return;
    }

    if (removeBox) {
      if (box) {
        const handler = () => {
          this._base.sciChartSurface.annotations.remove(box);
          this._base.sciChartSurface.annotations.remove(line);
          this._getCustodyStopOrdersAlert().hide();
          this._base.sciChartSurface.rendered.unsubscribe(handler);
        };
        this._base.sciChartSurface.rendered.subscribe(handler);
      }
    }
    annotation.y1 = y1;
    annotation.x1 = custodyAnnotation.getAnnotationBorders().x2;
    annotation.isHidden = this.isHidden ? true : false;
    annotation.customHover = this._createAnnotationHoverFunction({
      tooltipLabel: [`Posicione ordem Stop ${this._cheepLabel[id]}`],
      value: 0,
      color: '',
      type: TIGER_LINE_TYPE.ALERT,
      boxId: id,
    });
  }

  hideCustodyCheeps() {
    if (this.stopGainCheep) {
      this.stopGainCheep.isHidden = true;
      this.stopGainCheep.y1 = 0;
    }
    if (this.stopLossCheep) {
      this.stopLossCheep.isHidden = true;
      this.stopLossCheep.y1 = 0;
    }

    this._getCustodyStopOrdersAlert().hide();
  }

  clearCustodyCheeps() {
    if (this.stopGainCheep) {
      this._base.sciChartSurface.annotations.remove(this.stopGainCheep);
      this.stopGainCheep = undefined;
    }
    if (this.stopLossCheep) {
      this._base.sciChartSurface.annotations.remove(this.stopLossCheep);
      this.stopLossCheep = undefined;
    }

    this._removeStopOrderFromCustody(
      `${TIGER_DOUBLE_STOP_ORDER_IDS.GAIN}_line`
    );
    this._removeStopOrderFromCustody(
      `${TIGER_DOUBLE_STOP_ORDER_IDS.LOSS}_line`
    );
    this._getCustodyStopOrdersAlert().hide();
  }

  private _removeStopOrderFromCustody(id: string) {
    const box = this._base?.sciChartSurface.annotations.getById(
      `${id}_box`
    ) as ClickableAnnotation;

    if (box) {
      box.customClick();
    }
  }

  checkIsDoubleStartStop(options: TCandleLineOptions, id: string) {
    if (
      !options.data?.cd_order_type ||
      options.data?.cd_order_type != DOUBLE_START_STOP
    )
      return;

    const stop = this._base.sciChartSurface.annotations.getById(`${id}_line`);

    if (stop) {
      const stopValue = parseFloat(
        this._yAxisLabelFormatter(stop.y1).replace('.', '').replace(',', '.')
      );
      if (
        +options.value == stopValue &&
        options.data.qtty == ensurePositiveNumber(this.custodyQuantity)
      ) {
        this._base.sciChartSurface.annotations.remove(stop);
        const stopGainBox = this._base.sciChartSurface.annotations.getById(
          `${id}_line_box`
        );
        this._base.sciChartSurface.annotations.remove(stopGainBox);
      }
    }
  }

  setCustodyOrderButtons(xAxisId: string, options: TCandleLineOptions) {
    const {
      custodyQtty,
      pnlValue,
      vlPriceAvg,
      normalVlPriceAvg,
      pnlDay,
      pnlOpen,
      plValue,
      coverageDiff,
      coveragePrice,
    } = this._custodyStopOrderService.getCustodyInfos(options.data);
    options.data = {
      ...options.data,
      custodyQtty,
      pnlValue,
      vlPriceAvg,
      normalVlPriceAvg,
      pnlDay,
      pnlOpen,
      plValue,
      coverageDiff,
      coveragePrice,
    };
    this.custodyQuantity = parseInt(options.data.custodyQtty);
    this.custodyInfo = options.data;
    this.boxLabel = options.boxLabel;
    this.custodyLineId = options.boxId;
    const yAxis = this._base.sciChartSurface.yAxes.getById('DefaultAxisId');
    const yVisibleRange = yAxis.visibleRange;
    const difference = ((yVisibleRange.max - yVisibleRange.min) / 100) * 8;

    const configs = JSON.parse(this._customPreferencesService.chartContextMenu);
    this.isHidden =
      options.hasDoubleStop || (configs && configs.hideStopOrderCheeps);

    if (this.stopGainCheep || this.stopLossCheep) {
      this.updateCustodyCheeps(false, options.hidden);

      return;
    }

    const doubleStopAnnotations = Object.values(this._getLineData()).filter(
      (data: any) => isDoubleStartStop(data?.cd_order_type)
    );
    if (doubleStopAnnotations.length > 0) {
      const custodyAnnotation = this._base.sciChartSurface.annotations.getById(
        options.boxId
      );
      const custodyBoxAnnotation =
        this._base.sciChartSurface.annotations.getById(
          `${this.custodyLineId}_box`
        ) as ClickableAnnotation;

      if (custodyAnnotation) {
        if (doubleStopAnnotations.length > 0) {
          doubleStopAnnotations.forEach((annotation: any) => {
            this._updateStopOrderLineX1(
              `${annotation.id_order}_gain`,
              custodyBoxAnnotation
            );
            this._updateStopOrderLineX1(
              `${annotation.id_order}_loss`,
              custodyBoxAnnotation
            );
          });
        }
      }
    }

    const isBuying = options.data.custodyQtty < 0;

    const gainValue = isBuying
      ? options.value + difference * -1
      : options.value + difference;
    this.stopGainCheep = this._createCustodyOrderBall(xAxisId, {
      ...options,
      label: 'SGain',
      boxId: TIGER_DOUBLE_STOP_ORDER_IDS.GAIN,
      value: gainValue,
      textX: '4',
      tooltipLabel: [
        `Posicione ordem Stop ${
          this._cheepLabel[TIGER_DOUBLE_STOP_ORDER_IDS.GAIN]
        }`,
      ],
      hidden: this.isHidden,
    });
    this._base.sciChartSurface.annotations.add(this.stopGainCheep);
    const lossValue = isBuying
      ? options.value + difference
      : options.value + difference * -1;
    this.stopLossCheep = this._createCustodyOrderBall(xAxisId, {
      ...options,
      label: 'SLoss',
      boxId: TIGER_DOUBLE_STOP_ORDER_IDS.LOSS,
      value: lossValue,
      textX: '5',
      tooltipLabel: [
        `Posicione ordem Stop ${
          this._cheepLabel[TIGER_DOUBLE_STOP_ORDER_IDS.LOSS]
        }`,
      ],
      hidden: this.isHidden,
    });
    this._base.sciChartSurface.annotations.add(this.stopLossCheep);
    yAxis.visibleRangeChanged.subscribe(
      (data: VisibleRangeChangedArgs | undefined) => {
        const custodyAnnotation =
          this._base.sciChartSurface.annotations.getById(options.boxId);
        if (!data || !custodyAnnotation) return;
        const difference =
          ((data.visibleRange.max - data.visibleRange.min) / 100) * 8;
        if (this.stopGainCheep)
          this.stopGainCheep.y1 = isBuying
            ? custodyAnnotation.y1 + difference * -1
            : custodyAnnotation.y1 + difference;
        if (this.stopLossCheep)
          this.stopLossCheep.y1 = isBuying
            ? custodyAnnotation.y1 + difference
            : custodyAnnotation.y1 + difference * -1;

        if (this._getCustodyStopOrdersAlert().isVisible) {
          const annotaion =
            this._getCustodyStopOrdersAlert().label == 'gain'
              ? this.stopGainCheep
              : this.stopLossCheep;

          if (annotaion) {
            const x2 =
              annotaion.getAnnotationBorders().x2 +
              (this._getOrderAndCustodyConfigs().custody.rightSide
                ? -this.drawToolWidth
                : this.drawToolWidth);
            this._getCustodyStopOrdersAlert().show(
              this._getCustodyStopOrdersAlert().label,
              annotaion.getAnnotationBorders().y1,
              x2,
              this._getOrderAndCustodyConfigs().custody.rightSide
            );
          }
        }
      }
    );
  }

  private _createCustodyOrderBall(
    xAxisId: string,
    options: TCandleLineOptions
  ) {
    const annotation = this._base.sciChartSurface.annotations.getById(
      options.boxId
    );
    if (annotation) {
      this._base.sciChartSurface.annotations.remove(annotation);
    }
    this.data = options.data;
    const ball = new ClickableAnnotation({
      xAxisId,
      svgString: getStopOrderTemplate(
        options.label || '',
        'white',
        options.color
      ),
      horizontalAnchorPoint: options.alignRight
        ? EHorizontalAnchorPoint.Right
        : EHorizontalAnchorPoint.Left,
      verticalAnchorPoint: EVerticalAnchorPoint.Center,
      xCoordinateMode: ECoordinateMode.Pixel,
      x1: options.xOffset,
      y1: options.value,
      id: options.boxId,
      isEditable: true,
      annotationsGripsRadius: 0,
      annotationsGripsFill: 'transparent',
      annotationsGripsStroke: 'transparent',
      selectionBoxStroke: 'transparent',
      selectionBoxDelta: 0,
      selectionBoxThickness: 0,
      isHidden: options.hidden || false,
      customHover: this._createAnnotationHoverFunction(options),
      onClick: () => {
        if (ball.isHidden) return;
        this._drawToolsService.blockEditDraw$.next(true);
        this._isClickedOnBall = true;
      },
      onDrag: () => {
        if (ball.isHidden) return;
        this._drawToolsService.blockEditDraw$.next(true);
        this._isDragging = true;
        this.dragAnnotationCursorGrabbing(true)
        this.onLineDrag({ ...options, hidden: false });
        if (this._getCustodyStopOrdersAlert().isVisible) {
          this._getCustodyStopOrdersAlert().hide();
        }
      },
      onDragEnded: () => {
        if (ball.isHidden) return;
        this._drawToolsService.blockEditDraw$.next(true);
        setTimeout(() => {
          this._isClickedOnBall = false;
        }, 500);
        this._isDragging = false;
        this.dragAnnotationCursorGrabbing(false)
        ball.isHidden = true;
        ball.y1 = 0;
        const ballLine = this._base.sciChartSurface.annotations.getById(
          `${options.boxId}_line`
        ) as HorizontalLineAnnotation;
        this.onLineDragEnd(ballLine, { ...options, hidden: false });
      },
    });
    return ball;
  }

  onLineDrag(options: TCandleLineOptions, line?: HorizontalLineAnnotation) {
    const custodyAnnotation = this._base.sciChartSurface.annotations.getById(
      `${this.custodyLineId}_box`
    ) as ClickableAnnotation;
    const cheep = this._base.sciChartSurface.annotations.getById(
      options.boxId
    ) as ClickableAnnotation;

    if (!custodyAnnotation || (cheep.isHidden && !line)) return;

    cheep.x1 = custodyAnnotation.getAnnotationBorders().x2;
    const value = line ? line.y1 : cheep.y1;
    const qttyCustody = this.getQtty(options.data);
    const hasIdOrder = !!options.data.id_order;
    const isBuying = hasIdOrder
      ? options.data.side == 'B'
      : this.custodyQuantity < 0;
    const orderType = ORDER_TYPE_DIC.get(isBuying ? 'B4' : 'S4');

    let id = options.boxId;
    const isLoss = options.data?.cd_order_type
      ? options.boxId?.endsWith('_loss')
      : options.boxId?.startsWith(TIGER_DOUBLE_STOP_ORDER_IDS.LOSS);
    if (options.data?.cd_order_type) {
      id = isLoss
        ? TIGER_DOUBLE_STOP_ORDER_IDS.LOSS
        : TIGER_DOUBLE_STOP_ORDER_IDS.GAIN;
    }

    const { gainOrderPrice, lossOrderPrice } = this._getOrderValues(
      value,
      value,
      isBuying,
      options.data
    );
    const { isGain, qtty, price, stop, estimate, distance } =
      this._custodyStopOrderService.getInfoCustodyHint(
        options.boxId,
        this._stock,
        qttyCustody,
        this.vlContractMultiplier,
        value,
        isLoss ? lossOrderPrice : gainOrderPrice,
        custodyAnnotation?.y1,
        this._yAxisLabelFormatter
      );
    this.updateHintDragBall(options, distance!!);
    let tooltipLabel = options.tooltipLabel
      ? this._chartHelper.getTooltipForType(TIGER_LINE_TYPE.ORDER, {
          isDoubleStartStop: true,
          isGain,
          qtty,
          price,
          stop,
          estimate,
          distance,
          isBuying,
        })
      : undefined;

    const xOffset =
      !cheep || cheep.isHidden
        ? custodyAnnotation.getAnnotationBorders().x2
        : cheep.getAnnotationBorders().x2;

    const ballLine =
      line ||
      (this._base.sciChartSurface.annotations.getById(
        `${options.boxId}_line`
      ) as HorizontalLineAnnotation);

    if (!ballLine) {
      const validation = this.validateNewOrderValue(
        options.data,
        options.value,
        options.boxId as TIGER_DOUBLE_STOP_ORDER_IDS
      );
      this._createLine({
        value: options.value,
        color: orderType?.color + '98' || '',
        xOffset: xOffset,
        xCoordinateMode: ECoordinateMode.Pixel,
        boxId: `${options.boxId}_line`,
        type: TIGER_LINE_TYPE.ORDER,
        label: stop,
        boxLabel: this.getBoxLabel(id, validation, qttyCustody),
        boxTextColor: orderType?.boxTextColor,
        showLabel: true,
        tooltipLabel: tooltipLabel,
        data: {
          ...options.data,
          quantity: qttyCustody,
        },
      });
      return;
    }

    if (!line) {
      ballLine.y1 = cheep.y1;
    }
    const validation = this.validateNewOrderValue(
      options.data,
      cheep ? cheep.y1 : ballLine.y1,
      options.boxId.replace('_line', '') as TIGER_DOUBLE_STOP_ORDER_IDS
    );

    if (!validation)
      tooltipLabel = [
        id == TIGER_DOUBLE_STOP_ORDER_IDS.GAIN
          ? `O gatilho (Gain) de ganho deve ser ${
              isBuying ? 'menor' : 'maior'
            } ou igual que o preço do ativo.`
          : `O gatilho (Loss) de perda deve ser ${
              isBuying ? 'maior' : 'menor'
            } ou igual que o preço do ativo.`,
      ];

    this._updateLine(ballLine, {
      ...options,
      boxId: line ? line.id : `${options.boxId}_line`,
      value: ballLine.y1,
      label: stop,
      boxLabel: this.getBoxLabel(id, validation, qttyCustody),
      color:
        orderType?.color +
          (!options.data?.cd_order_type && cheep && !cheep.isHidden
            ? '98'
            : '') || '',
      boxTextColor: orderType?.boxTextColor,
      xOffset: xOffset,
      tooltipLabel: tooltipLabel,
      data: {
        ...options.data,
        quantity: qttyCustody,
      },
    });
  }

  onLineDragEnd(line: HorizontalLineAnnotation, options: TCandleLineOptions) {
    const cheep = this._base.sciChartSurface.annotations.getById(
      options.boxId
    ) as ClickableAnnotation;

    if (cheep.isHidden && !line) return;

    if (options.data?.cd_order_type) {
      this._lineDragWithOrder(line, options);
      return;
    }
    const qttyCustody = this.getQtty(options.data);
    const isBuying = qttyCustody < 0;
    const orderType = ORDER_TYPE_DIC.get(isBuying ? 'B4' : 'S4');

    const otherId =
      options.boxId == TIGER_DOUBLE_STOP_ORDER_IDS.GAIN
        ? TIGER_DOUBLE_STOP_ORDER_IDS.LOSS
        : TIGER_DOUBLE_STOP_ORDER_IDS.GAIN;
    const otherCheep = this._base.sciChartSurface.annotations.getById(
      otherId
    ) as ClickableAnnotation;

    const validation = this.validateNewOrderValue(
      options.data,
      line.y1,
      options.boxId.replace('_line', '') as TIGER_DOUBLE_STOP_ORDER_IDS,
      true
    );

    const gainAnnotation =
      options.boxId == TIGER_DOUBLE_STOP_ORDER_IDS.GAIN
        ? line
        : (this._base.sciChartSurface.annotations.getById(
            `${TIGER_DOUBLE_STOP_ORDER_IDS.GAIN}_line`
          ) as HorizontalLineAnnotation);
    const lossAnnotation =
      options.boxId == TIGER_DOUBLE_STOP_ORDER_IDS.LOSS
        ? line
        : (this._base.sciChartSurface.annotations.getById(
            `${TIGER_DOUBLE_STOP_ORDER_IDS.LOSS}_line`
          ) as HorizontalLineAnnotation);

    const otherLine =
      options.boxId == TIGER_DOUBLE_STOP_ORDER_IDS.GAIN
        ? lossAnnotation
        : gainAnnotation;

    const otherAnnotationValidation =
      otherLine && !otherLine.isHidden
        ? this.validateNewOrderValue(
            options.data,
            otherLine.y1,
            otherCheep.id as TIGER_DOUBLE_STOP_ORDER_IDS,
            false
          )
        : false;

    const {
      gainOrderPrice,
      lossOrderPrice,
      gainTriggerPrice,
      lossTriggerPrice,
    } = this._getOrderValues(
      options.boxId == TIGER_DOUBLE_STOP_ORDER_IDS.GAIN
        ? line.y1
        : otherLine?.y1 || 0,
      options.boxId == TIGER_DOUBLE_STOP_ORDER_IDS.LOSS
        ? line.y1
        : otherLine?.y1 || 0,
      isBuying,
      options.data
    );
    const custodyAnnotation = this._base.sciChartSurface.annotations.getById(
      `${this.custodyLineId}_box`
    ) as ClickableAnnotation;
    const oPrice =
      (!isBuying && options.boxId != TIGER_DOUBLE_STOP_ORDER_IDS.GAIN) ||
      (isBuying && options.boxId == TIGER_DOUBLE_STOP_ORDER_IDS.GAIN)
        ? lossOrderPrice
        : gainOrderPrice;
    const { isGain, qtty, price, stop, estimate, distance } =
      this._custodyStopOrderService.getInfoCustodyHint(
        options.boxId,
        this._stock,
        qttyCustody,
        this.vlContractMultiplier,
        line.y1,
        oPrice,
        custodyAnnotation?.y1,
        this._yAxisLabelFormatter
      );
    let tooltipLabel = options.tooltipLabel
      ? this._chartHelper.getTooltipForType(TIGER_LINE_TYPE.ORDER, {
          isDoubleStartStop: true,
          isGain,
          qtty,
          price,
          stop,
          estimate,
          distance,
        })
      : options.tooltipLabel;

    if (!validation)
      tooltipLabel = [
        options.boxId == TIGER_DOUBLE_STOP_ORDER_IDS.GAIN
          ? `O gatilho (Gain) de ganho deve ser ${
              isBuying ? 'menor' : 'maior'
            } ou igual que o preço do ativo.`
          : `O gatilho (Loss) de perda deve ser ${
              isBuying ? 'maior' : 'menor'
            } ou igual que o preço do ativo.`,
      ];

    this._updateLine(line, {
      ...options,
      boxId: `${options.boxId}_line`,
      value: line.y1,
      label: stop,
      boxLabel: this.getBoxLabel(options.boxId, validation, qttyCustody),
      color: !validation
        ? CHART_COLORS.FEEDBACK_ERROR
        : orderType?.color +
            (!options.data?.cd_order_type &&
            (!otherCheep.isHidden || !otherAnnotationValidation)
              ? '98'
              : '') || '',
      boxTextColor: !validation
        ? CHART_COLORS.NEUTRAL_SMOOTHEST
        : orderType?.boxTextColor,
      tooltipLabel: tooltipLabel,
      xOffset: otherAnnotationValidation ?
        this._startStopOrderOffset(custodyAnnotation) : 
        custodyAnnotation.getAnnotationBorders().x2,
      alignRight: this._getOrderAndCustodyConfigs().orders?.rightSide, 
      xCoordinateMode: ECoordinateMode.Pixel,
    });

    if (!options.data?.cd_order_type && (!otherLine || otherLine.isHidden)) {
      if (!validation) return;

      otherCheep.customHover = this._createAnnotationHoverFunction({
        tooltipLabel: [
          `Agora posicione a ordem Stop ${this._cheepLabel[otherId]} para enviar a ordem.`,
        ],
        value: 0,
        color: '',
        type: TIGER_LINE_TYPE.ALERT,
        boxId: '',
      });
      const x2 =
        otherCheep.getAnnotationBorders().x2 +
        (this._getOrderAndCustodyConfigs().custody.rightSide
          ? -this.drawToolWidth
          : this.drawToolWidth);
      this._getCustodyStopOrdersAlert().show(
        options.boxId == TIGER_DOUBLE_STOP_ORDER_IDS.GAIN ? 'loss' : 'gain',
        otherCheep.getAnnotationBorders().y1,
        x2,
        this._getOrderAndCustodyConfigs().custody.rightSide
      );

      return;
    }

    if (
      !gainAnnotation ||
      gainAnnotation.isHidden ||
      !lossAnnotation ||
      lossAnnotation.isHidden ||
      !validation
    )
      return;

    if (!otherAnnotationValidation) return;

    this._updateLine(otherLine, {
      boxId: otherLine.id,
      label: this._yAxisLabelFormatter(otherCheep.y1),
      value: otherLine.y1,
      boxLabel: this.getBoxLabel(
        otherCheep.id,
        otherAnnotationValidation,
        qttyCustody
      ),
      color: !validation ? CHART_COLORS.FEEDBACK_ERROR : orderType?.color || '',
      boxTextColor: !validation
        ? CHART_COLORS.NEUTRAL_SMOOTHEST
        : orderType?.boxTextColor,
      type: options.type,
      xOffset: this._startStopOrderOffset(custodyAnnotation),
      alignRight: this._getOrderAndCustodyConfigs().orders?.rightSide,
      xCoordinateMode: ECoordinateMode.Pixel,
    });
    this._rightClickEvent.emit({
      itemMenu: CONTEXT_MENU_ITEM_ENUM.TYPE_STOP_ORDER,
      typeOrder: isBuying
        ? CONTEXT_MENU_ORDER_TYPE.BUY
        : CONTEXT_MENU_ORDER_TYPE.SELL,
      data: {
        pointValueY: parseFloat(stop.replace('.', '').replace(',', '.')),
        gainOrderPrice,
        lossOrderPrice,
        gainTriggerPrice,
        lossTriggerPrice,
        quantity: qttyCustody < 0 ? qttyCustody * -1 : qttyCustody,
        isBuying,
      },
    });
  }

  private _formatAndParseNumber(value: number) {
    return parseFloat(
      this._yAxisLabelFormatter(value).replace('.', '').replace(',', '.')
    );
  }

  private _lineDragWithOrder(
    line: HorizontalLineAnnotation,
    options: TCandleLineOptions
  ) {
    const isLoss = options.boxId?.endsWith('_loss');
    const priceStop = isLoss
      ? options.data.personalized_loss_trigger
      : options.data.personalized_gain_trigger;
    if (parseFloat(priceStop) == line.y1) {
      //caso o preço do click for igual ao valor já existente na linha
      //nao é necessario fazer nada.
      return;
    }
    const qttyCustody = this.getQtty(options.data);
    const isBuying = qttyCustody < 0;
    const orderType = ORDER_TYPE_DIC.get(isBuying ? 'B4' : 'S4');

    const validation = this.validateNewOrderValue(
      options.data,
      line.y1,
      isLoss
        ? TIGER_DOUBLE_STOP_ORDER_IDS.LOSS
        : TIGER_DOUBLE_STOP_ORDER_IDS.GAIN,
      true
    );

    const otherLineId = isLoss
      ? options.boxId.replace('_loss', '_gain')
      : options.boxId.replace('_gain', '_loss');
    const otherLine =
      this._base.sciChartSurface.annotations.getById(otherLineId);

    const {
      gainOrderPrice,
      lossOrderPrice,
      gainTriggerPrice,
      lossTriggerPrice,
    } = this._getOrderValues(
      !isLoss ? line.y1 : otherLine?.y1 || 0,
      isLoss ? line.y1 : otherLine?.y1 || 0,
      isBuying,
      options.data
    );
    const custodyAnnotation = this._base.sciChartSurface.annotations.getById(
      `${this.custodyLineId}_box`
    ) as ClickableAnnotation;
    const oPrice =
      (!isBuying && isLoss) || (isBuying && !isLoss)
        ? lossOrderPrice
        : gainOrderPrice;
    const { qtty, price, stop, estimate, distance } =
      this._custodyStopOrderService.getInfoCustodyHint(
        options.boxId,
        this._stock,
        qttyCustody,
        this.vlContractMultiplier,
        line.y1,
        oPrice,
        custodyAnnotation?.y1,
        this._yAxisLabelFormatter
      );
    this._yAxisLabelFormatter(line.y1);
    const tooltipLabel =
      validation && options.tooltipLabel
        ? this._chartHelper.getTooltipForType(TIGER_LINE_TYPE.ORDER, {
            isDoubleStartStop: true,
            isGain: !isLoss,
            qtty,
            price,
            stop,
            estimate,
            distance,
          })
        : options.tooltipLabel;

    this._updateLine(line, {
      ...options,
      boxId: `${options.boxId}_line`,
      value: line.y1,
      label: stop,
      boxLabel: this.getBoxLabel(options.boxId, validation, qttyCustody),
      color: !validation
        ? CHART_COLORS.FEEDBACK_ERROR
        : orderType?.color + '98' || '',
      boxTextColor: !validation
        ? CHART_COLORS.NEUTRAL_SMOOTHEST
        : orderType?.boxTextColor,
      xOffset: custodyAnnotation?.getAnnotationBorders()?.x2 || options.xOffset,
      tooltipLabel: tooltipLabel,
    });
    const otherAnnotationValidation =
      otherLine && !otherLine.isHidden
        ? this.validateNewOrderValue(
            options.data,
            otherLine.y1,
            isLoss
              ? TIGER_DOUBLE_STOP_ORDER_IDS.GAIN
              : TIGER_DOUBLE_STOP_ORDER_IDS.LOSS,
            false
          )
        : false;

    if (!validation || !otherAnnotationValidation) return;

    this._lineMoved.emit({
      type: options.type,
      id: options.boxId,
      value: parseFloat(stop.replace('.', '').replace(',', '.')),
      data: {
        ...options.data,
        gainOrderPrice,
        lossOrderPrice,
        gainTriggerPrice,
        lossTriggerPrice,
        quantity:
          options.data.custodyQtty < 0
            ? options.data.custodyQtty * -1
            : options.data.custodyQtty,
      },
    });
  }

  validateNewOrderValue(
    data: any,
    value: number,
    lineType: TIGER_DOUBLE_STOP_ORDER_IDS,
    showWarning?: boolean
  ): boolean {
    const currentValue = this._getCurrentValue();
    const hasIdOrder = !!data.id_order;
    const isBuyStop = hasIdOrder ? data.side == 'B' : this.custodyQuantity < 0;
    if (isBuyStop) {
      if (
        lineType == TIGER_DOUBLE_STOP_ORDER_IDS.GAIN &&
        +value >= +currentValue
      ) {
        if (showWarning) {
          this._toastService.showToast(
            'warning',
            'O gatilho de ganho deve ser menor ou igual que o preço do ativo.'
          );
        }
        return false;
      }

      if (
        lineType == TIGER_DOUBLE_STOP_ORDER_IDS.LOSS &&
        +value <= +currentValue
      ) {
        if (showWarning) {
          this._toastService.showToast(
            'warning',
            'O gatilho de perda deve ser maior ou igual que o preço do ativo.'
          );
        }
        return false;
      }
    } else {
      if (
        lineType == TIGER_DOUBLE_STOP_ORDER_IDS.GAIN &&
        +value <= +currentValue
      ) {
        if (showWarning) {
          this._toastService.showToast(
            'warning',
            'O gatilho de ganho deve ser maior ou igual que o preço do ativo.'
          );
        }
        return false;
      }

      if (
        lineType == TIGER_DOUBLE_STOP_ORDER_IDS.LOSS &&
        +value >= +currentValue
      ) {
        if (showWarning) {
          this._toastService.showToast(
            'warning',
            'O gatilho de perda deve ser menor ou igual que o preço do ativo.'
          );
        }
        return false;
      }
    }
    return true;
  }

  private _getOrderValues(
    gainValue: number,
    lossValue: number,
    isBuying: boolean,
    order?: any
  ): IStockChartDoubleStopOrderValues {
    const parsedGainValue = this._formatAndParseNumber(gainValue);
    const parsedLossValue = this._formatAndParseNumber(lossValue);

    const gainTriggerPrice = parsedGainValue;
    const lossTriggerPrice = parsedLossValue;

    const { gainOrderPrice, lossOrderPrice } = this._getPrices(
      gainTriggerPrice,
      lossTriggerPrice,
      isBuying,
      order
    );

    return {
      gainTriggerPrice,
      lossTriggerPrice,
      gainOrderPrice: parseFloat(
        this._yAxisLabelFormatter(gainOrderPrice)
          .replace('.', '')
          .replace(',', '.')
      ),
      lossOrderPrice: parseFloat(
        this._yAxisLabelFormatter(lossOrderPrice)
          .replace('.', '')
          .replace(',', '.')
      ),
    };
  }

  private _getPrices(
    gainTriggerPrice: number,
    lossTriggerPrice: number,
    isBuying: boolean,
    order?: any
  ) {
    if (order?.cd_order_type) {
      if (isBuying) {
        return {
          gainOrderPrice:
            gainTriggerPrice +
            (order.personalized_gain_price - order.personalized_gain_trigger),
          lossOrderPrice:
            lossTriggerPrice +
            (order.personalized_loss_price - order.personalized_loss_trigger),
        };
      }

      return {
        gainOrderPrice:
          gainTriggerPrice -
          (order.personalized_gain_trigger - order.personalized_gain_price),
        lossOrderPrice:
          lossTriggerPrice -
          (order.personalized_loss_trigger - order.personalized_loss_price),
      };
    }
    if (isBuying)
      return {
        gainOrderPrice: gainTriggerPrice + gainTriggerPrice * 0.01,
        lossOrderPrice: lossTriggerPrice + lossTriggerPrice * 0.01,
      };

    return {
      gainOrderPrice: gainTriggerPrice - gainTriggerPrice * 0.01,
      lossOrderPrice: lossTriggerPrice - lossTriggerPrice * 0.01,
    };
  }

  getBoxLabel(id: string, validation: boolean, qttyCustody: number) {
    if (!validation) return 'Preço inválido';
    const letter =
      id == TIGER_DOUBLE_STOP_ORDER_IDS.GAIN ||
      id == `${TIGER_DOUBLE_STOP_ORDER_IDS.GAIN}_line` ||
      id.endsWith('_gain')
        ? 'SG'
        : 'SL';
    const quantity = ensurePositiveNumber(qttyCustody);
    return `${letter} | ${formatNumber(quantity, this._locale, `1.0-0`)}`;
  }

  toggleVisibility() {
    this.isHidden = !this.isHidden;
    if (this.stopGainCheep) this.stopGainCheep.isHidden = this.isHidden;
    if (this.stopLossCheep) this.stopLossCheep.isHidden = this.isHidden;
  }

  private updateHintDragBall(options: TCandleLineOptions, distance: string) {
    const annotation =
      options.boxId === TIGER_DOUBLE_STOP_ORDER_IDS.LOSS ||
      options.boxId.endsWith('_loss')
        ? this.stopLossCheep!!
        : this.stopGainCheep!!;
    annotation.customHover = this._createAnnotationHoverFunction({
      tooltipLabel: [`Dist. custódia: ${distance}`],
      value: 0,
      color: '',
      type: TIGER_LINE_TYPE.ALERT,
      boxId: options.boxId,
      xHoverOffset: 30,
    });
    setTimeout(() => {
      this._updateHoverAnnotationPosition(
        {
          tooltipLabel: [`Dist. custódia: ${distance}`],
          value: 0,
          color: '',
          type: TIGER_LINE_TYPE.ALERT,
          boxId: options.boxId,
          xHoverOffset: 30,
        },
        annotation
      );
    }, 200);
  }

  private updateHintContractMultiplier(boxId: string) {
    const cheep = this._base.sciChartSurface.annotations.getById(
      boxId
    ) as ClickableAnnotation;
    const custodyAnnotation = this._base.sciChartSurface.annotations.getById(
      `${this.custodyLineId}_box`
    ) as ClickableAnnotation;
    if (!cheep || cheep.isHidden || !custodyAnnotation) return;
    const isBuying = this.custodyQuantity < 0;
    const value = cheep.y1;
    const isLoss = boxId.endsWith('_loss');
    const { gainOrderPrice, lossOrderPrice } = this._getOrderValues(
      value,
      value,
      isBuying,
      this.data
    );
    const { isGain, qtty, price, stop, estimate, distance } =
      this._custodyStopOrderService.getInfoCustodyHint(
        boxId,
        this._stock,
        this.custodyQuantity,
        this.vlContractMultiplier,
        value,
        isLoss ? lossOrderPrice : gainOrderPrice,
        custodyAnnotation?.y1,
        this._yAxisLabelFormatter
      );
    const tooltip = this._chartHelper.getTooltipForType(TIGER_LINE_TYPE.ORDER, {
      isDoubleStartStop: true,
      isGain,
      qtty,
      price,
      stop,
      estimate,
      distance,
    });
    cheep.customHover = this._createAnnotationHoverFunction({
      boxId,
      color: '',
      type: TIGER_LINE_TYPE.ALERT,
      value: 0,
      tooltipLabel: tooltip,
    });
  }

  private createArrow(options: {
    xOffset: number;
    value: number;
    isHidden: boolean;
    typeArrow: ARROW_TYPES;
  }) {
    const id = 'custody_arrow';
    const annotation = this._base.sciChartSurface.annotations.getById(id);
    if (annotation) {
      this._base.sciChartSurface.annotations.remove(annotation);
    }
    this._custodyArrow = new ClickableAnnotation({
      svgString: getArrow(options.typeArrow, 'arrow-custody-color'),
      horizontalAnchorPoint: EHorizontalAnchorPoint.Left,
      verticalAnchorPoint: EVerticalAnchorPoint.Center,
      xCoordinateMode: ECoordinateMode.Pixel,
      yCoordinateMode: ECoordinateMode.Relative,
      x1: options.xOffset,
      y1: options.value,
      id,
      isEditable: true,
      isHidden: options.isHidden,
      annotationsGripsRadius: 0,
      annotationsGripsFill: 'transparent',
      annotationsGripsStroke: 'transparent',
      selectionBoxStroke: 'transparent',
      selectionBoxDelta: 0,
      selectionBoxThickness: 0,
      onClick: () => {
        const custodyAnnotation =
          this._base.sciChartSurface.annotations.getById(
            this.custodyLineId
          ) as ClickableAnnotation;
        const value = custodyAnnotation.y1;
        this._custodyArrow!!.isSelected = false;
        const yAxis = this._base.sciChartSurface.yAxes.getById('DefaultAxisId');
        const yVisibleRange = yAxis.visibleRange;
        const typeArrow =
          value < yVisibleRange.min
            ? ARROW_TYPES.NEGATIVE
            : ARROW_TYPES.POSITIVE;
        this._scrollingCustody(typeArrow, value);
      },
    });
    this._base.sciChartSurface.annotations.add(this._custodyArrow);
  }

  updateSeeCustody(
    visibleRange: NumberRange,
    baseChart: TWebAssemblyChart,
    dispatchUpdate: boolean = true,
    force: boolean = false
  ) {
    const custodyAnnotation = baseChart.sciChartSurface.annotations.getById(
      this.custodyLineId
    ) as HorizontalLineAnnotation;
    const custodyBoxAnnotation = baseChart.sciChartSurface.annotations.getById(
      `${this.custodyLineId}_box`
    ) as ClickableAnnotation;
    let update = false;
    if (custodyAnnotation && custodyBoxAnnotation) {
      const value = custodyAnnotation.y1;
      if (value > visibleRange.min && value < visibleRange.max) {
        if (custodyBoxAnnotation.yCoordinateMode != ECoordinateMode.DataValue) {
          custodyBoxAnnotation.yCoordinateMode = ECoordinateMode.DataValue;
          custodyBoxAnnotation.y1 = value;
          update = true;
        }
      } else if (
        custodyBoxAnnotation.yCoordinateMode == ECoordinateMode.DataValue
      ) {
        custodyBoxAnnotation.yCoordinateMode = ECoordinateMode.Relative;
        this.repositionCustody(custodyBoxAnnotation, visibleRange, value);
        update = true;
      } else if (
        custodyBoxAnnotation.yCoordinateMode == ECoordinateMode.Relative
      ) {
        this.repositionCustody(custodyBoxAnnotation, visibleRange, value);
        update = true;
      }
      if ((dispatchUpdate && update) || force) {
        this._updateLine(custodyAnnotation, {
          boxId: this.custodyLineId,
          boxLabel: this.boxLabel,
          type: TIGER_LINE_TYPE.CUSTODY,
          value,
          color: custodyAnnotation.stroke,
        });
        this.updatePositionCheep$.next();
      }
    }
  }

  private repositionCustody(
    custodyBoxAnnotation: ClickableAnnotation,
    visibleRange: NumberRange,
    value: number
  ) {
    if (value < visibleRange.min) {
      custodyBoxAnnotation.y1 = 0.9;
    }
    if (value > visibleRange.max) {
      custodyBoxAnnotation.y1 = 0.1;
    }
  }

  private getQtty(data?: any) {
    const side = data && data.side ? data.side : '';
    const multiplier = side === 'B' ? -1 : 1;
    return data && data.qtty
      ? parseInt(data.qtty) * multiplier
      : this.custodyQuantity;
  }
}
