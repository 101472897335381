import { Injectable } from '@angular/core';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';

import { HttpClient } from '@angular/common/http';
import { GLOBAL_STOCK_LIST_SELECTED } from '../../subscription/const/events-name';
import { GlobalStockListPreferencesInterface } from './global-stock-list-preferences.interface';

@Injectable({
  providedIn: 'root',
})
export class GlobalStockListPreferences
  extends CustomPreferencesService
  implements GlobalStockListPreferencesInterface
{
  constructor(http: HttpClient) {
    super(http);
  }

  get globalStockListSelected() {
    const item = this.storage.getItem(GLOBAL_STOCK_LIST_SELECTED);
    return JSON.parse(item);
  }

  set globalStockListSelected(e: any) {
    this.setValueCustomPreferences(
      GLOBAL_STOCK_LIST_SELECTED,
      JSON.stringify(e)
    );
  }
}
