import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectService {
  private handlerEvent$ = new Subject<ISelectEvent>();
  hasSelectOpen: boolean = false;

  get onEvent() {
    return this.handlerEvent$;
  }

  event(info: ISelectEvent) {
    this.hasSelectOpen = info.type == 'OPEN';
    this.handlerEvent$.next(info);
  }
}

export interface ISelectEvent {
  type: 'OPEN' | 'CLOSE';
}
