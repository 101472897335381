import { Injectable } from '@angular/core';
import { VolumeData } from '@shared/channel/interface/volume.channel.interface';
import { map, Subject, tap } from 'rxjs';
import {
  StackedColumnRenderableSeries,
  TWebAssemblyChart,
  XyDataSeries,
} from 'scichart';
import { StackedColumnCollectionTiger } from './stacked-column-collection-tiger';
import { CANDLE_IDS } from '../constants/tiger-chart.constants';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { Dictionary } from '@core/models';
import { ISearchStock } from '@core/interface';

@Injectable({
  providedIn: 'root',
})
export class VolumeAtPriceService {
  volumeAtPrice$ = new Subject<{
    baseChart: TWebAssemblyChart;
    xAxisId: string;
    yAxisId: string;
  }>();
  instance$ = new Subject<{
    stock: ISearchStock;
    baseChart: TWebAssemblyChart;
    xAxisId: string;
    yAxisId: string;
    ref: string;
    volumeAtPriceDict: Dictionary<VolumeData>;
    maxVolumeAtPrice: number;
    type: 'UPDATE' | 'CREATE';
    volumeData?: VolumeData;
  }>();

  private fixSumToFixed(
    value: number,
    stock: ISearchStock,
    type: 'increment' | 'subtract' = 'increment'
  ): number {
    let vlMinPriceIncrement = stock.vlMinPriceIncrement!;
    if (type === 'subtract') {
      vlMinPriceIncrement = -stock.vlMinPriceIncrement!;
    }
    return parseFloat(
      (value + vlMinPriceIncrement).toFixed(stock.tick_size_denominator)
    );
  }

  constructor(private themeService: ThemePreferencesService) {
    this.instance$
      .pipe(
        map((data) => {
          const { xValues, yValues, hashPrices } =
            this.mapAxisValuesVolumeAtPriceChart(
              data.volumeAtPriceDict,
              data.maxVolumeAtPrice
            );
          return { xValues, yValues, hashPrices, ...data };
        }),
        tap(({ xValues, yValues, hashPrices, stock, type, volumeData }) => {
          if (type === 'CREATE' || (type === 'UPDATE' && !volumeData)) {
            this.createRangeVolumeAtPriceChart(
              hashPrices,
              xValues,
              yValues,
              stock
            );
          }
        })
      )
      .subscribe(
        ({
          baseChart,
          xAxisId,
          yAxisId,
          xValues,
          yValues,
          type,
          volumeData,
        }) => {
          if (type === 'CREATE') {
            this.createVolumeAtPriceChart(
              baseChart,
              xAxisId,
              yAxisId,
              xValues,
              yValues
            );
          }
          if (type === 'UPDATE') {
            this.updateVolumeAtPriceChart(
              baseChart,
              xValues,
              yValues,
              volumeData
            );
          }
        }
      );
  }

  private mapAxisValuesVolumeAtPriceChart(
    volumeAtPriceDict: Dictionary<VolumeData>,
    maxVolumeAtPrice: number
  ): { xValues: number[]; yValues: any; hashPrices: any } {
    const xValues: number[] = [];
    const yValues: any = {
      qtty_buyer: [],
      qtty_seller: [],
      qtty_rlp: [],
      qtty_direct: [],
    };
    const hashPrices: any = {};
    volumeAtPriceDict.values().forEach((volumeData) => {
      xValues.push(volumeData.key);
      const { qtty_buyer, qtty_seller, qtty_rlp, qtty_direct } =
        this.getNewSizeQtty(volumeData, maxVolumeAtPrice);
      yValues['qtty_buyer'].push(qtty_buyer);
      yValues['qtty_seller'].push(qtty_seller);
      yValues['qtty_rlp'].push(qtty_rlp);
      yValues['qtty_direct'].push(qtty_direct);
      hashPrices[volumeData.key] = true;
    });
    return { xValues, yValues, hashPrices };
  }

  private createRangeVolumeAtPriceChart(
    hashPrices: any,
    xValues: number[],
    yValues: any,
    stock: ISearchStock
  ) {
    const max = this.fixSumToFixed(Math.max(...xValues), stock, 'subtract');
    let min = this.fixSumToFixed(Math.min(...xValues), stock);
    while (min <= max) {
      if (hashPrices[min]) {
        min = this.fixSumToFixed(min, stock);
        return;
      }
      xValues.push(min);
      yValues['qtty_buyer'].push(0);
      yValues['qtty_seller'].push(0);
      yValues['qtty_rlp'].push(0);
      yValues['qtty_direct'].push(0);
      min = this.fixSumToFixed(min, stock);
    }
  }

  private updateVolumeAtPriceChart(
    baseChart: TWebAssemblyChart,
    xValues: number[],
    yValues: any,
    volumeData?: VolumeData
  ) {
    const columns = baseChart.sciChartSurface.renderableSeries.getById(
      CANDLE_IDS.VOLUME_X_PRICE_SERIES
    ) as StackedColumnCollectionTiger;
    if (columns) {
      const updates = baseChart.sciChartSurface.suspendUpdates();
      columns.getVisibleSeries().forEach((serie) => {
        const dataSeries = serie.dataSeries as XyDataSeries;
        if (!volumeData) {
          dataSeries.clear();
          const val = yValues[dataSeries.id];
          dataSeries.appendRange(xValues, val);
        } else {
          const index = xValues.findIndex((x) => x == volumeData.key);
          const value = yValues[dataSeries.id][index];
          if (volumeData.command === 'ADD') {
            dataSeries.append(volumeData.key, value);
          } else {
            dataSeries.update(index, value);
          }
        }
      });
      updates.resume();
    }
  }

  private getNewSizeQtty(
    volume: VolumeData,
    maxVolumeAtPrice: number
  ): {
    qtty_buyer: number;
    qtty_seller: number;
    qtty_rlp: number;
    qtty_direct: number;
  } {
    const minSize = 1;
    const qttyHasValue = this.getQttyHasValue(volume);
    const newSize = minSize / qttyHasValue;
    const qtty_total = (volume.qtty_total / maxVolumeAtPrice) * 100;
    const isTooSmall = qtty_total < minSize;
    const qtty_buyer = !isTooSmall
      ? (volume.qtty_buyer / maxVolumeAtPrice) * 100
      : volume.qtty_buyer
      ? newSize
      : 0;
    const qtty_seller = !isTooSmall
      ? (volume.qtty_seller / maxVolumeAtPrice) * 100
      : volume.qtty_seller
      ? newSize
      : 0;
    const qtty_rlp = !isTooSmall
      ? (volume.qtty_rlp / maxVolumeAtPrice) * 100
      : volume.qtty_rlp
      ? newSize
      : 0;
    const qtty_direct = !isTooSmall
      ? (volume.qtty_direct / maxVolumeAtPrice) * 100
      : volume.qtty_direct
      ? newSize
      : 0;
    return { qtty_buyer, qtty_seller, qtty_rlp, qtty_direct };
  }

  private getQttyHasValue(volume: VolumeData): number {
    let qttyHasValue = 0;
    if (volume.qtty_buyer) {
      qttyHasValue++;
    }
    if (volume.qtty_seller) {
      qttyHasValue++;
    }
    if (volume.qtty_rlp) {
      qttyHasValue++;
    }
    if (volume.qtty_direct) {
      qttyHasValue++;
    }
    return qttyHasValue;
  }

  private createVolumeAtPriceChart(
    baseChart: TWebAssemblyChart,
    xAxisId: string,
    yAxisId: string,
    xValues: number[],
    yValues: any
  ) {
    const renderableSeries = baseChart.sciChartSurface.renderableSeries.getById(
      CANDLE_IDS.VOLUME_X_PRICE_SERIES
    );
    if (renderableSeries) return renderableSeries;
    const stacked = new StackedColumnCollectionTiger(
      baseChart.wasmContext,
      CANDLE_IDS.VOLUME_X_PRICE_SERIES,
      {
        xAxisId,
        yAxisId,
      }
    );
    const rendSeries1 = new StackedColumnRenderableSeries(
      baseChart.wasmContext,
      {
        dataSeries: new XyDataSeries(baseChart.wasmContext, {
          xValues,
          yValues: yValues['qtty_buyer'],
          dataSeriesName: `qtty_buyer`,
          id: `qtty_buyer`,
          dataIsSortedInX: true,
        }),
        fill: '#5999f880',
        strokeThickness: 0,
        stroke: '#5999f880',
        stackedGroupId: `StackedGroupId`,
      }
    );
    const rendSeries2 = new StackedColumnRenderableSeries(
      baseChart.wasmContext,
      {
        dataSeries: new XyDataSeries(baseChart.wasmContext, {
          xValues,
          yValues: yValues['qtty_seller'],
          dataSeriesName: `qtty_seller`,
          id: `qtty_seller`,
          dataIsSortedInX: true,
        }),
        fill: '#fbbb0080',
        strokeThickness: 0,
        stroke: '#fbbb0080',
        stackedGroupId: `StackedGroupId`,
      }
    );

    const rendSeries3 = new StackedColumnRenderableSeries(
      baseChart.wasmContext,
      {
        dataSeries: new XyDataSeries(baseChart.wasmContext, {
          xValues,
          yValues: yValues['qtty_rlp'],
          dataSeriesName: `qtty_rlp`,
          id: `qtty_rlp`,
          dataIsSortedInX: true,
        }),
        fill: this.themeService.isDarkTheme() ? '#E7EAEE80' : '#23242F80',
        strokeThickness: 0,
        stroke: this.themeService.isDarkTheme() ? '#E7EAEE80' : '#23242F80',
        stackedGroupId: `StackedGroupId`,
      }
    );

    const rendSeries4 = new StackedColumnRenderableSeries(
      baseChart.wasmContext,
      {
        dataSeries: new XyDataSeries(baseChart.wasmContext, {
          xValues,
          yValues: yValues['qtty_direct'],
          dataSeriesName: `qtty_direct`,
          id: `qtty_direct`,
          dataIsSortedInX: true,
        }),
        fill: '#35354680',
        strokeThickness: 0,
        stroke: '#35354680',
        stackedGroupId: `StackedGroupId`,
      }
    );
    stacked.add(rendSeries1, rendSeries2, rendSeries3, rendSeries4);
    stacked.dataPointWidth = 0.8;
    baseChart.sciChartSurface.renderableSeries.add(stacked);
    return stacked;
  }
}
