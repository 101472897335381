<a
  class="list-group-item align-items-center list-group-item-action d-flex gap-3 py-3"
  aria-current="true"
>
  <div class="d-flex gap-2 w-100 justify-content-between align-items-center">
    <div class="d-flex flex-column fw-normal">
      <ng-container *ngIf="field">
        <span class="fw-bold my-0 fw-default fs-5">
          {{ field }}
        </span>
      </ng-container>
      <span class="my-0">{{ value }}</span>
    </div>
    <ng-container *ngIf="displayButton && buttonLabel">
      <rocket-button
        type="outline"
        [isSmall]="true"
        [label]="buttonLabel"
        (rocketClick)="handleClick.emit()"
      >
      </rocket-button>
    </ng-container>
  </div>
</a>
