export const AUTH_SESSION_KEYS = {
  USER_STATE_KEY: 'USER_AUTH',
  USER_STATE_LOGGED: 'LOGADO',
  CLIENT_AUTH: 'INVAUT',
  ID_INVESTOR: 'IDINV',
  USER_AUTHENTICATED: 'USAUTH',
  ORDER_STATUS_COLOR: 'ORDER_STATUS_COLOR',
  PROFILE_KEY: 'PROFILE_DATA',
  TRADING_TOKEN: 'TRADING_TOKEN',
  BROKERS: 'BROKERS',
  SIMULATED_BROKERS: 'SIMULATED_BROKERS',
  PACKAGES: 'PACKAGES',
  TRADE_MODE_LABELS: 'TRADE_MODE_LABELS',
  IS_NEW_TAB: 'IS_NEW_TAB',
  VALIDATED_PHONE: 'VALIDATED_PHONE',
};

export const NITRO_PREFIX = 'NITRO';
export const AUTH_LOCAL_KEYS = {
  USE_SPLIT: `${NITRO_PREFIX}_USE_SPLIT`,
  DONT_ASK_SPLIT: `${NITRO_PREFIX}_DONT_ASK_SPLIT`,
  CONFIRMATION_RESET_POSITION: `${NITRO_PREFIX}_CONFIRMATION_RESET_POSITION`,
  CONFIRMATION_CANCEL_POSITION: `${NITRO_PREFIX}_CONFIRMATION_CANCEL_POSITION`,
  CONFIRMATION_INVERT_POSITION: `${NITRO_PREFIX}CONFIRMATION_INVERT_POSITION`,
  CONFIRMATION_SCHEDULE_ORDER_AFTER_MARKET_CLOSE: `${NITRO_PREFIX}_CONFIRMATION_SCHEDULE_ORDER_AFTER_MARKET_CLOSE`,
  SIMULATOR_ON: 'SIMULATOR_ON',
  MULTIBROKER_ACCOUNT_SELECTED: 'MULTIBROKER_ACCOUNT_SELECTED',
  SIMULATOR_ACCOUNT_SELECTED: 'SIMULATOR_ACCOUNT_SELECTED',
  SIMULATOR_SELECTED: 'SIMULATOR_SELECTED',
  INDICATORS_MODAL_COLLAPSE: 'INDICATORS_MODAL_COLLAPSE',
  CHART_TEMP_CONFIG: 'CHART_TEMP_CONFIG',
  CHART_INDICATORS_CONFIG: 'CHART_INDICATORS_CONFIG',
  CHART_CONTEXT_MENU_CONFIG: 'CHART_CONTEXT_MENU_CONFIG',
  USER_COLOR_DRAW_TOOLS: 'USER_COLOR_DRAW_TOOLS',
  USER_DRAW_STOCK: 'USER_DRAW_STOCK',
  DRAW_TOOL_MODEL: 'DRAW_TOOL_MODEL',
  USE_DAYTRADE_MODE: 'USE_DAYTRADE_MODE',
  SHOW_CHART_INDICATORS: 'SHOW_CHART_INDICATORS',
  CHART_GLOBAL_CONFIGURATION: 'CHART_GLOBAL_CONFIGURATION',
  NEWS_CONFIGURATION: 'NEWS_CONFIGURATION',
  SCREENING_FILTERS: 'SCREENING_FILTERS',
  OPTIONS_CONFIGURATION: 'OPTIONS_CONFIGURATION',
  SAVE_QTTY_GROUP_STOCKS: 'SAVE_QTTY_GROUP_STOCKS',
  BREAK_EVEN_IS_CLICKED: 'BREAK_EVEN_IS_CLICKED',
  LIST_STOCK_QTTY_GROUP: 'LIST_STOCK_QTTY_GROUP',
  ALL_LIST_STOCKS: 'ALL_LIST_STOCKS',
  ALL_ORDERS: 'ALL_ORDERS',
  ALL_HASH_ORDERS: 'ALL_HASH_ORDERS',
  STOCK_ANALYSIS_CONFIG: 'STOCK_ANALYSIS_CONFIG',
  MARKET_SUMMARY_CONFIG: 'MARKET_SUMMARY_CONFIG',
  AUDIO_NOTIFICATION: `AUDIO_NOTIFICATION`,
  CONFIG_SOUNDS_NOTIFICATIONS: `CONFIG_SOUNDS_NOTIFICATIONS`,
  CHART_FAVORITE_INDICATORS: 'CHART_FAVORITE_INDICATORS',
  DISPLAY_TRADEMAP_WELCOME_MODAL: 'DISPLAY_TRADEMAP_WELCOME_MODAL',
  LINKED_PARTNER: `${NITRO_PREFIX}_LINKED_PARTNER`,
  PARTNER_MODAL_SEEN: `${NITRO_PREFIX}_PARTNER_MODAL_SEEN`,
  STOCK_INDEX_SELECTED: `${NITRO_PREFIX}_STOCK_INDEX_SELECTED`,
  ACTIVE_TAB_ROCKET: 'ACTIVE_TAB_ROCKET',
  CHEETAH_TOKEN: 'CHEETAH_TOKEN',
  CHEETAH_URL: 'CHEETAH_URL',
  TIMES_TRADES_FILTERS: 'TIMES_TRADES_FILTERS',
  COUNT_INITIALIZED_BY_TOUR: 'COUNT_INITIALIZED_BY_TOUR',
  ORDERS_HISTORY_CARD_VIEW: 'ORDERS_HISTORY_CARD_VIEW',
  COUNT_TRADEMAP_TOKEN_ALERT: 'COUNT_TRADEMAP_TOKEN_ALERT',
  SHOULD_INCREASE_TRADEMAP_TOKEN_ALERT: 'SHOULD_INCREASE_TRADEMAP_TOKEN_ALERT',
};
