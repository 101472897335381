<div *ngIf="!noData; else noInfo" class="d-flex flex-column gap-3">
  <div
    *ngIf="stockConsensus"
    class="recomendation d-flex flex-column h-100 gap-5"
  >
    <div class="d-flex flex-column">
      <span class="fw-bold">{{ qttyRecomendations }}</span>
      <span class="fs-5 fw-bold text-neutral-medium"
        >Recomendações {{ princi }}</span
      >
    </div>
    <div class="d-flex align-items-center gap-5">
      <div
        [ngClass]="selectedRecommendaton.class"
        (click)="recommendationClickCallback()"
        class="circle-recomendation d-flex justify-content-center align-items-center fw-bold round-pill"
      >
        <span>{{ stockConsensus.final_recommendation }}</span>
      </div>
      <div class="w-100">
        <app-recomendation-bars
          [recommendations]="recommendationsBars"
          [stockSelected]="stock"
          [vlClose]="0"
          [widthClose]="0"
        ></app-recomendation-bars>
      </div>
    </div>
  </div>

  <div class="target-price d-flex flex-column h-100">
    <div class="d-flex flex-column">
      <span class="fw-bold d-flex justify-content-between">
        {{ qttyTargetPrices }}
        <div class="p-0 mb-1 d-flex gap-1">
          <span
            (click)="changeViewType(false)"
            class="cursor-pointer"
            [ngClass]="{ 'text-brand-primary': !usePercentage }"
            >{{ currency }}</span
          >
          <span
            (click)="changeViewType(true)"
            class="cursor-pointer"
            [ngClass]="{ 'text-brand-primary': usePercentage }"
            >%</span
          >
        </div>
      </span>
      <span class="fs-5 fw-bold text-neutral-medium"
        >Estimativas de preço justo {{ princi }}</span
      >
      <app-recomendation-bars
        [stockSelected]="stock"
        [recommendations]="recommendationsTargetPrices"
        [vlClose]="vlClose"
        [widthClose]="widthClose"
      ></app-recomendation-bars>
    </div>
  </div>
</div>

<ng-template #noInfo>
  <div class="h-100 w-100 d-flex justify-content-center align-items-center">
    <app-no-info></app-no-info>
  </div>
</ng-template>
