import { AUTH_LOCAL_KEYS, AUTH_SESSION_KEYS } from '../const/auth_util.const';
import {
  CLEANNER_LOGOUT_MESSAGE,
  FORM_LOGIN_PASSWORD,
  FORM_LOGIN_REMEMBER,
  FORM_LOGIN_USER,
  LOGOUT_MESSAGE,
  LOGOUT_REASON,
} from '../const/local-storage.const';
import { sharedSessionStorage } from './shared-session.storage';

export class StorageUtils {
  private static getItem(factory: any, key: string) {
    let rep;
    try {
      const session_data: any = factory.getItem(key);
      rep = JSON.parse(session_data);
    } catch (error) {
      rep = {};
    }

    return rep;
  }

  private static setItem(factory: any, key: string, value: any) {
    factory.setItem(key, JSON.stringify(value));
  }
  private static removeItem(factory: any, key: string) {
    factory.removeItem(key);
  }
  public static getItemSessionStorage(key: any) {
    return this.getItem(sharedSessionStorage, key);
  }

  public static setItemSessionStorage(key: string, value: any) {
    this.setItem(sharedSessionStorage, key, value);
  }

  public static getItemLocalStorage(key: any) {
    return this.getItem(localStorage, key);
  }

  public static setItemLocalStorage(key: string, value: any) {
    this.setItem(localStorage, key, value);
  }

  public static removeItemSessionStorage(key: string) {
    this.removeItem(sharedSessionStorage, key);
  }

  public static removeItemLocalStorage(key: string) {
    this.removeItem(localStorage, key);
  }

  public static clearLocalStorage = () => {
    const keysToPreserve = [
      LOGOUT_REASON,
      LOGOUT_MESSAGE,
      CLEANNER_LOGOUT_MESSAGE,
      FORM_LOGIN_USER,
      FORM_LOGIN_PASSWORD,
      FORM_LOGIN_REMEMBER,
      AUTH_SESSION_KEYS.USER_AUTHENTICATED,
      AUTH_SESSION_KEYS.ID_INVESTOR,
      AUTH_LOCAL_KEYS.CHEETAH_TOKEN,
      AUTH_LOCAL_KEYS.CHEETAH_URL,
      AUTH_SESSION_KEYS.USER_AUTHENTICATED,
      AUTH_SESSION_KEYS.ID_INVESTOR,
      AUTH_SESSION_KEYS.PACKAGES,
      AUTH_SESSION_KEYS.ORDER_STATUS_COLOR,
    ];
    const previousValues: { [key: string]: string | null } = {};

    keysToPreserve.forEach((key) => {
      previousValues[key] = localStorage.getItem(key);
    });

    localStorage.clear();

    for (const [key, value] of Object.entries(previousValues)) {
      if (value !== null) {
        localStorage.setItem(key, value);
      }
    }
  };
}
