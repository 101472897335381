export const momentumTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  O indicador Momentum (MOM) é uma medida da velocidade de preços que tem como
  objetivo identificar a força de uma tendência. Ele é calculado como a
  diferença entre o preço atual e o preço de um período anterior. Quando o
  indicador é positivo, isso indica que o preço atual é maior do que o preço de
  um período anterior, o que sugere que há uma tendência de alta. Por outro
  lado, quando o indicador é negativo, isso indica que o preço atual é menor do
  que o preço de um período anterior, o que sugere que há uma tendência de
  baixa.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">
  O cálculo do indicador Momentum (MOM) envolve a subtração do preço de
  fechamento atual pelo preço de fechamento de um período anterior. A fórmula é
  a seguinte:
</div>
<div class="mb-3">Momentum = Preço Atual - Preço de X Períodos Atrás</div>
<div class="mb-3">
  O valor de X pode variar dependendo do período que está sendo analisado. Por
  exemplo, para um gráfico diário, pode-se utilizar o preço de fechamento de um
  dia anterior. Já para um gráfico semanal, pode-se utilizar o preço de
  fechamento de uma semana anterior.
</div>
<div class="mb-3">
  O valor resultante do cálculo do Momentum pode ser positivo ou negativo,
  dependendo do valor do preço atual em relação ao preço do período anterior. Um
  valor positivo indica uma tendência de alta, enquanto um valor negativo indica
  uma tendência de baixa.
</div>
<div class="fw-bolder">Exemplo de cálculo</div>
<div class="mb-3">
  Suponha que o preço de fechamento de uma ação foi de R$ 50,00 há 5 dias, e
  hoje o preço de fechamento é de R$ 55,00. Se estivermos analisando um gráfico
  diário, podemos calcular o Momentum (MOM) como:
</div>
<div class="mb-3">
  &bull; Mom = Preço Atual - Preço de 5 Dias Atrás<br />
  &bull; Mom = R$ 55,00 - R$ 50,00 <br />
  &bull; Mom = R$ 5,00
</div>
<div class="mb-3">
  Neste caso, como o resultado é positivo, isso indica que há uma tendência de
  alta para essa ação. O valor de R$ 5,00 indica a força dessa tendência, ou
  seja, a velocidade com que o preço está subindo.
</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  O indicador Momentum (MOM) pode ser usado de várias maneiras para ajudar na
  análise técnica de um ativo. Algumas das maneiras mais comuns incluem:
</div>
<div class="mb-3">
  &bull; Identificar possíveis reversões de tendência: quando o indicador
  Momentum (MOM) atinge níveis extremos (altos ou baixos), isso pode indicar que
  a tendência atual está perdendo força e pode se reverter em breve.<br />
  &bull; Confirmar tendências de preços: se o indicador Momentum (MOM) estiver
  se movendo na mesma direção que a tendência de preços atual, isso pode indicar
  que a tendência está se fortalecendo e é mais provável que continue.<br />
  &bull; Identificar divergências: se o indicador Momentum (MOM) estiver se
  movendo em direção oposta à tendência de preços atual, isso pode indicar uma
  divergência e sugerir que a tendência pode estar perdendo força ou prestes a
  se reverter.
</div>
<div class="mb-3">
  É importante lembrar que nenhum indicador técnico é infalível e, portanto, é
  sempre recomendável usar o indicador Momentum (MOM) em conjunto com outras
  ferramentas de análise técnica para obter uma visão mais completa do mercado.
</div>
<div class="mb-3">
  <img class="w-75" src="/assets/indicators/mom-como-funciona.png" />
</div>
<div class="fw-bolder">Como configurar</div>
<div class="fw-bolder">Valores</div>
<div class="text-decoration-underline">Período</div>
<div class="mb-3">
  O período a ser usado no cálculo do MOM. 10 dias é o padrão.
</div>
<div class="text-decoration-underline">Fonte</div>
<div class="mb-3">
  Determina quais dados de cada barra serão usados nos cálculos. Fechamento é o
  padrão.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/mom-entrada.png"
  />
</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">MOM</div>
<div class="mb-3">
  Pode alternar a visibilidade do MOM. Também pode selecionar a cor, a espessura
  e o estilo da linha do MOM.
</div>
<div class="mb-3">
  <img class="image-indicators-input" src="/assets/indicators/mom-estilo.png" />
</div>
`;
