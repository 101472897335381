export const averagePriceTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  O indicador de preço médio é um cálculo que mostra o preço médio de um ativo
  durante um período específico. É calculado somando o preço de fechamento de
  cada período e dividindo pelo número de períodos incluídos no cálculo. Esse
  indicador é utilizado para ajudar na identificação de tendências de preços e
  níveis de suporte e resistência, sendo uma ferramenta bastante útil para
  traders e investidores que desejam analisar o comportamento de um ativo ao
  longo do tempo.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">
  O indicador de preço médio é calculado somando o preço de fechamento de cada
  período e dividindo pelo número de períodos incluídos no cálculo. Por exemplo,
  se quisermos calcular o indicador de preço médio de um ativo para os últimos
  10 dias, somamos o preço de fechamento dos últimos 10 dias e dividimos por 10.
  Esse indicador pode ser usado para identificar tendências de preços e níveis
  de suporte e resistência de um ativo.
</div>
<div class="mb-3">
  Um exemplo de cálculo do indicador de preço médio seria o seguinte: suponha
  que, nos últimos 5 dias, o preço de fechamento de um ativo tenha sido R$10,
  R$12, R$11, R$13 e R$14. Para calcular o indicador de preço médio, somamos
  esses preços e dividimos por 5, obtendo um valor de R$12. Essa média pode ser
  útil para identificar tendências de preços e níveis de suporte e resistência
  do ativo em questão.
</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  Para usar o indicador de preço médio, primeiro você precisa definir um período
  específico, como 10 dias, 1 mês ou 1 ano. Em seguida, você precisa coletar os
  preços de fechamento do ativo durante esse período e somá-los. Depois, divida
  o resultado pelo número de períodos incluídos no cálculo.
</div>
<div class="mb-3">
  O resultado é o preço médio do ativo durante o período selecionado. Esse
  indicador pode ser usado para identificar tendências de preços e níveis de
  suporte e resistência do ativo. Se o preço atual do ativo estiver acima do
  indicador de preço médio, isso pode indicar uma tendência de alta, enquanto um
  preço abaixo do indicador pode indicar uma tendência de baixa.
</div>
<div class="mb-3">
  Em resumo, o indicador de preço médio é uma ferramenta útil para traders e
  investidores que desejam analisar o comportamento de um ativo ao longo do
  tempo e identificar possíveis tendências de preços e níveis de suporte e
  resistência.
</div>
<div class="mb-3">
  <img class="w-75" src="/assets/indicators/average-price-como-funciona.png" />
</div>
<div class="fw-bolder">Como configurar</div>
<div class="fw-bolder">Valores</div>
<div class="mb-3">Sem valores pré-definidos para configurar.</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">Linha</div>
<div class="mb-3">
  Pode alternar a visibilidade do Preço Médio. Também pode selecionar a cor, a
  espessura e o estilo da linha do Preço Médio.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/average-price-estilo.png"
  />
</div>
`;
