<rocket-modal>
  <rocket-modal-header [hideCloseButton]="configs.showButtons">
    <h3 class="modal-title">{{ configs.title }}</h3>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <p class="mb-4" [innerHTML]="configs.text"></p>
    <span *ngIf="configs.showPriceStock && lastPrice"
      >Preço atual do ativo: {{ lastPrice }}</span
    >
    <div *ngIf="configs.askAgain">
      <app-check
        label="Não perguntar novamente"
        css=""
        value=""
        formControlName="status"
        [isRequired]="false"
        [inline]="false"
        [isSwitch]="false"
        [disabled]="false"
        (flaChange)="dontAskAgain = !dontAskAgain"
      ></app-check>
    </div>
  </app-rocket-modal-body>
  <rocket-modal-footer *ngIf="configs.showButtons">
    <rocket-button
      type="fill"
      [label]="configs.cancelButton"
      (rocketClick)="onConfirm(false)"
    ></rocket-button>
    <rocket-button
      css="ml-2"
      [label]="configs.confirmeButton"
      (rocketClick)="onConfirm(true)"
    ></rocket-button>
  </rocket-modal-footer>
</rocket-modal>
