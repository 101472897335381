import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '@shared/services/rest';
import { Observable, catchError, delay, map, of } from 'rxjs';
import { IResponse } from '../V6/interface/loan-balance.interface';
import { Dictionary } from '@core/models';
import { IvlCloseChart, IvlCloseWithSynonymous } from './interface/chart';

@Injectable({
  providedIn: 'root',
})
export class ChartService extends RestService {
  override _url = 'api/trademap/v3/chart';
  private _vlCloseDict: Dictionary<IvlCloseChart> =
    new Dictionary<IvlCloseChart>();
  private _vlCloseWithSynonymousDict: Dictionary<IvlCloseWithSynonymous> =
    new Dictionary<IvlCloseWithSynonymous>();

  constructor(private _httpClient: HttpClient) {
    super(_httpClient);
  }

  public vlCloseWithSynonymous(
    cdStocks: string[]
  ): Observable<IvlCloseWithSynonymous> {
    const dataInDict: any = {};
    const stocksToSearch: string[] = [];
    cdStocks.map((stock) => {
      if (this._vlCloseWithSynonymousDict.has(stock))
        dataInDict[stock] = this._vlCloseWithSynonymousDict.get(stock);
      else stocksToSearch.push(stock);
    });
    if (!stocksToSearch.length)
      return of({ close_limit: dataInDict, close_variation: {} });
    return this.post<IResponse<IvlCloseWithSynonymous[]>>(
      'get-vl-close-limit-with-synonymous',
      {
        cd_stocks: stocksToSearch,
        tbbar: 'fiveminutes',
        dt_ini: '',
        size: 100,
        last_price: true,
        with_chart: true,
      }
    ).pipe(
      catchError((error) => of(this.handlerServiceError(error))),
      map((response) => {
        if (!response?.data?.success) throw response;
        const data: any = {};
        const closeLimit = response.data.result.close_limit;
        for (const stock in closeLimit) {
          if (closeLimit[stock].length) {
            const reversedArray: any = [];
            for (let i = closeLimit[stock].length - 1; i >= 0; i--) {
              reversedArray.push(closeLimit[stock][i]);
            }
            data[stock] = reversedArray;
            this._vlCloseWithSynonymousDict.set(stock, reversedArray);
          }
        }
        return {
          close_limit: Object.assign(data, dataInDict),
          close_variation: [],
        };
      })
    );
  }

  public vlClose(cdStocks: string[]): Observable<IvlCloseChart> {
    let stocksToSearch: string[] = [];
    const vlCloseInDict: any = {};

    if (this._vlCloseDict.size() > 0) {
      for (let index = 0, len = cdStocks.length; index < len; index++) {
        if (this._vlCloseDict.has(cdStocks[index])) {
          vlCloseInDict[cdStocks[index]] = this._vlCloseDict.get(
            cdStocks[index]
          );
        } else stocksToSearch.push(cdStocks[index]);
      }

      if (!stocksToSearch.length)
        return of({
          close_limit_chart: vlCloseInDict,
          close_variation: {},
        }).pipe(delay(10));
    } else stocksToSearch = cdStocks;

    return this.post<IResponse<IvlCloseChart[]>>('get-vl-close-limit-chart ', {
      cd_stocks: stocksToSearch,
      tbbar: 'fiveminutes',
      size: 100,
    }).pipe(
      catchError((error) => of(this.handlerServiceError(error))),
      map((response) => {
        if (!response?.data?.success) throw response;
        const data: any = {};
        const result = response.data.result.close_limit_chart;
        for (const stock in result) {
          if (result[stock].length) {
            const chartPoints: any = [];
            for (let i = result[stock].length - 1; i >= 0; i--) {
              chartPoints.push(result[stock][i]);
            }
            data[stock] = chartPoints;
            this._vlCloseDict.set(stock, chartPoints);
          }
        }
        return {
          close_limit_chart: Object.assign(data, vlCloseInDict),
        };
      })
    );
  }
}
