import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';

@Component({
  selector: 'app-rocket-trader-terms.component',
  templateUrl: './rocket-trader-terms.component.html',
  styleUrls: ['../terms-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RocketTraderTermsComponent implements OnInit, OnDestroy {
  constructor(private themePreferences: ThemePreferencesService) {}

  ngOnInit(): void {
    this.themePreferences.forceLightTheme();
  }

  ngOnDestroy(): void {
    this.themePreferences.resetTheme();
  }
}
