<rocket-modal>
  <rocket-modal-header [hideCloseButton]="!isDev">
    <div>
      <h3 class="modal-title">{{ title }}</h3>
      <p class="mb-0 mt-2 fs-5" [innerHTML]="subTitle"></p>
    </div>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <div class="form-check cursor-pointer">
      <input
        class="form-check-input cursor-pointer"
        type="checkbox"
        (change)="acceptedTerms = !acceptedTerms"
        id="terms"
      />
      <label class="form-check-label fs-5" for="terms">
        Aceito os
        <a [href]="data.content" class="text-brand-primary fs-5" target="_blank"
          >Termos de Uso e Política de Privacidade</a
        >
      </label>

      <div class="mt-3">
        <label for="token" class="form-label">Senha</label>
        <div class="input-group group-suffix">
          <input
            [type]="inputType"
            class="form-control"
            [ngClass]="{ 'border-feedback-error': invalid }"
            id="token"
            placeholder="Insira aqui a sua senha"
            (keyup)="setToken($event)"
            maxlength="100"
          />
          <button
            class="btn btn-icon suffix"
            type="button"
            (click)="toggleVisibility()"
            [ngClass]="{ 'border-feedback-error': invalid }"
          >
            <i class="icons pe-none" style="left: 8px; position: relative">
              {{ visibilityIcon }}
            </i>
          </button>
        </div>
        <span class="d-block invalid-feedback">
          {{ tokenErrorMessage }}
        </span>
      </div>
    </div>
  </app-rocket-modal-body>
  <rocket-modal-footer>
    <rocket-button
      *ngIf="!isRequired"
      type="fill"
      [loading]="loadingApprove"
      label="Talvez mais tarde"
      (rocketClick)="onClose(false)"
      css="mr-4"
    ></rocket-button>

    <rocket-button
      [isDisabled]="!token || !acceptedTerms"
      [loading]="loadingApprove"
      label="Próximo"
      (rocketClick)="acceptTerms()"
    ></rocket-button>
  </rocket-modal-footer>
</rocket-modal>

<div class="modal-backdrop fade show"></div>
