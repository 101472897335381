export enum STOCK_ANALYSIS_VOLATILITY {
  '12M' = 'Volatilidade 12m',
  '24M' = 'Volatilidade 24m',
}

export enum STOCK_ANALYSIS_PROFITABILITY {
  YESTERDAY = 'Ontem',
  ONEDAY = '1 dia',
  ONEWEEK = '1 semana',
  ONEMONTH = '1 mês',
  ONEYEAR = '1 ano',
  THISYEAR = 'Ano atual',
}

export enum STOCK_ANALYSIS_VARIATION {
  VARIATION = 'Variação 1 Dia',
  MCAP = 'MCAP',
  VOLUME = 'Volume 1 Dia',
  YESTERDAY_VOLUME = 'Volume Ontem',
  ONE_WEEK = 'Volume 1 Semana',
  ONE_MONTH = 'Volume 1 Mês',
  ONE_YEAR = 'Volume 1 Ano',
}

export enum STOCK_ANALYSIS_VOLATILITY_FIELDS {
  '12M' = 'vl_volatility_one_year',
  '24M' = 'vl_volatility_two_year',
}

export enum STOCK_ANALYSIS_PROFITABILITY_FIELDS {
  YESTERDAY = 'yesterday_variation',
  ONEDAY = 'day_variation',
  ONEWEEK = 'week_variation',
  ONEMONTH = 'month_variation',
  ONEYEAR = 'year_variation',
  THISYEAR = 'this_year_variation',
}

export enum STOCK_ANALYSIS_VARIATION_FIELDS {
  VARIATION = 'variacao_dia',
  MCAP = 'mcap',
  VOLUME = 'volume',
  YESTERDAY_VOLUME = 'yesterday_volume',
  ONE_WEEK = 'week_volume',
  ONE_MONTH = 'month_volume',
  ONE_YEAR = 'year_volume',
}

export const STOCK_ANALYSIS_RENDERABLE_SERIES_ID =
  'STOCK_ANALYSIS_RENDERABLE_SERIES_ID';

export enum STOCK_ANALYSIS_NAVIGATION_CONTROLS {
  ZOOM_IN = 'ZoomIn',
  ZOOM_OUT = 'ZoomOut',
  REFRESH = 'Refresh',
}

export enum STOCK_ANALYSIS_MOUSE_EVENT_DATA_TYPE {
  SERIES_ELEMENT_MOUSE_CLICK,
  SERIES_ELEMENT_HOVER,
}
