import { Injectable } from '@angular/core';
import { CHEETAH_CHANNEL, OPTIONS_FIELDS } from '@shared/cheetah/cheetah.const';
import { MarketDispatcher } from '@shared/cheetah/dispatcher/market.dispatcher';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { RxEvent } from './rx-event';

@Injectable({
  providedIn: 'root',
})
export class OptionsChannel {
  static instance: OptionsChannel;

  constructor(
    private _rxEvent: RxEvent,
    private cheetahService: MarketDispatcher
  ) {
    return (OptionsChannel.instance = OptionsChannel.instance || this);
  }

  async onEvents() {
    return await this._rxEvent.read(CHEETAH_CHANNEL.OPTIONS);
  }

  subscribe(params: SubscribeParam) {
    params.fields = OPTIONS_FIELDS;
    params.channel_cheetah = CHEETAH_CHANNEL.OPTIONS;
    this.cheetahService.subscribe(params);
  }

  unsubscribe(params: SubscribeParam) {
    if (!params) return;
    this.cheetahService.unsubscribe({
      channel_cheetah: CHEETAH_CHANNEL.OPTIONS,
      itemsArray: params.itemsArray,
    });
  }
}
