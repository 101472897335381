<div *ngIf="authenticator?.id === 'TRADEMAP'; else nitroTemplate">
  <p class="fs-5 pb-3">
    Você atualmente está com o Token do TradeMap ativado,
    <b
      >para continuar negociando pela versão Web atualize para o Token do
      RocketTrader.</b
    >
  </p>
  <p class="fs-5 pb-3">
    <b>Baixe nosso app e tenha acesso a uma plataforma intuitiva e poderosa</b>,
    ideal para todos os níveis de experiência e estilos. Tenha uma experiência
    única com nosso layout moderno, análises técnicas, tecnologia de ponta e
    segurança!
  </p>
  <div class="bg-neutral-strong px-3 py-3 authenticator-card">
    <p class="fs-4 fw-bold pb-2">
      <img
        src="./assets/svg/rocket.svg"
        alt="Logo Vale Mobi"
        width="24px"
        class="mr-2"
      />
      RocketTrader
    </p>

    <p>
      Para atualizer seu Token para ser RocketTrader, siga os passos abaixo:
    </p>
    <ng-template *ngTemplateOutlet="instructions"></ng-template>
    <ng-content></ng-content>
  </div>
</div>

<ng-template #nitroTemplate>
  <p class="fs-5 pb-3">
    Para atualizar seu Token para ser <b>{{ authenticator?.name }}</b
    >, siga os passos abaixo:
  </p>
  <ng-template *ngTemplateOutlet="instructions"></ng-template>
</ng-template>
<ng-template #instructions>
  <ol class="pl-4">
    <li class="pb-3 fs-5">
      Baixe o app do <b>RocketTrader</b> na loja (Google Play ou App Store)
    </li>
    <li class="pb-3 fs-5">Abra o app e faça login na sua conta</li>
    <li class="pb-3 fs-5">
      No aplicativo do <b>RocketTrader</b>, clique na sua foto de perfil no
      canto superior direito
    </li>
    <li class="pb-3 fs-5">
      Escolha a opção <b>Token</b> e siga o passo a passo na tela
    </li>
    <li class="pb-3 fs-5">
      Quando aparecer o token de 6 dígitos no <b>RocketTrader</b> volte aqui e
      clique em <b>confirmar</b>
    </li>
  </ol>
</ng-template>
