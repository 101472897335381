<svg:path
  d="M83.5696 18.1553C89.2592 18.1553 93.6216 19.1689 96.6512 21.1905C99.6808 23.2177 101.193 26.3649 101.193 30.6321C101.193 33.2977 100.582 35.4593 99.3616 37.1169C98.1408 38.7745 96.3768 40.0793 94.0808 41.0313C94.848 41.9833 95.6488 43.0641 96.4888 44.2905C97.3288 45.5113 98.1576 46.7881 98.9808 48.1209C99.804 49.4537 100.594 50.8369 101.361 52.2817C102.128 53.7209 102.839 55.1377 103.494 56.5209H93.952C93.2577 55.2833 92.552 54.0233 91.8353 52.7465C91.1241 51.4697 90.3905 50.2265 89.6401 49.0225C88.8897 47.8185 88.1449 46.6761 87.4169 45.6009C86.6833 44.5257 85.9496 43.5457 85.2216 42.6721H81.0048V56.5209H72.4648V19.1409C74.324 18.7769 76.2505 18.5193 78.2385 18.3737C80.2265 18.2281 82.0073 18.1553 83.5753 18.1553H83.5696ZM84.0624 25.4353C83.4408 25.4353 82.8864 25.4521 82.3937 25.4913C81.9009 25.5305 81.436 25.5641 80.9992 25.6033V35.8905H83.4072C86.616 35.8905 88.9176 35.4873 90.3008 34.6865C91.6896 33.8857 92.3785 32.5137 92.3785 30.5817C92.3785 28.6497 91.6728 27.4009 90.2728 26.6113C88.8672 25.8273 86.7952 25.4353 84.0624 25.4353Z"
  fill="var(--vm-brand-primary)"
/>
<svg:path
  d="M133.976 42.0732C133.976 44.3356 133.646 46.4076 132.991 48.2836C132.335 50.1652 131.383 51.7668 130.146 53.0996C128.903 54.4324 127.419 55.4628 125.683 56.1908C123.947 56.9188 122.009 57.2828 119.853 57.2828C117.697 57.2828 115.81 56.9188 114.079 56.1908C112.343 55.4628 110.859 54.4324 109.616 53.0996C108.373 51.7668 107.41 50.1652 106.715 48.2836C106.021 46.402 105.674 44.3356 105.674 42.0732C105.674 39.8108 106.032 37.75 106.743 35.8908C107.455 34.0316 108.44 32.4412 109.7 31.1308C110.96 29.8204 112.455 28.7956 114.186 28.0676C115.916 27.3396 117.809 26.9756 119.853 26.9756C121.897 26.9756 123.84 27.3396 125.571 28.0676C127.301 28.7956 128.791 29.8204 130.034 31.1308C131.271 32.4468 132.24 34.0316 132.935 35.8908C133.629 37.75 133.976 39.8108 133.976 42.0732ZM125.655 42.0732C125.655 39.5532 125.151 37.5764 124.148 36.1372C123.146 34.698 121.712 33.9756 119.853 33.9756C117.994 33.9756 116.549 34.698 115.53 36.1372C114.511 37.582 113.995 39.5588 113.995 42.0732C113.995 44.5876 114.505 46.5868 115.53 48.0652C116.549 49.5436 117.994 50.2828 119.853 50.2828C121.712 50.2828 123.146 49.5436 124.148 48.0652C125.151 46.5868 125.655 44.5876 125.655 42.0732Z"
  fill="var(--vm-brand-primary)"
/>
<svg:path
  d="M138.904 42.1289C138.904 40.0513 139.24 38.0857 139.918 36.2433C140.595 34.4009 141.57 32.7937 142.846 31.4273C144.123 30.0609 145.674 28.9745 147.5 28.1737C149.326 27.3729 151.403 26.9697 153.738 26.9697C155.273 26.9697 156.673 27.1041 157.955 27.3785C159.232 27.6529 160.475 28.0449 161.679 28.5545L159.982 35.0673C159.215 34.7761 158.375 34.5185 157.462 34.3001C156.55 34.0817 155.53 33.9697 154.399 33.9697C151.991 33.9697 150.194 34.7201 149.006 36.2153C147.819 37.7105 147.226 39.6817 147.226 42.1289C147.226 44.7217 147.78 46.7265 148.894 48.1489C150.009 49.5713 151.952 50.2825 154.724 50.2825C155.71 50.2825 156.768 50.1929 157.899 50.0081C159.03 49.8233 160.072 49.5321 161.018 49.1345L162.166 55.8097C161.22 56.2129 160.033 56.5545 158.61 56.8513C157.188 57.1481 155.62 57.2881 153.901 57.2881C151.274 57.2881 149.012 56.8961 147.114 56.1121C145.215 55.3281 143.658 54.2585 142.432 52.9089C141.211 51.5593 140.315 49.9633 139.75 48.1209C139.184 46.2785 138.898 44.2793 138.898 42.1289H138.904Z"
  fill="var(--vm-brand-primary)"
/>
<svg:path
  d="M175.79 38.0748C176.591 37.2068 177.42 36.2996 178.277 35.3532C179.134 34.4124 179.962 33.4772 180.763 32.5532C181.564 31.6292 182.32 30.75 183.031 29.9156C183.742 29.0812 184.353 28.3588 184.862 27.7372H194.562C192.63 29.9604 190.737 32.0772 188.894 34.0988C187.052 36.1204 185.036 38.2036 182.846 40.354C183.938 41.3396 185.07 42.5212 186.24 43.8876C187.405 45.2596 188.536 46.6764 189.634 48.138C190.726 49.5996 191.734 51.0612 192.646 52.5228C193.559 53.9844 194.326 55.3172 194.942 56.5268H185.568C184.986 55.5748 184.319 54.522 183.574 53.374C182.83 52.2204 182.034 51.0724 181.2 49.9188C180.36 48.7652 179.481 47.662 178.551 46.598C177.622 45.5396 176.703 44.6436 175.79 43.91V56.5268H167.637V15.3668L175.79 14.0508V38.0692V38.0748Z"
  fill="var(--vm-brand-primary)"
/>
<svg:path
  d="M195.984 42.3473C195.984 39.7937 196.376 37.5593 197.16 35.6441C197.944 33.7289 198.975 32.1329 200.252 30.8561C201.528 29.5793 202.996 28.6105 204.659 27.9553C206.316 27.3001 208.024 26.9697 209.777 26.9697C213.865 26.9697 217.091 28.2185 219.465 30.7161C221.834 33.2137 223.021 36.8929 223.021 41.7425C223.021 42.2185 223.004 42.7393 222.965 43.3049C222.926 43.8705 222.892 44.3745 222.853 44.8113H204.356C204.541 46.4913 205.325 47.8241 206.708 48.8041C208.097 49.7897 209.956 50.2825 212.292 50.2825C213.787 50.2825 215.254 50.1481 216.699 49.8737C218.138 49.5993 219.314 49.2633 220.227 48.8601L221.319 55.4849C220.882 55.7033 220.3 55.9217 219.566 56.1401C218.838 56.3585 218.026 56.5489 217.13 56.7169C216.234 56.8793 215.276 57.0193 214.257 57.1257C213.232 57.2377 212.213 57.2881 211.194 57.2881C208.601 57.2881 206.35 56.9073 204.435 56.1401C202.52 55.3729 200.935 54.3257 199.675 52.9929C198.415 51.6601 197.485 50.0809 196.886 48.2609C196.281 46.4353 195.984 44.4641 195.984 42.3473ZM215.142 39.2281C215.103 38.5337 214.985 37.8617 214.784 37.2009C214.582 36.5457 214.274 35.9633 213.854 35.4481C213.434 34.9385 212.902 34.5185 212.269 34.1881C211.631 33.8577 210.836 33.6953 209.889 33.6953C208.943 33.6953 208.192 33.8521 207.537 34.1601C206.882 34.4681 206.333 34.8825 205.896 35.3921C205.46 35.9017 205.124 36.4953 204.883 37.1729C204.648 37.8505 204.474 38.5337 204.362 39.2281H215.142Z"
  fill="var(--vm-brand-primary)"
/>
<svg:path
  d="M229.041 20.564L237.194 19.248V27.7321H246.989V34.5193H237.194V44.6441C237.194 46.3577 237.497 47.7297 238.096 48.7489C238.701 49.7681 239.91 50.2833 241.736 50.2833C242.61 50.2833 243.517 50.1993 244.446 50.0369C245.376 49.8745 246.227 49.6449 246.989 49.3537L248.137 55.7041C247.151 56.1073 246.059 56.4545 244.855 56.7457C243.651 57.0369 242.173 57.1825 240.42 57.1825C238.191 57.1825 236.349 56.8801 234.893 56.2809C233.431 55.6761 232.266 54.8417 231.387 53.7609C230.514 52.6857 229.898 51.3809 229.556 49.8465C229.209 48.3121 229.035 46.6153 229.035 44.7561V20.564H229.041Z"
  fill="var(--vm-brand-primary)"
/>
<svg:path
  d="M282.297 18.5928V25.8728H270.856V56.5216H262.316V25.8728H250.875V18.5928H282.291H282.297Z"
  fill="var(--vm-neutral-smoothest)"
/>
<svg:path
  d="M300.794 34.9616C300.066 34.7768 299.21 34.5864 298.224 34.3848C297.238 34.1832 296.18 34.0824 295.049 34.0824C294.539 34.0824 293.929 34.1272 293.218 34.2168C292.506 34.3064 291.969 34.4072 291.605 34.5192V56.5216H283.451V29.2664C284.907 28.7568 286.632 28.2752 288.626 27.816C290.614 27.3624 292.831 27.1328 295.278 27.1328C295.715 27.1328 296.247 27.1608 296.863 27.2168C297.485 27.2728 298.101 27.3456 298.722 27.4352C299.344 27.5248 299.96 27.6368 300.582 27.7656C301.203 27.8944 301.73 28.0512 302.166 28.2304L300.8 34.9616H300.794Z"
  fill="var(--vm-neutral-smoothest)"
/>
<svg:path
  d="M316.338 26.9697C318.746 26.9697 320.751 27.2441 322.358 27.7929C323.966 28.3417 325.248 29.1257 326.217 30.1449C327.186 31.1697 327.869 32.4073 328.272 33.8689C328.675 35.3305 328.877 36.9545 328.877 38.7409V55.709C327.706 55.9666 326.088 56.2634 324.005 56.6106C321.922 56.9578 319.407 57.1314 316.45 57.1314C314.591 57.1314 312.9 56.969 311.388 56.6386C309.876 56.3082 308.571 55.7706 307.474 55.0258C306.376 54.281 305.542 53.301 304.954 52.097C304.371 50.893 304.08 49.4146 304.08 47.6618C304.08 45.9089 304.416 44.5593 305.094 43.3945C305.771 42.2297 306.673 41.2945 307.804 40.6057C308.935 39.9113 310.229 39.4129 311.69 39.0993C313.152 38.7913 314.664 38.6345 316.232 38.6345C317.29 38.6345 318.231 38.6793 319.049 38.7689C319.872 38.8585 320.533 38.9817 321.048 39.1273V38.3601C321.048 36.9769 320.628 35.8625 319.788 35.0225C318.948 34.1825 317.486 33.7625 315.409 33.7625C314.02 33.7625 312.654 33.8633 311.304 34.0649C309.954 34.2665 308.784 34.5465 307.804 34.9105L306.762 28.3417C307.238 28.1961 307.832 28.0393 308.543 27.8769C309.254 27.7145 310.027 27.5689 310.867 27.4401C311.707 27.3113 312.592 27.2049 313.522 27.1097C314.451 27.0201 315.392 26.9753 316.338 26.9753V26.9697ZM316.994 50.8314C317.794 50.8314 318.562 50.8146 319.29 50.7754C320.018 50.7418 320.606 50.6858 321.042 50.613V44.4305C320.712 44.3577 320.219 44.2849 319.564 44.2121C318.909 44.1393 318.304 44.1001 317.761 44.1001C316.994 44.1001 316.271 44.1449 315.599 44.2345C314.922 44.3241 314.334 44.4977 313.818 44.7553C313.309 45.0129 312.906 45.3601 312.614 45.7969C312.323 46.2337 312.178 46.7825 312.178 47.4377C312.178 48.7146 312.603 49.5994 313.466 50.0922C314.322 50.585 315.498 50.8314 316.994 50.8314Z"
  fill="var(--vm-neutral-smoothest)"
/>
<svg:path
  d="M361.654 55.5916C360.926 55.81 360.086 56.0228 359.134 56.2188C358.182 56.4204 357.191 56.594 356.15 56.7396C355.108 56.8852 354.05 57.0028 352.974 57.098C351.899 57.1876 350.869 57.2324 349.883 57.2324C347.509 57.2324 345.398 56.8852 343.533 56.1908C341.674 55.4964 340.106 54.5052 338.823 53.206C337.546 51.9124 336.572 50.3444 335.894 48.4964C335.217 46.654 334.881 44.582 334.881 42.286C334.881 39.99 335.172 37.8452 335.754 35.9636C336.337 34.082 337.177 32.486 338.274 31.1756C339.366 29.8596 340.71 28.8572 342.295 28.1628C343.88 27.4684 345.7 27.1212 347.738 27.1212C348.87 27.1212 349.883 27.2332 350.774 27.4516C351.67 27.67 352.571 27.9836 353.484 28.3812V15.3668L361.638 14.0508V55.5916H361.654ZM343.208 41.9108C343.208 44.4308 343.774 46.4524 344.905 47.9868C346.036 49.5212 347.716 50.2828 349.939 50.2828C350.667 50.2828 351.345 50.2548 351.966 50.1988C352.588 50.1428 353.098 50.0812 353.501 50.0084V35.174C352.991 34.8436 352.325 34.5692 351.502 34.3508C350.678 34.1324 349.85 34.0204 349.01 34.0204C345.14 34.0204 343.208 36.6468 343.208 41.8996V41.9108Z"
  fill="var(--vm-neutral-smoothest)"
/>
<svg:path
  d="M367.949 42.3473C367.949 39.7937 368.341 37.5593 369.125 35.6441C369.909 33.7289 370.94 32.1329 372.216 30.8561C373.493 29.5793 374.96 28.6105 376.624 27.9553C378.281 27.3001 379.989 26.9697 381.742 26.9697C385.83 26.9697 389.056 28.2185 391.43 30.7161C393.799 33.2137 394.986 36.8929 394.986 41.7425C394.986 42.2185 394.969 42.7393 394.93 43.3049C394.891 43.8705 394.857 44.3745 394.818 44.8113H376.321C376.506 46.4913 377.29 47.8241 378.673 48.8041C380.062 49.7897 381.921 50.2825 384.256 50.2825C385.752 50.2825 387.219 50.1481 388.664 49.8737C390.103 49.5993 391.279 49.2633 392.192 48.8601L393.289 55.4849C392.852 55.7033 392.264 55.9217 391.536 56.1401C390.808 56.3585 389.996 56.5489 389.1 56.7169C388.204 56.8793 387.247 57.0193 386.228 57.1257C385.208 57.2377 384.184 57.2881 383.164 57.2881C380.572 57.2881 378.32 56.9073 376.405 56.1401C374.49 55.3729 372.905 54.3257 371.645 52.9929C370.385 51.6601 369.456 50.0809 368.856 48.2609C368.252 46.4353 367.955 44.4641 367.955 42.3473H367.949ZM387.107 39.2281C387.068 38.5337 386.95 37.8617 386.748 37.2009C386.547 36.5457 386.239 35.9633 385.819 35.4481C385.399 34.9385 384.867 34.5185 384.234 34.1881C383.596 33.8577 382.8 33.6953 381.854 33.6953C380.908 33.6953 380.157 33.8521 379.502 34.1601C378.847 34.4681 378.298 34.8825 377.861 35.3921C377.424 35.9017 377.088 36.4953 376.848 37.1729C376.612 37.8505 376.439 38.5337 376.327 39.2281H387.107Z"
  fill="var(--vm-neutral-smoothest)"
/>
<svg:path
  d="M418.634 34.9616C417.906 34.7768 417.049 34.5864 416.064 34.3848C415.078 34.1832 414.02 34.0824 412.889 34.0824C412.379 34.0824 411.769 34.1272 411.057 34.2168C410.346 34.3064 409.809 34.4072 409.445 34.5192V56.5216H401.291V29.2664C402.747 28.7568 404.472 28.2752 406.465 27.816C408.453 27.3624 410.671 27.1328 413.118 27.1328C413.555 27.1328 414.087 27.1608 414.703 27.2168C415.325 27.2728 415.941 27.3456 416.562 27.4352C417.184 27.5248 417.8 27.6368 418.421 27.7656C419.043 27.8944 419.569 28.0512 420.006 28.2304L418.64 34.9616H418.634Z"
  fill="var(--vm-neutral-smoothest)"
/>
