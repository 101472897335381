<app-context-menu
  *ngIf="isDisplayContextMenu"
  [show]="isDisplayContextMenu"
  [options]="contextMenuOptions"
  (menuClose)="onCloseContextMenu()"
></app-context-menu>
<div class="bg-neutral-strong round-top d-flex justify-content-between">
  <section class="hstack">
    <ul
      class="tabs nav nav-navbar align-items-center"
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="drop($event)"
    >
      <li
        cdkDrag
        class="nav-item drag-box"
        *ngFor="let item of visiblesWorkspaces; let i = index"
        (contextmenu)="onMenuContextClick($event, item)"
      >
        <span
          *ngIf="item.visible"
          class="hstack py-2 px-1 tab"
          [ngClass]="{
            active: item.id === workspaceActive!.id,
            hide: !item.visible
          }"
        >
          <p
            *ngIf="!item.editMode; else elseEdit"
            [tooltip]="item.name"
            tooltipPosition="bottom"
            class="nav-link p-0 ml-0 mr-2 lh-sm cursor-pointer text-truncate d-inline-block"
            [class]="{
              'text-brand-primary': item.id === workspaceActive!.id
            }"
            (click)="activeWorkspace(item)"
            (dblclick)="showEditMode(item, i)"
          >
            <fla-icon
              [css]="
                item.id === workspaceActive!.id
                  ? 'ml-2 mr-2 align-middle text-brand-primary'
                  : 'ml-2 mr-2 align-middle '
              "
              size="icon-size-micro"
              name="video_label"
            ></fla-icon>
            {{ item.name }}
          </p>

          <ng-template #elseEdit>
            <div #editWsNameElement class="input-group">
              <div class="input-group group-suffix">
                <input
                  type="text"
                  titleCase
                  class="form-control form-control-sm border-0 py-0"
                  placeholder="Novo nome"
                  [formControl]="editForm"
                  fla-focus
                  (keydown.enter)="editName(item)"
                />
                <button
                  class="btn btn-icon suffix border-0"
                  type="button"
                  (click)="editName(item)"
                >
                  <i class="icons icon-size-micro">check</i>
                </button>
              </div>
            </div>
          </ng-template>
          <rocket-button
            *ngIf="visiblesWorkspaces.length > 1"
            type="icon"
            css="p-0 min-w-0"
            (rocketClick)="hide(item)"
          >
            <fla-icon
              name="close"
              size="icon-size-micro"
              css="text-neutral-medium"
            ></fla-icon>
          </rocket-button>
          <span class="vr align-self-center ml-2 bg-neutral-stronger"></span>
        </span>
      </li>
    </ul>

    <rocket-button
      type="icon"
      css="p-0 min-w-0 bg-neutral-stronger round-sm ml-2"
      (rocketClick)="newWorkspace()"
    >
      <fla-icon
        name="add"
        size="icon-size-micro"
        css="text-neutral-medium"
      ></fla-icon>
    </rocket-button>
  </section>
  <div class="pt-1 mr-2">
    <app-account-selector></app-account-selector>
  </div>
</div>
