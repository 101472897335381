import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-movers-options',
  templateUrl: './movers-options.component.html',
  styles: [
    `
      .list-max-height {
        min-width: 80px;
        max-height: 215px;
        overflow-x: auto;
      }
    `,
  ],
})
export class MoversOptionsComponent {
  @Output() handleSelectedOption = new EventEmitter();

  @Input() public ref!: string;
  @Input() public currentValueLabel!: string;
  @Input() public labelField!: string;
  @Input() public valueField!: string;
  @Input() public options!: any[];
  @Input() public currentValue!: any;
  @Input() public defaultValue!: any;
  @Input() public displayListOnly: boolean = false;

  public expandIcon: 'expand_more' | 'expand_less' = 'expand_more';

  public selectOption(value: any): void {
    this.currentValue = value;
    this.handleSelectedOption.emit(value);
  }
}
