import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldsBase } from './fields-base';
import { DEFAULT_MAX } from '../input-count/input-count.const';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styles: [
    `
      :host(app-dynamic-form) {
        display: contents;
      }
    `,
  ],
})
export class DynamicFormComponent {
  @Input() form!: FormGroup;
  @Input() field!: FieldsBase<string>;
  @Input() elementID!: string;
  public messageError = '';
  public defaultMax = DEFAULT_MAX.toString();
  get error() {
    let error = '';
    this.field.listErrors
      ? this.field.listErrors.forEach((item: string) => {
          if (this.form.errors && !error) {
            error = this.form.errors[item];
          }
        })
      : '';
    return error;
  }
  get classComponent() {
    let classComp;
    if (this.field.typeShowError === 'icon') {
      classComp = this.error
        ? `${this.field.classComponent} pr-2`
        : this.field.classComponent;
    } else {
      classComp = this.field.classComponent;
    }
    return classComp || '';
  }
}
