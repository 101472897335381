export const periodsEvolution = [
  { id: 'daily', label: 'Diário' },
  { id: 'week', label: 'Semanal' },
  { id: 'month', label: 'Mensal' },
];

export const periodsEvolutionFuture = [
  { id: 'daily', label: 'Diário' },
  { id: 'semana', label: 'Semanal' },
  { id: 'mes', label: 'Mensal' },
];

export const periods = [
  { id: 'oneweek', label: '1 Semana' },
  { id: 'week', label: 'Na semana' },
  { id: 'onemonth', label: '1 Mês' },
  { id: 'month', label: 'No mês' },
  { id: 'oneyear', label: '12 Meses' },
  { id: 'year', label: 'No ano' },
];

export const periodFields = {
  oneweek: 'investor_acumulated_1week',
  onemonth: 'investor_acumulated_1month',
  oneyear: 'investor_acumulated_1year',
  week: 'investor_chart_daily',
  month: 'investor_chart_month',
  year: 'investor_acumulated_year',
};
