<div
  [id]="ref + '_annotation-tooltip'"
  class="app-tiger-chart-annotation-tooltip"
  [ngClass]="{ 'opacity-0': !_isVisible }"
  [style.top]="top + 'px'"
  [style.left]="left + 'px'"
>
  <ng-container *ngFor="let item of label">
    <div class="d-flex flex-column align-items-center gap-3">
      <span class="w-100" [innerHTML]="item"></span>
      <ng-container *ngIf="isExpanded">
        <div
          *ngIf="typeButtons && typeButtons.cod === 'THUMBS'"
          class="w-100 d-flex gap-2 justify-content-end"
        >
          <fla-icon
            css="cursor-pointer text-brand-primary"
            name="thumb_up"
            size="icon-size-micro"
            iconType="icons-outlined"
            (click)="buttonAnnotationClick('THUMBS_UP')"
          >
          </fla-icon>
          <fla-icon
            css="cursor-pointer text-feedback-error"
            name="thumb_down"
            size="icon-size-micro"
            iconType="icons-outlined"
            (click)="buttonAnnotationClick('THUMBS_DOWN')"
          >
          </fla-icon>
        </div>
        <div
          *ngIf="typeButtons && typeButtons.isTradeIdea"
          class="w-100 d-flex flex-column"
        >
          <div class="mb-3 d-flex">
            <span>Preço entrada: {{ priceTradeIdea }}</span>
          </div>
          <app-table-trade-idea-items
            [items]="typeButtons.tradeIdea.strategy.items"
            [isSmall]="true"
            [isMediumBorder]="true"
          ></app-table-trade-idea-items>
        </div>
        <div class="d-flex justify-content-end w-100">
          <div class="button-width-max d-flex">
            <div *ngIf="typeButtons && typeButtons.hasQtty" class="w-100">
              <app-input-count
                [formControl]="qttyControl"
                labelPlacement="horizontal"
                [isSmall]="true"
                margin="mb-0"
                label="Qtd:"
                [allowNegativeValue]="false"
                [incrementValue]="standardLot"
                [initialValue]="negotiationLot"
                [min]="standardLot"
                paddingSmallCustom="py-1"
                mask="separator.0"
                [enableScrollEvent]="true"
                [selectOnFocus]="true"
                css="margin-label"
              ></app-input-count>
            </div>
            <div
              tooltip="Alterar parâmetros"
              tooltipPosition="top"
              class="px-1"
              *ngIf="typeButtons && typeButtons.isTradeIdea"
            >
              <rocket-button
                css="fs-7 min-width-0"
                [isSmall]="true"
                type="outline"
                (rocketClick)="
                  buttonAnnotationClick('UPDATE_PARAMS_TRADE_IDEA')
                "
              >
                <fla-icon css="mr-0" name="edit" size="icon-size-sm"></fla-icon
              ></rocket-button>
            </div>
            <div
              *ngIf="typeButtons && typeButtons.cod === 'BUTTON'"
              class="w-50 d-flex gap-2 justify-content-end"
            >
              <rocket-button
                css="fs-7 min-width-0"
                [isSmall]="true"
                [label]="typeButtons.buttonLabel"
                (rocketClick)="buttonAnnotationClick('BUTTON_CLICK')"
              ></rocket-button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
