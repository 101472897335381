import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { VIEW_SCREENING } from './constants/constants';
import {
  IScreeningFilter,
  TViewScreening,
} from './interfaces/indicator.interface';
import { HomeService } from '@modules/home/service/home.service';
import { GRAPHIC_SCREENING_COMPONENT } from '@core/workspace/constants/components/graphic_screening';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-stock-screening',
  templateUrl: './stock-screening.component.html',
  styleUrls: ['./stock-screening.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockScreeningComponent
  extends RocketComponentBase
  implements OnInit
{
  @Input() component!: any;
  public filters!: string;
  public readonly viewScreening = VIEW_SCREENING;
  public tabSelect: TViewScreening = VIEW_SCREENING[0] as TViewScreening;

  constructor(
    private customPreferencesService: CustomPreferencesService,
    private homeService: HomeService,
    private cdr: ChangeDetectorRef,
    activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  protected initComponent(component: any): void {
    if (component) this.component = component;
    this.filters = this.customPreferencesService.screeningFilters;
    this.cdr.detectChanges();
  }

  public saveFilters(filters: string): void {
    this.customPreferencesService.screeningFilters = filters;
    this.filters = filters;
    this.cdr.detectChanges();
  }

  public updateComponent(component: any): void {
    this.component.metadata.component = component;
    this.homeService.updateComp(this.component);
    this.cdr.detectChanges();
  }

  public openGraphic(filters: {
    filter: IScreeningFilter;
    filterString: string;
  }): void {
    const component = GRAPHIC_SCREENING_COMPONENT;
    component.link = false;
    component.data = {
      filters,
    };
    this.homeService.addComponent(component);
    this.cdr.detectChanges();
  }
}
