import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RTInputCountComponent } from './input-count.component';

import {
  FlaIconModule,
  RocketButtonModule,
} from '@shared/rocket-components/components/index';
import { FlaMaskModule } from '../directives/mask';

@NgModule({
  declarations: [RTInputCountComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlaIconModule,
    FlaMaskModule.forChild(),
    RocketButtonModule,
  ],
  exports: [RTInputCountComponent],
  providers: [],
})
export class RTInputCountModule {}
