import { Injectable } from '@angular/core';
import { ScreeningService } from '@shared/services/api/trademap/v1/screening.service';
import { Observable } from 'rxjs';
import { StockServiceRT } from '@shared/services/api/nitro-ws/v1/stock.service';
import { TData } from '@shared/tiger-chart/types/tiger-chart.types';

@Injectable()
export class GraphicScreeningService {
  constructor(
    private screeningService: ScreeningService,
    private stockServiceRT: StockServiceRT
  ) {}

  public createAndRemove(indicator: string): Observable<any> {
    return this.screeningService.save({ json: indicator });
  }

  getIntervalGraphic = (
    stock: string,
    idExchange: number,
    interval: number
  ): Observable<TData> => {
    return this.stockServiceRT.getPriceHistory(
      stock,
      interval,
      0,
      idExchange,
      500
    );
  };

  backtest = (jsonFilter: string): Observable<any> => {
    return this.screeningService.backtest(jsonFilter);
  };
}
