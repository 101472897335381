import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { generateLayoutWorkspace } from '@core/workspace/layout/functions/functions';
import { ComponentsService } from '@shared/services/api/nitro-ws/v1/components.service';
import { WorkspaceApi } from '@shared/services/api/nitro-ws/workspace/workspace-api.service';
import { take, delay, filter, tap, auditTime } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';
import { GlobalStockPreferences } from '../custom-preferences/global-stock/global-stock-preferences.service';
import { IntrojsService } from '@core/introjs/introjs.service';
import { WorkspacesPreferences } from '../custom-preferences/workspace';
import { HomeService } from '@modules/home/service/home.service';
import { WorkspaceComponentService } from '@modules/home/service/workspace-component.service';
import { IWorkSpace, IWorkSpaceComponet } from '@core/interface';
import { deepClone } from '@shared/rocket-components/utils';
import { TermsService } from '@shared/services/api/terms/v2/terms.service';
import { AccountPreferences } from '../custom-preferences/account/account-preferences.services';
import { system } from '@core/system/system.service';
import { JoystickService } from '@shared/services/joystick.service';
import { isWebViewContext } from 'src/app/desktop/integration.utils';
import { TradeTokenPopupService } from '@core/layout/header/trade-token-popup/trade-token-popup.service';
import { OtpService } from '@shared/services/api/authentication/v3/otp.service';
import { PartnersService } from '../partners-services/partners.service';
import { RocketModalService } from '@shared/rocket-components/components';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';

@Injectable({
  providedIn: 'root',
})
export class InitializeWsService extends WorkspaceApi {
  private _tourSubs!: Subscription;
  private _subscription = new Subscription();
  private _tourTimeout: number = 3000;
  private _isNewUser: boolean = false;
  private firstValidityToken: boolean = false;
  private _modalService = inject(RocketModalService);

  get displayWelcomeModal() {
    const response = this._accountPreference.displayTMWelcomeModal;
    if (response === '1') return true;
    if (response === '0') return false;
    return null;
  }

  get shouldDisplayWelcomeModal() {
    const partner = this._partnerService.partner;
    return !partner || partner.key === 'trademappro';
  }

  constructor(
    http: HttpClient,
    private components: ComponentsService,
    private _globalStockPreferences: GlobalStockPreferences,
    private _introJSService: IntrojsService,
    private _workspacesPreferences: WorkspacesPreferences,
    private _homeService: HomeService,
    private workspaceComponentService: WorkspaceComponentService,
    private _termsService: TermsService,
    private _accountPreference: AccountPreferences,
    private _otpService: OtpService,
    private _tradeTokenService: TradeTokenPopupService,
    private joystickService: JoystickService,
    private _partnerService: PartnersService,
    private customPreferencesService: CustomPreferencesService
  ) {
    super(http);
  }

  private getComponents = (workspaces: any[]) => {
    return workspaces.reduce((acc: any, curr: any) => {
      acc.push(
        generateLayoutWorkspace(
          curr,
          this._globalStockPreferences.globalStockSelected
        ).getComponents()
      );
      curr.components = deepClone(acc[acc.length - 1]);
      curr.components.forEach((component: IWorkSpaceComponet) => {
        component.metadata = JSON.parse(<any>component.metadata);
      });
      return acc;
    }, []);
  };

  public initialize(isNewUser: boolean) {
    const termsSub = this._termsService
      .verifyTermStatus(1)
      .subscribe((status) => {
        if (status === 'AWAIT_FOR_LIBERATION') {
          this._isNewUser = isNewUser;
          this._generateWsAfterAcceptTerms();
          return;
        }
        this.generateWorkspaces(isNewUser);
      });
    this._subscription.add(termsSub);
  }

  private _generateWsAfterAcceptTerms(): void {
    const onAcceptSub = this._termsService.onTermsAccepted$
      .pipe(filter((accept) => accept))
      .subscribe(() => {
        this.generateWorkspaces(this._isNewUser);
        this._subscription.add(onAcceptSub);
        this._subscription.unsubscribe();
      });
  }

  private _shouldCreateWorkspaceComponents(workspaces: IWorkSpace[]): boolean {
    if (workspaces.length !== 3) return false;
    return workspaces.every((ws) => !ws?.components?.length);
  }

  generateWorkspaces(firstAccess: boolean): void {
    let newUser = firstAccess;
    this.getAllWorkspaces(isWebViewContext() ? 'PLATAFORMA' : 'WEB')
      .pipe(
        tap((workspaces) => {
          this.subJoystick();
          if (!firstAccess)
            newUser = this._shouldCreateWorkspaceComponents(workspaces);
          if (!newUser) {
            this.workspaceComponentService.setWorkspaces(workspaces);
            this._handleProcesAfterGenerateComponents(false);
          }
          this._validityTradeToken();
        }),
        filter(() => newUser)
      )
      .subscribe((workspaces: any) => {
        this.components
          .createComponents(this.getComponents(workspaces).flat())
          .subscribe((components: IWorkSpaceComponet[]) => {
            this._generateInitialWorkspacesAndComponents(
              workspaces,
              components
            );
            this._handleProcesAfterGenerateComponents(true);
          });
      });
  }

  private subJoystick() {
    this.joystickService
      .onSimulatorActivate()
      .pipe(
        filter(() => this.firstValidityToken),
        auditTime(200)
      )
      .subscribe((data) => {
        if (!data) {
          this._validityTradeToken();
        } else {
          this._otpService.activateAuthenticatorConfig$.next({
            isForce: true,
          });
        }
      });
  }

  private _generateInitialWorkspacesAndComponents(
    workspaces: IWorkSpace[],
    components: IWorkSpaceComponet[]
  ): void {
    const workspaceOrder: string[] = [];
    workspaces.forEach((ws: IWorkSpace) => {
      const wsComponent = components.filter((cp) => ws.id === cp.workspace_id);
      ws.components = wsComponent?.length ? wsComponent : [];
      this.workspaceComponentService.createdOrUpdateWorkspace(ws, ws.active);
      workspaceOrder.push(ws.id);
    });
    this.workspaceComponentService.setWorkspaces(workspaces);
    this._workspacesPreferences.visibleWorkspaceOrder = workspaceOrder;
  }

  private _startWorkspaceTour = (isNewUser: boolean): void => {
    if (!this._workspacesPreferences.displayInitialTour && !isNewUser) return;
    this._workspacesPreferences.displayInitialTour = true;
    this._welcomeTour();
  };

  private _subInTourObservable = () => {
    this._tourTimeout = 1500;
    this._tourSubs = this._workspacesPreferences
      .onCanDisplayTourChange()
      .pipe(
        filter((display) => display),
        take(1)
      )
      .subscribe(() => this._welcomeTour());
  };

  private _welcomeTour(): void {
    of(null)
      .pipe(
        take(1),
        delay(this._tourTimeout),
        filter(() => {
          const canDisplay =
            this._modalService.isDisplayingSomeModal ||
            this._workspacesPreferences.waitToDisplayInitialTour;
          if (canDisplay) {
            this._subInTourObservable();
            return false;
          }
          return true;
        })
      )
      .subscribe(() => {
        this._introJSService.onboardingIntro();
        this._introJSService
          .onStart()
          .subscribe((params: { step: number; concluded: boolean }) => {
            if (this._tourSubs) this._tourSubs.unsubscribe();
            if (
              params.concluded ||
              params.step >= 2 ||
              this.customPreferencesService.countInitializedByTour === '2'
            ) {
              this._workspacesPreferences.displayInitialTour = false;
              return;
            }
            this._workspacesPreferences.displayInitialTour =
              !params.concluded || params.step < 2;
          });
      });
  }

  private _handleProcesAfterGenerateComponents(isNewUser: boolean): void {
    this._homeService.workspaceComponentsIsInitialized = true;
    if (
      this.shouldDisplayWelcomeModal &&
      (this.displayWelcomeModal || this._displayWelcomeModal(isNewUser))
    ) {
      this._openWelcomeModal();
      return;
    }
    this._partnerService.verifyPartnerAfterLogin();
    this._startWorkspaceTour(isNewUser);
  }

  private _displayWelcomeModal(isNewUser: boolean): boolean {
    if (this.displayWelcomeModal || !isNewUser) return false;
    return (
      this.displayWelcomeModal === null &&
      !system.userAuthenticated.investor.isSessionWeak
    );
  }

  private _openWelcomeModal(): void {
    this._partnerService.openWelcomeModal(true);
    this._accountPreference.displayTMWelcomeModal = false;
    this._workspacesPreferences.waitToDisplayInitialTour = true;
    this._subInTourObservable();
  }

  private _validityTradeToken() {
    this.firstValidityToken = true;
    this._tradeTokenService.validityTradeToken();
  }
}
