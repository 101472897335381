import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-remove-stock-button',
  template: `
    <ng-container *ngIf="displayRemoveStock">
      <div
        class="align-items-center d-flex justify-content-center pointer remove-stock"
        (click)="removeStock.emit()"
      >
        <fla-icon
          name="close"
          size="icon-size-nano"
          css="text-feedback-error"
        ></fla-icon>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .remove-stock {
        z-index: 3;
        position: relative;
        cursor: pointer;
        width: 14px !important;
        height: 14px;
        float: right;
        padding-left: 1px;

        &:hover,
        :active {
          background-color: var(--vm-neutral-strongest);
          border-radius: 50%;
          padding-left: 1px;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveStockButtonComponent implements OnChanges {
  @Output() public removeStock = new EventEmitter<void>();
  @Input() public displayRemoveStock: boolean = false;

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { displayRemoveStock } = changes;
    if (!isNullOrUndefined(displayRemoveStock?.currentValue))
      this._cdr.detectChanges();
  }
}
