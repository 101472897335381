import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';

import { CHART_COLORS } from '@shared/tiger-chart/colors';
import {
  TData,
  TTooltipIndicatorValue,
  TUpdateData,
} from '@shared/tiger-chart/types/tiger-chart.types';
import {
  AveragePriceData,
  BaseIndicator,
  TigerChartIndicatorAveragePriceOptions,
  TTigerChartIndicatorCreateOptions,
  TTigerChartIndicatorParameter,
  TTigerChartIndicatorRenderSeriesConfig,
} from '../indicators.types';
import {
  TIGER_INDICATORS_ENUM,
  TIGER_INDICATOR_PARAMETER_TYPE,
} from '@shared/tiger-chart/enum/tiger-chart.enum';
import { Subject } from 'rxjs';
import { TalibService } from '@shared/tiger-chart/services/talib.service';
import { XyyScaleOffsetFilter } from 'scichart/Charting/Model/Filters/XyyScaleOffsetFilter';
import { XyDataSeries } from 'scichart/Charting/Model/XyDataSeries';
import { FastLineRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastLineRenderableSeries';
import { TIGER_INDICATOR_PRECISIONS } from '../indicators.constants';
import { setDefaultConfiguration } from '../indicators.functions';

export class AveragePriceBase implements BaseIndicator {
  protected lineDataSeries!: XyDataSeries;
  protected movingAverageDataSeries!: XyyScaleOffsetFilter;
  protected lineRenderableSeries!: FastLineRenderableSeries;
  protected options!: TigerChartIndicatorAveragePriceOptions;
  protected data: TData;
  protected precision: number = 2;
  protected points: AveragePriceData;
  protected lineColor = CHART_COLORS.FEEDBACK_WARNING;
  protected lineThickness = 1;

  private baseChart!: TWebAssemblyChart;
  lineNumber!: string;
  type: TIGER_INDICATORS_ENUM = TIGER_INDICATORS_ENUM.AVERAGE_PRICE;
  yAxisId!: string;
  settings: TTigerChartIndicatorParameter[] = [];
  styles: TTigerChartIndicatorParameter[] = [];
  renderSeriesConfig: TTigerChartIndicatorRenderSeriesConfig[] = [];
  onChange = new Subject<null>();
  service: TalibService;
  mainLineId = '';
  isNewOnChart = false;

  get isVisible(): boolean {
    return this.lineRenderableSeries.isVisible;
  }

  set isVisible(visible: boolean) {
    this.lineRenderableSeries.isVisible = visible;
  }

  get propertiesText(): string {
    return ``;
  }

  constructor(options: TigerChartIndicatorAveragePriceOptions) {
    this.data = options.data;
    this.service = options.service;
    this.precision = options.tickSize;
    this.options = options;
    if (options.lineColor) this.lineColor = options.lineColor;
    if (options.precision) this.precision = options.precision;
    if (options.lineThickness) this.lineThickness = options.lineThickness;
    this.mainLineId = `${this.type}-line-average-price`;

    this.points = {
      output: [],
    };
    this.renderSeriesConfig = [
      {
        label: 'Linha',
        id: this.mainLineId,
        color: this.lineColor,
        propertyColor: 'lineColor',
        thickness: this.lineThickness,
        propertyThickness: 'lineThickness',
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
      },
    ];
    this.settings = [];
    this.styles = [
      {
        type: TIGER_INDICATOR_PARAMETER_TYPE.SELECTION,
        property: 'precision',
        label: 'Precisão',
        values: TIGER_INDICATOR_PRECISIONS,
      },
    ];
    this.lineNumber = options.lineNumber;
  }

  create(options: TTigerChartIndicatorCreateOptions): BaseRenderableSeries[] {
    this.baseChart = options.base;

    const xValues = this.data.id_point;
    this.lineDataSeries = new XyDataSeries(this.baseChart.wasmContext);
    this.lineDataSeries.appendRange(xValues, [...this.points.output.slice(0)]);
    return this.renderSeriesConfig.map((config) => {
      this.lineRenderableSeries = new FastLineRenderableSeries(
        this.baseChart.wasmContext,
        {
          strokeThickness: config.thickness,
          stroke: config.color,
          dataSeries: this.lineDataSeries,
          id: `${config.id}_${this.lineNumber}`,
          xAxisId: options.xAxisId,
        }
      );

      return this.lineRenderableSeries;
    });
  }

  updatePoints(): void {
    //do nothing.
  }

  append(xValue: number, data: TUpdateData, fullData: TData) {
    this.data = fullData;
    this.updatePoints();

    this.lineDataSeries.append(
      xValue,
      this.points.output[this.points.output.length - 1]
    );
  }

  insertRange(xValues: number[], fullData: TData): void {
    this.data = fullData;
    this.updatePoints();

    const s = this.baseChart.sciChartSurface.suspendUpdates();
    const arrayLength = xValues.length;
    try {
      this.lineDataSeries.insertRange(0, xValues, [
        ...this.points.output.slice(0, arrayLength),
      ]);

      const indexInitialValue = 0;
      for (
        let index = indexInitialValue;
        index < this.points.output.length;
        index++
      ) {
        this.lineDataSeries.update(index, this.points.output[index]);
      }
    } finally {
      s.resume();
    }
  }

  changeVisibility(): void {
    this.lineRenderableSeries.isVisible = !this.lineRenderableSeries.isVisible;
  }

  update(index: number, data: TUpdateData, fullData: TData) {
    this.data = fullData;
    this.updatePoints();

    this.lineDataSeries.update(
      index,
      this.points.output[this.points.output.length - 1]
    );
  }

  updateSettings(): void {
    const s = this.baseChart.sciChartSurface.suspendUpdates();
    const dataSeriesCount = this.lineDataSeries.count();

    const lineValues = [
      ...this.points.output.slice(0, this.data.id_point.length),
    ];

    if (dataSeriesCount > lineValues.length) {
      this.lineDataSeries.removeRange(
        lineValues.length - 1,
        dataSeriesCount - lineValues.length
      );
    }

    try {
      lineValues.forEach((linePointValue, index) => {
        this.lineDataSeries.update(index, lineValues[index]);
      });
    } finally {
      s.resume();
    }
    this.onChange.next(null);
  }

  updateStyles(
    baseChart: TWebAssemblyChart,
    config: TTigerChartIndicatorRenderSeriesConfig
  ): void {
    const hashFunctions: any = {
      [this.mainLineId]: () => {
        this.lineRenderableSeries.stroke = config.color;
        this.lineRenderableSeries.strokeThickness = config.thickness;
        this.lineRenderableSeries.isVisible = config.active;
        const lineRenderSeries =
          baseChart.sciChartSurface.renderableSeries.getById(
            `${this.type}-line-average-price_${this.lineNumber}`
          );
        lineRenderSeries.stroke = config.color;
        lineRenderSeries.strokeThickness = config.thickness;
        lineRenderSeries.isVisible = config.active;
      },
    };
    const func = hashFunctions[config.id];
    if (func) {
      func();
    }
    this.onChange.next(null);
  }

  delete() {
    this.baseChart.sciChartSurface.renderableSeries.remove(
      this.lineRenderableSeries
    );
  }

  setValue(property: keyof this, value: any) {
    const prop = this[property];
    if (typeof this[property] == 'number') {
      this[property] = +value as unknown as typeof prop;
    } else {
      this[property] = value;
    }
    this.updateSettings();
  }

  getValuesByIndex(index?: number): TTooltipIndicatorValue[] {
    const precision = this.precision;
    const lineValues = this.lineDataSeries.getNativeYValues();
    const lineValue = {
      value: lineValues.get(index || lineValues.size() - 1),
      precision,
      color: this.renderSeriesConfig[0].color,
    };
    return [lineValue];
  }

  resetConfiguration(): void {
    setDefaultConfiguration(
      this,
      this.baseChart,
      this.options,
      this.settings,
      this.renderSeriesConfig,
      this.styles,
      this.type
    );
  }
}
