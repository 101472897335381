import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FlaUnsubscribe } from '@shared/rocket-components/decorators';
import { removeMask, MESSAGES } from '@shared/rocket-components/utils';
import { AuthService } from '@services/auth.service';
import {
  CLEANNER_LOGOUT_MESSAGE,
  LOGOUT_MESSAGE,
} from '@services/core/const/local-storage.const';
import { StorageUtils } from '@services/core/storage/storage.utils';
import { GLOBAL_STOCK_SELECTED } from '@services/core/subscription/const/events-name';
import {
  auditTime,
  catchError,
  filter,
  of,
  Subject,
  Subscription,
  takeUntil,
} from 'rxjs';
import { isCPF } from 'src/app/utils/utils.functions';
import { ActivatedRoute, Router } from '@angular/router';
import { OnboardService } from '@shared/services/api/trademap/onboard';
import {
  RECOVERY_STORAGE,
  ONBOARD_STORAGE,
} from '@shared/services/api/trademap/onboard/data/index';
import { RecaptchaComponent } from '../register/recaptcha/recaptcha.component';
import {
  AUTH_PARTNER_NICKNAME,
  AVAILABLE_PARTNERS_ENUM,
  AVAILABLE_PARTNERS_TO_LINK,
  PARTNER_CUSTOM_CONTENT,
  PARTNER_WITH_CUSTOM_LAYOUT,
} from '@shared/services/core/partners-services/partners.const';
import { PartnersService } from '@shared/services/core/partners-services/partners.service';
import { AdjustService } from '@shared/services/api/adjust/v1/adjust.service';
import { sharedSessionStorage } from '@shared/services/core/storage/shared-session.storage';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  loginForm!: FormGroup;
  isLoading = false;
  showPassword = false;
  requiredText: string = MESSAGES.requiredText;
  public invalidUserInfos!: string;
  public usernameFieldLabel: string = 'E-mail, CPF ou Celular';
  public usernameFieldPlaceholder: string = 'name@example.com';
  public blockedPassword: boolean = false;
  public displayCreateAccount: boolean = true;
  private validityForm$ = new Subject<void>();
  private _onDestroy = new Subject<void>();
  private _validityForm$!: Subscription;
  @ViewChild('recaptcha') recaptcha!: RecaptchaComponent;
  @FlaUnsubscribe
  untilDestroy$: Subscription[] = [];

  get passControl() {
    return this.loginForm.get('password')!;
  }
  get userControl() {
    return this.loginForm.get('username')!;
  }
  get isLightMode() {
    return false;
  }

  get logoutMessage() {
    return (
      this.cleanerLogoutMessage ?? localStorage.getItem(LOGOUT_MESSAGE) ?? false
    );
  }

  recoveryPassMessage!: string | null | undefined;

  onboardCompletedMessage!: string | null | undefined;

  get cleanerLogoutMessage() {
    return localStorage.getItem(CLEANNER_LOGOUT_MESSAGE);
  }
  callAuth = false;

  private _adjustService = inject(AdjustService);
  constructor(
    private _auth: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private onboardService: OnboardService,
    private _cdr: ChangeDetectorRef,
    private _partners: PartnersService
  ) {}

  ngOnInit() {
    this._clearAllBeforeInit();
    this._initializeAuthForm();
    this._verifyHasPartners();
  }

  get isFormInvalid() {
    return (
      !this.loginForm.pristine &&
      (this.loginForm.invalid ||
        this.isLoading ||
        this.loginForm.get('password')?.value === '' ||
        this.loginForm.get('username')?.value === '')
    );
  }

  ngAfterViewInit(): void {
    this.loginForm.updateValueAndValidity();
    this.untilDestroy$.push(
      this.loginForm.valueChanges
        .pipe(filter(() => this.callAuth))
        .subscribe(() => {
          this.callAuth = false;
          this.userControl.setErrors(null);
          this.passControl.setErrors(null);
          this.requiredText = MESSAGES.requiredText;
        })
    );
    this.validityForm$.next();
  }

  ngOnDestroy(): void {
    this._validityForm$ && this._validityForm$.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private _initializeAuthForm(): void {
    this.loginForm = this.fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
      recaptcha: [''],
    });

    this._validityForm$ = this.validityForm$
      .pipe(auditTime(500), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.userControl.updateValueAndValidity();
        this._cdr.detectChanges();
      });
  }

  openForgotPass = () => this.router.navigateByUrl('auth/reset/password');

  doLogin() {
    if (!this.userControl.value || !this.passControl.value) {
      this._displayInvalidUserError('Preencha os dados');
      return;
    }
    if (isCPF(this.userControl.value)) {
      this.userControl.setValue(removeMask(this.userControl.value));
    }

    if (this.loginForm.valid && !this.isLoading) {
      this.isLoading = true;
      this.callAuth = true;
      this._auth
        .authenticate(this.loginForm.value)
        .pipe(
          catchError((err) => {
            this.isLoading = false;
            this.blockedPassword = err.code === 'BLOCKED_PASSWORD';
            this._displayInvalidUserError(err.message);
            return of(err);
          })
        )
        .subscribe({
          next: () => {
            this.isLoading = false;
            this._cdr.detectChanges();
          },
        });
    }
  }

  setMessageByRecoveryStatus() {
    const recoverystatus = StorageUtils.getItemSessionStorage(
      RECOVERY_STORAGE.RECOVERYSTATUS
    );

    const onboardstatus = StorageUtils.getItemSessionStorage(
      ONBOARD_STORAGE.ONBOARD_STATUS
    );

    this.recoveryPassMessage =
      this.onboardService.getRecoveryMessageByStatus(recoverystatus);
    this.onboardCompletedMessage =
      this.onboardService.getOnboardMessageByStatus(onboardstatus);
  }

  removeLogoutMessage() {
    localStorage.removeItem(
      this.cleanerLogoutMessage ? CLEANNER_LOGOUT_MESSAGE : LOGOUT_MESSAGE
    );
  }

  private _clearAllBeforeInit() {
    this.removeLogoutMessage();
    localStorage.removeItem(GLOBAL_STOCK_SELECTED);
    this.setMessageByRecoveryStatus();
    sharedSessionStorage.clear();
  }

  goToRegister = () => {
    this.router.navigateByUrl('auth/register/phone');
  };

  private _setInvalidInputs(): void {
    this.userControl.setErrors({ invalid: true });
    this.passControl.setErrors({ invalid: true });
  }

  private _displayInvalidUserError(message: string): void {
    this.invalidUserInfos = message.includes('string')
      ? 'Usuário ou senha incorretos'
      : message;
    this.requiredText = '';
    this._setInvalidInputs();
    this._cdr.detectChanges();
  }

  private _verifyHasPartners() {
    this.route.params.pipe(takeUntil(this._onDestroy)).subscribe((data) => {
      if (data?.['partner']) this._setBrokerPartner(data['partner']);
      if (data?.['id']) this._verifyAndSaveInfluencerID(data['id']);
    });
  }

  private _setBrokerPartner(partner: AVAILABLE_PARTNERS_ENUM): void {
    if (!partner || !AVAILABLE_PARTNERS_TO_LINK[partner]) return;
    this._partners.partnerBeforeAuth = {
      type: 'BROKER',
      key: partner,
      name: AUTH_PARTNER_NICKNAME[partner],
    };
    this._verifyPartnerHasCustomLayout(partner);
  }

  private _verifyAndSaveInfluencerID(id: string): void {
    const influencerID = parseInt(id.replace(/\D/g, ''));
    if (!influencerID) {
      this.router.navigateByUrl('/login');
      this._adjustService.clearInfluencerStorageIfExists();
      return;
    }
    this._adjustService.influencerID = String(influencerID);
  }

  private _verifyPartnerHasCustomLayout(
    partner: AVAILABLE_PARTNERS_ENUM
  ): void {
    if (!partner) return;
    if (PARTNER_WITH_CUSTOM_LAYOUT[partner]) {
      this.displayCreateAccount = false;
      const content = PARTNER_CUSTOM_CONTENT[partner];
      if (content.usernameInputLabel)
        this.usernameFieldLabel = content.usernameInputLabel;
      if (content.usernameInputPlaceholder)
        this.usernameFieldPlaceholder = content.usernameInputPlaceholder;
    }
  }

  onResolved(token: string) {
    this.loginForm.controls['recaptcha'].setValue(token);
    this.doLogin();
  }

  onError() {
    this.invalidUserInfos =
      'Ocorreu um erro ao validar o reCAPTCHA, tente novamente.';
    this.loginForm.controls['recaptcha'].setValue(null);
    this._cdr.detectChanges();
  }

  makeRecaptchaExecute() {
    this.recaptcha.execute();
  }

  allowCaracters(control: FormControl) {
    const inputValue = control.value;
    if (!inputValue) return;
    const regex = /^\S*$/; // N permite espaço
    if (!regex.test(inputValue)) {
      control.setValue(inputValue.replace(/ /g, '')); // Remove caracteres que sao espaços
    }
    this._cdr.detectChanges();
  }
}
