import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'draw-helper-message',
  templateUrl: './draw-helper-message.component.html',
  styleUrls: ['./draw-helper-message.component.scss'],
})
export class DrawHelperMessageComponent implements OnInit {
  message: string = '';
  parentRef: string = '';
  private _onClose$ = new Subject<any>();
  get onClose(): Observable<any> {
    return this._onClose$.asObservable();
  }
  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this._onClose$.complete();
  }
}
