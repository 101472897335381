import { Injectable } from '@angular/core';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { GLOBAL_CHART_CONFIGURATION } from '../interface/stock-chart-modal.interface';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import { GlobalChartConfiguration } from '../../modal-stock/global-chart-configuration';
import {
  EVENTS,
  NEGOTIATIONS,
  SHORTCUTS,
  STYLES,
  TABS,
} from '../const/stock-chart-modal.config.const';
import { ConfigService } from '@core/config';
import { CHART_MODAL_CONFIGS_TAB_NAME } from '@shared/components/stock-chart/enum/stock-chart.enum';

@Injectable({
  providedIn: 'root',
})
export class StockChartModalService {
  private colors: any = {
    colorsVelaDown: CHART_COLORS.FEEDBACK_NEGATIVE,
    colorsVelaUp: CHART_COLORS.FEEDBACK_POSITIVE,
    colorsBorderDown: CHART_COLORS.FEEDBACK_NEGATIVE,
    colorsBorderUp: CHART_COLORS.FEEDBACK_POSITIVE,
    volumeDownColor: CHART_COLORS.FEEDBACK_NEGATIVE + '80',
    volumeUpColor: CHART_COLORS.FEEDBACK_POSITIVE + '80',
  };

  constructor(
    private _customPreferencesService: CustomPreferencesService,
    private configService: ConfigService
  ) {}

  getGlobalChartConfigs(): GlobalChartConfiguration {
    const globalConfigString =
      this._customPreferencesService.chartGlobalConfiguration;
    let globalConfig: GLOBAL_CHART_CONFIGURATION;
    let configuration = new GlobalChartConfiguration();
    if (!isNullOrUndefined(globalConfigString)) {
      globalConfig = JSON.parse(
        globalConfigString
      ) as GLOBAL_CHART_CONFIGURATION;
      configuration = new GlobalChartConfiguration(globalConfig);
    }
    this.checkNullColors(configuration);
    return configuration;
  }

  updateGlobalChartConfigs(configs: GLOBAL_CHART_CONFIGURATION) {
    const configuration = this.getGlobalChartConfigs();
    const mix = Object.assign(configuration, configs);
    this._customPreferencesService.chartGlobalConfiguration =
      JSON.stringify(mix);
  }

  resetColors() {
    const configuration = {
      ...this.getGlobalChartConfigs(),
      ...structuredClone(this.colors),
    };
    this._customPreferencesService.chartGlobalConfiguration =
      JSON.stringify(configuration);
    return configuration;
  }

  checkNullColors(config: any) {
    Object.keys(structuredClone(this.colors)).forEach(
      (item) => !config[item] && (config[item] = this.colors[item])
    );
  }

  getModalTabs() {
    const tabs = TABS;
    if (!this.configService.isBroker()) {
      return tabs;
    }
    const hashTabs: any = {
      [CHART_MODAL_CONFIGS_TAB_NAME.ESTILO]: true,
      [CHART_MODAL_CONFIGS_TAB_NAME.EVENTOS]: true,
    };
    return tabs.filter((tab) => hashTabs[tab.ref]);
  }

  getInputForms() {
    let shortcuts = SHORTCUTS;
    let negotiations = NEGOTIATIONS;
    let events = EVENTS;
    let styles = STYLES;
    if (this.configService.isBroker()) {
      const hashInputsToRemove: any = {
        showPreviousClose: true,
        showExecOrders: true,
        showFlagOrders: true,
        showMinMaxVisible: true,
        showBidAsk: true,
        showTradingChange: true,
        showWatermark: true,
        watermarkOpacity: true,
      };
      shortcuts = SHORTCUTS.filter(
        (input) => !hashInputsToRemove[input.formControlName]
      );
      negotiations = NEGOTIATIONS.filter(
        (input) => !hashInputsToRemove[input.formControlName]
      );
      events = EVENTS.filter(
        (input) => !hashInputsToRemove[input.formControlName]
      );
      styles = STYLES.filter(
        (input) => !hashInputsToRemove[input.formControlName]
      );
    }
    return { shortcuts, negotiations, events, styles };
  }
}
