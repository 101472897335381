import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export interface PasswordValidators {
  passwordLength?: boolean;
  passwordLowerCase?: boolean;
  passwordUpperCase?: boolean;
  passwordNumber?: boolean;
  passwordSpecialChar?: boolean;
  passwordSequentialNumbers?: boolean;
}

export const PasswordValidatorsMessage: any = {
  passwordLength: '8 caracteres',
  passwordLowerCase: '1 Letra Minúscula',
  passwordUpperCase: '1 Letra Maiúscula',
  passwordNumber: '1 Número',
  passwordSpecialChar: '1 Caracter Especial',
  passwordSequentialNumbers:
    'Não pode ter sequência númerica. Ex: (12, 123 ou 21, 321)',
};

export function hasSequentialNumbers(password: string): boolean {
  const sequentialNumbersRegex =
    /^(?=.*\d)(?!.*(?:01|12|23|34|45|56|67|78|89|10|21|32|43|54|65|76|87|98))/;
  return !sequentialNumbersRegex.test(password);
}

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const password = control.value;
    const errors: PasswordValidators = {};

    // Verifica o comprimento da senha
    errors.passwordLength = password.length < 8;

    // Verifica se possui letra minúscula
    errors.passwordLowerCase = !/[a-z]/.test(password);

    // Verifica se possui letra maiúscula
    errors.passwordUpperCase = !/[A-Z]/.test(password);

    // Verifica se possui número
    errors.passwordNumber = !/\d/.test(password);

    // Verifica se possui caractere especial
    errors.passwordSpecialChar = !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
      password
    );

    // Verifica se possui números consecutivos (Teste23)
    errors.passwordSequentialNumbers = password
      ? hasSequentialNumbers(password)
      : true;

    const hasErrors = Object.values(errors).some((value) => value === true);
    return hasErrors ? errors : null;
  };
}

export function confirmPasswordValidator(
  passwordControlName: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const passwordControl = control.root.get(passwordControlName);

    let passwordField = null;
    let reconfirmPass = null;
    if (control.value !== '' && control.value !== null)
      reconfirmPass = control.value;
    if (passwordControl?.value !== '' && passwordControl?.value !== null)
      passwordField = passwordControl?.value;

    if (reconfirmPass !== passwordField) {
      return { confirmPassword: true };
    }
    return null;
  };
}

export function compareIsSameValue(fieldToCompare: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const fieldControl = control.root.get(fieldToCompare);

    if (fieldControl && control.value !== fieldControl.value) {
      fieldControl.setErrors({
        customError: true,
        confirmPassword: true,
      });
      return null;
    }
    fieldControl?.setErrors(null);
    return null;
  };
}
