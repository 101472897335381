<div class="d-flex flex-column h-100">
  <div class="d-flex w-100 justify-content-between">
    <div class="info-stock d-flex flex-column gap-3">
      <div class="d-flex">
        <div class="logo round">
          <ng-container *ngIf="image; else withoutImage">
            <img
              class="round w-100 h-100"
              [src]="image"
              alt="Company Logo"
              (error)="handleImageError()"
            />
          </ng-container>
          <ng-template #withoutImage>
            <div class="align-items-center d-flex h-100 justify-content-center">
              <fla-icon name="business" css="text-dark"> </fla-icon>
            </div>
          </ng-template>
        </div>
        <div class="infos d-flex flex-column pl-2 justify-content-center">
          <div class="cd-stock d-flex">
            <div class="fs-3 fw-bold">{{ _stockSelected.cd_stock }}</div>
            <div class="country-logo mt-11 ml-2">
              <img
                *ngIf="_stockSelected.country_image_url"
                class="w-100 h-100"
                [src]="_stockSelected.country_image_url"
              />
            </div>
          </div>
          <div class="fs-5 text-neutral-medium">
            {{ _stockSelected.nm_company_valemobi }}
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center gap-3">
        <div class="price fs-3 fw-bold">
          {{ lastPriceFormatted(_stockInfo.lastPrice) }}
        </div>
        <div
          [style.color]="_stockInfo.arrow_hex"
          class="d-flex align-items-center variation fs-4 fw-bold"
        >
          <fla-icon
            *ngIf="_stockInfo.variacao_dia > 0"
            name="arrow_upward"
            size="icon-size-sm"
          ></fla-icon>
          <fla-icon
            *ngIf="_stockInfo.variacao_dia < 0"
            name="arrow_downward"
            size="icon-size-sm"
          ></fla-icon>
          <fla-icon
            *ngIf="_stockInfo.variacao_dia === 0"
            name="arrow_forward"
            size="icon-size-sm"
          ></fla-icon>
          {{ _stockInfo.lastVariation }}
        </div>
      </div>
    </div>
    <div class="resumes grid">
      <div
        *ngFor="let item of resumes"
        class="align-items-center d-flex justify-content-center text-center w-100"
      >
        <div
          class="d-flex flex-column align-items-center w-fit"
          [tooltip]="_stockInfo[item.tooltip]"
        >
          <span
            class="text-neutral-medium fw-bold fs-5 d-flex text-center text-nowrap"
            [innerHTML]="item.title"
          ></span>
          <span
            *ngIf="item.title"
            class="fw-bold fs-5 d-flex"
            [innerHTML]="_stockInfo[item.cod] ?? '-'"
          ></span>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex h-100 overflow-auto gap-3 mt-2">
    <ng-container *ngIf="loadingStatus.loadedAllContent; else loadingContent">
      <ng-container *ngIf="!isSotckIndex; else treemap">
        <div class="d-flex flex-column w-50 h-100 overflow-auto">
          <span class="fs-5 fw-bold text-neutral-medium">Documentos</span>
          <ng-container *ngIf="docsToShow.length; else emptyDocs">
            <app-resume-list
              class="d-flex h-100"
              [hasEvents]="true"
              [lists]="docsToShow"
              css="overflow-hidden"
            ></app-resume-list>
          </ng-container>
          <ng-template #emptyDocs>
            <app-no-info
              css="fs-5 my-3"
              text="No momento o ativo não tem documentos recentes"
            ></app-no-info>
          </ng-template>
        </div>
      </ng-container>

      <ng-template #treemap>
        <div class="d-flex flex-column w-50 h-100 overflow-auto distribution">
          <span class="fs-5 fw-bold text-neutral-medium">Distribuição</span>
          <app-distribution
            [stockSelected]="_stockSelected"
            [refComponent]="refComponent"
            [height]="height"
            [width]="width"
          ></app-distribution>
        </div>
      </ng-template>

      <div class="d-flex flex-column w-50 h-100 overflow-auto">
        <span class="fs-5 fw-bold text-neutral-medium">Notícias</span>
        <ng-container *ngIf="news?.length; else emptyNews">
          <app-news-list class="d-flex h-100" [news]="news"></app-news-list>
        </ng-container>
        <ng-template #emptyNews>
          <app-no-info
            css="fs-5 my-3"
            text="No momento o ativo não tem notícias recentes"
          ></app-no-info>
        </ng-template>
      </div>

      <ng-container *ngIf="!isSotckIndex">
        <div
          class="d-flex flex-column w-50 h-100 overflow-auto provents-max-width"
        >
          <div class="w-100 d-inline-flex align-items-center">
            <ng-container *ngFor="let tab of proventsTabs">
              <button
                type="button"
                class="custom-tab-btn btn btn-fill-primary btn-sm fs-5 p-0 text-left"
                [ngClass]="{
                  'text-brand-primary': tab.active,
                  'text-neutral-medium': !tab.active
                }"
                (click)="changeProventsTab(tab.ref)"
                [tooltip]="tab.tooltip"
              >
                {{ tab.title }}
              </button>
            </ng-container>
          </div>

          <ng-container
            *ngIf="proventsTabs[0].active; else nextCorporateEvents"
          >
            <ng-container
              *ngIf="corporateEventsToShow.length; else emptyCorporateEvents"
            >
              <app-resume-list
                class="d-flex h-100"
                [lists]="corporateEventsToShow"
                css="overflow-hidden"
              ></app-resume-list>
            </ng-container>
          </ng-container>

          <ng-template #nextCorporateEvents>
            <ng-container
              *ngIf="proceedsList.length; else emptyCorporateEvents"
            >
              <app-resume-list
                class="d-flex h-100"
                [lists]="proceedsList"
                css="overflow-hidden"
              ></app-resume-list>
            </ng-container>
          </ng-template>

          <ng-template #emptyCorporateEvents>
            <app-no-info
              css="fs-5 my-3"
              [text]="
                proventsTabs[0].active
                  ? 'No momento o ativo não possui histórico de proventos'
                  : 'No momento o ativo não tem pagamento de proventos agendado'
              "
            ></app-no-info>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #loadingContent>
      <div class="w-100 d-flex justify-content-center mt-8 pt-8">
        <div class="spinner-border text-light" role="status"></div>
      </div>
    </ng-template>
  </div>
</div>
