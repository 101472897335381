<div
  class="bg-neutral-stronger p-1 w-100 h-100 overflow-y-auto d-flex flex-column"
>
  <div #rentDetailsHeaderContent>
    <div class="w-100 d-inline-flex align-items-center justify-content-between">
      <rocket-button
        [label]="cdStock"
        type="fill"
        (rocketClick)="onBackToGrid.emit()"
        css="text-white fs-5"
      >
        <fla-icon
          fla-icon
          name="arrow_back_ios"
          css="text-brand-primary"
        ></fla-icon>
      </rocket-button>
      <rocket-button
        label="Ver fundos"
        type="fill"
        (rocketClick)="scrollToFundsTable()"
        css="text-brand-primary fs-6"
        [isSmall]="true"
      >
      </rocket-button>
    </div>
    <app-rent-chart [cdStock]="cdStock"></app-rent-chart>
  </div>

  <div id="funds-table-content" class="h-100">
    <app-funds-table
      [cdStock]="cdStock"
      [heightToAdjust]="headerHeight"
      [isInfinityScroll]="false"
    ></app-funds-table>
  </div>
</div>
