import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { isUserAgentDevice } from 'src/app/utils/utils.functions';

@Injectable()
export class DeviceValidatorService {
  constructor(private platform: Platform) {}

  browserMessage = `
    <i class="icons text-brand-primary mb-5 icon-size-lg">web_asset_off</i>
    <h2 class="mb-5">Olá!</h2>
    <p>Percebemos que você não está utilizando um <b>navegador recomendado</b> para o Rocket Trader</p>
    <p>Para aproveitar todas as nossas <b>funcionalidades</b> recomendamos que o acesso seja feito pelo <b>Google Chrome</b></p>
  `

  public isMobile = () => {
    return (this.platform.ANDROID || this.platform.IOS || isUserAgentDevice().WINDOWSPHONE)
  }

  public isChromium = () => {
    const userAgentData = (window.navigator as any).userAgentData
    const isChromium = !!userAgentData && userAgentData.brands.some((data: any) => data.brand === 'Chromium');
    // const isEdge = !!userAgentData && userAgentData.brands.some((data: any) => data.brand === 'Microsoft Edge');
    return isChromium
  }
}
