import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { ComponentsService } from '@shared/services/api/nitro-ws/v1/components.service';
import { of } from 'rxjs';

export const componentResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot
) => {
  const id = route.paramMap.get('id');
  if (!id) return of(null);
  document.querySelector('body div')?.setAttribute('id', id);
  document.querySelector('body div')?.classList.add('h-100');
  return inject(ComponentsService).getComponentById(id);
};
