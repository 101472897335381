import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchStockComponent } from './search-stock.component';
import { FlaSelectModule } from '@shared/rocket-components/components';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FlaSelectModule],
  declarations: [SearchStockComponent],
  exports: [SearchStockComponent],
})
export class SearchStockModule {}
