import { Injectable } from '@angular/core';
import { AUTH_SESSION_KEYS } from '@services/core/const/auth_util.const';
import { NotificationService } from '@services/core/order/notification.service';
import { BrowserStorageBase } from '@services/core/storage/browser-storage-base';
import {
  CHEETAH_CHANNEL,
  NOTIFICATION_CHEETAH_FIELDS,
} from '@shared/cheetah/cheetah.const';

import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { HEADER_NOTIFICATION_CHEETAH } from '@shared/components/stock-chart/constants/stock-chart.constant';
import { NOTIFICATION_TYPE } from '@shared/constants/notifications.const';
import { BehaviorSubject } from 'rxjs';
import { IAlert } from 'src/app/core/interface';

import { RocketStreamRead, RxEvent } from './rx-event';
import { MarketDispatcher } from '@shared/cheetah/dispatcher/market.dispatcher';
import { AlertService } from '@shared/services/api/trademap/v1/alert.service';
import { sharedSessionStorage } from '@shared/services/core/storage/shared-session.storage';

@Injectable({
  providedIn: 'root',
})
export class NotificationChannel {
  private storage = new BrowserStorageBase(sharedSessionStorage);
  private alerts$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private notificationStream!: RocketStreamRead | undefined;
  static instance: NotificationChannel;
  static INFINITY_READ_STREAM = true;

  _valuesCached: any = {};

  get id_investor() {
    return this.storage.getItem(AUTH_SESSION_KEYS.ID_INVESTOR);
  }

  get subscribeParams(): SubscribeParam {
    return {
      channel_cheetah: CHEETAH_CHANNEL.NOTIFICATION,
      itemsArray: [`${this.id_investor}`],
      fields: NOTIFICATION_CHEETAH_FIELDS,
      header: `${HEADER_NOTIFICATION_CHEETAH}_${this.id_investor}`,
    };
  }

  constructor(
    private _rxEvent: RxEvent,
    private cheetahService: MarketDispatcher,
    private notificationService: NotificationService,
    private alertService: AlertService
  ) {
    return (NotificationChannel.instance =
      NotificationChannel.instance || this);
  }

  private async readStream(stream: ReadableStream) {
    const reader = stream.getReader();
    let ret;
    do {
      ret = await reader.read();
      if (!ret.done) {
        this.dispatchEvents(ret.value.data);
      }
    } while (NotificationChannel.INFINITY_READ_STREAM);
  }

  instanciate = async () => {
    if (this.notificationStream) return;
    this.notificationStream = await this._rxEvent.read(
      CHEETAH_CHANNEL.NOTIFICATION
    );
    this.readStream(this.notificationStream.stream);
  };

  destroyInstanceStream() {
    this.notificationStream && this.notificationStream.close();
    this.notificationStream = undefined;
  }

  onAlerts() {
    return this.alerts$.asObservable();
  }

  dispatchEvents(payload: any) {
    const data = payload.get(`${this.id_investor}`);
    this.notificationService.emit(data);
    if (data.notification_type === NOTIFICATION_TYPE.ALERTA) {
      const alerts: IAlert[] = this.alertService.updateAlertsIndexDB(data);
      data.alerts = alerts;
      this.alerts$.next(data);
    }
  }

  private subscribe(params: SubscribeParam) {
    this.cheetahService.subscribe(params);
  }

  unsubscribe(params: SubscribeParam) {
    this.cheetahService.unsubscribe(params);
  }

  restart = () => {
    this.subscribe(this.subscribeParams);
  };
}
