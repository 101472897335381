import { Subject } from 'rxjs';
import { Point } from 'scichart/Core/Point';
import { ModifierMouseArgs } from 'scichart/Charting/ChartModifiers/ModifierMouseArgs';
import { testIsInBounds } from 'scichart/utils/pointUtil';
import { TIGER_MOUSE_EVENT_DATA_TYPE } from '../enum';
import {
  IXAxisDragModifierOptions,
  XAxisDragModifier,
} from 'scichart/Charting/ChartModifiers/XAxisDragModifier';

export class TigerXAxisDragModifier extends XAxisDragModifier {
  events$ = new Subject<any>();
  constructor(options?: IXAxisDragModifierOptions) {
    super(options);
  }

  makeScaling(pointTo: Point) {
    super.doScaling(pointTo);
  }

  protected override doScaling(pointTo: Point): void {
    this.makeScaling(pointTo);
    this.events$.next({});
  }

  override modifierMouseMove(args: ModifierMouseArgs): void {
    super.modifierMouseMove(args);
    const mousePoint = args.mousePoint;
    this.parentSurface?.xAxes.asArray().forEach((xAxis) => {
      const viewRect = xAxis.viewRect;
      if (!viewRect) {
        return;
      }
      if (
        testIsInBounds(
          mousePoint.x,
          mousePoint.y,
          viewRect.left,
          viewRect.bottom,
          viewRect.right,
          viewRect.top
        )
      ) {
        this.events$.next({
          type: TIGER_MOUSE_EVENT_DATA_TYPE.XAXIS_HOVER,
          data: null,
        });
      } else {
        this.events$.next({});
      }
    });
  }
}
