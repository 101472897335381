import { Injectable } from '@angular/core';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { AccountPreferencesInterface } from './account-preferences.interface';
import { AUTH_LOCAL_KEYS } from '../../const/auth_util.const';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';

@Injectable({
  providedIn: 'root',
})
export class AccountPreferences
  extends CustomPreferencesService
  implements AccountPreferencesInterface
{
  set simulatorSelected(e: boolean) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.SIMULATOR_SELECTED,
      e ? '1' : '0'
    );
  }

  get simulatorSelected() {
    const enableSimulator = this.storage.getItem(
      AUTH_LOCAL_KEYS.SIMULATOR_SELECTED
    );
    if (isNullOrUndefined(enableSimulator)) return true;
    return enableSimulator === '1';
  }

  set simulatorAccountSelected(e: string) {
    JSON.stringify(this.simulatorAccountSelected) !== e &&
      this.setValueCustomPreferences(
        AUTH_LOCAL_KEYS.SIMULATOR_ACCOUNT_SELECTED,
        e
      );
  }

  get simulatorAccountSelected() {
    const result = isNullOrUndefined(
      this.storage.getItem(AUTH_LOCAL_KEYS.SIMULATOR_ACCOUNT_SELECTED)
    )
      ? null
      : JSON.parse(
          this.storage.getItem(AUTH_LOCAL_KEYS.SIMULATOR_ACCOUNT_SELECTED)
        );
    return result;
  }

  set multiBrokerAccountSelected(e: string) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.MULTIBROKER_ACCOUNT_SELECTED,
      e
    );
  }

  get multiBrokerAccountSelected() {
    const result = isNullOrUndefined(
      this.storage.getItem(AUTH_LOCAL_KEYS.MULTIBROKER_ACCOUNT_SELECTED)
    )
      ? null
      : JSON.parse(
          this.storage.getItem(AUTH_LOCAL_KEYS.MULTIBROKER_ACCOUNT_SELECTED)
        );
    return result;
  }

  set displayTMWelcomeModal(value: boolean) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.DISPLAY_TRADEMAP_WELCOME_MODAL,
      value ? '1' : '0'
    );
  }

  // return '1' | '0' or null
  get displayTMWelcomeModal(): any {
    return this.storage.getItem(AUTH_LOCAL_KEYS.DISPLAY_TRADEMAP_WELCOME_MODAL);
  }
}
