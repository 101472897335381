import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { RocketGridComponent } from './rocket-grid.component';

import { RocketGridService } from './service/grid.service';
import { ActionsRowComponent } from './grid-components/actions-row.component';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import {
  FlaButtonCloseModule,
  FlaIconModule,
  FlaListGroupModule,
  FlaTagModule,
  RocketButtonModule,
} from '@shared/rocket-components/components/index';

@NgModule({
  imports: [
    CommonModule,
    AgGridModule,
    FlaTagModule,
    FlaButtonCloseModule,
    TooltipsModule,
    FlaIconModule,
    FlaListGroupModule,
    RocketButtonModule,
  ],
  declarations: [RocketGridComponent, ActionsRowComponent],
  exports: [RocketGridComponent],
  providers: [RocketGridService],
})
export class RocketGridModule {}
