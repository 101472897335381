import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  inject,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, filter } from 'rxjs/operators';
import {
  RocketModalRef,
  RocketModalService,
  ROCKET_MODAL_DATA,
} from '@shared/rocket-components/components';
import { deepClone } from '@shared/rocket-components/utils';
import { execFormatFinancial } from 'src/app/utils/utils.functions';
import {
  IAllStockListSimple,
  IListStockDB,
  ISearchStock,
  IStockListItemsNewList,
} from 'src/app/core/interface';
import { ListStocksService } from '@shared/services/core/list-stocks/list-stocks.service';
import { StockSearchConfigModalComponent } from '@shared/components/stock-search-config-modal/stock-search-config-modal.component';
import { Dictionary } from '@core/models';
import { GridISelectRow } from '@shared/rocket-grid';
import { StockServiceRT } from '@shared/services/api/nitro-ws/v1/stock.service';
import { StockService } from '@shared/services/api/trademap/v1/stock.service';

@Component({
  selector: 'app-new-stock-modal',
  templateUrl: './new-stock-modal.component.html',
  styles: [
    `
      :host {
        ::ng-deep .margin-bottom {
          margin-bottom: 0px !important;
        }

        ::ng-deep .modal-content {
          width: 100% !important;
        }

        ::ng-deep .modal-dialog {
          max-width: 450px !important;
        }

        .custom-suffix {
          top: 14px;
          z-index: 3;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewStockModalComponent
  extends RocketModalRef
  implements OnInit, AfterViewInit
{
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private idStockList: number = 0;
  private listStockInDB!: Array<string>;

  listStocksSelected: Array<any> = [];
  hashListStocksSelected: any = {};
  newStockForm!: FormGroup;
  isLoading = false;
  isStart = false;
  searchedStock: ISearchStock[] = [];
  stockList!: Array<any>;
  private _stocksToRemove = new Dictionary<boolean>();
  modalService!: RocketModalService;
  noData: boolean = false;
  isSearch: boolean = false;
  hasSomeStockFilter: boolean = false;
  private _stockServiceTM = inject(StockService);

  constructor(
    @Inject(ROCKET_MODAL_DATA)
    public data: {
      listSelected: IAllStockListSimple;
      refComponent: string;
      listStocks: Array<IAllStockListSimple>;
      listStockInfo: IListStockDB;
      row: GridISelectRow | undefined;
    },
    service: RocketModalService,
    private _formBuilder: FormBuilder,
    private _stockService: StockServiceRT,
    private _cdr: ChangeDetectorRef,
    private _listStocksService: ListStocksService,
    private _elementRef: ElementRef
  ) {
    super(service);
    this.modalService = service;
    this.createForm();
  }

  ngOnInit(): void {
    this._stocksFilterObservable();
  }

  ngAfterViewInit(): void {
    this.init();
    this.isStart = true;
  }

  private _stocksFilterObservable = (): void => {
    this._stockServiceTM.hasFilterOnSearchStock
      .pipe(
        takeUntil(this.destroy$),
        filter((value) => value !== undefined)
      )
      .subscribe((hasFilter) => (this.hasSomeStockFilter = hasFilter));
  };

  openConfig() {
    this.modalService.open(StockSearchConfigModalComponent, {
      centered: true,
      keyboard: false,
      toggleBetweenModal: this._elementRef,
      data: { isSecondModal: true },
    });
  }

  private init(): void {
    this.idStockList = this.data.listSelected.id_stock_list;
    this.stockList = this.data.listStockInfo.stocks;
    this.checkStockInList();
    this._cdr.detectChanges();
    this.newStockForm.controls['search'].valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe((text: string) => this.getStock(text));
  }

  searchAgain() {
    this.getStock(this.newStockForm.controls['search'].value ?? '');
  }

  private createForm() {
    this.newStockForm = this._formBuilder.group({
      search: new FormControl(undefined),
    });
  }

  private checkStockInList(): void {
    this.listStockInDB = this.stockList.map((stock: any) => stock.cd_stock);
    this.verifyExistStockInList(this.stockList);
  }

  private getStock(cdStock: string): void {
    if (!cdStock || !cdStock.length) {
      this.isSearch = false;
      this.searchedStock = [];
      this.noData = false;
      this._cdr.detectChanges();
      return;
    }
    this.isSearch = true;
    this._stockService
      .searchStock(cdStock, [], false, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: Array<ISearchStock>) => {
        this.noData = !res.length;
        this.verifyExistStockInList(res, true);
      });
  }

  private verifyExistStockInList(
    list: Array<ISearchStock>,
    isSearch: boolean = false
  ): void {
    if (isSearch) {
      this.searchedStock = deepClone(list);
    }
    this._cdr.detectChanges();
  }

  public onClose = (stocks: string[] = []) => {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.close(stocks);
    this._cdr.detectChanges();
  };

  public onSave(): void {
    this.isLoading = true;
    this._cdr.detectChanges();
    const stocks = this.listStocksSelected
      .filter((item) => item.isNew)
      .map((stock: ISearchStock) =>
        this._stockId(
          stock.is_synonymous,
          stock.cd_stock,
          stock.ds_asset,
          stock.id_exchange
        )
      );
    this._listStocksService.addItemStockList$.next({
      stocks,
      rowIndex: this.data.row?.rowIndex,
      removed: <string[]>this._stocksToRemove.keys(),
      idList: this.idStockList,
    });
    this.onClose();
  }

  public selectStock(data: {
    listStocksSelected: any[];
    hashListStocksSelected: any;
    item: IStockListItemsNewList;
  }): void {
    this.listStocksSelected = data.listStocksSelected;
    this.hashListStocksSelected = data.hashListStocksSelected;
    this._setRemovedStocks(data.item);
  }

  private _setRemovedStocks(stock: IStockListItemsNewList): void {
    const key = this._stockId(
      stock.isSynonymous,
      stock.cdStock,
      stock.dsAsset,
      stock.idExchange
    );
    if (stock.isCheck) this._stocksToRemove.delete(key);
    else this._stocksToRemove.set(key, true);
  }

  public formatPrice(data: any, value: any): string {
    return execFormatFinancial(data, value);
  }

  private _stockId(
    synonymous: boolean,
    cdStock: string,
    dsAsset: string,
    idExchange: number
  ): string {
    return synonymous
      ? `${dsAsset}:${idExchange}:SYN`
      : `${cdStock}:${idExchange}`;
  }
}
