import { joinValuesAndFormatter } from '@shared/rocket-components/utils';
import { IStockListItemsWithView } from 'src/app/core/interface';

export class StockListRowModel {
  public actions!: string;
  public idUpdate!: string;
  public idRow!: string;
  public item!: string;
  public cd_stock!: string;
  public cd_stock_order!: string;
  public id_exchange!: number;
  public preco_ultimo!: number;
  public preco_fechamento!: number;
  public variacao_dia!: number;
  public var_fech_anterior!: number;
  public vl_fech_ant_essa_sem!: number;
  public vl_fech_ant_esse_mes!: number;
  public vl_fech_ant_esse_ano!: number;
  public vl_fech_ant_sem!: number;
  public vl_fech_ant_mes!: number;
  public vl_fech_ant_ano!: number;
  public volume!: number;
  public yesterday_volume!: number;
  public week_volume!: number;
  public month_volume!: number;
  public year_volume!: number;
  public this_week_volume!: number;
  public this_month_volume!: number;
  public this_year_volume!: number;
  public today_delta!: number;
  public thirty_min_delta!: number;
  public vl_high_today!: string;
  public vl_low_today!: number;
  public vl_close_today!: number;
  public vl_open_today!: number;
  public vl_high_yesterday!: string;
  public vl_low_yesterday!: number;
  public vl_close_yesterday!: number;
  public vl_open_yesterday!: number;
  public vl_high_week!: string;
  public vl_low_week!: number;
  public vl_close_week!: number;
  public vl_open_week!: number;
  public vl_high_month!: string;
  public vl_low_month!: number;
  public vl_close_month!: number;
  public vl_open_month!: number;
  public vl_high_year!: string;
  public vl_low_year!: number;
  public vl_close_year!: number;
  public vl_open_year!: number;
  public vl_high_this_week!: string;
  public vl_low_this_week!: number;
  public vl_open_this_week!: number;
  public vl_close_this_week!: number;
  public vl_high_this_month!: string;
  public vl_low_this_month!: number;
  public vl_close_this_month!: number;
  public vl_open_this_month!: number;
  public vl_high_this_year!: string;
  public vl_low_this_year!: number;
  public vl_close_this_year!: number;
  public vl_open_this_year!: number;
  public mcap!: number;
  public ev!: number;
  public pl!: number;
  public pvpa!: number;
  public evebtida!: number;
  public perc_strong_buy!: number;
  public perc_buy!: number;
  public perc_maintain!: number;
  public perc_sell!: number;
  public perc_strong_sell!: number;
  public vl_high!: number;
  public vl_low!: number;
  public vl_mean!: number;
  public upside_medio!: number;
  public upside_min!: number;
  public upside_max!: number;
  public vl_volatility_one_year!: number;
  public vl_volatility_two_year!: number;
  public vl_beta_one_year!: number;
  public seq_priority!: number;
  public arrow_font_hex!: string;
  public arrow_hex!: string;
  public preco_maximo!: string;
  public preco_minimo!: string;
  public preco_abertura!: string;
  public precoDiaAnt!: string;
  public qtde_negocios!: string;
  public tick_size_denominator!: number;
  public ds_asset!: string;
  public synonymous_nickname!: string;
  public is_synonymous!: boolean;
  public day_variation!: number;
  public yesterday_variation!: number;
  public week_variation!: number;
  public month_variation!: number;
  public year_variation!: number;
  public this_week_variation!: number;
  public this_month_variation!: number;
  public this_year_variation!: number;
  public nm_company!: string;
  public company_logo!: string;
  public situacao!: string;
  public idStockList: number;
  public isIndex: boolean;

  constructor(stock: IStockListItemsWithView, refComponent: string) {
    this.actions = refComponent;
    this.idUpdate = stock.is_synonymous
      ? `${stock.ds_asset}:${stock.id_exchange}:SYN`
      : `${this.getStock(stock)}:${stock.id_exchange}`;
    this.idRow = `${this.getStock(stock)}:${stock.id_exchange}`;
    this.item = this.idRow;
    this.cd_stock_order = stock.cd_stock_order || stock.cd_stock;
    this.cd_stock = this.getStock(stock);
    this.id_exchange = stock.id_exchange;
    this.tick_size_denominator = stock.tick_size_denominator;
    this.preco_ultimo = stock.vl_close;
    this.preco_fechamento = stock.previous_vl_close ?? stock.vl_close;
    this.variacao_dia = stock.day_variation;
    this.var_fech_anterior = stock.yesterday_variation;
    this.vl_fech_ant_essa_sem = stock.week_variation;
    this.vl_fech_ant_esse_mes = stock.month_variation;
    this.vl_fech_ant_esse_ano = stock.year_variation;
    this.vl_fech_ant_sem = stock.this_week_variation;
    this.vl_fech_ant_mes = stock.this_month_variation;
    this.vl_fech_ant_ano = stock.this_year_variation;
    this.volume = stock.today_volume;
    this.yesterday_volume = stock.yesterday_volume;
    this.week_volume = stock.week_volume;
    this.month_volume = stock.month_volume;
    this.year_volume = stock.year_volume;
    this.this_week_volume = stock.this_week_volume;
    this.this_month_volume = stock.this_month_volume;
    this.this_year_volume = stock.this_year_volume;
    this.today_delta = stock.today_delta;
    this.thirty_min_delta = stock.thirty_min_delta;
    this.vl_high_today = this.joinFormatterValues(
      stock.vl_low_today,
      stock.vl_high_today
    );
    this.vl_low_today = stock.vl_low_today;
    this.vl_close_today = stock.vl_close_today;
    this.vl_open_today = stock.vl_open_today;
    this.vl_high_yesterday = this.joinFormatterValues(
      stock.vl_low_yesterday,
      stock.vl_high_yesterday
    );
    this.vl_low_yesterday = stock.vl_low_yesterday;
    this.vl_close_yesterday = stock.vl_close_yesterday;
    this.vl_open_yesterday = stock.vl_open_yesterday;
    this.vl_high_week = this.joinFormatterValues(
      stock.vl_low_week,
      stock.vl_high_week
    );
    this.vl_low_week = stock.vl_low_week;
    this.vl_close_week = stock.vl_close_week;
    this.vl_open_week = stock.vl_open_week;
    this.vl_high_month = this.joinFormatterValues(
      stock.vl_low_month,
      stock.vl_high_month
    );
    this.vl_low_month = stock.vl_low_month;
    this.vl_close_month = stock.vl_close_month;
    this.vl_open_month = stock.vl_open_month;
    this.vl_high_year = this.joinFormatterValues(
      stock.vl_low_year,
      stock.vl_high_year
    );
    this.vl_low_year = stock.vl_low_year;
    this.vl_close_year = stock.vl_close_year;
    this.vl_open_year = stock.vl_open_year;
    this.vl_high_this_week = this.joinFormatterValues(
      stock.vl_low_this_week,
      stock.vl_high_this_week
    );
    this.vl_low_this_week = stock.vl_low_this_week;
    this.vl_open_this_week = stock.vl_open_this_week;
    this.vl_close_this_week = stock.vl_close_this_week;
    this.vl_high_this_month = this.joinFormatterValues(
      stock.vl_low_this_month,
      stock.vl_high_this_month
    );
    this.vl_low_this_month = stock.vl_low_this_month;
    this.vl_close_this_month = stock.vl_close_this_month;
    this.vl_open_this_month = stock.vl_open_this_month;
    this.vl_high_this_year = this.joinFormatterValues(
      stock.vl_low_this_year,
      stock.vl_high_this_year
    );
    this.vl_low_this_year = stock.vl_low_this_year;
    this.vl_close_this_year = stock.vl_close_this_year;
    this.vl_open_this_year = stock.vl_open_this_year;
    this.mcap = stock.mcap ?? 0;
    this.ev = stock.ev ?? 0;
    this.pl = stock.pl ?? 0;
    this.pvpa = stock.pvpa ?? 0;
    this.evebtida = stock.evebtida ?? 0;
    this.perc_strong_buy = stock.perc_strong_buy ?? 0;
    this.perc_buy = stock.perc_buy ?? 0;
    this.perc_maintain = stock.perc_maintain ?? 0;
    this.perc_sell = stock.perc_sell ?? 0;
    this.perc_strong_sell = stock.perc_strong_sell ?? 0;
    this.vl_high = stock.vl_high ?? 0;
    this.vl_low = stock.vl_low ?? 0;
    this.vl_mean = stock.vl_mean ?? 0;
    this.upside_medio = stock.upside_medio ?? 0;
    this.upside_min = stock.upside_min ?? 0;
    this.upside_max = stock.upside_max ?? 0;
    this.vl_volatility_one_year = stock.vl_volatility_one_year;
    this.vl_volatility_two_year = stock.vl_volatility_two_year;
    this.vl_beta_one_year = stock.vl_beta_one_year;
    this.arrow_font_hex = stock.arrow_font_hex ?? '';
    this.arrow_hex = stock.arrow_hex ?? '';
    this.preco_maximo = stock?.preco_maximo ?? '';
    this.preco_minimo = stock?.preco_minimo ?? '';
    this.preco_abertura = stock?.preco_abertura ?? '';
    this.precoDiaAnt = stock?.precoDiaAnt ?? '';
    this.qtde_negocios = stock?.qtde_negocios ?? '';
    this.ds_asset = stock.ds_asset ?? '';
    this.synonymous_nickname = stock.synonymous_nickname ?? '';
    this.is_synonymous = stock.is_synonymous ?? false;
    this.day_variation = stock.day_variation ?? 0;
    this.yesterday_variation = stock.yesterday_variation ?? 0;
    this.week_variation = stock.week_variation ?? 0;
    this.month_variation = stock.month_variation ?? 0;
    this.year_variation = stock.year_variation ?? 0;
    this.this_week_variation = stock.this_week_variation ?? 0;
    this.this_month_variation = stock.this_month_variation ?? 0;
    this.this_year_variation = stock.this_year_variation ?? 0;
    this.nm_company = stock.nm_company;
    this.company_logo = stock.company_logo;
    this.situacao = stock?.situacao;
    this.idStockList = stock.idStockList;
    this.isIndex = stock.cd_segment.trim() === '90';
  }

  private getStock(stock: IStockListItemsWithView): string {
    return stock.is_synonymous ? stock.synonymous_nickname : stock.cd_stock;
  }

  private joinFormatterValues(firstField: any, secondField: any): string {
    return joinValuesAndFormatter(
      firstField,
      secondField,
      {
        maximumFractionDigits: this.tick_size_denominator || 2,
        minimumFractionDigits: this.tick_size_denominator || 2,
      },
      '|'
    );
  }
}
