import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AppConfig } from './config.interface';
import { SciChartSurface } from 'scichart';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from 'src/environments/environment';
import { APP_VERSION } from 'src/environments/version';
import { AppStates, ChannelAuthentication } from './config.enums';
import { changeTheme } from 'src/app/utils/change-theme';
import { CHEETAH_DNS_PROD } from '@shared/cheetah/cheetah.const';
import { AppConfigInfo } from './config.const';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private config: AppConfig | undefined;

  private keyMapping: any = {
    ENV_CONTRACT: 'idContract',
    ENV_CHANNEL: 'channel',
    ENV_CHEETAH: 'cheetahUrl',
    ENV_FREQUENCY: 'cheetahFrequency',
    ENV_APP_STATE: 'appState',
    ENV_ACCESS_TYPE: 'accessType',
    ENV_ENABLED_PWA_DEBUG: 'pwaDebugEnabled',
    ENV_RECAPTCHA_KEY: 'recaptchaKey',
    ENV_RECAPTCHA_VALIDATE: 'recapchaValidate',
    ENV_IDLE_TIME: 'idleTime',
    ENV_CHEETAH_DEBUG: 'cheetahDebugStatus',
    ENV_SCICHART_KEY: 'scichartKey',
    ENV_SENTRY_ENABLE: 'sentryEnable',
    ENV_IS_BROKER: 'isBroker',
    ENV_PUBLIC_KEY: 'rsaPublicKey',
  };

  constructor(private http: HttpClient) {}

  private isKeyOfAppConfig(key: any): key is keyof AppConfig {
    return key in this.keyMapping;
  }

  private normalizeConfig(config: any): AppConfig {
    const normalizedConfig: AppConfig = {} as AppConfig;
    for (const key in config) {
      if (this.isKeyOfAppConfig(key)) {
        const normalizedKey = this.keyMapping[key] as keyof AppConfig; // Usa o mapeamento ou a chave original
        normalizedConfig[normalizedKey] = config[key];
      }
    }
    return normalizedConfig;
  }

  private sciSetRuntimeLicenseKey() {
    if (this.config?.scichartKey)
      SciChartSurface.setRuntimeLicenseKey(this.config.scichartKey);
  }

  private initializeSentry() {
    if (!this.canInitializeSentry()) return;
    Sentry.init({
      dsn: environment.sentry.dsn,
      environment: environment.sentry.environment,
      release: `${APP_VERSION}`,
      integrations: [
        Sentry.httpClientIntegration({
          failedRequestStatusCodes: [[400, 599]],
          failedRequestTargets: environment.sentry.sentryTargets,
        }),
      ],
      sendDefaultPii: environment.sentry.sendDefaultPii,
      tracesSampleRate: environment.sentry.tracesSampleRate,
      tracePropagationTargets: environment.sentry.tracePropagationTargets,
    });
  }

  canInitializeSentry = () => this.isSentryEnable() && !this.isDev();

  isWebAuthenticationChannel = () =>
    this.config?.channel === ChannelAuthentication.WEB;

  isDebugEnabled = () =>
    this.config?.cheetahDebugStatus &&
    this.config?.cheetahDebugStatus === 'ENABLED';

  isSentryEnable = () =>
    this.config?.sentryEnable && this.config?.sentryEnable === 'ENABLED';

  isRecapcthaEnabled = () =>
    this.config?.recapchaValidate &&
    this.config?.recapchaValidate === 'ENABLED';

  isDev = () => this.config?.appState === AppStates.DEV;

  getCheetahURL = () => this.config?.cheetahUrl || CHEETAH_DNS_PROD;

  getRsaPublicKey = () => this.config?.rsaPublicKey || '';

  loadConfig() {
    return firstValueFrom(this.http.get('/environments.json')).then(
      (data: any) => {
        this.setConfig(data);
        changeTheme();
        this.sciSetRuntimeLicenseKey();
        this.initializeSentry();
      }
    );
  }

  setConfig(data: any) {
    this.config = this.normalizeConfig(data);
  }

  getConfig() {
    return this.config;
  }

  isBroker = () =>
    !!(this.config?.isBroker && this.config?.isBroker === 'ENABLED');

  invertStopBroker(orderSide: string) {
    return this.isBroker() && orderSide == 'B';
  }

  getAppConfig(configKey: string) {
    return AppConfigInfo.get(configKey) ?? AppConfigInfo.get('core')!;
  }
}
