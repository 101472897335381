import { Injectable } from '@angular/core';
import intlTelInput, { Iti, SomeOptions } from 'intl-tel-input';
import { TelInputLanguages } from '../constants/tel-input-languages.const';
@Injectable()
export class PhoneInputConfigService {
  getConfig(
    containerRef: HTMLElement,
    componentRef: HTMLInputElement,
    options?: SomeOptions
  ): Iti {
    (options as SomeOptions) = {
      initialCountry: 'br',
      //TODO: Passar a usar o utils.js local
      utilsScript:
        'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/23.0.7/js/utils.js',
      separateDialCode: true,
      dropdownContainer: containerRef,
      formatAsYouType: true,
      formatOnDisplay: true,
      nationalMode: true,
      strictMode: true,
      i18n: TelInputLanguages.BR,
      countryOrder: ['br', 'us'],
      autoPlaceholder: 'polite',
      validationNumberType: 'MOBILE',
      ...options,
    };
    return intlTelInput(componentRef, options);
  }
}
