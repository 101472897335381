import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';

export const PERFORMANCE_REPORT_COMPONENT: any = {
  name: 'Relatório de performance',
  id: COMPONENTS_NAMES_ENUM.PERFORMANCE_REPORT,
  icon: 'sports_score',
  cod: 'PERFORMANCE_REPORT',
  data: null,
  close: true,
  fullscreen: true,
  link: false,
  widthDesk: 583,
  heightDesk: 346,
  unitOfMeasurement: 'px',
  height: 740,
  width: 756,
  minWidth: '756px',
  minHeight: '740px',
  left: 'calc(15% + 5px)',
  top: '0px',
  label: 'Relatório de performance',
  borderClass: '',
  hasBorder: true,
  noPublished: false,
  divider: true,
  iconSvg: '',
};
