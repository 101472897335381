import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  FlaButtonCloseModule,
  FlaIconModule,
  RocketButtonModule,
  RocketModalModule,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { DEFAULT_FUNCTIONS, RATINGS } from './rating-system.const';
import { Rating } from './rating-system.interface';
import {
  AnalyticsService,
  EventAnalytics,
} from '@shared/services/api/trademap/v1/analytics.service';
import { ToastService } from '@shared/services';
import { StandaloneModalsService } from '../standalone-modals.service';

@Component({
  selector: 'app-rating-system',
  templateUrl: './rating-system.component.html',
  styleUrls: ['./rating-system.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RocketModalModule,
    RocketButtonModule,
    FlaButtonCloseModule,
    FlaIconModule,
  ],
  providers: [RocketModalService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingSystemComponent extends RocketModalRef {
  ratingComment: string = '';
  readonly limitText: number = 280;
  lengthText: number = 0;
  ratings = RATINGS;
  ratingSelect!: Rating;
  constructor(
    rocketModalService: RocketModalService,
    private _standaloneModal: StandaloneModalsService,
    private cdr: ChangeDetectorRef,
    private toast: ToastService,
    private analyticsService: AnalyticsService
  ) {
    super(rocketModalService);
  }

  validityTextLength() {
    this.lengthText = this.ratingComment.length;
    this.cdr.detectChanges();
  }

  sendRating() {
    if (
      !this.ratingSelect ||
      !this.ratingComment ||
      this.lengthText > this.limitText
    ) {
      return;
    }
    const rating: EventAnalytics[] = this.getEvent();
    this.analyticsService.saveEvents(rating).subscribe(() => {
      this.toast.showToast(
        'success',
        'Obrigado por sua avaliação, ela é muito importante para nós direcionarmos nosso trabalho!'
      );
      this._standaloneModal.close({ success: true });
    });
  }

  emojiSelect(rating: Rating) {
    this.ratingSelect = rating;
  }

  private getEvent(): EventAnalytics[] {
    return [
      {
        ds_param: `${this.ratingSelect.label}|${this.ratingComment}`,
        ds_platform: 'ROCKET_WEB',
        ds_function: DEFAULT_FUNCTIONS.SCREEN_VIEW,
        ds_subfunction: 'CSAT_ROCKET_WEB',
        version: '',
        version_name: '',
      },
    ];
  }
}
