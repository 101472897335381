import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { randomId } from 'src/app/utils/utils.framework';
import { BaseComponent } from '../base-componentes';
import { MESSAGES } from '../messages.const';
import { RTTMargin } from '../types';
import { HomeService } from '@modules/home/service/home.service';
import { DEFAULT_MAX } from './input-count.const';
import { FlaTType } from '../components/input/types/input.types';
import { Subscription } from 'rxjs';
import { InputCountService } from './input-count.service';

@Component({
  selector: 'app-input-count',
  templateUrl: './input-count.component.html',
  styles: [
    `
      :host(app-input-count) {
        display: contents;
        ::ng-deep.up.btn-icon:hover i {
          color: var(--vm-feedback-positive) !important;
        }
        ::ng-deep.down.btn-icon:hover i {
          color: var(--vm-feedback-negative) !important;
        }

        ::ng-deep.suffix {
          padding: 0px;
        }
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RTInputCountComponent),
    },
  ],
})
export class RTInputCountComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() tabIndex!: number;
  @Input() tabIndexIncrement!: number;
  @Input() css: string = '';
  @Input() margin: RTTMargin = 'mb-5';
  @Input() placeholder: string = '';
  @Input() labelPlacement: 'vertical' | 'horizontal' = 'vertical';
  @Input() ariaDescribedby: string = '';
  @Input() hasError: string = '';
  @Input() mask!: string;
  @Input() showMaskTyped: boolean = false;
  @Input() selectOnFocus: boolean = false;
  @Input() label!: string;
  @Input() hint!: string;
  @Input() requiredText: string = MESSAGES['requiredText'];
  @Input() isRequired: boolean = false;
  @Input() isSmall: boolean = false;
  @Input() allowNegativeValue: boolean = true;
  @Input() clearable: boolean = true;
  @Input() isDisabledKeyboard: boolean = false;
  @Input() max: number = DEFAULT_MAX;
  @Input() min: number = 0;
  @Input() maxLength: number = DEFAULT_MAX;
  @Input() minLength: number = 0;
  @Input() initialValue: number = 0;
  @Input() enableZeroValue: boolean = false;
  @Input() incrementValue: number = 1;
  @Input() paddingSmallCustom: string = 'py-2';
  @Input() alwaysShowIncrementButton = true;
  @Input() alwaysShowDecrementButton = true;
  @Input() isModal = false;
  @Input() enableUpdateValueBtn = false;
  @Input() set isDisable(disabled: boolean) {
    this.disabledBtn = disabled;
    this.disable?.next(disabled);
  }
  @Input() enableScrollEvent: boolean = false;
  @Input() type: FlaTType = 'text';
  @Input() symbolPrefix!: string;
  @Input() symbolSuffix!: string;
  @Input() isQttyInput: boolean = false;
  @Input() isStockTableUse: boolean = false;
  @Input() resetValue: boolean = false;
  @Input() emitEventOnBackToSourceValue: boolean = false;
  @Input() useMaxAsLastValue: boolean = false;
  @Input() stock!: string;
  @Input() globalStockSelected!: string;
  @Output() rtChange = new EventEmitter<any>();
  private _focusHandler!: Subscription;
  private blurElements!: Subscription;
  @Output() refIdCreated = new EventEmitter<string>();
  @Output() updateValue = new EventEmitter<any>();
  @Output() flaScroll = new EventEmitter<any>();
  @Output() flaKeyDown = new EventEmitter<any>();
  @Output() flaKeyUp = new EventEmitter<any>();
  @Output() flaChange = new EventEmitter<any>();
  @Output() isFocused = new EventEmitter<boolean>();
  @ViewChild('inputCount') private input!: ElementRef<HTMLInputElement>;
  public originalValue: number = 0;
  public refId: string = randomId('fla_input_count');
  disabledBtn: boolean = false;
  disabledIncrementButton: boolean = false;
  disabledDecrementButton: boolean = false;
  selectedInputCountId: string = '';
  private _isFocused: boolean = false;
  private get _incrementValue() {
    return parseFloat(this.incrementValue.toString());
  }
  constructor(
    injector: Injector,
    cdr: ChangeDetectorRef,
    private homeService: HomeService,
    private inputCountService: InputCountService
  ) {
    super(injector, cdr);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { initialValue, min, resetValue } = changes;
    if (initialValue && initialValue.currentValue !== 0) {
      this.originalValue = initialValue.currentValue;
      this.formControl.setValue(initialValue.currentValue);
    }
    if (resetValue?.currentValue) {
      this.originalValue = this.initialValue;
      this.formControl.setValue(this.initialValue);
    }
    if (min?.currentValue)
      if (this.initialValue < min.currentValue)
        this.formControl.setValue(min.currentValue);
  }

  ngOnInit() {
    this._focusHandler = this.inputCountService.focusHandler.subscribe(
      (data) => {
        if (data.withEvent) {
          this.selectedInputCountId = data.refId;
        } else {
          this.selectedInputCountId = '';
        }
      }
    );
    this.blurElements = this.inputCountService.blurAllElements.subscribe(() => {
      this.input.nativeElement.blur();
    });
  }

  override ngAfterViewInit(): void {
    this.refIdCreated.emit(this.refId);
    this.enableScrollEvent && this.startWheelEvent();
    if (this.initialValue) {
      super.ngAfterViewInit();
      this.formControl.setValue(+this.initialValue);
      !this.originalValue && (this.originalValue = this.initialValue);
      return;
    }
    !this.originalValue && (this.originalValue = this.initialValue);
    super.ngAfterViewInit();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.input.nativeElement.removeEventListener('wheel', this.wheelEvent);
    this._focusHandler && this._focusHandler.unsubscribe();
    this.blurElements && this.blurElements.unsubscribe();
  }

  @HostListener('window:keydown.Alt', ['true'])
  @HostListener('window:keyup.Alt', ['false'])
  alt(press: boolean) {
    if(this._isFocused)
      this.inputCountService.hotkeyPress({ alt: press, shift: false });
  }
  @HostListener('window:keydown.shift', ['true'])
  @HostListener('window:keyup.shift', ['false'])
  shift(press: boolean) {
    if(this._isFocused)
      this.inputCountService.hotkeyPress({ alt: false, shift: press });
  }

  @HostListener('window:keydown.ArrowUp')
  up(clickArrow: boolean = false) {
    let value: number | string = this.formControl.value;
    if (!clickArrow && (!this.isInputActive() || !this.validityArrowEvent())) {
      return;
    }

    if (
      this.isStockTableUse &&
      !clickArrow &&
      (!this._isFocused || this.stock !== this.globalStockSelected)
    )
      return;
    if (typeof value === 'string') {
      value = parseFloat(value.replace(',', '.'));
    }
    let total = value + this._incrementValue;
    if (!this.useMaxAsLastValue) {
      if (total >= this.max || this.disabledBtn || this.isDisabledKeyboard) {
        return;
      }
    }
    total = total >= this.max ? this.max : total;

    if (!this.isQttyInput) {
      this.formControl.setValue(Math.round(total * 100) / 100);
      return;
    }
    this.formControl.setValue(Math.ceil(total * 100) / 100);
  }

  @HostListener('window:keydown.ArrowDown')
  down(clickArrow: boolean = false) {
    let value: number | string = this.formControl.value;

    if (!clickArrow && (!this.isInputActive() || !this.validityArrowEvent())) {
      return;
    }
    if (
      this.isStockTableUse &&
      !clickArrow &&
      (!this._isFocused || this.stock !== this.globalStockSelected)
    )
      return;
    if (typeof value === 'string') {
      value = parseFloat(value.replace(',', '.'));
    }

    if (
      (!this.allowNegativeValue && value - this._incrementValue == 0) ||
      this.disabledBtn ||
      this.isDisabledKeyboard
    ) {
      return;
    }
    const total = value - this._incrementValue;

    if (total <= this.min) {
      this.formControl.setValue(this.min);
      return;
    }

    if (!this.isQttyInput) {
      this.formControl.setValue(Math.round(total * 100) / 100);
      return;
    }
    this.formControl.setValue(Math.floor(total * 100) / 100);
  }

  private validityArrowEvent(): boolean {
    const existSelected = !!this.selectedInputCountId;
    return (
      (this.homeService.isSelectedComponent(this.refId) || this.isModal) &&
      ((this.isQttyInput && !existSelected) ||
        (existSelected && this.selectedInputCountId === this.refId))
    );
  }

  public handleFocus(event?: any) {
    if (!this.selectOnFocus) return;
    if (event) event.target.select();
    this._isFocused = event !== undefined;
    this.isFocused.emit(event !== undefined);
    this.inputCountService.dispatchFocusHandler({
      refId: this.refId,
      withEvent: !!event,
    });
  }

  isInputActive = () => document.activeElement === this.input.nativeElement;

  public updateValueClick(): void {
    this.updateValue.emit();
  }

  private startWheelEvent = () => {
    this.input.nativeElement.addEventListener('wheel', this.wheelEvent);
  };

  private wheelEvent = (e: WheelEvent) => {
    if (this.isInputActive()) {
      e.deltaY < 0 ? this.up() : this.down();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  public onChangeValue(formControl: FormControl): void {
    if (this.useMaxAsLastValue && formControl.value > this.max) {
      this.formControl.setValue(this.max);
    }
    if (formControl.value === this.originalValue) {
      if (this.emitEventOnBackToSourceValue) this.rtChange.emit(formControl);
      return;
    }
    this.rtChange.emit(formControl);
  }

  setFocus = () => {
    this.input.nativeElement.focus();
  };
}
