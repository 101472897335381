import { AbstractControl, ValidationErrors } from '@angular/forms';
import { normalizeString } from 'src/app/utils/utils.functions';

export function fullNameValidator(
  control: AbstractControl
): ValidationErrors | null {
  const fullNameRegex = /^[a-zA-Z]+(?: [a-zA-Z]+)+$/;
  const isValid = fullNameRegex.test(normalizeString(control.value));
  return isValid ? null : { invalidFullName: true };
}
