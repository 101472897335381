import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  RocketModalRef,
  RocketModalService,
  ROCKET_MODAL_DATA,
} from '@shared/rocket-components/components/index';
import { CustomPreferencesService } from '@services/api/nitro-ws/v1/custom-preferences.service';
import { ORDER_CONFIRM_ACTIONS_ENUM } from '@shared/enum/buyOrSell.enum';
import { ModalBrokersToConnectComponent } from '@modules/broker-connection/modals/modal-brokers-to-connect/modal-brokers-to-connect.component';
import { IModalInfosParams } from './interface/modal-aviso.interface';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { formatByTick } from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-modal-aviso',
  templateUrl: './modal-aviso.component.html',
  styleUrls: ['./modal-aviso.component.scss'],
})
export class ModalAvisoComponent
  extends RocketModalRef
  implements OnInit, OnDestroy
{
  public dontAskAgain: boolean = false;
  private _modalService!: RocketModalService;
  public configs = {
    title: '',
    text: '',
    showButtons: true,
    cancelButton: 'Cancelar',
    confirmeButton: 'Salvar',
    isSecondModal: true,
    askAgain: false,
    typeAskAgain: '',
    stock: undefined,
    showPriceStock: false,
  };
  private quoteParams!: SubscribeParam;
  lastPrice: string = '';

  constructor(
    @Inject(ROCKET_MODAL_DATA)
    private data: IModalInfosParams,
    service: RocketModalService,
    private _customPreferencesService: CustomPreferencesService,
    private quoteChannel: QuoteChannel
  ) {
    super(service);
    this._modalService = service;
  }

  ngOnInit(): void {
    this.startQuote();
    this.configs = Object.assign(this.configs, this.data);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.quoteParams && this.quoteChannel.unsubscribe(this.quoteParams);
  }

  private startQuote() {
    if (this.data.stock && this.data.showPriceStock) {
      this.quoteParams = {
        itemsArray: [
          `${this.data.stock.cd_stock}:${this.data.stock.id_exchange}`,
        ],
      };
      this.quoteChannel.subscribe(this.quoteParams);
      this.quoteChannel.readEvents().then(async (data) => {
        this.readStream(data.stream, this.quoteHandler);
      });
    }
  }

  private quoteHandler = (res: any): void => {
    if (
      !this.quoteParams ||
      this.quoteParams.itemsArray[0] !== res.item ||
      !res.preco_ultimo
    )
      return;

    this.lastPrice = formatByTick(
      res.preco_ultimo,
      this.data.stock?.tick_size_denominator
    );
  };

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    (event.code === 'Enter' ||
      event.code === 'NumpadEnter' ||
      event.code === 'Space') &&
      this.onConfirm(true, event.code);

    event.code === 'Escape' && this.onConfirm(false, event.code);
  }

  onConfirm(value: boolean, typeEvent: string = 'CLICK') {
    if (
      this.data.positionActionEnum === ORDER_CONFIRM_ACTIONS_ENUM.CONNECT_BROKER
    ) {
      this._connectionsModalHandler(value);
      return;
    }
    if (this.dontAskAgain) this._saveDontAskAgain(value);
    this.close({ value, typeEvent }, Boolean(this.configs.isSecondModal));
  }

  _saveDontAskAgain(optionSelected: boolean) {
    if (this.data.positionActionEnum) {
      this._toggleOrderConfirmActions();
      return;
    }
    this._customPreferencesService.skipSplitConfirmation = true;
    this._customPreferencesService.useSplit = optionSelected;
  }

  private _toggleOrderConfirmActions(): void {
    switch (this.data.positionActionEnum) {
      case ORDER_CONFIRM_ACTIONS_ENUM.RESET:
        this._customPreferencesService.diplayResetPositionConfirmattion = false;
        break;
      case ORDER_CONFIRM_ACTIONS_ENUM.INVERT:
        this._customPreferencesService.diplayInvertPositionConfirmattion =
          false;
        break;
      case ORDER_CONFIRM_ACTIONS_ENUM.SCHEDULE:
        this._customPreferencesService.displayScheduleOrderConfirmation = false;
        break;
      default:
        console.warn('Opção não encontrada');
        break;
    }
  }

  private _connectionsModalHandler(openConnectionModal: boolean): void {
    this.close({ value: null }, false);
    if (openConnectionModal) {
      this._modalService.open(ModalBrokersToConnectComponent, {
        size: 'xl',
        centered: true,
        backdrop: true,
        keyboard: false,
        scrollable: false,
        data: {},
      });
    }
  }
}
