<rocket-modal>
  <rocket-modal-header>
    <h3>Alertas</h3>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <div class="d-inline-flex align-items-center">
      <span>
        Ativo: <b>{{ data.cd_stock }}</b>
      </span>
    </div>
    <form class="mt-3 was-validated" [formGroup]="alertForm">
      <app-input-count
        formControlName="price"
        label="Preço"
        [initialValue]="initialValue"
        [incrementValue]="incrementValue"
        [min]="1"
        [selectOnFocus]="true"
        [isModal]="true"
        [mask]="mask"
        (blurHandler)="verifyAlertPrice($event)"
      >
        <span class="text-feedback-error fs-6 mt-2">
          {{ priceErrorMessage }}
        </span>
      </app-input-count>
    </form>
  </app-rocket-modal-body>
  <rocket-modal-footer>
    <rocket-button
      type="default"
      label="OK"
      (rocketClick)="changeAlertPrice()"
      [isDisabled]="alertPriceInvalid"
    ></rocket-button>
  </rocket-modal-footer>
</rocket-modal>
