import { Injectable } from '@angular/core';
import { AbstractCacheService } from './AbstractCacheService';
import { IListDocuments } from '@shared/components/business-profile/business-profile.interface';

@Injectable({
  providedIn: 'root',
})
export class ListDocumentsCacheService extends AbstractCacheService<
  IListDocuments[]
> {}
