import {
  Component,
  ComponentRef,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextMenuEventPayload } from '@shared/components/popup-root/context-menu-event.model';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-popup-root',
  template: '<div></div>',
})
export class PopupRootComponent implements OnInit, OnDestroy {
  private componentRef!: ComponentRef<any>;
  private event$ = new Subscription();
  private isRoot: boolean = false;
  private closeSubscription$ = new Subscription();
  constructor(
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private contextMenuService: ContextMenuService,
    private route: ActivatedRoute
  ) {
    this.route.url.subscribe((url) => {
      this.isRoot = url[0]?.path === 'popup';
    });
  }

  ngOnInit(): void {
    this.event$.add(
      this.contextMenuService
        .listenOpenEvent()
        .subscribe((data: ContextMenuEventPayload<'OPEN'>) => {
          const componentClass = this.contextMenuService.getComponentByName(
            data.openEventContext!.type
          );
          if (!componentClass)
            throw new Error(
              `Component ${data.openEventContext!.type} not found!`
            );
          this.closeSubscription$.unsubscribe();
          this.viewContainerRef.clear();
          this.componentRef?.destroy();
          const rect = this.elementRef.nativeElement.getBoundingClientRect();
          const position = this.isRoot
            ? { top: 0, left: 0 }
            : {
                top: data.openEventContext!.event.clientY - rect.top,
                left: data.openEventContext!.event.clientX - rect.left,
              };
          this.componentRef =
            this.viewContainerRef.createComponent(componentClass);
          this.componentRef.instance.position = position;
          this.componentRef.instance.parent_id = data.id;
          Object.assign(
            this.componentRef.instance,
            data.openEventContext!.configurationData
          );
          this.componentRef.changeDetectorRef.detectChanges();
          this.closeSubscription$ = this.contextMenuService
            .listenCloseEvent(data.id)
            .subscribe(() => {
              this.componentRef?.destroy();
            });
        })
    );
  }

  ngOnDestroy(): void {
    this.componentRef?.destroy();
    this.event$.unsubscribe();
  }
}
