import { FormGroup } from '@angular/forms';
import { DynamicControlService } from '@shared/rocket-components/dynamic-form/dynamic-control.service';
import { FieldsBase } from '@shared/rocket-components/dynamic-form/fields-base';
import { ORDER_TYPES, VALIDADE_TYPES } from '../constants/boleta.constants';
import {
  STOCK_TRADE_ELEMENTS_ID,
  TYPE_ORDE_ENUM,
  TYPE_VALIDADE_ENUM,
} from '../enum/stock-trade.enum';
import { deepClone } from '@shared/rocket-components/utils/functions';

export class MarketForm {
  private orderTypes!: any[];
  private orderTypesInit!: number;

  constructor(
    private dynamicControlService: DynamicControlService,
    private standardLot: number,
    private incrementValue: number,
    private vlorderSide: any,
    private isSimulator: boolean,
    private vlClosePrice: string | number,
    private orderOcoValue: boolean,
    private priceMask: string
  ) {
    this.orderTypes = this.isSimulator
      ? deepClone(ORDER_TYPES).splice(0, 2)
      : deepClone(ORDER_TYPES).splice(0, 5);
    this.orderTypesInit = ORDER_TYPES[1].id;
  }

  private fields: any = (tempFormValues?: any) => [
    {
      controlType: 'freeContent',
      key: 'search',
      classLine: 'col-4 pr-2',
      tooltip: `<span class="fs-6">Ativo</span><br><span class="fs-7 line-heigth">Busque pelo código do ativo que deseja negociar.</span>`,
      tooltipDelay: 1000,
    },
    {
      controlType: 'select',
      key: 'typeOdrer',
      label: 'Tipo:',
      value: `${this.orderTypesInit}`,
      isSmall: true,
      bindLabel: 'desc',
      bindValue: 'id',
      options: this.orderTypes,
      classLine: 'col-4 pr-2',
      classComponent: 'margin-bottom',
      tooltip: `<span class="fs-6">Tipo da Ordem</span><br><span class="fs-7 line-heigth">Tipo da ordem a ser enviada.</span>`,
      tooltipDelay: 1000,
      elementID: STOCK_TRADE_ELEMENTS_ID.ORDER_TYPE,
    },
    {
      controlType: 'freeContent',
      key: 'qtde',
      label: 'Qtde:',
      value: tempFormValues?.qtde || this.standardLot,
      initialValue: tempFormValues?.qtde || this.standardLot,
      isSmall: true,
      selectOnFocus: true,
      flaMask: 'separator.0',
      allowNegativeValue: false,
      incrementValue: this.incrementValue,
      classLine: 'col-4 pr-2',
      classComponent: 'margin-bottom',
      listErrors: ['qtdeRequired'],
      typeShowError: 'icon',
      tooltip: `<span class="fs-6">Quantidade</span><br><span class="fs-7 line-heigth">Número de ativos ou contratos que deseja negociar.</span>`,
      tooltipDelay: 1000,
      scrollEvent: true,
      isQttyInput: true,
      tabIndex: 0,
    },
    {
      controlType: 'freeContent',
      key: 'space',
      classLine: 'col-4',
    },
    {
      controlType: 'select',
      key: 'typeValidade',
      label: 'Validade:',
      value: `${TYPE_VALIDADE_ENUM.TODAY}`,
      isSmall: true,
      bindLabel: 'name',
      bindValue: 'id',
      options: VALIDADE_TYPES,
      classLine: 'col-4 pr-2',
      classComponent: 'margin-bottom',
      tooltip: `<span class="fs-6">Validade</span><br><span class="fs-7 line-heigth">Até quando a ordem pode ser executada.</span>`,
      tooltipDelay: 1000,
      elementID: STOCK_TRADE_ELEMENTS_ID.VALIDATE,
      listErrors: ['incorrectValidityType'],
      typeShowError: 'icon',
    },
    {
      controlType: 'freeContent',
      key: 'orderOco',
      label: 'Ordem OCO',
      value: this.orderOcoValue,
      classLine: 'col-4 pl-1',
      tooltip: `<span class="fs-6">Estratégia OCO</span><br><span class="fs-7 line-heigth">Habilite para criar estratégia OCO de saída  da operação com ordens de venda com ganho (Gain) e perda (Loss) máximos.</span>`,
      tooltipDelay: 1000,
      elementID: STOCK_TRADE_ELEMENTS_ID.ORDER_OCO,
    },
    {
      controlType: '',
      key: 'dueDate',
      label: 'Data Expiração:',
      value: '',
      isSmall: true,
      type: 'date',
      classLine: 'col-4 pr-2',
      classComponent: 'margin-bottom',
    },
    {
      controlType: 'freeContent',
      key: 'gainPrice',
      label: 'Gain:',
      value: tempFormValues?.gainPrice || this.vlClosePrice,
      isSmall: true,
      selectOnFocus: true,
      flaMask: this.priceMask || 'separator.2',
      classLine: 'col-4 pr-3',
      listErrors: ['gainRequired', 'multiplePriceGainError', 'gainPrice'],
      classComponent: 'margin-bottom',
      typeShowError: 'icon',
      tooltip: `<span class="fs-6">Gain</span><br><span class="fs-7 line-heigth">Preço de ordem de saída para fechar a posição com lucro.</span>`,
      tooltipDelay: 1000,
      tabIndex: 0,
    },
    {
      controlType: 'freeContent',
      key: 'lossPrice',
      label: 'Loss:',
      value: tempFormValues?.lossPrice || this.vlClosePrice,
      isSmall: true,
      selectOnFocus: true,
      flaMask: this.priceMask || 'separator.2',
      classLine: 'col-4 pr-3',
      listErrors: ['lossRequired', 'multiplePriceLossError', 'lossPrice'],
      classComponent: 'margin-bottom',
      typeShowError: 'icon',
      tooltip: `<span class="fs-6">Loss</span><br><span class="fs-7 line-heigth">Preço de ordem de saída para fechar a posição com perca.</span>`,
      tooltipDelay: 1000,
      tabIndex: 0,
    },
    {
      key: 'orderSide',
      value: this.vlorderSide,
    },
    {
      key: 'orderType',
      value: TYPE_ORDE_ENUM.MARKET,
    },
  ];

  public createFields(tempFormValues?: any): any[] {
    return this.fields(tempFormValues);
  }

  public createForm(tempFormValues?: any): FormGroup {
    return this.dynamicControlService.toFormGroup(
      this.fields(tempFormValues) as FieldsBase<string>[]
    );
  }
}
