import { Routes, RouterModule } from '@angular/router';
import { componentResolver } from '@core/services/resolvers/component.resolver';
import {
  AtPriceRouteModule,
  BusinessProfileRouteModule,
  FlowInvestorsRouteModule,
  GraphicScreeningRouteModule,
  HeatmapRouteModule,
  MarketSummaryRouteModule,
  NewsRouteModule,
  OptionsRouteModule,
  OrdersHistoryRouteModule,
  PerformanceReportRouteModule,
  RankingRouteModule,
  RentBTCRouteModule,
  ScreeningRouteModule,
  StockAnalysisRouteModule,
  StockChartRouteModule,
  StockTableRouteModule,
  StockTradeRouteModule,
  SuperDOMRouteModule,
  TableBookRouteModule,
  TimesTradesRouteModule,
  TradeIdeaRouteModule,
} from './modules';

const routes: Routes = [
  {
    path: 'stocktrade/:id',
    loadChildren: () => StockTradeRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'timestrades/:id',
    loadChildren: () => TimesTradesRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'stockchart/:id',
    loadChildren: () => StockChartRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'stocktable/:id',
    loadChildren: () => StockTableRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'ordershistory/:id',
    loadChildren: () => OrdersHistoryRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'table-book/:id',
    loadChildren: () => TableBookRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'superdom/:id',
    loadChildren: () => SuperDOMRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'heatmap/:id',
    loadChildren: () => HeatmapRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'stockanalysis/:id',
    loadChildren: () => StockAnalysisRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'news/:id',
    loadChildren: () => NewsRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'options/:id',
    loadChildren: () => OptionsRouteModule,
    resolve: { component: componentResolver },
  },

  {
    path: 'screening/:id',
    loadChildren: () => ScreeningRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'graphicscreening/:id',
    loadChildren: () => GraphicScreeningRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'businessprofile/:id',
    loadChildren: () => BusinessProfileRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'performancereport/:id',
    loadChildren: () => PerformanceReportRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'tradeidea/:id',
    loadChildren: () => TradeIdeaRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'ranking/:id',
    loadChildren: () => RankingRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'atprice/:id',
    loadChildren: () => AtPriceRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'rentbtc/:id',
    loadChildren: () => RentBTCRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'flowinvestors/:id',
    loadChildren: () => FlowInvestorsRouteModule,
    resolve: { component: componentResolver },
  },
  {
    path: 'marketsummary/:id',
    loadChildren: () => MarketSummaryRouteModule,
    resolve: { component: componentResolver },
  },
];

export const ViewRoutes = RouterModule.forChild(routes);
