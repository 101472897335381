import {
  AnnotationBase,
  ECoordinateMode,
  EHorizontalAnchorPoint,
  EVerticalAnchorPoint,
  HorizontalLineAnnotation,
  TWebAssemblyChart,
  ELabelPlacement,
  CategoryAxis,
  CategoryCoordinateCalculator,
} from 'scichart';
import { TCandleLineOptions } from '../types/tiger-chart.types';
import { CHART_COLORS } from '../colors';
import { ClickableAnnotation } from '../annotations/clickable-annotation';
import { getClose } from '../templates/tiger-chart.template';
import { Subject, auditTime, filter } from 'rxjs';
import { TIGER_INTERVAL_ENUM } from '../enum';
import { standardizeDate } from '@shared/rocket-components/utils';
import { ICandleConfigs } from '../interface';
export const PREVIOUS_LINE_ID = 'PREVIOUS_LINE_ID';
export const BID_LINE_ID = 'BID_LINE_ID';
export const ASK_LINE_ID = 'ASK_LINE_ID';
export class CreateLineService {
  private events$ = new Subject<{
    ref: string;
  }>();
  private hashAllowAuxLine: any = {
    [TIGER_INTERVAL_ENUM.ONE_MINUTE]: true,
    [TIGER_INTERVAL_ENUM.TWO_MINUTE]: true,
    [TIGER_INTERVAL_ENUM.FIVE_MINUTE]: true,
    [TIGER_INTERVAL_ENUM.TEN_MINUTE]: true,
    [TIGER_INTERVAL_ENUM.FIFTEEN_MINUTE]: true,
    [TIGER_INTERVAL_ENUM.THIRTY_MINUTE]: true,
    [TIGER_INTERVAL_ENUM.ONE_HOUR]: true,
  };

  constructor(
    private _base: TWebAssemblyChart,
    private _xAxisId: string,
    private ref: string,
    private _createLine: (
      options: TCandleLineOptions
    ) => HorizontalLineAnnotation | undefined,
    private _deleteLine: (lineId: string) => void,
    private _getAnnotationsById: (id: string) => AnnotationBase
  ) {}

  onEvents = (ref: string) =>
    this.events$.pipe(
      filter((data) => {
        return data.ref === ref;
      }),
      auditTime(100)
    );

  set base(base: TWebAssemblyChart) {
    this._base = base;
  }

  plotPreviousVlClose(lastClose: number) {
    const annotation = this._getAnnotationsById(PREVIOUS_LINE_ID);
    if (annotation) {
      if (annotation.y1 !== lastClose) {
        annotation.y1 = lastClose;
      }
      return;
    }
    const newAnnotation = this.createCloseHorizontalLine(
      lastClose,
      PREVIOUS_LINE_ID,
      CHART_COLORS.NEUTRAL_SMOOTH,
      CHART_COLORS.NEUTRAL_STRONGEST
    );
    this._base.sciChartSurface.annotations.add(newAnnotation);
  }

  plotBidAsk(bidValue: number, askValue: number) {
    const annotationBid = this._getAnnotationsById(BID_LINE_ID);
    const annotationAsk = this._getAnnotationsById(ASK_LINE_ID);
    if (annotationBid && annotationAsk) {
      if (annotationBid.y1 !== bidValue) {
        annotationBid.y1 = bidValue;
      }
      if (annotationAsk.y1 !== askValue) {
        annotationAsk.y1 = askValue;
      }
      return;
    }
    const newAnnotationBid = this.createCloseHorizontalLine(
      bidValue,
      BID_LINE_ID,
      CHART_COLORS.MULTIBROKER_BUY,
      CHART_COLORS.NEUTRAL_STRONGEST
    );
    const newAnnotationAsk = this.createCloseHorizontalLine(
      askValue,
      ASK_LINE_ID,
      CHART_COLORS.MULTIBROKER_SELL,
      CHART_COLORS.NEUTRAL_STRONGEST
    );
    this._base.sciChartSurface.annotations.add(newAnnotationBid);
    this._base.sciChartSurface.annotations.add(newAnnotationAsk);
  }

  deletePreviousVlClose() {
    this._deleteLine(PREVIOUS_LINE_ID);
  }

  deleteBidAsk() {
    this._deleteLine(BID_LINE_ID);
    this._deleteLine(ASK_LINE_ID);
  }

  private createCustomCloseBox(
    lastClose: number,
    id: string,
    stroke: string,
    fill: string
  ) {
    return new ClickableAnnotation({
      svgString: getClose(stroke, fill),
      horizontalAnchorPoint: EHorizontalAnchorPoint.Right,
      verticalAnchorPoint: EVerticalAnchorPoint.Center,
      xCoordinateMode: ECoordinateMode.Relative,
      x1: 1,
      y1: parseFloat(lastClose.toString()),
      isEditable: false,
      id,
      annotationsGripsRadius: 0,
      annotationsGripsFill: 'transparent',
      annotationsGripsStroke: 'transparent',
      selectionBoxStroke: 'transparent',
      selectionBoxDelta: 0,
      selectionBoxThickness: 0,
      xAxisId: this._xAxisId,
      onClick: (args) => {
        args.mouseArgs.nativeEvent.stopPropagation();
        args.mouseArgs.nativeEvent.stopImmediatePropagation();
        args.mouseArgs.nativeEvent.preventDefault();
        this.events$.next({
          ref: this.ref,
        });
        return;
      },
    });
  }

  private createCloseHorizontalLine(
    value: number,
    id: string,
    stroke: string,
    axisLabelStroke: string
  ) {
    return new HorizontalLineAnnotation({
      y1: parseFloat(value.toString()),
      id,
      stroke,
      strokeThickness: 1,
      strokeDashArray: [2, 2],
      xAxisId: this._xAxisId,
      showLabel: true,
      axisLabelFill: stroke,
      axisLabelStroke,
    });
  }

  createHorizontalLine(
    options: TCandleLineOptions,
    id: string,
    xAxisId: string
  ): HorizontalLineAnnotation {
    return new HorizontalLineAnnotation({
      labelPlacement: options.labelPlacement
        ? options.labelPlacement
        : ELabelPlacement.Axis,
      xCoordinateMode: ECoordinateMode.DataValue,
      showLabel: false,
      stroke: options.color,
      axisLabelFill: options.color,
      axisLabelStroke: options.boxTextColor,
      strokeThickness: options.strokeThickness,
      y1: options.value,
      x1: options.x1,
      isEditable: false,
      id,
      annotationsGripsRadius: 0,
      annotationsGripsFill: 'transparent',
      annotationsGripsStroke: 'transparent',
      selectionBoxStroke: 'transparent',
      selectionBoxDelta: 0,
      selectionBoxThickness: 0,
      xAxisId,
    });
  }

  isSameDateAuxLine(dh: string): boolean {
    const today = new Date().setHours(0, 0, 0, 0);
    const dhCreation = standardizeDate(dh);
    const dhCreationToday = new Date(dhCreation).setHours(0, 0, 0, 0);
    return today === dhCreationToday;
  }

  canPlotAuxLine(dh: string, interval: TIGER_INTERVAL_ENUM): boolean {
    return this.hashAllowAuxLine[interval] && this.isSameDateAuxLine(dh);
  }

  getCategoryAxisIndex(xAxis: CategoryAxis, dateMs: number): number {
    const calc =
      xAxis.getCurrentCoordinateCalculator() as CategoryCoordinateCalculator;
    return calc.transformDataToIndex(dateMs);
  }

  setCloseTimeLeft(milliseconds: number, configs: ICandleConfigs) {
    const interval = TIGER_INTERVAL_ENUM;
    const selectedInterval = interval[configs.series.interval!!];
    let candleTimeLeft = undefined;
    /**
     * para tempos superiores a 1h o cheetah hoje não retorna os milliseconds
     */
    const timerToUseLeft: any = {
      ONE_MINUTE: { minutes: 1, seconds: 60 },
      TWO_MINUTE: { minutes: 2, seconds: 2 * 60 },
      THREE_MINUTE: { minutes: 3, seconds: 3 * 60 },
      FOUR_MINUTE: { minutes: 4, seconds: 4 * 60 },
      FIVE_MINUTE: { minutes: 5, seconds: 5 * 60 },
      TEN_MINUTE: { minutes: 10, seconds: 10 * 60 },
      FIFTEEN_MINUTE: { minutes: 15, seconds: 15 * 60 },
      THIRTY_MINUTE: { minutes: 30, seconds: 30 * 60 },
      ONE_HOUR: { minutes: 60, seconds: 60 * 60 },
      //ONE_DAY: { minutes: 1440, seconds: 1440 * 60 },
      //THIS_WEEK: { minutes: 10080, seconds: 10080 * 60 },
      //ONE_MONTH: { minutes: 43800, seconds: 43800 * 60 },
      //ONE_WEEK: { minutes: 10080, seconds: 10080 * 60 },
    };
    if (timerToUseLeft[selectedInterval]) {
      const today = new Date();
      const minutes = milliseconds / 60000;
      const seconds = parseInt(((minutes % 1) * 60).toString());
      const todayTimestamp = today.getTime();
      const newTimestampMinutes = today.setUTCMinutes(
        today.getUTCMinutes() + parseInt(minutes.toString())
      );
      const newDateMinutes = new Date(newTimestampMinutes);
      const newDateTimestamp = newDateMinutes.setUTCSeconds(
        newDateMinutes.getUTCSeconds() + parseInt(seconds.toString())
      );
      candleTimeLeft = new Date(Math.abs(todayTimestamp - newDateTimestamp));
    }
    return candleTimeLeft;
  }

  getFormattedTimer(time: Date): string {
    const utcMinutes = time.getUTCMinutes();
    const minutes = utcMinutes.toString().padStart(2, '0');
    const utcSeconds = time.getUTCSeconds();
    const seconds = utcSeconds.toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  }
}
