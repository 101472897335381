import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { USER_SAVES } from '@shared/tiger-chart/indicators/settings-modal/settings-modal.consts';
import { SaveConfigurationOptions } from '@shared/tiger-chart/indicators/settings-modal/settings-modal.interfaces';

@Component({
  selector: 'rocket-model-save',
  templateUrl: './model-save.component.html',
  styleUrls: ['./model-save.component.scss'],
})
export class RocketModelSaveComponent implements OnInit {
  form!: FormGroup;
  defaultValue = { label: 'Padrão', labelTmp: 'Padrão' };
  saveOptions = USER_SAVES;
  @Output() rocketChange = new EventEmitter<SaveConfigurationOptions>();

  constructor(private _fb: FormBuilder) {
    this.form = this._fb.group({
      optionConfig: new FormControl(this.defaultValue),
    });
  }

  ngOnInit() {}

  dispatchItem(item: SaveConfigurationOptions) {
    this.rocketChange.emit(item);
  }
}
