import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuperDomComponent } from './super-dom.component';
import { SuperDomHeaderComponent } from './parts/header/super-dom-header.component';
import { SuperDomService } from './service/super-dom.service';
import { ForceChartModule } from '../force-chart/force-chart.module';
import { SearchStockModule } from '../search-stock/search-stock.module';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { TableNegotiationComponent } from './parts/table-negotiation/table-negotiation.component';
import {
  FlaIconModule,
  FlaInputModule,
  RocketButtonModule,
} from '@shared/rocket-components/components/index';
import { SuperDomContextMenuComponent } from './parts/context-menu/super-dom-context-menu.component';
import { DetailsTabModule } from '../details-tab/details-tab.module';
import { RTInputCountModule } from '../../rocket-components/input-count/input-count.module';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { ModalAvisoModule } from '../modal-aviso/modal-aviso.module';
import {
  FlaMaskModule,
  FlaFocusAndBlurModule,
} from '@shared/rocket-components/directives';
import { FlaBigNumberModule } from '@shared/rocket-components/pipes/big-number';
import { CtrlClickModule } from '@shared/directives/ctrl-click/ctrl-click.module';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';

@NgModule({
  declarations: [
    SuperDomComponent,
    SuperDomHeaderComponent,
    TableNegotiationComponent,
    SuperDomContextMenuComponent,
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    ForceChartModule,
    RocketButtonModule,
    FlaInputModule,
    RTDropdownModule,
    FlaWorkSpaceModule,
    SearchStockModule,
    ReactiveFormsModule,
    FormsModule,
    FlaFocusAndBlurModule,
    FlaBigNumberModule,
    FlaIconModule,
    FlaMaskModule,
    TooltipsModule,
    DetailsTabModule,
    RTInputCountModule,
    ModalAvisoModule,
    CtrlClickModule,
  ],
  exports: [SuperDomComponent],
  providers: [SuperDomService],
})
export class SuperDomModule {}
