import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { IAlert } from '@core/interface';
import { formatterNumber } from '@shared/rocket-components/utils';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-orders-history-alert-card',
  templateUrl: './orders-history-alert-card.component.html',
  styles: [`
    :host(app-orders-history-alert-card) {
      display: contents;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersHistoryAlertCardComponent {
  @Input() dsAsset!: string;
  @Input() cdStock!: string;
  @Input() set lastPrice(lastPrice: any) {
    this._lastPrice = parseFloat(lastPrice || 0);
  }
  get lastPrice() {
    return formatterNumber(this._lastPrice, {
      maximumFractionDigits: !isNullOrUndefined(this.tickSize)
        ? parseInt(this.tickSize)
        : 2,
      minimumFractionDigits: !isNullOrUndefined(this.tickSize)
        ? parseInt(this.tickSize)
        : 2,
    });
  }
  @Input() set variation(variation: string) {
    this._variation = variation
      ? formatterNumber(parseFloat(variation))
      : '0,00';
  }
  get variation() {
    return this._variation;
  }
  @Input() variationColor!: string;
  @Input() tickSize!: string;
  @Input() set candle(candle: any) {
    if(candle){
      this.vlOpen = parseFloat(candle.vl_open).toFixed(2)
      this.vlClose = parseFloat(candle.vl_close).toFixed(2)
      this.vlLow = parseFloat(candle.vl_low).toFixed(2)
      this.vlHigh = parseFloat(candle.vl_high).toFixed(2)
      this.vlAvgPrice = candle.avg_price
      this.variationBgColor = candle.arrow_hex
      this.hideArrow = Boolean(!parseFloat(candle.vl_open))
      this._cdr.detectChanges()
    }
  }
  @Input() set price(price: any) {
    this.alertPrice = parseFloat(price || 0).toFixed(2)
  }
  @Input() isSelected: boolean = false
  @Input() set listContainerWidth(width: number) {
    this.isCompact = width < 480
  }
  @Output() editAlertHandler: EventEmitter<IAlert> = new EventEmitter<IAlert>()
  @Output() deleteAlertHandler: EventEmitter<IAlert> = new EventEmitter<IAlert>()
  get stockImage() {
    return `https://cdn.trademap.com.br/company_logos_icons/${this.dsAsset}.png`
  }
  private _lastPrice!: number;
  private _variation!: string;
  public vlOpen!: string;
  public vlClose!: string;
  public vlLow!: string;
  public vlHigh!: string;
  public vlAvgPrice!: string;
  public alertPrice!: string;
  public variationBgColor!: string;
  public hideArrow: boolean = true;
  public isCompact: boolean = false;

  constructor(private _cdr: ChangeDetectorRef) {
  }

  editAlert(event: Event) {
    event?.stopPropagation()
    this.editAlertHandler.emit()
  }

  cancelAlert(event: Event) {
    event?.stopPropagation()
    this.deleteAlertHandler.emit()
  }
}
