<div #listWrapper class="w-100 option-grid bg-neutral-strongest">
  <div class="w-100 bg-neutral-stronger d-flex p-0 justify-content-between">
    <div
      [style.width.px]="callWidthWithScroll"
      class="bg-neutral-strong text-center fs-5 fw-bold border-bottom border-neutral-medium"
    >
      Calls
    </div>
    <div>
      <rocket-button
        type="icon"
        (rocketClick)="setCentralizeLine()"
        tooltip="Centralizar"
        tooltipPosition="bottom"
      >
        <fla-icon
          name="vertical_align_center"
          size="icon-size-micro"
        ></fla-icon>
      </rocket-button>
    </div>
    <div
      [style.width.px]="putWidthWithScroll"
      class="bg-neutral-strong text-center fs-5 fw-bold border-bottom border-neutral-medium"
    >
      Puts
    </div>
  </div>
  <div class="w-100 grid-wrapper">
    <ng-container *ngIf="loading || !ready">
      <div
        class="align-items-sm-center bg-neutral-strongest d-flex justify-content-center position-absolute w-100 grid-loading"
      >
        <app-loading></app-loading>
      </div>
    </ng-container>

    <ng-container *ngIf="refId">
      <rocket-grid
        #grid
        height="100%"
        [refComponent]="refId"
        [columnDefs]="columnDefs"
        [setColumnDefsDefault]="columnDefs"
        [columnVisible]="columnVisible"
        [rowData]="rowData"
        [addRow]="addRow"
        [gridOptions]="gridOptions"
        [rowClassRules]="rowClassRule"
        fieldIndexer="strike_price"
        sortByField="strike_price"
        sortByFieldOrder="asc"
        (onGridReady)="onGridReady($event)"
        (flaColumnMoved)="onColumnMoved($event)"
        (flaColumnResized)="onColumnResized($event)"
        (onBodyScroll)="onBodyScroll($event)"
      ></rocket-grid>
    </ng-container>
  </div>
</div>
