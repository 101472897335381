import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicComponent } from './public.component';
import { RocketTraderTermsComponent } from './components/terms-of-use-and-privacy-policy/rocket-trader-terms/rocket-trader-terms.component';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { PublicRoutes } from './public.routing';
import { BtgTermsComponent } from './components/terms-of-use-and-privacy-policy/btg-terms/btg-terms.component';

@NgModule({
  imports: [CommonModule, PublicRoutes],
  declarations: [
    PublicComponent,
    RocketTraderTermsComponent,
    ConfirmEmailComponent,
    BtgTermsComponent,
  ],
})
export class PublicModule {}
