import {
  IZoomPanModifierOptions,
  ModifierMouseArgs,
  ZoomPanModifier,
} from 'scichart';
import { ScrollChartService } from '../services/scroll-chart.service';

export class RocketZoomPanModifier extends ZoomPanModifier {
  constructor(
    private scrollChartService: ScrollChartService,
    options?: IZoomPanModifierOptions
  ) {
    super(options);
  }

  protected override performModifierAction(args: ModifierMouseArgs): void {
    if (
      this.scrollChartService.canScrollChart(
        this.parentSurface,
        args.nativeEvent.movementX
      )
    ) {
      super.performModifierAction(args);
    }
  }
}
