import { ColDef } from 'ag-grid-community';
import {
  bigValueFormatter,
  formatterNumber,
} from 'src/app/utils/utils.functions';
import { StatusStockRendererComponent } from '../stock-table-rendlers/status-stock/status-stock-renderer.component';
import {
  financialFormatterFunction,
  formatterPercentageFields,
  qtdeFormatterFunction,
} from 'src/app/utils/cell-format.functions';
import { ActionsRowComponent } from '@shared/rocket-grid';
import { TimerAuctionComponent } from '../stock-table-rendlers/timer-auction.component';
import { deepClone } from '@shared/rocket-components/utils';
import { TableStockNameComponent } from '../stock-table-rendlers/table-tooltip/table-tooltip.component';

export const HEADERS_DEFAULT: Array<ColDef> = [
  {
    headerName: '',
    field: 'actions',
    minWidth: 52,
    maxWidth: 52,
    width: 52,
    lockPosition: 'left',
    pinned: 'left',
    cellRenderer: ActionsRowComponent,
    hide: true,
  },
  {
    headerName: '',
    field: 'situacao',
    minWidth: 32,
    maxWidth: 32,
    width: 32,
    lockPosition: 'left',
    pinned: 'left',
    cellRenderer: StatusStockRendererComponent,
    valueGetter: (params) => {
      return [
        params.data.situacao,
        params.data.variacao_leilao,
        params.data.preco_leilao,
        params.data.qtde_leilao_nao_atendida,
        params.data.sentido_leilao_nao_atendida,
        params.data.qtde_leilao,
        params.data.hora_abert_program,
        params.data.variacao_dia,
      ];
    },
  },
];

export const VISIBLE_HEADERS: any = {
  cd_stock: {
    hide: false,
    width: 70,
  },
  preco_ultimo: {
    hide: false,
    width: 60,
  },
  variacao_ant_dia: {
    hide: false,
    width: 72,
  },
  var_fech_anterior: {
    hide: false,
    width: 72,
  },
  variacao_dia: {
    hide: false,
    width: 76,
  },
  preco_abertura: {
    hide: false,
    width: 60,
  },
  preco_maximo: {
    hide: false,
    width: 60,
  },
  preco_minimo: {
    hide: false,
    width: 60,
  },
  preco_fechamento: {
    hide: false,
    width: 60,
  },
  volume: {
    hide: false,
    width: 80,
  },
  qtde_negocios: {
    hide: false,
    width: 80,
  },
};

export const HIDDEN_HEADERS: any = {
  precoDiaAnt: {
    hide: true,
    width: 60,
  },
  vl_fech_ant_essa_sem: {
    hide: true,
    width: 100,
  },
  vl_fech_ant_esse_mes: {
    hide: true,
    width: 100,
  },
  vl_fech_ant_esse_ano: {
    hide: true,
    width: 100,
  },
  vl_fech_ant_sem: {
    hide: true,
    width: 100,
  },
  vl_fech_ant_mes: {
    hide: true,
    width: 100,
  },
  vl_fech_ant_ano: {
    hide: true,
    width: 100,
  },
  vl_var_essa_semana: {
    hide: true,
    width: 100,
  },
  vl_var_esse_mes: {
    hide: true,
    width: 100,
  },
  vl_var_esse_ano: {
    hide: true,
    width: 100,
  },
  vl_var_uma_semana: {
    hide: true,
    width: 100,
  },
  vl_var_um_mes: {
    hide: true,
    width: 100,
  },
  vl_var_um_ano: {
    hide: true,
    width: 100,
  },
  vl_volatility_one_year: {
    hide: true,
    width: 100,
  },
  vl_volatility_two_year: {
    hide: true,
    width: 100,
  },
  vl_beta_one_year: {
    hide: true,
    width: 100,
  },
  yesterday_volume: {
    hide: true,
    width: 100,
  },
  this_week_volume: {
    hide: true,
    width: 100,
  },
  this_month_volume: {
    hide: true,
    width: 100,
  },
  this_year_volume: {
    hide: true,
    width: 100,
  },
  week_volume: {
    hide: true,
    width: 100,
  },
  month_volume: {
    hide: true,
    width: 100,
  },
  year_volume: {
    hide: true,
    width: 100,
  },
  today_delta: {
    hide: true,
    width: 100,
  },
  thirty_min_delta: {
    hide: true,
    width: 100,
  },
  vl_high_today: {
    hide: true,
    width: 100,
  },
  vl_high_yesterday: {
    hide: true,
    width: 100,
  },
  vl_high_week: {
    hide: true,
    width: 100,
  },
  vl_high_this_month: {
    hide: true,
    width: 100,
  },
  vl_high_this_year: {
    hide: true,
    width: 100,
  },
  vl_high_this_week: {
    hide: true,
    width: 100,
  },
  vl_high_month: {
    hide: true,
    width: 100,
  },
  vl_high_year: {
    hide: true,
    width: 100,
  },
  mcap: {
    hide: true,
    width: 100,
  },
  ev: {
    hide: true,
    width: 100,
  },
  pl: {
    hide: true,
    width: 100,
  },
  pvpa: {
    hide: true,
    width: 100,
  },
  evebtida: {
    hide: true,
    width: 100,
  },
  vl_mean: {
    hide: true,
    width: 100,
  },
  vl_low: {
    hide: true,
    width: 100,
  },
  vl_high: {
    hide: true,
    width: 100,
  },
  qtde_melhor_oferta_compra: {
    hide: true,
    width: 100,
  },
  valor_melhor_oferta_compra: {
    hide: true,
    width: 100,
  },
  qtde_melhor_oferta_venda: {
    hide: true,
    width: 100,
  },
  valor_melhor_oferta_venda: {
    hide: true,
    width: 100,
  },
};

export const HEADERS = deepClone({ ...VISIBLE_HEADERS, ...HIDDEN_HEADERS });

export const CONFIG_HEADERS: Array<ColDef> = [
  {
    headerName: 'Ativo',
    field: 'cd_stock',
    cellClassRules: {
      'text-brand-primary': (params) => params.data.isSelected,
    },
    lockPosition: 'left',
    pinned: 'left',
    cellRenderer: TableStockNameComponent,
    valueGetter: (params) => {
      return [params.data.cd_stock, params.data.nm_company];
    },
  },
  {
    headerName: 'Atual',
    field: 'preco_ultimo',
    headerComponentParams: ['Preços', 'preco_ultimo'],
    type: 'numericColumn',
    valueFormatter: financialFormatterFunction,
    headerTooltip: 'Preço Atual',
  },
  {
    headerName: 'Intradia',
    field: 'variacao_dia',
    headerComponentParams: ['Variações', 'variacao_dia'],
    width: 76,
    cellClass: ['tag', 'intraday-pill', 'text-center'],
    enableCellChangeFlash: true,
    valueFormatter: (params) =>
      formatterNumber(parseFloat(params.value)) + ' %',
    cellClassRules: {
      red: (params) => params.data.arrow_hex === '#D30D48',
      'smooth-red': (params) => params.data.arrow_hex === '#E66F87',
      green: (params) => params.data.arrow_hex === '#0FEF83',
      'smooth-green': (params) => params.data.arrow_hex === '#86FFC5',
      neutral: (params) => params.data.arrow_hex === '#0056FF',
      auction: (params) => params.data.arrow_hex === '#F0960F',
    },
    headerTooltip: 'Variação Intradia',
  },
  {
    headerName: 'Ontem',
    field: 'variacao_ant_dia',
    headerComponentParams: ['Variações', 'variacao_ant_dia'],
    type: 'numericColumn',
    valueFormatter: formatterPercentageFields,
    headerTooltip: 'Variação de Ontem',
  },
  {
    headerName: 'Abertura',
    field: 'preco_abertura',
    headerComponentParams: ['Preços', 'preco_abertura'],
    type: 'numericColumn',
    valueFormatter: financialFormatterFunction,
    headerTooltip: 'Preço de Abertura',
  },
  {
    headerName: 'Máximo',
    field: 'preco_maximo',
    headerComponentParams: ['Preços', 'preco_maximo'],
    type: 'numericColumn',
    valueFormatter: financialFormatterFunction,
    headerTooltip: 'Preço Máximo',
  },
  {
    headerName: 'Mínimo',
    field: 'preco_minimo',
    headerComponentParams: ['Preços', 'preco_minimo'],
    type: 'numericColumn',
    valueFormatter: financialFormatterFunction,
    headerTooltip: 'Preço Mínimo',
  },
  {
    headerName: 'Fechamento',
    field: 'preco_fechamento',
    headerComponentParams: ['Preços', 'preco_fechamento'],
    type: 'numericColumn',
    valueFormatter: financialFormatterFunction,
    headerTooltip: 'Preço de Fechamento',
  },
  {
    headerName: 'Volume Intradia',
    field: 'volume',
    headerComponentParams: ['Volume', 'volume'],
    type: 'numericColumn',
    valueFormatter: (params: any) => bigValueFormatter(params.value),
    headerTooltip: 'Volume Intradia',
  },
  {
    headerName: 'Qtd de Negócios',
    field: 'qtde_negocios',
    headerComponentParams: ['Volume', 'qtde_negocios'],
    type: 'numericColumn',
    valueFormatter: qtdeFormatterFunction,
    headerTooltip: 'Quantidade de Negócios',
  },
  {
    headerName: 'Fechamento Anterior',
    field: 'precoDiaAnt',
    headerComponentParams: ['Preços', 'precoDiaAnt'],
    type: 'numericColumn',
    valueFormatter: financialFormatterFunction,
    headerTooltip: 'Preço Fechamento Anterior',
  },
  {
    headerName: 'Na Semana',
    field: 'vl_var_essa_semana',
    headerComponentParams: ['Variações', 'vl_var_essa_semana'],
    type: 'numericColumn',
    valueFormatter: formatterPercentageFields,
    headerTooltip: 'Variação Na Semana',
  },
  {
    headerName: 'No Mês',
    field: 'vl_var_esse_mes',
    headerComponentParams: ['Variações', 'vl_var_esse_mes'],
    type: 'numericColumn',
    valueFormatter: formatterPercentageFields,
    headerTooltip: 'Variação no Mês',
  },
  {
    headerName: 'No Ano',
    field: 'vl_var_esse_ano',
    headerComponentParams: ['Variações', 'vl_var_esse_ano'],
    type: 'numericColumn',
    valueFormatter: formatterPercentageFields,
    headerTooltip: 'Variação no Ano',
  },
  {
    headerName: '1 Semana',
    field: 'vl_var_uma_semana',
    headerComponentParams: ['Variações', 'vl_var_uma_semana'],
    type: 'numericColumn',
    valueFormatter: formatterPercentageFields,
    headerTooltip: 'Variação 1 Semana',
  },
  {
    headerName: '1 Mês',
    field: 'vl_var_um_mes',
    headerComponentParams: ['Variações', 'vl_var_um_mes'],
    type: 'numericColumn',
    valueFormatter: formatterPercentageFields,
    headerTooltip: 'Variação 1 Mês',
  },
  {
    headerName: '12 meses',
    field: 'vl_var_um_ano',
    headerComponentParams: ['Variações', 'vl_var_um_ano'],
    type: 'numericColumn',
    valueFormatter: formatterPercentageFields,
    headerTooltip: 'Variação 12 Meses',
  },
  {
    headerName: 'Vol. 12m',
    field: 'vl_volatility_one_year',
    headerComponentParams: ['Volatilidade', 'vl_volatility_one_year'],
    type: 'numericColumn',
    valueFormatter: (params: any) => formatterNumber(params.value),
    headerTooltip: 'Volatilidade 12 meses',
  },
  {
    headerName: 'Vol. 24m',
    field: 'vl_volatility_two_year',
    headerComponentParams: ['Volatilidade', 'vl_volatility_two_year'],
    type: 'numericColumn',
    valueFormatter: (params: any) => formatterNumber(params.value),
    headerTooltip: 'Volatilidade 24 meses',
  },
  {
    headerName: 'Beta Dia 12m',
    field: 'vl_beta_one_year',
    headerComponentParams: ['Volatilidade', 'vl_beta_one_year'],
    type: 'numericColumn',
    valueFormatter: (params: any) => formatterNumber(params.value),
    headerTooltip: 'Beta Dia 12m',
  },
  {
    headerName: 'Volume Ontem',
    field: 'yesterday_volume',
    headerComponentParams: ['Volume', 'yesterday_volume'],
    type: 'numericColumn',
    valueFormatter: (params: any) => bigValueFormatter(params.value),
    headerTooltip: 'Volume Ontem',
  },
  {
    headerName: 'Volume Semana',
    field: 'this_week_volume',
    headerComponentParams: ['Volume', 'this_week_volume'],
    type: 'numericColumn',
    valueFormatter: (params: any) => bigValueFormatter(params.value),
    headerTooltip: 'Volume Na Semana',
  },
  {
    headerName: 'Volume no Mês',
    field: 'this_month_volume',
    headerComponentParams: ['Volume', 'this_month_volume'],
    type: 'numericColumn',
    valueFormatter: (params: any) => bigValueFormatter(params.value),
    headerTooltip: 'Volume No Mês',
  },
  {
    headerName: 'Volume no Ano',
    field: 'this_year_volume',
    headerComponentParams: ['Volume', 'this_year_volume'],
    type: 'numericColumn',
    valueFormatter: (params: any) => bigValueFormatter(params.value),
    headerTooltip: 'Volume No Ano',
  },
  {
    headerName: 'Volume 1 Semana',
    field: 'week_volume',
    headerComponentParams: ['Volume', 'week_volume'],
    type: 'numericColumn',
    valueFormatter: (params: any) => bigValueFormatter(params.value),
    headerTooltip: 'Volume 1 Semana',
  },
  {
    headerName: 'Volume 1 Mês',
    field: 'month_volume',
    headerComponentParams: ['Volume', 'month_volume'],
    type: 'numericColumn',
    valueFormatter: (params: any) => bigValueFormatter(params.value),
    headerTooltip: 'Volume 1 Mês',
  },
  {
    headerName: 'Volume 12 Meses',
    field: 'year_volume',
    headerComponentParams: ['Volume', 'year_volume'],
    type: 'numericColumn',
    valueFormatter: (params: any) => bigValueFormatter(params.value),
    headerTooltip: 'Volume 1 Ano',
  },
  {
    headerName: 'ΔVolume 1D',
    field: 'today_delta',
    headerComponentParams: ['Delta Volume', 'today_delta'],
    type: 'numericColumn',
    valueFormatter: qtdeFormatterFunction,
    headerTooltip: 'Delta Volume 1D',
  },
  {
    headerName: 'ΔVolume 1h',
    field: 'thirty_min_delta',
    headerComponentParams: ['Delta Volume', 'thirty_min_delta'],
    type: 'numericColumn',
    valueFormatter: (params: any) => formatterNumber(params.value),
    headerTooltip: 'Delta Volume 1h',
  },
  {
    headerName: 'Range Intradia',
    field: 'vl_high_today',
    headerComponentParams: ['Ranges', 'vl_high_today'],
    type: 'numericColumn',
    headerTooltip: 'Range Intradia',
    valueFormatter: financialFormatterFunction,
  },
  {
    headerName: 'Range Ontem',
    field: 'vl_high_yesterday',
    headerComponentParams: ['Ranges', 'vl_high_yesterday'],
    type: 'numericColumn',
    headerTooltip: 'Range Ontem',
    valueFormatter: financialFormatterFunction,
  },
  {
    headerName: 'Range Semana',
    field: 'vl_high_week',
    headerComponentParams: ['Ranges', 'vl_high_week'],
    type: 'numericColumn',
    headerTooltip: 'Range Semana',
    valueFormatter: financialFormatterFunction,
  },
  {
    headerName: 'Range Mês',
    field: 'vl_high_this_month',
    headerComponentParams: ['Ranges', 'vl_high_this_month'],
    type: 'numericColumn',
    headerTooltip: 'Range Mês',
    valueFormatter: financialFormatterFunction,
  },
  {
    headerName: 'Range Ano',
    field: 'vl_high_this_year',
    headerComponentParams: ['Ranges', 'vl_high_this_year'],
    type: 'numericColumn',
    headerTooltip: 'Range Ano',
    valueFormatter: financialFormatterFunction,
  },
  {
    headerName: 'Range 1 Semana',
    field: 'vl_high_this_week',
    headerComponentParams: ['Ranges', 'vl_high_this_week'],
    type: 'numericColumn',
    headerTooltip: 'Range 1 Semana',
    valueFormatter: financialFormatterFunction,
  },
  {
    headerName: 'Range 1 Mês',
    field: 'vl_high_month',
    headerComponentParams: ['Ranges', 'vl_high_month'],
    type: 'numericColumn',
    headerTooltip: 'Range 1 Mês',
    valueFormatter: financialFormatterFunction,
  },
  {
    headerName: 'Range 12 Meses',
    field: 'vl_high_year',
    headerComponentParams: ['Ranges', 'vl_high_year'],
    type: 'numericColumn',
    headerTooltip: 'Range 12 Meses',
    valueFormatter: financialFormatterFunction,
  },
  {
    headerName: 'Mcap',
    field: 'mcap',
    headerComponentParams: ['Fundamentalista', 'mcap'],
    type: 'numericColumn',
    valueFormatter: (params: any) => bigValueFormatter(params.value),
    headerTooltip: 'Mcap',
  },
  {
    headerName: 'EV',
    field: 'ev',
    headerComponentParams: ['Fundamentalista', 'ev'],
    type: 'numericColumn',
    valueFormatter: (params: any) => bigValueFormatter(params.value),
    headerTooltip: 'EV/EBITDA',
  },
  {
    headerName: 'P/L',
    field: 'pl',
    headerComponentParams: ['Fundamentalista', 'pl'],
    type: 'numericColumn',
    valueFormatter: (params: any) => formatterNumber(params.value),
    headerTooltip: 'P/L',
  },
  {
    headerName: 'P/VPA',
    field: 'pvpa',
    headerComponentParams: ['Fundamentalista', 'pvpa'],
    type: 'numericColumn',
    valueFormatter: (params: any) => formatterNumber(params.value),
    headerTooltip: 'P/PVA',
  },
  {
    headerName: 'EV/EBITDA',
    field: 'evebtida',
    headerComponentParams: ['Fundamentalista', 'evebtida'],
    type: 'numericColumn',
    valueFormatter: (params: any) => formatterNumber(params.value),
    headerTooltip: 'EV/EBITDA',
  },
  {
    headerName: 'P.Alvo Médio',
    field: 'vl_mean',
    headerComponentParams: ['P Alvo', 'vl_mean'],
    type: 'numericColumn',
    valueFormatter: (params: any) => formatterNumber(params.value),
    headerTooltip: 'Preço Alvo Médio',
  },
  {
    headerName: 'P.Alvo Mín',
    field: 'vl_low',
    headerComponentParams: ['P Alvo', 'vl_low'],
    type: 'numericColumn',
    valueFormatter: (params: any) => formatterNumber(params.value),
    headerTooltip: 'Preço Alvo Mínimo',
  },
  {
    headerName: 'P.Alvo Máx',
    field: 'vl_high',
    headerComponentParams: ['P Alvo', 'vl_high'],
    type: 'numericColumn',
    valueFormatter: (params: any) => formatterNumber(params.value),
    headerTooltip: 'Preço Alvo Máximo',
  },
  {
    headerName: 'Qtd Compra',
    field: 'qtde_melhor_oferta_compra',
    headerComponentParams: ['Book', 'qtde_melhor_oferta_compra'],
    type: 'numericColumn',
    valueFormatter: (params: any) => formatterNumber(params.value),
    headerTooltip: 'Qtd de melhor oferta compra',
  },
  {
    headerName: 'Preço Compra',
    field: 'valor_melhor_oferta_compra',
    headerComponentParams: ['Book', 'valor_melhor_oferta_compra'],
    type: 'numericColumn',
    valueFormatter: financialFormatterFunction,
    headerTooltip: 'Preço de melhor oferta de compra',
  },
  {
    headerName: 'Qtd Venda',
    field: 'qtde_melhor_oferta_venda',
    headerComponentParams: ['Book', 'qtde_melhor_oferta_venda'],
    type: 'numericColumn',
    valueFormatter: (params: any) => formatterNumber(params.value),
    headerTooltip: 'Qtd de melhor oferta venda',
  },
  {
    headerName: 'Preço Venda',
    field: 'valor_melhor_oferta_venda',
    headerComponentParams: ['Book', 'valor_melhor_oferta_venda'],
    type: 'numericColumn',
    valueFormatter: financialFormatterFunction,
    headerTooltip: 'Preço de melhor oferta de venda',
  },
];

export const HEADERS_AUCTION: any = {
  variacao_leilao: {
    hide: false,
    width: 70,
  },
  preco_leilao: {
    hide: false,
    width: 90,
  },
  qtde_leilao_nao_atendida: {
    hide: false,
    width: 90,
  },
  qtde_leilao: {
    hide: false,
    width: 120,
  },
  hora_abert_program: {
    hide: false,
    width: 70,
  },
};

export const CONFIG_HEADERS_AUCTION: Array<ColDef> = [
  {
    headerName: 'Leilão',
    field: 'variacao_leilao',
    hide: true,
    width: 70,
    headerComponentParams: ['Leilão', 'variacao_leilao'],
    headerTooltip: 'Variação no leilão',
    headerClass: 'text-feedback-warning',
    type: 'numericColumn',
    valueFormatter: (params: any) => {
      return params.value && params.data?.situacao === 'LEILAO'
        ? `${formatterPercentageFields(params)}`
        : '-';
    },
  },
  {
    headerName: 'Atual Leilão',
    field: 'preco_leilao',
    hide: true,
    width: 90,
    headerComponentParams: ['Leilão', 'preco_leilao'],
    headerTooltip: 'Preço Atual do Leilão',
    headerClass: 'text-feedback-warning',
    type: 'numericColumn',
    valueFormatter: (params: any) =>
      params.value && params.data?.situacao === 'LEILAO'
        ? financialFormatterFunction(params)
        : '-',
  },
  {
    headerName: 'QTD não atendidas',
    field: 'qtde_leilao_nao_atendida',
    hide: true,
    width: 90,
    headerComponentParams: ['Leilão', 'qtde_leilao_nao_atendida'],
    headerTooltip: 'Quantidade não atendida do leilão',
    headerClass: 'text-feedback-warning',
    type: 'numericColumn',
    tooltipValueGetter: (params) => {
      const isAuction = params.value && params.data?.situacao === 'LEILAO';
      const isA = params.data.sentido_leilao_nao_atendida == 'A';
      return isAuction
        ? isA
          ? 'Compra não atendida'
          : 'Venda não atendida'
        : '';
    },
    valueFormatter: (params: any) => {
      const isAuction = params.value && params.data?.situacao === 'LEILAO';
      const isA = params.data.sentido_leilao_nao_atendida == 'A';
      const quantity = `NA ${bigValueFormatter(params.value)}`;
      return isAuction ? (isA ? `C${quantity}` : `V${quantity}`) : '-';
    },
  },
  {
    headerName: 'QTD negociadas',
    field: 'qtde_leilao',
    hide: true,
    width: 120,
    headerComponentParams: ['Leilão', 'qtde_leilao'],
    headerTooltip: 'Quantidade negociada no leilão ',
    headerClass: 'text-feedback-warning',
    type: 'numericColumn',
    valueFormatter: (params: any) =>
      params.value && params.data?.situacao === 'LEILAO'
        ? bigValueFormatter(params.value)
        : '-',
  },
  {
    headerName: 'Tempo',
    field: 'hora_abert_program',
    hide: true,
    width: 70,
    headerComponentParams: ['Leilão', 'hora_abert_program'],
    headerTooltip: 'Tempo para encerrar leilão',
    headerClass: 'text-feedback-warning',
    cellRenderer: TimerAuctionComponent,
  },
];

export const HEADERS_AUCTION_IGNORE: string =
  'actions,situacao,variacao_leilao,preco_leilao,qtde_leilao,qtde_leilao_nao_atendida,hora_abert_program';

export const HEADERS_BIG_NUMBER_FORMAT: string =
  'volume,yesterday_volume,this_week_volume,this_month_volume,this_year_volume,week_volume,month_volume,year_volume,AUCTION_HEADERSap,ev';

export const AUCTION_HEADERS = (): ColDef<any>[] => {
  const auctionColumns = CONFIG_HEADERS_AUCTION.map((column) => {
    if (column.field) {
      const columnSettings = HEADERS_AUCTION[column.field];
      if (columnSettings) {
        column.hide = columnSettings.hide;
        column.width = columnSettings.width;
        return column;
      }
    }
    return column;
  });

  const gridSettings = HEADERS;
  const gridColumns = CONFIG_HEADERS.map((column) => {
    if (column.field) {
      const columnSettings = gridSettings[column.field];
      if (columnSettings) {
        column.hide = columnSettings.hide;
        column.width = columnSettings.width;
        return column;
      }
    }
    return column;
  });
  return [HEADERS_DEFAULT[1], ...auctionColumns, ...gridColumns];
};
