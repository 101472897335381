import { deepClone } from '@shared/rocket-components/utils';
import { CategoryCoordinateCalculator, NumberRange } from 'scichart';
import { ModifierMouseArgs } from 'scichart/Charting/ChartModifiers/ModifierMouseArgs';
import {
  IMouseWheelZoomModifierOptions,
  MouseWheelZoomModifier,
} from 'scichart/Charting/ChartModifiers/MouseWheelZoomModifier';
import { Point } from 'scichart/Core/Point';
import { CANDLE_IDS } from '../constants/tiger-chart.constants';
import { Subject } from 'rxjs';
export const DEFAULT_DELTA_VALUE = 0.05;
export class wheelZoomModifier extends MouseWheelZoomModifier {
  private axisId: string = '';
  event$ = new Subject<ModifierMouseArgs>();
  constructor(options?: IMouseWheelZoomModifierOptions, xAxisId?: string) {
    super(options);
    if (xAxisId) this.axisId = xAxisId;
  }

  makeGrow(factor: number) {
    const axis = this.parentSurface.xAxes.asArray()[0];
    const minVisible = axis.visibleRange.min;
    const max = axis.visibleRange.max;
    const mainSeries = this.parentSurface.renderableSeries.getById(
      CANDLE_IDS.MAIN_SERIE
    );
    const nativeXElements = mainSeries.dataSeries.getNativeXValues();
    const lastXElement = nativeXElements.get(nativeXElements.size() - 1);
    const calculator =
      axis.getCurrentCoordinateCalculator() as CategoryCoordinateCalculator;
    const indexLastElement = calculator.transformDataToIndex(lastXElement);
    let pointX = calculator.getCoordinate(max);
    if (max > indexLastElement) {
      pointX = calculator.getCoordinate(indexLastElement);
    }
    const qtty = mainSeries.dataSeries.count();
    const doubleCandleMoreHalf = 2.5;
    if (minVisible < qtty - doubleCandleMoreHalf || factor > 0) {
      this.growBy(new Point(pointX, 0), axis, factor);
    }
  }

  override modifierMouseWheel(args: ModifierMouseArgs): void {
    const xAxis = this.parentSurface.xAxes.getById(this.axisId);
    if (args.ctrlKey) {
      args.nativeEvent.preventDefault();
      this.makeGrow(
        args.mouseWheelDelta > 0 ? DEFAULT_DELTA_VALUE : -DEFAULT_DELTA_VALUE
      );
      return;
    }
    const series = this.parentSurface.renderableSeries.getById(
      CANDLE_IDS.MAIN_SERIE
    );
    const hasAxisAndSeries = xAxis && series;
    let max = 0;
    if (hasAxisAndSeries) {
      max = deepClone(series.dataSeries.count() + 10);
    }
    super.modifierMouseWheel(args);
    if (hasAxisAndSeries) {
      xAxis.visibleRange = new NumberRange(xAxis.visibleRange.min, max);
    }
  }

  zoom(mouseWheelDelta: number) {
    const domSvg = this.parentSurface.domSvgContainer.getBoundingClientRect();
    const mousePoint = new Point(domSvg.width / 2, domSvg.height / 2);
    this.performZoom(mousePoint, mouseWheelDelta);
  }
}
