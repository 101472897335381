<rocket-modal>
  <rocket-modal-header>
    <div class="hstack align-items-center justify-content-between w-100 mr-3">
      <h1 class="fs-2 fw-bold">Ajude-nos com sua avaliação</h1>
    </div>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <div class="d-flex flex-column">
      <span class="mb-3">
        Nos conte sobre a sua experiência com o
        <span class="text-brand-primary fw-bold">RocketTrader</span>
      </span>
      <div class="emojis d-flex gap-8 px-7 mb-3">
        <div
          class="d-flex flex-column align-items-center cursor-pointer"
          *ngFor="let rating of ratings"
          (click)="emojiSelect(rating)"
          [ngClass]="{
            'text-brand-primary':
              ratingSelect && ratingSelect.icon == rating.icon
          }"
        >
          <span>{{ rating.icon }}</span>
          <span>{{ rating.label }}</span>
        </div>
      </div>
      <textarea
        id="comment_textarea"
        name="text-input"
        rows="9"
        class="form-control"
        [(ngModel)]="ratingComment"
        placeholder="Como podemos melhorar? Deixe seu comentário."
        (input)="validityTextLength()"
        [maxlength]="limitText"
      ></textarea>
      <div class="d-flex justify-content-end fs-5">
        <span [ngClass]="{ 'text-feedback-error': lengthText >= limitText }">{{
          lengthText
        }}</span>
        / {{ limitText }}
      </div>
      <div class="my-3 d-flex justify-content-center">
        <rocket-button
          [isDisabled]="
            !ratingSelect || !ratingComment || lengthText > limitText
          "
          css="mr-2 w-75"
          label="Enviar avaliação"
          (click)="sendRating()"
        ></rocket-button>
      </div>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
