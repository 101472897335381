<div #card class="position-card card w-100 bg-neutral-strong py-2 cursor-pointer">
  <div class="card-body">
    <div class="hstack d-flex justify-content-between"
    [ngClass]="{'flex-wrap': isCompact}">
      <div class="hstack order-first" [ngClass]="isCompact ? 'w-50' : 'w-25'">
        <app-stock-logo [image]="image" class="mr-2"></app-stock-logo>
        <div>
          <p class="card-title fs-5" [ngClass]="{'text-brand-primary': isSelected }">{{ cdStock }}</p>
          <small class="card-subtitle fw-bold">
            {{ qttyFinal }} | 
            <span [class]="plColor">
              R$ {{ pl }}
            </span>
          </small>
        </div>
      </div>
      <div class="d-flex justify-content-between fw-bold" 
      [ngClass]="{'w-50': !isCompact, 'compact-infos': isCompact}">
        <div class="info-block d-flex flex-column align-items-center p-1">
          <span class="fs-6">Qtd aberto</span>
          <span class="fs-5">{{netDay}}</span>
        </div>
        <div class="info-block d-flex flex-column align-items-center p-1">
          <span class="fs-6">Médio</span>
          <span class="fs-5">{{vlPriceAvg}}</span>
        </div>
        <div class="info-block d-flex flex-column align-items-center p-1">
          <span class="fs-6">P&L aberto</span>
          <span class="fs-5" [ngClass]="pnlOpenColor">{{pnlOpen}}</span>
        </div>
        <div class="info-block d-flex flex-column align-items-center p-1">
          <span class="fs-6">P&L dia</span>
          <span class="fs-5" [ngClass]="pnlDayColor">{{pnlDay}}</span>
        </div>
        <div class="info-block d-flex flex-column align-items-center p-1">
          <span class="fs-6">P&L total</span>
          <span class="fs-5" [ngClass]="pnlTotalColor">{{pnlTotal}}</span>
        </div>
      </div>
      <div class="w-25 d-flex justify-content-end">
        <div class="align-items-center d-inline-flex gap-2">
          <div class="chart-container-chart mr-2">
            <ng-container *ngIf="chart?.length">
              <app-chart-line
                [data]="chart!"
                [tickSize]="tickSize"
                [lineColor]="variationColor"
                [cdStock]="cdStock"
                [updateLastValue]="chartLastPrice"
              ></app-chart-line>
            </ng-container>
          </div>
          <div
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <span
              class="fs-6 text-truncate custom-font-size fw-bold"
              style="max-width: 55px"
              [tooltip]="lastPrice"
            >
              {{ lastPrice }}
            </span>
            <app-tag
              [useSmallTag]="true"
              [isVariation]="true"
              [variation]="variation || 0"
              [bgColor]="variationColor"
              [color]="variationFontColor"
              symbol="%"
              [situation]="situation"
            ></app-tag>
          </div>
          <div>
            <fla-icon name="close" css="cursor-pointer text-feedback-negative no-select" (click)="resetStockPosition($event)"></fla-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>