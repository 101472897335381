import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TOptionsStrategyType } from '@shared/components/options/types';
import { FlaTSize } from '@shared/rocket-components/components/icon/types/icon.types';

@Component({
  selector: 'app-options-strategy-modal-strategy-selector',
  templateUrl: './options-strategy-modal-strategy-selector.component.html',
  styleUrls: ['./options-strategy-modal-strategy-selector.component.scss'],
})
export class OptionsStrategyModalStrategySelectorComponent implements OnInit {
  @Input() strategy!: TOptionsStrategyType;
  @Input() selectedStrategy!: string;
  @Input() hideLabel: boolean = false;
  @Output() strategySelected: EventEmitter<TOptionsStrategyType> =
    new EventEmitter();

  src: string = '';
  arrows: { [key: string]: string } = {
    alta: 'arrow_drop_up',
    baixa: 'arrow_drop_down',
    neutra: 'play_arrow',
  };
  arrowClasses: { [key: string]: string } = {
    alta: 'text-feedback-success',
    baixa: 'text-feedback-error',
    neutra: 'text-muted',
  };
  arrowSizes: { [key: string]: FlaTSize } = {
    alta: 'icon-size-tiny',
    baixa: 'icon-size-tiny',
    neutra: 'icon-size-nano',
  };
  actionArrowClass: string = '';
  volatilityArrow: string = '';
  volatilityArrowClass: string = '';

  ngOnInit() {
    this.src = `/assets/svg/option-strategies/${
      this.strategy.icon || 'fallback'
    }.svg`;
  }

  triggerClick() {
    !this.hideLabel && this.strategySelected.emit(this.strategy);
  }
}
