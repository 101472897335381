export const medianPriceTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  A Mediana é uma medida estatística que representa o valor médio de uma série
  de dados ordenados por ordem crescente ou decrescente. É frequentemente
  utilizado em análises de mercado para determinar o preço mediano de uma
  determinada commodity ou imóvel. A Mediana é calculada pela identificação do
  valor central da série de dados, isto é, o valor que divide a série de dados
  em duas partes iguais.
</div>
<div class="mb-3">
  Ao contrário da média aritmética, a Mediana não é influenciada por valores
  extremos ou discrepantes na série de dados. Por essa razão, é considerado um
  indicador mais confiável para representar o valor mediano de uma série de
  dados.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">O cálculo da Mediana é feito da seguinte forma:</div>
<div class="mb-3">
  1. Ordene a série de dados em ordem crescente ou decrescente.
</div>
<div class="mb-3">
  2. Identifique o valor central da série de dados. Se a série de dados contiver
  um número par de observações, a Mediana é calculada pela média aritmética dos
  dois valores centrais. Se a série de dados contiver um número ímpar de
  observações, a Mediana é o valor central da série de dados.
</div>
<div class="mb-3">
  3. A Mediana representa o valor mediano da série de dados.
</div>
<div class="mb-3">Por exemplo, se os preços em ordem crescente forem:</div>
<div class="mb-3">[10, 20, 30, 40, 50]</div>
<div class="mb-3">
  O preço mediano seria 30, já que este é o preço que está na metade da lista.
  Se a lista tivesse um número par de preços, como:
</div>
<div class="mb-3">[10, 20, 30, 40]</div>
<div class="mb-3">
  O preço mediano seria a média dos dois preços do meio, ou seja, (20 + 30) / 2
  = 25.
</div>
<div class="mb-3">
  A Mediana é uma ferramenta útil para analisar o comportamento do mercado e
  tomar decisões de investimento. É recomendável utilizá-lo em conjunto com
  outras medidas estatísticas para obter uma visão mais completa do mercado.
</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  O preço mediano é uma medida estatística que representa o valor que está
  exatamente no meio de uma lista ordenada de preços. Ele é útil porque é menos
  sensível a valores extremos (muito altos ou muito baixos) do que o preço
  médio, por exemplo. Para calcular o preço mediano, basta ordenar os preços em
  ordem crescente ou decrescente e selecionar o valor que está exatamente no
  meio da lista. Se houver um número par de preços, o preço médio é calculado
  como a média aritmética dos dois valores do meio.
</div>
<div class="mb-3">
  <img class="w-75" src="/assets/indicators/median-price-como-funciona.png" />
</div>
<div class="fw-bolder">Como configurar</div>
<div class="fw-bolder">Valores</div>
<div class="mb-3">Sem valores pré-definidos para configurar.</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">Linha</div>
<div class="mb-3">
  Pode alternar a visibilidade do Mediana. Também pode selecionar a cor, a
  espessura e o estilo da linha do Mediana.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/median-price-estilo.png"
  />
</div>
`;
