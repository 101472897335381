import { Injectable } from '@angular/core';
import { AbstractCacheService } from './AbstractCacheService';
import { IIndicatorHistoryWithAverage } from '@shared/components/business-profile/business-profile.interface';

@Injectable({
  providedIn: 'root',
})
export class IndicatorHistoryWithAverageCacheService extends AbstractCacheService<
  IIndicatorHistoryWithAverage[]
> {}
