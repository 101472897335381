import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingModule } from '@shared/components/loading/loading.module';
import {
  FlaIconModule,
  FlaTabModule,
  RocketButtonModule,
} from '@shared/rocket-components/components';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { RocketGridModule } from '@shared/rocket-grid';
import { FundsTableComponent } from './funds-table.component';
import { RentService } from '../../service/rent.service';
import { FundsChartComponent } from '../funds-chart/funds-chart.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NoInfoModule } from '@shared/components/business-profile/no-info/no-info.module';

@NgModule({
  imports: [
    CommonModule,
    RocketGridModule,
    LoadingModule,
    FlaIconModule,
    RocketButtonModule,
    TooltipsModule,
    FlaTabModule,
    ScrollingModule,
    NoInfoModule,
  ],
  declarations: [FundsTableComponent, FundsChartComponent],
  exports: [FundsTableComponent],
  providers: [RentService],
})
export class FundsTableModule {}
