import { TEMP_TAB_HEADER } from '../constants/header.contanst';
import { FormControl } from '@angular/forms';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { deepClone, isNullOrWhiteSpace } from '@shared/rocket-components/utils';
import { debounceTime, filter, fromEvent, Subject, takeUntil, tap } from 'rxjs';
import { HomeService } from '@modules/home/service/home.service';
import { IWorkSpace } from 'src/app/core/interface';
import {
  IContextMenuOptions,
  WS_COMMANDS,
} from '@shared/components/context-menu/context-menu.component';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { HeaderService } from '../header.service';
import { WorkspaceComponentService } from '@modules/home/service/workspace-component.service';

@Component({
  selector: 'app-header-tabs',
  templateUrl: './header-tabs.component.html',
  styleUrls: ['./header-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderTabsComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild('editWsNameElement') private _editWsNameElement!: ElementRef;
  @Input() visiblesWorkspaces: IWorkSpace[] = [];
  @Input() workspaceActive!: IWorkSpace;
  editForm: FormControl = new FormControl(undefined);
  isDisplayContextMenu!: boolean;
  private _tabIndex!: number;
  contextMenuOptions!: IContextMenuOptions;
  private _isSingleClick = true;
  private _onDestroy = new Subject<void>();
  private subjectDebounced = new Subject<void>();
  private _clickEvent = new Subject<void>();

  get isLastWorkspaceVisible() {
    return !(this.workspaceComponentService.getVisiblesWorkspaces().length > 1);
  }
  constructor(
    private homeService: HomeService,
    private cdr: ChangeDetectorRef,
    private workspaceComponentService: WorkspaceComponentService,
    private headerService: HeaderService
  ) {
    this.initializeTotalSubscription();
  }

  ngOnInit(): void {
    this.workspaceHeaderClick();
    this.homeService
      .onExecComponent()
      .pipe(
        takeUntil(this._onDestroy),
        tap((payload: any) => {
          const { command, data } = payload;
          switch (command.cod) {
            case WS_COMMANDS.DELETE_WS:
              this.homeService.processRemoveWorkspace([data]);
              break;
            case WS_COMMANDS.REFRESH_WS:
              this.homeService.resetWorkspace(data);
              break;
            case WS_COMMANDS.EDIT_NAME:
              this.showEditMode(data);
              break;
            case WS_COMMANDS.DUPLICATE_WS:
              this.homeService.openCloneModal(data);
              break;
          }
        })
      )
      .subscribe(() => {
        this.subjectDebounced.next();
      });
  }

  ngOnChanges(): void {
    this.subjectDebounced.next();
  }

  private initializeTotalSubscription(): void {
    this.subjectDebounced
      .pipe(debounceTime(100))
      .subscribe(() => this.cdr.detectChanges());
  }

  ngAfterViewInit() {
    this.subjectDebounced.next();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onMenuContextClick = (event: MouseEvent, item: any) => {
    event.preventDefault();
    this.isDisplayContextMenu = false;
    const position = {
      top: event.clientY,
      left: event.clientX,
    };
    this.contextMenuOptions = {
      type: 'WORKSPACE_TAB',
      position,
      title: item.name,
      data: { ws: item, isLastWs: this.isLastWorkspaceVisible },
    };
    this.homeService.openContextMenu$.next(this.contextMenuOptions);
    this.isDisplayContextMenu = true;
    this.subjectDebounced.next();
  };

  onCloseContextMenu = () => {
    this.isDisplayContextMenu = false;
    this.subjectDebounced.next();
  };

  hide(workspace: IWorkSpace) {
    if (this.headerService.isLockWorkspace()) return;
    if (isNullOrWhiteSpace(workspace.id)) {
      this.workspaceComponentService.removeWorkspace(TEMP_TAB_HEADER as any);
      this.subjectDebounced.next();
      return;
    }
    if (workspace.editMode) {
      workspace.editMode = false;
      this._cancelOutsideClickEvent();
      return;
    }

    this.homeService.hideWorkspace(workspace).subscribe();
  }

  showEditMode(item: IWorkSpace, index?: number) {
    if (this.headerService.isLockWorkspace()) return;
    this._isSingleClick = false;
    const value: string = (item.name ?? '') as string;
    this.editForm.setValue(value);
    item.editMode = true;
    if (index !== undefined) this._tabIndex = index;
    this._onClickOutsideTab();
  }

  drop(event: any) {
    moveItemInArray(
      this.visiblesWorkspaces,
      event.previousIndex,
      event.currentIndex
    );
    this.headerService.setVisibleTabs(this.visiblesWorkspaces);
  }

  editName(workspace: IWorkSpace) {
    workspace.name = (this.editForm.value ?? '') as string;
    workspace.editMode = false;
    this._cancelOutsideClickEvent();

    if (isNullOrWhiteSpace(workspace.id)) {
      this.homeService.newWorkspace(workspace.name).subscribe((res: any) => {
        this.editForm.reset();
        this.workspaceComponentService.changeActiveWorkspace(res);
        this.workspaceComponentService.removeWorkspace(TEMP_TAB_HEADER as any);
        this.subjectDebounced.next();
      });
    } else {
      this.homeService.updateWorkspace(deepClone(workspace)).subscribe(() => {
        this.workspaceComponentService.createdOrUpdateWorkspace(
          workspace,
          false
        );
        this.editForm.reset();
        this.subjectDebounced.next();
      });
    }
  }

  activeWorkspace(workspace: IWorkSpace) {
    this._isSingleClick = true;
    if (workspace.id == this.workspaceComponentService.getActiveWorkspace().id)
      return;
    if (this._isSingleClick) {
      workspace.active = true;
      this.homeService.updateWorkspace(deepClone(workspace!)).subscribe(() => {
        this.workspaceComponentService.changeActiveWorkspace(workspace);
        this.subjectDebounced.next();
      });
    }
  }

  newWorkspace(data?: any) {
    if (this.headerService.isLockWorkspace()) return;
    const isActive = !!data;
    this.workspaceComponentService.createdOrUpdateWorkspace(
      data ?? deepClone(TEMP_TAB_HEADER),
      isActive
    );
    this.subjectDebounced.next();
  }

  private workspaceHeaderClick() {
    this.headerService.addWorkspaceObservable().subscribe(() => {
      this.newWorkspace();
      this.subjectDebounced.next();
    });
    this.headerService
      .changeWorkspaceObservable()
      .subscribe((workspace: IWorkSpace) => {
        this._showTab(workspace);
        this.subjectDebounced.next();
      });
  }

  private _showTab(workspace: IWorkSpace) {
    this.workspaceComponentService.createdOrUpdateWorkspace(workspace);
    this.homeService.updateWorkspace(workspace).subscribe();
    this.subjectDebounced.next();
  }

  private _onClickOutsideTab(): void {
    this._clickEvent = new Subject<void>();
    fromEvent(document, 'click')
      .pipe(
        takeUntil(this._clickEvent),
        filter(
          (event) =>
            this._editWsNameElement?.nativeElement &&
            !this._editWsNameElement.nativeElement.contains(event.target)
        )
      )
      .subscribe(() => {
        this.visiblesWorkspaces[this._tabIndex].editMode = false;
        this._cancelOutsideClickEvent();
        this.cdr.detectChanges();
      });
  }

  private _cancelOutsideClickEvent(): void {
    if (this._clickEvent.closed) return;
    this._clickEvent.next();
    this._clickEvent.unsubscribe();
  }
}
