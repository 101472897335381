import { ComponentRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { randomId } from '@shared/rocket-components/utils';
import { DrawHelperMessageComponent } from './draw-helper-message.component';
import { HelperMessageChart } from './draw-helper-message.interface';
import { RocketCreateComponentService } from '@shared/rocket-components/services';

@Injectable({ providedIn: 'root' })
export class DrawHelperMessageService {
  private _component!: DrawHelperMessageComponent;
  private _element!: HTMLElement | undefined;
  private _componentRef!: ComponentRef<DrawHelperMessageComponent> | undefined;
  private onClose$ = new Subject<any>();
  private toolbarDrawRefId = randomId('draw_helper_message');
  showDrawConfigModal$ = new Subject<any>();

  constructor(private _createComponent: RocketCreateComponentService) {}

  show(options: HelperMessageChart): {
    onClose: Observable<any>;
  } {
    this._removeBeforeInit();

    const create = this._createComponent.create(DrawHelperMessageComponent);
    this._componentRef = create.componentRef;
    this._component = this._componentRef.instance;
    this._element = create.rootNodeElement as HTMLElement;
    this._element.id = this.toolbarDrawRefId;

    this._bindValuesComponent(options);
    this._setComponentView(options.ref);
    this._component.onClose.subscribe((value) => {
      //this.onClose$.next(value);
      this.destroyComponent();
    });

    return { onClose: this.onClose$.asObservable() };
  }

  private _bindValuesComponent(value: HelperMessageChart) {
    this._component.parentRef = value.ref;
    this._component.message = value.helperMessage;
  }

  private _setComponentView(id: string) {
    const rootComponent = document.getElementById(id)!;
    rootComponent.append(this._element!!);
  }

  private _removeBeforeInit() {
    const findAll = document.querySelectorAll('draw-helper-message');
    if (findAll) {
      findAll.forEach((elem) => elem.remove());
    }
  }

  destroyComponent() {
    this._element!!.remove();
    this._element = undefined;
    this._createComponent.destroy(this._componentRef!!);
    this._componentRef = undefined;
  }
}
