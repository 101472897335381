<div
  [ngClass]="{
    'text-neutral-medium': isDarkTheme,
    'text-neutral-strong': !isDarkTheme
  }"
  class="p-2 round-top fs-5 pb-4 w-100 d-inline-flex align-items-center justify-content-between"
>
  <span class="fw-bold">Participação de {{ cdStock }} no Fundo (%PL)</span>
  <span>Dt. Ref.: {{ referenceDate }}</span>
</div>

<div
  #fundsChartContainer
  [id]="fundsChartRef + lineIndex"
  class="w-100 h-100 round-bottom"
  [style.max-height.px]="125"
></div>

<div class="funds-container">
  <ng-container *ngFor="let info of fundInfo">
    <div class="card p-1">
      <span
        [ngClass]="{
          'text-neutral-smoothest': isDarkTheme,
          'text-neutral-strongest': !isDarkTheme
        }"
        class="fs-6"
      >
        {{ info.label }}
      </span>
      <span
        [ngClass]="{
          'text-neutral-smooth': isDarkTheme,
          'text-neutral-strong': !isDarkTheme
        }"
        class="fs-6"
      >
        {{ info.value }}
      </span>
    </div>
  </ng-container>
</div>
