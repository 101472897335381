export const flagsMap: any = {
  CN: 'china',
  BR: 'brazil',
  DE: 'germany',
  US: 'united-states-of-america',
  JP: 'japan',
  CH: 'switzerland',
  CA: 'canada',
  IL: 'israel',
  TR: 'turkey',
  HK: 'hong-kong',
  LU: 'luxembourg',
  GB: 'united-kingdom',
  BM: 'bermuda',
  CO: 'colombia',
  IE: 'ireland',
  TW: 'taiwan',
  ID: 'indonesia',
  FR: 'france',
  MX: 'mexico',
  KY: 'cayman-islands',
  GR: 'greece',
  NL: 'netherlands',
  CY: 'cyprus',
  AU: 'australia',
  SG: 'singapore',
  SE: 'sweden',
  IT: 'italy',
  BE: 'belgium',
  AE: 'united-arab-emirates',
  IN: 'india',
  AR: 'argentina',
  ZA: 'south-africa',
  VG: 'british-virgin-islands',
  NO: 'norway',
  PE: 'peru',
  MU: 'mauritius',
  KR: 'south-korea',
  BS: 'bahamas',
  PR: 'puerto-rico',
  RU: 'russia',
  CL: 'chile',
  GI: 'gibraltar',
  MC: 'monaco',
  DK: 'denmark',
  AT: 'austria',
  TH: 'thailand',
  MT: 'malta',
  PA: 'panama',
};

export const flagsMapNews: any = {
  AFG: 'afghanistan',
  ALB: 'albania',
  DZA: 'algeria',
  BRA: 'brazil',
  ASM: 'american-samoa',
  DEU: 'germany',
  USA: 'united-states-of-america',
  ZEU: 'european-union',
  AND: 'andorra',
  AGO: 'angola',
  AIA: 'anguilla',
  ATA: 'antarctica',
  ATG: 'antigua-and-barbuda',
  ARG: 'argentina',
  ARM: 'armenia',
  ABW: 'aruba',
  AUS: 'australia',
  AUT: 'austria',
  AZE: 'azerbaijan',
  BHS: 'bahamas',
  BHR: 'bahrain',
  BGD: 'bangladesh',
  BRB: 'barbados',
  BLR: 'belarus',
  BEL: 'belgium',
  BLZ: 'belize',
  BEN: 'benin',
  BMU: 'bermuda',
  BTN: 'bhutan',
  BOL: 'bolivia',
  BIH: 'bosnia-and-herzegovina',
  BWA: 'botswana',
  BVT: 'bouvet-island',
  IOT: 'british-indian-ocean-territory',
  BRN: 'brunei-darussalam',
  BGR: 'bulgaria',
  BFA: 'burkina-faso',
  BDI: 'burundi',
  KHM: 'cambodia',
  CMR: 'cameroon',
  CAN: 'canada',
  CPV: 'cape-verde',
  CYM: 'cayman-islands',
  CAF: 'central-african-republic',
  TCD: 'chad',
  CHL: 'chile',
  CHN: 'china',
  CXR: 'christmas-island',
  CCK: 'cocos-(keeling)-islands',
  COL: 'colombia',
  COM: 'comoros',
  COG: 'congo',
  COD: 'congo,-the-democratic-republic-of-the',
  COK: 'cook-islands',
  CRI: 'costa-rica',
  CIV: 'cote-divoire',
  HRV: 'croatia',
  CUB: 'cuba',
  CYP: 'cyprus',
  CZE: 'czech-republic',
  DNK: 'denmark',
  DJI: 'djibouti',
  DMA: 'dominica',
  DOM: 'dominican-republic',
  ECU: 'ecuador',
  EGY: 'egypt',
  SLV: 'el-salvador',
  GNQ: 'equatorial-guinea',
  ERI: 'eritrea',
  EST: 'estonia',
  ETH: 'ethiopia',
  FLK: 'falkland-islands-(malvinas)',
  FRO: 'faroe-islands',
  FJI: 'fiji',
  FIN: 'finland',
  FRA: 'france',
  GUF: 'french-guiana',
  PYF: 'french-polynesia',
  ATF: 'french-southern-territories',
  GAB: 'gabon',
  GMB: 'gambia',
  GEO: 'georgia',
  GHA: 'ghana',
  GIB: 'gibraltar',
  GRC: 'greece',
  GRL: 'greenland',
  GRD: 'grenada',
  GLP: 'guadeloupe',
  GUM: 'guam',
  GTM: 'guatemala',
  GIN: 'guinea',
  GNB: 'guinea-bissau',
  GUY: 'guyana',
  HTI: 'haiti',
  HMD: 'heard-island-and-mcdonald-islands',
  VAT: 'holy-see-(vatican-city-state)',
  HND: 'honduras',
  HKG: 'hong-kong',
  HUN: 'hungary',
  ISL: 'iceland',
  IND: 'india',
  IDN: 'indonesia',
  IRN: 'iran',
  IRQ: 'iraq',
  IRL: 'ireland',
  ISR: 'israel',
  ITA: 'italy',
  JAM: 'jamaica',
  JPN: 'japan',
  JOR: 'jordan',
  KAZ: 'kazakhstan',
  KEN: 'kenya',
  KIR: 'kiribati',
  PRK: 'korea,-democratic-peoples-republic-of',
  KOR: 'korea,-republic-of',
  KWT: 'kuwait',
  KGZ: 'kyrgyzstan',
  LAO: 'lao-peoples-democratic-republic',
  LVA: 'latvia',
  LBN: 'lebanon',
  LSO: 'lesotho',
  LBR: 'liberia',
  LBY: 'libyan-arab-jamahiriya',
  LIE: 'liechtenstein',
  LTU: 'lithuania',
  LUX: 'luxembourg',
  MAC: 'macao',
  MKD: 'macedonia',
  MDG: 'madagascar',
  MWI: 'malawi',
  MYS: 'malaysia',
  MDV: 'maldives',
  MLI: 'mali',
  MLT: 'malta',
  MHL: 'marshall-islands',
  MTQ: 'martinique',
  MRT: 'mauritania',
  MUS: 'mauritius',
  MYT: 'mayotte',
  MEX: 'mexico',
  FSM: 'micronesia',
  MDA: 'moldova',
  MCO: 'monaco',
  MNG: 'mongolia',
  MSR: 'montserrat',
  MAR: 'morocco',
  MOZ: 'mozambique',
  MMR: 'myanmar',
  NAM: 'namibia',
  NRU: 'nauru',
  NPL: 'nepal',
  NLD: 'netherlands',
  ANT: 'netherlands-antilles',
  NCL: 'new-caledonia',
  NZL: 'new-zealand',
  NIC: 'nicaragua',
  NER: 'niger',
  NGA: 'nigeria',
  NIU: 'niue',
  NFK: 'norfolk-island',
  MNP: 'northern-mariana-islands',
  NOR: 'norway',
  OMN: 'oman',
  PAK: 'pakistan',
  PLW: 'palau',
  PSE: 'palestinian-territory',
  PAN: 'panama',
  PNG: 'papua-new-guinea',
  PRY: 'paraguay',
  PER: 'peru',
  PHL: 'philippines',
  PCN: 'pitcairn',
  POL: 'poland',
  PRT: 'portugal',
  PRI: 'puerto-rico',
  QAT: 'qatar',
  REU: 'reunion',
  ROM: 'romania',
  RUS: 'russian-federation',
  RWA: 'rwanda',
  SHN: 'saint-helena',
  KNA: 'saint-kitts-and-nevis',
  LCA: 'saint-lucia',
  SPM: 'saint-pierre-and-miquelon',
  VCT: 'saint-vincent-and-the-grenadines',
  WSM: 'samoa',
  SMR: 'san-marino',
  STP: 'sao-tome-and-principe',
  SAU: 'saudi-arabia',
  SEN: 'senegal',
  SRB: 'serbia-and-montenegro',
  SYC: 'seychelles',
  SLE: 'sierra-leone',
  SGP: 'singapore',
  SVK: 'slovakia',
  SVN: 'slovenia',
  SLB: 'solomon-islands',
  SOM: 'somalia',
  ZAF: 'south-africa',
  SGS: 'south-georgia-and-the-south-sandwich-islands',
  ESP: 'spain',
  LKA: 'sri-lanka',
  SDN: 'sudan',
  SUR: 'suriname',
  SJM: 'svalbard-and-jan-mayen',
  SWZ: 'eswatini',
  SWE: 'sweden',
  CHE: 'switzerland',
  SYR: 'syrian-arab-republic',
  TWN: 'taiwan',
  TJK: 'tajikistan',
  TZA: 'tanzania',
  THA: 'thailand',
  TLS: 'timor-leste',
  TGO: 'togo',
  TKL: 'tokelau',
  TON: 'tonga',
  TTO: 'trinidad-and-tobago',
  TUN: 'tunisia',
  TUR: 'turkey',
  TKM: 'turkmenistan',
  TCA: 'turks-and-caicos-islands',
  TUV: 'tuvalu',
  UGA: 'uganda',
  UKR: 'ukraine',
  ARE: 'united-arab-emirates',
  GBR: 'united-kingdom',
  UMI: 'united-states-minor-outlying-islands',
  URY: 'uruguay',
  UZB: 'uzbekistan',
  VUT: 'vanuatu',
  VEN: 'venezuela',
  VNM: 'viet-nam',
  VGB: 'virgin-islands,-british',
  VIR: 'virgin-islands,-u.s.',
  WLF: 'wallis-and-futuna',
  ESH: 'western-sahara',
  YEM: 'yemen',
  ZMB: 'zambia',
  ZWE: 'zimbabwe',
};
