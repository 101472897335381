import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OriginAnalysisOrderService {
  private originOrder = '';

  public setOriginOrder(origin: string): void {
    this.originOrder = origin;
  }

  public getOriginOrder(): string {
    return this.originOrder;
  }
}
