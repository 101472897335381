<div class="suffix">
  <ng-content select="fla-button"></ng-content>
  <ng-container *ngTemplateOutlet="split"></ng-container>
  <span *ngIf="text">{{ text }}</span>
  <ng-container *ngTemplateOutlet="split"></ng-container>
  <ng-content select="fla-icon"></ng-content>
</div>

<ng-template #split>
  <ng-content select="fla-input-split"></ng-content>
</ng-template>
