<rocket-modal>
  <rocket-modal-header>
    <div class="d-flex flex-column fw-bolder position-relative w-100">
      <fla-tab>
        <fla-tab-header
          *ngFor="let tab of tabViews"
          [ref]="tab.key"
          [title]="tab.label"
          [active]="tab.key === data.type"
          (flaChange)="getList(tab.key, tab.periods[0].key, true)"
        ></fla-tab-header>
        <fla-tab-body
          *ngFor="let tab of tabViews"
          [ref]="tab.key"
          [active]="tab.key === data.type"
        >
          <fla-tab>
            <ng-container *ngIf="tab.key !== 'news'">
              <fla-tab-header
                *ngFor="let period of tab.periods; let i = index"
                [ref]="period.key"
                [title]="period.label"
                [active]="period.key === periodSelected.key"
                (flaChange)="getList(tab.key, period.key)"
              ></fla-tab-header>
            </ng-container>
          </fla-tab>
        </fla-tab-body>
      </fla-tab>
      <div
        *ngIf="typeSelected === 'news' && newsGroups"
        class="d-flex align-items-center fs-base text-neutral-medium fw-bold mt-2"
        [style.bottom.px]="10"
      >
        <ng-container *ngFor="let tag of newsGroups[0].itens; let i = index">
          <span
            class="mr-2 cursor-pointer"
            [ngClass]="{
              'text-brand-primary': newsConfiguration.tag === tag.tag_agg
            }"
            (click)="getList('news', tag.tag_agg)"
          >
            {{ tag.nm_view_news }}
          </span>
        </ng-container>
      </div>
      <div
        *ngIf="typeSelected === 'proceeds'"
        class="d-flex align-items-center fs-6 position-absolute right-0"
        [style.bottom.px]="10"
      >
        <span
          class="mr-1 cursor-pointer"
          [ngClass]="{ 'text-brand-primary': dtComSelected }"
          (click)="changeDtComSelected(true)"
        >
          Dt. Com
        </span>
        <span
          class="cursor-pointer"
          [ngClass]="{ 'text-brand-primary': !dtComSelected }"
          (click)="changeDtComSelected(false)"
        >
          Dt. Pagto
        </span>
      </div>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div
      #bodyWrapper
      class="w-100 mb-6 body-modal overflow-y-auto d-flex flex-column"
      (scroll)="onListScroll($event)"
    >
      <ng-container *ngIf="typeSelected !== 'calendar'">
        <ng-container *ngIf="loading; else content">
          <app-loading></app-loading>
        </ng-container>

        <ng-template #content>
          <ng-container *ngIf="list.length; else withoutContent">
            <fla-card class="pr-2 w-100" *ngFor="let _list of list">
              <fla-card-custom
                #card
                [css]="
                  'p-1 bg-neutral-strong mt-2' +
                  (hasEvents ? ' cursor-pointer' : '')
                "
              >
                <div
                  class="card-body justify-content-center align-items-center"
                  (click)="documentOpen(_list)"
                >
                  <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center w-75">
                      <div
                        class="p-1 dateEventContainer bg-dark mr-2 round d-flex flex-column justify-content-center align-items-center"
                        [style.min-width.px]="48"
                      >
                        <span class="fs-7">{{ _list.dateString }}</span>
                        <span class="fs-7 text-neutral-medium">{{
                          _list.yearDoc
                        }}</span>
                      </div>
                      <div class="d-flex w-100">
                        <p
                          class="card-title fs-6 d-inline-block text-truncate"
                          [innerHTML]="_list.body"
                        ></p>
                      </div>
                    </div>
                    <div
                      *ngIf="hasEvents"
                      class="d-flex align-items-center justify-content-end"
                    >
                      <fla-icon
                        name="chevron_right"
                        css="cursor-pointer text-brand-primary"
                        size="icon-size-sm"
                        [isVmIcon]="false"
                      >
                      </fla-icon>
                    </div>
                  </div>
                </div>
              </fla-card-custom>
            </fla-card>
          </ng-container>
          <ng-template #withoutContent>
            <div>Sem item para mostrar!</div>
          </ng-template>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="typeSelected === 'calendar'">
        <ng-container *ngIf="loading; else calendarContent">
          <app-loading></app-loading>
        </ng-container>

        <ng-template #calendarContent>
          <ng-container *ngIf="list.length; else withoutCalendarContent">
            <fla-card class="d-flex card-border" *ngFor="let _list of list">
              <fla-card-custom #card css="w-100 m-2 p-2 py-0">
                <div class="card-body">
                  <div class="d-flex" [style.min-height.px]="46">
                    <div class="mr-2">
                      <img class="image-country" [src]="_list.image_url" />
                    </div>
                    <div>
                      <p class="fs-5 fw-bold mb-1 w-100">
                        {{ _list.headline }}
                      </p>
                      <p class="fw-bold fs-5 mb-2 limit-text fs-6">
                        <ng-container *ngIf="_list.previusLabel">
                          {{ _list.previusLabel }}
                        </ng-container>
                        <ng-container *ngIf="_list?.currentLabelCompleted">
                          {{ _list.currentLabelCompleted }}
                        </ng-container>
                        <ng-container *ngIf="_list?.period">
                          ({{ _list.period }})
                        </ng-container>
                      </p>
                    </div>
                  </div>
                  <div class="fs-6">
                    {{ _list?.date }} |
                    {{ _list?.time | date : 'HH:mm' }}
                    <span *ngIf="_list.institution"
                      >| {{ _list.institution }}</span
                    >
                  </div>
                </div>
              </fla-card-custom>
            </fla-card>
          </ng-container>

          <ng-template #withoutCalendarContent>
            <div *ngIf="!list.length">
              Não há evento cadastrado para
              <strong>{{ periodSelected.label }}</strong>
            </div>
          </ng-template>
        </ng-template>
      </ng-container>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
