import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { NumberRange } from 'scichart/Core/NumberRange';
import {
  CustomAnnotation,
  ICustomAnnotationOptions,
} from 'scichart/Charting/Visuals/Annotations/CustomAnnotation';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import {
  DrawToolConfiguration,
  TIGER_CHART_TOOL,
  TIGER_CHART_TOOL_CHILD,
} from '../tiger-chart-tools/tiger-chart-tools.interface';
import { TMouseEventData } from '../types/tiger-chart.types';
import {
  Axis,
  DrawTools,
  DrawToolsReposition,
  EventSubject,
  LineToolsOptions,
  StyleTool,
} from './draw-tools.interface';
import {
  TOOLS_ENUM,
  TOOLS_METHOD,
} from '../tiger-chart-tools/tiger-chart-tools.enum';
import { CoordinateCalculatorBase } from 'scichart/Charting/Numerics/CoordinateCalculators/CoordinateCalculatorBase';
import { Point } from 'scichart/Core/Point';
import { TYPE_RESIZE } from './draw-tools.enum';
import { Subject, Subscription, auditTime, debounceTime } from 'rxjs';

export class IconTools extends CustomAnnotation implements DrawTools {
  tool!: TIGER_CHART_TOOL;
  children = [];
  isDraw = true;
  startEdit = false;
  baseChart!: TWebAssemblyChart;
  isChildren = false;
  lastPointValues!: Point;
  lastMouseValues!: Point;
  lastXDiff: number = 0;
  isDrawing!: boolean;
  style: StyleTool = {};
  dictIdChildren!: string;
  configuration!: DrawToolConfiguration | undefined;
  hasElements: boolean = false;
  updateChart: boolean = false;
  width: number = 0;
  height: number = 0;
  previousVisibleRange!: NumberRange;
  isAux: boolean = false;
  endDraw: boolean = false;
  lastEventData!: TMouseEventData;
  repositionX: number = 0;
  axisSub!: Subscription;
  isWithinDataBounds: boolean | undefined = true;
  private updateDrawTool$!: Subscription;
  private _updateDrawTool$ = new Subject<EventSubject>();
  private resizeSVG$!: Subscription;
  private _resizeSVG$ = new Subject<EventSubject>();
  private repositionBoxNote$!: Subscription;
  private _repositionBoxNote$ = new Subject<EventSubject>();
  private get isIconsTool(): boolean {
    return this.tool.codTool === `${TOOLS_ENUM.ICONS}_ICONS`;
  }
  private get isNoteTool(): boolean {
    return this.tool.codTool === `${TOOLS_ENUM.ANNOTATION_TOOLS}_NOTE`;
  }

  constructor(
    options: ICustomAnnotationOptions,
    private drawToolsService: DrawToolsService,
    tool: LineToolsOptions
  ) {
    super(options);
    this.initSubscriptions();
    this.isChildren = tool.isChildren;
    this.baseChart = tool.baseChart;
    this.tool = tool.tool;
    this.dictIdChildren = tool.dictIdChildren;
    this.configuration = tool.configuration;
    this.isAux = tool.isAux;
    this.lastEventData = tool.eventData;
    this.isWithinDataBounds = tool.eventData.isWithinDataBounds;
    this.repositionBoxNote();
  }

  protected override setAnnotationBorders(
    x1: number,
    x2: number,
    y1: number,
    y2: number
  ): void {
    super.setAnnotationBorders(x1, x2, y1, y2);
    this.setStyleConfiguration();
  }

  override update(
    xCalc: CoordinateCalculatorBase,
    yCalc: CoordinateCalculatorBase,
    xCoordSvgTrans: number,
    yCoordSvgTrans: number
  ): void {
    super.update(xCalc, yCalc, xCoordSvgTrans, yCoordSvgTrans);
    if (this.isDrawing) {
      return;
    }
    if (this.style) {
      this.updateStyleTool(this.style);
    }
    //this.updateAux();
  }

  unsubscriber(): void {
    this.axisSub.unsubscribe();
  }

  initSubscriptions() {
    this.updateDrawTool$ = this._updateDrawTool$
      .pipe(debounceTime(100))
      .subscribe((data) => {
        data.function();
      });
    this.resizeSVG$ = this._resizeSVG$
      .pipe(debounceTime(100))
      .subscribe((data) => {
        data.function();
      });
    this.repositionBoxNote$ = this._repositionBoxNote$
      .pipe(auditTime(100))
      .subscribe((data) => {
        data.function();
      });
    this.axisSub = this.drawToolsService.axisChanged$.subscribe(() => {
      this._repositionBoxNote$.next({
        function: () => {
          //this.repositionBoxNote();
        },
      });
    });
  }

  private destroySubscriptions() {
    this.updateDrawTool$ && this.updateDrawTool$.unsubscribe();
    this.resizeSVG$ && this.resizeSVG$.unsubscribe();
    this.repositionBoxNote$ && this.repositionBoxNote$.unsubscribe();
  }

  override onDetach(): void {
    super.onDetach();
    this.destroySubscriptions();
  }

  override delete(): void {
    super.delete();
    this.destroySubscriptions();
  }

  getName() {
    return this.constructor.name;
  }

  updateDrawTool(
    eventData: TMouseEventData,
    // eslint-disable-next-line
    countStepClickTool: number,
    // eslint-disable-next-line
    reposition?: DrawToolsReposition
  ) {
    this.lastEventData = eventData;
    this.lastPointValues = eventData.pointValues!!;
    this._updateDrawTool$.next({
      function: () => {
        this.updateAux();
        this.updateNote(0);
      },
    });
  }

  private repositionBoxNote() {
    if (this.isNoteTool) {
      this.tool.children?.forEach((child) => {
        const draw = this.baseChart.sciChartSurface.annotations.getById(
          child.dictIdChildren!!
        ) as DrawTools;
        if (!draw) {
          return;
        }
        if (child.method === TOOLS_METHOD.BOX_ANNOTATION) {
          return;
        }
        const svg = document
          .getElementById(`SVG_${this.tool.dictId}`)
          ?.getBoundingClientRect();
        const { yAxis } = this.getAxis();
        if (!yAxis || !svg) {
          return;
        }
        const borders = this.getAnnotationBorders();
        const y1 = yAxis
          .getCurrentCoordinateCalculator()
          .getDataValue(borders.y1 - (svg.height!! + 4) * 2);
        draw.isDrawing = true;
        const dataValues = this.getDataValues();
        if (!dataValues) {
          return;
        }
        draw.x1 = dataValues.x1;
        draw.y1 = y1;
      });
    }
  }

  updateBoxNoteWidth() {
    this._updateDrawTool$.next({
      function: () => {
        this.updateNote(0);
      },
    });
    this._repositionBoxNote$.next({
      function: () => {
        this.repositionBoxNote();
      },
    });
  }

  private updateNote(newWidth: number) {
    if (this.isNoteTool) {
      const svg = document
        .getElementById(`SVG_${this.tool.dictId}`)
        ?.getBoundingClientRect();
      const { yAxis } = this.getAxis();
      if (!yAxis || !svg) {
        return;
      }
      const borders = this.getAnnotationBorders();
      const y1 = yAxis
        .getCurrentCoordinateCalculator()
        .getDataValue(borders.y1 - (svg.height!! + 4) * 2);
      this.tool.children?.forEach((child) => {
        if (child.method === TOOLS_METHOD.CUSTOM_BOX_ANNOTATION) {
          const dataValues = this.getTextDataValues();
          if (!dataValues) {
            return;
          }
          const draw = this.baseChart.sciChartSurface.annotations.getById(
            child.dictIdChildren!!
          ) as DrawTools;
          draw.isDrawing = true;
          draw.x1 = this.x1;
          draw.x2 = dataValues.x2;
          draw.y1 = y1;
          draw.y2 = dataValues.y2;
          const pathElement = document.getElementById(
            `PATH_${child.dictIdChildren!!}`
          );
          if (pathElement) {
            const borders = this.getAnnotationBorders();
            const width =
              newWidth === 0 || newWidth < borders.x2 - borders.x1 + 20
                ? borders.x2 - borders.x1 + 20
                : newWidth + 20;
            const arrowPosition = width / 4;
            pathElement.setAttribute(
              'd',
              `M -2 -1 h ${width} v ${
                borders.y2 - borders.y1 - 6
              } h -${arrowPosition} l -10 10 l -10 -10 H -2 z`
            );
          }
        }
        if (child.method === TOOLS_METHOD.TEXT_ANNOTATION) {
          const draw = this.baseChart.sciChartSurface.annotations.getById(
            child.dictIdChildren!!
          ) as DrawTools;
          draw.y1 = y1;
          draw.x1 = this.x1;
        }
        if (child.method === TOOLS_METHOD.BOX_ANNOTATION) {
          const draw = this.baseChart.sciChartSurface.annotations.getById(
            child.dictIdChildren!!
          ) as DrawTools;
          draw.isDrawing = true;
          const dataValues = this.getDataValues();
          if (!dataValues) {
            return;
          }
          const yDiff = dataValues.y1 - dataValues.y2;
          const xMedian = (dataValues.x1 + dataValues.x2) / 2;
          const xDiff = dataValues.x2 - xMedian;
          draw.x1 = this.x1 - xDiff;
          draw.x2 = dataValues.x2 - xDiff;
          draw.y2 = this.y1;
          draw.y1 = this.y1 + yDiff;
        }
      });
    }
  }

  private getTextDataValues() {
    if (!this.tool.children) return;
    const child = this.tool.children.find(
      (c) => c.method === TOOLS_METHOD.TEXT_ANNOTATION
    );
    if (!child) return;
    const draw = this.baseChart.sciChartSurface.annotations.getById(
      child.dictIdChildren!!
    ) as DrawTools;
    const { yAxis, xAxis } = this.getAxis();
    if (!yAxis || !xAxis) {
      return;
    }
    const borders = draw.getAnnotationBorders();
    const y1 = yAxis.getCurrentCoordinateCalculator().getDataValue(borders.y1),
      y2 = yAxis.getCurrentCoordinateCalculator().getDataValue(borders.y2);
    const x1 = xAxis.getCurrentCoordinateCalculator().getDataValue(borders.x1),
      x2 = xAxis.getCurrentCoordinateCalculator().getDataValue(borders.x2);
    return { x1, x2, y1, y2 };
  }

  private getDataValues() {
    const { yAxis, xAxis } = this.getAxis();
    if (!yAxis || !xAxis) {
      return;
    }
    const borders = this.getAnnotationBorders();
    const y1 = yAxis.getCurrentCoordinateCalculator().getDataValue(borders.y1),
      y2 = yAxis.getCurrentCoordinateCalculator().getDataValue(borders.y2);
    const x1 = xAxis.getCurrentCoordinateCalculator().getDataValue(borders.x1),
      x2 = xAxis.getCurrentCoordinateCalculator().getDataValue(borders.x2);
    return { x1, x2, y1, y2 };
  }

  private updateAux() {
    if (this.tool.hasBoxAux && this.tool.children && this.isIconsTool) {
      const newsValues = this.getDataValues();
      const auxs = this.tool.children.filter((child) => child.isAux);
      const box = auxs.find(
        (child) => child.method === TOOLS_METHOD.BOX_ANNOTATION
      );
      if (!newsValues || !box) {
        return;
      }
      const drawBox = this.baseChart.sciChartSurface.annotations.getById(
        box.dictIdChildren!!
      ) as DrawTools;
      const diffY = (newsValues.y1 - newsValues.y2) / 2;
      this.y1 = this.y1 + diffY;
      const diffX = (newsValues.x1 - newsValues.x2) / 2;
      this.x1 = this.x1 + diffX;
      this.x2 = newsValues.x2 + diffX;
      this.y2 = newsValues.y2 + diffY;
      drawBox.x1 = this.x1;
      drawBox.x2 = this.x2;
      drawBox.y1 = this.y1;
      drawBox.y2 = this.y2;
    }
  }

  customClick = () => {
    //do nothing.
  };

  repositionZoomDraw(): void {
    //do nothing.
  }

  customHover = () => {
    //do nothing.
  };

  updateStyleTool(style: StyleTool) {
    let childrenDraw!: TIGER_CHART_TOOL_CHILD;
    if (this.isChildren && this.tool.children) {
      childrenDraw = this.tool.children.find(
        (child) => child.dictIdChildren === this.dictIdChildren
      )!!;
    }
    if (this.isNoteTool) {
      const pinElement = document.getElementById(
        `PATH_PIN_${this.tool.dictId!!}`
      );
      if (pinElement) {
        if (style.color) {
          pinElement.setAttribute('fill', style.color.rgbaHexColor);
          this.style.color = style.color;
          if (childrenDraw) {
            childrenDraw.color = style.color.rgbaHexColor;
            return;
          }
          this.tool.color = style.color.rgbaHexColor;
        }
      }
    }
  }

  updateTool(tool: TIGER_CHART_TOOL) {
    this.tool = tool;
  }

  private setStyleConfiguration() {
    if (!this.configuration || this.hasElements) {
      return;
    }
    if (this.isNoteTool) {
      const pinElement = document.getElementById(
        `PATH_PIN_${this.tool.dictId!!}`
      );
      if (!pinElement) {
        return;
      } else {
        this.hasElements = true;
      }
      if (pinElement) {
        if (this.configuration.color) {
          this.style.color = {
            hexColor: this.configuration.color,
            rgbaHexColor: this.configuration.color,
            rgbaColor: this.configuration.color,
            rgbColor: this.configuration.color,
            selected: true,
          };
          pinElement.setAttribute('fill', this.configuration.color);
        }
      }
    }
  }

  resizeSVG(
    typeResize: TYPE_RESIZE,
    childId: string,
    svgPosition?: { x1: number; x2: number; y1: number; y2: number },
    position?: { x1: number; x2: number; y1: number; y2: number }
  ) {
    if (position) {
      const child = this.tool.children?.find(
        (c) => childId === c.dictIdChildren
      );
      const children = this.tool.children?.filter(
        (c) => childId != c.dictIdChildren
      );
      if (this.isIconsTool) {
        if (child) {
          if (child.method === TOOLS_METHOD.BOX_ANNOTATION) {
            this.x1 = position.x1;
            this.x2 = position.x2;
            this.y1 = position.y1;
            this.y2 = position.y2;
            const datavalues = this.getDataValues();
            if (!datavalues) {
              return;
            }
            const centerEmoji = this.y1 - datavalues.y2;
            const draw = this.baseChart.sciChartSurface.annotations.getById(
              childId
            ) as DrawTools;
            draw.isDrawing = true;
            const image = document.getElementById(
              `IMAGE_${this.tool.dictId!!}`
            );
            if (typeResize != TYPE_RESIZE.DRAG) {
              draw.y1 = this.y1;
              draw.y2 = position.y1 - centerEmoji;
              draw.x2 = datavalues.x2;
            } else {
              const absValue = Math.abs(svgPosition!!.x2);
              image!!.setAttribute('width', `${absValue}`);
              image!!.setAttribute('height', `${absValue}`);
              draw.y2 = datavalues.y2;
            }
            draw.isDrawing = false;
          }
          if (child.method === TOOLS_METHOD.DRAW_LINE) {
            this._resizeSVG$.next({
              function: () => {
                const datavalues = this.getDataValues();
                if (!datavalues) {
                  return;
                }
                const centerEmoji = (this.y1 - datavalues.y2) / 2;
                this.y1 = centerEmoji + position.y1;
                const newValues = this.getDataValues();
                if (!newValues) {
                  return;
                }
                const draw = this.baseChart.sciChartSurface.annotations.getById(
                  child.dictIdChildren!!
                ) as DrawTools;
                draw.isDrawing = true;
                if (typeResize != TYPE_RESIZE.DRAG) {
                  draw.x2 = newValues.x2;
                }
                draw.isDrawing = false;
                children?.forEach((c) => {
                  const childDraw =
                    this.baseChart.sciChartSurface.annotations.getById(
                      c.dictIdChildren!!
                    ) as DrawTools;
                  childDraw.y1 = this.y1;
                  childDraw.y2 = position.y1 - centerEmoji;
                  childDraw.x2 = position.x2;
                });
                const image = document.getElementById(
                  `IMAGE_${this.tool.dictId!!}`
                );
                if (typeResize === TYPE_RESIZE.DRAG) {
                  const absValue = Math.abs(svgPosition!!.x2);
                  image!!.setAttribute('width', `${absValue}`);
                  image!!.setAttribute('height', `${absValue}`);
                }
              },
            });
          }
        }
      }
    }
    /*const svgElement = document.getElementById(`SVG_${this.tool.dictId!!}`);
    this.svgString = svgElement!!.outerHTML!!;
    this.notifyPropertyChanged('svgString');*/
  }

  private getAxis(): Axis {
    const yAxis = this.baseChart.sciChartSurface.yAxes.getById(this.yAxisId);
    const xAxis = this.baseChart.sciChartSurface.xAxes.getById(this.xAxisId);
    if (!yAxis || !xAxis) {
      return { xAxis: undefined, yAxis: undefined };
    }
    try {
      yAxis.getCurrentCoordinateCalculator();
      xAxis.getCurrentCoordinateCalculator();
    } catch (e: any) {
      return { xAxis: undefined, yAxis: undefined };
    }
    return { yAxis, xAxis };
  }
}
