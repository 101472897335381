import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import {
  auditTime,
  debounceTime,
  delay,
  filter,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { RocketModalService } from '@shared/rocket-components/components/index';
import { NewStockModalComponent } from '../modals/new-stock-modal/new-stock-modal.component';
import { GridISelectRow, RocketGridService } from '@shared/rocket-grid';
import { NewListStockModalComponent } from '../modals/new-list-stock-modal/new-list-stock-modal.component';
import { DuplicateListModalComponent } from '../modals/duplicate-list-modal/duplicate-list-modal.component';
import { ManageListModalComponent } from '../modals/manage-list-modal/manage-list-modal.component';
import {
  STOCK_TABLE_ELEMENT_IDS,
  isAuctionList,
  isMoversList,
  isPresetListID,
} from '../constants/stock-table.constants';
import {
  IAllStockListSimple,
  IListStockDB,
  IStockIndexResponse,
  IWorkSpaceComponet,
} from 'src/app/core/interface';
import { GlobalStockListSubscription } from '@shared/services/core/subscription/global-stock-list.subscription';
import { deepClone } from '@shared/rocket-components/utils';
import { ListStocksService } from '@shared/services/core/list-stocks/list-stocks.service';
import {
  EVENTS,
  TRIGGERED_THE_EVENT,
} from '@shared/services/core/list-stocks/list-stocks.const';
import {
  IStockTableView,
  STOCK_TABLE_INDEXES,
  STOCK_TABLE_VIEW,
  STOCK_TABLE_VIEWS,
} from '@shared/components/stock-table-views';
import {
  IDynamicList,
  IGlobalStock,
} from '../interfaces/stock-table.interfaces';
import { MOVERS_PERIODS } from '../constants/periods.contants';
import { IStockTableIndex } from '../interfaces/movers-index.interface';
import {
  AUCTION_INDEXES,
  MOVERS_LISTS,
  STOCKS_LISTS,
  STOCK_TABLE_ACTIONS,
  STOCK_TABLE_TYPES,
} from './constants/stock-table-header.constant';
import { StockService } from '@shared/services/api/trademap/v1/stock.service';

@Component({
  selector: 'app-stock-table-header',
  templateUrl: './stock-table-header.component.html',
  styleUrls: ['./stock-table-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockTableHeaderComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild('stockTableHeader') private _stockTableHeader!: ElementRef;
  @ViewChild('stockListSelected') private _stockListSelected!: ElementRef;

  @Input() refComponent!: string;
  @Input() componentId!: string;
  @Input() set presetListName(name: string) {
    this.presetListSelected = name;
  }
  @Input() idList!: number;
  @Input() isNotListPersonal: boolean = false;
  @Input() isOpenListPersonal: boolean = false;
  @Input() linked: boolean = false;
  @Input() toggleDPVisibility: boolean = false;
  @Input() isChangeViewStep: boolean = false;
  @Input() openCreateList: boolean = false;
  @Input() isPresetList: boolean = false;
  @Input() isIndex: boolean = false;
  @Input() listStocks: Array<IAllStockListSimple> = [];
  @Input() listSelected!: IAllStockListSimple | undefined;
  @Input() listStockInfo!: IListStockDB;
  @Input() list: IListStockDB[] = [];
  @Input() initialStockTableType!: STOCK_TABLE_VIEW;
  @Input() dynamicInitialList!: IDynamicList | undefined;
  @Input() globalStockInfos!: IGlobalStock;
  @Input() currentMoversIndex = STOCK_TABLE_INDEXES[1];
  @Input() currentAuctionIndex = STOCK_TABLE_INDEXES[1];
  @Input() currentMoversPeriod = MOVERS_PERIODS[0];
  @Input() component!: IWorkSpaceComponet;

  @Output() changeName = new EventEmitter<string>();
  @Output() changeStockTableView = new EventEmitter<STOCK_TABLE_VIEW>();
  @Output() changeListSelected = new EventEmitter<IListStockDB>();
  @Output() changeNotListPersonal = new EventEmitter<any>();
  @Output() changeOpenListPersonal = new EventEmitter<boolean>();
  @Output() changePresetList = new EventEmitter<string>();
  @Output() getAllLists = new EventEmitter<{
    isNewList: boolean;
    idStockList: number;
  }>();
  @Output() updateAuctionIndex = new EventEmitter<IStockTableIndex>();
  @Output() displayIndexStocks = new EventEmitter<boolean>();

  expandIcon: 'expand_more' | 'expand_less' = 'expand_more';
  dropnAutoClose = false;
  forceCloseDP = false;
  focusInDP = false;
  onlyStockTableIcon: boolean = false;
  smallListName: boolean = false;
  hideListNameTooltip: boolean = false;
  moversHeaderAtMinWidth: boolean = false;
  displayIndexAndPeriodFilters: boolean = false;
  isStockIndexList: boolean = false;
  displayToggleToIndexList: boolean = false;
  moversHeaderUsed: number = 175;
  indexValue: IStockTableIndex = { value: 'IBOV' };
  indexList: IStockTableIndex[] = [];
  presetListSelected!: string;
  indexTooltip: string = 'Exibir ativos do índice selecionado';
  auctionIndexs = AUCTION_INDEXES;
  moversList = MOVERS_LISTS;
  actions = STOCK_TABLE_ACTIONS;
  stocksList = STOCKS_LISTS;
  currentListType!: STOCK_TABLE_TYPES;
  readonly LIST_TYPES = STOCK_TABLE_TYPES;
  readonly ELEMENT_IDS = STOCK_TABLE_ELEMENT_IDS;

  private _previusListName!: string;
  private channels!: any;
  private _previusIdListEmitted!: number;
  private _withoutPersonList: boolean = false;
  private _subscriptions = new Subscription();
  private _headerLayoutSubject = new Subject<number>();
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private _updateInitialList = new Subject<IDynamicList>();
  private _updateComponentWidthSubject = new Subject<void>();
  private _closeDropdown$ = new Subject<{ refComponent: string }>();
  private _currentViewInfos: IStockTableView = STOCK_TABLE_VIEWS[0];
  private _resize$!: ResizeObserver;
  private configModals = {
    centered: true,
    keyboard: false,
    scrollable: false,
  };
  private openModalCreateStockByModalManageList$ = new Subject<void>();

  constructor(
    private rocketGridService: RocketGridService,
    private _rocketModalService: RocketModalService,
    private _globalStockList: GlobalStockListSubscription,
    private listStocksService: ListStocksService,
    private _stockService: StockService,
    private cdr: ChangeDetectorRef
  ) {
    this.channels = EVENTS;
    this._subscriptionsHandler();
  }

  ngOnInit(): void {
    this.triggeredEvent();
    this.getStockIndexList();
  }

  ngAfterViewInit(): void {
    this._resize$ = new ResizeObserver((entries) => {
      this._headerLayoutSubject.next(entries[0].contentRect.width);
    });
    this._resize$.observe(this._stockTableHeader.nativeElement);
    this.openModalCreateStockByModalManageList$
      .pipe(takeUntil(this.destroy$), auditTime(200))
      .subscribe(() => this.openCreateStockList());
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {
      dynamicInitialList,
      openCreateList,
      listStocks,
      idList,
      currentAuctionIndex,
      currentMoversIndex,
      isIndex,
      globalStockInfos,
    } = changes;
    if (idList?.currentValue) {
      this._updateIndex(idList.currentValue);
      this._updateListType(idList.currentValue);
    }
    if (dynamicInitialList?.currentValue)
      this._updateInitialList.next(dynamicInitialList.currentValue);
    if (openCreateList?.currentValue) this.openCreateStockList();
    if (listStocks?.currentValue) {
      if (!listStocks.currentValue.length) {
        this._setWithoutLists();
        return;
      }
      this._withoutPersonList = false;
    }
    if (currentAuctionIndex?.currentValue || currentMoversIndex?.currentValue)
      this._updateIndex(this.idList);
    if (isIndex?.currentValue || globalStockInfos?.currentValue) {
      this._upateIndexListInfos(globalStockInfos?.currentValue);
    }
  }

  ngOnDestroy(): void {
    this._resize$.unobserve(this._stockTableHeader.nativeElement);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this._subscriptions.unsubscribe();
    this._updateInitialList.unsubscribe();
    this._headerLayoutSubject.unsubscribe();
  }

  private _updateListType(idList: number): void {
    this.currentListType = STOCK_TABLE_TYPES.PERSONAL;
    if (isMoversList(idList)) this.currentListType = STOCK_TABLE_TYPES.MOVERS;
    if (isAuctionList(idList)) this.currentListType = STOCK_TABLE_TYPES.AUCTION;
    if (isPresetListID(idList)) this.currentListType = STOCK_TABLE_TYPES.PRESET;
    this._upateIndexListInfos(this.globalStockInfos);
  }

  private _updateIndex(idList: number): void {
    const isMovers = isMoversList(idList);
    const isAuction = isAuctionList(idList);
    if (isMovers) this.indexValue = this.currentMoversIndex;
    if (isAuction) this.indexValue = this.currentAuctionIndex;
    this.displayIndexAndPeriodFilters = isMovers || isAuction;
    this.cdr.detectChanges();
  }

  private _subscriptionsHandler = () => {
    const dropDownSub = this._closeDropdown$
      .pipe(
        filter((data) => data.refComponent === this.refComponent),
        tap(() => {
          this.dropnAutoClose = true;
          this.forceCloseDP = true;
          this.cdr.detectChanges();
        }),
        delay(100)
      )
      .subscribe(() => {
        this.dropnAutoClose = false;
        this.forceCloseDP = false;
        this.cdr.detectChanges();
      });
    this._subscriptions.add(dropDownSub);

    const newStockSub = this.rocketGridService.openAddRow
      .pipe(filter((data) => data.refComponent === this.refComponent))
      .subscribe(
        (data: {
          refComponent: string;
          row: GridISelectRow | undefined | null;
        }) => this.searchStock(data.row!)
      );
    this._subscriptions.add(newStockSub);

    const stockListSub = this.listStocksService.stockListEvents.subscribe(
      this.processEvents
    );
    this._subscriptions.add(stockListSub);

    const globalStockSub = this._globalStockList
      .onGlobalStockListChange()
      .pipe(
        filter((data) => {
          const { changedInHeatmap, list } = data;
          return !(
            !changedInHeatmap ||
            !this.linked ||
            (!!this.listSelected &&
              list.id_stock_list == this.listSelected.id_stock_list)
          );
        })
      )
      .subscribe(({ list }) => this._onStockGlobalChange(list));
    this._subscriptions.add(globalStockSub);

    const updateListSub = this._updateInitialList
      .pipe(debounceTime(100))
      .subscribe((list) => this.selectListNotPersonal(list, true));
    this._subscriptions.add(updateListSub);

    const headerSub = this._headerLayoutSubject
      .pipe(debounceTime(100))
      .subscribe((width) => this._updateHeaderLayout(width));
    this._subscriptions.add(headerSub);

    const componentWidth = this._updateComponentWidthSubject
      .pipe(debounceTime(100))
      .subscribe(() => this._updateComponentWidth());
    this._subscriptions.add(componentWidth);
  };

  searchStock = (row: GridISelectRow | undefined = undefined) => {
    this.triggeredEvent();
    this._rocketModalService.open(NewStockModalComponent, {
      size: 'lg',
      centered: true,
      keyboard: true,
      backdrop: true,
      data: {
        listSelected: deepClone(this.listSelected),
        listStockInfo: deepClone(this.listStockInfo),
        refComponent: this.refComponent,
        row,
      },
    });
  };

  selectList(item: IAllStockListSimple): void {
    this.closeDropdown();
    if (this._withoutPersonList) {
      this._changeToPersonListWithoutLists();
      return;
    }

    if (
      this.currentListType !== STOCK_TABLE_TYPES.PERSONAL ||
      item.id_stock_list !== this.listSelected?.id_stock_list
    ) {
      this._previusIdListEmitted = -1;
      this.listSelected = item;
      this.sendListSelected();
      if (this.linked) this._globalStockList.changeGlobalStockList(item);
      return;
    }

    if (item.nm_stock_list !== this.listSelected?.nm_stock_list) {
      this.listSelected!.nm_stock_list = item.nm_stock_list;
      if (this.linked) this._globalStockList.changeGlobalStockList(item);
    }
  }

  selectListNotPersonal(
    item: IDynamicList,
    isInitialList: boolean = false
  ): void {
    this.closeDropdown();
    if (
      !isInitialList &&
      this.currentListType === STOCK_TABLE_TYPES.MOVERS &&
      item.id === this.listSelected?.id_stock_list
    )
      return;
    const list = {
      id_stock_list: item.id,
      nm_stock_list: item.label,
      isNotListPersonal: true,
      in_visibility: true,
      is_favorite: false,
      seq_priority: 0,
      isPresetList: false,
    };
    this.listSelected = list;
    this.changeNotListPersonal.emit(list);
    this._previusIdListEmitted = list.id_stock_list;
    this.linked && this._globalStockList.changeGlobalStockList(list);
    this.cdr.detectChanges();
  }

  selectPresetList(item: string): void {
    if (
      this.currentListType === STOCK_TABLE_TYPES.PRESET &&
      this.presetListSelected === item
    )
      return;
    this.closeDropdown();
    this.presetListSelected = item;
    this.isPresetList = true;
    this._upateIndexListInfos();
    this.changePresetList.emit(item);
  }

  private sendListSelected(): void {
    if (!this.listSelected) return;
    const list = this.listStocksService.getStockList(
      this.listSelected.id_stock_list.toString()
    );
    if (list.idStockList !== this._previusIdListEmitted) {
      this._previusIdListEmitted = list.idStockList;
      this.changeListSelected.emit(list);
      this._headerLayoutSubject.next(
        this._stockTableHeader.nativeElement.offsetWidth
      );
    }
  }

  openCreateStockList = (isNotLists: boolean = false) => {
    this.closeDropdown();
    this.triggeredEvent();
    const ref = this._rocketModalService.open(NewListStockModalComponent, {
      centered: true,
      scrollable: false,
      keyboard: true,
      data: {
        refComponent: this.refComponent,
        isNotLists: isNotLists,
      },
    });
    const modalSub = ref.afterDismissed
      .pipe(filter((item) => item && !item.closed))
      .subscribe((item: number | any) => this.updateLists(item));
    this._subscriptions.add(modalSub);
  };

  openDuplicateStockList = () => {
    if (this.isNotListPersonal) return;
    this.closeDropdown();
    this.triggeredEvent();
    const ref = this._rocketModalService.open(DuplicateListModalComponent, {
      ...this.configModals,
      data: {
        idStockList: this.listSelected?.id_stock_list,
        name: this.listSelected?.nm_stock_list,
      },
    });
    const modalSub = ref.afterDismissed
      .pipe(filter((item) => item && !item.closed))
      .subscribe((item: string | any) => this.updateLists(item));
    this._subscriptions.add(modalSub);
  };

  private updateLists(idStockList: number): void {
    this.idList = idStockList;
    this.getAllLists.emit({ isNewList: true, idStockList });
  }

  openManageStockList = () => {
    if (this.isNotListPersonal) return;
    this.closeDropdown();
    this.triggeredEvent();
    const ref = this._rocketModalService.open(ManageListModalComponent, {
      ...this.configModals,
      data: {
        listStocks: deepClone(this.listStocks),
        list: deepClone(this.list),
        selectedList: deepClone(this.listSelected),
        refComponent: this.refComponent,
      },
    });
    const modalSub = ref.afterDismissed.subscribe(
      (manage: {
        list: any[];
        deletedActualList: boolean;
        editActualList: boolean;
        actualListName: string;
        idStockList: number;
        openCreateList?: boolean;
      }) => {
        if (manage.openCreateList) {
          this.openModalCreateStockByModalManageList$.next();
          return;
        }
        this.listStocks = manage.list;
        this.cdr.detectChanges();
        if (manage.idStockList) {
          this.updateLists(manage.idStockList);
        }
        if (manage.editActualList) {
          this.updateNameOrDeleteList({
            name: manage.actualListName,
            delete: false,
          });
        }
      }
    );
    this._subscriptions.add(modalSub);
  };

  private updateNameOrDeleteList(manage: {
    name: string;
    delete: boolean;
  }): void {
    if (manage.delete) {
      this.listSelected = {} as any;
      this.idList = 0;
      this.getAllLists.emit({ isNewList: true, idStockList: 0 });
    } else {
      this.listSelected && (this.listSelected.nm_stock_list = manage.name);
      this.changeName.emit(manage.name);
      this.getAllLists.emit();
    }
    this.cdr.detectChanges();
  }

  private processEvents = (data: any) => {
    const refList = localStorage.getItem(TRIGGERED_THE_EVENT)?.split('/');
    const triggeredTheEvent = refList
      ? refList![0] === this.refComponent && refList![1] === this.componentId
      : false;
    const { type, detail } = data;
    if (!detail || !this.listSelected || this.isNotListPersonal) {
      return;
    }
    const equalId = !!(
      detail.idStockList == this.listSelected.id_stock_list &&
      !triggeredTheEvent
    );
    const equalName = !!(
      equalId &&
      detail['list.nm_stock_list'] &&
      detail['list.nm_stock_list'] != this.listSelected.nm_stock_list
    );

    if (this.channels.LIST_STOCKS_ADD == type) {
      this.getAllLists.emit();
      return;
    }
    if (this.channels.LIST_STOCKS_UPDATE == type) {
      this.getAllLists.emit();
      equalName &&
        (this.listSelected.nm_stock_list = detail['list.nm_stock_list']);
      equalId && this.sendListSelected();
      return;
    }
    if (this.channels.LIST_STOCKS_DELETE == type) {
      if (equalId) {
        this.listSelected = {} as any;
        this.idList = 0;
        this.cdr.detectChanges();
      }
      this.getAllLists.emit({ isNewList: true, idStockList: this.idList });
      return;
    }
  };

  private triggeredEvent(): void {
    localStorage.setItem(
      TRIGGERED_THE_EVENT,
      `${this.refComponent}/${this.componentId}`
    );
  }

  private closeDropdown(): void {
    this._closeDropdown$.next({ refComponent: this.refComponent });
  }

  handleDropDownVS(): void {
    if (this.toggleDPVisibility) return;
    this.closeDropdown();
  }

  handleView(view: IStockTableView): void {
    if (this._currentViewInfos?.enum !== view.enum) {
      this._currentViewInfos = view;
      this.cdr.detectChanges();
      this.changeStockTableView.emit(view.enum);
      this._updateComponentWidthSubject.next();
    }
  }

  setTableView(view: IStockTableView): void {
    this._currentViewInfos = view;
    this.cdr.detectChanges();
    this._updateComponentWidthSubject.next();
  }

  private _onStockGlobalChange(list: any): void {
    this.listSelected = list;
    this.cdr.detectChanges();
    if (list.isNotListPersonal) {
      this.changeNotListPersonal.emit(list);
      return;
    }
    this.sendListSelected();
  }

  private _updateHeaderLayout(width: number): void {
    const emptySpace = width - this._calcFreeSpace();
    this.moversHeaderAtMinWidth =
      this.displayIndexAndPeriodFilters && emptySpace < 55;
    this.smallListName = emptySpace < 180;
    const listSelector =
      this._stockListSelected?.nativeElement?.offsetWidth ?? 100;
    const selectorsWidth = this._currentViewInfos.width + listSelector;
    this.onlyStockTableIcon = emptySpace <= selectorsWidth;

    if (!this.listSelected) {
      this.hideListNameTooltip = true;
      return;
    }
    const maxLength = this.smallListName ? 6 : 20;
    this.hideListNameTooltip =
      this.listSelected.nm_stock_list.length < maxLength;
    this.cdr.detectChanges();
  }

  private _calcFreeSpace(): number {
    if (!this.displayIndexAndPeriodFilters) return 130;
    return this.moversHeaderUsed;
  }

  private _updateComponentWidth(): void {
    const component = document.getElementById(this.componentId);
    if (!component) return;
    this._updateHeaderLayout(component.offsetWidth);
  }

  private _setWithoutLists(): void {
    this._withoutPersonList = true;
    this.listStocks = [
      {
        id_stock_list: 0,
        in_visibility: true,
        is_favorite: false,
        nm_stock_list: 'Sem listas pessoais',
        isPresetList: false,
      },
    ];
  }

  private _changeToPersonListWithoutLists(): void {
    this.listSelected = this.listStocks[0];
    this.changeListSelected.emit({
      idStockList: 0,
      isNotListPersonal: false,
      configList: null,
      list: <any>{},
      stocks: [],
    });
  }

  onMoversHeaderChangeWidth(width: number): void {
    this.moversHeaderUsed = width;
    this._updateComponentWidthSubject.next();
  }

  selectAuctionAndIndex(index: IStockTableIndex): void {
    this.closeDropdown();
    this.updateAuctionIndex.emit(index);
  }

  handleListActions(action: string): void {
    if (action === 'CREATE') {
      this.openCreateStockList();
      return;
    }
    if (action === 'CLONE') {
      this.openDuplicateStockList();
      return;
    }
    if (action === 'MANAGE') {
      this.openManageStockList();
      return;
    }
  }

  // Handle Index List
  private _upateIndexListInfos(globalStock?: IGlobalStock): void {
    if (
      (this.currentListType !== STOCK_TABLE_TYPES.PRESET &&
        this.currentListType !== STOCK_TABLE_TYPES.PERSONAL) ||
      (!this.isIndex && !this.isStockIndexList)
    ) {
      if (this.isStockIndexList) this.toggleIndexStockListVisibility();
      this.displayToggleToIndexList = false;
      this.cdr.detectChanges();
      return;
    }

    if (this.isStockIndexList) {
      this.indexTooltip = `Retornar para lista anterior`;
      return;
    }
    const index = globalStock
      ? globalStock.cd_stock
      : this.globalStockInfos.cd_stock;
    this.indexTooltip = `Exibir ativos do índice selecionado (${index})`;
    this.displayToggleToIndexList = true;
    this.cdr.detectChanges();
  }

  toggleIndexStockListVisibility(): void {
    this.isStockIndexList = !this.isStockIndexList;
    this._upateIndexListInfos();
    if (this.listSelected) {
      if (this.isStockIndexList)
        this._previusListName = this.listSelected.nm_stock_list;
      this.listSelected.nm_stock_list = this.isStockIndexList
        ? this.globalStockInfos.cd_stock
        : this._previusListName;
    }

    this.displayIndexStocks.emit(this.isStockIndexList);
  }

  getStockIndexList() {
    this._stockService.getStockIndexList().then((data: IStockIndexResponse) => {
      data.success &&
        (this.indexList = data.data.map(
          (item) => ({ value: item.cd_stock } as IStockTableIndex)
        ));
      this.cdr.detectChanges();
    });
  }
}
