import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { STOCK_TABLE_INDEXES } from '@shared/components/stock-table-views';
import { MOVERS_PERIODS } from '@shared/components/stock-table/constants/periods.contants';
import {
  isAuctionList,
  isLowerOrHighMoversList,
  isMoversList,
} from '@shared/components/stock-table/constants/stock-table.constants';
import { IStockTableIndex } from '@shared/components/stock-table/interfaces/movers-index.interface';
import { IMoversPeriod } from '@shared/components/stock-table/interfaces/movers-period.interface';
import { RocketGridService } from '@shared/rocket-grid';
import { Subject, delay, tap } from 'rxjs';

@Component({
  selector: 'app-movers-header-options',
  templateUrl: './movers-header-options.component.html',
  styles: [
    `
      .custom-divider {
        height: 34px;
        top: -1px;
        position: relative;
      }

      .list-max-height {
        width: 160px;
        max-height: 215px;
        overflow-x: auto;
      }

      .movers-options-custom-selector {
        width: 95% !important;
        height: 36px;
        min-height: 36px;
        z-index: 2;
      }

      .collapse-items-custom {
        top: 36px;
      }
    `,
  ],
})
export class MoversHeaderOptionsComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Output() updateHeaderSize = new EventEmitter<number>();

  @Input() public currentMoversIndex: IStockTableIndex = STOCK_TABLE_INDEXES[0];
  @Input() public currentMoversPeriod: IMoversPeriod = MOVERS_PERIODS[0];
  @Input() public idList!: number;
  @Input() public refComponent!: string;
  @Input() public useSmallVersion: boolean = true;
  @Input() public moversIndexes: IStockTableIndex[] = STOCK_TABLE_INDEXES

  public periods = MOVERS_PERIODS;
  public readonly moversIndexDefaultValue = STOCK_TABLE_INDEXES[1];
  public readonly moversPeriodDefaultValue = MOVERS_PERIODS[0];

  public displayPeriods: boolean = false;
  public displayIndex: boolean = false;
  public isExpandedIndex: boolean = false;
  public isPeriodExpanded: boolean = false;
  public closeDP: boolean = false;
  public preventCloseDP: boolean = false;

  private _closeDropDownSubject = new Subject<void>();

  constructor(private _rocketGridService: RocketGridService) {
    this._closeDropDownSubject
      .pipe(
        tap(() => (this.closeDP = true)),
        delay(100)
      )
      .subscribe(() => (this.closeDP = false));
  }

  ngOnInit(): void {
    this.updateHeaderSize.emit(this._updateHeaderSpaceInUse());
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { idList } = changes;
    if (idList?.currentValue) this._setMoversOptions();
  }

  ngOnDestroy(): void {
    this.updateHeaderSize.emit(175);
    this._closeDropDownSubject.unsubscribe();
  }

  public handleCloseDP(): void {
    if (this.preventCloseDP) return;
    this._closeDropDownSubject.next();
  }

  private _setMoversOptions(): void {
    if (this.idList) {
      this.displayPeriods = isLowerOrHighMoversList(this.idList);
      this.displayIndex =
        isMoversList(this.idList) || isAuctionList(this.idList);
      this.updateHeaderSize.emit(this._updateHeaderSpaceInUse());
      return;
    }
    this.displayPeriods = false;
    this.displayIndex = false;
    this.updateHeaderSize.emit(this._updateHeaderSpaceInUse());
  }

  private _updateHeaderSpaceInUse(): number {
    if (this.displayPeriods && this.displayIndex) return 247;
    if (this.displayIndex) return 200;
    return 175;
  }

  public handleMoversOption(type: 'INDEX' | 'PERIOD', value: any): void {
    if (type === 'INDEX') {
      if (value.value === this.currentMoversIndex.value) return;
      this._closeDropDownSubject.next();
      this._rocketGridService.updateMoversIndex({
        index: value as IStockTableIndex,
        refComponent: this.refComponent,
      });
      return;
    }

    if (value.value === this.currentMoversPeriod.value) return;
    this._closeDropDownSubject.next();
    this._rocketGridService.updateMoversPeriod({
      period: value as IMoversPeriod,
      refComponent: this.refComponent,
    });
  }
}
