import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';
import { COLOR_BOLETA } from '@shared/components/stock-trade/constants/boleta.constants';

export const BOLETA_COMPRA_BASECOMPONENT = () => {
  return {
    name: 'Boleta Compra',
    id: COMPONENTS_NAMES_ENUM.BOLETA,
    icon: 'flash_on',
    cod: 'TRADE_BUY',
    data: { bg: COLOR_BOLETA.BUY },
    close: true,
    fullscreen: true,
    link: true,
    minWidth: '535px',
    width: 554,
    height: 400,
    minHeight: '400px',
    widthDesk: 696,
    heightDesk: 485,
    iconSvg: '',
    iconColor: 'text-brand-primary',
  };
};
