import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { ISearchStock, IWorkSpaceComponet } from '@core/interface';
import { TABS, TABS_INDEX } from './business-profile.const';
import { IBusinessProfileTab } from './business-profile.interface';
import { HomeService } from '@modules/home/service/home.service';
import { Subject, Subscription, debounceTime, filter } from 'rxjs';
import { BusinessProfileService } from './business-profile.service';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { isTypeStockIndex } from '@shared/constants/general.contant';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { ActivatedRoute } from '@angular/router';
import { isWebViewContext } from 'src/app/desktop/integration.service';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { RocketStreamRead } from '@shared/channel/rx-event';

@Component({
  selector: 'app-business-profile',
  templateUrl: './business-profile.component.html',
  styleUrls: ['./business-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessProfileComponent
  extends RocketComponentBase
  implements AfterViewInit, OnDestroy
{
  @Input() component!: IWorkSpaceComponet;
  @Input() height!: string | number;
  @Input() width!: string | number;

  refComponent!: string;
  selectedTab = 'RESUME';
  tabs = TABS;
  tabsIndex = TABS_INDEX;
  stockInfo: any = {};
  isDesktop = false;
  componentID: string = 'BUSINESS_PROFILE_CONTAINER';
  isSotckIndex = false;

  private _updateMetadataSubject = new Subject<void>();
  private stockChanged$ = new Subscription();
  private quoteParams!: SubscribeParam;
  private quoteCheetahStream!: RocketStreamRead;

  constructor(
    private homeService: HomeService,
    private businessProfileService: BusinessProfileService,
    private cdr: ChangeDetectorRef,
    private globalStock: GlobalSelectedStockSubscription,
    private _quoteChannel: QuoteChannel,
    activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
    this.isDesktop = isWebViewContext();
    this._updateMetadataSubject
      .pipe(debounceTime(100))
      .subscribe(() =>
        this.homeService.updateMeta<ISearchStock>(this.component)
      );

    this.stockChanged$ = this.businessProfileService.stockChanged
      .pipe(filter((value) => value.refComponent === this.refComponent))
      .subscribe((value) => this.stockChanges(value.stock, true));
  }

  get headerOptions() {
    return this.component.metadata?.headerOptions;
  }

  get stockSelected(): ISearchStock {
    return this.component?.metadata?.component?.stock ?? {};
  }

  get itemsSubscribed() {
    return `${this.stockSelected.cd_stock}:${this.stockSelected.id_exchange}`;
  }

  protected initComponent(component: any): void {
    if (component) this.component = component;

    this.componentInit();
  }

  ngOnDestroy(): void {
    this._updateMetadataSubject.unsubscribe();
    this.stockChanged$ && this.stockChanged$.unsubscribe();
    this.quoteCheetahStream?.close();
    this._destroyCheetah();
  }

  ngAfterViewInit() {
    this._quoteChannel.readEvents().then(async (data) => {
      this.quoteCheetahStream = data;
      this.quoteCheetahStream.snapshot([this.itemsSubscribed]);
      this.readStream(this.quoteCheetahStream.stream, this._quoteHandler);
    });
    this.subscribeCheetah();
  }

  private _quoteHandler = (value: any) => {
    const data = value.get(this.itemsSubscribed);
    if (!data || data.isEndOfSnap) return;
    const stockTemp = { ...this.stockInfo, ...this.clearCheetahData(data) };
    const updatedData = this.businessProfileService.getFormattedValues(
      stockTemp,
      this.stockSelected
    );
    this.stockInfo = updatedData;
    this.cdr.detectChanges();
  };

  subscribeCheetah() {
    this.quoteParams = {
      header: this.refComponent,
      itemsArray: [this.itemsSubscribed],
    };
    this._quoteChannel.subscribe(this.quoteParams);
    !!this.quoteCheetahStream &&
      this.quoteCheetahStream.snapshot([this.itemsSubscribed]);
  }

  private _destroyCheetah(): void {
    this.quoteParams && this._quoteChannel.unsubscribe(this.quoteParams);
  }

  private _updateMetadata = () => this._updateMetadataSubject.next();

  private emitSearchStock = (stock: ISearchStock): void => {
    if (!stock) return;
    if (this.headerOptions.link) {
      this.globalStock.changeGlobalStock(stock);
    }
  };

  componentInit() {
    this.isSotckIndex = isTypeStockIndex(this.stockSelected.type);
    this.refComponent = this.component.metadata.headerOptions.component!.data;
    this.componentID = `BUSINESS_PROFILE_CONTAINER_${this.refComponent}`;
    if (Object.keys(this.stockSelected).length)
      this.processComponent(this.stockSelected);
    const initialTab = this.component.metadata.component.lastActiveTab;
    if (initialTab) this.changeTab(initialTab, true);
  }

  private processComponent(stock: ISearchStock): void {
    if (stock && !stock.country_image_url) {
      this.stockSelected.country_image_url =
        this.stockSelected.id_exchange === 1
          ? `https://cdn.trademap.com.br/assets/flags/brazil.imageset/brazil.png`
          : `https://cdn.trademap.com.br/assets/flags/united-states-of-america.imageset/united-states-of-america.png`;
    }
  }

  stockChanges = (stock: ISearchStock, initialConf: boolean = false): void => {
    if (
      this.stockSelected &&
      stock &&
      this.stockSelected.cd_stock === stock.cd_stock &&
      this.stockSelected.id_exchange === stock.id_exchange
    ) {
      return;
    }
    if (isTypeStockIndex(stock.type) !== this.isSotckIndex) {
      this.selectedTab = 'RESUME';
    }
    this.isSotckIndex = isTypeStockIndex(stock.type);
    this._destroyCheetah();
    this._updateStockMetadata(stock);
    this.processComponent(stock);
    this.emitSearchStock(stock);
    this.subscribeCheetah();
    if (initialConf) this.changeTab(this.tabs[0], true);
  };

  private _updateStockMetadata(data: ISearchStock): void {
    if (!this.component) return;
    this.component.metadata.component = {
      ...this.component.metadata.component,
      stock: data,
    };
    this.cdr.detectChanges();
    this._updateMetadata();
  }

  changeTab(tab: IBusinessProfileTab, initialConf: boolean = false) {
    if (tab.cod === this.selectedTab) return;
    this.selectedTab = tab.cod;
    this.cdr.detectChanges();
    if (!initialConf) {
      this.component.metadata.component.lastActiveTab = { cod: tab.cod };
      this._updateMetadata();
    }
  }
}
