export interface IAlert {
  cd_status: ALERT_STATUS_ENUM;
  cd_stock: string;
  cd_strategy_period: string;
  cd_type: string;
  cond: string;
  dh_insert: string;
  ds_status: string;
  ds_strategy: string;
  ds_type: string;
  id_exchange: number;
  id_menu: number;
  id_stock: number;
  id_trade_system: number;
  is_just_one_hit: boolean;
  seq: number;
  vl_value: number;
  vl_current_price: number;
  tick_size_denominator: number;
  ds_asset: string;
  cd_segment: number;
  first_box_id?: string;
  ds_status_custom?: 'Ativo' | 'Concluído';
  isSelected?: boolean;
}

export interface IAlertTransaction {
  add?: IAlert[];
  update?: IAlert[];
  remove?: any[];
}

export interface IAlertSettings {
  action: 'EDIT' | 'DELETE' | 'REFRESH';
  alert: IAlert;
}

export interface IAlertConfigMenu {
  enum: ALERT_MENU_ENUM;
  label: string;
  icon: string;
  disabled: boolean;
  bottomLine: boolean;
}

export enum ALERT_MENU_ENUM {
  EDIT_ALERT = 'EDIT_ALERT',
  REMOVE_ALERT = 'REMOVE_ALERT',
  REOPEN_ALERT = 'REOPEN_ALERT',
  HIDDEN_COLUMN = 'HIDDEN_COLUMN',
  RESET_FILTERS = 'RESET_FILTERS',
  RESET_SORT = 'RESET_SORT',
  RESET_PRESET = 'RESET_PRESET',
  HELP = 'HELP',
  PREFERENCES = 'PREFERENCES',
  EXPORT_DATA = 'EXPORT_DATA'
}

export enum ALERT_STATUS_ENUM {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
}
