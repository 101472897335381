import { Injectable } from '@angular/core';
import { StockServiceRT } from '@shared/services/api/nitro-ws/v1/stock.service';
import { ISearchStock } from 'src/app/core/interface';
import { GLOBAL_STOCK_CHANGE } from './const/events-name';
import { GlobalStockPreferences } from '../custom-preferences/global-stock/global-stock-preferences.service';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { RxEvent, StreamReadResponse } from '@shared/channel/rx-event';
@Injectable({
  providedIn: 'root',
})
export class GlobalSelectedStockSubscription {
  private get globalStock() {
    return this.preferences.globalStockSelected;
  }

  private set globalStock(stock) {
    this.preferences.globalStockSelected = stock;
  }

  constructor(
    private rxEvent: RxEvent,
    private stockService: StockServiceRT,
    private preferences: GlobalStockPreferences
  ) {}

  hasStock = () => Boolean(this.globalStock);

  setGlobalStockSelected(stock: any) {
    this.globalStock = stock;
  }

  getGlobalStockSelected(): ISearchStock {
    return this.globalStock;
  }

  changeGlobalStock(stock: ISearchStock) {
    if (!stock || !Object.keys(stock).length) return;
    this.globalStock = stock;
    this.rxEvent.emit(GLOBAL_STOCK_CHANGE, {
      ...stock,
      channel: GLOBAL_STOCK_CHANGE,
    });
  }

  onGlobalStockChange(): StreamReadResponse {
    return this.rxEvent.read(GLOBAL_STOCK_CHANGE);
  }

  researchToStandardizeGlobalStock(
    data: any,
    searchByStock: boolean = false
  ): void {
    if (
      `${data.cd_stock}:${data.id_exchange}` ===
      `${this.globalStock.cd_stock}:${this.globalStock.id_exchange}`
    )
      return;
    const cdStock =
      isNullOrUndefined(data.synonymous_nickname) ||
      data.synonymous_nickname === ''
        ? data.cd_stock
        : data.synonymous_nickname;
    !searchByStock &&
      this.stockService
        .searchStock(cdStock, [], true)
        .subscribe((resp: any) => {
          const stock = resp[0];
          !!stock && this.changeGlobalStock(stock);
        });
    searchByStock &&
      this.stockService.searchStockByStock(cdStock).subscribe((resp: any) => {
        this.changeGlobalStock(resp);
      });
  }
}
