<div [ngClass]="{ 'gradient-border round': displayBorder }">
  <div
    class="bg-neutral-strong p-3 round align-items-center content-container gap-3 h-100 hstack justify-content-between w-100 rounded"
    (click)="clicked.emit()"
  >
    <app-broker-logo
      [logo]="logo"
      [imageSize]="24"
      iconSize="icon-size-sm"
    ></app-broker-logo>
    <div class="fs-6 vstack">
      <span class="fs-5 fw-bold">{{ title }}</span>
      <span> {{ message }} </span>
    </div>
    <span class="material-icons text-brand-primary"> chevron_right </span>
  </div>
</div>
