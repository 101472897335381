import { formatNumber } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { Point } from 'scichart/Core/Point';
import {
  CustomAnnotation,
  ICustomAnnotationOptions,
} from 'scichart/Charting/Visuals/Annotations/CustomAnnotation';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { TIGER_CHART_TOOL } from '../tiger-chart-tools/tiger-chart-tools.interface';
import { TMouseEventData } from '../types/tiger-chart.types';
import { DrawTools, LineToolsOptions } from './draw-tools.interface';

export class FibonacciRetracementTools
  extends CustomAnnotation
  implements DrawTools
{
  tool!: TIGER_CHART_TOOL;
  viewBoxWidth!: number;
  viewBoxHeight!: number;
  maxYValue!: number;
  minYValue!: number;
  tickSize: number = 0;
  fibonacciRetracementLevels = [
    4.236, 3.618, 2.618, 1.618, 1, 0.786, 0.618, 0.5, 0.382, 0.236, 0,
  ];
  children = [];
  isDraw = true;
  startEdit = false;
  baseChart!: TWebAssemblyChart;
  isChildren = false;
  lastPointValues!: Point;
  dictIdChildren!: string;
  isAux: boolean = false;
  endDraw: boolean = false;
  lastEventData!: TMouseEventData;
  isWithinDataBounds: boolean | undefined = true;

  constructor(
    options: ICustomAnnotationOptions,
    private drawToolsService: DrawToolsService,
    tool: LineToolsOptions,
    @Inject(LOCALE_ID) private locale: string
  ) {
    super(options);
    this.isChildren = tool.isChildren;
    this.baseChart = tool.baseChart;
    this.tool = tool.tool;
    this.viewBoxWidth = tool.viewBoxWidth;
    this.viewBoxHeight = tool.viewBoxHeight;
    this.maxYValue = this.tool.maxYValue!!;
    this.minYValue = this.tool.minYValue!!;
    this.tickSize = tool.stock.tick_size_denominator;
    this.dictIdChildren = tool.dictIdChildren;
    this.isAux = tool.isAux;
    this.lastEventData = tool.eventData;
    this.isWithinDataBounds = tool.eventData.isWithinDataBounds;
    this.makeSvg();
  }

  initSubscriptions(): void {
    //do nothing;
  }

  unsubscriber(): void {
    //do nothing;
  }

  getName() {
    return this.constructor.name;
  }

  updateDrawTool(eventData: TMouseEventData) {
    this.lastEventData = eventData;
    const svgElement = document.getElementById(`SVG_${this.tool.dictId!!}`);
    const x2 = (this.tool.mouseValues!!.x - eventData.mousePoint!!.x) * -1;
    const y2 = (this.tool.mouseValues!!.y - eventData.mousePoint!!.y) * -1;
    this.tool.maxYValue = parseFloat(
      eventData.pointValues!.y!!.toFixed(this.tickSize)!!
    );
    if (svgElement) {
      for (let k = 0; k < this.fibonacciRetracementLevels.length; k++) {
        const element = document.getElementById(`${this.tool.dictId!!}_${k}`);
        if (element) {
          const textElement = document.getElementById(
            `TEXT_${this.tool.dictId!!}_${k}`
          );
          element.setAttribute('x2', `${x2}`);
          textElement!!.textContent = `${
            this.fibonacciRetracementLevels[k]
          }(${formatNumber(
            this.tool.minYValue!!,
            this.locale,
            `1.${this.tickSize}-${this.tickSize}`
          )})`;
          if (k != 4) {
            const newY = y2 - this.fibonacciRetracementLevels[k] * y2;
            element.setAttribute('y1', `${newY}`);
            element.setAttribute('y2', `${newY}`);
            textElement?.setAttribute('y', `${newY - 10}`);
            const UR =
              this.tool.maxYValue -
              (this.tool.maxYValue - this.tool.minYValue!!) *
                this.fibonacciRetracementLevels[k];
            textElement!!.textContent = `${
              this.fibonacciRetracementLevels[k]
            }(${formatNumber(
              UR,
              this.locale,
              `1.${this.tickSize}-${this.tickSize}`
            )})`;
          }
        }
      }
      const draw = this.drawToolsService.drawDict.get(this.tool.dictId!!)!!;
      draw.svgString = svgElement.outerHTML!!;
      this.drawToolsService.updateToolInfo(this.tool);
    }
  }

  private makeSvg() {
    const originalSvg = this.drawToolsService.getSvgString(
      this.tool,
      this.viewBoxWidth,
      this.viewBoxHeight
    );
    const doc = document.createElement('div');
    doc.innerHTML = originalSvg.trim();
    const line = doc.getElementsByTagName('line');
    const text = doc.getElementsByTagName('text');
    if (line && line[0]) {
      const svg = doc.getElementsByTagName('svg');
      if (svg && svg[0]) {
        svg[0].getElementsByTagName('line')[0].id = `${this.tool.dictId}_0`;
        svg[0].getElementsByTagName(
          'text'
        )[0].id = `TEXT_${this.tool.dictId}_0`;
        svg[0].getElementsByTagName('text')[0].innerHTML = formatNumber(
          this.maxYValue,
          this.locale,
          `1.${this.tickSize}-${this.tickSize}`
        );
        for (let k = 1; k < this.fibonacciRetracementLevels.length; k++) {
          svg[0].innerHTML += text[0].outerHTML.replace(
            `TEXT_${this.tool.dictId}_0`,
            `TEXT_${this.tool.dictId}_${k}`
          );
          svg[0].innerHTML += line[0].outerHTML.replace(
            `${this.tool.dictId}_0`,
            `${this.tool.dictId}_${k}`
          );
        }
        this.tool.svgString = svg[0].outerHTML;
      }
    }
    this.svgString = this.tool.svgString!!;
  }

  customClick = () => {
    //do nothing.
  };

  repositionZoomDraw(): void {
    //do nothing.
  }

  customHover = () => {
    //do nothing.
  };

  updateStyleTool() {
    //do nothing.
  }

  updateTool(tool: TIGER_CHART_TOOL) {
    this.tool = tool;
  }
}
