<div class="dropdown d-flex">
  <a
    [id]="ELEMENT_IDS.STOCK_TABLE_CHANGE_VIEW"
    class="align-items-center bg-neutral-stronger btn d-inline-flex dropdown-toggle h-100 hide-arrow justify-content-start p-0 px-1 w-100"
    [ngClass]="{ show: forceDisplayDP }"
    style="min-width: 100% !important"
    role="button"
    id="stockTableView"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <div
      class="d-inline-flex align-items-center gap-1 view-icon-min-width"
      [tooltip]="currentView.name"
      [tooltipHidden]="!onlyIcon"
      tooltipPosition="bottom"
    >
      <ng-template
        *ngTemplateOutlet="
          viewIcon;
          context: { data: currentView, useMarginBottom: true }
        "
      ></ng-template>
      <ng-container *ngIf="!onlyIcon">
        <span class="fs-6 fw-bold text-white">
          {{ currentView.name }}
        </span>
        <fla-icon
          name="expand_more"
          size="icon-size-micro"
          css="text-white"
        ></fla-icon>
      </ng-container>
    </div>
  </a>

  <ul
    aria-labelledby="stockTableView"
    class="dropdown-menu overflow-hidden round-sm"
    [ngClass]="{ 'dp-position show': forceDisplayDP }"
  >
    <li *ngFor="let view of views">
      <a
        type="button"
        class="dropdown-item p-2 d-inline-flex"
        (click)="stockTypeHandler(view)"
      >
        <div class="d-inline-flex align-items-center gap-2">
          <ng-template
            *ngTemplateOutlet="
              viewIcon;
              context: { data: view, useMarginBottom: false }
            "
          ></ng-template>
          <span
            class="fs-6 fw-bold"
            [ngClass]="{
              'text-brand-primary': currentView.enum === view.enum
            }"
          >
            {{ view.name }}
          </span>
        </div>
      </a>
    </li>
  </ul>
</div>

<ng-template #viewIcon let-data="data" let-useMarginBottom="useMarginBottom">
  <ng-container *ngIf="data.custom_icon; else flaIcon">
    <div [ngClass]="{ 'mb-1': data.custom_icon && useMarginBottom }">
      <app-rocket-icons
        [name]="data.icon"
        [color]="
          data.enum === currentView.enum
            ? 'var(--vm-brand-primary)'
            : 'var(--vm-neutral-smoothest)'
        "
      ></app-rocket-icons>
    </div>
  </ng-container>
  <ng-template #flaIcon>
    <fla-icon
      size="icon-size-micro"
      [name]="data.icon"
      [css]="
        data.enum === currentView.enum ? 'text-brand-primary' : 'text-white'
      "
    >
    </fla-icon>
  </ng-template>
</ng-template>
