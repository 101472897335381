import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TABS_TYPE } from '../../constants/orders-history.const';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { everythingOrNothing } from '../../grid/columns';
import {
  isDoubleStartStop,
  isStartStop,
} from '@shared/constants/general.contant';
import { OrdersService } from '@shared/services/orders.service';
import { ALERT_STATUS_ENUM, IAlert } from '@core/interface';
import { filter, Subject, takeUntil } from 'rxjs';
import { MultibrokerService } from '@shared/services/core/multibroker';
import { FilterChangedEvent, GridApi, GridOptions } from 'ag-grid-community';
import { OrdersHistoryService } from '../../service/orders-history.service';
import {
  DragService,
  rowDragText,
} from '@shared/rocket-components/services/ag-grid/drag.service';
import { GridIChangeField } from '@shared/rocket-grid';

@Component({
  selector: 'app-orders-history-table-view',
  templateUrl: './orders-history-table-view.component.html',
  styles: [
    `
      :host(app-orders-history-table-view) {
        display: contents;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersHistoryTableViewComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() refComponent!: any;
  @Input() componentType!: string;
  @Input() componentId!: any;
  @Input() globalCdStock!: any;
  @Input() fieldIndexer = '';
  @Input() tableConfig: any = {};
  @Input() noRowsTemplateMessage: string = 'Não existem dados.';
  @Input() onUpdateField!: GridIChangeField;
  @Input() topOptions!: GridOptions;
  @Input() bottomOptions!: GridOptions;
  @Input() ignoreRocketService!: boolean;
  @Output() removeAllAlertsAndOrders: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() changeStockHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() columnMovedHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() columnResizedHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() gridReadyHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() contextMenuHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() gridSortChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() gridColumnApiReference: EventEmitter<any> = new EventEmitter<any>();
  @Output() gridFilterChange: EventEmitter<FilterChangedEvent> =
    new EventEmitter<FilterChangedEvent>();
  @Output() editOrderHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() editAlertHandler: EventEmitter<IAlert> = new EventEmitter<IAlert>();
  @Output() resetPositionHandler: EventEmitter<any> = new EventEmitter<any>();

  dropZoneParams!: any;
  configColumnDefs: any[] = [];
  resetFilter: boolean = false;
  displayPinnedLine: boolean = false;
  public sortingOrder: ('asc' | 'desc' | null)[] = ['desc', 'asc', null];
  public agGridApi!: GridApi;
  private onDestroy$ = new Subject<void>();
  rowDragText = rowDragText;

  constructor(
    private dragService: DragService,
    private _stockChartService: StockChartService,
    private ordersService: OrdersService,
    private _multiBrokerService: MultibrokerService,
    private ordersHistoryService: OrdersHistoryService
  ) {}

  ngOnInit() {
    this.ordersHistoryService.clearGrid$
      .pipe(
        takeUntil(this.onDestroy$),
        filter((data) => this.componentId === data)
      )
      .subscribe(this.clearGrid);
  }

  ngAfterViewInit() {
    this.dropZoneParams = this.dragService.makeDragZoneParams();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public editOrder = (value: any) => {
    this.editOrderHandler.emit(value)
  };

  clearGrid() {
    this.agGridApi && this.agGridApi.setRowData([]);
  }

  public changeBrokerAccount = (id_broker: number) => {
    this._multiBrokerService.getAccounstMultibroker().then((data: any) => {
      const account = data.accounts.find(
        (item: any) => item.id_broker == id_broker
      );

      if (account && account != undefined) {
        this._multiBrokerService.account$.next(account);
      }
    });
  };

  public selectRow = (event: any): void => {
    this.checkResetPosition(event);

    if (this.componentType === TABS_TYPE.ORDER) {
      this._stockChartService.orderSelectedByGrid$.next({ order: event.data });
      if (
        event.colDef.field === 'edit' &&
        (!everythingOrNothing(event.data) ||
          isStartStop(event.data.cd_order_type) ||
          isDoubleStartStop(event.data.cd_order_type))
      ) {
        this.editOrder(event.data);
        return;
      }

      if (event.colDef.field === 'close') {
        this.ordersService.cancelOrders([event.data]);
        return;
      }
    }

    if (this.componentType === TABS_TYPE.ALERTS) {
      const fieldType = event.colDef.field;
      const alertInfo: IAlert = event.data;

      if (fieldType === 'alert_status') {
        event.api.startEditingCell({
          rowIndex: event.node.rowIndex,
          colKey: 'alert_status',
          rowPinned: null,
          data: alertInfo,
        });
        return;
      }

      if (
        fieldType === 'edit' ||
        alertInfo.cd_status === ALERT_STATUS_ENUM.COMPLETED
      ) {
        this.editAlertHandler.emit(alertInfo)
        return;
      }

      if (fieldType === 'delete') {
        this.removeAllAlertsAndOrders.emit(alertInfo);
        return;
      }
    } else {
      this.changeBrokerAccount(event.data.id_broker);
    }
    this.changeStockHandler.emit(event.data);
  };

  public columnMoved = (event: any) => this.columnMovedHandler.emit(event);

  public columnResized = (event: any): void =>
    this.columnResizedHandler.emit(event);

  public onGridReady = (event?: any) => {
    this.agGridApi = event;
    this.gridReadyHandler.emit(this.agGridApi);
  };

  public emitContext = (event?: any) => {
    this.contextMenuHandler.emit(event);
  };

  public onGridFilterChange(event: FilterChangedEvent) {
    this.gridFilterChange.emit(event);
  }

  public onHeaderSortChange(event: any): void {
    this.gridSortChange.emit(event);
  }

  public columnApiReference(event: any): void {
    this.gridColumnApiReference.emit(event);
  }

  public checkResetPosition(
    event: any,
    isResetAllPosition: boolean = false
  ): void {
    if (
      (isResetAllPosition || event.colDef.field === 'remove_custody') &&
      (this.componentType === TABS_TYPE.INTRADIA ||
        this.componentType === TABS_TYPE.SWINGTRADING ||
        this.componentType === TABS_TYPE.POSITION)
    ) {
      const fieldQtty = {
        [TABS_TYPE.INTRADIA]: 'qtty_final_daytrade',
        [TABS_TYPE.SWINGTRADING]: 'qtty_final_swing',
        [TABS_TYPE.POSITION]: 'qtty_final',
      };
      const fieldOpen = {
        [TABS_TYPE.INTRADIA]: 'net_day_daytrade',
        [TABS_TYPE.SWINGTRADING]: 'net_day_swing',
        [TABS_TYPE.POSITION]: 'net_day',
      };
      if (
        (event.data[fieldQtty[this.componentType]] !== '0' ||
          event.data[fieldOpen[this.componentType]] !== '0') &&
        !this.ordersHistoryService.stockInResetPosition[event.data.cd_stock]
      ) {
        this.ordersHistoryService.stockInResetPosition[event.data.cd_stock] =
          event.data.cd_stock;
        const position = {
          ...event.data,
          qtd: event.data[fieldQtty[this.componentType]],
          isResetAllPosition,
        };
        this.resetPositionHandler.emit({position, isResetAllPosition});
      }
    }
  }

}
