import { Injectable } from '@angular/core';
import { FundamentalService } from '@shared/services/api/trademap/V2/fundamental.service';
import { LoanBalanceService } from '@shared/services/api/trademap/V6/loan-balance.service';
import { map } from 'rxjs';
import { IStockFunds } from '../interface/rent.interface';
import { getVolumeText } from 'src/app/utils/utils.functions';
import { formatterNumber } from '@shared/rocket-components/utils';
import { FundsService } from '@shared/services/api/trademap/v1/funds.service';
import { RENT_PERIOD } from '../enum/rent.enum';

@Injectable({
  providedIn: 'root',
})
export class RentService {
  constructor(
    private _loanBalanceService: LoanBalanceService,
    private _fundamentalService: FundamentalService,
    private _fundsService: FundsService
  ) {}

  public findRents = () => this._loanBalanceService.getRentTable();

  public stockBalance = (
    cd_stock: string,
    period: RENT_PERIOD = RENT_PERIOD.one_year
  ) => this._loanBalanceService.getStockBalance(cd_stock, period);

  public stockFunds = (
    cd_stock: string,
    strategy: 'long' | 'short',
    limit: number,
    offset: number
  ) =>
    this._fundamentalService
      .findStockFunds(cd_stock, strategy, limit, offset)
      .pipe(
        map((response) => {
          if (!response.isCache && response.data)
            return this._formatFundsData(response.data);
          return response.data;
        })
      );

  private _formatFundsData(funds: IStockFunds[]): IStockFunds[] {
    return funds.map((fund: any) => {
      fund.vl_final_market = getVolumeText('pt-BR', fund.vl_final_market);
      fund.pc_net_worth = formatterNumber(fund.pc_net_worth);
      fund.pc_net_worth_company = formatterNumber(fund.pc_net_worth_company);
      fund.expanded = false;
      fund.loading = false;
      fund.erro = false;
      fund.fundsInfo = null;
      return fund;
    });
  }

  public fundData = (idFund: number, cdStock: string, period: number = 1) =>
    this._fundsService.getFundData(idFund, cdStock, period);
}
