import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalAcceptTermsComponent } from './modal-accept-terms.component';
import {
  RocketButtonModule,
  RocketModalModule,
} from '@shared/rocket-components/components';
import { ModalTokenConfigModule } from '@core/layout/header/modal-token-config/modal-token-config.module';

@NgModule({
  imports: [
    CommonModule,
    RocketButtonModule,
    RocketModalModule,
    ModalTokenConfigModule,
  ],
  declarations: [ModalAcceptTermsComponent],
})
export class ModalAcceptTermsModule {}
