<div
  *ngIf="visible"
  class="stock-analysis-tooltip bg-neutral-stronger"
  [style.top.px]="top"
  [style.left.px]="left"
>
  <h5 class="text-center text-white">{{ data.stock }}</h5>

  <div class="fs-6 mt-1 text-white">
    <app-rocket-icons
      class="stock-analysis-selector-icon ml-2"
      name="stock_analysis_x_axis"
    ></app-rocket-icons>
    {{ data.profitability }}%
  </div>
  <div class="fs-6 mt-1 text-white">
    <app-rocket-icons
      class="stock-analysis-selector-icon ml-2"
      name="stock_analysis_y_axis"
    ></app-rocket-icons>
    {{ data.volatility }}
  </div>
  <div class="fs-6 mt-1 text-white">
    <app-rocket-icons
      class="stock-analysis-selector-icon ml-2"
      name="stock_analysis_z_axis"
    ></app-rocket-icons>
    {{ data.variation }}
  </div>
</div>
