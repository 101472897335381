export enum CONTEXT_MENU_ITEM_ENUM {
  RESET,
  LOCK_VERTICAL_LINE,
  OBJECT_LIST,
  REMOVE_ALL_TOOLS,
  REMOVE_ALL_INDICATORS,
  MORE_OPTIONS,
  TOGGLE_VOLUME,
  TYPE_ORDER,
  FAST_TYPE_ORDER,
  TYPE_STOP_ORDER,
  CREATE_ALERT,
  TOGGLE_CUSTODY_STOP_ORDER,
  DRAW_HORIZONTAL_LINE,
  HELP,
  ADD_INDICATORS,
}

export enum CONTEXT_MENU_ORDER_TYPE {
  BUY = 'Comprar',
  SELL = 'Vender',
}

export enum CONTEXT_MENU_ORDER_BOLETA_TYPE {
  BUY = 'Boleta',
  SELL = 'Boleta',
}
