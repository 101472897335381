<div class="w-100 p-2 mt-1 d-flex justify-content-between">
  <div class="col-11 d-flex">
    <div>
      <rocket-button
        type="icon"
        css="min-w-0 pr-0"
        (rocketClick)="scrollBackward()"
      >
        <fla-icon
          name="arrow_back_ios"
          size="icon-size-micro"
          css="p-0 pl-1 arrow"
        ></fla-icon>
      </rocket-button>
    </div>
    <div #scroll class="d-flex align-items-center ml-0 mr-2 scroll-wrapper">
      <a
        type="button"
        class="px-2 text-nowrap"
        [id]="'tag-' + tag.tag_agg"
        [ngClass]="
          selectedTagId === tag.tag_agg ? 'text-brand-primary' : 'text-body'
        "
        *ngFor="let tag of newsGroup.itens"
        (click)="selectTag($event, tag.tag_agg)"
      >
        {{ tag.nm_view_news }}
      </a>
    </div>
    <div>
      <rocket-button
        type="icon"
        css="min-w-0 pr-0"
        (rocketClick)="scrollForward()"
      >
        <fla-icon
          name="arrow_forward_ios"
          size="icon-size-micro"
          css="p-0 pl-1 arrow"
        ></fla-icon>
      </rocket-button>
    </div>
  </div>
  <div class="col-4">
    <fla-input
      margin="ml-2"
      placeholder="Pesquisar"
      type="text"
      decidePosition="prefix"
      [isSmall]="true"
      [formControl]="formControl"
      (keyup)="searchNews($event)"
    >
      <span class="prefix pl-1 cursor-pointer">
        <fla-icon name="search" size="icon-size-sm"></fla-icon>
      </span>
    </fla-input>
  </div>
</div>
