<div [class]="customClass">
  <input
    type="hidden"
    [id]="refComponent"
    [formControl]="formControl"
    [required]="isRequired"
  />
  <ng-container *ngFor="let radio of radioButtons">
    <ng-container
      *ngTemplateOutlet="radio.content; context: { refComponent }"
    ></ng-container>
  </ng-container>

  <small
    class="ng-invalid invalid-feedback d-block"
    *ngIf="
      (isRequired &&
        formControl.enabled &&
        formControl.invalid &&
        formControl.touched) ||
      formControl.hasError(hasError)
    "
  >
    {{ requiredText }}
  </small>
</div>
