<rocket-modal [ngClass]="data.styleModal" class="settings-modal">
  <rocket-modal-header (onDimissClicked)="closeModal()">{{
    indicatorAbbreviation
  }}</rocket-modal-header>
  <app-rocket-modal-body>
    <fla-tab>
      <fla-tab-header
        [ref]="tabNameEnum.SETTINGS"
        title="Entrada"
        [active]="true"
        (flaChange)="tabChanges($event)"
        *ngIf="data.indicator.settings.length"
      ></fla-tab-header>
      <fla-tab-header
        [ref]="tabNameEnum.STYLES"
        title="Estilo"
        (flaChange)="tabChanges($event)"
        [active]="!data.indicator.settings.length"
      ></fla-tab-header>
      <fla-tab-body
        *ngIf="data.indicator.settings.length"
        [ref]="tabNameEnum.SETTINGS"
        [active]="true"
      >
        <ng-container>
          <app-settings-input
            [indicator]="data.indicator"
            [baseChart]="data.baseChart"
            [refComponent]="data.refComponent"
          ></app-settings-input>
        </ng-container>
      </fla-tab-body>
      <fla-tab-body
        [active]="!data.indicator.settings.length"
        [ref]="tabNameEnum.STYLES"
      >
        <ng-container>
          <RT-styles-input
            [indicator]="data.indicator"
            [baseChart]="data.baseChart"
            [refComponent]="data.refComponent"
          ></RT-styles-input>
        </ng-container>
      </fla-tab-body>
    </fla-tab>
    <div class="d-flex gap-2 justify-content-between mb-6">
      <rocket-model-save
        (rocketChange)="updateConfigUser($event)"
      ></rocket-model-save>
      <rocket-button
        label="Ok"
        [isSmall]="true"
        (rocketClick)="saveChangeIndicator()"
      ></rocket-button>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
