import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FlaIconModule,
  FlaInputModule,
  FlaSelectModule,
  RocketButtonModule,
  RocketModalModule,
} from '@shared/rocket-components/components';
import { SearchStockModule } from '@shared/components/search-stock/search-stock.module';
import { RTInputCountModule } from '@shared/rocket-components/input-count/input-count.module';
import { TableTradeIdeaItemsModule } from '../table-trade-idea-items/table-trade-idea-items.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalTradeIdeaComponent } from './modal-trade-idea.component';
import { FlaDatepickerModule } from '@shared/rocket-components/components/datepicker';
import { TickStrategyModule } from '@core/layout/header/strategy/modal-strategies/tick-strategy/tick-strategy.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RocketModalModule,
    SearchStockModule,
    RTInputCountModule,
    TableTradeIdeaItemsModule,
    RocketButtonModule,
    FlaSelectModule,
    FlaDatepickerModule,
    FlaInputModule,
    TickStrategyModule,
    FlaIconModule,
  ],
  declarations: [ModalTradeIdeaComponent],
  exports: [ModalTradeIdeaComponent],
})
export class ModalTradeIdeaModule {}
