<cdk-virtual-scroll-viewport
  #scrollViewport
  class="w-100 h-100"
  [itemSize]="cardHeight"
  *ngIf="stockDict.size() > 0; else loadingTemplate"
>
  <div 
  *cdkVirtualFor="let item of stocksToDisplay; let i = index"
  [attr.tabindex]="i"
  class="px-2 py-1"
  >
    <ng-container *ngIf="item">
      <app-orders-history-order-card
        *ngIf="isOrder"
        [cdStock]="item.cd_stock"
        [side]="item.side"
        [price]="item.price"
        [qtty]="item.qtty"
        [dhCreationTime]="item.dh_creation_time"
        [dsOrderType]="item.ds_order_type"
        [dsOrderTimeEnforce]="item.ds_order_time_enforce"
        [cdOrderStatus]="item.cd_order_status"
        [dsOrderStatus]="item.ds_order_status"
        [lastPrice]="quoteDict[item.code]?.preco_ultimo"
        [variation]="quoteDict[item.code]?.variacao_dia"
        [variationColor]="quoteDict[item.code]?.arrow_hex"
        [tickSize]="quoteDict[item.code]?.tick_size_denominator"
        [hasDayTrade]="item.has_daytrade"
        [isSelected]="item.cd_stock === globalCdStock"
        [tabRef]="tabSelectedRef"
        [listContainerWidth]="width"
        (cancelOrderHandler)="cancelOrder(item)"
        (editOrderHandler)="editOrderHandler.emit(item)"
        (click)="changeStockHandler.emit(item)"
        (contextmenu)="onContextMenu($event, item)"
      ></app-orders-history-order-card>
      <app-orders-history-position-card
        *ngIf="isCustody"
        [cdStock]="item.cd_stock"
        [qttyFinal]="item[fields.qttyFinal]"
        [pl]="item[fields.pl]"
        [netDay]="item[fields.netDay]"
        [vlPriceAvg]="item[fields.vlPriceAvg]"
        [pnlOpen]="item[fields.pnlOpen]"
        [pnlDay]="item[fields.pnlDay]"
        [pnlTotal]="item[fields.pnlTotal]"
        [lastPrice]="quoteDict[item.code]?.preco_ultimo"
        [variation]="quoteDict[item.code]?.variacao_dia"
        [variationColor]="quoteDict[item.code]?.arrow_hex"
        [variationFontColor]="quoteDict[item.code]?.arrow_font_hex"
        [situation]="quoteDict[item.code]?.situacao"
        [tickSize]="quoteDict[item.code]?.tick_size_denominator"
        [listContainerWidth]="width"
        [chart]="vlCloseHistory.get(item.code) || []"
        [positionKey]="item.code"
        [isSelected]="item.cd_stock === globalCdStock"
        [cdInstrumentType]="item.cd_instrument_type"
        (resetPosition)="resetPosition(item)"
        (click)="changeStockHandler.emit(item)"
        (contextmenu)="onContextMenu($event, item)"
      ></app-orders-history-position-card>
      <app-orders-history-alert-card
        *ngIf="isAlert && alertDict[item.iosmaKey]"
        [cdStock]="item.cd_stock"
        [dsAsset]="item.ds_asset"
        [price]="item.vl_value"
        [lastPrice]="quoteDict[item.code]?.preco_ultimo"
        [variation]="quoteDict[item.code]?.variacao_dia"
        [variationColor]="quoteDict[item.code]?.arrow_hex"
        [tickSize]="quoteDict[item.code]?.tick_size_denominator"
        [candle]="alertDict[item.iosmaKey]"
        [isSelected]="item.cd_stock === globalCdStock"
        [listContainerWidth]="width"
        (click)="changeStockHandler.emit(item)"
        (editAlertHandler)="editAlertHandler.emit(item)"
        (deleteAlertHandler)="removeAllAlertsAndOrders.emit(item)"
        (contextmenu)="onContextMenu($event, item)"
      ></app-orders-history-alert-card>
    </ng-container>

  </div>
</cdk-virtual-scroll-viewport>

<ng-template #loadingTemplate>
  <div *ngIf="loading; else noDataTemplate" class="h-100 w-100 d-flex align-items-sm-center justify-content-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
<ng-template #noDataTemplate>
  <app-no-info
    text="Não há dados."
    css="w-100 d-flex align-items-center justify-content-center h-100"
  ></app-no-info>
</ng-template>
  