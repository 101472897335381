import { isIdBrokerConsolidated } from '@shared/constants/simulator-league.constant';

export class BrokerAccountModel {
  public key!: string;
  public label!: string;
  public id_broker!: number;
  public accounts!: string[];
  public account_number!: string;
  isConsolidated!: boolean;
  constructor(brokerAccount: any) {
    this.key = brokerAccount?.account_number ?? brokerAccount.id_broker;
    this.label = this.selectName(brokerAccount);
    this.id_broker = brokerAccount.id_broker;
    this.accounts = this.selectAccounts(brokerAccount);
    this.account_number = brokerAccount?.account_number;
    this.isConsolidated = isIdBrokerConsolidated(brokerAccount.id_broker);
  }

  private selectName(brokerAccount: any): string {
    if (brokerAccount.nm_broker_nickname) {
      return brokerAccount.nm_broker_nickname;
    }
    if (brokerAccount.account_name) {
      return brokerAccount.account_name;
    }
    return brokerAccount.nm_broker || brokerAccount.label;
  }

  private selectAccounts(brokerAccount: any) {
    if (brokerAccount?.accounts?.length) {
      return brokerAccount.accounts;
    }
    if (brokerAccount?.account_number) {
      return [brokerAccount?.account_number];
    }
    return [];
  }
}
