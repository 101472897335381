import { Component, Input } from '@angular/core';
@Component({
  selector: 'fla-input-prefix',
  templateUrl: './input-prefix.component.html',
  styles: [
    `
      :host(fla-input-prefix) {
        display: contents;
      }
    `,
  ],
})
export class FlaInputPrefixComponent {
  @Input() text!: string;
}
