import { DEFAULT_DAY_TRADE_LABEL, DEFAULT_SWING_LABEL } from "../constants/daytrade.constant"

export class DaytradeLabels {
  public dayTrade: string
  public swing: string

  constructor(dayTrade?: string | undefined, swing?: string | undefined) {
    this.dayTrade = dayTrade ?? DEFAULT_DAY_TRADE_LABEL
    this.swing = swing ?? DEFAULT_SWING_LABEL
  }
}
