import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TIGER_TYPE_CHART_ENUM } from '@shared/tiger-chart/enum';

@Component({
  selector: 'chart-types',
  template: `
    <li *ngFor="let item of options">
      <a
        class="dropdown-item px-2 hstack"
        type="button"
        [ngClass]="{
          'text-brand-primary': item.id === currentOption
        }"
        (click)="onSelectOption.emit(item.id)"
      >
        <i class="icons icon-size-micro mr-2"> {{ item.icon }} </i>
        {{ item.name }}
      </a>
    </li>
  `,
})
export class ChartTypesComponent {
  @Input() options: any[] = [];
  @Input() currentOption!: TIGER_TYPE_CHART_ENUM | undefined;
  @Output() onSelectOption: EventEmitter<TIGER_TYPE_CHART_ENUM> =
    new EventEmitter<TIGER_TYPE_CHART_ENUM>();

  constructor() {}
}
