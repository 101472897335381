import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';
import { OptionsComponents } from '../component-list';

export const TIMES_TRADES_COMPONENT: any = (options?: OptionsComponents) => {
  return {
    name: 'Negócios',
    id: COMPONENTS_NAMES_ENUM.TIMES_TRADES,
    icon: 'price_change',
    cod: 'TIMES_TRADES',
    data: null,
    close: true,
    fullscreen: false,
    link: true,
    widthDesk: 268,
    heightDesk: 375,
    unitOfMeasurement: '%',
    width: options?.width ?? 15,
    height: options?.height ?? 50,
    left: options?.left ?? 'calc(55% + 10px)',
    top: options?.top ?? 'calc(50% + 5px)',
    minWidth: '215px',
    minHeight: '200px',
    iconSvg: '',
  };
};
