import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlaLinksComponent } from './links.component';

@NgModule({
  declarations: [FlaLinksComponent],
  imports: [CommonModule],
  exports: [FlaLinksComponent],
})
export class FlaLinksModule {}
