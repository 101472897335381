<div class="d-flex buttons-wrapper">
  <app-context-menu
    *ngIf="isDisplayContextMenu"
    [show]="isDisplayContextMenu"
    [options]="contextMenuOptions"
    (changes)="handleMenuItemClick($event)"
    (menuClose)="onCloseContextMenu()"
  ></app-context-menu>
  <fla-workspace-doc-header-item
    *ngIf="showSelectWindow"
    (flaClick)="selectWindow()"
    [icon]="windowSelect.icon"
    [iconColor]="windowSelect.color"
    idElement="TOGGLE_FULLSCREEN"
  ></fla-workspace-doc-header-item>
  <fla-workspace-doc-header-item
    *ngIf="headerOptions.fullscreen"
    (flaClick)="maximize()"
    [icon]="maximized.icon"
    [iconColor]="maximized.color"
    idElement="TOGGLE_FULLSCREEN"
  ></fla-workspace-doc-header-item>
  <fla-workspace-doc-header-item
    [idElement]="'LINK'"
    [icon]="linked.icon"
    [iconColor]="linked.color"
    [active]="headerOptions.link!"
    (flaClick)="link(workspaceDoc.id)"
  ></fla-workspace-doc-header-item>
  <fla-workspace-doc-header-item
    idElement="CLOSE_COMPONENT"
    [hideBorder]="true"
    icon="close"
    (flaClick)="remove(workspaceDoc.id)"
  >
  </fla-workspace-doc-header-item>
</div>
