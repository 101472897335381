<ng-template>
  <div
    class="tab-pane fade"
    [ngClass]="active ? customClass + ' show active' : customClass"
    [id]="refId"
    role="tabpanel"
    [attr.aria-labelledby]="refId + '-tab'"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
