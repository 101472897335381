import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { BaseComponent } from '@shared/rocket-components/base-componentes';
import { PhoneInputConfigService } from './service/phone-input-config.service';
import { MESSAGES, randomId } from '@shared/rocket-components/utils';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Iti, SomeOptions } from 'intl-tel-input';
import { Subscription } from 'rxjs';

export interface IInternationalNumber {
  code: string;
  country: string;
}

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styles: [
    `
      :host(app-phone-input) {
        display: contents;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PhoneInputComponent),
    },
  ],
})
export class PhoneInputComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy, OnChanges
{
  @ViewChild('telInput') telInput!: ElementRef<HTMLInputElement>;
  @Input() value = '';
  @Input() css = '';
  @Input() options: SomeOptions = {};
  @Input() label!: string;
  @Input() isRequired: boolean = false;
  @Input() requiredText: string = MESSAGES['requiredText'];
  @Input() hasError: string = '';
  @Input() autoComplete: boolean = false;
  @Input() initialCountry: string = 'br';
  @Input() maxLength: number = 15;
  @Output() countryHasChanged = new EventEmitter<IInternationalNumber>();
  public isInvalid = false;
  private iti!: Iti;
  private inputChanged!: Subscription;
  public refId: string = randomId('phone_input');

  constructor(
    public service: PhoneInputConfigService,
    injector: Injector,
    cdr: ChangeDetectorRef,
    private _element: ElementRef
  ) {
    super(injector, cdr);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.setSubscriptions();
    this.startIti();
  }

  ngOnChanges = (changes: SimpleChanges) => {
    const { initialCountry } = changes;
    if (initialCountry?.currentValue) {
      this.setInitialCountry(initialCountry.currentValue);
    }
  };

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.iti.destroy();
    this.inputChanged?.unsubscribe();
    this.telInput.nativeElement.removeEventListener(
      'countrychange',
      this.inputReset
    );
  }

  public setInitialCountry = (country: string): void => {
    if (this.iti) this.iti?.setCountry(country);
  };

  private startIti() {
    this.options.initialCountry = this.initialCountry;
    this.iti = this.service.getConfig(
      this._element.nativeElement,
      this.telInput.nativeElement,
      this.options
    );
  }

  private setSubscriptions = () => {
    this.telInput.nativeElement.addEventListener(
      'countrychange',
      this.inputReset
    );
  };

  private inputReset = () => {
    this.iti.setNumber('');
    this.formControl.patchValue('');
    const { dialCode, iso2 } = this.iti.getSelectedCountryData();
    this.countryHasChanged.emit({ country: iso2!, code: dialCode! });
  };
}
