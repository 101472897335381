import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '@shared/services/rest';
import { catchError, map, Observable, of } from 'rxjs';
import { ToastService } from '@shared/services/toast.service';
import { IBrokersToConnect } from './models/brokers-to-connect.interface';
import { IBrokerConnectionInfos } from './models/brokers-connection-infos.interface';
import { IBrokerServiceResponse } from './models/rocket-weak-brokers-response.interface';

@Injectable({
  providedIn: 'root',
})
export class TrademapV9Service extends RestService {
  override _url: string = 'api/trademap/v9/rocket-weak';
  private _brokersToConnect: IBrokersToConnect[] = [];
  private _brokerInfos: { [idBroker: string]: IBrokerConnectionInfos } = {};

  constructor(private http: HttpClient, private _toast: ToastService) {
    super(http);
  }

  public nitroSevenDaysContract(): Observable<boolean> {
    return this.post('seven-days-contract', {}).pipe(
      catchError((err) => of(this.handlerServiceError(err))),
      map((response) => {
        if (
          !response ||
          !response?.data ||
          !response.data.success ||
          !response.data.result
        )
          throw response;
        return true;
      })
    );
  }

  public getBrokersToConnect(
    updateBrokersAfterConnection: boolean = false
  ): Observable<IBrokersToConnect[]> {
    if (!updateBrokersAfterConnection && this._brokersToConnect.length)
      return of(this._brokersToConnect);
    return this.get<IBrokerServiceResponse<any>>('get-brokers-to-connect').pipe(
      map((res) => {
        this._brokersToConnect = res.data?.result || [];
        return res.data?.result || [];
      }),
      catchError(() => {
        this._toast.showToast(
          'error',
          'Ocorreu um erro ao buscar as corretoras disponíveis, tente novamente.'
        );
        return of([]);
      })
    );
  }

  public getBrokerConnectionInfos(
    idBroker: number
  ): Observable<IBrokerConnectionInfos> {
    if (this._brokerInfos[idBroker]) return of(this._brokerInfos[idBroker]);
    return this.post<IBrokerServiceResponse<IBrokerConnectionInfos>>(
      'get-broker-connection-details',
      { idBroker }
    ).pipe(
      map((res) => {
        this._brokerInfos[idBroker] = res.data.result;
        return res.data.result;
      }),
      catchError(() => {
        this._toast.showToast(
          'error',
          'Ocorreu um erro ao buscar as informações de conexão da corretora, tente novamente.'
        );
        return of();
      })
    );
  }
}
