<div class="tiger-chart-tooltip position-absolute">
  <div *ngIf="!isSubChart" class="h-100 d-flex gap-2">
    <button
      [tooltip]="(isVisible ? 'Ocultar ' : 'Exibir ') + 'legenda'"
      tooltipPosition="bottom"
      [tooltipIsAdaptive]="false"
      class="btn btn-icon btn-sm p-0"
      (click)="toggleHide()"
    >
      <img
        [src]="isVisible ? 'assets/svg/minus.svg' : 'assets/svg/plus.svg'"
        alt="Mostrar/esconder indicadores"
      />
    </button>
    <div [innerHTML]="template"></div>
  </div>
  <div class="h-100" *ngIf="isSubChart || isVisibleTooltipIndicators">
    <app-tiger-chart-tooltip-indicator
      *ngFor="let indicator of indicators"
      [id]="tooltipRef"
      [indicator]="indicator"
      [graphContainer]="graphContainer"
      [tickSize]="tickSize"
      [mouseIndex]="mouseIndex"
      (indicatorSettings)="indicatorSettings($event)"
      (indicatorDelete)="deleteIndicator($event)"
      (indicatorVisibilityChange)="indicatorVisibilityChange(indicator)"
    ></app-tiger-chart-tooltip-indicator>
  </div>
  <div
    *ngIf="indicators.length > 1"
    class="d-flex gap-1 position-absolute mt-1"
  >
    <div
      [tooltip]="
        (isVisibleTooltipIndicators ? 'Ocultar ' : 'Exibir ') +
        'legenda dos Indicadores'
      "
      tooltipPosition="bottom"
      [tooltipIsAdaptive]="false"
    >
      <rocket-button
        type="default"
        (rocketClick)="hideTooltipIndicators()"
        [isDisabled]="false"
        [label]="isVisibleTooltipIndicators ? '' : indicators.length.toString()"
        css="d-flex hideIndicators"
        [ngClass]="{ sizeSpan: !isVisibleTooltipIndicators }"
      >
        <fla-icon
          *ngIf="!isVisibleTooltipIndicators"
          name="expand_more"
          size="icon-size-micro"
        ></fla-icon>
        <fla-icon
          *ngIf="isVisibleTooltipIndicators"
          name="expand_less"
          size="icon-size-micro"
        ></fla-icon>
      </rocket-button>
    </div>
    <div
      *ngIf="isVisibleTooltipIndicators"
      [tooltip]="
        (isVisibleIndicators ? 'Ocultar ' : 'Exibir ') + 'todos Indicadores'
      "
      tooltipPosition="bottom"
      [tooltipIsAdaptive]="false"
    >
      <rocket-button
        type="default"
        (rocketClick)="hideAllIndicators()"
        [isDisabled]="false"
        [label]="''"
        css="d-flex hideIndicators"
      >
        <fla-icon
          *ngIf="!isVisibleIndicators"
          name="visibility"
          size="icon-size-micro"
        ></fla-icon>
        <fla-icon
          *ngIf="isVisibleIndicators"
          name="visibility_off"
          size="icon-size-micro"
        ></fla-icon>
      </rocket-button>
    </div>
    <div
      *ngIf="isVisibleTooltipIndicators"
      tooltip="Remover todos Indicadores"
      tooltipPosition="bottom"
      [tooltipIsAdaptive]="false"
    >
      <rocket-button
        type="default"
        (rocketClick)="removeAllIndicators()"
        [isDisabled]="false"
        [label]="''"
        css="d-flex hideIndicators"
      >
        <fla-icon name="clear" size="icon-size-micro"></fla-icon>
      </rocket-button>
    </div>
  </div>
</div>
