<ng-container [ngSwitch]="typeTag">
  <ol *ngSwitchCase="'ol'" class="list-group" [ngClass]="customClass">
    <ng-container *ngTemplateOutlet="li"></ng-container>
  </ol>
  <ul *ngSwitchCase="'ul'" class="list-group" [ngClass]="customClass">
    <ng-container *ngTemplateOutlet="li"></ng-container>
  </ul>
  <div *ngSwitchDefault class="list-group" [ngClass]="customClass">
    <ng-content></ng-content>
  </div>
</ng-container>

<ng-template #li>
  <ng-content select="li"></ng-content>
</ng-template>
