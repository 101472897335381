import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StockTableViewsComponent } from './stock-table-views.component';
import {
  FlaButtonCloseModule,
  FlaIconModule,
  FlaTagModule,
} from '@shared/rocket-components/components';
import { StockTableViewsContextComponent } from './components/stock-table-view-context/stock-table-view-context.component';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { HighchartModule } from '@shared/highchart/highchart.module';
import { StockTAbleViewCardAndChartComponent } from './components/stock-table-view-card-and-chart/stock-table-view-card-and-chart.component';
import { StockTableViewCandleHorizontalComponent } from './components/stock-table-view-candle-horizontal/stock-table-view-candle-horizontal.component';
import { RemoveStockButtonComponent } from './components/remove-stock-button/remove-stock-button.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';
import { StockTableViewMinicardComponent } from './components/stock-table-view-minicard/stock-table-view-minicard.component';
import { StatusStockComponent } from './components/status-stock/status-stock.component';
import { AuctionTooltipDirective } from '@shared/rocket-components/directives/auction-tooltip/auction-tooltip.directive';
import { StockLogoComponent } from './components/stock-logo/stock-logo.component';
import { keyboardControlsDirective } from './directive/keyboard-controls.directive';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RTInputCountModule } from '@shared/rocket-components/input-count/input-count.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CandleHorizontalComponent } from '@shared/highchart/candle-horizontal/candle-horizontal.component';

@NgModule({
  imports: [
    CommonModule,
    FlaTagModule,
    FlaIconModule,
    FlaButtonCloseModule,
    HighchartModule,
    TooltipsModule,
    DragDropModule,
    RTDropdownModule,
    AuctionTooltipDirective,
    StockLogoComponent,
    ScrollingModule,
    RTInputCountModule,
    ReactiveFormsModule,
    TooltipsModule,
    CandleHorizontalComponent,
  ],
  declarations: [
    StockTableViewsComponent,
    StockTableViewsContextComponent,
    StockTableViewMinicardComponent,
    StockTAbleViewCardAndChartComponent,
    StockTableViewCandleHorizontalComponent,
    RemoveStockButtonComponent,
    keyboardControlsDirective,
    StatusStockComponent,
  ],
  exports: [
    StockTableViewsComponent,
    StockTAbleViewCardAndChartComponent,
    StatusStockComponent,
  ],
})
export class StockTableViewsModule {}
