<div
  #workspaceContext
  class="round bg-neutral-strong position-absolute"
  *ngIf="show && options"
  [style.top.px]="topPosition"
  [style.left.px]="leftPosition"
  [style.z-index]="9999"
>
  <div class="context-container list-group list-group-flush">
    <div
      *ngIf="options.type === 'TOOLS'; else titleElse"
      class="d-flex tools-icon-wrapper"
    >
      <div
        class="w-50 prevent-close d-flex justify-content-center tools-icon py-2"
      >
        <button
          type="button"
          class="bg-transparent prevent-close border-none"
          tooltip="Componentes"
          tooltipPosition="bottom"
          (click)="selectedComponentsMenu = componentsId"
        >
          <fla-icon
            size="icon-size-tiny"
            css="prevent-close  fs-4"
            [ngClass]="
              selectedComponentsMenu === componentsId
                ? 'text-white'
                : 'text-neutral-medium'
            "
            name="extension"
          ></fla-icon>
        </button>
      </div>
      <div class="w-50 prevent-close d-flex justify-content-center py-2">
        <button
          type="button"
          class="bg-transparent prevent-close border-none"
          tooltip="Ferramentas"
          tooltipPosition="bottom"
          (click)="selectedComponentsMenu = toolsId"
        >
          <fla-icon
            size="icon-size-tiny"
            css="prevent-close fs-4"
            [ngClass]="
              selectedComponentsMenu === toolsId
                ? 'text-white'
                : 'text-neutral-medium'
            "
            name="build"
            [flip]="true"
          ></fla-icon>
        </button>
      </div>
    </div>
    <ng-template #titleElse>
      <div *ngIf="options.title" class="header-context-menu">
        <span>{{ options.title }}</span>
      </div>
    </ng-template>

    <ng-container [ngSwitch]="options.type">
      <ng-container *ngSwitchCase="'WORKSPACE_TAB'">
        <section class="round">
          <button
            *ngFor="let tab of menuTab"
            type="button"
            [disabled]="tab.isDisabled"
            class="list-group-item list-group-item-action context-item-button"
            (click)="runCommand(tab)"
          >
            <fla-icon
              size="icon-size-tiny"
              css="position-absolute left-0 text-neutral-medium fs-4"
              [name]="tab.icon"
            ></fla-icon>
            <span> {{ tab.label }}</span>
          </button>
        </section>
      </ng-container>

      <ng-container *ngSwitchCase="'TOOLS'">
        <ng-container
          *ngIf="selectedComponentsMenu === componentsId; else toolsTemplate"
        >
          <section *ngFor="let key of menuTool.keys()" class="round-bottom">
            <button
              *ngFor="let item of menuTool.get(key)"
              class="list-group-item list-group-item-action border-none fs-6 d-flex align-items-center"
              type="button"
              (click)="addComponent(item)"
            >
              <div
                class="w-100 d-inline-flex align-items-center justify-content-between"
              >
                <div class="gap-2 d-inline-flex align-items-center">
                  <ng-template
                    *ngTemplateOutlet="componentIcon; context: { data: item }"
                  ></ng-template>
                  <span> {{ item.name }} </span>
                </div>
                <ng-container *ngIf="componentsQty[item.id]">
                  <app-components-count
                    [qty]="componentsQty[item.id].qty"
                  ></app-components-count>
                </ng-container>
              </div>
            </button>
          </section>
        </ng-container>

        <ng-template #toolsTemplate>
          <section #toolsTemplate class="round-bottom">
            <button
              *ngFor="let item of menuTool.values()"
              class="list-group-item list-group-item-action fs-6 d-flex align-items-center"
              [ngClass]="item.borderClass ? 'tools-menu-border' : 'border-none'"
              type="button"
              (click)="addComponent(item)"
            >
              <div
                class="w-100 d-inline-flex align-items-center justify-content-between"
              >
                <div class="gap-2 d-inline-flex align-items-center">
                  <ng-template
                    *ngTemplateOutlet="componentIcon; context: { data: item }"
                  ></ng-template>
                  <span>{{ item.name }}</span>
                </div>
                <ng-container *ngIf="componentsQty[item.id]">
                  <app-components-count
                    [qty]="componentsQty[item.id].qty"
                  ></app-components-count>
                </ng-container>
              </div>
            </button>
          </section>
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <button
          type="button"
          class="list-group-item list-group-item-action border-none fs-6"
          (click)="emitEventDuplicate($event)"
        >
          Duplicar
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #componentIcon let-data="data">
  <ng-container *ngIf="data.icon; else customIcon">
    <fla-icon
      size="icon-size-micro"
      [name]="data.icon"
      [css]="data.iconColor ? data.iconColor : 'text-white'"
      [iconType]="data.iconType ? data.iconType : 'icons'"
    >
    </fla-icon>
  </ng-container>
  <ng-template #customIcon>
    <app-rocket-icons
      [name]="data.iconSvg"
      [color]="data.iconColor ? data.iconColor : 'var(--vm-light)'"
    ></app-rocket-icons>
  </ng-template>
</ng-template>
