import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtPriceComponent } from './at-price.component';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingModule } from '../loading/loading.module';
import {
  FlaIconModule,
  RocketButtonModule,
} from '@shared/rocket-components/components';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { AtPriceHeaderComponent } from './header/at-price-header.component';
import { AtPriceService } from './services/at-price.service';
import { SearchStockModule } from '../search-stock/search-stock.module';
import { DetailsTabModule } from '../details-tab/details-tab.module';
import { GraphicAtPriceComponent } from './graphic-at-price/graphic-at-price.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AtPriceContextMenuComponent } from './parts/at-price-context-menu.component';
import { FormatTickSizeModule } from '@shared/rocket-components/pipes/format-tick-size';

@NgModule({
  imports: [
    CommonModule,
    RTDropdownModule,
    FlaWorkSpaceModule,
    FormsModule,
    FormatTickSizeModule,
    ReactiveFormsModule,
    LoadingModule,
    RocketButtonModule,
    FlaIconModule,
    TooltipsModule,
    SearchStockModule,
    DetailsTabModule,
    ScrollingModule,
  ],
  declarations: [
    AtPriceComponent,
    AtPriceHeaderComponent,
    GraphicAtPriceComponent,
    AtPriceContextMenuComponent,
  ],
  exports: [AtPriceComponent],
  providers: [AtPriceService],
})
export class AtPriceModule {}
