import { ENumericFormat } from 'scichart/types/NumericFormat';
import { CHART_COLORS } from '../colors';
import {
  TIGER_INDICATOR_SOURCES,
  TIGER_INTERVAL_ENUM,
  TIGER_TYPE_CHART_ENUM,
} from '../enum/tiger-chart.enum';
import { ICandleConfigs } from '../interface/tiger-chart.interface';
import { IActiveStrategy } from '@shared/services/core/custom-preferences/strategy';
import { ACTIVE_STRATEGY_TYPE } from '@core/layout/header/strategy/enum/strategy.enum';
import { COMPONENTS_LIST } from '@core/workspace';

const DEFAULT_STRATEGY = (): IActiveStrategy => {
  return {
    idStrategy: -1,
    name: '',
    type: ACTIVE_STRATEGY_TYPE.TICK,
    items: [],
  };
};

export const DEFAULT_CONFIGS = (): ICandleConfigs => {
  return {
    showEvents: true,
    showExecOrders: true,
    showFlagOrders: true,
    showOrderMarker: true,
    showOpenFlag: true,
    withTools: true,
    enableZoom: true,
    enableCursorModifier: false,
    enableKeyPressActions: true,
    enableRightClickActions: true,
    enableContextMenu: true,
    showFastOrderDaytradeStrategy: false,
    showBreakEven: false,
    defaultCursor: 'Cursores_CRUZ',
    fastOrder: {
      showFastOrder: false,
      isSelectedStrategy: false,
      strategy: DEFAULT_STRATEGY(),
    },
    width: COMPONENTS_LIST.get('FERRAMENTAS')[0].width,
    height: COMPONENTS_LIST.get('FERRAMENTAS')[0].height - 50,
    negotiationLot: undefined,
    yAxis: {
      axisGrowBy: 0.25,
      labelFormat: ENumericFormat.Decimal,
      labelPrecision: 2,
    },

    xAxis: {
      axisGrowBy: 0.25,
      labelFormat: ENumericFormat.Date_HHMM,
      visibleRangeLimit: {
        min: -50,
        max: 100000,
      },
      visibleRange: {
        min: 0,
        max: 1000,
      },
    },

    series: {
      interval: TIGER_INTERVAL_ENUM.FIVE_MINUTE,
      type: TIGER_TYPE_CHART_ENUM.CANDLE,
      bar: {
        showHLC: true,
        strokeThickness: 1,
        colorsUp: CHART_COLORS.FEEDBACK_POSITIVE,
        colorsDown: CHART_COLORS.FEEDBACK_NEGATIVE,
        dataPointWidth: 0.4,
      },
      candle: {
        colorsVelaDown: CHART_COLORS.FEEDBACK_NEGATIVE,
        colorsVelaUp: CHART_COLORS.FEEDBACK_POSITIVE,
        colorsBorderDown: CHART_COLORS.FEEDBACK_NEGATIVE,
        colorsBorderUp: CHART_COLORS.FEEDBACK_POSITIVE,
        strokeThickness: 1,
        dataPointWidth: 0.7,
        showBorder: true,
      },
      line: {
        type: '',
        color: CHART_COLORS.FEEDBACK_POSITIVE,
        strokeThickness: 2,
        sortBy: TIGER_INDICATOR_SOURCES.CLOSE,
      },
      area: {
        lineColor: CHART_COLORS.FEEDBACK_POSITIVE,
        grandientHexColor: CHART_COLORS.FEEDBACK_POSITIVE,
        grandientOpacityUp: 0.5,
        grandientOpacityDown: 0,
        strokeThickness: 2,
        sortBy: TIGER_INDICATOR_SOURCES.CLOSE,
      },
    },

    closeLine: {
      enable: true,
      showLabel: true,
      strokeThickness: 1,
      color: {
        up: CHART_COLORS.FEEDBACK_POSITIVE,
        down: CHART_COLORS.FEEDBACK_NEGATIVE,
      },
    },
  };
};
