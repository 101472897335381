import {
  TigerChartIndicatorPercentagePriceOscillatorOptions,
  TTigerChartIndicatorCreateOptions,
} from './indicators.types';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { PercentagePriceOscillatorBase } from './base/percentage-price-oscillator-base';

export class TigerChartIndicatorPercentagePriceOscillator extends PercentagePriceOscillatorBase {
  constructor(options: TigerChartIndicatorPercentagePriceOscillatorOptions) {
    super(options);
  }

  override create(
    options: TTigerChartIndicatorCreateOptions
  ): BaseRenderableSeries[] {
    this.updatePoints();
    return super.create(options);
  }

  override updatePoints(): void {
    const offset: number[] = Array(this.slowPeriod).fill(
      this.data[this.source][0]
    );
    this.points = this.service.call('PPO', {
      inReal: [...offset, ...this.data[this.source]],
      fastPeriod: this.options.fastPeriod,
      slowPeriod: this.options.slowPeriod,
    });
  }

  override updateSettings() {
    const offset: number[] = Array(this.slowPeriod).fill(
      this.data[this.source][0]
    );
    this.points = this.service.call('PPO', {
      inReal: [...offset, ...this.data[this.source]],
      fastPeriod: this.options.fastPeriod,
      slowPeriod: this.options.slowPeriod,
    });
    super.updateSettings();
  }
}