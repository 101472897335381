import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  computed,
  signal,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ISearchStock } from '@core/interface';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { OptionsService } from '@shared/services/api/trademap/v1/options.service';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { DEFAULT_THEME, LIGHT_THEME } from '@shared/tiger-chart/colors';
import { Subject, takeUntil } from 'rxjs';
import { SciChartSurface } from 'scichart';
import { randomId } from 'src/app/utils/utils.framework';
import { TStrategyOptionExpirationDate } from '../../types';

@Component({
  selector: 'app-smile-volatility',
  templateUrl: './options-smile-volatility.component.html',
  styleUrls: ['./options-smile-volatility.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsSmileVolatilityComponent implements OnInit, OnChanges {
  @Output() enableChangeStock = new EventEmitter<void>();
  @Input() stock!: ISearchStock | undefined;
  @Input() refId!: string;
  @Input() height!: string;
  @Input() expirationDates!: TStrategyOptionExpirationDate[];

  smileRefId: string = randomId('options-smile');
  historyRefId: string = randomId('options-history');
  smileReady = false;
  historyReady = false;
  theme!: any;
  smileData = [];
  historyData = [];
  form!: FormGroup;
  loading = true;
  loadingSmile = true;
  loadingHistory = true;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _themeService: ThemePreferencesService,
    private _formBuilder: FormBuilder,
    private _optionsService: OptionsService,
    private _stockChartService: StockChartService,
    private cdr: ChangeDetectorRef
  ) {
    this.form = this._formBuilder.group({
      date: new FormControl(''),
    });
    this.theme = this.getTheme(this.isDarkTheme());
  }

  ngOnInit() {
    SciChartSurface.useWasmFromCDN();
    this._themeService
      .themeActiveObservable()
      .subscribe(this.changeThemeHandler);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { expirationDates } = changes;

    if (expirationDates && expirationDates.currentValue) {
      if (this.expirationDates.length <= 0) {
        this.smileData = [];
        this.historyData = [];
        this.loadingHistory = false;
        this.loadingSmile = false;
        this.enableChangeStock.emit();
        this.cdr.detectChanges();
        return;
      }
      this.loadingHistory = true;
      this.loadingSmile = true;
      this.cdr.detectChanges();
      this.form.get('date')?.setValue(this.expirationDates[0]?.id || null);
      this.loadSmileData();
      this.loadHistoryData();
    }
  }

  getTheme = (isDark: boolean) => (isDark ? DEFAULT_THEME() : LIGHT_THEME());

  changeThemeHandler = (data: any) => {
    this.theme = this.getTheme(data === 'dark-theme');
    this.isDarkThemeSignal.set(this._themeService.isDarkTheme());
    this.cdr.detectChanges();
  };

  isDarkTheme = computed(() => {
    return this.isDarkThemeSignal();
  });

  isDarkThemeSignal = signal(true);

  onSmileChartRead() {
    this.historyReady = true;
    this.cdr.detectChanges();
  }

  onHistoryChartRead() {
    this.historyReady = true;
    this.cdr.detectChanges();
  }

  loadSmileData() {
    const dt_expiration_option = this.form.get('date')?.value;
    if (!this.stock || !dt_expiration_option) return;
    const { cd_stock, id_exchange } = this.stock;
    this._getSmileData(dt_expiration_option, id_exchange, cd_stock);
  }

  loadHistoryData() {
    const dt_expiration_option = this.form.get('date')?.value;
    if (!this.stock || !dt_expiration_option) return;
    const { cd_stock, id_exchange } = this.stock;
    this._getHistoryData(dt_expiration_option, id_exchange, cd_stock);
  }

  private _getSmileData(
    dt_expiration_option: number,
    id_exchange: number,
    cd_stock: string
  ) {
    this._optionsService
      .getSmileAndVolatility(dt_expiration_option, id_exchange, cd_stock)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.smileData = response.candles;
        this._stockChartService.removeChartLoading$.next({
          remove: true,
          ref: this.smileRefId,
        });
        this.loadingSmile = false;
        this.cdr.detectChanges();
      });
  }
  private _getHistoryData(
    dt_expiration_option: number,
    id_exchange: number,
    cd_stock: string
  ) {
    this._optionsService
      .getVolatilityHistory(dt_expiration_option, id_exchange, cd_stock)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.historyData = response;
        this._stockChartService.removeChartLoading$.next({
          remove: true,
          ref: this.historyRefId,
        });
        this.enableChangeStock.emit();
        this.loadingHistory = false;
        this.cdr.detectChanges();
      });
  }
}
