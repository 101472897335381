<rocket-modal>
  <rocket-modal-header [hideCloseButton]="true">
    <div class="w-100 d-inline-flex align-items-center justify-content-between">
      <h3 class="modal-title">{{ title }}</h3>
      <button
        type="button"
        class="btn btn-outline-primary btn-sm"
        [ngClass]="{ 'disable-click opacity-medium': leaving }"
        (click)="logout()"
      >
        <i class="icons">logout</i>
        Sair
      </button>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div>
      <span class="d-block pb-3">
        Para continuar com acesso ao RocketTrader você pode escolher as opções
        abaixo:
      </span>

      <ul class="p-0 pl-4">
        <li>
          <span class="fw-bold">TradeMap PRO</span>: além do Rocket tenha acesso
          a outras ferramentas
        </li>
        <li>
          <span class="fw-bold">Conexão com parceiros</span>: conecte-se a um
          parceiro a garanta acesso gratuito ao RocketTrader
        </li>
      </ul>
    </div>
    <div class="mb-6">
      <app-benefit-card
        [brokers]="brokers"
        [displayPaymentWarning]="true"
        (handleButtonClicked)="handleBenefit($event)"
      >
      </app-benefit-card>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
