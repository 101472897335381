import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { DaytradeSelectorComponent } from './daytrade-selector.component';

@NgModule({
  declarations: [DaytradeSelectorComponent],
  imports: [CommonModule, TooltipsModule],
  exports: [DaytradeSelectorComponent],
  providers: [],
})
export class DaytradeSelectorModule {}
