import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ISearchStock } from '@core/interface';
import { ConsensusService } from '@shared/services/api/trademap/V2/consensus.service';
import {
  ConsensusStock,
  RecommendationsBars,
} from '../business-profile.interface';
import {
  CONSENSUS_RECOMMENDATION,
  CONSENSUS_TARGET_PRICE,
} from '../business-profile.const';
import { formatByTick } from 'src/app/utils/utils.functions';
import { HomeService } from '@modules/home/service/home.service';
import {
  BOLETA_COMPRA_BASECOMPONENT,
  BOLETA_VENDA_BASECOMPONENT,
} from '@core/workspace';
import { Subject, auditTime, takeUntil } from 'rxjs';

@Component({
  selector: 'app-consensus',
  templateUrl: './consensus.component.html',
  styleUrls: ['./consensus.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsensusComponent implements OnInit, OnDestroy {
  @Input() set stockSelected(stockSelected: ISearchStock) {
    if (stockSelected) {
      this.vlClose = 0;
      this.stock = stockSelected;
      this.currency = this.stock.cd_country === 'BR' ? 'R$' : '$';
      this.getConsensus();
    }
  }
  @Input() set priceClosing(priceClosing: string) {
    if (priceClosing && !this.vlClose) {
      const price = parseFloat(priceClosing);
      this.vlClose = price;
      if (this.recommendationsTargetPrices.length) {
        this.processRecommendations();
        this.processTargetPrices();
      }
    }
  }
  stock!: ISearchStock;
  stockConsensus!: ConsensusStock;
  currency!: string;
  recommendations: any = CONSENSUS_RECOMMENDATION;
  recommendationsBars: RecommendationsBars[] = [];
  recommendationsTargetPrices: RecommendationsBars[] = [];
  selectedRecommendaton: any = CONSENSUS_RECOMMENDATION.Default;
  protected princi = 'dos principais bancos e corretoras do mundo.';
  qttyRecomendations = '';
  qttyTargetPrices = '';
  vlClose = 0;
  widthClose = 0;
  noData = false;
  usePercentage = false;
  private consensusTargetPrices = CONSENSUS_TARGET_PRICE;
  private onDestroy = new Subject<void>();
  private onDetectChanges = new Subject<void>();

  constructor(
    private consensusService: ConsensusService,
    private _homeService: HomeService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initObservables();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private initObservables(): void {
    this.onDetectChanges
      .pipe(takeUntil(this.onDestroy), auditTime(200))
      .subscribe(() => this.cdr.detectChanges());
  }

  private getConsensus() {
    this.consensusService
      .getConsensus([this.stock.cd_stock])
      .then((res: any) => {
        this.recommendationsBars = [];
        this.recommendationsTargetPrices = [];
        const consensus = res.data.data;
        this.noData = false;
        if (consensus.length) {
          this.stockConsensus = consensus[0];
          const recommendation =
            this.recommendations[this.stockConsensus.final_recommendation];
          this.selectedRecommendaton = this.recommendations.Default;
          if (recommendation) {
            this.selectedRecommendaton = recommendation;
          }
          const pluralRecomendations =
            this.stockConsensus.qt_total > 1 ? 'ões' : 'ão';
          const pluralTargetPrice =
            this.stockConsensus.qt_total_target_price > 1 ? 's' : '';
          this.qttyRecomendations = `${this.stockConsensus.qt_total} Recomendaç${pluralRecomendations}`;
          this.qttyTargetPrices = `${this.stockConsensus.qt_total_target_price} Preço${pluralTargetPrice}-alvo`;
          this.processRecommendations();
          this.processTargetPrices();
        } else {
          this.noData = true;
        }
        this.onDetectChanges.next();
      });
  }

  private processTargetPrices() {
    if (!this.stockConsensus) return;
    const vlConsensus = [
      this.stockConsensus.vl_high,
      this.stockConsensus.vl_median,
      this.stockConsensus.vl_mean,
      this.stockConsensus.vl_low,
    ];
    const stockConsensusAny = this.stockConsensus as any;
    const max = Math.max(...vlConsensus, this.vlClose);
    this.widthClose = (this.vlClose / max) * 100;
    this.recommendationsTargetPrices.length &&
      (this.recommendationsTargetPrices = []);
    this.consensusTargetPrices.values().forEach((consenso) => {
      const value = stockConsensusAny[consenso.field];
      const isLower = value < this.vlClose;
      const clas = isLower ? 'negative-price' : 'positive-price';
      const width = (value / max) * 100;
      const percentage = (value / this.vlClose - 1) * 100;
      this.recommendationsTargetPrices.push({
        quantity: this.usePercentage
          ? formatByTick(percentage.toString()) + '%'
          : this.currency + ' ' + formatByTick(value.toString()),
        width: width + '%',
        secondBarWidth: this.widthClose + '%',
        secondBarClass: isLower ? clas : 'neutral-price',
        text: consenso.text,
        class: isLower ? 'neutral-price' : clas,
        fontColor: 'font-white',
        zIndex: isLower ? 1 : 0,
      });
    });
    this.onDetectChanges.next();
  }

  private processRecommendations() {
    if (!this.stockConsensus) return;
    const max = Math.max(
      this.stockConsensus.qt_buy,
      this.stockConsensus.qt_maintain,
      this.stockConsensus.qt_sell
    );
    this.recommendationsBars = [
      {
        quantity: this.stockConsensus.qt_buy,
        width: (this.stockConsensus.qt_buy / max) * 100 + '%',
        text: 'Comprar',
        class: 'green',
        fontColor: 'font-white',
      },
      {
        quantity: this.stockConsensus.qt_maintain,
        width: (this.stockConsensus.qt_maintain / max) * 100 + '%',
        text: 'Manter',
        class: 'yellow',
        fontColor: 'font-white',
      },
      {
        quantity: this.stockConsensus.qt_sell,
        width: (this.stockConsensus.qt_sell / max) * 100 + '%',
        text: 'Vender',
        class: 'orange',
        fontColor: 'font-white',
      },
    ];
    this.onDetectChanges.next();
  }

  changeViewType(usePercentage: boolean) {
    this.recommendationsTargetPrices = [];
    this.usePercentage = usePercentage;
    this.processTargetPrices();
    this.onDetectChanges.next();
  }

  recommendationClickCallback = () => {
    if (this.selectedRecommendaton.hasCallback) {
      let stockTrade: any;
      if (this.selectedRecommendaton.class === 'comprar') {
        stockTrade = BOLETA_COMPRA_BASECOMPONENT();
      } else if (this.selectedRecommendaton.class === 'vender') {
        stockTrade = BOLETA_VENDA_BASECOMPONENT();
      }

      if (stockTrade) this._homeService.addComponent(stockTrade);
    }
  };
}
