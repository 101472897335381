import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';

@Component({
  selector: 'custody-stop-orders-alert',
  templateUrl: './custody-stop-orders-alert.component.html',
  styleUrls: ['./custody-stop-orders-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustodyStopOrdersAlertComponent {
  isVisible = false;
  label = '';
  top = 0;
  left = 0;

  constructor(private cdr: ChangeDetectorRef) {}

  show(value: string, top: number, left: number) {
    if (!value) return;
    this.isVisible = true;
    this.label = value;
    this.top = top + 14;
    this.left = left + 12;
    this.cdr.detectChanges();
  }

  hide() {
    this.isVisible = false;
    this.label = '';
    this.top = 0;
    this.left = 0;
    this.cdr.detectChanges();
  }
}
