import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-flow-header',
  templateUrl: './flow-header.component.html',
  styleUrls: ['./flow-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowHeaderComponent {
  @Input() refComponent!: string;
  @Input() public component!: any;
  @Output() changeMenu: EventEmitter<any> = new EventEmitter<any>();
  public headers = [
    { label: 'B3', code: 'FLOW_B3', active: true },
    { label: 'Futuros', code: 'FLOW_INVESTORS', active: false },
    { label: 'Estrangeiros', code: 'FOREIGNERS', active: false },
    { label: 'Volume de IPOs', code: 'VOLUME_IPO', active: false },
  ];

  public selectMenu(code: string): void {
    this.headers.forEach((menu) => {
      menu.active = code === menu.code;
    });
    this.changeMenu.emit(code);
  }
}
