import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ConfigService } from '@core/config';
import { SearchStockComponent } from '@shared/components/search-stock/search-stock.component';
import { DragService } from '@shared/rocket-components/services/ag-grid/drag.service';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { ISearchStock, IWorkSpaceComponet } from 'src/app/core/interface';

@Component({
  selector: 'app-times-trades-header',
  templateUrl: './times-trades-header.component.html',
})
export class TimesTradesHeaderComponent
  implements OnChanges, AfterViewInit, OnDestroy
{
  @ViewChild('searchStock') searchStock!: SearchStockComponent;
  @ViewChild('timesAndTradesHeader') private _timesAndTradesHeader!: ElementRef;

  @Input() public refComponent!: string;
  @Input() public headerOptions!: any;
  @Input() component!: IWorkSpaceComponet;
  @Input() stockSelected!: ISearchStock;
  @Input() tradeByOrderActive: boolean = false;
  @Input() filterAndHighlightActive: boolean = false;
  @Input() haveCustomFilters: boolean = false;

  @Output() changeStock = new EventEmitter<any>();
  @Output() changeTradeByOrder = new EventEmitter<boolean>();
  @Output() handleOpenTradeSettings = new EventEmitter<void>();
  @Output() useSmallShortCutSize = new EventEmitter<boolean>();

  private dragEvents$!: Subscription;
  private _resize$!: ResizeObserver;
  private _onDocumentSizeChange = new Subject<number>();
  private _usingSmallShortcut = false;

  public tooltip!: string;
  public useDefaultHeader = true;
  showSelectWindow: boolean =
    !inject(ConfigService).isWebAuthenticationChannel();

  public filterAndHighlightIconColor = 'text-light';

  constructor(private dragService: DragService) {
    this._onDocumentSizeChange.pipe(debounceTime(100)).subscribe((width) => {
      this._configureHeaderAndShortcutWidth(width);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {
      tradeByOrderActive,
      stockSelected,
      haveCustomFilters,
      filterAndHighlightActive,
    } = changes;
    if (
      tradeByOrderActive?.currentValue ||
      tradeByOrderActive?.currentValue === false
    ) {
      this.setTooltip();
    }

    if (this._timesAndTradesHeader && stockSelected?.currentValue) {
      this._onDocumentSizeChange.next(
        this._timesAndTradesHeader.nativeElement.offsetWidth
      );
    }

    if (haveCustomFilters || filterAndHighlightActive)
      this._updateFilterAndHighlightIconColor();
  }

  private _updateFilterAndHighlightIconColor(): void {
    if (this.filterAndHighlightActive && this.haveCustomFilters) {
      this.filterAndHighlightIconColor = 'text-brand-primary';
      return;
    }
    if (!this.filterAndHighlightActive && this.haveCustomFilters) {
      this.filterAndHighlightIconColor = 'text-feedback-warning';
      return;
    }
    this.filterAndHighlightIconColor = 'text-light';
  }

  ngAfterViewInit() {
    this.dragEvents$ = this.dragService
      .onEvents(this.refComponent)
      .subscribe((data) => {
        this.selectStockByCdStock(data.data.cd_stock);
      });
    this.setTooltip();
    this._initializeObservableEvent();
  }

  ngOnDestroy() {
    this._resize$.unobserve(this._timesAndTradesHeader.nativeElement);
    this.dragEvents$ && this.dragEvents$.unsubscribe();
    this._onDocumentSizeChange.unsubscribe();
  }

  public selectStock = (stock: ISearchStock) => this.changeStock.emit(stock);

  selectStockByCdStock(cdStock: string) {
    this.searchStock.selectStockByCdStock(cdStock);
  }

  toggleTradeByOrder = () => {
    this.tradeByOrderActive = !this.tradeByOrderActive;
    this.setTooltip();
    this.changeTradeByOrder.emit();
  };

  setTooltip = () => {
    this.tooltip =
      'Trades por ordem ' +
      (this.tradeByOrderActive ? 'ativado' : 'desativado');
  };

  private _initializeObservableEvent(): void {
    this._resize$ = new ResizeObserver((entries) => {
      this._onDocumentSizeChange.next(entries[0].contentRect.width);
    });
    this._resize$.observe(this._timesAndTradesHeader.nativeElement);
  }

  private _configureHeaderAndShortcutWidth(width: number): void {
    this.useDefaultHeader = width >= 240;
    if (this.stockSelected.standard_lot < 100) {
      if (this._usingSmallShortcut) this._updateShortcutWidth(false);
      return;
    }
    if (!this._usingSmallShortcut && width <= 255) {
      this._updateShortcutWidth(true);
      return;
    }
    if (this._usingSmallShortcut && width > 255)
      this._updateShortcutWidth(false);
  }

  private _updateShortcutWidth(useSmall: boolean): void {
    this._usingSmallShortcut = useSmall;
    this.useSmallShortCutSize.emit(useSmall);
  }
}
