import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'fla-card-icon[text]',
  templateUrl: './card-icon.component.html',
})
export class FlaCardIconComponent {
  @ViewChild(TemplateRef) content!: TemplateRef<any>;
  @Input() text!: string;
  @Input() description!: string;
  @Input() css: string = '';
  @Input() hideArrow: boolean = false;
  @Output() flaClick = new EventEmitter<any>();

  public clickButton(event: any): void {
    this.flaClick.emit(event);
  }
}
