import { Observable } from 'rxjs';
import hash from 'hash-it';

export abstract class AbstractCacheService<T> {
  readonly CACHE_DURATION_IN_MINUTES = 5;
  readonly DEFAULT_KEY = 'DEFAULT';

  private cache: {
    [id: string]: {
      useExpires?: boolean;
      expires?: Date;
      value: Observable<T>;
    };
  } = {};

  getValue(object?: any): Observable<T> {
    const key = object ? hash(object).toString() : this.DEFAULT_KEY;
    const item = this.cache[key];
    if (!item) {
      return null!;
    }
    return item.value;
  }

  setValue(value: Observable<T>, object?: any) {
    const key = object ? hash(object).toString() : this.DEFAULT_KEY;
    this.cache[key] = { value };
  }

  clearCache() {
    this.cache = null!;
    this.cache = {};
  }
}
