import { IConfigView } from './config-view.interface';

export const COLUMNS_TO_REMOVE = {
  qtde_melhor_oferta_compra: true,
  valor_melhor_oferta_compra: true,
  qtde_melhor_oferta_venda: true,
  valor_melhor_oferta_venda: true,
  vl_var_uma_semana: true,
  vl_var_um_mes: true,
  vl_var_um_ano: true,
  week_volume: true,
  month_volume: true,
  year_volume: true,
  vl_volatility_one_year: true,
  vl_volatility_two_year: true,
  vl_beta_one_year: true,
  mcap: true,
  ev: true,
  pl: true,
  pvpa: true,
  evebtida: true,
  vl_low: true,
  vl_mean: true,
  vl_high: true,
  vl_high_today: true,
  vl_high_week: true,
  vl_high_this_month: true,
  vl_high_this_year: true,
  vl_high_yesterday: true,
  vl_high_this_week: true,
  vl_high_month: true,
  vl_high_year: true,
};

export const CONFIG_VIEW: IConfigView[] = [
  {
    label: 'Preços',
    allCheck: false,
    width: 84,
    columns: [
      {
        label: 'Atual',
        field: 'preco_ultimo',
        isCheck: false,
        width: 84,
        tooltip: '',
      },
      {
        label: 'Fechamento',
        field: 'preco_fechamento',
        isCheck: false,
        width: 138,
        tooltip: '',
      },
      {
        label: 'Mínimo',
        field: 'preco_minimo',
        isCheck: false,
        width: 100,
        tooltip: '',
      },
      {
        label: 'Máximo',
        field: 'preco_maximo',
        isCheck: false,
        width: 110,
        tooltip: '',
      },
      {
        label: 'Abertura',
        field: 'preco_abertura',
        isCheck: false,
        width: 112,
        tooltip: '',
      },
      {
        label: 'Fechamento Anterior',
        field: 'precoDiaAnt',
        isCheck: false,
        width: 210,
        tooltip: '',
      },
    ],
  },
  {
    label: 'Book',
    allCheck: false,
    width: 60,
    columns: [
      {
        label: 'Qtd Compra',
        field: 'qtde_melhor_oferta_compra',
        isCheck: false,
        width: 144,
        tooltip: 'Quantidade disponível na melhor oferta de compra',
      },
      {
        label: 'Preço Compra',
        field: 'valor_melhor_oferta_compra',
        isCheck: false,
        width: 158,
        tooltip: 'Preço da melhor oferta de compra',
      },
      {
        label: 'Qtd Venda',
        field: 'qtde_melhor_oferta_venda',
        isCheck: false,
        width: 144,
        tooltip: 'Quantidade disponível na melhor oferta de venda',
      },
      {
        label: 'Preço Venda',
        field: 'valor_melhor_oferta_venda',
        isCheck: false,
        width: 144,
        tooltip: 'Preço da melhor oferta de venda',
      },
    ],
  },
  {
    label: 'Variações',
    allCheck: false,
    width: 60,
    columns: [
      {
        label: 'Intradia',
        field: 'variacao_dia',
        isCheck: false,
        width: 112,
        tooltip: '',
      },
      {
        label: '% Na Semana',
        field: 'vl_var_essa_semana',
        isCheck: false,
        width: 154,
        tooltip: '',
      },
      {
        label: '% No Mês',
        field: 'vl_var_esse_mes',
        isCheck: false,
        width: 126,
        tooltip: '',
      },
      {
        label: '% No Ano',
        field: 'vl_var_esse_ano',
        isCheck: false,
        width: 130,
        tooltip: '',
      },
      {
        label: '% Ontem',
        field: 'variacao_ant_dia',
        isCheck: false,
        width: 120,
        tooltip: '',
      },
      {
        label: '% 1 Semana',
        field: 'vl_var_uma_semana',
        isCheck: false,
        width: 148,
        tooltip: '',
      },
      {
        label: '% 1 Mês',
        field: 'vl_var_um_mes',
        isCheck: false,
        width: 118,
        tooltip: '',
      },
      {
        label: '% 12 meses',
        field: 'vl_var_um_ano',
        isCheck: false,
        width: 138,
        tooltip: '',
      },
    ],
  },
  {
    label: 'Volume',
    allCheck: false,
    width: 50,
    columns: [
      {
        label: 'Intradia',
        field: 'volume',
        isCheck: false,
        width: 120,
        tooltip: '',
      },
      {
        label: 'Semana',
        field: 'this_week_volume',
        isCheck: false,
        width: 120,
        tooltip: '',
      },
      {
        label: 'No Mês',
        field: 'this_month_volume',
        isCheck: false,
        width: 120,
        tooltip: '',
      },
      {
        label: 'No Ano',
        field: 'this_year_volume',
        isCheck: false,
        width: 120,
        tooltip: '',
      },
      {
        label: 'Ontem',
        field: 'yesterday_volume',
        isCheck: false,
        width: 120,
        tooltip: '',
      },
      {
        label: '1 Semana',
        field: 'week_volume',
        isCheck: false,
        width: 124,
        tooltip: '',
      },
      {
        label: '1 Mês',
        field: 'month_volume',
        isCheck: false,
        width: 120,
        tooltip: '',
      },
      {
        label: '12 Meses',
        field: 'year_volume',
        isCheck: false,
        width: 122,
        tooltip: '',
      },
      {
        label: 'Qtd de Negócios',
        field: 'qtde_negocios',
        isCheck: false,
        width: 180,
        tooltip: '',
      },
    ],
  },
  {
    label: 'Volatilidade',
    allCheck: false,
    width: 51,
    columns: [
      {
        label: 'Vol. 12m',
        field: 'vl_volatility_one_year',
        isCheck: false,
        width: 116,
        tooltip: '',
      },
      {
        label: 'Vol. 24m',
        field: 'vl_volatility_two_year',
        isCheck: false,
        width: 116,
        tooltip: '',
      },
      {
        label: 'Beta Dia 12m',
        field: 'vl_beta_one_year',
        isCheck: false,
        width: 150,
        tooltip: '',
      },
    ],
  },
  {
    label: 'Delta Volume',
    allCheck: false,
    width: 26,
    columns: [
      {
        label: 'Δ 1D',
        field: 'today_delta',
        isCheck: false,
        width: 100,
        tooltip: '',
      },
      {
        label: 'Δ 1h',
        field: 'thirty_min_delta',
        isCheck: false,
        width: 100,
        tooltip: '',
      },
    ],
  },
  {
    label: 'Fundamentalista',
    allCheck: false,
    width: 62,
    columns: [
      {
        label: 'Mcap',
        field: 'mcap',
        isCheck: false,
        width: 100,
        tooltip: '',
      },
      {
        label: 'EV',
        field: 'ev',
        isCheck: false,
        width: 100,
        tooltip: '',
      },
      {
        label: 'P/L',
        field: 'pl',
        isCheck: false,
        width: 100,
        tooltip: '',
      },
      {
        label: 'P/VPA',
        field: 'pvpa',
        isCheck: false,
        width: 100,
        tooltip: '',
      },
      {
        label: 'EV/EBITDA',
        field: 'evebtida',
        isCheck: false,
        width: 130,
        tooltip: '',
      },
    ],
  },
  {
    label: 'P Alvo',
    allCheck: false,
    width: 38,
    columns: [
      {
        label: 'Mínimo',
        field: 'vl_low',
        isCheck: false,
        width: 100,
        tooltip: '',
      },
      {
        label: 'Médio',
        field: 'vl_mean',
        isCheck: false,
        width: 100,
        tooltip: '',
      },
      {
        label: 'Máximo',
        field: 'vl_high',
        isCheck: false,
        width: 110,
        tooltip: '',
      },
    ],
  },
  {
    label: 'Ranges',
    allCheck: false,
    width: 54,
    columns: [
      {
        label: 'Intradia',
        field: 'vl_high_today',
        isCheck: false,
        width: 117,
        tooltip: '',
      },
      {
        label: 'Semana',
        field: 'vl_high_week',
        isCheck: false,
        width: 117,
        tooltip: '',
      },
      {
        label: 'Mês',
        field: 'vl_high_this_month',
        isCheck: false,
        width: 117,
        tooltip: '',
      },
      {
        label: 'Ano',
        field: 'vl_high_this_year',
        isCheck: false,
        width: 117,
        tooltip: '',
      },
      {
        label: 'Ontem',
        field: 'vl_high_yesterday',
        isCheck: false,
        width: 117,
        tooltip: '',
      },
      {
        label: '1 Semana',
        field: 'vl_high_this_week',
        isCheck: false,
        width: 124,
        tooltip: '',
      },
      {
        label: '1 Mês',
        field: 'vl_high_month',
        isCheck: false,
        width: 117,
        tooltip: '',
      },
      {
        label: '12 Meses',
        field: 'vl_high_year',
        isCheck: false,
        width: 122,
        tooltip: '',
      },
    ],
  },
];

export const CONFIG_VIEW_AUCTION: IConfigView = {
  label: 'Leilão',
  allCheck: false,
  width: 78,
  columns: [
    {
      label: 'Leilão',
      field: 'variacao_leilao',
      isCheck: false,
      width: 88,
      tooltip: '',
    },
    {
      label: 'Atual Leilão',
      field: 'preco_leilao',
      isCheck: false,
      width: 132,
      tooltip: '',
    },
    {
      label: 'QTD não atendidas',
      field: 'qtde_leilao_nao_atendida',
      isCheck: false,
      width: 192,
      tooltip: '',
    },
    {
      label: 'QTD negociadas',
      field: 'qtde_leilao',
      isCheck: false,
      width: 170,
      tooltip: '',
    },
    {
      label: 'Tempo',
      field: 'hora_abert_program',
      isCheck: false,
      width: 98,
      tooltip: '',
    },
  ],
};
