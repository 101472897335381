import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';
import { OptionsComponents } from '../component-list';

export const BOOK_COMPONENT: any = (options?: OptionsComponents) => {
  return {
    name: 'Book',
    id: COMPONENTS_NAMES_ENUM.BOOK,
    icon: '',
    cod: 'BOOK',
    data: null,
    close: true,
    fullscreen: false,
    link: true,
    widthDesk: 220,
    heightDesk: 375,
    unitOfMeasurement: '%',
    height: options?.height ?? 50,
    width: options?.width ?? 15,
    left: options?.left ?? 'calc(55% + 10px)',
    top: options?.top ?? '0px',
    minHeight: '200px',
    minWidth: '210px',
    iconSvg: 'BOOK',
  };
};
