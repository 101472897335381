<rocket-modal>
  <rocket-modal-header>
    <div>
      <h3 class="modal-title">Atalhos</h3>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div class="mb-6">
      <fla-tab css="mb-3">
        <ng-container *ngFor="let tab of shortCutTabs">
          <fla-tab-header
            [ref]="tab.ref"
            [title]="tab.title"
            [active]="tab.active"
            (flaChange)="changeTab(tab)"
          ></fla-tab-header>

          <fla-tab-body ref="tab.ref" [active]="tab.active">
            <ng-container *ngFor="let shortCut of tabShortCuts[tab.ref]">
              <div class="px-3">
                <div class="row mb-1 fw-bold">
                  <div class="col" [style.max-width.%]="45">
                    <span>{{ shortCut.label }}</span>
                  </div>
                  <div class="col">
                    <span class="text-neutral-medium">
                      {{ shortCut.command }}
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
          </fla-tab-body>
        </ng-container>
      </fla-tab>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
