<div class="container d-flex justify-content-center">
  <div class="terms-container text-center">
    <h1 class="pt-1 text-center text-uppercase mb-5 fs-4">
      TERMOS DE CONDIÇÕES DA CONTRATAÇÃO DA PLATAFORMA ROCKET TRADER
    </h1>
    <p class="text-justify">
      A
      <span class="fw-bold text-uppercase">
        VALEMOBI CONSULTORIA EMPRESARIAL S/A,
      </span>
      pessoa jurídica de direito privado, inscrita no CNPJ sob nº
      10.535.290/0001-21, com sede na Avenida General Furtado Nascimento, nº
      740, cj. 60, 6º andar, São Paulo/SP, empresa proprietária de softwares e
      plataformas tecnológicas desenvolvidas para distribuir informações
      financeiras e de mercado, market data da B3 e de outras bolsas e
      fornecedores, assim como rotear ordens de negociação de títulos e valores
      mobiliários (“Plataforma”), doravante referida simplesmente como
      “VALEMOBI”.
    </p>

    <p class="text-justify">
      <span class="fw-bold text-uppercase"> BANCO BTG PACTUAL S.A </span>,
      inscrita no CNPJ/MF sob nº. 30.306.294/0001-45, com sede na Praia de
      Botafogo, nº 501 – 6 º andar, Torre Corcovado no bairro de Botafogo,
      cidade e estado do Rio de Janeiro,
      <span class="fw-bold text-uppercase">CORRETORA (PARCEIRA)</span> e,
    </p>

    <p class="text-justify">
      <span class="fw-bold text-uppercase">VOCÊ</span>, pessoa física não
      profissional do mercado financeiro, nesse ato denominado “Usuário”, que se
      responsabiliza integralmente pelas suas ações perante a VALEMOBI, e a
      CORRETORA PARCEIRA, pela utilização da Plataforma.
    </p>

    <p class="text-justify">
      Ao aceitar esse Termo de Condições de Contratação (“Termo de
      Contratação”), VOCÊ declara que leu, entendeu, aceita, e cumprirá suas
      responsabilidades e obrigações como Usuário da Plataforma, contidas nos
      Termos de Responsabilidades e Obrigações, já aceito, e declara perante a
      Legislação Brasileira que detém conhecimento para a utilização da
      Plataforma, inclusive das normas da Comissão de Valores Mobiliários, ou a
      quaisquer outros órgãos reguladores, aos quais o Usuário está sujeito.
    </p>

    <ol>
      <li class="mb-5 w-100 text-left">
        <span class="fw-bold">CONDIÇÕES DA CONTRATAÇÃO</span>
        <ol>
          <li>
            O usuário declara possuir conta ativa e regular no BANCO BTG PACTUAL
            S.A., regulamentado junto à CVM, para o envio de ordens de compra e
            venda, considerando estar `compliance` junto ao processo
            administrativo e operacional da CORRETORA PARCEIRA.
          </li>
          <li>
            O Usuário irá realizar a contratação da Plataforma através da
            CORRETORA PARCEIRA onde possui conta. Poderá haver período de
            `trial` (período de gratuidade para utilização da Plataforma),
            acordado entre a VALEMOBI e a Parceira. Após o período de `trial`, a
            Parceira irá pagar diretamente à VALEMOBI pela contratação da
            Plataforma, única e exclusivamente se o Usuário cumprir as seguintes
            condições:
            <ul>
              <li>
                nos 03(três) primeiros meses, operar, no mínimo 01 (um)
                minicontrato de índice ou de dólar com o RLP ativo, a cada mês
              </li>
              <li>
                a partir do 4º(quarto) mês, operar mensalmente, no mínimo, 10
                (dez) minicontratos de índice ou de dólar com o RLP ou
                R$150,00(cento e cinquenta reais),
              </li>
            </ul>
            Caso as condições acima estabelecidas, não sejam cumpridas, o
            Usuário deverá realizar o pagamento mensal, no valor de R$ 129,90
            (cento e vinte e nove reais, e noventa centavos) diretamente à
            VALEMOBI, pela contratação da Plataforma.
          </li>
          <li>
            Para as condições em que o Usuário irá realizar o pagamento
            diretamente à VALEMOBI, esse deverá ocorrer através de Cartão de
            Crédito a ser fornecido pelo Usuário.
          </li>
          <li>
            Neste momento, o Usuário aceita que as informações do seu Cartão de
            Crédito sejam enviadas pela VALEMOBI ao meio de pagamento online
            para viabilizar a transação.
          </li>
          <li>
            A VALEMOBI se reserva o direito de alterar a plataforma de pagamento
            utilizada para intermediar a contratação (com a correspondente
            migração dos dados do Usuário, se aplicável) a qualquer momento e
            sem aviso prévio. Todos os pagamentos são gerenciados pelas
            plataformas intermediadoras de pagamento e, por isso, estão sujeitos
            às normas e regras de cada plataforma no que diz respeito à
            efetivação do pagamento, estorno de compra, cancelamento de compra,
            gestão de fraude, entre outros.
          </li>
          <li>
            O Usuário concorda que seja cobrado no seu cartão de crédito o preço
            relativo à contratação mensal solicitada. Além disso, está de acordo
            com o fato de que a contratação funciona como um modelo de
            assinatura e, portanto, concorda que seja cobrado no seu cartão de
            crédito o preço relativo à renovação automática ao fim de cada
            período mensal contratado. A renovação da assinatura poderá implicar
            um ajuste e/ou correção do preço pago, no caso de contratação dos
            serviços durante janelas de promoções, conforme definido nas regras
            de contratação no momento da compra.
          </li>
          <li>
            Se um pagamento não for liquidado com sucesso, devido à perda de
            validade, insuficiência de fundos ou qualquer outro motivo, a
            VALEMOBI poderá suspender o seu acesso a Plataforma até que a
            cobrança seja efetuada com sucesso.
          </li>
        </ol>
      </li>

      <li class="mb-5 w-100 text-left">
        <span class="fw-bold">TRATAMENTO DE DADOS</span>
        <ol>
          <li>
            A VALEMOBI e VOCÊ concordam que o tratamento dos dados
            fornecidos/recebidos por qualquer das Partes pela outra Parte em
            razão deste Contrato deverá ser realizado em consonância à
            legislação brasileira, inclusive no tocante às diretrizes previstas
            na Lei nº 13.709/2018 (“Lei Geral de Proteção de Dados”), Marco
            Civil da Internet (Lei nº 12.965/2014) e alterações posteriores, e
            quaisquer outras leis e regulamentos em relação ao tratamento de
            dados pessoais e privacidade aplicáveis, sendo certo que deverão ser
            verificados e respeitados todos os tipos de dados previstos na
            legislação aplicável, conforme cada caso concreto em questão
            (inclusive com condutas para anonimizar os dados, caso exigido por
            lei e/ou se fizer necessário).
          </li>
          <li>
            A VALEMOBI irá coletar os dados pessoais fornecidos para pagamento,
            para uso interno, de seus parceiros, e que atenda a legislação
            vigente, e a otimização da experiência do Usuário
          </li>
          <li>
            A VALEMOBI armazena, os dados coletados, em datacenter próprio,
            obedecendo às boas práticas de segurança recomendadas pelo mercado,
            por prazo nunca superior ao admitido pela legislação vigente.
          </li>
        </ol>
      </li>

      <li class="mb-5 w-100 text-left">
        <span class="fw-bold">LEGISLAÇÃO APLICÁVEL E JURISDIÇÃO</span>
        <ol>
          <li>
            Todas as controvérsias ou reclamações decorrentes da interpretação
            ou execução destes Termos de Responsabilidades e Obrigações do
            Usuário da Plataforma serão regidas pela legislação brasileira.
          </li>
        </ol>
      </li>
    </ol>
    <p class="text-left fs-5">
      Estes Termos de Uso foram atualizados em 27/10/2023.
    </p>
  </div>
</div>
