<rocket-modal>
  <rocket-modal-header>
    <h3 class="modal-title">Ativação de telefone</h3>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div class="vstack">
      <div class="vstack gap-2 mb-3" [innerHTML]="stepsText[step]"></div>

      <form class="vstack gap-3" [formGroup]="form" (keydown.enter)="submit()">
        <ng-container *ngIf="step === 1">
          <app-phone-input
            [autofocus]="true"
            formControlName="phone"
            label="Novo Telefone"
            [requiredText]="
              phone.value ? 'Telefone inválido' : 'Esse campo é obrigatório!'
            "
            hasError="invalid"
            css="w-100"
            [isRequired]="true"
            [autoComplete]="false"
            [initialCountry]="phoneCountryCode.value.country"
            (countryHasChanged)="onUpdateCountryCode($event)"
          ></app-phone-input>

          <fla-input
            formControlName="password"
            placeholder="Informe a sua senha"
            label="Senha"
            requiredText="Informe uma senha"
            [isRequired]="true"
            [type]="displaySecurityText ? 'text' : 'password'"
            hasError="invalid"
            decidePosition="suffix"
            [autoComplete]="false"
            margin="m-0"
          >
            <button
              class="btn btn-icon suffix p-3"
              type="button"
              (click)="displaySecurityText = !displaySecurityText"
            >
              <i class="icons">
                {{ displaySecurityText ? 'visibility' : 'visibility_off' }}
              </i>
            </button>
          </fla-input>
        </ng-container>

        <ng-container *ngIf="step === 2">
          <ng-container
            *ngTemplateOutlet="
              pincodeField;
              context: {
                formControlName: 'phone_pincode',
                placeholder: 'Informe o código recebido via telefone',
                maxLength: 6
              }
            "
          ></ng-container>
        </ng-container>

        <ng-container *ngIf="step === 3">
          <ng-container
            *ngTemplateOutlet="
              pincodeField;
              context: {
                formControlName: 'email_pincode',
                placeholder: 'Informe o código recebido via e-mail',
                maxLength: 8
              }
            "
          ></ng-container>
        </ng-container>

        <ng-container *ngIf="errorMessage">
          <span
            class="d-block fs-5 fw-bold py-2 text-center text-feedback-error w-100"
          >
            {{ errorMessage }}
          </span>
        </ng-container>
      </form>
    </div>
  </app-rocket-modal-body>

  <rocket-modal-footer>
    <rocket-button
      *ngIf="canResendPincode"
      type="fill"
      label="Reenviar código"
      (rocketClick)="resendPincode()"
      css="mr-3"
    ></rocket-button>
    <rocket-button
      [loading]="isLoading"
      [type]="step === 1 ? 'fill' : 'outline'"
      [label]="step === 1 ? 'Sair' : 'Voltar'"
      (rocketClick)="backward()"
      css="mr-3"
    ></rocket-button>
    <rocket-button
      [loading]="isLoading"
      label="Continuar"
      [isDisabled]="disableSubmitButton && !isLoading"
      (rocketClick)="submit()"
    ></rocket-button>
  </rocket-modal-footer>
</rocket-modal>

<ng-template
  #pincodeField
  let-formControlName="formControlName"
  let-placeholder="placeholder"
  let-maxLength="maxLength"
>
  <form [formGroup]="form" (keydown.enter)="submit()">
    <fla-input
      [formControlName]="formControlName"
      label="Código de verificação"
      hasError="invalid"
      [placeholder]="placeholder"
      css="w-100"
      decidePosition="suffix"
      [type]="displaySecurityText ? 'text' : 'password'"
      [isRequired]="true"
      [maxLength]="maxLength"
      requiredText=""
      margin="mb-2"
    >
      <div class="suffix">
        <button
          class="btn btn-icon text-muted ml-3"
          type="button"
          id="btnPass"
          (click)="displaySecurityText = !displaySecurityText"
        >
          <i class="icons pe-none">
            {{ displaySecurityText ? 'visibility' : 'visibility_off' }}
          </i>
        </button>
      </div>
    </fla-input>

    <ng-container *ngIf="!canResendPincode">
      <app-timer-resend
        [seconds]="resendPincodeTimeout"
        message="Reenvie um novo código em"
        (timerCompleted)="canResendPincode = true"
      ></app-timer-resend>
    </ng-container>
  </form>
</ng-template>
