import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { IIndexStock } from '../interface/stocks-at-auction-by-index.interface';
import { AUTH_LOCAL_KEYS } from '@shared/services/core/const/auth_util.const';

@Injectable({
  providedIn: 'root',
})
export class StocksAtAuctionByIndex extends CustomPreferencesService {
  private _indexSelected: IIndexStock | undefined = undefined;

  constructor(private http: HttpClient) {
    super(http);
  }

  get indexSelected(): IIndexStock {
    if (this._indexSelected !== undefined) return this._indexSelected;
    const option = this.getCustomPreference(
      AUTH_LOCAL_KEYS.STOCK_INDEX_SELECTED
    );
    if (!option) {
      this._indexSelected = { cdStock: 'IBOV', qtty: 0 };
      return { cdStock: 'IBOV', qtty: 0 };
    }
    this._indexSelected = JSON.parse(option) as IIndexStock;
    return this._indexSelected;
  }

  set indexSelected(data: IIndexStock | null) {
    if (!data || data.cdStock === this._indexSelected?.cdStock) return;
    this._indexSelected = data;
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.STOCK_INDEX_SELECTED,
      JSON.stringify(data)
    );
  }
}
