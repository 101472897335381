import { inject, Injectable } from '@angular/core';
import { NumberRange } from 'scichart/Core/NumberRange';
import { IChartModifierBase } from 'scichart/Charting/ChartModifiers/ChartModifierBase';
import { MouseWheelZoomModifier } from 'scichart/Charting/ChartModifiers/MouseWheelZoomModifier';
import { ZoomPanModifier } from 'scichart/Charting/ChartModifiers/ZoomPanModifier';
import { IRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/IRenderableSeries';
import {
  TWebAssemblyChart,
  SciChartSurface,
} from 'scichart/Charting/Visuals/SciChartSurface';
import { EXyDirection } from 'scichart/types/XyDirection';
import { IAnnotation } from 'scichart/Charting/Visuals/Annotations/IAnnotation';
import { SciChartJSDarkv2Theme } from 'scichart/Charting/Themes/SciChartJSDarkv2Theme';
import {
  ReplaySubject,
  Subject,
  Subscription,
  auditTime,
  debounceTime,
  delay,
  filter,
} from 'rxjs';
import { DpiHelper } from 'scichart/Charting/Visuals/TextureManager/DpiHelper';
import { BaseIndicator } from './indicators/indicators.types';
import {
  TIGER_LINE_TYPE,
  TIGER_MOUSE_EVENT_DATA_TYPE,
} from './enum/tiger-chart.enum';
import { CHART_COLORS, DEFAULT_THEME, LIGHT_THEME } from './colors';
import { ICandleConfigs } from './interface/tiger-chart.interface';
import {
  TCandleLineOptions,
  TData,
  TMouseEventData,
  TUpdateData,
} from './types/tiger-chart.types';
import { DEFAULT_CONFIGS } from './constants/default.configs';
import { CANDLE_IDS, LINE_OFFSET } from './constants/tiger-chart.constants';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { CustomChartLoader } from './custom-chart-loader';
import {
  TIGER_CHART_TOOL,
  saveConfiguration,
} from './tiger-chart-tools/tiger-chart-tools.interface';
import {
  getLineAuxAnnotationId,
  getLineBoxAnnotationId,
  isNullOrUndefined,
  objectAssign,
} from 'src/app/utils/utils.functions';
import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { deepClone } from '@shared/rocket-components/utils/functions';
import { TOOLS_ENUM } from './tiger-chart-tools/tiger-chart-tools.enum';
import { TOOLS } from './tiger-chart-tools/tiger-chart-tools.const';
import { DrawTools } from './draw-tools/draw-tools.interface';
import { ToolbarDrawService } from './tiger-chart-tools/toolbar-draw/toolbar-draw.service';
import { TYPE_EVENT } from './tiger-chart-tools/toolbar-draw/toolbar-draw.enum';
import { StockChartModalService } from '@shared/components/stock-chart/parts/modal-more-options/service/stock-chart-modal.service';
import { TigerYAxisDragModifier } from './annotations/yaxis-drag-modifier';
import { TigerXAxisDragModifier } from './annotations/xaxis-drag-modifier';
import { PlusLineService } from './services/plus-line.service';
import { ZoomDoubleClickModifier } from './annotations/zoom-double-click-modifier';
import { ScichartService } from './services/scichart.service';
import { Point } from 'scichart/Core/Point';
import { TigerMiddleChartService } from './services/tiger-middle-chart.service';
import { Dictionary } from '@core/models';
import { AxisBase2D } from 'scichart/Charting/Visuals/Axis/AxisBase2D';
import {
  COVERAGE_PRICE_ID,
  ZOOM_PAN_MODIFIER_ID,
  ZOOM_RUBBER_BAND_MODIFIER_ID,
} from '@shared/components/stock-chart/constants/stock-chart.constant';
import { RubberBandXyZoomModifier } from 'scichart/Charting/ChartModifiers/RubberBandXyZoomModifier';
import { CustodyStopOrder } from './custody-stop-orders/custodyStopOrder';
import { wheelZoomModifier } from './annotations/mouse-wheel-zoom-modifier';
import { ISearchStock } from '@core/interface';
import {
  CategoryAxis,
  CursorModifier,
  CustomAnnotation,
  ECoordinateMode,
  EDragMode,
  EHorizontalAnchorPoint,
  LogarithmicAxis,
  NumericAxis,
} from 'scichart';
import { SeparatorDateAnnotationService } from './services/separator-date-annotation.service';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { THEMES } from '@shared/services/core/custom-preferences/theme/theme-preferences.interface';
import { CursorCandleModifier } from './annotations/cursor-candle-modifier';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { RTTextLengthService } from './services/text-length.service';
import { DrawsService } from '@shared/components/stock-chart/service/draws.service';
import { HorizontalLine } from './annotations/horizontal-line-annotation';
import { CHART_LINE_FIELD_CONFIG } from '@shared/components/stock-chart/parts/modal-more-options/interface/stock-chart-modal.interface';
import { ClickableAnnotation } from './annotations/clickable-annotation';
import { RocketZoomPanModifier } from './annotations/zoom-pan-modifier';
import { ScrollChartService } from './services/scroll-chart.service';

@Injectable()
export abstract class RTChartScichartBase extends ReadStreamBase {
  baseChart!: TWebAssemblyChart;
  started$ = new ReplaySubject<boolean>(1);
  cursor: string = 'default';
  backupCursor: string = 'default';
  toolCursor: string = 'crosshair';
  chartHeight!: number;
  stock!: ISearchStock;
  selectedDrawAnnotation!: DrawTools | undefined;
  isHoverDraw: boolean = false;
  isDragging = false;
  navigationControl: string = 'ONLY_HOVER';
  showFastRuler = false;
  showBidAsk = false;
  showChartBook = false;
  showFastOrder = false;
  showChartVolumeAtPrice = false;
  showMinMaxVisible = false;
  showTradingChange = false;
  showPreviousClose = false;
  showLastPrice = false;
  orderLine!: CHART_LINE_FIELD_CONFIG;
  alertLine!: CHART_LINE_FIELD_CONFIG;
  custodyLine!: CHART_LINE_FIELD_CONFIG;
  breakEvenConfig!: CHART_LINE_FIELD_CONFIG;
  showClosePriceTimer: boolean = true;
  showExecOrders = true;
  showFlagOrders = true;
  showOrderMarker = true;
  showOpenFlag = true;
  isFreeScale: boolean = false;
  clickOnIndicator: boolean = false;
  clickOnPlus: boolean = false;
  clickOnArrow: boolean = false;
  inBoundYAxis: boolean = false;
  inBoundXAxis: boolean = false;
  scrollBegin: boolean = false;
  annotationHovered: boolean = false;
  _custodyStopOrder!: CustodyStopOrder;
  separatorDateAnnotationService!: SeparatorDateAnnotationService;
  configsToolsToRedraw: saveConfiguration[] = [];
  mouseWheelId = 'mouse_wheel_modifier_id';
  private _refComponent!: string;
  private _deSelectedTool$ = new Subject<void>();
  private deSelectedTool$!: Subscription;

  set renderableSeriesAdd(item: IRenderableSeries) {
    this.scichartService.renderableSeriesAdd = item;
  }
  set renderableSeriesRemove(item: IRenderableSeries) {
    this.scichartService.renderableSeriesRemove = item;
  }
  set annotationsAdd(item: IAnnotation | IAnnotation[]) {
    this.scichartService.annotationsAdd = item;
  }

  set annotationsRemove(item: IAnnotation) {
    this.scichartService.annotationsRemove = item;
  }

  set modifiersAdd(modifier: IChartModifierBase) {
    this.scichartService.modifiersAdd = modifier;
  }

  set modifiersRemove(modifier: IChartModifierBase) {
    this.scichartService.modifiersRemove = modifier;
  }

  set axisRemove(axis: AxisBase2D) {
    this.scichartService.axisRemove = axis;
  }

  set indicatorsAdd(indicator: BaseIndicator) {
    this.indicators.push(indicator);
    const series = indicator.create({
      base: this.baseChart,
      yAxisId: '',
      xAxisId: '',
      tickSize: this.configs.yAxis.labelPrecision || 2,
    });
    if (series) {
      series.forEach((serie) => (this.renderableSeriesAdd = serie));
    }
    this.getIndicators$.next(this.indicators);
  }

  set indicatorsRemove(indicatorRemove: any) {
    const indicatorIndex = this.indicators.findIndex(
      (indicator) =>
        indicator.type == indicatorRemove.type &&
        indicator.lineNumber == indicatorRemove.lineNumber
    );
    if (indicatorIndex < 0) {
      return;
    }
    this.indicators[indicatorIndex].delete();
    this.indicators.splice(indicatorIndex, 1);
    this.getIndicators$.next(this.indicators);
  }

  set applyConfigs(configs: Partial<ICandleConfigs>) {
    const config = objectAssign(deepClone(this.configs), deepClone(configs));
    this.configs = {
      ...config,
      enableCursorModifier: this.configs?.enableCursorModifier ?? false,
    };
  }

  get chartWidth() {
    return this.baseChart.sciChartSurface.seriesViewRect.width;
  }

  get inBoundAxis() {
    return this.inBoundXAxis || this.inBoundYAxis;
  }

  cursorModifier!: CursorModifier | undefined;
  cursorCandleModifier!: CursorCandleModifier | undefined;
  indicators: BaseIndicator[] = [];
  configs: ICandleConfigs = DEFAULT_CONFIGS();

  getTheme = (isDark: boolean) => (isDark ? DEFAULT_THEME() : LIGHT_THEME());

  getIndicators$: ReplaySubject<BaseIndicator[]> = new ReplaySubject<
    BaseIndicator[]
  >(1);
  protected _modifierGroup = '';
  private _visibleRange!: Subscription;
  private _toolSelectedSub!: Subscription;
  private _widthChangedSub!: Subscription;
  private _sharedToolSub!: Subscription;
  protected _candleYAxis!: NumericAxis | LogarithmicAxis;
  protected _columnYAxis!: NumericAxis;
  countStepClickTool = 0;
  selectedTool!: TIGER_CHART_TOOL | null;
  _themeService!: ThemePreferencesService;
  drawToolsService!: DrawToolsService;
  stockChartModalService!: StockChartModalService;
  toolbarDrawService!: ToolbarDrawService;
  plusLineService!: PlusLineService;
  scichartService!: ScichartService;
  middleChartService!: TigerMiddleChartService;
  textLengthService!: RTTextLengthService;
  scrollChartService!: ScrollChartService;
  isBrush: boolean = false;
  isChanging: number = 0;
  xAxis!: CategoryAxis;
  _firstVisibleRange!: NumberRange | undefined;
  _previousVRange!: NumberRange | undefined;
  isFromSub = false;
  subCharts = new Dictionary<{
    indicator: BaseIndicator;
    height: number;
    dividerPosition: number;
  }>();
  theme!: any;
  drawsService = inject(DrawsService);
  chartWidthChange$ = new Subject<void>();
  changeThemeHandler = (data: any) => {
    if (!this.baseChart?.sciChartSurface) return;
    this.theme = this.getTheme(data === THEMES.dark);
    const theme = Object.assign(new SciChartJSDarkv2Theme(), this.theme);
    this.baseChart.sciChartSurface.applyTheme(theme);
  };
  constructor(private stockChartService: StockChartService) {
    super();
    this.theme = this.getTheme(this.stockChartService.isDarkTheme());
    this.stockChartService.onChangeTheme(this.changeThemeHandler);
    this.deSelectedTool$ = this._deSelectedTool$
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.deSelectedTool();
      });
    this._visibleRange = this.stockChartService.visibleRange$.subscribe(
      (value) => {
        if (
          value.ref !== this._refComponent &&
          this.configs.series.interval === value.interval &&
          this.baseChart &&
          this.baseChart.sciChartSurface &&
          this.baseChart.sciChartSurface.xAxes.size() > 0 &&
          value.visibleRange
        ) {
          const xAxes = this.baseChart.sciChartSurface.xAxes.get(0);
          if (
            !isNullOrUndefined(this.stockChartService.clickRef) &&
            this.stockChartService.clickRef !== '' &&
            this.stockChartService.clickRef !== this._refComponent
          ) {
            if (value.previousVisibleRange) {
              this.stockChartService.notReload.set(this._refComponent, {
                reload: true,
              });
              // const minDiff =
              //   value.previousVisibleRange.min - value.visibleRange.min;
              // const maxDiff =
              //   value.previousVisibleRange.max - value.visibleRange.max;
              // const visibleRange = xAxes.visibleRange;
              // const min = visibleRange.min - minDiff;
              // const max = visibleRange.max - maxDiff;
              const newVisibleRange = value.visibleRange;
              xAxes.animateVisibleRange(newVisibleRange, 0);
              this.xAxis.visibleRange = newVisibleRange;
            }
          }
          if (
            !isNullOrUndefined(this.stockChartService.clickRef) &&
            this.stockChartService.clickRef === ''
          ) {
            xAxes.animateVisibleRange(this._firstVisibleRange!!, 0);
            this.xAxis.visibleRange = this._firstVisibleRange!!;
          }
        }
      }
    );
    this._toolSelectedSub = this.stockChartService.toolSelected$.subscribe(
      (tool) => {
        if (tool.componentRef === this._refComponent) {
          if (!tool.toolEnum) {
            this.selectedTool = null;
          }
          if (
            tool.tool &&
            !(
              tool.tool?.method === 'drawLine' &&
              tool.tool?.codTool === 'temporário_LINHA_TEMPORARIA' &&
              this.isBrush
            )
          ) {
            this.tool(tool.tool);
          }
        }
      }
    );
    this._sharedToolSub = this.stockChartService.sharedTool$.subscribe(
      (too) => {
        if (this._refComponent != too.ref) {
          const { draw } = this.drawToolsService.getDrawTool(
            too.tool,
            too.eventData,
            too.eventDataLast,
            this._refComponent,
            too.width,
            too.height,
            this.stock,
            this.baseChart,
            this.configs,
            too.isFromSaved,
            undefined,
            true
          );
          if (draw) {
            const id = draw.id.split('_');
            const index = parseInt(id[id.length - 1]);
            if (index === this.subCharts.size() - 1) {
              draw.showLabel = true;
            }
            this.annotationsAdd = draw;
          }
        }
      }
    );

    this._widthChangedSub = this.chartWidthChange$
      .pipe(auditTime(100))
      .subscribe(() => {
        this.updateLineOffsets();
      });
  }

  init(idElement: string, callback: () => void) {
    DpiHelper.IsDpiScaleEnabled = false;
    const loader = idElement.includes('sub_chart')
      ? undefined
      : new CustomChartLoader(this.stockChartService, idElement);
    const theme = Object.assign(new SciChartJSDarkv2Theme(), this.theme);
    SciChartSurface.useWasmFromCDN();
    SciChartSurface.create(idElement, { loader, theme }).then(
      (chart: TWebAssemblyChart) => {
        this.baseChart = chart;
        this.scichartService = new ScichartService(this.baseChart);
        this._refComponent = idElement;
        this.stockChartService.verticalGroup.addSurfaceToGroup(
          this.baseChart.sciChartSurface
        );
        this.toolEventSub();
        callback();
        this.started$.next(true);
      }
    );
  }

  appendToIndicators(xValue: number, data: TUpdateData, fullData: TData) {
    this.indicators.forEach((indicator) =>
      indicator.append(xValue, data, fullData)
    );
  }

  insertToIndicators(xValues: number[], fullData: TData) {
    this.indicators.forEach((indicator) =>
      indicator.insertRange(xValues, fullData)
    );
  }

  updateIndicators(index: number, data: TUpdateData, fullData: TData) {
    this.indicators.forEach((indicator) =>
      indicator.update(index, data, fullData)
    );
  }

  getRenderableSeriesById<T = IRenderableSeries>(id: string): T {
    return this.scichartService.getRenderableSeriesById(id);
  }

  getAnnotationsById = <T = IAnnotation>(id: string): T => {
    return this.scichartService?.getAnnotationsById(id);
  };

  getModifierById<T = IChartModifierBase>(id: string): T {
    return this.scichartService.getModifierById(id);
  }

  enableZoom(includedXAxisIds: string[], includedYAxisIds: string[]) {
    const zoomPan = this.getModifierById(ZOOM_PAN_MODIFIER_ID);
    if (zoomPan) {
      return;
    }
    this.modifiersAdd = new RocketZoomPanModifier(this.scrollChartService, {
      xyDirection: EXyDirection.XDirection,
      id: ZOOM_PAN_MODIFIER_ID,
      includedXAxisIds,
      includedYAxisIds,
      yAxisId: 'DefaultAxisId',
    });
    const rubberBand = new RubberBandXyZoomModifier({
      id: ZOOM_RUBBER_BAND_MODIFIER_ID,
    });
    rubberBand.isEnabled = false;
    this.modifiersAdd = rubberBand;
    this.modifiersAdd = new ZoomDoubleClickModifier({ isAnimated: false });
    this.modifiersAdd = this.getDefaultMouseWheelModifier();
    if (this.configs.enableZoom) this.baseChart.sciChartSurface.zoomExtents();
  }

  addAxisDragModifier(xAxisId: string, yAxisId: string) {
    const modifier = this.getModifierById('yaxis-drag-modifier');
    if (modifier) {
      return;
    }
    const yAxisDragModifier = new TigerYAxisDragModifier({
      id: 'yaxis-drag-modifier',
      yAxisId,
      dragMode: EDragMode.Scaling,
    });
    const xAxisDragModifier = new TigerXAxisDragModifier({
      id: 'xaxis-drag-modifier',
      xAxisId,
    });
    yAxisDragModifier.events$.subscribe((data) => {
      if (data.type) {
        this.inBoundYAxis = true;
        this.drawToolsService.blockEditDraw$.next(this.inBoundAxis);
        if (data.type === TIGER_MOUSE_EVENT_DATA_TYPE.YAXIS_HOVER) {
          if (!this.isGrabbing()) {
            this.updateChartCursor('ns-resize');
          }
        }
        if (data.type === TIGER_MOUSE_EVENT_DATA_TYPE.YAXIS_GROW) {
          this.setChartFreeScale();
        }
        this.removePlus();
        return;
      }
      this.inBoundYAxis = false;
      this.drawToolsService.blockEditDraw$.next(this.inBoundAxis);
      if (!this.annotationHovered) this.updateCursorToDefaultNotGrab();
    });
    xAxisDragModifier.events$.subscribe((data) => {
      if (data.type && data.type === TIGER_MOUSE_EVENT_DATA_TYPE.XAXIS_HOVER) {
        this.inBoundXAxis = true;
        this.drawToolsService.blockEditDraw$.next(this.inBoundAxis);
        if (!this.isGrabbing()) {
          this.updateChartCursor('ew-resize');
        }
        this.removePlus();
        return;
      }
      this.inBoundXAxis = false;
      this.drawToolsService.blockEditDraw$.next(this.inBoundAxis);
      if (!this.annotationHovered) this.updateCursorToDefaultNotGrab();
    });
    this.modifiersAdd = yAxisDragModifier;
    this.modifiersAdd = xAxisDragModifier;
  }

  updateCursorToDefaultNotGrab() {
    //do nothing.
  }

  public setChartFreeScale() {
    if (!this.isFreeScale) {
      this.isFreeScale = true;
      this.emitFreeScale();
      this.changeZoomPanDirection();
    }
  }

  emitFreeScale() {
    // do nothing.
  }

  getDefaultMouseWheelModifier(): MouseWheelZoomModifier {
    return new wheelZoomModifier(
      {
        xyDirection: EXyDirection.XDirection,
        id: this.mouseWheelId,
      },
      `xAxis_${this._refComponent}`
    );
  }

  enableCursorModifier(
    crosshairStrokeThickness: number,
    eXyDirection: EXyDirection,
    showXLine: boolean,
    showYLine: boolean,
    crosshairStroke: string
  ) {
    if (this.cursorModifier) return;
    const id = `${CANDLE_IDS.CURSOR_MODIFIER}_${this._refComponent}`;
    const candleInfoId = `${CANDLE_IDS.CURSOR_MODIFIER}_${this._refComponent}_candle_info`;
    this.cursorModifier = new CursorModifier({
      crosshairStroke,
      crosshairStrokeThickness: crosshairStrokeThickness,
      tooltipContainerBackground: CHART_COLORS.NEUTRAL_STRONG,
      tooltipTextStroke: CHART_COLORS.NEUTRAL_SMOOTHEST,
      showTooltip: false,
      axisLabelFill: CHART_COLORS.NEUTRAL_STRONG,
      axisLabelStroke: CHART_COLORS.NEUTRAL_SMOOTHEST,
      id,
      xyDirection: eXyDirection,
      modifierGroup: this._modifierGroup,
      showXLine,
      showYLine,
      showAxisLabels: true,
      xAxisId: `xAxis_${this._refComponent}`,
    });
    this.cursorCandleModifier = new CursorCandleModifier(
      this.textLengthService,
      this._themeService,
      this.stockChartService,
      this.drawsService,
      this.stock,
      {
        showTooltip: true,
        id: candleInfoId,
        xyDirection: eXyDirection,
        modifierGroup: this._modifierGroup,
        showXLine: false,
        showYLine: false,
        showAxisLabels: false,
        xAxisId: `xAxis_${this._refComponent}`,
      }
    );
    this.modifiersAdd = this.cursorModifier;
    this.modifiersAdd = this.cursorCandleModifier;
  }

  showOnlyAxisLabelsCursorModifier(crosshairStroke: string) {
    let eXyDirection = EXyDirection.XyDirection;
    let showXLine = true;
    let showYLine = true;
    if (this.cursorModifier) {
      this.modifiersRemove = this.cursorModifier;
      eXyDirection = deepClone(this.cursorModifier.xyDirection);
      showXLine = deepClone(this.cursorModifier.showXLine);
      showYLine = deepClone(this.cursorModifier.showYLine);
    }
    this.cursorModifier = undefined;
    this.enableCursorModifier(
      1,
      eXyDirection,
      showXLine,
      showYLine,
      crosshairStroke
    );
  }

  endInfinityDraw() {
    const drawTool = this.baseChart.sciChartSurface.annotations.getById(
      this.selectedTool!!.dictId!!
    ) as DrawTools;
    if (!drawTool.endDraw) {
      this.drawToolsService.removeAnnotation(this.baseChart, drawTool);
    } else {
      this.drawToolsService.endDrawClick(
        this.selectedTool!!,
        this.baseChart,
        this._refComponent
      );
      const removedDraw: string[] = [];
      drawTool.children.forEach((childDraw) => {
        if (!childDraw.endDraw) {
          this.drawToolsService.deleteChild(this.baseChart, childDraw.id);
          removedDraw.push(childDraw.id);
        }
      });
      removedDraw.forEach((removed) => {
        drawTool.children = drawTool.children.filter(
          (child) => child.id !== removed
        );
        drawTool.tool.children = drawTool.tool.children?.filter(
          (child) => child.dictIdChildren !== removed
        );
      });
    }
    this.drawToolsService.removeMessageHelper(this.selectedTool!!);
    this.selectedTool = null;
  }

  mouseEvents(eventData: TMouseEventData | null, mainChart: boolean = true) {
    if (
      !mainChart &&
      !isNullOrUndefined(this.selectedTool) &&
      this.selectedTool?.group != TOOLS_ENUM.TEMPORARY
    ) {
      return;
    }
    //if (
    //  !isNullOrUndefined(this.selectedTool) &&
    //  this.selectedTool?.group == TOOLS_ENUM.TEMPORARY
    //) {
    //  this.selectedTool = null;
    //}
    if (
      eventData &&
      eventData.type === TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_MOUSE_CLICK
    ) {
      const configuration = this.stockChartModalService.getGlobalChartConfigs();
      if (isNullOrUndefined(this.selectedTool) && configuration.showFastRuler) {
        TOOLS.get(TOOLS_ENUM.TEMPORARY)![0].position = undefined;
      }
      if (!isNullOrUndefined(this.selectedTool)) {
        this.countStepClickTool++;
        const created = this.drawToolsService.createTool(
          this.baseChart,
          eventData,
          this.selectedTool,
          this._refComponent,
          this.countStepClickTool,
          this.configs,
          this.chartHeight,
          this.stock
        );
        this.selectedTool = created.tool;
        this.drawToolsService.isLocked = false;
        this.toolLockerChanged();
        this.configsToolsToRedraw = [];
        //this.deSelectedTool(TOOLS_ENUM.LOCKER);
      } else {
        if (this.isBrush && !this.showFastRuler) {
          this.drawToolsService.brushAnnotation(this.baseChart, eventData);
          return;
        }
        this.startDrawEdit({
          selectedTool: this.selectedTool,
          baseChart: this.baseChart,
          eventData,
          chartHeight: this.chartHeight,
          configs: this.configs,
          showFastRuler: this.showFastRuler,
          stock: this.stock,
        });
      }
      return;
    }
    if (eventData && eventData.id) {
      this.stockChartService.mouseEventClear$.next({ ref: this._refComponent });
    }
  }

  updateDraw(eventData: TMouseEventData | null) {
    if (this.selectedTool) {
      const update = this.drawToolsService.updateDraw(
        this.baseChart,
        eventData,
        this.selectedTool,
        this.configs,
        this.countStepClickTool,
        this.stock
      );
      this.selectedTool = update.tool;
    }
  }

  removeSubscriptions() {
    this._visibleRange.unsubscribe();
    this._toolSelectedSub.unsubscribe();
    this._sharedToolSub.unsubscribe();
    this._widthChangedSub.unsubscribe();
    this.deSelectedTool$ && this.deSelectedTool$.unsubscribe();
    this.removeDraws();
  }

  removeDraws() {
    this.baseChart &&
      this.baseChart.sciChartSurface.annotations
        .asArray()
        .filter((annotation) => (annotation as DrawTools).isDraw)
        .forEach((annotation) => {
          (annotation as DrawTools).unsubscriber();
          this.annotationsRemove = annotation;
        });
  }

  tool(tool: TIGER_CHART_TOOL) {
    if (tool.group === TOOLS_ENUM.LOCKER) {
      this.drawToolsService.isLocked = !this.drawToolsService.isLocked;
      this.toolLockerChanged();
      return;
    }
    if (this.isBrush) {
      this.toolCursor = TOOLS.get(TOOLS_ENUM.CURSORS)!![2].mouseCursor!!;
      //this.backupCursor = this.cursor;
    }
    this.selectedTool = deepClone(tool);
    if (tool.stepClick === Infinity) {
      this.selectedTool!!.stepClick = Infinity;
    }
    this.isBrush = false;
    let position = '12 12';
    if (tool.codTool === `${TOOLS_ENUM.CURSORS}_BORRACHA`) {
      position = '4 4';
      this.isBrush = true;
    }
    this.drawToolsService.isBrush = this.isBrush;
    if (!isNullOrUndefined(this.selectedTool!!.position)) {
      return;
    }
    this.countStepClickTool = 0;
    if (tool.group === TOOLS_ENUM.CURSORS && tool.mouseCursor) {
      this.toolCursor = tool.mouseCursor
        .replace(new RegExp(/\s/, 'g'), '')
        .replace('),', `) ${position},`);
      this.selectedTool = null;
      setTimeout(() => {
        this.stockChartService.toolSelected$.next({
          componentRef: this._refComponent,
          tool: null,
        });
      }, 100);

      return;
    }
    if (tool.group === TOOLS_ENUM.TRASH || tool.group === TOOLS_ENUM.VISIBLE) {
      this.drawToolsService.clearShowDraws(this.baseChart, tool);
      this.selectedTool = null;
      setTimeout(() => {
        this.stockChartService.toolSelected$.next({
          componentRef: this._refComponent,
          tool: null,
        });
      }, 100);
      return;
    }
    if (this.selectedTool!!.group !== TOOLS_ENUM.TEMPORARY) {
      this.drawToolsService.updateChartZoomModifers(this.baseChart, false);
    }
  }

  toolLockerChanged = () => {
    if (
      this.drawToolsService.isLocked &&
      this.toolbarDrawService.hasElement()
    ) {
      this.toolbarDrawService.destroyComponent();
    }
    this.baseChart &&
      this.baseChart.sciChartSurface.annotations
        .asArray()
        .filter((annotation) => (annotation as DrawTools).isDraw)
        .forEach((annotation) => {
          (annotation as DrawTools).isEditable =
            !this.drawToolsService.isLocked;
        });
  };

  esc() {
    if (this.selectedTool) {
      if (this.selectedTool.stepClick === Infinity) {
        this.endInfinityDraw();
        this.deSelectedTool();
        return;
      }
      const annotation = this.getAnnotationsById(
        this.selectedTool.dictId!!
      ) as DrawTools;
      if (annotation) {
        this.drawToolsService.removeAnnotation(this.baseChart, annotation);
        this.selectedTool = null;
      }
      this.deSelectedTool();
    }
  }

  toolEventSub() {
    this.drawToolsService.toolEvent$
      .pipe(filter((tool) => tool.componentRef === this._refComponent))
      .subscribe((tool) => {
        if (tool.typeEvent === TYPE_EVENT.DELETE) {
          const annotation = this.getAnnotationsById(
            tool.tool!.dictId!
          ) as DrawTools;
          if (annotation) {
            this.drawToolsService.removeAnnotation(this.baseChart, annotation);
            if (this.selectedDrawAnnotation) {
              this.selectedDrawAnnotation.startEdit = false;
              //this.selectedDrawAnnotation = undefined;
            }
          }
        }
        if (tool.typeEvent === TYPE_EVENT.UPDATE) {
          this.countStepClickTool = tool.countStepClick;
          this.selectedTool = tool.tool;
          if (isNullOrUndefined(this.selectedTool)) {
            this.selectedDrawAnnotation = undefined;
            this._deSelectedTool$.next();
          }
        }
        if (tool.typeEvent === TYPE_EVENT.EDIT) {
          if (tool.tool?.group !== TOOLS_ENUM.TEMPORARY) {
            if (
              this.selectedTool &&
              this.selectedTool.codTool !=
                `${TOOLS_ENUM.GEOMETRIC_SHAPES}_SEQUENCE` &&
              this.countStepClickTool >= this.selectedTool.stepClick
            ) {
              this.startDrawEdit();
              this._deSelectedTool$.next();
            }
          } else {
            setTimeout(() => {
              this.drawToolsService.dispatchEndDrawClick(
                tool.tool,
                this.baseChart,
                this._refComponent
              );
            }, 100);
          }
        }
      });
  }

  startDrawEdit(navigation?: {
    selectedTool: TIGER_CHART_TOOL | null;
    baseChart: TWebAssemblyChart;
    eventData: TMouseEventData;
    configs: ICandleConfigs;
    chartHeight: number;
    stock: any;
    showFastRuler: boolean;
  }) {
    if (
      this.clickOnIndicator ||
      this.clickOnPlus ||
      this.clickOnArrow ||
      this._custodyStopOrder?.isClickedOnBall ||
      this.drawToolsService.isLocked ||
      this.inBoundAxis
    ) {
      return;
    }
    if (
      this.toolbarDrawService?.hasElement() &&
      this.toolbarDrawService?.getDrawTool()!.id ==
        this.selectedDrawAnnotation?.id &&
      this.selectedDrawAnnotation?.tool.group == TOOLS_ENUM.ANNOTATION_TOOLS
    ) {
      this.drawToolsService.openModalConfigDraw(
        this.selectedDrawAnnotation?.tool,
        false
      );
    } else {
      this.selectedDrawAnnotation = this.drawToolsService.startDrawEdit(
        this.selectedDrawAnnotation,
        this.isHoverDraw,
        this._refComponent,
        navigation
      ).drawTool;
    }
  }

  annotationHoverEvents(draw?: DrawTools) {
    if (!draw) {
      this.isHoverDraw = false;
      this.selectionDraw();
      if (this.selectedDrawAnnotation) {
        this.selectedDrawAnnotation.startEdit = false;
      }
      this.selectedDrawAnnotation = undefined;
    }
    if (draw && !this.selectedTool && !this.isBrush) {
      if (
        this.selectedDrawAnnotation &&
        draw.id != this.selectedDrawAnnotation.id
      ) {
        this.selectedDrawAnnotation.startEdit = false;
      }
      setTimeout(() => {
        if (draw.isChildren) {
          const father = this.drawToolsService.childrenFatherDic.get(draw.id);
          if (father && father.tool.group !== TOOLS_ENUM.TEMPORARY) {
            this.selectedDrawAnnotation = father;
          }
        } else if (draw.tool.group !== TOOLS_ENUM.TEMPORARY) {
          this.selectedDrawAnnotation = draw;
        }
        this.selectionDraw();
      }, 50);
      this.isHoverDraw = true;
      this.cursor = 'pointer';
    }
    this.stockChartService.drawToolHover$.next({
      componentRef: this._refComponent,
      draw: this.selectedDrawAnnotation,
    });
    this.setChartCursor();
  }

  private setChartCursor() {
    const element = document.getElementById(`${this._refComponent}_2D`);
    if (element) {
      element.style.cursor = this.cursor;
    }
  }

  private isCursorAxis(): boolean {
    const element = document.getElementById(`${this._refComponent}_2D`);
    if (element) {
      return (
        element.style.cursor === 'ns-resize' ||
        element.style.cursor === 'ew-resize'
      );
    }
    return false;
  }

  isGrabbing() {
    const element = document.getElementById(`${this._refComponent}_2D`);
    if (element) {
      return element.style.cursor === 'grabbing';
    }
    return false;
  }

  updateChartCursor(cursor: string, updateBackup: boolean = true) {
    if (this.isCursorAxis() || (this.isDragging && cursor !== 'grabbing')) {
      return;
    }
    this.cursor = cursor;
    if (updateBackup) {
      //this.backupCursor = cursor;
    }
    this.setChartCursor();
  }

  makeDeselectionAuxBox() {
    if (!this.selectedDrawAnnotation) {
      return;
    }
    const tool = this.selectedDrawAnnotation.tool;
    const children = tool.children?.filter((c) => c.isAux);
    const childrenEdit = tool.children?.filter(
      (c) => !c.isAux && c.withBoxAuxCanEdit
    );
    if (children) {
      children.forEach((child) => {
        const aux = this.selectedDrawAnnotation!!.children.find(
          (c) => c.id === child?.dictIdChildren
        )!!;
        if (aux.isSelected && !this.toolbarDrawService.hasElement()) {
          if (tool.withBoxAuxCanEdit) {
            this.selectedDrawAnnotation!!.isEditable = false;
            this.selectedDrawAnnotation!!.isSelected = false;
          }
          aux.isSelected = false;
          aux.isEditable = false;
        }
      });
    }
    if (childrenEdit) {
      childrenEdit.forEach((child) => {
        const edit = this.selectedDrawAnnotation!!.children.find(
          (c) => c.id === child?.dictIdChildren
        )!!;
        if (edit.isSelected && !this.toolbarDrawService.hasElement()) {
          edit.isSelected = false;
          edit.isEditable = false;
        }
      });
    }
  }

  private makeSelectionAuxBox() {
    const tool = this.selectedDrawAnnotation!!.tool;
    const children = tool.children?.filter((c) => c.isAux);
    const childrenEdit = tool.children?.filter(
      (c) => !c.isAux && c.withBoxAuxCanEdit
    );
    if (children) {
      children.forEach((child) => {
        const aux = this.selectedDrawAnnotation!!.children.find(
          (c) => c.id === child?.dictIdChildren
        )!!;
        if (!aux.isSelected) {
          this.drawToolsService.makeGripsAnnotation(aux);
          if (tool.withBoxAuxCanEdit) {
            this.drawToolsService.makeGripsAnnotation(
              this.selectedDrawAnnotation!!
            );
          }
        }
      });
    }
    if (childrenEdit) {
      childrenEdit.forEach((child) => {
        const edit = this.selectedDrawAnnotation!!.children.find(
          (c) => c.id === child?.dictIdChildren
        )!!;
        if (!edit.isSelected) {
          this.drawToolsService.makeGripsAnnotation(edit);
        }
      });
    }
  }

  selectionDraw() {
    if (this.selectedDrawAnnotation && !this.selectedDrawAnnotation.startEdit) {
      if (this.toolbarDrawService.hasElement()) {
        return;
      }
      const tool = this.selectedDrawAnnotation.tool;
      if (!this.isHoverDraw) {
        this.drawToolsService.childrenDrawHidden(
          this.selectedDrawAnnotation,
          true
        );
        if (tool.hasBoxAux) {
          this.makeDeselectionAuxBox();
        } else {
          this.selectedDrawAnnotation.isSelected = false;
          this.selectedDrawAnnotation.isEditable = false;
        }
      } else {
        this.drawToolsService.childrenDrawHidden(
          this.selectedDrawAnnotation,
          false
        );
        if (tool.hasBoxAux) {
          this.makeSelectionAuxBox();
        } else {
          this.drawToolsService.makeGripsAnnotation(
            this.selectedDrawAnnotation
          );
        }
      }
    }
  }

  deleteLastDraw() {
    this.drawToolsService.deleteLastDraw(this.baseChart);
  }

  addDeletedLastDraw() {
    this.drawToolsService.addDeletedLastDraw(
      this.baseChart,
      this._refComponent,
      this.configs,
      this.chartHeight,
      this.stock
    );
  }

  deleteDraw() {
    if (
      this.toolbarDrawService.hasElement() &&
      !this.drawToolsService.isLocked
    ) {
      const draw = this.toolbarDrawService.getDrawTool();
      if (draw) {
        this.drawToolsService.deleteDraw(this.baseChart, draw, true);
      }
    }
  }

  redraw() {
    this.drawToolsService.trashDraw(this.baseChart);
    this.drawToolsService.clearAllDic();
  }

  changeZoomPanDirection() {
    const modifier = this.getModifierById(
      ZOOM_PAN_MODIFIER_ID
    ) as ZoomPanModifier;
    if (modifier) {
      modifier.xyDirection = this.isFreeScale
        ? EXyDirection.XyDirection
        : EXyDirection.XDirection;
    }
  }

  addPlus(eventData: TMouseEventData) {
    this.plusLineService.addPlus(this.baseChart, eventData, this._refComponent);
  }

  removePlus() {
    this.plusLineService.removePlus(this._refComponent);
  }

  drawHorizontalLine(mousePoint: Point, pointValueY: number) {
    const selectedTool = deepClone(
      TOOLS.get(TOOLS_ENUM.TREND_LINES)?.find(
        (tool) => tool.codTool === `${TOOLS_ENUM.TREND_LINES}_LINHA_HORIZONTAL`
      )
    );
    const { hitTestMiddleXInfo, hitTestMiddleYInfo } =
      this.middleChartService.getMiddleChart(
        this.baseChart.sciChartSurface,
        mousePoint
      );
    if (!hitTestMiddleXInfo || !hitTestMiddleYInfo) {
      return;
    }
    const eventData: TMouseEventData = {
      data: null,
      type: TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_MOUSE_CLICK,
      pointValues: new Point(0, pointValueY),
      middleXPointValues: hitTestMiddleXInfo.hitTestPointValues,
      middleYPointValues: hitTestMiddleYInfo.hitTestPointValues,
    };
    this.drawToolsService.createTool(
      this.baseChart,
      eventData,
      selectedTool,
      this._refComponent,
      1,
      this.configs,
      this.chartHeight,
      this.stock
    );
  }

  removeFastRuler() {
    const annotation = this.baseChart.sciChartSurface.annotations
      .asArray()
      .find((anno) => {
        const drawAnnotation = anno as DrawTools;
        return (
          drawAnnotation &&
          drawAnnotation.tool &&
          drawAnnotation.tool.codTool ===
            `${TOOLS_ENUM.TEMPORARY}_LINHA_TEMPORARIA` &&
          !drawAnnotation.isChildren
        );
      }) as DrawTools;
    if (annotation) {
      this.drawToolsService.removeAnnotation(this.baseChart, annotation);
    }
  }

  // eslint-disable-next-line
  deSelectedTool(toolEnum?: TOOLS_ENUM) {
    //do nothing.
  }

  getLineVisibility = (type: TIGER_LINE_TYPE) => {
    if (type === TIGER_LINE_TYPE.CLOSE && !this.showLastPrice) return false;
    if (type === TIGER_LINE_TYPE.ALERT && !this.alertLine?.enabled)
      return false;
    if (type === TIGER_LINE_TYPE.BREAK_EVEN && !this.breakEvenConfig?.enabled)
      return false;
    if (type === TIGER_LINE_TYPE.PREVIOUS_CLOSE && !this.showPreviousClose)
      return false;
    return true;
  };

  orderLineConfig = (orders: any) => {
    if (!orders) return;
    this.orderLine = orders;
    const custodyAnnotation =
      this.baseChart.sciChartSurface.annotations.getById(
        getLineBoxAnnotationId(this._custodyStopOrder.custodyLineId)
      ) as ClickableAnnotation;
    this.baseChart.sciChartSurface.annotations
      .asArray()
      .filter((ann: IAnnotation) => {
        return (
          ann instanceof HorizontalLine &&
          ann.getLineType() === TIGER_LINE_TYPE.ORDER
        );
      })
      .forEach((item) => {
        const isDoubleStartStop =
          item.id.includes('_gain') || item.id.includes('_loss');
        const options = this.orderLineOffset();
        const offsetX = this.getOrderOffset(options);
        item.xCoordinateMode = options.xCoordinateMode;
        if (isDoubleStartStop) {
          item.x1 = this.startStopOrderOffset(custodyAnnotation);
        } else {
          item.x1 = offsetX;
        }
        const auxLine = this.baseChart.sciChartSurface.annotations.getById(
          getLineAuxAnnotationId(item.id)
        ) as ClickableAnnotation;
        const box = this.baseChart.sciChartSurface.annotations.getById(
          getLineBoxAnnotationId(item.id)
        ) as ClickableAnnotation;
        if (box) {
          box.xCoordinateMode = item.xCoordinateMode;
          box.x1 = item.x1;
          box.horizontalAnchorPoint = this.orderLine?.rightSide
            ? EHorizontalAnchorPoint.Right
            : EHorizontalAnchorPoint.Left;
        }
        if (auxLine) auxLine.isHidden = this.orderLine.rightSide!!;
      });
  };

  alertLineConfig = (alerts: any) => {
    if (!alerts) return;
    this.alertLine = alerts;
    this.baseChart.sciChartSurface.annotations
      .asArray()
      .filter((ann: IAnnotation) => {
        return (
          ann instanceof HorizontalLine &&
          ann.getLineType() === TIGER_LINE_TYPE.ALERT
        );
      })
      .forEach((item) => {
        const setHidden = !this.alertLine.enabled;
        item.isHidden = setHidden;
        const { xOffset, xCoordinateMode } = this.alertLineOffset();
        item.xCoordinateMode = xCoordinateMode;
        item.x1 = xOffset;

        const box = this.baseChart.sciChartSurface.annotations.getById(
          getLineBoxAnnotationId(item.id)
        ) as ClickableAnnotation;
        if (box) {
          box.isHidden = setHidden;
          box.xCoordinateMode = item.xCoordinateMode;
          box.x1 = item.x1;
          box.horizontalAnchorPoint = this.alertLine?.rightSide
            ? EHorizontalAnchorPoint.Right
            : EHorizontalAnchorPoint.Left;
        }
      });
  };

  custodyLineConfig = (custody: any) => {
    if (!custody) return;
    this.custodyLine = custody;
    this.baseChart.sciChartSurface.annotations
      .asArray()
      .filter((ann: IAnnotation) => {
        return (
          ann instanceof HorizontalLine &&
          ann.getLineType() === TIGER_LINE_TYPE.CUSTODY
        );
      })
      .forEach((item) => {
        const custodyAnn = item as HorizontalLine;
        const completeLine = (custodyAnn as HorizontalLine).getCompleteLine();
        const box = this.baseChart.sciChartSurface.annotations.getById(
          getLineBoxAnnotationId(custodyAnn.id)
        ) as ClickableAnnotation;
        const { xOffset, xCoordinateMode } = this.custodyLineOffset();
        custodyAnn.xCoordinateMode = xCoordinateMode;
        custodyAnn.x1 = xOffset;
        if (box) {
          box.xCoordinateMode = custodyAnn.xCoordinateMode;
          box.x1 = custodyAnn.x1;
          box.horizontalAnchorPoint = this.custodyLine?.rightSide
            ? EHorizontalAnchorPoint.Right
            : EHorizontalAnchorPoint.Left;
        }
        if (completeLine) completeLine.isHidden = this.custodyLine.rightSide!!;

        if (this._custodyStopOrder) this._updateCustodyCheepsDelayed();
      });
  };

  alertLineOffset = (options: any = {}) => {
    options.xOffset = this.alertLine?.rightSide
      ? this.chartWidth - LINE_OFFSET.ALERT_RIGHT
      : LINE_OFFSET.ALERT_LEFT;
    options.xCoordinateMode = ECoordinateMode.Pixel;
    options.alignRight = this.alertLine?.rightSide;
    return options;
  };

  custodyLineOffset = (options: any = {}) => {
    options.xOffset = this.custodyLine?.rightSide
      ? this.chartWidth - LINE_OFFSET.CUSTODY_RIGHT
      : LINE_OFFSET.CUSTODY_LEFT;
    options.xCoordinateMode = ECoordinateMode.Pixel;
    options.alignRight = this.custodyLine?.rightSide;
    return options;
  };

  orderLineOffset = (options: any = {}) => {
    options.xOffset = this.orderLine?.rightSide
      ? this.chartWidth - LINE_OFFSET.ORDER_RIGHT
      : LINE_OFFSET.ORDER_LEFT;
    options.xCoordinateMode = ECoordinateMode.Pixel;
    options.alignRight = this.orderLine?.rightSide;
    return options;
  };

  updateLineOffsets = () => {
    this.custodyLine?.rightSide && this.custodyLineConfig(this.custodyLine);
    this.orderLine?.rightSide && this.orderLineConfig(this.orderLine);
    this.breakEvenConfig?.rightSide && this.updateBreakEvenAnnotation();
    this.alertLine?.rightSide && this.alertLineConfig(this.alertLine);
  };

  private updateBreakEvenAnnotation = () => {
    const offset = this.chartWidth - LINE_OFFSET.BREAK_EVEN_RIGHT;
    const ann =
      this.baseChart.sciChartSurface.annotations.getById(COVERAGE_PRICE_ID);
    ann && (ann.x1 = offset);
  };

  private _updateCustodyCheepsDelayed = () => {
    let started = new Subscription();
    const handler = () => {
      this._custodyStopOrder.updateCustodyCheeps();
      started && started.unsubscribe();
    };
    started = this.started$.pipe(delay(10)).subscribe(handler);
  };

  getOrderOffset = (options: TCandleLineOptions) => {
    let { xOffset } = this.orderLineOffset();
    if (this.custodyLine.rightSide !== this.orderLine.rightSide) {
      return xOffset;
    }
    const diff = this.orderLine.rightSide ? -10 : 10;
    const custodyAnnotation =
      this.baseChart.sciChartSurface.annotations.getById(
        getLineBoxAnnotationId(this._custodyStopOrder.custodyLineId)
      ) as ClickableAnnotation;

    if (custodyAnnotation) {
      const custodyAnnBorder = custodyAnnotation.getAnnotationBorders();
      const box = this.getAnnotationsById<CustomAnnotation>(
        getLineBoxAnnotationId(options.boxId)
      );

      if (box) {
        const boxBorders = box.getAnnotationBorders();
        if (
          boxBorders.y1 <= custodyAnnBorder.y2 &&
          boxBorders.y2 >= custodyAnnBorder.y1
        ) {
          xOffset = custodyAnnotation?.getAnnotationBorders().x2 + diff;
        }
      } else {
        const y1 = this._candleYAxis
          .getCurrentCoordinateCalculator()
          .getDataValue(custodyAnnBorder.y1);
        const y2 = this._candleYAxis
          .getCurrentCoordinateCalculator()
          .getDataValue(custodyAnnBorder.y2);
        if (options.value <= y1 && options.value >= y2) {
          xOffset = custodyAnnotation?.getAnnotationBorders().x2 + diff;
        }
      }
    }
    return xOffset;
  };

  startStopOrderOffset = (custodyAnnotation?: ClickableAnnotation) => {
    if (this.orderLine?.rightSide === this.custodyLine?.rightSide) {
      return this.orderLine?.rightSide
        ? custodyAnnotation?.getAnnotationBorders().x1 || this.chartWidth - 90
        : custodyAnnotation?.getAnnotationBorders().x2 || 90;
    }
    return this.orderLine?.rightSide ? this.chartWidth - 110 : 90;
  };
}
