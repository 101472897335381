import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'auction-template',
  templateUrl: './auction-template.component.html',
  styleUrls: [
    './auction-template.component.scss',
    '../tiger-chart-message.component.scss',
  ],
})
export class AuctionTemplateComponent implements OnInit {
  @Input() auctionVariation!: string;
  @Input() auctionPrice!: string;
  @Input() typeAuctionQttyNotAnswered!: string;
  @Input() auctionQttyNotAnswered!: string;
  @Input() auctionQtty!: string;
  @Input() timer!: string;
  @Input() auctionMessage!: string;

  constructor() {}

  ngOnInit() {}
}
