import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { STOCK_ANALYSIS_NAVIGATION_CONTROLS } from '@shared/components/stock-analysis/enum';

@Component({
  selector: 'stock-analysis-controls',
  templateUrl: './stock-analysis-controls.component.html',
  styleUrls: ['./stock-analysis-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockAnalysisControlsComponent implements OnInit {
  controls = STOCK_ANALYSIS_NAVIGATION_CONTROLS;
  @Input() width!: number;
  @Output() control = new EventEmitter<{
    control: STOCK_ANALYSIS_NAVIGATION_CONTROLS;
  }>();
  constructor() {}

  ngOnInit() {}

  controlClick(control: STOCK_ANALYSIS_NAVIGATION_CONTROLS) {
    this.control.emit({ control });
  }
}
