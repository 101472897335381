import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { OnboardService } from '@shared/services/api/trademap/onboard';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';

@Injectable({
  providedIn: 'root',
})
export class OnboardGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _onboardService: OnboardService
  ) {}

  canActivate() {
    if (this._isInvalidPasswordRecovery()) {
      this._redirToLogin();
      return false;
    }

    if (!this._onboardService.isRecoveryProcess()) {
      if (window.location.pathname.includes('/welcome')) {
        if (this._hasSomeInvalidDataInOnboard()) {
          this._redirToLogin();
          return false;
        }
        return true;
      }

      if (window.location.pathname.includes('/validate')) {
        if (this._hasSomeInvalidDataInPincodeConfirmation()) {
          this._redirToLogin();
          return false;
        }
        return true;
      }
    }
    return true;
  }

  private _isInvalidPasswordRecovery(): boolean {
    return (
      this._onboardService.isRecoveryProcess() &&
      isNullOrUndefined(this._onboardService?.validateInfo)
    );
  }

  private _hasSomeInvalidDataInOnboard(): boolean {
    return (
      !this._onboardService.cdAccessToken ||
      !this._onboardService.isAcceptTerm ||
      !this._onboardService.password ||
      !this._onboardService.phoneNumber
    );
  }

  private _hasSomeInvalidDataInPincodeConfirmation(): boolean {
    return (
      !this._onboardService.phoneNumber || !this._onboardService.cdAccessToken
    );
  }

  private _redirToLogin(): void {
    this._router.navigate([`/auth/login`], {
      relativeTo: this._route,
      skipLocationChange: false,
    });
  }
}
