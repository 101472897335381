import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  WritableSignal,
  signal,
} from '@angular/core';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { MoversChannel } from '@shared/channel/movers.channel';
import { RocketStreamRead } from '@shared/channel/rx-event';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { Subject, debounceTime, filter, map } from 'rxjs';
import { STOCKS_AT_AUCTION_BY_INDEX_ELEMENTS_ID } from './enum/stocks-at-auction-by-index.enum';
import {
  IAuctionCountFields,
  IIndexStock,
  IQuoteFields,
} from './interface/stocks-at-auction-by-index.interface';
import { StocksAtAuctionByIndex } from './service/stocks-at-auction-by-index.service';
import {
  AUCTIONING_CHEETAH_ITEM,
  createIndexesListDict,
} from './constants/stocks-at-auction-by-index.contants';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { AuctionChannel } from '@shared/channel/auction.channel';
import { IAuctionChannelData } from '../stock-table/interfaces/stock-table.interfaces';
import { Dictionary } from '@core/models';
import { getAuctionInfos } from 'src/app/utils/utils.functions';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { ISearchStock } from '@core/interface';

@Component({
  selector: 'app-stocks-at-auction-by-index',
  templateUrl: './stocks-at-auction-by-index.component.html',
  styles: [
    `
      :host(app-stocks-at-auction-by-index) {
        ::ng-deep .notification-header app-notification-count .notification {
          top: 12px !important;
          right: -14px !important;
        }
      }
      .content {
        box-shadow: inset 0 0 0 1px var(--vm-neutral-strong);
        z-index: 4;
        cursor: default;
        top: 16px;
        max-height: 500px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StocksAtAuctionByIndexComponent
  extends ReadStreamBase
  implements OnInit, OnDestroy
{
  private readonly _CHEETAH_HEADER_ID = 'STOCKS_AT_AUCTION_BY_INDEX';
  private _auctionSubParams: SubscribeParam = {
    itemsArray: [],
    header: this._CHEETAH_HEADER_ID,
  };
  private _moversSubParams: SubscribeParam = {
    itemsArray: [AUCTIONING_CHEETAH_ITEM],
    header: this._CHEETAH_HEADER_ID,
  };

  private _quoteStream!: RocketStreamRead;
  private _auctionStream!: RocketStreamRead;
  private _moversStream!: RocketStreamRead;
  private _closeContentSubject = new Subject<boolean>();
  private _updateIndexSelectedAtPreference = new Subject<void>();
  private _unsubStockSubject = new Subject<void>();
  private _updateGlobalStockSubject = new Subject<IQuoteFields>();
  private _indexesWithStocksAtAuctionCount: WritableSignal<any> = signal({});
  private _quoteStreamIsActive = false;
  private _auctionStreamIsActive = false;
  private _loadedAuctionSnapshot = false;
  private _subscribedAtAuction = false;
  private _stocksToRemoveQuoteSub: any = {};

  public indexesDict = createIndexesListDict();
  public stocksAtAuctionDict = new Dictionary<IQuoteFields>();
  public elementsID = STOCKS_AT_AUCTION_BY_INDEX_ELEMENTS_ID;
  public indexSelected: IIndexStock | null = null;
  public indexToDisplay: IIndexStock | null = null;
  public loadedSnapshot: boolean = false;
  public displayAuctionByIndex = false;
  public isLoadingStocks = true;
  public enableAuctionIcon = false;
  public globalCdStock!: string;

  constructor(
    private _moversChannel: MoversChannel,
    private _quoteChannel: QuoteChannel,
    private _auctionChannel: AuctionChannel,
    private _cdr: ChangeDetectorRef,
    private _stockAtAuctionByIndexService: StocksAtAuctionByIndex,
    private _globalStock: GlobalSelectedStockSubscription
  ) {
    super();
    this._closeContentSubject
      .pipe(debounceTime(200))
      .subscribe((display) => this._onToggleContentVisibility(display));
    this._updateIndexSelectedAtPreference
      .pipe(debounceTime(1000))
      .subscribe(() => {
        this._stockAtAuctionByIndexService.indexSelected = this.indexSelected!;
        const index = this.indexesDict.get(this.indexSelected!.cdStock);
        if (!index) {
          this.enableAuctionIcon = false;
        } else {
          this.setEnableIcon(index.qtty);
        }
        this._cdr.detectChanges();
      });
    this._unsubStockSubject
      .pipe(
        debounceTime(500),
        map(() => {
          const stocks = structuredClone(
            Object.values(this._stocksToRemoveQuoteSub)
          ) as string[];
          this._stocksToRemoveQuoteSub = {};
          return stocks;
        })
      )
      .subscribe((stocks) => this._unsubAtQuoteChannel(stocks));

    this._updateGlobalStockSubject
      .pipe(
        filter((stock) => stock.cd_stock !== this.globalCdStock),
        debounceTime(250)
      )
      .subscribe((stock) => this._updateGlobalStock(stock));

    this._globalStock.onGlobalStockChange().then(async (data) => {
      this.readStream(data.stream, this._updateSelectedStock);
    });
  }

  ngOnInit() {
    this._getIndexSelected();
    this._initilizeMoversEvents();
    this._updateSelectedStock(this._globalStock.getGlobalStockSelected());
  }

  ngOnDestroy(): void {
    this._moversChannel.unsubscribe(this._moversSubParams);
    this._moversStream?.close();
    this._closeContentSubject.unsubscribe();
    this._updateIndexSelectedAtPreference.unsubscribe();
    this._unsubStockSubject.unsubscribe();
  }

  public handleDisplayAuctionByIndex(displayContent: boolean): void {
    this._closeContentSubject.next(displayContent);
  }

  public onSelectIndex(index: IIndexStock): void {
    if (index.cdStock === this.indexSelected?.cdStock) {
      this.updateIndexToDisplay(index);
      return;
    }
    this.updateIndexToDisplay(index);
    this.indexSelected = index;
    this._updateIndexSelectedAtPreference.next();
  }

  public updateIndexToDisplay(index: IIndexStock): void {
    this.indexToDisplay = index;
    this._subAtAuctionChannel();
  }

  public displayAvailableIndexes(): void {
    this._unsubAtAuctionChannel();
    this._unsubAtQuoteChannel(this._getStocksAtAuction());
    this.stocksAtAuctionDict.clear();
    this.indexToDisplay = null;
    this._cdr.detectChanges();
  }

  public updateGlobalStock(stock: IQuoteFields): void {
    this._updateGlobalStockSubject.next(stock);
  }

  // Global Stocks
  private _updateGlobalStock(stock: IQuoteFields): void {
    this._globalStock.researchToStandardizeGlobalStock(stock, true);
  }

  private _updateSelectedStock = (stock: ISearchStock): void => {
    const cdStock = stock.is_synonymous
      ? stock?.synonymous_nickname
      : stock.cd_stock_order;
    this.globalCdStock = cdStock;
    this._cdr.detectChanges();
  };

  private _onToggleContentVisibility(display: boolean): void {
    if (display) this._subAtAuctionChannel();
    else this._resetValuesOnClose();
    this.displayAuctionByIndex = display;
    this._cdr.detectChanges();
  }

  private _resetValuesOnClose(): void {
    this._unsubAtAuctionChannel();
    this._unsubAtQuoteChannel(this._getStocksAtAuction());
    this.stocksAtAuctionDict.clear();
    this._getIndexSelected();
  }

  private _getIndexSelected(): void {
    const index = this._stockAtAuctionByIndexService.indexSelected;
    this.indexToDisplay = index;
    this.indexSelected = index;
    this._cdr.detectChanges();
  }

  // MOVERS
  private async _initilizeMoversEvents(): Promise<void> {
    this._moversChannel.subscribe(this._moversSubParams);
    this._moversStream = await this._moversChannel.readEvents();
    this.readStream(this._moversStream.stream, this._handleMoversChannel);
    this._moversStream.snapshot(this._moversSubParams.itemsArray);
  }

  private _handleMoversChannel = (
    data: Map<string, IAuctionCountFields[]>
  ): void => {
    const items = data.get(AUCTIONING_CHEETAH_ITEM);
    if (!data.size || !items?.length) return;
    items.forEach((item) => {
      if (item.key === 'EOS') {
        this.loadedSnapshot = true;
        this._cdr.detectChanges();
        return;
      }
      if (!this.loadedSnapshot) {
        const value = parseInt(item.valor as string);
        this.setSelectedIndexQtty(item.key, value);
        this.indexesDict.set(item.key, { cdStock: item.key, qtty: value });
        this._indexesWithStocksAtAuctionCount.update((items) =>
          this._handleIndexesWithAuctionStocks(items, value, item.key)
        );
        return;
      }

      if (item.command === 'DELETE') {
        this.setSelectedIndexQtty(item.key, 0);
        this.indexesDict.set(item.key, { cdStock: item.key, qtty: 0 });
        this._indexesWithStocksAtAuctionCount.update((items) =>
          this._handleIndexesWithAuctionStocks(items, 0, item.key)
        );
        return;
      }

      if (item.command === 'ADD' || item.command === 'UPDATE') {
        const value = parseInt(item.valor as string);
        this.setSelectedIndexQtty(item.key, value);
        this.indexesDict.set(item.key, { cdStock: item.key, qtty: value });
        this._indexesWithStocksAtAuctionCount.update((items) =>
          this._handleIndexesWithAuctionStocks(items, value, item.key)
        );
      }
    });
    this._cdr.detectChanges();
  };

  private setSelectedIndexQtty(cdStock: string, qtty: number) {
    if (this.indexSelected && this.indexSelected.cdStock === cdStock) {
      this.indexSelected.qtty = qtty;
      this.setEnableIcon(qtty);
      this._cdr.detectChanges();
    }
  }

  private setEnableIcon(qtty: number) {
    if (this.indexSelected!.cdStock === 'GERAL') {
      this.enableAuctionIcon = false;
    } else {
      this.enableAuctionIcon = qtty > 0;
    }
    this._cdr.detectChanges();
  }

  private _handleIndexesWithAuctionStocks = (
    items: any,
    value: number,
    key: string
  ) => {
    if (value) {
      items[key] = key;
      return items;
    }
    delete items[key];
    return items;
  };

  // AUCTION
  private _subAtAuctionChannel(): void {
    if (!this.indexToDisplay || this._subscribedAtAuction) return;
    this._subscribedAtAuction = true;
    this._auctionSubParams = {
      itemsArray: [this.indexToDisplay.cdStock.toUpperCase()],
      header: this._CHEETAH_HEADER_ID,
    };
    this._cdr.detectChanges();
    if (!this._auctionStreamIsActive) this._initializeAuctionEvents();
    this._auctionChannel.subscribe(this._auctionSubParams);
  }

  private _unsubAtAuctionChannel(): void {
    if (this._auctionStreamIsActive) {
      this._auctionChannel.unsubscribe(this._auctionSubParams);
      this._auctionStream?.close();
      this._auctionStreamIsActive = false;
      this._subscribedAtAuction = false;
    }
  }

  private async _initializeAuctionEvents(): Promise<void> {
    this._auctionStreamIsActive = true;
    this._auctionStream = await this._auctionChannel.readEvents();
    this.readStream(this._auctionStream.stream, this._handleAuctionEvents);
    this._auctionStream.snapshot(this._auctionSubParams.itemsArray);
  }

  private _handleAuctionEvents = (
    data: Map<string, IAuctionChannelData[]>
  ): void => {
    const stocks = data.get(this.indexToDisplay!.cdStock.toUpperCase());
    if (!stocks || !stocks.length) return;
    stocks.forEach((item) => {
      if (item.isEndOfSnap) {
        const stocksToSub = structuredClone(this._getStocksAtAuction());
        this._subAtQuoteChannel(stocksToSub as string[]);
        return;
      }

      if (!this._loadedAuctionSnapshot) {
        this.stocksAtAuctionDict.set(item.key, {
          item: item.key,
          cd_stock: item.key,
        });
        return;
      }

      if (item.command === 'DELETE') {
        if (this.stocksAtAuctionDict.has(item.key)) {
          this._stocksToRemoveQuoteSub[item.key] = `${item.key}:1`;
          this.stocksAtAuctionDict.delete(item.key);
          this._unsubStockSubject.next();
        }
        return;
      }
      this.stocksAtAuctionDict.set(item.key, {
        item: item.key,
        cd_stock: item.key,
      });
    });
  };

  // QUOTE
  private _subAtQuoteChannel(stocks: string[]): void {
    if (!this._quoteStreamIsActive) this._initializeQuoteEvents(stocks);
    this._quoteChannel.subscribe({
      header: this._CHEETAH_HEADER_ID,
      itemsArray: stocks,
    });
  }

  private _unsubAtQuoteChannel(stocks: string[]): void {
    if (this._quoteStreamIsActive) {
      this._quoteChannel.unsubscribe({
        header: this._CHEETAH_HEADER_ID,
        itemsArray: stocks,
      });
      this._quoteStream.close();
      this._quoteStreamIsActive = false;
      this.isLoadingStocks = true;
    }
  }

  private _getStocksAtAuction(): string[] {
    return this.stocksAtAuctionDict.values().map((stock) => stock.item!);
  }

  private async _initializeQuoteEvents(stocks: string[]): Promise<void> {
    this._quoteStreamIsActive = true;
    this._quoteStream = await this._quoteChannel.readEvents();
    this._quoteStream.snapshot(stocks);
    this.readStream(this._quoteStream.stream, this._handleQuoteEvents);
    this.isLoadingStocks = false;
    this._cdr.detectChanges();
  }

  private _handleQuoteEvents = (data: Map<string, IQuoteFields>): void => {
    data?.forEach((stock) => {
      if (!this.stocksAtAuctionDict.has(stock.item)) return;
      stock.auctionInfos = getAuctionInfos(stock, {});
      this.stocksAtAuctionDict.set(stock.item, stock);
    });
    this._cdr.detectChanges();
  };
}
