import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';

@Component({
  selector: 'toogle-theme',
  template: ` <div>
    <input
      type="checkbox"
      class="checkbox"
      id="checkbox"
      [(ngModel)]="isDarkThemeSelected"
      (ngModelChange)="onCheckboxChange($event)"
    />
    <label for="checkbox" class="checkbox-label">
      <i class="icons icon-size-tiny w-50">check</i>
      <span class="ball"></span>
    </label>
  </div>`,
  styleUrls: ['./toogle-theme.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToogleThemeComponent {
  isDarkThemeSelected!: boolean;
  constructor(
    private themePreferences: ThemePreferencesService,
    private cdr: ChangeDetectorRef
  ) {
    this.isDarkThemeSelected = this.themePreferences.isDarkTheme();
  }

  onCheckboxChange(event: any) {
    this.themePreferences.setActiveTheme(this.isDarkThemeSelected);
    this.cdr.detectChanges();
  }
}
