import { Injectable } from '@angular/core';
import { ScreeningService } from '@shared/services/api/trademap/v1/screening.service';
import { Observable } from 'rxjs';

@Injectable()
export class StockScreeningService {
  constructor(private screeningService: ScreeningService) {}

  public createRealTime(indicator: string): Observable<any> {
    return this.screeningService.save({ json: indicator });
  }

  public removeRealTime(id: number): Observable<any> {
    return this.screeningService.remove(id);
  }
}
