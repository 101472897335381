import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OrdersHistoryComponent } from './orders-history.component';
import { OrdersHistoryHeaderComponent } from './orders-history-header/orders-history-header.component';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { OrderTabsContextMenuComponent } from './grid/order-tabs-context-menu/order-tabs-context-menu.component';
import { RocketGridModule, RocketGridService } from '@shared/rocket-grid';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';
import { GenericFilterComponent } from './grid/generic-filter/generic-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditAlertModalComponent } from './modals/edit-alert-modal/edit-alert-modal.component';
import { RTInputCountModule } from '@shared/rocket-components/input-count/input-count.module';
import { AlertSettingsComponent } from './grid/alert-settings/alert-settings.component';
import { AlertTabContextMenuComponent } from './grid/alert-tab-context-menu/alert-tab-context-menu.component';
import { PositionTabContextMenuComponent } from './grid/position-tab-context-menu/position-tab-context-menu.component';
import {
  FlaCheckModule,
  FlaIconModule,
  FlaListGroupModule,
  FlaTabModule,
  FlaTagModule,
  RocketButtonModule,
  RocketModalModule,
} from '@shared/rocket-components/components/index';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ModalOrdersTabSettingsComponent } from './modals/modal-orders-tab-settings/modal-orders-tab-settings.component';
import { OrdersHistoryGridComponent } from './orders-history-grid/orders-history-grid.component';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { OrderHistoryHeaderConfigComponent } from './orders-history-header/header-config/header-config.component';
import { OrdersHistoryConfigService } from './service/orders-history-config.service';
import { OrdersHistoryCardListComponent } from './orders-history-grid/orders-history-card-list/orders-history-card-list.component';
import { OrdersHistoryTableViewComponent } from './orders-history-grid/orders-history-table-view/orders-history-table-view.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { StockLogoComponent } from '@shared/components/stock-table-views/components/stock-logo/stock-logo.component';
import { OrdersHistoryPositionCardComponent } from './orders-history-grid/orders-history-card-list/orders-history-position-card/orders-history-position-card.component';
import { HighchartModule } from '@shared/highchart/highchart.module';
import { OrdersHistoryOrderCardComponent } from './orders-history-grid/orders-history-card-list/orders-history-order-card/orders-history-order-card.component';
import { OrdersHistoryAlertCardComponent } from './orders-history-grid/orders-history-card-list/orders-history-alert-card/orders-history-alert-card.component';
import { CandleHorizontalComponent } from '@shared/highchart/candle-horizontal/candle-horizontal.component';
import { ResetAllPositionsComponent } from './reset-all-positions/reset-all-positions.component';
import { CountPipe } from '@shared/pipes/count-value.pipe';
import { OrdersHistoryTotalRowComponent } from './orders-history-grid/orders-history-total-row/orders-history-total-row.component';
import { NoInfoModule } from '@shared/components/business-profile/no-info/no-info.module';
import { OrdersHistoryService } from './service/orders-history.service';

@NgModule({
  imports: [
    RocketButtonModule,
    RocketModalModule,
    FlaTabModule,
    FlaWorkSpaceModule,
    FlaListGroupModule,
    FlaIconModule,
    FlaCheckModule,
    CommonModule,
    RocketGridModule,
    RTDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    RTInputCountModule,
    DragDropModule,
    TooltipsModule,
    ScrollingModule,
    StockLogoComponent,
    FlaTagModule,
    HighchartModule,
    CandleHorizontalComponent,
    CountPipe,
    NoInfoModule
  ],
  declarations: [
    OrdersHistoryComponent,
    OrdersHistoryHeaderComponent,
    OrderTabsContextMenuComponent,
    GenericFilterComponent,
    EditAlertModalComponent,
    AlertSettingsComponent,
    AlertTabContextMenuComponent,
    PositionTabContextMenuComponent,
    ModalOrdersTabSettingsComponent,
    OrdersHistoryGridComponent,
    OrderHistoryHeaderConfigComponent,
    OrdersHistoryCardListComponent,
    OrdersHistoryPositionCardComponent,
    OrdersHistoryOrderCardComponent,
    OrdersHistoryAlertCardComponent,
    OrdersHistoryTableViewComponent,
    OrdersHistoryTableViewComponent,
    ResetAllPositionsComponent,
    OrdersHistoryTotalRowComponent
  ],
  exports: [
    OrdersHistoryComponent,
    OrdersHistoryHeaderComponent,
    GenericFilterComponent,
  ],
  providers: [RocketGridService, OrdersHistoryConfigService, OrdersHistoryService],
})
export class OrdersHistoryCoreModule {}
