import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import {
  CategoryAxis,
  ICategoryAxisOptions,
  SciChartSurface,
  TSciChart,
} from 'scichart';
import { RocketDefaultAxisService } from './rocket-default-axis.service';

export class RocketCategoryAxis extends CategoryAxis {
  private defaultAxis!: RocketDefaultAxisService;
  constructor(
    private themeService: ThemePreferencesService,
    webAssemblyContext: TSciChart,
    options?: ICategoryAxisOptions
  ) {
    super(webAssemblyContext, options);
  }

  override onAttach(
    parentSurface: SciChartSurface,
    isXAxis: boolean,
    isPrimaryAxis: boolean
  ): void {
    this.defaultAxis = new RocketDefaultAxisService(
      this.themeService,
      this.labelStyle
    );
    this.defaultAxis.subscribeTheme();
    this.defaultAxis.setColor();
    super.onAttach(parentSurface, isXAxis, isPrimaryAxis);
  }

  override onDetach(): void {
    super.onDetach();
    this.defaultAxis.unsubscribeTheme();
  }
}
