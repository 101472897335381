import Adjust from '@adjustcom/adjust-web-sdk';
import { inject, Injectable } from '@angular/core';
import { ConfigService } from '@core/config';
import { AdjustAttribution } from './interface/adjust-attribution.interface';
import { debug, getBrowser, getOS } from 'src/app/utils/utils.functions';
import { APP_VERSION } from 'src/environments/version';

@Injectable({
  providedIn: 'root',
})
export class AdjustSdkService {
  private _config = inject(ConfigService);

  public initializeAdjustSdk(): void {
    try {
      Adjust.initSdk({
        appToken: this._config.getAjustKey(),
        environment: this._config.isDev() ? 'sandbox' : 'production',
        logLevel: this._config.isDev() ? 'info' : 'error',
      });
      debug(`ADJUST_INITIALIZED`);
    } catch (error) {
      debug(`ADJUST_ERROR ${error}`);
    }
  }

  public getAttribuitionInfos(): AdjustAttribution | undefined {
    const attr = Adjust.getAttribution();
    debug(JSON.stringify(attr));
    return attr;
  }

  public getEvents(
    deepLinkTrakerId: string,
    origin: 'NEW_LOGIN' | 'NEW_REGISTER'
  ) {
    const isDev = this._config.isDev();
    const config = this._config.getConfig();
    const nmChannel = 'ROCKETWEB';
    return {
      dhEvent: new Date().toISOString().split('.')[0],
      dsFunction: 'DEEP_LINK_ORIGIN',
      dsSubfunction: origin,
      dsParam: `idchannel:${config?.channel}|nmchannel:${nmChannel}|${deepLinkTrakerId}|`,
      idchannel: config?.channel,
      nmchannel: nmChannel,
      deepLink: deepLinkTrakerId,
      dsPlatform: `WEB|${isDev ? 'DEV' : 'PROD'}`,
      campaign: '',
      deviceInfoDTO: {
        phoneModel: '',
        deviceModel: '',
        appVersion: APP_VERSION.toString(),
        osName: `${getOS()} - ${getBrowser()}`,
      },
    };
  }

  public resetSdkIndexDBAfterSendEvent(): void {
    Adjust.stop();
    if (indexedDB.databases) {
      indexedDB.databases().then((db) => {
        if (db && db.length) {
          const adjustDB = db.find((db) => db.name === 'adjust-sdk');
          if (adjustDB?.name) {
            Adjust.stop();
            indexedDB.deleteDatabase(adjustDB?.name);
            return;
          }
          debug('adjust-sdk db not found');
        }
      });
    }
  }
}
