import { ColDef } from 'ag-grid-community';
import { formatterPercentageFields } from 'src/app/utils/cell-format.functions';
import { bigValueFormatter } from 'src/app/utils/utils.functions';
import { formatterNumber } from 'src/app/utils/utils.functions';
import { ActionButtonsCellRendererComponent } from '../parts/list/action-buttons-cell-renderer/action-buttons-cell-renderer.component';
import { StyleCellRendererComponent } from '../parts/list/style-cell-renderer/style-cell-renderer.component';
import {
  NumberComparator,
  OPTIONS_TYPES,
  OptionComparator,
  TCallPutOptions,
} from './options.types';

const checkValue = (
  params: any,
  formatter?: (params: any) => string,
  passParamsToFormatter?: boolean
) => {
  if (
    params.data.isCurrentPrice &&
    !params.data.cd_stock_call &&
    !params.data.cd_stock_put &&
    !params.value
  )
    return '-';

  return formatter
    ? formatter(passParamsToFormatter ? params : params.value)
    : params.value;
};

const default_options: Partial<ColDef> = {
  cellStyle: { textAlign: 'right' },
  sortable: true,
  sortingOrder: ['desc', 'asc'],
  hide: false,
};

export const OPTIONS_CALLPUT_CONFIG_HEADERS: { [key: string]: ColDef } = {
  label_call: {
    headerName: 'Opção',
    headerTooltip: 'Opção',
    field: 'label_call',
    width: 60,
    minWidth: 60,
    valueFormatter: (params: any) => {
      if (!params.data.isCurrentPrice && !params.data.cd_stock_call) {
        return '-';
      }
      return checkValue(params);
    },
    comparator: OptionComparator,
    ...default_options,
  },
  in_option_call: {
    headerName: 'Tipo',
    headerTooltip: 'Tipo',
    field: 'in_option_call',
    width: 38,
    minWidth: 38,
    valueGetter: (params: { data: TCallPutOptions }) => {
      return params.data.in_option_call
        ? OPTIONS_TYPES[params.data.in_option_call]
        : '';
    },
    cellClass: (params: any) =>
      params.value == 'Call' ? 'text-multibroker-buy' : 'text-multibroker-sell',
    ...default_options,
    hide: true,
  },
  strike_state_call: {
    headerName: 'Status',
    headerTooltip: 'Status',
    field: 'strike_state_call',
    width: 52,
    minWidth: 52,
    ...default_options,
    hide: true,
  },
  kind_option_call: {
    headerName: 'Estilo',
    field: 'kind_option_call',
    width: 46,
    minWidth: 46,
    maxWidth: 46,
    headerTooltip: 'Estilo',
    tooltipValueGetter: (params) => `Estilo: ${params.value}`,
    cellRenderer: StyleCellRendererComponent,
    ...default_options,
  },
  uncovered_qty_call: {
    headerName: 'Desc',
    headerTooltip: 'Descoberto',
    field: 'uncovered_qty_call',
    width: 45,
    minWidth: 45,
    valueGetter: (params: { data: TCallPutOptions }) => {
      return params.data.uncovered_qty_call
        ? params.data.uncovered_qty_call
        : 0;
    },
    valueFormatter: (params: any) =>
      formatterNumber(+params.value, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
    comparator: NumberComparator,
    ...default_options,
    hide: true,
  },
  covered_qty_call: {
    headerName: 'Cob',
    headerTooltip: 'Coberto',
    field: 'covered_qty_call',
    width: 45,
    minWidth: 45,
    valueGetter: (params: { data: TCallPutOptions }) => {
      return params.data.covered_qty_call ? params.data.covered_qty_call : 0;
    },
    valueFormatter: (params: any) =>
      formatterNumber(+params.value, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
    comparator: NumberComparator,
    ...default_options,
    hide: true,
  },
  day_variation_call: {
    headerName: 'Var',
    headerTooltip: 'Variação',
    field: 'day_variation_call',
    width: 50,
    minWidth: 45,
    headerComponentParams: ['Preços', 'day_variation'],
    valueFormatter: (params: any) =>
      checkValue(params, formatterPercentageFields, true),
    comparator: NumberComparator,
    ...default_options,
    cellClass: (params: any) =>
      +params.value >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
  },
  vl_vol_implicitly_call: {
    headerName: 'V.I.',
    headerTooltip: 'Vol. Implicita',
    field: 'vl_vol_implicitly_call',
    width: 40,
    minWidth: 40,
    headerComponentParams: ['Variações', 'vl_vol_implicitly'],
    valueFormatter: (params: any) =>
      checkValue(params, formatterPercentageFields, true),
    comparator: NumberComparator,
    ...default_options,
  },
  vl_delta_call: {
    headerName: 'Delta',
    headerTooltip: 'Delta',
    field: 'vl_delta_call',
    width: 48,
    minWidth: 40,
    maxWidth: 48,
    headerComponentParams: ['Variações', 'vl_delta'],
    valueFormatter: (params: any) =>
      checkValue(params, (value: any) => {
        return formatterNumber(+value, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
      }),
    comparator: NumberComparator,
    ...default_options,
    cellClass: (params: any) =>
      +params.value >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
  },
  qt_trades_call: {
    headerName: 'Neg',
    headerTooltip: 'Negócios',
    field: 'qt_trades_call',
    width: 40,
    minWidth: 40,
    maxWidth: 40,
    valueFormatter: (params: any) => checkValue(params, bigValueFormatter),
    comparator: NumberComparator,
    ...default_options,
  },
  qt_share_traded_call: {
    headerName: 'Qtd.Neg',
    headerTooltip: 'Qtd. Negociada',
    field: 'qt_share_traded_call',
    width: 70,
    minWidth: 70,
    valueFormatter: (params: any) => checkValue(params, bigValueFormatter),
    comparator: NumberComparator,
    ...default_options,
  },
  valor_melhor_oferta_compra_call: {
    headerName: 'Bid',
    headerTooltip: 'Bid',
    headerClass: 'text-multibroker-buy',
    field: 'valor_melhor_oferta_compra_call',
    width: 45,
    minWidth: 37,
    maxWidth: 45,
    valueFormatter: (params: any) =>
      checkValue({ ...params, value: +params.value }, formatterNumber),
    comparator: NumberComparator,
    ...default_options,
  },
  valor_melhor_oferta_venda_call: {
    headerName: 'Ask',
    headerTooltip: 'Ask',
    headerClass: 'text-multibroker-sell',
    field: 'valor_melhor_oferta_venda_call',
    width: 45,
    minWidth: 37,
    maxWidth: 45,
    valueFormatter: (params: any) =>
      checkValue({ ...params, value: +params.value }, formatterNumber),
    cellStyle: { textAlign: 'right' },
    comparator: NumberComparator,
    ...default_options,
  },
  vl_close_call: {
    headerName: 'Preço',
    headerTooltip: 'Preço',
    field: 'vl_close_call',
    width: 50,
    minWidth: 50,
    valueFormatter: (params: any) =>
      checkValue(params, (value: any) => formatterNumber(+value)),
    comparator: NumberComparator,
    ...default_options,
    hide: true,
  },
  vl_gamma_call: {
    headerName: 'Gamma',
    headerTooltip: 'Gamma',
    field: 'vl_gamma_call',
    width: 56,
    minWidth: 45,
    headerComponentParams: ['Variações', 'vl_gamma_call'],
    valueFormatter: (params: any) =>
      checkValue(params, (value: any) =>
        formatterNumber(+value, {
          maximumFractionDigits: 4,
          minimumFractionDigits: 4,
        })
      ),
    cellClass: (params: any) =>
      +params.value >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
    comparator: NumberComparator,
    ...default_options,
    hide: true,
  },
  vl_theta_call: {
    headerName: 'Theta',
    headerTooltip: 'Theta',
    field: 'vl_theta_call',
    width: 50,
    minWidth: 48,
    headerComponentParams: ['Variações', 'vl_theta_call'],
    valueFormatter: (params: any) =>
      checkValue(params, (value: any) =>
        formatterNumber(+value, {
          maximumFractionDigits: 4,
          minimumFractionDigits: 4,
        })
      ),
    cellClass: (params: any) =>
      +params.value >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
    comparator: NumberComparator,
    ...default_options,
    hide: true,
  },
  vl_rho_call: {
    headerName: 'Rho',
    headerTooltip: 'Rho',
    field: 'vl_rho_call',
    width: 46,
    minWidth: 38,
    headerComponentParams: ['Variações', 'vl_var_um_mes'],
    valueFormatter: (params: any) =>
      checkValue(params, (value: any) =>
        formatterNumber(+value, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      ),
    cellClass: (params: any) =>
      +params.value >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
    comparator: NumberComparator,
    ...default_options,
    hide: true,
  },
  vl_vega_call: {
    headerName: 'Vega',
    headerTooltip: 'Vega',
    field: 'vl_vega_call',
    width: 46,
    minWidth: 42,
    headerComponentParams: ['Variações', 'vl_vega_call'],
    valueFormatter: (params: any) =>
      checkValue(params, (value: any) =>
        formatterNumber(+value, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      ),
    cellClass: (params: any) =>
      +params.value >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
    comparator: NumberComparator,
    ...default_options,
    hide: true,
  },
  volume_call: {
    headerName: 'Vol',
    headerTooltip: 'Volume',
    field: 'volume_call',
    width: 50,
    minWidth: 50,
    cellStyle: { textAlign: 'right' },
    sortable: true,
    resizable: false,
    valueFormatter: (params: any) =>
      checkValue(params, (value: any) =>
        formatterNumber(+value, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })
      ),
    comparator: NumberComparator,
    sortingOrder: ['asc', 'desc'],
    hide: true,
  },
  strike_price: {
    headerName: 'Strike',
    headerTooltip: 'Strike',
    field: 'strike_price',
    width: 82,
    minWidth: 82,
    valueFormatter: (params: any) => formatterNumber(+params.value),
    comparator: NumberComparator,
    headerClass: 'text-center',
    tooltipField: 'strike_price',
    ...default_options,
    cellClass: (params) => {
      const currentPriceClass =
        params.data.cd_stock_call && params.data.cd_stock_put
          ? 'bg-neutral-smooth text-neutral-strongest'
          : 'bg-brand-primary';
      return params.data.isCurrentPrice
        ? `${currentPriceClass} text-center`
        : 'bg-neutral-strong text-center';
    },
  },
  volume_put: {
    headerName: 'Vol',
    headerTooltip: 'Volume',
    field: 'volume_put',
    width: 50,
    minWidth: 50,
    cellStyle: { textAlign: 'right' },
    sortable: true,
    resizable: false,
    valueFormatter: (params: any) =>
      checkValue(params, (value: any) =>
        formatterNumber(+value, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })
      ),
    comparator: NumberComparator,
    sortingOrder: ['asc', 'desc'],
    hide: true,
  },
  vl_vega_put: {
    headerName: 'Vega',
    headerTooltip: 'Vega',
    field: 'vl_vega_put',
    width: 46,
    minWidth: 42,
    headerComponentParams: ['Variações', 'vl_vega_put'],
    valueFormatter: (params: any) =>
      checkValue(params, (value: any) =>
        formatterNumber(+value, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      ),
    cellClass: (params: any) =>
      +params.value >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
    comparator: NumberComparator,
    ...default_options,
    hide: true,
  },
  vl_rho_put: {
    headerName: 'Rho',
    headerTooltip: 'Rho',
    field: 'vl_rho_put',
    width: 46,
    minWidth: 38,
    headerComponentParams: ['Variações', 'vl_var_um_mes'],
    valueFormatter: (params: any) =>
      checkValue(params, (value: any) =>
        formatterNumber(+value, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      ),
    cellClass: (params: any) =>
      +params.value >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
    comparator: NumberComparator,
    ...default_options,
    hide: true,
  },
  vl_theta_put: {
    headerName: 'Theta',
    headerTooltip: 'Theta',
    field: 'vl_theta_put',
    width: 50,
    minWidth: 48,
    headerComponentParams: ['Variações', 'vl_theta_put'],
    valueFormatter: (params: any) =>
      checkValue(params, (value: any) =>
        formatterNumber(+value, {
          maximumFractionDigits: 4,
          minimumFractionDigits: 4,
        })
      ),
    cellClass: (params: any) =>
      +params.value >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
    comparator: NumberComparator,
    ...default_options,
    hide: true,
  },
  vl_gamma_put: {
    headerName: 'Gamma',
    headerTooltip: 'Gamma',
    field: 'vl_gamma_put',
    width: 56,
    minWidth: 45,
    headerComponentParams: ['Variações', 'vl_gamma_put'],
    valueFormatter: (params: any) =>
      checkValue(params, (value: any) =>
        formatterNumber(+value, {
          maximumFractionDigits: 4,
          minimumFractionDigits: 4,
        })
      ),
    cellClass: (params: any) =>
      +params.value >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
    comparator: NumberComparator,
    ...default_options,
    hide: true,
  },
  vl_close_put: {
    headerName: 'Preço',
    headerTooltip: 'Preço',
    field: 'vl_close_put',
    width: 50,
    minWidth: 50,
    valueFormatter: (params: any) =>
      checkValue(params, (value: any) => formatterNumber(+value)),
    comparator: NumberComparator,
    ...default_options,
    hide: true,
  },
  valor_melhor_oferta_venda_put: {
    headerName: 'Ask',
    headerTooltip: 'Ask',
    headerClass: 'text-multibroker-sell',
    field: 'valor_melhor_oferta_venda_put',
    width: 45,
    minWidth: 37,
    maxWidth: 45,
    valueFormatter: (params: any) =>
      checkValue({ ...params, value: +params.value }, formatterNumber),
    comparator: NumberComparator,
    ...default_options,
  },
  valor_melhor_oferta_compra_put: {
    headerName: 'Bid',
    headerTooltip: 'Bid',
    headerClass: 'text-multibroker-buy',
    field: 'valor_melhor_oferta_compra_put',
    width: 45,
    minWidth: 37,
    maxWidth: 45,
    valueFormatter: (params: any) =>
      checkValue({ ...params, value: +params.value }, formatterNumber),
    comparator: NumberComparator,
    ...default_options,
  },
  qt_share_traded_put: {
    headerName: 'Qtd.Neg',
    headerTooltip: 'Qtd. Negociada',
    field: 'qt_share_traded_put',
    width: 70,
    minWidth: 70,
    valueFormatter: (params: any) => checkValue(params, bigValueFormatter),
    comparator: NumberComparator,
    ...default_options,
  },
  qt_trades_put: {
    headerName: 'Neg',
    headerTooltip: 'Negócios',
    field: 'qt_trades_put',
    width: 40,
    minWidth: 40,
    maxWidth: 40,
    valueFormatter: (params: any) => checkValue(params, bigValueFormatter),
    comparator: NumberComparator,
    ...default_options,
  },
  vl_delta_put: {
    headerName: 'Delta',
    headerTooltip: 'Delta',
    field: 'vl_delta_put',
    width: 48,
    minWidth: 40,
    maxWidth: 48,
    headerComponentParams: ['Variações', 'vl_delta'],
    valueFormatter: (params: any) =>
      checkValue(params, (value: any) =>
        formatterNumber(+value, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      ),
    comparator: NumberComparator,
    ...default_options,
    cellClass: (params: any) =>
      +params.value >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
  },
  vl_vol_implicitly_put: {
    headerName: 'V.I.',
    headerTooltip: 'Vol. Implicita',
    field: 'vl_vol_implicitly_put',
    width: 40,
    minWidth: 40,
    headerComponentParams: ['Variações', 'vl_vol_implicitly'],
    valueFormatter: (params: any) =>
      checkValue(params, formatterPercentageFields, true),
    comparator: NumberComparator,
    ...default_options,
  },
  day_variation_put: {
    headerName: 'Var',
    headerTooltip: 'Variação',
    field: 'day_variation_put',
    width: 50,
    minWidth: 45,
    headerComponentParams: ['Preços', 'day_variation'],
    valueFormatter: (params: any) =>
      checkValue(params, formatterPercentageFields, true),
    comparator: NumberComparator,
    ...default_options,
    cellClass: (params: any) =>
      +params.value >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
  },
  covered_qty_put: {
    headerName: 'Cob',
    headerTooltip: 'Coberto',
    field: 'covered_qty_put',
    width: 45,
    minWidth: 45,
    valueGetter: (params: { data: TCallPutOptions }) => {
      return params.data.covered_qty_put ? params.data.covered_qty_put : 0;
    },
    valueFormatter: (params: any) =>
      formatterNumber(+params.value, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
    comparator: NumberComparator,
    ...default_options,
    hide: true,
  },
  uncovered_qty_put: {
    headerName: 'Desc',
    headerTooltip: 'Descoberto',
    field: 'uncovered_qty_put',
    width: 45,
    minWidth: 45,
    valueGetter: (params: { data: TCallPutOptions }) => {
      return params.data.uncovered_qty_put ? params.data.uncovered_qty_put : 0;
    },
    valueFormatter: (params: any) =>
      formatterNumber(+params.value, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
    comparator: NumberComparator,
    ...default_options,
    hide: true,
  },
  kind_option_put: {
    headerName: 'Estilo',
    field: 'kind_option_put',
    width: 46,
    minWidth: 46,
    maxWidth: 46,
    headerTooltip: 'Estilo',
    tooltipValueGetter: (params) => `Estilo: ${params.value}`,
    cellRenderer: StyleCellRendererComponent,
    ...default_options,
  },
  strike_state_put: {
    headerName: 'Status',
    headerTooltip: 'Status',
    field: 'strike_state_put',
    width: 52,
    minWidth: 52,
    ...default_options,
    hide: true,
  },
  in_option_put: {
    headerName: 'Tipo',
    headerTooltip: 'Tipo',
    field: 'in_option_put',
    width: 38,
    minWidth: 38,
    valueGetter: (params: { data: TCallPutOptions }) => {
      return params.data.in_option_put
        ? OPTIONS_TYPES[params.data.in_option_put]
        : '';
    },
    cellClass: (params: any) =>
      params.value == 'Call' ? 'text-multibroker-buy' : 'text-multibroker-sell',
    ...default_options,
    hide: true,
  },
  label_put: {
    headerName: 'Opção',
    headerTooltip: 'Opção',
    field: 'label_put',
    width: 60,
    minWidth: 60,
    valueFormatter: (params: any) => {
      if (!params.data.isCurrentPrice && !params.data.cd_stock_put) {
        return '-';
      }
      return checkValue(params);
    },
    comparator: OptionComparator,
    ...default_options,
  },
};

export const OPTIONS_CALLPUT_CONFIG_HEADERS_FIXED_COLUNS: (
  width: number
) => Array<ColDef> = (width: number) => [
  {
    field: 'option_last_cell',
    resizable: false,
    suppressSizeToFit: true,
    headerName: '',
    flex: 1,
    headerClass: 'option-last-cell',
    minWidth: 0,
  },
  {
    field: 'id_stock',
    resizable: false,
    suppressSizeToFit: true,
    headerName: 'Action Buttons',
    minWidth: 0,
    width,
    pinned: 'right',
    cellClass: 'action-buttons',
    cellRenderer: ActionButtonsCellRendererComponent,
  },
];
