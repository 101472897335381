export const formatMsDateToHumanDate = (
  dateMs: number,
  showTimer: boolean = true,
  showDate: boolean = true
): string => {
  const date = new Date(dateMs);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().substring(-2);
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  const withoutTimer = date.getHours() == 0 && date.getMinutes() == 0;
  const preffixTimer = showTimer ? ' às ' : '';
  const timer =
    showTimer && !withoutTimer ? `${preffixTimer}${hour}h${minute}` : '';
  const dt = showDate ? `${day}/${month}/${year}` : '';
  return `${dt}${timer}`;
};

export const span = (text: string, css: string = 'fw-bold'): string => {
  return `<span class='${css}'>${text}</span>`;
};

export const div = (text: string, css: string = 'fs-7'): string => {
  return `<div class="${css}">${text}</div>`;
};
