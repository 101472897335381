<rocket-modal>
  <rocket-modal-header>
    <h2 class="modal-title">Duplicar Lista</h2>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <form [formGroup]="duplicateForm" class="px-2">
      <div class="row">
        <div class="col-16">
          <fla-input
            css="margin-bottom"
            formControlName="name"
            placeholder="Nome da lista"
            requiredText="Preencha o campo."
            type="text"
          >
          </fla-input>
        </div>
      </div>
    </form>
  </app-rocket-modal-body>
  <rocket-modal-footer>
    <rocket-button
      type="fill"
      label="Cancelar"
      css="mr-4"
      (rocketClick)="onClose()"
    ></rocket-button>
    <rocket-button
      label="Salvar"
      [loading]="isLoading"
      [isDisabled]="!duplicateForm.valid"
      (rocketClick)="onSave()"
    ></rocket-button>
  </rocket-modal-footer>
</rocket-modal>
