import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';
import { BaseContextMenuComponent } from '@shared/rocket-components/base-context-menu';

export enum HeatmapContextMenuEvent {}

@Component({
  selector: 'app-heatmap-context-menu',
  template: `
    <div
      class="round bg-neutral-strong position-absolute"
      [style.top.px]="position.top"
      [style.left.px]="position.left"
      [style.z-index]="9999"
      [style.width]="'200px'"
      [style.padding]="'10px'"
      [style.display]="'block'"
      (mouseleave)="onFocusOut()"
    >
      <div class="list-group list-group-flush">
        <div class="header-context-menu">
          <span>Mapa de mercado</span>
        </div>

        <button
          type="button"
          class="list-group-item list-group-item-action border-none fs-6 context-item-button"
          disabled
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="refresh"
          ></fla-icon>
          Restaurar
        </button>
        <button
          type="button"
          class="list-group-item list-group-item-action border-none fs-6 context-item-button"
          disabled
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="help_outline"
          ></fla-icon>
          Ajuda
        </button>
      </div>
    </div>
  `,
})
export class HeatmapContextMenuComponent extends BaseContextMenuComponent {
  show = false;
  parent_id!: string;

  constructor(
    elementRef: ElementRef,
    cdr: ChangeDetectorRef,
    private contextMenuService: ContextMenuService
  ) {
    super(elementRef, cdr);
  }

  static openContextMenu(
    contextMenuService: ContextMenuService,
    parent_id: string,
    event: { clientX: number; clientY: number }
  ) {
    contextMenuService.openContextMenu(parent_id, {
      type: 'HeatmapContextMenuComponent',
      event: event,
      configurationData: {},
    });
  }

  private emitEvent(action: HeatmapContextMenuEvent) {
    this.contextMenuService.emitEvent(this.parent_id, action);
  }

  onFocusOut() {
    this.contextMenuService.closeContextMenu(this.parent_id);
  }
}
