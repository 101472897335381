/* eslint-disable @angular-eslint/component-selector */
import {
  Component,
  Input,
  OnChanges,
  ChangeDetectorRef,
  AfterViewInit,
  TemplateRef,
  EventEmitter,
  ViewChild,
  Output,
} from '@angular/core';
import { FlaTTab } from '../types/tab.type';

@Component({
  selector: 'fla-tab-header[ref][title]',
  templateUrl: './fla-tab-header.component.html',
})
export class FlaTabHeaderComponent implements AfterViewInit, OnChanges {
  @ViewChild(TemplateRef) content!: TemplateRef<any>;
  @Input() type!: FlaTTab;
  @Input('ref') refId!: string;
  @Input() title!: string;
  @Input() disabled: boolean = false;
  @Input() showDot: boolean = false;
  @Input() active: boolean = false;
  @Input() enableContent: boolean = false;
  @Input() tooltip!: string;
  @Input() css: string = '';
  @Input() cssDot: string = '';
  @Output() flaChange: EventEmitter<string> = new EventEmitter();

  constructor(public cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnChanges(): void {
    this.cdr.detectChanges();
  }
}
