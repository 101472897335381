import { ComponentRef } from '@angular/core';
import { RocketCreateComponentService } from '@shared/rocket-components/services';
import { setPositionContextMenu } from 'src/app/utils/utils.functions';
import { StockTradeContextMenuComponent } from './stock-trade-context-menu.component';

export class StockTradeContextMenuConfig {
  private contextComponent!: StockTradeContextMenuComponent;
  private element!: HTMLElement;
  private componentRef!: ComponentRef<StockTradeContextMenuComponent>;

  constructor(private createComponent: RocketCreateComponentService) {}

  public showConfig(value: any): void {
    this._destroyComponent();
    const create = this.createComponent.create(StockTradeContextMenuComponent);
    this.componentRef = create.componentRef;
    this.contextComponent = this.componentRef.instance;
    this.element = create.rootNodeElement as HTMLElement;
    this.contextComponent.onClose.subscribe(this._destroyComponent);
    this._bindValues(value);
  }

  private _bindValues(value: any) {
    const infos = {
      innerHeight: value.event.view.innerHeight,
      innerWidth: value.event.view.innerWidth,
      pageY: value.event.pageY,
      pageX: value.event.pageX,
      height: 208,
      width: 200,
    };
    const { left, top } = setPositionContextMenu(infos);
    this.contextComponent.position = { left, top };
    this.contextComponent.show = value.show;
    this.contextComponent.isTour = value.isTour;
    this.contextComponent.handleTour = value.tourCallback;
    document.body.append(this.element);
  }

  private _destroyComponent() {
    const element = document.querySelectorAll('stock-trade-context-menu');
    if (element) element.forEach((elem) => elem.remove());
    if (this.componentRef) this.createComponent.destroy(this.componentRef);
  }
}
