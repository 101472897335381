import { Component, OnDestroy, OnInit } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { RankingBrokerService } from '../../services/ranking-broker.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  template: `<div>
    <app-check
      [isChecked]="stateChecked"
      (flaChange)="changeCheckAll()"
    ></app-check>
  </div>`,
  styles: [
    `
      div {
        height: 19px;
        font-size: 12px;
      }
    `,
  ],
})
export class SelectAllHeaderGridComponent
  implements IHeaderAngularComp, OnInit, OnDestroy
{
  public stateChecked = false;
  private destroy$ = new Subject<void>();
  private ref!: string;

  constructor(private _rankingBrokerService: RankingBrokerService) {}

  ngOnInit(): void {
    if (
      !this.ref ||
      this.ref === this._rankingBrokerService.selectAllBrokers$.value.ref
    ) {
      this.ref = this._rankingBrokerService.selectAllBrokers$.value.ref;
      this.stateChecked =
        this._rankingBrokerService.selectAllBrokers$.value.selectedAll;
    }
    this._rankingBrokerService.selectAllBrokers$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: { ref: string; selectedAll: boolean }) => {
        if (this.ref === value.ref) {
          this.stateChecked = value.selectedAll;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  agInit(headerParams: IHeaderParams): void {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  refresh(params: IHeaderParams<any>): any {}

  public changeCheckAll() {
    this.stateChecked = !this.stateChecked;
    this._rankingBrokerService.selectAllBrokers$.next({
      ref: this.ref,
      selectedAll: this.stateChecked,
    });
  }
}
