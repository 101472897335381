import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IScreeningFilter } from '../stock-screening/interfaces/indicator.interface';
import { GraphicScreeningService } from './graphic-screening.service';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { LinkSubscription } from '@shared/services/core/subscription/link.subscription';
import { ISearchStock } from '@core/interface';
import { GraphicScreeningHeaderComponent } from './header/graphic-screening-header.component';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { ActivatedRoute } from '@angular/router';
import { StockServiceRT } from '@shared/services/api/nitro-ws/v1/stock.service';

@Component({
  selector: 'app-graphic-screening',
  templateUrl: './graphic-screening.component.html',
  styleUrls: ['./graphic-screening.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphicScreeningComponent
  extends RocketComponentBase
  implements OnInit, OnChanges
{
  @Input() component!: any;
  @Input() headerOptions!: any;
  @ViewChild('graphicHeader') header!: GraphicScreeningHeaderComponent;
  public filters!: { filterString: string; filter: IScreeningFilter };
  public selectStock!: string;
  public idExchange!: string;
  public interval!: number;
  public intervalName: string = '';
  public filterName: string = '';
  public listPoints!: any;
  public infosTickSize!: any;
  public isRetart: boolean = false;

  private globalInstanceLink!: any;

  constructor(
    private graphicScreeningService: GraphicScreeningService,
    private stockService: StockServiceRT,
    private globalStock: GlobalSelectedStockSubscription,
    private linkDoc: LinkSubscription,
    private cdr: ChangeDetectorRef,
    activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  protected initComponent(component: any): void {
    if (component) this.component = component;

    this.filters = this.component.metadata.component.filters;
    const intervalChart = JSON.parse(this.filters.filter.intervalChart);
    this.interval = +intervalChart.id;
    const tempStock = this.filters.filter.stocks[0];
    !!this.filters.filter.tickSize &&
      (this.infosTickSize = this.filters.filter.tickSize[tempStock]);
    this.filterName = this.filters.filter.name;
    this.intervalName = intervalChart.name;
    this.idExchange = tempStock.split(':').pop()!;
    this.selectStock = tempStock.split(':').shift()!;
    this.cdr.detectChanges();
    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { headerOptions } = changes;
    headerOptions?.currentValue && this.initGlobalLinked();
  }

  private setGlobalStockSelected = (stock: string) => {
    if (!this.headerOptions.link) return;
    const cdStock = stock;
    this.stockService
      .searchStock(cdStock)
      .subscribe((stocks: ISearchStock[]) => {
        this.globalStock.changeGlobalStock(stocks[0]);
      });
  };

  public changeTab(stock: string, globalStockChanged: boolean = false): void {
    if (stock === this.selectStock) return;
    !!this.filters.filter.tickSize &&
      (this.infosTickSize = this.filters.filter.tickSize[stock]);
    this.idExchange = stock.split(':').pop()!;
    this.selectStock = stock.split(':').shift()!;
    !globalStockChanged && this.setGlobalStockSelected(this.selectStock);
    this.cdr.detectChanges();
  }

  public refresh(restart: boolean = false): void {
    this.isRetart = restart;
    this.cdr.detectChanges();
    this.graphicScreeningService
      .backtest(this.filters.filterString)
      .subscribe((res: any) => {
        if (!res || !Object.keys(res).length) {
          this.isRetart = false;
          this.cdr.detectChanges();
          return;
        }
        let points = {};
        Object.keys(res).forEach((item: string) => {
          points = { ...points, [item]: res[item] };
        });
        this.listPoints = points;
        this.isRetart = false;
        this.cdr.detectChanges();
      });
  }

  private emitChangeTab = (stock: ISearchStock): void => {
    if (!stock || !this.headerOptions.link) return;
    const stocks = this.filters.filter.stocks;
    const selectedStock = `${stock.cd_stock}:${stock.id_exchange}`;
    const index = stocks.findIndex((item) => selectedStock === item);
    if (index > -1) {
      this.changeTab(selectedStock, true);
      this.header.selectTab(index, true);
    }
  };

  private initGlobalLinked(): void {
    //do nothing
    /*this.globalInstanceLink = new GlobalInstanceLink(
      this.globalStock,
      this.linkDoc,
      this.emitChangeTab,
      this.component.id,
      this.headerOptions
    );
    this.globalInstanceLink.onLinked();
    this.globalInstanceLink.subscribeGlobalStockChange();*/
  }
}
