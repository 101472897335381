import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { BrowserStorageBase } from '@shared/services/core/storage/browser-storage-base';
import { AUTH_SESSION_KEYS } from '@shared/services/core/const/auth_util.const';
import { TermsService } from '@shared/services/api/terms/v2/terms.service';
import { IModalTermsParams } from '@shared/services/api/terms/v2/interface/terms.interface';
import { Subscription, filter } from 'rxjs';
import { ModalTokenService } from '@core/layout/header/modal-token-config/modal-token.service';
import { NavigationEnd, Router } from '@angular/router';
import {
  TERMS_SUB_TITLE,
  getModalTermTitle,
  isRequiredTerm,
} from './constants/modal-accept-terms.constants';
import { OtpService } from '@shared/services/api/authentication/v3/otp.service';
import { ConfigService } from '@core/config';
import { sharedSessionStorage } from '@shared/services/core/storage/shared-session.storage';

@Component({
  selector: 'app-modal-accept-terms',
  templateUrl: './modal-accept-terms.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalAcceptTermsComponent
  extends RocketModalRef
  implements OnInit, OnDestroy
{
  private _storage = new BrowserStorageBase(sharedSessionStorage);

  public title!: string;
  public subTitle!: string;
  public name: string =
    this._storage
      .getItem(AUTH_SESSION_KEYS.USER_AUTHENTICATED)
      ?.investor?.name?.split(' ')[0] ?? '';

  public disableSubmitButton: boolean = true;
  public acceptedTerms: boolean = false;
  public loadingApprove: boolean = false;
  public isRequired: boolean = true;
  public invalid: boolean = false;
  public inputType: 'text' | 'password' = 'password';
  public visibilityIcon: 'visibility_off' | 'visibility' = 'visibility_off';
  public tokenErrorMessage!: string | null;

  public loading: boolean = true;
  public isDev: boolean;
  public token!: string | null;

  private _subs = new Subscription();

  @HostListener('document:keydown.enter', ['$event'])
  onKeyEnterPress() {
    if (this.token && this.acceptedTerms) this.acceptTerms();
  }

  constructor(
    service: RocketModalService,
    @Inject(ROCKET_MODAL_DATA) public data: IModalTermsParams,
    private _cdr: ChangeDetectorRef,
    private _termService: TermsService,
    private _otpService: OtpService,
    private _modalTokenService: ModalTokenService,
    private _router: Router,
    private config: ConfigService
  ) {
    super(service);
    this.isDev = this.config.isDev();
    const route = this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => this.onClose(false));
    this._subs.add(route);
  }

  ngOnInit() {
    this.isRequired = !this.data.isBrokerTerm && isRequiredTerm(this.data.code);
    this.setModalTexts();
    this.loadConfig();
    this._refreshConfig();
    this._cdr.detectChanges();
  }

  private setModalTexts(): void {
    const type = this.data.isBrokerTerm ? 'BROKER' : 'PERSON';
    this.title = getModalTermTitle(type, this.name, this.data.brokerName);
    this.subTitle = TERMS_SUB_TITLE[type];
  }

  public toggleVisibility(): void {
    if (this.inputType === 'password') {
      this.inputType = 'text';
      this.visibilityIcon = 'visibility';
      return;
    }
    this.inputType = 'password';
    this.visibilityIcon = 'visibility_off';
  }

  public loadConfig = () => {
    this.loading = true;
    this._cdr.detectChanges();
    const _sub = this._otpService.userTokenPreferences().subscribe((data) => {
      if (data === 'UNDEFINED_TOKEN') {
        this.loading = false;
        this.token = null;
        this.acceptedTerms = false;
        this._cdr.detectChanges();
        return;
      }
    });
    this._subs.add(_sub);
  };

  private _refreshConfig(): void {
    const ref = this._modalTokenService.onReloadConfig$.subscribe(() => {
      this.loadConfig();
    });
    this._subs.add(ref);
  }

  public setToken(event: any): void {
    if (event.target.value?.length >= 100) return;
    if (this.invalid) {
      this.invalid = false;
      this.tokenErrorMessage = null;
    }
    this.token = event.target.value;
  }

  public onClose = (termAccepted: boolean): void => {
    this._subs.unsubscribe();
    this.close(termAccepted, false);
  };

  public acceptTerms(): void {
    this.loadingApprove = true;
    let idTerm = 1;
    if (this.data.idBrokerTerm) idTerm = this.data.idBrokerTerm;
    const termSub = this._termService
      .approveTerm(idTerm, this.token!, true)
      .subscribe({
        next: () => {
          this.onClose(true);
          this.loadingApprove = false;
        },
        error: (err) => {
          this.loadingApprove = false;
          this.invalid = true;
          this.tokenErrorMessage = err.message;
          this._cdr.detectChanges();
        },
      });
    this._subs.add(termSub);
  }
}
