import { Component, HostListener, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'stock-trade-context-menu',
  templateUrl: './stock-trade-context-menu.component.html',
})
export class StockTradeContextMenuComponent {
  @Input() show: boolean = false;
  @Input() position!: { top: number; left: number };

  public isTour: boolean = false;

  private _onClose$: Subject<boolean> = new Subject<boolean>();

  get onClose(): Observable<boolean> {
    return this._onClose$.asObservable();
  }

  constructor() {}

  ngOnInit() {}

  @HostListener('window:click')
  private close(): void {
    if (this.isTour) return;
    this._onClose$.next(true);
  }

  public handleTour(): void {
    console.log('START_TOUR');
  }
}
