<app-trade-idea-header
  [refComponent]="refComponent"
  [component]="component"
  (changeStock)="getTradeIdea($event)"
  (changeMenu)="changeMenu($event)"
></app-trade-idea-header>

<div *ngIf="isLoading; else endLoading" class="h-100 bg-neutral-stronger">
  <app-loading></app-loading>
</div>
<ng-template #endLoading>
  <div
    class="d-flex flex-column p-3 bg-neutral-stronger"
    [ngClass]="isDesktop ? 'component-height' : 'h-100'"
  >
    <span *ngIf="selectedHeader.code !== headerCodes.MY" class="mb-5">
      {{
        infoToShow && infoToShow.length
          ? defaultMsg
          : !selectedStock
          ? emptyMsg
          : emptyStockMsg
      }}</span
    >
    <div
      *ngIf="
        (infoToShow && infoToShow.length) ||
        selectedHeader.code === headerCodes.MY
      "
      class="d-flex height-body"
    >
      <div class="d-flex flex-column">
        <div
          class="d-flex justify-content-center"
          *ngIf="selectedHeader.code === headerCodes.MY"
        >
          <rocket-button
            css="fs-7 w-75"
            [isSmall]="true"
            label="Criar novo"
            type="outline"
            (rocketClick)="openTradeIdeaModal(false)"
          >
            <fla-icon size="icon-size-sm" name="add"></fla-icon>
          </rocket-button>
        </div>
        <div
          (scroll)="onScroll($event)"
          class="trades-idea d-flex flex-column gap-3 overflow-x-auto pr-3 py-1"
        >
          <div *ngFor="let item of infoToShow">
            <span
              [ngClass]="{
                'text-neutral-medium': item.isNotInfo,
                'cursor-pointer': !item.isNotInfo,
                'user-select-none': item.isNotInfo,
                'text-brand-primary':
                  selectedTradeIdea &&
                  selectedTradeIdea.id_trade_idea === item.id_trade_idea
              }"
              class="fw-bold fs-5"
              (click)="selectTradeIdea(item)"
              >{{ item.label }}</span
            >
          </div>
        </div>
      </div>

      <div class="info-trade-idea pl-3 w-100 ml-3 pl-3 border-left">
        <div
          *ngIf="selectedTradeIdea"
          class="d-flex flex-column justify-content-between h-100 overflow-x-auto"
        >
          <div class="d-flex flex-column">
            <span class="fw-bold"
              >{{ selectedTradeIdea.title }} |
              {{ selectedTradeIdea.subTitle }}</span
            >
            <span class="fw-bold pt-5">{{
              selectedTradeIdea.description
            }}</span>
            <span class="py-5">
              Preço entrada: {{ selectedTradeIdea.priceFormatted }}
            </span>
            <div class="d-flex">
              <span class="fw-bold"> Estratégia OCO </span>
            </div>
            <app-table-trade-idea-items
              [items]="selectedTradeIdea.strategy.items"
            ></app-table-trade-idea-items>
          </div>

          <div
            *ngIf="
              selectedHeader.code === headerCodes.ACTIVE;
              else myTradeIdeaFooter
            "
            class="d-flex mb-3 justify-content-end"
          >
            <div class="w-25">
              <app-input-count
                [formControl]="qttyControl"
                labelPlacement="horizontal"
                [isSmall]="true"
                margin="mb-0"
                label="Qtd:"
                [allowNegativeValue]="false"
                [incrementValue]="standardLot"
                [initialValue]="negotiationLot"
                [min]="standardLot"
                paddingSmallCustom="py-1"
                mask="separator.0"
                [enableScrollEvent]="true"
                [selectOnFocus]="true"
                css="qtty-input"
              ></app-input-count>
            </div>
            <div class="ml-3">
              <rocket-button
                css="fs-7 min-width-0 mr-3"
                [isSmall]="true"
                label="Alterar parâmetros"
                type="outline"
                (rocketClick)="openTradeIdeaModal(true)"
              >
                <fla-icon size="icon-size-sm" name="edit"></fla-icon>
              </rocket-button>
              <rocket-button
                css="fs-7 min-width-0"
                [isSmall]="true"
                label="Executar"
                (rocketClick)="executeTradeIdea()"
              ></rocket-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #myTradeIdeaFooter>
  <div
    *ngIf="selectedHeader.code === headerCodes.MY"
    class="d-flex mb-3 justify-content-end"
  >
    <rocket-button
      css="fs-7 min-width-0 mr-3 text-feedback-error border-feedback-error"
      [isSmall]="true"
      label="Apagar"
      type="outline"
      (rocketClick)="deleteTradeIdea()"
    >
    </rocket-button>
    <rocket-button
      css="fs-7 min-width-0"
      [isSmall]="true"
      label="Editar"
      (rocketClick)="openTradeIdeaModal(false, true)"
    ></rocket-button>
  </div>
</ng-template>
