import { Injectable } from '@angular/core';
import { Subject, auditTime } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InputCountService {
  private focusHandler$ = new Subject<{
    refId: string;
    withEvent: boolean;
  }>();
  private hotkeyPress$ = new Subject<{
    alt: boolean;
    shift: boolean;
  }>();
  private blurAllElements$ = new Subject<void>();

  get blurAllElements() {
    return this.blurAllElements$.pipe(auditTime(100));
  }

  get focusHandler() {
    return this.focusHandler$.pipe(auditTime(100));
  }

  get onHotkeyPress() {
    return this.hotkeyPress$;
  }

  dispatchFocusHandler(data: { refId: string; withEvent: boolean }) {
    this.focusHandler$.next(data);
  }

  hotkeyPress(data: { alt: boolean; shift: boolean }) {
    this.hotkeyPress$.next(data);
  }

  dispatchBlurAllElements() {
    this.blurAllElements$.next();
  }
}
