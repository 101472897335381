import { ErrorCode } from './error-code';

export class ApiError {
  code!: ErrorCode;
  text!: string;
}

export function getErrorMessage(error: any) {
  if (!error) return { msg: '', code: '' };
  const message = error instanceof Object ? error.message : error;
  if (!message) return { msg: '', code: '' };
  const splitMessage = message.split('%&');
  const msg = splitMessage[0];
  const code = splitMessage[1] || '';
  return { msg, code };
}

export const INTERNAL_ERROR_MESSAGE: any = {
  CHEETAH: '208',
  NITROWS: '201',
  TRADEMAP: '207',
  NOTDEFINED: '204',
};

export const normalizeServer = (value: string) =>
  value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();

export const isCheetahUrl = (url: string) => /^https?:\/\/cheetah/i.test(url);

export const getErrorCodeByServer = (url: string) => {
  if (isCheetahUrl(url)) {
    return INTERNAL_ERROR_MESSAGE.CHEETAH;
  }
  const regex = /api\/([^/]+)\/v/i;
  const match = url.match(regex);
  let def = INTERNAL_ERROR_MESSAGE.NOTDEFINED;
  if (match) {
    const server = normalizeServer(match[1]);
    def = INTERNAL_ERROR_MESSAGE[server];
  }
  return def;
};

export interface ErrorRequest {
  title: string;
  status: number;
  success: boolean;
  message: string;
  timestap: number;
  developerMessage: string;
  code: string;
}
