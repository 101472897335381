import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChange,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { StockService } from '@shared/services/api/trademap/v1/stock.service';
import {
  deepClone,
  formatterNumber,
  randomId,
} from '@shared/rocket-components/utils';
import {
  ISearchStock,
  IStockListItemsDynamic,
  IStockListItemsNewList,
} from '@core/interface';
import {
  execFormatFinancial,
  waitTimeout,
} from 'src/app/utils/utils.functions';
import { Subject, debounceTime } from 'rxjs';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { Dictionary } from '@core/models';
import { ChartService } from '@shared/services/api/trademap/V3/chart.service';
import { StockListService as StockListServiceV10 } from '@shared/services/api/trademap/V10/stock-list.service';
import { StockListItemsModel } from '@shared/components/stock-table/models/stock-list-items.model';
import { NEW_STOCK_MODAL_TABS } from '@shared/components/stock-table/constants/stock-table.constants';
import {
  DOWN_ARROW,
  ENTER,
  LEFT_ARROW,
  RIGHT_ARROW,
  UP_ARROW,
} from '@angular/cdk/keycodes';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { StockCardComponent } from '@shared/rocket-components/components/stock-card/stock-card.component';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { StockServiceRT } from '@shared/services/api/nitro-ws/v1/stock.service';
import { RocketStreamRead } from '@shared/channel/rx-event';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-most-search',
  templateUrl: './most-search.component.html',
  styleUrls: ['./most-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MostSearchComponent
  extends ReadStreamBase
  implements OnChanges, OnDestroy, AfterViewInit
{
  private componentId = randomId('most_search');
  private searchDynamic$ = new Subject<void>();
  objectKeys = Object.keys;
  @Output() searchAgain: EventEmitter<void> = new EventEmitter<void>();
  @Output() scrollEnd: EventEmitter<void> = new EventEmitter<void>();
  @Output() selected: EventEmitter<{
    listStocksSelected: any[];
    hashListStocksSelected: any;
    item: IStockListItemsNewList;
  }> = new EventEmitter<{
    listStocksSelected: any[];
    hashListStocksSelected: any;
    item: IStockListItemsNewList;
  }>();
  @Output() stockClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() set searchedStock(searchedStock: ISearchStock[]) {
    this.setItensScroll(searchedStock);
  }
  @Input() set listStocksSelected(listStocksSelected: Array<any>) {
    if (listStocksSelected) {
      this._listStocksSelected = listStocksSelected;
      this.hashListStocksSelected = this.reduceToObject(
        listStocksSelected,
        'cd_stock'
      );
      this.searchDynamic$.next();
    }
  }
  @Input() selectToStockList: boolean = true;
  @Input() height: string = '32vh';
  @Input() noData: boolean = false;
  @Input() loading = false;
  @Input() isSearching: boolean = false;
  @ViewChildren(StockCardComponent) items!: QueryList<StockCardComponent>;
  @ViewChild('scroll') scroll!: ElementRef;
  private _updateListTypeSubject = new Subject<void>();
  private _cheetahParams!: SubscribeParam | null;
  private _listStocksSelected: Array<any> = [];
  private _searchedStock: ISearchStock[] = [];
  private _quoteChannelStreaming!: RocketStreamRead;

  stockListItems: StockListItemsModel[] = [];
  stockListItemsAux: StockListItemsModel[] = [];
  hashListStocksSelected: any = {};
  hashToShow = new Dictionary<any>();
  vlCloseHistory: any = {};
  hashSearchedStock: any = {};
  defaultTabs = [
    NEW_STOCK_MODAL_TABS.MOST_SEARCHED,
    NEW_STOCK_MODAL_TABS.STOCKS,
    NEW_STOCK_MODAL_TABS.FUTURES,
    NEW_STOCK_MODAL_TABS.COINS,
    NEW_STOCK_MODAL_TABS.FEES,
    NEW_STOCK_MODAL_TABS.COMMODITIES,
    NEW_STOCK_MODAL_TABS.FIIS,
    NEW_STOCK_MODAL_TABS.INDEXES,
    NEW_STOCK_MODAL_TABS.ETFS,
    NEW_STOCK_MODAL_TABS.BDRS,
    NEW_STOCK_MODAL_TABS.BONDS,
  ];
  tabs = this.defaultTabs;
  tabNames = NEW_STOCK_MODAL_TABS;
  selectedTab = NEW_STOCK_MODAL_TABS.MOST_SEARCHED;
  public emptyStateMessage = '';
  private keyManager!: ActiveDescendantKeyManager<StockCardComponent>;
  public snapshotCallback!: (stocks: string[]) => void;
  private onDestroy$ = new Subject<void>();
  private onVerifyExistStockInList$ = new Subject<
    ISearchStock[] | StockListItemsModel[]
  >();
  private unGetVlClose$ = new Subject<void>();
  public disableArrowLeft = true;
  public disableArrowRight = false;

  get stockList() {
    return this.hashToShow.values();
  }

  constructor(
    private stockService: StockService,
    private stockServiceRT: StockServiceRT,
    private _quoteChannel: QuoteChannel,
    private _chartService: ChartService,
    private _stockListServiceV10: StockListServiceV10,
    private _cdr: ChangeDetectorRef
  ) {
    super();
    this._updateListTypeSubject
      .pipe(takeUntil(this.onDestroy$), debounceTime(200))
      .subscribe(this._onListTypeChange);
    this.searchDynamic$
      .pipe(takeUntil(this.onDestroy$), debounceTime(300))
      .subscribe(() => {
        !this.isSearching && this.getStockListItemsDynamic();
      });
    this.onVerifyExistStockInList$
      .pipe(takeUntil(this.onDestroy$), debounceTime(300))
      .subscribe((list: ISearchStock[] | StockListItemsModel[]) => {
        this.verifyExistStockInList(list);

        this.loading = false;
        this._cdr.detectChanges();
      });

    this.startStreaming();
  }

  ngOnDestroy() {
    this._destroy();
    if (this._cheetahParams)
      this._quoteChannel.unsubscribe(this._cheetahParams);

    this._quoteChannelStreaming && this._quoteChannelStreaming.close();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { searchedStock } = changes;
    if (searchedStock?.currentValue !== searchedStock?.previousValue) {
      this._setTabsOnSearchChange(searchedStock);
    }
  }

  ngAfterViewInit() {
    this.initKeyManager();
    this.items.changes
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.itemsChangeHandler);
  }

  startStreaming = async () => {
    if (!this._quoteChannelStreaming) {
      this._quoteChannelStreaming = await this._quoteChannel.readEvents();
      this.readStream(this._quoteChannelStreaming.stream, this.channelHandler);
    }
  };

  changeTab(tab: NEW_STOCK_MODAL_TABS) {
    this.selectedTab = tab;
    this._updateListTypeSubject.next();
  }

  private _onListTypeChange = () => {
    this.loading = true;
    this._cdr.detectChanges();
    if (this.selectedTab === NEW_STOCK_MODAL_TABS.MOST_SEARCHED) {
      this._startMostSearched();
      return;
    }
    this._loadGroupList();
  };

  private _setTabsOnSearchChange(searchChange: SimpleChange | undefined) {
    if (!searchChange) return;
    if (searchChange.currentValue.length || this.noData) {
      this.selectedTab = this.tabs[0];
      return;
    }
    this.tabs = this.defaultTabs;
    this.selectedTab = this.tabs[0];
    if (!this.selectToStockList) this.searchDynamic$.next();
  }

  private _destroy() {
    this.destroyCheeath();
    this._updateListTypeSubject.unsubscribe();
    this.unGetVlClose$.next();
    this.unGetVlClose$.complete();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private _startMostSearched() {
    this.hashToShow.clear();
    this.searchDynamic$.next();
    if (this.isSearching) {
      this.searchAgain.emit();
    }
  }

  private _loadGroupList() {
    this._stockListServiceV10
      .getStockListByGroup(this.selectedTab)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (stocks) => {
          if (!stocks.length) {
            this.destroyCheeath();
            this._emptyState(
              `Não há ativos disponíveis na lista ${this.selectedTab}.`
            );
            return;
          }
          this._processGetStockListReturn(stocks);
        },
        error: () => {
          this.destroyCheeath();
          this._emptyState(
            `Ocorreu um erro ao carregar os ativos da lista ${this.selectedTab}, tente novamente.`
          );
        },
      });
  }

  private _emptyState(message: string): void {
    this.stockListItems = [];
    this.stockListItemsAux = [];
    this.hashToShow.clear();
    this.loading = false;
    this.emptyStateMessage = message;
    this._cdr.detectChanges();
  }

  private getStockListItemsDynamic(): void {
    this.loading = true;
    this._cdr.detectChanges();
    this.stockService
      .getStockListItemsDynamic()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res: Array<IStockListItemsDynamic>) =>
        this._processGetStockListReturn(res)
      );
  }

  private _processGetStockListReturn(res: any) {
    if (res && res.length) {
      this.onVerifyExistStockInList$.next(res);
      this.stockListItemsAux = deepClone(res);
    } else {
      this.stockListItems = [];
    }
    this.stockListItems = [];
  }

  private verifyExistStockInList(list: any[]): void {
    if (!list) {
      return;
    }
    this.destroyCheeath();
    this.stockListItems = list.map((item) => {
      const listStock =
        this.hashListStocksSelected[item.cdStock || item.cd_stock];
      return new StockListItemsModel(item, !!listStock);
    });
    this.hashSearchedStock = this.getDictonary(this.stockListItems, [
      'cdStock',
      'idExchange',
    ]);
    this.hashToShow = this.hashSearchedStock;
    this._getVlClose();
    this.initCheeath();
  }

  private _getVlClose() {
    this.unGetVlClose$.next();
    this.unGetVlClose$.complete();
    const stocks = this.hashToShow
      .values()
      .map(
        (item: any) => `${item.cdStockOrder || item.cdStock}:${item.idExchange}`
      );
    this._chartService
      .vlClose(stocks)
      .pipe(takeUntil(this.unGetVlClose$))
      .subscribe((data: any) => {
        this.vlCloseHistory = {};
        this.hashToShow.values().forEach((item: any) => {
          this.vlCloseHistory[`${item.cdStock}:${item.idExchange}`] =
            data.close_limit_chart[
              `${item.cdStockOrder || item.cdStock}:${item.idExchange}`
            ];
        });
        this._cdr.detectChanges();
      });
  }

  public selectStock(item: IStockListItemsNewList): void {
    if (this.selectToStockList) {
      const listStock = this.hashListStocksSelected[item.cdStock];
      if (listStock) {
        this._listStocksSelected.splice(listStock.arrayIndex, 1);
        delete this.hashListStocksSelected[item.cdStock];
        item.isCheck = false;
      } else {
        const newLength = this._listStocksSelected.push({
          cd_stock: item.cdStock,
          id_exchange: item.idExchange,
          ds_asset: item.dsAsset,
          is_synonymous: item.isSynonymous,
          idRow: `${item.cdStock}:${item.idExchange}`,
          isNew: true,
        });
        this.hashListStocksSelected[item.cdStock] = {
          ...item,
          arrayIndex: newLength - 1,
        };
        item.isCheck = true;
      }
      this.selected.emit({
        listStocksSelected: this._listStocksSelected,
        hashListStocksSelected: this.hashListStocksSelected,
        item,
      });
    } else {
      if (this._searchedStock.length) {
        const selectedStock = this._searchedStock.find(
          (stock) =>
            item.cdStock === stock.cd_stock &&
            item.idExchange === stock.id_exchange
        );
        this.stockClicked.emit(selectedStock);
      } else this.getStockFromDynamicList(item.cdStock);
    }
  }

  private getStockFromDynamicList = (cdStock: string) => {
    let stock: ISearchStock;
    this.stockServiceRT
      .searchStock(cdStock)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((resp: ISearchStock[]) => {
        if (resp.length) stock = resp[0];
        this.stockClicked.emit(stock);
      });
  };

  private initCheeath() {
    this.configCheetah();
    if (this._cheetahParams) {
      this._quoteChannel.subscribe(this._cheetahParams);
      this._quoteChannelStreaming &&
        this._quoteChannelStreaming.snapshot(this._cheetahParams.itemsArray);
    }
  }

  private channelHandler = (payload: Map<string, any>) => {
    payload.forEach((data: any, key: string) => {
      if (this._cheetahParams?.itemsArray.includes(key)) {
        if (!data || data.isEndOfSnap) return;
        const key = data.item;
        const stock = this.hashToShow.has(key)
          ? this.hashToShow.get(key)
          : data;
        const { variacao_dia, preco_ultimo, arrow_font_hex, arrow_hex } = data;
        variacao_dia && (stock.dayVariation = formatterNumber(+variacao_dia));
        if (preco_ultimo) {
          stock.valueClose = preco_ultimo;
          if (!stock.formatPrice) {
            stock.formatPrice = {
              cd_segment: stock.cd_segment ?? null,
              id_exchange: stock.id_exchange,
              tick_size_denominat: stock.tick_size_denominator,
            };
          }
          stock.valueCloseFormatted = execFormatFinancial(
            stock.formatPrice,
            stock.valueClose
          );
        }
        arrow_font_hex && (stock.arrowFont = arrow_font_hex);
        arrow_hex && (stock.arrowHex = arrow_hex);
        this._cdr.detectChanges();
      }
    });
  };

  private destroyCheeath() {
    this._cheetahParams && this._quoteChannel.unsubscribe(this._cheetahParams);
  }

  private configCheetah() {
    const listStock = this.stockListItems.map(
      (item) => `${item.cdStock}:${item.idExchange}`
    );

    if (!listStock || !listStock.length) {
      this._cheetahParams = null;
      return;
    }

    const qouteParams: SubscribeParam = {
      itemsArray: listStock,
      header: this.componentId,
    };
    this._cheetahParams = qouteParams;
  }

  private reduceToObject(info: any[], key: string | string[]) {
    const { k1, k2 } = this.getKeys(key);
    return info.reduce(
      (accumulator: any, currentValue: any, index: number) => ({
        ...accumulator,
        [`${currentValue[k1]}${k2 ? `:${currentValue[k2]}` : ''}`]: {
          ...currentValue,
          arrayIndex: index,
        },
      }),
      {}
    );
  }

  private getDictonary(info: any[], key: string | string[]): Dictionary<any> {
    const dictionary = new Dictionary<any>();
    const { k1, k2 } = this.getKeys(key);
    info.forEach((data, index) => {
      dictionary.set(`${data[k1]}${k2 ? `:${data[k2]}` : ''}`, {
        ...data,
        arrayIndex: index,
      });
    });
    return dictionary;
  }

  private getKeys(key: string | string[]): {
    k1: string;
    k2: string | undefined;
  } {
    let k1 = key.toString(),
      k2: string | undefined = undefined;
    if (Array.isArray(key)) {
      k1 = key[0];
      k2 = key[1];
    }
    return { k1, k2 };
  }

  @HostListener('window:keydown', ['$event'])
  onKeydown = (event: KeyboardEvent | any) => {
    if (event.keyCode === ENTER) {
      const index: any = this.keyManager.activeItemIndex;
      const item = this.stockList[index];
      item && this.selectStock(item);
      event.preventDefault();
    } else if ([UP_ARROW, DOWN_ARROW].includes(event.keyCode)) {
      this.keyManager.activeItem?.setInactiveStyles();
      this.keyManager.onKeydown(event);
      this.keyManager.activeItem?.setActiveStyles();
      event.preventDefault();
    } else if ([LEFT_ARROW, RIGHT_ARROW].includes(event.keyCode)) {
      const tabCount = event.keyCode === LEFT_ARROW ? -1 : 1;
      const nextTab = this.tabs.findIndex((tab) => tab === this.selectedTab);
      const indexToFocus = nextTab + tabCount;
      if (indexToFocus > 4) this.nextContent();
      else this.prevContent();
      nextTab != -1 &&
        this.tabs[indexToFocus] &&
        this.changeTab(this.tabs[indexToFocus]);
      event.preventDefault();
    }
  };

  private initKeyManager = () => {
    this.keyManager = new ActiveDescendantKeyManager(this.items)
      .withWrap()
      .withTypeAhead();
  };

  setKeyManagerItem = (index: number) => {
    this.keyManager.setActiveItem(index);
  };

  private itemsChangeHandler = () => {
    waitTimeout().then(this.setFirstActiveItem);
  };

  private setFirstActiveItem = () => {
    this.items.first && this.items.first.setActiveStyles();
    this.keyManager && this.keyManager.setFirstItemActive();
    this._cdr.detectChanges();
  };

  scrollend(e: any) {
    const element = e.srcElement;
    if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
      this.scrollEnd.emit();
    }
  }

  setItensScroll(searchedStock: ISearchStock[]) {
    this._searchedStock = searchedStock;
    if (!searchedStock || !searchedStock.length) {
      if (this.selectedTab === NEW_STOCK_MODAL_TABS.MOST_SEARCHED) {
        this.searchDynamic$.next();
      } else {
        this.onVerifyExistStockInList$.next(this.stockListItemsAux);
      }
      return;
    }
    this.onVerifyExistStockInList$.next(searchedStock);
    this._cdr.detectChanges();
  }

  public prevContent() {
    if (this.disableArrowLeft) return;
    const scrollValue = this.scroll.nativeElement.scrollLeft - 120;
    this._scroll(scrollValue);
    this.disableArrowRight = false;
    this.disableArrowLeft = scrollValue <= 0;
  }

  public nextContent() {
    if (this.disableArrowRight) return;
    const scrollValue = this.scroll.nativeElement.scrollLeft + 120;
    this.disableArrowRight = scrollValue > 300;
    this._scroll(scrollValue);
    this.disableArrowLeft = false;
  }

  private _scroll(value: number): void {
    this.scroll.nativeElement.scrollTo({
      top: 0,
      left: value,
      behavior: 'smooth',
    });
  }
}
