import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IWorkSpaceComponet } from '@core/interface';
import { Subject, debounceTime, map, takeUntil } from 'rxjs';
import { ToastService } from '@shared/services';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { lowerCaseAndNormalizeString } from 'src/app/utils/utils.functions';
import { NewsChannel } from '@shared/channel/news.channel';
import {
  ICalendar,
  INews,
  INewsAgency,
  INewsGroup,
  INewsSubGroup,
  INewsTag,
} from '@core/interface/news-agency.interface';
import { TNewsConfiguration } from './types';
import { TrademapNewsService } from '@shared/services/api/trademap/v1/news.service';
import { NEWS_TAGS_NOT_AGENCIES } from './types';
import { DatePipe } from '@angular/common';
import { DEFAULT_GROUP_NEWS } from './news.const';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { ActivatedRoute } from '@angular/router';
import { isWebViewContext } from 'src/app/desktop/integration.utils';
import { ContextMenuService } from '../popup-root/context-menu.service';
import { NewsContextMenuComponent } from './config/news-context-menu.component';
import { NewsService } from './news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsComponent
  extends RocketComponentBase
  implements OnChanges, OnDestroy
{
  @Input() component!: IWorkSpaceComponet;
  @Input() refComponent!: string;
  @Input() width!: number;
  @Input() height!: number;
  @ViewChild('newsWrapper', { static: true }) newsWrapper!: ElementRef;

  private _onDestroy = new Subject<void>();
  private _cheetahParams: any;
  private _page = 0;
  private datePipe: DatePipe = new DatePipe('pt-BR');
  private _filterNewsByTextSubject = new Subject<void>();
  newsConfiguration: TNewsConfiguration = {
    tab: 0,
    tag: '0',
  };
  agencies!: INewsAgency[];
  tags!: INewsTag[];
  news: INews[] = [];
  selectedNews!: INews | null;
  selectedNewsIndex!: number | null;
  selectedFooterTag!: INewsTag | null;
  searchText!: string;
  wrapperHeight: any;
  loading = true;
  loadingNews = false;
  newsGroups!: INewsGroup[];
  isDesktop = false;

  get linked() {
    return this.component.metadata?.headerOptions?.link ?? true;
  }

  get selectedNewsGroup() {
    return (
      this.newsGroups.find(
        (newsGroup: INewsGroup) =>
          newsGroup.id_news_group == this.newsConfiguration.tab
      ) || {
        id_news_group: 0,
        itens: [],
        nm_news_group: '',
      }
    );
  }

  constructor(
    private _trademapNewsService: TrademapNewsService,
    private _newsChannel: NewsChannel,
    private _toastService: ToastService,
    private _customPreferencesService: CustomPreferencesService,
    private cdr: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    protected contextMenuService: ContextMenuService,
    private newsService: NewsService
  ) {
    super(activatedRoute);
    this.isDesktop = isWebViewContext();
    this._filterNewsByTextSubject
      .pipe(debounceTime(250))
      .subscribe(() => this._loadNews());
  }

  protected initComponent(component: any): void {
    if (component) this.component = component;

    this.newsWrapper.nativeElement.oncontextmenu = this._showConfig;
    const configs = JSON.parse(
      this._customPreferencesService.newsConfiguration
    );
    if (configs)
      this.newsConfiguration = { ...this.newsConfiguration, ...configs };
    else this.newsConfiguration = { tab: 1, tag: 'ULTIMAS' };
    this.newsChannelSubscriber();
    this._loadData();

    this.height =
      this.component.metadata.componentResize?.height ??
      parseInt(this.component.metadata.layout.height);
    this.width =
      this.component.metadata.componentResize?.width ??
      parseInt(this.component.metadata.layout.width);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { height } = changes;

    height && (this.wrapperHeight = height.currentValue - 40);
  }

  ngOnDestroy(): void {
    this._filterNewsByTextSubject.unsubscribe();
    this._unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  selectNews(news: INews | null) {
    this.selectedNewsIndex = this.news.findIndex(
      (listNews: INews) => listNews.item == news?.item
    );

    if (!news || news.texto || news.agencia == 'CVM') {
      this.selectedNews = news;
      this.cdr.detectChanges();
      return;
    }
    this.loading = true;
    this._trademapNewsService
      .getNewsDetails(news.agency_type, news.nu_noticia, news.id_news_agency)
      .subscribe((news: INews | undefined) => {
        this.loading = false;
        if (!news) {
          this._toastService.showToast(
            'warning',
            'Ocorreu um erro ao listar a notícia!'
          );
          this.cdr.detectChanges();
          return;
        }

        if (news.texto) {
          this.selectedNews = news;
        } else {
          window.open(news.url, '_blank');
          this.selectedNews = null;
          this.selectedNewsIndex = null;
        }
        this.cdr.detectChanges();
      });
  }

  selectPreviousNews() {
    if (!this.selectedNews) return;

    const selectedNewsIndex = this.news.findIndex(
      (news: INews) => news.item == this.selectedNews?.item
    );
    if (selectedNewsIndex <= 0) return;

    this.selectNews(this.news[selectedNewsIndex - 1]);
  }

  selectNextNews() {
    if (!this.selectedNews) return;

    const selectedNewsIndex = this.news.findIndex(
      (news: INews) => news.item == this.selectedNews?.item
    );
    if (selectedNewsIndex < 0 || selectedNewsIndex == this.news.length - 1)
      return;

    this.selectNews(this.news[selectedNewsIndex + 1]);
  }

  onTagSelected(tag: string) {
    this.selectedFooterTag = null;
    this.newsConfiguration.tag = tag;
    this._saveConfig();
    this._page = 0;
    this._loadNews();
    this._subscribeNewsChannel();
  }

  onFooterTagSelected(tag: INewsTag) {
    this.news = [];
    this.selectedFooterTag = tag;
    this._page = 0;
    this._loadNews();
    this._unsubscribe();
  }

  onTagGoBack() {
    this.selectedFooterTag = null;
    this._page = 0;
    this._loadNews();
    this._subscribeNewsChannel();
  }

  onTextSearch(text: string) {
    if (!text || text.length > 2) {
      this._page = 0;
      this.searchText = text;
      this._filterNewsByTextSubject.next();
    }
  }

  onListScroll(event: any) {
    if (
      !this.selectedNews &&
      event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight &&
      this.newsConfiguration.tab !== 999
    ) {
      this._loadNews(true);
    }
  }

  onTabChange(tab: number) {
    this.newsConfiguration.tab = tab;
    this.newsConfiguration.tag = this.selectedNewsGroup.itens[0].tag_agg || '';
    this.selectedNews = null;
    this.selectedFooterTag = null;
    this._page = 0;
    this._saveConfig();
    this._loadNews();
    this._subscribeNewsChannel();
  }

  private _loadNews(append = false) {
    if (this.loadingNews) return;

    this.selectedNews = null;
    this.loadingNews = true;
    !append && (this.news = []);
    append &&
      setTimeout(() => {
        const element = this.newsWrapper.nativeElement;
        element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
        this.cdr.detectChanges();
      }, 0);

    const { agencies, tags, service, groupTag } = this._getNewsListFilter();
    if (groupTag.id_news_group != 999) {
      this._getNews(agencies, tags, service, append);
      return;
    }
    this._getCalendarNews(service, groupTag);
  }

  private _getNews(
    agencies: string[],
    tags: string[],
    service: any,
    append: boolean
  ): void {
    service(this._page, undefined, agencies, tags, this.searchText)
      .pipe(takeUntil(this._onDestroy))
      .subscribe({
        next: (news: INews[]) => {
          this.news = append ? [...this.news, ...news] : news;
          this.loadingNews = false;
          this.loading = false;
          this._page += 1;
          this.cdr.detectChanges();
        },
        error: () => {
          this.news = [];
          this.loadingNews = false;
          this.loading = false;
          this.cdr.detectChanges();
        },
      });
  }

  private _getCalendarNews(service: any, groupTag: any): void {
    if (this.news.length) return;
    service(groupTag.tag_agg.toLowerCase())
      .pipe(
        map((news: ICalendar[]) => {
          const allNews: ICalendar[] = [];
          news.forEach((data: any) => {
            if (
              data.time.includes('undefined') ||
              !this._filterCalendarByText(data)
            )
              return;
            const dateCreated = data.time.includes('T')
              ? data.time
              : data.date + 'T' + data.time;
            allNews.push({
              ...data,
              dateTransformed: this.datePipe.transform(
                dateCreated,
                'yyyy-MM-ddTHH:mm'
              ),
            });
          });

          return allNews;
        })
      )
      .subscribe((transformedData: INews[]) => {
        if (!transformedData.length) this.news = [];
        else this.news = transformedData;
        this.loadingNews = false;
        this.loading = false;
        this._page += 1;
        this.cdr.detectChanges();
      });
  }

  private _filterCalendarByText(calendar: INews): boolean {
    if (!this.searchText || this.searchText.length < 2) return true;
    const normalizeText = lowerCaseAndNormalizeString(this.searchText);
    if (
      calendar.headline &&
      lowerCaseAndNormalizeString(calendar.headline).includes(normalizeText)
    )
      return true;
    if (
      calendar.institution &&
      lowerCaseAndNormalizeString(calendar.institution).includes(normalizeText)
    )
      return true;
    return false;
  }

  private _getNewsListFilter(): {
    agencies: string[];
    tags: string[];
    service: any;
    groupTag: any;
  } {
    const response: {
      agencies: string[];
      tags: string[];
      service: any;
      groupTag: any;
    } = {
      agencies: [],
      tags: [],
      service: this._trademapNewsService.getNews,
      groupTag: {},
    };

    if (this.selectedFooterTag) {
      response.tags.push(`${this.selectedFooterTag.id_agency_tag}`);
      return response;
    }

    const { tag } = this.newsConfiguration;

    if (!tag) {
      return response;
    }
    const groupTag = this.selectedNewsGroup.itens.find(
      (group: INewsSubGroup) => group.tag_agg == tag
    );
    if (!groupTag) {
      return response;
    }

    response.groupTag = groupTag;

    if (groupTag.tp_view_news == 'TAG') {
      response.tags.push(tag);
      return response;
    }

    NEWS_TAGS_NOT_AGENCIES.indexOf(groupTag.tp_view_news) < 0 &&
      (response.agencies =
        groupTag.tag_agg.indexOf(',') > -1
          ? groupTag.tag_agg.split(',')
          : [groupTag.tag_agg]);

    const groupId =
      groupTag.nm_view_news == 'BDM' ? 'BDM' : groupTag.tp_view_news;
    response.service =
      this._trademapNewsService.serviceFunctionMapping[groupId];
    return response;
  }

  private _loadData() {
    this._trademapNewsService
      .getNewsGroups()
      .pipe(
        takeUntil(this._onDestroy),
        map((groups: INewsGroup[]) => {
          const hash = this.newsService.getHashRemoveGroupNews();
          if (!hash.size()) {
            return groups;
          }
          return groups.filter((group) => !hash.has(group.id_news_group));
        })
      )
      .subscribe((groups: INewsGroup[]) => {
        this.newsGroups = groups;
        this.newsGroups.push(DEFAULT_GROUP_NEWS);
        this.cdr.detectChanges();
        this._loadNews();
        this._subscribeNewsChannel();
      });
  }

  private _subscribeNewsChannel() {
    this._unsubscribe();

    this._cheetahParams = {
      itemsArray: this._getCheetahItems(),
      header: this.refComponent,
    };
    this._newsChannel.subscribe(this._cheetahParams);
    this.cdr.detectChanges();
  }

  private _unsubscribe() {
    if (!this._cheetahParams) return;

    this._newsChannel.unsubscribe(this._cheetahParams);
    this._cheetahParams = null;
  }

  private _getCheetahItems() {
    return ['BVSP:1', 'BMF:1', 'EXTERNAL-NEWS'];
  }

  private _channelHandler = (data: Map<string, any>): void => {
    if (!data || !data.size) return;
    data.forEach((notice) => {
      if (notice.item === 'EXTERNAL-NEWS') {
        const groupTag: any = this.selectedNewsGroup.itens.find(
          (group: INewsSubGroup) => group.tag_agg == this.newsConfiguration.tag
        );

        const idxTabSelected = this.newsGroups.findIndex(
          (news) => news.id_news_group === groupTag.id_news_group
        );
        const idxAgency = this.newsGroups[idxTabSelected].itens.findIndex(
          (agency) => agency.tag_agg === groupTag.tag_agg
        );

        if (
          groupTag.tag_agg != 'ULTIMAS' &&
          groupTag.tag_agg != 'EVENTOS' &&
          groupTag.tp_view_news != 'TAG'
        ) {
          if (
            !groupTag ||
            groupTag.tag_agg !==
              this.newsGroups[idxTabSelected].itens[idxAgency].tag_agg ||
            notice.id_news_agency != groupTag.tag_agg
          )
            return;
        }

        notice.agencia = notice.ds_agency;
        notice.headline = notice.ds_headline;
        notice.data_noticia = notice.dt_news;
        notice.hora_noticia = notice.hr_news;
        notice.agency_type = notice.tp_agency;
        notice.item = +notice.id_news;
        notice.id_news_agency = +notice.id_news_agency;
        notice.nu_noticia = +notice.id_news;

        const tagsArray = notice.tags.split(';').slice(0, -1);
        notice.tags = tagsArray.map((tag: any) => {
          const [ds_agency_tag, id_agency_tag] = tag.split(':');
          return { ds_agency_tag, id_agency_tag: parseInt(id_agency_tag) };
        });

        if (
          groupTag.tp_view_news == 'TAG' &&
          !tagsArray.includes(`${groupTag.nm_view_news}:${groupTag.tag_agg}`)
        ) {
          return;
        }
      }

      if (
        this.searchText &&
        notice.cd_company.indexOf(this.searchText) < 0 &&
        notice.headline.indexOf(this.searchText) < 0
      )
        return;

      this.news = [notice, ...this.news.slice(0, 100)];
      this.cdr.detectChanges();
    });
  };

  private _showConfig = (event: any) => {
    NewsContextMenuComponent.openContextMenu(
      this.contextMenuService,
      this.component.id,
      { clientX: event.pageX, clientY: event.pageY }
    );
    return false;
  };

  private _saveConfig() {
    this._customPreferencesService.newsConfiguration = JSON.stringify(
      this.newsConfiguration
    );
  }

  private newsChannelSubscriber() {
    this._newsChannel.onEvents().then((data) => {
      this.readStream(data.stream, this._channelHandler);
    });
  }
}
