import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  WritableSignal,
  signal,
} from '@angular/core';
import {
  IAllStockListSimple,
  IListStockDB,
  IWorkSpaceComponet,
  StockListItemsWithView,
} from '@core/interface';
import { HomeService } from '@modules/home/service/home.service';
import { StockService } from '@shared/services/api/trademap/v1/stock.service';
import {
  Subject,
  delay,
  tap,
  debounceTime,
  merge,
  map,
  auditTime,
  filter,
  Subscription,
  takeUntil,
} from 'rxjs';
import { StockListRowModel } from '../stock-table/models/stock-table-row.model';
import { ToastService } from '@shared/services';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { Dictionary } from '@core/models';
import { CheckConfigListMotPersonal } from '../stock-table/configurations-list/check-config-list-not-personal';
import { StockListStettingsService } from '@shared/services/api/nitro-ws/v1/stock-list-stettings.service';
import { MoversChannel } from '@shared/channel/movers.channel';
import { MarketSectorsService } from '@shared/services/api/trademap/v1/market-sectors.service';
import { IMarketSectorDomain } from '@core/interface/market-sector.interface';
import { HeatmapHeaderComponent } from './header/heatmap-header.component';
import { RocketCreateComponentService } from '@shared/rocket-components/services';
import { IMoversData } from '../stock-table/interfaces/stock-table.interfaces';
import { ListsService } from '../heatmap-chart/services/lists.service';
import { SectorsService } from '../heatmap-chart/services/sectors.service';
import {
  THeatmapConfig,
  TOrderBy,
  TPeriod,
  TSource,
} from '../heatmap-chart/types';
import {
  CHART_OPTIONS,
  HEATMAP_PREFERENCES_MARKET_WS,
} from './constants/heatmap.constants';
import { ListStocksService } from '@shared/services/core/list-stocks/list-stocks.service';
import { DYNAMIC_LISTS } from '../stock-table/constants/stock-table.constants';
import { RocketModalService } from '@shared/rocket-components/components';
import { NewListStockModalComponent } from '../stock-table/modals/new-list-stock-modal/new-list-stock-modal.component';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { ActivatedRoute } from '@angular/router';
import { RocketStreamRead } from '@shared/channel/rx-event';
import { isWebViewContext } from 'src/app/desktop/integration.utils';
import { randomId } from '@shared/rocket-components/utils';
import { HeatmapContextMenuComponent } from './config/heatmap-context-menu.component';
import { ContextMenuService } from '../popup-root/context-menu.service';

@Component({
  selector: 'app-heatmap',
  templateUrl: './heatmap.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeatmapComponent
  extends RocketComponentBase
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() set component(component: IWorkSpaceComponet) {
    if (component) this._component.set(component);
  }
  _component: WritableSignal<IWorkSpaceComponet | undefined> =
    signal(undefined);
  @Input() refComponent!: string;
  @Input() width!: number;
  @Input() height!: number;
  @Input() link!: boolean;
  @ViewChild(HeatmapHeaderComponent) header!: HeatmapHeaderComponent;
  @ViewChild('heatmap', { static: true }) heatmap!: ElementRef;

  public heatmapConfigsSignal: WritableSignal<THeatmapConfig> = signal({
    period: 'ONEDAY',
  });
  public componentId!: string;
  public stockList = new Dictionary<StockListRowModel>();
  public moversList = new Dictionary<any>();
  public withoutPersonalLists: boolean = false;
  public isOpenListPersonal: boolean = true;
  public isSectors: boolean = false;
  public loadingMovers: boolean = true;
  public displayEmptyDynamicList: boolean = false;
  isDesktop = false;

  private _sectors!: { [key: number]: string };
  private _updateChartDataSubject = new Subject<void>();
  private _updateHeatmapConfsSubect = new Subject<void>();
  private _onClickSubscription!: Subscription;
  private _onDestroy = new Subject<void>();
  private _updateChartSource = new Subject<void>();
  private _updateOrdenation = new Subject<{ orderBy: TOrderBy }>();
  private _chart!: Highcharts.Chart;
  private _quoteParams: any;
  private _moversParams: any;
  private _moversTypes: any = {
    [DYNAMIC_LISTS.MOVERS_HIGH.id]: 'ALTA.IBOV',
    [DYNAMIC_LISTS.MOVERS_LOW.id]: 'BAIXA.IBOV',
    [DYNAMIC_LISTS.MOVERS_VOLUME.id]: 'VOLUME.IBOV',
  };
  private _sectorsStocks!: [][];
  private quoteChannelStream!: RocketStreamRead | undefined;
  private _moversReadStreamRef!: RocketStreamRead | undefined;

  constructor(
    private _homeService: HomeService,
    private _stockService: StockService,
    private _listsService: ListsService,
    private _sectorsService: SectorsService,
    private _toastService: ToastService,
    private _marketSectorService: MarketSectorsService,
    private _quoteChannel: QuoteChannel,
    protected stockListStettingsService: StockListStettingsService,
    private _moversChannel: MoversChannel,
    private _createComponent: RocketCreateComponentService,
    private listStocksService: ListStocksService,
    private cdr: ChangeDetectorRef,
    private _rocketModalService: RocketModalService,
    private _globalStock: GlobalSelectedStockSubscription,
    activatedRoute: ActivatedRoute,
    protected contextMenuService: ContextMenuService
  ) {
    super(activatedRoute);
    this.isDesktop = isWebViewContext();
    this._handleSubscriptions();
  }

  private _handleSubscriptions = (): void => {
    this._updateHeatmapConfsSubect.pipe(debounceTime(250)).subscribe(() => {
      this._component()!!.metadata.component = {
        ...this._component()!!.metadata.component,
        configs: this.heatmapConfigsSignal(),
      };
      this._homeService.updateMeta(this._component()!!);
    });

    // Chart
    this._updateChartSource
      .pipe(
        delay(100),
        filter(() => this.heatmapConfigsSignal().source !== undefined)
      )
      .subscribe(() => {
        this.header.selectSource(this.heatmapConfigsSignal().source);
      });

    this._updateOrdenation
      .pipe(
        delay(100),
        tap((data) => {
          this._chart.showLoading();
          this.heatmapConfigsSignal.mutate(
            (config) => (config.orderBy = data.orderBy)
          );
          this._saveConfig();
        })
      )
      .subscribe(() => {
        const source = this.heatmapConfigsSignal().source;
        if (source) {
          this._sectorsService.plotSectors(
            this._chart,
            this._sectorsStocks,
            this._sectors,
            this.refComponent,
            this.heatmapConfigsSignal()
          );
          return;
        }
        this.cdr.detectChanges();
        this._updateChartDataSubject.next();
      });

    this._updateChartDataSubject
      .pipe(
        filter(() => this._chart !== undefined && !this.isSectors),
        auditTime(600)
      )
      .subscribe(() => this._updateChartInfos());

    this._onClickSubscription = merge(
      this._listsService.onClick,
      this._sectorsService.onClick
    )
      .pipe(
        filter(
          (params) => this.link && params.refComponent === this.refComponent
        ),
        map((params) => params.event.point.options.custom)
      )
      .subscribe((stock) => {
        const globalStock = this._globalStock.getGlobalStockSelected();
        if (stock && stock['cd_stock'] !== globalStock?.cd_stock)
          this._globalStock.researchToStandardizeGlobalStock(stock);
      });
  };

  protected initComponent(component: any): void {
    if (component) {
      this._component.set(component);
      this.height =
        component.metadata.componentResize?.height ??
        parseInt(component.metadata.layout.height);
      this.width =
        component.metadata.componentResize?.width ??
        parseInt(component.metadata.layout.width);
    }

    if (this.isDesktop) {
      this.refComponent = randomId('HEATMAP');
    }

    const configs = this._component()!!.metadata.component.configs;
    if (configs) {
      this.heatmapConfigsSignal.set(configs);
      if (configs.source) this._initialConfigToSourceList();
      this.cdr.detectChanges();
      return;
    }
    this._configureInitialOptions();
  }

  ngAfterViewInit(): void {
    this.heatmap.nativeElement.oncontextmenu = this._showConfig;
  }

  ngOnDestroy(): void {
    this._unsubscribeQuote(true);
    this._unsubscribeMovers(true);
    this._updateHeatmapConfsSubect.unsubscribe();
    this._updateChartSource.unsubscribe();
    this._updateOrdenation.unsubscribe();
    this._updateChartDataSubject.unsubscribe();
    this._onClickSubscription.unsubscribe();
    this._onClickSubscription.unsubscribe();
    this.quoteChannelStream?.close();
    this._moversReadStreamRef?.close();
  }

  private _showConfig = (event: any) => {
    HeatmapContextMenuComponent.openContextMenu(
      this.contextMenuService,
      this._component()!!.id,
      { clientX: event.pageX, clientY: event.pageY }
    );
    return false;
  };

  // CHART
  public setChartRef(chart: Highcharts.Chart): void {
    this._chart = chart;
    this.cdr.detectChanges();
    this._updateChartDataSubject.next();
  }

  public chartCallBack = (): void => {
    this._updateChartSource.next();
  };

  private _updateChartInfos(): void {
    if (this.isSectors || !this._chart || !this._chart?.series?.length) return;
    this._listsService.plotData(
      this._chart,
      this.stockList,
      this.refComponent,
      !this.heatmapConfigsSignal().isNotListPersonal,
      CHART_OPTIONS,
      this.heatmapConfigsSignal().orderBy,
      this.heatmapConfigsSignal().period,
      false
    );
  }

  // ReadStream
  private _initializeReadStreams = async () => {
    if (this.isOpenListPersonal) {
      if (!this.quoteChannelStream) {
        this.quoteChannelStream = await this._quoteChannel.readEvents();
        this.readStream(this.quoteChannelStream.stream, this._channelHandler);
        this.quoteChannelStream.snapshot(this._quoteParams.itemsArray);
        this.cdr.detectChanges();
        return;
      }
      this.quoteChannelStream.snapshot(this._quoteParams.itemsArray);
      return;
    }

    if (!this.isOpenListPersonal) {
      if (!this._moversReadStreamRef) {
        this._moversReadStreamRef = await this._moversChannel.readEvents();
        this.readStream(this._moversReadStreamRef.stream, this._moverHandler);
        this._moversReadStreamRef.snapshot(this._moversParams.itemsArray);
        this.cdr.detectChanges();
        return;
      }
      this._moversReadStreamRef.snapshot(this._moversParams.itemsArray);
    }
  };

  // QUOTE CHANNEL
  private _subscribeQuote() {
    this._quoteParams = {
      itemsArray: this.stockList.keys(),
      header: this.componentId,
    };
    this._quoteChannel.subscribe(this._quoteParams);
    this._initializeReadStreams();
    this.cdr.detectChanges();
  }

  private _unsubscribeQuote(closeReadStream: boolean) {
    if (!this._quoteParams) return;
    this._quoteChannel.unsubscribe(this._quoteParams);
    if (closeReadStream && this.quoteChannelStream) {
      this.quoteChannelStream.close();
      this.quoteChannelStream = undefined;
    }
    this._quoteParams = null;
  }

  private _channelHandler = (payload: Map<string, any>): void => {
    payload.forEach((data: any, key: string) => {
      if (this.stockList.has(key)) {
        this.stockList.set(key, data);
        if (
          this._updateChartDataSubject &&
          !this._updateChartDataSubject.closed
        ) {
          this._updateChartDataSubject.next();
        }
      }
    });
  };

  //MOVERS CHANNEL
  private _subscribeMovers = (list: IListStockDB) => {
    this._unsubscribeMovers(false);
    this.loadingMovers = true;
    this.displayEmptyDynamicList = false;
    this.stockList.clear();
    this.moversList.clear();
    this._moversParams = {
      itemsArray: [this._moversTypes[list.idStockList]],
      header: this.refComponent,
    };
    this.cdr.detectChanges();
    this._moversChannel.subscribe(this._moversParams);
    this._initializeReadStreams();
  };

  private _unsubscribeMovers(closeReadStrem: boolean) {
    if (!this._moversParams) return;
    this.loadingMovers = false;
    this.displayEmptyDynamicList = false;
    this._moversChannel.unsubscribe(this._moversParams);
    this._moversParams = null;
    if (closeReadStrem && this._moversReadStreamRef) {
      this._moversReadStreamRef.close();
      this._moversReadStreamRef = undefined;
    }
    this.cdr.detectChanges();
  }

  private _moverHandler = (payload: Map<string, IMoversData[]>): void => {
    const list = payload.get(this._moversParams.itemsArray[0]);
    if (!list) return;
    list.forEach((data) => this._updateMoversData(data));
  };

  private _updateMoversData(stockData: IMoversData) {
    const stock: any = {
      ...stockData,
      id: `${stockData.key}:1`,
      cd_stock: stockData.key,
      variacao_dia: stockData.variacao,
    };
    if (stockData.isEndOfSnap) {
      this.loadingMovers = false;
      this.displayEmptyDynamicList = this.stockList.size() === 0;
      this.cdr.detectChanges();
      return;
    }
    if (stockData.command === 'DELETE') {
      this.stockList.delete(stock.id);
      this.moversList.delete(stock.id);
    } else this.stockList.set(stock.id, stock);
    const stockInfos = this._listsService.stockToHighchartItem(
      this.stockList,
      this.refComponent,
      !this.heatmapConfigsSignal().isNotListPersonal,
      CHART_OPTIONS,
      stock,
      this.heatmapConfigsSignal().orderBy,
      this.heatmapConfigsSignal().period,
      false
    );
    this.moversList.set(stock.id, stockInfos);
    this._updateChartDataSubject.next();
  }

  // FILTERS and ORDENATION
  onOrderByChange(orderBy: TOrderBy) {
    this._updateOrdenation.next({
      orderBy,
    });
  }

  onPeriodChange(period: TPeriod) {
    this.heatmapConfigsSignal.mutate((config) => (config.period = period));
    this._saveConfig();
    const source = this.heatmapConfigsSignal().source;
    if (source) {
      this.onSourceSelected(source);
      return;
    }
    this._updateChartInfos();
  }

  // ON SELECT LIST
  public processList = (selectedList: IAllStockListSimple): void => {
    if (!selectedList || !selectedList.id_stock_list) {
      this._unsubscribeQuote(true);
      this._unsubscribeMovers(true);
      return;
    }

    this.isSectors = false;
    this.cdr.detectChanges();

    if (selectedList.isNotListPersonal) {
      this.changeNotListPersonal(selectedList);
      return;
    }

    if (this._moversParams) this._unsubscribeMovers(true);
    this._unsubscribeQuote(this.isSectors || !this.isOpenListPersonal);
    this.heatmapConfigsSignal.mutate((config) => {
      config.isNotListPersonal = false;
      config.source = undefined;
      config.idList = selectedList.id_stock_list;
    });
    const orderBy = this.heatmapConfigsSignal().orderBy;
    if (orderBy && ['AFTERMARKET', 'GAPOPENING'].indexOf(orderBy) > 0)
      this.heatmapConfigsSignal.mutate(
        (config) => (config.orderBy = 'variation')
      );

    this._saveConfig();
    this.isOpenListPersonal = true;
    this.stockList.clear();
    this.cdr.detectChanges();
    this._stockService
      .getStockListItemsWithView(
        null,
        selectedList.id_stock_list,
        this.refComponent
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((result: StockListItemsWithView) => {
        if (!result.stockListItemsRow || !result.stockListItemsRow.length)
          return;
        result.stockListItemsRow.forEach((stock) =>
          this.stockList.set(stock.idRow, stock)
        );
        this.cdr.detectChanges();
        this._updateChartDataSubject.next();
        this._subscribeQuote();
      });
  };

  public changeNotListPersonal(list: IAllStockListSimple) {
    if (this._quoteParams) this._unsubscribeQuote(true);
    this.isSectors = false;
    this.loadingMovers = true;
    this.heatmapConfigsSignal.mutate((config) => {
      config.source = undefined;
      config.period = 'ONEDAY';
      config.orderBy = 'volume';
      config.isNotListPersonal = true;
      config.idList = list.id_stock_list;
    });
    this._saveConfig();
    this.isOpenListPersonal = false;
    this._component()!!.metadata.component = {
      ...this._component()!!.metadata.component,
      isOpenListPersonal: false,
    };
    this._homeService.updateMeta(this._component()!!);
    const checkConfigListMotPersonal = new CheckConfigListMotPersonal(
      this.stockListStettingsService,
      this.listStocksService
    );
    this.cdr.detectChanges();
    checkConfigListMotPersonal.check(list, this._subscribeMovers);
  }

  public changeOpenListPersonal(data: boolean): void {
    this._component()!!.metadata.component = {
      ...this._component()!!.metadata.component,
      isOpenListPersonal: data,
    };
    this._homeService.updateMeta(this._component()!!);
  }

  public onSourceSelected(source: TSource) {
    this.isSectors = true;
    this.cdr.detectChanges();
    if (this._chart) this._chart.showLoading();

    this._unsubscribeQuote(true);
    this._unsubscribeMovers(true);
    this.isOpenListPersonal = false;
    this.stockList.clear();
    this.moversList.clear();
    this.cdr.detectChanges();

    this.heatmapConfigsSignal.mutate((config) => {
      config.source = source;
      config.isNotListPersonal = false;
      config.idList = undefined;
    });
    this._saveConfig();

    if (this._sectors) {
      this._listStocksForSector(source);
      return;
    }

    this._marketSectorService
      .geMarketSectorDomains()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((result: IMarketSectorDomain | null) => {
        if (!result) {
          this._toastService.showToast(
            'warning',
            'Não foi possível listar os setores.'
          );
          return;
        }
        this._sectors = result.sector;
        this.cdr.detectChanges();
        this._listStocksForSector(source);
      });
  }

  private _listStocksForSector(source: TSource) {
    if (!this._sectors) return;
    this._marketSectorService
      .geStocksBySource(source, this.heatmapConfigsSignal().period)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((result: any | null) => {
        if (!result || result.length == 0) {
          this._toastService.showToast(
            'warning',
            'Não foi possível listar os ativos.'
          );
          return;
        }
        this._sectorsStocks = result;
        this._sectorsService.plotSectors(
          this._chart,
          result,
          this._sectors,
          this.refComponent,
          this.heatmapConfigsSignal()
        );
        this.cdr.detectChanges();
      });
  }

  public ghostListSelected(): void {
    this._unsubscribeQuote(true);
    this._unsubscribeMovers(true);
    this.stockList.clear();
    this.moversList.clear();
    this.isOpenListPersonal = true;
    this.cdr.detectChanges();
    this.heatmapConfigsSignal.mutate((config) => {
      config.idList = 0;
      config.isNotListPersonal = false;
      config.source = undefined;
    });
    this._updateHeatmapConfsSubect.next();
  }

  // CONFIGS
  private _saveConfig() {
    this._updateHeatmapConfsSubect.next();
  }

  private _initialConfigToSourceList(): void {
    this.isOpenListPersonal = false;
    this.isSectors = true;
  }

  private _configureInitialOptions(): void {
    const initialOptions =
      this._component()!!.metadata.component.initialOptions;
    if (initialOptions?.initialList) {
      this._initialConfigToSourceList();
      this.heatmapConfigsSignal.set(HEATMAP_PREFERENCES_MARKET_WS);
    }
  }

  public setIsWithoutPersonalList(withoutList: boolean): void {
    this.withoutPersonalLists = withoutList;
    this.cdr.detectChanges();
  }

  public openCreateStockList(): void {
    const ref = this._rocketModalService.open(NewListStockModalComponent, {
      centered: true,
      scrollable: false,
      keyboard: true,
      data: {
        refComponent: this.refComponent,
        isNotLists: false,
        returnNameAndId: true,
      },
    });
    const modalSub = ref.afterDismissed
      .pipe(filter((item) => item && !item.closed))
      .subscribe((params: { name: string; idList: number }) => {
        this.withoutPersonalLists = false;
        this.processList({
          nm_stock_list: params.name,
          id_stock_list: params.idList,
          isNotListPersonal: false,
          in_visibility: true,
          is_favorite: false,
          isPresetList: false,
        });
        modalSub.unsubscribe();
      });
  }
}
