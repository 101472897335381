import {
  Component,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { ISearchStock, IWorkSpaceComponet } from '@core/interface';
import { SearchStockComponent } from '@shared/components/search-stock/search-stock.component';
import { DragService } from '@shared/rocket-components/services/ag-grid/drag.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-at-price-header',
  templateUrl: './at-price-header.component.html',
  styles: [
    `
      ::ng-deep .header-wrapper {
        padding-right: 44px;
      }
      .list-max-height {
        width: 160px;
        max-height: 112px;
        overflow-x: auto;
      }
      .arrow::after {
        display: inline-block;
        vertical-align: 0.255em;
        content: '';
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        position: absolute;
        left: 142px;
        top: 43%;
      }
    `,
  ],
})
export class AtPriceHeaderComponent implements AfterViewInit, OnDestroy {
  @Input() refComponent!: string;
  @Input() public headerOptions!: any;
  @Input() stockSelected!: ISearchStock;
  @Input() component!: IWorkSpaceComponet;
  @Output() headerHandler = new EventEmitter();
  @Output() changeStock: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeViewType: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchStock') searchStock!: SearchStockComponent;
  private dragEvents$!: Subscription;

  constructor(private dragService: DragService) {}

  ngAfterViewInit() {
    this.dragEvents$ = this.dragService
      .onEvents(this.component.id)
      .subscribe((data) => {
        this.selectStockByCdStock(data.data.cd_stock);
      });
  }

  ngOnDestroy() {
    this.dragEvents$ && this.dragEvents$.unsubscribe();
  }

  public selectStock = (stock: ISearchStock) => this.changeStock.emit(stock);

  selectStockByCdStock(cdStock: string) {
    this.searchStock.selectStockByCdStock(cdStock);
  }
}
