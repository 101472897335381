import { inject, InjectionToken, NgZone } from '@angular/core';
import { BroadcastService } from 'src/app/core/broadcast/broadcast-service';

export const BROADCAST_SERVICE = new InjectionToken<BroadcastService>(
  'broadCastService',
  {
    factory: () =>
      new BroadcastService('rocket-broadcast-service', inject(NgZone)),
  }
);
