import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { QuoteData } from '@shared/channel/interface/quote.channel.interface';
import { isAuction } from '@shared/constants/general.contant';
import { IStockAuctionInfos } from '@shared/interfaces/stock-auction-infos.interface';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { getAuctionInfos } from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-status-stock-renderer',
  template: `
    <app-status-stock
      [isAuction]="isAuction"
      [status]="status"
      [auctionInfos]="stockAuctionInfos"
      [hideSituationTooltip]="hideSituationTooltip"
    >
    </app-status-stock>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusStockRendererComponent implements ICellRendererAngularComp {
  public status!: string;
  public isAuction: boolean = false;
  public data!: QuoteData | null;
  public stockAuctionInfos!: IStockAuctionInfos;
  public hideSituationTooltip: boolean = true;

  constructor(private _cdr: ChangeDetectorRef) {}

  public agInit(params: ICellRendererParams): void {
    this.init(params);
  }

  public refresh(params: ICellRendererParams): boolean {
    this.init(params);
    return true;
  }

  private init(params: ICellRendererParams): void {
    if (params.data) {
      const auction = isAuction(params.data.situacao);
      this.isAuction = auction;
      this.status = params.data.situacao;
      if (params.data.is_movers) {
        this.hideSituationTooltip = false;
        return;
      }
      if (auction) this.data = params.data;
      else this.data = null;
      this.stockAuctionInfos = getAuctionInfos(
        params.data,
        this.stockAuctionInfos
      );
    }
    this._cdr.detectChanges();
  }
}
