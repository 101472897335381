import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { formatNumber } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ISearchStock } from '@core/interface';
import { Dictionary } from '@core/models';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { randomId } from '@shared/rocket-components/utils';
import { IStockIndexesParticipation } from '@shared/services/api/trademap/v1/interface/indexesParticipation';
import { StockService } from '@shared/services/api/trademap/v1/stock.service';
import { Subject, Subscription, delay, filter } from 'rxjs';
import { BusinessProfileService } from '../../business-profile.service';
import { StockServiceRT } from '@shared/services/api/nitro-ws/v1/stock.service';
import { RocketStreamRead } from '@shared/channel/rx-event';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';

@Component({
  selector: 'app-stock-indexes',
  templateUrl: './stock-indexes.component.html',
  styleUrls: ['./stock-indexes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockIndexesComponent
  extends ReadStreamBase
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @Input() stockSelected!: ISearchStock;
  @Input() refComponent!: string;
  @ViewChild('scrollViewport', { static: false })
  virtualScroll!: CdkVirtualScrollViewport;
  addClass = new Subject<string>();
  addClass$!: Subscription;
  itemsSubscribed = new Dictionary<IStockIndexesParticipation>();
  loading: boolean = true;
  private quoteChannel$!: RocketStreamRead;
  private ref = randomId('STOCK_INDICES');
  private quoteParams!: SubscribeParam;

  public indexes: IStockIndexesParticipation[] = [];
  constructor(
    private _stockService: StockService,
    private stockServiceRT: StockServiceRT,
    private _businessProfileService: BusinessProfileService,
    private _cdr: ChangeDetectorRef,
    private _quoteChannel: QuoteChannel,
    @Inject(LOCALE_ID) private locale: string
  ) {
    super()
  }

  ngOnInit() {
    this.addClass$ = this.addClass
      .pipe(
        filter((ref) => ref === this.ref),
        delay(100)
      )
      .subscribe(() => {
        this.addTableClass();
      });
    this.subscribeQuoteEvents();
  }

  ngOnDestroy() {
    this.addClass$ && this.addClass$.unsubscribe();
    this.quoteChannel$ && this.quoteChannel$.close()
    this._destroyQuote();
  }

  ngAfterViewInit() {
    this.addTableClass();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { stockSelected } = changes;
    stockSelected?.currentValue && this.getStockIndexesParticipation();
  }

  getStockIndexesParticipation() {
    this._destroyQuote();
    this._stockService
      .getStockIndexesParticipations(this.stockSelected.cd_stock)
      .subscribe((data: IStockIndexesParticipation[]) => {
        this.indexes = data
          .sort((a, b) => {
            return b.pcComposition - a.pcComposition;
          })
          .map((item) => {
            item.formattedComposition =
              formatNumber(item.pcComposition, this.locale, '1.2-2') + '%';
            this.itemsSubscribed.set(
              `${item.cdIndex}:${item.idExchange}`,
              item
            );
            return item;
          });
        this.addClass.next(this.ref);
        this.subscribeQuote();
        this.loading = false;
        this._cdr.detectChanges();
      });
  }

  indexClick(index: IStockIndexesParticipation) {
    this.stockServiceRT
      .searchStock(index.cdIndex, [], true)
      .subscribe((data: ISearchStock[]) => {
        const response = { refComponent: this.refComponent, stock: data[0] };
        this._businessProfileService.stockChanged.next(response);
      });
  }

  private addTableClass() {
    if (!this.virtualScroll) return;
    const nativeElement =
      this.virtualScroll.elementRef.nativeElement.querySelector(
        '.cdk-virtual-scroll-content-wrapper'
      );
    if (!nativeElement?.classList.contains('table')) {
      nativeElement?.classList.add('table');
    }
    this._cdr.detectChanges();
  }

  private async subscribeQuoteEvents() {
    if(!this.quoteChannel$){
      this.quoteChannel$ = await this._quoteChannel.readEvents()
      this.readStream(this.quoteChannel$.stream, this.quoteChannelHandler)
    }
  }

  private subscribeQuote() {
    this.quoteParams = {
      header: this.ref,
      itemsArray: this.itemsSubscribed.keys() as string[],
    };
    this._quoteChannel.subscribe(this.quoteParams);
    this.quoteChannel$ && this.quoteChannel$.snapshot(this.quoteParams.itemsArray)
  }

  private quoteChannelHandler = (payload: Map<string, any>) => {
    payload.forEach((data: any, key: string) => {
      if(!this.itemsSubscribed.has(key)) return;

      let quoteData = this.itemsSubscribed.get(data.item)!!;
      if (data.variacao_dia)
        quoteData.dayVariation = parseFloat(data.variacao_dia);
      if (data.preco_ultimo)
        quoteData.lastPrice = parseFloat(data.preco_ultimo);
      if (data.arrow_hex) quoteData.arrow_hex = data.arrow_hex;
      if (data.arrow_font_hex)
        quoteData.arrow_font_hex = data.arrow_font_hex;

      quoteData = { ...data, ...quoteData, }
      this.itemsSubscribed.set(data.item, quoteData);
      this._cdr.detectChanges();
    })
  }

  private _destroyQuote(): void {
    if (!this._quoteChannel || !this.itemsSubscribed.size()) return;
    this.quoteParams && this._quoteChannel.unsubscribe(this.quoteParams);
    this.itemsSubscribed.clear();
  }
}
