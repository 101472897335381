import { CHART_COLORS } from '@shared/tiger-chart/colors';
import { ICursorModifierOptions, INumericAxisOptions } from 'scichart';

export const NUMERIC_AXIS_PREFERENCES: INumericAxisOptions = {
  drawMajorGridLines: false,
  drawMinorGridLines: false,
  drawMajorBands: false,
  drawMajorTickLines: false,
  drawMinorTickLines: false,
};

export const SCICHART_TOOLTIP_PREFERENCES: ICursorModifierOptions = {
  showTooltip: true,
  tooltipContainerBackground: CHART_COLORS.NEUTRAL_STRONG,
  tooltipTextStroke: CHART_COLORS.NEUTRAL_SMOOTHEST,
  crosshairStroke: CHART_COLORS.NEUTRAL_MEDIUM,
};

export const TOOLTIP_INFO_FIELD: any = {
  vl_net: 'volume_net',
  vl_net_sum: 'volume_net_sum',
  vl_total: 'volume_total',
  vl_total_sum: 'volume_total_sum',
  vl_buuy_sum: 'volume_buy_sum',
  vl_sell_sum: 'volume_sell_sum',
  vl_negocios: 'quantity_trade_net',
  vl_negocios_sum: 'quantity_trade_net_sum',
};

export const BROKERS_TO_IGNORE = [
  247, -1, 339, 69, 45, 58, 341, 25, 75, 4, 40, 335,
];
