import { Injectable } from '@angular/core';
import { IInterval } from '../../interface/stock-chart.interface';
import { Dictionary } from '@core/models';
import {
  getTextByRegex,
  isNullOrUndefined,
} from 'src/app/utils/utils.functions';
import { FormControl } from '@angular/forms';
import { isNullOrWhiteSpace } from '@shared/rocket-components/utils';

@Injectable({
  providedIn: 'root',
})
export class StockChartModalIntervalService {
  modalOpened: boolean = false;
  constructor() {}

  changeIntervalByArrow(
    type: 'next' | 'previous',
    intervals: Dictionary<IInterval>,
    intervalControl: FormControl,
    findValue: IInterval
  ): IInterval {
    let newInterval!: IInterval;
    if (!isNullOrWhiteSpace(findValue)) {
      const actualIndex = intervals
        .keys()
        .findIndex((key) => key === findValue.id);
      if (type === 'next') {
        const nextIndex = actualIndex + 1;
        const nextInterval = intervals.keys()[nextIndex];
        if (!isNullOrUndefined(nextInterval)) {
          const interval = intervals.get(nextInterval)!!;
          newInterval = interval;
        } else {
          const firstInterval = intervals.keys()[0];
          const interval = intervals.get(firstInterval)!!;
          newInterval = interval;
        }
      } else {
        const previousIndex = actualIndex - 1;
        const previousInterval = intervals.keys()[previousIndex];
        if (!isNullOrUndefined(previousInterval)) {
          const interval = intervals.get(previousInterval)!!;
          newInterval = interval;
        } else {
          const intervalLength = intervals.keys().length;
          const lastInterval = intervals.keys()[intervalLength - 1];
          const interval = intervals.get(lastInterval)!!;
          newInterval = interval;
        }
      }
    } else {
      const text = getTextByRegex(
        intervalControl.value,
        /[a-zA-Z]+/g
      ).toUpperCase();
      const num = getTextByRegex(intervalControl.value, /\d+/g);
      if (num !== '') {
        if (text === '') {
          const keyMinutes = intervals.keys().filter((key) => {
            return intervals.get(key)!!.name.includes('minuto');
          });
          if (type === 'next') {
            const keyed = keyMinutes.find((key) => {
              const interval = intervals.get(key)!!;
              return parseInt(interval.keyCode) > parseInt(num);
            });
            newInterval = intervals.get(keyed!!)!!;
          } else {
            const keyed = keyMinutes.filter((key) => {
              const interval = intervals.get(key)!!;
              return parseInt(interval.keyCode) < parseInt(num);
            });
            newInterval = intervals.get(keyed[keyed.length - 1]!!)!!;
          }
        } else {
          const sameIntervalKeys = intervals.keys().filter((key) => {
            return intervals.get(key)!!.keyCode.includes(text);
          });
          const filterPrevious = sameIntervalKeys.filter((key) => {
            const interval = intervals.get(key)!!;
            const intervalNum = getTextByRegex(interval.keyCode, /\d+/g);
            return parseInt(intervalNum) < parseInt(num);
          });
          const findNext = sameIntervalKeys.find((key) => {
            const interval = intervals.get(key)!!;
            const intervalNum = getTextByRegex(interval.keyCode, /\d+/g);
            return parseInt(intervalNum) > parseInt(num);
          });
          if (type === 'next') {
            if (findNext) {
              newInterval = intervals.get(findNext)!!;
            } else if (filterPrevious) {
              const lastIndex = intervals
                .keys()
                .findIndex(
                  (key) => key === filterPrevious[filterPrevious.length - 1]
                );
              const next = lastIndex + 1;
              const nextKey = intervals.keys()[next];
              if (nextKey) {
                newInterval = intervals.get(nextKey)!!;
              } else {
                const firstKey = intervals.keys()[0];
                newInterval = intervals.get(firstKey)!!;
              }
            }
          } else {
            newInterval = intervals.get(
              filterPrevious[filterPrevious.length - 1]!!
            )!!;
          }
        }
      }
    }
    return newInterval;
  }
}
