import { Routes, RouterModule } from '@angular/router';
import { StockTableComponent } from './stock-table.component';

const routes: Routes = [
  {
    path: '',
    component: StockTableComponent,
  },
];

export const StockTableRoutes = RouterModule.forChild(routes);
