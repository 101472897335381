import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  RENT_DEFAULT_HEADERS,
  RENT_HEADER_DEFAULT_WIDTH,
} from '../../constants/rent-headers.constant';
import { DEFAULT_HEADER_FILTER } from '../../constants/rent.constant';
import { IRentInfos } from '@shared/services/api/trademap/V6/interface/loan-balance.interface';
import { ColDef, SortChangedEvent } from 'ag-grid-community';
import { RentService } from '../../service/rent.service';
import { RentPreferencesService } from '@shared/services/core/custom-preferences/rent/rent-preferences.service';

@Component({
  selector: 'app-rent-grid',
  templateUrl: './rent-grid.component.html',
  styles: [
    `
      .grid-height {
        height: calc(100% - 40px);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RentGridComponent implements OnInit, OnChanges, OnDestroy {
  @Output() public gridRowClicked: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public sortHeaderUpdated: EventEmitter<string | undefined> =
    new EventEmitter<string | undefined>();

  @Input() public sortByField: string = DEFAULT_HEADER_FILTER.field;
  @Input() public gridRef: string = '';
  @Input() public isDesktop: boolean = false;

  public columnDefs: ColDef[] = RENT_DEFAULT_HEADERS;
  private _columnWidth!: any;
  public sortingOrder: ('asc' | 'desc' | null)[] = ['desc', 'asc', null];
  public widthComponent: number = 0;
  public rowData: IRentInfos[] = [];
  public emptyDataMessage: string = 'Não há infomações.';
  public isLoading: boolean = true;

  private _rentSub$!: Subscription;

  constructor(
    public rentService: RentService,
    private _rentPreferences: RentPreferencesService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this._getRentData();
    this._columnWidth = this._rentPreferences.gridHeadersWidth;
    if (!this._columnWidth) this._columnWidth = RENT_HEADER_DEFAULT_WIDTH;
    this.columnDefs = this.customColumns(this._columnWidth, this.columnDefs);
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { sortByField } = changes;
    if (sortByField && sortByField.currentValue) {
      this.sortByField = sortByField.currentValue;
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this._rentSub$.unsubscribe();
  }

  private _getRentData(): void {
    this._rentSub$ = this.rentService.findRents().subscribe({
      next: (response) => {
        this.rowData = response.result;
        this.isLoading = false;
        this.emptyDataMessage = 'Não há infomações.';
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.isLoading = false;
        this.rowData = [];
        this.emptyDataMessage = error.message;
        this.cdr.detectChanges();
      },
    });
  }

  public onSortChange(event: SortChangedEvent): void {
    const sortState = event.columnApi
      .getColumnState()
      .find((column) => column.sort)?.colId;
    this.sortHeaderUpdated.emit(sortState);
    this.cdr.detectChanges();
  }

  public onSelectedRow(rowInfo: any): void {
    this.gridRowClicked.emit(rowInfo.data.cd_stock);
    this.cdr.detectChanges();
  }

  private customColumns(customWidth: any, defaultColumnDefs: any[]): any[] {
    const columns: any[] = [];
    Object.keys(customWidth).forEach((field: string) => {
      const col = defaultColumnDefs.find((item: any) => item.field == field);
      columns.push({ ...col, ...customWidth[field] });
    });
    return columns;
  }

  public onColumnResized(event: { colId: string; actualWidth: number }): void {
    this._columnWidth[event.colId].width = event.actualWidth;
    this._rentPreferences.gridHeadersWidth = this._columnWidth;
    this.cdr.detectChanges();
  }
}
