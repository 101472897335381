<div
  class="w-100 h-100 p-5 round bg-neutral-strong d-inline-flex align-items-center justify-content-between"
>
  <app-tag
    [text]="repushaseInfo.status"
    typeTag="outline_medium"
    [colorNotVariation]="repushaseInfo.statusColor"
  >
  </app-tag>

  <ng-container *ngFor="let data of repurchaseInfos">
    <ng-template
      *ngTemplateOutlet="repurchase; context: { data: data }"
    ></ng-template>
  </ng-container>
</div>

<ng-template #repurchase let-data="data">
  <div class="d-flex flex-column align-items-center justify-content-center">
    <span class="text-neutral-medium fs-5">{{ data.title }}</span>
    <span class="text-white fs-base fs-5 fw-bold">
      {{ repushaseInfo[data.field] }}
    </span>
  </div>
</ng-template>
