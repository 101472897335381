import { ICustodyChartInfos } from '@shared/services/api/nitro-custody-ws/v1/interface/custody.interface';
import { IPerformanceData } from '../interface/performance-report.interface';

export class PerformanceInfosData {
  _buildPerfomanceData(
    performanceByPeriod: ICustodyChartInfos
  ): IPerformanceData {
    return {
      gross_profit: performanceByPeriod.vl_gross_profit,
      gross_loss: performanceByPeriod.vl_gross_loss,
      win_percent: performanceByPeriod.perc_win_operation,
      loss_percent: performanceByPeriod.perc_loss_operation,
      zero_percent: performanceByPeriod.perc_zero_operation,
      total_operation: performanceByPeriod.total_operation,
      win_operation: performanceByPeriod.qtty_win_operation,
      zero_operation: performanceByPeriod.qtty_zero_operation,
      loss_operation: performanceByPeriod.qtty_loss_operation,
      avg_profit_and_loss: performanceByPeriod.avg_pnl,
      avg_profit: performanceByPeriod.avg_win_operation,
      highest_won_operation: performanceByPeriod.vl_highest_win_operation,
      highest_lose_operation: performanceByPeriod.vl_highest_loss_operation,
      highest_win_streak: performanceByPeriod.qtty_win_streak,
      highest_loss_streak: performanceByPeriod.qtty_loss_streak,
      avg_loss: performanceByPeriod.avg_loss_operation,
      reason_profit_loss: performanceByPeriod.vl_reason_pnl,
      has_day_trade: false,
      has_swing: false,
      liquid_profit: performanceByPeriod.vl_profit,
      total_order: performanceByPeriod.total_order,
      open_order: performanceByPeriod.qtty_open_order,
      closed_order: 0,
      executed_order: performanceByPeriod.qtty_exec_order,
      parc_canceled_order: 0,
      canceled_order: performanceByPeriod.qtty_canc_order,
    };
  }
}
