import { validStringValueOrDefault } from 'src/app/utils/utils.book';

export class MinibookFillerTranformer {
  private constructor(private stream: ReadableStream) {}
  private lastBookData: any = {};

  private transformer = new TransformStream({
    transform: (chunk, controller) => {
      const currentBookData = this.prepareData(chunk);
      controller.enqueue(currentBookData);
    },
  });

  private applyTransformer() {
    return this.stream.pipeThrough(this.transformer);
  }

  private prepareData(message: any) {
    const item = message.data.item;
    if (!this.lastBookData[item]) this.lastBookData[item] = {};
    for (const key in message.data) {
      message.data[key] = validStringValueOrDefault(
        message.data[key],
        this.lastBookData[item][key]
      );
    }
    this.lastBookData[item] = message.data.isEndOfSnap
      ? this.lastBookData[item]
      : message.data;
    return message;
  }

  static transformStream(stream: ReadableStream): ReadableStream {
    return new MinibookFillerTranformer(stream).applyTransformer();
  }
}
