<div class="h-100 overflow-x-hidden overflow-y-auto">
  <app-dropdown [style.maxHeight.px]="50" *ngFor="let group of groupTools">
    <a
      type="button"
      class="nav-link p-0 pt-2 lh-sm round-top"
      [title]="
        group.active && group.selectedTool.activeTitle
          ? group.selectedTool.activeTitle!!
          : group.selectedTool.title
      "
      appDropdownButton
      placement="right-start"
      strategy="fixed"
    >
      <div
        [ngClass]="{ 'border-separator': group.title === toolsEnum.LOCKER }"
        class="d-flex btn-fill-color btn-gap-2"
      >
        <button
          *ngIf="group.selectedTool.htmlIcon || group.selectedTool.activeIcon"
          [innerHtml]="
            (group.active && group.selectedTool.activeIcon
              ? group.selectedTool.activeIcon!!
              : group.selectedTool.htmlIcon!!
            ) | safeHtml
          "
          id="appDropdownButtonIconTools"
          (click)="useThisTool('SELECTED', $event, group, group.selectedTool)"
          type="button"
          [ngClass]="{
            'btn-fill-color': !group.active,
            'mt-2': group.title === toolsEnum.LOCKER
          }"
          class="ml-1 btn-fill-primary btn btn-remove-min-width w-75 p-0"
        ></button>
        <button
          *ngIf="group.tools.length > 1 || group.alwaysShowArrow"
          (click)="clickArrow('SELECTED', $event, group, group.selectedTool)"
          [title]="group.title"
          id="appDropdownButtonTools"
          type="button"
          class="ml-n1 dropdown d-flex flex-column align-items-center justify-content-center btn btn-fill-primary btn-remove-min-width w-25 p-0"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="material-icons-outlined">navigate_next</span>
        </button>
      </div>
    </a>
    <div *ngIf="group.title === toolsEnum.ICONS" class="contents">
      <div
        id="id_menuBoxIcons"
        title=""
        class="menuBoxIcons"
        [style.maxHeight.px]="maxHeightBoxIcon"
      >
        <div class="wrapper">
          <div class="d-flex cursor-pointer wrapperGroup">
            <div
              [ngClass]="{ isActive: option.active }"
              class="d-flex align-items-center justify-content-center wrapperIcon"
              *ngFor="let option of group.options"
              (click)="
                useThisTool(
                  'OPTION',
                  $event,
                  group,
                  group.tools[0],
                  true,
                  option
                )
              "
            >
              <div class="d-flex justify-content-center wrapperSvg">
                <span
                  *ngIf="option.svgIcon"
                  [innerHtml]="option.svgIcon | safeHtml"
                ></span>
              </div>
            </div>
          </div>
          <div>
            <div *ngIf="chunks.length !== 0" class="list">
              <div class="w-100">
                <cdk-virtual-scroll-viewport
                  class="w-100"
                  style="height: 250px"
                  itemSize="24"
                  minBufferPx="200"
                  id="virtualScrollEmojis"
                >
                  <div
                    *cdkVirtualFor="let chunk of chunks; let last = last"
                    class="heightChunk wrapperGroupEmoji d-flex"
                  >
                    <div
                      *ngFor="let emoji of chunk"
                      [ngClass]="{ wrapperEmoji: !emoji.isTitle }"
                    >
                      <div
                        [id]="emoji.title"
                        *ngIf="emoji.isTitle"
                        class="wrapperTitle"
                      >
                        <div class="d-flex align-items-center title">
                          {{ emoji.title }}
                        </div>
                      </div>
                      <img
                        *ngIf="!emoji.isTitle"
                        class="float-left cursor-pointer emoji"
                        [src]="emoji.url"
                        decoding="async"
                        width="24"
                        height="24"
                        alt=""
                        draggable="false"
                        (click)="selectEmoji(emoji, group)"
                      />
                    </div>
                  </div>
                </cdk-virtual-scroll-viewport>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="group.title != toolsEnum.ICONS" class="contents">
      <li
        [ngClass]="{ 'd-none': !tool.htmlIcon }"
        *ngFor="let tool of group.tools"
      >
        <a
          [ngClass]="{
            'text-brand-primary': tool.codTool === group.selectedTool.codTool
          }"
          title=""
          (click)="useThisTool('SELECTED', $event, group, tool, false)"
          type="button"
          class="dropdown-item d-flex align-items-center gap-2 px-2"
        >
          <div
            *ngIf="tool.htmlIcon"
            [innerHtml]="tool.htmlIcon | safeHtml"
          ></div>
          {{ tool.title }}
        </a>
      </li>
    </div>
  </app-dropdown>
</div>
