import { Injectable } from '@angular/core';
import { IStockChartParamsChannel } from '../interface/stock-chart.interface';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { MarketDispatcher } from '@shared/cheetah/dispatcher/market.dispatcher';
import { getFieldsByChannel } from '@shared/cheetah/cheetah.const';
@Injectable({
  providedIn: 'root',
})
export class StockChartChannelService {
  constructor(private cheetahService: MarketDispatcher) {}

  subscribe(params: IStockChartParamsChannel) {
    let toSub: SubscribeParam[] = [];
    const { book, quote, volume, candle, orderChart } = params;
    toSub = this.setChannelSub(book, toSub, 'MINIBOOK');
    toSub = this.setChannelSub(quote, toSub, 'QUOTE');
    toSub = this.setChannelSub(volume, toSub, 'VOLUME');
    toSub = this.setChannelSub(candle, toSub, 'CANDLE');
    toSub = this.setChannelSub(orderChart, toSub, 'ORDER_CHART');
    this.cheetahService.subscribe(toSub);
  }

  unsubscribeChannels(params: IStockChartParamsChannel) {
    let toUnsub: SubscribeParam[] = [];
    const { book, quote, volume, candle, orderChart } = params;
    toUnsub = this.setChannelSub(book, toUnsub, 'MINIBOOK');
    toUnsub = this.setChannelSub(quote, toUnsub, 'QUOTE');
    toUnsub = this.setChannelSub(volume, toUnsub, 'VOLUME');
    toUnsub = this.setChannelSub(candle, toUnsub, 'CANDLE');
    toUnsub = this.setChannelSub(orderChart, toUnsub, 'ORDER_CHART');
    if (toUnsub.length) {
      this.cheetahService.unsubscribe(toUnsub);
    }
  }

  private setChannelSub(
    param: SubscribeParam | undefined,
    toSub: SubscribeParam[],
    channel: string
  ) {
    if (param) {
      param.channel_cheetah = channel;
      param.fields = getFieldsByChannel(channel).toString();
      toSub.push(param);
    }
    return toSub;
  }
}
