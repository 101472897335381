import { Injectable } from '@angular/core';
import { CHEETAH_CHANNEL } from '@shared/cheetah/cheetah.const';
import { MarketDispatcher } from '@shared/cheetah/dispatcher/market.dispatcher';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { StreamReadResponse, RxEvent } from './rx-event';

@Injectable({
  providedIn: 'root',
})
export class TradeChannel {
  instanciated!: boolean;
  static instance: TradeChannel;
  static channel = CHEETAH_CHANNEL.TRADE;

  constructor(
    private _rxEvent: RxEvent,
    private cheetahService: MarketDispatcher
  ) {
    return (TradeChannel.instance = TradeChannel.instance || this);
  }

  instanciate(): void {
    if (!this.instanciated) {
      this.instanciated = true;
    }
  }

  onEvents(): StreamReadResponse {
    return this._rxEvent.read(TradeChannel.channel);
  }

  subscribe(params: SubscribeParam) {
    this.cheetahService.subscribe({
      channel_cheetah: TradeChannel.channel,
      itemsArray: params.itemsArray,
    });
  }

  unsubscribe(params: SubscribeParam) {
    if (!params) return;
    this.cheetahService.unsubscribe({
      channel_cheetah: TradeChannel.channel,
      itemsArray: params.itemsArray,
    });
  }
}
