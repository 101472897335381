<form
  [formGroup]="recoveryPassForm"
  (ngSubmit)="resetPassword()"
  *ngIf="!isCompleted; else congratulations"
>
  <h3>Crie sua nova senha :)</h3>
  <div>
    <fla-input
      formControlName="password"
      label="Senha"
      hasError="invalid"
      type="password"
      [margin]="'mb-4'"
      inputPassword
      [requiredText]="errorMessages.password"
      [isRequired]="true"
      (flaBlur)="detectChanges()"
    >
    </fla-input>
    <password-validation
      *ngIf="password?.dirty || password?.touched"
      [inputState]="password.errors"
    ></password-validation>
    <!-- passwordValition -->
  </div>
  <div>
    <fla-input
      formControlName="confirm_password"
      label="Confirme a Senha"
      hasError="invalid"
      type="password"
      [margin]="'mb-5'"
      [requiredText]="errorMessages.confirm_password"
      inputPassword
      [isRequired]="true"
    >
    </fla-input>
  </div>
  <rocket-button
    label="Salvar nova senha"
    type="default"
    btnType="submit"
    css="w-100 mb-3"
    [loading]="isLoading"
    [isDisabled]="!isFormValid || isLoading"
  >
  </rocket-button>
  <ng-container *ngIf="erroData.hasError">
    <error-card [message]="erroData.errorMessage"></error-card>
  </ng-container>
</form>
<ng-template #congratulations>
  <div>
    <h3>Pronto! Sua senha foi alterada :)</h3>
    <h4 class="my-5">
      Agora é só entrar na sua conta e aproveitar todas as vantagens do
      RocketTrader!
    </h4>

    <rocket-button
      label="Entrar na minha conta"
      type="default"
      btnType="button"
      css="w-100 mb-3"
      (rocketClick)="doRecaptcha('CLICK')"
      [loading]="authenticating"
    >
    </rocket-button>

    <app-timer-resend
      *ngIf="displayCountDown"
      [seconds]="timeRedirect"
      message="Redirecionamento Automático "
      (timerCompleted)="doRecaptcha('COUNT_DOWN')"
    ></app-timer-resend>

    <app-recaptcha
      #recaptcha
      size="invisible"
      (resolved)="authenticate($event)"
      (errored)="onRecaptchaError()"
    ></app-recaptcha>
  </div>
</ng-template>
