import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FILTERS_INDICATORS_GROUP } from '@shared/components/screening/stock-screening/constants/constants';
import { ISideConditional } from '@shared/components/screening/stock-screening/interfaces/indicator.interface';
import { FieldsForm } from './fields-form';
import { DynamicControlService } from '@shared/rocket-components/dynamic-form/dynamic-control.service';
import { RocketModalRef } from '@shared/rocket-components/components/modal/modal-parts/modal-ref.component';
import {
  ROCKET_MODAL_DATA,
  RocketModalService,
} from '@shared/rocket-components/components';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-edit-params-conditions-modal',
  templateUrl: './edit-params-conditions-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditParamsConditionsModalComponent
  extends RocketModalRef
  implements OnInit
{
  public form!: FormGroup;
  public fieldsForm!: any[];
  public filterIndicators!: any;
  public typeForm: 'INDICATOR' | 'CUSTOM' | 'QUOTATION' | 'CANDLE_PATTERNS' =
    'INDICATOR';
  private detectChangesFieldsSubject = new Subject<void>();
  private detectChangesFieldsCustomSubject = new Subject<void>();

  constructor(
    @Inject(ROCKET_MODAL_DATA)
    public data: ISideConditional,
    private _rocketModalService: RocketModalService,
    private formBuilder: FormBuilder,
    private dynamicControlService: DynamicControlService,
    private cdr: ChangeDetectorRef
  ) {
    super(_rocketModalService);
  }

  ngOnInit(): void {
    this.detectChangesFieldsSubject
      .pipe(debounceTime(20))
      .subscribe(this.updateFields);
    this.detectChangesFieldsCustomSubject
      .pipe(debounceTime(20))
      .subscribe(this.updateFieldCustom);
    this.typeForm = this.data.type;
    this.data.type === 'INDICATOR' && this.createFormIndicator();
    this.data.type === 'CUSTOM' && this.createFormCustom();
    this.cdr.detectChanges();
  }

  private unSubscribe(): void {
    !!this.detectChangesFieldsSubject &&
      this.detectChangesFieldsSubject.unsubscribe();
    !!this.detectChangesFieldsCustomSubject &&
      this.detectChangesFieldsCustomSubject.unsubscribe();
  }

  private createFormIndicator(): void {
    this.filterIndicators = FILTERS_INDICATORS_GROUP.map(
      (item) => item.indicators
    )
      .flat()
      .find((item: any) => item.type === this.data.value);
    this.fieldsForm = [];
    this.form = this.formBuilder.group({});
    const form: any = new FieldsForm(
      this.dynamicControlService,
      this.data.value as string
    );
    this.fieldsForm = form.createFields();
    this.form = form.createForm();
    this.detectChangesFieldsSubject.next();
  }

  private updateFields = () => {
    Object.keys(this.data.filter).forEach((field: string) => {
      this.form.get(field)?.setValue(this.data.filter[field]);
    });
    this.cdr.detectChanges();
  };

  private createFormCustom(): void {
    this.fieldsForm = [];
    this.form = this.formBuilder.group({});
    const form: any = new FieldsForm(this.dynamicControlService, 'custom');
    this.fieldsForm = form.createFields();
    this.form = form.createForm();
    this.detectChangesFieldsCustomSubject.next();
  }

  private updateFieldCustom = () => {
    this.form.get('custom')?.setValue(this.data.value);
    this.cdr.detectChanges();
  };

  public onSave(): void {
    this.data.type === 'INDICATOR' && (this.data.filter = this.form.value);
    if (this.data.type === 'CUSTOM') {
      this.data.value = this.form.get('custom')?.value;
      this.data.description = this.form.get('custom')?.value;
    }
    this.unSubscribe();
    this.close(this.data);
  }

  public onClose(): void {
    this.unSubscribe();
    this.close();
  }

  public showField(key: string): boolean {
    return (
      key === 'time_period' ||
      key === 'acceleration' ||
      key === 'maximum' ||
      key === 'slow_period' ||
      key === 'fast_period'
    );
  }
}
