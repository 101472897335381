import { Injectable } from '@angular/core';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { CHART_COLORS, CHART_COLORS_LIGHT } from '@shared/tiger-chart/colors';
import { BehaviorSubject } from 'rxjs';
import {
  CursorTooltipSvgAnnotation,
  SeriesInfo,
  adjustTooltipPosition,
} from 'scichart';
import { getKeyWithHighestValue } from 'src/app/utils/utils.functions';

interface ITooltipOptions {
  isFlexWrap: boolean;
  addZero: boolean;
}

interface ITooltipParams {
  seriesName: string;
  color: string;
  xValue: string;
  yValue: string;
  date: number;
  isFirstIndex: boolean;
}

export interface IForeignersTooltipsParams {
  params: ITooltipParams[];
  date: number;
  dateFormatted: string;
}

@Injectable({
  providedIn: 'root',
})
export class FlowInvestorsService {
  public hideTyper$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private _themeService: ThemePreferencesService) {}

  public tooltipSvgTemplate = (
    seriesInfos: SeriesInfo[],
    svgAnnotation: CursorTooltipSvgAnnotation,
    width: number,
    initialHeight: number,
    conditions: ITooltipOptions | undefined = undefined
  ): string => {
    const seriesInfo = seriesInfos[0];
    if (!seriesInfo) return '<svg></svg>';
    let info = '';
    const flex = conditions?.isFlexWrap ? 'flex-wrap gap-1' : 'flex-column';
    let height = initialHeight;

    seriesInfos.forEach((element) => {
      if (!conditions?.addZero && element.yValue == 0) return;
      const circle = `<div style="background: ${element.stroke};width:5px;height:5px;border-radius:100%;"></div>`;
      const gap = 'gap-1';
      const gapSeriesName = 'gap-1';
      const seriesName = `<span class="fs-6">${element.seriesName}:</span>`;
      height += conditions?.isFlexWrap ? 12 : 18;
      info += `<div class="d-flex align-items-center ${gap}">
        ${circle}
        <div class="d-flex align-items-center ${gapSeriesName}">
          ${seriesName}
          <span class="fs-6">${element.formattedYValue}</span>
        </div>
      </div>`;
    });

    const x = seriesInfo ? seriesInfo.formattedXValue : '';
    adjustTooltipPosition(width, initialHeight, svgAnnotation);

    const bgColor = this._themeService.isDarkTheme()
      ? CHART_COLORS.NEUTRAL_STRONG
      : CHART_COLORS_LIGHT.NEUTRAL_STRONG;

    const html = `
      <div style="width:${width}px" class="pl-2 d-flex flex-column text-white">
        <small class="fs-6">${x}</small>
        <small class="d-flex ${flex}">${info}</small>
      </div>
    `;

    const svg = `
    <svg width="${width}" height="${height}">
      <rect x="0" width="100%" height="100%" y="0" rx="4" style="fill: ${bgColor};"/>
      <svg width="100%">
        <foreignobject class="node" width="100%" height="100%">
        ${html}    
        </foreignobject>
      </svg>
    </svg>`;
    return svg;
  };

  public foreignersTooltip(
    series: SeriesInfo[]
  ): IForeignersTooltipsParams | null {
    if (!series || !series?.length) return null;
    const xValues: any = {};
    const params: ITooltipParams[] = [];
    let date: number = 0,
      dateFormatted: string = '';

    for (let index = 0, len = series.length; index < len; index++) {
      if (!series[index].isHit) continue;
      params.push({
        seriesName: series[index].seriesName,
        color: series[index].stroke,
        xValue: series[index].formattedXValue,
        yValue: series[index].formattedYValue,
        date: series[index].xValue,
        isFirstIndex: series[index].dataSeriesIndex === 0,
      });
      xValues[series[index].xValue] = (xValues[series[index].xValue] || 0) + 1;
    }

    if (!params?.length) return null;
    if (Object.keys(xValues).length > 1) {
      const xValue = getKeyWithHighestValue(xValues);
      date = xValue;
      dateFormatted = this.formatDateXAxis(xValue);
    } else if (params.length) {
      date = params[0].date;
      dateFormatted = this.formatDateXAxis(params[0].date);
    }

    return { params, date, dateFormatted };
  }

  public formatDateXAxis(date: number, period: string = ''): string {
    const value = new Date(date * 1000).toLocaleDateString('pt-BR');
    if (period === 'month') return value.slice(3, 12);
    return value;
  }
}
