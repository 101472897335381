import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalOpenService {
  private _isDisplayingSomeModalSubject = new Subject<boolean>();

  get onContentChange$(): Observable<boolean> {
    return this._isDisplayingSomeModalSubject.asObservable();
  }

  dispatchDisplaying(isOpen: boolean) {
    this._isDisplayingSomeModalSubject.next(isOpen);
  }
}
