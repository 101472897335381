import { Component, Input, OnInit } from '@angular/core';
import {
  PasswordValidators,
  PasswordValidatorsMessage,
} from '@shared/validators/password-validator';

@Component({
  selector: 'password-validation',
  templateUrl: './password-validation.component.html',
})
export class PasswordValidationComponent implements OnInit {
  @Input() inputState!: PasswordValidators | any;

  get validatorMessages() {
    return PasswordValidatorsMessage;
  }

  get dataState() {
    return (
      this.inputState ?? {
        passwordLength: false,
        passwordLowerCase: false,
        passwordUpperCase: false,
        passwordNumber: false,
        passwordSpecialChar: false,
        passwordSequentialNumbers: false,
      }
    );
  }

  get keys() {
    return Object.keys(this.dataState).filter(
      (field) => this.validatorMessages[field]
    );
  }

  constructor() {}

  ngOnInit() {}
}
