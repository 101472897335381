import { NgModule } from '@angular/core';

import { OrdersHistoryCoreModule } from '@core/components/orders-history-core/orders-history.module';
import { OrdersHistoryRoutes } from '@core/components/orders-history-core/orders-history.routing';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';

@NgModule({
  declarations: [],
  imports: [OrdersHistoryRoutes, OrdersHistoryCoreModule],
  providers: [StockChartService],
})
export class OrdersHistoryRouteModule {}
