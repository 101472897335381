import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangelogComponent } from './changelog.component';
import { ChangelogRoutes } from './changelog.routing';

@NgModule({
  imports: [CommonModule, ChangelogRoutes],
  declarations: [ChangelogComponent],
})
export class ChangelogModule {}
