import { RTChartScichartBase } from '../../chart-base-scichart';
import { TIGER_INDICATORS_MENU_ENUM } from '../../enum/tiger-chart.enum';
import { BaseIndicator } from '../../indicators/indicators.types';

export default class MenuItemBase {
  label: string = '';
  icon: string = '';
  submenu!: MenuItemBase[];
  divisor: boolean = false;

  id!: TIGER_INDICATORS_MENU_ENUM;
  onCLick(chart: RTChartScichartBase, indicator: BaseIndicator): void {}
}
