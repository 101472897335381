import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ALERT_STATUS_ENUM,
  IAlert,
  IAlertSettings,
  IAlertTransaction,
} from '@core/interface';
import { RestService } from '@services/rest/rest.service';
import { ToastService } from '@services/toast.service';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { catchError, map, merge, Observable, of, Subject, tap } from 'rxjs';
import {
  formatByTick,
  getTextByRegex,
  groupBy,
  toValue,
} from 'src/app/utils/utils.functions';

@Injectable({
  providedIn: 'root',
})
export class AlertService extends RestService {
  override _url: string = 'api/trademap';
  private _alertsDict!: Map<number, any>;
  private _alertsQty: number = 0;
  private _newAlertCreated$: Subject<IAlert> = new Subject();
  private _alertEdited$: Subject<any> = new Subject();
  private _alertDeleted$: Subject<number> = new Subject();
  private _updateAlertsQty$: Subject<number> = new Subject();
  private _alertSetting$: Subject<IAlertSettings> = new Subject();

  get newAlertObservable(): Observable<IAlertTransaction> {
    return this._newAlertCreated$.asObservable().pipe(
      map((data) => ({
        add: [{ ...data, ds_status_custom: 'Ativo' }],
      }))
    );
  }

  get updateAlertObservable(): Observable<IAlertTransaction> {
    return this._alertEdited$.asObservable().pipe(
      map((data) => ({
        update: [
          {
            ...{
              ...data.alert,
              previous_id_trade_system: data.id_trade_system,
            },
            //ds_status_custom: 'Ativo',
          },
        ],
      }))
    );
  }

  get deleteAlertObservable(): Observable<IAlertTransaction> {
    return this._alertDeleted$.asObservable().pipe(
      map((idTradeSystem) => ({
        remove: [{ id_trade_system: idTradeSystem }],
      }))
    );
  }

  get updateAlertsQty(): Observable<number> {
    return this._updateAlertsQty$.asObservable();
  }

  get handleAlertSettings(): Observable<IAlertSettings> {
    return this._alertSetting$.asObservable();
  }

  constructor(
    http: HttpClient,
    private toastService: ToastService,
    private _globalStock: GlobalSelectedStockSubscription
  ) {
    super(http);
  }

  allAlerts() {
    return this._alertsDict ? [...this._alertsDict.values()] : [];
  }

  getAllAlerts = () =>
    this.post<{ success: boolean; result: IAlert[] }>(
      'v2/alert/get-all-alerts',
      {}
    ).pipe(
      map((res) => {
        const { result } = res.data || {};
        return {
          alertsMap: groupBy(result, (dt: any) => dt.id_trade_system, false),
          alerts: result,
        };
      }),
      catchError(() => of({ alertsMap: new Map<number, any>(), alerts: [] })),
      tap((data: { alertsMap: Map<number, any>; alerts: IAlert[] }) => {
        this._alertsDict = data.alertsMap;
        this.handleUpdateAlertsQty('UP');
      })
    );

  deleteAlert = async (idTradeSystem: number) => {
    this._alertsDict.delete(idTradeSystem);
    this.handleUpdateAlertsQty('DOWN');
  };

  processAlertByValue = (data: any) => {
    const { cdStock, value } = data;
    const alerts = this.allAlerts();
    const alertData: IAlert[] = alerts.filter(
      (alert) => alert.vl_value == value && alert.cd_stock == cdStock
    );
    if (alertData.length) {
      alertData.forEach((alert) => {
        this._alertsDict.set(alert.id_trade_system, alert);
      });
    }
    return alertData;
  };
  updateAlertsIndexDB(data: any) {
    const cdStock = getTextByRegex(data.ds_title, /\b[A-Z][A-Z0-9]+\b/);
    const value = toValue(
      getTextByRegex(
        data.ds_title,
        /\b\.?(?:(?:\d{1,3}[,. ])*\d{1,3}(?:\.\d{2})?|\d+\.\d+|\d+)\.?/
      )
    );
    /*
      o metodo getAlertByValue retorna um array pois pode ser que o usuario tenha colocado + de um alerta no mesmo preço,
      gerando assim id_trade_system diferentes
    */
    return this.processAlertByValue({
      ...{ cdStock, value },
      ...data,
    });
  }
  onEvents = () =>
    merge(
      this.newAlertObservable,
      this.updateAlertObservable,
      this.deleteAlertObservable
    );

  createAlert(
    cd_stock: string,
    id_exchange: number,
    price: number,
    direction: string,
    urgente: boolean,
    tick_size_denominator: number
  ) {
    return this.post<any>('v2/alert/create', {
      cd_stock,
      id_exchange,
      price,
      direction,
      urgente,
    }).pipe(
      map((res: any) => {
        this.saveAlerts([res.data.result]);
        return res.data;
      }),
      tap(() => {
        this.toastService.showToast(
          'success',
          `Seu alerta de ${cd_stock} foi criado a ${formatByTick(
            price.toString(),
            tick_size_denominator
          )}`
        );
      })
    );
  }

  saveAlerts = (alerts: Array<any>) => {
    alerts && alerts.length && this.addAlerts(alerts);
  };

  addAlerts(alerts: Array<any>) {
    alerts.forEach((alert: any) => {
      this._alertsDict.set(alert.id_trade_system, alert);
    });
  }

  updateAlerts = async (alerts: Array<IAlert>) => {
    this.saveAlerts(alerts);
  };

  updateAlert(
    id_trade_system: number,
    price: number,
    direction: string,
    tick_size_denominator: number,
    cd_stock: string,
    boxID?: string
  ) {
    return this.post<any>('v2/alert/update', {
      id_trade_system,
      price,
      direction,
    }).pipe(
      tap((res: any) => {
        this.toastService.showToast(
          'success',
          `Seu alerta de ${cd_stock} foi editado a ${formatByTick(
            price.toString(),
            tick_size_denominator
          )}`
        );
        this._alertsDict.delete(id_trade_system);
        this.updateAlerts([
          {
            ...res.data.result,
            ...{ first_box_id: boxID },
          },
        ]);
      }),
      map((resp: any) => resp.data)
    );
  }

  public excludeAlert(
    id_trade_system: number,
    cd_stock: string,
    price: number,
    tick_size_denominator: number
  ): Observable<any> {
    return this.post<any>('v1/alert/exclude', { id_trade_system }).pipe(
      map((res) => {
        this.toastService.showToast(
          'success',
          `Seu alerta de ${cd_stock} a ${formatByTick(
            price.toString(),
            tick_size_denominator
          )} foi cancelado`
        );
        this.deleteAlert(id_trade_system);
        return res.data;
      }),
      catchError((res) => {
        return res.data;
      })
    );
  }

  addNewAlertInOrderHistory(alert: IAlert): void {
    alert.isSelected =
      this._globalStock.getGlobalStockSelected().cd_stock_order ===
      alert.cd_stock;
    this._newAlertCreated$.next(alert);
    this.handleUpdateAlertsQty('UP');
  }

  updateAlertInOrderHistory(id_trade_system: number = 0, alert: IAlert): void {
    this._alertEdited$.next({ id_trade_system, alert });
  }

  updateAlertStatus(alert: IAlert): void {
    const alertWithUpdatedInfo: IAlert = {
      ...alert,
      ...{
        cd_status: ALERT_STATUS_ENUM.COMPLETED,
        ds_status_custom: 'Concluído',
        ds_status: 'Completada',
      },
    };
    this.updateAlertInOrderHistory(alert.id_trade_system, alertWithUpdatedInfo);
    this.updateAlerts([alertWithUpdatedInfo]);
  }

  public setAlertsQty(qty: number): void {
    this._alertsQty = qty;
  }

  public deleteAlertInOrderHistory(id_trade_system: number): void {
    this._alertDeleted$.next(id_trade_system);
    this.handleUpdateAlertsQty('DOWN');
  }

  public handleUpdateAlertsQty(eventType: 'UP' | 'DOWN'): void {
    let newAlertsQty =
      eventType === 'UP' ? this._alertsQty + 1 : this._alertsQty - 1;
    if (newAlertsQty < 0) newAlertsQty = 0;
    this._alertsQty = newAlertsQty;
    this._updateAlertsQty$.next(newAlertsQty);
  }

  public displayAlertSetting(
    action: 'EDIT' | 'DELETE' | 'REFRESH',
    alert: IAlert
  ): void {
    this._alertSetting$.next({ action, alert });
  }

  updateAlertDispatch(
    item: {
      idTradeSystem?: number;
    },
    alert: any
  ) {
    const orderAlert = alert;
    orderAlert.isSelected =
      orderAlert.cd_stock ===
      this._globalStock.getGlobalStockSelected()?.cd_stock_order;
    this.updateAlertInOrderHistory(item.idTradeSystem, orderAlert);
  }
}
