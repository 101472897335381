import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-default-context-menu',
  template: '',
})
export class BaseContextMenuComponent implements AfterViewInit {
  position!: { top: number; left: number };
  constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef) {}
  ngAfterViewInit(): void {
    this.repositionContextMenu();
  }

  private repositionContextMenu() {
    const rect =
      this.elementRef.nativeElement.firstChild.getBoundingClientRect();
    const contextWidth = rect.width;
    const contextHeigth = rect.height;
    const width = window.innerWidth;
    const height = window.innerHeight;
    const componentWidth = contextWidth + rect.x;
    const componentHeight = contextHeigth + rect.y;
    if (componentWidth > width) {
      this.position.left = rect.x - (componentWidth - width + 10);
    }
    if (componentHeight > height) {
      this.position.top = rect.y - (componentHeight - height + 10);
    }
    this.cdr.detectChanges();
  }
}
