import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { THEMES } from '@shared/services/core/custom-preferences/theme/theme-preferences.interface';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { TrademapCDNService } from '@shared/services/api/cdn/trademap-cdn.service';
import { IBrokersToConnect } from '@shared/services/api/trademap/V9/models/brokers-to-connect.interface';
import { filter, debounceTime, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-card-broker',
  templateUrl: './card-broker.component.html',
  styles: [
    `
      :host(app-card-broker) {
        .card-broker {
          width: 345px;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardBrokerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public broker!: IBrokersToConnect;

  @Output() brokerSelected: EventEmitter<any> = new EventEmitter();
  @Output() brokerDisconnect: EventEmitter<any> = new EventEmitter();

  public svgDataUrl: SafeResourceUrl = '';
  private _cdr = inject(ChangeDetectorRef);
  private _trademapCDN = inject(TrademapCDNService);
  public isDarkTheme: boolean = true;
  private onDestroy$ = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private themeService: ThemePreferencesService
  ) {}
  
  ngOnInit() {
    this.initializeThemeChange();
    this.loadSvgContent();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['broker']?.currentValue) this.loadSvgContent();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initializeThemeChange() {
    this.isDarkTheme = this.themeService.isDarkTheme();
    this.themeService
      .themeActiveObservable()
      .pipe(takeUntil(this.onDestroy$), debounceTime(100))
      .subscribe((theme) => {
        this.isDarkTheme = theme === THEMES.dark;
        this.cdr.detectChanges();
      });
  }

  loadSvgContent() {
    if (!this.broker.brokerLogo) return;
    this._trademapCDN
      .getBrokerLogo(this.broker.brokerLogo)
      .pipe(filter((logo) => logo !== null))
      .subscribe((logo) => {
        this.svgDataUrl = logo!;
        this._cdr.detectChanges();
      });
  }

  public selectBroker() {
    if (this.broker.hasAccount) return;
    this.brokerSelected.emit(this.broker);
  }

  public disconnectBroker() {
    if (!this.broker.hasAccount) return;
    this.brokerDisconnect.emit(this.broker);
  }
}
