import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface IKeyboardControlEvent {
  elementID: string;
  componentID: string;
  stocksID: string;
  action: KEYBOARD_FOCUS_EVENT;
  handleFocus?: boolean;
  disableFocus?: boolean;
}

export enum KEYBOARD_FOCUS_EVENT {
  RIGTH = 'RIGTH',
  LEFT = 'LEFT',
  INITIAL = 'INITIAL',
  SELECT = 'SELECT',
}

@Injectable({
  providedIn: 'root',
})
export class KeyboardControlsService {
  public onKeyPressed = new Subject<IKeyboardControlEvent>();

  sendMessage(params: IKeyboardControlEvent) {
    this.onKeyPressed.next(params);
  }
}
