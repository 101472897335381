import { Injectable } from '@angular/core';
import { SciChartSurface } from 'scichart';
import { CANDLE_IDS } from '../constants/tiger-chart.constants';

@Injectable({
  providedIn: 'root',
})
export class ScrollChartService {
  canScrollChart(parentSurface: SciChartSurface, pixel: number) {
    const renderableSeries = parentSurface.renderableSeries.getById(
      CANDLE_IDS.MAIN_SERIE
    );
    if (renderableSeries) {
      const size = renderableSeries.dataSeries.count();
      const xAxes = parentSurface.xAxes.get(0);
      if (xAxes) {
        const visibleRange = xAxes.visibleRange;
        if (visibleRange.min < size - 1.5 || pixel > 0) {
          return true;
        }
      }
    }
    return false;
  }
}
