import {
  CategoryAxis,
  EClipMode,
  ICategoryAxisOptions,
  TSciChart,
} from 'scichart';
import { ScrollChartService } from '../services/scroll-chart.service';

export class RocketCategoryAxis extends CategoryAxis {
  constructor(
    webAssemblyContext: TSciChart,
    private scrollChartService: ScrollChartService,
    options?: ICategoryAxisOptions
  ) {
    super(webAssemblyContext, options);
  }

  override scroll(pixelsToScroll: number, clipMode: EClipMode): boolean {
    if (
      this.scrollChartService.canScrollChart(this.parentSurface, pixelsToScroll)
    ) {
      return super.scroll(pixelsToScroll, clipMode);
    }
    return false;
  }
}
