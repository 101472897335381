import {
  IWorkSpaceComponet,
  IWorkSpaceDocLayout,
} from 'src/app/core/interface';

export class DocWorkspace {
  public doc!: IWorkSpaceComponet;
  get name() {
    return this.doc.name;
  }

  get headerOptions() {
    return this.doc.metadata.headerOptions;
  }

  get footerOptions() {
    return this.doc.metadata.footerOptions;
  }

  set layout(data: IWorkSpaceDocLayout) {
    this.doc.metadata.layout = data;
  }

  get layout() {
    return this.doc.metadata.layout;
  }

  get id() {
    return this.doc.id;
  }

  get component() {
    return this.doc.metadata.component;
  }

  get headerBg() {
    return this.component?.bg ?? 'bg-neutral-stronger';
  }

  get bodyBg() {
    return this.component?.bg ?? 'bg-neutral-strongest';
  }

  get styles() {
    const styles = {
      left: this.layout.left,
      top: this.layout.top
        ? parseInt(this.layout.top) >= 0
          ? this.layout.top
          : '0px'
        : null,
      width: this.layout.width,
      minWidth: this.layout.minWidth,
      height: this.layout.height,
      minHeight: this.layout.minHeight,
      right: this.layout.right,
      bottom: this.layout.bottom,
    };
    return styles;
  }

  get transform() {
    return this.layout.transform;
  }

  set transform(transform: any) {
    if (!this.layout.transform) {
      this.layout.transform = { x: 0, y: 0 };
      return;
    }
    const { x: eixoX, y: eixoY } = this.layout.transform!;
    const x = Math.max(0, eixoX);
    const y = Math.max(0, eixoY);
    this.layout.transform = {
      x,
      y,
    };
  }

  constructor(component: IWorkSpaceComponet) {
    this.doc = component;
    this.layout = this._validityPositionComponentLayout();
  }

  initSize!: any;
  enterFullScreen() {
    this.initSize = Object.assign({}, this.layout);
    this.layout.originalHeight = this.layout.height;
    this.layout.originalWidth = this.layout.width;
    this.layout.height = '100%';
    this.layout.width = '100%';
    this.layout.top = '0px';
    this.layout.left = '0';
    this.layout.originalTransform = this.layout.transform;
    this.layout.transform = { x: 0, y: 0 };
    this.layout.isMaximized = true;
  }
  exitFullScreen() {
    if (!this.initSize) {
      return;
    }
    this.layout.height = this.initSize.height;
    this.layout.width = this.initSize.width;
    this.layout.originalHeight = undefined;
    this.layout.originalWidth = undefined;
    this.layout.top = this.initSize.top;
    this.layout.left = this.initSize.left;
    this.layout.transform = this.initSize.transform;
    this.layout.originalTransform = this.initSize.transform;
    this.layout.isMaximized = false;
  }

  private _validityPositionComponentLayout(): IWorkSpaceDocLayout {
    const header = document.getElementById('navbar-header');
    if (!header) return this.layout;
    const headerPosition = header.getBoundingClientRect();
    const top = this.layout.top ? parseInt(this.layout.top) : 0;
    if (
      (this.transform && this.transform.y < headerPosition.y) ||
      top < headerPosition.y
    ) {
      this.layout.transform!!.y = 0;
      this.layout.top = '0px';
    }
    return this.layout;
  }
}
