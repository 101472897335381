<app-stock-analysis-header
  #header
  [refComponent]="refComponent"
  [componentId]="componentId"
  [component]="component"
  [isOpenListPersonal]="isOpenListPersonal"
  [stockAnalysisConfigs]="stockAnalysisConfigs"
  [linked]="link"
  [initialListId]="stockAnalysisConfigs.idList"
  [isMoversList]="stockAnalysisConfigs.isNotListPersonal || false"
  (handlerListSelected)="processList($event)"
  (changeNotListPersonal)="changeNotListPersonal($event)"
  (changeOpenListPersonal)="changeOpenListPersonal($event)"
  (handlerConfigChange)="onConfigChange($event)"
></app-stock-analysis-header>

<div
  #stockAnalysis
  class="w-100"
  [ngClass]="isDesktop ? 'component-height' : 'h-100'"
>
  <app-stock-analysis-graph
    #stockAnalysisGraph
    [linked]="link"
    [stockList]="graphData"
    [isMoversList]="false"
    [profitability]="stockAnalysisConfigs.profitability"
    [volatility]="stockAnalysisConfigs.volatility"
    [variation]="stockAnalysisConfigs.variation"
  ></app-stock-analysis-graph>
</div>
