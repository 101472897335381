import { Component, HostListener, Inject, OnInit } from '@angular/core';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { SHORT_CUTS } from '../constants/header.contanst';
import { IShortcutTabs } from '../interfaces/header.interfaces';
import { ModalShortcutsService } from './modal-shortcuts.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'modal-shortcuts',
  templateUrl: './modal-shortcuts.component.html',
})
export class ModalShortcutsComponent extends RocketModalRef implements OnInit {
  public shortCutTabs!: IShortcutTabs[];
  public tabShortCuts: any = SHORT_CUTS;

  @HostListener('document:keydown.escape')
  @HostListener('window:keydown.esc')
  onKeyScapePress() {
    this.close();
  }

  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: any,
    service: RocketModalService,
    private modalShortcutService: ModalShortcutsService
  ) {
    super(service);
  }

  ngOnInit() {
    this.shortCutTabs = this.modalShortcutService.getShortcutTabs();
  }

  public changeTab(tabToDisplay: any) {
    this.shortCutTabs = this.shortCutTabs.map((tab: any) => {
      tab.active = tab.ref === tabToDisplay.ref;
      return tab;
    });
  }
}
