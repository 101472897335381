import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { TIGER_INDICATOR_PARAMETER_TYPE } from '../../../enum/tiger-chart.enum';
import {
  BaseIndicator,
  TTigerChartIndicatorParameter,
  TTigerChartIndicatorRenderSeriesConfig,
} from '../../indicators.types';
import { SettingsModalService } from '../settings-modal.service';
import { LINE_TYPES } from './styles-input.consts';

@Component({
  selector: 'app-styles-input',
  templateUrl: './styles-input.component.html',
  styleUrls: ['./styles-input.component.scss'],
})
export class StylesInputComponent implements AfterViewInit {
  @Input() indicator!: BaseIndicator;
  @Input() refComponent!: string;
  @Input() baseChart!: TWebAssemblyChart;
  @Input() renderSeriesConfig: TTigerChartIndicatorRenderSeriesConfig[] = [];
  @Input() defaultConfigs: TTigerChartIndicatorParameter[] = [];
  cssStaticColor: string = 'static-color-index';
  @ViewChild('colorSelector') colorSelector!: NgSelectComponent;
  thicknessSize = [1, 2, 3, 4];
  typeLines = LINE_TYPES;
  hashValues: any = {};
  formControl = new FormControl(undefined);
  max = Number.MAX_SAFE_INTEGER;
  get parameterType() {
    return TIGER_INDICATOR_PARAMETER_TYPE;
  }

  constructor(private settingsModalService: SettingsModalService) {}

  ngAfterViewInit(): void {
    this.initializeHashValues();
  }

  private initializeHashValues() {
    this.renderSeriesConfig.forEach((style) => {
      this.getValueProperties(style.property);
      this.getValueProperties(style.propertyColor);
      this.getValueProperties(style.propertyThickness);
      this.getValueProperties(style.propertyVisible);
      this.getValueProperties(style.propertyOpacity);
    });
    this.defaultConfigs.forEach((style) => {
      this.hashValues[style.property!!] = this.getValue(style.property!!);
    });
  }

  private getValueProperties(property?: string) {
    if (property) {
      this.hashValues[property] = this.getValue(property);
    }
  }

  changeActive(config: TTigerChartIndicatorRenderSeriesConfig) {
    config.active = !config.active;
    this.indicator.updateStyles(
      this.baseChart,
      config as TTigerChartIndicatorRenderSeriesConfig
    );
  }

  changeColor(config: TTigerChartIndicatorRenderSeriesConfig, color: string) {
    config.color = color;
    this.indicator.updateStyles(this.baseChart, config);
  }

  changeThickness(
    config: TTigerChartIndicatorRenderSeriesConfig,
    thickness: number
  ) {
    config.thickness = thickness;
    this.indicator.updateStyles(this.baseChart, config);
  }

  changeTypeLine(
    config: TTigerChartIndicatorRenderSeriesConfig,
    lineId: number
  ) {
    config.lineTypeId = lineId;
    this.indicator.updateStyles(this.baseChart, config);
  }

  changeOpacity(
    config: TTigerChartIndicatorRenderSeriesConfig,
    opacity: number
  ) {
    config.opacity = opacity;
    this.indicator.updateStyles(this.baseChart, config);
  }

  private getValue(property: string) {
    return this.indicator[property as keyof typeof this.indicator];
  }

  onChange(config: any, event: any, property: string) {
    const value =
      typeof event.value != 'undefined' ? event.value : event.target.value;
    config.valueSelected = value;
    this.indicator.setValue(property as keyof BaseIndicator, value);
  }

  setCssColorPicker(isOpen: boolean) {
    this.cssStaticColor = isOpen
      ? 'static-color-index unset'
      : 'static-color-index';
  }
}
