import { inject, enableProdMode, runInInjectionContext } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { IntegrationService } from 'src/app/desktop/integration.service';
import { environment } from './environments/environment';
import { APP_VERSION } from './environments/version';

declare global {
  interface Window {
    ngIntegration: any;
    chrome: any;
  }
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((ref) => {
    console.log(`The application is running on version ${APP_VERSION}`);
    if (window.chrome.webview)
      runInInjectionContext(ref.injector, () => {
        window.ngIntegration = inject(IntegrationService);
      });
  })
  .catch((err) => console.error(err));
