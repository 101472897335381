import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AccountSelectService {
  public readonly SIMULATOR_TOOLTIP = `
    <div class="vstack gap-1">
      <h3 class="fs-6 m-0">Simulador {status}</h3>
      <span class="fs-7">
        Use o Modo Simulador para operar sem preocupações. Realize negociações
        fictícias em condições reais, sem envolver sua conta real.
      </span>
    </div>
  `;

  public getSimulatorTooltip(isEnable: boolean): string {
    let label = this.SIMULATOR_TOOLTIP;
    label = label.replace('{status}', isEnable ? 'Ativado' : 'Desativado');
    return label;
  }
}
