<div
  [ngClass]="[css, margin]"
  [class]="labelPlacement === 'horizontal' ? 'hstack' : ''"
>
  <label
    *ngIf="label"
    [class]="'form-label ' + labelCss"
    [ngClass]="{ required: isRequired }"
    [for]="refId"
  >
    {{ label }}
  </label>
  <ng-select
    #selectInput
    [virtualScroll]="true"
    [id]="refId"
    [class]="'form-select ' + selectCss"
    [ngClass]="{
      'form-select-sm': isSmall,
      border: hasBorder,
      markArrowKey: markArrowKey
    }"
    [items]="list"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [multiple]="multiple"
    [groupBy]="groupBy"
    [placeholder]="placeholder"
    [closeOnSelect]="!multiple"
    [searchable]="searchable"
    [clearable]="clearable"
    [disabled]="isDisable"
    [formControl]="formControl"
    [notFoundText]="notFoundText"
    [loading]="loading"
    clearAllText="Remover Tudo"
    dropdownPosition="auto"
    [openOnEnter]="openOnEnter"
    [selectOnTab]="selectOnTab"
    [searchFn]="customSearchFn"
    (open)="open()"
    (close)="close()"
    (search)="search($event)"
    (clear)="clear()"
    (add)="add($event)"
    (remove)="remove($event)"
    (change)="change($event)"
  >
    <ng-container *ngIf="multiple">
      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div
          class="ng-value border-text"
          *ngFor="let item of items | slice : 0 : maxItems"
        >
          <span class="ng-value-label">{{ getLabel(item) }} </span>
          <span
            class="ng-value-icon right"
            (click)="clear(item)"
            aria-hidden="true"
          >
            <fla-icon name="close" size="icon-size-micro"></fla-icon>
          </span>
        </div>
        <div
          class="ng-value mr-7 position-absolute pr-3 right-0 text-center"
          *ngIf="items.length > maxItems"
        >
          <span class="ng-value-label">
            <fla-icon name="add" size="icon-size-micro"></fla-icon>
            {{ items.length - maxItems }} outros
          </span>
        </div>
      </ng-template>
      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <input
          id="item-{{ index }}"
          class="form-check-input mr-2 mt-0"
          type="checkbox"
          [ngModel]="item$.selected"
        />
        {{ item[bindLabel] }}
      </ng-template>
    </ng-container>
    <ng-container *ngIf="isHTMLLabel">
      <ng-template ng-option-tmp let-item="item">
        <span class="ng-option-label" [innerHTML]="item.stockLabel"></span>
      </ng-template>
    </ng-container>
  </ng-select>
  <small class="form-text" *ngIf="hint">{{ hint }}</small>
  <small
    class="ng-invalid invalid-feedback d-block"
    *ngIf="isRequired && formControl.invalid && formControl.touched"
  >
    {{ requiredText }}
  </small>
</div>
