import { Injectable } from '@angular/core';
import { CustomPreferencesService } from './api/nitro-ws/v1/custom-preferences.service';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class StockPreferencesService {
  get saveQttyStockEnabled() {
    return this._customPreferencesService.saveQttyGroupStocks;
  }

  get stocksCustomPreferences() {
    return JSON.parse(this._customPreferencesService.listStocksQtty) || {};
  }

  constructor(private _customPreferencesService: CustomPreferencesService) {}

  public setStockQtty(cd_stock: string, qtty: number, standardLot: number) {
    if (this.saveQttyStockEnabled) {
      const stocksSaved = this.stocksCustomPreferences;

      if (qtty === standardLot || !qtty) {
        if (stocksSaved[cd_stock]?.qtty) {
          delete stocksSaved[cd_stock].qtty;
        }

        if (!stocksSaved[cd_stock]?.qtty && !stocksSaved[cd_stock]?.idPeriod) {
          delete stocksSaved[cd_stock];
        }
      } else {
        stocksSaved[cd_stock] = { ...stocksSaved[cd_stock], qtty };
      }

      this._customPreferencesService.listStocksQtty = stocksSaved;
    }
  }

  public getStockSaved(
    cd_stock: string,
    standardLot: number,
    param: string
  ): number {
    const listStocksQtty = this.stocksCustomPreferences;
    const qttyItems = listStocksQtty ? Object.keys(listStocksQtty) : [];
    if (this.saveQttyStockEnabled) {
      const hasStock = qttyItems.includes(cd_stock);
      const stockData = hasStock ? listStocksQtty[cd_stock] : null;

      if (hasStock && stockData && stockData[param]) {
        return stockData[param];
      } else {
        return standardLot;
      }
    }
    return standardLot;
  }

  public formatForm(
    form: FormGroup,
    fieldForm: string,
    cdStock: string,
    standardLot: number,
    fieldSave: string
  ): void {
    form
      .get(fieldForm)
      ?.setValue(this.getStockSaved(cdStock, standardLot, fieldSave));
  }

  public onChangePreferences() {
    return this._customPreferencesService.onChangePreference();
  }
}
