import {
  Component,
  Input,
  ChangeDetectorRef,
  Injector,
  forwardRef,
  EventEmitter,
  Output,
  AfterViewInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { randomId } from '../../utils/functions';
import { MESSAGES } from '../../utils/message.const';
import { BaseComponent } from '@shared/rocket-components/base-componentes';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styles: [
    `
      :host(app-check) {
        .top-negative {
          margin-top: -3px;
        }
        display: contents;
        .padding-right-label {
          padding-left: 0px;
        }
        .width-max {
          width: calc(100% - 64px);
        }
        .form-switch .form-check-input {
          height: 20px !important;
          width: 2.5rem !important;
        }
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FlaCheckComponent),
    },
  ],
})
export class FlaCheckComponent extends BaseComponent implements AfterViewInit {
  @Input() label!: string;
  @Input() css: string = '';
  @Input() subLabelClass: string = 'text-neutral-medium fw-bold fs-6';
  @Input() hasError: string = '';
  @Input() value: string = '';
  @Input() subLabel!: string;
  @Input() requiredText: string = MESSAGES['requiredText'];
  @Input() name!: string;
  @Input() isRequired: boolean = false;
  @Input() inline: boolean = false;
  @Input() isSwitch: boolean = false;
  @Input() isTag: boolean = false;
  @Input() isTextLeft: boolean = false;
  @Input() infoText!: string;
  @Input() set disabled(disabled: boolean) {
    this.disable.next(disabled);
    this._disabled = disabled;
  }
  public _disabled: boolean = false;
  @Input() set isChecked(value: boolean) {
    this.formControl?.setValue(value);
    this.checked = value;
  }
  @Output() flaChange = new EventEmitter<any>();

  public checked: boolean = false;
  public refId: string = randomId('fla_checkbox');

  constructor(injector: Injector, cdr: ChangeDetectorRef) {
    super(injector, cdr);
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (this.isSwitch) {
      const elem = document.getElementById(this.refId);
      elem?.setAttribute('role', 'switch');
    }
    if (this.formControl) {
      this.formControl?.value && (this.isChecked = this.formControl?.value);
      this.formControl.valueChanges
        .pipe(debounceTime(200))
        .subscribe(
          (value: boolean) => value !== this.checked && (this.checked = value)
        );
    }
  }

  changeChecked() {
    this.isChecked = !this.checked;
    this.cdr.detectChanges();
    this.flaChange.emit(this.checked);
  }
}
