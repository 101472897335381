<div>
  <h2 class="mb-6">Você já possui uma conta!</h2>
  <p class="fw-bold mb-6">
    Identificamos que você já tem uma conta no
    <img
      class="mb-2"
      src="/assets/logo/logo-TM-without-icon.svg"
      alt="Logo TradeMap"
      width="120"
    />
  </p>

  <p class="fw-bold mb-6">
    Para acessar o RocketTrader é só clicar em "Entrar na minha conta" e fazer
    login usando as mesmas credenciais do TradeMap.
  </p>

  <p class="fst-italic fw-normal mb-6">
    Esqueceu sua senha do TradeMap? Não se preocupe! Você pode recuperá-la
    clicando em "Esqueci a senha" e suas novas credenciais servirão para o
    RocketTrader e TradeMap.
  </p>

  <div class="d-flex flex-column">
    <rocket-button
      label="Entrar na minha conta"
      type="default"
      btnType="submit"
      (rocketClick)="handleButtonClick('LOGIN')"
      css="mb-3"
    >
    </rocket-button>
    <rocket-button
      label="Esqueci a senha"
      type="outline"
      btnType="submit"
      (rocketClick)="handleButtonClick('RECOVER_PASSWORD')"
    >
    </rocket-button>
  </div>
</div>
