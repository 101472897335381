import { Component, Input } from '@angular/core';
import { INewsInfos } from '@core/interface/news-agency.interface';
import { NewsModalComponent } from '@shared/components/market-summary/parts/news-modal/news-modal.component';
import { StandaloneModalsService } from '@shared/modals/standalone-modals.service';
import { RocketModalService } from '@shared/rocket-components/components';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styles: [
    `
      #newsList {
        ::ng-deep.cdk-virtual-scroll-content-wrapper {
          width: 100%;
        }
      }
    `,
  ],
})
export class NewsListComponent {
  @Input() news: INewsInfos[] = [];

  constructor(
    private _standaloneModalsService: StandaloneModalsService,
    private _rocketModalService: RocketModalService
  ) {}

  public documentOpen(param: INewsInfos) {
    if (param.type === 'news') {
      window.open(param.data.news_url, '_blank');
      return;
    }
    this._openNews(param);
  }

  private _openNews(news: INewsInfos): void {
    this._rocketModalService.open(NewsModalComponent, {
      data: {
        event: {
          id_news_agency: news.idAgency,
          agency_type: news.agencyType,
          data: { id_news: news.data.id_news },
        },
      },
    });
  }
}
