import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlaCardIconComponent } from './card-icon/card-icon.component';
import { CardComponent } from './card.component';
import { FlaCardCalenderComponent } from './card-calender/card-calender.component';
import { FlaCardOrderComponent } from './card-order/card-order.component';
import { FlaCardImgComponent } from './card-img/card-img.component';
import { FlaCardInfoComponent } from './card-info/card-info.component';
import { FlaCardCustomComponent } from './card-custom/card-custom.component';
import { FlaCardHorizontalComponent } from './card-horizontal/card-horizontal.component';
import { FlaTagModule } from '../tag/tag.module';
import { FlaCardPriceComponent } from './card-price/card-price.component';
import { FlaIconModule } from '../icon';
import { FlaButtonCloseModule } from '../button-close';
import { RocketButtonModule } from '../button';

const COMPONENTS = [
  CardComponent,
  FlaCardIconComponent,
  FlaCardCalenderComponent,
  FlaCardOrderComponent,
  FlaCardImgComponent,
  FlaCardInfoComponent,
  FlaCardCustomComponent,
  FlaCardHorizontalComponent,
  FlaCardPriceComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FlaIconModule,
    FlaButtonCloseModule,
    RocketButtonModule,
    FlaTagModule,
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class FlaCardModule {}
