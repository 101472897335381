import { Component, Input, OnDestroy } from '@angular/core';
import { IYoutubeMiniPlayer } from './youtube-miniplayer.interface';
import { HomeService } from '../service/home.service';
import { Subject, tap } from 'rxjs';

@Component({
  selector: 'app-youtube-miniplayer',
  templateUrl: './youtube-miniplayer.component.html',
  styleUrls: ['./youtube-miniplayer.component.scss'],
})
export class YoutubeMiniplayerComponent implements OnDestroy {
  private _videoId!: string;
  private _autoplay!: number;
  private _startSeconds!: number;
  private movePlayer$ = new Subject<void>();
  public player: any;
  public isGrabbing: boolean = false;
  transform = {
    x: 0,
    y: 0,
  };
  @Input() set videoYoutubeData(videoYoutubeData: IYoutubeMiniPlayer) {
    this._videoId = videoYoutubeData?.videoId;
    this._autoplay = videoYoutubeData?.autoplay ? 1 : 2;
    this._startSeconds = videoYoutubeData?.startAt;
    this.initIframe();
  }
  constructor(private homeService: HomeService) {
    this.movePlayer$
      .pipe(
        tap(() => {
          const targetRect = document.getElementById(`ytb-player`)!!;
          const closerDiv = document
            .getElementById(`closerDiv`)!!
            .getBoundingClientRect();
          targetRect.style.top = `${closerDiv.top}px`;
          targetRect.style.left = `${closerDiv.left}px`;
        })
      )
      .subscribe(() => {
        this.transform = {
          x: 0,
          y: 0,
        };
      });
  }

  ngOnDestroy() {
    this.destroyPlayer();
    this.movePlayer$.unsubscribe();
  }

  private destroyPlayer() {
    this.player && this.player.destroy();
  }

  initIframe() {
    if ((window as any)['YT']) {
      this.destroyPlayer();
      this._videoId && this.startVideo();
      return;
    }
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag!.parentNode!.insertBefore(tag, firstScriptTag);
    (window as any)['onYouTubeIframeAPIReady'] = () => this.startVideo();
  }

  startVideo() {
    setTimeout(() => {
      this.player = new (window as any)['YT'].Player('mini-player', {
        videoId: this._videoId,
        playerVars: {
          autoplay: this._autoplay,
          start: Math.floor(this._startSeconds) ?? 0,
        },
        width: '400',
        height: '225',
        modestbranding: 1,
        disablekb: 1,
        rel: 0,
        showinfo: 0,
        fs: 0,
        playsinline: 1,
        origin: window.location.host,
      });
    }, 500);
  }

  closeMiniPlayer() {
    this.homeService.miniplayerComponent = undefined;
  }

  movePlayer() {
    this.movePlayer$.next();
  }
}
