import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
} from '@angular/core';
import { AuctionTooltipComponent } from '@shared/rocket-components/components/auction-tooltip/auction-tooltip.component';
import { RocketCreateComponentService } from '@shared/rocket-components/services/create-component/create-component.service';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appAuctionTooltip]',
  standalone: true,
})
export class AuctionTooltipDirective implements OnChanges {
  @Input('appAuctionTooltip') auction!: any;
  @Input() situation!: string;
  private parentPositions!: IDebouncePositions;
  private configComponent!: AuctionTooltipComponent;
  private element!: HTMLElement | null;
  private componentRef!: ComponentRef<AuctionTooltipComponent>;
  private updateAuctionData = new Subject<any>();
  private isGrid = false;
  private isShow = false;

  get onUpdateAuctionData$() {
    return this.updateAuctionData.asObservable();
  }

  constructor(
    private el: ElementRef,
    private createComponent: RocketCreateComponentService
  ) {}

  ngOnChanges(): void {
    if (
      this.isShow &&
      this.situation === 'LEILAO' &&
      this.auction &&
      !this.updateAuctionData.closed
    )
      this.updateAuctionData.next(this.auction);
    if (this.situation !== 'LEILAO') this.destroyComponent();
  }

  @HostListener('mouseenter')
  private openConfig(): void {
    if (this.situation !== 'LEILAO' || !this.auction) return;
    this.isShow = true;
    this.isGrid =
      this.el.nativeElement.offsetParent.className.includes('ag-cell');
    this.parentPositions =
      this.el.nativeElement.offsetParent.getBoundingClientRect();
    this.updateAuctionData = new Subject();
    this._displayConfig();
  }

  private _displayConfig(): void {
    this._removeBeforeInit();
    const create = this.createComponent.create(AuctionTooltipComponent);
    this.componentRef = create.componentRef;
    this.configComponent = this.componentRef.instance;
    this.element = create.rootNodeElement as HTMLElement;
    this._bindValuesComponent();
  }

  private _bindValuesComponent(): void {
    this.configComponent.parentPositions = this.parentPositions;
    this.configComponent.auctionInfos = this.auction!;
    this.configComponent.auctionUpdateObservable = this.onUpdateAuctionData$;
    this.configComponent.isGridTooltip = this.isGrid;
    this.configComponent.spacing = this.isGrid
      ? { top: 24, left: 24 }
      : { top: 45, left: 0 };
    document.body.append(this.element!);
  }

  private _removeBeforeInit(): void {
    const findAll = document.querySelectorAll('app-auction-tooltip');
    if (findAll)
      findAll.forEach((elem) => {
        elem.remove();
        this.updateAuctionData?.unsubscribe();
      });
  }

  @HostListener('mouseleave')
  private destroyComponent(): void {
    this.isShow = false;
    this.updateAuctionData?.unsubscribe();
    if (this.configComponent) this.configComponent?.ngOnDestroy();
    this.element?.remove();
  }
}

export interface IDebouncePositions {
  top: number;
  left: number;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  right?: number;
  bottom?: number;
}
