import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-stock-chart-header-button',
  templateUrl: 'stock-chart-header-button.component.html',
})
export class StockChartHeaderButtonComponent {
  @Input() useFlaIcon: boolean = true;

  @Input() iconName: string = '';
  @Input() titleTxt: string = '';
  @Input() label: string = '';
  @Input() flaCss: string = '';

  @Input() width: number = 0;
  @Input() height: number = 0;
  @Input() minWidth: number = 0;
  @Input() minHeight: number = 0;

  @Output() headerClick: EventEmitter<boolean> = new EventEmitter<boolean>();
}
