<div [id]="idElement" class="dropdown" [ngClass]="[css]">
  <ng-content select="[appDropdownButton]"></ng-content>
  <div
    class="dropdown-menu"
    [ngClass]="[menuAlign]"
    [style.minWidth]="minWidth"
    [style.visibility]="contentVisibility"
  >
    <ng-content></ng-content>
  </div>
</div>
