import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { RocketModalService } from '@shared/rocket-components/components/index';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';

@Injectable()
export abstract class RocketModalRef<T = any>
  extends ReadStreamBase
  implements OnDestroy
{
  private onClosed = new Subject<T | { closed: boolean }>();
  constructor(private service: RocketModalService) {
    super();
  }

  ngOnDestroy(): void {
    this.onClosed.complete();
  }

  afterClosed(): Observable<T | { closed: boolean }> {
    return this.onClosed.asObservable();
  }

  close(value?: any, isSecondModal?: boolean): void {
    this.service.close(value, isSecondModal);
  }

  outsideEvent(outsideEvent: () => void): void {
    this.service.outsideEvent = outsideEvent;
  }
}
