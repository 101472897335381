import { TABLE_BOOK_CONTEXT_MENU_ENUM } from '../enum/table-book.enum';

export const TABLE_BOOK_CONTEXT_MENU_INIT = [
  {
    id: TABLE_BOOK_CONTEXT_MENU_ENUM.PRICE,
    elementID: 'BOOK_CONTEXT_PRICE',
    name: 'Por Preços',
    key: 'Por Preço',
    configKey: 'hidePrice',
    enable: false,
  },
  {
    id: TABLE_BOOK_CONTEXT_MENU_ENUM.OFFER,
    elementID: 'BOOK_CONTEXT_OFFER',
    name: 'Por Ofertas',
    key: 'Por Ofertas',
    configKey: 'hideOffers',
    enable: true,
  },
  {
    id: TABLE_BOOK_CONTEXT_MENU_ENUM.FORCE_CHART,
    elementID: 'BOOK_CONTEXT_FORCE_CHART',
    name: 'Pressão',
    key: 'Pressão',
    configKey: 'hidePressure',
    enable: false,
    divider: true,
  },
  {
    id: TABLE_BOOK_CONTEXT_MENU_ENUM.DETAILS,
    elementID: 'BOOK_CONTEXT_DETAILS',
    name: 'Detalhes do ativo',
    key: 'Detalhes do ativo',
    configKey: 'hideDetails',
    enable: false,
  },
  {
    id: TABLE_BOOK_CONTEXT_MENU_ENUM.ORDER,
    elementID: 'BOOK_CONTEXT_ORDER',
    name: 'Qtd ordens',
    key: 'Qtd ordens',
    configKey: 'hideOrders',
    enable: true,
  },
  {
    id: TABLE_BOOK_CONTEXT_MENU_ENUM.HIGHLIGHT_AGRESSOR,
    elementID: 'BOOK_CONTEXT_HIGHLIGHT_AGRESSOR',
    name: 'Agressor',
    key: 'Agressor',
    configKey: 'hideAggressor',
    enable: false,
    divider: true,
  },
  {
    id: TABLE_BOOK_CONTEXT_MENU_ENUM.HIGHLIGHT_QUANTITY,
    elementID: 'BOOK_CONTEXT_HIGHLIGHT_QUANTITY',
    name: 'Destaque Quantidade',
    key: 'Destaque Quantidade',
    configKey: 'hideHighlightQuantity',
    enable: false,
  },
  {
    id: TABLE_BOOK_CONTEXT_MENU_ENUM.HIGHLIGHT_QUANTITY,
    elementID: 'BOOK_CONTEXT_HIGHLIGHT_QUANTITY',
    name: 'Destaque Ofertas',
    key: 'Destaque Ofertas',
    configKey: 'hideHighlightOffer',
    enable: false,
  },
];
