/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable prefer-spread */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-empty */
/* eslint-disable no-case-declarations */
/* eslint-disable prefer-const */
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TalibService {
  private API: any = {
    ACCBANDS: {
      name: 'ACCBANDS',
      camelCaseName: 'accBands',
      group: 'Overlap Studies',
      description: 'Acceleration Bands',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 20,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [
        { name: 'upperBand', type: 'Double[]', plotHint: 'limit_upper' },
        { name: 'middleBand', type: 'Double[]', plotHint: 'line' },
        { name: 'lowerBand', type: 'Double[]', plotHint: 'limit_lower' },
      ],
    },
    ACOS: {
      name: 'ACOS',
      camelCaseName: 'acos',
      group: 'Math Transform',
      description: 'Vector Trigonometric ACos',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    AD: {
      name: 'AD',
      camelCaseName: 'ad',
      group: 'Volume Indicators',
      description: 'Chaikin A/D Line',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
        { name: 'volume', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    ADD: {
      name: 'ADD',
      camelCaseName: 'add',
      group: 'Math Operators',
      description: 'Vector Arithmetic Add',
      inputs: [
        { name: 'inReal0', type: 'Double[]' },
        { name: 'inReal1', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    ADOSC: {
      name: 'ADOSC',
      camelCaseName: 'adOsc',
      group: 'Volume Indicators',
      description: 'Chaikin A/D Oscillator',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
        { name: 'volume', type: 'Double[]' },
      ],
      options: [
        {
          name: 'fastPeriod',
          displayName: 'Fast Period',
          defaultValue: 3,
          hint: 'Number of period for the fast MA',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
        {
          name: 'slowPeriod',
          displayName: 'Slow Period',
          defaultValue: 10,
          hint: 'Number of period for the slow MA',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    ADX: {
      name: 'ADX',
      camelCaseName: 'adx',
      group: 'Momentum Indicators',
      description: 'Average Directional Movement Index',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
        {
          name: 'smoothing',
          displayName: 'ADX Smoothing',
          defaultValue: 14,
          hint: 'Number of Smoothing',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    ADXR: {
      name: 'ADXR',
      camelCaseName: 'adxr',
      group: 'Momentum Indicators',
      description: 'Average Directional Movement Index Rating',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    APO: {
      name: 'APO',
      camelCaseName: 'apo',
      group: 'Momentum Indicators',
      description: 'Absolute Price Oscillator',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'fastPeriod',
          displayName: 'Fast Period',
          defaultValue: 12,
          hint: 'Number of period for the fast MA',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
        {
          name: 'slowPeriod',
          displayName: 'Slow Period',
          defaultValue: 26,
          hint: 'Number of period for the slow MA',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
        {
          name: 'MAType',
          displayName: 'MA Type',
          defaultValue: 0,
          hint: 'Type of Moving Average',
          type: 'MAType',
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    AROON: {
      name: 'AROON',
      camelCaseName: 'aroon',
      group: 'Momentum Indicators',
      description: 'Aroon',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [
        { name: 'aroonDown', type: 'Double[]', plotHint: 'line_dash' },
        { name: 'aroonUp', type: 'Double[]', plotHint: 'line' },
      ],
    },
    AROONOSC: {
      name: 'AROONOSC',
      camelCaseName: 'aroonOsc',
      group: 'Momentum Indicators',
      description: 'Aroon Oscillator',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    ASIN: {
      name: 'ASIN',
      camelCaseName: 'asin',
      group: 'Math Transform',
      description: 'Vector Trigonometric ASin',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    ATAN: {
      name: 'ATAN',
      camelCaseName: 'atan',
      group: 'Math Transform',
      description: 'Vector Trigonometric ATan',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    ATR: {
      name: 'ATR',
      camelCaseName: 'atr',
      group: 'Volatility Indicators',
      description: 'Average True Range',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    AVGDEV: {
      name: 'AVGDEV',
      camelCaseName: 'avgDev',
      group: 'Price Transform',
      description: 'Average Deviation',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    AVGPRICE: {
      name: 'AVGPRICE',
      camelCaseName: 'avgPrice',
      group: 'Price Transform',
      description: 'Average Price',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    BBANDS: {
      name: 'BBANDS',
      camelCaseName: 'bbands',
      group: 'Overlap Studies',
      description: 'Bollinger Bands',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 5,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
        {
          name: 'nbDevUp',
          displayName: 'Deviations up',
          defaultValue: 2,
          hint: 'Deviation multiplier for upper band',
          type: 'Double',
          range: { min: -3e37, max: 3e37 },
        },
        {
          name: 'nbDevDn',
          displayName: 'Deviations down',
          defaultValue: 2,
          hint: 'Deviation multiplier for lower band',
          type: 'Double',
          range: { min: -3e37, max: 3e37 },
        },
        {
          name: 'MAType',
          displayName: 'MA Type',
          defaultValue: 0,
          hint: 'Type of Moving Average',
          type: 'MAType',
        },
      ],
      outputs: [
        { name: 'upperBand', type: 'Double[]', plotHint: 'limit_upper' },
        { name: 'middleBand', type: 'Double[]', plotHint: 'line' },
        { name: 'lowerBand', type: 'Double[]', plotHint: 'limit_lower' },
      ],
    },
    BETA: {
      name: 'BETA',
      camelCaseName: 'beta',
      group: 'Statistic Functions',
      description: 'Beta',
      inputs: [
        { name: 'inReal0', type: 'Double[]' },
        { name: 'inReal1', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 5,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    BOP: {
      name: 'BOP',
      camelCaseName: 'bop',
      group: 'Momentum Indicators',
      description: 'Balance Of Power',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    CCI: {
      name: 'CCI',
      camelCaseName: 'cci',
      group: 'Momentum Indicators',
      description: 'Commodity Channel Index',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    CDL2CROWS: {
      name: 'CDL2CROWS',
      camelCaseName: 'cdl2Crows',
      group: 'Pattern Recognition',
      description: 'Two Crows',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDL3BLACKCROWS: {
      name: 'CDL3BLACKCROWS',
      camelCaseName: 'cdl3BlackCrows',
      group: 'Pattern Recognition',
      description: 'Three Black Crows',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDL3INSIDE: {
      name: 'CDL3INSIDE',
      camelCaseName: 'cdl3Inside',
      group: 'Pattern Recognition',
      description: 'Three Inside Up/Down',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDL3LINESTRIKE: {
      name: 'CDL3LINESTRIKE',
      camelCaseName: 'cdl3LineStrike',
      group: 'Pattern Recognition',
      description: 'Three-Line Strike ',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDL3OUTSIDE: {
      name: 'CDL3OUTSIDE',
      camelCaseName: 'cdl3Outside',
      group: 'Pattern Recognition',
      description: 'Three Outside Up/Down',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDL3STARSINSOUTH: {
      name: 'CDL3STARSINSOUTH',
      camelCaseName: 'cdl3StarsInSouth',
      group: 'Pattern Recognition',
      description: 'Three Stars In The South',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDL3WHITESOLDIERS: {
      name: 'CDL3WHITESOLDIERS',
      camelCaseName: 'cdl3WhiteSoldiers',
      group: 'Pattern Recognition',
      description: 'Three Advancing White Soldiers',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLABANDONEDBABY: {
      name: 'CDLABANDONEDBABY',
      camelCaseName: 'cdlAbandonedBaby',
      group: 'Pattern Recognition',
      description: 'Abandoned Baby',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'penetration',
          displayName: 'Penetration',
          defaultValue: 0.3,
          hint: 'Percentage of penetration of a candle within another candle',
          type: 'Double',
          range: { min: 0, max: 3e37 },
        },
      ],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLADVANCEBLOCK: {
      name: 'CDLADVANCEBLOCK',
      camelCaseName: 'cdlAdvanceBlock',
      group: 'Pattern Recognition',
      description: 'Advance Block',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLBELTHOLD: {
      name: 'CDLBELTHOLD',
      camelCaseName: 'cdlBeltHold',
      group: 'Pattern Recognition',
      description: 'Belt-hold',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLBREAKAWAY: {
      name: 'CDLBREAKAWAY',
      camelCaseName: 'cdlBreakaway',
      group: 'Pattern Recognition',
      description: 'Breakaway',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLCLOSINGMARUBOZU: {
      name: 'CDLCLOSINGMARUBOZU',
      camelCaseName: 'cdlClosingMarubozu',
      group: 'Pattern Recognition',
      description: 'Closing Marubozu',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLCONCEALBABYSWALL: {
      name: 'CDLCONCEALBABYSWALL',
      camelCaseName: 'cdlConcealBabysWall',
      group: 'Pattern Recognition',
      description: 'Concealing Baby Swallow',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLCOUNTERATTACK: {
      name: 'CDLCOUNTERATTACK',
      camelCaseName: 'cdlCounterAttack',
      group: 'Pattern Recognition',
      description: 'Counterattack',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLDARKCLOUDCOVER: {
      name: 'CDLDARKCLOUDCOVER',
      camelCaseName: 'cdlDarkCloudCover',
      group: 'Pattern Recognition',
      description: 'Dark Cloud Cover',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'penetration',
          displayName: 'Penetration',
          defaultValue: 0.5,
          hint: 'Percentage of penetration of a candle within another candle',
          type: 'Double',
          range: { min: 0, max: 3e37 },
        },
      ],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLDOJI: {
      name: 'CDLDOJI',
      camelCaseName: 'cdlDoji',
      group: 'Pattern Recognition',
      description: 'Doji',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLDOJISTAR: {
      name: 'CDLDOJISTAR',
      camelCaseName: 'cdlDojiStar',
      group: 'Pattern Recognition',
      description: 'Doji Star',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLDRAGONFLYDOJI: {
      name: 'CDLDRAGONFLYDOJI',
      camelCaseName: 'cdlDragonflyDoji',
      group: 'Pattern Recognition',
      description: 'Dragonfly Doji',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLENGULFING: {
      name: 'CDLENGULFING',
      camelCaseName: 'cdlEngulfing',
      group: 'Pattern Recognition',
      description: 'Engulfing Pattern',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLEVENINGDOJISTAR: {
      name: 'CDLEVENINGDOJISTAR',
      camelCaseName: 'cdlEveningDojiStar',
      group: 'Pattern Recognition',
      description: 'Evening Doji Star',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'penetration',
          displayName: 'Penetration',
          defaultValue: 0.3,
          hint: 'Percentage of penetration of a candle within another candle',
          type: 'Double',
          range: { min: 0, max: 3e37 },
        },
      ],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLEVENINGSTAR: {
      name: 'CDLEVENINGSTAR',
      camelCaseName: 'cdlEveningStar',
      group: 'Pattern Recognition',
      description: 'Evening Star',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'penetration',
          displayName: 'Penetration',
          defaultValue: 0.3,
          hint: 'Percentage of penetration of a candle within another candle',
          type: 'Double',
          range: { min: 0, max: 3e37 },
        },
      ],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLGAPSIDESIDEWHITE: {
      name: 'CDLGAPSIDESIDEWHITE',
      camelCaseName: 'cdlGapSideSideWhite',
      group: 'Pattern Recognition',
      description: 'Up/Down-gap side-by-side white lines',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLGRAVESTONEDOJI: {
      name: 'CDLGRAVESTONEDOJI',
      camelCaseName: 'cdlGravestoneDoji',
      group: 'Pattern Recognition',
      description: 'Gravestone Doji',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLHAMMER: {
      name: 'CDLHAMMER',
      camelCaseName: 'cdlHammer',
      group: 'Pattern Recognition',
      description: 'Hammer',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLHANGINGMAN: {
      name: 'CDLHANGINGMAN',
      camelCaseName: 'cdlHangingMan',
      group: 'Pattern Recognition',
      description: 'Hanging Man',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLHARAMI: {
      name: 'CDLHARAMI',
      camelCaseName: 'cdlHarami',
      group: 'Pattern Recognition',
      description: 'Harami Pattern',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLHARAMICROSS: {
      name: 'CDLHARAMICROSS',
      camelCaseName: 'cdlHaramiCross',
      group: 'Pattern Recognition',
      description: 'Harami Cross Pattern',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLHIGHWAVE: {
      name: 'CDLHIGHWAVE',
      camelCaseName: 'cdlHignWave',
      group: 'Pattern Recognition',
      description: 'High-Wave Candle',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLHIKKAKE: {
      name: 'CDLHIKKAKE',
      camelCaseName: 'cdlHikkake',
      group: 'Pattern Recognition',
      description: 'Hikkake Pattern',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLHIKKAKEMOD: {
      name: 'CDLHIKKAKEMOD',
      camelCaseName: 'cdlHikkakeMod',
      group: 'Pattern Recognition',
      description: 'Modified Hikkake Pattern',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLHOMINGPIGEON: {
      name: 'CDLHOMINGPIGEON',
      camelCaseName: 'cdlHomingPigeon',
      group: 'Pattern Recognition',
      description: 'Homing Pigeon',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLIDENTICAL3CROWS: {
      name: 'CDLIDENTICAL3CROWS',
      camelCaseName: 'cdlIdentical3Crows',
      group: 'Pattern Recognition',
      description: 'Identical Three Crows',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLINNECK: {
      name: 'CDLINNECK',
      camelCaseName: 'cdlInNeck',
      group: 'Pattern Recognition',
      description: 'In-Neck Pattern',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLINVERTEDHAMMER: {
      name: 'CDLINVERTEDHAMMER',
      camelCaseName: 'cdlInvertedHammer',
      group: 'Pattern Recognition',
      description: 'Inverted Hammer',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLKICKING: {
      name: 'CDLKICKING',
      camelCaseName: 'cdlKicking',
      group: 'Pattern Recognition',
      description: 'Kicking',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLKICKINGBYLENGTH: {
      name: 'CDLKICKINGBYLENGTH',
      camelCaseName: 'cdlKickingByLength',
      group: 'Pattern Recognition',
      description: 'Kicking - bull/bear determined by the longer marubozu',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLLADDERBOTTOM: {
      name: 'CDLLADDERBOTTOM',
      camelCaseName: 'cdlLadderBottom',
      group: 'Pattern Recognition',
      description: 'Ladder Bottom',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLLONGLEGGEDDOJI: {
      name: 'CDLLONGLEGGEDDOJI',
      camelCaseName: 'cdlLongLeggedDoji',
      group: 'Pattern Recognition',
      description: 'Long Legged Doji',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLLONGLINE: {
      name: 'CDLLONGLINE',
      camelCaseName: 'cdlLongLine',
      group: 'Pattern Recognition',
      description: 'Long Line Candle',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLMARUBOZU: {
      name: 'CDLMARUBOZU',
      camelCaseName: 'cdlMarubozu',
      group: 'Pattern Recognition',
      description: 'Marubozu',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLMATCHINGLOW: {
      name: 'CDLMATCHINGLOW',
      camelCaseName: 'cdlMatchingLow',
      group: 'Pattern Recognition',
      description: 'Matching Low',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLMATHOLD: {
      name: 'CDLMATHOLD',
      camelCaseName: 'cdlMatHold',
      group: 'Pattern Recognition',
      description: 'Mat Hold',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'penetration',
          displayName: 'Penetration',
          defaultValue: 0.5,
          hint: 'Percentage of penetration of a candle within another candle',
          type: 'Double',
          range: { min: 0, max: 3e37 },
        },
      ],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLMORNINGDOJISTAR: {
      name: 'CDLMORNINGDOJISTAR',
      camelCaseName: 'cdlMorningDojiStar',
      group: 'Pattern Recognition',
      description: 'Morning Doji Star',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'penetration',
          displayName: 'Penetration',
          defaultValue: 0.3,
          hint: 'Percentage of penetration of a candle within another candle',
          type: 'Double',
          range: { min: 0, max: 3e37 },
        },
      ],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLMORNINGSTAR: {
      name: 'CDLMORNINGSTAR',
      camelCaseName: 'cdlMorningStar',
      group: 'Pattern Recognition',
      description: 'Morning Star',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'penetration',
          displayName: 'Penetration',
          defaultValue: 0.3,
          hint: 'Percentage of penetration of a candle within another candle',
          type: 'Double',
          range: { min: 0, max: 3e37 },
        },
      ],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLONNECK: {
      name: 'CDLONNECK',
      camelCaseName: 'cdlOnNeck',
      group: 'Pattern Recognition',
      description: 'On-Neck Pattern',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLPIERCING: {
      name: 'CDLPIERCING',
      camelCaseName: 'cdlPiercing',
      group: 'Pattern Recognition',
      description: 'Piercing Pattern',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLRICKSHAWMAN: {
      name: 'CDLRICKSHAWMAN',
      camelCaseName: 'cdlRickshawMan',
      group: 'Pattern Recognition',
      description: 'Rickshaw Man',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLRISEFALL3METHODS: {
      name: 'CDLRISEFALL3METHODS',
      camelCaseName: 'cdlRiseFall3Methods',
      group: 'Pattern Recognition',
      description: 'Rising/Falling Three Methods',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLSEPARATINGLINES: {
      name: 'CDLSEPARATINGLINES',
      camelCaseName: 'cdlSeperatingLines',
      group: 'Pattern Recognition',
      description: 'Separating Lines',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLSHOOTINGSTAR: {
      name: 'CDLSHOOTINGSTAR',
      camelCaseName: 'cdlShootingStar',
      group: 'Pattern Recognition',
      description: 'Shooting Star',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLSHORTLINE: {
      name: 'CDLSHORTLINE',
      camelCaseName: 'cdlShortLine',
      group: 'Pattern Recognition',
      description: 'Short Line Candle',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLSPINNINGTOP: {
      name: 'CDLSPINNINGTOP',
      camelCaseName: 'cdlSpinningTop',
      group: 'Pattern Recognition',
      description: 'Spinning Top',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLSTALLEDPATTERN: {
      name: 'CDLSTALLEDPATTERN',
      camelCaseName: 'cdlStalledPattern',
      group: 'Pattern Recognition',
      description: 'Stalled Pattern',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLSTICKSANDWICH: {
      name: 'CDLSTICKSANDWICH',
      camelCaseName: 'cdlStickSandwhich',
      group: 'Pattern Recognition',
      description: 'Stick Sandwich',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLTAKURI: {
      name: 'CDLTAKURI',
      camelCaseName: 'cdlTakuri',
      group: 'Pattern Recognition',
      description: 'Takuri (Dragonfly Doji with very long lower shadow)',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLTASUKIGAP: {
      name: 'CDLTASUKIGAP',
      camelCaseName: 'cdlTasukiGap',
      group: 'Pattern Recognition',
      description: 'Tasuki Gap',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLTHRUSTING: {
      name: 'CDLTHRUSTING',
      camelCaseName: 'cdlThrusting',
      group: 'Pattern Recognition',
      description: 'Thrusting Pattern',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLTRISTAR: {
      name: 'CDLTRISTAR',
      camelCaseName: 'cdlTristar',
      group: 'Pattern Recognition',
      description: 'Tristar Pattern',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLUNIQUE3RIVER: {
      name: 'CDLUNIQUE3RIVER',
      camelCaseName: 'cdlUnique3River',
      group: 'Pattern Recognition',
      description: 'Unique 3 River',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLUPSIDEGAP2CROWS: {
      name: 'CDLUPSIDEGAP2CROWS',
      camelCaseName: 'cdlUpsideGap2Crows',
      group: 'Pattern Recognition',
      description: 'Upside Gap Two Crows',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CDLXSIDEGAP3METHODS: {
      name: 'CDLXSIDEGAP3METHODS',
      camelCaseName: 'cdlXSideGap3Methods',
      group: 'Pattern Recognition',
      description: 'Upside/Downside Gap Three Methods',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    CEIL: {
      name: 'CEIL',
      camelCaseName: 'ceil',
      group: 'Math Transform',
      description: 'Vector Ceil',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    CMO: {
      name: 'CMO',
      camelCaseName: 'cmo',
      group: 'Momentum Indicators',
      description: 'Chande Momentum Oscillator',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    CORREL: {
      name: 'CORREL',
      camelCaseName: 'correl',
      group: 'Statistic Functions',
      description: "Pearson's Correlation Coefficient (r)",
      inputs: [
        { name: 'inReal0', type: 'Double[]' },
        { name: 'inReal1', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 30,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    COS: {
      name: 'COS',
      camelCaseName: 'cos',
      group: 'Math Transform',
      description: 'Vector Trigonometric Cos',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    COSH: {
      name: 'COSH',
      camelCaseName: 'cosh',
      group: 'Math Transform',
      description: 'Vector Trigonometric Cosh',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    DEMA: {
      name: 'DEMA',
      camelCaseName: 'dema',
      group: 'Overlap Studies',
      description: 'Double Exponential Moving Average',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 30,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    DIV: {
      name: 'DIV',
      camelCaseName: 'div',
      group: 'Math Operators',
      description: 'Vector Arithmetic Div',
      inputs: [
        { name: 'inReal0', type: 'Double[]' },
        { name: 'inReal1', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    DX: {
      name: 'DX',
      camelCaseName: 'dx',
      group: 'Momentum Indicators',
      description: 'Directional Movement Index',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    EMA: {
      name: 'EMA',
      camelCaseName: 'ema',
      group: 'Overlap Studies',
      description: 'Exponential Moving Average',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 30,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    EXP: {
      name: 'EXP',
      camelCaseName: 'exp',
      group: 'Math Transform',
      description: 'Vector Arithmetic Exp',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    FLOOR: {
      name: 'FLOOR',
      camelCaseName: 'floor',
      group: 'Math Transform',
      description: 'Vector Floor',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    HT_DCPERIOD: {
      name: 'HT_DCPERIOD',
      camelCaseName: 'htDcPeriod',
      group: 'Cycle Indicators',
      description: 'Hilbert Transform - Dominant Cycle Period',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    HT_DCPHASE: {
      name: 'HT_DCPHASE',
      camelCaseName: 'htDcPhase',
      group: 'Cycle Indicators',
      description: 'Hilbert Transform - Dominant Cycle Phase',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    HT_PHASOR: {
      name: 'HT_PHASOR',
      camelCaseName: 'htPhasor',
      group: 'Cycle Indicators',
      description: 'Hilbert Transform - Phasor Components',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [
        { name: 'inPhase', type: 'Double[]', plotHint: 'line' },
        { name: 'quadrature', type: 'Double[]', plotHint: 'line_dash' },
      ],
    },
    HT_SINE: {
      name: 'HT_SINE',
      camelCaseName: 'htSine',
      group: 'Cycle Indicators',
      description: 'Hilbert Transform - SineWave',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [
        { name: 'sine', type: 'Double[]', plotHint: 'line' },
        { name: 'leadSine', type: 'Double[]', plotHint: 'line_dash' },
      ],
    },
    HT_TRENDLINE: {
      name: 'HT_TRENDLINE',
      camelCaseName: 'htTrendline',
      group: 'Overlap Studies',
      description: 'Hilbert Transform - Instantaneous Trendline',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    HT_TRENDMODE: {
      name: 'HT_TRENDMODE',
      camelCaseName: 'htTrendMode',
      group: 'Cycle Indicators',
      description: 'Hilbert Transform - Trend vs Cycle Mode',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    IMI: {
      name: 'IMI',
      camelCaseName: 'imi',
      group: 'Momentum Indicators',
      description: 'Intraday Momentum Index',
      inputs: [
        { name: 'open', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    KAMA: {
      name: 'KAMA',
      camelCaseName: 'kama',
      group: 'Overlap Studies',
      description: 'Kaufman Adaptive Moving Average',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 30,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    LINEARREG: {
      name: 'LINEARREG',
      camelCaseName: 'linearReg',
      group: 'Statistic Functions',
      description: 'Linear Regression',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    LINEARREG_ANGLE: {
      name: 'LINEARREG_ANGLE',
      camelCaseName: 'linearRegAngle',
      group: 'Statistic Functions',
      description: 'Linear Regression Angle',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    LINEARREG_INTERCEPT: {
      name: 'LINEARREG_INTERCEPT',
      camelCaseName: 'linearRegIntercept',
      group: 'Statistic Functions',
      description: 'Linear Regression Intercept',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    LINEARREG_SLOPE: {
      name: 'LINEARREG_SLOPE',
      camelCaseName: 'linearRegSlope',
      group: 'Statistic Functions',
      description: 'Linear Regression Slope',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    LN: {
      name: 'LN',
      camelCaseName: 'ln',
      group: 'Math Transform',
      description: 'Vector Log Natural',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    LOG10: {
      name: 'LOG10',
      camelCaseName: 'log10',
      group: 'Math Transform',
      description: 'Vector Log10',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    MA: {
      name: 'MA',
      camelCaseName: 'movingAverage',
      group: 'Overlap Studies',
      description: 'Moving average',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 30,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
        {
          name: 'MAType',
          displayName: 'MA Type',
          defaultValue: 0,
          hint: 'Type of Moving Average',
          type: 'MAType',
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    MACD: {
      name: 'MACD',
      camelCaseName: 'macd',
      group: 'Momentum Indicators',
      description: 'Moving Average Convergence/Divergence',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'fastPeriod',
          displayName: 'Fast Period',
          defaultValue: 12,
          hint: 'Number of period for the fast MA',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
        {
          name: 'slowPeriod',
          displayName: 'Slow Period',
          defaultValue: 26,
          hint: 'Number of period for the slow MA',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
        {
          name: 'signalPeriod',
          displayName: 'Signal Period',
          defaultValue: 9,
          hint: 'Smoothing for the signal line (nb of period)',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
      ],
      outputs: [
        { name: 'MACD', type: 'Double[]', plotHint: 'line' },
        { name: 'MACDSignal', type: 'Double[]', plotHint: 'line_dash' },
        { name: 'MACDHist', type: 'Double[]', plotHint: 'histogram' },
      ],
    },
    MACDEXT: {
      name: 'MACDEXT',
      camelCaseName: 'macdExt',
      group: 'Momentum Indicators',
      description: 'MACD with controllable MA type',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'fastPeriod',
          displayName: 'Fast Period',
          defaultValue: 12,
          hint: 'Number of period for the fast MA',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
        {
          name: 'fastMAType',
          displayName: 'Fast MA',
          defaultValue: 0,
          hint: 'Type of Moving Average for fast MA',
          type: 'MAType',
        },
        {
          name: 'slowPeriod',
          displayName: 'Slow Period',
          defaultValue: 26,
          hint: 'Number of period for the slow MA',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
        {
          name: 'slowMAType',
          displayName: 'Slow MA',
          defaultValue: 0,
          hint: 'Type of Moving Average for slow MA',
          type: 'MAType',
        },
        {
          name: 'signalPeriod',
          displayName: 'Signal Period',
          defaultValue: 9,
          hint: 'Smoothing for the signal line (nb of period)',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
        {
          name: 'signalMAType',
          displayName: 'Signal MA',
          defaultValue: 0,
          hint: 'Type of Moving Average for signal line',
          type: 'MAType',
        },
      ],
      outputs: [
        { name: 'MACD', type: 'Double[]', plotHint: 'line' },
        { name: 'MACDSignal', type: 'Double[]', plotHint: 'line_dash' },
        { name: 'MACDHist', type: 'Double[]', plotHint: 'histogram' },
      ],
    },
    MACDFIX: {
      name: 'MACDFIX',
      camelCaseName: 'macdFix',
      group: 'Momentum Indicators',
      description: 'Moving Average Convergence/Divergence Fix 12/26',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'signalPeriod',
          displayName: 'Signal Period',
          defaultValue: 9,
          hint: 'Smoothing for the signal line (nb of period)',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
      ],
      outputs: [
        { name: 'MACD', type: 'Double[]', plotHint: 'line' },
        { name: 'MACDSignal', type: 'Double[]', plotHint: 'line_dash' },
        { name: 'MACDHist', type: 'Double[]', plotHint: 'histogram' },
      ],
    },
    MAMA: {
      name: 'MAMA',
      camelCaseName: 'mama',
      group: 'Overlap Studies',
      description: 'MESA Adaptive Moving Average',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'fastLimit',
          displayName: 'Fast Limit',
          defaultValue: 0.5,
          hint: 'Upper limit use in the adaptive algorithm',
          type: 'Double',
          range: { min: 0.01, max: 0.99 },
        },
        {
          name: 'slowLimit',
          displayName: 'Slow Limit',
          defaultValue: 0.05,
          hint: 'Lower limit use in the adaptive algorithm',
          type: 'Double',
          range: { min: 0.01, max: 0.99 },
        },
      ],
      outputs: [
        { name: 'MAMA', type: 'Double[]', plotHint: 'line' },
        { name: 'FAMA', type: 'Double[]', plotHint: 'line_dash' },
      ],
    },
    MAVP: {
      name: 'MAVP',
      camelCaseName: 'movingAverageVariablePeriod',
      group: 'Overlap Studies',
      description: 'Moving average with variable period',
      inputs: [
        { name: 'inReal', type: 'Double[]' },
        { name: 'inPeriods', type: 'Double[]' },
      ],
      options: [
        {
          name: 'minPeriod',
          displayName: 'Minimum Period',
          defaultValue: 2,
          hint: 'Value less than minimum will be changed to Minimum period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
        {
          name: 'maxPeriod',
          displayName: 'Maximum Period',
          defaultValue: 30,
          hint: 'Value higher than maximum will be changed to Maximum period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
        {
          name: 'MAType',
          displayName: 'MA Type',
          defaultValue: 0,
          hint: 'Type of Moving Average',
          type: 'MAType',
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    MAX: {
      name: 'MAX',
      camelCaseName: 'max',
      group: 'Math Operators',
      description: 'Highest value over a specified period',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 30,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    MAXINDEX: {
      name: 'MAXINDEX',
      camelCaseName: 'maxIndex',
      group: 'Math Operators',
      description: 'Index of highest value over a specified period',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 30,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    MEDPRICE: {
      name: 'MEDPRICE',
      camelCaseName: 'medPrice',
      group: 'Price Transform',
      description: 'Median Price',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    MFI: {
      name: 'MFI',
      camelCaseName: 'mfi',
      group: 'Momentum Indicators',
      description: 'Money Flow Index',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
        { name: 'volume', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    MIDPOINT: {
      name: 'MIDPOINT',
      camelCaseName: 'midPoint',
      group: 'Overlap Studies',
      description: 'MidPoint over period',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    MIDPRICE: {
      name: 'MIDPRICE',
      camelCaseName: 'midPrice',
      group: 'Overlap Studies',
      description: 'Midpoint Price over period',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    MIN: {
      name: 'MIN',
      camelCaseName: 'min',
      group: 'Math Operators',
      description: 'Lowest value over a specified period',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 30,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    MININDEX: {
      name: 'MININDEX',
      camelCaseName: 'minIndex',
      group: 'Math Operators',
      description: 'Index of lowest value over a specified period',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 30,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Integer[]', plotHint: 'line' }],
    },
    MINMAX: {
      name: 'MINMAX',
      camelCaseName: 'minMax',
      group: 'Math Operators',
      description: 'Lowest and highest values over a specified period',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 30,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [
        { name: 'min', type: 'Double[]', plotHint: 'line' },
        { name: 'max', type: 'Double[]', plotHint: 'line' },
      ],
    },
    MINMAXINDEX: {
      name: 'MINMAXINDEX',
      camelCaseName: 'minMaxIndex',
      group: 'Math Operators',
      description:
        'Indexes of lowest and highest values over a specified period',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 30,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [
        { name: 'minIdx', type: 'Integer[]', plotHint: 'line' },
        { name: 'maxIdx', type: 'Integer[]', plotHint: 'line' },
      ],
    },
    MINUS_DI: {
      name: 'MINUS_DI',
      camelCaseName: 'minusDI',
      group: 'Momentum Indicators',
      description: 'Minus Directional Indicator',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    MINUS_DM: {
      name: 'MINUS_DM',
      camelCaseName: 'minusDM',
      group: 'Momentum Indicators',
      description: 'Minus Directional Movement',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    MOM: {
      name: 'MOM',
      camelCaseName: 'mom',
      group: 'Momentum Indicators',
      description: 'Momentum',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 10,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    MULT: {
      name: 'MULT',
      camelCaseName: 'mult',
      group: 'Math Operators',
      description: 'Vector Arithmetic Mult',
      inputs: [
        { name: 'inReal0', type: 'Double[]' },
        { name: 'inReal1', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    NATR: {
      name: 'NATR',
      camelCaseName: 'natr',
      group: 'Volatility Indicators',
      description: 'Normalized Average True Range',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    OBV: {
      name: 'OBV',
      camelCaseName: 'obv',
      group: 'Volume Indicators',
      description: 'On Balance Volume',
      inputs: [
        { name: 'inReal', type: 'Double[]' },
        { name: 'volume', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    PLUS_DI: {
      name: 'PLUS_DI',
      camelCaseName: 'plusDI',
      group: 'Momentum Indicators',
      description: 'Plus Directional Indicator',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    PLUS_DM: {
      name: 'PLUS_DM',
      camelCaseName: 'plusDM',
      group: 'Momentum Indicators',
      description: 'Plus Directional Movement',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    PPO: {
      name: 'PPO',
      camelCaseName: 'ppo',
      group: 'Momentum Indicators',
      description: 'Percentage Price Oscillator',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'fastPeriod',
          displayName: 'Fast Period',
          defaultValue: 12,
          hint: 'Number of period for the fast MA',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
        {
          name: 'slowPeriod',
          displayName: 'Slow Period',
          defaultValue: 26,
          hint: 'Number of period for the slow MA',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
        {
          name: 'MAType',
          displayName: 'MA Type',
          defaultValue: 0,
          hint: 'Type of Moving Average',
          type: 'MAType',
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    ROC: {
      name: 'ROC',
      camelCaseName: 'roc',
      group: 'Momentum Indicators',
      description: 'Rate of change : ((price/prevPrice)-1)*100',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 10,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    ROCP: {
      name: 'ROCP',
      camelCaseName: 'rocP',
      group: 'Momentum Indicators',
      description: 'Rate of change Percentage: (price-prevPrice)/prevPrice',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 10,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    ROCR: {
      name: 'ROCR',
      camelCaseName: 'rocR',
      group: 'Momentum Indicators',
      description: 'Rate of change ratio: (price/prevPrice)',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 10,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    ROCR100: {
      name: 'ROCR100',
      camelCaseName: 'rocR100',
      group: 'Momentum Indicators',
      description: 'Rate of change ratio 100 scale: (price/prevPrice)*100',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 10,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    RSI: {
      name: 'RSI',
      camelCaseName: 'rsi',
      group: 'Momentum Indicators',
      description: 'Relative Strength Index',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    SAR: {
      name: 'SAR',
      camelCaseName: 'sar',
      group: 'Overlap Studies',
      description: 'Parabolic SAR',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
      ],
      options: [
        {
          name: 'acceleration',
          displayName: 'Acceleration Factor',
          defaultValue: 0.02,
          hint: 'Acceleration Factor used up to the Maximum value',
          type: 'Double',
          range: { min: 0, max: 3e37 },
        },
        {
          name: 'maximum',
          displayName: 'AF Maximum',
          defaultValue: 0.2,
          hint: 'Acceleration Factor Maximum value',
          type: 'Double',
          range: { min: 0, max: 3e37 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    SAREXT: {
      name: 'SAREXT',
      camelCaseName: 'sarExt',
      group: 'Overlap Studies',
      description: 'Parabolic SAR - Extended',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
      ],
      options: [
        {
          name: 'startValue',
          displayName: 'Start Value',
          defaultValue: 0,
          hint: 'Start value and direction. 0 for Auto, >0 for Long, <0 for Short',
          type: 'Double',
          range: { min: -3e37, max: 3e37 },
        },
        {
          name: 'offsetOnReverse',
          displayName: 'Offset on Reverse',
          defaultValue: 0,
          hint: 'Percent offset added/removed to initial stop on short/long reversal',
          type: 'Double',
          range: { min: 0, max: 3e37 },
        },
        {
          name: 'accelerationInitLong',
          displayName: 'AF Init Long',
          defaultValue: 0.02,
          hint: 'Acceleration Factor initial value for the Long direction',
          type: 'Double',
          range: { min: 0, max: 3e37 },
        },
        {
          name: 'accelerationLong',
          displayName: 'AF Long',
          defaultValue: 0.02,
          hint: 'Acceleration Factor for the Long direction',
          type: 'Double',
          range: { min: 0, max: 3e37 },
        },
        {
          name: 'accelerationMaxLong',
          displayName: 'AF Max Long',
          defaultValue: 0.2,
          hint: 'Acceleration Factor maximum value for the Long direction',
          type: 'Double',
          range: { min: 0, max: 3e37 },
        },
        {
          name: 'accelerationInitShort',
          displayName: 'AF Init Short',
          defaultValue: 0.02,
          hint: 'Acceleration Factor initial value for the Short direction',
          type: 'Double',
          range: { min: 0, max: 3e37 },
        },
        {
          name: 'accelerationShort',
          displayName: 'AF Short',
          defaultValue: 0.02,
          hint: 'Acceleration Factor for the Short direction',
          type: 'Double',
          range: { min: 0, max: 3e37 },
        },
        {
          name: 'accelerationMaxShort',
          displayName: 'AF Max Short',
          defaultValue: 0.2,
          hint: 'Acceleration Factor maximum value for the Short direction',
          type: 'Double',
          range: { min: 0, max: 3e37 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    SIN: {
      name: 'SIN',
      camelCaseName: 'sin',
      group: 'Math Transform',
      description: 'Vector Trigonometric Sin',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    SINH: {
      name: 'SINH',
      camelCaseName: 'sinh',
      group: 'Math Transform',
      description: 'Vector Trigonometric Sinh',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    SMA: {
      name: 'SMA',
      camelCaseName: 'sma',
      group: 'Overlap Studies',
      description: 'Simple Moving Average',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 30,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    SQRT: {
      name: 'SQRT',
      camelCaseName: 'sqrt',
      group: 'Math Transform',
      description: 'Vector Square Root',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    STDDEV: {
      name: 'STDDEV',
      camelCaseName: 'stdDev',
      group: 'Statistic Functions',
      description: 'Standard Deviation',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 5,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
        {
          name: 'nbDev',
          displayName: 'Deviations',
          defaultValue: 1,
          hint: 'Nb of deviations',
          type: 'Double',
          range: { min: -3e37, max: 3e37 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    STOCH: {
      name: 'STOCH',
      camelCaseName: 'stoch',
      group: 'Momentum Indicators',
      description: 'Stochastic',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'fastK_Period',
          displayName: 'Fast-K Period',
          defaultValue: 5,
          hint: 'Time period for building the Fast-K line',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
        {
          name: 'slowK_Period',
          displayName: 'Slow-K Period',
          defaultValue: 3,
          hint: 'Smoothing for making the Slow-K line. Usually set to 3',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
        {
          name: 'slowK_MAType',
          displayName: 'Slow-K MA',
          defaultValue: 0,
          hint: 'Type of Moving Average for Slow-K',
          type: 'MAType',
        },
        {
          name: 'slowD_Period',
          displayName: 'Slow-D Period',
          defaultValue: 3,
          hint: 'Smoothing for making the Slow-D line',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
        {
          name: 'slowD_MAType',
          displayName: 'Slow-D MA',
          defaultValue: 0,
          hint: 'Type of Moving Average for Slow-D',
          type: 'MAType',
        },
      ],
      outputs: [
        { name: 'slowK', type: 'Double[]', plotHint: 'line_dash' },
        { name: 'slowD', type: 'Double[]', plotHint: 'line_dash' },
      ],
    },
    STOCHF: {
      name: 'STOCHF',
      camelCaseName: 'stochF',
      group: 'Momentum Indicators',
      description: 'Stochastic Fast',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'fastK_Period',
          displayName: 'Fast-K Period',
          defaultValue: 5,
          hint: 'Time period for building the Fast-K line',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
        {
          name: 'fastD_Period',
          displayName: 'Fast-D Period',
          defaultValue: 3,
          hint: 'Smoothing for making the Fast-D line. Usually set to 3',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
        {
          name: 'fastD_MAType',
          displayName: 'Fast-D MA',
          defaultValue: 0,
          hint: 'Type of Moving Average for Fast-D',
          type: 'MAType',
        },
      ],
      outputs: [
        { name: 'fastK', type: 'Double[]', plotHint: 'line' },
        { name: 'fastD', type: 'Double[]', plotHint: 'line' },
      ],
    },
    STOCHRSI: {
      name: 'STOCHRSI',
      camelCaseName: 'stochRsi',
      group: 'Momentum Indicators',
      description: 'Stochastic Relative Strength Index',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
        {
          name: 'fastK_Period',
          displayName: 'Fast-K Period',
          defaultValue: 5,
          hint: 'Time period for building the Fast-K line',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
        {
          name: 'fastD_Period',
          displayName: 'Fast-D Period',
          defaultValue: 3,
          hint: 'Smoothing for making the Fast-D line. Usually set to 3',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
        {
          name: 'fastD_MAType',
          displayName: 'Fast-D MA',
          defaultValue: 0,
          hint: 'Type of Moving Average for Fast-D',
          type: 'MAType',
        },
      ],
      outputs: [
        { name: 'fastK', type: 'Double[]', plotHint: 'line' },
        { name: 'fastD', type: 'Double[]', plotHint: 'line' },
      ],
    },
    SUB: {
      name: 'SUB',
      camelCaseName: 'sub',
      group: 'Math Operators',
      description: 'Vector Arithmetic Substraction',
      inputs: [
        { name: 'inReal0', type: 'Double[]' },
        { name: 'inReal1', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    SUM: {
      name: 'SUM',
      camelCaseName: 'sum',
      group: 'Math Operators',
      description: 'Summation',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 30,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    T3: {
      name: 'T3',
      camelCaseName: 't3',
      group: 'Overlap Studies',
      description: 'Triple Exponential Moving Average (T3)',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 5,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
        {
          name: 'VFactor',
          displayName: 'Volume Factor',
          defaultValue: 0.7,
          hint: 'Volume Factor',
          type: 'Double',
          range: { min: 0, max: 1 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    TAN: {
      name: 'TAN',
      camelCaseName: 'tan',
      group: 'Math Transform',
      description: 'Vector Trigonometric Tan',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    TANH: {
      name: 'TANH',
      camelCaseName: 'tanh',
      group: 'Math Transform',
      description: 'Vector Trigonometric Tanh',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    TEMA: {
      name: 'TEMA',
      camelCaseName: 'tema',
      group: 'Overlap Studies',
      description: 'Triple Exponential Moving Average',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 30,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    TRANGE: {
      name: 'TRANGE',
      camelCaseName: 'trueRange',
      group: 'Volatility Indicators',
      description: 'True Range',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    TRIMA: {
      name: 'TRIMA',
      camelCaseName: 'trima',
      group: 'Overlap Studies',
      description: 'Triangular Moving Average',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 30,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    TRIX: {
      name: 'TRIX',
      camelCaseName: 'trix',
      group: 'Momentum Indicators',
      description: '1-day Rate-Of-Change (ROC) of a Triple Smooth EMA',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 30,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    TSF: {
      name: 'TSF',
      camelCaseName: 'tsf',
      group: 'Statistic Functions',
      description: 'Time Series Forecast',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    TYPPRICE: {
      name: 'TYPPRICE',
      camelCaseName: 'typPrice',
      group: 'Price Transform',
      description: 'Typical Price',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    ULTOSC: {
      name: 'ULTOSC',
      camelCaseName: 'ultOsc',
      group: 'Momentum Indicators',
      description: 'Ultimate Oscillator',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod1',
          displayName: 'First Period',
          defaultValue: 7,
          hint: 'Number of bars for 1st period.',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
        {
          name: 'timePeriod2',
          displayName: 'Second Period',
          defaultValue: 14,
          hint: 'Number of bars fro 2nd period',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
        {
          name: 'timePeriod3',
          displayName: 'Third Period',
          defaultValue: 28,
          hint: 'Number of bars for 3rd period',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    VAR: {
      name: 'VAR',
      camelCaseName: 'variance',
      group: 'Statistic Functions',
      description: 'Variance',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 5,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 1, max: 100000 },
        },
        {
          name: 'nbDev',
          displayName: 'Deviations',
          defaultValue: 1,
          hint: 'Nb of deviations',
          type: 'Double',
          range: { min: -3e37, max: 3e37 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    WCLPRICE: {
      name: 'WCLPRICE',
      camelCaseName: 'wclPrice',
      group: 'Price Transform',
      description: 'Weighted Close Price',
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    WILLR: {
      name: 'WILLR',
      camelCaseName: 'willR',
      group: 'Momentum Indicators',
      description: "Williams' %R",
      inputs: [
        { name: 'high', type: 'Double[]' },
        { name: 'low', type: 'Double[]' },
        { name: 'close', type: 'Double[]' },
      ],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 14,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
    WMA: {
      name: 'WMA',
      camelCaseName: 'wma',
      group: 'Overlap Studies',
      description: 'Weighted Moving Average',
      inputs: [{ name: 'inReal', type: 'Double[]' }],
      options: [
        {
          name: 'timePeriod',
          displayName: 'Time Period',
          defaultValue: 30,
          hint: 'Number of period',
          type: 'Integer',
          range: { min: 2, max: 100000 },
        },
      ],
      outputs: [{ name: 'output', type: 'Double[]', plotHint: 'line' }],
    },
  };

  private wasmExports: any = null;
  private wasmMemory = new WebAssembly.Memory({
    initial: 256,
    shared: true,
    maximum: 512,
  });
  private wasmTable = new WebAssembly.Table({ initial: 0, element: 'anyfunc' });
  private ABORT = false;
  private EXITSTATUS = 0;
  private buffer = new ArrayBuffer(8);
  private HEAP8 = new Int8Array(this.buffer);
  // Module['HEAP16'] = HEAP16 = new Int16Array(buf);
  private HEAP32: any = new Int32Array(this.buffer);
  private HEAPU16 = new Uint16Array(this.buffer);
  private HEAPU32 = new Uint32Array(this.buffer);
  private HEAPF32: any = new Float32Array(this.buffer);
  // Module['this.HEAPF64'] = this.HEAPF64 = new Float64Array(buf);
  HEAPU8 = new Uint8Array(this.buffer);
  private HEAPF64 = new Float64Array(this.buffer);
  private thisProgram = './this.program';

  private emscripten_realloc_buffer = (size: any) => {
    let b = this.wasmMemory.buffer;
    try {
      // round size grow request up to wasm page size (fixed 64KB per spec)
      this.wasmMemory.grow((size - b.byteLength + 65535) >>> 16); // .grow() takes a delta compared to the previous size
      this.updateGlobalBufferAndViews();
      return 1 /*success*/;
    } catch (e) {
      return 0;
    }
  };

  private _emscripten_resize_heap = (requestedSize: number) => {
    let oldSize = this.HEAPU8.length;
    requestedSize = requestedSize >>> 0;
    // With multithreaded builds, races can happen (another thread might increase the size
    // in between), so return a failure, and let the caller retry.

    // Memory resize rules:
    // 1.  Always increase heap size to at least the requested size, rounded up
    //     to next page multiple.
    // 2a. If MEMORY_GROWTH_LINEAR_STEP == -1, excessively resize the heap
    //     geometrically: increase the heap size according to
    //     MEMORY_GROWTH_GEOMETRIC_STEP factor (default +20%), At most
    //     overreserve by MEMORY_GROWTH_GEOMETRIC_CAP bytes (default 96MB).
    // 2b. If MEMORY_GROWTH_LINEAR_STEP != -1, excessively resize the heap
    //     linearly: increase the heap size by at least
    //     MEMORY_GROWTH_LINEAR_STEP bytes.
    // 3.  Max size for the heap is capped at 2048MB-WASM_PAGE_SIZE, or by
    //     MAXIMUM_MEMORY, or by ASAN limit, depending on which is smallest
    // 4.  If we were unable to allocate as much memory, it may be due to
    //     over-eager decision to excessively reserve due to (3) above.
    //     Hence if an allocation fails, cut down on the amount of excess
    //     growth, in an attempt to succeed to perform a smaller allocation.

    // A limit is set for how much we can grow. We should not exceed that
    // (the wasm binary specifies it, so if we tried, we'd fail anyhow).
    let maxHeapSize = this.getHeapMax();
    if (requestedSize > maxHeapSize) {
      return false;
    }

    let alignUp = (x: any, multiple: any) =>
      x + ((multiple - (x % multiple)) % multiple);

    // Loop through potential heap size increases. If we attempt a too eager
    // reservation that fails, cut down on the attempted size and reserve a
    // smaller bump instead. (max 3 times, chosen somewhat arbitrarily)
    for (let cutDown = 1; cutDown <= 4; cutDown *= 2) {
      let overGrownHeapSize = oldSize * (1 + 0.2 / cutDown); // ensure geometric growth
      // but limit overreserving (default to capping at +96MB overgrowth at most)
      overGrownHeapSize = Math.min(
        overGrownHeapSize,
        requestedSize + 100663296
      );

      let newSize = Math.min(
        maxHeapSize,
        alignUp(Math.max(requestedSize, overGrownHeapSize), 65536)
      );

      let replacement = this.emscripten_realloc_buffer(newSize);
      if (replacement) {
        return true;
      }
    }
    return false;
  };

  private asmLibraryArg = {
    memoryBase: 0,
    tableBase: 0,
    memory: this.wasmMemory,
    table: this.wasmTable,
    __assert_fail: this.___assert_fail,
    __call_sighandler: this.___call_sighandler,
    __syscall__newselect: this.funcMock,
    __syscall_accept4: this.funcMock,
    __syscall_bind: this.funcMock,
    __syscall_chdir: this.funcMock,
    __syscall_chmod: this.funcMock,
    __syscall_connect: this.funcMock,
    __syscall_dup: this.funcMock,
    __syscall_dup3: this.funcMock,
    __syscall_faccessat: this.funcMock,
    __syscall_fadvise64: this.___syscall_fadvise64,
    __syscall_fallocate: this.funcMock,
    __syscall_fchdir: this.funcMock,
    __syscall_fchmod: this.funcMock,
    __syscall_fchmodat: this.funcMock,
    __syscall_fchown32: this.funcMock,
    __syscall_fchownat: this.funcMock,
    __syscall_fcntl64: this.funcMock,
    __syscall_fdatasync: this.funcMock,
    __syscall_fstat64: this.funcMock,
    __syscall_fstatfs64: this.funcMock,
    __syscall_ftruncate64: this.funcMock,
    __syscall_getcwd: this.funcMock,
    __syscall_getdents64: this.funcMock,
    __syscall_getpeername: this.funcMock,
    __syscall_getsockname: this.funcMock,
    __syscall_getsockopt: this.funcMock,
    __syscall_ioctl: this.funcMock,
    __syscall_linkat: this.funcMock,
    __syscall_listen: this.funcMock,
    __syscall_lstat64: this.funcMock,
    __syscall_mkdirat: this.funcMock,
    __syscall_mknodat: this.funcMock,
    __syscall_newfstatat: this.funcMock,
    __syscall_openat: this.funcMock,
    __syscall_pipe: this.funcMock,
    __syscall_poll: this.funcMock,
    __syscall_readlinkat: this.funcMock,
    __syscall_recvfrom: this.funcMock,
    __syscall_recvmsg: this.funcMock,
    __syscall_renameat: this.funcMock,
    __syscall_rmdir: this.funcMock,
    __syscall_sendmsg: this.funcMock,
    __syscall_sendto: this.funcMock,
    __syscall_socket: this.funcMock,
    __syscall_stat64: this.funcMock,
    __syscall_statfs64: this.funcMock,
    __syscall_symlink: this.funcMock,
    __syscall_symlinkat: this.funcMock,
    __syscall_truncate64: this.funcMock,
    __syscall_unlinkat: this.funcMock,
    __syscall_utimensat: this.funcMock,
    _dlinit: this.funcMock,
    _dlopen_js: this.funcMock,
    _dlsym_js: this.funcMock,
    _emscripten_dlopen_js: this.funcMock,
    _emscripten_err: this.__emscripten_err,
    _emscripten_get_now_is_monotonic: this.__emscripten_get_now_is_monotonic,
    _emscripten_get_progname: this.__emscripten_get_progname,
    _emscripten_out: this.__emscripten_out,
    _emscripten_throw_longjmp: this.__emscripten_throw_longjmp,
    _gmtime_js: this.funcMock,
    _localtime_js: this.funcMock,
    _mktime_js: this.funcMock,
    _mmap_js: this.funcMock,
    _msync_js: this.funcMock,
    _munmap_js: this.funcMock,
    _setitimer_js: this.funcMock,
    _timegm_js: this.funcMock,
    _tzset_js: this.funcMock,
    abort: this._abort,
    alBuffer3f: this._alBuffer3f,
    alBuffer3i: this._alBuffer3i,
    alBufferData: this._alBufferData,
    alBufferf: this._alBufferf,
    alBufferfv: this._alBufferfv,
    alBufferi: this._alBufferi,
    alBufferiv: this._alBufferiv,
    alDeleteBuffers: this._alDeleteBuffers,
    alDeleteSources: this._alDeleteSources,
    alDisable: this._alDisable,
    alDistanceModel: this._alDistanceModel,
    alDopplerFactor: this._alDopplerFactor,
    alDopplerVelocity: this._alDopplerVelocity,
    alEnable: this._alEnable,
    alGenBuffers: this._alGenBuffers,
    alGenSources: this._alGenSources,
    alGetBoolean: this._alGetBoolean,
    alGetBooleanv: this._alGetBooleanv,
    alGetBuffer3f: this._alGetBuffer3f,
    alGetBuffer3i: this._alGetBuffer3i,
    alGetBufferf: this._alGetBufferf,
    alGetBufferfv: this._alGetBufferfv,
    alGetBufferi: this._alGetBufferi,
    alGetBufferiv: this._alGetBufferiv,
    alGetDouble: this._alGetDouble,
    alGetDoublev: this._alGetDoublev,
    alGetEnumValue: this._alGetEnumValue,
    alGetError: this._alGetError,
    alGetFloat: this._alGetFloat,
    alGetFloatv: this._alGetFloatv,
    alGetInteger: this._alGetInteger,
    alGetIntegerv: this._alGetIntegerv,
    alGetListener3f: this._alGetListener3f,
    alGetListener3i: this._alGetListener3i,
    alGetListenerf: this._alGetListenerf,
    alGetListenerfv: this._alGetListenerfv,
    alGetListeneri: this._alGetListeneri,
    alGetListeneriv: this._alGetListeneriv,
    alGetSource3f: this._alGetSource3f,
    alGetSource3i: this._alGetSource3i,
    alGetSourcef: this._alGetSourcef,
    alGetSourcefv: this._alGetSourcefv,
    alGetSourcei: this._alGetSourcei,
    alGetSourceiv: this._alGetSourceiv,
    alGetString: this._alGetString,
    alIsBuffer: this._alIsBuffer,
    alIsEnabled: this._alIsEnabled,
    alIsExtensionPresent: this._alIsExtensionPresent,
    alIsSource: this._alIsSource,
    alListener3f: this._alListener3f,
    alListener3i: this._alListener3i,
    alListenerf: this._alListenerf,
    alListenerfv: this._alListenerfv,
    alListeneri: this._alListeneri,
    alListeneriv: this._alListeneriv,
    alSource3f: this._alSource3f,
    alSource3i: this._alSource3i,
    alSourcePause: this._alSourcePause,
    alSourcePausev: this._alSourcePausev,
    alSourcePlay: this._alSourcePlay,
    alSourcePlayv: this._alSourcePlayv,
    alSourceQueueBuffers: this._alSourceQueueBuffers,
    alSourceRewind: this._alSourceRewind,
    alSourceRewindv: this._alSourceRewindv,
    alSourceStop: this._alSourceStop,
    alSourceStopv: this._alSourceStopv,
    alSourceUnqueueBuffers: this._alSourceUnqueueBuffers,
    alSourcef: this._alSourcef,
    alSourcefv: this._alSourcefv,
    alSourcei: this._alSourcei,
    alSourceiv: this._alSourceiv,
    alSpeedOfSound: this._alSpeedOfSound,
    alcCaptureCloseDevice: this._alcCaptureCloseDevice,
    alcCaptureOpenDevice: this._alcCaptureOpenDevice,
    alcCaptureSamples: this._alcCaptureSamples,
    alcCaptureStart: this._alcCaptureStart,
    alcCaptureStop: this._alcCaptureStop,
    alcCloseDevice: this._alcCloseDevice,
    alcCreateContext: this._alcCreateContext,
    alcDestroyContext: this._alcDestroyContext,
    alcGetContextsDevice: this._alcGetContextsDevice,
    alcGetCurrentContext: this._alcGetCurrentContext,
    alcGetEnumValue: this._alcGetEnumValue,
    alcGetError: this._alcGetError,
    alcGetIntegerv: this._alcGetIntegerv,
    alcGetString: this._alcGetString,
    alcIsExtensionPresent: this._alcIsExtensionPresent,
    alcMakeContextCurrent: this._alcMakeContextCurrent,
    alcOpenDevice: this._alcOpenDevice,
    alcProcessContext: this._alcProcessContext,
    alcSuspendContext: this._alcSuspendContext,
    emscripten_alcDevicePauseSOFT: this._emscripten_alcDevicePauseSOFT,
    emscripten_alcDeviceResumeSOFT: this._emscripten_alcDeviceResumeSOFT,
    emscripten_alcGetStringiSOFT: this._emscripten_alcGetStringiSOFT,
    emscripten_alcResetDeviceSOFT: this._emscripten_alcResetDeviceSOFT,
    emscripten_asm_const_int: this._emscripten_asm_const_int,
    emscripten_console_error: this._emscripten_console_error,
    emscripten_console_log: this._emscripten_console_log,
    emscripten_console_warn: this._emscripten_console_warn,
    emscripten_date_now: this._emscripten_date_now,
    emscripten_get_heap_max: this._emscripten_get_heap_max,
    emscripten_get_now: this._emscripten_get_now,
    emscripten_get_now_res: this._emscripten_get_now_res,
    emscripten_glActiveTexture: this._emscripten_glActiveTexture,
    emscripten_glAttachShader: this._emscripten_glAttachShader,
    emscripten_glBeginQueryEXT: this._emscripten_glBeginQueryEXT,
    emscripten_glBindAttribLocation: this._emscripten_glBindAttribLocation,
    emscripten_glBindBuffer: this._emscripten_glBindBuffer,
    emscripten_glBindFramebuffer: this._emscripten_glBindFramebuffer,
    emscripten_glBindRenderbuffer: this._emscripten_glBindRenderbuffer,
    emscripten_glBindTexture: this._emscripten_glBindTexture,
    emscripten_glBindVertexArrayOES: this._emscripten_glBindVertexArrayOES,
    emscripten_glBlendColor: this._emscripten_glBlendColor,
    emscripten_glBlendEquation: this._emscripten_glBlendEquation,
    emscripten_glBlendEquationSeparate:
      this._emscripten_glBlendEquationSeparate,
    emscripten_glBlendFunc: this._emscripten_glBlendFunc,
    emscripten_glBlendFuncSeparate: this._emscripten_glBlendFuncSeparate,
    emscripten_glBufferData: this._emscripten_glBufferData,
    emscripten_glBufferSubData: this._emscripten_glBufferSubData,
    emscripten_glCheckFramebufferStatus:
      this._emscripten_glCheckFramebufferStatus,
    emscripten_glClear: this._emscripten_glClear,
    emscripten_glClearColor: this._emscripten_glClearColor,
    emscripten_glClearDepthf: this._emscripten_glClearDepthf,
    emscripten_glClearStencil: this._emscripten_glClearStencil,
    emscripten_glColorMask: this._emscripten_glColorMask,
    emscripten_glCompileShader: this._emscripten_glCompileShader,
    emscripten_glCompressedTexImage2D: this._emscripten_glCompressedTexImage2D,
    emscripten_glCompressedTexSubImage2D:
      this._emscripten_glCompressedTexSubImage2D,
    emscripten_glCopyTexImage2D: this._emscripten_glCopyTexImage2D,
    emscripten_glCopyTexSubImage2D: this._emscripten_glCopyTexSubImage2D,
    emscripten_glCreateProgram: this._emscripten_glCreateProgram,
    emscripten_glCreateShader: this._emscripten_glCreateShader,
    emscripten_glCullFace: this._emscripten_glCullFace,
    emscripten_glDeleteBuffers: this._emscripten_glDeleteBuffers,
    emscripten_glDeleteFramebuffers: this._emscripten_glDeleteFramebuffers,
    emscripten_glDeleteProgram: this._emscripten_glDeleteProgram,
    emscripten_glDeleteQueriesEXT: this._emscripten_glDeleteQueriesEXT,
    emscripten_glDeleteRenderbuffers: this._emscripten_glDeleteRenderbuffers,
    emscripten_glDeleteShader: this._emscripten_glDeleteShader,
    emscripten_glDeleteTextures: this._emscripten_glDeleteTextures,
    emscripten_glDeleteVertexArraysOES:
      this._emscripten_glDeleteVertexArraysOES,
    emscripten_glDepthFunc: this._emscripten_glDepthFunc,
    emscripten_glDepthMask: this._emscripten_glDepthMask,
    emscripten_glDepthRangef: this._emscripten_glDepthRangef,
    emscripten_glDetachShader: this._emscripten_glDetachShader,
    emscripten_glDisable: this._emscripten_glDisable,
    emscripten_glDisableVertexAttribArray:
      this._emscripten_glDisableVertexAttribArray,
    emscripten_glDrawArrays: this._emscripten_glDrawArrays,
    emscripten_glDrawArraysInstancedANGLE:
      this._emscripten_glDrawArraysInstancedANGLE,
    emscripten_glDrawBuffersWEBGL: this._emscripten_glDrawBuffersWEBGL,
    emscripten_glDrawElements: this._emscripten_glDrawElements,
    emscripten_glDrawElementsInstancedANGLE:
      this._emscripten_glDrawElementsInstancedANGLE,
    emscripten_glEnable: this._emscripten_glEnable,
    emscripten_glEnableVertexAttribArray:
      this._emscripten_glEnableVertexAttribArray,
    emscripten_glEndQueryEXT: this._emscripten_glEndQueryEXT,
    emscripten_glFinish: this._emscripten_glFinish,
    emscripten_glFlush: this._emscripten_glFlush,
    emscripten_glFramebufferRenderbuffer:
      this._emscripten_glFramebufferRenderbuffer,
    emscripten_glFramebufferTexture2D: this._emscripten_glFramebufferTexture2D,
    emscripten_glFrontFace: this._emscripten_glFrontFace,
    emscripten_glGenBuffers: this._emscripten_glGenBuffers,
    emscripten_glGenFramebuffers: this._emscripten_glGenFramebuffers,
    emscripten_glGenQueriesEXT: this._emscripten_glGenQueriesEXT,
    emscripten_glGenRenderbuffers: this._emscripten_glGenRenderbuffers,
    emscripten_glGenTextures: this._emscripten_glGenTextures,
    emscripten_glGenVertexArraysOES: this._emscripten_glGenVertexArraysOES,
    emscripten_glGenerateMipmap: this._emscripten_glGenerateMipmap,
    emscripten_glGetActiveAttrib: this._emscripten_glGetActiveAttrib,
    emscripten_glGetActiveUniform: this._emscripten_glGetActiveUniform,
    emscripten_glGetAttachedShaders: this._emscripten_glGetAttachedShaders,
    emscripten_glGetAttribLocation: this._emscripten_glGetAttribLocation,
    emscripten_glGetBooleanv: this._emscripten_glGetBooleanv,
    emscripten_glGetBufferParameteriv: this._emscripten_glGetBufferParameteriv,
    emscripten_glGetError: this._emscripten_glGetError,
    emscripten_glGetFloatv: this._emscripten_glGetFloatv,
    emscripten_glGetFramebufferAttachmentParameteriv:
      this._emscripten_glGetFramebufferAttachmentParameteriv,
    emscripten_glGetIntegerv: this._emscripten_glGetIntegerv,
    emscripten_glGetProgramInfoLog: this._emscripten_glGetProgramInfoLog,
    emscripten_glGetProgramiv: this._emscripten_glGetProgramiv,
    emscripten_glGetQueryObjecti64vEXT:
      this._emscripten_glGetQueryObjecti64vEXT,
    emscripten_glGetQueryObjectivEXT: this._emscripten_glGetQueryObjectivEXT,
    emscripten_glGetQueryObjectui64vEXT:
      this._emscripten_glGetQueryObjectui64vEXT,
    emscripten_glGetQueryObjectuivEXT: this._emscripten_glGetQueryObjectuivEXT,
    emscripten_glGetQueryivEXT: this._emscripten_glGetQueryivEXT,
    emscripten_glGetRenderbufferParameteriv:
      this._emscripten_glGetRenderbufferParameteriv,
    emscripten_glGetShaderInfoLog: this._emscripten_glGetShaderInfoLog,
    emscripten_glGetShaderPrecisionFormat:
      this._emscripten_glGetShaderPrecisionFormat,
    emscripten_glGetShaderSource: this._emscripten_glGetShaderSource,
    emscripten_glGetShaderiv: this._emscripten_glGetShaderiv,
    emscripten_glGetString: this._emscripten_glGetString,
    emscripten_glGetTexParameterfv: this._emscripten_glGetTexParameterfv,
    emscripten_glGetTexParameteriv: this._emscripten_glGetTexParameteriv,
    emscripten_glGetUniformLocation: this._emscripten_glGetUniformLocation,
    emscripten_glGetUniformfv: this._emscripten_glGetUniformfv,
    emscripten_glGetUniformiv: this._emscripten_glGetUniformiv,
    emscripten_glGetVertexAttribPointerv:
      this._emscripten_glGetVertexAttribPointerv,
    emscripten_glGetVertexAttribfv: this._emscripten_glGetVertexAttribfv,
    emscripten_glGetVertexAttribiv: this._emscripten_glGetVertexAttribiv,
    emscripten_glHint: this._emscripten_glHint,
    emscripten_glIsBuffer: this._emscripten_glIsBuffer,
    emscripten_glIsEnabled: this._emscripten_glIsEnabled,
    emscripten_glIsFramebuffer: this._emscripten_glIsFramebuffer,
    emscripten_glIsProgram: this._emscripten_glIsProgram,
    emscripten_glIsQueryEXT: this._emscripten_glIsQueryEXT,
    emscripten_glIsRenderbuffer: this._emscripten_glIsRenderbuffer,
    emscripten_glIsShader: this._emscripten_glIsShader,
    emscripten_glIsTexture: this._emscripten_glIsTexture,
    emscripten_glIsVertexArrayOES: this._emscripten_glIsVertexArrayOES,
    emscripten_glLineWidth: this._emscripten_glLineWidth,
    emscripten_glLinkProgram: this._emscripten_glLinkProgram,
    emscripten_glPixelStorei: this._emscripten_glPixelStorei,
    emscripten_glPolygonOffset: this._emscripten_glPolygonOffset,
    emscripten_glQueryCounterEXT: this._emscripten_glQueryCounterEXT,
    emscripten_glReadPixels: this._emscripten_glReadPixels,
    emscripten_glReleaseShaderCompiler:
      this._emscripten_glReleaseShaderCompiler,
    emscripten_glRenderbufferStorage: this._emscripten_glRenderbufferStorage,
    emscripten_glSampleCoverage: this._emscripten_glSampleCoverage,
    emscripten_glScissor: this._emscripten_glScissor,
    emscripten_glShaderBinary: this._emscripten_glShaderBinary,
    emscripten_glShaderSource: this._emscripten_glShaderSource,
    emscripten_glStencilFunc: this._emscripten_glStencilFunc,
    emscripten_glStencilFuncSeparate: this._emscripten_glStencilFuncSeparate,
    emscripten_glStencilMask: this._emscripten_glStencilMask,
    emscripten_glStencilMaskSeparate: this._emscripten_glStencilMaskSeparate,
    emscripten_glStencilOp: this._emscripten_glStencilOp,
    emscripten_glStencilOpSeparate: this._emscripten_glStencilOpSeparate,
    emscripten_glTexImage2D: this._emscripten_glTexImage2D,
    emscripten_glTexParameterf: this._emscripten_glTexParameterf,
    emscripten_glTexParameterfv: this._emscripten_glTexParameterfv,
    emscripten_glTexParameteri: this._emscripten_glTexParameteri,
    emscripten_glTexParameteriv: this._emscripten_glTexParameteriv,
    emscripten_glTexSubImage2D: this._emscripten_glTexSubImage2D,
    emscripten_glUniform1f: this._emscripten_glUniform1f,
    emscripten_glUniform1fv: this._emscripten_glUniform1fv,
    emscripten_glUniform1i: this._emscripten_glUniform1i,
    emscripten_glUniform1iv: this._emscripten_glUniform1iv,
    emscripten_glUniform2f: this._emscripten_glUniform2f,
    emscripten_glUniform2fv: this._emscripten_glUniform2fv,
    emscripten_glUniform2i: this._emscripten_glUniform2i,
    emscripten_glUniform2iv: this._emscripten_glUniform2iv,
    emscripten_glUniform3f: this._emscripten_glUniform3f,
    emscripten_glUniform3fv: this._emscripten_glUniform3fv,
    emscripten_glUniform3i: this._emscripten_glUniform3i,
    emscripten_glUniform3iv: this._emscripten_glUniform3iv,
    emscripten_glUniform4f: this._emscripten_glUniform4f,
    emscripten_glUniform4fv: this._emscripten_glUniform4fv,
    emscripten_glUniform4i: this._emscripten_glUniform4i,
    emscripten_glUniform4iv: this._emscripten_glUniform4iv,
    emscripten_glUniformMatrix2fv: this._emscripten_glUniformMatrix2fv,
    emscripten_glUniformMatrix3fv: this._emscripten_glUniformMatrix3fv,
    emscripten_glUniformMatrix4fv: this._emscripten_glUniformMatrix4fv,
    emscripten_glUseProgram: this._emscripten_glUseProgram,
    emscripten_glValidateProgram: this._emscripten_glValidateProgram,
    emscripten_glVertexAttrib1f: this._emscripten_glVertexAttrib1f,
    emscripten_glVertexAttrib1fv: this._emscripten_glVertexAttrib1fv,
    emscripten_glVertexAttrib2f: this._emscripten_glVertexAttrib2f,
    emscripten_glVertexAttrib2fv: this._emscripten_glVertexAttrib2fv,
    emscripten_glVertexAttrib3f: this._emscripten_glVertexAttrib3f,
    emscripten_glVertexAttrib3fv: this._emscripten_glVertexAttrib3fv,
    emscripten_glVertexAttrib4f: this._emscripten_glVertexAttrib4f,
    emscripten_glVertexAttrib4fv: this._emscripten_glVertexAttrib4fv,
    emscripten_glVertexAttribDivisorANGLE:
      this._emscripten_glVertexAttribDivisorANGLE,
    emscripten_glVertexAttribPointer: this._emscripten_glVertexAttribPointer,
    emscripten_glViewport: this._emscripten_glViewport,
    emscripten_memcpy_big: (dest: number, src: number, num: any) => {
      this.HEAPU8.copyWithin(dest, src, src + num);
    },
    emscripten_resize_heap: this._emscripten_resize_heap,
    environ_get: this._environ_get,
    environ_sizes_get: this._environ_sizes_get,
    exit: this.exitJS,
    fd_close: this.funcMock,
    fd_fdstat_get: this.funcMock,
    fd_pread: this.funcMock,
    fd_pwrite: this.funcMock,
    fd_read: this.funcMock,
    fd_seek: this.funcMock,
    fd_sync: this.funcMock,
    fd_write: this.funcMock,
    getentropy: this._getentropy,
    getnameinfo: this.funcMock,
    proc_exit: this._proc_exit,
    strftime: this.funcMock,
    strftime_l: this.funcMock,
    __dlsym: this.funcMock,
  };

  onRead: ReplaySubject<null> = new ReplaySubject();

  constructor() {
    this.loadWasm();
  }

  private funcMock() {}

  private updateGlobalBufferAndViews() {
    this.buffer = this.wasmExports.memory.buffer;
    this.HEAP8 = new Int8Array(this.wasmExports.memory.buffer);
    // this.HEAP16 = new Int16Array(this.wasmExports.memory.buffer);
    this.HEAP32 = new Int32Array(this.wasmExports.memory.buffer);
    this.HEAPU8 = new Uint8Array(this.wasmExports.memory.buffer);
    this.HEAPU16 = new Uint16Array(this.wasmExports.memory.buffer);
    this.HEAPU32 = new Uint32Array(this.wasmExports.memory.buffer);
    this.HEAPF32 = new Float32Array(this.wasmExports.memory.buffer);
    this.HEAPF64 = new Float64Array(this.wasmExports.memory.buffer);
  }

  async loadWasm() {
    let response = await fetch('assets/talib.wasm');
    const info = {
      env: this.asmLibraryArg,
      wasi_snapshot_preview1: this.asmLibraryArg,
    };
    const wasmObj = await WebAssembly.instantiateStreaming(response, info);
    this.wasmExports = wasmObj.instance.exports;
    this.updateGlobalBufferAndViews();
    /**
     * Em alguns estudos o talib utiliza algumas variaveis globais,
     * que só são preenchidas quando é chamado o TA_Initialize.
     */
    const initialize = this.wasmExports.TA_Initialize;
    let ret = initialize.apply();
    this.onRead.next(null);
  }

  private validityMalloc(ptr: number, funcIdent: string): number {
    if (this.ptrHash[ptr] && this.ptrHash[ptr] != funcIdent) {
      ptr = this.wasmExports.malloc(length * 8);
      return this.validityMalloc(ptr, funcIdent);
    } else {
      this.ptrHash[ptr] = funcIdent;
    }
    return ptr;
  }

  private malloc(funcIdent: string, length: number, index: number) {
    const key = `${funcIdent}_${length}`;
    const ptrs = this.ptrFuncHash[key];
    let hasHash = ptrs && ptrs.length && ptrs[index];
    if (!ptrs) {
      this.ptrFuncHash[key] = [];
    }
    let ptr = hasHash ? ptrs[index] : this.wasmExports.malloc(length * 8);
    ptr = this.validityMalloc(ptr, key);
    if (!hasHash) {
      this.ptrFuncHash[key].push(ptr);
    }
    return ptr;
  }

  ptrFuncHash: any = {};
  ptrHash: any = {};
  call(name: string, params: any) {
    const api = this.API[name];
    const funcIdent = `TA_${api.name}`;
    if (!this.wasmExports)
      throw Error(`${api.name}() called before initialization.`);
    const ccallArgsLen =
      2 + api.inputs.length + api.options.length + 2 + api.outputs.length;
    const argTypesToCcall = new Array(ccallArgsLen).fill('number');
    for (const { name } of api.inputs) {
      if (!Array.isArray(params[name])) {
        if (params[name] === undefined)
          throw Error(`Bad Param: "${name}" is required`);
        throw Error(`Bad Param: "${name}" should be array of number`);
      }
    }
    for (const { name, defaultValue, range } of api.options) {
      if (params[name] === undefined) {
        params[name] = defaultValue;
      } else if (
        range &&
        (params[name] < range.min || params[name] > range.max)
      ) {
        throw Error(
          `Bad Param: "${name}" out of range (min: ${range.min}, max: ${range.max})`
        );
      }
    }
    let { startIdx, endIdx } = params;
    if (startIdx === undefined) startIdx = 0;
    const reqParamsLen = api.inputs.map(
      (element: any) => params[element.name].length
    );
    if (endIdx === undefined) {
      endIdx = Math.min(...reqParamsLen);
    }
    const argsToCcall = [startIdx, endIdx];
    const arraysToRelease: any[] = [];
    const ptrSizes: any = [];
    api.inputs.forEach((element: any, i: number) => {
      const length = params[element.name].length;
      const paramArray: number[] = params[element.name];
      const ptr = this.malloc(funcIdent, length, i);
      ptrSizes.push(ptr);
      const argArray = new Float64Array(
        this.wasmExports.memory.buffer,
        ptr,
        length
      );
      argArray.set(paramArray);
      arraysToRelease.push(argArray);
      argsToCcall.push(ptr);
    });
    api.options.forEach((element: any) =>
      argsToCcall.push(params[element.name])
    );
    argsToCcall.push(0);
    argsToCcall.push(endIdx - startIdx);
    const outputs = api.outputs.map((element: any, j: number) => {
      const length = endIdx - startIdx;
      const ptr = this.malloc(funcIdent, length, j);
      ptrSizes.push(ptr);
      const argArray = new Float64Array(
        this.wasmExports.memory.buffer,
        ptr,
        length
      );
      arraysToRelease.push(argArray);
      argsToCcall.push(ptr);

      return { name: element.name, array: argArray };
    });
    const retCode: number = this.ccall(
      funcIdent,
      'number',
      argTypesToCcall,
      argsToCcall
    );
    //ptrSizes.forEach((ptr: number) => {
    //  try {
    //    this.wasmExports.free(ptr);
    //  } catch (e: any) {
    //    console.log(`Erro ao liberar memória: ${api.name}`);
    //  }
    //});
    const result = outputs.reduce((result: any, current: any) => {
      result[current.name] = current.array;
      return result;
    }, {});
    if (retCode === 0) {
      return result;
    } else {
      const TA_RET_CODE: any = {
        0: 'TA_SUCCESS',
        1: 'TA_LIB_NOT_INITIALIZE',
        2: 'TA_BAD_PARAM',
        3: 'TA_ALLOC_ERR',
        4: 'TA_GROUP_NOT_FOUND',
        5: 'TA_FUNC_NOT_FOUND',
        6: 'TA_INVALID_HANDLE',
        7: 'TA_INVALID_PARAM_HOLDER',
        8: 'TA_INVALID_PARAM_HOLDER_TYPE',
        9: 'TA_INVALID_PARAM_FUNCTION',
        10: 'TA_INPUT_NOT_ALL_INITIALIZE',
        11: 'TA_OUTPUT_NOT_ALL_INITIALIZE',
        12: 'TA_OUT_OF_RANGE_START_INDEX',
        13: 'TA_OUT_OF_RANGE_END_INDEX',
        14: 'TA_INVALID_LIST_TYPE',
        15: 'TA_BAD_OBJECT',
        16: 'TA_NOT_SUPPORTED',
        5000: 'TA_INTERNAL_ERROR',
        [0xffff]: 'TA_UNKNOWN_ERR',
      };
      throw Error('[C_ERROR] ' + TA_RET_CODE[retCode] + ' - ' + retCode);
    }
  }

  private getCFunc(ident: string) {
    let func = this.wasmExports[ident];
    this.assert(
      func,
      'Cannot call unknown function ' + ident + ', make sure it is exported'
    );
    return func;
  }

  private ccall(
    ident: any,
    returnType: string,
    argTypes: (string | number)[],
    args: string | any[],
    opts?: any
  ) {
    // For fast lookup of conversion functions
    let toC: any = {
      string: (str: string) => {
        let ret = 0;
        if (str !== null && str !== undefined && str !== '0') {
          // null string
          // at most 4 bytes per UTF-8 code point, +1 for the trailing '\0'
          let len = (str.length << 2) + 1;
          ret = this.wasmExports.stackAlloc(len);
          this.stringToUTF8(str, ret, len);
        }
        return ret;
      },
      array: (arr: any[]) => {
        let ret = this.wasmExports.stackAlloc(arr.length);
        this.writeArrayToMemory(arr, ret);
        return ret;
      },
    };

    const convertReturnValue = (ret: any) => {
      if (returnType === 'string') {
        return this.UTF8ToString(ret);
      }
      if (returnType === 'boolean') return Boolean(ret);
      return ret;
    };

    let func = this.getCFunc(ident);
    let cArgs = [];
    let stack = 0;
    this.assert(returnType !== 'array', 'Return type should not be "array".');
    if (args) {
      for (let i = 0; i < args.length; i++) {
        let converter = toC[argTypes[i]];
        if (converter) {
          if (stack === 0) stack = this.wasmExports.stackSave();
          cArgs[i] = converter(args[i]);
        } else {
          cArgs[i] = args[i];
        }
      }
    }
    let ret = func.apply(null, cArgs);
    const onDone = (ret: any) => {
      if (stack !== 0) this.wasmExports.stackRestore(stack);
      return convertReturnValue(ret);
    };

    ret = onDone(ret);
    return ret;
  }

  private createExportWrapper(name: any, fixedasm?: any) {
    return () => {
      let displayName = name;
      let asm = fixedasm;
      if (!fixedasm) {
        asm = this.wasmExports;
      }

      if (!asm[name]) {
        this.assert(
          asm[name],
          'exported native private `' + displayName + '` not found'
        );
      }
      return asm[name].apply(null, arguments);
    };
  }

  private assert(condition: any, text?: any) {
    if (!condition) {
      this.abort('Assertion failed' + (text ? ': ' + text : ''));
    }
  }

  private ___assert_fail(condition: any, filename: any, line: any, func: any) {
    this.abort(
      'Assertion failed: ' +
        this.UTF8ToString(condition) +
        ', at: ' +
        [
          filename ? this.UTF8ToString(filename) : 'unknown filename',
          line,
          func ? this.UTF8ToString(func) : 'unknown function',
        ]
    );
  }

  private warnOnce(text: string) {
    this.err(text);
  }

  private getWasmTableEntry(funcPtr: any) {
    let wasmTableMirror: any = [];
    let func = wasmTableMirror[funcPtr];
    if (!func) {
      if (funcPtr >= wasmTableMirror.length)
        wasmTableMirror.length = funcPtr + 1;
      wasmTableMirror[funcPtr] = func = this.wasmTable.get(funcPtr);
    }
    this.assert(
      this.wasmTable.get(funcPtr) == func,
      'JavaScript-side Wasm private table mirror is out of date!'
    );
    return func;
  }

  private ___call_sighandler(fp: any, sig: any) {
    this.getWasmTableEntry(fp)(sig);
  }

  private getRandomDevice() {
    if (
      typeof crypto == 'object' &&
      typeof crypto['getRandomValues'] == 'function'
    ) {
      // for modern web browsers
      let randomBuffer = new Uint8Array(1);

      crypto.getRandomValues(randomBuffer);
      return randomBuffer[0];
    }
    // we couldn't find a proper implementation, as Math.random() is not suitable for /dev/random, see emscripten-core/emscripten/pull/7096

    this.abort(
      'no cryptographic support found for randomDevice. consider polyfilling it if you want to use something insecure like Math.random(), e.g. put this in a --pre-js: let crypto = { getRandomValues: function(array) { for (let i = 0; i < array.length; i++) array[i] = (Math.random()*256)|0 } };'
    );
    return 0;
  }

  private out(out: any) {
    console.log(out);
  }

  private FS = {
    error: () => {
      console.log(
        'Filesystem support (FS) was not included. The problem is that you are using files from JS, but files were not used from C/C++, so filesystem support was not auto-included. You can force-include filesystem support with -sFORCE_FILESYSTEM'
      );
    },
    init: () => {
      this.FS.error();
    },
    createDataFile: () => {
      this.FS.error();
    },
    createPreloadedFile: () => {
      this.FS.error();
    },
    createLazyFile: () => {
      this.FS.error();
    },
    open: () => {
      this.FS.error();
    },
    mkdev: () => {
      this.FS.error();
    },
    registerDevice: () => {
      this.FS.error();
    },
    analyzePath: () => {
      this.FS.error();
    },
    loadFilesFromDB: () => {
      this.FS.error();
    },
    ErrnoError: () => {
      this.FS.error();
    },
  };

  /** @suppress {checkTypes} */
  private jstoi_q(str: string) {
    return parseInt(str);
  }

  private ___syscall_fadvise64(fd: any, offset: any, len: any, advice: any) {
    return 0; // your advice is important to us (but we can't use it)
  }

  private __emscripten_err(str: any) {
    this.assert(typeof str == 'number');
    this.err(this.UTF8ToString(str));
  }

  private nowIsMonotonic = true;
  private __emscripten_get_now_is_monotonic() {
    return this.nowIsMonotonic;
  }

  private __emscripten_get_progname(str: any, len: any) {
    this.assert(typeof str == 'number');
    this.assert(typeof len == 'number');
    // this.stringToUTF8(thisProgram, str, len);
  }

  private __emscripten_out(str: any) {
    this.assert(typeof str == 'number');
    this.out(this.UTF8ToString(str));
  }

  private __emscripten_throw_longjmp() {
    throw Infinity;
  }

  private _abort() {
    this.abort('native code called this.abort()');
  }

  private _emscripten_get_now() {
    return performance.now();
  }

  private _proc_exit(code: number) {
    this.EXITSTATUS = code;
  }
  /** @param {boolean|number=} implicit */
  private exitJS(status: number, implicit: any) {
    this.EXITSTATUS = status;

    this._proc_exit(status);
  }

  /**
   * @param {number=} arg
   * @param {boolean=} noSetTiming
   */
  private setMainLoop(
    browserIterationFunc: any,
    fps: number,
    simulateInfiniteLoop: boolean,
    arg: any,
    noSetTiming: boolean
  ) {
    this.assert(
      !this.Browser.mainLoop.func,
      'emscripten_set_main_loop: there can only be one main loop private at once: call emscripten_cancel_main_loop to cancel the previous one before setting a new one with different parameters.'
    );

    this.Browser.mainLoop.func = browserIterationFunc;
    this.Browser.mainLoop.arg = arg;

    let thisMainLoopId = this.Browser.mainLoop.currentlyRunningMainloop;
    const checkIsRunning = () => {
      if (thisMainLoopId < this.Browser.mainLoop.currentlyRunningMainloop) {
        return false;
      }
      return true;
    };

    // We create the loop runner here but it is not actually running until
    // _emscripten_set_main_loop_timing is called (which might happen a
    // later time).  This member signifies that the current runner has not
    // yet been started so that we can call runtimeKeepalivePush when it
    // gets it timing set for the first time.
    this.Browser.mainLoop.running = false;
    this.Browser.mainLoop['runner'] = () => {
      if (this.ABORT) return;
      if (this.Browser.mainLoop.queue.length > 0) {
        let start = Date.now();
        let blocker: any = this.Browser.mainLoop.queue.shift();
        if (!blocker) return;
        blocker.func(blocker.arg);
        if (this.Browser.mainLoop.remainingBlockers) {
          let remaining = this.Browser.mainLoop.remainingBlockers;
          let next = remaining % 1 == 0 ? remaining - 1 : Math.floor(remaining);
          if (blocker.counted) {
            this.Browser.mainLoop.remainingBlockers = next;
          } else {
            // not counted, but move the progress along a tiny bit
            next = next + 0.5; // do not steal all the next one's progress
            this.Browser.mainLoop.remainingBlockers =
              (8 * remaining + next) / 9;
          }
        }
        this.out(
          'main loop blocker "' +
            blocker.name +
            '" took ' +
            (Date.now() - start) +
            ' ms'
        ); //, left: ' + this.Browser.mainLoop.remainingBlockers);
        this.Browser.mainLoop.updateStatus();

        // catches pause/resume main loop from blocker execution
        if (!checkIsRunning()) return;

        setTimeout(this.Browser.mainLoop.runner, 0);
        return;
      }

      // catch pauses from non-main loop sources
      if (!checkIsRunning()) return;

      // Implement very basic swap interval control
      this.Browser.mainLoop.currentFrameNumber =
        (this.Browser.mainLoop.currentFrameNumber + 1) | 0;
      if (
        this.Browser.mainLoop.timingMode == 1 /*EM_TIMING_RAF*/ &&
        this.Browser.mainLoop.timingValue > 1 &&
        this.Browser.mainLoop.currentFrameNumber %
          this.Browser.mainLoop.timingValue !=
          0
      ) {
        // Not the scheduled time to render this frame - skip.
        this.Browser.mainLoop.scheduler();
        return;
      } else if (
        this.Browser.mainLoop.timingMode == 0 /*EM_TIMING_SETTIMEOUT*/
      ) {
        this.Browser.mainLoop.tickStartTime = this._emscripten_get_now();
      }

      // Signal GL rendering layer that processing of a new frame is about to start. This helps it optimize
      // VBO double-buffering and reduce GPU stalls.

      // if (this.Browser.mainLoop.method === 'timeout' && Module.ctx) {
      //   this.warnOnce(
      //     'Looks like you are rendering without using requestAnimationFrame for the main loop. You should use 0 for the frame rate in emscripten_set_main_loop in order to use requestAnimationFrame, as that can greatly improve your frame rates!'
      //   );
      //   this.Browser.mainLoop.method = ''; // just warn once per call to set main loop
      // }

      this.Browser.mainLoop.runIter(browserIterationFunc);

      // this.checkStackCookie();

      // catch pauses from the main loop itself
      if (!checkIsRunning()) return;

      // Queue new audio data. This is important to be right after the main loop invocation, so that we will immediately be able
      // to queue the newest produced audio samples.
      // TODO: Consider adding pre- and post- rAF callbacks so that this.GL.newRenderingFrameStarted() and SDL.audio.queueNewAudioData()
      //       do not need to be hardcoded into this function, but can be more generic.
      // if (typeof SDL == 'object' && SDL.audio && SDL.audio.queueNewAudioData)
      //   SDL.audio.queueNewAudioData();

      // this.Browser.mainLoop.scheduler();
    };

    // if (!noSetTiming) {
    //   if (fps && fps > 0)
    //     _emscripten_set_main_loop_timing(
    //       0 /*EM_TIMING_SETTIMEOUT*/,
    //       1000.0 / fps
    //     );
    //   else _emscripten_set_main_loop_timing(1 /*EM_TIMING_RAF*/, 1); // Do rAF by rendering each frame (no decimating)

    //   this.Browser.mainLoop.scheduler();
    // }

    // if (simulateInfiniteLoop) {
    //   throw 'unwind';
    // }
  }

  private callUserCallback(func: () => void) {
    if (this.ABORT) {
      this.err(
        'user callback triggered after runtime exited or application aborted.  Ignoring.'
      );
      return;
    }
    try {
      func();
    } catch (e: any) {
      console.log(e);
    }
  }

  private Browser: any = {
    mainLoop: {
      running: false,
      scheduler: null,
      method: '',
      currentlyRunningMainloop: 0,
      func: null,
      arg: 0,
      timingMode: 0,
      timingValue: 0,
      currentFrameNumber: 0,
      queue: [],
      pause: () => {
        this.Browser.mainLoop.scheduler = null;
        // Incrementing this signals the previous main loop that it's now become old, and it must return.
        this.Browser.mainLoop.currentlyRunningMainloop++;
      },
      resume: () => {
        this.Browser.mainLoop.currentlyRunningMainloop++;
        let timingMode = this.Browser.mainLoop.timingMode;
        let timingValue = this.Browser.mainLoop.timingValue;
        let func = this.Browser.mainLoop.func;
        this.Browser.mainLoop.func = null;
        // do not set timing and call scheduler, we will do it on the next lines
        this.setMainLoop(func, 0, false, this.Browser.mainLoop.arg, true);
        this.Browser.mainLoop.scheduler();
      },
      updateStatus: () => {
        // if (Module['setStatus']) {
        //   let message = Module['statusMessage'] || 'Please wait...';
        //   let remaining = this.Browser.mainLoop.remainingBlockers;
        //   let expected = this.Browser.mainLoop.expectedBlockers;
        //   if (remaining) {
        //     if (remaining < expected) {
        //       Module['setStatus'](
        //         message + ' (' + (expected - remaining) + '/' + expected + ')'
        //       );
        //     } else {
        //       Module['setStatus'](message);
        //     }
        //   } else {
        //     Module['setStatus']('');
        //   }
        // }
      },
      runIter: (func: any) => {
        // if (ABORT) return;
        // if (Module['preMainLoop']) {
        //   let preRet = Module['preMainLoop']();
        //   if (preRet === false) {
        //     return; // |return false| skips a frame
        //   }
        // }
        // callUserCallback(func);
        // if (Module['postMainLoop']) Module['postMainLoop']();
      },
    },
    isFullscreen: false,
    pointerLock: false,
    moduleContextCreatedCallbacks: [],
    workers: [],
    init: () => {
      // if (!Module['preloadPlugins']) Module['preloadPlugins'] = []; // needs to exist even in workers
      // if (this.Browser.initted) return;
      // this.Browser.initted = true;
      // try {
      //   new Blob();
      //   this.Browser.hasBlobConstructor = true;
      // } catch (e: any) {
      //   this.Browser.hasBlobConstructor = false;
      //   errthis.('warning: no blob constructor, cannot create blobs with mimetypes');
      // }
      // this.Browser.BlobBuilder =
      //   typeof MozBlobBuilder != 'undefined'
      //     ? MozBlobBuilder
      //     : typeof WebKitBlobBuilder != 'undefined'
      //     ? WebKitBlobBuilder
      //     : !this.Browser.hasBlobConstructor
      //     ? errthis.('warning: no BlobBuilder')
      //     : null;
      // this.Browser.URLObject =
      //   typeof window != 'undefined'
      //     ? window.URL
      //       ? window.URL
      //       : window.webkitURL
      //     : undefined;
      // if (!Module.noImageDecoding && typeof this.Browser.URLObject == 'undefined') {
      //   this.err(
      //     'warning: Browser does not support creating object URLs. Built-in browser image decoding will not be available.'
      //   );
      //   Module.noImageDecoding = true;
      // }
    },
    handledByPreloadPlugin: (
      byteArray: any,
      fullname: any,
      finish: any,
      onerror: any
    ) => {
      // Ensure plugins are ready.
      // this.Browser.init();
      // let handled = false;
      // Module['preloadPlugins'].forEach((plugin) => {
      //   if (handled) return;
      //   if (plugin['canHandle'](fullname)) {
      //     plugin['handle'](byteArray, fullname, finish, onerror);
      //     handled = true;
      //   }
      // });
      // return handled;
    },
    createContext: (
      /** @type {HTMLCanvasElement} */ canvas: any,
      useWebGL: any,
      setInModule: any,
      webGLContextAttributes: any
    ) => {
      // if (useWebGL && Module.ctx && canvas == Module.canvas) return Module.ctx; // no need to recreate GL context if it's already been created for this canvas.
      // let ctx;
      // let contextHandle;
      // if (useWebGL) {
      //   // For GLES2/desktop GL compatibility, adjust a few defaults to be different to WebGL defaults, so that they align better with the desktop defaults.
      //   let contextAttributes = {
      //     antialias: false,
      //     alpha: false,
      //     majorVersion: 1,
      //   };
      //   if (webGLContextAttributes) {
      //     for (let attribute in webGLContextAttributes) {
      //       contextAttributes[attribute] = webGLContextAttributes[attribute];
      //     }
      //   }
      //   // This check of existence of GL is here to satisfy Closure compiler, which yells if variable GL is referenced below but GL object is not
      //   // actually compiled in because application is not doing any GL operations. TODO: Ideally if GL is not being used, this function
      //   // this.Browser.createContext() should not even be emitted.
      //   if (typeof GL != 'undefined') {
      //     contextHandle = this.GL.createContext(canvas, contextAttributes);
      //     if (contextHandle) {
      //       ctx = this.GL.getContext(contextHandle).GLctx;
      //     }
      //   }
      // } else {
      //   ctx = canvas.getContext('2d');
      // }
      // if (!ctx) return null;
      // if (setInModule) {
      //   if (!useWebGL)
      //     this.assert(
      //       typeof GLctx == 'undefined',
      //       'cannot set in module if GLctx is used, but we are a non-GL context that would replace it'
      //     );
      //   Module.ctx = ctx;
      //   if (useWebGL) this.GL.makeContextCurrent(contextHandle);
      //   Module.useWebGL = useWebGL;
      //   this.Browser.moduleContextCreatedCallbacks.forEach((callback) => {
      //     callback();
      //   });
      //   this.Browser.init();
      // }
      // return ctx;
    },
    destroyContext: function (canvas: any, useWebGL: any, setInModule: any) {},
    fullscreenHandlersInstalled: false,
    lockPointer: undefined,
    resizeCanvas: undefined,
    requestFullscreen: (lockPointer: any, resizeCanvas: any) => {
      // this.Browser.lockPointer = lockPointer;
      // this.Browser.resizeCanvas = resizeCanvas;
      // if (typeof this.Browser.lockPointer == 'undefined') this.Browser.lockPointer = true;
      // if (typeof this.Browser.resizeCanvas == 'undefined')
      //   this.Browser.resizeCanvas = false;
      // let canvas = Module['canvas'];
      // const fullscreenChange = () => {
      //   this.Browser.isFullscreen = false;
      //   let canvasContainer = canvas.parentNode;
      //   if (
      //     (document['fullscreenElement'] ||
      //       document['mozFullScreenElement'] ||
      //       document['msFullscreenElement'] ||
      //       document['webkitFullscreenElement'] ||
      //       document['webkitCurrentFullScreenElement']) === canvasContainer
      //   ) {
      //     canvas.exitFullscreen = this.Browser.exitFullscreen;
      //     if (this.Browser.lockPointer) canvas.requestPointerLock();
      //     this.Browser.isFullscreen = true;
      //     if (this.Browser.resizeCanvas) {
      //       this.Browser.setFullscreenCanvasSize();
      //     } else {
      //       this.Browser.updateCanvasDimensions(canvas);
      //     }
      //   } else {
      //     // remove the full screen specific parent of the canvas again to restore the HTML structure from before going full screen
      //     canvasContainer.parentNode.insertBefore(canvas, canvasContainer);
      //     canvasContainer.parentNode.removeChild(canvasContainer);
      //     if (this.Browser.resizeCanvas) {
      //       this.Browser.setWindowedCanvasSize();
      //     } else {
      //       this.Browser.updateCanvasDimensions(canvas);
      //     }
      //   }
      //   if (Module['onFullScreen'])
      //     Module['onFullScreen'](this.Browser.isFullscreen);
      //   if (Module['onFullscreen'])
      //     Module['onFullscreen'](this.Browser.isFullscreen);
      // };
      // if (!this.Browser.fullscreenHandlersInstalled) {
      //   this.Browser.fullscreenHandlersInstalled = true;
      //   document.addEventListener('fullscreenchange', fullscreenChange, false);
      //   document.addEventListener(
      //     'mozfullscreenchange',
      //     fullscreenChange,
      //     false
      //   );
      //   document.addEventListener(
      //     'webkitfullscreenchange',
      //     fullscreenChange,
      //     false
      //   );
      //   document.addEventListener(
      //     'MSFullscreenChange',
      //     fullscreenChange,
      //     false
      //   );
      // }
      // // create a new parent to ensure the canvas has no siblings. this allows browsers to optimize full screen performance when its parent is the full screen root
      // let canvasContainer = document.createElement('div');
      // canvas.parentNode.insertBefore(canvasContainer, canvas);
      // canvasContainer.appendChild(canvas);
      // // use parent of canvas as full screen root to allow aspect ratio correction (Firefox stretches the root to screen size)
      // canvasContainer.requestFullscreen =
      //   canvasContainer['requestFullscreen'] ||
      //   canvasContainer['mozRequestFullScreen'] ||
      //   canvasContainer['msRequestFullscreen'] ||
      //   (canvasContainer['webkitRequestFullscreen']
      //     ? () =>
      //         canvasContainer['webkitRequestFullscreen'](
      //           Element['ALLOW_KEYBOARD_INPUT']
      //         )
      //     : null) ||
      //   (canvasContainer['webkitRequestFullScreen']
      //     ? () =>
      //         canvasContainer['webkitRequestFullScreen'](
      //           Element['ALLOW_KEYBOARD_INPUT']
      //         )
      //     : null);
      // canvasContainer.requestFullscreen();
    },
    requestFullScreen: () => {
      this.abort(
        'Module.requestFullScreen has been replaced by Module.requestFullscreen (without a capital S)'
      );
    },
    exitFullscreen: () => {
      // This is workaround for chrome. Trying to exit from fullscreen
      // not in fullscreen state will cause "TypeError: Document not active"
      // in chrome. See https://github.com/emscripten-core/emscripten/pull/8236
      if (!this.Browser.isFullscreen) {
        return false;
      }

      // let CFS =
      //   document['exitFullscreen'] ||
      //   document['cancelFullScreen'] ||
      //   document['mozCancelFullScreen'] ||
      //   document['msExitFullscreen'] ||
      //   document['webkitCancelFullScreen'] ||
      //   (() => {});
      // CFS.apply(document, []);
      return true;
    },
    nextRAF: 0,
    fakeRequestAnimationFrame: (func: (...args: any[]) => void) => {
      // try to keep 60fps between calls to here
      let now = Date.now();
      if (this.Browser.nextRAF === 0) {
        this.Browser.nextRAF = now + 1000 / 60;
      } else {
        while (now + 2 >= this.Browser.nextRAF) {
          // fudge a little, to avoid timer jitter causing us to do lots of delay:0
          this.Browser.nextRAF += 1000 / 60;
        }
      }
      let delay = Math.max(this.Browser.nextRAF - now, 0);
      setTimeout(func, delay);
    },
    requestAnimationFrame: (func: FrameRequestCallback) => {
      if (typeof requestAnimationFrame == 'function') {
        requestAnimationFrame(func);
        return;
      }
      let RAF = this.Browser.fakeRequestAnimationFrame;
      RAF(func);
    },
    safeSetTimeout: (func: any, timeout: any) => {
      // Legacy function, this is used by the SDL2 port so we need to keep it
      // around at least until that is updated.
      // See https://github.com/libsdl-org/SDL/pull/6304
      // return safeSetTimeout(func, timeout);
    },
    safeRequestAnimationFrame: (func: any) => {
      // return this.Browser.requestAnimationFrame(() => {
      //   callUserCallback(func);
      // });
    },
    getMimetype: (name: string) => {
      return {
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        bmp: 'image/bmp',
        ogg: 'audio/ogg',
        wav: 'audio/wav',
        mp3: 'audio/mpeg',
      }[name.substr(name.lastIndexOf('.') + 1)];
    },
    getUserMedia: (func: any) => {
      // if (!window.getUserMedia) {
      //   window.getUserMedia =
      //     navigator['getUserMedia'] || navigator['mozGetUserMedia'];
      // }
      // window.getUserMedia(func);
    },
    getMovementX: (event: { [x: string]: any }) => {
      return (
        event['movementX'] ||
        event['mozMovementX'] ||
        event['webkitMovementX'] ||
        0
      );
    },
    getMovementY: (event: { [x: string]: any }) => {
      return (
        event['movementY'] ||
        event['mozMovementY'] ||
        event['webkitMovementY'] ||
        0
      );
    },
    getMouseWheelDelta: (event: {
      type: string;
      detail: number;
      wheelDelta: number;
      deltaY: number;
      deltaMode: number;
    }) => {
      let delta = 0;
      switch (event.type) {
        case 'DOMMouseScroll':
          // 3 lines make up a step
          delta = event.detail / 3;
          break;
        case 'mousewheel':
          // 120 units make up a step
          delta = event.wheelDelta / 120;
          break;
        case 'wheel':
          delta = event.deltaY;
          switch (event.deltaMode) {
            case 0:
              // DOM_DELTA_PIXEL: 100 pixels make up a step
              delta /= 100;
              break;
            case 1:
              // DOM_DELTA_LINE: 3 lines make up a step
              delta /= 3;
              break;
            case 2:
              // DOM_DELTA_PAGE: A page makes up 80 steps
              delta *= 80;
              break;
            default:
              throw 'unrecognized mouse wheel delta mode: ' + event.deltaMode;
          }
          break;
        default:
          throw 'unrecognized mouse wheel event: ' + event.type;
      }
      return delta;
    },
    mouseX: 0,
    mouseY: 0,
    mouseMovementX: 0,
    mouseMovementY: 0,
    touches: {},
    lastTouches: {},
    calculateMouseEvent: (event: any) => {
      // // event should be mousemove, mousedown or mouseup
      // if (this.Browser.pointerLock) {
      //   // When the pointer is locked, calculate the coordinates
      //   // based on the movement of the mouse.
      //   // Workaround for Firefox bug 764498
      //   if (event.type != 'mousemove' && 'mozMovementX' in event) {
      //     this.Browser.mouseMovementX = this.Browser.mouseMovementY = 0;
      //   } else {
      //     this.Browser.mouseMovementX = this.Browser.getMovementX(event);
      //     this.Browser.mouseMovementY = this.Browser.getMovementY(event);
      //   }
      //   // check if SDL is available
      //   if (typeof SDL != 'undefined') {
      //     this.Browser.mouseX = SDL.mouseX + this.Browser.mouseMovementX;
      //     this.Browser.mouseY = SDL.mouseY + this.Browser.mouseMovementY;
      //   } else {
      //     // just add the mouse delta to the current absolut mouse position
      //     // FIXME: ideally this should be clamped against the canvas size and zero
      //     this.Browser.mouseX += this.Browser.mouseMovementX;
      //     this.Browser.mouseY += this.Browser.mouseMovementY;
      //   }
      // } else {
      //   // Otherwise, calculate the movement based on the changes
      //   // in the coordinates.
      //   let rect = Module['canvas'].getBoundingClientRect();
      //   let cw = Module['canvas'].width;
      //   let ch = Module['canvas'].height;
      //   // Neither .scrollX or .pageXOffset are defined in a spec, but
      //   // we prefer .scrollX because it is currently in a spec draft.
      //   // (see: http://www.w3.org/TR/2013/WD-cssom-view-20131217/)
      //   let scrollX =
      //     typeof window.scrollX != 'undefined'
      //       ? window.scrollX
      //       : window.pageXOffset;
      //   let scrollY =
      //     typeof window.scrollY != 'undefined'
      //       ? window.scrollY
      //       : window.pageYOffset;
      //   // If this assert lands, it's likely because the browser doesn't support scrollX or pageXOffset
      //   // and we have no viable fallback.
      //   this.assert(
      //     typeof scrollX != 'undefined' && typeof scrollY != 'undefined',
      //     'Unable to retrieve scroll position, mouse positions likely broken.'
      //   );
      //   if (
      //     event.type === 'touchstart' ||
      //     event.type === 'touchend' ||
      //     event.type === 'touchmove'
      //   ) {
      //     let touch = event.touch;
      //     if (touch === undefined) {
      //       return; // the "touch" property is only defined in SDL
      //     }
      //     let adjustedX = touch.pageX - (scrollX + rect.left);
      //     let adjustedY = touch.pageY - (scrollY + rect.top);
      //     adjustedX = adjustedX * (cw / rect.width);
      //     adjustedY = adjustedY * (ch / rect.height);
      //     let coords = { x: adjustedX, y: adjustedY };
      //     if (event.type === 'touchstart') {
      //       this.Browser.lastTouches[touch.identifier] = coords;
      //       this.Browser.touches[touch.identifier] = coords;
      //     } else if (event.type === 'touchend' || event.type === 'touchmove') {
      //       let last = this.Browser.touches[touch.identifier];
      //       if (!last) last = coords;
      //       this.Browser.lastTouches[touch.identifier] = last;
      //       this.Browser.touches[touch.identifier] = coords;
      //     }
      //     return;
      //   }
      //   let x = event.pageX - (scrollX + rect.left);
      //   let y = event.pageY - (scrollY + rect.top);
      //   // the canvas might be CSS-scaled compared to its backbuffer;
      //   // SDL-using content will want mouse coordinates in terms
      //   // of backbuffer units.
      //   x = x * (cw / rect.width);
      //   y = y * (ch / rect.height);
      //   this.Browser.mouseMovementX = x - this.Browser.mouseX;
      //   this.Browser.mouseMovementY = y - this.Browser.mouseY;
      //   this.Browser.mouseX = x;
      //   this.Browser.mouseY = y;
      // }
    },
    resizeListeners: [],
    updateResizeListeners: () => {
      // let canvas = Module['canvas'];
      // this.Browser.resizeListeners.forEach((listener) => {
      //   listener(canvas.width, canvas.height);
      // });
    },
    setCanvasSize: (width: any, height: any, noUpdates: any) => {
      // let canvas = Module['canvas'];
      // this.Browser.updateCanvasDimensions(canvas, width, height);
      // if (!noUpdates) this.Browser.updateResizeListeners();
    },
    windowedWidth: 0,
    windowedHeight: 0,
    setFullscreenCanvasSize: () => {
      // check if SDL is available
      // if (typeof SDL != 'undefined') {
      //   let flags = this.HEAPU32[SDL.screen >> 2];
      //   flags = flags | 0x00800000; // set SDL_FULLSCREEN flag
      //   this.HEAP32[SDL.screen >> 2] = flags;
      // }
      // this.Browser.updateCanvasDimensions(Module['canvas']);
      // this.Browser.updateResizeListeners();
    },
    setWindowedCanvasSize: () => {
      // check if SDL is available
      // if (typeof SDL != 'undefined') {
      //   let flags = this.HEAPU32[SDL.screen >> 2];
      //   flags = flags & ~0x00800000; // clear SDL_FULLSCREEN flag
      //   this.HEAP32[SDL.screen >> 2] = flags;
      // }
      // this.Browser.updateCanvasDimensions(Module['canvas']);
      // this.Browser.updateResizeListeners();
    },
    updateCanvasDimensions: (canvas: any, wNative: any, hNative: any) => {
      // if (wNative && hNative) {
      //   canvas.widthNative = wNative;
      //   canvas.heightNative = hNative;
      // } else {
      //   wNative = canvas.widthNative;
      //   hNative = canvas.heightNative;
      // }
      // let w = wNative;
      // let h = hNative;
      // if (Module['forcedAspectRatio'] && Module['forcedAspectRatio'] > 0) {
      //   if (w / h < Module['forcedAspectRatio']) {
      //     w = Math.round(h * Module['forcedAspectRatio']);
      //   } else {
      //     h = Math.round(w / Module['forcedAspectRatio']);
      //   }
      // }
      // if (
      //   (document['fullscreenElement'] ||
      //     document['mozFullScreenElement'] ||
      //     document['msFullscreenElement'] ||
      //     document['webkitFullscreenElement'] ||
      //     document['webkitCurrentFullScreenElement']) === canvas.parentNode &&
      //   typeof screen != 'undefined'
      // ) {
      //   let factor = Math.min(screen.width / w, screen.height / h);
      //   w = Math.round(w * factor);
      //   h = Math.round(h * factor);
      // }
      // if (this.Browser.resizeCanvas) {
      //   if (canvas.width != w) canvas.width = w;
      //   if (canvas.height != h) canvas.height = h;
      //   if (typeof canvas.style != 'undefined') {
      //     canvas.style.removeProperty('width');
      //     canvas.style.removeProperty('height');
      //   }
      // } else {
      //   if (canvas.width != wNative) canvas.width = wNative;
      //   if (canvas.height != hNative) canvas.height = hNative;
      //   if (typeof canvas.style != 'undefined') {
      //     if (w != wNative || h != hNative) {
      //       canvas.style.setProperty('width', w + 'px', 'important');
      //       canvas.style.setProperty('height', h + 'px', 'important');
      //     } else {
      //       canvas.style.removeProperty('width');
      //       canvas.style.removeProperty('height');
      //     }
      //   }
      // }
    },
  };

  private AL: any = {
    QUEUE_INTERVAL: 25,
    QUEUE_LOOKAHEAD: 0.1,
    DEVICE_NAME: 'Emscripten OpenAL',
    CAPTURE_DEVICE_NAME: 'Emscripten OpenAL capture',
    ALC_EXTENSIONS: { ALC_SOFT_pause_device: true, ALC_SOFT_HRTF: true },
    AL_EXTENSIONS: {
      AL_EXT_float32: true,
      AL_SOFT_loop_points: true,
      AL_SOFT_source_length: true,
      AL_EXT_source_distance_model: true,
      AL_SOFT_source_spatialize: true,
    },
    _alcErr: 0,
    alcErr: 0,
    deviceRefCounts: {},
    alcStringCache: {},
    paused: false,
    stringCache: {},
    contexts: {},
    currentCtx: null,
    buffers: {
      0: {
        id: 0,
        refCount: 0,
        audioBuf: null,
        frequency: 0,
        bytesPerSample: 2,
        channels: 1,
        length: 0,
      },
    },
    paramArray: [],
    _nextId: 1,
    newId: () => {
      return this.AL.freeIds.length > 0
        ? this.AL.freeIds.pop()
        : this.AL._nextId++;
    },
    freeIds: [],
    scheduleContextAudio: (ctx: { sources: { [x: string]: any } }) => {
      // If we are animating using the requestAnimationFrame method, then the main loop does not run when in the background.
      // To give a perfect glitch-free audio stop when switching from foreground to background, we need to avoid updating
      // audio altogether when in the background, so detect that case and kill audio buffer streaming if so.
      if (
        this.Browser.mainLoop.timingMode === 1 /* EM_TIMING_RAF */ &&
        document['visibilityState'] != 'visible'
      ) {
        return;
      }

      for (let i in ctx.sources) {
        this.AL.scheduleSourceAudio(ctx.sources[i]);
      }
    },
    scheduleSourceAudio: (
      src: {
        state: number;
        bufStartTime: any;
        bufOffset: any;
        bufsProcessed: any;
        audioQueue: any[];
        bufQueue: string | any[];
        looping: any;
        context: {
          audioCtx: { createBufferSource: () => any; currentTime: number };
        };
        playbackRate: number;
        type: number;
        gain: any;
      },
      lookahead?: any
    ) => {
      // See comment on scheduleContextAudio above.
      if (
        this.Browser.mainLoop.timingMode === 1 /*EM_TIMING_RAF*/ &&
        document['visibilityState'] != 'visible'
      ) {
        return;
      }
      if (src.state !== 0x1012 /* AL_PLAYING */) {
        return;
      }

      let currentTime = this.AL.updateSourceTime(src);

      let startTime = src.bufStartTime;
      let startOffset = src.bufOffset;
      let bufCursor = src.bufsProcessed;

      // Advance past any audio that is already scheduled
      for (let i = 0; i < src.audioQueue.length; i++) {
        let audioSrc = src.audioQueue[i];
        startTime = audioSrc._startTime + audioSrc._duration;
        startOffset = 0.0;
        bufCursor += audioSrc._skipCount + 1;
      }

      if (!lookahead) {
        lookahead = this.AL.QUEUE_LOOKAHEAD;
      }
      let lookaheadTime = currentTime + lookahead;
      let skipCount = 0;
      while (startTime < lookaheadTime) {
        if (bufCursor >= src.bufQueue.length) {
          if (src.looping) {
            bufCursor %= src.bufQueue.length;
          } else {
            break;
          }
        }

        let buf = src.bufQueue[bufCursor % src.bufQueue.length];
        // If the buffer contains no data, skip it
        if (buf.length === 0) {
          skipCount++;
          // If we've gone through the whole queue and everything is 0 length, just give up
          if (skipCount === src.bufQueue.length) {
            break;
          }
        } else {
          let audioSrc = src.context.audioCtx.createBufferSource();
          audioSrc.buffer = buf.audioBuf;
          audioSrc.playbackRate.value = src.playbackRate;
          if (buf.audioBuf._loopStart || buf.audioBuf._loopEnd) {
            audioSrc.loopStart = buf.audioBuf._loopStart;
            audioSrc.loopEnd = buf.audioBuf._loopEnd;
          }

          let duration = 0.0;
          // If the source is a looping static buffer, use native looping for gapless playback
          if (src.type === 0x1028 /* AL_STATIC */ && src.looping) {
            duration = Number.POSITIVE_INFINITY;
            audioSrc.loop = true;
            if (buf.audioBuf._loopStart) {
              audioSrc.loopStart = buf.audioBuf._loopStart;
            }
            if (buf.audioBuf._loopEnd) {
              audioSrc.loopEnd = buf.audioBuf._loopEnd;
            }
          } else {
            duration = (buf.audioBuf.duration - startOffset) / src.playbackRate;
          }

          audioSrc._startOffset = startOffset;
          audioSrc._duration = duration;
          audioSrc._skipCount = skipCount;
          skipCount = 0;

          audioSrc.connect(src.gain);

          if (typeof audioSrc.start != 'undefined') {
            // Sample the current time as late as possible to mitigate drift
            startTime = Math.max(startTime, src.context.audioCtx.currentTime);
            audioSrc.start(startTime, startOffset);
          } else if (typeof audioSrc.noteOn != 'undefined') {
            startTime = Math.max(startTime, src.context.audioCtx.currentTime);
            audioSrc.noteOn(startTime);
          }
          audioSrc._startTime = startTime;
          src.audioQueue.push(audioSrc);

          startTime += duration;
        }

        startOffset = 0.0;
        bufCursor++;
      }
    },
    updateSourceTime: (src: {
      context: { audioCtx: { currentTime: any } };
      state: number;
      bufStartTime: number;
      bufOffset: number;
      playbackRate: number;
      audioQueue: { _startTime: number }[] | void[];
      bufsProcessed: number;
      bufQueue: string | any[];
      looping: any;
      type: number;
    }) => {
      let currentTime = src.context.audioCtx.currentTime;
      if (src.state !== 0x1012 /* AL_PLAYING */) {
        return currentTime;
      }

      // if the start time is unset, determine it based on the current offset.
      // This will be the case when a source is resumed after being paused, and
      // allows us to pretend that the source actually started playing some time
      // in the past such that it would just now have reached the stored offset.
      if (!isFinite(src.bufStartTime)) {
        src.bufStartTime = currentTime - src.bufOffset / src.playbackRate;
        src.bufOffset = 0.0;
      }

      let nextStartTime = 0.0;
      while (src.audioQueue.length) {
        let audioSrc: any = src.audioQueue[0];
        src.bufsProcessed += audioSrc._skipCount;
        nextStartTime = audioSrc._startTime + audioSrc._duration; // n.b. audioSrc._duration already factors in playbackRate, so no divide by src.playbackRate on it.

        if (currentTime < nextStartTime) {
          break;
        }

        src.audioQueue.shift();
        src.bufStartTime = nextStartTime;
        src.bufOffset = 0.0;
        src.bufsProcessed++;
      }

      if (src.bufsProcessed >= src.bufQueue.length && !src.looping) {
        // The source has played its entire queue and is non-looping, so just mark it as stopped.
        this.AL.setSourceState(src, 0x1014 /* AL_STOPPED */);
      } else if (src.type === 0x1028 /* AL_STATIC */ && src.looping) {
        // If the source is a looping static buffer, determine the buffer offset based on the loop points
        let buf = src.bufQueue[0];
        if (buf.length === 0) {
          src.bufOffset = 0.0;
        } else {
          let delta = (currentTime - src.bufStartTime) * src.playbackRate;
          let loopStart = buf.audioBuf._loopStart || 0.0;
          let loopEnd = buf.audioBuf._loopEnd || buf.audioBuf.duration;
          if (loopEnd <= loopStart) {
            loopEnd = buf.audioBuf.duration;
          }

          if (delta < loopEnd) {
            src.bufOffset = delta;
          } else {
            src.bufOffset =
              loopStart + ((delta - loopStart) % (loopEnd - loopStart));
          }
        }
      } else if (src.audioQueue[0]) {
        // The source is still actively playing, so we just need to calculate where we are in the current buffer
        // so it can be remembered if the source gets paused.
        src.bufOffset =
          (currentTime - src.audioQueue[0]._startTime) * src.playbackRate;
      } else {
        // The source hasn't finished yet, but there is no scheduled audio left for it. This can be because
        // the source has just been started/resumed, or due to an underrun caused by a long blocking operation.
        // We need to determine what state we would be in by this point in time so that when we next schedule
        // audio playback, it will be just as if no underrun occurred.

        if (src.type !== 0x1028 /* AL_STATIC */ && src.looping) {
          // if the source is a looping buffer queue, let's first calculate the queue duration, so we can
          // quickly fast forward past any full loops of the queue and only worry about the remainder.
          let srcDuration = this.AL.sourceDuration(src) / src.playbackRate;
          if (srcDuration > 0.0) {
            src.bufStartTime +=
              Math.floor((currentTime - src.bufStartTime) / srcDuration) *
              srcDuration;
          }
        }

        // Since we've already skipped any full-queue loops if there were any, we just need to find
        // out where in the queue the remaining time puts us, which won't require stepping through the
        // entire queue more than once.
        for (let i = 0; i < src.bufQueue.length; i++) {
          if (src.bufsProcessed >= src.bufQueue.length) {
            if (src.looping) {
              src.bufsProcessed %= src.bufQueue.length;
            } else {
              this.AL.setSourceState(src, 0x1014 /* AL_STOPPED */);
              break;
            }
          }

          let buf = src.bufQueue[src.bufsProcessed];
          if (buf.length > 0) {
            nextStartTime =
              src.bufStartTime + buf.audioBuf.duration / src.playbackRate;

            if (currentTime < nextStartTime) {
              src.bufOffset =
                (currentTime - src.bufStartTime) * src.playbackRate;
              break;
            }

            src.bufStartTime = nextStartTime;
          }

          src.bufOffset = 0.0;
          src.bufsProcessed++;
        }
      }

      return currentTime;
    },
    cancelPendingSourceAudio: (src: { audioQueue: string | any[] }) => {
      // this.AL.updateSourceTime(src);
      // for (let i = 1; i < src.audioQueue.length; i++) {
      //   let audioSrc = src.audioQueue[i];
      //   audioSrc.stop();
      // }
      // if (src.audioQueue.length > 1) {
      //   src.audioQueue.length = 1;
      // }
    },
    stopSourceAudio: (src: { audioQueue: string | any[] }) => {
      // for (let i = 0; i < src.audioQueue.length; i++) {
      //   src.audioQueue[i].stop();
      // }
      // src.audioQueue.length = 0;
    },
    setSourceState: (
      src: {
        state: number;
        bufsProcessed: number;
        bufOffset: number;
        bufStartTime: number;
        bufQueue: string | any[];
      },
      state: number
    ) => {
      if (state === 0x1012 /* AL_PLAYING */) {
        if (
          src.state === 0x1012 /* AL_PLAYING */ ||
          src.state == 0x1014 /* AL_STOPPED */
        ) {
          src.bufsProcessed = 0;
          src.bufOffset = 0.0;
        } else {
        }

        this.AL.stopSourceAudio(src);

        src.state = 0x1012 /* AL_PLAYING */;
        src.bufStartTime = Number.NEGATIVE_INFINITY;
        this.AL.scheduleSourceAudio(src);
      } else if (state === 0x1013 /* AL_PAUSED */) {
        if (src.state === 0x1012 /* AL_PLAYING */) {
          // Store off the current offset to restore with on resume.
          this.AL.updateSourceTime(src);
          this.AL.stopSourceAudio(src);

          src.state = 0x1013 /* AL_PAUSED */;
        }
      } else if (state === 0x1014 /* AL_STOPPED */) {
        if (src.state !== 0x1011 /* AL_INITIAL */) {
          src.state = 0x1014 /* AL_STOPPED */;
          src.bufsProcessed = src.bufQueue.length;
          src.bufStartTime = Number.NEGATIVE_INFINITY;
          src.bufOffset = 0.0;
          this.AL.stopSourceAudio(src);
        }
      } else if (state === 0x1011 /* AL_INITIAL */) {
        if (src.state !== 0x1011 /* AL_INITIAL */) {
          src.state = 0x1011 /* AL_INITIAL */;
          src.bufsProcessed = 0;
          src.bufStartTime = Number.NEGATIVE_INFINITY;
          src.bufOffset = 0.0;
          this.AL.stopSourceAudio(src);
        }
      }
    },
    initSourcePanner: (src: {
      type: number;
      bufQueue: string | any[];
      spatialize: number;
      panner: { connect: (arg0: any) => void; disconnect: () => void } | null;
      context: { audioCtx: { createPanner: () => any }; gain: any };
      gain: { disconnect: () => void; connect: (arg0: any) => void };
    }) => {
      if (src.type === 0x1030 /* AL_UNDETERMINED */) {
        return;
      }

      // Find the first non-zero buffer in the queue to determine the proper format
      let templateBuf = this.AL.buffers[0];
      for (let i = 0; i < src.bufQueue.length; i++) {
        if (src.bufQueue[i].id !== 0) {
          templateBuf = src.bufQueue[i];
          break;
        }
      }
      // Create a panner if AL_SOURCE_SPATIALIZE_SOFT is set to true, or alternatively if it's set to auto and the source is mono
      if (
        src.spatialize === 1 ||
        (src.spatialize === 2 /* AL_AUTO_SOFT */ && templateBuf.channels === 1)
      ) {
        if (src.panner) {
          return;
        }
        src.panner = src.context.audioCtx.createPanner();

        this.AL.updateSourceGlobal(src);
        this.AL.updateSourceSpace(src);

        src.panner?.connect(src.context.gain);
        src.gain.disconnect();
        src.gain.connect(src.panner);
      } else {
        if (!src.panner) {
          return;
        }

        src.panner.disconnect();
        src.gain.disconnect();
        src.gain.connect(src.context.gain);
        src.panner = null;
      }
    },
    updateContextGlobal: (ctx: { sources: { [x: string]: any } }) => {
      for (let i in ctx.sources) {
        this.AL.updateSourceGlobal(ctx.sources[i]);
      }
    },
    updateSourceGlobal: (src: {
      panner: any;
      refDistance: any;
      maxDistance: any;
      rolloffFactor: any;
      context: { hrtf: any; sourceDistanceModel: any; distanceModel: any };
      distanceModel: any;
    }) => {
      let panner = src.panner;
      if (!panner) {
        return;
      }

      panner.refDistance = src.refDistance;
      panner.maxDistance = src.maxDistance;
      panner.rolloffFactor = src.rolloffFactor;

      panner.panningModel = src.context.hrtf ? 'HRTF' : 'equalpower';

      // Use the source's distance model if AL_SOURCE_DISTANCE_MODEL is enabled
      let distanceModel = src.context.sourceDistanceModel
        ? src.distanceModel
        : src.context.distanceModel;
      switch (distanceModel) {
        case 0:
          panner.distanceModel = 'inverse';
          panner.refDistance = 3.40282e38 /* FLT_MAX */;
          break;
        case 0xd001 /* AL_INVERSE_DISTANCE */:
        case 0xd002 /* AL_INVERSE_DISTANCE_CLAMPED */:
          panner.distanceModel = 'inverse';
          break;
        case 0xd003 /* AL_LINEAR_DISTANCE */:
        case 0xd004 /* AL_LINEAR_DISTANCE_CLAMPED */:
          panner.distanceModel = 'linear';
          break;
        case 0xd005 /* AL_EXPONENT_DISTANCE */:
        case 0xd006 /* AL_EXPONENT_DISTANCE_CLAMPED */:
          panner.distanceModel = 'exponential';
          break;
      }
    },
    updateListenerSpace: (ctx: {
      audioCtx: { listener: any };
      listener: { position: any[]; direction: any[]; up: any[] };
      sources: { [x: string]: any };
    }) => {
      let listener = ctx.audioCtx.listener;
      if (listener.positionX) {
        listener.positionX.value = ctx.listener.position[0];
        listener.positionY.value = ctx.listener.position[1];
        listener.positionZ.value = ctx.listener.position[2];
      } else {
        listener.setPosition(
          ctx.listener.position[0],
          ctx.listener.position[1],
          ctx.listener.position[2]
        );
      }
      if (listener.forwardX) {
        listener.forwardX.value = ctx.listener.direction[0];
        listener.forwardY.value = ctx.listener.direction[1];
        listener.forwardZ.value = ctx.listener.direction[2];
        listener.upX.value = ctx.listener.up[0];
        listener.upY.value = ctx.listener.up[1];
        listener.upZ.value = ctx.listener.up[2];
      } else {
        listener.setOrientation(
          ctx.listener.direction[0],
          ctx.listener.direction[1],
          ctx.listener.direction[2],
          ctx.listener.up[0],
          ctx.listener.up[1],
          ctx.listener.up[2]
        );
      }

      // Update sources that are relative to the listener
      for (let i in ctx.sources) {
        this.AL.updateSourceSpace(ctx.sources[i]);
      }
    },
    updateSourceSpace: (src: {
      panner: any;
      position: any[];
      direction: any[];
      context: { listener: any; speedOfSound: any; dopplerFactor: any };
      relative: any;
      dopplerShift: number;
      velocity: any[];
    }) => {
      if (!src.panner) {
        return;
      }
      let panner = src.panner;

      let posX = src.position[0];
      let posY = src.position[1];
      let posZ = src.position[2];
      let dirX = src.direction[0];
      let dirY = src.direction[1];
      let dirZ = src.direction[2];

      let listener = src.context.listener;
      let lPosX = listener.position[0];
      let lPosY = listener.position[1];
      let lPosZ = listener.position[2];

      // WebAudio does spatialization in world-space coordinates, meaning both the buffer sources and
      // the listener position are in the same absolute coordinate system relative to a fixed origin.
      // By default, OpenAL works this way as well, but it also provides a "listener relative" mode, where
      // a buffer source's coordinate are interpreted not in absolute world space, but as being relative
      // to the listener object itself, so as the listener moves the source appears to move with it
      // with no update required. Since web audio does not support this mode, we must transform the source
      // coordinates from listener-relative space to absolute world space.
      //
      // We do this via affine transformation matrices applied to the source position and source direction.
      // A change-of-basis converts from listener-space displacements to world-space displacements,
      // which must be done for both the source position and direction. Lastly, the source position must be
      // added to the listener position to get the final source position, since the source position represents
      // a displacement from the listener.
      if (src.relative) {
        // Negate the listener direction since forward is -Z.
        let lBackX = -listener.direction[0];
        let lBackY = -listener.direction[1];
        let lBackZ = -listener.direction[2];
        let lUpX = listener.up[0];
        let lUpY = listener.up[1];
        let lUpZ = listener.up[2];

        let inverseMagnitude = (x: number, y: number, z: number) => {
          let length = Math.sqrt(x * x + y * y + z * z);

          if (length < Number.EPSILON) {
            return 0.0;
          }

          return 1.0 / length;
        };

        // Normalize the Back vector
        let invMag = inverseMagnitude(lBackX, lBackY, lBackZ);
        lBackX *= invMag;
        lBackY *= invMag;
        lBackZ *= invMag;

        // ...and the Up vector
        invMag = inverseMagnitude(lUpX, lUpY, lUpZ);
        lUpX *= invMag;
        lUpY *= invMag;
        lUpZ *= invMag;

        // Calculate the Right vector as the cross product of the Up and Back vectors
        let lRightX = lUpY * lBackZ - lUpZ * lBackY;
        let lRightY = lUpZ * lBackX - lUpX * lBackZ;
        let lRightZ = lUpX * lBackY - lUpY * lBackX;

        // Back and Up might not be exactly perpendicular, so the cross product also needs normalization
        invMag = inverseMagnitude(lRightX, lRightY, lRightZ);
        lRightX *= invMag;
        lRightY *= invMag;
        lRightZ *= invMag;

        // Recompute Up from the now orthonormal Right and Back vectors so we have a fully orthonormal basis
        lUpX = lBackY * lRightZ - lBackZ * lRightY;
        lUpY = lBackZ * lRightX - lBackX * lRightZ;
        lUpZ = lBackX * lRightY - lBackY * lRightX;

        let oldX = dirX;
        let oldY = dirY;
        let oldZ = dirZ;

        // Use our 3 vectors to apply a change-of-basis matrix to the source direction
        dirX = oldX * lRightX + oldY * lUpX + oldZ * lBackX;
        dirY = oldX * lRightY + oldY * lUpY + oldZ * lBackY;
        dirZ = oldX * lRightZ + oldY * lUpZ + oldZ * lBackZ;

        oldX = posX;
        oldY = posY;
        oldZ = posZ;

        // ...and to the source position
        posX = oldX * lRightX + oldY * lUpX + oldZ * lBackX;
        posY = oldX * lRightY + oldY * lUpY + oldZ * lBackY;
        posZ = oldX * lRightZ + oldY * lUpZ + oldZ * lBackZ;

        // The change-of-basis corrects the orientation, but the origin is still the listener.
        // Translate the source position by the listener position to finish.
        posX += lPosX;
        posY += lPosY;
        posZ += lPosZ;
      }

      if (panner.positionX) {
        // Assigning to panner.positionX/Y/Z unnecessarily seems to cause performance issues
        // See https://github.com/emscripten-core/emscripten/issues/15847

        if (posX != panner.positionX.value) panner.positionX.value = posX;
        if (posY != panner.positionY.value) panner.positionY.value = posY;
        if (posZ != panner.positionZ.value) panner.positionZ.value = posZ;
      } else {
        panner.setPosition(posX, posY, posZ);
      }
      if (panner.orientationX) {
        // Assigning to panner.orientation/Y/Z unnecessarily seems to cause performance issues
        // See https://github.com/emscripten-core/emscripten/issues/15847

        if (dirX != panner.orientationX.value) panner.orientationX.value = dirX;
        if (dirY != panner.orientationY.value) panner.orientationY.value = dirY;
        if (dirZ != panner.orientationZ.value) panner.orientationZ.value = dirZ;
      } else {
        panner.setOrientation(dirX, dirY, dirZ);
      }

      let oldShift = src.dopplerShift;
      let velX = src.velocity[0];
      let velY = src.velocity[1];
      let velZ = src.velocity[2];
      let lVelX = listener.velocity[0];
      let lVelY = listener.velocity[1];
      let lVelZ = listener.velocity[2];
      if (
        (posX === lPosX && posY === lPosY && posZ === lPosZ) ||
        (velX === lVelX && velY === lVelY && velZ === lVelZ)
      ) {
        src.dopplerShift = 1.0;
      } else {
        // Doppler algorithm from 1.1 spec
        let speedOfSound = src.context.speedOfSound;
        let dopplerFactor = src.context.dopplerFactor;

        let slX = lPosX - posX;
        let slY = lPosY - posY;
        let slZ = lPosZ - posZ;

        let magSl = Math.sqrt(slX * slX + slY * slY + slZ * slZ);
        let vls = (slX * lVelX + slY * lVelY + slZ * lVelZ) / magSl;
        let vss = (slX * velX + slY * velY + slZ * velZ) / magSl;

        vls = Math.min(vls, speedOfSound / dopplerFactor);
        vss = Math.min(vss, speedOfSound / dopplerFactor);

        src.dopplerShift =
          (speedOfSound - dopplerFactor * vls) /
          (speedOfSound - dopplerFactor * vss);
      }
      if (src.dopplerShift !== oldShift) {
        this.AL.updateSourceRate(src);
      }
    },
    updateSourceRate: (src: {
      state: number;
      audioQueue: any[];
      type: number;
      looping: any;
      playbackRate: number;
    }) => {
      if (src.state === 0x1012 /* AL_PLAYING */) {
        // clear scheduled buffers
        this.AL.cancelPendingSourceAudio(src);

        let audioSrc = src.audioQueue[0];
        if (!audioSrc) {
          return; // It is possible that this.AL.scheduleContextAudio() has not yet fed the next buffer, if so, skip.
        }

        let duration;
        if (src.type === 0x1028 /* AL_STATIC */ && src.looping) {
          duration = Number.POSITIVE_INFINITY;
        } else {
          // audioSrc._duration is expressed after factoring in playbackRate, so when changing playback rate, need
          // to recompute/rescale the rate to the new playback speed.
          duration =
            (audioSrc.buffer.duration - audioSrc._startOffset) /
            src.playbackRate;
        }

        audioSrc._duration = duration;
        audioSrc.playbackRate.value = src.playbackRate;

        // reschedule buffers with the new playbackRate
        this.AL.scheduleSourceAudio(src);
      }
    },
    sourceDuration: (src: { bufQueue: string | any[] }) => {
      let length = 0.0;
      for (let i = 0; i < src.bufQueue.length; i++) {
        let audioBuf = src.bufQueue[i].audioBuf;
        length += audioBuf ? audioBuf.duration : 0.0;
      }
      return length;
    },
    sourceTell: (src: {
      bufsProcessed: number;
      bufQueue: { audioBuf: { duration: any } }[];
      bufOffset: any;
    }) => {
      this.AL.updateSourceTime(src);

      let offset: any = 0.0;
      for (let i = 0; i < src.bufsProcessed; i++) {
        if (src.bufQueue[i].audioBuf) {
          offset += src.bufQueue[i].audioBuf.duration;
        }
      }
      offset += src.bufOffset;

      return offset;
    },
    sourceSeek: (
      src: {
        state: number;
        bufQueue: { [x: string]: { audiobuf: { duration: number } } };
        bufsProcessed: number;
        bufOffset: any;
      },
      offset: number
    ) => {
      // let playing = src.state == 0x1012; /* AL_PLAYING */
      // if (playing) {
      //   this.AL.setSourceState(src, 0x1011 /* AL_INITIAL */);
      // }
      // if (src.bufQueue[src.bufsProcessed].audioBuf !== null) {
      //   src.bufsProcessed = 0;
      //   while (offset > src.bufQueue[src.bufsProcessed].audioBuf.duration) {
      //     offset -= src.bufQueue[src.bufsProcessed].audiobuf.duration;
      //     src.bufsProcessed++;
      //   }
      //   src.bufOffset = offset;
      // }
      // if (playing) {
      //   this.AL.setSourceState(src, 0x1012 /* AL_PLAYING */);
      // }
    },
    getGlobalParam: (funcname: any, param: any) => {
      if (!this.AL.currentCtx) {
        return null;
      }

      switch (param) {
        case 49152:
          return this.AL.currentCtx.dopplerFactor;
        case 49155:
          return this.AL.currentCtx.speedOfSound;
        case 53248:
          return this.AL.currentCtx.distanceModel;
        default:
          this.AL.currentCtx.err = 40962;
          return null;
      }
    },
    setGlobalParam: (funcname: any, param: any, value: any) => {
      if (!this.AL.currentCtx) {
        return;
      }

      switch (param) {
        case 49152:
          if (!Number.isFinite(value) || value < 0.0) {
            // Strictly negative values are disallowed
            this.AL.currentCtx.err = 40963;
            return;
          }

          this.AL.currentCtx.dopplerFactor = value;
          this.AL.updateListenerSpace(this.AL.currentCtx);
          break;
        case 49155:
          if (!Number.isFinite(value) || value <= 0.0) {
            // Negative or zero values are disallowed
            this.AL.currentCtx.err = 40963;
            return;
          }

          this.AL.currentCtx.speedOfSound = value;
          this.AL.updateListenerSpace(this.AL.currentCtx);
          break;
        case 53248:
          switch (value) {
            case 0:
            case 0xd001 /* AL_INVERSE_DISTANCE */:
            case 0xd002 /* AL_INVERSE_DISTANCE_CLAMPED */:
            case 0xd003 /* AL_LINEAR_DISTANCE */:
            case 0xd004 /* AL_LINEAR_DISTANCE_CLAMPED */:
            case 0xd005 /* AL_EXPONENT_DISTANCE */:
            case 0xd006 /* AL_EXPONENT_DISTANCE_CLAMPED */:
              this.AL.currentCtx.distanceModel = value;
              this.AL.updateContextGlobal(this.AL.currentCtx);
              break;
            default:
              this.AL.currentCtx.err = 40963;
              return;
          }
          break;
        default:
          this.AL.currentCtx.err = 40962;
          return;
      }
    },
    getListenerParam: (funcname: any, param: any) => {
      if (!this.AL.currentCtx) {
        return null;
      }

      switch (param) {
        case 4100:
          return this.AL.currentCtx.listener.position;
        case 4102:
          return this.AL.currentCtx.listener.velocity;
        case 4111:
          return this.AL.currentCtx.listener.direction.concat(
            this.AL.currentCtx.listener.up
          );
        case 4106:
          return this.AL.currentCtx.gain.gain.value;
        default:
          this.AL.currentCtx.err = 40962;
          return null;
      }
    },
    setListenerParam: (funcname: any, param: any, value: any) => {
      if (!this.AL.currentCtx) {
        return;
      }
      if (!value) {
        this.AL.currentCtx.err = 40962;
        return;
      }

      let listener = this.AL.currentCtx.listener;
      switch (param) {
        case 4100:
          if (
            !Number.isFinite(value[0]) ||
            !Number.isFinite(value[1]) ||
            !Number.isFinite(value[2])
          ) {
            this.AL.currentCtx.err = 40963;
            return;
          }

          listener.position[0] = value[0];
          listener.position[1] = value[1];
          listener.position[2] = value[2];
          this.AL.updateListenerSpace(this.AL.currentCtx);
          break;
        case 4102:
          if (
            !Number.isFinite(value[0]) ||
            !Number.isFinite(value[1]) ||
            !Number.isFinite(value[2])
          ) {
            this.AL.currentCtx.err = 40963;
            return;
          }

          listener.velocity[0] = value[0];
          listener.velocity[1] = value[1];
          listener.velocity[2] = value[2];
          this.AL.updateListenerSpace(this.AL.currentCtx);
          break;
        case 4106:
          if (!Number.isFinite(value) || value < 0.0) {
            this.AL.currentCtx.err = 40963;
            return;
          }

          this.AL.currentCtx.gain.gain.value = value;
          break;
        case 4111:
          if (
            !Number.isFinite(value[0]) ||
            !Number.isFinite(value[1]) ||
            !Number.isFinite(value[2]) ||
            !Number.isFinite(value[3]) ||
            !Number.isFinite(value[4]) ||
            !Number.isFinite(value[5])
          ) {
            this.AL.currentCtx.err = 40963;
            return;
          }

          listener.direction[0] = value[0];
          listener.direction[1] = value[1];
          listener.direction[2] = value[2];
          listener.up[0] = value[3];
          listener.up[1] = value[4];
          listener.up[2] = value[5];
          this.AL.updateListenerSpace(this.AL.currentCtx);
          break;
        default:
          this.AL.currentCtx.err = 40962;
          return;
      }
    },
    getBufferParam: (funcname: any, bufferId: number, param: any) => {
      if (!this.AL.currentCtx) {
        return;
      }
      let buf = this.AL.buffers[bufferId];
      if (!buf || bufferId === 0) {
        this.AL.currentCtx.err = 40961;
        return;
      }

      switch (param) {
        case 0x2001 /* AL_FREQUENCY */:
          return buf.frequency;
        case 0x2002 /* AL_BITS */:
          return buf.bytesPerSample * 8;
        case 0x2003 /* AL_CHANNELS */:
          return buf.channels;
        case 0x2004 /* AL_SIZE */:
          return buf.length * buf.bytesPerSample * buf.channels;
        case 0x2015 /* AL_LOOP_POINTS_SOFT */:
          if (buf.length === 0) {
            return [0, 0];
          }
          return [
            (buf.audioBuf._loopStart || 0.0) * buf.frequency,
            (buf.audioBuf._loopEnd || buf.length) * buf.frequency,
          ];
        default:
          this.AL.currentCtx.err = 40962;
          return null;
      }
    },
    setBufferParam: (
      funcname: any,
      bufferId: number,
      param: any,
      value: number | number[] | null
    ) => {
      // if (!this.AL.currentCtx) {
      //   return;
      // }
      // let buf = this.AL.buffers[bufferId];
      // if (!buf || bufferId === 0) {
      //   this.AL.currentCtx.err = 40961;
      //   return;
      // }
      // if (!value) {
      //   this.AL.currentCtx.err = 40962;
      //   return;
      // }
      // switch (param) {
      //   case 0x2004 /* AL_SIZE */:
      //     if (value !== 0) {
      //       this.AL.currentCtx.err = 40963;
      //       return;
      //     }
      //     // Per the spec, setting AL_SIZE to 0 is a legal NOP.
      //     break;
      //   case 0x2015 /* AL_LOOP_POINTS_SOFT */:
      //     if (
      //       value[0] < 0 ||
      //       value[0] > buf.length ||
      //       value[1] < 0 ||
      //       value[1] > buf.Length ||
      //       value[0] >= value[1]
      //     ) {
      //       this.AL.currentCtx.err = 40963;
      //       return;
      //     }
      //     if (buf.refCount > 0) {
      //       this.AL.currentCtx.err = 40964;
      //       return;
      //     }
      //     if (buf.audioBuf) {
      //       buf.audioBuf._loopStart = value[0] / buf.frequency;
      //       buf.audioBuf._loopEnd = value[1] / buf.frequency;
      //     }
      //     break;
      //   default:
      //     this.AL.currentCtx.err = 40962;
      //     return;
      // }
    },
    getSourceParam: (funcname: any, sourceId: string | number, param: any) => {
      if (!this.AL.currentCtx) {
        return null;
      }
      let src = this.AL.currentCtx.sources[sourceId];
      if (!src) {
        this.AL.currentCtx.err = 40961;
        return null;
      }

      let internalLength = 0;
      switch (param) {
        case 0x202 /* AL_SOURCE_RELATIVE */:
          return src.relative;
        case 0x1001 /* AL_CONE_INNER_ANGLE */:
          return src.coneInnerAngle;
        case 0x1002 /* AL_CONE_OUTER_ANGLE */:
          return src.coneOuterAngle;
        case 0x1003 /* AL_PITCH */:
          return src.pitch;
        case 4100:
          return src.position;
        case 4101:
          return src.direction;
        case 4102:
          return src.velocity;
        case 0x1007 /* AL_LOOPING */:
          return src.looping;
        case 0x1009 /* AL_BUFFER */:
          if (src.type === 0x1028 /* AL_STATIC */) {
            return src.bufQueue[0].id;
          }
          return 0;
        case 4106:
          return src.gain.gain.value;
        case 0x100d /* AL_MIN_GAIN */:
          return src.minGain;
        case 0x100e /* AL_MAX_GAIN */:
          return src.maxGain;
        case 0x1010 /* AL_SOURCE_STATE */:
          return src.state;
        case 0x1015 /* AL_BUFFERS_QUEUED */:
          if (src.bufQueue.length === 1 && src.bufQueue[0].id === 0) {
            return 0;
          }
          return src.bufQueue.length;
        case 0x1016 /* AL_BUFFERS_PROCESSED */:
          if (
            (src.bufQueue.length === 1 && src.bufQueue[0].id === 0) ||
            src.looping
          ) {
            return 0;
          }
          return src.bufsProcessed;
        case 0x1020 /* AL_REFERENCE_DISTANCE */:
          return src.refDistance;
        case 0x1021 /* AL_ROLLOFF_FACTOR */:
          return src.rolloffFactor;
        case 0x1022 /* AL_CONE_OUTER_GAIN */:
          return src.coneOuterGain;
        case 0x1023 /* AL_MAX_DISTANCE */:
          return src.maxDistance;
        case 0x1024 /* AL_SEC_OFFSET */:
          return this.AL.sourceTell(src);
        case 0x1025 /* AL_SAMPLE_OFFSET */:
          let sampleOffset = this.AL.sourceTell(src);
          if (sampleOffset > 0.0) {
            sampleOffset *= src.bufQueue[0].frequency;
          }
          return sampleOffset;
        case 0x1026 /* AL_BYTE_OFFSET */:
          let byteOffset = this.AL.sourceTell(src);
          if (byteOffset > 0.0) {
            byteOffset *=
              src.bufQueue[0].frequency * src.bufQueue[0].bytesPerSample;
          }
          return byteOffset;
        case 0x1027 /* AL_SOURCE_TYPE */:
          return src.type;
        case 0x1214 /* AL_SOURCE_SPATIALIZE_SOFT */:
          return src.spatialize;
        case 0x2009 /* AL_BYTE_LENGTH_SOFT */:
          let bytesPerFrame = 0;
          for (let i = 0; i < src.bufQueue.length; i++) {
            internalLength += src.bufQueue[i].length;
            if (src.bufQueue[i].id !== 0) {
              bytesPerFrame =
                src.bufQueue[i].bytesPerSample * src.bufQueue[i].channels;
            }
          }
          return internalLength * bytesPerFrame;
        case 0x200a /* AL_SAMPLE_LENGTH_SOFT */:
          let length = 0;
          for (let i = 0; i < src.bufQueue.length; i++) {
            internalLength += src.bufQueue[i].length;
          }
          return internalLength;
        case 0x200b /* AL_SEC_LENGTH_SOFT */:
          return this.AL.sourceDuration(src);
        case 53248:
          return src.distanceModel;
        default:
          this.AL.currentCtx.err = 40962;
          return null;
      }
    },
    setSourceParam: (
      funcname: any,
      sourceId: string | number,
      param: any,
      value: any
    ) => {
      if (!this.AL.currentCtx) {
        return;
      }
      let src = this.AL.currentCtx.sources[sourceId];
      if (!src) {
        this.AL.currentCtx.err = 40961;
        return;
      }
      if (value === null) {
        this.AL.currentCtx.err = 40962;
        return;
      }

      switch (param) {
        case 0x202 /* AL_SOURCE_RELATIVE */:
          if (value === 1) {
            src.relative = true;
            this.AL.updateSourceSpace(src);
          } else if (value === 0) {
            src.relative = false;
            this.AL.updateSourceSpace(src);
          } else {
            this.AL.currentCtx.err = 40963;
            return;
          }
          break;
        case 0x1001 /* AL_CONE_INNER_ANGLE */:
          if (!Number.isFinite(value)) {
            this.AL.currentCtx.err = 40963;
            return;
          }

          src.coneInnerAngle = value;
          if (src.panner) {
            src.panner.coneInnerAngle = value % 360.0;
          }
          break;
        case 0x1002 /* AL_CONE_OUTER_ANGLE */:
          if (!Number.isFinite(value)) {
            this.AL.currentCtx.err = 40963;
            return;
          }

          src.coneOuterAngle = value;
          if (src.panner) {
            src.panner.coneOuterAngle = value % 360.0;
          }
          break;
        case 0x1003 /* AL_PITCH */:
          if (!Number.isFinite(value) || value <= 0.0) {
            this.AL.currentCtx.err = 40963;
            return;
          }

          if (src.pitch === value) {
            break;
          }

          src.pitch = value;
          this.AL.updateSourceRate(src);
          break;
        case 4100:
          if (
            !Number.isFinite(value[0]) ||
            !Number.isFinite(value[1]) ||
            !Number.isFinite(value[2])
          ) {
            this.AL.currentCtx.err = 40963;
            return;
          }

          src.position[0] = value[0];
          src.position[1] = value[1];
          src.position[2] = value[2];
          this.AL.updateSourceSpace(src);
          break;
        case 4101:
          if (
            !Number.isFinite(value[0]) ||
            !Number.isFinite(value[1]) ||
            !Number.isFinite(value[2])
          ) {
            this.AL.currentCtx.err = 40963;
            return;
          }

          src.direction[0] = value[0];
          src.direction[1] = value[1];
          src.direction[2] = value[2];
          this.AL.updateSourceSpace(src);
          break;
        case 4102:
          if (
            !Number.isFinite(value[0]) ||
            !Number.isFinite(value[1]) ||
            !Number.isFinite(value[2])
          ) {
            this.AL.currentCtx.err = 40963;
            return;
          }

          src.velocity[0] = value[0];
          src.velocity[1] = value[1];
          src.velocity[2] = value[2];
          this.AL.updateSourceSpace(src);
          break;
        case 0x1007 /* AL_LOOPING */:
          if (value === 1) {
            src.looping = true;
            this.AL.updateSourceTime(src);
            if (
              src.type === 0x1028 /* AL_STATIC */ &&
              src.audioQueue.length > 0
            ) {
              let audioSrc = src.audioQueue[0];
              audioSrc.loop = true;
              audioSrc._duration = Number.POSITIVE_INFINITY;
            }
          } else if (value === 0) {
            src.looping = false;
            let currentTime = this.AL.updateSourceTime(src);
            if (
              src.type === 0x1028 /* AL_STATIC */ &&
              src.audioQueue.length > 0
            ) {
              let audioSrc = src.audioQueue[0];
              audioSrc.loop = false;
              audioSrc._duration =
                src.bufQueue[0].audioBuf.duration / src.playbackRate;
              audioSrc._startTime =
                currentTime - src.bufOffset / src.playbackRate;
            }
          } else {
            this.AL.currentCtx.err = 40963;
            return;
          }
          break;
        case 0x1009 /* AL_BUFFER */:
          if (
            src.state === 0x1012 /* AL_PLAYING */ ||
            src.state === 0x1013 /* AL_PAUSED */
          ) {
            this.AL.currentCtx.err = 40964;
            return;
          }

          if (value === 0) {
            for (let i in src.bufQueue) {
              src.bufQueue[i].refCount--;
            }
            src.bufQueue.length = 1;
            src.bufQueue[0] = this.AL.buffers[0];

            src.bufsProcessed = 0;
            src.type = 0x1030 /* AL_UNDETERMINED */;
          } else {
            let buf = this.AL.buffers[value];
            if (!buf) {
              this.AL.currentCtx.err = 40963;
              return;
            }

            for (let i in src.bufQueue) {
              src.bufQueue[i].refCount--;
            }
            src.bufQueue.length = 0;

            buf.refCount++;
            src.bufQueue = [buf];
            src.bufsProcessed = 0;
            src.type = 0x1028 /* AL_STATIC */;
          }

          this.AL.initSourcePanner(src);
          this.AL.scheduleSourceAudio(src);
          break;
        case 4106:
          if (!Number.isFinite(value) || value < 0.0) {
            this.AL.currentCtx.err = 40963;
            return;
          }
          src.gain.gain.value = value;
          break;
        case 0x100d /* AL_MIN_GAIN */:
          if (
            !Number.isFinite(value) ||
            value < 0.0 ||
            value > Math.min(src.maxGain, 1.0)
          ) {
            this.AL.currentCtx.err = 40963;
            return;
          }
          src.minGain = value;
          break;
        case 0x100e /* AL_MAX_GAIN */:
          if (
            !Number.isFinite(value) ||
            value < Math.max(0.0, src.minGain) ||
            value > 1.0
          ) {
            this.AL.currentCtx.err = 40963;
            return;
          }
          src.maxGain = value;
          break;
        case 0x1020 /* AL_REFERENCE_DISTANCE */:
          if (!Number.isFinite(value) || value < 0.0) {
            this.AL.currentCtx.err = 40963;
            return;
          }
          src.refDistance = value;
          if (src.panner) {
            src.panner.refDistance = value;
          }
          break;
        case 0x1021 /* AL_ROLLOFF_FACTOR */:
          if (!Number.isFinite(value) || value < 0.0) {
            this.AL.currentCtx.err = 40963;
            return;
          }
          src.rolloffFactor = value;
          if (src.panner) {
            src.panner.rolloffFactor = value;
          }
          break;
        case 0x1022 /* AL_CONE_OUTER_GAIN */:
          if (!Number.isFinite(value) || value < 0.0 || value > 1.0) {
            this.AL.currentCtx.err = 40963;
            return;
          }
          src.coneOuterGain = value;
          if (src.panner) {
            src.panner.coneOuterGain = value;
          }
          break;
        case 0x1023 /* AL_MAX_DISTANCE */:
          if (!Number.isFinite(value) || value < 0.0) {
            this.AL.currentCtx.err = 40963;
            return;
          }
          src.maxDistance = value;
          if (src.panner) {
            src.panner.maxDistance = value;
          }
          break;
        case 0x1024 /* AL_SEC_OFFSET */:
          if (value < 0.0 || value > this.AL.sourceDuration(src)) {
            this.AL.currentCtx.err = 40963;
            return;
          }

          this.AL.sourceSeek(src, value);
          break;
        case 0x1025 /* AL_SAMPLE_OFFSET */:
          let srcLenOffset = this.AL.sourceDuration(src);
          if (srcLenOffset > 0.0) {
            let frequency;
            for (let bufId in src.bufQueue) {
              if (bufId) {
                frequency = src.bufQueue[bufId].frequency;
                break;
              }
            }
            value /= frequency;
          }
          if (value < 0.0 || value > srcLenOffset) {
            this.AL.currentCtx.err = 40963;
            return;
          }

          this.AL.sourceSeek(src, value);
          break;
        case 0x1026 /* AL_BYTE_OFFSET */:
          let srcLenByte = this.AL.sourceDuration(src);
          if (srcLenByte > 0.0) {
            let bytesPerSec = 0;
            for (let bufId in src.bufQueue) {
              if (bufId) {
                let buf = src.bufQueue[bufId];
                bytesPerSec = buf.frequency * buf.bytesPerSample * buf.channels;
                break;
              }
            }
            value /= bytesPerSec;
          }
          if (value < 0.0 || value > srcLenByte) {
            this.AL.currentCtx.err = 40963;
            return;
          }

          this.AL.sourceSeek(src, value);
          break;
        case 0x1214 /* AL_SOURCE_SPATIALIZE_SOFT */:
          if (value !== 0 && value !== 1 && value !== 2 /* AL_AUTO_SOFT */) {
            this.AL.currentCtx.err = 40963;
            return;
          }

          src.spatialize = value;
          this.AL.initSourcePanner(src);
          break;
        case 0x2009 /* AL_BYTE_LENGTH_SOFT */:
        case 0x200a /* AL_SAMPLE_LENGTH_SOFT */:
        case 0x200b /* AL_SEC_LENGTH_SOFT */:
          this.AL.currentCtx.err = 40964;
          break;
        case 53248:
          switch (value) {
            case 0:
            case 0xd001 /* AL_INVERSE_DISTANCE */:
            case 0xd002 /* AL_INVERSE_DISTANCE_CLAMPED */:
            case 0xd003 /* AL_LINEAR_DISTANCE */:
            case 0xd004 /* AL_LINEAR_DISTANCE_CLAMPED */:
            case 0xd005 /* AL_EXPONENT_DISTANCE */:
            case 0xd006 /* AL_EXPONENT_DISTANCE_CLAMPED */:
              src.distanceModel = value;
              if (this.AL.currentCtx.sourceDistanceModel) {
                this.AL.updateContextGlobal(this.AL.currentCtx);
              }
              break;
            default:
              this.AL.currentCtx.err = 40963;
              return;
          }
          break;
        default:
          this.AL.currentCtx.err = 40962;
          return;
      }
    },
    captures: {},
    sharedCaptureAudioCtx: null,
    requireValidCaptureDevice: (deviceId: number, funcname: any) => {
      if (deviceId === 0) {
        this.AL.alcErr = 40961;
        return null;
      }
      let c = this.AL.captures[deviceId];
      if (!c) {
        this.AL.alcErr = 40961;
        return null;
      }
      let err = c.mediaStreamError;
      if (err) {
        this.AL.alcErr = 40961;
        return null;
      }
      return c;
    },
  };
  private _alBuffer3f(
    bufferId: any,
    param: any,
    value0: any,
    value1: any,
    value2: any
  ) {
    this.AL.setBufferParam('alBuffer3f', bufferId, param, null);
  }

  private _alBuffer3i(
    bufferId: any,
    param: any,
    value0: any,
    value1: any,
    value2: any
  ) {
    this.AL.setBufferParam('alBuffer3i', bufferId, param, null);
  }

  private _alBufferData(
    bufferId: any,
    format: any,
    pData: any,
    size: any,
    freq: any
  ) {
    // if (!this.AL.currentCtx) {
    //   return;
    // }
    // let buf = this.AL.buffers[bufferId];
    // if (!buf) {
    //   this.AL.currentCtx.err = 40963;
    //   return;
    // }
    // if (freq <= 0) {
    //   this.AL.currentCtx.err = 40963;
    //   return;
    // }
    // let audioBuf = null;
    // try {
    //   switch (format) {
    //     case 0x1100 /* AL_FORMAT_MONO8 */:
    //       // if (size > 0) {
    //       //   audioBuf = this.AL.currentCtx.audioCtx.createBuffer(1, size, freq);
    //       //   let channel0 = audioBuf.getChannelData(0);
    //       //   for (let i = 0; i < size; ++i) {
    //       //     channel0[i] = this.HEAPU8[pData++] * 0.0078125 /* 1/128 */ - 1.0;
    //       //   }
    //       // }
    //       // buf.bytesPerSample = 1;
    //       // buf.channels = 1;
    //       // buf.length = size;
    //       break;
    //     case 0x1101 /* AL_FORMAT_MONO16 */:
    //       // if (size > 0) {
    //       //   audioBuf = this.AL.currentCtx.audioCtx.createBuffer(
    //       //     1,
    //       //     size >> 1,
    //       //     freq
    //       //   );
    //       //   let channel0 = audioBuf.getChannelData(0);
    //       //   pData >>= 1;
    //       //   for (let i = 0; i < size >> 1; ++i) {
    //       //     channel0[i] = HEAP16[pData++] * 0.000030517578125 /* 1/32768 */;
    //       //   }
    //       // }
    //       // buf.bytesPerSample = 2;
    //       // buf.channels = 1;
    //       // buf.length = size >> 1;
    //       break;
    //     case 0x1102 /* AL_FORMAT_STEREO8 */:
    //       if (size > 0) {
    //         audioBuf = this.AL.currentCtx.audioCtx.createBuffer(
    //           2,
    //           size >> 1,
    //           freq
    //         );
    //         let channel0 = audioBuf.getChannelData(0);
    //         let channel1 = audioBuf.getChannelData(1);
    //         for (let i = 0; i < size >> 1; ++i) {
    //           channel0[i] = this.HEAPU8[pData++] * 0.0078125 /* 1/128 */ - 1.0;
    //           channel1[i] = this.HEAPU8[pData++] * 0.0078125 /* 1/128 */ - 1.0;
    //         }
    //       }
    //       buf.bytesPerSample = 1;
    //       buf.channels = 2;
    //       buf.length = size >> 1;
    //       break;
    //     case 0x1103 /* AL_FORMAT_STEREO16 */:
    //       if (size > 0) {
    //         audioBuf = this.AL.currentCtx.audioCtx.createBuffer(
    //           2,
    //           size >> 2,
    //           freq
    //         );
    //         let channel0 = audioBuf.getChannelData(0);
    //         let channel1 = audioBuf.getChannelData(1);
    //         pData >>= 1;
    //         for (let i = 0; i < size >> 2; ++i) {
    //           channel0[i] = HEAP16[pData++] * 0.000030517578125 /* 1/32768 */;
    //           channel1[i] = HEAP16[pData++] * 0.000030517578125 /* 1/32768 */;
    //         }
    //       }
    //       buf.bytesPerSample = 2;
    //       buf.channels = 2;
    //       buf.length = size >> 2;
    //       break;
    //     case 0x10010 /* AL_FORMAT_MONO_FLOAT32 */:
    //       if (size > 0) {
    //         audioBuf = this.AL.currentCtx.audioCtx.createBuffer(
    //           1,
    //           size >> 2,
    //           freq
    //         );
    //         let channel0 = audioBuf.getChannelData(0);
    //         pData >>= 2;
    //         for (let i = 0; i < size >> 2; ++i) {
    //           channel0[i] = this.HEAPF32[pData++];
    //         }
    //       }
    //       buf.bytesPerSample = 4;
    //       buf.channels = 1;
    //       buf.length = size >> 2;
    //       break;
    //     case 0x10011 /* AL_FORMAT_STEREO_FLOAT32 */:
    //       if (size > 0) {
    //         audioBuf = this.AL.currentCtx.audioCtx.createBuffer(
    //           2,
    //           size >> 3,
    //           freq
    //         );
    //         let channel0 = audioBuf.getChannelData(0);
    //         let channel1 = audioBuf.getChannelData(1);
    //         pData >>= 2;
    //         for (let i = 0; i < size >> 3; ++i) {
    //           channel0[i] = this.HEAPF32[pData++];
    //           channel1[i] = this.HEAPF32[pData++];
    //         }
    //       }
    //       buf.bytesPerSample = 4;
    //       buf.channels = 2;
    //       buf.length = size >> 3;
    //       break;
    //     default:
    //       this.AL.currentCtx.err = 40963;
    //       return;
    //   }
    //   buf.frequency = freq;
    //   buf.audioBuf = audioBuf;
    // } catch (e: any) {
    //   this.AL.currentCtx.err = 40963;
    //   return;
    // }
  }

  private _alBufferf(bufferId: any, param: any, value: any) {
    this.AL.setBufferParam('alBufferf', bufferId, param, null);
  }

  private _alBufferfv(bufferId: any, param: any, pValues: any) {
    if (!this.AL.currentCtx) {
      return;
    }
    if (!pValues) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    this.AL.setBufferParam('alBufferfv', bufferId, param, null);
  }

  private _alBufferi(bufferId: any, param: any, value: any) {
    this.AL.setBufferParam('alBufferi', bufferId, param, null);
  }

  private _alBufferiv(bufferId: any, param: any, pValues: any) {
    // if (!this.AL.currentCtx) {
    //   return;
    // }
    // if (!pValues) {
    //   this.AL.currentCtx.err = 40963;
    //   return;
    // }
    // switch (param) {
    //   case 0x2015 /* AL_LOOP_POINTS_SOFT */:
    //     this.AL.paramArray[0] = this.HEAP32[pValues >> 2];
    //     this.AL.paramArray[1] = this.HEAP32[(pValues + 4) >> 2];
    //     this.AL.setBufferParam(
    //       'alBufferiv',
    //       bufferId,
    //       param,
    //       this.AL.paramArray
    //     );
    //     break;
    //   default:
    //     this.AL.setBufferParam('alBufferiv', bufferId, param, null);
    //     break;
    // }
  }

  private _alDeleteBuffers(count: number, pBufferIds: number) {
    if (!this.AL.currentCtx) {
      return;
    }

    for (let i = 0; i < count; ++i) {
      let bufId = this.HEAP32[(pBufferIds + i * 4) >> 2];
      /// Deleting the zero buffer is a legal NOP, so ignore it
      if (bufId === 0) {
        continue;
      }

      // Make sure the buffer index is valid.
      if (!this.AL.buffers[bufId]) {
        this.AL.currentCtx.err = 40961;
        return;
      }

      // Make sure the buffer is no longer in use.
      if (this.AL.buffers[bufId].refCount) {
        this.AL.currentCtx.err = 40964;
        return;
      }
    }

    for (let i = 0; i < count; ++i) {
      let bufId = this.HEAP32[(pBufferIds + i * 4) >> 2];
      if (bufId === 0) {
        continue;
      }

      this.AL.deviceRefCounts[this.AL.buffers[bufId].deviceId]--;
      delete this.AL.buffers[bufId];
      this.AL.freeIds.push(bufId);
    }
  }

  private _alSourcei(sourceId: any, param: number, value: number) {
    switch (param) {
      case 0x202 /* AL_SOURCE_RELATIVE */:
      case 0x1001 /* AL_CONE_INNER_ANGLE */:
      case 0x1002 /* AL_CONE_OUTER_ANGLE */:
      case 0x1007 /* AL_LOOPING */:
      case 0x1009 /* AL_BUFFER */:
      case 0x1020 /* AL_REFERENCE_DISTANCE */:
      case 0x1021 /* AL_ROLLOFF_FACTOR */:
      case 0x1023 /* AL_MAX_DISTANCE */:
      case 0x1024 /* AL_SEC_OFFSET */:
      case 0x1025 /* AL_SAMPLE_OFFSET */:
      case 0x1026 /* AL_BYTE_OFFSET */:
      case 0x1214 /* AL_SOURCE_SPATIALIZE_SOFT */:
      case 0x2009 /* AL_BYTE_LENGTH_SOFT */:
      case 0x200a /* AL_SAMPLE_LENGTH_SOFT */:
      case 53248:
        this.AL.setSourceParam('alSourcei', sourceId, param, value);
        break;
      default:
        this.AL.setSourceParam('alSourcei', sourceId, param, null);
        break;
    }
  }
  private _alDeleteSources(count: number, pSourceIds: number) {
    if (!this.AL.currentCtx) {
      return;
    }

    for (let i = 0; i < count; ++i) {
      let srcId = this.HEAP32[(pSourceIds + i * 4) >> 2];
      if (!this.AL.currentCtx.sources[srcId]) {
        this.AL.currentCtx.err = 40961;
        return;
      }
    }

    for (let i = 0; i < count; ++i) {
      let srcId = this.HEAP32[(pSourceIds + i * 4) >> 2];
      this.AL.setSourceState(
        this.AL.currentCtx.sources[srcId],
        0x1014 /* AL_STOPPED */
      );
      this._alSourcei(srcId, 0x1009 /* AL_BUFFER */, 0);
      delete this.AL.currentCtx.sources[srcId];
      this.AL.freeIds.push(srcId);
    }
  }

  private _alDisable(param: any) {
    if (!this.AL.currentCtx) {
      return;
    }
    switch (param) {
      case 'AL_SOURCE_DISTANCE_MODEL':
        this.AL.currentCtx.sourceDistanceModel = false;
        this.AL.updateContextGlobal(this.AL.currentCtx);
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alDistanceModel(model: any) {
    this.AL.setGlobalParam('alDistanceModel', 53248, model);
  }

  private _alDopplerFactor(value: any) {
    this.AL.setGlobalParam('alDopplerFactor', 49152, value);
  }

  private _alDopplerVelocity(value: number) {
    this.warnOnce(
      'alDopplerVelocity() is deprecated, and only kept for compatibility with OpenAL 1.0. Use alSpeedOfSound() instead.'
    );
    if (!this.AL.currentCtx) {
      return;
    }
    if (value <= 0) {
      // Negative or zero values are disallowed
      this.AL.currentCtx.err = 40963;
      return;
    }
  }

  private _alEnable(param: any) {
    if (!this.AL.currentCtx) {
      return;
    }
    switch (param) {
      case 'AL_SOURCE_DISTANCE_MODEL':
        this.AL.currentCtx.sourceDistanceModel = true;
        this.AL.updateContextGlobal(this.AL.currentCtx);
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alGenBuffers(count: number, pBufferIds: number) {
    if (!this.AL.currentCtx) {
      return;
    }

    for (let i = 0; i < count; ++i) {
      let buf = {
        deviceId: this.AL.currentCtx.deviceId,
        id: this.AL.newId(),
        refCount: 0,
        audioBuf: null,
        frequency: 0,
        bytesPerSample: 2,
        channels: 1,
        length: 0,
      };
      this.AL.deviceRefCounts[buf.deviceId]++;
      this.AL.buffers[buf.id] = buf;
      this.HEAP32[(pBufferIds + i * 4) >> 2] = buf.id;
    }
  }

  private _alGenSources(count: number, pSourceIds: number) {
    if (!this.AL.currentCtx) {
      return;
    }
    for (let i = 0; i < count; ++i) {
      let gain = this.AL.currentCtx.audioCtx.createGain();
      gain.connect(this.AL.currentCtx.gain);
      let src = {
        context: this.AL.currentCtx,
        id: this.AL.newId(),
        type: 0x1030 /* AL_UNDETERMINED */,
        state: 0x1011 /* AL_INITIAL */,
        bufQueue: [this.AL.buffers[0]],
        audioQueue: [],
        looping: false,
        pitch: 1.0,
        dopplerShift: 1.0,
        gain: gain,
        minGain: 0.0,
        maxGain: 1.0,
        panner: null,
        bufsProcessed: 0,
        bufStartTime: Number.NEGATIVE_INFINITY,
        bufOffset: 0.0,
        relative: false,
        refDistance: 1.0,
        maxDistance: 3.40282e38 /* FLT_MAX */,
        rolloffFactor: 1.0,
        position: [0.0, 0.0, 0.0],
        velocity: [0.0, 0.0, 0.0],
        direction: [0.0, 0.0, 0.0],
        coneOuterGain: 0.0,
        coneInnerAngle: 360.0,
        coneOuterAngle: 360.0,
        distanceModel: 0xd002 /* AL_INVERSE_DISTANCE_CLAMPED */,
        spatialize: 2 /* AL_AUTO_SOFT */,

        get playbackRate() {
          return this.pitch * this.dopplerShift;
        },
      };
      this.AL.currentCtx.sources[src.id] = src;
      this.HEAP32[(pSourceIds + i * 4) >> 2] = src.id;
    }
  }

  private _alGetBoolean(param: any) {
    let val = this.AL.getGlobalParam('alGetBoolean', param);
    if (val === null) {
      return 0;
    }

    switch (param) {
      case 49152:
      case 49155:
      case 53248:
        return val !== 0 ? 1 : 0;
      default:
        this.AL.currentCtx.err = 40962;
        return 0;
    }
  }

  private _alGetBooleanv(param: any, pValues: number) {
    let val = this.AL.getGlobalParam('alGetBooleanv', param);
    // Silently ignore null destinations, as per the spec for global state functions
    if (val === null || !pValues) {
      return;
    }

    switch (param) {
      case 49152:
      case 49155:
      case 53248:
        this.HEAP8[pValues >> 0] = val;
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alGetBuffer3f(
    bufferId: any,
    param: any,
    pValue0: any,
    pValue1: any,
    pValue2: any
  ) {
    let val = this.AL.getBufferParam('alGetBuffer3f', bufferId, param);
    if (val === null) {
      return;
    }
    if (!pValue0 || !pValue1 || !pValue2) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    this.AL.currentCtx.err = 40962;
  }

  private _alGetBuffer3i(
    bufferId: any,
    param: any,
    pValue0: any,
    pValue1: any,
    pValue2: any
  ) {
    let val = this.AL.getBufferParam('alGetBuffer3i', bufferId, param);
    if (val === null) {
      return;
    }
    if (!pValue0 || !pValue1 || !pValue2) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    this.AL.currentCtx.err = 40962;
  }

  private _alGetBufferf(bufferId: any, param: any, pValue: any) {
    let val = this.AL.getBufferParam('alGetBufferf', bufferId, param);
    if (val === null) {
      return;
    }
    if (!pValue) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    this.AL.currentCtx.err = 40962;
  }

  private _alGetBufferfv(bufferId: any, param: any, pValues: any) {
    let val = this.AL.getBufferParam('alGetBufferfv', bufferId, param);
    if (val === null) {
      return;
    }
    if (!pValues) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    this.AL.currentCtx.err = 40962;
  }

  private _alGetBufferi(bufferId: any, param: any, pValue: number) {
    let val = this.AL.getBufferParam('alGetBufferi', bufferId, param);
    if (val === null) {
      return;
    }
    if (!pValue) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    switch (param) {
      case 0x2001 /* AL_FREQUENCY */:
      case 0x2002 /* AL_BITS */:
      case 0x2003 /* AL_CHANNELS */:
      case 0x2004 /* AL_SIZE */:
        this.HEAP32[pValue >> 2] = val;
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alGetBufferiv(bufferId: any, param: any, pValues: number) {
    let val = this.AL.getBufferParam('alGetBufferiv', bufferId, param);
    if (val === null) {
      return;
    }
    if (!pValues) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    switch (param) {
      case 0x2001 /* AL_FREQUENCY */:
      case 0x2002 /* AL_BITS */:
      case 0x2003 /* AL_CHANNELS */:
      case 0x2004 /* AL_SIZE */:
        this.HEAP32[pValues >> 2] = val;
        break;
      case 0x2015 /* AL_LOOP_POINTS_SOFT */:
        this.HEAP32[pValues >> 2] = val[0];
        this.HEAP32[(pValues + 4) >> 2] = val[1];
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alGetDouble(param: any) {
    let val = this.AL.getGlobalParam('alGetDouble', param);
    if (val === null) {
      return 0.0;
    }

    switch (param) {
      case 49152:
      case 49155:
      case 53248:
        return val;
      default:
        this.AL.currentCtx.err = 40962;
        return 0.0;
    }
  }

  private _alGetDoublev(param: any, pValues: number) {
    let val = this.AL.getGlobalParam('alGetDoublev', param);
    // Silently ignore null destinations, as per the spec for global state functions
    if (val === null || !pValues) {
      return;
    }

    switch (param) {
      case 49152:
      case 49155:
      case 53248:
        this.HEAPF64[pValues >> 3] = val;
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alGetEnumValue(pEnumName: any) {
    if (!this.AL.currentCtx) {
      return 0;
    }

    if (!pEnumName) {
      this.AL.currentCtx.err = 40963;
      return 0;
    }
    let name = this.UTF8ToString(pEnumName);

    switch (name) {
      // Spec doesn't clearly state that alGetEnumValue() is required to
      // support _only_ extension tokens.
      // We should probably follow OpenAL-Soft's example and support all
      // of the names we know.
      // See http://repo.or.cz/openal-soft.git/blob/HEAD:/Alc/ALc.c
      case 'AL_BITS':
        return 0x2002;
      case 'AL_BUFFER':
        return 0x1009;
      case 'AL_BUFFERS_PROCESSED':
        return 0x1016;
      case 'AL_BUFFERS_QUEUED':
        return 0x1015;
      case 'AL_BYTE_OFFSET':
        return 0x1026;
      case 'AL_CHANNELS':
        return 0x2003;
      case 'AL_CONE_INNER_ANGLE':
        return 0x1001;
      case 'AL_CONE_OUTER_ANGLE':
        return 0x1002;
      case 'AL_CONE_OUTER_GAIN':
        return 0x1022;
      case 'AL_DIRECTION':
        return 0x1005;
      case 'AL_DISTANCE_MODEL':
        return 0xd000;
      case 'AL_DOPPLER_FACTOR':
        return 0xc000;
      case 'AL_DOPPLER_VELOCITY':
        return 0xc001;
      case 'AL_EXPONENT_DISTANCE':
        return 0xd005;
      case 'AL_EXPONENT_DISTANCE_CLAMPED':
        return 0xd006;
      case 'AL_EXTENSIONS':
        return 0xb004;
      case 'AL_FORMAT_MONO16':
        return 0x1101;
      case 'AL_FORMAT_MONO8':
        return 0x1100;
      case 'AL_FORMAT_STEREO16':
        return 0x1103;
      case 'AL_FORMAT_STEREO8':
        return 0x1102;
      case 'AL_FREQUENCY':
        return 0x2001;
      case 'AL_GAIN':
        return 0x100a;
      case 'AL_INITIAL':
        return 0x1011;
      case 'AL_INVALID':
        return -1;
      case 'AL_ILLEGAL_ENUM': // fallthrough
      case 'AL_INVALID_ENUM':
        return 0xa002;
      case 'AL_INVALID_NAME':
        return 0xa001;
      case 'AL_ILLEGAL_COMMAND': // fallthrough
      case 'AL_INVALID_OPERATION':
        return 0xa004;
      case 'AL_INVALID_VALUE':
        return 0xa003;
      case 'AL_INVERSE_DISTANCE':
        return 0xd001;
      case 'AL_INVERSE_DISTANCE_CLAMPED':
        return 0xd002;
      case 'AL_LINEAR_DISTANCE':
        return 0xd003;
      case 'AL_LINEAR_DISTANCE_CLAMPED':
        return 0xd004;
      case 'AL_LOOPING':
        return 0x1007;
      case 'AL_MAX_DISTANCE':
        return 0x1023;
      case 'AL_MAX_GAIN':
        return 0x100e;
      case 'AL_MIN_GAIN':
        return 0x100d;
      case 'AL_NONE':
        return 0;
      case 'AL_NO_ERROR':
        return 0;
      case 'AL_ORIENTATION':
        return 0x100f;
      case 'AL_OUT_OF_MEMORY':
        return 0xa005;
      case 'AL_PAUSED':
        return 0x1013;
      case 'AL_PENDING':
        return 0x2011;
      case 'AL_PITCH':
        return 0x1003;
      case 'AL_PLAYING':
        return 0x1012;
      case 'AL_POSITION':
        return 0x1004;
      case 'AL_PROCESSED':
        return 0x2012;
      case 'AL_REFERENCE_DISTANCE':
        return 0x1020;
      case 'AL_RENDERER':
        return 0xb003;
      case 'AL_ROLLOFF_FACTOR':
        return 0x1021;
      case 'AL_SAMPLE_OFFSET':
        return 0x1025;
      case 'AL_SEC_OFFSET':
        return 0x1024;
      case 'AL_SIZE':
        return 0x2004;
      case 'AL_SOURCE_RELATIVE':
        return 0x202;
      case 'AL_SOURCE_STATE':
        return 0x1010;
      case 'AL_SOURCE_TYPE':
        return 0x1027;
      case 'AL_SPEED_OF_SOUND':
        return 0xc003;
      case 'AL_STATIC':
        return 0x1028;
      case 'AL_STOPPED':
        return 0x1014;
      case 'AL_STREAMING':
        return 0x1029;
      case 'AL_UNDETERMINED':
        return 0x1030;
      case 'AL_UNUSED':
        return 0x2010;
      case 'AL_VELOCITY':
        return 0x1006;
      case 'AL_VENDOR':
        return 0xb001;
      case 'AL_VERSION':
        return 0xb002;

      /* Extensions */
      case 'AL_AUTO_SOFT':
        return 0x0002;
      case 'AL_SOURCE_DISTANCE_MODEL':
        return 0x200;
      case 'AL_SOURCE_SPATIALIZE_SOFT':
        return 0x1214;
      case 'AL_LOOP_POINTS_SOFT':
        return 0x2015;
      case 'AL_BYTE_LENGTH_SOFT':
        return 0x2009;
      case 'AL_SAMPLE_LENGTH_SOFT':
        return 0x200a;
      case 'AL_SEC_LENGTH_SOFT':
        return 0x200b;
      case 'AL_FORMAT_MONO_FLOAT32':
        return 0x10010;
      case 'AL_FORMAT_STEREO_FLOAT32':
        return 0x10011;

      default:
        this.AL.currentCtx.err = 40963;
        return 0;
    }
  }

  private _alGetError() {
    if (!this.AL.currentCtx) {
      return 40964;
    }
    // Reset error on get.
    let err = this.AL.currentCtx.err;
    this.AL.currentCtx.err = 0;
    return err;
  }

  private _alGetFloat(param: any) {
    let val = this.AL.getGlobalParam('alGetFloat', param);
    if (val === null) {
      return 0.0;
    }

    switch (param) {
      case 49152:
      case 49155:
      case 53248:
        return val;
      default:
        return 0.0;
    }
  }

  private _alGetFloatv(param: any, pValues: number) {
    let val = this.AL.getGlobalParam('alGetFloatv', param);
    // Silently ignore null destinations, as per the spec for global state functions
    if (val === null || !pValues) {
      return;
    }

    switch (param) {
      case 49152:
      case 49155:
      case 53248:
        this.HEAPF32[pValues >> 2] = val;
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alGetInteger(param: any) {
    let val = this.AL.getGlobalParam('alGetInteger', param);
    if (val === null) {
      return 0;
    }

    switch (param) {
      case 49152:
      case 49155:
      case 53248:
        return val;
      default:
        this.AL.currentCtx.err = 40962;
        return 0;
    }
  }

  private _alGetIntegerv(param: any, pValues: number) {
    let val = this.AL.getGlobalParam('alGetIntegerv', param);
    // Silently ignore null destinations, as per the spec for global state functions
    if (val === null || !pValues) {
      return;
    }

    switch (param) {
      case 49152:
      case 49155:
      case 53248:
        this.HEAP32[pValues >> 2] = val;
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alGetListener3f(
    param: any,
    pValue0: number,
    pValue1: number,
    pValue2: number
  ) {
    let val = this.AL.getListenerParam('alGetListener3f', param);
    if (val === null) {
      return;
    }
    if (!pValue0 || !pValue1 || !pValue2) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    switch (param) {
      case 4100:
      case 4102:
        this.HEAPF32[pValue0 >> 2] = val[0];
        this.HEAPF32[pValue1 >> 2] = val[1];
        this.HEAPF32[pValue2 >> 2] = val[2];
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alGetListener3i(
    param: any,
    pValue0: number,
    pValue1: number,
    pValue2: number
  ) {
    let val = this.AL.getListenerParam('alGetListener3i', param);
    if (val === null) {
      return;
    }
    if (!pValue0 || !pValue1 || !pValue2) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    switch (param) {
      case 4100:
      case 4102:
        this.HEAP32[pValue0 >> 2] = val[0];
        this.HEAP32[pValue1 >> 2] = val[1];
        this.HEAP32[pValue2 >> 2] = val[2];
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alGetListenerf(param: any, pValue: number) {
    let val = this.AL.getListenerParam('alGetListenerf', param);
    if (val === null) {
      return;
    }
    if (!pValue) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    switch (param) {
      case 4106:
        this.HEAPF32[pValue >> 2] = val;
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alGetListenerfv(param: any, pValues: number) {
    let val = this.AL.getListenerParam('alGetListenerfv', param);
    if (val === null) {
      return;
    }
    if (!pValues) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    switch (param) {
      case 4100:
      case 4102:
        this.HEAPF32[pValues >> 2] = val[0];
        this.HEAPF32[(pValues + 4) >> 2] = val[1];
        this.HEAPF32[(pValues + 8) >> 2] = val[2];
        break;
      case 4111:
        this.HEAPF32[pValues >> 2] = val[0];
        this.HEAPF32[(pValues + 4) >> 2] = val[1];
        this.HEAPF32[(pValues + 8) >> 2] = val[2];
        this.HEAPF32[(pValues + 12) >> 2] = val[3];
        this.HEAPF32[(pValues + 16) >> 2] = val[4];
        this.HEAPF32[(pValues + 20) >> 2] = val[5];
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alGetListeneri(param: any, pValue: any) {
    let val = this.AL.getListenerParam('alGetListeneri', param);
    if (val === null) {
      return;
    }
    if (!pValue) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    this.AL.currentCtx.err = 40962;
  }

  private _alGetListeneriv(param: any, pValues: number) {
    let val = this.AL.getListenerParam('alGetListeneriv', param);
    if (val === null) {
      return;
    }
    if (!pValues) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    switch (param) {
      case 4100:
      case 4102:
        this.HEAP32[pValues >> 2] = val[0];
        this.HEAP32[(pValues + 4) >> 2] = val[1];
        this.HEAP32[(pValues + 8) >> 2] = val[2];
        break;
      case 4111:
        this.HEAP32[pValues >> 2] = val[0];
        this.HEAP32[(pValues + 4) >> 2] = val[1];
        this.HEAP32[(pValues + 8) >> 2] = val[2];
        this.HEAP32[(pValues + 12) >> 2] = val[3];
        this.HEAP32[(pValues + 16) >> 2] = val[4];
        this.HEAP32[(pValues + 20) >> 2] = val[5];
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alGetSource3f(
    sourceId: any,
    param: any,
    pValue0: number,
    pValue1: number,
    pValue2: number
  ) {
    let val = this.AL.getSourceParam('alGetSource3f', sourceId, param);
    if (val === null) {
      return;
    }
    if (!pValue0 || !pValue1 || !pValue2) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    switch (param) {
      case 4100:
      case 4101:
      case 4102:
        this.HEAPF32[pValue0 >> 2] = val[0];
        this.HEAPF32[pValue1 >> 2] = val[1];
        this.HEAPF32[pValue2 >> 2] = val[2];
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alGetSource3i(
    sourceId: any,
    param: any,
    pValue0: number,
    pValue1: number,
    pValue2: number
  ) {
    let val = this.AL.getSourceParam('alGetSource3i', sourceId, param);
    if (val === null) {
      return;
    }
    if (!pValue0 || !pValue1 || !pValue2) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    switch (param) {
      case 4100:
      case 4101:
      case 4102:
        this.HEAP32[pValue0 >> 2] = val[0];
        this.HEAP32[pValue1 >> 2] = val[1];
        this.HEAP32[pValue2 >> 2] = val[2];
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alGetSourcef(sourceId: any, param: any, pValue: number) {
    let val = this.AL.getSourceParam('alGetSourcef', sourceId, param);
    if (val === null) {
      return;
    }
    if (!pValue) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    switch (param) {
      case 0x1001 /* AL_CONE_INNER_ANGLE */:
      case 0x1002 /* AL_CONE_OUTER_ANGLE */:
      case 0x1003 /* AL_PITCH */:
      case 4106:
      case 0x100d /* AL_MIN_GAIN */:
      case 0x100e /* AL_MAX_GAIN */:
      case 0x1020 /* AL_REFERENCE_DISTANCE */:
      case 0x1021 /* AL_ROLLOFF_FACTOR */:
      case 0x1022 /* AL_CONE_OUTER_GAIN */:
      case 0x1023 /* AL_MAX_DISTANCE */:
      case 0x1024 /* AL_SEC_OFFSET */:
      case 0x1025 /* AL_SAMPLE_OFFSET */:
      case 0x1026 /* AL_BYTE_OFFSET */:
      case 0x200b /* AL_SEC_LENGTH_SOFT */:
        this.HEAPF32[pValue >> 2] = val;
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alGetSourcefv(sourceId: any, param: any, pValues: number) {
    let val = this.AL.getSourceParam('alGetSourcefv', sourceId, param);
    if (val === null) {
      return;
    }
    if (!pValues) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    switch (param) {
      case 0x1001 /* AL_CONE_INNER_ANGLE */:
      case 0x1002 /* AL_CONE_OUTER_ANGLE */:
      case 0x1003 /* AL_PITCH */:
      case 4106:
      case 0x100d /* AL_MIN_GAIN */:
      case 0x100e /* AL_MAX_GAIN */:
      case 0x1020 /* AL_REFERENCE_DISTANCE */:
      case 0x1021 /* AL_ROLLOFF_FACTOR */:
      case 0x1022 /* AL_CONE_OUTER_GAIN */:
      case 0x1023 /* AL_MAX_DISTANCE */:
      case 0x1024 /* AL_SEC_OFFSET */:
      case 0x1025 /* AL_SAMPLE_OFFSET */:
      case 0x1026 /* AL_BYTE_OFFSET */:
      case 0x200b /* AL_SEC_LENGTH_SOFT */:
        this.HEAPF32[pValues >> 2] = val[0];
        break;
      case 4100:
      case 4101:
      case 4102:
        this.HEAPF32[pValues >> 2] = val[0];
        this.HEAPF32[(pValues + 4) >> 2] = val[1];
        this.HEAPF32[(pValues + 8) >> 2] = val[2];
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alGetSourcei(sourceId: any, param: any, pValue: number) {
    let val = this.AL.getSourceParam('alGetSourcei', sourceId, param);
    if (val === null) {
      return;
    }
    if (!pValue) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    switch (param) {
      case 0x202 /* AL_SOURCE_RELATIVE */:
      case 0x1001 /* AL_CONE_INNER_ANGLE */:
      case 0x1002 /* AL_CONE_OUTER_ANGLE */:
      case 0x1007 /* AL_LOOPING */:
      case 0x1009 /* AL_BUFFER */:
      case 0x1010 /* AL_SOURCE_STATE */:
      case 0x1015 /* AL_BUFFERS_QUEUED */:
      case 0x1016 /* AL_BUFFERS_PROCESSED */:
      case 0x1020 /* AL_REFERENCE_DISTANCE */:
      case 0x1021 /* AL_ROLLOFF_FACTOR */:
      case 0x1023 /* AL_MAX_DISTANCE */:
      case 0x1024 /* AL_SEC_OFFSET */:
      case 0x1025 /* AL_SAMPLE_OFFSET */:
      case 0x1026 /* AL_BYTE_OFFSET */:
      case 0x1027 /* AL_SOURCE_TYPE */:
      case 0x1214 /* AL_SOURCE_SPATIALIZE_SOFT */:
      case 0x2009 /* AL_BYTE_LENGTH_SOFT */:
      case 0x200a /* AL_SAMPLE_LENGTH_SOFT */:
      case 53248:
        this.HEAP32[pValue >> 2] = val;
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alGetSourceiv(sourceId: any, param: any, pValues: number) {
    let val = this.AL.getSourceParam('alGetSourceiv', sourceId, param);
    if (val === null) {
      return;
    }
    if (!pValues) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    switch (param) {
      case 0x202 /* AL_SOURCE_RELATIVE */:
      case 0x1001 /* AL_CONE_INNER_ANGLE */:
      case 0x1002 /* AL_CONE_OUTER_ANGLE */:
      case 0x1007 /* AL_LOOPING */:
      case 0x1009 /* AL_BUFFER */:
      case 0x1010 /* AL_SOURCE_STATE */:
      case 0x1015 /* AL_BUFFERS_QUEUED */:
      case 0x1016 /* AL_BUFFERS_PROCESSED */:
      case 0x1020 /* AL_REFERENCE_DISTANCE */:
      case 0x1021 /* AL_ROLLOFF_FACTOR */:
      case 0x1023 /* AL_MAX_DISTANCE */:
      case 0x1024 /* AL_SEC_OFFSET */:
      case 0x1025 /* AL_SAMPLE_OFFSET */:
      case 0x1026 /* AL_BYTE_OFFSET */:
      case 0x1027 /* AL_SOURCE_TYPE */:
      case 0x1214 /* AL_SOURCE_SPATIALIZE_SOFT */:
      case 0x2009 /* AL_BYTE_LENGTH_SOFT */:
      case 0x200a /* AL_SAMPLE_LENGTH_SOFT */:
      case 53248:
        this.HEAP32[pValues >> 2] = val;
        break;
      case 4100:
      case 4101:
      case 4102:
        this.HEAP32[pValues >> 2] = val[0];
        this.HEAP32[(pValues + 4) >> 2] = val[1];
        this.HEAP32[(pValues + 8) >> 2] = val[2];
        break;
      default:
        this.AL.currentCtx.err = 40962;
        return;
    }
  }

  private _alGetString(param: string | number) {
    if (this.AL.stringCache[param]) {
      return this.AL.stringCache[param];
    }

    let ret;
    switch (param) {
      case 0:
        ret = 'No Error';
        break;
      case 40961:
        ret = 'Invalid Name';
        break;
      case 40962:
        ret = 'Invalid Enum';
        break;
      case 40963:
        ret = 'Invalid Value';
        break;
      case 40964:
        ret = 'Invalid Operation';
        break;
      case 0xa005 /* AL_OUT_OF_MEMORY */:
        ret = 'Out of Memory';
        break;
      case 0xb001 /* AL_VENDOR */:
        ret = 'Emscripten';
        break;
      case 0xb002 /* AL_VERSION */:
        ret = '1.1';
        break;
      case 0xb003 /* AL_RENDERER */:
        ret = 'WebAudio';
        break;
      case 0xb004 /* AL_EXTENSIONS */:
        ret = '';
        for (let ext in this.AL.AL_EXTENSIONS) {
          ret = ret.concat(ext);
          ret = ret.concat(' ');
        }
        ret = ret.trim();
        break;
      default:
        if (this.AL.currentCtx) {
          this.AL.currentCtx.err = 40962;
        } else {
        }
        return 0;
    }

    ret = '';
    this.AL.stringCache[param] = ret;
    return ret;
  }

  private _alIsBuffer(bufferId: number) {
    if (!this.AL.currentCtx) {
      return false;
    }
    if (bufferId > this.AL.buffers.length) {
      return false;
    }

    if (!this.AL.buffers[bufferId]) {
      return false;
    }
    return true;
  }

  private _alIsEnabled(param: any) {
    if (!this.AL.currentCtx) {
      return 0;
    }
    switch (param) {
      case 'AL_SOURCE_DISTANCE_MODEL':
        return this.AL.currentCtx.sourceDistanceModel ? 0 : 1;
      default:
        this.AL.currentCtx.err = 40962;
        return 0;
    }
  }

  private _alIsExtensionPresent(pExtName: any) {
    let name = this.UTF8ToString(pExtName);

    return this.AL.AL_EXTENSIONS[name] ? 1 : 0;
  }

  private _alIsSource(sourceId: string | number) {
    if (!this.AL.currentCtx) {
      return false;
    }

    if (!this.AL.currentCtx.sources[sourceId]) {
      return false;
    }
    return true;
  }

  private _alListener3f(param: any, value0: any, value1: any, value2: any) {
    switch (param) {
      case 4100:
      case 4102:
        this.AL.paramArray[0] = value0;
        this.AL.paramArray[1] = value1;
        this.AL.paramArray[2] = value2;
        this.AL.setListenerParam('alListener3f', param, this.AL.paramArray);
        break;
      default:
        this.AL.setListenerParam('alListener3f', param, null);
        break;
    }
  }

  private _alListener3i(param: any, value0: any, value1: any, value2: any) {
    switch (param) {
      case 4100:
      case 4102:
        this.AL.paramArray[0] = value0;
        this.AL.paramArray[1] = value1;
        this.AL.paramArray[2] = value2;
        this.AL.setListenerParam('alListener3i', param, this.AL.paramArray);
        break;
      default:
        this.AL.setListenerParam('alListener3i', param, null);
        break;
    }
  }

  private _alListenerf(param: any, value: any) {
    switch (param) {
      case 4106:
        this.AL.setListenerParam('alListenerf', param, value);
        break;
      default:
        this.AL.setListenerParam('alListenerf', param, null);
        break;
    }
  }

  private _alListenerfv(param: any, pValues: number) {
    if (!this.AL.currentCtx) {
      return;
    }
    if (!pValues) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    switch (param) {
      case 4100:
      case 4102:
        this.AL.paramArray[0] = this.HEAPF32[pValues >> 2];
        this.AL.paramArray[1] = this.HEAPF32[(pValues + 4) >> 2];
        this.AL.paramArray[2] = this.HEAPF32[(pValues + 8) >> 2];
        this.AL.setListenerParam('alListenerfv', param, this.AL.paramArray);
        break;
      case 4111:
        this.AL.paramArray[0] = this.HEAPF32[pValues >> 2];
        this.AL.paramArray[1] = this.HEAPF32[(pValues + 4) >> 2];
        this.AL.paramArray[2] = this.HEAPF32[(pValues + 8) >> 2];
        this.AL.paramArray[3] = this.HEAPF32[(pValues + 12) >> 2];
        this.AL.paramArray[4] = this.HEAPF32[(pValues + 16) >> 2];
        this.AL.paramArray[5] = this.HEAPF32[(pValues + 20) >> 2];
        this.AL.setListenerParam('alListenerfv', param, this.AL.paramArray);
        break;
      default:
        this.AL.setListenerParam('alListenerfv', param, null);
        break;
    }
  }

  private _alListeneri(param: any, value: any) {
    this.AL.setListenerParam('alListeneri', param, null);
  }

  private _alListeneriv(param: any, pValues: number) {
    if (!this.AL.currentCtx) {
      return;
    }
    if (!pValues) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    switch (param) {
      case 4100:
      case 4102:
        this.AL.paramArray[0] = this.HEAP32[pValues >> 2];
        this.AL.paramArray[1] = this.HEAP32[(pValues + 4) >> 2];
        this.AL.paramArray[2] = this.HEAP32[(pValues + 8) >> 2];
        this.AL.setListenerParam('alListeneriv', param, this.AL.paramArray);
        break;
      case 4111:
        this.AL.paramArray[0] = this.HEAP32[pValues >> 2];
        this.AL.paramArray[1] = this.HEAP32[(pValues + 4) >> 2];
        this.AL.paramArray[2] = this.HEAP32[(pValues + 8) >> 2];
        this.AL.paramArray[3] = this.HEAP32[(pValues + 12) >> 2];
        this.AL.paramArray[4] = this.HEAP32[(pValues + 16) >> 2];
        this.AL.paramArray[5] = this.HEAP32[(pValues + 20) >> 2];
        this.AL.setListenerParam('alListeneriv', param, this.AL.paramArray);
        break;
      default:
        this.AL.setListenerParam('alListeneriv', param, null);
        break;
    }
  }

  private _alSource3f(
    sourceId: any,
    param: any,
    value0: any,
    value1: any,
    value2: any
  ) {
    switch (param) {
      case 4100:
      case 4101:
      case 4102:
        this.AL.paramArray[0] = value0;
        this.AL.paramArray[1] = value1;
        this.AL.paramArray[2] = value2;
        this.AL.setSourceParam(
          'alSource3f',
          sourceId,
          param,
          this.AL.paramArray
        );
        break;
      default:
        this.AL.setSourceParam('alSource3f', sourceId, param, null);
        break;
    }
  }

  private _alSource3i(
    sourceId: any,
    param: any,
    value0: any,
    value1: any,
    value2: any
  ) {
    switch (param) {
      case 4100:
      case 4101:
      case 4102:
        this.AL.paramArray[0] = value0;
        this.AL.paramArray[1] = value1;
        this.AL.paramArray[2] = value2;
        this.AL.setSourceParam(
          'alSource3i',
          sourceId,
          param,
          this.AL.paramArray
        );
        break;
      default:
        this.AL.setSourceParam('alSource3i', sourceId, param, null);
        break;
    }
  }

  private _alSourcePause(sourceId: string | number) {
    if (!this.AL.currentCtx) {
      return;
    }
    let src = this.AL.currentCtx.sources[sourceId];
    if (!src) {
      this.AL.currentCtx.err = 40961;
      return;
    }
    this.AL.setSourceState(src, 0x1013 /* AL_PAUSED */);
  }

  private _alSourcePausev(count: number, pSourceIds: number) {
    if (!this.AL.currentCtx) {
      return;
    }
    if (!pSourceIds) {
      this.AL.currentCtx.err = 40963;
    }
    for (let i = 0; i < count; ++i) {
      if (!this.AL.currentCtx.sources[this.HEAP32[(pSourceIds + i * 4) >> 2]]) {
        this.AL.currentCtx.err = 40961;
        return;
      }
    }

    for (let i = 0; i < count; ++i) {
      let srcId = this.HEAP32[(pSourceIds + i * 4) >> 2];
      this.AL.setSourceState(
        this.AL.currentCtx.sources[srcId],
        0x1013 /* AL_PAUSED */
      );
    }
  }

  private _alSourcePlay(sourceId: string | number) {
    if (!this.AL.currentCtx) {
      return;
    }
    let src = this.AL.currentCtx.sources[sourceId];
    if (!src) {
      this.AL.currentCtx.err = 40961;
      return;
    }
    this.AL.setSourceState(src, 0x1012 /* AL_PLAYING */);
  }

  private _alSourcePlayv(count: number, pSourceIds: number) {
    if (!this.AL.currentCtx) {
      return;
    }
    if (!pSourceIds) {
      this.AL.currentCtx.err = 40963;
    }
    for (let i = 0; i < count; ++i) {
      if (!this.AL.currentCtx.sources[this.HEAP32[(pSourceIds + i * 4) >> 2]]) {
        this.AL.currentCtx.err = 40961;
        return;
      }
    }

    for (let i = 0; i < count; ++i) {
      let srcId = this.HEAP32[(pSourceIds + i * 4) >> 2];
      this.AL.setSourceState(
        this.AL.currentCtx.sources[srcId],
        0x1012 /* AL_PLAYING */
      );
    }
  }

  private _alSourceQueueBuffers(
    sourceId: string | number,
    count: number,
    pBufferIds: number
  ) {
    if (!this.AL.currentCtx) {
      return;
    }
    let src = this.AL.currentCtx.sources[sourceId];
    if (!src) {
      this.AL.currentCtx.err = 40961;
      return;
    }
    if (src.type === 0x1028 /* AL_STATIC */) {
      this.AL.currentCtx.err = 40964;
      return;
    }

    if (count === 0) {
      return;
    }

    // Find the first non-zero buffer in the queue to determine the proper format
    let templateBuf = this.AL.buffers[0];
    for (let i = 0; i < src.bufQueue.length; i++) {
      if (src.bufQueue[i].id !== 0) {
        templateBuf = src.bufQueue[i];
        break;
      }
    }

    for (let i = 0; i < count; ++i) {
      let bufId = this.HEAP32[(pBufferIds + i * 4) >> 2];
      let buf = this.AL.buffers[bufId];
      if (!buf) {
        this.AL.currentCtx.err = 40961;
        return;
      }

      // Check that the added buffer has the correct format. If the template is the zero buffer, any format is valid.
      if (
        templateBuf.id !== 0 &&
        (buf.frequency !== templateBuf.frequency ||
          buf.bytesPerSample !== templateBuf.bytesPerSample ||
          buf.channels !== templateBuf.channels)
      ) {
        this.AL.currentCtx.err = 40964;
      }
    }

    // If the only buffer in the queue is the zero buffer, clear the queue before we add anything.
    if (src.bufQueue.length === 1 && src.bufQueue[0].id === 0) {
      src.bufQueue.length = 0;
    }

    src.type = 0x1029 /* AL_STREAMING */;
    for (let i = 0; i < count; ++i) {
      let bufId = this.HEAP32[(pBufferIds + i * 4) >> 2];
      let buf = this.AL.buffers[bufId];
      buf.refCount++;
      src.bufQueue.push(buf);
    }

    // if the source is looping, cancel the schedule so we can reschedule the loop order
    if (src.looping) {
      this.AL.cancelPendingSourceAudio(src);
    }

    this.AL.initSourcePanner(src);
    this.AL.scheduleSourceAudio(src);
  }

  private _alSourceRewind(sourceId: string | number) {
    if (!this.AL.currentCtx) {
      return;
    }
    let src = this.AL.currentCtx.sources[sourceId];
    if (!src) {
      this.AL.currentCtx.err = 40961;
      return;
    }
    // Stop the source first to clear the source queue
    this.AL.setSourceState(src, 0x1014 /* AL_STOPPED */);
    // Now set the state of AL_INITIAL according to the specification
    this.AL.setSourceState(src, 0x1011 /* AL_INITIAL */);
  }

  private _alSourceRewindv(count: number, pSourceIds: number) {
    if (!this.AL.currentCtx) {
      return;
    }
    if (!pSourceIds) {
      this.AL.currentCtx.err = 40963;
    }
    for (let i = 0; i < count; ++i) {
      if (!this.AL.currentCtx.sources[this.HEAP32[(pSourceIds + i * 4) >> 2]]) {
        this.AL.currentCtx.err = 40961;
        return;
      }
    }

    for (let i = 0; i < count; ++i) {
      let srcId = this.HEAP32[(pSourceIds + i * 4) >> 2];
      this.AL.setSourceState(
        this.AL.currentCtx.sources[srcId],
        0x1011 /* AL_INITIAL */
      );
    }
  }

  private _alSourceStop(sourceId: string | number) {
    if (!this.AL.currentCtx) {
      return;
    }
    let src = this.AL.currentCtx.sources[sourceId];
    if (!src) {
      this.AL.currentCtx.err = 40961;
      return;
    }
    this.AL.setSourceState(src, 0x1014 /* AL_STOPPED */);
  }

  private _alSourceStopv(count: number, pSourceIds: number) {
    if (!this.AL.currentCtx) {
      return;
    }
    if (!pSourceIds) {
      this.AL.currentCtx.err = 40963;
    }
    for (let i = 0; i < count; ++i) {
      if (!this.AL.currentCtx.sources[this.HEAP32[(pSourceIds + i * 4) >> 2]]) {
        this.AL.currentCtx.err = 40961;
        return;
      }
    }

    for (let i = 0; i < count; ++i) {
      let srcId = this.HEAP32[(pSourceIds + i * 4) >> 2];
      this.AL.setSourceState(
        this.AL.currentCtx.sources[srcId],
        0x1014 /* AL_STOPPED */
      );
    }
  }

  private _alSourceUnqueueBuffers(
    sourceId: string | number,
    count: number,
    pBufferIds: number
  ) {
    if (!this.AL.currentCtx) {
      return;
    }
    let src = this.AL.currentCtx.sources[sourceId];
    if (!src) {
      this.AL.currentCtx.err = 40961;
      return;
    }
    if (
      count >
      (src.bufQueue.length === 1 && src.bufQueue[0].id === 0
        ? 0
        : src.bufsProcessed)
    ) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    if (count === 0) {
      return;
    }

    for (let i = 0; i < count; i++) {
      let buf = src.bufQueue.shift();
      buf.refCount--;
      // Write the buffers index out to the return list.
      this.HEAP32[(pBufferIds + i * 4) >> 2] = buf.id;
      src.bufsProcessed--;
    }

    /// If the queue is empty, put the zero buffer back in
    if (src.bufQueue.length === 0) {
      src.bufQueue.push(this.AL.buffers[0]);
    }

    this.AL.initSourcePanner(src);
    this.AL.scheduleSourceAudio(src);
  }

  private _alSourcef(sourceId: any, param: any, value: any) {
    switch (param) {
      case 0x1001 /* AL_CONE_INNER_ANGLE */:
      case 0x1002 /* AL_CONE_OUTER_ANGLE */:
      case 0x1003 /* AL_PITCH */:
      case 4106:
      case 0x100d /* AL_MIN_GAIN */:
      case 0x100e /* AL_MAX_GAIN */:
      case 0x1020 /* AL_REFERENCE_DISTANCE */:
      case 0x1021 /* AL_ROLLOFF_FACTOR */:
      case 0x1022 /* AL_CONE_OUTER_GAIN */:
      case 0x1023 /* AL_MAX_DISTANCE */:
      case 0x1024 /* AL_SEC_OFFSET */:
      case 0x1025 /* AL_SAMPLE_OFFSET */:
      case 0x1026 /* AL_BYTE_OFFSET */:
      case 0x200b /* AL_SEC_LENGTH_SOFT */:
        this.AL.setSourceParam('alSourcef', sourceId, param, value);
        break;
      default:
        this.AL.setSourceParam('alSourcef', sourceId, param, null);
        break;
    }
  }

  private _alSourcefv(sourceId: any, param: any, pValues: number) {
    if (!this.AL.currentCtx) {
      return;
    }
    if (!pValues) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    switch (param) {
      case 0x1001 /* AL_CONE_INNER_ANGLE */:
      case 0x1002 /* AL_CONE_OUTER_ANGLE */:
      case 0x1003 /* AL_PITCH */:
      case 4106:
      case 0x100d /* AL_MIN_GAIN */:
      case 0x100e /* AL_MAX_GAIN */:
      case 0x1020 /* AL_REFERENCE_DISTANCE */:
      case 0x1021 /* AL_ROLLOFF_FACTOR */:
      case 0x1022 /* AL_CONE_OUTER_GAIN */:
      case 0x1023 /* AL_MAX_DISTANCE */:
      case 0x1024 /* AL_SEC_OFFSET */:
      case 0x1025 /* AL_SAMPLE_OFFSET */:
      case 0x1026 /* AL_BYTE_OFFSET */:
      case 0x200b /* AL_SEC_LENGTH_SOFT */:
        let val = this.HEAPF32[pValues >> 2];
        this.AL.setSourceParam('alSourcefv', sourceId, param, val);
        break;
      case 4100:
      case 4101:
      case 4102:
        this.AL.paramArray[0] = this.HEAPF32[pValues >> 2];
        this.AL.paramArray[1] = this.HEAPF32[(pValues + 4) >> 2];
        this.AL.paramArray[2] = this.HEAPF32[(pValues + 8) >> 2];
        this.AL.setSourceParam(
          'alSourcefv',
          sourceId,
          param,
          this.AL.paramArray
        );
        break;
      default:
        this.AL.setSourceParam('alSourcefv', sourceId, param, null);
        break;
    }
  }

  private _alSourceiv(sourceId: any, param: any, pValues: number) {
    if (!this.AL.currentCtx) {
      return;
    }
    if (!pValues) {
      this.AL.currentCtx.err = 40963;
      return;
    }

    switch (param) {
      case 0x202 /* AL_SOURCE_RELATIVE */:
      case 0x1001 /* AL_CONE_INNER_ANGLE */:
      case 0x1002 /* AL_CONE_OUTER_ANGLE */:
      case 0x1007 /* AL_LOOPING */:
      case 0x1009 /* AL_BUFFER */:
      case 0x1020 /* AL_REFERENCE_DISTANCE */:
      case 0x1021 /* AL_ROLLOFF_FACTOR */:
      case 0x1023 /* AL_MAX_DISTANCE */:
      case 0x1024 /* AL_SEC_OFFSET */:
      case 0x1025 /* AL_SAMPLE_OFFSET */:
      case 0x1026 /* AL_BYTE_OFFSET */:
      case 0x1214 /* AL_SOURCE_SPATIALIZE_SOFT */:
      case 0x2009 /* AL_BYTE_LENGTH_SOFT */:
      case 0x200a /* AL_SAMPLE_LENGTH_SOFT */:
      case 53248:
        let val = this.HEAP32[pValues >> 2];
        this.AL.setSourceParam('alSourceiv', sourceId, param, val);
        break;
      case 4100:
      case 4101:
      case 4102:
        this.AL.paramArray[0] = this.HEAP32[pValues >> 2];
        this.AL.paramArray[1] = this.HEAP32[(pValues + 4) >> 2];
        this.AL.paramArray[2] = this.HEAP32[(pValues + 8) >> 2];
        this.AL.setSourceParam(
          'alSourceiv',
          sourceId,
          param,
          this.AL.paramArray
        );
        break;
      default:
        this.AL.setSourceParam('alSourceiv', sourceId, param, null);
        break;
    }
  }

  private _alSpeedOfSound(value: any) {
    this.AL.setGlobalParam('alSpeedOfSound', 49155, value);
  }

  private _alcCaptureCloseDevice(deviceId: string | number) {
    let c = this.AL.requireValidCaptureDevice(
      deviceId,
      'alcCaptureCloseDevice'
    );
    if (!c) return false;

    delete this.AL.captures[deviceId];
    this.AL.freeIds.push(deviceId);

    // This clean-up might be unnecessary (paranoid) ?

    // May happen if user hasn't decided to grant or deny input
    if (c.mediaStreamSourceNode) c.mediaStreamSourceNode.disconnect();
    if (c.mergerNode) c.mergerNode.disconnect();
    if (c.splitterNode) c.splitterNode.disconnect();
    // May happen if user hasn't decided to grant or deny input
    if (c.scriptProcessorNode) c.scriptProcessorNode.disconnect();
    if (c.mediaStream) {
      // Disabling the microphone of the browser.
      // Without this operation, the red dot on the browser tab page will remain.
      c.mediaStream.getTracks().forEach((track: { stop: () => void }) => {
        track.stop();
      });
    }

    delete c.buffers;

    c.capturedFrameCount = 0;
    c.isCapturing = false;

    return true;
  }

  private listenOnce(
    object: {
      addEventListener: (arg0: any, arg1: any, arg2: { once: boolean }) => void;
    },
    event: string,
    func: () => void
  ) {
    object.addEventListener(event, func, { once: true });
  }
  /** @param {Object=} elements */
  private autoResumeAudioContext(
    ctx: { state: string; resume: () => void },
    elements: any[]
  ) {
    if (!elements) {
      elements = [document, document.getElementById('canvas')];
    }
    ['keydown', 'mousedown', 'touchstart'].forEach((event) => {
      elements.forEach((element: any) => {
        if (element) {
          this.listenOnce(element, event, () => {
            if (ctx.state === 'suspended') ctx.resume();
          });
        }
      });
    });
  }
  private _alcCaptureOpenDevice(
    pDeviceName: any,
    requestedSampleRate: any,
    format: any,
    bufferFrameCapacity: any
  ) {
    // let resolvedDeviceName = this.AL.CAPTURE_DEVICE_NAME;
    // // NULL is a valid device name here (resolves to default);
    // if (pDeviceName !== 0) {
    //   resolvedDeviceName = this.UTF8ToString(pDeviceName);
    //   if (resolvedDeviceName !== this.AL.CAPTURE_DEVICE_NAME) {
    //     // ALC_OUT_OF_MEMORY
    //     // From the programmer's guide, ALC_OUT_OF_MEMORY's meaning is
    //     // overloaded here, to mean:
    //     // 'The specified device is invalid, or can not capture audio.'
    //     // This may be misleading to API users, but well...
    //     this.AL.alcErr = 0xa005 /* ALC_OUT_OF_MEMORY */;
    //     return 0;
    //   }
    // }
    // // Otherwise it's probably okay (though useless) for bufferFrameCapacity to be zero.
    // if (bufferFrameCapacity < 0) {
    //   // ALCsizei is signed int
    //   this.AL.alcErr = 40964;
    //   return 0;
    // }
    // navigator.getUserMedia =
    //   navigator.getUserMedia ||
    //   navigator.webkitGetUserMedia ||
    //   navigator.mozGetUserMedia ||
    //   navigator.msGetUserMedia;
    // let has_getUserMedia =
    //   navigator.getUserMedia ||
    //   (navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
    // if (!has_getUserMedia) {
    //   // See previously mentioned rationale for ALC_OUT_OF_MEMORY
    //   this.AL.alcErr = 0xa005 /* ALC_OUT_OF_MEMORY */;
    //   return 0;
    // }
    // let AudioContext = window.AudioContext || window.webkitAudioContext;
    // if (!this.AL.sharedCaptureAudioCtx) {
    //   try {
    //     this.AL.sharedCaptureAudioCtx = new AudioContext();
    //   } catch (e: any) {
    //     // See previously mentioned rationale for ALC_OUT_OF_MEMORY
    //     this.AL.alcErr = 0xa005 /* ALC_OUT_OF_MEMORY */;
    //     return 0;
    //   }
    // }
    // autoResumeAudioContext(this.AL.sharedCaptureAudioCtx);
    // let outputChannelCount;
    // switch (format) {
    //   case 0x10010: /* AL_FORMAT_MONO_FLOAT32 */
    //   case 0x1101: /* AL_FORMAT_MONO16 */
    //   case 0x1100 /* AL_FORMAT_MONO8 */:
    //     outputChannelCount = 1;
    //     break;
    //   case 0x10011: /* AL_FORMAT_STEREO_FLOAT32 */
    //   case 0x1103: /* AL_FORMAT_STEREO16 */
    //   case 0x1102 /* AL_FORMAT_STEREO8 */:
    //     outputChannelCount = 2;
    //     break;
    //   default:
    //     this.AL.alcErr = 40964;
    //     return 0;
    // }
    // const newF32Array = (cap) => {
    //   return new Float32Array(cap);
    // };
    // const newI16Array = (cap) => {
    //   return new Int16Array(cap);
    // };
    // const newU8Array = (cap) => {
    //   return new Uint8Array(cap);
    // };
    // let requestedSampleType;
    // let newSampleArray;
    // switch (format) {
    //   case 0x10010: /* AL_FORMAT_MONO_FLOAT32 */
    //   case 0x10011 /* AL_FORMAT_STEREO_FLOAT32 */:
    //     requestedSampleType = 'f32';
    //     newSampleArray = newF32Array;
    //     break;
    //   case 0x1101: /* AL_FORMAT_MONO16 */
    //   case 0x1103 /* AL_FORMAT_STEREO16 */:
    //     requestedSampleType = 'i16';
    //     newSampleArray = newI16Array;
    //     break;
    //   case 0x1100: /* AL_FORMAT_MONO8 */
    //   case 0x1102 /* AL_FORMAT_STEREO8 */:
    //     requestedSampleType = 'u8';
    //     newSampleArray = newU8Array;
    //     break;
    // }
    // let buffers = [];
    // try {
    //   for (let chan = 0; chan < outputChannelCount; ++chan) {
    //     buffers[chan] = newSampleArray(bufferFrameCapacity);
    //   }
    // } catch (e: any) {
    //   this.AL.alcErr = 0xa005 /* ALC_OUT_OF_MEMORY */;
    //   return 0;
    // }
    // // What we'll place into the `this.AL.captures` array in the end,
    // // declared here for closures to access it
    // let newCapture = {
    //   audioCtx: this.AL.sharedCaptureAudioCtx,
    //   deviceName: resolvedDeviceName,
    //   requestedSampleRate: requestedSampleRate,
    //   requestedSampleType: requestedSampleType,
    //   outputChannelCount: outputChannelCount,
    //   inputChannelCount: null, // Not known until the getUserMedia() promise resolves
    //   mediaStreamError: null, // Used by other functions to return early and report an error.
    //   mediaStreamSourceNode: null,
    //   mediaStream: null,
    //   // Either one, or none of the below two, is active.
    //   mergerNode: null,
    //   splitterNode: null,
    //   scriptProcessorNode: null,
    //   isCapturing: false,
    //   buffers: buffers,
    //   get bufferFrameCapacity() {
    //     return buffers[0].length;
    //   },
    //   capturePlayhead: 0, // current write position, in sample frames
    //   captureReadhead: 0,
    //   capturedFrameCount: 0,
    // };
    // // Preparing for getUserMedia()
    // let onError = (mediaStreamError) => {
    //   newCapture.mediaStreamError = mediaStreamError;
    // };
    // let onSuccess = (mediaStream) => {
    //   newCapture.mediaStreamSourceNode =
    //     newCapture.audioCtx.createMediaStreamSource(mediaStream);
    //   newCapture.mediaStream = mediaStream;
    //   let inputChannelCount = 1;
    //   switch (newCapture.mediaStreamSourceNode.channelCountMode) {
    //     case 'max':
    //       inputChannelCount = outputChannelCount;
    //       break;
    //     case 'clamped-max':
    //       inputChannelCount = Math.min(
    //         outputChannelCount,
    //         newCapture.mediaStreamSourceNode.channelCount
    //       );
    //       break;
    //     case 'explicit':
    //       inputChannelCount = newCapture.mediaStreamSourceNode.channelCount;
    //       break;
    //   }
    //   newCapture.inputChannelCount = inputChannelCount;
    //   // Have to pick a size from 256, 512, 1024, 2048, 4096, 8192, 16384.
    //   // One can also set it to zero, which leaves the decision up to the impl.
    //   // An extension could allow specifying this value.
    //   let processorFrameCount = 512;
    //   newCapture.scriptProcessorNode =
    //     newCapture.audioCtx.createScriptProcessor(
    //       processorFrameCount,
    //       inputChannelCount,
    //       outputChannelCount
    //     );
    //   if (inputChannelCount > outputChannelCount) {
    //     newCapture.mergerNode =
    //       newCapture.audioCtx.createChannelMerger(inputChannelCount);
    //     newCapture.mediaStreamSourceNode.connect(newCapture.mergerNode);
    //     newCapture.mergerNode.connect(newCapture.scriptProcessorNode);
    //   } else if (inputChannelCount < outputChannelCount) {
    //     newCapture.splitterNode =
    //       newCapture.audioCtx.createChannelSplitter(outputChannelCount);
    //     newCapture.mediaStreamSourceNode.connect(newCapture.splitterNode);
    //     newCapture.splitterNode.connect(newCapture.scriptProcessorNode);
    //   } else {
    //     newCapture.mediaStreamSourceNode.connect(
    //       newCapture.scriptProcessorNode
    //     );
    //   }
    //   newCapture.scriptProcessorNode.connect(newCapture.audioCtx.destination);
    //   newCapture.scriptProcessorNode.onaudioprocess = (
    //     audioProcessingEvent
    //   ) => {
    //     if (!newCapture.isCapturing) {
    //       return;
    //     }
    //     let c = newCapture;
    //     let srcBuf = audioProcessingEvent.inputBuffer;
    //     // Actually just copy srcBuf's channel data into
    //     // c.buffers, optimizing for each case.
    //     let channel0 = srcBuf.getChannelData(0);
    //     let channel1 = srcBuf.getChannelData(1);
    //     switch (format) {
    //       case 0x10010 /* AL_FORMAT_MONO_FLOAT32 */:
    //         for (let i = 0; i < srcBuf.length; ++i) {
    //           let wi = (c.capturePlayhead + i) % c.bufferFrameCapacity;
    //           c.buffers[0][wi] = channel0[i];
    //         }
    //         break;
    //       case 0x10011 /* AL_FORMAT_STEREO_FLOAT32 */:
    //         for (let i = 0; i < srcBuf.length; ++i) {
    //           let wi = (c.capturePlayhead + i) % c.bufferFrameCapacity;
    //           c.buffers[0][wi] = channel0[i];
    //           c.buffers[1][wi] = channel1[i];
    //         }
    //         break;
    //       case 0x1101 /* AL_FORMAT_MONO16 */:
    //         for (let i = 0; i < srcBuf.length; ++i) {
    //           let wi = (c.capturePlayhead + i) % c.bufferFrameCapacity;
    //           c.buffers[0][wi] = channel0[i] * 32767;
    //         }
    //         break;
    //       case 0x1103 /* AL_FORMAT_STEREO16 */:
    //         for (let i = 0; i < srcBuf.length; ++i) {
    //           let wi = (c.capturePlayhead + i) % c.bufferFrameCapacity;
    //           c.buffers[0][wi] = channel0[i] * 32767;
    //           c.buffers[1][wi] = channel1[i] * 32767;
    //         }
    //         break;
    //       case 0x1100 /* AL_FORMAT_MONO8 */:
    //         for (let i = 0; i < srcBuf.length; ++i) {
    //           let wi = (c.capturePlayhead + i) % c.bufferFrameCapacity;
    //           c.buffers[0][wi] = (channel0[i] + 1.0) * 127;
    //         }
    //         break;
    //       case 0x1102 /* AL_FORMAT_STEREO8 */:
    //         for (let i = 0; i < srcBuf.length; ++i) {
    //           let wi = (c.capturePlayhead + i) % c.bufferFrameCapacity;
    //           c.buffers[0][wi] = (channel0[i] + 1.0) * 127;
    //           c.buffers[1][wi] = (channel1[i] + 1.0) * 127;
    //         }
    //         break;
    //     }
    //     c.capturePlayhead += srcBuf.length;
    //     c.capturePlayhead %= c.bufferFrameCapacity;
    //     c.capturedFrameCount += srcBuf.length;
    //     c.capturedFrameCount = Math.min(
    //       c.capturedFrameCount,
    //       c.bufferFrameCapacity
    //     );
    //   };
    // };
    // // The latest way to call getUserMedia()
    // if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    //   navigator.mediaDevices
    //     .getUserMedia({ audio: true })
    //     .then(onSuccess)
    //     .catch(onError);
    // } else {
    //   // The usual (now deprecated) way
    //   navigator.getUserMedia({ audio: true }, onSuccess, onError);
    // }
    // let id = this.AL.newId();
    // this.AL.captures[id] = newCapture;
    // return id;
  }

  private _alcCaptureSamples(
    deviceId: any,
    pFrames: any,
    requestedFrameCount: any
  ) {
    // let c = this.AL.requireValidCaptureDevice(deviceId, 'alcCaptureSamples');
    // if (!c) return;
    // // ALCsizei is actually 32-bit signed int, so could be negative
    // // Also, spec says :
    // //   Requesting more sample frames than are currently available is
    // //   an error.
    // let dstfreq = c.requestedSampleRate;
    // let srcfreq = c.audioCtx.sampleRate;
    // let fratio = srcfreq / dstfreq;
    // if (
    //   requestedFrameCount < 0 ||
    //   requestedFrameCount > c.capturedFrameCount / fratio
    // ) {
    //   this.AL.alcErr = 40964;
    //   return;
    // }
    // const setF32Sample = (i, sample) => {
    //   this.HEAPF32[(pFrames + 4 * i) >> 2] = sample;
    // };
    // const setI16Sample = (i, sample) => {
    //   this.HEAP16[(pFrames + 2 * i) >> 1] = sample;
    // };
    // const setU8Sample = (i, sample) => {
    //   this.HEAP8[(pFrames + i) >> 0] = sample;
    // };
    // let setSample;
    // switch (c.requestedSampleType) {
    //   case 'f32':
    //     setSample = setF32Sample;
    //     break;
    //   case 'i16':
    //     setSample = setI16Sample;
    //     break;
    //   case 'u8':
    //     setSample = setU8Sample;
    //     break;
    //   default:
    //     return;
    // }
    // // If fratio is an integer we don't need linear resampling, just skip samples
    // if (Math.floor(fratio) == fratio) {
    //   for (let i = 0, frame_i = 0; frame_i < requestedFrameCount; ++frame_i) {
    //     for (let chan = 0; chan < c.buffers.length; ++chan, ++i) {
    //       setSample(i, c.buffers[chan][c.captureReadhead]);
    //     }
    //     c.captureReadhead =
    //       (fratio + c.captureReadhead) % c.bufferFrameCapacity;
    //   }
    // } else {
    //   // Perform linear resampling.
    //   // There is room for improvement - right now we're fine with linear resampling.
    //   // We don't use OfflineAudioContexts for this: See the discussion at
    //   // https://github.com/jpernst/emscripten/issues/2#issuecomment-312729735
    //   // if you're curious about why.
    //   for (let i = 0, frame_i = 0; frame_i < requestedFrameCount; ++frame_i) {
    //     let lefti = Math.floor(c.captureReadhead);
    //     let righti = Math.ceil(c.captureReadhead);
    //     let d = c.captureReadhead - lefti;
    //     for (let chan = 0; chan < c.buffers.length; ++chan, ++i) {
    //       let lefts = c.buffers[chan][lefti];
    //       let rights = c.buffers[chan][righti];
    //       setSample(i, (1 - d) * lefts + d * rights);
    //     }
    //     c.captureReadhead =
    //       (c.captureReadhead + fratio) % c.bufferFrameCapacity;
    //   }
    // }
    // // Spec doesn't say if alcCaptureSamples() must zero the number
    // // of available captured sample-frames, but not only would it
    // // be insane not to do, OpenAL-Soft happens to do that as well.
    // c.capturedFrameCount = 0;
  }

  private _alcCaptureStart(deviceId: any) {
    let c = this.AL.requireValidCaptureDevice(deviceId, 'alcCaptureStart');
    if (!c) return;

    if (c.isCapturing) {
      // NOTE: Spec says (emphasis mine):
      //     The amount of audio samples available after **restarting** a
      //     stopped capture device is reset to zero.
      // So redundant calls to alcCaptureStart() must have no effect.
      return;
    }
    c.isCapturing = true;
    c.capturedFrameCount = 0;
    c.capturePlayhead = 0;
  }

  private _alcCaptureStop(deviceId: any) {
    let c = this.AL.requireValidCaptureDevice(deviceId, 'alcCaptureStop');
    if (!c) return;

    c.isCapturing = false;
  }

  private _alcCloseDevice(deviceId: string) {
    if (
      !(deviceId in this.AL.deviceRefCounts) ||
      this.AL.deviceRefCounts[deviceId] > 0
    ) {
      return 0;
    }

    delete this.AL.deviceRefCounts[deviceId];
    this.AL.freeIds.push(deviceId);
    return 1;
  }

  private _alcCreateContext(deviceId: any, pAttrList: any) {
    // if (!(deviceId in this.AL.deviceRefCounts)) {
    //   this.AL.alcErr = 0xa001; /* ALC_INVALID_DEVICE */
    //   return 0;
    // }
    // let options: any = null;
    // let attrs: any = [];
    // let hrtf: any = null;
    // pAttrList >>= 2;
    // if (pAttrList) {
    //   let attr = 0;
    //   let val = 0;
    //   while (true) {
    //     attr = this.HEAP32[pAttrList++];
    //     attrs.push(attr);
    //     if (attr === 0) {
    //       break;
    //     }
    //     val = this.HEAP32[pAttrList++];
    //     attrs.push(val);
    //     switch (attr) {
    //       case 0x1007 /* ALC_FREQUENCY */:
    //         if (!options) {
    //           options = {};
    //         }
    //         options.sampleRate = val;
    //         break;
    //       case 0x1010 /* ALC_MONO_SOURCES */: // fallthrough
    //       case 0x1011 /* ALC_STEREO_SOURCES */:
    //         // Do nothing; these hints are satisfied by default
    //         break;
    //       case 0x1992 /* ALC_HRTF_SOFT */:
    //         switch (val) {
    //           case 0:
    //             hrtf = false;
    //             break;
    //           case 1:
    //             hrtf = true;
    //             break;
    //           case 2 /* ALC_DONT_CARE_SOFT */:
    //             break;
    //           default:
    //             this.AL.alcErr = 40964;
    //             return 0;
    //         }
    //         break;
    //       case 0x1996 /* ALC_HRTF_ID_SOFT */:
    //         if (val !== 0) {
    //           this.AL.alcErr = 40964;
    //           return 0;
    //         }
    //         break;
    //       default: /* ALC_INVALID_VALUE */
    //         this.AL.alcErr = 0xa004;
    //         return 0;
    //     }
    //   }
    // }
    // let AudioContext = window.AudioContext || window.webkitAudioContext;
    // let ac = null;
    // try {
    //   // Only try to pass options if there are any, for compat with browsers that don't support this
    //   if (options) {
    //     ac = new AudioContext(options);
    //   } else {
    //     ac = new AudioContext();
    //   }
    // } catch (e: any) {
    //   if (e.name === 'NotSupportedError') {
    //     this.AL.alcErr = 0xa004; /* ALC_INVALID_VALUE */
    //   } else {
    //     this.AL.alcErr = 0xa001; /* ALC_INVALID_DEVICE */
    //   }
    //   return 0;
    // }
    // autoResumeAudioContext(ac);
    // // Old Web Audio API (e.g. Safari 6.0.5) had an inconsistently named createGainNode function.
    // if (typeof ac.createGain == 'undefined') {
    //   ac.createGain = ac.createGainNode;
    // }
    // let gain = ac.createGain();
    // gain.connect(ac.destination);
    // let ctx = {
    //   deviceId: deviceId,
    //   id: this.AL.newId(),
    //   attrs: attrs,
    //   audioCtx: ac,
    //   listener: {
    //     position: [0.0, 0.0, 0.0],
    //     velocity: [0.0, 0.0, 0.0],
    //     direction: [0.0, 0.0, 0.0],
    //     up: [0.0, 0.0, 0.0],
    //   },
    //   sources: [],
    //   interval: setInterval(() => {
    //     this.AL.scheduleContextAudio(ctx);
    //   }, this.AL.QUEUE_INTERVAL),
    //   gain: gain,
    //   distanceModel: 0xd002 /* AL_INVERSE_DISTANCE_CLAMPED */,
    //   speedOfSound: 343.3,
    //   dopplerFactor: 1.0,
    //   sourceDistanceModel: false,
    //   hrtf: hrtf || false,
    //   _err: 0,
    //   get err() {
    //     return this._err;
    //   },
    //   set err(val) {
    //     // Errors should not be overwritten by later errors until they are cleared by a query.
    //     if (this._err === 0 || val === 0) {
    //       this._err = val;
    //     }
    //   },
    // };
    // this.AL.deviceRefCounts[deviceId]++;
    // this.AL.contexts[ctx.id] = ctx;
    // if (hrtf !== null) {
    //   // Apply hrtf attrib to all contexts for this device
    //   for (let ctxId in this.AL.contexts) {
    //     let c = this.AL.contexts[ctxId];
    //     if (c.deviceId === deviceId) {
    //       c.hrtf = hrtf;
    //       this.AL.updateContextGlobal(c);
    //     }
    //   }
    // }
    // return ctx.id;
  }

  private _alcDestroyContext(contextId: string | number) {
    let ctx = this.AL.contexts[contextId];
    if (this.AL.currentCtx === ctx) {
      this.AL.alcErr = 0xa002 /* ALC_INVALID_CONTEXT */;
      return;
    }

    // Stop playback, etc
    if (this.AL.contexts[contextId].interval) {
      clearInterval(this.AL.contexts[contextId].interval);
    }
    this.AL.deviceRefCounts[ctx.deviceId]--;
    delete this.AL.contexts[contextId];
    this.AL.freeIds.push(contextId);
  }

  private _alcGetContextsDevice(contextId: string) {
    if (contextId in this.AL.contexts) {
      return this.AL.contexts[contextId].deviceId;
    }
    return 0;
  }

  private _alcGetCurrentContext() {
    if (this.AL.currentCtx !== null) {
      return this.AL.currentCtx.id;
    }
    return 0;
  }

  private _alcGetEnumValue(deviceId: string | number, pEnumName: any) {
    // Spec says :
    // Using a NULL handle is legal, but only the
    // tokens defined by the AL core are guaranteed.
    if (deviceId !== 0 && !(deviceId in this.AL.deviceRefCounts)) {
      // ALC_INVALID_DEVICE is not listed as a possible error state for
      // this function, sadly.
      return 0;
    } else if (!pEnumName) {
      this.AL.alcErr = 40964;
      return 0;
    }
    let name = this.UTF8ToString(pEnumName);
    // See alGetEnumValue(), but basically behave the same as OpenAL-Soft
    switch (name) {
      case 'ALC_NO_ERROR':
        return 0;
      case 'ALC_INVALID_DEVICE':
        return 0xa001;
      case 'ALC_INVALID_CONTEXT':
        return 0xa002;
      case 'ALC_INVALID_ENUM':
        return 0xa003;
      case 'ALC_INVALID_VALUE':
        return 0xa004;
      case 'ALC_OUT_OF_MEMORY':
        return 0xa005;
      case 'ALC_MAJOR_VERSION':
        return 0x1000;
      case 'ALC_MINOR_VERSION':
        return 0x1001;
      case 'ALC_ATTRIBUTES_SIZE':
        return 0x1002;
      case 'ALC_ALL_ATTRIBUTES':
        return 0x1003;
      case 'ALC_DEFAULT_DEVICE_SPECIFIER':
        return 0x1004;
      case 'ALC_DEVICE_SPECIFIER':
        return 0x1005;
      case 'ALC_EXTENSIONS':
        return 0x1006;
      case 'ALC_FREQUENCY':
        return 0x1007;
      case 'ALC_REFRESH':
        return 0x1008;
      case 'ALC_SYNC':
        return 0x1009;
      case 'ALC_MONO_SOURCES':
        return 0x1010;
      case 'ALC_STEREO_SOURCES':
        return 0x1011;
      case 'ALC_CAPTURE_DEVICE_SPECIFIER':
        return 0x310;
      case 'ALC_CAPTURE_DEFAULT_DEVICE_SPECIFIER':
        return 0x311;
      case 'ALC_CAPTURE_SAMPLES':
        return 0x312;

      /* Extensions */
      case 'ALC_HRTF_SOFT':
        return 0x1992;
      case 'ALC_HRTF_ID_SOFT':
        return 0x1996;
      case 'ALC_DONT_CARE_SOFT':
        return 0x0002;
      case 'ALC_HRTF_STATUS_SOFT':
        return 0x1993;
      case 'ALC_NUM_HRTF_SPECIFIERS_SOFT':
        return 0x1994;
      case 'ALC_HRTF_SPECIFIER_SOFT':
        return 0x1995;
      case 'ALC_HRTF_DISABLED_SOFT':
        return 0x0000;
      case 'ALC_HRTF_ENABLED_SOFT':
        return 0x0001;
      case 'ALC_HRTF_DENIED_SOFT':
        return 0x0002;
      case 'ALC_HRTF_REQUIRED_SOFT':
        return 0x0003;
      case 'ALC_HRTF_HEADPHONES_DETECTED_SOFT':
        return 0x0004;
      case 'ALC_HRTF_UNSUPPORTED_FORMAT_SOFT':
        return 0x0005;

      default:
        this.AL.alcErr = 40964;
        return 0;
    }
  }

  private _alcGetError(deviceId: any) {
    let err = this.AL.alcErr;
    this.AL.alcErr = 0;
    return err;
  }

  private _alcGetIntegerv(
    deviceId: string,
    param: any,
    size: number,
    pValues: number
  ) {
    if (size === 0 || !pValues) {
      // Ignore the query, per the spec
      return;
    }

    switch (param) {
      case 0x1000 /* ALC_MAJOR_VERSION */:
        this.HEAP32[pValues >> 2] = 1;
        break;
      case 0x1001 /* ALC_MINOR_VERSION */:
        this.HEAP32[pValues >> 2] = 1;
        break;
      case 0x1002 /* ALC_ATTRIBUTES_SIZE */:
        if (!(deviceId in this.AL.deviceRefCounts)) {
          this.AL.alcErr = 40961;
          return;
        }
        if (!this.AL.currentCtx) {
          this.AL.alcErr = 0xa002 /* ALC_INVALID_CONTEXT */;
          return;
        }

        this.HEAP32[pValues >> 2] = this.AL.currentCtx.attrs.length;
        break;
      case 0x1003 /* ALC_ALL_ATTRIBUTES */:
        if (!(deviceId in this.AL.deviceRefCounts)) {
          this.AL.alcErr = 40961;
          return;
        }
        if (!this.AL.currentCtx) {
          this.AL.alcErr = 0xa002 /* ALC_INVALID_CONTEXT */;
          return;
        }

        for (let i = 0; i < this.AL.currentCtx.attrs.length; i++) {
          this.HEAP32[(pValues + i * 4) >> 2] = this.AL.currentCtx.attrs[i];
        }
        break;
      case 0x1007 /* ALC_FREQUENCY */:
        if (!(deviceId in this.AL.deviceRefCounts)) {
          this.AL.alcErr = 40961;
          return;
        }
        if (!this.AL.currentCtx) {
          this.AL.alcErr = 0xa002 /* ALC_INVALID_CONTEXT */;
          return;
        }

        this.HEAP32[pValues >> 2] = this.AL.currentCtx.audioCtx.sampleRate;
        break;
      case 0x1010 /* ALC_MONO_SOURCES */:
      case 0x1011 /* ALC_STEREO_SOURCES */:
        if (!(deviceId in this.AL.deviceRefCounts)) {
          this.AL.alcErr = 40961;
          return;
        }
        if (!this.AL.currentCtx) {
          this.AL.alcErr = 0xa002 /* ALC_INVALID_CONTEXT */;
          return;
        }

        this.HEAP32[pValues >> 2] = 0x7fffffff;
        break;
      case 0x1992 /* ALC_HRTF_SOFT */:
      case 0x1993 /* ALC_HRTF_STATUS_SOFT */:
        if (!(deviceId in this.AL.deviceRefCounts)) {
          this.AL.alcErr = 40961;
          return;
        }

        let hrtfStatus = 0; /* ALC_HRTF_DISABLED_SOFT */
        for (let ctxId in this.AL.contexts) {
          let ctx = this.AL.contexts[ctxId];
          if (ctx.deviceId === deviceId) {
            hrtfStatus = ctx.hrtf
              ? 1 /* ALC_HRTF_ENABLED_SOFT */
              : 0 /* ALC_HRTF_DISABLED_SOFT */;
          }
        }
        this.HEAP32[pValues >> 2] = hrtfStatus;
        break;
      case 0x1994 /* ALC_NUM_HRTF_SPECIFIERS_SOFT */:
        if (!(deviceId in this.AL.deviceRefCounts)) {
          this.AL.alcErr = 40961;
          return;
        }
        this.HEAP32[pValues >> 2] = 1;
        break;
      case 0x20003 /* ALC_MAX_AUXILIARY_SENDS */:
        if (!(deviceId in this.AL.deviceRefCounts)) {
          this.AL.alcErr = 40961;
          return;
        }
        if (!this.AL.currentCtx) {
          this.AL.alcErr = 0xa002 /* ALC_INVALID_CONTEXT */;
          return;
        }

        this.HEAP32[pValues >> 2] = 1;
        break;
      case 0x312 /* ALC_CAPTURE_SAMPLES */:
        let c = this.AL.requireValidCaptureDevice(deviceId, 'alcGetIntegerv');
        if (!c) {
          return;
        }
        let n = c.capturedFrameCount;
        let dstfreq = c.requestedSampleRate;
        let srcfreq = c.audioCtx.sampleRate;
        let nsamples = Math.floor(n * (dstfreq / srcfreq));
        this.HEAP32[pValues >> 2] = nsamples;
        break;
      default:
        this.AL.alcErr = 40963;
        return;
    }
  }

  private _alcGetString(deviceId: any, param: any) {
    // if (this.AL.alcStringCache[param]) {
    //   return this.AL.alcStringCache[param];
    // }
    // let ret;
    // switch (param) {
    //   case 0:
    //     ret = 'No Error';
    //     break;
    //   case 40961:
    //     ret = 'Invalid Device';
    //     break;
    //   case 0xa002 /* ALC_INVALID_CONTEXT */:
    //     ret = 'Invalid Context';
    //     break;
    //   case 40963:
    //     ret = 'Invalid Enum';
    //     break;
    //   case 40964:
    //     ret = 'Invalid Value';
    //     break;
    //   case 0xa005 /* ALC_OUT_OF_MEMORY */:
    //     ret = 'Out of Memory';
    //     break;
    //   case 0x1004 /* ALC_DEFAULT_DEVICE_SPECIFIER */:
    //     if (
    //       typeof AudioContext != 'undefined' ||
    //       typeof webkitAudioContext != 'undefined'
    //     ) {
    //       ret = this.AL.DEVICE_NAME;
    //     } else {
    //       return 0;
    //     }
    //     break;
    //   case 0x1005 /* ALC_DEVICE_SPECIFIER */:
    //     if (
    //       typeof AudioContext != 'undefined' ||
    //       typeof webkitAudioContext != 'undefined'
    //     ) {
    //       ret = this.AL.DEVICE_NAME.concat('\0');
    //     } else {
    //       ret = '\0';
    //     }
    //     break;
    //   case 0x311 /* ALC_CAPTURE_DEFAULT_DEVICE_SPECIFIER */:
    //     ret = this.AL.CAPTURE_DEVICE_NAME;
    //     break;
    //   case 0x310 /* ALC_CAPTURE_DEVICE_SPECIFIER */:
    //     if (deviceId === 0) ret = this.AL.CAPTURE_DEVICE_NAME.concat('\0');
    //     else {
    //       let c = this.AL.requireValidCaptureDevice(deviceId, 'alcGetString');
    //       if (!c) {
    //         return 0;
    //       }
    //       ret = c.deviceName;
    //     }
    //     break;
    //   case 0x1006 /* ALC_EXTENSIONS */:
    //     if (!deviceId) {
    //       this.AL.alcErr = 40961;
    //       return 0;
    //     }
    //     ret = '';
    //     for (let ext in this.AL.ALC_EXTENSIONS) {
    //       ret = ret.concat(ext);
    //       ret = ret.concat(' ');
    //     }
    //     ret = ret.trim();
    //     break;
    //   default:
    //     this.AL.alcErr = 40963;
    //     return 0;
    // }
    // ret = allocateUTF8(ret);
    // this.AL.alcStringCache[param] = ret;
    // return ret;
  }

  private _alcIsExtensionPresent(deviceId: any, pExtName: any) {
    let name = this.UTF8ToString(pExtName);

    return this.AL.ALC_EXTENSIONS[name] ? 1 : 0;
  }

  private _alcMakeContextCurrent(contextId: number) {
    if (contextId === 0) {
      this.AL.currentCtx = null;
    } else {
      this.AL.currentCtx = this.AL.contexts[contextId];
    }
    return 1;
  }

  private _alcOpenDevice(pDeviceName: any) {
    // if (pDeviceName) {
    //   let name = this.UTF8ToString(pDeviceName);
    //   if (name !== this.AL.DEVICE_NAME) {
    //     return 0;
    //   }
    // }
    // if (
    //   typeof AudioContext != 'undefined' ||
    //   typeof webkitAudioContext != 'undefined'
    // ) {
    //   let deviceId = this.AL.newId();
    //   this.AL.deviceRefCounts[deviceId] = 0;
    //   return deviceId;
    // }
    // return 0;
  }

  private _alcProcessContext(contextId: any) {}

  private _alcSuspendContext(contextId: any) {}

  private _emscripten_alcDevicePauseSOFT(deviceId: string) {
    if (!(deviceId in this.AL.deviceRefCounts)) {
      this.AL.alcErr = 40961;
      return;
    }

    if (this.AL.paused) {
      return;
    }
    this.AL.paused = true;

    for (let ctxId in this.AL.contexts) {
      let ctx = this.AL.contexts[ctxId];
      if (ctx.deviceId !== deviceId) {
        continue;
      }

      ctx.audioCtx.suspend();
      clearInterval(ctx.interval);
      ctx.interval = null;
    }
  }

  private _emscripten_alcDeviceResumeSOFT(deviceId: string) {
    if (!(deviceId in this.AL.deviceRefCounts)) {
      this.AL.alcErr = 40961;
      return;
    }

    if (!this.AL.paused) {
      return;
    }
    this.AL.paused = false;

    for (let ctxId in this.AL.contexts) {
      let ctx = this.AL.contexts[ctxId];
      if (ctx.deviceId !== deviceId) {
        continue;
      }

      ctx.interval = setInterval(() => {
        this.AL.scheduleContextAudio(ctx);
      }, this.AL.QUEUE_INTERVAL);
      ctx.audioCtx.resume();
    }
  }

  private _emscripten_alcGetStringiSOFT(deviceId: any, param: any, index: any) {
    // if (!(deviceId in this.AL.deviceRefCounts)) {
    //   this.AL.alcErr = 40961;
    //   return 0;
    // }
    // if (this.AL.alcStringCache[param]) {
    //   return this.AL.alcStringCache[param];
    // }
    // let ret;
    // switch (param) {
    //   case 0x1995 /* ALC_HRTF_SPECIFIER_SOFT */:
    //     if (index === 0) {
    //       ret = 'Web Audio HRTF';
    //     } else {
    //       this.AL.alcErr = 40964;
    //       return 0;
    //     }
    //     break;
    //   default:
    //     if (index !== 0) {
    //       this.AL.alcErr = 40963;
    //       return 0;
    //     }
    //     return _alcGetString(deviceId, param);
    // }
    // ret = allocateUTF8(ret);
    // this.AL.alcStringCache[param] = ret;
    // return ret;
  }

  private _emscripten_alcResetDeviceSOFT(deviceId: any, pAttrList: any) {
    // if (!(deviceId in this.AL.deviceRefCounts)) {
    //   this.AL.alcErr = 40961;
    //   return 0;
    // }
    // let hrtf = null;
    // pAttrList >>= 2;
    // if (pAttrList) {
    //   let attr = 0;
    //   let val = 0;
    //   while (true) {
    //     attr = this.HEAP32[pAttrList++];
    //     if (attr === 0) {
    //       break;
    //     }
    //     val = this.HEAP32[pAttrList++];
    //     switch (attr) {
    //       case 0x1992 /* ALC_HRTF_SOFT */:
    //         if (val === 1) {
    //           hrtf = true;
    //         } else if (val === 0) {
    //           hrtf = false;
    //         }
    //         break;
    //     }
    //   }
    // }
    // if (hrtf !== null) {
    //   // Apply hrtf attrib to all contexts for this device
    //   for (let ctxId in this.AL.contexts) {
    //     let ctx = this.AL.contexts[ctxId];
    //     if (ctx.deviceId === deviceId) {
    //       ctx.hrtf = hrtf;
    //       this.AL.updateContextGlobal(ctx);
    //     }
    //   }
    // }
    // return 1;
  }

  private readEmAsmArgsArray = [];
  private readEmAsmArgs(sigPtr: any, buf: any) {
    // // Nobody should have mutated _readEmAsmArgsArray underneath us to be something else than an array.
    // this.assert(Array.isArray(readEmAsmArgsArray));
    // // The input buffer is allocated on the stack, so it must be stack-aligned.
    // this.assert(buf % 16 == 0);
    // readEmAsmArgsArray.length = 0;
    // let ch;
    // // Most arguments are i32s, so shift the buffer pointer so it is a plain
    // // index into this.HEAP32.
    // buf >>= 2;
    // while ((ch = this.HEAPU8[sigPtr++])) {
    //   let chr = String.fromCharCode(ch);
    //   let validChars = ['d', 'f', 'i'];
    //   this.assert(
    //     validChars.includes(chr),
    //     'Invalid character ' +
    //       ch +
    //       '("' +
    //       chr +
    //       '") in readEmAsmArgs! Use only [' +
    //       validChars +
    //       '], and do not specify "v" for void return argument.'
    //   );
    //   // Floats are always passed as doubles, and doubles and int64s take up 8
    //   // bytes (two 32-bit slots) in memory, align reads to these:
    //   buf += (ch != 105) /*i*/ & buf;
    //   readEmAsmArgsArray.push(
    //     ch == 105 /*i*/ ? this.HEAP32[buf] : this.HEAPF64[buf++ >> 1]
    //   );
    //   ++buf;
    // }
    // return readEmAsmArgsArray;
  }
  private runEmAsmFunction(code: any, sigPtr: any, argbuf: any) {
    // let args = readEmAsmArgs(sigPtr, argbuf);
    // if (!ASM_CONSTS.hasOwnProperty(code))
    //   this.abort('No EM_ASM constant found at address ' + code);
    // return ASM_CONSTS[code].apply(null, args);
  }
  private _emscripten_asm_const_int(code: any, sigPtr: any, argbuf: any) {
    // return runEmAsmFunction(code, sigPtr, argbuf);
  }

  private _emscripten_console_error(str: any) {
    this.assert(typeof str == 'number');
    console.error(this.UTF8ToString(str));
  }

  private _emscripten_console_log(str: any) {
    this.assert(typeof str == 'number');
    console.log(this.UTF8ToString(str));
  }

  private _emscripten_console_warn(str: any) {
    this.assert(typeof str == 'number');
    console.warn(this.UTF8ToString(str));
  }

  private _emscripten_date_now() {
    return Date.now();
  }

  private getHeapMax() {
    return this.HEAPU8.length;
  }
  private _emscripten_get_heap_max() {
    return this.getHeapMax();
  }

  private _emscripten_get_now_res() {
    // return resolution of get_now, in nanoseconds
    // Modern environment where performance.now() is supported:
    return 1000; // microseconds (1/1000 of a millisecond)
  }

  private __webgl_enable_ANGLE_instanced_arrays(ctx: {
    [x: string]: (
      mode: any,
      count: any,
      type: any,
      indices: any,
      primcount: any
    ) => void;
    getExtension: (arg0: string) => any;
  }) {
    // Extension available in WebGL 1 from Firefox 26 and Google Chrome 30 onwards. Core feature in WebGL 2.
    let ext = ctx.getExtension('ANGLE_instanced_arrays');
    if (ext) {
      ctx['vertexAttribDivisor'] = (index: any, divisor: any) => {
        ext['vertexAttribDivisorANGLE'](index, divisor);
      };
      ctx['drawArraysInstanced'] = (
        mode: any,
        first: any,
        count: any,
        primcount: any
      ) => {
        ext['drawArraysInstancedANGLE'](mode, first, count, primcount);
      };
      ctx['drawElementsInstanced'] = (
        mode: any,
        count: any,
        type: any,
        indices: any,
        primcount: any
      ) => {
        ext['drawElementsInstancedANGLE'](
          mode,
          count,
          type,
          indices,
          primcount
        );
      };
      return 1;
    }
    return 0;
  }

  private __webgl_enable_OES_vertex_array_object(ctx: {
    [x: string]: (vao: any) => any;
    getExtension: (arg0: string) => any;
  }) {
    // Extension available in WebGL 1 from Firefox 25 and WebKit 536.28/desktop Safari 6.0.3 onwards. Core feature in WebGL 2.
    let ext = ctx.getExtension('OES_vertex_array_object');
    if (ext) {
      ctx['createVertexArray'] = () => {
        return ext['createVertexArrayOES']();
      };
      ctx['deleteVertexArray'] = (vao: any) => {
        ext['deleteVertexArrayOES'](vao);
      };
      ctx['bindVertexArray'] = (vao: any) => {
        ext['bindVertexArrayOES'](vao);
      };
      ctx['isVertexArray'] = (vao: any) => {
        return ext['isVertexArrayOES'](vao);
      };
      return 1;
    }
    return 0;
  }

  private __webgl_enable_WEBGL_draw_buffers(ctx: {
    [x: string]: (n: any, bufs: any) => void;
    getExtension: (arg0: string) => any;
  }) {
    // Extension available in WebGL 1 from Firefox 28 onwards. Core feature in WebGL 2.
    let ext = ctx.getExtension('WEBGL_draw_buffers');
    if (ext) {
      ctx['drawBuffers'] = (n: any, bufs: any) => {
        ext['drawBuffersWEBGL'](n, bufs);
      };
      return 1;
    }
    return 0;
  }

  private __webgl_enable_WEBGL_multi_draw(ctx: {
    multiDrawWebgl: any;
    getExtension: (arg0: string) => any;
  }) {
    // Closure is expected to be allowed to minify the '.multiDrawWebgl' property, so not accessing it quoted.
    return !!(ctx.multiDrawWebgl = ctx.getExtension('WEBGL_multi_draw'));
  }

  private GL: any = {
    counter: 1,
    buffers: [],
    programs: [],
    framebuffers: [],
    renderbuffers: [],
    textures: [],
    shaders: [],
    vaos: [],
    contexts: [],
    offscreenCanvases: {},
    queries: [],
    stringCache: {},
    unpackAlignment: 4,
    recordError: (errorCode: any) => {
      if (!this.GL.lastError) {
        this.GL.lastError = errorCode;
      }
    },
    getNewId: (table: any) => {
      let ret = this.GL.counter++;
      for (let i = table.length; i < ret; i++) {
        table[i] = null;
      }
      return ret;
    },
    getSource: (shader: any, count: number, string: number, length: number) => {
      let source = '';
      for (let i = 0; i < count; ++i) {
        let len = length ? this.HEAP32[(length + i * 4) >> 2] : -1;
        source += this.UTF8ToString(
          this.HEAP32[(string + i * 4) >> 2],
          len < 0 ? undefined : len
        );
      }
      return source;
    },
    createContext: (
      /** @type {HTMLCanvasElement} */ canvas: {
        getContextSafariWebGL2Fixed: (arg0: any, arg1: any) => any;
        getContext: (arg0: string, arg1: any) => any;
      },
      webGLContextAttributes: any
    ) => {
      // BUG: Workaround Safari WebGL issue: After successfully acquiring WebGL context on a canvas,
      // calling .getContext() will always return that context independent of which 'webgl' or 'webgl2'
      // context version was passed. See https://bugs.webkit.org/show_bug.cgi?id=222758 and
      // https://github.com/emscripten-core/emscripten/issues/13295.
      // TODO: Once the bug is fixed and shipped in Safari, adjust the Safari version field in above check.
      if (!canvas.getContextSafariWebGL2Fixed) {
        canvas.getContextSafariWebGL2Fixed = canvas.getContext;
        /** @type {function(this:HTMLCanvasElement, string, (Object|null)=): (Object|null)} */
        const fixedGetContext = (ver: string, attrs: any) => {
          let gl = canvas.getContextSafariWebGL2Fixed(ver, attrs);
          return (ver == 'webgl') == gl instanceof WebGLRenderingContext
            ? gl
            : null;
        };
        canvas.getContext = fixedGetContext;
      }

      let ctx = canvas.getContext('webgl', webGLContextAttributes);
      // https://caniuse.com/#feat=webgl

      if (!ctx) return 0;

      let handle = this.GL.registerContext(ctx, webGLContextAttributes);

      return handle;
    },
    registerContext: (
      ctx: { canvas: { GLctxObject: any } },
      webGLContextAttributes: {
        majorVersion: any;
        enableExtensionsByDefault: any;
      }
    ) => {
      // without pthreads a context is just an integer ID
      let handle = this.GL.getNewId(this.GL.contexts);

      let context: any = {
        handle: handle,
        attributes: webGLContextAttributes,
        version: webGLContextAttributes.majorVersion,
        GLctx: ctx,
      };

      // Store the created context object so that we can access the context given a canvas without having to pass the parameters again.
      if (ctx.canvas) ctx.canvas.GLctxObject = context;
      this.GL.contexts[handle] = context;
      if (
        typeof webGLContextAttributes.enableExtensionsByDefault ==
          'undefined' ||
        webGLContextAttributes.enableExtensionsByDefault
      ) {
        this.GL.initExtensions(context);
      }

      return handle;
    },
    makeContextCurrent: (contextHandle: string | number) => {
      this.GL.currentContext = this.GL.contexts[contextHandle]; // Active Emscripten GL layer context object.
      const GLctx = this.GL.currentContext && this.GL.currentContext.GLctx; // Active WebGL context object.
      this.wasmExports.ctx =
        this.GL.currentContext && this.GL.currentContext.GLctx; // Active WebGL context object.
      return !(contextHandle && !GLctx);
    },
    getContext: (contextHandle: string | number) => {
      return this.GL.contexts[contextHandle];
    },
    deleteContext: (contextHandle: string | number) => {
      if (this.GL.currentContext === this.GL.contexts[contextHandle])
        this.GL.currentContext = null;
      // if (typeof JSEvents == 'object')
      //   JSEvents.removeAllHandlersOnTarget(
      //     this.GL.contexts[contextHandle].this.GLctx.canvas
      //   ); // Release all JS event handlers on the DOM element that the GL context is associated with since the context is now deleted.
      if (
        this.GL.contexts[contextHandle] &&
        this.GL.contexts[contextHandle].this.GLctx.canvas
      )
        this.GL.contexts[contextHandle].this.GLctx.canvas.GLctxObject =
          undefined; // Make sure the canvas object no longer refers to the context object so there are no GC surprises.
      this.GL.contexts[contextHandle] = null;
    },
    initExtensions: (context: { initExtensionsDone: boolean; GLctx: any }) => {
      // If this private is called without a specific context object, init the extensions of the currently active context.
      if (!context) context = this.GL.currentContext;

      if (context.initExtensionsDone) return;
      context.initExtensionsDone = true;

      let GLctx = context.GLctx;

      // Detect the presence of a few extensions manually, this GL interop layer itself will need to know if they exist.

      // Extensions that are only available in WebGL 1 (the calls will be no-ops if called on a WebGL 2 context active)
      this.__webgl_enable_ANGLE_instanced_arrays(GLctx);
      this.__webgl_enable_OES_vertex_array_object(GLctx);
      this.__webgl_enable_WEBGL_draw_buffers(GLctx);

      {
        this.GLctx.disjointTimerQueryExt = this.GLctx.getExtension(
          'EXT_disjoint_timer_query'
        );
      }

      this.__webgl_enable_WEBGL_multi_draw(GLctx);

      // .getSupportedExtensions() can return null if context is lost, so coerce to empty array.
      let exts = this.GLctx.getSupportedExtensions() || [];
      exts.forEach((ext: string | string[]) => {
        // WEBGL_lose_context, WEBGL_debug_renderer_info and WEBGL_debug_shaders are not enabled by default.
        if (!ext.includes('lose_context') && !ext.includes('debug')) {
          // Call .getExtension() to enable that extension permanently.
          this.GLctx.getExtension(ext);
        }
      });
    },
  };
  private GLctx: any = {};
  private _emscripten_glActiveTexture(x0: any) {
    this.GLctx['activeTexture'](x0);
  }

  private _emscripten_glAttachShader(
    program: string | number,
    shader: string | number
  ) {
    this.GLctx.attachShader(this.GL.programs[program], this.GL.shaders[shader]);
  }

  private _emscripten_glBeginQueryEXT(target: any, id: string | number) {
    this.GLctx.disjointTimerQueryExt['beginQueryEXT'](
      target,
      this.GL.queries[id]
    );
  }

  private _emscripten_glBindAttribLocation(
    program: string | number,
    index: any,
    name: any
  ) {
    this.GLctx.bindAttribLocation(
      this.GL.programs[program],
      index,
      this.UTF8ToString(name)
    );
  }

  private _emscripten_glBindBuffer(target: any, buffer: string | number) {
    this.GLctx.bindBuffer(target, this.GL.buffers[buffer]);
  }

  private _emscripten_glBindFramebuffer(
    target: any,
    framebuffer: string | number
  ) {
    this.GLctx.bindFramebuffer(target, this.GL.framebuffers[framebuffer]);
  }

  private _emscripten_glBindRenderbuffer(
    target: any,
    renderbuffer: string | number
  ) {
    this.GLctx.bindRenderbuffer(target, this.GL.renderbuffers[renderbuffer]);
  }

  private _emscripten_glBindTexture(target: any, texture: string | number) {
    this.GLctx.bindTexture(target, this.GL.textures[texture]);
  }

  private _emscripten_glBindVertexArrayOES(vao: string | number) {
    this.GLctx['bindVertexArray'](this.GL.vaos[vao]);
  }

  private _emscripten_glBlendColor(x0: any, x1: any, x2: any, x3: any) {
    this.GLctx['blendColor'](x0, x1, x2, x3);
  }

  private _emscripten_glBlendEquation(x0: any) {
    this.GLctx['blendEquation'](x0);
  }

  private _emscripten_glBlendEquationSeparate(x0: any, x1: any) {
    this.GLctx['blendEquationSeparate'](x0, x1);
  }

  private _emscripten_glBlendFunc(x0: any, x1: any) {
    this.GLctx['blendFunc'](x0, x1);
  }

  private _emscripten_glBlendFuncSeparate(x0: any, x1: any, x2: any, x3: any) {
    this.GLctx['blendFuncSeparate'](x0, x1, x2, x3);
  }

  private _emscripten_glBufferData(
    target: any,
    size: any,
    data: number | undefined,
    usage: any
  ) {
    // N.b. here first form specifies a heap subarray, second form an integer size, so the ?: code here is polymorphic. It is advised to avoid
    // randomly mixing both uses in calling code, to avoid any potential JS engine JIT issues.
    this.GLctx.bufferData(
      target,
      data ? this.HEAPU8.subarray(data, data + size) : size,
      usage
    );
  }

  private _emscripten_glBufferSubData(
    target: any,
    offset: any,
    size: any,
    data: number | undefined
  ) {
    this.GLctx.bufferSubData(
      target,
      offset,
      this.HEAPU8.subarray(data, data + size)
    );
  }

  private _emscripten_glCheckFramebufferStatus(x0: any) {
    return this.GLctx['checkFramebufferStatus'](x0);
  }

  private _emscripten_glClear(x0: any) {
    this.GLctx['clear'](x0);
  }

  private _emscripten_glClearColor(x0: any, x1: any, x2: any, x3: any) {
    this.GLctx['clearColor'](x0, x1, x2, x3);
  }

  private _emscripten_glClearDepthf(x0: any) {
    this.GLctx['clearDepth'](x0);
  }

  private _emscripten_glClearStencil(x0: any) {
    this.GLctx['clearStencil'](x0);
  }

  private _emscripten_glColorMask(red: any, green: any, blue: any, alpha: any) {
    this.GLctx.colorMask(!!red, !!green, !!blue, !!alpha);
  }

  private _emscripten_glCompileShader(shader: string | number) {
    this.GLctx.compileShader(this.GL.shaders[shader]);
  }

  private _emscripten_glCompressedTexImage2D(
    target: any,
    level: any,
    internalFormat: any,
    width: any,
    height: any,
    border: any,
    imageSize: any,
    data: number | undefined
  ) {
    this.GLctx['compressedTexImage2D'](
      target,
      level,
      internalFormat,
      width,
      height,
      border,
      data ? this.HEAPU8.subarray(data, data + imageSize) : null
    );
  }

  private _emscripten_glCompressedTexSubImage2D(
    target: any,
    level: any,
    xoffset: any,
    yoffset: any,
    width: any,
    height: any,
    format: any,
    imageSize: any,
    data: number | undefined
  ) {
    this.GLctx['compressedTexSubImage2D'](
      target,
      level,
      xoffset,
      yoffset,
      width,
      height,
      format,
      data ? this.HEAPU8.subarray(data, data + imageSize) : null
    );
  }

  private _emscripten_glCopyTexImage2D(
    x0: any,
    x1: any,
    x2: any,
    x3: any,
    x4: any,
    x5: any,
    x6: any,
    x7: any
  ) {
    this.GLctx['copyTexImage2D'](x0, x1, x2, x3, x4, x5, x6, x7);
  }

  private _emscripten_glCopyTexSubImage2D(
    x0: any,
    x1: any,
    x2: any,
    x3: any,
    x4: any,
    x5: any,
    x6: any,
    x7: any
  ) {
    this.GLctx['copyTexSubImage2D'](x0, x1, x2, x3, x4, x5, x6, x7);
  }

  private _emscripten_glCreateProgram() {
    let id = this.GL.getNewId(this.GL.programs);
    let program = this.GLctx.createProgram();
    // Store additional information needed for each shader program:
    program.name = id;
    // Lazy cache results of glGetProgramiv(GL_ACTIVE_UNIFORM_MAX_LENGTH/GL_ACTIVE_ATTRIBUTE_MAX_LENGTH/GL_ACTIVE_UNIFORM_BLOCK_MAX_NAME_LENGTH)
    program.maxUniformLength =
      program.maxAttributeLength =
      program.maxUniformBlockNameLength =
        0;
    program.uniformIdCounter = 1;
    this.GL.programs[id] = program;
    return id;
  }

  private _emscripten_glCreateShader(shaderType: any) {
    let id = this.GL.getNewId(this.GL.shaders);
    this.GL.shaders[id] = this.GLctx.createShader(shaderType);

    return id;
  }

  private _emscripten_glCullFace(x0: any) {
    this.GLctx['cullFace'](x0);
  }

  private _emscripten_glDeleteBuffers(n: number, buffers: number) {
    for (let i = 0; i < n; i++) {
      let id = this.HEAP32[(buffers + i * 4) >> 2];
      let buffer = this.GL.buffers[id];

      // From spec: "glDeleteBuffers silently ignores 0's and names that do not
      // correspond to existing buffer objects."
      if (!buffer) continue;

      this.GLctx.deleteBuffer(buffer);
      buffer.name = 0;
      this.GL.buffers[id] = null;
    }
  }

  private _emscripten_glDeleteFramebuffers(n: number, framebuffers: number) {
    for (let i = 0; i < n; ++i) {
      let id = this.HEAP32[(framebuffers + i * 4) >> 2];
      let framebuffer = this.GL.framebuffers[id];
      if (!framebuffer) continue; // GL spec: "glDeleteFramebuffers silently ignores 0s and names that do not correspond to existing framebuffer objects".
      this.GLctx.deleteFramebuffer(framebuffer);
      framebuffer.name = 0;
      this.GL.framebuffers[id] = null;
    }
  }

  private _emscripten_glDeleteProgram(id: string | number) {
    if (!id) return;
    let program = this.GL.programs[id];
    if (!program) {
      // glDeleteProgram actually signals an error when deleting a nonexisting object, unlike some other GL delete functions.
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
      return;
    }
    this.GLctx.deleteProgram(program);
    program.name = 0;
    this.GL.programs[id] = null;
  }

  private _emscripten_glDeleteQueriesEXT(n: number, ids: number) {
    for (let i = 0; i < n; i++) {
      let id = this.HEAP32[(ids + i * 4) >> 2];
      let query = this.GL.queries[id];
      if (!query) continue; // GL spec: "unused names in ids are ignored, as is the name zero."
      this.GLctx.disjointTimerQueryExt['deleteQueryEXT'](query);
      this.GL.queries[id] = null;
    }
  }

  private _emscripten_glDeleteRenderbuffers(n: number, renderbuffers: number) {
    for (let i = 0; i < n; i++) {
      let id = this.HEAP32[(renderbuffers + i * 4) >> 2];
      let renderbuffer = this.GL.renderbuffers[id];
      if (!renderbuffer) continue; // GL spec: "glDeleteRenderbuffers silently ignores 0s and names that do not correspond to existing renderbuffer objects".
      this.GLctx.deleteRenderbuffer(renderbuffer);
      renderbuffer.name = 0;
      this.GL.renderbuffers[id] = null;
    }
  }

  private _emscripten_glDeleteShader(id: string | number) {
    if (!id) return;
    let shader = this.GL.shaders[id];
    if (!shader) {
      // glDeleteShader actually signals an error when deleting a nonexisting object, unlike some other GL delete functions.
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
      return;
    }
    this.GLctx.deleteShader(shader);
    this.GL.shaders[id] = null;
  }

  private _emscripten_glDeleteTextures(n: number, textures: number) {
    for (let i = 0; i < n; i++) {
      let id = this.HEAP32[(textures + i * 4) >> 2];
      let texture = this.GL.textures[id];
      if (!texture) continue; // GL spec: "glDeleteTextures silently ignores 0s and names that do not correspond to existing textures".
      this.GLctx.deleteTexture(texture);
      texture.name = 0;
      this.GL.textures[id] = null;
    }
  }

  private _emscripten_glDeleteVertexArraysOES(n: number, vaos: number) {
    for (let i = 0; i < n; i++) {
      let id = this.HEAP32[(vaos + i * 4) >> 2];
      this.GLctx['deleteVertexArray'](this.GL.vaos[id]);
      this.GL.vaos[id] = null;
    }
  }

  private _emscripten_glDepthFunc(x0: any) {
    this.GLctx['depthFunc'](x0);
  }

  private _emscripten_glDepthMask(flag: any) {
    this.GLctx.depthMask(!!flag);
  }

  private _emscripten_glDepthRangef(x0: any, x1: any) {
    this.GLctx['depthRange'](x0, x1);
  }

  private _emscripten_glDetachShader(
    program: string | number,
    shader: string | number
  ) {
    this.GLctx.detachShader(this.GL.programs[program], this.GL.shaders[shader]);
  }

  private _emscripten_glDisable(x0: any) {
    this.GLctx['disable'](x0);
  }

  private _emscripten_glDisableVertexAttribArray(index: any) {
    this.GLctx.disableVertexAttribArray(index);
  }

  private _emscripten_glDrawArrays(mode: any, first: any, count: any) {
    this.GLctx.drawArrays(mode, first, count);
  }

  private _emscripten_glDrawArraysInstancedANGLE(
    mode: any,
    first: any,
    count: any,
    primcount: any
  ) {
    this.GLctx['drawArraysInstanced'](mode, first, count, primcount);
  }

  private tempFixedLengthArray: any = [];
  private _emscripten_glDrawBuffersWEBGL(n: number, bufs: number) {
    let bufArray = this.tempFixedLengthArray[n];
    for (let i = 0; i < n; i++) {
      bufArray[i] = this.HEAP32[(bufs + i * 4) >> 2];
    }

    this.GLctx['drawBuffers'](bufArray);
  }

  private _emscripten_glDrawElements(
    mode: any,
    count: any,
    type: any,
    indices: any
  ) {
    this.GLctx.drawElements(mode, count, type, indices);
  }

  private _emscripten_glDrawElementsInstancedANGLE(
    mode: any,
    count: any,
    type: any,
    indices: any,
    primcount: any
  ) {
    this.GLctx['drawElementsInstanced'](mode, count, type, indices, primcount);
  }

  private _emscripten_glEnable(x0: any) {
    this.GLctx['enable'](x0);
  }

  private _emscripten_glEnableVertexAttribArray(index: any) {
    this.GLctx.enableVertexAttribArray(index);
  }

  private _emscripten_glEndQueryEXT(target: any) {
    this.GLctx.disjointTimerQueryExt['endQueryEXT'](target);
  }

  private _emscripten_glFinish() {
    this.GLctx['finish']();
  }

  private _emscripten_glFlush() {
    this.GLctx['flush']();
  }

  private _emscripten_glFramebufferRenderbuffer(
    target: any,
    attachment: any,
    renderbuffertarget: any,
    renderbuffer: string | number
  ) {
    this.GLctx.framebufferRenderbuffer(
      target,
      attachment,
      renderbuffertarget,
      this.GL.renderbuffers[renderbuffer]
    );
  }

  private _emscripten_glFramebufferTexture2D(
    target: any,
    attachment: any,
    textarget: any,
    texture: string | number,
    level: any
  ) {
    this.GLctx.framebufferTexture2D(
      target,
      attachment,
      textarget,
      this.GL.textures[texture],
      level
    );
  }

  private _emscripten_glFrontFace(x0: any) {
    this.GLctx['frontFace'](x0);
  }

  private __glGenObject(
    n: number,
    buffers: number,
    createFunction: string,
    objectTable: { [x: string]: any }
  ) {
    for (let i = 0; i < n; i++) {
      let buffer = this.GLctx[createFunction]();
      let id = buffer && this.GL.getNewId(objectTable);
      if (buffer) {
        buffer.name = id;
        objectTable[id] = buffer;
      } else {
        this.GL.recordError(0x502 /* GL_INVALID_OPERATION */);
      }
      this.HEAP32[(buffers + i * 4) >> 2] = id;
    }
  }
  private _emscripten_glGenBuffers(n: any, buffers: any) {
    this.__glGenObject(n, buffers, 'createBuffer', this.GL.buffers);
  }

  private _emscripten_glGenFramebuffers(n: any, ids: any) {
    this.__glGenObject(n, ids, 'createFramebuffer', this.GL.framebuffers);
  }

  private _emscripten_glGenQueriesEXT(n: number, ids: number) {
    for (let i = 0; i < n; i++) {
      let query = this.GLctx.disjointTimerQueryExt['createQueryEXT']();
      if (!query) {
        this.GL.recordError(0x502 /* GL_INVALID_OPERATION */);
        while (i < n) this.HEAP32[(ids + i++ * 4) >> 2] = 0;
        return;
      }
      let id = this.GL.getNewId(this.GL.queries);
      query.name = id;
      this.GL.queries[id] = query;
      this.HEAP32[(ids + i * 4) >> 2] = id;
    }
  }

  private _emscripten_glGenRenderbuffers(n: any, renderbuffers: any) {
    this.__glGenObject(
      n,
      renderbuffers,
      'createRenderbuffer',
      this.GL.renderbuffers
    );
  }

  private _emscripten_glGenTextures(n: any, textures: any) {
    this.__glGenObject(n, textures, 'createTexture', this.GL.textures);
  }

  private _emscripten_glGenVertexArraysOES(n: any, arrays: any) {
    this.__glGenObject(n, arrays, 'createVertexArray', this.GL.vaos);
  }

  private _emscripten_glGenerateMipmap(x0: any) {
    this.GLctx['generateMipmap'](x0);
  }

  private __glGetActiveAttribOrUniform(
    funcName: string,
    program: string | number,
    index: any,
    bufSize: any,
    length: number,
    size: number,
    type: number,
    name: any
  ) {
    program = this.GL.programs[program];
    let info = this.GLctx[funcName](program, index);
    if (info) {
      // If an error occurs, nothing will be written to length, size and type and name.
      let numBytesWrittenExclNull =
        name && this.stringToUTF8(info.name, name, bufSize);
      if (length) this.HEAP32[length >> 2] = numBytesWrittenExclNull;
      if (size) this.HEAP32[size >> 2] = info.size;
      if (type) this.HEAP32[type >> 2] = info.type;
    }
  }
  private _emscripten_glGetActiveAttrib(
    program: any,
    index: any,
    bufSize: any,
    length: any,
    size: any,
    type: any,
    name: any
  ) {
    this.__glGetActiveAttribOrUniform(
      'getActiveAttrib',
      program,
      index,
      bufSize,
      length,
      size,
      type,
      name
    );
  }

  private _emscripten_glGetActiveUniform(
    program: any,
    index: any,
    bufSize: any,
    length: any,
    size: any,
    type: any,
    name: any
  ) {
    this.__glGetActiveAttribOrUniform(
      'getActiveUniform',
      program,
      index,
      bufSize,
      length,
      size,
      type,
      name
    );
  }

  private _emscripten_glGetAttachedShaders(
    program: string | number,
    maxCount: number,
    count: number,
    shaders: number
  ) {
    let result = this.GLctx.getAttachedShaders(this.GL.programs[program]);
    let len = result.length;
    if (len > maxCount) {
      len = maxCount;
    }
    this.HEAP32[count >> 2] = len;
    for (let i = 0; i < len; ++i) {
      let id = this.GL.shaders.indexOf(result[i]);
      this.HEAP32[(shaders + i * 4) >> 2] = id;
    }
  }

  private _emscripten_glGetAttribLocation(program: string | number, name: any) {
    return this.GLctx.getAttribLocation(
      this.GL.programs[program],
      this.UTF8ToString(name)
    );
  }

  private readI53FromU64(ptr: number) {
    return this.HEAPU32[ptr >> 2] + this.HEAPU32[(ptr + 4) >> 2] * 4294967296;
  }
  private ptrToString(ptr: number) {
    return '0x' + ptr.toString(16).padStart(8, '0');
  }
  private writeI53ToI64(ptr: number, num: number) {
    this.HEAPU32[ptr >> 2] = num;
    this.HEAPU32[(ptr + 4) >> 2] = (num - this.HEAPU32[ptr >> 2]) / 4294967296;
    let deserialized = num >= 0 ? this.readI53FromU64(ptr) : ptr;
    if (deserialized != num)
      this.warnOnce(
        'writeI53ToI64() out of range: serialized JS Number ' +
          num +
          ' to Wasm heap as bytes lo=' +
          this.ptrToString(this.HEAPU32[ptr >> 2]) +
          ', hi=' +
          this.ptrToString(this.HEAPU32[(ptr + 4) >> 2]) +
          ', which deserializes back to ' +
          deserialized +
          ' instead!'
      );
  }
  private emscriptenWebGLGet(name_: number, p: number, type: string | number) {
    // Guard against user passing a null pointer.
    // Note that GLES2 spec does not say anything about how passing a null pointer should be treated.
    // Testing on desktop core GL 3, the application crashes on glGetIntegerv to a null pointer, but
    // better to report an error instead of doing anything random.
    if (!p) {
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
      return;
    }
    let ret: any = undefined;
    switch (
      name_ // Handle a few trivial GLES values
    ) {
      case 0x8dfa: // GL_SHADER_COMPILER
        ret = 1;
        break;
      case 0x8df8: // GL_SHADER_BINARY_FORMATS
        if (type != 0 && type != 1) {
          this.GL.recordError(0x500); // GL_INVALID_ENUM
        }
        return; // Do not write anything to the out pointer, since no binary formats are supported.
      case 0x8df9: // GL_NUM_SHADER_BINARY_FORMATS
        ret = 0;
        break;
      case 0x86a2: // GL_NUM_COMPRESSED_TEXTURE_FORMATS
        // WebGL doesn't have GL_NUM_COMPRESSED_TEXTURE_FORMATS (it's obsolete since GL_COMPRESSED_TEXTURE_FORMATS returns a JS array that can be queried for length),
        // so implement it ourselves to allow C++ GLES2 code get the length.
        let formats = this.GLctx.getParameter(
          0x86a3 /*GL_COMPRESSED_TEXTURE_FORMATS*/
        );
        ret = formats ? formats.length : 0;
        break;
    }

    if (ret === undefined) {
      let result = this.GLctx.getParameter(name_);
      switch (typeof result) {
        case 'number':
          ret = result;
          break;
        case 'boolean':
          ret = result ? 1 : 0;
          break;
        case 'string':
          this.GL.recordError(0x500); // GL_INVALID_ENUM
          return;
        case 'object':
          if (result === null) {
            // null is a valid result for some (e.g., which buffer is bound - perhaps nothing is bound), but otherwise
            // can mean an invalid name_, which we need to report as an error
            switch (name_) {
              case 0x8894: // ARRAY_BUFFER_BINDING
              case 0x8b8d: // CURRENT_PROGRAM
              case 0x8895: // ELEMENT_ARRAY_BUFFER_BINDING
              case 0x8ca6: // FRAMEBUFFER_BINDING or DRAW_FRAMEBUFFER_BINDING
              case 0x8ca7: // RENDERBUFFER_BINDING
              case 0x8069: // TEXTURE_BINDING_2D
              case 0x85b5: // WebGL 2 GL_VERTEX_ARRAY_BINDING, or WebGL 1 extension OES_vertex_array_object GL_VERTEX_ARRAY_BINDING_OES
              case 0x8514: {
                // TEXTURE_BINDING_CUBE_MAP
                ret = 0;
                break;
              }
              default: {
                this.GL.recordError(0x500); // GL_INVALID_ENUM
                return;
              }
            }
          } else if (
            result instanceof Float32Array ||
            result instanceof Uint32Array ||
            result instanceof Int32Array ||
            result instanceof Array
          ) {
            for (let i = 0; i < result.length; ++i) {
              switch (type) {
                case 0:
                  this.HEAP32[(p + i * 4) >> 2] = result[i];
                  break;
                case 2:
                  this.HEAPF32[(p + i * 4) >> 2] = result[i];
                  break;
                case 4:
                  this.HEAP8[(p + i) >> 0] = result[i] ? 1 : 0;
                  break;
              }
            }
            return;
          } else {
            try {
              ret = result.name | 0;
            } catch (e: any) {
              this.GL.recordError(0x500); // GL_INVALID_ENUM
              this.err(
                'GL_INVALID_ENUM in glGet' +
                  type +
                  'v: Unknown object returned from WebGL getParameter(' +
                  name_ +
                  ')! (error: ' +
                  e +
                  ')'
              );
              return;
            }
          }
          break;
        default: // GL_INVALID_ENUM
          this.GL.recordError(0x500);
          this.err(
            'GL_INVALID_ENUM in glGet' +
              type +
              'v: Native code calling glGet' +
              type +
              'v(' +
              name_ +
              ') and it returns ' +
              result +
              ' of type ' +
              typeof result +
              '!'
          );
          return;
      }
    }

    switch (type) {
      case 1:
        this.writeI53ToI64(p, ret);
        break;
      case 0:
        this.HEAP32[p >> 2] = ret;
        break;
      case 2:
        this.HEAPF32[p >> 2] = ret;
        break;
      case 4:
        this.HEAP8[p >> 0] = ret ? 1 : 0;
        break;
    }
  }
  private _emscripten_glGetBooleanv(name_: any, p: any) {
    this.emscriptenWebGLGet(name_, p, 4);
  }

  private _emscripten_glGetBufferParameteriv(
    target: any,
    value: any,
    data: number
  ) {
    if (!data) {
      // GLES2 specification does not specify how to behave if data is a null pointer. Since calling this private does not make sense
      // if data == null, issue a GL error to notify user about it.
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
      return;
    }
    this.HEAP32[data >> 2] = this.GLctx.getBufferParameter(target, value);
  }

  private _emscripten_glGetError() {
    let error = this.GLctx.getError() || this.GL.lastError;
    this.GL.lastError = 0 /*GL_NO_ERROR*/;
    return error;
  }

  private _emscripten_glGetFloatv(name_: any, p: any) {
    this.emscriptenWebGLGet(name_, p, 2);
  }

  private _emscripten_glGetFramebufferAttachmentParameteriv(
    target: any,
    attachment: any,
    pname: any,
    params: number
  ) {
    let result: any = this.GLctx.getFramebufferAttachmentParameter(
      target,
      attachment,
      pname
    );
    if (result instanceof WebGLRenderbuffer || result instanceof WebGLTexture) {
      result = 0;
    }
    this.HEAP32[params >> 2] = result;
  }

  private _emscripten_glGetIntegerv(name_: any, p: any) {
    this.emscriptenWebGLGet(name_, p, 0);
  }

  private _emscripten_glGetProgramInfoLog(
    program: string | number,
    maxLength: number,
    length: number,
    infoLog: any
  ) {
    let log = this.GLctx.getProgramInfoLog(this.GL.programs[program]);
    if (log === null) log = '(unknown error)';
    let numBytesWrittenExclNull =
      maxLength > 0 && infoLog ? this.stringToUTF8(log, infoLog, maxLength) : 0;
    if (length) this.HEAP32[length >> 2] = numBytesWrittenExclNull;
  }

  private _emscripten_glGetProgramiv(program: any, pname: number, p: number) {
    if (!p) {
      // GLES2 specification does not specify how to behave if p is a null pointer. Since calling this private does not make sense
      // if p == null, issue a GL error to notify user about it.
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
      return;
    }

    if (program >= this.GL.counter) {
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
      return;
    }

    program = this.GL.programs[program];

    if (pname == 0x8b84) {
      // GL_INFO_LOG_LENGTH
      let log = this.GLctx.getProgramInfoLog(program);
      if (log === null) log = '(unknown error)';
      this.HEAP32[p >> 2] = log.length + 1;
    } else if (pname == 0x8b87 /* GL_ACTIVE_UNIFORM_MAX_LENGTH */) {
      if (!program.maxUniformLength) {
        for (
          let i = 0;
          i <
          this.GLctx.getProgramParameter(
            program,
            0x8b86 /*GL_ACTIVE_UNIFORMS*/
          );
          ++i
        ) {
          program.maxUniformLength = Math.max(
            program.maxUniformLength,
            this.GLctx.getActiveUniform(program, i).name.length + 1
          );
        }
      }
      this.HEAP32[p >> 2] = program.maxUniformLength;
    } else if (pname == 0x8b8a /* GL_ACTIVE_ATTRIBUTE_MAX_LENGTH */) {
      if (!program.maxAttributeLength) {
        for (
          let i = 0;
          i <
          this.GLctx.getProgramParameter(
            program,
            0x8b89 /*GL_ACTIVE_ATTRIBUTES*/
          );
          ++i
        ) {
          program.maxAttributeLength = Math.max(
            program.maxAttributeLength,
            this.GLctx.getActiveAttrib(program, i).name.length + 1
          );
        }
      }
      this.HEAP32[p >> 2] = program.maxAttributeLength;
    } else if (pname == 0x8a35 /* GL_ACTIVE_UNIFORM_BLOCK_MAX_NAME_LENGTH */) {
      if (!program.maxUniformBlockNameLength) {
        for (
          let i = 0;
          i <
          this.GLctx.getProgramParameter(
            program,
            0x8a36 /*GL_ACTIVE_UNIFORM_BLOCKS*/
          );
          ++i
        ) {
          program.maxUniformBlockNameLength = Math.max(
            program.maxUniformBlockNameLength,
            this.GLctx.getActiveUniformBlockName(program, i).length + 1
          );
        }
      }
      this.HEAP32[p >> 2] = program.maxUniformBlockNameLength;
    } else {
      this.HEAP32[p >> 2] = this.GLctx.getProgramParameter(program, pname);
    }
  }

  private _emscripten_glGetQueryObjecti64vEXT(
    id: string | number,
    pname: any,
    params: any
  ) {
    if (!params) {
      // GLES2 specification does not specify how to behave if params is a null pointer. Since calling this private does not make sense
      // if p == null, issue a GL error to notify user about it.
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
      return;
    }
    let query = this.GL.queries[id];
    let param;
    {
      param = this.GLctx.disjointTimerQueryExt['getQueryObjectEXT'](
        query,
        pname
      );
    }
    let ret;
    if (typeof param == 'boolean') {
      ret = param ? 1 : 0;
    } else {
      ret = param;
    }
    this.writeI53ToI64(params, ret);
  }

  private _emscripten_glGetQueryObjectivEXT(
    id: string | number,
    pname: any,
    params: number
  ) {
    if (!params) {
      // GLES2 specification does not specify how to behave if params is a null pointer. Since calling this private does not make sense
      // if p == null, issue a GL error to notify user about it.
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
      return;
    }
    let query = this.GL.queries[id];
    let param = this.GLctx.disjointTimerQueryExt['getQueryObjectEXT'](
      query,
      pname
    );
    let ret;
    if (typeof param == 'boolean') {
      ret = param ? 1 : 0;
    } else {
      ret = param;
    }
    this.HEAP32[params >> 2] = ret;
  }

  private _emscripten_glGetQueryObjectui64vEXT(
    id: string | number,
    pname: any,
    params: any
  ) {
    if (!params) {
      // GLES2 specification does not specify how to behave if params is a null pointer. Since calling this private does not make sense
      // if p == null, issue a GL error to notify user about it.
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
      return;
    }
    let query = this.GL.queries[id];
    let param;
    {
      param = this.GLctx.disjointTimerQueryExt['getQueryObjectEXT'](
        query,
        pname
      );
    }
    let ret;
    if (typeof param == 'boolean') {
      ret = param ? 1 : 0;
    } else {
      ret = param;
    }
    this.writeI53ToI64(params, ret);
  }

  private _emscripten_glGetQueryObjectuivEXT(
    id: string | number,
    pname: any,
    params: number
  ) {
    if (!params) {
      // GLES2 specification does not specify how to behave if params is a null pointer. Since calling this private does not make sense
      // if p == null, issue a GL error to notify user about it.
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
      return;
    }
    let query = this.GL.queries[id];
    let param = this.GLctx.disjointTimerQueryExt['getQueryObjectEXT'](
      query,
      pname
    );
    let ret;
    if (typeof param == 'boolean') {
      ret = param ? 1 : 0;
    } else {
      ret = param;
    }
    this.HEAP32[params >> 2] = ret;
  }

  private _emscripten_glGetQueryivEXT(target: any, pname: any, params: number) {
    if (!params) {
      // GLES2 specification does not specify how to behave if params is a null pointer. Since calling this private does not make sense
      // if p == null, issue a GL error to notify user about it.
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
      return;
    }
    this.HEAP32[params >> 2] = this.GLctx.disjointTimerQueryExt['getQueryEXT'](
      target,
      pname
    );
  }

  private _emscripten_glGetRenderbufferParameteriv(
    target: any,
    pname: any,
    params: number
  ) {
    if (!params) {
      // GLES2 specification does not specify how to behave if params is a null pointer. Since calling this private does not make sense
      // if params == null, issue a GL error to notify user about it.
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
      return;
    }
    this.HEAP32[params >> 2] = this.GLctx.getRenderbufferParameter(
      target,
      pname
    );
  }

  private _emscripten_glGetShaderInfoLog(
    shader: string | number,
    maxLength: number,
    length: number,
    infoLog: any
  ) {
    let log = this.GLctx.getShaderInfoLog(this.GL.shaders[shader]);
    if (log === null) log = '(unknown error)';
    let numBytesWrittenExclNull =
      maxLength > 0 && infoLog ? this.stringToUTF8(log, infoLog, maxLength) : 0;
    if (length) this.HEAP32[length >> 2] = numBytesWrittenExclNull;
  }

  private _emscripten_glGetShaderPrecisionFormat(
    shaderType: any,
    precisionType: any,
    range: number,
    precision: number
  ) {
    let result = this.GLctx.getShaderPrecisionFormat(shaderType, precisionType);
    this.HEAP32[range >> 2] = result.rangeMin;
    this.HEAP32[(range + 4) >> 2] = result.rangeMax;
    this.HEAP32[precision >> 2] = result.precision;
  }

  private _emscripten_glGetShaderSource(
    shader: string | number,
    bufSize: number,
    length: number,
    source: any
  ) {
    let result = this.GLctx.getShaderSource(this.GL.shaders[shader]);
    if (!result) return; // If an error occurs, nothing will be written to length or source.
    let numBytesWrittenExclNull =
      bufSize > 0 && source ? this.stringToUTF8(result, source, bufSize) : 0;
    if (length) this.HEAP32[length >> 2] = numBytesWrittenExclNull;
  }

  private _emscripten_glGetShaderiv(
    shader: string | number,
    pname: number,
    p: number
  ) {
    if (!p) {
      // GLES2 specification does not specify how to behave if p is a null pointer. Since calling this private does not make sense
      // if p == null, issue a GL error to notify user about it.
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
      return;
    }
    if (pname == 0x8b84) {
      // GL_INFO_LOG_LENGTH
      let log = this.GLctx.getShaderInfoLog(this.GL.shaders[shader]);
      if (log === null) log = '(unknown error)';
      // The GLES2 specification says that if the shader has an empty info log,
      // a value of 0 is returned. Otherwise the log has a null char appended.
      // (An empty string is falsey, so we can just check that instead of
      // looking at log.length.)
      let logLength = log ? log.length + 1 : 0;
      this.HEAP32[p >> 2] = logLength;
    } else if (pname == 0x8b88) {
      // GL_SHADER_SOURCE_LENGTH
      let source = this.GLctx.getShaderSource(this.GL.shaders[shader]);
      // source may be a null, or the empty string, both of which are falsey
      // values that we report a 0 length for.
      let sourceLength = source ? source.length + 1 : 0;
      this.HEAP32[p >> 2] = sourceLength;
    } else {
      this.HEAP32[p >> 2] = this.GLctx.getShaderParameter(
        this.GL.shaders[shader],
        pname
      );
    }
  }

  private _malloc: any = this.createExportWrapper('malloc');

  private stringToNewUTF8(jsString: any) {
    let length = this.lengthBytesUTF8(jsString) + 1;
    let cString = this._malloc(length);
    this.stringToUTF8(jsString, cString, length);
    return cString;
  }
  private _emscripten_glGetString(name_: string | number) {
    let ret = this.GL.stringCache[name_];
    if (!ret) {
      switch (name_) {
        case 0x1f03 /* GL_EXTENSIONS */:
          let exts = this.GLctx.getSupportedExtensions() || []; // .getSupportedExtensions() can return null if context is lost, so coerce to empty array.
          exts = exts.concat(
            exts.map((e: string) => {
              return 'GL_' + e;
            })
          );
          ret = this.stringToNewUTF8(exts.join(' '));
          break;
        case 0x1f00 /* GL_VENDOR */:
        case 0x1f01 /* GL_RENDERER */:
        case 0x9245 /* UNMASKED_VENDOR_WEBGL */:
        case 0x9246 /* UNMASKED_RENDERER_WEBGL */:
          let s = this.GLctx.getParameter(name_);
          if (!s) {
            this.GL.recordError(0x500 /*GL_INVALID_ENUM*/);
          }
          ret = s && this.stringToNewUTF8(s);
          break;

        case 0x1f02 /* GL_VERSION */:
          let glVersion = this.GLctx.getParameter(0x1f02 /*GL_VERSION*/);
          // return GLES version string corresponding to the version of the WebGL context
          {
            glVersion = 'OpenGL ES 2.0 (' + glVersion + ')';
          }
          ret = this.stringToNewUTF8(glVersion);
          break;
        case 0x8b8c /* GL_SHADING_LANGUAGE_VERSION */:
          let glslVersion = this.GLctx.getParameter(
            0x8b8c /*GL_SHADING_LANGUAGE_VERSION*/
          );
          // extract the version number 'N.M' from the string 'WebGL GLSL ES N.M ...'
          let ver_re = /^WebGL GLSL ES ([0-9]\.[0-9][0-9]?)(?:$| .*)/;
          let ver_num = glslVersion.match(ver_re);
          if (ver_num !== null) {
            if (ver_num[1].length == 3) ver_num[1] = ver_num[1] + '0'; // ensure minor version has 2 digits
            glslVersion =
              'OpenGL ES GLSL ES ' + ver_num[1] + ' (' + glslVersion + ')';
          }
          ret = this.stringToNewUTF8(glslVersion);
          break;
        default:
          this.GL.recordError(0x500 /*GL_INVALID_ENUM*/);
        // fall through
      }
      this.GL.stringCache[name_] = ret;
    }
    return ret;
  }

  private _emscripten_glGetTexParameterfv(
    target: any,
    pname: any,
    params: number
  ) {
    if (!params) {
      // GLES2 specification does not specify how to behave if params is a null pointer. Since calling this private does not make sense
      // if p == null, issue a GL error to notify user about it.
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
      return;
    }
    this.HEAPF32[params >> 2] = this.GLctx.getTexParameter(target, pname);
  }

  private _emscripten_glGetTexParameteriv(
    target: any,
    pname: any,
    params: number
  ) {
    if (!params) {
      // GLES2 specification does not specify how to behave if params is a null pointer. Since calling this private does not make sense
      // if p == null, issue a GL error to notify user about it.
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
      return;
    }
    this.HEAP32[params >> 2] = this.GLctx.getTexParameter(target, pname);
  }

  /** @noinline */
  private webglGetLeftBracePos(name: string) {
    return name.slice(-1) == ']' && name.lastIndexOf('[');
  }
  private webglPrepareUniformLocationsBeforeFirstUse(program: any) {
    let uniformLocsById = program.uniformLocsById, // Maps GLuint -> WebGLUniformLocation
      uniformSizeAndIdsByName = program.uniformSizeAndIdsByName, // Maps name -> [uniform array length, GLuint]
      i,
      j;

    // On the first time invocation of glGetUniformLocation on this shader program:
    // initialize cache data structures and discover which uniforms are arrays.
    if (!uniformLocsById) {
      // maps GLint integer locations to WebGLUniformLocations
      program.uniformLocsById = uniformLocsById = {};
      // maps integer locations back to uniform name strings, so that we can lazily fetch uniform array locations
      program.uniformArrayNamesById = {};

      for (
        i = 0;
        i <
        this.GLctx.getProgramParameter(program, 0x8b86 /*GL_ACTIVE_UNIFORMS*/);
        ++i
      ) {
        let u = this.GLctx.getActiveUniform(program, i);
        let nm = u.name;
        let sz = u.size;
        let lb = this.webglGetLeftBracePos(nm);
        let arrayName = lb > 0 ? nm.slice(0, lb) : nm;

        // Assign a new location.
        let id = program.uniformIdCounter;
        program.uniformIdCounter += sz;
        // Eagerly get the location of the uniformArray[0] base element.
        // The remaining indices >0 will be left for lazy evaluation to
        // improve performance. Those may never be needed to fetch, if the
        // application fills arrays always in full starting from the first
        // element of the array.
        uniformSizeAndIdsByName[arrayName] = [sz, id];

        // Store placeholder integers in place that highlight that these
        // >0 index locations are array indices pending population.
        for (j = 0; j < sz; ++j) {
          uniformLocsById[id] = j;
          program.uniformArrayNamesById[id++] = arrayName;
        }
      }
    }
  }
  private _emscripten_glGetUniformLocation(program: any, name: string | any[]) {
    name = this.UTF8ToString(name);

    if ((program = this.GL.programs[program])) {
      this.webglPrepareUniformLocationsBeforeFirstUse(program);
      let uniformLocsById = program.uniformLocsById; // Maps GLuint -> WebGLUniformLocation
      let arrayIndex = 0;
      let uniformBaseName = name;

      // Invariant: when populating integer IDs for uniform locations, we must maintain the precondition that
      // arrays reside in contiguous addresses, i.e. for a 'vec4 colors[10];', colors[4] must be at location colors[0]+4.
      // However, user might call glGetUniformLocation(program, "colors") for an array, so we cannot discover based on the user
      // input arguments whether the uniform we are dealing with is an array. The only way to discover which uniforms are arrays
      // is to enumerate over all the active uniforms in the program.
      let leftBrace = this.webglGetLeftBracePos(name) as number;

      // If user passed an array accessor "[index]", parse the array index off the accessor.
      if (leftBrace > 0) {
        arrayIndex = this.jstoi_q(name.slice(leftBrace + 1)) >>> 0; // "index]", coerce parseInt(']') with >>>0 to treat "foo[]" as "foo[0]" and foo[-1] as unsigned out-of-bounds.
        uniformBaseName = name.slice(0, leftBrace);
      }

      // Have we cached the location of this uniform before?
      let sizeAndId = program.uniformSizeAndIdsByName[uniformBaseName]; // A pair [array length, GLint of the uniform location]

      // If an uniform with this name exists, and if its index is within the array limits (if it's even an array),
      // query the WebGLlocation, or return an existing cached location.
      if (sizeAndId && arrayIndex < sizeAndId[0]) {
        arrayIndex += sizeAndId[1]; // Add the base location of the uniform to the array index offset.
        if (
          (uniformLocsById[arrayIndex] =
            uniformLocsById[arrayIndex] ||
            this.GLctx.getUniformLocation(program, name))
        ) {
          return arrayIndex;
        }
      }
    } else {
      // N.b. we are currently unable to distinguish between GL program IDs that never existed vs GL program IDs that have been deleted,
      // so report GL_INVALID_VALUE in both cases.
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
    }
    return -1;
  }

  private webglGetUniformLocation(location: string | number) {
    let p = this.GLctx.currentProgram;

    if (p) {
      let webglLoc = p.uniformLocsById[location];
      // p.uniformLocsById[location] stores either an integer, or a WebGLUniformLocation.

      // If an integer, we have not yet bound the location, so do it now. The integer value specifies the array index
      // we should bind to.
      if (typeof webglLoc == 'number') {
        p.uniformLocsById[location] = webglLoc = this.GLctx.getUniformLocation(
          p,
          p.uniformArrayNamesById[location] +
            (webglLoc > 0 ? '[' + webglLoc + ']' : '')
        );
      }
      // Else an already cached WebGLUniformLocation, return it.
      return webglLoc;
    } else {
      this.GL.recordError(0x502 /*GL_INVALID_OPERATION*/);
    }
  }
  /** @suppress{checkTypes} */
  private emscriptenWebGLGetUniform(
    program: string | number,
    location: any,
    params: number,
    type: number
  ) {
    if (!params) {
      // GLES2 specification does not specify how to behave if params is a null pointer. Since calling this private does not make sense
      // if params == null, issue a GL error to notify user about it.
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
      return;
    }
    program = this.GL.programs[program];
    this.webglPrepareUniformLocationsBeforeFirstUse(program);
    let data = this.GLctx.getUniform(
      program,
      this.webglGetUniformLocation(location)
    );
    if (typeof data == 'number' || typeof data == 'boolean') {
      switch (type) {
        case 0:
          this.HEAP32[params >> 2] = data;
          break;
        case 2:
          this.HEAPF32[params >> 2] = data;
          break;
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        switch (type) {
          case 0:
            this.HEAP32[(params + i * 4) >> 2] = data[i];
            break;
          case 2:
            this.HEAPF32[(params + i * 4) >> 2] = data[i];
            break;
        }
      }
    }
  }
  private _emscripten_glGetUniformfv(program: any, location: any, params: any) {
    this.emscriptenWebGLGetUniform(program, location, params, 2);
  }

  private _emscripten_glGetUniformiv(program: any, location: any, params: any) {
    this.emscriptenWebGLGetUniform(program, location, params, 0);
  }

  private _emscripten_glGetVertexAttribPointerv(
    index: any,
    pname: any,
    pointer: number
  ) {
    if (!pointer) {
      // GLES2 specification does not specify how to behave if pointer is a null pointer. Since calling this private does not make sense
      // if pointer == null, issue a GL error to notify user about it.
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
      return;
    }
    this.HEAP32[pointer >> 2] = this.GLctx.getVertexAttribOffset(index, pname);
  }

  /** @suppress{checkTypes} */
  private emscriptenWebGLGetVertexAttrib(
    index: any,
    pname: number,
    params: number,
    type: number
  ) {
    if (!params) {
      // GLES2 specification does not specify how to behave if params is a null pointer. Since calling this private does not make sense
      // if params == null, issue a GL error to notify user about it.
      this.GL.recordError(0x501 /* GL_INVALID_VALUE */);
      return;
    }
    let data = this.GLctx.getVertexAttrib(index, pname);
    if (pname == 0x889f /*VERTEX_ATTRIB_ARRAY_BUFFER_BINDING*/) {
      this.HEAP32[params >> 2] = data && data['name'];
    } else if (typeof data == 'number' || typeof data == 'boolean') {
      switch (type) {
        case 0:
          this.HEAP32[params >> 2] = data;
          break;
        case 2:
          this.HEAPF32[params >> 2] = data;
          break;
        case 5:
          this.HEAP32[params >> 2] = Math.fround(data as number);
          break;
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        switch (type) {
          case 0:
            this.HEAP32[(params + i * 4) >> 2] = data[i];
            break;
          case 2:
            this.HEAPF32[(params + i * 4) >> 2] = data[i];
            break;
          case 5:
            this.HEAP32[(params + i * 4) >> 2] = Math.fround(data[i]);
            break;
        }
      }
    }
  }
  private _emscripten_glGetVertexAttribfv(index: any, pname: any, params: any) {
    // N.B. This private may only be called if the vertex attribute was specified using the private glVertexAttrib*f(),
    // otherwise the results are undefined. (GLES3 spec 6.1.12)
    this.emscriptenWebGLGetVertexAttrib(index, pname, params, 2);
  }

  private _emscripten_glGetVertexAttribiv(index: any, pname: any, params: any) {
    // N.B. This private may only be called if the vertex attribute was specified using the private glVertexAttrib*f(),
    // otherwise the results are undefined. (GLES3 spec 6.1.12)
    this.emscriptenWebGLGetVertexAttrib(index, pname, params, 5);
  }

  private _emscripten_glHint(x0: any, x1: any) {
    this.GLctx['hint'](x0, x1);
  }

  private _emscripten_glIsBuffer(buffer: string | number) {
    let b = this.GL.buffers[buffer];
    if (!b) return 0;
    return this.GLctx.isBuffer(b);
  }

  private _emscripten_glIsEnabled(x0: any) {
    return this.GLctx['isEnabled'](x0);
  }

  private _emscripten_glIsFramebuffer(framebuffer: string | number) {
    let fb = this.GL.framebuffers[framebuffer];
    if (!fb) return 0;
    return this.GLctx.isFramebuffer(fb);
  }

  private _emscripten_glIsProgram(program: string | number) {
    program = this.GL.programs[program];
    if (!program) return 0;
    return this.GLctx.isProgram(program);
  }

  private _emscripten_glIsQueryEXT(id: string | number) {
    let query = this.GL.queries[id];
    if (!query) return 0;
    return this.GLctx.disjointTimerQueryExt['isQueryEXT'](query);
  }

  private _emscripten_glIsRenderbuffer(renderbuffer: string | number) {
    let rb = this.GL.renderbuffers[renderbuffer];
    if (!rb) return 0;
    return this.GLctx.isRenderbuffer(rb);
  }

  private _emscripten_glIsShader(shader: string | number) {
    let s = this.GL.shaders[shader];
    if (!s) return 0;
    return this.GLctx.isShader(s);
  }

  private _emscripten_glIsTexture(id: string | number) {
    let texture = this.GL.textures[id];
    if (!texture) return 0;
    return this.GLctx.isTexture(texture);
  }

  private _emscripten_glIsVertexArrayOES(array: string | number) {
    let vao = this.GL.vaos[array];
    if (!vao) return 0;
    return this.GLctx['isVertexArray'](vao);
  }

  private _emscripten_glLineWidth(x0: any) {
    this.GLctx['lineWidth'](x0);
  }

  private _emscripten_glLinkProgram(program: any) {
    program = this.GL.programs[program];
    this.GLctx.linkProgram(program);
    // Invalidate earlier computed uniform->ID mappings, those have now become stale
    program.uniformLocsById = 0; // Mark as null-like so that glGetUniformLocation() knows to populate this again.
    program.uniformSizeAndIdsByName = {};
  }

  private _emscripten_glPixelStorei(pname: number, param: any) {
    if (pname == 0xcf5 /* GL_UNPACK_ALIGNMENT */) {
      this.GL.unpackAlignment = param;
    }
    this.GLctx.pixelStorei(pname, param);
  }

  private _emscripten_glPolygonOffset(x0: any, x1: any) {
    this.GLctx['polygonOffset'](x0, x1);
  }

  private _emscripten_glQueryCounterEXT(id: string | number, target: any) {
    this.GLctx.disjointTimerQueryExt['queryCounterEXT'](
      this.GL.queries[id],
      target
    );
  }

  private computeUnpackAlignedImageSize(
    width: number,
    height: number,
    sizePerPixel: number,
    alignment: any
  ) {
    const roundedToNextMultipleOf = (x: number, y: number) => {
      return (x + y - 1) & -y;
    };
    let plainRowSize = width * sizePerPixel;
    let alignedRowSize = roundedToNextMultipleOf(plainRowSize, alignment);
    return height * alignedRowSize;
  }

  private __colorChannelsInGlTextureFormat(format: number) {
    // Micro-optimizations for size: map format to size by subtracting smallest enum value (0x1902) from all values first.
    // Also omit the most common size value (1) from the list, which is assumed by formats not on the list.
    let colorChannels: any = {
      // 0x1902 /* GL_DEPTH_COMPONENT */ - 0x1902: 1,
      // 0x1906 /* GL_ALPHA */ - 0x1902: 1,
      5: 3,
      6: 4,
      // 0x1909 /* GL_LUMINANCE */ - 0x1902: 1,
      8: 2,
      29502: 3,
      29504: 4,
    };
    return colorChannels[format - 0x1902] || 1;
  }

  private heapObjectForWebGLType(type: number) {
    // Micro-optimization for size: Subtract lowest GL enum number (0x1400/* GL_BYTE */) from type to compare
    // smaller values for the heap, for shorter generated code size.
    // Also the type HEAPU16 is not tested for explicitly, but any unrecognized type will return out HEAPU16.
    // (since most types are HEAPU16)
    type -= 0x1400;

    if (type == 1) return this.HEAPU8;

    if (type == 4) return this.HEAP32;

    if (type == 6) return this.HEAPF32;

    if (type == 5 || type == 28922) return this.HEAPU32;

    return this.HEAPU16;
  }

  private heapAccessShiftForWebGLHeap(heap: { BYTES_PER_ELEMENT: number }) {
    return 31 - Math.clz32(heap.BYTES_PER_ELEMENT);
  }
  private emscriptenWebGLGetTexPixelData(
    type: any,
    format: any,
    width: any,
    height: any,
    pixels: number,
    internalFormat: number
  ) {
    let heap = this.heapObjectForWebGLType(type);
    let shift = this.heapAccessShiftForWebGLHeap(heap);
    let byteSize = 1 << shift;
    let sizePerPixel = this.__colorChannelsInGlTextureFormat(format) * byteSize;
    let bytes = this.computeUnpackAlignedImageSize(
      width,
      height,
      sizePerPixel,
      this.GL.unpackAlignment
    );
    return heap.subarray(pixels >> shift, (pixels + bytes) >> shift);
  }
  private _emscripten_glReadPixels(
    x: any,
    y: any,
    width: any,
    height: any,
    format: any,
    type: any,
    pixels: any
  ) {
    let pixelData = this.emscriptenWebGLGetTexPixelData(
      type,
      format,
      width,
      height,
      pixels,
      format
    );
    if (!pixelData) {
      this.GL.recordError(0x500 /*GL_INVALID_ENUM*/);
      return;
    }
    this.GLctx.readPixels(x, y, width, height, format, type, pixelData);
  }

  private _emscripten_glReleaseShaderCompiler() {
    // NOP (as allowed by GLES 2.0 spec)
  }

  private _emscripten_glRenderbufferStorage(
    x0: any,
    x1: any,
    x2: any,
    x3: any
  ) {
    this.GLctx['renderbufferStorage'](x0, x1, x2, x3);
  }

  private _emscripten_glSampleCoverage(value: any, invert: any) {
    this.GLctx.sampleCoverage(value, !!invert);
  }

  private _emscripten_glScissor(x0: any, x1: any, x2: any, x3: any) {
    this.GLctx['scissor'](x0, x1, x2, x3);
  }

  private _emscripten_glShaderBinary() {
    this.GL.recordError(0x500 /*GL_INVALID_ENUM*/);
  }

  private _emscripten_glShaderSource(
    shader: string | number,
    count: any,
    string: any,
    length: any
  ) {
    let source = this.GL.getSource(shader, count, string, length);

    this.GLctx.shaderSource(this.GL.shaders[shader], source);
  }

  private _emscripten_glStencilFunc(x0: any, x1: any, x2: any) {
    this.GLctx['stencilFunc'](x0, x1, x2);
  }

  private _emscripten_glStencilFuncSeparate(
    x0: any,
    x1: any,
    x2: any,
    x3: any
  ) {
    this.GLctx['stencilFuncSeparate'](x0, x1, x2, x3);
  }

  private _emscripten_glStencilMask(x0: any) {
    this.GLctx['stencilMask'](x0);
  }

  private _emscripten_glStencilMaskSeparate(x0: any, x1: any) {
    this.GLctx['stencilMaskSeparate'](x0, x1);
  }

  private _emscripten_glStencilOp(x0: any, x1: any, x2: any) {
    this.GLctx['stencilOp'](x0, x1, x2);
  }

  private _emscripten_glStencilOpSeparate(x0: any, x1: any, x2: any, x3: any) {
    this.GLctx['stencilOpSeparate'](x0, x1, x2, x3);
  }

  private _emscripten_glTexImage2D(
    target: any,
    level: any,
    internalFormat: any,
    width: any,
    height: any,
    border: any,
    format: any,
    type: any,
    pixels: any
  ) {
    this.GLctx.texImage2D(
      target,
      level,
      internalFormat,
      width,
      height,
      border,
      format,
      type,
      pixels
        ? this.emscriptenWebGLGetTexPixelData(
            type,
            format,
            width,
            height,
            pixels,
            internalFormat
          )
        : null
    );
  }

  private _emscripten_glTexParameterf(x0: any, x1: any, x2: any) {
    this.GLctx['texParameterf'](x0, x1, x2);
  }

  private _emscripten_glTexParameterfv(
    target: any,
    pname: any,
    params: number
  ) {
    let param = this.HEAPF32[params >> 2];
    this.GLctx.texParameterf(target, pname, param);
  }

  private _emscripten_glTexParameteri(x0: any, x1: any, x2: any) {
    this.GLctx['texParameteri'](x0, x1, x2);
  }

  private _emscripten_glTexParameteriv(
    target: any,
    pname: any,
    params: number
  ) {
    let param = this.HEAP32[params >> 2];
    this.GLctx.texParameteri(target, pname, param);
  }

  private _emscripten_glTexSubImage2D(
    target: any,
    level: any,
    xoffset: any,
    yoffset: any,
    width: any,
    height: any,
    format: any,
    type: any,
    pixels: any
  ) {
    let pixelData = null;
    if (pixels)
      pixelData = this.emscriptenWebGLGetTexPixelData(
        type,
        format,
        width,
        height,
        pixels,
        0
      );
    this.GLctx.texSubImage2D(
      target,
      level,
      xoffset,
      yoffset,
      width,
      height,
      format,
      type,
      pixelData
    );
  }

  private _emscripten_glUniform1f(location: any, v0: any) {
    this.GLctx.uniform1f(this.webglGetUniformLocation(location), v0);
  }

  private miniTempWebGLFloatBuffers = [];
  private _emscripten_glUniform1fv(
    location: any,
    count: number,
    value: number
  ) {
    let view: any = [];
    if (count <= 288) {
      // avoid allocation when uploading few enough uniforms
      view = this.miniTempWebGLFloatBuffers[count - 1];
      for (let i = 0; i < count; ++i) {
        view[i] = this.HEAPF32[(value + 4 * i) >> 2];
      }
    } else {
      view = this.HEAPF32.subarray(value >> 2, (value + count * 4) >> 2);
    }
    this.GLctx.uniform1fv(this.webglGetUniformLocation(location), view);
  }

  private _emscripten_glUniform1i(location: any, v0: any) {
    this.GLctx.uniform1i(this.webglGetUniformLocation(location), v0);
  }

  private __miniTempWebGLIntBuffers = [];
  private _emscripten_glUniform1iv(
    location: any,
    count: number,
    value: number
  ) {
    let view: any = [];
    if (count <= 288) {
      // avoid allocation when uploading few enough uniforms
      view = this.__miniTempWebGLIntBuffers[count - 1];
      for (let i = 0; i < count; ++i) {
        view[i] = this.HEAP32[(value + 4 * i) >> 2];
      }
    } else {
      view = this.HEAP32.subarray(value >> 2, (value + count * 4) >> 2);
    }
    this.GLctx.uniform1iv(this.webglGetUniformLocation(location), view);
  }

  private _emscripten_glUniform2f(location: any, v0: any, v1: any) {
    this.GLctx.uniform2f(this.webglGetUniformLocation(location), v0, v1);
  }

  private _emscripten_glUniform2fv(
    location: any,
    count: number,
    value: number
  ) {
    let view: any = [];
    if (count <= 144) {
      // avoid allocation when uploading few enough uniforms
      view = this.miniTempWebGLFloatBuffers[2 * count - 1];
      for (let i = 0; i < 2 * count; i += 2) {
        view[i] = this.HEAPF32[(value + 4 * i) >> 2];
        view[i + 1] = this.HEAPF32[(value + (4 * i + 4)) >> 2];
      }
    } else {
      view = this.HEAPF32.subarray(value >> 2, (value + count * 8) >> 2);
    }
    this.GLctx.uniform2fv(this.webglGetUniformLocation(location), view);
  }

  private _emscripten_glUniform2i(location: any, v0: any, v1: any) {
    this.GLctx.uniform2i(this.webglGetUniformLocation(location), v0, v1);
  }

  private _emscripten_glUniform2iv(
    location: any,
    count: number,
    value: number
  ) {
    let view: any = [];
    if (count <= 144) {
      // avoid allocation when uploading few enough uniforms
      view = this.__miniTempWebGLIntBuffers[2 * count - 1];
      for (let i = 0; i < 2 * count; i += 2) {
        view[i] = this.HEAP32[(value + 4 * i) >> 2];
        view[i + 1] = this.HEAP32[(value + (4 * i + 4)) >> 2];
      }
    } else {
      view = this.HEAP32.subarray(value >> 2, (value + count * 8) >> 2);
    }
    this.GLctx.uniform2iv(this.webglGetUniformLocation(location), view);
  }

  private _emscripten_glUniform3f(location: any, v0: any, v1: any, v2: any) {
    this.GLctx.uniform3f(this.webglGetUniformLocation(location), v0, v1, v2);
  }

  private _emscripten_glUniform3fv(
    location: any,
    count: number,
    value: number
  ) {
    let view: any = [];
    if (count <= 96) {
      // avoid allocation when uploading few enough uniforms
      view = this.miniTempWebGLFloatBuffers[3 * count - 1];
      for (let i = 0; i < 3 * count; i += 3) {
        view[i] = this.HEAPF32[(value + 4 * i) >> 2];
        view[i + 1] = this.HEAPF32[(value + (4 * i + 4)) >> 2];
        view[i + 2] = this.HEAPF32[(value + (4 * i + 8)) >> 2];
      }
    } else {
      view = this.HEAPF32.subarray(value >> 2, (value + count * 12) >> 2);
    }
    this.GLctx.uniform3fv(this.webglGetUniformLocation(location), view);
  }

  private _emscripten_glUniform3i(location: any, v0: any, v1: any, v2: any) {
    this.GLctx.uniform3i(this.webglGetUniformLocation(location), v0, v1, v2);
  }

  private _emscripten_glUniform3iv(
    location: any,
    count: number,
    value: number
  ) {
    let view: any = [];
    if (count <= 96) {
      // avoid allocation when uploading few enough uniforms
      view = this.__miniTempWebGLIntBuffers[3 * count - 1];
      for (let i = 0; i < 3 * count; i += 3) {
        view[i] = this.HEAP32[(value + 4 * i) >> 2];
        view[i + 1] = this.HEAP32[(value + (4 * i + 4)) >> 2];
        view[i + 2] = this.HEAP32[(value + (4 * i + 8)) >> 2];
      }
    } else {
      view = this.HEAP32.subarray(value >> 2, (value + count * 12) >> 2);
    }
    this.GLctx.uniform3iv(this.webglGetUniformLocation(location), view);
  }

  private _emscripten_glUniform4f(
    location: any,
    v0: any,
    v1: any,
    v2: any,
    v3: any
  ) {
    this.GLctx.uniform4f(
      this.webglGetUniformLocation(location),
      v0,
      v1,
      v2,
      v3
    );
  }

  private _emscripten_glUniform4fv(
    location: any,
    count: number,
    value: number
  ) {
    let view: any = [];
    if (count <= 72) {
      // avoid allocation when uploading few enough uniforms
      view = this.miniTempWebGLFloatBuffers[4 * count - 1];
      // hoist the heap out of the loop for size and for pthreads+growth.
      let heap = this.HEAPF32;
      value >>= 2;
      for (let i = 0; i < 4 * count; i += 4) {
        let dst = value + i;
        view[i] = heap[dst];
        view[i + 1] = heap[dst + 1];
        view[i + 2] = heap[dst + 2];
        view[i + 3] = heap[dst + 3];
      }
    } else {
      view = this.HEAPF32.subarray(value >> 2, (value + count * 16) >> 2);
    }
    this.GLctx.uniform4fv(this.webglGetUniformLocation(location), view);
  }

  private _emscripten_glUniform4i(
    location: any,
    v0: any,
    v1: any,
    v2: any,
    v3: any
  ) {
    this.GLctx.uniform4i(
      this.webglGetUniformLocation(location),
      v0,
      v1,
      v2,
      v3
    );
  }

  private _emscripten_glUniform4iv(
    location: any,
    count: number,
    value: number
  ) {
    let view: any = [];
    if (count <= 72) {
      // avoid allocation when uploading few enough uniforms
      view = this.__miniTempWebGLIntBuffers[4 * count - 1];
      for (let i = 0; i < 4 * count; i += 4) {
        view[i] = this.HEAP32[(value + 4 * i) >> 2];
        view[i + 1] = this.HEAP32[(value + (4 * i + 4)) >> 2];
        view[i + 2] = this.HEAP32[(value + (4 * i + 8)) >> 2];
        view[i + 3] = this.HEAP32[(value + (4 * i + 12)) >> 2];
      }
    } else {
      view = this.HEAP32.subarray(value >> 2, (value + count * 16) >> 2);
    }
    this.GLctx.uniform4iv(this.webglGetUniformLocation(location), view);
  }

  private _emscripten_glUniformMatrix2fv(
    location: any,
    count: number,
    transpose: any,
    value: number
  ) {
    let view: any = [];
    if (count <= 72) {
      // avoid allocation when uploading few enough uniforms
      view = this.miniTempWebGLFloatBuffers[4 * count - 1];
      for (let i = 0; i < 4 * count; i += 4) {
        view[i] = this.HEAPF32[(value + 4 * i) >> 2];
        view[i + 1] = this.HEAPF32[(value + (4 * i + 4)) >> 2];
        view[i + 2] = this.HEAPF32[(value + (4 * i + 8)) >> 2];
        view[i + 3] = this.HEAPF32[(value + (4 * i + 12)) >> 2];
      }
    } else {
      view = this.HEAPF32.subarray(value >> 2, (value + count * 16) >> 2);
    }
    this.GLctx.uniformMatrix2fv(
      this.webglGetUniformLocation(location),
      !!transpose,
      view
    );
  }

  private _emscripten_glUniformMatrix3fv(
    location: any,
    count: number,
    transpose: any,
    value: number
  ) {
    let view: any = [];
    if (count <= 32) {
      // avoid allocation when uploading few enough uniforms
      view = this.miniTempWebGLFloatBuffers[9 * count - 1];
      for (let i = 0; i < 9 * count; i += 9) {
        view[i] = this.HEAPF32[(value + 4 * i) >> 2];
        view[i + 1] = this.HEAPF32[(value + (4 * i + 4)) >> 2];
        view[i + 2] = this.HEAPF32[(value + (4 * i + 8)) >> 2];
        view[i + 3] = this.HEAPF32[(value + (4 * i + 12)) >> 2];
        view[i + 4] = this.HEAPF32[(value + (4 * i + 16)) >> 2];
        view[i + 5] = this.HEAPF32[(value + (4 * i + 20)) >> 2];
        view[i + 6] = this.HEAPF32[(value + (4 * i + 24)) >> 2];
        view[i + 7] = this.HEAPF32[(value + (4 * i + 28)) >> 2];
        view[i + 8] = this.HEAPF32[(value + (4 * i + 32)) >> 2];
      }
    } else {
      view = this.HEAPF32.subarray(value >> 2, (value + count * 36) >> 2);
    }
    this.GLctx.uniformMatrix3fv(
      this.webglGetUniformLocation(location),
      !!transpose,
      view
    );
  }

  private _emscripten_glUniformMatrix4fv(
    location: any,
    count: number,
    transpose: any,
    value: number
  ) {
    let view: any = [];
    if (count <= 18) {
      // avoid allocation when uploading few enough uniforms
      view = this.miniTempWebGLFloatBuffers[16 * count - 1];
      // hoist the heap out of the loop for size and for pthreads+growth.
      let heap = this.HEAPF32;
      value >>= 2;
      for (let i = 0; i < 16 * count; i += 16) {
        let dst = value + i;
        view[i] = heap[dst];
        view[i + 1] = heap[dst + 1];
        view[i + 2] = heap[dst + 2];
        view[i + 3] = heap[dst + 3];
        view[i + 4] = heap[dst + 4];
        view[i + 5] = heap[dst + 5];
        view[i + 6] = heap[dst + 6];
        view[i + 7] = heap[dst + 7];
        view[i + 8] = heap[dst + 8];
        view[i + 9] = heap[dst + 9];
        view[i + 10] = heap[dst + 10];
        view[i + 11] = heap[dst + 11];
        view[i + 12] = heap[dst + 12];
        view[i + 13] = heap[dst + 13];
        view[i + 14] = heap[dst + 14];
        view[i + 15] = heap[dst + 15];
      }
    } else {
      view = this.HEAPF32.subarray(value >> 2, (value + count * 64) >> 2);
    }
    this.GLctx.uniformMatrix4fv(
      this.webglGetUniformLocation(location),
      !!transpose,
      view
    );
  }

  private _emscripten_glUseProgram(program: string | number) {
    program = this.GL.programs[program];
    this.GLctx.useProgram(program);
    // Record the currently active program so that we can access the uniform
    // mapping table of that program.
    this.GLctx.currentProgram = program;
  }

  private _emscripten_glValidateProgram(program: string | number) {
    this.GLctx.validateProgram(this.GL.programs[program]);
  }

  private _emscripten_glVertexAttrib1f(x0: any, x1: any) {
    this.GLctx['vertexAttrib1f'](x0, x1);
  }

  private _emscripten_glVertexAttrib1fv(index: any, v: number) {
    this.GLctx.vertexAttrib1f(index, this.HEAPF32[v >> 2]);
  }

  private _emscripten_glVertexAttrib2f(x0: any, x1: any, x2: any) {
    this.GLctx['vertexAttrib2f'](x0, x1, x2);
  }

  private _emscripten_glVertexAttrib2fv(index: any, v: number) {
    this.GLctx.vertexAttrib2f(
      index,
      this.HEAPF32[v >> 2],
      this.HEAPF32[(v + 4) >> 2]
    );
  }

  private _emscripten_glVertexAttrib3f(x0: any, x1: any, x2: any, x3: any) {
    this.GLctx['vertexAttrib3f'](x0, x1, x2, x3);
  }

  private _emscripten_glVertexAttrib3fv(index: any, v: number) {
    this.GLctx.vertexAttrib3f(
      index,
      this.HEAPF32[v >> 2],
      this.HEAPF32[(v + 4) >> 2],
      this.HEAPF32[(v + 8) >> 2]
    );
  }

  private _emscripten_glVertexAttrib4f(
    x0: any,
    x1: any,
    x2: any,
    x3: any,
    x4: any
  ) {
    this.GLctx['vertexAttrib4f'](x0, x1, x2, x3, x4);
  }

  private _emscripten_glVertexAttrib4fv(index: any, v: number) {
    this.GLctx.vertexAttrib4f(
      index,
      this.HEAPF32[v >> 2],
      this.HEAPF32[(v + 4) >> 2],
      this.HEAPF32[(v + 8) >> 2],
      this.HEAPF32[(v + 12) >> 2]
    );
  }

  private _emscripten_glVertexAttribDivisorANGLE(index: any, divisor: any) {
    this.GLctx['vertexAttribDivisor'](index, divisor);
  }

  private _emscripten_glVertexAttribPointer(
    index: any,
    size: any,
    type: any,
    normalized: any,
    stride: any,
    ptr: any
  ) {
    this.GLctx.vertexAttribPointer(
      index,
      size,
      type,
      !!normalized,
      stride,
      ptr
    );
  }

  private _emscripten_glViewport(x0: any, x1: any, x2: any, x3: any) {
    this.GLctx['viewport'](x0, x1, x2, x3);
  }

  private abortOnCannotGrowMemory(requestedSize: number) {
    this.abort(
      'Cannot enlarge memory arrays to size ' +
        requestedSize +
        ' bytes (OOM). Either (1) compile with -sINITIAL_MEMORY=X with X higher than the current value ' +
        this.HEAP8.length +
        ', (2) compile with -sALLOW_MEMORY_GROWTH which allows increasing the size at runtime, or (3) if you want malloc to return NULL (0) instead of this abort, compile with -sABORTING_MALLOC=0'
    );
  }

  private getExecutableName() {
    return this.thisProgram || './this.program';
  }

  private getEnvStrings() {
    let lang =
      (
        (typeof navigator == 'object' &&
          navigator.languages &&
          navigator.languages[0]) ||
        'C'
      ).replace('-', '_') + '.UTF-8';
    let env: any = {
      USER: 'web_user',
      LOGNAME: 'web_user',
      PATH: '/',
      PWD: '/',
      HOME: '/home/web_user',
      LANG: lang,
      _: this.getExecutableName(),
    };
    let strings: string[] = [];
    for (let x in env) {
      strings.push(x + '=' + env[x]);
    }

    return strings;
  }

  private writeAsciiToMemory(
    str: string,
    buffer: number,
    dontAddNull?: undefined
  ) {
    for (let i = 0; i < str.length; ++i) {
      this.assert(str.charCodeAt(i) === (str.charCodeAt(i) & 0xff));
      this.HEAP8[buffer++ >> 0] = str.charCodeAt(i);
    }
    // Null-terminate the pointer to the HEAP.
    if (!dontAddNull) this.HEAP8[buffer >> 0] = 0;
  }

  private _environ_get(__environ: number, environ_buf: number) {
    let bufSize = 0;
    this.getEnvStrings().forEach((string, i) => {
      let ptr = environ_buf + bufSize;
      this.HEAPU32[(__environ + i * 4) >> 2] = ptr;
      this.writeAsciiToMemory(string, ptr);
      bufSize += string.length + 1;
    });
    return 0;
  }

  private _environ_sizes_get(
    penviron_count: number,
    penviron_buf_size: number
  ) {
    let strings = this.getEnvStrings();
    this.HEAPU32[penviron_count >> 2] = strings.length;
    let bufSize = 0;
    strings.forEach((string) => {
      bufSize += string.length + 1;
    });
    this.HEAPU32[penviron_buf_size >> 2] = bufSize;
    return 0;
  }

  private _getentropy(buffer: number, size: number) {
    for (let i = 0; i < size; i++) {
      this.HEAP8[(buffer + i) >> 0] = this.getRandomDevice();
    }
    return 0;
  }

  private err = (err: string) => console.warn(err);

  private abort(what: string | undefined) {
    what = 'Aborted(' + what + ')';
    // TODO(sbc): Should we remove printing and leave it up to whoever
    // catches the exception?
    this.err(what);

    this.ABORT = true;
    this.EXITSTATUS = 1;

    // Use a wasm runtime error, because a JS error might be seen as a foreign
    // exception, which means we'd run destructors on it. We need the error to
    // simply make the program stop.
    // FIXME This approach does not work in Wasm EH because it currently does not assume
    // all RuntimeErrors are from traps; it decides whether a RuntimeError is from
    // a trap or not based on a hidden field within the object. So at the moment
    // we don't have a way of throwing a wasm trap from JS. TODO Make a JS API that
    // allows this in the wasm spec.

    // Suppress closure compiler warning here. Closure compiler's builtin extern
    // defintion for WebAssembly.RuntimeError claims it takes no arguments even
    // though it can.
    // TODO(https://github.com/google/closure-compiler/pull/3913): Remove if/when upstream closure gets fixed.
    /** @suppress {checkTypes} */
    let e = new WebAssembly.RuntimeError(what);

    // Throw the error whether or not MODULARIZE is set because abort is used
    // in code paths apart from instantiation where an exception is expected
    // to be thrown when abort is called.
    throw e;
  }

  private UTF8ToString(ptr: any, maxBytesToRead?: undefined) {
    return ptr ? this.UTF8ArrayToString(this.HEAPU8, ptr, maxBytesToRead) : '';
  }

  private lengthBytesUTF8(str: string) {
    let len = 0;
    for (let i = 0; i < str.length; ++i) {
      // Gotcha: charCodeAt returns a 16-bit word that is a UTF-16 encoded code
      // unit, not a Unicode code point of the character! So decode
      // UTF16->UTF32->UTF8.
      // See http://unicode.org/faq/utf_bom.html#utf16-3
      let c = str.charCodeAt(i); // possibly a lead surrogate
      if (c <= 0x7f) {
        len++;
      } else if (c <= 0x7ff) {
        len += 2;
      } else if (c >= 0xd800 && c <= 0xdfff) {
        len += 4;
        ++i;
      } else {
        len += 3;
      }
    }
    return len;
  }

  private stringToUTF8Array(
    str: string,
    heap: any[] | Uint8Array,
    outIdx: number,
    maxBytesToWrite: number
  ) {
    if (!(maxBytesToWrite > 0)) return 0;
    let startIdx = outIdx;
    let endIdx = outIdx + maxBytesToWrite - 1;
    for (let i = 0; i < str.length; ++i) {
      let u = str.charCodeAt(i);
      if (u >= 55296 && u <= 57343) {
        let u1 = str.charCodeAt(++i);
        u = (65536 + ((u & 1023) << 10)) | (u1 & 1023);
      }
      if (u <= 127) {
        if (outIdx >= endIdx) break;
        heap[outIdx++] = u;
      } else if (u <= 2047) {
        if (outIdx + 1 >= endIdx) break;
        heap[outIdx++] = 192 | (u >> 6);
        heap[outIdx++] = 128 | (u & 63);
      } else if (u <= 65535) {
        if (outIdx + 2 >= endIdx) break;
        heap[outIdx++] = 224 | (u >> 12);
        heap[outIdx++] = 128 | ((u >> 6) & 63);
        heap[outIdx++] = 128 | (u & 63);
      } else {
        if (outIdx + 3 >= endIdx) break;
        heap[outIdx++] = 240 | (u >> 18);
        heap[outIdx++] = 128 | ((u >> 12) & 63);
        heap[outIdx++] = 128 | ((u >> 6) & 63);
        heap[outIdx++] = 128 | (u & 63);
      }
    }
    heap[outIdx] = 0;
    return outIdx - startIdx;
  }
  private stringToUTF8(str: any, outPtr: any, maxBytesToWrite: number) {
    return this.stringToUTF8Array(str, this.HEAPU8, outPtr, maxBytesToWrite);
  }
  private writeArrayToMemory(
    array: ArrayLike<number>,
    buffer: number | undefined
  ) {
    this.HEAP8.set(array, buffer);
  }
  private UTF8Decoder =
    typeof TextDecoder != 'undefined' ? new TextDecoder('utf8') : undefined;
  private UTF8ArrayToString(heapOrArray: any, idx: any, maxBytesToRead?: any) {
    let endIdx = idx + maxBytesToRead;
    let endPtr = idx;
    // TextDecoder needs to know the byte length in advance, it doesn't stop on
    // null terminator by itself.  Also, use the length info to avoid running tiny
    // strings through TextDecoder, since .subarray() allocates garbage.
    // (As a tiny code save trick, compare endPtr against endIdx using a negation,
    // so that undefined means Infinity)
    while (heapOrArray[endPtr] && !(endPtr >= endIdx)) ++endPtr;

    if (endPtr - idx > 16 && heapOrArray.buffer && this.UTF8Decoder) {
      return this.UTF8Decoder.decode(heapOrArray.subarray(idx, endPtr));
    }
    let str = '';
    // If building with TextDecoder, we have already computed the string length
    // above, so test loop end condition against that
    while (idx < endPtr) {
      // For UTF8 byte structure, see:
      // http://en.wikipedia.org/wiki/UTF-8#Description
      // https://www.ietf.org/rfc/rfc2279.txt
      // https://tools.ietf.org/html/rfc3629
      let u0 = heapOrArray[idx++];
      if (!(u0 & 0x80)) {
        str += String.fromCharCode(u0);
        continue;
      }
      let u1 = heapOrArray[idx++] & 63;
      if ((u0 & 0xe0) == 0xc0) {
        str += String.fromCharCode(((u0 & 31) << 6) | u1);
        continue;
      }
      let u2 = heapOrArray[idx++] & 63;
      if ((u0 & 0xf0) == 0xe0) {
        u0 = ((u0 & 15) << 12) | (u1 << 6) | u2;
      } else {
        if ((u0 & 0xf8) != 0xf0)
          this.warnOnce(
            'Invalid UTF-8 leading byte ' +
              this.ptrToString(u0) +
              ' encountered when deserializing a UTF-8 string in wasm memory to a JS string!'
          );
        u0 =
          ((u0 & 7) << 18) | (u1 << 12) | (u2 << 6) | (heapOrArray[idx++] & 63);
      }

      if (u0 < 0x10000) {
        str += String.fromCharCode(u0);
      } else {
        let ch = u0 - 0x10000;
        str += String.fromCharCode(0xd800 | (ch >> 10), 0xdc00 | (ch & 0x3ff));
      }
    }
    return str;
  }
}
