<workspace-doc-header
  css="w-100 header-wrapper d-flex justify-content-between nav flex-nowrap border-bottom"
  [fullscreenEnabled]="false"
  [item]="component"
>
  <ng-container *ngIf="!isCompactMode; else compactDropdownTemplate">
    <fla-workspace-doc-header-item
      [enableContent]="true"
      *ngFor="let newsGroup of newsGroups; let i = index"
    >
      <fla-workspace-doc-header-item
        margin="mx-2"
        [hideBorder]="true"
        [name]="newsGroup.nm_news_group"
        [active]="selectedTab === newsGroup.id_news_group"
        (flaClick)="changeTab(newsGroup.id_news_group)"
      >
      </fla-workspace-doc-header-item>
    </fla-workspace-doc-header-item>
  </ng-container>
</workspace-doc-header>

<ng-template #compactDropdownTemplate>
  <div class="d-inline-flex align-items-center" [style.z-index]="3">
    <ng-container *ngIf="newsGroups">
      <app-dropdown menuAlign="dropdown-menu-left">
        <div appDropdownButton>
          <fla-workspace-doc-header-item
            margin="mx-2"
            [name]="newsGroups[indexTab].nm_news_group"
            [isDropdown]="true"
          >
          </fla-workspace-doc-header-item>
        </div>
        <div cdkDropList cdkDropListOrientation="vertical">
          <ng-container
            *ngFor="let newsGroup of newsGroups; let tabIndex = index"
          >
            <li>
              <fla-workspace-doc-header-item
                [hideBorder]="true"
                [name]="newsGroup.nm_news_group"
                [active]="selectedTab === newsGroup.id_news_group"
                (flaClick)="changeTab(newsGroup.id_news_group)"
              >
              </fla-workspace-doc-header-item>
            </li>
          </ng-container>
        </div>
      </app-dropdown>
    </ng-container>
  </div>
</ng-template>
