import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TTokenAuthenticator } from '../types';
import {
  IServiceResponse,
  InvestorData,
  UserService,
} from '@shared/services/api/trademap/v1/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { phoneValidator } from '@shared/validators/phone-validator';
import { ToastService } from '@shared/services';

@Component({
  selector: 'app-token-authenticators-phone',
  templateUrl: './token-authenticators-phone.component.html',
  styleUrls: ['./token-authenticators-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TokenAuthenticatorsPhoneComponent {
  @Input() investorData!: InvestorData;
  @Output() reloadConfig: EventEmitter<TTokenAuthenticator> =
    new EventEmitter();
  @Output() closeModal: EventEmitter<null> = new EventEmitter();

  form!: FormGroup;
  nextButtonDisabled = true;
  requestSent = false;
  tokenError = 'Informe o token enviado ao seu telefone';
  showPassword = false;
  phoneMask: string = '(00) 00000-0000';
  resendLabel = `Reenviar código (30s)`;
  resendDisabled = true;
  loading = false;

  private _interval!: any;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _formBuilder: FormBuilder,
    private _userService: UserService,
    private _toastService: ToastService
  ) {
    this.form = this._formBuilder.group({
      phone: ['', { validators: [Validators.required, phoneValidator] }],
      userPassword: [
        '',
        {
          validators: [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(70),
          ],
        },
      ],
      token: [
        '',
        {
          validators: [Validators.maxLength(6), Validators.minLength(6)],
        },
      ],
    });
  }

  emitReturn() {
    if (this.requestSent) {
      this.form.get('token')?.setValue('');
      this.requestSent = false;
      this.nextButtonDisabled = false;
      clearInterval(this._interval);
      return;
    }
    this.closeModal.emit();
    this._cdr.detectChanges();
  }
  confirm() {
    if (!this.requestSent) {
      this.sendChangeRequest();
      return;
    }
    this._validateToken();
  }

  checkFormStatus() {
    this.nextButtonDisabled = this.form.invalid;
    this._cdr.detectChanges();
  }

  checkToken() {
    this.nextButtonDisabled = this.form.get('token')?.value.length != 6;
  }

  cancelSubmit(event: any) {
    event.preventDefault();
  }

  sendChangeRequest() {
    if (!this.form.get('phone')?.valid || !this.form.get('userPassword')?.valid)
      return;

    const form = this.form.getRawValue();

    this.loading = true;

    this._userService
      .updateInfoRequest({
        field: 'phone_mobile',
        password: btoa(form.userPassword),
        value: `+55${form.phone}`,
      })
      .subscribe((result: IServiceResponse) => {
        this.loading = false;
        if (result.isError) {
          const msg = result.message
            ? result.message
            : 'Não foi possível enviar a solicitação de alteração. Por favor tente novamente';
          this._toastService.showToast('error', msg);
          this._cdr.detectChanges();
          return;
        }
        this._setResendTime(+result.message);
        this.requestSent = true;
        this.nextButtonDisabled = true;
        this._cdr.detectChanges();
      });
  }

  private _setResendTime(seconds: number) {
    let time = seconds;
    this.resendLabel = `Reenviar código (30s)`;
    this.resendDisabled = true;
    this._cdr.detectChanges();
    this._interval && clearInterval(this._interval);

    this._interval = setInterval(() => {
      if (!time) {
        this.resendLabel = 'Reenviar código';
        this.resendDisabled = false;
        clearInterval(this._interval);
        this._cdr.detectChanges();
        return;
      }
      this.resendLabel = `Reenviar código (${time}s)`;
      time = time - 1;
      this._cdr.detectChanges();
    }, 1000);
  }

  private _validateToken() {
    const form = this.form.getRawValue();

    if (!this.form.valid || !form.token) return;

    this.loading = true;
    this._userService
      .checkPhoneUpdatePinCode({
        field: 'phone_mobile',
        value: `+55${form.phone}`,
        pincode: form.token,
      })
      .subscribe((result: IServiceResponse) => {
        this.loading = false;
        if (result.isError) {
          this.tokenError = 'Token incorreto. Por favor, tentar novamente.';
          this.form.get('token')?.setErrors({
            required: true,
          });
          this._cdr.detectChanges();
          return;
        }
        this._toastService.showToast(
          'success',
          'Seu novo telefone foi adicionado corretamente!'
        );
        this.requestSent = true;
        this.nextButtonDisabled = true;
        this._cdr.detectChanges();
        this.reloadConfig.emit();
      });
  }
}
