import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { ACCOUNT_SELECT_TOOLTIP } from '../account-selector/constant/account-select.constant';
import { TigerChartOrdersService } from '@shared/tiger-chart/tiger-chart-orders/tiger-chart-orders.service';
import { DaytradeLabels } from '@core/layout/header/daytrade/daytrade-labels';
import { IAccountSelect } from '@core/interface';
import { system } from '@core/system/system.service';
import { DEFAULT_SWING_LABEL } from '@core/layout/header/constants/daytrade.constant';
@Component({
  selector: 'app-daytrade-selector',
  templateUrl: './daytrade-selector.component.html',
  styleUrls: ['./daytrade-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DaytradeSelectorComponent implements OnChanges, OnDestroy {
  public useDayTradeMode: boolean = false;
  private _subscriptions$!: Subscription[];
  private subjectDebounced = new Subject<void>();
  public custodyModeLabel: string = DEFAULT_SWING_LABEL;
  public brokerModeLabels: DaytradeLabels = new DaytradeLabels();
  public tooltip: string = ' ';
  public swingDisabled = false;
  public dayTradeDisabled = false;
  @Input() css = '';
  @Input() universalDaytrade: boolean = true;
  @Input() withTooltip: boolean = true;
  @Input() refComponent!: string;
  @Input() account!: IAccountSelect | any;

  constructor(
    private _daytradeService: DaytradeService,
    private tigerChartOrdersService: TigerChartOrdersService,
    private cdr: ChangeDetectorRef
  ) {
    this.subscriptions();
    this.initializeTotalSubscription();
  }

  private initializeTotalSubscription(): void {
    this.subjectDebounced.pipe(debounceTime(500)).subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    changes['account']?.currentValue && this.accountChanged();
  }

  ngOnDestroy(): void {
    this._subscriptions$.forEach((sub) => sub.unsubscribe());
  }

  private subscriptions() {
    const dayTrade$ = this._daytradeService.dayTradeMode.subscribe(
      (isEnable: boolean) => {
        this.universalDaytrade && this.updateDaytradeInfo(isEnable);
        this.subjectDebounced.next();
      }
    );

    const dayTradeLabels$ = system.tradeModeLabelsSubscription.subscribe(
      (labels: DaytradeLabels) => {
        this.brokerModeLabels = labels;
        this.updateDaytradeInfo(this.useDayTradeMode);
      }
    );
    this._subscriptions$ = [dayTrade$, dayTradeLabels$];
    this.subjectDebounced.next();
  }

  public toggleDayTradeMode(setDayTrade: boolean): void {
    if (
      (setDayTrade && this.dayTradeDisabled) ||
      (!setDayTrade && this.swingDisabled)
    )
      return;

    if (this.universalDaytrade) {
      this._daytradeService.toggleUseDayTradeMode();
      return;
    }
    this.updateDaytradeInfo(setDayTrade);
  }

  public accountChanged = () => {
    if (system && system.brokers) {
      const brokerSelected = system.brokers.find(
        (item) => item.id_broker == this.account.id_broker
      );
      system.tradeModeLabels = new DaytradeLabels(
        brokerSelected?.nm_broker_daytrade,
        brokerSelected?.nm_broker_swing
      );
      this.validateTradeModes(
        brokerSelected?.broker_swing,
        brokerSelected?.broker_daytrade
      );
    }
  };

  private validateTradeModes(has_swing?: boolean, has_day_trade?: boolean) {
    this.swingDisabled = !has_swing;
    this.dayTradeDisabled = !has_day_trade;
    if (
      (this.swingDisabled && !this.useDayTradeMode) ||
      (this.dayTradeDisabled && this.useDayTradeMode)
    ) {
      if (this.universalDaytrade) {
        this._daytradeService.toggleUseDayTradeMode();
        return;
      }
      this.updateDaytradeInfo(!this.useDayTradeMode);
    }

    this.updateDaytradeInfo(this.useDayTradeMode);
  }

  private updateDaytradeInfo(isEnable: boolean) {
    this.useDayTradeMode = isEnable;
    this.setLabelsByBroker(isEnable);
    this.subjectDebounced.next();
  }

  private setLabelsByBroker(isEnable: boolean) {
    this.custodyModeLabel = isEnable
      ? this.brokerModeLabels.dayTrade
      : this.brokerModeLabels.swing;
    if (isEnable)
      this.tooltip = DaytradeService.getTextWithTradeMode(
        ACCOUNT_SELECT_TOOLTIP.DAYTRADE_ON
      );
    else
      this.tooltip = DaytradeService.getTextWithTradeMode(
        ACCOUNT_SELECT_TOOLTIP.DAYTRADE_OFF
      );
  }
}
