import { Injectable } from '@angular/core';
import {
  OPTIONS_CONTEXT_MENU_ENUM,
  TOptionsStrategyType,
  TStrategyOption,
} from './types';
import { Subject } from 'rxjs';
import {
  CategoryAxis,
  CursorModifier,
  CursorTooltipSvgAnnotation,
  EXyDirection,
  LegendModifier,
  NumericAxis,
  SeriesInfo,
  TWebAssemblyChart,
  ZoomPanModifier,
} from 'scichart';
import { wheelZoomModifier } from '@shared/tiger-chart/annotations/mouse-wheel-zoom-modifier';
import { ZoomDoubleClickModifier } from '@shared/tiger-chart/annotations/zoom-double-click-modifier';
import { formatterNumber } from '@shared/rocket-components/utils';

@Injectable({
  providedIn: 'root',
})
export class OptionsComponentService {
  private _strategyOptions: TStrategyOption[] = [];
  private _strategyName = '';
  onSelectOption$ = new Subject<{
    type: string;
    option: any;
  }>();
  onSetStrategy$ = new Subject<boolean>();
  onSettingsUpdate$ = new Subject<any>();
  contextMenuActions$ = new Subject<OPTIONS_CONTEXT_MENU_ENUM>();

  get strategyOptions() {
    return this._strategyOptions;
  }

  get strategyName() {
    return this._strategyName;
  }

  setStrategyOption(option: TStrategyOption) {
    const optionIndex = this._strategyOptions.findIndex(
      (optionInArray: TStrategyOption) =>
        optionInArray.cd_stock == option.cd_stock
    );
    if (optionIndex > -1) {
      this._strategyOptions[optionIndex] = option;
      this.onSetStrategy$.next(true);
    } else {
      this._strategyOptions.push(option);
      this.onSetStrategy$.next(false);
    }
  }

  setStrategyOptions(
    options: TStrategyOption[],
    strategy: TOptionsStrategyType
  ) {
    this._strategyOptions = options;
    this._strategyName = strategy.ds_title;
    this.onSetStrategy$.next(false);
  }

  removeStrategyOption(option: TStrategyOption) {
    this._strategyOptions = this._strategyOptions.filter(
      (optionInArray: TStrategyOption) =>
        optionInArray.cd_stock !== option.cd_stock
    );
    this._strategyOptions.length == 0 && (this._strategyName = '');
    this.onSetStrategy$.next(false);
  }

  clearStrategyOptions() {
    this._strategyOptions = [];
    this._strategyName = '';
    this.onSetStrategy$.next(false);
  }

  buildXAxis(
    baseChart: TWebAssemblyChart,
    labelPrecision?: number,
    formatter?: (value: any) => string
  ): CategoryAxis {
    const xAxis = new CategoryAxis(baseChart.wasmContext, {
      drawMajorBands: false,
      drawMajorGridLines: false,
      drawMinorGridLines: false,
      drawMinorTickLines: false,
      labelPrecision,
    });
    if (formatter) {
      xAxis.labelProvider.formatLabel = (value) => {
        return formatter(`${value}`);
      };

      xAxis.labelProvider.formatCursorLabel = (value) => {
        return formatter(`${value}`);
      };
    }
    return xAxis;
  }

  buildXNumericAxis(
    baseChart: TWebAssemblyChart,
    labelPrecision?: number
  ): NumericAxis {
    const xAxis = new NumericAxis(baseChart.wasmContext, {
      drawMajorBands: false,
      drawMajorGridLines: false,
      drawMinorGridLines: false,
      drawMinorTickLines: false,
      labelPrecision,
    });
    xAxis.labelProvider.formatLabel = (value) => {
      return formatterNumber(value, {
        maximumFractionDigits: labelPrecision,
        minimumFractionDigits: labelPrecision,
      });
    };

    xAxis.labelProvider.formatCursorLabel = (value) => {
      return formatterNumber(value, {
        maximumFractionDigits: labelPrecision,
        minimumFractionDigits: labelPrecision,
      });
    };
    return xAxis;
  }

  buildYAxis(baseChart: TWebAssemblyChart): NumericAxis {
    return new NumericAxis(baseChart.wasmContext, {
      drawMajorBands: false,
      drawMajorGridLines: true,
      drawMinorGridLines: false,
      drawMinorTickLines: false,
      labelPrecision: 0,
    });
  }

  buildCursorModifier(
    theme: any,
    tooltipSvgTemplate: (
      seriesInfos: SeriesInfo[],
      svgAnnotation: CursorTooltipSvgAnnotation,
      theme: any,
      data?: any
    ) => string,
    data?: any
  ): CursorModifier {
    return new CursorModifier({
      showXLine: true,
      showYLine: false,
      crosshairStroke: theme.majorGridLineBrush,
      xAxisLabelStroke: theme.axisTitleColor,
      xAxisLabelFill: theme.majorGridLineBrush,
      crosshairStrokeThickness: 1,
      showTooltip: true,
      tooltipSvgTemplate: (
        seriesInfos: SeriesInfo[],
        svgAnnotation: CursorTooltipSvgAnnotation
      ) => tooltipSvgTemplate(seriesInfos, svgAnnotation, theme, data),
    });
  }

  buildZoomPan(): ZoomPanModifier {
    return new ZoomPanModifier({
      xyDirection: EXyDirection.XDirection,
    });
  }

  buildWheelZoom(): wheelZoomModifier {
    return new wheelZoomModifier({
      xyDirection: EXyDirection.XDirection,
    });
  }

  buildZoomDoubleClick(): ZoomDoubleClickModifier {
    return new ZoomDoubleClickModifier({ isAnimated: false });
  }

  buildLegend(placementDivId: string): LegendModifier {
    return new LegendModifier({
      placementDivId,
      showSeriesMarkers: true,
      showLegend: true,
    });
  }
}
