<rocket-modal>
  <rocket-modal-header [hideCloseButton]="true">
    <div class="vstack h-100 w-100 gap-3">
      <div class="align-items-center gap-3 hstack">
        <app-broker-logo
          [logo]="broker.brokerLogoUrl"
          [imageSize]="64"
          iconSize="icon-size-xxl"
        ></app-broker-logo>
        <span class="fs-4">+</span>
        <img
          src="../../../../../assets/icons/ico-svg.svg"
          alt="RocketTrader Logo"
          width="64"
          height="64"
        />
      </div>
      <h3 class="fs-2 fw-bold">Faça sua autenticação</h3>
    </div>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <div
      class="h-100 w-100 vstack"
      [ngClass]="{
        'opacity-semi-opaque': isLoading
      }"
    >
      <form [formGroup]="formConnectBroker">
        <ng-container *ngIf="connectionField.length">
          <div class="broker-fields-container">
            <ng-container *ngFor="let field of connectionField">
              <fla-input
                margin="mb-0"
                [label]="field.label"
                [placeholder]="
                  field.description
                    ? field.description
                    : placeholder[field.cd_field]
                "
                [isSmall]="false"
                [isRequired]="true"
                [formControlName]="field.cd_field"
                [requiredText]="statusInputError"
                [type]="
                  field.is_secure
                    ? displayPassword
                      ? 'text'
                      : 'password'
                    : 'text'
                "
                [autoComplete]="false"
                [name]="'dynamic' + field.cd_field"
                [decidePosition]="field.is_secure ? 'suffix' : ''"
                (keyup)="fixValueTexts($event, field.cd_field)"
                (flaChange)="fixValueTexts($event, field.cd_field)"
              >
                <div *ngIf="field.is_secure" class="suffix">
                  <rocket-button
                    type="icon"
                    (rocketClick)="displayPassword = !displayPassword"
                  >
                    <fla-icon
                      [name]="displayPassword ? 'visibility' : 'visibility_off'"
                    ></fla-icon>
                  </rocket-button>
                </div>
              </fla-input>
            </ng-container>

            <ng-container *ngIf="hasTerm">
              <fla-input
                margin="mb-0"
                label="Senha do RocketTrader"
                placeholder="Insira aqui a sua senha"
                [type]="displayTermPassword ? 'text' : 'password'"
                name="rocket_password"
                decidePosition="suffix"
                [isSmall]="false"
                [isRequired]="true"
                formControlName="password"
                [autoComplete]="false"
                (flaChange)="fixValueTexts($event, 'password')"
              >
                <div class="suffix">
                  <rocket-button
                    type="icon"
                    (rocketClick)="displayTermPassword = !displayTermPassword"
                  >
                    <fla-icon
                      [name]="
                        displayTermPassword ? 'visibility' : 'visibility_off'
                      "
                    ></fla-icon>
                  </rocket-button>
                </div>
              </fla-input>
            </ng-container>
          </div>
        </ng-container>
      </form>

      <ng-container *ngIf="broker && broker.brokerCondition?.length">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item border-none">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed pl-0 pt-0 text-left text-light w-100"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                (click)="showBrokerRequirements = !showBrokerRequirements"
              >
                <div class="hstack">
                  <span class="text-brand-primary">
                    Condição de gratuidade
                  </span>
                  <span class="material-icons-outlined text-brand-primary ml-2">
                    {{ showBrokerRequirements ? 'expand_less' : 'expand_more' }}
                  </span>
                </div>
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body bg-neutral-strong p-3 round">
                <ul class="pl-4 mb-0 fs-5">
                  <ng-container *ngFor="let rule of broker.brokerCondition">
                    <li class="mb-1" [innerHTML]="rule"></li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="isLoading">
      <div class="align-items-center d-flex gap-3 justify-content-center">
        <span class="fw-bold text-white">
          Carregando conexão, por favor aguarde
        </span>
        <div class="spinner-border" role="status"></div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isLoading && errorMessage">
      <span class="d-block pt-2 text-center text-feedback-error w-100">
        {{ errorMessage }}
      </span>
    </ng-container>
  </app-rocket-modal-body>

  <rocket-modal-footer>
    <rocket-button
      type="fill"
      label="Cancelar"
      css="mr-4"
      (rocketClick)="handleBtnAction('CANCEL')"
      btnType="button"
    ></rocket-button>
    <rocket-button
      label="Continuar"
      css="mr-4"
      (rocketClick)="handleBtnAction('SUBMIT')"
      [isDisabled]="formConnectBroker.invalid"
      btnType="submit"
    ></rocket-button>
  </rocket-modal-footer>
</rocket-modal>
