<ng-container *ngIf="isLoading">
  <app-loading></app-loading>
</ng-container>
<div
  class="position-absolute tooltip-div"
  [id]="'rent-chart-tooltip-div-id_' + refChart"
></div>
<div
  [ngClass]="{
    'opacity-0 h-0': errorOnDisplayChart || isLoading,
    'h-100 d-flex flex-column': !errorOnDisplayChart && !isLoading
  }"
>
  <div class="w-100">
    <div
      class="w-100 py-2 d-inline-flex align-items-center justify-content-center flex-wrap gap-3"
    >
      <ng-container *ngFor="let info of stockBalanceFields">
        <div
          *ngIf="stockBalanceData && stockBalanceData[info.field]"
          class="custom-width fs-5 fw-bold d-flex flex-column align-items-center text-center justify-content-center mb-2"
          [tooltip]="info.tooltip"
        >
          <span
            [ngClass]="{
              'text-neutral-medium': isDarkMode,
              'text-neutral-strong': !isDarkMode
            }"
            >{{ info.label }}</span
          >
          <span class="text-white">{{ stockBalanceData[info.field] }}</span>
        </div>
      </ng-container>
    </div>
  </div>
  <div
    class="w-100 d-flex flex-column align-items-center"
    [style.height.px]="300"
  >
    <div
      id="legend-div-id"
      class="w-100 d-inline-flex align-items-center justify-content-center custom-legend"
    ></div>
    <div class="w-100 d-flex justify-content-end gap-2">
      <ng-container *ngFor="let period of periods">
        <span
          class="cursor-pointer"
          [ngClass]="{ 'text-brand-primary': periodSelected === period.value }"
          (click)="selectPeriod(period)"
        >
          {{ period.label }}
        </span>
      </ng-container>
    </div>
    <div
      [id]="refChart"
      #chartContainer
      class="w-100 h-100"
      [style.max-height.px]="300"
    ></div>
  </div>
</div>
<div
  *ngIf="!isLoading && errorOnDisplayChart"
  class="h-100 w-100 d-flex justify-content-center align-items-center"
>
  <app-no-info></app-no-info>
</div>
