export const dojiTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  Um doji é um padrão de candlestick que ocorre quando o preço de abertura e
  fechamento de um ativo são praticamente iguais. Esse padrão cria um
  candlestick com um corpo muito pequeno e sombras longas em ambas as
  extremidades. O doji indica indecisão ou equilíbrio entre compradores e
  vendedores, e pode ser um sinal de possível reversão da tendência atual. O
  doji pode aparecer em diferentes tipos de gráficos, incluindo gráficos
  diários, semanais e mensais.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">
  A fórmula para calcular o indicador Doji é comparar o tamanho do corpo do
  candlestick com o tamanho das sombras. Se o corpo do candlestick for muito
  pequeno em relação às sombras, ele será considerado um Doji.
</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  Para usar o indicador Doji, é possível identificar possíveis pontos de
  reversão de tendência ou pontos de entrada e saída de trades. Quando um Doji
  ocorre após uma tendência de alta ou de baixa, pode indicar que a tendência
  está perdendo força e pode haver uma reversão em breve. Por outro lado, se um
  Doji ocorrer em uma área de suporte ou resistência, pode indicar que o preço
  está se consolidando e que pode haver uma oportunidade de compra ou venda em
  breve, dependendo da direção da tendência. É importante lembrar que o
  indicador Doji deve ser usado em conjunto com outras ferramentas de análise
  técnica para tomar decisões de trading informadas.
</div>
<div class="mb-3">
  <img class="w-75" src="/assets/indicators/doji-como-funciona.png" />
</div>
<div class="fw-bolder">Como configurar</div>
<div class="fw-bolder">Valores</div>
<div class="mb-3">Sem valores pré-definidos para configurar.</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">Background</div>
<div class="mb-3">
  Pode alternar a visibilidade do Doji. Também pode selecionar a cor, a
  espessura e o estilo da linha do Doji.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/doji-estilo.png"
  />
</div>
`;
