import { Injectable } from '@angular/core';
import { isNullOrWhiteSpace } from '../utils';

@Injectable()
export abstract class TableHelper {
  get thCell() {
    return document.querySelector<HTMLElement>('.sticky-header > th');
  }

  get thSecondaryCell() {
    return document.querySelector<HTMLElement>(
      '.double-header.sticky-header > th'
    );
  }

  updateWidthCell() {
    const tds = document.querySelectorAll<HTMLElement>(
      'td[visible="true"].sticky-col'
    );
    let maxWidth = 0;
    tds.forEach((tr) => {
      const cell = tr.childNodes[0] as HTMLElement;
      let cellWidth = cell.getBoundingClientRect().width;
      if (!isNullOrWhiteSpace(cell.offsetLeft)) {
        cellWidth = cellWidth + cell.offsetLeft;
      }

      if (cellWidth > maxWidth) {
        maxWidth = cellWidth;
      }
    });

    maxWidth = +maxWidth.toFixed(0) + 20;
    tds.forEach((td) => {
      this.thCell!.style.width = maxWidth + 'px';
      if (this.thSecondaryCell)
        this.thSecondaryCell.style.width = maxWidth + 'px';
      td.style.width = maxWidth + 'px';
    });
  }
}
