<div
  class="position-absolute chartControls d-flex justify-content-center w-100 align-items-end"
  [ngClass]="{ alwaysShow: typeControl === 'ALWAYS_SHOW' }"
>
  <div class="d-flex justify-content-center">
    <rocket-button
      [isSmall]="true"
      tooltip="Mover para esquerda"
      tooltipPosition="bottom"
      type="icon"
      [isDisabled]="false"
      label=""
      (rocketClick)="controlClick(controls.ARROW_LEFT)"
      css="bg-neutral-strong text-white round"
    >
      <fla-icon name="chevron_left" size="icon-size-micro"></fla-icon>
    </rocket-button>
    <rocket-button
      [isSmall]="true"
      tooltip="Diminuir zoom"
      tooltipPosition="bottom"
      type="icon"
      [isDisabled]="false"
      label=""
      (rocketClick)="controlClick(controls.ZOOM_OUT)"
      css="bg-neutral-strong text-white round"
    >
      <fla-icon name="remove" size="icon-size-micro"></fla-icon>
    </rocket-button>
    <rocket-button
      [isSmall]="true"
      tooltip="Aumentar zoom"
      tooltipPosition="bottom"
      type="icon"
      [isDisabled]="false"
      label=""
      (rocketClick)="controlClick(controls.ZOOM_IN)"
      css="bg-neutral-strong text-white round"
    >
      <fla-icon name="add" size="icon-size-micro"></fla-icon>
    </rocket-button>
    <rocket-button
      [isSmall]="true"
      tooltip="Mover para direita"
      tooltipPosition="bottom"
      type="icon"
      [isDisabled]="false"
      label=""
      (rocketClick)="controlClick(controls.ARROW_RIGHT)"
      css="bg-neutral-strong text-white round"
    >
      <fla-icon name="chevron_right" size="icon-size-micro"></fla-icon>
    </rocket-button>
    <rocket-button
      [isSmall]="true"
      tooltip="Restaurar vista do gráfico"
      tooltipPosition="bottom"
      type="icon"
      [isDisabled]="false"
      label=""
      (rocketClick)="controlClick(controls.REFRESH)"
      css="bg-neutral-strong text-white round"
    >
      <fla-icon
        css="rotateIcon"
        name="refresh"
        size="icon-size-micro"
      ></fla-icon>
    </rocket-button>
  </div>
</div>
