<business-profile-header
  [refComponent]="refComponent"
  [component]="component"
  [headerOptions]="headerOptions"
  [stockSelected]="stockSelected"
  (onChangeStock)="stockChanges($event)"
></business-profile-header>

<div
  [id]="componentID"
  class="px-3 pb-3 bg-neutral-stronger overflow-auto"
  [ngClass]="isDesktop ? 'component-height' : 'h-100'"
>
  <ng-container *ngIf="!isSotckIndex; else stockIndex">
    <fla-tab cssContent="height-content">
      <fla-tab-header
        *ngFor="let tab of tabs"
        [ref]="'tab_' + refComponent"
        [title]="tab.title"
        [active]="tab.cod === this.selectedTab"
        (flaChange)="changeTab(tab)"
      ></fla-tab-header>

      <fla-tab-body css="h-100" [ref]="'tab_' + refComponent" [active]="true">
        <ng-container [ngSwitch]="selectedTab">
          <ng-container *ngSwitchCase="'RESUME'">
            <app-resume
              [refComponent]="refComponent"
              [stockSelected]="stockSelected"
              [stockInfo]="stockInfo"
            ></app-resume>
          </ng-container>
          <ng-container *ngSwitchCase="'PAIRS'">
            <app-pairs
              [refComponent]="refComponent"
              [stockSelected]="stockSelected"
              [component]="component"
            ></app-pairs>
          </ng-container>
          <ng-container *ngSwitchCase="'PROVENTS'">
            <app-provents
              [refComponent]="refComponent"
              [stockSelected]="stockSelected"
            ></app-provents>
          </ng-container>
          <ng-container *ngSwitchCase="'INDICATORS'">
            <app-indicators
              [refComponent]="refComponent"
              [stockSelected]="stockSelected"
              [componentID]="componentID"
            ></app-indicators>
          </ng-container>
          <ng-container *ngSwitchCase="'FUNDS'">
            <app-funds-table
              css="p-0"
              cssContainer="p-0"
              [cdStock]="stockSelected.cd_stock"
              [showTitle]="false"
              [limit]="20"
              [isInfinityScroll]="false"
            ></app-funds-table>
          </ng-container>
          <ng-container *ngSwitchCase="'ALUGUEL'">
            <app-rent-chart [cdStock]="stockSelected.cd_stock"></app-rent-chart>
          </ng-container>
          <ng-container *ngSwitchCase="'REPURCHASE'">
            <app-repurchase [stockSelected]="stockSelected"></app-repurchase>
          </ng-container>
          <ng-container *ngSwitchCase="'ABOUT'">
            <app-about [stockSelected]="stockSelected"></app-about>
          </ng-container>
          <ng-container *ngSwitchCase="'CONSENSUS'">
            <app-consensus
              [stockSelected]="stockSelected"
              [priceClosing]="stockInfo?.preco_fechamento"
            ></app-consensus>
          </ng-container>
        </ng-container>
      </fla-tab-body>
    </fla-tab>
  </ng-container>
</div>

<ng-template #stockIndex>
  <fla-tab cssContent="height-content">
    <fla-tab-header
      *ngFor="let tab of tabsIndex"
      [ref]="'tab_' + refComponent"
      [title]="tab.title"
      [active]="tab.cod === this.selectedTab"
      (flaChange)="changeTab(tab)"
    ></fla-tab-header>

    <fla-tab-body css="h-100" [ref]="'tab_' + refComponent" [active]="true">
      <ng-container [ngSwitch]="selectedTab">
        <ng-container *ngSwitchCase="'RESUME'">
          <app-resume
            [refComponent]="refComponent"
            [stockSelected]="stockSelected"
            [stockInfo]="stockInfo"
            [height]="height"
            [width]="width"
          ></app-resume>
        </ng-container>
        <ng-container *ngSwitchCase="'ABOUT_INDEX'">
          <app-about-index [stockSelected]="stockSelected"></app-about-index>
        </ng-container>
      </ng-container>
    </fla-tab-body>
  </fla-tab>
</ng-template>
