import { Component } from '@angular/core';
import { WhatsappService } from './whatsapp.service';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss'],
})
export class WhatsappComponent {
  constructor(private whatsAppService: WhatsappService) {}

  openWhats() {
    this.whatsAppService.openWhatsAppRocket();
  }
}
