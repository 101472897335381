<rocket-modal>
  <rocket-modal-header [hideCloseButton]="true">
    <div>
      <h3 class="modal-title">Gerenciador de workspace</h3>
      <small class="modal-subtitle">
        Editar, redefinir, duplicar ou apagar seus Workspaces
      </small>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div class="d-flex flex-column overflow-y-auto ws-list-height">
      <ng-container *ngFor="let workspace of workspaces; let wsIndex = index">
        <div
          class="d-inline-flex align-items-center justify-content-start mb-1"
        >
          <ng-container *ngIf="!workspace.editMode; else elseEdit">
            <input
              class="form-check-input cursor-pointer"
              type="checkbox"
              value="1"
              name="workspace"
              [ngModel]="workspace.select"
              [disabled]="isEdit"
              (change)="handleCheckedWorkspace(wsIndex)"
            />
            <span class="ml-2 w-75 text-truncate">{{ workspace.name }}</span>
          </ng-container>

          <ng-template #elseEdit>
            <div class="mb-2 w-100">
              <fla-input
                fla-focus
                placeholder="Novo nome"
                decidePosition="suffix"
                margin="m-0"
                requiredText="Informe o nome da workspace"
                [isRequired]="true"
                [formControl]="editControl"
                [maxLength]="80"
                [max]="80"
              >
                <div class="suffix">
                  <rocket-button
                    type="icon"
                    (rocketClick)="handleEditMode(wsIndex, false)"
                  >
                    <fla-icon size="icon-size-micro" name="close"></fla-icon>
                  </rocket-button>
                  <fla-input-split></fla-input-split>
                  <rocket-button
                    type="icon"
                    (rocketClick)="confirmEditingWorkspaceName(wsIndex)"
                  >
                    <fla-icon size="icon-size-micro" name="check"></fla-icon>
                  </rocket-button>
                </div>
              </fla-input>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>

    <div
      class="modal-footer pt-7 px-0 w-100 d-inline-flex align-items-center justify-content-between"
    >
      <div class="d-inline-flex align-items-center justify-content-start">
        <rocket-button
          type="icon"
          css="ml-auto"
          class="ml-auto"
          (rocketClick)="enableEditWorkspaceName()"
          tooltip="Editar Nome Workspace"
          tooltipPosition="top"
          [isDisabled]="isDisabled"
        >
          <fla-icon size="icon-size-sm" name="edit"></fla-icon>
        </rocket-button>

        <rocket-button
          type="icon"
          (rocketClick)="cloneWorkspace()"
          tooltip="Duplicar Workspace"
          tooltipPosition="top"
          [isDisabled]="isDisabled"
        >
          <fla-icon size="icon-size-sm" name="library_add"></fla-icon>
        </rocket-button>

        <rocket-button
          type="icon"
          tooltip="Redefinir workspace"
          tooltipPosition="top"
          (rocketClick)="resetDefaultWorkspace()"
          [isDisabled]="isDisabled"
        >
          <fla-icon size="icon-size-sm" name="refresh"></fla-icon>
        </rocket-button>

        <rocket-button
          type="icon"
          (rocketClick)="deleteWorkspace()"
          tooltip="Apagar Workspace"
          tooltipPosition="top"
          [isDisabled]="workspaces[wsIndexDefault]?.select || isDisabledDelete"
        >
          <fla-icon size="icon-size-sm" name="delete"></fla-icon>
        </rocket-button>
      </div>

      <rocket-button
        label="Fechar"
        type="fill"
        (rocketClick)="closeModal()"
      ></rocket-button>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
