import { Component, OnInit } from '@angular/core';
import * as marked from 'marked';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss'],
})
export class ChangelogComponent implements OnInit {
  changelogContent!: string;
  constructor() {}
  ngOnInit(): void {
    const changelogPath = './assets/changelog';
    fetch(changelogPath)
      .then((response) => response.text())
      .then((changelogText) => {
        this.changelogContent = marked.marked(changelogText);
      });
  }
}
