import { deepClone } from '@shared/rocket-components/utils/functions';
import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { CategoryCoordinateCalculator } from 'scichart/Charting/Numerics/CoordinateCalculators/CategoryCoordinateCalculator';
import { CoordinateCalculatorBase } from 'scichart/Charting/Numerics/CoordinateCalculators/CoordinateCalculatorBase';
import {
  CustomAnnotation,
  ICustomAnnotationOptions,
} from 'scichart/Charting/Visuals/Annotations/CustomAnnotation';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { Point } from 'scichart/Core/Point';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import {
  DrawToolConfiguration,
  TIGER_CHART_TOOL,
  TIGER_CHART_TOOL_CHILD,
} from '../tiger-chart-tools/tiger-chart-tools.interface';
import { TMouseEventData } from '../types/tiger-chart.types';
import {
  Axis,
  DrawTools,
  DrawToolsReposition,
  EventSubject,
  LineToolsOptions,
  StyleTool,
} from './draw-tools.interface';
import { NumberRange } from 'scichart/Core/NumberRange';
import { EDraggingGripPoint } from 'scichart/Charting/Visuals/Annotations/AnnotationBase';
import { Subject, Subscription, auditTime, debounceTime } from 'rxjs';
import { TOOLS_METHOD } from '../tiger-chart-tools/tiger-chart-tools.enum';
import { TIGER_INTERVAL_ENUM } from '../enum';
import { ICandleConfigs } from '../interface';
import { formatNumber } from '@angular/common';
import { CHART_COLORS } from '../colors';
import { Inject, LOCALE_ID } from '@angular/core';
import { LineTools } from './line-tools';
import { CANDLE_IDS } from '../constants/tiger-chart.constants';
export class ArrowLineTools extends CustomAnnotation implements DrawTools {
  tool!: TIGER_CHART_TOOL;
  children = [];
  isDraw = true;
  startEdit = false;
  baseChart!: TWebAssemblyChart;
  isChildren = false;
  lastPointValues!: Point;
  lastMouseValues!: Point;
  lastXDiff: number = 0;
  isDrawing!: boolean;
  style: StyleTool = {};
  dictIdChildren!: string;
  configuration!: DrawToolConfiguration | undefined;
  hasElements: boolean = false;
  updateChart: boolean = false;
  width: number = 0;
  height: number = 0;
  previousVisibleRange!: NumberRange;
  isAux: boolean = false;
  endDraw: boolean = false;
  lastEventData!: TMouseEventData;
  axisSub!: Subscription;
  configs!: ICandleConfigs;
  stroke!: string;
  stock!: any;
  isWithinDataBounds: boolean | undefined = true;
  private repositionTemporaryBox$!: Subscription;
  private _repositionTemporaryBox$ = new Subject<EventSubject>();
  private repositionZoomDraw$!: Subscription;
  private _repositionZoomDraw$ = new Subject<EventSubject>();
  private updateDrawTool$!: Subscription;
  private _updateDrawTool$ = new Subject<{
    eventData: TMouseEventData;
    countStepClickTool: number;
    reposition?: DrawToolsReposition;
  }>();
  private isFromSaved = true;

  constructor(
    options: ICustomAnnotationOptions,
    private drawToolsService: DrawToolsService,
    tool: LineToolsOptions,
    @Inject(LOCALE_ID) private locale: string
  ) {
    super(options);
    this.isChildren = tool.isChildren;
    this.baseChart = tool.baseChart;
    this.tool = tool.tool;
    this.dictIdChildren = tool.dictIdChildren;
    this.configuration = tool.configuration;
    this.configs = tool.configs;
    this.isAux = tool.isAux;
    this.lastEventData = tool.eventData;
    this.stock = tool.stock;
    this.isWithinDataBounds = tool.eventData.isWithinDataBounds;
    this.initSubscriptions();
  }

  private xAxisEvent(visibleRange: NumberRange) {
    const diffActual = deepClone(visibleRange.diff);
    const diffPrevious = deepClone(this.previousVisibleRange.diff);
    if (
      !isNullOrUndefined(diffActual) &&
      !isNullOrUndefined(diffPrevious) &&
      diffActual != diffPrevious
    ) {
      this.updateChart = true;
    }
  }

  unsubscriber(): void {
    this.axisSub.unsubscribe();
  }

  initSubscriptions() {
    this.updateDrawTool$ = this._updateDrawTool$
      .pipe(auditTime(1))
      .subscribe((data) => {
        const { eventData, reposition } = data;
        this.lastEventData = eventData;
        const svgElement = document.getElementById(`SVG_${this.tool.dictId!!}`);
        const element = document.getElementById(`${this.tool.dictId!!}`);
        const arrowHeadElement = document.getElementById(
          `ARROWHEAD_${this.tool.dictId!!}`
        );
        let x2 = (this.tool.mouseValues!!.x - eventData.mousePoint!!.x) * -1;
        const children = this.tool.children?.filter((child) => child.isAux);
        const annotationChildAux = this.parentSurface.annotations.getById(
          children![0].dictIdChildren!
        ) as LineTools;
        const newX2 =
          annotationChildAux.getAnnotationBorders().x2 -
          annotationChildAux.getAnnotationBorders().x1;
        if (!newX2) {
          this.isFromSaved = false;
        } else if (this.isFromSaved) {
          x2 = newX2;
        }
        const y2 = (this.tool.mouseValues!!.y - eventData.mousePoint!!.y) * -1;
        this.lastPointValues = eventData.pointValues!!;
        this.lastMouseValues = eventData.mousePoint!!;
        if (svgElement) {
          if (element) {
            element.setAttribute('x2', `${x2}`);
            element.setAttribute('y2', `${y2}`);
            this.x2 = eventData.pointValues!!.x;
            this.y2 = eventData.pointValues!!.y;
            if (arrowHeadElement) {
              arrowHeadElement.setAttribute('markerWidth', '10');
              arrowHeadElement.setAttribute('markerHeight', '10');
            }
            if (this.tool.hasBoxAux && this.tool.children) {
              const child = this.tool.children.find((c) => c.isAux)!!;
              const aux = this.baseChart.sciChartSurface.annotations.getById(
                child.dictIdChildren!!
              );
              aux.x2 = this.x2;
              aux.y2 = this.y2;
            }
          }
          this.svgString = svgElement.outerHTML!!;
        }
        this.repositionTemporaryBox(eventData, reposition);
      });
    this.repositionTemporaryBox$ = this._repositionTemporaryBox$
      .pipe(debounceTime(1))
      .subscribe((data) => {
        data.function();
      });
    this.repositionZoomDraw$ = this._repositionZoomDraw$
      .pipe(debounceTime(10))
      .subscribe((data) => {
        data.function();
      });
    const { xAxis } = this.getAxis();
    if (xAxis) {
      this.previousVisibleRange = {
        ...deepClone(xAxis.visibleRange),
        diff: xAxis.visibleRange.max - xAxis.visibleRange.min,
      };
    }
    this.axisSub = this.drawToolsService.axisChanged$.subscribe((data) => {
      if (data.type === 'XAXIS') {
        this.xAxisEvent(data.visibleRange);
      }
    });
  }

  private destroySubscriptions() {
    this.repositionTemporaryBox$ && this.repositionTemporaryBox$.unsubscribe();
    this.repositionZoomDraw$ && this.repositionZoomDraw$.unsubscribe();
    this.updateDrawTool$ && this.updateDrawTool$.unsubscribe();
  }

  override onDetach(): void {
    super.onDetach();
    this.destroySubscriptions();
  }

  override delete(): void {
    super.delete();
    this.destroySubscriptions();
  }

  override click(): boolean {
    return false;
  }

  protected override setAnnotationBorders(
    x1: number,
    x2: number,
    y1: number,
    y2: number
  ): void {
    super.setAnnotationBorders(x1, x2, y1, y2);
    this.setStyleConfiguration();
  }

  override getAnnotationGripSvg(x: number, y: number) {
    const size = this.annotationsGripsRadius;
    return `<rect x="${x - size / 2}" y="${
      y - size / 2
    }" rx="6" width="12" height="12" fill="${
      this.annotationsGripsFill
    }" stroke="${this.annotationsGripsStroke}"/>`;
  }

  override svgStringAdornerTemplate(
    x1: number,
    y1: number,
    x2: number,
    y2: number
  ): string {
    if (this.tool.hasBoxAux) {
      return '';
    }
    const width = x2 - x1;
    const height = y2 - y1;
    let svg = `<svg xmlns="http://www.w3.org/2000/svg">
    <defs>
        <pattern id="grid1" patternUnits="userSpaceOnUse" width="10" height="10">
            <line x1="0" y1="0" x2="10" y2="10" />
        </pattern>
    </defs>
    <rect x="${x1}" y="${y1}" width="${width}" height="${height}" fill="url(#grid1)"/>
    `;
    const grips = this.getAdornerAnnotationBorders(false, true);
    if (this.canDragPoint(EDraggingGripPoint.x1y1)) {
      svg += this.getAnnotationGripSvg(grips.x1, grips.y1 - 3);
    }
    if (this.canDragPoint(EDraggingGripPoint.x2y2)) {
      svg += this.getAnnotationGripSvg(grips.x2, grips.y2 - 2.5);
    }
    svg += '</svg>';
    return svg;
  }

  override update(
    xCalc: CoordinateCalculatorBase,
    yCalc: CoordinateCalculatorBase,
    xCoordSvgTrans: number,
    yCoordSvgTrans: number
  ): void {
    super.update(xCalc, yCalc, xCoordSvgTrans, yCoordSvgTrans);
    const x = xCalc as CategoryCoordinateCalculator;
    const xDiff = x.indexMax - x.indexMin;
    if (this.isDrawing) {
      return;
    }
    this.lastXDiff = xDiff;
    if (this.style) {
      this.updateStyleTool(this.style);
    }
    if (!this.isEditable && !this.isSelected && !this.tool.hasBoxAux) {
      this.deleteAdorner();
    }
    if (this.updateChart) {
      this.updateChart = false;
      this.repositionZoomDraw(deepClone(this.getAnnotationBorders()));
    } else {
      /*const element = document.getElementById(`${this.tool.dictId!!}`);
      if (!element) {
        return;
      }
      const x2 = deepClone(Math.abs(parseFloat(element.getAttribute('x2')!!)));
      const y2 = deepClone(Math.abs(parseFloat(element.getAttribute('y2')!!)));
      element.setAttribute('x2', `${x2}`);
      element.setAttribute('y2', `${y2}`);*/
    }
  }

  getName() {
    return this.constructor.name;
  }

  updateDrawTool(
    eventData: TMouseEventData,
    countStepClickTool: number,
    reposition?: DrawToolsReposition
  ) {
    this._updateDrawTool$.next({ eventData, countStepClickTool, reposition });
  }

  private repositionTemporaryBox(
    eventData?: TMouseEventData,
    reposition?: DrawToolsReposition
  ) {
    if (!this || !this.getAdornerAnnotationBorders() || !this.tool.children) {
      return;
    }
    const child = this.tool.children.find(
      (c) => c.method === TOOLS_METHOD.CUSTOM_BOX_ANNOTATION
    );
    if (!child) {
      return;
    }
    const rectElement = document.getElementById(
      `FOREIGN_${child.dictIdChildren}`
    );
    const percentTextElement = document.getElementById(
      `PERCENT_${child.dictIdChildren}`
    );
    const timeTextElement = document.getElementById(
      `TIME_${child.dictIdChildren}`
    );
    const backgroundElement = document.getElementById(
      `BACKGROUND_${child.dictIdChildren}`
    );
    const aux = this.tool.children?.find((child) => child.isAux);
    const auxDraw = this.baseChart.sciChartSurface.annotations.getById(
      aux!!.dictIdChildren!!
    ) as DrawTools;
    const x1 = auxDraw.getAdornerAnnotationBorders().x1;
    const x2 = auxDraw.getAdornerAnnotationBorders().x2;
    const y1 = auxDraw.getAdornerAnnotationBorders().y1;
    const y2 = auxDraw.getAdornerAnnotationBorders().y2;
    if (rectElement) {
      let x = 0;
      let y = 0;
      if (eventData) {
        x = eventData.mousePoint!!.x - this.tool.mouseValues!!.x + 15;
        y = eventData.mousePoint!!.y - this.tool.mouseValues!!.y - 30;
      } else {
        x = x2 - x1 + 15;
        if (y2 < y1) {
          y = y2 - y1 + 15;
        } else {
          y = -(y1 - y2 + 15);
        }
      }
      let width = 100;
      rectElement.setAttribute('height', '40');
      rectElement.setAttribute('x', `${x}`);
      rectElement.setAttribute('y', `${y}`);
      let date1!: Date;
      if (eventData) {
        date1 = new Date(eventData.xCategoryValue!!);
      } else {
        date1 = new Date(this.lastEventData.xCategoryValue!!);
      }
      let date2 = new Date(this.tool.xCategoryValue!!);
      const series = this.baseChart.sciChartSurface.renderableSeries;
      const serie = series.getById(CANDLE_IDS.MAIN_SERIE) ?? series.get(0);
      const seriesCount = serie.dataSeries.count();
      const lastPoint = serie.getNativeXValues().get(seriesCount - 1);
      if (date1.getTime() > lastPoint) date1 = new Date(lastPoint);
      if (date2.getTime() > lastPoint) date2 = new Date(lastPoint);
      const diffDates = date1.getTime() - date2.getTime();
      const minutes = Math.round(diffDates / 1000 / 60);
      const minutesToHour = minutes / 60;
      let hours = parseInt(minutesToHour.toString());
      const hourMinutes = (minutesToHour - hours) * 60;
      let hourMinutesText = ` ${parseInt(hourMinutes.toString())}m`;
      let typeTimer = 'h';
      const days = Math.round(hours / 24);
      const hashTime: any = {
        ONE_MINUTE: minutes / 1,
        TWO_MINUTE: minutes / 2,
        FIVE_MINUTE: minutes / 5,
        TEN_MINUTE: minutes / 10,
        FIFTEEN_MINUTE: minutes / 15,
        THIRTY_MINUTE: minutes / 30,
        ONE_HOUR: hours / 1,
        ONE_DAY: days / 1,
        THIS_WEEK: days / 7,
        ONE_MONTH: Math.round(days / 30),
      };
      const objectInterval = TIGER_INTERVAL_ENUM;
      const qttyTime = hashTime[objectInterval[this.configs.series.interval!!]];
      if (!objectInterval[this.configs.series.interval!!].includes('MINUTE')) {
        hourMinutesText = '';
      }
      if (
        objectInterval[this.configs.series.interval!!].includes('YEAR') ||
        objectInterval[this.configs.series.interval!!].includes('MONTH') ||
        objectInterval[this.configs.series.interval!!].includes('WEEK') ||
        objectInterval[this.configs.series.interval!!].includes('DAY')
      ) {
        hours = days;
        typeTimer = 'd';
      }
      const bars = `Barras: ${formatNumber(qttyTime, this.locale, `1.0-0`)} | `;
      const time =
        Math.abs(hours) > 0
          ? `${hours}${typeTimer}${hourMinutesText}`
          : `${hourMinutesText.trim()}`;
      const tickSizeDenominator = this.stock.tick_size_denominator;
      const firstY = parseFloat(
        this.tool.pointValues!!.y.toFixed(tickSizeDenominator)
      );
      const secondY = parseFloat(
        this.lastEventData!!.pointValues!!.y.toFixed(tickSizeDenominator)
      );
      const percent = (secondY / firstY - 1) * 100;
      const points = this.y2 - this.y1;
      let textColor = '';
      if (reposition?.stock.cd_security_type === 'FUT') {
        percentTextElement!!.textContent = `${bars}${formatNumber(
          points,
          this.locale,
          `1.0-0`
        )} pontos`;
        textColor = this.updateTemporaryColor(
          parseFloat(formatNumber(points, 'en-US', `1.0-0`))
        );
      } else {
        percentTextElement!!.textContent = `${bars}${formatNumber(
          percent,
          this.locale,
          `1.2-2`
        )}%`;
        textColor = this.updateTemporaryColor(
          parseFloat(formatNumber(percent, 'en-US', `1.2-2`))
        );
      }
      backgroundElement!!.style.background = this.stroke;
      timeTextElement!!.textContent = `${time}`;
      percentTextElement!!.style.color = textColor;
      timeTextElement!!.style.color = textColor;
      width =
        Math.max(
          percentTextElement!!.getBoundingClientRect().width,
          timeTextElement!!.getBoundingClientRect().width
        ) + 20;
      rectElement.setAttribute('width', `${width}`);
      const aux = this.tool.children?.find((child) => child.isAux);
      const drawAux = this.baseChart.sciChartSurface.annotations.getById(
        aux!.dictIdChildren!!
      ) as DrawTools;
      drawAux.annotationsGripsStroke = this.stroke;
      this._repositionTemporaryBox$.next({
        function: () => {
          this.updateStyleTool({
            color: {
              rgbaHexColor: this.stroke,
              hexColor: '',
              rgbaColor: '',
              rgbColor: '',
              selected: false,
            },
          });
          this.notifyPropertyChanged('STROKE');
          this.notifyPropertyChanged('svgString');
        },
      });
    }
  }

  private updateTemporaryColor(value: number) {
    let textColor = 'white';
    if (value > 0) {
      this.stroke = CHART_COLORS.FEEDBACK_POSITIVE;
      textColor = 'black';
    } else if (value < 0) {
      this.stroke = CHART_COLORS.FEEDBACK_NEGATIVE;
    } else {
      this.stroke = '#1DA1F2';
    }
    return textColor;
  }

  resizeSVG(
    typeResize: string,
    childId: string,
    svgPosition?: { x1: number; x2: number; y1: number; y2: number },
    position?: { x1: number; x2: number; y1: number; y2: number }
  ) {
    const svgElement = document.getElementById(`SVG_${this.tool.dictId!!}`);
    const element = document.getElementById(`${this.tool.dictId!!}`);
    if (svgElement) {
      if (element) {
        if (svgPosition) {
          element.setAttribute('x2', `${svgPosition.x2}`);
          element.setAttribute('y2', `${svgPosition.y2}`);
        }
        if (position) {
          this.x1 = position.x1;
          this.x2 = position.x2;
          this.y1 = position.y1;
          this.y2 = position.y2;
        }
      }
      this.svgString = svgElement.outerHTML!!;
      this.notifyPropertyChanged('svgString');
    }
  }

  customClick = () => {
    //do nothing.
  };

  repositionZoomDraw(borders: {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
  }): void {
    if (!this.lastPointValues) {
      return;
    }
    const { xAxis, yAxis } = this.getAxis();
    if (!xAxis || !yAxis) {
      return;
    }
    const actualPositionX = deepClone(
      xAxis
        .getCurrentCoordinateCalculator()
        .getCoordinate(this.lastPointValues.x)
    );
    const actualPositionY = deepClone(
      yAxis
        .getCurrentCoordinateCalculator()
        .getCoordinate(this.lastPointValues.y)
    );
    const element = document.getElementById(`${this.tool.dictId!!}`);
    if (element) {
      const diffX = borders.x2 - actualPositionX;
      const x2 = deepClone(Math.abs(parseFloat(element.getAttribute('x2')!!)));
      const newX2 = x2 - diffX;
      const diffY = borders.y2 - actualPositionY;
      const y2 = deepClone(Math.abs(parseFloat(element.getAttribute('y2')!!)));
      const newY2 = y2 - diffY;
      element.setAttribute('x2', `${newX2}`);
      element.setAttribute('y2', `${newY2}`);
      this.width = newX2;
      this.height = newY2;
    }
    this._repositionZoomDraw$.next({
      function: () => {
        this.repositionTemporaryBox();
        this.updateLineInfoBoxPosition();
      },
    });
  }

  private updateLineInfoBoxPosition() {
    const box = this.tool.children?.find(
      (child) => child.method === TOOLS_METHOD.CUSTOM_BOX_ANNOTATION
    );
    const aux = this.tool.children?.find((child) => child.isAux);
    const childAnnotation = this.baseChart.sciChartSurface.annotations.getById(
      box!!.dictIdChildren!!
    );
    const auxDraw = this.baseChart.sciChartSurface.annotations.getById(
      aux!!.dictIdChildren!!
    );
    childAnnotation.x1 = auxDraw.x1;
    childAnnotation.x2 = auxDraw.x2;
    childAnnotation.y1 = auxDraw.y1;
    childAnnotation.y2 = auxDraw.y2;
  }

  customHover = () => {
    //do nothing.
  };

  updateStyleTool(style: StyleTool) {
    const element = document.getElementById(`${this.tool.dictId!!}`);
    const polylineElement = document.getElementById(
      `POLYLINE_${this.tool.dictId!!}`
    );
    let childrenDraw!: TIGER_CHART_TOOL_CHILD;
    if (this.isChildren && this.tool.children) {
      childrenDraw = this.tool.children.find(
        (child) => child.dictIdChildren === this.dictIdChildren
      )!!;
    }
    if (element) {
      if (style.color) {
        element.setAttribute('stroke', style.color.rgbaHexColor);
        if (polylineElement) {
          polylineElement.setAttribute('stroke', style.color.rgbaHexColor);
        }
        this.style.color = style.color;
        if (childrenDraw) {
          childrenDraw.color = style.color.rgbaHexColor;
        } else {
          this.tool.color = style.color.rgbaHexColor;
        }
      }
      if (style.strokeThickness) {
        element.setAttribute('stroke-width', style.strokeThickness.toString());
        this.style.strokeThickness = style.strokeThickness;
        if (childrenDraw) {
          childrenDraw.lineThickness = style.strokeThickness;
        } else {
          this.tool.lineThickness = style.strokeThickness;
        }
      }
      if (style.strokeDashArray) {
        element.setAttribute(
          'stroke-dasharray',
          style.strokeDashArray[0].toString()
        );
        this.style.strokeDashArray = style.strokeDashArray;
        if (childrenDraw) {
          childrenDraw.strokeDashArray = style.strokeDashArray;
        } else {
          this.tool.strokeDashArray = style.strokeDashArray;
        }
      }
    }
  }

  updateTool(tool: TIGER_CHART_TOOL) {
    this.tool = tool;
  }

  private setStyleConfiguration() {
    if (!this.configuration || this.hasElements) {
      return;
    }
    const element = document.getElementById(`${this.tool.dictId!!}`);
    const polylineElement = document.getElementById(
      `POLYLINE_${this.tool.dictId!!}`
    );
    if (!element || !polylineElement) {
      return;
    } else {
      this.hasElements = true;
    }

    if (this.configuration.color) {
      element.setAttribute('stroke', this.configuration.color);
      if (polylineElement) {
        polylineElement.setAttribute('stroke', this.configuration.color);
      }
    }
    if (this.configuration.lineThickness) {
      element.setAttribute(
        'stroke-width',
        this.configuration.lineThickness.toString()
      );
    }
    if (this.configuration.strokeDashArray) {
      element.setAttribute(
        'stroke-dasharray',
        this.configuration.strokeDashArray[0].toString()
      );
    }
  }

  private getAxis(): Axis {
    const yAxis = this.baseChart.sciChartSurface.yAxes.getById(this.yAxisId);
    const xAxis = this.baseChart.sciChartSurface.xAxes.getById(this.xAxisId);
    if (!yAxis || !xAxis) {
      return { xAxis: undefined, yAxis: undefined };
    }
    try {
      yAxis.getCurrentCoordinateCalculator();
      xAxis.getCurrentCoordinateCalculator();
    } catch (e: any) {
      return { xAxis: undefined, yAxis: undefined };
    }
    return { yAxis, xAxis };
  }
}
