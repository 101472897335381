export const percentagePriceOscillatorTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  O Oscilador de Preço PPO (Percentage Price Oscillator) é um indicador técnico
  de análise de ações que mede a diferença percentual entre duas médias móveis
  exponenciais. Ele é semelhante ao MACD, mas usa uma diferença percentual em
  vez de uma diferença de preço.
</div>
<div class="mb-3">
  O PPO é usado para identificar a direção da tendência dos preços das ações. Um
  PPO positivo indica uma tendência de alta, enquanto um PPO negativo indica uma
  tendência de baixa. O PPO também pode ser usado para identificar divergências
  entre o preço das ações e o indicador, o que pode indicar uma reversão
  iminente da tendência.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">
  O cálculo do Oscilador de Preço PPO é feito subtraindo a média móvel
  exponencial de 26 períodos da média móvel exponencial de 12 períodos. Em
  seguida, divide-se o resultado pelo valor da média móvel exponencial de 26
  períodos. O resultado é então multiplicado por 100 para produzir uma
  porcentagem.
</div>
<div class="mb-3">
  PPO = ((MME de 12 períodos - MME de 26 períodos) / MME de 26 períodos) x 100
</div>
<div class="fw-bolder">Exemplo de cálculo</div>
<div class="mb-3">Um exemplo de cálculo do PPO seria o seguinte:</div>
<div class="mb-3">
  Suponha que o preço de uma ação é de R$ 50, e a MME de 12 períodos é de R$ 48
  e a MME de 26 períodos é de R$ 45. O cálculo do PPO seria:
</div>
<div class="mb-3">((48 - 45) / 45) x 100 = 6,67%</div>
<div class="mb-3">
  Isso significa que o PPO é positivo e indica uma tendência de alta nos preços
  das ações.
</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  O PPO é usado principalmente para identificar a direção da tendência dos
  preços das ações. Quando o PPO é positivo, indica uma tendência de alta,
  enquanto um PPO negativo indica uma tendência de baixa. Quando o PPO cruza a
  linha zero, é considerado um sinal de reversão da tendência atual.
</div>
<div class="mb-3">
  Os traders também usam o PPO para identificar divergências entre o preço das
  ações e o indicador. Quando o preço da ação está subindo, mas o PPO está
  caindo, isso pode indicar uma possível reversão da tendência de alta. Da mesma
  forma, quando o preço da ação está caindo, mas o PPO está subindo, isso pode
  indicar uma possível reversão da tendência de baixa.
</div>
<div class="mb-3">
  No entanto, é importante lembrar que nenhum indicador é infalível. É sempre
  importante usar o PPO em conjunto com outras ferramentas de análise técnica e
  levar em consideração outros fatores, como notícias e eventos mundiais que
  podem afetar o mercado de ações.
</div>
<div class="mb-3">
  <img class="w-75" src="/assets/indicators/ppo-como-funciona.png" />
</div>
<div class="fw-bolder">Como configurar</div>
<div class="fw-bolder">Valores</div>
<div class="text-decoration-underline">Período Curto</div>
<div class="mb-3">
  O período curto a ser usado no cálculo do PPO. 10 dias é o padrão.
</div>
<div class="text-decoration-underline">Período Longo</div>
<div class="mb-3">
  O período longo a ser usado no cálculo da PPO. 21 dias é o padrão.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/ppo-entrada.png"
  />
</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">PPO</div>
<div class="mb-3">
  Pode alternar a visibilidade do PPO. Também pode selecionar a cor, a espessura
  e o estilo da linha do PPO.
</div>
<div class="mb-3">
  <img class="image-indicators-input" src="/assets/indicators/ppo-estilo.png" />
</div>
`;
