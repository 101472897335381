<rocket-modal
  [ngClass]="{
    'token-config': !disconnectingName
  }"
>
  <rocket-modal-header>
    <div>
      <h3 class="modal-title">
        {{ title }}
      </h3>
    </div>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <ng-container *ngIf="loading; else content">
      <div class="h-100">
        <app-loading></app-loading>
      </div>
    </ng-container>

    <ng-template #content>
      <div class="mb-6">
        <ng-container *ngIf="selectedAuthenticator; else authenticators">
          <app-token-authenticator-instructions
            [authenticator]="selectedAuthenticator"
            (clearSelectedAuth)="clearSelectedAuth()"
            (reloadConfig)="getTokenPreference()"
          ></app-token-authenticator-instructions>
        </ng-container>

        <ng-template #authenticators>
          <app-token-authenticators-list
            [tokenPreference]="tokenPreference"
            (changeAutenticator)="selectAuthenticator($event)"
            (reloadConfig)="getTokenPreference()"
            (disconnecting)="onDisconnecting($event)"
          ></app-token-authenticators-list>
        </ng-template>
      </div>
    </ng-template>
  </app-rocket-modal-body>
</rocket-modal>
