import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoComponent } from './logo.component';
import { PathAgoraComponent } from './path-agora/path-agora.component';
import { PathRocketComponent } from './path-rocket/path-rocket.component';

@NgModule({
  declarations: [LogoComponent, PathAgoraComponent, PathRocketComponent],
  imports: [CommonModule],
  exports: [LogoComponent],
  providers: [],
})
export class LogoModule {}
