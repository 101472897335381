import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { WorkspacesPreferencesInterface } from './workspaces-preferences.interface';
import { IWorkSpace } from '@core/interface';
import { Dictionary } from '@core/models';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { Subject, filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkspacesPreferences
  extends CustomPreferencesService
  implements WorkspacesPreferencesInterface
{
  override KEYS = {
    VISIBLE_WORKSPACE_ORDER: `VISIBLE_WORKSPACE_ORDER`,
    LOADED_DEFAULT_WS: `LOADED_DEFAULT_WS`,
    ALL_WS_DEFAULTS: `ALL_WS_DEFAULTS`,
    INITIAL_TOUR: `INITIAL_TOUR`,
    LOCK_WORKSPACE: `LOCK_WORKSPACE`,
  };

  private _waitToDisplayTour: boolean = false;
  private _canDisplayTour$: Subject<boolean> = new Subject<boolean>();
  private _allWorkspacesDefaults: Dictionary<any> = new Dictionary();

  constructor(http: HttpClient) {
    super(http);
  }

  onLockWorkspaceChange = () =>
    this.onChangePreference().pipe(
      filter((data: any) => data && Object.keys(this.KEYS).includes(data.key))
    );

  setLockWorkspace(data: any) {
    this.setValueCustomPreferences(
      this.KEYS.LOCK_WORKSPACE,
      JSON.stringify(data)
    );
  }

  get lockWorkspace() {
    const lock = this.storage.hasKey(this.KEYS.LOCK_WORKSPACE)
      ? this.storage.getItem(this.KEYS.LOCK_WORKSPACE)
      : 'false';
    return JSON.parse(lock);
  }

  get visibleWorkspaceOrder() {
    const item =
      this.storage.getItem(this.KEYS.VISIBLE_WORKSPACE_ORDER) ?? '[]';
    return JSON.parse(item);
  }

  set visibleWorkspaceOrder(e: any) {
    this.setValueCustomPreferences(
      this.KEYS.VISIBLE_WORKSPACE_ORDER,
      JSON.stringify(e)
    );
  }

  set loadedDefaultWS(e: boolean) {
    this.setValueCustomPreferences(this.KEYS.LOADED_DEFAULT_WS, e ? '1' : '0');
  }

  get loadedDefaultWS() {
    return this.storage.getItem(this.KEYS.LOADED_DEFAULT_WS) === '1';
  }

  get allWorkspacesDefaults() {
    const item = this.storage.getItem(this.KEYS.ALL_WS_DEFAULTS) ?? '[]';
    const data = JSON.parse(item);
    this._allWorkspacesDefaults.bulkData('id', data);
    return this._allWorkspacesDefaults;
  }

  set displayInitialTour(display: boolean) {
    this.setValueCustomPreferences(this.KEYS.INITIAL_TOUR, display ? '1' : '0');
  }

  get displayInitialTour(): boolean {
    const storageInfo = this.storage.getItem(this.KEYS.INITIAL_TOUR);
    if (isNullOrUndefined(storageInfo)) return false;
    return storageInfo === '1';
  }

  set waitToDisplayInitialTour(display: boolean) {
    this._waitToDisplayTour = display;
    this._canDisplayTour$.next(!display);
  }

  get waitToDisplayInitialTour(): boolean {
    return this._waitToDisplayTour;
  }

  onCanDisplayTourChange = () => this._canDisplayTour$.asObservable();

  loadPreference(param: any[]) {
    if (!this.visibleWorkspaceOrder) {
      this.setVisibleWorkspaces(param);
      return param;
    }

    const arrayMap = param.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue.id]: currentValue,
      }),
      {}
    );
    const sortingArr = [
      ...new Set([
        ...this.visibleWorkspaceOrder,
        ...param.map((item: any) => item.id),
      ]),
    ];
    const result = sortingArr
      .map((key: string) => arrayMap[key])
      .filter((item) => item);
    return result;
  }

  setVisibleWorkspaces(param: IWorkSpace[]) {
    const ids = param.map((item) => item.id);
    this.visibleWorkspaceOrder = ids;
  }

  setAllWorkspaceDefault(workspaces: IWorkSpace[]) {
    this._allWorkspacesDefaults.bulkData('id', workspaces);
    this.setValueCustomPreferences(
      this.KEYS.ALL_WS_DEFAULTS,
      JSON.stringify(this._allWorkspacesDefaults.values())
    );
  }
}
