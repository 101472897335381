import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { THEMES } from '@shared/services/core/custom-preferences/theme/theme-preferences.interface';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { THEME_COLOR } from './path-agora.consts';
import { Subscription } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-path-agora]',
  templateUrl: './path-agora.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathAgoraComponent implements AfterViewInit, OnDestroy {
  @Input() withIcon: boolean = false;
  themeSVG = THEME_COLOR.DARK;
  private themeActiveObservable$!: Subscription;

  constructor(
    private themeService: ThemePreferencesService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.themeActiveObservable$ && this.themeActiveObservable$.unsubscribe();
  }

  ngAfterViewInit() {
    this.themeActiveObservable$ = this.themeService
      .themeActiveObservable()
      .subscribe((theme) => {
        const isDark = theme == THEMES.dark;
        this.themeSVG = isDark ? THEME_COLOR.DARK : THEME_COLOR.LIGHT;
        this.cdr.detectChanges();
      });
  }
}
