<workspace-doc-header css="w-100 d-flex header-wrapper" [item]="component">
  <fla-workspace-doc-header-item [enableContent]="true" [hideBorder]="true">
    <div class="d-flex justify-content-between w-100">
      <section class="hstack">
        <a
          type="button"
          class="nav-link p-2 lh-sm border-right fw-bolder text-center text-brand-primary"
        >
          Resumo de mercado
        </a>
      </section>
    </div>
  </fla-workspace-doc-header-item>
</workspace-doc-header>
