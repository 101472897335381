<div
  class="bg-neutral-stronger border border-brand-primary p-2 reposition-token round toast-token"
>
  <div class="d-flex flex-column h-100 justify-content-center w-100">
    <div class="d-flex align-items-center justify-content-between gap-2">
      <div class="d-flex gap-2">
        <div class="position-relative">
          <img
            *ngIf="!data.isConfig"
            [src]="data.icon"
            [alt]="data.id"
            width="32px"
            class="mr-2"
          />
          <fla-icon
            iconType="icons"
            [size]="data.isConfig ? 'icon-size-md' : 'icon-size-micro'"
            name="lock"
            [css]="data.isConfig ? '' : 'position-absolute left-50 top-50'"
          ></fla-icon>
        </div>
        <div class="fs-6" [innerHTML]="data.msg"></div>
      </div>
      <div *ngIf="data.isConfig">
        <rocket-button
          label="Configurar"
          (rocketClick)="openConfigTokenModal()"
          [isSmall]="true"
        ></rocket-button>
      </div>
      <div>
        <rocket-button type="icon" (rocketClick)="close()">
          <fla-icon size="icon-size-micro" name="close"></fla-icon>
        </rocket-button>
      </div>
    </div>
    <div class="d-flex align-items-center gap-3 pt-3" *ngIf="!data.isConfig">
      <div>
        <app-input-code
          #codeInput
          [codeLength]="6"
          [initialFocusField]="fieldToFocus"
          [isSmall]="true"
          [resetValues]="clearInputValues"
          (codeCompleted)="codeCompleted($event)"
          (codeChanged)="codeChanged($event)"
        ></app-input-code>
      </div>
      <div *ngIf="showConfirm">
        <rocket-button
          label="Confirmar"
          (rocketClick)="executeTokenValidation()"
          [isSmall]="true"
          [isDisabled]="isDisabled"
        ></rocket-button>
      </div>
    </div>
  </div>
</div>
