import { TableHeaderComponent } from '../header/table-header.component';
import { TABLE_IDS } from './../../constants/table.constants';
import { Component, ContentChildren, Input, QueryList } from '@angular/core';

@Component({
  selector: 'app-table-row',
  template: `
    <tr
      [id]="idRow"
      [attr.data-bs-parent]="idParentRow"
      [ngClass]="{ 'd-none': !visible, 'sticky-header': fixedHeader }"
      [class]="css"
    >
      <ng-container *ngFor="let header of tableHeader">
        <ng-container *ngTemplateOutlet="header.content"></ng-container>
      </ng-container>
      <ng-content></ng-content>
    </tr>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class TableRowComponent {
  @Input() ref!: string | number;
  @Input() refParent!: string | number;
  @Input() visible: boolean = true;
  @Input() css: string = '';
  @Input() fixedHeader: boolean = false;

  @ContentChildren(TableHeaderComponent)
  tableHeader!: QueryList<TableHeaderComponent>;

  get idRow() {
    return this.ref ? TABLE_IDS.ROW + this.ref : '';
  }
  get idParentRow() {
    return this.refParent ? TABLE_IDS.ROW + this.refParent : '';
  }
}
