<div id="resumeList" class="d-flex h-100 overflow-auto w-100">
  <cdk-virtual-scroll-viewport
    #scrollViewport
    itemSize="46"
    class="w-100"
    [ngClass]="css"
  >
    <fla-card class="pr-2 w-100">
      <fla-card-custom
        #card
        [css]="
          'p-1 bg-neutral-strong mt-2' + (hasEvents ? ' cursor-pointer' : '')
        "
        *cdkVirtualFor="let list of lists"
      >
        <div
          class="card-body justify-content-center align-items-center w-100"
          (click)="documentOpen(list)"
        >
          <div class="d-flex justify-content-between w-100">
            <div class="d-flex align-items-center">
              <div
                class="p-1 dateEventContainer bg-dark mr-2 round d-flex flex-column justify-content-center align-items-center"
              >
                <span class="fs-7">{{ list.dateString }}</span>
                <span class="fs-7 text-neutral-medium">{{ list.yearDoc }}</span>
              </div>
            </div>
            <p
              class="card-title fs-6 flex-grow-1"
              [ngClass]="{
                'mt-2 text-truncate': hasEvents, 
                'd-flex flex-column justify-content-center': !hasEvents
              }"
              [innerHTML]="list.body"
            ></p>
            <div
              *ngIf="hasEvents"
              class="d-flex align-items-center justify-content-end"
            >
              <fla-icon
                name="chevron_right"
                css="cursor-pointer text-brand-primary"
                size="icon-size-sm"
                [isVmIcon]="false"
              >
              </fla-icon>
            </div>
          </div>
        </div>
      </fla-card-custom>
    </fla-card>
  </cdk-virtual-scroll-viewport>
</div>
