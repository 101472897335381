import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrategySelectorComponent } from './strategy-selector.component';
import { FlaIconModule } from '@shared/rocket-components/components';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';

@NgModule({
  imports: [CommonModule, FlaIconModule, TooltipsModule, RTDropdownModule],
  declarations: [StrategySelectorComponent],
  exports: [StrategySelectorComponent],
})
export class StrategySelectorModule {}
