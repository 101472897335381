import { Routes, RouterModule } from '@angular/router';
import { StockAnalysisComponent } from './stock-analysis.component';

const routes: Routes = [
  {
    path: '',
    component: StockAnalysisComponent,
  },
];

export const StockAnalysisRoutes = RouterModule.forChild(routes);
