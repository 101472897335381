import {
  DefaultPaletteProvider,
  IPointMetadata,
  IRenderableSeries,
  OhlcDataSeries,
  parseColorToUIntArgb,
} from 'scichart';
import { CHART_COLORS } from '../colors';
import { IElephantCandles } from '../indicators/elephant-bar';

export class CandlePaletteProvider extends DefaultPaletteProvider {
  highlightColor = 0;
  highlightPositiveElephantBarColor = 0;
  highlightNegativeElephantBarColor = 0;
  private elephantBars!: IElephantCandles;
  useElephantBars: boolean = false;
  constructor() {
    super();
    this.highlightColor = parseColorToUIntArgb(CHART_COLORS.BRAND_PRIMARY);
    this.highlightPositiveElephantBarColor = parseColorToUIntArgb(
      CHART_COLORS.FEEDBACK_POSITIVE
    );
    this.highlightNegativeElephantBarColor = parseColorToUIntArgb(
      CHART_COLORS.FEEDBACK_NEGATIVE
    );
  }

  setHighLightElephantBarsColor = (
    positiveColor: string,
    negativeColor: string
  ) => {
    this.highlightPositiveElephantBarColor =
      parseColorToUIntArgb(positiveColor);
    this.highlightNegativeElephantBarColor =
      parseColorToUIntArgb(negativeColor);
  };

  override onAttached(parentSeries: IRenderableSeries): void {
    super.onAttached(parentSeries);
    this.parentSeries = parentSeries;
  }

  override overrideStrokeArgb(
    xValue: number,
    yValue: number,
    index: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    opacity?: number | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    metadata?: IPointMetadata | undefined
  ): number {
    return this.overrideColorAt(
      index,
      this.parentSeries.dataSeries as OhlcDataSeries
    );
  }

  override overrideFillArgb(
    xValue: number,
    yValue: number,
    index: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    opacity?: number | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    metadata?: IPointMetadata | undefined
  ): number {
    return this.overrideColorAt(
      index,
      this.parentSeries.dataSeries as OhlcDataSeries
    );
  }

  overrideColorAt(index: number, ohlcDataSeries: OhlcDataSeries) {
    const close = ohlcDataSeries.getNativeCloseValues().get(index);
    const open = ohlcDataSeries.getNativeOpenValues().get(index);
    if (this.useElephantBars && this.elephantBars) {
      const positive = this.elephantBars.positive;
      const negative = this.elephantBars.negative;
      if (positive.has(index)) {
        return this.highlightPositiveElephantBarColor;
      }
      if (negative.has(index)) {
        return this.highlightNegativeElephantBarColor;
      }
    } else if (open == close) {
      return this.highlightColor;
    }
    return -1;
  }

  updateElephantBars = (elephantBars: IElephantCandles) => {
    //console.log(elephantBars);
    this.elephantBars = elephantBars;
  };
}

export const defaultPositiveElephantColor = '#FEFEFE';
export const defaultNegativeElephantColor = '#050505';
