<div class="container d-flex flex-column">
  <div class="d-flex align-items-center justify-content-center my-5">
    <app-logo [width]="230" [height]="45"></app-logo>
    <img src="assets/icons/icon-72x72.png" height="25"/>
  </div>
  <div class="card p-5">
    <fla-icon name="computer" size="icon-size-lg" css="text-brand-primary mb-5"></fla-icon>
    <h2 class="mb-5">Olá, {{ userFirstName }}</h2>
    <p>Vimos que você está acessando por um <b>celular.</b></p>
    <p>Para aproveitar todas as nossas <b>funcionalidades</b> recomendamos que o acesso seja feito pelo navegador de um <b>computador.</b></p>
  </div>
</div>