import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  TOOLS_CALCULATION,
  TOOLS_ENUM,
  TOOLS_METHOD,
} from '@shared/tiger-chart/tiger-chart-tools/tiger-chart-tools.enum';
import { DRAW } from '../interface/draws.interface';
import { DrawTools } from '@shared/tiger-chart/draw-tools/draw-tools.interface';
import { ECoordinateMode } from 'scichart/Charting/Visuals/Annotations/AnnotationBase';
import {
  EHorizontalAnchorPoint,
  EVerticalAnchorPoint,
} from 'scichart/types/AnchorPoint';
import { ELabelPlacement } from 'scichart/types/LabelPlacement';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import { deepClone } from '@shared/rocket-components/utils';
import { TIGER_CHART_TOOL } from '@shared/tiger-chart/tiger-chart-tools/tiger-chart-tools.interface';
import { Observable, ReplaySubject } from 'rxjs';
import { AxisService } from '@shared/tiger-chart/services/axis.service';
import {
  ArrowLineTools,
  BoxTools,
  CircleTools,
  CustomBoxTools,
  FibonacciRetracementTools,
  FlagTools,
  HorizontalLineTools,
  LineTools,
  TextTools,
  TrendAngleLineTools,
  VerticalLineTools,
  IconTools,
} from '@shared/tiger-chart/draw-tools';

@Injectable({
  providedIn: 'root',
})
export class DrawsService {
  public isDrawingLine: boolean = false;
  private drawClick$ = new ReplaySubject<{
    type: 'CLICK' | 'DRAG';
    tool: TIGER_CHART_TOOL;
    childrenId?: string;
  }>();

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private axisService: AxisService
  ) {}

  get drawClick(): Observable<{
    type: 'CLICK' | 'DRAG';
    tool: TIGER_CHART_TOOL;
    childrenId?: string;
  }> {
    return this.drawClick$.asObservable();
  }

  getDraw(data: DRAW): DrawTools | undefined {
    let draw!: DrawTools;
    const isFibo =
      data.tool.calculation === TOOLS_CALCULATION.FIBONACCI_RETRACEMENT;
    switch (data.tool.method) {
      case TOOLS_METHOD.TEXT_ANNOTATION: {
        draw = new TextTools(
          {
            id: data.childrenId || data.tool.dictId,
            x1: data.configuration?.x1 || data.eventData.pointValues!!.x,
            y1: data.configuration?.y1 || data.eventData.pointValues!!.y,
            xCoordinateMode: ECoordinateMode.DataValue,
            yCoordinateMode: ECoordinateMode.DataValue,
            verticalAnchorPoint:
              data.configuration && data.configuration.verticalAnchorPoint
                ? data.configuration.verticalAnchorPoint
                : data.tool.verticalAnchorPoint,
            horizontalAnchorPoint:
              data.configuration && data.configuration.horizontalAnchorPoint
                ? data.configuration.horizontalAnchorPoint
                : data.tool.horizontalAnchorPoint,
            text: data.configuration?.text || data.tool.text || '',
            dragPoints: data.configuration?.dragPoints || data.tool.dragPoints,
            xCoordShift:
              data.configuration && data.configuration.xCoordShift
                ? data.configuration.xCoordShift
                : undefined,
            yCoordShift:
              data.configuration && data.configuration.yCoordShift
                ? data.configuration.yCoordShift
                : undefined,
            textColor:
              data.configuration?.textColor ||
              data.tool.textColor ||
              data.configuration?.color ||
              data.tool.color ||
              CHART_COLORS.FEEDBACK_WARNING,
            isEditable: false,
            fontSize: data.configuration?.textSize || data.tool.textSize || 12,
            fontFamily: 'Metropolis',
            xAxisId: `xAxis_${data.refComponent}`,
            isHidden: data.configuration?.isHidden || data.tool.isHidden,
            onClick: () => {
              this.drawClick$.next({ type: 'CLICK', tool: data.tool });
            },
          },
          data.drawToolsService,
          data.toolsOption
        );
        break;
      }
      case TOOLS_METHOD.BOX_ANNOTATION: {
        draw = new BoxTools(
          {
            id: data.childrenId || data.tool.dictId,
            x1: data.configuration?.x1 || data.eventData.pointValues!!.x,
            x2: data.configuration?.x2 || data.eventData.pointValues!!.x,
            y1: data.configuration?.y1 || data.eventData.pointValues!!.y,
            y2: data.configuration?.y2 || data.eventData.pointValues!!.y,
            xCoordinateMode: ECoordinateMode.DataValue,
            yCoordinateMode: ECoordinateMode.DataValue,
            fill:
              data.configuration?.backgroundColor ||
              data.configuration?.color ||
              data.tool.backgroundColor ||
              data.tool.color ||
              CHART_COLORS.FEEDBACK_WARNING,
            stroke: data.configuration?.color || data.tool.color || undefined,
            isEditable: false,
            strokeThickness: isFibo
              ? 0
              : data.configuration?.lineThickness ||
                data.tool.lineThickness ||
                0,
            xAxisId: `xAxis_${data.refComponent}`,
            dragPoints: data.configuration?.dragPoints || data.tool.dragPoints,
            resizeDirections:
              data.configuration?.resizeDirections ||
              data.tool.resizeDirections,
            selectionBoxStroke: 'transparent',
            onClick: () => {
              this.drawClick$.next({ type: 'CLICK', tool: data.tool });
            },
          },
          data.drawToolsService,
          data.toolbarDrawService,
          data.toolsOption,
          this.locale
        );
        break;
      }
      case TOOLS_METHOD.CUSTOM_BOX_ANNOTATION: {
        draw = new CustomBoxTools(
          {
            id: data.childrenId || data.tool.dictId,
            x1: data.eventData.pointValues!!.x,
            y1: data.eventData.pointValues!!.y,
            svgString:
              data.configuration && data.configuration.svgString
                ? this.getSvgString(
                    deepClone({
                      ...data.tool,
                      svgString: data.configuration.svgString,
                      dictId: data.childrenId
                        ? data.childrenId
                        : data.tool.dictId,
                    }),
                    data.toolsOption.viewBoxWidth,
                    data.toolsOption.viewBoxHeight
                  )
                : data.tool.svgString
                ? this.getSvgString(
                    data.tool,
                    data.toolsOption.viewBoxWidth,
                    data.toolsOption.viewBoxHeight
                  )
                : undefined,
            xCoordinateMode: ECoordinateMode.DataValue,
            yCoordinateMode: ECoordinateMode.DataValue,
            isEditable: false,
            xAxisId: `xAxis_${data.refComponent}`,
            isHidden: data.configuration?.isHidden || data.tool.isHidden,
            onClick: () => {
              this.drawClick$.next({ type: 'CLICK', tool: data.tool });
            },
          },
          data.drawToolsService,
          data.toolsOption
        );
        break;
      }
      case TOOLS_METHOD.FIBONACCI_RETRACEMENT: {
        draw = new FibonacciRetracementTools(
          {
            id: data.childrenId || data.tool.dictId,
            x1: data.eventData.pointValues!!.x,
            y1: data.eventData.pointValues!!.y,
            xCoordinateMode: ECoordinateMode.DataValue,
            yCoordinateMode: ECoordinateMode.DataValue,
            isEditable: false,
            xAxisId: `xAxis_${data.refComponent}`,
          },
          data.drawToolsService,
          data.toolsOption,
          this.locale
        );
        break;
      }
      case TOOLS_METHOD.DRAW_ARROW_LINE: {
        draw = new ArrowLineTools(
          {
            id: data.childrenId || data.tool.dictId,
            x1: data.configuration?.x1 || data.eventData.pointValues!!.x,
            y1: data.configuration?.y1 || data.eventData.pointValues!!.y,
            xCoordinateMode: ECoordinateMode.DataValue,
            yCoordinateMode: ECoordinateMode.DataValue,
            verticalAnchorPoint: EVerticalAnchorPoint.Top,
            horizontalAnchorPoint: EHorizontalAnchorPoint.Left,
            isEditable: false,
            svgString: this.getSvgString(
              data.tool,
              data.toolsOption.viewBoxWidth,
              data.toolsOption.viewBoxHeight
            ),
            xAxisId: `xAxis_${data.refComponent}`,
            onClick: () => {
              this.drawClick$.next({ type: 'CLICK', tool: data.tool });
            },
          },
          data.drawToolsService,
          data.toolsOption,
          this.locale
        );

        break;
      }
      case TOOLS_METHOD.DRAW_LINE: {
        this.isDrawingLine = true;
        draw = new LineTools(
          {
            id: data.childrenId || data.tool.dictId,
            x1: data.configuration?.x1 || data.eventData.pointValues!!.x,
            x2: data.configuration?.x2 || data.eventData.pointValues!!.x,
            y1: data.configuration?.y1 || data.eventData.pointValues!!.y,
            y2: data.configuration?.y2 || data.eventData.pointValues!!.y,
            xCoordinateMode: ECoordinateMode.DataValue,
            yCoordinateMode: ECoordinateMode.DataValue,
            strokeDashArray:
              data.configuration && data.configuration.strokeDashArray
                ? data.configuration.strokeDashArray
                : undefined,
            isEditable: false,
            strokeThickness:
              data.configuration?.lineThickness || data.tool.lineThickness || 2,
            stroke:
              data.configuration?.color ||
              data.tool.color ||
              CHART_COLORS.FEEDBACK_WARNING,
            xAxisId: `xAxis_${data.refComponent}`,
            dragPoints: data.configuration?.dragPoints || data.tool.dragPoints,
            resizeDirections:
              data.configuration?.resizeDirections ||
              data.tool.resizeDirections,
            selectionBoxStroke: 'transparent',
            onClick: () => {
              this.drawClick$.next({ type: 'CLICK', tool: data.tool });
            },
            onDragEnded: () => {
              this.isDrawingLine = false;
            },
          },
          data.drawToolsService,
          data.toolbarDrawService,
          data.toolsOption,
          this.locale
        );
        break;
      }
      case TOOLS_METHOD.HORIZONTAL_LINE: {
        draw = new HorizontalLineTools(
          {
            labelPlacement: ELabelPlacement.Axis,
            id: data.childrenId || data.tool.dictId,
            y1: data.configuration?.y1 || data.eventData.pointValues!!.y,
            x1:
              data.tool.codTool === `${TOOLS_ENUM.TREND_LINES}_RAIO_HORIZONTAL`
                ? data.configuration?.x1 || data.eventData.pointValues!!.x
                : undefined,
            yCoordinateMode: ECoordinateMode.DataValue,
            isEditable: false,
            showLabel: true,
            strokeThickness:
              data.configuration?.lineThickness || data.tool.lineThickness || 2,
            strokeDashArray:
              data.configuration && data.configuration.strokeDashArray
                ? data.configuration.strokeDashArray
                : undefined,
            stroke:
              data.configuration?.color ||
              data.tool.color ||
              CHART_COLORS.FEEDBACK_WARNING,
            axisLabelFill:
              data.configuration?.color ||
              data.tool.color ||
              CHART_COLORS.FEEDBACK_WARNING,
            xAxisId: `xAxis_${data.refComponent}`,
            dragPoints: data.configuration?.dragPoints || data.tool.dragPoints,
            resizeDirections:
              data.configuration?.resizeDirections ||
              data.tool.resizeDirections,
            selectionBoxStroke: 'transparent',
            onClick: () => {
              this.drawClick$.next({ type: 'CLICK', tool: data.tool });
            },
          },
          data.drawToolsService,
          data.toolsOption
        );
        break;
      }
      case TOOLS_METHOD.VERTICAL_LINE: {
        draw = new VerticalLineTools(
          {
            labelPlacement: ELabelPlacement.Axis,
            id: data.childrenId || data.tool.dictId,
            x1: data.configuration?.x1 || data.eventData.pointValues!!.x,
            xCoordinateMode: ECoordinateMode.DataValue,
            isEditable: false,
            strokeThickness:
              data.configuration?.lineThickness || data.tool.lineThickness || 2,
            strokeDashArray:
              data.configuration && data.configuration.strokeDashArray
                ? data.configuration.strokeDashArray
                : undefined,
            stroke:
              data.configuration?.color ||
              data.tool.color ||
              CHART_COLORS.FEEDBACK_WARNING,
            axisLabelFill:
              data.configuration?.color ||
              data.tool.color ||
              CHART_COLORS.FEEDBACK_WARNING,
            xAxisId: `xAxis_${data.refComponent}`,
            dragPoints: data.configuration?.dragPoints || data.tool.dragPoints,
            resizeDirections:
              data.configuration?.resizeDirections ||
              data.tool.resizeDirections,
            selectionBoxStroke: 'transparent',
            onClick: () => {
              this.drawClick$.next({ type: 'CLICK', tool: data.tool });
            },
            onDrag: () => {
              if (data.tool.shared) {
                this.drawClick$.next({
                  type: 'DRAG',
                  tool: data.tool,
                  childrenId: data.childrenId,
                });
              }
            },
          },
          data.drawToolsService,
          data.toolsOption,
          this.axisService
        );
        break;
      }
      case TOOLS_METHOD.TREND_ANGLE_LINE: {
        draw = new TrendAngleLineTools(
          {
            id: data.childrenId || data.tool.dictId,
            x1: data.configuration?.x1 || data.eventData.pointValues!!.x,
            y1: data.configuration?.y1 || data.eventData.pointValues!!.y,
            xCoordinateMode: ECoordinateMode.DataValue,
            yCoordinateMode: ECoordinateMode.DataValue,
            svgString: this.getSvgString(
              data.tool,
              data.toolsOption.viewBoxWidth,
              data.toolsOption.viewBoxHeight
            ),
            isEditable: false,
            xAxisId: `xAxis_${data.refComponent}`,
            onClick: () => {
              this.drawClick$.next({ type: 'CLICK', tool: data.tool });
            },
          },
          data.drawToolsService,
          data.toolsOption,
          this.locale
        );
        break;
      }
      case TOOLS_METHOD.CIRCLE_ANNOTATION: {
        draw = new CircleTools(
          {
            id: data.childrenId || data.tool.dictId,
            x1: data.configuration?.x1 || data.eventData.pointValues!!.x,
            y1: data.configuration?.y1 || data.eventData.pointValues!!.y,
            xCoordinateMode: ECoordinateMode.DataValue,
            yCoordinateMode: ECoordinateMode.DataValue,
            svgString: this.getSvgString(
              data.tool,
              data.toolsOption.viewBoxWidth,
              data.toolsOption.viewBoxHeight
            ),
            isEditable: false,
            xAxisId: `xAxis_${data.refComponent}`,
            dragPoints: data.configuration?.dragPoints || data.tool.dragPoints,
            resizeDirections:
              data.configuration?.resizeDirections ||
              data.tool.resizeDirections,
            selectionBoxStroke: 'transparent',
            onClick: () => {
              this.drawClick$.next({ type: 'CLICK', tool: data.tool });
            },
          },
          data.drawToolsService,
          data.toolsOption
        );
        break;
      }
      case TOOLS_METHOD.FLAG: {
        draw = new FlagTools(
          {
            id: data.childrenId || data.tool.dictId,
            x1: data.configuration?.x1 || data.eventData.pointValues!!.x,
            y1: data.configuration?.y1 || data.eventData.pointValues!!.y,
            xCoordinateMode: ECoordinateMode.DataValue,
            yCoordinateMode: ECoordinateMode.DataValue,
            svgString: this.getSvgString(
              data.tool,
              data.toolsOption.viewBoxWidth,
              data.toolsOption.viewBoxHeight
            ),
            isEditable: false,
            xAxisId: `xAxis_${data.refComponent}`,
            dragPoints: data.configuration?.dragPoints || data.tool.dragPoints,
            resizeDirections:
              data.configuration?.resizeDirections ||
              data.tool.resizeDirections,
            selectionBoxStroke: 'transparent',
            onClick: () => {
              this.drawClick$.next({ type: 'CLICK', tool: data.tool });
            },
          },
          data.drawToolsService,
          data.toolsOption
        );
        break;
      }
      case TOOLS_METHOD.ICON: {
        draw = new IconTools(
          {
            id: data.childrenId || data.tool.dictId,
            x1: data.configuration?.x1 || data.eventData.pointValues!!.x,
            y1: data.configuration?.y1 || data.eventData.pointValues!!.y,
            xCoordinateMode: ECoordinateMode.DataValue,
            yCoordinateMode: ECoordinateMode.DataValue,
            svgString:
              data.configuration && data.configuration.svgString
                ? this.getSvgString(
                    deepClone({
                      ...data.tool,
                      svgString: data.configuration.svgString,
                      dictId: data.childrenId
                        ? data.childrenId
                        : data.tool.dictId,
                    }),
                    data.toolsOption.viewBoxWidth,
                    data.toolsOption.viewBoxHeight
                  )
                : this.getSvgString(
                    data.tool,
                    data.toolsOption.viewBoxWidth,
                    data.toolsOption.viewBoxHeight
                  ),
            isEditable: false,
            xAxisId: `xAxis_${data.refComponent}`,
            dragPoints: data.configuration?.dragPoints || data.tool.dragPoints,
            resizeDirections:
              data.configuration?.resizeDirections ||
              data.tool.resizeDirections,
            selectionBoxStroke: 'transparent',
            onClick: () => {
              this.drawClick$.next({ type: 'CLICK', tool: data.tool });
            },
          },
          data.drawToolsService,
          data.toolsOption
        );
        break;
      }
      default: {
        console.error('ferramenta nao especificada');
        break;
      }
    }
    return draw;
  }

  getSvgString(
    tool: TIGER_CHART_TOOL,
    viewBoxWidth: number,
    viewBoxHeight: number
  ): string {
    const replaced = tool
      .svgString!!.replace('$WIDTH', `${viewBoxWidth}`)
      .replace('$HEIGHT', `${viewBoxHeight}`)
      .replace(/\$CODTOOL/g, `${tool.dictId}`);
    return replaced;
  }
}
