import { ColDef } from 'ag-grid-community';
import { formatterPercentageFields } from 'src/app/utils/cell-format.functions';
import { bigValueFormatter } from 'src/app/utils/utils.functions';
import { formatterNumber } from 'src/app/utils/utils.functions';
import { ActionButtonsCellRendererComponent } from '../parts/list/action-buttons-cell-renderer/action-buttons-cell-renderer.component';
import { StyleCellRendererComponent } from '../parts/list/style-cell-renderer/style-cell-renderer.component';
import {
  NumberComparator,
  OPTIONS_TYPES,
  OptionComparator,
  TOptions,
} from './options.types';

const default_options: Partial<ColDef> = {
  cellStyle: { textAlign: 'right' },
  sortable: true,
  sortingOrder: ['asc', 'desc'],
  hide: false,
};

export const OPTIONS_TABLE_CONFIG_HEADERS: { [key: string]: ColDef } = {
  label: {
    headerName: 'Opção',
    headerTooltip: 'Opção',
    field: 'label',
    width: 50,
    comparator: OptionComparator,
    ...default_options,
  },
  in_option: {
    headerName: 'Tipo',
    headerTooltip: 'Tipo',
    field: 'in_option',
    width: 40,
    valueGetter: (params: { data: TOptions }) => {
      return OPTIONS_TYPES[params.data.in_option];
    },
    cellClass: (params: any) =>
      params.value == 'Call' ? 'text-multibroker-buy' : 'text-multibroker-sell',
    ...default_options,
  },
  strike_state: {
    headerName: 'Status',
    headerTooltip: 'Status',
    field: 'strike_state',
    width: 52,
    ...default_options,
  },
  kind_option: {
    headerName: 'Estilo',
    field: 'kind_option',
    width: 50,
    headerTooltip: 'Estilo',
    tooltipValueGetter: (params) => `Estilo: ${params.value}`,
    cellRenderer: StyleCellRendererComponent,
    ...default_options,
  },
  uncovered_qty: {
    headerName: 'Desc',
    headerTooltip: 'Descoberto',
    field: 'uncovered_qty',
    width: 50,
    valueGetter: (params: { data: TOptions }) => {
      return params.data.uncovered_qty ? params.data.uncovered_qty : 0;
    },
    valueFormatter: (params: any) =>
      formatterNumber(+params.value, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
    comparator: NumberComparator,
    ...default_options,
  },
  covered_qty: {
    headerName: 'Cob',
    headerTooltip: 'Coberto',
    field: 'covered_qty',
    width: 50,
    valueGetter: (params: { data: TOptions }) => {
      return params.data.covered_qty ? params.data.covered_qty : 0;
    },
    valueFormatter: (params: any) =>
      formatterNumber(+params.value, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
    comparator: NumberComparator,
    ...default_options,
  },
  strike_price: {
    headerName: 'Strike',
    headerTooltip: 'Strike',
    field: 'strike_price',
    width: 50,
    valueFormatter: (params: any) => formatterNumber(+params.value),
    comparator: NumberComparator,
    ...default_options,
  },
  qt_trades: {
    headerName: 'Neg',
    headerTooltip: 'Negócios',
    field: 'qt_trades',
    width: 40,
    valueFormatter: (params: any) => bigValueFormatter(+params.value),
    comparator: NumberComparator,
    ...default_options,
  },
  qt_share_traded: {
    headerName: 'Qtd.Neg',
    headerTooltip: 'Qtd. Negociada',
    field: 'qt_share_traded',
    width: 62,
    valueFormatter: (params: any) => bigValueFormatter(+params.value),
    comparator: NumberComparator,
    ...default_options,
  },
  vl_close: {
    headerName: 'Preço',
    headerTooltip: 'Preço',
    field: 'vl_close',
    width: 50,
    valueFormatter: (params: any) => formatterNumber(+params.value),
    comparator: NumberComparator,
    ...default_options,
  },
  valor_melhor_oferta_compra: {
    headerName: 'Bid',
    headerTooltip: 'Bid',
    headerClass: 'text-multibroker-buy',
    field: 'valor_melhor_oferta_compra',
    width: 45,
    valueFormatter: (params: any) => formatterNumber(+params.value),
    cellStyle: { textAlign: 'right' },
    sortable: true,
    comparator: NumberComparator,
    sortingOrder: ['asc', 'desc'],
  },
  valor_melhor_oferta_venda: {
    headerName: 'Ask',
    headerTooltip: 'Ask',
    headerClass: 'text-multibroker-sell',
    field: 'valor_melhor_oferta_venda',
    width: 45,
    valueFormatter: (params: any) => formatterNumber(+params.value),
    cellStyle: { textAlign: 'right' },
    sortable: true,
    comparator: NumberComparator,
    sortingOrder: ['asc', 'desc'],
  },
  day_variation: {
    headerName: 'Var',
    headerTooltip: 'Variação',
    field: 'day_variation',
    width: 50,
    headerComponentParams: ['Preços', 'day_variation'],
    valueFormatter: formatterPercentageFields,
    cellClass: (params: any) =>
      +params.value >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
    comparator: NumberComparator,
    ...default_options,
  },
  vl_vol_implicitly: {
    headerName: 'V.I.',
    headerTooltip: 'Vol. Implicita',
    field: 'vl_vol_implicitly',
    width: 40,
    headerComponentParams: ['Variações', 'vl_vol_implicitly'],
    valueFormatter: formatterPercentageFields,
    comparator: NumberComparator,
    ...default_options,
  },
  vl_delta: {
    headerName: 'Delta',
    headerTooltip: 'Delta',
    field: 'vl_delta',
    width: 48,
    headerComponentParams: ['Variações', 'vl_delta'],
    valueFormatter: (params: any) =>
      formatterNumber(+params.value, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    cellClass: (params: any) =>
      +params.value >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
    comparator: NumberComparator,
    ...default_options,
  },
  vl_gamma: {
    headerName: 'Gamma',
    headerTooltip: 'Gamma',
    field: 'vl_gamma',
    width: 56,
    headerComponentParams: ['Variações', 'vl_gamma'],
    valueFormatter: (params: any) =>
      formatterNumber(+params.value, {
        maximumFractionDigits: 4,
        minimumFractionDigits: 4,
      }),
    cellClass: (params: any) =>
      +params.value >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
    comparator: NumberComparator,
    ...default_options,
    hide: true,
  },
  vl_theta: {
    headerName: 'Theta',
    headerTooltip: 'Theta',
    field: 'vl_theta',
    width: 50,
    headerComponentParams: ['Variações', 'vl_theta'],
    valueFormatter: (params: any) =>
      formatterNumber(+params.value, {
        maximumFractionDigits: 4,
        minimumFractionDigits: 4,
      }),
    cellClass: (params: any) =>
      +params.value >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
    comparator: NumberComparator,
    ...default_options,
    hide: true,
  },
  vl_rho: {
    headerName: 'Rho',
    headerTooltip: 'Rho',
    field: 'vl_rho',
    width: 46,
    headerComponentParams: ['Variações', 'vl_var_um_mes'],
    valueFormatter: (params: any) =>
      formatterNumber(+params.value, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    cellClass: (params: any) =>
      +params.value >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
    comparator: NumberComparator,
    ...default_options,
    hide: true,
  },
  vl_vega: {
    headerName: 'Vega',
    headerTooltip: 'Vega',
    field: 'vl_vega',
    width: 46,
    headerComponentParams: ['Variações', 'vl_vega'],
    valueFormatter: (params: any) =>
      formatterNumber(+params.value, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    cellClass: (params: any) =>
      +params.value >= 0 ? 'text-feedback-success' : 'text-feedback-negative',
    comparator: NumberComparator,
    ...default_options,
    hide: true,
  },
  volume: {
    headerName: 'Vol',
    headerTooltip: 'Volume',
    field: 'volume',
    width: 50,
    cellStyle: { textAlign: 'right' },
    sortable: true,
    resizable: false,
    valueFormatter: (params: any) =>
      bigValueFormatter(+params.value, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
    comparator: NumberComparator,
    sortingOrder: ['asc', 'desc'],
  },
};

export const OPTIONS_TABLE_CONFIG_HEADERS_FIXED_COLUNS: (
  width: number
) => Array<ColDef> = (width: number) => [
  {
    resizable: false,
    headerName: '',
    flex: 1,
    headerClass: '',
    minWidth: 0,
  },
  {
    headerName: 'Action Buttons',
    field: 'id_stock',
    minWidth: 160,
    width,
    pinned: 'right',
    cellRenderer: ActionButtonsCellRendererComponent,
  },
];
