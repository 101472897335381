import { Component, Input } from '@angular/core';
import { RocketTButton } from '@shared/rocket-components/components/button/types/button-type.types';
import { ExportDataConverterService } from '@shared/services/export-data-converter.service';
import { ExportDataIdEnum } from './export-data.enum';
@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.scss']
})
export class ExportDataComponent {
  @Input() id!: ExportDataIdEnum;
  @Input() cdStock: string | undefined;
  @Input() label: string = 'Exportar dados'
  @Input() type: RocketTButton = 'default'
  @Input() data!: any;

  constructor(
    private exporter: ExportDataConverterService
  ) { }

  exportData = () => {
    if(this.id){
      const data = this.exporter.exporterFunctions[this.id](this.data)
      this.exporter.exportData(this.id, data)
    }
  }

}
