import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { Point } from 'scichart/Core/Point';
import {
  ITextAnnotationOptions,
  TextAnnotation,
} from 'scichart/Charting/Visuals/Annotations/TextAnnotation';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import {
  DrawToolConfiguration,
  TIGER_CHART_TOOL,
  TIGER_CHART_TOOL_CHILD,
} from '../tiger-chart-tools/tiger-chart-tools.interface';
import { TMouseEventData } from '../types/tiger-chart.types';
import {
  Axis,
  DrawTools,
  DrawToolsReposition,
  EventSubject,
  LineToolsOptions,
  StyleTool,
} from './draw-tools.interface';
import {
  TOOLS_ENUM,
  TOOLS_METHOD,
} from '../tiger-chart-tools/tiger-chart-tools.enum';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { CoordinateCalculatorBase } from 'scichart/Charting/Numerics/CoordinateCalculators/CoordinateCalculatorBase';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { IconTools } from './icon-tools';

export class TextTools extends TextAnnotation implements DrawTools {
  tool!: TIGER_CHART_TOOL;
  children = [];
  viewBoxWidth!: number;
  viewBoxHeight!: number;
  isDraw = true;
  startEdit = false;
  baseChart!: TWebAssemblyChart;
  isChildren = false;
  lastPointValues!: Point;
  dictIdChildren!: string;
  isAux: boolean = false;
  endDraw: boolean = false;
  lastEventData!: TMouseEventData;
  style: StyleTool = {};
  isDrawing!: boolean;
  updatedFontSize: boolean = false;
  updatedText: boolean = false;
  configuration!: DrawToolConfiguration | undefined;
  hasElements: boolean = false;
  axisSub!: Subscription;
  isWithinDataBounds: boolean | undefined = true;
  private notifyPropertyChanged$!: Subscription;
  private _notifyPropertyChanged$ = new Subject<EventSubject>();
  private updateNote$!: Subscription;
  private _updateNote$ = new Subject<EventSubject>();
  private updateStyleTool$!: Subscription;
  private _updateStyleTool$ = new Subject<EventSubject>();

  constructor(
    options: ITextAnnotationOptions,
    private drawToolsService: DrawToolsService,
    tool: LineToolsOptions
  ) {
    super(options);
    this.initSubscriptions();
    this.isChildren = tool.isChildren;
    this.baseChart = tool.baseChart;
    this.tool = tool.tool;
    this.viewBoxWidth = tool.viewBoxWidth;
    this.viewBoxHeight = tool.viewBoxHeight;
    this.dictIdChildren = tool.dictIdChildren;
    this.isAux = tool.isAux;
    this.lastEventData = tool.eventData;
    this.configuration = tool.configuration;
    this.isWithinDataBounds = tool.eventData.isWithinDataBounds;
  }

  protected override setAnnotationBorders(
    x1: number,
    x2: number,
    y1: number,
    y2: number
  ): void {
    super.setAnnotationBorders(x1, x2, y1, y2);
    this.setStyleConfiguration();
  }

  protected override notifyPropertyChanged(propertyName: string): void {
    super.notifyPropertyChanged(propertyName);
    if (propertyName === 'TEXT') {
      this._notifyPropertyChanged$.next({
        function: () => {
          this.updateStyleTool({ ...this.style, text: undefined });
        },
      });
      return;
    }
    if (propertyName === 'FONT_SIZE') {
      this._notifyPropertyChanged$.next({
        function: () => {
          this.updateStyleTool({ ...this.style, textSize: undefined });
        },
      });
      return;
    }
    if (!this.isAux && this.startEdit) {
      return;
    }
    if (this.isDrawing) {
      return;
    }
  }

  override update(
    xCalc: CoordinateCalculatorBase,
    yCalc: CoordinateCalculatorBase,
    xCoordSvgTrans: number,
    yCoordSvgTrans: number
  ): void {
    super.update(xCalc, yCalc, xCoordSvgTrans, yCoordSvgTrans);
    const text = this.svg.querySelector('text');
    if (!text) {
      return;
    }
    this.updateNote(text.getBoundingClientRect().width);
    if (this.isDrawing) {
      return;
    }
    if (this.style && Object.keys(this.style).length) {
      this.updateStyleTool(this.style);
    }
  }

  unsubscriber(): void {
    this.destroySubscriptions();
  }

  initSubscriptions() {
    this.notifyPropertyChanged$ = this._notifyPropertyChanged$
      .pipe(debounceTime(5))
      .subscribe((data) => {
        data.function();
      });
    this.updateNote$ = this._updateNote$
      .pipe(debounceTime(100))
      .subscribe((data) => {
        data.function();
      });
    this.updateStyleTool$ = this._updateStyleTool$
      .pipe(debounceTime(100))
      .subscribe((data) => {
        data.function();
      });
  }

  private destroySubscriptions() {
    this.notifyPropertyChanged$ && this.notifyPropertyChanged$.unsubscribe();
    this.updateNote$ && this.updateNote$.unsubscribe();
    this.updateStyleTool$ && this.updateStyleTool$.unsubscribe();
  }

  override onDetach(): void {
    super.onDetach();
    this.destroySubscriptions();
  }

  override delete(): void {
    super.delete();
    this.destroySubscriptions();
  }

  getName() {
    return this.constructor.name;
  }

  private getDataValues() {
    const { xAxis, yAxis } = this.getAxis();
    if (!xAxis || !yAxis) {
      return;
    }
    const borders = this.getAnnotationBorders();
    const y1 = yAxis.getCurrentCoordinateCalculator().getDataValue(borders.y1),
      y2 = yAxis.getCurrentCoordinateCalculator().getDataValue(borders.y2);
    const x1 = xAxis.getCurrentCoordinateCalculator().getDataValue(borders.x1),
      x2 = xAxis.getCurrentCoordinateCalculator().getDataValue(borders.x2);
    return { x1, x2, y1, y2 };
  }

  updateDrawTool(
    eventData: TMouseEventData | undefined,
    countStepClickTool: number,
    reposition?: DrawToolsReposition
  ) {
    if (eventData) {
      this.lastEventData = eventData;
      this.lastPointValues = eventData.pointValues!!;
    }
    if (
      this.tool.codTool === `${TOOLS_ENUM.GANN_FIBONACCI}_RETRACAO_DE_FIBONACCI`
    ) {
      const annotation = this.baseChart.sciChartSurface.annotations.getById(
        reposition!!.id || this.tool.dictId!!
      ) as TextAnnotation;
      const x1 = reposition?.x1;
      const y1 = reposition?.y1;
      if (annotation) {
        annotation.text = reposition && reposition.text ? reposition.text : '';
        annotation.x1 = x1 ? x1 : annotation.x1;
        annotation.y1 = y1 ? y1 : annotation.y1;
      }
    }
  }

  private updateNote(newWidth: number) {
    if (this.tool.codTool === `${TOOLS_ENUM.ANNOTATION_TOOLS}_NOTE`) {
      this._updateNote$.next({
        function: () => {
          const dataValues = this.getDataValues();
          if (!dataValues) {
            return;
          }
          this.tool.children?.forEach((child) => {
            if (child.method === TOOLS_METHOD.CUSTOM_BOX_ANNOTATION) {
              const draw = this.baseChart.sciChartSurface.annotations.getById(
                child.dictIdChildren!!
              ) as DrawTools;
              if (!draw) {
                return;
              }
              draw.isDrawing = true;
              if (dataValues.y1 != dataValues.y2) {
                draw.y1 = dataValues.y1;
                draw.y2 = dataValues.y2;
              }
              if (dataValues.x1 != dataValues.x2) {
                draw.x1 = dataValues.x1;
                draw.x2 = dataValues.x2;
              }
              const pathElement = document.getElementById(
                `PATH_${child.dictIdChildren!!}`
              );
              if (pathElement) {
                const borders = this.getAnnotationBorders();
                const width = newWidth <= 30 ? 30 : newWidth + 5;
                const height =
                  borders.y2 - borders.y1 === 0
                    ? this.fontSize
                    : borders.y2 - borders.y1;
                const arrowPosition = Math.abs(width / 2 - 10);
                pathElement.setAttribute(
                  'd',
                  `M -2 -1 h ${width} v ${
                    height + 8
                  } h -${arrowPosition} l -10 10 l -10 -10 H -2 z`
                );
              }
            }
          });
        },
      });
    }
  }

  customClick = () => {
    //do nothing.
  };

  repositionZoomDraw(): void {
    //do nothing.
  }

  customHover = () => {
    //do nothing.
  };

  updateStyleTool(style: StyleTool) {
    let childrenDraw!: TIGER_CHART_TOOL_CHILD;
    const text = this.svg.querySelector('text');
    if (this.isChildren && this.tool.children) {
      childrenDraw = this.tool.children.find(
        (child) => child.dictIdChildren === this.dictIdChildren
      )!!;
    }
    if (style.color) {
      this.style.color = style.color;
      if (text) {
        text.setAttribute('fill', style.color.rgbaHexColor);
      }
      if (childrenDraw) {
        childrenDraw.color = style.color.rgbaHexColor;
      } else {
        this.tool.color = style.color.rgbaHexColor;
      }
    }
    if (style.textColor) {
      this.style.textColor = style.textColor;
      if (text) {
        text.setAttribute('fill', style.textColor.rgbaHexColor);
      }
      if (childrenDraw) {
        childrenDraw.textColor = style.textColor.rgbaHexColor;
      } else {
        this.tool.textColor = style.textColor.rgbaHexColor;
      }
    }
    if (!isNullOrUndefined(style.text)) {
      this.style.text = style.text;
      this.text = style.text!!;
      if (childrenDraw) {
        childrenDraw.text = style.text;
      } else {
        this.tool.text = style.text;
      }
    }
    if (!isNullOrUndefined(style.textBold)) {
      this.style.textBold = style.textBold;
      if (text) {
        if (style.textBold) {
          text.setAttribute('font-weight', 'Bold');
        } else {
          text.setAttribute('font-weight', 'Normal');
        }
      }
      if (childrenDraw) {
        childrenDraw.textBold = style.textBold;
      } else {
        this.tool.textBold = style.textBold;
      }
    }
    if (!isNullOrUndefined(style.textItalic)) {
      this.style.textItalic = style.textItalic;
      if (text) {
        if (style.textItalic) {
          text.setAttribute('font-style', 'italic');
        } else {
          text.setAttribute('font-style', 'normal');
        }
      }
      if (childrenDraw) {
        childrenDraw.textItalic = style.textItalic;
      } else {
        this.tool.textItalic = style.textItalic;
      }
    }
    if (style.textSize) {
      this.style.textSize = style.textSize;
      this.fontSize = style.textSize;
      this._updateStyleTool$.next({
        function: () => {
          if (this.tool.codTool === `${TOOLS_ENUM.ANNOTATION_TOOLS}_NOTE`) {
            const father = this.baseChart.sciChartSurface.annotations.getById(
              this.tool.dictId!!
            ) as DrawTools;
            const svg = this.svg;
            const bound = svg.getBoundingClientRect();
            const { yAxis } = this.getAxis();
            if (!yAxis) {
              return;
            }
            const borders = father.getAnnotationBorders();
            const y1 = yAxis
              .getCurrentCoordinateCalculator()
              .getDataValue(borders.y1 - (bound.height!! + 18) * 2);
            this.y1 = y1;
          }
        },
      });
      if (childrenDraw) {
        childrenDraw.textSize = style.textSize;
      } else {
        this.tool.textSize = style.textSize;
      }
    }
  }

  updateTool(tool: TIGER_CHART_TOOL) {
    this.tool = tool;
  }

  private setStyleConfiguration() {
    if (!this.configuration || this.hasElements) {
      return;
    }
    const text = this.svg.querySelector('text');
    if (!text) {
      return;
    } else {
      this.hasElements = true;
    }
    if (text) {
      if (!isNullOrUndefined(this.configuration.textBold)) {
        this.style.textBold = this.configuration.textBold;
        if (this.configuration.textBold) {
          text.setAttribute('font-weight', 'Bold');
        } else {
          text.setAttribute('font-weight', 'Normal');
        }
      }
      if (!isNullOrUndefined(this.configuration.textItalic)) {
        this.style.textItalic = this.configuration.textItalic;
        if (this.configuration.textItalic) {
          text.setAttribute('font-style', 'italic');
        } else {
          text.setAttribute('font-weight', 'Normal');
        }
      }
    }
  }

  private getAxis(): Axis {
    const yAxis = this.baseChart.sciChartSurface.yAxes.getById(this.yAxisId);
    const xAxis = this.baseChart.sciChartSurface.xAxes.getById(this.xAxisId);
    if (!yAxis || !xAxis) {
      return { xAxis: undefined, yAxis: undefined };
    }
    try {
      yAxis.getCurrentCoordinateCalculator();
      xAxis.getCurrentCoordinateCalculator();
    } catch (e: any) {
      return { xAxis: undefined, yAxis: undefined };
    }
    return { yAxis, xAxis };
  }

  updateBoxNote() {
    const father = this.baseChart.sciChartSurface.annotations.getById(
      this.tool.dictId!!
    ) as IconTools;
    father.updateBoxNoteWidth();
  }
}
