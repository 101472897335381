<rocket-modal>
  <rocket-modal-header>
    <section>
      <h4 class="modal-title">
        Selecione elementos para iniciar a criação do seu novo Workspace
      </h4>
    </section>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <div class="row">
      <fla-tab css="mb-1">
        <ng-container *ngFor="let tab of tabs">
          <fla-tab-header
            css="px-2"
            [ref]="tab.ref"
            [title]="tab.title"
            [active]="tab.active"
            (flaChange)="changeTab(tab.ref)"
          ></fla-tab-header>
          <fla-tab-body ref="tab.ref" [active]="tab.active">
            <div class="gridline-components">
              <div *ngFor="let item of tabsComponents[tab.ref]">
                <fla-card (click)="selectComponentToAdd(item)">
                  <fla-card-custom
                    *ngIf="!item.noPublished"
                    #card
                    css="px-3 col cursor-pointer"
                  >
                    <div
                      [class]="{
                        'text-brand-primary': selecteds.get(item.cod)
                      }"
                      class="card-body vstack d-flex justify-content-center align-items-center"
                    >
                      <ng-container *ngIf="item.icon; else customIcon">
                        <fla-icon
                          size="icon-size-lg"
                          [name]="item.icon"
                          [class]="item.iconColor"
                          [css]="
                            item.iconColor
                              ? item.iconColor
                              : selecteds.has(item.cod)
                              ? 'text-body-secondary'
                              : 'text-white'
                          "
                        >
                        </fla-icon>
                      </ng-container>
                      <ng-template #customIcon>
                        <app-rocket-icons
                          [name]="item.iconSvg"
                          [height]="40"
                          [width]="40"
                          [color]="
                            selecteds.get(item.cod)
                              ? 'var(--vm-brand-primary)'
                              : 'var(--vm-neutral-smoothest)'
                          "
                        ></app-rocket-icons>
                      </ng-template>
                      <h6 class="fw-normal text-center">
                        {{ item.name }}
                      </h6>
                    </div>
                  </fla-card-custom>
                </fla-card>
              </div>
            </div>
          </fla-tab-body>
        </ng-container>
      </fla-tab>
    </div>
  </app-rocket-modal-body>
  <rocket-modal-footer padding="pt-1">
    <rocket-button
      [label]="'Cancelar'"
      type="fill"
      (rocketClick)="onClose()"
    ></rocket-button>
    <rocket-button
      [label]="'Concluir'"
      css="ml-2"
      (rocketClick)="onSave()"
    ></rocket-button>
  </rocket-modal-footer>
</rocket-modal>
