import { Injectable } from '@angular/core';
import { ListOrdersPreferences } from '../custom-preferences/list-orders/list-stocks-preferences.services';
import { Dictionary, OrderModel } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class ListOrdersService {
  public static _instance: ListOrdersService;
  private _allOrders!: any;
  private _allHashOrders!: any;

  private _ordersDict = new Dictionary<OrderModel>();
  get ordersDict(): Dictionary<OrderModel> {
    return this._ordersDict;
  }

  set ordersDict(data: any) {
    this._ordersDict.bulkData('id_order', data);
  }

  get allOrders() {
    return this._allOrders || this.preferenceAllOrders();
  }

  get allHashOrders() {
    return this._allHashOrders || this.preferenceAllHashOrders();
  }

  get checkReturnCallApiOrders(): boolean {
    return !!this._allOrders;
  }

  constructor(private listOrdersPreferences: ListOrdersPreferences) {
    if (ListOrdersService._instance == null) {
      ListOrdersService._instance = this;
    } else {
      return ListOrdersService._instance;
    }
  }

  sortOrders = (first: any, second: any) => {
    if (+first.dh_last_update > +second.dh_last_update) {
      return -1;
    }
    if (+first.dh_last_update < +second.dh_last_update) {
      return 1;
    }
    return 0;
  };

  getOrdersByKey = (key: string) => {
    const hashs = this.allHashOrders;
    return hashs[key] || null;
  };

  preferenceAllOrders = () => {
    const list = this.listOrdersPreferences.allOrders;
    if (list) {
      this._allOrders = list;
      this.ordersDict = list;
    }
    return list;
  };

  preferenceAllHashOrders = () => {
    const list = this.listOrdersPreferences.allHashOrders;
    this._allHashOrders = list;
    return list;
  };

  saveOrders = (orders: any[], hashOrders: any) => {
    this._allOrders = orders || [];
    this.listOrdersPreferences.allOrders = orders || [];
    this.listOrdersPreferences.allHashOrders = hashOrders || {};
  };
}
