import { TigerChartComponent } from './tiger-chart.component';
import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GraphContainer } from './directives/graph-container.directive';
import { TooltipMenu } from './tooltip/tooltip-menu.component';
import { RTWaitForElementService } from './services/wait-for-element.service';
import { SettingsModalComponent } from './indicators/settings-modal/settings-modal.component';
import { StylesInputComponent } from './indicators/settings-modal/styles-input/styles-input.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColorPickerModule } from 'ngx-color-picker';
import { TigerChartContextMenuComponent } from './context-menu/tiger-chart-context-menu.component';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { RTColorPickerModule } from '@shared/components/color-picker/color-picker.module';
import { TalibService } from './services/talib.service';
import { TigerChartTooltipComponent } from './tooltip/tiger-chart-tooltip.component';
import { TooltipIndicatorComponent } from './tooltip/tooltip-indicator/tooltip-indicator.component';
import { SubTigerChartComponent } from './sub-tiger-chart/sub-tiger-chart.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SettingsModalService } from './indicators/settings-modal/settings-modal.service';
import { TigerChartAnnotationTooltipComponent } from './annotations/annotation-tooltip/annotation-tooltip.component';
import { TigerChartToolsComponent } from './tiger-chart-tools/tiger-chart-tools.component';
import { ToolbarDrawModule } from './tiger-chart-tools/toolbar-draw/toolbar-draw.module';
import { CustodyStopOrdersAlertComponent } from './custody-stop-orders/custody-stop-orders-alert/custody-stop-orders-alert.component';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  FlaCheckModule,
  FlaIconModule,
  FlaInputModule,
  FlaSelectModule,
  FlaTabModule,
  RocketButtonModule,
  RocketModalModule,
} from '@shared/rocket-components/components/index';
import { RocketModelSaveModule } from '@shared/rocket-components/model-save/model-save.module';
import { TigerChartMessageComponent } from './tiger-chart-message/tiger-chart-message.component';
import { TigerChartControlsComponent } from './tiger-chart-controls/tiger-chart-controls.component';
import { HotkeysService } from '@shared/rocket-components/services/hotkeys/hotkeys.service';
import { TigerChartOrdersComponent } from './tiger-chart-orders/tiger-chart-orders.component';
import { DynamicFormModule } from '@shared/rocket-components/dynamic-form/dynamic-form.module';
import { DaytradeSelectorModule } from '@shared/components/daytrade-selector/daytrade-selector.module';
import { StrategySelectorModule } from '@shared/components/strategy-selector/strategy-selector.module';
import { StockTradeService } from '@shared/components/stock-trade/service/stock-trade.service';
import { ColumnBarService } from './services/column-bar.service';
import { EventsContextMenuComponent } from './tiger-chart-events-modal/events-context-menu/events-context-menu.component';
import { EventsContextMenuService } from './tiger-chart-events-modal/events-context-menu/events-context-menu.service';
import { AuctionTemplateComponent } from './tiger-chart-message/auction-template/auction-template.component';
import { MaturityTemplateComponent } from './tiger-chart-message/maturity-template/maturity-template.component';
import { DocumentsModalModule } from '@shared/documents-modal/documents-modal.module';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';
import { RTInputCountModule } from '@shared/rocket-components/input-count/input-count.module';
import { TableTradeIdeaItemsModule } from '@shared/components/trade-idea/table-trade-idea-items/table-trade-idea-items.module';
import { HelpDrawsComponent } from './tiger-chart-tools/help-draws/help-draws.component';
import { RocketIconsModule } from '@shared/rocket-icons/rocket-icons.module';
import { OpacitySliderComponent } from './tiger-chart-tools/toolbar-draw/config-colorpicker/opacity-slider/opacity-slider.component';
import { StaticColorPickerComponent } from './indicators/settings-modal/styles-input/static-color-picker/static-color-picker.component';
registerLocaleData(ptBr);

@NgModule({
  declarations: [
    TigerChartComponent,
    GraphContainer,
    TigerChartTooltipComponent,
    TooltipIndicatorComponent,
    TooltipMenu,
    TigerChartContextMenuComponent,
    SettingsModalComponent,
    StylesInputComponent,
    SubTigerChartComponent,
    TigerChartAnnotationTooltipComponent,
    TigerChartToolsComponent,
    CustodyStopOrdersAlertComponent,
    TigerChartMessageComponent,
    AuctionTemplateComponent,
    MaturityTemplateComponent,
    TigerChartControlsComponent,
    TigerChartOrdersComponent,
    EventsContextMenuComponent,
    HelpDrawsComponent,
  ],
  imports: [
    FlaIconModule,
    FlaTabModule,
    FlaCheckModule,
    FlaSelectModule,
    FlaWorkSpaceModule,
    FlaInputModule,
    ColorPickerModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    RTColorPickerModule,
    DragDropModule,
    RTDropdownModule,
    ToolbarDrawModule,
    TooltipsModule,
    ScrollingModule,
    RocketButtonModule,
    RocketModelSaveModule,
    RocketModalModule,
    DynamicFormModule,
    DaytradeSelectorModule,
    StrategySelectorModule,
    DocumentsModalModule,
    RTInputCountModule,
    TableTradeIdeaItemsModule,
    RocketIconsModule,
    OpacitySliderComponent,
    StaticColorPickerComponent,
  ],
  exports: [TigerChartComponent],
  providers: [
    RTWaitForElementService,
    TalibService,
    SettingsModalService,
    HotkeysService,
    StockTradeService,
    ColumnBarService,
    EventsContextMenuService,
  ],
})
export class TigerChartModule {
  static forRoot(): ModuleWithProviders<TigerChartModule> {
    return {
      ngModule: TigerChartModule,
      providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }],
    };
  }
}
