import { Directive, HostListener, ElementRef } from '@angular/core';
import {
  KEYBOARD_FOCUS_EVENT,
  KeyboardControlsService,
} from '../service/keyboard-controls.service';

@Directive({
  selector: '[appKeyboardControls]',
})
export class keyboardControlsDirective {
  constructor(
    public element: ElementRef,
    private _keyboardService: KeyboardControlsService
  ) {}

  private _eventByKey: any = {
    ArrowRight: KEYBOARD_FOCUS_EVENT.RIGTH,
    ArrowDown: KEYBOARD_FOCUS_EVENT.RIGTH,
    ArrowUp: KEYBOARD_FOCUS_EVENT.LEFT,
    ArrowLeft: KEYBOARD_FOCUS_EVENT.LEFT,
    Tab: KEYBOARD_FOCUS_EVENT.INITIAL,
    Enter: KEYBOARD_FOCUS_EVENT.SELECT,
  };

  private _acceptedEvents = [
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
    'Tab',
    'Enter',
  ];

  @HostListener('keydown', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (!this._acceptedEvents.includes(event.code)) return true;
    this._sendEvent(this._eventByKey[event.code]);
    return false
  }

  private _sendEvent(action: KEYBOARD_FOCUS_EVENT): void {
    const infos = this.element.nativeElement.id.split('::');
    this._keyboardService.sendMessage({
      elementID: this.element.nativeElement.id,
      stocksID: infos[0],
      componentID: infos[1],
      action,
    });
  }
}
