import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IScreeningFilter } from '../interfaces/indicator.interface';
import { FormControl } from '@angular/forms';
import { deepClone, randomId } from '@shared/rocket-components/utils';
import {
  DATA_POINT,
  FILTERS_INDICATORS_GROUP,
  SCREENING_BASE,
  SELECT_CONDITIONS,
} from '../constants/constants';
import { removeAccents } from 'src/app/utils/utils.functions';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ToastService } from '@shared/services/toast.service';

@Component({
  selector: 'app-screening-filters',
  templateUrl: './screening-filters.component.html',
  styleUrls: ['./screening-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreeningFiltersComponent implements OnChanges {
  @Input() filtersString!: string;
  @Output() sendAndSave: EventEmitter<string> = new EventEmitter<string>();
  public filters: IScreeningFilter[] = [];
  public filterSelected: IScreeningFilter = deepClone(SCREENING_BASE);

  public quotes: any = [];
  public filteredIndicators: any = [];
  public custom: any = [];
  public searchControl!: FormControl;
  public isSelectIndicators: boolean = false;

  public selectionConditions!: any;

  constructor(
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {
    this.setDefaultInfos();
    this.searchControl = new FormControl(undefined);
    this.quotes = deepClone(DATA_POINT);
    this.filteredIndicators = deepClone(FILTERS_INDICATORS_GROUP);
    this.custom = [{ name: 'Valor personalizado', value: 1 }];
    this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value: string) => {
        this.searchIndicators(value);
        this.cdr.detectChanges();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { filtersString } = changes;
    if (filtersString?.currentValue) {
      this.filters = JSON.parse(this.filtersString);
      this.cdr.detectChanges();
    }
  }

  private setDefaultInfos(): void {
    this.filterSelected = deepClone(SCREENING_BASE);
    this.filterSelected.id = randomId('id');
  }

  public saveEdition(event: IScreeningFilter): void {
    this.setDefaultInfos();
    const index = this.filters.findIndex(
      (filter: IScreeningFilter) => filter.id === event.id
    );
    if (index > -1) {
      this.filters[index] = event;
    } else {
      this.filters.push(event);
    }
    this.sendAndSave.emit(JSON.stringify(this.filters));
    this.toastService.showToast('success', 'Seu filtro foi salvo com sucesso.');
    this.cdr.detectChanges();
  }

  public selectCondition(condition: any): void {
    if (!this.isSelectIndicators) return;
    const params = {
      type: SELECT_CONDITIONS[0],
      value: condition.id,
      description: condition.name,
      filter: null,
    };
    this.selectionConditions = params;
    this.cdr.detectChanges();
  }

  public selectIndicator(indicator: any): void {
    if (!this.isSelectIndicators) return;
    const params = {
      type: SELECT_CONDITIONS[1],
      value: indicator.type,
      description: indicator.name,
      filter: indicator.values,
    };
    this.selectionConditions = params;
    this.cdr.detectChanges();
  }

  public selectCustom(): void {
    if (!this.isSelectIndicators) return;
    const params = {
      type: SELECT_CONDITIONS[2],
      value: 1,
      description: '1',
      filter: null,
    };
    this.selectionConditions = params;
    this.cdr.detectChanges();
  }

  private searchIndicators(value: string): void {
    //Verifica se o campo de busca é vazio
    if (!value || value == '') {
      this.quotes = deepClone(DATA_POINT);
      this.filteredIndicators = deepClone(FILTERS_INDICATORS_GROUP);
      this.custom = [{ name: 'Valor personalizado', value: 1 }];
      this.cdr.detectChanges();
      return;
    }

    this.quotes = [];
    this.filteredIndicators = [];
    this.custom = [];
    const customGroups: any[] = []; // Objeto auxiliar para armazenar grupos já adicionados
    const quoteGroups: any[] = []; // Objeto auxiliar para armazenar grupos já adicionados
    const filteredGroups: { [key: string]: any } = {}; // Objeto auxiliar para armazenar grupos já adicionados
    const queryWithoutAccents = removeAccents(value.toLowerCase());

    DATA_POINT.forEach((quote: any) => {
      const quoteName = removeAccents(quote.name.toLowerCase());

      if (quoteName.includes(queryWithoutAccents)) {
        quoteGroups.push(quote);
      }
    });
    if (quoteGroups.length) {
      this.quotes = quoteGroups;
    }
    [{ name: 'Valor personalizado', value: 1 }].forEach((custom: any) => {
      const customName = removeAccents(custom.name.toLowerCase());

      if (customName.includes(queryWithoutAccents)) {
        customGroups.push(custom);
      }
    });
    if (customGroups.length) {
      this.custom = customGroups;
    }

    FILTERS_INDICATORS_GROUP.forEach((group: any) => {
      const groupName = removeAccents(group.label.toLowerCase());

      if (groupName.includes(queryWithoutAccents)) {
        // Adiciona o grupo inteiro se o nome der match com o grupo
        if (!filteredGroups[groupName]) {
          filteredGroups[groupName] = { ...group };
          this.filteredIndicators.push(filteredGroups[groupName]);
        }
      } else {
        const filteredIndicators = [];

        for (const indicator of group.indicators) {
          const indicatorName = removeAccents(indicator.name.toLowerCase());
          const indicatorId = removeAccents(indicator.id.toLowerCase());

          if (
            indicatorName.includes(queryWithoutAccents) ||
            indicatorId.includes(queryWithoutAccents)
          ) {
            filteredIndicators.push(indicator);
          }
        }

        if (filteredIndicators.length > 0) {
          // Adiciona o grupo filtrado
          const filteredGroup = {
            ...group,
            label: group.label,
            indicators: filteredIndicators,
          };

          // Verifica se o grupo já foi adicionado e se não foi, adiciona ao objeto de apoio e ao array de resultados
          const filteredGroupName = removeAccents(
            filteredGroup.label.toLowerCase()
          );
          if (!filteredGroups[filteredGroupName]) {
            filteredGroups[filteredGroupName] = { ...filteredGroup };
            this.filteredIndicators.push(filteredGroups[filteredGroupName]);
          }
        }
      }
    });
    this.cdr.detectChanges();
  }

  public changeFilter(filter: IScreeningFilter): void {
    this.filterSelected = deepClone(filter);
    this.cdr.detectChanges();
  }

  public deleteFilter(id: string): void {
    const index = this.filters.findIndex(
      (item: IScreeningFilter) => item.id === id
    );
    if (index > -1) {
      this.filters.splice(index, 1);
      this.sendAndSave.emit(JSON.stringify(this.filters));
      index === 0 && (this.filterSelected = this.filters[0]);
      this.toastService.showToast(
        'success',
        'Seu filtro foi removido com sucesso.'
      );
    } else {
      this.toastService.showToast('error', 'Seu filtro não foi removido.');
    }
    this.cdr.detectChanges();
  }

  public createFilter(): void {
    this.setDefaultInfos();
    this.cdr.detectChanges();
  }

  public selectIndicators(event: any): void {
    this.isSelectIndicators = event;
    this.cdr.detectChanges();
  }
}
