import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';

import { CHART_COLORS } from '@shared/tiger-chart/colors';
import {
  TData,
  TTooltipIndicatorValue,
  TUpdateData,
} from '@shared/tiger-chart/types/tiger-chart.types';
import {
  BaseIndicator,
  StochasticIndexData,
  TigerChartIndicatorStochasticIndexOptions,
  TTigerChartIndicatorCreateOptions,
  TTigerChartIndicatorLimitTypes,
  TTigerChartIndicatorParameter,
  TTigerChartIndicatorRenderSeriesConfig,
} from '../indicators.types';
import {
  TIGER_INDICATORS_ENUM,
  TIGER_INDICATOR_PARAMETER_TYPE,
  TIGER_INDICATOR_SOURCES,
} from '@shared/tiger-chart/enum/tiger-chart.enum';
import { Subject } from 'rxjs';
import { TalibService } from '@shared/tiger-chart/services/talib.service';
import { XyyScaleOffsetFilter } from 'scichart/Charting/Model/Filters/XyyScaleOffsetFilter';
import { XyDataSeries } from 'scichart/Charting/Model/XyDataSeries';
import { FastLineRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastLineRenderableSeries';
import { TIGER_INDICATOR_PRECISIONS } from '../indicators.constants';
import { HorizontalLineAnnotation } from 'scichart/Charting/Visuals/Annotations/HorizontalLineAnnotation';
import { ECoordinateMode } from 'scichart/Charting/Visuals/Annotations/AnnotationBase';
import { BoxAnnotation } from 'scichart/Charting/Visuals/Annotations/BoxAnnotation';
import { LINE_CONFIG_DICTIONARY } from '../settings-modal/styles-input/styles-input.dictionary';
import { setDefaultConfiguration } from '../indicators.functions';

export class StochasticIndexBase implements BaseIndicator {
  protected lineDataSeriesKPeriod!: XyDataSeries;
  protected lineDataSeriesDPeriod!: XyDataSeries;
  protected movingAverageDataSeries!: XyyScaleOffsetFilter;
  protected lineRenderableSeriesKPeriod!: FastLineRenderableSeries;
  protected lineRenderableSeriesDPeriod!: FastLineRenderableSeries;
  protected options!: TigerChartIndicatorStochasticIndexOptions;
  protected data: TData;
  protected precision: number = 2;
  protected fastK_Period: number = 14;
  protected slowK_Period: number = 1;
  protected slowD_Period: number = 3;
  protected upperLimit: number = 70;
  protected bottomLimit: number = 30;
  protected source: TIGER_INDICATOR_SOURCES = TIGER_INDICATOR_SOURCES.CLOSE;
  protected points: StochasticIndexData;
  protected lineColorK = CHART_COLORS.BRAND_SUPPORT_PRIMARY;
  protected lineColorD = CHART_COLORS.FEEDBACK_WARNING;
  protected upperLimitColor = CHART_COLORS.NEUTRAL_MEDIUM;
  protected bottomLimitColor = CHART_COLORS.NEUTRAL_MEDIUM;
  protected backgroundColor = CHART_COLORS.FEEDBACK_NEGATIVE;
  protected lineVisibleK = true;
  protected lineVisibleD = true;
  protected upperVisible = true;
  protected bottomVisible = true;
  protected backgroundVisible = true;
  protected lineThickness = 1;
  protected upperLimitLineType = 1;
  protected bottomLimitLineType = 1;

  private baseChart!: TWebAssemblyChart;
  lineNumber!: string;
  type: TIGER_INDICATORS_ENUM = TIGER_INDICATORS_ENUM.STOCHASTIC;
  yAxisId!: string;
  settings: TTigerChartIndicatorParameter[] = [];
  styles: TTigerChartIndicatorParameter[] = [];
  renderSeriesConfig: TTigerChartIndicatorRenderSeriesConfig[] = [];
  onChange = new Subject<null>();
  service: TalibService;
  mainLineId = 'stochastic-line';
  isNewOnChart = false;

  private get totalPeriod(): number {
    return this.fastK_Period + this.slowD_Period - this.slowK_Period - 1;
  }

  get isVisible(): boolean {
    return this.lineRenderableSeriesKPeriod.isVisible;
  }

  set isVisible(visible: boolean) {
    this.lineRenderableSeriesKPeriod.isVisible = visible;
    this.lineRenderableSeriesDPeriod.isVisible = visible;
  }

  get propertiesText(): string {
    return `(${this.fastK_Period}, ${this.slowK_Period}, ${this.slowD_Period})`;
  }

  constructor(options: TigerChartIndicatorStochasticIndexOptions) {
    this.data = options.data;
    this.service = options.service;
    this.precision = options.tickSize;
    this.options = options;
    if (options.fastK_Period) this.fastK_Period = options.fastK_Period;
    if (options.slowK_Period) this.slowK_Period = options.slowK_Period;
    if (options.slowD_Period) this.slowD_Period = options.slowD_Period;
    if (options.upperLimit) this.upperLimit = options.upperLimit;
    if (options.bottomLimit) this.bottomLimit = options.bottomLimit;
    if (options.lineColorK) this.lineColorK = options.lineColorK;
    if (options.lineColorD) this.lineColorD = options.lineColorD;
    if (options.upperLimitColor) this.upperLimitColor = options.upperLimitColor;
    if (options.bottomLimitColor)
      this.bottomLimitColor = options.bottomLimitColor;
    if (options.backgroundColor) this.backgroundColor = options.backgroundColor;
    if (options.precision) this.precision = options.precision;
    if (options.upperLimitLineType)
      this.upperLimitLineType = options.upperLimitLineType;
    if (options.bottomLimitLineType)
      this.bottomLimitLineType = options.bottomLimitLineType;
    if (options.lineThickness) this.lineThickness = options.lineThickness;
    if ('lineVisibleK' in options) {
      this.lineVisibleK = !!options.lineVisibleK;
    }
    if ('lineVisibleD' in options) {
      this.lineVisibleD = !!options.lineVisibleD;
    }
    if ('upperVisible' in options) {
      this.upperVisible = !!options.upperVisible;
    }
    if ('bottomVisible' in options) {
      this.bottomVisible = !!options.bottomVisible;
    }
    if ('backgroundVisible' in options) {
      this.backgroundVisible = !!options.backgroundVisible;
    }

    this.points = {
      slowD: [],
      slowK: [],
    };
    this.renderSeriesConfig = [
      {
        label: '%K',
        id: `${this.type}-line-k-stoch`,
        color: this.lineColorK,
        propertyColor: 'lineColorK',
        thickness: this.lineThickness,
        propertyThickness: 'lineThickness',
        active: this.lineVisibleK,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        propertyVisible: 'lineVisibleK',
      },
      {
        label: '%D',
        id: `${this.type}-line-d-stoch`,
        color: this.lineColorD,
        propertyColor: 'lineColorD',
        thickness: this.lineThickness,
        propertyThickness: 'lineThickness',
        active: this.lineVisibleD,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        propertyVisible: 'lineVisibleD',
      },
      {
        label: 'Limite Superior',
        id: `${this.type}-upper-limit-stoch`,
        color: this.upperLimitColor,
        propertyColor: 'upperLimitColor',
        thickness: 1,
        active: this.upperVisible,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        dontShowTickness: true,
        showTypeLine: true,
        lineTypeId: this.upperLimitLineType,
        limit: this.upperLimit,
        showLimit: true,
        limitType: TTigerChartIndicatorLimitTypes.UPPER,
        property: 'upperLimit',
        propertyVisible: 'upperVisible',
      },
      {
        label: 'Limite Inferior',
        id: `${this.type}-bottom-limit-stoch`,
        color: this.bottomLimitColor,
        propertyColor: 'bottomLimitColor',
        thickness: 1,
        active: this.bottomVisible,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        dontShowTickness: true,
        showTypeLine: true,
        lineTypeId: this.bottomLimitLineType,
        limit: this.bottomLimit,
        showLimit: true,
        limitType: TTigerChartIndicatorLimitTypes.BOTTOM,
        property: 'bottomLimit',
        propertyVisible: 'bottomVisible',
      },
      {
        label: 'Background',
        id: `${this.type}-background-limit-stoch`,
        color: this.backgroundColor,
        propertyColor: 'backgroundColor',
        thickness: 1,
        active: this.backgroundVisible,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        dontShowTickness: true,
        propertyVisible: 'backgroundVisible',
      },
    ];
    this.settings = [
      {
        label: '%K Período',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property: 'fastK_Period' as keyof StochasticIndexBase,
      },
      {
        label: '%K Suavização',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property: 'slowK_Period' as keyof StochasticIndexBase,
      },
      {
        label: '%D Suavização',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property: 'slowD_Period' as keyof StochasticIndexBase,
      },
    ];
    this.styles = [
      {
        type: TIGER_INDICATOR_PARAMETER_TYPE.SELECTION,
        property: 'precision',
        label: 'Precisão',
        values: TIGER_INDICATOR_PRECISIONS,
      },
    ];
    this.lineNumber = options.lineNumber;
  }

  create(options: TTigerChartIndicatorCreateOptions): BaseRenderableSeries[] {
    this.baseChart = options.base;
    const xValues = this.data.id_point;
    const offset = new Array(this.totalPeriod).fill(NaN);

    this.lineDataSeriesKPeriod = new XyDataSeries(this.baseChart.wasmContext);
    this.lineDataSeriesDPeriod = new XyDataSeries(this.baseChart.wasmContext);
    /* O  TALIB STOCH 
      É necessário retirar no final do array do OUTPUT o tamanho do fastK_Period,
      pois eles retornam 0 por conta do calculo.
      e como o slice extrai até o tamanho, nao incluindo o fim, nao precisa subtrair 1 do fastK_Period.
    */
    this.lineDataSeriesDPeriod.appendRange(xValues, [
      ...offset,
      ...this.points.slowD.slice(
        0,
        this.data.id_point.length - this.totalPeriod
      ),
    ]);
    this.lineDataSeriesKPeriod.appendRange(xValues, [
      ...offset,
      ...this.points.slowK.slice(
        0,
        this.data.id_point.length - this.totalPeriod
      ),
    ]);

    const lineConfigK = this.renderSeriesConfig.find(
      (config) => config.id == `${this.type}-line-k-stoch`
    );
    this.lineRenderableSeriesKPeriod = new FastLineRenderableSeries(
      this.baseChart.wasmContext,
      {
        dataSeries: this.lineDataSeriesKPeriod,
        strokeThickness: lineConfigK?.thickness,
        stroke: lineConfigK?.color,
        id: `${this.mainLineId}_${this.lineNumber}_K`,
        xAxisId: options.xAxisId,
        isVisible: lineConfigK?.active,
      }
    );

    const lineConfigD = this.renderSeriesConfig.find(
      (config) => config.id == `${this.type}-line-d-stoch`
    );
    this.lineRenderableSeriesDPeriod = new FastLineRenderableSeries(
      this.baseChart.wasmContext,
      {
        dataSeries: this.lineDataSeriesDPeriod,
        strokeThickness: lineConfigD?.thickness,
        stroke: lineConfigD?.color,
        id: `${this.mainLineId}_${this.lineNumber}_D`,
        xAxisId: options.xAxisId,
        isVisible: lineConfigD?.active,
      }
    );

    this.setAnnotations(options.xAxisId);
    return [this.lineRenderableSeriesKPeriod, this.lineRenderableSeriesDPeriod];
  }

  updatePoints(): void {
    //do nothing.
  }

  append(xValue: number, data: TUpdateData, fullData: TData) {
    this.data = fullData;
    this.updatePoints();
    if (this.lineDataSeriesKPeriod && this.lineDataSeriesDPeriod) {
      this.lineDataSeriesDPeriod.append(
        xValue,
        this.points.slowD[this.points.slowD.length - this.totalPeriod - 1]
      );
      this.lineDataSeriesKPeriod.append(
        xValue,
        this.points.slowK[this.points.slowK.length - this.totalPeriod - 1]
      );
    }
  }

  insertRange(xValues: number[], fullData: TData): void {
    const offset = new Array(this.totalPeriod).fill(NaN);
    this.data = fullData;
    this.updatePoints();

    const s = this.baseChart.sciChartSurface.suspendUpdates();
    const arrayLength =
      xValues.length <= this.totalPeriod
        ? xValues.length
        : xValues.length - this.totalPeriod;
    try {
      if (xValues.length <= this.totalPeriod) {
        this.lineDataSeriesKPeriod.insertRange(0, xValues, [
          ...offset.slice(0, arrayLength),
        ]);
        this.lineDataSeriesDPeriod.insertRange(0, xValues, [
          ...offset.slice(0, arrayLength),
        ]);
      } else {
        this.lineDataSeriesKPeriod.insertRange(0, xValues, [
          ...offset,
          ...this.points.slowD.slice(0, arrayLength),
        ]);
        this.lineDataSeriesDPeriod.insertRange(0, xValues, [
          ...offset,
          ...this.points.slowD.slice(0, arrayLength),
        ]);
        for (
          let index = xValues.length - 1;
          index < this.totalPeriod;
          index++
        ) {
          this.lineDataSeriesKPeriod.update(index, NaN);
          this.lineDataSeriesDPeriod.update(index, NaN);
        }
      }

      const indexInitialValue =
        xValues.length <= this.totalPeriod
          ? this.totalPeriod
          : xValues.length - this.totalPeriod;
      for (
        let index = indexInitialValue;
        index < this.points.slowD.length - this.totalPeriod;
        index++
      ) {
        this.lineDataSeriesDPeriod.update(index, this.points.slowD[index]);
        this.lineDataSeriesKPeriod.update(index, this.points.slowK[index]);
      }
    } finally {
      s.resume();
    }
  }

  changeVisibility(): void {
    this.lineRenderableSeriesDPeriod.isVisible =
      !this.lineRenderableSeriesDPeriod.isVisible;
    this.lineRenderableSeriesKPeriod.isVisible =
      !this.lineRenderableSeriesKPeriod.isVisible;
  }

  update(index: number, data: TUpdateData, fullData: TData) {
    if (!this.lineDataSeriesDPeriod || !this.lineDataSeriesKPeriod) {
      return;
    }
    const count = this.lineDataSeriesDPeriod.count();
    if (index > count) {
      return;
    }
    this.data = fullData;
    this.updatePoints();
    this.lineDataSeriesDPeriod.update(
      index,
      this.points.slowD[this.points.slowD.length - this.totalPeriod - 1]
    );
    this.lineDataSeriesKPeriod.update(
      index,
      this.points.slowD[this.points.slowK.length - this.totalPeriod - 1]
    );
  }

  updateSettings(): void {
    const s = this.baseChart.sciChartSurface.suspendUpdates();
    const dataSeriesCount = this.lineDataSeriesDPeriod.count();

    const offset = new Array(this.totalPeriod).fill(NaN);

    const lineValuesDPeriod = [
      ...offset,
      ...this.points.slowD.slice(
        0,
        this.data.id_point.length - this.totalPeriod
      ),
    ];
    const lineValuesKPeriod = [
      ...offset,
      ...this.points.slowK.slice(
        0,
        this.data.id_point.length - this.totalPeriod
      ),
    ];
    /**
     * o offset padrão(fastK_Period) vai como NaN no inicio dos arrays
     *
     */

    if (dataSeriesCount > lineValuesDPeriod.length) {
      this.lineDataSeriesDPeriod.removeRange(
        lineValuesDPeriod.length - 1,
        dataSeriesCount - lineValuesDPeriod.length
      );
    }
    if (dataSeriesCount > lineValuesKPeriod.length) {
      this.lineDataSeriesKPeriod.removeRange(
        lineValuesKPeriod.length - 1,
        dataSeriesCount - lineValuesKPeriod.length
      );
    }

    try {
      lineValuesDPeriod.forEach((linePointValue, index) => {
        this.lineDataSeriesDPeriod.update(index, lineValuesDPeriod[index]);
      });
      lineValuesKPeriod.forEach((linePointValue, index) => {
        this.lineDataSeriesKPeriod.update(index, lineValuesKPeriod[index]);
      });
    } finally {
      s.resume();
    }
    this.onChange.next(null);
  }

  updateStyles(
    baseChart: TWebAssemblyChart,
    config: TTigerChartIndicatorRenderSeriesConfig
  ): void {
    const hash: any = {
      [`${this.type}-line-k-stoch`]: () => {
        const lineRenderSeries =
          baseChart.sciChartSurface.renderableSeries.getById(
            `${this.mainLineId}_${this.lineNumber}_K`
          );
        if (lineRenderSeries) {
          lineRenderSeries.stroke = !config.active
            ? 'transparent'
            : config.color;
          lineRenderSeries.strokeThickness = config.thickness;
        }
      },
      [`${this.type}-line-d-stoch`]: () => {
        const lineRenderSeries =
          baseChart.sciChartSurface.renderableSeries.getById(
            `${this.mainLineId}_${this.lineNumber}_D`
          );
        if (lineRenderSeries) {
          lineRenderSeries.stroke = !config.active
            ? 'transparent'
            : config.color;
          lineRenderSeries.strokeThickness = config.thickness;
        }
      },
      [`${this.type}-upper-limit-stoch`]: () => {
        const annotationUpper = this.getHorizontalLine(
          `${this.type}-upper-limit-stoch`
        );
        if (annotationUpper) {
          annotationUpper.isHidden = !config.active;
          annotationUpper.stroke = config.color;
          const lineConfig = LINE_CONFIG_DICTIONARY.get(config.lineTypeId!!);
          if (lineConfig) {
            annotationUpper.strokeDashArray = lineConfig.dashArray!!;
          }
        }
      },
      [`${this.type}-bottom-limit-stoch`]: () => {
        const annotationBottom = this.getHorizontalLine(
          `${this.type}-bottom-limit-stoch`
        );
        if (annotationBottom) {
          annotationBottom.isHidden = !config.active;
          annotationBottom.stroke = config.color;
          const lineConfig = LINE_CONFIG_DICTIONARY.get(config.lineTypeId!!);
          if (lineConfig) {
            annotationBottom.strokeDashArray = lineConfig.dashArray!!;
          }
        }
      },
      [`${this.type}-background-limit-stoch`]: () => {
        const annotationBackground = this.getBackgroundBox(
          `${this.type}-background-limit-stoch`
        );
        if (annotationBackground) {
          annotationBackground.isHidden = !config.active;
          annotationBackground.fill = `${config.color}21`;
        }
      },
    };
    const func = hash[config.id];
    if (func) {
      func();
    }
    this.onChange.next(null);
  }

  updatePositionHorizontalLine() {
    const annotationUpper = this.getHorizontalLine(
      `${this.type}-upper-limit-stoch`
    );
    const annotationBottom = this.getHorizontalLine(
      `${this.type}-bottom-limit-stoch`
    );
    const background = this.getBackgroundBox(
      `${this.type}-background-limit-stoch`
    );
    if (annotationUpper) {
      annotationUpper.y1 = this.upperLimit;
    }
    if (annotationBottom) {
      annotationBottom.y1 = this.bottomLimit;
    }
    if (background) {
      background.y1 = this.upperLimit;
      background.y2 = this.bottomLimit;
    }
  }

  private getHorizontalLine(id: string): HorizontalLineAnnotation {
    return this.baseChart.sciChartSurface.annotations.getById(
      id
    ) as HorizontalLineAnnotation;
  }

  private getBackgroundBox(id: string): BoxAnnotation {
    return this.baseChart.sciChartSurface.annotations.getById(
      id
    ) as BoxAnnotation;
  }

  delete() {
    this.baseChart.sciChartSurface.renderableSeries.remove(
      this.lineRenderableSeriesDPeriod
    );
    this.baseChart.sciChartSurface.renderableSeries.remove(
      this.lineRenderableSeriesKPeriod
    );
  }

  setValue(property: keyof this, value: any) {
    const prop = this[property];
    if (typeof this[property] == 'number') {
      this[property] = +value as unknown as typeof prop;
    } else {
      this[property] = value;
    }
    if (
      property.toString().includes('upper') ||
      property.toString().includes('bottom')
    ) {
      this.updatePositionHorizontalLine();
      return;
    }
    this.updateSettings();
  }

  getValuesByIndex(index?: number): TTooltipIndicatorValue[] {
    if (!this.lineDataSeriesDPeriod || !this.lineDataSeriesKPeriod) {
      return [];
    }
    const precision = this.precision;
    const lineValuesK = this.lineDataSeriesKPeriod.getNativeYValues();
    const lineValueK = {
      value: lineValuesK.get(index || lineValuesK.size() - 1),
      precision,
      color: this.renderSeriesConfig[0].color,
    };
    const lineValuesD = this.lineDataSeriesDPeriod.getNativeYValues();
    const lineValueD = {
      value: lineValuesD.get(index || lineValuesD.size() - 1),
      precision,
      color: this.renderSeriesConfig[1].color,
    };
    return [lineValueK, lineValueD];
  }

  private setAnnotations(xAxisId: string) {
    const configs = this.renderSeriesConfig.filter((config) => {
      return (
        config.id.includes('upper-limit') ||
        config.id.includes('bottom-limit') ||
        config.id.includes('background-limit')
      );
    });
    configs.forEach((config) => {
      if (
        config.id.includes('upper-limit') ||
        config.id.includes('bottom-limit')
      ) {
        const strokeDash = LINE_CONFIG_DICTIONARY.get(
          config.lineTypeId!!
        )?.dashArray;
        const line = new HorizontalLineAnnotation({
          showLabel: false,
          stroke: config.color,
          y1: config.limit!!,
          id: config.id,
          strokeThickness: config.thickness,
          strokeDashArray: strokeDash,
          xAxisId,
          isHidden: !config.active,
        });
        this.baseChart.sciChartSurface.annotations.add(line);
      }
      if (config.id.includes('background-limit')) {
        const background = new BoxAnnotation({
          fill: `${config.color}21`,
          strokeThickness: 0,
          xCoordinateMode: ECoordinateMode.Relative,
          id: config.id,
          x1: 0,
          x2: 1,
          y1: this.upperLimit,
          y2: this.bottomLimit,
          xAxisId,
          isHidden: !config.active,
        });
        this.baseChart.sciChartSurface.annotations.add(background);
      }
    });
  }

  resetConfiguration(): void {
    setDefaultConfiguration(
      this,
      this.baseChart,
      this.options,
      this.settings,
      this.renderSeriesConfig,
      this.styles,
      this.type
    );
  }
}
