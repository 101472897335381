import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-data-item',
  templateUrl: './data-item.component.html',
  styleUrls: ['./data-item.component.scss'],
})
export class DataItemComponent {
  @Input() field!: string;
  @Input() value!: string;
  @Input() displayButton?: boolean = false;
  @Input() buttonLabel?: string = '';

  @Output() handleClick = new EventEmitter<void>();
}
