import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Inject,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  FlaIconModule,
  FlaInputModule,
  ROCKET_MODAL_DATA,
  RocketButtonModule,
  RocketModalModule,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { IBrokerConnectionInfos } from '@shared/services/api/trademap/V9/models/brokers-connection-infos.interface';
import { IConnectionBroker } from './models/connection-broker-fields.interface';
import { isNullOrUndefined, removeEmojis } from 'src/app/utils/utils.functions';
import { StandaloneModalsService } from '@shared/modals/standalone-modals.service';
import { take, timer } from 'rxjs';
import { ModalConnectBrokerComponent } from '../modal-connect-broker/modal-connect-broker.component';
import { BrokerService } from '@shared/services/api/trademap/v1/broker.service';
import { ToastService } from '@shared/services';
import { ModalBrokersToConnectComponent } from '../modal-brokers-to-connect/modal-brokers-to-connect.component';
import { TermsService } from '@shared/services/api/terms/v2/terms.service';
import { BrokerLogoComponent } from '@modules/broker-connection/components/broker-logo/broker-logo.component';
import { TrademapV10Service } from '@shared/services/api/trademap/V10/trademap.service';

@Component({
  selector: 'app-modal-connect-broker-form',
  templateUrl: './modal-connect-broker-form.component.html',
  styles: [
    `
      .broker-fields-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        margin: 24px 0px;
      }
    `,
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlaInputModule,
    RocketButtonModule,
    FlaIconModule,
    RocketModalModule,
    BrokerLogoComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConnectBrokerFormComponent
  extends RocketModalRef
  implements OnInit
{
  public formConnectBroker!: FormGroup;
  public broker!: IBrokerConnectionInfos;
  public connectionField: IConnectionBroker[] = [];
  public displayPassword: boolean = false;
  public showBrokerRequirements: boolean = false;
  public hasTerm: boolean = false;
  public isLoading: boolean = false;
  public displayTermPassword: boolean = false;
  public errorMessage: string = '';
  public statusInputError: string = 'Preencha o campo.';
  public placeholder: {
    [key: string]: string;
  } = {
    accountNumber: 'Digite o número da conta com dígito.',
    token: 'Digite o token.',
    password: 'Digite sua senha da corretora.',
    dt_birthday: '',
    email: 'Digite seu e-mail da corretora.',
    codeClient: 'Digite seu código de cliente.',
    senha: 'Digite sua senha da corretora.',
    accessKey: 'Digite sua Access Key da corretora.',
    secretKey: 'Digite sua Secret Key da corretora.',
    apiKey: 'Digite sua API Key da corretora.',
    userId: 'Digite seu User ID da corretora.',
    identifier: 'Digite o seu Identificador da corretora.',
    secret: 'Digite o segredo da sua corretora.',
    account: 'Digite o seu número de conta da corretora.',
  };

  private _standaloneModalService = inject(StandaloneModalsService);
  private _cdr = inject(ChangeDetectorRef);
  private _fb = inject(FormBuilder);
  private _termsService = inject(TermsService);
  private _brokerService = inject(BrokerService);
  private _toast = inject(ToastService);
  private _trademapV10Service = inject(TrademapV10Service);

  constructor(
    service: RocketModalService,
    @Inject(ROCKET_MODAL_DATA)
    private data: { broker: IBrokerConnectionInfos }
  ) {
    super(service);
  }

  ngOnInit() {
    this.broker = this.data.broker;
    this.hasTerm = Boolean(this.broker?.term && this.broker?.term.idTerm);
    this._buildFormField();
  }

  public fixValueTexts(event: any, field: string) {
    const text = event?.target?.value || event?.value;
    if (isNullOrUndefined(text)) return;
    this.formConnectBroker.get(field)?.setValue(removeEmojis(text));
  }

  private _buildFormField(): void {
    const fields = JSON.parse(this.data.broker.questionnaireField!);
    this._createFormFields(fields);
  }

  private _createFormFields(fields: IConnectionBroker[]) {
    const validators: any = {
      NUMERIC: [Validators.pattern('^[0-9]*$')],
      ALPHA: [
        Validators.pattern(
          '^[a-zA-Z0-9À-ÿ !"#$%&\\\'()*+,-./:;<=>?@\\[\\]^_`{|}~]*$'
        ),
      ],
    };
    const field = fields.reduce((acc: any, item: any) => {
      acc[item.cd_field] = [
        '',
        [Validators.required, ...validators[item.cd_type]],
      ];
      return acc;
    }, {});

    if (this.hasTerm)
      field['password'] = [
        '',
        [Validators.required, Validators.maxLength(120)],
      ];

    this.formConnectBroker = this._fb.group(field);
    this.connectionField = fields;
    this._cdr.detectChanges();
  }

  public handleBtnAction(action: 'CANCEL' | 'SUBMIT'): void {
    if (action === 'SUBMIT') {
      this.connectBroker();
      return;
    }
    this._closeAndOpenOtherModal(ModalConnectBrokerComponent, {
      idBroker: this.broker.idBroker,
    });
  }

  public connectBroker() {
    if (!this.connectionField.length || !this.formConnectBroker.valid) return;
    this.isLoading = true;
    this._brokerService
      .loginMultibroker(this.broker.idBroker, this.formConnectBroker.value)
      .subscribe((response) => {
        if (response.success) {
          const successLabel = response.text || response.result.text;
          this._updateBrokersAfterConnection();
          if (this.hasTerm) {
            this._assignTerm(successLabel);
            return;
          }
          this._brokerConnected(successLabel);
          return;
        }
        this.statusInputError = 'Conta inválida, revise para continuar.';
        Object.keys(this.formConnectBroker.controls).forEach(
          (field: string) => {
            this.formConnectBroker.get(field)?.setValue('');
          }
        );
        if (response.error)
          this.errorMessage =
            response.error != 'Connection reset'
              ? response.error
              : 'Desculpe, ocorreu um erro durante a conexão.';
        this.isLoading = false;
        this._cdr.detectChanges();
      });
  }

  private _updateBrokersAfterConnection(): void {
    this._brokerService.getBrokers(true).subscribe();
    this._trademapV10Service.getBrokersToConnect().subscribe();
  }

  private _assignTerm(successLabel: string) {
    const password = this.formConnectBroker.controls['password'].value;
    if (password?.length && this.broker.term) {
      this._termsService
        .approveTerm(this.broker.term.idTerm, password, true)
        .subscribe(() => this._brokerConnected(successLabel));
    }
  }

  private _brokerConnected(message: string): void {
    this._toast.showToast('success', message || 'Conexão bem-sucedida!');
    this._closeAndOpenOtherModal(ModalBrokersToConnectComponent, {});
  }

  private _closeAndOpenOtherModal(component: any, params: any): void {
    this._standaloneModalService.close();
    timer(75)
      .pipe(take(1))
      .subscribe(() =>
        this._standaloneModalService.openStandaloneModal(
          component,
          params,
          {},
          '',
          'lg'
        )
      );
  }
}
