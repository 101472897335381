import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StockChartComponent } from './stock-chart.component';
import { StockChartService } from './service/stock-chart.service';

import { SearchStockModule } from '../search-stock/search-stock.module';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { StockChartHeaderComponent } from './parts/header/stock-chart-header.component';
import { StockChartModalIntervalComponent } from './parts/modal-interval/stock-chart-modal-interval.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StockChartModalStockComponent } from './parts/modal-stock/stock-chart-modal-stock.component';
import { StockChartModalMoreOptionsComponent } from './parts/modal-more-options/stock-chart-modal-more-options.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { TigerChartModule } from '@shared/tiger-chart/tiger-chart.module';
import { LoadingModule } from '../loading/loading.module';
import { RTInputCountModule } from '@shared/rocket-components/input-count/input-count.module';
import { ToggleIndicatorService } from './service/toggle-indicator.service';
import { IndicatorsModalComponent } from './parts/header/indicators-modal/indicators-modal.component';
import {
  FlaCheckModule,
  FlaIconModule,
  FlaInputModule,
  FlaSelectModule,
  FlaTabModule,
  FlaTagModule,
  RocketButtonModule,
  RocketModalModule,
} from '@shared/rocket-components/components/index';
import { DrawToolsService } from './service/draw-tools.service';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { StockChartModalConfigCandleComponent } from './parts/modal-more-options/candle/stock-chart-modal-config-candle.component';
import { ConfigColorpickerModule } from '@shared/tiger-chart/tiger-chart-tools/toolbar-draw/config-colorpicker/config-colorpicker.component.module';
import { StockChartModalConfigVolumeComponent } from './parts/modal-more-options/volume/stock-chart-modal-config-volume.component';
import {
  FlaCollapseModule,
  FlaMaskModule,
} from '@shared/rocket-components/directives';
import { TigerChartFooterComponent } from '@shared/tiger-chart/tiger-chart-footer/tiger-chart-footer.component';
import { LogoModule } from '../logo/logo.module';
import {
  DropDownIntervalComponent,
  DropDownTypesComponent,
  IntervalOptionsComponent,
  ChartTypesComponent,
  StockChartHeaderButtonComponent,
  StockChartSelect,
} from './parts/header/parts';
import { FlaFocusModule } from '@shared/directives/focus/focus.module';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';
import { StockCardModule } from '@shared/rocket-components/components/stock-card/stock-card.module';
import { RocketIconsModule } from '@shared/rocket-icons/rocket-icons.module';
import { MostSearchModule } from '@shared/modals/most-search/most-search.module';
import { ModalTradeIdeaModule } from '../trade-idea/modal-trade-idea/modal-trade-idea.module';

const COMPONENT = [
  StockChartComponent,
  StockChartHeaderComponent,
  DropDownIntervalComponent,
  DropDownTypesComponent,
  IntervalOptionsComponent,
  ChartTypesComponent,
  StockChartHeaderButtonComponent,
  StockChartSelect,
  StockChartModalIntervalComponent,
  StockChartModalStockComponent,
  StockChartModalMoreOptionsComponent,
  IndicatorsModalComponent,
  StockChartModalConfigCandleComponent,
  StockChartModalConfigVolumeComponent,
  TigerChartFooterComponent,
];
@NgModule({
  declarations: [COMPONENT],
  imports: [
    CommonModule,
    SearchStockModule,
    ReactiveFormsModule,
    FormsModule,
    FlaWorkSpaceModule,
    FlaIconModule,
    FlaSelectModule,
    RTDropdownModule,
    FlaCheckModule,
    FlaMaskModule,
    RTInputCountModule,
    FlaTabModule,
    ColorPickerModule,
    TigerChartModule,
    LoadingModule,
    FlaCollapseModule,
    TooltipsModule,
    FlaInputModule,
    ConfigColorpickerModule,
    RocketButtonModule,
    RocketModalModule,
    LogoModule,
    FlaFocusModule,
    FlaTagModule,
    StockCardModule,
    RocketIconsModule,
    MostSearchModule,
    ModalTradeIdeaModule,
    FlaIconModule
  ],
  exports: [COMPONENT],
  providers: [StockChartService, ToggleIndicatorService, DrawToolsService],
})
export class StockChartModule {}
