import {
  bigValueFormatter,
  execFormatFinancial,
  formatterNumber,
} from 'src/app/utils/utils.functions';

const formatAuctionVariation = (value: number): string => {
  return value ? `${formatterNumber(+value)}%` : '-';
};

const formatAuctionPrice = (params: any, value: number): string => {
  return value ? execFormatFinancial(params, value) : '-';
};

const formatQttyNotAnswered = (
  qtty: number,
  senseNotAnswered: string
): string => {
  if (!qtty) return '-';
  const isCNA = senseNotAnswered == 'A';
  return `
  <span class="hstack align-items-center gap-1">
    <span class="${isCNA ? 'text-brand-primary' : 'text-multibroker-sell'}">
      ${isCNA ? 'C' : 'V'}NA 
    </span>
    ${bigValueFormatter(qtty)}
  </span>
  `;
};

const formatQtty = (value: number): string => {
  return value ? bigValueFormatter(value) : '-';
};

export const formatAuctionValues = (params: any) => {
  params.variacao_leilao = formatAuctionVariation(params.variacao_leilao);
  params.preco_leilao = formatAuctionPrice(params, params.preco_leilao);
  params.qtde_leilao_nao_atendida = formatQttyNotAnswered(
    params.qtde_leilao_nao_atendida,
    params.sentido_leilao_nao_atendida
  );
  params.qtde_leilao = formatQtty(params.qtde_leilao);
  params.variacao_dia = formatAuctionVariation(params.variacao_dia);
  return params;
};

export const calculateTimeDifference = (openTime: string): number => {
  if (!openTime) return -1;
  const data = openTime.split(':');
  const date1 = new Date();
  const date2 = new Date();
  date2.setHours(+data[0], +data[1], +data[2]);
  return (date2.getTime() - date1.getTime() + 1000) / 1000;
};

export const countdown = (
  timer: number
): { timerFormatted: string; timer: number } => {
  if (timer > -1) {
    let min: any = timer / 60;
    let seg: any = timer % 60;
    +min < 10 && (min = `0${min}`);
    +seg < 10 && (seg = `0${seg}`);
    min = `${min}`.substring(0, 2);
    seg = `${seg}`.substring(0, 2);
    const timerFormatted = `${min}:${seg}`;
    timer = timer - 1;
    return { timerFormatted, timer };
  }
  return { timerFormatted: '00:00', timer: -1 };
};
