import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@shared/services/api/trademap/V9/authentication.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styles: [
    `
      .confirm-container {
        height: 100vh;
        width: 100%;
      }

      .confirm-card {
        width: 41rem;
        height: 21rem;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmEmailComponent implements OnInit, OnDestroy {
  private _titles = {
    LOADING: 'Validando e-mail, por favor aguarde.',
    SUCCESS: 'Obrigado por confirmar o seu e-mail!',
    ERROR:
      'Não foi possível validar seu e-mail, verifique as informações e tente novamente mais tarde.',
  };
  private _redirTimeoutSubject = new Subject<void>();

  public titleColor:
    | 'text-feedback-error'
    | 'text-brand-primary'
    | 'text-white' = 'text-white';
  public title = this._titles.LOADING;
  public redirtTimeout: number = 10;
  public isLoading: boolean = true;
  public tokenError: boolean = false;

  constructor(
    private _activeRoute: ActivatedRoute,
    private _router: Router,
    private _authServiceV9: AuthenticationService,
    private _cdr: ChangeDetectorRef
  ) {
    this._redirTimeoutSubject.pipe(debounceTime(1000)).subscribe(() => {
      this.redirtTimeout = this.redirtTimeout - 1;
      if (!this.redirtTimeout) {
        this._router.navigateByUrl('auth/login');
        return;
      }
      this._redirTimeoutSubject.next();
      this._cdr.detectChanges();
    });
  }

  ngOnInit() {
    const emailToken = this._activeRoute.snapshot.paramMap.get('token');
    if (!emailToken) {
      this.title = this._titles.ERROR;
      this.isLoading = false;
      this._redirTimeoutSubject.next();
      return;
    }
    this._confirmEmail(emailToken);
  }

  ngOnDestroy(): void {
    this._redirTimeoutSubject.unsubscribe();
  }

  private _confirmEmail(token: string): void {
    this._authServiceV9.confirmEmail(token).subscribe({
      next: () => {
        this.titleColor = 'text-brand-primary';
        this.title = this._titles.SUCCESS;
        this.isLoading = false;
        this._redirTimeoutSubject.next();
        this._cdr.detectChanges();
      },
      error: () => {
        this.titleColor = 'text-feedback-error';
        this.title = this._titles.ERROR;
        this.isLoading = false;
        this._redirTimeoutSubject.next();
        this._cdr.detectChanges();
      },
    });
  }
}
