import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ROCKET_MODAL_DATA,
  RocketModalModule,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { StandaloneModalsService } from '../../standalone-modals.service';
import { Subscription, take, timer } from 'rxjs';
import { IPartnerInfos } from '@shared/services/core/partners-services/partner-preferences.interface';
import { system } from '@core/system/system.service';
import { getUserName } from 'src/app/utils/utils.functions';
import { PartnerConnectionCardComponent } from '../partner-connection-card/partner-connection-card.component';
import { PartnersService } from '@shared/services/core/partners-services/partners.service';

@Component({
  selector: 'app-connect-partner',
  templateUrl: './connect-partner.component.html',
  styles: [
    `
      :host {
        ::ng-deep {
          .modal-dialog {
            margin: unset;
            width: 610px;
          }

          .modal-body {
            padding: 2px;
            cursor: pointer;
            border-radius: 8px;
            background: linear-gradient(
              to right,
              #6842b9 0%,
              var(--vm-brand-primary) 100%
            );
          }

          .connect-partner-modal {
            .content-container {
              background: var(--vm-neutral-stronger);
            }

            .modal {
              width: 355px;
              height: 97px;
              max-height: 97px;
              top: calc(100vh - (97px + 16px));
              left: 16px;
            }

            .modal-dialog {
              width: 355px;
              height: 97px;
              max-height: 97px;
            }
          }
        }
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, RocketModalModule, PartnerConnectionCardComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectPartnerComponent
  extends RocketModalRef
  implements OnInit, OnDestroy
{
  private _autoClose!: Subscription;
  public params!: IPartnerInfos;
  public title: string = '';
  public message: string = '';

  private _standaloneModalService = inject(StandaloneModalsService);
  private _partnersService = inject(PartnersService);

  constructor(
    private _rocketModalService: RocketModalService,
    @Inject(ROCKET_MODAL_DATA)
    private data: { partner: IPartnerInfos }
  ) {
    super(_rocketModalService);
  }

  ngOnInit(): void {
    this._autoClose = timer(10000)
      .pipe(take(1))
      .subscribe(() => this._standaloneModalService.close());
    const infos = this.data.partner;
    const logoPath = infos.name.toLowerCase().replace(/ /g, '-');
    infos.logo = `../../../../assets/logo/logo-${logoPath}.svg`;
    this.params = infos;
    this._configureCardContent();
  }

  override ngOnDestroy(): void {
    this._autoClose.unsubscribe();
    super.ngOnDestroy();
  }

  private _configureCardContent(): void {
    const name = getUserName(system.userAuthenticated.investor.name, 12);
    const nameLabel = name ? ` ${name}!` : '';
    this.title = `E aí, ${nameLabel} Está gostando?`;
    this.message = `Entre na sua conta ${this.params.key!.toUpperCase()}, conecte e comece a potencializar seus trades agora mesmo!`;
  }

  public openConnectBrokerModal(): void {
    this._autoClose.unsubscribe();
    this._standaloneModalService.close();
    this._partnersService.openConnectionModal();
  }
}
