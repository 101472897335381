import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TOptions } from '../../../types';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { RocketModalService } from '@shared/rocket-components/components';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { RocketCreateComponentService } from '@shared/rocket-components/services';
import { RocketGridComponent } from '@shared/rocket-grid/rocket-grid.component';
import { ISearchStock } from '@core/interface';
import { OptionsListBase } from '../options-list-base';
import { OptionsListHelper } from '../options-list.helper';
import { OptionsComponentService } from '@shared/components/options/options-component.service';
import { IRowData } from '@shared/components/stock-table/interfaces/stock-table.interfaces';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { OptionsService } from '@shared/components/options/options.service';

@Component({
  selector: 'app-options-list-table',
  templateUrl: './options-list-table.component.html',
  styleUrls: ['./options-list-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsListTableComponent
  extends OptionsListHelper
  implements OptionsListBase, OnInit, AfterViewInit, OnDestroy
{
  @Input() override linked: boolean = false;
  @Input() set width(width: string) {
    if (width) {
      this._width = parseInt(width);
    }
  }
  @Input() refId!: string;
  @Input() override componentId: string = '';
  @Input() height!: string;
  @Input() stock!: ISearchStock | undefined;
  @Input() override configKey: string = '';
  @Input() loading = true;
  @ViewChild('grid') override grid: RocketGridComponent | undefined = undefined;
  @ViewChild('listWrapper', { static: true }) listWrapper!: ElementRef;

  rowData: TOptions[] = [];
  override subheaderTab = 'table';
  private _indexMapping: { [key: string]: number } = {};

  constructor(
    themeService: ThemePreferencesService,
    modalService: RocketModalService,
    customPreferencesService: CustomPreferencesService,
    createComponent: RocketCreateComponentService,
    optionsComponentService: OptionsComponentService,
    private cdr: ChangeDetectorRef,
    contextMenuService: ContextMenuService,
    globalStock: GlobalSelectedStockSubscription,
    optionsService: OptionsService
  ) {
    super(
      themeService,
      modalService,
      customPreferencesService,
      createComponent,
      optionsComponentService,
      cdr,
      contextMenuService,
      globalStock,
      optionsService
    );
  }

  ngOnInit(): void {
    this.listWrapper.nativeElement.oncontextmenu = this._showConfig;
    this._initiateConfigs();
  }

  ngAfterViewInit(): void {
    this.ready = true;
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  processOptions(response: any) {
    if (!this.stock) return [];

    if (!response || !response.length) {
      this.rowData = [];
      this.cdr.detectChanges();
      return [];
    }

    const { id_exchange } = this.stock;
    const cheetahItems: string[] = [];

    this.rowData = response.map((row: TOptions, index: number) => {
      const item = `${row.cd_stock}:${id_exchange}`;
      this._indexMapping[item] = index;
      cheetahItems.push(item);
      return {
        ...row,
        label: row.cd_stock,
        valor_melhor_oferta_compra: 0,
        valor_melhor_oferta_venda: 0,
      };
    });

    return cheetahItems;
  }

  optionsChannelHandler = (payload: Map<string, any>): void => {
    payload.forEach((data, key) => {
      if (!this._indexMapping[key] || !data || this.loading) return;
      const rowIndex = this._indexMapping[data.item];
      this.rowData[rowIndex] = {
        ...this.rowData[rowIndex],
        ...data,
      };

      data.days_to_expiration &&
        (this.rowData[rowIndex].option_days_to_expiration =
          data.days_to_expiration);
      data.vol_implicitly &&
        (this.rowData[rowIndex].vl_vol_implicitly = data.vol_implicitly);
      data.theta && (this.rowData[rowIndex].vl_theta = data.theta);
      data.delta && (this.rowData[rowIndex].vl_delta = data.delta);
      data.rho && (this.rowData[rowIndex].vl_rho = data.rho);
      data.gamma && (this.rowData[rowIndex].vl_gamma = data.gamma);
      data.vega && (this.rowData[rowIndex].vl_vega = data.vega);
      data.qtde_negocios &&
        (this.rowData[rowIndex].qt_trades = data.qtde_negocios);
      data.qtde_papel_negociado &&
        (this.rowData[rowIndex].qt_share_traded = data.qtde_papel_negociado);
      data.variacao_dia &&
        (this.rowData[rowIndex].day_variation = data.variacao_dia);
      data.vl_close && (this.rowData[rowIndex].vl_close = data.vl_close);

      this.gridApi.applyTransactionAsync({
        update: [
          { idRow: this.rowData[rowIndex].cd_stock, ...this.rowData[rowIndex] },
        ],
      });
      this.cdr.detectChanges();
    });
  };

  quotesChannelHandler = (data: IRowData): void => {
    if (!data || this.loading || !this._indexMapping[data.item]) return;
    const rowIndex = this._indexMapping[data.item];

    if (!this.rowData[rowIndex]) return;

    if (data.valor_melhor_oferta_compra)
      this.rowData[rowIndex].valor_melhor_oferta_compra = parseFloat(
        data.valor_melhor_oferta_compra
      );

    if (data.valor_melhor_oferta_venda)
      this.rowData[rowIndex].valor_melhor_oferta_venda = parseFloat(
        data.valor_melhor_oferta_venda
      );
    this.gridApi.applyTransactionAsync({
      update: [
        { idRow: this.rowData[rowIndex].cd_stock, ...this.rowData[rowIndex] },
      ],
    });
    this.cdr.detectChanges();
  };
}
