<workspace-doc-header css="w-100 d-flex header-wrapper nav">
  <fla-workspace-doc-header-item
    [enableContent]="true"
    *ngFor="let tab of tabs; let i = index"
  >
    <fla-workspace-doc-header-item
      margin="mx-2"
      [hideBorder]="true"
      [name]="tab.name"
      [active]="selectedTab === tab.type"
      (flaClick)="selectTab(tab)"
    >
    </fla-workspace-doc-header-item>
  </fla-workspace-doc-header-item>
</workspace-doc-header>
