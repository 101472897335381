import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  TOptionsStrategyType,
  TOptionsStrateyAnswer,
  TStrategyOption,
} from '../../types';
import {
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { ISearchStock } from '@core/interface';
import { Subject } from 'rxjs';
import { OptionsComponentService } from '../../options-component.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-options-strategy-modal',
  templateUrl: './options-strategy-modal.component.html',
  styleUrls: ['./options-strategy-modal.component.scss'],
})
export class OptionsStrategyModalComponent
  extends RocketModalRef
  implements OnInit, OnDestroy
{
  price: TOptionsStrateyAnswer = '';
  volatility: TOptionsStrateyAnswer = '';
  filteredStrategies: TOptionsStrategyType[] = [];
  strategies: TOptionsStrategyType[] = [];
  selectedStrategy!: TOptionsStrategyType;
  src = '';
  loading = true;
  data: any;
  searchField!: FormControl;

  private _stock!: ISearchStock;
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private modalService: RocketModalService,
    private _strategyService: OptionsComponentService
  ) {
    super(modalService);
  }

  ngOnInit() {
    this.searchField = new FormControl('');
    this.data = this.modalService.data;
    this.data.stock && (this._stock = this.data.stock as ISearchStock);
    if (this.data.strategies) {
      this.strategies = this.data.strategies;
      this.filteredStrategies = this.data.strategies;
      this.setStrategy(this.strategies[0]);
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }

  onAnswerChange(value: TOptionsStrateyAnswer, type: string) {
    type == 'price' && (this.price = this.price == value ? '' : value);
    type == 'volatility' &&
      (this.volatility = this.volatility == value ? '' : value);

    this.filterStrategies();
  }

  setStrategy(strategy: TOptionsStrategyType) {
    this.selectedStrategy = strategy;
    this.src = strategy
      ? `/assets/svg/option-strategies/${strategy.icon || 'fallback'}.svg`
      : '';
  }

  selectOperation() {
    if (!this.selectedStrategy || !this.selectedStrategy.legs.length) return;

    const legs = this.selectedStrategy.legs as TStrategyOption[];
    this._strategyService.setStrategyOptions(legs, this.selectedStrategy);
    this.close();
  }

  filterStrategies() {
    const searchString = this.searchField.value;

    this.filteredStrategies = this.strategies.filter((strategy) => {
      if (this.price && strategy.trending_market !== this.price) return false;
      if (this.volatility && strategy.trending_vol !== this.volatility)
        return false;

      if (
        searchString &&
        strategy.ds_title.toUpperCase().indexOf(searchString.toUpperCase()) < 0
      )
        return false;

      return true;
    });

    this.setStrategy(this.filteredStrategies[0]);
  }
}
