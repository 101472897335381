import { FlaIToast } from './interface/toast.interface';
import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TOAST_ICON_COLOR } from './constants/toast.constants';
import { randomId } from '@shared/rocket-components/utils';
import { FlaIconType } from '../components/icon/types/icon.types';

@Component({
  selector: 'fla-toast[data]',
  templateUrl: './toast.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlaToastComponent implements OnDestroy {
  id: string = randomId('fla_toast');
  data!: FlaIToast;
  iconType: FlaIconType = 'icons';
  toastIconColor = TOAST_ICON_COLOR;
  private onClosed = new Subject<any>();

  ngOnDestroy(): void {
    this.onClosed.next(undefined);
    this.onClosed.complete();
  }
  afterClosed(): Observable<any> {
    return this.onClosed.asObservable();
  }
}
