// or, for npm, import { NumericTickProvider, ... } from "scichart"

import { reOrderVisibleRange } from '@shared/components/stock-chart/constants/stock-chart.constant';
import {
  LogarithmicTickProvider,
  NumberRange,
  TSciChart,
  TSciChart3D,
} from 'scichart';

// Custom TickProvider implementation
//
export class CustomLogarithmicTickProvider extends LogarithmicTickProvider {
  private webContext!: TSciChart | TSciChart3D;
  constructor(webAssemblyContext: TSciChart | TSciChart3D) {
    super(webAssemblyContext);
    this.webContext = webAssemblyContext;
  }

  // returns an array of major gridline positions in data space
  // Called once per draw. Can be dynamic
  override getMajorTicks(
    minorDelta: number,
    majorDelta: number,
    visibleRange: NumberRange
  ): number[] {
    const newVisibleRange = reOrderVisibleRange(visibleRange);
    const min = newVisibleRange.min;
    const max = newVisibleRange.max;
    const diffMaxMin = Math.abs(max - min);
    const defaultReturn = [this.round(max) - 0.01, this.round(max)];
    if (diffMaxMin < 0.01) {
      return defaultReturn;
    }
    const major = super.getMajorTicks(minorDelta, majorDelta, newVisibleRange);
    const val = major.reduce((acc: number[], m) => {
      if (m >= min && m <= max) {
        acc.push(m);
      }
      return acc;
    }, []);
    return val.length ? val : defaultReturn;
  }

  private round(value: number) {
    const fator = Math.pow(10, 2);
    const newValue = Math.round(value * fator) / fator;
    return newValue;
  }
}
