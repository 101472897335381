<div
  *ngIf="strategy.legs.length > 0"
  class="w-100 mb-2 mr-1 d-flex align-items-center"
  [ngClass]="{
    'justify-content-center': hideLabel,
    'cursor-pointer': !hideLabel,
    selected: strategy.ds_title === selectedStrategy
  }"
  (click)="triggerClick()"
  role="button"
  aria-hidden="true"
>
  <img [src]="src" [alt]="strategy.ds_strategy" class="strategy-img pr-2" />
  <div *ngIf="!hideLabel" class="h-100">
    <div
      class="fs-5 fw-bold w-100"
      [ngClass]="{
        'text-brand-primary': strategy.ds_title === selectedStrategy
      }"
    >
      {{ strategy.ds_title }}
    </div>
    <div class="fs-6 w-100">
      Ação
      <fla-icon
        [name]="arrows[strategy.trending_market]"
        [size]="arrowSizes[strategy.trending_market]"
        [css]="arrowClasses[strategy.trending_market] + ' p-0 pl-1'"
      ></fla-icon>
      Vol
      <fla-icon
        [name]="arrows[strategy.trending_vol]"
        [size]="arrowSizes[strategy.trending_vol]"
        [css]="arrowClasses[strategy.trending_vol] + ' p-0 pl-1'"
      ></fla-icon>
    </div>
  </div>
</div>
