import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IInterval } from '@shared/components/stock-chart/interface/stock-chart.interface';
import { TIGER_INTERVAL_ENUM } from '@shared/tiger-chart/enum';

@Component({
  selector: 'interval-options',
  template: `
    <ng-container *ngFor="let item of options">
      <hr *ngIf="item.divider" />
      <li>
        <a
          class="dropdown-item px-2 hstack"
          type="button"
          [ngClass]="{
            'text-brand-primary': currentID === item.id
          }"
          (click)="onSelectOption.emit(item.id)"
        >
          {{ item.name }}

          <span class="new" title="Novo" *ngIf="item.new"></span>
        </a>
      </li>
    </ng-container>
  `,
})
export class IntervalOptionsComponent {
  @Input() options: IInterval[] = [];
  @Input() currentID!: TIGER_INTERVAL_ENUM;
  @Output() onSelectOption: EventEmitter<TIGER_INTERVAL_ENUM> =
    new EventEmitter<TIGER_INTERVAL_ENUM>();

  constructor() {}
}
