import { TIGER_INDICATORS_MENU_ENUM } from '../../enum/tiger-chart.enum';
import MenuItemBase from './menu-item-base';

export default class VisualOrderBringToFront extends MenuItemBase {
  constructor() {
    super();
    this.label = 'Tudo para frente';
    this.id = TIGER_INDICATORS_MENU_ENUM.ORDER_BRING_TO_FRONT;
  }

  override onCLick(): void {
    console.log(this.id);
  }
}
