import { ALERT_STATUS_ENUM } from '@core/interface';
import { deepClone } from '@shared/rocket-components/utils/functions';
import { ColDef } from 'ag-grid-community';
import { dateOrTimeFormatter } from 'src/app/utils/cell-format.functions';
import {
  execFormatFinancial,
  isNullOrUndefined,
} from 'src/app/utils/utils.functions';
import { AlertSettingsComponent } from '../alert-settings/alert-settings.component';
import { CustomTooltipComponent } from '../custom-tooltip/custom-tooltip.component';
import { GenericFilterComponent } from '../generic-filter/generic-filter.component';
import { FILTER_OPTIONS_ORDER_GRID } from './filter-options';

function getAlertBackgroundColor(cd_status: ALERT_STATUS_ENUM) {
  return {
    'background-color':
      cd_status === ALERT_STATUS_ENUM.ACTIVE ? '#345977' : '#2F6D2F',
  };
}

export const HEADERS_ALERTS_ICONS: Array<ColDef> = [
  {
    headerName: '',
    field: 'edit',
    minWidth: 32,
    maxWidth: 32,
    width: 32,
    lockPosition: 'left',
    pinned: 'left',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onCellClicked: () => {}, // Tratativa no orders-history-grid.component.ts:244
    cellClass: (params) => {
      if (isNullOrUndefined(params.data.cd_status)) {
        return '';
      }
      return params.data.cd_status == ALERT_STATUS_ENUM.COMPLETED
        ? 'ag-grid-custom-span-column'
        : '';
    },
    cellRenderer: (params: any) => {
      if (isNullOrUndefined(params.data.cd_status)) {
        return '';
      }
      return params.data.cd_status == ALERT_STATUS_ENUM.ACTIVE
        ? ` <i class="icons icon-size-micro text-feedback-warning cursor-pointer"> edit </i> `
        : `<i class="icons icon-size-micro text-feedback-success cursor-pointer"> refresh </i>`;
    },
  },
  {
    headerName: '',
    field: 'delete',
    minWidth: 32,
    maxWidth: 32,
    width: 32,
    lockPosition: 'left',
    pinned: 'left',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onCellClicked: () => {}, // Tratativa no orders-history-grid.component.ts:244
    cellRenderer: (params: any) => {
      if (isNullOrUndefined(params.data.cd_status)) {
        return null;
      }
      return params.data.cd_status == ALERT_STATUS_ENUM.ACTIVE
        ? ` <i class="icons icon-size-micro text-feedback-negative cursor-pointer"> close </i>`
        : null;
    },
  },
];

export const HEADERS_ALERTS_CONFIG: any = {
  delete: {
    hide: false,
  },
  edit: {
    hide: false,
  },
  dh_insert: {
    hide: false,
    width: 90,
  },
  cd_stock: {
    hide: false,
    width: 70,
  },
  vl_value: {
    hide: false,
    width: 80,
  },
  vl_current_price: {
    hide: false,
    width: 80,
  },
  alert_status: {
    hide: false,
    width: 80,
  },
};

export const HEADERS_ALERTS: Array<ColDef> = [
  {
    headerName: 'Data',
    field: 'dh_insert',
    headerTooltip: 'Data/Hora',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    valueFormatter: (params) => dateOrTimeFormatter(params.value),
    filterParams: {
      ...deepClone(FILTER_OPTIONS_ORDER_GRID.DATE),
      filterDefinitions: {
        ...deepClone(FILTER_OPTIONS_ORDER_GRID.DATE.filterDefinitions),
        replaceField: 'dh_insert',
        sortBy: 'desc',
      },
    },
    filter: GenericFilterComponent,
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Data/Hora' },
  },
  {
    headerName: 'Ativo',
    field: 'cd_stock',
    headerTooltip: 'Ativo',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    filter: GenericFilterComponent,
    filterParams: deepClone(FILTER_OPTIONS_ORDER_GRID.TEXT),
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Ativo' },
    cellClassRules: {
      'text-brand-primary': (params) => params.data.isSelected,
    },
  },
  {
    headerName: 'Preço',
    field: 'vl_current_price',
    headerTooltip: 'Preço do ativo na criação do alerta',
    sortingOrder: ['asc', 'desc', null],
    type: 'numericColumn',
    headerClass: 'ag-right-aligned-header',
    sortable: true,
    valueFormatter: (params) =>
      execFormatFinancial(params.data, params.data.vl_current_price),
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Preço do ativo na criação do alerta' },
  },
  {
    headerName: 'Alerta',
    field: 'vl_value',
    headerTooltip: 'Preço de disparo do alerta',
    sortingOrder: ['asc', 'desc', null],
    type: 'numericColumn',
    headerClass: 'ag-right-aligned-header',
    sortable: true,
    valueFormatter: (params) =>
      execFormatFinancial(params.data, params.data.vl_value),
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Preço de disparo do alerta' },
  },
  {
    headerName: 'Situação',
    field: 'alert_status',
    headerTooltip: 'Situação',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    cellRenderer: (params: any) => {
      return `
        <span> ${
          params.data.cd_status === ALERT_STATUS_ENUM.ACTIVE
            ? 'Ativo'
            : 'Concluído'
        } </span>
        <span class="border border-thin round material-icons-round icon-size-micro position-absolute right-0 ag-grid-custom-dropdown-btn">arrow_drop_down</span>
      `;
    },
    cellStyle: (params) => getAlertBackgroundColor(params.data.cd_status),
    cellEditor: AlertSettingsComponent,
    cellEditorPopup: true,
    editable: true,
    filter: GenericFilterComponent,
    filterParams: {
      ...deepClone(FILTER_OPTIONS_ORDER_GRID.TEXT),
      filterDefinitions: {
        ...deepClone(FILTER_OPTIONS_ORDER_GRID.TEXT.filterDefinitions),
        replaceField: 'ds_status_custom',
      },
    },
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Situação' },
  },
];
