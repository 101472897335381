<a
  [ngClass]="{ 'text-brand-primary': useDayTradeMode }"
  [tooltip]="withTooltip ? tooltip : ''"
  (click)="toggleDayTradeMode(!useDayTradeMode)"
  [style.min-width.px]="73"
  [class]="
    'daytradeSelector nav-link cursor-pointer round-top d-inline-block text-truncate user-select-none d-inline-flex align-items-center px-2 justify-content-center ' +
    ' ' +
    css
  "
>
  {{ custodyModeLabel }}
</a>
