import {
  TTigerChartIndicatorCreateOptions,
  TTigerChartIndicatorMovingAverageOptions,
} from './indicators.types';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { TIGER_INDICATORS_ENUM } from '../enum/tiger-chart.enum';
import { TigerChartIndicatorMovingAverageBase } from './base/moving-average-base';

export class TigerChartIndicatorExponentialMovingAverage extends TigerChartIndicatorMovingAverageBase {
  constructor(options: TTigerChartIndicatorMovingAverageOptions) {
    super(options, TIGER_INDICATORS_ENUM.EXPONENTIAL_MOVING_AVERAGE);
  }

  override create(
    options: TTigerChartIndicatorCreateOptions
  ): BaseRenderableSeries[] {
    this.updatePoints();
    return super.create(options);
  }

  override updatePoints(): void {
    const offset: number[] = Array(+this.timePeriod).fill(
      this.data[this.source][0]
    );
    this.points = this.service.call('EMA', {
      inReal: [...offset, ...this.data[this.source]],
      timePeriod: this.timePeriod,
    });
  }

  override updateSettings() {
    const offset: number[] = Array(+this.timePeriod).fill(
      this.data[this.source][0]
    );
    this.points = this.service.call('EMA', {
      inReal: [...offset, ...this.data[this.source]],
      timePeriod: +this.timePeriod,
    });
    super.updateSettings();
  }
}
