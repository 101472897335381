import { Inject, Injectable } from '@angular/core';
import { WORKER_FACTORY_TOKEN } from './shared-worker-factory';
import { emitToDesktop } from 'src/app/desktop/integration.utils';

export type RocketStreamRead = {
  stream: ReadableStream;
  snapshot: (items: any) => void;
  close: () => void;
  key: string;
  reader?: ReadableStreamDefaultReader;
  controller?: ReadableStreamDefaultController;
};

export type StreamReadResponse = Promise<RocketStreamRead>;

@Injectable({
  providedIn: 'root',
})
export class RxEvent {
  private port!: MessagePort;

  constructor(
    @Inject(WORKER_FACTORY_TOKEN) private workerFactory: () => SharedWorker
  ) {
    const worker = this.workerFactory();
    this.port = worker.port;
  }

  createSharedWorker(
    type: string,
    name: string,
    data: any = {}
  ): StreamReadResponse {
    return new Promise((resolve) => {
      const worker = this.workerFactory();
      worker.port.postMessage({
        type,
        name,
        data,
      });
      worker.port.onmessage = (event) => {
        resolve({
          key: event.data.key,
          stream: event.data.stream,
          snapshot: (items: any) => {
            if (!items) return;
            worker.port.postMessage({
              type: 'snapshot',
              name: name,
              key: event.data.key,
              data: {
                itemsArray: items,
              },
            });
          },
          close: () => {
            worker.port.postMessage({
              type: 'unsubscribe',
              name: event.data.key,
              data: {
                channel: name,
              },
            });
            worker.port.close();
          },
        });
      };
    });
  }

  subscribe(channel: string, handler: any) {
    // return this.createSharedWorker('subscribe', channel);
    console.log(
      `Use o [read] adapte o channel [${channel}] seu handler`,
      typeof handler
    );
  }

  read(channel: string): StreamReadResponse {
    return this.createSharedWorker('read', channel);
  }

  connect(params: any) {
    return this.createSharedWorker('connect', 'SSE', params);
  }

  disconnect(reason?: string) {
    this.port.postMessage({ type: 'disconnect', data: { reason } });
  }

  reset() {
    this.port.postMessage({ type: 'reset' });
  }

  unsubscribe(name: string) {
    this.port.postMessage({ type: 'unsubscribe', name });
  }

  emit(name: string, data: any) {
    this.port.postMessage({ type: 'emit', name, data });
    emitToDesktop(name, data);
  }

  emitCommand(command: string, name: string, data: any) {
    this.port.postMessage({ type: command, name, data });
    emitToDesktop(name, data);
  }
}
