<div
  class="round bg-neutral-strong position-absolute fs-7"
  [style.width]="'210px'"
  [style.padding]="'10px'"
  [style.top.px]="position.top"
  [style.left.px]="position.left"
  [style.z-index]="9999"
  [style.display]="isTour ? 'none' : 'block'"
  (mouseleave)="onFocusOut()"
>
  <div class="list-group list-group-flush">
    <div class="header-context-menu">
      <span>{{ title }}</span>
    </div>

    <ng-container *ngFor="let buttonInfo of configActions">
      <ng-container *ngIf="buttonInfo.isShow">
        <button
          type="button"
          [class]="buttonInfo.class"
          (click)="buttonInfo.handler(buttonInfo.enum)"
          [disabled]="buttonInfo.disabled"
        >
          {{ buttonInfo.label }}
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            [name]="buttonInfo.icon"
          ></fla-icon>
        </button>

        <hr *ngIf="buttonInfo.bottomDivider" class="separator-context-menu" />
      </ng-container>
    </ng-container>
  </div>
</div>
