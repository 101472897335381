<div
  #stockTableViews
  class="h-100 w-100"
  [style.--table-view-width]="contentSizes.width"
  [style.--table-view-height]="contentSizes.height"
>
  <ng-container *ngIf="stocksDict.size(); else withoutStocks">
    <cdk-virtual-scroll-viewport
      [itemSize]="cardHeight"
      [style.height.px]="componentSize.height"
      appendOnly
      class="px-1 py-1 h-100"
    >
      <div
        #stockTableElement
        cdkDropListGroup
        class="py-2 px-1 h-100 view-container"
        (keydown)="preventScroll($event)"
        (keyup)="preventScroll($event)"
      >
        <div
          appKeyboardControls
          *cdkVirtualFor="let stock of stocksDict.values(); let i = index"
          [id]="stock.ref"
          [attr.tabindex]="i"
        >
          <div
            cdkDropList
            cdkDropListOrientation="horizontal"
            [cdkDropListData]="{ item: stock, index: i }"
            (cdkDropListDropped)="drop($event)"
            [ngClass]="{
              'border border-brand-primary round border-thin':
                stock.ref === elementFocused
            }"
          >
            <div cdkDrag [cdkDragDisabled]="isNotListPersonal">
              <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
              <ng-container *ngIf="tableView === TABLE_VIEW.MINICARD">
                <app-stock-table-view-minicard
                  [name]="stock.cd_stock"
                  [cdStock]="stock.cd_stock"
                  [companyName]="stock.nm_company"
                  [variation]="stock.custom_variation"
                  [variationBgColor]="stock.arrow_hex"
                  [variationTextColor]="stock.arrow_font_hex"
                  [globalStock]="globalStockCode"
                  [globalStockSelected]="globalStockSelected"
                  [auctionInfos]="stockAuctionInfos[stock.item]"
                  [displayRemoveStock]="displayRemoveIcon"
                  [componentId]="componentId"
                  [situation]="stock.situacao"
                  (stockSelected)="updateGlobalStock(stock)"
                  (removeStock)="removeStockHandler(stock)"
                  (mouseenter)="updateFocusedStock(stock, i)"
                  (mouseleave)="updateFocusedStock(null)"
                ></app-stock-table-view-minicard>
              </ng-container>

              <ng-container
                *ngIf="
                  tableView === TABLE_VIEW.CARD ||
                  tableView === TABLE_VIEW.CHART
                "
              >
                <app-stock-table-view-card-and-chart
                  [tableView]="tableView"
                  [name]="stock.cd_stock"
                  [cdStock]="stock.cd_stock"
                  [image]="stock.company_logo"
                  [dsAsset]="stock.ds_asset"
                  [companyName]="stock.nm_company"
                  [vlCloseHistory]="stock.vl_close_history"
                  [lastPrice]="stock.preco_ultimo"
                  [variation]="stock.custom_variation"
                  [variationBgColor]="stock.arrow_hex"
                  [variationTextColor]="stock.arrow_font_hex"
                  [tickSize]="stock.tick_size_denominator"
                  [globalStock]="globalStockCode"
                  [globalStockSelected]="globalStockSelected"
                  [displayRemoveStock]="displayRemoveIcon"
                  [auctionInfos]="stockAuctionInfos[stock.item]"
                  [componentId]="componentId"
                  [situation]="stock.situacao"
                  (stockSelected)="updateGlobalStock(stock)"
                  (removeStock)="removeStockHandler(stock)"
                  (mouseenter)="updateFocusedStock(stock, i)"
                  (mouseleave)="updateFocusedStock(null)"
                >
                </app-stock-table-view-card-and-chart>
              </ng-container>

              <ng-container *ngIf="tableView === TABLE_VIEW.CANDLE">
                <app-stock-table-view-candle-horizontal
                  [cdStock]="stock.cd_stock"
                  [companyName]="stock.nm_company"
                  [price]="stock.vl_close"
                  [avgPrice]="stock.avg_price"
                  [tickSize]="stock.tick_size_denominator"
                  [variation]="stock.custom_variation"
                  [vlOpen]="stock.vl_open"
                  [vlClose]="stock.vl_close"
                  [vlMin]="stock.vl_low"
                  [vlMax]="stock.vl_high"
                  [variationBgColor]="stock.arrow_hex"
                  [variationTextColor]="stock.arrow_font_hex"
                  [situation]="stockSituation[stock.idRow] ?? stock.situacao"
                  [period]="stock.period"
                  [enableSendOrder]="!stock.isIndex"
                  [globalStock]="globalStockCode"
                  [displayRemoveStock]="displayRemoveIcon"
                  [auctionInfos]="stockAuctionInfos[stock.iosma_key]"
                  [componentId]="componentId"
                  [standardLot]="standardLot[stock.idRow]"
                  [lotInitialValue]="stock.lotInitialValue"
                  [stockToEnableArrowEvent]="stockToEnableArrowEvents"
                  [dsAsset]="stock.ds_asset"
                  (stockSelected)="updateGlobalStock(stock)"
                  (sendOrderEvent)="sendOrder($event, stock)"
                  (removeStock)="removeStockHandler(stock)"
                  (mouseenter)="updateFocusedStock(stock, i)"
                  (mouseleave)="updateFocusedStock(null)"
                  (stockQtty)="updateStockQttyPreference($event, stock)"
                  (blockKeyboardEvent)="blockKeyboardEvents($event)"
                >
                </app-stock-table-view-candle-horizontal>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
  </ng-container>
</div>

<ng-template #withoutStocks>
  <span
    class="ag-theme-alpine-dark w-100 position-absolute d-block text-center center-empty-message"
  >
    {{ noDataTemplate }}
  </span>
</ng-template>
