import { ApiError } from './api-error';

export class ApiResponse<T> {
  constructor() {
    this.errors = [];
    this.error = '';
    this.code = '';
    this.errorCode = '';
  }
  data!: T;
  errors: ApiError[];
  error: string;
  code: string;
  errorCode: string;
  getErrorsText(): string {
    return this.errors.map((e) => e.text).join(' ');
  }
  hasErrors(): boolean {
    return this.errors.length > 0;
  }
}
