import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RTDropdownComponent } from './dropdown.component';
import { RTDropdownDirective } from './directive/dropdown.directive';

@NgModule({
  declarations: [RTDropdownComponent, RTDropdownDirective],
  imports: [CommonModule],
  exports: [RTDropdownComponent, RTDropdownDirective],
  providers: [],
})
export class RTDropdownModule {}
