import { Injectable } from '@angular/core';
import { RocketModalService } from '@shared/rocket-components/components';
import { ModalAvisoComponent } from '@shared/components/modal-aviso/modal-aviso.component';
import { IModalInfosParams } from '@shared/components/modal-aviso/interface/modal-aviso.interface';

@Injectable({
  providedIn: 'root',
})
export class WarningService {
  constructor(private _rocketModalService: RocketModalService) {}

  public openWarningModal(data: IModalInfosParams) {
    const ref = this._rocketModalService.open(ModalAvisoComponent, { data });
    return ref.afterDismissed;
  }
}
