<app-news-header
  #header
  [width]="width"
  [refComponent]="refComponent"
  [linked]="linked"
  [newsGroups]="newsGroups"
  [selectedTab]="newsConfiguration.tab"
  [component]="component"
  (tabChange)="onTabChange($event)"
></app-news-header>
<div
  #newsWrapper
  class="news-wrapper position-relative w-100 bg-neutral-stronger"
  [ngClass]="isDesktop ? 'component-height' : 'h-100'"
  [style.height.px]="wrapperHeight"
  (scroll)="onListScroll($event)"
>
  <app-news-search
    *ngIf="!loading && !selectedFooterTag"
    [searchText]="searchText"
    [newsGroup]="selectedNewsGroup"
    [selectedTagId]="newsConfiguration.tag"
    (tagSelected)="onTagSelected($event)"
    (search)="onTextSearch($event)"
  ></app-news-search>

  <app-loading *ngIf="loading; else newsList"></app-loading>

  <ng-template #newsList>
    <div
      class="w-100 h-100"
      *ngIf="loadingNews || (!selectedNews && news.length); else newsText"
    >
      <div class="p-2 w-100 d-flex" *ngIf="selectedFooterTag">
        <rocket-button
          type="icon"
          css="min-w-0 pr-0"
          (rocketClick)="onTagGoBack()"
          [tooltip]="'Voltar'"
          tooltipPosition="bottom"
        >
          <fla-icon
            name="arrow_back_ios"
            size="icon-size-micro"
            css="text-brand-primary p-0 pl-1"
          ></fla-icon>
        </rocket-button>
        <div class="card-list-size h-100 text-center">
          {{ selectedFooterTag.ds_agency_tag }}
        </div>
      </div>
      <div class="w-100 h-100">
        <app-news-card
          *ngFor="let newsItem of news"
          [news]="newsItem"
          [selectedTag]="selectedFooterTag?.id_agency_tag || null"
          (outputClick)="selectNews($event)"
          (outputTagSelected)="onFooterTagSelected($event)"
        ></app-news-card>
        <div
          class="w-100"
          [ngClass]="news.length === 0 ? 'h-100' : 'news-loading'"
          *ngIf="loadingNews"
        >
          <app-loading></app-loading>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #newsText>
    <news-text
      *ngIf="selectedNews; else noNews"
      [news]="selectedNews"
      [height]="height"
      [selectedNewsIndex]="selectedNewsIndex"
      [newsListLength]="news.length"
      (onClick)="selectNews(null)"
      (onNextNews)="selectNextNews()"
      (onPreviousNews)="selectPreviousNews()"
    ></news-text>
  </ng-template>

  <ng-template #noNews>
    <p class="fs-6 p-2">Sem notícias para mostrar!</p>
  </ng-template>
</div>
