import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { ITableBookContextMenu } from '../../interface/table-book.interface';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';
import { TABLE_BOOK_CONTEXT_MENU_ENUM } from '../../enum/table-book.enum';
import { BaseContextMenuComponent } from '@shared/rocket-components/base-context-menu';

@Component({
  selector: 'app-table-book-context-menu',
  templateUrl: './table-book-context-menu.component.html',
  styleUrls: ['./table-book-context-menu.component.scss'],
})
export class TabelBookContextMenuComponent extends BaseContextMenuComponent {
  items: ITableBookContextMenu[] = [];
  isTour: boolean = false;
  parent_id!: string;
  payload: ITableBookContextMenu[] = [];

  constructor(
    elementRef: ElementRef,
    cdr: ChangeDetectorRef,
    private contextMenuService: ContextMenuService
  ) {
    super(elementRef, cdr);
  }

  static openContextMenu(
    contextMenuService: ContextMenuService,
    parent_id: string,
    event: { clientX: number; clientY: number },
    isTour: boolean,
    items: ITableBookContextMenu[]
  ) {
    contextMenuService.openContextMenu(parent_id, {
      type: 'TabelBookContextMenuComponent',
      event: event,
      configurationData: {
        isTour,
        items,
      },
    });
  }

  private emitEvent(id: TABLE_BOOK_CONTEXT_MENU_ENUM | 'TOUR') {
    this.contextMenuService.emitEvent(this.parent_id, id);
    if (id !== 'TOUR') this._verifyIsBookType(id);
  }

  onClick(item: ITableBookContextMenu) {
    if (!item) return;
    item.enable = !item.enable;
    this.emitEvent(item.id);
  }

  onFocusOut() {
    if (this.isTour) return;
    this.contextMenuService.closeContextMenu(this.parent_id);
  }

  onTour() {
    this.emitEvent('TOUR');
    this.onFocusOut();
  }

  private _verifyIsBookType(id: TABLE_BOOK_CONTEXT_MENU_ENUM): void {
    if (
      id !== TABLE_BOOK_CONTEXT_MENU_ENUM.OFFER &&
      id !== TABLE_BOOK_CONTEXT_MENU_ENUM.PRICE
    )
      return;
    const itemIndex = TABLE_BOOK_CONTEXT_MENU_ENUM.OFFER === id ? 1 : 0;
    const otherModeIndex = TABLE_BOOK_CONTEXT_MENU_ENUM.OFFER === id ? 0 : 1;
    this.items[otherModeIndex].enable = !this.items[itemIndex].enable;
  }
}
