import {
  STOCK_CHART_COMPONENT,
  STOCK_TABLE_COMPONENT,
} from '@core/workspace/constants';
import { LayoutBase } from '../base';
import { BUSINESS_PROFILE_COMPONENT } from '@core/workspace/constants/components/business-profile';

export class RaioX extends LayoutBase {
  override name = 'Raio X';
  override components = [
    STOCK_TABLE_COMPONENT({
      height: 49,
      width: 43,
    }),
    STOCK_CHART_COMPONENT({
      height: 50,
      width: 43,
      top: 'calc(49% + 5px)',
      left: '0px'
    }),
    BUSINESS_PROFILE_COMPONENT({
      unit: '%',
      height: 100,
      width: 56,
      left: 'calc(43% + 5px)'
    })
  ];
}
