import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
} from '@angular/core';
import { GRID_CONFIG_POSITION_ENUM } from '@core/interface';
import { GridISelectRow } from '@shared/rocket-grid';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';
import { GridConfigOptions } from '@core/models/grid-config-options.model';
import { OrdersHistoryContextMenuEvent } from '../../service/orders-history-config.service';
import { BaseContextMenuComponent } from '@shared/rocket-components/base-context-menu';

@Component({
  selector: 'app-position-tab-context-menu',
  templateUrl: './position-tab-context-menu.component.html',
})
export class PositionTabContextMenuComponent
  extends BaseContextMenuComponent
  implements OnInit
{
  refComponent!: string;
  componentId!: string;
  isTour: boolean = false;
  isVisible: boolean = false;
  isRowSelect: boolean = false;
  filterIsActive: boolean = false;
  isSorted: boolean = false;
  disabledReset: boolean = true;
  showTotalRow: boolean = true;
  title: string = 'Posições';
  keyHeader!: string | null;
  rowSelect!: GridISelectRow | null;
  configActions!: any;
  parent_id!: string;
  isCardView: boolean = false;

  constructor(
    elementRef: ElementRef,
    cdr: ChangeDetectorRef,
    private contextMenuService: ContextMenuService
  ) {
    super(elementRef, cdr);
  }

  ngOnInit() {
    this.configActions = new GridConfigOptions(
      this.handleConfigAction,
      !this.keyHeader,
      !this.filterIsActive,
      this.disabledReset,
      this.showTotalRow,
      this.isSorted,
      this.isCardView
    );
  }

  static openContextMenu(
    contextMenuService: ContextMenuService,
    parent_id: string,
    event: { clientX: number; clientY: number },
    isTour: boolean,
    isVisible: boolean,
    isRowSelect: boolean,
    filterIsActive: boolean,
    isSorted: boolean,
    keyHeader: string,
    disabledReset: boolean,
    showTotalRow: boolean,
    isCardView: boolean
  ) {
    if (isTour) {
      const domRect = document
        .getElementsByTagName('app-orders-history')[0]
        .getClientRects()[0];
      event.clientX = domRect.x;
      event.clientY = domRect.y + 100;
    }
    contextMenuService.openContextMenu(parent_id, {
      type: 'PositionTabContextMenuComponent',
      event: event,
      configurationData: {
        isTour,
        isVisible,
        isRowSelect,
        filterIsActive,
        isSorted,
        keyHeader,
        disabledReset,
        showTotalRow,
        isCardView
      },
    });
  }

  private emitEvent(action: OrdersHistoryContextMenuEvent) {
    this.contextMenuService.emitEvent(this.parent_id, action);
    this.onFocusOut();
  }

  onFocusOut() {
    this.contextMenuService.closeContextMenu(this.parent_id);
  }

  handleConfigAction = (actionID: GRID_CONFIG_POSITION_ENUM): void => {
    switch (actionID) {
      case GRID_CONFIG_POSITION_ENUM.HIDDEN_COLUMN:
        this.emitEvent(OrdersHistoryContextMenuEvent.SetInvisibleColumn);
        break;
      case GRID_CONFIG_POSITION_ENUM.TOGGLE_TOTAL_LINE:
        this.emitEvent(OrdersHistoryContextMenuEvent.ToggleTotalLine);
        break;
      case GRID_CONFIG_POSITION_ENUM.CLEAR_FILTERS:
        this.emitEvent(OrdersHistoryContextMenuEvent.ResetFilter);
        break;
      case GRID_CONFIG_POSITION_ENUM.CLEAR_SORT:
        this.emitEvent(OrdersHistoryContextMenuEvent.RestartSort);
        break;
      case GRID_CONFIG_POSITION_ENUM.RESET:
        this.emitEvent(OrdersHistoryContextMenuEvent.ResetDefault);
        break;
      case GRID_CONFIG_POSITION_ENUM.PREFERENCES:
        this.emitEvent(OrdersHistoryContextMenuEvent.OpenModalPreferences);
        break;
      case GRID_CONFIG_POSITION_ENUM.HELP:
        this.emitEvent(OrdersHistoryContextMenuEvent.TourHandler);
        break;
      case GRID_CONFIG_POSITION_ENUM.EXPORT_DATA:
        this.emitEvent(OrdersHistoryContextMenuEvent.ExportData);
        break;
      default:
        break;
    }
  };
}
