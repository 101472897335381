export const ONBOARD_STEPS = {
  INITIAL: 'INITIAL',
  VALIDATED: 'VALIDATED',
  COMPLETED: 'COMPLETED',
  UNDEFINED: 'UNDEFINED',
};

export const ONBOARD_STORAGE = {
  ONBOARD_STATUS: 'onboardStatus',
  ACCEPT_TERM: 'accept_term',
  PHONE_NUMBER: 'phone_number',
  VL_COUNTRY_CODE: 'vl_country_code',
  VL_BIRTHDATE: 'vl_birthdate',
  CNPJ_CPF: 'cnpj_cpf',
  CD_ACCESS_TOKEN: 'cd_access_token',
  EMAIL: 'email',
  NAME: 'name',
  PASSWORD: 'password',
  PINCODE: 'pincode',
};

export const ONBOARD_STEPS_MESSAGE = (step?: string | null) => {
  let message: string | null = null;
  switch (step) {
    case ONBOARD_STEPS.COMPLETED:
      message = 'Ok, pode fazer login com sua nova conta';
      break;
  }
  return message;
};
