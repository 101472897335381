import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HomeHelperService {
  componentsNoOverlapping(components: any) {
    return components.map((component: any) => {
      return component;
    });
  }
}
