import { FormGroup } from '@angular/forms';
import {
  TYPE_ORDE_ENUM,
  TYPE_VALIDADE_ENUM,
} from '../../enum/stock-trade.enum';
import { formatDatePtBr } from 'src/app/utils/utils.framework';
import { WorkSpaceConfigs } from '@core/workspace/config/workspace.configs';
import { OrsGatewayServiceV2 } from '@shared/services/api/trademap/V2/ors-gateway.service';
import { StockTradeService } from '../../service/stock-trade.service';
import { ProcessModificationOrderModel } from 'src/app/core/models/process-modification-order.model';
import {
  IAccountSelect,
  IOrderModification,
  IOrders,
  ISearchStock,
} from 'src/app/core/interface';
import { editOrderTypeAuction } from 'src/app/utils/utils.functions';
import { ISendOrder } from './send-order.interface';
import { Injectable } from '@angular/core';
@Injectable()
export class SendOrder {
  constructor(
    private workSpaceConfigs: WorkSpaceConfigs,
    private _orsGatewayServiceV2: OrsGatewayServiceV2,
    private stockTradeService: StockTradeService
  ) {}

  public createdOrderByForm(
    form: FormGroup,
    stock: ISearchStock,
    account: IAccountSelect,
    refComponent?: string
  ): void {
    const order: any = {
      ord_type: +form.value.orderType,
      order_qty: form.value.qtde,
      validade: form.value.typeValidade,
      date: formatDatePtBr(form.value.dueDate),
      price: form.value.price,
      orderSide: form.value.orderSide,
      stopPx: form.value.stopPx,
    };
    if (form.value?.orderOco) {
      order.gainPrice = form.value.gainPrice.toString();
      order.lossPrice = form.value.lossPrice.toString();
      /*if (isOrderBuy(form.value.orderSide)) {
        order.gainPrice = form.value.gainPrice.toString();
        order.lossPrice = form.value.lossPrice.toString();
      } else {
        order.gainPrice = form.value.lossPrice.toString();
        order.lossPrice = form.value.gainPrice.toString();
      }*/
    }
    if (form.value?.orderDouble) {
      order.gainOrderPrice = form.value.gainPrice;
      order.gainTriggerPrice = form.value.gainPriceTrigger;
      order.lossOrderPrice = form.value.lossPrice;
      order.lossTriggerPrice = form.value.lossPriceTrigger;
      /*if (isOrderBuy(form.value.orderSide)) {
        order.gainOrderPrice = form.value.lossPrice;
        order.gainTriggerPrice = form.value.lossPriceTrigger;
        order.lossOrderPrice = form.value.gainPrice;
        order.lossTriggerPrice = form.value.gainPriceTrigger;
      } else {
        order.gainOrderPrice = form.value.gainPrice;
        order.gainTriggerPrice = form.value.gainPriceTrigger;
        order.lossOrderPrice = form.value.lossPrice;
        order.lossTriggerPrice = form.value.lossPriceTrigger;
      }*/
    }

    this.stockTradeService.creatingAndSendingOrder(
      stock,
      order,
      account,
      form.value.orderSide,
      refComponent
    );
  }

  public createdOrder(
    sendOrder: ISendOrder,
    stock: ISearchStock,
    account: IAccountSelect
  ): void {
    const order: any = {
      ord_type: +sendOrder.orderType,
      order_qty: sendOrder.qtde,
      validade: sendOrder.typeValidade,
      date: formatDatePtBr(sendOrder.dueDate),
      price: sendOrder.price,
      orderSide: sendOrder.orderSide,
    };
    /*if (sendOrder.orderOco) {
      if (isOrderBuy(sendOrder.orderSide)) {
        order.gainPrice = sendOrder.gainPrice.toString();
        order.lossPrice = sendOrder.lossPrice.toString();
      } else {
        order.gainPrice = sendOrder.lossPrice.toString();
        order.lossPrice = sendOrder.gainPrice.toString();
      }
    }
    if (sendOrder.orderDouble) {
      if (isOrderBuy(sendOrder.orderSide)) {
        order.gainOrderPrice = sendOrderlossPrice;
        order.gainTriggerPrice = sendOrderlossPriceTrigger;
        order.lossOrderPrice = sendOrder.gainPrice;
        order.lossTriggerPrice =sendOrder.gainPriceTrigger;
      } else {
        order.gainOrderPrice = sendOrder.gainPrice;
        order.gainTriggerPrice = sendOrder.gainPriceTrigger;
        order.lossOrderPrice = sendOrder.lossPrice;
        order.lossTriggerPrice = sendOrder.lossPriceTrigger;
      }
    }*/
    this.stockTradeService.creatingAndSendingOrder(
      stock,
      order,
      account,
      sendOrder.orderSide
    );
  }

  public modificationOrder = (
    form: FormGroup,
    orderEdit: IOrders,
    componentId: string
  ): void => {
    const order: IOrderModification = new ProcessModificationOrderModel(
      orderEdit,
      this.stockTradeService.getConfigService().invertStopBroker(orderEdit.side)
    );
    order.time_in_force = form.value.typeValidade || order.time_in_force;
    order.order_qty = form.value.qtde;
    order.price = form.value.price;
    editOrderTypeAuction(orderEdit, order);
    if (form.value.orderType == TYPE_ORDE_ENUM.MARKET) {
      order.price = null;
      order.tipo = 'Mercado';
    } else if (form.value.orderType == TYPE_ORDE_ENUM.START_STOP) {
      order.stopPx = form.value.stopPx;
      order.tipo = 'start/stop';
    } else if (form.value.orderType == TYPE_ORDE_ENUM.DOUBLE_START_STOP) {
      //if (isOrderBuy(form.value.orderSide)) {
      //  order.gainOrderPrice = form.value.lossPrice;
      //  order.gainTriggerPrice = form.value.lossPriceTrigger;
      //  order.lossOrderPrice = form.value.gainPrice;
      //  order.lossTriggerPrice = form.value.gainPriceTrigger;
      //} else {
      order.gainOrderPrice = form.value.gainPrice;
      order.gainTriggerPrice = form.value.gainPriceTrigger;
      order.lossOrderPrice = form.value.lossPrice;
      order.lossTriggerPrice = form.value.lossPriceTrigger;
      //}
      order.tipo = 'Start Duplo';
    }
    let dateSplit;
    switch (form.value.typeValidade) {
      case TYPE_VALIDADE_ENUM.TODAY:
        order.validade = '0';
        break;
      case TYPE_VALIDADE_ENUM.UNTIL_THE_DAY:
        order.validade = formatDatePtBr(form.value.dueDate)!;
        dateSplit = order.validade.split('/');
        order.expirate_date = `${dateSplit[2]}${dateSplit[1]}${dateSplit[0]}`;
        break;
      case TYPE_VALIDADE_ENUM.VALID_UNTIL_CANCEL:
        order.validade = 'Até cancelar';
        break;
      case TYPE_VALIDADE_ENUM.EXECUTE_OR_CANCEL:
        order.validade = 'Imediata ou cancela';
        break;
    }
    this._orsGatewayServiceV2.orderModificationRequest(order).subscribe();
    this.workSpaceConfigs.removeComponent(componentId);
  };
}
