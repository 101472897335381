import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-stock-info',
  templateUrl: './stock-info.component.html',
  styleUrls: ['./stock-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockInfoComponent implements OnChanges {
  @Input() companyInfo!: any;
  cards = [
    { title: 'Data IPO', field: 'dtIPO', value: undefined },
    { title: 'Preço IPO', field: 'priceIPO', value: undefined },
    { title: 'Acionistas', field: 'shareholders', value: undefined },
    { title: 'Listagem', field: 'listing', value: undefined },
    { title: 'Tag Along', field: 'tagLong', value: undefined },
    { title: 'Free Float', field: 'freeFloat', value: undefined },
  ];

  ngOnChanges(changes: SimpleChanges) {
    const { companyInfo } = changes
    if(companyInfo?.currentValue){
      this.cards.map(card => {
        const value = this.companyInfo[card.field].replace('|', '<br>')
        card.value = value
        return card
      })
    }
  }
}
