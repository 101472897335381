<div>
  <div class="color-picker" *ngIf="showColorPicker">
    <div class="color-picker-body">
      <rt-color-palette
        [hue]="hue"
        (color)="onChangesColor($event)"
        [width]="width"
        [height]="height"
      ></rt-color-palette>
      <rt-color-slider
        [height]="height"
        (color)="onChangesHue($event)"
      ></rt-color-slider>
      <ng-content></ng-content>
    </div>

    <div class="color-picker-input">
      <small *ngIf="!isEditable; else inputColor">{{ color }}</small>
      <a
        *ngIf="!hideTypeButton"
        type="button"
        class="color-picker-btn"
        (click)="toggleType()"
        >{{ btnName }}</a
      >
      <span
        [style.background-color]="color"
        class="color-picker-active-color"
      ></span>
      <a type="button" class="color-picker-btn ok-btn" (click)="toggle()">Ok</a>
    </div>
  </div>
</div>

<ng-template #inputColor>
  <div class="inputWrap">
    <span class="inputHash">#</span>
    <input
      type="text"
      class="input"
      [value]="color ? color.replace('#', '') : ''"
      [readOnly]="type !== 'Hex'"
      (keyup)="updateColorByHex($event)"
      (blur)="verifyIsValidColor($event)"
      minlength="6"
      maxlength="6"
    />
  </div>
</ng-template>
