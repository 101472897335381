import { TIGER_INDICATORS_MENU_ENUM } from '../../enum/tiger-chart.enum';
import MenuItemBase from './menu-item-base';
import MoveToNewPanelAbove from './move-to-new-panel-above';
import MoveToNewPanelBelow from './move-to-new-panel-below';
import MoveToPanelAbove from './move-to-panel-above';
import MoveToPanelBelow from './move-to-panel-below';

export default class MoveTo extends MenuItemBase {
  constructor() {
    super();
    this.label = 'Mover Para';
    this.id = TIGER_INDICATORS_MENU_ENUM.MOVE_TO;
    this.submenu = [
      new MoveToNewPanelAbove(),
      new MoveToPanelAbove(),
      new MoveToPanelBelow(),
      new MoveToNewPanelBelow(),
    ];
  }
}
