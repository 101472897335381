import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InputCodeComponent } from '@shared/rocket-components/components';
import { OnboardService } from '@shared/services/api/trademap/onboard';
import { RECOVERY_STEPS } from '@shared/services/api/trademap/onboard/data/recovery/recovery.configs';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { RecaptchaComponent } from '../recaptcha/recaptcha.component';

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styles: [
    `
      :host(app-validate) {
        ::ng-deep.grecaptcha-badge {
          display: none !important;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidateComponent implements OnInit, AfterViewInit {
  validateForm!: FormGroup;
  isLoading!: any;
  isResendDisabled: boolean = true;
  erroData = {
    hasError: false,
    errorMessage: '',
  };
  @ViewChild('codeInput') codeInput!: InputCodeComponent;
  @ViewChild('recaptcha') appRecaptcha!: RecaptchaComponent;
  phoneNumber: string = '';
  _params: any = {};
  set params(data: any) {
    this._params = data;
  }

  get params() {
    return this._params;
  }
  isRecoveryProcess!: boolean;
  isSmsRecovery!: boolean;
  public disableSendPincodeBtn: boolean = false;
  public resendTimeout: number = 30;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private onboardService: OnboardService,
    private cdr: ChangeDetectorRef
  ) {
    this.validateForm = this.fb.group({
      phone: ['', Validators.required],
      document: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.isRecoveryProcess = this.onboardService.isRecoveryProcess();
    this.isSmsRecovery = this.onboardService.isSmsRecovery();
    this.phoneNumber = this.getValueToDisplay();
    this._initialResendTimeout();
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.codeInput.focusOnField(0);
  }

  private _initialResendTimeout(): void {
    const time = this.onboardService.createAccountPincodeTimeout;
    if (time && time > this.resendTimeout) {
      this.resendTimeout = time;
      this.cdr.detectChanges();
      return;
    }
  }

  getValueToDisplay(): string {
    const country = this.onboardService?.phoneCountryInfos.country;
    if (!this.isRecoveryProcess) {
      const phone = this.isRecoveryProcess
        ? this.onboardService.recoveryData.recoveryInfo
        : this.onboardService?.phoneNumber;
      return this._buildPhoneToValidate(phone, country);
    }
    const recovery = this.onboardService.getRecoveryInfoValidate();
    if (!recovery.isMobile) return recovery.data;
    return this._buildPhoneToValidate(recovery.data, country);
  }

  private _buildPhoneToValidate(phone: string, country: string): string {
    if (country === 'br') return `(**) *****-${phone?.slice(-4)}`;
    let stars = '';
    for (let index = 0; index < phone.length - 4; index++) stars += '*';
    return `${stars}${phone?.slice(-4)}`;
  }

  reSendSMS() {
    this.erroData.hasError = false;
    if (!this.isRecoveryProcess) {
      this.disableSendPincodeBtn = true;
      this.onboardService.onResendPhoneConfirmation().subscribe((res) => {
        if (res.success) {
          this._handlerResendPincodeTimeout(
            true,
            res.result.validation.seconds
          );
          return;
        }
        this._displayErrorOnSendPincode(res.error!);
      });
    } else this.resendCode();
  }

  reSendWhatsapp() {
    this.erroData.hasError = false;
    if (!this.isRecoveryProcess) {
      this.disableSendPincodeBtn = true;
      this.onboardService.onRegisterCelPhone().subscribe((res) => {
        if (res.success) {
          this._handlerResendPincodeTimeout(true, res.result.next_interation);
          return;
        }
        this._displayErrorOnSendPincode(res.error!);
      });
    } else this.resendCode({ recoveryInfoType: 'whatsapp' });
  }

  onCodeCompleted(code: string) {
    this.isLoading = true;
    this.onboardService.codeValidator(code).subscribe((response: any) => {
      this.isLoading = false;
      if (response.isError) {
        this.erroData.errorMessage = response.message;
        this.erroData.hasError = true;
        this.codeInput.reset();
        this.cdr.detectChanges();
      } else {
        if (this.isRecoveryProcess) {
          this.onboardService.setRecoveryStatus(RECOVERY_STEPS.VALIDATED);
          this.router.navigate(['../recovery'], { relativeTo: this.route });
        } else {
          this.router.navigate(['../create'], { relativeTo: this.route });
        }
      }
    });
  }

  onTimerCompleted() {
    this.disableSendPincodeBtn = false;
    this.isResendDisabled = false;
  }

  resendCode(data?: { recoveryInfoType: string }) {
    this.disableSendPincodeBtn = true;
    this.params = data;
    this.appRecaptcha.execute();
  }

  onResolved(recaptcha: string) {
    this.onboardService
      .resendCodeRecovery({ ...this.params, recaptcha })
      .subscribe((response) => {
        this.isLoading = false;
        if (!response?.isError) {
          this._handlerResendPincodeTimeout(true, response.seconds);
          return;
        }
        this._displayErrorOnSendPincode(response.message);
      });
  }

  onError() {
    this.erroData.hasError = true;
    this.erroData.errorMessage =
      'Ocorreu um erro ao exibir reCAPTCHA, tente novamente.';
    this.isLoading = false;
    this.disableSendPincodeBtn = false;
    this.cdr.detectChanges();
  }

  private _handlerResendPincodeTimeout(
    disableResendBtn: boolean,
    timeout: number
  ): void {
    this.isResendDisabled = disableResendBtn;
    this.resendTimeout = timeout;
    this.cdr.detectChanges();
  }

  private _displayErrorOnSendPincode(message: string): void {
    const msg = isNullOrUndefined(message)
      ? 'Ocorreu um erro ao enviar o código de verificação, tente novamente.'
      : message;
    this.erroData.errorMessage = msg;
    this.erroData.hasError = true;
    this.disableSendPincodeBtn = false;
    this.isResendDisabled = false;
    this.cdr.detectChanges();
  }
}
