import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TOKEN_AUTHENTICATORS_BY_ID, TTokenAuthenticator } from '../types';
import { TokenAuthenticatorOtherAppsComponent } from './token-authenticator-other-apps/token-authenticator-other-apps.component';
import { ModalTokenService } from '../modal-token.service';

@Component({
  selector: 'app-token-authenticator-instructions',
  templateUrl: './token-authenticator-instructions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TokenAuthenticatorInstructionsComponent {
  @Input() authenticator!: TTokenAuthenticator | null;
  @Output() clearSelectedAuth: EventEmitter<null> = new EventEmitter();
  @Output() closeModal: EventEmitter<null> = new EventEmitter();
  @ViewChild('otherApps') otherApps!: TokenAuthenticatorOtherAppsComponent;

  tokenCode = '';
  configRequestSendStatus = false;
  loading = false;
  buttonDisabled = true;

  @HostListener('document:keydown.enter', ['$event'])
  onKeyEnterPress() {
    if (this.authenticator?.id === 'NITRO' || !this.buttonDisabled)
      this.confirm();
  }

  constructor(
    private _cdr: ChangeDetectorRef,
    private _modalTokenService: ModalTokenService
  ) {}

  emitReturn() {
    if (this.authenticator?.id === 'TRADEMAP') {
      this._modalTokenService.onCloseModal$.next();
      return;
    }
    this.clearSelectedAuth.emit();
  }

  onChangeLoading(loading: boolean) {
    this.loading = loading;
  }

  confirm() {
    if (!this.authenticator) return;
    const { id } = this.authenticator;
    this._modalTokenService.alreadySelectedAuthenticator = null;
    if (id === 'NITRO' || id === 'TRADEMAP') {
      this.authenticator = TOKEN_AUTHENTICATORS_BY_ID.NITRO;
      this._saveAuthenticator();
      this.clearSelectedAuth.emit();
      return;
    }
    this.otherApps.confirm();
  }

  onButtonDisabledChange(buttonDisabled: boolean) {
    this.buttonDisabled = buttonDisabled;
    this._cdr.detectChanges();
  }

  private _saveAuthenticator() {
    if (!this.authenticator) return;
    this._modalTokenService.saveAuthenticator(this.authenticator);
  }
}
