import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { RocketModalService } from '@shared/rocket-components/components';
import { TrademapHighlightsService } from '@shared/services/api/trademap/V9/highlights.service';
import { tap } from 'rxjs';
import { NewsModalComponent } from '../news-modal/news-modal.component';
import { CarouselHelper } from '../../helpers/carousel.helper';

@Component({
  selector: 'app-news-carousel',
  templateUrl: './news-carousel.component.html',
  styleUrls: ['./news-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsCarouselComponent
  extends CarouselHelper
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('carouselItem') _carouselItem!: ElementRef<any>;
  @ViewChild('itemList') _itemList!: ElementRef<HTMLElement>;
  @ViewChild('showcase') showcase!: ElementRef<any>;
  translateX = 0;
  carouselContainerWidth: number = 0;
  elementCarouselWidth: number = 0;
  array: Array<any> = [];
  disablePreviusButton: boolean = true;
  preventClickPreviusButton: boolean = true;
  widthObserver!: ResizeObserver;
  widthComponent: number = 0;

  private autoNavigationInterval: any;

  constructor(
    elRef: ElementRef,
    private modalService: RocketModalService,
    private _trademapHighlightsService: TrademapHighlightsService,
    cdr: ChangeDetectorRef
  ) {
    super(elRef, cdr);
  }

  ngOnInit() {
    this.getHighlights();
  }

  ngAfterViewInit() {
    this.widthObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const cr = entry.contentRect;
        this.widthComponent = cr.width;
        this.onResize();
      }
    });

    this.widthObserver.observe(this.showcase.nativeElement);
  }

  ngOnDestroy() {
    this.widthObserver.disconnect();
    this.widthObserver.unobserve(this.showcase.nativeElement);
    this.autoNavigationInterval && clearInterval(this.autoNavigationInterval);
  }

  @HostListener('window:resize')
  onResize() {
    this.verifyShowArrows();
  }

  override handleNavigation(increase: boolean): void {
    this.autoNavigationInterval && clearInterval(this.autoNavigationInterval);
    super.handleNavigation(increase, this._itemList);
    this.startAutoNavigation();
  }

  private startAutoNavigation() {
    this.autoNavigationInterval && clearInterval(this.autoNavigationInterval);

    this.autoNavigationInterval = setInterval(() => {
      this.handleNavigation(true);
    }, 5000);
  }

  private getHighlights() {
    this._trademapHighlightsService
      .getHighlightCards()
      .pipe(
        tap((data: any) => {
          if (data.length) {
            this.cards = JSON.parse(data[0].cards[0].content);
            this.totalElements = this.cards.length;
          }
        })
      )
      .subscribe(() => {
        this.onResize();
        this.startAutoNavigation();
        this.cdr.detectChanges();
      });
  }

  public openNews(newsletter: any) {
    this.modalService.open(NewsModalComponent, {
      data: {
        event: newsletter,
      },
      centered: true,
      backdrop: true,
      keyboard: false,
      scrollable: false,
    });
  }
}
