export enum ChannelAuthentication {
  DESKTOP = '23',
  WEB = '22',
}

export enum AppStates {
  DEV = 'DESENVOLVIMENTO',
  PROD = 'PRODUCAO',
  HOMOLOG = 'HOMOLOGACAO',
}

export type ConfigEnableType = 'DISABLED' | 'ENABLED';
