import { TvariationRange } from '@shared/services/core/color-range/color-range.interface';
import { ColorRangeServiceService } from '@shared/services/core/color-range/color-range.service';
import {
  DefaultPaletteProvider,
  EStrokePaletteMode,
  TPointMarkerArgb,
  parseColorToUIntArgb,
} from 'scichart';

export default class StockAnalysisPaletteProvider extends DefaultPaletteProvider {
  constructor(
    private _colorRangeService: ColorRangeServiceService,
    private _variation: TvariationRange
  ) {
    super();
    this.strokePaletteMode = EStrokePaletteMode.SOLID;
  }

  override overridePointMarkerArgb(xValue: number): TPointMarkerArgb {
    const color = this._colorRangeService.getColor(xValue, this._variation);
    const fill = parseColorToUIntArgb(color);
    return {
      stroke: 0,
      fill,
    };
  }
}
