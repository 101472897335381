export enum PERIODS_ENUM {
  ONE_DAY = 'one_day',
  ONE_WEEK = 'one_week',
  ONE_MONTH = 'one_month',
  THREE_MONTHS = 'three_months',
  SIX_MONTHS = 'six_months',
  TWELVE_MONTHS = 'twelve_months',
  ONE_YEAR = 'one_year',
  TWO_YEARS = 'two_years',
  FIVE_YEARS = 'five_years',
  TEN_YEARS = 'ten_years',
}
