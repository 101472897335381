import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EnvironmentInjector,
  Injectable,
} from '@angular/core';
import { Observable, take } from 'rxjs';

@Injectable()
export class RtPopupService {
  constructor(
    private injector: EnvironmentInjector,
    private applicationRef: ApplicationRef
  ) {}

  public displayComponent<T = any>(
    component: any,
    params: any = null
  ): Observable<T> {
    const element = document.createElement(
      this._getComponentTag(component.name)
    );
    const elementRef: ComponentRef<any> = createComponent(component, {
      environmentInjector: this.injector,
      hostElement: element,
    });
    elementRef.instance.data = params;
    this.applicationRef.attachView(elementRef.hostView);
    document.body.appendChild(element);

    elementRef.instance.closed.pipe(take(1)).subscribe(() => {
      document.body.removeChild(element);
      this.applicationRef.detachView(elementRef.hostView);
    });
    return elementRef.instance.closed;
  }

  private _getComponentTag(text: string): string {
    const tag = text
      .replace('Component', '')
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .toLowerCase();
    return `app-${tag}`;
  }
}
