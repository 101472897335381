import { randomId } from '@shared/rocket-components/utils';
import { WorkSpaceDocLayout } from '../model';
import { IWorkSpaceComponetList } from 'src/app/core/interface';

import { deepClone } from '@shared/rocket-components/utils/functions';
import { COMPONENTS_NAMES_ENUM } from '../enum';

export function repositionLayout(valuePx: string) {
  const vl = parseInt(valuePx.replace(/px/, ''));
  return `${vl + 60}px`;
}

export const DUPLICATE_COMPONENT_LAYOUT = (component: any) => {
  const componentCopy = deepClone(component);
  const id = randomId(componentCopy.id);
  const layoutCopy: any = componentCopy.metadata.layout;
  const type = componentCopy.type;
  componentCopy.id = id;
  componentCopy.metadata.headerOptions.component = {
    ...componentCopy.metadata.headerOptions.component,
    ...{ data: `${type}_${id}` },
  };
  componentCopy.metadata.layout = {
    ...layoutCopy,
    ...{
      left: repositionLayout(layoutCopy.left),
      top: repositionLayout(layoutCopy.top),
    },
  };
  return componentCopy;
};

export const CREATE_COMPONENT_LAYOUT = (
  workspaceId: string,
  component: IWorkSpaceComponetList,
  stockSelected?: any
) => {
  let metadataComponentOptions = {
    ...component.data,
  };
  if (stockSelected) {
    metadataComponentOptions = {
      ...metadataComponentOptions,
      stock: stockSelected,
    };
  }
  const id = randomId(component.id);
  const doc: IWorkSpaceComponetList | any = {
    id: id,
    workspace_id: workspaceId,
    name: component.name,
    type: component.id,
    visible: true,
    link: component?.link ?? true,
    metadata: {
      component: metadataComponentOptions,
      layout: new WorkSpaceDocLayout(component),
      headerOptions: {
        link: component.link,
        close: component.close,
        fullscreen: component.fullscreen,
        component: {
          data: id,
        },
      },
      footerOptions: {
        hasFooter: component.hasFooter,
      },
    },
  };
  return doc;
};

export const WITHOUT_CONFIG_TOKEN_MSGS = [
  'Conta segura e protegida! Garanta configurando o segundo fato de autenticação 🔑',
  'Configure o seu segundo fator de autenticação e mantenha sua conta mais segura 🔐',
  'Com o segundo fator de autenticação você garante segurança no envio de ordens e mudança de dados pessoais na sua conta. Não esqueça de configurar o seu 😉',
  'Você sabia que o segundo fator de autenticação é uma camada de segurança cada vez mais utilizada para a segurança de sua conta? Não esqueça de configurar o seu 😊',
];

export const AVAIABLE_COMPONENT_ROUTES_MAP: any = {
  [COMPONENTS_NAMES_ENUM.GRAFICO]: 'stockchart',
  [COMPONENTS_NAMES_ENUM.TIMES_TRADES]: 'timestrades',
  [COMPONENTS_NAMES_ENUM.HEATMAP]: 'heatmap',
  [COMPONENTS_NAMES_ENUM.STOCK_ANALYSIS]: 'stockanalysis',
  [COMPONENTS_NAMES_ENUM.NEWS]: 'news',
  [COMPONENTS_NAMES_ENUM.OPTIONS]: 'options',
  [COMPONENTS_NAMES_ENUM.PAPEIS]: 'stocktable',
  [COMPONENTS_NAMES_ENUM.LISTA_ORDENS]: 'ordershistory',
  [COMPONENTS_NAMES_ENUM.BOOK]: 'table-book',
  [COMPONENTS_NAMES_ENUM.SUPER_DOM]: 'superdom',
  [COMPONENTS_NAMES_ENUM.BOLETA]: 'stocktrade',
  [COMPONENTS_NAMES_ENUM.SCREENING]: 'screening',
  [COMPONENTS_NAMES_ENUM.GRAPHIC_SCREENING]: 'graphicscreening',
  [COMPONENTS_NAMES_ENUM.BUSINESS_PROFILE]: 'businessprofile',
  [COMPONENTS_NAMES_ENUM.PERFORMANCE_REPORT]: 'performancereport',
  [COMPONENTS_NAMES_ENUM.AT_PRICE]: 'atprice',
  [COMPONENTS_NAMES_ENUM.RANKING_BROKER]: 'ranking',
  [COMPONENTS_NAMES_ENUM.TRADE_IDEA]: 'tradeidea',
  [COMPONENTS_NAMES_ENUM.RENT_BTC]: 'rentbtc',
  [COMPONENTS_NAMES_ENUM.FLOW_INVESTOR]: 'flowinvestors',
  [COMPONENTS_NAMES_ENUM.MARKET_RESUME]: 'marketsummary',
};
