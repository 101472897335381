import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { IAllStockListSimple, IListStockDB } from '@core/interface';
import {
  DYNAMIC_LISTS,
  DYNAMIC_LIST_BY_ID,
  isMoversList,
} from '@shared/components/stock-table/constants/stock-table.constants';
import { deepClone } from '@shared/rocket-components/utils';
import {
  EVENTS,
  TRIGGERED_THE_EVENT,
} from '@shared/services/core/list-stocks/list-stocks.const';
import { ListStocksService } from '@shared/services/core/list-stocks/list-stocks.service';
import { ProcessListStockService } from '@shared/services/core/stock-list/process-list-stock.service';
import { Subject, Subscription, filter, takeUntil } from 'rxjs';
import { descendingSort } from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-rt-list-selector',
  templateUrl: './list-selector.component.html',
  styleUrls: ['./list-selector.component.scss'],
})
export class RTListSelectorComponent implements AfterViewInit, OnDestroy {
  @Input() refComponent!: string;
  @Input() componentId!: string;
  @Input() linked!: boolean;
  @Input() initialListId: number | undefined;
  @Input() useSourceListWithInitialList: boolean = false;
  @Input() displayMoversLists: boolean = true;
  @Output() handlerListSelected: EventEmitter<IAllStockListSimple> =
    new EventEmitter<IAllStockListSimple>();
  @Output() handlerAddStocks: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  @Output() changeNotListPersonal: EventEmitter<IAllStockListSimple> =
    new EventEmitter<IAllStockListSimple>();
  @Output() changeOpenListPersonal: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() withoutPersonalList = new EventEmitter<boolean>();
  @Output() selectedGhostList = new EventEmitter<boolean>();

  selectedList!: IAllStockListSimple | null;
  isPersonalListOpen: boolean = true;
  isDynamicListOpenned: boolean = false;
  stockLists: Array<IAllStockListSimple> = [];
  dropnAutoClose = false;
  public dynamicLists = [
    {
      type: DYNAMIC_LISTS.MOVERS_HIGH,
      id: DYNAMIC_LISTS.MOVERS_HIGH.id,
      name: '> Altas',
    },
    {
      type: DYNAMIC_LISTS.MOVERS_LOW,
      id: DYNAMIC_LISTS.MOVERS_LOW.id,
      name: '> Baixas',
    },
    {
      type: DYNAMIC_LISTS.MOVERS_VOLUME,
      id: DYNAMIC_LISTS.MOVERS_VOLUME.id,
      name: 'Volume',
    },
  ];

  readonly dynamiclists = DYNAMIC_LISTS;
  readonly dynamiclistsMapping = {
    [DYNAMIC_LISTS.MOVERS_HIGH.id]: DYNAMIC_LISTS.MOVERS_HIGH,
    [DYNAMIC_LISTS.MOVERS_LOW.id]: DYNAMIC_LISTS.MOVERS_LOW,
    [DYNAMIC_LISTS.MOVERS_VOLUME.id]: DYNAMIC_LISTS.MOVERS_VOLUME,
  };

  private _stockListEvents$!: Subscription;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private channels!: any;

  constructor(
    private _listStocksService: ListStocksService,
    private _processListStockService: ProcessListStockService
  ) {
    this.channels = EVENTS;
  }

  ngAfterViewInit(): void {
    this._triggeredEvent();
    this._getStockLists();
  }

  ngOnDestroy(): void {
    this._removeEvents();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private _checkInitialListIsDynamic() {
    if (
      !this.initialListId ||
      !this.dynamiclistsMapping[this.initialListId] ||
      !this._listStocksService.allStockLists ||
      this.useSourceListWithInitialList
    )
      return;

    this.selectListNotPersonal(this.dynamiclistsMapping[this.initialListId]);
    this.isPersonalListOpen = false;
  }

  private _getStockLists(): void {
    this._processListStockService
      .checkExistLists()
      .pipe(
        filter((loaded) => loaded),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this._getAllLists();
        this._startEvents();
        this._checkInitialListIsDynamic();
      });
  }

  private _getAllLists(): void {
    const hashList = this._listStocksService.allStockLists;
    if (!hashList) {
      this._setEmptyPersonalList();
      return;
    }
    const lists = Object.values(deepClone(hashList)) as IListStockDB[];
    const personalList = lists.filter((list) => !list.isNotListPersonal);
    if (!personalList.length) {
      this._setEmptyPersonalList();
      return;
    }
    this.withoutPersonalList.emit(false);
    this.stockLists = lists
      .sort((a, b) => descendingSort(a.id, b.id))
      .map((item: IListStockDB) => item.list);
    this._verifyInitialList();
  }

  private _verifyInitialList(): void {
    if (this.useSourceListWithInitialList) return;
    if (this.initialListId && !this.selectedList) {
      if (isMoversList(this.initialListId)) {
        this._setInitialListMovers(this.initialListId);
        return;
      }
      const list = this._findListById(this.initialListId);
      if (!list) {
        console.error('initial_list_not_found');
        this._setInitialList(this.stockLists[0]);
        return;
      }
      this._setInitialList(list);
      return;
    }
    if (!this.selectedList) this._setInitialList(this.stockLists[0]);
  }

  private _findListById(idList: number) {
    return this.stockLists.find((list) => list.id_stock_list === idList);
  }

  private _setInitialList(list: IAllStockListSimple): void {
    if (isMoversList(list.id_stock_list)) {
      this.selectListNotPersonal(DYNAMIC_LIST_BY_ID[list.id_stock_list]);
      return;
    }
    this.selectList(list);
  }

  private _setInitialListMovers(idList: number): void {
    this.selectListNotPersonal(DYNAMIC_LIST_BY_ID[idList]);
  }

  selectListNotPersonal(item: { id: number; label: string }): void {
    this._closeDropdown();
    if (item.id === this.selectedList?.id_stock_list) return;
    const list = {
      id_stock_list: item.id,
      in_visibility: true,
      is_favorite: false,
      nm_stock_list: item.label,
      seq_priority: 0,
      isNotListPersonal: false,
      isPresetList: false,
    };
    this.selectedList = list;
    this.changeNotListPersonal.emit(list);
  }

  selectList(list: IAllStockListSimple) {
    this.setList(list);
    const isGhostList = list.id_stock_list < 0;
    this.withoutPersonalList.emit(isGhostList);
    if (isGhostList) {
      this.selectedGhostList.emit();
      return;
    }
    this.handlerListSelected.emit(list);
  }

  setList(list: IAllStockListSimple) {
    this.selectedList = list;
  }

  private _processEvents = (data: any) => {
    const refList = localStorage.getItem(TRIGGERED_THE_EVENT)?.split('/');
    const triggeredTheEvent = refList
      ? refList![0] === this.refComponent && refList![1] === this.componentId
      : false;
    const { type, detail } = data;
    if (!detail || !this.selectedList || !this.selectedList.isNotListPersonal) {
      return;
    }
    const equalId = !!(
      detail.idStockList == this.selectedList.id_stock_list &&
      !triggeredTheEvent
    );
    const equalName = !!(
      equalId &&
      detail['list.nm_stock_list'] &&
      detail['list.nm_stock_list'] != this.selectedList.nm_stock_list
    );

    if (this.channels.LIST_STOCKS_ADD == type) {
      this._getAllLists();
      return;
    }
    if (this.channels.LIST_STOCKS_UPDATE == type) {
      this._getAllLists();
      equalName &&
        (this.selectedList.nm_stock_list = detail['list.nm_stock_list']);
      equalId && this.selectList(this.selectedList);
      return;
    }
    if (this.channels.LIST_STOCKS_DELETE == type) {
      if (equalId) {
        this.selectedList = {} as any;
        const lists = this._listStocksService.allStockLists;
        lists.length &&
          this.handlerListSelected.emit(
            this._listStocksService.allStockLists[0]
          );
      }
      this._getAllLists();
      return;
    }
  };

  private _startEvents(): void {
    this._stockListEvents$ = this._listStocksService.stockListEvents.subscribe(
      (data) => this._processEvents(data)
    );
  }

  private _removeEvents(): void {
    this._stockListEvents$ && this._stockListEvents$.unsubscribe();
  }

  private _triggeredEvent(): void {
    localStorage.setItem(
      TRIGGERED_THE_EVENT,
      `${this.refComponent}/${this.componentId}`
    );
  }

  private _closeDropdown(): void {
    this.dropnAutoClose = true;
    this.dropnAutoClose = false;
  }

  openListPersonal(): void {
    this.isPersonalListOpen = !this.isPersonalListOpen;
    this.changeOpenListPersonal.emit(this.isPersonalListOpen);
  }

  private _setEmptyPersonalList(): void {
    const emptyList = {
      id_stock_list: -1,
      in_visibility: true,
      is_favorite: false,
      nm_stock_list: 'Sem listas pessoais',
      isNotListPersonal: false,
      isSectors: false,
      isPresetList: false,
    };
    this.stockLists = [emptyList];
    this.useSourceListWithInitialList = false;
    this.initialListId = 0;
    this.withoutPersonalList.emit(true);
    if (!this.useSourceListWithInitialList && !this.selectedList)
      this.selectList(emptyList);
  }
}
