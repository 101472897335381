import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { InvestorParticipationService } from '@shared/services/api/trademap/V9/investor-participation.service';

@Component({
  selector: 'app-volume-ipo-tab',
  templateUrl: './volume-ipo-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VolumeIpoTabComponent implements OnInit {
  @Input() ipoData: any = null;
  @Input() width: number = 0;
  @Input() height: number = 0;
  @Input() updatePosition: boolean = false;

  constructor(
    private _investorParticipationService: InvestorParticipationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getInfos();
  }

  private getInfos() {
    this._investorParticipationService.getIpoData().subscribe((res) => {
      this.ipoData = res;
      this.cdr.detectChanges();
    });
  }
}
