import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';
import { OptionsComponents } from '../component-list';

export const AT_PRICE_COMPONENT: any = (options?: OptionsComponents) => {
  return {
    name: 'Volume x Preço',
    id: COMPONENTS_NAMES_ENUM.AT_PRICE,
    icon: '',
    cod: 'AT_PRICE',
    data: null,
    close: true,
    fullscreen: true,
    link: true,
    unitOfMeasurement: '%',
    height: options?.height ?? 0,
    width: options?.width ?? 0,
    minWidth: '307px',
    minHeight: '308px',
    left: options?.left ?? 'calc(15% + 5px)',
    top: options?.top ?? '0px',
    label: 'Volume x Preço',
    noPublished: false,
    hasBorder: false,
    divider: false,
    iconSvg: 'VOLUME_X_PRICE',
  };
};
