import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RankingBrokerComponent } from './ranking-broker.component';
import { HeaderRankingBrokerComponent } from './parts/header-ranking-broker/header-ranking-broker.component';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingModule } from '../loading/loading.module';
import {
  FlaCheckModule,
  FlaIconModule,
  FlaSelectModule,
  RocketButtonModule,
} from '@shared/rocket-components/components';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { SearchStockModule } from '../search-stock/search-stock.module';
import { DetailsTabModule } from '../details-tab/details-tab.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RankingBrokerPartComponent } from './parts/ranking-broker-part/ranking-broker-part.component';
import { RocketGridModule } from '@shared/rocket-grid';
import { RankingBrokerService } from './services/ranking-broker.service';
import { SelectAllHeaderGridComponent } from './parts/select-all-header-grid/select-all-header-grid.component';
import { FlashMarketPartComponent } from './parts/flash-market-part/flash-market-part.component';
import { FilterRankingComponent } from './parts/filter-ranking/filter-ranking.component';
import { RankingBrokerPeriodComponent } from './parts/ranking-broker-period/ranking-broker-period.component';
import { FlashMarketTooltipComponent } from './parts/flash-market-tooltip/flash-market-tooltip.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { StockChartService } from '../stock-chart/service/stock-chart.service';

@NgModule({
  imports: [
    CommonModule,
    FlaWorkSpaceModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingModule,
    RocketButtonModule,
    FlaIconModule,
    TooltipsModule,
    SearchStockModule,
    DetailsTabModule,
    ScrollingModule,
    RocketGridModule,
    FlaCheckModule,
    FlaSelectModule,
    DragDropModule,
  ],
  declarations: [
    RankingBrokerComponent,
    HeaderRankingBrokerComponent,
    RankingBrokerPartComponent,
    SelectAllHeaderGridComponent,
    FlashMarketPartComponent,
    FilterRankingComponent,
    RankingBrokerPeriodComponent,
    FlashMarketTooltipComponent,
  ],
  exports: [
    RankingBrokerComponent,
    FlashMarketPartComponent,
    FilterRankingComponent,
    RankingBrokerPeriodComponent,
  ],
  providers: [RankingBrokerService, StockChartService],
})
export class RankingBrokerModule {}
