import { Injectable } from '@angular/core';
import {
  CHEETAH_CHANNEL,
  QUOTE_FIELDS_CHEETAH,
} from '@shared/cheetah/cheetah.const';
import { MarketDispatcher } from '@shared/cheetah/dispatcher/market.dispatcher';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { RxEvent, StreamReadResponse } from './rx-event';
import { QuoteFillerTransformer } from 'src/app/utils/quote-filler.transformer';

@Injectable({
  providedIn: 'root',
})
export class QuoteChannel {
  private instanciated!: boolean;
  static instance: QuoteChannel;

  constructor(
    private _rxEvent: RxEvent,
    private cheetahService: MarketDispatcher
  ) {
    this.instanciate();
    return (QuoteChannel.instance = QuoteChannel.instance || this);
  }

  instanciate() {
    if (!this.instanciated) {
      this.instanciated = true;
    }
  }

  async readEvents(): StreamReadResponse {
    const data = await this._rxEvent.read(CHEETAH_CHANNEL.QUOTE);
    if (data && data.stream) {
      data.stream = QuoteFillerTransformer.transformStream(data.stream);
    }
    return data;
  }

  subscribe(params: SubscribeParam) {
    const { itemsArray } = params;
    params.fields = QUOTE_FIELDS_CHEETAH;
    params.channel_cheetah = CHEETAH_CHANNEL.QUOTE;
    this.cheetahService.subscribe({
      channel_cheetah: CHEETAH_CHANNEL.QUOTE,
      itemsArray,
    });
  }

  unsubscribe(params: SubscribeParam) {
    if (!params) return;
    this.cheetahService.unsubscribe({
      channel_cheetah: CHEETAH_CHANNEL.QUOTE,
      itemsArray: params.itemsArray,
    });
  }
}
