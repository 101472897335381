import {
  dateTimeFormatter,
  getValidStringDate,
} from '@shared/rocket-components/utils';
import {
  ORDER_AUCTION_TYPE,
  isDoubleStartStop,
} from '@shared/constants/general.contant';
import {
  TYPE_ORDE_SIDE_ENUM,
  TYPE_ORDE_SIDE_STRING_ENUM,
} from '@shared/enum/buyOrSell.enum';
import { ValueFormatterParams } from 'ag-grid-community';
import {
  execFormatFinancial,
  execFormatQtd,
  formatByTick,
  formatterNumber,
} from './utils.functions';

export const formatByTickSize = (params: ValueFormatterParams) => {
  const { value, data } = params;
  const { tick_size_denominator } = data;
  return formatByTick(value, tick_size_denominator);
};

export const financialFormatterFunction = (params: ValueFormatterParams) => {
  const { value, data } = params;
  return execFormatFinancial(data, value);
};

export const financialFormatter = (data: any) => {
  const value = data.preco;
  return execFormatFinancial(data, value);
};

export const financialFormatterForPositionCurrentPrice = (
  params: ValueFormatterParams
) => {
  if (!params.value) return;
  const value =
    typeof params.value === 'number' ? params.value.toString() : params.value;
  params.data = {
    ...params.data,
    ...{
      tick_size_denominator: value.includes('.')
        ? value.split('.')[1].length
        : 0,
    },
  };
  return financialFormatterFunction(params);
};

/**
 * Formatador do campo quantidade
 * Existem valores para quantidades fracionadas de acordo o id_exchange
 * a função esta funcionando com formatador em 8 casas decimais para id_exchange maior que 7000
 * teoricamente são ativos internaocionais nao b3
 * @param params recebe a linha inteira para fazer o tratamento
 * @returns valor formatado
 */

export const formatterPercentageFields = (params: ValueFormatterParams) => {
  const { value } = params;
  const formattedValue = formatterNumber(parseFloat(value), {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  if (formattedValue === '-') return formattedValue;
  return `${formattedValue} %`;
};

export const qtdeFormatterFunction = (params: ValueFormatterParams) => {
  const { value, data } = params;
  return execFormatQtd(data, value);
};

export const qtdeFormatter = (data: any) => {
  const value = data.qtde_negociada;
  return execFormatQtd(data, value);
};

export const dec2hex = (dec: number) => {
  let hex = Number(dec).toString(16);
  if (hex.length < 2) {
    hex = `0${hex}`;
  }
  return `#${hex}`;
};

export const orderTypeFormatterFunction = (params: ValueFormatterParams) => {
  const { value } = params;
  const { cd_order_time_enforce, cd_order_type } = params.data;
  const firstValue =
    ORDER_AUCTION_TYPE[`${cd_order_time_enforce}${cd_order_type}`] ??
    value[0] ??
    value;
  switch (firstValue) {
    case 'Mercado Limitada':
      return 'A mercado';
    default:
      return firstValue;
  }
};

export const dateOrTimeFormatter = (
  value: string,
  type: 'time' | 'date' | undefined = undefined
): string => {
  const date = getValidStringDate(value);
  const day = new Date().toLocaleString('pt-br');
  const dateFormatted = new Date(date).toLocaleString('pt-br');
  const daySplit = day.split(' ');
  const dateFormattedSplit = dateFormatted.split(' ');
  if (daySplit.shift() != dateFormattedSplit[0] && !type) {
    return dateFormattedSplit.shift()!.replace(',', '');
  }
  return dateTimeFormatter(value, type || 'time');
};

export const financialFormatterFunctionPriceDouble = (
  params: ValueFormatterParams
) => {
  const { value, data } = params;
  if (isDoubleStartStop(data.cd_order_type)) {
    const isBuy =
      data.side == TYPE_ORDE_SIDE_STRING_ENUM.BUY ||
      data.side == TYPE_ORDE_SIDE_ENUM.BUY;
    return isBuy
      ? execFormatFinancial(data, data.personalized_loss_price)
      : execFormatFinancial(data, data.personalized_gain_price);
  }
  return execFormatFinancial(data, value);
};

export const financialFormatterFunctionStopDouble = (
  params: ValueFormatterParams
) => {
  const { value, data } = params;
  if (isDoubleStartStop(data.cd_order_type)) {
    const isBuy =
      data.side == TYPE_ORDE_SIDE_STRING_ENUM.BUY ||
      data.side == TYPE_ORDE_SIDE_ENUM.BUY;
    return isBuy
      ? execFormatFinancial(data, data.personalized_gain_trigger)
      : execFormatFinancial(data, data.personalized_loss_trigger);
  }
  return execFormatFinancial(data, value);
};

export const tooltipDoublePrice = (data: any) => {
  const isBuy =
    data.side == TYPE_ORDE_SIDE_STRING_ENUM.BUY ||
    data.side == TYPE_ORDE_SIDE_ENUM.BUY;
  const price = isBuy
    ? `Preço (Gain): ${execFormatFinancial(data, data.personalized_gain_price)}`
    : `Preço (Loss): ${execFormatFinancial(
        data,
        data.personalized_loss_price
      )}`;
  const stop = isBuy
    ? `Preço (Loss): ${execFormatFinancial(data, data.personalized_loss_price)}`
    : `Preço (Gain): ${execFormatFinancial(
        data,
        data.personalized_gain_price
      )}`;
  return `<div class="text-left"> ${price} <br> ${stop}</div>`;
};

export const financialFormatDoublePrice = (data: any) => {
  const isBuy =
    data.side == TYPE_ORDE_SIDE_STRING_ENUM.BUY ||
    data.side == TYPE_ORDE_SIDE_ENUM.BUY;

  const price = isBuy
    ? `<span class="icon-position new-position gain">G</span> ${execFormatFinancial(
        data,
        data.personalized_gain_price
      )}`
    : `<span class="icon-position loss">L</span> ${execFormatFinancial(
        data,
        data.personalized_loss_price
      )}`;

  const stop = isBuy
    ? `<span class="icon-position loss">L</span> ${execFormatFinancial(
        data,
        data.personalized_loss_price
      )}`
    : `<span class="icon-position new-position gain">G</span> ${execFormatFinancial(
        data,
        data.personalized_gain_price
      )}`;

  return `${price} / ${stop}`;
};

export const tooltipDoubleTrigger = (data: any) => {
  const isBuy =
    data.side == TYPE_ORDE_SIDE_STRING_ENUM.BUY ||
    data.side == TYPE_ORDE_SIDE_ENUM.BUY;
  const price = isBuy
    ? `Stop (Gain): ${execFormatFinancial(
        data,
        data.personalized_gain_trigger
      )}`
    : `Stop (Loss): ${execFormatFinancial(
        data,
        data.personalized_loss_trigger
      )}`;
  const stop = isBuy
    ? `Stop (Loss): ${execFormatFinancial(
        data,
        data.personalized_loss_trigger
      )}`
    : `Stop (Gain): ${execFormatFinancial(
        data,
        data.personalized_gain_trigger
      )}`;
  return `<div class="text-left"> ${price} <br> ${stop}</div>`;
};

export const financialFormatDoubleTrigger = (data: any) => {
  const isBuy =
    data.side == TYPE_ORDE_SIDE_STRING_ENUM.BUY ||
    data.side == TYPE_ORDE_SIDE_ENUM.BUY;

  const price = isBuy
    ? `<span class="icon-position new-position gain">G</span> ${execFormatFinancial(
        data,
        data.personalized_gain_trigger
      )}`
    : `<span class="icon-position loss">L</span> ${execFormatFinancial(
        data,
        data.personalized_loss_trigger
      )}`;

  const stop = isBuy
    ? `<span class="icon-position loss">L</span> ${execFormatFinancial(
        data,
        data.personalized_loss_trigger
      )}`
    : `<span class="icon-position new-position gain">G</span> ${execFormatFinancial(
        data,
        data.personalized_gain_trigger
      )}`;
  return `${price} / ${stop}`;
};

export const verifyDirectionStopDouble = (
  data: any
): null | 'GAIN' | 'LOSS' => {
  if (data?.stop_triggered) return data.stop_triggered;
  let direction: null | 'GAIN' | 'LOSS' = null;
  const gain_price = data?.personalized_gain_price || null;
  const loss_price = data?.personalized_loss_price || null;
  if (
    gain_price &&
    +gain_price == +data.price_average &&
    data.cd_order_status == 'EXEC'
  ) {
    direction = 'GAIN';
  } else if (
    loss_price &&
    +loss_price == +data.price_average &&
    data.cd_order_status == 'EXEC'
  ) {
    direction = 'LOSS';
  }
  return direction;
};

export const checkIsDayTrade = (value: any): string => {
  value = JSON.parse(value || false);
  return `<span class="material-icons-outlined icon-size-tiny ${
    value ? 'text-feedback-success' : 'text-hint-loss'
  }">
  ${value ? 'done' : 'close'}
  </span>`;
};

export const verifyResetPosition = (data: any) => {
  return JSON.parse(data?.is_reset_position || 'false');
};
