import { Injectable } from '@angular/core';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import {
  IGetRisksPersonal,
  ISendRisksPersonal,
} from '@shared/services/api/trademap/v1/interface/risks-personal.interface';
import { OrsGatewayService } from '@shared/services/api/trademap/v1/ors-gateway.service';
import { MultibrokerService } from '@shared/services/core/multibroker/multibroker.service';
import { auditTime, Observable, Subject } from 'rxjs';
import { system } from '@core/system/system.service';

@Injectable({
  providedIn: 'root',
})
export class RiskManagementService {
  private risksPersonalActive$ = new Subject<boolean>();
  private getRisksPersonalDelayTime$ = new Subject<void>();
  private risksPersonalList!: IGetRisksPersonal[];
  private broker!: any;
  private isDayTrade = false;

  constructor(
    private orsGatewayService: OrsGatewayService,
    private multibrokerService: MultibrokerService,
    private dayTradeService: DaytradeService
  ) {
    this.startObeservabes();
  }

  public risksPersonalActive(): Observable<boolean> {
    return this.risksPersonalActive$.asObservable();
  }

  public getRisksPersonalDelayTime(): void {
    this.getRisksPersonalDelayTime$.next();
  }

  private getRisksPersonal(): void {
    this.orsGatewayService
      .getRisksPersonal()
      .subscribe((data: IGetRisksPersonal[]) => {
        this.risksPersonalList = data;
        this.changeRisckPersonalByBrokerActive();
      });
  }

  private changeRisckPersonalByBrokerActive(): void {
    if (!this.risksPersonalList) return;
    const params: IGetRisksPersonal | any =
      this.getRisksPersonalByModoAndBroker();
    let active = false;
    if (params) {
      Object.keys(params).forEach((item: string) => {
        if (
          params[item] &&
          item !== 'idBroker' &&
          item !== 'idExchange' &&
          item !== 'riskType'
        ) {
          active = true;
        }
      });
    }
    this.risksPersonalActive$.next(active);
  }

  public changeRisckPersonalByBroker(): {
    risksPersonal: IGetRisksPersonal | any;
    isUpdate: boolean;
    idBroker: number;
    isDayTrade: boolean;
    modo: string;
  } {
    const params: IGetRisksPersonal | undefined =
      this.getRisksPersonalByModoAndBroker();
    const modoLabel: { dayTrade: string; swing: string } =
      system.tradeModeLabels;
    return {
      risksPersonal: params ?? {},
      isUpdate: !!params,
      idBroker: this.broker.id_broker,
      isDayTrade: this.isDayTrade,
      modo: this.isDayTrade ? modoLabel.dayTrade : modoLabel.swing,
    };
  }

  private getRisksPersonalByModoAndBroker(): IGetRisksPersonal | undefined {
    this.broker = this.multibrokerService.getAccountSelected();
    const modo = this.isDayTrade ? 'DAYTRADE' : 'SWING';
    const params: IGetRisksPersonal | undefined = this.risksPersonalList.find(
      (item: IGetRisksPersonal) =>
        item.idBroker === this.broker.id_broker && item.riskType === modo
    );
    return params;
  }

  public saveOrUpdate(isUpdate: boolean, params: ISendRisksPersonal): void {
    if (isUpdate) {
      this.orsGatewayService
        .updateRisksPersonal(params)
        .subscribe(() => this.getRisksPersonalDelayTime());
    } else {
      this.orsGatewayService
        .saveRisksPersonal(params)
        .subscribe(() => this.getRisksPersonalDelayTime());
    }
  }

  private startObeservabes(): void {
    this.dayTradeService.dayTradeMode.subscribe((isEnable: boolean) => {
      this.isDayTrade = isEnable;
      this.changeRisckPersonalByBrokerActive();
    });
    this.getRisksPersonalDelayTime$
      .pipe(auditTime(250))
      .subscribe(() => this.getRisksPersonal());
    this.multibrokerService
      .onUpdateSelectedAccountChannel()
      .subscribe(() => this.changeRisckPersonalByBrokerActive());
  }

  public risksSubscribe(): void {
    this.getRisksPersonal();
    this.orsGatewayService.risksSubscribe('SWING').subscribe();
    this.orsGatewayService.risksSubscribe('DAYTRADE').subscribe();
  }
}
