import { THEMES } from '@shared/services/core/custom-preferences/theme/theme-preferences.interface';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { DEFAULT_THEME, LIGHT_THEME } from '@shared/tiger-chart/colors';
import { ICandleTheme } from '@shared/tiger-chart/interface';
import { Subject } from 'rxjs';

export default class themeHelper {
  public theme!: ICandleTheme;

  private _isDarkTheme: boolean = true;
  public onThemeChange = new Subject<void>();

  get isDarkTheme(): boolean {
    return this._isDarkTheme;
  }

  constructor(private _themeService: ThemePreferencesService) {
    const isDarkTheme = this._initialTheme();
    this._isDarkTheme = isDarkTheme;
    this.theme = this.getTheme(isDarkTheme);
    this._themeService.themeActiveObservable().subscribe((theme) => {
      this._isDarkTheme = theme === THEMES.dark;
      this.changeThemeHandler(theme);
    });
  }

  private _initialTheme = () => this._themeService.isDarkTheme();

  public getTheme = (isDark: boolean) =>
    isDark ? DEFAULT_THEME() : LIGHT_THEME();

  public changeThemeHandler(data: THEMES): void {
    this.theme = this.getTheme(data === THEMES.dark);
    this.onThemeChange.next();
  }
}
