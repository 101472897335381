export enum BODY_CONTENT {
  USER_STRATEGIES = 'USER_STRATEGIES',
  DELETE_STRATEGY = 'DELETE_STRATEGY',
}

export enum STRATEGY_TYPE {
  TICK = 'TICK',
  PERCENTUAL = 'PERCENTUAL',
  FINANCEIRO = 'FINANCEIRO',
}

export enum STRATEGY_TYPE_LABEL {
  TICK = 'Ticks',
  PERCENTUAL = '%',
  FINANCEIRO = 'Financ',
}

export enum ACTIVE_STRATEGY_TYPE {
  TICK = 'Ticks',
  PERCENTUAL = 'Percentual',
  FINANCEIRO = 'Financeiro',
}
