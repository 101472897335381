<ng-container *ngIf="!loading && authenticator; else loadingRef">
  <ng-container *ngIf="step === steps.TOKEN">
    <p class="fs-5 pb-3">
      Para começar digite a sua senha do <b>RocketTrader</b>
    </p>
    <fla-input
      fla-focus
      placeholder="Informe a sua senha"
      label="Senha"
      css="my-2 m-0"
      decidePosition="suffix"
      [focusOnInit]="true"
      [formControl]="userPassword"
      [autoComplete]="false"
      [requiredText]="'Informe a sua senha de acesso'"
      [type]="showPassword ? 'text' : 'password'"
      (flaKeyUp)="checkFormStatus()"
    >
      <div class="suffix">
        <rocket-button type="icon" (rocketClick)="showPassword = !showPassword">
          <fla-icon
            [name]="showPassword ? 'visibility' : 'visibility_off'"
          ></fla-icon>
        </rocket-button>
      </div>
    </fla-input>
  </ng-container>
  <ng-container *ngIf="step === steps.QRCODE">
    <fla-input
      label="Insira o Token que você recebeu por SMS"
      fla-focus
      placeholder="Insira aqui o token"
      css="my-2 m-0"
      requiredText="Informe o token recebido por SMS"
      [focusOnInit]="true"
      [formControl]="smsCode"
      [flaMask]="numberMask"
      (flaKeyUp)="checkFormStatus()"
    >
    </fla-input>
    <rocket-button
      css="resend-button fs-6"
      type="fill"
      [label]="resendLabel"
      [isDisabled]="resendDisabled"
      (rocketClick)="sendNewSeed()"
    ></rocket-button>
  </ng-container>
  <ng-container *ngIf="step === steps.VALIDATION">
    <ol class="pl-4" *ngIf="authenticator.id === 'GOOGLE'">
      <li class="pb-2 fs-5">
        Abra o <b>{{ authenticator.name }}</b> e clique no <b>+</b> no canto
        inferior direito da tela e vá na opção <b>Ler código QR</b>.
      </li>
      <li class="pb-2 fs-5">
        <span>Faça a leitura do QR Code no app:</span>
        <span class="w-100 py-1" *ngIf="QRCodeUrl">
          <qrcode
            cssClass="pt-1"
            [qrdata]="QRCodeUrl"
            [width]="150"
            [errorCorrectionLevel]="'M'"
            [elementType]="'img'"
          ></qrcode>
        </span>
      </li>

      <li class="pb-2 fs-5">
        Insira abaixo o token de 6 dígitos que agora aparece no
        <b>{{ authenticator.name }}</b>
      </li>
    </ol>

    <ol class="pl-4" *ngIf="authenticator.id === 'MICROSOFT'">
      <li class="pb-2 fs-5">
        Abra o aplicativo e clique no <b>+</b> no canto superior direito da tela
        e depois em <b>Outro (Google, Facebook, etc.)</b>
      </li>
      <li class="pb-2 fs-5">
        <span>Faça o scan do QR Code:</span>
        <span>
          <span *ngIf="QRCodeUrl">
            <qrcode
              cssClass="pt-1"
              [qrdata]="QRCodeUrl"
              [width]="150"
              [errorCorrectionLevel]="'M'"
              [elementType]="'img'"
            ></qrcode>
          </span>
        </span>
      </li>
      <li class="pb-2 fs-5">
        Insira abaixo o token de 6 dígitos que agora aparece no
        <b>{{ authenticator.name }}</b>
      </li>
    </ol>

    <fla-input
      fla-focus
      placeholder="Insira aqui o seu token"
      label="Token"
      css="mt-2 m-0"
      [focusOnInit]="true"
      [requiredText]="'Informe o token gerado no ' + authenticator.name"
      [formControl]="tokenCode"
      [flaMask]="numberMask"
      (flaKeyUp)="checkFormStatus()"
    >
    </fla-input>
  </ng-container>
</ng-container>
<ng-template #loadingRef>
  <div class="h-100">
    <app-loading></app-loading>
  </div>
</ng-template>
