import { IMonthDetails } from './../../interface/datepicker.interface';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector:
    'app-datepicker-days[monthDetails][todayTimestamp][selectedDay][daysMap]',
  templateUrl: './days.component.html',
  styleUrls: ['./days.component.scss'],
})
export class FlaDatepickerDaysComponent {
  @Input() monthDetails!: IMonthDetails[];
  @Input() daysMap!: string[];
  @Input() todayTimestamp!: number;
  @Input() selectedDay!: number;
  @Output() flaClick = new EventEmitter<any>();
}
