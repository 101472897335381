export interface IBroker {
  broker_logo: string;
  can_edit_order: boolean;
  display_priority: number;
  has_day_trade: boolean;
  id_broker: number;
  id_exchange: number;
  is_available_hire: boolean;
  is_http_requisition: boolean;
  is_multi_broker: boolean;
  is_third_party_auth: boolean;
  nm_broker: string;
  nm_broker_nickname: string;
  seq_ordination: number;
  tick_size: number;
  label: string;
  broker_swing: boolean;
  broker_daytrade: boolean;
  nm_broker_swing: string;
  nm_broker_daytrade: string;
  term: IBrokerTerms;
}

export interface IAccountSelect {
  key: string;
  label: string;
  id_broker: number;
  accounts: string[];
  account_number: string;
}

export interface IBrokerTerms {
  idTerm: number;
  nmTerm: string;
  content: string;
  contentType: string;
  status: BROKER_TERMS_STATUS;
}

export enum BROKER_TERMS_STATUS {
  OK = 'OK',
  NOT_MOST_RECENT = 'NOT_MOST_RECENT',
  HASNT_APPROVED = 'HASNT_APPROVED',
}

export enum ACCOUNT_WORKER_ACTION {
  SET_BROKER_ACCOUNTS = 'SET_BROKER_ACCOUNTS',
  UPDATE_SELECTED_ACCOUNT = 'UPDATE_SELECTED_ACCOUNT',
  UPDATE_ACCOUNT = 'UPDATE_ACCOUNT',
  DELETE_ACCOUNT_MULTIBROKER = 'DELETE_ACCOUNT_MULTIBROKER',
  GET_BROKER_ACCOUNTS = 'GET_BROKER_ACCOUNTS',
  GET_SELECTED_ACCOUNT = 'GET_SELECTED_ACCOUNT',
}

export interface IWorkerAccountAction {
  action: ACCOUNT_WORKER_ACTION;
  workspacesData: any;
}

export interface IWorkerAccountActionReturn {
  brokerAccounts?: IBroker[];
  selectedAccount?: IAccountSelect;
  preventUpdate?: boolean;
}
