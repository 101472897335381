import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';
import { OptionsComponents } from '../component-list';

export const STOCK_ANALYSIS_COMPONENT: any = (options?: OptionsComponents) => ({
  name: 'Análise de ativos',
  id: COMPONENTS_NAMES_ENUM.STOCK_ANALYSIS,
  icon: '',
  cod: 'STOCK_ANALYSIS',
  data: null,
  close: true,
  fullscreen: true,
  link: true,
  widthDesk: 935,
  heightDesk: 600,
  unitOfMeasurement: options?.unit ?? 'px',
  height: options?.height ?? 600,
  width: options?.width ?? 935,
  minWidth: '650px',
  left: options?.left ?? 'calc(15% + 5px)',
  top: options?.top ?? '0px',
  label: 'Análise de ativos',
  borderClass: '',
  noPublished: false,
  iconSvg: 'BUBBLE',
});
