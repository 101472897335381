import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Dictionary } from '@core/models';
import { QuoteChannel } from '@shared/channel/quote.channel';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { MarketSectorsService } from '@shared/services/api/trademap/v1/market-sectors.service';
import { StockService } from '@shared/services/api/trademap/v1/stock.service';
import { formatByTick } from 'src/app/utils/utils.functions';
import { ISectorSubSectorSegmentStocks } from '../../business-profile.interface';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { Subscription } from 'rxjs';
import { CONFIGURATION_SECTORS } from '../../business-profile.const';

@Component({
  selector: 'app-modal-sector-subsector-segment',
  templateUrl: './modal-sector-subsector-segment.component.html',
  styleUrls: ['./modal-sector-subsector-segment.component.scss'],
})
export class ModalSectorSubsectorSegmentComponent
  extends RocketModalRef
  implements OnInit, OnDestroy
{
  public pdfUrl = '';
  configuration: any = CONFIGURATION_SECTORS;
  configModal: any = {};
  stocksObj: any = {};
  quoteStocks = new Dictionary<ISectorSubSectorSegmentStocks>();
  private quoteParams!: SubscribeParam;
  private quoteChannel$!: Subscription;

  constructor(
    @Inject(ROCKET_MODAL_DATA)
    public data: any,
    _rocketModalService: RocketModalService,
    private marketSectorsService: MarketSectorsService,
    private stockService: StockService,
    private _quoteChannel: QuoteChannel
  ) {
    super(_rocketModalService);
  }

  ngOnInit() {
    this._quoteChannel.readEvents().then(async (data) => {
      this.readStream(data.stream, this._quoteHandler);
    });

    const { info, position } = this.data;
    this.configModal.title = info[this.configuration[position].title];
    this.getInfo(
      info[this.configuration[position].id],
      this.configuration[position].method
    );
  }

  private _quoteHandler(data: any) {
    if (!this.quoteStocks.has(data.item)) return;

    if (data.variacao_dia) data.variacao_dia = formatByTick(data.variacao_dia);
    if (data.preco_ultimo) data.preco_ultimo = formatByTick(data.preco_ultimo);

    const previous = this.quoteStocks.get(data.item)!!;
    if (!previous.image) data.image = this.getLogo(previous);

    this.quoteStocks.set(data.item, data);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.quoteChannel$.unsubscribe();
    this.quoteParams && this._quoteChannel.unsubscribe(this.quoteParams);
  }

  async getInfo(id: any, method: any) {
    (this.marketSectorsService as any)[method](id).then((info: any) => {
      const response = info.data;
      if (response.success) {
        this.configModal.cards = response.result.stocks;
        this.processSubscribeCheetah();
      }
    });
  }

  getLogo(stock: ISectorSubSectorSegmentStocks) {
    if (!stock.ds_asset) {
      stock.ds_asset = stock.cd_stock.substring(0, 4);
    }
    const image = stock.ds_asset ? stock.ds_asset.trim() : '';
    return `https://cdn.trademap.com.br/company_logos_icons/${image.toUpperCase()}.png`;
  }

  processSubscribeCheetah() {
    this.configModal.cards.forEach((card: ISectorSubSectorSegmentStocks) => {
      this.quoteStocks.set(`${card.cd_stock}:${card.id_exchange}`, card);
    });
    this.quoteParams = {
      header: this.data.refComponent,
      itemsArray: this.quoteStocks.keys() as string[],
    };
    this._quoteChannel.subscribe(this.quoteParams);
  }

  setErrorImage(card: any) {
    card.hasImage = false;
  }

  public async stockRedirect() {
    this.close();
  }
}
