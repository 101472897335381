import { Dictionary } from 'src/app/core/models';
import { LINE_STYLES } from './styles-input.consts';

export const LINE_CONFIG_DICTIONARY = new Dictionary<{
  label: string;
  dashArray: number[];
}>();

LINE_CONFIG_DICTIONARY.set(LINE_STYLES.solid.id, {
  label: 'Linha',
  dashArray: [0, 0],
});
LINE_CONFIG_DICTIONARY.set(LINE_STYLES.dashed.id, {
  label: 'Linha Tracejada',
  dashArray: [5, 5],
});
LINE_CONFIG_DICTIONARY.set(LINE_STYLES.dotted.id, {
  label: 'Linha Pontilhada',
  dashArray: [2, 2],
});
