import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';

@Injectable({
  providedIn: 'root',
})
export class JoystickPreferencesService extends CustomPreferencesService {
  public NEGOTIATION_STORAGE_KEYS = {
    DISPLAY_JOYSTICK: 'DISPLAY_JOYSTICK',
  };

  constructor(public http: HttpClient) {
    super(http);
  }

  set displayJoystick(display: boolean) {
    this.setValueCustomPreferences(
      this.NEGOTIATION_STORAGE_KEYS.DISPLAY_JOYSTICK,
      display ? '1' : '0'
    );
  }

  get displayJoystick(): boolean {
    const joyStickPreference = JSON.parse(
      this.storage.getItem(this.NEGOTIATION_STORAGE_KEYS.DISPLAY_JOYSTICK)
    );
    if (isNullOrUndefined(joyStickPreference)) return true;
    return joyStickPreference === 1;
  }
}
