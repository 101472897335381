import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Dictionary } from '@core/models';
import { formatterNumber } from '@shared/rocket-components/utils';
import { ICustodyChartInfos } from '@shared/services/api/nitro-custody-ws/v1/interface/custody.interface';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import * as Highcharts from 'highcharts';
import {
  VMDateToHoursAndMin,
  formatDate,
  getVolumeText,
} from 'src/app/utils/utils.functions';
import { PERFOMANCE_CHART_STYLES } from '../constants/performance-report.contants';
import { THEMES_ENUM } from 'src/app/utils/change-theme';
interface IPerformanceTooltip {
  operations: string;
  win_operation: number;
  avg_profit: string;
  loss_operation: number;
  avg_loss: string;
  zero_operation: number;
  zero_percent: string;
  orders: string;
  open_order: number;
  executed_order: number;
  canceled_order: number;
}

@Injectable({
  providedIn: 'root',
})
export class PerformanceHighchartsService {
  private tooltipDict: Dictionary<any> = new Dictionary();

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  private buildTooltipInfos = (
    ref: string,
    performance: ICustodyChartInfos
  ): IPerformanceTooltip => {
    const dictKey = `${ref}_${performance.id_point}`;
    if (this.tooltipDict.has(dictKey)) return this.tooltipDict.get(dictKey);
    const tooltipInfos = {
      operations: this._sectionTitle(
        performance.total_operation,
        'Operação',
        'Operações'
      ),
      win_operation: performance.qtty_win_operation,
      avg_profit: `${formatterNumber(performance.avg_win_operation)}%`,
      loss_operation: performance.qtty_loss_operation,
      avg_loss: `${formatterNumber(performance.avg_loss_operation)}%`,
      zero_operation: performance.qtty_zero_operation,
      zero_percent: `${formatterNumber(performance.perc_zero_operation)}%`,
      orders: this._sectionTitle(performance.total_order, 'Ordem', 'Ordens'),
      open_order: performance.qtty_open_order,
      executed_order: performance.qtty_exec_order,
      canceled_order: performance.qtty_canc_order,
    };
    this.tooltipDict.set(dictKey, tooltipInfos);
    return tooltipInfos;
  };

  private _sectionTitle = (
    qty: number,
    singular: string,
    plural: string
  ): string => {
    return `${qty <= 0 ? 'Nenhuma' : qty} ${qty <= 1 ? singular : plural}`;
  };

  initGraph(
    refComponent: string,
    isIntraday: boolean = false,
    chartData: ICustodyChartInfos[],
    theme: THEMES_ENUM,
    callback?: Highcharts.ChartCallbackFunction
  ): Highcharts.Chart {
    const data: number[] = [];
    const categories: string[] = [];

    for (let index = 0; index < chartData.length; index++) {
      data.push(chartData[index].vl_profit);
      categories.push(
        isIntraday
          ? VMDateToHoursAndMin(chartData[index].id_point)
          : formatDate(chartData[index].id_point.toString().slice(0, 8), true)
      );
    }
    return Highcharts.chart(
      refComponent,
      {
        chart: {
          type: 'area',
          backgroundColor: 'transparent',
        },
        title: {
          text: 'Resultados',
          style: {
            ...PERFOMANCE_CHART_STYLES,
            fontSize: '16px',
            fontWeight: 'bold',
          },
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          area: {
            zoneAxis: 'y',
            lineWidth: 2,
            zones: [
              {
                value: Number.MIN_VALUE,
                color: {
                  linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                  stops: [
                    [0, `${CHART_COLORS.FEEDBACK_NEGATIVE}10`],
                    [1, CHART_COLORS.FEEDBACK_NEGATIVE],
                  ],
                },
              },
              {
                value: Number.MAX_VALUE,
                color: {
                  linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
                  stops: [
                    [0, `${CHART_COLORS.FEEDBACK_POSITIVE}10`],
                    [1, CHART_COLORS.FEEDBACK_POSITIVE],
                  ],
                },
              },
            ],
          },
        },
        legend: {
          enabled: false,
        },
        yAxis: {
          opposite: true,
          tickPixelInterval: 48,
          title: {
            text: null,
          },
          labels: {
            formatter: function (response: any) {
              if (response.value >= 0)
                return getVolumeText('pt-BR', response.value, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              return `-${getVolumeText('pt-BR', -response.value, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`;
            },
            style: PERFOMANCE_CHART_STYLES,
          },
        },
        xAxis: {
          categories,
          labels: {
            style: PERFOMANCE_CHART_STYLES,
          },
        },
        series: [
          {
            type: 'area',
            data,
          },
        ],
        tooltip: {
          positioner: function () {
            return { x: 10, y: 12 };
          },
          useHTML: true,
          shadow: false,
          borderWidth: 0,
          backgroundColor: undefined,
          formatter: this.getTooltipFormater(
            refComponent,
            isIntraday,
            chartData,
            theme
          ),
        },
      },
      callback
    );
  }

  getTooltipFormater(
    refComponent: string,
    isIntraday: boolean = false,
    chartData: ICustodyChartInfos[],
    theme: THEMES_ENUM
  ): Highcharts.TooltipFormatterCallbackFunction {
    const tooltipTextClass =
      theme == THEMES_ENUM.DARK
        ? 'text-neutral-smoothest'
        : 'text-neutral-strongest';

    const buildTooltipInfos = this.buildTooltipInfos;

    return function () {
      const performance = buildTooltipInfos(
        refComponent,
        chartData[this.point.index]
      );

      return `
      <div class="d-flex flex-column p-0 fs-12 ${tooltipTextClass}">
        <div>
          <span class="fs-6 mr-2">
            ${isIntraday ? 'Hora:' : 'Data:'} ${this.x}
          </span>
          <span class="fs-6">Saldo líquido:</span>
          <i class="icons icon-size-sm ${
            this.y! > 0 ? 'text-feedback-positive' : 'text-feedback-negative'
          }">
            ${this.y! > 0 ? 'arrow_drop_up' : 'arrow_drop_down'}
          </i>
          <span class="fs-6">R$ ${formatterNumber(this.y!)}</span>
        </div>

        <div>
          <span class="fs-6 mr-1">${performance.operations}</span>
            <span class="fs-6">(Vencedoras</span>
            <span class="fs-6 mr-1">
              ${performance.win_operation} | ${performance.avg_profit}
            </span>
            <span class="fs-6">Perdedoras</span>
            <span class="fs-6 mr-1">
              ${performance.loss_operation} | ${performance.avg_loss}
            </span>
            <span class="fs-6">Zeradas</span>
            <span class="fs-6">
              ${performance.zero_operation} | ${performance.zero_percent})
            </span>
        </div>

        <div>
          <span class="fs-6" mr-2>${performance.orders}</span>
          <span class="fs-6">(Abertas</span>
          <span class="fs-6 mr-1">${performance.open_order}</span>
          <span class="fs-6">Executadas</span>
          <span class="fs-6 mr-1">${performance.executed_order}</span>
          <span class="fs-6">Canceladas</span>
          <span class="fs-6">${performance.canceled_order})</span>
        </div>
      </div>
    </div>
    `;
    };
  }
}
