import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { TOptions } from '../../../types';

@Component({
  selector: 'app-style-cell-renderer',
  template: `<span>
    <img
      *ngIf="src; else elseTemplate"
      [alt]="altText"
      [style.width.px]="22"
      [src]="src"
    />
    <ng-template #elseTemplate>-</ng-template>
  </span>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StyleCellRendererComponent implements ICellRendererAngularComp {
  cellValue!: TOptions;
  colDef!: ColDef<any>;
  src = '';
  altText: string | number = '';

  agInit(params: ICellRendererParams): void {
    this.cellValue = params.data;
    params.colDef && (this.colDef = params.colDef);
    this._setSrc();
  }

  refresh(params: ICellRendererParams): boolean {
    this.cellValue = params.data;
    this._setSrc();
    return true;
  }

  private _setSrc() {
    const value =
      this.cellValue[(this.colDef?.field || 'kind_option') as keyof TOptions];

    if (!value) return;

    this.altText = value;
    const svg =
      value == 'Americana' ? 'united-states-flag.svg' : 'europe-flag.svg';
    this.src = `assets/svg/${svg}`;
  }
}
