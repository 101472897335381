import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ICandleConfigs } from '@shared/tiger-chart/interface/tiger-chart.interface';
import { ChartBase } from '@shared/components/stock-chart/model/stock-chart.model';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { objectAssign } from 'src/app/utils/utils.functions';
import { ConfigModalCandle } from '../model/stock-chart-modal.config.model';
import { TYPE_CHART_UPDATE } from '../enum/stock-chart-modal.enum';
import { TOOLS_TYPE_DRAW } from '@shared/tiger-chart/tiger-chart-tools/tiger-chart-tools.enum';
import { color } from '@shared/tiger-chart/tiger-chart-tools/toolbar-draw/toolbar-draw.interface';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import { StockChartModalService } from '../service/stock-chart-modal.service';
import { COLORS_CHART } from '../interface/stock-chart-modal.interface';
@Component({
  selector: 'app-stock-chart-modal-config-candle',
  templateUrl: './stock-chart-modal-config-candle.component.html',
})
export class StockChartModalConfigCandleComponent
  extends ChartBase
  implements OnInit, AfterViewInit
{
  color: EventEmitter<COLORS_CHART> = new EventEmitter<COLORS_CHART>();
  @Input() refComponent!: string;
  @Input() configs!: Partial<ICandleConfigs>;
  form!: FormGroup;
  toolsTypeDraw = TOOLS_TYPE_DRAW;
  candleDefaultColors: any = {
    colorsVelaDown: CHART_COLORS.FEEDBACK_NEGATIVE,
    colorsVelaUp: CHART_COLORS.FEEDBACK_POSITIVE,
    colorsBorderDown: CHART_COLORS.FEEDBACK_NEGATIVE,
    colorsBorderUp: CHART_COLORS.FEEDBACK_POSITIVE,
  };
  constructor(
    private _fb: FormBuilder,
    private service: StockChartService,
    private stockChartModalService: StockChartModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this._fb.group(
      new ConfigModalCandle(this.configs.series?.candle)
    );
  }

  ngAfterViewInit() {
    const configuration: any =
      this.stockChartModalService.getGlobalChartConfigs();
    Object.keys(this.candleDefaultColors).forEach((key) => {
      let value = this.candleDefaultColors[key];
      if (key in configuration && configuration[key]) {
        const configValue = (configuration as any)[key];
        if (configValue != '') {
          value = (configuration as any)[key];
          //this.candleDefaultColors[key] = value;
        }
      }
      this.form.controls[key].setValue(value);
      setTimeout(() => {
        const element = document.getElementById(`${key}Colorpicker`);
        if (element) {
          element.style.backgroundColor = value;
        }
      }, 1);
    });
  }

  showBorder(value: boolean) {
    this.configs = objectAssign(this.configs, {
      series: { candle: { showBorder: value } },
    });
    this._sendConfigs(TYPE_CHART_UPDATE.CANDLE_BORDER);
  }

  private _sendConfigs(type: TYPE_CHART_UPDATE) {
    this.service.eventConfigs$.next({
      ref: this.refComponent,
      config: {
        ...this.configs,
        ...{
          colorsVelaDown: this.form.value['colorsVelaDown'],
          colorsVelaUp: this.form.value['colorsVelaUp'],
          colorsBorderDown: this.form.value['colorsBorderDown'],
          colorsBorderUp: this.form.value['colorsBorderUp'],
        },
      },
      save: false,
      global: true,
      typeUpdate: type,
    });
  }

  selectedColor(
    field: string,
    event: { color: color; typeColorUpdate?: TOOLS_TYPE_DRAW }
  ) {
    this.form.controls[field].setValue(event.color.rgbaHexColor);
    this.updateElementColor(field);
    this.stockChartModalService.updateGlobalChartConfigs({
      [field]: event.color.rgbaHexColor,
    });
    this._sendConfigs(TYPE_CHART_UPDATE.CANDLE_COLOR);
    this.color.emit({ field, event });
  }

  private updateElementColor(field: string) {
    const element = document.getElementById(`${field}Colorpicker`);
    if (element) {
      element.style.backgroundColor = this.form.value[field];
    }
  }

  updateAllElements() {
    Object.keys(this.candleDefaultColors).forEach((key) => {
      this.updateElementColor(key);
    });
  }
}
