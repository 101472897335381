import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import {
  RocketModalRef,
  RocketModalService,
  ROCKET_MODAL_DATA,
} from '@shared/rocket-components/components/index';
import {
  DrawTools,
  StyleTool,
} from '@shared/tiger-chart/draw-tools/draw-tools.interface';
import { OPTIONS_TYPE } from '@shared/tiger-chart/indicators/settings-modal/settings-modal.enum';
import { SaveConfigurationOptions } from '@shared/tiger-chart/indicators/settings-modal/settings-modal.interfaces';
import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { TOOLS_TYPE_DRAW } from '../../tiger-chart-tools.enum';
import { TIGER_CHART_TOOL } from '../../tiger-chart-tools.interface';
import { ColorsBaseService } from '../colors-base.service';
import { FONTSIZES } from '../toolbar-draw.const';
import { TYPE_VALUE } from '../toolbar-draw.enum';
import { color, ToolbarDrawConfigs } from '../toolbar-draw.interface';
import { ToolbarDrawService } from '../toolbar-draw.service';

@Component({
  selector: 'app-modal-config-draw',
  templateUrl: './modal-config-draw.component.html',
  styleUrls: ['./modal-config-draw.component.scss'],
})
export class ModalConfigDrawComponent
  extends RocketModalRef
  implements OnInit, AfterViewInit
{
  selectedTool!: TIGER_CHART_TOOL;
  fontSizes = FONTSIZES;
  fontSize = this.fontSizes[0];
  draw!: DrawTools;
  baseChart!: TWebAssemblyChart;
  text = 'Texto';
  hashSelect: any = {};
  toolsTypeDraw = TOOLS_TYPE_DRAW;
  selectedSaveConfiguration!: SaveConfigurationOptions;
  isSaveModel: boolean = false;
  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: any,
    service: RocketModalService,
    private drawToolsService: DrawToolsService,
    private colorsBaseService: ColorsBaseService,
    private toolbarDrawService: ToolbarDrawService
  ) {
    super(service);
    this.toolbarDrawService.showDrawConfigModal$.subscribe((data) => {
      if (data) {
        this.isSaveModel = false;
        this.updateBackdrop();
      }
    });
  }

  ngOnInit() {
    this.isSaveModel = false;
    this.selectedTool = this.data.selectedTool;
    this.text = this.selectedTool.text ? this.selectedTool.text : 'Texto';
    this.baseChart = this.drawToolsService.baseChartDic.get(
      this.selectedTool.dictId!!
    )!!;
    this.draw = this.baseChart.sciChartSurface.annotations.getById(
      this.selectedTool.dictId!!
    )!! as DrawTools;
    if (this.selectedTool.textConfiguration) {
      const font = this.fontSizes.find(
        (f) => f.value === this.selectedTool.textSize
      );
      this.fontSize = font!!;
    }
  }

  ngAfterViewInit() {
    this.setColorDivs();
  }

  setFontStyle(typeStyle: string) {
    if (typeStyle === 'bold') {
      this.selectedTool.textBold = !this.selectedTool.textBold;
    }
    if (typeStyle === 'italic') {
      this.selectedTool.textItalic = !this.selectedTool.textItalic;
    }
    if (typeStyle === 'text') {
      this.selectedTool.text = this.text;
    }
    if (typeStyle === 'fontsize') {
      this.selectedTool.textSize = this.fontSize.value;
    }
    this.updateTextStyle();
  }

  private updateTextStyle() {
    const rgbaBackgroundColor = this.colorsBaseService.hex2Rgba(
      this.selectedTool.backgroundColor!!
    );
    const rgbBackgroundColor =
      this.colorsBaseService.rgba2rgb(rgbaBackgroundColor);
    const hexBackgroundColor =
      this.colorsBaseService.rgba2hex(rgbBackgroundColor);
    const backgroundColor: color = {
      rgbColor: rgbBackgroundColor,
      rgbaColor: rgbaBackgroundColor,
      rgbaHexColor: this.selectedTool.backgroundColor!!,
      hexColor: hexBackgroundColor,
      selected: true,
    };

    const rgbaOutlineColor = this.colorsBaseService.hex2Rgba(
      this.selectedTool.outlineColor!!
    );
    const rgbOutlineColor = this.colorsBaseService.rgba2rgb(rgbaOutlineColor);
    const hexOutlineColor = this.colorsBaseService.rgba2hex(rgbOutlineColor);
    const outlineColor: color = {
      rgbColor: rgbOutlineColor,
      rgbaColor: rgbaOutlineColor,
      rgbaHexColor: this.selectedTool.outlineColor!!,
      hexColor: hexOutlineColor,
      selected: true,
    };

    const rgbaTextColor = this.colorsBaseService.hex2Rgba(
      this.selectedTool.textColor!!
    );
    const rgbTextColor = this.colorsBaseService.rgba2rgb(rgbaTextColor);
    const hexTextColor = this.colorsBaseService.rgba2hex(rgbTextColor);
    const textColor: color = {
      rgbColor: rgbTextColor,
      rgbaColor: rgbaTextColor,
      rgbaHexColor: this.selectedTool.textColor!!,
      hexColor: hexTextColor,
      selected: true,
    };
    const style: StyleTool = {
      textColor,
      backgroundColor,
      outlineColor,
      textBold: this.selectedTool.textBold,
      textItalic: this.selectedTool.textItalic,
      text: this.selectedTool.text,
      textSize: this.selectedTool.textSize,
    };
    this.drawToolsService.updateStyleDraw(this.draw, this.selectedTool, style);
    if (this.toolbarDrawService.hasElement()) {
      this.toolbarDrawService.updateSelectedTool(this.selectedTool);
    }
  }

  select(id: string) {
    if (id in this.hashSelect) {
      return true;
    }
    const element = document.getElementById(id)!! as HTMLInputElement;
    if (this.data.selectAllText) {
      element.select();
    } else {
      element.focus();
    }
    return;
  }

  selectedColor(
    typeStyle: string,
    event: { color: color; typeColorUpdate?: TOOLS_TYPE_DRAW }
  ) {
    if (typeStyle === 'text') {
      this.selectedTool.textColor = event.color.rgbaHexColor;
    }
    if (typeStyle === 'background') {
      this.selectedTool.backgroundColor = event.color.rgbaHexColor;
    }
    if (typeStyle === 'outline') {
      this.selectedTool.outlineColor = event.color.rgbaHexColor;
    }
    this.setColorDivs();
    this.updateTextStyle();
  }

  updateOpcional(fieldTool: any) {
    (this.selectedTool as any)[fieldTool] = !(this.selectedTool as any)[
      fieldTool
    ];
  }

  updateConfigUser(e: SaveConfigurationOptions) {
    if (e.cod === OPTIONS_TYPE.SAVE) {
      this.isSaveModel = true;
      this.updateBackdrop();
    }
    this.selectedSaveConfiguration = e;
    const option: ToolbarDrawConfigs = {
      title: e.label,
      hint: e.hint || '',
      typeValue: TYPE_VALUE.MODELS,
      value: e.cod,
    };
    this.toolbarDrawService.selectModelOption(option);
  }

  override close() {
    if (this.selectedSaveConfiguration && this.selectedSaveConfiguration.cod) {
      this.updateConfigUser(this.selectedSaveConfiguration);
    }
    super.close();
  }

  private updateBackdrop() {
    const backdrop = document.querySelector('.modal-backdrop.fade.show');
    if (backdrop) {
      const hidden = backdrop.getAttribute('hidden');
      if (isNullOrUndefined(hidden)) {
        backdrop.setAttribute('hidden', 'true');
      } else {
        if (JSON.parse(hidden!!)) {
          backdrop.removeAttribute('hidden');
        }
      }
    }
  }

  private setColorDivs() {
    if (this.selectedTool.textConfiguration) {
      const text = document.getElementById('colorpickerText');
      if (text) {
        text.style.backgroundColor = this.selectedTool.textColor!!;
      }
      const bg = document.getElementById('colorpickerBackground');
      if (bg) {
        bg.style.backgroundColor = this.selectedTool.backgroundColor!!;
      }
      const outline = document.getElementById('colorpickerOutline');
      if (outline) {
        outline.style.backgroundColor = this.selectedTool.outlineColor!!;
      }
    }
  }
}
