import {
  TTigerChartIndicatorCreateOptions,
  TTigerChartIndicatorMovingAverageColorOptions,
} from './indicators.types';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { TigerChartIndicatorMovingAverageColorBase } from './base/moving-average-color-base';
import { ColorsBaseService } from '../tiger-chart-tools/toolbar-draw/colors-base.service';

export class TigerChartIndicatorMovingAverageColor extends TigerChartIndicatorMovingAverageColorBase {
  constructor(
    options: TTigerChartIndicatorMovingAverageColorOptions,
    colorsBaseService: ColorsBaseService
  ) {
    super(options, colorsBaseService);
  }

  override create(
    options: TTigerChartIndicatorCreateOptions
  ): BaseRenderableSeries[] {
    this.updatePoints();
    return super.create(options);
  }

  override updatePoints(): void {
    const offset1: number[] = Array(this.timePeriod).fill(
      this.data[this.source][0]
    );
    const offset2: number[] = Array(this.timePeriod2).fill(
      this.data[this.source2][0]
    );
    this.points = this.service.call('MA', {
      inReal: [...offset1, ...this.data[this.source]],
      timePeriod: this.timePeriod,
    });
    this.points2 = this.service.call('MA', {
      inReal: [...offset2, ...this.data[this.source2]],
      timePeriod: this.timePeriod2,
    });
  }

  override updateSettings() {
    this.updatePoints();
    super.updateSettings();
  }
}
