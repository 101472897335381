<div
  class="round bg-neutral-strong position-absolute grid-config"
  [style.top.px]="position.top"
  [style.left.px]="position.left"
  [style.z-index]="99"
  [style.display]="isTour ? 'none' : 'block'"
  (mouseleave)="onFocusOut()"
>
  <div class="list-group list-group-flush">
    <div class="header-context-menu">
      <span>Lista de Ativos</span>
    </div>

    <button
      [disabled]="!keyHeader || blockInvisibleColumn"
      type="button"
      class="list-group-item list-group-item-action context-item-button"
      (click)="setInvisibleColumn()"
    >
      <fla-icon
        size="icon-size-tiny"
        css="position-absolute left-0 text-neutral-medium fs-4"
        name="visibility_off"
      ></fla-icon>
      Ocultar Coluna
    </button>
    <div class="d-flex flex-column">
      <button
        [disabled]="isNotListPersonal || keyHeader"
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="addStock()"
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="add"
        ></fla-icon>
        Adicionar ativos
      </button>
      <button
        [disabled]="!isRemoveRow || isNotListPersonal"
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="removeStock()"
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="remove"
        ></fla-icon>
        Remover Ativo
      </button>
      <button
        [disabled]="isNotListPersonal"
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="editGrid()"
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="edit"
        ></fla-icon>
        {{ isShowEdit ? 'Ocultar Edição' : 'Editar Lista' }}
      </button>
    </div>

    <div [id]="ELEMENT_IDS.STOCK_TABLE_TABLE_VIEW">
      <button
        [disabled]="isNotListPersonal"
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="configColumns()"
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="settings"
        ></fla-icon>
        Preferências
      </button>
      <button
        [disabled]="isNotListPersonal"
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="changeAction()"
      >
        <fla-icon
          size="icon-size-tiny"
          [css]="'position-absolute left-0 fs-4 ' + colorIconAuction"
          name="gavel"
        ></fla-icon>
        {{ showColumnsAuction ? 'Desabilitar Leilão' : 'Habilitar Leilão' }}
      </button>
    </div>
    <hr class="separator-context-menu" />
    <button
      type="button"
      class="list-group-item list-group-item-action border-none context-item-button"
      (click)="exportData()"
    >
      <fla-icon
        size="icon-size-tiny"
        css="position-absolute left-0 text-neutral-medium fs-4"
        name="download"
      ></fla-icon>
      Exportar Dados
    </button>
    <hr class="separator-context-menu" />
    <div class="d-flex flex-column">
      <button
        type="button"
        class="list-group-item list-group-item-action border-none fs-6 context-item-button"
        (click)="resetHeaders()"
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="refresh"
        ></fla-icon>
        Restaurar
      </button>
      <button
        type="button"
        class="list-group-item list-group-item-action border-none fs-6 context-item-button"
        (click)="showHelp()"
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="help_outline"
        ></fla-icon>
        Ajuda
      </button>
    </div>
  </div>
</div>
