<rocket-modal class="connect-partner-modal">
  <app-rocket-modal-body>
    <app-partner-connection-card
      [title]="title"
      [message]="message"
      [logo]="params.logo!"
      (clicked)="openConnectBrokerModal()"
    ></app-partner-connection-card>
  </app-rocket-modal-body>
</rocket-modal>
