import { StockListStettingsService } from '@services/api/nitro-ws/v1/stock-list-stettings.service';
import { Subject } from 'rxjs';
import { IListStockConfig } from 'src/app/core/interface';
import { HEADERS, HEADERS_AUCTION } from '../constants/headers.constants';

export class SearchConfig {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private stockListStettingsService: StockListStettingsService) {}

  private onDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public getConfigListStock(
    idStockList: number,
    isAction: boolean = false
  ): Promise<IListStockConfig> {
    return new Promise((resolve) => {
      this.stockListStettingsService.getByIdConfigList(idStockList).subscribe({
        next: (res: IListStockConfig | null) => {
          this.onDestroy();
          resolve(res!);
        },
        error: (error: any) => {
          console.error(error);
          this.createListStockConfig(idStockList, resolve, isAction);
        },
      });
    });
  }

  private createListStockConfig(
    idStockList: number,
    resolve: any,
    isAction: boolean = false
  ): void {
    const newConfigList: IListStockConfig = {
      idStockList: idStockList,
      metadata: {
        headers: !isAction ? HEADERS : this.configHeadersAuction(),
      },
    };
    this.stockListStettingsService.createConfig(newConfigList).subscribe({
      next: (res: IListStockConfig | boolean) => {
        let configList!: any;
        if (res) {
          configList = res as IListStockConfig;
        } else {
          configList = {
            idStockList: idStockList,
            metadata: {
              headers: !isAction ? HEADERS : this.configHeadersAuction(),
            },
          };
        }
        this.onDestroy();
        resolve(configList);
      },
      error: () => {
        const configList = {
          idStockList: idStockList,
          metadata: {
            headers: !isAction ? HEADERS : this.configHeadersAuction(),
          },
        };
        this.onDestroy();
        resolve(configList);
      },
    });
  }

  private configHeadersAuction() {
    let headers: any = {};
    Object.keys(HEADERS).forEach((item: string) => {
      if (item === 'cd_stock') {
        headers = { [item]: HEADERS[item], ...HEADERS_AUCTION };
      } else {
        headers[item] = HEADERS[item];
        headers[item].hide = true;
      }
    });
    return headers;
  }
}
