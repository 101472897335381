import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
  ViewChild,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  computed,
  Signal,
} from '@angular/core';
import { Subject, Subscription, filter, tap } from 'rxjs';
import { TABS_REF, TABS_TYPE } from '../constants/orders-history.const';
import { ElementRef, AfterViewInit } from '@angular/core';
import { OrdersTabService } from '../service/orders-tab.service';
import { IWorkSpaceComponet } from 'src/app/core/interface';
import { AlertService } from '@shared/services/api/trademap/v1/alert.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { OrdersHistoryService } from '../service/orders-history.service';
import { deepClone } from '@shared/rocket-components/utils';
import { ITabHandler } from '../interfaces/orders-history.interface';
import { ORDERS_HISTORY_ELEMENT_IDS } from '../enum/orders-history.enum';
import { HomeService } from '@modules/home/service/home.service';
import { CustodyChannel } from '@shared/channel/custody.channel';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { OrdersService } from '@shared/services/orders.service';
import { INFINITY_READ_STREAM } from '@shared/constants/general.contant';
import { OrdersHistoryConfigService } from '../service/orders-history-config.service';
import { TOOLS_LIST } from '@core/workspace';

@Component({
  selector: 'app-orders-history-header',
  templateUrl: 'orders-history-header.component.html',
  styles: [
    `
      .drag-box {
        cursor: move;
      }

      .cdk-drag-preview {
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
          0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
        
      }

      li.cdk-drag-preview {
        list-style-type: none;
      }

      .cdk-drag-placeholder {
        opacity: 0;
      }

      .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }

      .cdk-drop-list-dragging .drag-box:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersHistoryHeaderComponent
  extends ReadStreamBase
  implements OnDestroy, AfterViewInit, OnChanges
{
  @ViewChild('tabs') contextMenuTabs!: ElementRef;

  @Output() eventChangeTab: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleHeaderTabsPreference: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() resetGrid: EventEmitter<string> = new EventEmitter<string>();
  @Output() viewChanged: EventEmitter<string> = new EventEmitter<string>();
  @Input() idBrokerSelected!: any;
  @Input() isDayTrade: boolean = false;
  @Input() refComponent!: string;
  @Input() componentId!: string;
  @Input() component!: IWorkSpaceComponet;
  @Input() set positionQtty(qtty: any) {
    this.qttyCount.set(TABS_REF.POSITION, qtty);
  }
  @Input() ordersHistoryTab: any;
  @Input() isCardView!: boolean;
  private untilDestroy$ = new Subject<void>();
  private _subscriptions$!: Subscription[];
  private _tabClicked!: any;
  private _custodySnapshot!: (items: any) => void;
  selectedTabIndex: number = -1;
  accOptions: AccountSelectorOptions = { useConsolidated: true };
  qttyCount = new Map();
  qtty: any = {};
  headerTabs: Signal<any[]> = computed(() => []);
  headerTabsHash: any = {};
  showAccountSelect: boolean = true;
  canLoadAccountSelector: boolean = false;
  _activeTab: any;
  elementIDS = ORDERS_HISTORY_ELEMENT_IDS;
  isCompactMode = false;
  private _resize$!: ResizeObserver;
  private componentWidth = 0;
  private _headersBeforeTour: any = {
    tabs: [],
    activeTab: null,
  };

  get getHeaderTabs() {
    return this.headerTabs()
  }

  get selectedHeaderTab() {
    return this.headerTabs()[this.selectedTabIndex]
  }

  constructor(
    private _ordersTabService: OrdersTabService,
    private _alertsService: AlertService,
    private _ordersHistoryService: OrdersHistoryService,
    private _homeService: HomeService,
    private _ordersService: OrdersService,
    private custodyChannel: CustodyChannel,
    private cdr: ChangeDetectorRef,
    private _configService: OrdersHistoryConfigService
  ) {
    super();
    this.createWorker();
    this.headerTabs = computed(() => {
      const config = this._configService.getComponentConfig(this.refComponent);
      return config ? config().headerTabs : [];
    });
  }

  ngAfterViewInit(): void {
    this._initObservables();
  }

  ngOnDestroy(): void {
    this.untilDestroy$.next();
    this.untilDestroy$.complete();
    this.contextMenuTabs?.nativeElement &&
    this._resize$.unobserve(this.contextMenuTabs.nativeElement);
    this._subscriptions$.forEach((sub) => sub.unsubscribe());
    this.worker && this.worker.terminate();
  }
  private worker!: Worker;

  private createWorker(): void {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(
        new URL('./header-total.worker', import.meta.url)
      );
      this.worker.onmessage = this.onMessageWorkerCallback;
    } else {
      alert('Atualize o navegador ou instale um mais moderno como o Chrome!');
    }
  }

  handleHeadersPreferences = (data: any) => {
    const tabs = [...data];
    const orderedKeys = this.getHeaderTabs
      .filter((tab) => tabs.includes(tab.key))
      .map((tab) => tab.key);
    const nonMatchingKeys = tabs.filter((key) => !orderedKeys.includes(key));
    const finalOrderedKeys = orderedKeys.concat(nonMatchingKeys);
    const newtabs = this._ordersTabService.buildHeaderTabs(finalOrderedKeys);

    this._configService.setConfig(this.refComponent, {
      headerTabs: newtabs,
    });

    this.buildHeaderTabsHash();
    const activeInTab = tabs.includes(this.activeTab.key);
    if (!activeInTab) {
      this.changeTab(newtabs[0], 0, true);
    } else {
      this._updateHeaderKeysInMetadata();
    }
    this.setHeaderCompact()
    this.cdr.detectChanges();
  };

  setValuesHeader(totais: any) {
    Object.keys(totais).forEach((item) => {
      this.qtty[item] = totais[item];
    });
    this.cdr.detectChanges();
  }

  onMessageWorkerCallback = ({ data }: any): void => {
    this.setValuesHeader(data.totais);
  };

  threadTotalHandler = (orders: any, type: string) => {
    this.worker.postMessage({
      messageData: orders,
      type,
      idBrokerSelected: this.idBrokerSelected,
      isDayTrade: this.isDayTrade,
    });
  };

  get componentMetadata() {
    return this.component.metadata.component;
  }

  buildHeaderTabs = () => {
    const tabs =
      this.componentMetadata?.headerTabs ||
      this._ordersTabService.defaultHeaderKeys;

    const tabUsing = this.isDayTrade
      ? TABS_TYPE.INTRADIA
      : TABS_TYPE.SWINGTRADING;
    const tabUnsing = this.isDayTrade
      ? TABS_TYPE.SWINGTRADING
      : TABS_TYPE.INTRADIA;
    const indexUnsing = tabs.findIndex((tab: string) => tab === tabUnsing);
    if (indexUnsing > -1) {
      tabs.splice(indexUnsing, 1);
    }
    if (!tabs.includes(tabUsing)) {
      tabs.unshift(tabUsing);
    }

    const newtabs = this._ordersTabService.buildHeaderTabs(tabs);
    this._configService.setConfig(this.refComponent, {
      headerTabs: newtabs,
    });

    this.buildHeaderTabsHash();
    const currentTabIndex = newtabs.findIndex(
      (tab) => tab.key === this.componentMetadata?.lastActiveTab
    );
    this.selectedTabIndex = currentTabIndex;
    this.activeTab = newtabs[this.selectedTabIndex];
    this.setHeaderCompact()
  };

  ngOnChanges(changes: SimpleChanges): void {
    const { component, ordersHistoryTab, idBrokerSelected, isDayTrade } =
      changes;
    if (
      component &&
      component.currentValue &&
      component.currentValue.metadata &&
      component.currentValue.metadata.component
    ) {
      this.buildHeaderTabs();
      this._updateTabsByComponentRef();
    }
    ordersHistoryTab && this.handleActiveTab(ordersHistoryTab.currentValue);

    if (idBrokerSelected && !idBrokerSelected.firstChange) {
      this.keepReading = false;
      this.updateContador();
      this._readCustodyStream();
      this.updateTabsLabels();
    }
    if (isDayTrade) {
      this.updateContador();
      this.updateTabsLabels();
    }
  }

  set activeTab(activeTab: any) {
    this._activeTab = activeTab;
  }

  get activeTab() {
    return this._activeTab;
  }

  private _initObservables(): void {
    // Colocar isso dentro de um webworker
    const _orderChannelTotal$ = this._ordersService
      .onOrdersCount()
      .pipe(
        tap(() => {
          this.setValuesHeader({});
        }),
        filter((pos: any) => {
          return pos;
        })
      )
      .subscribe((data: any) => {
        this.threadTotalHandler(data, 'ORDERS');
      });
    this._ordersService.emitTotalRecount();

    this._readCustodyStream();

    const _alertCount$ = this._alertsService.updateAlertsQty.subscribe(
      (qty) => {
        this.qttyCount.set('alerts', qty);
        this.threadTotalHandler(this._alertsService.allAlerts(), 'ALERTS');
      }
    );

    const _tabOrdenation$ = this._ordersTabService.ordersTab$
      .pipe(
        filter(
          (params: ITabHandler) => params.refComponent === this.refComponent
        )
      )
      .subscribe((response) => {
        const { tabs, isTour, closedTour } = response;
        if (closedTour) {
          this.headerTabs = this._headersBeforeTour.tabs;
          this.activeTab = this._headersBeforeTour.activeTab;
          this._updateTabsByComponentRef();
        } else {
          if (isTour) {
            this._headersBeforeTour = {
              tabs: this.getHeaderTabs,
              activeTab: this.activeTab,
            };
          } else {
            tabs[0] = this.getHeaderTabs[0];
          }
          const currentTabIndex = tabs.findIndex(
            (tab) => tab.key === this.activeTab.key
          );
          if (currentTabIndex < 0) this.changeTab(tabs[0], 0, true);
          else this.selectedTabIndex = currentTabIndex;

          this._configService.setConfig(this.refComponent, {
            headerTabs: tabs,
          });
          this.updateContador();
          this._updateTabsByComponentRef();
          this.updateTabsLabels();
          if (!isTour) this._updateHeaderKeysInMetadata();
        }
        this.buildHeaderTabsHash();
        this.setHeaderCompact()
      });

    const _resetTab$ = this._ordersHistoryService.resetGridContent$
      .pipe(filter((reset: boolean) => reset))
      .subscribe(this.resetOrdersHistoryContent);

    const _onTabHidden$ = this._configService.onTabHide$.subscribe(
      ({ tabInfs, componentRef }) => {
        if (componentRef == this.refComponent) this.hiddenTab(tabInfs);
      }
    );

    this._resize$ = new ResizeObserver((entries) => {
      this.componentWidth = entries[0].contentRect.width;
      this.setHeaderCompact();
    });
    this._resize$.observe(this.contextMenuTabs.nativeElement);

    this._subscriptions$ = [
      _alertCount$,
      _tabOrdenation$,
      _resetTab$,
      _orderChannelTotal$,
      _onTabHidden$,
    ];
  }

  private _readCustodyStream() {
    this.custodyChannel.readEvents().then(async (data) => {
      this._custodySnapshot = data.snapshot;
      this._custodySnapshot(this.custodyChannel.itemsArray);
      this.setValuesHeader({});
      this.keepReading = INFINITY_READ_STREAM;
      this.readStream(data.stream, (payload: Map<string, any>) => {
        const items = payload.get(this.custodyChannel.itemsArray[0]);
        items?.forEach((data: any) => {
          if (!data || data.isEndOfSnap) return;
          this.threadTotalHandler(data, 'CUSTODY');
        });
      });
    });
  }

  setHeaderCompact = () => {
    if(this.getHeaderTabs.length){
      const visibleTabs = this.getHeaderTabs.length
      this.isCompactMode = this.componentWidth < (250 + (visibleTabs * 90));
    }
    this.cdr.detectChanges()
  }

  openPerformanceReport() {
    const tool = TOOLS_LIST.get('PERFORMANCE_REPORT');
    tool.link = this.component.metadata.headerOptions.link;
    this._homeService.addComponent(tool);
  }

  onRightClick = (event: any, tabInfs: any): void => {
    this._tabClicked = tabInfs;

    const config = {
      event: { clientX: event.x, clientY: event.y },
      rowSelect: null,
      isVisible: true,
      keyHeader: null,
      isShowEdit: false,
      hasExportBtn: true,
    };
    this._configService.openHeaderTabsContextMenu(
      this.refComponent,
      config,
      tabInfs
    );
  };

  private resetOrdersHistoryContent = (
    onlyResetTabContent: boolean = false
  ) => {
    !onlyResetTabContent &&
      this._ordersHistoryService.resetOrdersHistoryPreferences(
        this.componentId
      );

    const currentTabs = this.getHeaderTabs;
    const hasPosition = currentTabs.find(
      (tab) =>
        tab.ref === TABS_REF.POSITION || tab.ref === TABS_REF.SWINGTRADING
    );
    if (hasPosition) {
      const headerTabs = this._ordersTabService.defaultHeaderTabs.map(
        (tab) => ({ ...tab })
      );
      this._configService.setConfig(this.refComponent, {
        headerTabs,
      });
    } else {
      const headerTabs = deepClone(
        this._ordersTabService.defaultHeaderTabs.filter(
          (tab) => tab.ref !== TABS_REF.POSITION
        )
      );
      this._configService.setConfig(this.refComponent, {
        headerTabs,
      });
    }
    this.buildHeaderTabsHash();
    this._updateHeaderKeysInMetadata();
    this.selectedTabIndex = this._ordersTabService.getTabActiveIndex(
      this.componentId
    );
    this._updateTabsByComponentRef();
    this._updateActiveTabIndex();
    this.setHeaderCompact()
    this.resetGrid.emit(this._tabClicked.key);
  };

  changeTab = (tab: any, index: number, forceChange: boolean = false): void => {
    if (this.selectedTabIndex === index && !forceChange) return;
    this.activeTab = tab;
    this.selectedTabIndex = index;
    this.eventChangeTab.emit(tab);
    this._updateHeaderKeysInMetadata();
    this.cdr.detectChanges();
  };

  public changeOrderHistoryChange() {
    this.isCardView = !this.isCardView
    this.viewChanged.emit()
  }

  handleOnChangeTabsOrdenation(event: any) {
    const headerTabs = this.getHeaderTabs;
    moveItemInArray(headerTabs, event.previousIndex, event.currentIndex);
    this._configService.setConfig(this.refComponent, {
      headerTabs,
    });
    this._updateActiveTabIndex();
    this._updateHeaderKeysInMetadata();
    this.buildHeaderTabsHash();
    this.cdr.detectChanges();
  }

  hiddenTab = (tabToHidden: any) => {
    const headerTabs = this.getHeaderTabs.filter(
      (tab: any) => tab.key !== tabToHidden.key
    );

    this._configService.setConfig(this.refComponent, {
      headerTabs,
    });
    if (this.activeTab.key === tabToHidden.key) {
      this.changeTab(headerTabs[0], 0, true);
    } else {
      this._updateActiveTabIndex();
    }
    this._updateHeaderKeysInMetadata();
    this._updateTabsByComponentRef();
    this.buildHeaderTabsHash();
    this.setHeaderCompact()
  };

  private _updateActiveTabIndex(): void {
    const index = this.getHeaderTabs.findIndex(
      (tab) => tab.key === this.activeTab.key
    );
    this.selectedTabIndex = index;
    this.cdr.detectChanges();
  }

  private _getHeaderKeys(): string[] {
    return this.getHeaderTabs.map((tab) => {
      return tab.key;
    });
  }

  handleActiveTab(tabToDisplay: any): void {
    const config = this._configService.getComponentConfig(this.refComponent);
    const headerTabs = config && config().headerTabs;
    if (!tabToDisplay || !headerTabs) return;
    const tab = this.headerTabsHash[tabToDisplay.key];
    const tabIndex = tab ? tab.index : -1;
    if (tabIndex === -1) {
      headerTabs.unshift(tabToDisplay);
      this._configService.setConfig(this.refComponent, {
        headerTabs,
      });
      this.selectedTabIndex = 0;
      this.buildHeaderTabsHash();
    } else {
      this.selectedTabIndex = tabIndex;
    }
    if (
      tabToDisplay.key === TABS_TYPE.SWINGTRADING ||
      tabToDisplay.key === TABS_TYPE.INTRADIA
    ) {
      this.updateTradingView(TABS_TYPE.SWINGTRADING, true);
      this.updateTradingView(TABS_TYPE.INTRADIA, true);
      this.updateTradingView(tabToDisplay.key, false);
      this.cdr.detectChanges();
    }
    this.activeTab = tabToDisplay;
    this._updateHeaderKeysInMetadata();
    this.updateTabsLabels();
  }

  private updateTradingView(key: string, hidden: boolean) {
    const tab = this.headerTabsHash[key];
    if (tab) {
      const headerTabs = this.getHeaderTabs;
      headerTabs[tab.index].isHidden = hidden;

      this._configService.setConfig(this.refComponent, {
        headerTabs,
      });
    }
  }

  private _updateHeaderKeysInMetadata(): void {
    const headerKeys = this._getHeaderKeys();
    const headerTabs = this.componentMetadata.headerTabs;
    const isSameHeaders = headerKeys.join() === headerTabs?.join();
    const isSameActiveKey =
      this.componentMetadata.lastActiveTab === this.activeTab.key;
    if (!isSameActiveKey || !isSameHeaders) {
      this.componentMetadata.headerTabs = headerKeys;
      this.componentMetadata.lastActiveTab = this.activeTab.key;
      this.saveTabHeadersOrdenation(this.component);
    }
  }

  saveTabHeadersOrdenation(componentMetadata: any): void {
    this._homeService.updateMeta(componentMetadata);
  }

  private _updateTabsByComponentRef(): void {
    this._ordersTabService.tabsByComponentRef = {
      ref: this.refComponent,
      tabs: this.getHeaderTabs,
    };
  }

  private updateContador() {
    const headerTabs = this.getHeaderTabs;
    headerTabs.forEach((tab) => {
      this.qtty[tab.ref] = 0;
      this.qttyCount.set(tab.ref, this.qtty[tab.ref]);
    });
    !!this._custodySnapshot &&
      this._custodySnapshot(this.custodyChannel.itemsArray);
    this.threadTotalHandler(this._alertsService.allAlerts(), 'ALERTS');
  }

  private buildHeaderTabsHash() {
    this.headerTabsHash = {};
    const tabs = this.getHeaderTabs;

    tabs.forEach((tab, i) => {
      this.headerTabsHash[tab.key] = { ...tab, index: i };
    });
    this.cdr.detectChanges();
  }

  private getTradeModeLabel(index: number) {
    const headerTabs = this.getHeaderTabs;
    const item = headerTabs ? headerTabs[index] : {};
    if (item?.dayTradeText)
      return DaytradeService.getTextWithTradeMode(item.title);
    else return item?.title ?? '';
  }

  private updateTabsLabels = () => {
    const config = this._configService.getComponentConfig(this.refComponent);
    const tabs = config && config().headerTabs;
    if (!tabs) return;

    const headerTabs = tabs.map((item, i) => {
      const label = this.getTradeModeLabel(i);
      return { ...item, label };
    });

    this._configService.setConfig(this.refComponent, {
      headerTabs,
    });
    this.cdr.detectChanges();
  };
}

export interface AccountSelectorOptions {
  sortBy?: any[];
  useConsolidated?: any;
}
