export const CARD_FIELDS = {
  SWINGTRADING: {
    qttyFinal: 'qtty_final_swing',
    pl: 'pl_swing',
    netDay: 'net_day_swing',
    vlPriceAvg: 'vl_price_avg_swing',
    pnlOpen: 'pnl_open_swing',
    pnlDay: 'pnl_day_swing',
    pnlTotal: 'pnl_swing',
  },
  INTRADIA: {
    qttyFinal: 'qtty_final_daytrade',
    pl: 'pl_daytrade',
    netDay: 'net_day_daytrade',
    vlPriceAvg: 'vl_price_avg_daytrade',
    pnlOpen: 'pnl_open_daytrade',
    pnlDay: 'pnl_day_daytrade',
    pnlTotal: 'pnl_daytrade',
  },
  POSITION: {
    qttyFinal: 'qtty_final',
    pl: 'pl',
    netDay: 'net_day',
    vlPriceAvg: 'vl_price_avg',
    pnlOpen: 'pnl_open',
    pnlDay: 'pnl_day',
    pnlTotal: 'pnl',
  }
}