import { CommonModule } from '@angular/common';
import { Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { BrokerLogoComponent } from '@modules/broker-connection/components/broker-logo/broker-logo.component';
import { StandaloneModalsService } from '@shared/modals/standalone-modals.service';
import {
  ROCKET_MODAL_DATA,
  RocketModalModule,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { AuthService, ToastService } from '@shared/services';
import { TrademapCDNService } from '@shared/services/api/cdn/trademap-cdn.service';
import { TermsService } from '@shared/services/api/terms/v2/terms.service';
import { BrokerService } from '@shared/services/api/trademap/v1/broker.service';
import { IBrokerConnectionInfos } from '@shared/services/api/trademap/V9/models/brokers-connection-infos.interface';
import { TrademapV9Service } from '@shared/services/api/trademap/V9/trademap.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-broker-partner-already-connected',
  templateUrl: './broker-partner-already-connected.component.html',
  styles: [
    `
      .btn-min-width {
        min-width: 154px;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, RocketModalModule, BrokerLogoComponent],
})
export class BrokerPartnerAlreadyConnectedComponent
  extends RocketModalRef
  implements OnInit, OnDestroy
{
  public broker!: IBrokerConnectionInfos;
  public showBrokerRequirements: boolean = false;
  public showRTPassword: boolean = false;
  public hasTerm: boolean = false;
  public error: boolean = false;
  public isLoading: boolean = false;
  public password: string = '';
  public errorMsg: string = '';
  public brokerLogo: SafeResourceUrl = '';
  public trialDuration: number = 30;

  private _authService = inject(AuthService);
  private _termsService = inject(TermsService);
  private _standaloneModalsService = inject(StandaloneModalsService);
  private _brokerService = inject(BrokerService);
  private _trademapV9Service = inject(TrademapV9Service);
  private _trademapCDNService = inject(TrademapCDNService);
  private _toast = inject(ToastService);

  constructor(
    @Inject(ROCKET_MODAL_DATA)
    public data: { idBroker: number; trialDuration: string },
    service: RocketModalService
  ) {
    super(service);
  }

  ngOnInit() {
    this._getBrokerInfos();
  }

  private _getBrokerInfos(): void {
    this._trademapV9Service
      .getBrokerConnectionInfos(this.data.idBroker)
      .subscribe({
        next: (response) => {
          this.broker = response;
          this.hasTerm = Boolean(response.term?.idTerm);
          this._downloadBrokerLogo();
        },
        error: () => {
          this.logout();
          this._toast.showToast(
            'error',
            'Ocorreu um erro ao carregar as informações da corretora selecionada, tente novamente.'
          );
        },
      });
  }

  private _downloadBrokerLogo(): void {
    this._trademapCDNService
      .getBrokerLogo(this.broker.brokerLogo)
      .pipe(filter((logo) => logo !== null))
      .subscribe((logo) => (this.brokerLogo = logo!));
  }

  public logout(): void {
    this._authService.performLogout({ reason: 'USER_COMMAND' });
  }

  public setRTPassword(event: any): void {
    this.password = event.target.value;
  }

  public handleConnection() {
    if (this.hasTerm && !this.password?.length) return;
    this.isLoading = true;
    this.error = false;
    this.errorMsg = '';
    this._brokerService
      .loginMultibroker(this.broker.idBroker, {
        hasBrokerConnected: true,
      })
      .subscribe({
        next: () => {
          this._brokerService.getBrokers(true).subscribe();
          if (this.hasTerm) {
            this._assignBrokerTerm();
            return;
          }
          this.closeModal();
        },
        error: (error) => {
          this.error = true;
          this.isLoading = false;
          console.error('ERROR_ON_LOGIN_BROKER', error);
        },
      });
  }

  private _assignBrokerTerm(): void {
    this._termsService
      .approveTerm(this.broker.term!.idTerm, this.password, true)
      .subscribe({
        next: () => this.closeModal(),
        error: (error) => {
          this.isLoading = false;
          this.error = true;
          this.errorMsg =
            error.message ?? 'Ocorreu um erro ao aceitar os termos';
        },
      });
  }

  public closeModal(): void {
    this._standaloneModalsService.close();
  }
}
