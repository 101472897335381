/* eslint-disable @angular-eslint/component-selector */
import {
  Component,
  forwardRef,
  Input,
  Injector,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { randomId } from '../../utils';
import { MESSAGES } from '../../utils/message.const';

import { FlaTType } from './types/input.types';
import { BaseComponent } from '@shared/rocket-components/base-componentes';
import { FlaTMargin } from '../../types/index';
import { InputCountService } from '@shared/rocket-components/input-count/input-count.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fla-input',
  templateUrl: './input.component.html',
  styles: [
    `
      :host {
        display: contents;
        input[type='date']::-webkit-calendar-picker-indicator {
          color-scheme: dark;
        }
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FlaInputComponent),
    },
  ],
})
export class FlaInputComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Input() css: string = '';
  @Input() cssInput: string = '';
  @Input() labelPlacement: 'vertical' | 'horizontal' = 'vertical';
  @Input() margin: FlaTMargin = 'mb-5';
  @Input() placeholder: string = '';
  @Input() ariaDescribedby: string = '';
  @Input() hasError: string = '';
  @Input() flaMask!: string;
  @Input() showMaskTyped: boolean = false;
  @Input() selectOnFocus: boolean = false;
  @Input() allowNegativeNumbers: boolean = false;
  @Input() label!: string;
  @Input() hint!: string;
  @Input() decidePosition!: string;
  @Input() requiredText: string = MESSAGES['requiredText'];
  @Input() type: FlaTType = 'text';
  @Input() isRequired: boolean = false;
  @Input() isSmall: boolean = false;
  @Input() max: number = 9999;
  @Input() min: number = 0;
  @Input() maxLength: number = 9999;
  @Input() minLength: number = 0;

  @Input() set isDisable(disabled: boolean) {
    this.disable?.next(disabled);
  }
  @Input() focusOnInit: boolean = false;
  @Input() autoComplete: boolean = true;
  @Input() name: string = '';
  @Input() preventPaste: boolean = false;

  @Output() flaInput = new EventEmitter<any>();
  @Output() flaChange = new EventEmitter<any>();
  @Output() flaBlur = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onRefIdCreated = new EventEmitter<string>();
  @Output() flaKeyDown = new EventEmitter<any>();
  @Output() flaKeyUp = new EventEmitter<any>();
  @ViewChild('inputField') private input!: ElementRef<HTMLInputElement>;
  public refId: string = randomId('fla_input');
  private blurElements!: Subscription;

  constructor(
    injector: Injector,
    cdr: ChangeDetectorRef,
    private inputCountService: InputCountService
  ) {
    super(injector, cdr);
  }

  ngOnInit() {
    this.blurElements = this.inputCountService.blurAllElements.subscribe(() => {
      this.input.nativeElement.blur();
    });
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (this.focusOnInit) this.input.nativeElement.focus();
    this.onRefIdCreated.emit(this.refId);
  }

  override ngOnDestroy = () => {
    super.ngOnDestroy();
    this.blurElements && this.blurElements.unsubscribe();
  };

  public preventPasteText(event: ClipboardEvent): void {
    this.preventPaste && event.preventDefault();
  }

  setFocus() {
    this.input.nativeElement.focus();
  }
}
