<workspace-doc-header
  css="w-100 d-flex header-wrapper border-bottom"
  [item]="component"
>
  <fla-workspace-doc-header-item [enableContent]="true">
    <a
      type="button"
      class="nav-link p-2 lh-sm round-top"
      [ngClass]="{
        'text-brand-primary': currentContent === PERFORMANCE_CONTENT.INFOS
      }"
      (click)="tabHandler(PERFORMANCE_CONTENT.INFOS)"
    >
      Performance
    </a>
  </fla-workspace-doc-header-item>
  <fla-workspace-doc-header-item [enableContent]="true">
    <a
      type="button"
      class="nav-link p-2 lh-sm round-top"
      [ngClass]="{
        'text-brand-primary': currentContent === PERFORMANCE_CONTENT.CHART
      }"
      (click)="tabHandler(PERFORMANCE_CONTENT.CHART)"
    >
      Gráfico
    </a>
  </fla-workspace-doc-header-item>
  <fla-workspace-doc-header-item [enableContent]="true">
    <app-dropdown>
      <a
        type="button"
        class="nav-link p-2 lh-sm round-top dropdown-toggle"
        appDropdownButton
        [rtDropnAutoClose]="true"
        [rtDropnClose]="dropnAutoClose"
      >
        {{ accountSelectedLabel() }}
      </a>
      <div class="list-max-height" *ngIf="accounts">
        <div
          *ngFor="let account of accounts"
          [title]="account.label"
          (click)="filterByBroker(account)"
        >
          <a class="dropdown-item" type="button">
            {{ account.label }}
          </a>
        </div>
      </div>
    </app-dropdown>
  </fla-workspace-doc-header-item>
  <fla-workspace-doc-header-item
    [enableContent]="true"
    *ngIf="typesOperation.length"
  >
    <app-dropdown>
      <a
        type="button"
        class="nav-link p-2 lh-sm round-top dropdown-toggle"
        appDropdownButton
        [rtDropnAutoClose]="true"
        [rtDropnClose]="dropnAutoClose"
      >
        {{ typesOperationLabels[typeOperationSelected] || defaultStock }}
      </a>
      <div class="list-max-height" *ngIf="typesOperation.length">
        <ng-container *ngFor="let type of typesOperation">
          <div
            *ngIf="typesOperationLabels[type]"
            [title]="typesOperationLabels[type]"
            (click)="filterByTypes(type)"
          >
            <a class="dropdown-item" type="button">
              {{ typesOperationLabels[type] }}
            </a>
          </div>
        </ng-container>
      </div>
    </app-dropdown>
  </fla-workspace-doc-header-item>
  <fla-workspace-doc-header-item
    [enableContent]="true"
    *ngIf="stockListFiltered.length"
  >
    <app-dropdown>
      <a
        type="button"
        class="nav-link p-2 lh-sm round-top dropdown-toggle"
        appDropdownButton
        [rtDropnAutoClose]="true"
        [rtDropnClose]="dropnAutoClose"
      >
        {{ stockSelected || defaultStock }}
      </a>
      <div class="list-max-height" *ngIf="stockListFiltered.length">
        <div
          *ngFor="let stock of stockListFiltered"
          [title]="stock"
          (click)="filterByStock(stock)"
        >
          <a
            [ngClass]="{ 'border-bottom': stock === defaultStock }"
            class="dropdown-item"
            type="button"
          >
            <input
              *ngIf="false"
              [checked]="checkedStocks.has(stock)"
              class="form-check-input cursor-pointer"
              type="checkbox"
            />
            {{ stock }}
          </a>
        </div>
      </div>
    </app-dropdown>
  </fla-workspace-doc-header-item>
  <ng-container *ngIf="selectedPeriod">
    <fla-workspace-doc-header-item [enableContent]="true">
      <app-dropdown>
        <a
          type="button"
          class="nav-link p-2 lh-sm round-top dropdown-toggle"
          appDropdownButton
          [rtDropnAutoClose]="true"
          [rtDropnClose]="dropnAutoClose"
        >
          {{ selectedPeriod.label }}
        </a>
        <div class="list-max-height">
          <div
            *ngFor="let periods of periodsOptions"
            [title]="periods.text"
            (click)="changePerformancePeriod(periods)"
          >
            <a class="dropdown-item" type="button">
              {{ periods.text }}
            </a>
          </div>
        </div>
      </app-dropdown>
    </fla-workspace-doc-header-item>
  </ng-container>
</workspace-doc-header>
