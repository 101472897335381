import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { IWorkSpaceComponet } from '@core/interface';
import { INewsGroup } from '@core/interface/news-agency.interface';
import { isWebViewContext } from 'src/app/desktop/integration.utils';

@Component({
  selector: 'app-news-header',
  templateUrl: './news-header.component.html',
  styleUrls: ['./news-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsHeaderComponent implements OnChanges {
  @Input() refComponent!: string;
  @Input() linked!: boolean;
  @Input() newsGroups!: INewsGroup[];
  @Input() selectedTab: number = 0;
  @Input() width!: any;
  @Input() component!: IWorkSpaceComponet;
  @Output() tabChange: EventEmitter<number> = new EventEmitter<number>();
  public isDesktop = false;
  public isCompactMode = false;
  public indexTab = 0;

  constructor(private cdr: ChangeDetectorRef) {
    this.isDesktop = isWebViewContext();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { width, newsGroups, selectedTab } = changes;
    width?.currentValue && this.setHeaderCompact();
    if (
      newsGroups?.currentValue ||
      (selectedTab?.currentValue && !!this.newsGroups)
    ) {
      this.getIndexTabSelected();
    }
  }

  changeTab(tab: number) {
    this.tabChange.emit(tab);
  }

  private setHeaderCompact(): void {
    if (this.width) {
      this.isCompactMode = parseInt(this.width) < 570;
    }
    this.cdr.detectChanges();
  }

  private getIndexTabSelected(): void {
    this.indexTab = this.newsGroups.findIndex(
      (item: INewsGroup) => item.id_news_group === this.selectedTab
    );
  }
}
