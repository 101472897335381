import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ISearchStock } from '@core/interface';
import { RESUMES } from '../business-profile.const';
import { SummaryService } from '@shared/services/api/trademap/V2/summary.service';
import { BusinessProfileService } from '../business-profile.service';
import { DocumentsService } from '@shared/services/api/trademap/v1/documents.service';
import {
  ICorporateEvents,
  ILaminaSectionStatistics,
  IListDocuments,
  IResumeList,
} from '../business-profile.interface';
import { map } from 'rxjs';
import { TrademapNewsService } from '@shared/services/api/trademap/V8/news.service';
import { INewsInfos, INewsV8 } from '@core/interface/news-agency.interface';
import { TrademapCalendarsService } from '@shared/services/api/trademap/v1/calendars.service';
import { VMDateToFriendlyDate } from 'src/app/utils/utils.functions';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { isTypeStockIndex } from '@shared/constants/general.contant';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResumeComponent extends ReadStreamBase {
  @Input() refComponent!: string;
  @Input() height!: string | number;
  @Input() width!: string | number;
  @Input() set stockSelected(stockSelected: ISearchStock) {
    if (stockSelected) {
      this.isSotckIndex = isTypeStockIndex(stockSelected.type);
      this._resetLoadingValues();
      this._stockInfo = {};
      this._stockSelected = stockSelected;
      const { cd_stock, id_exchange, logo_company } = stockSelected;
      this.image = logo_company;
      this.cdr.detectChanges();
      this.getResumes(cd_stock, id_exchange);
      this.getDocuments(cd_stock, id_exchange);
      this._getProceeds(cd_stock, id_exchange);
      this._getNews(cd_stock);
      this.getCorporateEvents();
    }
  }
  _stockSelected!: ISearchStock;
  public isSotckIndex = false;
  @Input() set stockInfo(stockInfo: any) {
    if (stockInfo) {
      this._stockInfo = { ...this._stockInfo, ...stockInfo };
      this.cdr.detectChanges();
    }
  }
  _stockInfo: any = {};
  resumes = RESUMES;
  docs: IListDocuments[] = [];
  corporateEvents!: ICorporateEvents | undefined;
  docsToShow: IResumeList[] = [];
  corporateEventsToShow: IResumeList[] = [];
  public proceedsList: IResumeList[] = [];
  public image!: string;

  public news: INewsInfos[] = [];

  public loadingStatus: any = {
    docs: true,
    news: true,
    corporateEvents: true,
    loadedAllContent: false,
  };

  public proventsTabs = [
    {
      ref: 'PROVENTS',
      title: 'Proventos',
      active: true,
      tooltip: 'Últimos',
    },
    {
      ref: 'NEXT_PROVENTS',
      title: 'Próximos',
      active: false,
      tooltip: 'Data com',
    },
  ];

  constructor(
    private businessProfileService: BusinessProfileService,
    private summaryService: SummaryService,
    private cdr: ChangeDetectorRef,
    private documentsService: DocumentsService,
    private _newsService: TrademapNewsService,
    private _trademapCalendarsService: TrademapCalendarsService
  ) {
    super();
  }

  private getResumes(cd_stock: string, id_exchange: number) {
    this.summaryService
      .getStatistics(cd_stock, id_exchange)
      .then((info: any) => {
        const data = info.data.statistics;
        data.forEach((element: ILaminaSectionStatistics) => {
          this._stockInfo = this.businessProfileService.formatStatistics(
            element,
            this._stockSelected,
            this._stockInfo
          );
        });
        this.setDefaultInfoResume();
        this.cdr.detectChanges();
      });
  }

  private setDefaultInfoResume() {
    this.resumes.forEach((resume) => {
      if (!this._stockInfo[resume.cod]) {
        this._stockInfo = this.businessProfileService.formatStatistics(
          {
            help: '',
            label: resume.title,
            property: resume.cod,
            value: 0,
            suffix: resume.suffix,
          },
          this._stockSelected,
          this._stockInfo
        );
      }
    });
  }

  getDocuments(cd_stock: string, id_exchange: number) {
    this.documentsService
      .getLastDocuments(cd_stock, id_exchange, 5)
      .then((data: any) => {
        this.docs = this.businessProfileService.formatDocs(data.data);
        this.docsToShow = this.docs as unknown as IResumeList[];
        this._updateLoadingStatus('docs', false);
        this.cdr.detectChanges();
      })
      .catch(() => {
        this.docs = [];
        this.docsToShow = [];
        this._updateLoadingStatus('docs', false);
        this.cdr.detectChanges();
      });
  }

  // PROVENTS
  getCorporateEvents() {
    this.businessProfileService
      .getCorporateEvents(this._stockSelected, 5)
      .then((data) => {
        this.corporateEvents = data.corporateEvents;
        this.corporateEventsToShow = data.corporateEventsToShow;
        this._updateLoadingStatus('corporateEvents', false);
        this.cdr.detectChanges();
      })
      .catch(() => {
        this.corporateEvents = undefined;
        this._updateLoadingStatus('corporateEvents', false);
        this.corporateEventsToShow = [];
        this.cdr.detectChanges();
      });
  }

  private _getProceeds(cdStock: string, idExchange: number) {
    this._trademapCalendarsService
      .getProceeds(false, 5, 'next', cdStock, idExchange.toString())
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => {
            return {
              body: `${data.company}<br /><span class="fs-7">${data.ds_company_action_type}</span>`,
              yearDoc: data.reference_year.toString(),
              dateString: VMDateToFriendlyDate(data.dt_limit.toString()),
            };
          });
        })
      )
      .subscribe((transformedData) => {
        this.proceedsList = transformedData;
        this.cdr.detectChanges();
      });
  }

  public changeProventsTab(ref: string): void {
    this.proventsTabs = this.proventsTabs.map((tab) => {
      tab.active = tab.ref === ref;
      return tab;
    });
    this.cdr.detectChanges();
  }

  public handleImageError = () => {
    this.image = '';
  };

  // NEWS
  private _getNews(cdStock: string): void {
    this._newsService.getNews(cdStock).subscribe({
      next: (news) => {
        if (!news || !news.length) {
          this._errorOnGetNews();
          return;
        }
        this._setNewsToDisplay(news);
      },
      error: () => this._errorOnGetNews(),
    });
  }

  private _errorOnGetNews(): void {
    this.news = [];
    this._updateLoadingStatus('news', false);
    this.cdr.detectChanges();
  }

  private _setNewsToDisplay(news: INewsV8[]): void {
    const newsToDisplay: INewsInfos[] = [];
    for (let agencyIndex = 0; agencyIndex < news.length; agencyIndex++) {
      for (
        let newsIndex = 0;
        newsIndex < news[agencyIndex].news.length;
        newsIndex++
      ) {
        if (newsToDisplay.length === 5) break;
        const newsToUpdate = news[agencyIndex].news[newsIndex];
        newsToUpdate.idAgency = news[agencyIndex].id_news_agency;
        newsToUpdate.agencyType = news[agencyIndex].agency_type;
        const { year, dateFormatted } = this._getDateReleasedNew(
          newsToUpdate.dt_news
        );
        newsToUpdate.dateReleased = dateFormatted;
        newsToUpdate.yearReleased = year;
        newsToDisplay.push(newsToUpdate);
      }
      if (newsToDisplay.length === 5) break;
    }
    this.news = newsToDisplay.sort((a, b) => b.dt_news - a.dt_news);
    this._updateLoadingStatus('news', false);
    this.cdr.detectChanges();
  }

  private _getDateReleasedNew(date: number) {
    const dt = date.toString();
    const year = dt.slice(0, 4);
    const dateFormatted = VMDateToFriendlyDate(dt);
    return { year, dateFormatted };
  }

  // LOADING HANDLER
  private _updateLoadingStatus(content: string, isLoading: boolean): void {
    this.loadingStatus[content] = isLoading;
    this.loadingStatus.loadedAllContent = Object.values(
      this.loadingStatus
    ).every((isLoading) => !isLoading);
    this.cdr.detectChanges();
  }

  private _resetLoadingValues(): void {
    this.loadingStatus = {
      docs: true,
      news: true,
      corporateEvents: true,
      loadedAllContent: false,
    };
  }

  public lastPriceFormatted(value: string): string {
    return this.isSotckIndex && !!value ? value.replace('R$ ', '') : value;
  }
}
