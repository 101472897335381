import { Rating } from './rating-system.interface';

export const RATINGS: Rating[] = [
  { icon: ':(', label: 'Péssima' },
  { icon: ':/', label: 'Ruim' },
  { icon: ':)', label: 'Boa' },
  { icon: ';)', label: 'Ótima' },
  { icon: ':D', label: 'Excelente' },
];

export const DEFAULT_FUNCTIONS = {
  SCREEN_VIEW: 'SCREEN_VIEW',
};
