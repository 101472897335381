import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { ModalAvisoComponent } from '@shared/components/modal-aviso/modal-aviso.component';
import { openZendeskRocket } from '@shared/components/helper-mini-modal/helper-mini-modal.component';
import { WhatsappService } from '@core/layout/header/whatsapp/whatsapp.service';
import { StandaloneModalsService } from '@shared/modals/standalone-modals.service';
import { ModalConnectBrokerComponent } from '../modal-connect-broker/modal-connect-broker.component';
import { IBrokersToConnect } from '@shared/services/api/trademap/V9/models/brokers-to-connect.interface';
import { take } from 'rxjs';
import { TrademapV10Service } from '@shared/services/api/trademap/V10/trademap.service';

@Component({
  selector: 'app-modal-brokers-to-connect',
  templateUrl: './modal-brokers-to-connect.component.html',
  styles: [
    `
      ::ng-deep.fix-width-steps {
        width: 25px !important;
      }

      .broker-fields-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        margin: 24px 0px;
      }

      :host {
        ::ng-deep.modal-dialog {
          @media (min-width: 1280px) {
            max-width: 800px;
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalBrokersToConnectComponent
  extends RocketModalRef
  implements OnInit, OnDestroy
{
  public brokers: IBrokersToConnect[] = [];
  public isLoading: boolean = true;

  private _modalService!: RocketModalService;
  private _trademapV10Service = inject(TrademapV10Service);
  private _standaloneModalService = inject(StandaloneModalsService);
  private _cdr = inject(ChangeDetectorRef);
  private _elementRef = inject(ElementRef);
  private _whatsAppService = inject(WhatsappService);

  constructor(service: RocketModalService) {
    super(service);
    this._modalService = service;
  }

  ngOnInit() {
    this._trademapV10Service.getBrokersToConnect().subscribe({
      next: (result) => {
        this.brokers = result.brokerList;
        this.isLoading = false;
        this._cdr.detectChanges();
      },
      error: () => {
        this.isLoading = false;
        this._cdr.detectChanges();
      },
    });
  }

  public brokerDisconnect(broker: IBrokersToConnect) {
    const ref = this._modalService.open(ModalAvisoComponent, {
      data: {
        title: 'Deseja cancelar sua conexão com nosso parceiro?',
        text: `
        <p>Para cancelar sua conexão com ${broker.nmBrokerNickname} será necessário entrar em contato com a nossa equipe.</p>
        <p>Abra um chamado colocando na descrição o nome do parceiro que você gostaria de cancelar a conexão.</p>
        <p>Ou nos envie uma mensagem no WhatsApp dizendo o parceiro que gostaria de cancelar.</p>`,
        confirmeButton: 'Abrir um chamado',
        askAgain: false,
        showButtons: true,
        cancelButton: 'Conversar pelo WhatsApp',
        isSecondModal: true,
        stock: undefined,
        showPriceStock: false,
      },
      toggleBetweenModal: this._elementRef,
    });
    ref.afterDismissed.pipe(take(1)).subscribe((data) => {
      if (data.typeEvent === 'Escape') return;
      if (data.value) openZendeskRocket();
      else this._whatsAppService.openWhatsAppRocket();
    });
  }

  public openConnectionBrokerModal(broker: IBrokersToConnect) {
    this.close();
    this._standaloneModalService.openStandaloneModal(
      ModalConnectBrokerComponent,
      {
        idBroker: broker.idBroker,
        customComponentOnClose: true,
        customComponentInfos: {
          component: ModalBrokersToConnectComponent,
        },
      },
      {},
      '',
      'lg'
    );
  }
}
