import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TOptionsSubheaderTabs } from '../../types';
import { RocketStreamRead } from '@shared/channel/rx-event';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { ISearchStock } from '@core/interface/stock.interface';
import { randomId } from '@shared/rocket-components/utils';
import { execFormatFinancial } from 'src/app/utils/utils.functions';
import { Subject, auditTime } from 'rxjs';
import { OptionsService } from '../../options.service';

@Component({
  selector: 'app-options-subheader',
  templateUrl: './options-subheader.component.html',
  styleUrls: ['./options-subheader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsSubheaderComponent
  extends ReadStreamBase
  implements OnInit, OnChanges, OnDestroy
{
  @Input() selectedTab!: TOptionsSubheaderTabs;
  @Input() stock!: ISearchStock | undefined;
  @Input() disableChangeStock: boolean = false;
  @Output() tabChange = new EventEmitter<TOptionsSubheaderTabs>();

  tabs!: TOptionsSubheaderTabs[];
  private quoteCurrentSubscription!: RocketStreamRead;
  private itemSubscribed!: string;
  private ref = randomId('options');
  public stockInfoCard = {
    price: '',
    variacao_dia: '',
    arrow_font_hex: '',
    arrow_hex: '',
  };
  private detectChanges$ = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private quoteChannel: QuoteChannel,
    private optionsService: OptionsService
  ) {
    super();
  }

  ngOnInit() {
    this.initializeTabs();
    if (!this.selectedTab) {
      this.initializeDefaultTab();
    }
    this.detectChanges$.pipe(auditTime(50)).subscribe(() => {
      this.cdr.detectChanges();
    });
    this.initializeReadStreams();
    this.onChangeType(this.tabs[0]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { stock } = changes;
    if (stock?.currentValue) {
      if (this.itemSubscribed) {
        this.unSubscribeCheetah();
      }
      this.stockInfoCard = {
        price: '',
        variacao_dia: '',
        arrow_font_hex: '',
        arrow_hex: '',
      };
      this.itemSubscribed = `${this.stock?.cd_stock}:${this.stock?.id_exchange}`;
      this.subscribeCheetah();
    }
  }

  ngOnDestroy(): void {
    this.unSubscribeCheetah();
    this.quoteCurrentSubscription?.close();
    this.detectChanges$ && this.detectChanges$.unsubscribe();
  }

  private initializeTabs() {
    this.tabs = this.optionsService.getSubheaderTabs();
  }

  private initializeDefaultTab() {
    this.selectedTab = this.optionsService.getDefaultOptionSubheaderTab();
  }

  public onChangeType(event: any): void {
    this.disableChangeStock = true;
    this.selectedTab = event;
    this.tabChange.emit(this.selectedTab);
    this.cdr.detectChanges();
  }

  private async initializeReadStreams() {
    this.quoteCurrentSubscription = await this.quoteChannel.readEvents();
    this.readStream(this.quoteCurrentSubscription.stream, this.channelHandler);
  }

  private subscribeCheetah() {
    const items = [this.itemSubscribed];
    const qouteParams = {
      itemsArray: items,
      header: this.ref,
    };
    this.quoteChannel.subscribe(qouteParams);
    this.quoteCurrentSubscription &&
      this.quoteCurrentSubscription.snapshot([this.itemSubscribed]);
  }

  private unSubscribeCheetah() {
    const items = [structuredClone(this.itemSubscribed)];
    const qouteParams = {
      itemsArray: items,
      header: this.ref,
    };
    this.quoteChannel.unsubscribe(qouteParams);
  }

  channelHandler = (payload: any) => {
    const data = payload.get(this.itemSubscribed);
    if (!data) return;
    const { preco_ultimo, arrow_font_hex, arrow_hex, variacao_dia } = data;
    if (arrow_font_hex) {
      this.stockInfoCard.arrow_font_hex = arrow_font_hex;
      this.detectChanges$.next();
    }
    if (arrow_hex) {
      this.stockInfoCard.arrow_hex = arrow_hex;
      this.detectChanges$.next();
    }
    if (variacao_dia) {
      this.stockInfoCard.variacao_dia = this.formatPrice(variacao_dia);
      this.detectChanges$.next();
    }
    if (preco_ultimo) {
      this.stockInfoCard.price = this.formatPrice(preco_ultimo);
      this.detectChanges$.next();
    }
  };

  private formatPrice(value: string): string {
    const data = {
      id_exchange: this.stock?.id_exchange,
      tick_size_denominator: this.stock?.tick_size_denominator,
    };
    return execFormatFinancial(data, value);
  }
}
