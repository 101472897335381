import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { ColorsBaseService } from './colors-base.service';
import { COLORS, MONO_COLORS } from './toolbar-draw.const';
import { color } from './toolbar-draw.interface';

export abstract class ColorsBase {
  principalColors: Array<color> = [];
  monoColors: Array<color> = [];
  userColors: Array<color> = [];

  constructor(
    private customPreferencesService: CustomPreferencesService,
    private colorsBaseService: ColorsBaseService
  ) {
    COLORS.forEach((color) => {
      const rgba = color.rgbColor.replace('rgb', 'rgba').replace(')', ', 1)');
      this.principalColors.push({
        rgbColor: color.rgbColor,
        rgbaColor: rgba,
        rgbaHexColor: this.rgba2hex(rgba),
        hexColor: this.rgba2hex(color.rgbColor),
        selected: false,
      });
    });
    MONO_COLORS.forEach((color) => {
      const rgba = color.rgbColor.replace('rgb', 'rgba').replace(')', ', 1)');
      this.monoColors.push({
        rgbColor: color.rgbColor,
        rgbaColor: rgba,
        rgbaHexColor: this.rgba2hex(rgba),
        hexColor: this.rgba2hex(color.rgbColor),
        selected: false,
      });
    });
    this.refreshUserColors();
  }

  setSelectedColor(hexColor: string) {
    this.principalColors
      .filter(
        (color) => color.rgbaHexColor.toUpperCase() === hexColor.toUpperCase()
      )
      .map((color) => {
        color.selected = true;
      });
    this.monoColors
      .filter(
        (color) => color.rgbaHexColor.toUpperCase() === hexColor.toUpperCase()
      )
      .map((color) => {
        color.selected = true;
      });
    this.userColors
      .filter(
        (color) => color.rgbaHexColor.toUpperCase() === hexColor.toUpperCase()
      )
      .map((color) => {
        color.selected = true;
      });
    const principal = this.principalColors.find((color) => color.selected);
    const mono = this.monoColors.find((color) => color.selected);
    const user = this.userColors.find((color) => color.selected);
    return { principal, mono, user };
  }

  resetSelectedColor() {
    this.principalColors
      .filter((color) => color.selected)
      .map((color) => {
        color.selected = false;
      });
    this.monoColors
      .filter((color) => color.selected)
      .map((color) => {
        color.selected = false;
      });
    this.userColors
      .filter((color) => color.selected)
      .map((color) => {
        color.selected = false;
      });
  }

  refreshUserColors() {
    const stringColors = this.customPreferencesService.userColorDrawTools;
    if (!isNullOrUndefined(stringColors) && stringColors != '') {
      const userColors = JSON.parse(stringColors) as Array<color>;
      this.userColors = userColors;
      this.resetSelectedColor();
    }
  }

  rgba2hex(rgba: string): string {
    return this.colorsBaseService.rgba2hex(rgba);
  }

  hex2Rgba(hex: string): string {
    return this.colorsBaseService.hex2Rgba(hex);
  }

  rgba2rgb(rgbaColor: string): string {
    return this.colorsBaseService.rgba2rgb(rgbaColor);
  }
}
