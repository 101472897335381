import { TYPE_ORDE_ENUM, TYPE_VALIDADE_ENUM } from '../enum/stock-trade.enum';

export const ORDER_TYPES = [
  {
    id: TYPE_ORDE_ENUM.LIMITADA,
    desc: 'Limitada',
    cod: '2',
    tipo_bmf: true,
    inedit: false,
  },
  {
    id: TYPE_ORDE_ENUM.MARKET,
    desc: 'Mercado',
    cod: '1',
    tipo_bmf: false,
    inedit: false,
  },
  {
    id: TYPE_ORDE_ENUM.SALE,
    desc: 'Leilão Limitada',
    cod: '7',
    tipo_bmf: true,
    inedit: true,
    type: '2',
  },
  {
    id: TYPE_ORDE_ENUM.SALE_OF_OPEN,
    desc: 'Leilão Abertura',
    cod: 'A',
    tipo_bmf: true,
    inedit: true,
    type: '1',
  },
  {
    id: TYPE_ORDE_ENUM.SALE_OF_CLOSE,
    desc: 'Leilão Fechamento',
    cod: '7',
    tipo_bmf: true,
    inedit: true,
    type: '1',
  },
  {
    id: TYPE_ORDE_ENUM.START_STOP,
    desc: 'Start/Stop',
    cod: '4',
    tipo_bmf: true,
    inedit: true,
  },
  {
    id: TYPE_ORDE_ENUM.DOUBLE_START_STOP,
    desc: 'Start Duplo',
    cod: 'D',
    tipo_bmf: true,
    inedit: true,
  },
];

export const VALIDADE_TYPES = [
  {
    id: `${TYPE_VALIDADE_ENUM.TODAY}`,
    name: 'Hoje',
  },
  {
    id: TYPE_VALIDADE_ENUM.UNTIL_THE_DAY,
    name: 'Até o Dia',
  },
  {
    id: TYPE_VALIDADE_ENUM.VALID_UNTIL_CANCEL,
    name: 'Válido até Cancelar',
  },
  //TODO: Ir liberando quando cada uma for ficando concluida
  {
    id: TYPE_VALIDADE_ENUM.EXECUTE_OR_CANCEL,
    name: 'Executar ou Cancelar',
  },
  {
    id: TYPE_VALIDADE_ENUM.EVERYTHING_OR_NOTHING,
    name: 'Tudo ou Nada',
  },
];

export const COLOR_BOLETA = {
  BUY: 'boleta-compra',
  SELL: 'boleta-venda',
  BUY_START_STOP: 'boleta-compra-start-stop',
  SELL_START_STOP: 'boleta-venda-start-stop',
};

export const TYPE_TRADE = {
  B4: {
    bg: COLOR_BOLETA.BUY,
    type: COLOR_BOLETA.BUY_START_STOP,
  },
  S4: {
    bg: COLOR_BOLETA.SELL,
    type: COLOR_BOLETA.SELL_START_STOP,
  },
  B2: {
    bg: COLOR_BOLETA.BUY,
    type: COLOR_BOLETA.BUY,
  },
  S2: {
    bg: COLOR_BOLETA.SELL,
    type: COLOR_BOLETA.SELL,
  },
  BD: {
    bg: COLOR_BOLETA.BUY,
    type: COLOR_BOLETA.BUY,
  },
  SD: {
    bg: COLOR_BOLETA.SELL,
    type: COLOR_BOLETA.SELL,
  },
  BK: {
    bg: COLOR_BOLETA.BUY,
    type: COLOR_BOLETA.BUY,
  },
  SK: {
    bg: COLOR_BOLETA.SELL,
    type: COLOR_BOLETA.SELL,
  },
  B1: {
    bg: COLOR_BOLETA.BUY,
    type: COLOR_BOLETA.BUY,
  },
  S1: {
    bg: COLOR_BOLETA.SELL,
    type: COLOR_BOLETA.SELL,
  },
};

export const PRICE_COUNTER_FIELDS = [
  'lossPrice',
  'gainPrice',
  'gainPriceTrigger',
  'lossPriceTrigger',
  'stopPx',
];

export const STOCK_TRADE_TOTAL_LAST_PRICE = [ 
  TYPE_ORDE_ENUM.MARKET, 
  TYPE_ORDE_ENUM.DOUBLE_START_STOP,
  TYPE_ORDE_ENUM.SALE_OF_OPEN,
  TYPE_ORDE_ENUM.SALE_OF_CLOSE
]

