import {
  TTigerChartIndicatorCreateOptions,
  TTigerChartIndicatorMovingAverageOptions,
} from './indicators.types';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { TIGER_INDICATORS_ENUM } from '../enum/tiger-chart.enum';
import { TigerChartIndicatorMovingAverageBase } from './base/moving-average-base';

export class TigerChartIndicatorTripleExponentialMovingAverage extends TigerChartIndicatorMovingAverageBase {
  constructor(options: TTigerChartIndicatorMovingAverageOptions) {
    super(options, TIGER_INDICATORS_ENUM.TRIPLE_EXPONENTIAL_MOVING_AVERAGE);
  }

  override create(
    options: TTigerChartIndicatorCreateOptions
  ): BaseRenderableSeries[] {
    this.updatePoints();
    return super.create(options);
  }

  override updatePoints(): void {
    const offset: number[] = Array(this.timePeriod).fill(
      this.data[this.source][0]
    );
    const TAema1 = this.service.call('EMA', {
      inReal: [...offset, ...this.data[this.source]],
      timePeriod: this.timePeriod,
    });
    const ema1Output = [].slice.call(TAema1.output);
    const off1 = Array(this.timePeriod).fill(ema1Output[0]);
    const TAema2 = this.service.call('EMA', {
      inReal: [...off1, ...ema1Output],
      timePeriod: this.timePeriod,
    });
    const ema2Output = [].slice.call(TAema2.output);
    const off2 = Array(this.timePeriod).fill(ema2Output[0]);
    const TAema3 = this.service.call('EMA', {
      inReal: [...off2, ...ema2Output],
      timePeriod: this.timePeriod,
    });
    const ema3Output = [].slice.call(TAema3.output);
    const output = [];
    for (let i = 0; i < ema3Output.length; i++) {
      const ema1 = ema1Output[i];
      const ema2 = ema2Output[i];
      const ema3 = ema3Output[i];
      output.push(3 * (ema1 - ema2) + ema3);
    }
    this.points = { output };
  }

  override updateSettings() {
    this.updatePoints();
    super.updateSettings();
  }
}
