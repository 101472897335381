<div class="container-fluid px-4">
  <div class="row g-3 pt-2 flex-nowrap">
    <div *ngIf="performance" class="col-7">
      <div
        #barChartCard
        class="card d-flex flex-column align-items-center"
        [style.height.px]="180"
      >
        <div class="w-100 d-flex justify-content-between pb-3 fw-bold">
          <div>Lucro</div>
          <div class="text-feedback-success">
            {{
              performance.gross_profit | currency : 'BRL' : 'symbol' : '1.2-2'
            }}
          </div>
        </div>
        <div class="w-100 d-flex justify-content-between pb-2 fw-bold">
          <div>Prejuízo</div>
          <div class="text-feedback-error">
            {{ performance.gross_loss | currency : 'BRL' : 'symbol' : '1.2-2' }}
          </div>
        </div>
        <div class="w-100 divider"></div>
        <div class="w-100 d-flex justify-content-between pb-3 fw-bold">
          <div>Resultado</div>
          <div>
            {{
              performance.liquid_profit | currency : 'BRL' : 'symbol' : '1.2-2'
            }}
          </div>
        </div>
        <app-profit-bar-chart
          *ngIf="profitBarData"
          [data]="profitBarData"
          [width]="barChartCard.offsetWidth"
          [small]="true"
        ></app-profit-bar-chart>
      </div>
    </div>
    <div class="col-9">
      <div class="card h-100 p-2" [style.height.px]="180">
        <div class="position-relative h-100 w-100">
          <div
            #chartContainer
            [id]="refChart"
            class="chart-container position-relative h-100"
          ></div>
          <div
            *ngIf="loadingChart || chartNoData"
            class="position-absolute h-100 w-100 bg-neutral-stronger top-0"
          >
            <app-loading *ngIf="loadingChart; else noData"></app-loading>
            <ng-template #noData>
              <app-no-info
                text="Não há dados."
                css="d-flex align-items-center justify-content-center h-100"
              ></app-no-info>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row g-3 pt-2">
    <div *ngIf="performance" class="col-6 d-flex flex-column fw-bold fs-6">
      <div class="card py-2 mb-2 flex-row justify-content-between">
        <span>Exposição atual</span>
        <span
          >{{
            performance.vl_total || 0 | currency : 'BRL' : 'symbol' : '1.2-2'
          }}
        </span>
      </div>
      <div class="card py-2 mb-2 flex-row justify-content-between">
        <span>Maior posição aberta</span>
        <span>{{ performance.h_open_qtty || 0 }}</span>
      </div>
      <div class="card py-2 mb-2">
        <span class="fs-6">Maior sequência vencedora</span>
        <span class="text-feedback-success">
          {{ performance.highest_win_streak }}
          |
          {{
            performance.highest_won_operation
              | currency : 'BRL' : 'symbol' : '1.2-2'
          }}
        </span>
      </div>
      <div class="card py-2 mb-2">
        <span class="fs-6">Maior sequência perdedora</span>
        <span class="text-feedback-error">
          {{ performance.highest_loss_streak }}
          |
          {{
            performance.highest_lose_operation
              | currency : 'BRL' : 'symbol' : '1.2-2'
          }}
        </span>
      </div>
      <div class="card py-2">
        <span class="fs-6">Maior sequência ordens em aberto (preço médio)</span>
        <span>{{ performance.h_qtty_avg_price }}</span>
      </div>
    </div>
    <div class="col-10">
      <div class="card h-100">
        <table *ngIf="operations" class="table mb-0">
          <thead class="fs-4">
            <th class="col-4" scope="col">Operação</th>
            <th
              class="no-select cursor-pointer col-4"
              scope="col-3"
              (click)="sortOperationTable('qtd')"
            >
              Qtd
              <fla-icon
                name="arrow_drop_down"
                size="icon-size-micro"
                [css]="operationsSortField !== 'qtd' ? 'unsorted-column' : ''"
                [upsideDown]="operationsSortField === 'qtd' && invertSort"
              ></fla-icon>
            </th>
            <th
              class="no-select cursor-pointer col-3"
              scope="col-3"
              (click)="sortOperationTable('perc')"
            >
              %
              <fla-icon
                name="arrow_drop_down"
                size="icon-size-micro"
                [css]="operationsSortField !== 'perc' ? 'unsorted-column' : ''"
                [upsideDown]="operationsSortField === 'perc' && invertSort"
              ></fla-icon>
            </th>
            <th
              class="no-select cursor-pointer col-5"
              scope="col-3"
              (click)="sortOperationTable('total')"
            >
              Total
              <fla-icon
                name="arrow_drop_down"
                size="icon-size-micro"
                [css]="operationsSortField !== 'total' ? 'unsorted-column' : ''"
                [upsideDown]="operationsSortField === 'total' && invertSort"
              ></fla-icon>
            </th>
          </thead>
          <tbody>
            <tr *ngFor="let row of operationsTableSorted">
              <td>{{ row.name }}</td>
              <td>{{ row.qtd }}</td>
              <td>{{ row.perc }}%</td>
              <td>{{ row.total | currency : 'BRL' : 'symbol' : '1.2-2' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="row g-3 py-2">
    <div class="col">
      <div class="card table-responsive">
        <table *ngIf="marketPerformanceData" class="table">
          <thead>
            <th>Tipo</th>
            <th colspan="2">Abertas</th>
            <th colspan="2">Executadas</th>
            <th colspan="2">Canceladas</th>
            <th></th>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td class="fw-bold">Compra</td>
              <td class="fw-bold">Venda</td>
              <td class="fw-bold">Compra</td>
              <td class="fw-bold">Venda</td>
              <td class="fw-bold">Compra</td>
              <td class="fw-bold">Venda</td>
              <td></td>
            </tr>
            <tr *ngFor="let item of marketTypeOperations">
              <td>{{ item.type }}</td>
              <td>{{ item.open_buy }}</td>
              <td>{{ item.open_sell }}</td>
              <td>{{ item.exec_buy }}</td>
              <td>{{ item.exec_sell }}</td>
              <td>{{ item.canc_buy }}</td>
              <td>{{ item.canc_sell }}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
