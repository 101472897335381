import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@shared/services';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PreventLoginReturnGuard {
  private _auth: AuthService = inject(AuthService);

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve) => {
      if (nextState.url.includes('already-open')) return resolve(true);
      this._auth
        .isAuth()
        .pipe(map((data) => Boolean(data)))
        .subscribe(async (isLogged) => {
          resolve(!isLogged);
        });
    });
  }
}
