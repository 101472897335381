import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TimesTradesComponent } from './times-trades.component';

import { SearchStockModule } from '../search-stock/search-stock.module';
import { TimesTradesHeaderComponent } from './header/times-trades-header.component';
import { ForceChartModule } from '../force-chart/force-chart.module';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { TimesTradesContextMenuComponent } from './parts/times-trades-context-menu/times-trades-context-menu.component';
import { DetailsTabModule } from '../details-tab/details-tab.module';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import {
  FlaIconModule,
  RocketButtonModule,
} from '@shared/rocket-components/components';
import { TradeRowModule } from '@shared/components/times-trades/parts/index';
import { PopupRootModule } from '@shared/components/popup-root/popup-root.module';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';
import { TradesFilterShortcutComponent } from './parts/trades-filter-shortcut/trades-filter-shortcut.component';

@NgModule({
  declarations: [
    TimesTradesComponent,
    TimesTradesHeaderComponent,
    TimesTradesContextMenuComponent,
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    SearchStockModule,
    ForceChartModule,
    FlaWorkSpaceModule,
    TooltipsModule,
    FlaIconModule,
    DetailsTabModule,
    RocketButtonModule,
    TradeRowModule,
    PopupRootModule,
    RTDropdownModule,
    TradesFilterShortcutComponent,
  ],
  exports: [TimesTradesComponent, TimesTradesHeaderComponent],
})
export class TimesTradesModule {}
