<div
  [ngClass]="[css, margin]"
  [class]="labelPlacement === 'horizontal' ? 'hstack' : ''"
>
  <label
    *ngIf="label"
    [for]="refId"
    class="form-label"
    [ngClass]="{ required: isRequired }"
  >
    {{ label }}
  </label>
  <div [ngClass]="{ 'ml-1': labelPlacement === 'horizontal' }">
    <div
      class="input-group"
      [ngClass]="{
        'group-suffix': !symbolPrefix,
        'group-suffix-prefix': symbolPrefix
      }"
    >
      <input
        #inputCount
        [tabIndex]="tabIndex"
        [type]="type"
        class="form-control group-suffix-prefix"
        [ngClass]="{ 'form-control-sm': isSmall }"
        [mask]="mask"
        [showMaskTyped]="showMaskTyped"
        [selectOnFocus]="selectOnFocus"
        [allowNegativeNumbers]="allowNegativeValue"
        [id]="refId"
        [required]="isRequired"
        [formControl]="formControl"
        [attr.aria-describedby]="ariaDescribedby"
        [placeholder]="placeholder"
        [maxlength]="maxLength"
        [minlength]="minLength"
        [max]="max"
        [min]="min"
        (keydown)="flaKeyDown.emit(formControl)"
        (keyup)="flaKeyUp.emit(formControl)"
        (change)="flaChange.emit(formControl)"
        (ngModelChange)="onChangeValue(formControl)"
        (focus)="handleFocus($event)"
        (blur)="handleFocus()"
      />
      <div *ngIf="symbolPrefix" class="prefix px-0 fs-6 pl-1 text-white">
        {{ symbolPrefix }}
      </div>

      <div class="suffix">
        <span class="fs-6 text-white" *ngIf="symbolSuffix">{{
          symbolSuffix
        }}</span>
        <span
          *ngIf="enableUpdateValueBtn"
          class="d-flex justify-content-center p-0 wid cursor-pointer"
          tooltip="Atualizar preço"
          (click)="updateValueClick()"
        >
          <fla-icon
            name="refresh"
            size="icon-size-micro"
            css="text-brand-primary"
          ></fla-icon>
        </span>
        <div
          [ngClass]="{
            'justify-content-end':
              !alwaysShowDecrementButton && originalValue > min,
            'justify-content-start':
              !alwaysShowIncrementButton && originalValue <= max,
            'min-height-count':
              !alwaysShowIncrementButton && !alwaysShowDecrementButton
          }"
          class="d-flex flex-column input-count-buttons"
        >
          <rocket-button
            type="icon"
            [css]="isSmall ? 'up py-1' : 'up p-0'"
            [isDisabled]="disabledBtn || disabledIncrementButton"
            (rocketClick)="up(true)"
            [tabIndex]="tabIndexIncrement"
          >
            <fla-icon
              name="arrow_drop_up"
              [css]="isSmall ? 'lh-none' : ''"
            ></fla-icon>
          </rocket-button>
          <rocket-button
            type="icon"
            [css]="isSmall ? 'down py-1' : 'down p-0'"
            [isDisabled]="disabledBtn || disabledDecrementButton"
            (rocketClick)="down(true)"
            [tabIndex]="tabIndexIncrement"
          >
            <fla-icon
              name="arrow_drop_down"
              [css]="isSmall ? 'lh-none' : ''"
            ></fla-icon>
          </rocket-button>
        </div>
      </div>
    </div>
    <small class="form-text">{{ hint }}</small>
    <ng-content select=".text-feedback-error"></ng-content>
    <small
      class="ng-invalid invalid-feedback d-block"
      *ngIf="
        (isRequired &&
          formControl.enabled &&
          formControl.invalid &&
          formControl.touched) ||
        formControl.hasError(hasError)
      "
    >
      {{ requiredText }}
    </small>
  </div>
</div>
