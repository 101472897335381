import { Pipe, PipeTransform } from '@angular/core';
import { formatterNumber } from '../../utils/functions';

@Pipe({ name: 'flaFormatNumber' })
export class FlaFormatNumberPipe implements PipeTransform {
  transform(value: number, decimalMarker: number = 2): string {
    return formatterNumber(value, {
      maximumFractionDigits: decimalMarker,
      minimumFractionDigits: decimalMarker,
    });
  }
}
