<workspace-doc-header
  css="w-100 d-flex header-wrapper border-bottom"
  [item]="component"
>
  <fla-workspace-doc-header-item [enableContent]="true" [hideBorder]="true">
    <a
      *ngFor="let header of headers"
      type="button"
      class="nav-link p-2 lh-sm border-right fw-bolder text-center w-100"
      [ngClass]="{
        'text-brand-primary': selectedHeader.code === header.code,
        'text-neutral-medium ': selectedHeader.code !== header.code
      }"
      (click)="selectView(header)"
    >
      {{ header.label }}
    </a>
  </fla-workspace-doc-header-item>
  <fla-workspace-doc-header-item [enableContent]="true" [hideBorder]="true">
    <div
      class="d-flex top-position position-relative mt-1"
      [ngClass]="{ 'desktop-spacing': showSelectWindow }"
    >
      <app-search-stock
        #searchStock
        [headerOptions]="component.metadata?.headerOptions"
        (selectStockChange)="selectStock($event)"
        [initStock]="stockSelected"
        [ref]="component.id"
        searchLabel="Ativo"
        css="border-right"
      ></app-search-stock>
      <div
        class="position-absolute pr-1 right-0 top-0 cursor-pointer stock-remove"
        *ngIf="stockSelected"
        tooltip="Remover ativo"
      >
        <rocket-button type="icon" (rocketClick)="selectStock(undefined)">
          <fla-icon name="delete" size="icon-size-micro"></fla-icon>
        </rocket-button>
      </div>
    </div>
  </fla-workspace-doc-header-item>
</workspace-doc-header>
