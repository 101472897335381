import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountSelectorComponent } from './account-selector.component';
import {
  FlaIconModule,
  RocketButtonModule,
} from '@shared/rocket-components/components/index';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { DaytradeSelectorModule } from '../daytrade-selector/daytrade-selector.module';
import { StrategySelectorModule } from '../strategy-selector/strategy-selector.module';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';

@NgModule({
  declarations: [AccountSelectorComponent],
  imports: [
    CommonModule,
    TooltipsModule,
    RTDropdownModule,
    FlaIconModule,
    RocketButtonModule,
    DaytradeSelectorModule,
    StrategySelectorModule,
  ],
  exports: [AccountSelectorComponent],
  providers: [],
})
export class AccountSelectorModule {}
