import { deepClone, formatterNumber } from '@shared/rocket-components/utils';
import { ColDef } from 'ag-grid-community';
import {
  financialFormatterForPositionCurrentPrice,
  qtdeFormatterFunction,
} from 'src/app/utils/cell-format.functions';
import { CustomTooltipComponent } from '../custom-tooltip/custom-tooltip.component';
import { TOOLTIP_COLUMNS } from './tooltip-columns';
import { GenericFilterComponent } from '../generic-filter/generic-filter.component';
import { FILTER_OPTIONS_ORDER_GRID } from './filter-options';

function numberComparator(valueA: string, valueB: string) {
  if (!valueA && !valueB) {
    return 0;
  }
  if (!valueA) {
    return -1;
  }
  if (!valueB) {
    return 1;
  }
  return +valueA - +valueB;
}

export const HEADERS_PORTFOLIO_CONFIG: any = {
  remove_custody: {
    hide: false,
    width: 32,
  },
  cd_stock: {
    hide: false,
    width: 70,
  },
  qtty_final: {
    hide: false,
    width: 60,
  },
  net_day: {
    hide: false,
    width: 60,
  },
  vl_price_avg: {
    hide: false,
    width: 80,
  },
  preco_ultimo: {
    hide: false,
    width: 80,
  },
  pnl_open: {
    hide: false,
    width: 80,
  },
  pnl_day: {
    hide: false,
    width: 80,
  },
  pnl: {
    hide: false,
    width: 80,
  },
  pl: {
    hide: false,
    width: 80,
  },
};

export const HEADERS_PORTFOLIO: Array<ColDef> = [
  {
    headerName: '',
    field: 'remove_custody',
    minWidth: 32,
    maxWidth: 32,
    width: 32,
    lockPosition: 'left',
    pinned: 'left',
    tooltipValueGetter: () => 'Zerar posição e cancelar as ordens abertas',
    tooltipComponentParams: {
      title: 'Zerar posição e cancelar as ordens abertas',
    },
    tooltipComponent: CustomTooltipComponent,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onCellClicked: () => {}, // Tratativa no order.component.ts
    cellRenderer: () =>
      ` <i class="icons icon-size-micro text-feedback-negative cursor-pointer"> close </i> `,
  },
  {
    headerName: 'Ativo',
    field: 'cd_stock',
    headerTooltip: 'Ativo',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    filter: GenericFilterComponent,
    filterParams: deepClone(FILTER_OPTIONS_ORDER_GRID.TEXT),
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Ativo' },
    cellClassRules: {
      'text-brand-primary': (params) => params.data.isSelected,
    },
  },
  {
    headerName: 'Qtd',
    field: 'qtty_final',
    headerTooltip: 'Quantidade custódia',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    cellClass: ['text-right'],
    headerClass: 'ag-right-aligned-header',
    valueFormatter: (params: any) =>
      params.value ? qtdeFormatterFunction(params) : '-',
    comparator: numberComparator,
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Quantidade custódia' },
  },
  {
    headerName: 'Abr',
    field: 'net_day',
    headerTooltip: 'Quantidade aberta devido a ordens pendente de execução.',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    cellClass: ['text-right'],
    headerClass: 'ag-right-aligned-header',
    valueFormatter: (params: any) =>
      params.value ? qtdeFormatterFunction(params) : '-',
    comparator: numberComparator,
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: {
      title: 'Quantidade aberta devido a ordens pendente de execução.',
    },
  },
  {
    headerName: 'Médio',
    field: 'vl_price_avg',
    headerTooltip: 'Preço médio',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    type: 'numericColumn',
    comparator: numberComparator,
    valueFormatter: (params: any) =>
      financialFormatterForPositionCurrentPrice(params) || '-',
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Preço médio' },
  },
  {
    headerName: 'Atual',
    field: 'preco_ultimo',
    headerTooltip: 'Preço atual',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    type: 'numericColumn',
    comparator: numberComparator,
    enableCellChangeFlash: true,
    cellClass: (params) => {
      return params.data.class;
    },
    valueFormatter: (params) =>
      financialFormatterForPositionCurrentPrice(params) || '',
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: { title: 'Preço atual' },
  },
  {
    headerName: 'P&L abr',
    field: 'pnl_open',
    headerTooltip: 'P&L aberto',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    type: 'numericColumn',
    comparator: numberComparator,
    valueFormatter: (params: any) =>
      params.value ? formatterNumber(+params.value) : '-',
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: TOOLTIP_COLUMNS.pnl_open,
  },
  {
    headerName: 'P&L dia',
    field: 'pnl_day',
    headerTooltip: 'P&L dia',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    type: 'numericColumn',
    comparator: numberComparator,
    valueFormatter: (params: any) =>
      params.value ? formatterNumber(+params.value) : '-',
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: TOOLTIP_COLUMNS.pnl_day,
  },
  {
    headerName: 'P&L tot',
    field: 'pnl',
    headerTooltip: 'P&L total',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    type: 'numericColumn',
    comparator: numberComparator,
    valueFormatter: (params: any) =>
      params.value ? formatterNumber(+params.value) : '-',
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: TOOLTIP_COLUMNS.pnl,
  },
  {
    headerName: 'Valor tot',
    field: 'pl',
    headerTooltip: 'Valor total',
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    type: 'numericColumn',
    comparator: numberComparator,
    valueFormatter: (params: any) =>
      params.value ? formatterNumber(+params.value) : '-',
    tooltipComponent: CustomTooltipComponent,
    tooltipComponentParams: TOOLTIP_COLUMNS.pl,
  },
];
