<div class="d-flex pt-2">
  <ng-container *ngFor="let option of filterOptions">
    <a
      type="button"
      class="nav-link p-2 lh-sm round-top"
      [ngClass]="{ 'text-brand-primary fw-bolder': option.active }"
      (click)="selectViewType(option.code)"
    >
      {{ option.label }}
    </a>
  </ng-container>
</div>
