export enum HEATMAP_ORDER_BY {
  variation = 'Variação 1 Dia',
  mcap = 'MCAP',
  volume = 'Volume 1 Dia',
  yesterday_volume = 'Volume Ontem',
  one_week = 'Volume 1 Semana',
  one_month = 'Volume 1 Mês',
  one_year = 'Volume 1 Ano',
}

export enum HEATMAP_SOURCE_LABELS {
  BR = 'Setores',
  FII = 'Fundos imobiliários',
  US = 'US',
  INDEX = 'Índices',
  ETF = 'ETFs',
  BDR = 'BDRs',
}

export enum HEATMAP_ORDER_BY_LIST_MAPPING {
  variation = 'variacao_dia',
  mcap = 'mcap',
  volume = 'volume',
  one_week = 'week_volume',
  one_month = 'month_volume',
  one_year = 'year_volume',
  yesterday_volume = 'yesterday_volume',
}

export enum HEATMAP_ORDER_BY_SECTOR_MAPPING {
  volume = 10,
  variation = 9,
  mcap = 13,
  one_week = 14,
  one_month = 15,
  one_year = 16,
  yesterday_volume = 17,
}

export enum HEATMAP_PERIODS {
  YESTERDAY = 'Ontem',
  ONEDAY = '1 dia',
  ONEWEEK = '1 semana',
  ONEMONTH = '1 mês',
  ONEYEAR = '1 ano',
  THISYEAR = 'Ano atual',
  AFTERMARKET = 'AfterMarket',
  GAPOPENING = 'Gap Opening',
}

export enum PERIOD_KEY_MAPPING {
  YESTERDAY = 'yesterday_variation',
  ONEDAY = 'variacao_dia',
  ONEWEEK = 'week_variation',
  ONEMONTH = 'month_variation',
  ONEYEAR = 'year_variation',
  AFTERMARKET = 'variacao_dia',
  THISYEAR = 'this_year_variation',
  GAPOPENING = 'GAPOPENING',
}
