import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'user-registered',
  templateUrl: './user-registered.component.html',
})
export class UserRegisteredComponent {
  @Output() public redirRegisteredUser: EventEmitter<
    'LOGIN' | 'RECOVER_PASSWORD'
  > = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  public handleButtonClick(type: 'LOGIN' | 'RECOVER_PASSWORD'): void {
    this.redirRegisteredUser.emit(type);
  }
}
