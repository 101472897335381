import { Injectable } from '@angular/core';
import { TIGER_INTERVAL_ENUM } from '../enum';
import { ICandleConfigs } from '../interface';
import { StockChartHelper } from '@shared/components/stock-chart/stock-chart.helper';
import { IYAxisLabelFormatter } from '../interface/axis.interface';

@Injectable({ providedIn: 'root' })
export class AxisService {
  constructor(private _chartHelper: StockChartHelper) {}

  xAxisLabelFormatter(
    configs: Partial<ICandleConfigs>,
    locale: string,
    dataValue: number,
    showFullDate: boolean,
    isCursor?: boolean
  ) {
    const options: Intl.DateTimeFormatOptions = {
      timeZone: 'America/Sao_Paulo',
    };
    const date = new Date(dataValue);

    switch (configs.series?.interval) {
      case TIGER_INTERVAL_ENUM.ONE_DAY:
      case TIGER_INTERVAL_ENUM.THIS_WEEK:
      case TIGER_INTERVAL_ENUM.ONE_WEEK:
        options.month = '2-digit';
        options.day = '2-digit';
        if (isCursor) options.year = '2-digit';
        break;
      case TIGER_INTERVAL_ENUM.ONE_MONTH:
        options.month = '2-digit';
        options.year = '2-digit';
        break;
      default: {
        if (showFullDate && isCursor) {
          options.day = '2-digit';
          options.month = '2-digit';
          options.year = '2-digit';
        }
        options.hour = 'numeric';
        options.minute = '2-digit';
        break;
      }
    }
    return date.toLocaleString(locale, options);
  }

  yAxisLabelFormatter = (data: IYAxisLabelFormatter) => {
    return this._chartHelper.yAxisLabelFormatter({
      value: data.value,
      cdSegment: data.cdSegment,
      labelPrecision: data.configs?.yAxis.labelPrecision,
      dsAsset: data.dsAsset,
      cdStock: data.papel!!,
      indicators: data.indicators,
      hasPercentagePriceOscillator: data.hasPercentagePriceOscillator,
    });
  };
}
