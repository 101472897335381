<rocket-modal>
  <rocket-modal-header [hideCloseButton]="true">
    <div class="d-flex flex-column">
      <ng-container [ngSwitch]="typeForm">
        <ng-container *ngSwitchCase="'INDICATOR'">
          <h4>{{ filterIndicators?.name }}</h4>
          <span class="fs-6">Escolha os parâmetros do indicador.</span>
        </ng-container>
        <ng-container *ngSwitchCase="'CUSTOM'">
          <h4>Valor Personalizado</h4>
        </ng-container>
      </ng-container>
      <rocket-button
        type="icon"
        css="position-absolute right-0 top-0 mt-2 mr-2"
        (rocketClick)="onClose()"
      >
        <fla-icon name="close"></fla-icon>
      </rocket-button>
    </div>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <div class="row m-0" *ngIf="form" [formGroup]="form">
      <app-dynamic-form
        *ngFor="let field of fieldsForm"
        [field]="field"
        [form]="form"
      >
        <div
          *ngIf="showField(field.key)"
          #time_period
          class="d-flex align-items-baseline mb-2"
        >
          <div class="w-50 fs-5 fw-bold">
            {{ field.label }}
          </div>
          <div class="w-50">
            <app-input-count
              [formControlName]="field.key"
              [isSmall]="field.isSmall"
              [isRequired]="field.required"
              [allowNegativeValue]="field.allowNegativeValue"
              [incrementValue]="field.incrementValue"
              [isModal]="field.isModal"
              css="margin-bottom"
            ></app-input-count>
          </div>
        </div>
        <div
          *ngIf="field.key === 'data_point'"
          #data_point
          class="d-flex align-items-baseline mb-2"
        >
          <div class="w-50 fs-5 fw-bold">
            {{ field.label }}
          </div>
          <div class="w-50">
            <fla-select
              class="w-100"
              [formControlName]="field.key"
              [list]="field.options"
              label=""
              [isSmall]="field.isSmall"
              [bindLabel]="field.bindLabel"
              [bindValue]="field.bindValue"
              [tooltip]="field.tooltip"
              [tooltipDelay]="field.tooltipDelay || 0"
              [labelPlacement]="field.labelPlacement || 'vertical'"
            ></fla-select>
          </div>
        </div>
        <div
          *ngIf="field.key === 'custom'"
          #time_period
          class="d-flex align-items-baseline mb-2"
        >
          <div class="w-50 fs-5 fw-bold">
            {{ field.label }}
          </div>
          <div class="w-50">
            <app-input-count
              [formControlName]="field.key"
              [isSmall]="field.isSmall"
              [isRequired]="field.required"
              [allowNegativeValue]="field.allowNegativeValue"
              [incrementValue]="field.incrementValue"
              [isModal]="field.isModal"
              css="margin-bottom"
            ></app-input-count>
          </div>
        </div>
      </app-dynamic-form>
    </div>
    <div class="w-100 pr-2 d-flex justify-content-end mb-3 mt-5">
      <rocket-button
        css="ml-2"
        label="Salvar"
        (rocketClick)="onSave()"
      ></rocket-button>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
