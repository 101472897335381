<ng-template>
  <div
    [id]="workspaceDoc.id"
    class="rt-component resizers d-flex flex-column round-sm position-absolute p-0 hide border border-2"
    [ngClass]="{
      'border-multibroker-sell': isSellBoleta,
      'border-multibroker-buy': isBuyBoleta,
      'border-brand-primary': isFocused
    }"
    cdkDrag
    [cdkDragBoundary]="'#' + ref"
    [cdkDragFreeDragPosition]="workspaceDoc.layout.transform!"
    [style.zIndex]="zIndex"
    [ngStyle]="workspaceDoc.styles"
    (cdkDragStarted)="flaClick.emit(workspaceDoc.id)"
    (cdkDragEnded)="move(workspaceDoc, $event)"
    [cdkDragDisabled]="lockWorkspace"
    (click)="componentFocused.emit(workspaceDoc.id)"
    (contextmenu)="componentFocused.emit(workspaceDoc.id)"
    appOutsideClick
    [isFocused]="isFocused"
    (outsideClick)="componentFocused.emit('')"
  >
    <!-- START HEADER DOC -->
    <section
      (contextmenu)="displayContextMenu($event)"
      class="d-flex align-items-center border-bottom cursor-move w-100 justify-content-end doc-header"
      cdkDragHandle
      [style.min-height.px]="36"
      [style.z-index]="2"
      [ngClass]="[workspaceDoc?.headerBg]"
    >
      <app-header-commom-buttons
        [workspaceDoc]="workspaceDoc"
        [focused]="focused"
        [dragDisabled]="lockWorkspace"
        [ref]="ref"
        (contextMenuClick)="contextMenuClick.emit($event)"
        (flaRemoveComponent)="flaRemoveComponent.emit($event)"
        (flaLink)="flaLink.emit($event)"
        (flaMaximize)="flaMaximize.emit($event)"
      ></app-header-commom-buttons>
    </section>
    <!-- END HEADER DOC -->

    <!-- START BODY DOC -->
    <section
      [id]="workspaceDoc.id"
      [ngClass]="[workspaceClass]"
      class="doc-body d-flex align-items-center justify-content-center height-work-doc bg-neutral-strongest"
    >
      <ng-content></ng-content>
    </section>
    <!-- END BODY DOC -->

    <!-- START FOOTER DOC -->
    <section
      *ngIf="footerOptions?.hasFooter"
      class="d-flex align-items-center border-top w-100 justify-content-end doc-footer"
      [ngClass]="[workspaceDoc.headerBg]"
    ></section>
    <!-- END FOOTER DOC -->

    <!-- START RESIZERS -->
    <ng-container *ngIf="!lockWorkspace">
      <div
        class="resizer bottom-right"
        [workspace-rize]="workspaceDoc.doc"
        workspace-position="bottom-right"
      ></div>
      <div
        class="resizer bottom-left"
        [workspace-rize]="workspaceDoc.doc"
        workspace-position="bottom-left"
      ></div>
      <div
        class="resizer top-right"
        [workspace-rize]="workspaceDoc.doc"
        workspace-position="top-right"
      ></div>
      <div
        class="resizer top-left"
        [workspace-rize]="workspaceDoc.doc"
        workspace-position="top-left"
      ></div>
      <div
        class="resizer top"
        [workspace-rize]="workspaceDoc.doc"
        workspace-position="top"
      ></div>
      <div
        class="resizer bottom"
        [workspace-rize]="workspaceDoc.doc"
        workspace-position="bottom"
      ></div>
      <div
        class="resizer left"
        [workspace-rize]="workspaceDoc.doc"
        workspace-position="left"
      ></div>
      <div
        class="resizer right"
        [workspace-rize]="workspaceDoc.doc"
        workspace-position="right"
      ></div>
    </ng-container>
    <!-- END RESIZERS -->
  </div>
</ng-template>
