/* eslint-disable @angular-eslint/contextual-lifecycle */
import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReadStreamBase } from './read-stream-base';

@Injectable()
export abstract class RocketComponentBase
  extends ReadStreamBase
  implements OnInit
{
  constructor(protected activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ component }: any) => {
      this.initComponent(component);
    });
  }

  protected abstract initComponent(component: any): void;
}
