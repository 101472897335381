import {
  Directive,
  ElementRef,
  HostListener,
  OnInit,
  Optional,
  Input,
  ComponentRef,
  OnDestroy,
} from '@angular/core';
import { NgControl, FormControl } from '@angular/forms';
import { FlaDatePickerComponent } from '../datepicker.component';
import { isNullOrWhiteSpace, stringToDate } from '../../../utils';
import { Subject } from 'rxjs';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { RocketCreateComponentService } from '@shared/rocket-components/services';

@Directive({
  selector: '[flaDatepicker]',
})
export class FlaDatepickerDirective implements OnInit, OnDestroy {
  @Input() closeClickOutside: boolean = true;
  @Input() closeOnClick: boolean = true;
  @Input() minDate!: Date;
  @Input() maxDate!: Date;
  @Input('flaDatepickerAlign') align: 'left' | 'right' = 'right';
  @Input('flaDatepickerPosition') position: 'top' | 'bottom' | 'auto' = 'auto';
  @Input('flaDatepicker') formControl!: FormControl;

  private _initDatePicker: boolean = false;
  private _datePicker!: FlaDatePickerComponent;
  private _element!: HTMLElement;
  private _rootElement = this.elementRef.nativeElement as HTMLElement;
  private _componentRef!: ComponentRef<FlaDatePickerComponent>;
  private _onClose$ = new Subject<void>();
  constructor(
    @Optional() private readonly ngControl: NgControl,
    private elementRef: ElementRef,
    private createCompService: RocketCreateComponentService
  ) {}

  ngOnInit(): void {
    if (this.ngControl) {
      this.formControl = this.ngControl.control as FormControl;
      this._registreControl();
    }
  }

  ngOnDestroy(): void {
    this._onClose$.complete();
  }

  @HostListener('click', ['$event'])
  toggle() {
    if (!this._initDatePicker) {
      this._initDatepicker();
    }
    this._initDatePicker = !this._initDatePicker;
    this._datePicker.toggle();
  }

  private _initDatepicker() {
    const findAll = document.querySelectorAll('fla-datepicker');
    if (findAll) {
      findAll.forEach((picker) => picker.remove());
    }

    const create = this.createCompService.create(FlaDatePickerComponent);
    this._componentRef = create.componentRef;
    this._datePicker = this._componentRef.instance;
    this._element = create.rootNodeElement as HTMLElement;

    this._datePicker.element = this._rootElement;
    this._datePicker.closeClickOutside = this.closeClickOutside;
    this._datePicker.closeOnClick = this.closeOnClick;
    this._datePicker.minDate = this.minDate;
    this._datePicker.maxDate = this.maxDate;
    if (this.formControl.value) {
      this._datePicker.setDate(this.formControl.value);
    }

    this._datePicker.onClose.subscribe((value) => {
      if (value?.value) {
        this.formControl.setValue(value.value);
      }
      if (value?.closed) {
        this._destroyDatepicker();
      }
    });

    this._setPosition();
  }

  private _destroyDatepicker() {
    this._element.remove();
    this.createCompService.destroy(this._componentRef);
    this._initDatePicker = false;
  }

  private _registreControl() {
    this.formControl.valueChanges
      .pipe(
        skipWhile((res: any) => !isNullOrWhiteSpace(res)),
        takeUntil(this._onClose$)
      )
      .subscribe((value: any) => {
        if (isNaN(value)) return;
        this._datePicker.setDate(value);
      });
  }

  private _setPosition() {
    const root = this._rootElement.getBoundingClientRect();
    const picker = this._element.firstChild as HTMLElement;
    const heightPicker = 356;
    const widthPicker = 296;
    const bodyView = document.body;
    picker.style.position = 'fixed';
    picker.style.zIndex = '1050';

    if (this.align === 'right') {
      picker.style.left = root.right - widthPicker + 'px';
    } else {
      picker.style.left = root.left + 'px';
    }

    switch (this.position) {
      case 'auto':
        if (heightPicker + root.height + root.top >= bodyView.clientHeight) {
          picker.style.top = `${root.top - heightPicker}px`;
          break;
        }
        picker.style.top = `${root.bottom + 6}px`;
        break;

      case 'bottom':
        picker.style.top = `${root.bottom + 6}px`;
        break;

      case 'top':
        picker.style.top = `${root.top - heightPicker}px`;
        break;
    }

    document.body.append(this._element);
  }
}
