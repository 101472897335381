import { ChartModifierBase2D } from 'scichart/Charting/ChartModifiers/ChartModifierBase2D';
import { EChart2DModifierType } from 'scichart/types/ChartModifierType';
import { ModifierMouseArgs } from 'scichart/Charting/ChartModifiers/ModifierMouseArgs';

export class DisableMoveDecorationsModifier extends ChartModifierBase2D {
  readonly type: EChart2DModifierType = EChart2DModifierType.Custom;

  private baseId = '';

  constructor(elementId: string) {
    super();
    this.baseId = elementId;
  }

  override modifierMouseEnter(args: ModifierMouseArgs) {
    super.modifierMouseEnter(args);
    if (!this.isAttached) {
      throw new Error(
        'Should not call DisableMoveDecorationsModifier.modifierMouseEnter if not attached'
      );
    }
    /**
     * Por alguma razao, ao setar o adorner para display none, esta afetando os grips
     * dos desenhos que sao annotations.
     */
    /*document
      .getElementById(`${this.baseId}_Adorner`)
      ?.setAttribute('style', 'display:none');*/
  }
}
