<app-header-ranking-broker
  [refComponent]="refComponent"
  [component]="component"
  [isExplode]="isExplode"
  [enableChangePeriod]="enableChangePeriod"
  (changeView)="onChangeView($event)"
  (changePeriod)="onChangePeriod($event)"
  (changeStock)="onChangeStock($event)"
  (flaLink)="clickLink($event)"
></app-header-ranking-broker>
<app-ranking-broker-part
  [stockInput]="stock"
  [typeRanking]="typeRanking"
  [refComponent]="refComponent"
  [component]="component"
  [periodSelected]="period"
  [width]="width"
  [layoutOptions]="layoutOptions"
  (explode)="onExplode($event)"
  (periodUpdated)="setEnableChangePeriod()"
></app-ranking-broker-part>
