import { ChartModifierBase2D } from 'scichart/Charting/ChartModifiers/ChartModifierBase2D';
import { EChart2DModifierType } from 'scichart/types/ChartModifierType';
import { ModifierMouseArgs } from 'scichart/Charting/ChartModifiers/ModifierMouseArgs';
import { testIsInBounds } from 'scichart/utils/pointUtil';
import { Subject, auditTime } from 'rxjs';
import { DpiHelper } from 'scichart/Charting/Visuals/TextureManager/DpiHelper';
import {
  STOCK_ANALYSIS_MOUSE_EVENT_DATA_TYPE,
  STOCK_ANALYSIS_RENDERABLE_SERIES_ID,
} from '../../enum';
import { NumericAxis } from 'scichart';
import { TStockAnalysisMouseEvent } from '../../types';

export class StockAnalysisMouseEventsModifier extends ChartModifierBase2D {
  readonly type: EChart2DModifierType = EChart2DModifierType.Custom;
  private events$ = new Subject<TStockAnalysisMouseEvent>();
  private readonly EVENT_DELAY = 90;

  constructor() {
    super();
  }

  onEvents = () => this.events$.pipe(auditTime(this.EVENT_DELAY));

  override modifierMouseMove(args: ModifierMouseArgs) {
    super.modifierMouseMove(args);

    this._checkMouseAction(
      args,
      STOCK_ANALYSIS_MOUSE_EVENT_DATA_TYPE.SERIES_ELEMENT_HOVER
    );
  }

  override modifierMouseDown(args: ModifierMouseArgs) {
    super.modifierMouseMove(args);

    this._checkMouseAction(
      args,
      STOCK_ANALYSIS_MOUSE_EVENT_DATA_TYPE.SERIES_ELEMENT_MOUSE_CLICK
    );
  }

  private _checkMouseAction(
    args: ModifierMouseArgs,
    type: STOCK_ANALYSIS_MOUSE_EVENT_DATA_TYPE
  ) {
    if (!this.isAttached) {
      throw new Error(
        'Should not call DetectClicksOnChartPartsModifier.modifierMouseDown if not attached'
      );
    }

    if (!this.parentSurface) return;

    const mousePoint = args.mousePoint;
    const { left, right, top, bottom } = this.parentSurface.seriesViewRect;
    if (testIsInBounds(mousePoint.x, mousePoint.y, left, bottom, right, top)) {
      const rSeries = this.parentSurface?.renderableSeries.getById(
        STOCK_ANALYSIS_RENDERABLE_SERIES_ID
      );
      if (!rSeries) {
        return;
      }

      const xAxis = rSeries.xAxis as NumericAxis;
      const seriesRange = xAxis.getMaximumRange();
      const zoomMultiplier = seriesRange.diff / xAxis.visibleRange.diff;

      const mouseEvent = args.nativeEvent;
      const premultipliedX = mouseEvent.offsetX * DpiHelper.PIXEL_RATIO;
      const premultipliedY = mouseEvent.offsetY * DpiHelper.PIXEL_RATIO;
      const testData = rSeries.hitTestProvider.hitTestDataPoint(
        premultipliedX,
        premultipliedY,
        zoomMultiplier
      );

      this.dispatchEvents(type, testData.isHit ? testData : null);
    }
  }

  private dispatchEvents(
    type: STOCK_ANALYSIS_MOUSE_EVENT_DATA_TYPE,
    data?: any
  ) {
    this.events$.next({ type, data });
  }
}
