export const ID_CONSOLIDATED = -99; // = consolidada
export const ID_BROKER_SIMULADOR = 23; // = simulador
export const ID_BROKER_LEAGUE = 116; // = liga

export const isIdBrokerSimulator = (id_broker: number) =>
  id_broker === ID_BROKER_SIMULADOR;

export const isIdBrokerLeague = (id_broker: number) =>
  id_broker === ID_BROKER_LEAGUE;

export const isIdBrokerConsolidated = (id_broker: number) =>
  id_broker === ID_CONSOLIDATED;

export const isSimulatorOrLeague = (id_broker: number) =>
  isIdBrokerLeague(id_broker) || isIdBrokerSimulator(id_broker);

export const isSimulatorOrConsolidated = (id_broker: number) =>
  isIdBrokerConsolidated(id_broker) || isIdBrokerSimulator(id_broker);
