import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ICustodyChartInfos } from '@shared/services/api/nitro-custody-ws/v1/interface/custody.interface';
import { PerformanceHighchartsService } from '../../services/performance-highchart.service';
import { deepClone, randomId } from '@shared/rocket-components/utils';
import { Subject, Subscription, debounceTime, filter, takeUntil } from 'rxjs';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';

@Component({
  selector: 'app-chart-performance',
  templateUrl: './chart-performance.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartPerformanceComponent
  implements OnChanges, AfterViewInit, OnDestroy
{
  @ViewChild('chartContainer') public chartContainer!: ElementRef;
  @Input() chartData!: ICustodyChartInfos[];
  @Input() public isIntraDayInfos: boolean = false;

  private _highChart!: Highcharts.Chart;
  private _resize$!: ResizeObserver;
  private _initGraph$ = new Subject<void>();
  private _initGraphSubscription$!: Subscription;
  private _unsubscribe$ = new Subject<void>();

  public refChart: string = randomId('CHART_PERFORMANCE');

  constructor(
    private _performanceHighChartService: PerformanceHighchartsService,
    private _cdr: ChangeDetectorRef,
    private _themePreferences: ThemePreferencesService
  ) {
    this._buildChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { chartData } = changes;
    if (chartData?.currentValue) {
      this._initGraph$.next();
      this._cdr.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    this._resize$ = new ResizeObserver(() => {
      if (this._highChart) this._highChart.reflow();
    });
    this._resize$.observe(this.chartContainer.nativeElement);
    this._themePreferences
      .themeActiveObservable()
      .pipe(takeUntil(this._unsubscribe$), debounceTime(150))
      .subscribe(() => this._updateChartTheme());
  }

  ngOnDestroy(): void {
    this._highChart.destroy();
    this._resize$.unobserve(this.chartContainer.nativeElement);
    this._initGraphSubscription$ && this._initGraphSubscription$.unsubscribe();
    this._unsubscribe$ && this._unsubscribe$.unsubscribe();
  }

  private _buildChart = (): void => {
    this._initGraphSubscription$ = this._initGraph$
      .pipe(
        debounceTime(100),
        filter(() => this.chartData?.length > 0)
      )
      .subscribe({
        next: () => {
          this._highChart = this._performanceHighChartService.initGraph(
            this.refChart,
            this.isIntraDayInfos,
            deepClone(this.chartData).reverse(),
            this._themePreferences.activeTheme
          );
        },
        complete: () => this._cdr.detectChanges(),
      });
  };

  private _updateChartTheme() {
    if (!this._highChart?.options.tooltip) return;
    this._highChart.options.tooltip.formatter =
      this._performanceHighChartService.getTooltipFormater(
        this.refChart,
        this.isIntraDayInfos,
        deepClone(this.chartData).reverse(),
        this._themePreferences.activeTheme
      );
    this._highChart.redraw();
  }
}
