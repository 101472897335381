import { Injectable } from '@angular/core';
import { Dictionary } from '@core/models';
import {
  ConfigurationFastOrder,
  ITigerChartOrder,
  TemporaryInfo,
} from './tiger-chart-orders.interface';
import { IActiveStrategy } from '@shared/services/core/custom-preferences/strategy';
import { ISearchStock } from '@core/interface';
import { ReplaySubject, delay, Observable, of } from 'rxjs';
import {
  IStockChartOrderConfig,
  IStockChartSendOrderOptions,
} from '@shared/components/stock-chart/interface/stock-chart.interface';
import { TYPE_ORDE_ENUM } from '@shared/components/stock-trade/enum/stock-trade.enum';
import { CONTEXT_MENU_ORDER_TYPE } from '../enum';
import { TYPE_ORDE_SIDE_ENUM } from '@shared/enum/buyOrSell.enum';
import {
  DoubleStartStopValidation,
  calculatePriceOrderByOffset,
} from 'src/app/utils/utils.functions';

@Injectable({
  providedIn: 'root',
})
export class TigerChartOrdersService {
  private temporaryInfo = new Dictionary<TemporaryInfo>();
  private _configuration$ = new ReplaySubject<ConfigurationFastOrder>();

  get configuration() {
    return this._configuration$.asObservable();
  }

  setStrategy(
    key: string,
    dispatch: boolean,
    strategy?: IActiveStrategy,
    isSelected?: boolean
  ) {
    const temp: TemporaryInfo = {
      strategy,
      isSelectedStrategy: isSelected,
    };
    this.temporaryInfo.set(key, temp, true);
    dispatch && this._configuration$.next({ refComponent: key, ...temp });
  }

  getStrategy(key: string): {
    strategy?: IActiveStrategy;
    isSelected?: boolean;
  } {
    return {
      strategy: this.temporaryInfo.get(key)?.strategy,
      isSelected: this.temporaryInfo.get(key)?.isSelectedStrategy,
    };
  }

  getValueUpdatePrice(stock: ISearchStock): number {
    let value = 1;
    if (stock.ds_asset === 'DOL' || stock.ds_asset === 'WDO') {
      value = 0.5;
    } else if (stock.cd_security_type === 'FUT') {
      value = 5;
    }
    return value;
  }

  sendOrder(
    chartOrderInfo: IStockChartSendOrderOptions,
    options: ITigerChartOrder
  ): Observable<any> {
    const {
      stock,
      account,
      quant,
      price,
      lastVlClose,
      isFastOrder,
      componentId,
      minPriceIncrement,
      chartTradingOffset,
      refComponent,
      service,
    } = options;
    const strategy = this.getStrategy(componentId);
    const orderConfig: IStockChartOrderConfig = {
      stock,
      quant,
      account,
      ord_type: chartOrderInfo.ord_type
        ? chartOrderInfo.ord_type
        : TYPE_ORDE_ENUM.LIMITADA,
      ord_side:
        chartOrderInfo.typeOrder == CONTEXT_MENU_ORDER_TYPE.BUY
          ? TYPE_ORDE_SIDE_ENUM.BUY
          : TYPE_ORDE_SIDE_ENUM.SELL,
      price,
      isFastOrder,
      fastOrderStrategy: strategy.strategy,
      fastOrderIsSelectedStrategy: strategy.isSelected,
    };
    if (orderConfig.ord_type === TYPE_ORDE_ENUM.START_STOP) {
      if (chartOrderInfo.data.quantity) {
        orderConfig.quant = parseInt(`${chartOrderInfo.data.quantity}`);
      }
      /**
       * caso seja uma ordem com os gatilhos stop
       * fará os calculos de gain e loss.
       * caso contrário apenas adiciona os valores na configuração da boleta.
       */
      if (
        chartOrderInfo.data.gainTriggerPrice &&
        chartOrderInfo.data.lossTriggerPrice
      ) {
        const message = DoubleStartStopValidation(
          orderConfig.ord_side,
          lastVlClose,
          chartOrderInfo.data.gainTriggerPrice,
          chartOrderInfo.data.lossTriggerPrice
        );
        if (message) {
          return of({ doubleStartValidation: true, message });
        }
        const sideOrderByOffset =
          chartOrderInfo.typeOrder == CONTEXT_MENU_ORDER_TYPE.BUY
            ? TYPE_ORDE_SIDE_ENUM.BUY
            : TYPE_ORDE_SIDE_ENUM.SELL;
        const calculatePrice = (price: number) =>
          calculatePriceOrderByOffset({
            price,
            minPriceIncrement,
            offset: +chartTradingOffset!,
            side: sideOrderByOffset,
          });
        orderConfig.gainOrderPrice = calculatePrice(
          +chartOrderInfo.data.gainTriggerPrice!
        );
        orderConfig.lossOrderPrice = calculatePrice(
          +chartOrderInfo.data.lossTriggerPrice!
        );
        orderConfig.gainTriggerPrice = chartOrderInfo.data.gainTriggerPrice;
        orderConfig.lossTriggerPrice = chartOrderInfo.data.lossTriggerPrice;
        orderConfig.ord_type = TYPE_ORDE_ENUM.DOUBLE_START_STOP;
        if (orderConfig.quant % stock.standard_lot != 0) {
          orderConfig.useSplit = true;
          orderConfig.isConfirm = true;
        }
      } else {
        orderConfig.stopPx = chartOrderInfo.data.pointValueY;
        orderConfig.price = calculatePriceOrderByOffset({
          price: chartOrderInfo.data!.pointValueY,
          minPriceIncrement,
          offset: +chartTradingOffset!,
          side: chartOrderInfo.typeOrder!,
        });
      }
    }
    return service.newOrder(orderConfig, refComponent).pipe(delay(100));
  }
}
