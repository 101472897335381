import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  signal,
  WritableSignal,
  computed,
} from '@angular/core';
import { auditTime, filter, Subject, Subscription, tap } from 'rxjs';
import {
  IAccountSelect,
  IBroker,
  ISearchStock,
  IWorkSpaceComponet,
} from 'src/app/core/interface';
import { MultibrokerService } from '@shared/services/core/multibroker';
import {
  ICheetahCustodyPerformance,
  IPerformanceFilters,
  IPerformancePeriod,
} from '../../interface/performance-report.interface';
import { BrowserStorageBase } from '@shared/services/core/storage/browser-storage-base';
import { AUTH_SESSION_KEYS } from '@shared/services/core/const/auth_util.const';
import { CustodyPerformanceChannel } from '@shared/channel/custody-performance.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { Dictionary } from '@core/models';
import { PerformanceReportService } from '../../services/performance-report.service';
import {
  alphabeticalSort,
  isNullOrUndefined,
} from 'src/app/utils/utils.functions';
import {
  PERFORMANCE_CONTENT,
  PERFORMANCE_FILTERS,
} from '../../enum/performance-report.enum';
import {
  PERFORMANCE_CHART_PERIODS,
  PERFORMANCE_OPERATION_TYPE_FILTER,
  PERFORMANCE_CHART_DEFAULT_PERIOD,
  PERFORMANCE_PERIODS,
  PERFORMANCE_DEFAULT_PERIOD,
  performanceOperationTypeLabels,
} from '../../constants/performance-report.contants';
import { BrokerService } from '@shared/services/api/trademap/v1/broker.service';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { RocketStreamRead } from '@shared/channel/rx-event';
import { PerformanceReportData } from '../../performance-report';
import { ALL_STOCKS_LABEL } from '@shared/services/api/nitro-custody-ws/v1/custody.service';
import { sharedSessionStorage } from '@shared/services/core/storage/shared-session.storage';

@Component({
  selector: 'app-performance-report-header',
  templateUrl: './performance-report-header.component.html',
  styleUrls: ['./performance-report-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformanceReportHeaderComponent
  extends ReadStreamBase
  implements OnInit, OnChanges, OnDestroy
{
  @Input() refComponent!: string;
  @Input() component!: IWorkSpaceComponet;
  @Input() performanceSubscription: boolean = true;
  @Input() linked!: boolean;
  @Output() performanceHandler = new EventEmitter();
  @Output() changeTab: EventEmitter<PERFORMANCE_CONTENT> =
    new EventEmitter<PERFORMANCE_CONTENT>();
  @Output() performanceFiltersChange: EventEmitter<IPerformanceFilters> =
    new EventEmitter<IPerformanceFilters>();

  private _cheetahSubscribeParams!: SubscribeParam | undefined;
  private storage = new BrowserStorageBase(sharedSessionStorage);
  public dropnAutoClose: boolean = true;
  private lastValues!: PerformanceReportData | null;
  public performanceData: any = {};
  public typesOperation: string[] = PERFORMANCE_OPERATION_TYPE_FILTER;
  public typesOperationLabels: any = {};
  public typeOperationSelected: string = '';
  public stockList: string[] = [];
  public stockListFiltered: string[] = [];
  public checkedStocks = new Dictionary<boolean>();
  public stockSelected: string = ALL_STOCKS_LABEL;
  public readonly PERFORMANCE_CONTENT = PERFORMANCE_CONTENT;
  public currentContent = PERFORMANCE_CONTENT.INFOS;
  public periodsOptions!: IPerformancePeriod[];
  public selectedPeriod!: IPerformancePeriod;
  public accountSelected: WritableSignal<IAccountSelect | undefined> =
    signal(undefined);
  public accounts: IAccountSelect[] = [];
  public defaultStock: string = ALL_STOCKS_LABEL;
  private _updateSelectedStockSubject = new Subject<void>();
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private onAccountChange$: Subscription = new Subscription();
  private onUseDayTradeChange$: Subscription = new Subscription();
  private subscribedInPerformance: boolean = true;
  private _updateComponentStockAfterLoadCheetahInfos = false;
  private currentSubscription$!: RocketStreamRead;
  private _onGlobalStockChangeStream!: RocketStreamRead | undefined;

  get id_investor() {
    return this.storage.getItem(AUTH_SESSION_KEYS.ID_INVESTOR);
  }
  accountSelectedLabel = computed(() => {
    return this.accountSelected()?.label || '-';
  });

  constructor(
    private _mbService: MultibrokerService,
    private _brokerService: BrokerService,
    private _custodyPerformanceChannel: CustodyPerformanceChannel,
    private _performanceReportService: PerformanceReportService,
    private _daytradeService: DaytradeService,
    private _globalStock: GlobalSelectedStockSubscription,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.checkedStocks.set(ALL_STOCKS_LABEL, true);
  }

  custodyPerfSnapshot!: (data: any) => void;

  ngOnInit(): void {
    this._accountPreferences();
    this._periodOptionsHandler();
    this._getSimulatorOrMultibroker();
    this._startStreaming();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { performanceSubscription, linked } = changes;
    if (
      performanceSubscription &&
      !isNullOrUndefined(performanceSubscription.currentValue)
    )
      this._togglePerformanceSubscription(performanceSubscription.currentValue);

    if (linked && typeof linked.currentValue === 'boolean') {
      this._linkedGlobalStockHandler();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.onAccountChange$.unsubscribe();
    this.onUseDayTradeChange$.unsubscribe();
    this._unsubscribePerformanceChannel();
    this._onGlobalStockChangeStream?.close();
    this._updateSelectedStockSubject.unsubscribe();
  }

  private _accountPreferences() {
    this.onUseDayTradeChange$ = this._daytradeService.dayTradeMode
      .pipe(filter(() => !this.typeOperationSelected || this.linked))
      .subscribe((isDayTrade) => this._periodSelected(isDayTrade));
  }

  private async _startStreaming() {
    this.currentSubscription$ =
      await this._custodyPerformanceChannel.onEvents();
    this.readStream(
      this.currentSubscription$.stream,
      this._handlerCustodyPerformance
    );
  }

  private _subscribeCheetah(account?: IAccountSelect) {
    let broker = '';
    if (account) {
      broker = `.${account.id_broker}`;
    }
    this._cheetahSubscribeParams = {
      itemsArray: [`ID_INVESTOR_${this.id_investor}${broker}`],
    };
    this._custodyPerformanceChannel.subscribe(this._cheetahSubscribeParams);
    this.currentSubscription$ &&
      this.currentSubscription$.snapshot(
        this._cheetahSubscribeParams.itemsArray
      );
    this.subscribedInPerformance = true;
  }

  private _unsubscribePerformanceChannel(): void {
    if (!this._cheetahSubscribeParams) return;
    this._custodyPerformanceChannel.unsubscribe(this._cheetahSubscribeParams);
    this._cheetahSubscribeParams = undefined;
    this.subscribedInPerformance = false;
  }

  private _togglePerformanceSubscription(subscribe: boolean): void {
    if (subscribe && !this.subscribedInPerformance) {
      this._subscribeCheetah(this.accountSelected()!);
      return;
    }

    if (!subscribe && this.subscribedInPerformance) {
      this._unsubscribePerformanceChannel();
    }
  }

  private _linkedGlobalStockHandler(): void {
    if (this.linked) {
      this._periodSelected(this._daytradeService.useDayTradeMode);
      this.filterByBroker(this._mbService.getAccountSelected());
      this._getGlobalStock();
      this._updateSelectedStock();
      this._updateComponentStockAfterLoadCheetahInfos = true;
      return;
    }
    this._onGlobalStockChangeStream?.close();
    this._updateSelectedStockSubject.unsubscribe();
  }

  private async _getGlobalStock() {
    this._onGlobalStockChangeStream =
      await this._globalStock.onGlobalStockChange();
    this.readStream(
      this._onGlobalStockChangeStream.stream,
      this.onGlobalStockChange
    );
  }

  private onGlobalStockChange = (stock: ISearchStock) => {
    if (
      this.linked &&
      !this._updateComponentStockAfterLoadCheetahInfos &&
      stock.cd_stock !== this.stockSelected
    ) {
      this._selectGlobalStock(stock.cd_stock);
    }
  };

  private _updateSelectedStock(): void {
    if (this._updateSelectedStockSubject.closed)
      this._updateSelectedStockSubject = new Subject<void>();
    this._updateSelectedStockSubject
      .pipe(
        filter(
          () => this.linked && this._updateComponentStockAfterLoadCheetahInfos
        ),
        auditTime(50),
        tap(() => (this._updateComponentStockAfterLoadCheetahInfos = false))
      )
      .subscribe(() => {
        const stockSelected = this._globalStock.getGlobalStockSelected();
        this._selectGlobalStock(stockSelected.cd_stock);
      });
  }

  private _selectGlobalStock(cd_stock: string) {
    let stock = ALL_STOCKS_LABEL;
    if (this.stockListFiltered.length) {
      stock = this.stockListFiltered.find((item) => item === cd_stock) ?? stock;
    }
    this.filterByStock(stock);
  }

  private _getSimulatorOrMultibroker(): void {
    this._brokerService.processBrokers().subscribe({
      next: (brokers: Dictionary<IBroker>) => {
        brokers.values().forEach((broker: any) => {
          broker.label = broker.nm_broker_nickname;
          if (broker.id_broker !== 23) this.accounts.push(broker);
          else this.accounts.unshift(broker);
        });
      },
      error: (err) => console.error('ERROR_ON_FIND_BROKERS', err),
    });

    this.onAccountChange$ = this._mbService
      .onUpdateSelectedAccountChannel()
      .pipe(filter(() => !this.accountSelected() || this.linked))
      .subscribe((account) => this.filterByBroker(account));
  }

  private _handlerCustodyPerformance = (
    payload: Map<string, ICheetahCustodyPerformance[] | any[]>
  ) => {
    if (!this._cheetahSubscribeParams) return;
    const items = payload.get(this._cheetahSubscribeParams.itemsArray[0]);
    items?.forEach((data) => {
      if (!data || !this.accountSelected() || data.isEndOfSnap) return;
      this._processPerformanceData(data);
    });
  };

  private _processPerformanceData = (data: ICheetahCustodyPerformance) => {
    const { tradeType, stock, idBroker } = this._getKeyFields(data.key);

    const isDayTrade = tradeType === 'DAYTRADE';
    const isSwing = tradeType === 'SWING';
    const stockCode = stock === 'TOTAL' ? ALL_STOCKS_LABEL : stock;
    const typeMode = isDayTrade ? 'Intradia' : isSwing ? 'Swing' : 'Posições';
    this._updatePerformanceData(parseInt(idBroker), typeMode, stockCode, data);
  };

  private _getKeyFields(key: string) {
    const keyData = key.split('.');

    if (keyData.length == 3) {
      return {
        tradeType: 'TOTAL',
        stock: keyData[0],
        idBroker: keyData[2],
      };
    }

    return {
      tradeType: keyData[0],
      stock: keyData[1],
      idBroker: keyData[3],
    };
  }

  private _updatePerformanceData(
    idBroker: number,
    typeMode: 'Posições' | 'Intradia' | 'Swing',
    cdStock: string,
    performanceData: ICheetahCustodyPerformance
  ): void {
    if (!this.performanceData[idBroker]) {
      this.performanceData[idBroker] = {};
    }
    if (!this.performanceData[idBroker][typeMode]) {
      this.performanceData[idBroker][typeMode] = {};
    }
    if (!this.performanceData[idBroker][typeMode][cdStock]) {
      this.performanceData[idBroker][typeMode][cdStock] = {};
    }
    this.performanceData[idBroker][typeMode][cdStock] = performanceData;
    this._buildStocksAvailable();
    this.cdr.detectChanges();
  }

  private _buildStocksAvailable() {
    const stockList: string[] = [];
    if (!this.performanceData || !this.accountSelected()) {
      this._resetPerformanceData();
      return;
    }
    const performanceInfo =
      this.performanceData[this.accountSelected()!!.id_broker]?.[
        this.typeOperationSelected
      ];
    if (!performanceInfo || isNullOrUndefined(performanceInfo)) {
      this._resetPerformanceData();
      return;
    }
    new Map(Object.entries(performanceInfo)).forEach((item: any) => {
      const { stock } = this._getKeyFields(item.key);
      stock !== 'TOTAL' && stockList.push(stock);
    });
    this.stockListFiltered = alphabeticalSort(stockList);
    this.stockListFiltered.unshift(ALL_STOCKS_LABEL);
    this._filterPerformanceHandler();
    if (this._updateComponentStockAfterLoadCheetahInfos)
      this._updateSelectedStockSubject.next();
  }

  private _filterPerformanceHandler() {
    if (!this.accountSelected() || !this.typeOperationSelected) {
      return;
    }
    const values = this._performanceReportService.sumReports(
      this.performanceData,
      this.stockSelected,
      this.typeOperationSelected,
      this.accountSelected()!!.id_broker
    );
    if (
      values &&
      this.lastValues !== values &&
      values.id_broker === this.accountSelected()!!.id_broker
    ) {
      this.lastValues = values;
      this.performanceHandler.next(values);
      this.cdr.detectChanges();
    }
  }

  private _resetPerformanceData(): void {
    if (!this.lastValues) return;
    this.lastValues = null;
    this.performanceHandler.next(null);
    this.cdr.detectChanges();
  }

  private _resetCheckedStocks() {
    this.checkedStocks.clear();
    this.checkedStocks.set(ALL_STOCKS_LABEL, true);
    this.cdr.detectChanges();
  }

  private _periodOptionsHandler(): void {
    if (this.currentContent === PERFORMANCE_CONTENT.INFOS) {
      this.periodsOptions = PERFORMANCE_PERIODS;
      this.selectedPeriod = PERFORMANCE_DEFAULT_PERIOD;
    } else {
      this.periodsOptions = PERFORMANCE_CHART_PERIODS;
      this.selectedPeriod = PERFORMANCE_CHART_DEFAULT_PERIOD;
    }
    this.cdr.detectChanges();
  }

  private _periodSelected = (useDayTradeMode: boolean): void => {
    const currentPeriod = useDayTradeMode ? 'Intradia' : 'Swing';
    this.filterByTypes(currentPeriod);
  };

  public filterByBroker(broker: IAccountSelect) {
    if (this._cheetahSubscribeParams) this._unsubscribePerformanceChannel();

    this._subscribeCheetah(broker);
    this.accountSelected.set(broker);
    this.performanceFiltersChange.emit({
      type: PERFORMANCE_FILTERS.BROKER,
      value: broker,
    });
    this._resetPerformanceData();
    this.stockSelected = ALL_STOCKS_LABEL;
    this._resetCheckedStocks();
    this._filterPerformanceHandler();
    this._buildStocksAvailable();
    this.typesOperationLabels = performanceOperationTypeLabels(
      broker.id_broker
    );
    if (!this.typesOperationLabels[this.typeOperationSelected])
      this.typeOperationSelected = 'Posições';
    this.cdr.detectChanges();
  }

  public filterByTypes(type: string) {
    if (
      this.typeOperationSelected &&
      type.toUpperCase() === this.typeOperationSelected.toUpperCase()
    )
      return;
    this.performanceFiltersChange.emit({
      type: PERFORMANCE_FILTERS.OPERATION_TYPE,
      value: type,
    });
    this._resetPerformanceData();
    this.stockSelected = ALL_STOCKS_LABEL;
    this._resetCheckedStocks();
    this.typeOperationSelected = type;
    this._filterPerformanceHandler();
    this._buildStocksAvailable();
    this.cdr.detectChanges();
  }

  public filterByStock(stock: string) {
    this.performanceFiltersChange.emit({
      type: PERFORMANCE_FILTERS.STOCK,
      value: stock,
    });
    this._resetPerformanceData();
    this.stockSelected = stock;
    this._filterPerformanceHandler();
    this.cdr.detectChanges();
  }

  public tabHandler(changeTo: PERFORMANCE_CONTENT): void {
    this.currentContent = changeTo;
    this._periodOptionsHandler();
    this.changeTab.emit(changeTo);
    this.cdr.detectChanges();
  }

  public changePerformancePeriod(period: IPerformancePeriod): void {
    if (period.value === this.selectedPeriod.value) return;
    this.performanceFiltersChange.emit({
      type: PERFORMANCE_FILTERS.PERIOD,
      value: period,
    });
    this.selectedPeriod = period;
    this.cdr.detectChanges();
  }
}
