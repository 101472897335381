<ng-template>
  <div class="card" [ngClass]="css">
    <div class="hstack">
      <ng-content select="fla-icon"></ng-content>
      <ng-content select="[content]"></ng-content>
      <div class="mr-auto">
        <p class="card-title">{{ text }}</p>
        <small class="card-subtitle text-neutral-medium">
          {{ description }}
        </small>
      </div>
      <ng-container *ngIf="hideArrow">
        <rocket-button
          label
          type="icon"
          css="ml-3"
          (click)="clickButton($event)"
        >
          <fla-icon name="navigate_next" css="text-brand-primary"></fla-icon>
        </rocket-button>
      </ng-container>
    </div>
  </div>
</ng-template>
