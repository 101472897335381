import { Component, HostListener, Inject } from '@angular/core';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';

@Component({
  selector: 'app-modal-desktop-version',
  templateUrl: './modal-desktop-version.component.html',
  styleUrls: ['./modal-desktop-version.component.scss'],
})
export class ModalDesktopVersionComponent extends RocketModalRef {
  public downloads = [
    {
      os_name: 'Windows',
      link: 'https://cdn.rockettrader.com.br/rocket-trader/install/RocketTraderx64-setup.exe',
      pre_selected: false,
      image_icon: 'assets/logo-operational-system/windows.png',
    },
    {
      os_name: 'Mac',
      link: 'https://cdn.rockettrader.com.br/rocket-trader/install/RocketTrader.dmg',
      pre_selected: false,
      image_icon: 'assets/logo-operational-system/mac.png',
    },
    {
      os_name: 'Linux',
      link: 'https://cdn.rockettrader.com.br/rocket-trader/install/RocketTrader.AppImage',
      pre_selected: false,
      image_icon: 'assets/logo-operational-system/linux.png',
    },
  ];

  public downloadNotAvailable = false;

  @HostListener('document:keydown.escape')
  @HostListener('window:keydown.esc')
  onKeyScapePress() {
    this.close();
  }

  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: any,
    service: RocketModalService
  ) {
    super(service);
    this.checkSystem();
  }

  public checkSystem() {
    const userAgent = navigator.userAgent;
    const modelOS = userAgent.includes('Win')
      ? 'Windows'
      : userAgent.includes('Linux')
      ? 'Linux'
      : 'Mac';
    const idx = this.downloads.findIndex((os) => os.os_name === modelOS);
    this.downloads[idx].pre_selected = true;
  }

  public goToDownload(link: string) {
    window.open(link, '_blank')?.focus();
    this.close();
  }
}
