import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  RocketModalRef,
  RocketModalService,
  ROCKET_MODAL_DATA,
} from '@shared/rocket-components/components/index';
import { StockListService } from '@shared/services/api/trademap/V10/stock-list.service';
import { ToastService } from '@services/toast.service';
import { CreateList } from '../../configurations-list/create-list';
import { StockListRowModel } from '../../models/stock-table-row.model';
import { ListStocksService } from '@shared/services/core/list-stocks/list-stocks.service';

@Component({
  selector: 'app-duplicate-list-modal',
  templateUrl: './duplicate-list-modal.component.html',
})
export class DuplicateListModalComponent
  extends RocketModalRef
  implements OnInit
{
  public duplicateForm!: FormGroup;
  public isLoading = false;
  private stocks!: string[];

  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: any,
    private _rocketModalService: RocketModalService,
    private formBuilder: FormBuilder,
    private stockListService: StockListService,
    private toastService: ToastService,
    private listStocksService: ListStocksService
  ) {
    super(_rocketModalService);
  }

  ngOnInit(): void {
    this.createForm();
    this.searchStocks();
  }

  public onSave = () => this.createdList();
  public onClose = (idStockList: number = 0) => this.close(idStockList);

  private createForm() {
    this.duplicateForm = this.formBuilder.group({
      name: new FormControl(`${this.data.name} duplicada`, [
        Validators.required,
      ]),
    });
  }

  private searchStocks(): void {
    const list = this.listStocksService.getStockList(this.data.idStockList);
    this.stocks = list.stocks.map((stock: StockListRowModel) => stock.idUpdate);
  }

  public createdList(): void {
    this.isLoading = true;
    const createList = new CreateList(
      this.listStocksService,
      this.stockListService
    );
    createList
      .createdList(
        this.duplicateForm.controls['name'].value,
        this.stocks,
        false
      )
      .subscribe((idStockList: number) => {
        this.isLoading = false;
        if (idStockList) {
          this.toastService.showToast(
            'success',
            `Lista ${this.duplicateForm.controls['name'].value} criada com sucesso!`
          );
          this.onClose(idStockList);
        } else {
          this.toastService.showToast(
            'error',
            'Ocorreu um problema ao duplicar a lista, tente novamente.'
          );
        }
      });
  }
}
