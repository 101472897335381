import { Dictionary } from "@core/models";
import { BOLETA_COMPRA_BASECOMPONENT } from "./components/boleta-compra";
import { BOLETA_VENDA_BASECOMPONENT } from "./components/boleta-venda";
import { BOOK_COMPONENT } from "./components/book";
import { ORDER_HISTORY_COMPONENT } from "./components/order-history";
import { STOCK_CHART_COMPONENT } from "./components/stock-chart";
import { STOCK_TABLE_COMPONENT } from "./components/stock-table";
import { SUPER_DOM_COMPONENT } from "./components/super-dom";
import { TIMES_TRADES_COMPONENT } from "./components/times-trades";
import { AT_PRICE_COMPONENT } from "./components/at-price";
import { BUSINESS_PROFILE_COMPONENT } from "./components/business-profile";
import { FLOW_INVESTOR_COMPONENT } from "./components/flow-investor";
import { HEATMAP_COMPONENT } from "./components/heatmap";
import { MARKET_RESUME_COMPONENT } from "./components/market-resume";
import { NEWS_COMPONENT } from "./components/news";
import { OPTIONS_COMPONENT } from "./components/options";
import { PERFORMANCE_REPORT_COMPONENT } from "./components/performance-report";
import { RANKING_BROKER_COMPONENT } from "./components/ranking-broker";
import { RENT_BTC_COMPONENT } from "./components/rent-btc";
import { SCREENING_COMPONENT } from "./components/screening";
import { STOCK_ANALYSIS_COMPONENT } from "./components/stock-analysis";
import { TRADE_IDEA_COMPONENT } from "./components/trade-idea";

export const COMPONENTS_AND_TOOLS: Dictionary<any> = new Dictionary();
COMPONENTS_AND_TOOLS.bulkData('id', [
  //COMPONENTS  
  STOCK_TABLE_COMPONENT(),
  ORDER_HISTORY_COMPONENT(),
  STOCK_CHART_COMPONENT(),
  BOOK_COMPONENT(),
  SUPER_DOM_COMPONENT(),
  TIMES_TRADES_COMPONENT(),
  BOLETA_COMPRA_BASECOMPONENT(),
  BOLETA_VENDA_BASECOMPONENT(),

  //TOOLS
  MARKET_RESUME_COMPONENT(),
  NEWS_COMPONENT(),
  TRADE_IDEA_COMPONENT,
  BUSINESS_PROFILE_COMPONENT(),
  AT_PRICE_COMPONENT(),
  RANKING_BROKER_COMPONENT(),
  RENT_BTC_COMPONENT(),
  FLOW_INVESTOR_COMPONENT(),
  HEATMAP_COMPONENT(),
  STOCK_ANALYSIS_COMPONENT(),
  SCREENING_COMPONENT,
  OPTIONS_COMPONENT(),
  PERFORMANCE_REPORT_COMPONENT,
])