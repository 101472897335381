import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { STRATEGY_TOOLTIP } from '../account-selector/constant/account-select.constant';
import { IActiveStrategy } from '@shared/services/core/custom-preferences/strategy';
import { StrategyService } from '@core/layout/header/strategy/service/strategy.service';
import { Subject, Subscription, debounceTime } from 'rxjs';
import {
  ACTIVE_STRATEGY_TYPE,
  STRATEGY_TYPE_LABEL,
} from '@core/layout/header/strategy/enum/strategy.enum';
import {
  IStrategy,
  IStrategyCondition,
} from '@core/layout/header/strategy/interface/strategy.interface';
import { descendingSort } from 'src/app/utils/utils.functions';
import { OrsGatewayService } from '@shared/services/api/trademap/v1/ors-gateway.service';
import { TigerChartOrdersService } from '@shared/tiger-chart/tiger-chart-orders/tiger-chart-orders.service';
import { randomId } from '@shared/rocket-components/utils';
@Component({
  selector: 'app-strategy-selector',
  templateUrl: './strategy-selector.component.html',
  styles: [
    `
      :host(app-strategy-selector) {
        .strategySelector {
          height: 32px;

          .background-dropdown {
            z-index: 2;
            width: calc(100% - 0.875rem - 8px) !important;
          }
        }
      }

      ::ng-deep {
        .strategy-tooltip {
          display: grid !important;
          grid-template-columns: repeat(4, 1fr);
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StrategySelectorComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public currentStrategy!: IActiveStrategy | undefined;
  public strategies: IStrategy[] = [];
  public useStrategyMode: boolean = false;
  public strategyTooltip: string = '';
  public smallName?: string;
  private subjectDebounced = new Subject<void>();
  private _strategy$!: Subscription;
  private _strategies$!: Subscription;
  private _width!: number;
  @Input() customClass = '';
  @Input() isDropdown: boolean = false;
  @Input() isSelectable: boolean = false;
  @Input() isFastOrder: boolean = false;
  @Input() universalStrategy: boolean = true;
  @Input() refComponent!: string;
  @Input() set width(width: number) {
    this._width = width;
    this.getCurrentName();
  }
  isSelected: boolean = false;
  id = randomId('strategy_dropdown_selector');

  private initializeTotalSubscription(): void {
    this.subjectDebounced.pipe(debounceTime(133)).subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  constructor(
    private _orsGatewayService: OrsGatewayService,
    private _strategyService: StrategyService,
    private tigerChartOrdersService: TigerChartOrdersService,
    private cdr: ChangeDetectorRef
  ) {
    this.initializeTotalSubscription();
  }

  ngAfterViewInit() {
    this.getCurrentName();
  }

  ngOnInit() {
    this.subscriptions();
    this._verifyHasStrategyEnabled();
    this.fastOrderStrategy();
  }

  ngOnDestroy(): void {
    this._strategy$ && this._strategy$.unsubscribe();
    this._strategies$ && this._strategies$.unsubscribe();
  }

  public toggleStrategyMode(forceDisable?: boolean): void {
    const enableStrategy = forceDisable ? false : !this.useStrategyMode;
    if (enableStrategy) {
      const activeStrategy = this._strategyService.findActiveStrategy();
      if (
        !activeStrategy ||
        !activeStrategy.idStrategy ||
        activeStrategy.isDisabled
      ) {
        this.openModalStrategies();
        this.subjectDebounced.next();
        return;
      }
    }
    this._strategyService.setEnableStrategy(enableStrategy);
    this.useStrategyMode = enableStrategy;
    this._handleStrategyTooltips();
    this.subjectDebounced.next();
  }

  private _handleStrategyTooltips(): void {
    if (this.useStrategyMode && this.currentStrategy) {
      this.strategyTooltip = this._buildStrategyTooltip(this.currentStrategy);
    } else this.strategyTooltip = STRATEGY_TOOLTIP.STRATEGY_OFF;
    this.subjectDebounced.next();
  }

  public openModalStrategies(): void {
    const ref = this._strategyService.openStrategiesModal();
    ref.afterDismissed.subscribe((res: any) => {
      if (!res.closed && res.isSave) {
        this._strategyService.setEnableStrategy(true);
        this.useStrategyMode = true;
        this._handleStrategyTooltips();
        this.subjectDebounced.next();
      }
      this.findStrategies();
      setTimeout(() => {
        this._strategyService.dispatchUpdate(this.strategies);
      }, 100);
    });
  }

  private _verifyHasStrategyEnabled(): void {
    if (!this.universalStrategy) {
      return;
    }
    this.currentStrategy = this._strategyService.findActiveStrategy();
    this.useStrategyMode = this._strategyService.strategyIsEnabled();
    this._handleStrategyTooltips();
    this.subjectDebounced.next();
  }

  fastOrderStrategy() {
    if (this.universalStrategy) {
      return;
    }
    const strategy = this.tigerChartOrdersService.getStrategy(
      this.refComponent
    );
    if (strategy.strategy && Object.keys(strategy.strategy).length) {
      this.currentStrategy = strategy.strategy;
    }
    this.isSelected = !!strategy.isSelected;
    this.subjectDebounced.next();
  }

  private _buildStrategyTooltip(strategy: IActiveStrategy): string {
    if (strategy.type.toString() === 'Pontos')
      strategy.type = ACTIVE_STRATEGY_TYPE.TICK;
    return `
      <div style="min-width: 300px" class="w-100 d-flex flex-column">
        <span class="mb-1">Tipo: ${strategy.type}</span>
        ${this._buildStrategyCondition(strategy.items)}
      </div>
    `;
  }

  private _buildStrategyCondition(conditions: IStrategyCondition[]): string {
    let text: string = '';
    if (!conditions) return text;
    conditions = Array.isArray(conditions)
      ? conditions
      : Object.values(conditions);
    conditions.forEach((condition) => {
      text += `
        <div class="w-100 gap-1 strategy-tooltip">
          <span class="text-truncate text-left fs-6">Q(%): ${
            condition.quantity
          }</span>
          <span class="text-truncate text-left fs-6">G: ${
            this.currentStrategy?.type == 'Financeiro' ||
            this.currentStrategy?.type == 'Percentual'
              ? parseFloat(condition.gain.toString()).toFixed(2) +
                (this.currentStrategy?.type == 'Percentual' ? '%' : '')
              : condition.gain
          }</span>
          <span class="text-truncate text-left fs-6">L: ${
            this.currentStrategy?.type == 'Financeiro' ||
            this.currentStrategy?.type == 'Percentual'
              ? parseFloat(condition.loss.toString()).toFixed(2) +
                (this.currentStrategy?.type == 'Percentual' ? '%' : '')
              : condition.loss
          }</span>
          <span class="text-truncate text-left fs-6">Of: ${
            condition.offset
          }</span>
        </div>
      `;
    });
    return text;
  }

  private subscriptions() {
    const strategy$ = this._strategyService.handleStrategy$.subscribe(
      (params: IActiveStrategy | undefined) => {
        if (!this.universalStrategy) {
          return;
        }
        this.currentStrategy = params;
        this.toggleStrategyMode(true);
        //if (params && params.isDisabled) {
        //  this.toggleStrategyMode(true);
        //}
        this._handleStrategyTooltips();
        this.subjectDebounced.next();
      }
    );
    const strategies$ = this._strategyService.updateObservable.subscribe(
      (data) => {
        this.strategies = data;
        this.subjectDebounced.next();
      }
    );
    this.findStrategies();
    this._strategy$ = strategy$;
    this._strategies$ = strategies$;
    this.subjectDebounced.next();
  }

  private findStrategies() {
    this._orsGatewayService
      .getAllStrategies()
      .pipe((strategy) => strategy)
      .subscribe((strategies: IStrategy[]) => {
        if (Array.isArray(strategies) && strategies && strategies.length) {
          this.strategies = [
            ...strategies
              .map((strategy) => {
                strategy.strategyTypeLabel =
                  STRATEGY_TYPE_LABEL[strategy.strategyType];
                return strategy;
              })
              .sort((a, b) => descendingSort(a.idStrategy, b.idStrategy)),
          ];
        } else this.strategies = [];
        this.subjectDebounced.next();
      });
  }

  selectStrategy(strategy: IStrategy) {
    this.smallName = undefined;
    this.currentStrategy = {
      name: strategy.nameStrategy,
      idStrategy: strategy.idStrategy,
      type: ACTIVE_STRATEGY_TYPE[strategy.strategyType],
      items: strategy.strategyItems,
    };
    this.updateStrategy();
    this.getCurrentName();
    this.subjectDebounced.next();
  }

  activeStrategy() {
    if (!this.currentStrategy) {
      return;
    }
    this.tigerChartOrdersService.setStrategy(
      this.refComponent,
      true,
      undefined
    );
    this.strategyTooltip = '';
    this.isSelected = !this.isSelected;
    this.updateStrategy();
    this.getCurrentName();
    this.subjectDebounced.next();
  }

  private updateStrategy() {
    this.tigerChartOrdersService.setStrategy(
      this.refComponent,
      true,
      this.currentStrategy,
      this.isSelected
    );
    this.subjectDebounced.next();
    return;
  }

  private getCurrentName() {
    setTimeout(() => {
      const aDoc = document.querySelector(
        `#${this.id} app-dropdown .dropdown-toggle #currentNameFull`
      );
      let name = this.currentStrategy?.name || undefined;
      if (aDoc && name) {
        const aBoudingDoc = aDoc.getBoundingClientRect();
        name =
          aBoudingDoc.width > this._width
            ? `${name.substring(0, name.length - 10)}...`
            : undefined;
      }
      this.smallName = name;
      this.isSelected &&
        this.currentStrategy &&
        (this.strategyTooltip = this._buildStrategyTooltip(
          this.currentStrategy
        ));

      this.subjectDebounced.next();
    }, 10);
  }
}
