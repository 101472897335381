import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IWorkSpaceComponet } from '@core/interface';

@Component({
  selector: 'app-market-summary-header',
  templateUrl: './market-summary-header.component.html',
  styleUrls: ['./market-summary-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketSummaryHeaderComponent {
  @Input() component!: IWorkSpaceComponet;
  @Input() refComponent!: string;
}
