import { isNullOrUndefinedOrEmpty } from 'src/app/utils/utils.functions';

export const AGRESSOR_SIDES: any = {
  B: 'C',
  S: 'V',
  X: 'D',
  RLP: 'R',
  R: 'R',
  A: 'L',
};

export const DEF_AGRESSOR: any = {
  C: {
    description: 'Compra',
    code: 'C',
    class: 'text-multibroker-buy',
  },
  V: {
    description: 'Venda',
    code: 'V',
    class: 'text-multibroker-sell',
  },
  X: {
    description: 'Cross',
    code: 'X',
    class: 'text-white',
  },
  L: {
    description: 'Leilão',
    code: 'L',
    class: 'text-feedback-warning',
  },
};

export const defAgressorSide = (item: any) =>
  `${AGRESSOR_SIDES[item.agressor_side] ?? '-'}`;

export const isInvalidSituation = (situation: string) =>
  isNullOrUndefinedOrEmpty(situation) ||
  ['INATIVO', 'CONGELADO'].includes(situation);
