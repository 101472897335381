export const STATIC_COLORS = [
  '#ffffff',
  '#000000',
  '#607d8b',
  '#9e9e9e',
  '#d50000',
  '#ff80ab',
  '#c51162',
  '#ea80fc',
  '#aa00ff',
  '#b388ff',
  '#6200ea',
  '#8c9eff',
  '#304ffe',
  '#82b1ff',
  '#2962ff',
  '#80d8ff',
  '#0091ea',
  '#84ffff',
  '#00b8d4',
  '#a7ffeb',
  '#00bfa5',
  '#b9f6ca',
  '#00c853',
  '#ccff90',
  '#64dd17',
  '#f4ff81',
  '#aeea00',
  '#ffff8d',
  '#ffd600',
  '#ffe57f',
  '#ffab00',
  '#ffd180',
  '#ff6d00',
  '#ff9e80',
  '#dd2c00',
];
