import { GENERIC_INTRO_STEPS } from './gerenric.steps';

const TIMES_TRADES_INTRO_STEPS = {
  INITIAL: (component: Element) => ({
    title: 'Conheça o Negócios',
    intro:
      'O Negócios traz a quantidade de transações e volume negociado em tempo real de um determinado ativo para uma análise mais detalhada.',
    element: component,
  }),
  TIMES_TRADES_TABLE_TRADES: (component: Element) => ({
    title: 'Trades',
    intro:
      'Na parte central, você vai encontrar todos os trades do ativo em tempo real. Caso queira ver mais operações simultaneamente é só ampliar o componente do tamanho que preferir.',
    element: component.querySelector('cdk-virtual-scroll-viewport'),
  }),
  TRADES_INFOS: (component: Element) => ({
    title: 'Informações dos trades',
    intro: `
    Hora = Horário que ocorreu a operação
    C = Corretora compradora
    <br/>
    Preço = Preço negociado na operação
    <br/>
    Qtd = Quantidade de ativos do trade
    <br/>
    V = Corretora vendedora
    <br/>
    A = Agressor, sendo:
    <br/>
    <div class="ml-2">
      C = Compra
      <br/>
      V = Venda
      <br/>
      R = RLP
      <br/>
      D = Direto
      <br/>
      L = Leilão
    </div>
    `,
    element: component.querySelector('#TIMES_TRADES_TRADE_INFOS'),
  }),
  CONTEXT: {
    enum: 'CONTEXT_MENU',
    title: 'Configurações do componente',
    intro:
      'Ao clicar com o botão direito em cima do Negócios, você verá as configurações de funcionalidades disponíveis para ele.',
    element: document.querySelector('app-times-trades-context-menu > div'),
    position: 'bottom',
    preChange: function () {
      this.element = document.querySelector(
        'app-times-trades-context-menu > div'
      );
      this.position = 'bottom';
    },
  },
  CONTEXT_TOGGLE_VISIBILITY: {
    enum: 'CONTEXT_MENU',
    title: 'Ocultar funcionalidades',
    intro:
      'Oculte algumas das funcionalidades do Negócios, sendo elas a barra de pressão e detalhes.',
    element: document.querySelector('#TIMES_TRADES_CONTEXT_TOGGLE_FEATURES'),
    position: 'bottom',
    preChange: function () {
      this.element = document.querySelector(
        '#TIMES_TRADES_CONTEXT_TOGGLE_FEATURES'
      );
      this.position = 'bottom';
    },
  },
};

export const TIMES_TRADES_STEPS = (component: Element) => [
  TIMES_TRADES_INTRO_STEPS.INITIAL(component),
  GENERIC_INTRO_STEPS.STOCK_SELECTOR(component),
  GENERIC_INTRO_STEPS.FORCE_CHART(component),
  GENERIC_INTRO_STEPS.STOCK_DETAILS(component),
  TIMES_TRADES_INTRO_STEPS.TIMES_TRADES_TABLE_TRADES(component),
  TIMES_TRADES_INTRO_STEPS.TRADES_INFOS(component),
  TIMES_TRADES_INTRO_STEPS.CONTEXT,
  TIMES_TRADES_INTRO_STEPS.CONTEXT_TOGGLE_VISIBILITY,
  GENERIC_INTRO_STEPS.TOUR_END,
];
