import { DOCUMENT } from '@angular/common';
import { ComponentRef, inject, Inject, Injectable } from '@angular/core';
import { FlaToastConfig } from '../config/toast.config';
import { FlaIToast } from '../interface/toast.interface';
import { FlaToastComponent } from '../toast.component';
// @ts-ignore
import { Toast } from '@omni/omnitheme';
import {
  RocketCreateComponentService,
  ViewRefReturn,
} from '@shared/rocket-components/services';

import { isNullOrWhiteSpace } from '@shared/rocket-components/utils';

@Injectable()
export class FlaToastService {
  private container: HTMLElement | undefined = undefined;
  private toastList: Array<{ element: HTMLElement; toast: Toast }> = [];
  private document = inject(DOCUMENT);
  constructor(private service: RocketCreateComponentService) {}

  show(data: FlaIToast): ViewRefReturn {
    const create = this.service.create(FlaToastComponent);
    const element = create.rootNodeElement.firstChild as HTMLElement;
    create.componentRef.instance.data = new FlaToastConfig(
      element,
      data
    ).toastConfig;

    this.toastContainer(create.componentRef.instance.data);
    this.container!.append(element);

    setTimeout(() => {
      const toast = new Toast(element);
      if (data.isHtml)
        toast._element.innerHTML = this.formatColorWord(
          toast._element.innerHTML
        );
      this.toastList.push({ element, toast });
      toast.show();
      this.closedEnvent(element, create.componentRef);
    }, 100);

    return { afterDismissed: create.componentRef.instance.afterClosed() };
  }

  close(
    element: HTMLElement,
    componentRef: ComponentRef<FlaToastComponent>
  ): void {
    const obj = this.toastList.find((i) => i.element.id === element.id);

    if (obj) {
      obj.toast!.hide();
      obj.element!.remove();
      this.service.destroy(componentRef);
    }
  }

  private toastContainer(data: FlaIToast): void {
    const container = this.document.body.querySelector(
      '.toast-live[aria-live="polite"]'
    );
    if (!!container) {
      this.container = container.firstChild as HTMLElement;
      return;
    }
    const div = this.document.createElement('div');
    div.setAttribute('aria-live', 'polite');
    div.setAttribute('aria-atomic', 'true');
    div.classList.add('toast-live');

    this.container = this.document.createElement('div');
    this.container.classList.add(
      'toast-container',
      'position-absolute',
      'p-3',
      data.horizintalPosition!,
      data.verticalPosition!
    );

    if (!isNullOrWhiteSpace(data.translatePosition)) {
      this.container.setAttribute('class', data.translatePosition!);
    }

    div.append(this.container);
    this.document.body.appendChild(div);
  }

  private closedEnvent(
    element: HTMLElement,
    componentRef: ComponentRef<FlaToastComponent>
  ): void {
    element.addEventListener('hidden.bs.toast', () =>
      this.close(element, componentRef)
    );
  }

  private formatColorWord(_innerHtml: string) {
    _innerHtml = _innerHtml.replace(
      'Cancelada',
      '<span class="text-feedback-error pl-1 pr-1">Cancelada</span>'
    );
    return _innerHtml;
  }
}
