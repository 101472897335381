import { Injectable } from '@angular/core';
import { CHEETAH_CHANNEL } from '@shared/cheetah/cheetah.const';
import { MarketDispatcher } from '@shared/cheetah/dispatcher/market.dispatcher';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { RxEvent, StreamReadResponse } from './rx-event';

@Injectable({
  providedIn: 'root',
})
export class TradeByOrderChannel {
  instanciated!: boolean;
  static instance: TradeByOrderChannel;
  static channel = CHEETAH_CHANNEL.TRADE_BY_ORDER;
  constructor(
    private _rxEvent: RxEvent,
    private cheetahService: MarketDispatcher
  ) {
    return (TradeByOrderChannel.instance =
      TradeByOrderChannel.instance || this);
  }

  onEvents(): StreamReadResponse {
    return this._rxEvent.read(TradeByOrderChannel.channel);
  }

  subscribe(params: SubscribeParam) {
    this.cheetahService.subscribe({
      channel_cheetah: TradeByOrderChannel.channel,
      itemsArray: params.itemsArray,
    });
  }

  unsubscribe(params: SubscribeParam) {
    if (!params) return;
    this.cheetahService.unsubscribe({
      channel_cheetah: TradeByOrderChannel.channel,
      itemsArray: params.itemsArray,
    });
  }
}
