import {
  Component,
  ContentChildren,
  Input,
  QueryList,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
  OnInit,
  ElementRef,
} from '@angular/core';
import { TableHeaderComponent } from './parts/header/table-header.component';
import {
  ITableDropdownEvent,
  ITableHeaderDropdownOption,
} from './interfaces/table.interface';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { TableService } from './service/table.service';
import { RTDropdownDirective } from '../dropdown';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class TableComponent implements OnInit, OnDestroy {
  @ViewChild('scrollElementRef') private _scrollElementRef!: ElementRef;
  @Input() tableRef: string = '';
  @Input() containerCss: string = 'table-container';
  @Input() width: string = '100%';
  @Input() height: string = '100%';
  @Input() layout: 'auto' | 'fixed' = 'auto';
  @Input() css: string = 'table';
  @Input() loading: boolean = false;
  @Input() isChildren: boolean = false;
  @Input() componentID!: string;

  @Input() emitEventOnScroll: boolean = false;
  @Input() infiniteScrollEnabled: boolean = false;
  @Input() infiniteScrollDistance: number = 4;
  @Input() infiniteScrollDelay: number = 200;
  @Input() tableDropdownOptions: ITableHeaderDropdownOption[] = [];

  @Output() scrollElementRef = new EventEmitter<ElementRef>();
  @Output() Scroll = new EventEmitter<{
    type: 'SCROLL' | 'END_SCROLL';
    event: Event;
  }>();
  @ContentChildren(TableHeaderComponent)
  tableHeader!: QueryList<TableHeaderComponent>;
  @ViewChild(RTDropdownDirective)
  dropdownBtn!: RTDropdownDirective;

  private _dropdownEvent!: Subscription;
  private _scrollEventSubject = new Subject<{
    event: 'SCROLL_EVENT' | 'SCROLL_REF';
    value?: Event;
  }>();
  private _subjectEventDelay: number = this.infiniteScrollDelay;

  constructor(private _service: TableService) {
    this._dropdownEvent = this._service
      .getTableDropdownEvent()
      .subscribe((next: ITableDropdownEvent | null) => {
        if (next?.element) this.openDropdown(next.element);
      });

    this._scrollEventSubject
      .pipe(debounceTime(this._subjectEventDelay))
      .subscribe((params) => {
        if (params.event === 'SCROLL_EVENT') {
          this._onScroll(params.value!);
          return;
        }
        this.scrollElementRef.emit(this._scrollElementRef);
      });
  }

  ngOnInit(): void {
    this._scrollEventSubject.next({ event: 'SCROLL_REF' });
  }

  ngOnDestroy() {
    this._dropdownEvent.unsubscribe();
    this._scrollEventSubject.unsubscribe();
  }

  private _onScroll(event: Event) {
    const container = event.target as HTMLDivElement;
    const heightToScroll =
      container.scrollHeight * ((10 - this.infiniteScrollDistance) / 10);
    if (container.scrollTop + container.clientHeight == container.scrollHeight)
      this.Scroll.emit({ type: 'END_SCROLL', event });
    if (container.scrollTop >= heightToScroll) {
      this.Scroll.emit({ type: 'SCROLL', event });
    }
  }

  scrollDebounce(event: Event) {
    event.preventDefault();
    if (this.emitEventOnScroll) {
      this._subjectEventDelay = this.infiniteScrollDelay;
      this._scrollEventSubject.next({ event: 'SCROLL_EVENT', value: event });
    }
  }

  onResize() {
    this._service.updateWidthCell(this.componentID);
  }

  openDropdown(event: EventTarget) {
    this.dropdownBtn.toggleOnTarget(event);
  }
}
