export const kaufmanMovingAverageTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  A Média Móvel de Kaufman (KAMA) é uma média móvel adaptativa que ajusta
  dinamicamente seu peso com base na volatilidade do mercado. Isso permite que a
  média móvel se adapte rapidamente às mudanças nas condições do mercado,
  tornando-a mais precisa do que outras médias móveis tradicionais.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">
  O cálculo da Média Móvel de Kaufman envolve os seguintes passos:
</div>
<div class="mb-3">
  1. Calcula-se o EMA de 10 períodos do valor absoluto da diferença entre o
  preço de fechamento e o EMA de 10 períodos do preço de fechamento.
</div>
<div class="mb-3">
  2. Calcula-se o EMA de 10 períodos da volatilidade do mercado.
</div>
<div class="mb-3">
  3. Calcula-se o peso multiplicando a diferença entre os dois EMAs anteriores
  pelo fator de eficiência de Kaufman (geralmente 2/($n+1) $, onde $n$ é o
  número de períodos de EMA).
</div>
<div class="mb-3">
  4. Adiciona-se o peso atualizado ao preço de fechamento anterior multiplicado
  pelo complemento do peso atualizado.
</div>
<div class="mb-3">5. Repete-se o processo para cada período subsequente.</div>
<div class="fw-bolder">Exemplo de cálculo</div>
<div class="mb-3">
  Aqui está um exemplo de como calcular a média móvel de Kaufman.
</div>
<div class="mb-3">
  Suponha que os preços de fechamento de um ativo são os seguintes:
</div>
<div class="mb-3">
  &bull; Dia 1: 100<br />
  &bull; Dia 2: 105<br />
  &bull; Dia 3: 110<br />
  &bull; Dia 4: 115<br />
  &bull; Dia 5: 120
</div>
<div class="mb-3">
  Para calcular a média móvel de Kaufman para esse ativo, precisamos seguir os
  passos descritos anteriormente. Suponha que estamos usando um período de EMA
  de 10 dias e um fator de eficiência de 0,15.
</div>
<div class="mb-3">
  1. Calculamos o EMA de 10 dias do valor absoluto da diferença entre o preço de
  fechamento e o EMA de 10 dias do preço de fechamento:
</div>
<div class="mb-3">
  &bull; Dia 1: 0 <br />
  &bull; Dia 2: 0,5 <br />
  &bull; Dia 3: 1,05 <br />
  &bull; Dia 4: 1,65 <br />
  &bull; Dia 5: 2,4
</div>
<div class="mb-3">
  2. Calculamos o EMA de 10 dias da volatilidade do mercado:
</div>
<div class="mb-3">
  &bull; Dia 1: 0 <br />
  &bull; Dia 2: 0,5 <br />
  &bull; Dia 3: 0,83 <br />
  &bull; Dia 4: 1,13 <br />
  &bull; Dia 5: 1,42
</div>
<div class="mb-3">
  3. Calculamos o peso multiplicando a diferença entre os dois EMAs anteriores
  pelo fator de eficiência de Kaufman:
</div>
<div class="mb-3">
  &bull; Dia 1: 0 <br />
  &bull; Dia 2: 0,044 <br />
  &bull; Dia 3: 0,123 <br />
  &bull; Dia 4: 0,304 <br />
  &bull; Dia 5: 0,546
</div>
<div class="mb-3">
  4. Adicionamos o peso atualizado ao preço de fechamento anterior multiplicado
  pelo complemento do peso atualizado:
</div>
<div class="mb-3">
  &bull; Dia 1: 100 <br />
  &bull; Dia 2: 104,88 <br />
  &bull; Dia 3: 109,13 <br />
  &bull; Dia 4: 113,86 <br />
  &bull; Dia 5: 118,91
</div>
<div class="mb-3">5. Repetimos o processo para cada período subsequente.</div>
<div class="mb-3">
  Assim, a média móvel de Kaufman para este ativo nos últimos 5 dias seria:
</div>
<div class="mb-3">
  &bull; Dia 1: N/A <br />
  &bull; Dia 2: 104,88 <br />
  &bull; Dia 3: 109,13 <br />
  &bull; Dia 4: 113,86 <br />
  &bull; Dia 5: 118,91
</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  A Média Móvel de Kaufman (KAMA) pode ser usada da mesma forma que outras
  médias móveis, como uma ferramenta para ajudar a identificar tendências de
  preços. No entanto, a KAMA é mais adaptativa e pode ser mais precisa do que
  outras médias móveis tradicionais em condições de mercado voláteis.
</div>
<div class="mb-3">
  Uma possível estratégia de negociação com a KAMA é comprar quando o preço do
  ativo está acima da média móvel e vender quando o preço está abaixo dela. No
  entanto, é importante lembrar que nenhuma estratégia de negociação é infalível
  e que o desempenho passado não é garantia de desempenho futuro.
</div>
<div class="mb-3">
  É sempre importante realizar uma análise mais ampla do mercado, incluindo
  fatores fundamentais e técnicos, antes de tomar qualquer decisão de
  negociação.
</div>
<div class="mb-3">
  <img class="w-75" src="/assets/indicators/kama-como-funciona.png" />
</div>
<div class="fw-bolder">Como configurar</div>
<div class="fw-bolder">Valores</div>
<div class="text-decoration-underline">Período</div>
<div class="mb-3">
  O período a ser usado no cálculo da média móvel. 21 dias é o padrão.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/kama-entrada.png"
  />
</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">KAMA</div>
<div class="mb-3">
  Pode alternar a visibilidade da KAMA. Também pode selecionar a cor, a
  espessura e o estilo da linha do KAMA.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/kama-estilo.png"
  />
</div>
`;
