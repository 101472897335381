import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StockServiceRT } from '@services/api/nitro-ws/v1/stock.service';
import { StockTableComponent } from './stock-table.component';
import { ConfigHeaderModalComponent } from './modals/config-modal/config-header-modal.component';
import { StockTableHeaderComponent } from './header/stock-table-header.component';
import { StatusStockRendererComponent } from './stock-table-rendlers/status-stock/status-stock-renderer.component';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { NewListStockModalComponent } from './modals/new-list-stock-modal/new-list-stock-modal.component';
import { DuplicateListModalComponent } from './modals/duplicate-list-modal/duplicate-list-modal.component';
import { ManageListModalComponent } from './modals/manage-list-modal/manage-list-modal.component';
import { NewStockModalComponent } from './modals/new-stock-modal/new-stock-modal.component';
import { FlaFocusModule } from '@shared/directives/focus/focus.module';
import { StockTableContextMenuComponent } from './grid-config/stock-table-context-menu.component';
import { RocketGridModule, RocketGridService } from '@shared/rocket-grid';
import { TimerAuctionComponent } from './stock-table-rendlers/timer-auction.component';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import {
  FlaCardModule,
  FlaCheckModule,
  FlaIconModule,
  FlaInputModule,
  FlaListGroupModule,
  FlaTagModule,
  RocketButtonModule,
  RocketModalModule,
} from '@shared/rocket-components/components/index';
import { StockTableHeatmapModule } from '../stock-table-heatmap/stock-table-heatmap.module';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';
import { StockTableViewsModule } from '../stock-table-views/stock-table-views.module';
import { RocketIconsModule } from '@shared/rocket-icons/rocket-icons.module';
import { StockCardModule } from '@shared/rocket-components/components/stock-card/stock-card.module';
import { LoadingModule } from '../loading/loading.module';
import { StockSearchConfigModalModule } from '../stock-search-config-modal/stock-search-config-modal.module';
import { MostSearchModule } from '@shared/modals/most-search/most-search.module';
import { MoversOptionsComponent } from './header/components/movers-options/movers-options.component';
import { ChangeViewComponent } from './header/components/change-view/change-view.component';
import { MoversHeaderOptionsComponent } from './header/components/movers-header-options/movers-header-options.component';

@NgModule({
  imports: [
    CommonModule,
    FlaIconModule,
    FlaCardModule,
    FlaCheckModule,
    FlaTagModule,
    FlaWorkSpaceModule,
    FlaInputModule,
    FlaFocusModule,
    FlaListGroupModule,
    FormsModule,
    LoadingModule,
    ReactiveFormsModule,
    RocketButtonModule,
    RocketGridModule,
    RocketIconsModule,
    RocketModalModule,
    RTDropdownModule,
    StockTableHeatmapModule,
    StockTableViewsModule,
    StockCardModule,
    TooltipsModule,
    StockSearchConfigModalModule,
    MostSearchModule,
  ],
  declarations: [
    StockTableComponent,
    ConfigHeaderModalComponent,
    StockTableHeaderComponent,
    StatusStockRendererComponent,
    NewListStockModalComponent,
    DuplicateListModalComponent,
    ManageListModalComponent,
    NewStockModalComponent,
    StockTableContextMenuComponent,
    TimerAuctionComponent,
    ChangeViewComponent,
    MoversOptionsComponent,
    MoversHeaderOptionsComponent,
  ],
  exports: [StockTableComponent, StockTableHeaderComponent],
  providers: [StockServiceRT, RocketGridService],
})
export class StockTableModule {}
