import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { RocketModalService } from '@shared/rocket-components/components/index';
import {
  deepClone,
  isNullOrWhiteSpace,
  standardizeDate,
  stringToDate,
} from '@shared/rocket-components/utils';
import { HomeService } from '@modules/home/service/home.service';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import {
  CustodyInfos,
  IInterval,
  IPosition,
  IStockChartConfig,
  IStockChartSendOrderOptions,
} from './interface/stock-chart.interface';
import { StockChartService } from './service/stock-chart.service';
import {
  getCdStockParam,
  isCrypto,
  isNullOrUndefined,
  objectAssign,
  startStopValidation,
} from 'src/app/utils/utils.functions';
import {
  auditTime,
  catchError,
  debounceTime,
  filter,
  forkJoin,
  from,
  map,
  mergeMap,
  ReplaySubject,
  Subject,
  Subscription,
  takeUntil,
  tap,
} from 'rxjs';
import { ORDER_TYPE_DIC } from '@shared/dictionary/order.dictionary';
import { ChartBase } from './model/stock-chart.model';
import {
  COVERAGE_PRICE_ID,
  INDICATORS,
  NEW_LINE_ID,
} from './constants/stock-chart.constant';
import { CandleChannel } from '@shared/channel/candle.channel';
import { StockChartHelper } from './stock-chart.helper';
import { WorkSpaceConfigs } from '@core/workspace/config/workspace.configs';
import { TigerChartComponent } from '@shared/tiger-chart/tiger-chart.component';
import {
  TCandleLineOptions,
  TCorporateEvents,
  TData,
  TEvents,
  TNewsChartEvents,
  TRelevantEvents,
} from '@shared/tiger-chart/types/tiger-chart.types';
import { DEFAULT_CONFIGS } from '@shared/tiger-chart/constants/default.configs';
import {
  StockChartModalIntervalComponent,
  StockChartModalMoreOptionsComponent,
} from './parts';
import { BaseIndicator } from '@shared/tiger-chart/indicators';
import {
  ICandleConfigs,
  ICandleContextMenu,
  ITigerChartTourEventEmitter,
} from '@shared/tiger-chart/interface';
import {
  CONTEXT_MENU_ITEM_ENUM,
  CONTEXT_MENU_ORDER_TYPE,
  TIGER_DOUBLE_STOP_ORDER_IDS,
  TIGER_INDICATORS_ENUM,
  TIGER_INTERVAL_ENUM,
  TIGER_LINE_TYPE,
  TIGER_TYPE_CHART_ENUM,
} from '@shared/tiger-chart/enum';
import { TYPE_ORDE_ENUM } from '@shared/components/stock-trade/enum/stock-trade.enum';
import { TYPE_ORDE_SIDE_ENUM } from '@shared/enum/buyOrSell.enum';
import { ToastService } from '@services/toast.service';
import { TalibService } from '@shared/tiger-chart/services/talib.service';
import { AlertService } from '@services/api/trademap/v1/alert.service';
import { formatNumber } from '@angular/common';
import { ProcessAlertService } from '@services/core/alert/process-alerts.service';
import { CustodyChannel } from '@shared/channel/custody.channel';
import { ToggleIndicatorService } from './service/toggle-indicator.service';
import {
  isStartStop,
  isDoubleStartStop,
  isTypeStockIndex,
} from '@shared/constants/general.contant';
import { TPOrderStatus } from 'src/app/core/models/order.model';
import {
  ALERT_STATUS_ENUM,
  IAccountSelect,
  IAlert,
  IOrders,
  IOrderType,
  ISearchStock,
  IWorkSpaceComponet,
} from 'src/app/core/interface';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { saveConfiguration } from '@shared/tiger-chart/tiger-chart-tools/tiger-chart-tools.interface';
import { TYPE_CHART_UPDATE } from './parts/modal-more-options/enum/stock-chart-modal.enum';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { QuoteData } from '@shared/channel/interface/quote.channel.interface';
import { SuperSearchService } from 'src/app/modules/home/super-search.service';
import { cantProcessKeyboardEvent } from 'src/app/utils/utils.keyboard';
import { StockChartModalService } from './parts/modal-more-options/service/stock-chart-modal.service';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import { TFooterType } from '@shared/tiger-chart/tiger-chart-footer/tiger-chart-footer.enum';
import { TigerChartOrdersService } from '@shared/tiger-chart/tiger-chart-orders/tiger-chart-orders.service';
import { IActiveStrategy } from '@shared/services/core/custom-preferences/strategy';
import { StrategyService } from '@core/layout/header/strategy/service/strategy.service';
import { CustodyStopOrderService } from '@shared/tiger-chart/custody-stop-orders/custody-stop-order.service';
import { MultibrokerService } from '@shared/services/core/multibroker';
import { SettingsModalService } from '@shared/tiger-chart/indicators/settings-modal/settings-modal.service';
import { StockChartHeaderComponent } from './parts/header/stock-chart-header.component';
import { JoystickService } from '@shared/services/joystick.service';
import { OrderTokenService } from '@shared/rocket-components/modal-order-token/order-token.service';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { OriginAnalysisOrderService } from '@shared/services/origin-analysis-order.service';
import { ORDER_PARAM_HELPER } from '@shared/constants/order-param-helper.const';
import { GlobalChartConfiguration } from './parts/modal-stock/global-chart-configuration';
import { FiltersService } from '@shared/tiger-chart/services/filters.service';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '@shared/services/orders.service';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { RocketStreamRead } from '@shared/channel/rx-event';
import { StockServiceRT } from '@shared/services/api/nitro-ws/v1/stock.service';
import {
  ConfigurationFastOrder,
  ITigerChartOrder,
} from '@shared/tiger-chart/tiger-chart-orders/tiger-chart-orders.interface';
import { StockChartChannelService } from './service/stock-chart-channel.service';
import { isWebViewContext } from 'src/app/desktop/integration.service';
import { StockPreferencesService } from '@shared/services/stock-preferences.service';
import { ComponentsService } from '@core/workspace/service/components.service';

@Component({
  selector: 'app-stock-chart',
  templateUrl: './stock-chart.component.html',
  styleUrls: ['./stock-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockChartComponent
  extends RocketComponentBase
  implements OnInit, OnDestroy
{
  @Input() set focused(focused: boolean) {
    this.tigerChartViewChild?.focus(focused);
  }
  @Input() component!: IWorkSpaceComponet;
  @Input() set height(value: string) {
    const numberValue = this.toNumber(value) - 46;
    this.updateStockChartConfigs({
      height: numberValue,
    });
    if (this.tigerChartViewChild) this.tigerChartViewChild.height = numberValue;
  }
  @Input() set width(value: string) {
    this.updateStockChartConfigs({
      width: this.toNumber(value),
    });
    if (this.tigerChartViewChild)
      this.tigerChartViewChild.width = this.toNumber(value);
    this.componentWidth = this.toNumber(value);
  }
  @ViewChild('stockChartHeader')
  stockChartHeaderViewChild!: StockChartHeaderComponent;
  @ViewChild('tigerChartWrapper')
  tigerChartWrapperViewChild!: ElementRef<HTMLDivElement>;
  @ViewChild(TigerChartComponent) tigerChartViewChild!: TigerChartComponent;
  @ViewChild(StockChartModalIntervalComponent)
  intervalModal!: StockChartModalIntervalComponent;
  removeLine!: string;
  data: TData | null = null;
  events: TEvents | undefined = undefined;
  addIndicator!: BaseIndicator[];
  addDraws!: saveConfiguration[];
  refComponent!: string;
  candleParams!: SubscribeParam | undefined;
  quoteParams!: SubscribeParam | undefined;
  volumeParams!: SubscribeParam | undefined;
  bookParams!: SubscribeParam | undefined;
  lastDate!: number;
  stockChartConfigs!: IStockChartConfig;
  intervalChartConfigs!: IInterval;
  alerts!: any;
  lastVlClose!: number;
  componentWidth!: number;
  custody_qty: string = '0';
  settings!: Partial<ICandleConfigs>;
  isLoading: boolean = true;
  isLog: boolean = false;
  isFreeScale: boolean = true;
  showFastOrder = false;
  showFastOrderAux = false;
  showChartBook = false;
  showBookButton = true;
  showFastOrderDaytradeStrategy = false;
  hasBreakEven = false;
  fastOrderWidth!: number | undefined;
  displayFastRuler = true;
  userCustody!: IPosition | undefined;
  showChartVolumeAtPrice = false;
  showVolume = true;
  zoomIn = false;
  showEvents: boolean = true;
  showExecOrders: boolean = false;
  showFlagOrders: boolean = false;
  showOrderMarker: boolean = false;
  showOpenFlag: boolean = false;
  showPreviousClose: boolean = false;
  showBidAsk: boolean = false;
  isInit = false;
  forceUseDefaultHeaderType: boolean = false;
  minPriceIncrement = 0;
  withTools: boolean = false;
  defaultCursor!: string;
  isDesktop = false;
  private onRestart: boolean = false;
  private isChangeInterval: boolean = false;
  private positions = new Map<string, IPosition>();
  private getDataByStock$!: Subscription;
  private _words: string = '';
  private _modalOpned: boolean = false;
  private _loadingMoreData$: boolean = false;
  private _keyPress$ = new Subject<string>();
  private _onDestroy = new Subject<void>();
  private _account!: IAccountSelect;
  private _legSubscribed!: string;
  private _negotiationLot!: number;
  private _newLineId = NEW_LINE_ID;
  private _indicators: BaseIndicator[] = [];
  private _onAccountChange$: Subscription = new Subscription();
  private _sendOrder$: Subscription = new Subscription();
  private _started$: Subscription = new Subscription();
  private _dayTradeMode$: Subscription = new Subscription();
  private _joystick$: Subscription = new Subscription();
  private _configuration$: Subscription = new Subscription();
  private _indicatorConfig$: Subscription = new Subscription();
  private closedModalToken$: Subscription = new Subscription();
  private _orderChannel$: Subscription = new Subscription();
  private _custodyChannel$: Subscription = new Subscription();
  private _alerts$: Subscription = new Subscription();
  private _alertCharts$: Subscription = new Subscription();
  private _onDataLoad$ = new ReplaySubject<{ isChangeInterval: boolean }>();
  private onUpdateMetadata$ = new ReplaySubject<void>();
  private _updateAlertsInChart$: Subscription | null = null;
  private _isSelectedStrategy: boolean | undefined = false;
  private _selectedStrategy!: IActiveStrategy | undefined;
  private _previousStock!: Partial<ISearchStock>;
  private dontUpdateFreeScale: boolean = false;
  private _funcsPreviusValue: any = {};
  private globalItem: string = '';
  private timesTriedStockExpired = 1;
  private trySynonymousStock: boolean = false;
  private subscribeUpdateChartConfig$!: Subscription;
  private nomeCompanhia: string | undefined = '';
  private _chartBase = new ChartBase();
  private _addOrUpdateOrder$ = new Subject<{ order: any }>();
  private addOrUpdateOrder$!: Subscription;
  private datesToEvents: number[] = [];
  private endChart: boolean = false;
  private isBreakEvenClicked: boolean = false;

  get tickSize(): number {
    if (isCrypto(this.stockChartConfigs.stock.ds_type!)) {
      return 0;
    }
    return this.stockChartConfigs.stock.tick_size_denominator ?? 2;
  }

  get headerComponentData() {
    return this.component.metadata.headerOptions.component?.data;
  }

  get stockSelected() {
    return getCdStockParam(this.stockChartConfigs.stock);
  }

  get intervalSelected() {
    return (
      this.stockChartConfigs?.chart?.series?.interval ||
      TIGER_INTERVAL_ENUM.ONE_MINUTE
    );
  }

  get idExchangeSelected() {
    return this.stockChartConfigs.stock.id_exchange!;
  }

  get metadataComponent() {
    return this.component.metadata.component;
  }

  get configStock() {
    return this.stockChartConfigs?.stock;
  }

  set configStock(value: any) {
    this.stockChartConfigs.stock = value;
  }

  get headerOptions() {
    return this.component.metadata?.headerOptions;
  }

  get chartTradingOffset() {
    return this.stockChartConfigs.chart.chartTradingOffset!;
  }

  private get isDaytradeOrders() {
    return this._dayTradeService?.useDayTradeMode;
  }

  constructor(
    private stockChartService: StockChartService,
    private _homeService: HomeService,
    private _modal: RocketModalService,
    private _candleChannel: CandleChannel,
    private stockChartHelper: StockChartHelper,
    private _workspaceConfigs: WorkSpaceConfigs,
    private _stockService: StockServiceRT,
    private _toastService: ToastService,
    private _talibService: TalibService,
    private _ordersService: OrdersService,
    private _custodyChannel: CustodyChannel,
    private _alertService: AlertService,
    private _processAlertService: ProcessAlertService,
    private _multibrokerService: MultibrokerService,
    private _toggleIndicator: ToggleIndicatorService,
    private _customPreferencesService: CustomPreferencesService,
    private _quoteChannel: QuoteChannel,
    private _superSearchService: SuperSearchService,
    private _stockChartModalService: StockChartModalService,
    private _dayTradeService: DaytradeService,
    private _tigerChartOrdersService: TigerChartOrdersService,
    private _strategyService: StrategyService,
    private _custodyStopOrdersService: CustodyStopOrderService,
    private _settingsModalService: SettingsModalService,
    private cdr: ChangeDetectorRef,
    private joystickService: JoystickService,
    private orderTokenService: OrderTokenService,
    private _globalStock: GlobalSelectedStockSubscription,
    private originAnalysisOrderService: OriginAnalysisOrderService,
    private filtersService: FiltersService,
    @Inject(LOCALE_ID) private locale: string,
    activatedRoute: ActivatedRoute,
    private ordersService: OrdersService,
    private stockChartChannelService: StockChartChannelService,
    private _stockPreferencesService: StockPreferencesService,
    private componentsService: ComponentsService
  ) {
    super(activatedRoute);
    this.isDesktop = isWebViewContext();
    this.initializeReadStreams();
  }

  custodyCurrentSubscription!: RocketStreamRead;
  quoteCurrentSubscription!: RocketStreamRead;
  candleCurrentSubscription!: RocketStreamRead;

  private initializeReadStreams = async () => {
    this.custodyCurrentSubscription = await this._custodyChannel.readEvents();
    this.readStream(this.custodyCurrentSubscription.stream, (data: any) =>
      this.custodyDataHandler(data)
    );

    this.quoteCurrentSubscription = await this._quoteChannel.readEvents();
    this.readStream(this.quoteCurrentSubscription.stream, this.quoteHandler);

    this.candleCurrentSubscription = await this._candleChannel.onEvents();
    this.readStream(this.candleCurrentSubscription.stream, this.channelHandler);
  };

  protected initComponent(component: any): void {
    if (component) this.component = component;
    this.componentInit();
  }

  ngOnDestroy(): void {
    this.stockChartChannelService.unsubscribeChannels({
      quote: this.quoteParams,
      candle: this.candleParams,
      volume: this.volumeParams,
      book: this.bookParams,
    });
    this.tigerChartViewChild.unsubscribeChartOrder();
    this._onDestroy.next();
    this._onDestroy.complete();
    this.verifyIsLoadingData();
    this.candleCurrentSubscription.close();
    this.quoteCurrentSubscription.close();
    this.custodyCurrentSubscription.close();
    this.unsubscriber();
  }

  initHandler = async () => {
    const response: any = await this.stockChartService.loadInitialUserConfig(
      this.metadataComponent
    );
    this.initializeScrollChartListener();
    this.configureStockChart(response.configs);
    this.configureGlobalSettings();
    this.updateChartSettings();
    this.updateStrategy();
    this.updateNegotiationQuantityStock();
    this.subscribeToOrderChannel();
    this.isLoading = false;
    this.cdr.detectChanges();
  };

  private initializeScrollChartListener() {
    this.stockChartService.scrollToBegin$
      .pipe(
        takeUntil(this._onDestroy),
        filter((ref) => ref === this.component.id),
        auditTime(100)
      )
      .subscribe(() => {
        this.onScrollToBegin();
      });
  }

  private updateNegotiationQuantityStock() {
    if (this.configStock.cd_stock) {
      this.changeNegotiationLot(
        this.settings.negotiationLot || this.configStock.standard_lot
      );
      this.processChartCommands();
    }
  }

  private configureStockChart(configs: ICandleConfigs) {
    this.stockChartConfigs = deepClone(configs);
    if (this.componentWidth?.toString()?.includes('%')) {
      if (this.tigerChartViewChild) {
        this.tigerChartViewChild.height = this.stockChartConfigs.chart.height!!;
        this.tigerChartViewChild.width = this.stockChartConfigs.chart.width!!;
      }
      this.componentWidth = this.stockChartConfigs.chart.width!!;
    }
    this._previousStock = this.stockChartConfigs.stock;
    this.intervalChartConfigs = this._chartBase.intervalDic.get(
      this.intervalSelected
    )!;
    this.stockChartService.intervalSelected.next(this.intervalSelected);
    if (this.stockChartConfigs.chart.chartTradingOffset === undefined) {
      this.stockChartConfigs.chart.chartTradingOffset = 30;
      this.updateStockChartConfigs(this.stockChartConfigs.chart);
    }
  }

  private configureGlobalSettings() {
    const configuration = this._stockChartModalService.getGlobalChartConfigs();
    this.showChartBook = configuration.showChartBook;
    this.displayFastRuler = !configuration.fastRulerNavigation;
    this.isLog = configuration.useLogarithmic;
    this.isFreeScale = configuration.isFreeScale;
    this.showChartVolumeAtPrice = configuration.showChartVolumeAtPrice;
    this.showPreviousClose = configuration.showPreviousClose;
    this.showBidAsk = configuration.showBidAsk;
    this.showExecOrders = configuration.showExecOrders;
    this.showFlagOrders = configuration.showFlagOrders;
    this.showOpenFlag = configuration.showOpenFlag;
    this.showOrderMarker = configuration.showOrderMarker;
  }

  private updateChartSettings() {
    this.showFastOrderDaytradeStrategy =
      this.stockChartConfigs.chart.showFastOrderDaytradeStrategy!!;
    this.hasBreakEven = this.stockChartConfigs.chart.showBreakEven!!;
    this.fastOrderWidth = this.stockChartConfigs.chart.fastOrder?.width;
    this.showFastOrder = this._displayFastOrder();
    if (this.showFastOrder && isTypeStockIndex(this.configStock.type)) {
      this.showFastOrder = false;
      this.showFastOrderAux = true;
    }
    this._selectedStrategy = this.stockChartConfigs.chart.fastOrder?.strategy;
    this._isSelectedStrategy =
      this.stockChartConfigs.chart.fastOrder?.isSelectedStrategy;
    this.defaultCursor =
      this.stockChartConfigs.chart?.defaultCursor ?? 'Cursores_CRUZ';
    this.withTools = this.stockChartConfigs.chart?.withTools ?? true;
    this.showEvents = this.stockChartConfigs.chart?.showEvents ?? true;
    const configs = JSON.parse(this._customPreferencesService.chartContextMenu);
    this.showVolume = configs?.volumeVisibility;
    this.isBreakEvenClicked = this._customPreferencesService.breakEvenIsClicked;
    this.settings = this.stockChartConfigs.chart;
  }

  private updateStrategy() {
    this._tigerChartOrdersService.setStrategy(
      this.component.id,
      false,
      this._selectedStrategy,
      this._isSelectedStrategy
    );
  }

  private subscribeToOrderChannel() {
    this._subscribeOrderChannel();
  }

  async componentInit() {
    this.initializeCustodyChannel();
    this.initializeAddOrUpdateOrder();
    this.initializeMetadataUpdate();
    this.initializeHeaderComponent();
    this.subscribeUpdateChartConfig();
    this.subscribeClickOnCustody();
    await this.initHandler();
    this.initializeListeners();
  }

  private subscribeClickOnCustody() {
    this.stockChartService.clickOnCustody$
      .pipe(takeUntil(this._onDestroy))
      .subscribe((boolean) => {
        this.isBreakEvenClicked = boolean;
      });
  }

  private initializeCustodyChannel() {
    this._custodyChannel.onRestart().subscribe(() => {
      this.onRestart = true;
      this.clearCheetahParams();
      this._updateStockAfterChange(this.configStock, true);
    });
  }

  private initializeAddOrUpdateOrder() {
    this.addOrUpdateOrder$ = this._addOrUpdateOrder$
      .pipe(
        filter(({ order }) => order.isSnap || order.isReconnect),
        map(({ order }) => (order.isSnap ? order.values : order.orders) ?? []),
        mergeMap((ordersArray) =>
          from(ordersArray).pipe(
            filter((order) =>
              this.filtersService.orderChannelFilter(
                order,
                this.configStock,
                this._account
              )
            )
          )
        )
      )
      .subscribe((order) => this.addOrUpdateOrder(order));
  }

  private initializeMetadataUpdate() {
    this.onUpdateMetadata$
      .pipe(
        auditTime(200),
        filter(() => this.isInit)
      )
      .subscribe(() => this._updateMetadata());
  }

  private initializeHeaderComponent() {
    this.refComponent = this.headerComponentData;
  }

  private updateFastOrder() {
    if (this.showFastOrder) {
      return;
    }
    this._selectedStrategy = this._strategyService.findActiveStrategy();
    this._isSelectedStrategy = this._strategyService.strategyIsEnabled();
  }

  private updateOrderLine(removeDraws: boolean = true) {
    if (!this.tigerChartViewChild || !this.isInit) return;
    this.tigerChartViewChild.clearLines(removeDraws);
    const { orders } = this.stockChartService.processPositionOrders(
      this.configStock,
      this.isDaytradeOrders
    );
    if (orders?.length) {
      orders?.forEach((item: any) => {
        this.addOrUpdateOrder(item);
      });
    }
  }

  onChartReady() {
    const defaultConfigs = DEFAULT_CONFIGS();
    if (
      this.metadataComponent &&
      this.metadataComponent.chart?.width == defaultConfigs.width &&
      this.metadataComponent.chart?.height == defaultConfigs.height
    ) {
      this.updateStockChartConfigs({
        width: this.tigerChartWrapperViewChild.nativeElement.clientWidth,
        height: this.tigerChartWrapperViewChild.nativeElement.clientHeight,
      });
    }
    this._onDataLoad$.subscribe((data) => {
      if (
        this.configStock.cd_segment == '8888' ||
        this.configStock.id_exchange == 7777
      ) {
        this.showBookButton = false;
      } else {
        this.showBookButton = true;
      }
      this.updateConfigStock(this.configStock, false);
      this.tigerChartViewChild.changeStockAndSettings(
        {
          data: this.data,
          settings: this.stockChartConfigs.chart,
          stock: this.configStock,
        },
        data.isChangeInterval
      );
    });
  }

  private updateConfigStock(stock: ISearchStock, update: boolean) {
    if (this.verifyHasStockInfo(stock)) {
      this.getStock(stock, update, false);
      return;
    }
  }

  private getStock(
    stock: ISearchStock,
    update: boolean,
    dispatchHeader: boolean,
    firstResp: boolean = false,
    force: boolean = false
  ) {
    const cdStock = stock?.synonymous_nickname || stock.cd_stock;
    const cdStockGlobal =
      this._globalStock.getGlobalStockSelected()?.synonymous_nickname ||
      this._globalStock.getGlobalStockSelected().cd_stock;
    if (cdStock !== cdStockGlobal || force) {
      this._stockService
        .searchStock(stock.cd_stock, [])
        .subscribe((resp: Array<ISearchStock>) => {
          const respStock = firstResp
            ? resp[0]
            : resp.find(
                (item: ISearchStock) => item.cd_stock === stock.cd_stock
              );
          if (!respStock) {
            this._toastService.showToast(
              'warning',
              'Algumas informações do papel não foram encontradas'
            );
            return;
          }
          if (firstResp) {
            stock = respStock;
          } else {
            stock.cd_segment = respStock.cd_segment;
            stock.standard_lot = respStock.standard_lot;
            stock.ds_asset = respStock.ds_asset;
            stock.tick_size_denominator = respStock.tick_size_denominator;
            stock.stockVariation = respStock.stockVariation;
          }
          this.infosStock(update, dispatchHeader, stock);
        });
    } else {
      this.infosStock(update, dispatchHeader);
    }
  }

  private infosStock(
    update: boolean,
    dispatchHeader: boolean,
    stockSelected?: ISearchStock
  ): void {
    const stock = stockSelected ?? this._globalStock.getGlobalStockSelected();
    this.configStock = deepClone(stock);
    if (dispatchHeader) {
      this.stockChartHeaderViewChild.updateStock(stock);
    }
    update && this._updateStockAfterChange(stock, update);
  }

  onChartUpdate() {
    this._initIndicatorObservable()
      .pipe(tap(this.loadIndicatorHandler), auditTime(250))
      .subscribe(async () => {
        this.getPositionAddCustody(false, false);
        this._setCandleParams();
        this._setQuoteParams();
        this._setVolumeOrBookParams();
        this._subscribeCheetahChannels();
        this._initDraws();
        this.fillPositionChart();
        this.processPositionOrders().then(() => {
          this._getAlerts();
          this.isInit = true;
        });
        this.onRestart = false;
      });
  }

  private _subscribeOrderChannel() {
    this._orderChannel$ = this._ordersService
      .getOrdersStream()
      .pipe(
        takeUntil(this._onDestroy),
        filter((order) =>
          this.filtersService.orderChannelFilter(
            order,
            this.configStock,
            this._account
          )
        )
      )
      .subscribe(this.addOrUpdateOrder);
    this._ordersService.getOrdersCached();
  }

  filterPosition = (key: string) => {
    const chartKey = this.stockChartService.getPositionKey(
      this.configStock,
      this._account
    );
    return this.positions.has(chartKey) && key === chartKey;
  };

  fillPositionChart() {
    this.custodyCurrentSubscription &&
      this.custodyCurrentSubscription.snapshot(this._custodyChannel.itemsArray);
  }
  custodyChannelSubs!: Subscription;
  custodySnap$!: Subscription;

  custodyDataHandler(payload: Map<string, any[]>) {
    const chartKey = this.stockChartService.getPositionKey(
      this.configStock,
      this._account
    );
    const items = payload.get(this._custodyChannel.itemsArray[0]);
    items?.forEach((data) => {
      if (!data || data.isEndOfSnap) return;
      this.positions.set(data.key, data);
      const custody = this.positions.get(chartKey);
      custody && this.addCustody(custody, false, this.showFastOrder);
    });
  }

  private processChartCommands() {
    this._updateChart(false);
    this._listinerUpdateConfigs();
    this.startEventChangeAccountStock();
  }

  private startEventChangeAccountStock(): void {
    this._openModalOnKeyPress();
  }

  toNumber = (value: string) => parseInt(value);

  onScrollToBegin() {
    console.log('--- onScrollToBegin ----');
    if (!this._loadingMoreData$) {
      this._loadMoreData();
    }
  }

  keypressEvent = (event: KeyboardEvent) => {
    const element: any = event.target;
    if (cantProcessKeyboardEvent(element) && !this._modalOpned) {
      return;
    }
    if (this._superSearchService.modalOpened) {
      return;
    }
    if (
      /^[A-Za-z0-9]$/.test(event.key) &&
      !this.stockChartService.intervalModalValue
    ) {
      this._keyPress$.next((this._words += event.key));
    } else {
      this._words = this.stockChartService.intervalModalValue;
    }
  };

  private onUpdateMetadata() {
    if (this.onUpdateMetadata$ && !this.onUpdateMetadata$.closed) {
      this.onUpdateMetadata$.next();
    }
  }

  deleteIndicator(indicator: any) {
    if (this.stockChartConfigs.indicators) {
      this.stockChartConfigs.indicators = {
        value: indicator.type,
        action: 'remove',
        actives: this.stockChartConfigs.indicators!.actives!.filter((i) => {
          const indicatorActive = typeof i === 'string' ? i : i.indicator;
          return (
            indicatorActive !== `${indicator.type}_${indicator.lineNumber}`
          );
        }),
      };
      this._toggleIndicator.removeActiveIndicators(
        this.refComponent,
        indicator.type,
        indicator.lineNumber
      );
      this._toggleIndicator.removeTempConfigIndicator(
        this.refComponent,
        indicator.type,
        indicator.lineNumber
      );
      this.onUpdateMetadata();
    }
  }

  private isNotSameStock = (stock: ISearchStock): boolean => {
    return (
      stock.cd_stock !== this.configStock.cd_stock ||
      (stock.cd_stock === this.configStock.cd_stock &&
        stock.is_synonymous != this.configStock.is_synonymous)
    );
  };

  handlerStockChange = (stock: ISearchStock) => {
    if (this.isNotSameStock(stock)) {
      this.nomeCompanhia = '';
      this.resetUserCustody();
      this.getPositionAddCustody(false, false);
      this.timesTriedStockExpired = 1;
      this.trySynonymousStock = false;
      if (!this.dontUpdateFreeScale) {
        this.setFreeScale({ isFreeScale: false, updateMetadata: true });
      }
      this.cdr.detectChanges();
      if (isTypeStockIndex(stock.type) && this.showFastOrder) {
        this.showFastOrderAux = deepClone(this.showFastOrder);
        this.fastOrder();
      }
      if (this.verifyHasStockInfo(stock)) {
        this.updateConfigStock(stock, true);
        return;
      }
      this._updateStockAfterChange(stock);
      if (this.showFastOrderAux && !isTypeStockIndex(stock.type)) {
        this.showFastOrderAux = false;
        this.fastOrder();
      }
    }
  };

  private _updateStockAfterChange(stock: ISearchStock, force: boolean = false) {
    if (!this.isNotSameStock(stock) && !force) {
      return;
    }
    const position = this.getPosition();
    if (position) {
      this.tigerChartViewChild.deleteLine(position.item!);
    }
    this.configStock = deepClone(stock);
    this.updateStockChartConfigs({ yAxis: { labelPrecision: this.tickSize } });
    const stocksCustomsQtty =
      this._stockPreferencesService.stocksCustomPreferences;
    const stockPreference = stocksCustomsQtty[this.configStock.cd_stock_order];
    let negotiationLot =
      stockPreference &&
      stockPreference.qtty &&
      this._stockPreferencesService.saveQttyStockEnabled
        ? stockPreference.qtty
        : this.settings.negotiationLot || stock.standard_lot;
    if (
      !this._previousStock ||
      this._previousStock.cd_stock === stock.cd_stock
    ) {
      this._previousStock = deepClone(stock);
    } else {
      negotiationLot = stock.standard_lot;
      this._previousStock = deepClone(stock);
    }
    this.changeNegotiationLot(negotiationLot);
    this._updateChart();
    this.isChangeInterval = false;
    this.getPositionAddCustody(false, false);
  }

  get haveStockConfig(): boolean {
    return !isNullOrWhiteSpace(this.configStock.cd_stock);
  }

  needRemoveEvents(stock: ISearchStock) {
    const isSameStock =
      !this._account ||
      getCdStockParam(stock) === getCdStockParam(this.configStock);
    return isSameStock || this.haveStockConfig;
  }

  changeNegotiationLot(value: number) {
    this._negotiationLot = value;
    if (!this.isInit) return;
    if (value != this._negotiationLot) {
      this.updateStockChartConfigs({
        negotiationLot: this._negotiationLot,
      });
      this.onUpdateMetadata();
    }
    this.settings = { ...this.stockChartConfigs.chart };
    this.tigerChartViewChild.forceQtty(value);
  }

  changeInterval(interval: TIGER_INTERVAL_ENUM) {
    const intervalSelected = this._chartBase.intervalDic.get(interval)!;
    if (
      this.isInit &&
      this.intervalChartConfigs &&
      this.intervalChartConfigs.id === intervalSelected.id
    ) {
      return;
    }
    this.updateStockChartConfigs({ series: { interval } });
    this.intervalChartConfigs = intervalSelected;
    this.stockChartService.intervalSelected.next(interval);
    this.onUpdateMetadata();
    this.tigerChartViewChild.updateCustodyCheeps();
    this.timesTriedStockExpired = 1;
    this.trySynonymousStock = false;
    this.isChangeInterval = true;
    this.stockChartService.updateChart(this.refComponent, true);
  }

  changesTypes(type: TIGER_TYPE_CHART_ENUM) {
    if (type === this.stockChartConfigs.chart.series?.type) return;
    this.updateStockChartConfigs({ series: { type } });
    this.onUpdateMetadata();
    this.tigerChartViewChild.settings = this.stockChartConfigs.chart;
  }

  changeAccount(account: IAccountSelect) {
    if (
      !this._account ||
      account.account_number === this._account.account_number
    ) {
      return;
    }
    if (!isNullOrWhiteSpace(this.configStock.cd_stock)) {
      this._account = account;
      this._updateChart();
    }
  }

  toggleIndicator = (
    data: {
      value: TIGER_INDICATORS_ENUM;
      actives: string[];
      action: 'add' | 'remove';
      lineNumber: number;
      config?: any;
    },
    updateChart: boolean = false
  ) => {
    if (!this.data) return;
    if (INDICATORS.findIndex((indicator) => indicator.id == data.value) == -1)
      return;

    const ind: BaseIndicator | undefined =
      this._toggleIndicator.processIndicator(
        data.value,
        this.data,
        this.tickSize,
        this.refComponent,
        data.lineNumber,
        data.config
      );

    if (ind) {
      this._indicators.push(ind);
      this._toggleIndicator.setActiveIndicators(
        ind,
        this.refComponent,
        data.config
      );
      if (updateChart) {
        const actives = this._toggleIndicator.getActiveIndicators(
          this.refComponent
        );
        ind.isNewOnChart = true;
        this.stockChartConfigs.indicators = {
          value: data.value,
          actives: actives,
          action: 'add',
        };
        this.addIndicator = [ind];
        this.cdr.detectChanges();
        this.onUpdateMetadata();
      }
    }
  };

  async lineMoved(
    item: {
      id: string;
      value: number;
      data: IOrders | IPosition;
      idTradeSystem?: number;
    },
    alertCharts: boolean = true,
    alertExisting?: any,
    updateByAlertColumn: boolean = false
  ) {
    if (item.id.includes(this._newLineId)) {
      const direction =
        parseFloat(item.value.toFixed(2)) >= this.lastVlClose ? '>=' : '<=';
      let newAlert = alertExisting;
      if (alertCharts) {
        this._alertService
          .updateAlert(
            item.idTradeSystem!!,
            parseFloat(item.value.toFixed(2)),
            direction,
            this.configStock.tick_size_denominator,
            this.configStock.cd_stock_order
          )
          .subscribe((alert: any) => {
            newAlert = alert;
            this.tigerChartViewChild.updateLineAlertData(
              item.id,
              newAlert.result,
              updateByAlertColumn ? true : !alertCharts
            );
            this.stockChartService.alertCharts$.next({
              ref: this.refComponent,
              type: 'UPDATE',
              alertAnother: false,
              itemUpdate: item,
              newAlert: newAlert,
            });
            this._alertService.deleteAlertInOrderHistory(item.idTradeSystem!!);
            this._alertService.addNewAlertInOrderHistory(alert.result);
          });
      } else {
        this.tigerChartViewChild.updateLineAlertData(
          item.id,
          newAlert.result,
          !alertCharts
        );
      }
      return;
    }
    if ('id_order' in item.data) {
      let order = deepClone(item.data);
      if (item.value < 0) {
        this._toastService.showToast(
          'warning',
          'O novo preço para a ordem não pode ser negativo.'
        );
        return this.addOrUpdateOrder(order);
      }
      this.originAnalysisOrderService.setOriginOrder(
        ORDER_PARAM_HELPER.STOCK_CHART_DRAG_AND_DROP
      );
      let isOrderStartStop: boolean = false;
      if (isStartStop(order.cd_order_type)) {
        const temp = +order.price_stop - +item.value;
        order.price = +order.price - temp;

        if (this.startStopTriggerValidation(item.value, order)) {
          this.addOrUpdateOrder(order);
          return;
        }
        isOrderStartStop = true;
      }

      order = this.stockChartService.processDoubleStartStopUpdate(order);
      order.qtty = +order.qtty;
      this.stockChartService
        .updateOrder(order, item.value, isOrderStartStop)
        .pipe(
          catchError((err) => {
            //ToDo restaurar posicao no grafico.
            this.addOrUpdateOrder(order);
            return err;
          })
        )
        .subscribe();
    }
  }

  private startStopTriggerValidation(stopPx: any, order: IOrders): boolean {
    const price = order.price === order.price_stop ? stopPx : order.price;

    const message = startStopValidation(
      order.side,
      this.lastVlClose,
      price,
      stopPx
    );
    message && this._toastService.showToast('warning', message);
    return message ? true : false;
  }

  lineDeleted(
    item: {
      id: string;
      value: number;
      data: IOrders | IPosition[] | IPosition;
      idTradeSystem?: number;
      boxIdAlert?: string;
      type: TIGER_LINE_TYPE;
      cdStock?: string;
    },
    alertCharts: boolean = true
  ) {
    const isPreviousClose = item.type === TIGER_LINE_TYPE.PREVIOUS_CLOSE;
    if (isPreviousClose) {
      this.deletePreviousClose();
      return;
    }
    if (item.boxIdAlert && item.boxIdAlert.includes(this._newLineId)) {
      const box = this.tigerChartViewChild.lineAlertData[item.boxIdAlert];
      this.removeLine = box ? box.firstBoxId : item.boxIdAlert;
      this.cdr.detectChanges();
      if (alertCharts) {
        this._alertService
          .excludeAlert(
            item.idTradeSystem!!,
            item.cdStock ?? (this.configStock as ISearchStock).cd_stock_order,
            item.value,
            (this.configStock as ISearchStock).tick_size_denominator
          )
          .subscribe();
        this.stockChartService.alertCharts$.next({
          ref: this.refComponent,
          type: 'DELETE',
          alertAnother: false,
          itemDelete: item,
        });
        this._alertService.deleteAlertInOrderHistory(item.idTradeSystem!!);
      }
      return;
    }
    if ('id_order' in item.data) {
      this.ordersService.cancelOrders([item.data]);
    }
    if (item.data instanceof Array && 'id_custodian' in item.data[0]) {
      item.data.forEach((value) => {
        this.stockChartService
          .newOrder({
            stock: this.configStock as ISearchStock,
            quant: parseInt(value.qtty_final.toString()),
            account: this._account!,
            isFastOrder:
              this.showFastOrder && this.showFastOrderDaytradeStrategy,
          })
          .subscribe();
      });
    }

    if ('id_custodian' in item.data) {
      const qtd_final = parseInt(item.data.qtty_final.toString());
      this.stockChartService.resetPositions({
        stock: this.configStock as ISearchStock,
        quant: qtd_final < 0 ? Math.abs(qtd_final) : qtd_final,
        ord_side:
          qtd_final < 0 ? TYPE_ORDE_SIDE_ENUM.BUY : TYPE_ORDE_SIDE_ENUM.SELL,
        account: this._account!,
        ord_type: TYPE_ORDE_ENUM.RESET_POSITION,
        isFastOrder: this.showFastOrder && this.showFastOrderDaytradeStrategy,
      });
    }
  }

  dissMissHandler = (res: {
    save: boolean;
    tabName: string;
    configs: Partial<ICandleConfigs>;
    closed: boolean;
    defaultConfiguration: GlobalChartConfiguration;
  }) => {
    if (res.closed) {
      const updated: any = {};
      Object.keys(res.defaultConfiguration).forEach((key) => {
        updated[key] = (res.defaultConfiguration as any)[key];
      });
      this._stockChartModalService.updateGlobalChartConfigs(updated);
      setTimeout(() => {
        Object.keys(TYPE_CHART_UPDATE).forEach((key) => {
          this.updateChartView({
            typeUpdate: (TYPE_CHART_UPDATE as any)[key],
            config: updated,
          });
        });
      }, 1);
      return;
    }
    if (res.save) {
      this.stockChartConfigs.chart = res.configs;
      this.onUpdateMetadata();
    } else {
      if (res.tabName === 'estilo') {
        const defaultConfigSeries =
          DEFAULT_CONFIGS().series[this.stockChartConfigs.chart.series!.type!]!;
        const defaultConfigCloseLine = DEFAULT_CONFIGS().closeLine;
        this.updateStockChartConfigs({
          closeLine: defaultConfigCloseLine,
          series: {
            [this.stockChartConfigs.chart.series!.type!]: defaultConfigSeries,
          },
        });
      }
    }
  };

  private openStockTrade(value: ICandleContextMenu<{ pointValueY: number }>) {
    this._workspaceConfigs.openStockTrade =
      this.stockChartHelper.getStockTradeOptions(
        this.configStock,
        value,
        this._negotiationLot
      );
  }

  private openMoreOptionsModal() {
    const ref = this._modal.open(StockChartModalMoreOptionsComponent, {
      keyboard: true,
      backdrop: true,
      data: {
        config: this.stockChartConfigs,
        ref: this.refComponent,
        isSecondModal: false,
      },
    });
    ref.afterDismissed.subscribe(this.dissMissHandler);
  }

  handleRightClick(
    value: ICandleContextMenu<{ pointValueY: number }> | undefined
  ) {
    if (value === undefined) return;

    if (value.showModal) {
      const el = document.getElementById(this.component.id);
      if (el)
        el.dispatchEvent(
          new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: false,
          })
        );
      return;
    }
    if (!value.itemMenu) return;
    const funcs: any = {
      [CONTEXT_MENU_ITEM_ENUM.TYPE_ORDER]: () => this.openStockTrade(value),
      [CONTEXT_MENU_ITEM_ENUM.FAST_TYPE_ORDER]: () => {
        this.originAnalysisOrderService.setOriginOrder(
          value.typeOrder == CONTEXT_MENU_ORDER_TYPE.BUY
            ? ORDER_PARAM_HELPER.RIGHT_CLICK_BUY_LIMITED
            : ORDER_PARAM_HELPER.RIGHT_CLICK_SELL_LIMITED
        );
        this.sendOrder(value as IStockChartSendOrderOptions);
      },
      [CONTEXT_MENU_ITEM_ENUM.TYPE_STOP_ORDER]: () => {
        this.originAnalysisOrderService.setOriginOrder(
          value.typeOrder == CONTEXT_MENU_ORDER_TYPE.BUY
            ? ORDER_PARAM_HELPER.RIGHT_CLICK_BUY_STOP
            : ORDER_PARAM_HELPER.RIGHT_CLICK_SELL_STOP
        );
        this.sendOrder({
          ...(value as IStockChartSendOrderOptions),
          ord_type: TYPE_ORDE_ENUM.START_STOP,
        });
      },
      [CONTEXT_MENU_ITEM_ENUM.MORE_OPTIONS]: () => this.openMoreOptionsModal(),
      [CONTEXT_MENU_ITEM_ENUM.CREATE_ALERT]: () =>
        this.createAlertLineOnCandle(value),
    };
    const func = funcs[value.itemMenu];
    if (func) {
      func();
    }
  }

  sendOrder = (value: IStockChartSendOrderOptions) => {
    const orderOptions: ITigerChartOrder = {
      stock: this.configStock,
      account: this._account,
      quant: this._negotiationLot,
      price:
        value.ord_type == TYPE_ORDE_ENUM.MARKET
          ? this.data?.vl_close[this.data?.vl_close.length - 1]
          : value.data.pointValueY,
      lastVlClose: this.lastVlClose,
      isFastOrder: this.showFastOrder && this.showFastOrderDaytradeStrategy,
      componentId: this.component.id,
      chartTradingOffset: this.chartTradingOffset,
      minPriceIncrement: this.minPriceIncrement,
      refComponent: this.refComponent,
      service: this.stockChartService,
    };
    this._tigerChartOrdersService.sendOrder(value, orderOptions).subscribe({
      error: () => {
        this.updateCustodyCheeps(
          value.ord_type!!,
          value.data.gainOrderPrice,
          value.data.lossOrderPrice
        );
        const loadingKey =
          value.typeOrder == CONTEXT_MENU_ORDER_TYPE.BUY
            ? 'MARKET_BUY_BUTTON'
            : 'MARKET_SELL_BUTTON';
        this.stockChartService.loadingButton$.next({
          type: loadingKey,
          bool: false,
        });
      },
      next: (data: any) => {
        if (data && data.doubleStartValidation) {
          this._toastService.showToast('warning', data.message);
          this.tigerChartViewChild.updateCustodyCheeps(true);
          return;
        }
        const loadingKey =
          value.typeOrder == CONTEXT_MENU_ORDER_TYPE.BUY
            ? 'MARKET_BUY_BUTTON'
            : 'MARKET_SELL_BUTTON';
        this.stockChartService.loadingButton$.next({
          type: loadingKey,
          bool: false,
        });
      },
    });
  };

  loadMoreDataLastDate = 0;
  private _loadMoreData() {
    this._loadingMoreData$ = true;
    if (this.loadMoreDataLastDate === this.data?.id_point[0] || this.endChart) {
      return;
    }
    this.loadMoreDataLastDate = this.data!!.id_point[0];
    this.getDataByStock(this.data?.id_point[0]).subscribe((res) => {
      this.datesToEvents.push(this.loadMoreDataLastDate);
      this.tigerChartViewChild.prependToData(res);
      this.endDataChart(res!.id_point);
      if (this.showEvents) {
        this.updateAllEvents(this.data!.id_point[0]!);
      }
      this._loadingMoreData$ = false;
    });
  }

  private updateCustodyCheeps(
    ordType: TYPE_ORDE_ENUM,
    gainOrderPrice?: number,
    lossOrderPrice?: number
  ) {
    if (
      (ordType === TYPE_ORDE_ENUM.START_STOP ||
        ordType === TYPE_ORDE_ENUM.DOUBLE_START_STOP) &&
      (gainOrderPrice || lossOrderPrice)
    ) {
      this.tigerChartViewChild.updateCustodyCheeps(true);
    }
  }

  private _openModalOnKeyPress() {
    this._keyPress$
      .pipe(takeUntil(this._onDestroy), debounceTime(200))
      .subscribe((res) => {
        this._openModalInterval(res);
      });
  }
  private _openModalInterval(word: string) {
    if (!this._modalOpned) {
      this._modalOpned = true;
      const refInterval = this._modal.open(StockChartModalIntervalComponent, {
        data: { interval: this._chartBase.intervalDic, ref: this.refComponent },
        keyboard: true,
        backdrop: true,
        appendTo: document.getElementById(this.component.id) as HTMLElement,
      });
      refInterval.afterDismissed
        .pipe(tap(() => (this._modalOpned = false)))
        .subscribe((value: any) => {
          if (typeof value != 'object') {
            this.changeInterval(value);
          }
          this.stockChartService.updateIntervalModalValue(undefined);
          const doc = document.getElementById(this.component.id);
          if (doc) {
            setTimeout(() => {
              doc.tabIndex = 1;
              doc.focus();
            }, 100);
          }
          this._words = '';
        });
    }
    this.stockChartService.eventKey$.next({ ref: this.refComponent, word });
  }

  loadIndicatorHandler = () => {
    let indicator: any = '';
    this._indicators = [];
    return new Promise((resolve) => {
      this.stockChartConfigs.indicators!.actives?.forEach((i) => {
        let splited = [],
          config = undefined;
        if (typeof i === 'string') {
          splited = i.split('_');
        } else {
          splited = i.indicator.split('_');
          config = i.config;
        }
        indicator = splited[0] as TIGER_INDICATORS_ENUM;
        this.toggleIndicator({
          value: indicator,
          actives: this.stockChartConfigs.indicators!.actives!,
          action: 'add',
          lineNumber: parseInt(splited[1]),
          config,
        });
      });
      return resolve(true);
    }).finally(() => {
      const actives =
        this._toggleIndicator.getActiveIndicators(this.refComponent) || [];
      if (
        !this.stockChartConfigs.indicators ||
        !this.stockChartConfigs.indicators.actives ||
        this.stockChartConfigs.indicators.actives.toString() !=
          actives.toString()
      ) {
        this.stockChartConfigs.indicators = {
          value: indicator,
          actives,
          action: 'add',
        };
        this.onUpdateMetadata();
      }
      this.addIndicator = this._indicators;
      this.cdr.detectChanges();
    });
  };

  protected _initIndicatorObservable() {
    return this._talibService.onRead;
  }

  protected _initDraws() {
    const cdStock = getCdStockParam(this.configStock);
    const key = `${cdStock}:${this.configStock.id_exchange}`;
    const stringDraw = this._customPreferencesService.userDrawStock;
    let stocksDraw: any = {};
    if (!isNullOrUndefined(stringDraw) && stringDraw != '') {
      stocksDraw = JSON.parse(stringDraw);
    }
    this.addDraws =
      key in stocksDraw ? (stocksDraw[key] as saveConfiguration[]) : [];
  }

  private getDataByStock(step: number = 0) {
    const { stock } = this.stockChartConfigs;
    const dsAsset = stock.is_synonymous ? stock.ds_asset : undefined;
    return this.stockChartService.getAll(
      this.stockSelected,
      this.intervalSelected,
      step,
      this.idExchangeSelected,
      this.intervalChartConfigs.limit,
      dsAsset
    );
  }

  private isStockExpired() {
    const dtMaturity = this.stockChartConfigs.stock.dt_maturity;
    const today = new Date().getTime();
    this.timesTriedStockExpired++;
    if (dtMaturity) {
      const standardizeDtMaturity = standardizeDate(dtMaturity.toString());
      const dtMaturityTime = stringToDate(standardizeDtMaturity)!!.getTime();
      if (dtMaturityTime < today) {
        return true;
      }
    }
    return false;
  }

  private verifyIsLoadingData() {
    if (this.getDataByStock$) {
      if (this.tigerChartViewChild) {
        this.tigerChartViewChild.removeLoader();
        this.blockChartButtons(false);
      }
      this.getDataByStock$.unsubscribe();
    }
  }

  private updateChartData(isChangeInterval: boolean = false) {
    this.verifyIsLoadingData();
    this.resetDataState();
    if (this.shouldTrySynonymousStock()) {
      this.trySynonymousStockUpdate();
      return;
    }
    this.stockChartChannelService.unsubscribeChannels({
      book: this.bookParams,
      volume: this.volumeParams,
      quote: this.quoteParams,
      candle: this.candleParams,
    });
    this.clearCheetahParams();
    if (!this.onRestart) {
      this.tigerChartViewChild.unsubscriberCheetah(isChangeInterval);
    }
    this.getDataByStock$ = this.getDataByStock(0).subscribe(
      (res: TData | null) => this.handleDataLoadSuccess(res, isChangeInterval),
      () => this.handleDataLoadError(isChangeInterval)
    );
  }

  private resetDataState() {
    this.datesToEvents = [];
    this._loadingMoreData$ = false;
    this.data = null;
    this.loadMoreDataLastDate = 0;
    this.events = undefined;
    this.globalItem = `${this.stockSelected}.${
      TIGER_INTERVAL_ENUM[this.intervalSelected]
    }:${this.stockChartConfigs.stock.id_exchange}`;
  }

  private shouldTrySynonymousStock(): boolean {
    return (
      this.timesTriedStockExpired <= 10 &&
      this.stockChartConfigs.stock.is_synonymous! &&
      (this.isStockExpired() || !this.trySynonymousStock)
    );
  }

  private trySynonymousStockUpdate() {
    this.trySynonymousStock = true;
    this.getStock(
      {
        ...this.stockChartConfigs.stock,
        cd_stock: this.stockChartConfigs.stock.synonymous_nickname!!,
      } as any,
      true,
      false,
      true
    );
  }

  private handleDataLoadSuccess(res: TData | null, isChangeInterval: boolean) {
    this.data = res;
    this.datesToEvents.push(0);
    const previousGlobal = deepClone(this.globalItem);
    if (this.data) {
      this.data.qtty_shares_traded = [];
    }
    if (this.data?.id_point) {
      this.lastDate = this.data.id_point[this.data.id_point.length - 1];
      this.lastVlClose = this.data.vl_close[this.data.vl_close.length - 1];
      this.endDataChart(res!.id_point);
      if (this.showEvents && previousGlobal == this.globalItem) {
        this.getAllEvents();
      }
    }
    this._toggleIndicator.resetIndicators();
    this._onDataLoad$.next({ isChangeInterval });
    this.blockChartButtons(false);
    this.cdr.detectChanges();
  }

  private handleDataLoadError(isChangeInterval: boolean) {
    this.data = null;
    this._onDataLoad$.next({ isChangeInterval });
  }

  private endDataChart(data: number[]) {
    this.endChart = data.length < this.intervalChartConfigs.limit;
  }

  private blockChartButtons(isBlock: boolean) {
    this.stockChartService.blockChartButtons$.next({
      isBlock,
      ref: this.component.id,
    });
  }

  private getCorporateEvents(idPoint: number = 0) {
    const { stock } = this.stockChartConfigs;
    const dsAsset =
      stock.cd_security_type == 'FUT' ? stock.ds_asset : undefined;
    return this.stockChartService.getCorporateEvents(
      this.stockSelected,
      this.intervalSelected,
      idPoint,
      this.idExchangeSelected,
      this.intervalChartConfigs.limit,
      dsAsset
    );
  }

  private getTradeIdeaEvents() {
    const { stock } = this.stockChartConfigs;
    const dsAsset =
      stock.cd_security_type == 'FUT' ? stock.ds_asset : undefined;
    return this.stockChartService.getTradeIdeaEvents(
      this.configStock.cd_stock,
      this.intervalSelected,
      this.idExchangeSelected,
      this.intervalChartConfigs.limit,
      dsAsset
    );
  }

  private getNewsChart(idPoint: number = 0) {
    const { stock } = this.stockChartConfigs;
    const dsAsset =
      stock.cd_security_type == 'FUT' ? stock.ds_asset : undefined;
    return this.stockChartService.getNewsChart(
      this.configStock.cd_stock_order,
      this.intervalSelected,
      idPoint,
      this.idExchangeSelected,
      this.intervalChartConfigs.limit,
      dsAsset
    );
  }

  private getRelevantEvents(idPoint: number = 0) {
    const { stock } = this.stockChartConfigs;
    const dsAsset =
      stock.cd_security_type == 'FUT' ? stock.ds_asset : undefined;
    return this.stockChartService.getRelevantEvents(
      this.stockSelected,
      this.intervalSelected,
      idPoint,
      this.idExchangeSelected,
      this.intervalChartConfigs.limit,
      dsAsset
    );
  }

  private _updateMetadata() {
    if (!this.component) return;
    this.component.metadata.component = this.stockChartConfigs;
    this._homeService.updateMeta<IStockChartConfig>(this.component);
  }

  channelHandler = (payload: any) => {
    const res = payload.get(this._legSubscribed);
    if (
      res &&
      res.id_point != 0 &&
      !isNaN(res.id_point) &&
      !isNaN(this.lastDate) &&
      !!this.tigerChartViewChild
    ) {
      const data = this.stockChartService.mapCandleCheetah(res, this.lastDate);
      if (data.id_point < this.lastDate) return;
      this.lastDate = data.id_point;
      this.lastVlClose = data.vl_close;
      this.tigerChartViewChild.updateData(data);
    }
  };

  quoteHandler = (payload: any) => {
    const res = payload.get(this.itemsArraySelected);
    if (!res || res.isEndOfSnap) return;

    res.min_price_increment &&
      (this.minPriceIncrement = +res.min_price_increment);
    const quoteData: QuoteData = this.stockChartService.parseQuoteChannel(res);
    this.stockChartService.updateQuoteData$.next({ quoteData });
    if (
      quoteData.nome_companhia != quoteData.cd_stock &&
      this.tigerChartViewChild &&
      !this.nomeCompanhia
    ) {
      this.nomeCompanhia = quoteData.nome_companhia;
      this.tigerChartViewChild.setWatermark(null, quoteData.nome_companhia);
    }
  };

  private _subscribeCheetahChannels() {
    this.stockChartChannelService.subscribe({
      candle: this.candleParams,
      quote: this.quoteParams,
      volume: this.volumeParams,
      book: this.bookParams,
    });
  }

  private _subscribeVolumeOrBook() {
    this.stockChartChannelService.subscribe({
      volume: this.volumeParams,
      book: this.bookParams,
    });
  }

  private _unsubscribeVolumeOrBook() {
    this.stockChartChannelService.unsubscribeChannels({
      volume:
        this.volumeParams && !this.showChartVolumeAtPrice
          ? this.volumeParams
          : undefined,
      book:
        this.bookParams && !this.showChartBook && !this.showBidAsk
          ? this.bookParams
          : undefined,
    });
  }

  private async _setCandleParams() {
    let interval =
      this.intervalSelected != TIGER_INTERVAL_ENUM.ONE_HOUR
        ? TIGER_INTERVAL_ENUM[this.intervalSelected!]
        : 'SIXTY_MINUTE';
    interval =
      this.intervalSelected === TIGER_INTERVAL_ENUM.ONE_MONTH
        ? 'THIS_MONTH'
        : interval;
    const cdStock = this.configStock.is_synonymous
      ? this.configStock.synonymous_nickname
      : this.configStock.cd_stock;
    this._legSubscribed = `${cdStock}.${interval}:${this.configStock.id_exchange}`;
    this.candleParams = {
      itemsArray: [this._legSubscribed],
    };
  }

  get itemsArraySelected() {
    return `${this.configStock?.cd_stock}:${this.configStock?.id_exchange}`;
  }

  private _setQuoteParams() {
    this.quoteParams = {
      itemsArray: [this.itemsArraySelected],
    };
  }

  private _setVolumeOrBookParams() {
    this.volumeParams = undefined;
    this.bookParams = undefined;
    const key = `${this.configStock.cd_stock}:${this.configStock.id_exchange}`;
    if (this.showChartBook || this.showBidAsk) {
      this.bookParams = { itemsArray: [key] };
    }
    if (this.showChartVolumeAtPrice) {
      this.volumeParams = { itemsArray: [`ANALYSIS=${key}`] };
    }
  }

  // TODO: Deve receber a linha direto e nao fazer essa logica dentro dessa função, a posição ja deve chegar aqui pronta para criar a linha
  private addCustody = (
    custody: IPosition,
    updateMetadata: boolean,
    isFromFastOrder: boolean
  ): void => {
    if (this.shouldAbortAddCustody(isFromFastOrder)) {
      return;
    }
    this.resetUserCustody();
    if (updateMetadata) {
      this.updateMetadata();
    }
    custody = this.updateCustodyQuantity(custody);
    const custodyInfos = this.getCustodyInfos(custody);
    custody = { ...custody, ...custodyInfos };
    this.updateUserCustody(custody);
    this.showBreakEven();
    if (this.shouldDeleteLine(custody)) {
      this.deleteChartLine(custody.item);
    } else if (this.shouldCreateLine(custody)) {
      this.createChartLine(custody);
    }
    this.cdr.detectChanges();
  };

  private shouldAbortAddCustody(isFromFastOrder: boolean): boolean {
    return (
      !isFromFastOrder &&
      this.showFastOrder &&
      this.showFastOrderDaytradeStrategy
    );
  }

  private resetUserCustody(): void {
    this.userCustody = undefined;
  }

  private updateMetadata(): void {
    this.updateFastOrderInfo(this._isSelectedStrategy, this._selectedStrategy);
  }

  private updateCustodyQuantity(custody: IPosition): IPosition {
    if (custody.qtty_final !== undefined) {
      this.custody_qty = custody.qtty_final;
    } else {
      custody.qtty_final = this.custody_qty;
    }
    return custody;
  }

  private getCustodyInfos(custody: IPosition): CustodyInfos {
    return this._custodyStopOrdersService.getCustodyInfos(custody);
  }

  private updateUserCustody(custody: IPosition): void {
    this.userCustody = custody;
  }

  private shouldDeleteLine(custody: IPosition): boolean {
    return custody.custodyQtty === 0 && !!custody.item;
  }

  private shouldCreateLine(custody: IPosition): boolean {
    return custody.vlPriceAvg > 0;
  }

  private deleteChartLine(item: any): void {
    this.tigerChartViewChild.deleteLine(item);
  }

  private createChartLine(custody: IPosition): void {
    this.tigerChartViewChild.createLinePosition(
      custody,
      this.isBreakEvenClicked,
      this.configStock
    );
  }

  private addOrUpdateOrder = (order: any) => {
    if (!order) return;
    this._addOrUpdateOrder$.next({ order });
    if (order.tp_status === TPOrderStatus.FECHADA) {
      this.handleClosedOrder(order);
    } else {
      this.handleOpenOrder(order);
    }
  };

  private handleClosedOrder(order: any) {
    this.tigerChartViewChild.deleteLine(order.id_order.toString());
    if (
      isDoubleStartStop(order.cd_order_type) ||
      (order.personalized_gain_price && order.personalized_loss_price)
    ) {
      this.tigerChartViewChild.deleteLine(`${order.id_order.toString()}_gain`);
      this.tigerChartViewChild.deleteLine(`${order.id_order.toString()}_loss`);
      this.tigerChartViewChild.deleteLine(
        `${TIGER_DOUBLE_STOP_ORDER_IDS.GAIN}_line`
      );
      this.tigerChartViewChild.deleteLine(
        `${TIGER_DOUBLE_STOP_ORDER_IDS.LOSS}_line`
      );
      this.tigerChartViewChild.updateCustodyCheeps();
    }
  }

  private handleOpenOrder(order: any) {
    const id = `${order.side}${order.cd_order_type === '4' ? '4' : ''}`;
    const orderType = ORDER_TYPE_DIC.get(id);
    if (!orderType) {
      console.error(
        `não foi possivel encontrar o tipo da ordem: ${order.id_order} - ${id}`
      );
      return;
    }
    const quantity = this.formatNumber(order.qtty_left, '1.0-0');
    if (isStartStop(order.cd_order_type)) {
      this.handleStartStopOrder(order, orderType, quantity);
    } else if (isDoubleStartStop(order.cd_order_type)) {
      this.tigerChartViewChild?.createDoubleStopLine(
        this.userCustody,
        order,
        orderType,
        quantity
      );
    } else {
      this.handleRegularOrder(order, orderType, quantity);
    }
  }

  private handleStartStopOrder(
    order: any,
    orderType: IOrderType,
    quantity: string
  ) {
    const price = this.formatNumber(
      order.price,
      `1.${this.tickSize}-${this.tickSize}`
    );
    const stopPrice = this.formatNumber(
      order.price_stop,
      `1.${this.tickSize}-${this.tickSize}`
    );
    const value =
      order.price_stop > 0 ? parseFloat(order.price_stop.toString()) : 0;
    const line = this.createLine(
      order,
      orderType,
      quantity,
      value,
      { stopPrice, price },
      true
    );
    this.emitAddLine(line);
    this.cdr.detectChanges();
  }

  private handleRegularOrder(
    order: any,
    orderType: IOrderType,
    quantity: string
  ) {
    const value = order.price > 0 ? parseFloat(order.price.toString()) : 0;
    const line = this.createLine(
      order,
      orderType,
      quantity,
      value,
      null,
      false
    );
    this.emitAddLine(line);
    this.cdr.detectChanges();
  }

  private createLine(
    order: any,
    orderType: IOrderType,
    quantity: string,
    value: number,
    stop: { stopPrice: string; price: string } | null,
    isStartStop: boolean
  ): TCandleLineOptions {
    return deepClone({
      value: stop ? parseFloat(order.price_stop.toString()) : value,
      color: orderType.color,
      boxLabel: `${orderType.name[0]} | ${quantity}`,
      boxId: order.id_order.toString(),
      data: order,
      boxTextColor: orderType.boxTextColor,
      tooltipLabel: this.stockChartHelper.getTooltipForType(
        TIGER_LINE_TYPE.ORDER,
        {
          isStartStop,
          description: orderType.description,
          quantity,
          price: stop?.price ?? undefined,
          stopPrice: stop?.stopPrice ?? undefined,
          indicators: [],
          value,
        }
      ),
      type: TIGER_LINE_TYPE.ORDER,
    });
  }

  private formatNumber(value: number, format: string): string {
    return formatNumber(value, this.locale, format);
  }

  private _updateChart(updateMetadata: boolean = true) {
    updateMetadata && this.onUpdateMetadata();
    this.stockChartService.updateChart(
      this.refComponent,
      this.isChangeInterval
    );
  }

  private async _getAlerts() {
    this._alertService.getAllAlerts().subscribe((data) => {
      this.alerts = this.filterAlerts(data.alerts);
      this.cdr.detectChanges();
    });
  }

  private filterAlerts = (alerts: IAlert[]) => {
    return alerts.filter(
      (alert: any) =>
        alert.cd_stock === this.configStock.cd_stock_order &&
        alert.cd_status === ALERT_STATUS_ENUM.ACTIVE
    );
  };

  async processPositionOrders(clearLines: boolean = true) {
    if (!this.configStock) return;
    if (clearLines && this.isInit) {
      this.tigerChartViewChild?.clearLines();
    }
    const { orders } = await this.stockChartService.processPositionOrders(
      this.configStock,
      this.isDaytradeOrders
    );
    this.tigerChartViewChild &&
      this.tigerChartViewChild.baseChart?.sciChartSurface.rendered.unsubscribeAll();
    if (orders?.length) {
      orders?.forEach((item: any) => {
        this.addOrUpdateOrder(item);
      });
    }
    const handler = () => {
      this._started$?.unsubscribe();
      this.cdr.detectChanges();
    };
    this._started$ = this.tigerChartViewChild?.started$.subscribe(handler);
    return true;
  }

  private _listinerUpdateConfigs() {
    this.stockChartService.eventConfigs$
      .pipe(takeUntil(this._onDestroy))
      .subscribe((value) => {
        if (value.ref !== this.refComponent) return;
        this.updateStockChartConfigs(value.config);
        if (value.global) {
          this.updateChartView(value);
        }
      });
  }

  private updateChartView(value: {
    config: Partial<ICandleConfigs>;
    typeUpdate: TYPE_CHART_UPDATE;
  }) {
    const updateFunctions: any = {
      [TYPE_CHART_UPDATE.WATER_MARK]: () =>
        this.stockChartService.updateWaterMark$.next({
          ref: this.component.id,
          showWatermark: value.config.showWatermark,
          watermarkOpacity: value.config.watermarkOpacity,
        }),
      [TYPE_CHART_UPDATE.WATER_MARK_OPACITY]: () =>
        this.stockChartService.updateWaterMark$.next({
          ref: this.component.id,
          showWatermark: value.config.showWatermark,
          watermarkOpacity: value.config.watermarkOpacity,
        }),
      [TYPE_CHART_UPDATE.GRID_LINES]: () =>
        this.tigerChartViewChild.updateAxisGridLinesVisibility(value.config),

      [TYPE_CHART_UPDATE.PRICE_CLOSE_TIMER]: () =>
        this.tigerChartViewChild.updatePriceCloseTimer(value.config),

      [TYPE_CHART_UPDATE.CANDLE_COLOR]: () =>
        this.tigerChartViewChild.updateCandleColors({
          colorsBorderUp: value.config.colorsBorderUp ?? '',
          colorsBorderDown: value.config.colorsBorderDown ?? '',
          colorsVelaUp: value.config.colorsVelaUp ?? '',
          colorsVelaDown: value.config.colorsVelaDown ?? '',
        }),

      [TYPE_CHART_UPDATE.VOLUME_COLOR]: () =>
        this.tigerChartViewChild.updateVolumeColors(value.config),

      [TYPE_CHART_UPDATE.SHOW_NAVIGATION_CONTROLS]: () =>
        this.tigerChartViewChild.showNavigationControls(value.config),

      [TYPE_CHART_UPDATE.SHOW_EXEC_ORDER]: () => {
        this.showExecUpdate(value.config);
      },
      [TYPE_CHART_UPDATE.SHOW_FLAG_ORDERS]: () => {
        this.showFlagExecUpdate(value.config);
      },
      [TYPE_CHART_UPDATE.SHOW_ORDER_MARKER]: () => {
        this.showOrderMarkerUpdate(value.config);
      },
      [TYPE_CHART_UPDATE.SHOW_OPEN_FLAG]: () => {
        this.showOpenFlagUpdate(value.config);
      },
      [TYPE_CHART_UPDATE.SHOW_MIN_MAX_VISIBLE]: () =>
        this.tigerChartViewChild.minMaxVisible(value.config),

      [TYPE_CHART_UPDATE.SHOW_TRADING_CHANGE]: () =>
        this.tigerChartViewChild.tradingChange(value.config),

      [TYPE_CHART_UPDATE.SHOW_PREVIOUS_CLOSE]: () => {
        this.previousClose(value.config);
      },

      [TYPE_CHART_UPDATE.SHOW_BID_ASK]: () => {
        this.bidAsk(value.config);
      },

      [TYPE_CHART_UPDATE.BOOK_VOLUME_AT_PRICE]: () => {
        this.bookVolumeAtPrice(value.config);
      },

      [TYPE_CHART_UPDATE.SHOW_FAST_ORDER]: () => {
        this.fastOrder(value.config);
      },

      [TYPE_CHART_UPDATE.SHOW_EVENTS]: () => {
        this.updateShowEvents(value.config);
      },

      [TYPE_CHART_UPDATE.FAST_ORDER_DAYTRADE_STRATEGY]: () =>
        this.hideFastOrderDaytradeStrategy(value.config),

      [TYPE_CHART_UPDATE.TRADING_OFFSET]: () =>
        this.tradingOffset(value.config),

      [TYPE_CHART_UPDATE.SHOW_VOLUME]: () =>
        this.updateShowVolume(value.config),

      [TYPE_CHART_UPDATE.BREAK_EVEN]: () => this.breakEven(value.config),
    };
    const updateFunction = updateFunctions[value.typeUpdate];
    if (updateFunction) {
      updateFunction();
    }
  }

  private breakEven(config: any) {
    this.hasBreakEven = config.showBreakEven;
    this.updateStockChartConfigs({
      showBreakEven: this.hasBreakEven,
    });
    this.onUpdateMetadata$.next();
    this.showBreakEven();
  }

  private showBreakEven() {
    if (!this.userCustody) return;
    if (this.userCustody.coveragePrice === 0 || !this.hasBreakEven) {
      this.tigerChartViewChild.deleteLine(COVERAGE_PRICE_ID);
    } else if (this.userCustody.coveragePrice > 0) {
      this.tigerChartViewChild.createLineBreakEven(
        this.configStock,
        this.userCustody,
        this.isBreakEvenClicked
      );
    }
  }

  private updateStockChartConfigs(value: Partial<ICandleConfigs>) {
    if (!this.stockChartConfigs) return;
    this.stockChartConfigs = {
      ...this.stockChartConfigs,
      chart: objectAssign(this.stockChartConfigs.chart, value),
    };
  }

  private async createAlertLineOnCandle(
    value: ICandleContextMenu<{ pointValueY: number }>,
    idTradeSystem?: number,
    id: string = '',
    alertCharts: boolean = true
  ) {
    const direction =
      value.data!!.pointValueY >= this.lastVlClose ? '>=' : '<=';
    let tradeSystem = idTradeSystem,
      boxId = id;
    if (alertCharts) {
      this._alertService
        .createAlert(
          this.configStock.cd_stock_order,
          this.configStock.id_exchange,
          value.data!!.pointValueY,
          direction,
          false,
          this.configStock.tick_size_denominator
        )
        .subscribe((alert: any) => {
          boxId = `${this._newLineId}_${alert.result.id_trade_system}`;
          tradeSystem = alert.result.id_trade_system;
          this.createLineAlert(value.data!!.pointValueY, boxId, tradeSystem!!);
          this.stockChartService.alertCharts$.next({
            ref: this.refComponent,
            candle: value,
            type: 'NEW',
            boxId: boxId,
            idTradeSystem: alert.result.id_trade_system,
            alertAnother: false,
          });
          this._alertService.addNewAlertInOrderHistory(alert.result);
        });
    } else {
      this.createLineAlert(value.data!!.pointValueY, boxId, tradeSystem!!);
    }
  }

  private _listinerUpdateChart() {
    this._alertCharts$ = this.stockChartService.alertCharts$
      .pipe(takeUntil(this._onDestroy))
      .subscribe((value) => {
        if (value.ref != this.refComponent) {
          const funcs: any = {
            NEW: () =>
              this.createAlertLineOnCandle(
                value.candle!!,
                value.idTradeSystem,
                value.boxId,
                value.alertAnother
              ),
            UPDATE: () =>
              this.lineMoved(
                value.itemUpdate!!,
                value.alertAnother,
                value.newAlert!!,
                value.itemUpdate!!.updateByAlertColumn
              ),
            DELETE: () =>
              this.lineDeleted(value.itemDelete!!, value.alertAnother),
          };
          const func = funcs[value.type];
          if (func) {
            func();
          }
        }
      });
  }

  private handleNotificationAlert = (alert: any) => {
    if (!alert) return;
    const alertToUpdate = alert.alerts[alert.alerts.length - 1];
    alertToUpdate.isSelected =
      alertToUpdate.cd_stock ===
      this._globalStock.getGlobalStockSelected()?.cd_stock_order;
    if (!this.tigerChartViewChild) {
      this._alertService.updateAlertStatus(alertToUpdate);
      return;
    }

    const boxId = `${this._newLineId}_${alertToUpdate.id_trade_system}`;
    if (this.tigerChartViewChild.lineAlertData[boxId]) {
      const firstBoxId =
        this.tigerChartViewChild.lineAlertData[boxId].firstBoxId;
      this.removeLine = firstBoxId;
    }
    this._alertService.updateAlertStatus(alertToUpdate);
    this.cdr.detectChanges();
  };

  footerClick(data: { type: TFooterType }) {
    const { type } = data;
    const updateFunctions: any = {
      [TFooterType.LOG]: () => this.changeTypeAxis(),
      [TFooterType.SCALE]: () => this.changeFreeScale(),
      [TFooterType.CHART_BOOK]: () => this.chartBook(),
      [TFooterType.CHART_VOLUME_AT_PRICE]: () => this.chartVolumeAtPrice(),
      [TFooterType.FAST_ORDER]: () => this.fastOrder(),
      [TFooterType.VOLUME]: () => {
        this.showVolume = !this.showVolume;
      },
      [TFooterType.EVENTS]: () => this.updateShowEvents(),
      [TFooterType.ZOOM_IN]: () => {
        this.zoomIn = !this.zoomIn;
        this.cdr.detectChanges();
      },
      [TFooterType.EXEC_ORDERS]: () => {
        this.updateShowExecOrders();
      },
      [TFooterType.PREVIOUS_CLOSE]: () => {
        this.previousClose();
      },
      [TFooterType.SHOW_BID_ASK]: () => {
        this.bidAsk();
      },
    };
    const updateFunction = updateFunctions[type];
    if (updateFunction) {
      updateFunction();
    }
  }

  private changeTypeAxis() {
    this.isLog = !this.isLog;
    this._stockChartModalService.updateGlobalChartConfigs({
      useLogarithmic: this.isLog,
    });
  }

  private changeFreeScale() {
    this.isFreeScale = !this.isFreeScale;
    this._stockChartModalService.updateGlobalChartConfigs({
      isFreeScale: this.isFreeScale,
    });
    this.dontUpdateFreeScale = false;
    if (this.isFreeScale) {
      this.dontUpdateFreeScale = true;
    }
  }

  private fastOrder(configs?: any) {
    if (isTypeStockIndex(this.configStock.type)) return;
    if (configs) {
      this.showFastOrder = configs.showFastOrder;
    } else {
      this.showFastOrder = !this.showFastOrder;
    }
    let strategyIsEnabled = this._strategyService.strategyIsEnabled();
    let findActiveStrategy: IActiveStrategy | undefined =
      this._strategyService.findActiveStrategy();
    if (!this.showFastOrder) {
      this.getPositionAddCustody(false, false);
      this.updateOrderLine(false);
      strategyIsEnabled = false;
      findActiveStrategy = undefined;
      const stocksCustomsQtty =
        this._stockPreferencesService.stocksCustomPreferences;
      let stockQtty = this.stockChartConfigs.stock.standard_lot ?? 100;
      const stockPreference =
        stocksCustomsQtty[this.stockChartConfigs.stock.cd_stock_order!];
      if (stockPreference && stockPreference.qtty) {
        stockQtty = stockPreference.qtty;
      }
      this.changeNegotiationLot(stockQtty);
    }
    this.updateFastOrderInfo(strategyIsEnabled, findActiveStrategy);
    this.cdr.detectChanges();
  }

  private updateFastOrderInfo(
    isSelectedStrategy?: boolean,
    selectedStrategy?: IActiveStrategy
  ) {
    let showFastOrderTemp = this.showFastOrder;
    if (this.showFastOrderAux) {
      showFastOrderTemp = this.showFastOrderAux;
    }
    this.updateStockChartConfigs({
      fastOrder: {
        showFastOrder: showFastOrderTemp,
        strategy: selectedStrategy,
        isSelectedStrategy: isSelectedStrategy,
        width: this.fastOrderWidth,
      },
    });
    this.onUpdateMetadata();
    this._tigerChartOrdersService.setStrategy(
      this.component.id,
      false,
      selectedStrategy,
      isSelectedStrategy
    );
  }

  private updateShowEvents(configs?: any) {
    if (configs) {
      this.showEvents = configs.showEvents;
    } else {
      this.showEvents = !this.showEvents;
    }
    this.updateStockChartConfigs({
      showEvents: this.showEvents,
    });
    this.onUpdateMetadata();
    this.showAllEvents();
    this.cdr.detectChanges();
  }

  private updateShowExecOrders() {
    this.showExecOrders = !this.showExecOrders;
    const config = {
      showExecOrders: this.showExecOrders,
    };
    this.tigerChartViewChild.showExecOrderControl(config);
    this.updateStockChartConfigs(config);
    this.onUpdateMetadata();
    this.cdr.detectChanges();
  }

  private bidAsk(config?: any) {
    if (config) {
      this.showBidAsk = config.showBidAsk;
    } else {
      this.showBidAsk = !this.showBidAsk;
      this._stockChartModalService.updateGlobalChartConfigs({
        showBidAsk: this.showBidAsk,
      });
    }
    this._unsubscribeVolumeOrBook();
    this._setVolumeOrBookParams();
    this._subscribeVolumeOrBook();
    this.tigerChartViewChild.bidAsk({
      showBidAsk: config?.showBidAsk ?? this.showBidAsk,
    });
    this.cdr.detectChanges();
  }

  private previousClose(config?: any) {
    if (config) {
      this.showPreviousClose = config.showPreviousClose;
    } else {
      this.showPreviousClose = !this.showPreviousClose;
      this._stockChartModalService.updateGlobalChartConfigs({
        showPreviousClose: this.showPreviousClose,
      });
    }
    this.tigerChartViewChild.previousClose({
      showPreviousClose: config?.showPreviousClose ?? this.showPreviousClose,
    });
    this.cdr.detectChanges();
  }

  private chartBook() {
    this.showChartBook = !this.showChartBook;
    this.showChartVolumeAtPrice = false;
    this._stockChartModalService.updateGlobalChartConfigs({
      showChartBook: this.showChartBook,
      showChartVolumeAtPrice: false,
    });
    this._unsubscribeVolumeOrBook();
    this._setVolumeOrBookParams();
    this._subscribeVolumeOrBook();
    this.cdr.detectChanges();
  }

  setFreeScale(e: any) {
    this.isFreeScale = e.isFreeScale;
    this.cdr.detectChanges();
    if (e.updateMetadata) {
      this.dontUpdateFreeScale = false;
      this._stockChartModalService.updateGlobalChartConfigs({
        isFreeScale: this.isFreeScale,
      });
      if (this.isFreeScale) {
        this.dontUpdateFreeScale = true;
      }
    }
  }

  private chartVolumeAtPrice() {
    this.showChartVolumeAtPrice = !this.showChartVolumeAtPrice;
    this.showChartBook = false;
    this._stockChartModalService.updateGlobalChartConfigs({
      showChartVolumeAtPrice: this.showChartVolumeAtPrice,
      showChartBook: false,
    });
    this._unsubscribeVolumeOrBook();
    this._setVolumeOrBookParams();
    this._subscribeVolumeOrBook();
    this.cdr.detectChanges();
  }

  private bookVolumeAtPrice(config: any) {
    this.showChartBook = false;
    this.showChartVolumeAtPrice = false;
    if (config.showChartVolumeAtPrice === 'BOOK' || config.showChartBook) {
      this.showChartBook = true;
      this.showChartVolumeAtPrice = false;
    }
    if (
      config.showChartVolumeAtPrice === 'VOL_PRICE' ||
      (typeof config.showChartVolumeAtPrice === 'boolean' &&
        config.showChartVolumeAtPrice)
    ) {
      this.showChartBook = false;
      this.showChartVolumeAtPrice = true;
    }
    this.cdr.detectChanges();
  }

  showExecUpdate(config: any) {
    this.showExecOrders = config.showExecOrders;
    this.cdr.detectChanges();
    if (this.showOrderMarker) this.tigerChartViewChild.showOrderMarkerOnChart();
    else this.tigerChartViewChild.clearOrderMarkerAnnotation();
  }

  showOrderMarkerUpdate(config: any) {
    this.showOrderMarker = config.showOrderMarker;
    this.cdr.detectChanges();
    if (this.showOrderMarker) this.tigerChartViewChild.showOrderMarkerOnChart();
    else this.tigerChartViewChild.clearOrderMarkerAnnotation();
  }

  showFlagExecUpdate(config: any) {
    this.showFlagOrders = config.showFlagOrders;
    this.cdr.detectChanges();
    if (this.showExecOrders && this.showFlagOrders)
      this.tigerChartViewChild.showOrderFlagOnChart();
    else this.tigerChartViewChild.clearOrderFlagAnnotation();
  }

  showOpenFlagUpdate(config: any) {
    this.showOpenFlag = config.showOpenFlag;
    this.cdr.detectChanges();
    if (this.showExecOrders && this.showOpenFlag)
      this.tigerChartViewChild.showOrderFlagOpenOnChart();
    else this.tigerChartViewChild.clearOrderFlagOpenAnnotation();
  }

  hideFastOrderDaytradeStrategy(config: any) {
    this.showFastOrderDaytradeStrategy = config.showFastOrderDaytradeStrategy;
    this.updateStockChartConfigs({
      showFastOrderDaytradeStrategy: this.showFastOrderDaytradeStrategy,
    });
    this.onUpdateMetadata();
    this.tigerChartViewChild.hideFastOrderDaytradeStrategy(config);
    this.getPositionAddCustody(false, true);
    this.updateOrderLine(false);
    this.cdr.detectChanges();
  }

  private updateIndicatorConfig = (config: any) => {
    this.stockChartConfigs.indicators?.actives?.forEach((active, i) => {
      if (typeof active === 'string') {
        if (active === config.indicator) {
          this.stockChartConfigs.indicators!!.actives!![i] = config;
        }
      } else if (active.indicator === config.indicator) {
        this.stockChartConfigs.indicators!!.actives!![i] = config;
      }
    });
    this.onUpdateMetadata();
  };

  changeActiveStock(stock: ISearchStock) {
    stock.cd_stock = stock.ds_asset + stock.cd_security_type;
    this.getStock(stock, true, true, true, true);
  }

  updateOrderWidth(width: number) {
    this.fastOrderWidth = width;
    this.updateStockChartConfigs({
      fastOrder: {
        showFastOrder: this.showFastOrder,
        strategy: this._selectedStrategy,
        isSelectedStrategy: this._isSelectedStrategy,
        width,
      },
    });
    this.onUpdateMetadata();
  }

  handleTour(event: ITigerChartTourEventEmitter): void {
    if (event.action === 'STARTED') this._disableAllFuncs();
    if (event.action === 'USE_DEFAULT_HEADER')
      this.forceUseDefaultHeaderType = true;
    if (event.action === 'CLOSED') this._resetChartPreferencesOnTourClosed();
  }

  public withToolsChange(event: boolean): void {
    this.stockChartConfigs.chart.withTools = event;
    this.onUpdateMetadata();
  }

  public defaultCursorChange(event: string): void {
    this.stockChartConfigs.chart.defaultCursor = event;
    this.onUpdateMetadata();
  }

  private _disableAllFuncs(): void {
    const funcsValue: any = {
      3: this.showChartBook,
      4: this.showChartVolumeAtPrice,
      5: this.showFastOrder,
      6: this.showVolume,
      7: this.showEvents,
    };
    Object.keys(funcsValue).forEach((index: any) => {
      if (funcsValue[index]) {
        this._toggleChartFuncs(index, false, true);
        this._funcsPreviusValue[index] = true;
      }
    });
  }

  private _toggleChartFuncs(
    funcIndex: number,
    isResetToDefaultOptions: boolean = false,
    onlyDisable: boolean = false
  ): void {
    const handlerFuncsValue: any = {
      3: (value: boolean) => (this.showChartBook = value),
      4: (value: boolean) => (this.showChartVolumeAtPrice = value),
      5: (value: boolean) => (this.showFastOrder = value),
      6: (value: boolean) => (this.showVolume = value),
      7: (value: boolean) => (this.showEvents = value),
    };
    if (!isResetToDefaultOptions) {
      Object.keys(handlerFuncsValue).forEach((index) =>
        handlerFuncsValue[index](false)
      );
    }
    if (!onlyDisable && !isNullOrUndefined(funcIndex)) {
      handlerFuncsValue[funcIndex](true);
    }
    this.showAllEvents();
  }

  private _resetChartPreferencesOnTourClosed(): void {
    this._toggleChartFuncs(0, false, true);
    Object.keys(this._funcsPreviusValue).forEach((index: any) =>
      this._toggleChartFuncs(index, true)
    );
    this.forceUseDefaultHeaderType = false;
  }

  openTool(code: 'BUSINESS_PROFILE_COMPONENT' | 'RANKING_BROKER') {
    const tool = this.componentsService.getToolsList().get(code);
    tool.link = this.component.metadata.headerOptions.link;
    tool.data = {
      stock: this.stockChartConfigs.stock,
    };
    this._homeService.addComponent(tool);
  }

  private getPositionAddCustody(
    updateMetadata: boolean,
    isFromFastOrder: boolean
  ) {
    const position = this.getPosition();
    position && this.addCustody(position, updateMetadata, isFromFastOrder);
  }

  private tradingOffset(config: any) {
    this.updateStockChartConfigs({
      chartTradingOffset: config.chartTradingOffset,
    });
    this.onUpdateMetadata();
  }

  private updateShowVolume(config: any) {
    this.showVolume = config.showVolume;
    this.cdr.detectChanges();
  }

  private getPosition() {
    return this.positions.get(
      this.stockChartService.getPositionKey(this.configStock, this._account)
    );
  }

  private _displayFastOrder(): boolean {
    if (this.stockChartConfigs?.initialOptions?.showFastOrder) {
      delete this.stockChartConfigs.initialOptions;
      this.onUpdateMetadata();
      return true;
    }
    return this.stockChartConfigs.chart.fastOrder?.showFastOrder ?? false;
  }

  private emitAddLine(line: TCandleLineOptions) {
    this.stockChartService.addLine.next({
      line,
      refComponent: this.refComponent,
    });
  }

  private createLineAlert(value: number, boxId: string, tradeSystem: number) {
    const line = this.stockChartHelper.createLineAlert(
      value,
      boxId,
      tradeSystem!!,
      this.lastVlClose,
      this.tickSize,
      this.configStock.cd_segment,
      this.configStock.ds_asset,
      this.configStock.cd_stock_order,
      this.tigerChartViewChild.tooltip.indicators
    );
    this.emitAddLine(line);
  }

  private verifyHasStockInfo(stock: ISearchStock): boolean {
    return (
      isNullOrUndefined(stock.standard_lot) ||
      !stock.cd_segment ||
      !stock.ds_asset ||
      isNullOrUndefined(stock.tick_size_denominator) ||
      !stock.stockVariation
    );
  }

  private subscribeUpdateChartConfig() {
    this.subscribeUpdateChartConfig$ = this.stockChartService
      .subscribeUpdateChart(this.refComponent)
      .subscribe((data) => {
        this.updateChartData(data.isChangeInterval);
      });
  }

  private updateSelectedAccount = (account: IAccountSelect) => {
    this._account = account;
    this.deleteCustodyAnnotation();
    this.getPositionAddCustody(true, false);
    this.processPositionOrders(false);
    this.cdr.detectChanges();
  };

  private deleteCustodyAnnotation() {
    this.tigerChartViewChild && this.tigerChartViewChild.deleteOrders();
    if (this.userCustody) {
      this.tigerChartViewChild.deleteLine(this.userCustody.item!);
      this.tigerChartViewChild.deleteLine(COVERAGE_PRICE_ID);
    }
  }

  private daytradeChanged = () => {
    this.updateFastOrder();
    this.getPositionAddCustody(true, false);
    this.updateOrderLine(false);
    this.redrawAlertsOnChart();
    this.cdr.detectChanges();
  };

  private redrawAlertsOnChart() {
    if (
      this._alertService.allAlerts() &&
      this._alertService.allAlerts().length
    ) {
      this.alerts = [...this.filterAlerts(this._alertService.allAlerts())];
    }
  }

  private resetCustodyUser = () => {
    this.tigerChartViewChild && this.tigerChartViewChild.deleteCustody();
    this.resetUserCustody();
    this.cdr.detectChanges();
  };

  private accountListener() {
    this._onAccountChange$ = this._multibrokerService
      .onUpdateSelectedAccountChannel()
      .subscribe(this.updateSelectedAccount);
  }

  private alertListener() {
    this._alerts$ = this._processAlertService
      .getNotiticationObs()
      .subscribe(this.handleNotificationAlert);
  }

  private sendOrderListener() {
    this._sendOrder$ = this.stockChartService.sendOrder$
      .pipe(filter((data) => data.ref === this.component.id))
      .subscribe(this.sendOrder);
  }

  private daytradeListener() {
    this._dayTradeMode$ = this._dayTradeService.dayTradeMode.subscribe(
      this.daytradeChanged
    );
  }

  private joystickListener() {
    this._joystick$ = this.joystickService
      .onSimulatorActivate()
      .subscribe(this.resetCustodyUser);
  }

  private chartOrdersConfigurationListener() {
    this._configuration$ = this._tigerChartOrdersService.configuration
      .pipe(filter((data) => data.refComponent === this.component.id))
      .subscribe(this.updateStrategyByFastOrder);
  }

  private updateStrategyByFastOrder = (data: ConfigurationFastOrder) => {
    this._selectedStrategy = data.strategy;
    this._isSelectedStrategy = data.isSelectedStrategy;
    this.getPositionAddCustody(true, true);
    this.updateOrderLine(false);
  };

  private indicatorListener() {
    this._indicatorConfig$ = this._settingsModalService
      .onEvents()
      .pipe(filter((data) => data.ref === this.refComponent))
      .subscribe((data) => {
        this.updateIndicatorConfig(data.indicatorConfig);
      });
  }

  private closeModalTokenListener() {
    this.closedModalToken$ = this.orderTokenService.closedModalToken
      .pipe(filter((data) => data.refComponent === this.refComponent))
      .subscribe((data) => {
        this.updateCustodyCheeps(
          data.ordType,
          data.gainOrderPrice,
          data.lossOrderPrice
        );
      });
  }

  private initializeListeners() {
    this._listinerUpdateChart();
    this.accountListener();
    this.alertListener();
    this.sendOrderListener();
    this.daytradeListener();
    this.joystickListener();
    this.chartOrdersConfigurationListener();
    this.indicatorListener();
    this.closeModalTokenListener();
  }

  private unsubscriber() {
    this.custodyChannelSubs && this.custodyChannelSubs.unsubscribe();
    this.custodySnap$ && this.custodySnap$.unsubscribe();
    this._onDataLoad$ && this._onDataLoad$.unsubscribe();
    this._updateAlertsInChart$ && this._updateAlertsInChart$.unsubscribe();
    this._onAccountChange$ && this._onAccountChange$.unsubscribe();
    this._sendOrder$ && this._sendOrder$.unsubscribe();
    this._dayTradeMode$ && this._dayTradeMode$.unsubscribe();
    this._joystick$ && this._joystick$.unsubscribe();
    this._configuration$ && this._configuration$.unsubscribe();
    this._indicatorConfig$ && this._indicatorConfig$.unsubscribe();
    this.closedModalToken$ && this.closedModalToken$.unsubscribe();
    this._alerts$ && this._alerts$.unsubscribe();
    this._orderChannel$ && this._orderChannel$.unsubscribe();
    this._custodyChannel$ && this._custodyChannel$.unsubscribe();
    this._alertCharts$ && this._alertCharts$.unsubscribe();
    this.onUpdateMetadata$ && this.onUpdateMetadata$.unsubscribe();
    this.subscribeUpdateChartConfig$ &&
      this.subscribeUpdateChartConfig$.unsubscribe();
    this.addOrUpdateOrder$ && this.addOrUpdateOrder$.unsubscribe();
  }

  private deletePreviousClose() {
    this.tigerChartViewChild.deletePreviousVlClose();
  }

  private getAllEvents() {
    const events = forkJoin({
      corporateEvents: this.getCorporateEvents(0),
      newsChartEvents: this.getNewsChart(0),
      tradeIdeaEvents: this.getTradeIdeaEvents(),
      relevantEvents: this.getRelevantEvents(0),
    });
    events.subscribe((data) => {
      this.events = deepClone(data);
      this.cdr.detectChanges();
    });
  }

  private showAllEvents() {
    if (this.showEvents) {
      for (let i = this.datesToEvents.length - 1; i >= 0; i--) {
        const date = this.datesToEvents[i];
        if (date === 0) {
          this.getAllEvents();
        } else {
          this.updateAllEvents(date);
        }
      }
    } else {
      this.events = undefined;
      this.cdr.detectChanges();
    }
  }

  private updateAllEvents(idPoint: number) {
    this.getNewsChart(idPoint).subscribe((events: TNewsChartEvents[]) => {
      this.tigerChartViewChild.updateNewsChartEvents(events);
    });
    this.getCorporateEvents(idPoint).subscribe((events: TCorporateEvents[]) => {
      this.tigerChartViewChild.updateCorporateEvents(events);
    });
    this.getRelevantEvents(idPoint).subscribe((events: TRelevantEvents[]) => {
      this.tigerChartViewChild.updateRelevantEvents(events);
    });
  }

  private clearCheetahParams() {
    this.bookParams = undefined;
    this.volumeParams = undefined;
    this.quoteParams = undefined;
    this.candleParams = undefined;
    this._legSubscribed = '';
  }

  openIndicatorsModal() {
    this.stockChartHeaderViewChild.openIndicatorsModal();
  }
}
