export interface IPosition {
  cd_stock: string;
  id_exchange: string;
  nm_company_valemobi: string;
  qtty_d3: string;
  qtty_formatted: string;
  vl_total: string;
  vl_total_cost: string;
  vl_total_previous: string;
  vl_cost: string;
  vl_last: string;
  vl_price_buy: string;
  vl_price_buy_accumulated: string;
  can_show_result_in_grid: boolean;
  cd_segment: string;
  id_broker: string;
  has_swing: string;
  has_day_trade: string;
  cd_instrument_type: string;
  command: string;
  custodian_name: string;
  id_custodian: string;
  id_fund: string;
  id_portfolio: string;
  item: string;
  key: string;
  net_day: string;
  net_day_daytrade: string;
  net_day_swing: string;
  open_buy_order: string;
  open_sell_order: string;
  pl: string;
  pl_daytrade: string;
  pl_swing: string;
  pnl: string;
  pnl_day: string;
  pnl_day_daytrade: string;
  pnl_day_swing: string;
  pnl_daytrade: string;
  pnl_open: string;
  pnl_open_daytrade: string;
  pnl_open_swing: string;
  pnl_swing: string;
  portfolio_name: string;
  preco_ultimo: string;
  qtty_buy_day: string;
  qtty_final: string;
  qtty_final_daytrade: string;
  qtty_final_swing: string;
  qtty_sell_day: string;
  vl_price_avg: string;
  vl_price_avg_daytrade: string;
  vl_price_avg_swing: string;
}

export enum GRID_CONFIG_POSITION_ENUM {
  HIDDEN_COLUMN = 'HIDDEN_COLUMN',
  TOGGLE_TOTAL_LINE = 'TOGGLE_TOTAL_LINE',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  CLEAR_SORT = 'CLEAR_SORT',
  RESET = 'RESET',
  HELP = 'HELP',
  PREFERENCES = 'PREFERENCES',
  EXPORT_DATA = 'EXPORT_DATA',
}
