import {
  NEWS_COMPONENT,
  STOCK_CHART_COMPONENT,
  STOCK_TABLE_COMPONENT,
} from '@core/workspace/constants';
import { LayoutBase } from '../base';
import { HEATMAP_COMPONENT } from '@core/workspace/constants/components/heatmap';
import { STOCK_TABLE_VIEW } from '@shared/components/stock-table-views';

export class Mercado extends LayoutBase {
  override name = 'Mercado';
  override components = [
    STOCK_TABLE_COMPONENT({
      height: 49,
      width: 19,
      data: {
        initialOptions: {
          initialView: STOCK_TABLE_VIEW.MINICARD,
          dynamicList: 'MOVERS_HIGH',
        },
      },
    }),
    STOCK_TABLE_COMPONENT({
      height: 49,
      width: 19,
      top: 'calc(49% + 3px)',
      data: {
        initialOptions: {
          initialView: STOCK_TABLE_VIEW.MINICARD,
          dynamicList: 'MOVERS_LOW',
        },
      },
    }),
    HEATMAP_COMPONENT({
      left: 'calc(19% + 8px)',
      height: window.innerHeight - 30,
      width: window.innerWidth * 0.4 - 8,
      link: false,
      data: { initialOptions: { initialList: 'SETORES_BR' } },
    }),
    STOCK_CHART_COMPONENT({
      left: 'calc(59% + 16px)',
      height: 49,
      width: 40,
    }),
    NEWS_COMPONENT({
      left: 'calc(59% + 16px)',
      top: 'calc(50%)',
      height: window.innerHeight / 2 - 50,
      width: window.innerWidth * 0.4 - 8,
      minWidth: 300,
    }),
  ];
}
