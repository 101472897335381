import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextMenuComponent } from './context-menu.component';
import { FlaIconModule } from '@shared/rocket-components/components';
import { RocketIconsModule } from '@shared/rocket-icons/rocket-icons.module';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { ComponentsCountComponent } from '../components-count/components-count.component';

@NgModule({
  declarations: [ContextMenuComponent],
  imports: [
    CommonModule,
    FlaIconModule,
    RocketIconsModule,
    TooltipsModule,
    ComponentsCountComponent,
  ],
  exports: [ContextMenuComponent],
  providers: [],
})
export class ContextMenuModule {}
