<section class="p-4" style="height: calc(100% + 8px)">
  <div class="w-100 h-100 d-inline-flex">
    <div
      class="d-flex flex-column border-right border-neutral-strong position-relative"
      style="min-width: 200px; max-width: 200px"
    >
      <span class="fw-bold mb-3">Filtros</span>
      <div style="max-height: 90%; overflow-y: auto">
        <ng-container *ngFor="let filter of filters">
          <div
            class="w-100 mb-2 d-inline-flex align-items-center justify-content-between"
          >
            <div class="d-inline-flex align-items-center">
              <label
                for="strategyOption"
                class="cursor-pointer text-truncate"
                (click)="changeFilter(filter)"
                [style.max-width.px]="166"
              >
                <input
                  class="form-check-input mr-1"
                  type="checkbox"
                  name="strategyOption"
                  [ngModel]="filter.id === filterSelected.id"
                  [style.min-width.px]="18"
                />
                {{ filter.name }}
              </label>
            </div>
            <rocket-button
              *ngIf="!filter.isStart"
              type="icon"
              (rocketClick)="startStopFilter(filter)"
            >
              <fla-icon
                name="play_circle"
                size="icon-size-tiny"
                css="pr-1 text-feedback-success"
                tooltip="Executar"
              ></fla-icon>
            </rocket-button>
            <rocket-button
              *ngIf="filter.isStart"
              type="icon"
              (rocketClick)="startStopFilter(filter)"
            >
              <fla-icon
                name="stop_circle"
                size="icon-size-tiny"
                css="pr-1 text-feedback-error"
                tooltip="Executando"
              ></fla-icon>
            </rocket-button>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="pl-3 position-relative" style="width: calc(100% - 190px)">
      <div class="w-100 d-flex flex-column mb-3">
        <div class="w-100 d-flex flex-column mb-3">
          <span class="fw-bold">Configurações</span>
          <span class="fs-6">
            Insira os parâmetros para executar a sua estratégia.
          </span>
        </div>

        <form [formGroup]="formTypeExecFilter">
          <fla-radio-group formControlName="typeExecFilter">
            <fla-radio-button
              name="typeExecFilter"
              label="Tempo real"
              [value]="1"
              [isInline]="true"
              css="fw-bold"
              [isChecked]="isRealTime"
            ></fla-radio-button>
            <fla-radio-button
              name="typeExecFilter"
              label="BackTest"
              [value]="2"
              [isInline]="true"
              css="fw-bold"
              [isChecked]="isBackTest"
              [disabled]="filterSelected.isStart"
            ></fla-radio-button>
          </fla-radio-group>
        </form>

        <div class="w-100 d-flex align-items-baseline mt-6">
          <div class="round border position-relative">
            <span
              class="position-absolute fw-bold fs-5"
              style="margin-top: -22px"
            >
              Ativo
            </span>
            <app-search-stock
              [resetInput]="false"
              [ref]="refComponent"
              [headerOptions]="headerOptions"
              [searchLabel]="searchLabel"
              [initStock]="stockSelected"
              [isDisable]="filterSelected.isStart"
              css=""
              (selectStockChange)="selectStock($event)"
            ></app-search-stock>
          </div>

          <div class="border round px-3 ml-4 position-relative">
            <span
              class="position-absolute fw-bold fs-5"
              style="margin-top: -22px; margin-left: -18px"
            >
              Período
            </span>
            <app-dropdown menuAlign="dropdown-menu-right">
              <a
                *ngIf="intervalSelected"
                type="button"
                class="nav-link p-2 lh-sm round-top dropdown-toggle fs-5 fw-bold"
                [ngClass]="{ 'text-white-50': filterSelected.isStart }"
                appDropdownButton
                [rtDropnAutoClose]="true"
                [rtDropnDisabled]="filterSelected.isStart"
                style="height: 30px"
              >
                {{ intervalSelected.short }}
              </a>
              <a
                *ngFor="let interval of intervalChart"
                class="dropdown-item"
                type="button"
                (click)="selectInterval(interval)"
              >
                {{ interval.name }}
              </a>
            </app-dropdown>
          </div>
        </div>
      </div>

      <section
        class="mb-3"
        style="height: 25px"
        *ngIf="filterSelected?.stocks?.length"
      >
        <span
          class="mr-1 border round p-2 fs-6"
          *ngFor="let item of filterSelected.stocks; let i = index"
        >
          {{ labelStock(item) }}

          <rocket-button
            *ngIf="filterSelected.stocks.length > 1 && !filterSelected.isStart"
            type="icon"
            (rocketClick)="removeStock(i)"
          >
            <fla-icon name="close" size="icon-size-micro"></fla-icon>
          </rocket-button>
        </span>
      </section>

      <div class="w-100 d-flex flex-column mb-1 opacity-intense">
        <span class="fw-bold">Condições</span>
      </div>

      <div
        class="opacity-intense"
        [ngClass]="{
          'overflow-y-scroll': filterSelected.conditional.length > 4
        }"
        style="max-height: calc(100% - 270px)"
      >
        <div
          *ngFor="let conditional of filterSelected.conditional"
          class="w-100 d-inline-flex align-items-center mb-2 position-relative"
        >
          <span class="mr-1">Se </span>

          <section
            class="border d-flex justify-content-between mr-1 py-1 round-sm"
            style="height: 30px"
            [style]="
              !(conditional['leftSide'].type === 'CANDLE_PATTERNS')
                ? 'width: 36%;'
                : 'width: 100%;'
            "
          >
            <div class="pl-1 fs-5 truncate">
              {{ mountedLabel(conditional.leftSide) }}
            </div>
          </section>

          <section
            *ngIf="!(conditional['leftSide'].type === 'CANDLE_PATTERNS')"
            class="border round-sm pt-1 pl-4"
            style="width: 14%; height: 30px"
          >
            <div class="pl-1 fs-5">
              {{ conditional.relation }}
            </div>
          </section>

          <section
            *ngIf="!(conditional['leftSide'].type === 'CANDLE_PATTERNS')"
            class="border d-flex justify-content-between ml-1 py-1 round-sm"
            style="height: 30px; width: 36%"
          >
            <div class="pl-1 fs-5 truncate">
              {{ mountedLabel(conditional.rightSide) }}
            </div>
          </section>
        </div>
      </div>

      <div
        class="w-100 position-absolute bottom-0 pr-2 d-flex justify-content-end"
      >
        <rocket-button
          css="ml-2"
          [label]="labelButton"
          [isSmall]="true"
          (rocketClick)="startStopFilter()"
        ></rocket-button>
      </div>
    </div>
  </div>
</section>
