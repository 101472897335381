import { formatByTick, isCrypto } from 'src/app/utils/utils.functions';

export class DataResume {
  tick_size_denominator: any;
  private _stock: any;
  private _preco_ultimo: any;
  private _variacao_dia: any;
  private _preco_minimo: any;
  private _preco_maximo: any;
  private _volume: any;
  private _arrow_hex: any;
  private _min_price_increment: any;

  get vl_preco_ultimo() {
    return +this._preco_ultimo;
  }

  get vl_variacao_dia() {
    return +this._variacao_dia;
  }

  get vl_preco_minimo() {
    return +this._preco_minimo;
  }

  get vl_preco_maximo() {
    return +this._preco_maximo;
  }

  get vl_volume() {
    return +this._volume;
  }

  get preco_ultimo() {
    return formatByTick(
      this._preco_ultimo,
      this.getTickSize(this._preco_ultimo)
    );
  }

  get variacao_dia() {
    return this._variacao_dia;
  }

  get preco_minimo() {
    return formatByTick(
      this._preco_minimo,
      this.getTickSize(this._preco_minimo)
    );
  }

  get preco_maximo() {
    return formatByTick(
      this._preco_maximo,
      this.getTickSize(this._preco_maximo)
    );
  }

  get volume() {
    return this._volume;
  }

  get tick_size_volume() {
    return this.getTickSize(this._volume);
  }

  get arrow_hex() {
    return this._arrow_hex;
  }

  get min_price_increment() {
    return parseFloat(this._min_price_increment) || 1;
  }

  getRawValues() {
    return {
      tick_size_denominator: this.tick_size_denominator,
      stock: this._stock,
      preco_ultimo: this._preco_ultimo,
      variacao_dia: this._variacao_dia,
      preco_minimo: this._preco_minimo,
      preco_maximo: this._preco_maximo,
      volume: this._volume,
      arrow_hex: this._arrow_hex,
      min_price_increment: this._min_price_increment,
    };
  }

  getTickSize(value: string | number): number {
    if (isCrypto(this._stock.ds_type)) {
      return +value > 1 ? 0 : this.tick_size_denominator ?? 8;
    }
    return this.tick_size_denominator;
  }

  constructor(stock: any, data: any, oldData: any) {
    this._stock = stock;
    const {
      tick_size_denominator,
      preco_ultimo,
      variacao_dia,
      preco_minimo,
      preco_maximo,
      volume,
      arrow_hex,
      min_price_increment,
    } = data;
    this.tick_size_denominator =
      tick_size_denominator && tick_size_denominator > -1
        ? tick_size_denominator
        : oldData.tick_size_denominator;
    this._preco_ultimo = preco_ultimo || oldData.preco_ultimo;
    this._variacao_dia = variacao_dia || oldData.variacao_dia;
    this._preco_minimo = preco_minimo || oldData.preco_minimo;
    this._preco_maximo = preco_maximo || oldData.preco_maximo;
    this._volume = volume || oldData.volume;
    this._arrow_hex = arrow_hex || oldData.arrow_hex;
    this._min_price_increment =
      min_price_increment || oldData.min_price_increment;
  }
}
