import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components/index';
import { deepClone } from '@shared/rocket-components/utils';
import { TAB_DEFAULT_PREFERENCES } from '../../constants/headers.const';
import { IOrdersTabPreferences } from '../../interfaces/orders-history.interface';
import { TABS_TYPE } from '../../constants/orders-history.const';

@Component({
  selector: 'app-modal-orders-tab-settings',
  templateUrl: './modal-orders-tab-settings.component.html',
  styles: [
    `
      .position-top {
        top: -14px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalOrdersTabSettingsComponent
  extends RocketModalRef
  implements OnInit
{
  public orderHistoryPreferences: IOrdersTabPreferences[] = [];
  public qtyActiveTabs: number = 0;
  tabSelected!: Map<string, any>;
  positionColumn!: any;
  constructor(
    @Inject(ROCKET_MODAL_DATA)
    private data: {
      tabs: any[];
      qtyDefaultTabs: number;
    },
    service: RocketModalService,
    private cdr: ChangeDetectorRef
  ) {
    super(service);
  }

  ngOnInit() {
    this.tabSelected = this.data.tabs.reduce((map: any, item: any) => {
      if (
        item.key !== TABS_TYPE.INTRADIA &&
        item.key !== TABS_TYPE.SWINGTRADING
      )
        map.set(item.key, true);
      else this.positionColumn = item;
      return map;
    }, new Map<string, any>());
    this._buildCurrentTabsPreferences();
  }

  isAllColumnsCheck = () => {
    const defaultPreferences: IOrdersTabPreferences = deepClone(
      TAB_DEFAULT_PREFERENCES
    );
    for (const item of defaultPreferences.columns) {
      if (!this.tabSelected.has(item.key)) {
        return false;
      }
    }
    return true;
  };

  private _buildCurrentTabsPreferences(): void {
    const defaultPreferences: IOrdersTabPreferences = deepClone(
      TAB_DEFAULT_PREFERENCES
    );
    defaultPreferences.allCheck = this.isAllColumnsCheck();
    this.orderHistoryPreferences = [defaultPreferences];
    this.cdr.detectChanges();
  }

  public closeModal(): void {
    this.close(null, false);
  }

  public confirmChanges(): void {
    if (this.positionColumn)
      this.tabSelected.set(this.positionColumn.key, true);
    const tabs = [];
    for (const tab of this.tabSelected.keys()) {
      tabs.push(tab);
    }
    this.close(tabs, false);
  }

  public toggleAllTabContent(displayAllContent: boolean): void {
    const defaultPreferences: IOrdersTabPreferences = deepClone(
      TAB_DEFAULT_PREFERENCES
    );
    defaultPreferences.columns.forEach((item: any) => {
      displayAllContent
        ? this.tabSelected.set(item.key, true)
        : item.key !== TABS_TYPE.POSITION && this.tabSelected.delete(item.key);
    });
    this.orderHistoryPreferences[0].allCheck = displayAllContent;
    this.cdr.detectChanges();
  }

  public setVisibleOrInvisible(displayItem: boolean, columnKey: string): void {
    displayItem
      ? this.tabSelected.set(columnKey, true)
      : this.tabSelected.delete(columnKey);
    this.orderHistoryPreferences[0].allCheck = this.isAllColumnsCheck();
    this.cdr.detectChanges();
  }
}
