<div
  *ngIf="seriesToShow.length"
  class="gap-1 d-inline-flex bg-neutral-strong text-dark fs-6 fw-bold p-2 round-sm tooltip-fake"
  [style.left.px]="tooltipX"
  [style.top.px]="tooltipY"
>
  <div class="w-100 d-flex flex-column text-light">
    <span class="mb-1 fw-bold">{{ dateTime }}</span>
    <div
      class="grid-content"
      [ngClass]="{ 'hide-one-colume': hideCurrentValue }"
    >
      <span
        *ngIf="!hideCurrentValue"
        class="border-right border-thin text-right pr-2 mb-1 table-header"
        [ngClass]="borderColor"
      >
        Atual
      </span>
      <span class="mb-1" [ngClass]="{ 'table-header': hideCurrentValue }">
        Acum.
      </span>
      <ng-container *ngFor="let serie of seriesToShow">
        <ng-container
          *ngIf="!xValueToDisplay || serie.xValue === xValueToDisplay"
        >
          <div
            class="round-lg dot-tooltip"
            [ngStyle]="{ 'background-color': serie.color }"
          ></div>
          <span> {{ serie.params.broker }} </span>
          <span
            *ngIf="!hideCurrentValue"
            [ngClass]="borderColor"
            class="border-right border-thin text-right pr-2"
          >
            {{ serie.params.volume_net }}
          </span>
          <span [ngClass]="{ 'text-left': !hideCurrentValue }">
            {{ serie.params.volume_net_sum }}
          </span>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
