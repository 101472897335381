import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IFlashTooltip } from '../../interface/ranking-broker.interface';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-flash-market-tooltip',
  templateUrl: './flash-market-tooltip.component.html',
  styles: [
    `
      .grid-content {
        display: grid;
        align-items: center;
        gap: 4px;
        grid-template-columns: 15px repeat(3, auto);
        column-gap: 8px;

        &.hide-one-colume {
          grid-template-columns: 15px repeat(2, auto) !important;
        }
      }

      .table-header {
        grid-column: 3 / 3;
      }

      .tooltip-fake {
        width: 250px;
        position: fixed;
        z-index: 999;
        box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.1);

        .dot-tooltip {
          width: 8px !important;
          height: 8px !important;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlashMarketTooltipComponent implements OnChanges {
  @Input() public tooltipX: number = 0;
  @Input() public tooltipY: number = 0;
  @Input() public tooltipWidth: number = 250;
  @Input() public seriesToShow: IFlashTooltip[] = [];
  @Input() public hideCurrentValue: boolean = false;
  @Input() public isDarkMode: boolean = false;
  @Input() public xValueToDisplay!: number | null;
  @Input() public dateTime!: string;

  public borderColor: 'border-dark' | 'border-light' = 'border-light';

  ngOnChanges(changes: SimpleChanges): void {
    const { isDarkMode } = changes;
    if (!isNullOrUndefined(isDarkMode?.currentValue)) {
      this.borderColor = isDarkMode.currentValue
        ? 'border-light'
        : 'border-dark';
    }
  }
}
