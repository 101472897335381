export const GENERIC_INTRO_STEPS = {
  STOCK_SELECTOR: (component?: Element) => ({
    element: component
      ? component?.querySelectorAll('app-search-stock')[0]
      : document.querySelectorAll('app-search-stock')[0],
    title: 'Seletor de papel',
    intro:
      'Aqui você seleciona o ativo que deseja analisar. Para alterar, basta clicar no papel e digitar o código de negociação.',
    position: 'right',
    preChange: function () {
      this.element = component
        ? component?.querySelectorAll('app-search-stock')[0]
        : document.querySelectorAll('app-search-stock')[0];
      this.position = 'right';
    },
  }),
  DOC_LINK: (component?: Element) => ({
    element: component
      ? component.querySelectorAll('#LINK')[0]
      : document.querySelectorAll('#LINK')[0],
    title: 'Ícone de link',
    intro:
      'Você pode ativar ou desativar o link para acompanhar vários ativos de forma simultânea. Se o link estiver ativado (azul), todos os componentes estarão com o mesmo ativo.',
    position: 'left',
    preChange: function () {
      this.element = component
        ? component.querySelectorAll('#LINK')[0]
        : document.querySelectorAll('#LINK')[0];
      this.position = 'left';
    },
  }),
  COMPONENT_TOGGLE_FULLSCREEN: (component?: Element) => ({
    element: component
      ? component?.querySelectorAll('#TOGGLE_FULLSCREEN')[0]
      : document.querySelectorAll('#TOGGLE_FULLSCREEN')[0],
    title: 'Maximizar',
    intro: `Clicando nesse ícone, você maximiza o componente na sua tela, tendo uma melhor visualização de cada funcionalidade.`,
  }),
  CLOSE: (component?: Element) => ({
    element: component
      ? component?.querySelectorAll('#CLOSE_COMPONENT')[0]
      : document.querySelectorAll('#CLOSE_COMPONENT')[0],
    title: 'Fechar componente',
    intro: `Você pode fechar a visualização de um componente a qualquer momento.  <br /> <br />
      Para adicionar um novo, clique em ‘Componentes’ no menu acima e selecione qual deseja inserir.`,
  }),
  STOCK_DETAILS: (component: Element) => ({
    title: 'Detalhes do ativo',
    intro:
      'Aqui você encontra um resumo com as informações mais relevantes do ativo, como cotação atual, variação, preço mínimo e máximo, além do volume negociado.',
    element: component.querySelector('app-details-tab'),
    position: 'bottom',
    preChange: function () {
      this.element = component.querySelector('app-details-tab');
      this.position = 'bottom';
    },
  }),
  FORCE_CHART: (component: Element) => ({
    title: 'Barra de pressão',
    intro:
      'A barra de pressão aponta o lado mais forte da negociação, sendo azul para a força compradora e a amarelo para a vendedora.',
    element: component.querySelector('app-force-chart'),
    position: 'bottom',
    preChange: function () {
      this.element = component.querySelector('app-force-chart');
      this.position = 'bottom';
    },
  }),
  TOUR_END: {
    title: 'Agora é com você! 🖖',
    intro: `
      Aproveite as funcionalidades do RocketTrader.
      Se quiser rever esse tour e de outros componentes, clique no menu 'Ajuda'.
      `,
  },
  CONTEXT_MENU_RESET_AND_HELP: (
    selector: string,
    position: string = 'bottom'
  ) => ({
    title: 'Restaurar e Ajuda',
    intro:
      'Você pode voltar para as configurações do sistema clicando em ‘Restaurar’. E para rever esse e outros tutoriais em cada um dos componentes, basta clicar em ‘Ajuda’.',
    element: document.querySelector(selector),
    position: position,
    preChange: function () {
      this.element = document.querySelector(selector);
      this.position = position;
    },
  }),
};
