import {
  Component,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
  HostListener,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import {
  auditTime,
  debounceTime,
  delay,
  filter,
  takeUntil,
} from 'rxjs/operators';
import {
  BodyScrollEvent,
  CellContextMenuEvent,
  ColDef,
  FilterChangedEvent,
  GetRowIdFunc,
  GetRowIdParams,
  RefreshCellsParams,
  RowNode,
  RowNodeTransaction,
  SortChangedEvent,
} from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { isNullOrWhiteSpace, randomId } from '@shared/rocket-components/utils';
import { RocketGridService } from './service/grid.service';
import { RocketTThemeGrid } from './types/grid.types';
import {
  GridIOpenConfigGrid,
  GridIReferenceService,
  GridIChangeField,
  GridIColumnVisible,
  GridIColumnVisibleInService,
  GridISelectRow,
} from './interfaces/grid.interface';
import { HomeService } from '@modules/home/service/home.service';
import { hasSortColumn } from 'src/app/utils/utils.functions';
import { ModalOpenService } from '@shared/rocket-components/components/modal/service/modal-open.service';
import { SelectService } from '@shared/rocket-components/components/select/select.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rocket-grid[columnDefs]',
  templateUrl: './rocket-grid.component.html',
})
export class RocketGridComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @ViewChild('grid') agGrid!: AgGridAngular;
  @Input() ignoreRocketService!: boolean;
  @Input() public theme: RocketTThemeGrid = 'ag-theme-alpine-dark';
  @Input() public width: string = '100%';
  @Input() public height: string = '300px';
  @Input() public animateRows: boolean = false;
  @Input() public rowDragManaged: boolean = false;
  @Input() public rowDragEntireRow: boolean = false;
  @Input() public suppressDragLeaveHidesColumns: boolean = false;
  @Input() public resizable: boolean = false;
  @Input() public columnDefs!: ColDef[];
  @Input() public setColumnDefsDefault!: ColDef[];
  @Input() public rowData!: any[];
  @Input() public onUpdateField!: GridIChangeField;
  @Input() public cellFlashDelay: number = 250;
  @Input() public cellFadeDelay: number = 100;
  @Input() public tooltipHideDelay: number = 30000;
  @Input() public addRow!: any;
  @Input() public deleteRow!: any;
  @Input() public addRowPosition: 'start' | 'end' = 'end';
  @Input() public columnVisible!: GridIColumnVisible;
  @Input() public refComponent!: string;
  @Input() public noRowsTemplate: string | undefined =
    'Sem dados para mostrar!';
  @Input() public isConfig: boolean = false;
  @Input() public rowSelection: 'single' | undefined = undefined;
  @Input() public columnShowEdit: string = 'actions';
  @Input() public fieldIndexer: string = 'id';
  @Input() public isDebug: boolean = false;
  @Input() public sortingOrder: ('asc' | 'desc' | null)[] = [null];
  @Input() public pinnedBottomRowData!: any[];
  @Input() public rowDragText!: any;
  @Input() public dropZoneParams!: any;
  @Input() public displayPinnedBottomLine: boolean = false;
  @Input() public globalCdStock: string = '';
  @Input() public sortByField: string = '';
  @Input() public sortByFieldOrder: 'asc' | 'desc' = 'desc';
  @Input() public resizeToFitWidth: number = 0;
  @Input() public suppressMoveWhenRowDragging: boolean = false;
  @Input() public resetFilter: boolean = false;
  @Input() public useDefaultMinWidth: boolean = true;
  @Input() public gridOptions!: any;
  @Input() public rowClassRules: any = {};
  @Input() public rowBuffer = 1;
  @Input() public suppressChangeDetection = false;
  @Input() public aggregateOnlyChangedColumns = false;
  @Output() emitContextMenu: EventEmitter<any> = new EventEmitter<any>();
  @Output() public flaRowMoved: EventEmitter<any> = new EventEmitter<any>();
  @Output() public flaColumnMoved: EventEmitter<any> = new EventEmitter<any>();
  @Output() public flaColumnResized: EventEmitter<{
    colId: string;
    actualWidth: number;
  }> = new EventEmitter<{ colId: string; actualWidth: number }>();
  @Output() public flaSelectRow: EventEmitter<any> = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onGridReady: EventEmitter<any> = new EventEmitter<any>();
  @Output() public columnApiReference: EventEmitter<any> =
    new EventEmitter<any>();
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Output() public updateCallback: EventEmitter<Function> =
    // eslint-disable-next-line @typescript-eslint/ban-types
    new EventEmitter<Function>();
  @Output() public filterIsReseted: EventEmitter<any> = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onHeaderSortChange: EventEmitter<SortChangedEvent> =
    new EventEmitter<SortChangedEvent>();
  @Output() public filterChangedEvent: EventEmitter<FilterChangedEvent> =
    new EventEmitter<FilterChangedEvent>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onBodyScroll: EventEmitter<BodyScrollEvent> =
    new EventEmitter<BodyScrollEvent>();

  onGridReadyHandler = (params: any) => {
    this.agGrid = params;
    this.dropZoneParams && this.agGrid.api.addRowDropZone(this.dropZoneParams);
    this.onGridReady.emit(params.api);
    this.columnApi = params.columnApi;
    this.suppressHeaderFocus = structuredClone(
      this.columnApi.suppressHeaderFocus
    );
    this.suppressCellFocus = structuredClone(this.columnApi.suppressCellFocus);
    this.columnApiReference.emit(params.columnApi);
    this.initializeModalServiceSubscription();
    this.initializeNgSelectSubscription();
  };
  private isFocused = false;
  private timeColumnResized!: any;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private _onChangeColumnDefs = new Subject<ColDef[]>();
  public defaultColDef: ColDef = {
    resizable: true,
  };
  public idGrid: string = randomId('fla_grid');
  public openConfig: boolean = false;
  private _hasDestroyedGrid: boolean = false;
  public rowSelect!: GridISelectRow | null;
  public getRowId: GetRowIdFunc = (params: GetRowIdParams) =>
    params.data[this.fieldIndexer];
  public tooltipShowDelay = 500;
  private isShowEdit = false;
  public domLayout!: 'autoHeight' | 'normal';
  public columnApi!: any;
  private modalOpened$!: Subscription;
  private selectOpened$!: Subscription;
  private suppressHeaderFocus: boolean = false;
  private suppressCellFocus: boolean = false;
  private hasModalOpen: boolean = false;
  private keyArrowEvent = new Subject<{ event: KeyboardEvent; type: string }>();
  constructor(
    private rocketGridService: RocketGridService,
    private homeService: HomeService,
    private modalOpenService: ModalOpenService,
    private selectService: SelectService
  ) {
    this.initializeTotalSubscription();
    this._onChangeColumnDefs
      .pipe(debounceTime(50))
      .subscribe((columns) => this.agGrid.api.setColumnDefs(columns));
    this.keyArrowEventSubscription();
  }
  private selectedNode!: RowNode;

  @HostListener('window:keydown.Enter', ['$event'])
  enter() {
    const selecteds = this.agGrid.api.getSelectedRows();
    if (
      !this.homeService.isSelectedComponent(this.idGrid) ||
      !(selecteds && selecteds.length)
    ) {
      return;
    }
    this.flaSelectRow.emit({ data: selecteds[0], colDef: {} });
  }

  @HostListener('window:keydown.ArrowUp', ['$event'])
  up(event: KeyboardEvent) {
    this.keyArrowEvent.next({ event, type: 'ArrowUp' });
  }

  @HostListener('window:keydown.ArrowDown', ['$event'])
  down(event: KeyboardEvent) {
    this.keyArrowEvent.next({ event, type: 'ArrowDown' });
  }

  private validityNavigation(event: string) {
    if (
      this.selectedNode &&
      !this.isFocused &&
      this.homeService.isSelectedComponent(this.idGrid)
    ) {
      const newIndex =
        event === 'ArrowUp'
          ? this.selectedNode.rowIndex!! - 1
          : this.selectedNode.rowIndex!! + 1;
      const firstCol = this.agGrid.columnApi.getAllDisplayedColumns()[0];
      this.agGrid.api.setFocusedCell(newIndex, firstCol);
      const nextCellPosition = this.agGrid.api.getRenderedNodes()[newIndex];
      if (!nextCellPosition) return;
      this.navigateToNextCell({
        key: event,
        api: this.agGrid.api,
        nextCellPosition,
      });
    }
  }

  private selectFirstNode(event: KeyboardEvent) {
    const selecteds = this.agGrid.api.getSelectedRows();
    const doc = document.querySelector(
      `#${this.idGrid} .ag-body-viewport.ag-layout-normal`
    );
    if (
      !doc ||
      this.selectedNode ||
      !this.homeService.isSelectedComponent(this.idGrid) ||
      (selecteds && selecteds.length)
    ) {
      this.isFocused = false;
      return;
    }
    this.isFocused = true;
    doc.classList.add('overflow-hidden');
    this.selectedNode = this.agGrid.api.getRenderedNodes()[0];
    this.navigateToNextCell({
      api: this.agGrid.api,
      key: event.key,
      nextCellPosition: this.selectedNode,
    });
    const firstCol = this.agGrid.columnApi.getAllDisplayedColumns()[0];
    this.agGrid.api.setFocusedCell(0, firstCol);
    this.agGrid.api.ensureNodeVisible(this.selectedNode);
    doc.scrollTo({ top: 0 });
    setTimeout(() => {
      doc.classList.remove('overflow-hidden');
      doc.scrollTo({ top: 0 });
      if (selecteds.length) {
        this.agGrid.api.ensureIndexVisible(0);
      }
    }, 1);
  }

  navigateToNextCell = (params: any) => {
    if (!this.homeService.isSelectedComponent(this.idGrid)) return;
    this.isFocused = true;
    const suggestedNextCell = params.nextCellPosition!!;
    if (!suggestedNextCell) return;
    const KEY_UP = 'ArrowUp';
    const KEY_DOWN = 'ArrowDown';
    const noUpOrDownKey = params.key !== KEY_DOWN && params.key !== KEY_UP;
    if (noUpOrDownKey) {
      return suggestedNextCell;
    }
    params.api.forEachNode((node: any) => {
      if (node.rowIndex === suggestedNextCell.rowIndex) {
        this.selectedNode = node;
        node.setSelected(true);
      }
    });
    return suggestedNextCell;
  };

  ngOnInit(): void {
    this.domLayout = 'normal';
    this.updateCallback.emit(this.onUpdateValues);
  }

  ngAfterViewInit(): void {
    if (this.isConfig) {
      this.rocketGridService.removeRow
        .pipe(
          takeUntil(this.destroy$),
          filter(
            (event: GridISelectRow) => this.refComponent === event.refComponent
          )
        )
        .subscribe((event) => this.removeRow(event));
      this.agGrid.gridOptions.onCellContextMenu = this.showRowSelect;
      this.showEdit();
      this.rocketGridService.invisibleColumn
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (invisibleColumn: GridIColumnVisibleInService) =>
            invisibleColumn.refComponent === this.refComponent &&
            this.setColumnsVisible({
              keys: invisibleColumn.keys,
              visible: invisibleColumn.visible,
            })
        );
      setTimeout(() => {
        const element = document.getElementById(this.idGrid);
        if (!element) return;
        element!.oncontextmenu = this.showConfig;
      }, 100);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {
      onUpdateField,
      resizable,
      addRow,
      columnVisible,
      deleteRow,
      setColumnDefsDefault,
      resetFilter,
      displayPinnedBottomLine,
      sortByField,
      resizeToFitWidth,
      dropZoneParams,
      noRowsTemplate,
    } = changes;
    onUpdateField?.currentValue &&
      this.agGrid &&
      this.onUpdateValues(onUpdateField.currentValue);
    (resizable?.currentValue || resizable?.currentValue === false) &&
      (this.defaultColDef.resizable = resizable.currentValue);
    addRow?.currentValue && this.agGrid && this.setRow(addRow.currentValue);
    deleteRow?.currentValue &&
      this.agGrid &&
      this.removeRow(deleteRow.currentValue);
    if (columnVisible && columnVisible?.currentValue) {
      this.setColumnsVisible(columnVisible.currentValue);
    }
    setColumnDefsDefault?.currentValue &&
      this.setColumnDefs(setColumnDefsDefault.currentValue);
    resetFilter?.currentValue && this.agGrid && this.restartFilters();
    displayPinnedBottomLine &&
      this.agGrid &&
      this._handlePinnedBottomLine(displayPinnedBottomLine.currentValue);
    sortByField?.currentValue && this.sortingByField(sortByField.currentValue);
    resizeToFitWidth?.currentValue && this.agGrid && this.sizeToFit();
    dropZoneParams?.currentValue &&
      this.agGrid &&
      this.agGrid.api.addRowDropZone(this.dropZoneParams);
    if (noRowsTemplate?.currentValue && this.agGrid) {
      this.agGrid.api.hideOverlay();
      setTimeout(() => {
        this.agGrid.api.showNoRowsOverlay();
      }, 1);
    }
  }

  ngOnDestroy(): void {
    this._hasDestroyedGrid = true;
    this.agGrid.api.destroy();
    this.destroy$.next(true);
    this.destroy$.complete();
    this._onChangeColumnDefs.unsubscribe();
    this.modalOpened$ && this.modalOpened$.unsubscribe();
    this.selectOpened$ && this.selectOpened$.unsubscribe();
    this.keyArrowEvent.unsubscribe();
  }

  triggerBodyScroll(event: BodyScrollEvent) {
    this.onBodyScroll.emit(event);
  }

  private setColumnDefs(columns: ColDef[]): void {
    this._onChangeColumnDefs.next(columns);
  }

  onUpdateValues = (props: GridIChangeField): void => {
    const rowNode = this.agGrid.api.getRowNode(props.idRow)!;
    if (!rowNode) {
      return;
    }
    rowNode.data = {
      ...rowNode.data,
      ...props.propsCell,
    };
    const params: RefreshCellsParams = {
      rowNodes: [rowNode],
      force: true,
    };
    this.agGrid.api.refreshCells(params);
  };

  setRow(data: any, callback?: (res: RowNodeTransaction<any>) => void): void {
    const add = data?.length ? data : [data];
    const transaction = {
      add: add,
      addIndex: this.addRowPosition === 'start' ? 0 : null,
    };
    this.agGrid.api.applyTransactionAsync(transaction, callback);
    this.addRowPosition === 'start'
      ? this.agGrid.rowData?.push(data)
      : this.agGrid.rowData?.unshift(data);
  }

  centralizeRow(rowData: any) {
    this.agGrid.api.ensureNodeVisible(rowData, 'middle');
  }

  setColumnsVisible(data: GridIColumnVisible): void {
    if (this.agGrid)
      this.agGrid.columnApi.setColumnsVisible(data.keys, data.visible);
  }

  private showEdit(): void {
    this.rocketGridService.showEdit
      .pipe(takeUntil(this.destroy$))
      .subscribe((edit: GridIReferenceService) => {
        this.refComponent === edit.refComponent && this.processShowEdit(edit);
      });
  }

  private processShowEdit(edit: GridIReferenceService): void {
    this.isShowEdit = edit.value;
    this.setColumnsVisible({
      keys: [this.columnShowEdit],
      visible: edit.value,
    });
  }

  public removeRow(data: GridISelectRow): void {
    if (data.rowIndex > -1 && this.agGrid.api.getRowNode(data.rowData.idRow)) {
      this.agGrid.api.removeDetailGridInfo(`${data.rowIndex}`);
      this.agGrid.api.applyTransactionAsync({
        remove: [data.rowData],
      });
      this.rocketGridService.setRemoveRow({
        rowData: null,
        rowIndex: -1,
        refComponent: '',
      });
    }
  }

  @HostListener('window:keydown.esc')
  @HostListener('click')
  public onClick(): void {
    if (this.openConfig) {
      this.openConfig = false;
      this.rowSelect = null;
      const config: GridIOpenConfigGrid = {
        event: null,
        refComponent: this.refComponent,
        rowSelect: this.rowSelect,
        isVisible: false,
        keyHeader: null,
        isShowEdit: this.isShowEdit,
      };
      this.rocketGridService.setShowConfig(config);
    }
  }

  private subjectDebounced = new Subject<void>();

  private keyArrowEventSubscription() {
    this.keyArrowEvent
      .pipe(
        filter(() => !this.hasModalOpen),
        delay(200)
      )
      .subscribe((data) => {
        this.validityNavigation(data.type);
        this.selectFirstNode(data.event);
      });
  }

  private initializeTotalSubscription(): void {
    this.subjectDebounced.pipe(debounceTime(133)).subscribe((event: any) => {
      // Executa o que for necessário
      const offsetParent = event.target.offsetParent;
      const keyHeader = offsetParent.className.includes('ag-header-active')
        ? offsetParent?.getAttribute('col-id')
        : null;

      const config: GridIOpenConfigGrid = {
        event: event,
        refComponent: this.refComponent,
        rowSelect: this.rowSelect,
        isVisible: true,
        keyHeader,
        isShowEdit: this.isShowEdit,
        isFiltered: this.agGrid.api.isAnyFilterPresent(),
        isSorted: hasSortColumn(this.columnApi, true) as boolean,
      };

      this.ignoreRocketService
        ? this.emitContextMenu.emit(config)
        : this.rocketGridService.setShowConfig(config);
    });
  }

  private initializeModalServiceSubscription = () => {
    this.modalOpened$ = this.modalOpenService.onContentChange$
      .pipe(auditTime(100))
      .subscribe((open) => {
        this.hasModalOpen = open;
        this.columnApi.suppressHeaderFocus = open ?? this.suppressHeaderFocus;
        this.columnApi.suppressCellFocus = open ?? this.suppressCellFocus;
      });
  };

  private initializeNgSelectSubscription = () => {
    this.selectOpened$ = this.selectService.onEvent
      .pipe(auditTime(100))
      .subscribe((isOpen) => {
        this.hasModalOpen = isOpen.type === 'OPEN';
        this.columnApi.suppressHeaderFocus =
          this.hasModalOpen ?? this.suppressHeaderFocus;
        this.columnApi.suppressCellFocus =
          this.hasModalOpen ?? this.suppressCellFocus;
      });
  };

  public showConfig = (event: any): boolean => {
    this.rowSelect = null;
    this.subjectDebounced.next(event);
    return false;
  };

  public showRowSelect = (event: CellContextMenuEvent<any>) => {
    const data: GridISelectRow = {
      rowData: event.data,
      rowIndex: event.rowIndex!,
      refComponent: this.refComponent,
    };
    this.rowSelect = data;
  };

  public rowMoved(event: any): void {
    this.flaRowMoved.emit({ id: event.node.id, rowIndex: event.node.rowIndex });
  }

  public columnMoved(event: any): void {
    this.flaColumnMoved.emit(event.columnApi.getColumnState());
  }

  public onRowSelected(event: any): void {
    event.event && this.flaSelectRow.emit(event);
  }

  public onColumnResized(event: any): void {
    if (isNullOrWhiteSpace(event.column)) return;
    clearTimeout(this.timeColumnResized);
    this.timeColumnResized = setTimeout(() => {
      this.flaColumnResized.emit({
        colId: event.column.colId,
        actualWidth: event.column.actualWidth,
      });
      clearTimeout(this.timeColumnResized);
    }, 500);
  }

  public restartFilters(): void {
    this.agGrid.api?.getColumnDefs()?.forEach((col: any) => {
      this.agGrid.api?.destroyFilter(col.colId);
    });

    this.filterIsReseted.emit(true);
  }

  clearGridView() {
    this.agGrid.api.setRowData([]);
    this.agGrid.rowData = [];
  }

  private _handlePinnedBottomLine = (displayLine: boolean): void => {
    try {
      displayLine &&
        this.agGrid.api.setPinnedBottomRowData(this.pinnedBottomRowData);
      !displayLine && this.agGrid.api.setPinnedBottomRowData(undefined);
    } catch (error) {
      //do nothing.
    }
  };

  private sortingByField(field: string) {
    setTimeout(() => {
      this.agGrid.columnApi.applyColumnState({
        state: [{ colId: field, sort: this.sortByFieldOrder }],
        defaultState: { sort: null },
      });
    }, 100);
  }

  private sizeToFit = () => {
    this.agGrid.api!.sizeColumnsToFit(
      this.useDefaultMinWidth ? { defaultMinWidth: 100 } : {}
    );
  };

  autoSizeAllColumns() {
    this.agGrid.columnApi.autoSizeAllColumns();
  }

  public onGridSortChange(event: SortChangedEvent): void {
    this.onHeaderSortChange.emit(event);
  }

  public onGridFilterChange(event: FilterChangedEvent): void {
    this.filterChangedEvent.emit(event);
  }
}
