import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { ISearchStock } from '@core/interface';
import {
  ICorporateEvents,
  IHistCorporateEvent,
  IResumeList,
  IYields,
} from '../business-profile.interface';
import { BusinessProfileService } from '../business-profile.service';
import {
  GROUPS,
  PERIODS_PROVENTS_EVOLUTION,
  UNITS,
} from '../business-profile.const';
import { PERIODS_ENUM } from '../business-profile.enum';
import { Dictionary } from '@core/models';
import { XyDataSeries } from 'scichart/Charting/Model/XyDataSeries';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { NumericAxis } from 'scichart/Charting/Visuals/Axis/NumericAxis';
import { ScichartService } from '@shared/tiger-chart/services/scichart.service';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import {
  FLA_GRAPH_COLORS,
  deepClone,
  randomId,
} from '@shared/rocket-components/utils';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  VMDateToDate,
  VMDateToYear,
  dateToVMDate,
  formatByTick,
  formatDate,
} from 'src/app/utils/utils.functions';
import { StackedColumnRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/StackedColumnRenderableSeries';
import { StackedColumnCollection } from 'scichart/Charting/Visuals/RenderableSeries/StackedColumnCollection';
import { CategoryAxis } from 'scichart/Charting/Visuals/Axis/CategoryAxis';
import { DateTimeNumericAxis } from 'scichart/Charting/Visuals/Axis/DateTimeNumericAxis';
import { IProceedsCalendar } from '@shared/services/api/trademap/v1/interface/calendars.interface';
import { TrademapCalendarsService } from '@shared/services/api/trademap/v1/calendars.service';

@Component({
  selector: 'app-provents',
  templateUrl: './provents.component.html',
  styleUrls: ['./provents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProventsComponent implements AfterViewInit, OnDestroy {
  @Input() refComponent!: string;
  @Input() set stockSelected(stockSelected: ISearchStock) {
    if (stockSelected) {
      this.minDate = undefined;
      this.infoChart = {};
      this.started$ && this.started$.unsubscribe();
      this._stockSelected = stockSelected;
      this.getCorporateEvents();
      this.getProceeds();
      this.cdr.detectChanges();
    }
  }
  _stockSelected!: ISearchStock;
  corporateEvents!: ICorporateEvents | undefined;
  corporateEventsToShow: IResumeList[] = [];
  proceeds!: IProceedsCalendar[] | undefined;
  proceedsToShow: IResumeList[] = [];
  periodSelected = PERIODS_ENUM.TEN_YEARS;
  unitSelected = 'TOTAL';
  groupSelected = 'ANUAL';
  units = UNITS;
  periods = PERIODS_PROVENTS_EVOLUTION;
  groups = GROUPS;
  cards!: Dictionary<IYields>;
  baseChart!: TWebAssemblyChart;
  minDate!: number | undefined;
  tabs = [
    { title: 'Histórico', cod: 'HISTORIC', tooltip: '' },
    { title: 'Próximos', cod: 'NEXT', tooltip: 'Data com' },
  ];
  selectedTab = this.tabs[0].cod;
  private _scichartService!: ScichartService;
  public refChart: string = randomId('PROVENTS_EVOLUTION_CHART');
  private xAxis!: CategoryAxis | DateTimeNumericAxis;
  private yAxis!: NumericAxis;
  private start$ = new BehaviorSubject<boolean>(false);
  private started$!: Subscription;
  private maxPreviousYear = 10;
  infoChart: any = {};
  public proceedsNoDataMsg =
    'No momento o ativo não tem agendado pagamento de proventos';
  constructor(
    private cdr: ChangeDetectorRef,
    private businessProfileService: BusinessProfileService,
    private stockChartService: StockChartService,
    private calendarService: TrademapCalendarsService
  ) {
    this._scichartService = new ScichartService(undefined, stockChartService);
  }

  ngAfterViewInit() {
    this.start$.next(true);
  }

  ngOnDestroy() {
    this.started$ && this.started$.unsubscribe();
  }

  getCorporateEvents() {
    this.businessProfileService
      .getCorporateEvents(this._stockSelected, undefined, 10)
      .then((data) => {
        this.corporateEvents = deepClone(data.corporateEvents);
        this.corporateEventsToShow = deepClone(data.corporateEventsToShow);
        const lastEvent = this.corporateEvents!!.hist_corporate_event[0];
        if (
          this._stockSelected.type != 'FII' &&
          this._stockSelected.id_exchange != 7777
        ) {
          this.corporateEvents!!.hist_profit.forEach((element) => {
            this.corporateEvents!!.hist_corporate_event.push({
              ds_corporative_event_type: 'Lucro',
              dt_event: element.dt_entry,
              total_paid: element.vl_profit,
            });
          });
        }
        this.buildInfo();
        if (this._stockSelected.type == 'FII') {
          this.getFIISummary(lastEvent);
        } else {
          this.getSummary();
        }
        this.started$ = this.start$.subscribe((data) => {
          if (data) {
            if (!this.baseChart || this.baseChart.sciChartSurface.isDeleted) {
              this.buildChart();
            } else {
              this.changeChart();
            }
          }
        });
        this.cdr.detectChanges();
      })
      .catch(() => {
        this.corporateEvents = undefined;
        this.corporateEventsToShow = [];
        this.baseChart?.sciChartSurface.delete();
        this.cdr.detectChanges();
      });
  }

  selectPeriod(period: any) {
    this.periodSelected = period.cod;
    const max = period.title === '12M' ? 1 : parseInt(period.title);
    const today = new Date();
    today.setFullYear(today.getFullYear() - max);
    this.maxPreviousYear = max;
    this.minDate = parseInt(dateToVMDate(today));
    this.changeChart();
  }

  selectUnit(unit: any) {
    if (unit.cod === this.unitSelected) return;
    this.unitSelected = unit.cod;
    this.units.map((item) => {
      item.active = unit.cod === item.cod;
      return item;
    });
    this.baseChart.sciChartSurface.yAxes.clear();
    this.setYAxisUnit();
    this.changeChart();
  }

  selectGroup(group: any) {
    if (this.groupSelected === group.cod) return;
    this.groupSelected = group.cod;
    this.groups.map((item) => {
      item.active = group.cod === item.cod;
      return item;
    });
    this.baseChart.sciChartSurface.xAxes.clear();
    this.setXAxisGroup();
    this.changeChart();
  }

  setYAxisUnit = () => {
    if (this.unitSelected === 'TOTAL') {
      this.yAxis = this.businessProfileService.buildChartYAxis(
        this.baseChart,
        this.businessProfileService.getVolumeText
      );
    } else {
      const preffix = this._stockSelected.cd_country === 'BR' ? 'R$' : '$';
      this.yAxis = this.businessProfileService.buildChartYAxis(
        this.baseChart,
        (value: any) => `${preffix} ${formatByTick(value, 3)}`
      );
    }
    this.cdr.detectChanges();
    this.baseChart.sciChartSurface.yAxes.add(this.yAxis);
  };

  setXAxisGroup = () => {
    if (this.groupSelected === 'ANUAL') {
      this.xAxis = this.businessProfileService.buildChartXAxis(
        this.baseChart,
        'CATEGORY',
        VMDateToYear
      );
    } else {
      this.xAxis = this.businessProfileService.buildChartXAxis(
        this.baseChart,
        'NUMERIC'
      );
    }
    this.cdr.detectChanges();
    this.baseChart.sciChartSurface.xAxes.add(this.xAxis);
  };

  getFIISummary(lastEvent: IHistCorporateEvent) {
    this.cards = this.businessProfileService.getFIISummary(
      this.corporateEvents!!,
      lastEvent
    );
    this.cdr.detectChanges();
  }

  getSummary() {
    this.cards = this.businessProfileService.getSummary(
      this.corporateEvents!!,
      this._stockSelected
    );
    this.cdr.detectChanges();
  }

  private buildChart() {
    this._scichartService.initSciChart(this.refChart).then((baseChart) => {
      this.baseChart = baseChart;
      this.baseChart.sciChartSurface.chartModifiers.add(
        this.businessProfileService.buildTooltip(
          200,
          50,
          `provents-tooltip-div-id_${this.refChart}`
        )
      );
      this.setYAxisUnit();
      this.baseChart.sciChartSurface.chartModifiers.add(
        this.businessProfileService.buildLegend(
          `provents-legend-div-id_${this.refChart}`
        )
      );
      this.setXAxisGroup();
      this.buildInfoChart();
      this.stockChartService.removeChartLoading$.next({
        remove: true,
        ref: this.refChart,
      });
    });
  }

  private buildInfoChart() {
    const infos =
      this.infoChart[`${this.maxPreviousYear}`][this.unitSelected][
        this.groupSelected
      ];
    const stacked = new StackedColumnCollection(this.baseChart.wasmContext);
    Object.keys(infos).forEach((key, i) => {
      const tmColor = FLA_GRAPH_COLORS['theme-trademap'][i];
      const randomColor =
        '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');
      const color = tmColor ? tmColor : randomColor;
      const stackedGroupId =
        key === 'Lucro' ? `StackedGroupId-Second` : 'StackedGroupId';
      const xValues = Object.values(infos[key].xValues) as number[],
        yValues = Object.values(infos[key].yValues) as number[];
      const rendSeries = new StackedColumnRenderableSeries(
        this.baseChart.wasmContext,
        {
          dataSeries: new XyDataSeries(this.baseChart.wasmContext, {
            xValues,
            yValues,
            dataSeriesName: key,
            id: key,
            dataIsSortedInX: false,
          }),
          fill: color,
          strokeThickness: 0,
          stroke: color,
          stackedGroupId,
        }
      );
      stacked.add(rendSeries);
    });
    this.baseChart.sciChartSurface.renderableSeries.add(stacked);
  }

  private instanceInfo() {
    this.createInfo('Direitos');
    this.createInfo('Dividendos');
    this.createInfo('JCP');
    this.createInfo('Lucro');
    this.createInfo('Rendimento');
  }

  private createByYear(dsCorporativeEventType: string, timer: number) {
    if (!this.infoChart[`${timer}`]) {
      this.infoChart[`${timer}`] = {
        TOTAL: {
          ANUAL: {},
          DIARIO: {},
        },
        UNIT: {
          ANUAL: {},
          DIARIO: {},
        },
      };
    }
    let totalDaily =
      this.infoChart[`${timer}`].TOTAL.DIARIO[dsCorporativeEventType];
    let totalAnual =
      this.infoChart[`${timer}`].TOTAL.ANUAL[dsCorporativeEventType];
    let unitDaily =
      this.infoChart[`${timer}`].UNIT.DIARIO[dsCorporativeEventType];
    let unitAnual =
      this.infoChart[`${timer}`].UNIT.ANUAL[dsCorporativeEventType];
    if (!totalDaily) {
      totalDaily = {
        yValues: {},
        xValues: {},
      };
    }
    if (!totalAnual) {
      totalAnual = {
        yValues: {},
        xValues: {},
      };
    }
    if (!unitDaily) {
      unitDaily = {
        yValues: {},
        xValues: {},
      };
    }
    if (!unitAnual) {
      unitAnual = {
        yValues: {},
        xValues: {},
      };
    }
    this.infoChart[`${timer}`].TOTAL.DIARIO = {
      ...this.infoChart[`${timer}`].TOTAL.DIARIO,
      [dsCorporativeEventType]: totalDaily,
    };
    this.infoChart[`${timer}`].TOTAL.ANUAL = {
      ...this.infoChart[`${timer}`].TOTAL.ANUAL,
      [dsCorporativeEventType]: totalAnual,
    };
    this.infoChart[`${timer}`].UNIT.DIARIO = {
      ...this.infoChart[`${timer}`].UNIT.DIARIO,
      [dsCorporativeEventType]: unitDaily,
    };
    this.infoChart[`${timer}`].UNIT.ANUAL = {
      ...this.infoChart[`${timer}`].UNIT.ANUAL,
      [dsCorporativeEventType]: unitAnual,
    };
    this.cdr.detectChanges();
  }

  private createInfo(dsCorporativeEventType: string) {
    this.createByYear(dsCorporativeEventType, 10);
    this.createByYear(dsCorporativeEventType, 5);
    this.createByYear(dsCorporativeEventType, 2);
    this.createByYear(dsCorporativeEventType, 1);
  }

  private buildInfoByDate(element: IHistCorporateEvent, qtty: number) {
    const today = new Date();
    today.setFullYear(today.getFullYear() - qtty);
    const t = parseInt(dateToVMDate(today));
    if (element.dt_event < t) return;
    const dsCorporativeEventType = element.ds_corporative_event_type;
    const totalDaily =
      this.infoChart[`${qtty}`].TOTAL.DIARIO[dsCorporativeEventType];
    const totalAnual =
      this.infoChart[`${qtty}`].TOTAL.ANUAL[dsCorporativeEventType];
    const unitDaily =
      this.infoChart[`${qtty}`].UNIT.DIARIO[dsCorporativeEventType];
    const unitAnual =
      this.infoChart[`${qtty}`].UNIT.ANUAL[dsCorporativeEventType];
    let aux = true;
    if (dsCorporativeEventType == 'Lucro') {
      aux = false;
    }
    const date = VMDateToDate(element.dt_event);
    const key = formatDate(element.dt_event);
    if (aux) {
      const totalXValues = totalDaily.xValues;
      const unitXValues = unitDaily.xValues;
      if (!totalXValues[key]) {
        totalXValues[key] = date.getTime();
      }
      if (!unitXValues[key]) {
        unitXValues[key] = date.getTime();
      }
      const totalPrice = element.total_paid || 0;
      const unitPrice = element.provent_price_ajust;
      totalDaily.yValues[key] = totalPrice;
      unitDaily.yValues[key] = unitPrice;
    }
    const dateToString = date.getFullYear().toString();
    const totalXValues = totalAnual.xValues;
    const unitXValues = unitAnual.xValues;
    if (!totalXValues[dateToString]) {
      totalXValues[dateToString] = element.dt_event;
    }
    if (!unitXValues[dateToString]) {
      unitXValues[dateToString] = element.dt_event;
    }
    let totalSum = totalAnual.yValues[dateToString] || 0;
    let unitSum = unitAnual.yValues[dateToString] || 0;
    const totalPrice = element.total_paid;
    const unitPrice = element.provent_price_ajust;
    totalSum += totalPrice!!;
    unitSum += unitPrice!!;
    totalAnual.yValues[dateToString] = totalSum;
    unitAnual.yValues[dateToString] = unitSum;
    this.infoChart[`${qtty}`].TOTAL.DIARIO = {
      ...this.update(`${qtty}`, 'TOTAL', 'DIARIO', key, date.getTime()),
      [dsCorporativeEventType]: totalDaily,
    };
    this.infoChart[`${qtty}`].TOTAL.ANUAL = {
      ...this.update(
        `${qtty}`,
        'TOTAL',
        'ANUAL',
        dateToString,
        element.dt_event
      ),
      [dsCorporativeEventType]: totalAnual,
    };
    this.infoChart[`${qtty}`].UNIT.DIARIO = {
      ...this.update(`${qtty}`, 'UNIT', 'DIARIO', key, date.getTime()),
      [dsCorporativeEventType]: unitDaily,
    };
    this.infoChart[`${qtty}`].UNIT.ANUAL = {
      ...this.update(
        `${qtty}`,
        'UNIT',
        'ANUAL',
        dateToString,
        element.dt_event
      ),
      [dsCorporativeEventType]: unitAnual,
    };
  }

  private buildInfo() {
    this.instanceInfo();
    this.corporateEvents!!.hist_corporate_event.forEach((element) => {
      this.buildInfoByDate(element, 10);
      this.buildInfoByDate(element, 5);
      this.buildInfoByDate(element, 2);
      this.buildInfoByDate(element, 1);
    });
    this.cdr.detectChanges();
  }

  private update(
    yearTime: string,
    unit: 'TOTAL' | 'UNIT',
    type: 'DIARIO' | 'ANUAL',
    key: string,
    value: number
  ) {
    const h: any = {};
    ['Direitos', 'Dividendos', 'JCP', 'Lucro', 'Rendimento'].forEach((k) => {
      const hash = this.infoChart[yearTime][unit][type][k];
      if (!hash.xValues[key]) {
        hash.xValues[key] = value;
        hash.yValues[key] = 0;
      }
      h[k] = hash;
    });
    return h;
  }

  private changeChart() {
    this.baseChart.sciChartSurface.renderableSeries.clear();
    this.buildInfoChart();
  }

  private getProceeds = () => {
    this.calendarService
      .getProceeds(
        false,
        12,
        'next',
        this._stockSelected.cd_stock,
        this._stockSelected.id_exchange.toString()
      )
      .subscribe((res) => {
        this.proceeds = res;
        this.proceedsToShow = this.businessProfileService.formatProceeds(
          this.proceeds
        ) as IResumeList[];
        this.cdr.detectChanges();
      });
  };

  public changeTab(tab: any) {
    this.selectedTab = tab.cod;
  }
}
