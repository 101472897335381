import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  OPTIONS_STRATEGY_ANSWERS,
  TOptionsStrateyAnswer,
} from '@shared/components/options/types';

@Component({
  selector: 'options-strategy-modal-answers',
  templateUrl: './options-strategy-modal-answers.component.html',
  styleUrls: ['./options-strategy-modal-answers.component.scss'],
})
export class OptionsStrategyModalAnswersComponent implements OnInit {
  @Input() question!: string;
  @Input() answer!: TOptionsStrateyAnswer;
  @Output() onChange: EventEmitter<TOptionsStrateyAnswer> = new EventEmitter();

  answers = OPTIONS_STRATEGY_ANSWERS;

  constructor() {}

  ngOnInit() {}

  toggleValue(value: TOptionsStrateyAnswer) {
    this.onChange.emit(value);
  }
}
