import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlaIconModule } from '@shared/rocket-components/components';
import { PasswordValidationComponent } from './components/password-validation/password-validation.component';
import { ErrorCardComponent } from './components/error-card/error-card.component';

@NgModule({
  imports: [CommonModule, FlaIconModule],
  declarations: [PasswordValidationComponent, ErrorCardComponent],
  exports: [PasswordValidationComponent, ErrorCardComponent],
})
export class FormValidatorsModule {}
