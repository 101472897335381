import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { INewsDetails } from '@core/interface/news-agency.interface';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { TrademapNewsService } from '@shared/services/api/trademap/V8/news.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-news-modal',
  templateUrl: './news-modal.component.html',
  styleUrls: ['./news-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsModalComponent extends RocketModalRef implements OnInit {
  public newsletter!: INewsDetails;
  public errorOnLoadNews: boolean = false;
  private close$ = new Subject<void>();

  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: any,
    rocketModalService: RocketModalService,
    private _trademapNewsService: TrademapNewsService,
    private cdr: ChangeDetectorRef
  ) {
    super(rocketModalService);
    this.close$.pipe(debounceTime(300)).subscribe(() => this.close());
  }

  ngOnInit() {
    this.getNewsDetail(this.data.event);
  }

  getNewsDetail(data: any) {
    const id_news = data.data?.id_news || data.nu_noticia;
    this._trademapNewsService
      .getNewsDetail(id_news, data.id_news_agency, data.agency_type)
      .subscribe({
        next: (response) => {
          if (response.type === 'news') {
            open(response.data.news_url, '_blank');
            this.close$.next();
            return;
          }
          this.errorOnLoadNews = false;
          this.newsletter = response;
          this.cdr.detectChanges();
        },
        error: () => {
          this.errorOnLoadNews = true;
          this.cdr.detectChanges();
        },
      });
  }
}
