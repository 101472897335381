import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTitleCase]',
})
export class TitleCaseDirective {
  constructor(private el: ElementRef) {}
  ngOnInit(): void {
    console.log('oninit: ', this.el);
  }

  @HostListener('blur') onBlur() {
    console.log('blur');

    if (this.el.nativeElement.value) {
      const arr: string[] = this.el.nativeElement.value.split('');
      arr[0] = arr[0].toUpperCase();
      this.el.nativeElement.value = arr.join('');
    }
  }
}
