<workspace-doc-header css="w-100 header-wrapper d-flex" [item]="component">
  <app-search-stock
    #searchStock
    [resetInput]="false"
    [ref]="refComponent"
    [headerOptions]="headerOptions"
    [initStock]="stockSelected"
    [typesOut]="typesOut"
    (selectStockChange)="selectStock($event)"
  ></app-search-stock>
  <fla-workspace-doc-header-item
    [idElement]="SUPER_DOM_ELEMENT_ID.EXCHANGE_BALANCE"
    [enableContent]="true"
    [hideBorder]="true"
    alignItem="ml-auto"
  >
    <div
      class="fs-6 mx-3 mb-0 fw-bold py-2 border-right mr-0 pr-3"
      tooltip="Saldo em conta"
      tooltipPosition="bottom"
    >
      {{ _balance }}
    </div>
  </fla-workspace-doc-header-item>
</workspace-doc-header>
