import { TIGER_INDICATORS_MENU_ENUM } from '../../enum/tiger-chart.enum';
import MenuItemBase from './menu-item-base';

export default class MoveToPanelBelow extends MenuItemBase {
  constructor() {
    super();
    this.label = 'Painel abaixo';
    this.id = TIGER_INDICATORS_MENU_ENUM.MOVE_EXISTING_PANEL_BELOW;
  }

  override onCLick(): void {}
}
