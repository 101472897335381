import { INFINITY_READ_STREAM } from '@shared/constants/general.contant';

export abstract class ReadStreamBase {
  protected keepReading = INFINITY_READ_STREAM;
  protected async readStream(
    stream: ReadableStream,
    callback: (data: any) => void
  ) {
    const reader = stream.getReader();
    let ret;
    do {
      ret = await reader.read();
      if (!ret.done) {
        callback(ret.value.data);
      }
    } while (this.keepReading);
  }

  protected clearCheetahData(data: any) {
    const updatedData: any = {};
    Object.keys(data).forEach((key: string) => {
      if (!data[key]) return;
      updatedData[key] = data[key];
    });
    return updatedData;
  }
}
