import { Component, Input } from '@angular/core';
import { TTooltipData } from '@shared/components/stock-analysis/types';

@Component({
  selector: 'stock-analysis-tooltip',
  templateUrl: './stock-analysis-tooltip.component.html',
  styleUrls: ['./stock-analysis-tooltip.component.scss'],
})
export class StockAnalysisTooltip {
  @Input() top = 0;
  @Input() left = 0;
  @Input() visible = false;
  @Input() data: TTooltipData = {
    stock: '',
    profitability: '',
    volatility: '',
    variation: '',
  };

  constructor() {}
}
