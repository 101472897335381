import { Injectable } from '@angular/core';
import { AUTH_SESSION_KEYS } from '@shared/services/core/const/auth_util.const';
import { BrowserStorageBase } from '@shared/services/core/storage/browser-storage-base';
import {
  formatByTick,
  getCodeByResp,
  isInvalidActiveToken,
  isNullOrUndefined,
  isTokenInvalid,
} from 'src/app/utils/utils.functions';
import { BehaviorSubject, Observable, Subject, delay, of } from 'rxjs';
import { RocketModalService } from '@shared/rocket-components/components/index';
import { ModalOrderTokenComponent } from './modal-order-token.component';
import { isSimulatorOrLeague } from '@shared/constants/simulator-league.constant';
import { TYPE_ORDE_ENUM } from '@shared/components/stock-trade/enum/stock-trade.enum';
import { ModalTokenConfigComponent } from '@core/layout/header/modal-token-config/modal-token-config.component';
import { ISearchStock } from '@core/interface';
import { ModalAvisoComponent } from '@shared/components/modal-aviso/modal-aviso.component';
import {
  DEFAULT_TIMEENFORCE_ORDERTYPE,
  ORDER_AUCTION_TYPE,
} from '@shared/constants/general.contant';
import { system } from '@core/system/system.service';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { OtpService } from '@shared/services/api/authentication/v3/otp.service';
import { sharedSessionStorage } from '@shared/services/core/storage/shared-session.storage';

@Injectable({
  providedIn: 'root',
})
export class OrderTokenService {
  private storage = new BrowserStorageBase(sharedSessionStorage);
  private _hasSessionTradingToken$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(!isNullOrUndefined(this.tradingToken));
  private _closedModalToken$: Subject<{
    refComponent?: string;
    ordType: TYPE_ORDE_ENUM;
    gainOrderPrice?: number;
    lossOrderPrice?: number;
  }> = new Subject<{
    refComponent?: string;
    ordType: TYPE_ORDE_ENUM;
    gainOrderPrice?: number;
    lossOrderPrice?: number;
  }>();
  private _openAlertModal$ = new Subject<{
    order: {
      refComponent?: string;
      ordType: TYPE_ORDE_ENUM;
      gainOrderPrice?: number;
      lossOrderPrice?: number;
      side: number;
      stock: ISearchStock;
      price: number;
      qtd: number;
      validity: string;
      date: string;
    };
    callbackFunction: () => void;
    stock: ISearchStock;
  }>();
  private _showModalCount!: number;

  constructor(
    private _modalService: RocketModalService,
    private _customPreferencesService: CustomPreferencesService,
    private _otpService: OtpService
  ) {
    this._openAlertModal$.pipe(delay(200)).subscribe((info) => {
      const { order, callbackFunction, stock } = info;
      this.openAlertModal(order, stock).subscribe((data) => {
        if (data && data.value) {
          callbackFunction();
        }
      });
    });
  }

  set tradingToken(token: string) {
    this.storage.setItem(AUTH_SESSION_KEYS.TRADING_TOKEN, token);
    this._hasSessionTradingToken$.next(true);
  }

  get tradingToken(): string {
    return this.storage.getItem(AUTH_SESSION_KEYS.TRADING_TOKEN);
  }

  get onChangeSessionToken(): Observable<boolean> {
    return this._hasSessionTradingToken$.asObservable();
  }

  get closedModalToken(): Observable<{
    refComponent?: string;
    ordType: TYPE_ORDE_ENUM;
    gainOrderPrice?: number;
    lossOrderPrice?: number;
  }> {
    return this._closedModalToken$.asObservable();
  }

  get hasSessionToken(): boolean {
    return !isNullOrUndefined(this.tradingToken);
  }

  removeTradingToken() {
    this.storage.removeItem(AUTH_SESSION_KEYS.TRADING_TOKEN);
    this._hasSessionTradingToken$.next(false);
  }

  public openTokenModal(
    hasToken: boolean,
    isFirstOrder: boolean,
    isHeader: boolean = false
  ): Observable<any> {
    if (system.authenticationMethod === 'TRADEMAP') {
      this._modalService.open(ModalTokenConfigComponent, {
        size: 'lg',
        centered: true,
        backdrop: false,
        keyboard: false,
        scrollable: false,
        notClosable: true,
        data: { shouldDisplayTMAlert: true },
      });
      return of(false);
    }

    const ref = this._modalService.open(ModalOrderTokenComponent, {
      css: 'modal-sized',
      centered: true,
      backdrop: true,
      scrollable: false,
      keyboard: true,
      data: { hasToken, isFirstOrder, isHeader },
    });
    return ref.afterDismissed;
  }

  public hasTradingToken(idBroker: number): {
    hasTrading: boolean;
    tradingToken: string;
  } {
    const isSimOrLg = isSimulatorOrLeague(idBroker);
    if (isSimOrLg) return { hasTrading: true, tradingToken: '' };

    if (!isNullOrUndefined(this.tradingToken)) {
      return { hasTrading: true, tradingToken: this.tradingToken };
    }
    return { hasTrading: false, tradingToken: '' };
  }

  showTokenModal(
    callbackFunction: () => void,
    data?: {
      refComponent?: string;
      ordType: TYPE_ORDE_ENUM;
      gainOrderPrice?: number;
      lossOrderPrice?: number;
      side: number;
      stock: ISearchStock;
      price: number;
      qtd: number;
      validity: string;
      date: string;
    }
  ) {
    const callback = (modalDismissed: boolean = false) => {
      if (this.tradingToken) {
        callbackFunction();
        return;
      }
      if (modalDismissed) {
        if (data && data.refComponent) {
          this._closedModalToken$.next(data);
        }
        return;
      }
      this.openTokenModal(false, true).subscribe((params: any) => {
        if (params.confirm) {
          if (data) {
            this._openAlertModal$.next({
              order: data,
              callbackFunction,
              stock: data.stock,
            });
          } else {
            callbackFunction();
          }
        } else if (data && data.refComponent) {
          this._closedModalToken$.next(data);
        }
      });
    };
    this._otpService.userTokenPreferences().subscribe((response: any) => {
      if (response === 'UNDEFINED_TOKEN') {
        this._modalService.open(ModalTokenConfigComponent, {
          size: 'lg',
          centered: true,
          backdrop: true,
          keyboard: true,
          scrollable: false,
          data: { callback },
        });
        return;
      }
      callback();
    });
  }

  isInvalidTradingToken(res: any) {
    const code = getCodeByResp(res);
    if (isInvalidActiveToken(code) || isTokenInvalid(code)) {
      this.removeTradingToken();
    }
  }

  private openAlertModal(
    data: {
      refComponent?: string;
      ordType: TYPE_ORDE_ENUM;
      gainOrderPrice?: number;
      lossOrderPrice?: number;
      side: number;
      stock: ISearchStock;
      price: number;
      qtd: number;
      validity: string;
      date: string;
    },
    stock: ISearchStock
  ) {
    const validities: any = {
      '0': 'Dia',
      'Até o vencimento': data.date,
      '6': data.date,
      '1': 'VAC',
      'Até cancelar': 'VAC',
      '3': 'Executa ou Cancela',
      '4': 'Tudo ou Nada',
    };
    const validity = validities[data.validity] ?? '';
    const side = data.side === 1 ? 'Compra' : 'Venda';
    const type = `${data.validity}${data.ordType}`.toUpperCase();
    const orderTypeObj: any = {
      ...DEFAULT_TIMEENFORCE_ORDERTYPE,
      ...ORDER_AUCTION_TYPE,
      '00': 'Limitada',
    };
    const orderTypeSelected = orderTypeObj[type] ?? '-';
    const isMarket = orderTypeSelected === DEFAULT_TIMEENFORCE_ORDERTYPE['11'];
    const price = !isMarket
      ? `<li><span class="fw-bold">Preço</span>: ${formatByTick(
          data.price,
          data.stock.tick_size_denominator
        )}</li>`
      : '';
    const ref = this._modalService.open(ModalAvisoComponent, {
      centered: true,
      backdrop: true,
      keyboard: true,
      scrollable: false,
      data: {
        title: 'Ainda deseja enviar sua ordem?',
        text: `
                <div class="d-flex flex-column">
                  <span>Veja abaixo os parâmetros da sua ordem:</span>
                  <ul>
                    <li><span class="fw-bold">Lado</span>: ${side}</li>
                    <li><span class="fw-bold">Ativo</span>: ${
                      data.stock.cd_stock_order
                    }</li>
                    <li><span class="fw-bold">Tipo</span>: ${orderTypeSelected}</li>
                    ${price}
                    <li><span class="fw-bold">Qtd.</span>: ${formatByTick(
                      data.qtd,
                      0
                    )}</li>
                    <li><span class="fw-bold">Validade</span>: ${validity}</li>
                  </ul>
                </div>
              `,
        confirmeButton: 'Sim, enviar ordem',
        isSecondModal: false,
        showButtons: true,
        cancelButton: 'Cancelar',
        stock,
        showPriceStock: true,
      },
    });
    return ref.afterDismissed;
  }
}
