<div #listWrapper class="w-100 option-grid bg-neutral-strongest">
  <ng-container *ngIf="loading || !ready">
    <div
      class="align-items-sm-center bg-neutral-strongest d-flex justify-content-center position-absolute w-100 grid-loading"
    >
      <app-loading></app-loading>
    </div>
  </ng-container>
  <ng-container *ngIf="refId">
    <rocket-grid
      #grid
      height="100%"
      [refComponent]="refId"
      [columnDefs]="columnDefs"
      [setColumnDefsDefault]="columnDefs"
      [columnVisible]="columnVisible"
      [rowData]="rowData"
      fieldIndexer="cd_stock"
      (onGridReady)="onGridReady($event)"
      (flaColumnMoved)="onColumnMoved($event)"
      (flaColumnResized)="onColumnResized($event)"
    ></rocket-grid>
  </ng-container>
</div>
