import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import {
  IOrders,
  IWorkSpaceComponet,
  IWorkSpaceDocLayout,
} from 'src/app/core/interface';
import { isWebViewContext } from 'src/app/desktop/integration.service';

@Injectable({
  providedIn: 'root',
})
export class WorkSpaceConfigs {
  workSpaceBase!: HTMLElement;
  private _element: HTMLElement[] = [];
  private _doc = inject(DOCUMENT);
  private _updateComponentsInWS$ = new Subject<void>();
  private _openStockTrade$ = new BehaviorSubject<{
    order: Partial<IOrders> | null;
    isEditMode: boolean;
  } | null>(null);
  private _removeComponent$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  private _sizeChanges$ = new ReplaySubject<{
    element: HTMLElement;
    isMaximized: boolean;
  }>(10);
  private _maximizedComponent = new Subject<{ ref: string, isMaximized: boolean }>();
  private isWebViewContext: boolean = isWebViewContext();

  get maximizedComponent() {
    return this._maximizedComponent.asObservable()
  }

  set maximizedComponent(param: any) {
    this._maximizedComponent.next(param)
  }

  get sizeChanges() {
    return this._sizeChanges$.asObservable();
  }

  get onUpdateComponentsInWS() {
    return this._updateComponentsInWS$.asObservable();
  }

  updateSizes(options: IWorkSpaceComponet[], isMaximized: boolean) {
    if (!this.isWebViewContext) {
      this.doUpdateSizes(options, isMaximized);
    }
  }

  private doUpdateSizes(options: IWorkSpaceComponet[], isMaximized: boolean) {
    this._element = [];
    options.forEach((item) => {
      const elem = this._getElementsRef(item.id);
      this._updateStylesElem(elem, item.metadata.layout, isMaximized);
    });
  }

  updateIndex(options: IWorkSpaceComponet[]) {
    if (!this.isWebViewContext) {
      this.doUpdateIndex(options);
    }
  }

  private doUpdateIndex(options: IWorkSpaceComponet[]) {
    this._element = [];
    options.forEach((item) => {
      const elem = this._getElementsRef(item.id);
      elem.style.zIndex = item.metadata.layout.index!;
    });
  }

  updateComponentsInWS() {
    this._updateComponentsInWS$.next();
  }

  private _getElementsRef(element: string) {
    const elem = this._doc.getElementById(element) as HTMLElement;
    this._element.push(elem);
    return elem;
  }

  private _updateStylesElem(
    element: HTMLElement,
    styles: Partial<IWorkSpaceDocLayout>,
    isMaximized: boolean
  ) {
    element.style.width = styles.width;
    element.style.height = styles.height;
    element.style.top = this.valueOrEmpty(styles.top);
    element.style.left = this.valueOrEmpty(styles.left);
    element.style.bottom = this.valueOrEmpty(styles.bottom);
    element.style.right = this.valueOrEmpty(styles.right);
    element.style.zIndex = this.valueOrEmpty(styles.index);
    element.style.transform = `translate3d(${styles.transform?.x ?? 0}px, ${styles.transform?.y ?? 0
      }px, 0px)`;

    this._sizeChanges$.next({
      element: element as HTMLElement,
      isMaximized,
    });
  }

  private valueOrEmpty(value: string | undefined): string {
    return value !== undefined ? value : '';
  }

  public removeComponent(key: string | null): void {
    this._removeComponent$.next(key);
  }

  public asObservableRemoveComponent(): Observable<string | null> {
    return this._removeComponent$.asObservable();
  }
  set openStockTrade(
    value: { order: Partial<IOrders> | null; isEditMode: boolean } | null
  ) {
    this._openStockTrade$.next(value);
  }

  get openStockTrade() {
    return this._openStockTrade$.value;
  }

  get openStockTrade$(): Observable<{
    order: Partial<IOrders> | null;
    isEditMode: boolean;
  } | null> {
    return this._openStockTrade$.asObservable();
  }
}
