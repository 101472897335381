<section
  *ngIf="warningMonitor"
  class="h-100 d-flex align-items-center justify-content-center"
  (click)="imHere()"
>
  <div class="card">
    Você ainda está por aqui?
    <rocket-button
      label="Sim, estou aqui!"
      (rocketClick)="imHere()"
    ></rocket-button>
  </div>
</section>
