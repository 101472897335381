<rocket-modal>
  <rocket-modal-header>
    <h3>Aviso</h3>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <h4 class="mb-4">{{ message }}</h4>
    <ng-container *ngIf="!data.dontAskAgain">
      <div>
        <app-check
          label="Não perguntar novamente"
          (flaChange)="dontAskAgain = !dontAskAgain"
        ></app-check>
      </div>
    </ng-container>
  </app-rocket-modal-body>
  <rocket-modal-footer>
    <rocket-button
      label="Cancelar"
      type="fill"
      css="mr-2"
      (rocketClick)="closeModal()"
    ></rocket-button>
    <rocket-button
      label="Confirmar"
      (rocketClick)="cancelOrder()"
    ></rocket-button>
  </rocket-modal-footer>
</rocket-modal>
