export const COLORS_BROKER = [
  '#FF6784',
  '#9C77FF',
  '#FFB484',
  '#ACACFF',
  '#FF8B84',
  '#D39CFF',
  '#FFAC84',
  '#E5A5FF',
  '#FFCC84',
  '#C8B2FF',
  '#FFE884',
  '#AFAFFF',
  '#FFF084',
  '#8A9CFF',
  '#FF9678',
  '#7C84FF',
  '#FF7E78',
  '#8484FF',
  '#FFA378',
  '#969CFF',
  '#FFD478',
  '#5E78FF',
  '#FF6F78',
  '#7474FF',
  '#FF916C',
  '#4C69FF',
  '#FF526C',
  '#6664FF',
  '#FF754C',
  '#3C54FF',
  '#FF3D4C',
  '#5C3AFF',
  '#FF634C',
  '#3A46FF',
  '#FF4B4C',
  '#3833FF',
  '#FF543C',
  '#4230FF',
  '#FF5D2C',
  '#3122FF',
  '#FF663C',
  '#2710FF',
  '#FF6C2C',
  '#2406FF',
  '#FF763C',
  '#2301FF',
  '#FF7D2C',
  '#0A00FF',
  '#FF872C',
  '#0A00FF',
  '#FF8E1C',
  '#FFFFFF',
];
