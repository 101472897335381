import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  IAuctionCommand,
  IGlobalStock,
  IMoversData,
  IMoversTransaction,
  IRowData,
  IStockListItemsRow,
} from '../stock-table/interfaces/stock-table.interfaces';
import { Dictionary } from '@core/models';
import { QuoteData } from '@shared/channel/interface/quote.channel.interface';
import {
  formatterNumber,
  isNullOrUndefined,
  isNullOrUndefinedOrEmpty,
} from 'src/app/utils/utils.functions';
import {
  SORT_MOVERS_ROWS,
  isAuctionList,
  isMoversList,
  isPresetListID,
} from '../stock-table/constants/stock-table.constants';
import { Subject, auditTime, debounceTime, filter, takeUntil, tap } from 'rxjs';
import { deepClone } from '@shared/rocket-components/utils';
import { StockTableViewContext } from './stock-table-views.helper';
import { RocketCreateComponentService } from '@shared/rocket-components/services';
import { StockTableViewsService } from './service/stock-table-views.service';
import { IosmaChannel } from '@shared/channel/iosma.channel';
import {
  CONFIG_ACTIONS,
  IIosmaStockInfo,
  ITraderViewOrder,
  STOCK_TABLE_VIEW,
  findContentSizes,
} from './index';
import { RocketGridService } from '@shared/rocket-grid';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { isAuction } from '@shared/constants/general.contant';
import { IMoversPeriod } from '../stock-table/interfaces/movers-period.interface';
import { DragService } from '@shared/rocket-components/services/ag-grid/drag.service';
import {
  IKeyboardControlEvent,
  KEYBOARD_FOCUS_EVENT,
  KeyboardControlsService,
} from './service/keyboard-controls.service';
import { TYPE_ORDE_ENUM } from '../stock-trade/enum/stock-trade.enum';
import { TYPE_ORDE_SIDE_ENUM } from '@shared/enum/buyOrSell.enum';
import { ORDER_PARAM_HELPER } from '@shared/constants/order-param-helper.const';
import { MultibrokerService } from '@shared/services/core/multibroker';
import { StockTableOrderService } from './service/stock-table-order.service';
import { StockPreferencesService } from '@shared/services/stock-preferences.service';
import { AUTH_LOCAL_KEYS } from '@shared/services/core/const/auth_util.const';

@Component({
  selector: 'app-stock-table-views',
  templateUrl: './stock-table-views.component.html',
  styleUrls: ['./stock-table-views.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockTableViewsComponent
  extends StockTableViewContext
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild('stockTableViews') private _stockTableViewsElement!: ElementRef;
  @ViewChild('stockTableElement')
  private _stockTableElement!: ElementRef<HTMLDivElement>;

  @Output() public stockClicked = new EventEmitter<{ data: IRowData }>();
  @Output() public updateStocksPosition = new EventEmitter<IRowData[]>();
  @Output() public removeStock = new EventEmitter<any>();

  @Input() set refComponent(ref: string) {
    this.ref = ref;
  }
  @Input() public componentId!: string;
  @Input() public tableView!: STOCK_TABLE_VIEW;
  @Input() public globalStock!: IGlobalStock;
  @Input() public stocks!: IRowData[] | IMoversData[];
  @Input() set handlerQuoteUpdate(
    handlerQuoteUpdate: IStockListItemsRow | null
  ) {
    !!handlerQuoteUpdate && this._handleQuoteUpdate(handlerQuoteUpdate);
  }
  @Input() public stockToRemoveFromList!: any;
  @Input() public stockListId!: number;
  @Input() public isNotListPersonal: boolean = false;
  @Input() public updateMoversSort: boolean = false;
  @Input() public moversCommand!: IMoversTransaction;
  @Input() public clearStocks: boolean = false;
  @Input() public changingView: boolean = false;
  @Input() public link: boolean = true;
  @Input() public period!: IMoversPeriod;
  @Input() public noDataTemplate!: string;
  @Input() public focused: boolean = false;
  @Input() public auctionCommand!: IAuctionCommand;

  public TABLE_VIEW = STOCK_TABLE_VIEW;
  public stocksDict = new Dictionary<any>();
  public globalStockCode!: string;
  public globalStockSelected!: IGlobalStock;
  public elementFocused: string = '';
  public stockToEnableArrowEvents: string = '';
  public contentSizes = { width: '115px', height: '78px' };
  public stockAuctionInfos: any = {};
  public componentSize = { width: 200, height: 200 };
  public cardHeight = 90;
  public standardLot: any = {};
  public stockSituation: any = {};
  public blockKeyboard: boolean = false;

  private _loadingSnapshot: boolean = false;
  private _subscribedInQuote: boolean = false;
  private _subscribedInIosma: boolean = false;
  private _getStockVlCloseSubject = new Subject<void>();
  private _onDestroy = new Subject<void>();
  private _addSubsInIosmaSubject = new Subject<void>();
  private _removeSubsInIosmaSubject = new Subject<void>();
  private _handleStocksSubject = new Subject<IRowData[] | IMoversData[]>();
  private _elementSettingsSubject = new Subject<void>();
  private _resizeSubject = new Subject<{ width: number; height: number }>();
  private _sortMoversSubject = new Subject<void>();
  private _stocksToSub: string[] = [];
  private _stocksToUnsub: string[] = [];
  private _quoteKeys: any = {};
  private _lastView!: STOCK_TABLE_VIEW;
  private iosmaItemsArray: string[] = [];
  private _elements: HTMLDivElement[] = [];
  private _lastElementFocusedIndex: number = -1;
  private _resizeObservable!: ResizeObserver;

  public readonly TYPE_ORDER = TYPE_ORDE_ENUM;
  public readonly SIDE_ORDER = TYPE_ORDE_SIDE_ENUM;
  public readonly ORDER_ORIGIN = ORDER_PARAM_HELPER;

  get isChartView(): boolean {
    return (
      this.tableView === STOCK_TABLE_VIEW.CARD ||
      this.tableView === STOCK_TABLE_VIEW.CHART
    );
  }

  get isCandleView(): boolean {
    return this.tableView === STOCK_TABLE_VIEW.CANDLE;
  }

  get clearCandleSubs(): boolean {
    return this._lastView === STOCK_TABLE_VIEW.CANDLE;
  }

  get dictFieldId(): 'iosma_key' | 'idRow' {
    return this.isCandleView ? 'iosma_key' : 'idRow';
  }

  get isIntradayPeriod(): boolean {
    return (
      !this.isNotListPersonal || !this.period || this.period.value === 'one_day'
    );
  }

  get enableQuoteSubscription(): boolean {
    return (
      !this.isCandleView &&
      this.isNotListPersonal &&
      !isAuctionList(this.stockListId) &&
      !isPresetListID(this.stockListId)
    );
  }

  get stockPreferences() {
    return this._stockPreferencesService.stocksCustomPreferences;
  }

  constructor(
    protected override _createComponent: RocketCreateComponentService,
    private _cdr: ChangeDetectorRef,
    private _stockTableViewsService: StockTableViewsService,
    private _iosmaChannel: IosmaChannel,
    private _quoteChannel: QuoteChannel,
    private _rocketGridSerice: RocketGridService,
    private _dragService: DragService,
    private _keyboardControlsService: KeyboardControlsService,
    private _multibrokerService: MultibrokerService,
    private _stockTableOrderService: StockTableOrderService,
    private _stockPreferencesService: StockPreferencesService
  ) {
    super(_createComponent);
    this._initializeObservables();
  }

  private _initializeObservables = () => {
    // CONTEXT MENU
    this.onPreferencesChange$
      .pipe(
        takeUntil(this._onDestroy),
        tap((change) => {
          if (change.type === CONFIG_ACTIONS.EDIT) {
            this.displayRemoveIcon = !this.displayRemoveIcon;
            this._setContentSizes(this.tableView);
            this._cdr.detectChanges();
          }
        })
      )
      .subscribe();

    // STOCKS
    this._handleStocksSubject
      .pipe(
        takeUntil(this._onDestroy),
        debounceTime(75),
        tap(() => this._clearStocks())
      )
      .subscribe((stocks) => this._setStocks(deepClone(stocks)));

    this._elementSettingsSubject
      .pipe(takeUntil(this._onDestroy), debounceTime(200))
      .subscribe(() => {
        this._handleResizeEvent();
        this._setListElementsRef();
      });

    this._sortMoversSubject
      .pipe(debounceTime(300))
      .subscribe(() => this._sortMovers());

    // HISTORY PRICE
    this._stockTableViewsService.stocksCloseLimit$
      .pipe(
        takeUntil(this._onDestroy),
        filter(
          (response) =>
            response && response?.data && response.refComponent === this.ref
        ),
        tap((response) => {
          if (response?.isError) return;
          this._updateStocksVlCloseHistory(
            deepClone(this.stocksDict.values()),
            response?.data
          );
        })
      )
      .subscribe();

    this._getStockVlCloseSubject
      .pipe(
        takeUntil(this._onDestroy),
        filter(() => isMoversList(this.stockListId)),
        auditTime(1000)
      )
      .subscribe(() => {
        const stocks: string[] = this.stocksDict
          .values()
          .map((stock) => stock.idRow);
        this._stockTableViewsService.chartVlClose(stocks, this.ref);
      });

    // KEYBOARD EVENT
    this._keyboardControlsService.onKeyPressed
      .pipe(
        takeUntil(this._onDestroy),
        filter(
          (params) => params.componentID === this.ref && !this.blockKeyboard
        )
      )
      .subscribe((event) => this._updateCardFocused(event));

    // RESIZE
    this._resizeSubject
      .pipe(takeUntil(this._onDestroy), debounceTime(300))
      .subscribe((size) => {
        this.componentSize = size;
        this._cdr.detectChanges();
        this._setCardHeight();
      });

    // ACCOUNT
    this._multibrokerService
      .onUpdateSelectedAccountChannel()
      .pipe(
        takeUntil(this._onDestroy),
        filter((account) => account && account.id_broker)
      )
      .subscribe((account) => (this._stockTableOrderService.account = account));

    this._stockPreferencesService
      .onChangePreferences()
      .pipe(
        takeUntil(this._onDestroy),
        filter(
          (data) =>
            this.isCandleView &&
            !this._loadingSnapshot &&
            data.key === AUTH_LOCAL_KEYS.SAVE_QTTY_GROUP_STOCKS
        )
      )
      .subscribe(() => this._updateStocksLot());
  };

  ngOnInit(): void {
    this._iosmaSubsHandler();
  }

  ngAfterViewInit(): void {
    this._stockTableViewsElement.nativeElement.oncontextmenu = this.openConfig;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {
      isNotListPersonal,
      stocks,
      moversCommand,
      updateMoversSort,
      stockToRemoveFromList,
      tableView,
      stockListId,
      globalStock,
      moversIndex,
      clearStocks,
      focused,
      period,
      auctionCommand,
    } = changes;
    if (tableView?.currentValue)
      this._onChangeTableView(tableView.currentValue, tableView?.previousValue);
    if (moversIndex?.currentValue) {
      if (this.isCandleView || this.clearCandleSubs)
        this._unsubscribeInIosmaChannel(deepClone(this._getStocksKey()));
    }
    if (stockListId?.currentValue) {
      if (this.isCandleView || this.clearCandleSubs)
        this._unsubscribeInIosmaChannel(deepClone(this._getStocksKey()));
      this.listId = stockListId.currentValue;
      if (isAuctionList(this.listId)) this._clearStocks();
      if (isMoversList(this.listId)) this._elementSettingsSubject.next();
    }
    if (clearStocks?.currentValue) this._clearStocks();
    if (!isNullOrUndefined(isNotListPersonal?.currentValue))
      this._handleFeatures(isNotListPersonal.currentValue);
    if (period?.currentValue) {
      if (this._subscribedInIosma && !this.isIntradayPeriod) {
        this._unsubscribeInIosmaChannel(this._getStocksKey());
        return;
      }
      if (!this._subscribedInIosma && this.isIntradayPeriod)
        this._subscribeInIosmaChannel(this._getStocksKey());
    }
    if (stocks?.currentValue)
      this._handleStocksSubject.next(deepClone(stocks.currentValue));
    if (moversCommand?.currentValue)
      this._commandHandler(moversCommand.currentValue);
    if (updateMoversSort?.currentValue) this._sortMoversSubject.next();
    if (stockToRemoveFromList?.currentValue)
      this._removeStockFromList(stockToRemoveFromList.currentValue);
    if (globalStock?.currentValue)
      this._setGlobalStockCode(globalStock.currentValue);
    if (!isNullOrUndefined(focused?.currentValue) && this._elements.length)
      this._handleCardFocus(focused.currentValue);
    if (auctionCommand?.currentValue)
      this._handleAuctionCommandAtCandleView(auctionCommand.currentValue);
  }

  ngOnDestroy(): void {
    if (this.isCandleView) this._unsubscribeInIosmaChannel();
    this._onDestroy.next();
    this._onDestroy.complete();
    this._onDestroy.unsubscribe();
  }

  private _setContentSizes(type: STOCK_TABLE_VIEW): void {
    const { width, height } = findContentSizes(type, this.displayRemoveIcon);
    this.contentSizes = { width, height };
    this._cdr.detectChanges();
    this._setCardHeight();
  }

  private _setCardHeight(): void {
    const cardHeight = parseInt(this.contentSizes.height.replace(/\D/g, ''));
    const cardWidth = parseInt(this.contentSizes.width.replace(/\D/g, ''));
    this.cardHeight =
      cardHeight / Math.floor(this.componentSize.width / cardWidth);
    this._cdr.detectChanges();
  }

  private _handleFeatures(isNotListPersonal: boolean): void {
    this.isMoversList = isNotListPersonal;
    if (this.displayRemoveIcon && isNotListPersonal)
      this.displayRemoveIcon = false;
    this._cdr.detectChanges();
  }

  private _setGlobalStockCode(globalStock: IGlobalStock): void {
    this.globalStockCode = globalStock.cd_stock;
    this.globalStockSelected = deepClone(globalStock);
    this._cdr.detectChanges();
  }

  public removeStockHandler(stock: IRowData): void {
    this._rocketGridSerice.setRemoveStockFromSameLists({
      idList: this.stockListId,
      rowData: stock,
    });
  }

  // CHANGE VIEW
  private _onChangeTableView(
    newView: STOCK_TABLE_VIEW,
    previusView: STOCK_TABLE_VIEW | undefined
  ): void {
    if (previusView) this._lastView = previusView;
    this.tableView = newView;
    this._cdr.detectChanges();
    this._setContentSizes(newView);
    this._loadStocksToIosmaSubscribe();
  }

  // CONTEXT MENU
  public updateFocusedStock(stock: IRowData | null, index?: number): void {
    if (isMoversList(this.stockListId) || !stock) {
      this.stockFocused = null;
      return;
    }
    stock.stock_position = index;
    this.stockFocused = stock;
  }

  // HANDLE STOCKS IN LIST
  private _setStocks(stocks: IRowData[] | IMoversData[]): void {
    if (this.isCandleView)
      this._unsubscribeInIosmaChannel(deepClone(this._getStocksKey()));
    if (!stocks?.length) return;
    this._loadingSnapshot = true;
    const cdStocks: string[] = [];
    const iosmaKeys: string[] = [];
    stocks.forEach((stock: any) => {
      stock.custom_variation = formatterNumber(+stock[this._variationField()]);
      stock.iosma_key = `${stock.cd_stock}.ONE_DAY:${stock.id_exchange ?? 1}`;
      stock.ref = `${stock[this.dictFieldId]}::${this.ref}`;
      if (
        this.isCandleView &&
        this._stockPreferencesService.saveQttyStockEnabled
      )
        stock.lotInitialValue =
          this.stockPreferences[stock.cd_stock_order]?.qtty;
      if (this.isMoversList) stock.ds_asset = this._setDsAsset(stock.cd_stock);
      this._updateStockInfo(stock, this.dictFieldId);
      cdStocks.push(
        stock.cd_stock_order
          ? `${stock.cd_stock_order}:${stock.id_exchange}`
          : stock.idRow
      );
      iosmaKeys.push(stock.iosma_key);
    });
    this._afterBuildSnapshot(cdStocks, iosmaKeys);
  }

  private _updateStockInfo = (
    stock: QuoteData | any,
    stockIdField: 'iosma_key' | 'idRow'
  ): void => {
    if (!this.stocksDict.has(stock[stockIdField]))
      this._getStockVlCloseSubject.next();
    if (this.isMoversList) stock.ds_asset = this._setDsAsset(stock.cd_stock);
    if (this.isCandleView) stock = this._buildIosmaFields(stock);
    if (stock.period) stock.custom_variation = stock.period_variation;
    else {
      const value = stock[this._variationField()];
      if (!isNullOrUndefinedOrEmpty(value))
        stock.custom_variation = formatterNumber(parseFloat(value));
    }
    this.stocksDict.set(stock[stockIdField], stock);
    this._updateAuctionInfos(stock[stockIdField], stock);
    this._cdr.detectChanges();
  };

  private _handleQuoteUpdate(stock: QuoteData | any): void {
    this.isChartView &&
      !this.stocksDict.has(stock.idRow) &&
      this._getStockVlCloseSubject.next();
    stock.variacao_dia &&
      (stock.custom_variation = formatterNumber(+stock.variacao_dia));
    this.stocksDict.set(stock.idRow, stock);
    this._updateAuctionInfos(stock.idRow, stock);
    this._cdr.detectChanges();
  }

  private _variationField(): string {
    if (isMoversList(this.stockListId)) return 'variacao';
    return 'variacao_dia';
  }

  private _afterBuildSnapshot(cdStocks: string[], iosmaKeys: string[]): void {
    if (isMoversList(this.stockListId)) this._sortMovers();
    if (this.isChartView)
      this._stockTableViewsService.chartVlClose(cdStocks, this.ref);
    if (this.isIntradayPeriod)
      this._subscribeInIosmaChannel(iosmaKeys);
    this._loadingSnapshot = false;
    this._cdr.detectChanges();
    this._elementSettingsSubject.next();
  }

  private _handleResizeEvent(): void {
    this._resizeObservable = new ResizeObserver((entries) => {
      for (const entry of entries) this._resizeSubject.next(entry.contentRect);
    });
    this._resizeObservable.observe(this._stockTableViewsElement.nativeElement);
  }

  private _clearStocks(): void {
    if (this.isCandleView || this.clearCandleSubs)
      this._unsubscribeInIosmaChannel(deepClone(this._getStocksKey()));
    this._lastElementFocusedIndex = 0;
    this.elementFocused = '';
    this.stocksDict.clear();
    this._cdr.detectChanges();
  }

  private _removeStockFromList(stock: any): void {
    this.stockFocused = null;
    if (this.isCandleView) {
      this.stocksDict.delete(stock.iosma_key);
      delete this._quoteKeys[stock.idRow];
      this._unsubscribeInIosmaChannel([stock.iosma_key]);
      this._cdr.detectChanges();
      return;
    }
    this.stocksDict.delete(stock.idRow);
    this._cdr.detectChanges();
  }

  private _loadStocksToIosmaSubscribe(): void {
    this._unsubscribeInIosmaChannel();
    if (this.stocks.length || this._lastView === STOCK_TABLE_VIEW.CANDLE)
      this._handleStocksSubject.next(deepClone(this.stocks));
  }

  // HANDLE STOCK PRICE HISTORY
  private _updateStocksVlCloseHistory(
    stocks: any,
    stocksVlCloseHistory: any
  ): void {
    if (!stocks || !stocks.length) return;
    stocks.forEach((stock: any) => {
      const idRow = stock.cd_stock_order
        ? `${stock.cd_stock_order}:${stock.id_exchange}`
        : stock.idRow;
      if (stocksVlCloseHistory[idRow]) {
        stock.vl_close_history = stocksVlCloseHistory[idRow];
        this.stocksDict.set(stock[this.dictFieldId], stock);
      }
    });
    this._cdr.detectChanges();
  }

  // HANDLE MOVERS COMMAND
  private _commandHandler = (commands: IMoversTransaction): void => {
    if (
      this._loadingSnapshot ||
      (this.isCandleView && commands.update.length) ||
      isAuctionList(this.listId)
    )
      return;
    if (commands.add.length) {
      const value = commands.add[0];
      value.custom_variation = formatterNumber(parseFloat(value.variacao));
      value.ds_asset = this._setDsAsset(value.cd_stock);
      if (this.isChartView) this._getStockVlCloseSubject.next();
      this.stocksDict.set(value[this.dictFieldId]!, value);
      this._updateStocks();
      return;
    }
    if (commands.update.length) {
      const value = commands.update[0];
      value.ds_asset = this._setDsAsset(value.cd_stock);
      value.custom_variation = formatterNumber(parseFloat(value.variacao));
      this.stocksDict.set(value.idRow, value);
      this._updateStocks();
      return;
    }
    if (commands.remove.length) {
      const field = this.isCandleView
        ? `${commands.remove[0].idRow.split(':')[0]}.ONE_DAY:1`
        : commands.remove[0].idRow;
      if (this.isCandleView) {
        this._stocksToUnsub.push(field);
        this._removeSubsInIosmaSubject.next();
      }
      this.stocksDict.delete(field);
      this._updateStocks();
    }
  };

  private _buildIosmaFields(stock: IMoversData): IMoversData {
    stock.iosma_key = `${stock.cd_stock}.ONE_DAY:1`;
    stock.vl_close = stock.preco_ultimo;
    this._stocksToSub.push(stock.iosma_key!);
    this._addSubsInIosmaSubject.next();
    return stock;
  }

  private _updateStocks(): void {
    this.stocks = <IMoversData[]>this.stocksDict.values();
    this._cdr.detectChanges();
  }

  private _sortMovers(): void {
    const field = this.isCandleView ? 'custom_variation' : 'variacao';
    const stocks = SORT_MOVERS_ROWS(
      this.stockListId,
      this.stocksDict.values(),
      field
    );
    this.stocksDict.clear();
    this.stocksDict.bulkData(this.dictFieldId, stocks);
    this._updateStocks();
  }

  public updateGlobalStock(stock: any): void {
    if (this.isCandleView) this.stockToEnableArrowEvents = stock.cd_stock;
    if (stock.cd_stock === this.globalStock) return;
    if (!this.link) return;
    this.stockClicked.emit({ data: stock });
  }

  // HANDLE IOSMA CHANNEL
  private _iosmaSubsHandler(): void {
    this._addSubsInIosmaSubject
      .pipe(takeUntil(this._onDestroy), debounceTime(500))
      .subscribe(() => {
        this._subscribeInIosmaChannel(this._stocksToSub);
        this._stocksToSub = [];
        this._cdr.detectChanges();
      });

    this._removeSubsInIosmaSubject
      .pipe(takeUntil(this._onDestroy), debounceTime(500))
      .subscribe(() => {
        this._unsubscribeInIosmaChannel(this._stocksToUnsub);
        this._stocksToUnsub = [];
        this._cdr.detectChanges();
      });
  }

  private _subscribeInIosmaChannel = (stocksToSub?: string[]): void => {
    const items = stocksToSub?.length ? stocksToSub : this._getStocksKey();
    this.iosmaItemsArray = items;
    if (!items || !items?.length || !this.isCandleView) return;
    this._cheetahChannelsHandler();
    this._iosmaChannel.subscribe({
      itemsArray: items,
    });
    this._subscribedInIosma = true;
    this._cdr.detectChanges();
    if (this.enableQuoteSubscription) return;
    this._quoteChannel.subscribe({
      itemsArray: this._handleStocksSubscribedAtIosma(deepClone(items)),
    });
    this._subscribedInQuote = true;
    this._cdr.detectChanges();
  };

  private _unsubscribeInIosmaChannel(stocksToUnsub?: string[]): void {
    if (!this.isCandleView && !this.clearCandleSubs) return;
    this._stocksToSub = [];
    this._stocksToUnsub = [];
    const items = stocksToUnsub ? stocksToUnsub : this._getStocksKey();
    if (!items || !items?.length) return;
    if (this._subscribedInIosma) {
      this._iosmaChannel.unsubscribe({ itemsArray: items });
      this._subscribedInIosma = false;
      if (this._subscribedInQuote) {
        this._subscribedInQuote = false;
        this._quoteChannel.unsubscribe({
          itemsArray: this._handleStocksSubscribedAtIosma(
            deepClone(items),
            false
          ),
        });
      }
      this._cdr.detectChanges();
    }
  }

  private _getStocksKey(): string[] {
    return this.stocksDict.values().map((stock) => stock.iosma_key!);
  }

  private _handleStocksSubscribedAtIosma(
    values: string[],
    newStock: boolean = true
  ): string[] {
    return values.map((stock) => {
      const quoteKey = stock.replace('.ONE_DAY', '');
      if (newStock) this._quoteKeys[quoteKey] = stock;
      else delete this._quoteKeys[quoteKey];
      return quoteKey;
    });
  }

  private _cheetahChannelsHandler = (): void => {
    this._iosmaChannel.readEvents().then((data) => {
      data.snapshot(this.iosmaItemsArray);
      this.readStream(data.stream, this._channelIosmaHandler);
    });
    if (this.enableQuoteSubscription) return;
    this._quoteChannel.readEvents().then(async (data) => {
      data.snapshot(Object.values(this._quoteKeys));
      this.readStream(data.stream, this._quoteHandler);
    });
  };

  private _quoteHandler = (payload: Map<string, any>) => {
    if (!this._subscribedInQuote || !Object.keys(this._quoteKeys).length)
      return;
    payload.forEach((stock, key) => {
      if (this._quoteKeys[key]) {
        this._updateAuctionInfos(this._quoteKeys[key], stock);
        if (!this.standardLot[key] && stock.lote_padrao) {
          this.standardLot[key] = parseInt(stock.lote_padrao);
          this._cdr.detectChanges();
        }
        if (stock.situacao !== this.stockSituation[key]) {
          this.stockSituation[key] = stock.situacao;
          this._cdr.detectChanges();
        }
      }
    });
  };

  private _channelIosmaHandler = (
    payload: Map<string, IIosmaStockInfo>
  ): void => {
    payload.forEach((stock, key) => {
      if (this.stocksDict.has(key)) {
        const stockInfo: any = stock;
        for (const key in stock) {
          if (['vl_', 'avg_'].includes(key)) {
            stockInfo[key] = stockInfo[key]
              ? parseFloat(stockInfo[key]).toFixed(2)
              : 0;
          } else if (key === 'variation')
            stockInfo.custom_variation = stockInfo.variation;
        }
        this.stocksDict.set(stockInfo.item, stockInfo);
      }
    });
    if (this.isMoversList) this._sortMoversSubject.next();
    this._cdr.detectChanges();
  };

  public drop(event: CdkDragDrop<any>) {
    const stocks = this.stocksDict.values();
    this._dragService.dropInAnotherComponent(
      Object.assign(event, {
        node: { data: stocks[event.previousContainer.data.index] },
      })
    );
    if (event.previousContainer.data.index === event.container.data.index)
      return;
    stocks[event.previousContainer.data.index] = event.container.data.item;
    stocks[event.container.data.index] = event.previousContainer.data.item;
    this.updateStocksPosition.emit(stocks);
    this._cdr.detectChanges();
  }

  // AUCTION
  private _updateAuctionInfos(key: string, stock: QuoteData): void {
    if (isMoversList(this.listId)) return;
    if (isAuction(stock.situacao)) {
      const cachedInfos = this.stockAuctionInfos[key];
      this.stockAuctionInfos[key] = {
        variacao_leilao: stock.variacao_leilao || cachedInfos?.variacao_leilao,
        preco_leilao: stock.preco_leilao || cachedInfos?.preco_leilao,
        situacao: stock.situacao || cachedInfos?.situacao,
        qtde_leilao_nao_atendida:
          stock.qtde_leilao_nao_atendida ||
          cachedInfos?.qtde_leilao_nao_atendida,
        sentido_leilao_nao_atendida:
          stock.sentido_leilao_nao_atendida ||
          cachedInfos?.sentido_leilao_nao_atendida,
        qtde_leilao: stock.qtde_leilao || cachedInfos?.qtde_leilao,
        hora_abert_program:
          stock.hora_abert_program || cachedInfos?.hora_abert_program,
        variacao_dia: stock.variacao_dia || cachedInfos?.variacao_dia,
      };
      this._cdr.detectChanges();
      return;
    }
    if (this.stockAuctionInfos[key]) {
      delete this.stockAuctionInfos[key];
      this._cdr.detectChanges();
    }
  }

  private _handleAuctionCommandAtCandleView(command: IAuctionCommand): void {
    const iomaKey = `${command.cd_stock}.ONE_DAY:1`;
    if (command.command === 'ADD') {
      if (this.stocksDict.has(iomaKey)) return;
      this.stocksDict.set(iomaKey, command);
      this._stocksToSub.push(iomaKey);
      this._addSubsInIosmaSubject.next();
      return;
    }

    if (command.command === 'DELETE') {
      this._stocksToUnsub.push(iomaKey);
      this._removeSubsInIosmaSubject.next();
    }
  }

  // OTHERS
  private _setDsAsset = (cdStock: string): string => cdStock.replace(/\d/g, '');

  // Elements Focus
  private _setListElementsRef(focusElementAfter: boolean = false): void {
    if (!this._stockTableElement) return;
    this._elements = Array.from(
      this._stockTableElement.nativeElement.children
    ) as HTMLDivElement[];
    if (this.focused || focusElementAfter) this._handleCardFocus(true);
    this._cdr.detectChanges();
  }

  private _handleCardFocus(isFocused: boolean): void {
    this._updateCardFocused({
      action: KEYBOARD_FOCUS_EVENT.INITIAL,
      componentID: '',
      elementID: '',
      stocksID: '',
      handleFocus: true,
      disableFocus: !isFocused,
    });
  }

  private _updateInitialCardFocused(
    disable: boolean = false,
    onlySetInitial: boolean = false
  ): void {
    if (disable) {
      this.elementFocused = '';
      this._cdr.detectChanges();
      return;
    }

    const element =
      this._lastElementFocusedIndex > -1
        ? this._elements[this._lastElementFocusedIndex]
        : this._elements[0];
    element.focus();
    if (!onlySetInitial) this.elementFocused = element.id;
    this._cdr.detectChanges();
  }

  private _updateCardFocused(params: IKeyboardControlEvent) {
    if (!this._elements || !this._elements.length) {
      if (!this.stocksDict.size()) return;
      this._setListElementsRef(true);
      return;
    }

    if (params.action === KEYBOARD_FOCUS_EVENT.INITIAL) {
      this._updateInitialCardFocused(params.disableFocus, params.handleFocus);
      return;
    }

    if (params.action === KEYBOARD_FOCUS_EVENT.SELECT) {
      if (this.stocksDict.has(params.stocksID)) {
        const stock = this.stocksDict.get(params.stocksID);
        this.updateGlobalStock(stock);
      }
      return;
    }

    let index = this._elements.findIndex((x) => x.id == params.elementID);
    index =
      params.action === KEYBOARD_FOCUS_EVENT.RIGTH ? index + 1 : index - 1;
    if (index >= 0 && index < this._elements.length) {
      this.elementFocused = this._elements[index].id;
      this._lastElementFocusedIndex = index;
      this._elements[index].focus();
      this._cdr.detectChanges();
    }
  }

  public blockKeyboardEvents(blockEvent: boolean): void {
    this.blockKeyboard = blockEvent;
  }

  public preventScroll(event: KeyboardEvent): void {
    if (this.isCandleView && !this.blockKeyboard) event.preventDefault();
  }

  // Orders
  public sendOrder(params: ITraderViewOrder, stock: any): void {
    const isGlobalStock = this.globalStock.cd_stock === stock.cd_stock;
    const isPersonalList =
      !this.isNotListPersonal &&
      !isAuctionList(this.stockListId) &&
      !isPresetListID(this.stockListId);
    this._stockTableOrderService.handleOrder(
      isPersonalList,
      this.link,
      isGlobalStock,
      params,
      stock
    );
  }

  // Stock Preferences
  public updateStockQttyPreference(
    params: {
      qtty: number;
      standardLot: number;
    },
    stock: any
  ): void {
    if (!this._stockPreferencesService.saveQttyStockEnabled) return;
    this._stockPreferencesService.setStockQtty(
      stock.cd_stock_order,
      params.qtty,
      params.standardLot
    );
  }

  private _updateStocksLot(): void {
    const stocks = this.stocksDict.values();
    const useCustomLot = this._stockPreferencesService.saveQttyStockEnabled;
    stocks.forEach((stock) => {
      const lot = useCustomLot
        ? this.stockPreferences[stock.cd_stock_order]?.qtty
        : this.standardLot[stock.idRow];
      stock.lotInitialValue = lot;
      this.stocksDict.set(stock[this.dictFieldId], stock);
    });
    this._cdr.detectChanges();
  }
}
