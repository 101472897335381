import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  FlaInputModule,
  RocketButtonModule,
  RocketModalModule,
} from '@shared/rocket-components/components';
import { StockSearchConfigModalComponent } from './stock-search-config-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LoadingModule } from '../loading/loading.module';

@NgModule({
  declarations: [StockSearchConfigModalComponent],
  imports: [
    CommonModule,
    DragDropModule,
    FlaInputModule,
    FormsModule,
    ReactiveFormsModule,
    RocketButtonModule,
    RocketModalModule,
    LoadingModule,
  ],
  exports: [StockSearchConfigModalComponent],
  providers: [],
})
export class StockSearchConfigModalModule {}
