<rocket-modal>
  <rocket-modal-header
    [hideCloseButton]="!onlyDisplayPartners"
    (onDimissClicked)="enableTourAfterClose()"
  >
    <div class="hstack w-100 justify-content-between">
      <h3 class="modal-title">Nossas boas-vindas ao RocketTrader! 🚀</h3>
      <ng-container *ngIf="!onlyDisplayPartners">
        <button
          type="button"
          class="btn btn-outline-primary btn-sm"
          (click)="doLogout()"
        >
          <span class="material-icons-outlined"> logout </span> Sair
        </button>
      </ng-container>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div class="w-100">
      <p class="fs-5">
        Somos uma plataforma focada em <span class="fw-bold">trading</span> com
        um ambiente que vai além das expectativas, garantindo
        <span class="fw-bold">performance</span> excepcional,
        <span class="fw-bold">estabilidade</span> robusta e a mais alta
        <span class="fw-bold">segurança</span>.
      </p>

      <p class="fs-5">
        Para presentear sua chegada, você terá um
        <span class="fw-bold">trial gratuito</span> para conhecer todas as
        nossas ferramentas!
      </p>
      <p class="fs-5">Escolha uma das opções abaixo para começar a usar!</p>
      <app-benefit-card
        [brokers]="brokers"
        [displayPaymentWarning]="true"
        (handleButtonClicked)="handleBenefits($event)"
      ></app-benefit-card>
      <div class="gap-3 hstack my-6">
        <ng-container *ngIf="!onlyDisplayPartners">
          <app-rocket-icons name="CORRETORAS"> </app-rocket-icons>
          <div class="gap-1 vstack">
            <span class="fs-base text-light fw-bold">
              Não encontrou sua corretora?
            </span>
            <span class="fs-6 text-light fw-bold">
              Clique no botão Quero conhecer e experimente a plataforma por 7
              dias gratuitos
            </span>
          </div>
          <button
            type="button"
            class="btn btn-outline-primary btn-sm"
            (click)="openTrialModal()"
          >
            Quero conhecer
          </button>
        </ng-container>
      </div>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
