export const SHARED_WORKER_EVENT_CHANNEL = 'SHARED_WORKER_EVENT_CHANNEL';

export const SharedWorkerEventsActions = {
  INDICATOR_SELECTED: 'INDICATOR_SELECTED_ACTION',
  OPTIONS_LIST_PREFERENCES: 'OPTIONS_LIST_PREFERENCES_ACTION',
  STOCKCHART_DETECT_CHANGES: 'STOCKCHART_DETECT_CHANGES_ACTION',
  INDICATORS_UPDATE_FAVORITE_ACTION: 'INDICATORS_UPDATE_FAVORITE_ACTION',
  LOCK_WORKSPACE: 'LOCK_WORKSPACE',
  UPDATE_ORDERS_METADATA: 'UPDATE_ORDERS_METADATA',
  UPDATE_TOTAL_ORDERS: 'UPDATE_TOTAL_ORDERS',
  ORDERS: 'ORDERS',
  ORDERS_COUNT: 'ORDERS_COUNT',
  CUSTODY_READY: 'CUSTODY_READY',
  UPDATE_HEATMAP_CHART_DATA: 'UPDATE_HEATMAP_CHART_DATA',
  SELECT_MARKET_SUMMARY_INDEX: 'SELECT_MARKET_SUMMARY_INDEX',
};