<div
  class="d-flex justify-content-between bg-neutral-strong round p-2 mb-2"
  [ngClass]="{
    'cursor-pointer': isClickable,
    active: isActive
  }"
  (click)="onClick()"
>
  <div class="fw-bolder fs-7 d-flex align-items-center">
    <img
      [src]="stock.logoCompany"
      class="round logo-company mr-3"
      (error)="showImgError = true"
      *ngIf="!showImgError; else noLogo"
    />
    <ng-template #noLogo>
      <div
        class="round logo-company mr-3 bg-neutral-medium d-flex justify-content-center align-items-center"
      >
        <fla-icon name="business" css="text-dark"> </fla-icon>
      </div>
    </ng-template>
    <div class="d-flex flex-column">
      <span class="fs-6 mb-1" [innerHTML]="stockLabel"></span>
      <span class="fs-7 fw-normal text-neutral-smooth">
        {{ stock.nmCompany }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <div class="chart-container-card pr-2 pt-1">
      <ng-container *ngIf="vlCloseHistory?.length">
        <app-chart-line
          [lineColor]="bgColor"
          [data]="vlCloseHistory!"
          [tickSize]="2"
          [cdStock]="stock.cdStock"
        ></app-chart-line>
      </ng-container>
    </div>
    <div class="d-flex flex-column">
      <div class="fs-6 d-flex justify-content-center pb-1">
        {{ lastPrice }}
      </div>
      <div class="d-flex align-items-center">
        <app-tag
          [isVariation]="true"
          [variation]="variation"
          [bgColor]="bgColor"
          [color]="textColor"
        ></app-tag>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <ng-content></ng-content>
    </div>
  </div>
</div>
