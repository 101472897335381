import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  computed,
  signal,
} from '@angular/core';
import { OptionsComponentService } from '@shared/components/options/options-component.service';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { randomId } from '@shared/rocket-components/utils';
import { TIGER_INTERVAL_ENUM } from '@shared/tiger-chart/enum';
import { ScichartService } from '@shared/tiger-chart/services/scichart.service';
import {
  CursorTooltipSvgAnnotation,
  FastMountainRenderableSeries,
  GradientParams,
  Point,
  SeriesInfo,
  TWebAssemblyChart,
  XyDataSeries,
  adjustTooltipPosition,
} from 'scichart';
import { formatDate } from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-options-history-graph',
  templateUrl: './options-history-graph.component.html',
  styleUrls: ['./options-history-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsHistoryGraphComponent implements AfterViewInit, OnChanges {
  @Input() set height(height: string) {
    if (height) {
      this._height.set(parseInt(height));
    }
  }
  private _height = signal(0);
  getHeight = computed(() => {
    return this._height() / 2 - 88;
  });
  @Input() theme!: any;
  @Input() data!: any;
  @Input() refId!: string;
  @Input() loading = true;
  @Output() chartReady = new EventEmitter();

  legendId: string = randomId('options-history_legend');
  noDataId: string = randomId('options-smile-no-data');
  loadingGraph = true;
  chart!: TWebAssemblyChart | undefined;
  series!: FastMountainRenderableSeries;
  configs = { series: { interval: TIGER_INTERVAL_ENUM.ONE_DAY } };
  scichartService!: ScichartService;

  constructor(
    private _stockChartService: StockChartService,
    private optionsService: OptionsComponentService
  ) {
    this.scichartService = new ScichartService(
      undefined,
      this._stockChartService
    );
  }

  ngAfterViewInit() {
    this._initGraph();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { theme, data } = changes;
    if ((theme && theme.currentValue) || (data && data.currentValue)) {
      this._plotData();
    }
  }

  private _initGraph() {
    this.scichartService.initSciChart(this.refId).then((baseChart) => {
      this.chart = baseChart;
      const xAxisFormatter = (value: any) => {
        return formatDate(`${value}`, true);
      };
      const xAxis = this.optionsService.buildXAxis(
        this.chart,
        0,
        xAxisFormatter
      );
      this.chart.sciChartSurface.xAxes.add(xAxis);
      const yAxis = this.optionsService.buildYAxis(this.chart);
      this.chart.sciChartSurface.yAxes.add(yAxis);
      this.chart.sciChartSurface.chartModifiers.add(
        this.optionsService.buildCursorModifier(
          this.theme,
          this._tooltipSvgTemplate
        )
      );
      this.chart.sciChartSurface.chartModifiers.add(
        this.optionsService.buildZoomPan()
      );
      this.chart.sciChartSurface.chartModifiers.add(
        this.optionsService.buildWheelZoom()
      );
      this.chart.sciChartSurface.chartModifiers.add(
        this.optionsService.buildZoomDoubleClick()
      );
      this.chart.sciChartSurface.chartModifiers.add(
        this.optionsService.buildLegend(this.legendId)
      );
      this._stockChartService.removeChartLoading$.next({
        remove: true,
        ref: this.refId,
      });
      this.loadingGraph = false;
      this._plotData();
      this.chartReady.emit(null);
    });
  }

  private _plotData() {
    if (!this.chart) return;
    const xValues: number[] = [];
    const yValues: number[] = [];
    for (let index = this.data.length - 1; index >= 0; index--) {
      const element = this.data[index];
      xValues.push(+element.id_point);
      yValues.push(element.vl_year * 100);
    }

    const dataSeries = new XyDataSeries(this.chart.wasmContext, {
      dataSeriesName: 'Volatilidade',
      yValues,
      xValues,
      containsNaN: false,
      isSorted: true,
    });
    if (this.series) {
      this.series.dataSeries = dataSeries;
      this.chart.sciChartSurface.zoomExtents();
      return;
    }
    this.series = new FastMountainRenderableSeries(this.chart.wasmContext, {});
    this.series.strokeThickness = 2;
    this.series.stroke = this.theme.brandSupportPrimary;
    this.series.fillLinearGradient = new GradientParams(
      new Point(0, 0),
      new Point(0, 1),
      [
        { color: this.theme.brandSupportPrimary, offset: 0 },
        { color: `${this.theme.brandSupportPrimary}00`, offset: 1 },
      ]
    );

    this.series.dataSeries = dataSeries;
    this.chart.sciChartSurface.renderableSeries.add(this.series);
    this.chart.sciChartSurface.zoomExtents();
  }

  private _tooltipSvgTemplate(
    seriesInfos: SeriesInfo[],
    svgAnnotation: CursorTooltipSvgAnnotation,
    theme: any
  ): string {
    const width = 120;
    const height = 46;
    const seriesInfo = seriesInfos[0];
    if (!seriesInfo?.isWithinDataBounds || !theme) {
      return '<svg></svg>';
    }

    const x = seriesInfo ? seriesInfo.formattedXValue : '';
    const y1 = seriesInfo ? seriesInfo.yValue.toFixed(2) : '';

    adjustTooltipPosition(width, height, svgAnnotation);
    return `<svg width="${width}" height="${height}">
            <rect width="100%" height="100%" rx="4" style="fill:${theme.axisBandsFill};"></rect>
            <svg width="100%">
                <text y="5" font-size="11" dy="0" fill="${theme.axisTitleColor}">
                    <tspan x="6" text-anchor="left" dy="1.4em" font-weight="bold">${x}</tspan>
                    <tspan x="6" text-anchor="left" dy="1.2em">Volatilidade: ${y1}%</tspan>
                </text>
            </svg>
        </svg>`;
  }
}
