<rocket-modal>
  <rocket-modal-header>
    <h2 class="modal-title">Configurações</h2>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <div class="row pt-3">
      <div
        class="col-auto mb-5"
        *ngFor="let item of configView"
        [style.width.%]="item.width"
      >
        <div
          class="position-relative border border-brand-primary round px-2 py-3"
        >
          <div
            class="bg-dark fw-bold position-absolute pr-2 round-sm position-top d-flex"
          >
            <app-check
              css="cursor-pointer"
              [isChecked]="item.allCheck"
              (flaChange)="selectAll($event, item.columns)"
            ></app-check>
            <label class="ml-2">
              {{ item.label }}
            </label>
          </div>
          <div
            *ngFor="let column of item.columns"
            class="d-inline-block"
            [style.width.px]="column.width"
            [tooltip]="column.tooltip"
          >
            <app-check
              [label]="column.label"
              [isChecked]="column.isCheck"
              [inline]="true"
              css="cursor-pointer"
              (flaChange)="setVisibleOrInvisible($event, column, item.label)"
            ></app-check>
          </div>
        </div>
      </div>
    </div>
  </app-rocket-modal-body>
  <rocket-modal-footer>
    <rocket-button
      type="fill"
      label="Cancelar"
      css="mr-4"
      [isSmall]="true"
      (rocketClick)="onClose()"
    ></rocket-button>
    <rocket-button
      label="Salvar"
      [isSmall]="true"
      (rocketClick)="onSave()"
    ></rocket-button>
  </rocket-modal-footer>
</rocket-modal>
