import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SearchStockComponent } from '@shared/components/search-stock/search-stock.component';
import { DragService } from '@shared/rocket-components/services/ag-grid/drag.service';
import { Subscription } from 'rxjs';
import { ISearchStock, IWorkSpaceComponet } from 'src/app/core/interface';

@Component({
  selector: 'app-table-book-header',
  templateUrl: 'table-book-header.component.html',
})
export class TableBookHeaderComponent
  implements AfterViewInit, OnDestroy, OnChanges
{
  @ViewChild('searchStock') searchStock!: SearchStockComponent;
  @Input() refComponent!: string;
  @Input() stockSelected!: ISearchStock;
  @Input() headerOptions!: any;
  @Input() bookByOfferActive: boolean = false;
  @Input() component!: IWorkSpaceComponet;
  @Output() changeStock: EventEmitter<ISearchStock> =
    new EventEmitter<ISearchStock>();
  @Output() changeBookByOffer: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  private dragEvents$!: Subscription;

  public tooltip!: string;
  public icon!: string;
  public iconClass!: string;
  constructor(private dragService: DragService) {}

  ngAfterViewInit() {
    this.dragEvents$ = this.dragService
      .onEvents(this.refComponent)
      .subscribe((data) => {
        this.selectStockByCdStock(data.data.cd_stock);
      });
    this.setButtonIcon();
  }

  ngOnChanges(changes: SimpleChanges) {
    changes['bookByOfferActive'] && this.setButtonIcon();
  }

  ngOnDestroy() {
    this.dragEvents$ && this.dragEvents$.unsubscribe();
  }

  public selectStock(stock: ISearchStock): void {
    if (!this.stockSelected || this.stockSelected.cd_stock !== stock.cd_stock) {
      this.stockSelected = stock;
      this.changeStock.emit(stock);
    }
  }

  selectStockByCdStock(cdStock: string) {
    this.searchStock.selectStockByCdStock(cdStock);
  }

  toggleTradeByOffer = () => {
    this.bookByOfferActive = !this.bookByOfferActive;
    this.setButtonIcon();
    this.changeBookByOffer.emit();
  };

  setButtonIcon = () => {
    if (this.bookByOfferActive) {
      this.tooltip = 'Book por oferta';
      this.icon = 'money_off';
      this.iconClass = 'text-neutral-medium';
    } else {
      this.tooltip = 'Book por preços';
      this.icon = 'attach_money';
      this.iconClass = 'text-brand-primary';
    }
  };
}
