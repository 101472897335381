import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ISearchStock } from '@core/interface';
import {
  VMDateToDate,
  VMDateToFriendlyDate,
  VMDateToYear,
  dateToVMDate,
  execFormatFinancial,
  formatByTick,
  formatCNPJ,
  formatCPF,
  formatDate,
  getVolumeText,
  isNullOrUndefined,
  newDateToVMDate,
  toValue,
  updateDate,
} from 'src/app/utils/utils.functions';
import {
  ICompanyMembersDataRemuneracaoDiretoriaConselho,
  ICorporateEvents,
  IGroupIndicatorsRows,
  IGroupIndicatorsRowsValues,
  IHistCorporateEvent,
  IIndicatorsTable,
  ILaminaSectionStatistics,
  IListDocuments,
  IResumeList,
  IShareHoldersHistory,
  IShareHoldersInfo,
  IYields,
} from './business-profile.interface';
import { FundamentalService } from '@shared/services/api/trademap/V2/fundamental.service';
import { StockService } from '@shared/services/api/trademap/v1/stock.service';
import {
  CARDS_FII,
  CARDS_YIELDS,
  EXECUTIVES_BOARD_CARDS,
} from './business-profile.const';
import { formatterNumber } from '@shared/rocket-components/utils';
import { Dictionary } from '@core/models';
import { adjustTooltipPosition } from 'scichart/Charting/ChartModifiers/CursorModifier';
import { SeriesInfo } from 'scichart/Charting/Model/ChartData/SeriesInfo';
import { CursorTooltipSvgAnnotation } from 'scichart/Charting/Visuals/Annotations/CursorTooltipSvgAnnotation';
import { NumberRange } from 'scichart/Core/NumberRange';
import { EAxisAlignment } from 'scichart/types/AxisAlignment';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { ELegendOrientation } from 'scichart/Charting/Visuals/Legend/SciChartLegendBase';
import { LegendModifier } from 'scichart/Charting/ChartModifiers/LegendModifier';
import { EAutoRange } from 'scichart/types/AutoRange';
import { CursorModifier } from 'scichart/Charting/ChartModifiers/CursorModifier';
import { Subject, auditTime, filter, tap } from 'rxjs';
import { IRenderableSeries } from 'scichart';
import { CHART_COLORS, CHART_COLORS_LIGHT } from '@shared/tiger-chart/colors';
import { IProceedsCalendar } from '@shared/services/api/trademap/v1/interface/calendars.interface';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { TreemapService } from '@shared/services/api/trademap/V6/treemap.service';
import { RocketCategoryAxis } from '@shared/scichart/axis/rocket-category-axis';
import { RocketDateTimeNumericAxis } from '@shared/scichart/axis/rocket-date-time-numeric-axis';
import { RocketNumericAxis } from '@shared/scichart/axis/rocket-numeric-axis';
import { IIndicatorHeaders } from './indicators/interface/indicators.interface';
@Injectable({
  providedIn: 'root',
})
export class BusinessProfileService {
  initSubchart$ = new Subject<{
    id: string;
    row: IGroupIndicatorsRows | IIndicatorsTable;
    lineIndex: number;
  }>();

  private volumeTwoDigits = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };

  public stockChanged = new Subject<{
    refComponent: string;
    stock: ISearchStock;
  }>();

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private fundamentalService: FundamentalService,
    private stockService: StockService,
    private themeService: ThemePreferencesService,
    private treemapService: TreemapService
  ) {}

  getFormattedValues(data: any, stockSelected: ISearchStock) {
    const value = { ...stockSelected, ...data };
    const formattedVolume = getVolumeText(
      this.locale,
      parseFloat(data.volume),
      this.volumeTwoDigits
    );
    const lastPrice = `${
      stockSelected.cd_security_type !== 'IDX'
        ? stockSelected.id_exchange === 1 &&
          stockSelected.cd_security_type !== 'FUT'
          ? 'R$'
          : ''
        : ''
    } ${execFormatFinancial(value, data.preco_ultimo)}`;
    const lastVariation = `${formatByTick(data.variacao_dia)}%`;
    return {
      ...data,
      lastPrice,
      lastVariation,
      formattedVolume,
    };
  }

  formatStatistics(
    element: ILaminaSectionStatistics,
    stockSelected: ISearchStock,
    stockInfo: any
  ) {
    const hashMethods: any = {
      marketcap: getVolumeText(
        this.locale,
        element.value,
        this.volumeTwoDigits
      ),
      volume: getVolumeText(this.locale, element.value, this.volumeTwoDigits),
      vl_open: execFormatFinancial(stockSelected, element.value),
      price_previous_closing: execFormatFinancial(stockSelected, element.value),
    };
    const isFuture = stockSelected.cd_security_type == 'FUT';
    const isVolume = element.property === 'volume';
    const isDYield = element.property === 'dividend_yield';
    const isDPA = element.property === 'dividend_per_stock_annual';
    const defaultFormatter =
      isFuture || (!isDYield && !isDPA)
        ? formatByTick(element.value)
        : formatterNumber(element.value);
    const method = hashMethods[element.property] ?? defaultFormatter;
    const isFinancialValue =
      element.property === 'marketcap' ||
      element.property === 'profit_per_stock_annual' ||
      isDPA;
    const field = isVolume ? 'formattedVolume' : element.property;
    stockInfo[field] = isFinancialValue
      ? `R$ ${method}`
      : `${method}${element.suffix ? element.suffix : ''}`;
    const tooltip = isVolume
      ? 'formattedVolume_help'
      : `${element.property}_help`;
    stockInfo[tooltip] = element.help;
    return stockInfo;
  }

  formatDocs(docs: IListDocuments[]): IListDocuments[] {
    docs.map((doc) => {
      return Object.assign(
        doc,
        this.getResumeList(
          doc.dtDocument || doc.dtReference,
          doc.dsDocumentDescription || doc.dsDocumentCategoryPt || doc.type,
          doc.idCvmCompany || doc.id,
          doc.seq
        )
      );
    });
    return docs;
  }

  formatCorporateEvents(
    events: IHistCorporateEvent[],
    stockSelected: ISearchStock
  ): IHistCorporateEvent[] {
    events.map((event) => {
      if (isNullOrUndefined(event.provent_price)) return;
      const formattedPrice = formatByTick(event.provent_price!!.toString(), 3);
      const preffix = stockSelected.cd_country === 'BR' ? 'R$' : '$';
      return Object.assign(
        event,
        this.getResumeList(
          event.dt_event,
          `<span>${event.ds_corporative_event_type}</span><span>${preffix} ${formattedPrice}</span>`
        )
      );
    });
    return events;
  }

  formatProceeds(events: IProceedsCalendar[]): IProceedsCalendar[] {
    events.map((event) => {
      if (isNullOrUndefined(event.ds_company_action_type)) return;
      const bodyValues = event.ds_company_action_type.split('-');
      return Object.assign(
        event,
        this.getResumeList(
          event.dt_limit,
          `<span>${bodyValues[0]}</span><span>${bodyValues[1]}</span>`
        )
      );
    });
    return events;
  }

  private getResumeList(
    dt: number,
    value: string,
    idCvmCompany?: number,
    seq?: number
  ): IResumeList {
    const date = VMDateToDate(dt.toString());
    const yearDoc = date.getFullYear().toString();
    const dateString = VMDateToFriendlyDate(dt.toString());
    const body = value;
    return { yearDoc, dateString, body, idCvmCompany, seq };
  }

  private getCompanyInfo(stockSelected: ISearchStock) {
    switch (stockSelected.type) {
      case 'VIS':
      case 'FRC':
      case 'BDR':
      case 'ADR':
      case 'REIT-US':
      case 'US':
        return this.fundamentalService.companyInfo(
          stockSelected.cd_stock,
          stockSelected.id_exchange
        );
      case 'FII':
        return this.stockService.fiiSummary(stockSelected.cd_stock);
      default:
        return new Promise((resolve) => resolve(false));
    }
  }

  getCompanyInfoByTypeStock(stockSelected: ISearchStock) {
    let companyInfo: any = {};
    companyInfo.sectors = [];
    return this.getCompanyInfo(stockSelected).then((info: any) => {
      if (!info) {
        return Promise.reject();
      }
      companyInfo = { ...companyInfo, ...info.data.result };
      companyInfo.sectors.push({
        title: 'Setor',
        description: companyInfo.market_position.ds_position_sector,
        cod: 'sector',
      });
      companyInfo.sectors.push({
        title: 'Sub-setor',
        description: companyInfo.market_position.ds_position_subsector,
        cod: 'subsector',
      });
      companyInfo.sectors.push({
        title: 'Segmento',
        description: companyInfo.market_position.ds_position_segment,
        cod: 'segment',
      });
      companyInfo.description = companyInfo.detail.ds_company_activict;
      companyInfo.dtIPO = '-';
      companyInfo.priceIPO = '-';
      companyInfo.tagLong = '-';
      companyInfo.freeFloat = '';
      companyInfo.listing = '';
      companyInfo.shareholders = '';
      if (companyInfo.ipo) {
        const preffix = stockSelected.cd_country === 'BR' ? 'R$' : '$';
        companyInfo.dtIPO = formatDate(companyInfo.ipo.dt_inicio, true);
        companyInfo.priceIPO = `${preffix} ${formatByTick(
          companyInfo.ipo.preco_ini.toString()
        )}`;
      }
      if (companyInfo.governanca && companyInfo.governanca.length) {
        companyInfo.tagLong = `${companyInfo.governanca[0].pc_tag_along}% ${companyInfo.governanca[0].mercado}`;
        if (companyInfo.governanca[1]) {
          companyInfo.tagLong += ` | ${companyInfo.governanca[1].pc_tag_along}% ${companyInfo.governanca[1].mercado}`;
        }
      }
      if (companyInfo.mercado && companyInfo.mercado.length) {
        const pcOn = companyInfo.mercado[0].pc_on;
        const pcPn = companyInfo.mercado[0].pc_pn;
        if (pcOn) {
          companyInfo.freeFloat += `${formatByTick(
            companyInfo.mercado[0].pc_on
          )}% ON`;
        }
        if (pcOn && pcPn) {
          companyInfo.freeFloat += ' | ';
        }
        if (pcPn) {
          companyInfo.freeFloat += `${formatByTick(
            companyInfo.mercado[0].pc_pn
          )}% PN`;
        }
        companyInfo.freeFloat = companyInfo.freeFloat.length
          ? companyInfo.freeFloat
          : '-';
      }
      if (companyInfo.market_position) {
        companyInfo.listing = `${companyInfo.market_position.cd_governance_indicator}`;
      }
      if (companyInfo.distribuicao && companyInfo.distribuicao.length) {
        const qtdInst = companyInfo.distribuicao[0].qtd_inst || 0;
        const qtdPf = companyInfo.distribuicao[0].qtd_pf || 0;
        const qtdPj = companyInfo.distribuicao[0].qtd_pj || 0;
        const sumShareholders = qtdInst + qtdPf + qtdPj;
        companyInfo.shareholders = `${
          sumShareholders ? formatByTick(sumShareholders, 0) : '-'
        }`;
      }

      if (
        stockSelected.cd_security_type === 'ADR' ||
        stockSelected.cd_security_type === 'REIT-US' ||
        stockSelected.cd_security_type === 'US'
      ) {
        companyInfo.sectors.pop();
        companyInfo.sectors[0].description = companyInfo.market_position.sector;
        companyInfo.sectors[1].description =
          companyInfo.market_position.industry;
      }
      if (stockSelected.cd_security_type === 'FII') {
        companyInfo.sectors.push({
          title: 'Tipo ANBIMA',
          description: companyInfo.anbimaType,
          cod: 'sector',
        });
        companyInfo.sectors.push({
          title: 'Segmento ANBIMA',
          description: companyInfo.anbimaSegment,
          cod: 'subsector',
        });
        companyInfo.sectors.push({
          title: 'Público-alvo',
          description: companyInfo.target,
          cod: 'segment',
        });
      }
      return companyInfo;
    });
  }

  formatDateChart = (
    dataValue: number,
    yearDigit: 'numeric' | '2-digit' | undefined = '2-digit'
  ) => {
    const options: Intl.DateTimeFormatOptions = {
      timeZone: 'America/Sao_Paulo',
    };
    const date = new Date(dataValue);
    options.month = '2-digit';
    options.day = '2-digit';
    options.year = yearDigit;
    return date.toLocaleString(this.locale, options);
  };

  getCorporateEvents(
    stockSelected: ISearchStock,
    slice?: number,
    maxYear?: number
  ) {
    return this.stockService
      .getHistCorporateEventFull(
        stockSelected.cd_stock,
        stockSelected.id_exchange
      )
      .then((data: any) => {
        const result = data.data.result;
        if (!result.hist_corporate_event.length) {
          return Promise.reject();
        }
        let corporateEvents: IHistCorporateEvent[] = slice
          ? result.hist_corporate_event.slice(0, slice)
          : result.hist_corporate_event;
        if (maxYear) {
          corporateEvents = corporateEvents.filter((event) => {
            const year = parseInt(VMDateToYear(event.dt_event));
            const todayYear = parseInt(VMDateToYear(newDateToVMDate()));
            const mYear = todayYear - maxYear;
            return year >= mYear;
          });
          result.hist_corporate_event = corporateEvents;
        }
        const corporateEventsToShow = this.formatCorporateEvents(
          corporateEvents,
          stockSelected
        ) as unknown as IResumeList[];
        return { corporateEvents: result, corporateEventsToShow };
      });
  }

  indexDescription(stockSelected: ISearchStock) {
    return this.stockService.getIndexDescription(
      stockSelected.cd_stock,
      stockSelected.id_exchange
    );
  }

  treemap(stockSelected: ISearchStock) {
    return this.treemapService.getTreemap(
      stockSelected.cd_stock,
      stockSelected.id_exchange
    );
  }

  getFIISummary(
    corporateEvents: ICorporateEvents,
    lastEvent: IHistCorporateEvent
  ): Dictionary<IYields> {
    const deepCards = CARDS_FII;
    const cards = new Dictionary<IYields>();
    const processedSummary = [
      {
        cod: 'U_R',
        value_yield:
          formatByTick(corporateEvents.divident_yield_last_value.toString()) +
          '%',
        dt_event: formatDate(lastEvent.dt_event, true),
        value: formatByTick(corporateEvents.divident_last_value.toString(), 3),
      },
      {
        cod: '3_M',
        value_yield:
          formatByTick(corporateEvents.divident_yield_tres_meses.toString()) +
          '%',
        value: formatByTick(corporateEvents.divident_tres_meses.toString(), 3),
      },
      {
        cod: '6_M',
        value_yield:
          formatByTick(corporateEvents.divident_yield_seis_meses.toString()) +
          '%',
        value: formatByTick(corporateEvents.divident_seis_meses.toString(), 3),
      },
      {
        cod: '12_M',
        value_yield:
          formatByTick(corporateEvents.divident_yield_um.toString()) + '%',
        value: formatByTick(corporateEvents.divident_um.toString(), 3),
      },
      {
        cod: '24_M',
        value_yield:
          formatByTick(corporateEvents.divident_yield_dois.toString()) + '%',
        value: formatByTick(corporateEvents.divident_dois.toString(), 3),
      },
    ];
    processedSummary.forEach((summary) => {
      const previous = deepCards.get(summary.cod)!!;
      previous.dt_event = summary.dt_event;
      previous.value_yield = summary.value_yield;
      previous.value = summary.value;
      cards.set(summary.cod, previous);
    });
    return cards;
  }

  getSummary(
    corporateEvents: ICorporateEvents,
    stockSelected: ISearchStock
  ): Dictionary<IYields> {
    const deepCards = CARDS_YIELDS;
    const cards = new Dictionary<IYields>();
    const delta =
      corporateEvents.divident_yield_ultimo != 0
        ? (corporateEvents.divident_yield_um /
            corporateEvents.divident_yield_ultimo -
            1) *
          100
        : undefined;
    const preffix = stockSelected.cd_country === 'BR' ? 'R$' : '$';
    const processedSummary = [
      {
        cod: 'YIELD_12M',
        value_yield:
          formatByTick(corporateEvents.divident_yield_um.toString()) + '%',
        value: `${preffix} ${formatByTick(
          corporateEvents.divident_um.toString(),
          2
        )}`,
      },
      {
        cod: 'YIELD_24M',
        value_yield:
          formatByTick(corporateEvents.divident_yield_dois.toString()) + '%',
        value: `${preffix} ${formatByTick(
          corporateEvents.divident_dois.toString(),
          2
        )}`,
      },
      {
        cod: 'YIELD_36M',
        value_yield:
          formatByTick(corporateEvents.divident_yield_tres.toString()) + '%',
        value: `${preffix} ${formatByTick(
          corporateEvents.divident_tres.toString(),
          2
        )}`,
      },
      {
        cod: 'V_12M',
        value_yield: delta ? formatByTick(delta.toString()) + '%' : '-',
      },
      {
        cod: 'MM_24M',
        value_yield: corporateEvents.divident_yield_avg
          ? formatByTick(corporateEvents.divident_yield_avg.toString()) + '%'
          : '-',
      },
    ];
    processedSummary.forEach((summary) => {
      const previous = deepCards.get(summary.cod)!!;
      previous.value_yield = summary.value_yield;
      previous.value = summary.value;
      cards.set(summary.cod, previous);
    });
    return cards;
  }

  buildChartYAxis(
    baseChart: TWebAssemblyChart,
    formatter?: any
  ): RocketNumericAxis {
    const yAxis = new RocketNumericAxis(
      this.themeService,
      baseChart.wasmContext,
      {
        axisAlignment: EAxisAlignment.Right,
        drawMajorGridLines: true,
        drawMinorGridLines: false,
        drawMajorTickLines: false,
        drawMinorTickLines: false,
        drawMajorBands: false,
        autoTicks: true,
        autoRange: EAutoRange.Always,
        majorDelta: 3,
        minorDelta: 2,
        growBy: new NumberRange(0.1, 0.2),
        lineSpacing: 1.5,
      }
    );
    yAxis.labelProvider.formatLabel = (param: number) =>
      formatter ? formatter(param) : formatterNumber(param);
    yAxis.labelProvider.formatCursorLabel = (param: number) =>
      formatter ? formatter(param) : formatterNumber(param);
    return yAxis;
  }

  buildChartXAxis(
    baseChart: TWebAssemblyChart,
    typeAxis: 'NUMERIC' | 'CATEGORY',
    formatter?: any
  ): RocketCategoryAxis | RocketDateTimeNumericAxis {
    const xAxis =
      typeAxis === 'CATEGORY'
        ? new RocketCategoryAxis(this.themeService, baseChart.wasmContext, {
            drawMajorGridLines: false,
            drawMinorGridLines: false,
            drawMajorBands: false,
            drawMajorTickLines: false,
            drawMinorTickLines: false,
            autoRange: EAutoRange.Always,
          })
        : new RocketDateTimeNumericAxis(
            this.themeService,
            baseChart.wasmContext,
            {
              drawMajorGridLines: false,
              drawMinorGridLines: false,
              drawMajorBands: false,
              drawMajorTickLines: false,
              drawMinorTickLines: false,
              autoRange: EAutoRange.Always,
            }
          );
    const yearDigit = typeAxis === 'NUMERIC' ? 'numeric' : undefined;
    xAxis.labelProvider.formatLabel = (value: number) =>
      formatter ? formatter(value) : this.formatDateChart(value, yearDigit);
    xAxis.labelProvider.formatCursorLabel = (value: number) =>
      formatter ? formatter(value) : this.formatDateChart(value, yearDigit);
    return xAxis;
  }
  private mouseEvent$ = new Subject<{
    maxXCoordinate: number;
    maxYCoordinate: number;
    id: string;
    target: any;
    mouseEvent: MouseEvent;
  }>();
  private deleteElement$ = new Subject<void>();
  tooltipSvgTemplate = (
    seriesInfos: SeriesInfo[],
    svgAnnotation: CursorTooltipSvgAnnotation,
    width: number,
    height: number,
    divTooltipId: string,
    conditions:
      | {
          isFlexWrap: boolean;
          addZero: boolean;
          qttyWrap: number;
          showSeriesName?: boolean;
          showCircle?: boolean;
        }
      | undefined = undefined
  ): string => {
    const seriesInfo = seriesInfos[0];
    if (!seriesInfo) {
      return '<svg></svg>';
    }
    const mouse = (mouseEvent: MouseEvent) => {
      const maxXCoordinate = mouseEvent.screenX;
      const maxYCoordinate = mouseEvent.clientY;
      this.mouseEvent$.next({
        mouseEvent,
        maxXCoordinate,
        maxYCoordinate,
        id: seriesInfo.renderableSeries.id,
        target: mouseEvent.target,
      });
    };
    seriesInfo.renderableSeries.parentSurface.domCanvas2D.addEventListener(
      'mousemove',
      mouse
    );
    let info = '';
    const classFlex =
      conditions && conditions.isFlexWrap ? 'flex-wrap gap-1' : 'flex-column';
    seriesInfos.forEach((element) => {
      if (conditions && !conditions.addZero && element.yValue == 0) {
        return;
      }
      const circle =
        !conditions || conditions.showCircle
          ? `<div style="background: ${element.stroke};width:5px;height:5px;border-radius:100%;"></div>`
          : '';
      const gap = !conditions || conditions.showCircle ? 'gap-1' : '';
      const gapSeriesName =
        !conditions || conditions.showSeriesName ? 'gap-1' : '';
      const seriesName =
        !conditions || conditions.showSeriesName
          ? `<span class="fs-6">${element.seriesName}:</span>`
          : '';
      info += `<div class="d-flex align-items-center ${gap}">
      ${circle}
      <div class="d-flex align-items-center ${gapSeriesName}">
        ${seriesName}
        <span class="fs-6">${element.formattedYValue}</span>
      </div>
    </div>`;
    });
    this.mouseEvent$
      .pipe(
        auditTime(50),
        filter((data) => data.id === seriesInfo.renderableSeries.id),
        tap((data) => {
          const ws = document.getElementById('workspace_base');
          const wsBounding = ws!.getBoundingClientRect();
          let left = `${data.mouseEvent.offsetX + 45}px`;
          if (data.mouseEvent.pageX + width >= wsBounding.width) {
            left = `${data.mouseEvent.pageX - (width + 20) * 2}px`;
          }
          const tooltip = document.getElementById(divTooltipId)!!;
          if (tooltip) {
            tooltip.style.top = `${data.maxYCoordinate - 60}px`;
            tooltip.style.left = left;
          }
        })
      )
      .subscribe(() => {
        seriesInfo.renderableSeries.parentSurface?.domCanvas2D.removeEventListener(
          'mousemove',
          mouse
        );
      });
    this.deleteElement$.pipe(auditTime(1000)).subscribe(() => {
      document.getElementById('TOOLTIP_HTML_TO_DELETE')?.remove();
    });
    const x = seriesInfo ? seriesInfo.formattedXValue : '';
    adjustTooltipPosition(width, height, svgAnnotation);
    const bgColor = this.themeService.isDarkTheme()
      ? CHART_COLORS.NEUTRAL_STRONG
      : CHART_COLORS_LIGHT.NEUTRAL_STRONG;
    const html = `<div style="width:${width}px" class="pl-2 d-flex flex-column text-white">
      <small class="fs-6">${x}</small>
      <small class="d-flex ${classFlex}">${info}</small>
    </div>`;
    document
      .getElementById('workspace_base')
      ?.insertAdjacentHTML(
        'afterend',
        `<div style="opacity:0;" class="position-absolute top-0" id="TOOLTIP_HTML_TO_DELETE">${html}</div>`
      );
    const tooltip = document.getElementById('TOOLTIP_HTML_TO_DELETE');
    const newHeight = tooltip ? tooltip.getBoundingClientRect().height : '100%';
    const svg = `<svg width="${width}" height="${newHeight}">
    <rect x="0" width="100%" height="100%" y="0" rx="4" style="fill: ${bgColor};"/>
    <svg width="100%">
      <foreignobject class="node" width="100%" height="100%">
      ${html}
      </foreignobject>
    </svg>
  </svg>`;
    this.deleteElement$.next();

    return svg;
  };

  buildLegend(
    placementDivId: string,
    callBack?: (series: IRenderableSeries, isChecked: boolean) => void
  ): LegendModifier {
    return new LegendModifier({
      showCheckboxes: true,
      showSeriesMarkers: true,
      showLegend: true,
      orientation: ELegendOrientation.Horizontal,
      placementDivId,
      isCheckedChangedCallback: (series, isChecked) => {
        if (callBack) callBack(series, isChecked);
      },
    });
  }

  buildTooltip(
    width: number,
    height: number,
    placementDivId: string
  ): CursorModifier {
    return new CursorModifier({
      showTooltip: true,
      showAxisLabels: false,
      crosshairStroke: CHART_COLORS.NEUTRAL_MEDIUM,
      tooltipSvgTemplate: (
        seriesInfos: SeriesInfo[],
        svgAnnotation: CursorTooltipSvgAnnotation
      ) =>
        this.tooltipSvgTemplate(
          seriesInfos,
          svgAnnotation,
          width,
          height,
          placementDivId
        ),
      placementDivId,
    });
  }

  buildIndicatorValue(
    value: IGroupIndicatorsRowsValues
  ): IGroupIndicatorsRowsValues {
    value.friendlyValue = value.value
      ? getVolumeText(this.locale, value.value, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : '-';
    return value;
  }

  formatRemuneracao(
    isExecutive: boolean,
    remuneracao: ICompanyMembersDataRemuneracaoDiretoriaConselho
  ): ICompanyMembersDataRemuneracaoDiretoriaConselho {
    const remAny = remuneracao as any;
    const label = isExecutive ? 'executivos' : 'conselheiros';
    remAny.bonus_and_others =
      remuneracao.bonus +
      remuneracao.comissoes +
      remuneracao.outros_fixos +
      remuneracao.outros_variaveis +
      remuneracao.participacao_resultados;
    remAny.remuneracao_total_tooltip = `Remuneração total aprovada aos ${label} para o ano de ${remAny.ano_referencia}.`;
    remAny.salarios_tooltip = `Remuneração em salários aprovada aos ${label} para o ano de ${remAny.ano_referencia}.`;
    remAny.beneficios_tooltip = `Total em benefícios aprovado aos ${label} para o ano de ${remAny.ano_referencia}.`;
    remAny.beneficios_acoes_tooltip = `Benefícios em ações aprovados aos ${label} para o ano de ${remAny.ano_referencia}.`;
    remAny.bonus_and_others_tooltip = `Remunerações dos tipos Bônus, Participações em Resultados, Recisões, entre outras aprovadas aos ${label} para o ano de ${remAny.ano_referencia}.`;
    EXECUTIVES_BOARD_CARDS.forEach((card) => {
      remAny[card.field] = card.formatter(this.locale, remAny[card.prevField]);
    });
    return remuneracao;
  }

  processShareholdersTableData(shareholders: IShareHoldersHistory[]) {
    const data: IShareHoldersInfo[] = [];
    const period = parseInt(
      dateToVMDate(updateDate(new Date(), 5, 'year', 'remove'))
    );

    shareholders.forEach((item) => {
      const values = item.history
        .filter((subitem) => subitem.dt_entry >= period)
        .sort((a, b) => a.dt_entry - b.dt_entry);

      if (values.length > 0) {
        data.push({
          name:
            item.shareholder_name === 'AcoesTesouraria'
              ? 'Ações Tesouraria'
              : item.shareholder_name,
          id: item.id_shareholder_company || undefined,
          mais: item,
          data: values,
        });
      }
    });

    const datesArray = new Dictionary<{
      dt_entry: number;
      dtFormatted: string;
    }>();

    let rows: any[] = [];
    const othersHolders: any[] = [];

    data.forEach((item, index) => {
      const result: any = {};
      item.data.forEach((subitem) => {
        if (!datesArray.has(subitem.dt_entry)) {
          datesArray.set(subitem.dt_entry, {
            dt_entry: subitem.dt_entry,
            dtFormatted: subitem.dt_entry.toString().slice(0, 4),
          });
        }
        const totalSharesPercentage = subitem.total_shares_percentage
          ? `${formatByTick(subitem.total_shares_percentage.toString(), 1)}%`
          : '-';
        const preferredSharesPercentage = subitem.preferred_shares_percentage
          ? `${formatByTick(
              subitem.preferred_shares_percentage.toString(),
              1
            )}%`
          : '-';
        const normalSharesPercentage = subitem.normal_shares_percentage
          ? `${formatByTick(subitem.normal_shares_percentage.toString(), 1)}%`
          : '-';
        result['date_' + subitem.dt_entry + '_total'] = totalSharesPercentage;
        result['date_' + subitem.dt_entry + '_pn'] = preferredSharesPercentage;
        result['date_' + subitem.dt_entry + '_on'] = normalSharesPercentage;
        result[
          'date_' + subitem.dt_entry + '_tooltip'
        ] = `${normalSharesPercentage} ON\n${preferredSharesPercentage} PN.`;
      });
      const infos = {
        title: item.name,
        id_shareholder_company: item.id,
        ...result,
        seq: index,
        detail: null,
        titleMsg: 'Clique para abrir os detalhes',
      };
      if (['Outros', 'Ações Tesouraria'].includes(infos.title))
        othersHolders.push(infos);
      else rows.push(infos);
    });
    rows = this._sortShareholders(rows, datesArray);
    rows.push(...this._sortShareholders(othersHolders, datesArray));
    return { rows, datesArray };
  }

  private _sortShareholders(data: any[], datesArray: any) {
    return data.sort((a, b) => {
      const date = datesArray.values()[datesArray.size() - 1].dt_entry;
      const aVal = a['date_' + date + '_total'] || '0';
      const bVal = b['date_' + date + '_total'] || '0';
      return toValue(bVal) - toValue(aVal);
    });
  }

  processSubsidiaresTableData(subsidiaries: any[]) {
    return subsidiaries.map((sub) => {
      const accounting = sub.accountingValue ?? sub.accounting;
      const dividends = sub.dividendValue ?? sub.dividends;
      const pc = `${formatByTick(sub.participation.toString())}%`;
      const cnpjFormatted = sub.cnpj ? formatCNPJ(sub.cnpj) : undefined;
      const subsidiaryOrigin = this.getSubsidiaryOrigin(sub);
      const vlContabil = getVolumeText(
        this.locale,
        accounting,
        this.volumeTwoDigits
      );
      const vlDivid = getVolumeText(
        this.locale,
        dividends,
        this.volumeTwoDigits
      );
      return {
        ...sub,
        pc,
        vlContabil,
        vlDivid,
        cnpjFormatted,
        subsidiaryOrigin,
      };
    });
  }

  private getSubsidiaryOrigin(subsidiary: any) {
    if (!subsidiary) return;
    let label = '';
    if (subsidiary.city) {
      label += subsidiary.city;
    }
    if (subsidiary.state) {
      label += label ? '/' + subsidiary.state : subsidiary.state;
    }
    if (subsidiary.country) {
      label += label ? ' - ' + subsidiary.country : subsidiary.country;
    }
    return label;
  }

  getVolumeText = (value: number) => {
    return getVolumeText(this.locale, value, this.volumeTwoDigits);
  };

  formatMember(members: any[]) {
    members.forEach((member) => {
      member.salarioFormatted = member.salario
        ? formatByTick(member.salario)
        : member.salario;
      member.dt_electionFormatted = member.dt_election
        ? formatDate(member.dt_election, true)
        : member.dt_election;
      member.cpf_numberFormatted = member.cpf_number
        ? formatCPF(member.cpf_number)
        : member.cpf_number;
    });
    return members;
  }

  createInfoWithoutDate(
    headers: IIndicatorHeaders[],
    rows: any[],
    arrayDates?: number[]
  ): any[] {
    if (!arrayDates) {
      arrayDates = [];
      headers.forEach((header: any) => {
        const dt = header.quarter.split('/');
        arrayDates!.push(parseInt(`${dt[2]}${dt[1]}${dt[0]}`));
      });
    }
    rows.forEach((row) => {
      if (row.indicators) {
        row.indicators = this.createInfoWithoutDate(
          headers,
          row.indicators,
          arrayDates
        );
      } else {
        // Processa os childrens recursivamente
        if (row.childrens && row.childrens.length) {
          row.childrens = this.createInfoWithoutDate(
            headers,
            row.childrens,
            arrayDates
          );
        }
        // Conjunto para armazenar todas as datas de referência existentes em row.values
        const existingDates = new Set(
          row.values.map((value: any) => value.dtReference)
        );
        // Loop para verificar e inserir datas que não existem em row.values
        for (const date of arrayDates!) {
          if (!existingDates.has(date)) {
            let indexToPlot = row.values.findIndex(
              (value: any) => value.dtReference > date
            );
            // Se não encontrar, indexToPlot será -1 e a data será adicionada ao final do array
            if (indexToPlot === -1) {
              indexToPlot = row.values.length;
            }
            row.values.splice(indexToPlot, 0, {
              canView: false,
              dtReference: date,
              idPeriod: 1,
              quarter: '',
              value: 0,
            });
            // Atualiza o conjunto de datas existentes
            existingDates.add(date);
          }
        }
      }
    });
    return rows;
  }
}
