import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from '@core/models';
import { IFundsService } from '@shared/components/rent/interface/rent.interface';
import { IndicatorCompanyCacheService } from '@shared/services/core/cache/indicator-company-cache.service';
import { IndicatorsTableCacheService } from '@shared/services/core/cache/indicators-table-cache.service';
import { StockCompetitorsCacheService } from '@shared/services/core/cache/stock-competitors-cache.service';
import { SubsidiariesCountriesCacheService } from '@shared/services/core/cache/subsidiaries-countries-cache.service';
import { SubsidiariesCountryCacheService } from '@shared/services/core/cache/subsidiaries-country-cache.service';
import { RestService } from '@shared/services/rest';
import {
  Observable,
  catchError,
  firstValueFrom,
  map,
  of,
  shareReplay,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FundamentalService extends RestService {
  override _url: string = 'api/trademap/v1/fundamental';
  private _stockFunds: Dictionary<any> = new Dictionary<any>();

  constructor(
    private stockCompetitorsCacheService: StockCompetitorsCacheService,
    private indicatorCompanyCacheService: IndicatorCompanyCacheService,
    private indicatorsTableCacheService: IndicatorsTableCacheService,
    private subsidiariesCountriesCacheService: SubsidiariesCountriesCacheService,
    private subsidiariesCountryCacheService: SubsidiariesCountryCacheService,
    http: HttpClient
  ) {
    super(http);
  }

  public findStockFunds(
    cdStock: string,
    strategy: 'long' | 'short'
  ): Observable<IFundsService> {
    const dictKey = `${cdStock}_${strategy}`;
    if (this._stockFunds.has(dictKey)) {
      return of({
        data: this._stockFunds.get(dictKey),
        isCache: true,
      });
    }

    return this.get<IFundsService>(
      `${cdStock}/funds?orderBy=vl_final_market&full=false&strategy=${strategy}&limit=8&offset=0`
    ).pipe(
      catchError((error) => of(this.handlerServiceError(error))),
      map((response) => {
        if (response.error || !response.data.permission.allowed) throw response;
        this._stockFunds.set(dictKey, response.data.data);
        return response.data;
      })
    );
  }

  public getCompanySectorCompetitor(cdStock: string, idExchange: number = 1) {
    const key = `${cdStock}:${idExchange}`;
    let request$: any = this.stockCompetitorsCacheService.getValue(key);
    if (!request$) {
      request$ = this.get(
        `companies/sectors/competitors?cdStock=${cdStock}&idExchange=${idExchange}`
      )
        .pipe(
          catchError((error) => of(this.handlerServiceError(error))),
          map((response) => {
            if (response.error) throw response;
            return response.data;
          })
        )
        .pipe(shareReplay(1));
      this.stockCompetitorsCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }

  public getIndicatorCompany(cdStocks: string[]) {
    const key = `${cdStocks.toString()}`;
    let request$: any = this.indicatorCompanyCacheService.getValue(key);
    if (!request$) {
      request$ = this.post(`get-indicator-company`, { cdStocks })
        .pipe(
          catchError((error) => of(this.handlerServiceError(error))),
          map((response) => {
            if (response.error) throw response;
            return response.data;
          })
        )
        .pipe(shareReplay(1));
      this.indicatorCompanyCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }

  public getIndicatorsTable(
    cdStock: string,
    idExchange: number = 1,
    idLayout: number = 1
  ) {
    const key = `${cdStock}:${idExchange}:${idLayout}`;
    let request$: any = this.indicatorsTableCacheService.getValue(key);
    if (!request$) {
      request$ = this.get(
        `indicators/table?cd_stock=${cdStock}&id_exchange=${idExchange}&id_layout=${idLayout}`
      )
        .pipe(
          catchError((error) => of(this.handlerServiceError(error))),
          map((response) => {
            if (response.error) throw response;
            return response.data;
          })
        )
        .pipe(shareReplay(1));
      this.indicatorsTableCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }

  public getSubsidiaries(cdStock: string, idExchange: number) {
    const key = `${cdStock}:${idExchange}`;
    let request$: any = this.subsidiariesCountriesCacheService.getValue(key);
    if (!request$) {
      request$ = this.get(
        `subsidiaries/countries?cdStock=${cdStock}&idExchange=${idExchange}`
      )
        .pipe(
          catchError((error) => of(this.handlerServiceError(error))),
          map((response) => {
            if (response.error) throw response;
            return response.data;
          })
        )
        .pipe(shareReplay(1));
      this.subsidiariesCountriesCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }

  public getCountrySubsidiaries(
    cdStock: string,
    idExchange: number,
    countryName: string
  ) {
    const key = `${cdStock}:${idExchange}:${countryName}`;
    let request$: any = this.subsidiariesCountryCacheService.getValue(key);
    if (!request$) {
      request$ = this.get(
        `subsidiaries/countries/${countryName}?cdStock=${cdStock}&idExchange=${idExchange}`
      )
        .pipe(
          catchError((error) => of(this.handlerServiceError(error))),
          map((response) => {
            if (response.error) throw response;
            return response.data;
          })
        )
        .pipe(shareReplay(1));
      this.subsidiariesCountryCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }
}
