<div class="w-100 bg-neutral-stronger" [style.height.px]="+height - 76">
  <h5 class="px-2">Volatilidade</h5>
  <div class="w-100 d-flex flex-column volatility-graph">
    <app-options-history-graph
      [height]="height"
      [theme]="theme"
      [refId]="historyRefId"
      [data]="historyData"
      [loading]="loadingHistory"
      (chartReady)="onHistoryChartRead()"
    ></app-options-history-graph>
    <form
      #formElement
      [formGroup]="form"
      class="row mx-0 pt-2 pb-2 w-100 d-flex justify-content-between"
      [ngClass]="{ 'dark-theme': isDarkTheme() }"
    >
      <div class="col-3">
        <h5 class="pt-1">Smile</h5>
      </div>
      <div class="col-3">
        <fla-select
          formControlName="date"
          bindLabel="twoDigitsYearLabel"
          bindValue="id"
          margin="m-0"
          [isSmall]="true"
          [list]="expirationDates"
          [searchable]="false"
          (flaChange)="loadSmileData()"
        ></fla-select>
      </div>
    </form>
    <app-smile-graph
      [height]="height"
      [theme]="theme"
      [refId]="smileRefId"
      [data]="smileData"
      [loading]="loadingSmile"
      (chartReady)="onSmileChartRead()"
    ></app-smile-graph>
  </div>
</div>
