import { Point } from '@angular/cdk/drag-drop';
import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { NumberRange } from 'scichart/Core/NumberRange';
import { CoordinateCalculatorBase } from 'scichart/Charting/Numerics/CoordinateCalculators/CoordinateCalculatorBase';
import {
  CustomAnnotation,
  ICustomAnnotationOptions,
} from 'scichart/Charting/Visuals/Annotations/CustomAnnotation';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import {
  TOOLS_ENUM,
  TOOLS_METHOD,
} from '../tiger-chart-tools/tiger-chart-tools.enum';
import {
  DrawToolConfiguration,
  TIGER_CHART_TOOL,
  TIGER_CHART_TOOL_CHILD,
} from '../tiger-chart-tools/tiger-chart-tools.interface';
import { TMouseEventData } from '../types/tiger-chart.types';
import {
  Axis,
  DrawTools,
  DrawToolsReposition,
  LineToolsOptions,
  StyleTool,
} from './draw-tools.interface';
import { EDraggingGripPoint } from 'scichart/Charting/Visuals/Annotations/AnnotationBase';
import { TYPE_RESIZE } from './draw-tools.enum';
import { Subscription } from 'rxjs';

export class CircleTools extends CustomAnnotation implements DrawTools {
  tool!: TIGER_CHART_TOOL;
  children = [];
  isDraw = true;
  startEdit = false;
  baseChart!: TWebAssemblyChart;
  isChildren = false;
  lastPointValues!: Point;
  lastMouseValues!: Point;
  lastXDiff: number = 0;
  isDrawing!: boolean;
  style: StyleTool = {};
  dictIdChildren!: string;
  configuration!: DrawToolConfiguration | undefined;
  hasElements: boolean = false;
  updateChart: boolean = false;
  width: number = 0;
  height: number = 0;
  previousVisibleRange!: NumberRange;
  isAux: boolean = false;
  endDraw: boolean = false;
  lastEventData!: TMouseEventData;
  distanceX!: number | undefined;
  distanceY!: number | undefined;
  axisSub!: Subscription;
  isWithinDataBounds: boolean | undefined = true;

  constructor(
    options: ICustomAnnotationOptions,
    private drawToolsService: DrawToolsService,
    tool: LineToolsOptions
  ) {
    super(options);
    this.isChildren = tool.isChildren;
    this.baseChart = tool.baseChart;
    this.tool = tool.tool;
    this.dictIdChildren = tool.dictIdChildren;
    this.configuration = tool.configuration;
    this.isAux = tool.isAux;
    this.lastEventData = tool.eventData;
    this.isWithinDataBounds = tool.eventData.isWithinDataBounds;
  }

  initSubscriptions() {
    //do nothing;
  }

  unsubscriber(): void {
    //do nothing;
  }

  override update(
    xCalc: CoordinateCalculatorBase,
    yCalc: CoordinateCalculatorBase,
    xCoordSvgTrans: number,
    yCoordSvgTrans: number
  ): void {
    super.update(xCalc, yCalc, xCoordSvgTrans, yCoordSvgTrans);
    if (this.style) {
      this.updateStyleTool(this.style);
    }
  }

  protected override setAnnotationBorders(
    x1: number,
    x2: number,
    y1: number,
    y2: number
  ): void {
    super.setAnnotationBorders(x1, x2, y1, y2);
    this.setStyleConfiguration();
  }

  getName() {
    return this.constructor.name;
  }
  updateDrawTool(
    eventData: TMouseEventData,
    // eslint-disable-next-line
    countStepClickTool: number,
    // eslint-disable-next-line
    reposition?: DrawToolsReposition
  ) {
    this.lastEventData = eventData;
    this.lastPointValues = eventData.pointValues!!;
    const svgElement = document.getElementById(`SVG_${this.tool.dictId!!}`);
    const circleElement = document.getElementById(
      `CIRCLE_${this.tool.dictId!!}`
    );
    const x2 = (this.tool.mouseValues!!.x - eventData.mousePoint!!.x) * -1;
    if (svgElement) {
      if (circleElement) {
        if (this.tool.codTool === `${TOOLS_ENUM.GEOMETRIC_SHAPES}_ELLIPSE`) {
          this.x2 = eventData.pointValues!!.x;
          this.y2 = eventData.pointValues!!.y;
          if (this.tool.hasBoxAux && this.tool.children) {
            const child = this.tool.children.find((c) => c.isAux)!!;
            const aux = this.baseChart.sciChartSurface.annotations.getById(
              child.dictIdChildren!!
            ) as DrawTools;
            aux.x2 = this.x2;
            aux.y2 = this.y2;
            const auxBorders = aux.getAnnotationBorders();
            const x = auxBorders.x1 - 40;
            circleElement.setAttribute('cx', `${x}`);
            circleElement.setAttribute('cy', `0`);
            circleElement.setAttribute('rx', `150`);
            circleElement.setAttribute('ry', `70`);
          }
        } else {
          circleElement.setAttribute('r', `${Math.abs(x2)}`);
          this.x2 = eventData.pointValues!!.x;
          this.y2 = eventData.pointValues!!.y;
          this.distanceX = this.x2 - this.x1;
          this.distanceY = this.y2 - this.y1;
          if (this.tool.hasBoxAux && this.tool.children) {
            const children = this.tool.children.filter((c) => c.isAux)!!;
            children.forEach((child) => {
              const aux = this.baseChart.sciChartSurface.annotations.getById(
                child.dictIdChildren!!
              ) as DrawTools;
              aux.isDrawing = true;
              aux.lastEventData = eventData;
              aux.lastPointValues = eventData.pointValues!!;
              if (child.method === TOOLS_METHOD.DRAW_LINE) {
                if (child.dragPoints!![0] != EDraggingGripPoint.x1y1) {
                  aux.x2 = eventData.pointValues!!.x;
                }
                aux.y2 = eventData.pointValues!!.y;
              }
              if (child.method === TOOLS_METHOD.BOX_ANNOTATION) {
                const borders = this.getAnnotationBorders();
                const medianX = (borders.x2 + borders.x1) / 2;
                const diffX = borders.x1 - (medianX - borders.x1);
                const diffX2 = borders.x1 + (medianX - borders.x1);
                const medianY = (borders.y2 + borders.y1) / 2;
                const diffY = borders.y1 - (medianY - borders.y1);
                const diffY2 = borders.y1 + (medianY - borders.y1);
                const { yAxis, xAxis } = this.getAxis();
                if (xAxis) {
                  aux.x1 = xAxis
                    .getCurrentCoordinateCalculator()
                    .getDataValue(diffX + diffX * 0.2);
                  aux.x2 = xAxis
                    .getCurrentCoordinateCalculator()
                    .getDataValue(diffX2 - diffX * 0.2);
                }
                if (yAxis) {
                  aux.y1 = yAxis
                    .getCurrentCoordinateCalculator()
                    .getDataValue(diffY);
                  aux.y2 = yAxis
                    .getCurrentCoordinateCalculator()
                    .getDataValue(diffY2);
                }
              }
            });
          }
        }
      }
      const draw = this.drawToolsService.drawDict.get(this.tool.dictId!!)!!;
      draw.svgString = svgElement.outerHTML!!;
    }
  }

  customClick = () => {
    //do nothing.
  };

  repositionZoomDraw(): void {
    //do nothing.
  }

  customHover = () => {
    //do nothing.
  };

  updateStyleTool(style: StyleTool) {
    const circleElement = document.getElementById(
      `CIRCLE_${this.tool.dictId!!}`
    );
    let childrenDraw!: TIGER_CHART_TOOL_CHILD;
    if (this.isChildren && this.tool.children) {
      childrenDraw = this.tool.children.find(
        (child) => child.dictIdChildren === this.dictIdChildren
      )!!;
    }
    if (circleElement) {
      if (style.color) {
        circleElement.setAttribute('stroke', style.color.rgbaHexColor);
        this.style.color = style.color;
        if (childrenDraw) {
          childrenDraw.color = style.color.rgbaHexColor;
        } else {
          this.tool.color = style.color.rgbaHexColor;
        }
      }
      if (style.backgroundColor) {
        circleElement.setAttribute('fill', style.backgroundColor.rgbaHexColor);
        this.style.backgroundColor = style.backgroundColor;
        if (childrenDraw) {
          childrenDraw.backgroundColor = style.backgroundColor.rgbaHexColor;
        } else {
          this.tool.backgroundColor = style.backgroundColor.rgbaHexColor;
        }
      }
      if (style.strokeThickness) {
        circleElement.setAttribute(
          'stroke-width',
          style.strokeThickness.toString()
        );
        this.style.strokeThickness = style.strokeThickness;
        if (childrenDraw) {
          childrenDraw.lineThickness = style.strokeThickness;
        } else {
          this.tool.lineThickness = style.strokeThickness;
        }
      }
      if (style.strokeDashArray) {
        circleElement.setAttribute(
          'stroke-dasharray',
          style.strokeDashArray[0].toString()
        );
        this.style.strokeDashArray = style.strokeDashArray;
        if (childrenDraw) {
          childrenDraw.strokeDashArray = style.strokeDashArray;
        } else {
          this.tool.strokeDashArray = style.strokeDashArray;
        }
      }
    }
  }

  updateTool(tool: TIGER_CHART_TOOL) {
    this.tool = tool;
  }

  private setStyleConfiguration() {
    if (!this.configuration || this.hasElements) {
      return;
    }
    const circleElement = document.getElementById(
      `CIRCLE_${this.tool.dictId!!}`
    );
    if (!circleElement) {
      return;
    } else {
      this.hasElements = true;
    }

    if (circleElement) {
      if (this.configuration.color) {
        circleElement.setAttribute('stroke', this.configuration.color);
      }
      if (this.configuration.backgroundColor) {
        circleElement.setAttribute('fill', this.configuration.backgroundColor);
      }
      if (this.configuration.lineThickness) {
        circleElement.setAttribute(
          'stroke-width',
          this.configuration.lineThickness.toString()
        );
      }
      if (this.configuration.strokeDashArray) {
        circleElement.setAttribute(
          'stroke-dasharray',
          this.configuration.strokeDashArray[0].toString()
        );
      }
    }
  }

  private getDataValues() {
    const { xAxis, yAxis } = this.getAxis();
    if (!xAxis || !yAxis) {
      return;
    }
    const borders = this.getAnnotationBorders();
    const y1 = yAxis.getCurrentCoordinateCalculator().getDataValue(borders.y1),
      y2 = yAxis.getCurrentCoordinateCalculator().getDataValue(borders.y2);
    const x1 = xAxis.getCurrentCoordinateCalculator().getDataValue(borders.x1),
      x2 = xAxis.getCurrentCoordinateCalculator().getDataValue(borders.x2);
    return { x1, x2, y1, y2 };
  }

  resizeSVG(
    typeResize: TYPE_RESIZE,
    childId: string,
    svgPosition?: { x1: number; x2: number; y1: number; y2: number },
    position?: { x1: number; x2: number; y1: number; y2: number }
  ) {
    if (position) {
      const svgElement = document.getElementById(`SVG_${this.tool.dictId!!}`);
      if (this.tool.codTool !== `${TOOLS_ENUM.GEOMETRIC_SHAPES}_ELLIPSE`) {
        const child = this.tool.children?.find(
          (c) => childId === c.dictIdChildren
        );
        const children = this.tool.children?.filter(
          (c) => childId != c.dictIdChildren
        );
        const circleElement = document.getElementById(
          `CIRCLE_${this.tool.dictId!!}`
        );
        if (child && child.method === TOOLS_METHOD.BOX_ANNOTATION) {
          const firstLine = children!![0];
          const secondLine = children!![1];
          const firstDraw = this.baseChart.sciChartSurface.annotations.getById(
            firstLine.dictIdChildren!!
          ) as DrawTools;
          const secondDraw = this.baseChart.sciChartSurface.annotations.getById(
            secondLine.dictIdChildren!!
          ) as DrawTools;
          firstDraw.isDrawing = true;
          secondDraw.isDrawing = true;
          const centerX = (position.x2 + position.x1) / 2;
          const centerY = (position.y2 + position.y1) / 2;
          const secondDrawYDiff = firstDraw.y1 - secondDraw.y2;
          const secondDrawXDiff = secondDraw.x1 - secondDraw.x2;
          firstDraw.x1 = centerX;
          firstDraw.x2 = position.x2;
          firstDraw.y1 = centerY;
          firstDraw.y2 = centerY - secondDrawYDiff;
          secondDraw.x1 = centerX;
          secondDraw.x2 = secondDraw.x1 - secondDrawXDiff;
          secondDraw.y1 = centerY;
          secondDraw.y2 = centerY - secondDrawYDiff;
          this.y1 = firstDraw.y1;
          this.y2 = firstDraw.y1;
          this.x1 = firstDraw.x1;
        }
        if (child && child.method === TOOLS_METHOD.DRAW_LINE) {
          if (child.dragPoints!![0] === EDraggingGripPoint.x1y1) {
            children!!.sort((c) => {
              return c.method === TOOLS_METHOD.DRAW_LINE ? 1 : -1;
            });
            if (!this.distanceX) {
              this.distanceX = this.x2 - position.x1;
            }
            if (!this.distanceY) {
              this.distanceY = this.y2 - position.y1;
            }
            this.y1 = position.y1;
            this.y2 = position.y1;
            this.x1 = position.x1;
            children?.forEach((c) => {
              const drawC = this.baseChart.sciChartSurface.annotations.getById(
                c.dictIdChildren!!
              ) as DrawTools;
              drawC.isDrawing = true;
              if (c.method === TOOLS_METHOD.DRAW_LINE) {
                drawC.x1 = position.x1;
                drawC.x2 = position.x1 + this.distanceX!!;
                drawC.y1 = position.y1 + this.distanceY!!;
                drawC.y2 = position.y1 + this.distanceY!!;
              }
              if (c.method === TOOLS_METHOD.BOX_ANNOTATION) {
                drawC.x1 = position.x1 - this.distanceX!!;
                drawC.x2 = position.x1 + this.distanceX!!;
                const borders = this.getAnnotationBorders();
                const medianY = (borders.y2 + borders.y1) / 2;
                const diffY = borders.y1 - (medianY - borders.y1);
                const diffY2 = borders.y1 + (medianY - borders.y1);
                const { yAxis } = this.getAxis();
                if (yAxis) {
                  drawC.y1 = yAxis
                    .getCurrentCoordinateCalculator()
                    .getDataValue(diffY);
                  drawC.y2 = yAxis
                    .getCurrentCoordinateCalculator()
                    .getDataValue(diffY2);
                }
              }
            });
          }
          if (child.dragPoints!![0] === EDraggingGripPoint.x2y2) {
            if (circleElement) {
              svgPosition!!.x2 >= 0 &&
                circleElement.setAttribute('r', `${svgPosition!!.x2}`);
              const box = children?.find(
                (c) => c.method === TOOLS_METHOD.BOX_ANNOTATION
              );
              const drawC = this.baseChart.sciChartSurface.annotations.getById(
                box!!.dictIdChildren!!
              ) as DrawTools;
              drawC.isDrawing = true;
              this.x2 = position.x2;
              this.y2 = position.y2;
              const borders = this.getAnnotationBorders();
              const medianX = (borders.x2 + borders.x1) / 2;
              const diffX = borders.x1 - (medianX - borders.x1);
              const diffX2 = borders.x1 + (medianX - borders.x1);
              const medianY = (borders.y2 + borders.y1) / 2;
              const diffY = borders.y1 - (medianY - borders.y1);
              const diffY2 = borders.y1 + (medianY - borders.y1);
              const { yAxis, xAxis } = this.getAxis();
              if (xAxis) {
                drawC.x1 = xAxis
                  .getCurrentCoordinateCalculator()
                  .getDataValue(diffX);
                drawC.x2 = xAxis
                  .getCurrentCoordinateCalculator()
                  .getDataValue(diffX2);
              }
              if (yAxis) {
                drawC.y1 = yAxis
                  .getCurrentCoordinateCalculator()
                  .getDataValue(diffY);
                drawC.y2 = yAxis
                  .getCurrentCoordinateCalculator()
                  .getDataValue(diffY2);
              }
              this.distanceX = undefined;
              this.distanceY = undefined;
            }
          }
        }
      }
      if (svgElement) {
        this.svgString = svgElement.outerHTML!!;
        this.notifyPropertyChanged('svgString');
      }
    }
  }

  private getAxis(): Axis {
    const yAxis = this.baseChart.sciChartSurface.yAxes.getById(this.yAxisId);
    const xAxis = this.baseChart.sciChartSurface.xAxes.getById(this.xAxisId);
    if (!yAxis || !xAxis) {
      return { xAxis: undefined, yAxis: undefined };
    }
    try {
      yAxis.getCurrentCoordinateCalculator();
      xAxis.getCurrentCoordinateCalculator();
    } catch (e: any) {
      return { xAxis: undefined, yAxis: undefined };
    }
    return { yAxis, xAxis };
  }
}
