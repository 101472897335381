import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { GridISelectRow, RocketGridService } from '@shared/rocket-grid';
import { Observable, Subject } from 'rxjs';
import { CONFIG_ACTIONS } from '../../enum/stock-table-views.enum';

@Component({
  selector: 'app-stock-table-view-context',
  templateUrl: './stock-table-view-context.component.html',
  styles: [
    `
      .context-container {
        width: 210px;
        padding: 10px;
        z-index: 99;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockTableViewsContextComponent implements OnDestroy {
  public readonly CONFIG_ACTIONS = CONFIG_ACTIONS;
  public componentRef!: string;
  public display: boolean = false;
  public removeStockIsEnable: boolean = false;
  public showDetail: boolean = true;
  public position!: { top: string; left: string };
  public stock!: any;
  public callback = new Function();
  public isMoversList: boolean = false;
  public stockListId: number = 0;
  public options: any[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  public displayMoversIndexes: boolean = false;
  public expandIcon: 'expand_more' | 'expand_less' = 'expand_more';

  private _preventCloseEvent: boolean = false;
  private _onClose = new Subject<void>();

  get onClose$(): Observable<void> {
    return this._onClose.asObservable();
  }

  constructor(private _rocketGridService: RocketGridService) {}

  ngOnDestroy(): void {
    this._onClose.unsubscribe();
  }

  @HostListener('window:click')
  private close(): void {
    if (!this.display || this._preventCloseEvent) return;
    this._onClose.next();
    this.display = false;
  }

  public actionHandler(type: CONFIG_ACTIONS): void {
    if (this.isMoversList) return;
    if (type === CONFIG_ACTIONS.ADD) {
      const params = this._addRowParams();
      this._rocketGridService.setOpenAddRow(this.componentRef, params);
      return;
    }
    if (type === CONFIG_ACTIONS.REMOVE && this.stock) {
      this._rocketGridService.setRemoveStockFromSameLists({
        idList: this.stockListId,
        rowData: this.stock,
      });
      return;
    }

    if (type === CONFIG_ACTIONS.EDIT) {
      this.callback({ type: CONFIG_ACTIONS.EDIT, enable: true });
      return;
    }
  }

  private _addRowParams(): GridISelectRow | undefined {
    if (this.stock) {
      return {
        rowIndex: this.stock.stock_position,
        rowData: this.stock,
        refComponent: this.componentRef,
      };
    }
    return undefined;
  }
}
