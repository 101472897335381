import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { STOCK_TABLE_ELEMENT_IDS } from '../constants/stock-table.constants';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';
import { BaseContextMenuComponent } from '@shared/rocket-components/base-context-menu';
import { auditTime, Subject } from 'rxjs';

export enum StockTableContextMenuEvent {
  Auction = 'AUCTION',
  Helper = 'HELPER',
  Export = 'EXPORT',
  SetInvisibleColumn = 'SETINVISIBLECOLUMN',
  AddStock = 'ADDSTOCK',
  RemoveStock = 'REMOVESTOCK',
  ShowEdit = 'SHOWEDIT',
  ConfigColumns = 'CONFIGCOLUMNS',
  ResetHeaders = 'RESETHEADERS',
}

@Component({
  selector: 'app-stock-table-context-menu',
  templateUrl: './stock-table-context-menu.component.html',
  styles: [
    `
      .grid-config {
        width: 200px;
        padding: 8px;
        z-index: 99;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockTableContextMenuComponent
  extends BaseContextMenuComponent
  implements OnInit, OnDestroy
{
  stockListId?: number;
  isRemoveRow!: boolean;
  isVisible!: boolean;
  isShowEdit!: boolean;
  showColumnsAuction!: boolean;
  isNotListPersonal!: boolean;
  isListAuction!: boolean;
  disableResetButtons: boolean = true;
  isTour: boolean = false;
  refComponent!: string;
  colorIconAuction!: string;
  expandIcon: 'expand_more' | 'expand_less' = 'expand_more';
  parent_id!: string;
  keyHeader!: string | null;
  readonly ELEMENT_IDS = STOCK_TABLE_ELEMENT_IDS;
  private onFocusOut$ = new Subject<void>();
  public blockInvisibleColumn = false;

  constructor(
    elementRef: ElementRef,
    cdr: ChangeDetectorRef,
    private contextMenuService: ContextMenuService
  ) {
    super(elementRef, cdr);
    this.onFocusOut$.pipe(auditTime(50)).subscribe(() => {
      this.onFocusOut();
    });
  }

  ngOnDestroy() {
    this.onFocusOut$.unsubscribe();
  }

  static openContextMenu(
    contextMenuService: ContextMenuService,
    parent_id: string,
    event: { clientX: number; clientY: number },
    isTour: boolean,
    stockListId: number,
    showColumnsAuction: boolean,
    isListAuction: boolean,
    isNotListPersonal: boolean,
    defaultHeadersConfig: boolean | undefined,
    keyHeader: string | null,
    isShowEdit: boolean,
    isRemoveRow: boolean
  ) {
    if (isTour) {
      const domRect = document
        .getElementsByTagName('app-stock-table')[0]
        .getClientRects()[0];
      event.clientX = domRect.x;
      event.clientY = domRect.y + 100;
    }
    contextMenuService.openContextMenu(parent_id, {
      type: 'StockTableContextMenuComponent',
      event: event,
      configurationData: {
        isTour,
        stockListId,
        showColumnsAuction,
        isListAuction,
        isNotListPersonal,
        defaultHeadersConfig,
        keyHeader,
        isShowEdit,
        isRemoveRow,
      },
    });
  }

  private emitEvent(action: StockTableContextMenuEvent) {
    this.contextMenuService.emitEvent(this.parent_id, action);
    this.onFocusOut$.next();
  }

  onFocusOut() {
    this.contextMenuService.closeContextMenu(this.parent_id);
  }

  ngOnInit(): void {
    this.colorIconAuction = this.showColumnsAuction
      ? 'text-brand-primary'
      : 'text-neutral-medium';
    this.blockInvisibleColumn =
      this.keyHeader === 'cd_stock' || this.keyHeader === 'situacao';
  }

  setInvisibleColumn(): void {
    this.emitEvent(StockTableContextMenuEvent.SetInvisibleColumn);
  }

  addStock(): void {
    this.emitEvent(StockTableContextMenuEvent.AddStock);
  }

  removeStock(): void {
    this.emitEvent(StockTableContextMenuEvent.RemoveStock);
  }

  changeAction(): void {
    this.emitEvent(StockTableContextMenuEvent.Auction);
  }

  showHelp(): void {
    this.emitEvent(StockTableContextMenuEvent.Helper);
  }

  exportData(): void {
    this.emitEvent(StockTableContextMenuEvent.Export);
  }

  editGrid(): void {
    this.emitEvent(StockTableContextMenuEvent.ShowEdit);
  }

  configColumns(): void {
    this.emitEvent(StockTableContextMenuEvent.ConfigColumns);
  }

  resetHeaders(): void {
    this.emitEvent(StockTableContextMenuEvent.ResetHeaders);
  }
}
