import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'fla-card-custom',
  templateUrl: './card-custom.component.html',
})
export class FlaCardCustomComponent {
  @ViewChild(TemplateRef) content!: TemplateRef<any>;
  @Input('css') customClass: string = '';
}
