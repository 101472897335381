<rocket-modal>
  <rocket-modal-header>
    <h3 class="modal-title">Criar lista</h3>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <form [formGroup]="newListForm">
      <div class="list-name">
        <fla-input
          fla-focus
          css="margin-bottom"
          formControlName="name"
          placeholder="Nome da lista"
          requiredText="Preencha o campo."
          type="text"
          [maxLength]="30"
          [max]="30"
          [hint]="newListForm.get('name')?.value.length + ' / 30'"
          [autoComplete]="false"
        >
        </fla-input>
      </div>
      <div class="mt-2 mb-3">
        <fla-input
          css="margin-bottom"
          formControlName="search"
          placeholder="Buscar"
          decidePosition="all"
          type="text"
          [autoComplete]="false"
        >
          <div class="prefix">
            <fla-icon name="search" size="icon-size-nano"></fla-icon>
          </div>
          <div class="suffix">
            <rocket-button
              type="icon"
              title="Configurações de listagem"
              (rocketClick)="openConfig()"
            >
              <fla-icon
                css="cursor-pointer"
                name="filter_list"
                size="icon-size-sm"
              ></fla-icon>
            </rocket-button>
          </div>
        </fla-input>
      </div>
    </form>
    <app-most-search
      [isSearching]="isSearch"
      [searchedStock]="searchedStock"
      [listStocksSelected]="[]"
      [noData]="noData"
      (selected)="selectStock($event)"
    ></app-most-search>
  </app-rocket-modal-body>
  <rocket-modal-footer>
    <rocket-button
      type="fill"
      label="Cancelar"
      css="mr-4"
      (rocketClick)="onClose()"
    ></rocket-button>
    <rocket-button
      label="Salvar"
      [loading]="isLoading"
      [isDisabled]="!newListForm.valid || !listStocksSelected.length"
      (rocketClick)="createdList()"
    ></rocket-button>
  </rocket-modal-footer>
</rocket-modal>
