<a
  type="button"
  class="nav-link py-0 m-0"
  (click)="handleMoveWorkspaceContent()"
  [tooltip]="lockTooltip"
>
  <fla-icon
    size="icon-size-tiny"
    css="p-0 pl-1 arrow"
    [name]="lockWorkspace ? 'lock' : 'lock_open'"
  ></fla-icon>
</a>
