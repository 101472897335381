import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

enum REPURCHASE_FIELDS {
  APROVE_DT = 'approveDateFormatted',
  START_DT = 'startedAtFormatted',
  END_DT = 'endedAtFormatted',
  QTY_TYPE = 'quantityType',
  QTY = 'quantityFormatted',
}
@Component({
  selector: 'app-repurchase-card',
  templateUrl: './repurchase-card.component.html',
  styleUrls: ['./repurchase-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepurchaseCardComponent {
  @Input() repushaseInfo!: any;

  public repurchaseInfos = [
    {
      title: 'Aprovado em',
      field: REPURCHASE_FIELDS.APROVE_DT,
    },
    {
      title: 'Data início',
      field: REPURCHASE_FIELDS.START_DT,
    },
    {
      title: 'Data fim',
      field: REPURCHASE_FIELDS.END_DT,
    },
    {
      title: 'Tipo',
      field: REPURCHASE_FIELDS.QTY_TYPE,
    },
    {
      title: 'Quantidade',
      field: REPURCHASE_FIELDS.QTY,
    },
  ];
}
