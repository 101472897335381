import { StockListService } from '@shared/services/api/trademap/V10/stock-list.service';
import { StockService } from '@services/api/trademap/v1/stock.service';
import { Subject, takeUntil } from 'rxjs';
import {
  IAllStockListSimple,
  IListStockDB,
  StockListItemsWithView,
} from 'src/app/core/interface';
import { UpdatesList } from './updates-list';
import { ToastService } from '@shared/services';
import { ListStocksService } from '@shared/services/core/list-stocks/list-stocks.service';
import { IStockListItemsRow } from '../interfaces/stock-table.interfaces';

export interface IUpdateStocksInList {
  list: IStockListItemsRow[];
  isError: boolean;
}

export class AddStock {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private listSelected!: IListStockDB;
  private refComp!: string;
  private callback!: (params: IUpdateStocksInList) => void;
  private updatesList!: any;

  constructor(
    private stockService: StockService,
    private stockListService: StockListService,
    private listStocksService: ListStocksService,
    private toastService: ToastService
  ) {}

  private onDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public setNewStock(
    listSelected: IListStockDB,
    stocks: string[],
    refComp: string,
    callback: (params: IUpdateStocksInList) => void,
    insertIndex: number | undefined,
    removedStocks: string[]
  ): void {
    this.listSelected = listSelected;
    this.refComp = refComp;
    this.callback = callback;
    let stockList: string[] = [];
    listSelected.stocks.forEach(
      (stock) => stock.idUpdate && stockList.push(stock.idUpdate)
    );

    if (insertIndex === undefined) stockList = [...stockList, ...stocks];
    else stockList.splice(insertIndex + 1, 0, ...stocks);

    if (removedStocks.length)
      stockList = stockList.filter((stock) => !removedStocks.includes(stock));
    this.updatesList = new UpdatesList(
      this.listStocksService,
      this.stockListService,
      this.toastService
    );

    this.updatesList.updateListStocksService(
      listSelected.list,
      stockList,
      (list: IAllStockListSimple, error: boolean) =>
        this.updateListAfterSaveChanges(list, error)
    );
  }

  private updateListAfterSaveChanges = (
    list: IAllStockListSimple,
    isError: boolean
  ) => {
    if (isError) {
      this.callback({ list: [], isError });
      return;
    }
    this.stockService
      .getStockListItemsWithView(null, list.id_stock_list, this.refComp)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: StockListItemsWithView) => {
        this.listSelected.stocks = result.stockListItemsRow;
        this.listSelected.stocksMapped = result.stocksMapped;
        this.updatesList.updateListStocksIndexedDB(this.listSelected);
        this.onDestroy();
        this.callback({ list: result.stockListItemsRow, isError });
      });
  };
}
