<rocket-modal>
  <rocket-modal-header [hideCloseButton]="true">
    <div class="d-flex position-relative w-100">
      <h3 class="fs-2">Gerenciamento de Risco</h3>
      <fla-icon
        name="info"
        css="text-neutral-medium ml-3 mt-2"
        [tooltip]="tooltipHeader"
        [tooltipIsHtml]="true"
      ></fla-icon>
      <ng-container *ngIf="isLockChange">
        <section class="m-auto mr-5">
          <fla-icon name="lock" css="text-feedback-warning"></fla-icon>
          <span class="text-feedback-warning fw-bold fs-6 mr-3">
            Bloqueado para alterações hoje
          </span>
        </section>
      </ng-container>
      <section class="pt-1 position-absolute right-0">
        <rocket-button type="icon" (rocketClick)="btnClose()">
          <fla-icon name="close"></fla-icon>
        </rocket-button>
      </section>
    </div>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <ng-container *ngIf="!loading; else loadingRef">
      <div class="mb-5 mx-0">
        <div
          class="mb-2"
          [appReplaceTextHtml]="[modoLabel]"
          [listVariables]="['[MODO]']"
        >
          No [MODO], bloquear aumento de posição quando:
        </div>
        <form
          [formGroup]="form"
          class="overflow-auto pr-1 pb-2"
          [ngClass]="{ 'opacity-intense': isLockChange }"
          [style.height.%]="82"
        >
          <div
            *ngFor="let field of fields"
            class="py-2 d-flex justify-content-between position-relative"
          >
            <div class="d-flex">
              <app-check
                [formControlName]="field.formCheck"
                css="align-items-center"
                (flaChange)="disabledOrEnabledInput(field.formCheck)"
              ></app-check>
              <div
                class="ml-3"
                [ngClass]="{
                  'opacity-intense': !form.get(field.formCheck)?.value,
                  'cursor-pointer': !isLockChange
                }"
                (click)="selectCheckBox(field.formCheck)"
              >
                <span class="fw-bold">{{ field.title }}</span>
                <fla-icon
                  name="info"
                  css="text-neutral-medium ml-3"
                  [tooltip]="field.description"
                ></fla-icon>
              </div>
            </div>
            <div
              [ngClass]="{
                'opacity-intense': !form.get(field.formCheck)?.value
              }"
            >
              <app-input-count
                [formControlName]="field.formInput"
                [isModal]="true"
                [enableScrollEvent]="true"
                [isSmall]="true"
                [min]="field.min"
                [incrementValue]="field.incrementValue"
                [minLength]="field.minLength"
                [symbolPrefix]="field.symbolPrefix"
                [mask]="field.mask"
                [css]="getErrorsCss(field.formInput, field.css)"
              >
              </app-input-count>
            </div>
            <span
              class="fs-6 position-absolute text-feedback-error right-0 message-error"
            >
              {{ getErrors(field.formInput) }}
            </span>
          </div>
        </form>
        <div class="mt-3 row">
          <ng-container *ngIf="!isConfirmLock; else confirmLockChange">
            <div
              class="col-8 d-flex align-items-center"
              [ngClass]="{ 'opacity-intense': isLockChange }"
            >
              <div class="fs-5 fw-bold">
                Você pode optar por bloquear alterações do seu gerenciamento até
                o fim do dia.
              </div>
              <fla-icon
                name="info"
                css="text-neutral-medium"
                [tooltip]="tooltipBockEdit"
                [tooltipIsHtml]="true"
              ></fla-icon>
            </div>
            <div class="col-8 d-flex justify-content-between">
              <rocket-button
                type="fill"
                label="Bloquear alterações hoje"
                css="text-black bg-brand-support-secondary border border-brand-support-secondary p-1"
                [isDisabled]="
                  isLockChange || form.invalid || checkOptionHasSelected()
                "
                (rocketClick)="alertConfirmLock()"
              >
                <fla-icon [name]="iconLock" css="text-black"></fla-icon>
              </rocket-button>
              <rocket-button
                label="Salvar"
                [isDisabled]="form.invalid || isLockChange"
                (rocketClick)="save()"
              ></rocket-button>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </app-rocket-modal-body>
</rocket-modal>

<ng-template #confirmLockChange>
  <div class="row m-0 bg-neutral-strong p-2 round">
    <div class="col-8">
      <div class="fs-4 text-feedback-warning fw-bold">Atenção</div>
      <div class="fs-6 mt-1">
        Caso confirme,
        <span
          class="text-feedback-warning fw-bold"
          [appReplaceTextHtml]="[modoLabel]"
          [listVariables]="['[MODO]']"
        >
          suas restrições para o [MODO] serão salvas e apenas podem ser
          alteradas no próximo dia de negociação.
        </span>
        Deseja seguir?
      </div>
    </div>
    <div class="col-8">
      <div class="w-75 d-grid ml-auto">
        <rocket-button
          type="fill"
          label="Sim, seguir"
          css="py-1 px-5 text-dark bg-brand-support-secondary border border-brand-support-secondary mb-1"
          (rocketClick)="lockChanges()"
        >
          <fla-icon name="lock_open" css="text-dark"></fla-icon>
        </rocket-button>
        <rocket-button
          type="fill"
          label="Cancelar"
          css="py-1 px-5 border border-brand-primary"
          (rocketClick)="alertConfirmLock()"
        ></rocket-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingRef>
  <div [style.height.px]="600">
    <app-loading></app-loading>
  </div>
</ng-template>
