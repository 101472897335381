import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';
import { OptionsComponents } from '../component-list';

export const MARKET_RESUME_COMPONENT: any = (options?: OptionsComponents) => {
  return {
    name: 'Resumo de mercado',
    id: COMPONENTS_NAMES_ENUM.MARKET_RESUME,
    icon: 'newspaper',
    cod: 'MARKET_RESUME',
    data: null,
    close: true,
    fullscreen: true,
    link: true,
    unitOfMeasurement: '%',
    minWidth: '775px',
    height: options?.height ?? 100,
    width: options?.width ?? 50,
    left: 'calc(15% + 5px)',
    top: '0px',
    label: 'Resumo de mercado',
    noPublished: false,
    hasBorder: false,
    divider: false,
    iconSvg: 'ARTICLE'
  }
};
