import { Injectable } from '@angular/core';
import { BrowserStorageBase } from '@services/core/storage/browser-storage-base';
import {
  TABS,
  swingOrDayTradeFieldFilter,
} from '../constants/orders-history.const';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { HttpClient } from '@angular/common/http';
import { RocketModalService } from '@shared/rocket-components/components/index';
import { deepClone } from '@shared/rocket-components/utils';
import { Observable, Subject } from 'rxjs';
import { ModalOrdersTabSettingsComponent } from '../modals/modal-orders-tab-settings/modal-orders-tab-settings.component';
import {
  CustodyPositionFilter,
  ITabHandler,
} from '../interfaces/orders-history.interface';
import { HomeService } from '@modules/home/service/home.service';
import { Dictionary } from '@core/models';
import { CustodyStopOrderService } from '@shared/tiger-chart/custody-stop-orders/custody-stop-order.service';
import { IPosition } from '@shared/components/stock-chart/interface/stock-chart.interface';
import {
  isIdBrokerConsolidated,
  isSimulatorOrLeague,
} from '@shared/constants/simulator-league.constant';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { sharedSessionStorage } from '@shared/services/core/storage/shared-session.storage';
export const ORDER_TAB_SELECTED = 'ORDER_TAB_SELECTED';

@Injectable({
  providedIn: 'root',
})
export class OrdersTabService extends CustomPreferencesService {
  override storage = new BrowserStorageBase(sharedSessionStorage);

  private _headerTabsByRef: Dictionary<any> = new Dictionary<any>();
  private _canHiddenMoreTabs: boolean = true;
  private _refComponentToUpdate!: string;

  public headerTabs: any[] = [];

  public LOCAL_STORAGE_ORDERS_TAB = {
    ORDERS_TAB_ORDENATION: 'ORDERS_TAB_ORDENATION',
  };

  private _tabSelected = new Dictionary<any>();

  private _ordersTabInfs$: Subject<ITabHandler> = new Subject<ITabHandler>();

  public defaultHeaderTabs: Array<any> = [
    /*TABS.INTRADIA,
    TABS.SWINGTRADING,*/
    TABS.POSITION,
    TABS.ORDER_OPEN,
    TABS.ORDER_EXEC,
    TABS.ORDER_CLOSE,
    TABS.ORDER_TOTAL,
    TABS.ALERTS,
  ];

  public defaultHeaderKeys = [
    /*TABS.INTRADIA.key,
    TABS.SWINGTRADING.key,
    TABS.POSITION.key,*/
    TABS.ORDER_OPEN.key,
    TABS.ORDER_EXEC.key,
    TABS.ORDER_CLOSE.key,
    TABS.ORDER_TOTAL.key,
    TABS.ALERTS.key,
  ];

  get ordersTab$(): Observable<ITabHandler> {
    return this._ordersTabInfs$.asObservable();
  }

  get tabs() {
    return this._tabSelected;
  }

  getTabSelected(id: string) {
    return this._tabSelected.get(id);
  }

  setTabSelected(id: string, tab: any) {
    this._tabSelected.set(id, tab);
    this.storage.setItem(ORDER_TAB_SELECTED, tab);
  }

  getTabActiveIndex(id: string) {
    return this.currentOrdersTab.findIndex(
      (item: any) => this._tabSelected.get(id).key === item.key
    );
  }

  get currentOrdersTab() {
    return this.headerTabs;
  }

  set enableHiddenTab(enableOption: boolean) {
    this._canHiddenMoreTabs = enableOption;
  }

  get enableHiddenTab() {
    return this._canHiddenMoreTabs;
  }

  set tabsByComponentRef(params: any) {
    this._headerTabsByRef.delete(params.ref);
    this._headerTabsByRef.set(params.ref, params.tabs);
  }

  get tabsByComponentRef() {
    return this._headerTabsByRef;
  }

  constructor(
    private http: HttpClient,
    private _rocketModalService: RocketModalService,
    private _homeService: HomeService,
    private _custodyStopOrderService: CustodyStopOrderService
  ) {
    super(http);
  }

  public saveTabHeadersOrdenation(componentMetadata: any): void {
    this._homeService.updateMeta(componentMetadata);
  }

  public resetOrdersTab = (
    componentMetadata: any,
    headerKeys?: string[]
  ): void => {
    componentMetadata.metadata.component.headerTabs = deepClone(
      headerKeys || this.defaultHeaderKeys
    );

    this.saveTabHeadersOrdenation(componentMetadata);
  };

  openModalPreferences(refComponent: string, headerTabs: any) {
    return this._rocketModalService.open(ModalOrdersTabSettingsComponent, {
      data: {
        tabs: headerTabs,
        qtyDefaultTabs: this.defaultHeaderTabs.length + 1,
      },
    }).afterDismissed;
  }

  public handleListOrdersPreferences(refComponent: string): void {
    this._refComponentToUpdate = refComponent;
    const tabs = this.tabsByComponentRef.get(refComponent);
    const ref$ = this._rocketModalService.open(
      ModalOrdersTabSettingsComponent,
      {
        data: {
          tabs,
          qtyDefaultTabs: this.defaultHeaderTabs.length + 1,
        },
      }
    );

    ref$.afterDismissed.subscribe((tabs: string[] | any) => {
      if (tabs?.closed) return;
      this.changeTabsHeader(tabs, this._refComponentToUpdate);
    });
  }

  public changeTabsHeader(tabs: string[], refComponent: string): void {
    const tabsVisible: any[] = [];
    tabs.forEach((item: any) => {
      if (item === TABS.INTRADIA.key || item === TABS.SWINGTRADING.key) {
        tabsVisible.unshift(Object.assign(TABS[item]));
      } else {
        tabsVisible.push(Object.assign(TABS[item]));
      }
    });
    this._ordersTabInfs$.next({
      tabs: tabsVisible,
      refComponent: refComponent,
    });
  }

  public buildHeaderTabs(tabsKey: string[]): any[] {
    try {
      const tabs: string[] = [];
      tabsKey.forEach((tabKey: string) => {
        tabs.push(TABS[tabKey]);
      });
      return tabs;
    } catch (error) {
      return this.defaultHeaderTabs;
    }
  }

  custodyChannelFilter = (
    custodyData: IPosition,
    id_broker: number,
    filterSwingOrDayTradeParams: CustodyPositionFilter | null = null
  ) => {
    if (!custodyData) return false;
    const isSameBroker = id_broker === +custodyData?.id_broker;
    /**
     * Se é um evento do mesmo id_broker ou esta com o somatorio ligado e o evento nao é do simulador ou liga
     */

    if (isNullOrUndefined(filterSwingOrDayTradeParams)) {
      return (
        custodyData &&
        (isSameBroker ||
          (isIdBrokerConsolidated(id_broker) &&
            !isSimulatorOrLeague(+custodyData?.id_broker)))
      );
    }

    const { isSwing, isQttyFinal } = filterSwingOrDayTradeParams!;
    /**
     * ainda nao sabemos o que fazer com os que vierem zerados
     */
    //let validity = this.validityDataInfo(custodyData, isSwing);
    const condition =
      //validity ||
      Boolean(
        JSON.parse(
          custodyData[swingOrDayTradeFieldFilter(isSwing, isQttyFinal)]
        )
      );
    return (
      custodyData &&
      condition &&
      (isSameBroker ||
        (isIdBrokerConsolidated(id_broker) &&
          !isSimulatorOrLeague(+custodyData?.id_broker)))
    );
  };

  validityDataInfo(data: any): boolean {
    const { custodyQtty, abrQtty, plValue } =
      this._custodyStopOrderService.getCustodyInfos(
        data as unknown as IPosition
      );
    const condition = this.removeZeroQttyData(custodyQtty, abrQtty, plValue);
    return condition;
  }

  removeZeroQttyData(qtty: number, abrQtty: number, plValue: number): boolean {
    return qtty === 0 && abrQtty === 0 && plValue === 0;
  }

  public tourTabs(componentRef: string, closedTour: boolean = false): void {
    this._ordersTabInfs$.next({
      refComponent: componentRef,
      isTour: true,
      closedTour,
      tabs: closedTour ? [] : this._tourTabs(),
    });
  }

  private _tourTabs(): any[] {
    const intradia = TABS.INTRADIA;
    const swing = TABS.SWINGTRADING;
    intradia.isHidden = false;
    swing.isHidden = false;
    return [intradia, swing, ...this.defaultHeaderTabs];
  }
}
