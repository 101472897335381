import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import {
  TData,
  TTooltipIndicatorValue,
  TUpdateData,
} from '@shared/tiger-chart/types/tiger-chart.types';
import {
  BaseIndicator,
  TMAPoints,
  TTigerChartIndicatorCreateOptions,
  TTigerChartIndicatorMovingAverageColorOptions,
  TTigerChartIndicatorParameter,
  TTigerChartIndicatorRenderSeriesConfig,
} from '../indicators.types';
import {
  TIGER_INDICATORS_ENUM,
  TIGER_INDICATOR_PARAMETER_TYPE,
  TIGER_INDICATOR_SOURCES,
} from '@shared/tiger-chart/enum/tiger-chart.enum';
import { debounceTime, Subject, takeUntil, tap } from 'rxjs';
import { TalibService } from '@shared/tiger-chart/services/talib.service';
import { XyDataSeries } from 'scichart/Charting/Model/XyDataSeries';
import { FastLineRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastLineRenderableSeries';
import { TIGER_INDICATOR_PRECISIONS } from '../indicators.constants';
import { setDefaultConfiguration } from '../indicators.functions';
import {
  TIGER_INDICATOR_SOURCE_LIST,
  TIGER_INDICATOR_SOURCE_OBJECT,
} from '@shared/tiger-chart/constants/tiger-chart.constants';
import {
  BoxAnnotation,
  CategoryCoordinateCalculator,
  EAnnotationLayer,
  ECoordinateMode,
  Point,
} from 'scichart';
import { ColorsBaseService } from '@shared/tiger-chart/tiger-chart-tools/toolbar-draw/colors-base.service';

export class TigerChartIndicatorMovingAverageColorBase
  implements BaseIndicator
{
  protected firstLineDataSeries!: XyDataSeries;
  protected secondLineDataSeries!: XyDataSeries;
  protected firstRenderableSeries!: BaseRenderableSeries;
  protected secondRenderableSeries!: BaseRenderableSeries;
  protected options!: TTigerChartIndicatorMovingAverageColorOptions;
  protected data: TData;
  protected precision: number = 2;
  protected points: TMAPoints;
  protected points2: TMAPoints;
  protected highlightColor = CHART_COLORS.BRAND_SUPPORT_SECONDARY;
  protected highlightOpacity = 20;
  protected lineColor = CHART_COLORS.MULTIBROKER_SELL;
  protected lineThickness = 1;
  protected lineColor2 = CHART_COLORS.MULTIBROKER_BUY;
  protected lineThickness2 = 1;
  protected timePeriod: number = 9;
  protected timePeriod2: number = 21;
  protected offset: number = 0;
  protected offset2: number = 0;
  protected source: TIGER_INDICATOR_SOURCES = TIGER_INDICATOR_SOURCES.CLOSE;
  protected source2: TIGER_INDICATOR_SOURCES = TIGER_INDICATOR_SOURCES.CLOSE;
  private baseChart!: TWebAssemblyChart;
  lineNumber!: string;
  type: TIGER_INDICATORS_ENUM = TIGER_INDICATORS_ENUM.MOVING_AVERAGE_COLOR;
  yAxisId!: string;
  xAxisId!: string;
  settings: TTigerChartIndicatorParameter[] = [];
  styles: TTigerChartIndicatorParameter[] = [];
  renderSeriesConfig: TTigerChartIndicatorRenderSeriesConfig[] = [];
  onChange = new Subject<null>();
  service: TalibService;
  firstLineId = '';
  secondLineId = '';
  isNewOnChart = false;
  mainLineId = '';
  protected idCross = 'MOVING_AVERAGE_COLOR_CROSS';
  private addCross$ = new Subject<{ yValues1: number[]; yValues2: number[] }>();
  private _onDestroy = new Subject<void>();
  private highlightActive: boolean = true;

  get isVisible(): boolean {
    return (
      this.firstRenderableSeries.isVisible ||
      this.secondRenderableSeries.isVisible
    );
  }

  set isVisible(visible: boolean) {
    this.firstRenderableSeries.isVisible = visible;
    this.secondRenderableSeries.isVisible = visible;
    this.highlightActive = visible;
    this.updateCrossColors();
  }

  get propertiesText(): string {
    return `(${this.timePeriod}, ${
      TIGER_INDICATOR_SOURCE_OBJECT[this.source].label
    }, ${this.offset}) (${this.timePeriod2}, ${
      TIGER_INDICATOR_SOURCE_OBJECT[this.source2].label
    }, ${this.offset2})`;
  }

  constructor(
    options: TTigerChartIndicatorMovingAverageColorOptions,
    private colorsBaseService: ColorsBaseService
  ) {
    this.initializeAddCross();
    this.data = options.data;
    this.service = options.service;
    this.precision = options.tickSize;
    this.options = options;
    if (options.timePeriod) this.timePeriod = options.timePeriod;
    if (options.timePeriod2) this.timePeriod2 = options.timePeriod2;
    if (options.source) this.source = options.source;
    if (options.source2) this.source2 = options.source2;
    if (options.offset) this.offset = options.offset;
    if (options.offset2) this.offset2 = options.offset2;
    if (options.highlightColor) this.highlightColor = options.highlightColor;
    if (options.highlightOpacity)
      this.highlightOpacity = options.highlightOpacity;
    if (options.lineColor) this.lineColor = options.lineColor;
    if (options.lineColor2) this.lineColor2 = options.lineColor2;
    if (options.precision) this.precision = options.precision;
    if (options.lineThickness) this.lineThickness = options.lineThickness;
    if (options.lineThickness2) this.lineThickness2 = options.lineThickness2;
    this.firstLineId = `${this.type}-first-line-moving-avarage-color`;
    this.secondLineId = `${this.type}-second-line-moving-avarage-color`;

    this.points = {
      output: [],
    };
    this.points2 = {
      output: [],
    };
    this.renderSeriesConfig = [
      {
        label: 'Destaque',
        id: 'highlightColor',
        color: this.highlightColor,
        propertyColor: 'highlightColor',
        opacity: this.highlightOpacity,
        propertyOpacity: 'highlightOpacity',
        thickness: 0,
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        dontShowTickness: true,
        showOpacity: true,
        staticColors: true,
        max: 100,
        min: 1,
        hideCheckbox: true,
      },
      {
        title: '1º Média Móvel',
        label: 'Linha',
        id: this.firstLineId,
        color: this.lineColor,
        propertyColor: 'lineColor',
        thickness: this.lineThickness,
        propertyThickness: 'lineThickness',
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        staticColors: true,
        hideCheckbox: true,
      },
      {
        title: '2º Média Móvel',
        label: 'Linha',
        id: this.secondLineId,
        color: this.lineColor2,
        propertyColor: 'lineColor2',
        thickness: this.lineThickness2,
        propertyThickness: 'lineThickness2',
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        staticColors: true,
        hideCheckbox: true,
      },
    ];
    this.settings = [
      {
        title: '1º Média Móvel',
        label: 'Período',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property:
          'timePeriod' as keyof TigerChartIndicatorMovingAverageColorBase,
        min: 1,
      },
      {
        label: 'Fonte',
        type: TIGER_INDICATOR_PARAMETER_TYPE.SELECTION,
        values: TIGER_INDICATOR_SOURCE_LIST,
        property: 'source' as keyof TigerChartIndicatorMovingAverageColorBase,
      },
      {
        label: 'Deslocamento',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property: 'offset' as keyof TigerChartIndicatorMovingAverageColorBase,
      },
      {
        title: '2º Média Móvel',
        label: 'Período',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property:
          'timePeriod2' as keyof TigerChartIndicatorMovingAverageColorBase,
        min: 1,
      },
      {
        label: 'Fonte',
        type: TIGER_INDICATOR_PARAMETER_TYPE.SELECTION,
        values: TIGER_INDICATOR_SOURCE_LIST,
        property: 'source2' as keyof TigerChartIndicatorMovingAverageColorBase,
      },
      {
        label: 'Deslocamento',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property: 'offset2' as keyof TigerChartIndicatorMovingAverageColorBase,
      },
    ];
    this.styles = [
      {
        type: TIGER_INDICATOR_PARAMETER_TYPE.SELECTION,
        property:
          'precision' as keyof TigerChartIndicatorMovingAverageColorBase,
        label: 'Precisão',
        values: TIGER_INDICATOR_PRECISIONS,
      },
    ];
    this.lineNumber = options.lineNumber;
  }

  create(options: TTigerChartIndicatorCreateOptions): BaseRenderableSeries[] {
    this.baseChart = options.base;
    this.deleteCross();
    this.xAxisId = options.xAxisId;
    const { yValues1, yValues2 } = this.getYValuesDataSeries();
    const xValues = this.data.id_point;
    this.firstLineDataSeries = new XyDataSeries(this.baseChart.wasmContext, {
      yValues: yValues1,
      xValues,
    });
    this.secondLineDataSeries = new XyDataSeries(this.baseChart.wasmContext, {
      yValues: yValues2,
      xValues,
    });
    this.firstLineDataSeries.isSorted = true;
    this.secondLineDataSeries.isSorted = true;
    const firstLineConfig = this.renderSeriesConfig.find(
      (config) => config.id == this.firstLineId
    );
    const secondLineConfig = this.renderSeriesConfig.find(
      (config) => config.id == this.secondLineId
    );
    this.firstRenderableSeries = new FastLineRenderableSeries(
      this.baseChart.wasmContext,
      {
        strokeThickness: firstLineConfig!.thickness,
        stroke: firstLineConfig!.color,
        dataSeries: this.firstLineDataSeries,
        yAxisId: this.yAxisId,
        id: `${firstLineConfig!.id}_${this.lineNumber}`,
        xAxisId: options.xAxisId,
      }
    );
    this.secondRenderableSeries = new FastLineRenderableSeries(
      this.baseChart.wasmContext,
      {
        strokeThickness: secondLineConfig!.thickness,
        stroke: secondLineConfig!.color,
        dataSeries: this.secondLineDataSeries,
        yAxisId: this.yAxisId,
        id: `${secondLineConfig!.id}_${this.lineNumber}`,
        xAxisId: options.xAxisId,
      }
    );
    this.addCross$.next({ yValues1, yValues2 });
    return [this.firstRenderableSeries, this.secondRenderableSeries];
  }

  private getCrossColor() {
    const rgbColor = this.colorsBaseService.rgba2rgb(
      this.colorsBaseService.hex2Rgba(this.highlightColor)
    );
    return this.colorsBaseService.rgba2hex(
      this.colorsBaseService.setOpacityRgb(
        rgbColor,
        this.highlightOpacity / 100
      )
    );
  }

  private updateCrossColors() {
    const color = this.getCrossColor();
    this.getCross().forEach((annotation) => {
      annotation.fill = color;
      annotation.isHidden = !this.highlightActive;
    });
  }

  private addCross(dataA: number[], dataB: number[]) {
    const color = this.getCrossColor();
    const xAxis = this.baseChart.sciChartSurface.xAxes.getById(this.xAxisId);
    const calculator =
      xAxis.getCurrentCoordinateCalculator() as CategoryCoordinateCalculator;
    for (let i = 0; i < this.data.id_point.length; i++) {
      const d1 = calculator.transformDataToIndex(this.data.id_point[i]);
      const d2 = calculator.transformDataToIndex(this.data.id_point[i + 1]);
      const intersection = this.getIntersection(
        new Point(d1, dataA[i]),
        new Point(d2, dataA[i + 1]),
        new Point(d1, dataB[i]),
        new Point(d2, dataB[i + 1])
      );
      if (intersection) {
        const index = parseFloat(intersection.x.toFixed(2));
        const annotation = new BoxAnnotation({
          fill: color,
          strokeThickness: 0,
          xCoordinateMode: ECoordinateMode.DataValue,
          x1: index - 0.2,
          x2: index + 0.2,
          yCoordinateMode: ECoordinateMode.Relative,
          y1: 0.0,
          y2: 1.0,
          xAxisId: this.xAxisId,
          id: this.idCross,
          isHidden:
            !this.firstRenderableSeries.isVisible ||
            !this.secondRenderableSeries.isVisible ||
            !this.highlightActive,
          annotationLayer: EAnnotationLayer.BelowChart,
        });
        this.baseChart.sciChartSurface.annotations.add(annotation);
      }
    }
  }

  getIntersection(
    start1: Point,
    end1: Point,
    start2: Point,
    end2: Point
  ): Point | null {
    const intersect = this.doIntersect(start1, end1, start2, end2);

    if (!intersect) {
      return null;
    }

    const a1 = end1.y - start1.y;
    const b1 = start1.x - end1.x;
    const c1 = a1 * start1.x + b1 * start1.y;

    const a2 = end2.y - start2.y;
    const b2 = start2.x - end2.x;
    const c2 = a2 * start2.x + b2 * start2.y;

    const determinant = a1 * b2 - a2 * b1;

    if (determinant === 0) {
      // As retas são paralelas
      return null;
    } else {
      const dx = (b2 * c1 - b1 * c2) / determinant;
      const dy = (a1 * c2 - a2 * c1) / determinant;
      return { x: dx, y: dy };
    }
  }

  /// 1 - Sentido horário
  /// 2 - Sentido anti-horário
  /// 0 - Colinear
  private orientation(p: Point, q: Point, r: Point): number {
    const val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);
    if (val === 0) return 0; // colinear
    return val > 0 ? 1 : 2; // horário ou anti-horário
  }

  /// Verifica se os segmentos de reta se interceptam
  private doIntersect(
    start1: Point,
    end1: Point,
    start2: Point,
    end2: Point
  ): boolean {
    const o1 = this.orientation(start1, end1, start2);
    const o2 = this.orientation(start1, end1, end2);
    const o3 = this.orientation(start2, end2, start1);
    const o4 = this.orientation(start2, end2, end1);

    // Caso Geral
    if (o1 !== o2 && o3 !== o4) {
      return true;
    }

    // Casos específicos
    if (o1 === 0 && this.onSegment(start1, start2, end1)) return true;
    if (o2 === 0 && this.onSegment(start1, end2, end1)) return true;
    if (o3 === 0 && this.onSegment(start2, start1, end2)) return true;
    if (o4 === 0 && this.onSegment(start2, end1, end2)) return true;

    return false;
  }

  /// Verifica se o ponto q está no segmento pr
  private onSegment(p: Point, q: Point, r: Point): boolean {
    if (
      q.x <= Math.max(p.x, r.x) &&
      q.x >= Math.min(p.x, r.x) &&
      q.y <= Math.max(p.y, r.y) &&
      q.y >= Math.min(p.y, r.y)
    ) {
      return true;
    }
    return false;
  }

  private deleteCross() {
    const cross = this.getCross();
    for (let i = 0; i < cross.length; i++) {
      const annotation = cross[i];
      this.baseChart.sciChartSurface.annotations.remove(annotation);
    }
  }

  private getCross() {
    return this.baseChart.sciChartSurface.annotations
      .asArray()
      .filter(
        (annotation) => annotation.id === this.idCross
      ) as BoxAnnotation[];
  }

  updatePoints(): void {
    //do nothing.
  }

  append(xValue: number, data: TUpdateData, fullData: TData) {
    this.data = fullData;
    this.updatePoints();
    const { yValues1, yValues2 } = this.getYValuesDataSeries();
    this.firstLineDataSeries.append(xValue, yValues1[yValues1.length - 1]);
    this.secondLineDataSeries.append(xValue, yValues2[yValues2.length - 1]);
  }

  private getYValuesDataSeries() {
    const yValues1 = this.points.output.slice(
      0,
      this.points.output.length - this.timePeriod
    );
    const yValues2 = this.points2.output.slice(
      0,
      this.points2.output.length - this.timePeriod2
    );
    return { yValues1, yValues2 };
  }

  insertRange(xValues: number[], fullData: TData): void {
    this.data = fullData;
    this.updatePoints();
    this.firstLineDataSeries.clear();
    this.secondLineDataSeries.clear();
    const { yValues1, yValues2 } = this.getYValuesDataSeries();
    this.firstLineDataSeries.appendRange(this.data.id_point, yValues1);
    this.secondLineDataSeries.appendRange(this.data.id_point, yValues2);
    this.addCross$.next({ yValues1, yValues2 });
  }

  changeVisibility(): void {
    this.firstRenderableSeries.isVisible =
      !this.firstRenderableSeries.isVisible;
    this.secondRenderableSeries.isVisible =
      !this.secondRenderableSeries.isVisible;
    this.highlightActive = !this.highlightActive;
    this.updateCrossColors();
  }

  update(index: number, data: TUpdateData, fullData: TData) {
    const count1 = this.firstLineDataSeries.count();
    const count2 = this.secondLineDataSeries.count();
    if (index > count1 && index > count2) {
      return;
    }
    this.data = fullData;
    this.updatePoints();
    const { yValues1, yValues2 } = this.getYValuesDataSeries();
    this.firstLineDataSeries.update(index, yValues1[index]);
    this.secondLineDataSeries.update(index, yValues2[index]);
    this.addCross$.next({ yValues1, yValues2 });
  }

  private removeRange(
    dataSeries: XyDataSeries,
    yValues: number[],
    maCount: number
  ) {
    dataSeries.removeRange(yValues.length - 1, maCount - yValues.length);
  }

  private appendRange(
    dataSeries: XyDataSeries,
    yValues: number[],
    offset: number
  ) {
    if (offset < 0) {
      const xOffsetValues: number[] = [];
      const yOffsetValues = yValues.slice(0, this.offset * -1);
      const xValues = dataSeries.getNativeXValues();
      const diff = xValues.get(1) - xValues.get(0);
      for (let index = this.offset * -1; index > 0; index--) {
        xOffsetValues.push(xValues.get(0) - diff * index);
      }
      dataSeries.appendRange(xOffsetValues, yOffsetValues);
    }
  }

  private updateOrAppendSeries(
    dataSeries: XyDataSeries,
    yValues: number[],
    index: number,
    point: number,
    offsetLine: number
  ) {
    if (offsetLine) {
      if (offsetLine < 0) {
        return;
      }
      const offset = +offsetLine;
      if (index < offset) {
        dataSeries.update(index, NaN);
      }
      if (index + offset >= yValues.length) {
        dataSeries.append(index + offset, point);
        return;
      }
      dataSeries.update(index + offset, point);
      return;
    }
    dataSeries.update(index, point);
  }

  updateSettings(): void {
    const { yValues1, yValues2 } = this.getYValuesDataSeries();
    const s = this.baseChart.sciChartSurface.suspendUpdates(); // This locks the surface and prevents further drawing
    const maCount1 = this.firstLineDataSeries.count();
    const maCount2 = this.firstLineDataSeries.count();
    if (maCount1 > yValues1.length) {
      this.removeRange(this.firstLineDataSeries, yValues1, maCount1);
    }
    if (maCount2 > yValues2.length) {
      this.removeRange(this.secondLineDataSeries, yValues2, maCount2);
    }
    try {
      this.appendRange(this.firstLineDataSeries, yValues1, this.offset);
      this.appendRange(this.secondLineDataSeries, yValues2, this.offset2);
      yValues1.forEach((point, index) => {
        this.updateOrAppendSeries(
          this.firstLineDataSeries,
          yValues1,
          index,
          point,
          this.offset
        );
      });
      yValues2.forEach((point, index) => {
        this.updateOrAppendSeries(
          this.secondLineDataSeries,
          yValues2,
          index,
          point,
          this.offset2
        );
      });
    } finally {
      s.resume();
    }
    this.onChange.next(null);
  }

  updateStyles(
    baseChart: TWebAssemblyChart,
    config: TTigerChartIndicatorRenderSeriesConfig
  ): void {
    switch (config.id) {
      case 'highlightColor': {
        this.highlightOpacity = config.opacity ?? this.highlightOpacity;
        this.highlightColor = config.color;
        this.highlightActive = config.active;
        this.updateCrossColors();
        break;
      }
      case this.firstLineId: {
        this.firstRenderableSeries.stroke = config.color;
        this.firstRenderableSeries.strokeThickness = config.thickness;
        this.firstRenderableSeries.isVisible = config.active;
        const firstLineRenderSeries =
          baseChart.sciChartSurface.renderableSeries.getById(
            `${config.id}_${this.lineNumber}`
          );
        firstLineRenderSeries.stroke = config.color;
        firstLineRenderSeries.strokeThickness = config.thickness;
        firstLineRenderSeries.isVisible = config.active;
        break;
      }
      case this.secondLineId: {
        this.secondRenderableSeries.stroke = config.color;
        this.secondRenderableSeries.strokeThickness = config.thickness;
        this.secondRenderableSeries.isVisible = config.active;
        const secondLineRenderSeries =
          baseChart.sciChartSurface.renderableSeries.getById(
            `${config.id}_${this.lineNumber}`
          );
        secondLineRenderSeries.stroke = config.color;
        secondLineRenderSeries.strokeThickness = config.thickness;
        secondLineRenderSeries.isVisible = config.active;
        break;
      }
      default: {
        break;
      }
    }
    this.onChange.next(null);
  }

  delete() {
    this.baseChart.sciChartSurface.renderableSeries.remove(
      this.firstRenderableSeries
    );
    this.baseChart.sciChartSurface.renderableSeries.remove(
      this.secondRenderableSeries
    );
    this.deleteCross();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  setValue(property: keyof this, value: any) {
    const prop = this[property];
    if (typeof this[property] == 'number') {
      this[property] = +value as unknown as typeof prop;
    } else {
      this[property] = value;
    }
    this.updateSettings();
  }

  getValuesByIndex(index?: number): TTooltipIndicatorValue[] {
    const precision = this.precision;
    const firstLineValues = this.firstLineDataSeries.getNativeYValues();
    const firstLineValue = {
      value: firstLineValues.get(index || firstLineValues.size() - 1),
      precision,
      color: this.renderSeriesConfig[1].color,
    };
    const secondLineValues = this.secondLineDataSeries.getNativeYValues();
    const secondLineValue = {
      value: secondLineValues.get(index || secondLineValues.size() - 1),
      precision,
      color: this.renderSeriesConfig[2].color,
    };
    return [firstLineValue, secondLineValue];
  }

  resetConfiguration(): void {
    setDefaultConfiguration(
      this,
      this.baseChart,
      this.options,
      this.settings,
      this.renderSeriesConfig,
      this.styles,
      this.type
    );
  }

  private initializeAddCross() {
    this.addCross$
      .pipe(
        takeUntil(this._onDestroy),
        debounceTime(100),
        tap(() => {
          this.deleteCross();
        })
      )
      .subscribe(({ yValues1, yValues2 }) => {
        this.addCross(yValues1, yValues2);
      });
  }
}
