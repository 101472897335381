import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ModalAvisoComponent } from '@shared/components/modal-aviso/modal-aviso.component';
import { ORDER_CONFIRM_ACTIONS_ENUM } from '@shared/enum/buyOrSell.enum';
import { RocketModalService } from '@shared/rocket-components/components/modal/service/modal.service';
import { OrderTokenService } from '@shared/rocket-components/modal-order-token/order-token.service';
import { MultibrokerService } from '@shared/services/core/multibroker/multibroker.service';
import { debounceTime, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-reset-all-positions',
  templateUrl: './reset-all-positions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host(app-reset-all-positions) {
        .height {
          height: 42px;
          .slide-bar {
            height: 32px;
            width: calc(100% - 10px);
            border-radius: 8px;
            &.block {
              z-index: 99;
            }
            .btn-side {
              width: 44px;
              height: 24px;
            }
          }
        }
      }
    `,
  ],
})
export class ResetAllPositionsComponent implements OnInit, OnDestroy {
  @Input() disableCancelAllPositions: boolean = true;
  @Input() css: string = '';
  @Output() resetAllPosition: EventEmitter<boolean> = new EventEmitter<boolean>(
    false
  );
  public transform = {
    x: 0,
    y: 0,
  };
  private onDestroy$: Subject<void> = new Subject<void>();
  private clearResetAllPositions$: Subject<void> = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private rocketModalService: RocketModalService,
    private orderToken: OrderTokenService,
    private multiBrokerService: MultibrokerService
  ) {}

  ngOnInit(): void {
    this.clearResetAllPositions$
      .pipe(takeUntil(this.onDestroy$), debounceTime(200))
      .subscribe(() => this.resetAllPosition.emit(false));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public resetBtn(): void {
    const account = this.multiBrokerService.getAccountSelected();
    this.handleAllPositionsAction(account?.id_broker);
  }

  handleAllPositionsAction(idBroker: number): void {
    const verifyToken = this.orderToken.hasTradingToken(idBroker);
    if (!verifyToken.hasTrading) {
      this.orderToken.showTokenModal(() =>
        setTimeout(() => {
          this.handleAllPositionsAction(idBroker);
        }, 100)
      );
      return;
    }
    this.displayModalToConfirmAction();
  }

  private displayModalToConfirmAction() {
    const ref = this.rocketModalService.open(ModalAvisoComponent, {
      keyboard: false,
      data: {
        title: 'Aviso',
        text: `Tem certeza que deseja zerar todas as suas posições?`,
        confirmeButton: 'Sim',
        cancelButton: 'Não',
        typeAskAgain: 'Não perguntar novamente',
        askAgain: false,
        showButtons: true,
        isSecondModal: false,
        positionActionEnum: ORDER_CONFIRM_ACTIONS_ENUM.RESET,
        stock: undefined,
        showPriceStock: false,
      },
    });

    const modalSub$ = ref.afterDismissed.subscribe((res: any) => {
      if (res && res.value) {
        this.sendResetPositions();
      }
      modalSub$.unsubscribe();
    });
  }

  private sendResetPositions(): void {
    this.resetAllPosition.emit(true);
    this.clearResetAllPositions$.next();
  }
}
