import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { ContextMenu } from '@shared/components/popup-root/context-menu.interface';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';
import { BaseContextMenuComponent } from '@shared/rocket-components/base-context-menu';

export enum TimesTradesContextMenuEvent {
  Pressure = 'PRESSURE',
  Details = 'DETAILS',
  Milliseconds = 'MILLISECONDS',
  Tour = 'TOUR',
  TradeByOrder = 'TRADE_BY_ORDER',
}
@Component({
  selector: 'app-times-trades-context-menu',
  template: `
    <div
      tabindex="-1"
      class="round bg-neutral-strong position-absolute"
      [style.width]="'210px'"
      [style.padding]="'10px'"
      [style.top.px]="position.top"
      [style.left.px]="position.left"
      [style.z-index]="9999"
      [style.display]="isTour ? 'none' : 'block'"
      (mouseleave)="onFocusOut()"
    >
      <div class="list-group list-group-flush">
        <div class="header-context-menu">
          <span>Negócios</span>
        </div>
        <div
          id="TIMES_TRADES_CONTEXT_TOGGLE_FEATURES"
          class="d-flex flex-column"
        >
          <button
            type="button"
            class="list-group-item list-group-item-action context-item-button"
            (click)="onConnectTradeByOrder()"
          >
            <fla-icon
              size="icon-size-tiny"
              css="position-absolute left-0 text-neutral-medium fs-4"
              [name]="!useTradeByOrders ? 'visibility_off' : 'visibility'"
            ></fla-icon>
            Por Ordens
          </button>
          <button
            type="button"
            class="list-group-item list-group-item-action context-item-button"
            (click)="onConnectTradeByOrder()"
          >
            <fla-icon
              size="icon-size-tiny"
              css="position-absolute left-0 text-neutral-medium fs-4"
              [name]="useTradeByOrders ? 'visibility_off' : 'visibility'"
            ></fla-icon>
            Por Negócios
          </button>
          <hr class="separator-context-menu" />
          <button
            type="button"
            class="list-group-item list-group-item-action context-item-button"
            (click)="onPressure()"
          >
            <fla-icon
              size="icon-size-tiny"
              css="position-absolute left-0 text-neutral-medium fs-4"
              [name]="!pressureVisible ? 'visibility_off' : 'visibility'"
            ></fla-icon>
            Pressão
          </button>
          <button
            type="button"
            class="list-group-item list-group-item-action context-item-button"
            (click)="onDetails()"
          >
            <fla-icon
              size="icon-size-tiny"
              css="position-absolute left-0 text-neutral-medium fs-4"
              [name]="!detailsVisible ? 'visibility_off' : 'visibility'"
            ></fla-icon>
            Detalhes do ativo
          </button>
          <button
            type="button"
            class="list-group-item list-group-item-action context-item-button"
            (click)="onMilliseconds()"
          >
            <fla-icon
              size="icon-size-tiny"
              css="position-absolute left-0 text-neutral-medium fs-4"
              [name]="!milliseconds ? 'visibility_off' : 'visibility'"
            ></fla-icon>
            Milissegundos
          </button>
        </div>
        <hr class="separator-context-menu" />
        <div class="d-flex flex-column">
          <button
            type="button"
            class="list-group-item list-group-item-action border-none fs-6 context-item-button"
            disabled
          >
            <fla-icon
              size="icon-size-tiny"
              css="position-absolute left-0 text-neutral-medium fs-4"
              name="refresh"
            ></fla-icon>
            Restaurar
          </button>
          <button
            type="button"
            class="list-group-item list-group-item-action border-none fs-6 context-item-button"
            (click)="onTour()"
          >
            <fla-icon
              size="icon-size-tiny"
              css="position-absolute left-0 text-neutral-medium fs-4"
              name="help_outline"
            ></fla-icon>
            Ajuda
          </button>
        </div>
      </div>
    </div>
  `,
})
export class TimesTradesContextMenuComponent
  extends BaseContextMenuComponent
  implements ContextMenu
{
  pressureVisible: boolean = false;
  milliseconds: boolean = false;
  useTradeByOrders: boolean = false;
  detailsVisible: boolean = false;
  isTour: boolean = false;
  parent_id!: string;

  constructor(
    elementRef: ElementRef,
    cdr: ChangeDetectorRef,
    private contextMenuService: ContextMenuService
  ) {
    super(elementRef, cdr);
  }

  static openContextMenu(
    contextMenuService: ContextMenuService,
    parent_id: string,
    event: { clientX: number; clientY: number },
    isTour: boolean,
    pressureVisible: boolean,
    milliseconds: boolean,
    detailsVisible: boolean,
    useTradeByOrders: boolean
  ) {
    if (isTour) {
      const domRect = document
        .getElementsByTagName('app-times-trades')[0]
        .getClientRects()[0];
      event.clientX = domRect.x;
      event.clientY = domRect.y + 100;
    }
    contextMenuService.openContextMenu(parent_id, {
      type: 'TimesTradesContextMenuComponent',
      event: event,
      configurationData: {
        isTour,
        detailsVisible,
        milliseconds,
        pressureVisible,
        useTradeByOrders,
      },
    });
  }

  private emitEvent(action: TimesTradesContextMenuEvent) {
    this.contextMenuService.emitEvent(this.parent_id, action);
  }

  onFocusOut() {
    this.contextMenuService.closeContextMenu(this.parent_id);
  }

  onPressure() {
    this.pressureVisible = !this.pressureVisible;
    this.emitEvent(TimesTradesContextMenuEvent.Pressure);
  }

  onDetails() {
    this.detailsVisible = !this.detailsVisible;
    this.emitEvent(TimesTradesContextMenuEvent.Details);
  }

  onMilliseconds() {
    this.milliseconds = !this.milliseconds;
    this.emitEvent(TimesTradesContextMenuEvent.Milliseconds);
  }

  onConnectTradeByOrder() {
    this.useTradeByOrders = !this.useTradeByOrders;
    this.emitEvent(TimesTradesContextMenuEvent.TradeByOrder);
  }

  onTour() {
    this.emitEvent(TimesTradesContextMenuEvent.Tour);
    this.onFocusOut();
  }
}
