import { Pipe, PipeTransform } from '@angular/core';
import { formatByTick, isCrypto } from 'src/app/utils/utils.functions';

@Pipe({
  name: 'tickSize',
})
export class FormatTickSize implements PipeTransform {
  private getTickSize(
    value: number,
    tickSizeDenominator: number,
    dsType: string | number
  ): number {
    if (isCrypto(dsType)) {
      return +value > 1 ? 0 : tickSizeDenominator ?? 8;
    }
    return tickSizeDenominator;
  }

  transform(
    value: number,
    tickSizeDenominator: number,
    dsType: string | number
  ): string {
    return formatByTick(
      value,
      this.getTickSize(value, tickSizeDenominator, dsType)
    );
  }
}
