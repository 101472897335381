import { Injectable } from '@angular/core';
import { ListStocksPreferences } from '../custom-preferences/list-stocks/list-stocks-preferences.services';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { EVENTS } from './list-stocks.const';
import { deepClone } from '@shared/rocket-components/utils';
import { TAddStock } from './types';

@Injectable({
  providedIn: 'root',
})
export class ListStocksService {
  public static _instance: ListStocksService;
  private _stockListChanges$ = new ReplaySubject<any>(1);
  private _stockListEvents$ = new Subject<{
    type: string;
    detail: any;
  }>();
  private _loadedStockLists$ = new BehaviorSubject<boolean>(false);
  private _allStockLists!: any;
  private events = EVENTS;

  addItemStockList$ = new Subject<TAddStock>();

  get stockListChanges() {
    return this._stockListChanges$.asObservable();
  }
  get stockListEvents() {
    return this._stockListEvents$.asObservable();
  }
  get onLoadedStockLists() {
    return this._loadedStockLists$.asObservable();
  }
  get allStockLists() {
    return this._allStockLists || this.preferenceStockLists();
  }

  constructor(private listStocksPreferences: ListStocksPreferences) {
    if (ListStocksService._instance == null) {
      ListStocksService._instance = this;
    } else {
      return ListStocksService._instance;
    }
  }

  insertAllStockList = (hash: any) => {
    this.updatePreference(hash);
    this._stockListChanges$.next(hash);
    if (!this._loadedStockLists$.getValue()) this._loadedStockLists$.next(true);
  };

  preferenceStockLists = () => {
    const list = this.listStocksPreferences.allStockLists;
    this._allStockLists = list;
    return list;
  };

  insertStockList = (list: any) => {
    const keys = Object.keys(this.allStockLists);
    const lastKey = keys[keys.length - 1];
    const last = this.allStockLists[lastKey];
    const key = Object.keys(list)[0];
    list[key].id = last?.id ?? 1 + 1;
    const all = {
      ...this.allStockLists,
      ...list,
    };
    this.updatePreference(all, {
      type: this.events.LIST_STOCKS_ADD,
      detail: list,
    });
  };

  getStockList(idStockList: string) {
    const list = this.allStockLists;
    return list[idStockList];
  }

  removeStockList(idStockList: string) {
    const list = this.allStockLists;
    const deleted = deepClone(list[idStockList]);
    delete list[idStockList];
    this.updatePreference(list, {
      type: this.events.LIST_STOCKS_DELETE,
      detail: deleted,
    });
  }

  updateStockList(param: { idStockList: string; value: any }) {
    const list = this.allStockLists;
    list[param.idStockList] = param.value;
    this.updatePreference(list, {
      type: this.events.LIST_STOCKS_UPDATE,
      detail: param.value,
    });
  }

  private updatePreference(
    list: any,
    dispatchInfo?: { type: string; detail: any }
  ) {
    this._allStockLists = list;
    this.listStocksPreferences.allStockLists = list;
    dispatchInfo && this._stockListEvents$.next(dispatchInfo);
  }
}
