import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ISearchStock, IWorkSpaceComponet } from '@core/interface';
import { HomeService } from '@modules/home/service/home.service';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { getAuctionInfos, isCrypto } from 'src/app/utils/utils.functions';
import { AtPriceHelper } from './at-price.helper';
import { IntrojsService } from '@core/introjs/introjs.service';
import { RocketCreateComponentService } from '@shared/rocket-components/services';
import { IStockAuctionInfos } from '@shared/interfaces/stock-auction-infos.interface';
import { ActivatedRoute } from '@angular/router';
import { RocketStreamRead } from '@shared/channel/rx-event';
import { isWebViewContext } from 'src/app/desktop/integration.service';
import { ContextMenuService } from '../popup-root/context-menu.service';
import { AtPriceContextMenuEvent } from './parts/at-price-context-menu.component';
import { Subscription } from 'rxjs';
import { ContextMenuEventPayload } from '../popup-root/context-menu-event.model';

@Component({
  selector: 'app-at-price',
  templateUrl: './at-price.component.html',
  styleUrls: ['./at-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AtPriceComponent
  extends AtPriceHelper
  implements OnDestroy, OnChanges
{
  @ViewChild('atPrice', { static: true }) atPrice!: ElementRef;
  @Input() component!: IWorkSpaceComponet;
  @Input() refComponent!: string;
  @Input() width!: number;
  @Input() height: number = 100;
  preco_ultimo!: number;
  tick_size_denominator: any;
  variacao_dia: any;
  preco_minimo: any;
  preco_maximo: any;
  volume: any;
  arrow_hex: any;
  isDesktop = false;
  private _stockPrices: any = {};
  private quoteCheetahStream!: RocketStreamRead;
  private qouteParamsMultibroker!: SubscribeParam | undefined;
  private _contextMenu$!: Subscription;

  viewTypeSelected = 'GRAPHIC';
  public stockAuctionInfos!: IStockAuctionInfos;

  public viewTypes = [
    { label: 'Principal', code: 'GRAPHIC', active: true },
    { label: 'Agressores', code: 'AGGRESSORS', active: false },
    { label: 'Saldo', code: 'AGGRESSORS_BALANCE', active: false },
    { label: 'Resumo', code: 'RESUME', active: false },
    // { label: 'Tabela', code: 'TABLE', active: false },
  ];

  constructor(
    private homeService: HomeService,
    protected override createComponent: RocketCreateComponentService,
    protected override introJsService: IntrojsService,
    protected _quoteChannel: QuoteChannel,
    private cdr: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    protected override contextMenuService: ContextMenuService
  ) {
    super(createComponent, introJsService, activatedRoute, contextMenuService);
    this.isDesktop = isWebViewContext();
  }

  get headerOptions() {
    return this.component.metadata?.headerOptions;
  }

  get stockSelected(): ISearchStock {
    return this.component?.metadata?.component?.stock ?? {};
  }

  set stockPrices(data: any) {
    Object.keys(data).forEach((key: string) => {
      if (!data[key]) return;
      this._stockPrices[key] = data[key];
    });

    this.tick_size_denominator =
      data.tick_size_denominator || this.tick_size_denominator;
    this.preco_ultimo = data.preco_ultimo || this.preco_ultimo;
    this.variacao_dia = data.variacao_dia || this.variacao_dia;
    this.preco_minimo = data.preco_minimo || this.preco_minimo;
    this.preco_maximo = data.preco_maximo || this.preco_maximo;
    this.volume = data.volume || this.volume;
    this.arrow_hex = data.arrow_hex || this.arrow_hex;
  }

  get stockPrices() {
    return this._stockPrices;
  }

  get metadataConfiguration() {
    return (
      this.component.metadata?.configuration ?? {
        showDetail: true,
      }
    );
  }

  set metadataConfiguration(data: any) {
    const config = { ...this.metadataConfiguration, ...data };
    this.component.metadata.configuration = config;
    this.updateMetadata();
  }

  get itemsSubscribed() {
    return `${this.stockSelected.cd_stock}:${this.stockSelected.id_exchange}`;
  }

  protected override initComponent(component: any): void {
    if (component) this.component = component;
    this.componentInit();

    this._contextMenu$ = this.contextMenuService
      .listenActionEvent<AtPriceContextMenuEvent>(this.componentId)
      .subscribe((payload) => this.onConfigUpdateHandler(payload));
  }

  private _processInitialHeight() {
    if (this.component.metadata.componentResize?.height)
      return this.component.metadata.componentResize?.height;

    const { layout } = this.component.metadata;
    const height = parseInt(layout.height.replace('%', ''));

    return Math.max(height || parseInt(layout.minHeight.replace('%', '')), 100);
  }

  private _processHeight(newHeigth?: number | string) {
    if (!newHeigth) return;

    if (typeof newHeigth == 'string') {
      this.height = parseInt(newHeigth.replace('%', ''));
      return;
    }

    this.height = newHeigth;
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this._destroyCheetah();
    this.quoteCheetahStream?.close();
    this._contextMenu$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { height } = changes;
    height && height.currentValue && this._processHeight(height.currentValue);
  }

  onConfigUpdateHandler = (
    payload: ContextMenuEventPayload<AtPriceContextMenuEvent>
  ) => {
    if (payload.action == AtPriceContextMenuEvent.Detail) {
      this.showDetail = !this.showDetail;
      this.metadataConfiguration = { showDetail: this.showDetail };
      this.attConfigVars();
      this.cdr.detectChanges();
    }
  };

  attConfigVars() {
    this.showDetail = this.metadataConfiguration.showDetail;
  }

  quoteSnapshot!: (data: any) => void;

  async componentInit() {
    this.startCheetahQuote();

    this.refComponent = this.component.metadata.headerOptions.component!.data;
    this.height =
      this.component.metadata.componentResize?.height ??
      parseInt(this.component.metadata.layout.height);

    this.atPrice.nativeElement.oncontextmenu = this.showConfig;

    this.height = this._processInitialHeight();
    this.width =
      this.component.metadata.componentResize?.width ??
      parseInt(this.component.metadata.layout.width);

    this.cdr.detectChanges();
  }

  public stockChanges = (stock: ISearchStock): void => {
    if (
      this.stockSelected &&
      stock &&
      this.stockSelected.cd_stock === stock.cd_stock
    ) {
      return;
    }
    this._destroyCheetah();
    this.preco_minimo = '-';
    this.preco_maximo = '-';
    this.preco_ultimo = 0;
    this._updateStockMetadata(stock);
    this._getCheetahGraficForce();
    this.cdr.detectChanges();
  };

  async startCheetahQuote() {
    this.quoteCheetahStream = await this._quoteChannel.readEvents();
    this.readStream(this.quoteCheetahStream.stream, this._quoteHandler);
    this._getCheetahGraficForce();
  }

  private _updateStockMetadata(data: ISearchStock): void {
    if (!this.component) return;
    this.component.metadata.component = {
      ...this.component.metadata.component,
      stock: data,
    };
    this.updateMetadata();
  }

  private _destroyCheetah(): void {
    this._quoteChannel.unsubscribe(
      structuredClone(this.qouteParamsMultibroker!)
    );
    this.qouteParamsMultibroker = undefined;
  }

  getTickSize(value: string | number): number {
    if (isCrypto(this.stockSelected.ds_type)) {
      return +value > 1 ? 0 : this.tick_size_denominator ?? 8;
    }
    return this.tick_size_denominator;
  }

  updateMetadata() {
    this.homeService.updateMeta<ISearchStock>(this.component);
  }

  private _getCheetahGraficForce(): void {
    this.qouteParamsMultibroker = {
      header: this.refComponent,
      itemsArray: [this.itemsSubscribed],
    };
    this._quoteChannel.subscribe(this.qouteParamsMultibroker);
    this.quoteCheetahStream.snapshot(this.qouteParamsMultibroker.itemsArray);
  }

  private _quoteHandler = (payload: any): void => {
    const data = payload.get(this.qouteParamsMultibroker?.itemsArray[0]);
    if (!data || data.isEndOfSnap) return;
    this.stockPrices = data;
    this.stockAuctionInfos = getAuctionInfos(data, this.stockAuctionInfos);
    this.cdr.detectChanges();
  };

  public selectViewType(viewTypeCode: string): void {
    this.viewTypes.map((view) => {
      view.active = viewTypeCode == view.code ? true : false;
    });
    this.viewTypeSelected = viewTypeCode;
  }
}
