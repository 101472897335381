import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ISearchStock } from '@core/interface';
import { ShareholdersService } from '@shared/services/api/trademap/v1/shareholders.service';
import {
  IShareHoldersHistory,
  ISubsidiariesCountry,
} from '../../business-profile.interface';
import { BusinessProfileService } from '../../business-profile.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { FundamentalService } from '@shared/services/api/trademap/v1/fundamental.service';
import { formatDate, isNullOrUndefined } from 'src/app/utils/utils.functions';
import { RocketModalService } from '@shared/rocket-components/components';
import { Subject, Subscription, delay, filter } from 'rxjs';
import { randomId } from '@shared/rocket-components/utils';
import { FIELDS_MODAL_SUBSIDIARIES } from '../../business-profile.const';
import { ModalInfoComponent } from '../../modal-info/modal-info.component';

@Component({
  selector: 'app-acionistas-subsidiaria',
  templateUrl: './acionistas-subsidiaria.component.html',
  styleUrls: ['./acionistas-subsidiaria.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcionistasSubsidiariaComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('scrollViewport', { static: false })
  virtualScroll!: CdkVirtualScrollViewport;
  @Input() type!: string;
  @Input() set stockSelected(stockSelected: ISearchStock) {
    if (stockSelected) {
      this.error = false;
      this._stockSelected = stockSelected;
      this.isAcionistas = this.type === 'ACIONISTAS';
      if (this.isAcionistas) {
        this.processShareholders();
      } else {
        this.getSubsidiaries();
      }
    }
  }
  isAcionistas = false;
  _stockSelected!: ISearchStock;
  error = false;
  rows: any[] = [];
  shareholderData: IShareHoldersHistory[] = [];
  datesArray: any[] = [];
  referenceDate = '';
  addClass = new Subject<string>();
  addClass$!: Subscription;
  centerText!: string;
  private ref = randomId('ACIONISTAS_SUBSIDIARIA');

  constructor(
    private cdr: ChangeDetectorRef,
    private shareholdersService: ShareholdersService,
    private businessProfileService: BusinessProfileService,
    private fundamentalService: FundamentalService,
    private modalService: RocketModalService
  ) {}

  ngOnInit() {
    this.addClass$ = this.addClass
      .pipe(
        filter((ref) => ref === this.ref),
        delay(100)
      )
      .subscribe(() => {
        this.addTableClass();
      });
  }

  ngAfterViewInit() {
    this.addTableClass();
  }

  ngOnDestroy() {
    this.addClass$ && this.addClass$.unsubscribe();
  }

  private processShareholders() {
    this.shareholderData = [];
    this.shareholdersService
      .getNewCompanyShareholdersHistory(this._stockSelected.cd_stock)
      .then((response: any) => {
        this.shareholderData = response.data.result;
        const { rows, datesArray } =
          this.businessProfileService.processShareholdersTableData(
            this.shareholderData
          );
        this.rows = rows;
        this.datesArray = datesArray.values().sort((a, b) => {
          return a.dt_entry - b.dt_entry;
        });
        this.cdr.detectChanges();
        this.addClass.next(this.ref);
      })
      .catch(() => {
        this.error = true;
        this.rows = [];
        this.datesArray = [];
        this.cdr.detectChanges();
      });
  }

  private getSubsidiaries() {
    this.fundamentalService
      .getSubsidiaries(
        this._stockSelected.cd_stock,
        this._stockSelected.id_exchange
      )
      .then((response: any) => {
        this.referenceDate = formatDate(response.referenceDate, true);
        this.rows = this.businessProfileService.processSubsidiaresTableData(
          response.subsidiaries
        );
        this.cdr.detectChanges();
        this.addClass.next(this.ref);
      })
      .catch(() => {
        this.error = true;
        this.rows = [];
        this.datesArray = [];
        this.cdr.detectChanges();
      });
  }

  private addTableClass() {
    if (!this.virtualScroll) return;
    const nativeElement =
      this.virtualScroll.elementRef.nativeElement.querySelector(
        '.cdk-virtual-scroll-content-wrapper'
      );
    if (!nativeElement?.classList.contains('table')) {
      nativeElement?.classList.add('table');
    }
  }

  displayColumnInfo(row: any, lineIndex: number) {
    const expandFund = !this.rows[lineIndex].expanded;
    this.rows[lineIndex].expanded = expandFund;
    if (isNullOrUndefined(this.rows[lineIndex].children)) {
      this.rows[lineIndex].children = [];
    }
    if (!this.rows[lineIndex].children.length) {
      this.rows[lineIndex].isLoading = true;
      this.rows[lineIndex].isError = false;
      this.getCountryInfo(row, lineIndex);
    }
    this.cdr.detectChanges();
  }

  private getCountryInfo(info: any, lineIndex: number) {
    this.fundamentalService
      .getCountrySubsidiaries(
        this._stockSelected.cd_stock,
        this._stockSelected.id_exchange,
        info.countryName
      )
      .then((response: any) => {
        this.rows[lineIndex].isLoading = false;
        this.rows[lineIndex].children =
          this.businessProfileService.processSubsidiaresTableData(response);
        this.cdr.detectChanges();
      })
      .catch(() => {
        this.rows[lineIndex].isError = true;
        this.rows[lineIndex].isLoading = false;
        this.cdr.detectChanges();
      });
  }

  openModalSub(subsidiaria: ISubsidiariesCountry) {
    this.modalService.open(ModalInfoComponent, {
      data: {
        info: subsidiaria,
        fields: FIELDS_MODAL_SUBSIDIARIES,
        modalTitle: 'Subisidiárias',
      },
      size: 'lg',
      scrollable: true,
    });
  }
}
