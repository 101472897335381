<div class="p-3">
  <ng-container *ngIf="isLoading; else containerChart">
    <app-loading></app-loading>
  </ng-container>
  <ng-template #containerChart>
    <ng-container *ngIf="data && data.length; else withoutData">
      <app-chart-performance
        [chartData]="data"
        [isIntraDayInfos]="isIntraday"
      ></app-chart-performance>
    </ng-container>
    <ng-template #withoutData>
      <span class="w-100 h-100 d-block text-center mt-5">
        {{ withouDataMessage }}
      </span>
    </ng-template>
  </ng-template>
</div>
