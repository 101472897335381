<rocket-modal>
  <rocket-modal-header>
    <h3 class="modal-title">{{ configModal?.title }}</h3>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <fla-card
      *ngIf="configModal && configModal.cards && configModal.cards.length > 0"
      id="modal-orders"
      css="overflow-height p-0"
      [base]="true"
    >
      <fla-card-custom
        *ngFor="let card of quoteStocks.values()"
        #card
        css="py-2 mb-2 bg-neutral-strong pointer"
      >
        <div (click)="stockRedirect()" class="card-body vstack">
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <div class="d-flex align-items-center">
                <img
                  (error)="setErrorImage(card)"
                  class="sized-img"
                  src="{{ card.image }}"
                />
              </div>
              <div class="pl-2 d-flex flex-column justify-content-center">
                <h4 class="fs-5">{{ card.cd_stock }}</h4>
                <span class="fs-6">{{ card.nm_company_valemobi }}</span>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div class="mr-2">
                {{ card.preco_ultimo }}
              </div>
              <div>
                <app-tag
                  class="d-d-flex py-2 col justify-center"
                  [variation]="card.variacao_dia"
                  [isVariation]="true"
                  [bgColor]="card.arrow_hex"
                  [color]="card.arrow_font_hex"
                >
                </app-tag>
              </div>
            </div>
          </div>
        </div>
      </fla-card-custom>
    </fla-card>
  </app-rocket-modal-body>

  <rocket-modal-footer padding="pt-3"></rocket-modal-footer>
</rocket-modal>

<ng-template #withoutImg>
  <fla-icon
    name
    name="business"
    css="text-neutral-medium"
    size="icon-size-sm"
    [isVmIcon]="false"
  ></fla-icon>
</ng-template>
