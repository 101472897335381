import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Dictionary } from '@core/models';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { Subject, debounceTime, filter, takeUntil } from 'rxjs';
import { MarketSummaryService } from '../../market-summary.service';
import { IRowData } from '@shared/components/stock-table/interfaces/stock-table.interfaces';
import {
  MARKET_SUMMARY_STOCKS,
  STOCKS_TO_SUB,
} from '../../constants/market-summary';
import { STOCK_TABLE_VIEW } from '@shared/components/stock-table-views';
import { formatByTick } from 'src/app/utils/utils.functions';
import { CarouselHelper } from '../../helpers/carousel.helper';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { RocketStreamRead } from '@shared/channel/rx-event';

@Component({
  selector: 'app-list-stock-cards',
  templateUrl: './list-stock-cards.component.html',
  styleUrls: ['./list-stock-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListStockCardsComponent
  extends CarouselHelper
  implements AfterViewInit, OnInit, OnDestroy
{
  @Input() public refComponent!: string;
  public stocksToSub: any = STOCKS_TO_SUB.values();
  public stocksDict = new Dictionary<IRowData>();
  public stocks = MARKET_SUMMARY_STOCKS;
  private qouteParamsMultibroker!: SubscribeParam;
  private untilDestroy$ = new Subject<void>();
  private autoNavigationInterval = new Subject<void>();
  private _quoteCurrentSubscription!: RocketStreamRead;

  public cardView = STOCK_TABLE_VIEW.CARD;

  @ViewChild('itemList') _itemList!: ElementRef<HTMLElement>;
  @ViewChild('carouselItem') _carouselItem!: ElementRef<any>;
  @ViewChild('showcase') showcase!: ElementRef<any>;
  translateX = 0;
  public carouselContainerWidth: number = 0;
  public elementCarouselWidth: number = 0;
  public array: Array<any> = [];
  public disablePreviusButton: boolean = true;
  public preventClickPreviusButton: boolean = true;
  public disableNextButton: boolean = false;
  public preventClickNextButton: boolean = false;
  public widthObserver!: ResizeObserver;
  public widthComponent: number = 0;

  constructor(
    private _quoteChannel: QuoteChannel,
    private _resumeMarketService: MarketSummaryService,
    private _cdr: ChangeDetectorRef,
    private _elRef: ElementRef,
    private _globalStock: GlobalSelectedStockSubscription
  ) {
    super(_elRef, _cdr);
    this._resumeMarketService.stocksCloseLimit$
      .pipe(
        takeUntil(this.untilDestroy$),
        filter(
          (response) =>
            response &&
            response.refComponent === this.refComponent &&
            response?.data &&
            !response.isError
        )
      )
      .subscribe((response) => this._updateStockPriceHistory(response!.data));
    this.autoNavigationInterval
      .pipe(debounceTime(5000))
      .subscribe(() => this.handleNavigation(true));
  }

  ngOnInit() {
    this.componentInit();
    this.getHighlights();
  }

  ngAfterViewInit() {
    this.widthObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const cr = entry.contentRect;
        this.widthComponent = cr.width;
        this.onResize();
      }
    });

    this.widthObserver.observe(this.showcase.nativeElement);
  }

  ngOnDestroy(): void {
    this._destroyCheetah();
    this._quoteCurrentSubscription.close();
    this.autoNavigationInterval.unsubscribe();
  }

  async componentInit() {
    this._quoteCurrentSubscription = await this._quoteChannel.readEvents();
    this.readStream(this._quoteCurrentSubscription.stream, this._quoteHandler);
    this._quoteCurrentSubscription.snapshot(this.stocksToSub);
    this._getCheetahGraficForce();
    this._updateStocksInDict(this.stocks);
    this._cdr.detectChanges();
  }

  private _quoteHandler = (payload: Map<string, IRowData>): void => {
    payload.forEach((stock, key) => {
      if (!STOCKS_TO_SUB.has(key)) return;
      if (stock.tick_size_denominator)
        stock.tick_size_denominator =
          +stock.tick_size_denominator > 2 ? '0' : stock.tick_size_denominator;
      if (stock.variacao_dia)
        stock.day_variation = formatByTick(
          parseFloat(stock.variacao_dia).toFixed(2)
        );
      this.stocksDict.set(key, stock);
      this._cdr.detectChanges();
    });
  };

  private _destroyCheetah(): void {
    this._quoteChannel.unsubscribe(this.qouteParamsMultibroker);
  }

  private _getCheetahGraficForce(): void {
    this.qouteParamsMultibroker = {
      header: this.refComponent,
      itemsArray: this.stocksToSub,
    };
    this._quoteChannel.subscribe(this.qouteParamsMultibroker);
  }

  private _updateStocksInDict(stocks: IRowData[]): void {
    if (!stocks?.length) {
      this.stocksDict.clear();
      return;
    }
    const cdStocks: string[] = stocks.map((stock) =>
      stock?.cd_stock_order
        ? `${stock?.cd_stock_order}:${stock?.id_exchange}`
        : stock.idRow
    );
    this._resumeMarketService.chartVlClose(cdStocks, this.refComponent);
  }

  private _updateStockPriceHistory(stockChart: any): void {
    if (!this.stocks || !this.stocks.length) return;
    this.stocks.forEach((stock: IRowData) => {
      const stockInfo = this.stocksDict.get(stock.idRow) ?? stock;
      stockInfo.cd_stock = stock.cd_stock;
      if (stockChart[stock.idRow]) {
        stockInfo.vl_close_history = stockChart[stock.idRow];
      }
      this.stocksDict.set(stock.idRow, stockInfo);
    });
    this._cdr.detectChanges();
  }

  @HostListener('window:resize')
  onResize() {
    this.verifyShowArrows();
  }

  private getHighlights() {
    this.totalElements = this.stocks.length;
    this.autoNavigationInterval.next();
    this._cdr.detectChanges();
  }

  override handleNavigation(increase: boolean): void {
    this.cards = this.stocksDict.values();
    super.handleNavigation(increase, this._itemList);
    this.autoNavigationInterval.next();
  }

  public updateGlobalStock = (data: any) =>
    this._globalStock.researchToStandardizeGlobalStock(data);
}
