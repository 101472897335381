import { Component, Input } from '@angular/core';
import { FlaTPosition, FlaTTarget } from './types/links-types.types';

@Component({
  selector: 'fla-links[url]',
  templateUrl: './links.component.html',
  styles: [
    `
      :host(fla-links) {
        display: contents;
      }
    `,
  ],
})
export class FlaLinksComponent {
  @Input() url!: string;
  @Input() text!: string;
  @Input('css') customClass: string = '';
  @Input() iconPosition: FlaTPosition = undefined;
  @Input() urlTarget: FlaTTarget = '_blank';
}
