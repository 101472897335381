import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StockCardComponent } from './stock-card.component';
import { FlaTagModule } from '../tag';
import { HighchartModule } from '@shared/highchart/highchart.module';
import { FlaIconModule } from '../icon';
import { RtImageFallbackModule } from '@shared/rocket-components/directives';

@NgModule({
  imports: [
    CommonModule,
    FlaTagModule,
    HighchartModule,
    FlaIconModule,
    RtImageFallbackModule,
  ],
  declarations: [StockCardComponent],
  exports: [StockCardComponent],
})
export class StockCardModule {}
