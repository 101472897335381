import { inject, Injectable } from '@angular/core';
import { deepClone, isNullOrWhiteSpace } from '@shared/rocket-components/utils';
import {
  TIGER_INTERVAL_ENUM,
  TIGER_LINE_TYPE,
  TIGER_TYPE_CHART_ENUM,
} from '@shared/tiger-chart/enum/tiger-chart.enum';
import { ICandleConfigs } from '@shared/tiger-chart/interface/tiger-chart.interface';
import {
  TCandleLineOptions,
  TCorporateEvents,
  TData,
  TMouseEventData,
  TNewsChartEvents,
  TRelevantEvents,
  TTradeIdeaEvents,
  TUpdateData,
} from '@shared/tiger-chart/types/tiger-chart.types';
import { StockServiceRT } from '@services/api/nitro-ws/v1/stock.service';
import { OrsGatewayService } from '@services/api/trademap/v1/ors-gateway.service';
import { OrsGatewayServiceV2 } from '@shared/services/api/trademap/V2/ors-gateway.service';
import { StockService } from '@services/api/trademap/v1/stock.service';
import { ToastService } from '@services/toast.service';
import {
  TYPE_ORDE_ENUM,
  TYPE_VALIDADE_ENUM,
} from '@shared/components/stock-trade/enum/stock-trade.enum';
import { isIdBrokerConsolidated } from '@shared/constants/simulator-league.constant';
import {
  ORDER_CONFIRM_ACTIONS_ENUM,
  TYPE_ORDE_SIDE_ENUM,
} from '@shared/enum/buyOrSell.enum';
import {
  auditTime,
  BehaviorSubject,
  filter,
  map,
  Observable,
  Subject,
} from 'rxjs';
import {
  editOrderTypeAuction,
  formatByTick,
  formattedStockForFractional,
  getOrdinaryTicker,
  getTextByRegex,
  isNullOrUndefined,
} from 'src/app/utils/utils.functions';
import { INIT_DEFAULT_CONFIG_CHART } from '../constants/stock-chart.constant';
import {
  FooterEvent,
  IConfigIndicator,
  IPosition,
  IStockChartOrderConfig,
} from '../interface/stock-chart.interface';
import { GlobalSelectedStockSubscription } from '@services/core/subscription/global-stock.subscription';
import { TP_STATUS } from '@core/components/orders-history-core/constants/orders-history.const';
import { ICandleContextMenu } from '@shared/tiger-chart/interface';
import { SciChartVerticalGroup } from 'scichart/Charting/LayoutManager/SciChartVerticalGroup';
import { CustomPreferencesService } from '@services/api/nitro-ws/v1/custom-preferences.service';
import { TIGER_CHART_TOOL } from '@shared/tiger-chart/tiger-chart-tools/tiger-chart-tools.interface';
import { ProcessModificationOrderModel } from 'src/app/core/models/process-modification-order.model';
import { Dictionary } from 'src/app/core/models/dictionary.model';
import {
  IAccountSelect,
  IOrderModification,
  IOrders,
  ISearchStock,
} from 'src/app/core/interface';
import {
  isDoubleStartStop,
  isStockFractional,
  isStockCashMarket,
  isStartStop,
} from '@shared/constants/general.contant';
import { QuoteData } from '@shared/channel/interface/quote.channel.interface';
import { COMPONENTS_NAMES_ENUM } from '@core/workspace';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { RocketModalService } from '@shared/rocket-components/components';
import { ModalAvisoComponent } from '@shared/components/modal-aviso/modal-aviso.component';
import { TIGER_INDICATOR_CLICK } from '@shared/tiger-chart/constants/tiger-chart.constants';
import { CONTEXT_MENU_ORDER_TYPE } from '@shared/tiger-chart/enum';
import { EventsService } from '@shared/services/api/nitro-ws/v1/events.service';
import { DrawTools } from '@shared/tiger-chart/draw-tools/draw-tools.interface';
import { TOOLS_ENUM } from '@shared/tiger-chart/tiger-chart-tools/tiger-chart-tools.enum';
import { OriginAnalysisOrderService } from '@shared/services/origin-analysis-order.service';
import { ORDER_PARAM_HELPER } from '@shared/constants/order-param-helper.const';
import { ORDER_TYPE_DIC } from '@shared/dictionary/order.dictionary';
import { ORDER_TYPES } from '@shared/components/stock-trade/constants/boleta.constants';
import { formatNumber } from '@angular/common';
import { system } from '@core/system/system.service';
import { TradeIdeaService } from '@shared/services/api/trade-idea/trade-idea.service';
import { OrdersService } from '@shared/services/orders.service';
import { StockChartHelper } from '../stock-chart.helper';
import { ConfigService } from '@core/config';
import { ComponentsService } from '@core/workspace/service/components.service';

@Injectable()
export class StockChartService {
  scrollToBegin$ = new Subject<string>();
  footerChartConfig$ = new Subject<FooterEvent>();
  updateWaterMark$ = new Subject<{
    ref: string;
    showWatermark?: boolean;
    watermarkOpacity?: number;
  }>();
  eventKey$ = new Subject<{ ref: string; word: string }>();
  updateQuoteData$ = new Subject<{ quoteData: QuoteData }>();
  eventConfigs$ = new Subject<{
    ref: string;
    config: Partial<ICandleConfigs>;
    save: boolean;
    typeUpdate: any;
    global?: boolean;
  }>();
  alertCharts$ = new Subject<{
    ref: string;
    candle?: ICandleContextMenu<{ pointValueY: number }>;
    type: string;
    boxId?: string;
    idTradeSystem?: number;
    alertAnother?: boolean;
    itemUpdate?: {
      id: string;
      value: number;
      data: IOrders | IPosition;
      idTradeSystem?: number;
      updateByAlertColumn?: boolean;
    };
    newAlert?: any;
    itemDelete?: {
      type: TIGER_LINE_TYPE;
      id: string;
      value: number;
      data: IOrders | IPosition[] | IPosition;
      idTradeSystem?: number;
      boxIdAlert?: string;
      cdStock?: string;
    };
  }>();
  clickOnCustody$ = new Subject<boolean>();
  orderSelectedByGrid$ = new Subject<any>();
  candleClicked$ = new Subject<any>();
  visibleRange$ = new Subject<any>();
  mouseEventClear$ = new Subject<any>();
  mouseIndex$ = new Subject<{ ref: string; index: number }>();
  indicatorSelected$ = new Subject<any>();
  updateSubchartXaxis$ = new Subject<{ ref: string }>();
  removeChartLoading$ = new Subject<{ remove: boolean; ref: string }>();
  addChartLoading$ = new Subject<{ ref: string }>();
  loadingButton$ = new Subject<{ type: string; bool: boolean }>();
  updateTooltip$ = new Subject<{
    refComponent: string;
    eventData: TMouseEventData;
    volume: number;
  }>();
  verticalGroup = new SciChartVerticalGroup();
  loading: any = {
    MARKET_BUY_BUTTON: false,
    MARKET_SELL_BUTTON: false,
  };
  indicatorsLineNumber = new Dictionary<{ qtty: number }>();
  indicatorsTempConfig = new Dictionary<Array<IConfigIndicator>>();
  indicatorsConfig = new Dictionary<IConfigIndicator>();
  indicatorsActive = new Dictionary<any[]>();
  notReload = new Dictionary<{ reload: boolean }>();
  intervalSelected = new BehaviorSubject(TIGER_INTERVAL_ENUM.ONE_MINUTE);
  toolSelected$ = new Subject<{
    componentRef: string;
    tool: TIGER_CHART_TOOL | null;
    toolEnum?: TOOLS_ENUM;
  }>();
  drawToolHover$ = new Subject<{
    componentRef: string;
    draw: DrawTools | undefined;
  }>();
  sharedTool$ = new Subject<{
    ref: string;
    tool: TIGER_CHART_TOOL;
    eventData: TMouseEventData;
    eventDataLast: TMouseEventData;
    width: number;
    height: number;
    isFromSaved: boolean;
    configs: ICandleConfigs;
  }>();
  tools$ = new Subject<{ show: boolean; refComponent: string }>();
  intervalModalValue!: any;
  clickRef!: string;
  indicatorClicked$ = new Subject<any>();
  sendOrder$ = new Subject<{
    ord_type: TYPE_ORDE_ENUM;
    typeOrder: CONTEXT_MENU_ORDER_TYPE;
    data: any;
    ref: string;
  }>();
  mouseOnChart = new Dictionary<{ isHover: boolean }>();
  addLine = new Subject<{ line: TCandleLineOptions; refComponent: string }>();
  blockChartButtons$ = new Subject<{ isBlock: boolean; ref: string }>();
  private _updateChart$ = new Subject<{
    ref: string;
    isChangeInterval: boolean;
  }>();
  private enableOrDisableCursorModifier$ = new Subject<{
    cursor: string;
    isLine: boolean;
    refComponent: string;
  }>();
  configService = inject(ConfigService);
  constructor(
    private stockService: StockServiceRT,
    private stockServiceTM: StockService,
    private orsGatewayService: OrsGatewayService,
    private _orsGatewayServiceV2: OrsGatewayServiceV2,
    private toastService: ToastService,
    private globalStock: GlobalSelectedStockSubscription,
    private customPreferencesService: CustomPreferencesService,
    private _rocketModalService: RocketModalService,
    private eventsService: EventsService,
    private themeService: ThemePreferencesService,
    private originAnalysisOrderService: OriginAnalysisOrderService,
    private tradeIdeaService: TradeIdeaService,
    private _ordersService: OrdersService,
    private componentsService: ComponentsService
  ) {
    this.setIndicatorsConfig();
  }

  updateChart(ref: string, isChangeInterval: boolean = false) {
    this._updateChart$.next({ ref, isChangeInterval });
  }

  subscribeUpdateChart(ref: string) {
    return this._updateChart$.pipe(
      filter((data) => data.ref === ref),
      auditTime(350)
    );
  }

  getCorporateEvents(
    stock: string,
    interval: TIGER_INTERVAL_ENUM,
    idPoint: number,
    idExchange: number,
    limit: number = 500,
    dsAsset?: string
  ): Observable<TCorporateEvents[]> {
    return this.eventsService.getCorporateEvents(
      stock,
      interval,
      idPoint,
      idExchange,
      limit,
      dsAsset
    );
  }

  getTradeIdeaEvents(
    stock: string,
    interval: TIGER_INTERVAL_ENUM,
    idExchange: number,
    limit: number = 500,
    dsAsset?: string
  ): Observable<TTradeIdeaEvents[]> {
    return this.tradeIdeaService
      .getTradeIdeaEvents(stock, interval, idExchange, limit, dsAsset)
      .pipe(
        map((data) => {
          return data.filter((tradeIdea) => !tradeIdea.is_expiration);
        })
      );
  }

  getNewsChart(
    stock: string,
    interval: TIGER_INTERVAL_ENUM,
    idPoint: number,
    idExchange: number,
    limit: number = 500,
    dsAsset?: string
  ): Observable<TNewsChartEvents[]> {
    return this.eventsService.getNewsChart(
      stock,
      interval,
      idPoint,
      idExchange,
      limit,
      dsAsset
    );
  }

  getRelevantEvents(
    stock: string,
    interval: TIGER_INTERVAL_ENUM,
    idPoint: number,
    idExchange: number,
    limit: number = 500,
    dsAsset?: string
  ): Observable<TRelevantEvents[]> {
    return this.eventsService.getRelevantEvents(
      stock,
      interval,
      idPoint,
      idExchange,
      limit,
      dsAsset
    );
  }

  isDarkTheme = () => this.themeService.isDarkTheme();

  onChangeTheme = (changeTheme: any) =>
    this.themeService.themeActiveObservable().subscribe(changeTheme);

  getAll(
    stock: string,
    interval: TIGER_INTERVAL_ENUM,
    idPoint: number,
    idExchange: number,
    limit: number = 500,
    dsAsset?: string
  ): Observable<TData> {
    // MOCK DATA
    // return of(
    //   stock === 'PETR4' ?
    //   MOCK_STOCK_CHART_PETR4[interval] || MOCK_STOCK_CHART_PETR4[0] :
    //   MOCK_STOCK_CHART_ITUB[interval] || MOCK_STOCK_CHART_ITUB[0]
    // );
    return this.stockService
      .getPriceHistory(stock, interval, idPoint, idExchange, limit, dsAsset)
      .pipe(
        map((res) =>
          isNullOrWhiteSpace(res)
            ? {
                vl_open: [],
                vl_high: [],
                vl_low: [],
                vl_close: [],
                id_point: [],
                vl_volume: [],
                candle_count: [],
                qtty_shares_traded: [],
              }
            : res
        )
      );
  }

  processDoubleStartStopUpdate(order: any): IOrders {
    if (isDoubleStartStop(order.cd_order_type)) {
      order.personalized_gain_trigger = order.gainTriggerPrice;
      order.personalized_gain_price = order.gainOrderPrice;
      order.personalized_loss_trigger = order.lossTriggerPrice;
      order.personalized_loss_price = order.lossOrderPrice;

      return order;
    }
    return order;
  }

  updateOrder(
    value: IOrders,
    newValues: number,
    isOrderStartStop: boolean = false
  ) {
    const order: IOrderModification = new ProcessModificationOrderModel(
      value,
      this.configService.invertStopBroker(value.side)
    );
    if (isOrderStartStop) {
      if (value.price == value.price_stop) {
        order.price = newValues;
      }
      order.stopPx = newValues;
    } else {
      order.price = newValues;
    }
    editOrderTypeAuction(value, order);
    return this._orsGatewayServiceV2.orderModificationRequest(order);
  }

  newOrder(config: IStockChartOrderConfig, refComponent?: string) {
    if (isIdBrokerConsolidated(config.account.id_broker)) {
      this.toastService.showToast('warning', 'Selecione uma corretora.');
      const c = new Subject();
      c.complete();
      return c;
    }
    // config.quant < 0 ?
    // config.ord_side = (config.quant < 0 && !config.ord_side) ?

    const order: any = {
      order_qty: config.quant,
      ord_type: config.ord_type ?? TYPE_ORDE_ENUM.MARKET,
      validade: config.validade ?? TYPE_VALIDADE_ENUM.TODAY,
      date: undefined,
      isFastOrder: config.isFastOrder,
      strategy: config.fastOrderIsSelectedStrategy
        ? config.fastOrderStrategy
        : undefined,
    };
    if (config.idStrategy) order.id_strategy = config.idStrategy;
    if (config.price) order.price = config.price;
    if (config.stopPx) order.stopPx = config.stopPx;
    if (config.gainOrderPrice != undefined)
      order.gainOrderPrice = config.gainOrderPrice;
    if (config.lossOrderPrice != undefined)
      order.lossOrderPrice = config.lossOrderPrice;
    if (config.gainTriggerPrice != undefined)
      order.gainTriggerPrice = config.gainTriggerPrice;
    if (config.lossTriggerPrice != undefined)
      order.lossTriggerPrice = config.lossTriggerPrice;

    return this._orsGatewayServiceV2.sendNewOrderSingle(
      config.stock,
      order,
      config.account,
      config.ord_side ?? TYPE_ORDE_SIDE_ENUM.SELL,
      config.isConfirm,
      false,
      false,
      refComponent
    );
  }

  public toastShow(text: string): void {
    this.toastService.showToast('warning', text);
  }

  private getOpenOrdersByAccountAndStock(stock: any, isDayTrade: boolean) {
    const cd_stock = this.getCdStockParam(stock);
    const orders = this._ordersService.filterOrders(
      'filterStockIdBrokerStatusIsDayTrade',
      `${cd_stock}_${TP_STATUS.ABERTA}_${isDayTrade}`
    );
    return orders;
  }

  private getCdStockParam(stock: ISearchStock): string {
    return stock.cd_crypto ? stock.cd_crypto : stock.cd_stock_order!;
  }

  processPositionOrders(stock: any, isDayTrade: boolean) {
    let orders = this.getOpenOrdersByAccountAndStock(stock, isDayTrade) || [];
    if (
      isStockFractional(stock.cd_security_type) ||
      isStockCashMarket(stock.cd_security_type)
    ) {
      const defaultMarketCdStock = getOrdinaryTicker(stock);
      const defaultMarketOrders = this.getOpenOrdersByAccountAndStock(
        { ...stock, cd_stock_order: defaultMarketCdStock },
        isDayTrade
      );
      orders = defaultMarketOrders
        ? orders.concat(defaultMarketOrders)
        : orders;
    }
    return { orders };
  }

  loadInitialUserConfig(metadataComponent: any) {
    return new Promise((resolve) => {
      const configs = {
        ...deepClone(INIT_DEFAULT_CONFIG_CHART),
        ...deepClone(metadataComponent),
      };
      /**
       * caso entre no if, significa que é um novo grafico,
       * sendo assim, como o grafico utiliza porcentagem para determinar seu tamanho,
       * fica sendo necessário atualizar para pixel.
       */
      const chartComponent = this.componentsService
        .getComponentsList()
        .get('FERRAMENTAS')
        .find(
          (component: any) => component.id === COMPONENTS_NAMES_ENUM.GRAFICO
        );
      if (
        chartComponent &&
        !metadataComponent.chart &&
        chartComponent.unitOfMeasurement &&
        chartComponent.unitOfMeasurement === '%'
      ) {
        const doc = document.getElementById('workspace_base');
        if (doc && configs.chart.width < parseInt(chartComponent.minWidth)) {
          const bounding = doc.getBoundingClientRect();
          configs.chart.height =
            bounding.height * (configs.chart.height / 100) - 45;
          configs.chart.width = bounding.width * (configs.chart.width / 100);
        }
      }
      if (isNullOrUndefined(configs.chart.series.interval)) {
        configs.chart.series.interval = TIGER_INTERVAL_ENUM.FIVE_MINUTE;
      }
      if (isNullOrUndefined(configs.chart.series.type)) {
        configs.chart.series.type = TIGER_TYPE_CHART_ENUM.CANDLE;
      }
      if (
        Object.keys(configs.stock).length < 3 &&
        !this.globalStock.getGlobalStockSelected()
      ) {
        this.searchStock(configs, resolve);
      } else if (
        Object.keys(configs.stock).length < 3 &&
        this.globalStock.getGlobalStockSelected()
      ) {
        configs.stock = this.globalStock.getGlobalStockSelected();
        configs.chart.yAxis.labelPrecision =
          configs.stock.tick_size_denominator;
        resolve({ configs });
      } else {
        configs.chart.yAxis.labelPrecision =
          configs.stock.tick_size_denominator;
        resolve({ configs });
      }
    });
  }

  private searchStock(configs: any, resolve: any) {
    this.stockService
      .searchStock(configs.stock.cd_stock)
      .subscribe((resp: any) => {
        const stock = resp[0];
        !!stock && (configs.stock = stock);
        resolve({ configs });
      });
  }

  private setIndicatorsConfig() {
    let sessionTempConfig: any = this.customPreferencesService.chartTempConfig;
    if (!isNullOrUndefined(sessionTempConfig) && sessionTempConfig != '') {
      sessionTempConfig = JSON.parse(sessionTempConfig);
      Object.keys(sessionTempConfig).forEach((key) => {
        this.indicatorsTempConfig.set(key, sessionTempConfig[key]);
      });
    }
    let sessionConfig: any =
      this.customPreferencesService.chartIndicatorsConfig;
    if (!isNullOrUndefined(sessionConfig) && sessionConfig != '') {
      sessionConfig = JSON.parse(sessionConfig);
      Object.keys(sessionConfig).forEach((key) => {
        this.indicatorsConfig.set(key, sessionConfig[key]);
      });
    }
  }

  parseQuoteChannel(data: any): QuoteData {
    const item = data.item.split(':');
    return {
      cd_stock: item[0],
      id_exchange: parseInt(item[1]),
      tick_size_denominator: parseFloat(data.tick_size_denominator),
      preco_ultimo: parseFloat(data.preco_ultimo),
      preco_fechamento: parseFloat(data.preco_fechamento),
      variacao_dia: parseFloat(data.variacao_dia),
      var_fech_anterior: parseFloat(data.var_fech_anterior),
      vl_fech_ant_essa_sem: parseFloat(data.vl_fech_ant_essa_sem),
      vl_fech_ant_esse_mes: parseFloat(data.vl_fech_ant_esse_mes),
      vl_fech_ant_esse_ano: parseFloat(data.vl_fech_ant_esse_ano),
      vl_fech_ant_sem: parseFloat(data.vl_fech_ant_sem),
      vl_fech_ant_mes: parseFloat(data.vl_fech_ant_mes),
      vl_fech_ant_ano: parseFloat(data.vl_fech_ant_ano),
      volume: parseFloat(data.volume),
      arrow_font_hex: data.arrow_font_hex,
      arrow_hex: data.arrow_hex,
      situacao: data.situacao,
      preco_maximo: parseFloat(data.preco_maximo),
      preco_minimo: parseFloat(data.preco_minimo),
      preco_abertura: parseFloat(data.preco_abertura),
      precoDiaAnt: parseFloat(data.precoDiaAnt),
      qtde_negocios: parseFloat(data.qtde_negocios),
      relatorio_forca_compra: parseFloat(data.relatorio_forca_compra),
      relatorio_forca_venda: parseFloat(data.relatorio_forca_venda),
      tick_size_quantity: parseFloat(data.tick_size_quantity),
      variacao_leilao: parseFloat(data.variacao_leilao),
      preco_leilao: parseFloat(data.preco_leilao),
      qtde_leilao: parseFloat(data.qtde_leilao),
      qtde_leilao_nao_atendida: parseFloat(data.qtde_leilao_nao_atendida),
      sentido_leilao_nao_atendida: data.sentido_leilao_nao_atendida,
      hora_abert_program: data.hora_abert_program,
      motivo_leilao_headline: data.motivo_leilao_headline,
      nome_companhia: data.nome_companhia,
    };
  }

  updateIntervalModalValue(value: any) {
    this.intervalModalValue = value;
  }

  public resetPositions(config: IStockChartOrderConfig): void {
    const { reset } =
      this.customPreferencesService.confirmattionInvertAndResetPosition;

    if (!reset) {
      this.newOrder(config).subscribe();
      return;
    }

    this.originAnalysisOrderService.setOriginOrder(
      ORDER_PARAM_HELPER.STOCK_CHART_RESET
    );
    const ref = this._rocketModalService.open(ModalAvisoComponent, {
      data: {
        title: 'Aviso',
        text: `Deseja zerar a sua posição de ${config.stock?.cd_stock}?`,
        confirmeButton: 'Sim',
        cancelButton: 'Não',
        typeAskAgain: 'Não perguntar novamente',
        askAgain: true,
        showButtons: true,
        isSecondModal: false,
        positionActionEnum: ORDER_CONFIRM_ACTIONS_ENUM.RESET,
        stock: undefined,
        showPriceStock: false,
      },
    });

    const modalSub$ = ref.afterDismissed.subscribe((res) => {
      if (res && res.value && !res.closed) this.newOrder(config).subscribe();
      modalSub$.unsubscribe();
    });
  }

  public indicatorClick(event: any) {
    if (event && TIGER_INDICATOR_CLICK.includes(event.type)) {
      const indicatorId = event.indicator.id;

      if (this.isAnnotationIndicator(indicatorId)) {
        const name = indicatorId.replace(
          'Tiger-chart-annotation-indicator-',
          ''
        );
        this.handleAnnotationIndicator(
          name,
          indicatorId,
          event.type,
          event.indicator
        );
      } else {
        const name = indicatorId.split('-')[0];

        if (
          this.hasMatchingIndicator(indicatorId, [
            'MACD',
            'Bandas de Bollinger',
          ])
        ) {
          this.handlePermittedAllLines(
            name,
            indicatorId,
            event.type,
            event.indicator
          );
        } else {
          this.handleOtherIndicators(
            name,
            indicatorId,
            event.type,
            event.indicator
          );
        }
      }
    } else {
      this.emitIndicatorClicked({
        name: null,
        hashId: null,
        id: null,
        type: null,
        indicator: null,
        isAnnotation: false,
      });
    }
  }

  private isAnnotationIndicator(id: string): boolean {
    return (
      id.includes('Martelo') ||
      id.includes('Martelo Invertido') ||
      id.includes('Doji')
    );
  }

  private handleAnnotationIndicator(
    name: string,
    indicatorId: string,
    type: string,
    indicator: any
  ): void {
    this.emitIndicatorClicked({
      name,
      hashId: null,
      id: indicatorId,
      type,
      indicator,
      isAnnotation: true,
    });
  }

  private handlePermittedAllLines(
    name: string,
    indicatorId: string,
    type: string,
    indicator: any
  ): void {
    this.emitIndicatorClicked({
      name,
      hashId: indicator.dataSeriesProperty.id,
      id: this.getIdByAliasName(name, indicatorId),
      type,
      indicator,
      isAnnotation: false,
    });
  }

  private handleOtherIndicators(
    name: string,
    indicatorId: string,
    type: string,
    indicator: any
  ): void {
    this.emitIndicatorClicked({
      name,
      hashId: indicator.dataSeriesProperty.id,
      id: indicatorId,
      type,
      indicator,
      isAnnotation: false,
    });
  }

  private emitIndicatorClicked(data: any) {
    this.indicatorClicked$.next(data);
  }

  private getIdByAliasName(name: string, id: any): string {
    const prefix = id.split('-')[0];
    const suffix = id.split('_')[1];

    switch (name) {
      case 'MACD':
        return `${prefix}-main-line_${suffix}`;
      case 'Bandas de Bollinger':
        return `${prefix}-middle-bands_${suffix}`;
      default:
        return '';
    }
  }

  private hasMatchingIndicator(
    indicatorId: string,
    indicators: string[]
  ): boolean {
    return indicators.some((word) => indicatorId.includes(word));
  }

  sendMarketOrder(type: CONTEXT_MENU_ORDER_TYPE, ref: string) {
    const loadingKey =
      type == CONTEXT_MENU_ORDER_TYPE.BUY
        ? 'MARKET_BUY_BUTTON'
        : 'MARKET_SELL_BUTTON';
    this.loading[loadingKey] = true;
    this.loadingButton$.next({ type: loadingKey, bool: true });
    this.sendOrder$.next({
      ord_type: TYPE_ORDE_ENUM.MARKET,
      typeOrder: type,
      data: { pointValueY: 0 },
      ref,
    });
  }

  generateTooltipOrder(
    order: any,
    stock: ISearchStock,
    locale: string,
    tickSizeDenominator: number,
    valueLine: number
  ) {
    const id = `${order.side}${order.cd_order_type == '4' ? '4' : ''}`;
    const orderType = ORDER_TYPE_DIC.get(id);
    const typeOrder = ORDER_TYPES.find(
      (item: any) => item.cod == order.cd_order_type
    );
    const value = order.price > 0 ? parseFloat(order.price.toString()) : 0;
    const quantity = formatNumber(order.qtty_left, locale, `1.0-0`);
    const diff = value - valueLine;
    const negative = diff < 0 ? '-' : '';
    const difference = formatByTick(Math.abs(diff), tickSizeDenominator);
    return {
      description: orderType!.description,
      quantity,
      value,
      tickSize: tickSizeDenominator,
      type: typeOrder?.desc,
      cdSegment: stock.cd_segment,
      labelPrecision: tickSizeDenominator,
      dsAsset: stock.ds_asset,
      stockName: stock.cd_stock,
      indicators: [],
      stockPrice: valueLine ?? 0,
      distance:
        stock.cd_security_type === 'FUT'
          ? `${difference} pontos`
          : `${negative}R$ ${difference}`,
    };
  }

  public getOrderOptions = (
    order: any,
    locale: string,
    tickSize: number,
    configStock: any,
    chartHelper: StockChartHelper
  ): TCandleLineOptions | undefined => {
    const id = `${order.side}${order.cd_order_type == '4' ? '4' : ''}`;
    const orderType = ORDER_TYPE_DIC.get(id);
    const quantity = formatNumber(order.qtty_left, locale, `1.0-0`);
    if (!orderType) {
      console.error(`não foi possivel encontrar o tipo da ordem: ${id}`);
      return;
    }
    const price = formatNumber(
      order.price,
      locale,
      `1.${tickSize}-${tickSize}`
    );
    const stopPrice = formatNumber(
      order.price_stop,
      locale,
      `1.${tickSize}-${tickSize}`
    );
    const value = order.price > 0 ? parseFloat(order.price.toString()) : 0;
    const tooltipLabel = isStartStop(order.cd_order_type)
      ? {
          isStartStop: true,
          description: orderType.description,
          quantity,
          price,
          stopPrice,
          indicators: [],
        }
      : this.generateTooltipOrder(order, configStock, locale, tickSize, value);

    return deepClone({
      value: isStartStop(order.cd_order_type)
        ? order.price_stop > 0
          ? parseFloat(order.price_stop.toString())
          : 0
        : value,
      color: orderType.color,
      boxLabel: `${orderType.name[0]} | ${quantity}`,
      boxId: order.id_order.toString(),
      data: order,
      boxTextColor: orderType.boxTextColor,
      tooltipLabel: chartHelper.getTooltipForType(
        TIGER_LINE_TYPE.ORDER,
        tooltipLabel
      ),
      type: TIGER_LINE_TYPE.ORDER,
    });
  };

  getPositionKey(
    brokerKey: string,
    stock: ISearchStock,
    account: IAccountSelect
  ) {
    return `${brokerKey}_${this.getStockPositionKey(stock, account)}`;
  }

  getBrokerPositionKey(brokerKey: string, key: string) {
    return `${brokerKey}_${key}`;
  }

  getStockPositionKey(stock: ISearchStock, account: IAccountSelect): string {
    if (!stock || !account) return '';
    return `${formattedStockForFractional(stock)}.${system.idInvestor}.${
      account.id_broker
    }`;
  }

  getOrderChartParams(
    stock: ISearchStock,
    account: IAccountSelect,
    dayTradeOn: boolean
  ) {
    const modeSelected = !dayTradeOn ? 'SWING' : 'DAYTRADE';
    const stockCode = `${modeSelected}.${
      stock.cd_stock_order || stock.cd_stock
    }.${system.idInvestor}.${account.id_broker}`;
    return {
      itemsArray: [stockCode],
    };
  }

  mapCandleCheetah(data: any, lastDate: number): TUpdateData {
    const date = new Date(
      Date.UTC(
        +data.id_point.slice(0, 4),
        +data.id_point.slice(4, 6) - 1,
        +data.id_point.slice(6, 8),
        +data.id_point.slice(8, 10),
        +data.id_point.slice(10),
        0,
        0
      )
    );
    const timezone = 1000 * 60 * 60 * 3;
    const idPoint = date.getTime() + timezone;
    const updated: TUpdateData = {
      id_point: idPoint,
      vl_close: parseFloat(data.vl_close),
      vl_high: parseFloat(data.vl_high),
      vl_low: parseFloat(data.vl_low),
      vl_open: parseFloat(data.vl_open),
      vl_volume: parseFloat(data.vl_volume),
      qtty_shares_traded: parseFloat(data.qtty_shares_traded),
      remaining_time_millis: parseFloat(data.remaining_time_millis),
      updateBar: idPoint == lastDate,
    };
    return updated;
  }

  updateCustodySVG(ref: string, idAnnotation: string, svgString: string) {
    const element = document.querySelector(`#${ref} #${idAnnotation}`);
    if (element) {
      const parser = new DOMParser();
      const width = `${getTextByRegex(
        getTextByRegex(svgString, /(width=")\d+/g),
        /\d+/
      )}px`;
      const height = getTextByRegex(
        getTextByRegex(svgString, /(height=")\d+/g),
        /\d+/
      );
      const html = parser.parseFromString(svgString, 'text/html');
      element.setAttribute('width', width);
      element.setAttribute('height', height);
      element.innerHTML = '';
      element.append(html.body.children[0].children[0]);
    }
  }

  enableOrDisableCursorModifier(value: {
    cursor: string;
    isLine: boolean;
    refComponent: string;
  }): void {
    this.enableOrDisableCursorModifier$.next(value);
  }

  getEnableOrDisableCursorModifier(): Observable<{
    cursor: string;
    isLine: boolean;
    refComponent: string;
  }> {
    return this.enableOrDisableCursorModifier$.asObservable();
  }

  getCandleClick(): Observable<any> {
    return this.candleClicked$.asObservable().pipe(auditTime(200));
  }

  emitCandleClicked(event: { chartId: string; dataSeriesIndex: number }) {
    this.candleClicked$.next(event);
  }
}

export const getFieldValueByIdBroker = (idBroker: number, cd_stock: string) => {
  const isConsolidated = isIdBrokerConsolidated(idBroker);
  const field = isConsolidated ? 'cd_stock' : ['cd_stock', 'id_broker'];
  const value = isConsolidated ? cd_stock : [`${cd_stock}`, `${idBroker}`];
  return {
    field,
    value,
  };
};
