import { DEFAULT_INIT_CELL, TABLE_IDS } from './../constants/table.constants';
import { TableRowComponent } from './../parts/row/table-row.component';
import { ComponentRef, Injectable } from '@angular/core';
import { TableCellComponent } from '../parts/cell/table-cell.component';
import { BehaviorSubject, Observable, Subject, auditTime, map, of } from 'rxjs';
import { ITableCell, ITableDropdownEvent } from '../interfaces/table.interface';
import { RocketCreateComponentService } from '@shared/rocket-components/services';
import { isNullOrWhiteSpace } from '@shared/rocket-components/utils';

@Injectable()
export class TableService {
  private _element: HTMLElement | undefined = undefined;
  private _componentRefRow: ComponentRef<TableRowComponent> | undefined =
    undefined;
  private _componentRefCell: ComponentRef<TableCellComponent> | undefined =
    undefined;
  private _parentElement: ParentNode | null = null;

  private defaultConfig = DEFAULT_INIT_CELL;
  private updateComponentSize$ = new Subject<{
    elementID: string;
    firstLine: any;
  }>();
  private _dropdownElementEvent =
    new BehaviorSubject<ITableDropdownEvent | null>(null);

  constructor(private _service: RocketCreateComponentService) {
    this.updateComponentSize$
      .pipe(
        auditTime(100),
        map((data) => {
          const tds = this._getElementByID(
            data.elementID
          ).querySelectorAll<HTMLElement>('td[visible="true"].sticky-col');
          const header = tds[0];
          return { ...data, header };
        })
      )
      .subscribe((data) => {
        const headerWidth =
          data.header.parentElement?.getBoundingClientRect().width;
        const firstLineTable = data.firstLine.parentElement?.parentElement;
        firstLineTable!.style.width = `${headerWidth}px`;
      });
  }

  insertRowElement(
    trElement: HTMLElement,
    settings: {
      ref: string;
      refParent: string;
      show: boolean;
    }
  ) {
    const create = this._service.create(TableRowComponent);
    this._componentRefRow = create.componentRef;
    this._parentElement = trElement.parentNode;

    this._componentRefRow.instance.ref = settings.ref;
    this._componentRefRow.instance.refParent = settings.refParent;

    this._element = create.rootNodeElement.firstChild as HTMLElement;
    this._parentElement!.insertBefore?.(this._element, trElement.nextSibling);

    return of(this._element);
  }

  insertCellElement(rowId: string | number, settings: ITableCell) {
    const create = this._service.create(TableCellComponent);

    this._componentRefCell = create.componentRef;

    this._bindParamComp(settings);

    this._element = create.rootNodeElement.firstChild as HTMLElement;

    const row = document.getElementById(TABLE_IDS.ROW + rowId);
    row?.append(this._element);

    return {
      click: this._componentRefCell.instance.Click.asObservable(),
    };
  }

  updateWidthCell(elementID: string, minWidth?: number) {
    const tds = this._getElementByID(elementID).querySelectorAll<HTMLElement>(
      'td[visible="true"].sticky-col'
    );
    let firstLine: any = undefined;
    let maxWidth = 0;
    tds.forEach((tr) => {
      !firstLine &&
        (firstLine = !tr.className.includes('table-cell-action')
          ? tr
          : undefined);
      const cell = tr.childNodes[0] as HTMLElement;
      let cellWidth = cell.getBoundingClientRect().width;
      if (!isNullOrWhiteSpace(cell.offsetLeft)) {
        cellWidth = cellWidth + cell.offsetLeft;
      }

      if (cellWidth > maxWidth) {
        maxWidth = cellWidth;
      }
    });
    if (firstLine) {
      this.updateComponentSize$.next({
        elementID,
        firstLine,
      });
    }
    maxWidth = +maxWidth.toFixed(0) + 20;
    if (minWidth && maxWidth < minWidth) {
      maxWidth = minWidth;
    }
    tds.forEach((td) => {
      this._thCell(elementID)!.style.width = maxWidth + 'px';
      const thSecondaty = this._thSecondaryCell(elementID);
      if (thSecondaty) thSecondaty.style.width = maxWidth + 'px';
      td.style.width = maxWidth + 'px';
    });
  }

  showTableDropdown(element: ITableDropdownEvent | null): void {
    this._dropdownElementEvent.next(element);
  }

  getTableDropdownEvent(): Observable<ITableDropdownEvent | null> {
    return this._dropdownElementEvent.asObservable();
  }

  private _bindParamComp(settings: ITableCell) {
    if (!this._componentRefCell) return;
    this.defaultConfig = Object.assign(this.defaultConfig, settings);
    this._componentRefCell.instance.width = this.defaultConfig.width;
    this._componentRefCell.instance.height = this.defaultConfig.height;
    this._componentRefCell.instance.alignText = this.defaultConfig.alignText;
    this._componentRefCell.instance.ref = this.defaultConfig.ref;
    this._componentRefCell.instance.css = this.defaultConfig.css;
    this._componentRefCell.instance.cssContent = this.defaultConfig.cssContent;
    this._componentRefCell.instance.refParent = this.defaultConfig.refParent;
    this._componentRefCell.instance.text = this.defaultConfig.text;
    this._componentRefCell.instance.hasChildren =
      this.defaultConfig.hasChildren;
    this._componentRefCell.instance.fixedColumn =
      this.defaultConfig.fixedColumn;
  }

  private _getElementByID(elementID: string) {
    return document.getElementById(elementID)!;
  }

  private _thCell(elementID: string) {
    return this._getElementByID(elementID).querySelector<HTMLElement>(
      '.sticky-header > th'
    );
  }

  private _thSecondaryCell(elementID: string) {
    return this._getElementByID(elementID).querySelector<HTMLElement>(
      '.double-header.sticky-header > th'
    );
  }
}
