import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { TIGER_INDICATORS_ENUM } from '../enum';
import { TIGER_INDICATOR_DEFAULT_VALUES } from './indicators.constants';
import {
  BaseIndicator,
  TTigerChartIndicatorLimitTypes,
  TTigerChartIndicatorParameter,
  TTigerChartIndicatorRenderSeriesConfig,
} from './indicators.types';

export function setDefaultConfiguration(
  that: BaseIndicator,
  baseChart: TWebAssemblyChart,
  options: any,
  settings: TTigerChartIndicatorParameter[],
  renderSeriesConfig: TTigerChartIndicatorRenderSeriesConfig[],
  styles: TTigerChartIndicatorParameter[],
  type: TIGER_INDICATORS_ENUM
) {
  const indicatorDefaultValues: any = TIGER_INDICATOR_DEFAULT_VALUES.get(type);
  if (!indicatorDefaultValues) {
    return;
  }
  settings.forEach((setting) => {
    that.setValue(
      setting.property as keyof typeof that,
      indicatorDefaultValues[setting.property]
    );
  });
  renderSeriesConfig.forEach((render) => {
    if (render.property) {
      (render as any)[render.property] =
        indicatorDefaultValues[render.property];
    }
    if (render.propertyColor) {
      render.color = indicatorDefaultValues[render.propertyColor];
    }
    if (render.propertyThickness) {
      render.thickness = indicatorDefaultValues[render.propertyThickness];
    }
    if (render.propertyVisible) {
      (render as any)[render.propertyVisible] =
        indicatorDefaultValues[render.propertyVisible];
    }
    if (render.showLimit) {
      let property = '';
      if (render.limitType === TTigerChartIndicatorLimitTypes.UPPER) {
        property = 'upperLimit';
      }
      if (render.limitType === TTigerChartIndicatorLimitTypes.BOTTOM) {
        property = 'bottomLimit';
      }
      render.limit = indicatorDefaultValues[property];
      that.setValue(
        render.property as keyof typeof that,
        indicatorDefaultValues[property]
      );
    }
    if (!isNullOrUndefined(render.lineTypeId)) {
      let property = '';
      if (render.limitType === TTigerChartIndicatorLimitTypes.UPPER) {
        property = 'upperLimitLineType';
      }
      if (render.limitType === TTigerChartIndicatorLimitTypes.BOTTOM) {
        property = 'bottomLimitLineType';
      }
      render.lineTypeId = indicatorDefaultValues[property];
    }
    that.updateStyles(baseChart, render);
  });
  styles.forEach((style) => {
    if (style.property === 'precision') {
      indicatorDefaultValues[style.property] = options.tickSize;
    }
    that.setValue(
      style.property as keyof typeof that,
      indicatorDefaultValues[style.property]
    );
  });
}
