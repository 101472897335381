import {
  TigerChartIndicatorAverageTrueRangeOptions,
  TTigerChartIndicatorCreateOptions,
} from './indicators.types';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { AverageTrueRangeBase } from './base/average-true-range-base';

export class TigerChartIndicatorAverageTrueRange extends AverageTrueRangeBase {
  constructor(options: TigerChartIndicatorAverageTrueRangeOptions) {
    super(options);
  }

  override create(
    options: TTigerChartIndicatorCreateOptions
  ): BaseRenderableSeries[] {
    this.updatePoints();
    return super.create(options);
  }

  override updatePoints(): void {
    this.points = this.service.call('ATR', {
      close: this.data.vl_close,
      high: this.data.vl_high,
      low: this.data.vl_low,
      timePeriod: this.timePeriod,
    });
  }

  override updateSettings() {
    this.points = this.service.call('ATR', {
      close: this.data.vl_close,
      high: this.data.vl_high,
      low: this.data.vl_low,
      timePeriod: this.timePeriod,
    });
    super.updateSettings();
  }
}