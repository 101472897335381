<div
  [ngClass]="[css, margin]"
  [class]="labelPlacement === 'horizontal' ? 'hstack' : ''"
>
  <label
    *ngIf="label"
    [for]="refId"
    class="form-label"
    [ngClass]="{ required: isRequired }"
  >
    {{ label }}
  </label>
  <div [ngClass]="{ 'ml-1': labelPlacement === 'horizontal' }">
    <div
      class="input-group"
      [ngClass]="{
        'group-prefix': decidePosition === 'prefix',
        'group-suffix': decidePosition === 'suffix',
        'group-suffix-prefix': decidePosition === 'all'
      }"
    >
      <input
        #inputField
        [type]="type"
        [class]="'form-control group-suffix-prefix ' + cssInput"
        [ngClass]="{ 'form-control-sm': isSmall }"
        [id]="refId"
        [mask]="flaMask"
        [showMaskTyped]="showMaskTyped"
        [selectOnFocus]="selectOnFocus"
        [allowNegativeNumbers]="allowNegativeNumbers"
        [attr.required]="
          isRequired &&
          formControl.enabled &&
          formControl.invalid &&
          formControl.touched &&
          !formControl.pristine
        "
        [formControl]="formControl"
        [attr.aria-describedby]="ariaDescribedby"
        [placeholder]="placeholder"
        [maxlength]="maxLength"
        [minlength]="minLength"
        [max]="max"
        [min]="min"
        [name]="name"
        (input)="flaInput.emit(formControl)"
        (change)="flaChange.emit(formControl)"
        (keydown)="flaKeyDown.emit(formControl)"
        (keyup)="flaKeyUp.emit(formControl)"
        (blur)="flaBlur.emit()"
        [autocomplete]="autoComplete ? 'on' : 'new-password'"
        [name]="name"
        (paste)="preventPasteText($event)"
      />
      <ng-container
        *ngIf="decidePosition === 'prefix' || decidePosition === 'all'"
      >
        <ng-content select=".prefix"></ng-content>
        <ng-content select="fla-input-prefix"></ng-content>
      </ng-container>

      <ng-container
        *ngIf="decidePosition === 'suffix' || decidePosition === 'all'"
      >
        <ng-content select=".suffix"></ng-content>
        <ng-content select="fla-input-suffix"></ng-content>
      </ng-container>
    </div>

    <small class="form-text" *ngIf="hint">{{ hint }}</small>
    <small
      class="ng-invalid invalid-feedback d-block"
      *ngIf="
        (isRequired &&
          formControl.enabled &&
          formControl.invalid &&
          formControl.touched &&
          !formControl.pristine) ||
        formControl.hasError(hasError)
      "
    >
      {{ requiredText }}
    </small>
  </div>
</div>
