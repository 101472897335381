<app-super-dom-header
  *ngIf="stock"
  [stockSelected]="stock"
  [balance]="balance"
  [refComponent]="componentId"
  [component]="headerComponent"
  [headerOptions]="headerOptions()"
  (stockSelect)="selectValues($event)"
></app-super-dom-header>

<section [ngClass]="isDesktop ? 'component-height' : 'h-100'" #superDom>
  <div
    class="h-100 d-flex flex-column"
    flaFocusAndBlur
    (flaFocusBlurChange)="isFocus = $event"
  >
    <div
      *ngIf="stock"
      class="position-relative w-100 h-100"
      [ngClass]="{ 'not-detalhes': !showDetail }"
    >
      <app-table-negotiation
        [refComponent]="refComponent"
        [cdStock]="stock?.cd_stock"
        [cdStockOrder]="stock?.cd_stock_order"
        [idExchange]="stock?.id_exchange"
        [standardLot]="stock?.standard_lot"
        [typeNearest]="stock?.type_nearest"
        [height]="height"
        [width]="width"
        [isFocus]="isFocus"
        [isCrypto]="isCrypto"
        [tickSizeDenominator]="tickSizeDenominator"
        [price]="priceWithoutFormatting"
        [showDetail]="showDetail"
        [showDetailCustody]="showDetailCustody"
        [showVolume]="showVolume"
        [ordersOpen]="ordersOpen"
        [showPowerBar]="showPowerBar"
        (changeEditOrder)="isEditOrder = $event"
        [isLineBlock]="isLineBlock"
        [precoMinimo]="minimumWithoutFormatting"
        [precoMaximo]="maximumWithoutFormatting"
        [hideAggressor]="hideAggressor"
        [showAtPrice]="showAtPrice"
        [showColumnBook]="showColumnBook"
        [showPosition]="showPosition && hasQttdPosition"
        [qttdPosition]="hasQttdPosition"
        [dsAsset]="stock?.ds_asset"
        [securityType]="stock?.cd_security_type"
        [cdSegment]="stock?.cd_segment"
        [vlMinPriceIncrement]="stock?.vlMinPriceIncrement"
        (sendOrderBook)="sendOrderBook($event)"
        (lockChange)="onConfigUpdateHandler($event)"
        [vlPriceAvg]="vlPriceAvg()"
        [qttyForm]="qtdSig()"
      ></app-table-negotiation>
    </div>

    <div
      class="border-top bottom-0 card mt-auto p-2 pt-0 px-1 round-none w-100"
    >
      <div class="card-body">
        <ng-container *ngIf="showDetail">
          <app-details-tab
            [cdStock]="stock?.cd_stock"
            [arrowHex]="arrowHex"
            [precoUltimo]="priceWithoutFormatting"
            [variacaoDia]="variacao_dia"
            [precoMinimo]="minimumWithoutFormatting"
            [precoMaximo]="maximumWithoutFormatting"
            [volume]="vol"
            [tickSizeDenominator]="tickSizeDenominator"
            [dsType]="stock?.ds_type"
            [stockAuctionInfos]="stockAuctionInfos"
          >
          </app-details-tab>
        </ng-container>
        <ng-container *ngIf="showDetailCustody">
          <div
            [id]="SUPER_DOM_ELEMENT_ID.POSITIONS_INFO"
            class="row fw-bold py-1 border-bottom border-thin fs-7 mx-0"
            [ngClass]="{ 'border-top': showDetail }"
          >
            <div class="col-8 d-flex">
              <div class="text-center fs-6">
                <p
                  class="mb-0"
                  [ngClass]="{
                    'position-color': showPosition && hasQttdPosition
                  }"
                >
                  Custódia
                </p>
                <span class="fw-bolder">
                  {{ qttyPositionFormated() }}
                </span>
              </div>

              <div
                tooltip="Quantidade aberta devido a ordens pendente de execução."
                class="mx-3 text-center fs-6"
              >
                <p class="mb-0">Abr</p>
                <span class="fw-bolder">
                  {{ qttyOpenFormated() }}
                </span>
              </div>

              <div tooltip="Preço médio" class="text-center fs-6">
                <p class="mb-0">Médio</p>
                <span class="fw-bolder">{{ vlPriceAvg() }}</span>
              </div>
            </div>

            <div class="col-8 d-flex justify-content-end">
              <ng-container *ngFor="let info of positionInfos">
                <div
                  [tooltip]="info.tooltip + ' ' + (+(position()?.[
                  getPositionField(info)
                ] || 0) | number : '0.2-2')"
                  class="mr-2 text-center fs-6"
                >
                  <p class="mb-0">{{ info.label }}</p>
                  <span class="fw-bolder">
                    {{
                    formatPosition(+(position()?.[getPositionField(info)] || 0))
                    }}
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <div [id]="SUPER_DOM_ELEMENT_ID.COMMANDS" class="d-flex flex-column">
          <div
            class="border-bottom border-thin bg-dark py-1 fs-7"
            [ngClass]="{ 'border-top': !showDetailCustody }"
          >
            <form [formGroup]="form" class="d-flex justify-content-center">
              <app-input-count
                labelPlacement="horizontal"
                formControlName="qtd"
                [isSmall]="true"
                margin="mb-0"
                label="Qtde:"
                [allowNegativeValue]="false"
                [incrementValue]="standardLot"
                [min]="standardLot"
                [isDisabledKeyboard]="isEditOrder"
                paddingSmallCustom="py-1"
                mask="separator.0"
                [css]="
                  qttyChangedColorEnabled
                    ? 'color-modify-qtty max-input qtty fs-7'
                    : 'max-input qtty fs-7'
                "
                [enableScrollEvent]="true"
                [selectOnFocus]="true"
                (rtChange)="qttyChange($event)"
                [isQttyInput]="true"
              ></app-input-count>
              <app-input-count
                labelPlacement="horizontal"
                formControlName="price"
                [isSmall]="true"
                [initialValue]="vlClose"
                [incrementValue]="incrementValue"
                margin="mx-1"
                label="Preço"
                [mask]="priceMask"
                css="max-input fs-7"
                [enableScrollEvent]="true"
                [enableUpdateValueBtn]="true"
                [selectOnFocus]="true"
                (flaScroll)="updatePrice($event)"
                (updateValue)="refreshPrice()"
              >
              </app-input-count>
              <div class="d-flex align-items-center justify-content-start">
                <span
                  class="mt-1 fw-bold"
                  [tooltip]="'Valor Total: ' + total()"
                >
                  {{ totalFormatted() }}
                </span>
              </div>
            </form>
          </div>

          <div class="hstack border-bottom border-thin bg-dark py-1 fs-7">
            <div class="hstack mx-auto">
              <rocket-button
                css="bg-multibroker-buy min-btn"
                label="Comprar"
                [isSmall]="true"
                tooltip="Comprar"
                (rocketClick)="
                  sendOrder(
                    typeOrder.LIMITADA,
                    sideOrders.BUY,
                    orderParamHelper.SUPER_DOM_BUTTON_BUY
                  )
                "
              ></rocket-button>
              <rocket-button
                css="bg-brand-reset-position mx-1 btn-min-width"
                [isSmall]="true"
                label="Zerar"
                [tooltip]="invertAndResetTooltips.reset!"
                (rocketClick)="
                  handlePositionAction(
                    invertOrReserPostionEnum.RESET,
                    orderParamHelper.SUPER_DOM_BUTTON_RESET
                  )
                "
              ></rocket-button>
              <rocket-button
                css="bg-multibroker-sell min-btn"
                label="Vender"
                [isSmall]="true"
                tooltip="Vender"
                (rocketClick)="
                  sendOrder(
                    typeOrder.LIMITADA,
                    sideOrders.SELL,
                    orderParamHelper.SUPER_DOM_BUTTON_SELL
                  )
                "
              ></rocket-button>
            </div>
          </div>

          <div class="hstack border-bottom border-thin bg-dark py-1 fs-7">
            <div class="hstack mx-auto">
              <rocket-button
                css="bg-multibroker-buy min-btn"
                [label]="labelMultibrokerBuy"
                tooltip="Comprar a mercado"
                [isSmall]="true"
                (rocketClick)="
                  sendOrder(
                    typeOrder.MARKET,
                    sideOrders.BUY,
                    orderParamHelper.SUPER_DOM_BUTTON_BUY_MARKET
                  )
                "
              ></rocket-button>
              <rocket-button
                css="bg-brand-support-secondary mx-1 btn-min-width"
                [isSmall]="true"
                label="Inverter"
                [tooltip]="invertAndResetTooltips.invert!"
                (rocketClick)="
                  handlePositionAction(
                    invertOrReserPostionEnum.INVERT,
                    orderParamHelper.SUPER_DOM_BUTTON_INVERT
                  )
                "
              ></rocket-button>
              <rocket-button
                css="bg-multibroker-sell min-btn"
                [label]="labelMultibrokerSell"
                tooltip="Vender a mercado"
                [isSmall]="true"
                (rocketClick)="
                  sendOrder(
                    typeOrder.MARKET,
                    sideOrders.SELL,
                    orderParamHelper.SUPER_DOM_BUTTON_SELL_MARKET
                  )
                "
              ></rocket-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
