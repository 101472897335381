import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';

import { RT_COLOR_EVENT } from '../types';
import { rgbToHex } from '@shared/rocket-components/utils';

@Component({
  selector: 'rt-color-palette',
  templateUrl: './color-palette.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class RTColorPaletteComponent implements AfterViewInit {
  @Input() set hue(value: string) {
    if (!value) return;
    this._hue = value;
    if (this.ctx) this.draw();
    const pos = this.selectedPosition;
    if (pos) {
      this._emitColor(pos.x, pos.y);
    }
  }
  @Input() width: number = 180;
  @Input() height: number = 180;
  @Output() color: EventEmitter<RT_COLOR_EVENT> =
    new EventEmitter<RT_COLOR_EVENT>();
  @ViewChild('canvas') canvas!: ElementRef<HTMLCanvasElement>;
  selectedPosition!: { x: number; y: number };
  private ctx!: CanvasRenderingContext2D;
  private mousedown: boolean = false;
  private _hue!: string;

  ngAfterViewInit() {
    this.draw();
  }

  draw() {
    if (!this.ctx) {
      this.ctx = this.canvas.nativeElement.getContext('2d')!;
    }
    const width = this.canvas.nativeElement.width;
    const height = this.canvas.nativeElement.height;

    this.ctx.fillStyle = this._hue || 'rgba(255,255,255,1)';
    this.ctx.fillRect(0, 0, width, height);

    const whiteGrad = this.ctx.createLinearGradient(0, 0, width, 0);
    whiteGrad.addColorStop(0, 'rgba(255,255,255,1)');
    whiteGrad.addColorStop(1, 'rgba(255,255,255,0)');

    this.ctx.fillStyle = whiteGrad;
    this.ctx.fillRect(0, 0, width, height);

    const blackGrad = this.ctx.createLinearGradient(0, 0, 0, height);
    blackGrad.addColorStop(0, 'rgba(0,0,0,0)');
    blackGrad.addColorStop(1, 'rgba(0,0,0,1)');

    this.ctx.fillStyle = blackGrad;
    this.ctx.fillRect(0, 0, width, height);

    if (this.selectedPosition) {
      this.ctx.strokeStyle = 'white';
      this.ctx.fillStyle = 'white';
      this.ctx.beginPath();
      this.ctx.arc(
        this.selectedPosition.x,
        this.selectedPosition.y,
        10,
        0,
        2 * Math.PI
      );
      this.ctx.lineWidth = 5;
      this.ctx.stroke();
    }
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(evt: MouseEvent) {
    evt.preventDefault();
    this.mousedown = false;
    this._emitColor(evt.offsetX, evt.offsetY);
  }

  onMouseDown(evt: MouseEvent) {
    this.mousedown = true;
    this.selectedPosition = { x: evt.offsetX, y: evt.offsetY };
    this.draw();
    this._emitColor(evt.offsetX, evt.offsetY);
  }

  onMouseMove(evt: MouseEvent) {
    if (this.mousedown) {
      this.selectedPosition = { x: evt.offsetX, y: evt.offsetY };
      this.draw();
      this._emitColor(evt.offsetX, evt.offsetY);
    }
  }

  private _emitColor(x: number, y: number) {
    this.color.emit(this._getColorAtPosition(x, y));
  }

  private _getColorAtPosition(x: number, y: number): RT_COLOR_EVENT {
    const imageData = this.ctx.getImageData(x, y, 1, 1).data;
    return {
      rgb:
        'rgb(' + imageData[0] + ',' + imageData[1] + ',' + imageData[2] + ',1)',
      hex: rgbToHex(imageData[0], imageData[1], imageData[2]),
    };
  }
}
