<div class="d-flex flex-column gap-2" *ngIf="rows.length; else loadingRef">
  <div *ngIf="remuneracao" class="d-flex">
    <fla-card class="w-100 d-flex flex-wrap gap-2">
      <fla-card-custom
        #card
        [css]="'p-1 bg-neutral-strong mt-2'"
        *ngFor="let card of cards"
      >
        <div
          [tooltip]="remuneracao[card.tooltip]"
          class="min-size-card card-body justify-content-center align-items-center"
        >
          <div
            class="d-flex flex-column align-items-center h-100 justify-content-center"
          >
            <span class="text-neutral-medium text-truncate fs-5">{{
              card.title
            }}</span>
            <span class="fs-5">{{ remuneracao[card.field] }}</span>
          </div>
        </div>
      </fla-card-custom>
    </fla-card>
  </div>
  <table *ngIf="!error; else noInfo" class="table">
    <thead>
      <tr class="w-100 d-flex">
        <th scope="col" class="fs-6 fw-bold text-left w-40">Nome</th>
        <th scope="col" class="fs-6 fw-bold text-left w-50">Cargo</th>
        <th scope="col" class="fs-6 fw-bold text-left w-10"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let row of rows">
        <tr class="text-white align-baseline w-100 d-flex">
          <td class="py-1 text-white p-0 pl-0 text-left text-nowrap w-40">
            <span>{{ row.member_name }}</span>
          </td>
          <td
            class="py-1 text-white p-0 pl-0 text-left text-nowrap w-50 overflow-hidden"
          >
            <span>{{ row.cargo }}</span>
          </td>
          <td class="py-1 text-white p-0 pl-0 text-center text-nowrap w-10">
            <span
              class="cursor-pointer text-brand-primary"
              (click)="openResume(row)"
              >Ver currículo</span
            >
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<ng-template #loadingRef>
  <div class="h-100">
    <app-loading></app-loading>
  </div>
</ng-template>

<ng-template #noInfo>
  <div class="h-100 w-100 d-flex justify-content-center align-items-center">
    <app-no-info></app-no-info>
  </div>
</ng-template>
