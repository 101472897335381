<form [formGroup]="loginForm">
  <app-login-info-messages></app-login-info-messages>

  <div class="mb-3">
    <fla-input
      fla-focus
      formControlName="username"
      [placeholder]="usernameFieldPlaceholder"
      [label]="usernameFieldLabel"
      [requiredText]="requiredText"
      hasError="invalid"
      type="text"
      [isRequired]="true"
      (flaKeyUp)="allowCaracters($event)"
    >
    </fla-input>
  </div>

  <div class="mb-3">
    <fla-input
      formControlName="password"
      placeholder="Senha"
      label="Senha"
      [requiredText]="requiredText"
      [type]="showPassword ? 'text' : 'password'"
      hasError="invalid"
      [isRequired]="true"
      decidePosition="suffix"
    >
      <div class="suffix">
        <rocket-button type="icon" (rocketClick)="showPassword = !showPassword">
          <fla-icon
            [name]="showPassword ? 'visibility' : 'visibility_off'"
          ></fla-icon>
        </rocket-button>
      </div>
    </fla-input>
  </div>
  <div class="mb-3 d-flex justify-content-end forgot-password-btn">
    <a (click)="openForgotPass()" class="cursor-pointer">Esqueci a senha</a>
  </div>
  <div class="mt-3">
    <app-recaptcha
      #recaptcha
      size="invisible"
      (resolved)="onResolved($event)"
      (errored)="onError()"
    ></app-recaptcha>
  </div>
  <rocket-button
    label="Entrar na minha conta"
    type="default"
    btnType="submit"
    css="w-100 default-opacity"
    [loading]="isLoading"
    [isDisabled]="isFormInvalid"
    (rocketClick)="makeRecaptchaExecute()"
  >
  </rocket-button>
  <ng-container *ngIf="displayCreateAccount">
    <rocket-button
      label="Criar uma conta"
      type="outline"
      (rocketClick)="goToRegister()"
      css="w-100 mt-2 create-account-btn"
    >
    </rocket-button>
  </ng-container>
  <div class="mt-5 alert" role="alert" *ngIf="recoveryPassMessage">
    <fla-icon [css]="'pr-3 text-feedback-success'" name="check"></fla-icon>
    {{ recoveryPassMessage }}
  </div>
  <div class="mt-5 alert" role="alert" *ngIf="onboardCompletedMessage">
    <fla-icon [css]="'pr-3 text-feedback-success'" name="check"></fla-icon>
    {{ onboardCompletedMessage }}
  </div>

  <ng-container *ngIf="!invalidUserInfos">
    <app-logout-message [message]="logoutMessage"></app-logout-message>
  </ng-container>

  <div
    class="mt-5 alert"
    role="alert"
    *ngIf="invalidUserInfos && loginForm.invalid"
  >
    <span *ngIf="!blockedPassword; else passwordBlocked">
      <i class="icons pr-3 text-feedback-error">error</i>
      {{ invalidUserInfos }}
    </span>
    <ng-template #passwordBlocked>
      <app-password-blocked></app-password-blocked>
    </ng-template>
  </div>
</form>
