import { inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigService } from '@core/config';
import { UpdateFieldOnboardInterface } from '@shared/services/api/trademap/onboard';
import { adultAgeValidator } from '@shared/validators/adult-age-validator';
import { cpfOrRneValidator } from '@shared/validators/cpf-or-rne-validator';
import { emailValidator } from '@shared/validators/email-validator';
import { fullNameValidator } from '@shared/validators/full-name-validator';
import { removeLeadingZeros } from '@shared/validators/functions';
import { removeNonNumericChars } from '@shared/validators/functions/validateDates';
import {
  compareIsSameValue,
  confirmPasswordValidator,
  passwordValidator,
} from '@shared/validators/password-validator';
import { Observable, filter, map, tap } from 'rxjs';

export abstract class CreateForm implements CreateFormInterface {
  createForm!: FormGroup;
  documentMask: string = '';
  documentMaxLength: number = 15;
  dateMask: string = '00/00/0000';
  public isDev: boolean;
  errorMessages = {
    email: 'Preencha com um e-mail válido',
    document: 'Preencha com um documento válido',
    name: 'Preencha um nome válido',
    vl_birthdate: 'Preencha uma data válida',
    password: 'Preencha a senha corretamente',
    confirm_password: 'As senhas estão diferentes',
  };

  get email() {
    return this.createForm.get('email')!;
  }

  get document() {
    return this.createForm.get('document')!;
  }

  get name() {
    return this.createForm.get('name')!;
  }

  get vl_birthdate() {
    return this.createForm.get('vl_birthdate')!;
  }

  get password() {
    return this.createForm.get('password')!;
  }

  get confirm_password() {
    return this.createForm.get('confirm_password')!;
  }

  get onEmailChange(): Observable<any> {
    return this.email.valueChanges.pipe(
      filter(() => this.email?.valid ?? false)
    );
  }

  get onDocumentChange(): Observable<any> {
    return this.document.valueChanges.pipe(
      map((value) => removeLeadingZeros(value))
    );
  }

  get onNameChanges(): Observable<UpdateFieldOnboardInterface> {
    return this.name.valueChanges.pipe(
      map((value) => {
        return {
          field: 'name',
          value,
        };
      }),
      filter(() => this.name?.valid ?? false)
    );
  }

  get onDtNascimentoChanges(): Observable<UpdateFieldOnboardInterface> {
    return this.vl_birthdate?.valueChanges.pipe(
      map((value) => {
        return {
          field: 'vl_birthdate',
          value: removeNonNumericChars(value),
        };
      }),
      filter(() => this.vl_birthdate?.valid ?? false)
    );
  }

  // get onPhoneChange(): Observable<UpdateFieldOnboardInterface> {
  //   return this.phone_number?.valueChanges.pipe(
  //     filter(() => this.phone_number?.valid ?? false),
  //     map((value) => {
  //       return {
  //         field: 'phone_number',
  //         value: getPhoneCountryValue(value, this.phone_country_code.value),
  //       };
  //     })
  //   );
  // }

  get onPasswordChanges(): Observable<UpdateFieldOnboardInterface> {
    return this.password?.valueChanges.pipe(
      map((value) => {
        return {
          field: 'password',
          value,
        };
      }),
      tap(() => this.confirm_password.updateValueAndValidity())
    );
  }

  get onConfirmPasswordChanges(): Observable<UpdateFieldOnboardInterface> {
    return this.confirm_password?.valueChanges.pipe(
      map((value) => {
        return {
          field: 'password',
          value,
        };
      }),
      filter(() => this.confirm_password?.valid ?? false)
    );
  }

  get formValues() {
    return this.createForm.value;
  }

  get isFormValid() {
    return this.createForm?.valid;
  }
  private config = inject(ConfigService);
  constructor(private fb: FormBuilder) {
    this.isDev = this.config.isDev();
    this.createForm = this.fb.group({
      email: [
        '',
        {
          validators: [
            Validators.required,
            Validators.maxLength(100),
            emailValidator,
          ],
        },
      ],
      document: [
        '',
        {
          validators: [Validators.required, cpfOrRneValidator()],
        },
      ],
      name: [
        '',
        {
          validators: [
            Validators.required,
            Validators.maxLength(100),
            fullNameValidator,
          ],
        },
      ],
      vl_birthdate: [
        '',
        { validators: [Validators.required, adultAgeValidator()] },
      ],
      password: [
        '',
        {
          validators: [
            Validators.required,
            passwordValidator(),
            compareIsSameValue('confirm_password'),
          ],
        },
      ],
      confirm_password: [
        '',
        {
          validators: [
            Validators.required,
            confirmPasswordValidator('password'),
          ],
        },
      ],
    });
  }

  setFormInvalid() {
    this.createForm.setErrors({
      customError: true,
      otherError: 'Algum erro ocorreu',
    });
  }
}

export interface CreateFormInterface {
  createForm: FormGroup;
  email: any;
  document: any;
  name: any;
  vl_birthdate: any;
  password: any;
  confirm_password: any;
  onNameChanges: Observable<UpdateFieldOnboardInterface>;
  onDtNascimentoChanges: Observable<UpdateFieldOnboardInterface>;
  onPasswordChanges: Observable<UpdateFieldOnboardInterface>;
  onConfirmPasswordChanges: Observable<UpdateFieldOnboardInterface>;
}
