import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CandleChannel } from '@shared/channel/candle.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import {
  RocketModalRef,
  RocketModalService,
  ROCKET_MODAL_DATA,
} from '@shared/rocket-components/components/index';
import { ToastService } from '@shared/services';
import { TIGER_INTERVAL_ENUM } from '@shared/tiger-chart/enum';
import { randomId } from 'src/app/utils/utils.framework';
import { getIncrementValue } from 'src/app/utils/utils.functions';

export interface IChangeAlertPrice {
  id_trade_system: number;
  price: number;
  cd_stock: string;
  cd_status: string;
  vl_close: number;
}

@Component({
  selector: 'app-edit-alert-modal',
  templateUrl: './edit-alert-modal.component.html',
})
export class EditAlertModalComponent
  extends RocketModalRef
  implements OnInit, OnDestroy
{
  public alertForm!: FormGroup;
  public modalMaxValue = 999999999999999;
  public alertPriceInvalid: boolean = false;
  public priceErrorMessage: string = '';
  public incrementValue: number = 1;
  public initialValue!: number;
  public mask: string = '';
  private refComponent = randomId('edit-alert-modal');
  private candleParams!: SubscribeParam;
  private vlClose = 0;
  private legSubscribed!: string;

  constructor(
    @Inject(ROCKET_MODAL_DATA)
    public data: any,
    private __rocketModalService: RocketModalService,
    private _formBuilder: FormBuilder,
    private _toast: ToastService,
    private candleChannel: CandleChannel
  ) {
    super(__rocketModalService);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.candleParams && this.candleChannel.unsubscribe(this.candleParams);
  }

  ngOnInit(): void {
    this._buildAlertForm();
    this.incrementValue = getIncrementValue(this.data);
    this.legSubscribed = `${this.data.cd_stock}.${TIGER_INTERVAL_ENUM.ONE_MINUTE}:${this.data.id_exchange}`;
    this.candleChannel.onEvents().then((data) => {
      this.readStream(data.stream, this._candleHandler);
    });
    this.candleParams = {
      header: this.refComponent,
      itemsArray: [this.legSubscribed],
    };
    this.candleChannel.subscribe(this.candleParams);
    this.initialValue = this.data.vl_value;
    this.setMask();
  }

  private _candleHandler = (data: any) => {
    if (data.item !== this.legSubscribed) {
      return;
    }
    this.vlClose = +data.vl_close;
  };

  private _buildAlertForm(): void {
    this.alertForm = this._formBuilder.group({
      price: [0, Validators.required],
    });
  }

  public changeAlertPrice(): void {
    const newAlertPrice = this.alertForm.controls['price'].value;

    if (
      newAlertPrice === this.data.vl_value &&
      this.data.cd_status === 'ACTIVE'
    ) {
      this._toast.showToast(
        'warning',
        'Por favor informe um preço diferente do já salvo.'
      );
      return;
    }

    this.close({
      price: newAlertPrice,
      id_trade_system: this.data.id_trade_system,
      cd_stock: this.data.cd_stock,
      cd_status: this.data.cd_status,
      vl_close: this.vlClose,
    });
  }

  public verifyAlertPrice(event: any): void {
    const price = event.value;
    const incrementRule = getIncrementValue(this.data);
    const isValidPrice = Number.isInteger(price / incrementRule);

    if (incrementRule === 1 || isValidPrice) {
      this.alertPriceInvalid = false;
      this.priceErrorMessage = '';
      return;
    }
    this.alertPriceInvalid = true;
    this.priceErrorMessage = `Valor padrão ${new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(incrementRule)}.`;
  }

  private setMask(): void {
    if (this.incrementValue === 5) {
      this.mask = 'separator.0';
      return;
    }
    if (this.incrementValue === 0.5) {
      this.mask = 'separator.1';
      return;
    }
    this.mask = 'separator.2';
  }
}
