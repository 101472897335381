import { isNullOrUndefined } from 'src/app/utils/utils.functions';

/**
 * Função utilizada para gerar id aleatorios.
 * @param name tipo string
 * @return - Retorna string
 * @example - 'fla_button_201317838'
 *
 * @usageNotes
 *
 * ```ts
 *  const idButton: string = randomId('fla_button')
 *  ...
 * ```
 * ### Nota
 * * In case of error will be throw exception`,
 *
 * @Annotation
 */
export function randomId(name: string): string {
  const array = new Uint32Array(10);
  const id = crypto.getRandomValues(array)[0];
  return `${name}_${id}`;
}

/**
 * Função utilizada para verificar valores vazios como `undefined` `null`  `{}` `[]` ou `empty`.
 * @param value tipo any
 * @return - Retorna `true` ou `false`
 *
 * @usageNotes
 *
 * ```ts
 *  const checkInput: boolean = isNullOrWhiteSpace(obj.price)
 *  if(checkInput){
 *  ...
 * ...
 *  }
 * ```
 * ### Nota
 * * In case of error will be throw exception`,
 *
 * @Annotation
 */
export function isNullOrWhiteSpace(value: any): boolean {
  switch (true) {
    case value instanceof Object:
      return Object.keys(value).length === 0;

    case value instanceof Array:
      return value?.length <= 0;

    default:
      return (
        value === undefined || value === null || value === '' || value === ' '
      );
  }
}

/**
 * Formatar valor numerico
 * O valor precisa ser do tipo `number` ou `string`
 * @param value  tipo `number` ou `string`
 * @param option - default value: { maximumFraction: 2, minimumFraction: 2 }
 * @param locale - default value: pt-BR
 * @return - 20,00
 *
 * @usageNotes
 *
 * ```ts
 *  let price: string = formatterNumber(20, { minimumFraction: 3, style: 'currency', currency: 'BRL' })
 * // Output: R$ 20,000
 * ```
 * ### Notes
 * * In case value null or undefined return `-`,
 * * In case of error will be throw exception`,
 *
 * @Annotation
 */
export function formatterNumber(
  value: number | string,
  options: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  },
  locale: string = 'pt-BR',
  symbol: string = ''
): string {
  if (value == undefined || value == null) {
    return '-';
  }

  return `${value.toLocaleString(locale, options)}${symbol}`;
}

/**
 * Obter variação a partir do preço de fechamento do momento, e do preço de fechamento mais atual
 * O valor do preço precisa ser do tipo `number`
 * @param price  tipo `number`
 * @param last_vl_close  tipo `number`
 *
 * @usageNotes
 *
 * ```ts
 *  let otherVariation: string = getVariationByPrice(20, 13)
 * // Output: 53,84
 * ```
 * ### Notes
 * * In case of error will be throw exception`,
 *
 * @Annotation
 */
export function getVariationByPrice(
  price: number,
  last_vl_close: number
): string {
  const value = (price / last_vl_close - 1) * 100;
  return formatterNumber(value);
}

/**
 * Converter Data Time para tipo Number
 * O valor do preço precisa ser do tipo `string` seguindo os formatos: '01:10:00' ou '01:10'
 * @param time  tipo `string`
 *
 * @usageNotes
 *
 * ```ts
 *  let timeNumber: number = timeToNumber('01:10:00')
 * // Output: 4200
 * ```
 * ### Notes
 * * In case of error will be throw exception`,
 *
 * @Annotation
 */
export function timeToTimestamps(time: string): number {
  const array = time.split(':');

  return (
    parseInt(array[0], 10) * 60 * 60 +
    parseInt(array[1], 10) * 60 +
    (array.length === 3 ? parseInt(array[2], 10) : parseInt('00', 10))
  );
}

/**
 * Converter cor de Hex para RGBA
 * @param hex  tipo `string`
 * @param opacity  tipo `number` default 1
 *
 * @usageNotes
 *
 * ```ts
 *  let toRgba:string = hex2rgba('#ffff')
 * // Output: rgba(255 255 255, 1)
 * ```
 * ### Notes
 * * In case of error will be throw exception`,
 *
 * @Annotation
 */
export const hex2rgba = (hex: string, opacity: number = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${opacity})`;
};

/**
 * Converter cor de RGBA para Hex
 * @param r  tipo `number`
 * @param g  tipo `number`
 * @param b  tipo `number`
 *
 * @usageNotes
 *
 * ```ts
 *  let toRgba:string = rgbToHex(255, 255, 255)
 * // Output: #ffff
 * ```
 * ### Notes
 * * In case of error will be throw exception`,
 *
 * @Annotation
 */
export const rgbToHex = (r: number, g: number, b: number) => {
  const R = r.toString(16).length == 1 ? '0' + r.toString(16) : r.toString(16);
  const G = g.toString(16).length == 1 ? '0' + g.toString(16) : g.toString(16);
  const B = b.toString(16).length == 1 ? '0' + b.toString(16) : b.toString(16);
  return '#' + R + G + B;
};

/**
 * Remove all special caracters from value
 * @param value - Value Like 2.0.1 or Teste-teste
 * @return - Return the value without special caracters. 201 or Testeteste
 *
 * @usageNotes
 *
 * ```ts
 *  let newValue: string = removeMask('21/08/2021')
 * 	console.log('21082021')
 *
 * ```
 * ### Notes
 * * In case of error will be return `null`,
 *
 * @Annotation
 */
export const removeMask = (value: string) =>
  value?.replace(/[^a-zA-Z0-9]/g, '') ?? null;

/**
 * Cria uma copia do objeto sem manter referencia
 * @param obj - Object
 * @return - Returns a copy of the object
 *
 * @Annotation
 */
export function deepClone(
  obj: any | object | Array<object>
): any | object | object[] {
  if (obj == undefined || obj == null) {
    return obj;
  }

  const cloneObj = JSON.parse(JSON.stringify(obj));
  return cloneObj;
}

function splitValue(value: string): string {
  try {
    const formatted = formatterNumber(+value);
    const list = formatted.split('.');
    return `${list[0]},${list[1].slice(0, 2)}`;
  } catch (error) {
    return value;
  }
}

/**
 * Format big values
 * @param value - number | string
 * @return - Returns big formatted values
 *
 * ### Notes
 * * In case value null or undefined return `-`,
 *
 * @Annotation
 */
export function bigValueFormatter(
  value: number | string,
  options: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }
): string {
  if (value == undefined || value == null) {
    return '-';
  }
  const big = (+value).toFixed();
  let bigFormatter = '-';
  big.length >= 15 && (bigFormatter = `${splitValue(big)}T`);
  big.length >= 11 && big.length < 15 && (bigFormatter = `${splitValue(big)}B`);
  big.length >= 7 && big.length < 11 && (bigFormatter = `${splitValue(big)}M`);
  big.length >= 4 && big.length < 7 && (bigFormatter = `${splitValue(big)}K`);
  if (big.length <= 3) {
    const decimal = value.toString().split('.')[1];
    if (decimal && +decimal > 0) {
      bigFormatter = formatterNumber(+value, options);
    } else {
      bigFormatter = formatterNumber(+value, options);
    }
  }
  return bigFormatter;
}

/**
 * Format big values
 * @param value - number
 * @return - Returns big formatted values
 *
 * ### Notes
 * * In case value null or undefined return `-`,
 * * Accept only numbers without dots
 * @Annotation
 */
export function bigValueFormatterWithoutDots(value: number) {
  const prefix = value < 0 ? '-' : '';
  if (prefix) value = Math.abs(value);
  if (value >= 1e12) return `${prefix}${(value / 1e12).toFixed(2)}T`;
  if (value >= 1e9) return `${prefix}${(value / 1e9).toFixed(2)}B`;
  if (value >= 1e6) return `${prefix}${(value / 1e6).toFixed(2)}M`;
  if (value >= 1e3) return `${prefix}${(value / 1e3).toFixed(2)}K`;
  return prefix + value.toFixed(2);
}

/**
 * Format join values
 * @param firstValue - number | string
 * @param secondValue - number | string
 * @return - Returns join values and formatter
 *
 * @Annotation
 */
export function joinValuesAndFormatter(
  firstValue: number | string,
  secondValue: number | string,
  options?: Intl.NumberFormatOptions,
  separator: string = '-'
): string {
  if (isNullOrUndefined(firstValue) && isNullOrUndefined(secondValue)) {
    return '-';
  } else if (isNullOrUndefined(firstValue)) {
    return formatterNumber(+secondValue, options);
  } else if (isNullOrUndefined(secondValue)) {
    return formatterNumber(+firstValue, options);
  }

  return `${formatterNumber(
    +firstValue,
    options
  )} ${separator} ${formatterNumber(+secondValue, options)}`;
}

/**
 *  Converte valores como 202209081650 para data
 * @param value - string
 * @param locale - fuso horario a ser fixo na data. Ex: GMT-0300
 * @return - string format date like dd/MM/yyyy hh:mm:ss
 *
 * ### Notes
 * * The function check if length includes 8, 12 or 14 before apply the better formart.
 *
 * @Annotation
 */
export function standardizeDate(value: string, locale: string = 'GMT-0300'): string {
  let date: string;
  switch (value?.length) {
    case 8:
      date = value.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');
      break;
    case 12:
      date = value.replace(
        /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/,
        '$1/$2/$3 $4:$5'
      );
      break;
    case 14:
      date = value.replace(
        /^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/,
        '$1-$2-$3 $4:$5:$6'
      );
      break;
    default:
      return '-';
  }

  return `${date} ${locale}`;
}

/**
 * Check param and return a ISO String Date
 * Params can be: 2024-04-16 10:28:03.88177 or 202209081650
 * @param value - string
 * @returns - ISO String
 */
export function getValidStringDate(value: string): string {
  const time = new Date(value).getTime();
  return isNaN(time) ? standardizeDate(value) : value;
}

/**
 *
 * @param value - string
 * @return - Returns string format date
 *
 * @Annotation
 */
export function dateTimeFormatter(
  value: string,
  format?: 'date' | 'time'
): string {
  if (!value?.length) {
    return '-';
  }
  const date = getValidStringDate(value);
  const formatted = new Date(date).toLocaleString('pt-br');
  let valueFormatted = '';
  !format && (valueFormatted = formatted);
  format === 'date' &&
    (valueFormatted = formatted.split(' ').shift() as string);
  format === 'time' && (valueFormatted = formatted.split(' ').pop() as string);
  return valueFormatted;
}

/**
 * Convert a string date to type date
 * @param value - Date like DD/MM/YYYY
 * @return - Return a new date like YYYY-MM-DD 00:00:00
 *
 * @usageNotes
 *
 * ```ts
 *  let date: Date = stringToDate('21/08/2021')
 *
 * ```
 * ### Notes
 * * The time aways will return 00:00:00.
 * * In case of error will be return `undefined`,
 *
 * @Annotation
 */
export const stringToDate = (value: string): Date | undefined => {
  if (!isNullOrWhiteSpace(value)) {
    return new Date(value.split('/').reverse().join('-') + ' 00:00:00');
  }

  return undefined;
};
