<ng-container *ngIf="!concluded; else congrats">
  <div
    *ngIf="!errorOnCreateAccount.error"
    class="align-items-center d-flex flex-column gap-3 my-3 text-center w-100"
  >
    <h3>Criando a sua conta, por favor aguarde!</h3>
    <div class="spinner-border text-brand-primary" role="status"></div>
  </div>
</ng-container>

<ng-template #congrats>
  <div class="overflow-y-hidden row">
    <h3>Conta criada!</h3>
    <h4 class="my-5">
      Agora é só entrar na sua conta e aproveitar todas as vantagens do
      RocketTrader!
    </h4>

    <app-recaptcha
      #recaptcha
      size="invisible"
      (resolved)="authenticate($event)"
      (errored)="onRecaptchaError()"
    ></app-recaptcha>

    <rocket-button
      label="Entrar na minha conta"
      type="default"
      btnType="button"
      css="w-100 mb-3"
      [loading]="authenticating"
      (rocketClick)="doRecaptcha('CLICK')"
    >
    </rocket-button>

    <app-timer-resend
      *ngIf="displayCountDown"
      [seconds]="timeRedirect"
      message="Redirecionamento Automático "
      (timerCompleted)="doRecaptcha('COUNT_DOWN')"
    ></app-timer-resend>
  </div>
</ng-template>

<ng-container *ngIf="errorOnCreateAccount.error">
  <div
    class="align-items-center border border-neutral-strong d-inline-flex justify-content-start p-2 round mt-3"
  >
    <i class="icons pr-3 text-feedback-error">error</i>
    <span
      class="fs-6"
      *ngIf="errorOnCreateAccount.createError; otherMessageError"
    >
      Ops, ocorreu um erro ao tentar criar a sua conta.
      <span
        class="text-decoration-underline fw-bold bg-transparent border-none text-white p-0 fs-6 cursor-pointer"
        (click)="backToForm()"
      >
        Por favor, clique aqui e tente novamente.
      </span>
    </span>
    <ng-template>
      <span class="fs-6">{{ errorOnCreateAccount.message }}</span>
    </ng-template>
  </div>
</ng-container>
