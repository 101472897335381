export const doubleExponentialMovingAverageTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  A média móvel exponencial dupla (DEMA) é um indicador de análise técnica que
  utiliza duas médias móveis exponenciais (EMAs) para determinar tendências de
  preços em um ativo. A DEMA é calculada tomando a média das diferenças entre
  duas EMAs, uma de período curto e outra de período longo.
</div>
<div class="mb-3">
  A DEMA é útil para identificar mudanças de tendência em um mercado, pois a
  média móvel de período curto é mais sensível às flutuações de preços recentes,
  enquanto a média móvel de período longo ajuda a suavizar as flutuações de
  preços de longo prazo. Quando a média móvel de período curto cruza acima da
  média móvel de período longo, isso pode ser um sinal de uma mudança de
  tendência de baixa para alta. Da mesma forma, quando a média móvel de período
  curto cruza abaixo da média móvel de período longo, isso pode ser um sinal de
  uma mudança de tendência de alta para baixa.
</div>
<div class="mb-3">
  Em resumo, a DEMA é uma ferramenta útil para traders que desejam identificar
  tendências de preços em um ativo e tomar decisões de compra ou venda com base
  nesses sinais.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">
  Média Móvel Exponencial Dupla (DEMA) é calculada tomando a média das
  diferenças entre duas EMAs, uma de período curto e outra de período longo. A
  fórmula para o cálculo da DEMA é a seguinte:
</div>
<div class="mb-3">DEMA = 2 * EMA(n) - EMA(EMA(n))</div>
<div class="mb-3">Onde:<br /></div>
<div class="mb-3">
  - EMA(n) = Média Móvel Exponencial de período n<br />
  - EMA(EMA(n)) = Média Móvel Exponencial da EMA(n)
</div>
<div class="mb-3">
  Por exemplo, para calcular a DEMA de um ativo com um período curto de 10 dias
  e um período longo de 20 dias, primeiro calcule a EMA (10) e a EMA (20). Em
  seguida, calcule a DEMA usando a fórmula acima.
</div>
<div class="fw-bolder">Exemplo de cálculo de DEMA</div>

<div class="mb-3">
  Suponha que queremos calcular a DEMA de um ativo com um período curto de 5
  dias e um período longo de 10 dias. Primeiro, calculamos a EMA (5) e a EMA
  (10) como segue:
</div>
<div class="mb-3">
  &bull; Calcule a EMA (5) usando a fórmula EMA (5) = Preço atual x (2 / (5 +
  1)) + EMA anterior x (1 - (2 / (5 + 1)))<br />
  &bull; Calcule a EMA (10) usando a fórmula EMA (10) = Preço atual x (2 / (10 +
  1)) + EMA anterior x (1 - (2 / (10 + 1)))
</div>
<div class="mb-3">
  Depois de calcular a EMA (5) e a EMA (10), podemos calcular a DEMA usando a
  fórmula DEMA = 2 * EMA (5) – EMA (EMA(5)). O resultado será a DEMA para o
  período especificado.
</div>
<div class="mb-3">
  A DEMA é uma ferramenta útil para traders que desejam identificar tendências
  de preços em um ativo e tomar decisões de compra ou venda com base nesses
  sinais.
</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  A Média Móvel Exponencial Dupla (DEMA) pode ser usada para identificar
  tendências de preços em um ativo e tomar decisões de compra ou venda com base
  nesses sinais. Quando a média móvel de período curto cruza acima da média
  móvel de período longo, isso pode ser um sinal de uma mudança de tendência de
  baixa para alta. Da mesma forma, quando a média móvel de período curto cruza
  abaixo da média móvel de período longo, isso pode ser um sinal de uma mudança
  de tendência de alta para baixa.
</div>
<div class="mb-3">
  É importante lembrar que nenhum indicador é infalível e que os traders devem
  usar a DEMA em conjunto com outras ferramentas de análise técnica e
  fundamental para tomar decisões de investimento informadas.
</div>
<div class="mb-3">
  <img class="w-75" src="/assets/indicators/dema-como-funciona.png" />
</div>
<div class="fw-bolder">Como configurar</div>
<div class="fw-bolder">Valores</div>
<div class="text-decoration-underline">Período</div>
<div class="mb-3">
  O período a ser usado no cálculo da média móvel. 9 dias é o padrão.
</div>
<div class="text-decoration-underline">Fonte</div>
<div class="mb-3">
  Determina quais dados de cada barra serão usados nos cálculos. Fechamento é o
  padrão.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/dema-entrada.png"
  />
</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">DEMA</div>
<div class="mb-3">
  Pode alternar a visibilidade da DEMA. Também pode selecionar a cor, a
  espessura e o estilo da linha do DEMA.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/dema-estilo.png"
  />
</div>
`;
