import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OnboardService } from '@shared/services/api/trademap/onboard';
import { RecaptchaComponent } from '../recaptcha/recaptcha.component';
import { ActivatedRoute, Router } from '@angular/router';
import { map, take } from 'rxjs';
import { TermsService } from '@shared/services/api/terms/v2/terms.service';
import { internationalPhoneValidator } from '@shared/validators/phone-validator';
import {
  IInternationalNumber,
  PhoneInputComponent,
} from '@shared/rocket-components/components';
import { ONBOARD_PARTNERS_ORIGIN } from '@shared/services/core/partners-services/partners.const';
import { PartnersService } from '@shared/services/core/partners-services/partners.service';

@Component({
  selector: 'app-register-phone',
  templateUrl: './register-phone.component.html',
  styles: [
    `
      :host {
        ::ng-deep.grecaptcha-badge {
          display: none !important;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterPhoneComponent implements OnInit, AfterViewInit {
  @ViewChild('recaptcha') appRecaptcha!: RecaptchaComponent;
  @ViewChild('phoneInput') phoneInput!: PhoneInputComponent;

  public form!: FormGroup;
  public isLoading = false;
  public acceptedTerms = false;
  public timeToResend = 0;
  public serviceError = '';

  private _alreadyAcceptedTerms = false;
  private _recaptcha = '';
  public termUrl = '/public/termos-de-uso-e-politica-de-privacidade';

  get disableSubmitBtn(): boolean {
    return this.form.value === '' || this.form.invalid || !this.acceptedTerms;
  }

  get onboardSessionData(): boolean {
    return this._onboardService.cdAccessToken?.length > 0;
  }

  get phoneNumber() {
    return this.form.get('phone_number')!;
  }

  get phoneCountryCode() {
    return this.form.get('phone_country_code')!;
  }

  constructor(
    private _onboardService: OnboardService,
    private _cdr: ChangeDetectorRef,
    private _termsService: TermsService,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private _fb: FormBuilder,
    private _partnersService: PartnersService
  ) {
    this._initializeForm();
  }

  private _initializeForm = () => {
    this.form = this._fb.group({
      phone_number: [
        null,
        {
          validators: [
            Validators.required,
            Validators.maxLength(15),
            internationalPhoneValidator,
          ],
        },
      ],
      phone_country_code: [{ country: 'br', code: '55' }, Validators.required],
    });
  };

  ngOnInit() {
    this._termsUrl();
  }

  ngAfterViewInit(): void {
    this._verifyHasOnboardDataInSession();
  }

  private _verifyHasOnboardDataInSession(): void {
    if (this.onboardSessionData) {
      const { cdAccessToken, phoneNumber, phoneCountryInfos } =
        this._onboardService;
      this.phoneInput.setInitialCountry(phoneCountryInfos.country);
      this._onboardService.onboardData.setInitialOnboard(cdAccessToken);
      this.phoneCountryCode.setValue(phoneCountryInfos);
      if (phoneNumber)
        this._verifyCountryAndSetPhone(phoneCountryInfos, phoneNumber);
      this._cdr.detectChanges();
      return;
    }
    this._getNewAccessToken();
  }

  private _getNewAccessToken(): void {
    this._onboardService.clearOnboardData();
    let origin = ONBOARD_PARTNERS_ORIGIN['rocket'];
    if (this._partnersService.partnerBeforeAuth)
      origin =
        ONBOARD_PARTNERS_ORIGIN[this._partnersService.partnerBeforeAuth.key!];
    this._onboardService.onInitialOnboard(origin).subscribe();
  }

  private _verifyCountryAndSetPhone(
    country: { code: string; country: string },
    number: string
  ): void {
    if (country.code)
      this.phoneNumber.setValue(number.replace(country.code, ''));
  }

  // RECAPTCHA
  public onResolved(recaptcha: string) {
    this._recaptcha = recaptcha;
    this._onboardService.onboardData.setOnboardRecaptcha(recaptcha);
    this._savePhoneOnResolveRecaptcha();
  }

  public onError() {
    this._onboardService.onboardData.setOnboardRecaptcha('');
    this.serviceError = 'Houve um erro ao validar o recaptcha.';
  }

  public onSetPhone(): void {
    this.isLoading = true;
    this.serviceError = '';
    this.appRecaptcha.execute();
  }

  // PHONE
  public onUpdateCountryCode(value: IInternationalNumber): void {
    this.phoneCountryCode.patchValue(value);
    this._onboardService.onboardData.updateOnboardField(
      'phone_country',
      value.country
    );
    this._onboardService.onboardData.updateOnboardField(
      'vl_country_code',
      value.code
    );
  }

  private _savePhoneOnResolveRecaptcha(): void {
    const phone = `${this.phoneCountryCode.value.code}${this.phoneNumber.value}`;
    this._onboardService
      .onUpdateOnboardField({
        field: 'phone_number',
        value: phone.replace(/\D/g, ''),
      })
      .pipe(
        map((response) => {
          if (!response.success || !response.result) throw new Error('');
          return true;
        })
      )
      .subscribe({
        next: () => this.sendPincode(),
        error: (err: any) => {
          this.isLoading = false;
          this.serviceError =
            err?.data?.message ??
            'Ocorreu um erro ao salvar o número informado, tente novamente.';
          this._cdr.detectChanges();
        },
      });
  }

  // TERMS
  private _termsUrl(): void {
    this._termsService.termsInfos(1).subscribe({
      next: (response) => {
        this.termUrl = response[response.length - 1].content;
        this._cdr.detectChanges();
      },
    });
  }

  public acceptTerms(): void {
    this.acceptedTerms = !this.acceptedTerms;
    if (this.acceptedTerms && !this._alreadyAcceptedTerms) {
      this.isLoading = true;
      this._cdr.detectChanges();
      this._onboardService
        .onUpdateOnboardField({ field: 'accept_term', value: true })
        .subscribe({
          next: () => {
            this._alreadyAcceptedTerms = true;
            this.isLoading = false;
          },
          error: () => {
            this.isLoading = false;
            this.acceptedTerms = false;
            this.serviceError =
              'Houve um erro ao aceitar os termos, tente novamente.';
          },
          complete: () => this._cdr.detectChanges(),
        });
    }
  }

  // PINCODE
  public sendPincode(): void {
    this._onboardService
      .onValidateInitialOnboard(this._recaptcha)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this._router.navigate(['../validate'], {
            relativeTo: this._activeRoute,
          });
        },
        error: (err: any) => {
          this.serviceError =
            err?.data?.message ??
            'Ocorreu um erro ao enviar o pincode, tente novamente.';
          this.isLoading = false;
          this._cdr.detectChanges();
        },
      });
  }
}
