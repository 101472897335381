<ng-template>
  <div class="card" [ngClass]="customClass" (click)="clickButton($event)">
    <div class="card-body hstack">
      <div class="mr-auto">
        <small class="fw-bold fs-5">{{ title }}</small>
        <small class="text-brand-primary fw-bold fs-5 d-block">{{
          value
        }}</small>
        <small class="card-subtitle mb-0 fw-bold fs-5 text-neutral-medium">{{
          subtitle
        }}</small>
      </div>
      <ng-content select="fla-chart-line"></ng-content>
    </div>
  </div>
</ng-template>
