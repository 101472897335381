import { Injectable } from '@angular/core';
import { SciChartSurface } from 'scichart/Charting/Visuals/SciChartSurface';
import { CANDLE_IDS } from '../constants/tiger-chart.constants';
import { HitTestInfo } from 'scichart/Charting/Visuals/RenderableSeries/HitTest/HitTestInfo';
import { Point } from 'scichart/Core/Point';
import { IRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/IRenderableSeries';

@Injectable({
  providedIn: 'root',
})
export class TigerMiddleChartService {
  getMiddleChart(
    sciChartSurface: SciChartSurface,
    mousePoint: Point
  ): {
    hitTestMiddleXInfo?: HitTestInfo;
    hitTestMiddleYInfo?: HitTestInfo;
    rSeries?: IRenderableSeries;
  } {
    const series = sciChartSurface.renderableSeries;
    const rSerie = series.getById(CANDLE_IDS.MAIN_SERIE) ?? series.get(0);
    if (!rSerie) {
      return {
        hitTestMiddleXInfo: undefined,
        hitTestMiddleYInfo: undefined,
        rSeries: undefined,
      };
    }
    const middleX =
      parseFloat(sciChartSurface.domSvgContainer.getAttribute('width')!!) / 2;
    const hitTestMiddleXInfo = rSerie.hitTestProvider.hitTestXSlice(
      middleX,
      mousePoint.y
    );
    const middleY =
      parseFloat(sciChartSurface.domSvgContainer.getAttribute('height')!!) / 2;
    const hitTestMiddleYInfo = rSerie.hitTestProvider.hitTestDataPoint(
      mousePoint.x,
      middleY
    );
    return { hitTestMiddleXInfo, hitTestMiddleYInfo, rSeries: rSerie };
  }
}
