import { FormGroup } from '@angular/forms';
import { DynamicControlService } from '@shared/rocket-components/dynamic-form/dynamic-control.service';
import { FieldsBase } from '@shared/rocket-components/dynamic-form/fields-base';

export class TigerChartOrdersForm {
  constructor(
    private dynamicControlService: DynamicControlService,
    private standardLot: number,
    private negotiationLot: number,
    private incrementValue: number,
    private vlClosePrice: string | number,
    private priceMask: string
  ) {}

  private fields: any = () => [
    {
      controlType: 'inputCount',
      key: 'qtde',
      label: 'Qtde:',
      value: this.standardLot,
      isSmall: true,
      selectOnFocus: true,
      allowNegativeValue: false,
      incrementValue: this.incrementValue,
      classLine: 'col-16',
      classComponent: 'col-16 margin-bottom new-button-size',
      listErrors: ['qtdeRequired'],
      typeShowError: 'icon',
      tooltip: `<span class="fs-6">Quantidade</span><br><span class="fs-7 line-heigth">Número de ativos ou contratos que deseja negociar.</span>`,
      tooltipDelay: 1000,
      initialValue: this.negotiationLot,
      flaMask: 'separator.0',
      scrollEvent: true,
      isQttyInput: true,
    },
    {
      controlType: 'freeContent',
      key: 'price',
      label: 'Preço:',
      value: this.vlClosePrice,
      isSmall: true,
      selectOnFocus: true,
      flaMask: this.priceMask,
      classLine: 'col-16',
      listErrors: ['multiplePriceError', 'priceRequired'],
      classComponent: 'col-16 margin-bottom',
      typeShowError: 'icon',
      tooltip: `<span class="fs-6">Preço</span><br><span class="fs-7 line-heigth">Valor pelo qual deseja negociar o ativo.</span>`,
      tooltipDelay: 1000,
    },
  ];

  public createFields(): any[] {
    return this.fields();
  }

  public createForm(): FormGroup {
    return this.dynamicControlService.toFormGroup(
      this.fields() as FieldsBase<string>[]
    );
  }
}
