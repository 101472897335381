<rocket-modal>
  <rocket-modal-header>
    <h3 class="modal-title">Redefinir workspace</h3>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <span class="mb-4 d-block">Escolha abaixo uma das opções</span>
    <div class="w-100 h-100 d-inline-flex">
      <div
        class="ws-names-width d-flex flex-column align-items-start justify-content-start gap-3 border-right border-thin"
      >
        <ng-container *ngFor="let ws of workspaces">
          <button
            type="button"
            class="btn btn-fill-primary p-0 text-left"
            [class]="
              ws.code === wsInfos.code ? 'text-brand-primary' : 'text-light'
            "
            (click)="selectTemplate(ws.code)"
          >
            {{ ws.name }}
          </button>
        </ng-container>
      </div>
      <div class="modal-content-height px-3 py-1">
        <span [innerHTML]="wsInfos.description"></span>
        <ul class="pl-4 pt-2">
          <ng-container *ngFor="let component of wsInfos.components">
            <li class="mb-1">
              {{ component }}
            </li>
          </ng-container>
        </ul>
        <img [src]="wsInfos.imagePath" class="w-100 round" />
      </div>
    </div>
  </app-rocket-modal-body>

  <rocket-modal-footer>
    <rocket-button
      label="Aplicar"
      [loading]="disableClick"
      (rocketClick)="resetWorkspace()"
    ></rocket-button>
  </rocket-modal-footer>
</rocket-modal>
