import {
  Component,
  Input,
  ChangeDetectorRef,
  Injector,
  forwardRef,
  Output,
  EventEmitter,
  ContentChildren,
  QueryList,
  AfterViewInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseComponent } from '@shared/rocket-components/base-componentes';
import { FlaRaioButtonComponent } from './radios-parts/radio-button.component';
import { takeUntil } from 'rxjs/operators';
import { FlaRadioService } from './service/radio.service';
import { isNullOrWhiteSpace, randomId } from '../../utils/functions';
import { MESSAGES } from '../../utils/message.const';

@Component({
  selector: 'fla-radio-group',
  templateUrl: './radios.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FlaRadiosComponent),
    },
  ],
})
export class FlaRadiosComponent extends BaseComponent implements AfterViewInit {
  @Input('css') customClass: string = '';
  @Input() hasError!: string;
  @Input() requiredText: string = MESSAGES['requiredText'];
  @Input() isRequired: boolean = false;
  @Output() flaChange = new EventEmitter<any>();
  @ContentChildren(FlaRaioButtonComponent)
  radioButtons!: QueryList<FlaRaioButtonComponent>;
  refComponent = randomId('fla_radio');

  constructor(
    inject: Injector,
    cdr: ChangeDetectorRef,
    private service: FlaRadioService
  ) {
    super(inject, cdr);
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this._updateChecked();

    this.service.valuesChanges$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((values) => {
        if (values.id === this.refComponent) this.changes(values.value);
      });

    this.cdr.detectChanges();
  }

  private changes(value?: any): void {
    this.formControl.setValue(value);
    this.formControl.markAllAsTouched();
    this.formControl.markAsTouched();

    if (isNullOrWhiteSpace(value)) {
      this._updateChecked();
    }

    this.flaChange.emit(value);
  }

  private _updateChecked(): void {
    if (this.radioButtons?.length) {
      const value = this.radioButtons.find((radio) => radio.checked)?.value;
      this.formControl.setValue(value);
    }
  }
}
