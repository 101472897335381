<fla-select
  #select
  *ngIf="!clearStockLabel"
  [css]="css"
  [label]="label"
  [placeholder]="searchLabel"
  bindLabel="stockLabel"
  bindValue="stockLabel"
  [clearable]="false"
  [isSmall]="true"
  [isDisable]="isDisable"
  [loading]="loading"
  [list]="listStock"
  [notFoundText]="notFoundText"
  [customSearchFn]="customSearchFn"
  [isHTMLLabel]="true"
  margin="mb-0"
  (flaChange)="selectStock($event, true)"
  (flaSearch)="search($event)"
  (flaClose)="clearList(true)"
  (flaOpen)="openSearch()"
></fla-select>
