import {
  CHART_ALTER_SCALE_CONFIGS,
  STOCK_CHART_ELEMENT_IDS,
} from '@shared/components/stock-chart/enum/stock-chart.enum';
import { EFooterEvent, TFooterType } from './tiger-chart-footer.enum';
import { Dictionary } from '@core/models';

export const FOOTER_SVG = {
  ruler: `<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 28 28" width="20" height="20">
    <path fill="currentColor" d="M2 9.75a1.5 1.5 0 0 0-1.5 1.5v5.5a1.5 1.5 0 0 0 1.5 1.5h24a1.5 1.5 0 0 0 1.5-1.5v-5.5a1.5 1.5 0 0 0-1.5-1.5zm0 1h3v2.5h1v-2.5h3.25v3.9h1v-3.9h3.25v2.5h1v-2.5h3.25v3.9h1v-3.9H22v2.5h1v-2.5h3a.5.5 0 0 1 .5.5v5.5a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-5.5a.5.5 0 0 1 .5-.5z" transform="rotate(-45 14 14)"></path>
  </svg>`,
  zoom: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor">
    <path d="M17.646 18.354l4 4 .708-.708-4-4z"></path>
    <path d="M12.5 21a8.5 8.5 0 1 1 0-17 8.5 8.5 0 0 1 0 17zm0-1a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15z"></path>
    <path d="M9 13h7v-1H9z"></path>
    <path d="M13 16V9h-1v7z"></path>
  </svg>`,
  volume: `<svg width="152" height="152" viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M85.5 88.668L85.5 117.168L72.8333 117.168L72.8333 88.668L85.5 88.668Z" fill="currentColor"/>
<g opacity="0.8">
<path d="M66.5 41.168L66.5 117.168L53.8333 117.168L53.8333 41.168L66.5 41.168Z" fill="currentColor"/>
</g>
<g opacity="0.8">
<path d="M104.5 60.168L104.5 117.168L91.8333 117.168L91.8333 60.168L104.5 60.168Z" fill="currentColor"/>
</g>
<path d="M47.5 60.168L47.5 117.168L34.8333 117.168L34.8333 60.168L47.5 60.168Z" fill="currentColor"/>
<path d="M126.666 25.334L126.666 117.167L113.999 117.167L113.999 25.334L126.666 25.334Z" fill="currentColor"/>
<path d="M19 133V19H28.5V123.5H78.5909H133V133H19Z" fill="currentColor"/>
</svg>`,
  event: `<svg width="155" height="151" viewBox="0 0 155 151" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.375 132.125V18.875H29.0625V122.688H80.142H135.625V132.125H19.375Z" fill="currentColor"/>
<circle cx="42" cy="64" r="9" fill="currentColor"/>
<circle cx="63" cy="91" r="9" fill="currentColor"/>
<ellipse cx="84.5" cy="69" rx="9.5" ry="9" fill="currentColor"/>
<circle cx="106" cy="100" r="9" fill="currentColor"/>
<circle cx="127" cy="64" r="9" fill="currentColor"/>
</svg>`,
  orders_executed: `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
  <path fill="currentColor" d="M240-160q-33 0-56.5-23.5T160-240q0-33 23.5-56.5T240-320q33 0 56.5 23.5T320-240q0 33-23.5 56.5T240-160Zm320 0q-33 0-56.5-23.5T480-240q0-33 23.5-56.5T560-320q33 0 56.5 23.5T640-240q0 33-23.5 56.5T560-160ZM400-320q-33 0-56.5-23.5T320-400q0-33 23.5-56.5T400-480q33 0 56.5 23.5T480-400q0 33-23.5 56.5T400-320Zm320 0q-33 0-56.5-23.5T640-400q0-33 23.5-56.5T720-480q33 0 56.5 23.5T800-400q0 33-23.5 56.5T720-320ZM240-480q-33 0-56.5-23.5T160-560q0-33 23.5-56.5T240-640q33 0 56.5 23.5T320-560q0 33-23.5 56.5T240-480Zm320 0q-33 0-56.5-23.5T480-560q0-33 23.5-56.5T560-640q33 0 56.5 23.5T640-560q0 33-23.5 56.5T560-480ZM400-640q-33 0-56.5-23.5T320-720q0-33 23.5-56.5T400-800q33 0 56.5 23.5T480-720q0 33-23.5 56.5T400-640Zm320 0q-33 0-56.5-23.5T640-720q0-33 23.5-56.5T720-800q33 0 56.5 23.5T800-720q0 33-23.5 56.5T720-640Z"/>
  </svg>`,
  previous_close: `<svg width="155" height="151" viewBox="0 0 155 151" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.375 132.125V18.875H29.0625V122.688H80.142H135.625V132.125H19.375Z" fill="currentColor"/>
<line x1="20" y1="70.5" x2="130" y2="70.5" stroke="currentColor" stroke-width="6" stroke-dasharray="6 6"/>
</svg>`,
  bid_ask: `<svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_8253_6654)">
<path d="M 1.0089 16.2 V 1.3608 H 5.2551 C 6.7545 1.3608 7.8129 2.916 7.8129 5.076 C 7.8129 6.588 7.3593 7.6464 6.5907 8.3592 C 7.5861 9.18 8.1783 10.5408 8.1783 12.1608 C 8.1783 14.5368 7.0317 16.2 5.4189 16.2 H 1.0089 Z M 2.5713 7.4304 H 4.8771 C 5.6457 7.4304 6.2001 6.6528 6.2001 5.5728 C 6.2001 4.4712 5.6457 3.6936 4.8771 3.6936 H 2.5713 V 7.4304 Z M 2.5713 13.8672 H 5.1039 C 5.9607 13.8672 6.5655 12.9816 6.5655 11.7504 C 6.5655 10.5192 5.9607 9.6336 5.1039 9.6336 H 2.5713 V 13.8672 Z M 8.485 17.7984 L 12.8445 0.1512 H 14.4951 L 10.1357 17.7984 H 8.485 Z M 21.8572 16.2 L 21.202 13.5216 H 17.0188 L 16.3636 16.2 H 14.6122 L 18.2284 1.3608 H 19.9798 L 23.596 16.2 H 21.8572 Z M 17.5858 11.1888 H 20.635 L 19.1104 4.9464 L 17.5858 11.1888 Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_8253_6654">
<rect width="23" height="15" fill="white"/>
</clipPath>
</defs>
</svg>`,
  boleta: `<svg width="152" height="152" viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="20" y="20" width="112" height="112" stroke="currentColor" stroke-width="2"/>
<rect x="19" y="19" width="32.5714" height="114" fill="currentColor"/>
<rect x="63.334" y="38" width="53.8333" height="19" fill="currentColor"/>
<rect opacity="0.8" x="63.334" y="63.334" width="53.8333" height="25.3333" fill="currentColor"/>
<rect opacity="0.95" x="63.334" y="95" width="25.3333" height="19" fill="currentColor"/>
<rect opacity="0.8" x="91.834" y="95" width="25.3333" height="19" fill="currentColor"/>
</svg>`,
  volume_at_price: `<svg width="152" height="152" viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="38" y="19" width="25.3333" height="31.6667" fill="currentColor"/>
<path opacity="0.8" d="M114 19H133V50.6667H114V19Z" fill="currentColor"/>
<rect opacity="0.9" x="63.334" y="19" width="50.6667" height="31.6667" fill="currentColor"/>
<rect x="19" y="60.166" width="14.04" height="31.6667" fill="currentColor"/>
<rect opacity="0.9" x="32.8477" y="60.166" width="26.5484" height="31.6667" fill="currentColor"/>
<path opacity="0.8" d="M59.2266 60.166H133V91.8327H59.2266V60.166Z" fill="currentColor"/>
<rect x="44.334" y="101.334" width="18.5981" height="31.6667" fill="currentColor"/>
<rect opacity="0.9" x="62.8203" y="101.334" width="47.6112" height="31.6667" fill="currentColor"/>
<path opacity="0.8" d="M110.432 101.334H132.997V133.001H110.432V101.334Z" fill="currentColor"/>
</svg>`,
  book: `<svg width="152" height="152" viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.8">
<path d="M47.5 57H133V76H47.5V57Z" fill="currentColor"/>
</g>
<g opacity="0.7">
<path d="M19 38H133V57H19V38Z" fill="currentColor"/>
</g>
<g opacity="0.8">
<path d="M31.666 19H132.999V38H31.666V19Z" fill="currentColor"/>
</g>
<path d="M28.5 76H133V95H28.5V76Z" fill="currentColor"/>
<g opacity="0.9">
<path d="M41.166 95H132.999V114H41.166V95Z" fill="currentColor"/>
</g>
<path d="M19 114H133V133H19V114Z" fill="currentColor"/>
</svg>`,
};

export const SECTION_BUTTONS = new Dictionary<any>();
SECTION_BUTTONS.set(STOCK_CHART_ELEMENT_IDS.STOCK_TRADE_EVENTS_AND_VOLUME, {
  [EFooterEvent.VOLUME]: {
    tooltip: CHART_ALTER_SCALE_CONFIGS.OFF_VOLUME,
    bool: false,
    event: TFooterType.VOLUME,
    svg: FOOTER_SVG.volume,
    hidden: false,
  },
  [EFooterEvent.EVENTS]: {
    tooltip: CHART_ALTER_SCALE_CONFIGS.ON_EVENTS,
    bool: false,
    event: TFooterType.EVENTS,
    svg: FOOTER_SVG.event,
    hidden: false,
  },
  [EFooterEvent.EXEC_ORDERS]: {
    tooltip: CHART_ALTER_SCALE_CONFIGS.OFF_EXEC_ORDERS,
    bool: false,
    event: TFooterType.EXEC_ORDERS,
    svg: FOOTER_SVG.orders_executed,
    hidden: false,
  },
  [EFooterEvent.PREVIOUS_CLOSE]: {
    tooltip: CHART_ALTER_SCALE_CONFIGS.OFF_PREVIOUS_CLOSE,
    bool: false,
    event: TFooterType.PREVIOUS_CLOSE,
    svg: FOOTER_SVG.previous_close,
    hidden: false,
  },
  [EFooterEvent.BID_ASK]: {
    tooltip: CHART_ALTER_SCALE_CONFIGS.OFF_BID_ASK,
    bool: false,
    event: TFooterType.SHOW_BID_ASK,
    svg: FOOTER_SVG.bid_ask,
    hidden: false,
  },
  [EFooterEvent.FAST_ORDER]: {
    tooltip: CHART_ALTER_SCALE_CONFIGS.ON_FAST_ORDER,
    bool: false,
    event: TFooterType.FAST_ORDER,
    svg: FOOTER_SVG.boleta,
    hidden: false,
    css: 'border-right',
  },
});
SECTION_BUTTONS.set(STOCK_CHART_ELEMENT_IDS.FOOTER_BOOK_AND_VOLUME_X_PRICE, {
  [EFooterEvent.VOLUME_AT_PRICE]: {
    tooltip: CHART_ALTER_SCALE_CONFIGS.OFF_VOLUME_AT_PRICE_CHART,
    bool: false,
    event: TFooterType.CHART_VOLUME_AT_PRICE,
    svg: FOOTER_SVG.volume_at_price,
    hidden: false,
  },
  [EFooterEvent.BOOK]: {
    tooltip: CHART_ALTER_SCALE_CONFIGS.OFF_BOOK_CHART,
    bool: false,
    event: TFooterType.CHART_BOOK,
    svg: FOOTER_SVG.book,
    hidden: false,
    css: 'border-right',
  },
});
