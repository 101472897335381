<div [id]="id" class="py-1">
  <div
    *ngIf="!isNewColor; else newColor"
    (click)="colorPickerMouseEvent($event)"
    class="py-1 px-2 width224"
  >
    <div class="d-flex flex-wrap swatches">
      <div
        [ngClass]="{ selected: color.selected }"
        class="swatch"
        *ngFor="let color of principalColors"
        [ngStyle]="{ color: color.rgbColor }"
        (click)="selectColor(color)"
      ></div>
    </div>
    <div class="d-flex flex-wrap swatches">
      <div
        [ngClass]="{ selected: color.selected }"
        class="swatch"
        *ngFor="let color of monoColors"
        [ngStyle]="{ color: color.rgbColor }"
        (click)="selectColor(color)"
      ></div>
    </div>
    <div class="separator"></div>
    <div class="d-flex flex-wrap swatches">
      <div
        [ngClass]="{
          selected: color.selected,
          opened: colorSettingsDict.has(color.rgbColor.toUpperCase())
        }"
        class="swatch"
        *ngFor="let color of userColors"
        [ngStyle]="{ color: color.rgbColor }"
        (click)="selectColor(color)"
        (mouseleave)="mouseLeaveDeleteDict(color)"
        (mouseenter)="mouseEnterValidity($event)"
      >
        <div (click)="openSettings($event, color)" class="swatchSetting">
          <app-dropdown title="">
            <a
              type="button"
              class="nav-link lh-sm round-top h-100"
              appDropdownButton
              placement="top-start"
              title=""
              [rtDropnOpened]="rtDropnOpened"
            >
              <span class="material-icons-outlined settings"> settings </span>
            </a>
            <div class="contents">
              <li>
                <a
                  title=""
                  (click)="deleteColor(color)"
                  type="button"
                  class="dropdown-item d-flex align-items-center gap-2 px-2"
                >
                  <span class="material-icons-outlined"> delete </span>
                  <span>Deletar cor</span>
                </a>
              </li>
            </div>
          </app-dropdown>
        </div>
      </div>
      <div
        class="customButton apply-common-tooltip"
        title="Adicionar cor customizada"
        (click)="addNewColor($event)"
      ></div>
    </div>
    <div
      *ngIf="(tool && !tool.isMultiColors) || !isTool"
      class="d-flex flex-column"
    >
      <div class="title">Opacidade</div>
      <div class="d-flex align-items-center opacity">
        <div
          id="pointerWrap"
          [id]="'opacitySlider_' + typeColorUpdate"
          class="opacitySlider"
          style="color: rgb(255, 224, 178)"
        >
          <div class="opacitySliderGradient"></div>
          <div class="opacityPointerWrap">
            <input
              class="rangePointer"
              [id]="'rangePointer_' + typeColorUpdate"
              type="range"
              min="0"
              max="100"
              step="1"
              [(ngModel)]="opacityValue"
              (input)="opacityChange()"
            />
          </div>
        </div>
        <div class="opacityInputWrap">
          <input type="text" class="opacityInput" [(ngModel)]="opacityValue" />
          <span class="opacityInputPercent">%</span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #newColor>
  <div (click)="colorPickerMouseEvent($event)" class="py-1 px-2 width224">
    <app-rt-color-picker
      #colorpicker
      [width]="170"
      [height]="184"
      [showColorPicker]="true"
      [closeOnClick]="false"
      [hideTypeButton]="true"
      type="Hex"
      [isEditable]="true"
      [color]="color!!.hexColor"
    ></app-rt-color-picker>
  </div>
</ng-template>
