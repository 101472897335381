import {
  TigerChartIndicatorCdlhammerOptions,
  TTigerChartIndicatorCreateOptions,
} from './indicators.types';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { CdlhammerBase } from './base/cdlhammer-base';
import { TIGER_INDICATORS_ENUM } from '../enum';
import { Dictionary } from 'src/app/core/models';
import { deepClone } from '@shared/rocket-components/utils/functions';

export class TigerChartIndicatorCdlhammer extends CdlhammerBase {
  constructor(options: TigerChartIndicatorCdlhammerOptions) {
    super(options, TIGER_INDICATORS_ENUM.CDL_HAMMER);
  }

  override create(
    options: TTigerChartIndicatorCreateOptions
  ): BaseRenderableSeries[] {
    this.updatePoints();

    // this.hammer(this.data.vl_open, this.data.vl_high, this.data.vl_low, this.data.vl_close, this.data.id_point.length-1)

    // var cloneData = deepClone(this.data)
    //REMOVER POINTS DUPLICADOS DAS 17H00
    // for(let k = 0; k < cloneData.id_point.length; k++){
    //   if(cloneData.id_point[k] === cloneData.id_point[k+1]){
    //     cloneData.vl_open.splice(k, 1);
    //     cloneData.vl_high.splice(k, 1);
    //     cloneData.vl_low.splice(k, 1);
    //     cloneData.vl_close.splice(k, 1);
    //     cloneData.vl_volume.splice(k, 1);
    //     cloneData.id_point.splice(k, 1);
    //   }
    // }

    // this.calcHammer(cloneData.vl_open, cloneData.vl_high, cloneData.vl_low, cloneData.vl_close, cloneData.id_point.length-1)
    // const hammer = this.isHammer(open, high, low, close);
    // console.log(hammer)

    return super.create(options);
  }

  override updatePoints(): void {
    this.points = this.service.call('CDLHAMMER', {
      open: this.data.vl_open,
      high: this.data.vl_high,
      low: this.data.vl_low,
      close: this.data.vl_close,
    });
  }

  override updateSettings() {
    this.points = this.service.call('CDLHAMMER', {
      open: this.data.vl_open,
      high: this.data.vl_high,
      low: this.data.vl_low,
      close: this.data.vl_close,
    });

    super.updateSettings();
  }

  public calcHammer(
    inOpen: number[],
    inHigh: number[],
    inLow: number[],
    inClose: number[],
    endIdx: number
  ) {
    let C_ShadowPercent = 5.0;
    let C_Factor = 2.0;

    let hammers = [];

    let bodyHiArray = [];
    let bodyLoArray = [];
    let C_BodyArray = [];

    for (let j = 0; j <= endIdx; j++) {
      bodyHiArray.push(Math.max(inClose[j], inOpen[j]));
      bodyLoArray.push(Math.min(inClose[j], inOpen[j]));
      C_BodyArray.push(bodyHiArray[j] - bodyLoArray[j]);
    }

    const offset: number[] = Array(14).fill(C_BodyArray[0]);

    let ema = this.service.call('EMA', {
      inReal: [...offset, ...C_BodyArray],
      timePeriod: 14,
    });

    /// CALCULA O BODY AVG
    let C_BodyAvgArray: any = [];
    let prevAvg = 0.0;
    C_BodyArray.map((item: number) => {
      let multiplier = 2 / (14 + 1);
      let calcPrevAvg = prevAvg == 0.0 ? item : prevAvg;
      let avg = multiplier * item + (1 - multiplier) * calcPrevAvg;
      C_BodyAvgArray.push(avg);
      prevAvg = avg;
    });

    for (let i = 0; i <= endIdx; i++) {
      const hl2 = (inHigh[i] + inLow[i]) / 2;
      const C_BodyHi = Math.max(inClose[i], inOpen[i]);
      const C_BodyLo = Math.min(inClose[i], inOpen[i]);
      const C_Body = Math.ceil((C_BodyHi - C_BodyLo) * 10) / 100;
      const C_BodyAvg = C_BodyAvgArray[i] + 0.001;
      const C_SmallBody = C_Body < C_BodyAvg;
      const C_UpShadow = parseFloat((inHigh[i] - C_BodyHi).toFixed(2));
      const C_DnShadow = parseFloat((C_BodyLo - inLow[i]).toFixed(2));

      const C_HasUpShadow = C_UpShadow > (C_ShadowPercent / 100) * C_Body;

      let C_HammerBullish = false;

      // if(i == 459 ){
      //   console.log('Abr', inOpen[i], 'Max', inHigh[i], 'Min', inLow[i], 'Fch', inClose[i])

      //   console.log('C_BodyAvg', C_BodyAvg)
      //   console.log('C_Body', C_Body)
      //   console.log('C_BodyHi', C_BodyHi)
      //   console.log('C_BodyLo', C_BodyLo)
      //   console.log('hl2', hl2)
      //   console.log('C_DnShadow', C_DnShadow)
      //   console.log('C_Factor', C_Factor)
      //   console.log('C_HasUpShadow', C_HasUpShadow)
      //   console.log('--------------------')

      //   console.log('C_SmallBody', C_SmallBody)
      //   console.log('C_Body > 0', C_Body > 0)
      //   console.log('C_BodyLo > hl2', C_BodyLo > hl2)
      //   console.log('C_DnShadow >= C_Factor * C_Body', C_DnShadow >= C_Factor * C_Body)
      //   console.log('!C_HasUpShadow', !C_HasUpShadow)
      // }

      if (
        C_SmallBody &&
        C_Body > 0 &&
        C_BodyLo > hl2 &&
        C_DnShadow >= C_Factor * C_Body &&
        !C_HasUpShadow
      ) {
        C_HammerBullish = true;
      }

      hammers.push(C_HammerBullish);
    }

    console.log(hammers);
  }

  public sma(values: any, period: number) {
    let smaArray = [];

    for (let i = period - 1; i < values.length; i++) {
      let sum = 0;
      for (let j = i - period + 1; j <= i; j++) {
        sum += values[j];
      }
      smaArray.push(sum / period);
    }

    return smaArray;
  }

  public ema(values: any, period: number) {
    let multiplier = 2 / (period + 1);
    let emaArray: any = [];
    console.log(values);
    values.forEach((item: any, k: number) => {
      const ema =
        k == 0 ? item : multiplier * item + (1 - multiplier) * values[0];
      emaArray.push(ema);
    });

    //[2/(20+1)]= 0.0952
    //EMA = Closing price x multiplier + EMA (previous day) x (1-multiplier)

    //EMA = multiplier * item + (1 - multiplier) * values[0]

    // let emaArray = [values[0]]

    // for (let i = 1; i < values.length; i++) {
    //   emaArray.push(values[i] * k + emaArray[i - 1] * (1 - k))
    // }

    return emaArray;
  }

  public hl2(inHigh: number, inLow: number) {
    return (inHigh + inLow) / 2;
  }

  private hammer(
    inOpen: number[],
    inHigh: number[],
    inLow: number[],
    inClose: number[],
    endIdx: number
  ) {
    let startIdx = 11;
    let BodyPeriodTotal = 0;
    let BodyTrailingIdx = startIdx - TA_CANDLEAVGPERIOD('BodyShort');
    let ShadowLongPeriodTotal = 0;
    let ShadowLongTrailingIdx = startIdx - TA_CANDLEAVGPERIOD('ShadowLong');
    let ShadowVeryShortPeriodTotal = 0;
    let ShadowVeryShortTrailingIdx =
      startIdx - TA_CANDLEAVGPERIOD('ShadowVeryShort');
    let NearPeriodTotal = 0;
    let NearTrailingIdx = startIdx - 1 - TA_CANDLEAVGPERIOD('Near');

    let i = BodyTrailingIdx;
    while (i < startIdx) {
      BodyPeriodTotal += TA_CANDLERANGE(
        'BodyShort',
        i,
        inClose,
        inOpen,
        inHigh,
        inLow
      );
      i++;
    }
    i = ShadowLongTrailingIdx;
    while (i < startIdx) {
      ShadowLongPeriodTotal += TA_CANDLERANGE(
        'ShadowLong',
        i,
        inClose,
        inOpen,
        inHigh,
        inLow
      );
      i++;
    }
    i = ShadowVeryShortTrailingIdx;
    while (i < startIdx) {
      ShadowVeryShortPeriodTotal += TA_CANDLERANGE(
        'ShadowVeryShort',
        i,
        inClose,
        inOpen,
        inHigh,
        inLow
      );
      i++;
    }
    i = NearTrailingIdx;
    while (i < startIdx - 1) {
      NearPeriodTotal += TA_CANDLERANGE(
        'Near',
        i,
        inClose,
        inOpen,
        inHigh,
        inLow
      );
      i++;
    }
    console.log('INICIO');
    console.log('BodyTrailingIdx', BodyTrailingIdx);
    console.log('ShadowLongTrailingIdx', ShadowLongTrailingIdx);
    console.log('ShadowVeryShortTrailingIdx', ShadowVeryShortTrailingIdx);
    console.log('NearTrailingIdx', NearTrailingIdx);
    i = startIdx;

    /* Proceed with the calculation for the requested range.
     * Must have:
     * - small real body
     * - long lower shadow
     * - no, or very short, upper shadow
     * - body below or near the lows of the previous candle
     * The meaning of "short", "long" and "near the lows" is specified with TA_SetCandleSettings;
     * outInteger is positive (1 to 100): hammer is always bullish;
     * the user should consider that a hammer must appear in a downtrend, while this function does not consider it
     */
    let outIdx = 0;

    let outInteger = [];
    const indxs = [471];
    for (let k = outIdx; k < startIdx; outIdx++) {
      outInteger[outIdx] = 0;
      k++;
    }
    do {
      if (indxs.indexOf(i) != -1) {
        console.log('OUTINDEX', outIdx);
        console.log('O', inOpen[i]);
        console.log('H', inHigh[i]);
        console.log('L', inLow[i]);
        console.log('C', inClose[i]);
        console.log('BodyPeriodTotal', BodyPeriodTotal);
        console.log('ShadowLongPeriodTotal', ShadowLongPeriodTotal);
        console.log('ShadowVeryShortPeriodTotal', ShadowVeryShortPeriodTotal);
        console.log('NearPeriodTotal', NearPeriodTotal);
        console.log(
          'TA_REALBODY(i, inClose, inOpen)',
          TA_REALBODY(i, inClose, inOpen)
        );
        console.log(
          'TA_CANDLEAVERAGE( BodyShort, BodyPeriodTotal, i, inClose, inOpen, inHigh, inLow)',
          TA_CANDLEAVERAGE(
            'BodyShort',
            BodyPeriodTotal,
            i,
            inClose,
            inOpen,
            inHigh,
            inLow
          )
        );
        console.log(
          'TA_REALBODY(i, inClose, inOpen) < TA_CANDLEAVERAGE( BodyShort, BodyPeriodTotal, i, inClose, inOpen, inHigh, inLow)',
          TA_REALBODY(i, inClose, inOpen) <
            TA_CANDLEAVERAGE(
              'BodyShort',
              BodyPeriodTotal,
              i,
              inClose,
              inOpen,
              inHigh,
              inLow
            )
        );
        console.log(
          'TA_LOWERSHADOW(i, inClose, inOpen, inLow)',
          TA_LOWERSHADOW(i, inClose, inOpen, inLow)
        );
        console.log(
          'TA_CANDLEAVERAGE( ShadowLong, ShadowLongPeriodTotal, i, inClose, inOpen, inHigh, inLow )',
          TA_CANDLEAVERAGE(
            'ShadowLong',
            ShadowLongPeriodTotal,
            i,
            inClose,
            inOpen,
            inHigh,
            inLow
          )
        );
        console.log(
          'TA_LOWERSHADOW(i, inClose, inOpen, inLow) > TA_CANDLEAVERAGE( ShadowLong, ShadowLongPeriodTotal, i, inClose, inOpen, inHigh, inLow )',
          TA_LOWERSHADOW(i, inClose, inOpen, inLow) >
            TA_CANDLEAVERAGE(
              'ShadowLong',
              ShadowLongPeriodTotal,
              i,
              inClose,
              inOpen,
              inHigh,
              inLow
            )
        );
        console.log(
          'TA_UPPERSHADOW(i, inHigh, inClose, inOpen)',
          TA_UPPERSHADOW(i, inHigh, inClose, inOpen)
        );
        console.log(
          'TA_CANDLEAVERAGE( ShadowVeryShort, ShadowVeryShortPeriodTotal, i, inClose, inOpen, inHigh, inLow )',
          TA_CANDLEAVERAGE(
            'ShadowVeryShort',
            ShadowVeryShortPeriodTotal,
            i,
            inClose,
            inOpen,
            inHigh,
            inLow
          )
        );
        console.log(
          'TA_UPPERSHADOW(i, inHigh, inClose, inOpen) < TA_CANDLEAVERAGE( ShadowVeryShort, ShadowVeryShortPeriodTotal, i, inClose, inOpen, inHigh, inLow )',
          TA_UPPERSHADOW(i, inHigh, inClose, inOpen) <
            TA_CANDLEAVERAGE(
              'ShadowVeryShort',
              ShadowVeryShortPeriodTotal,
              i,
              inClose,
              inOpen,
              inHigh,
              inLow
            )
        );
        console.log(
          'Math.min( inClose[i], inOpen[i] )',
          Math.min(inClose[i], inOpen[i])
        );
        console.log('inLow[i-1]', inLow[i - 1]);
        console.log(
          'TA_CANDLEAVERAGE( Near, NearPeriodTotal, i-1, inClose, inOpen, inHigh, inLow )',
          TA_CANDLEAVERAGE(
            'Near',
            NearPeriodTotal,
            i - 1,
            inClose,
            inOpen,
            inHigh,
            inLow
          )
        );
        console.log(
          'inLow[i-1] + TA_CANDLEAVERAGE( Near, NearPeriodTotal, i-1, inClose, inOpen, inHigh, inLow )',
          inLow[i - 1] +
            TA_CANDLEAVERAGE(
              'Near',
              NearPeriodTotal,
              i - 1,
              inClose,
              inOpen,
              inHigh,
              inLow
            )
        );
        console.log(
          'Math.min( inClose[i], inOpen[i] ) <= inLow[i-1] + TA_CANDLEAVERAGE( Near, NearPeriodTotal, i-1, inClose, inOpen, inHigh, inLow )',
          Math.min(inClose[i], inOpen[i]) <=
            inLow[i - 1] +
              TA_CANDLEAVERAGE(
                'Near',
                NearPeriodTotal,
                i - 1,
                inClose,
                inOpen,
                inHigh,
                inLow
              )
        );
      }
      if (
        TA_REALBODY(i, inClose, inOpen) != 0 &&
        TA_REALBODY(i, inClose, inOpen) <
          TA_CANDLEAVERAGE(
            'BodyShort',
            BodyPeriodTotal,
            i,
            inClose,
            inOpen,
            inHigh,
            inLow
          ) && // small rb
        TA_LOWERSHADOW(i, inClose, inOpen, inLow) >
          TA_CANDLEAVERAGE(
            'ShadowLong',
            ShadowLongPeriodTotal,
            i,
            inClose,
            inOpen,
            inHigh,
            inLow
          ) *
            2 && // long lower shadow
        TA_UPPERSHADOW(i, inHigh, inClose, inOpen) <
          TA_CANDLEAVERAGE(
            'ShadowVeryShort',
            ShadowVeryShortPeriodTotal,
            i,
            inClose,
            inOpen,
            inHigh,
            inLow
          ) && // very short upper shadow
        Math.min(inClose[i], inOpen[i]) <=
          inLow[i - 1] +
            TA_CANDLEAVERAGE(
              'Near',
              NearPeriodTotal,
              i - 1,
              inClose,
              inOpen,
              inHigh,
              inLow
            ) // rb near the prior candle's lows
      )
        outInteger[outIdx++] = 100;
      else outInteger[outIdx++] = 0;
      /* add the current range and subtract the first range: this is done after the pattern recognition
       * when avgPeriod is not 0, that means "compare with the previous candles" (it excludes the current candle)
       */
      BodyPeriodTotal += TA_CANDLERANGE(
        'BodyShort',
        i,
        inClose,
        inOpen,
        inHigh,
        inLow
      );
      -TA_CANDLERANGE(
        'BodyShort',
        BodyTrailingIdx,
        inClose,
        inOpen,
        inHigh,
        inLow
      );
      ShadowLongPeriodTotal +=
        TA_CANDLERANGE('ShadowLong', i, inClose, inOpen, inHigh, inLow) -
        TA_CANDLERANGE(
          'ShadowLong',
          ShadowLongTrailingIdx,
          inClose,
          inOpen,
          inHigh,
          inLow
        );
      ShadowVeryShortPeriodTotal +=
        TA_CANDLERANGE('ShadowVeryShort', i, inClose, inOpen, inHigh, inLow) -
        TA_CANDLERANGE(
          'ShadowVeryShort',
          ShadowVeryShortTrailingIdx,
          inClose,
          inOpen,
          inHigh,
          inLow
        );
      NearPeriodTotal +=
        TA_CANDLERANGE('Near', i - 1, inClose, inOpen, inHigh, inLow) -
        TA_CANDLERANGE('Near', NearTrailingIdx, inClose, inOpen, inHigh, inLow);
      i++;
      BodyTrailingIdx++;
      ShadowLongTrailingIdx++;
      ShadowVeryShortTrailingIdx++;
      NearTrailingIdx++;
    } while (i <= endIdx);
    console.log('OUTINTEGER', outInteger);
  }
}

export function TA_CANDLEAVERAGE(
  set: string,
  sum: number,
  idx: number,
  inClose: number[],
  inOpen: number[],
  inHigh: number[],
  inLow: number[]
): number {
  const type = DICT_GLOBALS.get(set)!!;
  return (
    (type.factor *
      (type.avgPeriod != 0
        ? sum / type.avgPeriod
        : TA_CANDLERANGE(set, idx, inClose, inOpen, inHigh, inLow))) /
    (type.rangeType == 'TA_RangeType_Shadows' ? 2 : 1)
  );
}

export function TA_CANDLERANGE(
  set: any,
  idx: number,
  inClose: number[],
  inOpen: number[],
  inHigh: number[],
  inLow: number[]
): number {
  const type = DICT_GLOBALS.get(set)!!;
  if (type.rangeType === 'TA_RangeType_RealBody') {
    return TA_REALBODY(idx, inClose, inOpen);
  }
  if (type.rangeType === 'TA_RangeType_HighLow') {
    return TA_HIGHLOWRANGE(idx, inHigh, inLow);
  }
  if (type.rangeType === 'TA_RangeType_Shadows') {
    return (
      TA_UPPERSHADOW(idx, inHigh, inClose, inOpen) +
      TA_LOWERSHADOW(idx, inClose, inOpen, inLow)
    );
  }
  return 0;
}

export function TA_UPPERSHADOW(
  IDX: number,
  inHigh: number[],
  inClose: number[],
  inOpen: number[]
): number {
  return (
    inHigh[IDX] - (inClose[IDX] >= inOpen[IDX] ? inClose[IDX] : inOpen[IDX])
  );
}

export function TA_LOWERSHADOW(
  IDX: number,
  inClose: number[],
  inOpen: number[],
  inLow: number[]
): number {
  return (
    (inClose[IDX] >= inOpen[IDX] ? inOpen[IDX] : inClose[IDX]) - inLow[IDX]
  );
}

export function TA_REALBODY(
  IDX: number,
  inClose: number[],
  inOpen: number[]
): number {
  return Math.abs(inClose[IDX] - inOpen[IDX]);
}

export function TA_HIGHLOWRANGE(
  IDX: number,
  inHigh: number[],
  inLow: number[]
): number {
  return inHigh[IDX] - inLow[IDX];
}

export function TA_CANDLEAVGPERIOD(set: string): number {
  const type = DICT_GLOBALS.get(set)!!;
  return type.avgPeriod;
}

const GLOBALS = {
  /* real body is long when it's longer than the average of the 10 previous candles' real body */
  BodyLong: { rangeType: 'TA_RangeType_RealBody', avgPeriod: 10, factor: 1 },
  /* real body is very long when it's longer than 3 times the average of the 10 previous candles' real body */
  BodyVeryLong: {
    rangeType: 'TA_RangeType_RealBody',
    avgPeriod: 10,
    factor: 3,
  },
  /* real body is short when it's shorter than the average of the 10 previous candles' real bodies */
  BodyShort: { rangeType: 'TA_RangeType_RealBody', avgPeriod: 10, factor: 1 },
  /* real body is like doji's body when it's shorter than 10% the average of the 10 previous candles' high-low range */
  BodyDoji: { rangeType: 'TA_RangeType_HighLow', avgPeriod: 10, factor: 0.1 },
  /* shadow is long when it's longer than the real body */
  ShadowLong: { rangeType: 'TA_RangeType_RealBody', avgPeriod: 0, factor: 1 },
  /* shadow is very long when it's longer than 2 times the real body */
  ShadowVeryLong: {
    rangeType: 'TA_RangeType_RealBody',
    avgPeriod: 0,
    factor: 2,
  },
  /* shadow is short when it's shorter than half the average of the 10 previous candles' sum of shadows */
  ShadowShort: { rangeType: 'TA_RangeType_Shadows', avgPeriod: 10, factor: 1 },
  /* shadow is very short when it's shorter than 10% the average of the 10 previous candles' high-low range */
  ShadowVeryShort: {
    rangeType: 'TA_RangeType_HighLow',
    avgPeriod: 10,
    factor: 0.1,
  },
  /* when measuring distance between parts of candles or width of gaps */
  /* "near" means "<= 20% of the average of the 5 previous candles' high-low range" */
  Near: { rangeType: 'TA_RangeType_HighLow', avgPeriod: 5, factor: 0.2 },
  /* when measuring distance between parts of candles or width of gaps */
  /* "far" means ">= 60% of the average of the 5 previous candles' high-low range" */
  Far: { rangeType: 'TA_RangeType_HighLow', avgPeriod: 5, factor: 0.6 },
  /* when measuring distance between parts of candles or width of gaps */
  /* "equal" means "<= 5% of the average of the 5 previous candles' high-low range" */
  Equal: { rangeType: 'TA_RangeType_HighLow', avgPeriod: 5, factor: 0.05 },
};

export const DICT_GLOBALS = new Dictionary<{
  rangeType: string;
  avgPeriod: number;
  factor: number;
}>();

DICT_GLOBALS.set('BodyLong', GLOBALS.BodyLong);
DICT_GLOBALS.set('BodyVeryLong', GLOBALS.BodyVeryLong);
DICT_GLOBALS.set('BodyShort', GLOBALS.BodyShort);
DICT_GLOBALS.set('BodyDoji', GLOBALS.BodyDoji);
DICT_GLOBALS.set('ShadowLong', GLOBALS.ShadowLong);
DICT_GLOBALS.set('ShadowVeryLong', GLOBALS.ShadowVeryLong);
DICT_GLOBALS.set('ShadowShort', GLOBALS.ShadowShort);
DICT_GLOBALS.set('ShadowVeryShort', GLOBALS.ShadowVeryShort);
DICT_GLOBALS.set('Near', GLOBALS.Near);
DICT_GLOBALS.set('Far', GLOBALS.Far);
DICT_GLOBALS.set('Equal', GLOBALS.Equal);
