import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { GlobalSelectedStockSubscription } from '@services/core/subscription/global-stock.subscription';
import { LinkSubscription } from '@services/core/subscription/link.subscription';
import { ISearchStock } from 'src/app/core/interface';
import { FlaSelectComponent } from '@shared/rocket-components/components';
import { StockServiceRT } from '@shared/services/api/nitro-ws/v1/stock.service';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { RocketStreamRead } from '@shared/channel/rx-event';
@Component({
  selector: 'app-search-stock',
  templateUrl: './search-stock.component.html',
  styleUrls: ['./search-stock.component.scss'],
})
export class SearchStockComponent
  extends ReadStreamBase
  implements OnInit, OnChanges, OnDestroy
{
  @Input() headerOptions!: any;
  @Input() resetInput: boolean = true;
  @Input() isDisable: boolean = false;
  @Input() css: string = 'ml-2 border-right';
  @Input() label!: string;
  @Input() notFoundText: string = 'Digite para pesquisar';
  @Input() typesOut: string[] = [];
  @Input() ref!: string;
  @Input() initStock!: ISearchStock | undefined;
  @Input() ignoreInitStock = false;
  @Output() public selectStockChange: EventEmitter<ISearchStock> =
    new EventEmitter<ISearchStock>();
  @Output() public openHandler: EventEmitter<void> = new EventEmitter<void>();
  @Output() public closeHandler: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('select') select!: FlaSelectComponent;
  @Input() public searchLabel: string = 'Novo papel';
  public loading: boolean = false;
  public listStock: Array<ISearchStock> = [];
  public clearStockLabel: boolean = false;
  private destroy$ = new Subject<void>();
  private _search$ = new Subject<{ term: string }>();
  private search$!: Subscription;
  private globalInstanceLink!: any;
  private stockStreamActive!: RocketStreamRead;

  constructor(
    private stockService: StockServiceRT,
    private globalStock: GlobalSelectedStockSubscription,
    private linkDoc: LinkSubscription,
    private cdr: ChangeDetectorRef,
    public el: ElementRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.setSearchLabel();
    this.search$ = this._search$.pipe(debounceTime(500)).subscribe((value) => {
      value.term && this.getStock(value.term);
      this.loading = true;
      this.cdr.detectChanges();
    });
    this.subscribeGlobalStockChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { headerOptions, initStock } = changes;
    headerOptions?.currentValue && this.initGlobalLinked();
    initStock?.currentValue && this.setSearchLabel();
  }

  ngOnDestroy(): void {
    this.globalInstanceLink?.unsubscribe();
    this.destroy$.next();
    this.destroy$.unsubscribe();
    this.search$?.unsubscribe();
  }

  private setSearchLabel(): void {
    this.searchLabel = this.initStock?.cd_stock || this.searchLabel || '';
  }

  public search(value: { term: string }): void {
    this._search$.next(value);
  }

  focus() {
    this.select.focus();
  }

  openSearch() {
    this.openHandler.emit();
  }

  public clearList(isFromSelect: boolean = false): void {
    this.listStock = [];
    this.loading = false;
    if (isFromSelect) {
      this.closeHandler.emit();
    }
  }

  public selectStock(stock: ISearchStock, isFromSelect: boolean = false): void {
    this.clearList(isFromSelect);
    stock.country_image_url =
      stock.id_exchange === 1
        ? `https://cdn.trademap.com.br/assets/flags/brazil.imageset/brazil.png`
        : `https://cdn.trademap.com.br/assets/flags/united-states-of-america.imageset/united-states-of-america.png`;
    this.emitSearchStock(stock, true);
    this.clearStockLabel = true;
    setTimeout(() => {
      this.clearStockLabel = false;
      this.cdr.detectChanges();
    }, 1);
  }

  selectStockByCdStock(cdStock: string) {
    this.searchStock(cdStock)
      .pipe(
        takeUntil(this.destroy$),
        tap((resp: Array<ISearchStock>) => {
          if (resp && resp.length > 0) this.selectStock(resp[0]);
        })
      )
      .subscribe();
  }

  private emitSearchStock = (
    stock: ISearchStock,
    isSearch: boolean = false
  ): void => {
    if (!stock) return;
    isSearch &&
      this.headerOptions.link &&
      this.globalStock.changeGlobalStock(stock);
    this.searchLabel = stock.cd_stock || '';
    this.selectStockChange.emit(stock);
  };

  private getStock(text: string): void {
    this.searchStock(text)
      .pipe(takeUntil(this.destroy$))
      .subscribe((resp: ISearchStock[]) => {
        this.listStock = resp;
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  private searchStock(text: string): Observable<any[]> {
    return this.stockService.searchStock(text, this.typesOut);
  }

  private setGlobalStockSelected() {
    const stock = this.globalStock.getGlobalStockSelected();
    this.headerOptions.link && this.emitSearchStock(stock);
  }

  public async subscribeGlobalStockChange() {
    this.stockStreamActive = await this.globalStock.onGlobalStockChange();
    this.readStream(this.stockStreamActive.stream, this.onGlobalStockChange);
  }

  private onGlobalStockChange = (stock: ISearchStock) => {
    if (this.headerOptions.link! && stock) {
      this.emitSearchStock(stock);
    }
  };

  private initGlobalLinked(): void {
    this.linkDoc.onLinked(this.ref).subscribe((linked) => {
      this.headerOptions.link = linked;
      if (linked) {
        this.setGlobalStockSelected();
        this.subscribeGlobalStockChange();
      } else {
        this.stockStreamActive?.close();
      }
    });
  }

  customSearchFn() {
    return true;
  }
}
