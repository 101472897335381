<div id="chart-tiger" class="position-relative w-100 h-100">
  <div *ngFor="let key of showStockMessage.keys()">
    <app-tiger-chart-message
      *ngIf="!forceHideStockMessage"
      [options]="showStockMessage.get(key)!!"
      [stock]="stock"
      [quoteData]="quoteData"
      (closeMessage)="hideStockMessage()"
      (goToNextActiveStock)="goToNextActiveStock()"
    ></app-tiger-chart-message>
  </div>
  <div
    [ngClass]="{
      withMessage: showStockMessage.size() && !forceHideStockMessage
    }"
    class="d-flex w-100 h-100"
  >
    <div class="chart-tiger-tools" [style.height.%]="100" *ngIf="withTools">
      <app-tiger-chart-tools
        #tigerChartTools
        [componentRef]="ref"
        [componentReference]="refComponent"
        [defaultCursor]="defaultCursor"
      ></app-tiger-chart-tools>
    </div>
    <div [id]="idMainChart" class="d-flex flex-column w-100 h-100">
      <div class="position-relative h-100">
        <app-tiger-chart-tooltip
          #TigerChartTooltip
          [style.width.%]="100"
          class="tooltip-element"
          [theme]="theme"
          [id]="ref + '_indicators'"
          [graphContainer]="graphContainer"
          [tickSize]="configs.yAxis.labelPrecision || 0"
          [vl_volume]="volume"
          [getIndicators$]="getIndicators$"
          (changeIndicatorSettings)="
            openIndicatorSettings($event.type, $event.lineNumber)
          "
          (changeIndicatorDelete)="deleteIndicator($event)"
          (indicatorVisibility)="indicatorVisibility($event)"
        ></app-tiger-chart-tooltip>
        <div
          graphContainer
          [style.width.%]="100"
          [style.height.%]="100"
          [id]="ref"
        ></div>
        <app-tiger-chart-controls
          *ngIf="
            (navigationControl === 'ALWAYS_SHOW' ||
              navigationControl === 'ONLY_HOVER') &&
            !selectedTool
          "
          [width]="configs.width - widthRemove"
          [typeControl]="navigationControl"
          (control)="updateVisibleRangeChart($event)"
        ></app-tiger-chart-controls>
        <div
          class="position-absolute control-back"
          [ngClass]="{ 'control-back-hidden': !showControlBack }"
          [ngStyle]="{
            'top.%': 88,
            'right.px': 65
          }"
        >
          <div
            (click)="resetVisibilityChart()"
            class="text-brand-primary cursor-pointer bottom-0"
            [ngStyle]="{
              'right.px': controlBackRightPosition
            }"
          >
            <span class="material-icons-outlined"
              >keyboard_double_arrow_right</span
            >
          </div>
        </div>
      </div>
      <div *ngFor="let key of subCharts.keys(); let i = index">
        <div
          cdkDrag
          [cdkDragBoundary]="'#chart-tiger'"
          (cdkDragEnded)="move(key.toString())"
          class="divider-tiger-chart"
          [id]="'divider_' + key.toString()"
          [cdkDragFreeDragPosition]="transform"
        >
          <div></div>
        </div>
        <app-sub-tiger-chart
          [selectedStock]="stock"
          [subCharts]="subCharts"
          [cdSegment]="cdSegment"
          [dsAsset]="dsAsset"
          [papel]="papel"
          [key]="key.toString()"
          [height]="
            subCharts.get(key)!!.height -
            (subCharts.size() === i + 1 ? footerHeight : 0)
          "
          [width]="configs.width - widthRemove"
          [settings]="configs"
          [position]="i"
          (subChartMouseHover)="mouseEvents($event)"
          [id]="'subchart-' + key.toString()"
          [stockChartRef]="ref"
          [indicadores]="indicators"
          [data]="data"
          [updateData]="updateDataSubChart"
          [isMouseOverValue]="isMouseOverValue"
          [showXGridLines]="showSubchartXLines"
          [showYGridLines]="showSubchartYLines"
          [firstVisibleRange]="_firstVisibleRange"
          [previousVisibleRange]="_previousVRange"
          [fatherXAxis]="xAxis"
          [fastRuler]="showFastRuler"
          (indicatorSettings)="
            openIndicatorSettings(
              $event.type,
              $event.lineNumber,
              $event.refId,
              $event.anotherBaseChart
            )
          "
          (indicatorDelete)="deleteIndicator($event)"
          (newCursor)="cursor = $event"
          (indicatorVisibility)="indicatorVisibility($event)"
        ></app-sub-tiger-chart>
      </div>
    </div>
    <div
      *ngIf="showFastOrder && canShowFastOrder"
      #fastOrder
      class="chart-tiger-orders"
      [style.height.%]="100"
      [style.width.px]="!withTools ? divOrderWidth - 4 : divOrderWidth + 1"
    >
      <div
        cdkDrag
        [cdkDragBoundary]="'#chart-tiger'"
        cdkDragLockAxis="x"
        [cdkDragFreeDragPosition]="transform"
        class="divider-tiger-chart-order"
        id="divider_order"
        (cdkDragEnded)="moveDividerOrder($event)"
      >
        <div class="d-flex justify-content-center align-items-center">
          <fla-icon
            css="pr-3"
            size="icon-size-md"
            name="chevron_left"
          ></fla-icon>
        </div>
      </div>
      <app-tiger-chart-orders
        [width]="!withTools ? divOrderWidth - 4 : divOrderWidth + 1"
        class="position-relative h-100"
        [stockChartConfig]="configs"
        [refComponent]="refComponent"
        [selectedStock]="stockTrade"
        [closePrice]="vlCloseLine"
        [minPriceIncrement]="minPriceIncrement"
        [userCustody]="userCustody"
        (fastOrdersClick)="fastOrdersClick($event)"
        [showFastOrderDaytradeStrategy]="showFastOrderDaytradeStrategy"
      ></app-tiger-chart-orders>
    </div>
  </div>
  <app-tiger-chart-annotation-tooltip
    *ngIf="showAnnotationTooltip"
    [ref]="ref"
    [top]="annotationTooltipTop"
    [left]="annotationTooltipLeft"
    [label]="annotationTooltipLabel"
    [isVisible]="annotationTooltipVisible"
    [typeButtons]="typeButtons"
    [isExpanded]="isEventExpanded"
    [tickSizeDenominator]="tickSizeDenominator"
    [stock]="stock"
    [stockChartConfig]="configs"
    [forcedQtty]="forcedQtty"
    (forceClose)="hideAnnotationTooltip(true)"
  ></app-tiger-chart-annotation-tooltip>
  <custody-stop-orders-alert></custody-stop-orders-alert>
</div>
