<ng-container *ngIf="tableView === TABLE_VIEW.CARD; else chartView">
  <div class="p-2 card w-100 h-100 bg-neutral-strong card-min-height">
    <app-remove-stock-button
      [displayRemoveStock]="displayRemoveStock"
      (removeStock)="removeStock.emit()"
    ></app-remove-stock-button>
    <div
      class="w-100 pointer d-flex align-items-center justify-content-between"
      (click)="stockSelected.emit()"
    >
      <ng-container *ngIf="!auctionInfos; else cardAuctionTemplate">
        <ng-container *ngTemplateOutlet="cardDefaultTemplate"> </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #chartView>
  <div
    class="w-100 h-100 bg-neutral-strong p-2 round pointer"
    (click)="stockSelected.emit()"
  >
    <app-remove-stock-button
      *ngIf="displayRemoveStock"
      [displayRemoveStock]="displayRemoveStock"
      (removeStock)="removeStock.emit()"
    ></app-remove-stock-button>
    <div
      class="align-items-center d-inline d-inline-flex justify-content-between pointer position-relative w-100"
      style="height: 45px"
    >
      <div
        class="align-items-center align-items-center d-inline-flex gap-2 justify-content-center"
      >
        <app-stock-logo [image]="image"></app-stock-logo>
        <div class="d-flex flex-column">
          <div class="d-inline-flex gap-1 status-line">
            <app-status-stock
              [status]="situation"
              [isAuction]="auctionInfos !== undefined"
              [moveTop]="true"
              [auctionInfos]="auctionInfos"
            >
            </app-status-stock>
            <span
              class="custom-font-size fw-bold"
              [ngClass]="{ 'text-brand-primary': isGlobalStocks }"
            >
              {{ name }}
            </span>
          </div>
          <span
            class="fs-7 text-truncate stock-name text-neutral-medium"
            [tooltip]="companyName"
          >
            {{ companyName }}
          </span>
        </div>
      </div>
      <div class="align-items-center d-inline-flex gap-2">
        <div class="chart-container-chart">
          <ng-container *ngIf="vlCloseHistory?.length">
            <app-chart-line
              [data]="vlCloseHistory!"
              [tickSize]="tickSize"
              [lineColor]="variationBgColor"
              [cdStock]="name"
              [updateLastValue]="chatLastValue"
            ></app-chart-line>
          </ng-container>
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <span
            class="fs-6 text-truncate custom-font-size fw-bold"
            style="max-width: 55px"
            [tooltip]="stockPrice"
          >
            {{ stockPrice }}
          </span>
          <app-tag
            [useSmallTag]="true"
            [isVariation]="true"
            [variation]="variation"
            [bgColor]="variationBgColor"
            [color]="variationTextColor"
            symbol="%"
            [auctionInfos]="auctionInfos"
            [situation]="situation"
          ></app-tag>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cardDefaultTemplate>
  <div class="d-flex flex-column w-100">
    <div class="d-inline-flex status-line" [ngClass]="{ 'gap-1 ': situation }">
      <app-status-stock
        [status]="situation"
        [isAuction]="false"
        [moveTop]="true"
        [auctionInfos]="undefined"
      >
      </app-status-stock>
      <p
        class="card-title fs-base fw-bold m-0 p-0 custom-font-size text-nowrap"
        [ngClass]="{
          'text-brand-primary': isGlobalStocks,
          'position-absolute': situation,
        }"
      >
        {{ name }}
      </p>
    </div>

    <small
      class="mb-2 text-neutral-medium text-truncate subtitle custom-font-size"
      [tooltip]="companyName"
    >
      {{ companyName }}
    </small>
    <app-tag
      [useSmallTag]="true"
      [isVariation]="true"
      [variation]="variation"
      [bgColor]="variationBgColor"
      [color]="variationTextColor"
      [auctionInfos]="undefined"
      [situation]="situation"
      symbol="%"
    ></app-tag>
  </div>
  <div class="w-100 h-100 d-flex flex-column align-items-end">
    <div class="chart-container-card">
      <ng-container *ngIf="vlCloseHistory?.length">
        <app-chart-line
          [lineColor]="variationBgColor"
          [data]="vlCloseHistory"
          [tickSize]="tickSize"
          [cdStock]="name"
          [updateLastValue]="chatLastValue"
          [highlightLastPointUpdate]="true"
          [stockAtAuction]="auctionInfos !== undefined"
        ></app-chart-line>
      </ng-container>
    </div>
    <span
      class="custom-font-size custom-price fw-bold m-0 p-0 pl-1 text-right text-truncate w-100 text-center"
      [tooltip]="stockPrice"
      [tooltipHidden]="disablePriceTooltip"
    >
      {{ stockPrice }}
    </span>
  </div>
</ng-template>

<ng-template #cardAuctionTemplate>
  <div class="vstack gap-1">
    <div class="align-items-center hstack justify-content-between">
      <div class="hstack status-line">
        <app-status-stock
          [status]="situation"
          [auctionInfos]="auctionInfos"
          [isAuction]="true"
          [moveTop]="true"
        >
        </app-status-stock>
        <p
          class="card-title fs-base fw-bold m-0 p-0 custom-font-size text-nowrap auction-position position-absolute"
          [ngClass]="{ 'text-brand-primary': isGlobalStocks }"
        >
          {{ name }}
        </p>
      </div>
      <span
        class="fs-7 text-feedback-warning"
        [innerHTML]="stockAuctionInfos?.qtde_leilao_nao_atendida"
      >
      </span>
    </div>

    <div
      class="align-items-center hstack justify-content-between text-feedback-warning fs-7"
    >
      <div class="hstack gap-1">
        <span class="hstack justify-content-center auction-info-width">
          {{ stockAuctionInfos?.variacao_leilao }}
        </span>
        <div class="vr custom-vr bg-feedback-warning"></div>
        <span class="hstack justify-content-center auction-info-width">
          {{ stockAuctionInfos?.preco_leilao }}
        </span>
      </div>
      <span>{{ stockAuctionInfos?.qtde_leilao }}</span>
    </div>

    <div
      class="align-items-center hstack justify-content-between text-feedback-warning fs-7"
    >
      <div class="hstack text-light gap-1">
        <span class="hstack justify-content-center auction-info-width">
          {{ variation }}%
        </span>
        <div class="bg-neutral-smoother custom-vr vr"></div>
        <span class="hstack justify-content-center auction-info-width">
          {{ stockPrice }}
        </span>
      </div>
      <div class="align-items-center gap-1 hstack justify-content-end">
        <fla-icon
          name="timer"
          size="icon-size-nano"
          css="text-feedback-warning"
          iconType="icons-outlined"
        ></fla-icon>
        <span>{{ timer }}</span>
      </div>
    </div>
  </div>
</ng-template>
