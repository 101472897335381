import { Subject } from 'rxjs';
import { Point } from 'scichart/Core/Point';
import { ModifierMouseArgs } from 'scichart/Charting/ChartModifiers/ModifierMouseArgs';
import { testIsInBounds } from 'scichart/utils/pointUtil';
import { TIGER_MOUSE_EVENT_DATA_TYPE } from '../enum';
import {
  IXAxisDragModifierOptions,
  XAxisDragModifier,
} from 'scichart/Charting/ChartModifiers/XAxisDragModifier';
import { AxisBase2D } from 'scichart';

export class TigerXAxisDragModifier extends XAxisDragModifier {
  events$ = new Subject<any>();
  constructor(options?: IXAxisDragModifierOptions) {
    super(options);
  }

  grow(fraction: number) {
    this.pointFrom = new Point(600, 0);
    this.growBy(new Point(0, 0), this.parentSurface.xAxes.get(0), fraction);
  }

  makeScaling(pointTo: Point) {
    super.doScaling(pointTo);
  }

  protected override doScaling(pointTo: Point): void {
    this.makeScaling(pointTo);
    this.events$.next({});
  }

  override modifierMouseMove(args: ModifierMouseArgs): void {
    super.modifierMouseMove(args);
    const mousePoint = args.mousePoint;
    if(this.xAxisId){
      const axis = this.parentSurface?.xAxes.getById(this.xAxisId)
      if(axis){
        this.sendHoverEvent(axis, mousePoint)
      }
    }else{
      this.parentSurface?.xAxes.asArray().forEach((xAxis) => {
        this.sendHoverEvent(xAxis, mousePoint)
      });
    }
  }

  private sendHoverEvent = (xAxis: AxisBase2D, mousePoint: Point) => {
    const viewRect = xAxis.viewRect;
    if (!viewRect) {
      return;
    }
    if (
      testIsInBounds(
        mousePoint.x,
        mousePoint.y,
        viewRect.left,
        viewRect.bottom,
        viewRect.right,
        viewRect.top
      )
    ) {
      this.events$.next({
        type: TIGER_MOUSE_EVENT_DATA_TYPE.XAXIS_HOVER,
        data: null,
      });
    } else {
      this.events$.next({});
    }
  }
}
