import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[flaFocusAndBlur]',
})
export class FlaFocusAndBlurDirective {
  @Output() public flaFocusBlurChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef) {
    this.elementRef.nativeElement.tabIndex = 0;
    this.elementRef.nativeElement.onfocus = this.focus;
    this.elementRef.nativeElement.onblur = this.blur;
  }

  private focus = () => this.flaFocusBlurChange.emit(true);

  private blur = (): void => this.flaFocusBlurChange.emit(false);
}
