<div
  *ngIf="cards.length"
  class="align-items-center d-flex flex-wrap"
  [ngClass]="{ 'justify-content-between': !expanded }"
>
  <div
    class="bg-neutral-strong p-1 round text-center card-flow mb-1"
    *ngFor="let card of cards"
    [ngClass]="{ 'mr-1 expanded': expanded, 'cursor-pointer': isFilter }"
    (click)="hideType(type + '_' + card[field], card)"
    [style.opacity]="card?.disabled ? 0.4 : 1"
  >
    <div class="fw-bolder fs-7 text-truncate" [title]="card[field]">
      {{ card[field] }}
    </div>
    <div
      class="card-legend"
      [style.background-color]="colors[card[field]]"
    ></div>
    <div
      class="fw-bolder fs-6"
      [ngClass]="{
        'text-feedback-positive': card.sum_delta_volume >= 0,
        'text-feedback-negative': card.sum_delta_volume < 0
      }"
    >
      <ng-container *ngIf="card.sum_delta_volume > 0"> + </ng-container>
      {{ card.delta_formatted }}
    </div>
  </div>
  <div
    class="subtitle-card"
    [ngClass]="{
      'w-100 d-flex justify-content-between': !expanded,
      'ml-1': expanded
    }"
  >
    <div *ngIf="type === 'flow_b3'">Volume em R$</div>
    <div *ngIf="type === 'future_ibov' || type === 'future_dolar'">
      Número de contratos
    </div>
    <div>Ref. {{ dtReference }} - Últimos 7 dias</div>
  </div>
</div>
