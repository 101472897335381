import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'workspace-doc-footer',
  templateUrl: './workspace-doc-footer.component.html',
  styleUrls: ['./workspace-doc-footer.component.scss'],
})
export class WorkspaceDocFooterComponent implements OnInit {
  @Input() css!: string;
  constructor() {}

  ngOnInit() {}
}
