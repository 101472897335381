export enum CHART_MODAL_CONFIGS_TAB_NAME {
  ESTILO = 'estilo',
  NEGOCIACAO = 'negociacao',
  EVENTOS = 'eventos',
  ATALHOS = 'atalhos',
}

export enum CHART_LOADING_KEYS {
  MARKET_BUY_BUTTON,
  MARKET_SELL_BUTTON,
}

export enum CHART_ALTER_SCALE_CONFIGS {
  LOGARITHMIC = 'Alterar para escala logarítmica',
  ARITHMETIC = 'Alterar para escala aritmética',
  ON_AUTO = 'Desabilitar ajuste automático da escala',
  OFF_AUTO = 'Habilitar ajuste automático da escala',
  OFF_FAST_RULER = 'Ativar régua e desativar navegação (CTRL)',
  ON_FAST_RULER = 'Ativar navegação e desativar régua (CTRL)',
  ON_BOOK_CHART = 'Exibir Book',
  OFF_BOOK_CHART = 'Ocultar Book',
  ON_VOLUME_AT_PRICE_CHART = 'Exibir Volume x Preço',
  OFF_VOLUME_AT_PRICE_CHART = 'Ocultar Volume x Preço',
  ON_FAST_ORDER = 'Exibir Boleta rápida',
  OFF_FAST_ORDER = 'Ocultar Boleta rápida',
  ON_VOLUME = 'Exibir Volume',
  OFF_VOLUME = 'Ocultar Volume',
  ON_EVENTS = 'Exibir Eventos',
  OFF_EVENTS = 'Ocultar Eventos',
  ON_EXEC_ORDERS = 'Exibir ordens executadas',
  OFF_EXEC_ORDERS = 'Ocultar ordens executadas',
  ON_PREVIOUS_CLOSE = 'Exibir fechamento dia anterior',
  OFF_PREVIOUS_CLOSE = 'Ocultar fechamento dia anterior',
  ON_BID_ASK = 'Exibir bid e ask',
  OFF_BID_ASK = 'Ocultar bid e ask',
  ON_ZOOM_IN = 'Habilitar zoom em área',
  OFF_ZOOM_IN = 'Desabilitar zoom em área',
}

export enum HEADER_TYPES {
  DEFAULT = 'DEFAULT',
  SCALE_ONE = 'SCALE_ONE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  MICRO = 'MICRO',
  NANO = 'NANO',
}

export enum ARROW_TYPES {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
}

export enum STOCK_CHART_ELEMENT_IDS {
  HEADER_PERIOD_AND_STYLE = 'STOCK_CHART_HEADER_PERIOD_AND_STYLE',
  HEADER_INDICATORS_AND_DRAW = 'STOCK_CHART_HEADER_INDICATORS_AND_DRAW',
  HEADER_X_RAY = 'STOCK_CHART_HEADER_X_RAY',
  HEADER_BUY_AT_MARKET = 'STOCK_CHART_HEADER_BUY_AT_MARKET',
  CONTEXT_NEGOTIATION_SHORTCUT = 'STOCK_CHART_CONTEXT_NEGOTIATION_SHORTCUT',
  CONTEXT_ALERTS = 'STOCK_CHART_CONTEXT_ALERTS',
  FOOTER_BOOK_AND_VOLUME_X_PRICE = 'STOCK_CHART_FOOTER_BOOK_AND_VOLUME_X_PRICE',
  STOCK_TRADE_EVENTS_AND_VOLUME = 'STOCK_CHART_STOCK_TRADE_EVENTS_AND_VOLUME',
  INDICATORS = 'INDICATORS',
}
