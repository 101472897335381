import { CommonModule } from '@angular/common';
import { Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { system } from '@core/system/system.service';
import { ModalConnectBrokerComponent } from '@modules/broker-connection/modals/modal-connect-broker/modal-connect-broker.component';
import { StandaloneModalsService } from '@shared/modals/standalone-modals.service';
import {
  ROCKET_MODAL_DATA,
  RocketIModal,
  RocketModalModule,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { AuthService } from '@shared/services';
import {
  IBrokerLogo,
  TrademapCDNService,
} from '@shared/services/api/cdn/trademap-cdn.service';
import { IBrokersToConnect } from '@shared/services/api/trademap/V9/models/brokers-to-connect.interface';
import { PartnersService } from '@shared/services/core/partners-services/partners.service';
import { BenefitCardComponent } from '@shared/standalone-components/benefit-card/benefit-card.component';
import { filter, take } from 'rxjs';
import { alphabeticalSort, getUserName } from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-trial-expired',
  templateUrl: './trial-expired.component.html',
  styles: [
    `
      .disable-click {
        pointer-events: none;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, RocketModalModule, BenefitCardComponent],
})
export class TrialExpiredComponent
  extends RocketModalRef
  implements OnInit, OnDestroy
{
  public title = 'Seu período de acesso gratuito já expirou :(';
  public leaving = false;
  public brokers!: IBrokersToConnect[];

  private _trademapCDNService = inject(TrademapCDNService);
  private _authService = inject(AuthService);
  private _partnersService = inject(PartnersService);
  private _standaloneModalsService = inject(StandaloneModalsService);

  constructor(
    private _rocketService: RocketModalService,
    @Inject(ROCKET_MODAL_DATA)
    private data: { brokers: IBrokersToConnect[] }
  ) {
    super(_rocketService);
    this._trademapCDNService.onDownloadedLogos
      .pipe(
        take(1),
        filter(() => this.brokers?.length > 0)
      )
      .subscribe((logos) => this._addBrokerLogo(logos));
  }

  ngOnInit() {
    this._setInvestorName();
    this._buildPartnersToConnect();
  }

  private _setInvestorName(): void {
    const name = getUserName(system.userAuthenticated.investor.name, 12);
    if (!name) return;
    this.title = `${name}, ${this.title}`;
  }

  private _downloadBrokersLogo(options: IBrokersToConnect[]): void {
    const logos = options.map((bkr) => bkr.brokerLogo).filter((logo) => logo);
    if (logos?.length) this._trademapCDNService.downloadManyBrokersLogo(logos);
  }

  private _buildPartnersToConnect(): void {
    const brokers = this.data.brokers.filter(
      (brk) => !this._partnersService.hideBroker(brk)
    );
    this._downloadBrokersLogo(brokers);
    this.brokers = alphabeticalSort(brokers, 'nmBrokerNickname');
    this.brokers.unshift({
      isTrademapPlan: true,
      brokerLogoUrl: '../../../../../assets/logo/logo-TM-icon.svg',
      nmBrokerNickname: 'TradeMap PRO',
      idBroker: 0,
      trialDescription: 'Assinatura',
      brokerCondition: [
        'RocketTrader',
        'Conexão com múltiplas corretoras',
        'Excel Add-in',
        'Carteira investimentos completa no TradeMap',
      ],
      brokerAdvantages: ['*Garanta o desconto aplicando o cupom PRO20'],
      brokerLogo: 'logo-trademap',
      hasAccount: false,
      nmBroker: 'TradeMap PRO',
      onboardingLink:
        'https://portal.trademap.com.br/onboard/contrate-promocao/trademappro/promocao/promensal',
    });
  }

  private _addBrokerLogo(logos: IBrokerLogo): void {
    this.brokers = this.brokers.map((brk) => {
      if (logos[brk.brokerLogo] && !brk.isTrademapPlan)
        brk.brokerLogoUrl = logos[brk.brokerLogo];
      return brk;
    });
  }

  public logout(): void {
    if (this.leaving) return;
    this.leaving = true;
    this._authService.performLogout({
      reason: 'USER_COMMAND',
    });
  }

  public handleBenefit(params: { action: string; value: string }): void {
    const { action, value } = params;
    if (this.leaving) return;
    if (action === 'URL') {
      window.open(value, '_blank');
      return;
    }
    const broker = this._getBroker(params.value);
    this._openConnectBroker(broker);
  }

  private _getBroker(nmBroker: string) {
    return this.brokers.find(
      (bkr) =>
        bkr.nmBrokerNickname === nmBroker || bkr.nmBrokerNickname === nmBroker
    )!;
  }

  public _openConnectBroker(broker: IBrokersToConnect) {
    this._closeModal();
    const modalSettings: RocketIModal = {
      keyboard: false,
      hideCloseButton: true,
      notClosable: true,
      backdrop: 'static',
    };
    this._standaloneModalsService.openStandaloneModal(
      ModalConnectBrokerComponent,
      {
        idBroker: broker.idBroker,
        customComponentOnClose: true,
        customComponentInfos: {
          component: TrialExpiredComponent,
          settings: modalSettings,
          size: 'xl',
        },
      },
      modalSettings,
      '',
      'lg'
    );
  }

  private _closeModal(): void {
    this._standaloneModalsService.close();
  }
}
