import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, delay, map, Observable, of, Subject } from 'rxjs';
import { ToastService } from '@shared/services/toast.service';
import { SideDict, TYPE_ORDE_SIDE_ENUM } from '@shared/enum/buyOrSell.enum';
import {
  getCodeByResp,
  isCodeScheduleReq,
  isInvalidIdBroker,
} from 'src/app/utils/utils.functions';
import { WarningService } from '@shared/services/warning.service';
import { ORDER_ERROS_STATUS_CODE } from '@shared/components/stock-chart/constants/stock-chart.constant';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { AUTH_LOCAL_KEYS } from '@shared/services/core/const/auth_util.const';
import {
  IAccountSelect,
  IOrderModification,
  IOrderSend,
} from 'src/app/core/interface';
import { ProcessSendOrderModel } from 'src/app/core/models/process-send-order.model';
import {
  SCHEDULE_WARN_MODAL_PARAMS,
  CONNECT_BROKER_WARN_MODEL_PARAMS,
} from '@shared/constants/general.contant';
import { OrderTokenService } from '@shared/rocket-components/modal-order-token/order-token.service';
import { StrategyService } from '@core/layout/header/strategy/service/strategy.service';
import { RestService } from '@shared/services/rest';
import { TYPE_ORDE_ENUM } from '@shared/components/stock-trade/enum/stock-trade.enum';
import { OriginAnalysisOrderService } from '@shared/services/origin-analysis-order.service';
import { TermsService } from '../../terms/v2/terms.service';
import { isRequiredTerm } from '@shared/modals/modal-accept-terms/constants/modal-accept-terms.constants';
import { StockServiceRT } from '../../nitro-ws/v1/stock.service';

@Injectable({
  providedIn: 'root',
})
export class OrsGatewayServiceV2 extends RestService {
  override _url: string = 'api/trademap/v2';
  private _tradingToken!: string;
  private _loadingComponent$: Subject<{
    loading: boolean;
    resetForm: boolean;
  }> = new Subject();
  private _splitNextOrder: boolean = false;

  get splitOrder(): boolean {
    if (this._splitNextOrder) {
      this._splitNextOrder = false;
      return true;
    }
    return (
      this._customPreferencesService.useSplit &&
      this._customPreferencesService.skipSplitConfirmation
    );
  }

  public onLoading = () => this._loadingComponent$.asObservable();
  constructor(
    http: HttpClient,
    private _toastService: ToastService,
    private _warningService: WarningService,
    private _customPreferencesService: CustomPreferencesService,
    private _orderToken: OrderTokenService,
    private _strategyService: StrategyService,
    private originAnalysisOrderService: OriginAnalysisOrderService,
    private termsService: TermsService,
    private stockService: StockServiceRT
  ) {
    super(http);
  }

  public sendNewOrderSingle(
    stock: any,
    orderValues: any,
    account: IAccountSelect,
    ordeSide: TYPE_ORDE_SIDE_ENUM,
    isConfirm: boolean = false,
    isSchedule: boolean = false,
    isValiditySynonymous: boolean = false,
    refComponent?: string
  ): Observable<any> {
    const serviceParams = {
      stock,
      orderValues,
      account,
      ordeSide,
      isConfirm,
      isSchedule,
      refComponent,
      isValiditySynonymous,
    };

    if (isInvalidIdBroker(account.id_broker)) {
      this._warningService.openWarningModal(CONNECT_BROKER_WARN_MODEL_PARAMS);
      return of(null);
    }

    if (this._brokerTermBeforeHandleOrder(serviceParams, true)) return of(null);

    const verifyToken = this._orderToken.hasTradingToken(account.id_broker);
    if (stock.is_synonymous && !isValiditySynonymous) {
      this.stockService
        .searchStockByStock(stock.cd_stock)
        .subscribe((searchedStock) => {
          serviceParams.stock = searchedStock
            ? searchedStock
            : serviceParams.stock;
          serviceParams.isValiditySynonymous = true;
          this.reSendNewOrderSingle(serviceParams);
        });
      return of(null);
    }
    if (!verifyToken.hasTrading) {
      this._sendOrderAfterSetTradingToken(serviceParams);
      return of(null);
    }
    this._tradingToken = verifyToken.tradingToken;

    let idStrategy = this._strategyService.findIdStrategyToSendOrder();
    orderValues.is_day_trade = this._customPreferencesService?.useDayTradeMode;
    if (orderValues.isFastOrder) {
      idStrategy = orderValues.strategy?.idStrategy || 0;
    } else if (orderValues.id_strategy) {
      idStrategy = orderValues.id_strategy;
    }
    const origin = this.originAnalysisOrderService.getOriginOrder();
    const order: IOrderSend = new ProcessSendOrderModel(
      stock,
      orderValues,
      account,
      ordeSide,
      this.splitOrder,
      idStrategy,
      origin
    );

    return this.post<any>('ors-gateway/setNewOrderSingle', {
      order: order,
      is_schedule: isSchedule,
      token: '',
    }).pipe(
      map((res) => {
        this._loadingComponent$.next({ loading: false, resetForm: true });
        return res.data;
      }),
      catchError((res) => {
        const code = getCodeByResp(res);
        if (isCodeScheduleReq(code)) {
          this._verifyCanScheduleOrder(
            stock,
            orderValues,
            account,
            ordeSide,
            order.ord_type,
            isConfirm
          );
          return of(null);
        }
        this._orderToken.isInvalidTradingToken(res);
        this._loadingComponent$.next({ loading: false, resetForm: true });
        if (this._canDisplayConfirmSplitOrderModal(isConfirm, code)) {
          this._openSplitOrderModal().subscribe((res) => {
            if (res && res.value)
              this._sendSplitedOrder(
                stock,
                orderValues,
                account,
                ordeSide,
                isSchedule
              );
          });
          return of(null);
        }
        const msgError = res.error ? res.error : res.message;
        this._toastService.showToast('error', msgError);
        return of(res.data);
      })
    );
  }

  private _canDisplayConfirmSplitOrderModal(
    isConfirm: boolean,
    code: string
  ): boolean {
    if (this._customPreferencesService.skipSplitConfirmation) return false;
    return !isConfirm && ORDER_ERROS_STATUS_CODE.includes(code);
  }

  private _sendSplitedOrder(
    stock: any,
    orderValues: any,
    account: IAccountSelect,
    ordeSide: TYPE_ORDE_SIDE_ENUM,
    isSchedule: boolean = false
  ): void {
    this._splitNextOrder = true;
    this.reSendNewOrderSingle({
      stock,
      orderValues,
      account,
      ordeSide,
      isConfirm: true,
      isSchedule,
      isValiditySynonymous: false,
    });
  }

  private _openSplitOrderModal(): Observable<any> {
    return this._warningService.openWarningModal({
      title: 'Aviso',
      text: 'A quantidade não é múltipla do lote de negociação. Deseja enviar mais de uma ordem no lote fracionário?',
      cancelButton: 'Não',
      confirmeButton: 'Sim',
      isSecondModal: false,
      askAgain: true,
      typeAskAgain: AUTH_LOCAL_KEYS.DONT_ASK_SPLIT,
    });
  }

  private _verifyCanScheduleOrder(
    stock: any,
    orderValues: any,
    account: IAccountSelect,
    ordeSide: TYPE_ORDE_SIDE_ENUM,
    ord_type: string,
    isConfirm: boolean = false
  ): void {
    const params = {
      stock,
      orderValues,
      account,
      ordeSide,
      isConfirm,
      isSchedule: true,
      isValiditySynonymous: false,
    };
    if (
      ord_type != TYPE_ORDE_ENUM.MARKET.toString() &&
      ord_type != TYPE_ORDE_ENUM.DOUBLE_START_STOP.toString() &&
      ord_type != 'D'
    ) {
      this._warningService
        .openWarningModal(SCHEDULE_WARN_MODAL_PARAMS)
        .subscribe((sendOrderWithSchedule: any) => {
          if (!sendOrderWithSchedule || !sendOrderWithSchedule.value) {
            this._loadingComponent$.next({ loading: false, resetForm: false });
            return;
          }
          this.reSendNewOrderSingle(params);
        });
      return;
    }
    this.reSendNewOrderSingle(params);
  }

  public orderModificationRequest(order: IOrderModification): Observable<any> {
    if (this._brokerTermBeforeHandleOrder(order, false)) return of(null);

    const verifyToken = this._orderToken.hasTradingToken(order.id_broker);
    if (!verifyToken.hasTrading) {
      this._orderToken.showTokenModal(() =>
        this.orderModificationRequest(order)
      );
      return of(null);
    }
    this._tradingToken = verifyToken.tradingToken;
    order.origin = this.originAnalysisOrderService.getOriginOrder();
    const params = {
      request: { ...order },
      // token: this._tradingToken,
      token: '',
    };
    return this.post<any>(
      'ors-gateway/order-modification-request',
      params
    ).pipe(
      map((res) => {
        return res.data;
      }),
      catchError((res) => {
        this._orderToken.isInvalidTradingToken(res);
        this._toastService.showToast('error', res.error);
        return res.data;
      })
    );
  }

  private _brokerTermBeforeHandleOrder(
    params: any,
    isNewOrder: boolean
  ): boolean {
    const idBroker = this._getIdBroker(params, isNewOrder);
    const broker = this.termsService.hasBrokerTermToAccept(idBroker);
    if (broker) {
      const sub = of(null)
        .pipe(delay(75))
        .subscribe(() => {
          this.termsService
            .openTermsModal({
              isBrokerTerm: true,
              brokerName: broker.nm_broker_nickname,
              code: broker.term.status,
              content: broker.term.content,
              idBrokerTerm: broker.term.idTerm,
            })!
            .subscribe((accepted) => {
              if (accepted === true || !isRequiredTerm(broker.term.status)) {
                this.termsService.updateBrokerTermStatus(idBroker);
                if (isNewOrder) {
                  this.reSendNewOrderSingle(params);
                } else this.orderModificationRequest(params).subscribe();
              }
              this.termsService.disableTerminal(false);
            });
          sub.unsubscribe();
        });
      return true;
    }
    return false;
  }

  private _getIdBroker(params: any, isNewOrder: boolean): number {
    if (isNewOrder) return params.account.id_broker;
    return params.id_broker;
  }

  private reSendNewOrderSingle(params: any) {
    this.sendNewOrderSingle(
      params.stock,
      params.orderValues,
      params.account,
      params.ordeSide,
      params.isConfirm,
      params.isSchedule,
      params.refComponent,
      params.isValiditySynonymous
    ).subscribe();
  }

  private _sendOrderAfterSetTradingToken(params: any): void {
    this._orderToken.showTokenModal(
      () => {
        this.reSendNewOrderSingle(params);
      },
      {
        ordType: params.orderValues.ord_type,
        refComponent: params.refComponent,
        gainOrderPrice: params.orderValues.gainOrderPrice,
        lossOrderPrice: params.orderValues.lossOrderPrice,
        stock: params.stock,
        price: params.orderValues.price,
        qtd: params.orderValues.order_qty,
        side: params.ordeSide,
        validity: params.orderValues.validade,
        date: params.orderValues.date,
      }
    );
  }
}

export const ORS_MESSAGES = {
  CANCEL_SENT: 'Cancelamento da ordem enviado',
  EDIT_SENT: 'Edição da ordem enviado',
  NEWORDER_SENT: (side: any, cd_stock_order: any) =>
    `Ordem de ${SideDict[side]} de ${cd_stock_order} Assim que ela for executada, a gente te avisa ;)`,
};
