<rocket-modal>
  <rocket-modal-header [hideCloseButton]="true">
    <div>
      <h3 class="modal-title">Configurações das Linhas</h3>
    </div>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <ng-container [formGroup]="form">
      <ng-container *ngFor="let input of fields">
        <div
          class="field-row d-flex align-items-center justify-content-between w-100 py-2"
        >
          <label>{{ input.title }}</label>
          <div class="d-flex w-50 justify-content-end" [class]="input.cssDiv">
            <div
              *ngFor="let control of input.fields"
              [style.width]="control.width"
            >
              <ng-container *ngIf="control.type === inputTypes.CHECKBOX">
                <ng-container
                  *ngTemplateOutlet="
                    checkBoxTemplate;
                    context: {
                      input,
                      field: control.key,
                      control,
                      formControlName: getFormControlName(input, control.key)
                    }
                  "
                ></ng-container>
              </ng-container>
              <ng-container *ngIf="control.type === inputTypes.SELECT">
                <ng-container
                  *ngTemplateOutlet="
                    selectTemplate;
                    context: {
                      input,
                      field: control.key,
                      formControlName: getFormControlName(input, control.key)
                    }
                  "
                ></ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template
        #checkBoxTemplate
        let-formControlName="formControlName"
        let-input="input"
        let-field="field"
        let-control="control"
      >
        <app-check
          *ngIf="!control.disabled; else disabledCheckbox"
          [isSwitch]="true"
          [isTextLeft]="true"
          css="justify-content-between"
          [formControlName]="formControlName"
          [isChecked]="form.get(formControlName)?.value"
          (flaChange)="updateConfig(input, field)"
        ></app-check>
        <ng-template #disabledCheckbox>
          <div class="d-flex justify-content-center pl-2">
            <fla-icon
              [tooltip]="control.disabledTooltip"
              name="lock"
              size="icon-size-sm"
              css="text-neutral-medium user-select-none"
            ></fla-icon>
          </div>
        </ng-template>
      </ng-template>
      <ng-template
        #selectTemplate
        let-formControlName="formControlName"
        let-input="input"
        let-field="field"
      >
        <fla-select
          [formControlName]="formControlName"
          bindLabel="label"
          bindValue="key"
          margin="m-0"
          [isSmall]="true"
          [list]="lineSideOptions"
          [searchable]="false"
          labelPlacement="horizontal"
          [selectCss]="input.css ?? ''"
          labelCss="fs-base text-white fw-normal"
          (flaChange)="updateConfig(input, field)"
        ></fla-select>
      </ng-template>
    </ng-container>
    <div
      class="d-flex align-items-start my-6 align-flex-start justify-content-between"
    >
      <div>
        <div tooltip="Restaurar padrão original">
          <rocket-button
            label="Restaurar Padrão"
            css="log-button p-0 text-brand-primary"
            type="fill"
            (rocketClick)="resetToDefault()"
          ></rocket-button>
        </div>
      </div>
      <div>
        <rocket-button
          label="Cancelar"
          type="fill"
          css="mr-3"
          [isSmall]="true"
          (rocketClick)="closeModal(false)"
        ></rocket-button>
        <rocket-button
          label="Confirmar"
          [isSmall]="true"
          (rocketClick)="closeModal(true)"
        ></rocket-button>
      </div>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
