<rocket-modal>
  <rocket-modal-header [hideCloseButton]="true">
    <div>
      <h3 class="modal-title">Nossas boas-vindas ao RocketTrader! 🚀</h3>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div class="w-100 d-flex flex-column gap-3 fs-5">
      <span>
        Somos uma plataforma focada em <span class="fw-bold">trading</span> com
        um ambiente que vai além das expectativas, garantindo
        <span class="fw-bold">performance</span> excepcional,
        <span class="fw-bold">estabilidade</span> robusta e a mais alta
        <span class="fw-bold">segurança</span>.
      </span>
      <span class="my-2">
        Fazemos parte do ecossistema do TradeMap, por isso, assinantes do
        TradeMap PRO possuem acesso ilimitado ao RocketTrader.
      </span>
      <span>
        Presenteamos a sua chegada com um
        <span class="fw-bold">trial de 7 dias</span> do TradeMap PRO para você
        conhecer. Aproveite!
      </span>
    </div>

    <div class="w-100 my-4">
      <div
        class="align-items-center d-inline-flex fs-5 gap-3 justify-content-between"
      >
        <img src="../../../../../assets/logo/logo-TM-icon.svg" />
        <div class="d-flex flex-column gap-2 justify-content-between">
          <span class="fw-bold">TradeMap PRO</span>
          <span class="fw-bold fs-6">Assinatura | R$ 290,83/mês</span>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column gap-2">
      <ng-container *ngFor="let benefit of partnerBenefits">
        <div
          class="align-items-center d-inline-flex gap-1 justify-content-start"
        >
          <span
            class="material-icons-outlined icon-size-tiny text-feedback-success"
          >
            done
          </span>
          <span class="fs-6">{{ benefit }}</span>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="someError">
      <span class="d-block fs-5 my-3 text-feedback-error">
        Ocorreu um erro ao habilitar o período de teste, tente novamente ou
        entre em contato conosco
      </span>
    </ng-container>
  </app-rocket-modal-body>

  <rocket-modal-footer>
    <rocket-button
      *ngIf="someError"
      type="fill"
      label="Sair"
      (rocketClick)="logout()"
      css="mr-3"
    >
    </rocket-button>
    <rocket-button
      *ngIf="someError"
      type="outline"
      label="Tentar novamente"
      (rocketClick)="enableTrial()"
      css="mr-3"
    >
    </rocket-button>
    <rocket-button
      label="Começar a usar"
      [loading]="isLoading"
      [isDisabled]="!sevenDaysTrialEnabled && !onlyDisplay"
      (rocketClick)="doAuthenticationAfterEnableTrial()"
    >
    </rocket-button>
  </rocket-modal-footer>
</rocket-modal>
