import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { formatterNumber, randomId } from '@shared/rocket-components/utils';
import { ScichartService } from '@shared/tiger-chart/services/scichart.service';
import {
  CursorModifier,
  CursorTooltipSvgAnnotation,
  EAxisAlignment,
  FastColumnRenderableSeries,
  NumberRange,
  SeriesInfo,
  TCursorTooltipSvgTemplate,
  TWebAssemblyChart,
  TextLabelProvider,
  XyDataSeries,
  XySeriesInfo,
  adjustTooltipPosition,
} from 'scichart';
import { IChartFund } from '../../interface/rent.interface';
import {
  VMDateToMonthAndYear,
  formatByTick,
  formatDate,
} from 'src/app/utils/utils.functions';
import { CHART_TITLE_STYLES } from '../../constants/rent.constant';
import { CHART_COLORS, CHART_COLORS_LIGHT } from '@shared/tiger-chart/colors';
import { RocketNumericAxis } from '@shared/scichart/axis/rocket-numeric-axis';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { Subscription, auditTime } from 'rxjs';

@Component({
  selector: 'app-funds-chart',
  templateUrl: './funds-chart.component.html',
  styleUrls: ['./funds-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundsChartComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild('fundsChartContainer') public fundsChartContainer!: ElementRef;

  @Input() public cdStock: string = '';
  @Input() public fundData: IChartFund[] = [];
  @Input() public deltaMesPerc: number = 0;
  @Input() public lineIndex: number = 0;

  private _scichartService!: ScichartService;
  private _baseChart!: TWebAssemblyChart;
  private theme$!: Subscription;
  public isDarkTheme = true;
  public fundsChartRef: string = randomId('FUNDS_CHART');
  public fundInfo!: { label: string; value: string }[];
  public referenceDate: string = '';

  constructor(
    private themeService: ThemePreferencesService,
    private stockChartService: StockChartService,
    private cdr: ChangeDetectorRef
  ) {
    this._scichartService = new ScichartService(undefined, stockChartService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { fundData } = changes;
    if (fundData && fundData.currentValue) {
      const lastIndex = fundData.currentValue.length - 1;
      this._buildFundInfos(fundData.currentValue[lastIndex]);
      this.referenceDate = formatDate(
        fundData.currentValue[lastIndex].dt_reference
      );
      setTimeout(() => {
        this._buildChart();
        this.cdr.detectChanges();
      }, 100);
    }
  }

  ngOnDestroy(): void {
    this._baseChart?.sciChartSurface?.delete();
    this.theme$ && this.theme$.unsubscribe();
  }

  ngAfterViewInit() {
    this.isDarkTheme = this.themeService.isDarkTheme();
    this.theme$ = this.themeService
      .themeActiveObservable()
      .pipe(auditTime(100))
      .subscribe(() => {
        this.isDarkTheme = this.themeService.isDarkTheme();
        this.cdr.detectChanges();
      });
  }

  private _buildFundInfos(funds: IChartFund): void {
    const numberOptions = {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    };

    const monthPercentage = this.deltaMesPerc
      ? `${formatByTick(this.deltaMesPerc)} %`
      : '0 %';

    this.fundInfo = [
      {
        label: 'Variação Mês',
        value: monthPercentage,
      },
      {
        label: 'Qtd Compra',
        value: `${formatterNumber(funds.qtty_buy, numberOptions)}`,
      },
      {
        label: 'Qtd Venda',
        value: `${formatterNumber(funds.qtty_sell, numberOptions)}`,
      },
      {
        label: 'Qtd Final',
        value: `${formatterNumber(funds.qtty_final, numberOptions)}`,
      },
    ];
    this.cdr.detectChanges();
  }

  public _buildChart(): void {
    this._scichartService
      .initSciChart(`${this.fundsChartRef}${this.lineIndex}`)
      .then((res) => {
        this._baseChart = res;
        this._baseChart.sciChartSurface.background = '#FFFFFF00';
        const xIndexs: number[] = [];
        const yValues: number[] = [];
        const xValues: string[] = [];
        let maxValue: number = 0;
        let minValue: number = 0;
        this.fundData.forEach((data, index) => {
          xIndexs.push(index);
          xValues.push(VMDateToMonthAndYear(data.dt_reference));
          yValues.push(data.pc_net_worth);
          if (data.pc_net_worth > maxValue) maxValue = data.pc_net_worth;
          if (data.pc_net_worth < minValue) minValue = data.pc_net_worth;
        });
        maxValue = maxValue + maxValue * 0.25;
        minValue = minValue + minValue * 0.25;

        const xAxis = new RocketNumericAxis(
          this.themeService,
          this._baseChart.wasmContext,
          {
            labelProvider: new TextLabelProvider({ labels: xValues }),
            labelStyle: CHART_TITLE_STYLES,
            drawMajorGridLines: false,
            drawMinorGridLines: false,
            drawMajorBands: false,
            drawMajorTickLines: false,
            drawMinorTickLines: false,
          }
        );

        const yAxis = new RocketNumericAxis(
          this.themeService,
          this._baseChart.wasmContext,
          {
            axisAlignment: EAxisAlignment.Left,
            axisTitleStyle: CHART_TITLE_STYLES,
            drawMajorGridLines: false,
            drawMinorGridLines: false,
            drawMajorTickLines: false,
            drawMinorTickLines: false,
            drawMajorBands: false,
            autoTicks: true,
            majorDelta: 3,
            minorDelta: 3,
            labelStyle: CHART_TITLE_STYLES,
            growBy: new NumberRange(0.1, 0.2),
            lineSpacing: 2,
            maxAutoTicks: 8,
          }
        );

        yAxis.labelProvider.formatLabel = (value: number) =>
          `${value ? formatByTick(value) : '0'} %`;
        yAxis.labelProvider.formatCursorLabel = (value: number) =>
          `${formatByTick(value)} %`;

        this._baseChart.sciChartSurface.xAxes.add(xAxis);
        this._baseChart.sciChartSurface.yAxes.add(yAxis);

        const columnSeries = new FastColumnRenderableSeries(
          this._baseChart.wasmContext,
          {
            fill: CHART_COLORS.BRAND_PRIMARY,
            stroke: CHART_COLORS.BRAND_PRIMARY,
            strokeThickness: 0,
            dataSeries: new XyDataSeries(this._baseChart.wasmContext, {
              xValues: xIndexs,
              yValues,
              containsNaN: false,
              isSorted: true,
            }),
          }
        );
        this._baseChart.sciChartSurface.renderableSeries.add(columnSeries);

        const tooltipSvgTemplate: TCursorTooltipSvgTemplate = (
          seriesInfos: SeriesInfo[],
          svgAnnotation: CursorTooltipSvgAnnotation
        ): string => {
          const seriesInfo = seriesInfos[0];
          if (!seriesInfo?.isWithinDataBounds) return '<svg></svg>';
          const volumeTooltips: any = [];
          seriesInfos.forEach((si) => {
            const xySeriesInfo = si as XySeriesInfo;
            volumeTooltips.push(`${formatByTick(xySeriesInfo.yValue)} %`);
          });
          const bgColor = this.themeService.isDarkTheme()
            ? CHART_COLORS.NEUTRAL_STRONG
            : CHART_COLORS_LIGHT.NEUTRAL_STRONG;
          adjustTooltipPosition(100, 25, svgAnnotation);
          return `
          <svg width="100" height="25">
            <rect x="0" width="100" height="25" y="0" rx="4" style="fill: ${bgColor};"/>
            <svg width="100%">
              ${volumeTooltips.map((item: any) => {
                return `
                    <foreignobject class="node" width="100%" height="100%">
                      <div class="justify-content-center align-content-center d-flex align-items-center text-white fs-5">${item}</div>
                    </foreignobject>
                  `;
              })}
            </svg>
          </svg>`;
        };
        this._baseChart.sciChartSurface.chartModifiers.add(
          new CursorModifier({
            showTooltip: true,
            tooltipContainerBackground: CHART_COLORS.NEUTRAL_STRONG,
            tooltipTextStroke: CHART_COLORS.NEUTRAL_SMOOTHEST,
            crosshairStroke: CHART_COLORS.NEUTRAL_MEDIUM,
            tooltipSvgTemplate: tooltipSvgTemplate,
          })
        );

        this.stockChartService.removeChartLoading$.next({
          remove: true,
          ref: `${this.fundsChartRef}${this.lineIndex}`,
        });
        this.cdr.detectChanges();
      })
      .catch((error) => {
        console.error('COLUMN_BUILD_CHART', error);
      });
  }
}
