import { Component } from '@angular/core';
@Component({
  selector: 'fla-input-split',
  template: ` <span class="border-right border-thin mx-2 h-50"></span>`,
  styles: [
    `
      :host(fla-input-split) {
        display: contents;
      }
    `,
  ],
})
export class FlaInputSplitComponent {}
