import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ROCKET_MODAL_DATA } from '@shared/rocket-components/components/modal/constants/modal.contants';
import { RocketModalBodyComponent } from '@shared/rocket-components/components/modal/modal-parts/modal-body.component';
import { RocketModalFooterComponent } from '@shared/rocket-components/components/modal/modal-parts/modal-footer.component';
import { RocketModalHeaderComponent } from '@shared/rocket-components/components/modal/modal-parts/modal-header.component';
import { RocketModalComponent } from '@shared/rocket-components/components/modal/modal.component';
import { RocketModalService } from '@shared/rocket-components/components/modal/service/modal.service';
import { RocketCreateComponentService } from '@shared/rocket-components/services';

const COMPONENTS = [
  RocketModalComponent,
  RocketModalHeaderComponent,
  RocketModalBodyComponent,
  RocketModalFooterComponent,
];

@NgModule({
  imports: [CommonModule],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
  providers: [
    { provide: ROCKET_MODAL_DATA, useValue: {} },
    RocketCreateComponentService,
    RocketModalService,
  ],
})
export class RocketModalModule {}
