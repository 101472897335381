import { IOrderChartData } from '@shared/channel/order-chart.channel';
import { Subscription, auditTime } from 'rxjs';
import { CustomAnnotation, ICustomAnnotationOptions } from 'scichart';
import { ExecutedOrdersService } from '../services/executed-orders.service';
import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { Dictionary } from '@core/models';

export class ExecOrderAnnotation extends CustomAnnotation {
  readonly annotationOrder!: IOrderChartData;
  readonly isExecOrderAnnotation: boolean = true;
  private axisChanged$!: Subscription;
  constructor(
    private order: IOrderChartData,
    private drawToolsService: DrawToolsService,
    private execOrdersService: ExecutedOrdersService,
    private refComponent: string,
    options?: ICustomAnnotationOptions | undefined
  ) {
    super(options);
    this.annotationOrder = this.order;
    this.axisChanged$ = this.drawToolsService.axisChanged$
      .pipe(auditTime(400))
      .subscribe(() => {
        this.execOrdersService.addExecOrderAnnotation$.next({
          annotation: this,
          refComponent: this.refComponent,
        });
      });
  }

  override delete(): void {
    super.delete();
    this.axisChanged$ && this.axisChanged$.unsubscribe();
  }
}

export class ExecOrderFlagAnnotation extends CustomAnnotation {
  annotationOrder!: Dictionary<IOrderChartData>;
  readonly isExecOrderFlagAnnotation: boolean = true;
  private axisChanged$!: Subscription;
  public flagOptions: OrderFlagOptions = {
    conflicted: false,
    isOpenPnl: false,
    totalPnl: 0,
    positionIndex: 1,
  };
  constructor(
    private order: Dictionary<IOrderChartData>,
    private drawToolsService: DrawToolsService,
    private execOrdersService: ExecutedOrdersService,
    private refComponent: string,
    flagOptions: OrderFlagOptions | undefined,
    options?: ICustomAnnotationOptions | undefined,
  ) {
    super(options);
    this.annotationOrder = this.order;
    if (flagOptions)
      this.flagOptions = {
        ...this.flagOptions,
        ...flagOptions,
      };
    this.axisChanged$ = this.drawToolsService.axisChanged$
      .pipe(auditTime(400))
      .subscribe(() => {
        this.execOrdersService.addExecOrderFlagAnnotation$.next({
          annotation: this,
          refComponent: this.refComponent,
        });
      });
  }

  override delete(): void {
    super.delete();
    this.axisChanged$ && this.axisChanged$.unsubscribe();
  }
}

export interface OrderFlagOptions {
  conflicted: boolean;
  isOpenPnl: boolean;
  totalPnl: number;
  positionIndex: number;
}
