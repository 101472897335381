<div
  [id]="id"
  class="toast"
  role="alert"
  aria-live="assertive"
  aria-atomic="true"
>
  <fla-icon
    [iconType]="iconType"
    [name]="toastIconColor[data.type!].icon"
    [css]="toastIconColor[data.type!].color + ' pr-3'"
  ></fla-icon>

  {{ data.text }}

  <ng-container *ngIf="data.button === 'close'; else buttonLink">
    <fla-button-close dismiss="toast"></fla-button-close>
  </ng-container>
  <ng-template #buttonLink>
    <fla-links
      text="Link"
      iconPosition="right"
      css="toast-link text-nowrap"
      [url]="data.button!"
    >
      <fla-icon name="navigate_next"></fla-icon>
    </fla-links>
  </ng-template>
</div>
