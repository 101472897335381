export enum ORDERS_HISTORY_ELEMENT_IDS {
  INTRADAY = 'ORDERYS_HISTORY_INTRADAY_TAB',
  SWING = 'ORDERYS_HISTORY_SWING_TAB',
  POSITION = 'ORDERYS_HISTORY_POSITION_TAB',
  OPEN_ORDERS = 'ORDERYS_HISTORY_OPEN_ORDERS_TAB',
  EXEC_ORDERS = 'ORDERYS_HISTORY_EXEC_ORDERS_TAB',
  CLOSED_ORDERS = 'ORDERYS_HISTORY_CLOSED_ORDERS_TAB',
  ALL_ORDERS = 'ORDERYS_HISTORY_ALL_ORDERS_TAB',
  ALERT = 'ORDERYS_HISTORY_ALERT_TAB',
  MENU_CONTEXT = 'ORDERYS_HISTORY_MENU_CONTEXT',
  HIDE_COLUMN = 'ORDERYS_HISTORY_MENU_CONTEXT_HIDE_COLUMN',
  EDIT_AND_CANCEL_ORDER = 'ORDERYS_HISTORY_MENU_CONTEXT_EDIT_AND_CANCEL_ORDER',
  PREFERENCES = 'ORDERYS_HISTORY_MENU_CONTEXT_PREFERENCES',
  CLEAR_FILTERS = 'ORDERYS_HISTORY_MENU_CONTEXT_CLEAR_FILTERS',
  CLEAR_SORTED = 'ORDERYS_HISTORY_MENU_CONTEXT_CLEAR_SORTED',
  RESET_AND_HELP = 'ORDERYS_HISTORY_MENU_CONTEXT_RESET_AND_HELP',
  EXPORT_DATA = 'ORDERYS_HISTORY_MENU_CONTEXT_EXPORT_DATA'
}
