import {
  Component,
  ElementRef,
  Input,
  SimpleChanges,
  ViewChild,
  OnChanges,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  AfterViewInit,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { TFlaTag } from './types/tag-type.types';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styles: [
    `
      .small-tag {
        width: 55px !important;
        height: 24px !important;
        min-width: 55px !important;
        min-height: 24px !important;
      }

      .opacity-border-color {
        border-color: rgba(var(--vm-neutral-smoothest-rgb), 0.15) !important;
      }

      .gray {
        background-color: #caced4 !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlaTagComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild('tag', { static: true }) ref!: ElementRef<HTMLDivElement>;
  @Input() text!: string;
  @Input() isVariation: boolean = false;
  @Input() symbol!: string;
  @Input() title!: string;
  @Input() variation: string | number = '0';
  @Input() bgColor!: string;
  @Input() color!: string;
  @Input() colorNotVariation!: string;
  @Input() typeTag: TFlaTag = 'neutral';
  @Input() closeBtn: boolean = false;
  @Input() closeBtnClass!: string;
  @Input() titleSize: string = 'fs-6';
  @Input() titleColor: string = 'text-white';
  @Input() useSmallTag: boolean = false;
  @Input() auctionInfos!: any;
  @Input() situation!: string;
  @Output() flaClose = new EventEmitter<any>();
  private timeClear!: NodeJS.Timeout;
  private useClass: any = {
    neutral: 'gray text-neutral-stronger',
    positive: 'bg-feedback-success text-neutral-stronger',
    positiveWeak: 'bg-feedback-positive text-neutral-stronger',
    alert: 'bg-feedback-warning text-neutral-stronger',
    negativeWeak: 'bg-negative text-white',
    negative: 'bg-feedback-error text-white',
    outline: 'border border-thin border-neutral-strong text-body',
    outline_medium: 'border border-thin opacity-border-color text-body',
  };
  public styleTag = 'neutral';

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setTypeTag();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isVariation && this.configTagToVariation(changes);
  }

  ngAfterViewInit(): void {
    this.closeBtn && this.closeEvent();
  }

  closeEvent(): void {
    const tagBtn = this.ref.nativeElement.querySelector('#tagCloseBtn');
    if (tagBtn) {
      tagBtn.addEventListener('click', () => {
        this.ref.nativeElement.remove();
        this.flaClose.emit();
      });
    }
  }

  private configTagToVariation(changes: SimpleChanges): void {
    const { bgColor, color, variation, auctionInfos } = changes;
    !this.symbol && (this.symbol = '%');
    variation?.currentValue && this.animationTag();
    bgColor?.currentValue && this.setBackgroundColor();
    color?.currentValue && this.setColor();
    auctionInfos?.currentValue && this.cdr?.detectChanges();
  }

  private setBackgroundColor(): void {
    this.styleTag && this.setTypeTag();
    this.ref.nativeElement.style.setProperty(
      'background-color',
      this.bgColor,
      'important'
    );
  }

  private setColor(): void {
    this.ref.nativeElement.style.setProperty('color', this.color, 'important');
  }

  private animationTag(): void {
    this.ref.nativeElement.classList.add('opacity-medium');
    this.timeClear = setTimeout(() => {
      this.ref?.nativeElement.classList?.remove('opacity-medium');
      clearTimeout(this.timeClear);
    }, 250);
  }

  private setTypeTag(): void {
    this.styleTag = this.useClass[this.typeTag ?? ''];
  }

  ngOnDestroy(): void {
    this.variation && clearTimeout(this.timeClear);
  }
}
