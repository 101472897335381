import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ToolbarDrawComponent } from './toolbar-draw.component';
import { ToolbarDrawService } from './toolbar-draw.service';
import { RTColorPickerModule } from '@shared/components/color-picker/color-picker.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { ModalModelSaveComponent } from './modal-model-save/modal-model-save.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { ModalConfigDrawComponent } from './modal-config-draw/modal-config-draw.component';
import {
  FlaCheckModule,
  RocketButtonModule,
  RocketModalModule,
} from '@shared/rocket-components/components/index';
import { RocketModelSaveModule } from '@shared/rocket-components/model-save/model-save.module';
import { ConfigColorpickerModule } from './config-colorpicker/config-colorpicker.component.module';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';

@NgModule({
  declarations: [
    ToolbarDrawComponent,
    ModalModelSaveComponent,
    ModalConfigDrawComponent,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    FlaWorkSpaceModule,
    DragDropModule,
    TooltipsModule,
    RTDropdownModule,
    RTColorPickerModule,
    NgSelectModule,
    FlaCheckModule,
    RocketModelSaveModule,
    ConfigColorpickerModule,
    RocketButtonModule,
    RocketModalModule,
  ],
  exports: [
    ToolbarDrawComponent,
    ModalModelSaveComponent,
    ModalConfigDrawComponent,
  ],
  providers: [ToolbarDrawService],
})
export class ToolbarDrawModule {}
