import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListDocumentsCacheService } from '@shared/services/core/cache/list-documents-cache.service';
import { RestService } from '@shared/services/rest';
import { firstValueFrom, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService extends RestService {
  override _url: string = 'api/trademap/v1/documents';
  constructor(
    private listDocumentsCacheService: ListDocumentsCacheService,
    http: HttpClient
  ) {
    super(http);
  }

  public getLastDocuments(
    cdStock: string,
    idExchange: number,
    limit: number = 4,
    offset = 0
  ) {
    const key = `LAST_${cdStock}:${idExchange}`;
    let request$: any = this.listDocumentsCacheService.getValue(key);
    if (!request$) {
      request$ = this.get(
        `list?cd_stock=${cdStock}&id_exchange=${idExchange}&limit=${limit}&offset=${offset}`
      ).pipe(shareReplay(1));
      this.listDocumentsCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }

  public getDocuments(
    cdStock: string,
    idExchange: number,
    idLabel: number,
    limit: number = 4,
    offset = 0
  ) {
    const key = `DOCUMENTS_${cdStock}:${idExchange}`;
    let request$: any = this.listDocumentsCacheService.getValue(key);
    if (!request$) {
      request$ = this.get(
        `list?cd_stock=${cdStock}&id_exchange=${idExchange}&id_label=${idLabel}&limit=${limit}&offset=${offset}`
      ).pipe(shareReplay(1));
      this.listDocumentsCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }
}
