<div
  class="candle d-flex flex-column horizontal justify-content-end w-100 overflow-hidden"
  [ngClass]="{'px-2': !hideAlert}"
  [style]="{ height: containerHeight }"
>
  <div
    class="bg-neutral-medium border-neutral-medium border-thin over-dot over-max over-min position-relative w-100 remove-max-width"
    (click)="flaClink.emit()"
  >
    <div
      *ngIf="!hideAlert"
      class="alert-indicator position-absolute d-inline-flex align-items-center justify-content-center"
      [style]="{ left: alertPricePosition + '%' }"
    >
      <span 
        class="alert-label position-absolute fw-bold"
        [style]="{ left: alertLabelPosition + 'px' }"
      >{{ alertLabel }}</span>
      <fla-icon
        [name]="'notifications'"
        size="icon-size-micro"
        [style]="{ color: alertColor }"
      >
      </fla-icon>
    </div>
    <ng-container *ngIf="!hideArrow">
      <div
        class="price-indicator position-absolute bg-neutral-smoothest"
        [style]="{ left: avgPricePosition + '%' }"
        [tooltip]="priceTooltip"
      ></div>
      <div
        class="arrow-line position-absolute"
        [style]="{
          'background-color': arrowColor,
          left: arrowStartPosition + '%',
          width: arrowWidth + '%'
        }"
      >
        <div
          class="tooltip-vl-open position-absolute round"
          [style.background]="arrowColor"
          [ngClass]="{ invert: useInvertOpenVl }"
          [tooltip]="vlOpenTooltip"
        ></div>
      </div>
      <div
        class="arrow position-absolute d-inline-flex align-items-center justify-content-center"
        [style]="{ left: arrowPoint + '%' }"
      >
        <fla-icon
          [name]="arrowIcon"
          size="icon-size-sm"
          [style]="{ color: arrowColor }"
        >
        </fla-icon>
      </div>
    </ng-container>
  </div>
  <div
    class="align-items-center d-inline-flex justify-content-between pt-1 px-2 w-100"
    id="CANDLE_HORIZONTAL_PRICE_VALUES"
  >
    <span
      class="custom-font-size text-truncate"
      [tooltip]="minValueTooltip"
      [tooltipIsHtml]="true"
    >
      {{ minValueFormatted }}
    </span>
    <span
      class="custom-font-size text-truncate"
      [tooltip]="maxValueTooltip"
      [tooltipIsHtml]="true"
    >
      {{ maxValueFormatted }}
    </span>
  </div>
</div>
