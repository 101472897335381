import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IAccountSelect, ISearchStock } from '@core/interface';
import { IStrategy } from '@core/layout/header/strategy/interface/strategy.interface';
import { StrategyService } from '@core/layout/header/strategy/service/strategy.service';
import { ORDER_TYPES } from '@shared/components/stock-trade/constants/boleta.constants';
import { isIdBrokerSimulator } from '@shared/constants/simulator-league.constant';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { deepClone, randomId } from '@shared/rocket-components/utils';
import { OrsGatewayService } from '@shared/services/api/trademap/v1/ors-gateway.service';
import { MultibrokerService } from '@shared/services/core/multibroker';
import { TTradeIdeaEvents } from '@shared/tiger-chart/types/tiger-chart.types';
import { Subscription } from 'rxjs';
import { TradeIdeaHelperService } from '../trade-idea-helper.service';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import {
  getIncrementValue,
  isNullOrUndefined,
} from 'src/app/utils/utils.functions';
import { formatMsDateToHumanDate } from 'src/app/utils/utils-events-worker.functions';
import { TradeIdeaService } from '@shared/services/api/trade-idea/trade-idea.service';
import { ToastService } from '@shared/services';
import { InputMask } from '@core/layout/header/strategy/types/strategy.types';
import { STRATEGY_TYPE } from '@core/layout/header/strategy/enum/strategy.enum';
import { ModalAvisoComponent } from '@shared/components/modal-aviso/modal-aviso.component';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { SearchStockComponent } from '@shared/components/search-stock/search-stock.component';
import { RocketStreamRead } from '@shared/channel/rx-event';

@Component({
  selector: 'app-modal-trade-idea',
  templateUrl: './modal-trade-idea.component.html',
  styleUrls: ['./modal-trade-idea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalTradeIdeaComponent
  extends RocketModalRef
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('searchStock') searchStock!: SearchStockComponent;
  groupForm!: FormArray<any>;
  today = new Date();
  showDatePicker: boolean = false;
  form!: FormGroup;
  refComponent: string = randomId('modal-trade-idea-edit');
  stockSelected!: ISearchStock;
  isSimulator: boolean = false;
  orderTypes: any[] = deepClone(ORDER_TYPES).splice(0, 2);
  selectedTradeIdea!: TTradeIdeaEvents | undefined;
  strategies!: IStrategy[];
  btnLabel: string = 'Comprar';
  btnColor: string = 'bg-multibroker-buy';
  standardLot = 100;
  incrementValue = 100;
  selectedStrategy!: IStrategy;
  priceMask = 'separator.2';
  incrementPrice = 1;
  isEdit: boolean = true;
  isEditMyTradeIdea: boolean = false;
  showWarningEdit: boolean = false;
  private onAccountChange$!: Subscription;
  private _account!: IAccountSelect;
  public disableAddCondition: boolean = false;
  public inputMask: InputMask = '';
  public indexInvalidQuantity: number | null | undefined = null;
  selectedSide: string = 'BUY';
  initialValuePrice: number = 0;
  selectedDate!: Date;
  public quantityInvalid: boolean = false;
  private rocketModalService!: RocketModalService;
  private _eventsQuoteChannel$!: RocketStreamRead;
  private lastPrice: number = 0;
  quoteParams!: SubscribeParam;
  configs!: any;

  get groups(): FormArray {
    return this.form.get('items') as FormArray;
  }

  get itemsArraySelected() {
    return `${this.stockSelected?.cd_stock}:${this.stockSelected?.id_exchange}`;
  }

  constructor(
    @Inject(ROCKET_MODAL_DATA) private data: any,
    service: RocketModalService,
    private _multibrokerService: MultibrokerService,
    private orsGatewayService: OrsGatewayService,
    private _strategyService: StrategyService,
    private _elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private tradeIdeaHelperService: TradeIdeaHelperService,
    private tradeIdeaService: TradeIdeaService,
    private stockChartService: StockChartService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef,
    private _quoteChannel: QuoteChannel
  ) {
    super(service);
    this.rocketModalService = service;
  }

  ngOnInit() {
    this.configs = deepClone(this.data);
    this.stockSelected = this.configs.stock;
    this.selectedTradeIdea = this.configs.selectedTradeIdea;
    this.isEdit = this.configs.isEdit;
    this.isEditMyTradeIdea = this.configs.isEditMyTradeIdea;
    if (this.selectedTradeIdea) {
      this.standardLot = this.selectedTradeIdea.standard_lot;
      this.incrementValue = this.selectedTradeIdea.standard_lot;
    }
    this.priceMask =
      this.stockSelected && this.stockSelected.cd_stock
        ? `separator.${this.stockSelected.tick_size_denominator}`
        : 'separator.2';
    this.incrementPrice =
      this.stockSelected && this.stockSelected.cd_stock
        ? getIncrementValue(this.stockSelected)
        : 0.01;
    this.lastPrice =
      this.selectedTradeIdea?.price_entry ??
      this.stockSelected?.stockVariation?.vl_close ??
      0;
    this.initialValuePrice =
      this.selectedTradeIdea?.price_entry ??
      this.stockSelected?.stockVariation?.vl_close ??
      0;
    this.buildForm();
    this.startQuoteStreaming()
    this.quoteSubscription();
  }

  startQuoteStreaming = async () => {
    if(!this._eventsQuoteChannel$){
      this._eventsQuoteChannel$ = await this._quoteChannel.readEvents()
      this.readStream(this._eventsQuoteChannel$.stream, this.quoteHandler)
    }
  }

  ngAfterViewInit() {
    if (this.selectedTradeIdea) {
      const isBuy = this.selectedTradeIdea.side === 'B';
      this.btnColor = isBuy ? 'bg-multibroker-buy' : 'bg-multibroker-sell';
      this.btnLabel = isBuy ? 'Comprar' : 'Vender';
    }
    this.getAllStrategies();
    this.initSubscriptions();
    if (this.isEditMyTradeIdea) {
      this.searchStock.selectStockByCdStock(this.selectedTradeIdea!.cd_stock);
      this.selectedSide = this.selectedTradeIdea!.side === 'B' ? 'BUY' : 'SELL';
      this.selectedTradeIdea?.strategy.items.forEach((item) => {
        const info = this._strategyService.addStrategyItems(
          this.disableAddCondition,
          this.groups,
          this.formBuilder,
          this.inputMask,
          {
            id_item: item.id_item,
            id_strategy: item.id_strategy,
            quantity: item.quantity ?? item.quatity ?? 0,
            gain: item.gain,
            loss: item.loss,
            offset: item.offset,
          }
        );
        if (info) {
          this.form.get('items')?.setValue(info.groups.value);
          this.disableAddCondition = info.disableAddCondition;
          this.indexInvalidQuantity = info.indexInvalidQuantity;
          this.groupForm = info.groups;
          this.form.updateValueAndValidity();
        }
      });
    }
    this.showWarningEdit = false;
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.onAccountChange$ && this.onAccountChange$.unsubscribe();
    this._eventsQuoteChannel$ && this._eventsQuoteChannel$.close();
    this.quoteParams && this._quoteChannel.unsubscribe(this.quoteParams);
  }

  private quoteSubscription() {
    if (this.quoteParams) {
      this._quoteChannel.unsubscribe(this.quoteParams);
    }
    this.quoteParams = {
      itemsArray: [this.itemsArraySelected],
    };
    this._quoteChannel.subscribe(this.quoteParams);
    this._eventsQuoteChannel$ && this._eventsQuoteChannel$.snapshot(this.quoteParams.itemsArray);
  }

  private quoteHandler = (payload: Map<string, any>) => {
    payload.forEach((res: any, key: string) => {
      if(key !== this.itemsArraySelected) return;

      this.lastPrice = parseFloat(res.preco_ultimo);
      this.cdr.detectChanges();
    })
  };

  selectStock(stock: ISearchStock) {
    if (
      !this.isEdit &&
      stock.cd_stock_order != this.stockSelected?.cd_stock_order
    ) {
      this.stockSelected = stock;
      this.form.controls['price'].setValue(stock.stockVariation.vl_close);
      this.quoteSubscription();
      this.incrementPrice =
        this.stockSelected && this.stockSelected.cd_stock
          ? getIncrementValue(this.stockSelected)
          : 0.01;
      this.cdr.detectChanges();
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      qtty: [
        this.isEdit
          ? this.configs.qtty
          : this.selectedTradeIdea?.standard_lot ?? 0,
        this.isEdit ? [Validators.required] : [],
      ],
      price: [this.initialValuePrice, [Validators.required]],
      strategy: ['', this.isEdit ? [Validators.required] : []],
      type: [this.orderTypes[0].id, [Validators.required]],
      dateExpiration: [
        this.isEditMyTradeIdea ? this.selectedTradeIdea?.dt_event : '',
        !this.isEdit ? [Validators.required] : [],
      ],
      description: [
        this.isEditMyTradeIdea ? this.selectedTradeIdea?.description : '',
        !this.isEdit ? [Validators.required] : [],
      ],
      items: this.formBuilder.array([]),
      typeStrategy: [
        this.isEditMyTradeIdea
          ? this.selectedTradeIdea?.strategy.type_strategy
          : '',
        !this.isEdit ? [Validators.required] : [],
      ],
    });
    this.setDateExpirationValue(
      this.isEditMyTradeIdea
        ? new Date(this.selectedTradeIdea!.dh_maturity)
        : this.today
    );
    if (!this.isEdit && !this.isEditMyTradeIdea) {
      const info = this._strategyService.addStrategyItems(
        this.disableAddCondition,
        this.groups,
        this.formBuilder,
        this.inputMask
      );
      if (info) {
        this.form.get('items')?.setValue(info.groups.value);
        this.disableAddCondition = info.disableAddCondition;
        this.indexInvalidQuantity = info.indexInvalidQuantity;
        this.groupForm = info.groups;
        this.form.updateValueAndValidity();
      }
      this.cdr.detectChanges();
    }
    setTimeout(() => {
      this.form.valueChanges.subscribe(() => {
        this.showWarningEdit = false;
        if (this.isEditMyTradeIdea) {
          this.showWarningEdit = true;
        }
      });
    }, 300);
  }

  private setDateExpirationValue(value: Date) {
    value.setSeconds(0, 0);
    const auxDate = new Date(deepClone(value.getTime()));
    const ms = auxDate.setHours(0, 0, 0, 0);
    const label = `${this.tradeIdeaHelperService.getLabel(
      ms,
      formatMsDateToHumanDate(value.getTime(), false)
    )} às ${formatMsDateToHumanDate(value.getTime(), true, false)}`;
    this.form.get('dateExpiration')?.setValue(label);
    this.selectedDate = value;
    this.cdr.detectChanges();
  }

  private initSubscriptions() {
    this.onAccountChange$ = this._multibrokerService
      .onUpdateSelectedAccountChannel()
      .subscribe((account) => {
        this._account = account;
        this.isSimulator = isIdBrokerSimulator(account.id_broker);
        this.orderTypes = this.isSimulator
          ? deepClone(ORDER_TYPES).splice(0, 2)
          : deepClone(ORDER_TYPES).splice(0, 5);
        this.form.get('type')?.setValue(this.orderTypes[0].id);
        this.cdr.detectChanges();
      });
  }

  private getAllStrategies(strategyCreated?: IStrategy) {
    this.orsGatewayService.getAllStrategies().subscribe((data: IStrategy[]) => {
      this.strategies = data;
      if (this.strategies.length) {
        const idStrategy = strategyCreated
          ? strategyCreated.idStrategy
          : this.strategies[0].idStrategy;
        this.selectedStrategy = strategyCreated ?? this.strategies[0];
        this.form.get('strategy')?.setValue(idStrategy);
        this.formatTableStrategy();
      }
      this.cdr.detectChanges();
    });
  }

  private formatTableStrategy() {
    this.selectedStrategy.strategyItems =
      this.tradeIdeaHelperService.formatStrategyItemsTradeIdea(
        this.selectedStrategy.strategyType,
        this.selectedStrategy.strategyItems
      );
    this.cdr.detectChanges();
  }

  openNewStrategyModal() {
    this.openModalStrategy(true, false);
  }

  openEditStrategyModal() {
    this.openModalStrategy(false, true);
  }

  private openModalStrategy(newStrategy: boolean, onlyEdit: boolean) {
    const ref = this._strategyService.openStrategiesModal({
      toggleBetweenModal: this._elementRef,
      showAllStrategies: false,
      selectedStrategy: this.selectedStrategy,
      onlyEdit,
      newStrategy,
    });
    ref.afterDismissed.subscribe((data) => {
      if (data && data.isSave) {
        this.getAllStrategies(data.strategyCreated);
      }
    });
  }

  onClose(isCreated: boolean = false, warning: boolean = true) {
    if (
      !isCreated &&
      !this.isEdit &&
      warning &&
      ((this.isEditMyTradeIdea && this.form.valid && this.showWarningEdit) ||
        (!this.isEditMyTradeIdea && this.form.valid && !this.showWarningEdit))
    ) {
      this.openWarningCreate();
      return;
    }
    this.close({ isCreated }, this.configs.isSecondModal);
  }

  private openWarningCreate() {
    const ref = this.rocketModalService.open(ModalAvisoComponent, {
      data: {
        title: 'Tem certeza que deseja voltar?',
        text: `
        <p>Você ainda não salvou as alterações no seu novo TradeIdea, ao voltar, elas serão perdidas.</p>`,
        confirmeButton: 'Sim, descartar alterações',
        askAgain: false,
        showButtons: true,
        cancelButton: 'Não, continuar editando',
        isSecondModal: true,
        stock: undefined,
        showPriceStock: false,
      },
      toggleBetweenModal: this._elementRef,
    });
    ref.afterDismissed.subscribe((data) => {
      if (data.value) {
        this.onClose(false, false);
      }
    });
  }

  private updateTradeIdea(formValue: any) {
    this.tradeIdeaService
      .updateTradeIdea(
        this.selectedTradeIdea!.id_trade_idea,
        this.tradeIdeaHelperService.buildTradeIdea(
          formValue,
          this.stockSelected,
          this.selectedSide,
          this.selectedDate,
          this.selectedTradeIdea
        )
      )
      .subscribe((data) => {
        if (data.success) {
          this.toastService.showToast(
            'success',
            'TradeIdea editado com sucesso!'
          );
          this.onClose(true, false);
        } else {
          this.toastService.showToast(
            'error',
            'Erro ao tentar editar o TradeIdea. Tente novamente'
          );
        }
      });
  }

  executeTradeIdea() {
    if (!this.isEdit) {
      const formValue = this.form.getRawValue();
      formValue.items = this._strategyService.formatItemsToCreate(
        formValue.items,
        this.inputMask,
        this.isEditMyTradeIdea
      );
      if (this.isEditMyTradeIdea) {
        this.updateTradeIdea(formValue);
        return;
      }
      this.tradeIdeaService
        .createTradeIdea(
          this.tradeIdeaHelperService.buildTradeIdea(
            formValue,
            this.stockSelected,
            this.selectedSide,
            this.selectedDate
          )
        )
        .subscribe((data) => {
          if (data.success) {
            this.toastService.showToast(
              'success',
              'TradeIdea criado com sucesso!'
            );
            this.onClose(true, false);
          } else {
            this.toastService.showToast(
              'error',
              'Erro ao tentar criar o TradeIdea. Tente novamente'
            );
          }
        });
      return;
    }
    this.tradeIdeaHelperService.executeTradeIdea(
      {
        ...this.selectedTradeIdea!,
        price_entry: this.form.get('price')?.value,
        ord_type: this.form.get('type')?.value,
        strategy: {
          items: [],
          name_strategy: '',
          type_strategy: STRATEGY_TYPE.FINANCEIRO,
          id_strategy: this.form.get('strategy')?.value,
        },
      },
      this._account,
      this.form.get('qtty')?.value,
      this.stockChartService
    );
    this.onClose();
  }

  changeStrategy(strategy: IStrategy) {
    this.selectedStrategy = strategy;
    this.formatTableStrategy();
  }

  selectSide(side: string) {
    this.selectedSide = side;
    this.cdr.detectChanges();
  }

  datePicker() {
    this.showDatePicker = !this.showDatePicker;
    this.cdr.detectChanges();
  }

  datePickerDate(event: any) {
    if (!event.closeOutside) {
      this.setDateExpirationValue(event.date);
    }
    this.showDatePicker = false;
    this.cdr.detectChanges();
  }

  inputMaskChange(inputMask: InputMask) {
    this.inputMask = inputMask;
  }

  groupHandler(data: any) {
    this.form.get('items')?.setValue(data.groups.value);
    this.form.get('items')?.updateValueAndValidity();
    this.indexInvalidQuantity = data.indexInvalidQuantity;
    this.quantityInvalid = !isNullOrUndefined(this.indexInvalidQuantity);
    this.cdr.detectChanges();
  }

  updateLastPrice() {
    this.form.get('price')?.setValue(this.lastPrice);
  }
}
