import {
  DAY_TRADE_REPLACE_KEY,
  SWING_REPLACE_KEY,
} from '@core/layout/header/constants/daytrade.constant';
import { TOOLTIP_COLUMNS } from '@core/components/orders-history-core/grid/columns/tooltip-columns';

export const SUPER_DOM_TOOLTIPS = {
  RESET_DAYTRADE_ON: `Zerar posição ${DAY_TRADE_REPLACE_KEY}`,
  RESET_DAYTRADE_OFF: `Zerar posição ${SWING_REPLACE_KEY}`,
  INVERT_DAYTRADE_ON: `Inverter posição ${DAY_TRADE_REPLACE_KEY}`,
  INVERT_DAYTRADE_OFF: `Inverter posição ${SWING_REPLACE_KEY}`,
  CANCEL_ORDERS_ON: `Cancelar ordens ${DAY_TRADE_REPLACE_KEY}`,
  CANCEL_ORDERS_OFF: `Cancelar ordens ${SWING_REPLACE_KEY}`,
};

export const SUPER_DOM_POSITION_INFOS = [
  {
    label: 'P&L abr',
    field: 'pnl_open_swing',
    dayTradeField: 'pnl_open_daytrade',
    tooltip: `${TOOLTIP_COLUMNS.pnl_open.title}`,
  },
  {
    label: 'P&L dia',
    field: 'pnl_day_swing',
    dayTradeField: 'pnl_day_daytrade',
    tooltip: `${TOOLTIP_COLUMNS.pnl_day.title}`,
  },
  {
    label: 'P&L tot',
    field: 'pnl_swing',
    dayTradeField: 'pnl_daytrade',
    tooltip: `${TOOLTIP_COLUMNS.pnl.title}`,
  },
];

export interface VolumeMetrics {
  qtty_total: number;
  atpriceHov: number;
  atpriceBuy: number;
  atpriceSell: number;
  atpriceRlp: number;
  atpriceDirect: number;
}
