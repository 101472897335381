import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  NgZone,
  Renderer2,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  debounceTime,
  filter,
  map,
  skip,
  skipWhile,
  Subject,
  Subscription,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs';
import { HomeService } from './service/home.service';
import { StockChartModalStockComponent } from '@shared/components/stock-chart/parts/modal-stock/stock-chart-modal-stock.component';
import { LinkSubscription } from '@services/core/subscription/link.subscription';
import { SuperSearchService } from './super-search.service';
import { IContextMenuOptions } from '@shared/components/context-menu/context-menu.component';
import { COMPONENTS_NAMES_ENUM, WorkSpaceConfigs } from '@core/workspace';
import { IWorkSpace, IWorkSpaceComponet } from '@core/interface';
import { ModalAddComponentWorkspaceComponent } from './workspace-component/modal-add-component-workspace/modal-add-component-workspace.component';
import { RocketModalService } from '@shared/rocket-components/components/index';
import { WorkspacesPreferences } from '@shared/services/core/custom-preferences/workspace';
import { WorkspaceComponentService } from './service/workspace-component.service';
import { IYoutubeMiniPlayer } from './youtube-miniplayer/youtube-miniplayer.interface';
import { isWebViewContext } from 'src/app/desktop/integration.service';
import { AuthService } from '@shared/services';
import { DeviceValidatorService } from '@shared/services/device-validator.service';
import { Router } from '@angular/router';
import { InitializeWsService } from '@shared/services/core/workspace/initialize-workspace.service';
import { UserAuthenticated } from '@shared/services/api/authentication/v1/login-response.interface';
import { NotificationChannel } from '@shared/channel/notification-channel';
import {
  LogoutReason,
  LogoutService,
} from '@shared/services/api/authentication/v2/logout.service';
import {
  CLEANNER_LOGOUT_MESSAGE,
  LOGOUT_MESSAGE,
  LOGOUT_REASON,
} from '@shared/services/core/const/local-storage.const';
import { RxEvent } from '@shared/channel/rx-event';
import { NetworkService } from '@core/services/network.service';
import { TradeTokenPopupService } from '@core/layout/header/trade-token-popup/trade-token-popup.service';
import { TabControlService } from '@core/services/tab-control.service';
import { debug } from 'src/app/utils/utils.functions';
import { ConfigService } from '@core/config';
import { OrderTokenService } from '@shared/rocket-components/modal-order-token/order-token.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, OnDestroy {
  workSpaces!: IWorkSpace;
  componentNamesEnum = COMPONENTS_NAMES_ENUM;
  isLoading: boolean = true;
  refComponent: string = 'HOME';
  isDisplayContextMenu!: boolean;
  contextMenuOptions!: IContextMenuOptions;
  focusedComponent: string = '';
  showMiniplayer: boolean = false;
  videoYoutubeData!: IYoutubeMiniPlayer;

  private untilDestroy$ = new Subject<void>();
  private _onMiniplayerComponent$!: Subscription;
  private dispatchActiveWorkspace$!: Subscription;
  private activityEvents$ = new Subject<Event>();
  private worker!: Worker;
  private networkSubscription!: Subscription;
  private openedModalAdd: boolean = false;

  totalComponents = 0;
  lockWorkspace!: boolean;
  position!: { x: number; y: number } | undefined;
  warningMonitor: boolean = false;
  isDesktop = false;

  constructor(
    private _homeService: HomeService,
    private workspacesConfigs: WorkSpaceConfigs,
    private modal: RocketModalService,
    private renderer: Renderer2,
    private ngZone: NgZone,
    private _linkDoc: LinkSubscription,
    private _superSearch: SuperSearchService,
    private cdr: ChangeDetectorRef,
    private workspacePreferences: WorkspacesPreferences,
    private workspaceComponentService: WorkspaceComponentService,
    private authService: AuthService,
    private deviceValidator: DeviceValidatorService,
    private router: Router,
    private _workspace: InitializeWsService,
    private notification: NotificationChannel,
    private logoutService: LogoutService,
    private rxEvent: RxEvent,
    private networkService: NetworkService,
    private _tradeTokenPopupService: TradeTokenPopupService,
    private tabControlService: TabControlService,
    private config: ConfigService,
    private _orderTokenService: OrderTokenService
  ) {
    this.isDesktop = isWebViewContext();
    this.lockWorkspace = this.workspacePreferences.lockWorkspace;
  }
  tabId!: string;
  private subscription!: Subscription;
  ngOnInit(): void {
    this.subscription = this.tabControlService.isAnotherTabOpen
      .pipe(filter((data) => data))
      .subscribe(() => {
        debug(`O app foi aberto em outra aba`);
        sessionStorage.clear();
        location.reload();
        this.setLogoutMessage({
          reason: 'ALREADY_OPEN',
          systemMessage: 'O sistema foi aberto em outra aba.',
        });
      });
    this._startInactivityWorker();
    this.inicializarDados();
    this.observarMudancas();
    this.configurarListeners();
    this.logoutService
      .onLogout()
      .pipe(takeUntil(this.untilDestroy$))
      .subscribe(this.onLogoutHandler);
    this.authService
      .isAuth()
      .pipe(skipWhile((data) => !data))
      .subscribe(this.isAuthCallback);
  }

  monitorNetwork(): void {
    if (this.isDesktop) return;

    this.networkSubscription = this.networkService
      .isOnline$()
      .subscribe((isOnline) => {
        this.worker.postMessage({ type: 'network-status', isOnline });
      });
  }

  setLogoutMessage(message: LogoutReason) {
    localStorage.setItem(LOGOUT_REASON, message.reason!);
    localStorage.setItem(LOGOUT_MESSAGE, message.systemMessage!);
    localStorage.setItem(CLEANNER_LOGOUT_MESSAGE, message.systemMessage!);
  }

  onLogoutHandler = (message: LogoutReason) => {
    this.rxEvent.emit('LOGOUT', {});
    if (message.reason !== 'USER_COMMAND') {
      this.setLogoutMessage(message);
    }
    // this.cheetahChannel.close();
    this.imHere();
  };

  private _startInactivityWorker() {
    if (this.isDesktop) return;

    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(
        new URL('../../shared/worker/idle.worker', import.meta.url)
      );
      this.worker.onmessage = this.resultProcess;
      this.activityEvents$
        .pipe(
          filter(() => !this.warningMonitor),
          throttleTime(1000)
        )
        .subscribe(() => {
          this.worker.postMessage({ type: 'activity' });
        });
    } else {
      console.log('Web Workers are not supported in your environment.');
      alert('Atualize o navegador ou instale um mais moderno como o Chrome!');
    }
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:keydown', ['$event'])
  @HostListener('document:click', ['$event'])
  handleUserActivity(event: Event) {
    this.activityEvents$.next(event);
  }

  private logout() {
    this.rxEvent.disconnect('logout');
    this.logoutService.exec('INACTIVITY', 'inactivity');
    this.terminateWorker();
  }

  private showWarning() {
    if (this.warningMonitor) return;
    this.warningMonitor = true;
    this.cdr.detectChanges();
  }

  private resultProcess = ({ data }: any): void => {
    switch (data.type) {
      case 'warn':
        this.showWarning();
        break;
      case 'logout':
        this.logout();
        break;
      case 'alive':
        this.authService.alive();
        break;
    }
  };

  imHere = () => {
    this.warningMonitor = false;
    this.worker.postMessage({ type: 'activity' });
    this.cdr.detectChanges();
  };

  private terminateWorker() {
    this.worker && this.worker.terminate();
  }

  isAuthCallback = async (user: UserAuthenticated | any) => {
    if (user) {
      this.tabControlService.initialize();
      // Não precisa ser aqui
      if (this.deviceValidator.isMobile()) {
        this.router.navigate(['invalid-device']);
        return;
      }
      // tem que rever a necessidade disso e como testar
      if (user.investor.isSessionWeak) {
        if (this.router.url !== '/') this.router.navigateByUrl('/');
        if (!user.isLogin) this.authService.handlePartner();
        return;
      }
      // teoricamente nao vai mais precisar desse if
      if (!user.isLogin) {
        this._workspace.initialize(false);
      } else this._tradeTokenPopupService.validityTradeToken();
      this._orderTokenService.checkTrademapTokenPreference();
      this.init(user);
      this.initObserverActivity();
      this.monitorNetwork();
    }
  };

  initObserverActivity() {
    this.notification.instanciate();

    if (this.isDesktop) return;

    this.worker.postMessage({
      typeMessage: 'start',
      idleTime: this.config.getConfig()?.idleTime,
    });
  }

  init(user: UserAuthenticated) {
    if (user.isLogin && this.router.url !== '/') {
      this.router.navigateByUrl('/');
    }
  }

  ngOnDestroy(): void {
    this.untilDestroy$.next();
    this.untilDestroy$.complete();
    this._onMiniplayerComponent$ && this._onMiniplayerComponent$.unsubscribe();
    this.terminateWorker();
    this.networkSubscription.unsubscribe();
    this.dispatchActiveWorkspace$ &&
      this.dispatchActiveWorkspace$.unsubscribe();
    this.subscription.unsubscribe();
  }

  private inicializarDados(): void {
    this._getData();
    this.componentFlowObserver();
    this._updateResize();
  }

  private observarMudancas(): void {
    this.workspacePreferences
      .onLockWorkspaceChange()
      .pipe(
        filter((data) => data.key === 'LOCK_WORKSPACE'),
        map((data) => JSON.parse(data.value)),
        takeUntil(this.untilDestroy$)
      )
      .subscribe((data: any) => {
        this.lockWorkspace = data;
        this.cdr.detectChanges();
      });

    this.workspacesConfigs
      .asObservableRemoveComponent()
      .pipe(takeUntil(this.untilDestroy$))
      .subscribe((key: string | null) => {
        if (key) this._homeService.removeComp(key);
        this.cdr.detectChanges();
      });

    this._homeService
      .onOpenContextMenu()
      .pipe(takeUntil(this.untilDestroy$))
      .subscribe(() => {
        this.isDisplayContextMenu = false;
        this.cdr.detectChanges();
      });

    this._updateFocusedComponentSubject
      .pipe(
        filter((id) => this.focusedComponent !== id && !!id),
        tap(() => {
          this._blockOutsideClickEvent = true;
          this.cdr.detectChanges();
        }),
        debounceTime(20),
        takeUntil(this.untilDestroy$)
      )
      .subscribe((id) => {
        this.workSpaces.components?.forEach((item) => {
          item.metadata.layout.index = item.id !== id ? '0' : '1';
        });
        this.focusedComponent = id;
        this._homeService.selectedComponent = id;
        this._blockOutsideClickEvent = false;
        this.workspacesConfigs.updateIndex(this.workSpaces.components);
        this.cdr.detectChanges();
      });
  }

  private _updateFocusedComponentSubject = new Subject<string>();
  private _blockOutsideClickEvent = false;
  private keyDownListener!: any;

  startKeyDownListener(renderer: any) {
    this.ngZone.runOutsideAngular(() => {
      this.keyDownListener = renderer.listen(
        'window',
        'keydown',
        this._superSearch.onKeyDown
      );
    });
  }

  stopKeyDownListener() {
    if (this.keyDownListener) {
      this.keyDownListener();
      this.keyDownListener = null;
    }
  }

  private configurarListeners(): void {
    this._onMiniplayerComponent$ = this._homeService
      .onMiniplayerComponent()
      .subscribe((data) => {
        if (!data) {
          this.showMiniplayer = false;
        } else {
          this.showMiniplayer = true;
          this.videoYoutubeData = data;
        }
        this.cdr.detectChanges();
      });

    this.keyDownListener = this.startKeyDownListener(this.renderer);

    this._superSearch
      .onOpen()
      .pipe(takeUntil(this.untilDestroy$))
      .subscribe((data) => {
        this.stopKeyDownListener();
        this.openGeneralStockSelector(data);
      });
  }

  openAddComponentContextMenu = (event: MouseEvent) => {
    event.preventDefault();
    const target: any = event.target;
    if (target.id === 'workspace_base') {
      const position = {
        top: event.clientY,
        left: event.clientX,
      };
      this.contextMenuOptions = {
        type: 'TOOLS',
        title: 'Componentes',
        position,
      };
      this.isDisplayContextMenu = true;
    }
  };

  onCloseContextMenu = () => (this.isDisplayContextMenu = false);

  execContextMenu = (event: any) =>
    this._homeService.duplicateComponent(event.component);

  removeComponent = (key: string) => this._homeService.removeComp(key);

  updateDocMeta = (value: IWorkSpaceComponet) =>
    this._homeService.updateMeta(value);

  updateComponentFocused(id_component: any) {
    this._updateFocusedComponentSubject.next(id_component);
  }

  clickLink(data: { key: string; comp: IWorkSpaceComponet }) {
    this.updateDocMeta(data.comp);
    this._linkDoc.linked({
      id_component: data.comp.id,
      linked: data.comp.metadata.headerOptions.link!,
    });
    this.cdr.detectChanges();
  }

  clickMaximize() {
    this.cdr.detectChanges();
  }

  private componentFlowObserver() {
    this.workspaceComponentService
      .onComponentOpened()
      .pipe(takeUntil(this.untilDestroy$))
      .subscribe((id_component: string) => {
        this.workSpaces?.components &&
          this.updateComponentFocused(id_component);
      });

    this.workspaceComponentService
      .onComponentRemoved()
      .pipe(takeUntil(this.untilDestroy$))
      .subscribe();
  }

  private _handleWorkspaceChange = (workspace: IWorkSpace) => {
    if (this.workSpaces?.id && this.workSpaces.id !== workspace.id) {
      this.rxEvent.reset();
      this.totalComponents = 0;
    }
    workspace = this._homeService.updateComponentNewInfo(workspace);
    if (workspace.components?.length) {
      this._updateComponentStockOrder(workspace.components);
    }

    this.workSpaces = workspace;
    this.isLoading = false;
    this.cdr.detectChanges();
    this.workspacesConfigs.updateComponentsInWS();

    if (!this.workSpaces?.components?.length && !this.openedModalAdd)
      this.openModalAddComponent(workspace.id);
  };

  private _updateComponentStockOrder(components: IWorkSpaceComponet[]) {
    components.forEach((component) => {
      if (
        component.metadata?.component?.stock &&
        !component.metadata?.component?.stock?.cd_stock_order
      ) {
        component.metadata.component.stock['cd_stock_order'] =
          component.metadata.component.stock.cd_stock;
      }
    });
  }

  private _getData() {
    this.dispatchActiveWorkspace$ = this.workspaceComponentService
      .dispatchActiveWorkspace()
      .subscribe(this._handleWorkspaceChange);
  }

  private _updateResize() {
    let index: number;
    this.workspacesConfigs.sizeChanges
      .pipe(
        skip(1),
        takeUntil(this.untilDestroy$),
        tap((data) => {
          const res = data.element;
          index = this.workSpaces.components.findIndex(
            (item) => item.id === (res.childNodes[1] as HTMLElement).id
          );
          if (index > -1) {
            const metadata = this.workSpaces.components[index].metadata;
            const { width, height, top, bottom, right, left, transform } =
              this._homeService.getSizes(data.isMaximized, metadata, res.style);
            metadata.layout = {
              top,
              bottom,
              right,
              left,
              transform,
              width,
              minWidth: res.style.minWidth,
              minHeight: res.style.minHeight,
              height,
              index: res.style.zIndex,
              isMaximized: data.isMaximized,
            };
            metadata.componentResize = {
              width: res.getBoundingClientRect().width,
              height: res.getBoundingClientRect().height,
            };
            this.workSpaces.components[index].metadata = metadata;
          }
        }),
        filter(() => index > -1),
        debounceTime(500)
      )
      .subscribe(() => {
        this.updateDocMeta(this.workSpaces.components[index]);
        this.cdr.detectChanges();
      });
  }

  openGeneralStockSelector(param: any) {
    const data = {
      word: param.words,
      loadWord: param.loadWord,
      ref: this.refComponent,
      searchString: null,
    };

    this.modal.open<StockChartModalStockComponent>(
      StockChartModalStockComponent,
      {
        data,
        keyboard: true,
        backdrop: true,
        scrollable: false,
        lockScreen: false,
      }
    );
    this._superSearch.listenToModalDismiss();
    this.cdr.detectChanges();
  }

  @HostListener('window:keydown.shift.T', ['$event'])
  openRocketTerminal() {
    console.warn('vamos abrir o terminal ');
  }

  openModalAddComponent(idWorkspace: any) {
    const data = { ref: this.refComponent, idWorkspace };
    this.openedModalAdd = true;
    const refStock = this.modal.open(ModalAddComponentWorkspaceComponent, {
      data,
      keyboard: true,
      backdrop: true,
      scrollable: false,
    });
    refStock.afterDismissed
      .pipe(
        tap(() => {
          this.openedModalAdd = false;
        }),
        filter((value) => !(typeof value === 'object' && 'closed' in value))
      )
      .subscribe(() => {
        this.cdr.detectChanges();
        // this.globalStock.changeGlobalStock(stock);
      });
  }

  @HostListener('window:beforeunload')
  closeTabFather() {
    debug(`window:beforeunload`);
    this.cdr.detectChanges();
  }

  onMoveComponent(value: IWorkSpaceComponet) {
    this.updateDocMeta(value);
    this.position = value.metadata.layout.transform;
  }

  clickedOutSide(): void {
    if (!this._blockOutsideClickEvent && this.focusedComponent) {
      this.focusedComponent = '';
      this.cdr.detectChanges();
    }
  }
}

export const debounceExec = (timout: number = 50) =>
  new Promise((resolve) => setTimeout(() => resolve(true), timout));
