import { Injectable } from '@angular/core';
import { ConfigService } from '@core/config';
import {
  NEGOTIATION_PERMISSIONS,
  TABS,
} from '@core/layout/header/constants/header.contanst';
import {
  IConfigTabs,
  INegotiationPermissions,
} from '@core/layout/header/interfaces/header.interfaces';
import { ORDER_CONFIRM_ACTIONS_ENUM } from '@shared/enum/buyOrSell.enum';

@Injectable({
  providedIn: 'root',
})
export class ModalOrdensService {
  constructor(private configService: ConfigService) {}

  getNegotiationPermissions() {
    const negotiationsPermissions = NEGOTIATION_PERMISSIONS;
    if (!this.configService.isBroker()) {
      return negotiationsPermissions;
    }
    const hashPermissions: any = {
      [ORDER_CONFIRM_ACTIONS_ENUM.USE_SPLIT]: true,
    };
    const permissions: INegotiationPermissions[] = [];
    negotiationsPermissions.forEach((permission) => {
      if (hashPermissions[permission.enum]) {
        permissions.push(permission);
      }
    });
    return permissions;
  }

  getConfigTabs() {
    const configTabs = TABS;
    if (!this.configService.isBroker()) {
      return configTabs;
    }
    const hashTabs: any = {
      NEGOTIATION: true,
      CONFIRMATIONS: true,
    };
    const tabs: IConfigTabs[] = [];
    configTabs.forEach((tab) => {
      if (hashTabs[tab.ref]) {
        tabs.push(tab);
      }
    });
    return tabs;
  }
}
