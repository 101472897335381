import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RtImageFallbackDirective } from './image-fallback.directive';

@NgModule({
  declarations: [RtImageFallbackDirective],
  imports: [CommonModule],
  exports: [RtImageFallbackDirective],
  providers: [],
})
export class RtImageFallbackModule {}
