import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Inject,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  FlaInputModule,
  IInternationalNumber,
  PhoneInputModule,
  ROCKET_MODAL_DATA,
  RocketButtonModule,
  RocketModalModule,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { StandaloneModalsService } from '../standalone-modals.service';
import { TimerResendComponent } from '@modules/auth/register/validate/timer-resend/timer-resend.component';
import { UserService } from '@shared/services/api/trademap/v1/user-service/user.service';
import { OtpService } from '@shared/services/api/authentication/v3/otp.service';
import {
  TOKEN_AUTHENTICATORS,
  TTokenPreferenceAuthenticator,
} from '@core/layout/header/modal-token-config/types';
import { delay } from 'rxjs';
import { TotpService } from '@shared/services/core/totp/totp.service';
import { system } from '@core/system/system.service';
import { ModalTokenConfigComponent } from '@core/layout/header/modal-token-config/modal-token-config.component';
import { ModalTokenService } from '@core/layout/header/modal-token-config/modal-token.service';

@Component({
  selector: 'app-modal-register-phone',
  templateUrl: './modal-register-phone.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RocketModalModule,
    RocketButtonModule,
    FormsModule,
    ReactiveFormsModule,
    PhoneInputModule,
    FlaInputModule,
    TimerResendComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalRegisterPhoneComponent
  extends RocketModalRef
  implements OnInit
{
  public form!: FormGroup;
  public step: 1 | 2 | 3 = 1;
  public displaySecurityText = false;
  public isLoading = false;
  public canResendPincode = false;
  public resendPincodeTimeout = 30;
  public errorMessage = '';

  public readonly stepsText = {
    1: `
    <span>
      Identificamos que a sua conta está sem telefone cadastrado. Para ativar seu token é preciso ter um telefone.
    </span>
    <span>
      Por favor, insira um telefone no campo abaixo e clique em <b>Continuar</b>
    </span>
    `,
    2: `
    <span>
      Para seguir com o processo de ativação do seu telefone é necessário validar o seu telefone, confirme o código PIN recebido via SMS e clique em <b>Continuar</b>
    </span>
    `,
    3: `
      <span>
        Para concluir o processo de ativação do seu telefone, insira o código de autenticação recebido por e-mail e clique em <b>Continuar</b>
      </span>
    `,
    4: '',
  };

  get phone() {
    return this.form.get('phone')!;
  }

  get phoneCountryCode() {
    return this.form.get('phone_country_code')!;
  }

  get password() {
    return this.form.get('password')!;
  }

  get phoneNumber() {
    return `${this.phoneCountryCode.value.code}${this.phone.value}`.replace(
      /\D/g,
      ''
    );
  }

  get disableSubmitButton() {
    if (this.step === 1) return this.password.invalid || this.phone.invalid;
    if (this.step === 2) return this.form.get('phone_pincode')!.invalid;
    if (this.step === 3) return this.form.get('email_pincode')!.invalid;
    return false;
  }

  private _cdr = inject(ChangeDetectorRef);
  private _standaloneModalsService = inject(StandaloneModalsService);
  private _userService = inject(UserService);
  private _formBuilder = inject(FormBuilder);
  private _otpService = inject(OtpService);
  private _totpService = inject(TotpService);
  private _rocketModalService = inject(RocketModalService);
  private _modalTokenService = inject(ModalTokenService);

  constructor(
    @Inject(ROCKET_MODAL_DATA)
    private data: { authMethod: TTokenPreferenceAuthenticator },
    service: RocketModalService
  ) {
    super(service);
    this._initializeForm();
  }

  private _initializeForm = (): void => {
    this.form = this._formBuilder.group({
      phone: ['', [Validators.required]],
      phone_country_code: [{ country: 'br', code: '55' }, Validators.required],
      password: ['', [Validators.required, Validators.maxLength(100)]],
      phone_pincode: ['', [Validators.required, Validators.maxLength(6)]],
      email_pincode: ['', [Validators.required, Validators.maxLength(8)]],
    });
  };

  ngOnInit(): void {
    if (!this.data.authMethod || this.data.authMethod === 'UNDEFINED_TOKEN') {
      this.data.authMethod = 'NITRO';
    }
  }

  public onUpdateCountryCode(value: IInternationalNumber): void {
    this.phoneCountryCode.patchValue(value);
  }

  public backward(): void {
    if (this.step === 1) {
      this._standaloneModalsService.close();
      return;
    }
    this.form.get('phone_pincode')?.setValue('');
    this.form.get('email_pincode')?.setValue('');
    this.step = 1;
    this.displaySecurityText = false;
    this._cdr.detectChanges();
  }

  private _forward(): void {
    this.step++;
    this.isLoading = false;
    this.displaySecurityText = false;
    this._cdr.detectChanges();
  }

  private _beforeCallService(): void {
    this.displaySecurityText = false;
    this.isLoading = true;
    this.errorMessage = '';
    this._cdr.detectChanges();
  }

  private _setResendPincode(time: number = 30): void {
    this.canResendPincode = false;
    this.resendPincodeTimeout = time;
    this._cdr.detectChanges();
  }

  public submit(): void {
    if (this.isLoading) return;
    this._beforeCallService();
    if (this.step === 1) {
      this._confirmPhone();
      return;
    }

    if (this.step === 2) {
      this._validPincode();
      return;
    }

    if (this.step === 3) {
      this._generateInvestorSeed();
      return;
    }

    this._standaloneModalsService.close();
  }

  public resendPincode(): void {
    if (!this.canResendPincode) return;

    if (this.step === 2) {
      this._confirmPhone(true);
      return;
    }

    if (this.step === 3) {
      this._createInvestorSeed();
      return;
    }
  }

  private _confirmPhone(isResend: boolean = false): void {
    this._userService
      .updateInfoRequest({
        field: 'phone_mobile',
        value: this.phoneNumber,
        password: btoa(this.password.value),
      })
      .subscribe({
        next: (result) => {
          this._setResendPincode(result.next_interaction);
          if (isResend) return;
          this._forward();
        },
        error: (error) => {
          this._onError(
            error.error ??
              'Ocorreu um erro ao enviar o código de verificação do telefone. Por favor tente novamente'
          );
        },
      });
  }

  private _validPincode(): void {
    this._userService
      .checkPhoneUpdatePinCode({
        field: 'phone_mobile',
        pincode: this.form.get('phone_pincode')?.value,
        value: this.phoneNumber,
      })
      .subscribe({
        next: (result) => {
          if (result.isError) {
            this._onError(result.message);
            this.form.get('phone_pincode')?.setErrors({ required: true });
            return;
          }
          this._forward();
          this._createInvestorSeed();
        },
        error: (error) => {
          this._onError(
            error.message ??
              'Ocorreu um erro ao validar o código. Por favor tente novamente'
          );
        },
      });
  }

  private _createInvestorSeed(): void {
    this._otpService
      .createInvestorSeed(
        btoa(this.password.value),
        'email',
        null,
        this.data.authMethod
      )
      .subscribe({
        next: (result) => {
          this._setResendPincode(result.next_interaction);
        },
        error: (error) => {
          if (error.next_interaction)
            this._setResendPincode(error.next_interaction);
          this._onError(
            error.message ??
              'Ocorreu um erro ao gerar o código de verificação do e-mail. Por favor tente novamente'
          );
        },
      });
  }

  private async _generateInvestorSeed(): Promise<void> {
    const secret = this.form.get('email_pincode')?.value;
    const token = await this._totpService.generateTotp(secret);
    if (token) {
      this._activateInvestorSeed(token);
      return;
    }
    this._onError(
      'Ocorreu um erro ao gerar o token, por favor tente novamente'
    );
  }

  private _activateInvestorSeed(code: string): void {
    this._otpService
      .activateInvestorSeed(code, this.data.authMethod)
      .pipe(delay(250))
      .subscribe({
        next: () => this._updatePhoneAndAuthMethod(code),
        error: (error) => {
          this._onError(
            error.message ??
              'Ocorreu um erro ao validar o código de verificação do e-mail. Por favor tente novamente'
          );
        },
      });
  }

  private _updatePhoneAndAuthMethod(code: string): void {
    this._userService
      .updateFieldByToken({
        field: 'phone_mobile',
        value: this.phoneNumber,
        password: btoa(this.password.value),
        token: code,
        cdOrigin: this.data.authMethod,
      })
      .subscribe({
        next: (result) => {
          if (result.isError) {
            this._onError(result.message);
            return;
          }
          const userInfos = system.userAuthenticated;
          userInfos.investor.phone_mobile = this.phoneNumber;
          system.userAuthenticated = structuredClone(userInfos);
          this.isLoading = false;
          this._configureAuthAfterAddPhone();
        },
        error: (error) => {
          this._onError(
            error.message ??
              'Ocorreu um erro ao atualizar as informações da conta. Por favor tente novamente'
          );
        },
      });
  }

  private _onError(message: string): void {
    this.isLoading = false;
    this.errorMessage = message;
    this._cdr.detectChanges();
  }

  private _configureAuthAfterAddPhone(): void {
    this._standaloneModalsService.close();
    const auth = TOKEN_AUTHENTICATORS.find(
      (item) => item.id === this.data.authMethod
    );
    this._modalTokenService.alreadySelectedAuthenticator = {
      authenticator: auth!,
      password: btoa(this.password.value),
    };
    this._rocketModalService.open(ModalTokenConfigComponent, {
      size: 'lg',
      centered: true,
      backdrop: true,
      keyboard: true,
      scrollable: false,
    });
  }
}
