export const averageTrueRangeTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  A Média de Amplitude de Variação (ATR) é um indicador técnico que ajuda a
  medir a volatilidade do mercado financeiro. Ela é calculada como uma média
  móvel simples do intervalo verdadeiro (TR) do ativo financeiro, sendo que o TR
  é a maior diferença entre qualquer um dos seguintes preços: o valor máximo
  atual e o valor mínimo atual, o valor máximo anterior e o valor mínimo atual,
  ou o valor máximo atual e o valor mínimo anterior. O ATR é geralmente usado
  para auxiliar na determinação de níveis de stop loss e metas de lucro para
  operações financeiras.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">
  O Média de Amplitude de Variação (ATR) é um indicador desenvolvido por J.
  Welles Wilder Jr.<br />
  O ATR calcula a volatilidade média de um ativo financeiro em um determinado
  período. Para calcular o ATR, siga os seguintes passos:
</div>
<div class="mb-3">1. Calcule o True Range (TR) para cada período.</div>
<div class="mb-3">O True Range é a maior diferença entre:</div>
<div class="mb-3">
  &bull; O preço máximo e mínimo do período atual; <br />
  &bull; A diferença entre o preço máximo do período anterior e o preço mínimo
  do período atual; <br />
  &bull; A diferença entre o preço mínimo do período anterior e o preço máximo
  do período atual.
</div>
<div class="mb-3">
  2. Calcule a média dos True Ranges para o período desejado.
</div>
<div class="mb-3">3. O valor resultante é o ATR.</div>
<div class="mb-3">
  O ATR pode ser usado para determinar o tamanho do stop-loss e o tamanho da
  posição a ser tomada em um determinado ativo financeiro.
</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  Uma maneira comum de usar o ATR é para determinar o tamanho do stop-loss e o
  tamanho da posição a ser tomada em um determinado ativo financeiro. Por
  exemplo, se o ATR de um ativo financeiro for R$2, então um stop-loss de R$2 ou
  mais pode ser usado para limitar as perdas. Além disso, o tamanho da posição
  pode ser determinado com base no ATR. Por exemplo, se um trader decidir
  arriscar 1% do capital em uma negociação e o ATR for R$2, então ele pode
  comprar R$2000 do ativo financeiro.
</div>
<div class="mb-3">
  É importante lembrar que o ATR é baseado na volatilidade passada do ativo
  financeiro e pode não prever a volatilidade futura. Portanto, é importante
  usar outras ferramentas de análise técnica e fundamental para tomar decisões
  de negociação informadas.
</div>

<div class="mb-3">
  <img class="w-75" src="/assets/indicators/atr-como-funciona.png" />
</div>
<div class="fw-bolder">Como configurar</div>
<div class="fw-bolder">Valores</div>
<div class="text-decoration-underline">Período</div>
<div class="mb-3">
  O período a ser usado no cálculo da ATR. 14 dias é o padrão.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/atr-entrada.png"
  />
</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">ATR</div>
<div class="mb-3">
  Pode alternar a visibilidade do ATR. Também pode selecionar a cor, a espessura
  e o estilo da linha do ATR.
</div>
<div class="mb-3">
  <img class="image-indicators-input" src="/assets/indicators/atr-estilo.png" />
</div>
`;
