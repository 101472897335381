<div class="w-100 mb-1 fs-6 d-flex justify-content-between" *ngIf="news">
  <div>
    <span class="mr-1 agency">
      {{ news.ds_channel ? news.ds_channel : news.agencia }}
    </span>
    <span>
      {{ getData() }}
    </span>
  </div>
  <div
    *ngIf="news.copy_news"
    class="mr-2 d-flex"
    [tooltip]="getTooltip()"
    tooltipPosition="bottom"
  >
    <i class="icons-outlined text-feedback-warning icon-size-micro mr-1"
      >report_problem</i
    >
    <div>
      <span class="text-brand-primary">
        {{ news.copy_news.ds_news_agency_nickname }}
      </span>
      <span>
        há
        {{
          news.copy_news.dt_news_formatted || news.copy_news.dh_news_formatted
        }}
      </span>
    </div>
  </div>
</div>
