<rocket-modal
  [ngClass]="{
    'token-config': !disconnectingName
  }"
>
  <rocket-modal-header>
    <div>
      <h3 class="modal-title">
        {{ title }}
      </h3>
    </div>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <div class="mb-6" *ngIf="!loading; else loadingRef">
      <ng-container *ngIf="!selectedAuthenticator">
        <app-token-authenticators-list
          [tokenPreference]="tokenPreference"
          (changeAutenticator)="selectAuthenticator($event)"
          (reloadConfig)="getTokenPreference()"
          (disconnecting)="onDisconnecting($event)"
        ></app-token-authenticators-list>
      </ng-container>
      <ng-container *ngIf="selectedAuthenticator">
        <app-token-authenticator-instructions
          [authenticator]="selectedAuthenticator"
          (return)="selectAuthenticator(null)"
          (reloadConfig)="getTokenPreference()"
        ></app-token-authenticator-instructions>
      </ng-container>
    </div>
    <ng-template #loadingRef>
      <div class="h-100">
        <app-loading></app-loading>
      </div>
    </ng-template>
  </app-rocket-modal-body>
</rocket-modal>
