export const TOAST_ICON_COLOR = {
  info: {
    color: 'text-neutral-stronger',
    icon: 'info',
  },
  error: {
    color: 'text-feedback-error',
    icon: 'error',
  },
  warning: {
    color: 'text-feedback-warning',
    icon: 'warning',
  },
  success: {
    color: 'text-feedback-success',
    icon: 'check_circle',
  },
};
