import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { WorkspacesPreferences } from '@shared/services/core/custom-preferences/workspace';
import { filter, map } from 'rxjs';
import { TOOLTIPS } from '../constants/header.contanst';

@Component({
  selector: 'lock-workspace',
  templateUrl: './lock-workspace.component.html',
  styleUrls: ['./lock-workspace.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LockWorkspaceComponent implements OnInit {
  lockTooltip = TOOLTIPS.LOCK;
  lockWorkspace!: boolean;
  constructor(
    private workspace: WorkspacesPreferences,
    private cdr: ChangeDetectorRef
  ) {
    this.lockWorkspace = this.workspace.lockWorkspace;
  }

  ngOnInit() {
    this.workspace
      .onLockWorkspaceChange()
      .pipe(
        filter((data) => data.key === 'LOCK_WORKSPACE'),
        map((data) => JSON.parse(data.value))
      )
      .subscribe((data: any) => {
        this.lockWorkspace = data;
        this.cdr.detectChanges();
      });
  }

  handleMoveWorkspaceContent() {
    this.workspace.setLockWorkspace(!this.lockWorkspace);
    this.cdr.detectChanges();
  }
}
