import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StockTradeModule } from './components/stock-trade/stock-trade.module';
import { StockChartModule } from './components/stock-chart/stock-chart.module';
import { StockTableModule } from './components/stock-table/stock-table.module';
import { TableBookModule } from './components/table-book/table-book.module';
import { TimesTradesModule } from './components/times-trades/times-trades.module';
import { TigerChartModule } from '@shared/tiger-chart/tiger-chart.module';
import { StockScreeningModule } from './components/screening/stock-screening/stock-screening.module';
import { HeatmapModule } from './components/heatmap/heatmap.module';
import { PerformanceReportModule } from './components/performance-report/performance-report.module';
import { RocketGridModule } from '@shared/rocket-grid';
import { NewsModule } from './components/news/news.module';
import { AtPriceModule } from './components/at-price/at-price.module';
import { OptionsModule } from './components/options/options.module';
import { RankingBrokerModule } from './components/ranking-broker/ranking-broker.module';
import { RentModule } from './components/rent/rent.module';
import { FlowInvestorsModule } from './components/flow-investors/flow-investors.module';
import { FakeTooltipScichartModule } from './components/fake-tooltip-scichart/fake-tooltip-scichart.module';
import { BusinessProfileModule } from './components/business-profile/business-profile.module';
import { GraphicScreeningModule } from './components/screening/graphic-screening/graphic-screening.module';
import { StockAnalysisModule } from './components/stock-analysis/stock-analysis.module';
import { MarketSummaryModule } from './components/market-summary/market-summary.module';
import { ExportDataModule } from './components/export-data/export-data.module';
import { BrokerConnectionModule } from '@modules/broker-connection/broker-connection.module';
import { TradeIdeaModule } from './components/trade-idea/trade-idea.module';

const ROCKET_MODULES = [
  TigerChartModule,
  StockChartModule,
  StockTableModule,
  StockTradeModule,
  TableBookModule,
  // OrdersHistoryModule,
  TimesTradesModule,
  StockScreeningModule,
  BusinessProfileModule,
  HeatmapModule,
  StockAnalysisModule,
  PerformanceReportModule,
  NewsModule,
  RocketGridModule,
  AtPriceModule,
  OptionsModule,
  RankingBrokerModule,
  RentModule,
  FlowInvestorsModule,
  FakeTooltipScichartModule,
  GraphicScreeningModule,
  MarketSummaryModule,
  ExportDataModule,
  BrokerConnectionModule,
  TradeIdeaModule,
];

@NgModule({
  exports: [...ROCKET_MODULES],
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...ROCKET_MODULES,
    TigerChartModule.forRoot(),
  ],
})
export class SharedModule {}
