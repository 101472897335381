import { Subject, Observable } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Renderer2,
  ElementRef,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { RT_COLOR_EVENT } from './types';

@Component({
  selector: 'app-rt-color-picker',
  templateUrl: './color-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: contents;
      }
      .color-picker-btn {
        color: var(--vm-brand-primary);
      }
      .ok-btn {
        margin: 0;
      }
      .inputWrap {
        color: var(--vm-neutral-smooth);
        align-items: center;
        display: flex;
        position: relative;
        .inputHash {
          pointer-events: none;
          position: absolute;
          text-align: right;
          top: 1px;
          width: 21px;
        }
        .input {
          border: 1px solid var(--vm-neutral-medium);
          background-color: initial;
          border-radius: 4px;
          box-sizing: border-box;
          display: flex;
          height: 26px;
          line-height: 24px;
          margin-left: 8px;
          padding: 0 5px 0 12px;
          width: 68px;
          color: var(--vm-neutral-smooth);
          font-size: 12px;
        }
      }
    `,
  ],
})
export class RTColorPickerSelectorComponent implements OnInit, OnDestroy {
  @Input() closeClickOutside: boolean = true;
  @Input() closeOnClick: boolean = true;
  @Input() color!: string;
  @Input() hue!: string;
  @Input() defaultColor: string = '#FFFFFF';
  @Input() type: 'Hex' | 'RGB' = 'RGB';
  @Input() hideTypeButton: boolean = false;
  @Input() showColorPicker: boolean = false;
  @Input() isEditable: boolean = false;
  @Input() width: number = 180;
  @Input() height: number = 180;
  @Output() updateColor = new EventEmitter<string>();

  btnName: string = 'Hex';
  element!: HTMLElement;
  private _values!: RT_COLOR_EVENT;
  private _onClose$ = new Subject<
    { closed: boolean; value?: RT_COLOR_EVENT } | undefined
  >();

  get onClose(): Observable<
    { closed: boolean; value?: RT_COLOR_EVENT } | undefined
  > {
    return this._onClose$.asObservable();
  }

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (!this.hue) this.hue = this.color;
    if (this.closeClickOutside) {
      this._clickOutside();
    }
  }

  ngOnDestroy(): void {
    this._onClose$.complete();
  }

  toggle() {
    this.showColorPicker = !this.showColorPicker;
    if (!this.showColorPicker) {
      this._onClose$.next({ closed: true, value: this._values });
    }
  }

  setColor(color: string) {
    this.color = color;
  }

  onChangesColor(value: RT_COLOR_EVENT) {
    this._values = value;
    this.color = this.type === 'RGB' ? value.rgb : value.hex;
    this.updateColor.emit(this.color);
  }

  onChangesHue(value: RT_COLOR_EVENT) {
    this.hue = this.type === 'RGB' ? value.rgb : value.hex;
    this._values = value;
    this.color = this.hue;
    this.updateColor.emit(this.hue);
  }

  toggleType() {
    if (this.type === 'Hex') {
      this.color = this._values.rgb;
      this.type = 'RGB';
      this.btnName = 'Hex';
    } else {
      this.color = this._values.hex;
      this.type = 'Hex';
      this.btnName = 'RGB';
    }
  }

  private _clickOutside(): void {
    if (this.closeClickOutside) {
      this._renderer.listen('window', 'click', (event: any) => {
        if (!this.element) return;
        if (!event.path) return;
        if (
          !this.element.contains(event.target) &&
          !event.path.includes(this._elementRef.nativeElement)
        ) {
          if (this.showColorPicker) {
            this.showColorPicker = false;
            this._onClose$.next({ closed: true, value: this._values });
          }
        }
      });
    }
  }

  public updateColorByHex(event: any): void {
    if (this.type !== 'Hex') return;
    const color = `#${event.target.value}`;
    if (color === this.color) return;
    this.hue = color;
    this.color = color;
    this.updateColor.emit(color);
  }

  public verifyIsValidColor(event: any): void {
    if (this.type !== 'Hex') return;
    const color = `#${event.target.value}`;
    if (/^#[0-9A-F]{6}$/i.test(color)) return;
    this.hue = this.defaultColor;
    this.color = this.defaultColor;
    this.updateColor.emit(this.defaultColor);
  }

  public closeColorPicker() {
    this.showColorPicker = false;
    this.cdr.detectChanges();
  }
}
