import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class RTWaitForElementService {
  wait(element: string): Observable<boolean> {
    return new Observable((subscriber) => {
      const interval = setInterval(() => {
        if (document.getElementById(element)) {
          clearInterval(interval);
          subscriber.next(true);
          subscriber.complete();
        }
      }, 10);
    });
  }
}
