import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlaRadiosComponent } from './radios.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlaRaioButtonComponent } from './radios-parts/radio-button.component';
import { FlaRadioService } from './service/radio.service';

@NgModule({
  declarations: [FlaRadiosComponent, FlaRaioButtonComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [FlaRadiosComponent, FlaRaioButtonComponent],
  providers: [FlaRadioService],
})
export class FlaRadiosModule {}
