<ng-container *ngIf="displayListOnly; else defaultTemplate">
  <ng-template
    *ngTemplateOutlet="list; context: { options: options }"
  ></ng-template>
</ng-container>

<ng-template #defaultTemplate>
  <div class="dropdown d-flex">
    <a
      [id]="ref"
      class="align-items-center bg-neutral-stronger btn d-inline-flex dropdown-toggle h-100 hide-arrow justify-content-start p-0 px-1 w-100"
      style="min-width: 100% !important"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <div class="d-inline-flex align-items-center">
        <span
          class="fs-6 fw-bold"
          [ngClass]="{
            'text-brand-primary':
              currentValue[valueField] !== defaultValue[valueField],
            'text-light': currentValue[valueField] === defaultValue[valueField]
          }"
        >
          {{ currentValue[currentValueLabel] }}
        </span>
        <fla-icon
          name="expand_more"
          size="icon-size-micro"
          css="text-white m-0"
        ></fla-icon>
      </div>
    </a>
    <div
      [attr.aria-labelledby]="ref"
      class="dropdown-menu round-sm list-max-height"
    >
      <ng-template
        *ngTemplateOutlet="list; context: { options: options }"
      ></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #list let-options="options">
  <ng-container *ngFor="let item of options">
    <a
      type="button"
      class="dropdown-item p-2 d-inline-flex"
      (click)="selectOption(item)"
    >
      <div class="d-inline-flex align-items-center gap-2">
        <span
          class="fs-6 fw-bold"
          [ngClass]="{
            'text-brand-primary': currentValue[valueField] === item[valueField]
          }"
        >
          {{ item[labelField] }}
        </span>
      </div>
    </a>
  </ng-container>
</ng-template>
