import { BROKER_TERMS_STATUS } from '@core/interface';

export interface ITermsStatus {
  success: boolean;
  code: TERMS_STATUS;
  message: {
    idTerm: number;
    nmTerm: string;
    idVersion: number;
    content: string;
    contentType: string;
    expirationDate: string;
  };
}

export enum TERMS_STATUS {
  SEARCH_TERM_APPROVE_STATUS_HAST_APPROVED_ERROR = 'SEARCH_TERM_APPROVE_STATUS_HAST_APPROVED_ERROR',
  SEARCH_TERM_APPROVE_STATUS_NOT_MOST_RECENT_ERROR = 'SEARCH_TERM_APPROVE_STATUS_NOT_MOST_RECENT_ERROR',
}

export interface IModalTermsParams {
  code: TERMS_STATUS | BROKER_TERMS_STATUS;
  content: string;
  isBrokerTerm: boolean;
  brokerName: string;
  idBrokerTerm: number;
}
