<div
  [class]="'form-check d-flex ' + css"
  [ngClass]="{
    'form-check-inline': inline,
    'form-switch': isSwitch,
    'form-selection': isTag,
    'padding-right-label': isTextLeft
  }"
>
  <div class="width-max" *ngIf="isTextLeft">
    <ng-container *ngTemplateOutlet="labelSub"></ng-container>
  </div>
  <input
    class="form-check-input"
    type="checkbox"
    [name]="name"
    [id]="refId"
    [value]="value"
    [formControl]="formControl"
    [checked]="checked"
    [required]="isRequired"
    [disabled]="_disabled"
    (change)="changeChecked()"
    [ngClass]="{ 'cursor-pointer': isSwitch }"
  />
  <div *ngIf="!isTextLeft">
    <ng-container *ngTemplateOutlet="labelSub"></ng-container>
  </div>
  <small
    class="ng-invalid invalid-feedback d-block"
    *ngIf="
      (isRequired &&
        formControl.enable &&
        formControl.invalid &&
        formControl.touched) ||
      formControl.hasError(hasError)
    "
  >
    {{ requiredText }}
  </small>
</div>

<ng-template #labelSub>
  <div class="d-flex flex-column">
    <div class="d-flex gap-2">
      <label
        class="form-check-label"
        [ngClass]="{
          tag: isTag,
          'pl-0': isTextLeft,
          'text-white-50': _disabled
        }"
        [for]="refId"
        *ngIf="label"
      >
        {{ label }}
      </label>
      <div class="top-negative">
        <fla-icon
          *ngIf="infoText"
          css="user-select-none"
          [tooltip]="infoText"
          size="icon-size-tiny"
          name="info"
        ></fla-icon>
      </div>
    </div>

    <span
      *ngIf="subLabel"
      [ngClass]="'pl-0 ' + subLabelClass + (_disabled ? ' text-white-50' : '')"
    >
      {{ subLabel }}
    </span>
  </div>
</ng-template>
