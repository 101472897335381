import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ITypeButton } from './annotation-tooltip.interface';
import { TrademapNewsService } from '@shared/services/api/trademap/v1/news.service';
import { TypeButtonEnum } from './annotation-tooltip.enum';
import { formatByTick } from 'src/app/utils/utils.functions';
import { FormControl } from '@angular/forms';
import { IAccountSelect, ISearchStock } from '@core/interface';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { IStockChartOrderConfig } from '@shared/components/stock-chart/interface/stock-chart.interface';
import { MultibrokerService } from '@shared/services/core/multibroker';
import { Subscription } from 'rxjs';
import { ICandleConfigs } from '@shared/tiger-chart/interface';
import { StockPreferencesService } from '@shared/services/stock-preferences.service';
import { TradeIdeaHelperService } from '@shared/components/trade-idea/trade-idea-helper.service';
import { ModalTradeIdeaComponent } from '@shared/components/trade-idea/modal-trade-idea/modal-trade-idea.component';
import { RocketModalService } from '@shared/rocket-components/components';

@Component({
  selector: 'app-tiger-chart-annotation-tooltip',
  templateUrl: './annotation-tooltip.component.html',
  styleUrls: ['./annotation-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TigerChartAnnotationTooltipComponent
  implements OnChanges, OnDestroy
{
  @Input() public stockChartConfig!: ICandleConfigs;
  @Input() label!: string[];
  @Input() top!: number;
  @Input() left!: number;
  @Input() ref!: string;
  @Input() forcedQtty!: number;
  @Input() set isVisible(isVisible: boolean) {
    if (this.label.length) {
      this._isVisible = isVisible;
    } else {
      this.forceClose.emit(true);
    }
  }
  @Input() typeButtons!: ITypeButton | undefined;
  @Input() isExpanded: boolean = false;
  @Input() tickSizeDenominator: number = 0;
  @Input() set stock(stock: ISearchStock) {
    this.selectedStock = stock;
  }
  @Output() forceClose: EventEmitter<boolean> = new EventEmitter();
  selectedStock!: ISearchStock;
  standardLot: number = 100;
  negotiationLot: number = 100;
  priceTradeIdea: string = '';
  qttyControl = new FormControl(100);
  _isVisible = false;
  private _onAccountChange$!: Subscription;
  private _account!: IAccountSelect;

  constructor(
    private newsService: TrademapNewsService,
    private stockChartService: StockChartService,
    private multibrokerService: MultibrokerService,
    private _stockPreferencesService: StockPreferencesService,
    private tradeIdeaHelperService: TradeIdeaHelperService,
    private rocketModalService: RocketModalService
  ) {
    this.initSubscriptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { typeButtons } = changes;
    if (
      typeButtons &&
      typeButtons.currentValue &&
      typeButtons.currentValue.isTradeIdea
    ) {
      this.formatTradeIdea();
    }
  }

  ngOnDestroy() {
    this._onAccountChange$ && this._onAccountChange$.unsubscribe();
  }

  private initSubscriptions() {
    this._onAccountChange$ = this.multibrokerService
      .onUpdateSelectedAccountChannel()
      .subscribe((account) => {
        this._account = account;
      });
  }

  buttonAnnotationClick(typeClick: string) {
    if (typeClick === 'UPDATE_PARAMS_TRADE_IDEA') {
      this.openEditTradeIdeaModal();
      return;
    }
    if (
      this.typeButtons &&
      this.typeButtons.cod === TypeButtonEnum.THUMBS &&
      this.typeButtons.idNews
    ) {
      this.newsService
        .updateNewsVote(
          this.typeButtons.idNews,
          typeClick === 'THUMBS_UP' ? 1 : 0
        )
        .subscribe();
    }
    if (
      this.typeButtons &&
      this.typeButtons.cod === TypeButtonEnum.BUTTON &&
      this.typeButtons.isTradeIdea
    ) {
      this.sendOrder();
    }
  }

  private sendOrder() {
    if (!this.typeButtons) return;
    if (this.typeButtons.isTradeIdea) {
      this.setTradeIdeaOrigin();
    }
    const event = this.typeButtons?.tradeIdea;
    const config: IStockChartOrderConfig =
      this.tradeIdeaHelperService.buildTradeIdeaOrder(
        event,
        this.selectedStock,
        this._account,
        this.qttyControl.value!
      );
    this.stockChartService.newOrder(config).subscribe({
      error: () => {
        this.forceClose.emit(true);
      },
      complete: () => {
        this.forceClose.emit(true);
      },
    });
  }

  private setTradeIdeaOrigin() {
    const event = this.typeButtons?.tradeIdea;
    if (!event) return;
    this.tradeIdeaHelperService.setTradeIdeaOrigin(event);
  }

  private formatTradeIdea() {
    if (!this.typeButtons) return;
    const value = this._stockPreferencesService.getStockSaved(
      this.selectedStock.cd_stock_order,
      this.forcedQtty ? this.forcedQtty : this.selectedStock.standard_lot,
      'qtty'
    );
    this.priceTradeIdea = formatByTick(
      this.typeButtons.tradeIdea.price_entry,
      this.tickSizeDenominator
    );
    this.standardLot = this.selectedStock.standard_lot;
    this.negotiationLot = value;
    this.qttyControl.setValue(this.negotiationLot);
    this.typeButtons.tradeIdea.strategy.items =
      this.tradeIdeaHelperService.formatStrategyItemsTradeIdea(
        this.typeButtons.tradeIdea.strategy.type_strategy,
        this.typeButtons.tradeIdea.strategy.items
      );
  }

  private openEditTradeIdeaModal() {
    const tradeIdea = this.typeButtons!.tradeIdea;
    const dsAsset = tradeIdea.cd_stock.substring(0, 3);
    this.rocketModalService.open(ModalTradeIdeaComponent, {
      centered: true,
      backdrop: true,
      keyboard: true,
      data: {
        stock: this.selectedStock ?? {
          cd_stock: tradeIdea.cd_stock,
          id_exchange: tradeIdea.id_exchange,
          tick_size_denominator: tradeIdea.tick_size_denominator,
          ds_asset: tradeIdea.cd_stock.substring(0, 3),
          //valor ficcional
          cd_segment: dsAsset == 'WDO' || dsAsset == 'DOL' ? 7 : 9999,
        },
        selectedTradeIdea: tradeIdea,
        isEdit: true,
        isSecondModal: false,
        qtty: this.qttyControl.value,
      },
    });
  }
}
