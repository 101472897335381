import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LinkSubscription {
  private metadata$ = new BehaviorSubject<any>(null);

  constructor() {}

  public linked(message: LinkEventMessage): void {
    this.metadata$.next(message);
  }

  public onLinked = (id_component: any): Observable<any> =>
    this.metadata$.asObservable().pipe(
      filter(
        (data: any) => data?.id_component && data.id_component === id_component
      ),
      map((data) => data.linked)
    );
}

export interface LinkEventMessage {
  id_component: string;
  linked: boolean;
}
