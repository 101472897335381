import { Injectable } from '@angular/core';
import { ToastService } from '@shared/services/toast.service';
import {
  NOTIFICATIONS_ALERT_STATUS,
  NOTIFICATIONS_ORDER_STATUS,
} from '@shared/dictionary/notifications.dictionary';
import { NOTIFICATION_TYPE } from '@shared/constants/notifications.const';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import {
  ORDER_CANCEL_STATUS_DIC,
  ORDER_CONF_STATUS_DIC,
  ORDER_EXEC_STATUS_DIC,
} from '@shared/dictionary/order.dictionary';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private sounds = [
    'order_executed',
    'confirmed_order',
    'canceled_order',
    'extra',
  ];

  constructor(
    private toastService: ToastService,
    private customPreferencesService: CustomPreferencesService
  ) {}

  public emit(data: any) {
    this.sendToast(data);
  }

  sendToast(data: any): void {
    const { notification_type, ds_title, status } = data;
    let notification;
    switch (notification_type) {
      case NOTIFICATION_TYPE.ORDER: {
        notification = NOTIFICATIONS_ORDER_STATUS.get(status);
        notification!.text = ds_title;
        notification!.isHtml = data.status === 'CANC';

        this.customPreferencesService.audioNotification &&
          this.soundNotification(data.status);
        if (
          Notification.permission === 'granted' &&
          (document.visibilityState === 'hidden' || !document.hasFocus())
        ) {
          this.notificationSystem(ds_title);
        }
        break;
      }
      case NOTIFICATION_TYPE.ALERTA: {
        notification = NOTIFICATIONS_ALERT_STATUS.get('DEFAULT');
        notification!.text = ds_title;
        this.customPreferencesService.audioNotification &&
          this.soundNotification(NOTIFICATION_TYPE.ALERTA);
        break;
      }
      default: {
        notification = NOTIFICATIONS_ALERT_STATUS.get('DEFAULT');
        notification!.type = 'info';
        notification!.text = ds_title;
        break;
      }
    }
    notification &&
      this.toastService.showToast(
        notification.type,
        notification.text,
        notification.isHtml
      );
  }

  private soundNotification(status: string): void {
    let sound = '';
    const config = this.customPreferencesService.configSoundsNotifications;
    if (ORDER_EXEC_STATUS_DIC.has(status)) {
      const index = config.split(',')[0];
      sound = this.sounds[+index];
    } else if (ORDER_CONF_STATUS_DIC.has(status)) {
      const index = config.split(',')[1];
      sound = this.sounds[+index];
    } else if (ORDER_CANCEL_STATUS_DIC.has(status)) {
      const index = config.split(',')[2];
      sound = this.sounds[+index];
    } else if (NOTIFICATION_TYPE.ALERTA === status) {
      const index = config.split(',')[3];
      sound = this.sounds[+index];
    }
    sound && this.playAudio(sound);
  }

  private async playAudio(name: string): Promise<void> {
    const sound = new Audio(`assets/sons/${name}.mp3`);
    sound.load();
    await sound.play();
  }

  private notificationSystem(message: string): void {
    const options = {
      body: message,
      icon: 'assets/icons/icon-72x72.png',
    };

    new Notification('Rocket Trader', options);
  }
}
