import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlaListGroupComponent } from './list-group.component';

@NgModule({
  imports: [CommonModule],
  declarations: [FlaListGroupComponent],
  exports: [FlaListGroupComponent],
})
export class FlaListGroupModule {}
