import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import {
  IPartnerInfos,
  IPartnerModalSeen,
} from './partner-preferences.interface';
import { AUTH_LOCAL_KEYS } from '../const/auth_util.const';
import { system } from '@core/system/system.service';
import { BrowserStorageBase } from '../storage/browser-storage-base';
import { IBrokersInfosV10 } from '@shared/services/api/trademap/V10/models/brokers-to-connect.interface';
import { sharedSessionStorage } from '../storage/shared-session.storage';

@Injectable({
  providedIn: 'root',
})
export class PartnersPreferencesService extends CustomPreferencesService {
  private _partnerInfos!: IPartnerInfos | null;
  private _partnerModalSeen!: IPartnerModalSeen | null;
  private _preSavedPartnerInfos: IPartnerInfos | null = null;
  private _storage = new BrowserStorageBase(sharedSessionStorage);

  set partnerBeforeAuth(params: IPartnerInfos | null) {
    this._preSavedPartnerInfos = params;
  }

  get partnerBeforeAuth(): IPartnerInfos | null {
    return this._preSavedPartnerInfos;
  }

  set hasSeenModal(params: IPartnerModalSeen | null) {
    this._partnerModalSeen = params;
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.PARTNER_MODAL_SEEN,
      JSON.stringify(params)
    );
  }

  get isSessionWeak(): boolean {
    return system.userAuthenticated.investor.isSessionWeak;
  }

  get linkedPartnerInfos(): IPartnerInfos | null {
    if (this._partnerInfos === undefined || this._preSavedPartnerInfos !== null)
      return this._verifyHasPartner();
    return this._partnerInfos;
  }

  get shouldDisplayWelcomeTM(): boolean {
    const response = this.storage.getItem(
      AUTH_LOCAL_KEYS.DISPLAY_TRADEMAP_WELCOME_MODAL
    );
    if (response === '0') return false;
    return true;
  }

  constructor(http: HttpClient) {
    super(http);
  }

  public updatePartner(partner: IPartnerInfos): void {
    this._partnerInfos = partner;
    if (this.isSessionWeak) {
      this._storage.setItem(
        AUTH_LOCAL_KEYS.LINKED_PARTNER,
        JSON.stringify(partner)
      );
      return;
    }
    this._savePartner(partner);
  }

  private _savePartner(partnerTOSave: IPartnerInfos): void {
    const previusPartner = this.getCustomPreference(
      AUTH_LOCAL_KEYS.LINKED_PARTNER
    );
    if (previusPartner && JSON.parse(previusPartner).key === partnerTOSave.key)
      return;
    this.savePartnerAfterAuth(partnerTOSave);
  }

  public savePartnerAfterAuth(partner: IPartnerInfos): void {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.LINKED_PARTNER,
      JSON.stringify(partner)
    );
  }

  public shouldDisplayPartnerModal(): boolean {
    const response = this._alreadySawPartnerModal();
    if (response === null) return true;
    if (response.key === this.linkedPartnerInfos?.key) return false;
    this.hasSeenModal = null;
    return true;
  }

  public hideBroker(broker: IBrokersInfosV10): boolean {
    if (!this.linkedPartnerInfos) return false;
    if (
      this.linkedPartnerInfos.name !== broker.nmBroker &&
      this.linkedPartnerInfos.name !== broker.nmBrokerNickname &&
      !broker.hasAccount
    )
      return true;
    return false;
  }

  private _alreadySawPartnerModal(): IPartnerModalSeen | null {
    const value = this.getCustomPreference(AUTH_LOCAL_KEYS.PARTNER_MODAL_SEEN);
    if (!value) return null;
    this._partnerModalSeen = JSON.parse(value);
    return this._partnerModalSeen;
  }

  private _getPartnerFromSession(): IPartnerInfos | null {
    const item = this._storage.getItem(AUTH_LOCAL_KEYS.LINKED_PARTNER);
    if (item) {
      const parsedItem = JSON.parse(item);
      this.updatePartner(parsedItem);
      return parsedItem;
    }
    const linkedPartner = this.getCustomPreference(
      AUTH_LOCAL_KEYS.LINKED_PARTNER
    );
    if (linkedPartner) return JSON.parse(linkedPartner);
    return null;
  }

  private _verifyHasPartner(): IPartnerInfos | null {
    if (this._preSavedPartnerInfos) {
      const value = this._preSavedPartnerInfos;
      this._preSavedPartnerInfos = null;
      this._partnerInfos = value;
      this.updatePartner(value);
      return value;
    }
    if (system.userAuthenticated.investor.isSessionWeak) {
      this._partnerInfos = null;
      this._preSavedPartnerInfos = null;
      return null;
    }
    const fromSession = this._getPartnerFromSession();
    if (!fromSession) {
      this._partnerInfos = null;
      this._preSavedPartnerInfos = null;
      return null;
    }
    this._partnerInfos = fromSession;
    return this._partnerInfos;
  }
}
