const ADD_IN_HELP_STEPS = {
  INITIAL: {
    title: 'Add-In Excel 🖖',
    intro:
      'O <b>Add-In</b> é uma ferramenta com mais de 20 modelos no Excel para análise de dados, desenvolvimento de estratégias, acompanhamento de ativos em tempo real e dados históricos. <br /> <br /> Crie suas planilhas de forma inteligente e personalizada!',
  },
  INSTALATION: {
    title: 'Instalação',
    intro:
      'Para instalar o <b>Add-In</b> siga os passos abaixo: <br /> <br /> <ol> <li>Clique em <b>Add-In Excel</b> no topo direito do <b>RocketTrader</b> e em <b>Baixar</b></li> <li>Siga o passo a passo da instalação</li> <li>Após concluir, abra o Excel e clique em <b>Trademap Add-In</b> no menu superior</li> <li>Clique no botão <b>Entrar</b></li> <li>Preencha com as mesmas informações de acesso do <b>RocketTrader</b></li> <li>Após conectado escolha o modelo e aproveite! </li> </ol>',
  },
  TOUR_END: {
    title: 'Agora é com você! 🖖',
    intro:
      'Aproveite as funcionalidades do <b>Add-In</b>! <br /> <br /> Para mais informações sobre <a class="fs-5" target="_blank" href="https://faq.trademap.com.br/hc/pt-br/articles/18532333480859-Como-acessar-o-Add-In">clique aqui</a>',
  },
};

export const ADD_IN_STEPS = () => [
  ADD_IN_HELP_STEPS.INITIAL,
  ADD_IN_HELP_STEPS.INSTALATION,
  ADD_IN_HELP_STEPS.TOUR_END,
];
