import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  IIndexStock,
  IQuoteFields,
} from '../../interface/stocks-at-auction-by-index.interface';
import { STOCK_TABLE_VIEW } from '@shared/components/stock-table-views';
import { CommonModule } from '@angular/common';
import { StockTableViewsModule } from '@shared/components/stock-table-views/stock-table-views.module';
import { AUCTION_STOCKS_BY_INDEX_ELEMENTS_ID } from '../../enum/stocks-at-auction-by-index.enum';
import { ScrollingModule } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-auction-stocks-by-index',
  templateUrl: './auction-stocks-by-index.component.html',
  styles: [
    `
      .stocks-content {
        display: grid;
        grid-template-columns: repeat(3, 135px);
        grid-template-rows: repeat(auto-fill, 78px);
        justify-content: center;
        grid-auto-flow: row;
        gap: 4px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, StockTableViewsModule, ScrollingModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuctionStocksByIndexComponent implements OnChanges {
  @Output() public showAllIndexes = new EventEmitter<void>();
  @Output() public closeContent = new EventEmitter<void>();
  @Output() public updateGlobalStock = new EventEmitter<IQuoteFields>();

  @Input() public indexSelected!: IIndexStock;
  @Input() public globalCdStock: string = '';
  @Input() public stocks: IQuoteFields[] = [];
  @Input() public isLoading: boolean = true;

  public readonly view = STOCK_TABLE_VIEW.CARD;
  public readonly componentID = AuctionStocksByIndexComponent.name;
  public readonly elementdID = AUCTION_STOCKS_BY_INDEX_ELEMENTS_ID;
  public itemSize = 78;
  public scrollHeight = 78;
  public stocksToDisplay: IQuoteFields[] = [];
  public showAllStocks = false;
  private _lastLength = 0;

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { stocks } = changes;
    if (stocks?.currentValue?.length)
      this._handleStocksToDisplay(stocks.currentValue);
  }

  public toggleDisplayAllStocks(): void {
    this.showAllStocks = !this.showAllStocks;
    this._cdr.detectChanges();
    this._handleStocksToDisplay(this.stocks);
  }

  private _handleStocksToDisplay(stocks: IQuoteFields[]): void {
    if (!stocks || !stocks.length) {
      this.stocksToDisplay = [];
      this._calcElementAndContainerSize(1);
      this._cdr.detectChanges();
      this.isLoading = false;
      return;
    }
    const qtty = this.showAllStocks || stocks.length < 9 ? stocks.length : 9;
    this._calcElementAndContainerSize(qtty);
    if (this.showAllStocks) {
      this.stocksToDisplay = stocks;
      this.isLoading = false;
      this._cdr.detectChanges();
      return;
    }
    this.stocksToDisplay = stocks.slice(0, 9);
    this.isLoading = false;
    this._cdr.detectChanges();
  }

  private _calcElementAndContainerSize(quantity: number): void {
    if (quantity === this._lastLength) return;
    const size = Math.ceil(quantity / 3);
    this._lastLength = quantity;
    this.itemSize = size;
    this.scrollHeight = size * 78;
    this._cdr.detectChanges();
  }
}
