import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { GLOBAL_CHART_CONFIGURATION } from '../modal-more-options/interface/stock-chart-modal.interface';

export class GlobalChartConfiguration {
  showXGridLines: boolean = true;
  showYGridLines: boolean = true;
  useTimerPriceClose: boolean = true;
  useLogarithmic: boolean = false;
  isFreeScale: boolean = true;
  showAuctionChartEvent: boolean = true;
  navigationControls: string = 'ONLY_HOVER';
  showChartBook: boolean = false;
  showExecOrders: boolean = true;
  showOpenFlag: boolean = true;
  showOrderMarker: boolean = true;
  showFlagOrders: boolean = true;
  showChartVolumeAtPrice: boolean = false;
  showFastRuler: boolean = false;
  fastRulerNavigation: boolean = false;
  showMinMaxVisible: boolean = false;
  showTradingChange: boolean = false;
  showPreviousClose: boolean = false;
  showBidAsk: boolean = false;
  showWatermark: boolean = true;
  watermarkOpacity: number = 30;
  colorsVelaDown?: string;
  colorsVelaUp?: string;
  colorsBorderDown?: string;
  colorsBorderUp?: string;
  volumeUpColor?: string;
  volumeDownColor?: string;
  constructor(values?: GLOBAL_CHART_CONFIGURATION) {
    if (values) {
      this.setGlobals(values, 'showXGridLines');
      this.setGlobals(values, 'showYGridLines');
      this.setGlobals(values, 'useTimerPriceClose');
      this.setGlobals(values, 'useLogarithmic');
      this.setGlobals(values, 'isFreeScale');
      this.setGlobals(values, 'showAuctionChartEvent');
      this.setGlobals(values, 'colorsVelaDown');
      this.setGlobals(values, 'colorsVelaUp');
      this.setGlobals(values, 'colorsBorderDown');
      this.setGlobals(values, 'colorsBorderUp');
      this.setGlobals(values, 'volumeUpColor');
      this.setGlobals(values, 'volumeDownColor');
      this.setGlobals(values, 'navigationControls');
      this.setGlobals(values, 'showFastRuler');
      this.setGlobals(values, 'showChartBook');
      this.setGlobals(values, 'showChartVolumeAtPrice');
      this.setGlobals(values, 'fastRulerNavigation');
      this.setGlobals(values, 'showMinMaxVisible');
      this.setGlobals(values, 'showTradingChange');
      this.setGlobals(values, 'showPreviousClose');
      this.setGlobals(values, 'showBidAsk');
      this.setGlobals(values, 'showWatermark');
      this.setGlobals(values, 'watermarkOpacity');
      this.setGlobals(values, 'showExecOrders');
      this.setGlobals(values, 'showOpenFlag');
      this.setGlobals(values, 'showOrderMarker');
      this.setGlobals(values, 'showFlagOrders');
    }
  }

  private setGlobals(values: GLOBAL_CHART_CONFIGURATION, field: string) {
    const anyThis = this as any;
    const anyValues = values as any;
    const value = anyValues[field];
    if (!isNullOrUndefined(value)) {
      anyThis[field] = value;
    }
  }
}
