<div *ngIf="showLabel" class="title">Opacidade</div>
<div class="d-flex align-items-center opacity">
  <div
    *ngIf="showSlider"
    id="pointerWrap"
    [id]="'opacitySlider_' + typeColorUpdate"
    class="opacitySlider"
    style="color: rgb(255, 224, 178)"
  >
    <div class="opacitySliderGradient"></div>
    <div class="opacityPointerWrap">
      <input
        class="rangePointer"
        [id]="'rangePointer_' + typeColorUpdate"
        type="range"
        min="0"
        max="100"
        step="1"
        [(ngModel)]="opacityValue"
        (input)="opacityChange()"
      />
    </div>
  </div>
  <div class="opacityInputWrap">
    <input
      type="number"
      class="opacityInput"
      [(ngModel)]="opacityValue"
      (input)="opacityChange()"
      min="0"
      max="100"
      step="1"
    />
    <span class="opacityInputPercent">%</span>
  </div>
</div>
