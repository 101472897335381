import { STOCK_TRADE_ELEMENTS_ID } from '@shared/components/stock-trade/enum/stock-trade.enum';
import { GENERIC_INTRO_STEPS } from './gerenric.steps';

const STOCK_TRADE_INTRO_STEPS = {
  INITIAL: (component: Element) => ({
    title: 'Conheça Boleta',
    intro:
      'A boleta permite o envio de ordens de compra ou venda de ativos financeiros, como ações, opções e futuros. Com ela, é possível especificar o código do ativo, a quantidade desejada, o tipo de ordem e selecionar um preço limite ou se a operação será a mercado. <br /><br /> Use as setas do teclado ou clique no próximo para começar. ',
    element: component,
  }),
  HEADER_ORDER_TYPE: (component: Element) => ({
    title: 'Tipo de ordem',
    intro:
      'As abas na parte superior indicam o tipo de ordem: Compra ou Venda e Simples ou Stop. Selecione uma delas e defina o ativo e a quantidade que você deseja negociar.',
    element: component.querySelector(
      `#${STOCK_TRADE_ELEMENTS_ID.HEADER_ORDER_TYPE}`
    ),
  }),
  BOOK_CHARTS: (component: Element) => ({
    title: 'Book',
    intro:
      'Dentro do book você visualiza a quantidade de negócios ofertados em um determinado preço e o lado da negociação, sendo compra em azul e venda em amarelo.',
    element: component.querySelector(`#${STOCK_TRADE_ELEMENTS_ID.BOOK_CHARTS}`),
  }),
  STOCK_SELECTOR: (component: Element) => ({
    title: 'Seletor de papel',
    intro:
      'Aqui você seleciona o ativo que deseja negociar. Para alterar, basta clicar no papel e digitar o código de negociação.',
    element: component.querySelector(
      `#${STOCK_TRADE_ELEMENTS_ID.STOCK_SELECTOR}`
    ),
    preChange: function () {
      this.element = component.querySelector(
        `#${STOCK_TRADE_ELEMENTS_ID.STOCK_SELECTOR}`
      );
    },
  }),
  ORDER_TYPE: (component: Element) => ({
    title: 'Tipo',
    intro:
      'Aqui você deve selecionar o tipo de ordem a ser enviada. Selecione o tipo e preencha os demais campos necessários para o envio da sua ordem.',
    element: component.querySelector(
      `#${STOCK_TRADE_ELEMENTS_ID.ORDER_TYPE}`
    ),
    preChange: function () {
      this.element = component.querySelector(
        `#${STOCK_TRADE_ELEMENTS_ID.ORDER_TYPE}`
      );
    },
  }),
  QUANTITY: (component: Element) => ({
    title: 'Quantidade',
    intro:
      'Aqui você precisa especificar a quantidade de ativos que deseja negociar. Preencha com o número de ações, contratos ou unidades para realizar a operação.',
    element: component.querySelector(`#${STOCK_TRADE_ELEMENTS_ID.QUANTITY}`),
    preChange: function () {
      this.element = component.querySelector(
        `#${STOCK_TRADE_ELEMENTS_ID.QUANTITY}`
      );
    },
  }),
  PRICE: (component: Element) => ({
    title: 'Preço',
    intro: 'Defina um preço para enviar suas ordens.',
    element: component.querySelector(`#${STOCK_TRADE_ELEMENTS_ID.PRICE}`),
    preChange: function () {
      this.element = component.querySelector(
        `#${STOCK_TRADE_ELEMENTS_ID.PRICE}`
      );
    },
  }),
  MARKET_PRICE: (component: Element) => ({
    title: 'A mercado',
    intro:
      'Ao marcar a opção “A mercado” a ordem será executada automaticamente pelo preço praticado no momento do envio.',
    element: component.querySelector(
      `#${STOCK_TRADE_ELEMENTS_ID.MARKET_PRICE}`
    ),
    preChange: function () {
      this.element = component.querySelector(
        `#${STOCK_TRADE_ELEMENTS_ID.MARKET_PRICE}`
      );
    },
  }),
  VALIDATE: (component: Element) => ({
    title: 'Validade',
    intro:
      'Aqui você pode selecionar a validade da sua ordem. Existem diferentes opções disponíveis, como "Hoje”, “Até o Dia”, “Até Cancelar (VAC)” e “Executar ou Cancelar”',
    element: component.querySelector(`#${STOCK_TRADE_ELEMENTS_ID.VALIDATE}`),
    preChange: function () {
      this.element = component.querySelector(
        `#${STOCK_TRADE_ELEMENTS_ID.VALIDATE}`
      );
    },
  }),
  ORDER_OCO: (component: Element) => ({
    title: 'Estratégia OCO',
    intro:
      'Habilitando a estratégia OCO, você pode definir valores para ordens de saída com ganho (Gain) e perda (Loss) máximos. Quando uma dessas ordens é executada a outra é cancelada automaticamente.',
    element: component.querySelector(`#${STOCK_TRADE_ELEMENTS_ID.ORDER_OCO}`),
    preChange: function () {
      this.element = component.querySelector(
        `#${STOCK_TRADE_ELEMENTS_ID.ORDER_OCO}`
      );
    },
  }),
  INFORMATIONS: (component: Element) => ({
    title: 'Informações',
    intro:
      'Aqui você encontra o valor total de negociação, o seu saldo e corretora para negociação.',
    element: component.querySelector(
      `#${STOCK_TRADE_ELEMENTS_ID.INFORMATIONS}`
    ),
    toggleStockTradeType: true,
  }),
  ORDER_STOP: (component: Element) => ({
    title: 'Ordem Stop',
    intro:
      'Nas abas de Compra Stop e Venda Stop, você pode definir um preço para o Stop. Assim que esse valor for atingido, uma ordem será disparada automaticamente no preço desejado.',
    element: component.querySelector(`#${STOCK_TRADE_ELEMENTS_ID.ORDER_STOP}`),
    position: 'bottom',
    preChange: function () {
      this.element = component.querySelector(
        `#${STOCK_TRADE_ELEMENTS_ID.ORDER_STOP}`
      );
      this.position = 'bottom';
    },
  }),
  DOUBLE_STOP: (component: Element) => ({
    title: 'Stop Duplo',
    intro:
      'As ordens Stop Duplo permitem que você monte estratégias de negociação em momentos de oscilação do papel para ter ganhos (Gain) ou perdas (Loss) máximos.',
    element: component.querySelector(`#${STOCK_TRADE_ELEMENTS_ID.DOUBLE_STOP}`),
    position: 'bottom',
    preChange: function () {
      this.element = component.querySelector(
        `#${STOCK_TRADE_ELEMENTS_ID.DOUBLE_STOP}`
      );
      this.position = 'bottom';
    },
  }),
};

export const STOCK_TRADE_STEPS = (superDomElement: Element) => [
  STOCK_TRADE_INTRO_STEPS.INITIAL(superDomElement),
  STOCK_TRADE_INTRO_STEPS.HEADER_ORDER_TYPE(superDomElement),
  STOCK_TRADE_INTRO_STEPS.BOOK_CHARTS(superDomElement),
  STOCK_TRADE_INTRO_STEPS.STOCK_SELECTOR(superDomElement),
  STOCK_TRADE_INTRO_STEPS.ORDER_TYPE(superDomElement),
  STOCK_TRADE_INTRO_STEPS.QUANTITY(superDomElement),
  STOCK_TRADE_INTRO_STEPS.PRICE(superDomElement),
  STOCK_TRADE_INTRO_STEPS.MARKET_PRICE(superDomElement),
  STOCK_TRADE_INTRO_STEPS.VALIDATE(superDomElement),
  STOCK_TRADE_INTRO_STEPS.ORDER_OCO(superDomElement),
  STOCK_TRADE_INTRO_STEPS.INFORMATIONS(superDomElement),
  STOCK_TRADE_INTRO_STEPS.ORDER_STOP(superDomElement),
  STOCK_TRADE_INTRO_STEPS.DOUBLE_STOP(superDomElement),
  GENERIC_INTRO_STEPS.TOUR_END,
];
