<div class="container d-flex justify-content-center">
  <div class="terms-container text-center">
    <h1 class="pt-1 text-center text-uppercase mb-5 fs-4">
      termos de responsabilidades e obrigações do usuário da plataforma rocket
      trader
    </h1>
    <p class="text-uppercase text-center mb-5">
      contrato de licenciamento de software e distribuiçao de informaçoes
      financeiras
    </p>
    <p class="text-justify">
      A
      <span class="fw-bold text-uppercase"
        >valemobi consultoria empresarial s/a </span
      >,pessoa jurídica de direito privado, inscrita no CNPJ sob nº
      10.535.290/0001-21, com sede na Avenida General Furtado Nascimento, nº
      740, cj. 60, 6º andar, São Paulo/SP, empresa proprietária de softwares e
      plataformas tecnológicas desenvolvidas para distribuir informações
      financeiras e de mercado, market data da B3 e de outras bolsas e
      fornecedores, assim como rotear ordens de negociação de títulos e valores
      mobiliários (“Plataforma”), doravante referida simplesmente como
      “VALEMOBI”.
    </p>
    <p class="text-justify">
      E, <span class="fw-bold text-uppercase">VOCÊ</span>, pessoa física não
      profissional do mercado financeiro, nesse ato denominado “Usuário”, que se
      responsabiliza integralmente pelas suas ações perante a VALEMOBI, e pela
      utilização da Plataforma.
    </p>
    <p class="mb-5 text-justify">
      Ao aceitar esse Termo de Responsabilidades e Obrigações (“Termo”), VOCÊ
      declara que leu, entendeu, aceita, e cumprirá suas responsabilidades e
      obrigações como Usuário da Plataforma, contidas nestes Termos de
      Responsabilidades e Obrigações, e declara perante a Legislação Brasileira
      que detém conhecimento para a utilização da Plataforma, inclusive das
      normas da Comissão de Valores Mobiliários, ou a quaisquer outros órgãos
      reguladores, aos quais o Usuário está sujeito, isentando integralmente a
      VALEMOBI por suas ações através da utilização da Plataforma. Também
      reconhece que a VALEMOBI é uma empresa de desenvolvimento e licenciamento
      de softwares e plataformas tecnológicas e não fornece serviços de
      recomendação de investimentos ou análise de ativos financeiros, os quais
      são atividades exclusivas de analistas de investimentos certificados.
    </p>

    <ol>
      <li class="mb-5 w-100 text-left">
        <span class="fw-bold">OBJETO</span>
        <ol>
          <li>
            A cessão da licença de uso da Plataforma ao Usuário, nos termos
            previstos neste Termo, atuando com diligência e primando pelo
            emprego de preceitos ético-profissionais e pelo uso das melhores
            ferramentas e técnicas disponíveis no mercado.
          </li>
          <li>
            A licença da Plataforma é temporária, limitada, não transferível,
            não exclusiva e revogável, para acessar e utilizar on-line ou
            baixar, instalar, executar e utilizar, sendo para uso pessoal, e
            apenas do Usuário.
          </li>
          <li>
            A licença não transfere o direito de propriedade intelectual ou
            qualquer outro direito sobre a Plataforma, sendo todos os direitos
            intelectuais, patrimoniais, e outros regulados pela legislação
            brasileira única e exclusivamente da VALEMOBI.
          </li>
        </ol>
      </li>

      <li class="mb-5 w-100 text-left">
        <span class="fw-bold">PLATAFORMA E SUPORTE</span>
        <ol>
          <li>
            O Usuário, ao aceitar esse Termo, terá acesso a licença de uso da
            Plataforma, que será composta pelos seguintes Módulos:
            <ol>
              <li>Módulo Simulador (“Simulador”)</li>
              <li>
                Ferramentas e Componentes de acompanhamento de Notícias,
                Informações do Mercado, Indicadores e Market Data B3 (“Market
                Data”)
              </li>
              <li>Posição de Custódia de Ativos (“Custódia”)</li>
              <li>
                Cadastro de Ordens de Compra e Venda no Mercado Brasileiro
                (“Ordens”)
              </li>
              <li>Acompanhamento do Status das Ordens</li>
            </ol>
          </li>
          <li>
            A VALEMOBI prestará suporte ao Usuário de dúvidas e esclarecimentos
            sobre aspectos técnicos da Plataforma, sendo que a Parceira será
            responsável pelo suporte e atendimento ao Usuário no que tange a
            execução de operações, intermediação e todo e qualquer assunto que
            não esteja exclusivamente relacionado à Plataforma.

            <p>
              O suporte será realizado das 9hs às 18hs todos os dias de
              negociação em que houver negociação na B3 S.A. – Brasil, Bolsa,
              Balcão (“B3”).
            </p>
          </li>
          <li>
            Toda e qualquer reclamação ou notificação de suporte por parte do
            Usuário (“Suporte”) deverá ser realizada impreterivelmente em até 10
            (dez) dias corridos da data do fato gerador do suporte, visando
            garantir a análise dos dados e logs dos softwares, e podendo prover
            o melhor suporte ao Usuário. A Valemobi não poderá garantir a
            resolução do Suporte fora desse prazo, eximindo-se desde já de
            qualquer responsabilidade pelo fato gerador de eventual Suporte.
          </li>
        </ol>
      </li>

      <li class="mb-5 w-100 text-left">
        <span class="fw-bold">OBRIGAÇÕES DA VALEMOBI</span>
        <br />
        <span>São obrigações da Valemobi:</span>
        <ol>
          <li>
            Conceder uma licença limitada, não transferível, não exclusiva de
            uso da Plataforma ao Usuário, sendo certo que a VALEMOBI pode
            conferir licenças, similares ou distintas, a outros usuários.
          </li>
          <li>
            Adotar práticas de governança, compliance, segurança da informação e
            proteção de dados aptas a oferecer adequado grau de proteção às
            informações, inclusive a dados pessoais do Usuário a que tenha
            acesso em razão deste Contrato.
          </li>
          <li>
            Prestar adequado serviço de suporte do uso da Plataforma, ao
            Usuário, excluídas atividades de avaliação, reparo e adaptação dos
            sistemas e equipamentos do Usuário, e outras não previstas neste
            Termo.
          </li>
          <li>
            <p>
              Atualizar os Termos de Responsabilidades e Obrigações, a qualquer
              tempo, sem que isso caracterize descumprimento ou rescisão por
              parte da VALEMOBI de suas obrigações e direitos desses Termos.
            </p>
          </li>
          <li>
            <p>
              Tratar os dados do Usuário em consonância à legislação brasileira,
              inclusive no tocante às diretrizes previstas na Lei nº 13.709/2018
              (“Lei Geral de Proteção de Dados”), Marco Civil da Internet (Lei
              nº 12.965/2014) e alterações posteriores, e quaisquer outras leis
              e regulamentos em relação ao tratamento de dados pessoais e
              privacidade aplicáveis, sendo certo que deverão ser verificados e
              respeitados todos os tipos de dados previstos na legislação
              aplicável, conforme cada caso concreto em questão (inclusive com
              condutas para anonimizar os dados, caso exigido por lei e/ou se
              fizer necessário).
            </p>
          </li>
        </ol>
      </li>

      <li class="mb-5 w-100 text-left">
        <span class="fw-bold">OBRIGAÇÕES DO USUÁRIO</span>
        <br />
        <span>São obrigações do Usuário:</span>
        <ol>
          <li>
            <span class="fw-bold">Do Termo:</span> Aceitar e cumprir com os
            Termos de Responsabilidades e Obrigações do Usuário da Plataforma,
            atualizando-se periodicamente acerca de novas versões dos Termos da
            Plataforma disponibilizadas pela Valemobi.
          </li>
          <li>
            <span class="fw-bold">Do Login:</span>
            Garantir que o acesso à Plataforma está sendo realizado única e
            exclusivamente pelo Usuário, sendo diligente e o único responsável
            com seu login e senha, que são pessoais e intransferíveis (“Login”),
            responsabilizando-se integralmente pela segurança de seu login e
            senha de acesso à Plataforma.
          </li>
          <li>
            <span class="fw-bold">Da Conta:</span>
            possuir cadastro, conta ativa e regular em Corretora de Títulos e
            Valores Mobiliários regulamentada junto à CVM (“Parceira”) para o
            envio de ordens de compra e venda, considerando estar `compliance`
            junto ao processo administrativo e operacional da Parceira.
          </li>
          <li>
            <span class="fw-bold">Da Contratação da Plataforma:</span>
            O Usuário irá realizar a contratação da Plataforma através da
            Corretora Parceira onde possui Conta. Poderá haver período de
            `trial` (período de gratuidade para utilização da Plataforma),
            acordado entre a VALEMOBI e a Parceira. Após o período de `trial`, a
            Parceira irá pagar diretamente à VALEMOBI pela contratação da
            Plataforma, única e exclusivamente se o Usuário cumprir as seguintes
            condições:
            <ul>
              <li>
                nos 03(três) primeiros meses, operar, no mínimo 01 (um)
                minicontrato de índice ou de dólar com o RLP ativo, a cada mês,
              </li>
              <li>
                a partir do 4º(quarto) mês, operar mensalmente, no mínimo, 10
                (dez) minicontratos de índice ou de dólar com o RLP ou
                R$150,00(cento e cinquenta reais),
              </li>
            </ul>
            Caso as condições acima estabelecidas, não sejam cumpridas, o
            Usuário deverá realizar o pagamento mensal, no valor de R$ 129,90
            (cento e vinte e nove reais, e noventa centavos) diretamente à
            VALEMOBI, pela contratação da Plataforma.
          </li>
          <li>
            <span class="fw-bold">Da Fraude ou Uso Inadequado:</span> Abster-se
            de realizar ações fraudulentas e/ou inadequadas que possam causar
            prejuízo a Valemobi, a terceiros ou que possam a vir a afetar outros
            Usuários da Plataforma, ou ações que descumpram o previsto nesse
            Termo, aceitando que a VALEMOBI poderá, a seu exclusivo critério,
            bloquear, inativar ou cancelar o Login do Usuário, sem prejuízo a
            demais ações cabíveis.
            <br />
            <br />
            Responsabilizar-se a não destruir, alterar, inutilizar ou de
            qualquer outra forma danificar os Conteúdos encontrados na
            Plataforma ou introduzir programas, vírus, macros, applets,
            controles Activex ou qualquer outro dispositivo lógico ou sequência
            de caracteres que causem ou possam provocar qualquer tipo de
            alteração na Plataforma, nos sistemas de informação da VALEMOBI ou
            de terceiros.
          </li>
          <li>
            <span class="fw-bold">Dos Equipamentos e Conexões Adequadas:</span>
            Observar e garantir hardware, software e conexões de Internet
            compatíveis para a adequada utilização e desempenho da Plataforma,
            sendo o único e exclusivo responsável por qualquer impacto negativo
            do uso de equipamentos e/ou conexões inadequados.
            <br />
            <br />
            Reconhecer que a Plataforma utiliza a rede mundial de computadores,
            (“Internet”), e que "toda a comunicação através da rede mundial de
            computadores está sujeita a interrupções ou atrasos, podendo impedir
            ou prejudicar o envio de ordens ou a recepção de informações
            atualizadas". Adotar medidas de segurança aplicáveis aos
            equipamentos utilizados, visando proteger-se de ataques
            cibernéticos, invasões e fraudes via internet.
          </li>
          <li>
            <span class="fw-bold">Das Ordens:</span>
            Concordar e aceitar que as Ordens de compra e venda de títulos e
            valores mobiliários gravadas na Plataforma, serão vinculadas ao
            Order Management System (“OMS”) da Parceira, a qual o Usuário
            garantiu ter cadastro, conta ativa e regular para a realização de
            operações no âmbito do mercado de capitais, reconhecendo que a
            Parceira será responsável integralmente pela execução, intermediação
            e liquidação física e financeira das ordens efetivadas.
            <br />
            <br />
            Compreender e concordar que a operação da Plataforma, como o módulo
            de Custódia, Ordens e Market Data dependem das condições dos
            equipamentos e conexões de Internet do Usuário, conforme item 4.4,
            assim como da disponibilidade e estabilidade do OMS da Parceira,
            sendo certo que, considerando os fatores críticos descritos, o
            Usuário deve reconhecer que a Valemobi não tem como garantir a
            funcionalidade completa da Plataforma, principalmente, mas não se
            limitando, as funcionalidades de automatização de estratégias de
            Ordens.
            <br />
            <br />
            Reconhecer que as funcionalidades de automatização de estratégias de
            Ordens disponíveis na Plataforma são meras ferramentas de auxílio ao
            módulo de Ordens, não sendo destinadas a minimizar, impedir ou
            limitar prejuízos ou majorar lucros, sendo certo que, dadas as
            condições críticas descritas acima, o Usuário concorda que não há
            garantia de realização das Ordens cadastradas.
            <br />
            <br />
            Concordar e conhecer que há outros canais de responsabilidade
            integral da Parceira para o envio de Ordens de compra e venda
            disponíveis, além da Plataforma, sendo que caso exista dificuldade
            ou indisponibilidade desta, o Usuário se compromete a utilizar
            outros canais da Parceira.
          </li>
          <li>
            <span class="fw-bold">Das Versões da Plataforma:</span>
            Conceder o direito à VALEMOBI para baixar e instalar atualizações e
            recursos adicionais para melhorá-la, aprimorá-la e desenvolvê-la.
            <br />
            Comprometer-se a manter a versão da Plataforma atualizada, para o
            seu correto funcionamento e operacionalização.
          </li>
          <li>
            <span class="fw-bold">Do direito autoral e de propriedade: </span
            >Reconhecer que a VALEMOBI é legítima e exclusiva proprietária e
            titular de todos os direitos autorais e demais direitos de
            propriedade intelectual que incidem sobre a Plataforma.
            <br />
            <br />
            Comprometer-se a não excluir, manipular ou de qualquer forma alterar
            os direitos autorais e outros dados de identificação da reserva de
            direitos da VALEMOBI ou de seus titulares, das impressões digitais
            e/ou identificadores digitais, ou de qualquer outro meio técnico
            estabelecido para o seu reconhecimento.
          </li>
          <li>
            <span class="fw-bold">
              Da autorização e consentimento para coleta e uso de dados:
            </span>
            Autoriza a VALEMOBI a coletar, usar, armazenar e tratar os dados
            pessoais, dados das ordens, bem como os dados do equipamento, link
            de internet, bem como outros dados que se façam necessários, visando
            a garantir o controle dos dados, integridade da utilização da
            Plataforma, e segurança das informações e do Usuário.
          </li>
        </ol>
      </li>

      <li class="mb-5 w-100 text-left">
        <span class="fw-bold">RESPONSABILIDADES E LIMITAÇOES DA VALEMOBI</span>
        <ol>
          <li>
            A VALEMOBI informa que a Plataforma está em contínuo desenvolvimento
            e pode conter erros e
            <span class="fst-italic">bugs</span>, por isso a sua licença de uso
            é fornecida “no estado em que se encontra”.
          </li>
          <li>
            A VALEMOBI não garante, declara, nem assegura que a Plataforma
            esteja livre de perda, interrupção, ataques, vírus, interferência,
            pirataria ou outra invasão de segurança e isenta-se de qualquer
            responsabilidade em relação a essas questões.
          </li>
          <li>
            Em hipótese alguma a VALEMOBI, bem como seus diretores, executivos,
            funcionários, afiliadas, agentes, contratados ou licenciadores
            responsabilizar-se-ão por perdas ou danos causados pelo uso da
            Plataforma.
          </li>
          <li>
            A VALEMOBI não será responsável por qualquer dano, incidental,
            punitivo, indireto ou consequencial, incluindo, mas não se
            limitando, a danos por lucros cessantes, interrupção ou atraso nas
            negociações de ativos ou qualquer outra perda financeira e/ou de
            outras modalidades dos Usuários, inclusive por qualquer operação
            realizada no Módulo de Simulação.
          </li>
          <li>
            A VALEMOBI não será responsável pelos riscos de negociações, cotação
            de ativos, atualizações de ordens e custódias que serão
            disponibilizadas na Plataforma, entendendo que atrasos, incidentes e
            inconsistência poderão ocorrer por fatores externos, inclusive por
            falhas nos equipamentos do Usuário, conexões de Internet,
            informações incorretas oriundas de outros sistemas, como da B3 e
            outros fornecedores, lentidão de data centers que enviam Market
            Data, entre outros.
          </li>
          <li>
            A VALEMOBI não será responsabilizada em nenhuma hipótese por danos
            pessoais, financeiros ou qualquer prejuízo incidental, especial,
            indireto ou consequente, incluindo, sem limitação, lucros cessantes,
            dano moral, dano reputacional, sanções pecuniárias, corrupção ou
            perda de dados, falha de transmissão ou recepção de dados, não
            continuidade do negócio ou qualquer outro prejuízo ou perda
            comercial, ou qualquer outro motivo decorrente ou relacionado ao uso
            ou inabilidade da Plataforma.
          </li>
        </ol>
      </li>

      <li class="mb-5 w-100 text-left">
        <span class="fw-bold">
          RESPONSABILIDADES E DECLARAÇÕES DO USUARIO
        </span>
        <ol>
          <li>
            O Usuário reconhece que a Valemobi é uma mera plataforma de
            distribuição de Market Data e cadastro de Ordens, facilitando a
            negociação e relacionamento entre Usuários, Parceiras e fornecedores
            de Market Data, e que, portanto, a relação entre a Valemobi e o
            Usuário não se sujeita as leis de direito do consumidor.
          </li>
          <li>
            O Usuário reconhece que a Valemobi não tem ingerência sobre qualquer
            conteúdo, matérias, dados de terceiros veiculados na Plataforma.
          </li>
          <li>
            O Usuário declara estar de acordo de que a VALEMOBI não é
            responsável por eventuais erros e
            <span class="fst-italic">bugs</span> de qualquer natureza no
            fornecimento ou utilização da Plataforma, não sendo responsável por
            qualquer prejuízo causado ao Usuário.
          </li>
          <li>
            O Usuário se responsabiliza integralmente por alterações ilícitas na
            Plataforma, ou por terceiros vinculados a ele, restringindo-se a
            utilizar a Plataforma com conhecimento do mercado de capitais, e
            preceitos éticos, morais e legais.
          </li>
          <li>
            O Usuário se responsabiliza única e exclusivamente por defeitos do
            hardware utilizado para acesso à Plataforma, bem como de softwares
            de terceiros que possam gerar risco a utilização da Plataforma, ou a
            segurança, inclusive de dados. O Usuário se responsabiliza também
            pela qualidade, velocidade e estabilidade da conexão de Internet que
            se utiliza para a operação da Plataforma.
          </li>
          <li>
            O Usuário será integralmente responsável pelas Ordens de compra e
            venda que forem gravadas na Plataforma, sejam provenientes de
            análise do próprio Usuário,o, ou de analista de investimentos por
            ele consultado, não havendo nenhuma participação da VALEMOBI,
            VALEMOBI, inclusive relativas as Ordens gravadas utilizando-se de
            ferramentas de auxílio para automatização de negociações. O Usuário
            reconhece que a VALEMOBI não é responsável pelo acompanhamento,
            verificação e correção, se assim for necessário, das ordens
            realizadas e/ou não realizadas pela Plataforma, principalmente
            através utilização das ferramentas de automatização de negociações,
            sendo o Usuário o único responsável por essas ações perante sua
            Parceira.
          </li>
          <li>
            O Usuário declara estar ciente de que, eventualmente, as Ordens
            poderão ser afetadas pelo fenômeno de splippage ou de encilhamento,
            e que em situações excepcionais de mercado, as Ordens poderão ser
            executadas por um preço diferente ao qual ela foi cadastrada.
          </li>
          <li>
            O Usuário declara estar ciente e ser o único responsável pelos
            riscos de mercado (variações dos valores de ativos e riscos de
            liquidez) no que tange negociações de ativos e atualizações de
            ordens disponíveis.
          </li>
          <li>
            O Usuário declara conhecer o funcionamento, bem como se
            responsabiliza pelos riscos associados, das operações no mercado de
            capitais, bem como das obrigações do Usuário como investidor perante
            os órgãos reguladores e autorreguladores, assumindo total
            responsabilidade por seus atos, e pela utilização da Plataforma,
            confirmando para todos os devidos fins do direito, que suas ações
            são decisões pessoais, baseadas também em outras fontes de
            informações, dados, análises ou relatórios, além dos pesquisados ou
            consultados na Plataforma.
          </li>
          <li>
            O Usuário responsabiliza-se, única e exclusivamente, pelos lucros e
            prejuízos, resultantes do envio de ordem de compra e venda, na
            Plataforma, através da livre e espontânea tomada de decisões, bem
            como por possíveis danos diretos e indiretos originados do envio de
            ordem de compra e venda.
          </li>
        </ol>
      </li>

      <li class="mb-5 w-100 text-left">
        <span class="fw-bold">ANTICORRUPÇÃO</span>
        <ol id="l9">
          <li>
            A VALEMOBI e VOCÊ declaram conhecer as normas de prevenção à
            corrupção previstas na legislação brasileira, dentre elas o Código
            Penal Brasileiro, a Lei de Improbidade Administrativa (Lei nº
            8.429/1992) e a Lei nº 12.846/2013 e seus regulamentos (em conjunto,
            “Leis Anticorrupção”) e se comprometem a cumpri-las fielmente, por
            si e por seus sócios, administradores e colaboradores. Sem prejuízo
            da obrigação de cumprimento das disposições de seus respectivos
            códigos internos, ambas as Partes desde já se obrigam a, no
            exercício dos direitos e obrigações previstos neste Contrato e no
            cumprimento de qualquer uma de suas disposições: (i) não dar,
            oferecer ou prometer qualquer bem de valor ou vantagem de qualquer
            natureza a agentes públicos ou a pessoas a eles relacionadas ou
            ainda quaisquer outras pessoas, empresas e/ou entidades privadas,
            com o objetivo de obter vantagem indevida, influenciar ato ou
            decisão ou direcionar negócios ilicitamente e (ii) adotar as
            melhores práticas de monitoramento e verificação do cumprimento das
            Leis Anticorrupção, com o objetivo de prevenir atos de corrupção,
            fraude, práticas ilícitas ou lavagem de dinheiro por seus sócios,
            administradores e colaboradores ou terceiros por ela contratados.
          </li>
          <li>
            A violação de qualquer das obrigações previstas nesta cláusula é
            considerada infração grave e será causa para a rescisão unilateral
            deste Contrato, sem prejuízo da cobrança das perdas e danos causados
            à Parte inocente.
          </li>
        </ol>
      </li>

      <li class="mb-5 w-100 text-left">
        <span class="fw-bold">TRATAMENTO DE DADOS</span>
        <ol>
          <li>
            A VALEMOBI e VOCÊ concordam que o tratamento dos dados
            fornecidos/recebidos por qualquer das Partes pela outra Parte em
            razão deste Contrato deverá ser realizado em consonância à
            legislação brasileira, inclusive no tocante às diretrizes previstas
            na Lei nº 13.709/2018 (“Lei Geral de Proteção de Dados”), Marco
            Civil da Internet (Lei nº 12.965/2014) e alterações posteriores, e
            quaisquer outras leis e regulamentos em relação ao tratamento de
            dados pessoais e privacidade aplicáveis, sendo certo que deverão ser
            verificados e respeitados todos os tipos de dados previstos na
            legislação aplicável, conforme cada caso concreto em questão
            (inclusive com condutas para anonimizar os dados, caso exigido por
            lei e/ou se fizer necessário).
          </li>
          <li>
            A VALEMOBI irá coletar os dados pessoais fornecidos, para o uso da
            Plataforma, bem como os dados de ordens, dados de integração, e
            demais dados que garantam o rastreamento do uso da Plataforma, sua
            qualidade, monitoramento de tempo, equipamentos, link de internet,
            para uso interno, de seus parceiros, e que atenda a legislação
            vigente, e a otimização da experiência do Usuário.
          </li>
          <li>
            A VALEMOBI armazena, os dados coletados, em datacenter próprio,
            obedecendo às boas práticas de segurança recomendadas pelo mercado,
            por prazo nunca superior ao admitido pela legislação vigente.
          </li>
        </ol>
      </li>

      <li class="mb-5 w-100 text-left">
        <span class="fw-bold">LEGISLAÇÃO APLICÁVEL E JURISDIÇÃO</span>
        <ol>
          <li>
            O presente Contrato prevalecerá sobre toda e qualquer negociação
            verbal ou documento eventualmente trocado entre as Partes. As
            condições expressas no presente Contrato somente poderão ser
            alteradas mediante acordo escrito entre as Partes.
          </li>
          <li>
            A eventual tolerância, pela VALEMOBI, da inobservância ou inexecução
            de quaisquer Cláusulas ou condições deste Contrato, constituirá mera
            liberalidade e não será considerada novação, tampouco renúncia ao
            direito de exigir o pleno cumprimento das obrigações ora pactuadas.
          </li>
          <li>
            A declaração de nulidade de qualquer das Cláusulas contidas no
            presente Contrato não implicará a nulidade das demais.
          </li>
          <li>
            Todas as controvérsias ou reclamações decorrentes da interpretação
            ou execução destes Termos de Responsabilidades e Obrigações do
            Usuário da Plataforma serão regidas pela legislação brasileira.
          </li>
        </ol>
      </li>
    </ol>
    <p class="text-left fs-5">
      Estes Termos de Uso foram atualizados em 20/10/2023.
    </p>
  </div>
</div>
