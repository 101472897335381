import { ComponentRef } from '@angular/core';
import { RocketCreateComponentService } from '@shared/rocket-components/services';
import { setPositionContextMenu } from 'src/app/utils/utils.functions';
import { Observable, Subject } from 'rxjs';
import { StockTableViewsContextComponent } from './components/stock-table-view-context/stock-table-view-context.component';
import { IRowData } from '../stock-table/interfaces/stock-table.interfaces';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { deepClone } from '../../rocket-components/utils/functions';

export interface IStockListConfigEvent {
  type: string;
  enable: boolean;
}

export class StockTableViewContext extends ReadStreamBase {
  private configComponent!: StockTableViewsContextComponent;
  private element!: HTMLElement;
  private componentRef!: ComponentRef<StockTableViewsContextComponent>;
  public ref!: string;
  public stockFocused!: IRowData | null;
  public displayRemoveIcon: boolean = false;
  public isMoversList: boolean = false;
  public listId: number = 0;

  private configUpdate$: Subject<IStockListConfigEvent> =
    new Subject<IStockListConfigEvent>();

  get onPreferencesChange$(): Observable<IStockListConfigEvent> {
    return this.configUpdate$.asObservable();
  }

  constructor(protected _createComponent: RocketCreateComponentService) {
    super();
  }

  protected openConfig = (event: any): void => {
    const stock = deepClone(this.stockFocused);
    const infos = {
      innerHeight: event.view.innerHeight,
      innerWidth: event.view.innerWidth,
      pageY: event.pageY,
      pageX: event.pageX,
      height: 144,
      width: 200,
    };
    this._displayConfig({
      show: true,
      stock: stock,
      position: setPositionContextMenu(infos),
      callback: this.onChangePreferences,
      removeStockIsEnable: this.displayRemoveIcon,
      isMoversList: this.isMoversList,
    });
  };

  private _displayConfig(value: any): void {
    this._removeBeforeInit();
    const create = this._createComponent.create(
      StockTableViewsContextComponent
    );
    this.componentRef = create.componentRef;
    this.configComponent = this.componentRef.instance;
    this.element = create.rootNodeElement as HTMLElement;
    this._bindValuesComponent(value);
    this.configComponent.onClose$.subscribe(this.destroyComponent);
  }

  private _bindValuesComponent(value: any) {
    this.configComponent.position = {
      left: `${value.position.left}px`,
      top: `${value.position.top}px`,
    };
    this.configComponent.display = value.show;
    this.configComponent.componentRef = this.ref;
    this.configComponent.stock = value.stock;
    this.configComponent.removeStockIsEnable = value.removeStockIsEnable;
    this.configComponent.callback = value.callback;
    this.configComponent.isMoversList = value.isMoversList;
    this.configComponent.stockListId = this.listId;
    document.body.append(this.element);
  }

  private _removeBeforeInit() {
    const findAll = document.querySelectorAll('app-stock-table-view-context');
    if (findAll) findAll.forEach((elem) => elem.remove());
  }

  private destroyComponent = (): void => {
    this.stockFocused = null;
    this.element.remove();
    this._createComponent.destroy(this.componentRef);
  };

  public onChangePreferences = (params: IStockListConfigEvent): void => {
    this.configUpdate$.next(params);
  };
}
