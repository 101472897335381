import { AbstractControl, ValidationErrors } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

export function validatePhoneCode(
  number: any,
  code: any
): ValidationErrors | null {
  try {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneNumberUtil.parse(number, code.toUpperCase());
    const isValid = phoneNumberUtil.isValidNumber(parsedNumber);
    if (!isValid) {
      return { phoneNumber: 'Invalid phone number' };
    }
  } catch (e) {
    return { phoneNumber: 'Invalid phone number' };
  }
  return null;
}

export function internationalPhoneValidator(control: AbstractControl) {
  const countryCodeControl = control.root.get('phone_country_code');
  let phone = control.value;
  if (phone) phone = phone.replace(/\D/g, '');
  return validatePhoneCode(phone, countryCodeControl?.value?.country ?? 'BR');
}

//função antiga que só valida telefones com ddi +55 (BR)
export function phoneValidator(
  control: AbstractControl
): ValidationErrors | null {
  const phoneNumber = control.value;
  return validatePhoneCode(phoneNumber, 'BR');
}

//usado para pegar campo do input do form e o country iso2 para colocar em uma só string
export function getPhoneCountryValue(value: string, country: string = 'br') {
  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  const parsedNumber = phoneNumberUtil.parse(value, country.toUpperCase());
  return `${parsedNumber.getCountryCode()}${parsedNumber.getNationalNumber()}`;
}
