import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { periodsSelector } from '../../consts/filters';
import { FlaSelectComponent } from '@shared/rocket-components/components';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ranking-broker-period',
  templateUrl: './ranking-broker-period.component.html',
  styleUrls: ['./ranking-broker-period.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RankingBrokerPeriodComponent {
  @ViewChild('select') select!: FlaSelectComponent;
  public periodsSelector: any = periodsSelector;
  form!: FormGroup;
  @Output() changePeriod = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.form = this.formBuilder.group({
      period: new FormControl('today'),
    });
  }

  ngAfterView() {
    this.select.writeValue(this.periodsSelector[0].label);
    this.cdr.detectChanges();
  }

  public handlePeriod(event: any) {
    this.changePeriod.emit(event);
    this.cdr.detectChanges();
  }
}
