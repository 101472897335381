import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketSummaryComponent } from './market-summary.component';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import {
  FlaCardModule,
  FlaIconModule,
  FlaTabModule,
  FlaTagModule,
  RocketButtonModule,
  RocketModalModule,
} from '@shared/rocket-components/components';
import { MiniCardsListComponent } from './parts/mini-cards-list/mini-cards-list.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HighLowStocksComponent } from './parts/high-low-stocks/high-low-stocks.component';
import { NewsModalModule } from './parts/news-modal/news-modal.module';
import { DocumentsModalModule } from '@shared/documents-modal/documents-modal.module';
import { GeneralModalComponent } from './parts/general-modal/general-modal.component';
import { ListStockCardsComponent } from './parts/list-stock-cards/list-stock-cards.component';
import { MarketSummaryService } from './market-summary.service';
import { StockTableViewsModule } from '../stock-table-views/stock-table-views.module';
import { NewsCarouselComponent } from './parts/news-carousel/news-carousel.component';
import { NewsCardComponent } from './parts/news-card/news-card.component';
import { MarketSummaryHeaderComponent } from './parts/market-summary-header/market-summary-header.component';
import { IndexesModalComponent } from './parts/indexes-modal/indexes-modal.component';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { LoadingModule } from '../loading/loading.module';
import { FlaBigNumberModule } from '@shared/rocket-components/pipes/big-number';

@NgModule({
  imports: [
    CommonModule,
    FlaWorkSpaceModule,
    FlaIconModule,
    FlaCardModule,
    ScrollingModule,
    FlaTagModule,
    RocketModalModule,
    RocketButtonModule,
    NewsModalModule,
    DocumentsModalModule,
    FlaTabModule,
    StockTableViewsModule,
    TooltipsModule,
    LoadingModule,
    FlaBigNumberModule,
    LoadingModule,
  ],
  declarations: [
    MarketSummaryComponent,
    MarketSummaryHeaderComponent,
    MiniCardsListComponent,
    HighLowStocksComponent,
    NewsCarouselComponent,
    NewsCardComponent,
    GeneralModalComponent,
    IndexesModalComponent,
    ListStockCardsComponent,
  ],
  exports: [
    MarketSummaryComponent,
    MarketSummaryHeaderComponent,
    MiniCardsListComponent,
    HighLowStocksComponent,
    NewsCarouselComponent,
    NewsCardComponent,
    GeneralModalComponent,
    IndexesModalComponent,
    ListStockCardsComponent,
  ],
  providers: [MarketSummaryService],
})
export class MarketSummaryModule {}
