export const ACCOUNT_SELECT_TOOLTIP = {
  DAYTRADE_OFF: `
  <div>
    <p class="fs-6 mb-1 fw-bold" style="line-height: 17px;">Modo {Swing}</p>
    <span class="fs-7 fw-normal" style="line-height: 17px;">
    No modo {Swing}, os componentes exibirão as posições e ordens {Swing}. A ação dos botões <span class="fw-bold">Zerar</span> e <span class="fw-bold">Inverter</span>, bem como a exibição de posições serão apenas do modo {Swing}.
    </span>
  </div>
  `,
  DAYTRADE_ON: `
  <div>
    <p class="fs-6 mb-1 fw-bold" style="line-height: 17px;">Modo {DayTrade}</p>
    <span class="fs-7 fw-normal" style="line-height: 17px;">
    No modo {DayTrade}, os componentes exibirão as posições intradia. A ação dos botões <span class="fw-bold">Zerar</span> e <span class="fw-bold">Inverter</span>, bem como a exibição de posições e ordens serão apenas do modo {DayTrade}.
    </span>
  </div>
  `
};

export const STRATEGY_TOOLTIP = {
  STRATEGY_OFF: `
  <div>
    <p class="fs-6 mb-1 fw-bold" style="line-height: 17px;">Ativar Modo Estratégia</p>
    <span class="fs-7 fw-normal" style="line-height: 17px;">
      Ao ativar o Modo "Estratégia" as ordens seguirão a estratégia que estiver ativa no momento.
    </span>
  </div>
`,
  STRATEGY_ON: `
  <div>
    <p class="fs-6 mb-1 fw-bold" style="line-height: 17px;">Desativar Modo Estratégia</p>
    <span class="fs-7 fw-normal" style="line-height: 17px;">
      Ao desativar o Modo "Estratégia" as ordens serão enviadas normalmente sem nenhuma estratégia associada.
    </span>
  </div>
`,
};
