import {
  Component,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  AfterViewInit,
} from '@angular/core';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { StockService } from '@services/api/trademap/v1/stock.service';
import { HomeService } from '@modules/home/service/home.service';
import { StockTableHelper } from './stock-table.helper';
import {
  GridIColumnVisibleInService,
  GridIReferenceService,
  GridISelectRow,
  IStockTableRemoveRow,
  RocketGridService,
} from '@shared/rocket-grid';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { StockListStettingsService } from '@services/api/nitro-ws/v1/stock-list-stettings.service';
import { AddStock, IUpdateStocksInList } from './configurations-list/add-stock';
import { StockListService } from '@shared/services/api/trademap/V10/stock-list.service';
import { UpdatesList } from './configurations-list/updates-list';
import { UpdatesConfig } from './configurations-list/updates-config';
import { GlobalSelectedStockSubscription } from '@services/core/subscription/global-stock.subscription';
import { HEADERS_AUCTION_IGNORE } from './constants/headers.constants';
import { AuctionChannel } from '@shared/channel/auction.channel';
import { MoversChannel } from '@shared/channel/movers.channel';
import {
  IAllStockListSimple,
  IListStockConfig,
  IListStockDB,
  ISearchStock,
  IWorkSpaceComponet,
} from 'src/app/core/interface';
import { RocketModalService } from '@shared/rocket-components/components/index';
import { RocketCreateComponentService } from '@shared/rocket-components/services';
import { IntrojsService } from '@core/introjs/introjs.service';
import {
  DragService,
  rowDragText,
} from '@shared/rocket-components/services/ag-grid/drag.service';
import { isAuction } from '@shared/constants/general.contant';
import { GlobalStockListSubscription } from '@shared/services/core/subscription/global-stock-list.subscription';
import { Subject, Subscription, timer } from 'rxjs';
import { deepClone } from '@shared/rocket-components/utils';
import {
  descendingSort,
  isNullOrUndefined,
  isRowSelectedStock,
} from 'src/app/utils/utils.functions';
import { ToastService } from '@shared/services';
import {
  IRowData,
  IStockListItemsRow,
} from './interfaces/stock-table.interfaces';
import { ListStocksService } from '@shared/services/core/list-stocks/list-stocks.service';
import { TAddStock } from '@shared/services/core/list-stocks/types';
import { ProcessListStockService } from '@shared/services/core/stock-list/process-list-stock.service';
import { ExportDataConverterService } from '@shared/services/export-data-converter.service';
import {
  PRESET_LIST_ID,
  isAuctionList,
  isMoversList,
  isPresetListID,
} from './constants/stock-table.constants';
import { ActivatedRoute } from '@angular/router';
import { WorkSpaceConfigs } from '@core/workspace';
import { IStockTableIndex } from './interfaces/movers-index.interface';
import { TreemapService } from '@shared/services/api/trademap/V6/treemap.service';
import { RocketStreamRead, RxEvent } from '@shared/channel/rx-event';
import { ContextMenuService } from '../popup-root/context-menu.service';
import { StockTableContextMenuEvent } from './grid-config/stock-table-context-menu.component';
import { isWebViewContext } from 'src/app/desktop/integration.utils';

@Component({
  selector: 'app-stock-table',
  templateUrl: './stock-table.component.html',
  styleUrls: ['./stock-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockTableComponent
  extends StockTableHelper
  implements AfterViewInit, OnDestroy
{
  @Input() set refComponent(refComponent: string) {
    if (refComponent) {
      this.refComp = refComponent;
    }
  }
  @Input() set componentInfo(componentInfo: IWorkSpaceComponet) {
    if (componentInfo) this.component = componentInfo;
  }
  @Input() height!: number;
  @Input() width!: number;
  @Input() link!: boolean;
  @Input() focused: boolean = false;

  list: IListStockDB[] = [];
  hashList: any = {};
  // WITHOUT LIST VARIABLES
  withoutList: boolean = false;
  openCreateList: boolean = false;
  isIndex: boolean = false;
  isDesktop = false;

  private onGlobalStockChangeStream!: RocketStreamRead | undefined;
  private _destroyCreateList = new Subject<void>();
  private stockListChanges$ = new Subscription();
  private _contextMenuListener$ = new Subscription();
  private _destroy$ = new Subject<void>();
  private checkExistLists$!: Subscription;
  rowDragText = rowDragText;
  dropZoneParams!: any;

  get componentMetadata() {
    return this.component.metadata;
  }

  get metadata() {
    return (this.listStockInfo.configList as IListStockConfig).metadata;
  }

  get listMetadata() {
    return this.component.metadata.listStock;
  }

  constructor(
    private _globalStockList: GlobalStockListSubscription,
    protected override stockListService: StockListService,
    protected override rocketGridService: RocketGridService,
    protected override _rocketModalService: RocketModalService,
    protected override stockService: StockService,
    protected override homeService: HomeService,
    protected override createComponent: RocketCreateComponentService,
    protected override quoteChannel: QuoteChannel,
    protected override stockListStettingsService: StockListStettingsService,
    protected override auctionChannel: AuctionChannel,
    protected override moversChannel: MoversChannel,
    protected override introJSService: IntrojsService,
    protected override cdr: ChangeDetectorRef,
    private globalStock: GlobalSelectedStockSubscription,
    listStocksService: ListStocksService,
    private dragService: DragService,
    private toastService: ToastService,
    private _processListStockService: ProcessListStockService,
    exporter: ExportDataConverterService,
    activatedRoute: ActivatedRoute,
    workspaceConfig: WorkSpaceConfigs,
    private _treemapService: TreemapService,
    private rxEvent: RxEvent,
    protected override contextMenuService: ContextMenuService
  ) {
    super(
      rocketGridService,
      _rocketModalService,
      stockService,
      homeService,
      createComponent,
      quoteChannel,
      stockListStettingsService,
      auctionChannel,
      moversChannel,
      introJSService,
      listStocksService,
      cdr,
      stockListService,
      exporter,
      workspaceConfig,
      activatedRoute,
      contextMenuService
    );

    this.isDesktop = isWebViewContext();
    this.updateGlobalStockSubject
      .pipe(debounceTime(250))
      .subscribe(this._updateGlobalStock);
  }

  protected initComponent(component: any): void {
    if (component) this.component = component;
    this.checkExistLists$ = this._processListStockService
      .checkExistLists()
      .pipe(
        filter((loaded) => loaded),
        takeUntil(this._destroy$),
        debounceTime(100)
      )
      .subscribe(() => this.componentInit());

    this.height =
      this.component.metadata.componentResize?.height ??
      parseInt(this.component.metadata.layout.height);
    this.width =
      this.component.metadata.componentResize?.width ??
      parseInt(this.component.metadata.layout.width);

    this._contextMenuListener$ = this.contextMenuService
      .listenActionEvent<StockTableContextMenuEvent>(this.refComp)
      .subscribe((payload) => {
        const { action } = payload;
        switch (action) {
          case StockTableContextMenuEvent.Auction:
            this.onChangeAuctions();
            break;
          case StockTableContextMenuEvent.Export:
            this.exportData();
            break;
          case StockTableContextMenuEvent.Helper:
            this.handleComponentTour();
            break;
          case StockTableContextMenuEvent.SetInvisibleColumn:
            this.setInvisibleColumn();
            break;
          case StockTableContextMenuEvent.AddStock:
            this.addStockOrCreateList();
            break;
          case StockTableContextMenuEvent.RemoveStock:
            this.removeStock();
            break;
          case StockTableContextMenuEvent.ShowEdit:
            this.editGrid();
            break;
          case StockTableContextMenuEvent.ConfigColumns:
            this.configColumns();
            break;
          case StockTableContextMenuEvent.ResetHeaders:
            this.resetHeaders();
            break;
        }
      });
  }

  ngAfterViewInit() {
    this.dropZoneParams = this.dragService.makeDragZoneParams();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.isGridStockList) this.gridApi && this.gridApi.destroy();
    this._gridApiInitialized = false;
    this.unSubscribeCheetah();
    this.checkExistLists$ && this.checkExistLists$.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.stockListChanges$.unsubscribe();
    this.moversChannel$.unsubscribe();
    this.configVisibleOrInvisibleColumns$.unsubscribe();
    this.forceDisplayContexMenu$.unsubscribe();
    this.getUpdateConfigList$.unsubscribe();
    this.updateGlobalStockSubject.unsubscribe();
    this.hideTableHeaders.unsubscribe();
    this._destroy$.next();
    this._destroy$.complete();
    this.setSnapshotOnChangeView.unsubscribe();
    this.clearStocksSubject.unsubscribe();
    this._contextMenuListener$.unsubscribe();
    this.onGlobalStockChangeStream && this.onGlobalStockChangeStream.close();
  }

  private componentInit() {
    this.componentId = this.component.id;
    this.idListSelect = this.listMetadata?.idList ?? 0;
    this.isNotListPersonal = Boolean(this.listMetadata?.isNotListPersonal);
    this.setIndexSelected(this.listMetadata);
    this.setInitialPeriod(this.listMetadata?.moversPeriodV2);
    this.verifyStockTableType();
    this.makeStockTableCheetah();
    this.contextMenuInitialSettings();
    this._initGridObservables();
    this._initGlobalStockObservable();
    this._initListObservables();
    this.getAllLists({ isNewList: false }, true);
    this.globalStockSubject(undefined);

    if (
      this.keyHeader &&
      !this.isListAuction &&
      HEADERS_AUCTION_IGNORE.includes(this.keyHeader)
    ) {
      this.keyHeader = null;
      return;
    }
    this.cdr.detectChanges();
  }

  private globalStockSubject(stock: ISearchStock | undefined) {
    if (
      this.updateGlobalStockSubject &&
      !this.updateGlobalStockSubject.closed
    ) {
      this.updateGlobalStockSubject.next(stock);
    }
  }

  private _updateGlobalStock = (stock?: ISearchStock): void => {
    const stockSelected = stock
      ? stock
      : this.globalStock.getGlobalStockSelected();
    this.stock = stockSelected;
    const cdStock = stockSelected.is_synonymous
      ? stockSelected?.synonymous_nickname
      : stockSelected.cd_stock;
    this.globalStockInfos = {
      cd_stock: cdStock,
      synonymous_nickname: stockSelected.synonymous_nickname,
      cd_stock_order: stockSelected.cd_stock_order,
      synonymous_related: stockSelected.synonymous_related,
      id_exchange: stockSelected.id_exchange,
    };
    this.isIndex = stockSelected.ds_segment?.trim() === 'Índices';
    this.globalItem = `${cdStock}:${stockSelected.id_exchange}`;
    this.cdr.detectChanges();
    if (this.isGridStockList) this.updateSelectedStockGrid();
  };

  onListSelected(list: IListStockDB): void {
    if (
      !list ||
      (list.idStockList === this.listStockInfo?.idStockList &&
        list.idStockList === this.idListSelect &&
        !this.isIndexList)
    )
      return;
    this.listSelected = list.list;
    this.startList(list);
  }

  private _initGridObservables(): void {
    this.rocketGridService.configColumns
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (config: GridIReferenceService) =>
          config.refComponent === this.refComp &&
          config.value &&
          this.openConfigHeaders()
      );
    this.rocketGridService.removeRow
      .pipe(
        takeUntil(this.destroy$),
        filter(
          (remove) =>
            remove.refComponent === this.refComp && remove.rowIndex > -1
        )
      )
      .subscribe((remove: GridISelectRow) => {
        this.removeRow(remove);
      });
    this.rocketGridService.removeRowFromSameLists
      .pipe(
        takeUntil(this.destroy$),
        filter((stockToRemove) => {
          return (
            stockToRemove.idList === this.idListSelect &&
            !isNullOrUndefined(stockToRemove.rowData)
          );
        })
      )
      .subscribe((remove: IStockTableRemoveRow) => {
        this.removeRow(<any>remove);
      });
    this.rocketGridService.invisibleColumn
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (invisibleColumn: GridIColumnVisibleInService) =>
          invisibleColumn.refComponent === this.refComp &&
          this.configVisibleOrInvisibleSingleColumn(invisibleColumn)
      );
    this.rocketGridService.updateStocksFromSameLists
      .pipe(
        takeUntil(this.destroy$),
        filter((params) => params.idList === this.idListSelect),
        tap((params) => this.unSubscribeCheetahQuote(params.stocks))
      )
      .subscribe((params) => {
        this.setStocksAtDictList('idRow', params.stocks);
        this.subscribeCheetahQuote();
      });
    this.rocketGridService.moversIndex$
      .pipe(
        takeUntil(this.destroy$),
        filter((params) => params.refComponent === this.refComp)
      )
      .subscribe((params) => this.updateListIndex(params.index));
    this.listStocksService.addItemStockList$
      .pipe(
        filter((params) => params.idList === this.idListSelect),
        takeUntil(this.destroy$)
      )
      .subscribe(this.onAddStocks);
    this.rocketGridService.moversPeriod$
      .pipe(
        takeUntil(this.destroy$),
        filter((params) => params.refComponent === this.refComp)
      )
      .subscribe((params) => this.updateMoversPeriod(params.period));
    this.rocketGridService.resetTableHeaders$
      .pipe(
        takeUntil(this.destroy$),
        filter((params) => params.refComponent === this.refComp)
      )
      .subscribe(() => this.resetTableHeaders());
    this.hideTableHeaders
      .pipe(debounceTime(200))
      .subscribe((params) => this.updateDefaultHeaders(params, false));
  }

  private async _initGlobalStockObservable(): Promise<void> {
    this.onGlobalStockChangeStream =
      await this.globalStock.onGlobalStockChange();
    this.readStream(
      this.onGlobalStockChangeStream.stream,
      this.onGlobalStockChange
    );
  }

  private onGlobalStockChange = (stock: ISearchStock) => {
    if (stock?.cd_stock && stock.cd_stock !== this.globalStockInfos.cd_stock) {
      this.globalStockSubject(stock);
    }
  };

  private _initListObservables(): void {
    this.stockListChanges$ = this.listStocksService.stockListChanges.subscribe(
      (list) => this.eventList(list, false)
    );
  }

  onAddStocks = (data: TAddStock): void => {
    const addStock = new AddStock(
      this.stockService,
      this.stockListService,
      this.listStocksService,
      this.toastService
    );

    addStock.setNewStock(
      this.listStockInfo,
      data.stocks,
      this.refComp,
      this._updateStocksAfterAddNew,
      data.rowIndex,
      data.removed
    );
  };

  private _updateStocksAfterAddNew = (params: IUpdateStocksInList): void => {
    if (params.isError) return;
    this.rocketGridService.setUpdateStocksFromSameLists(
      this.idListSelect,
      deepClone(params.list)
    );
  };

  onChangeName(name: string): void {
    this.listStockInfo.list.nm_stock_list = name;
    this.cdr.detectChanges();
  }

  removeRow(remove: GridISelectRow): void {
    this.triggeredEvent();
    this.unsubSomeStocksFromQuoteChannel([{ idRow: remove.rowData.idRow }]);
    const updatesList = new UpdatesList(
      this.listStocksService,
      this.stockListService,
      this.toastService
    );
    updatesList.removeRow(this.listStockInfo, remove);
    this._removeStockFromStockListInfos(remove.rowData);
    if (isAuction(remove.rowData.situacao))
      this.verifyAuctionStocksAfterRemove(remove.rowData);
  }

  private _removeStockFromStockListInfos(row: IStockListItemsRow): void {
    if (!this.isGridStockList) this.stockToRemove = row;
    this.removeStockFromDictList(row.idRow);
    this.rowData = this.getAllStocksAtDictList();
    this.cdr.detectChanges();
  }

  rowMoved(event: { id: string; rowIndex: number }): void {
    if (
      isPresetListID(this.idListSelect) ||
      !this.listStockInfo.stocks ||
      this.listStockInfo.stocks.length <= 1
    ) {
      return;
    }
    this.triggeredEvent();
    const updatesList = new UpdatesList(
      this.listStocksService,
      this.stockListService,
      this.toastService
    );
    const previusStockPositions = this.getAllStocksAtDictList();
    this.listStockInfo.stocks = previusStockPositions;
    const newStocksPositions = updatesList.rowMoved(this.listStockInfo, event);
    this.setStocksAtDictList('idRow', newStocksPositions.stocks);
    this.cdr.detectChanges();
  }

  columnMoved(event: any[]): void {
    if (isPresetListID(this.idListSelect)) return;
    clearTimeout(this.columnMovedDelay);
    this.columnMovedDelay = setTimeout(() => {
      this.triggeredEvent();
      const updatesConfig = new UpdatesConfig(
        this.listStocksService,
        this.stockListStettingsService
      );
      updatesConfig.columnMoved(this.listStockInfo, event);
    }, 500);
  }

  columnResized(event: any): void {
    if (!this.isListAuction && HEADERS_AUCTION_IGNORE.includes(event.colId)) {
      return;
    }
    this.triggeredEvent();
    !this.metadata.headers[event.colId] &&
      (this.metadata.headers[event.colId] = {});
    this.metadata.headers[event.colId].width = event.actualWidth;
    const updatesConfig = new UpdatesConfig(
      this.listStocksService,
      this.stockListStettingsService
    );
    updatesConfig.updateListConfig(this.listStockInfo);
  }

  selectRow = (event: { data: any }): void => {
    if (
      !event?.data ||
      !(this.link || isWebViewContext()) ||
      event.data.idRow === this.globalItem
    )
      return;
    this.globalStock.researchToStandardizeGlobalStock(event.data, true);
  };

  getAllLists(
    data: { isNewList: boolean; idStockList?: number },
    isInitialization: boolean
  ): void {
    if (!this.withoutList && !isInitialization && this.isLoading) return;
    if (this.withoutList && data?.isNewList) {
      this._processListStockService.getStockListAfterCreateNewOne();
      return;
    }
    const hashList = this.listStocksService.allStockLists;
    const hasList = Object.keys(hashList).filter(
      (key) => !isMoversList(parseInt(key))
    ).length;
    if (!hasList) {
      this._handleWithoutListCreated();
      if (isMoversList(this.idListSelect)) this.setSelectedList(false);
      return;
    }
    if (data) {
      this.listSelected = undefined;
      if (!isNullOrUndefined(data.idStockList))
        this.idListSelect = data.idStockList!;
    }
    if (this.withoutList && !this.idListSelect)
      this.idListSelect = parseInt(Object.keys(hashList)[0]);
    this.eventList(hashList, Boolean(data?.isNewList) || this.withoutList);
    if (hasList && this.idListSelect) this.withoutList = false;
  }

  private eventList(hashList: any, isNewList: boolean) {
    const list = Object.values(deepClone(hashList)) as IListStockDB[];
    this.listStocks = list
      .sort((a, b) => descendingSort(a.id, b.id))
      .map((item: IListStockDB) => item.list);
    this.list = list;
    this.hashList = hashList;
    this.cdr.detectChanges();
    if (!this.listSelected || isNewList) this.setSelectedList(isNewList);
  }

  private setSelectedList(isNewList: boolean): void {
    if (!this.listStocks.length && !this.isNotListPersonal) return;
    const selected = this.hashList[`${this.idListSelect}`];
    if (
      !isNewList &&
      ((!selected && this.isNotListPersonal) || this.isNotListPersonal)
    ) {
      if (isPresetListID(this.idListSelect)) {
        if (this.listMetadata?.presetListName)
          this.selectPresetList(this.listMetadata.presetListName);
        else this.setSelectedList(false);
        return;
      }

      let dynamic: any = {};
      Object.keys(this.dynamiclists).forEach(
        (item: string) =>
          this.dynamiclists[item].id === this.idListSelect &&
          (dynamic = this.dynamiclists[item])
      );
      this.selectListNotPersonal(dynamic);
      return;
    }
    this.selectList(selected ?? this.list[0]);
  }

  selectListNotPersonal(item: {
    id: number;
    label: string;
    id_stock_list: number;
    nm_stock_list: string;
  }): void {
    if (!item) return;
    const id = item.id ? item.id : item.id_stock_list;
    if (
      id === this.listSelected?.id_stock_list &&
      !this.isIndexList &&
      !this.dynamicInitialList
    )
      return;
    const name = item.label ? item.label : item.nm_stock_list;
    const list = this._setListInfos(id, name, false, false);
    this.dynamicInitialList = undefined;
    this.listSelected = list;
    this.isIndexList = false;
    this.changeNotListPersonal(list);
    this.link && this._globalStockList.changeGlobalStockList(list);
    this.cdr.detectChanges();
  }

  selectPresetList(name: string): void {
    const list = this._setListInfos(PRESET_LIST_ID, name, false, true);
    this.idListSelect = PRESET_LIST_ID;
    this.listSelected = list;
    this.presetListName = name;
    this.isNotListPersonal = true;
    this.isIndexList = false;
    this.cdr.detectChanges();
    if (this.link) this._globalStockList.changeGlobalStockList(list);
    this.changePresetList(name);
    this.updateMetadataSubject.next();
  }

  selectList(item: IListStockDB): void {
    if (item.list.id_stock_list !== this.listSelected?.id_stock_list) {
      this.listSelected = deepClone(item.list);
      this.listStockSelected = item;
      this.onListSelected(item);
    } else if (item.list.nm_stock_list !== this.listSelected?.nm_stock_list) {
      this.listSelected.nm_stock_list = item.list.nm_stock_list;
    }
    this.link && this._globalStockList.changeGlobalStockList(item.list);
    this.cdr.detectChanges();
  }

  private _setListInfos(
    id: number,
    name: string,
    isPersonal: boolean = true,
    isPreset: boolean = false
  ): IAllStockListSimple {
    return {
      id_stock_list: id,
      nm_stock_list: name,
      isNotListPersonal: !isPersonal,
      isPresetList: isPreset,
      in_visibility: true,
      is_favorite: false,
      seq_priority: 0,
    };
  }

  private updateSelectedStockGrid() {
    if (!this.gridApi) return;
    const itemsToUpdate: any = [];
    this.gridApi.forEachNode((rowNode) => {
      const row = rowNode.data;
      row.isSelected = isRowSelectedStock(
        row.idRow,
        this.globalItem,
        this.cdStockOrder,
        this.synonymousRelated
      );

      itemsToUpdate.push(row);
    });
    this.gridApi.applyTransactionAsync({ update: itemsToUpdate });
  }

  onStockPositionChange(stocks: IRowData[]): void {
    this.listStockInfo.stocks = stocks;
    new UpdatesList(
      this.listStocksService,
      this.stockListService,
      this.toastService
    ).updateListStocks(this.listStockInfo);
    this.rocketGridService.setUpdateStocksFromSameLists(
      this.idListSelect,
      <any>stocks
    );
    this.cdr.detectChanges();
  }

  // WITHOUT LIST FUNCTIONS
  private _handleWithoutListCreated(): void {
    if (!isMoversList(this.idListSelect)) this.idListSelect = 0;
    const list = {
      id_stock_list: 0,
      in_visibility: true,
      is_favorite: false,
      nm_stock_list: 'Sem listas pessoais',
      seq_priority: 0,
      isNotListPersonal: true,
      isPresetList: false,
    };
    this.listSelected = list;
    this.withoutList = true;
  }

  sendEventToOpenCreateListModal(): void {
    this.openCreateList = true;
    this.cdr.detectChanges();
    timer(100)
      .pipe(takeUntil(this._destroyCreateList))
      .subscribe(() => {
        this.openCreateList = false;
        this.cdr.detectChanges();
        this._destroyCreateList.next();
        this._destroyCreateList.complete();
      });
  }

  handleAuctionIndex(index: IStockTableIndex): void {
    if (isAuctionList(this.idListSelect)) {
      this.updateListIndex(index);
      return;
    }
    this.auctionIndex = index;
    this.selectListNotPersonal({
      id: 1000000000,
      label: 'Leilão',
      id_stock_list: 0,
      nm_stock_list: '',
    });
  }

  handleIndexStocks(displayIndexStocks: boolean): void {
    if (displayIndexStocks) {
      this.getStocksByIndex(this.globalStockInfos.cd_stock);
      return;
    }
    this._exitFromIndexList();
  }

  private _exitFromIndexList(): void {
    if (isPresetListID(this.idListSelect)) {
      this.selectPresetList(this.presetListName);
      return;
    }
    if (isMoversList(this.idListSelect) || isAuctionList(this.idListSelect)) {
      const previusListInfos: any = this.listSelected;
      this.selectListNotPersonal(previusListInfos);
      return;
    }
    this.onListSelected(this.listStockSelected);
  }

  getStocksByIndex(index: string): void {
    this.isIndexList = true;
    this.unSubscribeCheetah();
    this.updateTableEmptyMessage(
      `Carregando ativos do índice ${index}, por favor aguarde.`
    );
    this._treemapService.getTreemap(index, 1, true).subscribe({
      next: (stocks) => {
        if (!stocks.children.length) {
          this.rowData = [];
          this.updateTableEmptyMessage(
            `Não foi possível carregar os ativos do índice ${index}, tente novamente.`
          );
          return;
        }
        this.rowData = stocks.children;
        this.stocksAtListDict.bulkData('idRow', stocks.children as any);
        this.mountedRowData();
      },
      error: () => {
        this.updateTableEmptyMessage(
          `Ocorreu um erro ao carregar os ativos do índice ${index}.`
        );
      },
    });
  }
}
