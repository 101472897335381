import { Dictionary } from '@core/models';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';

interface RowTotalInterface {
  net_day: number;
  net_day_daytrade: number;
  net_day_swing: number;
  open_buy_order: number;
  open_sell_order: number;
  pl: number;
  pl_daytrade: number;
  pl_swing: number;
  pnl: number;
  pnl_day: number;
  pnl_day_daytrade: number;
  pnl_day_swing: number;
  pnl_daytrade: number;
  pnl_open: number;
  pnl_open_daytrade: number;
  pnl_open_swing: number;
  pnl_swing: number;
  portfolio_name: number;
  preco_ultimo: string;
  qtty_buy_day: number;
  qtty_final: number;
  qtty_final_daytrade: number;
  qtty_final_swing: number;
  qtty_sell_day: number;
  vl_price_avg: number;
  vl_price_avg_daytrade: number;
  vl_price_avg_swing: number;
}

export class RowTotal implements RowTotalInterface {
  net_day = 0;
  net_day_daytrade = 0;
  net_day_swing = 0;
  open_buy_order = 0;
  open_sell_order = 0;
  pl = 0;
  pl_daytrade = 0;
  pl_swing = 0;
  pnl = 0;
  pnl_day = 0;
  pnl_day_daytrade = 0;
  pnl_day_swing = 0;
  pnl_daytrade = 0;
  pnl_open = 0;
  pnl_open_daytrade = 0;
  pnl_open_swing = 0;
  pnl_swing = 0;
  portfolio_name = 0;
  preco_ultimo = '';
  qtty_buy_day = 0;
  qtty_final = 0;
  qtty_final_daytrade = 0;
  qtty_final_swing = 0;
  qtty_sell_day = 0;
  vl_price_avg = 0;
  vl_price_avg_daytrade = 0;
  vl_price_avg_swing = 0;
  totalRowData: any;

  calcularSomatorio(array: any[]) {
    for (const item of array) {
      this.net_day += +item.net_day;
      this.net_day_daytrade += +item.net_day_daytrade;
      this.net_day_swing += +item.net_day_swing;
      this.open_buy_order += +item.open_buy_order;
      this.open_sell_order += +item.open_sell_order;
      this.pl += +item.pl;
      this.pl_daytrade += +item.pl_daytrade;
      this.pl_swing += +item.pl_swing;
      this.pnl += +item.pnl;
      this.pnl_day += +item.pnl_day;
      this.pnl_day_daytrade += +item.pnl_day_daytrade;
      this.pnl_day_swing += +item.pnl_day_swing;
      this.pnl_daytrade += +item.pnl_daytrade;
      this.pnl_open += +item.pnl_open;
      this.pnl_open_daytrade += +item.pnl_open_daytrade;
      this.pnl_open_swing += +item.pnl_open_swing;
      this.pnl_swing += +item.pnl_swing;
      this.portfolio_name += +item.portfolio_name;
      this.qtty_buy_day += +item.qtty_buy_day;
      this.qtty_final += +item.qtty_final;
      this.qtty_final_daytrade += +item.qtty_final_daytrade;
      this.qtty_final_swing += +item.qtty_final_swing;
      this.qtty_sell_day += +item.qtty_sell_day;
      this.vl_price_avg += +item.vl_price_avg;
      this.vl_price_avg_daytrade += +item.vl_price_avg_daytrade;
    }
  }
}

interface TotalField {
  field: string;
  type: string;
  suffix?: any;
  separator?: any;
  secondField?: any;
}

const getValue = (
  field: string,
  type: string,
  fieldValue: any,
  distinct: Dictionary<any>
): { fieldValue: number; distinct: Dictionary<any> } => {
  if (type === 'count') {
    return { fieldValue: 1, distinct };
  }
  if (type === 'distinct') {
    if (!distinct.has(field)) {
      distinct.set(field, {});
    }
    let returnValue = 1;
    const distinctValues = distinct.get(field);
    if (distinctValues[fieldValue]) {
      returnValue = 0;
    } else {
      distinctValues[fieldValue] = true;
      distinct.set(field, distinctValues);
    }
    return { fieldValue: returnValue, distinct };
  }
  return { fieldValue: +fieldValue, distinct };
};

export const calculateSUM = (
  array: any[],
  fields: TotalField[]
): Record<string, any> => {
  const somatorios: Record<string, any> = {};
  let distinctValues = new Dictionary<any>();
  function getKey(campo: TotalField) {
    return campo.suffix || campo.secondField
      ? `aux_${campo.field}`
      : campo.field;
  }

  for (const campo of fields) {
    const key = getKey(campo);
    somatorios[key] = 0;
  }
  for (const item of array) {
    for (const campo of fields) {
      const key = getKey(campo);
      if (!isNullOrUndefined(item[campo.field])) {
        const data = getValue(
          campo.field,
          campo.type,
          item[campo.field],
          distinctValues
        );
        somatorios[key] += data.fieldValue;
        distinctValues = data.distinct;
        if (campo.secondField) {
          const separator = campo.separator ? ` ${campo.separator} ` : '';
          somatorios[campo.field] = `${somatorios[key]}${separator}${
            somatorios[campo.secondField]
          }`;
        }
        if (campo.suffix) {
          somatorios[campo.field] = campo.suffix(somatorios[key]);
        }
      }
    }
  }
  return somatorios;
};

export function calculateSUMForAlerts(rowData: any[]): any {
  return calculateSUM(rowData, [
    { field: 'dh_insert', type: 'count' },
    {
      field: 'cd_stock',
      type: 'distinct',
      suffix: (qtty: number) => {
        const plural = qtty > 1 ? 's' : '';
        return `${qtty} Ativo${plural}`;
      },
    },
  ]);
}

export function calculateSUMForOrders(rowData: any[]): any {
  return calculateSUM(rowData, [
    { field: 'qtty', type: 'sum' },
    {
      field: 'dh_creation_time',
      type: 'count',
      suffix: (qtty: number) => {
        const plural = qtty > 1 ? 'ns' : 'm';
        return `${qtty} Orde${plural}`;
      },
    },
    {
      field: 'cd_stock',
      type: 'distinct',
      suffix: (qtty: number) => {
        const plural = qtty > 1 ? 's' : '';
        return `${qtty} Ativo${plural}`;
      },
    },
    {
      field: 'qtty_executed',
      separator: '/',
      secondField: 'qtty',
      type: 'sum',
    },
  ]);
}
