import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IProfitBarData } from '../../interface/profit-bar-data.interface';
import { randomId } from '@shared/rocket-components/utils';
import { PerformanceDonutHighchartService } from '../../services/performance-donut-highchart.service';
import {
  IPerformanceChartSeriesInfo,
  IPerformanceDetailsData,
  IPerformanceMarketData,
  IPerformanceMarketType,
  IPerformanceOperationStatus,
  IPerformanceOperationsTable,
  IPerformancePercentagesData,
} from '../../interface/performance-report.interface';
import { PERFORMANCE_FIELD_PREFIX_TYPE } from '../../constants/performance-report.contants';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import { delay, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-performance-details',
  templateUrl: './performance-details.component.html',
  styleUrls: ['./performance-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformanceDetailsComponent implements OnChanges, OnDestroy {
  @ViewChild('chartContainer') chartContainer!: ElementRef<HTMLDivElement>;
  @Input() performance!: IPerformanceDetailsData;
  @Input() marketPerformanceData!: IPerformanceMarketData;
  @Input() operations!: IPerformancePercentagesData;
  @Input() width!: number;
  public profitBarData!: IProfitBarData;
  public refChart: string = randomId('PERFORMANCE_DONUT_CHART');
  public loadingChart: boolean = true;
  public operationsStatus: IPerformanceOperationStatus[] = [];
  public marketTypeOperations: IPerformanceMarketType[] = [];
  public chartSeriesInfo: IPerformanceChartSeriesInfo[] = [
    {
      name: 'Abertas',
      field: 'open_operation',
      perc: 'open_percent',
      total: 'open_pnl',
      color: CHART_COLORS.BRAND_SUPPORT_PRIMARY,
    },
    {
      name: 'Vencedoras',
      field: 'win_operation',
      perc: 'win_percent',
      total: 'won_pnl',
      color: CHART_COLORS.FEEDBACK_SUCCESS,
    },
    {
      name: 'Perdedoras',
      field: 'loss_operation',
      perc: 'loss_percent',
      total: 'loss_pnl',
      color: CHART_COLORS.FEEDBACK_ERROR,
    },
    {
      name: 'Empatadas',
      field: 'zero_operation',
      perc: 'zero_percent',
      color: CHART_COLORS.NEUTRAL_MEDIUM,
    },
  ];
  public operationsTableSorted: IPerformanceOperationsTable[] = [];
  public chartNoData: boolean = false;
  public operationsSortField: 'perc' | 'qtd' | 'total' = 'qtd';
  public invertSort: boolean = false;
  private _chart!: Highcharts.Chart;
  private chartReload = new Subject<void>();
  private destroyReload = new Subject<void>();
  constructor(
    private _cdr: ChangeDetectorRef,
    private _highchartsService: PerformanceDonutHighchartService
  ) {
    this.chartReload
      .pipe(delay(500), takeUntil(this.destroyReload))
      .subscribe(() => {
        this._startChart();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const { performance, marketPerformanceData, operations, width } = changes;
    if (this.dataHasChanged(performance)) {
      this._cdr.detectChanges();
    }

    if (this.dataHasChanged(marketPerformanceData)) {
      this._getMarketTypeOperationsTable(marketPerformanceData.currentValue);
    }

    if (this.dataHasChanged(operations)) {
      this._getProfitBarData(operations.currentValue);
      this._getOperationsTable(operations.currentValue);
      this.updateChart();
    }

    if (width?.currentValue) {
      this.reflowChart();
    }
  }

  ngOnDestroy() {
    this.destroyReload.next();
    this.destroyReload.complete();
  }

  reflowChart = () => {
    this._chart?.reflow();
  };

  sortOperationTable(field: 'total' | 'perc' | 'qtd') {
    if (this.operationsSortField === field) {
      this.invertSort = !this.invertSort;
    } else {
      this.operationsSortField = field;
      this.invertSort = false;
    }
    this._getOperationsTable(this.operations);
  }

  private _getProfitBarData(operations: IPerformancePercentagesData) {
    this.profitBarData = {
      loss: operations.loss_day_perc,
      won: operations.profit_day_perc,
    };
    this._cdr.detectChanges();
  }

  private dataHasChanged(data: SimpleChange) {
    if (!data?.currentValue) return false;
    if (!data.previousValue) return true;
    return Object.keys(data.currentValue).some(
      (key) => data.currentValue[key] !== data.previousValue[key]
    );
  }

  private _getMarketTypeOperationsTable(marketPerformanceData: any) {
    const fields_prefix = ['mkt', 'lmt', 'stop', 'stop2'];
    const operations: IPerformanceMarketType[] = [];
    fields_prefix.forEach((prefix: any) => {
      operations.push({
        type: PERFORMANCE_FIELD_PREFIX_TYPE[prefix],
        canc_buy: marketPerformanceData[`${prefix}_canc_order_buy`],
        canc_sell: marketPerformanceData[`${prefix}_canc_order_sell`],
        exec_buy: marketPerformanceData[`${prefix}_exec_order_buy`],
        exec_sell: marketPerformanceData[`${prefix}_exec_order_sell`],
        open_buy: marketPerformanceData[`${prefix}_open_order_buy`],
        open_sell: marketPerformanceData[`${prefix}_open_order_sell`],
      });
    });
    this.marketTypeOperations = operations;
    this._cdr.detectChanges();
  }

  private _getOperationsTable(operations: IPerformancePercentagesData) {
    const data: IPerformanceOperationsTable[] = [];
    this.chartSeriesInfo.forEach((serieInfo) => {
      const item = {
        name: serieInfo.name,
        perc: operations[serieInfo.perc],
        qtd: operations[serieInfo.field],
        total: serieInfo.total ? operations[serieInfo.total] : 0,
      };
      data.push(item);
    });
    data.sort((a, b) =>
      this.invertSort
        ? a[this.operationsSortField] - b[this.operationsSortField]
        : b[this.operationsSortField] - a[this.operationsSortField]
    );
    data.push({
      name: 'Total',
      perc: operations.total_percent,
      qtd: operations.total_operation,
      total: operations.liquid_profit,
    });
    this.operationsTableSorted = data;
    this._cdr.detectChanges();
  }

  private _startChart() {
    if (!this.chartContainer || !document.getElementById(this.refChart)) {
      this.chartReload.next();
      return;
    }

    if (this._chart || !this.performance) return;
    this.loadingChart = true;
    this._chart = this._highchartsService.initGraph(this.refChart, () => null);
    const data = this._buildDonutData();
    this._chart.addSeries(
      {
        type: 'pie',
        innerSize: '90%',
        thickness: 14,
        data,
      },
      false
    );
    this.destroyReload.next();
    this.destroyReload.complete();
    this.loadingChart = false;
    this.updateChart();
    this._cdr.detectChanges();
  }

  updateChart() {
    if (this._chart) this._chart.series[0].setData(this._buildDonutData());
    else {
      this._startChart();
      return;
    }

    this._chart.series[0].setData(this._buildDonutData());
    const config = this._highchartsService.getTitle(
      this.operations.total_operation > 1
    );

    this._chart.setTitle({
      ...config,
      text:
        `<span class="fs-base mb-2 text-body">${this.operations.total_operation}</span>` +
        config.text,
    });
    this.chartNoData = !this._chart.series[0].data.length;
    this._chart.redraw();
    this._cdr.detectChanges();
  }

  private _buildDonutData = () => {
    return this.chartSeriesInfo
      .map((data: IPerformanceChartSeriesInfo) => {
        if (!this.operations[data.field]) return null;
        return {
          name: this._checkPlural(
            data.nameChart ?? data.name,
            this.operations[data.field]
          ),
          z: this.operations[data.perc],
          y: this.operations[data.field],
          color: data.color,
        };
      })
      .filter((item) => item);
  };

  private _checkPlural(text: string, quantity: number) {
    return quantity > 1 ? text : text.slice(0, -1);
  }
}
