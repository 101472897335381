import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HeatmapService {
  getValue(
    value: number,
    total: number,
    minPercentage: number,
    smallBoxes: number
  ) {
    if (!total) return 1;
    if (!smallBoxes) return value;

    const minValue = total * (minPercentage / 100);
    const valueToAdd = value * (minPercentage / 100) * smallBoxes;

    if (value > minValue) return minValue + valueToAdd;

    if (!value) return minValue;

    return minValue + value * 2;
  }
}
