import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  distinctUntilChanged,
  filter,
  map,
  of,
  take,
  tap,
} from 'rxjs';
import { IAuthForm } from '../../core/interface';
import { system } from '../../core/system/system.service';
import { UserAuthenticated } from './api/authentication/v1/login-response.interface';
import { RestService } from './rest/rest.service';
import { IResponse } from './api/trademap/V6/interface/loan-balance.interface';
import {
  LogoutReason,
  LogoutService,
} from './api/authentication/v2/logout.service';
import { SystemDataService } from './core/system-data.service';
import { StorageUtils } from './core/storage/storage.utils';
import { AUTH_SESSION_KEYS } from './core/const/auth_util.const';
import { LOGOUT_CODE } from '@core/interceptors/auth.interceptor';
import {
  ONBOARDING_MODALS_EVENTS,
  PartnersService,
} from './core/partners-services/partners.service';
import { SessionService } from './api/authentication/v2/session.service';
import { Router } from '@angular/router';
import { IntegrationService } from 'src/app/desktop/integration.service';
import { isWebViewContext } from 'src/app/desktop/integration.utils';
import { AppConfig, ConfigService } from '@core/config';
import { AdjustService } from './api/adjust/v1/adjust.service';
import { TrademapV9Service } from './api/trademap/V9/trademap.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends RestService {
  override _url: string = 'api/authentication/v1/authentication';
  public origin: 'NEW_LOGIN' | 'NEW_REGISTER' = 'NEW_LOGIN';

  private _authenticationObservable = new BehaviorSubject<any>(
    StorageUtils.getItemSessionStorage(AUTH_SESSION_KEYS.USER_AUTHENTICATED)
  );

  private _adjustService = inject(AdjustService);
  private _trademapV9Service = inject(TrademapV9Service);

  constructor(
    http: HttpClient,
    private _logoutService: LogoutService,
    private _sys: SystemDataService,
    private session: SessionService,
    private _router: Router,
    private _partnersService: PartnersService,
    private _integrationService: IntegrationService,
    private config: ConfigService
  ) {
    super(http);
  }

  public performLogout(data: LogoutReason) {
    this._logoutService.exec(data.reason, data.systemMessage);
  }

  public isAuthenticated = () =>
    StorageUtils.getItemSessionStorage(AUTH_SESSION_KEYS.USER_AUTHENTICATED);

  public isAuth(): Observable<boolean> {
    return this._authenticationObservable
      .asObservable()
      .pipe(distinctUntilChanged());
  }

  public alive = () => this.session.verifyHealth();

  public authenticate(data: IAuthForm, blockRedir: boolean = false) {
    const config: AppConfig | undefined = this.config.getConfig();
    return this._callService<UserAuthenticated>(
      'login',
      {
        channel: config?.channel,
        id_contract: config?.idContract,
        info: { ip: '', os_version: '', version: '', os_name: '' },
        isTradeChat: false,
        password: data.password,
        username: data.username,
      },
      new HttpHeaders({
        'recaptcha-token': data.recaptcha ?? '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      })
    ).pipe(
      map((res: IResponse<UserAuthenticated>) => {
        if (!res.success) throw <any>res;
        return {
          investor: res.result.investor,
          orderStatusColor: res.result.orderStatusColor,
          packages: res.result.packages,
        } as UserAuthenticated;
      }),
      tap((user) => this._taskLogin(user, blockRedir, data))
    );
  }

  public appInit(): void {
    if (system.userAuthenticated.investor.isSessionWeak) {
      this._emitUserInfos();
      this._verifyIsAuthenticatedAndNavToSystem(false);
      return;
    }
    this.loadPreferencesAndInitialize();
  }

  public loadPreferencesAndInitialize(): void {
    this._saveAdjustEvent();
    this._sys
      .loadCustomPreferences()
      .pipe(tap(() => this._emitUserInfos()))
      .subscribe({
        next: () => this._verifyIsAuthenticatedAndNavToSystem(true),
        error: () =>
          this.performLogout({
            reason: LOGOUT_CODE[401],
            systemMessage: 'Erro ao carregar dados da sessão',
          }),
      });
  }

  public handlePartner(): void {
    this._partnersService.verifyPartnerAfterLogin();
    this._partnersService.authEvents$.pipe(take(1)).subscribe((event) => {
      if (event === ONBOARDING_MODALS_EVENTS.INIT) {
        this.appInit();
        return;
      }
      this.performLogout({ reason: 'USER_COMMAND' });
    });
  }

  private _callService = <T>(
    serviceName: string,
    params: any = {},
    header?: any
  ) =>
    this.post<T>(serviceName, params, header).pipe(
      map((res) => res.data),
      catchError((err) => of(this.handlerServiceError(err)))
    );

  private _taskLogin = (
    user: UserAuthenticated,
    blockRedir: boolean = false,
    data: IAuthForm
  ) => {
    system.userAuthenticated = user;
    system.idInvestor = user.investor.id_investor;
    system.ordersStatusColor = user.orderStatusColor;
    system.packages = user.packages;
    system.userAuthenticated.authForm = data;
    if (!blockRedir) this.appInit();
  };

  private _emitUserInfos(): void {
    this._authenticationObservable.next({
      ...system.userAuthenticated,
      ...{ isLogin: true },
    });
  }

  private _verifyIsAuthenticatedAndNavToSystem(
    userAuthenticated: boolean
  ): void {
    this.isAuth()
      .pipe(
        take(1),
        tap(() => !userAuthenticated && this.handlePartner()),
        filter((isLogged) => isLogged)
      )
      .subscribe(() => {
        this._router.navigateByUrl('/');
        this._verifyHasInfluencerIDToLink();
        if (isWebViewContext())
          this._integrationService.notifyAuthenticated(
            system.userAuthenticated.authForm
          );
      });
  }

  private _verifyHasInfluencerIDToLink(): void {
    const id = this._adjustService.influencerID;
    if (!id) return;
    this._adjustService.linkInfluencer(id).subscribe();
  }

  private _saveAdjustEvent(): void {
    this._trademapV9Service.saveDeepLinkEvents(this.origin);
    if (this.origin === 'NEW_REGISTER') this.origin = 'NEW_LOGIN';
  }
}
