import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DocumentsModalComponent } from '@shared/documents-modal/documents-modal.component';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { TrademapNewsService as TrademapNewsServiceV8 } from '@shared/services/api/trademap/V8/news.service';
import { TrademapNewsService as TrademapNewsServiceV1 } from '@shared/services/api/trademap/v1/news.service';
import { TrademapCalendarsService } from '@shared/services/api/trademap/v1/calendars.service';
import { map } from 'rxjs';
import {
  VMDateToFriendlyDate,
  VMDateToHoursAndMin,
  VMDateToYear,
} from 'src/app/utils/utils.functions';
import {
  INews,
  INewsGroup,
  INewsSubGroup,
} from '@core/interface/news-agency.interface';
import {
  NEWS_TAGS_NOT_AGENCIES,
  TNewsConfiguration,
} from '@shared/components/news/types';
import { NewsModalComponent } from '../news-modal/news-modal.component';
import {
  MODAL_MENU_TYPES,
  PERIODS_CALENDAR,
  PERIODS_CVM,
  PERIODS_GERAL,
  TAB_VIEWS,
} from '../../constants/market-summary';

@Component({
  selector: 'app-general-modal',
  templateUrl: './general-modal.component.html',
  styleUrls: ['./general-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralModalComponent extends RocketModalRef implements OnInit {
  public types = MODAL_MENU_TYPES;
  public periods = PERIODS_GERAL;
  public periodsCalendar = PERIODS_CALENDAR;
  public periodsCVM = PERIODS_CVM;
  public tabViews = TAB_VIEWS;
  public list: any[] = [];
  public dtComSelected: boolean = true;
  public loading: boolean = true;
  hasEvents: boolean = false;
  public typeSelected = '';
  public periodSelected: any = null;
  private _page = 0;
  loadingNews = false;
  newsGroups!: INewsGroup[];
  newsConfiguration: TNewsConfiguration = {
    tab: 1,
    tag: 'ULTIMAS',
  };
  private _calendarPeriod!: string;
  private _proceedsPeriod!: string;
  private _eventsPeriod!: string;
  private _CVMPeriod!: string;

  @ViewChild('bodyWrapper', { static: true }) bodyWrapper!: ElementRef;

  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: any,
    private rocketModalService: RocketModalService,
    private _trademapCalendarsService: TrademapCalendarsService,
    private _trademapNewsServiceV8: TrademapNewsServiceV8,
    private _trademapNewsServiceV1: TrademapNewsServiceV1,
    private cdr: ChangeDetectorRef
  ) {
    super(rocketModalService);
  }

  get selectedNewsGroup() {
    return (
      this.newsGroups.find(
        (newsGroup: INewsGroup) =>
          newsGroup.id_news_group == this.newsConfiguration.tab
      ) || {
        id_news_group: 0,
        itens: [],
        nm_news_group: '',
      }
    );
  }

  ngOnInit() {
    this.getList(
      this.data.type,
      this.data.type === 'calendar' ? 'today' : 'next'
    );
    this.typeSelected = this.data.type;
  }

  private getCalendar(period: string) {
    this._trademapNewsServiceV1
      .getNewsCalendar(period)
      .subscribe((transformedData: any) => {
        if (this.typeSelected === 'calendar') {
          this.list = transformedData;
          this.loading = false;
          this.cdr.detectChanges();
        }
      });
  }

  private getEvents(period: string) {
    this._trademapCalendarsService
      .getEvents(100, period)
      .pipe(
        map((dataArray: any) => {
          return dataArray.map((data: any) => {
            return {
              body: `${data.company}<br /><span class="fs-7">${data.ds_company_action_type}</span>`,
              yearDoc: data.reference_year,
              dateString: VMDateToFriendlyDate(data.dt_limit),
            };
          });
        })
      )
      .subscribe((transformedData: any) => {
        if (this.typeSelected === 'events') {
          this.list = transformedData;
          this.loading = false;
          this.cdr.detectChanges();
        }
      });
  }

  private getProceeds(period: string) {
    this._trademapCalendarsService
      .getProceeds(!this.dtComSelected, 100, period)
      .pipe(
        map((dataArray: any) => {
          return dataArray.map((data: any) => {
            return {
              body: `${data.company}<br /><span class="fs-7">${data.ds_company_action_type}</span>`,
              yearDoc: data.reference_year,
              dateString: VMDateToFriendlyDate(data.dt_limit),
            };
          });
        })
      )
      .subscribe((transformedData: any) => {
        if (this.typeSelected === 'proceeds') {
          this.list = transformedData;
          this.loading = false;
          this.cdr.detectChanges();
        }
      });
  }

  private getCVMNews(period: string) {
    this._trademapNewsServiceV8
      .getCVMNews(0, 100, period)
      .pipe(
        map((dataArray: any) => {
          if (!dataArray.length) {
            this.list = [];
            return;
          }
          return dataArray[0]?.news.map((data: any) => {
            return {
              dsDocumentDescription: data.data.ds_document_description,
              dsDocumentCategoryPt: data.ds_headline_category,
              idCvmCompany: data.data.id_cvm_company,
              seq: data.data.seq,
              body: `${data.ds_headline_legal_name}<br /><span class="fs-7">${data.ds_headline_category}</span>`,
              yearDoc: VMDateToYear(data.dt_news),
              dateString: VMDateToFriendlyDate(data.dt_news),
            };
          });
        })
      )
      .subscribe((transformedData: any) => {
        if (this.typeSelected === 'cvm') {
          if (!transformedData?.length) {
            this.list = [];
            this.loading = false;
            this.cdr.detectChanges();
            return;
          }
          this.list = transformedData;
          this.loading = false;
          this.cdr.detectChanges();
        }
      });
  }

  private _loadData() {
    this._trademapNewsServiceV1
      .getNewsGroups()
      .pipe(
        map((dataArray: any) => {
          return dataArray[0]?.itens.filter((data: any) => {
            const index = this.tabViews.findIndex(
              (tab: any) => tab.label === data.nm_view_news
            );
            if (index < 0) return true;
            return false;
          });
        })
      )
      .subscribe((group: INewsSubGroup[]) => {
        this.newsGroups = [];
        this.newsGroups.push({
          id_news_group: 1,
          itens: group,
          nm_news_group: 'Últimas',
        });
        this._loadNews();
      });
  }

  private _loadNews(append = false) {
    if (this.loadingNews) return;
    this.loadingNews = true;

    !append && (this.list = []);
    append &&
      setTimeout(() => {
        const element = this.bodyWrapper.nativeElement;
        element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
        this.cdr.detectChanges();
      }, 0);

    const { agencies, tags, service, groupTag } = this._getNewsListFilter();

    if (groupTag.id_news_group != 999) {
      service(this._page, undefined, agencies, tags)
        .pipe(
          map((dataArray: any) => {
            return dataArray.map((data: any) => {
              return {
                ...data,
                ds_headline: data.headline,
                body: `${data.headline}<br /><span class="fs-7">${data.agencia}</span>`,
                dateString: VMDateToHoursAndMin(
                  `${data.data_noticia}${data.hora_noticia.slice(0, 4)}`
                ),
                yearDoc: VMDateToFriendlyDate(data.data_noticia),
              };
            });
          })
        )
        .subscribe((news: INews[]) => {
          this.list = append ? [...this.list, ...news] : news;
          this.loading = false;
          this.loadingNews = false;
          this._page += 1;
          this.cdr.detectChanges();
        });
    }
  }

  private _getNewsListFilter(): {
    agencies: string[];
    tags: string[];
    service: any;
    groupTag: any;
  } {
    const response: {
      agencies: string[];
      tags: string[];
      service: any;
      groupTag: any;
    } = {
      agencies: [],
      tags: [],
      service: this._trademapNewsServiceV1.getNews,
      groupTag: {},
    };

    const { tag } = this.newsConfiguration;

    if (!tag) {
      return response;
    }
    const groupTag = this.selectedNewsGroup.itens.find(
      (group: INewsSubGroup) => group.tag_agg == tag
    );
    if (!groupTag) {
      return response;
    }

    response.groupTag = groupTag;

    if (groupTag.tp_view_news == 'TAG') {
      response.tags.push(tag);
      return response;
    }

    NEWS_TAGS_NOT_AGENCIES.indexOf(groupTag.tp_view_news) < 0 &&
      (response.agencies =
        groupTag.tag_agg.indexOf(',') > -1
          ? groupTag.tag_agg.split(',')
          : [groupTag.tag_agg]);

    const groupId =
      groupTag.nm_view_news == 'BDM' ? 'BDM' : groupTag.tp_view_news;
    response.service =
      this._trademapNewsServiceV1.serviceFunctionMapping[groupId];
    return response;
  }

  onListScroll(event: any) {
    if (
      this.typeSelected === 'news' &&
      event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight
    ) {
      this._loadNews(true);
    }
  }

  getList(type: string, period: string, isInitialValue: boolean = false) {
    if (type === this.typeSelected && period === this.periodSelected.key)
      return;
    this.loading = true;
    this.list = [];
    this.hasEvents = false;
    this.typeSelected = type;
    if (type === 'calendar') this._setCalendarPeriod(period, isInitialValue);
    if (type === 'cvm') this._setCVMPeriod(period, isInitialValue);
    if (type === 'proceeds') this._setProceedPeriod(period, isInitialValue);
    if (type === 'events') this._setEvents(period, isInitialValue);
    if (type === 'news') {
      this.periodSelected = { key: period };
      this.hasEvents = true;
      if (period !== 'next') {
        this.newsConfiguration.tag = period;
        this._page = 0;
        this._loadNews();
        return;
      }
      this._loadData();
    }
  }

  private _setCalendarPeriod(period: string, isInitialValue: boolean): void {
    const calendarPeriod =
      isInitialValue && this._calendarPeriod ? this._calendarPeriod : period;
    this.periodSelected = { key: calendarPeriod };
    this._calendarPeriod = calendarPeriod;
    this.getCalendar(calendarPeriod);
  }

  private _setCVMPeriod(period: string, isInitialValue: boolean): void {
    const cvmPeriod =
      isInitialValue && this._CVMPeriod ? this._CVMPeriod : period;
    this.getCVMNews(cvmPeriod);
    this.periodSelected = { key: cvmPeriod };
    this._CVMPeriod = cvmPeriod;
    this.hasEvents = true;
  }

  private _setProceedPeriod(period: string, isInitialValue: boolean): void {
    const value =
      isInitialValue && this._proceedsPeriod ? this._proceedsPeriod : period;
    this.getProceeds(value);
    this.periodSelected = { key: value };
    this._proceedsPeriod = value;
  }

  private _setEvents(period: string, isInitialValue: boolean): void {
    const eventsPeriod =
      isInitialValue && this._eventsPeriod ? this._eventsPeriod : period;
    this.getEvents(eventsPeriod);
    this.periodSelected = { key: eventsPeriod };
    this._eventsPeriod = eventsPeriod;
  }

  public changeDtComSelected(status: boolean) {
    if (status !== this.dtComSelected) {
      this.dtComSelected = status;
      this.getProceeds(this.periodSelected.key);
    }
  }

  documentOpen(doc: any) {
    if (!this.hasEvents) return;
    if (this.typeSelected === 'news') {
      if (doc.url) {
        window.open(doc.url, '_blank');
      } else {
        this.rocketModalService.open(NewsModalComponent, {
          data: {
            event: doc,
          },
        });
      }
    } else {
      this.rocketModalService.open(DocumentsModalComponent, {
        data: {
          event: doc,
        },
      });
    }
  }
}
