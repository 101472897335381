import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { system } from '@core/system/system.service';
import {
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { deepClone } from '@shared/rocket-components/utils';
import { AuthService, ToastService } from '@shared/services';
import { UserService } from '@shared/services/api/trademap/v1/user.service';
import {
  compareIsSameValue,
  confirmPasswordValidator,
  passwordValidator,
} from '@shared/validators/password-validator';
import { getTokenTooltip } from '../constants/modal-meus-dados.constants';
import { delay, of, take } from 'rxjs';

@Component({
  selector: 'app-modal-change-password',
  templateUrl: './modal-change-password.component.html',
  styles: [
    `
      ::ng-deep {
        .toast {
          width: 100% !important;
          min-width: 100% !important;
          max-width: 100% !important;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalChangePasswordComponent
  extends RocketModalRef
  implements OnInit
{
  @ViewChild('inputToken', { static: false }) public inputToken!: any;

  public form!: FormGroup;
  public isLoading: boolean = false;

  public tokenTooltip = '';

  public serviceResult: any = {
    error: false,
    message: '',
  };

  get newPassword() {
    return this.form.get('new_password')!;
  }

  get confirmNewPasswordError() {
    return this.newPassword?.value
      ? 'As senhas precisam ser iguais'
      : 'Verifique a confirmação da sua nova senha';
  }

  get disableSubmitButton(): boolean {
    return !this.form.valid || this.isLoading;
  }

  constructor(
    service: RocketModalService,
    private formBuilder: FormBuilder,
    private _userService: UserService,
    private _toast: ToastService,
    private _authservice: AuthService,
    private _cdr: ChangeDetectorRef
  ) {
    super(service);
    this.form = this.formBuilder.group({
      field: ['password'],
      current_password: [
        '',
        { validators: [Validators.required, Validators.maxLength(70)] },
      ],
      new_password: [
        '',
        {
          validators: [
            Validators.required,
            Validators.maxLength(70),
            passwordValidator(),
            compareIsSameValue('confirm_new_password'),
          ],
        },
      ],
      confirm_new_password: [
        '',
        {
          validators: [
            Validators.required,
            Validators.maxLength(70),
            confirmPasswordValidator('new_password'),
          ],
        },
      ],
      token: [
        '',
        {
          validators: [
            Validators.required,
            Validators.maxLength(6),
            Validators.minLength(6),
          ],
        },
      ],
    });
  }

  ngOnInit(): void {
    this._buildTokenTooltip();
    this._focusOnFieldText();
  }

  private _buildTokenTooltip(): void {
    if (!system.authenticationMethod) return;
    this.tokenTooltip = getTokenTooltip();
  }

  private _focusOnFieldText(): void {
    of(null)
      .pipe(take(1), delay(400))
      .subscribe(() => document.getElementById(this.inputToken.refId)?.focus());
  }

  public handleBtnAction(origin: 'SUBMIT' | 'CANCEL'): void {
    if (origin === 'SUBMIT') {
      this._handlerChangePassword();
      return;
    }
    this.close(null, true);
  }

  private _handlerChangePassword(): void {
    this.isLoading = true;
    const fields = deepClone(this.form.value);
    this._userService
      .updateFieldByToken({
        field: fields.field,
        token: fields.token,
        password: btoa(fields.current_password),
        value: btoa(fields.new_password),
        cdOrigin: system.authenticationMethod!,
      })
      .subscribe((response) => {
        if (response.isError) {
          if (response?.code === 'BLOCKED_PASSWORD') {
            this._authservice.performLogout({
              reason: 'BLOCKED_PASSWORD',
              systemMessage: 'Senha bloqueada',
            });
            return;
          }
          this.isLoading = false;
          this.serviceResult = {
            error: true,
            message: response.message,
          };
          this._cdr.detectChanges();
          return;
        }
        this._toast.showToast('success', 'Senha alterada com sucesso.');
        this.close(null, true);
      });
  }
}
