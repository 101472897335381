export const FILTER_OPTIONS_ORDER_GRID: any = {
  TEXT: {
    filterDefinitions: {
      type: 'text',
      sortBy: 'asc',
      filterFormatterActive: false,
      automaticFilter: true,
      replaceField: null,
    },
    filterOptions: [
      {
        text: 'Todos',
        value: ['ALL'],
      },
    ],
  },
  DATE: {
    filterDefinitions: {
      type: 'date',
      sortBy: 'asc',
      filterFormatterActive: false,
      automaticFilter: true,
      replaceField: null,
      tomorrowText: null,
      todayText: null,
      yesterdayText: null,
    },
    filterOptions: [
      {
        text: 'Todos',
        value: ['ALL'],
      },
    ],
  },
  SIDE: {
    filterDefinitions: {
      type: 'text',
      sortBy: 'asc',
      filterFormatterActive: false,
      automaticFilter: false,
      replaceField: null,
    },
    filterOptions: [
      {
        text: 'Compra',
        value: ['B'],
      },
      {
        text: 'Venda',
        value: ['S'],
      },
    ],
  },
  YESORNOT: {
    filterDefinitions: {
      type: 'text',
      sortBy: 'asc',
      filterFormatterActive: false,
      automaticFilter: false,
      replaceField: null,
    },
    filterOptions: [
      {
        text: 'Sim',
        value: ['true', true],
      },
      {
        text: 'Não',
        value: ['false', undefined, false],
      },
    ],
  },
};
