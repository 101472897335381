import {
  HorizontalLineAnnotation,
  IHVLineAnnotationOptions,
  SciChartSurface,
} from 'scichart';
import { TIGER_LINE_TYPE } from '../enum';
import { CANDLE_IDS } from '../constants/tiger-chart.constants';

export class HorizontalLine extends HorizontalLineAnnotation {
  private completeLine!: HorizontalLineAnnotation | undefined;
  private completeLineId: string = '';
  constructor(
    private tigerLineType: TIGER_LINE_TYPE,
    options?: IHVLineAnnotationOptions
  ) {
    super(options);
    this.completeLineId = `${this.id}_aux`;
  }

  override delete(): void {
    super.delete();
    if (this.tigerLineType === TIGER_LINE_TYPE.CUSTODY && this.completeLine) {
      this.parentSurface.annotations.remove(
        this.parentSurface.annotations.getById(this.completeLine.id)
      );
      this.completeLine = undefined;
    }
  }

  override onAttach(scs: SciChartSurface): void {
    super.onAttach(scs);
    if (this.tigerLineType === TIGER_LINE_TYPE.CUSTODY && !this.completeLine) {
      this.createCompleteLine();
    }
  }

  private createCompleteLine() {
    const lastCandle = this.getLastCandle();
    this.completeLine = new HorizontalLineAnnotation({
      id: this.completeLineId,
      xAxisId: this.xAxisId,
      yAxisId: this.yAxisId,
      stroke: this.stroke,
      showLabel: false,
      isEditable: false,
      y1: this.y1,
      x1: lastCandle,
    });
    this.parentSurface.annotations.add(this.completeLine);
  }

  updateCompleteLinePosition() {
    if (this.tigerLineType === TIGER_LINE_TYPE.CUSTODY && this.completeLine) {
      const lastCandle = this.getLastCandle();
      this.completeLine.x1 = lastCandle;
    }
  }

  private getLastCandle() {
    const mainSeries = this.parentSurface.renderableSeries.getById(
      CANDLE_IDS.MAIN_SERIE
    );
    if (!mainSeries) {
      return 0;
    }
    return mainSeries.dataSeries.count() - 1;
  }

  rePlotCompleteLine() {
    const completeOnChart = this.parentSurface.annotations.getById(
      this.completeLineId
    );
    if (!completeOnChart) {
      this.createCompleteLine();
    }
  }
}
