<ng-container *ngIf="isLoading; else elseLoading">
  <app-loading></app-loading>
</ng-container>
<app-youtube-miniplayer
  [videoYoutubeData]="videoYoutubeData"
  *ngIf="showMiniplayer"
></app-youtube-miniplayer>
<app-inactivity-alert
  [show]="warningMonitor"
  (resetInactivity)="imHere()"
></app-inactivity-alert>
<ng-template #elseLoading>
  <app-context-menu
    *ngIf="isDisplayContextMenu"
    [show]="isDisplayContextMenu"
    [options]="contextMenuOptions"
    (menuClose)="onCloseContextMenu()"
  ></app-context-menu>
  <app-workspace (contextmenu)="openAddComponentContextMenu($event)">
    <app-workspace-doc
      *ngFor="let item of workSpaces?.components; let i = index"
      ref="workspace_base"
      [item]="item"
      [lockWorkspace]="lockWorkspace"
      (flaRemoveComponent)="removeComponent($event)"
      (flaLink)="clickLink($event)"
      (flaMaximize)="clickMaximize()"
      (flaMove)="onMoveComponent($event)"
      (contextMenuClick)="execContextMenu($event)"
      [focused]="item.id === focusedComponent"
      (componentFocused)="updateComponentFocused($event)"
    >
      <div class="w-100 h-100">
        <ng-container [ngSwitch]="item.type">
          <ng-container *ngSwitchCase="componentNamesEnum.GRAFICO">
            <app-stock-chart
              [component]="item"
              [width]="
                item.metadata.componentResize?.width ??
                item.metadata.layout.width
              "
              [height]="
                item.metadata.componentResize?.height ??
                item.metadata.layout.height
              "
              [focused]="item.id === focusedComponent"
            ></app-stock-chart>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.HEATMAP">
            <app-heatmap
              [component]="item"
              [refComponent]="item.metadata.headerOptions.component.data"
              [link]="item.metadata?.headerOptions?.link"
              [width]="
                item.metadata.componentResize?.width ??
                item.metadata.layout.width
              "
              [height]="
                item.metadata.componentResize?.height ??
                item.metadata.layout.height
              "
            ></app-heatmap>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.STOCK_ANALYSIS">
            <app-stock-analysis
              [component]="item"
              [refComponent]="item.metadata.headerOptions.component.data"
              [width]="
                item.metadata.componentResize?.width ??
                item.metadata.layout.width
              "
              [height]="
                item.metadata.componentResize?.height ??
                item.metadata.layout.height
              "
              [link]="item.metadata?.headerOptions?.link"
            ></app-stock-analysis>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.NEWS">
            <app-news
              [component]="item"
              [refComponent]="item.metadata.headerOptions.component.data"
              [width]="
                item.metadata.componentResize?.width ??
                item.metadata.layout.width
              "
              [height]="
                item.metadata.componentResize?.height ??
                item.metadata.layout.height
              "
            ></app-news>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.OPTIONS">
            <app-options
              [component]="item"
              [refComponent]="item.metadata.headerOptions.component.data"
              [link]="item.metadata?.headerOptions?.link"
              [height]="
                item.metadata.componentResize?.height ??
                item.metadata.layout.height
              "
              [width]="
                item.metadata.componentResize?.width ??
                item.metadata.layout.width
              "
            ></app-options>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.PAPEIS">
            <app-stock-table
              [componentInfo]="item"
              [refComponent]="item.metadata.headerOptions.component.data"
              [link]="item.metadata?.headerOptions?.link"
              [height]="
                item.metadata.componentResize?.height ??
                item.metadata.layout.height
              "
              [width]="
                item.metadata.componentResize?.width ??
                item.metadata.layout.width
              "
              [focused]="item.id === focusedComponent"
            ></app-stock-table>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.LISTA_ORDENS">
            <app-orders-history
              [component]="item"
              [refComponent]="item.metadata.headerOptions.component.data"
              [componentId]="item.id"
            ></app-orders-history>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.BOOK">
            <app-table-book
              [component]="item"
              [height]="
                item.metadata.componentResize?.height ??
                item.metadata.layout.height
              "
              [width]="
                item.metadata.componentResize?.width ??
                item.metadata.layout.width
              "
            ></app-table-book>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.TIMES_TRADES">
            <app-times-trades
              [component]="item"
              [height]="
                item.metadata.componentResize?.height ??
                item.metadata.layout.height
              "
              [width]="
                item.metadata.componentResize?.width ??
                item.metadata.layout.width
              "
            ></app-times-trades>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.SUPER_DOM">
            <app-super-dom
              [component]="item"
              [refComponent]="item.metadata.headerOptions.component.data"
              [height]="
                item.metadata.componentResize?.height ??
                item.metadata.layout.height
              "
              [width]="
                item.metadata.componentResize?.width ??
                item.metadata.layout.width
              "
            ></app-super-dom>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.BOLETA">
            <app-stock-trade [component]="item"></app-stock-trade>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.SCREENING">
            <app-stock-screening [component]="item"></app-stock-screening>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.GRAPHIC_SCREENING">
            <app-graphic-screening
              [component]="item"
              [headerOptions]="item.metadata.headerOptions"
            ></app-graphic-screening>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.BUSINESS_PROFILE">
            <app-business-profile
              [component]="item"
              [height]="
                item.metadata.componentResize?.height ??
                item.metadata.layout.height
              "
              [width]="
                item.metadata.componentResize?.width ??
                item.metadata.layout.width
              "
            >
            </app-business-profile>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.PERFORMANCE_REPORT">
            <app-performance-report
              [component]="item"
              [refComponent]="item.metadata.headerOptions.component.data"
              [link]="item.metadata?.headerOptions?.link"
              [height]="
                item.metadata.componentResize?.height ??
                item.metadata.layout.height
              "
              [width]="
                item.metadata.componentResize?.width ??
                item.metadata.layout.width
              "
            ></app-performance-report>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.AT_PRICE">
            <app-at-price
              [component]="item"
              [refComponent]="item.metadata.headerOptions.component.data"
              [height]="
                item.metadata.componentResize?.height ??
                item.metadata.layout.height
              "
            ></app-at-price>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.RANKING_BROKER">
            <app-ranking-broker
              (flaLink)="clickLink($event)"
              [component]="item"
              [refComponent]="item.metadata.headerOptions.component.data"
              [height]="
                item.metadata.componentResize?.height ??
                item.metadata.layout.height
              "
              [width]="
                item.metadata.componentResize?.width ??
                item.metadata.layout.width
              "
            ></app-ranking-broker>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.TRADE_IDEA">
            <app-trade-idea
              [component]="item"
              [refComponent]="item.metadata.headerOptions.component.data"
            ></app-trade-idea>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.RENT_BTC">
            <app-rent-btc
              [component]="item"
              [refComponent]="item.metadata.headerOptions.component.data"
            ></app-rent-btc>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.FLOW_INVESTOR">
            <app-flow-investors
              [component]="item"
              [refComponent]="item.metadata.headerOptions.component.data"
              [height]="
                item.metadata.componentResize?.height ??
                item.metadata.layout.height
              "
              [width]="
                item.metadata.componentResize?.width ??
                item.metadata.layout.width
              "
              [position]="position"
            ></app-flow-investors>
          </ng-container>
          <ng-container *ngSwitchCase="componentNamesEnum.MARKET_RESUME">
            <app-market-summary
              [component]="item"
              [refComponent]="item.metadata.headerOptions.component.data"
              [link]="item.metadata?.headerOptions?.link"
            ></app-market-summary>
          </ng-container>
          <ng-container *ngSwitchDefault> </ng-container>
        </ng-container>
      </div>
    </app-workspace-doc>
  </app-workspace>
</ng-template>
