<div id="ytb-player" class="position-absolute position-player">
  <div
    class="bg-neutral-strongest d-flex justify-content-end"
    cdkDrag
    [cdkDragFreeDragPosition]="transform"
    id="closerDiv"
    (cdkDragEnded)="movePlayer()"
  >
    <div title="Fechar Miniplayer">
      <fla-icon
        name="close"
        css="text-neutral-medium close-mini-player cursor-pointer"
        size="icon-size-sm"
        (click)="closeMiniPlayer()"
      >
      </fla-icon>
    </div>
  </div>
  <div id="mini-player"></div>
</div>
