<ng-container *ngIf="headerTabs">
  <app-orders-history-header
    #orderHistoryHeader
    [componentId]="componentId"
    [refComponent]="refComponent"
    [isDayTrade]="isDayTrade"
    [positionQtty]="positionQtty"
    [component]="component"
    [idBrokerSelected]="accountSelected?.id_broker"
    [ordersHistoryTab]="ordersHistoryTab"
    [isCardView]="isCardView"
    (eventChangeTab)="onChangeTabHandler($event)"
    (resetGrid)="resetGridHeader($event)"
    (exportDataHandler)="exportData()"
    (viewChanged)="historyViewChanged()"
  ></app-orders-history-header>
</ng-container>
<div [ngClass]="isDesktop ? 'component-height' : 'h-100'">
  <app-orders-history-grid
    #orderHistoryGrid
    [componentId]="componentId"
    [refComponent]="refComponent"
    [metadataComponent]="metadataComponent"
    [globalCdStock]="globalCdStock"
    [idBrokerSelected]="idBrokerSelected"
    [showTotalRow]="showTotalRow()"
    [componentType]="tabSelected?.component_type"
    [componentKey]="tabSelected?.key"
    [width]="componentWidth"
    [height]="componentHeight"
    [noRowsTemplateMessage]="noRowsTemplateMessage"
    [fieldIndexer]="fieldIndexer"
    [ignoreRocketService]="ignoreRocketService"
    [onUpdateField]="onUpdateField"
    [isCardView]="isCardView"
    [tabSelected]="tabSelected"
    [tabSelectedUpdate]="tabSelectedUpdate"
    [isDayTrade]="isDayTrade"
    (applyFilter)="this.applyFilter$.next()"
    (viewReadyHandler)="onViewReady($event)"
    (totalGridReadyHandler)="onGridTotalReady($event)"
    (columnResizedHandler)="onColumnResizedHandler($event)"
    (columnMovedHandler)="onColumnMovedHandler($event)"
    (changeStockHandler)="stockChangeHandler($event)"
    (gridFilterChange)="gridFilterChange($event)"
    (gridSortChange)="saveSort($event)"
  ></app-orders-history-grid>
</div>
