import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { randomId } from '@shared/rocket-components/utils';
import { ScichartService } from '@shared/tiger-chart/services/scichart.service';
import {
  CursorModifier,
  CursorTooltipSvgAnnotation,
  EAxisAlignment,
  ELabelAlignment,
  NumberRange,
  NumericAxis,
  SeriesInfo,
  StackedColumnCollection,
  StackedColumnRenderableSeries,
  TWebAssemblyChart,
  TextLabelProvider,
  Thickness,
  XyDataSeries,
} from 'scichart';
import { getVolumeText } from 'src/app/utils/utils.functions';
import { colorsSector } from '../../consts/colors';
import { Subject, Subscription, debounceTime, delay, takeUntil } from 'rxjs';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { THEME_TIMER } from '../../flow-investors.component';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import { FlowInvestorsService } from '../../flow-investors.service';
import { THEMES } from '@shared/services/core/custom-preferences/theme/theme-preferences.interface';

@Component({
  selector: 'app-ipo-volume',
  templateUrl: './ipo-volume.component.html',
  styleUrls: ['./ipo-volume.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpoVolumeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public data: any;
  @Input() containerWidth: number = 0;
  @Input() containerHeight: number = 0;
  @Input() updatePosition: boolean = false;

  scichartService!: ScichartService;
  baseChart!: TWebAssemblyChart;

  public refChart: string = randomId('CHART_VOLUME_IPO');
  public seriesToShow: SeriesInfo[] = [];
  public tooltipX = 0;
  public tooltipY = 0;
  public displayTooltip = false;

  private _containerSizes!: any;
  private _candleYAxis!: NumericAxis;
  private _candleXAxis!: NumericAxis;
  private _theme$!: Subscription;
  private _initializeChartSubject = new Subject<void>();
  private _destroy = new Subject<void>();
  private _tooltipHeight = 0;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private stockChartService: StockChartService,
    private cdr: ChangeDetectorRef,
    private _flowInvestorsService: FlowInvestorsService,
    private _themeService: ThemePreferencesService,
    private _elementRef: ElementRef
  ) {
    this.scichartService = new ScichartService(undefined, stockChartService);
    this._themeService
      .themeActiveObservable()
      .pipe(delay(THEME_TIMER), takeUntil(this._destroy))
      .subscribe((data) => {
        this.scichartService.changeThemeHandler(
          this.baseChart,
          data === THEMES.dark
        );
      });

    this._initializeChartSubject
      .pipe(debounceTime(200), takeUntil(this._destroy))
      .subscribe(() => this._startChart());
  }

  ngOnInit() {
    this._initializeChartSubject.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { updatePosition } = changes;
    if (updatePosition?.currentValue) this._getChartContainerInfos();
  }

  ngOnDestroy(): void {
    this._theme$ && this._theme$.unsubscribe();
    this._destroy.next();
    this._destroy.complete();
  }

  private _startChart() {
    this.scichartService.initSciChart(this.refChart).then((res) => {
      this.baseChart = res;

      const labels = Object.keys(this.data);
      const labelProvider: any = new TextLabelProvider({
        labels: labels,
      });

      const stackedColumnCollection = new StackedColumnCollection(
        this.baseChart.wasmContext
      );
      stackedColumnCollection.dataPointWidth = 0.6;

      const xValues: any = labels.map((label, index) => index);
      const sectors: any = [];
      let totalValuesIpo: number = 0;

      labels.map((date: any) => {
        const tempSectors = Object.keys(this.data[date]);
        let tempTotalValues = 0;

        if (tempSectors.length) {
          for (let i = 0; i < tempSectors.length; i++) {
            const idxSectorTemp = sectors.findIndex(
              (sector: any) => sector.name === tempSectors[i]
            );

            const valueOffer = this.data[date][tempSectors[i]].sum_vl_offer;

            if (idxSectorTemp !== -1) {
              sectors[idxSectorTemp].years.push(date);
              sectors[idxSectorTemp].values.push(valueOffer);
            } else {
              sectors.push({
                name: tempSectors[i],
                years: [date],
                values: [valueOffer],
              });
            }

            tempTotalValues += valueOffer;
          }
          if (totalValuesIpo < tempTotalValues)
            totalValuesIpo = tempTotalValues;
        }
      });

      sectors.forEach((sector: any, index: number) => {
        const yValues: any = labels.map(() => 0);
        for (let y = 0; y < sector.years.length; y++) {
          const idxDateLabel = labels.findIndex(
            (date) => date === sector.years[y]
          );
          yValues[idxDateLabel] = sector.values[y];
        }

        const rendSeries = new StackedColumnRenderableSeries(
          this.baseChart.wasmContext,
          {
            dataSeries: new XyDataSeries(this.baseChart.wasmContext, {
              xValues,
              yValues: yValues,
              dataSeriesName: sector.name,
            }),
            fill: colorsSector[index],
            stroke: colorsSector[index],
            strokeThickness: 0,
            opacity: 1,
            stackedGroupId: 'StackedGroupId',
          }
        );

        stackedColumnCollection.add(rendSeries);
      });

      this.baseChart.sciChartSurface.renderableSeries.add(
        stackedColumnCollection
      );

      this._candleYAxis = new NumericAxis(this.baseChart.wasmContext, {
        axisAlignment: EAxisAlignment.Left,
        labelStyle: {
          fontSize: 9,
          alignment: ELabelAlignment.Right,
          padding: new Thickness(0, 15, 0, 0),
        },
        drawMajorGridLines: true,
        drawMinorGridLines: true,
        drawMajorBands: false,
        drawMajorTickLines: false,
        drawMinorTickLines: false,
        maxAutoTicks: 8,
        visibleRange: new NumberRange(0, totalValuesIpo / 6 + totalValuesIpo),
      });

      this._candleXAxis = new NumericAxis(this.baseChart.wasmContext, {
        labelProvider,
        labelStyle: {
          fontSize: 9,
          padding: new Thickness(20, 0, 0, 0),
        },
        drawMajorGridLines: false,
        drawMinorGridLines: false,
        drawMajorBands: false,
        drawMajorTickLines: false,
        drawMinorTickLines: false,
      });

      this._candleYAxis.labelProvider.formatCursorLabel = (value: any) =>
        getVolumeText(this.locale, value);
      this._candleYAxis.labelProvider.formatLabel = (value: any) =>
        getVolumeText(this.locale, value);
      this._candleXAxis.labelProvider.formatCursorLabel = (value: any) => {
        return labels[Math.round(value)];
      };
      this._candleXAxis.labelProvider.formatLabel = (value: any) => {
        return labels[value];
      };
      this.baseChart.sciChartSurface.xAxes.add(this._candleXAxis);
      this.baseChart.sciChartSurface.yAxes.add(this._candleYAxis);
      this.baseChart.sciChartSurface.background = '#FFFFFF00';

      this.baseChart.sciChartSurface.chartModifiers.add(
        new CursorModifier({
          showTooltip: true,
          tooltipContainerBackground: CHART_COLORS.NEUTRAL_STRONG,
          crosshairStroke: CHART_COLORS.NEUTRAL_MEDIUM,
          tooltipTextStroke: 'text-light',
          placementDivId: `chart-ipo-volume-tooltip-div-id_${this.refChart}`,
          tooltipSvgTemplate: (
            seriesInfos: SeriesInfo[],
            svgAnnotation: CursorTooltipSvgAnnotation
          ) => {
            this.displayTooltip = seriesInfos[0] !== undefined;
            const tooltip = this._flowInvestorsService.tooltipSvgTemplate(
              seriesInfos,
              svgAnnotation,
              350,
              30,
              {
                addZero: false,
                isFlexWrap: true,
              }
            );
            const value = tooltip.match(/height="(\d+)"/);
            this._tooltipHeight = value ? parseInt(value[1]) : 30;
            return tooltip;
          },
        })
      );
      this._getChartContainerInfos();
      this.stockChartService.removeChartLoading$.next({
        remove: true,
        ref: this.refChart,
      });
      this.cdr.detectChanges();
    });
  }

  // TOOLTIP CONTROLLER
  private _getChartContainerInfos(): void {
    const chartContainer = this._elementRef.nativeElement.querySelector(
      `#${this.refChart}`
    );
    if (!chartContainer) {
      console.error('CHART_CONTAINER_NOT_FOUND');
      return;
    }
    this._containerSizes = chartContainer.getBoundingClientRect();
    this.cdr.detectChanges();
  }

  public onMouseMove(event: MouseEvent) {
    if (!this._containerSizes) return;
    const mouseX = event.clientX - this._containerSizes.left + 15;
    const mouseY = event.clientY - this._containerSizes.top;
    if (
      mouseX < 1 ||
      mouseY < 1 ||
      mouseX > this._containerSizes.width ||
      mouseY > this._containerSizes.height
    ) {
      return;
    }
    const xLimitReached = event.clientX + 370 >= window.innerWidth;
    let leftPosition = mouseX;
    if (xLimitReached) leftPosition = leftPosition - 370;
    this.tooltipX = leftPosition;

    const yLimitReached =
      event.clientY + this._tooltipHeight >= window.innerHeight;
    let topPosition = event.offsetY + 140;
    if (yLimitReached) topPosition = topPosition - this._tooltipHeight - 20;
    this.tooltipY = topPosition;
  }
}
