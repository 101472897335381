import { Injectable } from '@angular/core';
import { BaseIndicator } from '../indicators';
import { TIGER_INDICATORS_ENUM } from '../enum';
import {
  CustomAnnotation,
  ECoordinateMode,
  EHorizontalAnchorPoint,
  EVerticalAnchorPoint,
  OhlcDataSeries,
  TWebAssemblyChart,
  XyDataSeries,
} from 'scichart';
import { Subject, delay, filter, map, tap } from 'rxjs';
import { CdlhammerBase } from '../indicators/base/cdlhammer-base';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { getTemplateHammer } from '../templates/tiger-chart.template';
import { NEW_ANNOTATION_INDICATOR } from '@shared/components/stock-chart/constants/stock-chart.constant';
import { Dictionary } from '@core/models';
import { IAddHammers } from '../interface';

@Injectable({
  providedIn: 'root',
})
export class HammersService {
  removeIndicator$ = new Subject<{
    refComponent: string;
    event: any;
    removeLegend: boolean;
  }>();
  addHammers$ = new Subject<IAddHammers>();
  updateGlobalIndicatorChart$ = new Subject<{
    globalIndicatorHammer: Dictionary<CustomAnnotation[]>;
    refComponent: string;
  }>();

  constructor() {
    this.addHammers$
      .pipe(
        delay(800),
        map((data) => {
          const { indicators } = data;
          const hammers = indicators.filter(
            (indicator) =>
              indicator.type == TIGER_INDICATORS_ENUM.CDL_HAMMER ||
              indicator.type == TIGER_INDICATORS_ENUM.CDL_INVERTED_HAMMER ||
              indicator.type == TIGER_INDICATORS_ENUM.CDL_DOJI
          );
          return { hammers, ...data };
        }),
        filter(({ hammers }) => hammers && hammers.length > 0),
        tap((data) => {
          const {
            hammers,
            baseChart,
            refComponent,
            xAxisId,
            candleDataSeries,
            lineDataSeries,
          } = data;
          let { globalIndicatorHammer } = data;
          hammers.forEach((hammer: BaseIndicator) => {
            const { type, lineNumber } = hammer;
            const key = `${NEW_ANNOTATION_INDICATOR}-${type}_${lineNumber}`;
            const indicator: any =
              baseChart.sciChartSurface.annotations.getById(key);
            if (!isNullOrUndefined(indicator) && !indicator.isHidden) {
              this.removeIndicator$.next({
                event: {
                  type,
                  lineNumber,
                },
                removeLegend: false,
                refComponent,
              });
              globalIndicatorHammer = this.addHammerIndicator(
                baseChart,
                xAxisId,
                hammer,
                type,
                candleDataSeries,
                lineDataSeries,
                globalIndicatorHammer
              );
            }
          });
        })
      )
      .subscribe(({ globalIndicatorHammer, refComponent }) => {
        this.updateGlobalIndicatorChart$.next({
          globalIndicatorHammer,
          refComponent,
        });
      });
  }

  addHammers(data: IAddHammers) {
    this.addHammers$.next(data);
  }

  addHammerIndicator(
    baseChart: TWebAssemblyChart,
    xAxisId: string,
    indicator: BaseIndicator,
    textIndicator: TIGER_INDICATORS_ENUM,
    candleDataSeries: OhlcDataSeries,
    lineDataSeries: XyDataSeries,
    globalIndicatorHammer: Dictionary<CustomAnnotation[]>
  ) {
    const newInd = indicator as CdlhammerBase;
    const objYAxis: any = {};
    const lowValues = candleDataSeries.getNativeLowValues();
    const highValues = candleDataSeries.getNativeHighValues();
    let allDifference = 0;
    const newLine = newInd.lineDataSeries.getNativeYValues();
    for (let i = 0; i < newInd.lineDataSeries.count(); i++) {
      if (newLine.get(i) != 0) {
        objYAxis[i] = {
          high: highValues.get(i),
          low: lowValues.get(i),
        };
        allDifference += objYAxis[i].high - objYAxis[i].low;
      }
    }
    if (Object.keys(objYAxis).length === 0) {
      return globalIndicatorHammer;
    }
    const difference = allDifference / Object.keys(objYAxis).length / 2;
    const newText = textIndicator.replace(/[a-z]/g, '').replace(/ /g, '');
    if (globalIndicatorHammer.has(textIndicator)) {
      globalIndicatorHammer.clear();
    }
    const hammers: CustomAnnotation[] = [];

    for (let i = 0; i < lineDataSeries.count(); i++) {
      if (i in objYAxis) {
        const y1 = lowValues.get(i);
        hammers.push(
          new CustomAnnotation({
            id: `${NEW_ANNOTATION_INDICATOR}-${textIndicator}_${indicator.lineNumber}`,
            x1: i,
            y1: y1 - difference,
            verticalAnchorPoint: EVerticalAnchorPoint.Top,
            horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
            yCoordinateMode: ECoordinateMode.DataValue,
            svgString: getTemplateHammer(
              indicator.renderSeriesConfig[0].color,
              newText
            ),
            xAxisId,
          })
        );
      }
    }
    globalIndicatorHammer.set(textIndicator, hammers);
    baseChart.sciChartSurface.annotations.add(...hammers);
    return globalIndicatorHammer;
  }
}
