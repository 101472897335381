import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IResumeList } from '@shared/components/business-profile/business-profile.interface';
import { DocumentsModalComponent } from '@shared/documents-modal/documents-modal.component';
import { RocketModalService } from '@shared/rocket-components/components';
import { NewsModalComponent } from '../news-modal/news-modal.component';

@Component({
  selector: 'app-mini-cards-list',
  templateUrl: './mini-cards-list.component.html',
  styleUrls: ['./mini-cards-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniCardsListComponent {
  @Input() lists: IResumeList[] = [];
  @Input() css = 'overflow-hidden';
  @Input() hasEvents: boolean = false;

  constructor(private modalService: RocketModalService) {}

  documentOpen(doc: any) {
    if (!this.hasEvents) return;
    if (doc.type && doc.type.includes('news')) {
      if (doc.url) {
        window.open(doc.url, '_blank');
      } else {
        this.modalService.open(NewsModalComponent, {
          data: {
            event: doc,
          },
        });
      }
    } else {
      this.modalService.open(DocumentsModalComponent, {
        data: {
          event: doc,
        },
      });
    }
  }
}
