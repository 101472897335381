import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { formatterNumber } from '@shared/rocket-components/utils';
import { getVolumeText, isNullOrUndefined } from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-orders-history-position-card',
  templateUrl: './orders-history-position-card.component.html',
  styleUrls: ['./orders-history-position-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersHistoryPositionCardComponent implements OnChanges {
  @Input() cdStock!: string;
  @Input() qttyFinal!: string;
  @Input() set pl(pl: string) {
    this._pl = getVolumeText(this.locale, parseFloat(pl), {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    this.plColor = this.getColorClass(pl);
  }
  get pl() {
    return this._pl;
  }
  @Input() set netDay(netDay: string) {
    this._netDay = parseInt(netDay);
  }
  get netDay() {
    return this._netDay.toString();
  }
  @Input() set vlPriceAvg(vlPriceAvg: any) {
    this._vlPriceAvg = parseFloat(vlPriceAvg)
  }
  get vlPriceAvg() {
    return formatterNumber(this._vlPriceAvg,  {
      maximumFractionDigits:
        !isNullOrUndefined(this.tickSize) ? parseInt(this.tickSize) : 2,
      minimumFractionDigits:
        !isNullOrUndefined(this.tickSize) ? parseInt(this.tickSize) : 2,
    });
  }
  @Input() set pnlOpen(pnlOpen: string) {
    this._pnlOpen = getVolumeText(this.locale, parseFloat(pnlOpen), {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    this.pnlOpenColor = this.getColorClass(pnlOpen);
  }
  get pnlOpen() {
    return this._pnlOpen;
  }
  @Input() set pnlDay(pnlDay: string) {
    this._pnlDay = getVolumeText(this.locale, parseFloat(pnlDay), {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    this.pnlDayColor = this.getColorClass(pnlDay);
  }
  get pnlDay() {
    return this._pnlDay;
  }
  @Input() set pnlTotal(pnlTotal: string) {
    this._pnlTotal = getVolumeText(this.locale, parseFloat(pnlTotal), {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    this.pnlTotalColor = this.getColorClass(pnlTotal);
  }
  get pnlTotal() {
    return this._pnlTotal;
  }
  @Input() set lastPrice(lastPrice: any) {
    this.chartLastPrice = parseFloat(lastPrice || 0);
    this._lastPrice = parseFloat(lastPrice || 0);
  }
  get lastPrice() {
    return formatterNumber(this._lastPrice, {
      maximumFractionDigits:
        !isNullOrUndefined(this.tickSize) ? parseInt(this.tickSize) : 2,
      minimumFractionDigits:
        !isNullOrUndefined(this.tickSize) ? parseInt(this.tickSize) : 2,
    });
  }
  @Input() set variation(variation: string) {
    this._variation = variation
      ? formatterNumber(parseFloat(variation))
      : '0,00';
  }
  get variation() {
    return this._variation;
  }
  @Input() variationColor!: string;
  @Input() variationFontColor!: string;
  @Input() situation!: string;
  @Input() chart!: number[];
  @Input() tickSize!: string;
  @Input() listContainerWidth: any;
  @Input() positionKey!: string;
  @Input() cdInstrumentType!: string;
  @Input() isSelected: boolean = false;
  @Output() resetPosition: EventEmitter<any> = new EventEmitter<any>()
  private _pl!: string;
  private _netDay!: number;
  private _vlPriceAvg!: number;
  private _pnlOpen!: string;
  private _pnlDay!: string;
  private _pnlTotal!: string;
  private _lastPrice!: number;
  private _variation!: string;

  public plColor!: string;
  public pnlOpenColor!: string;
  public pnlDayColor!: string;
  public pnlTotalColor!: string;
  public chartLastPrice!: number;
  public isCompact = false;
  public image!: string;

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  ngOnChanges(changes: SimpleChanges) {
    const { listContainerWidth, cdStock } = changes;
    if (listContainerWidth?.currentValue) {
      this.isCompact = listContainerWidth.currentValue <= 875;
    }

    if(cdStock?.currentValue) {
      this.image = `https://cdn.trademap.com.br/company_logos_icons/${this.getDsAsset()}.png`
    }
  }

  getColorClass = (data: string) => {
    const value = parseFloat(data);
    if (value !== 0) {
      return value > 0 ? 'text-feedback-success' : 'text-feedback-error';
    } else {
      return '';
    }
  };

  resetStockPosition(event: Event) {
    event?.stopPropagation()
    this.resetPosition.emit(this.positionKey)
  }

  private getDsAsset() {
    if(this.cdInstrumentType === 'BMF'){
      return this.cdStock.slice(0, 3)
    }else{
      return this.cdStock.slice(0, 4)
    }
  }
}
