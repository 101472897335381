import { Injectable } from '@angular/core';
import { AbstractCacheService } from './AbstractCacheService';
import { ISubsidiariesCountry } from '@shared/components/business-profile/business-profile.interface';

@Injectable({
  providedIn: 'root',
})
export class SubsidiariesCountryCacheService extends AbstractCacheService<
  ISubsidiariesCountry[]
> {}
