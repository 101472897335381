/**
 * Constante usada como prefixo para acessar a pasta assets da lib dos components
 */
export const BRAND_PREFIX = 'assets/brand/';
/**
 * Constante usada para nome padrão dos logos de dentro da pasta brand desejada
 */
export const DEFAULT_LOGO = '/logo-white.svg';
/**
 * Constante usada para nome padrão do ícone de dentro da pasta brand desejada
 */
export const DEFAULT_ICON = '/logo-icon.svg';
