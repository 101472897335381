import { Dictionary } from '@core/models';
import { BrowserStorageBase } from '@shared/services/core/storage/browser-storage-base';
import { RequestAccountRecoverWithTypeInterface } from '../../onboard-api.service';
import {
  RECOVERY_STEPS,
  RECOVERY_STORAGE,
  RECOVERY_TYPES,
} from './recovery.configs';
import { sharedSessionStorage } from '@shared/services/core/storage/shared-session.storage';

export class RecoveryData {
  private storage = new BrowserStorageBase(sharedSessionStorage);
  private recoveryDataDict: Dictionary<any> = new Dictionary<any>();

  get recoveryData() {
    return this.recoveryDataDict.get(RECOVERY_STORAGE.RECOVERY_DATA);
  }

  get appFlow() {
    return (
      this.recoveryData?.appFlow ??
      this.storage.getItem(RECOVERY_STORAGE.APPFLOW)
    );
  }

  get recoveryInfoType() {
    return (
      this.recoveryData?.recoveryInfoType ??
      this.storage.getItem(RECOVERY_STORAGE.RECOVERYINFOTYPE)
    );
  }

  get recoveryInfo() {
    return (
      this.recoveryData?.recoveryInfo ??
      this.storage.getItem(RECOVERY_STORAGE.RECOVERYINFO)
    );
  }

  get documentType() {
    return (
      this.recoveryData?.documentType ??
      this.storage.getItem(RECOVERY_STORAGE.DOCUMENTTYPE)
    );
  }
  get operatingSystem() {
    return (
      this.recoveryData?.operatingSystem ??
      this.storage.getItem(RECOVERY_STORAGE.OPERATINGSYSTEM)
    );
  }

  get vlDocument() {
    return (
      this.recoveryData?.vlDocument ??
      this.storage.getItem(RECOVERY_STORAGE.VLDOCUMENT)
    );
  }

  get token() {
    return (
      this.recoveryData?.token ?? this.storage.getItem(RECOVERY_STORAGE.TOKEN)
    );
  }

  set resendPincodeTimeout(seconds: number) {
    this.recoveryData.set('resendPincodeTimeout', seconds);
  }

  get resendPincodeTimeout() {
    return this.recoveryData?.resendPincodeTimeout ?? 30;
  }

  updateRecoveryField(field: string, value: any) {
    this.recoveryDataDict.set(RECOVERY_STORAGE.RECOVERY_DATA, {
      [field]: value,
    });
    this.storage.setItem(field, value);
  }

  setRecoveryData(data: RequestAccountRecoverWithTypeInterface | any) {
    Object.keys(data).forEach((field: any) => {
      this.updateRecoveryField(field, data[field]);
    });
  }
  setToken(token: string) {
    this.updateRecoveryField(RECOVERY_STORAGE.TOKEN, token);
  }

  get recoveryStatus() {
    return (
      this.storage.getItem(RECOVERY_STORAGE.RECOVERYSTATUS) ??
      RECOVERY_STEPS.UNDEFINED
    );
  }

  isCompleted = () => this.recoveryStatus === RECOVERY_STEPS.COMPLETED;

  setRecoveryStatus(status: string) {
    this.updateRecoveryField(RECOVERY_STORAGE.RECOVERYSTATUS, status);
  }

  getValues = () => ({
    recoveryInfoType: this.recoveryInfoType,
    recoveryInfo: this.recoveryInfo,
    documentType: this.documentType,
    operatingSystem: this.operatingSystem,
    vlDocument: this.vlDocument,
  });

  isSmsRecovery = (): boolean => RECOVERY_TYPES.includes(this.recoveryInfoType);

  clear() {
    this.recoveryDataDict.clear();
    const storageKeys = [
      RECOVERY_STORAGE.APPFLOW,
      RECOVERY_STORAGE.RECOVERYINFOTYPE,
      RECOVERY_STORAGE.RECOVERYINFO,
      RECOVERY_STORAGE.DOCUMENTTYPE,
      RECOVERY_STORAGE.OPERATINGSYSTEM,
      RECOVERY_STORAGE.VLDOCUMENT,
      RECOVERY_STORAGE.TOKEN,
      RECOVERY_STORAGE.RECOVERYSTATUS,
    ];
    storageKeys.forEach((key) => {
      this.storage.removeItem(key);
    });
  }
}
