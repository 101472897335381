import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { RecommendationsBars } from '../../business-profile.interface';
import { formatByTick } from 'src/app/utils/utils.functions';
import { ISearchStock } from '@core/interface';

@Component({
  selector: 'app-recomendation-bars',
  templateUrl: './recomendation-bars.component.html',
  styleUrls: ['./recomendation-bars.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecomendationBarsComponent {
  @Input() recommendations!: RecommendationsBars[];
  @Input() widthClose!: number;
  @Input() set vlClose(vlClose: number) {
    if (vlClose) {
      this.priceClose = vlClose;
      this.updateBars();
    }
  }
  @Input() set stockSelected(stockSelected: ISearchStock) {
    if (stockSelected) {
      this.stock = stockSelected;
      this.updateBars();
    }
    this.cdr.detectChanges();
  }
  private stock!: ISearchStock;
  formattedClose = '';
  private priceClose = 0;

  constructor(private cdr: ChangeDetectorRef) {}

  private updateBars() {
    if (!this.stock) return;
    const cifrao = this.stock.cd_country === 'BR' ? 'R$' : '$';
    this.formattedClose = `${cifrao} ${formatByTick(
      this.priceClose.toString()
    )}`;
    this.cdr.detectChanges();
  }
}
