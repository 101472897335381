<ng-container *ngIf="useSmallVersion; else default">
  <app-dropdown
    (mouseenter)="preventCloseDP = true"
    (mouseleave)="preventCloseDP = false"
  >
    <a
      appDropdownButton
      type="button"
      class="hide-arrow nav-link py-2 px-1 lh-sm round-top dropdown-toggle d-inline-flex align-items-center"
      [rtDropnAutoClose]="false"
      [rtDropnEventOnClicked]="true"
      [rtDropnForceClose]="closeDP"
      (clickedOutside)="handleCloseDP()"
    >
      <span class="fw-bold stock-list-name d-block text-truncate">
        <fla-icon name="more_vert" css="text-light"> </fla-icon>
      </span>
    </a>

    <div class="list-max-height round-top">
      <a
        class="movers-options-custom-selector bg-neutral-strong dropdown-item pl-2 pr-0 pt-2 w-100 collapsed round-top"
        [ngClass]="{ 'position-fixed': isExpandedIndex }"
        data-bs-toggle="collapse"
        role="button"
        href="#moversIndexesTemplate"
        aria-controls="moversIndexesTemplate"
        [attr.aria-expanded]="closeDP"
        (click)="isExpandedIndex = !isExpandedIndex"
      >
        Índice:
        <span
          [ngClass]="{
            'text-brand-primary':
              currentMoversIndex.value !== moversIndexDefaultValue.value,
            'text-light':
              currentMoversIndex.value === moversIndexDefaultValue.value
          }"
        >
          {{ currentMoversIndex.value }}
        </span>
      </a>
      <div
        id="moversIndexesTemplate"
        [ngClass]="{ 'position-relative': isExpandedIndex }"
        class="collapse collapse-items-custom"
      >
        <ng-template
          *ngTemplateOutlet="
            moversIndexesTemplate;
            context: { displayListOnly: true }
          "
        ></ng-template>
      </div>
    </div>
    <ng-container *ngIf="displayPeriods">
      <div class="w-100 border-bottom border-neutral-stronger"></div>
    </ng-container>
    <div class="list-max-height round-bottom">
      <a
        class="movers-options-custom-selector bg-neutral-strong dropdown-item pl-2 pr-0 pt-2 w-100 round-bottom"
        [ngClass]="{ 'position-fixed': isPeriodExpanded }"
        data-bs-toggle="collapse"
        role="button"
        href="#moversPeriodTemplate"
        aria-controls="moversPeriodTemplate"
        (click)="isPeriodExpanded = !isPeriodExpanded"
      >
        Período:
        <span
          [ngClass]="{
            'text-brand-primary':
              currentMoversPeriod.value !== moversPeriodDefaultValue.value,
            'text-light':
              currentMoversPeriod.value === moversPeriodDefaultValue.value
          }"
        >
          {{ currentMoversPeriod.label }}
        </span>
      </a>
      <div
        id="moversPeriodTemplate"
        [ngClass]="{ 'position-relative': isPeriodExpanded }"
        class="collapse collapse-items-custom"
      >
        <ng-template
          *ngTemplateOutlet="
            moversPeriodTemplate;
            context: { displayListOnly: true }
          "
        ></ng-template>
      </div>
    </div>
  </app-dropdown>
</ng-container>

<ng-template #default>
  <div class="hstack h-100">
    <ng-template
      *ngTemplateOutlet="
        moversIndexesTemplate;
        context: { displayListOnly: false }
      "
    ></ng-template>
    <ng-container *ngIf="displayPeriods">
      <div class="vr bg-neutral-strong custom-divider"></div>
    </ng-container>
    <ng-template
      *ngTemplateOutlet="
        moversPeriodTemplate;
        context: { displayListOnly: false }
      "
    ></ng-template>
  </div>
</ng-template>

<ng-template
  #appMoversOptions
  let-type="type"
  let-currentValueLabel="currentValueLabel"
  let-labelField="labelField"
  let-valueField="valueField"
  let-options="options"
  let-currentValue="currentValue"
  let-defaultValue="defaultValue"
  let-displayListOnly="displayListOnly"
>
  <app-movers-options
    [currentValueLabel]="currentValueLabel"
    [labelField]="labelField"
    [valueField]="valueField"
    [options]="options"
    [currentValue]="currentValue"
    [defaultValue]="defaultValue"
    [displayListOnly]="displayListOnly"
    (handleSelectedOption)="handleMoversOption(type, $event)"
  ></app-movers-options>
</ng-template>

<ng-template #moversIndexesTemplate let-displayListOnly="displayListOnly">
  <ng-container *ngIf="displayIndex">
    <ng-template
      *ngTemplateOutlet="appMoversOptions; context: { 
      type: 'INDEX',
      currentValueLabel: 'value',
      labelField: 'value',
      valueField: 'value',
      options: moversIndexes,
      currentValue: currentMoversIndex,
      defaultValue: moversIndexDefaultValue,
      displayListOnly: displayListOnly,
  }"
    ></ng-template>
  </ng-container>
</ng-template>

<ng-template #moversPeriodTemplate let-displayListOnly="displayListOnly">
  <ng-container *ngIf="displayPeriods">
    <ng-template
      *ngTemplateOutlet="appMoversOptions; context: { 
        type: 'PERIOD',
        currentValueLabel: 'abbreviation',
        labelField: 'label',
        valueField: 'value',
        options: periods,
        currentValue: currentMoversPeriod,
        defaultValue: moversPeriodDefaultValue,
        displayListOnly: displayListOnly,
  }"
    ></ng-template>
  </ng-container>
</ng-template>
