import { Injectable } from '@angular/core';
import {
  OrderStatusColor,
  Package,
  UserAuthenticated,
} from '@services/api/authentication/v1/login-response.interface';
import { AUTH_SESSION_KEYS } from '@services/core/const/auth_util.const';
import { StorageUtils } from '@services/core/storage/storage.utils';
import { genDict } from 'src/app/utils/utils.functions';
import { IAlert, IBroker } from '../interface';
import { BrokerAccountModel } from '@core/models';
import { DaytradeLabels } from '@core/layout/header/daytrade/daytrade-labels';
import { ReplaySubject } from 'rxjs';
import { TTokenPreferenceAuthenticator } from '@core/layout/header/modal-token-config/types';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  static _instance: SystemService;
  private _allBrokers: IBroker[] = [];
  private _allSimulatedAccounts: BrokerAccountModel[] = [];
  private _allAlerts: IAlert[] = [];
  private _userAuthenticated!: UserAuthenticated;
  private _idInvestor!: any;
  private _profilePicture: string = '';
  private _ordersStatusColor!: any[];
  private _packages!: any[];
  private _authenticationMethod: TTokenPreferenceAuthenticator | undefined =
    undefined;
  private _isValidatedPhone: boolean | undefined = undefined;
  private _tradeModeLabels: DaytradeLabels = new DaytradeLabels();
  private _tradeModeLabels$: ReplaySubject<DaytradeLabels> =
    new ReplaySubject<DaytradeLabels>();

  private getBySession(key: string) {
    return StorageUtils.getItemSessionStorage(key);
  }

  get alerts() {
    return this._allAlerts;
  }

  set alerts(alerts: IAlert[]) {
    this._allAlerts = alerts;
  }

  get brokers() {
    if (this._allBrokers && this._allBrokers.length) return this._allBrokers;
    return JSON.parse(this.getBySession(AUTH_SESSION_KEYS.BROKERS));
  }

  set brokers(brokers: IBroker[]) {
    this._allBrokers = brokers;
    StorageUtils.setItemSessionStorage(
      AUTH_SESSION_KEYS.BROKERS,
      JSON.stringify(brokers)
    );
  }

  get simulatedAccounts() {
    if (this._allSimulatedAccounts && this._allSimulatedAccounts.length)
      return this._allSimulatedAccounts;
    return JSON.parse(this.getBySession(AUTH_SESSION_KEYS.SIMULATED_BROKERS));
  }

  set simulatedAccounts(brokers: BrokerAccountModel[]) {
    this._allSimulatedAccounts = brokers;
    StorageUtils.setItemSessionStorage(
      AUTH_SESSION_KEYS.BROKERS,
      JSON.stringify(brokers)
    );
  }

  get profilePicture() {
    return (
      this._profilePicture || this.getBySession(AUTH_SESSION_KEYS.PROFILE_KEY)
    );
  }

  set profilePicture(picture: string) {
    this._profilePicture = picture ?? 'assets/user-profile.png';
    StorageUtils.setItemSessionStorage(
      AUTH_SESSION_KEYS.PROFILE_KEY,
      this._profilePicture
    );
  }

  get userAuthenticated() {
    return (
      this._userAuthenticated ||
      this.getBySession(AUTH_SESSION_KEYS.USER_AUTHENTICATED)
    );
  }

  set userAuthenticated(user: UserAuthenticated) {
    this._userAuthenticated = user;
    StorageUtils.setItemSessionStorage(
      AUTH_SESSION_KEYS.USER_AUTHENTICATED,
      user
    );
  }

  get idInvestor() {
    return this._idInvestor || this.getBySession(AUTH_SESSION_KEYS.ID_INVESTOR);
  }

  set idInvestor(id_investor: any) {
    this._idInvestor = id_investor;
    StorageUtils.setItemSessionStorage(
      AUTH_SESSION_KEYS.ID_INVESTOR,
      id_investor
    );
  }

  get ordersStatusColor() {
    return (
      this._ordersStatusColor ||
      this.getBySession(AUTH_SESSION_KEYS.ORDER_STATUS_COLOR)
    );
  }

  set ordersStatusColor(orderStatusColor: OrderStatusColor[]) {
    const statusColors = genDict(orderStatusColor, 'cd_order_status');
    this._ordersStatusColor = statusColors;
    StorageUtils.setItemSessionStorage(
      AUTH_SESSION_KEYS.ORDER_STATUS_COLOR,
      statusColors
    );
  }

  get packages() {
    return this._packages || this.getBySession(AUTH_SESSION_KEYS.PACKAGES);
  }

  set packages(args: Package[]) {
    const packagesDict = genDict(args, 'cd_package');
    this._packages = packagesDict;
    StorageUtils.setItemSessionStorage(
      AUTH_SESSION_KEYS.PACKAGES,
      packagesDict
    );
  }

  set tradeModeLabels(labels: DaytradeLabels) {
    this._tradeModeLabels = labels;
    this._tradeModeLabels$.next(labels);
    StorageUtils.setItemSessionStorage(
      AUTH_SESSION_KEYS.TRADE_MODE_LABELS,
      JSON.stringify(labels)
    );
  }

  get tradeModeLabels() {
    return (
      this._tradeModeLabels ||
      JSON.parse(this.getBySession(AUTH_SESSION_KEYS.TRADE_MODE_LABELS))
    );
  }

  get tradeModeLabelsSubscription() {
    return this._tradeModeLabels$.asObservable();
  }

  set authenticationMethod(method: TTokenPreferenceAuthenticator | undefined) {
    this._authenticationMethod = method;
  }

  get authenticationMethod(): TTokenPreferenceAuthenticator | undefined {
    return this._authenticationMethod;
  }

  set isValidatedPhone(isValidated: boolean | undefined) {
    this._isValidatedPhone = isValidated;
    StorageUtils.setItemSessionStorage(
      AUTH_SESSION_KEYS.VALIDATED_PHONE,
      isValidated ? '1' : '0'
    );
  }

  get isValidatedPhone(): boolean | undefined {
    if (this._isValidatedPhone === undefined) {
      const sessionValue = this.getBySession(AUTH_SESSION_KEYS.VALIDATED_PHONE);
      if (sessionValue === null) return undefined;
      this._isValidatedPhone = sessionValue === '1';
      return this._isValidatedPhone;
    }
    return this._isValidatedPhone;
  }

  constructor() {
    return (SystemService._instance = SystemService._instance || this);
  }
}

export const system: SystemService = new SystemService();
