import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { debounceTime, Subject } from 'rxjs';

export interface ICarouselContent {
  title: string;
  content: string;
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class CarouselComponent implements OnChanges, OnDestroy {
  @Input() public slides: ICarouselContent[] = [];
  public currentIndex = 0;

  private _carouselIntervalSubject = new Subject<void>();
  private _cdr = inject(ChangeDetectorRef);

  constructor() {
    this._carouselIntervalSubject.pipe(debounceTime(5000)).subscribe(() => {
      this.nextSlide();
      this._carouselIntervalSubject.next();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['slides']?.currentValue?.length) {
      this.currentIndex = 0;
      this._carouselIntervalSubject.next();
    }
  }

  ngOnDestroy() {
    this._carouselIntervalSubject.unsubscribe();
  }

  nextSlide() {
    const nextIndex = this.currentIndex + 1;
    this.currentIndex = nextIndex < this.slides.length ? nextIndex : 0;
    this._cdr.detectChanges();
  }

  goToSlide(index: number) {
    this.currentIndex = index;
    this._carouselIntervalSubject.next();
    this._cdr.detectChanges();
  }
}
