import { formatNumber } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { CoordinateCalculatorBase } from 'scichart/Charting/Numerics/CoordinateCalculators/CoordinateCalculatorBase';
import { Point } from 'scichart/Core/Point';
import {
  CustomAnnotation,
  ICustomAnnotationOptions,
} from 'scichart/Charting/Visuals/Annotations/CustomAnnotation';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import {
  DrawToolConfiguration,
  TIGER_CHART_TOOL,
  TIGER_CHART_TOOL_CHILD,
} from '../tiger-chart-tools/tiger-chart-tools.interface';
import { TMouseEventData } from '../types/tiger-chart.types';
import {
  Axis,
  DrawTools,
  DrawToolsReposition,
  LineToolsOptions,
  StyleTool,
} from './draw-tools.interface';
import { TYPE_RESIZE } from './draw-tools.enum';
import { Subscription } from 'rxjs';

export class TrendAngleLineTools extends CustomAnnotation implements DrawTools {
  tool!: TIGER_CHART_TOOL;
  children = [];
  isDraw = true;
  startEdit = false;
  baseChart!: TWebAssemblyChart;
  isChildren = false;
  lastPointValues!: Point;
  style: StyleTool = {};
  dictIdChildren!: string;
  hasElements: boolean = false;
  configuration!: DrawToolConfiguration | undefined;
  isAux: boolean = false;
  endDraw: boolean = false;
  lastEventData!: TMouseEventData;
  axisSub!: Subscription;
  isWithinDataBounds: boolean | undefined = true;

  constructor(
    options: ICustomAnnotationOptions,
    private drawToolsService: DrawToolsService,
    tool: LineToolsOptions,
    @Inject(LOCALE_ID) private locale: string
  ) {
    super(options);
    this.isChildren = tool.isChildren;
    this.baseChart = tool.baseChart;
    this.tool = tool.tool;
    this.dictIdChildren = tool.dictIdChildren;
    this.configuration = tool.configuration;
    this.isAux = tool.isAux;
    this.lastEventData = tool.eventData;
    this.isWithinDataBounds = tool.eventData.isWithinDataBounds;
    this.initSubscriptions();
  }

  initSubscriptions() {
    this.axisSub = this.drawToolsService.axisChanged$.subscribe((data) => {
      if (data.type === 'XAXIS') {
        this.tool.children?.forEach((aux) => {
          const auxDraw = this.baseChart.sciChartSurface.annotations.getById(
            aux.dictIdChildren!!
          ) as DrawTools;
          aux.isAux && auxDraw && (auxDraw.isDrawing = false);
        });
      } else {
        if (this.tool.hasBoxAux && this.tool.children) {
          const child = this.tool.children.find((c) => c.isAux)!!;
          const aux = this.baseChart.sciChartSurface.annotations.getById(
            child.dictIdChildren!!
          ) as DrawTools;
          const element = document.getElementById(this.tool.dictId!!);
          const auxBorders = aux.getAnnotationBorders();
          element!.setAttribute('y2', `${auxBorders.y2 - auxBorders.y1}`);
        }
      }
    });
  }

  unsubscriber(): void {
    this.axisSub.unsubscribe();
  }

  override update(
    xCalc: CoordinateCalculatorBase,
    yCalc: CoordinateCalculatorBase,
    xCoordSvgTrans: number,
    yCoordSvgTrans: number
  ): void {
    super.update(xCalc, yCalc, xCoordSvgTrans, yCoordSvgTrans);
    if (this.style) {
      this.updateStyleTool(this.style);
    }
  }

  protected override setAnnotationBorders(
    x1: number,
    x2: number,
    y1: number,
    y2: number
  ): void {
    super.setAnnotationBorders(x1, x2, y1, y2);
    this.setStyleConfiguration();
  }

  getName() {
    return this.constructor.name;
  }

  updateDrawTool(
    eventData: TMouseEventData,
    // eslint-disable-next-line
    countStepClickTool: number,
    reposition?: DrawToolsReposition
  ) {
    this.lastEventData = eventData;
    const annotation = this.baseChart.sciChartSurface.annotations.getById(
      reposition!!.id || this.tool.dictId!!
    );
    const svgElement = document.getElementById(`SVG_${this.tool.dictId!!}`);
    const element = document.getElementById(this.tool.dictId!!);
    const x2 = (this.tool.mouseValues!!.x - eventData.mousePoint!!.x) * -1;
    const y2 = (this.tool.mouseValues!!.y - eventData.mousePoint!!.y) * -1;
    if (svgElement) {
      if (element) {
        element.setAttribute('x2', `${x2}`);
        element.setAttribute('y2', `${y2}`);
        annotation.x2 = eventData.pointValues!!.x;
        annotation.y2 = eventData.pointValues!!.y;
        this.updateAngle(x2, y2);
        if (this.tool.hasBoxAux && this.tool.children) {
          const child = this.tool.children.find((c) => c.isAux)!!;
          const aux = this.baseChart.sciChartSurface.annotations.getById(
            child.dictIdChildren!!
          ) as DrawTools;
          aux.x1 = annotation.x1;
          aux.x2 = annotation.x2;
          aux.y2 = annotation.y2;
          const auxBorders = aux.getAnnotationBorders();
          element.setAttribute('x2', `${auxBorders.x2 - auxBorders.x1}`);
        }
      }
      const draw = this.drawToolsService.drawDict.get(this.tool.dictId!!)!!;
      draw.svgString = svgElement.outerHTML!!;
    }
  }

  private updateAngle(x2: number, y2: number) {
    const pathElement = document.getElementById(`PATH_${this.tool.dictId!!}`);
    const textElement = document.getElementById(`TEXT_${this.tool.dictId!!}`);
    const element = document.getElementById(this.tool.dictId!!);
    if (!element) {
      return;
    }
    const p2 = {
      x: parseFloat(element.getAttribute('x2')!!),
      y: parseFloat(element.getAttribute('y2')!!),
    };
    const p1 = {
      x: 0,
      y: 0,
    };
    const angleRadians = Math.atan2(p2.y - p1.y, p2.x - p1.x);
    let angleDeg = (angleRadians * 180) / Math.PI;
    if (pathElement) {
      let angleLimit = 0,
        isReverse = false;
      if (x2 > 0 && y2 < 0) {
        angleLimit = 90;
        angleDeg = angleDeg * -1 > angleLimit ? angleLimit : angleDeg * -1;
        pathElement.setAttribute(
          'd',
          `M 0 0 a 90 0 0 0 0 90 0 a 90 90 0 0 0 -${angleDeg} -${angleDeg}`
        );
      }
      if (x2 < 0 && y2 < 0) {
        angleLimit = 180;
        angleDeg = angleDeg * -1 > angleLimit ? angleLimit : angleDeg * -1;
        const repos = angleLimit - angleDeg;
        pathElement.setAttribute(
          'd',
          `M 0 0 a 90 0 0 0 0 90 0 a 90 90 0 0 0 -${angleDeg} -${repos}`
        );
      }
      if (x2 < 0 && y2 > 0) {
        angleLimit = 180;
        angleDeg = angleDeg > angleLimit ? angleLimit : angleDeg;
        const repos = angleLimit - angleDeg;
        isReverse = true;
        pathElement.setAttribute(
          'd',
          `M 0 0 a 90 0 0 0 0 90 0 a 90 90 0 0 1 -${angleDeg} ${repos}`
        );
      }
      if (x2 > 0 && y2 > 0) {
        angleLimit = 90;
        angleDeg = angleDeg > angleLimit ? angleLimit : angleDeg;
        isReverse = true;
        pathElement.setAttribute(
          'd',
          `M 0 0 a 90 0 0 0 0 90 0 a 90 90 0 0 1 -${angleDeg} ${angleDeg}`
        );
      }
      if (textElement) {
        textElement.textContent = `${formatNumber(
          isReverse ? -angleDeg : angleDeg,
          this.locale,
          `1.0-0`
        )} °`;
      }
    }
  }

  customClick = () => {
    //do nothing.
  };

  repositionZoomDraw(): void {
    //do nothing.
  }

  customHover = () => {
    //do nothing.
  };

  private getDataValues() {
    const { xAxis, yAxis } = this.getAxis();
    if (!xAxis || !yAxis) {
      return;
    }
    const borders = this.getAnnotationBorders();
    const y1 = yAxis.getCurrentCoordinateCalculator().getDataValue(borders.y1),
      y2 = yAxis.getCurrentCoordinateCalculator().getDataValue(borders.y2);
    const x1 = xAxis.getCurrentCoordinateCalculator().getDataValue(borders.x1),
      x2 = xAxis.getCurrentCoordinateCalculator().getDataValue(borders.x2);
    return { x1, x2, y1, y2 };
  }

  updateStyleTool(style: StyleTool) {
    const element = document.getElementById(`${this.tool.dictId!!}`);
    const strokeElement = document.getElementById(
      `STROKE_${this.tool.dictId!!}`
    );
    const pathElement = document.getElementById(`PATH_${this.tool.dictId!!}`);
    const textElement = document.getElementById(`TEXT_${this.tool.dictId!!}`);
    let childrenDraw!: TIGER_CHART_TOOL_CHILD;
    if (this.isChildren && this.tool.children) {
      childrenDraw = this.tool.children.find(
        (child) => child.dictIdChildren === this.dictIdChildren
      )!!;
    }
    if (element) {
      if (style.color) {
        element.setAttribute('stroke', style.color.rgbaHexColor);
        if (strokeElement) {
          strokeElement.setAttribute('stroke', style.color.rgbaHexColor);
        }
        if (pathElement) {
          pathElement.setAttribute('stroke', style.color.rgbaHexColor);
        }
        if (textElement) {
          textElement.setAttribute('fill', style.color.rgbaHexColor);
        }
        this.style.color = style.color;
        if (childrenDraw) {
          childrenDraw.color = style.color.rgbaHexColor;
        } else {
          this.tool.color = style.color.rgbaHexColor;
        }
      }
      if (style.strokeThickness) {
        element.setAttribute('stroke-width', style.strokeThickness.toString());
        this.style.strokeThickness = style.strokeThickness;
        if (childrenDraw) {
          childrenDraw.lineThickness = style.strokeThickness;
        } else {
          this.tool.lineThickness = style.strokeThickness;
        }
      }
      if (style.strokeDashArray) {
        element.setAttribute(
          'stroke-dasharray',
          style.strokeDashArray[0].toString()
        );
        this.style.strokeDashArray = style.strokeDashArray;
        if (childrenDraw) {
          childrenDraw.strokeDashArray = style.strokeDashArray;
        } else {
          this.tool.strokeDashArray = style.strokeDashArray;
        }
      }
    }
  }

  updateTool(tool: TIGER_CHART_TOOL) {
    this.tool = tool;
  }

  resizeSVG(
    typeResize: TYPE_RESIZE,
    childId: string,
    svgPosition?: { x1: number; x2: number; y1: number; y2: number },
    position?: { x1: number; x2: number; y1: number; y2: number }
  ) {
    const svgElement = document.getElementById(`SVG_${this.tool.dictId!!}`);
    const element = document.getElementById(`${this.tool.dictId!!}`);
    if (svgElement) {
      if (element) {
        if (svgPosition) {
          element.setAttribute('x2', `${svgPosition.x2}`);
          if (typeResize === TYPE_RESIZE.DRAG) {
            element.setAttribute('y2', `${svgPosition.y2}`);
            this.updateAngle(svgPosition.x2, svgPosition.y2);
          }
        }
        if (position) {
          this.x1 = position.x1;
          this.x2 = position.x2;
          this.y1 = position.y1;
          this.y2 = position.y2;
        }
      }
      this.svgString = svgElement.outerHTML!!;
      this.notifyPropertyChanged('svgString');
    }
  }

  private setStyleConfiguration() {
    if (!this.configuration || this.hasElements) {
      return;
    }
    const element = document.getElementById(`${this.tool.dictId!!}`);
    const strokeElement = document.getElementById(
      `STROKE_${this.tool.dictId!!}`
    );
    const pathElement = document.getElementById(`PATH_${this.tool.dictId!!}`);
    const textElement = document.getElementById(`TEXT_${this.tool.dictId!!}`);
    if (!element) {
      return;
    } else {
      this.hasElements = true;
    }

    if (element) {
      if (this.configuration.color) {
        element.setAttribute('stroke', this.configuration.color);
        if (strokeElement) {
          strokeElement.setAttribute('stroke', this.configuration.color);
        }
        if (pathElement) {
          pathElement.setAttribute('stroke', this.configuration.color);
        }
        if (textElement) {
          textElement.setAttribute('fill', this.configuration.color);
        }
      }
      if (this.configuration.lineThickness) {
        element.setAttribute(
          'stroke-width',
          this.configuration.lineThickness.toString()
        );
      }
      if (this.configuration.strokeDashArray) {
        element.setAttribute(
          'stroke-dasharray',
          this.configuration.strokeDashArray[0].toString()
        );
      }
    }
  }

  private getAxis(): Axis {
    const yAxis = this.baseChart.sciChartSurface.yAxes.getById(this.yAxisId);
    const xAxis = this.baseChart.sciChartSurface.xAxes.getById(this.xAxisId);
    if (!yAxis || !xAxis) {
      return { xAxis: undefined, yAxis: undefined };
    }
    try {
      yAxis.getCurrentCoordinateCalculator();
      xAxis.getCurrentCoordinateCalculator();
    } catch (e: any) {
      return { xAxis: undefined, yAxis: undefined };
    }
    return { yAxis, xAxis };
  }
}
