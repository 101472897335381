import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { INews } from '@core/interface/news-agency.interface';

@Component({
  selector: 'news-card-header',
  templateUrl: './news-card-header.component.html',
  styleUrls: ['./news-card-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsCardHeaderComponent implements OnInit {
  @Input() news!: INews | null;
  constructor() {}

  ngOnInit() {}

  getData() {
    if (!this.news) return;
    const currentDate = new Date();

    const { data_noticia, hora_noticia } = this.news;
    const year = data_noticia.slice(0, 4);
    const month = data_noticia.slice(4, 6);
    const day = data_noticia.slice(6);
    const offset = hora_noticia.length == 5 ? 0 : 1;
    const hours = hora_noticia.slice(0, 1 + offset);
    const minutes = hora_noticia.slice(1 + offset, 3 + offset);
    const newsDate = new Date(+year, +month - 1, +day, +hours, +minutes);

    if (
      currentDate.getUTCHours() == newsDate.getUTCHours() &&
      currentDate.getUTCMinutes() == newsDate.getUTCMinutes()
    ) {
      return 'agora';
    }

    const differenceInMinutes = (+currentDate - +newsDate) / 1000 / 60;
    if (differenceInMinutes < 60) {
      return `há ${Math.ceil(differenceInMinutes)} minutos`;
    }

    const differenceInHours = differenceInMinutes / 60;
    if (differenceInHours <= 23) {
      return `há ${Math.ceil(differenceInHours)} horas`;
    }

    const differenceInDays = Math.ceil(differenceInHours / 24);
    return `há ${Math.ceil(differenceInDays)} dia${
      differenceInDays > 1 ? 's' : ''
    }`;
  }

  getTooltip() {
    return `<p class="mb-1">Uma notícia igual a essa foi publicada por:</p>
        <span class="text-brand-primary">
          ${this.news?.copy_news.ds_news_agency_nickname}
        </span>
        há ${
          this.news?.copy_news.dt_news_formatted ||
          this.news?.copy_news.dh_news_formatted
        }`;
  }
}
