<section class="p-4" style="height: calc(100% + 8px)">
  <div class="w-100 h-100 d-inline-flex">
    <div
      class="d-flex flex-column border-right border-neutral-strong position-relative"
      style="min-width: 200px; max-width: 200px"
    >
      <span class="fw-bold mb-3">Filtros</span>
      <div style="max-height: 80%; overflow-y: auto">
        <ng-container *ngFor="let filter of filters">
          <div
            class="w-100 mb-2 d-inline-flex align-items-center justify-content-between"
          >
            <div class="d-inline-flex align-items-center">
              <label
                for="strategyOption"
                class="cursor-pointer text-truncate"
                (click)="changeFilter(filter)"
                [style.max-width.px]="166"
              >
                <input
                  class="form-check-input mr-1"
                  type="checkbox"
                  name="strategyOption"
                  [ngModel]="filter.id === filterSelected.id"
                  [style.min-width.px]="18"
                />
                {{ filter.name }}
              </label>
            </div>
            <rocket-button
              *ngIf="filters.length > 1 && !filter.isStart"
              type="icon"
              (rocketClick)="deleteFilter(filter.id)"
            >
              <fla-icon name="delete" size="icon-size-micro"></fla-icon>
            </rocket-button>
            <fla-icon
              *ngIf="filter.isStart"
              name="stop_circle"
              size="icon-size-tiny"
              css="pr-1 text-feedback-error"
              tooltip="Executando"
            ></fla-icon>
          </div>
        </ng-container>
      </div>

      <div class="w-100 d-inline-flex position-absolute bottom-0 pr-2">
        <rocket-button
          label="Criar filtro"
          css="w-100"
          (rocketClick)="createFilter()"
        ></rocket-button>
      </div>
    </div>

    <div
      class="d-flex flex-column border-right border-neutral-strong position-relative mx-3"
      style="min-width: 266px; max-width: 266px"
    >
      <fla-input
        css="pr-3"
        [placeholder]="'Pesquisar'"
        [isSmall]="false"
        [isRequired]="false"
        [formControl]="searchControl"
        decidePosition="prefix"
        #inputSearch
        autofocus
      >
        <div class="prefix">
          <fla-icon name="search" size="icon-size-sm"></fla-icon>
        </div>
      </fla-input>

      <div
        id="indicators-screening-body"
        class="overflow-auto"
        [ngClass]="{ 'opacity-medium': !isSelectIndicators }"
        *ngIf="quotes.length || filteredIndicators.length || custom.length"
      >
        <div class="fw-bold text-white fs-4">Cotações</div>
        <div
          *ngFor="let quote of quotes"
          class="align-items-center d-flex justify-content-between ml-2"
        >
          <button
            (click)="selectCondition(quote)"
            type="button"
            [disabled]="quote.checked"
            class="btn btn-fill-primary btn-sm btn-without-hover"
          >
            {{ quote.name }}
          </button>
        </div>
        <div class="fw-bold text-white fs-4">Indicadores</div>
        <div *ngFor="let group of filteredIndicators">
          <button
            class="btn btn-fill-primary btn-sm w-100 text-left sized-font"
            type="button"
            [flaCollapse]="group.label + '_collapse'"
            [flaCollapseIsOpen]="group.open"
            (click)="group.open = !group.open"
          >
            {{ group.label }}
            <span class="material-icons-outlined ml-8">
              {{ group.open ? 'arrow_drop_up' : 'arrow_drop_down' }}
            </span>
          </button>
          <div
            class="padding-body-indicator pl-2"
            [id]="group.label + '_collapse'"
          >
            <div
              *ngFor="let indicator of group.indicators"
              class="align-items-center d-flex justify-content-between"
            >
              <button
                (click)="selectIndicator(indicator)"
                type="button"
                [disabled]="indicator.checked"
                class="btn btn-fill-primary btn-sm btn-without-hover"
              >
                {{ indicator.name }}
              </button>
            </div>
          </div>
        </div>

        <div class="fw-bold text-white fs-4">Personalizado</div>
        <div
          *ngFor="let item of custom"
          class="align-items-center d-flex justify-content-between ml-2"
        >
          <button
            (click)="selectCustom()"
            type="button"
            class="btn btn-fill-primary btn-sm btn-without-hover"
          >
            {{ item.name }}
          </button>
        </div>
      </div>
      <div
        class="d-flex flex-column"
        *ngIf="!quotes.length && !filteredIndicators.length && !custom.length"
      >
        <span class="fw-bold"> Nenhum indicador encontrado </span>
        <span class="fs-6">
          Realize a busca novamente por outro indicador.
        </span>
      </div>
    </div>

    <div style="width: calc(100% - 190px)">
      <app-edit-filter
        [screeningFilter]="filterSelected"
        [selectionConditions]="selectionConditions"
        (save)="saveEdition($event)"
        (isSelectIndicators)="selectIndicators($event)"
      ></app-edit-filter>
    </div>
  </div>
</section>
