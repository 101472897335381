import { ComponentRef, inject, Injectable, Type } from '@angular/core';
import { RocketModalConfig } from '@shared/rocket-components/components/modal/config/modal.config';
import { RocketIModal } from '@shared/rocket-components/components/modal/interfaces/modal.interface';
// @ts-expect-error omnitheme import
import { Modal } from '@omni/omnitheme';
import { DOCUMENT } from '@angular/common';
import { ROCKET_MODAL_DATA } from '@shared/rocket-components/components';

import {
  ViewRefReturn,
  RocketCreateComponentService,
} from '@shared/rocket-components/services';
import { SuperSearchService } from '@modules/home/super-search.service';
import { ModalOpenService } from './modal-open.service';

import { filter, Subject, Subscription, map } from 'rxjs';
import { ModalEventPayload } from './modal-event.model';
import { RxEvent } from '@shared/channel/rx-event';
import { isWebViewContext } from 'src/app/desktop/integration.utils';
import { MinimalModalInterface } from '../modal-parts/minimal-modal.model';
import { MODAL_EVENT } from './modal.const';

@Injectable()
export class RocketModalService {

  private element: HTMLElement | undefined = undefined;
  private element2: HTMLElement | undefined = undefined;
  private modal: Modal;
  private modal2!: Modal;
  private document = inject(DOCUMENT);
  public data = inject(ROCKET_MODAL_DATA);
  private data2 = inject(ROCKET_MODAL_DATA);
  private configs!: RocketIModal;
  private configs2!: RocketIModal;
  private toggleBetweenModal: HTMLElement | null = null;
  public outsideEvent!: () => void;
  private arrayComponentRef: ComponentRef<any>[] = [];
  private arrayComponentRef2: ComponentRef<any>[] = [];

  private modalEvent$ = new Subject<ModalEventPayload>();
  static readonly INFINITY_READ_STREAM = true;
  public static readonly MODAL_CHANNEL = MODAL_EVENT.CHANNEL;
  public static readonly MODAL_OPEN_ACTION = MODAL_EVENT.OPEN;
  public static readonly MODAL_CLOSE_ACTION = MODAL_EVENT.CLOSE;
  private finalizer = new Subscription();

  get isDisplayingSomeModal(): boolean {
    return this.element !== undefined;
  }

  constructor(
    private service: RocketCreateComponentService,
    private _superSearchService: SuperSearchService,
    private _rxEvent: RxEvent,
    private modalOpenService: ModalOpenService
  ) {
    this.streamInit();
    
  }

  open<T extends MinimalModalInterface>(
    component: Type<T>,
    settings?: RocketIModal
  ): ViewRefReturn {
    if (settings?.toggleBetweenModal) {
      settings.backdrop = false;
      this.toggleBetweenModal = settings.toggleBetweenModal
        .nativeElement as HTMLElement;
      this.toggleBetweenModal.classList.add('d-none');
      return this.openSecondModal(component, settings);
    }
    if (isWebViewContext()) {
      // emite evento para o janela de modal
      const path = this.service.create(component).componentRef.instance.getPath()
      this.emitOpenRxEvent(path, settings);
      return {
        afterDismissed: this.listenCloseEvent(),
      };
    }

    this.doOpenModal<T>(component, settings);

    return {
      afterDismissed: this.listenCloseEvent(),
    };
  }

  openInRoute<T extends MinimalModalInterface>(
    component: Type<T>,
    settings?: RocketIModal
  ): ViewRefReturn {
    this.doOpenModal<T>(component, settings);

    return {
      afterDismissed: this.listenCloseEvent(),
    };
  }

  close = (data?: any, isSecondModal = false): void => {
    try {
      this.closeWithoutEmitEvent(data, isSecondModal);
    }
    catch (err) {
      console.log(err);
    }
    finally {
      if (!isSecondModal) {
        this.emitCloseRxEvent(data);
      }
    }
  };

  public closeWithoutEmitEvent = (data?: any, isSecondModal = false): void => {
    document.removeEventListener('keyup', this.handleKeyUp);
    document.removeEventListener('click', this.outsideClickListener);
    const componentRef2 =
      this.arrayComponentRef2[this.arrayComponentRef2.length - 1];
    if (isSecondModal) {
      componentRef2?.instance.onClosed.next(data ?? { closed: true });
      this.modal2.hide();
      this.element2?.remove();
      this.element2 = undefined;
      this.service.destroy(componentRef2!);
      this.toggleBetweenModal?.classList.remove('d-none');
      this.arrayComponentRef2.pop();
      return;
    } else {
      this.arrayComponentRef[
        this.arrayComponentRef.length - 1
      ]?.instance.onClosed.next(data ?? { closed: true });
      this.modal.hide();
      this.element?.remove();
      this.element = undefined;
      this.modalOpenService.dispatchDisplaying(false);
      this.service.destroy(
        this.arrayComponentRef[this.arrayComponentRef.length - 1]!
      );
      this.arrayComponentRef.pop();
    }
    if (this.configs?.lockScreen || this.configs2?.lockScreen) {
      this._superSearchService.isScreenLocked = false;
    }

    const modal = document.getElementsByClassName('modal-backdrop');
    modal[modal.length - 1]?.remove();
  };

  public doOpenModal<T extends MinimalModalInterface>(
    component: Type<T>,
    settings: RocketIModal | undefined
  ): ViewRefReturn {
    const create = this.service.create(component);
    (create.componentRef.instance as any).standaloneClose = this.close;
    const newComponent = create.componentRef;
    this.arrayComponentRef.push(newComponent);

    this.element = create.rootNodeElement.firstChild?.firstChild as HTMLElement;
    this.modalOpenService.dispatchDisplaying(true);
    this.configs = new RocketModalConfig(this.element!, settings).modalConfig;
    Object.assign(this.data, settings?.data);
    this.configs.appendTo!.append(create.rootNodeElement);
    if (this.configs.lockScreen) {
      this._superSearchService.isScreenLocked = true;
    }
    setTimeout(() => {
      this.modal = new Modal(this.element, {
        ...this.configs,
        element: this.configs.appendTo,
      });
      this.modal.show();
      this.closedEvent(this.element!);
      this.configs?.keyboard && this.listenForEscapeKey();
      this.configs?.backdrop &&
        this.configs?.backdrop !== 'static' &&
        this.clickOutsideEvent();
    }, 100);

    return { afterDismissed: newComponent!.instance.afterClosed() };
  }

  private closedEvent(element: HTMLElement): void {
    const closeBtn = element?.querySelector('[data-bs-dismiss="modal"]');
    closeBtn?.addEventListener('click', () => this.close());
  }

  private handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      const modals = (event.currentTarget as any).querySelectorAll(
        'rocket-modal'
      );
      const child = modals[modals.length - 1].children[0];
      if (child.id === this.modal._element.id) {
        this.close();
      }
    }
  };

  private listenForEscapeKey() {
    document.addEventListener('keyup', this.handleKeyUp);
  }

  private outsideClickListener = (event: MouseEvent) => {
    if (this.element?.id === (event.target as HTMLElement).id) {
      //caso necessite utilizar um callback especifico
      if (this.outsideEvent) {
        this.outsideEvent();
        return;
      }
      this.close();
    }
  };

  private clickOutsideEvent(): void {
    // Não está reconhecendo os botoes do body como items internos do modal
    document.addEventListener('click', this.outsideClickListener);
  }

  private openSecondModal(
    component: Type<any>,
    settings?: RocketIModal
  ): ViewRefReturn {
    const create = this.service.create(component);
    const componentRef2 = create.componentRef;
    this.arrayComponentRef2.push(componentRef2);
    this.element2 = create.rootNodeElement.firstChild
      ?.firstChild as HTMLElement;

    this.configs2 = new RocketModalConfig(this.element2!, settings).modalConfig;

    Object.assign(this.data2, settings?.data);

    this.document.body.append(create.rootNodeElement);

    this.modal2 = new Modal(this.element2, this.configs2);

    this.modal2.show();
    this.closedEvent(this.element2);

    return { afterDismissed: componentRef2!.instance.afterClosed() };
  }

  public resetDataInfos(): void {
    try {
      this.data = structuredClone(inject(ROCKET_MODAL_DATA));
    } catch (err) {
      // ignore log
    }
  }

  dismissAll() {
    const modals = this.document.getElementsByTagName('rocket-modal');
    while (modals.length) {
      const parent = modals.item(0)?.parentElement;
      if (parent) {
        parent.remove();
      }
    }
  }

  closeStream(): void {
    this.finalizer.unsubscribe();
  }


  public emitOpenRxEvent(path: string, settings: RocketIModal | undefined) {
    this._rxEvent.emit(RocketModalService.MODAL_CHANNEL, {
      channel: RocketModalService.MODAL_CHANNEL,
      settings: settings,
      path: path,
      action: RocketModalService.MODAL_OPEN_ACTION,
    } as ModalEventPayload);
  }

  public emitCloseRxEvent(data:any = {}) {
    this._rxEvent.emit(RocketModalService.MODAL_CHANNEL, {
      channel: RocketModalService.MODAL_CHANNEL,
      path: '',
      settings: data,
      action: RocketModalService.MODAL_CLOSE_ACTION,
    } as ModalEventPayload);
  }

  async streamInit() {
    try {
      const streamResult = await this._rxEvent.read(
        RocketModalService.MODAL_CHANNEL
      );
      this.readStream(streamResult.stream);
      this.finalizer.add(streamResult.close);
    } catch (error) {
      console.error('Error reading context menu event', error);
    }
  }

  private async readStream(stream: ReadableStream) {
    const reader = stream.getReader();
    let ret;
    do {
      ret = await reader.read();
      if (!ret.done) {
        this.modalEvent$.next(ret.value.data);
      }
    } while (RocketModalService.INFINITY_READ_STREAM);
  }

  private listenEvent() {
    return this.modalEvent$
      .asObservable()
  }

  public listenCloseEvent() {
    return this.modalEvent$
      .asObservable()
      .pipe(
        filter(
          (data: ModalEventPayload) =>
            data.action === RocketModalService.MODAL_CLOSE_ACTION
        ),
        map((data: ModalEventPayload) => data.settings)
      );
  }

  public listenOpenEvent() {
    return this.modalEvent$
      .asObservable()
      .pipe(
        filter(
          (data: ModalEventPayload) =>
            data.action === RocketModalService.MODAL_OPEN_ACTION
        ),
      );
  }
}
