import { Component } from '@angular/core';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';

@Component({
  selector: 'app-stock-analysis-context-menu',
  template: `
    <div
      class="round bg-neutral-strong position-absolute"
      [style.width]="'200px'"
      [style.padding]="'10px'"
      [style.top.px]="position.top"
      [style.left.px]="position.left"
      [style.z-index]="9999"
      [style.display]="'block'"
      (mouseleave)="onFocusOut()"
    >
      <div class="list-group list-group-flush">
        <div class="header-context-menu">
          <span>Análise de ativos</span>
        </div>

        <button
          type="button"
          class="list-group-item list-group-item-action border-none fs-6 context-item-button"
          disabled
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="refresh"
          ></fla-icon>
          Restaurar
        </button>
        <button
          type="button"
          class="list-group-item list-group-item-action border-none fs-6 context-item-button"
          disabled
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="help_outline"
          ></fla-icon>
          Ajuda
        </button>
      </div>
    </div>
  `,
})
export class StockAnalysisContextMenuComponent {
  show = false;
  position!: { top: number; left: number };
  parent_id!: string;

  constructor(private contextMenuService: ContextMenuService) {}

  static openContextMenu(
    contextMenuService: ContextMenuService,
    parent_id: string,
    event: { clientX: number; clientY: number }
  ) {
    contextMenuService.openContextMenu(parent_id, {
      type: 'HeatmapContextMenuComponent',
      event: event,
      configurationData: {},
    });
  }

  private emitEvent(action: StockAnalysisContextMenuComponent) {
    this.contextMenuService.emitEvent(this.parent_id, action);
  }

  onFocusOut() {
    this.contextMenuService.closeContextMenu(this.parent_id);
  }
}
