export const MESSAGES = {
  requiredText: 'Preencha o campo.',
  requiredDates: 'Informe as datas.',
  requiredDate: 'Informe a data.',
  selectOne: 'Selecione uma opção.',
  selectAtLeastOne: 'Selecione ao menos uma opção.',
  userOrPassInvalid: 'Usuário e/ou senha inválido(s).',
  userInvalid: 'Usuário inválido.',
  PassInvalid: 'Senha inválido.',
  UerNotFound: 'Usuário não encontrado.',
  ValueNotFound: 'Nenhum resultado encontrado.',
  createRegister: 'Registro criado com sucesso.',
  updateRegister: 'Registro atualizado com sucesso.',
  deleteRegister: 'Registro excluído com sucesso.',
  requiredInputsTitle: 'Os campos com * são obrigatórios.',
  typeNotSupported: 'Tipo não suportado.',
  invalidFormat: 'Formato inválido.',
  noData: 'Não há dados.',
  invalidToken: 'Token incorreto. Por favor, tentar novamente.',
};
