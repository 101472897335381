import { THEMES } from '@shared/services/core/custom-preferences/theme/theme-preferences.interface';
import { BrowserStorageBase } from '@shared/services/core/storage/browser-storage-base';

export enum THEMES_ENUM {
  'LIGHT',
  'DARK',
}

export const processThemeClass = (theme: THEMES_ENUM): THEMES =>
  theme == THEMES_ENUM.DARK ? THEMES.dark : THEMES.light;

export const changeTheme = () => {
  const storage = new BrowserStorageBase(sessionStorage);
  const theme = storage.getItem('ACTIVE_THEME') ?? THEMES_ENUM.DARK;
  const htmlTag = document.body;
  if (htmlTag) {
    const classList = Array.from(htmlTag.classList);
    classList.forEach((cssClass) => htmlTag.classList.remove(cssClass));
    htmlTag.classList.add(processThemeClass(theme));
  }
};
