import {
  TTigerChartIndicatorCreateOptions,
  TigerChartIndicatorWeisWaveOptions,
} from './indicators.types';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { WeisWaveBase } from './base/weis-wave-base';

export class TigerChartIndicatorWeisWave extends WeisWaveBase {
  constructor(options: TigerChartIndicatorWeisWaveOptions) {
    super(options);
  }

  private tr(high: number, low: number, close: number[]) {
    return Math.max(
      high - low,
      Math.abs(high - close[0]),
      Math.abs(low - close[0])
    );
  }

  override create(
    options: TTigerChartIndicatorCreateOptions
  ): BaseRenderableSeries[] {
    this.updatePoints();
    return super.create(options);
  }

  override updatePoints(): void {
    this.wiseWave();
  }

  override updateSettings() {
    this.wiseWave();
    super.updateSettings();
  }

  private wiseWave() {
    const offsetHigh: number[] = Array(this.timePeriod).fill(
      this.data.vl_high[0]
    );
    const offsetLow: number[] = Array(this.timePeriod).fill(
      this.data.vl_low[0]
    );
    const offsetClose: number[] = Array(this.timePeriod).fill(
      this.data.vl_close[0]
    );
    const atr = this.service.call('ATR', {
      high: [...offsetHigh, ...this.data.vl_high],
      low: [...offsetLow, ...this.data.vl_low],
      close: [...offsetClose, ...this.data.vl_close],
      timePeriod: this.timePeriod,
    });
    atr.output = atr.output.slice(0, atr.output.length - this.timePeriod);
    const useClose = this.priceSource == 'CLOSE';
    const useOpenClose = this.priceSource == 'ABE_FCH' || useClose;
    let prevClose = 0;
    let direction = 0;
    let barCount = 0;
    let acumulattedVol = 0;
    let currClose = 0;
    const points = [];
    this.directions = [];
    for (let i = 0; i < this.data.id_point.length; i++) {
      const volume = this.data.vl_volume[i];
      const high = this.data.vl_high[i];
      const low = this.data.vl_low[i];
      const close = this.data.vl_close[i];
      const open = this.data.vl_open[i];
      const normalize = false;
      const vol = !volume ? this.tr(high, low, this.data.vl_close) : volume;
      const op = useClose ? close : open;
      const hi = useOpenClose ? (close >= op ? close : op) : high;
      const lo = useOpenClose ? (close <= op ? close : op) : low;
      const methodValue = Math.round(atr.output[i]);
      prevClose = currClose;
      const prevHigh = prevClose + methodValue;
      const prevLow = prevClose - methodValue;
      currClose = hi > prevHigh ? hi : lo < prevLow ? lo : prevClose;
      const actualDirection =
        currClose > prevClose ? 1 : currClose < prevClose ? -1 : direction;
      const directionHasChanged = direction != actualDirection;
      direction = structuredClone(actualDirection);
      const directionIsUp = direction > 0;
      const directionIsDown = direction < 0;
      barCount = !directionHasChanged && normalize ? barCount + 1 : barCount;
      const actualVol = !directionHasChanged ? vol + acumulattedVol : vol;
      acumulattedVol = structuredClone(actualVol);
      points.push(barCount > 1 ? actualVol / barCount : actualVol);
      this.directions.push({ directionIsUp, directionIsDown });
    }
    this.updatePaletteDirections();
    this.points = { output: points };
  }
}
