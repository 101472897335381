import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { AUTH_LOCAL_KEYS } from '../../const/auth_util.const';

@Injectable({
  providedIn: 'root',
})
export class FavoriteIndicatorPreferencesService extends CustomPreferencesService {
  constructor(public http: HttpClient) {
    super(http);
  }

  set favoriteIndicators(favoriteIndicators: any[]) {
    this.setValueCustomPreferences(
      AUTH_LOCAL_KEYS.CHART_FAVORITE_INDICATORS,
      JSON.stringify(favoriteIndicators),
      true
    );
  }

  get favoriteIndicators(): any[] {
    const indicators = this.storage.getItem(
      AUTH_LOCAL_KEYS.CHART_FAVORITE_INDICATORS
    );
    return typeof indicators === 'string' ? JSON.parse(indicators) : indicators;
  }
}
