import { HttpClient } from '@angular/common/http';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { Subject, debounceTime, filter } from 'rxjs';
import {
  BG_FEEDBACK_CHEETAH,
  formatSubscribedItems,
  getFieldsByChannel,
} from '@shared/cheetah/cheetah.const';
import { inject } from '@angular/core';
import { RxEvent } from '@shared/channel/rx-event';
import { RestService } from '@shared/services/rest/rest.service';
import { SECRET_KEY } from '@shared/cheetah/cheetah.const';
import { CheetahAuthResponse } from '@shared/cheetah/service/cheetah.service';
import { catchError, map, of } from 'rxjs';
import { DisconnectService } from '@shared/services/api/authentication/v1/disconnect.service';
import { LogoutService } from '@shared/services/api/authentication/v2/logout.service';
import { debug, groupMapKeys } from 'src/app/utils/utils.functions';
import { AUTH_SESSION_KEYS } from '@shared/services/core/const/auth_util.const';
import { isWebViewContext } from 'src/app/desktop/integration.utils';
import { ConfigService } from '@core/config';
import { sharedSessionStorage } from '@shared/services/core/storage/shared-session.storage';

declare global {
  interface Window {
    Cypress: boolean | undefined;
  }
}

interface CheetahAuthParams {
  frequency: any;
  channel: string;
  cfid?: string;
  secret?: string;
}

export const STATE = {
  STARTED: 'STARTED',
  ERROR: 'ERROR',
  CLOSED: 'CLOSED',
  DISCONNECTED: 'DISCONNECTED',
  RESTARTING: 'RESTARTING',
};

export abstract class CheetahChannelBase extends RestService {
  restartProcess: boolean = false;
  token: string = localStorage.getItem('CHEETAH_TOKEN') || '';
  channel: string = 'ISNOTDEFINED';
  cheetahAddress!: string;
  override apiEndPoint: string;
  override _url: string = '';
  private readonly MAX_ATTEMPTS = 5;
  private readonly RETRY_DELAY = 1000;
  private disconnectCount = 0;
  private readerActive = true;
  private disconnectService: DisconnectService = inject(DisconnectService);
  private configService = inject(ConfigService);

  private logoutService = inject(LogoutService);
  private channelState$: Subject<any> = new Subject<any>();
  private restartChannel = new Subject<void>();
  private _state: CheetahChannelState = {
    channel: this.channel,
    value: 'CLOSED',
  };

  private subscriptionsData = new Map<string, string[]>();
  private subscribedItems = new Map<string, number>();
  private subscribeSubject = new Subject<void>();
  private rxEvent: RxEvent;
  private itemsToUnsubscribers: any[][] = [];
  get authUser() {
    return sharedSessionStorage.getItem(AUTH_SESSION_KEYS.USER_AUTHENTICATED);
  }
  get state() {
    return this._state;
  }

  set state(value: any) {
    this._state = {
      channel: this.channel,
      value,
    };
  }

  get isStateError() {
    return (
      this.state.value === STATE.CLOSED ||
      this.state.value === STATE.DISCONNECTED ||
      this.state.value === STATE.ERROR
    );
  }

  private updateSubscriptionCount(key: string, increment: boolean): number {
    const currentCount = this.subscribedItems.get(key) || 0;
    const newCount = currentCount + (increment ? 1 : -1);
    this.subscribedItems.set(key, newCount > 0 ? newCount : 0);
    return newCount;
  }

  private setNewSubscriptions(subscribeParam: SubscribeParam | any) {
    const { channel_cheetah, itemsArray } = subscribeParam;
    const newItems: string[] = [];

    for (const item of itemsArray) {
      const key = `${channel_cheetah}-${item}`;
      const count = this.updateSubscriptionCount(key, true);
      if (count === 1) {
        newItems.push(item);
      }
    }

    const existingItems = this.subscriptionsData.get(channel_cheetah) || [];
    this.subscriptionsData.set(channel_cheetah, [
      ...existingItems,
      ...newItems,
    ]);
  }

  constructor(http: HttpClient, rxEvent: RxEvent) {
    super(http);
    this.apiEndPoint = this.configService.getCheetahURL();
    this.rxEvent = rxEvent;
    this.initializeSubscription();
  }

  auth(channel: string) {
    this.httpOptions.set('X-Subscribe-Origin', channel);
    const params: CheetahAuthParams = {
      frequency: this.configService.getConfig()?.cheetahFrequency,
      channel: channel,
    };
    if (this.configService.isDev()) params.secret = SECRET_KEY;
    return this.post<CheetahAuthResponse>(`post/auth`, params).pipe(
      catchError((error) =>
        of({
          data: { cheetah_address: '', success: false, token: error },
        })
      ),
      map((res: any) => res.data as CheetahAuthResponse)
    );
  }

  subscribeItem(data: any) {
    this.post(`post/subscribe`, data).subscribe();
  }

  unsubscribeItem(data: any) {
    return this.post(`post/unsubscribe`, data);
  }

  private initializeSubscription(): void {
    this.subscribeSubject.pipe(debounceTime(200)).subscribe(() => {
      this.callSubscribe();
      this.subscriptionsData.clear();
    });
  }

  private handleOpen(): void {
    if (this.isRestarting()) {
      this.subscriptionsData = formatSubscribedItems(this.subscribedItems);
    }
    this.onOpenHandler();
  }
  private timeoutRef: any = null;
  private tryReconnect(): void {
    if (this.disconnectCount === 0) {
      this.serverLog(
        `[STREAM_ERROR] - ${this.apiEndPoint} - Preparando-se para a reconexão após desconexão do stream anterior. - state ${this.state.value} `
      );
    }

    if (this.disconnectCount >= this.MAX_ATTEMPTS) {
      this.logoutUser();
      return;
    }

    this.emitStatusMessage('SSH_CREATED_ERROR');
    this.state = STATE.DISCONNECTED;
    this.disconnectCount++;
    this.serverLog(
      `[RECONNECT_ERROR] - ${this.apiEndPoint} -  Tentativa de reconexão ${this.disconnectCount} de 5.`
    );
    const delaySeconds = Math.min(this.disconnectCount * 2, 20); // Limitado a 20 segundos
    debug(`Delaying restart for ${delaySeconds} seconds`);
    if (this.timeoutRef !== null) {
      clearTimeout(this.timeoutRef); // Limpa o timeout anterior, se existir
    }

    this.timeoutRef = setTimeout(() => {
      this.restart();
    }, delaySeconds * 1000);
  }

  private handleInactive(): void {
    const message: string = `[IDLE_CHEETAH] - ${this.apiEndPoint} - Não há eventos há mais de 10 segundos.`;
    debug(message);
    this.emitStatusMessage('SSH_CREATED_ERROR');
    this.serverLog(message);
    this.restart();
  }

  private logoutUser(): void {
    const message: string = `[LOGOUT_CHEETAH] - ${this.apiEndPoint} - Deslogando usuário após falha ao tentar reconectar ao serviço CHEETAH após 5 tentativas.`;
    debug(message);
    this.serverLog(message);
    this.logoutService.exec(
      'DISCONNECTED',
      'Parece que sua sessão foi desconectada. Faça login novamente para continuar de onde parou.'
    );
  }

  private processStreamData(key: string): void {
    switch (key) {
      case 'open':
        this.handleOpen();
        break;
      case 'already_connected':
        this.serverLog(
          `ALREADY CONNECTED - Usuario ja esta com uma aplicação rodando`
        );
        this.state = STATE.CLOSED;
        break;
      case 'disconnect':
        this.tryReconnect();
        break;
      case 'inactive':
        this.handleInactive();
        break;
    }
  }

  async readStream(stream: ReadableStream) {
    const reader = stream.getReader();
    while (this.readerActive) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      this.processStreamData(value.data.key);
    }
  }

  private serverLog(message: string) {
    this.disconnectService.performDisconnect(message);
  }

  private emitStatusMessage = (cdStatus: string) => {
    const dictMessage: any = {
      SSE_CREATED_ERROR: BG_FEEDBACK_CHEETAH.SSE_CREATED_ERROR,
      SSH_CREATED: BG_FEEDBACK_CHEETAH.SSE_CREATED,
      RECONNECT: BG_FEEDBACK_CHEETAH.RECONNECT,
    };
    this.rxEvent.emit('SSE_STATUS', {
      ...{
        cdStatus,
        status: dictMessage[cdStatus],
      },
      channel: 'SSE_STATUS',
    });
  };

  private onOpenHandler = () => {
    this.state = STATE.STARTED;
    this.channelState$.next(this.state);
    this.emitStatusMessage('SSH_CREATED');
    this.subscribeSubject.next();
    this.disconnectCount = 0;
  };

  async start() {
    this.authCheetah();
  }
  providerCheetahData() {
    return this.auth('ROCKET_TRADER'); //of(system.userAuthenticated.cheetah);
  }

  private authCheetah(alreadyOpen: boolean = false) {
    this.providerCheetahData().subscribe((data: any) => {
      try {
        if (!data.success)
          throw new Error('Authentication failed. Please try again.');
        const serverUrl: string = `${data.is_replay ? 'http' : 'https'}://${
          data.cheetah_address
        }`;
        this.apiEndPoint = serverUrl;
        this.token = data.token;
        if (isWebViewContext()) {
          localStorage.setItem('CHEETAH_TOKEN', this.token);
          localStorage.setItem('CHEETAH_URL', this.apiEndPoint);
        }
        const params = {
          action: 'instanciate',
          data: {
            token: this.token,
            serverUrl,
          },
        };
        this.rxEvent.connect(params).then(async (data) => {
          this.readStream(data.stream);
          if (this.isRestarting()) {
            this.serverLog(`RECONNECT - ${this.apiEndPoint}`);
            this.restartChannel.next();
          }
        });
        if (alreadyOpen) {
          this.channelState$.next(this.state);
        }
      } catch (error: any) {
        // console.log(error.message);
        this.state = STATE.ERROR;
        this.emitStatusMessage('SSH_CREATED_ERROR');
        this.state = STATE.DISCONNECTED;
        this.tryReconnect();
      }
    });
  }

  onChangeState = () => {
    return this.channelState$.asObservable().pipe(filter((data) => data));
  };

  subscribe(subscribeParams: SubscribeParam | SubscribeParam[]): void {
    if (this.restartProcess) return;
    const paramsArray = Array.isArray(subscribeParams)
      ? subscribeParams
      : [subscribeParams];
    paramsArray.forEach((subscribeParam) => {
      this.setNewSubscriptions(subscribeParam);
    });
    if (!this.isStateError) {
      this.subscribeSubject.next();
    }
  }

  private mapToObjectArray = (map: any) => {
    const objectArray = [];
    for (const [channel, items] of map.entries()) {
      items.length &&
        objectArray.push({
          channel,
          items,
          fields: getFieldsByChannel(channel),
        });
    }
    return objectArray;
  };

  callSubscribe() {
    const subscriptions = this.mapToObjectArray(this.subscriptionsData);
    subscriptions.length &&
      this.subscribeItem({
        subscriptions,
        token: this.token,
      });
  }

  setupSubscribes(subs: SubscribeParam | SubscribeParam[]) {
    this.subscribe(subs);
  }

  resetSubscriptionByWorkspace() {
    if (this.isStateError) return;
    const subscriptions = groupMapKeys(this.subscribedItems).filter(
      (item) =>
        item.channel !== 'ORDER' &&
        item.channel !== 'CUSTODIA' &&
        item.channel !== 'NOTIFICATION'
    );
    this.unsubscribeItem({
      subscriptions,
      token: this.token,
    }).subscribe();
    this.subscribedItems.clear();
  }

  unsubscribe(subscribeParams: SubscribeParam | SubscribeParam[]): void {
    // Trata tanto um único objeto SubscribeParam quanto um array de SubscribeParam uniformemente
    const paramsArray = Array.isArray(subscribeParams)
      ? subscribeParams
      : [subscribeParams];
    const itemsToUnsubscribe: any[] = [];
    paramsArray.forEach((param) => {
      const { channel_cheetah, itemsArray } = param;
      const data = this.getItemsToUnsubscribe(channel_cheetah!, itemsArray);
      if (data.items.length) {
        itemsToUnsubscribe.push(data);
      }
    });
    if (!this.restartProcess && itemsToUnsubscribe.length) {
      if (this.isStateError) {
        this.itemsToUnsubscribers.push(itemsToUnsubscribe);
      } else {
        this.unsubscribeItem({
          subscriptions: [...itemsToUnsubscribe],
          token: this.token,
        }).subscribe();
      }
    }
  }

  doUnsubscribers() {
    while (this.itemsToUnsubscribers.length) {
      const itemsToUnsubscribe = structuredClone(this.itemsToUnsubscribers[0]);
      this.unsubscribeItem({
        subscriptions: [...itemsToUnsubscribe],
        token: this.token,
      }).subscribe();
      this.itemsToUnsubscribers.shift();
    }
  }

  private getItemsToUnsubscribe(channel: string, itemsArray: string[]): any {
    return {
      channel,
      items: itemsArray.reduce((acc, item) => {
        const key = `${channel}-${item}`;
        if (!this.subscribedItems.has(key)) return acc;
        if (this.updateSubscriptionCount(key, false) <= 0) {
          this.subscribedItems.delete(key);
          acc.push(item);
        }
        return acc;
      }, [] as string[]),
    };
  }

  onRestart = () => this.restartChannel.asObservable();

  private isRestarting = () => this.state.value === STATE.RESTARTING;

  private restart(alreadyOpen: boolean = false): void {
    this.state = STATE.RESTARTING;
    this.apiEndPoint = this.configService.getCheetahURL();
    this.emitStatusMessage('RECONNECT');
    this.authCheetah(alreadyOpen);
  }
}

export type CheetahChannelState = { channel: string; value: string };
