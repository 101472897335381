import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CountPipe } from '@shared/pipes/count-value.pipe';

export const NOTIFICATION_COUNT_VALUE_ID = 'COUNT_ID';
@Component({
  selector: 'app-notification-count',
  template: `
    <div
      class="notification hstack align-items-center justify-content-center bg-feedback-warning fw-bold position-absolute round-lg"
    >
      <span [id]="countID" classs="text-center">
        {{ value | count : 10 : false }}
      </span>
    </div>
  `,
  styles: [
    `
      .notification {
        top: -8px;
        right: 0px;
        width: 24px;
        height: 23px;
      }
    `,
  ],
  standalone: true,
  imports: [CountPipe, CommonModule],
})
export class NotificationCountComponent {
  @Input() value: number = 0;
  public readonly countID = NOTIFICATION_COUNT_VALUE_ID;
}
