import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { TIGER_INDICATOR_PARAMETER_TYPE } from '../../../enum/tiger-chart.enum';
import {
  BaseIndicator,
  TTigerChartIndicatorParameter,
} from '../../indicators.types';

@Component({
  selector: 'app-settings-input',
  templateUrl: './settings-input.component.html',
})
export class SettingsInputComponent {
  @Input() indicator!: BaseIndicator;
  @Input() baseChart!: TWebAssemblyChart;
  @Input() refComponent!: string;
  @Output() changeEmitter = new EventEmitter();

  get parameterType() {
    return TIGER_INDICATOR_PARAMETER_TYPE;
  }

  getValue(property: string) {
    return this.indicator[property as keyof typeof this.indicator];
  }

  onChange(event: any, setting: TTigerChartIndicatorParameter) {
    let value =
      typeof event.value != 'undefined' ? event.value : event.target.value;
    value = setting.min && value < setting.min ? setting.min : value;
    setting.valueSelected = value;
    this.indicator.setValue(
      setting.property as keyof typeof this.indicator,
      value
    );
    //this.settingsModalService.updateTemporaryConfig(
    //  this.refComponent,
    //  this.indicator,
    //  value,
    //  setting.property
    //);
    this.changeEmitter.emit();
  }
}
