import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ISearchStock, IWorkSpaceComponet } from '@core/interface';
import { SearchStockComponent } from '@shared/components/search-stock/search-stock.component';
import { DragService } from '@shared/rocket-components/services/ag-grid/drag.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'business-profile-header',
  templateUrl: './business-profile-header.component.html',
  styleUrls: ['./business-profile-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessProfileHeaderComponent implements OnInit {
  @Input() refComponent!: string;
  @Input() public headerOptions!: any;
  @Input() stockSelected!: ISearchStock;
  @Input() component!: IWorkSpaceComponet;
  @Output() onChangeStock: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchStock') searchStock!: SearchStockComponent;
  private dragEvents$!: Subscription;

  constructor(private dragService: DragService) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.dragEvents$ = this.dragService
      .onEvents(this.component.id)
      .subscribe((data) => {
        this.selectStockByCdStock(data.data.cd_stock);
      });
  }

  ngOnDestroy() {
    this.dragEvents$ && this.dragEvents$.unsubscribe();
  }

  public selectStock = (stock: ISearchStock) => this.onChangeStock.emit(stock);

  selectStockByCdStock(cdStock: string) {
    this.searchStock.selectStockByCdStock(cdStock);
  }
}
