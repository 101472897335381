<div
  *ngIf="!isDropdown; else dropdownSelector"
  class="strategySelector d-flex h-100"
>
  <a
    [class]="
      'nav-link cursor-pointer round-top d-inline-block text-truncate user-select-none d-inline-flex align-items-center pr-2' +
      ' ' +
      customClass
    "
    (click)="toggleStrategyMode()"
    tooltipPosition="bottom"
    [tooltip]="strategyTooltip"
    [tooltipIsHtml]="true"
  >
    <span [style.max-width.px]="100"
    [style.height.px]="20"
    class="text-truncate"
    [ngClass]="{'d-flex align-items-center bg-brand-support-secondary p-1 round text-black': useStrategyMode}">
      {{ useStrategyMode ? currentStrategy?.name : 'Estratégia' }}
    </span>
  </a>
  <a
    class="nav-link cursor-pointer lh-sm round-top text-truncate user-select-none d-inline-flex align-items-center"
    tooltipPosition="bottom"
    [tooltip]="'Configurar estratégia'"
    [tooltipIsHtml]="false"
  >
    <fla-icon
      name="settings"
      size="icon-size-micro"
      (click)="openModalStrategies()"
    ></fla-icon>
  </a>
</div>

<ng-template #dropdownSelector>
  <div [id]="id" class="strategySelector position-relative d-flex flex-column">
    <div
      class="cursor-pointer background-dropdown opacity-0 w-100 h-100 position-absolute top-0 left-0"
      (click)="activeStrategy()"
      [tooltip]="strategyTooltip"
      [tooltipIsHtml]="true"
      [tooltipHidden]="!strategyTooltip"
    >
      <div class="h-100"></div>
    </div>
    <app-dropdown css="d-flex justify-content-center">
      <a
        title=""
        type="button"
        [class]="
          'right-0 nav-link p-2 lh-sm round-top dropdown-toggle d-inline-block text-truncate' +
          ' ' +
          customClass
        "
        [ngClass]="{
          'text-brand-primary': isSelected,
          'text-neutral-medium': !isSelected
        }"
        appDropdownButton
        strategy="fixed"
      >
        <span *ngIf="!currentStrategy">Estratégia</span>
        <span
          id="currentNameFull"
          [ngClass]="{ 'opacity-0 position-absolute': smallName }"
          *ngIf="currentStrategy"
          >{{ currentStrategy.name }}</span
        >

        <span *ngIf="currentStrategy && smallName">{{
          isSelected ? smallName : 'Estratégia'
        }}</span>
      </a>
      <div class="list-max-height">
        <div *ngFor="let strategy of strategies">
          <a
            class="dropdown-item"
            type="button"
            (click)="selectStrategy(strategy)"
          >
            {{ strategy.nameStrategy }}
          </a>
        </div>
      </div>
    </app-dropdown>
  </div>
</ng-template>
