import { ModifierMouseArgs, ZoomExtentsModifier } from 'scichart';

export class ZoomDoubleClickModifier extends ZoomExtentsModifier {
  override isAnimated: boolean = false;

  constructor(options?: any) {
    super({ ...options, isAnimated: false });
  }

  override modifierDoubleClick(args: ModifierMouseArgs): void {
    const rSeries = this.parentSurface?.renderableSeries.asArray();
    if (!rSeries) {
      super.modifierDoubleClick(args);
      this.isAnimated = true;
      super.isAnimated = true;
      return;
    }
    const mousePoint = args.mousePoint;
    let isIndicator = 0;

    for (const series of rSeries) {
      const hitTestProvider: any = series.hitTestProvider;
      if (!hitTestProvider) {
        super.modifierDoubleClick(args);
        this.isAnimated = true;
        super.isAnimated = true;
        break;
      }
      const dataSeries = hitTestProvider.parentSeries.dataSeries;
      const indexes = dataSeries.indexes;

      const index = Math.round(
        hitTestProvider.parentSeries.xAxis
          .getCurrentCoordinateCalculator()
          .getDataValue(mousePoint.x)
      );
      if (index > indexes.size()) {
        super.modifierDoubleClick(args);
        this.isAnimated = true;
        super.isAnimated = true;
        break;
      }
      const a = series.hitTestProvider.hitTestDataPoint(
        mousePoint.x,
        mousePoint.y,
        300
      );
      if (a.dataSeriesType == 'Xy') {
        if (a.isHit && series.isVisible) {
          isIndicator += 1;
          this.isAnimated = false;
          super.isAnimated = false;
          return;
        } else {
          super.modifierDoubleClick(args);
          this.isAnimated = true;
          super.isAnimated = true;
          break;
        }
      }
    }

    if (!isIndicator) {
      super.modifierDoubleClick(args);
      this.isAnimated = true;
      super.isAnimated = true;
    }
    return;
  }
}
