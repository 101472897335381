import { Dictionary } from 'src/app/core/models';
import { CHART_COLORS } from '../colors';
import { TIGER_INDICATORS_ENUM, TIGER_INDICATOR_SOURCES } from '../enum';
import { TTigerChartIndicatorDefaultValues } from './indicators.types';
import { source } from './base/weis-wave-base';

export const TIGER_INDICATOR_PRECISIONS = [
  { label: 'Padrão', value: 2 },
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
];

export const TIGER_INDICATOR_DEFAULT_VALUES =
  new Dictionary<TTigerChartIndicatorDefaultValues>();
TIGER_INDICATOR_DEFAULT_VALUES.set(
  TIGER_INDICATORS_ENUM.DOUBLE_EXPONENTIAL_MOVING_AVERAGE,
  {
    timePeriod: 9,
    offset: 0,
    precision: 4,
    source: TIGER_INDICATOR_SOURCES.CLOSE,
    lineColor: CHART_COLORS.FEEDBACK_WARNING,
    lineThickness: 1,
  }
);
TIGER_INDICATOR_DEFAULT_VALUES.set(
  TIGER_INDICATORS_ENUM.EXPONENTIAL_MOVING_AVERAGE,
  {
    timePeriod: 9,
    offset: 0,
    precision: 4,
    source: TIGER_INDICATOR_SOURCES.CLOSE,
    lineColor: CHART_COLORS.FEEDBACK_WARNING,
    lineThickness: 1,
  }
);
TIGER_INDICATOR_DEFAULT_VALUES.set(
  TIGER_INDICATORS_ENUM.KAUFMAN_ADAPTIVE_MOVING_AVERAGE,
  {
    timePeriod: 9,
    offset: 0,
    precision: 4,
    source: TIGER_INDICATOR_SOURCES.CLOSE,
    lineColor: CHART_COLORS.FEEDBACK_WARNING,
    lineThickness: 1,
  }
);
TIGER_INDICATOR_DEFAULT_VALUES.set(TIGER_INDICATORS_ENUM.MOVING_AVERAGE, {
  timePeriod: 9,
  offset: 0,
  precision: 4,
  source: TIGER_INDICATOR_SOURCES.CLOSE,
  lineColor: CHART_COLORS.FEEDBACK_WARNING,
  lineThickness: 1,
});
TIGER_INDICATOR_DEFAULT_VALUES.set(
  TIGER_INDICATORS_ENUM.TRIPLE_EXPONENTIAL_MOVING_AVERAGE,
  {
    timePeriod: 9,
    offset: 0,
    precision: 4,
    source: TIGER_INDICATOR_SOURCES.CLOSE,
    lineColor: CHART_COLORS.FEEDBACK_WARNING,
    lineThickness: 1,
  }
);
TIGER_INDICATOR_DEFAULT_VALUES.set(
  TIGER_INDICATORS_ENUM.WEIGHTED_MOVING_AVERAGE,
  {
    timePeriod: 9,
    offset: 0,
    precision: 4,
    source: TIGER_INDICATOR_SOURCES.CLOSE,
    lineColor: CHART_COLORS.FEEDBACK_WARNING,
    lineThickness: 1,
  }
);
TIGER_INDICATOR_DEFAULT_VALUES.set(TIGER_INDICATORS_ENUM.PARABOLIC_SAR, {
  precision: 2,
  acceleration: 0.02,
  maximum: 0.2,
  lineColor: CHART_COLORS.FEEDBACK_WARNING,
  lineThickness: 1,
});
TIGER_INDICATOR_DEFAULT_VALUES.set(
  TIGER_INDICATORS_ENUM.MOVING_AVERAGE_CONVERGENCE_DIVERGENCE,
  {
    fastTimePeriod: 12,
    slowTimePeriod: 26,
    signalTimePeriod: 9,
    source: TIGER_INDICATOR_SOURCES.CLOSE,
    precision: 4,
    upperHistogramColor: CHART_COLORS.FEEDBACK_POSITIVE,
    bottomHistogramColor: CHART_COLORS.FEEDBACK_NEGATIVE,
    macdColor: '#0200A6',
    signalColor: '#ffeb3c',
    macdThickness: 1,
    signalThickness: 1,
  }
);
TIGER_INDICATOR_DEFAULT_VALUES.set(
  TIGER_INDICATORS_ENUM.RELATIVE_STRENGTH_INDEX,
  {
    precision: 2,
    timePeriod: 14,
    upperLimit: 70,
    bottomLimit: 30,
    source: TIGER_INDICATOR_SOURCES.CLOSE,
    lineColor: CHART_COLORS.FEEDBACK_WARNING,
    upperLimitColor: CHART_COLORS.NEUTRAL_MEDIUM,
    bottomLimitColor: CHART_COLORS.NEUTRAL_MEDIUM,
    backgroundColor: CHART_COLORS.FEEDBACK_NEGATIVE,
    lineThickness: 1,
    upperLimitLineType: 1,
    bottomLimitLineType: 1,
  }
);
TIGER_INDICATOR_DEFAULT_VALUES.set(TIGER_INDICATORS_ENUM.STOCHASTIC, {
  precision: 2,
  fastK_Period: 14,
  slowK_Period: 1,
  slowD_Period: 3,
  upperLimit: 70,
  bottomLimit: 30,
  source: TIGER_INDICATOR_SOURCES.CLOSE,
  lineColor: CHART_COLORS.FEEDBACK_WARNING,
  upperLimitColor: CHART_COLORS.NEUTRAL_MEDIUM,
  bottomLimitColor: CHART_COLORS.NEUTRAL_MEDIUM,
  backgroundColor: CHART_COLORS.FEEDBACK_NEGATIVE,
  lineThickness: 1,
  upperLimitLineType: 1,
  bottomLimitLineType: 1,
  lineVisibleK: true,
  lineVisibleD: true,
  upperVisible: true,
  bottomVisible: true,
  backgroundVisible: true,
});
TIGER_INDICATOR_DEFAULT_VALUES.set(TIGER_INDICATORS_ENUM.MEDIAN_PRICE, {
  precision: 2,
  lineColor: CHART_COLORS.FEEDBACK_WARNING,
  lineThickness: 1,
});
TIGER_INDICATOR_DEFAULT_VALUES.set(TIGER_INDICATORS_ENUM.CDL_HAMMER, {
  backgroundColor: CHART_COLORS.BRAND_SUPPORT_PRIMARY,
});
TIGER_INDICATOR_DEFAULT_VALUES.set(TIGER_INDICATORS_ENUM.CDL_INVERTED_HAMMER, {
  backgroundColor: CHART_COLORS.MULTIBROKER_BUY,
});
TIGER_INDICATOR_DEFAULT_VALUES.set(TIGER_INDICATORS_ENUM.CDL_DOJI, {
  backgroundColor: CHART_COLORS.FEEDBACK_WARNING,
});
TIGER_INDICATOR_DEFAULT_VALUES.set(TIGER_INDICATORS_ENUM.CDL_ENGULFING, {
  backgroundColor: CHART_COLORS.FEEDBACK_WARNING,
});
TIGER_INDICATOR_DEFAULT_VALUES.set(TIGER_INDICATORS_ENUM.BOLLINGER_BANDS, {
  upperColor: '#0200A6',
  baseColor: '#A60000',
  bottomColor: '#0200A6',
  backgroundColor: '#00CBFF',
  timePeriod: 20,
  precision: 2,
  standardDeviation: 2,
  source: TIGER_INDICATOR_SOURCES.CLOSE,
  upperThickness: 1,
  baseThickness: 1,
  bottomThickness: 1,
  upperVisible: true,
  baseVisible: true,
  bottomVisible: true,
  backgroundVisible: true,
});
TIGER_INDICATOR_DEFAULT_VALUES.set(TIGER_INDICATORS_ENUM.AVERAGE_PRICE, {
  precision: 2,
  lineColor: CHART_COLORS.FEEDBACK_WARNING,
  lineThickness: 1,
});
TIGER_INDICATOR_DEFAULT_VALUES.set(TIGER_INDICATORS_ENUM.AVERAGE_TRUE_RANGE, {
  timePeriod: 14,
  offset: 0,
  precision: 4,
  source: TIGER_INDICATOR_SOURCES.CLOSE,
  lineColor: CHART_COLORS.FEEDBACK_WARNING,
  lineThickness: 1,
});
TIGER_INDICATOR_DEFAULT_VALUES.set(
  TIGER_INDICATORS_ENUM.PERCENTAGE_PRICE_OSCILLATOR,
  {
    offset: 0,
    precision: 4,
    source: TIGER_INDICATOR_SOURCES.CLOSE,
    lineColor: CHART_COLORS.FEEDBACK_WARNING,
    lineThickness: 1,
    fastPeriod: 21,
    slowPeriod: 10,
  }
);
TIGER_INDICATOR_DEFAULT_VALUES.set(
  TIGER_INDICATORS_ENUM.TRIANGULAR_MOVING_AVERAGE,
  {
    timePeriod: 10,
    offset: 0,
    precision: 4,
    source: TIGER_INDICATOR_SOURCES.CLOSE,
    lineColor: CHART_COLORS.FEEDBACK_WARNING,
    lineThickness: 1,
  }
);
TIGER_INDICATOR_DEFAULT_VALUES.set(TIGER_INDICATORS_ENUM.MOMENTUM, {
  offset: 0,
  precision: 4,
  source: TIGER_INDICATOR_SOURCES.CLOSE,
  lineColor: CHART_COLORS.FEEDBACK_WARNING,
  lineThickness: 1,
  timePeriod: 10,
});
TIGER_INDICATOR_DEFAULT_VALUES.set(
  TIGER_INDICATORS_ENUM.AVERAGE_DIRECTIONAL_X,
  {
    timePeriod: 14,
    smoothing: 14,
    offset: 0,
    precision: 4,
    source: TIGER_INDICATOR_SOURCES.CLOSE,
    lineColor: CHART_COLORS.FEEDBACK_WARNING,
    lineThickness: 1,
  }
);
TIGER_INDICATOR_DEFAULT_VALUES.set(TIGER_INDICATORS_ENUM.BAR_ELEPHANT, {
  timePeriod: 1,
  offset: 0,
  precision: 4,
  source: TIGER_INDICATOR_SOURCES.CLOSE,
  lineColor: CHART_COLORS.FEEDBACK_WARNING,
  lineThickness: 1,
  positiveBarColor: CHART_COLORS.FEEDBACK_POSITIVE,
  negativeBarColor: CHART_COLORS.FEEDBACK_NEGATIVE,
});
TIGER_INDICATOR_DEFAULT_VALUES.set(TIGER_INDICATORS_ENUM.WEIS_WAVE, {
  timePeriod: 14,
  precision: 4,
  price_source: source[0].value,
  lineColor: CHART_COLORS.FEEDBACK_WARNING,
  lineThickness: 1,
  volumeColorUp: CHART_COLORS.FEEDBACK_POSITIVE,
  volumeColorDown: CHART_COLORS.FEEDBACK_NEGATIVE,
});
TIGER_INDICATOR_DEFAULT_VALUES.set(TIGER_INDICATORS_ENUM.CANDLE_COUNTER, {
  textColor: CHART_COLORS.NEUTRAL_SMOOTHER 
});
