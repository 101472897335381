import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeatmapComponent } from './heatmap.component';
import { HeatmapHeaderComponent } from './header/heatmap-header.component';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingModule } from '../loading/loading.module';
import { MarketSectorsService } from '@shared/services/api/trademap/v1/market-sectors.service';
import {
  FlaIconModule,
  RocketButtonModule,
} from '@shared/rocket-components/components/index';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { HeatmapContextMenuComponent } from './config/heatmap-context-menu.component';
import { HeatmapChartModule } from '../heatmap-chart/heatmap-chart.module';
import { RTListSelectorModule } from '@shared/rocket-components/components/list-selector/list-selector.module';

@NgModule({
  imports: [
    CommonModule,
    RTDropdownModule,
    FlaWorkSpaceModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingModule,
    RocketButtonModule,
    RTListSelectorModule,
    FlaIconModule,
    TooltipsModule,
    HeatmapChartModule,
  ],
  declarations: [
    HeatmapComponent,
    HeatmapHeaderComponent,
    HeatmapContextMenuComponent,
  ],
  providers: [MarketSectorsService],
  exports: [HeatmapComponent],
})
export class HeatmapModule {}
