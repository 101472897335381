import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { HomeService } from '@modules/home/service/home.service';
import { Subscription, Subject } from 'rxjs';
import { auditTime, filter, map, takeUntil, tap } from 'rxjs/operators';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { TradeChannel } from '@shared/channel/trade.channel';
import {
  getAuctionInfos,
  removeNullInObject,
} from 'src/app/utils/utils.functions';
import {
  IMultibrokerBar,
  ISearchStock,
  IWorkSpaceComponet,
} from 'src/app/core/interface';
import { IntrojsService } from '@core/introjs/introjs.service';
import { deepClone } from '@shared/rocket-components/utils';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { IStockAuctionInfos } from '@shared/interfaces/stock-auction-infos.interface';
import { TradeRowData } from '@shared/components/times-trades/parts/trade-row/model/trade-row-data.model';
import { RocketStreamRead } from '@shared/channel/rx-event';
import {
  TimesTradesContextMenuComponent,
  TimesTradesContextMenuEvent,
} from '@shared/components/times-trades/parts/times-trades-context-menu/times-trades-context-menu.component';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';
import { TradeByOrderChannel } from '@shared/channel/trade_by_order.channel';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { ActivatedRoute } from '@angular/router';
import { isWebViewContext } from 'src/app/desktop/integration.utils';
import { StandaloneModalsService } from '@shared/modals/standalone-modals.service';
import { TradesFilterModalComponent } from './parts/trades-filter-modal/trades-filter-modal.component';
import {
  IShortcutFastFilter,
  ITradesColorsHighlights,
  ITradesFilter,
  ITradesFiltersAndColors,
} from './parts/interface/trades-filter.interface';
import { TradesFilterService } from './parts/service/trades-filter.service';

@Component({
  selector: 'app-times-trades',
  templateUrl: './times-trades.component.html',
  styleUrls: ['./times-trades.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesTradesComponent
  extends RocketComponentBase
  implements OnInit, OnChanges, OnDestroy
{
  @Input() component!: IWorkSpaceComponet;
  @Input() height!: any;
  @Input() width!: any;
  @ViewChild('timesTrade', { static: true }) timesTrade!: ElementRef;
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  cdkVirtualScrollViewport!: CdkVirtualScrollViewport;

  private _tourSubscription!: Subscription;
  private qouteParamsMultibroker!: SubscribeParam;
  private qouteParamsTimes!: SubscribeParam;
  private worker!: Worker;
  private event$: Subscription = new Subscription();
  private detectChangesRisize = new Subject<void>();
  private detectRowChanges = new Subject<void>();
  private detectComponentChanges = new Subject<void>();
  private destroy$ = new Subject<void>();
  data: TradeRowData[] = [];
  dataMultibroker!: IMultibrokerBar;
  refComponent!: string;
  showPressure!: boolean;
  showDetails!: boolean;
  showMilliseconds!: boolean;
  useTradeByOrders!: boolean;
  tick_size_denominator: any;
  preco_ultimo: number = 0;
  variacao_dia: number = 0;
  preco_minimo: number | string = 0;
  preco_maximo: number | string = 0;
  volume: any;
  arrow_hex: any;
  stockPrices: any = {};
  isTour: boolean = false;
  displayBtnToCallPreviusTrades: boolean = false;
  withoutPreviusTrades: boolean = false;
  virtualScrollViewportHeigth = 300;
  shortcutWidth: 38 | 58 = 38;
  stockAuctionInfos!: IStockAuctionInfos;
  previousPrice!: number;
  currentSubscription!: Subscription;
  incomingStreamController: ReadableStreamDefaultController | any;
  private tradeStreamActive!: RocketStreamRead;
  private tradeByOrderStreamActive!: RocketStreamRead;
  isDesktop = false;
  quoteStream!: RocketStreamRead;
  private itemsArraySubscribe = '';
  // Filters and colors
  public highlightColors: ITradesColorsHighlights[] = [];
  public tradeQttyFilter: number = 1;
  public fastTradeID: number = -1;
  public filtersAndHighlightEnabled: boolean = false;
  public shouldDisableShortCutFilter: boolean = false;
  private _hasSomeCustomTrade: boolean = false;
  public haveCustomFiltersAndHighlights: boolean = false;

  get metadataConfiguration() {
    return (
      this.component.metadata?.configuration ?? {
        showPressure: true,
        showDetails: true,
        showMilliseconds: false,
        useTradeByOrders: false,
      }
    );
  }

  set metadataConfiguration(data: any) {
    const config = { ...this.metadataConfiguration, ...data };
    this.component.metadata.configuration = config;
    this.updateMetadata();
  }

  get stockParams() {
    const params = {
      tickSizeDenominator: this.stockSelected.tick_size_denominator,
      tickSizeQuantity: this.stockSelected.tick_size_quantity,
      idExchange: this.stockSelected.id_exchange,
      cdSegment: +this.stockSelected.cd_segment,
    };
    return params;
  }

  get cdStockCache() {
    return this.stockSelected
      ? `${this.stockSelected.cd_stock}:${this.stockSelected.id_exchange}`
      : '';
  }

  get stockSelected(): ISearchStock {
    return this.component?.metadata?.component?.stock ?? {};
  }

  get headerOptions() {
    return this.component.metadata?.headerOptions;
  }

  get itemsArraySelected() {
    return `${this.stockSelected.cd_stock}:${this.stockSelected.id_exchange}`;
  }

  constructor(
    private homeService: HomeService,
    private _quoteChannel: QuoteChannel,
    private _tradeChannel: TradeChannel,
    private _tradeByOrderChannel: TradeByOrderChannel,
    private cdr: ChangeDetectorRef,
    private _introJS: IntrojsService,
    private elementRef: ElementRef,
    private contextMenuService: ContextMenuService,
    activatedRoute: ActivatedRoute,
    private _standaloneModalService: StandaloneModalsService,
    private _tradeFiltersService: TradesFilterService
  ) {
    super(activatedRoute);

    this.isDesktop = isWebViewContext();
    this.detectRowChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.cdr.detectChanges();
    });

    this.detectComponentChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.cdr.detectChanges();
    });

    this.detectChangesRisize
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.resize()),
        auditTime(25)
      )
      .subscribe(() => {
        this.cdkVirtualScrollViewport.checkViewportSize();
        this.cdr.detectChanges();
      });
  }

  protected initComponent(component: any): void {
    if (component) this.component = component;
    this.attConfigVars();
    this.componentInit();
    this.event$.add(
      this.contextMenuService
        .listenActionEvent<TimesTradesContextMenuEvent>(this.component.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((payload) => {
          switch (payload.action) {
            case TimesTradesContextMenuEvent.Pressure:
              this.showOrClosePressure();
              break;
            case TimesTradesContextMenuEvent.Details:
              this.showOrCloseDetails();
              break;
            case TimesTradesContextMenuEvent.Milliseconds:
              this.handleDisplayMilliseconds();
              break;
            case TimesTradesContextMenuEvent.Tour:
              this.handleComponentTour();
              break;
            case TimesTradesContextMenuEvent.TradeByOrder:
              this.handleTradeByOrderCallback();
              break;
          }
        })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { height } = changes;
    height?.currentValue && this.detectChangesRisize.next();
  }

  ngOnDestroy(): void {
    this.quoteStream?.close();
    this.tradeStreamActive?.close();
    this.tradeByOrderStreamActive?.close();
    this.destroy$.next();
    this.destroy$.complete();
    this._destroyCheetah();
    !!this.worker && this.worker.terminate();
    this.event$ && this.event$.unsubscribe();
    this.detectRowChanges && this.detectRowChanges.unsubscribe();
    this.detectComponentChanges && this.detectComponentChanges.unsubscribe();
  }

  attConfigVars() {
    this.showPressure = this.metadataConfiguration.showPressure;
    this.showDetails = this.metadataConfiguration.showDetails;
    this.showMilliseconds = this.metadataConfiguration.showMilliseconds;
    this.useTradeByOrders = this.metadataConfiguration.useTradeByOrders;
    this.detectChangesRisize.next();
  }

  trackByFn(index: any) {
    return index;
  }

  processSubscription = async () => {
    this.tradeStreamActive.snapshot(this.qouteParamsMultibroker.itemsArray);
    this.tradeByOrderStreamActive.snapshot(
      this.qouteParamsMultibroker.itemsArray
    );
  };

  async componentInit() {
    this.initReadEvents().then(() => {
      this.refComponent = this.component.metadata.headerOptions.component!.data;
      Object.keys(this.stockSelected).length &&
        this.processComponent(this.stockSelected);
      this.height =
        this.component.metadata.componentResize?.height ??
        parseInt(this.component.metadata.layout.height);
      this.width =
        this.component.metadata.componentResize?.width ??
        parseInt(this.component.metadata.layout.width);

      this.timesTrade.nativeElement.oncontextmenu = this.showConfig;
      this._getCheetahTrades();
      this.detectChangesRisize.next();
      this._verifyStockFiltersAndHighlights(this.stockSelected);
      this._subAtTradesConfigsChanges();
    });
  }

  private async initReadEvents() {
    this.tradeStreamActive = await this._tradeChannel.onEvents();
    this.tradeByOrderStreamActive = await this._tradeByOrderChannel.onEvents();
    this.readStream(this.tradeStreamActive.stream, (data) =>
      this.updateData(data, false)
    );
    this.readStream(this.tradeByOrderStreamActive.stream, (data) =>
      this.updateData(data, true)
    );
    this.quoteStream = await this._quoteChannel.readEvents();
    this.readStream(this.quoteStream.stream, this._quoteHandler);
  }

  private showConfig = (event: any): boolean => {
    TimesTradesContextMenuComponent.openContextMenu(
      this.contextMenuService,
      this.component.id,
      { clientX: event.clientX, clientY: event.clientY },
      this.isTour,
      this.showPressure,
      this.showMilliseconds,
      this.showDetails,
      this.useTradeByOrders
    );
    return false;
  };

  handleTradeByOrderCallback = () => {
    this.unsubscribeTrades();
    this.useTradeByOrders = !this.useTradeByOrders;
    this.metadataConfiguration = { useTradeByOrders: this.useTradeByOrders };
    this.subscribeTrades();
    this.detectChangesRisize.next();
  };

  handleDisplayMilliseconds = (): void => {
    this.showMilliseconds = !this.showMilliseconds;
    this.metadataConfiguration = { showMilliseconds: this.showMilliseconds };
    this.detectChangesRisize.next();
    this.detectComponentChanges.next();
  };

  showOrClosePressure = (): void => {
    this.showPressure = !this.showPressure;
    this.metadataConfiguration = { showPressure: this.showPressure };
    this.detectChangesRisize.next();
    this.detectComponentChanges.next();
  };

  showOrCloseDetails = (): void => {
    this.showDetails = !this.showDetails;
    this.metadataConfiguration = { showDetails: this.showDetails };
    this.detectChangesRisize.next();
    this.detectComponentChanges.next();
  };

  private processComponent(stock: ISearchStock): void {
    if (stock) {
      this._getCheetahGraficForce(stock);
    }
  }

  stockChanges = (stock: ISearchStock): void => {
    if (
      this.stockSelected &&
      stock &&
      this.stockSelected.cd_stock === stock.cd_stock
    ) {
      return;
    }
    this._resetFiltersAndColors();
    this._destroyCheetah();
    this.preco_maximo = '-';
    this.preco_minimo = '-';
    this.preco_ultimo = 0;
    this._updateStockMetadata(stock);
    this.processComponent(stock);
    this._getCheetahTrades();
    this._verifyStockFiltersAndHighlights(stock);
  };

  private getTradechannelByConfig() {
    return this.useTradeByOrders
      ? this._tradeByOrderChannel
      : this._tradeChannel;
  }

  private subscribeTrades() {
    const chosenChannel = this.getTradechannelByConfig();
    chosenChannel.subscribe(this.qouteParamsTimes);
    this.processSubscription();
  }

  private _getCheetahTrades(): void {
    if (this.itemsArraySubscribe === this.itemsArraySelected) return;
    this.itemsArraySubscribe = this.itemsArraySelected;
    this.qouteParamsTimes = {
      header: this.refComponent,
      itemsArray: [this.itemsArraySelected],
    };
    this.subscribeTrades();
  }

  private updateData = (
    payload: Map<string, any>,
    useTradeByOrders: boolean
  ): void => {
    if (useTradeByOrders != this.useTradeByOrders) return;
    const events = this._getPayload(payload);
    if (!events || events.length === 0 || this.detectRowChanges.closed) return;

    // Insere os novos eventos no início de `this.data`
    this.data = [...events, ...this.data];

    // Mantém apenas os 200 itens mais recentes em `this.data`
    if (this.data.length > 200) {
      this.data = this.data.slice(0, 200);
    }

    // Notifica mudanças
    this.detectRow();
  };

  private _getPayload(payload: Map<string, any>): any[] {
    const items = payload.get(this.itemsArraySelected) || [];
    if (this.filtersAndHighlightEnabled && this._hasSomeCustomTrade) {
      const data = items?.filter((trade: any) =>
        this._shouldDisplayLine(trade.qtde_negociada)
      );
      return data.reverse();
    }
    return items.reverse();
  }

  private async _getCheetahGraficForce(stock: ISearchStock): Promise<void> {
    this.qouteParamsMultibroker = {
      header: this.refComponent,
      itemsArray: [`${stock.cd_stock}:${stock.id_exchange}`],
    };
    this._quoteChannel.subscribe(this.qouteParamsMultibroker);
    this.quoteStream.snapshot(this.qouteParamsMultibroker.itemsArray);
  }

  private _quoteHandler = (payload: any): void => {
    const res = payload.get(this.itemsArraySelected);
    if (!res || res.isEndOfSnap) return;

    this.stockPrices = { ...this.stockPrices, ...removeNullInObject(res) };
    this.tick_size_denominator =
      res.tick_size_denominator || this.tick_size_denominator;
    res.preco_ultimo && (this.preco_ultimo = res.preco_ultimo);
    res.variacao_dia && (this.variacao_dia = res.variacao_dia);
    res.preco_minimo && (this.preco_minimo = res.preco_minimo);
    res.preco_maximo && (this.preco_maximo = res.preco_maximo);
    res.volume && (this.volume = res.volume);
    res.arrow_hex && (this.arrow_hex = res.arrow_hex);
    if (this.detectComponentChanges && !this.detectComponentChanges.closed) {
      this.detectComponentChanges.next();
    }
    if (res.relatorio_forca_compra || res.relatorio_forca_venda)
      this._updateForceChart(
        res.relatorio_forca_compra,
        res.relatorio_forca_venda
      );
    this.stockAuctionInfos = getAuctionInfos(res, this.stockAuctionInfos);
  };

  private _updateForceChart = (buy: string, sell: string): void => {
    if (
      this.dataMultibroker &&
      (this.dataMultibroker.buy || '').toString() === buy &&
      (this.dataMultibroker.sell || '').toString() === sell
    )
      return;
    this.dataMultibroker = deepClone({
      buy: buy ?? this.dataMultibroker?.buy,
      sell: sell ?? this.dataMultibroker?.sell,
    });
    this.detectRow();
  };

  private detectRow() {
    if (this.detectRowChanges && !this.detectRowChanges.closed) {
      this.detectRowChanges.next();
    }
  }

  private _updateStockMetadata(data: ISearchStock): void {
    if (!this.component) return;
    this.component.metadata.component = {
      ...this.component.metadata.component,
      stock: data,
    };
    this.updateMetadata();
  }

  updateMetadata() {
    this.homeService.updateMeta<ISearchStock>(this.component);
  }

  unsubscribeTrades() {
    const chosenChannel = this.getTradechannelByConfig();
    chosenChannel.unsubscribe(this.qouteParamsTimes);
  }

  private _destroyCheetah(): void {
    this.unsubscribeTrades();
    this._quoteChannel.unsubscribe(this.qouteParamsMultibroker);
    this.data = [];
  }

  @HostListener('window:resize')
  private resize(): void {
    let temp = this.isDesktop
      ? window.innerHeight - 52
      : parseInt(this.height) - 60;
    (this.showPressure || this.isTour) && (temp -= 12);
    (this.showDetails || this.isTour) && (temp -= 30);
    temp -= this.shortcutWidth;
    this.virtualScrollViewportHeigth = temp;
  }

  handleComponentTour = () => {
    this.isTour = true;
    this._forceDisplayContexMenu();
    setTimeout(() => {
      this._introJS.timesAndTrade(this.component.id);
    }, 100);
    this._tourSubscription = this._introJS.onStart().subscribe((res) => {
      ['DISPLAY_CONTEXT', 'HIDE_CONTEXT'].includes(res.action) &&
        this._handleContextVisibilityOnTour(res.action === 'DISPLAY_CONTEXT');
      res.action === 'CLOSED' && this._closeContext();
    });
  };

  private _forceDisplayContexMenu(): void {
    const position = this.elementRef.nativeElement.getBoundingClientRect();

    this.showConfig({
      view: {
        innerHeight: document.body.clientHeight,
        innerWidth: document.body.clientWidth,
      },
      pageY: position!.y + 20,
      pageX: position!.x + 20,
      isTour: true,
    });
  }

  private _handleContextVisibilityOnTour(displayContext: boolean): void {
    const element = document.querySelectorAll<HTMLElement>(
      'app-times-trades-context-menu > div'
    )[0];
    element.style.zIndex = displayContext ? '99' : '-1';
    element.style.display = displayContext ? 'block' : 'none';
  }

  private _closeContext(): void {
    const findAll = document.querySelectorAll('app-times-trades-context-menu');
    findAll && findAll.length && findAll.forEach((elem) => elem.remove());
    this._tourSubscription && this._tourSubscription.unsubscribe();
    this.isTour = false;
  }

  public openTradeFilterSettings(): void {
    this._standaloneModalService.openStandaloneModal(
      TradesFilterModalComponent,
      { stock: this.stockSelected },
      {
        backdrop: 'static',
      },
      'modal-sized',
      'lg'
    );
  }

  private _verifyStockFiltersAndHighlights(stock: ISearchStock): void {
    const configs = this._tradeFiltersService.getConfigByStock(
      stock.cd_stock,
      stock.cd_stock_order
    );
    this.haveCustomFiltersAndHighlights = Boolean(
      configs?.filters?.length || configs?.colors?.length
    );
    const preferences = this.metadataConfiguration.filterAndHighlight;
    if (preferences) {
      this.filtersAndHighlightEnabled = Boolean(preferences.enable);
      if (preferences.fastFilter) {
        this.fastTradeID = preferences.fastFilter.id;
        this._handleTradesFilter([preferences.fastFilter], true);
        this.filtersAndHighlightEnabled = true;
        this.detectComponentChanges.next();
        return;
      }
    }
    this._applyCustomFiltersAndHighlight(configs, false);
  }

  private _subAtTradesConfigsChanges(): void {
    this._tradeFiltersService.onUpdateConfigs$
      .pipe(
        takeUntil(this.destroy$),
        filter(
          ({ cd_stock_order }) =>
            cd_stock_order === this.stockSelected.cd_stock_order
        ),
        map((params) => params.configs),
        tap(() => {
          this.shouldDisableShortCutFilter = false;
          this.detectComponentChanges.next();
        })
      )
      .subscribe((configs) => {
        this.filtersAndHighlightEnabled = true;
        this._applyCustomFiltersAndHighlight(configs);
      });
  }

  private _applyCustomFiltersAndHighlight(
    configs: ITradesFiltersAndColors | null,
    shouldResetIfNull: boolean = true
  ): void {
    if (!configs?.filters && !configs?.colors) {
      this.haveCustomFiltersAndHighlights = false;
      if (shouldResetIfNull) this._resetFiltersAndColors();
      return;
    }
    this.haveCustomFiltersAndHighlights = true;
    this._handleTradesFilter(configs.filters);
    this.highlightColors = configs.colors ? configs.colors : [];
    this.detectComponentChanges.next();
  }

  private _handleTradesFilter(
    filter: ITradesFilter[],
    isFastFilter: boolean = false
  ): void {
    if (!filter || !filter.length) {
      this._hasSomeCustomTrade = false;
      this.shouldDisableShortCutFilter = true;
      this.tradeQttyFilter = 1;
      return;
    }
    this._hasSomeCustomTrade = true;
    this.shouldDisableShortCutFilter = isFastFilter ? false : true;
    this.tradeQttyFilter = filter[0].qtty;
  }

  private _resetFiltersAndColors(): void {
    this._hasSomeCustomTrade = false;
    this.filtersAndHighlightEnabled = false;
    this.shouldDisableShortCutFilter = true;
    this.tradeQttyFilter = 1;
    this.highlightColors = [];
    this.detectComponentChanges.next();
  }

  private _shouldDisplayLine(quantity: string) {
    return parseInt(quantity.replace(/\D/g, '')) >= this.tradeQttyFilter;
  }

  public onChangeFiltersPreferences(enable: boolean): void {
    this.filtersAndHighlightEnabled = enable;
    this.shouldDisableShortCutFilter = true;
    this.detectComponentChanges.next();
    this._updateTradesFilterPreferences({ enable });
  }

  public updateFooterHeight(useSmallWidth: boolean): void {
    this.shortcutWidth = useSmallWidth ? 58 : 38;
    this.detectChangesRisize.next();
  }

  public handleFastFilter(filter: IShortcutFastFilter | null): void {
    if (!filter) {
      this._updateTradesFilterPreferences({ fastFilter: null });
      this.filtersAndHighlightEnabled = false;
      this.detectComponentChanges.next();
      return;
    }
    const configs = { id: filter.id, qtty: filter.qtty, type: filter.type };
    this._updateTradesFilterPreferences({ fastFilter: configs, enable: true });
    this._handleTradesFilter([configs], true);
    this.filtersAndHighlightEnabled = true;
    this.detectComponentChanges.next();
  }

  private _updateTradesFilterPreferences(preferenceToSave: any): void {
    const previusValue = this.metadataConfiguration.filterAndHighlight ?? {};
    this.metadataConfiguration = {
      filterAndHighlight: {
        ...previusValue,
        ...preferenceToSave,
      },
    };
  }
}
0;
