import { Injectable } from '@angular/core';
import { IWorkSpace } from '@core/interface';
import { WorkspaceComponentService } from '@modules/home/service/workspace-component.service';
import { Subject, filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DragService {
  private events$: Subject<{ idComponent: string; data: any }> = new Subject();
  private workspace!: IWorkSpace;

  constructor(private workspaceComponentService: WorkspaceComponentService) {
    this.workspaceComponentService
      .dispatchActiveWorkspace()
      .subscribe((workspace: IWorkSpace) => (this.workspace = workspace));
    this.workspace = this.workspaceComponentService.getActiveWorkspace();
  }

  onEvents(idComponent: string) {
    return this.events$.asObservable().pipe(
      filter((data) => {
        return data.idComponent === idComponent;
      })
    );
  }

  dispatchEvent(idComponent: string, data: any) {
    this.events$.next({ idComponent, data });
  }

  public dropInAnotherComponent(params: any) {
    const { clientX, clientY } = params.event;
    this.workspace.components.forEach((component) => {
      const doc = document.getElementById(component.id);
      if (!doc) return;
      const bounding = doc.getBoundingClientRect();
      const { left, right, top, bottom } = bounding;
      if (
        clientX > left &&
        clientX < right &&
        clientY < bottom &&
        clientY > top
      ) {
        this.dispatchEvent(component.id, params.node.data);
      }
    });
  }

  get workspaceBase() {
    return document.getElementById('workspace_base')!!;
  }

  makeDragZoneParams() {
    return {
      getContainer: () => this.workspaceBase,
      onDragStop: (params: any) => {
        this.dropInAnotherComponent(params);
      },
    };
  }
}

export function rowDragText(params: any) {
  return params.rowNode.data.cd_stock;
}
