import { formatDate } from '@angular/common';

export function formatDatePtBr(
  value: string | number | Date
): string | undefined {
  return value ? formatDate(value, 'dd/MM/yyyy', 'PT-BR') : undefined;
}

export function formatDateByPattern(
  value: string | number | Date,
  pattern: string = 'YYYYMMddhhmmss'
): string | undefined {
  return value ? formatDate(value, pattern, 'PT-BR') : undefined;
}

export function randomId(name: string): string {
  const array = new Uint32Array(10);
  const id = window.crypto.getRandomValues(array)[0];
  return `${name}_${id}`;
}

export function formatDateWorkspace(dt: any): number {
  return parseInt(formatDateByPattern(dt)!);
}
