import { GENERIC_INTRO_STEPS } from './gerenric.steps';

const BOOK_INTRO_STEPS = {
  INITIAL: (component: Element) => ({
    title: 'Conheça o Book',
    intro:
      'No book de ofertas, você acompanha todas as intenções de compra e venda dos players do mercado. Nele, você encontra o melhor preço de compra e venda, volume de negociação e o número de ofertas daquele ativo. <br /><br /> Use as setas do teclado ou clique no próximo para começar. ',
    element: component,
  }),
  TABLE_BOOK: (component: Element, forceDisplayContexMenu: any) => ({
    title: 'Book de ofertas',
    intro:
      'Dentro do book você visualiza a quantidade de negócios ofertados em um determinado preço e o lado da negociação, sendo compra e venda.',
    element: component.querySelector('#BOOK'),
    preChange: function () {
      forceDisplayContexMenu();
    },
  }),
  QUANTITY_VOLUME: (component: Element) => ({
    title: 'Volume de quantidade',
    intro:
      'O volume de quantidade no Book refere-se à porção de ativos disponíveis em cada nível de preço. Essa informação pode ser útil para os traders que desejam ver onde estão os grandes volumes de negociação e identificar áreas importantes de suporte e resistência.',
    element: component.querySelector('#BOOK_QUANTITY_VOLUME'),
    position: 'bottom',
    preChange: function () {
      this.element = component.querySelector('#BOOK_QUANTITY_VOLUME');
      this.position = 'bottom';
    },
  }),
  CONTEXT: () => ({
    enum: 'CONTEXT_MENU',
    title: 'Funcionalidades',
    intro:
      'Você pode configurar as funcionalidades clicando com o botão direito dentro do Book. Vamos explicar cada uma delas a seguir.',
    element: document.querySelector('app-table-book-context-menu > div'),
    position: 'bottom',
    preChange: function () {
      this.element = document.querySelector(
        'app-table-book-context-menu > div'
      );
      this.position = 'bottom';
    },
  }),
  CONTEXT_TOGGLE_VISIBILITY: () => ({
    enum: 'CONTEXT_MENU',
    title: 'Ocultar funcionalidades',
    intro:
      'Oculte algumas das funcionalidades do Book, entre elas a barra de pressão e detalhes.',
    element: document.querySelector('#BOOK_CONTEXT_TOGGLE_VISIBILITY'),
    position: 'bottom',
    preChange: function () {
      this.element = document.querySelector('#BOOK_CONTEXT_TOGGLE_VISIBILITY');
      this.position = 'bottom';
    },
  }),
  CONTEXT_ORDER: () => ({
    enum: 'CONTEXT_MENU',
    title: 'Exibir Ordens',
    intro:
      'Aqui, você visualiza o número de ordens agrupadas que estão sendo negociadas naquele determinado preço.',
    element: document.querySelector('#BOOK_CONTEXT_ORDER'),
    position: 'bottom',
    preChange: function () {
      this.element = document.querySelector('#BOOK_CONTEXT_ORDER');
      this.position = 'bottom';
    },
  }),
  CONTEXT_HIGHLIGHT_AGRESSOR: () => ({
    enum: 'CONTEXT_MENU',
    title: 'Ocultar Agressor',
    intro:
      'Aqui você poderá ocultar a marcação (em amarelo) que identifica a mudanças nos preços máximos e mínimos do ativo. Você pode voltar a exibir elas a qualquer momento, clicando em Agressor’.',
    element: document.querySelector('#BOOK_CONTEXT_HIGHLIGHT_AGRESSOR'),
    position: 'bottom',
    preChange: function () {
      this.element = document.querySelector('#BOOK_CONTEXT_HIGHLIGHT_AGRESSOR');
      this.position = 'bottom';
    },
  }),
  CONTEXT_HIGHLIGHT_QUANTITY: () => ({
    enum: 'CONTEXT_MENU',
    title: 'Destaque de Ofertas',
    intro:
      'Desabilitando o destaque das ofertas, você não verá marcação em verde e vermelho para as mudanças de quantidade de cada preço.',
    element: document.querySelector('#BOOK_CONTEXT_HIGHLIGHT_QUANTITY'),
    position: 'bottom',
    preChange: function () {
      this.element = document.querySelector('#BOOK_CONTEXT_HIGHLIGHT_QUANTITY');
      this.position = 'bottom';
    },
  }),
};

export const BOOK_STEPS = (
  bookElement: Element,
  forceDisplayContexMenu: any
) => [
  BOOK_INTRO_STEPS.INITIAL(bookElement),
  GENERIC_INTRO_STEPS.STOCK_SELECTOR(bookElement),
  GENERIC_INTRO_STEPS.FORCE_CHART(bookElement),
  GENERIC_INTRO_STEPS.STOCK_DETAILS(bookElement),
  BOOK_INTRO_STEPS.TABLE_BOOK(bookElement, forceDisplayContexMenu),
  BOOK_INTRO_STEPS.QUANTITY_VOLUME(bookElement),
  BOOK_INTRO_STEPS.CONTEXT(),
  BOOK_INTRO_STEPS.CONTEXT_TOGGLE_VISIBILITY(),
  BOOK_INTRO_STEPS.CONTEXT_ORDER(),
  BOOK_INTRO_STEPS.CONTEXT_HIGHLIGHT_AGRESSOR(),
  BOOK_INTRO_STEPS.CONTEXT_HIGHLIGHT_QUANTITY(),
  GENERIC_INTRO_STEPS.TOUR_END,
];
