import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '@shared/services/rest';
import { Observable, catchError, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends RestService {
  override _url: string = 'api/trademap/v9/authentication';

  constructor(http: HttpClient) {
    super(http);
  }

  public confirmEmail(token: string): Observable<boolean> {
    return this.post('confirm-email', { token }).pipe(
      catchError((err) => this.handlerServiceError(err)),
      map((response: any) => {
        if (response.data?.success && response.data?.result) return true;
        throw new Error();
      })
    );
  }
}
