import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '@shared/services/rest';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService extends RestService {
  override _url: string = 'api/trademap/v1/analytics';

  constructor(http: HttpClient) {
    super(http);
  }

  saveEvents(requests: EventAnalytics[]) {
    return this.post('save-events', {
      requests,
    });
  }
}

export interface EventAnalytics {
  ds_function: string;
  ds_subfunction: string;
  ds_platform: string;
  ds_param: string;
  version_name: string;
  version: string;
}
