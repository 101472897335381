import {
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components/index';
import {
  IModalToken,
  IModalTokenSettings,
} from './interface/modal-order-token.interface';
import {
  TOKEN_REGISTERED,
  TOKEN_VISIBILITY_OFF,
  TOKEN_VISIBILITY_ON,
  WITHOUT_TOKEN,
} from './constants/modal-order.constants';
import { OrderTokenService } from './order-token.service';
import { ToastService } from '@shared/services';
import {
  TOKEN_AUTHENTICATORS_BY_ID,
  TTokenAuthenticator,
  TTokenPreferenceAuthenticator,
} from '@core/layout/header/modal-token-config/types';
import { ModalTokenConfigComponent } from '@core/layout/header/modal-token-config/modal-token-config.component';
import { IAPIServiceResponse } from '@shared/services/api/trademap/v1/interface/apiResponse';
import { OtpService } from '@shared/services/api/authentication/v3/otp.service';

@Component({
  selector: 'app-modal-order-token',
  templateUrl: './modal-order-token.component.html',
})
export class ModalOrderTokenComponent extends RocketModalRef implements OnInit {
  @ViewChild('inputToken', { static: false }) public inputToken!: any;

  public modalInfs: IModalToken = WITHOUT_TOKEN;
  public settings: IModalTokenSettings = TOKEN_VISIBILITY_ON;

  tokenForm!: FormGroup;
  tokenVisible: boolean = true;
  disableConfirmBtn: boolean = false;
  onlyNumber = /^[0-9]{6}/;
  authenticator!: TTokenAuthenticator;

  get hasToken() {
    return this._orderTokenService.hasSessionToken;
  }

  constructor(
    @Inject(ROCKET_MODAL_DATA)
    public data: {
      hasToken: boolean;
      isFirstOrder: boolean;
      isHeader?: boolean | undefined;
    },
    private fb: FormBuilder,
    private _orderTokenService: OrderTokenService,
    private _rocketModalService: RocketModalService,
    private _toastService: ToastService,
    private _otpService: OtpService
  ) {
    super(_rocketModalService);
  }

  ngOnInit() {
    this._buildTokenForm();
    if (this.data.hasToken || this.hasToken) {
      this.modalInfs = TOKEN_REGISTERED;
      return;
    }
    this._getTokenPreference();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeyScapePress() {
    this.close(false, false);
  }

  private _getTokenPreference() {
    this._otpService
      .userTokenPreferences()
      .subscribe((response: TTokenPreferenceAuthenticator) => {
        if (response === 'UNDEFINED_TOKEN') {
          this._toastService.showToast(
            'error',
            'Token não configurado. Por favor configure um token antes de enviar ordens!'
          );
          return;
        }
        this._focusOnFieldText();

        this.modalInfs = WITHOUT_TOKEN;
        this.authenticator = TOKEN_AUTHENTICATORS_BY_ID[response];
      });
  }

  private _focusOnFieldText(): void {
    setTimeout(() => {
      document.getElementById(this.inputToken.refId)?.focus();
    }, 400);
  }

  private _buildTokenForm(): void {
    this.tokenForm = this.fb.group({
      token: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
          Validators.pattern(this.onlyNumber),
        ],
      ],
    });
  }

  public onClose = () => {
    this.close({ confirm: false });
  };

  onConfirm() {
    this.disableConfirmBtn = true;

    this._otpService
      .activateAuthenticatorConfig(
        this.tokenForm.controls['token'].value,
        this.authenticator.id,
        true
      )
      .subscribe((res: IAPIServiceResponse) => {
        if (res && res.success) {
          this._saveTokenInfos();
          return;
        }
        this._toastService.showToast(
          'error',
          res.message
            ? res.message
            : 'Ocorreu um erro ao validar o token. Por favor tente novamente!'
        );
        this.disableConfirmBtn = false;
      });
  }

  private _saveTokenInfos = (): void => {
    this._orderTokenService.tradingToken =
      this.tokenForm.controls['token'].value;
    this.close({ confirm: true });
  };

  public toggleTokenVisibility() {
    this.tokenVisible = !this.tokenVisible;
    this.settings = this.tokenVisible
      ? TOKEN_VISIBILITY_ON
      : TOKEN_VISIBILITY_OFF;
  }

  validityToken(event: any) {
    const tk = event.target.value.replace(/\D+/g, '');
    this.tokenForm.controls['token']?.setValue(tk);
  }

  changeAuthenticator(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.close({ confirm: false });
    this._rocketModalService.open(ModalTokenConfigComponent, {
      size: 'lg',
      centered: true,
      backdrop: true,
      keyboard: true,
      scrollable: false,
      data: {
        callback: (modalDismissed: boolean) => {
          if (modalDismissed) return;
          this._orderTokenService.openTokenModal(this.data.hasToken, false);
        },
      },
    });
  }

  public backPrimaryModalContent(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.modalInfs = WITHOUT_TOKEN;
    this._focusOnFieldText();
  }
}
