export enum TFooterType {
  LOG,
  SCALE,
  RULER,
  CHART_BOOK,
  CHART_VOLUME_AT_PRICE,
  FAST_ORDER,
  VOLUME,
  EVENTS,
  ZOOM_IN,
  EXEC_ORDERS,
  EXEC_ORDERS_FLAG,
  EXEC_ORDERS_MARKER,
  EXEC_ORDERS_OPEN_FLAG,
  PREVIOUS_CLOSE,
  QTTY_GROUP_STOCK,
  SHOW_NAVIGATION_CONTROLS,
  USE_SPLIT,
  SHOW_BID_ASK,
  PRICE_CLOSE_TIMER,
  AUCTION_EVENTS,
  SHOW_MIN_MAX_VISIBLE,
  SHOW_TRADING_CHANGE,
  FAST_ORDER_DAYTRADE_STRATEGY,
  BREAK_EVEN,
  TRADING_OFFSET,
  GRID_LINES,
  WATER_MARK,
  WATER_MARK_OPACITY,
}
