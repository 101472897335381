import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FlaInputModule,
  IInternationalNumber,
  PhoneInputModule,
  ROCKET_MODAL_DATA,
  RocketButtonModule,
  RocketModalModule,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { StandaloneModalsService } from '../standalone-modals.service';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FlaFocusModule } from '@shared/directives/focus/focus.module';
import { Subject, auditTime, delay, filter, takeUntil, tap } from 'rxjs';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { UserService } from '@shared/services/api/trademap/v1/user-service/user.service';
import { AuthService, ToastService } from '@shared/services';
import { emailValidator } from '@shared/validators/email-validator';
import { getTokenTooltip } from '@core/layout/header/modal-meus-dados/constants/modal-meus-dados.constants';
import { system } from '@core/system/system.service';
import { TOKEN_AUTHENTICATORS_NAME } from '@core/layout/header/modal-token-config/types';
import { internationalPhoneValidator } from '@shared/validators/phone-validator';
import { InvestorDataInterface } from '@shared/services/api/trademap/v1/user-service/models/interface/investor-data.interface';
import { IUserServiceResponse } from '@shared/services/api/trademap/v1/user-service/models/interface/user-service-response.interface';

export enum CHANGE_DATA_STEPS {
  DATA = 1,
  PINCODE = 2,
  AUTHENTICATION = 3,
}

export const firstStepDescription = (field: 'telefone' | 'e-mail'): string => {
  return `
  <p>
    Para alterar o seu ${field} informe o novo ${field}, adicione sua senha do
    <span class="fw-bold">RocketTrader</span> e clique em
    <span class="fw-bold">Continuar</span>
  </p>
  <p>
    Por favor, insira um ${field} e sua senha do
    <span class="fw-bold">RocketTrader</span> e clique em
    <span class="fw-bold">Continuar</span>
  </p>
  `;
};

const PHONE_STEPS_DESCRIPTION = {
  1: firstStepDescription('telefone'),
  2: `<p>Informe o código de verificação recebido via SMS e clique em <span class="fw-bold">Continuar</span></p>`,
  3: `<p>Por fim, adicione o seu segundo fator de autenticação.</p>`,
};

const EMAIL_STEPS_DESCRIPTION = {
  1: firstStepDescription('e-mail'),
  2: `<p>Para seguir com a alteração do seu e-mail, é necessário validar o seu e-mail inserido, confirmar o PIN recebido via e-mail e clicar em <span class="fw-bold">Continuar</span>`,
  3: `<p>Por fim, adicione o seu segundo fator de autenticação.</p>`,
};

export interface IChangePhoneOrEmailModalParams {
  data: InvestorDataInterface;
  field: 'phone_mobile' | 'email';
  customTexts:
  | undefined
  | {
    title: string;
    description: string;
  };
}

@Component({
  selector: 'app-modal-change-phone',
  templateUrl: './modal-change-phone.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RocketModalModule,
    RocketButtonModule,
    FlaInputModule,
    FormsModule,
    ReactiveFormsModule,
    FlaFocusModule,
    TooltipsModule,
    PhoneInputModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalChangePhoneComponent
  extends RocketModalRef
  implements OnInit, OnDestroy {
  public form!: FormGroup;

  public readonly switchOptions = CHANGE_DATA_STEPS;
  public currentStep: CHANGE_DATA_STEPS = CHANGE_DATA_STEPS.DATA;

  public currentData: string = 'Número de telefone não foi encontrado';
  public errorMessage: string = 'Ocorreu um erro inesperado';
  public title: string = 'Alteração de telefone';
  public fieldLabel: 'Telefone' | 'E-mail' = 'Telefone';
  public description: string = PHONE_STEPS_DESCRIPTION[this.currentStep];
  public tokenTooltip: string = '';

  public isMobile: boolean = false;
  public viewPassword: boolean = false;
  public viewPincode: boolean = false;
  public viewToken: boolean = false;
  public disableSubmitBtn: boolean = true;
  public disableResendPincode: boolean = true;
  public error: boolean = false;
  public isLoading: boolean = false;

  public timeToResend: number = 0;

  private _fieldStepsDescription = PHONE_STEPS_DESCRIPTION;
  private _destroy = new Subject<void>();
  private _resendPincodeInterval = new Subject<void>();
  private _detectChangesSubject = new Subject<void>();
  private _modalParams!: IChangePhoneOrEmailModalParams;

  get value() {
    return this.form.get('value')!;
  }

  get password() {
    return this.form.get('password')!;
  }

  get pincode() {
    return this.form.get('pincode')!;
  }

  get token() {
    return this.form.get('token')!;
  }

  get phoneCountryCode() {
    return this.form.get('phone_country_code')!;
  }

  constructor(
    private _rocketModalService: RocketModalService,
    @Inject(ROCKET_MODAL_DATA)
    public data: { params: IChangePhoneOrEmailModalParams },
    private _standaloneModalsService: StandaloneModalsService,
    private _formBuilder: FormBuilder,
    private _cdr: ChangeDetectorRef,
    private _userService: UserService,
    private _authService: AuthService,
    private _toast: ToastService
  ) {
    super(_rocketModalService);
    this._resendPincodeInterval
      .pipe(
        delay(1000),
        filter(
          () =>
            this.timeToResend !== 0 &&
            this.currentStep === CHANGE_DATA_STEPS.PINCODE
        ),
        tap(() => {
          this.disableResendPincode = this.timeToResend > 1;
          this._detectChangesSubject.next();
        })
      )
      .subscribe(() => {
        this.timeToResend = this.timeToResend - 1;
        this._detectChangesSubject.next();
        this._resendPincodeInterval.next();
      });

    this._detectChangesSubject
      .pipe(auditTime(100))
      .subscribe(() => this._cdr.detectChanges());
  }

  ngOnInit(): void {
    this._buildTokenTooltip();
    this._modalParams = this.data.params;
    this._configureInfos();
  }

  override ngOnDestroy(): void {
    this._resendPincodeInterval.unsubscribe();
    this._detectChangesSubject.unsubscribe();
    this._destroy.next();
    this._destroy.complete();
    super.ngOnDestroy();
  }

  private _initializeForm = (): void => {
    this.form = this._formBuilder.group({
      value: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.maxLength(120)]],
      pincode: ['', [Validators.required, Validators.maxLength(10)]],
      token: ['', [Validators.required, Validators.maxLength(10)]],
      phone_country_code: [{ country: 'br', code: '55' }, Validators.required],
    });

    this.form.valueChanges
      .pipe(auditTime(100), takeUntil(this._destroy))
      .subscribe(() => {
        if (this.currentStep === CHANGE_DATA_STEPS.DATA) {
          this.disableSubmitBtn = !this.value.valid || !this.password.valid;
          this._detectChangesSubject.next();
          return;
        }

        if (this.currentStep === CHANGE_DATA_STEPS.PINCODE) {
          this.disableSubmitBtn = !this.pincode.valid;
          this._detectChangesSubject.next();
          return;
        }
        this.disableSubmitBtn = !this.token.valid;
        this._detectChangesSubject.next();
      });
  };

  private _buildTokenTooltip(): void {
    if (!system.authenticationMethod) return;
    this.tokenTooltip = getTokenTooltip();
  }

  private _configureInfos(): void {
    const isMobile = this._modalParams.field === 'phone_mobile';
    this.isMobile = isMobile;
    if (isMobile) {
      this._initializeForm();
      if (this._modalParams.data?.phone_mobile)
        this.currentData = this._modalParams.data.phone_mobile;
      if (this._modalParams.customTexts) this._useCustomTexts();
      this.value.setValidators([
        Validators.required,
        Validators.maxLength(15),
        internationalPhoneValidator,
      ]);
      return;
    }

    this._configureToChangeEmail();
  }

  private _configureToChangeEmail(): void {
    this._initializeForm();
    this.title = 'Alterar E-mail';
    this.fieldLabel = 'E-mail';
    this.currentData = this._modalParams.data?.email
      ? this._modalParams.data.email
      : 'E-mail não foi encontrado';
    this._fieldStepsDescription = EMAIL_STEPS_DESCRIPTION;
    this.description = EMAIL_STEPS_DESCRIPTION[this.currentStep];
    this.value.setValidators([Validators.required, emailValidator]);
    this._detectChangesSubject.next();
  }

  private _useCustomTexts(): void {
    this.title = this._modalParams.customTexts!.title;
    this.description = this._modalParams.customTexts!.description;
  }

  // PHONE
  public onUpdateCountryCode(value: IInternationalNumber): void {
    this.phoneCountryCode.patchValue(value);
  }

  // NAVIGATION
  public goBack() {
    if (this.currentStep === CHANGE_DATA_STEPS.DATA) {
      this._standaloneModalsService.close();
      return;
    }
    this.currentStep =
      this.currentStep === CHANGE_DATA_STEPS.PINCODE
        ? CHANGE_DATA_STEPS.DATA
        : CHANGE_DATA_STEPS.PINCODE;
    if (this.currentStep === CHANGE_DATA_STEPS.PINCODE)
      this._verifyResendPincodeTimeout();
    this.description = this._fieldStepsDescription[this.currentStep];
  }

  public submit() {
    if (this.disableSubmitBtn || this.isLoading) return;
    if (this.currentStep === CHANGE_DATA_STEPS.DATA) {
      this._sendPincode(false);
      return;
    }
    if (this.currentStep === CHANGE_DATA_STEPS.PINCODE) {
      this._checkPincode();
      return;
    }
    if (this.currentStep === CHANGE_DATA_STEPS.AUTHENTICATION) {
      this._checkAuthToken();
      return;
    }
  }

  private _navToNextStep(): void {
    this.currentStep =
      this.currentStep === CHANGE_DATA_STEPS.DATA
        ? CHANGE_DATA_STEPS.PINCODE
        : CHANGE_DATA_STEPS.AUTHENTICATION;
    this.disableSubmitBtn = true;
    if (this.currentStep === CHANGE_DATA_STEPS.AUTHENTICATION) {
      this._setAuthenticationName();
      return;
    }
    this.description = this._fieldStepsDescription[this.currentStep];
  }

  private _setAuthenticationName(): void {
    const label = this._fieldStepsDescription[this.currentStep];
    const authName = TOKEN_AUTHENTICATORS_NAME[system.authenticationMethod!];
    this.description = label.replace('[AUTHENTICATOR_NAME]', `do ${authName}`);
  }

  private _beforeCallService(): void {
    this.disableResendPincode = true;
    this.error = false;
    this.isLoading = true;
    this._detectChangesSubject.next();
  }

  private _getFieldValue(): string {
    if (this._modalParams.field === 'phone_mobile') {
      const phone =
        `${this.phoneCountryCode.value.code}${this.value.value}`.replace(
          /\D/g,
          ''
        );
      return `+${phone}`;
    }
    return this.value.value;
  }

  // PINCODE
  private _sendPincode(isResendPincode: boolean): void {
    this._beforeCallService();
    this._userService
      .updateInfoRequest({
        field: this._modalParams.field,
        password: btoa(this.password.value),
        value: this._getFieldValue(),
      })
      .subscribe({
        next: (response: any) => {
          this.isLoading = false;
          this.timeToResend = response.next_interaction;
          this._resendPincodeInterval.next();
          if (isResendPincode) return;
          this._navToNextStep();
          this._cdr.detectChanges();
        },
        error: (response) => {
          this.isLoading = false;
          const msg = response.message
            ? response.message
            : 'Não foi possível enviar a solicitação de alteração. Por favor tente novamente';
          this.error = true;
          this.errorMessage = msg;
          this.disableResendPincode = false;
          this._detectChangesSubject.next();
        },
      });
  }

  public resendPincode(): void {
    if (this.disableResendPincode) return;
    this._sendPincode(true);
  }

  private _verifyResendPincodeTimeout(): void {
    if (!this.timeToResend) {
      this.disableResendPincode = false;
      this.timeToResend = 0;
      this._detectChangesSubject.next();
      return;
    }
    this._resendPincodeInterval.next();
  }

  private _checkPincode(): void {
    this._beforeCallService();
    this._userService
      .checkPhoneUpdatePinCode({
        field: this._modalParams.field,
        value: this._getFieldValue(),
        pincode: this.pincode.value,
      })
      .subscribe((result: IUserServiceResponse) => {
        this.isLoading = false;
        if (result.isError) {
          this.error = true;
          this.errorMessage = 'Token incorreto. Por favor, tentar novamente.';
          this.pincode?.setErrors({ required: true });
          this._detectChangesSubject.next();
          return;
        }
        this._navToNextStep();
        this._detectChangesSubject.next();
      });
  }

  // AUTH TOKEN
  private _checkAuthToken(): void {
    this._beforeCallService();
    this._userService
      .updateFieldByToken({
        field: this._modalParams.field,
        password: btoa(this.password.value),
        token: this.token.value,
        value: this._getFieldValue(),
        cdOrigin: system.authenticationMethod!,
      })
      .subscribe((response) => {
        this.isLoading = false;
        if (response.isError) {
          if (response?.code === 'BLOCKED_PASSWORD') {
            this._authService.performLogout({
              reason: 'BLOCKED_PASSWORD',
              systemMessage: 'Senha bloqueada',
            });
            return;
          }
          this.token?.setErrors({ required: true });
          this.error = true;
          this.errorMessage = response.message
            ? response.message
            : 'Ocorreu um erro ao validar o token TradeMap, tente novamente.';
          this._cdr.detectChanges();
          return;
        }
        this._toast.showToast(
          'success',
          `${this.fieldLabel} alterado com sucesso`
        );
        this._standaloneModalsService.close({
          concluded: true,
          newPhone: this.value.value,
        });
      });
  }
}
