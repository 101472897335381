import { TIGER_INDICATORS_MENU_ENUM } from '../../enum/tiger-chart.enum';
import MenuItemBase from './menu-item-base';
import VisualOrderBringToFront from './visual-order-bring-front';
import VisualOrderSendToBack from './visual-order-send-back';
import VisualOrderBringForward from './visual-order-bring-foward';
import VisualOrderSendBackward from './visual-order-send-backward';

export default class VisualOrder extends MenuItemBase {
  constructor() {
    super();
    this.label = 'Ordem visual';
    this.id = TIGER_INDICATORS_MENU_ENUM.VISUAL_ORDER;
    this.submenu = [
      new VisualOrderBringToFront(),
      new VisualOrderSendToBack(),
      new VisualOrderBringForward(),
      new VisualOrderSendBackward(),
    ];
  }

  override onCLick(): void {}
}
