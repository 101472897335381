<div
  tooltip="Falar com atendente"
  class="cursor-pointer mb-1 fill-white"
  (click)="openWhats()"
>
  <svg width="17" height="17" xmlns="http://www.w3.org/2000/svg">
    <g stroke="null" id="svg_3">
      <path
        stroke="null"
        d="m12.57894,10.34305c-0.03312,-0.01683 -1.27244,-0.66302 -1.49267,-0.74694c-0.08991,-0.03419 -0.18622,-0.06756 -0.28866,-0.06756c-0.16735,0 -0.30793,0.08831 -0.41743,0.26176c-0.1238,0.19484 -0.49857,0.65869 -0.61437,0.79726c-0.01512,0.01829 -0.03577,0.04015 -0.04813,0.04015c-0.01109,0 -0.20289,-0.08363 -0.26096,-0.11034c-1.32946,-0.61147 -2.33857,-2.08192 -2.47694,-2.32989c-0.01976,-0.03565 -0.02059,-0.05184 -0.02075,-0.05184c0.00486,-0.01888 0.04956,-0.06633 0.07264,-0.09082c0.0675,-0.07071 0.14063,-0.16393 0.21139,-0.2541c0.0335,-0.04272 0.06706,-0.0855 0.10001,-0.12582c0.10266,-0.12647 0.14836,-0.22465 0.20135,-0.33838l0.02776,-0.05908c0.12937,-0.27216 0.01888,-0.50184 -0.01683,-0.576c-0.02931,-0.06206 -0.5526,-1.39932 -0.60824,-1.53982c-0.13379,-0.33902 -0.31058,-0.49687 -0.55625,-0.49687c-0.0228,0 0,0 -0.0956,0.00427c-0.1164,0.0052 -0.75031,0.09356 -1.03058,0.28064c-0.29722,0.19841 -0.80004,0.83086 -0.80004,1.94312c0,1.00105 0.59996,1.94622 0.85755,2.30569c0.0064,0.00906 0.01816,0.02747 0.03521,0.05388c0.98648,1.52544 2.21626,2.65593 3.46293,3.18325c1.20019,0.50762 1.76853,0.5663 2.09163,0.5663c0.00006,0 0.00006,0 0.00006,0c0.13578,0 0.24445,-0.01128 0.34033,-0.02127l0.06082,-0.00614c0.41462,-0.03892 1.32576,-0.53883 1.53301,-1.14867c0.16326,-0.48033 0.20632,-1.00514 0.09769,-1.1956c-0.0744,-0.12951 -0.20262,-0.19467 -0.36494,-0.27719z"
        id="svg_5"
      />
      <path
        stroke="null"
        d="m8.65078,0c-4.60407,0 -8.34976,3.93627 -8.34976,8.77459c0,1.56489 0.39552,3.0967 1.14478,4.43735l-1.43411,4.47925c-0.02671,0.08351 -0.00684,0.17585 0.0515,0.23873c0.04211,0.04553 0.09924,0.07007 0.15758,0.07007c0.02235,0 0.04487,-0.00356 0.06684,-0.01099l4.41111,-1.48418c1.20709,0.68289 2.57138,1.04336 3.95212,1.04336c4.60363,0.00006 8.34893,-3.93581 8.34893,-8.7736c0,-4.83832 -3.7453,-8.77459 -8.34898,-8.77459zm0,15.72043c-1.29921,0 -2.55758,-0.39723 -3.63928,-1.14879c-0.03637,-0.02531 -0.0786,-0.03828 -0.1211,-0.03828c-0.02246,0 -0.04498,0.00362 -0.0669,0.01099l-2.20969,0.74373l0.71333,-2.22832c0.02307,-0.07212 0.01154,-0.15166 -0.03096,-0.21314c-0.82372,-1.19174 -1.25914,-2.59977 -1.25914,-4.07203c0,-3.83043 2.9669,-6.94677 6.61368,-6.94677c3.64634,0 6.61291,3.11634 6.61291,6.94677c0.00006,3.82997 -2.96646,6.94584 -6.61286,6.94584z"
        id="svg_4"
      />
    </g>
  </svg>
</div>
