import { Injectable } from '@angular/core';
import { RxEvent } from '@shared/channel/rx-event';
import {
  ContextMenuEventPayload,
  OpenEventContext,
} from '@shared/components/popup-root/context-menu-event.model';
import { TimesTradesContextMenuComponent } from '@shared/components/times-trades/parts/times-trades-context-menu/times-trades-context-menu.component';
import { Subject, Subscription, filter } from 'rxjs';
import { TabelBookContextMenuComponent } from '../table-book/parts/table-book-context-menu/table-book-context-menu.component';
import { SuperDomContextMenuComponent } from '../super-dom/parts/context-menu/super-dom-context-menu.component';
import { TigerChartContextMenuComponent } from '@shared/tiger-chart/context-menu/tiger-chart-context-menu.component';
import { StockTableContextMenuComponent } from '../stock-table/grid-config/stock-table-context-menu.component';
import { OrderTabsContextMenuComponent } from '@core/components/orders-history-core/grid/order-tabs-context-menu/order-tabs-context-menu.component';
import { PositionTabContextMenuComponent } from '@core/components/orders-history-core/grid/position-tab-context-menu/position-tab-context-menu.component';
import { AlertTabContextMenuComponent } from '@core/components/orders-history-core/grid/alert-tab-context-menu/alert-tab-context-menu.component';
import { OrderHistoryHeaderConfigComponent } from '@core/components/orders-history-core/orders-history-header/header-config/header-config.component';
import { CONTEXT_MENU_EVENT } from '@shared/constants/context-menu.const';
import { OptionsTableContextMenuComponent } from '../options/config/options-table-context-menu.component';
import { HeatmapContextMenuComponent } from '../heatmap/config/heatmap-context-menu.component';
import { NewsContextMenuComponent } from '../news/config/news-context-menu.component';
import { AtPriceContextMenuComponent } from '../at-price/parts/at-price-context-menu.component';

class ClassRegistry {
  private classMap: Map<string, any>;

  constructor() {
    this.classMap = new Map();
  }

  registerClass(className: string, classReference: any): void {
    this.classMap.set(className, classReference);
  }

  getClassByName(className: string): any | undefined {
    return this.classMap.get(className);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ContextMenuService {
  static readonly CONTEXT_MENU_EVENT = 'CONTEXT_MENU';
  static readonly CONTEXT_MENU_EVENT_OPEN = 'OPEN';
  static readonly CONTEXT_MENU_EVENT_CLOSE = 'CLOSE';
  static readonly INFINITY_READ_STREAM = true;

  private contextMenuEvent$ = new Subject<ContextMenuEventPayload<any>>();
  private classRegistry = new ClassRegistry();
  private finalizer = new Subscription();

  static get AVAIABLE_CONTEXTMENU_SET(): { className: string; clazz: any }[] {
    return [
      {
        className: 'TimesTradesContextMenuComponent',
        clazz: TimesTradesContextMenuComponent,
      },
      {
        className: 'TabelBookContextMenuComponent',
        clazz: TabelBookContextMenuComponent,
      },
      {
        className: 'SuperDomContextMenuComponent',
        clazz: SuperDomContextMenuComponent,
      },
      {
        className: 'TigerChartContextMenuComponent',
        clazz: TigerChartContextMenuComponent,
      },
      {
        className: 'StockTableContextMenuComponent',
        clazz: StockTableContextMenuComponent,
      },
      {
        className: 'OrderTabsContextMenuComponent',
        clazz: OrderTabsContextMenuComponent,
      },
      {
        className: 'PositionTabContextMenuComponent',
        clazz: PositionTabContextMenuComponent,
      },
      {
        className: 'AlertTabContextMenuComponent',
        clazz: AlertTabContextMenuComponent,
      },
      {
        className: 'OrderHistoryHeaderConfigComponent',
        clazz: OrderHistoryHeaderConfigComponent,
      },
      {
        className: 'OptionsTableContextMenuComponent',
        clazz: OptionsTableContextMenuComponent,
      },
      {
        className: 'HeatmapContextMenuComponent',
        clazz: HeatmapContextMenuComponent,
      },
      {
        className: 'NewsContextMenuComponent',
        clazz: NewsContextMenuComponent,
      },
      {
        className: 'AtPriceContextMenuComponent',
        clazz: AtPriceContextMenuComponent,
      },
    ];
  }

  constructor(private rxEvent: RxEvent) {
    ContextMenuService.AVAIABLE_CONTEXTMENU_SET.forEach(
      (classReference: { className: string; clazz: any }) => {
        this.registerComponent(classReference.className, classReference.clazz);
      }
    );

    this.streamInit();
  }

  close(): void {
    this.finalizer.unsubscribe();
  }

  async streamInit() {
    try {
      const streamResult = await this.rxEvent.read(CONTEXT_MENU_EVENT.CHANNEL);
      this.readStream(streamResult.stream);
      this.finalizer.add(streamResult.close);
    } catch (error) {
      console.error('Error reading context menu event', error);
    }
  }

  private async readStream(stream: ReadableStream) {
    const reader = stream.getReader();
    let ret;
    do {
      ret = await reader.read();
      if (!ret.done) {
        this.contextMenuEvent$.next(ret.value.data);
      }
    } while (ContextMenuService.INFINITY_READ_STREAM);
  }

  private registerComponent(className: string, classReference: any): void {
    this.classRegistry.registerClass(className, classReference);
  }

  getComponentByName(className: string): any | undefined {
    return this.classRegistry.getClassByName(className);
  }

  openContextMenu(parent_id: string, openEventContext: OpenEventContext) {
    this.rxEvent.emit(CONTEXT_MENU_EVENT.CHANNEL, {
      channel: CONTEXT_MENU_EVENT.CHANNEL,
      id: parent_id,
      action: CONTEXT_MENU_EVENT.OPEN,
      event: openEventContext.event,
      type: openEventContext.type,
      openEventContext: openEventContext,
    });
  }

  emitEvent<T>(parent_id: string, action: T) {
    const payload: ContextMenuEventPayload<T> = {
      channel: CONTEXT_MENU_EVENT.CHANNEL,
      id: parent_id,
      action,
    };
    this.rxEvent.emit(CONTEXT_MENU_EVENT.CHANNEL, payload);
  }

  closeContextMenu(parent_id: string) {
    this.rxEvent.emit(CONTEXT_MENU_EVENT.CHANNEL, {
      channel: CONTEXT_MENU_EVENT.CHANNEL,
      action: CONTEXT_MENU_EVENT.CLOSE,
      id: parent_id,
    });
  }

  listenActionEvent<T>(parent_id: string) {
    return this.contextMenuEvent$
      .asObservable()
      .pipe(
        filter((data: ContextMenuEventPayload<T>) => data.id === parent_id)
      );
  }

  listenOpenEvent() {
    return this.contextMenuEvent$
      .asObservable()
      .pipe(
        filter(
          (data: ContextMenuEventPayload<'OPEN'>) =>
            data.action === ContextMenuService.CONTEXT_MENU_EVENT_OPEN
        )
      );
  }

  listenCloseEvent(parent_id: string) {
    return this.contextMenuEvent$
      .asObservable()
      .pipe(
        filter(
          (data: ContextMenuEventPayload<'CLOSE'>) =>
            data.id === parent_id &&
            data.action === ContextMenuService.CONTEXT_MENU_EVENT_CLOSE
        )
      );
  }
}
