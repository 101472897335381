import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { Point } from 'scichart/Core/Point';
import { IHVLineAnnotationOptions } from 'scichart/Charting/Visuals/Annotations/HorizontalLineAnnotation';
import { VerticalLineAnnotation } from 'scichart/Charting/Visuals/Annotations/VerticalLineAnnotation';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import {
  TIGER_CHART_TOOL,
  TIGER_CHART_TOOL_CHILD,
} from '../tiger-chart-tools/tiger-chart-tools.interface';
import { TMouseEventData } from '../types/tiger-chart.types';
import {
  DrawTools,
  EventSubject,
  LineToolsOptions,
  StyleTool,
} from './draw-tools.interface';
import { TOOLS_ENUM } from '../tiger-chart-tools/tiger-chart-tools.enum';
import { AxisService } from '../services/axis.service';
import { ICandleConfigs } from '../interface';
import { ModifierMouseArgs } from 'scichart/Charting/ChartModifiers/ModifierMouseArgs';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { CANDLE_IDS } from '../constants/tiger-chart.constants';
import { TYPE_RESIZE } from './draw-tools.enum';

export class VerticalLineTools
  extends VerticalLineAnnotation
  implements DrawTools
{
  tool!: TIGER_CHART_TOOL;
  children = [];
  isDraw = true;
  startEdit = false;
  baseChart!: TWebAssemblyChart;
  isChildren = false;
  lastPointValues!: Point;
  dictIdChildren!: string;
  middleYPointValues!: Point;
  middleXPointValues!: Point;
  configs!: ICandleConfigs;
  locale!: string;
  firstFormatter: boolean = false;
  isAux: boolean = false;
  endDraw: boolean = false;
  lastEventData!: TMouseEventData;
  axisSub!: Subscription;
  isWithinDataBounds: boolean | undefined = true;
  private onDragEnded$!: Subscription;
  private _onDragEnded$ = new Subject<EventSubject>();
  private refComponent: string = '';
  constructor(
    options: IHVLineAnnotationOptions,
    private drawToolsService: DrawToolsService,
    tool: LineToolsOptions,
    private axisService: AxisService
  ) {
    super(options);
    this.initSubscriptions();
    this.isChildren = tool.isChildren;
    this.baseChart = tool.baseChart;
    this.tool = tool.tool;
    this.dictIdChildren = tool.dictIdChildren;
    this.middleYPointValues = tool.middleYPointValues;
    this.middleXPointValues = tool.middleXPointValues;
    this.configs = tool.configs;
    this.locale = tool.locale;
    this.isAux = tool.isAux;
    this.lastEventData = tool.eventData;
    this.isWithinDataBounds = tool.eventData.isWithinDataBounds;
    this.refComponent = tool.refComponent;
  }

  unsubscriber(): void {
    this.axisSub.unsubscribe();
  }

  initSubscriptions() {
    this.onDragEnded$ = this._onDragEnded$
      .pipe(debounceTime(100))
      .subscribe((data) => {
        data.function();
      });
    this.axisSub = this.drawToolsService.axisChanged$.subscribe((data) => {
      if (data.type === 'XAXIS') {
        this.tool.children?.forEach((aux) => {
          const auxDraw = this.baseChart.sciChartSurface.annotations.getById(
            aux.dictIdChildren!!
          ) as DrawTools;
          aux.isAux && auxDraw && (auxDraw.isDrawing = false);
        });
      }
    });
  }

  private destroySubscriptions() {
    this.onDragEnded$ && this.onDragEnded$.unsubscribe();
  }

  override onDetach(): void {
    super.onDetach();
    this.destroySubscriptions();
  }

  override delete(): void {
    super.delete();
    this.destroySubscriptions();
  }

  override click(args: ModifierMouseArgs, selectOnClick: boolean): boolean {
    if (this.tool.codTool === `${TOOLS_ENUM.TREND_LINES}_LINHA_VERTICAL`) {
      return super.click(args, true);
    }
    return super.click(args, selectOnClick);
  }

  override onDragEnded(): void {
    super.onDragEnded();
    if (this.tool.hasBoxAux && this.tool.children) {
      const child = this.tool.children.find((c) => c.isAux)!!;
      const aux = this.baseChart.sciChartSurface.annotations.getById(
        child.dictIdChildren!!
      );
      this.baseChart.sciChartSurface.annotations.remove(aux);
      this._onDragEnded$.next({
        function: () => {
          aux.isEditable = true;
          aux.isSelected = true;
          this.baseChart.sciChartSurface.annotations.add(aux);
        },
      });
    }
    this.drawToolsService.saveAnnotationStock(
      this.baseChart,
      this,
      this.refComponent
    );
  }

  override onDragAdorner(args: ModifierMouseArgs): void {
    if (!this.isEditable) {
      return;
    }
    super.onDragAdorner(args);
    this.middleYPointValues = new Point(this.x1, this.middleYPointValues.y);
    this.middleXPointValues = new Point(this.middleXPointValues.x, this.y1);
    this.updateAux();
  }

  getName() {
    return this.constructor.name;
  }

  updateDrawTool(eventData: TMouseEventData) {
    this.lastEventData = eventData;
    this.updateAux();
  }

  private updateAux() {
    const middleY =
      parseFloat(this.parentSurface.domSvgContainer.getAttribute('height')!!) /
      2;
    const rSeries = this.parentSurface?.renderableSeries.getById(
      CANDLE_IDS.MAIN_SERIE
    );
    const hitTestMiddleYInfo = rSeries.hitTestProvider.hitTestDataPoint(
      this.tool.mouseValues!!.x,
      middleY
    );
    this.middleYPointValues = new Point(
      this.middleYPointValues.x,
      hitTestMiddleYInfo.hitTestPointValues.y
    );
    if (this.tool.hasBoxAux && this.tool.children) {
      const child = this.tool.children.find((c) => c.isAux)!!;
      const aux = this.baseChart.sciChartSurface.annotations.getById(
        child.dictIdChildren!!
      ) as DrawTools;
      aux.isDrawing = true;
      if (this.tool.codTool === `${TOOLS_ENUM.TREND_LINES}_LINHA_CRUZADA`) {
        aux.x2 = this.x1;
      } else {
        aux.x2 = this.x1;
        aux.y2 = this.middleYPointValues.y;
      }
    }
    if (this.labelValue === '' && !this.firstFormatter) {
      this.labelValue = this.axisService.xAxisLabelFormatter(
        this.configs,
        this.locale,
        this.tool.xCategoryValue!!,
        false
      );
      this.firstFormatter = true;
    } else {
      this.labelValue = '';
    }
  }

  customClick = () => {
    //do nothing.
  };

  repositionZoomDraw(): void {
    //do nothing.
  }

  customHover = () => {
    //do nothing.
  };

  updateStyleTool(style: StyleTool) {
    let childrenDraw!: TIGER_CHART_TOOL_CHILD;
    if (this.isChildren && this.tool.children) {
      childrenDraw = this.tool.children.find(
        (child) => child.dictIdChildren === this.dictIdChildren
      )!!;
    }
    if (style.color) {
      this.stroke = style.color.rgbaHexColor;
      this.axisLabelFill = style.color.rgbaHexColor;
      if (childrenDraw) {
        childrenDraw.color = style.color.rgbaHexColor;
      } else {
        this.tool.color = style.color.rgbaHexColor;
      }
    }
    if (style.strokeThickness) {
      this.strokeThickness = style.strokeThickness;
      if (childrenDraw) {
        childrenDraw.lineThickness = style.strokeThickness;
      } else {
        this.tool.lineThickness = style.strokeThickness;
      }
    }
    if (style.strokeDashArray) {
      this.strokeDashArray = style.strokeDashArray;
      if (childrenDraw) {
        childrenDraw.strokeDashArray = style.strokeDashArray;
      } else {
        this.tool.strokeDashArray = style.strokeDashArray;
      }
    }
  }

  updateTool(tool: TIGER_CHART_TOOL) {
    this.tool = tool;
  }

  resizeSVG(
    typeResize: TYPE_RESIZE,
    childId: string,
    svgPosition?: { x1: number; x2: number; y1: number; y2: number },
    position?: { x1: number; x2: number; y1: number; y2: number }
  ) {
    if (!position || !this.tool || !this.tool.mouseValues) {
      return;
    }
    const middleY =
      parseFloat(this.parentSurface.domSvgContainer.getAttribute('height')!!) /
      2;
    const rSeries = this.parentSurface?.renderableSeries.getById(
      CANDLE_IDS.MAIN_SERIE
    );
    const hitTestMiddleYInfo = rSeries.hitTestProvider.hitTestDataPoint(
      this.tool.mouseValues.x,
      middleY
    );
    this.middleYPointValues = new Point(
      this.middleYPointValues.x,
      hitTestMiddleYInfo.hitTestPointValues.y
    );
    this.x1 = position.x2;

    if (
      this.tool.codTool === `${TOOLS_ENUM.TREND_LINES}_LINHA_VERTICAL` &&
      typeResize === TYPE_RESIZE.DRAG
    ) {
      this.updateAux();
    }
  }
}
