import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GraphicScreeningComponent } from './graphic-screening.component';
import {
  FlaIconModule,
  RocketButtonModule,
} from '@shared/rocket-components/components';
import { GraphicScreeningHeaderComponent } from './header/graphic-screening-header.component';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { GraphicScreeningService } from './graphic-screening.service';
import { GraphicComponent } from './graphic/graphic.component';

@NgModule({
  imports: [
    CommonModule,
    FlaWorkSpaceModule,
    RocketButtonModule,
    TooltipsModule,
    FlaIconModule,
  ],
  declarations: [
    GraphicScreeningComponent,
    GraphicScreeningHeaderComponent,
    GraphicComponent,
  ],
  exports: [GraphicScreeningComponent],
  providers: [GraphicScreeningService],
})
export class GraphicScreeningModule {}
