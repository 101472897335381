import { IMoversData } from '../interfaces/stock-table.interfaces';

export const STOCK_STATUS_BACKGROUND: any = {
  ATIVO: 'bg-feedback-success',
  CONGELADO: 'bg-neutral-medium',
  INATIVO: 'bg-feedback-error',
  LEILAO: 'bg-feedback-warning',
  SUSPENSO: 'bg-feedback-warning',
  INIBIDO: 'bg-feedback-error',
};

export const STOCK_STATUS_LABEL: any = {
  LEILAO: 'Em Leilão',
  SUSPENSO: 'Suspenso',
  CONGELADO: 'Congelado',
  ATIVO: 'Em negociação',
  INIBIDO: 'Inibido',
  INATIVO: 'Inativo',
};

export const UPDATE_LIST: any = {
  UPDATE_LIST: 'UPDATE_LIST',
  ADD_ROW_STOCKS: 'ADD_ROW_STOCKS',
  REMOVE_ROW: 'REMOVE_ROW',
  ROW_MOVED: 'ROW_MOVED',
};

export const UPDATE_CONFIG: any = {
  COLUMN_MOVED: 'COLUMN_MOVED',
  COLUMN_RESIZED: 'COLUMN_RESIZED',
  VISIBLE_OR_INVISIBLE: 'VISIBLE_OR_INVISIBLE',
};

export const DYNAMIC_LISTS: any = {
  AUCTION: { id: 1000000000, label: 'Leilão' },
  MOVERS_HIGH: { id: 1000000001, label: '> Altas' },
  MOVERS_LOW: { id: 1000000002, label: '> Baixas' },
  MOVERS_VOLUME: { id: 1000000003, label: 'Volume' },
};

export const isAuctionList = (value: number) =>
  value === DYNAMIC_LISTS.AUCTION.id;

export const isMoversListLoss = (value: number) =>
  value === DYNAMIC_LISTS.MOVERS_LOW.id;

export const isMoversListVolume = (value: number) =>
  value === DYNAMIC_LISTS.MOVERS_VOLUME.id;

export const isMoversList = (value: number) =>
  [1000000001, 1000000002, 1000000003].includes(value);

export const MOVERS_TYPE: any = {
  1000000001: 'ALTA',
  1000000002: 'BAIXA',
  1000000003: 'VOLUME',
};

export const DYNAMIC_LIST_BY_ID: any = {
  1000000000: { id: 1000000000, label: 'Leilão' },
  1000000001: { id: 1000000001, label: '> Altas' },
  1000000002: { id: 1000000002, label: '> Baixas' },
  1000000003: { id: 1000000003, label: 'Volume' },
};

export const isLowerOrHighMoversList = (value: number) =>
  value === DYNAMIC_LISTS.MOVERS_HIGH.id ||
  value === DYNAMIC_LISTS.MOVERS_LOW.id;

export const SORT_MOVERS_ROWS = (
  stockList: number,
  stocks: IMoversData[],
  variationField: string = 'variacao'
): IMoversData[] => {
  if (isMoversListLoss(stockList)) {
    return stocks.sort((stock1: any, stock2: any) => {
      if (+stock1[variationField] < +stock2[variationField]) return -1;
      if (+stock1[variationField] > +stock2[variationField]) return 1;
      return 0;
    });
  }
  if (isMoversListVolume(stockList)) {
    return stocks.sort((stock1: any, stock2: any) => {
      return parseFloat(stock2.volume) - parseFloat(stock1.volume);
    });
  }
  return stocks.sort((stock1: any, stock2: any) => {
    if (parseFloat(stock1[variationField]) > parseFloat(stock2[variationField]))
      return -1;
    if (parseFloat(stock1[variationField]) < parseFloat(stock2[variationField]))
      return 1;
    return 0;
  });
};

export enum STOCK_TABLE_ELEMENT_IDS {
  STOCK_TABLE_CHANGE_VIEW = 'STOCK_TABLE_CHANGE_VIEW',
  STOCK_TABLE_TABLE_VIEW = 'STOCK_TABLE_TABLE_VIEW',
  STOCK_TABLE_LISTS = 'STOCK_TABLE_LISTS',
}

export enum NEW_STOCK_MODAL_TABS {
  MOST_SEARCHED = '+ Buscados',
  STOCKS = 'Ações',
  FUTURES = 'Futuros',
  COINS = 'Moedas',
  FEES = 'Juros',
  COMMODITIES = 'Commodities',
  FIIS = 'FIIs',
  INDEXES = 'Índices',
  ETFS = 'ETFs',
  BDRS = 'BDRs',
  BONDS = 'Bonds',
}

export const PRESET_LIST_ID = 999999999999;

export const isPresetListID = (id: number) => id === PRESET_LIST_ID;
