import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBroker } from '@core/interface';
import { Dictionary } from '@core/models';
import { system } from '@core/system/system.service';
import { RestService } from '@shared/services/rest/rest.service';
import { catchError, map, Observable, of, Subject, tap } from 'rxjs';
import {
  IRocketEnabledBroker,
  IRocketEnabledBrokersLists,
} from './interface/brokerResponse';
import {
  IBrokerActivity,
  IBrokerage,
  IBrokerageTop,
  IBrokerageTopServiceResponse,
} from '@shared/components/ranking-broker/interface/ranking-broker.interface';
import { BROKERS_TO_IGNORE } from '@shared/components/ranking-broker/consts/ranking-broker-part.constant';
import { COLORS_BROKER } from '@shared/components/ranking-broker/consts/colors';
import { RANKING_BROKER_FILTERS } from '@shared/components/ranking-broker/enum/ranking-broker.enum';

@Injectable({
  providedIn: 'root',
})
export class BrokerService extends RestService {
  override _url: string = 'api/trademap';
  private _updatedConnectedBrokers = new Subject<void>();

  get onUpdateConnectedBrokers$(): Observable<void> {
    return this._updatedConnectedBrokers.asObservable();
  }

  constructor(http: HttpClient) {
    super(http);
  }

  processBrokers = () =>
    this.getBrokers().pipe(
      map((res) => {
        return res.data?.result.reduce((map: any, item: IBroker) => {
          item.label = item.nm_broker;
          map.set(item.id_broker, item);
          return map;
        }, new Dictionary<IBroker>());
      }),
      tap((data: Dictionary<IBroker>) => {
        system.brokers = data.values();
        this._updatedConnectedBrokers.next();
      }),
      catchError((error) => of(error))
    );

  public getBrokers = (
    updateBrokersAfterConnection: boolean = false
  ): Observable<any> => {
    if (!updateBrokersAfterConnection && system && system.brokers)
      return of({ data: { result: system.brokers } });
    return this.post<any>('v7/broker/get-brokers', {}).pipe(
      catchError((error: any) => error)
    );
  };

  public getInvestorBrokerAccounts(idBroker: number): Observable<any> {
    return this.post<any>('v7/broker/get-investor-broker-accounts', {
      idBroker,
    }).pipe(
      map((res) => res.data?.result),
      catchError((error: any) => error)
    );
  }

  public getAllMultiBrokers = (): Observable<any> => {
    return this.post<any>('v8/broker/get-all-multibrokers', {}).pipe(
      map((res) => res.data?.result),
      catchError((error: any) => error)
    );
  };

  public getAllBrokersGroupedByConnection = (): Observable<any> => {
    return this.post<any>(
      'v7/broker/getAllBrokersGroupedByConnection',
      {}
    ).pipe(
      map((res) => res.data?.result),
      catchError((error: any) => error)
    );
  };

  private getGroupBrokers(data: any, type: string): IRocketEnabledBroker[] {
    const { br_brokers, usa_brokers, crypto_brokers } = data;
    const info = [
      ...br_brokers[type].map((broker: IRocketEnabledBroker) => ({
        type: 'BR',
        ...broker,
      })),
      ...usa_brokers[type].map((broker: IRocketEnabledBroker) => ({
        type: 'USA',
        ...broker,
      })),
      ...crypto_brokers[type].map((broker: IRocketEnabledBroker) => ({
        type: 'CRYPTO',
        ...broker,
      })),
    ];
    return info;
  }

  public getAllBrokersGroupedByConnectionEnabledToRocket =
    (): Observable<IRocketEnabledBrokersLists> => {
      const urlBrokers = system.userAuthenticated.investor.isSessionWeak
        ? 'v9/rocket-weak/get-all-available-brokers'
        : 'v7/broker/getAllBrokersGroupedByConnectionRocketEnabled';
      return this.post<any>(urlBrokers, {}).pipe(
        map((res) => {
          const response: IRocketEnabledBrokersLists = {
            connected: [],
            avaliable: [],
            pending: [],
            brokers: res.data?.result,
          };

          if (res.data && res.data.result) {
            const result = res.data.result;
            response.connected = this.getGroupBrokers(result, 'connected');
            response.avaliable = this.getGroupBrokers(result, 'avaliable');
            response.pending = this.getGroupBrokers(result, 'pending');
          }
          return response;
        }),
        catchError(() =>
          of({
            connected: [],
            avaliable: [],
            pending: [],
          } as IRocketEnabledBrokersLists)
        )
      );
    };

  public loginMultibroker = (idBroker: number, args?: any): Observable<any> => {
    let urlLogin = 'v7/broker/login-multibroker';
    if (system.userAuthenticated.investor.isSessionWeak) {
      urlLogin =
        args && args.hasBrokerConnected
          ? 'v9/rocket-weak/multibroker-contract'
          : 'v9/rocket-weak/login-multibroker';
    }
    return this.post<any>(urlLogin, {
      args,
      idBroker,
      ip: 'no-ip',
    }).pipe(
      map((res) => {
        if (res.data && res.data?.success && res.data?.result) {
          return res.data;
        }
        return null;
      }),
      catchError((error: any) => of(error))
    );
  };

  public removeBrokerAccounts = (
    idBroker: number,
    args: any
  ): Observable<any> => {
    return this.post<any>('v7/broker/remove-broker-accounts', {
      args,
      idBroker,
      url: window.location.href,
      ip: 'no-ip',
    }).pipe(
      map((res) => res.data?.result),
      catchError((error: any) => of(error))
    );
  };

  public getBrokerageTop = (
    params: any,
    viewTypeSelected: any,
    isFuture: boolean = false
  ): Observable<IBrokerageTopServiceResponse> =>
    this.post<IBrokerageTop>('v9/brokerage/get-brokerage-top', params).pipe(
      map((result: any) => {
        if (
          !result ||
          !result.data.success ||
          !result.data.result ||
          !result.data.result?.result
        )
          return { brokers: [], selectedDict: null };
        return this._buildBrokerageInfos(
          result.data.result.result,
          viewTypeSelected,
          isFuture
        );
      }),
      catchError((err) => of(err))
    );

  private _buildBrokerageInfos(
    data: IBrokerage[],
    viewTypeSelected: any,
    isIndex: boolean = false
  ): IBrokerageTopServiceResponse {
    const brokers: IBrokerage[] = [];
    const selectedDict = new Dictionary<IBrokerage>();
    const totalBrokers = data.length;
    data.forEach(
      (broker) =>
        (broker.vl_negocios = broker.qtty_trades_buy + broker.qtty_trades_sell)
    );
    data.sort((itemA: any, itemB: any) => {
      if (itemA[viewTypeSelected] < itemB[viewTypeSelected]) {
        return 1;
      }
      if (itemA[viewTypeSelected] > itemB[viewTypeSelected]) {
        return -1;
      }
      return 0;
    });
    data.forEach((broker, index) => {
      if (BROKERS_TO_IGNORE.includes(broker.id_brokerage)) return;
      if (isIndex) {
        broker.vl_net = broker.qtty_shares_net;
        broker.vl_total = broker.qtty_shares_sell + broker.qtty_shares_buy;
        broker.vl_sell = broker.qtty_shares_sell;
        broker.vl_buuy = broker.qtty_shares_buy;
      }
      let isSelected = index < 6;
      if (RANKING_BROKER_FILTERS.VL_NET === viewTypeSelected) {
        isSelected = index < 3 || index > totalBrokers - 4;
      }

      broker.selected = isSelected;
      broker.color = COLORS_BROKER[index];
      isSelected && selectedDict.set(broker.id_brokerage, broker);
      brokers.push(broker);
    });
    return { brokers, selectedDict };
  }

  public getBrokerageActivity = (
    params: any,
    isIndex: boolean = false
  ): Observable<{ success: boolean; result: IBrokerActivity }> =>
    this.post<any>('v9/brokerage/get-brokerage-activity', params).pipe(
      map((res) => {
        if (res.data && res.data?.success && res.data?.result) {
          if (isIndex) {
            res.data.result?.brokerageActivityHead?.forEach((item: any) => {
              item.brokerageActivity.forEach((brokerageActivity: any) => {
                brokerageActivity.volume_net_sum =
                  brokerageActivity.quantity_net_sum;
                brokerageActivity.volume_net = brokerageActivity.quantity_net;
                brokerageActivity.volume_total_sum =
                  brokerageActivity.quantity_trade_sell_sum +
                  brokerageActivity.quantity_trade_buy_sum;
                brokerageActivity.volume_total =
                  brokerageActivity.quantity_trade_sell +
                  brokerageActivity.quantity_trade_buy;
                brokerageActivity.volume_buy_sum =
                  brokerageActivity.quantity_trade_buy_sum;
                brokerageActivity.volume_sell_sum =
                  brokerageActivity.quantity_trade_sell_sum;
              });
            });
          }
          return res.data;
        }
        return null;
      }),
      catchError((error: any) => of(error))
    );

  public getBrokerageDetail = (params: any): Observable<any> =>
    this.post<any>('v9/brokerage/get-brokerage-detail', params).pipe(
      map((res) => {
        if (res.data && res.data?.success && res.data?.result) {
          return res.data;
        }
        return null;
      }),
      catchError((error: any) => of(error))
    );
}
