<button
  [id]="refId"
  [type]="btnType"
  [class]="'btn ' + config[type] + ' ' + customClass"
  [ngClass]="{
    dot: loading,
    'btn-sm': isSmall,
    'd-flex pr-2': type === 'textIcon'
  }"
  [disabled]="isDisabled"
  (click)="execClick($event)"
  [tabindex]="tabIndex"
>
  <ng-content select="fla-icon" *ngIf="!loading"></ng-content>
  <ng-content select="img" *ngIf="!loading"></ng-content>

  <ng-container *ngIf="!loading && type !== 'icon'">
    <ng-content select="[texticon]"></ng-content>
    <span [ngClass]="responsive ? responsiveClass : ''">
      {{ buttonText }}
    </span>
  </ng-container>
</button>
