import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ChartService } from '@shared/services/api/trademap/V3/chart.service';
import { IStockChartInfos } from '../stock-table-views';
import { IndexChange } from './types';

@Injectable({
  providedIn: 'root',
})
export class MarketSummaryService {
  selectedIndex$: Subject<IndexChange> = new Subject();

  private _closeLimitObservable: BehaviorSubject<IStockChartInfos | null> =
    new BehaviorSubject<IStockChartInfos | null>(null);

  get stocksCloseLimit$(): Observable<IStockChartInfos | null> {
    return this._closeLimitObservable.asObservable();
  }

  constructor(private _chart: ChartService) {}

  public chartVlClose(cdStocks: string[], refComponent: string): void {
    this._chart.vlCloseWithSynonymous(cdStocks).subscribe({
      next: (params) => {
        this._closeLimitObservable.next({
          data: params.close_limit,
          isError: false,
          message: '',
          refComponent,
        });
      },
      error: (error) => {
        this._closeLimitObservable.next({
          data: null,
          isError: true,
          message: error.message,
          refComponent,
        });
      },
    });
  }
}
