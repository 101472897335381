<workspace-doc-footer css="height-28" *ngIf="configs">
  <div class="h-100 w-100 px-3">
    <div class="d-flex h-100 align-items-center">
      <div class="d-flex justify-content-start w-100 ml-n2">
        <app-logo [height]="30" [width]="133"></app-logo>
      </div>
      <div
        class="d-flex justify-content-end align-items-center w-100 h-100 gap-2"
      >
        <div
          class="h-100"
          *ngIf="displayFastRuler"
          [tooltip]="fastRulerTooltip"
          [tooltipIsAdaptive]="false"
        >
          <button
            class="p-0 btn-svg log-button btn btn-icon btn-sm btn-sized"
            [ngClass]="{
              'text-brand-primary': fastRuler,
              'text-muted': !fastRuler
            }"
            [innerHtml]="rulerSvg | safeHtml"
            (click)="clickChartConfig(tFooterType.RULER, !fastRuler)"
          ></button>
        </div>
        <div
          class="border-right pr-1 h-100"
          [tooltip]="zoomInTooltip"
          [tooltipIsAdaptive]="false"
        >
          <button
            class="p-0 btn-svg log-button btn btn-icon btn-sm btn-sized"
            [ngClass]="{
              'text-brand-primary': zoomIn,
              'text-muted': !zoomIn
            }"
            [innerHtml]="zoomInSvg | safeHtml"
            (click)="click(tFooterType.ZOOM_IN)"
          ></button>
        </div>
        <section
          [id]="elementIDS.STOCK_TRADE_EVENTS_AND_VOLUME"
          class="d-inline-flex gap-2"
        >
          <div
            class="pr-1 h-100"
            [tooltip]="volumeTooltip"
            [tooltipIsAdaptive]="false"
          >
            <rocket-button
              label=""
              [css]="
                'log-button p-0 ' +
                (volume ? 'text-brand-primary' : 'text-muted')
              "
              type="icon"
              (rocketClick)="click(tFooterType.VOLUME)"
            >
              <img
                class="img-size h-100"
                [src]="
                  !volume
                    ? 'assets/svg/volum.svg'
                    : 'assets/svg/volum-active.svg'
                "
              />
            </rocket-button>
          </div>
          <div
            class="pr-1 h-100"
            [tooltip]="eventsTooltip"
            [tooltipIsAdaptive]="false"
          >
            <rocket-button
              label=""
              [css]="
                'log-button p-0 ' +
                (events ? 'text-brand-primary' : 'text-muted')
              "
              type="icon"
              (rocketClick)="click(tFooterType.EVENTS)"
            >
              <img
                class="img-size h-100"
                [src]="
                  !events
                    ? 'assets/svg/events.svg'
                    : 'assets/svg/events-active.svg'
                "
              />
            </rocket-button>
          </div>
          <div
            class="pr-1 h-100"
            [tooltip]="execOrdersTooltip"
            [tooltipIsAdaptive]="false"
          >
            <rocket-button
              label=""
              [css]="
                'log-button p-0 ' +
                (execOrders ? 'text-brand-primary' : 'text-muted')
              "
              type="icon"
              (rocketClick)="clickChartConfig(tFooterType.EXEC_ORDERS, !execOrders)"
            >
              <img
                class="img-size h-100"
                [src]="
                  !execOrders
                    ? 'assets/svg/orders-executed.svg'
                    : 'assets/svg/orders-executed-active.svg'
                "
              />
            </rocket-button>
          </div>
          <div
            class="pr-1 h-100"
            [tooltip]="previousCloseTooltip"
            [tooltipIsAdaptive]="false"
          >
            <rocket-button
              label=""
              [css]="
                'log-button p-0 ' +
                (previousClose ? 'text-brand-primary' : 'text-muted')
              "
              type="icon"
              (rocketClick)="click(tFooterType.PREVIOUS_CLOSE)"
            >
              <img
                class="img-size h-100"
                [src]="
                  !previousClose
                    ? 'assets/svg/previous-close.svg'
                    : 'assets/svg/previous-close-active.svg'
                "
              />
            </rocket-button>
          </div>
          <div
            class="pr-1 h-100"
            [tooltip]="bidAskTooltip"
            [tooltipIsAdaptive]="false"
          >
            <rocket-button
              label=""
              [css]="
                'log-button p-0 ' +
                (bidAsk ? 'text-brand-primary' : 'text-muted')
              "
              type="icon"
              (rocketClick)="click(tFooterType.SHOW_BID_ASK)"
            >
              <img
                class="img-size h-100"
                [src]="
                  !bidAsk
                    ? 'assets/svg/bidask.svg'
                    : 'assets/svg/bidask-active.svg'
                "
              />
            </rocket-button>
          </div>
          <div
            class="border-right pr-2 h-100"
            [tooltip]="fastOrderTooltip"
            [tooltipIsAdaptive]="false"
          >
            <rocket-button
              label=""
              [css]="
                'log-button p-0 ' +
                (fastOrder ? 'text-brand-primary' : 'text-muted')
              "
              type="icon"
              (rocketClick)="click(tFooterType.FAST_ORDER)"
            >
              <img
                class="img-size h-100"
                [src]="
                  !fastOrder
                    ? 'assets/svg/boleta.svg'
                    : 'assets/svg/boleta-active.svg'
                "
              />
            </rocket-button>
          </div>
        </section>

        <section
          [id]="elementIDS.FOOTER_BOOK_AND_VOLUME_X_PRICE"
          class="d-inline-flex gap-1 h-100"
        >
          <div
            *ngIf="showBookButton"
            class="pr-1 h-100"
            [tooltip]="chartVolumeAtPriceTooltip"
            [tooltipIsAdaptive]="false"
          >
            <rocket-button
              label=""
              [css]="
                'log-button p-0 ' +
                (chartBook ? 'text-brand-primary' : 'text-muted')
              "
              type="icon"
              (rocketClick)="click(tFooterType.CHART_VOLUME_AT_PRICE)"
            >
              <img
                class="img-size h-100"
                [src]="
                  !chartVolumeAtPrice
                    ? 'assets/svg/volum-at-price.svg'
                    : 'assets/svg/volum-at-price-active.svg'
                "
              />
            </rocket-button>
          </div>
          <div
            *ngIf="showBookButton"
            [tooltip]="chartBookTooltip"
            [tooltipIsAdaptive]="false"
            class="border-right pr-2 h-100"
          >
            <rocket-button
              label=""
              [css]="
                'log-button p-0 ' +
                (chartBook ? 'text-brand-primary' : 'text-muted')
              "
              type="icon"
              (rocketClick)="click(tFooterType.CHART_BOOK)"
            >
              <img
                class="img-size h-100"
                [src]="
                  !chartBook
                    ? 'assets/svg/book.svg'
                    : 'assets/svg/book-active.svg'
                "
              />
            </rocket-button>
          </div>
        </section>
        <section class="d-inline-flex gap-2">
          <div
            class="h-100"
            [tooltip]="freeScaleTooltip"
            [tooltipIsAdaptive]="false"
          >
            <rocket-button
              label="AUTO"
              [css]="
                'log-button p-0 ' +
                (!freeScale ? 'text-brand-primary' : 'text-muted')
              "
              type="fill"
              (rocketClick)="click(tFooterType.SCALE)"
            >
            </rocket-button>
          </div>
          <div
            class="h-100"
            [tooltip]="isLogTooltip"
            [tooltipIsAdaptive]="false"
          >
            <rocket-button
              label="LOG"
              [css]="
                'log-button p-0 ' + (log ? 'text-brand-primary' : 'text-muted')
              "
              type="fill"
              (rocketClick)="click(tFooterType.LOG)"
            ></rocket-button>
          </div>
        </section>
      </div>
    </div>
  </div>
</workspace-doc-footer>
