import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  ROCKET_MODAL_DATA,
  RocketButtonModule,
  RocketModalModule,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { StandaloneModalsService } from '../standalone-modals.service';
import { SuperSearchService } from '@modules/home/super-search.service';

interface IWsInfo {
  code: string;
  description: string;
  imagePath: string;
  components: string[];
}

@Component({
  selector: 'app-modal-reset-workspace',
  templateUrl: './modal-reset-workspace.component.html',
  styles: [
    `
      :host {
        .btn:hover,
        .btn:focus {
          background-color: transparent !important;
        }

        .ws-names-width {
          min-width: 140px;
          max-width: 140px;
        }

        .modal-content-height {
          height: 400px;
        }
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, RocketModalModule, RocketButtonModule],
})
export class ModalResetWorkspaceComponent
  extends RocketModalRef
  implements OnInit, OnDestroy
{
  private _workspaces: any = {
    PARA_COMECAR: {
      code: 'PARA_COMECAR',
      description:
        'A Workspace <b>Para começar</b> conta com as principais ferramentas para você aproveitar nossa interface intuitiva.',
      imagePath: '../../../../assets/workspaces/ws_para_comecar.png',
      components: [
        'Lista de ativos',
        'Gráfico',
        'Book de ofertas',
        'Ordens e posições',
      ],
    },
    MERCADO: {
      code: 'MERCADO',
      description:
        'A Workspace <b>Mercado</b> é ideal para acompanhar em tempo real como está o cenário do mercado financeiro.',
      imagePath: '../../../../assets/workspaces/ws_mercado.png',
      components: [
        'Maiores altas e Maiores baixas',
        'Mapa de calor (Setores)',
        'Gráfico',
        'Notícias',
      ],
    },
    PRO: {
      code: 'PRO',
      description:
        'A Workspace <b>PRO</b> é ideal para quem busca excelência na análise e execução com as melhores ferramentas.',
      imagePath: '../../../../assets/workspaces/ws_pro.png',
      components: [
        'Lista de ativos',
        '2 gráficos',
        'Volume x Preço',
        'SuperDOM',
      ],
    },
    RAIO_X: {
      code: 'RAIO_X',
      description:
        'A Workspace <b>Raio X</b> é ideal para quem quer saber no detalhe todas as informações das empresas.',
      imagePath: '../../../../assets/workspaces/ws_raio_x.png',
      components: ['Lista de ativos', 'Gráfico', 'Raio X'],
    },
    ANALISE_ATIVOS: {
      code: 'ANALISE_ATIVOS',
      description:
        'A Workspace <b>Análise de Ativos</b> é ideal para quem quer acompanhar o mercado e fazer comparações entre os ativos.',
      imagePath: '../../../../assets/workspaces/ws_analise_ativos.png',
      components: ['Lista de ativos', 'Mapa de mercado', 'Análise de Ativos'],
    },
    ANALISE_FLUXO: {
      code: 'ANALISE_FLUXO',
      description:
        'A Workspace <b>Análise de Fluxo</b> é ideal para quem quer acompanhar o fluxo do mercado e ver a sua tendência.',
      imagePath: '../../../../assets/workspaces/ws_analise_fluxo.png',
      components: [
        'Flash de mercado',
        'Aluguel',
        'Fluxo de investidores',
        'Notícias',
      ],
    },
    OPCOES: {
      code: 'OPCOES',
      description:
        'A Workspace <b>Opções</b> é ideal para quem quer acompanhar o mercado de opções.',
      imagePath: '../../../../assets/workspaces/ws_opcoes.png',
      components: ['Lista de ativos', 'Opções', 'Notícias'],
    },
  };

  public disableClick: boolean = false;
  public wsInfos: IWsInfo = this._workspaces['PARA_COMECAR'];
  public workspaces = [
    {
      name: 'Para começar',
      code: 'PARA_COMECAR',
    },
    {
      name: 'Mercado',
      code: 'MERCADO',
    },
    {
      name: 'PRO',
      code: 'PRO',
    },
    {
      name: 'Raio X',
      code: 'RAIO_X',
    },
    {
      name: 'Análise de Ativos',
      code: 'ANALISE_ATIVOS',
    },
    {
      name: 'Análise de Fluxo',
      code: 'ANALISE_FLUXO',
    },
    {
      name: 'Opções',
      code: 'OPCOES',
    },
  ];

  constructor(
    service: RocketModalService,
    @Inject(ROCKET_MODAL_DATA)
    public data: any,
    private _standaloneModalService: StandaloneModalsService,
    private _superSearchService: SuperSearchService
  ) {
    super(service);
  }

  ngOnInit(): void {
    this._superSearchService.isScreenLocked = true;
  }

  override ngOnDestroy(): void {
    this._superSearchService.isScreenLocked = false;
  }

  public selectTemplate(ws: string): void {
    this.wsInfos = this._workspaces[ws];
  }

  public resetWorkspace(): void {
    this.disableClick = true;
    this._standaloneModalService.close(this.wsInfos.code);
  }
}
