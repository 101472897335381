import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RTListSelectorComponent } from './list-selector.component';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';

@NgModule({
  imports: [CommonModule, RTDropdownModule],
  declarations: [RTListSelectorComponent],
  exports: [RTListSelectorComponent],
})
export class RTListSelectorModule {}
