
import { Component, OnInit } from '@angular/core';
import { randomId } from '@shared/rocket-components/utils/functions';
import { isWebViewContext } from 'src/app/desktop/integration.utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector 
  selector: 'rocket-modal',
  templateUrl: './modal.component.html',
})
export class RocketModalComponent implements OnInit {
  public id: string = randomId('rocket_modal');

  ngOnInit() {
    if (isWebViewContext()) {
      document.body.style.background = 'none';
    }
  }
}