import { Routes, RouterModule } from '@angular/router';
import { ChangelogComponent } from './changelog.component';

const routes: Routes = [
  {
    path: '',
    component: ChangelogComponent,
  },
];

export const ChangelogRoutes = RouterModule.forChild(routes);
