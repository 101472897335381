<div
  [id]="elementsID.container"
  class="position-relative d-flex justify-content-center"
  (mouseenter)="handleDisplayAuctionByIndex(true)"
  (mouseleave)="handleDisplayAuctionByIndex(false)"
>
  <i
    [id]="elementsID.icon"
    [ngClass]="{ 'text-feedback-warning': enableAuctionIcon }"
    class="icons fs-4"
  >
    gavel
  </i>
  <div class="notification-header" *ngIf="indexSelected?.qtty">
    <app-notification-count
      [value]="indexSelected!.qtty"
    ></app-notification-count>
  </div>
  <ng-container *ngIf="displayAuctionByIndex">
    <div
      class="content position-absolute bg-neutral-stronger overflow-y-auto p-2 mt-3 round vstack"
      (mouseenter)="handleDisplayAuctionByIndex(true)"
      (mouseleave)="handleDisplayAuctionByIndex(false)"
    >
      <ng-container *ngIf="indexToDisplay; else allIndexes">
        <app-auction-stocks-by-index
          [indexSelected]="indexToDisplay!"
          [stocks]="stocksAtAuctionDict.values()"
          [isLoading]="isLoadingStocks"
          [globalCdStock]="globalCdStock"
          (showAllIndexes)="displayAvailableIndexes()"
          (closeContent)="handleDisplayAuctionByIndex(false)"
          (updateGlobalStock)="updateGlobalStock($event)"
        >
        </app-auction-stocks-by-index>
      </ng-container>
      <ng-template #allIndexes>
        <app-indexes-to-show-stocks-at-auction
          [indexSelected]="indexSelected"
          [indexes]="indexesDict.values()"
          (displayIndexStocks)="updateIndexToDisplay($event)"
          (updateIndexSelected)="onSelectIndex($event)"
        >
        </app-indexes-to-show-stocks-at-auction>
      </ng-template>
    </div>
  </ng-container>
</div>
