import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FlaIconModule,
  RocketButtonModule,
  RocketModalModule,
} from '@shared/rocket-components/components';
import { ModalTokenConfigModule } from '@core/layout/header/modal-token-config/modal-token-config.module';
import { MostSearchComponent } from './most-search.component';
import { StockCardModule } from '@shared/rocket-components/components/stock-card/stock-card.module';
import { LoadingModule } from '@shared/components/loading/loading.module';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  imports: [
    CommonModule,
    RocketButtonModule,
    RocketModalModule,
    ModalTokenConfigModule,
    StockCardModule,
    FlaIconModule,
    LoadingModule,
    ScrollingModule,
  ],
  declarations: [MostSearchComponent],
  exports: [MostSearchComponent],
})
export class MostSearchModule {}
