import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StockScreeningComponent } from './stock-screening.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  FlaIconModule,
  FlaInputModule,
  FlaRadiosModule,
  FlaSelectModule,
  RocketButtonModule,
  RocketModalModule,
} from '@shared/rocket-components/components';
import { StockScreeningService } from './stock-screening.service';
import { StockScreeningHeaderComponent } from './header/stock-screening-header.component';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { DynamicFormModule } from '@shared/rocket-components/dynamic-form/dynamic-form.module';
import { RTInputCountModule } from '@shared/rocket-components/input-count/input-count.module';
import { ScreeningFiltersComponent } from './screening-filters/screening-filters.component';
import { EditFilterComponent } from './screening-filters/edit-filter/edit-filter.component';
import { EditParamsConditionsModalComponent } from './screening-filters/edit-params-conditions-modal/edit-params-conditions-modal.component';
import { ScreeningListFiltersComponent } from './screening-list-filters/screening-list-filters.component';
import { SearchStockModule } from '../../search-stock/search-stock.module';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { FlaCollapseModule } from '@shared/rocket-components/directives/collapse/collapse.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlaInputModule,
    FlaSelectModule,
    FlaIconModule,
    RTInputCountModule,
    FlaWorkSpaceModule,
    DynamicFormModule,
    RocketButtonModule,
    RocketModalModule,
    FlaRadiosModule,
    SearchStockModule,
    RTDropdownModule,
    TooltipsModule,
    FlaCollapseModule,
  ],
  declarations: [
    StockScreeningComponent,
    ScreeningFiltersComponent,
    StockScreeningHeaderComponent,
    EditFilterComponent,
    EditParamsConditionsModalComponent,
    ScreeningListFiltersComponent,
  ],
  exports: [StockScreeningComponent],
  providers: [StockScreeningService],
})
export class StockScreeningModule {}
