import { ColDef } from 'ag-grid-community';
import {
  formatterNumber,
  getVolumeText,
  lowerCaseAndNormalizeString,
} from 'src/app/utils/utils.functions';
import { SelectAllHeaderGridComponent } from '../parts/select-all-header-grid/select-all-header-grid.component';

export const optionsSelect = (params: any) => {
  return `
    <div class="d-flex align-items-center justify-content-between h-100">
      <div class="d-flex m-0 pb-1">
        <input class="form-check-input" type="checkbox" value="${
          params.value
        }" id="flexCheckDefault" ${params.data.selected ? 'checked' : ''}>
      </div>
      ${
        params.data.selected
          ? '<div class="align-items-center d-flex fs-2 justify-content-end m-0 pb-1" style="color: ' +
            params.data.color +
            ';height: 24px !important;">•</div>'
          : ''
      }
    </div>
  `;
};

export const comparator = (
  valueA: any,
  valueB: any,
  nodeA: any,
  nodeB: any,
  isDescending: boolean
) => {
  const stringA = lowerCaseAndNormalizeString(valueA);
  const stringB = lowerCaseAndNormalizeString(valueB);
  const intA = parseInt(stringA.slice(1));
  const intB = parseInt(stringB.slice(1));

  if (stringA == stringB) return 0;

  if (stringA[0] != stringB[0]) {
    if (isDescending) return stringA[0] < stringB[0] ? -1 : 1;

    return stringA[0] > stringB[0] ? 1 : -1;
  }

  if (isDescending) return intA < intB ? -1 : 1;

  return intA > intB ? 1 : -1;
};

export const HEADERS_DEFAULT: Array<ColDef> = [
  {
    field: 'selected',
    minWidth: 42,
    maxWidth: 42,
    width: 42,
    hide: false,
    cellRenderer: (params: any) => optionsSelect(params),
    suppressSizeToFit: true,
    headerComponent: SelectAllHeaderGridComponent,
  },
  {
    headerName: 'Corretora',
    field: 'nm_brokerage_valemobi',
    headerTooltip: 'Corretora',
    minWidth: 32,
    maxWidth: 150,
    width: 74,
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    comparator: comparator,
  },
  {
    headerName: 'Vl. Liquido',
    field: 'vl_net',
    headerTooltip: 'Valor Liquido',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
  },
  {
    headerName: 'Vl. Bruto',
    field: 'vl_total',
    headerTooltip: 'Valor Bruto',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
  },
  {
    headerName: 'Negócios',
    field: 'vl_negocios',
    headerTooltip: 'Negócios',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) =>
      getVolumeText('pt-BR', params.value, {
        maximumFractionDigits: 3,
        minimumFractionDigits: 0,
      }),
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
  },
  {
    headerName: 'Vl. Compra',
    field: 'vl_buuy',
    headerTooltip: 'Valor Compra',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
  },
  {
    headerName: '% Compra',
    field: 'percentage_buy',
    headerTooltip: 'Percentual Compra',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) =>
      `${params.value.toFixed(2).replace('.', ',')}%`,
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
  },
  {
    headerName: 'Vl. Venda',
    field: 'vl_sell',
    headerTooltip: 'Valor Venda',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
  },
  {
    headerName: '% Venda',
    field: 'percentage_sell',
    headerTooltip: 'Percentual Venda',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) =>
      `${params.value.toFixed(2).replace('.', ',')}%`,
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
  },
  {
    headerName: 'Qtd. de Trades Compra',
    field: 'qtty_trades_buy',
    minWidth: 32,
    width: 90,
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    hide: true,
  },
  {
    headerName: 'Qtd. de Trades Venda',
    field: 'qtty_trades_sell',
    minWidth: 32,
    width: 90,
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    hide: true,
  },
  {
    headerName: 'Médio de Compra',
    field: 'vl_avg_price_buy',
    minWidth: 32,
    width: 90,
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    hide: true,
  },
  {
    headerName: 'Médio de Venda',
    field: 'vl_avg_price_sell',
    minWidth: 32,
    width: 90,
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    hide: true,
  },
  {
    headerName: 'Vl. Compra',
    field: 'vl_buuy',
    minWidth: 32,
    width: 90,
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    hide: true,
  },
  {
    headerName: 'P&L',
    field: 'vl_profit',
    minWidth: 32,
    width: 90,
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    hide: true,
  },
  {
    headerName: 'Vl. Venda',
    field: 'vl_sell',
    minWidth: 32,
    width: 90,
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    hide: true,
  },
];

export const HEADERS_STOCKS: Array<ColDef> = [
  {
    headerName: 'Ativo',
    field: 'cd_stock',
    headerTooltip: 'Ativo',
    minWidth: 32,
    width: 150,
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    hide: false,
  },
  {
    headerName: 'Qtd. Compra',
    field: 'qtty_shares_buy',
    headerTooltip: 'Qtd. Compra',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    hide: false,
  },
  {
    headerName: 'Médio Compra',
    field: 'vl_avg_price_buy',
    headerTooltip: 'Médio Compra',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    hide: false,
  },
  {
    headerName: 'Vl. Compra',
    field: 'vl_buuy',
    headerTooltip: 'Volume Compra',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    hide: false,
  },
  {
    headerName: 'Qtd. Venda',
    field: 'qtty_shares_sell',
    headerTooltip: 'Qtd. Venda',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    hide: false,
  },
  {
    headerName: 'Médio Venda',
    field: 'vl_avg_price_sell',
    headerTooltip: 'Médio Venda',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    hide: false,
  },
  {
    headerName: 'Vl. Venda',
    field: 'vl_sell',
    headerTooltip: 'Volume Venda',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    hide: false,
  },
  {
    headerName: 'P&L',
    field: 'vl_profit',
    headerTooltip: 'P&L',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
    hide: false,
  },
];

export const HEADERS_BROKER: Array<ColDef> = [
  {
    headerName: '',
    field: 'nm_brokerage_valemobi',
    headerTooltip: '',
    minWidth: 32,
    maxWidth: 32,
    width: 32,
    sortingOrder: ['asc', 'desc', null],
    sortable: false,
    cellRenderer: () => {
      return `<i class="icons icon-size-micro ml-1 cursor-pointer">open_in_new</i>`;
    },
  },
  {
    headerName: 'Corretora',
    field: 'nm_brokerage_valemobi',
    headerTooltip: 'Corretora',
    minWidth: 32,
    maxWidth: 150,
    width: 74,
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
  },
  {
    headerName: 'Vl. Liquido',
    field: 'vl_net',
    headerTooltip: 'Valor Liquido',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
  },
  {
    headerName: 'Vl. Bruto',
    field: 'vl_total',
    headerTooltip: 'Valor Bruto',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
  },
  {
    headerName: 'Negócios',
    field: 'vl_negocios',
    headerTooltip: 'Negócios',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) =>
      getVolumeText('pt-BR', params.value, {
        maximumFractionDigits: 3,
        minimumFractionDigits: 0,
      }),
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
  },
  {
    headerName: 'Vl. Compra',
    field: 'vl_buuy',
    headerTooltip: 'Valor Compra',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
  },
  {
    headerName: '% Compra',
    field: 'percentage_buy',
    headerTooltip: 'Percentual Compra',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) =>
      `${params.value.toFixed(2).replace('.', ',')}%`,
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
  },
  {
    headerName: 'Vl. Venda',
    field: 'vl_sell',
    headerTooltip: 'Valor Venda',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) => getVolumeText('pt-BR', params.value),
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
  },
  {
    headerName: '% Venda',
    field: 'percentage_sell',
    headerTooltip: 'Percentual Venda',
    minWidth: 32,
    width: 90,
    cellClass: ['text-right'],
    valueFormatter: (params: any) =>
      `${params.value.toFixed(2).replace('.', ',')}%`,
    sortingOrder: ['asc', 'desc', null],
    sortable: true,
  },
];

export const HEADERS: any = {
  id_brokerage: {
    hide: false,
    width: 60,
  },
  nm_brokerage_valemobi: {
    hide: false,
    width: 74,
  },
  percentage_buy: {
    hide: false,
    width: 60,
  },
  percentage_sell: {
    hide: false,
    width: 60,
  },
  qtty_shares_buy: {
    hide: false,
    width: 60,
  },
  qtty_shares_net: {
    hide: false,
    width: 60,
  },
  qtty_shares_sell: {
    hide: false,
    width: 60,
  },
  qtty_trades_buy: {
    hide: false,
    width: 60,
  },
  qtty_trades_sell: {
    hide: false,
    width: 60,
  },
  vl_avg_price_buy: {
    hide: false,
    width: 60,
  },
  vl_avg_price_sell: {
    hide: false,
    width: 60,
  },
  vl_buuy: {
    hide: false,
    width: 60,
  },
  vl_net: {
    hide: false,
    width: 60,
  },
  vl_profit: {
    hide: false,
    width: 60,
  },
  vl_sell: {
    hide: false,
    width: 60,
  },
  vl_total: {
    hide: false,
    width: 60,
  },
  cd_stock: {
    hide: false,
    width: 70,
  },
  preco_ultimo: {
    hide: false,
    width: 60,
  },
  selected: {
    hide: false,
    width: 60,
  },
};

export const HEADERS_CONFIG_STOCKS: any = {
  cd_stock: {
    hide: false,
    width: 60,
  },
  qtty_shares_buy: {
    hide: false,
    width: 60,
  },
  vl_avg_price_buy: {
    hide: false,
    width: 60,
  },
  vl_buuy: {
    hide: false,
    width: 60,
  },
  qtty_shares_sell: {
    hide: false,
    width: 60,
  },
  vl_avg_price_sell: {
    hide: false,
    width: 60,
  },
  vl_sell: {
    hide: false,
    width: 60,
  },
  vl_profit: {
    hide: false,
    width: 60,
  },
};

export const CONFIG_HEADERS: Array<ColDef> = [];

export const formatedPrice = (price: string): string => {
  const options: Intl.NumberFormatOptions = {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  };
  return formatterNumber(+price, options);
};
