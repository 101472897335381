export enum INDICATORS_TABS {
  INDICATORS = 'INDICATORS',
  BALANCE = 'BALANCE',
  DRE = 'DRE',
  CASH_FLOW = 'CASH_FLOW',
}

export enum INDICATORS_COMPANY_TYPE {
  CONSOLIDATED = 'CONSOLIDATED',
  INDIVIDUAL = 'CONTROLLER',
}

export enum INDICATOR_PERIOD {
  THREE_MONTHS = 'QUARTER',
  ANNUAL = 'ANNUAL',
  EXERCISE = 'EXERCISE',
}
