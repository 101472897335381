<td>{{ tradeData.hrTrade }}</td>
<td class="truncate-nm-brokerage">{{ tradeData.mnBrokerageBuy }}</td>
<td [ngClass]="tradeData.classPrice">{{ tradeData.price }}</td>
<td [ngClass]="tradeData.classQuantity" [ngStyle]="styleBasedOnValue()">
  {{ tradeData.qtty }}
</td>
<td class="truncate-nm-brokerage">{{ tradeData.mnBrokerageSell }}</td>
<td [ngClass]="tradeData.classAgressorSide">
  {{ tradeData.agressorSideValue || '-' }}
</td>
