import { TIGER_INDICATORS_ENUM } from '../enum';
import { adxTemplate } from './templates-knowhow/template-adx';
import { averagePriceTemplate } from './templates-knowhow/template-average-price';
import { averageTrueRangeTemplate } from './templates-knowhow/template-average-true-range';
import { bollingerBandsTemplate } from './templates-knowhow/template-bollinger-bands';
import { dojiTemplate } from './templates-knowhow/template-doji';
import { doubleExponentialMovingAverageTemplate } from './templates-knowhow/template-double-exponential-moving-average';
import { exponentialMovingAverageTemplate } from './templates-knowhow/template-exponential-moving-average';
import { hammerTemplate } from './templates-knowhow/template-hammer';
import { invertedHammerTemplate } from './templates-knowhow/template-inverted-hammer';
import { kaufmanMovingAverageTemplate } from './templates-knowhow/template-kaufman-moving-average';
import { macdTemplate } from './templates-knowhow/template-macd';
import { medianPriceTemplate } from './templates-knowhow/template-median-price';
import { momentumTemplate } from './templates-knowhow/template-momentum';
import { movingAverageTemplate } from './templates-knowhow/template-moving-average';
import { parabolicSarTemplate } from './templates-knowhow/template-parabolic-sar';
import { percentagePriceOscillatorTemplate } from './templates-knowhow/template-percentage-price-oscillator';
import { relativeStrengthIndexTemplate } from './templates-knowhow/template-relative-strength-index';
import { triangularMovingAverageTemplate } from './templates-knowhow/template-triangular-moving-average';
import { tripleExponentialMovingAverageTemplate } from './templates-knowhow/template-triple-exponential-moving-average';
import { typicalPriceTemplate } from './templates-knowhow/template-typical-price';
import { weightedMovingAverageTemplate } from './templates-knowhow/template-weighted-moving-average';
import { weisWaveTemplate } from './templates-knowhow/template-weis-wave';

export const INDICATORS_EXPLICATION: any = {
  [TIGER_INDICATORS_ENUM.MOVING_AVERAGE]: {
    haveKnowMore: true,
    text: 'Provavelmente um dos indicadores mais utilizados. O indicador calcula a média dos dados entre a cotação atual e um período anterior.',
    html: movingAverageTemplate,
    video: 'https://www.youtube.com/embed/1XG4PGz9y2k',
    videoId: '1XG4PGz9y2k',
  },
  [TIGER_INDICATORS_ENUM.EXPONENTIAL_MOVING_AVERAGE]: {
    haveKnowMore: true,
    text: 'Com base na Média Móvel (MA), a EMA dá mais importância aos preços recentes, mesmo que a taxa de queda entre o preço atual e o preço anterior seja inconsistente.',
    html: exponentialMovingAverageTemplate,
    video: 'https://www.youtube.com/embed/xCHe-dW1vfQ',
    videoId: 'xCHe-dW1vfQ',
  },
  [TIGER_INDICATORS_ENUM.WEIGHTED_MOVING_AVERAGE]: {
    haveKnowMore: true,
    text: 'WMA (Weighted Moving Average) é uma média móvel ponderada que dá peso maior aos preços mais recentes e peso menor aos preços mais antigos.',
    html: weightedMovingAverageTemplate,
    video: 'https://www.youtube.com/embed/cG1O__bimio',
    videoId: 'cG1O__bimio',
  },
  [TIGER_INDICATORS_ENUM.BOLLINGER_BANDS]: {
    haveKnowMore: true,
    text: 'As bandas de Bollinger são um indicador de volatilidade que cria uma banda de três linhas, desenhadas em relação ao preço de um ativo. A linha média é geralmente uma média móvel simples de 20 dias. As bandas superiores e inferiores são geralmente dois desvios padrão acima e abaixo da linha média.',
    html: bollingerBandsTemplate,
    video: 'https://www.youtube.com/embed/MU0tRy28hRQ',
    videoId: 'MU0tRy28hRQ',
  },
  [TIGER_INDICATORS_ENUM.MEDIAN_PRICE]: {
    haveKnowMore: true,
    text: 'O indicador Median Price é uma ferramenta que pode ser usada para medir a direção e a volatilidade do mercado. Ele forma um canal com base em como o ATR se desvia do meio do intervalo. O indicador exibe o valor mediano da média entre alto e baixo para o comprimento especificado.',
    html: medianPriceTemplate,
    video: 'https://www.youtube.com/embed/4ZzqJED5jr4',
    videoId: '4ZzqJED5jr4',
  },
  [TIGER_INDICATORS_ENUM.RELATIVE_STRENGTH_INDEX]: {
    haveKnowMore: true,
    text: 'O Índice de Força Relativa (RSI) é um oscilador baseado em impulso bem versado que é usado para medir a rapidez (velocidade), bem como a mudança (magnitude) dos movimentos de preços direcionais.',
    html: relativeStrengthIndexTemplate,
    video: 'https://www.youtube.com/embed/AQk817llVkI',
    videoId: 'AQk817llVkI',
  },
  [TIGER_INDICATORS_ENUM.STOCHASTIC]: {
    haveKnowMore: false,
    text: 'O estocástico leva em consideração as máximas e as mínimas em relação ao preço atual da ação para achar pontos prováveis de mudança de tendência. Ele é composto por duas linhas principais %K (conhecido como indicador estocástico rápido) dá a referência de preço do ativo relacionado as máximas e mínimas de um período e o %D (considerado o estocástico lento) é a média móvel de 3 períodos de %k.',
    html: '',
    video: null,
    videoId: null,
  },
  [TIGER_INDICATORS_ENUM.PARABOLIC_SAR]: {
    haveKnowMore: true,
    text: 'O SAR Parabólico (SAR) é uma ferramenta de preço e tempo usada principalmente para identificar pontos de possíveis paradas e reversões. A palavra "SAR" significa "Stop and Reverse (Parada e Reversão)". Os cálculos do indicador criam uma parábola que está localizada abaixo do preço durante uma tendência de alta e acima do preço durante uma tendência de baixa.',
    html: parabolicSarTemplate,
    video: 'https://www.youtube.com/embed/W4IysoeoNG4',
    videoId: 'W4IysoeoNG4',
  },
  [TIGER_INDICATORS_ENUM.CDL_HAMMER]: {
    haveKnowMore: true,
    text: 'O Martelo é um padrão de alta que sinaliza a reversão de um movimento de baixa do preço para um movimento de alta.',
    html: hammerTemplate,
    video: 'https://www.youtube.com/embed/NWaiDQ6akZk',
    videoId: 'NWaiDQ6akZk',
    max: 1,
  },
  [TIGER_INDICATORS_ENUM.CDL_INVERTED_HAMMER]: {
    haveKnowMore: true,
    text: 'Ele ocorre sempre em uma tendência de baixa e sinaliza uma possível reversão para uma tendência de alta, seguindo alguns dos princípios básicos da Teoria de Dow.',
    html: invertedHammerTemplate,
    video: 'https://www.youtube.com/embed/mmpn9jhGVBg',
    videoId: 'mmpn9jhGVBg',
    max: 1,
  },
  [TIGER_INDICATORS_ENUM.AVERAGE_PRICE]: {
    haveKnowMore: true,
    text: 'Esse indicador é o preço médio de um ativo observado durante um período de tempo. É calculado encontrando a média aritmética simples dos preços de fechamento durante um período de tempo especificado.',
    html: averagePriceTemplate,
    video: 'https://www.youtube.com/embed/NlXUehtuAJ4',
    videoId: 'NlXUehtuAJ4',
  },
  [TIGER_INDICATORS_ENUM.CDL_DOJI]: {
    haveKnowMore: true,
    text: 'O doji é um candle, ou vela, que representa um momento de indecisão no mercado. Ou seja, nessa representação, o preço de fechamento de um ativo será igual ao de abertura do mesmo. Ele é representado por uma linha ou traço horizontal.',
    html: dojiTemplate,
    video: 'https://www.youtube.com/embed/l1-apv0Im7c',
    videoId: 'l1-apv0Im7c',
    max: 1,
  },
  [TIGER_INDICATORS_ENUM.CDL_ENGULFING]: {
    haveKnowMore: false,
    text: 'Engulfing',
    html: ``,
    video: null,
    videoId: null,
  },
  [TIGER_INDICATORS_ENUM.DOUBLE_EXPONENTIAL_MOVING_AVERAGE]: {
    haveKnowMore: true,
    text: 'A Média Móvel Exponencial Dupla (DEMA) foi desenvolvida com o objetivo de reduzir o atraso e aumentar a capacidade de resposta. Essa média móvel de ação rápida permite que os traders identifiquem as mudanças de tendência rapidamente, resultando em melhores entradas nas tendências recém-formadas.',
    html: doubleExponentialMovingAverageTemplate,
    video: 'https://www.youtube.com/embed/3h84d4Wo5M0',
    videoId: '3h84d4Wo5M0',
  },
  [TIGER_INDICATORS_ENUM.TRIPLE_EXPONENTIAL_MOVING_AVERAGE]: {
    haveKnowMore: true,
    text: 'O TEMA tem como marca registrada a suavização diante da tripla média móvel exponencial. Em uma primeira impressão, pode parecer semelhante ao MACD, mas que diferencia-se por filtrar oscilações e captar movimentos de tendência de forma antecipada.',
    html: tripleExponentialMovingAverageTemplate,
    video: 'https://www.youtube.com/embed/fq_eiUit_3E',
    videoId: 'fq_eiUit_3E',
  },
  [TIGER_INDICATORS_ENUM.KAUFMAN_ADAPTIVE_MOVING_AVERAGE]: {
    haveKnowMore: true,
    text: 'O poderoso indicador de tendência KAMA baseia-se na Média Móvel Exponencial (EMA) e responde tanto à tendência quanto à volatilidade. Ele segue o preço quando o ruído é baixo e suaviza o ruído quando o preço flutua.',
    html: kaufmanMovingAverageTemplate,
    video: 'https://www.youtube.com/embed/jAPLW-y_m_Q',
    videoId: 'jAPLW-y_m_Q',
  },
  [TIGER_INDICATORS_ENUM.MOVING_AVERAGE_CONVERGENCE_DIVERGENCE]: {
    haveKnowMore: true,
    text: 'O MACD é um indicador de Análise Técnica que vem do termo Moving Average Convergence-Divergence. Em português, seria algo como Média Móvel de Convergência/Divergência. Sua principal finalidade é monitorar a aceleração ou a desaceleração das tendências de preços das ações.',
    html: macdTemplate,
    video: 'https://www.youtube.com/embed/8j4MzwBHOEA',
    videoId: '8j4MzwBHOEA',
  },
  [TIGER_INDICATORS_ENUM.TYPICAL_PRICE]: {
    haveKnowMore: true,
    text: 'O preço típico é o resultado de se fazer uma média aritmética entre o preço máximo, o preço mínimo e o preço de fechamento de um ativo financeiro. O preço típico é um cálculo amplamente utilizado em indicadores técnicos.',
    html: typicalPriceTemplate,
    video: 'https://www.youtube.com/embed/sPpmAI4JsFo',
    videoId: 'sPpmAI4JsFo',
  },
  [TIGER_INDICATORS_ENUM.AVERAGE_TRUE_RANGE]: {
    haveKnowMore: true,
    text: 'O Average True Range (ATR) é uma ferramenta usada na análise técnica para medir a volatilidade. Ao contrário de muitos dos indicadores populares de hoje, o ATR não é usado para indicar a direção do preço.',
    html: averageTrueRangeTemplate,
    video: 'https://www.youtube.com/embed/Co86K1yTcBM',
    videoId: 'Co86K1yTcBM',
  },
  [TIGER_INDICATORS_ENUM.PERCENTAGE_PRICE_OSCILLATOR]: {
    haveKnowMore: true,
    text: 'O Indicador do Oscilador Preço (PPO) é usada para medir o momentum que é muito semelhante ao MACD. O objetivo do oscilador de preços é derivar informações objetivas de operação através da relação de spread (diferença) entre duas médias móveis de períodos distintos.',
    html: percentagePriceOscillatorTemplate,
    video: 'https://www.youtube.com/embed/JF-Nur65wdM',
    videoId: 'JF-Nur65wdM',
  },
  [TIGER_INDICATORS_ENUM.TRIANGULAR_MOVING_AVERAGE]: {
    haveKnowMore: true,
    text: 'A Média Móvel triangular (TRIMA) é um indicador técnico semelhante a outras médias móveis. O TRIMA mostra o preço médio de um ativo em um número especificado de pontos de dados, geralmente várias barras de preços.No entanto, a média móvel triangular difere na medida em que é suavizada duas vezes, o que também significa média duas vezes.',
    html: triangularMovingAverageTemplate,
    video: 'https://www.youtube.com/embed/VdFX3Brpt78',
    videoId: 'VdFX3Brpt78',
  },
  [TIGER_INDICATORS_ENUM.MOMENTUM]: {
    haveKnowMore: true,
    text: 'Este indicador mede a velocidade com que os preços variam em relação aos níveis de preços atuais de um determinado ativo. Desta forma, o momentum é derivado da contínua diferença de preços para um determinado intervalo de tempo pré-definido.',
    html: momentumTemplate,
    video: 'https://www.youtube.com/embed/I0uxkJuKs0k',
    videoId: 'I0uxkJuKs0k',
  },
  [TIGER_INDICATORS_ENUM.AVERAGE_DIRECTIONAL_X]: {
    haveKnowMore: true,
    text: 'O ADX é um indicador técnico utilizado na análise técnica de mercado para determinar a força e direção de uma tendência predominante. Além disso, o ADX também pode ser usado para identificar possíveis reversões de tendência, quando o valor do indicador começa a diminuir após atingir níveis elevados.',
    html: adxTemplate,
    video: 'https://www.youtube.com/embed/vxJzyDbWPTc',
    videoId: 'vxJzyDbWPTc',
  },
  [TIGER_INDICATORS_ENUM.CANDLE_COUNTER]: {
    haveKnowMore: false,
    text: 'O Contador de Candle é amplamente utilizado para acompanhar a formação dos candles ao longo do dia, sendo a contagem feita a partir do primeiro formado. Este indicador é útil para identificar visualmente identificar candles, padrões e tendências específicas.',
    html: '',
  },
  [TIGER_INDICATORS_ENUM.BAR_ELEPHANT]: {
    haveKnowMore: false,
    text: 'Este indicador se baseia em um padrão de Candles que se destaca visualmente no Gráfico. Este candle é significativamente maior que os demais, indicando um movimento forte e decisivo no mercado. A formação desta barra geralmente é resultado de grandes players e investidores institucionais movimentando grandes quantidades de dinheiro, criando um impacto notável nos preços.',
    html: '',
  },
  [TIGER_INDICATORS_ENUM.WEIS_WAVE]: {
    haveKnowMore: true,
    text: 'O indicador Weis Wave é uma customização do indicador desenvolvido por David Weis, baseado no método de ondas de Wyckoff. Esse indicador, apresentado na forma de gráfico de frequências (histograma), é a representação do acúmulo de volume negociado a cada alteração de Topo ou Fundo. Ao invés de somar o volume, somará o saldo de agressão da subida ou descida, popularmente conhecida como pernada.',
    html: weisWaveTemplate,
  },
};
