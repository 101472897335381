import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RTTDropdownAlign } from './types/dropdown.types';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styles: [
    `
      :host(app-dropdown) {
        display: contents;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RTDropdownComponent {
  @Input() idElement: string = 'NOID';
  @Input() contentVisibility: string = 'inherit';
  @Input() css: string = '';
  @Input() menuAlign: RTTDropdownAlign | '' = '';
  @Input() minWidth: string = '';
}
