import { inject, Injectable } from '@angular/core';
import { IAccountSelect } from '@core/interface/account.interface';
import { IOrderModification, IOrders } from '@core/interface/order.interface';
import { ISearchStock } from '@core/interface/stock.interface';
import { ProcessModificationOrderModel } from '@core/models/process-modification-order.model';
import { TFlaToast } from '@shared/rocket-components/toast';
import { OrsGatewayServiceV2 } from '@shared/services/api/trademap/V2/ors-gateway.service';
import { ToastService } from '@services/toast.service';
import {
  isDoubleStartStop,
  isLimited,
  isStartStop,
} from '@shared/constants/general.contant';
import {
  editOrderTypeAuction,
  isOrderBuy,
} from 'src/app/utils/utils.functions';
import {
  TYPE_ORDE_SIDE_ENUM,
  TYPE_ORDE_SIDE_STRING_ENUM,
} from '@shared/enum/buyOrSell.enum';
import { ListOrdersService } from '@shared/services/core/list-orders/orders.service';
import { ConfigService } from '@core/config';

@Injectable({
  providedIn: 'root',
})
export class SuperDomService {
  private configService = inject(ConfigService);
  constructor(
    private _orsGatewayServiceV2: OrsGatewayServiceV2,
    private toastService: ToastService,
    private listOrdersService: ListOrdersService
  ) {}
  public sideOrders = TYPE_ORDE_SIDE_ENUM;

  public checkOrderIsStopView(order: any, side: any, price: number): boolean {
    return (
      !!order &&
      !isLimited(order.cd_order_type) &&
      (isStartStop(order.cd_order_type) ||
        isDoubleStartStop(order.cd_order_type) ||
        this.checkOrderLabelStop(order, price)) &&
      order.side === side
    );
  }

  private checkOrderLabelStop(order: any, price: number): boolean {
    if (
      order.side == TYPE_ORDE_SIDE_STRING_ENUM.BUY ||
      order.side == `${TYPE_ORDE_SIDE_ENUM.BUY}`
    ) {
      if (price && +order.price > price) return true;
      return false;
    }
    if (price && +order.price < price) return true;
    return false;
  }

  public checkHasQttyOrder(
    price: string,
    side: TYPE_ORDE_SIDE_ENUM,
    ordersListObject: any
  ): number | null {
    const order = ordersListObject[+price];
    const sideString =
      side == this.sideOrders.BUY
        ? TYPE_ORDE_SIDE_STRING_ENUM.BUY
        : TYPE_ORDE_SIDE_STRING_ENUM.SELL;
    if (!order || order.side != sideString) {
      return null;
    }
    return order ? order.qtty_left : null;
  }

  public creatingAndSendingOrder(
    stocks: ISearchStock,
    order: any,
    account: IAccountSelect,
    sideOrder: TYPE_ORDE_SIDE_ENUM
  ): void {
    this._orsGatewayServiceV2
      .sendNewOrderSingle(stocks, order, account, sideOrder)
      .subscribe();
  }

  public processModificationOrder(
    ordersList: IOrders[],
    orderSelect: any,
    item: any
  ): void {
    const orderFind = ordersList.find(
      (order: IOrders) => order.id_order == orderSelect.id_order
    )!;
    const order: IOrderModification = new ProcessModificationOrderModel(
      orderFind,
      this.configService.invertStopBroker(orderFind.side)
    );
    if (isDoubleStartStop(orderSelect.cd_order_type)) {
      if (isOrderBuy(item.side)) {
        order.gainOrderPrice = item.personalized_loss_price;
        order.gainTriggerPrice = item.personalized_loss_trigger;
        order.lossOrderPrice = item.personalized_gain_price;
        order.lossTriggerPrice = item.personalized_gain_trigger;
      } else {
        order.gainOrderPrice = item.personalized_gain_price;
        order.gainTriggerPrice = item.personalized_gain_trigger;
        order.lossOrderPrice = item.personalized_loss_price;
        order.lossTriggerPrice = item.personalized_loss_trigger;
      }
    } else {
      order.price = item.price;
      order.stopPx = item.price_stop;
    }
    editOrderTypeAuction(orderSelect, order);
    item.orderQty && (order.order_qty = item.orderQty);
    this._orsGatewayServiceV2
      .orderModificationRequest(order)
      .subscribe((res) => {
        if (res && !res.success) {
          this.toastShow(res.message);
        }
      });
  }

  public toastShow(text: string, type: TFlaToast = 'warning'): void {
    this.toastService.showToast(type, text);
  }

  public checkReturnCallApiOrders(): boolean {
    return this.listOrdersService.checkReturnCallApiOrders;
  }
}
