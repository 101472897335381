import { Type, inject } from '@angular/core';
import { IWorkSpace } from '@core/interface';
import { ModalGerenciarComponent } from '@core/layout/header/modal-gerenciar/modal-gerenciar.component';
import { ModalMeusDadosComponent } from '@core/layout/header/modal-meus-dados/modal-meus-dados.component';
import { ModalShortcutsComponent } from '@core/layout/header/modal-shortcuts/modal-shortcuts.component';
import { ModalTokenConfigComponent } from '@core/layout/header/modal-token-config/modal-token-config.component';
import { ModalStrategiesComponent } from '@core/layout/header/strategy/modal-strategies/modal-strategies.component';
import { WorkspaceComponentService } from '@modules/home/service/workspace-component.service';
import { ModalAddComponentWorkspaceComponent } from '@modules/home/workspace-component/modal-add-component-workspace/modal-add-component-workspace.component';
import { StockChartModalMoreOptionsComponent } from '@shared/components/stock-chart/parts';
import { StockChartModalStockComponent } from '@shared/components/stock-chart/parts/modal-stock/stock-chart-modal-stock.component'
import { ModalOrdensComponent } from '@shared/modals/modal-ordens/modal-ordens.component';
import { firstValueFrom } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { StockSearchConfigModalComponent } from '@shared/components/stock-search-config-modal/stock-search-config-modal.component';
import { NewsModalComponent } from '@shared/components/market-summary/parts/news-modal/news-modal.component';
import { IndexesModalComponent } from '@shared/components/market-summary/parts/indexes-modal/indexes-modal.component';
import { ModalInfoComponent } from '@shared/components/business-profile/modal-info/modal-info.component';
import { DocumentsModalComponent } from '@shared/documents-modal/documents-modal.component';
import { HelpDrawsComponent } from '@shared/tiger-chart/tiger-chart-tools/help-draws/help-draws.component';
import { IndicatorsModalComponent } from '@shared/components/stock-chart/parts/header/indicators-modal/indicators-modal.component';
import { GeneralModalComponent } from '@shared/components/market-summary/parts/general-modal/general-modal.component';
import { ManageListModalComponent } from '@shared/components/stock-table/modals/manage-list-modal/manage-list-modal.component';
import { NewListStockModalComponent } from '@shared/components/stock-table/modals/new-list-stock-modal/new-list-stock-modal.component';
import { DuplicateListModalComponent } from '@shared/components/stock-table/modals/duplicate-list-modal/duplicate-list-modal.component';
import { ConfigHeaderModalComponent } from '@shared/components/stock-table/modals/config-modal/config-header-modal.component';
import { ModalModelSaveComponent } from '@shared/tiger-chart/tiger-chart-tools/toolbar-draw/modal-model-save/modal-model-save.component';
import { NewStockModalComponent } from '@shared/components/stock-table/modals/new-stock-modal/new-stock-modal.component';
import { BrokerPartnerAlreadyConnectedComponent } from '@shared/modals/welcome-modals/broker-partner-already-connected/broker-partner-already-connected.component';
import { TrialExpiredComponent } from '@shared/modals/welcome-modals/trial-expired/trial-expired.component';
import { EditParamsConditionsModalComponent } from '@shared/components/screening/stock-screening/screening-filters/edit-params-conditions-modal/edit-params-conditions-modal.component';
import { OptionsListPreferencesModalComponent } from '@shared/components/options/parts/list/list-preferences-modal/options-list-preferences-modal.component';
import { OptionsStrategyModalComponent } from '@shared/components/options/parts/strategy-modal/options-strategy-modal.component';
import { ModalConfigDrawComponent } from '@shared/tiger-chart/tiger-chart-tools/toolbar-draw/modal-config-draw/modal-config-draw.component';
import { WelcomeXpModalComponent } from '@shared/modals/partners/welcome-xp-modal/welcome-xp-modal.component';
import { TrademapComponent } from '@shared/modals/welcome-modals/trademap/trademap.component';
import { SettingsModalComponent } from '@shared/tiger-chart/indicators/settings-modal/settings-modal.component';
import { ModalOrderTokenComponent } from '@shared/rocket-components/modal-order-token/modal-order-token.component';
import { TradesFilterModalComponent } from '@shared/components/times-trades/parts/trades-filter-modal/trades-filter-modal.component';
import { ModalTradeIdeaComponent } from '@shared/components/trade-idea/modal-trade-idea/modal-trade-idea.component';
import { ModalAvisoComponent } from '@shared/components/modal-aviso/modal-aviso.component';
import { CancelOrderModalComponent } from '@shared/modals/cancel-order-modal/cancel-order-modal.component';
import { ModalBrokersToConnectComponent } from '@modules/broker-connection/modals/modal-brokers-to-connect/modal-brokers-to-connect.component';
import { ConfigHeaderColumnsModalComponent } from '@core/components/orders-history-core/modals/config-header-columns-modal/config-header-columns-modal.component';
import { EditAlertModalComponent } from '@core/components/orders-history-core/modals/edit-alert-modal/edit-alert-modal.component';
import { OrderDetailsModalComponent } from '@core/components/orders-history-core/modals/order-details-modal/order-details-modal.component';
import { ModalOrdersTabSettingsComponent } from '@core/components/orders-history-core/modals/modal-orders-tab-settings/modal-orders-tab-settings.component';
import { UserDetails } from '@shared/services/api/trademap/v1/user-service/models/class/user-details.class';
import { UserService } from '@shared/services/api/trademap/v1/user-service/user.service';

export const ModalSettingsByType = new Map<Type<any>, () => Promise<any>>([
  [
    ModalMeusDadosComponent,
    async (): Promise<any> => {
      const _userService = inject(UserService);
      const user: UserDetails = await firstValueFrom(_userService.getInfos());
      return {
        size: 'lg',
        centered: true,
        backdrop: 'static',
        keyboard: true,
        scrollable: false,
        data: user,
      };
    }
  ],
  [
    ModalTokenConfigComponent,
    async (): Promise<any> => {
      return {
        size: 'lg',
        centered: true,
        backdrop: true,
        keyboard: true,
        scrollable: false,
        data: { callback: undefined, tokenPreference: '' },
      };
    }
  ],
  [
    StockChartModalStockComponent,
    async (): Promise<any> => {
      const word: BehaviorSubject<string> = new BehaviorSubject<string>('');
      const data = { word: word, ref: 'HOME', searchString: null };
      return {
        keyboard: true,
        backdrop: true,
        scrollable: false,
        data: data
      };
    }
  ],
  [
    ModalGerenciarComponent,
    async (): Promise<any> => {
      return undefined;
    }
  ],
  [
    ModalAddComponentWorkspaceComponent,
    async (): Promise<any> => {
      const ResWorkspace = inject(WorkspaceComponentService).dispatchActiveWorkspace();
      const currentWorkspace: IWorkSpace = await firstValueFrom(ResWorkspace);
      const data = { ref: 'HOME', idWorkspace: currentWorkspace.id };
      return {
        data: data,
        keyboard: true,
        backdrop: true,
        scrollable: false,
      };
    }
  ],
  [
    ModalShortcutsComponent,
    async (): Promise<any> => {
      return {
        size: 'lg',
        centered: true,
        backdrop: true,
        keyboard: false,
        scrollable: false,
      };
    }
  ],
  [
    ModalStrategiesComponent,
    async (): Promise<any> => {
      const data: any = {
        toggleBetweenModal: undefined,
        isSecondModal: false,
        showAllStrategies: true,
        newStrategy: false,
        selectedStrategy: undefined,
        onlyEdit: false,
      };
      return {
        size: 'lg',
        data,
      };
    }
  ],
  [
    ModalOrdensComponent,
    async (): Promise<any> => {
      return undefined;
    }
  ],
  [
    ModalBrokersToConnectComponent,
    async (): Promise<any> => ({
      size: 'xl',
      centered: true,
      backdrop: 'static',
      keyboard: false,
      scrollable: false,
      data: {
        brokers: undefined,
        isSessionWeak: false,
        field: null,
        listBrokers: null,
      },
    })
  ]
]
);

export const ModalViewByPath = new Map<string, Type<any>>([
  ['app-modal-meus-dados', ModalMeusDadosComponent],
  ['app-token-config', ModalTokenConfigComponent],
  ['app-stock-chart-modal-stock', StockChartModalStockComponent],
  ['app-modal-gerenciar', ModalGerenciarComponent],
  ['app-stock-chart-modal-more-options', StockChartModalMoreOptionsComponent],
  ['app-modal-shortcuts', ModalShortcutsComponent],
  ['app-modal-strategies', ModalStrategiesComponent],
  ['app-modal-ordens', ModalOrdensComponent],
  ['app-stock-search-config-modal', StockSearchConfigModalComponent],
  ['app-modal-add-component-workspace', ModalAddComponentWorkspaceComponent],
  ['app-news-modal', NewsModalComponent],
  ['app-indexes-modal', IndexesModalComponent],
  ['app-modal-info', ModalInfoComponent],
  ['app-documents-modal', DocumentsModalComponent],
  ['app-help-draws', HelpDrawsComponent],
  ['app-indicators-modal', IndicatorsModalComponent],
  ['app-general-modal', GeneralModalComponent],
  ['app-manage-list-modal', ManageListModalComponent],
  ['app-new-list-stock-modal', NewListStockModalComponent],
  ['app-duplicate-list-modal', DuplicateListModalComponent],
  ['app-config-header-modal', ConfigHeaderModalComponent],
  ['app-modal-model-save', ModalModelSaveComponent],
  ['app-new-stock-modal', NewStockModalComponent],
  ['app-broker-partner-already-connected', BrokerPartnerAlreadyConnectedComponent],
  ['app-trial-expired', TrialExpiredComponent],
  ['app-config-header-columns-modal', ConfigHeaderColumnsModalComponent],
  ['app-edit-params-conditions-modal', EditParamsConditionsModalComponent],
  ['app-options-list-preferences-modal', OptionsListPreferencesModalComponent],
  ['app-options-strategy-modal', OptionsStrategyModalComponent],
  ['app-modal-config-draw', ModalConfigDrawComponent],
  ['app-welcome-xp-modal', WelcomeXpModalComponent],
  ['app-trademap', TrademapComponent],
  ['app-settings-modal', SettingsModalComponent],
  ['app-modal-order-token', ModalOrderTokenComponent],
  ['app-trades-filter-modal', TradesFilterModalComponent],
  ['app-modal-brokers-to-connect', ModalBrokersToConnectComponent],
  ['app-edit-alert-modal', EditAlertModalComponent],
  ['app-order-details-modal', OrderDetailsModalComponent],
  ['app-modal-orders-tab-settings', ModalOrdersTabSettingsComponent],
  ['app-modal-trade-idea', ModalTradeIdeaComponent],
  ['app-modal-aviso', ModalAvisoComponent],
  ['app-cancel-order-modal', CancelOrderModalComponent]
]);