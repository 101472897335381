import { TFooterType } from "@shared/tiger-chart/tiger-chart-footer/tiger-chart-footer.enum";
import { FROM_TYPES, INPUT_TYPES, TYPE_CHART_LINES_UPDATE } from "../enum/stock-chart-modal.enum";
import { LINE_INPUT } from "../interface/stock-chart-modal.interface";

export const LINE_OPTIONS: LINE_INPUT[] = [
  {
    title: 'Alertas',
    formControlName: 'alerts',
    from: FROM_TYPES.chart_line,
    chartUpdate: TYPE_CHART_LINES_UPDATE.SHOW_ALERT_LINE,
    fields: [
      { key: 'rightSide', type: INPUT_TYPES.SELECT, width: '100px' },
      { key: 'enabled', type: INPUT_TYPES.CHECKBOX, width: '50px' }
    ]
  },
  {
    title: 'Cobertura',
    formControlName: 'breakEven',
    cod: TFooterType.BREAK_EVEN,
    from: FROM_TYPES.chart_line,
    chartUpdate: TYPE_CHART_LINES_UPDATE.BREAK_EVEN,
    hint: 'A cobertura é um recurso que sinaliza o <b>ponto de equilíbrio</b> de suas operações a partir do <b>resultado do dia</b>, onde <b>não há lucro ou prejuízo</b>. Mostra que a  partir deste ponto (acima ou abaixo dependendo do lado posicionado), ele deixa de ter prejuízo, e passa a ganhar e equilibrar o capital investido.',
    fields: [
      { key: 'rightSide', type: INPUT_TYPES.SELECT, width: '100px' },
      { key: 'enabled', type: INPUT_TYPES.CHECKBOX, width: '50px' }
    ]
  },
  {
    title: 'Ordens',
    formControlName: 'orders',
    from: FROM_TYPES.chart_line,
    chartUpdate: TYPE_CHART_LINES_UPDATE.SHOW_ORDER_LINE,
    fields: [
      { key: 'rightSide', type: INPUT_TYPES.SELECT, width: '100px' },
      { key: 'enabled', type: INPUT_TYPES.CHECKBOX, width: '50px', disabled: true, disabledTooltip: 'Não é possível desativar a linha de ordens' }
    ]
  },
  {
    title: 'Custódia',
    formControlName: 'custody',
    from: FROM_TYPES.chart_line,
    chartUpdate: TYPE_CHART_LINES_UPDATE.SHOW_CUSTODY_LINE,
    fields: [
      { key: 'rightSide', type: INPUT_TYPES.SELECT, width: '100px' },
      { key: 'enabled', type: INPUT_TYPES.CHECKBOX, width: '50px', disabled: true, disabledTooltip: 'Não é possível desativar a linha de custódia' }
    ]
  },
  {
    title: 'Preço atual',
    formControlName: 'lastPrice',
    from: FROM_TYPES.chart_line,
    chartUpdate: TYPE_CHART_LINES_UPDATE.SHOW_LAST_PRICE_LINE,
    fields: [
      { key: 'enabled', type: INPUT_TYPES.CHECKBOX, width: '50px' }
    ]
  },
  {
    title: 'Último fechamento',
    formControlName: 'showPreviousClose',
    cod: TFooterType.PREVIOUS_CLOSE,
    chartUpdate: TYPE_CHART_LINES_UPDATE.SHOW_PREVIOUS_CLOSE,
    from: FROM_TYPES.chart_line,
    fields: [
      { key: 'enabled', type: INPUT_TYPES.CHECKBOX, width: '50px' }
    ]
  },
]

export const INPUT_LINE_SIDE_OPTIONS = [
  {key: '0', label: 'Esquerda'},
  {key: '1', label: 'Direita'},
]