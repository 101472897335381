<div class="w-36" [formGroup]="form">
  <div class="mt-3">
    <!--<div class="d-flex">
      <span class="text-neutral-medium fs-5">Volume</span>

    </div>-->
    <app-check
      label="Volume"
      name="showVolume"
      formControlName="showVolume"
      [isChecked]="form.get('showVolume')?.value"
      (flaChange)="changeVisibleVolume()"
      [isSwitch]="true"
      [isTextLeft]="true"
      css="mb-2 fs-5 text-neutral-medium w-100 justify-content-between white-space-nowrap"
    >
    </app-check>

    <div>
      <div class="d-flex align-items-center justify-content-between gap-3 mb-2">
        <div class="mt-3">Corpo</div>
        <div class="d-flex">
          <div class="d-flex flex-column align-items-center mr-1">
            <span tooltip="Comprador" class="fs-6">C</span>
            <app-dropdown title="">
              <a
                type="button"
                class="nav-link lh-sm round-top h-100"
                appDropdownButton
                placement="bottom-start"
                title=""
                [ngClass]="{ 'cursor-default': !form.get('showVolume')?.value }"
              >
                <div class="colorpicker">
                  <div
                    [ngClass]="{
                      'cursor-pointer': form.get('showVolume')?.value
                    }"
                    class="colorpickerWrapper"
                  >
                    <div
                      class="color d-flex align-items-center justify-content-center p-1"
                    >
                      <div class="opacitySwatch">
                        <div
                          id="volumeUpColorColorpicker"
                          class="swatch"
                          style="background-color: rgb(255, 255, 255)"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <div *ngIf="form.get('showVolume')?.value" class="contents">
                <li>
                  <app-config-colorpicker
                    (selectedColor)="selectedColor('volumeUpColor', $event)"
                    [typeColorUpdate]="toolsTypeDraw.TEXT"
                    [useColor]="volumeDefaultColors['volumeUpColor']"
                    #configColorPicker
                  ></app-config-colorpicker>
                </li>
              </div>
            </app-dropdown>
          </div>
          <div class="d-flex flex-column align-items-center mr-1">
            <span tooltip="Vendedor" class="fs-6">V</span>
            <app-dropdown title="">
              <a
                type="button"
                class="nav-link lh-sm round-top h-100"
                appDropdownButton
                placement="bottom-start"
                title=""
                [ngClass]="{ 'cursor-default': !form.get('showVolume')?.value }"
              >
                <div class="colorpicker">
                  <div
                    [ngClass]="{
                      'cursor-pointer': form.get('showVolume')?.value
                    }"
                    class="colorpickerWrapper"
                  >
                    <div
                      class="color d-flex align-items-center justify-content-center p-1"
                    >
                      <div class="opacitySwatch">
                        <div
                          id="volumeDownColorColorpicker"
                          class="swatch"
                          style="background-color: rgb(255, 255, 255)"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <div *ngIf="form.get('showVolume')?.value" class="contents">
                <li>
                  <app-config-colorpicker
                    (selectedColor)="selectedColor('volumeDownColor', $event)"
                    [typeColorUpdate]="toolsTypeDraw.TEXT"
                    [useColor]="volumeDefaultColors['volumeDownColor']"
                    #configColorPicker
                  ></app-config-colorpicker>
                </li>
              </div>
            </app-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
