<div [ngClass]="{ 'pr-none': loading }" class="d-flex flex-column h-100">
  <div
    class="position-absolute scichart-tooltip-div-cl"
    [id]="'pairs-tooltip-div-id_' + refChart"
  ></div>

  <div class="d-flex gap-3 w-100">
    <div class="col" *ngFor="let item of companyInfo.sectors">
      <fla-card
        (click)="
          openSectorSubSectorSegmentModal(companyInfo.market_position, item.cod)
        "
      >
        <fla-card-custom
          #card
          [css]="
            'p-2 border round' +
            (_stockSelected.type === 'VIS' ? ' cursor-pointer' : '') +
            (item.cod === selectedSect
              ? ' text-brand-primary border-brand-primary'
              : '')
          "
        >
          <div class="card-body hstack justify-content-between">
            <div class="d-flex flex-column w-100">
              <small>{{ item.title }}</small>
              <small class="fs-6 text-truncate">
                {{ item.description }}
              </small>
            </div>
          </div>
        </fla-card-custom>
      </fla-card>
    </div>
  </div>

  <div
    [ngClass]="{ 'd-none': !itemsSubscribed.size() }"
    id="tags"
    class="align-items-start d-flex gap-3 mb-2 mt-3"
  >
    <div id="inside-tags" class="tags d-flex flex-wrap gap-2 overflow-hidden">
      <app-tag
        *ngFor="let stock of itemsSubscribed.values()"
        symbol=" "
        [title]="stock.cd_stock"
        [variation]="stock[selectedVisualization.field] || '-'"
        [isVariation]="true"
        [useSmallTag]="true"
        [bgColor]="stock.arrow_hex"
        [color]="stock.arrow_font_hex"
        titleSize="fs-7"
        [auctionInfos]="stockAuctionInfos[stock.cd_stock]"
        [situation]="stockAuctionInfos[stock.cd_stock]?.situacao"
      ></app-tag>
    </div>

    <div class="mt-2">
      <div class="dropdown dropdown-position">
        <a
          class="dropdown-custom-size border btn btn-primary dropdown-toggle fw-bold p-1 round-lg text-white bg-transparent fs-6"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ selectedVisualization.label }}
        </a>

        <ul
          class="dropdown-menu cursor-pointer"
          aria-labelledby="dropdownMenuLink"
        >
          <li *ngFor="let visualization of visualizations">
            <a
              class="dropdown-item"
              type="button"
              (click)="changeVisualization(visualization)"
            >
              {{ visualization.label }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div
    [ngClass]="{ 'd-none': !itemsSubscribed.size() }"
    class="mt-3 d-flex flex-column h-100"
  >
    <div class="chart-header d-flex justify-content-between">
      <span>Comparador</span>
      <div class="period d-flex gap-2">
        <span
          (click)="selectPeriod(period)"
          [ngClass]="{ 'text-brand-primary': periodSelected === period.cod }"
          class="cursor-pointer"
          *ngFor="let period of periods.values()"
          >{{ period.title }}</span
        >
      </div>
    </div>
    <div class="chart h-100">
      <div
        [id]="'pairs-legend-div-id_' + refChart"
        class="w-100 d-inline-flex align-items-center justify-content-center custom-legend"
      ></div>
      <div
        [id]="refChart"
        #chartContainer
        class="w-100 h-100"
        [style.max-height.px]="235"
      ></div>
    </div>
  </div>

  <div
    *ngIf="loading"
    class="w-100 d-flex justify-content-center align-items-center my-3"
  >
    <app-loading></app-loading>
  </div>

  <div
    class="w-100 d-flex justify-content-center align-items-center h-100"
    [ngClass]="{ 'd-none': loading || itemsSubscribed.size() }"
  >
    <app-no-info></app-no-info>
  </div>
</div>
