import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TCallPutOptions, TOptions } from '../../../types';
import { RocketModalService } from '@shared/rocket-components/components';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { RocketCreateComponentService } from '@shared/rocket-components/services';
import { RocketGridComponent } from '@shared/rocket-grid/rocket-grid.component';
import { ISearchStock } from '@core/interface';
import { OptionsListBase } from '../options-list-base';
import { OptionsListHelper } from '../options-list.helper';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { OptionsComponentService } from '@shared/components/options/options-component.service';
import { BodyScrollEvent } from 'ag-grid-community';
import { Subject, debounceTime } from 'rxjs';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { OptionsService } from '@shared/components/options/options.service';

@Component({
  selector: 'app-options-list-callput',
  templateUrl: './options-list-callput.component.html',
  styleUrls: ['./options-list-callput.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsListCallputComponent
  extends OptionsListHelper
  implements OptionsListBase, OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() override linked: boolean = false;
  @Input() refId!: string;
  @Input() override componentId: string = '';
  @Input() height!: string;
  @Input() set width(width: string) {
    if (width) {
      this._width = parseInt(width);
    }
  }
  @Input() stock!: ISearchStock | undefined;
  @Input() override configKey: string = '';
  @Input() loading = true;
  @ViewChild('grid') override grid: RocketGridComponent | undefined = undefined;
  @ViewChild('listWrapper', { static: true }) listWrapper!: ElementRef;

  addRow!: any;
  oldRow!: any;
  override subheaderTab = 'callput';
  strikePriceMapping: { [key: string]: number } = {};
  optionMapping: { [key: string]: number } = {};
  rowData: TCallPutOptions[] = [];
  currentPriceIndex!: number;
  gridOptions = {
    getRowHeight: (params: any) => {
      const { isCurrentPrice, cd_stock_call, cd_stock_put } = params.data;
      return isCurrentPrice && !cd_stock_call && !cd_stock_put! ? 2 : 25;
    },
    getRowClass: (params: any) => {
      const { isCurrentPrice, cd_stock_call, cd_stock_put } = params.data;
      return isCurrentPrice && !cd_stock_call && !cd_stock_put!
        ? 'current-price-row'
        : '';
    },
  };

  defaultValues: TCallPutOptions = {
    label_call: '',
    kind_option_call: '',
    day_variation_call: 0,
    vl_vol_implicitly_call: 0,
    vl_delta_call: 0,
    qt_trades_call: 0,
    qt_share_traded_call: 0,
    valor_melhor_oferta_compra_call: 0,
    valor_melhor_oferta_venda_call: 0,
    id_stock_call: 0,
    cd_stock_call: '',
    vl_close_call: 0,
    option_days_to_expiration_call: 0,
    strike_price: 0,
    label_put: '',
    kind_option_put: '',
    day_variation_put: 0,
    vl_vol_implicitly_put: 0,
    vl_delta_put: 0,
    qt_trades_put: 0,
    qt_share_traded_put: 0,
    valor_melhor_oferta_compra_put: 0,
    valor_melhor_oferta_venda_put: 0,
    vl_close_put: 0,
    option_days_to_expiration_put: 0,
    id_stock_put: 0,
    cd_stock_put: '',
    dt_expiration_option_call: 0,
    in_option_call: 'ALL',
    volume_call: 0,
    strike_state_call: '',
    vl_theta_call: 0,
    vl_rho_call: 0,
    vl_gamma_call: 0,
    vl_vega_call: 0,
    vl_close_option_call: 0,
    vl_close_base_call: 0,
    dt_expiration_option_put: 0,
    in_option_put: 'ALL',
    volume_put: 0,
    strike_state_put: '',
    vl_theta_put: 0,
    vl_rho_put: 0,
    vl_gamma_put: 0,
    vl_vega_put: 0,
    uncovered_qty_call: 0,
    covered_qty_call: 0,
    uncovered_qty_put: 0,
    covered_qty_put: 0,
  };

  constructor(
    themeService: ThemePreferencesService,
    modalService: RocketModalService,
    customPreferencesService: CustomPreferencesService,
    createComponent: RocketCreateComponentService,
    optionsComponentService: OptionsComponentService,
    private cdr: ChangeDetectorRef,
    contextMenuService: ContextMenuService,
    globalStock: GlobalSelectedStockSubscription,
    optionsService: OptionsService
  ) {
    super(
      themeService,
      modalService,
      customPreferencesService,
      createComponent,
      optionsComponentService,
      cdr,
      contextMenuService,
      globalStock,
      optionsService
    );
  }

  ngOnInit(): void {
    this.listWrapper.nativeElement.oncontextmenu = this._showConfig;
    this._initiateConfigs();
    this.strikeRowSubscribe();
  }

  ngAfterViewInit(): void {
    this.ready = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { width } = changes;

    if (
      width &&
      width.currentValue &&
      this.columnDefs &&
      this.columnDefs[this.columnDefs.length - 1]
    ) {
      this.columnDefs[this.columnDefs.length - 1].width =
        width.currentValue - 10;
      this.gridApi &&
        !this.loading &&
        this.gridApi.setColumnDefs(this.columnDefs);
      this.updateHeaderGroupsWidth();
    }
  }
  ngOnDestroy(): void {
    this.onDestroy();
  }

  processOptions(response: any) {
    if (!this.stock) return [];

    this.rowData = [];
    this.optionMapping = {};
    this.strikePriceMapping = {};
    this.currentPriceIndex = -1;

    if (!response || !response.length) {
      this.cdr.detectChanges();
      return [];
    }

    const { id_exchange } = this.stock;
    const cheetahItems: string[] = [`${this.stock.cd_stock}:${id_exchange}`];

    response.forEach((row: TOptions) => {
      const item = `${row.cd_stock}:${id_exchange}`;
      cheetahItems.push(item);
      this.strikePriceMapping[item] = row.strike_price;
      this._processData(row);
    });

    return cheetahItems;
  }

  optionsChannelHandler = (payload: Map<string, any>): void => {
    payload.forEach((data) => {
      const { row, type, rowIndex } = this._getRowForCheetahItem(data);
      if (!row) return;
      this.rowData[rowIndex] = {
        ...row,
        [`vl_vol_implicitly${type}`]: data.vol_implicitly
          ? data.vol_implicitly
          : row[`vl_vol_implicitly${type}` as keyof TCallPutOptions],
        [`vl_delta${type}`]: data.delta
          ? data.delta
          : row[`vl_delta${type}` as keyof TCallPutOptions],
        [`qt_share_traded${type}`]: data.qtde_papel_negociado
          ? data.qtde_papel_negociado
          : row[`qt_share_traded${type}` as keyof TCallPutOptions],
        [`vl_close${type}`]: data.vl_close
          ? data.vl_close
          : row[`vl_close${type}` as keyof TCallPutOptions],
        [`dt_expiration_option_${type}`]: data.days_to_expiration
          ? data.days_to_expiration
          : row[`dt_expiration_option${type}` as keyof TCallPutOptions],
        [`vl_theta_${type}`]: data.theta
          ? data.theta
          : row[`vl_theta${type}` as keyof TCallPutOptions],
        [`vl_rho_${type}`]: data.rho
          ? data.rho
          : row[`vl_rho${type}` as keyof TCallPutOptions],
        [`vl_gamma_${type}`]: data.gamma
          ? data.gamma
          : row[`vl_gamma${type}` as keyof TCallPutOptions],
        [`vl_vega_${type}`]: data.vega
          ? data.vega
          : row[`vl_vega${type}` as keyof TCallPutOptions],
      };
      if (this.gridApi && !this.loading) {
        this.gridApi.applyTransactionAsync({
          update: [{ idRow: data.strike_price, ...this.rowData[rowIndex] }],
        });
        this.cdr.detectChanges();
      }
    });
  };

  quotesChannelHandler = (data: any): void => {
    if (!data) return;
    if (data.item == `${this.stock?.cd_stock}:${this.stock?.id_exchange}`) {
      this._updateCurrentPrice(data);
      return;
    }
    const { row, type, rowIndex } = this._getRowForCheetahItem(data);
    if (!row) return;
    this.rowData[rowIndex] = {
      ...row,
      [`valor_melhor_oferta_compra${type}`]: data.valor_melhor_oferta_compra,
      [`valor_melhor_oferta_venda${type}`]: data.valor_melhor_oferta_venda,
      [`day_variation${type}`]: data.variacao_dia,
      [`qt_trades${type}`]: data.qtde_negocios,
    };
    if (this.gridApi && !this.loading) {
      this.gridApi.applyTransactionAsync({
        update: [{ idRow: data.strike_price, ...this.rowData[rowIndex] }],
      });
      this.cdr.detectChanges();
    }
  };

  private _getRowForCheetahItem(data: any): {
    row: TCallPutOptions | null;
    type: string;
    rowIndex: number;
  } {
    if (!data || !this.strikePriceMapping[data.item])
      return { row: null, type: '', rowIndex: 0 };

    const rowIndex = this.optionMapping[this.strikePriceMapping[data.item]];
    const row: any = this.rowData[rowIndex];
    if (!row) {
      return { row: null, type: '', rowIndex: 0 };
    }
    const type =
      row.cd_stock_call == data.item.split(':')[0] ? '_call' : '_put';

    return { row, type, rowIndex };
  }

  onBodyScroll(event: BodyScrollEvent) {
    if (event.direction != 'horizontal') return;

    this.callWidthWithScroll = this.callWidth - event.left;
    this.putWidthWithScroll = this.putWidth + event.left;
    this.gridScroll = event.left;
  }

  private _processData(option: TOptions) {
    let rowIndex = this.optionMapping[option.strike_price];

    if (rowIndex == undefined) {
      this.rowData.push({
        ...this.defaultValues,
        strike_price: option.strike_price,
      });
      rowIndex = this.rowData.length - 1;

      this.optionMapping[option.strike_price] = rowIndex;
    }

    const type = option.in_option == 'C' ? '_call' : '_put';
    const row: any = this.rowData[rowIndex];
    this.rowData[rowIndex] = {
      ...row,
      [`id_stock${type}`]: option.id_stock,
      [`cd_stock${type}`]: option.cd_stock,
      [`label${type}`]: option.cd_stock,
      [`kind_option${type}`]: option.kind_option,
      [`day_variation${type}`]: option.day_variation,
      [`vl_vol_implicitly${type}`]: option.vl_vol_implicitly,
      [`vl_delta${type}`]: option.vl_delta,
      [`qt_trades${type}`]: option.qt_trades,
      [`qt_share_traded${type}`]: option.qt_share_traded,
      [`vl_close${type}`]: option.vl_close,
      [`option_days_to_expiration${type}`]: option.option_days_to_expiration,
      [`dt_expiration_option${type}`]: option.dt_expiration_option,
      [`in_option${type}`]: option.in_option,
      [`volume${type}`]: option.volume,
      [`strike_state${type}`]: option.strike_state,
      [`vl_theta${type}`]: option.vl_theta,
      [`vl_rho${type}`]: option.vl_rho,
      [`vl_gamma${type}`]: option.vl_gamma,
      [`vl_vega${type}`]: option.vl_vega,
      [`vl_close_option${type}`]: option.vl_close_option,
      [`vl_close_base${type}`]: option.vl_close_base,
      [`uncovered_qty${type}`]: option.uncovered_qty,
      [`covered_qty${type}`]: option.covered_qty,
    };
  }

  private _updateCurrentPrice(data: any) {
    if (!data.preco_ultimo || this.loading || !this.stock || !this.grid) return;

    if (this.currentPriceIndex > -1) {
      this.oldRow = this.rowData[this.currentPriceIndex];
      if (!this.oldRow) return;
      if (this.oldRow.strike_price == +data.preco_ultimo) {
        return;
      }

      this.rowData[this.currentPriceIndex] = {
        ...this.oldRow,
        isCurrentPrice: false,
      };

      if (this.oldRow.cd_stock_call || this.oldRow.cd_stock_put) {
        this.grid.onUpdateValues({
          idRow: `${this.rowData[this.currentPriceIndex].strike_price}`,
          propsCell: this.rowData[this.currentPriceIndex],
        });
      }
    }

    let rowIndex = this.optionMapping[+data.preco_ultimo];
    let shouldAdd = false;
    let strikePrice = '';

    if (rowIndex == undefined) {
      this.rowData.push(this.defaultValues);
      rowIndex = this.rowData.length - 1;

      shouldAdd = true;
      strikePrice = data.preco_ultimo;
    } else {
      strikePrice = `${this.rowData[rowIndex].strike_price}`;
    }

    const row: any = this.rowData[rowIndex];
    this.rowData[rowIndex] = {
      ...row,
      strike_price: +data.preco_ultimo,
      isCurrentPrice: true,
    };

    if (shouldAdd) {
      this.updateSubject.next(rowIndex);
      return;
    } else {
      if (
        this.oldRow &&
        !this.oldRow.cd_stock_call &&
        !this.oldRow.cd_stock_put
      ) {
        this.removeOldRow();
      }
    }
    this.grid.onUpdateValues({
      idRow: strikePrice,
      propsCell: this.rowData[rowIndex],
    });
    const node = this.gridApi.getRowNode(strikePrice);
    (this.currentPriceIndex == undefined || this.currentPriceIndex == -1) &&
      node &&
      this.grid.centralizeRow(node);
    this.currentPriceIndex = rowIndex;
    this.cdr.detectChanges();
  }

  updateSubject = new Subject<number>();
  protected strikeRowSubscribe = () => {
    this.updateSubject.pipe(debounceTime(200)).subscribe((index: number) => {
      if (this.grid) {
        this.oldRow &&
          !this.oldRow.cd_stock_call &&
          !this.oldRow.cd_stock_put &&
          this.removeOldRow();

        this.grid.setRow(this.rowData[index], (res: any) => {
          (this.currentPriceIndex == undefined ||
            this.currentPriceIndex == -1) &&
            res.add[0] &&
            this.gridApi.ensureNodeVisible(res.add[0], 'middle');
          this.currentPriceIndex = index;
        });
      }
    });
  };

  removeOldRow = () => {
    this.grid &&
      this.rowData[this.currentPriceIndex] &&
      this.grid.removeRow({
        rowData: {
          ...this.rowData[this.currentPriceIndex],
          idRow: this.rowData[this.currentPriceIndex].strike_price,
        },
        rowIndex: this.currentPriceIndex,
        refComponent: '',
      });
    delete this.optionMapping[this.oldRow?.strike_price];
    delete this.rowData[this.currentPriceIndex];
  };

  setCentralizeLine() {
    if (!this.grid || this.loading || !this.rowData[this.currentPriceIndex])
      return;
    const node = this.gridApi.getRowNode(
      this.rowData[this.currentPriceIndex].strike_price as any
    );
    this.grid.centralizeRow(node);
  }
}
