import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ISearchStock } from '@core/interface';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { VolumeData } from '@shared/channel/interface/volume.channel.interface';
import { RocketStreamRead } from '@shared/channel/rx-event';
import { VolumeChannel } from '@shared/channel/volume.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { TYPE_ORDE_SIDE_STRING_ENUM } from '@shared/enum/buyOrSell.enum';
import { formatterNumber } from '@shared/rocket-components/utils';
import { Subject, auditTime, takeUntil } from 'rxjs';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-graphic-at-price',
  templateUrl: './graphic-at-price.component.html',
  styleUrls: ['./graphic-at-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphicAtPriceComponent
  extends ReadStreamBase
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  public tableDefault: any[] = [];
  public virtualScrollViewportHeigth = 100;
  private createList = true;
  public rangePrice: any = {};

  @ViewChild('table') elementRefTable!: ElementRef<any>;
  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;
  @Input() refComponent!: string;
  @Input() cdStock!: string;
  @Input() idExchange!: number;
  @Input() height!: any;
  @Input() tickSizeDenominator: number = 0;
  @Input() showDetail: boolean = true;
  @Input() precoUltimo!: string;
  @Input() typeNearest: string = '';
  @Input() isLineBlock: boolean = true;
  @Input() precoMinimo!: string;
  @Input() precoMaximo!: string;
  @Input() price!: number;
  @Input() stock!: ISearchStock;
  @Input() type: string = 'GRAPHIC';
  @Input() isDesktop = false;
  public mouseClicked = false;
  public mouseClickedEvent!: any;
  public showSeparator = -1;
  public mask: string = '0000000';
  public reRender = false;
  public sideOrder = TYPE_ORDE_SIDE_STRING_ENUM;
  public classPrice: string = '';
  public priceSeletedMouseMove!: any;
  public lineBlockIcon: string = 'lock';
  private centralizeLine = true;
  private rangeListTable = 250;
  private _rangeFieldsCheetah = 40;
  public activeTable = false;
  get itemArraySelected() {
    return `ANALYSIS=${this.cdStock}:${this.idExchange}`;
  }
  getRangeFieldsCheetah = () =>
    this.typeNearest === 'US' ? 2 : this._rangeFieldsCheetah;

  private priceOld: number = 0;
  public maxVolumeAtPriceTotal = 0;
  private volumeParamsTable!: SubscribeParam | undefined;
  private checkForceResize$ = new Subject<void>();
  private customHeightVirtualScrollViewport$ = new Subject<void>();
  private changeCentralizeLine$ = new Subject<void>();
  private setCentralizeLine$ = new Subject<void>();
  private volumeChannelStream!: RocketStreamRead;
  private onDestroy$ = new Subject<void>();

  constructor(
    private _volumeChannel: VolumeChannel,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.startObservable();
  }

  ngAfterViewInit(): void {
    this.customHeightVirtualScrollViewport();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { cdStock, height, price, showDetail } = changes;
    cdStock?.currentValue && this.initCheetah();
    (height?.currentValue || showDetail) &&
      this.customHeightVirtualScrollViewport$.next();
    price?.currentValue && this.upOrDownPrice();
    price?.currentValue && this.createTable();
  }

  ngOnDestroy(): void {
    this.removeCheetah();
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.volumeChannelStream?.close();
  }

  private startObservable(): void {
    this.checkForceResize$
      .pipe(takeUntil(this.onDestroy$), auditTime(300))
      .subscribe(() => this.checkForceResize());
    this.customHeightVirtualScrollViewport$
      .pipe(takeUntil(this.onDestroy$), auditTime(100))
      .subscribe(() => this.customHeightVirtualScrollViewport());
    this.changeCentralizeLine$
      .pipe(takeUntil(this.onDestroy$), auditTime(10))
      .subscribe(() => this.changeCentralizeLine());
    this.setCentralizeLine$
      .pipe(takeUntil(this.onDestroy$), auditTime(1))
      .subscribe(() => {
        this.centralizeLine = false;
        const height = this.elementRefTable.nativeElement.clientHeight;
        const index = this.tableDefault.findIndex(
          (item) => +item.preco == +this.price
        );
        const view = +(height / 20 / 2).toFixed(0);
        this.viewport?.scrollToIndex(index - view);
        this.cdr.detectChanges();
      });
  }

  private startStreaming = async () => {
    this.volumeChannelStream = await this._volumeChannel.onEvents();
    this.readStream(this.volumeChannelStream.stream, this.channelVolumeHandler);
    this._getCheetahVolume();
  };

  private initCheetah() {
    this.activeTable = false;
    this.removeCheetah();
    this.rangePrice = {};
    this.tableDefault = [];
    this._getCheetahVolume();
    this.createList = true;
    this.centralizeLine = true;
  }

  private removeCheetah() {
    this.reRender = true;
    this.activeTable = false;
    this.centralizeLine = true;
    this.tableDefault = [];
    this.rangePrice = {};
    this.priceOld = 0;
    this.volumeParamsTable &&
      this._volumeChannel.unsubscribe(structuredClone(this.volumeParamsTable));
    this.volumeParamsTable = undefined;
  }

  private _getCheetahVolume() {
    if (!this.volumeChannelStream) {
      this.startStreaming();
      return;
    }
    this.maxVolumeAtPriceTotal = 0;
    this.volumeParamsTable = {
      header: this.refComponent,
      itemsArray: [`ANALYSIS=${this.cdStock}:${this.idExchange}`],
    };
    this._volumeChannel.subscribe(this.volumeParamsTable);
    this.volumeChannelStream.snapshot(this.volumeParamsTable?.itemsArray);
  }

  private createTable() {
    if (this.price && this.createList) {
      this.generateLines(
        parseFloat(this.price.toString()),
        this.getValueUpdatePrice(this.stock)
      );
      this.createList = false;
      this.activeTable = true;
      this.setCentralizeLine(true);
    } else if (this.createList && !this.price) {
      this.createTable();
    }
  }

  generateLines(preco: number, complemento: number): void {
    const array = [];
    const limiteComplementos = this.rangeListTable;

    let complementoAnterior = 0;
    let complementoPosterior = 0;

    array.push({ preco, precoFormatado: this.formatedPrice(preco.toString()) });

    for (let i = 0; i < limiteComplementos; i++) {
      complementoAnterior -= complemento;
      complementoPosterior += complemento;

      const valorAnterior = parseFloat(
        (preco + complementoAnterior).toFixed(2)
      );
      const valorPosterior = parseFloat(
        (preco + complementoPosterior).toFixed(2)
      );

      array.push({
        preco: valorAnterior,
        precoFormatado: this.formatedPrice(valorAnterior.toString()),
      });
      array.unshift({
        preco: valorPosterior,
        precoFormatado: this.formatedPrice(valorPosterior.toString()),
      });
    }
    this.tableDefault = array;
  }

  getValueUpdatePrice(stock: ISearchStock): number {
    if (stock.vlMinPriceIncrement) {
      return stock.vlMinPriceIncrement;
    }
    let value = 0.01;
    if (stock.ds_asset === 'DOL' || stock.ds_asset === 'WDO') {
      value = 0.5;
    } else if (stock.cd_security_type === 'FUT') {
      value = 5;
    }
    return value;
  }
  private processVolumedataPayload = (payload: any) => {
    const volumeData: VolumeData = {
      command: payload.command,
      item: payload.item,
      key: parseFloat(payload.key),
      qtty_buyer: parseFloat(payload.qtty_buyer),
      qtty_direct: parseFloat(payload.qtty_direct),
      qtty_rlp: parseFloat(payload.qtty_rlp),
      qtty_seller: parseFloat(payload.qtty_seller),
      qtty_total: parseInt(payload.qtty_total),
      lastAdd: payload.key === 'EOS',
    };
    if (!volumeData.lastAdd) {
      this.addOrUpdatePrice(volumeData);
    } else {
      this.setCentralizeLine(true);
    }
  };

  private channelVolumeHandler = (data: any) => {
    const payload = data.get(this.itemArraySelected);

    if (!payload) return;
    payload.forEach((data: any) => this.processVolumedataPayload(data));
  };

  private addOrUpdatePrice(volumeData: VolumeData) {
    this.rangePrice[volumeData.key] = {
      ...this.rangePrice[volumeData.key],
      qtty_buyer: volumeData.qtty_buyer,
      qtty_direct: volumeData.qtty_direct,
      qtty_rlp: volumeData.qtty_rlp,
      qtty_seller: volumeData.qtty_seller,
      qtty_total: volumeData.qtty_total,
      key: volumeData.key,
      hovered: false,
      priceFormated: this.formatedPrice(volumeData.key.toString()),
      price: volumeData.key,
      priceString: volumeData.key.toString(),
    };
    this.maxVolumeAtPriceTotal = Math.max(
      this.maxVolumeAtPriceTotal,
      volumeData.qtty_total
    );
    this.cdr.detectChanges();
  }

  private formatedPrice(price: string): string {
    const options: Intl.NumberFormatOptions = {
      maximumFractionDigits: this.tickSizeDenominator,
      minimumFractionDigits: this.tickSizeDenominator,
    };
    return formatterNumber(+price, options);
  }

  private customHeightVirtualScrollViewport(): void {
    this.activeTable = false;
    this.changeCentralizeLine$ && this.changeCentralizeLine$.next();
  }

  private changeCentralizeLine(): void {
    const showDetailHeight = this.showDetail ? 24 : -16;
    const height = Math.min(
      this.elementRefTable.nativeElement.clientHeight || 50,
      this.height - showDetailHeight
    );

    this.virtualScrollViewportHeigth = height;
    this.viewport?.checkViewportSize();
    this.activeTable = true;

    this.setCentralizeLine(true);
    this.cdr.detectChanges();
  }

  public setCentralizeLine(resize: boolean = false): void {
    if (this.tableDefault.length && (this.centralizeLine || resize)) {
      this.setCentralizeLine$.next();
    }
  }

  private upOrDownPrice(): void {
    this.classPrice =
      this.price > this.priceOld
        ? 'bg-feedback-success text-black'
        : 'bg-feedback-error';
    this.priceOld = this.price;
    if (this.isLineBlock) {
      this.setCentralizeLine(true);
    }
  }

  public hoverAtPrice(price: number, status: boolean) {
    if (!isNullOrUndefined(this.rangePrice[price])) {
      this.rangePrice[price].hovered = status;
    }
  }

  public changeBlockOrUnblockLine(): void {
    this.isLineBlock = !this.isLineBlock;
    this.lineBlockIcon = this.isLineBlock ? 'lock' : 'lock_open';
    this.setCentralizeLine(true);
  }

  private checkForceResize() {
    if (
      this.virtualScrollViewportHeigth !=
      this.elementRefTable.nativeElement.clientHeight
    ) {
      this.customHeightVirtualScrollViewport$.next();
    }
  }
}
