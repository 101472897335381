import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlaToastComponent } from './toast.component';
import { FlaToastService } from './service/toast.service';

import { RocketCreateComponentService } from '../services';
import {
  FlaButtonCloseModule,
  FlaIconModule,
  FlaLinksModule,
} from '../components';

@NgModule({
  imports: [CommonModule, FlaIconModule, FlaButtonCloseModule, FlaLinksModule],
  declarations: [FlaToastComponent],
  exports: [FlaToastComponent],
  providers: [RocketCreateComponentService, FlaToastService],
})
export class ToastModule {}
