import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ascendingSort } from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-flow-investors-tab',
  templateUrl: './flow-investors-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowInvestorsTabComponent {
  @Input() width!: number;
  @Input() height!: number;
  @Input() updatePosition: boolean = false;
  @Input() set futuros(futuros: any) {
    if (futuros) {
      this.futures = futuros;
      this.getCards();
    }
  }

  futures!: any;
  public typesFlows: any = [
    {
      label: 'Futuros de IBOV',
      code: 'future_ibov',
      isFuture: true,
      titleChart: 'Evolução Futuros',
    },
    {
      label: 'Futuros de Dólar',
      code: 'future_dolar',
      isFuture: true,
      titleChart: 'Evolução Futuros',
    },
  ];
  public cards: any = {};
  public flowExpanded: any = null;

  constructor(private cdr: ChangeDetectorRef) {}

  private getCards() {
    let dt_reference = 0;
    dt_reference = this.futures.dt_reference;

    this.cards.future_dolar = this.futures.dolar_top_semana
      .map((item: any) => {
        item.cd_type_investor = item.cd_type_investor.replace('Pessoa', 'P.');
        item.sum_delta_volume = item.delta_futuro;
        item.dt_reference = dt_reference;
        return item;
      })
      .sort((a: any, b: any) =>
        ascendingSort(b.cd_type_investor, a.cd_type_investor)
      );

    this.cards.future_ibov = this.futures.ibov_top_semana
      .map((item: any) => {
        item.cd_type_investor = item.cd_type_investor.replace('Pessoa', 'P.');
        item.sum_delta_volume = item.delta_futuro;
        item.dt_reference = dt_reference;
        return item;
      })
      .sort((a: any, b: any) =>
        ascendingSort(b.cd_type_investor, a.cd_type_investor)
      );
    this.cdr.detectChanges();
  }

  public flowToExpand(event: any) {
    this.flowExpanded = event;
    this.cdr.detectChanges();
  }
}
