<div class="vstack gap-3">
  <div class="hstack w-100 justify-content-between gap-3">
    <div class="hstack gap-3 align-items-center">
      <h1 class="fs-4 fw-bold">Leilão</h1>
      <ng-container *ngIf="stocks && stocks.length > 9">
        <button
          type="button"
          class="btn btn-outline-primary btn-sm"
          (click)="toggleDisplayAllStocks()"
        >
          {{ showAllStocks ? 'Ver menos' : 'Ver mais' }}
        </button>
      </ng-container>
    </div>
    <ng-container *ngIf="stocks && stocks.length > 0">
      <button
        type="button"
        class="border border-neutral-strong btn btn-fill-primary btn-sm round-lg text-light"
        (click)="showAllIndexes.emit()"
      >
        <span> {{ indexSelected.cdStock }} </span>
        <span class="material-icons-outlined"> arrow_drop_down </span>
      </button>
    </ng-container>
  </div>

  <ng-container *ngIf="isLoading; else onLoadStocks">
    <div
      [id]="elementdID.loading"
      class="gap-3 vstack align-items-center justify-content-center w-100"
    >
      <span class="fs-6"> Carregando, por favor aguarde.</span>
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-container>

  <ng-template #onLoadStocks>
    <ng-container *ngIf="stocks && stocks.length > 0; else emptyState">
      <div [id]="elementdID.content" style="width: 425px; max-height: 500px">
        <cdk-virtual-scroll-viewport
          appendOnly
          [itemSize]="itemSize"
          [style.min-height.px]="78"
          [style.max-height.px]="410"
          [style.height.px]="scrollHeight"
        >
          <div class="stocks-content">
            <ng-container *cdkVirtualFor="let stock of stocksToDisplay">
              <app-stock-table-view-card-and-chart
                image=""
                [tableView]="view"
                [componentId]="componentID"
                [globalStock]="globalCdStock"
                [displayRemoveStock]="false"
                [auctionInfos]="stock.auctionInfos"
                [name]="stock.cd_stock"
                [cdStock]="stock.cd_stock"
                [companyName]="stock.nome_companhia"
                [lastPrice]="stock.preco_ultimo"
                [variation]="stock.variacao_dia"
                [variationBgColor]="stock.arrow_hex"
                [variationTextColor]="stock.arrow_font_hex"
                [tickSize]="stock.tick_size_denominator"
                [situation]="stock.situacao"
                (stockSelected)="updateGlobalStock.emit(stock)"
              >
              </app-stock-table-view-card-and-chart>
            </ng-container>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #emptyState>
    <div [id]="elementdID.empty" class="vstack gap-2">
      <span class="mb-3 fs-6">
        Nenhum ativo em leilão no índice <b>{{ indexSelected.cdStock }}</b
        >.
      </span>

      <button
        type="button"
        class="btn btn-primary btn-sm"
        (click)="showAllIndexes.emit()"
      >
        Ver outros índices
      </button>

      <button
        type="button"
        class="btn btn-fill-primary btn-sm"
        (click)="closeContent.emit()"
      >
        Fechar
      </button>
    </div>
  </ng-template>
</div>
