import {
  Component,
  Input,
  ElementRef,
  AfterViewInit,
  OnChanges,
  ChangeDetectionStrategy,
  SimpleChanges,
} from '@angular/core';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import { IMultibrokerBar } from 'src/app/core/interface';

@Component({
  selector: 'app-force-chart[data]',
  template: '<canvas></canvas>',
  styleUrls: ['./force-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForceChartComponent implements AfterViewInit, OnChanges {
  @Input() data!: IMultibrokerBar;
  @Input() small: boolean = false;
  @Input() width!: number;
  @Input() toFixed: number = 2;
  private componentHeight: number = 12;
  private chartWidth!: number;
  private ctx!: CanvasRenderingContext2D;
  private previousBuyValue: number | null = null;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    const canvas = this.el.nativeElement.querySelector('canvas');
    this.ctx = canvas.getContext('2d');
    this.makeChartSize();
    this.draw();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { width } = changes;
    if (this.data && this.previousBuyValue !== this.data.buy) {
      this.draw();
    }

    if (!width?.isFirstChange() && width?.currentValue) {
      this.makeChartSize();
      this.draw();
    }
  }

  private draw() {
    if (!this.ctx || !this.data || !this.data.buy) return;
    if (!this.chartWidth) this.makeChartSize();
    const width = this.chartWidth;
    const height = this.componentHeight;
    const buyValue = parseFloat(this.data.buy.toString());
    const buyWidth = width * (buyValue / 100);
    const sellWidth = width - buyWidth;

    this.ctx.clearRect(0, 0, width, height);

    // Draw buy bar
    this.ctx.fillStyle = CHART_COLORS.MULTIBROKER_BUY;
    this.ctx.fillRect(0, 0, buyWidth, height);

    // Draw sell bar
    this.ctx.fillStyle = CHART_COLORS.MULTIBROKER_SELL;
    this.ctx.fillRect(buyWidth, 0, sellWidth, height);

    // Text styling
    this.ctx.font = 'bolder 0.625rem Arial'; // Adjust font-size and font-family as required
    this.ctx.textAlign = 'center';
    this.ctx.textBaseline = 'middle';
    // Draw buy text
    const buyText = `${buyValue.toFixed(this.toFixed)}%`;
    const buyTextX = buyWidth / 2;
    const buyTextY = height / 2;
    this.ctx.fillStyle = CHART_COLORS.NEUTRAL_SMOOTHER; // Choose a suitable color for the text
    this.ctx.fillText(buyText, buyTextX, buyTextY);

    // Draw sell text
    const sellValue = 100 - buyValue;
    const sellText = `${sellValue.toFixed(this.toFixed)}%`;
    const sellTextX = buyWidth + sellWidth / 2;
    const sellTextY = height / 2;
    this.ctx.fillStyle = CHART_COLORS.NEUTRAL_STRONGER; // Choose a suitable color for the text
    this.ctx.fillText(sellText, sellTextX, sellTextY);
  }

  private makeChartSize() {
    const canvas = this.el.nativeElement.querySelector('canvas');
    this.chartWidth = this.width && this.el.nativeElement.offsetWidth;
    const height = this.componentHeight;
    canvas.width = this.chartWidth;
    canvas.height = height;
  }
}
