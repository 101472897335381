import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
// @ts-expect-error omnitheme import
import { Dropdown } from '@omni/omnitheme';
import { randomId } from '@shared/rocket-components/utils';

@Directive({
  selector: '[appDropdownButton]',
})
export class RTDropdownDirective implements OnInit, AfterViewInit, OnChanges {
  @Input() rtDropnOpened: boolean = false;
  @Input() rtDropnAutoClose: boolean | 'inside' | 'outside' = true;
  @Input() rtDropnMouseOvere: boolean = false;
  @Input() rtDropnMouseLeave: boolean = false;
  @Input() rtDropnClose: boolean = false;
  @Input() rtDropnForceClose: boolean = false;
  @Input() rtDropnEventOnClicked: boolean = false;
  @Input() rtDropnFocusInComponent: boolean = false;
  @Input() rtDropnDisabled: boolean = false;
  @Input() rtDropdownToggle: boolean = true;
  @Input() rtDropdownPreventClose: boolean = false;
  @Input() placement!: string;
  @Input() strategy!: string;
  @Output() isOpenClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() clickedOutside: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() rtDropdownClose = new EventEmitter();

  private id: string = randomId('rt_dropdown');
  private dropdown: Dropdown;
  private element!: HTMLElement;
  private isOpen = false;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.element = this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    this.init();
    this.setId();
    this.ariaExpanded();

    if (this.rtDropnOpened) {
      this.dropdown.toggle();
    }
  }

  ngAfterViewInit(): void {
    this.clickOutside();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { rtDropnClose, rtDropnOpened, rtDropnForceClose } = changes;
    (rtDropnClose?.currentValue || rtDropnForceClose?.currentValue) &&
      this.close();
    rtDropnOpened?.currentValue && this.open();
  }

  @HostListener('click', ['$event'])
  public click(event: any): void {
    if (!this.rtDropnMouseOvere) {
      if (this.element.contains(event?.target) && this.isOpen) {
        this.close();
        return;
      }
      this.open();
    }
  }

  @HostListener('mouseover')
  public mouseOverOpen(): void {
    if (this.rtDropnMouseOvere) {
      this.open();
    }
  }

  @HostListener('mouseleave')
  public mouseLeaveOpen(): void {
    if (this.rtDropnMouseLeave) {
      this.close();
    }
  }

  @HostListener('window:keydown.esc')
  public escClose(): void {
    this.close();
  }

  private open(): void {
    if (this.isOpen || this.rtDropnDisabled) {
      return;
    }
    if (!this.dropdown) {
      this.dropdown = Dropdown.getOrCreateInstance(this.element, {
        autoClose: this.rtDropnAutoClose,
        popperConfig: this.getPopperConfig(),
      });
    }
    this.isOpenClose.emit(true);

    this.dropdown.toggle();
    this.isOpen = true;
  }

  private init(): void {
    if (this.element.tagName.toLowerCase().includes('rt')) {
      this.element.classList.add('d-inline-block');
    }

    this.dropdown = Dropdown.getOrCreateInstance(this.element, {
      autoClose: this.rtDropnAutoClose,
      popperConfig: this.getPopperConfig(),
    });

    this.renderer.listen(this.element, 'hidden.bs.dropdown', () => {
      this.rtDropnOpened = !this.rtDropnOpened;
      this.rtDropdownClose.emit();
      this.ariaExpanded();
    });

    this.renderer.listen(this.element, 'shown.bs.dropdown', () => {
      this.rtDropnOpened = !this.rtDropnOpened;
      this.ariaExpanded();
    });
  }

  private clickOutside(): void {
    if (
      this.dropdown &&
      (this.rtDropnAutoClose || this.rtDropnEventOnClicked)
    ) {
      this.renderer.listen('window', 'click', (event: any) => {
        if (event.target.classList.contains('dropdown-submenu')) return;
        if (
          this.element &&
          !this.element.contains(event?.target) &&
          this.isOpen
        ) {
          this.rtDropnAutoClose && this.close();
          if (this.rtDropnEventOnClicked && !this.rtDropnFocusInComponent)
            this.clickedOutside.emit();
        }
      });
    }
  }

  private close() {
    if (this.dropdown && !this.rtDropdownPreventClose) {
      this.isOpenClose.emit(false);
      this.isOpen = false;
      this.dropdown.hide();
      if (this.rtDropnOpened) {
        this.rtDropnOpened = !this.rtDropnOpened;
        this.ariaExpanded();
      }
    }
  }

  private ariaExpanded(): void {
    this.element.setAttribute('aria-expanded', this.rtDropnOpened.toString());
  }

  private setId(): void {
    this.element.id = this.id;
    (this.element.parentElement?.lastChild as HTMLElement).setAttribute(
      'aria-labelledby',
      this.id
    );
  }

  private getPopperConfig() {
    const properConfig: any = {};
    if (this.placement) {
      properConfig['placement'] = this.placement;
    }
    if (this.strategy) {
      properConfig['strategy'] = this.strategy;
    }
    return properConfig;
  }

  public toggleOnTarget(reference: any) {
    if (reference === this.dropdown._config.reference) {
      this.dropdown.toggle();
    } else {
      this.dropdown.hide();
      this.dropdown.dispose();
      this.dropdown = Dropdown.getOrCreateInstance(this.element, {
        autoClose: this.rtDropnAutoClose,
        reference,
      });
      setTimeout(() => {
        this.dropdown.show();
      });
    }
  }
}
