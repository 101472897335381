import { TableService } from './service/table.service';
import { TableComponent } from './table.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableCellComponent } from './parts/cell/table-cell.component';
import { TableRowComponent } from './parts/row/table-row.component';
import { TableHeaderComponent } from './parts/header/table-header.component';
import { RTDropdownModule } from '../dropdown';
import { FlaIconModule } from '../components';
import { RocketCreateComponentService } from '../services';
import { ResizeEventModule } from '../directives';

const COMPONENTS = [
  TableComponent,
  TableCellComponent,
  TableRowComponent,
  TableHeaderComponent,
];
@NgModule({
  declarations: [COMPONENTS],
  imports: [CommonModule, ResizeEventModule, RTDropdownModule, FlaIconModule],
  exports: [COMPONENTS],
  providers: [TableService, RocketCreateComponentService],
})
export class FlaTableModule {}
