import { GENERIC_INTRO_STEPS } from './gerenric.steps';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';

const ONBOARDING_INTRO_STEPS = {
  INITIAL: {
    title: 'Que bom te ver por aqui! 🖖',
    intro:
      'Conheça o RocketTrader, sua nova plataforma de negociação e de acompanhamento de mercado! Vamos lá? <br /> <br/> Use as setas do teclado ou clique no próximo para começar.',
  },
  WORKSPACE: {
    element: '#stepMenuWorkspace',
    title: 'Workspace',
    intro:
      'Aqui ficam suas áreas de trabalho personalizadas: você mesmo pode criar, editar ou excluir os componentes da sua Workspace.',
  },
  COMPONENTS: {
    element: '#stepMenuComponents',
    title: 'Componentes',
    intro:
      'Aqui você seleciona os componentes para adicionar na sua Workspace, seja uma lista de ativos, boleta, gráfico ou book de ofertas. Escolha quantos quiser e distribua da forma que preferir na tela.',
  },
  TOOLS: {
    element: '#stepMenuTools',
    title: 'Ferramentas',
    intro:
      'Neste menu, você encontra as ferramentas para acompanhar o mercado, como notícias, fluxo de investidores, mapa de calor, opções e muitos mais. Basta selecionar e a ferramenta aparecerá na sua Workspace.',
  },
  SIMULADOR: {
    element: '#stepSimulador',
    title: 'Modo Simulador',
    intro:
      'Clique aqui para ativar ou desativar o modo simulador para enviar ordens em ambiente de teste e simular suas estratégias.',
  },
  CONTA: {
    element: '#stepSeletorConta',
    title: 'Seletor de Contas',
    intro:
      'Aqui você consegue alternar suas contas no multibroker ou simuladas, caso o modo simulador estiver ativo.',
  },
  DAYTRADE: {
    element: document.querySelector('app-daytrade-selector > a'),
    title: 'Modo {DayTrade} ou {Swing}',
    intro:
      'Defina aqui qual estratégia você quer operar: <b>{DayTrade}</b> ou <b>{Swing}</b>.<br /><br /> <b>{DayTrade}</b>: negociações que são iniciadas e encerradas no mesmo dia. <br /><br /> <b>{Swing}</b>: negociações que são iniciadas e encerradas em dias diferentes. <br /><br /> Ao clicar em <b>Zerar</b> ou <b>Inverter</b>, será realizada a ação a depender do modo que estiver ativo.',
    position: 'bottom',
    preChange: function () {
      this.element = document.querySelector('app-daytrade-selector > a');
      this.title = DaytradeService.getTextWithTradeMode(this.title);
      this.intro = DaytradeService.getTextWithTradeMode(this.intro);
      this.position = 'bottom';
    },
  },
  STRATEGY: {
    element: 'app-strategy-selector',
    title: 'Estratégia',
    intro:
      'Neste menu, você cria estratégias de negociação para ordens <b>OCO</b> (ordem-cancela-ordem), que consiste no envio de duas ordens de saída, uma para ganhos <i>(Gain)</i> e outra para perdas <i>(Loss)</i>, juntamente com uma ordem de entrada. <br/><br/> Para definir uma estratégia clique na engrenagem. Para ativar uma já definida, basta clicar em cima do botão <b>Estratégia.</b>',
  },
  TOUR_END: {
    title: 'Agora é com você! 🖖',
    intro: `Aproveite as funcionalidades do RocketTrader! Se quiser rever esse tour ou ver a nossa FAQ clique no menu <b>Ajuda.</b>`,
  },
};

export const ONBOARDING_STEPS = [
  ONBOARDING_INTRO_STEPS.INITIAL,
  ONBOARDING_INTRO_STEPS.WORKSPACE,
  ONBOARDING_INTRO_STEPS.COMPONENTS,
  ONBOARDING_INTRO_STEPS.TOOLS,
  ONBOARDING_INTRO_STEPS.SIMULADOR,
  ONBOARDING_INTRO_STEPS.CONTA,
  ONBOARDING_INTRO_STEPS.DAYTRADE,
  ONBOARDING_INTRO_STEPS.STRATEGY,
  GENERIC_INTRO_STEPS.STOCK_SELECTOR(),
  GENERIC_INTRO_STEPS.DOC_LINK(),
  ONBOARDING_INTRO_STEPS.TOUR_END,
];
