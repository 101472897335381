import { Component, Inject, OnInit } from '@angular/core';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';

@Component({
  selector: 'app-documents-modal',
  templateUrl: './documents-modal.component.html',
  styleUrls: ['./documents-modal.component.scss'],
})
export class DocumentsModalComponent extends RocketModalRef implements OnInit {
  document!: any;
  public tooltipLabel = '';

  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: any,
    rocketModalService: RocketModalService
  ) {
    super(rocketModalService);
  }

  ngOnInit() {
    const { idCvmCompany, seq, filename, cnpj } = this.data.event;
    if (this.data.tooltipLabel && this.data.tooltipLabel[0]) {
      this.tooltipLabel = this.formattedLabel(this.data.tooltipLabel[0]);
    } else {
      this.tooltipLabel = '';
    }
    this.document = filename
      ? `${window.location.origin}/go/get-documents/stock/fii/${cnpj}/${filename}`
      : `${window.location.origin}/go/get-documents/fundamental/${idCvmCompany}/${seq}`;
  }

  private formattedLabel(text: string): string {
    if (!text) return '';
    const value = text.split('">');
    if (value.length === 1) return value.pop()!;
    const valueSplit = value[1];
    return valueSplit.split('</').shift()!;
  }
}
