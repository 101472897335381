<div
  class="round bg-neutral-strong position-absolute"
  [style.width]="'200px'"
  [style.padding]="'10px'"
  [style.top.px]="position.top"
  [style.left.px]="position.left"
  [style.z-index]="9999"
  [style.display]="isTour ? 'none' : 'block'"
  (mouseleave)="onFocusOut()"
>
  <div class="list-group list-group-flush">
    <div class="header-context-menu">
      <span>Book</span>
    </div>

    <div id="BOOK_CONTEXT_TOGGLE_VISIBILITY">
      <ng-container *ngFor="let item of items; let index = index">
        <ng-container *ngIf="index < 2">
          <button
            type="button"
            class="list-group-item list-group-item-action context-item-button"
            (click)="onClick(item)"
          >
            <fla-icon
              size="icon-size-tiny"
              css="position-absolute left-0 text-neutral-medium fs-4"
              [name]="item?.enable ? 'visibility_off' : 'visibility'"
            ></fla-icon>
            {{ item?.name }}
          </button>
        </ng-container>
      </ng-container>

      <ng-container *ngFor="let item of items; let index = index">
        <ng-container *ngIf="index >= 2">
          <hr *ngIf="item.divider" class="separator-context-menu" />
          <button
            [id]="item.elementID"
            type="button"
            class="list-group-item list-group-item-action context-item-button"
            (click)="onClick(item)"
          >
            <fla-icon
              size="icon-size-tiny"
              css="position-absolute left-0 text-neutral-medium fs-4"
              [name]="item.enable ? 'visibility_off' : 'visibility'"
            ></fla-icon>
            {{ item.name }}
          </button>
        </ng-container>
      </ng-container>
    </div>

    <hr class="separator-context-menu" />
    <div>
      <button
        type="button"
        class="list-group-item list-group-item-action border-none fs-6 context-item-button"
        disabled
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="refresh"
        ></fla-icon>
        Restaurar
      </button>
      <button
        type="button"
        class="list-group-item list-group-item-action border-none fs-6 context-item-button"
        (click)="onTour()"
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="help_outline"
        ></fla-icon>
        Ajuda
      </button>
    </div>
  </div>
</div>
