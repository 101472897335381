import { Pipe, PipeTransform } from '@angular/core';
import { bigValueFormatter } from '../../utils';

@Pipe({ name: 'flaBigNumber' })
export class FlaBigNumberPipe implements PipeTransform {
  transform(value: number, decimalMarker: number = 2, args2 = false) {
    if (args2 && (value == 0 || value == undefined)) {
      return '-';
    }
    return bigValueFormatter(value, {
      maximumFractionDigits: decimalMarker,
      minimumFractionDigits: decimalMarker,
    });
  }
}
