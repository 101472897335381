import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvalidBrowserComponent } from './invalid-browser.component';
import { FlaIconModule } from '@shared/rocket-components/components';
import { LogoModule } from '../logo/logo.module';
import { InvalidBrowserRoutes } from './invalid-browser.routing';
import { DeviceValidatorService } from '@shared/services/device-validator.service';

@NgModule({
  imports: [
    CommonModule,
    LogoModule,
    FlaIconModule,
    InvalidBrowserRoutes
  ],
  declarations: [InvalidBrowserComponent],
  providers: [
    DeviceValidatorService
  ]
})
export class InvalidBrowserModule { }
