<td
  scope="row"
  [class]="css + ' table-cell ' + alignText"
  [ngClass]="{
    'sticky-col': fixedColumn,
    'table-cell-action': hasChildren
  }"
  [ngStyle]="{ width: width, height: height }"
  [attr.aria-expanded]="expanded"
  [attr.colspan]="colspan"
  [attr.visible]="visible"
>
  <ng-container *ngIf="hasChildren; else elseHasChildren">
    <button
      #btnElement
      class="btn btn-icon remove-btn-hover"
      type="button"
      [ngClass]="cssContent"
      [id]="idCellBtn"
      [attr.aria-expanded]="expanded"
      [attr.data-bs-parent]="idParentRow"
      (click)="onClick(btnElement)"
    >
      <i
        class="icons text-neutral-medium pe-none"
        [ngClass]="{
          rotate: expanded,
          'default-position': !expanded
        }"
      >
        navigate_next
      </i>
      {{ text }}
    </button>
  </ng-container>
  <ng-template #elseHasChildren>
    <span
      *ngIf="text !== null && text !== undefined"
      class="text-white pl-2"
      [ngClass]="[cssContent]"
    >
      {{ text }}
    </span>
  </ng-template>

  <ng-content></ng-content>
</td>
