import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { Dictionary } from '@core/models';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { GROUP_TOOLS, TOOLS } from './tiger-chart-tools.const';
import { TOOLS_ENUM } from './tiger-chart-tools.enum';
import {
  TIGER_CHART_GROUP_TOOL,
  TIGER_CHART_GROUP_TOOL_EMOJI,
  TIGER_CHART_GROUP_TOOL_OPTIONS,
  TIGER_CHART_TOOL,
} from './tiger-chart-tools.interface';
import Twemoji from 'twemoji';
import { Subject, Subscription, debounceTime, filter } from 'rxjs';
import { RocketModalService } from '@shared/rocket-components/components';
import { HelpDrawsComponent } from './help-draws/help-draws.component';
import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';

@Component({
  selector: 'app-tiger-chart-tools',
  templateUrl: './tiger-chart-tools.component.html',
  styleUrls: ['./tiger-chart-tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TigerChartToolsComponent implements AfterViewInit, OnDestroy {
  @Input() componentRef!: string;
  @Input() componentReference!: string;
  @Input() defaultCursor!: string;
  @Input() selectedTool!: TIGER_CHART_TOOL;
  maxHeightBoxIcon: number = 0;
  groupTools = structuredClone(GROUP_TOOLS);
  toolsEnum = structuredClone(TOOLS_ENUM);
  dictEmoji = {
    'Smileys & Emotion': 'smiles & pessoas',
    'People & Body': 'smiles & pessoas',
    'Animals & Nature': 'animais & natureza',
    'Food & Drink': 'comida & bebida',
    Activities: 'atividade',
    'Travel & Places': 'viagens & lugares',
    Objects: 'objetos',
    Symbols: 'símbolos',
    Flags: 'bandeiras',
  };
  groupEmojis = new Dictionary<
    Array<{ code: string; emoji: any; url: string; svg: string }>
  >();
  chunks: Array<any> = [];
  private _useThisTool$ = new Subject<{
    group: TIGER_CHART_GROUP_TOOL;
    tool: TIGER_CHART_TOOL;
  }>();
  private useThisTool$!: Subscription;
  private disabledLock$!: Subscription;

  constructor(
    private stockChartService: StockChartService,
    private cdr: ChangeDetectorRef,
    private rocketModalService: RocketModalService,
    private drawToolsService: DrawToolsService
  ) {
    this._useThisTool$.pipe(debounceTime(100)).subscribe((data) => {
      const { group, tool } = data;
      if (
        group.active &&
        group.selectedTool.codTool === tool.codTool &&
        tool.group != TOOLS_ENUM.LOCKER &&
        tool.group != TOOLS_ENUM.VISIBLE
      ) {
        group.active = false;
        this.stockChartService.toolSelected$.next({
          componentRef: this.componentRef,
          tool: null,
        });
      } else {
        group.selectedTool = tool;
        group.active = group.selectedTool.activeIcon ? !group.active : true;
        this.stockChartService.toolSelected$.next({
          componentRef: this.componentRef,
          tool,
        });
      }
      this.cdr.detectChanges();
    });

    this.disabledLock$ = this.drawToolsService.isLocked$
    .pipe(filter((isLocked) => !isLocked))
    .subscribe(() => {
      const lockerKey = this.groupTools.findIndex((group: TIGER_CHART_GROUP_TOOL) => (
        group.title === TOOLS_ENUM.LOCKER
      ))
      this.groupTools[lockerKey].active = false
      this.cdr.detectChanges();
    })
  }

  private constructTwemojiURL(icon: string, options: any) {
    const extFolder = options.folder === 'svg' ? 'svg' : '';
    const size = extFolder === '' ? options.size : '';
    return ''.concat(
      `https://cdn.jsdelivr.net/gh/twitter/twemoji@v14.0.2/assets/${extFolder}`,
      size,
      '/',
      icon,
      options.ext
    );
  }

  ngAfterViewInit() {
    this.maxHeightBoxIcon = parseFloat(
      document
        .getElementById(`${this.componentRef}_2D`)!
        .getAttribute('height')!!
    );
    if (this.defaultCursor) {
      const group = TOOLS.get(TOOLS_ENUM.CURSORS)!!;
      const defaultCursor = group.find(
        (item: any) => item.codTool === this.defaultCursor
      );
      defaultCursor && (this.groupTools[0].selectedTool = defaultCursor);
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.useThisTool$ && this.useThisTool$.unsubscribe();
    this.disabledLock$ && this.disabledLock$.unsubscribe();
  }

  useThisTool(
    typeSelection: string,
    event: any,
    group: TIGER_CHART_GROUP_TOOL,
    tool: TIGER_CHART_TOOL,
    stopPropagation: boolean = true,
    optionClicked?: TIGER_CHART_GROUP_TOOL_OPTIONS
  ) {
    if (tool.codTool === `${TOOLS_ENUM.ICONS}_ICONS`) {
      this.makeGroupsEmoji();
      this.selectGroupEmoji(typeSelection, event, group, tool, optionClicked);
      return;
    }
    if (tool.group === TOOLS_ENUM.HELP) {
      this.openHelpModal();
      event.stopPropagation();
      return;
    }
    if (stopPropagation) {
      event.stopPropagation();
    }
    const hashDeselect: any = {
      [TOOLS_ENUM.CURSORS]: true,
      [TOOLS_ENUM.LOCKER]: true,
      [TOOLS_ENUM.VISIBLE]: true,
      [TOOLS_ENUM.TRASH]: true,
    };
    if (tool.group === 'Cursores') {
      let isLine = false;
      if (
        tool.codTool === `${TOOLS_ENUM.CURSORS}_CRUZ` ||
        tool.codTool === `${TOOLS_ENUM.CURSORS}_PONTO`
      ) {
        isLine = true;
      }
      this.stockChartService.enableOrDisableCursorModifier({
        cursor: tool.codTool,
        isLine,
        refComponent: this.componentReference,
      });
    }
    if (!group.active) {
      this.resetActivesTool();
    } else if (hashDeselect[group.title]) {
      this.deSelectTool();
    }
    this._useThisTool$.next({ tool, group });
  }

  deSelectTool(toolEnum?: TOOLS_ENUM) {
    this.stockChartService.toolSelected$.next({
      componentRef: this.componentRef,
      tool: null,
      toolEnum,
    });
    this.resetActivesTool(toolEnum);
    this.cdr.detectChanges();
  }

  selectGroupEmoji(
    typeSelection: string,
    event: any,
    group: TIGER_CHART_GROUP_TOOL,
    tool: TIGER_CHART_TOOL,
    optionClicked?: TIGER_CHART_GROUP_TOOL_OPTIONS
  ) {
    if (typeSelection === 'SELECTED') {
      return;
    }
    const optionPreviousIndex = group.options?.findIndex(
      (option) => option.active
    );
    const optionPrevious = group.options!![optionPreviousIndex!!]!!;
    optionPrevious!!.active = false;
    optionClicked!!.active = true;
    const element = document.getElementById(
      'virtualScrollEmojis'
    ) as HTMLElement;
    if (!element) {
      return;
    }
    const elementClicked = document.getElementById(
      optionClicked!!.group
    ) as HTMLElement;
    if (elementClicked) {
      this.goToElement(optionClicked!!.group);
    } else {
      element.scroll({
        top: optionClicked!.start!!,
        behavior: 'smooth',
      });
      this.goToElement(optionClicked!!.group);
    }
    event.stopPropagation();
  }

  private goToElement(id: string) {
    const elementClicked = document.getElementById(id) as HTMLElement;
    if (elementClicked) {
      setTimeout(() => {
        elementClicked.scrollIntoView({ behavior: 'smooth' });
      }, 100);
      setTimeout(() => {
        elementClicked.scrollIntoView({ behavior: 'smooth' });
      }, 350);
    } else {
      setTimeout(() => {
        this.goToElement(id);
      }, 200);
    }
  }

  private resetActivesTool(toolEnum?: TOOLS_ENUM) {
    this.groupTools
      .filter((group: any) => {
        const validity = toolEnum
          ? group.title === toolEnum
          : true && !group.selectedTool.activeIcon;
        return group.active && validity;
      })
      .map((group: any) => (group.active = false));
    this.cdr.detectChanges();
  }

  private arrayChunk(arr: Array<any>, len: number) {
    const chunks = [],
      n = arr.length;
    let i = 0;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  }

  show(
    last: boolean,
    group: TIGER_CHART_GROUP_TOOL,
    option: TIGER_CHART_GROUP_TOOL_OPTIONS
  ) {
    if (last) {
      const optionIndex = group.options!!.findIndex(
        (op) => op.group === option.group
      );
      if (optionIndex >= 0 && optionIndex < group.options!!.length - 1) {
        group.options!![optionIndex + 1].inView = true;
      }
    }
  }

  selectEmoji(
    emoji: { code: string; emoji: any; url: string; svg: string },
    group: TIGER_CHART_GROUP_TOOL
  ) {
    if (!group.active) {
      this.resetActivesTool();
    }
    group.active = true;
    group.selectedTool.selectedIcon = emoji;
    group.selectedTool.svgString = `
    <svg style="overflow:auto;" viewBox="0 0 $WIDTH $HEIGHT" id="SVG_$CODTOOL">
      <image x="0" y="0" id="IMAGE_$CODTOOL" xlink:href="${emoji.svg}" src="" width="50" height="50"/>
    </svg>`;
    this.stockChartService.toolSelected$.next({
      componentRef: this.componentRef,
      tool: group.selectedTool,
    });
    this.cdr.detectChanges();
  }

  private makeGroupsEmoji() {
    if (this.chunks.length) return;
    // eslint-disable-next-line
    let emojis = require('emoji.json') as TIGER_CHART_GROUP_TOOL_EMOJI[];
    let actualGroup = '';
    emojis.forEach((emoji) => {
      if (!(emoji.group in this.dictEmoji)) {
        return;
      }
      if (emoji.group === 'Smileys & Emotion' && emoji.subgroup === 'emotion') {
        return;
      }
      if (
        emoji.group === 'People & Body' &&
        emoji.codes.split(' ').length > 1
      ) {
        return;
      }
      const key = this.dictEmoji[emoji.group as keyof typeof this.dictEmoji];
      if (!this.groupEmojis.has(key)) this.groupEmojis.set(key, []);
      const emojis = this.groupEmojis.get(key)!!;
      emojis.push({ code: emoji.codes, emoji: emoji.char, url: '', svg: '' });
      this.groupEmojis.set(key, emojis);
    });
    this.groupEmojis.keys().forEach((key) => {
      const urls: any = {};
      this.groupEmojis.get(key)?.forEach((emoji) => {
        Twemoji.parse(emoji.emoji, {
          callback: (icon: string, options: any) => {
            const url = this.constructTwemojiURL(icon.toLowerCase(), options);
            const svg = this.constructTwemojiURL(icon.toLowerCase(), {
              ...options,
              ext: '.svg',
              folder: 'svg',
            });
            if (url in urls) {
              return false;
            }
            if (icon == '') {
              return false;
            }
            urls[url] = true;
            emoji.url = url;
            emoji.svg = svg;
            return icon;
          },
        });
      });
    });
    let height = 0;
    const groupTool = this.groupTools.find(
      (tool: any) => tool.title === TOOLS_ENUM.ICONS
    );
    this.groupEmojis.keys().forEach((key) => {
      const filtered = this.groupEmojis.get(key)!.filter((emoji) => {
        return emoji.url != '';
      })!!;
      this.groupEmojis.delete(key);
      this.groupEmojis.set(key, filtered);
      if (actualGroup != key) {
        this.chunks.push([
          {
            isTitle: true,
            title: key,
          },
        ]);
        actualGroup = key.toString();
      }
      const chunked = this.arrayChunk(filtered, 9);
      this.chunks.push(...chunked);
      if (groupTool) {
        const findGroupEmoji = groupTool.options?.find(
          (groupEmoji: any) => groupEmoji.group === key
        );
        if (findGroupEmoji) {
          findGroupEmoji.start = height;
          findGroupEmoji.end = findGroupEmoji.start + chunked.length * 24;
        }
      }
      height += chunked.length * 24;
    });
    this.cdr.detectChanges();
  }

  clickArrow(
    typeSelection: string,
    event: any,
    group: TIGER_CHART_GROUP_TOOL,
    tool: TIGER_CHART_TOOL,
    optionClicked?: TIGER_CHART_GROUP_TOOL_OPTIONS
  ) {
    if (tool.codTool === `${TOOLS_ENUM.ICONS}_ICONS`) {
      this.makeGroupsEmoji();
      this.selectGroupEmoji(typeSelection, event, group, tool, optionClicked);
      return;
    }
    if (tool.group === TOOLS_ENUM.ICONS) {
      this.openHelpModal();
      return;
    }
  }

  private openHelpModal() {
    this.rocketModalService.open(HelpDrawsComponent, {
      data: {},
      backdrop: true,
      keyboard: true,
    });
  }
}
