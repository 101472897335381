import {
  TigerChartIndicatorParabolicSAROptions,
  TTigerChartIndicatorCreateOptions,
} from './indicators.types';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { ParabolicSARBase } from './base/parabolic-sar-base';

export class TigerChartIndicatorParabolicSAR extends ParabolicSARBase {
  constructor(options: TigerChartIndicatorParabolicSAROptions) {
    super(options);
  }

  override create(
    options: TTigerChartIndicatorCreateOptions
  ): BaseRenderableSeries[] {
    this.updatePoints();
    //this.points.output = this.sar(this.acceleration, this.maximum, this.data.vl_high, this.data.vl_low, this.data.id_point.length-1)
    return super.create(options);
  }

  override updatePoints(): void {
    this.points = this.service.call('SAR', {
      high: this.data.vl_high,
      low: this.data.vl_low,
      acceleration: this.acceleration,
      maximum: this.maximum
    });
  }

  override updateSettings() {
    this.points = this.service.call('SAR', {
      high: this.data.vl_high,
      low: this.data.vl_low,
      acceleration: this.acceleration,
      maximum: this.maximum
    });

    super.updateSettings();
  }



  sar(optInAcceleration: number, optInMaximum: number, inHigh: number[], inLow: number[], endIdx: number): number[] {
    let outIdx = 0;
    let startIdx = 1;
   /* Write the first SAR. */
    let todayIdx = startIdx;
    let sar = 0;
    let ep = 0;
    let prevLow = 0
    let prevHigh = 0
    let isLong = true;
    let outReal = [];
    let newHigh = inHigh[todayIdx-1];
    let newLow  = inLow[todayIdx-1];
    let af = optInAcceleration;
    if( af > optInMaximum )
      af = optInAcceleration = optInMaximum;
    ep  = inLow[0];
  /*let up_trend_high = inHigh[0];
  let down_trend_low = inLow[0];*/
    sar = newLow;
   /* Cheat on the newLow and newHigh for the
    * first iteration.
    */
    newLow  = inLow[0];
    newHigh = inHigh[0];
    let reversal = false;
    const indxs = [472,473,474,475, 476]
    let direction = 0;
    outReal = [...inHigh];

    while( todayIdx <= endIdx )
    {
        reversal = false;
        prevLow  = newLow;
        prevHigh = newHigh;
        newLow  = inLow[todayIdx];
        newHigh = inHigh[todayIdx];   
        
        if(indxs.indexOf(outIdx) != -1) {
          console.log("isLong", isLong)
          console.log("newLow", newLow)
          console.log("newHigh", newHigh)
          console.log("index", todayIdx)
          console.log("SAR", sar)
          
        }

        if( isLong )
        {  
          sar = sar + (af *(ep - sar))
          if(indxs.indexOf(outIdx) != -1) {
            console.log("AF", af)
            console.log("sar + (0.02 *(up_trend_high - sar))", sar + (0.02 *(ep - sar)))
            console.log("NEWSAR", sar)
            console.log("newLow <= sar", newLow <= sar)
            console.log("newHigh > up_trend_high", newHigh > ep)
            console.log("newHigh >= sar", newHigh >= sar)
            console.log("newLow < down_trend_low", newLow < ep)
          }
          /* Switch to short if the low penetrates the SAR value. */
          
          if( newLow <= sar )
          {
              /* Switch and Overide the SAR with the ep */
              
              sar = ep;
              reversal = true;
              ep = newLow;

              /* Make sure the overide SAR is within
              * yesterday's and today's range.
              */
              /*if( sar < prevHigh )
                sar = prevHigh;            
              if( sar < newHigh )
                sar = newHigh;*/

              /* Output the overide SAR  */
              //outReal[outIdx++] = sar;

              /* Adjust af and ep */
              af = optInAcceleration;
              //ep = newLow;
  
              /* Calculate the new SAR */
              //sar = sar + af * (ep - sar);

              /* Make sure the new SAR is within
              * yesterday's and today's range.
              */
              /*if( sar < prevHigh )
                sar = prevHigh;            
              if( sar < newHigh )
                sar = newHigh;*/
          }
          else
          {
              /* No switch */

              /* Output the SAR (was calculated in the previous iteration) */
              //outReal[outIdx++] = sar;
  
              /* Adjust af and ep. */
              if( newHigh > ep )
              {
                ep = newHigh;
                af += optInAcceleration;
                if( af > optInMaximum )
                    af = optInMaximum;
              }

              /* Calculate the new SAR */
              //sar = sar + af * (ep - sar);

              /* Make sure the new SAR is within
              * yesterday's and today's range.
              */
              /*if( sar > prevLow )
                sar = prevLow;            
              if( sar > newLow )
                sar = newLow;*/
                
                let low1 = inLow[todayIdx - 1]
                      let low2 = inLow[todayIdx - 2]
                      if(indxs.indexOf(outIdx) != -1) {
                        console.log("NEWAF", af);
                        console.log("low1", low1)
                        console.log("low2", low2)
                        console.log("low2 < sar", low2 < sar)
                        console.log("low1 < sar", low1 < sar)
                      }
                      if (low2 < sar)
                          sar = low2
                      else if (low1 < sar)
                          sar = low1
          }
        }
        else
        {
          sar = sar - (af * (sar - ep))
          if(indxs.indexOf(outIdx) != -1) {
            console.log("AF", af)
            console.log("sar + (0.02 *(up_trend_high - sar))", sar + (0.02 *(ep - sar)))
            console.log("NEWSAR", sar)
            console.log("newLow <= sar", newLow <= sar)
          console.log("newHigh > up_trend_high", newHigh > ep)
          console.log("newHigh >= sar", newHigh >= sar)
          console.log("newLow < down_trend_low", newLow < ep)
          }
          
          /* Switch to long if the high penetrates the SAR value. */
          if( newHigh >= sar )
          {
              /* Switch and Overide the SAR with the ep */
              sar = ep;
              reversal = true;
              /* Make sure the overide SAR is within
              * yesterday's and today's range.
              */
              /*if( sar > prevLow )
                sar = prevLow;            
              if( sar > newLow )
                sar = newLow;*/

              /* Output the overide SAR  */
              //outReal[outIdx++] = sar;

              /* Adjust af and ep */
              af = optInAcceleration;
              ep = newHigh;

              /* Calculate the new SAR */
              //sar = sar + af * (ep - sar);

              /* Make sure the new SAR is within
              * yesterday's and today's range.
              */
              /*if( sar > prevLow )
                sar = prevLow;            
              if( sar > newLow )
                sar = newLow;*/
          }
          else
          {
              /* No switch */

              /* Output the SAR (was calculated in the previous iteration) */
              //outReal[outIdx++] = sar;

              /* Adjust af and ep. */
              if( newLow < ep )
              {
                ep = newLow;
                af += optInAcceleration;
                if( af > optInMaximum )
                    af = optInMaximum;
              }

              /* Calculate the new SAR */
              //sar = sar + af * (ep - sar);

              /* Make sure the new SAR is within
              * yesterday's and today's range.
              */
              /*if( sar < prevHigh )
                sar = prevHigh;            
              if( sar < newHigh )
                sar = newHigh;*/
                let high1 = inHigh[todayIdx - 1]
                      let high2 = inHigh[todayIdx - 2]
                      if(indxs.indexOf(outIdx) != -1) {
                        console.log("NEWAF", af);
                        console.log("low1", high1)
                        console.log("low2", high2)
                        console.log("low2 < sar", high2 > sar)
                        console.log("low1 < sar", high1 > sar)
                      }
                      if (high2 > sar)
                          sar = high2
                      else if (high1 > sar)
                          sar = high1
          }
        }
        isLong = isLong != reversal
        outReal[todayIdx] = sar;
        todayIdx++;
    }
    console.log("OUT",outReal)
    return outReal;
    }
}
