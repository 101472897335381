import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'fla-card-calender[title][src][body]',
  templateUrl: './card-calender.component.html',
})
export class FlaCardCalenderComponent {
  @ViewChild(TemplateRef) content!: TemplateRef<any>;
  @Input() title!: string;
  @Input() body!: string;
  @Input() src!: string;
  @Input('css') customClass: string = '';
}
