import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { HighchartsService } from './services/highcharts.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-heatmap-chart',
  template: `
    <div [id]="refComponent" class="position-relative w-100 h-100"></div>
  `,
  styleUrls: ['./heatmap-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeatmapChartComponent implements OnChanges, OnDestroy {
  @Output() public chartCallback: EventEmitter<void> = new EventEmitter();
  @Output() public chartLoaded: EventEmitter<Highcharts.Chart> =
    new EventEmitter();

  @Input() refComponent!: string;
  @Input() width!: number;
  @Input() height!: number;
  @Input() useVolumeInMoversValue: boolean = false;

  // Confs
  @Input() heatmapConfigs!: any;

  private _chart!: Highcharts.Chart;
  private _initilizeChartSubject = new Subject<void>();
  private _resizeChartSubject = new Subject<{
    width: SimpleChange;
    height: SimpleChange;
  }>();

  constructor(private _highchartsService: HighchartsService) {
    this._initilizeChartSubject
      .pipe(debounceTime(10))
      .subscribe(() => this._initGraph());
    this._resizeChartSubject
      .pipe(debounceTime(10))
      .subscribe((params) => this._resizeChart(params));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { refComponent, width, height, useVolumeInMoversValue } = changes;
    if (refComponent?.currentValue || useVolumeInMoversValue?.currentValue)
      this._initilizeChartSubject.next();
    this._updateGraphSize(width, height);
  }

  ngOnDestroy(): void {
    if (this._chart) this._chart.destroy();
    this._initilizeChartSubject.unsubscribe();
    this._resizeChartSubject.unsubscribe();
  }

  private _updateGraphSize(width: SimpleChange, height: SimpleChange) {
    if (!this._chart) return;
    this._resizeChartSubject.next({ width, height });
  }

  private _initGraph() {
    const chart = this._highchartsService.initGraph(
      this.refComponent,
      () => this.heatmapConfigs,
      () => this.chartCallback.emit(),
      this.useVolumeInMoversValue
    );
    this.chartLoaded.emit(chart);
    this._chart = chart;
  }

  private _resizeChart(params: {
    width: SimpleChange;
    height: SimpleChange;
  }): void {
    const { width, height } = params;
    if (width?.currentValue && height?.currentValue) {
      this._chart.setSize(width.currentValue, height.currentValue - 40, false);
      return;
    }
    if (width?.currentValue)
      this._chart.setSize(width.currentValue, this.height - 40, false);
    if (height?.currentValue)
      this._chart.setSize(this.width, height.currentValue - 40, false);
  }
}
