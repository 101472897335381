import {
  CategoryAxis,
  CategoryCoordinateCalculator,
  TWebAssemblyChart,
  VerticalLineAnnotation,
} from 'scichart';
import { TIGER_INTERVAL_ENUM } from '../enum';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
export const ID_SEPARATOR_DATE_ANNOTATION = 'ID_SEPARATOR_DATE_ANNOTATION';

export class SeparatorDateAnnotationService {
  constructor(
    private _base: TWebAssemblyChart,
    private themeService: ThemePreferencesService
  ) {}

  set base(base: TWebAssemblyChart) {
    this._base = base;
  }

  changeColorAnnotationTheme() {
    (
      this._base.sciChartSurface.annotations
        .asArray()
        .filter(
          (annotation) => annotation.id === ID_SEPARATOR_DATE_ANNOTATION
        ) as VerticalLineAnnotation[]
    ).forEach((annotation) => {
      annotation.stroke = this.getColorStroke();
    });
  }

  addSeparatorDate(
    xAxis: CategoryAxis,
    idPoints: number[],
    interval: TIGER_INTERVAL_ENUM,
    addNewPoint: boolean
  ) {
    const calculator =
      xAxis.getCurrentCoordinateCalculator() as CategoryCoordinateCalculator;
    if (addNewPoint) {
      const length = idPoints.length;
      const lastPoint = idPoints[length - 1];
      const preLastPoint = idPoints[length - 2];
      if (this.validityDate(lastPoint, preLastPoint, interval)) {
        const idx = calculator.transformDataToIndex(lastPoint);
        this._base.sciChartSurface.annotations.add(
          this.generateAnnotation(idx)
        );
      }
      return;
    }
    let separatorDate!: Date;
    const arr: number[] = [];
    idPoints.forEach((date) => {
      if (
        separatorDate &&
        this.validityDate(separatorDate.getTime(), date, interval)
      ) {
        arr.push(calculator.transformDataToIndex(date));
      }
      separatorDate = new Date(date);
    });
    if (arr.length) {
      arr.forEach((a) => {
        this._base.sciChartSurface.annotations.add(this.generateAnnotation(a));
      });
    }
  }

  private validityDate(
    firstNDate: number,
    secondNDate: number,
    interval: TIGER_INTERVAL_ENUM
  ) {
    const funcs: any = {
      [TIGER_INTERVAL_ENUM.ONE_MINUTE]: () =>
        this.transformAndValidityDate(firstNDate, secondNDate),
      [TIGER_INTERVAL_ENUM.TWO_MINUTE]: () =>
        this.transformAndValidityDate(firstNDate, secondNDate),
      [TIGER_INTERVAL_ENUM.FIVE_MINUTE]: () =>
        this.transformAndValidityDate(firstNDate, secondNDate),
      [TIGER_INTERVAL_ENUM.TEN_MINUTE]: () =>
        this.transformAndValidityDate(firstNDate, secondNDate),
      [TIGER_INTERVAL_ENUM.FIFTEEN_MINUTE]: () =>
        this.transformAndValidityDate(firstNDate, secondNDate),
      [TIGER_INTERVAL_ENUM.THIRTY_MINUTE]: () =>
        this.transformAndValidityDate(firstNDate, secondNDate),
      [TIGER_INTERVAL_ENUM.ONE_HOUR]: () =>
        this.transformAndValidityDate(firstNDate, secondNDate),
      [TIGER_INTERVAL_ENUM.ONE_DAY]: () =>
        this.transformAndValidityDate(firstNDate, secondNDate, true),
      [TIGER_INTERVAL_ENUM.THIS_WEEK]: () =>
        this.transformAndValidityDate(firstNDate, secondNDate, true),
      [TIGER_INTERVAL_ENUM.ONE_MONTH]: () =>
        this.transformAndValidityDate(firstNDate, secondNDate, true, true),
    };
    const func = funcs[interval];
    if (func) {
      return func();
    }
    return false;
  }

  private transformAndValidityDate(
    firstNDate: number,
    secondNDate: number,
    validityMonth: boolean = false,
    validityYear: boolean = false
  ): boolean {
    const firstDate = new Date(firstNDate),
      secondDate = new Date(secondNDate);
    if (validityMonth) {
      firstDate.setDate(1);
      secondDate.setDate(1);
    }
    if (validityYear) {
      firstDate.setMonth(0);
      secondDate.setMonth(0);
    }
    firstDate.setHours(0, 0, 0, 0);
    secondDate.setHours(0, 0, 0, 0);
    return firstDate.getTime() != secondDate.getTime();
  }

  private generateAnnotation(idx: number) {
    return new VerticalLineAnnotation({
      x1: idx - 0.5,
      id: ID_SEPARATOR_DATE_ANNOTATION,
      stroke: this.getColorStroke(),
      strokeThickness: 2,
      strokeDashArray: [2, 2],
    });
  }

  deleteAnnotations() {
    const annotations = this._base.sciChartSurface.annotations
      .asArray()
      .filter((annotation) => annotation.id === ID_SEPARATOR_DATE_ANNOTATION);
    annotations.forEach((annotation) => {
      this._base.sciChartSurface.annotations.remove(annotation);
    });
  }

  private getColorStroke() {
    return this.themeService.isDarkTheme() ? '#e7eaee' : '#23242f';
  }
}
