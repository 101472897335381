import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs';
import { RestService } from '@shared/services/rest/rest.service';
import { LogoutService } from '@shared/services/api/authentication/v2/logout.service';
import { DisconnectService } from '../v1/disconnect.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService extends RestService {
  override _url: string = 'api/authentication/v2/session';
  disconnectService = inject(DisconnectService);
  constructor(http: HttpClient, private logout: LogoutService) {
    super(http);
  }

  verifyHealth = () =>
    this.post<any>('alive', {})
      .pipe(map((res: any) => res.data))
      .subscribe((data) => {
        if (!data.result) {
          this.logout.exec(
            'ALIVE FALSE',
            'Ops! Parece que sua sessão expirou. Por favor, faça login novamente para continuar.'
          );
          this.disconnectService.performDisconnect(
            'ALIVE FALSE - sessão expirou'
          );
        }
      });
}
