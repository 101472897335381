import { Routes, RouterModule } from '@angular/router';
import { StockChartComponent } from './stock-chart.component';

const routes: Routes = [
  {
    path: '',
    component: StockChartComponent,
  },
];

export const StockChartRoutes = RouterModule.forChild(routes);
