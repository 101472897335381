import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  INDICATORS_COMPANY_TYPE,
  INDICATOR_PERIOD,
} from '@shared/components/business-profile/indicators/enum/indicators.enum';
import {
  IBalanceSheet, IConsolidatedData,
} from '@shared/components/business-profile/indicators/interface/indicators.interface';
import { IndicatorsGroupsCacheService } from '@shared/services/core/cache/indicators-groups-cache.service';
import { IndicatorsHistoriesCacheService } from '@shared/services/core/cache/indicators-histories-cache.service';
import { IndicatorsTableCacheService } from '@shared/services/core/cache/indicators-table-cache.service';
import { InfoIndicatorsGroupsCacheService } from '@shared/services/core/cache/info-indicators-groups-cache.service';
import { ApiResponse, RestService } from '@shared/services/rest';
import { catchError, firstValueFrom, map, of, shareReplay, delay } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class IndicatorsService extends RestService {
  override _url: string = 'api/trademap/v1/indicators';
  constructor(
    private indicatorsGroupsCacheService: IndicatorsGroupsCacheService,
    private infoIndicatorsGroupsCacheService: InfoIndicatorsGroupsCacheService,
    private indicatorsHistoriesCacheService: IndicatorsHistoriesCacheService,
    private _indicatorsTableCacheService: IndicatorsTableCacheService,
    http: HttpClient
  ) {
    super(http);
  }

  public getIndicatorsProspectusGroups(cdStock: string, idExchange: number) {
    const key = `${cdStock}:${idExchange}`;
    let request$: any = this.indicatorsGroupsCacheService.getValue(key);
    if (!request$) {
      request$ = this.get(
        `prospectus/groups?cdStock=${cdStock}&idExchange=${idExchange}`
      ).pipe(shareReplay(1));
      this.indicatorsGroupsCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }

  public getGroupIndicators(
    cdStock: string,
    idExchange: number = 1,
    idLayout: number = 1
  ) {
    const key = `${cdStock}:${idExchange}:${idLayout}`;
    let request$: any = this.infoIndicatorsGroupsCacheService.getValue(key);
    if (!request$) {
      request$ = this.get(
        `prospectus/groups/${idLayout}/indicators?cdStock=${cdStock}&idExchange=${idExchange}`
      ).pipe(shareReplay(1));
      this.infoIndicatorsGroupsCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }

  public getIndicatorsHistories(
    cdStock: string,
    idExchange: number,
    idIndicator: number,
    idCompanyType: 'CONSOLIDATED' | 'CONTROLLER'
  ) {
    const key = `${cdStock}:${idExchange}:${idIndicator}:${idCompanyType}`;
    let request$: any = this.indicatorsHistoriesCacheService.getValue(key);
    if (!request$) {
      request$ = this.get(
        `${idIndicator}/histories?cdStock=${cdStock}&idExchange=${idExchange}&companyType=${idCompanyType}`
      )
        .pipe(
          catchError((error) => of(this.handlerServiceError(error))),
          map((response) => {
            if (response.error) throw response;
            return response.data;
          })
        )
        .pipe(shareReplay(1));
      this.indicatorsHistoriesCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }

  public getConsolidatedIndicators(
    cdStock: string,
    idExchange: number,
    period: INDICATOR_PERIOD,
    companyType: INDICATORS_COMPANY_TYPE
  ) {
    const key = `${cdStock}:${idExchange}:${period}:${companyType}_INDICATORS`;
    const value = this._indicatorsTableCacheService.getValue(key);
    if (value) return of(value).pipe(delay(150));
    return this.get<IConsolidatedData>(
      `?cdStock=${cdStock}&idExchange=${idExchange}&periodType=${period}&companyType=${companyType}`
    ).pipe(
      catchError((err) => this.handlerServiceError(err)),
      map((response: any) => {
        const res: ApiResponse<IConsolidatedData> = response;
        if (!res.data.metadata || res.data?.success === false)
          throw response?.data;
        this._indicatorsTableCacheService.setValue(response.data, key);
        return response.data;
      })
    );
  }

  public getBalanceSheet(
    cdStock: string,
    idExchange: number,
    companyType: INDICATORS_COMPANY_TYPE,
    periodType: 'EXERCISE' = 'EXERCISE'
  ) {
    const key = `${cdStock}:${idExchange}:${companyType}:${periodType}_BALANCE_SHEET`;
    const value = this._indicatorsTableCacheService.getValue(key);
    if (value) return of(value).pipe(delay(150));
    return this.get<IBalanceSheet>(
      `balance-sheets?cdStock=${cdStock}&idExchange=${idExchange}&companyType=${companyType}&periodType=${periodType}`
    ).pipe(
      catchError((err) => this.handlerServiceError(err)),
      map((response: any) => {
        const res: ApiResponse<IBalanceSheet> = response;
        if (!res.data.metadata || res.data?.success === false)
          throw response?.data;
        this._indicatorsTableCacheService.setValue(response.data, key);
        return response.data;
      })
    );
  }

  public getIncomesStatements(
    cdStock: string,
    idExchange: number,
    companyType: INDICATORS_COMPANY_TYPE,
    periodType: INDICATOR_PERIOD
  ) {
    const key = `${cdStock}:${idExchange}:${companyType}:${periodType}_INCOME_STATEMENTS`;
    const value = this._indicatorsTableCacheService.getValue(key);
    if (value) return of(value).pipe(delay(150));
    return this.get<IBalanceSheet>(
      `incomes-statements?cdStock=${cdStock}&idExchange=${idExchange}&periodType=${periodType}&companyType=${companyType}`
    ).pipe(
      catchError((err) => this.handlerServiceError(err)),
      map((response: any) => {
        const res: ApiResponse<IBalanceSheet> = response;
        if (!res.data.metadata || res.data?.success === false)
          throw response?.data;
        this._indicatorsTableCacheService.setValue(response.data, key);
        return response.data;
      })
    );
  }

  public getCashFlow(
    cdStock: string,
    idExchange: number,
    companyType: INDICATORS_COMPANY_TYPE,
    periodType: INDICATOR_PERIOD
  ) {
    const key = `${cdStock}:${idExchange}:${companyType}:${periodType}_CASH_FLOW`;
    const value = this._indicatorsTableCacheService.getValue(key);
    if (value) return of(value).pipe(delay(150));
    return this.get<IBalanceSheet>(
      `cash-flows?cdStock=${cdStock}&idExchange=${idExchange}&periodType=${periodType}&companyType=${companyType}`
    ).pipe(
      catchError((err) => this.handlerServiceError(err)),
      map((response: any) => {
        const res: ApiResponse<IBalanceSheet> = response;
        if (!res.data.metadata || res.data?.success === false)
          throw response?.data;
        this._indicatorsTableCacheService.setValue(response.data, key);
        return response.data;
      })
    );
  }
}
