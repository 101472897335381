<div
  *ngIf="!opened"
  (click)="openModal()"
  class="mini-modal-shadow align-items-center bg-brand-primary bottom-0 cursor-pointer d-flex fs-5 fw-bold m-3 p-2 px-3 position-absolute right-0 round-pill text-dark w-fit"
>
  <fla-icon name="help_outline" css="mr-1"></fla-icon>
  <div class="lh-none">Ajuda</div>
</div>
<div
  *ngIf="opened"
  class="mini-modal-shadow position-absolute bottom-0 right-0 m-3 w-25 round"
>
  <div
    id="header-modal"
    class="align-items-center bg-brand-primary d-flex fw-bold h-100 justify-content-center position-relative py-2 round-top text-dark"
  >
    <div class="fs-5">Deixe uma mensagem</div>
    <div
      class="position-absolute top-2 right-0 mr-2 cursor-pointer"
      (click)="openModal()"
    >
      <fla-icon name="remove"></fla-icon>
    </div>
  </div>
  <div id="content-modal" class="bg-black round-bottom p-4 fs-5">
    <p class="fw-bolder border-bottom border-thin pb-2 m-0">
      Selecione o motivo:
    </p>
    <p
      class="border-bottom border-thin pb-2 m-0 cursor-pointer mt-2 d-flex align-items-center lh-sm"
      (click)="goToWhats()"
    >
      <img
        src="../../../../assets/logo/whatsapp-logo.png"
        width="16px"
        class="mr-2"
      />
      Falar com atendente
    </p>
    <p
      class="m-0 cursor-pointer mt-2 d-flex align-items-center lh-sm"
      (click)="goToMail()"
    >
      <fla-icon
        name="mail_outline"
        size="icon-size-micro"
        css="mr-2"
      ></fla-icon>
      Alteração de cadastro
    </p>
  </div>
</div>
