<div
  class="position-absolute scichart-tooltip-div-cl"
  [id]="'chart-acumulado-tooltip-div-id_' + refChart"
  *ngIf="showTooltip"
></div>
<div class="d-flex justify-content-end align-items-center" style="height: 34px">
  <div class="seletor-period d-flex align-items-center position-relative">
    <div class="dropdown dropdown-position">
      <a
        class="dropdown-custom-size border btn btn-primary dropdown-toggle fw-bold p-1 round-lg text-white bg-transparent fs-6"
        role="button"
        id="dropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ periodSelectedLabel }}
      </a>

      <ul
        class="dropdown-menu cursor-pointer"
        aria-labelledby="dropdownMenuLink"
      >
        <li *ngFor="let visualization of periodsSelector">
          <a
            class="dropdown-item"
            type="button"
            (click)="changePeriod(visualization)"
          >
            {{ visualization.label }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="chart-parent pl-2 pr-2">
  <div [id]="refChart" #chartContainer class="chart-container"></div>
</div>
