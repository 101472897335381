import { Injectable } from '@angular/core';
import { StyleTool } from '@shared/tiger-chart/draw-tools/draw-tools.interface';
import { TIGER_CHART_TOOL } from '../tiger-chart-tools.interface';
import { REGEX_GET_RGBA_ALPHA } from './toolbar-draw.const';

@Injectable({ providedIn: 'root' })
export class ColorsBaseService {
  constructor() {}

  rgba2hex(rgba: string) {
    var a,
      rgb: any = rgba
        .replace(/\s/g, '')
        .match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
      alpha = ((rgb && rgb[4]) || '').trim(),
      hex = rgb
        ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
          (rgb[2] | (1 << 8)).toString(16).slice(1) +
          (rgb[3] | (1 << 8)).toString(16).slice(1)
        : rgba;

    if (alpha !== '') {
      a = alpha;
    } else {
      a = 0o1;
    }
    // multiply before convert to HEX
    a = ((a * 255) | (1 << 8)).toString(16).slice(1);
    hex = hex + a;

    return `#${hex}`;
  }

  hex2Rgba(hex: string) {
    var r, g, b, a;
    hex = hex.replace('#', '');
    if (3 === hex.length) {
      r = hex.charAt(0);
      g = hex.charAt(1);
      b = hex.charAt(2);
    } else if (4 === hex.length) {
      r = hex.charAt(0);
      g = hex.charAt(1);
      b = hex.charAt(2);
      a = hex.charAt(3);
    } else if (6 === hex.length) {
      r = hex.substring(0, 2);
      g = hex.substring(2, 4);
      b = hex.substring(4, 6);
    } else if (8 === hex.length) {
      r = hex.substring(0, 2);
      g = hex.substring(2, 4);
      b = hex.substring(4, 6);
      a = hex.substring(6, 8);
    } else {
      return '';
    }
    if ('undefined' === typeof a) {
      a = 'ff';
    }
    if (1 === r.length) {
      r += r;
    }
    if (1 === g.length) {
      g += g;
    }
    if (1 === b.length) {
      b += b;
    }
    if (1 === a.length) {
      a += a;
    }
    r = parseInt(r, 16);
    g = parseInt(g, 16);
    b = parseInt(b, 16);
    a = parseInt(a, 16) / 255;
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')';
  }

  getRgbaObjectByHex(hex: string): {
    r: number;
    g: number;
    b: number;
    a: number;
  } {
    var r, g, b, a;
    hex = hex.replace('#', '');
    if (3 === hex.length) {
      r = hex.charAt(0);
      g = hex.charAt(1);
      b = hex.charAt(2);
    } else if (4 === hex.length) {
      r = hex.charAt(0);
      g = hex.charAt(1);
      b = hex.charAt(2);
      a = hex.charAt(3);
    } else if (6 === hex.length) {
      r = hex.substring(0, 2);
      g = hex.substring(2, 4);
      b = hex.substring(4, 6);
    } else if (8 === hex.length) {
      r = hex.substring(0, 2);
      g = hex.substring(2, 4);
      b = hex.substring(4, 6);
      a = hex.substring(6, 8);
    } else {
      return { r: 0, g: 0, b: 0, a: 0 };
    }
    if ('undefined' === typeof a) {
      a = 'ff';
    }
    if (1 === r.length) {
      r += r;
    }
    if (1 === g.length) {
      g += g;
    }
    if (1 === b.length) {
      b += b;
    }
    if (1 === a.length) {
      a += a;
    }
    r = parseInt(r, 16);
    g = parseInt(g, 16);
    b = parseInt(b, 16);
    a = parseInt(a, 16) / 255;
    return { r, g, b, a };
  }

  rgba2rgb(rgbaColor: string): string {
    return rgbaColor.replace('rgba', 'rgb').replace(REGEX_GET_RGBA_ALPHA, ')');
  }

  getOpacityHexByRGB(selectedTool: TIGER_CHART_TOOL, style: StyleTool) {
    const opacity = selectedTool.backgroundColorOpacity
      ? selectedTool.backgroundColorOpacity
      : 1;
    const rgba =
      style.backgroundColor?.rgbColor.replace(')', `, ${opacity})`) ||
      style.color?.rgbColor.replace(')', `, ${opacity})`) ||
      style.centerLineColor?.rgbColor.replace(')', `, ${opacity})`);
    return this.rgba2hex(rgba!!);
  }
}
