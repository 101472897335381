import { Component, OnDestroy, OnInit } from '@angular/core';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';

@Component({
  selector: 'app-btg-terms',
  templateUrl: './btg-terms.component.html',
  styleUrls: ['../terms-styles.scss'],
})
export class BtgTermsComponent implements OnInit, OnDestroy {
  constructor(private themePreferences: ThemePreferencesService) {}

  ngOnInit(): void {
    this.themePreferences.forceLightTheme();
  }

  ngOnDestroy(): void {
    this.themePreferences.resetTheme();
  }
}
