import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { IWorkSpaceComponet } from '@core/interface';
import { IRentFieldsToSort } from './interface/rent.interface';
import { IRentInfos } from '@shared/services/api/trademap/V6/interface/loan-balance.interface';
import { RENT_CONTENT_ENUM } from './enum/rent.enum';
import { RentService } from './service/rent.service';
import { DEFAULT_HEADER_FILTER } from './constants/rent.constant';
import { RentPreferencesService } from '@shared/services/core/custom-preferences/rent/rent-preferences.service';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { ActivatedRoute } from '@angular/router';
import { isWebViewContext } from 'src/app/desktop/integration.service';

@Component({
  selector: 'app-rent-btc',
  templateUrl: './rent.component.html',
  styleUrls: ['./rent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RentComponent extends RocketComponentBase implements OnInit {
  @Input() component!: IWorkSpaceComponet;
  @Input() refComponent!: string;
  @Input() width!: number;
  @Input() height!: number;

  public widthComponent: number = 0;
  public rowData: IRentInfos[] = [];
  public sortByField!: string;

  public stockToGetBalance!: string;
  public updateSortHeader!: string | undefined;

  public hideGridContent: boolean = false;

  public readonly componentContents = RENT_CONTENT_ENUM;
  public componentContent: RENT_CONTENT_ENUM = RENT_CONTENT_ENUM.GRID;
  isDesktop = false;

  constructor(
    public rentService: RentService,
    private _rentPreferencesService: RentPreferencesService,
    private cdr: ChangeDetectorRef,
    activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
    this.isDesktop = isWebViewContext();
  }

  protected initComponent(component: any): void {
    if (component) this.component = component;
    this._processComponentSize();
    this._getLastHeaderSelected();
  }

  private _processComponentSize() {
    if (this.isDesktop) {
      this.height =
        this.component.metadata.componentResize?.height ??
        parseInt(this.component.metadata.layout.height);
      this.width =
        this.component.metadata.componentResize?.width ??
        parseInt(this.component.metadata.layout.width);
    }
  }

  private _getLastHeaderSelected(): void {
    const header = this._rentPreferencesService.sortTab;
    if (header) {
      this.sortByField = header.field;
    } else this.sortByField = DEFAULT_HEADER_FILTER.field;
    this.cdr.detectChanges();
  }

  public handlerSortField(tab: IRentFieldsToSort): void {
    if (tab.field === this.sortByField) return;
    this._rentPreferencesService.sortTab = tab;
    this.sortByField = tab.field;
    this.cdr.detectChanges();
  }

  public onSortChange(colId: string | undefined): void {
    this.updateSortHeader = colId;
    this.cdr.detectChanges();
  }

  public onSelectedRow(rowInfo: string): void {
    this.stockToGetBalance = rowInfo;
    this.handlerRentContent(true);
    this.cdr.detectChanges();
  }

  public handlerRentContent(navToRentDetails: boolean): void {
    this.componentContent = navToRentDetails
      ? RENT_CONTENT_ENUM.INFOS
      : RENT_CONTENT_ENUM.GRID;
    this.hideGridContent = navToRentDetails;
    this.cdr.detectChanges();
  }
}
