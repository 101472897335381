import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { cpfOrRneValidator } from '@shared/validators/cpf-or-rne-validator';
import { emailValidator } from '@shared/validators/email-validator';
import {
  getPhoneCountryValue,
  validatePhoneCode,
} from '@shared/validators/phone-validator';
import { Observable, filter } from 'rxjs';

export abstract class ResetForm implements ResetFormInterface {
  resetForm!: FormGroup;
  documentMask: string = '';
  documentMaxLength: number = 15;
  phoneCountrySelected = { country: 'br', code: '55' };
  // 'Preencha com um documento válido'
  errorMessages = {
    document: 'Preencha com um documento válido',
    recoveryInfo: 'Preencha uma informção válida',
    phone: 'Preencha o número do telefone válido',
    email: 'Preencha com um e-mail válido',
  };
  erroData = {
    hasError: false,
    errorMessage: '',
  };
  revoveryInfoMessage: string = this.errorMessages.phone;
  get recoveryInfoType() {
    return this.resetForm.get('recoveryInfoType');
  }

  get recoveryInfo() {
    return this.resetForm.get('recoveryInfo');
  }

  get recaptcha() {
    return this.resetForm.get('recaptcha');
  }

  get formValues() {
    const { recoveryInfo, recoveryInfoType } = this.resetForm.value;
    return {
      ...this.resetForm.value,
      ...{
        recoveryInfo:
          recoveryInfoType === 'phone_mobile'
            ? getPhoneCountryValue(
                recoveryInfo,
                this.phoneCountrySelected.country
              )
            : recoveryInfo,
      },
    };
  }
  get isFormValid() {
    return this.resetForm?.valid;
  }

  get vlDocument() {
    return this.resetForm.get('vlDocument')!;
  }

  constructor(private fb: FormBuilder) {
    this.resetForm = this.fb.group({
      documentType: ['CPF', Validators.required],
      vlDocument: [
        '',
        {
          validators: [Validators.required, cpfOrRneValidator()],
        },
      ],
      recoveryInfoType: ['phone_mobile', Validators.required],
      recoveryInfo: [
        '',
        Validators.compose([Validators.required, this.phoneValidator]),
      ],
      operatingSystem: [''],
      recaptcha: [null],
    });
  }

  get onVlDocumentChange(): Observable<any> {
    return this.vlDocument.valueChanges.pipe(
      filter(() => this.vlDocument?.valid ?? false)
    );
  }

  updateRecoveryInfoValidator() {
    const recoveryInfoType = this.recoveryInfoType?.value;
    const isEmail = recoveryInfoType === 'email';
    const validator = isEmail
      ? [Validators.required, emailValidator]
      : [Validators.required, this.phoneValidator];
    this.revoveryInfoMessage = isEmail
      ? this.errorMessages.email
      : this.errorMessages.phone;
    this.recoveryInfo?.setValidators(validator);
    this.recoveryInfo?.updateValueAndValidity();
  }

  phoneValidator = (control: AbstractControl) =>
    validatePhoneCode(control.value, this.phoneCountrySelected.country);
}

export interface ResetFormInterface {
  resetForm: FormGroup;
  recoveryInfoType: AbstractControl | null;
  recoveryInfo: AbstractControl | null;
  formValues: any;
  isFormValid: boolean;
}
