import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IForeignersTooltipsParams } from '../../flow-investors.service';

@Component({
  selector: 'app-foreigners-chart-tooltip',
  template: `
    <ng-container *ngIf="display && data">
      <div
        class="foreigners-chart-tooltip position-absolute scichart-tooltip-div-cl bg-neutral-strong round p-2 fs-6 text-light"
        [style.left.px]="leftPosition"
        [style.top.px]="topPosition"
      >
        <span> {{ data.dateFormatted }} </span>
        <ng-container *ngFor="let serie of data.params">
          <ng-container *ngIf="serie.date === data.date">
            <div class="d-flex align-items-center gap-1">
              <div
                class="tooltip-dot"
                [style.background-color]="serie.color"
              ></div>
              <div class="d-flex align-items-center gap-2">
                {{ serie.seriesName }}:
                <span class="fs-6">{{ serie.yValue }}</span>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .foreigners-chart-tooltip {
        width: 200px;
      }

      .tooltip-dot {
        width: 5px;
        height: 5px;
        border-radius: 100%;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule],
})
export class ForeignersChartTooltipComponent {
  @Input() public display: boolean = false;
  @Input() public data!: IForeignersTooltipsParams | null;
  @Input() public leftPosition: number = 0;
  @Input() public topPosition: number = 0;
}
