import { Routes, RouterModule } from '@angular/router';
import { SuperDomComponent } from './super-dom.component';

const routes: Routes = [
  {
    path: '',
    component: SuperDomComponent,
  },
];

export const SuperDomRoutes = RouterModule.forChild(routes);
