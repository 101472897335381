export const imediateChannels: Set<string> = new Set([
  'CONTEXT_MENU',
  'DESKTOP',
]);

export const isWebViewContextValue =
  'chrome' in window && 'webview' in (window as any).chrome;

export const emitToDesktop = (name: string, data: any, ...debug: any[]) => {
  // so envia para o desktop se for um contexto de webview
  // e se for um canal pertinente
  if (isWebViewContextValue) {
    (window as any).chrome.webview.postMessage({ name, data });
    if (debug.length) console.log(...debug);
  }
};
