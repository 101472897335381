import { Injectable } from '@angular/core';
import {
  OnboardApiService,
  RequestAccountRecoverWithTypeInterface,
} from './onboard-api.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, of, tap } from 'rxjs';
import { UpdateFieldOnboardInterface } from './onboard-api.interface';
import { RecoveryPasswordFormInterface } from '@modules/auth/register/recovery/base';
import {
  OnboardData,
  RecoveryData,
  RECOVERY_STEPS_MESSAGE,
  ONBOARD_STEPS_MESSAGE,
} from './data/index';
import { IInternationalNumber } from '@shared/rocket-components/components';

@Injectable({
  providedIn: 'root',
})
export class OnboardService extends OnboardApiService {
  constructor(http: HttpClient) {
    super(http);
  }
  onboardData: OnboardData = new OnboardData();
  recoveryData: RecoveryData = new RecoveryData();

  get cdAccessToken() {
    return this.onboardData?.cdAcessToken;
  }

  get phoneNumber() {
    return this.onboardData.phoneNumber ?? this.recoveryData.recoveryInfo;
  }

  get phoneCountryInfos() {
    return this.onboardData.phoneCountryInfos ?? { country: 'br', code: '55' };
  }

  get validateInfo() {
    return this.onboardData.phoneNumber ?? this.recoveryData.recoveryInfo;
  }

  get password() {
    return this.onboardData.password;
  }

  get isAcceptTerm() {
    return this.onboardData.accept_term;
  }

  get email() {
    return this.onboardData.email;
  }

  get recaptcha() {
    return this.onboardData.recaptcha;
  }

  get document() {
    return this.onboardData.document;
  }

  get name() {
    return this.onboardData.name;
  }

  get vlBirthday() {
    return this.onboardData.vlBirthday;
  }

  get pincode() {
    return this.onboardData.pincode;
  }

  get createAccountPincodeTimeout() {
    return this.onboardData.pincodeTimeout;
  }

  get appFlow() {
    return this.recoveryData.appFlow;
  }

  get resendPincodeTimeout(): number {
    return this.recoveryData.resendPincodeTimeout;
  }

  getIsRecoveryCompleted() {
    return this.recoveryData.isCompleted();
  }

  getIsOnboardCompleted() {
    return this.onboardData.isCompleted();
  }

  getRecoveryMessageByStatus = (
    status?: string | null
  ): string | null | undefined => RECOVERY_STEPS_MESSAGE(status);

  getOnboardMessageByStatus = (
    status?: string | null
  ): string | null | undefined => ONBOARD_STEPS_MESSAGE(status);

  setRecoveryStatus(status: string) {
    this.recoveryData.setRecoveryStatus(status);
  }

  setRecoveryResendPincodeTimeout(seconds: number) {
    this.recoveryData.updateRecoveryField('resendPincodeTimeout', seconds);
  }

  updatePhoneInfos(value: IInternationalNumber) {
    this.recoveryData.updateRecoveryField('vl_country_code', value.code);
    this.recoveryData.updateRecoveryField('phone_country', value.country);
  }

  setOnboardStatus(status: string) {
    this.onboardData.setStatus(status);
  }

  setPincode(code: string): void {
    this.onboardData.setPincode(code);
  }

  setCreateAccountPincodeTimeout(seconds: number) {
    this.onboardData.updateOnboardField('pincodeTimeout', seconds);
  }

  isRecoveryProcess() {
    return this.appFlow && this.appFlow === 'ROCOVERY_ACCESS';
  }

  isSmsRecovery() {
    return this.recoveryData.isSmsRecovery();
  }

  maskEmail = (email: string) =>
    email?.replace(/^(.).*@/, (match, firstChar) => firstChar + '********@');

  private errorHandler = (res: any) => {
    return of({
      result: {
        isError: true,
        message: res.error ?? res.message,
      },
    });
  };

  private _handleServiceError(res: any) {
    let message = res.error ?? res.message;
    message.includes('No eligible members for the call') &&
      (message = 'Houve um erro inesperado');
    return { isError: true, message };
  }

  getRecoveryInfoValidate() {
    const isMobile = this.recoveryData.isSmsRecovery();
    const data = this.recoveryData.recoveryInfo;
    return {
      isMobile,
      data: isMobile ? data : this.maskEmail(data),
    };
  }

  onInitialOnboard = (origin: string) =>
    this.initialOnboard(origin).pipe(
      tap((data: any) => {
        this.onboardData.setInitialOnboard(data.result);
      })
    );

  onPhoneConfirmation = (verification: string) =>
    this.phoneConfirmation({
      cd_access_token: this.cdAccessToken,
      vl_full_phone_number: this.phoneNumber,
      verification,
    }).pipe(
      catchError(this.errorHandler),
      map((response: any) => {
        if (!response.success) return this._handleServiceError(response);
        this.onboardData.setPincode(verification);
        return response.result;
      })
    );

  codeValidator = (code: string) => {
    if (!this.appFlow) {
      return this.onPhoneConfirmation(code);
    } else {
      return this.onDoRecoverAccountWithType(code);
    }
  };

  onUpdateOnboardField = (data: UpdateFieldOnboardInterface) =>
    this.updateOnboardField({
      ...data,
      ...{ cd_access_token: this.cdAccessToken },
    }).pipe(
      tap(() => {
        this.onboardData.updateOnboardField(data.field, data.value);
      })
    );

  onUpdateOnboardAuxField = (data: UpdateFieldOnboardInterface) =>
    this.onboardData.updateOnboardField(data.field, data.value);

  onValidateInitialOnboard = (recaptcha: string) =>
    this.validateInitialOnboard({
      recaptcha,
      cd_access_token: this.cdAccessToken,
    }).pipe(
      catchError(this.errorHandler),
      map((response) => {
        if (!response || !response.success || !response.result) throw response;
        this.setCreateAccountPincodeTimeout(
          response.result.secondary.validation.seconds
        );
        return response.result;
      })
    );

  onResendPhoneConfirmation = () =>
    this.resendPhoneConfirmation({
      cd_access_token: this.cdAccessToken,
      vl_full_phone_number: this.phoneNumber,
    });

  onRegisterCelPhone = () =>
    this.registerCelPhone({
      cd_access_token: this.cdAccessToken,
      vl_full_phone_number: this.phoneNumber,
      operation_system: 'web',
      type_to_send: 'WHATSAPP',
    });

  onVerifyDuplicityEmail = (email: string) =>
    this.verifyDuplicityEmail({ email }).pipe(map((data: any) => data.result));

  onGetUserRegistered = (document: string) =>
    this.getUserRegistered({ document }).pipe(map((data: any) => data.result));

  clearOnboardData() {
    this.onboardData.clear();
  }

  clearRecoveryData() {
    this.recoveryData.clear();
  }

  onOnboardGogogo = () =>
    this.onboardGogogo({
      cd_access_token: this.cdAccessToken,
      password: this.password,
      is_term_accepted: this.isAcceptTerm,
    }).pipe(
      catchError(this.errorHandler),
      map((response: any) => {
        if (
          !response ||
          !response.success ||
          !response.result.onboard_completed
        )
          throw <any>this._handlerCreateAccountError(response);
        return response.result;
      })
    );

  private _handlerCreateAccountError(response: any) {
    let msgError = response?.result?.error_message ?? response?.result?.message;
    (!msgError || msgError.includes('No eligible members for the call')) &&
      (msgError =
        'Ops, ocorreu um erro ao tentar criar a sua conta. Por favor, clique aqui e tente novamente.');
    return {
      isError: true,
      message: msgError,
    };
  }

  resendCodeRecovery = (data?: {
    recaptcha: string;
    recoveryInfoType: string;
  }) => {
    const values = this.recoveryData.getValues();
    const params = data ? { ...values, ...data } : values;
    return this.onRequestAccountRecoverWithType(params);
  };

  onRequestAccountRecoverWithType = (
    dataParams: RequestAccountRecoverWithTypeInterface
  ) => {
    this.recoveryData.updateRecoveryField('appFlow', 'ROCOVERY_ACCESS');
    this.recoveryData.setRecoveryData(dataParams);
    return this.requestAccountRecoverWithType(dataParams).pipe(
      catchError(this.errorHandler),
      map((res: any) => {
        if (!res.success) return this._handleServiceError(res);
        return res.result;
      })
    );
  };

  onDoRecoverAccountWithType = (token: string) =>
    this.doRecoverAccountWithType({
      token,
      value: this.recoveryData.recoveryInfo,
      type: this.recoveryData.recoveryInfoType,
    }).pipe(
      catchError(this.errorHandler),
      tap((response) => {
        response &&
          response.success &&
          this.recoveryData.setToken(response.result);
      }),
      map((response: any) => {
        if (!response.success) return this._handleServiceError(response);
        return response.result;
      })
    );

  onChangePasswordAfterValidate = (params: RecoveryPasswordFormInterface) =>
    this.changePasswordAfterValidate({
      token: this.recoveryData.token,
      new_password: params.password,
      new_confirm_password: params.confirm_password,
    }).pipe(
      catchError(this.errorHandler),
      map((response: any) => {
        if (!response.success) return this._handleServiceError(response);
        return response.result;
      })
    );
}
