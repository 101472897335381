import { Dictionary } from '@core/models';
import { BrowserStorageBase } from '@shared/services/core/storage/browser-storage-base';
import { ONBOARD_STEPS, ONBOARD_STORAGE } from './onboard.config';
import { sharedSessionStorage } from '@shared/services/core/storage/shared-session.storage';

export class OnboardData {
  private storage = new BrowserStorageBase(sharedSessionStorage);
  private _cdAcessToken!: string | null;
  private onboardDataDict: Dictionary<any> = new Dictionary<any>();

  get cdAcessToken() {
    return this._cdAcessToken ?? this.storage.getItem('cd_access_token');
  }
  set cdAcessToken(token: string) {
    this._cdAcessToken = token;
    this.storage.setItem('cd_access_token', token);
  }

  get onboardData() {
    return this.onboardDataDict.get('ONBOARD_DATA');
  }

  get phoneNumber() {
    return (
      this.onboardData?.phone_number ?? this.storage.getItem('phone_number')
    );
  }

  get phoneCountry() {
    return (
      this.onboardData?.phone_country ?? this.storage.getItem('phone_country')
    );
  }

  get vlCountryCode() {
    return (
      this.onboardData?.vl_country_code ??
      this.storage.getItem('vl_country_code')
    );
  }

  get phoneCountryInfos() {
    return {
      country: this.phoneCountry,
      code: this.vlCountryCode,
    };
  }

  get accept_term() {
    return this.onboardData?.accept_term ?? this.storage.getItem('accept_term');
  }

  get email() {
    return this.onboardData?.email ?? this.storage.getItem('email');
  }

  get password() {
    return this.onboardData?.password ?? this.storage.getItem('password');
  }

  get recaptcha() {
    return this.onboardData?.recaptcha ?? this.storage.getItem('recaptcha');
  }

  get document() {
    return this.onboardData?.document ?? this.storage.getItem('cnpj_cpf');
  }

  get name() {
    return this.onboardData?.name ?? this.storage.getItem('name');
  }

  get vlBirthday() {
    return (
      this.onboardData?.vl_birthdate ?? this.storage.getItem('vl_birthdate')
    );
  }

  get pincode() {
    return this.onboardData?.pincode ?? this.storage.getItem('pincode');
  }

  get pincodeTimeout() {
    return (
      this.onboardData?.pincodeTimeout ?? this.storage.getItem('pincodeTimeout')
    );
  }

  setInitialOnboard(cd_access_token: any) {
    this.cdAcessToken = cd_access_token;
  }

  get onboardStatus() {
    return (
      this.storage.getItem(ONBOARD_STORAGE.ONBOARD_STATUS) ??
      ONBOARD_STEPS.UNDEFINED
    );
  }

  isCompleted = () => this.onboardStatus === ONBOARD_STEPS.COMPLETED;

  setStatus(status: string) {
    this.updateOnboardField(ONBOARD_STORAGE.ONBOARD_STATUS, status);
  }

  setPincode(code: string) {
    this.updateOnboardField(ONBOARD_STORAGE.PINCODE, code);
  }

  updateOnboardField(field: string, value: any) {
    this.onboardDataDict.set('ONBOARD_DATA', { [field]: value });
    this.storage.setItem(field, value);
  }

  setOnboardRecaptcha(value: string): void {
    this.onboardDataDict.set('ONBOARD_DATA', { ['recaptcha']: value });
    this.storage.setItem('recaptcha', value);
  }

  clear() {
    this.onboardDataDict.clear();
    this._cdAcessToken = null;
    const storageKeys = [
      ONBOARD_STORAGE.ACCEPT_TERM,
      ONBOARD_STORAGE.PHONE_NUMBER,
      ONBOARD_STORAGE.VL_COUNTRY_CODE,
      ONBOARD_STORAGE.VL_BIRTHDATE,
      ONBOARD_STORAGE.CNPJ_CPF,
      ONBOARD_STORAGE.CD_ACCESS_TOKEN,
      ONBOARD_STORAGE.EMAIL,
      ONBOARD_STORAGE.NAME,
      ONBOARD_STORAGE.PASSWORD,
    ];
    storageKeys.forEach((key) => this.storage.removeItem(key));
  }
}
