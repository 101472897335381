<rocket-modal>
  <rocket-modal-header css="justify-content-center" [hideCloseButton]="true">
    <div class="d-flex align-items-center gap-2">
      <h3 class="modal-title mx-auto">Alterar Agrupamento</h3>
      <span
        [tooltip]="tooltip"
        class="user-select-none material-icons-outlined fs-4 mb-1"
      >
        info
      </span>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div class="d-flex align-items-center">
      <span
        (click)="changeInterval('previous')"
        class="cursor-pointer material-icons-outlined"
      >
        navigate_before
      </span>
      <input
        #inputInterval
        fla-focus
        type="text"
        class="form-control input-interval"
        [formControl]="intervalControl"
        (keydown.enter)="onSave()"
        (input)="updateService()"
      />
      <span
        (click)="changeInterval('next')"
        class="cursor-pointer material-icons-outlined"
      >
        navigate_next
      </span>
    </div>
    <p
      class="my-3 text-center"
      [ngClass]="{ 'text-feedback-error': intervalControl.invalid }"
    >
      {{ message }}
    </p>
    <div class="mb-6 d-flex justify-content-end footer">
      <rocket-button
        type="default"
        label="OK"
        css="mr-2"
        (rocketClick)="closeModal()"
      ></rocket-button>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
