import { Injectable } from '@angular/core';
import { IAuthForm } from '@core/interface';
import {
  IWorkSpace,
  IWorkSpaceComponet,
} from '@core/interface/workspace.interfaces';
import { AVAIABLE_COMPONENT_ROUTES_MAP } from '@core/workspace/constants';
import { HomeService } from '@modules/home/service/home.service';
import { WorkspaceComponentService } from '@modules/home/service/workspace-component.service';
import { deepClone } from '@shared/rocket-components/utils';
import { WorkspaceService } from '@shared/services/api/nitro-ws/v1/workspace.service';
import {
  UserDetails,
  UserService,
} from '@shared/services/api/trademap/v1/user.service';
import { emitToDesktop, isWebViewContextValue } from './integration.utils';
import { ComponentsService } from '@core/workspace/service/components.service';

export const isWebViewContext = () => isWebViewContextValue;

@Injectable({
  providedIn: 'root',
})
export class IntegrationService {
  constructor(
    private workspaceService: WorkspaceService,
    private homeService: HomeService,
    private workspaceComponentService: WorkspaceComponentService,
    private userService: UserService,
    private componentsService: ComponentsService
  ) {}

  private emit(name: string, data: any, ...debug: any[]) {
    emitToDesktop(name, data, ...debug);
  }

  private sendError(event: any) {
    emitToDesktop('DESKTOP_ERROR', event);
  }

  /*
    WORKSPACE_CREATE
  */
  async createWorkspace(name: string, active: boolean) {
    this.workspaceService
      .addWorkspace({ name, active, type: 'PLATAFORMA' })
      .subscribe((data) => {
        this.workspaceComponentService.changeActiveWorkspace(data);
        this.emit(
          'WORKSPACE',
          { type: 'WORKSPACE', data },
          'Workspace created',
          name,
          active
        );
      });
  }

  /*
    WORKSPACE_ACTIVATE
  */
  async activateWorkspace(workspaceId: string) {
    const workspace = this.workspaceComponentService
      .getWorkspaces()
      .find((ws) => ws.id === workspaceId);
    if (workspace) {
      workspace.active = true;
      this.homeService.updateWorkspace(deepClone(workspace)).subscribe(() => {
        this.workspaceComponentService.changeActiveWorkspace(workspace);
      });
    } else {
      this.sendError('Workspace not found');
    }
  }

  /*
    WORKSPACE_UPDATE
  */
  async updateWorkspace(workspaceId: string, name: string, visible: boolean) {
    const workspace = this.workspaceComponentService
      .getWorkspaces()
      .find((ws) => ws.id === workspaceId);
    if (workspace) {
      workspace.name = name;
      workspace.visible = visible;
      this.homeService.updateWorkspace(deepClone(workspace)).subscribe(() => {
        console.log('Workspace updated', workspace);
      });
    } else {
      this.sendError('Workspace not found');
    }
  }

  /*
    COMPONENT_ADD
  */
  async addComponent(componentKey: string) {
    const componentsAndTools = [
      ...this.componentsService.getComponentsList().values().flat(),
      ...this.componentsService.getToolsList().values().flat(),
    ];
    const component = componentsAndTools.find(
      (comp) => comp.cod === componentKey
    );
    if (component) {
      const newComponent = await this.homeService.addComponent(component);
      if (newComponent) {
        newComponent.visible = true;
        this.emit(
          'COMPONENT',
          { type: 'COMPONENT', data: this.toComponentDTO(newComponent) },
          'Component added'
        );
      }
    } else {
      this.sendError('Component not found');
    }
  }

  /*
    COMPONENT_REMOVE
  */
  async removeComponent(componentId: string) {
    this.homeService.removeComp(componentId);
  }

  /*
    COMPONENT_METADATA_UPDATE
  */
  async updateComponentMetadata(
    componentId: string,
    width: number,
    height: number,
    top: number,
    left: number
  ) {
    const workspace = this.workspaceComponentService.getActiveWorkspace();
    const component = workspace.components?.find(
      (comp) => comp.id === componentId
    );
    if (component) {
      component.metadata.layout.width = width;
      component.metadata.layout.height = height;
      component.metadata.layout.top = top;
      component.metadata.layout.left = left;
      this.homeService.updateMeta(component);
      console.log('Component metadata updated');
    } else {
      this.sendError('Component not found');
    }
  }

  /*
    GET_PROFILE_PICTURE
  */
  async profileGetPicture() {
    this.userService.getInfos().subscribe((user: UserDetails) => {
      const profileData = user;
      const pictureUrl =
        profileData.picture === UserService.defaultUserPicturePath
          ? ''
          : profileData.picture;
      this.emit('PROFILE_PICTURE', {
        hasPicture: pictureUrl !== '',
        pictureUrl: pictureUrl,
      });
    });
  }

  async notifyAuthenticated(authData: IAuthForm) {
    this.emit('AUTHENTICATED', authData, 'User authenticated');
  }

  async closeComponent(componentId: string) {
    this.emit(
      'COMPONENT_CLOSE_WINDOW',
      { id: componentId },
      'Component closed'
    );
  }

  async availableComponents() {
    this.emit(
      'COMPONENTS',
      this.componentsService.getComponentsList().values().flat(),
      'Components sent'
    );
    this.emit(
      'TOOLS',
      this.componentsService.getToolsList().values().flat(),
      'Tools sent'
    );
  }

  private norUndefinedNorNan(value: any): number {
    if (!value) return 0;
    const val = parseFloat(value);
    if (isNaN(val)) return 0;
    return val;
  }

  private generateComponentLink(component: IWorkSpaceComponet): string {
    return `/view/${AVAIABLE_COMPONENT_ROUTES_MAP[component.type]}/${
      component.id
    }`;
  }

  async hideWorkspace(workspaceId: string) {
    const workspace = this.workspaceComponentService
      .getWorkspaces()
      .find((ws) => ws.id === workspaceId);
    if (workspace) {
      this.homeService.hideWorkspace(workspace).subscribe(() => {
        this.emit(
          'ACTIVE_WORKSPACE',
          this.workspaceComponentService.getActiveWorkspace(),
          'Workspace hidden'
        );
      });
    } else {
      this.sendError('Workspace not found');
    }
  }

  async getAllWorkspaces() {
    let listDtoWorkspaces: any[] = [];
    while (listDtoWorkspaces.length === 0) {
      const workspaces = this.workspaceComponentService.getWorkspaces();
      if (workspaces.length === 0) {
        await new Promise((resolve) => setTimeout(resolve, 100)); // wait 100ms before retrying (coxambre)
      } else {
        listDtoWorkspaces = workspaces.map(this.toWorkspaceDTO);
      }
    }
    this.emit('WORKSPACE_GET_ALL', listDtoWorkspaces, 'Workspaces sent');
  }

  private toComponentDTO = (component: IWorkSpaceComponet) => {
    return {
      id: component.id,
      name: component.name,
      type: component.type,
      visible: component.visible,
      minWidth: this.norUndefinedNorNan(component.metadata.layout.minWidth),
      minHeight: this.norUndefinedNorNan(component.metadata.layout.minHeight),
      width: this.norUndefinedNorNan(component.metadata.layout.width),
      height: this.norUndefinedNorNan(component.metadata.layout.height),
      top: this.norUndefinedNorNan(component.metadata.layout.top ?? '0'),
      left: this.norUndefinedNorNan(component.metadata.layout.left ?? '0'),
      url: this.generateComponentLink(component),
    };
  };

  private toWorkspaceDTO = (workspace: IWorkSpace) => {
    return {
      id: workspace.id,
      name: workspace.name,
      active: workspace.active,
      default: workspace.default,
      components: workspace.components?.map(this.toComponentDTO),
      visible: workspace.visible ?? true,
    };
  };
}
