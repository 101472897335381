import { Injectable } from '@angular/core';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { OrsGatewayService } from '@shared/services/api/trademap/v1/ors-gateway.service';
import { from, map, tap } from 'rxjs';
import { IBroker, IWorkSpace } from 'src/app/core/interface';
import { InitializeWsService } from './workspace/initialize-workspace.service';
import { ThemePreferencesService } from './custom-preferences/theme/theme-preferences.service';

@Injectable({
  providedIn: 'root',
})
export class SystemDataService {
  constructor(
    private _workspace: InitializeWsService,
    private _ors: OrsGatewayService,
    private _customPreferencesService: CustomPreferencesService,
    private themePreferences: ThemePreferencesService
  ) {}

  private processOrders() {
    //this._ors.getAllOrdersPagedCached().subscribe();
  }

  loadCustomPreferences = () =>
    from(this._customPreferencesService.getCustomPreferencesCached()).pipe(
      map((prefSize) => prefSize === 0),
      tap((isNewUser) => {
        this._workspace.initialize(isNewUser);
        this.themePreferences.initialTheme();
        //this.processOrders();
      })
    );
}

export interface ResolveData {
  profile: any;
  brokers: IBroker[];
  workspace: IWorkSpace[];
}
