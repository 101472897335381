import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import {
  InvestorData,
  UserDetails,
  UserService,
} from '@shared/services/api/trademap/v1/user.service';
import { ModalChangePasswordComponent } from './modal-change-password/modal-change-password.component';
import { Subject, delay, take, tap } from 'rxjs';
import { openZendeskRocket } from '@shared/components/helper-mini-modal/helper-mini-modal.component';
import { ModalAvisoComponent } from '@shared/components/modal-aviso/modal-aviso.component';
import { WhatsappService } from '../whatsapp/whatsapp.service';
import { USER_DATA_FIELDS } from './constants/modal-meus-dados.constants';
import { StandaloneModalsService } from '@shared/modals/standalone-modals.service';
import { ModalChangePhoneComponent } from '@shared/modals/modal-change-phone/modal-change-phone.component';
import { system } from '@core/system/system.service';
import { ModalTokenConfigComponent } from '../modal-token-config/modal-token-config.component';
import { PhoneInputConfigService } from '@shared/rocket-components/components/phone-input/service/phone-input-config.service';
import { Iti } from 'intl-tel-input';
import { TTokenPreferenceAuthenticator } from '../modal-token-config/types';

@Component({
  selector: 'app-modal-meus-dados',
  templateUrl: './modal-meus-dados.component.html',
  styleUrls: ['./modal-meus-dados.component.scss'],
})
export class ModalMeusDadosComponent
  extends RocketModalRef
  implements AfterViewInit
{
  profileData: UserDetails | any;
  investorData: InvestorData | any = {};
  private _onDestroy = new Subject<void>();
  private modalService!: RocketModalService;
  private _iti!: Iti;

  get investorKeys() {
    return USER_DATA_FIELDS;
  }

  @ViewChild('telHiddenInput')
  private _telHiddenInput!: ElementRef<HTMLInputElement>;

  constructor(
    @Inject(ROCKET_MODAL_DATA) data: any,
    service: RocketModalService,
    private userService: UserService,
    private _elementRef: ElementRef,
    private whatsAppService: WhatsappService,
    private _standaloneModalServices: StandaloneModalsService,
    private _phoneInputConfigService: PhoneInputConfigService
  ) {
    super(service);
    this.modalService = service;
    this.profileData = data;
  }

  ngAfterViewInit() {
    this._initializeIntField();
    this._getInvestorData();
  }

  private _initializeIntField(): void {
    this._iti = this._phoneInputConfigService.getConfig(
      document.body,
      this._telHiddenInput.nativeElement,
      {
        showFlags: false,
        allowDropdown: false,
        separateDialCode: false,
      }
    );
  }

  private _getInvestorData(): void {
    this.userService
      .getInvestorData()
      .pipe(
        tap(
          (data) =>
            data.phone_mobile && this._iti.setNumber(`+${data.phone_mobile}`)
        ),
        delay(100)
      )
      .subscribe((data) => {
        if (data.phone_mobile) {
          const phone = this._iti.getNumber(parseInt(data.phone_mobile));
          const country = this._iti.getSelectedCountryData();
          data.phone_mobile = `+${country.dialCode} ${phone}`;
        } else data.phone_mobile = 'Número de telefone não foi encontrado';
        Object.assign(this.profileData, data);
      });
  }

  public handleFieldButtonClick(field: string): void {
    if (
      !system.authenticationMethod ||
      system.authenticationMethod == 'UNDEFINED_TOKEN'
    ) {
      this._withoutToken();
      return;
    }

    if (system.authenticationMethod === 'TRADEMAP') {
      this._openConfigureTradeToken('TRADEMAP');
      return;
    }

    if (field === 'password') {
      this._openChangePasswordModal();
      return;
    }

    if (field === 'phone_mobile' || field === 'email') {
      this._changeDataModal(field);
      return;
    }
  }

  private _openChangePasswordModal() {
    this.modalService.open(ModalChangePasswordComponent, {
      toggleBetweenModal: this._elementRef,
      centered: true,
      backdrop: true,
      keyboard: false,
      scrollable: false,
    });
  }

  private _openConfigureTradeToken(
    token: TTokenPreferenceAuthenticator | '' = ''
  ): void {
    this.modalService.open(ModalTokenConfigComponent, {
      size: 'lg',
      centered: true,
      backdrop: true,
      keyboard: true,
      scrollable: false,
      data: { callback: undefined, tokenPreference: token },
    });
  }

  cancelAccount() {
    const ref = this.modalService.open(ModalAvisoComponent, {
      data: {
        title: 'Deseja cancelar sua conta conosco?',
        text: `
        <p>Para cancelar sua conta conosco é necessário entrar em contato com a nossa equipe.</p>
        <p>Abra um chamado colocando na descrição que você gostaria de cancelar sua conta.</p>
        <p>Ou nos envie uma mensagem no WhatsApp.</p>`,
        confirmeButton: 'Abrir um chamado',
        askAgain: false,
        showButtons: true,
        cancelButton: 'Conversar pelo WhatsApp',
        isSecondModal: true,
        stock: undefined,
        showPriceStock: false,
      },
      toggleBetweenModal: this._elementRef,
    });
    ref.afterDismissed.subscribe((data) => {
      if (data.typeEvent === 'Escape') {
        return;
      }
      if (data.value) {
        openZendeskRocket();
      } else {
        this.whatsAppService.openWhatsAppRocket();
      }
    });
  }

  private _changeDataModal(field: string): void {
    this._elementRef.nativeElement.classList.add('d-none');
    this._standaloneModalServices
      .openStandaloneModal(
        ModalChangePhoneComponent,
        {
          params: {
            customTexts: undefined,
            data: this.profileData,
            field,
          },
        },
        {
          lockScreen: true,
          backdrop: 'static',
        }
      )
      .afterDismissed.subscribe((event) => {
        if (event.concluded) this._getInvestorData();
        this._elementRef.nativeElement.classList.remove('d-none');
      });
  }

  private _withoutToken(): void {
    this.modalService
      .open(ModalAvisoComponent, {
        toggleBetweenModal: this._elementRef,
        data: {
          title: 'Atenção',
          text: 'Para prosseguir você precisa ativar um segundo fator de segurança (Token)',
          cancelButton: 'Cancelar',
          confirmeButton: 'Habilitar token',
          showButtons: true,
        },
      })
      .afterDismissed.pipe(take(1))
      .subscribe((action: { type: string; value: boolean }) => {
        if (action.value === true) {
          this.close();
          this._openConfigureTradeToken();
          return;
        }
      });
  }
}
