<app-flow-header
  [refComponent]="refComponent"
  [component]="component"
  (changeMenu)="onChangeMenu($event)"
></app-flow-header>
<ng-container [ngSwitch]="menuSelected">
  <div
    class="bg-neutral-stronger"
    [ngClass]="isDesktop ? 'component-height' : 'h-100'"
  >
    <ng-container *ngSwitchCase="'FLOW_B3'">
      <app-flow-b3-tab
        [acumuladoB3]="atualB3Investor"
        [atualB3Investor]="atualB3Investor"
        [width]="width"
        [height]="height"
        [updatePosition]="updatePosition"
      ></app-flow-b3-tab>
    </ng-container>
    <ng-container *ngSwitchCase="'FLOW_INVESTORS'">
      <app-flow-investors-tab
        [futuros]="futuros"
        [width]="width"
        [height]="height"
        [updatePosition]="updatePosition"
      ></app-flow-investors-tab>
    </ng-container>
    <ng-container *ngSwitchCase="'FOREIGNERS'">
      <app-chart-foreigners
        [data]="data"
        [containerWidth]="width"
        [containerHeight]="height"
        [updatePosition]="updatePosition"
      ></app-chart-foreigners>
    </ng-container>
    <ng-container *ngSwitchCase="'VOLUME_IPO'">
      <app-volume-ipo-tab
        [width]="width"
        [height]="height"
        [updatePosition]="updatePosition"
      ></app-volume-ipo-tab>
    </ng-container>
  </div>
</ng-container>
