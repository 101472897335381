import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, map, Observable, shareReplay } from 'rxjs';
import { RestService } from '@shared/services/rest/rest.service';
import { IMarketSectorDomain } from '@core/interface/market-sector.interface';
import { SectorSubSectorSegmentCacheService } from '@shared/services/core/cache/sector-subsector-segment-cache';
import { TPeriod, TSource } from '@shared/components/heatmap-chart/types';

@Injectable({
  providedIn: 'root',
})
export class MarketSectorsService extends RestService {
  override _url: string = 'api/trademap';

  constructor(
    private sectorSubSectorSegmentCacheService: SectorSubSectorSegmentCacheService,
    http: HttpClient
  ) {
    super(http);
  }

  public geMarketSectorDomains = (): Observable<IMarketSectorDomain | null> =>
    this.post<any>('v1/market-sector/get-sectors-domain', {}).pipe(
      map((res) => {
        if (res.data && res.data?.success && res.data?.result) {
          return res.data.result as IMarketSectorDomain;
        }
        return null;
      })
    );

  public geStocksBySource = (
    source: TSource,
    period: TPeriod
  ): Observable<[]> =>
    this.post<any>('v1/market-sector/get-analytics-nitro', {
      source,
      period,
      futuro: false,
      cd_index: 'GERAL',
    }).pipe(
      map((res) => {
        if (res.data && res.data?.success && res.data?.result) {
          return res.data.result.stocks;
        }
        return [];
      })
    );

  public getSectorStocksInfo(id_market_sector: number) {
    const key = `SECTOR_${id_market_sector}`;
    let request$: any = this.sectorSubSectorSegmentCacheService.getValue(key);
    if (!request$) {
      request$ = this.post('v7/market-sector/get-sector-stocks-info', {
        id_market_sector,
      }).pipe(shareReplay(1));
      this.sectorSubSectorSegmentCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }

  public getSubSectorStocksInfo(id_market_subsector: number) {
    const key = `SUB_SECTOR_${id_market_subsector}`;
    let request$: any = this.sectorSubSectorSegmentCacheService.getValue(key);
    if (!request$) {
      request$ = this.post('v7/market-sector/get-sub-sector-stocks-info', {
        id_market_subsector,
      }).pipe(shareReplay(1));
      this.sectorSubSectorSegmentCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }

  public getSegmentStocksInfo(id_market_segment: number) {
    const key = `SEGMENT_${id_market_segment}`;
    let request$: any = this.sectorSubSectorSegmentCacheService.getValue(key);
    if (!request$) {
      request$ = this.post('v1/market-sector/get-segment-stocks-info', {
        id_market_segment,
      }).pipe(shareReplay(1));
      this.sectorSubSectorSegmentCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }
}
