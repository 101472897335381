<ng-template>
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'vertical'">
      <button
        class="nav-link"
        [id]="refId + '-tab'"
        data-bs-toggle="tab"
        [attr.data-bs-target]="'#' + refId"
        type="button"
        role="tab"
        [attr.aria-controls]="refId"
        aria-selected="true"
        [disabled]="disabled"
        [class.active]="active"
        [class.disabled]="disabled"
        [tooltip]="tooltip || ''"
        [tooltipHidden]="!tooltip"
        [ngClass]="css"
        (click)="flaChange.emit(refId)"
      >
        <span
          class="round-pill mr-2"
          [ngClass]="[cssDot]"
          style="width: 8px; height: 8px"
        ></span>
        {{ title }}
      </button>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          [id]="refId + '-tab'"
          data-bs-toggle="tab"
          [attr.data-bs-target]="'#' + refId"
          type="button"
          role="tab"
          [attr.aria-controls]="refId"
          aria-selected="true"
          [disabled]="disabled"
          [class.active]="active"
          [class.disabled]="disabled"
          [tooltip]="tooltip || ''"
          [tooltipHidden]="!tooltip"
          [ngClass]="css"
          (click)="flaChange.emit(refId + '-tab')"
        >
          <span
            *ngIf="showDot"
            class="round-pill mr-2 d-inline-block"
            [ngClass]="[cssDot]"
            style="width: 8px; height: 8px"
          ></span>
          {{ title }}
        </button>
      </li>
    </ng-container>
    <ng-container *ngIf="enableContent">
      <li class="nav-item" role="presentation" [ngClass]="css">
        <ng-content select="fla-button"></ng-content>
      </li>
    </ng-container>
  </ng-container>
</ng-template>
