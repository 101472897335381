<div *ngIf="!isToken; else onlyRouter" class="container-fluid p-0">
  <div class="row mx-0">
    <section class="col-16 col-md-8 vh-100 p-0">
      <app-login-background-content
        [useCreateAccountLayout]="useRegisterContent"
      ></app-login-background-content>
    </section>

    <section class="col-16 col-md-8 bg-neutral-stronger">
      <ng-container *ngIf="!showBrowserErrorMsg; else browserErrorMsg">
        <span class="arrow-back" *ngIf="!isLoginRoute">
          <fla-icon
            (click)="navigateBackward()"
            size="icon-size-sm"
            name="chevron_left"
          ></fla-icon>
        </span>

        <div
          class="d-flex flex-column justify-content-center p-8 h-100 px-xxl-7"
          [ngClass]="{
            'px-7 py-5 create-account-container': isCreateAccountFormPage,
            'p-sm-2': useSmallPadding
          }"
        >
          <router-outlet></router-outlet>
        </div>
      </ng-container>
    </section>
  </div>
  <app-helper-mini-modal></app-helper-mini-modal>
</div>

<ng-template #onlyRouter>
  <router-outlet></router-outlet>
</ng-template>

<ng-template #browserErrorMsg>
  <div
    class="d-flex flex-column justify-content-center p-8 h-100 px-xxl-7"
    [innerHTML]="browserMessage"
  ></div>
</ng-template>
