import {
  isStartStop,
  isDoubleStartStop,
} from '@shared/constants/general.contant';
import { ColDef } from 'ag-grid-community';
import { CustomTooltipComponent } from '../custom-tooltip/custom-tooltip.component';
import { everythingOrNothing } from './coldef-order';

export const HEADERS_ORDERS_OPEN: Array<ColDef> = [
  {
    headerName: '',
    field: 'edit',
    minWidth: 32,
    maxWidth: 32,
    width: 32,
    lockPosition: 'left',
    pinned: 'left',
    tooltipValueGetter: (params) => {
      if (
        everythingOrNothing(params.data) &&
        !isStartStop(params.data.cd_order_type) &&
        !isDoubleStartStop(params.data.cd_order_type)
      )
        return '';
      return 'Editar ordem';
    },
    tooltipComponentParams: { title: 'Editar ordem' },
    tooltipComponent: CustomTooltipComponent,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onCellClicked: () => {}, // Tratativa no orders-history-grid.component.ts:231
    cellRenderer: (params: any) => {
      if (
        everythingOrNothing(params.data) &&
        !isStartStop(params.data.cd_order_type) &&
        !isDoubleStartStop(params.data.cd_order_type)
      )
        return '';
      return ` <i class="icons icon-size-micro text-feedback-warning cursor-pointer"> edit </i> `;
    },
  },
  {
    headerName: '',
    field: 'close',
    minWidth: 32,
    maxWidth: 32,
    width: 32,
    lockPosition: 'left',
    pinned: 'left',
    tooltipValueGetter: () => 'Cancelar ordem',
    tooltipComponentParams: { title: 'Cancelar ordem' },
    tooltipComponent: CustomTooltipComponent,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onCellClicked: () => {}, // Tratativa no order.component.ts
    cellRenderer: () =>
      ` <i class="icons icon-size-micro text-feedback-negative cursor-pointer"> close </i> `,
  },
];
