import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';

import { CHART_COLORS } from '@shared/tiger-chart/colors';
import {
  TData,
  TTooltipIndicatorValue,
  TUpdateData,
} from '@shared/tiger-chart/types/tiger-chart.types';
import {
  BaseIndicator,
  RelativeStengthIndexData,
  TigerChartIndicatorRelativeStrengthIndexOptions,
  TTigerChartIndicatorCreateOptions,
  TTigerChartIndicatorLimitTypes,
  TTigerChartIndicatorParameter,
  TTigerChartIndicatorRenderSeriesConfig,
} from '../indicators.types';
import {
  TIGER_INDICATORS_ENUM,
  TIGER_INDICATOR_PARAMETER_TYPE,
  TIGER_INDICATOR_SOURCES,
} from '@shared/tiger-chart/enum/tiger-chart.enum';
import { Subject } from 'rxjs';
import { TalibService } from '@shared/tiger-chart/services/talib.service';
import { XyyScaleOffsetFilter } from 'scichart/Charting/Model/Filters/XyyScaleOffsetFilter';
import { XyDataSeries } from 'scichart/Charting/Model/XyDataSeries';
import { FastLineRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastLineRenderableSeries';
import { TIGER_INDICATOR_PRECISIONS } from '../indicators.constants';
import { HorizontalLineAnnotation } from 'scichart/Charting/Visuals/Annotations/HorizontalLineAnnotation';
import { ECoordinateMode } from 'scichart/Charting/Visuals/Annotations/AnnotationBase';
import { BoxAnnotation } from 'scichart/Charting/Visuals/Annotations/BoxAnnotation';
import { LINE_CONFIG_DICTIONARY } from '../settings-modal/styles-input/styles-input.dictionary';
import { setDefaultConfiguration } from '../indicators.functions';

export class RelativeStrengthIndexBase implements BaseIndicator {
  protected lineDataSeries!: XyDataSeries;
  protected movingAverageDataSeries!: XyyScaleOffsetFilter;
  protected lineRenderableSeries!: FastLineRenderableSeries;
  protected options!: TigerChartIndicatorRelativeStrengthIndexOptions;
  protected data: TData;
  protected precision: number = 2;
  protected timePeriod: number = 14;
  protected upperLimit: number = 70;
  protected bottomLimit: number = 30;
  protected source: TIGER_INDICATOR_SOURCES = TIGER_INDICATOR_SOURCES.CLOSE;
  protected points: RelativeStengthIndexData;
  protected lineColor = CHART_COLORS.FEEDBACK_WARNING;
  protected upperLimitColor = CHART_COLORS.NEUTRAL_MEDIUM;
  protected bottomLimitColor = CHART_COLORS.NEUTRAL_MEDIUM;
  protected backgroundColor = CHART_COLORS.FEEDBACK_NEGATIVE;
  protected lineThickness = 1;
  protected upperLimitLineType = 1;
  protected bottomLimitLineType = 1;

  private baseChart!: TWebAssemblyChart;
  lineNumber!: string;
  type: TIGER_INDICATORS_ENUM = TIGER_INDICATORS_ENUM.RELATIVE_STRENGTH_INDEX;
  yAxisId!: string;
  settings: TTigerChartIndicatorParameter[] = [];
  styles: TTigerChartIndicatorParameter[] = [];
  renderSeriesConfig: TTigerChartIndicatorRenderSeriesConfig[] = [];
  onChange = new Subject<null>();
  service: TalibService;
  mainLineId = 'rsi-line';
  isNewOnChart = false;

  get isVisible(): boolean {
    return this.lineRenderableSeries.isVisible;
  }

  set isVisible(visible: boolean) {
    this.lineRenderableSeries.isVisible = visible;
  }

  get propertiesText(): string {
    return `(${this.timePeriod})`;
  }

  constructor(options: TigerChartIndicatorRelativeStrengthIndexOptions) {
    this.data = options.data;
    this.service = options.service;
    this.precision = options.tickSize;
    this.options = options;
    if (options.timePeriod) this.timePeriod = options.timePeriod;
    if (options.upperLimit) this.upperLimit = options.upperLimit;
    if (options.bottomLimit) this.bottomLimit = options.bottomLimit;
    if (options.lineColor) this.lineColor = options.lineColor;
    if (options.upperLimitColor) this.upperLimitColor = options.upperLimitColor;
    if (options.bottomLimitColor)
      this.bottomLimitColor = options.bottomLimitColor;
    if (options.backgroundColor) this.backgroundColor = options.backgroundColor;
    if (options.precision) this.precision = options.precision;
    if (options.upperLimitLineType)
      this.upperLimitLineType = options.upperLimitLineType;
    if (options.bottomLimitLineType)
      this.bottomLimitLineType = options.bottomLimitLineType;
    if (options.lineThickness) this.lineThickness = options.lineThickness;

    this.points = {
      output: [],
    };
    this.renderSeriesConfig = [
      {
        label: 'Linha',
        id: `${this.type}-line-rsi`,
        color: this.lineColor,
        propertyColor: 'lineColor',
        thickness: this.lineThickness,
        propertyThickness: 'lineThickness',
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
      },
      {
        label: 'Limite Superior',
        id: `${this.type}-upper-limit-rsi`,
        color: this.upperLimitColor,
        propertyColor: 'upperLimitColor',
        thickness: 1,
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        dontShowTickness: true,
        showTypeLine: true,
        lineTypeId: this.upperLimitLineType,
        limit: this.upperLimit,
        showLimit: true,
        limitType: TTigerChartIndicatorLimitTypes.UPPER,
        property: 'upperLimit',
      },
      {
        label: 'Limite Inferior',
        id: `${this.type}-bottom-limit-rsi`,
        color: this.bottomLimitColor,
        propertyColor: 'bottomLimitColor',
        thickness: 1,
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        dontShowTickness: true,
        showTypeLine: true,
        lineTypeId: this.bottomLimitLineType,
        limit: this.bottomLimit,
        showLimit: true,
        limitType: TTigerChartIndicatorLimitTypes.BOTTOM,
        property: 'bottomLimit',
      },
      {
        label: 'Background',
        id: `${this.type}-background-limit-rsi`,
        color: this.backgroundColor,
        propertyColor: 'backgroundColor',
        thickness: 1,
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        dontShowTickness: true,
      },
    ];
    this.settings = [
      {
        label: 'Período',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property: 'timePeriod' as keyof RelativeStrengthIndexBase,
      },
    ];
    this.styles = [
      {
        type: TIGER_INDICATOR_PARAMETER_TYPE.SELECTION,
        property: 'precision',
        label: 'Precisão',
        values: TIGER_INDICATOR_PRECISIONS,
      },
    ];
    this.styles[0].values![0].value = this.precision;
    this.lineNumber = options.lineNumber;
  }

  create(options: TTigerChartIndicatorCreateOptions): BaseRenderableSeries[] {
    this.baseChart = options.base;
    const xValues = this.data.id_point;
    const offset = new Array(this.timePeriod).fill(NaN);

    this.lineDataSeries = new XyDataSeries(this.baseChart.wasmContext);
    /* O  TALIB RSI 
      É necessário retirar no final do array do OUTPUT o tamanho do timePeriod,
      pois eles retornam 0 por conta do calculo.
      e como o slice extrai até o tamanho, nao incluindo o fim, nao precisa subtrair 1 do timePeriod.
    */
    this.lineDataSeries.appendRange(xValues, [
      ...offset,
      ...this.points.output.slice(
        0,
        this.data.id_point.length - this.timePeriod
      ),
    ]);

    const lineConfig = this.renderSeriesConfig.find(
      (config) => config.id == `${this.type}-line-rsi`
    );
    this.lineRenderableSeries = new FastLineRenderableSeries(
      this.baseChart.wasmContext,
      {
        dataSeries: this.lineDataSeries,
        strokeThickness: lineConfig?.thickness,
        stroke: lineConfig?.color,
        id: `${this.mainLineId}_${this.lineNumber}`,
        xAxisId: options.xAxisId,
      }
    );

    this.setAnnotations(options.xAxisId);
    return [this.lineRenderableSeries];
  }

  updatePoints(): void {}

  append(xValue: number, data: TUpdateData, fullData: TData) {
    this.data = fullData;
    this.updatePoints();
    this.lineDataSeries &&
      this.lineDataSeries.append(
        xValue,
        this.points.output[this.points.output.length - this.timePeriod - 1]
      );
  }

  insertRange(xValues: number[], fullData: TData): void {
    const offset = new Array(this.timePeriod).fill(NaN);
    this.data = fullData;
    this.updatePoints();

    const s = this.baseChart.sciChartSurface.suspendUpdates();
    const arrayLength =
      xValues.length <= this.timePeriod
        ? xValues.length
        : xValues.length - this.timePeriod;
    try {
      if (xValues.length <= this.timePeriod) {
        this.lineDataSeries.insertRange(0, xValues, [
          ...offset.slice(0, arrayLength),
        ]);
      } else {
        this.lineDataSeries.insertRange(0, xValues, [
          ...offset,
          ...this.points.output.slice(0, arrayLength),
        ]);
        for (let index = xValues.length - 1; index < this.timePeriod; index++) {
          this.lineDataSeries.update(index, NaN);
        }
      }

      const indexInitialValue =
        xValues.length <= this.timePeriod
          ? this.timePeriod
          : xValues.length - this.timePeriod;
      for (
        let index = indexInitialValue;
        index < this.points.output.length - this.timePeriod;
        index++
      ) {
        this.lineDataSeries.update(index, this.points.output[index]);
      }
    } finally {
      s.resume();
    }
  }

  changeVisibility(): void {
    this.lineRenderableSeries.isVisible = !this.lineRenderableSeries.isVisible;
  }

  update(index: number, data: TUpdateData, fullData: TData) {
    if (!this.lineDataSeries) {
      return;
    }
    const count = this.lineDataSeries.count();
    if (index > count) {
      return;
    }
    this.data = fullData;
    this.updatePoints();
    this.lineDataSeries.update(
      index,
      this.points.output[this.points.output.length - this.timePeriod - 1]
    );
  }

  updateSettings(): void {
    const s = this.baseChart.sciChartSurface.suspendUpdates();
    const dataSeriesCount = this.lineDataSeries.count();

    const offset = new Array(this.timePeriod).fill(NaN);

    const lineValues = [
      ...offset,
      ...this.points.output.slice(
        0,
        this.data.id_point.length - this.timePeriod
      ),
    ];
    /**
     * o offset padrão(timePeriod) vai como NaN no inicio dos arrays
     *
     */

    if (dataSeriesCount > lineValues.length) {
      this.lineDataSeries.removeRange(
        lineValues.length - 1,
        dataSeriesCount - lineValues.length
      );
    }

    try {
      lineValues.forEach((linePointValue, index) => {
        this.lineDataSeries.update(index, lineValues[index]);
      });
    } finally {
      s.resume();
    }
    this.onChange.next(null);
  }

  updateStyles(
    baseChart: TWebAssemblyChart,
    config: TTigerChartIndicatorRenderSeriesConfig
  ): void {
    switch (config.id) {
      case `${this.type}-line-rsi`:
        const lineRenderSeries =
          baseChart.sciChartSurface.renderableSeries.getById(
            `${this.mainLineId}_${this.lineNumber}`
          );
        if (lineRenderSeries) {
          lineRenderSeries.stroke = !config.active
            ? 'transparent'
            : config.color;
          lineRenderSeries.strokeThickness = config.thickness;
        }
        break;
      case `${this.type}-upper-limit-rsi`:
        const annotationUpper = this.getHorizontalLine(
          `${this.type}-upper-limit-rsi`
        );
        if (annotationUpper) {
          annotationUpper.isHidden = !config.active;
          annotationUpper.stroke = config.color;
          annotationUpper.strokeDashArray = LINE_CONFIG_DICTIONARY.get(
            config.lineTypeId!!
          )?.dashArray!!;
        }
        break;
      case `${this.type}-bottom-limit-rsi`:
        const annotationBottom = this.getHorizontalLine(
          `${this.type}-bottom-limit-rsi`
        );
        if (annotationBottom) {
          annotationBottom.isHidden = !config.active;
          annotationBottom.stroke = config.color;
          annotationBottom.strokeDashArray = LINE_CONFIG_DICTIONARY.get(
            config.lineTypeId!!
          )?.dashArray!!;
        }
        break;
      case `${this.type}-background-limit-rsi`:
        const annotationBackground = this.getBackgroundBox(
          `${this.type}-background-limit-rsi`
        );
        if (annotationBackground) {
          annotationBackground.isHidden = !config.active;
          annotationBackground.fill = `${config.color}21`;
        }
        break;
      default:
        break;
    }
    this.onChange.next(null);
  }

  updatePositionHorizontalLine() {
    const annotationUpper = this.getHorizontalLine(
      `${this.type}-upper-limit-rsi`
    );
    const annotationBottom = this.getHorizontalLine(
      `${this.type}-bottom-limit-rsi`
    );
    const background = this.getBackgroundBox(
      `${this.type}-background-limit-rsi`
    );
    if (annotationUpper) {
      annotationUpper.y1 = this.upperLimit;
    }
    if (annotationBottom) {
      annotationBottom.y1 = this.bottomLimit;
    }
    if (background) {
      background.y1 = this.upperLimit;
      background.y2 = this.bottomLimit;
    }
  }

  private getHorizontalLine(id: string): HorizontalLineAnnotation {
    return this.baseChart.sciChartSurface.annotations.getById(
      id
    ) as HorizontalLineAnnotation;
  }

  private getBackgroundBox(id: string): BoxAnnotation {
    return this.baseChart.sciChartSurface.annotations.getById(
      id
    ) as BoxAnnotation;
  }

  delete() {
    this.baseChart.sciChartSurface.renderableSeries.remove(
      this.lineRenderableSeries
    );
  }

  setValue(property: keyof this, value: any) {
    const prop = this[property];
    if (typeof this[property] == 'number') {
      this[property] = +value as unknown as typeof prop;
    } else {
      this[property] = value;
    }
    if (
      property.toString().includes('upper') ||
      property.toString().includes('bottom')
    ) {
      this.updatePositionHorizontalLine();
      return;
    }
    this.updateSettings();
  }

  getValuesByIndex(index?: number): TTooltipIndicatorValue[] {
    if (!this.lineDataSeries) {
      return [];
    }
    const precision = this.precision;
    const lineValues = this.lineDataSeries.getNativeYValues();
    const lineValue = {
      value: lineValues.get(index || lineValues.size() - 1),
      precision,
      color: this.renderSeriesConfig[0].color,
    };
    return [lineValue];
  }

  private setAnnotations(xAxisId: string) {
    const configs = this.renderSeriesConfig.filter((config) => {
      return (
        config.id.includes('upper-limit') ||
        config.id.includes('bottom-limit') ||
        config.id.includes('background-limit')
      );
    });
    configs.forEach((config) => {
      if (
        config.id.includes('upper-limit') ||
        config.id.includes('bottom-limit')
      ) {
        const strokeDash = LINE_CONFIG_DICTIONARY.get(
          config.lineTypeId!!
        )?.dashArray;
        const line = new HorizontalLineAnnotation({
          showLabel: false,
          stroke: config.color,
          y1: config.limit!!,
          id: config.id,
          strokeThickness: config.thickness,
          strokeDashArray: strokeDash,
          xAxisId,
        });
        this.baseChart.sciChartSurface.annotations.add(line);
      }
      if (config.id.includes('background-limit')) {
        const background = new BoxAnnotation({
          fill: `${config.color}21`,
          strokeThickness: 0,
          xCoordinateMode: ECoordinateMode.Relative,
          id: config.id,
          x1: 0,
          x2: 1,
          y1: this.upperLimit,
          y2: this.bottomLimit,
          xAxisId,
        });
        this.baseChart.sciChartSurface.annotations.add(background);
      }
    });
  }

  resetConfiguration(): void {
    setDefaultConfiguration(
      this,
      this.baseChart,
      this.options,
      this.settings,
      this.renderSeriesConfig,
      this.styles,
      this.type
    );
  }
}
