import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { Point } from 'scichart/Core/Point';
import {
  BoxAnnotation,
  IBoxAnnotationOptions,
} from 'scichart/Charting/Visuals/Annotations/BoxAnnotation';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import {
  TIGER_CHART_TOOL,
  TIGER_CHART_TOOL_CHILD,
} from '../tiger-chart-tools/tiger-chart-tools.interface';
import { TMouseEventData } from '../types/tiger-chart.types';
import {
  Axis,
  DrawTools,
  DrawToolsReposition,
  EventSubject,
  LineToolsOptions,
  StyleTool,
} from './draw-tools.interface';
import {
  TOOLS_CALCULATION,
  TOOLS_ENUM,
  TOOLS_METHOD,
} from '../tiger-chart-tools/tiger-chart-tools.enum';
import { ModifierMouseArgs } from 'scichart/Charting/ChartModifiers/ModifierMouseArgs';
import { TYPE_RESIZE } from './draw-tools.enum';
import { formatNumber } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { ToolbarDrawService } from '../tiger-chart-tools/toolbar-draw/toolbar-draw.service';
import { ICandleConfigs } from '../interface';
import { TIGER_INTERVAL_ENUM } from '../enum';
import { CANDLE_IDS } from '../constants/tiger-chart.constants';
import { getVolumeText } from 'src/app/utils/utils.functions';
import { Subject, Subscription, auditTime, debounceTime } from 'rxjs';

export class BoxTools extends BoxAnnotation implements DrawTools {
  tool!: TIGER_CHART_TOOL;
  children = [];
  viewBoxWidth!: number;
  viewBoxHeight!: number;
  isDraw = true;
  startEdit = false;
  baseChart!: TWebAssemblyChart;
  isChildren = false;
  tickSize: number = 0;
  lastPointValues!: Point;
  dictIdChildren!: string;
  isDrawing!: boolean;
  isAux: boolean = false;
  endDraw: boolean = false;
  middleXPointValues!: Point;
  middleYPointValues!: Point;
  lastEventData!: TMouseEventData;
  configs!: ICandleConfigs;
  axisSub!: Subscription;
  stock!: any;
  isWithinDataBounds: boolean | undefined = true;
  private _updateFather$ = new Subject<EventSubject>();
  private updateFather$!: Subscription;
  private blockEditDraw$!: Subscription;
  private refComponent: string = '';
  private blockEdit: boolean = false;

  constructor(
    options: IBoxAnnotationOptions,
    private drawToolsService: DrawToolsService,
    private toolbarDrawService: ToolbarDrawService,
    tool: LineToolsOptions,
    @Inject(LOCALE_ID) private locale: string
  ) {
    super(options);
    this.initSubscriptions();
    this.isChildren = tool.isChildren;
    this.baseChart = tool.baseChart;
    this.tool = tool.tool;
    this.viewBoxWidth = tool.viewBoxWidth;
    this.viewBoxHeight = tool.viewBoxHeight;
    this.dictIdChildren = tool.dictIdChildren;
    this.tickSize = tool.stock.tick_size_denominator;
    this.isAux = tool.isAux;
    this.middleXPointValues = tool.middleXPointValues;
    this.middleYPointValues = tool.middleYPointValues;
    this.isDrawing = true;
    this.lastEventData = tool.eventData;
    this.configs = tool.configs;
    this.stock = tool.stock;
    this.isWithinDataBounds = tool.eventData.isWithinDataBounds;
    this.refComponent = tool.refComponent;
    this._updateFather$.next({
      function: () => {
        this.updateFather(TYPE_RESIZE.INITIAL);
      },
    });
  }

  unsubscriber(): void {
    this.axisSub && this.axisSub.unsubscribe();
  }

  initSubscriptions() {
    this.blockEditDraw$ = this.drawToolsService.blockEditDraw$
      .pipe(auditTime(100))
      .subscribe((data) => {
        this.blockEdit = data;
      });
    this.updateFather$ = this._updateFather$
      .pipe(debounceTime(100))
      .subscribe((data) => {
        data.function();
      });
    this.axisSub = this.drawToolsService.axisChanged$.subscribe((data) => {
      if (data.type === 'XAXIS') {
        this._updateFather$.next({
          function: () => {
            this.updateFather(TYPE_RESIZE.VISIBLE_RANGE);
          },
        });
        return;
      }
      this._updateFather$.next({
        function: () => {
          this.updateFather(TYPE_RESIZE.VISIBLE_RANGE);
        },
      });
    });
  }

  private destroySubscriptions() {
    this.updateFather$ && this.updateFather$.unsubscribe();
    this.blockEditDraw$ && this.blockEditDraw$.unsubscribe();
  }

  override onDragEnded(): void {
    super.onDragEnded();
    this.drawToolsService.saveAnnotationStock(
      this.baseChart,
      this,
      this.refComponent
    );
  }

  override onDetach(): void {
    super.onDetach();
    this.destroySubscriptions();
  }

  override delete(): void {
    super.delete();
    this.destroySubscriptions();
  }

  override clickToSelect(args: ModifierMouseArgs): boolean {
    if (this.blockEdit) return false;
    if (this.toolbarDrawService.hasElement()) {
      const drawToolbar = this.toolbarDrawService.getDrawTool()!!;
      if (drawToolbar.id === this.id && this.drawToolsService.isLocked) {
        return false;
      }
    }
    return super.clickToSelect(args);
  }

  override click(args: ModifierMouseArgs, selectOnClick: boolean): boolean {
    if (this.blockEdit) return false;
    if (this.startEdit) {
      return super.click(args, true);
    }
    if (this.toolbarDrawService.hasElement()) {
      const drawToolbar = this.toolbarDrawService.getDrawTool()!!;
      if (drawToolbar.id === this.id && this.drawToolsService.isLocked) {
        return false;
      }
    }
    return super.click(args, selectOnClick);
  }

  override onDragAdorner(args: ModifierMouseArgs): void {
    if (!this.isEditable) {
      return;
    }
    if (this.isDrawing) {
      this.isDrawing = false;
    }
    super.onDragAdorner(args);
    if (this.tool.group === TOOLS_ENUM.RULER) {
      this.lastPointValues = new Point(this.x2, this.y2);
      this.tool.xCategoryValue = this.baseChart.sciChartSurface.renderableSeries
        .asArray()[0]
        .getNativeXValues()
        .get(Math.floor(this.x1));
      this.lastEventData.xCategoryValue =
        this.baseChart.sciChartSurface.renderableSeries
          .asArray()[0]
          .getNativeXValues()
          .get(Math.floor(this.x2));
      this.repositionBox();
    }
    this.updateAux();
  }

  protected override notifyPropertyChanged(propertyName: string): void {
    super.notifyPropertyChanged(propertyName);
    if (!this.isAux && this.startEdit) {
      return;
    }
    if (this.isDrawing) {
      return;
    }

    if (
      propertyName === 'X1' ||
      propertyName === 'X2' ||
      propertyName === 'Y1' ||
      propertyName === 'Y2'
    ) {
      this.updateFather(TYPE_RESIZE.DRAG);
    }
  }

  protected override setAnnotationBorders(
    x1: number,
    x2: number,
    y1: number,
    y2: number
  ): void {
    super.setAnnotationBorders(x1, x2, y1, y2);
  }

  getName() {
    return this.constructor.name;
  }

  updateDrawTool(
    eventData: TMouseEventData,
    countStepClickTool: number,
    reposition?: DrawToolsReposition
  ) {
    if (eventData) {
      this.lastEventData = eventData;
      this.lastPointValues = eventData.pointValues!!;
    }
    const x2 = reposition?.x2 ? reposition.x2 : eventData.pointValues!!.x;
    const y2 = reposition?.y2 ? reposition.y2 : eventData.pointValues!!.y;
    const x1 = reposition?.x1;
    const y1 = reposition?.y1;
    this.x2 = x2;
    if (!this.tool.calculation) {
      this.y2 = y2;
    } else if (
      this.tool.calculation === TOOLS_CALCULATION.FIBONACCI_RETRACEMENT &&
      reposition &&
      reposition.id
    ) {
      this.y2 = y2;
    }
    this.x1 = x1 ? x1 : this.x1;
    this.y1 = y1 ? y1 : this.y1;
    if (this.tool.group === TOOLS_ENUM.RULER) {
      this.repositionBox(eventData, reposition);
    }
    this.updateAux();
  }

  private repositionBox(
    eventData?: TMouseEventData,
    reposition?: DrawToolsReposition
  ) {
    if (!this.tool.children) return;
    const child = this.tool.children.find(
      (c) => c.method === TOOLS_METHOD.CUSTOM_BOX_ANNOTATION
    )!!;
    const rectElement = document.getElementById(`RECT_${child.dictIdChildren}`);
    const textVerticalElement = document.getElementById(
      `TEXT_VERTICAL_DISTANCE_${child.dictIdChildren}`
    );
    const textBarElement = document.getElementById(
      `TEXT_BAR_${child.dictIdChildren}`
    );
    const textVolElement = document.getElementById(
      `TEXT_VOL_${child.dictIdChildren}`
    );
    if (rectElement) {
      const x = -65;
      let y = 10;
      let width = 110,
        height = 70;
      const draw = this.baseChart.sciChartSurface.annotations.getById(
        child.dictIdChildren!!
      );
      const median = (this.x1 + this.x2) / 2;
      const difference = this.y2 - this.y1;
      if (difference < 0) {
        this.fill = '#f2364533';
      } else {
        this.fill = '#4caf5033';
        y = -70;
      }
      draw.x1 = median;
      draw.y1 = this.y2;
      rectElement.setAttribute('x', `${x}`);
      rectElement.setAttribute('y', `${y}`);
      textBarElement!!.setAttribute('x', `${x + 10}`);
      textBarElement!!.setAttribute('y', `${y + 21}`);
      textVerticalElement!!.setAttribute('x', `${x + 10}`);
      textVerticalElement!!.setAttribute('y', `${y + 40}`);
      textVolElement!!.setAttribute('x', `${x + 10}`);
      textVolElement!!.setAttribute('y', `${y + 60}`);
      let date1!: Date;
      if (eventData) {
        date1 = new Date(eventData.xCategoryValue!!);
      } else {
        date1 = new Date(this.lastEventData.xCategoryValue!!);
      }
      const date2 = new Date(this.tool.xCategoryValue!!);
      const diffDates = date1.getTime() - date2.getTime();
      const minutes = Math.round(Math.abs(diffDates) / 1000 / 60);
      const minutesToHour = minutes / 60;
      let hours = parseInt(minutesToHour.toString());
      let typeTimer = 'h';
      const hourMinutes = (minutesToHour - hours) * 60;
      let hourMinutesText = ` ${parseInt(hourMinutes.toString())}m`;
      const vol = this.baseChart.sciChartSurface.renderableSeries.getById(
        CANDLE_IDS.VOLUME_SERIES
      );
      let sumVol = 0;
      let startIndex = Math.min(
        Math.round(this.tool.pointValues!!.x),
        Math.round(this.lastPointValues!!.x)
      );
      let endIndex = Math.max(
        Math.round(this.lastPointValues!!.x),
        Math.round(this.tool.pointValues!!.x)
      );
      const volNativeValues = vol.dataSeries.getNativeYValues();
      let plusEnd = 1;
      if (endIndex >= volNativeValues.size()) {
        plusEnd = 0;
        endIndex = volNativeValues.size() - 1;
      }
      if (startIndex > volNativeValues.size()) {
        startIndex = volNativeValues.size() - 1;
      }
      for (let i = startIndex; i < endIndex + plusEnd; i++) {
        const yValue = volNativeValues.get(i);
        sumVol += yValue ? Math.floor(yValue) : 0;
      }
      const objectInterval = TIGER_INTERVAL_ENUM;
      if (!objectInterval[this.configs.series.interval!!].includes('MINUTE')) {
        hourMinutesText = '';
      }
      if (
        objectInterval[this.configs.series.interval!!].includes('YEAR') ||
        objectInterval[this.configs.series.interval!!].includes('MONTH') ||
        objectInterval[this.configs.series.interval!!].includes('WEEK') ||
        objectInterval[this.configs.series.interval!!].includes('DAY')
      ) {
        hours = hours / 24;
        typeTimer = 'd';
      }
      const time =
        hours > 0
          ? `${hours}${typeTimer}${hourMinutesText}`
          : `${hourMinutesText.trim()}`;
      const tickSizeDenominator = this.stock.tick_size_denominator;
      const firstY = parseFloat(
        this.tool.pointValues!!.y.toFixed(tickSizeDenominator)
      );
      const secondY = parseFloat(
        this.lastEventData!!.pointValues!!.y.toFixed(tickSizeDenominator)
      );
      const percent = (secondY / firstY - 1) * 100;
      let diffCalc = difference * 100;
      if (
        reposition?.stock.ds_asset === 'DOL' ||
        reposition?.stock.ds_asset === 'WDO'
      ) {
        diffCalc = difference / 0.5;
      }
      if (reposition?.stock.ds_asset === 'WIN') {
        diffCalc = difference / 5;
      }
      const textVol =
        reposition?.stock.cd_security_type === 'FUT' ? 'Contrato' : 'Vol';
      const negativeSign = diffCalc < 0 ? '-' : '';
      let financial =
        reposition?.stock.cd_security_type === 'FUT'
          ? `${formatNumber(Math.abs(diffCalc), this.locale, `1.0-0`)}`
          : '';
      let preffix =
        reposition?.stock.cd_segment == 8888 ||
        reposition?.stock.id_exchange == 7777
          ? `${negativeSign}$`
          : `${negativeSign}R$`;
      if (financial !== '') {
        financial = ', ' + preffix + financial;
        preffix = '';
      }
      textVerticalElement!!.textContent = `${preffix}${formatNumber(
        Math.abs(difference),
        this.locale,
        `1.${this.tickSize}-${this.tickSize}`
      )} | ${formatNumber(percent, this.locale, `1.2-2`)}%${financial}`;
      let negativeTime = '';
      if (diffDates < 0) {
        negativeTime = '-';
      }
      textBarElement!!.textContent = `Barras: ${formatNumber(
        Math.abs(startIndex - endIndex),
        this.locale,
        `1.0-0`
      )}, ${negativeTime}${time}`;
      textVolElement!!.textContent = `${textVol}: ${getVolumeText(
        this.locale,
        sumVol
      )}`;
      textVolElement!!.style.display = 'block';
      if (sumVol === 0) {
        textVolElement!!.hidden = true;
        textVolElement!!.style.display = 'none';
        height = 50;
      }
      width =
        Math.max(
          textVerticalElement!!.getBoundingClientRect().width,
          textBarElement!!.getBoundingClientRect().width,
          textVolElement!!.getBoundingClientRect().width
        ) + 20;
      rectElement.setAttribute('width', `${width}`);
      rectElement.setAttribute('height', `${height}`);
    }
  }

  private updateAux() {
    if (this.isChildren) {
      return;
    }
    if (this.tool.hasBoxAux && this.tool.children) {
      if (this.tool.codTool === `${TOOLS_ENUM.GEOMETRIC_SHAPES}_RECTANGLE`) {
        const children = this.tool.children.filter((c) => c.isAux)!!;
        children.forEach((child) => {
          const aux = this.baseChart.sciChartSurface.annotations.getById(
            child.dictIdChildren!!
          ) as DrawTools;
          aux.isDrawing = true;
          if (child.typeAux) {
            if (child.typeAux === 'middle') {
              aux.x1 = (this.x1 + this.x2) / 2;
              aux.x2 = (this.x1 + this.x2) / 2;
              aux.y2 = this.y2;
              aux.y1 = this.y1;
            }
            if (child.typeAux === 'center') {
              aux.y1 = (this.y1 + this.y2) / 2;
              aux.y2 = (this.y1 + this.y2) / 2;
              aux.x2 = this.x2;
              aux.x1 = this.x1;
            }
          }
        });
      }
    }
  }

  customClick = () => {
    //do nothing.
  };

  repositionZoomDraw(): void {
    //do nothing.
  }

  customHover = () => {
    //do nothing.
  };

  updateStyleTool(style: StyleTool) {
    let childrenDraw!: TIGER_CHART_TOOL_CHILD;
    if (this.isChildren && this.tool.children) {
      childrenDraw = this.tool.children.find(
        (child) => child.dictIdChildren === this.dictIdChildren
      )!!;
    }
    if (style.color) {
      this.stroke = style.color.rgbaHexColor;
      if (childrenDraw) {
        childrenDraw.color = style.color.hexColor;
      } else {
        this.tool.color = style.color.rgbaHexColor;
      }
    }
    if (style.backgroundColor) {
      this.fill = style.backgroundColor.rgbaHexColor;
      if (childrenDraw) {
        childrenDraw.backgroundColor = style.backgroundColor.rgbaHexColor;
      } else {
        this.tool.backgroundColor = style.backgroundColor.rgbaHexColor;
      }
    }
    if (
      style.strokeThickness &&
      this.tool.calculation !== TOOLS_CALCULATION.FIBONACCI_RETRACEMENT
    ) {
      this.strokeThickness = style.strokeThickness;
      if (childrenDraw) {
        childrenDraw.lineThickness = style.strokeThickness;
      } else {
        this.tool.lineThickness = style.strokeThickness;
      }
    }
  }

  updateTool(tool: TIGER_CHART_TOOL) {
    this.tool = tool;
  }

  updateFather(typeResize: TYPE_RESIZE) {
    if (this.isDrawing) {
      return;
    }
    const childrenConfig = this.tool.children?.find(
      (child) => child.dictIdChildren === this.dictIdChildren
    );
    if (
      this.isChildren &&
      childrenConfig &&
      childrenConfig.isAux &&
      this.tool.hasBoxAux
    ) {
      const father = this.baseChart.sciChartSurface.annotations.getById(
        this.tool.dictId!!
      ) as DrawTools;
      const borders = this.getAnnotationBorders();
      if (father && father.resizeSVG) {
        father.resizeSVG(
          typeResize,
          this.dictIdChildren,
          {
            x1: 0,
            x2: borders.x2 - borders.x1,
            y1: 0,
            y2: borders.y2 - borders.y1,
          },
          { x1: this.x1, x2: this.x2, y1: this.y1, y2: this.y2 }
        );
      }
    }
  }

  resizeSVG(
    typeResize: string,
    childId: string,
    svgPosition?: { x1: number; x2: number; y1: number; y2: number },
    position?: { x1: number; x2: number; y1: number; y2: number }
  ) {
    if (!position || !this.tool.children) {
      return;
    }
    const aux = this.tool.children.find(
      (child) => child.isAux && child.dictIdChildren === childId
    );
    const secondAux = this.tool.children?.find(
      (child) => child.isAux && child.dictIdChildren != childId
    );
    if (aux && secondAux) {
      const secondLine = this.baseChart.sciChartSurface.annotations.getById(
        secondAux.dictIdChildren!!
      );
      if (aux.typeAux === 'middle') {
        this.y1 = position.y1;
        this.y2 = position.y2;
        secondLine.y1 = (this.y1 + this.y2) / 2;
        secondLine.y2 = (this.y1 + this.y2) / 2;
      }
      if (aux.typeAux === 'center') {
        this.x1 = position.x1;
        this.x2 = position.x2;
        secondLine.x1 = (this.x1 + this.x2) / 2;
        secondLine.x2 = (this.x1 + this.x2) / 2;
      }
    }
  }

  private getAxis(): Axis {
    const yAxis = this.baseChart.sciChartSurface.yAxes.getById(this.yAxisId);
    const xAxis = this.baseChart.sciChartSurface.xAxes.getById(this.xAxisId);
    if (!yAxis || !xAxis) {
      return { xAxis: undefined, yAxis: undefined };
    }
    try {
      yAxis.getCurrentCoordinateCalculator();
      xAxis.getCurrentCoordinateCalculator();
    } catch (e: any) {
      return { xAxis: undefined, yAxis: undefined };
    }
    return { yAxis, xAxis };
  }
}
