import { Routes, RouterModule } from '@angular/router';
import { TableBookComponent } from './table-book.component';

const routes: Routes = [
  {
    path: '',
    component: TableBookComponent,
  },
];

export const TableBookRoutes = RouterModule.forChild(routes);
