import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';
import { OptionsComponents } from '../component-list';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';

export const HEATMAP_COMPONENT: any = (options?: OptionsComponents) => {
  return {
    name: 'Mapa de mercado',
    id: COMPONENTS_NAMES_ENUM.HEATMAP,
    icon: 'view_quilt',
    cod: 'HEATMAP',
    data: options?.data ?? null,
    close: true,
    fullscreen: true,
    link: isNullOrUndefined(options?.link) ? true : options!.link,
    widthDesk: 500,
    heightDesk: 400,
    unitOfMeasurement: options?.unit ?? 'px',
    height: options?.height ?? 350,
    width: options?.width ?? 460,
    minWidth: '460px',
    left: options?.left ?? 'calc(15% + 5px)',
    top: options?.top ?? '0px',
    label: 'Mapa de mercado',
    borderClass: 'border-top border-neutral-stronger',
    noPublished: false,
    iconSvg: '',
  };
};
