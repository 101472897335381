<div *ngIf="!loading; else loadingRef" class="d-flex h-100">
  <div class="about w-100 d-flex flex-column gap-2 mt-3">
    <span>Sobre</span>
    <ng-container *ngIf="companyInfo; else noInfo">
      <span class="truncated fs-5">{{ companyInfo }}</span>
    </ng-container>
  </div>
</div>

<ng-template #loadingRef>
  <div class="h-100">
    <app-loading></app-loading>
  </div>
</ng-template>

<ng-template #noInfo>
  <div class="h-100 w-100 d-flex justify-content-center align-items-center">
    <app-no-info></app-no-info>
  </div>
</ng-template>
