<div
  class="d-flex flex-column gap-2 h-100 position-relative"
  *ngIf="rows.length; else loadingRef"
>
  <div *ngIf="!isAcionistas" class="d-flex gap-1 mt-2">
    <span class="fs-5 text-neutral-medium">Data de Referência: </span>
    <span class="fs-5">{{ referenceDate }}</span>
  </div>
  <div class="h-100">
    <div *ngIf="isAcionistas; else subsidiaria" class="h-100">
      <table *ngIf="!error; else noInfo" class="h-100 w-100">
        <thead>
          <tr class="w-100 d-flex">
            <th scope="col" class="fs-6 fw-bold text-left w-50">Nome</th>
            <th
              *ngFor="let date of datesArray"
              class="fs-6 fw-bold text-center col-md-2"
              scope="col"
            >
              <span>{{ date.dtFormatted }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <cdk-virtual-scroll-viewport
            #scrollViewport
            itemSize="24"
            class="w-100 h-100"
          >
            <ng-container *cdkVirtualFor="let row of rows">
              <tr class="text-white align-baseline w-100 d-flex">
                <td class="py-1 text-white p-0 pl-0 text-left text-nowrap w-50">
                  <span>{{ row.title }}</span>
                </td>
                <td
                  class="py-1 text-white p-0 pl-0 text-right text-nowrap col-md-2"
                  *ngFor="let date of datesArray"
                >
                  <span class="py-1 text-white p-0 pl-0 text-right text-nowrap">
                    <ng-container
                      *ngIf="
                        row['date_' + date.dt_entry + '_total'];
                        else noValue
                      "
                    >
                      {{ row['date_' + date.dt_entry + '_total'] }}
                    </ng-container>
                  </span>
                </td>
              </tr>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #loadingRef>
  <div class="h-100">
    <app-loading></app-loading>
  </div>
</ng-template>
<ng-template #noInfo>
  <div class="h-100 w-100 d-flex justify-content-center align-items-center">
    <app-no-info></app-no-info>
  </div>
</ng-template>
<ng-template #noValue> - </ng-template>
<ng-template #subsidiaria>
  <div class="h-100">
    <table *ngIf="!error; else noInfo" class="h-100 w-100">
      <thead>
        <tr class="w-100 d-flex">
          <th scope="col" class="col">Origem</th>
          <th scope="col" class="text-center col">Participação</th>
        </tr>
      </thead>
      <tbody>
        <cdk-virtual-scroll-viewport
          #scrollViewport
          itemSize="24"
          class="w-100 h-100"
        >
          <ng-container *cdkVirtualFor="let row of rows; let lineIndex = index">
            <tr class="text-white align-baseline w-100 d-flex">
              <td class="py-1 text-white p-0 pl-0 text-left text-nowrap col">
                <span>{{ row.countryName }}</span>
              </td>
              <td class="py-1 text-white p-0 pl-0 text-right text-nowrap col">
                <span>{{ row.pc }}</span>
              </td>
            </tr>
          </ng-container>
        </cdk-virtual-scroll-viewport>
      </tbody>
    </table>
  </div>
</ng-template>
