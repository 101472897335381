<div class="w-100 h-100 option-graph">
  <div
    [id]="refId"
    [style.height.px]="componentHeight() / 2 - 90"
    class="w-100 smile-graph"
  ></div>
  <div
    *ngIf="!loading && !loadingGraph && (!data || data.length === 0)"
    [id]="noDataId"
    [style.height.px]="componentHeight() / 4"
    class="w-100 fs-5 graph-no-data d-flex justify-content-center align-items-center"
  >
    Sem dados para exibir
  </div>
  <div [id]="legendId" class="w-100 fs-6 smile-graph-legend"></div>
</div>
