export enum TYPE_ORDE_SIDE_ENUM {
  BUY = 1,
  SELL = 2,
}

export enum TYPE_ORDE_SIDE_STRING_ENUM {
  BUY = 'B',
  SELL = 'S',
}

export const SideDict: any = {
  '1': 'Compra',
  '2': 'Venda',
  B: 'Compra',
  S: 'Venda',
};

export enum ORDER_CONFIRM_ACTIONS_ENUM {
  USE_SPLIT = 'use_split',
  DISPLAY_SPLIT_CONFIRMATION = 'display_split_confirmation',
  RESET = 'reset',
  CANCEL = 'cancel',
  INVERT = 'invert',
  SCHEDULE = 'schedule_order',
  CONNECT_BROKER = 'connect_broker',
  SIMULATOR_VISIBILITY = 'simulator_visibility',
  AUDIO_NOTIFICATION = 'audio_notification',
  QTTY_GROUP_STOCK = 'qtty_group_stock',
}
