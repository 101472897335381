<rocket-modal>
  <rocket-modal-header>
    <div class="d-flex flex-column">
      <span *ngIf="tooltipLabel">
        {{ tooltipLabel }}
      </span>
      <span
        *ngIf="data.event.dsDocumentDescription"
        class="text-neutral-medium fs-5"
      >
        {{ data.event.dsDocumentDescription }}
      </span>
      <span
        *ngIf="data.event.dsDocumentCategoryPt"
        class="text-neutral-medium fs-5"
      >
        {{ data.event.dsDocumentCategoryPt }}
      </span>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div>
      <div class="w-100 mb-6">
        <ngx-extended-pdf-viewer
          *ngIf="document"
          [showDrawEditor]="true"
          [showTextEditor]="true"
          [showPrintButton]="true"
          [showDownloadButton]="true"
          [textLayer]="false"
          [src]="document"
          [height]="'400px'"
          [showHandToolButton]="true"
          [showOpenFileButton]="false"
          [showPresentationModeButton]="true"
          [showPropertiesButton]="false"
          [showRotateButton]="false"
          [handTool]="true"
          [showSecondaryToolbarButton]="false"
          [showSidebarButton]="false"
          [showSpreadButton]="false"
        ></ngx-extended-pdf-viewer>
      </div>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
