import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { VIEW_SCREENING } from '../constants/constants';
import { TViewScreening } from '../interfaces/indicator.interface';

@Component({
  selector: 'app-stock-screening-header',
  templateUrl: './stock-screening-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockScreeningHeaderComponent {
  @Output() changeTab: EventEmitter<TViewScreening> =
    new EventEmitter<TViewScreening>();
  public readonly viewScreening = VIEW_SCREENING;
  public tabs = [
    {
      name: 'Condições',
      type: VIEW_SCREENING[0],
    },
    {
      name: 'Execução',
      type: VIEW_SCREENING[1],
    },
  ];
  public selectedTab = VIEW_SCREENING[0];

  constructor(private cdr: ChangeDetectorRef) {}

  public selectTab(tab: any): void {
    if (tab.type === this.selectedTab) return;
    this.selectedTab = tab.type;
    this.cdr.detectChanges();
    this.changeTab.emit(tab.type);
  }
}
