import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { RestService } from '@shared/services/rest/rest.service';
import { INews } from '@core/interface/news-agency.interface';

@Injectable({
  providedIn: 'root',
})
export class TrademapHighlightsService extends RestService {
  override _url: string = 'api/trademap';

  constructor(http: HttpClient) {
    super(http);
  }

  getHighlightCards = (
    offset: number = 0,
    limit: number = 12
  ): Observable<INews[]> =>
    this.post<any>('v9/highlights/get-highlight-cards-labs', {
      period: 'next',
      filters: { isRocket: true },
      offset,
      limit,
    }).pipe(
      map((res) => {
        if (res.data && res.data?.success && res.data?.result) {
          return res.data.result;
        }
        return null;
      }),
      catchError((error: any) => of(error))
    );
}
