import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { IStockChartConfig } from '@shared/components/stock-chart/interface/stock-chart.interface';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { ICandleConfigs } from '@shared/tiger-chart/interface';
import { deepClone } from '@shared/rocket-components/utils';
import { StockChartModalService } from '../service/stock-chart-modal.service';
import { ChartLinesConfiguration } from '../../modal-stock/global-chart-line-configuration';
import {
  INPUT_LINE_SIDE_OPTIONS,
  LINE_OPTIONS,
} from '../const/stock-chart-modal-lines.config.const';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LINE_INPUT } from '../interface/stock-chart-modal.interface';
import { INPUT_TYPES } from '../enum/stock-chart-modal.enum';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
@Component({
  selector: 'app-stock-chart-modal-config-lines',
  templateUrl: './stock-chart-modal-config-lines.component.html',
  styleUrls: ['./stock-chart-modal-config-lines.component.scss'],
})
export class StockChartModalConfigLinesComponent
  extends RocketModalRef
  implements OnInit, OnDestroy
{
  @Input() configs!: Partial<ICandleConfigs>;
  @Input() refComponent!: string;

  defaultLineConfiguration!: ChartLinesConfiguration;
  public fields!: LINE_INPUT[];
  public form!: FormGroup;
  public inputTypes = INPUT_TYPES;
  public lineSideOptions = INPUT_LINE_SIDE_OPTIONS;
  private enabledField = 'enabled'
  constructor(
    @Inject(ROCKET_MODAL_DATA)
    public data: {
      config: IStockChartConfig;
      ref: string;
      isSecondModal: boolean;
    },
    _service: RocketModalService,
    private stockChartModalService: StockChartModalService,
    private _fb: FormBuilder,
    public _stockService: StockChartService
  ) {
    super(_service);
  }

  ngOnInit() {
    this.initLineConfig();
  }

  initLineConfig() {
    this.fields = LINE_OPTIONS;
    this.defaultLineConfiguration = deepClone(
      this.stockChartModalService.getChartLinesConfigs()
    );
    this.form = this._fb.group(this.buildForm(this.fields));
  }

  buildForm(form: LINE_INPUT[]) {
    const controls: any = {};
    form.forEach((input: LINE_INPUT) => {
      input.fields.forEach((field) => {
        const valueSource: any = this.defaultLineConfiguration;
        if (field.type === INPUT_TYPES.CHECKBOX) {
          const formControlName = `${input.formControlName}_${field.key}`;
          controls[formControlName] =
            valueSource[input.formControlName].enabled ??
            valueSource[input.formControlName];
        } else if (field.type === INPUT_TYPES.SELECT) {
          const formControlName = `${input.formControlName}_${field.key}`;
          controls[formControlName] = valueSource[input.formControlName][
            field.key
          ]
            ? this.lineSideOptions[1].key
            : this.lineSideOptions[0].key;
        }
      });
    });
    return controls;
  }

  getFormControlName = (input: LINE_INPUT, field: string) =>
    `${input.formControlName}_${field}`;

  updateConfig = (input: LINE_INPUT, field: string) => {
    const formValue = this.form.get(
      this.getFormControlName(input, field)
    )?.value;
    const inputField = (this.stockChartModalService.getChartLinesConfigs() as any)[
      input.formControlName
    ];
    if(field === 'rightSide'){
      inputField[field] = formValue === '1' ? true : false;
    }else{
      inputField[field] = formValue;
    }
    this.stockChartModalService.updateChartLinesConfigs({
      [input.formControlName]: inputField,
    });
    this.updateConfigOnChart(input, inputField)
  };

  updateConfigOnChart = (input: LINE_INPUT, inputField: any) => {
    this._stockService.eventConfigs$.next({
      ref: this.data.ref,
      config: {
        ...this.data.config.chart,
        [input.formControlName]: inputField
      },
      save: false,
      global: true,
      typeUpdate: input.chartUpdate,
    });
  }

  resetToDefault = () => {
    const defaultChartLine = new ChartLinesConfiguration();
    Object.keys(defaultChartLine).forEach((key: string) => {
      const values = (defaultChartLine as any)[key];
      const valuesKeys = Object.keys(values);
      const inputIndex = this.fields.findIndex(item => item.formControlName === key) 
      valuesKeys.forEach((field) => {
        let value = values[field]
        if(field === 'rightSide'){
          value = value ? '1' : '0'
        }
        this.form.controls[`${key}_${field}`]?.setValue(value);
      });
      if(inputIndex !== -1){
        this.updateConfigOnChart(this.fields[inputIndex], values)
      }
    });
    this.stockChartModalService.updateChartLinesConfigs(defaultChartLine);
  };

  unSaveConfigs = () => {
    this.stockChartModalService.updateChartLinesConfigs(
      this.defaultLineConfiguration
    );
    this.fields.forEach((input) => {
      const value: any = this.defaultLineConfiguration
      this.updateConfigOnChart(input, value[input.formControlName])
    })
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  closeModal(save: boolean) {
    if(!save){
      this.unSaveConfigs()
    }
    this.close(save, true);
  }
}
