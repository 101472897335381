import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { IStockAuctionInfos } from '@shared/interfaces/stock-auction-infos.interface';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  bigValueFormatter,
  formatterNumber,
} from 'src/app/utils/utils.functions';

@Component({
  selector: 'app-details-tab',
  templateUrl: './details-tab.component.html',
  styleUrls: ['./details-tab.component.scss'],
})
export class DetailsTabComponent implements OnChanges, OnDestroy {
  @Input() cdStock!: string;
  @Input() precoUltimo!: number | string;
  @Input() variacaoDia!: number | string;
  @Input() precoMinimo!: number | string;
  @Input() precoMaximo!: number | string;
  @Input() volume!: any;
  @Input() tickSizeDenominator!: number;
  @Input() dsType!: string;
  @Input() arrowHex!: string;
  @Input() stockAuctionInfos!: IStockAuctionInfos;
  public variation = '0';
  public situation = '';
  public minimumPriceClass = '';
  public maximumPriceClass = '';
  private minimumPrice = 0;
  private maximumPrice = 0;
  private changeHighlight$: Subject<void> = new Subject<void>();

  constructor() {
    this.changeHighlight$.pipe(debounceTime(1000)).subscribe(() => {
      this.minimumPriceClass = '';
      this.maximumPriceClass = '';
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {
      volume,
      variacaoDia,
      stockAuctionInfos,
      precoMinimo,
      precoMaximo,
      cdStock,
    } = changes;
    if (cdStock?.currentValue) {
      this.minimumPrice = 0;
      this.maximumPrice = 0;
    }
    volume?.currentValue &&
      volume.previousValue !== volume.currentValue &&
      (this.volume = bigValueFormatter(volume.currentValue));
    variacaoDia?.currentValue &&
      (this.variation = formatterNumber(+variacaoDia.currentValue));
    stockAuctionInfos?.currentValue &&
      (this.situation = this.stockAuctionInfos.situacao);
    (precoMaximo?.currentValue || precoMinimo?.currentValue) &&
      this.highlightPriceMinOrMax();
  }

  ngOnDestroy(): void {
    this.changeHighlight$?.unsubscribe();
  }

  private highlightPriceMinOrMax(): void {
    !this.minimumPrice && (this.minimumPrice = +this.precoMinimo);
    !this.maximumPrice && (this.maximumPrice = +this.precoMaximo);
    if (this.minimumPrice > +this.precoMinimo) {
      this.minimumPrice = +this.precoMinimo;
      this.minimumPriceClass = 'text-feedback-warning';
      this.changeHighlight$.next();
    }
    if (this.maximumPrice < +this.precoMaximo) {
      this.maximumPrice = +this.precoMaximo;
      this.maximumPriceClass = 'text-feedback-warning';
      this.changeHighlight$.next();
    }
  }
}
