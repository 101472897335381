import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '@shared/services/rest/rest.service';
import {
  catchError,
  debounceTime,
  firstValueFrom,
  forkJoin,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { IMetadata, IWorkSpaceComponet } from '@core/interface';

@Injectable({
  providedIn: 'root',
})
export class ComponentsService extends RestService {
  override _url: string = 'api/nitro-ws/v1';

  private updateMetadataBuffer$: Subject<{ id: string; metadata: IMetadata }> =
    new Subject<{ id: string; metadata: IMetadata }>();
  private listMetadataBuffer: any = {};

  constructor(http: HttpClient) {
    super(http);
    this.startBufferUpdateMetadata();
  }

  public create(component: IWorkSpaceComponet) {
    return this.post<IWorkSpaceComponet>(
      `components`,
      this._dataAssign(component)
    ).pipe(map((res) => res.data));
  }

  public getComponentById(component_id: string) {
    return this.get<IWorkSpaceComponet | any>(
      `components/${component_id}`
    ).pipe(
      map((res) => {
        const data = res.data;
        const metadata = JSON.parse(data.metadata);
        return { ...data, metadata };
      })
    );
  }

  public update(component: IWorkSpaceComponet) {
    return this.put<IWorkSpaceComponet>(
      `components/${component.id}`,
      this._dataAssign(component)
    ).pipe(map((res) => res.data));
  }

  private updateMetadataByBuffer(id: string, metadata: IMetadata) {
    const httpOptions = new HttpHeaders({
      'Content-Type': 'text/plain',
      Accept: 'text/plain',
      responseType: 'text',
    });

    return this.put<IMetadata>(
      `components/${id}/metadata`,
      metadata,
      httpOptions
    ).pipe(map((res) => res.data));
  }

  public updateMetadata(id: string, metadata: IMetadata): void {
    this.updateMetadataBuffer$.next({ id, metadata });
  }

  public remove(id: string) {
    return firstValueFrom(
      this.delete<IWorkSpaceComponet>(`components/${id}`)
    ).then();
  }

  private _dataAssign(component: IWorkSpaceComponet) {
    const data: IWorkSpaceComponet<any> = Object.assign({}, component);
    if (typeof component.metadata === 'object') {
      data.metadata = JSON.stringify(component.metadata);
    }

    return data;
  }

  public removeComponents(components: IWorkSpaceComponet[]) {
    return new Promise((resolve) => {
      components?.map((component: any) => {
        this.remove(component.id);
      });
      resolve([]);
    });
  }

  public createComponents(components: IWorkSpaceComponet[]) {
    return this.post<IWorkSpaceComponet>(`components/list`, components).pipe(
      map((res: any) =>
        res.data.map((data: any) => {
          data.metadata = JSON.parse(data.metadata);
          return data;
        })
      )
    );
  }

  private updateMetadataBatch(updates: string[]): Observable<void> {
    return new Observable((observer) => {
      const updateRequests = updates.map((id: string) => {
        const metadata = structuredClone(this.listMetadataBuffer[id].metadata);
        this.listMetadataBuffer[id] = null;
        return this.updateMetadataByBuffer(id, metadata).pipe(
          catchError((err) => {
            console.error(`Erro ao atualizar o metadado com ID ${id}:`, err);
            return of(null);
          })
        );
      });

      forkJoin(updateRequests).subscribe({
        next: () => {
          observer.next();
          observer.complete();
        },
        error: (err) => observer.error(err),
      });
    });
  }

  private startBufferUpdateMetadata(): void {
    this.updateMetadataBuffer$
      .pipe(
        tap(
          (item: { id: string; metadata: IMetadata }) =>
            (this.listMetadataBuffer[item.id] = item)
        ),
        debounceTime(1000),
        switchMap(() => {
          const updates = Object.keys(this.listMetadataBuffer).filter(
            (id: string) => !!this.listMetadataBuffer[id]
          );
          return this.updateMetadataBatch(updates);
        })
      )
      .subscribe();
  }
}
