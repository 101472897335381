import {
  TTigerChartIndicatorCreateOptions,
  TTigerChartIndicatorMovingAverageOptions,
} from './indicators.types';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { TIGER_INDICATORS_ENUM } from '../enum';
import { TigerChartIndicatorADXBase } from './base/adx-base';

export class TigerChartAverageDirectionalMovementIndex extends TigerChartIndicatorADXBase {
  constructor(options: TTigerChartIndicatorMovingAverageOptions) {
    super(options, TIGER_INDICATORS_ENUM.AVERAGE_DIRECTIONAL_X);
  }

  override create(
    options: TTigerChartIndicatorCreateOptions
  ): BaseRenderableSeries[] {
    this.updatePoints();

    this.adxData = this.calcADX(
      this.data.vl_high,
      this.data.vl_low,
      this.data.vl_close,
      this.timePeriod,
      this.smoothing,
      this.data.id_point.length - 1
    );
    this.points.output = this.adxData;

    return super.create(options);
  }

  override updatePoints(): void {
    this.points.output = this.calcADX(
      this.data.vl_high,
      this.data.vl_low,
      this.data.vl_close,
      this.timePeriod,
      this.smoothing,
      this.data.id_point.length - 1
    );
  }

  override updateSettings() {
    this.points.output = this.calcADX(
      this.data.vl_high,
      this.data.vl_low,
      this.data.vl_close,
      this.timePeriod,
      this.smoothing,
      this.data.id_point.length - 1
    );

    super.updateSettings();
  }

  public calcADX(
    inHigh: number[],
    inLow: number[],
    inClose: number[],
    timePeriod: number,
    smoothing: number,
    endIdx: number
  ) {
    const adxlen: number = smoothing;
    const dilen: number = timePeriod;
    const arrUp = [];
    const arrDown = [];
    const arrPlusDM = [];
    const arrMinusDM = [];
    const arrADX = [];
    const arrADXFinal = [];

    for (let i = 0; i <= endIdx; i++) {
      const up = parseFloat((inHigh[i] - inHigh[i - 1]).toFixed(2));
      const down = parseFloat((inLow[i - 1] - inLow[i]).toFixed(2));
      arrUp.push(up);
      arrDown.push(down);
      const plusDM = isNaN(up) ? 0.0 : up > down && up > 0 ? up : 0;
      const minusDM = isNaN(down) ? 0.0 : down > up && down > 0 ? down : 0;
      arrPlusDM.push(plusDM);
      arrMinusDM.push(minusDM);
      const tr: number[] = this.calculateTrueRanges(inHigh, inLow, inClose);
      const truerange: number = this.calculateRMA(tr, dilen, i);
      const plus = (100 * this.calculateRMA(arrPlusDM, dilen, i)) / truerange;
      const minus = (100 * this.calculateRMA(arrMinusDM, dilen, i)) / truerange;
      const sum = isNaN(plus + minus) ? 0.0 : plus + minus;
      const substract = isNaN(plus - minus) ? 0.0 : plus - minus;
      const pos_subsctract = Math.abs(substract) / (sum == 0 ? 1 : sum);

      arrADX.push(pos_subsctract);
      const adx = parseFloat(
        (100 * this.calculateRMA(arrADX, adxlen, i)).toFixed(2)
      );
      arrADXFinal.push(adx);
    }
    return arrADXFinal;
  }

  public calculateTrueRanges(high: number[], low: number[], close: number[]) {
    const trueRanges = [];

    for (var i = 0; i < high.length; i++) {
      const highLowDiff = high[i] - low[i];
      const highPrevCloseDiff = Math.abs(high[i] - close[i - 1]);
      const lowPrevCloseDiff = Math.abs(low[i] - close[i - 1]);
      const trueRange = Math.max(
        highLowDiff,
        highPrevCloseDiff,
        lowPrevCloseDiff
      );
      trueRanges.push(isNaN(trueRange) ? 0 : trueRange);
    }
    return trueRanges;
  }

  public calculateRMA(src: any, length: any, index: number) {
    const alpha = 1 / length;
    const rmaArray = [];
    let sum = 0.0;

    for (let i = 0; i < src.length; i++) {
      if (isNaN(sum)) {
        sum = 0;
      } else {
        sum = parseFloat((alpha * src[i] + (1 - alpha) * sum).toFixed(4));
      }
      rmaArray.push(sum);
    }
    return rmaArray[index];
  }

  public calculateFinalTR(tr: any, length: any, soma: any) {
    const alpha = 1 / length;
    let sum = 0.0;
    sum = alpha * tr + (1 - alpha) * soma;

    if (isNaN(sum) || !isFinite(sum)) {
      return 0.0;
    }

    return sum;
  }

  public updateADX() {
    const endIdx = this.adxData.length;
    this.adxData[endIdx] = this.adxData[endIdx - 1];
    return this.adxData;
  }
}
