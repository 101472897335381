import { formatNumber } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import {
  CONTEXT_MENU_ORDER_TYPE,
  TIGER_INDICATORS_ENUM,
  TIGER_LINE_TYPE,
} from '@shared/tiger-chart/enum';
import { TYPE_ORDE_SIDE_STRING_ENUM } from '@shared/enum/buyOrSell.enum';
import { isDI } from 'src/app/utils/utils.functions';
import {
  IBreakEvenValueToPlot,
  IPosition,
  IStockChartYLabelFormatterOptions,
} from './interface/stock-chart.interface';
import { BaseIndicator } from '@shared/tiger-chart/indicators';
import { COLORS } from '@shared/constants/colors.const';
import { COVERAGE_PRICE_ID } from './constants/stock-chart.constant';
import { ISearchStock } from '@core/interface';
import { ECoordinateMode } from 'scichart';
import { IOverrideCustodyBox } from '@shared/tiger-chart/interface';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { LINE_OFFSET } from '@shared/tiger-chart/constants/tiger-chart.constants';
import { TCandleLineOptions } from '@shared/tiger-chart/types/tiger-chart.types';
import { CHART_LINE_FIELD_CONFIG } from './parts/modal-more-options/interface/stock-chart-modal.interface';

export class ConsolidatedTotal {
  price: number = 0;
  qtd: number = 0;
  pnl: number = 0;
  monetario: number = 0;
  constructor(data: any) {
    data.forEach((value: any) => {
      this.price += parseFloat(value.vl_price_avg);
      this.qtd += parseFloat(value.qtty_final);
      this.pnl += parseFloat(value.pnl);
      this.monetario +=
        parseFloat(value.qtty_final) * parseFloat(value.vl_price_avg);
    });
  }
}
@Injectable({
  providedIn: 'root',
})
export class StockChartHelper {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private customPreferencesService: CustomPreferencesService
  ) {}
  getConsolidated() {
    return {
      id_broker: -99,
      nm_broker: 'Consolidada',
      accounts: [],
      account_number: '-99',
      label: 'Consolidada',
      key: 'CONSOLIDATED',
    };
  }

  formatBoxLabel = (value: number, ticksize: number) =>
    formatNumber(value, this.locale, `1.${ticksize}-${ticksize}`);

  createLinePosition(
    value: number,
    qtd: number,
    pnl: number,
    vlPriceAvg: number,
    boxId: string,
    data: IPosition,
    ticksize: number,
    stock: ISearchStock,
    hasDoubleStop: boolean,
    dayTradeIsEnable: boolean,
    isBreakEvenClicked: boolean
  ): TCandleLineOptions {
    const infoPnl = this.getBreakEvenValueToPlot(
      stock,
      pnl,
      data,
      isBreakEvenClicked,
      true
    );
    const { percent, pts } = this.getValuePreffix(infoPnl, stock);
    const color: string = COLORS.CUSTODY;
    const boxLabel: string = `| ${this.formatBoxLabel(
      qtd,
      0
    )} | ${this.formatBoxLabel(infoPnl.value, ticksize)}${percent}${pts}`;
    return {
      value,
      color,
      boxLabel,
      boxId,
      data,
      undraggable: true,
      showStopOrders: true,
      type: TIGER_LINE_TYPE.CUSTODY,
      tooltipLabel: this.getTooltipForType(TIGER_LINE_TYPE.CUSTODY, {
        quantity: qtd,
        pnl,
        vl_price_avg: vlPriceAvg,
        id_exchange: stock.id_exchange,
        labelPrecision: ticksize,
        dayTradeIsEnable,
      }),
      hasDoubleStop,
    };
  }

  createLineBreakEven(
    userCustody: IPosition,
    stock: ISearchStock,
    isBreakEvenClicked: boolean,
    config: CHART_LINE_FIELD_CONFIG,
    chartWidth: number
  ): TCandleLineOptions {
    const value = userCustody.coveragePrice;
    const boxId = COVERAGE_PRICE_ID;
    const { boxLabel, breakEven } = this.getBoxLabelBreakEven(
      userCustody,
      stock,
      isBreakEvenClicked
    );
    const color =
      breakEven.value > 0
        ? CHART_COLORS.FEEDBACK_SUCCESS
        : CHART_COLORS.FEEDBACK_ERROR;
    const boxTextColor =
      breakEven.value > 0
        ? CHART_COLORS.NEUTRAL_STRONGER
        : CHART_COLORS.NEUTRAL_SMOOTHER;
    return {
      value,
      color,
      boxLabel,
      boxId,
      undraggable: true,
      showStopOrders: true,
      type: TIGER_LINE_TYPE.BREAK_EVEN,
      xOffset: !config.rightSide ? LINE_OFFSET.BREAK_EVEN_LEFT : chartWidth - LINE_OFFSET.BREAK_EVEN_RIGHT,
      alignRight: config.rightSide,
      xCoordinateMode: ECoordinateMode.Pixel,
      boxTextColor,
      hidden: !config.enabled
    };
  }

  private getBoxLabelBreakEven(
    userCustody: IPosition,
    stock: ISearchStock,
    isBreakEvenClicked: boolean
  ) {
    const breakEven = this.getBreakEvenValueToPlot(
      stock,
      userCustody.pnlDay,
      userCustody,
      isBreakEvenClicked,
      false
    );
    const isPositive = breakEven.value > 0;
    const absBreakEven = Math.abs(breakEven.value);
    const tickSize = breakEven.isPoint
      ? 0
      : breakEven.isPercent
      ? 2
      : stock.id_exchange == 7777
      ? 3
      : 2;
    const label = absBreakEven == 0 ? '' : isPositive ? '' : '- ';
    const { percent, pts } = this.getValuePreffix(breakEven, stock);
    const boxLabel: string = `Cobertura | ${label}${this.formatBoxLabel(
      absBreakEven,
      tickSize
    )}${percent}${pts}`;
    return { boxLabel, breakEven };
  }

  private getValuePreffix(
    breakEven: IBreakEvenValueToPlot,
    stock: ISearchStock
  ) {
    const isFuture = stock.cd_security_type == 'FUT';
    const pts = isFuture ? (breakEven.isPoint ? ' pts' : '') : '';
    const percent = !isFuture && breakEven.isPercent ? '%' : '';
    return { pts, percent };
  }

  private getBreakEvenValueToPlot(
    stock: ISearchStock,
    pnltoUse: number,
    userCustody: IPosition,
    isBreakEvenClicked: boolean,
    isCustody: boolean
  ): IBreakEvenValueToPlot {
    let value = pnltoUse;
    let isPercent = false;
    let isPoint = false;
    let diff = userCustody.coverageDiff;
    let percent = userCustody.coveragePercent;
    const isBMF = stock.type === 'BMF' || stock.type === 'BMF_MINI';
    if (isCustody) {
      diff = userCustody.pnlDiff;
      percent = userCustody.pnlPerc;
    }
    if (isBreakEvenClicked) {
      if (!isBMF) {
        isPercent = true;
        value = percent;
      }
    } else {
      if (isBMF) {
        isPoint = true;
        value = diff;
      }
    }
    return { value, isPercent, isPoint };
  }

  createLineAlert(
    value: number,
    boxId: string,
    idTradeSystem: number,
    currentPrice: number,
    ticksize: number,
    cdSegment: string,
    dsAsset: string,
    cdStock: string,
    indicators: BaseIndicator[]
  ): TCandleLineOptions {
    return {
      value: value,
      color: CHART_COLORS.BRAND_SUPPORT_SECONDARY,
      strokeThickness: 1,
      boxTextColor: CHART_COLORS.NEUTRAL_SMOOTHER,
      showLabel: true,
      boxId: boxId,
      undraggable: false,
      boxLabel: ` `,
      idTradeSystem: idTradeSystem,
      type: TIGER_LINE_TYPE.ALERT,
      tooltipLabel: this.getTooltipForType(TIGER_LINE_TYPE.ALERT, {
        value,
        currentPrice,
        cdSegment,
        labelPrecision: ticksize,
        dsAsset,
        cdStock,
        indicators: indicators,
      }),
    };
  }

  private tooltipAlert(options: any) {
    let distance = options.value - options.currentPrice;
    if (distance < 0) {
      distance = distance * -1;
    }
    const distancePercentage = (distance / options.currentPrice) * 100;
    return [
      'Alerta de Preço',
      `Preço: ${this.yAxisLabelFormatter({
        value: options.value,
        cdSegment: options.cdSegment,
        labelPrecision: options.labelPrecision,
        dsAsset: options.dsAsset,
        cdStock: options.cdStock,
        indicators: options.indicators,
      })}`,
      `Distância: ${this.yAxisLabelFormatter({
        value: distance,
        cdSegment: options.cdSegment,
        labelPrecision: options.labelPrecision,
        dsAsset: options.dsAsset,
        cdStock: options.cdStock,
        indicators: options.indicators,
      })} (${this.formatBoxLabel(distancePercentage, 2)}%)`,
    ];
  }

  private tooltipCustody(options: any) {
    //const custodyLabel = options.dayTradeIsEnable ? 'P&L Aberto' : 'P&L Total';
    const custodyLabel = 'P&L Aberto';
    return [
      'Custódia',
      `Qtd: ${this.formatBoxLabel(options.quantity, 0)}`,
      `Preço Médio: ${this.yAxisLabelFormatter({
        value: options.vl_price_avg,
        cdSegment: options.cdSegment,
        labelPrecision: 2,
        dsAsset: options.dsAsset,
        cdStock: options.cdStock,
        indicators: options.indicators,
      })}`,
      `${custodyLabel}: ${this.yAxisLabelFormatter({
        value: options.pnl,
        cdSegment: options.cdSegment,
        labelPrecision: 2,
        dsAsset: options.dsAsset,
        cdStock: options.cdStock,
        indicators: options.indicators,
      })}`,
    ];
  }

  private tooltipOrder(options: any) {
    if (!options) return;
    if (options.isStartStop) {
      return [
        options.description,
        `Qtd: ${options.quantity}`,
        `Preço Limite: ${options.price}`,
        `Preço Stop: ${options.stopPrice}`,
      ];
    }
    if (options.isDoubleStartStop) {
      const stop = options.stop;
      const qtty = options.qtty;
      const isGain = options.isGain;
      const estimate = options.estimate;
      const distance = options.distance;
      const gainLoss = isGain ? 'Ganho' : 'Perda';
      const lastLatter = gainLoss[gainLoss.length - 1];
      const hint = [
        isGain ? 'Stop Gain' : 'Stop Loss',
        `Qtd: ${qtty}`,
        `Stop: ${stop}`,
        `Preço Limite: ${options.price}`,
      ];
      estimate && hint.push(`${gainLoss} estimad${lastLatter}: ${estimate}`);
      distance && hint.push(`Dist. custódia: ${distance}`);
      return hint;
    }
    const distance = options.distance;
    return [
      `${options.description} ${options.quantity}`,
      `Preço: ${this.yAxisLabelFormatter({
        value: options.value,
        cdSegment: options.cdSegment,
        labelPrecision: options.labelPrecision,
        dsAsset: options.dsAsset,
        cdStock: options.cdStock,
        indicators: options.indicators,
      })}`,
      `Tipo: ${options.type}`,
      `Dist. atual: ${distance}`,
    ];
  }

  private tooltipAnnotationEvent(options: any) {
    const strings: any[] = [];
    if (options.description) {
      let lastDate = 0;
      options.description.forEach((element: any, index: number) => {
        if (options.dtDocument && lastDate != options.dtDocument[index]) {
          lastDate = options.dtDocument[index];
          const date = new Date(lastDate);
          strings.push(
            `Fato Relevante - ${new Intl.DateTimeFormat('pt-BR').format(date)}`
          );
        }
        if (!options.dtDocument || options.dtDocument.length < 6) {
          strings.push(element);
        }
      });
    }
    return strings;
  }

  getTooltipForType(type: TIGER_LINE_TYPE, options: any): string[] {
    const funcs: any = {
      [TIGER_LINE_TYPE.ALERT]: () => this.tooltipAlert(options),
      [TIGER_LINE_TYPE.CUSTODY]: () => this.tooltipCustody(options),
      [TIGER_LINE_TYPE.ORDER]: () => this.tooltipOrder(options),
      [TIGER_LINE_TYPE.ANNOTATION_EVENT]: () =>
        this.tooltipAnnotationEvent(options),
    };
    const func = funcs[type];
    if (func) {
      return func();
    }
    return [];
  }

  yAxisLabelFormatter = (options: IStockChartYLabelFormatterOptions) => {
    const ticksize = this._getTicksize(options);

    let formatted = formatNumber(
      options.value,
      this.locale,
      `1.${ticksize}-${ticksize}`
    );

    if (options.cdSegment == '9999') {
      formatted = this._formatMiniIndex(options, formatted);
    }

    return formatted;
  };

  _getTicksize(options: IStockChartYLabelFormatterOptions) {
    if (
      options.indicators &&
      options.indicators[0] &&
      options.indicators[0].type ===
        TIGER_INDICATORS_ENUM.PERCENTAGE_PRICE_OSCILLATOR
    )
      return 4;

    if (options.labelPrecision != null) return options.labelPrecision;

    if (
      options.cdSegment == '9999' &&
      (!options.indicators ||
        !options.indicators[0] ||
        options.indicators[0].type !=
          TIGER_INDICATORS_ENUM.PERCENTAGE_PRICE_OSCILLATOR)
    ) {
      return options.dsAsset == 'DOL' || options.dsAsset == 'WDO' ? 3 : 0;
    }

    return options.labelPrecision || 2;
  }

  round5(x: number) {
    return Math.ceil(x / 5) * 5;
  }
  private _formatMiniIndex(
    options: IStockChartYLabelFormatterOptions,
    formatted: string
  ): string {
    if (options.labelPrecision == undefined) return formatted;

    if (options.dsAsset == 'DOL' || options.dsAsset == 'WDO') {
      const tickSize: number = options.labelPrecision;
      const lastNumber = parseInt(formatted.slice(formatted.length - tickSize));

      if (lastNumber == 500) return formatted;

      let value = options.value;

      if (lastNumber > 0) {
        const halfDecimal = (0.5).toFixed(tickSize).slice(2);
        value =
          lastNumber > +halfDecimal
            ? Math.ceil(options.value)
            : Math.floor(options.value) + 0.5;
      }

      return formatNumber(
        value,
        this.locale,
        `1.${options.labelPrecision}-${options.labelPrecision}`
      );
    }
    if (isDI(options.cdStock)) {
      return formatted;
    }
    const valueRounded = this.round5(options.value);
    return formatNumber(
      valueRounded,
      this.locale,
      `1.${options.labelPrecision}-${options.labelPrecision}`
    );
  }

  processConsolidatedItens(consolidatedItems: any): ConsolidatedTotal {
    return new ConsolidatedTotal(consolidatedItems);
  }

  getStockTradeOptions(configStock: any, value: any, negotiationLot?: number) {
    const typeOrder = value.typeOrder;
    const { data } = value;
    return {
      order: {
        ...configStock,
        side:
          typeOrder === CONTEXT_MENU_ORDER_TYPE.BUY
            ? TYPE_ORDE_SIDE_STRING_ENUM.BUY
            : TYPE_ORDE_SIDE_STRING_ENUM.SELL,
        price: data!.pointValueY,
        qtty_left: negotiationLot ?? configStock.standard_lot,
      },
      isEditMode: false,
    };
  }

  generateOverrideCustodyBox(
    isCustody: boolean,
    pnlLength: number,
    qttyLength: number,
    pnlText: string,
    qttyText: string
  ): IOverrideCustodyBox {
    const colorsOverride = isCustody
      ? {}
      : {
          positiveColor: {
            bg: CHART_COLORS.FEEDBACK_SUCCESS,
            font: CHART_COLORS.NEUTRAL_STRONGEST,
          },
          negativeColor: {
            bg: CHART_COLORS.FEEDBACK_ERROR,
            font: CHART_COLORS.NEUTRAL_SMOOTHEST,
          },
        };
    return {
      pnlLength,
      qttyLength,
      pnlText,
      qttyText,
      colors: colorsOverride,
    };
  }
}
