export const environment = {
  production: false,
  order_notification: false,
  recaptcha: {
    siteKey: '',
  },
  scichartKey: '',
  sentry: {
    environment: 'development',
    dsn: '',
    tracePropagationTargets: [],
    tracesSampleRate: 1.0,
    sentryTargets: [],
    sendDefaultPii: true,
  },
};
