import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';

export const SCREENING_COMPONENT: any = {
  cod: 'SCR',
  label: 'Screening',
  name: 'Screening',
  id: COMPONENTS_NAMES_ENUM.SCREENING,
  data: null,
  close: true,
  fullscreen: false,
  link: true,
  minWidth: '958px',
  width: 958,
  height: 480,
  minHeight: '480px',
  noPublished: false,
  unitOfMeasurement: 'px',
  left: 'calc(15% + 5px)',
  top: '0px',
  icon: 'find_in_page',
  iconSvg: '',
  borderClass: 'border-top border-neutral-stronger',
  hasBorder: true,
};
