import { Injectable } from '@angular/core';
import { ConfigService } from '@core/config';
import { Dictionary } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private configService: ConfigService) {}

  getHashRemoveGroupNews(): Dictionary<boolean> {
    const hashDontShow: Dictionary<boolean> = new Dictionary<boolean>();
    if (this.configService.isBroker()) {
      hashDontShow.set(2, true);
      hashDontShow.set(4, true);
    }
    return hashDontShow;
  }
}
