import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { RocketGridService } from '@shared/rocket-grid';

@Component({
  selector: 'app-actions-row',
  template: `
    <rocket-button type="icon" (rocketClick)="removeRow($event)">
      <fla-icon
        name="close"
        size="icon-size-tiny"
        css="text-feedback-error"
      ></fla-icon>
    </rocket-button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsRowComponent implements ICellRendererAngularComp {
  public params!: any;

  constructor(
    private rocketGridService: RocketGridService,
    private _cdr: ChangeDetectorRef
  ) {}

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this._cdr.detectChanges();
  }

  public refresh(): boolean {
    return false;
  }

  public removeRow(event: any): void {
    event && event.stopPropagation();
    this.rocketGridService.setRemoveStockFromSameLists({
      idList: this.params.data.idStockList,
      rowData: this.params.data,
    });
    this._cdr.detectChanges();
  }
}
