import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IWorkSpaceComponet } from '@core/interface';
import { DocWorkspace } from '../doc';
import { isWebViewContext } from 'src/app/desktop/integration.utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'workspace-doc-header',
  template: `<div
    class="top-0 left-0 d-flex align-items-center position-absolute bg-neutral-stronger"
    [ngClass]="css"
    [style.maxWidth]="maxWidth"
    [style.height.px]="height"
  >
    <div
      class="d-flex align-items-center h-100"
      [ngClass]="{'justify-content-between': spaceBetween, 'w-100': !inheritMaxWidth }"
      [style.maxWidth]="inheritMaxWidth ? 'inherit' : null"
    >
      <ng-content></ng-content>
    </div>
    <app-header-commom-buttons
      *ngIf="showRightButtons"
      [workspaceDoc]="workspaceDoc"
      [focused]="false"
      [dragDisabled]="true"
      [ref]="ref"
    ></app-header-commom-buttons>
  </div>`,
  styles: [
    `
      :host(workspace-doc-header) {
        display: contents;

        &.view-spacing {
          display: block;
          padding-bottom: 32px;
        }
      }
      :host ::ng-deep app-search-stock fla-select > div {
        height: 35px;
        padding-top: 0px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkSpaceHeaderComponent implements OnChanges {
  @Input() css!: string;
  @Input() fullscreenEnabled: boolean = true;
  @Input() set item(value: IWorkSpaceComponet | undefined) {
    if (!value) return;
    this.workspaceDoc = new DocWorkspace(value);
  }
  @Input() ref!: string;
  @Input() spaceBetween!: boolean;
  @Input() inheritMaxWidth: boolean = false;
  @Input() autoMaxWidth: boolean = true;
  @Input() maxWidth = 'calc(100% - 80px)';

  @HostBinding('class') class!: string;
  isDesktop: boolean = false;
  showRightButtons = false;
  workspaceDoc!: DocWorkspace | any;
  height = 36;

  constructor(private _cdr: ChangeDetectorRef) {
    this.isDesktop = isWebViewContext();
    if (this.isDesktop) {
      this.maxWidth = '100%';
      this.showRightButtons = this.isDesktop;
      this.class = ' view-spacing';
      this.height = 32;
      return;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const { item } = changes;
    if (item && !this.isDesktop && this.autoMaxWidth) {
      let spacing = 80;
      this.isDesktop && (spacing += 45);
      this.workspaceDoc?.headerOptions?.fullscreen && (spacing += 45);

      this.maxWidth = `calc(100% - ${spacing}px)`;
      this._cdr.detectChanges();
    }
    this._cdr.detectChanges();
  }
}
