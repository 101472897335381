import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { IWorkSpaceComponet } from '@core/interface';
import { INewsGroup } from '@core/interface/news-agency.interface';
import { isWebViewContext } from 'src/app/desktop/integration.service';

@Component({
  selector: 'app-news-header',
  templateUrl: './news-header.component.html',
  styleUrls: ['./news-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsHeaderComponent {
  @Input() refComponent!: string;
  @Input() linked!: boolean;
  @Input() newsGroups!: INewsGroup[];
  @Input() selectedTab: number = 0;
  @Input() component!: IWorkSpaceComponet;
  @Output() tabChange: EventEmitter<number> = new EventEmitter<number>();
  isDesktop = false;

  constructor() {
    this.isDesktop = isWebViewContext();
  }

  changeTab(tab: number) {
    this.tabChange.emit(tab);
  }
}
