import { TFlaToast } from '@shared/rocket-components/toast';

export type TTokenPreferenceAuthenticator =
  | 'TRADEMAP'
  | 'NITRO'
  | 'GOOGLE'
  | 'MICROSOFT'
  | 'UNDEFINED_TOKEN';

export type TTokenAuthenticatorConfigurationStatus =
  | 'WAITING'
  | 'UNVALIDATED'
  | 'VALIDATED';

export type TTokenAuthenticatorConfigurationStep =
  | 'token'
  | 'qrcode'
  | 'validation';

export const TTokenAuthenticatorConfigurationSteps: {
  TOKEN: TTokenAuthenticatorConfigurationStep;
  QRCODE: TTokenAuthenticatorConfigurationStep;
  VALIDATION: TTokenAuthenticatorConfigurationStep;
} = {
  TOKEN: 'token',
  QRCODE: 'qrcode',
  VALIDATION: 'validation',
};

export type TTokenAuthenticator = {
  name: string;
  icon: string;
  id: TTokenPreferenceAuthenticator;
};

export const TOKEN_AUTHENTICATORS: TTokenAuthenticator[] = [
  {
    name: 'Google Authenticator',
    icon: './assets/svg/google-authenticator.svg',
    id: 'GOOGLE',
  },
  {
    name: 'Microsoft Authenticator',
    icon: './assets/svg/microsoft-authenticator.svg',
    id: 'MICROSOFT',
  },
  {
    name: 'RocketTrader',
    icon: './assets/svg/rocket.svg',
    id: 'NITRO',
  },
];

export const TOKEN_AUTHENTICATORS_NAME = {
  GOOGLE: 'Google Authenticator',
  MICROSOFT: 'Microsoft Authenticator',
  TRADEMAP: 'app TradeMap',
  NITRO: 'app RocketTrader',
  UNDEFINED_TOKEN: '',
};

export const TOKEN_AUTHENTICATORS_BY_ID: any = {
  GOOGLE: TOKEN_AUTHENTICATORS[0],
  MICROSOFT: TOKEN_AUTHENTICATORS[1],
  NITRO: TOKEN_AUTHENTICATORS[2],
};

export const TOKEN_VALIDATION_MESSAGES = {
  WAITING: (name: string) => `Estamos validando a conexão com o ${name}`,
  UNVALIDATED: () => `Erro ao tentar conectar. Por favor, tente novamente.`,
  VALIDATED: (name: string) => `Sua conexão com ${name} foi feita com sucesso`,
};

export const TOKEN_VALIDATION_ICONS: {
  [key: string]: TFlaToast;
} = {
  WAITING: 'info',
  UNVALIDATED: 'error',
  VALIDATED: 'success',
};
