<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div *ngIf="showPowerBar" class="force-chart-height">
  <app-force-chart
    [data]="forceChartData"
    [small]="true"
    [width]="width"
    [toFixed]="0"
  ></app-force-chart>
</div>
<table class="w-100 text-center">
  <thead class="bg-neutral-strong fixed-header">
    <tr
      [style.maxHeight.px]="26"
      [ngClass]="{
        'with-at-price': (_showAtPrice && !_showColumnBook) || (_showAtPrice && !showVolume),
        'with-columnbook': !_showAtPrice && (showVolume && _showColumnBook),
        'default-columns': !_showAtPrice && !showVolume || !_showAtPrice && !_showColumnBook,
        'with-atprice-columnbook': _showAtPrice && (showVolume && _showColumnBook),
      }"
    >
      <th *ngIf="showVolume && _showColumnBook">Book</th>
      <th class="white-space pl-1">
        <ng-template
          *ngTemplateOutlet="
            closeOrders;
            context: {
              side: 'left-0',
              tooltip: 'Cancelar todas as ordens de compra em aberto'
            }
          "
        ></ng-template>
        Ordem
      </th>
      <th>Qtd compra</th>
      <th>Preço</th>
      <th>Qtd venda</th>
      <th>
        Ordem
        <ng-template
          *ngTemplateOutlet="
            closeOrders;
            context: {
              side: 'right-0',
              tooltip: 'Cancelar todas as ordens de venda em aberto'
            }
          "
        ></ng-template>
      </th>
      <th *ngIf="_showAtPrice" class="pr-2">Vol x Preço</th>
    </tr>
  </thead>
</table>
<div
  id="SUPERDOM_TABLE_NEGOTIATION"
  class="table-responsive height-table-negotiation default-cursor position-relative"
  [ngClass]="{ 'hidden-power-bar': !showPowerBar }"
  #table
>
  <cdk-virtual-scroll-viewport
    *ngIf="tableDefault.length"
    itemSize="18"
    minBufferPx="18"
    maxBufferPx="18"
    class="w-100 h-100"
    [ngClass]="{ 'disabled-scroll': isLineBlock }"
  >
    <table
      class="w-100 h-100 text-center"
      [ngClass]="{ 'custom-cursor': mouseClicked }"
      [id]="'table_super_dom_' + refComponent"
    >
      <tbody (mouseleave)="clearLineEdit(true)" class="position-relative">
        <tr
          *cdkVirtualFor="let item of tableDefaultLength; let index = index"
          [ngClass]="{
            'border-top': showSeparator == tableDefault[index]?.price,
            'order-price': ordersListObject[+tableDefault[index]?.price] && ordersListObject[+tableDefault[index]?.price].qtty_left,
            buy: ordersListObject[+tableDefault[index]?.price]?.side == sideOrderString.BUY && ordersListObject[+tableDefault[index]?.price].qtty_left,
            sell: ordersListObject[+tableDefault[index]?.price]?.side == sideOrderString.SELL && ordersListObject[+tableDefault[index]?.price].qtty_left,
            'edit-buy': editBuy && priceSeletedMouseMove == tableDefault[index]?.price,
            'edit-sell': editSell && priceSeletedMouseMove == tableDefault[index]?.price,
            'fix-bg':
              lineEditOrder.idOrder == ordersListObject[+tableDefault[index]?.price]?.id_order && ordersListObject[+tableDefault[index]?.price].qtty_left,
            'with-at-price': (_showAtPrice && !_showColumnBook) || (_showAtPrice && !showVolume),
            'with-columnbook':  !_showAtPrice && (showVolume && _showColumnBook),
            'default-columns': !_showAtPrice && !showVolume || !_showAtPrice && !_showColumnBook,
            'with-atprice-columnbook': _showAtPrice && (showVolume && _showColumnBook),
          }"
          (dblclick)="
            editOrderDBlClick(
              tableDefault[index]?.price,
              ordersListObject[+tableDefault[index]?.price]?.side
            )
          "
          [class]="'opacity-semi-opaque-hover ' + refComponent + '_' + index"
          (mouseup)="mouseup(tableDefault[index]?.price)"
        >
          <td
            id="SUPERDOM_COLUMN_BOOK"
            *ngIf="showVolume && _showColumnBook"
            [class]="
              'position-relative columnbook flex w-100 ' +
              refComponent +
              '_' +
              index
            "
          >
            <div
              class="columnbook"
              [ngClass]="{
                'columnbook-buy':
                  rangePrice[tableDefault[index]?.price]?.compraQtde,
                'columnbook-sell':
                  rangePrice[tableDefault[index]?.price]?.vendaQtde
              }"
              [style.width.%]="
                rangePrice[tableDefault[index]?.price]?.barGraphic
              "
              [class]="refComponent + '_' + index"
            >
              &nbsp;
            </div>
          </td>
          <ng-template
            *ngTemplateOutlet="
              td;
              context: {
                price: tableDefault[index]?.price,
                side: sideOrders.BUY,
                index,
                hasQttyOrder: tableDefault[index]?.hasQttyOrderBuy
              }
            "
          ></ng-template>
          <td
            [ngClass]="{
              'opacity-medium':
                !tableDefault[index]?.orderIsStopViewBuy &&
                !rangePrice[tableDefault[index]?.price]?.compraQtde,
              'cursor-pointer opacity-100':
                rangePrice[tableDefault[index]?.price]?.compraQtde,
              'opacity-100 p-0':
                showPosition &&
                tableDefault[index]?.price == pricePosition &&
                showSeparator < pricePosition
            }"
            (dblclick)="
              sendOrder(
                tableDefault[index]?.price,
                sideOrders.BUY,
                ordersListObject[+tableDefault[index]?.price]
              )
            "
            [class]="'position-relative ' + refComponent + '_' + index"
          >
            {{ rangePrice[tableDefault[index]?.price]?.compraQtde }}
            <ng-container *ngIf="tableDefault[index]?.orderIsStopViewBuy">
              <span
                [class]="'fw-bold text-light ' + refComponent + '_' + index"
                [ngClass]="{
                  'foat-label-stop bg-brand-primary text-black':
                    showPosition &&
                    tableDefault[index]?.price == pricePosition &&
                    showSeparator < pricePosition
                }"
              >
                Stop
              </span>
            </ng-container>
            <ng-container
              *ngIf="
                showPosition &&
                tableDefault[index]?.price == pricePosition &&
                showSeparator < pricePosition
              "
            >
              <div
                class="text-white d-flex justify-content-around position-bg-cell h-100 align-items-center"
              >
                <span class="fw-bold">{{ qttdPosition }}</span>
                <fla-icon name="work" size="icon-size-nano"></fla-icon>
              </div>
            </ng-container>
          </td>
          <td
            [class]="'price position-relative ' + refComponent + '_' + index"
            [ngClass]="tableDefault[index]?.price == price ? classPrice : ''"
          >
            <div
              [ngClass]="{
                'position-custody':
                  showPosition && tableDefault[index]?.price == pricePosition
              }"
            ></div>
            <label
              [class]="
                rangePrice[tableDefault[index]?.price]?.classAgg +
                ' ' +
                refComponent +
                '_' +
                index
              "
            >
              {{ tableDefault[index]?.priceFormated }}
            </label>
            <span
              *ngIf="showVolume && !_showColumnBook"
              [class]="
                'opacity-light position-absolute graphic ' +
                refComponent +
                '_' +
                index
              "
              [ngClass]="{
                'bg-multibroker-buy round-right left-0':
                  rangePrice[tableDefault[index]?.price]?.compraQtde,
                'bg-multibroker-sell round-left right-0':
                  rangePrice[tableDefault[index]?.price]?.vendaQtde
              }"
              [style.width.%]="
                rangePrice[tableDefault[index]?.price]?.barGraphic
              "
            ></span>
          </td>
          <td
            [ngClass]="{
              'opacity-medium':
                !tableDefault[index]?.orderIsStopViewSell &&
                !rangePrice[tableDefault[index]?.price]?.vendaQtde,
              'cursor-pointer opacity-100':
                rangePrice[tableDefault[index]?.price]?.vendaQtde,
              'opacity-100 p-0':
                showPosition &&
                tableDefault[index]?.price == pricePosition &&
                showSeparator >= pricePosition
            }"
            (dblclick)="
              sendOrder(
                tableDefault[index]?.price,
                sideOrders.SELL,
                ordersListObject[+tableDefault[index]?.price]
              )
            "
            [class]="'position-relative ' + refComponent + '_' + index"
          >
            {{ rangePrice[tableDefault[index]?.price]?.vendaQtde }}
            <ng-container *ngIf="tableDefault[index]?.orderIsStopViewSell">
              <span
                [class]="'fw-bold text-light ' + refComponent + '_' + index"
                [ngClass]="{
                  'foat-label-stop bg-feedback-warning text-black bottom':
                    showPosition &&
                    tableDefault[index]?.price == pricePosition &&
                    showSeparator > pricePosition
                }"
              >
                Stop
              </span>
            </ng-container>
            <ng-container
              *ngIf="
                showPosition &&
                tableDefault[index]?.price == pricePosition &&
                showSeparator >= pricePosition
              "
            >
              <div
                class="text-white d-flex justify-content-around position-bg-cell h-100 align-items-center"
              >
                <fla-icon name="work" size="icon-size-nano"></fla-icon>
                <span class="fw-bold">{{ qttdPosition }}</span>
              </div>
            </ng-container>
          </td>
          <ng-template
            *ngTemplateOutlet="
              td;
              context: {
                price: tableDefault[index]?.price,
                side: sideOrders.SELL,
                index,
                hasQttyOrder: tableDefault[index]?.hasQttyOrderSell
              }
            "
          ></ng-template>

          <td
            *ngIf="_showAtPrice"
            [class]="
              'position-relative atprice w-100 ' + refComponent + '_' + index
            "
          >
            <div
              [class]="'d-flex flex-row-reverse ' + refComponent + '_' + index"
            >
              <span
                [class]="'atprice-hov ' + refComponent + '_' + index"
                [style.margin-left.px]="
                  rangeVolume[tableDefault[index]?.price]?.atpriceHov
                "
              >
                {{
                  rangeVolume[tableDefault[index]?.price]?.qtty_total | number
                }}
              </span>
              <div
                [class]="'atprice atprice-buy ' + refComponent + '_' + index"
                [style.width.%]="
                  rangeVolume[tableDefault[index]?.price]?.atpriceBuy
                "
              >
                &nbsp;
              </div>
              <div
                [class]="'atprice atprice-sell ' + refComponent + '_' + index"
                [style.width.%]="
                  rangeVolume[tableDefault[index]?.price]?.atpriceSell
                "
              >
                &nbsp;
              </div>
              <div
                [class]="'atprice atprice-rlp ' + refComponent + '_' + index"
                [style.width.%]="
                  rangeVolume[tableDefault[index]?.price]?.atpriceRlp
                "
              >
                &nbsp;
              </div>
              <div
                [class]="'atprice atprice-direct ' + refComponent + '_' + index"
                [style.width.%]="
                  rangeVolume[tableDefault[index]?.price]?.atpriceDirect
                "
              >
                &nbsp;
              </div>
              <div>&nbsp;</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </cdk-virtual-scroll-viewport>
  <div
    *ngIf="!isLineBlock"
    class="position-absolute round-pill d-flex justify-content-center align-items-center btn-floating right-0 mr-2 mb-1"
  >
    <rocket-button type="icon" (rocketClick)="centralizeBook()">
      <fla-icon name="vertical_align_center" size="icon-size-micro"></fla-icon>
    </rocket-button>
  </div>
  <div
    [id]="SUPER_DOM_ELEMENT_ID.PADLOCK"
    class="position-absolute round-pill d-flex justify-content-center align-items-center btn-floating-lock"
  >
    <rocket-button type="icon" (rocketClick)="toogleLockComponent()">
      <fla-icon [name]="lineBlockIcon" size="icon-size-tiny"></fla-icon>
    </rocket-button>
  </div>
</div>

<ng-template
  #td
  let-price="price"
  let-side="side"
  let-index="index"
  let-hasQttyOrder="hasQttyOrder"
>
  <td [class]="refComponent + '_' + index" *ngIf="hasQttyOrder">
    <section
      [class]="'h-100 cursor-pointer ' + refComponent + '_' + index"
      (mousedown)="mousedown(price, side, index)"
    >
      <section
        [class]="'d-flex h-100 ' + refComponent + '_' + index"
        *ngIf="lineEditOrder.price != price; else form"
        [ngClass]="{ 'flex-row-reverse': side === sideOrders.SELL }"
      >
        <rocket-button
          type="icon"
          [css]="
            side === sideOrders.BUY ? 'float-left py-0' : 'float-right py-0'
          "
          (rocketClick)="cancelOrder(price)"
        >
          <fla-icon name="close" size="icon-size-micro"></fla-icon>
        </rocket-button>
        <div
          [class]="
            'h-100 w-100 d-flex align-items-center justify-content-center ' +
            refComponent +
            '_' +
            index
          "
        >
          {{ hasQttyOrder | number }}
        </div>
      </section>
    </section>
  </td>
  <td [class]="refComponent + '_' + index" *ngIf="!hasQttyOrder">
    <section
      [id]="
        side === sideOrders.BUY && rangePrice[price]?.compraQtde
          ? SUPER_DOM_ELEMENT_ID.BUY_AREA
          : ''
      "
      [class]="'h-100 pointer-cursor ' + refComponent + '_' + index"
      (dblclick)="editOrderDBlClick(price, side)"
      (ctrl-click)="handleCtrlClick(price, side)"
      (mousedown)="mousedown(price, side, index)"
    >
      <section
        [class]="'h-100 pointer-cursor ' + refComponent + '_' + index"
        *ngIf="lineEditOrder.price == price && lineEditOrder.side == side"
      >
        <ng-template *ngTemplateOutlet="form"></ng-template>
      </section>
    </section>
  </td>
</ng-template>

<ng-template #form>
  <form [formGroup]="editOrderForm">
    <input
      #input
      mask="separator.0"
      type="text"
      class="form-control form-control-sm fs-6"
      formControlName="qtd"
      [style.height.px]="18"
      (keydown.enter)="orderSend(true)"
      (keydown.esc)="clearLineEdit()"
    />
  </form>
</ng-template>

<ng-template #closeOrders let-side="side" let-tooltip="tooltip">
  <span
    *ngIf="ordersOpen.length"
    class="cursor-pointer bg-neutral-strong position-absolute"
    [ngClass]="side"
    [tooltip]="tooltip"
    (click)="closeAllOrders(side)"
  >
    <fla-icon
      name="close"
      size="icon-size-nano"
      css="text-neutral-medium"
    ></fla-icon>
  </span>
</ng-template>
