<rocket-modal>
  <rocket-modal-header>
    <ng-container *ngIf="errorOnLoadNews; else title">
      <h3>Ocorreu um erro ao abrir a notícia</h3>
    </ng-container>
    <ng-template #title>
      <ng-container *ngIf="newsletter">
        <span class="d-flex flex-column fw-bolder">
          {{ newsletter.ds_headline }}
        </span>
      </ng-container>
    </ng-template>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <ng-container *ngIf="errorOnLoadNews; else content">
      <span
        class="w-100 d-flex align-items-center justify-content-center error-msg"
      >
        Tente novamente mais tarde
      </span>
    </ng-container>
    <ng-template #content>
      <ng-container *ngIf="newsletter">
        <div
          class="w-100 mb-6 body-modal overflow-y-auto"
          [innerHTML]="newsletter.data.ds_body"
        ></div>
      </ng-container>
    </ng-template>
  </app-rocket-modal-body>
</rocket-modal>
