import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { RestService } from '@shared/services/rest/rest.service';

@Injectable({
  providedIn: 'root',
})
export class BalanceService extends RestService {
  override _url: string = 'api/trademap';

  constructor(http: HttpClient) {
    super(http);
  }

  public getBalance = (idBroker: number) =>
    this.post<any>('v3/broker/get-balance', {
      idBroker: idBroker,
      args: {},
    }).pipe(
      map((res) => {
        if (res.data && res.data?.success) {
          return res.data.result;
        }
        return null;
      })
    );
}
