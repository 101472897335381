<app-table-book-header
  [stockSelected]="stock"
  [refComponent]="component.id"
  [headerOptions]="headerOptions"
  [bookByOfferActive]="bookByOfferActive"
  [component]="component"
  (changeBookByOffer)="toggleOfferConfig()"
  (changeStock)="stockChanges($event)"
></app-table-book-header>
<div
  class="position-relative d-flex flex-column"
  [ngClass]="isDesktop ? 'component-height' : 'h-100'"
>
  <div
    *ngIf="(dataChartForce && !metadataConfiguration?.hidePressure) || isTour"
    class="force-chart-height"
  >
    <app-force-chart
      [data]="dataChartForce"
      [small]="true"
      [width]="width"
      [toFixed]="0"
    ></app-force-chart>
  </div>
  <ng-container
    *ngIf="dataResume && (!metadataConfiguration?.hideDetails || isTour)"
  >
    <app-details-tab
      [cdStock]="stock.cd_stock"
      [arrowHex]="dataResume.arrow_hex"
      [precoUltimo]="dataResume.vl_preco_ultimo"
      [variacaoDia]="dataResume.variacao_dia"
      [precoMinimo]="dataResume.vl_preco_minimo"
      [precoMaximo]="dataResume.vl_preco_maximo"
      [volume]="dataResume.volume"
      [tickSizeDenominator]="dataResume.tick_size_denominator"
      [dsType]="stock.ds_type"
      [stockAuctionInfos]="stockAuctionInfos"
    >
    </app-details-tab>
  </ng-container>
  <div class="h-100 d-flex flex-column" id="BOOK" #book>
    <table class="w-100 m-0 text-center table">
      <thead>
        <tr>
          <th
            scope="col"
            class="col wd-10 text-multibroker-buy"
            *ngIf="
              metadataConfiguration?.hideOffers &&
              !metadataConfiguration?.hideOrders &&
              !isTour
            "
          >
            Ordens
          </th>
          <th
            scope="col"
            class="col text-center text-multibroker-buy wd-15"
            title="Agente Compra"
            *ngIf="!metadataConfiguration?.hideOffers"
          >
            C
          </th>
          <th
            scope="col"
            class="col text-center text-multibroker-buy wd-10"
            title="Quantidade Compra"
          >
            Qtd
          </th>
          <th
            scope="col"
            class="col fw-bolder text-multibroker-buy text-right"
            [ngClass]="titleCVClass()"
            title="Compra"
          >
            C
          </th>
          <th
            scope="col"
            class="col fw-bolder text-multibroker-sell text-left"
            [ngClass]="titleCVClass()"
            title="Venda"
          >
            V
          </th>
          <th
            scope="col"
            class="col text-center text-multibroker-sell wd-10"
            title="Quantidade Venda"
          >
            Qtd
          </th>
          <th
            scope="col"
            class="col text-center text-multibroker-sell wd-15"
            title="Agente Venda"
            *ngIf="!metadataConfiguration?.hideOffers"
          >
            V
          </th>
          <th
            scope="col"
            class="col wd-10 text-multibroker-sell"
            *ngIf="
              metadataConfiguration?.hideOffers &&
              !metadataConfiguration?.hideOrders &&
              !isTour
            "
          >
            Ordens
          </th>
        </tr>
      </thead>
    </table>
    <cdk-virtual-scroll-viewport
      itemSize="15"
      minBufferPx="0"
      maxBufferPx="0"
      [style.height.px]="virtualScrollViewportHeigth"
      class="w-100"
    >
      <table class="w-100 h-100 text-center table">
        <tbody class="position-relative">
          <tr
            *cdkVirtualFor="let item of dataTable; trackBy: trackItemBy"
            class="position-relative w-100"
          >
            <td
              *ngIf="
                metadataConfiguration?.hideOffers &&
                !metadataConfiguration?.hideOrders &&
                !isTour
              "
              class="wd-10"
              [ngClass]="item.buyOfferDiffClass"
            >
              {{ item.offerBuy }}
            </td>

            <td *ngIf="!metadataConfiguration?.hideOffers" class="wd-10">
              {{ item.buyBroker }}
            </td>

            <td
              colspan="2"
              class="position-relative wd-30"
              [ngClass]="{
                'wd-40':
                  metadataConfiguration?.hideOffers &&
                  metadataConfiguration?.hideOrders
              }"
            >
              <div class="w-100 d-flex">
                <div class="w-50" [ngClass]="item.buyQttyDiffClass">
                  {{ item.qttyBuy }}
                </div>
                <div class="text-right w-50" [ngClass]="item.classBuy">
                  {{ item.priceBuy }}
                </div>
              </div>
              <div
                id="BOOK_QUANTITY_VOLUME"
                class="bg-multibroker-buy opacity-light position-absolute top-0 right-0 h-100 round-left"
                [style.width.%]="item.barBuy"
              ></div>
            </td>

            <td
              colspan="2"
              class="position-relative wd-30"
              [ngClass]="{
                'wd-40':
                  metadataConfiguration?.hideOffers &&
                  metadataConfiguration?.hideOrders
              }"
            >
              <div class="w-100 d-flex">
                <div class="text-left w-50" [ngClass]="item.classSell">
                  {{ item.priceSell }}
                </div>
                <div class="w-50" [ngClass]="item.sellQttyDiffClass">
                  {{ item.qttySell }}
                </div>
              </div>
              <div
                class="bg-multibroker-sell opacity-light position-absolute top-0 left-0 h-100 round-right"
                [style.width.%]="item.barSell"
              ></div>
            </td>
            <td *ngIf="!metadataConfiguration?.hideOffers" class="wd-10">
              {{ item.sellBroker }}
            </td>

            <td
              *ngIf="
                metadataConfiguration?.hideOffers &&
                !metadataConfiguration?.hideOrders &&
                !isTour
              "
              class="wd-10"
              [ngClass]="item.sellOfferDiffClass"
            >
              {{ item.offerSell }}
            </td>
          </tr>
        </tbody>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>
</div>

<ng-template #notData>
  <tbody [style.height.px]="100">
    <tr>
      <td colspan="5">
        <p
          class="position-absolute top-50 left-50"
          style="transform: translate(-50%, -50%)"
        >
          Sem dados para mostrar!
        </p>
      </td>
    </tr>
  </tbody>
</ng-template>
