import { Injectable } from '@angular/core';
import { AbstractCacheService } from './AbstractCacheService';
import { ICorporateEvents } from '@shared/components/business-profile/business-profile.interface';

@Injectable({
  providedIn: 'root',
})
export class CorporateEventsCacheService extends AbstractCacheService<
  ICorporateEvents[]
> {}
