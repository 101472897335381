<div
  class="position-absolute scichart-tooltip-div-cl"
  [id]="'provents-tooltip-div-id_' + refChart"
></div>
<div class="grid h-100">
  <div class="d-flex flex-column">
    <span>Evolução</span>
    <div
      class="d-flex flex-column h-100"
      *ngIf="corporateEventsToShow.length; else noInfo"
    >
      <div *ngIf="cards" class="cards d-flex gap-5 mb-3">
        <fla-card class="w-100 grid-evolution fs-6">
          <fla-card-custom
            #card
            *ngFor="let card of cards.values()"
            css="p-1 bg-neutral-strong mt-2"
          >
            <div
              class="card-body d-flex flex-column justify-content-center align-items-center"
            >
              <span [tooltip]="card.tooltip" class="text-neutral-medium">
                {{ card.label }}
              </span>
              <div class="d-flex flex-column fw-bold">
                <span class="value" [tooltip]="card.tooltipAux || ''">
                  {{ card.value }}
                </span>
                <span *ngIf="card.value_yield" class="value">
                  {{ card.value_yield }}
                </span>
              </div>
            </div>
          </fla-card-custom>
        </fla-card>
      </div>
      <div class="iosma d-flex justify-content-between align-items-center">
        <div class="d-flex gap-3">
          <div class="d-flex gap-2">
            <fla-tab css="fs-6 bg-neutral-strong" styleTab="nav-segmented">
              <ng-container *ngFor="let unit of units">
                <fla-tab-header
                  [ref]="unit.cod"
                  [title]="unit.title"
                  [active]="unit.active"
                  (flaChange)="selectUnit(unit)"
                ></fla-tab-header>
                <fla-tab-body [ref]="unit.cod" [active]="unit.active">
                </fla-tab-body>
              </ng-container>
            </fla-tab>
          </div>
          <div class="d-flex gap-2">
            <fla-tab css="fs-6 bg-neutral-strong" styleTab="nav-segmented">
              <ng-container *ngFor="let group of groups">
                <fla-tab-header
                  [ref]="group.cod"
                  [title]="group.title"
                  [active]="group.active"
                  (flaChange)="selectGroup(group)"
                ></fla-tab-header>
                <fla-tab-body [ref]="group.cod" [active]="group.active">
                </fla-tab-body>
              </ng-container>
            </fla-tab>
          </div>
        </div>
        <div class="period d-flex gap-2">
          <span
            (click)="selectPeriod(period)"
            [ngClass]="{ 'text-brand-primary': periodSelected === period.cod }"
            class="cursor-pointer"
            *ngFor="let period of periods.values()"
            >{{ period.title }}</span
          >
        </div>
      </div>
      <div class="chart h-100 d-flex flex-column">
        <div
          [id]="'provents-legend-div-id_' + refChart"
          class="w-100 d-inline-flex align-items-center justify-content-center custom-legend"
        ></div>
        <div [id]="refChart" #chartContainer class="w-100 h-100"></div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column overflow-auto">
    <fla-tab css="fs-5" cssContent="h-100 height-content">
      <fla-tab-header
        *ngFor="let tab of tabs"
        [ref]="'tab_provents_' + refComponent"
        [title]="tab.title"
        [active]="tab.cod === this.selectedTab"
        [enableContent]="true"
        [tooltip]="tab.tooltip"
        (flaChange)="changeTab(tab)"
        css="pt-0"
      ></fla-tab-header>
    </fla-tab>

    <ng-container [ngSwitch]="selectedTab">
      <ng-container *ngSwitchCase="'HISTORIC'">
        <div
          *ngIf="corporateEventsToShow.length; else noInfo"
          class="overflow-auto h-100 w-100"
        >
          <app-resume-list [lists]="corporateEventsToShow"></app-resume-list>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'NEXT'">
        <div
          *ngIf="proceedsToShow.length; else noInfoProceeds"
          class="overflow-auto h-100 w-100"
        >
          <app-resume-list [lists]="proceedsToShow"></app-resume-list>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<ng-template #noInfo>
  <div class="h-100 w-100 d-flex justify-content-center pt-3">
    <app-no-info class="fs-6"></app-no-info>
  </div>
</ng-template>
<ng-template #noInfoProceeds>
  <div class="h-100 w-100 d-flex justify-content-center pt-3">
    <app-no-info class="fs-6" [text]="proceedsNoDataMsg"></app-no-info>
  </div>
</ng-template>
