<section
  #workSpaceBase
  id="workspace_base"
  class="workspace position-relative w-100 h-100"
>
  <ng-container *ngFor="let item of workspaceItems">
    <ng-container *ngTemplateOutlet="item.content"></ng-container>
  </ng-container>

  <div
    *ngIf="!workspaceItems.length"
    class="h-100 d-flex flex-column align-items-center justify-content-center"
    (contextmenu)="openAddComponentContextMenu($event)"
  >
    <app-context-menu
      *ngIf="isDisplayContextMenu"
      [show]="isDisplayContextMenu"
      [options]="contextMenuOptions"
      (menuClose)="onCloseContextMenu()"
    ></app-context-menu>
    <i class="icons icon-size-xxl text-neutral-medium">web_asset</i>
    <h3 class="text-neutral-medium">Sua workspace esta vazia.</h3>
    <h4 class="text-feedback-warning" (click)="openModalAddComponentStart()">
      Adicione um Componente para iniciar.
    </h4>
  </div>
</section>
