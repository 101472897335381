import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { interval, skipWhile, Subject, take, takeUntil } from 'rxjs';
import { WorkSpaceConfigs } from '../../config/workspace.configs';
import { CdkDrag } from '@angular/cdk/drag-drop';
import {
  ContextMenuModel,
  IContextMenuOptions,
} from '@shared/components/context-menu/context-menu.component';
import { DocWorkspace } from './model/doc';
import { IWorkSpaceComponet } from 'src/app/core/interface';
import { ToastService } from '@shared/services';
import { ConfigService } from '@core/config';

@Component({
  selector: 'app-workspace-doc[ref][item]',
  templateUrl: './workspace-doc.component.html',
  styleUrls: ['./workspace-doc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceDocComponent implements OnDestroy, OnInit, OnChanges {
  showSelectWindow: boolean =
    !inject(ConfigService).isWebAuthenticationChannel();
  @ViewChild(TemplateRef) content!: TemplateRef<any>;
  @Input() set item(value: IWorkSpaceComponet) {
    this.workspaceDoc = new DocWorkspace(value);
    this._loaded(this.workspaceDoc.doc);
  }
  isFocused: boolean = false;
  @Input() set focused(isFocused: boolean) {
    this.isFocused = isFocused;
  }
  @Input() lockWorkspace!: boolean;
  @Input() ref!: string;
  @Output() contextMenuClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() flaRemoveComponent = new EventEmitter<string>();
  @Output() flaLink = new EventEmitter<{
    key: string;
    comp: IWorkSpaceComponet;
  }>();
  @Output() flaClick = new EventEmitter<string>();
  @Output() flaMaximize = new EventEmitter<{
    key: string;
    maximized: boolean;
  }>();
  @Output() flaMove = new EventEmitter<IWorkSpaceComponet>();

  @Output() componentFocused = new EventEmitter<string>();

  isDisplayContextMenu!: boolean;
  rightClickMenuItems: Array<ContextMenuModel> = [];
  rightClickMenuPositionX!: number;
  rightClickMenuPositionY!: number;
  contextMenuOptions!: IContextMenuOptions;
  public workspaceDoc!: DocWorkspace | any;
  private untilDestroy$ = new Subject<void>();
  zIndex: number = 0;
  public isBuyBoleta: boolean = false;
  public isSellBoleta: boolean = false;
  public focusedComponent!: string;

  windowSelect: { icon: string; color: string; enable: boolean } = {
    icon: 'open_in_new',
    color: 'text-neutral-medium',
    enable: false,
  };

  maximized: { icon: string; color: string; enable: boolean } = {
    icon: 'fullscreen',
    color: 'text-neutral-medium',
    enable: false,
  };
  linked: { icon: string; color: string; enable: boolean } = {
    icon: 'link',
    color: 'text-brand-primary',
    enable: true,
  };

  get headerOptions() {
    return this.workspaceDoc.headerOptions;
  }

  get footerOptions() {
    return this.workspaceDoc.footerOptions;
  }

  get workspaceClass() {
    return (
      this.workspaceDoc.bodyBg +
      (this.footerOptions?.hasFooter ? ' height-footer' : '')
    );
  }

  get getBorderColorStockTrader() {
    let borderColor = '';
    this.workspaceDoc.name == 'Boleta Venda' &&
      (borderColor = 'border-multibroker-sell');
    this.workspaceDoc.name == 'Boleta Compra' &&
      (borderColor = 'border-multibroker-buy');
    return borderColor;
  }

  constructor(
    private configs: WorkSpaceConfigs,
    @Inject(DOCUMENT) private doc: Document,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this._checkHeightNull();
    this._checkHeightOverflow();
    this.workspaceDoc.name.includes('Boleta') && this._verifyBoletaType();
  }

  ngOnChanges(changes: SimpleChanges): void {
    !changes['lockWorkspace']?.isFirstChange() && this.disabledDragHandler();
  }

  private _verifyBoletaType(): void {
    this.isBuyBoleta = this.workspaceDoc.name == 'Boleta Compra';
    this.isSellBoleta = this.workspaceDoc.name == 'Boleta Venda';
  }

  ngOnDestroy(): void {
    this.untilDestroy$.next();
    this.untilDestroy$.complete();
  }

  displayContextMenu = (event: any) => {
    this.isDisplayContextMenu = true;
    this.contextMenuOptions = {
      position: {
        top: event.offsetY,
        left: event.offsetX,
      },
    };
  };

  move(component: DocWorkspace, transform: any) {
    const formatValue = (
      transform.source as CdkDrag
    ).element.nativeElement.style.transform
      .slice(12)
      .split(',');
    component.layout.transform = {
      x: parseInt(formatValue[0]),
      y: parseInt(formatValue[1]),
    };
    this.flaMove.emit(component.doc);
  }

  private _checkHeightNull() {
    if (this.workspaceDoc.layout.height === 'px') {
      this.workspaceDoc.layout.height = '420px';
    }
  }
  private _checkHeightOverflow() {
    const compoentHeight = this.workspaceDoc.layout.height;
    const workspaceHeight = document.querySelector('.workspace')!.clientHeight;
    if (workspaceHeight < parseFloat(compoentHeight)) {
      this.workspaceDoc.layout.height = workspaceHeight + 'px';
    }
  }

  private _loaded(value: IWorkSpaceComponet) {
    interval(100)
      .pipe(
        takeUntil(this.untilDestroy$),
        take(1),
        skipWhile(() => this.doc.getElementById(this.workspaceDoc.id) == null)
      )
      .subscribe(() => {
        this.disabledDragHandler();
        this.configs.updateSizes([value], false);
      });
  }

  private disabledDragHandler = () => {
    const source = document.getElementById(this.workspaceDoc.id);
    if (this.lockWorkspace)
      source?.addEventListener('mousedown', this.mouseDownCallback);
    else source?.removeEventListener('mousedown', this.mouseDownCallback);
  };

  private mouseDownCallback = (event: Event) => {
    document
      .getElementById(this.ref)
      ?.addEventListener('mouseup', (eventUp: Event) => {
        this.mouseUpCallback(event, eventUp);
      });
  };

  private mouseUpCallback = (eventDown: Event, eventUp: Event) => {
    if (this.mouseChangedPosition(eventDown, eventUp))
      this.disabledWorkspaceToast();

    document.getElementById(this.ref)?.removeAllListeners!('mouseup');
  };

  private disabledWorkspaceToast = () =>
    this.toastService.showToast('warning', `Suas workspaces estão bloqueadas.`);

  private mouseChangedPosition = (mouseDownEvent: any, mouseUpEvent: any) => {
    return (
      (mouseDownEvent.x !== mouseUpEvent.x ||
        mouseDownEvent.y !== mouseDownEvent.y) &&
      (mouseDownEvent.target.className.indexOf('cdk-drag-handle') > -1 ||
        mouseUpEvent.target.className.indexOf('cdk-drag-handle') > -1)
    );
  };
}
