import { forkJoin, catchError } from 'rxjs';
import { StockListService } from '@shared/services/api/trademap/V10/stock-list.service';
import { StockListStettingsService } from '@services/api/nitro-ws/v1/stock-list-stettings.service';
import { ListStocksService } from '@shared/services/core/list-stocks/list-stocks.service';

export class DeleteList {
  constructor(
    private listStocksService: ListStocksService,
    private stockListService: StockListService,
    private stockListStettingsService: StockListStettingsService
  ) {}

  public delete(idStockList: number, idConfig: number): Promise<boolean> {
    return new Promise((resolve) => {
      forkJoin({
        serviceList: this.stockListService.deleteStockList(idStockList),
        serviceConfig: this.stockListStettingsService.removeConfig(idConfig),
      }).subscribe(() => {
        this.deleteListInDb(idStockList);
        resolve(true);
      }),
        catchError((): any => resolve(false));
    });
  }

  private deleteListInDb(idStockList: number) {
    this.listStocksService.removeStockList(idStockList.toString());
  }
}
