import { Injectable, inject } from '@angular/core';
import {
  CHEETAH_CHANNEL,
  NOTIFICATION_CHEETAH_FIELDS,
} from '@shared/cheetah/cheetah.const';
import { BrowserStorageBase } from './browser-storage-base';
import { AUTH_SESSION_KEYS } from '../const/auth_util.const';
import { CheetahChannelState } from '@shared/channel/base/cheetah-channel.base';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { MarketDispatcher } from '@shared/cheetah/dispatcher/market.dispatcher';
import { Subscription, take, tap } from 'rxjs';
import { AuthService } from '@shared/services/auth.service';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { sharedSessionStorage } from './shared-session.storage';

@Injectable({
  providedIn: 'root',
})
export class SessionCoreService extends ReadStreamBase {
  private storage = new BrowserStorageBase(sharedSessionStorage);
  private marketDispatcher = inject(MarketDispatcher);
  private authService = inject(AuthService);
  private instanciate$!: Subscription;
  constructor() {
    super();
  }

  get id_investor() {
    return this.storage.getItem(AUTH_SESSION_KEYS.ID_INVESTOR);
  }

  get notificationParams(): SubscribeParam {
    return {
      channel_cheetah: CHEETAH_CHANNEL.NOTIFICATION,
      itemsArray: [`${this.id_investor}`],
      fields: NOTIFICATION_CHEETAH_FIELDS,
    };
  }

  setupGeneral = () => {
    this.marketDispatcher.setupSubscribes(this.notificationParams);
    setTimeout(() => {
      this.marketDispatcher.doUnsubscribers();
    }, 150);
  };

  unsubGeneral = () => {
    this.marketDispatcher.unsubscribe(this.notificationParams);
  };

  tapChangeStateHandler = (state: CheetahChannelState) => {
    state.value === 'ERROR' &&
      this.authService.performLogout({
        reason: 'CHEETAH_ERROR',
        systemMessage: 'Faça login novamente.',
      });
  };

  init() {
    this.instanciate$ = this.marketDispatcher
      .instanciate()
      .pipe(take(1), tap(this.tapChangeStateHandler))
      .subscribe(this.setupGeneral);
  }

  destroyInstance() {
    this.instanciate$ && this.instanciate$.unsubscribe();
  }
}
