import { Component, Input } from '@angular/core';
import { TTokenAuthenticator } from '../../types';

@Component({
  selector: 'app-token-authenticator-nitro',
  templateUrl: './token-authenticator-nitro.component.html',
  styles: ['.authenticator-card {border-radius: 4px}'],
})
export class TokenAuthenticatorNitroComponent {
  @Input() authenticator!: TTokenAuthenticator | null;
}
