import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { GridIChangeField } from '@shared/rocket-grid';
import { IAlert, IAlertSettings, ISearchStock } from '@core/interface';
import { WorkSpaceConfigs } from '@core/workspace';
import { RocketModalService } from '@shared/rocket-components/components';
import {
  EditAlertModalComponent,
  IChangeAlertPrice,
} from '../modals/edit-alert-modal/edit-alert-modal.component';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { AlertService } from '@shared/services/api/trademap/v1/alert.service';
import { Subject, auditTime, bufferTime, delay, filter, takeUntil } from 'rxjs';
import {
  ColumnApi,
  FilterChangedEvent,
  GridApi,
  GridOptions,
  RowNode,
} from 'ag-grid-community';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { NEW_LINE_ID } from '@shared/components/stock-chart/constants/stock-chart.constant';
import { ToastService } from '@shared/services/toast.service';
import { OrdersService } from '@shared/services/orders.service';
import { StockServiceRT } from '@shared/services/api/nitro-ws/v1/stock.service';
import { ORDER_OPEN_STATUS_DIC } from '@shared/dictionary/order.dictionary';
import { OrdersHistoryTableViewComponent } from './orders-history-table-view/orders-history-table-view.component';
import { TIGER_LINE_TYPE } from '@shared/tiger-chart/enum';
import { TABS_TYPE, TP_STATUS } from '../constants/orders-history.const';
import {
  calculateSUMForAlerts,
  calculateSUMForOrders,
  RowTotal,
} from '../base/row-total';
import { OrdersHistoryService } from '../service/orders-history.service';
import { OrdersHistoryConfigService } from '../service/orders-history-config.service';
import { SharedWorkerEventsService } from '@shared/services/core/shared-worker-events/shared-worker-events.service';
import { SharedWorkerEventsActions } from '@shared/services/core/shared-worker-events/shared-worker-events.constants';
import { ListOrdersService } from '@shared/services/core/list-orders/orders.service';

import { OrdersHistoryCardListComponent } from './orders-history-card-list/orders-history-card-list.component';
import { OrdersHistoryTotalRowComponent } from './orders-history-total-row/orders-history-total-row.component';
@Component({
  selector: 'app-orders-history-grid',
  templateUrl: './orders-history-grid.component.html',
  styleUrls: ['./orders-history-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersHistoryGridComponent
  implements AfterViewInit, OnChanges, OnDestroy {
  @Input() ignoreRocketService!: boolean;
  @Input() refComponent!: any;
  @Input() metadataComponent!: any;
  @Input() globalCdStock!: any;
  @Input() idBrokerSelected!: any;
  @Input() componentId!: any;
  @Input() onUpdateField!: GridIChangeField;
  @Input() showTotalRow!: boolean;
  @Input() noRowsTemplateMessage: string = 'Não existem dados.';
  @Input() componentType!: string;
  @Input() componentKey!: string;
  @Input() width!: string;
  @Input() height!: string;
  @Input() fieldIndexer = '';
  @Input() tabSelected: any = {};
  @Input() tabSelectedUpdate!: string;
  @Input() isCardView!: boolean;
  @Input() isDayTrade!: boolean;
  @Output() viewReadyHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() contextMenuHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() totalGridReadyHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() columnResizedHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() columnMovedHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeStockHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() gridSortChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() gridColumnApiReference: EventEmitter<any> = new EventEmitter<any>();
  @Output() gridFilterChange: EventEmitter<FilterChangedEvent> =
    new EventEmitter<FilterChangedEvent>();
  @Output() applyFilter: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('ordersTable')
  ordersHistoryTable!: OrdersHistoryTableViewComponent;
  @ViewChild('ordersCards')
  ordersCardList!: OrdersHistoryCardListComponent;
  @ViewChild('totalRow')
  totalRow!: OrdersHistoryTotalRowComponent;

  tableConfig: any = {};
  bottomColumns: any[] = [];
  updateObject: any = {};
  ordersStubDict: Map<number, any> = new Map<number, any>();
  public disableCancelAllOrders: boolean = true;
  public disableCancelAllAlerts: boolean = true;
  public disableCancelAllPositions: boolean = true;

  get disableCancelAllAlertsOrOrders() {
    if (this.componentKey === 'ORDER_OPEN') {
      return this.disableCancelAllOrders;
    }
    return this.disableCancelAllAlerts;
  }

  get gridApi() {
    return this.ordersHistoryTable?.agGridApi;
  }

  get checkGridTotal() {
    return this.agGridTotalApi && !this.agGridTotalApi['destroyCalled'];
  }

  get dataValues() {
    return Object.values(this.dataCache);
  }

  private onDestroy$ = new Subject<void>();
  private agGridTotalApi!: GridApi;
  public columnApi!: ColumnApi;
  public updateTotalSubject = new Subject<void>();
  private _gridObjectUpdate = new Subject<any>();
  set gridObjectUpdate(value: { transaction: any; tab: string; config?: any }) {
    this._gridObjectUpdate.next(value);
  }
  public subjectDebounced = new Subject<{
    transaction: any;
    tab: string;
    config?: any;
  }>();

  topOptions: GridOptions = {
    alignedGrids: [],
    defaultColDef: {},
    suppressHorizontalScroll: true,
  };
  bottomOptions: GridOptions = {
    alignedGrids: [],
    defaultColDef: {},
    rowHeight: 20,
  };
  public tooltipCancel = 'Cancelar todas as ordens';
  public dataCache: any = {};

  get showSideBarResetAllPositions() {
    return (
      this.componentKey === TABS_TYPE.SWINGTRADING ||
      this.componentKey === TABS_TYPE.INTRADIA ||
      this.componentKey === TABS_TYPE.POSITION
    );
  }

  constructor(
    private _globalStock: GlobalSelectedStockSubscription,
    private cdRef: ChangeDetectorRef,
    private _workSpaceConfigs: WorkSpaceConfigs,
    private _rocketModalService: RocketModalService,
    private _stockChartService: StockChartService,
    private _alertService: AlertService,
    private ordersService: OrdersService,
    private _toastService: ToastService,
    private _stockService: StockServiceRT,
    private _ordersHistoryService: OrdersHistoryService,
    private _configService: OrdersHistoryConfigService,
    private rocketSharedWorkerEvents: SharedWorkerEventsService,
    private _listOrdersService: ListOrdersService,
    private ordersHistoryService: OrdersHistoryService,
  ) {
    this.topOptions.alignedGrids?.push(this.bottomOptions);
    this.bottomOptions.alignedGrids?.push(this.topOptions);
    this.topOptions.context = {
      subscriber: this._globalStock,
    };
    this.ordersService.cancelOrdesByRestPosition
      .pipe(takeUntil(this.onDestroy$), delay(500))
      .subscribe((position: any) => {
        if (this.ordersHistoryService.stockInResetPosition[position.cd_stock]) {
          this.cancelOrdersByResetPosition(position);
        }
      });

    this._gridObjectUpdate
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.updateGridData);
    this.updateTotalSubscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { componentKey, tabSelected, idBrokerSelected, isDayTrade } = changes;
    if (componentKey?.currentValue) {
      this.disableCancelAllPositions = true;
      if (
        this.componentKey === 'ORDER_OPEN' ||
        this.componentKey === 'ALERTS'
      ) {
        this.componentKey === 'ORDER_OPEN' &&
          (this.tooltipCancel = 'Cancelar todas as ordens');
        this.componentKey === 'ALERTS' &&
          (this.tooltipCancel = 'Cancelar todos os Alertas');
      }
    }

    if (
      tabSelected?.previousValue !== tabSelected?.currentValue ||
      idBrokerSelected?.previousValue !== idBrokerSelected?.currentValue ||
      isDayTrade?.previousValue !== isDayTrade?.currentValue
    ) {
      this.dataCache = {};
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.gridApi && this.gridApi.destroy();
    this.agGridTotalApi && this.agGridTotalApi.destroy();
  }

  ngAfterViewInit() {
    this.startSubscription();
    this.cdRef.detectChanges();
  }

  onGridReady(gridApi: GridApi) {
    this.viewReadyHandler.emit(gridApi);
    this.cdRef.detectChanges();
  }

  onColumnApi(columnApi: ColumnApi) {
    this.columnApi = columnApi;
    this.cdRef.detectChanges();
  }

  onCardsReady() {
    this.viewReadyHandler.emit();
    this.cdRef.detectChanges();
  }

  onTotalGridReady = (event?: any) => {
    this.agGridTotalApi = event;
    this.totalGridReadyHandler.emit(this.agGridTotalApi);
    this.cdRef.detectChanges();
  };

  public getStockByResetPosition(event: any): void {
    const { position, isResetAllPosition } = event;
    if (
      this._globalStock.getGlobalStockSelected().cd_stock === position.cd_stock
    ) {
      this.ordersHistoryService.resetPosition(
        this._globalStock.getGlobalStockSelected(),
        position,
        isResetAllPosition
      );
      return;
    }
    this._stockService
      .searchStockByStock(position.cd_stock)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response: ISearchStock) => {
        this.ordersHistoryService.resetPosition(
          response,
          position,
          isResetAllPosition
        );
      });
  }

  private cancelOrdersByResetPosition(position: any): void {
    if (position.isResetAllPosition) {
      delete this.ordersHistoryService.stockInResetPosition[position.cd_stock];
      return;
    }
    const orders: any[] = this._listOrdersService.retrieveOrdersDict()
      .values()
      .filter(
        (order: any) =>
          order.ds_order_type !== 'A mercado' &&
          ORDER_OPEN_STATUS_DIC.has(order.cd_order_status) &&
          order.cd_stock === position.cd_stock
      );
    orders?.length && this.ordersService.cancelOrders(orders);
    delete this.ordersHistoryService.stockInResetPosition[position.cd_stock];
  }

  public editOrder = (value: any) => {
    this._stockService.searchStockByStock(value.cd_stock).subscribe((stock) => {
      this._globalStock.changeGlobalStock(stock);
    });
    this._workSpaceConfigs.openStockTrade = {
      order: value,
      isEditMode: true,
    };
  };

  public openModalChangeAlertPrice(alertInfo: IAlert): void {
    const modalRef = this._rocketModalService.open(EditAlertModalComponent, {
      centered: true,
      keyboard: false,
      data: alertInfo,
      size: 'sm',
    });

    modalRef.afterDismissed.subscribe((res: IChangeAlertPrice) => {
      if (!res || !res.id_trade_system) return;
      const direction =
        parseFloat(res.price.toFixed(2)) >= res.vl_close ? '>=' : '<=';
      this._alertService
        .updateAlert(
          res.id_trade_system,
          parseFloat(res.price.toFixed(2)),
          direction,
          2,
          res.cd_stock
        )
        .subscribe((alert: any) => {
          const newAlert = alert;
          const isCompleted = res.cd_status === 'COMPLETED';
          let id = `${NEW_LINE_ID}_`;
          if (isCompleted) {
            id += newAlert.id_trade_system;
          } else {
            id += res.id_trade_system;
          }
          if (res.cd_status)
            this._stockChartService.alertCharts$.next({
              ref: this.refComponent,
              type: 'UPDATE',
              alertAnother: false,
              itemUpdate: {
                id,
                value: res.price,
                data: <any>null,
                idTradeSystem: res.id_trade_system,
              },
              newAlert: newAlert,
            });
          this._alertService.deleteAlertInOrderHistory(res.id_trade_system);
          this._alertService.addNewAlertInOrderHistory(alert.result);
        });
    });
  }

  private startSubscription() {
    this.updateDataBuffer();
    this._alertService.handleAlertSettings
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((params: IAlertSettings) => {
        if (params.action === 'EDIT' || params.action === 'REFRESH') {
          this.openModalChangeAlertPrice(params.alert);
          return;
        }
        if (params.action === 'DELETE') {
          this.sendEventsToRemoveAlertInChartAndOrdersHistory(params.alert);
          return;
        }
      });
  }

  public cancelAllOrdersAndAlerts(): void {
    const data: any = this.isCardView
      ? Object.values(this.dataCache)
      : (this.gridApi.getModel() as any)?.rowsToDisplay;
    const isAlerts = this.componentKey === 'ALERTS';
    if (!data?.length) {
      this._toastService.showToast(
        'warning',
        isAlerts
          ? 'Você não tem alertas no momento!'
          : 'Você não tem ordens em aberto no momento!'
      );
      return;
    }
    if (isAlerts) {
      data.forEach((item: any) => {
        this.sendEventsToRemoveAlertInChartAndOrdersHistory(
          this.isCardView ? item : item.data
        );
      });
      return;
    }
    const orders: any[] = [];
    data.forEach((item: any) => {
      orders.push(this.isCardView ? item : item.data);
    });
    this.ordersService.cancelOrders(orders);
  }

  public sendEventsToRemoveAlertInChartAndOrdersHistory(
    alertInfo: IAlert
  ): void {
    if (!document.getElementsByTagName('app-stock-chart').length) {
      this.removeAlerts(alertInfo);
      return;
    }
    const boxID = `Tiger-chart-new-line_${alertInfo.id_trade_system}`;
    this._stockChartService.alertCharts$.next({
      ref: '',
      type: 'DELETE',
      alertAnother: true,
      itemDelete: {
        id: boxID,
        value: alertInfo.vl_value,
        data: <any>null,
        idTradeSystem: alertInfo.id_trade_system,
        boxIdAlert: boxID,
        type: TIGER_LINE_TYPE.ALERT,
        cdStock: alertInfo.cd_stock,
      },
    });
  }

  private removeAlerts(alertInfo: IAlert): void {
    this._alertService
      .excludeAlert(
        alertInfo.id_trade_system,
        alertInfo.cd_stock,
        alertInfo.vl_value,
        alertInfo.tick_size_denominator
      )
      .subscribe();
    this._alertService.deleteAlertInOrderHistory(alertInfo.id_trade_system);
  }

  gridOrderHandler(data: any): void {
    if (!this.ordersStubDict.has(data.id_order)) {
      this.addNewOrder(data);
    } else {
      this.updateExistingOrder(data);
    }
    this.cdRef.detectChanges();
  }

  private addNewOrder(data: any): void {
    this.ordersStubDict.set(data.id_order, { ...data, command: 'add' });
    if (this.shouldAddToTransaction(data) && this.isStatusMatchingTab(data)) {
      this.gridObjectUpdate = {
        transaction: { add: [data], addIndex: 0 },
        tab: structuredClone(this.tabSelectedUpdate),
        config: { skipDebounce: true },
      };
    }
  }

  private updateExistingOrder(data: any): void {
    const previousData = structuredClone(
      this.ordersStubDict.get(data.id_order)
    );
    this.ordersStubDict.set(data.id_order, { ...data, command: 'update' });

    const flow = this.ordersHistoryService.getCorrectFlow(
      this.tabSelected.type,
      previousData.tp_status,
      data.tp_status,
      data.cd_order_status,
      previousData.cd_order_status
    );

    flow === 'add' &&
      !this.isCardView &&
      this.gridApi.forEachNode((node) => {
        const data = node.data;
        if (data.id_order === previousData.id_order) {
          this.gridObjectUpdate = {
            transaction: { remove: [data] },
            tab: structuredClone(this.tabSelectedUpdate),
            config: { skipDebounce: true, redrawRows: true },
          };
        }
      });
    if (
      flow !== 'remove' &&
      (!this.shouldAddToTransaction(data) || !this.isStatusMatchingTab(data))
    ) {
      return;
    }

    const transaction: any =
      flow === 'add' ? { add: [], addIndex: 0 } : { update: [], remove: [] };
    transaction[flow].push(data);
    this.gridObjectUpdate = {
      transaction,
      tab: structuredClone(this.tabSelectedUpdate),
      config: { applyFilters: true, redrawRows: true },
    };
  }

  private isStatusMatchingTab(data: any): boolean {
    return this.ordersStubDict.has(data.id_order);
  }

  private shouldAddToTransaction(data: any): boolean {
    return (
      data.tp_status === this.tabSelected.type ||
      this.tabSelected.type === TP_STATUS.TOTAL ||
      (this.tabSelected.type === TP_STATUS.EXECUTADA &&
        (data.cd_order_status === 'EXEC' ||
          data.cd_order_status === 'PARC' ||
          data.cd_order_status === 'PCAN'))
    );
  }

  private updateGridData = (updateObject: {
    transaction: any;
    tab: string;
    config?: any;
  }) => {
    if (this.isCardView) {
      this.updateCardsView(updateObject);
    } else {
      this.updateTableView(updateObject);
    }
  };

  private updateCardsView(updateObject: {
    transaction: any;
    tab: string;
    config?: any;
  }) {
    this.saveOnCache(updateObject.transaction);
    this.rocketSharedWorkerEvents.emitSharedWorkerEvent(SharedWorkerEventsActions.UPDATE_TOTAL_ORDERS, {});
    this.cdRef.detectChanges();
  }

  private updateTableView(updateObject: {
    transaction: any;
    tab: string;
    config?: any;
  }) {
    if (updateObject.config.skipDebounce) {
      this.updateDataTable(updateObject.transaction, {
        applyFilters: true,
        updateTotal: true,
      });
      return;
    }
    this.subjectDebounced.next(updateObject);
  }

  private updateDataBuffer(): void {
    this.subjectDebounced
      .pipe(
        bufferTime(100),
        filter((batch) => batch.length > 0),
        takeUntil(this.onDestroy$)
      )
      .subscribe((batch: any[]) => {
        const transactions: any = { add: [], remove: [], update: [] };
        let configs = {};

        batch.forEach((item: any) => {
          if (item.tab === this.tabSelectedUpdate) {
            if (item.transaction.add) {
              if (item.transaction.addIndex === 0) {
                transactions.add.unshift(...item.transaction.add);
                transactions.addIndex = 0;
              } else {
                transactions.add.push(...item.transaction.add);
              }
            }
            if (item.transaction.remove)
              transactions.remove.push(...item.transaction.remove);
            if (item.transaction.update)
              transactions.update.push(...item.transaction.update);
            configs = { ...configs, ...item.config };
          }
        });
        this.updateDataTable(transactions, configs);
      });
  }

  private updateTotalSubscription(): void {
    this.rocketSharedWorkerEvents.listenSelectedAction(SharedWorkerEventsActions.UPDATE_TOTAL_ORDERS)
      .pipe(auditTime(300), takeUntil(this.onDestroy$))
      .subscribe(() => {
        const rowData: any[] = [];
        if (!this.isCardView)
          this.gridApi.forEachNodeAfterFilterAndSort((rowNode) => {
            rowData.push(this.processRowData(rowNode));
          });
        else
          Object.values(this.dataCache).forEach((data: any) => {
            rowData.push(this.processRowData(data));
          });
        this.updateTotalRowData(rowData);
      });
  }

  private processRowData = (rowNode: RowNode) => {
    const data = rowNode.data || rowNode;
    if (
      [
        TABS_TYPE.ALERTS,
        TABS_TYPE.INTRADIA,
        TABS_TYPE.SWINGTRADING,
        TABS_TYPE.POSITION,
      ].indexOf(this.metadataComponent.lastActiveTab) > -1
    ) {
      return data;
    }
    if (this.isDayTrade === data?.has_day_trade) return data;
  };

  updateTotalRowData(rowData: any[]): void {
    if (this.tabSelected.component_type === TABS_TYPE.ORDER) {
      this.updateTotalRowForOrders(rowData);
    } else if (this.tabSelected.component_type === TABS_TYPE.ALERTS) {
      this.updateTotalRowForAlerts(rowData);
    } else {
      this.updateTotalRowForOtherTabs(rowData);
    }
  }

  private updateTotalRowForOtherTabs(rowData: any[]): void {
    const totalRowData = new RowTotal();
    totalRowData.calcularSomatorio(rowData);
    const plural = rowData.length > 1 ? 's' : '';
    this.setTotalRow({
      ...totalRowData,
      ...{
        key: this.tabSelected.key,
        cd_stock: `${rowData.length} Ativo${plural}`,
      },
    });
    this.checkHasPositions(totalRowData);
  }

  private checkHasPositions(totalRowData: any): void {
    switch (this.tabSelected.key) {
      case TABS_TYPE.INTRADIA:
        !!totalRowData.qtty_final_daytrade &&
          (this.disableCancelAllPositions = false);
        !totalRowData.qtty_final_daytrade &&
          (this.disableCancelAllPositions = true);
        break;
      case TABS_TYPE.SWINGTRADING:
        !!totalRowData.qtty_final_swing &&
          (this.disableCancelAllPositions = false);
        !totalRowData.qtty_final_swing &&
          (this.disableCancelAllPositions = true);
        break;
      case TABS_TYPE.POSITION:
        !!totalRowData.qtty_final && (this.disableCancelAllPositions = false);
        !totalRowData.qtty_final && (this.disableCancelAllPositions = true);
        break;
      default:
        this.disableCancelAllPositions = true;
        break;
    }
    this.cdRef.detectChanges();
  }

  private updateTotalRowForAlerts(rowData: any[]): void {
    if (rowData.length) {
      this.disableCancelAllAlerts = false;
    } else {
      this.disableCancelAllAlerts = true;
    }
    const data = calculateSUMForAlerts(rowData);
    this.setTotalRow(data);
  }

  private updateTotalRowForOrders(rowData: any[]): void {
    if (this.tabSelected.key === 'ORDER_OPEN' && rowData.length) {
      this.disableCancelAllOrders = false;
    } else {
      this.disableCancelAllOrders = true;
    }
    const data = calculateSUMForOrders(rowData);
    this.setTotalRow(data);
  }

  setTotalRow(totalRowData: any) {
    if (
      (this.isCardView && !this.totalRow) ||
      (!this.isCardView &&
        (!this.checkGridTotal ||
          !this.agGridTotalApi ||
          !this.agGridTotalApi.getColumnDefs() ||
          !this.agGridTotalApi.getColumnDefs()?.length))
    )
      return;

    if (this.isCardView) {
      this.totalRow.setTotalData(totalRowData);
    } else {
      this.agGridTotalApi.applyTransactionAsync({
        update: [totalRowData],
      });
    }
    this.cdRef.detectChanges();
  }

  updateDataTable(
    transaction: any,
    config?: {
      applyFilters?: boolean;
      updateTotal?: boolean;
      redrawRows?: boolean;
      skipDebounce?: boolean;
    }
  ) {
    this.gridApi?.applyTransactionAsync(transaction, () => {
      if (config?.applyFilters) this.applyFilter.emit();
      if (config?.redrawRows) this.gridApi.redrawRows();
    });
    if (config?.updateTotal) this.rocketSharedWorkerEvents.emitSharedWorkerEvent(SharedWorkerEventsActions.UPDATE_TOTAL_ORDERS, {});
    this.saveOnCache(transaction);
    this.cdRef.detectChanges();
  }

  saveOnCache = (transaction: any) => {
    if (transaction.add)
      transaction.add?.forEach((item: any) => {
        const key = item.key || item.idRow || item.id_trade_system;
        this.dataCache[key] = item;
      });
    if (transaction.update)
      transaction.update.forEach((item: any) => {
        const key = item.key || item.idRow || item.id_trade_system;
        const previous = this.dataCache[key] || {};
        this.dataCache[key] = { ...previous, ...item };
      });
    if (transaction.remove)
      transaction.remove?.forEach((item: any) => {
        const key = item.key || item.idRow || item.id_trade_system;
        delete this.dataCache[key];
      });
  };

  updateSelectedStock(stock: ISearchStock) {
    const stocks: any[] = [];
    if (!this.isCardView && this.gridApi) {
      this.gridApi.forEachNode((node) => {
        const data = node.data;
        data.isSelected =
          stock.cd_stock_order === data.cd_stock ||
          stock.cd_stock === data.cd_stock;
        data.command = 'update';
        stocks.push(data);
      });

      this.gridObjectUpdate = {
        transaction: { update: stocks },
        config: { skipDebounce: true },
        tab: structuredClone(this.tabSelectedUpdate),
      };
    }
  }

  cleanSortSave(): void {
    if (
      !this.columnApi ||
      !this.metadataComponent.gridSort ||
      !this.metadataComponent.gridSort[this.tabSelected.ref]
    )
      return;
    this.columnApi.applyColumnState({
      state: this.metadataComponent.gridSort[this.tabSelected.ref],
    });
  }

  isOnTab(key: string): boolean {
    return this.tabSelected.key === key;
  }

  emitContextMenuHandler(config: any) {
    if (this.isOnTab(TABS_TYPE.ALERTS)) {
      this._configService.openAlertTabContextMenu(
        this.refComponent,
        config,
        this.tabSelected,
        !this.isCardView ? this.gridApi : undefined,
        !this.isCardView ? this.columnApi : undefined,
        this.isCardView
      );
    }
    if (this.tabSelected.component_type === TABS_TYPE.ORDER) {
      this._configService.openOrderTabsContextMenu(
        this.refComponent,
        config,
        !this.isCardView ? this.gridApi : undefined,
        !this.isCardView ? this.columnApi : undefined,
        this.isCardView
      );
    }

    if (this._configService.isAnyPositionTab(this.tabSelected)) {
      this._configService.openPositionTabContextMenu(
        this.refComponent,
        { ...config, showTotalRow: this.showTotalRow },
        this.tabSelected,
        !this.isCardView ? this.gridApi : undefined,
        !this.isCardView ? this.columnApi : undefined,
        this.isCardView
      );
    }
  }

  public resetAllPosition(isReset: boolean): void {
    if (isReset) {
      this.dataValues.forEach((item: any) => {
        this.checkResetPosition(item, true);
      });
    }
  }

  private checkResetPosition(
    event: any,
    isResetAllPosition: boolean = false
  ): void {
    if (
      this.componentType === TABS_TYPE.INTRADIA ||
      this.componentType === TABS_TYPE.SWINGTRADING ||
      this.componentType === TABS_TYPE.POSITION
    ) {
      const fieldQtty = {
        [TABS_TYPE.INTRADIA]: 'qtty_final_daytrade',
        [TABS_TYPE.SWINGTRADING]: 'qtty_final_swing',
        [TABS_TYPE.POSITION]: 'qtty_final',
      };
      const fieldOpen = {
        [TABS_TYPE.INTRADIA]: 'net_day_daytrade',
        [TABS_TYPE.SWINGTRADING]: 'net_day_swing',
        [TABS_TYPE.POSITION]: 'net_day',
      };
      if (
        (event[fieldQtty[this.componentType]] !== '0' ||
          event[fieldOpen[this.componentType]] !== '0') &&
        !this.ordersHistoryService.stockInResetPosition[event.cd_stock]
      ) {
        this.ordersHistoryService.stockInResetPosition[event.cd_stock] =
          event.cd_stock;
        const position = {
          ...event,
          qtd: event[fieldQtty[this.componentType]],
          isResetAllPosition,
        };
        this.getStockByResetPosition({ position, isResetAllPosition });
      }
    }
  }
}
