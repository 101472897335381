import { Injectable } from '@angular/core';
import { ConfigService } from '@core/config';
import { COLUMNS_TO_REMOVE, CONFIG_VIEW } from './config-view.contants';
import { IConfigView } from './config-view.interface';

@Injectable({
  providedIn: 'root',
})
export class ConfigViewService {
  constructor(private configService: ConfigService) {}

  getConfigView() {
    const configView = CONFIG_VIEW;
    if (!this.configService.isBroker()) {
      return configView;
    }
    const hashRemoveColumns: any = COLUMNS_TO_REMOVE;
    const views: IConfigView[] = [];
    configView.forEach((config) => {
      const columns = config.columns.filter(
        (column) => !hashRemoveColumns[column.field]
      );
      if (columns.length) {
        views.push({ ...config, columns });
      }
    });
    return views;
  }
}
