import {
  RANKING_BROKER_FILTERS,
  RANKING_BROKER_PERIODS,
} from '../enum/ranking-broker.enum';
import {
  IBrokaregeTopPayload,
  IBrokerageActivityPayload,
  IBrokerageDetailPayload,
} from '../interface/ranking-broker.interface';

export const TAB_FILTERS = [
  {
    label: 'Vl. Liquido',
    code: RANKING_BROKER_FILTERS.VL_NET,
    active: true,
  },
  {
    label: 'Vl. Bruto',
    code: RANKING_BROKER_FILTERS.VL_TOTAL,
    active: false,
  },
  {
    label: 'Vl. Compra',
    code: RANKING_BROKER_FILTERS.VL_BUY,
    active: false,
  },
  {
    label: 'Vl. Venda',
    code: RANKING_BROKER_FILTERS.VL_SELL,
    active: false,
  },
  {
    label: '% Compra',
    code: RANKING_BROKER_FILTERS.VL_PERCENTAGE_BUY,
    active: false,
  },
  {
    label: '% Venda',
    code: RANKING_BROKER_FILTERS.VL_PERCENTAGE_SELL,
    active: false,
  },
  {
    label: 'Negócios',
    code: RANKING_BROKER_FILTERS.VL_BUSINESS,
    active: false,
  },
];

export const FLASH_TAB_FILTERS = [
  {
    label: 'Vl. Liquido',
    code: RANKING_BROKER_FILTERS.VL_NET,
    active: true,
  },
  {
    label: 'Vl. Bruto',
    code: RANKING_BROKER_FILTERS.VL_TOTAL,
    active: false,
  },
  {
    label: 'Vl. Compra',
    code: RANKING_BROKER_FILTERS.VL_BUY,
    active: false,
  },
  {
    label: 'Vl. Venda',
    code: RANKING_BROKER_FILTERS.VL_SELL,
    active: false,
  },
  {
    label: 'Negócios',
    code: RANKING_BROKER_FILTERS.VL_BUSINESS,
    active: false,
  },
];

export const FLASH_FILTERS_FIELD: any = {
  vl_total: 'volume_total_sum',
  vl_net: 'volume_net_sum',
  vl_negocios: 'quantity_trade_net_sum',
  vl_buuy: 'volume_buy_sum',
  vl_sell: 'volume_sell_sum',
  percentage_buy: null,
  percentage_sell: null,
};

export const periods = {
  today: 'Intradia',
  yesterday: 'Ontem',
  oneweek: '1 Semana',
  onemonth: '1 Mês',
  threemonth: '3 Meses',
  sixmonth: '6 Meses',
  oneyear: '12 Meses',
  twoyear: '2 Anos',
};

export const periodsSelector = [
  {
    id: RANKING_BROKER_PERIODS.TODAY,
    label: 'Intradia',
  },
  {
    id: RANKING_BROKER_PERIODS.YESTERDAY,
    label: 'Ontem',
  },
  {
    id: RANKING_BROKER_PERIODS.ONE_WEEK,
    label: '1 Semana',
  },
  {
    id: RANKING_BROKER_PERIODS.ONE_MONTH,
    label: '1 Mês',
  },
  {
    id: RANKING_BROKER_PERIODS.THREE_MONTH,
    label: '3 Meses',
  },
  {
    id: RANKING_BROKER_PERIODS.SIX_MONTH,
    label: '6 Meses',
  },
  {
    id: RANKING_BROKER_PERIODS.ONE_YEAR,
    label: '12 Meses',
  },
  {
    id: RANKING_BROKER_PERIODS.TWO_YEAR,
    label: '2 Anos',
  },
];

export const BROKERAGE_TOP_DEFAULT_FILTERS: IBrokaregeTopPayload = {
  cd_stock: null,
  id_exchange: null,
  limit: 0,
  tipo: 3,
  dt_inicio: 0,
  dt_fim: 0,
  period: RANKING_BROKER_PERIODS.TODAY,
};

export const BROKERAGE_ACTIVITY_DEFAULT_FILTERS: IBrokerageActivityPayload = {
  cd_stock: null,
  id_exchange: null,
  corretoras: [],
  dt_inicio: 0,
  dt_fim: 0,
  period: RANKING_BROKER_PERIODS.TODAY,
  marketType: 'geral',
};

export const BROKERAGE_DETAIL_DEFAULT_FILTERS: IBrokerageDetailPayload = {
  idBrokerage: null,
  dt_inicio: 0,
  dt_fim: 0,
  marketType: 'geral',
  period: RANKING_BROKER_PERIODS.TODAY,
};
