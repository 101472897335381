<workspace-doc-header
  css="w-100 d-flex header-wrapper border-bottom"
  [item]="component"
>
  <fla-workspace-doc-header-item [enableContent]="true" [hideBorder]="true">
    <div class="d-flex justify-content-between w-100">
      <section class="hstack">
        <a
          *ngFor="let header of headers"
          type="button"
          class="nav-link p-2 lh-sm border-right fw-bolder text-center"
          [ngClass]="{
            'text-brand-primary': header.active,
            'text-neutral-medium ': !header.active
          }"
          (click)="selectMenu(header.code)"
        >
          {{ header.label }}
        </a>
      </section>
    </div>
  </fla-workspace-doc-header-item>
</workspace-doc-header>
