import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { formatDate, getVolumeText } from 'src/app/utils/utils.functions';
import { FlowInvestorsService } from '../../flow-investors.service';
import { colorsAcumulados } from '../../consts/colors';

@Component({
  selector: 'app-flow-cards',
  templateUrl: './flow-cards.component.html',
  styleUrls: ['./flow-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowCardsComponent implements OnInit {
  @Input() public cards: any = [];
  @Input() public type: any = 'FUTURE';
  @Input() public field: any = 'cd_type_investor';
  @Input() public expanded: boolean = false;
  @Input() public isFilter: boolean = true;
  @Output() hideTyper: EventEmitter<string> = new EventEmitter<string>();
  public colors: any = colorsAcumulados;

  public dtReference: string = '';
  constructor(
    private _flowInvestorsService: FlowInvestorsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.formatFields();
  }

  public formatFields() {
    this.cards.map((item: any) => {
      this.dtReference = formatDate(item.dt_reference.toString());
      const format = getVolumeText('pt-BR', item.sum_delta_volume);
      item.delta_formatted =
        format.slice(0, format.length - 2) +
        format.slice(format.length - 1, format.length);
      item.disabled = false;
      return item;
    });
    this.cdr.detectChanges();
  }

  public hideType(id: string, card: any) {
    if (!this.isFilter) return;
    card.disabled = !card.disabled ?? true;
    this._flowInvestorsService.hideTyper$.next(id);
    this.cdr.detectChanges();
  }
}
