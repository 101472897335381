import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRentFieldsToSort } from '@shared/components/rent/interface/rent.interface';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';

@Injectable({
  providedIn: 'root',
})
export class RentPreferencesService extends CustomPreferencesService {
  private _updateKey: number = 0;
  public RENT_STORAGE_KEYS = {
    GRID_HEADERS: 'RENT_PREFERENCES_GRID_HEADERS',
    GRID_INITIAL_HEADER_FILTER: 'RENT_PREFERENCES_GRID_INITIAL_HEADER_FILTER',
  };

  constructor(public http: HttpClient) {
    super(http);
  }

  set gridHeadersWidth(headersConf: any) {
    this.setValueCustomPreferences(
      this.RENT_STORAGE_KEYS.GRID_HEADERS,
      JSON.stringify({ headers: headersConf, updateKey: this._updateKey })
    );
  }

  get gridHeadersWidth(): any {
    const response = JSON.parse(
      this.storage.getItem(this.RENT_STORAGE_KEYS.GRID_HEADERS)
    );
    if (
      !response ||
      !response.headers ||
      response.updateKey !== this._updateKey
    )
      return null;
    return response.headers;
  }

  set sortTab(sortTabSelected: IRentFieldsToSort) {
    this.setValueCustomPreferences(
      this.RENT_STORAGE_KEYS.GRID_INITIAL_HEADER_FILTER,
      JSON.stringify(sortTabSelected)
    );
  }

  get sortTab(): IRentFieldsToSort {
    return JSON.parse(
      this.storage.getItem(this.RENT_STORAGE_KEYS.GRID_INITIAL_HEADER_FILTER)
    );
  }
}
