import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TCallPutOptions, TOptions } from '../../../types';
import { OptionsComponentService } from '../../../options-component.service';

@Component({
  selector: 'app-action-buttons-cell-renderer',
  template: ` <ng-container>
    <div class="d-flex">
      <span
        (click)="rowSelected('call')"
        *ngIf="isCallPut && !hideButtons"
        class="float-left w-100"
      >
        <rocket-button
          css="bg-multibroker-buy mr-1 p-1 very-small"
          label="Comprar"
          [isSmall]="true"
          (rocketClick)="buttonClicked('B', '_call')"
        ></rocket-button>
        <rocket-button
          css="bg-multibroker-sell very-small"
          label="Vender"
          [isSmall]="true"
          (rocketClick)="buttonClicked('S', '_call')"
        ></rocket-button>
      </span>
      <span
        (click)="rowSelected('put')"
        *ngIf="!hideButtons"
        [ngClass]="{
          'w-50': isCallPut && !hideButtons,
          'w-100': !(isCallPut && !hideButtons)
        }"
        class="float-right d-flex justify-content-end"
      >
        <rocket-button
          css="bg-multibroker-buy mr-1 p-1 very-small"
          label="Comprar"
          [isSmall]="true"
          (rocketClick)="buttonClicked('B', '_put')"
        ></rocket-button>
        <rocket-button
          css="bg-multibroker-sell very-small"
          label="Vender"
          [isSmall]="true"
          (rocketClick)="buttonClicked('S', '_put')"
        ></rocket-button>
      </span>
    </div>
  </ng-container>`,
  styles: [
    `
      :host ::ng-deep button {
        min-width: 30px;
        margin-top: 1px !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionButtonsCellRendererComponent
  implements ICellRendererAngularComp
{
  cellValue!: TOptions | TCallPutOptions;
  isCallPut = false;
  hideButtons = false;

  constructor(private _optionsComponentService: OptionsComponentService) {}

  agInit(params: ICellRendererParams): void {
    const { isCurrentPrice, cd_stock_call, cd_stock_put } = params.data;
    this.cellValue = params.data;
    this.isCallPut = cd_stock_call;
    this.hideButtons = isCurrentPrice && !cd_stock_call && !cd_stock_put;
  }

  refresh(params: ICellRendererParams): boolean {
    this.cellValue = params.data;
    return true;
  }

  buttonClicked(side: string, type: '_call' | '_put') {
    if ((this.cellValue as TCallPutOptions).cd_stock_call) {
      const values = this.cellValue as TCallPutOptions;
      if (!values[`cd_stock${type}`]) return;

      this._optionsComponentService.setStrategyOption({
        cd_stock: values[`cd_stock${type}`],
        option_type: type == '_call' ? 'C' : 'P',
        strike_price: values.strike_price,
        price: values[`vl_close${type}`],
        option_days_to_expiration: values[`option_days_to_expiration${type}`],
        side,
      });
      return;
    }
    const values = this.cellValue as TOptions;
    this._optionsComponentService.setStrategyOption({
      cd_stock: values.cd_stock,
      option_type: values.in_option,
      strike_price: values.strike_price,
      price: values.vl_close,
      option_days_to_expiration: values.option_days_to_expiration,
      side,
    });
  }

  rowSelected(type: string) {
    this._optionsComponentService.onSelectOption$.next({
      type,
      option: this.cellValue as TCallPutOptions,
    });
  }
}
