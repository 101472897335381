<div class="w-100 d-flex pt-1 position-relative">
  <a
    *ngFor="let tab of tabs; let i = index"
    class="nav-link p-2 lh-sm round-top fw-bold"
    [ngClass]="{
      'text-brand-primary': tab === selectedTab,
      'pe-none opacity-semi-opaque': disableChangeStock
    }"
    type="button"
    role="tab"
    [attr.aria-selected]="tab === selectedTab"
    (click)="onChangeType(tab)"
  >
    {{ tab }}
  </a>
  <div
    class="h-100 d-flex align-items-center position-absolute right-0 pr-3 gap-3"
  >
    <span class="fs-6 fw-bold">{{ stockInfoCard.price }}</span>
    <app-tag
      class="d-d-flex mb-1 col justify-center"
      [useSmallTag]="true"
      [variation]="stockInfoCard.variacao_dia"
      [isVariation]="true"
      [bgColor]="stockInfoCard.arrow_hex"
      [color]="stockInfoCard.arrow_font_hex"
      titleSize="fs-7"
    >
    </app-tag>
  </div>
</div>
