import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RTTextLengthService {
  private canvas: HTMLCanvasElement = document.createElement('canvas');

  private calculate(text: string, fontSize: string) {
    const font = `400 ${fontSize} Open Sans`;
    const context = this.canvas.getContext('2d');
    if (!context) return 0;
    context.font = font;
    const metrics = context.measureText(text);
    return Math.ceil(metrics.actualBoundingBoxRight);
  }

  getBoxLineTextLength(text: string, codeIcon?: string, fontSize: string = '11px'): number {
    if (!text.trim() && codeIcon) return 4;
    let textLength = this.calculate(text, fontSize);
    textLength = codeIcon ? textLength + 9 : textLength;
    return textLength;
  }
}
