import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';
import { OptionsComponents } from '../component-list';

export const OPTIONS_COMPONENT: any = (options?: OptionsComponents) => ({
  name: 'Opções',
  id: COMPONENTS_NAMES_ENUM.OPTIONS,
  icon: 'show_chart',
  cod: 'OPTIONS',
  data: null,
  close: true,
  fullscreen: true,
  link: false,
  widthDesk: 935,
  heightDesk: 500,
  unitOfMeasurement: options?.unit ?? 'px',
  height: options?.height ?? 500,
  width: options?.width ?? 985,
  minWidth: '1005px',
  minHeight: '443px',
  left: options?.left ?? 'calc(15% + 5px)',
  top: options?.top ?? '0px',
  label: 'Opções',
  noPublished: false,
  borderClass: '',
  iconSvg: 'STACKED_LINE_CHART',
});
