import { Dictionary } from '@core/models';
import {
  IBusinessProfileResume,
  IBusinessProfileTab,
  IFields,
  IIndicatorCompanyInfo,
  IPeriods,
  IYields,
} from './business-profile.interface';
import { PERIODS_ENUM } from './business-profile.enum';
import { formatDate, getVolumeText } from 'src/app/utils/utils.functions';

export const TABS: IBusinessProfileTab[] = [
  { title: 'Resumo', active: true, cod: 'RESUME' },
  { title: 'Pares', active: false, cod: 'PAIRS' },
  { title: 'Proventos', active: false, cod: 'PROVENTS' },
  { title: 'Indicadores', active: false, cod: 'INDICATORS' },
  { title: 'Fundos', active: false, cod: 'FUNDS' },
  { title: 'Aluguel', active: false, cod: 'ALUGUEL' },
  { title: 'Recompra', active: false, cod: 'REPURCHASE' },
  { title: 'Consenso', active: false, cod: 'CONSENSUS' },
  { title: 'Sobre', active: false, cod: 'ABOUT' },
];

export const TABS_INDEX: IBusinessProfileTab[] = [
  { title: 'Resumo', active: true, cod: 'RESUME' },
  { title: 'Sobre', active: false, cod: 'ABOUT_INDEX' },
];

export const ABOUT_TABS: IBusinessProfileTab[] = [
  { title: 'Detalhes', active: true, cod: 'ATIVO' },
  { title: 'Índices', active: false, cod: 'INDICES' },
  { title: 'Executivos', active: false, cod: 'EXECUTIVOS' },
  { title: 'Conselho', active: false, cod: 'CONSELHO' },
  { title: 'Acionistas', active: false, cod: 'ACIONISTAS' },
  { title: 'Subsidiária', active: false, cod: 'SUBSIDIARIA' },
];

export const RESUMES: IBusinessProfileResume[] = [
  {
    cod: 'vl_open',
    tooltip: 'vl_open_help',
    title: 'Abertura',
    value: 0,
    suffix: '',
  },
  {
    cod: 'price_previous_closing',
    tooltip: 'price_previous_closing_help',
    title: 'Anterior',
    value: 0,
    suffix: '',
  },
  {
    cod: 'formattedVolume',
    tooltip: 'formattedVolume_help',
    title: 'Volume',
    value: 0,
    suffix: '',
  },
  {
    cod: 'vl_volatility_one_year',
    tooltip: 'vl_volatility_one_year_help',
    title: 'Volatilidade 12m',
    value: 0,
    suffix: '',
  },
  { cod: '', tooltip: '', title: '', value: 0, suffix: '' },
  {
    cod: 'marketcap',
    tooltip: 'marketcap_help',
    title: 'MCAP',
    value: 0,
    suffix: '',
  },
  {
    cod: 'dividend_yield',
    tooltip: 'dividend_yield_help',
    title: 'D. Yield (12m)',
    value: 0,
    suffix: '%',
  },
  {
    cod: 'price_per_profit',
    tooltip: 'price_per_profit_help',
    title: 'P/L (12m)',
    value: 0,
    suffix: '',
  },
  {
    cod: 'profit_per_stock_annual',
    tooltip: 'profit_per_stock_annual_help',
    title: 'LPA (12m)',
    value: 0,
    suffix: '',
  },
  {
    cod: 'dividend_per_stock_annual',
    tooltip: 'dividend_per_stock_annual_help',
    title: 'DPA (12m)',
    value: 0,
    suffix: '',
  },
];

const periods: any = {
  ONE_DAY: { title: '1D' },
  ONE_WEEK: { title: '1S' },
  ONE_MONTH: { title: '1M' },
  THREE_MONTHS: { title: '3M' },
  SIX_MONTHS: { title: '6M' },
  TWELVE_MONTHS: { title: '12M' },
  ONE_YEAR: { title: '1A' },
  TWO_YEARS: { title: '2A' },
  FIVE_YEARS: { title: '5A' },
  TEN_YEARS: { title: '10A' },
};

export const PERIODS = new Dictionary<IPeriods>();
export const PERIODS_PROVENTS_EVOLUTION = new Dictionary<IPeriods>();
Object.keys(PERIODS_ENUM).forEach((period) => {
  const periodAny = PERIODS_ENUM as any;
  if (
    periodAny[period] !== PERIODS_ENUM.ONE_DAY &&
    periodAny[period] !== PERIODS_ENUM.ONE_WEEK
  ) {
    if (
      periodAny[period] !== PERIODS_ENUM.ONE_MONTH &&
      periodAny[period] !== PERIODS_ENUM.ONE_YEAR &&
      periodAny[period] !== PERIODS_ENUM.THREE_MONTHS &&
      periodAny[period] !== PERIODS_ENUM.SIX_MONTHS
    ) {
      PERIODS_PROVENTS_EVOLUTION.set(period, {
        cod: periodAny[period],
        ...periods[period],
      });
    }
    if (
      periodAny[period] !== PERIODS_ENUM.TWELVE_MONTHS &&
      periodAny[period] !== PERIODS_ENUM.TEN_YEARS
    ) {
      PERIODS.set(period, {
        cod: periodAny[period],
        ...periods[period],
      });
    }
  }
});

export const UNITS = [
  { title: 'Total', cod: 'TOTAL', active: true },
  { title: 'Unitário', cod: 'UNIT', active: false },
];

export const GROUPS = [
  { title: 'Anual', cod: 'ANUAL', active: true },
  { title: 'Diário', cod: 'DIARIO', active: false },
];

export const CARDS_YIELDS = new Dictionary<IYields>();
CARDS_YIELDS.set('YIELD_12M', {
  label: 'D. Yield 12m',
  tooltip:
    '<b>D. Yield 12m</b></br>Razão entre os dividendos pagos por ação nos últimos 12 meses e o preço de fechamento da ação em D-1. Cálculo convencional de Dividend Yield.',
  value_yield: '',
  value: '',
  tooltipAux: 'Dividendos pagos por ação nos últimos 12 meses',
});
CARDS_YIELDS.set('YIELD_24M', {
  label: 'D. Yield 24m',
  tooltip:
    '<b>D. Yield 24m</b></br>Razão entre os dividendos pagos por ação nos últimos 24 meses e o preço de fechamento da ação em D-1.',
  value: '',
  tooltipAux: 'Dividendos pagos por ação nos últimos 24 meses',
});
CARDS_YIELDS.set('YIELD_36M', {
  label: 'D. Yield 36m',
  tooltip:
    '<b>D. Yield 36m</b></br>Razão entre os dividendos pagos por ação nos últimos 36 meses e o preço de fechamento da ação em D-1.',
  value: '',
  tooltipAux: 'Dividendos pagos por ação nos últimos 36 meses',
});
CARDS_YIELDS.set('V_12M', {
  label: 'Var. 12m',
  tooltip:
    '<b>Variação 12m</b></br>Variação do Dividend Yield da empresa em um período de 1 ano. Ou seja, se o valor for positivo a empresa está pagando mais dividendos por ação que havia pago há 1 ano atrás, considerando uma janela de pagamento de 12 meses.',
});
CARDS_YIELDS.set('MM_24M', {
  label: 'Média M. 24m',
  tooltip:
    '<b>Média móvel 24m</b></br>Média dos Dividend Yields mensais da empresa considerando os últimos 24 meses. Indica se a empresa tem consistência de pagamento de dividendos no curto prazo (período mensal).',
});

export const CARDS_FII = new Dictionary<IYields>();
CARDS_FII.set('U_R', {
  label: 'Último Rendimento',
  tooltip:
    '<b>Último Rendimento</b></br>Último dividendo pago por ação e a razão em relação o preço de fechamento da ação em D-1.',
  dt_event: '',
  value: '',
  tooltipAux: 'Último dividendo pago por ação',
});
CARDS_FII.set('3_M', {
  label: '3 Meses',
  tooltip:
    '<b>3 Meses</b></br>Dividendos pagos por ação nos últimos 3 meses e a razão em relação o preço de fechamento da ação em D-1.',
  value: '',
  tooltipAux: 'Dividendos pagos por ação nos últimos 3 meses',
});
CARDS_FII.set('6_M', {
  label: '6 Meses',
  tooltip:
    '<b>6 Meses</b></br>Dividendos pagos por ação nos últimos 6 meses e a razão em relação o preço de fechamento da ação em D-1.',
  value: '',
  tooltipAux: 'Dividendos pagos por ação nos últimos 6 meses',
});
CARDS_FII.set('12_M', {
  label: '12 Meses',
  tooltip:
    '<b>12 Meses</b></br>Dividendos pagos por ação nos últimos 12 meses e a razão em relação o preço de fechamento da ação em D-1.',
  value: '',
  tooltipAux: 'Dividendos pagos por ação nos últimos 12 meses',
});
CARDS_FII.set('24_M', {
  label: '24 Meses',
  tooltip:
    '<b>24 Meses</b></br>Dividendos pagos por ação nos últimos 24 meses e a razão em relação o preço de fechamento da ação em D-1.',
  value: '',
  tooltipAux: 'Dividendos pagos por ação nos últimos 24 meses',
});

export const CONFIGURATION_SECTORS: any = {
  sector: {
    title: 'ds_position_sector',
    id: 'id_company_position_sector',
    method: 'getSectorStocksInfo',
  },
  subsector: {
    title: 'ds_position_subsector',
    id: 'id_company_position_subsector',
    method: 'getSubSectorStocksInfo',
  },
  segment: {
    title: 'ds_position_segment',
    id: 'id_company_position_segment',
    method: 'getSegmentStocksInfo',
  },
};

export const VISUALIZATION_IOSMA = [
  {
    label: 'Variação',
    id: 'custom_variation',
    icon: '%',
    field: 'custom_variation',
  },
  {
    label: 'Valor',
    id: 'preco_ultimo_formatted',
    icon: '$',
    field: 'preco_ultimo_formatted',
  },
  {
    label: 'Volume',
    id: 'volume_formatted',
    icon: 'V',
    field: 'volume_formatted',
  },
];

export const StockTypes: any = {
  BR: { VIS: true, FRC: true },
  US: { US: true },
  FII: { FII: true },
};

export const INDICATOR_COMPANY_INFO: IIndicatorCompanyInfo[] = [
  { title: 'P/L', field: 'price_profit_annual', value: '' },
  { title: 'D.Yield', field: 'vl_dividend_yield_annual', value: '' },
  { title: 'LL3A', field: 'evo_profit_three', value: '' },
  { title: 'M.Liq', field: 'net_margin_annual', value: '' },
  { title: 'ROE', field: 'roe_annual', value: '' },
  { title: 'DL', field: 'net_debt_ebitda_annual', value: '' },
];

export const INDICATOR_COMPANY_INFO_DICT =
  new Dictionary<IIndicatorCompanyInfo>();

INDICATOR_COMPANY_INFO_DICT.bulkData('field', INDICATOR_COMPANY_INFO);

export const EXECUTIVES_BOARD_CARDS = [
  {
    tooltip: 'remuneracao_total_tooltip',
    prevField: 'remuneracao_total',
    title: 'Rem. Total',
    field: 'friendlyRemuneracaoTotal',
    formatter: (locale: string, value: any) =>
      getVolumeText(locale, value, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
  },
  {
    tooltip: 'salarios_tooltip',
    prevField: 'salarios',
    title: 'Salários',
    field: 'friendlySalarios',
    formatter: (locale: string, value: any) =>
      getVolumeText(locale, value, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
  },
  {
    tooltip: 'beneficios_tooltip',
    prevField: 'beneficios',
    title: 'Benefícios',
    field: 'friendlyBeneficios',
    formatter: (locale: string, value: any) =>
      getVolumeText(locale, value, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
  },
  {
    tooltip: 'beneficios_acoes_tooltip',
    prevField: 'beneficios_acoes',
    title: 'Benef. em Ações',
    field: 'friendlyBeneficiosAcoes',
    formatter: (locale: string, value: any) =>
      getVolumeText(locale, value, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
  },
  {
    tooltip: 'bonus_and_others_tooltip',
    prevField: 'bonus_and_others',
    title: 'Bônus e Outros',
    field: 'friendlyBonusAndOthers',
    formatter: (locale: string, value: any) =>
      getVolumeText(locale, value, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
  },
  {
    tooltip: '',
    prevField: 'data_referencia',
    title: 'Data Ref.',
    field: 'dataReferencia',
    formatter: (locale: string, value: any) => formatDate(value, true),
  },
];

export const FIELDS_MODAL_EXECUTIVES_BOARD: IFields[] = [
  {
    title: 'Cargo',
    field: 'cargo',
  },
  {
    title: 'Salário',
    field: 'salarioFormatted',
    preffix: 'R$',
  },
  {
    title: 'Data de eleição',
    field: 'dt_electionFormatted',
  },
  {
    title: 'Data fim',
    field: 'term_of_office',
  },
  {
    title: 'Profissão',
    field: 'function_description',
  },
  {
    title: 'CPF',
    field: 'cpf_numberFormatted',
  },
  {
    title: 'Outros cargos',
    field: 'outros_cargos',
  },
  {
    field: 'ds_cv',
  },
];

export const FIELDS_MODAL_SUBSIDIARIES: IFields[] = [
  { title: 'Nome', field: 'socialDenomination' },
  { title: 'Valor Contábil', field: 'vlContabil' },
  { title: 'Participação', field: 'pc' },
  { title: 'Dividendos', field: 'vlDivid' },
  { title: 'CNPJ', field: 'cnpjFormatted' },
  { title: 'Origem', field: 'subsidiaryOrigin' },
  { title: 'Razão da Compra', field: 'acquisitionReason' },
  { title: 'Descrição das Atividades', field: 'developedActivities' },
];

export const CONSENSUS_RECOMMENDATION = {
  Manter: { class: 'manter' },
  Comprar: { hasCallback: true, class: 'comprar' },
  Vender: { hasCallback: true, class: 'vender' },
  Analisar: { class: 'analisar' },
  Default: { class: 'analisar' },
};

export const CONSENSUS_TARGET_PRICE = new Dictionary<{
  field: string;
  text: string;
}>();

CONSENSUS_TARGET_PRICE.set('high', {
  field: 'vl_high',
  text: 'Máximo',
});
CONSENSUS_TARGET_PRICE.set('median', {
  field: 'vl_median',
  text: 'Mediana',
});
CONSENSUS_TARGET_PRICE.set('mean', {
  field: 'vl_mean',
  text: 'Médio',
});
CONSENSUS_TARGET_PRICE.set('low', {
  field: 'vl_low',
  text: 'Mínimo',
});
