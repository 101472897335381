import { ORDER_ALL_STATUS_DIC } from '@shared/dictionary/order.dictionary';
import { IOrders } from '../interface/order.interface';
import {
  DEFAULT_TIMEENFORCE_ORDERTYPE,
  ORDER_AUCTION_TYPE,
} from '@shared/constants/general.contant';

export class OrderModel {
  public id_order!: number;
  public account!: string;
  public ds_account_type!: string;
  public id_investor!: number;
  public id_advisor!: number;
  public id_broker!: number;
  public id_exchange!: number;
  public cd_order_type!: string;
  public side!: string;
  public id_stock!: number;
  public qtty!: number;
  public qtty_executed!: number;
  public qtty_left!: number;
  public max_floor!: number;
  public price!: number;
  public cd_order_time_enforce!: string;
  public dh_creation_time!: string;
  public dt_creation!: string;
  public dh_last_update!: string;
  public dh_last_update_formatted!: string;
  public dt_expirate_date!: string;
  public id_order_fix_server!: string;
  public id_order_exchange!: string;
  public cd_order_status!: string;
  public cd_stock!: string;
  public ds_order_status!: string;
  public nm_broker!: string;
  public nm_broker_mnemonic!: string;
  public ds_exchange!: string;
  public ds_order_time_enforce!: string;
  public ds_order_type!: string;
  public price_average!: number;
  public cd_order_reject_reason!: number;
  public standard_lot!: number;
  public vlMinPriceIncrement!: number;
  public assessor!: string;
  public cd_user_assessor!: string;
  public last_price!: number;
  public tick_size_denominator!: number;
  public personalized_stop_type!: string;
  public personalized_gain_trigger!: string;
  public personalized_gain_price!: string;
  public personalized_loss_trigger!: string;
  public personalized_loss_price!: string;
  public personalized_stop_triggered!: boolean;
  public personalized_stop_triggered_side!: string;
  public personalized_new_order_scheduled!: boolean;
  public personalized_cancel_replace_scheduled!: boolean;
  public ds_order_reject_reason!: string;
  public ds_text!: string;
  public vl_total!: number;
  public cd_segment!: string;
  public quotation_form!: number;
  public precise_last_update!: string;
  public order_qty_canc!: number;
  public is_multi_broker!: boolean;
  public can_edit!: boolean;
  public nm_instrument!: string;
  public tp_status!: string;
  public is_synonymous!: boolean;
  public cd_stock_order!: string;
  public synonymous_nickname!: string;
  public nm_broker_nickname!: string;
  public qtty_executada!: number;
  public price_stop!: string | number;
  public has_day_trade?: boolean;
  public has_swing?: boolean;
  public is_reset_position?: boolean;
  public is_day_trade?: boolean;
  public dh_creation_time_filter?: string;
  public stop_triggered?: string | null;

  constructor(order: IOrders | any, invertStopBroker: boolean) {
    this.cd_stock = this.setSynonymousNickname(order);
    this.cd_stock_order = order.cd_stock ?? order.cd_papel;
    this.cd_order_status = order.cd_order_status ?? order.status_ordem;
    this.id_order = +order.id_order || +order.id_ordem;
    this.account = order.account ?? order.id_conta;
    this.ds_account_type = order.ds_account_type ?? null;
    this.id_investor = order.id_investor ?? order.id_investidor;
    this.id_advisor = order.id_advisor ?? null;
    this.id_broker = +order.id_broker || +order.id_corretora;
    this.id_exchange = order.id_exchange ?? order.id_bolsa;
    this.cd_order_type = order.cd_order_type ?? order.tipo_ordem;
    this.ds_order_type = this.dsOrderType(
      order.cd_order_type ?? order.tipo_ordem,
      order.cd_order_time_enforce ?? order.time_enforce
    );
    this.side = order.side ?? null;
    this.id_stock = order.id_stock ?? order.id_papel;
    this.qtty = order.qtty ?? null;
    this.qtty_executed = order.qtty_executed ?? order.qtty_executada;
    this.qtty_left = +order.qtty_left || +order.qtty_restante;
    this.max_floor = order.max_floor ?? null;
    this.price = order.price ?? order.preco;
    this.cd_order_time_enforce =
      order.cd_order_time_enforce ?? order.time_enforce;
    this.dh_creation_time =
      order.dh_creation_time ?? this.formattingDate(order.dh_criacao);
    this.dt_creation = order.dh_creation_time ?? order.dh_criacao;
    this.dh_last_update =
      order.dh_last_update ?? this.formattingDate(order.dh_ultima_atualizacao);
    this.dh_last_update_formatted =
      order.dh_last_update_formatted ?? order.dh_ultima_atualizacao;
    this.dt_expirate_date = order.dt_expirate_date ?? order.data_expiracao;
    this.id_order_fix_server = order.id_order_fix_server ?? order.seq_valemobi;
    this.id_order_exchange = order.id_order_exchange ?? null;
    this.ds_order_status =
      order.ds_order_status ?? ORDER_ALL_STATUS_DIC.get(order.status_ordem);
    this.nm_broker = order.nm_broker ?? null;
    this.nm_broker_nickname = order.nm_broker_nickname ?? order.nm_broker;
    this.nm_broker_mnemonic = order.nm_broker_mnemonic ?? null;
    this.ds_exchange = order.ds_exchange ?? order.mercado;
    this.ds_order_time_enforce =
      order.ds_order_time_enforce ?? order.time_enforce;
    this.price_average = order.price_average ?? order.preco_medio_exec;
    this.cd_order_reject_reason =
      order.cd_order_reject_reason ?? order.cd_rejeicao;
    this.assessor = order.assessor ?? order.id_advisor;
    this.cd_user_assessor = order.cd_user_assessor ?? order.nm_advisor;
    this.last_price = order.last_price ?? null;
    this.tick_size_denominator = order.tick_size_denominator ?? null;
    this.personalized_stop_type = order.personalized_stop_type ?? null;
    /**
     * na regra da corretora caso seja uma ordem de compra inverte o gain e loss.
     */
    this.personalized_gain_trigger =
      (invertStopBroker
        ? order.personalized_loss_trigger
        : order.personalized_gain_trigger) ?? null;
    this.personalized_gain_price =
      (invertStopBroker
        ? order.personalized_loss_price
        : order.personalized_gain_price) ?? null;
    this.personalized_loss_trigger =
      (invertStopBroker
        ? order.personalized_gain_trigger
        : order.personalized_loss_trigger) ?? null;
    this.personalized_loss_price =
      (invertStopBroker
        ? order.personalized_gain_price
        : order.personalized_loss_price) ?? null;
    this.personalized_stop_triggered =
      order.personalized_stop_triggered ?? null;
    this.personalized_stop_triggered_side =
      order.personalized_stop_triggered_side ?? null;
    this.personalized_new_order_scheduled =
      order.personalized_new_order_scheduled ?? null;
    this.personalized_cancel_replace_scheduled =
      order.personalized_cancel_replace_scheduled ?? null;
    this.ds_order_reject_reason =
      order.ds_order_reject_reason ?? order.ds_order_reject_reason;
    this.ds_text = order.ds_text ?? null;
    this.vl_total = order.vl_total ?? null;
    this.cd_segment = order.cd_segment ?? null;
    this.quotation_form = order.quotation_form ?? null;
    this.precise_last_update = order.precise_last_update ?? null;
    this.order_qty_canc = order.order_qty_canc ?? null;
    this.is_multi_broker = order.is_multi_broker ?? null;
    this.can_edit = order.can_edit ?? null;
    this.nm_instrument = order.nm_instrument ?? null;
    this.tp_status = order.tp_status ?? null;
    this.qtty_executada = order.qtty_executed;
    this.price_stop = order.price_stop || order.preco_stop;
    this.has_day_trade = order.has_day_trade || false;
    this.has_swing = order.has_swing || false;
    this.is_reset_position = order.is_reset_position || false;
    this.standard_lot = order.standard_lot ?? null;
    this.is_day_trade =
      typeof order.is_day_trade === 'string'
        ? order.is_day_trade.toLowerCase() === 'true'
        : order.is_day_trade;
    this.vlMinPriceIncrement = order.min_price_increment ?? null;
    this.dh_creation_time_filter = `${this.dh_creation_time.slice(0, 8)}000000`;
    this.stop_triggered = order.stop_triggered || null;
  }

  private setSynonymousNickname(order: IOrders | any): string {
    if (order.is_synonymous) {
      return order.synonymous_nickname;
    }
    return order.cd_stock ?? order.cd_papel;
  }

  private formattingDate(date: string): string | null {
    return date ? date.split('.').shift()!.replace(/\D/g, '') : null;
  }

  private dsOrderType(orderType: string, timeEnforce: string): string {
    const type = `${timeEnforce}${orderType}`.toUpperCase();
    const orderTypeObj: any = {
      ...DEFAULT_TIMEENFORCE_ORDERTYPE,
      ...ORDER_AUCTION_TYPE,
    };
    return orderTypeObj[type] ?? '-';
  }
}

export const enum TPOrderStatus {
  FECHADA = 'FECHADA',
  ABERTA = 'ABERTA',
  EXECUTADA = 'EXECUTADA',
}
