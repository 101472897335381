<div
  class="position-absolute left-0 cursor-pointer icon-redir-position"
  (click)="redirToLogin()"
>
  <fla-icon
    name="chevron_left"
    css="cursor-pointer text-brand-primary"
    size="icon-size-md"
  >
  </fla-icon>
</div>

<ng-container *ngIf="!isUserRegistered; else userRegistered">
  <form [formGroup]="createForm" class="overflow-y-hidden row">
    <h3 class="mb-5">Criar uma conta</h3>
    <div class="w-100">
      <fla-input
        fla-focus
        formControlName="name"
        label="Nome Completo"
        hasError="invalid"
        type="text"
        margin="mb-3"
        [requiredText]="errorMessages.name"
        [isRequired]="true"
        [maxLength]="100"
        [max]="100"
        [autoComplete]="false"
      >
      </fla-input>
    </div>

    <div class="w-100 d-inline-flex gap-2">
      <fla-input
        formControlName="email"
        label="E-mail"
        hasError="invalid"
        type="text"
        margin="mb-3"
        [requiredText]="errorMessages.email"
        [isRequired]="true"
        [maxLength]="100"
        [max]="100"
        [autoComplete]="false"
        css="w-100"
      >
      </fla-input>

      <fla-input
        formControlName="document"
        label="CPF"
        hasError="invalid"
        type="text"
        margin="mb-3"
        [flaMask]="documentMask"
        [maxLength]="documentMaxLength"
        [requiredText]="errorMessages.document"
        [isRequired]="true"
        css="w-100"
      >
      </fla-input>
    </div>

    <div class="w-100 d-inline-flex gap-2 position-relative">
      <fla-input
        formControlName="vl_birthdate"
        label="Data Nascimento"
        hasError="invalid"
        type="date"
        margin="mb-3"
        [requiredText]="errorMessages.vl_birthdate"
        [isRequired]="true"
        [min]="minDate"
        [max]="maxDate"
        css="w-100"
        decidePosition="suffix"
      >
        <div class="suffix" style="z-index: 2; padding-right: 0">
          <rocket-button type="icon" (click)="datePicker()">
            <fla-icon
              name="calendar_today"
              size="icon-size-micro"
              css="text-light"
            ></fla-icon>
          </rocket-button>

          <app-datepicker
            [initialDate]="selectedDate"
            [useTimepicker]="false"
            [closeOnClick]="false"
            [isAbsolute]="true"
            [withWeekend]="true"
            [showDatePicker]="showDatePicker"
            positionAbsolute="RIGHT-CENTER"
            (flaClick)="datePickerDate($event)"
            (closeDatePicker)="closeDatePicker()"
          ></app-datepicker>
        </div>
      </fla-input>
    </div>

    <div class="w-100 d-inline-flex gap-2">
      <div class="col">
        <fla-input
          formControlName="password"
          label="Senha"
          hasError="invalid"
          type="password"
          margin="mb-3"
          inputPassword
          [requiredText]="errorMessages.password"
          [isRequired]="true"
          [autoComplete]="false"
        >
        </fla-input>
      </div>
      <div class="col">
        <fla-input
          formControlName="confirm_password"
          label="Confirme a Senha"
          hasError="invalid"
          type="password"
          margin="mb-3"
          [requiredText]="errorMessages.confirm_password"
          inputPassword
          [isRequired]="true"
        >
        </fla-input>
      </div>
    </div>

    <div class="w-100 d-flex flex-column gap-2">
      <password-validation
        *ngIf="password?.dirty || password?.touched"
        [inputState]="password.errors"
      ></password-validation>
    </div>

    <error-card
      *ngIf="onboardError.hasError"
      cardMargin="mb-4"
      cardBorder="border-feedback-error"
      [message]="onboardError.message"
    ></error-card>

    <rocket-button
      label="Próximo"
      type="default"
      btnType="submit"
      css="w-100 mb-3"
      [loading]="isLoading"
      [isDisabled]="!isFormValid || isUserRegistered || disableSubmitButton"
      (rocketClick)="submitForm()"
    >
    </rocket-button>
  </form>
</ng-container>

<ng-template #userRegistered>
  <user-registered (redirRegisteredUser)="redirToLogin()"></user-registered>
</ng-template>
