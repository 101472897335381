import { Injectable } from '@angular/core';
import {
  IBalanceSheet,
  IBalanceSheetChildrens,
  IConsolidatedData,
} from '@shared/components/business-profile/indicators/interface/indicators.interface';
import { ExportDataIdEnum } from '@shared/components/export-data/export-data.enum';
import { HEADERS_BIG_NUMBER_FORMAT } from '@shared/components/stock-table/constants/headers.constants';
import { STOCK_STATUS_LABEL } from '@shared/components/stock-table/constants/stock-table.constants';
import { formatterNumber } from '@shared/rocket-components/utils';
import {
  Column,
  ColumnApi,
  GridApi,
  ProcessCellForExportParams,
  ProcessHeaderForExportParams,
  ValueFormatterFunc,
  ValueFormatterParams,
} from 'ag-grid-community';
import { ToastService } from './toast.service';
import { Papa } from 'ngx-papaparse';
import { getOrderRowToExport } from '@core/components/orders-history-core/grid/columns';
import { dateOrTimeFormatter } from 'src/app/utils/cell-format.functions';
import { ALERT_STATUS_ENUM } from '@core/interface';
@Injectable({ providedIn: 'root' })
export class ExportDataConverterService {
  public exporterFunctions: { [key: string]: (...data: any) => any };
  constructor(private toastService: ToastService, private papa: Papa) {
    this.exporterFunctions = {
      [ExportDataIdEnum.INDICADORES]: this.getIndicatorsDataToExport,
      [ExportDataIdEnum.BALANCO_PATRIMONIAL]: this.getBalanceSheetDataToExport,
      [ExportDataIdEnum.DRE]: this.getBalanceSheetDataToExport,
      [ExportDataIdEnum.FLUXO_CAIXA]: this.getBalanceSheetDataToExport,
      [ExportDataIdEnum.LISTA_ATIVOS]: this.getStockListDataToExport,
      [ExportDataIdEnum.ORDENS_POSICOES]: this.getOrdersDataToExport,
      [ExportDataIdEnum.POSICOES]: this.getOrdersPositionsDataToExport,
      [ExportDataIdEnum.ALERTAS]: this.getAlertsDataToExport,
    };
  }

  public exportData = (
    id: string,
    data: any,
    suffix?: string,
    parseToCsv: boolean = true
  ) => {
    let convertedData;
    if (parseToCsv) {
      convertedData = this.papa.unparse(data, {
        header: true,
        delimiter: ';',
      });
    } else convertedData = data;
    const fileName = id.toLowerCase() + (suffix ? `-${suffix}` : '');
    this.fileDataToBlob(convertedData, fileName);
  };

  private fileDataToBlob = (csvData: string, filename: string) => {
    const blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser =
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
    this.toastService.showToast(
      'success',
      'Exportação dos dados iniciada com sucesso. Verifique sua pasta downloads do computador.'
    );
  };

  getIndicatorsDataToExport = (response: {
    data: IConsolidatedData;
    companyType: string;
    period: string;
  }) => {
    const { data, companyType, period } = response;
    const header: any[] = [`Data (${companyType})`];
    const monthGroup: any[] = [`Agrupamento (${period})`];
    const rows: any[][] = [];
    data.headers.forEach((item) => {
      header.push(item.quarter);
      monthGroup.push(item.month);
    });

    rows.push(monthGroup);

    data.rows.forEach((item) => {
      rows.push([item.description]);
      item.indicators.forEach((ind) => {
        const row: any[] = [ind.namePt];
        ind.values.forEach((col) => {
          if (col.value) {
            row.push(formatterNumber(col.value));
          } else {
            row.push('-');
          }
        });
        rows.push(row);
      });
    });

    return {
      fields: header,
      data: rows,
    };
  };

  getBalanceSheetDataToExport = (response: {
    data: IBalanceSheet;
    companyType: string;
  }) => {
    const { data, companyType } = response;
    const header: any[] = [`Data (${companyType})`];
    const rows: any[][] = [];
    data.headers.forEach((item) => {
      header.push(item.quarter);
    });

    function setRowData(dataRows: IBalanceSheetChildrens[]) {
      dataRows.forEach((item) => {
        const row = [item.description];
        if (item.values.length) {
          item.values.forEach((col) => {
            if (col.value) {
              row.push(formatterNumber(col.value));
            } else {
              row.push('-');
            }
          });
        }

        rows.push(row);

        if (item.childrens.length) {
          setRowData(item.childrens);
        }
      });
    }

    setRowData(data.rows);

    return {
      fields: header,
      data: rows,
    };
  };

  getStockListDataToExport = (gridApi: GridApi) => {
    return gridApi.getDataAsCsv({
      columnSeparator: ';',
      suppressQuotes: true,
      processHeaderCallback: (params: ProcessHeaderForExportParams) => {
        const colDef = params.column.getUserProvidedColDef();
        if (colDef?.field === 'situacao') {
          return 'Status';
        }

        return colDef?.headerName || '';
      },
      processCellCallback: (params: ProcessCellForExportParams) => {
        if (params.column.getUserProvidedColDef()) {
          const colDef = params.column.getUserProvidedColDef();
          const field = colDef?.field;
          const formatter: ValueFormatterFunc<any> =
            colDef?.valueFormatter as ValueFormatterFunc;
          const formatterParams = {
            data: params.node?.data,
            value: params.value,
          };
          const paramsValue = Array.isArray(params.value)
            ? params.value[0]
            : params.value;
          switch (field) {
            case 'situacao':
              return STOCK_STATUS_LABEL[paramsValue];
            case 'cd_stock':
              return paramsValue;
            case 'variacao':
              return formatterNumber(parseFloat(paramsValue)) + ' %';
            default:
              if (field && HEADERS_BIG_NUMBER_FORMAT.includes(field)) {
                return paramsValue
                  ? formatterNumber(parseFloat(paramsValue))
                  : '';
              }
              if (formatter) {
                return formatter(formatterParams as ValueFormatterParams);
              }
              return paramsValue;
          }
        }
        return params.value;
      },
    });
  };

  getOrdersDataToExport(orders: any[], gridApi: ColumnApi) {
    const fields: string[] = [];
    const rows: any[][] = [];

    gridApi.getAllDisplayedColumns().forEach((col: Column) => {
      col.getColDef().headerName && fields.push(col.getColDef().headerName!);
    });
    orders.forEach((order) => {
      const row = getOrderRowToExport(order);
      rows.push(row);
    });

    fields.splice(2, 0, 'Corretora');

    return {
      fields,
      data: rows,
    };
  }

  getOrdersPositionsDataToExport(gridApi: GridApi) {
    return gridApi.getDataAsCsv({
      columnSeparator: ';',
      suppressQuotes: true,
      processCellCallback: (param: ProcessCellForExportParams) => {
        const valueData = {
          data: param.node?.data,
          value: param.value,
        } as ValueFormatterParams;
        switch (param.column.getColId()) {
          case 'remove_custody':
            return '';
          case 'cd_stock':
            return param.value;
          default:
            return (
              param.column.getUserProvidedColDef()
                ?.valueFormatter as ValueFormatterFunc
            )(valueData);
        }
      },
    });
  }

  getAlertsDataToExport(gridApi: GridApi) {
    const columns = gridApi
      .getColumnDefs()
      ?.map((col: any) => col.colId)
      .filter((col: string | undefined) => {
        return col && col != undefined && col != 'edit' && col != 'delete';
      });
    return gridApi.getDataAsCsv({
      columnSeparator: ';',
      suppressQuotes: true,
      columnKeys: columns ?? [],
      processCellCallback: (param: ProcessCellForExportParams) => {
        switch (param.accumulatedRowIndex) {
          case 0:
            return dateOrTimeFormatter(param.value);
          case 1:
            return param.value;
          case 4:
            return param.node?.data.cd_status === ALERT_STATUS_ENUM.ACTIVE
              ? 'Ativo'
              : 'Concluído';
          default:
            return param.value ? formatterNumber(param.value) : 0;
        }
      },
    });
  }
}
