import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IIndexStock } from '../../interface/stocks-at-auction-by-index.interface';
import { CommonModule } from '@angular/common';
import { NotificationCountComponent } from '../notification-count/notification-count.component';
import { INDEXES_TO_SHOW_STOCKS_AT_AUCTION } from '../../enum/stocks-at-auction-by-index.enum';

@Component({
  selector: 'app-indexes-to-show-stocks-at-auction',
  templateUrl: './indexes-to-show-stocks-at-auction.component.html',
  styles: [
    `
      .grid-content {
        display: grid;
        grid-template-columns: repeat(3, 100px);
        grid-template-rows: repeat(10, 32px);
        justify-content: space-around;
        width: 100%;

        .pointer-cursor {
          cursor: pointer;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NotificationCountComponent],
})
export class IndexesToShowStocksAtAuctionComponent {
  @Output() public updateIndexSelected = new EventEmitter<IIndexStock>();
  @Output() public displayIndexStocks = new EventEmitter<IIndexStock>();

  @Input() public indexSelected: IIndexStock | null = null;
  @Input() public indexes: IIndexStock[] = [];

  public isSelectingIndex: boolean = false;
  public newIndexSelected: IIndexStock | null = null;
  public readonly elementsID = INDEXES_TO_SHOW_STOCKS_AT_AUCTION;

  public onSelectIndex(index: IIndexStock): void {
    if (this.isSelectingIndex) {
      this.newIndexSelected = index;
      return;
    }
    this.displayIndexStocks.emit(index);
  }

  public changeIndexSelected(): void {
    if (this.newIndexSelected?.cdStock === this.indexSelected?.cdStock) {
      this.updateIndexSelected.emit(this.newIndexSelected!);
      this.newIndexSelected = null;
      this.isSelectingIndex = false;
      return;
    }
    this.updateIndexSelected.emit(this.newIndexSelected!);
    this.newIndexSelected = null;
    this.isSelectingIndex = false;
  }
}
