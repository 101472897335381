import { Injectable } from '@angular/core';
import {
  TTradeIdeaEvents,
  TTradeIdeaEventsStrategyItem,
} from '@shared/tiger-chart/types/tiger-chart.types';
import { formatMsDateToHumanDate } from 'src/app/utils/utils-events-worker.functions';
import {
  formatByTick,
  formatPrice,
  isNullOrUndefined,
} from 'src/app/utils/utils.functions';
import { IStockChartOrderConfig } from '../stock-chart/interface/stock-chart.interface';
import { TYPE_ORDE_ENUM } from '../stock-trade/enum/stock-trade.enum';
import { TYPE_ORDE_SIDE_ENUM } from '@shared/enum/buyOrSell.enum';
import { CONTEXT_MENU_ORDER_TYPE } from '@shared/tiger-chart/enum';
import { IAccountSelect, ISearchStock } from '@core/interface';
import { ORDER_PARAM_HELPER } from '@shared/constants/order-param-helper.const';
import { OriginAnalysisOrderService } from '@shared/services/origin-analysis-order.service';
import { StockChartService } from '../stock-chart/service/stock-chart.service';
import { createTradeIdea } from './trade-idea.interface';
import { system } from '@core/system/system.service';
import { STRATEGY_TYPE } from '@core/layout/header/strategy/enum/strategy.enum';
import { IHeader } from './trade-idea-header/trade-idea-header.interface';
import { HEADER_CODE } from './trade-idea-header/trade-idea-header.enum';
import { StockServiceRT } from '@shared/services/api/nitro-ws/v1/stock.service';

@Injectable({
  providedIn: 'root',
})
export class TradeIdeaHelperService {
  constructor(
    private originAnalysisOrderService: OriginAnalysisOrderService,
    private stockService: StockServiceRT
  ) {}

  formatTradeIdeas(
    tradeIdeas: TTradeIdeaEvents[],
    header: IHeader,
    tickSizeDenominator?: number
  ): TTradeIdeaEvents[] {
    let lastDate = 0;
    const infoToShow: TTradeIdeaEvents[] = [];

    tradeIdeas.forEach((element: TTradeIdeaEvents) => {
      if (element.cd_status === 'INATIVO') return;
      const trade = { ...element };
      const date = trade.dh_insert.split('+')[0];
      const defaultDt = new Date(date).getTime();
      const dt = new Date(date).setHours(0, 0, 0, 0);
      const validityDate =
        header.code === HEADER_CODE.ACTIVE
          ? !element.is_expiration
          : element.is_expiration;
      if (!validityDate && header.code !== HEADER_CODE.MY) return;
      if (dt != lastDate) {
        lastDate = dt;
        infoToShow.push({
          ...trade,
          isNotInfo: true,
          label: this.getLabel(dt, formatMsDateToHumanDate(defaultDt, false)),
        });
      }
      const side = trade.side === 'B' ? 'Compra' : 'Venda';
      infoToShow.push({
        ...trade,
        label: `${formatMsDateToHumanDate(defaultDt, true, false)} ${
          trade.cd_stock
        } - ${side}`,
        title: `${this.getLabel(
          dt,
          formatMsDateToHumanDate(defaultDt, false)
        )} às ${formatMsDateToHumanDate(defaultDt, true, false)}`,
        subTitle: `${trade.cd_stock} - ${side}`,
        priceFormatted: formatByTick(
          trade.price_entry,
          tickSizeDenominator ?? trade.tick_size_denominator ?? 2
        ),
      });
    });
    return infoToShow;
  }

  getLabel(dt: number, label: string): string {
    const today = new Date();
    const todayMs = new Date().setHours(0, 0, 0, 0);
    const yesterday = today.setDate(today.getDate() - 1);
    const yesterdayMs = new Date(yesterday).setHours(0, 0, 0, 0);
    const isToday = todayMs === dt;
    const isYesterday = yesterdayMs === dt;
    if (isToday) {
      label = 'Hoje';
    }
    if (isYesterday) {
      label = 'Ontem';
    }
    return label;
  }

  formatStrategyItemsTradeIdea(
    strategyType: STRATEGY_TYPE,
    items: TTradeIdeaEventsStrategyItem[]
  ): any[] {
    let tickSize = 0;
    const hashTickSize: any = {
      [STRATEGY_TYPE.FINANCEIRO]: 2,
      [STRATEGY_TYPE.PERCENTUAL]: 0,
      [STRATEGY_TYPE.TICK]: 0,
    };
    const tick = hashTickSize[strategyType];
    if (tick) {
      tickSize = tick;
    }
    const suffix = strategyType === STRATEGY_TYPE.PERCENTUAL ? '%' : '';
    items.map((item) => {
      item.formattedQuantity = formatByTick(
        item.quatity ?? item.quantity ?? 0,
        0
      );
      item.formattedGain = `${formatByTick(item.gain, tickSize)}${suffix}`;
      item.formattedLoss = `${formatByTick(item.loss, tickSize)}${suffix}`;
      item.formattedOffset = formatByTick(item.offset, 0);
      return item;
    });
    return items;
  }

  buildTradeIdeaOrder(
    event: TTradeIdeaEvents,
    stock: ISearchStock,
    account: IAccountSelect,
    quant: number
  ): IStockChartOrderConfig {
    const typeOrder = event.side === 'B' ? 'Comprar' : 'Vender';
    const config: IStockChartOrderConfig = {
      idStrategy: event.strategy.id_strategy,
      stock,
      account,
      quant,
      price: event.price_entry,
      ord_type: isNullOrUndefined(event.ord_type)
        ? TYPE_ORDE_ENUM.LIMITADA
        : event.ord_type,
      ord_side:
        typeOrder == CONTEXT_MENU_ORDER_TYPE.BUY
          ? TYPE_ORDE_SIDE_ENUM.BUY
          : TYPE_ORDE_SIDE_ENUM.SELL,
    };
    if (config.quant % stock.standard_lot != 0) {
      config.useSplit = true;
      config.isConfirm = true;
    }
    return config;
  }

  setTradeIdeaOrigin(event: TTradeIdeaEvents) {
    let orderOrigin = ORDER_PARAM_HELPER.TRADE_IDEA_BUY_LIMITED;
    if (event?.side === 'S') {
      orderOrigin = ORDER_PARAM_HELPER.TRADE_IDEA_SELL_LIMITED;
    }
    this.originAnalysisOrderService.setOriginOrder(orderOrigin);
  }

  executeTradeIdea(
    selectedTradeIdea: TTradeIdeaEvents,
    account: IAccountSelect,
    qtty: number,
    stockChartService: StockChartService
  ) {
    this.setTradeIdeaOrigin(selectedTradeIdea);
    this.stockService
      .searchStock(selectedTradeIdea.cd_stock, [], true)
      .subscribe((data) => {
        const firstStock = data[0];
        if (!firstStock) return;
        const config: IStockChartOrderConfig = this.buildTradeIdeaOrder(
          selectedTradeIdea,
          firstStock,
          account,
          qtty
        );
        stockChartService.newOrder(config).subscribe();
      });
  }
  /**
   *
   * @param date
   * @returns a data formatada em YYYY-MM-DAYTHH:MM:00.000Z
   */
  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`;
  }

  buildTradeIdea(
    formValue: any,
    stock: ISearchStock,
    side: string,
    date: Date,
    selectedTradeIdea?: TTradeIdeaEvents
  ): createTradeIdea {
    const tradeIdea: createTradeIdea = {
      cd_status: 'ATIVO',
      cd_stock: stock.cd_stock_order,
      description: formValue.description,
      cd_trade_idea_type: 'DRE23',
      dh_maturity: this.formatDate(date),
      id_exchange: stock.id_exchange,
      id_user: system.idInvestor,
      price_entry: parseFloat(
        formatPrice(formValue.price.toString(), stock.tick_size_denominator)
      ),
      side: side === 'BUY' ? 'B' : 'S',
      strategy: {
        name_strategy: 'Estratégia',
        items: formValue.items,
        type_strategy: formValue.typeStrategy,
      },
    };
    if (selectedTradeIdea) {
      tradeIdea.strategy.id_strategy = selectedTradeIdea.strategy.id_strategy;
    }
    return tradeIdea;
  }
}
