import { Injectable } from '@angular/core';
import {
  RocketIModal,
  RocketModalService,
} from '@shared/rocket-components/components';
import { RocketTModalSize } from '@shared/rocket-components/components/modal/types/modal.types';

@Injectable({
  providedIn: 'root',
})
export class StandaloneModalsService {
  private _defaultOptions: RocketIModal = {
    centered: true,
    backdrop: true,
    keyboard: true,
    scrollable: false,
  };

  constructor(private _modalService: RocketModalService) {}

  public close(data: any = null, isSecondModal: boolean = false): void {
    this._modalService.close(data, isSecondModal);
  }

  public openStandaloneModal(
    modalComponent: any,
    params: any = null,
    settings: RocketIModal = {},
    css: string = 'modal-sized',
    size: RocketTModalSize = ''
  ) {
    return this._modalService.open(modalComponent, {
      data: params,
      ...this._defaultOptions,
      ...settings,
      css,
      size,
    });
  }
}
