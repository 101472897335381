import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '@shared/services/rest';
import {
  IResendSMSPincode,
  ISendWhatsAppPincode,
  OnboardApiInterface,
} from './onboard-api.interface';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnboardApiService
  extends RestService
  implements OnboardApiInterface
{
  override _url: string = 'api/trademap';

  constructor(http: HttpClient) {
    super(http);
  }

  private callService = <T>(
    serviceName: string,
    params: any = {},
    header?: any
  ) =>
    this.post<T>(serviceName, params, header).pipe(
      map((res) => res.data),
      catchError((err) => of(err))
    );

  initialOnboard = (origin: string) =>
    this.callService('v8/onboard/initial-onboard', {
      ds_push_notification_token: '',
      device_info: 'new_nitro_user_job',
      vl_locale: 'pt-BR',
      origin
    });

  phoneConfirmation = (params: {
    cd_access_token: string;
    vl_full_phone_number: string;
    verification: string;
  }) => this.callService('v4/onboard/phone-confirmation', params);

  updateOnboardField = (data: {
    value: string;
    field: string;
    cd_access_token: string;
  }) => this.callService('v8/onboard/update-onboard-field', data);

  validateInitialOnboard = (param: {
    cd_access_token: string;
    recaptcha?: string;
  }) => {
    const token = param.recaptcha;
    delete param.recaptcha;
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'recaptcha-token': token ?? '',
    });
    return this.callService(
      'v8/onboard/validate-initial-onboard',
      param,
      httpOptions
    );
  };

  resendPhoneConfirmation(params: {
    cd_access_token: string;
    vl_full_phone_number: string;
  }): Observable<IResendSMSPincode> {
    return this.callService<IResendSMSPincode>(
      'v4/onboard/resend-phone-confirmation',
      params
    );
  }

  registerCelPhone = (params: {
    cd_access_token: string;
    vl_full_phone_number: string;
    operation_system: string;
    type_to_send: string;
  }): Observable<ISendWhatsAppPincode> =>
    this.callService('v9/onboard/register-cel-phone', params);

  verifyDuplicityEmail = (params: { email: string; cpf?: any }) =>
    this.callService('v9/onboard/verify-duplicity-email', params);

  getUserRegistered = (params: { document: string }) =>
    this.callService('v8/onboard/get-user-registered', params);

  onboardGogogo = (params: {
    cd_access_token: string;
    password: string;
    is_term_accepted: boolean;
  }) => this.callService('v8/onboard/onboard-gogogo', params);

  requestAccountRecoverWithType = (
    params: RequestAccountRecoverWithTypeInterface
  ) => {
    const token = params.recaptcha;
    delete params.recaptcha;
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'recaptcha-token': token ?? '',
    });

    return this.callService(
      'v8/authentication/requestNitroAccountRecover',
      params,
      httpOptions
    );
  };

  doRecoverAccountWithType = (params: DoRecoverAccountWithTypeInterface) =>
    this.callService('v8/authentication/doRecoverAccountWithType', params);

  changePasswordAfterValidate = (params: ChangePasswordParamInterface) =>
    this.callService(
      'v8/authentication/changePasswordNitroAfterValidate',
      params
    );
}

export interface ChangePasswordParamInterface {
  token: string;
  new_password: string;
  new_confirm_password: string;
}

export interface DoRecoverAccountWithTypeInterface {
  token: string;
  value: string;
  type: string;
}

export interface RequestAccountRecoverWithTypeInterface {
  vlDocument: string;
  documentType: string;
  recoveryInfoType: string;
  recoveryInfo: string;
  operatingSystem: string;
  recaptcha?: string;
}
