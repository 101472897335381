import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ISearchStock } from '@core/interface';
import { BusinessProfileService } from '../business-profile.service';

@Component({
  selector: 'app-about-index',
  templateUrl: './about-index.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutIndexComponent {
  @Input() set stockSelected(stockSelected: ISearchStock) {
    if (stockSelected) {
      this._stockSelected = stockSelected;
      this.getCompanyInfo();
    }
  }
  private _stockSelected!: ISearchStock;
  public loading = true;
  public companyInfo!: string | undefined;

  constructor(
    private cdr: ChangeDetectorRef,
    private businessProfileService: BusinessProfileService
  ) {}

  private getCompanyInfo() {
    this.businessProfileService
      .indexDescription(this._stockSelected)
      .subscribe((data: string) => {
        this.companyInfo = data;
        this.loading = false;
        this.cdr.detectChanges();
      });
  }
}
