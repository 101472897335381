import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  Signal,
  ViewChild,
  computed,
} from '@angular/core';
import { HistoryTableConfig } from './history-table-config.class';
import { OrdersHistoryService } from './service/orders-history.service';
import { GlobalSelectedStockSubscription } from '@services/core/subscription/global-stock.subscription';
import { OrdersTabService } from './service/orders-tab.service';
import {
  debounceTime,
  filter,
  map,
  Observable,
  sampleTime,
  skip,
  Subject,
  Subscription,
  takeUntil,
  tap,
} from 'rxjs';
import {
  FIELD_INDEXER,
  GRID_EMPTY_MESSAGE,
  TABS,
  TABS_TYPE,
} from './constants/orders-history.const';
import {
  isIdBrokerConsolidated,
  isSimulatorOrLeague,
} from '@shared/constants/simulator-league.constant';
import {
  ALERT_STATUS_ENUM,
  IAccountSelect,
  IAlert,
  IOrders,
  ISearchStock,
  IWorkSpaceComponet,
} from 'src/app/core/interface';
import {
  hasSortColumn,
  isNullOrUndefined,
  isRowSelectedStock,
} from 'src/app/utils/utils.functions';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import { MultibrokerService } from '@shared/services/core/multibroker';
import { Dictionary } from '@core/models';
import { CustodyChannel } from '@shared/channel/custody.channel';
import { GridIChangeField, RocketGridService } from '@shared/rocket-grid';
import { ComponentsService } from '@shared/services/api/nitro-ws/v1/components.service';
import { AlertService } from '@shared/services/api/trademap/v1/alert.service';
import {
  FilterChangedEvent,
  GridApi,
  RowDataTransaction,
} from 'ag-grid-community';
import { RowTotal } from './base/row-total';
import { OrdersHistoryHeaderComponent } from './orders-history-header/orders-history-header.component';
import { OrdersService } from '@shared/services/orders.service';
import { deepClone } from '@shared/rocket-components/utils/functions';
import { ActivatedRoute } from '@angular/router';
import { ViewHistoryBase } from './base/view-history.base';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { OrdersHistoryConfigService } from './service/orders-history-config.service';
import { isWebViewContext } from 'src/app/desktop/integration.service';
import { RocketStreamRead } from '@shared/channel/rx-event';
import { OrdersHistoryGridComponent } from './orders-history-grid/orders-history-grid.component';

@Component({
  selector: 'app-orders-history',
  templateUrl: 'orders-history.component.html',
  styleUrls: ['./orders-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersHistoryComponent
  extends RocketComponentBase
  implements OnDestroy
{
  @ViewChild('orderHistoryHeader', { static: false })
  header!: OrdersHistoryHeaderComponent;
  @ViewChild('orderHistoryGrid', { static: false })
  grid!: OrdersHistoryGridComponent;
  @Input() refComponent!: string;
  @Input() componentId!: string;
  @Input() component!: IWorkSpaceComponet;
  private custodyReadStream!: RocketStreamRead;
  private resetLineTotal$ = new Subject<void>();
  private _onDestroy = new Subject<void>();
  private custodyChannelEvent$!: Subscription;
  private custodyChannelSnapshot$!: Subscription;
  private _orderChannelSnapshot$!: Subscription;
  private getOrdersStream$!: Subscription;
  private gridTotalApi!: GridApi;
  private isApplyFilter = false;
  private resetSort$ = new Subject<void>();
  private _removeBottomLineKeyBeforeAdd: boolean = false;
  private _base = new ViewHistoryBase();
  private _custodySnapshot!: (items: any) => void;
  private _contextMenuListener$ = new Subscription();
  private stockInTabByPosition: any = {};
  private orderHistoryReady = false;
  applyFilter$ = new Subject<void>();
  tableConfig = new Dictionary<HistoryTableConfig>();
  rowData: any = {};
  tabSelected: any = {};
  accountSelected!: any;
  onAccountChange$: Subscription = new Subscription();
  TABS_TYPE = TABS_TYPE;
  positionQtty: number = 0;
  forceDisplayTotalLine: boolean = false;
  ordersHistoryTab!: any;
  onUpdateField!: GridIChangeField;
  pinnedBottomRowData: any = {};
  resetFilter = new Dictionary<boolean>();
  isDayTrade: boolean = false;
  noRowsTemplateMessage: string = 'Não existem dados.';
  fieldIndexer = '';
  positionDict: any = { DAYTRADE: {}, SWINGTRADE: {} };
  totalRowData: any = new RowTotal();
  globalCdStock!: string;
  cdStockOrder!: string;
  synonymousRelated!: string;
  ignoreRocketService: boolean = true;
  alertSubscription$!: Subscription;
  showTotalRow: Signal<boolean> = computed(() => false);
  orders$!: Observable<[]>;
  keyHeader!: string | null;
  isDesktop = false;
  isCardView = false;

  get positionDictByType() {
    const type = this.isDayTrade ? 'DAYTRADE' : 'SWINGTRADE';
    return this.positionDict[type];
  }

  get checkGridTotal() {
    return this.gridTotalApi && !this.gridTotalApi['destroyCalled'];
  }

  getGlobalStockSelected = () => {
    const stock = this._globalStock.getGlobalStockSelected();
    return stock?.cd_stock_order;
  };

  get linked() {
    return this.component.metadata?.headerOptions?.link;
  }

  get idBrokerSelected() {
    return this.accountSelected?.id_broker;
  }

  get headerTabs(): string[] {
    return this.component.metadata.component.headerTabs;
  }

  set headerTabs(tabs: any) {
    this.component.metadata.component.headerTabs = tabs;
  }

  get metadataComponent() {
    return this.component.metadata.component;
  }

  get gridConfigMetadata() {
    return this.metadataComponent.gridConfigs;
  }

  get componentWidth() {
    return (
      this.component.metadata.componentResize?.width ??
      this.component.metadata.layout.width
    );
  }

  get componentHeight() {
    return (
      this.component.metadata.componentResize?.height ??
      this.component.metadata.layout.height
    );
  }

  get tradeModeSelected() {
    return this._dayTradeService.useDayTradeMode
      ? TABS_TYPE.INTRADIA
      : TABS_TYPE.SWINGTRADING;
  }

  get tabSelectedUpdate() {
    return `${this.tabSelected.key}_${this.tradeModeSelected}`;
  }

  get gridApi() {
    return this.grid?.gridApi;
  }

  setGridConfigMetadata(ref: string, data?: any) {
    if (!this.metadataComponent.gridConfigs)
      this.metadataComponent.gridConfigs = {};
    const config = data
      ? data
      : this._ordersHistoryService.getGridConfigByRef(ref);
    this.metadataComponent.gridConfigs[ref] = config;
    return config;
  }

  constructor(
    private _ordersHistoryService: OrdersHistoryService,
    private _ordersService: OrdersService,
    private _globalStock: GlobalSelectedStockSubscription,
    _componentService: ComponentsService,
    public _ordersTab: OrdersTabService,
    private _mbService: MultibrokerService,
    private _dayTradeService: DaytradeService,
    private custodyChannel: CustodyChannel,
    protected _rocketGridService: RocketGridService,
    private _alertService: AlertService,
    private cdr: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    private _configService: OrdersHistoryConfigService
  ) {
    super(activatedRoute);
    this.isDesktop = isWebViewContext();
    this.updateMetadaSubscription();
  }
  private updateMetadata$ = new Subject<void>();
  private updateMetadaSubscription(): void {
    this.updateMetadata$
      .pipe(takeUntil(this._onDestroy), debounceTime(1000))
      .subscribe(() => {
        this._ordersHistoryService.updateMetadata(this.component);
      });
  }

  //OBS: Função para adicionar fullscreen em componentes criados antes de 21/nov/23
  updateMetadataFullScreen() {
    this.component.metadata.headerOptions.fullscreen = true;
    this.updateMetadata$.next();
  }

  onViewReady = (gridApi: any) => {
    this.orderHistoryReady = true;
    if (!this.isCardView) {
      this._configService.setHandler(this.refComponent, {
        gridApi,
      });
    }
    const tabKey =
      this.metadataComponent.lastActiveTab ?? this.tradeModeSelected;
    const lastActiveTab = TABS[tabKey];
    if (!this.gridConfigMetadata) this.setGridConfigMetadata(lastActiveTab.ref);
    this.selectTypeOrderList(lastActiveTab);
    this.resetSort$.next();
  };

  onGridTotalReady = (gridApi: any) => {
    this.gridTotalApi = gridApi;
    this.cdr.detectChanges();
    this.resetLineTotal$.next();
  };

  protected async initComponent(component: any): Promise<void> {
    this.createWorker();
    if (component) this.component = component;

    this._configService.addNewComponent(this.refComponent, {
      filterIsActive: false,
      isSorted: false,
      canHideTab: true,
      isDetailsOrder: true,
      totalLineIsVisible: false,
      isFiltered: false,
      isVisible: true,
      isTour: false,
      keyHeader: '',
      selectedRow: undefined,
      showTotalRow: false,
      headerTabs: [],
      tabInfs: null,
    });

    this._changeTabOnToggleDayTradeMode();
    this.checkAndSetTabSelectedByMode();
    this.initializeTotalSubscription();
    this.isCardView = this._ordersService.ordersHistoryCardView;
    this.globalCdStock = this.getGlobalStockSelected();
    this.cdStockOrder =
      this._globalStock.getGlobalStockSelected().cd_stock_order;
    this.synonymousRelated =
      this._globalStock.getGlobalStockSelected().synonymous_related;
    this.accountEvents();
    this._base
      .onStart(this.component)
      .pipe(
        tap((component) => {
          this.component = component;
          if (!this.headerTabs)
            this.headerTabs = this._ordersTab.defaultHeaderKeys;
        })
      )
      .subscribe(this.startComponent);
    this.resetSort$
      .pipe(takeUntil(this._onDestroy), debounceTime(100))
      .subscribe(() => this.grid.cleanSortSave());
    this.applyFilter$
      .pipe(takeUntil(this._onDestroy), debounceTime(50))
      .subscribe(() => this.applyFilter());

    this.custodyReadStream = await this.custodyChannel.readEvents();
    this._custodySnapshot = this.custodyReadStream.snapshot;
    this.readStream(this.custodyReadStream.stream, (payload: any) => {
      const tabs = [
        TABS_TYPE.POSITION,
        TABS_TYPE.INTRADIA,
        TABS_TYPE.SWINGTRADING,
      ];
      if (tabs.indexOf(this.tabSelected.key) < 0) return;
      const item = payload.get(this.custodyChannel.itemsArray[0]);
      item?.forEach((value: any) => {
        if (
          this.custodyChannel.itemsArray.includes(value.item) &&
          !value.isEndOfSnap
        ) {
          this.threadCustodyHandler(value);
        }
      });
    });
    this._custodySnapshot(this.custodyChannel.itemsArray);
    this._configService.onColumnHide$
      .pipe(takeUntil(this._onDestroy))
      .subscribe((tab) => {
        this.onChangeTabHandler(tab);
      });
    this._contextMenuListener$ = this._configService.startContextMenuListener(
      this.refComponent,
      this.component
    );
    const metadata = this.metadataComponent;

    this._configService.setConfig(this.refComponent, {
      filterIsActive: metadata.isFiltered,
      isFiltered: metadata.isFiltered,
      showTotalRow: this.tabSelected?.key
        ? this._configService.hasPinnedRow(this.component, this.tabSelected.key)
        : false,
    });
    this.showTotalRow = computed(() => {
      const config = this._configService.getComponentConfig(this.refComponent);
      return config ? config().showTotalRow : false;
    });

    if (this.isDesktop) {
      this.refComponent = this.component.metadata.headerOptions.component?.data;
    }
  }

  ngOnDestroy() {
    this.keepReading = false;
    this._onDestroy.next();
    this._onDestroy.complete();
    this.custodyReadStream && this.custodyReadStream.close();
    this.custodyChannelEvent$ && this.custodyChannelEvent$.unsubscribe();
    this._orderChannelSnapshot$ && this._orderChannelSnapshot$.unsubscribe();
    this.getOrdersStream$ && this.getOrdersStream$.unsubscribe();
    this.worker && this.worker.terminate();
    this._contextMenuListener$.unsubscribe;
    this.gridTotalApi && this.gridTotalApi.destroy();
  }

  private initializeTotalSubscription(): void {
    this.resetLineTotal$
      .pipe(
        debounceTime(100),
        takeUntil(this._onDestroy),
        filter(() => Boolean(this.gridTotalApi))
      )
      .subscribe(this.resetFooterTotal);
  }

  startComponent = () => {
    this._globalStock.onGlobalStockChange().then(async (data) => {
      this.readStream(data.stream, this.onGlobalStockChange);
    });
    this.updateMetadataFullScreen();
  };

  private onGlobalStockChange = (stock: ISearchStock) => {
    this.grid.updateSelectedStock(stock);
    const cdStock = stock.is_synonymous
      ? stock?.synonymous_nickname
      : stock.cd_stock_order;
    this.globalCdStock = cdStock;
    this.cdStockOrder = stock.cd_stock_order;
    this.synonymousRelated = stock.synonymous_related;
  };

  filterTotalPositionByBroker = (data: any) => {
    const { detail: id_broker } = data;
    return (
      +id_broker == this.idBrokerSelected ||
      (isIdBrokerConsolidated(this.idBrokerSelected) &&
        !isSimulatorOrLeague(+id_broker))
    );
  };

  accountEvents() {
    this.accountSelected = this._mbService.getAccountSelected();
    const general$ = this._mbService.onUpdateSelectedAccountChannel().pipe(
      takeUntil(this._onDestroy),
      sampleTime(400),
      filter(
        (account: any) =>
          account &&
          this.accountSelected &&
          ((account.account_number !== this.accountSelected.account_number &&
            account.id_broker !== this.accountSelected.id_broker) ||
            isSimulatorOrLeague(account.id_broker) !==
              isSimulatorOrLeague(this.accountSelected.id_broker))
      )
    );
    this.onAccountChange$.add(general$.subscribe(this.changeAccount));
  }

  public changeAccount = (account: IAccountSelect): void => {
    this.accountSelected = deepClone(account);
    this.positionDict = { DAYTRADE: {}, SWINGTRADE: {} };
    if (this.grid) {
      this.grid.ordersStubDict.clear();
    }
    this._removeBottomLineKeyBeforeAdd = true;
    this.resetLineTotal$.next();
    this.gridApi && this.gridApi.setRowData([]);
    this.stockInTabByPosition = {};
    this.processTableData(account);
    this.cdr.detectChanges();
    this._ordersService.emitTotalRecount();
  };

  public onColumnResizedHandler(event: any): void {
    if (!this.gridConfigMetadata[this.tabSelected.ref][event.colId]) return;
    this.gridConfigMetadata[this.tabSelected.ref][event.colId].width =
      event.actualWidth;
    this.updateMetadata$.next();
  }

  public onColumnMovedHandler(event: any): void {
    const reorderHeaders: any = {};
    event.forEach((item: any) => {
      reorderHeaders[item.colId] =
        this.gridConfigMetadata[this.tabSelected.ref][item.colId];
    });
    this.gridConfigMetadata[this.tabSelected.ref] = reorderHeaders;
    this.updateMetadata$.next();
  }

  public resetDefaultColumns = (tab: any): void => {
    this.forceDisplayTotalLine = true;
    setTimeout(() => {
      this.selectTypeOrderList(tab);
      this.forceDisplayTotalLine = false;
    }, 100);
  };

  public getColumnsConfigByRef(ref: string) {
    return !this.gridConfigMetadata || !this.gridConfigMetadata[ref]
      ? this.setGridConfigMetadata(ref)
      : this.gridConfigMetadata[ref];
  }

  private resetFooterTotal = () => {
    let gridTotalColumns = this.tabSelected.footerColumnDefs
      ? this.tabSelected.footerColumnDefs
      : this.tabSelected.columnDefs;
    if (
      this.metadataComponent.lastActiveTab === TABS_TYPE.INTRADIA ||
      this.metadataComponent.lastActiveTab === TABS_TYPE.SWINGTRADING ||
      this.metadataComponent.lastActiveTab === TABS_TYPE.POSITION
    ) {
      gridTotalColumns = this.tabSelected.columnDefs.map((column: any) => {
        if (column.field === 'remove_custody') {
          return {
            headerName: '',
            field: 'remove_custody',
            width: 32,
          };
        }
        return { ...column };
      });
    }
    this.gridTotalApi.setColumnDefs(gridTotalColumns);
    const key = this.tabSelected.key;
    const info = this.gridTotalApi.getRowNode(key);
    this.gridTotalApi.applyTransactionAsync({
      remove: info && this._removeBottomLineKeyBeforeAdd ? [{ key }] : [],
      add: [{ key, cd_stock: `0 Ativos` }],
    });
    this._removeBottomLineKeyBeforeAdd = false;
  };

  private resetState(): void {
    this.unsubscribeAll();
    this._configService.resetTotalRow(
      this.refComponent,
      this.component,
      this.gridTotalApi,
      this.tabSelected
    );

    if (!this.isCardView) {
      this.grid.columnApi?.applyColumnState({
        defaultState: { sort: null },
      });
      this.grid.ordersStubDict.clear();
    }
    this.stockInTabByPosition = {};
  }

  private unsubscribeAll(): void {
    [
      this.custodyChannelEvent$,
      this.custodyChannelSnapshot$,
      this.alertSubscription$,
      this._orderChannelSnapshot$,
      this.getOrdersStream$,
    ]
      .filter((subscription) => subscription)
      .forEach((subscription) => subscription.unsubscribe());
  }

  private updateTabProperties(tab: any, newColumns: any[]): void {
    const showTotalRow = this._configService.hasPinnedRow(
      this.component,
      tab.key
    );
    this._configService.setConfig(this.refComponent, { showTotalRow });

    this.positionDict = { DAYTRADE: {}, SWINGTRADE: {} };
    tab.columnDefs = newColumns;
    this.tabSelected = tab;
    this.fieldIndexer = FIELD_INDEXER[this.tabSelected.component_type];

    if (this.checkGridTotal) {
      this.resetLineTotal$.next();
    }

    this.ordersHistoryTab = { ...this.tabSelected };

    this._configService.setHandler(this.refComponent, {
      selectedTab: this.tabSelected,
    });
  }

  private updateGridApi(newColumns: any[]): void {
    if (this.gridApi) this.gridApi.setColumnDefs(newColumns);
  }

  onChangeTabHandler = (tab: any): void => {
    if (!this.orderHistoryReady) return;
    this.resetState();
    if (!this.isCardView) {
      this.gridApi?.setRowData([]);
    }
    this.processTotalRowColumns(tab);
    this.processTableData();
    this.resetSort$.next();
    this.cdr.detectChanges();
  };

  private processTotalRowColumns = (tab: any) => {
    this.gridTotalApi?.setRowData([]);
    const columnsByRef = this.getColumnsConfigByRef(tab.ref);
    const newColumns = this.customColumns(
      columnsByRef,
      tab.columnDefs,
      tab.ref
    );
    if (this.showTotalRow()) {
      tab.footerColumnDefs = this.customColumns(
        columnsByRef,
        tab.footerColumnDefs ?? tab.columnDefs,
        tab.ref
      );
    }
    if (
      tab.component_type === TABS_TYPE.ORDER ||
      tab.component_type === TABS_TYPE.ALERTS
    ) {
      this.sortFooterColumns(newColumns, tab.footerColumnDefs);
    }
    this.noRowsTemplateMessage = GRID_EMPTY_MESSAGE[tab.component_type];
    this.updateTabProperties(tab, newColumns);
    if (!this.isCardView) this.updateGridApi(newColumns);
    this.cdr.detectChanges();
  };

  private sortFooterColumns(newColumns: any, footerColumnDefs: any): void {
    newColumns.forEach((item: any) => {
      const column = footerColumnDefs.find(
        (col: any) => col.field === item.field
      );
      if (!column) return;
      column['width'] = item.width;
      column['hide'] = item.hide;
    });
  }

  processTableData(account: any = null) {
    if (account) this.accountSelected = account;
    this.getOrdersStream$ && this.getOrdersStream$.unsubscribe();
    this._orderChannelSnapshot$ && this._orderChannelSnapshot$.unsubscribe();
    if (this._configService.isAnyPositionTab(this.tabSelected)) {
      this.positionDict = { DAYTRADE: {}, SWINGTRADE: {} };
      this.fillPositionData();
    }

    if (this.tabSelected.type === 'ALERTS') {
      this.initializeAlertTab();
    }

    if (this.tabSelected.component_type === 'ORDER') {
      this.fillOrderData();
    }
    this.cdr.detectChanges();
  }

  selectTypeOrderList = (tab: any) => {
    if (isNullOrUndefined(tab)) return;
    this.onChangeTabHandler(tab);
  };

  private customColumns(config: any, columnDefs: any[], ref: string): any[] {
    if (!config) return columnDefs;
    const columns: any[] = [];
    const configDefault = this._ordersHistoryService.getGridConfigByRef(ref);
    const configColumnsDefault =
      this._ordersHistoryService.getColumnsdefByRef(ref);
    Object.keys(configDefault).forEach((item: string, index: number) => {
      if (!config[item]) {
        const configColumns = configColumnsDefault.find(
          (column: any) =>
            column.field === item || column.headerComponentParams?.[1] === item
        );
        columns.splice(index, 0, { ...configColumns, ...configDefault[item] });
        return;
      }
      const field = columnDefs.find(
        (column) =>
          column.field === item || column.headerComponentParams?.[1] === item
      );
      if (field) {
        const indexPosition = Object.keys(config).findIndex(
          (fieldConfig: any) => fieldConfig === item
        );
        columns.splice(indexPosition, 0, { ...field, ...config[item] });
      }
    });
    return columns;
  }

  stockChangeHandler(event: any): void {
    this.linked &&
      this._globalStock.researchToStandardizeGlobalStock(event, true);
  }

  private _changeTabOnToggleDayTradeMode(): void {
    this._dayTradeService.dayTradeMode
      .pipe(
        takeUntil(this._onDestroy),
        tap((isEnable) => {
          this.isDayTrade = isEnable;
        }),
        map((isEnable) => (isEnable ? TABS.INTRADIA : TABS.SWINGTRADING)),
        skip(1)
      )
      .subscribe((tab) => {
        this.selectTypeOrderList(tab);
        this._ordersService.emitTotalRecount();
      });
  }

  fillPositionData() {
    this._custodySnapshot &&
      this._custodySnapshot(this.custodyChannel.itemsArray);
  }

  private worker!: Worker;

  private createWorker(): void {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('./custody.worker', import.meta.url));
      this.worker.onmessage = this.onMessageWorkerCallback;
    } else {
      alert('Atualize o navegador ou instale um mais moderno como o Chrome!');
    }
  }

  onMessageWorkerCallback = ({ data }: any): void => {
    this.positionDict = data.position;
    const isAdd = data?.type === 'ADD';
    data.transaction &&
      (this.grid.gridObjectUpdate = this.getGridData(data.transaction, {
        updateTotal: true,
        skipDebounce: isAdd,
      }));
  };

  sendDataToWork(data: {
    eventData: any;
    position: any;
    tabSelected: any;
    selectedStock: string;
    type?: string;
  }) {
    this.worker.postMessage(data);
  }

  threadCustodyHandler(eventData: any) {
    let { command } = eventData;
    const { isDayTrade } = this;
    const { has_day_trade, has_swing, id_broker } = eventData;
    const previous = this.positionDictByType[eventData.key];
    const data = this._handleData(eventData);

    const isPositionRemoved = this.removePosition(eventData, data, previous);
    if (isPositionRemoved) return;

    if (
      id_broker != this.idBrokerSelected ||
      (!previous && command == 'DELETE') ||
      (this.tabSelected.key !== 'POSITION' &&
        ((this.isDayTrade && has_day_trade === 'false') ||
          (!this.isDayTrade && has_swing === 'false')))
    ) {
      return;
    }

    const cdStock = data.is_synonymous
      ? data?.synonymous_nickname
      : data.cd_stock;
    data.isSelected = isRowSelectedStock(
      cdStock,
      this.globalCdStock,
      this.cdStockOrder,
      this.synonymousRelated
    );
    const updateObject: RowDataTransaction = {};

    if (command === 'ADD') {
      updateObject.addIndex = 0;
      if (previous) command = 'UPDATE';
    }

    const shouldAdd =
      !previous ||
      (previous &&
        ((isDayTrade && previous.has_day_trade !== has_day_trade) ||
          (!isDayTrade && previous.has_swing !== has_swing)));

    let key = shouldAdd ? 'ADD' : command;
    const keys: {
      [key: string]: 'add' | 'update' | 'remove';
    } = { ADD: 'add', UPDATE: 'update', DELETE: 'remove' };

    key = this.checkCustodyExistInTabPosition(key, data);
    updateObject[keys[key]] = [data];
    if (key === 'ADD') updateObject.addIndex = 0;
    this.grid.gridObjectUpdate = this.getGridData(updateObject, {
      updateTotal: true,
    });
    this.cdr.detectChanges();
  }

  private removePosition(eventData: any, data: any, previous: any): boolean {
    const { has_day_trade, has_swing } = eventData;
    if (
      (this.tabSelected.key !== 'POSITION' &&
        this.isDayTrade &&
        has_day_trade === 'false' &&
        previous?.has_day_trade === 'true') ||
      (!this.isDayTrade &&
        has_swing === 'false' &&
        previous?.has_swing === 'true')
    ) {
      delete this.stockInTabByPosition[data.key];
      this.grid.gridObjectUpdate = this.getGridData(
        { remove: [data] },
        { skipDebounce: true }
      );
      return true;
    }
    return false;
  }

  private checkCustodyExistInTabPosition(key: string, data: any): string {
    if (!this.stockInTabByPosition[data.key]) {
      this.stockInTabByPosition[data.key] = data.key;
      key = 'ADD';
    } else if (key === 'ADD' && this.stockInTabByPosition[data.key]) {
      key = 'UPDATE';
    }
    return key;
  }

  private _handleData = (data: any) => {
    const { has_day_trade, has_swing } = data;

    const lastValues =
      (this.positionDictByType[data.key] &&
        this.positionDictByType[data.key].value) ||
      0;
    data.class = ['text-right'];
    if (data.command === 'UPDATE') {
      if (data.preco_ultimo < lastValues) {
        data.class.push('blink-down');
      }
      if (data.preco_ultimo > lastValues) {
        data.class.push('blink-up');
      }
      data.class = data.class.join(' ');
    }

    this.positionDictByType[data.key] = {
      value: data.preco_ultimo || this.positionDictByType[data.key],
      has_day_trade,
      has_swing,
    };

    return data;
  };

  fillOrderData() {
    this._orderChannelSnapshot$ = this._ordersService
      .getSnapshot()
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.cdr.detectChanges();
        this.grid.updateTotalSubject.next();
      });
    this.subscribeOrderChannel();
  }

  subscribeOrderChannel() {
    this.getOrdersStream$ = this._ordersService
      .getOrdersStream()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        this.orderChannelHandler(data);
      });
    this._ordersService.getOrdersCached();
  }

  exportData() {
    this._configService.exportData(
      this.tabSelected,
      this.grid.gridApi,
      this.grid.columnApi
    );
  }

  public resetGridHeader = (ref: string) => {
    if (this.tabSelected.ref === ref) this.resetGrid();
  };

  public resetGrid = () => {
    this.metadataComponent.gridConfigs[this.tabSelected.ref] =
      this._ordersHistoryService.getGridConfigByRef(this.tabSelected.ref);
    this.updateMetadata$.next();
    this.onChangeTabHandler(TABS[this.tabSelected.key]);
  };

  orderChannelFilter = (order: IOrders | any) => {
    if (order.id_broker != this.idBrokerSelected) return;

    if (order?.isSnap || order?.isReconnect) return true;
    //const isSameBroker = order?.id_broker === this.idBrokerSelected;
    /*const canProcessConsolidated =
      this.isConsolidated() && !isSimulatorOrLeague(order?.id_broker);*/
    return !!order && this.isDayTrade === order?.has_day_trade; //&& canProcessConsolidated;
  };

  isConsolidated = () => isIdBrokerConsolidated(this.idBrokerSelected);

  orderChannelHandler = (data: any) => {
    if (!data || !this.orderChannelFilter(data)) {
      return;
    }
    if (this.tabSelected.component_type !== TABS_TYPE.ORDER) {
      this.grid.updateTotalSubject.next();
      return;
    }
    const isSelected = isRowSelectedStock(
      data.cd_stock,
      this.globalCdStock,
      this.cdStockOrder,
      this.synonymousRelated
    );

    this.grid.gridOrderHandler({
      ...data,
      isSelected,
      idRow: data.id_order,
    });

    this.grid.updateTotalSubject.next();
  };

  historyViewChanged() {
    this.isCardView = !this.isCardView;
    this._ordersService.ordersHistoryCardView = this.isCardView;
    this.orderHistoryReady = false;
    this.cdr.detectChanges();
  }

  subscribeAlertsEvents = () => {
    this.alertSubscription$ = this._alertService
      .onEvents()
      .subscribe((data: any) => {
        if (this.tabSelected.component_type !== TABS_TYPE.ALERTS) {
          this.grid.updateTotalSubject.next();
          return;
        }
        const isAdd: boolean = Object.prototype.hasOwnProperty.call(
          data,
          'add'
        );
        this.grid.gridObjectUpdate = this.getGridData(data, {
          redrawRows: !isAdd,
          skipDebounce: isAdd,
        });
        this.grid.updateTotalSubject.next();
      });
  };

  private _buildAlerts = (alerts: IAlert[]): IAlert[] =>
    alerts
      .map((alert: any) => {
        alert.ds_status_custom =
          alert.cd_status === ALERT_STATUS_ENUM.ACTIVE ? 'Ativo' : 'Concluído';
        alert.isSelected = isRowSelectedStock(
          alert.cd_Stock,
          this.globalCdStock,
          this.cdStockOrder,
          this.synonymousRelated
        );
        return alert;
      })
      .sort((a: IAlert, b: IAlert) => b.dh_insert.localeCompare(a.dh_insert));

  initializeAlertTab() {
    this._alertService
      .getAllAlerts()
      .pipe(map((data) => this._buildAlerts(data.alerts)))
      .subscribe((alerts: any) => {
        this.grid.gridObjectUpdate = this.getGridData(
          { add: alerts },
          { skipDebounce: true }
        );
        this.subscribeAlertsEvents();
        this.grid.updateTotalSubject.next();
      });
  }

  private initializeGridFilters(): void {
    if (!this.metadataComponent.gridFilters) {
      this.metadataComponent.gridFilters = {};
    }
    if (!this.metadataComponent.gridFilters[this.tabSelected.component_type]) {
      this.metadataComponent.gridFilters[this.tabSelected.component_type] = {};
    }
  }

  private extractActiveFilters(filterOptions: any[]): any {
    return filterOptions
      .filter((option: any) => option.active)
      .reduce(
        (accumulator: any, currentValue: any) => ({
          ...accumulator,
          [currentValue.value[0]]: true,
        }),
        {}
      );
  }

  private updateGridFilters(event: FilterChangedEvent): void {
    this.initializeGridFilters();
    event.columns.forEach((column) => {
      const key = column.getColDef().field!;
      const filter = this.gridApi.getFilterInstance(key) as any;
      if (!filter) {
        return;
      }
      let actives = this.extractActiveFilters(filter.filterOptions);
      Object.keys(actives).forEach((item: string) => {
        const today = new Date()
          .toISOString()
          .split('T')
          .shift()
          ?.replaceAll('-', '');
        if (item.includes(today!)) {
          delete actives[item];
          actives = { ...actives, [`${item}/hoje`]: true };
        }
      });
      this.metadataComponent.gridFilters[this.tabSelected.component_type][key] =
        { ...actives };
    });
  }

  private shouldSkipFilterChange(): boolean {
    if (this.isApplyFilter) {
      this.isApplyFilter = false;
      return true;
    }
    return false;
  }

  private notifySubjects(): void {
    this.updateMetadata$.next();
    this.grid.updateTotalSubject.next();
  }

  gridFilterChange(event: FilterChangedEvent): void {
    if (this.shouldSkipFilterChange()) {
      return;
    }
    this.updateGridFilters(event);
    this.notifySubjects();
  }

  private applyFilter() {
    if (
      this.metadataComponent.gridFilters &&
      this.gridApi.getDisplayedRowCount()
    ) {
      this.isApplyFilter = true;
      const gridFilter = deepClone(
        this.metadataComponent.gridFilters[this.tabSelected.component_type]
      );
      if (gridFilter) {
        Object.keys(gridFilter).forEach((key) => {
          const filter = this.gridApi.getFilterInstance(key);
          if (!filter) return;
          const field = Object.getOwnPropertyNames(gridFilter[key])[0];
          if (field.includes('hoje')) {
            const date = field.split('/').shift()!;
            const today = new Date()
              .toISOString()
              .split('T')
              .shift()
              ?.replaceAll('-', '');

            if (date.includes(today!)) {
              delete gridFilter[key][field];
              gridFilter[key] = { ...gridFilter[key], [date]: true };
            } else {
              gridFilter[key] = { ...gridFilter[key], ALL: true };
            }
          }
          filter.setModel(gridFilter[key]);
        });
      }
      this.gridApi.onFilterChanged();
    }
  }

  private checkAndSetTabSelectedByMode(): void {
    if (
      (this.metadataComponent.lastActiveTab === TABS_TYPE.INTRADIA ||
        this.metadataComponent.lastActiveTab === TABS_TYPE.SWINGTRADING) &&
      this.metadataComponent.lastActiveTab !== this.tradeModeSelected
    ) {
      this.component.metadata.component.lastActiveTab = this.tradeModeSelected;
    }
  }

  public saveSort(event: any): void {
    if (event.source === 'api') return;
    const sortState = hasSortColumn(this.grid.columnApi);
    if (!this.metadataComponent.gridSort) this.metadataComponent.gridSort = {};
    this.metadataComponent.gridSort[this.tabSelected.ref] = sortState;
    this.updateMetadata$.next();
  }

  private getGridData = (transaction: any, config?: any, tab?: string) => {
    return {
      transaction,
      config: config || {},
      tab: tab || structuredClone(this.tabSelectedUpdate),
    };
  };
}
