<ul class="mt-1 w-100 d-inline-flex flex-wrap bg-neutral-strong p-1 round">
  <li *ngFor="let item of keys" class="list-group-item p-1 fs-6">
    <fla-icon
      [name]="dataState[item] ? 'error' : 'done'"
      [css]="
        dataState[item] ? 'text-feedback-negative' : 'text-feedback-success '
      "
      size="icon-size-micro"
    ></fla-icon>
    {{ validatorMessages[item] }}
  </li>
</ul>
