import {
  BaseIndicator,
  TMAPoints,
  TTigerChartIndicatorParameter,
  TTigerChartIndicatorRenderSeriesConfig,
  TTigerChartIndicatorCreateOptions,
  TigerChartIndicatorElephantBarOptions,
} from '../indicators.types';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import {
  TIGER_INDICATORS_ENUM,
  TIGER_INDICATOR_PARAMETER_TYPE,
  TIGER_INDICATOR_SOURCES,
} from '../../enum/tiger-chart.enum';
import {
  TData,
  TTooltipIndicatorValue,
  TUpdateData,
} from '../../types/tiger-chart.types';
import { Subject } from 'rxjs';
import { TalibService } from '../../services/talib.service';
import { TIGER_INDICATOR_PRECISIONS } from '../indicators.constants';
import { setDefaultConfiguration } from '../indicators.functions';
import { TigerChartAverageDirectionalMovementIndex } from '../average-directional-x';
import { CANDLE_IDS } from '@shared/tiger-chart/constants/tiger-chart.constants';
import { CandlePaletteProvider } from '@shared/tiger-chart/annotations/candle-palette-provider';
import { IElephantCandles } from '../elephant-bar';
import { IRenderableSeries } from 'scichart';
import { CHART_COLORS } from '@shared/tiger-chart/colors';

export class TigerChartIndicatorElephantBarBase implements BaseIndicator {
  protected options!: TigerChartIndicatorElephantBarOptions;
  protected data: TData;
  protected timePeriod: number = 1;
  protected offset: number = 0;
  protected precision: number = 4;
  protected points: TMAPoints;
  protected source: TIGER_INDICATOR_SOURCES = TIGER_INDICATOR_SOURCES.CLOSE;
  protected positiveBarColor = CHART_COLORS.FEEDBACK_POSITIVE;
  protected negativeBarColor = CHART_COLORS.FEEDBACK_NEGATIVE;
  protected lineThickness = 1;
  baseChart!: TWebAssemblyChart;
  lineNumber!: string;
  updateOffset: boolean = false;
  service: TalibService;
  type: TIGER_INDICATORS_ENUM = TIGER_INDICATORS_ENUM.BAR_ELEPHANT;
  private visible: boolean = true;
  yAxisId!: string;
  settings: TTigerChartIndicatorParameter[] = [];
  styles: TTigerChartIndicatorParameter[] = [];
  renderSeriesConfig: TTigerChartIndicatorRenderSeriesConfig[] = [];
  onChange = new Subject<null>();
  mainLineId = '';
  isNewOnChart = false;

  get isVisible(): boolean {
    return this.visible;
  }

  set isVisible(visible: boolean) {
    console.log('????', visible);
    this.visible = visible;
    //do nothing
  }

  get propertiesText(): string {
    return `(${this.timePeriod})`;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getValuesByIndex(index?: number): TTooltipIndicatorValue[] {
    return [];
  }

  constructor(options: TigerChartIndicatorElephantBarOptions) {
    this.data = options.data;
    this.service = options.service;
    this.mainLineId = `${this.type}-main-line`;
    this.precision = options.tickSize;
    this.options = options;
    if (options.timePeriod) this.timePeriod = options.timePeriod;
    if (options.precision) this.precision = options.precision;
    if (options.positiveBarColor)
      this.positiveBarColor = options.positiveBarColor;
    if (options.negativeBarColor)
      this.negativeBarColor = options.negativeBarColor;
    this.points = {
      output: [],
    };
    this.settings = [
      {
        label: 'Fator de Busca',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property:
          'timePeriod' as keyof TigerChartAverageDirectionalMovementIndex,
        increment: 0.1,
      },
    ];
    this.renderSeriesConfig = [
      {
        label: 'Cor da barra positiva',
        id: this.mainLineId,
        color: this.positiveBarColor,
        propertyColor: 'positiveBarColor',
        thickness: this.lineThickness,
        propertyThickness: 'lineThickness',
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        dontShowTickness: true,
      },
      {
        label: 'Cor da barra negativa',
        id: this.mainLineId,
        color: this.negativeBarColor,
        propertyColor: 'negativeBarColor',
        thickness: this.lineThickness,
        propertyThickness: 'lineThickness',
        active: true,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        dontShowTickness: true,
      },
    ];

    this.styles = [
      {
        type: TIGER_INDICATOR_PARAMETER_TYPE.SELECTION,
        property: 'precision',
        label: 'Precisão',
        values: TIGER_INDICATOR_PRECISIONS,
      },
    ];
    this.styles[0].values![0].value = this.precision;
    this.lineNumber = options.lineNumber;
  }

  create(options: TTigerChartIndicatorCreateOptions) {
    this.baseChart = options.base;
    const candleSeries = this.getCandleSeries();
    if (candleSeries) {
      const paletteProvider = this.getCandlePaletteProvider(candleSeries);
      paletteProvider.useElephantBars = true;
    }
    this.setCandlePaletteElephantColor();
  }

  updatePoints(): void {
    //do nothing.
  }

  append(xValue: number, data: TUpdateData, fullData: TData) {
    this.data = fullData;
    this.updatePoints();
  }

  insertRange(xValues: number[], fullData: TData): void {
    this.data = fullData;
    this.updatePoints();
  }

  changeVisibility(): void {
    this.visible = !this.visible;
    //do nothing
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update(index: number, data: TUpdateData, fullData: TData) {
    this.updatePoints();
  }

  updateSettings(): void {
    this.onChange.next(null);
  }

  updateStyles(
    baseChart: TWebAssemblyChart,
    config: TTigerChartIndicatorRenderSeriesConfig
  ): void {
    if (config.propertyColor == 'positiveBarColor') {
      this.positiveBarColor = config.color;
    }
    if (config.propertyColor == 'negativeBarColor') {
      this.negativeBarColor = config.color;
    }
    this.setCandlePaletteElephantColor();
    this.onChange.next(null);
  }

  private setCandlePaletteElephantColor() {
    const candleSeries = this.getCandleSeries();
    if (candleSeries) {
      const paletteProvider = this.getCandlePaletteProvider(candleSeries);
      paletteProvider.setHighLightElephantBarsColor(
        this.positiveBarColor,
        this.negativeBarColor
      );
    }
  }

  delete() {
    this.usePaletteElephant();
  }

  usePaletteElephant(use: boolean = false) {
    const candleSeries = this.getCandleSeries();
    if (candleSeries) {
      const paletteProvider = this.getCandlePaletteProvider(candleSeries);
      paletteProvider.useElephantBars = use;
    }
  }

  setValue(property: keyof this, value: any) {
    const prop = this[property];
    if (typeof this[property] == 'number') {
      this[property] = +value as unknown as typeof prop;
    } else {
      this[property] = value;
    }
    this.updateSettings();
  }

  resetConfiguration(): void {
    setDefaultConfiguration(
      this,
      this.baseChart,
      this.options,
      this.settings,
      this.renderSeriesConfig,
      this.styles,
      this.type
    );
  }

  updateCandlePalleteProvider(elephantBars: IElephantCandles) {
    const candleSeries = this.getCandleSeries();
    if (candleSeries) {
      const paletteProvider = this.getCandlePaletteProvider(candleSeries);
      if (paletteProvider && paletteProvider instanceof CandlePaletteProvider) {
        paletteProvider.updateElephantBars(elephantBars);
      }
    }
  }

  private getCandleSeries() {
    return this.baseChart?.sciChartSurface.renderableSeries.getById(
      CANDLE_IDS.MAIN_SERIE
    );
  }

  private getCandlePaletteProvider(candleSeries: IRenderableSeries) {
    return candleSeries.paletteProvider as CandlePaletteProvider;
  }
}
