import { Component, Inject, OnInit } from '@angular/core';
import {
  RocketModalRef,
  RocketModalService,
  ROCKET_MODAL_DATA,
} from '@shared/rocket-components/components/index';
import { CONFIG_VIEW_AUCTION } from './config-view.contants';
import { IConfigView } from './config-view.interface';
import { ConfigViewService } from './config-view.service';

@Component({
  selector: 'app-config-header-modal',
  templateUrl: './config-header-modal.component.html',
  styles: [
    `
      .position-top {
        top: -14px;
      }
    `,
  ],
})
export class ConfigHeaderModalComponent
  extends RocketModalRef
  implements OnInit
{
  public configView!: IConfigView[];

  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: any,
    private _rocketModalService: RocketModalService,
    private configViewService: ConfigViewService
  ) {
    super(_rocketModalService);
  }

  ngOnInit(): void {
    if (this.data.isListAuction) {
      this.configView = [
        ...this.configViewService.getConfigView(),
        CONFIG_VIEW_AUCTION,
      ];
    } else {
      this.configView = this.configViewService.getConfigView();
    }
    this.configureFields();
  }

  private configureFields(): void {
    this.configView.forEach((group) => {
      let isCheck = true;
      group.columns.forEach((column) => {
        const field = this.data.columns.find(
          (c: any) => c.field === column.field
        );
        column.isCheck = field ? !field.hide : column.isCheck ?? true;
        isCheck &&= column.isCheck;
      });
      group.allCheck = isCheck;
    });
  }

  public onSave = () => this.close(this.data.columns);
  public onClose = () => this.close([]);

  public setVisibleOrInvisible(
    event: any,
    item: any,
    groupLabel: string
  ): void {
    item.isCheck = event;
    this.data.columns.find((column: any) => column.field == item.field).hide =
      !event;
    const group: any = this.configView.find(
      (column: any) => column.label == groupLabel
    );
    const isCheck = !!group.columns.find((column: any) => !column.isCheck);
    group.allCheck = !isCheck;
  }

  public selectAll(event: boolean, columns: any[]): void {
    columns.forEach((item: any) => {
      this.data.columns.find((column: any) => column.field == item.field).hide =
        !event;
      item.isCheck = event;
    });
  }
}
