/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Component, ElementRef } from '@angular/core';
import { AlertService } from '@shared/services/api/trademap/v1/alert.service';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  host: {
    '(document:click)': 'onClick($event)',
  },
  selector: 'app-alert-settings',
  template: `
    <div class="alert-settings bg-neutral-stronger">
      <ng-container *ngIf="isCompletedAlert; else alertControls">
        <rocket-button
          label="Reativar alerta"
          type="fill"
          (rocketClick)="handleOptionClick('REFRESH')"
          css="d-inline-flex align-items-center justify-content-start text-white fs-6 p-1 w-100 border-none fw-normal"
        >
          <fla-icon
            fla-icon
            css="mr-2 text-feedback-success"
            size="icon-size-micro"
            name="refresh"
          ></fla-icon>
        </rocket-button>
      </ng-container>
      <ng-template #alertControls>
        <rocket-button
          label="Editar alerta"
          type="fill"
          (rocketClick)="handleOptionClick('EDIT')"
          css="d-inline-flex align-items-center justify-content-start text-white fs-6 p-1 w-100 border-none fw-normal"
        >
          <fla-icon
            fla-icon
            css="mr-2 text-feedback-warning"
            size="icon-size-micro"
            name="edit"
          ></fla-icon>
        </rocket-button>

        <rocket-button
          label="Excluir alerta"
          type="fill"
          (rocketClick)="handleOptionClick('DELETE')"
          css="d-inline-flex align-items-center justify-content-start text-white fs-6 p-1 w-100 border-none fw-normal"
        >
          <fla-icon
            fla-icon
            css="mr-2 text-feedback-negative"
            size="icon-size-micro"
            name="delete"
          ></fla-icon>
        </rocket-button>
      </ng-template>
    </div>
  `,
  styles: [
    `
      .alert-settings {
        top: 26px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        min-width: 100px;
        border-radius: 4px !important;
      }

      ::ng-deep.border-none {
        border-radius: 0px !important;
      }
    `,
  ],
})
export class AlertSettingsComponent {
  public params!: ICellRendererParams;
  public isCompletedAlert: boolean = false;

  constructor(private _alertService: AlertService, private _eref: ElementRef) {}

  onClick(event: any) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.params.api.stopEditing();
      return;
    }
  }

  agInit(params: any): void {
    if (!params || !params.data) return;
    this.params = params;
    this.isCompletedAlert = params.data.cd_status === 'COMPLETED';
  }

  handleOptionClick(actionType: 'EDIT' | 'DELETE' | 'REFRESH'): void {
    this.params.api.stopEditing();
    this._alertService.displayAlertSetting(actionType, this.params.data);
  }
}
