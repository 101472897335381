<app-rent-header
  [hideGridContent]="hideGridContent"
  [updateCurrentSortField]="updateSortHeader"
  [component]="component"
  [isDesktop]="isDesktop"
  (fieldSortChanged)="handlerSortField($event)"
>
</app-rent-header>

<div
  class="w-100"
  [ngClass]="isDesktop ? 'component-height' : 'h-100'"
  #timesTrade
>
  <ng-container [ngSwitch]="componentContent">
    <ng-container *ngSwitchCase="componentContents.GRID">
      <app-rent-grid
        [gridRef]="refComponent"
        [sortByField]="sortByField"
        [isDesktop]="isDesktop"
        (gridRowClicked)="onSelectedRow($event)"
        (sortHeaderUpdated)="onSortChange($event)"
      ></app-rent-grid>
    </ng-container>

    <ng-container *ngSwitchCase="componentContents.INFOS">
      <rent-details
        [cdStock]="stockToGetBalance"
        (onBackToGrid)="handlerRentContent(false)"
      ></rent-details>
    </ng-container>
  </ng-container>
</div>
