import { ChangeDetectorRef, ElementRef } from '@angular/core';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';

export class CarouselHelper extends ReadStreamBase {
  cards: any = [];
  totalElements: number = 0;
  hideButtons: boolean = false;

  constructor(protected elRef: ElementRef, protected cdr: ChangeDetectorRef) {
    super();
  }

  handleNavigation(increase: boolean, itemList: ElementRef<HTMLElement>): void {
    const listElement = itemList?.nativeElement;

    let maxIndex = this.cards.length - 1;
    let minIndex = 0;
    let orderMax = -99999999999999;
    let orderMin = 99999999999999;
    Array.from(listElement.children).forEach(
      (value: any, currentIndex: number) => {
        if (+value.style.order > orderMax) {
          orderMax = +value.style.order;
          maxIndex = currentIndex;
        }
        if (+value.style.order <= orderMin) {
          orderMin = +value.style.order;
          minIndex = currentIndex;
        }
      }
    );

    listElement.scrollBy(0, 0);
    if (increase) {
      const child: any = listElement.children[minIndex];
      child.style.order = `${+orderMax + 1}`;
      return;
    }

    const child: any = listElement.children[maxIndex];
    child.style.order = `${orderMin - 1}`;
  }

  verifyShowArrows() {
    const carouselContent = this.elRef.nativeElement.querySelector(
      '.rt-carousel-container'
    )?.offsetWidth;
    const carouselElement =
      this.elRef.nativeElement.querySelector('.rt-carousel-item');
    if (!carouselElement) return;
    this.hideButtons =
      carouselContent > carouselElement.offsetWidth * this.totalElements;
    this.cdr.detectChanges();
  }
}
