<form [formGroup]="validateForm">
  <h3 class="mb-3">Criar uma conta</h3>
  <h5 class="mb-5">Digite o código enviado para {{ phoneNumber }}</h5>
  <div class="mb-3">
    <app-input-code
      #codeInput
      [codeLength]="6"
      (codeCompleted)="onCodeCompleted($event)"
    ></app-input-code>
    <h5 class="text-feedback-error" *ngIf="erroData.hasError">
      {{ erroData.errorMessage }}
    </h5>
  </div>
  <div
    *ngIf="isLoading"
    class="h-100 d-flex align-items-sm-center justify-content-center"
  >
    <div class="spinner-border" role="status"></div>
  </div>
  <section *ngIf="!isResendDisabled; else timerResend">
    <h5 *ngIf="!isRecoveryProcess || isSmsRecovery">Reenviar código por:</h5>
    <div class="d-flex" *ngIf="isRecoveryProcess && !isSmsRecovery">
      <rocket-button
        label="Reenviar código"
        type="outline"
        btnType="submit"
        css="col mr-2"
        (rocketClick)="resendCode()"
        [isDisabled]="isLoading"
        [loading]="isLoading"
      >
      </rocket-button>
    </div>
    <div class="d-flex" *ngIf="!isRecoveryProcess || isSmsRecovery">
      <rocket-button
        label="SMS"
        type="outline"
        btnType="submit"
        css="col mr-2 d-inline-flex align-items-center justify-content-center gap-1"
        (rocketClick)="reSendSMS()"
        [isDisabled]="isLoading || disableSendPincodeBtn"
        [loading]="isLoading"
      >
        <fla-icon size="icon-size-tiny" name="textsms"> </fla-icon>
      </rocket-button>
      <rocket-button
        label="WhatsApp"
        type="outline"
        btnType="submit"
        css="col ml-2 d-inline-flex align-items-center justify-content-center gap-1"
        (rocketClick)="reSendWhatsapp()"
        [isDisabled]="isLoading || disableSendPincodeBtn"
        [loading]="isLoading"
      >
        <fla-icon size="icon-size-tiny" name="whatsapp"> </fla-icon>
      </rocket-button>
    </div>
    <app-recaptcha
      #recaptcha
      size="invisible"
      (resolved)="onResolved($event)"
      (errored)="onError()"
    ></app-recaptcha>
  </section>
</form>
<ng-template #timerResend>
  <app-timer-resend
    [seconds]="resendTimeout"
    (timerCompleted)="onTimerCompleted()"
  ></app-timer-resend>
</ng-template>
