import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'fla-card-order[stock][qtdPrice][price][status][classStatus]',
  templateUrl: './card-order.component.html',
})
export class FlaCardOrderComponent {
  @ViewChild(TemplateRef) content!: TemplateRef<any>;
  @Input() stock!: string;
  @Input() qtdPrice!: string;
  @Input() description!: string;
  @Input() status!: string;
  @Input() price!: string;
  @Input() src!: string;
  @Input('css') customClass: string = '';
  @Input() classStatus: string = '';
}
