import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { INews, INewsTag } from '@core/interface/news-agency.interface';

@Component({
  selector: 'news-card-footer',
  templateUrl: './news-card-footer.component.html',
  styleUrls: ['./news-card-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsCardFooterComponent implements OnInit {
  @Input() news!: INews | null;
  @Input() selectedTag!: number | null;
  @Output() onTagSelected: EventEmitter<INewsTag> =
    new EventEmitter<INewsTag>();

  tagOffsetLeft = 0;
  tagOffsetRight = 4;

  get tagsOnScroll() {
    const { tagOffsetLeft, tagOffsetRight } = this;
    return this.news?.tags?.slice(tagOffsetLeft, tagOffsetRight);
  }

  get showScrollButtons() {
    if (!this.news || !this.news.tags) return false;

    return this.news.tags.length > 4;
  }

  constructor() {}

  ngOnInit() {}

  incrementTagOffset() {
    if (!this.news || !this.news?.tags) return;

    if (this.news.tags.length == this.tagOffsetRight) return;

    this.tagOffsetLeft += 1;
    this.tagOffsetRight += 1;
  }

  decrementTagOffset() {
    if (!this.news || !this.news?.tags) return;

    if (this.tagOffsetLeft == 0) return;

    this.tagOffsetLeft -= 1;
    this.tagOffsetRight -= 1;
  }

  ignoreClick(event: any) {
    event.stopPropagation();
  }

  selectTag(tag: INewsTag) {
    if (tag.id_agency_tag != this.selectedTag) this.onTagSelected.emit(tag);
  }
}
