import { FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { delay } from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  RocketModalRef,
  RocketModalService,
  ROCKET_MODAL_DATA,
} from '@shared/rocket-components/components/index';
import { HomeService } from '@modules/home/service/home.service';
import { ToastService } from '@services/toast.service';
import { IWorkSpace } from 'src/app/core/interface';
import { WorkspaceComponentService } from '@modules/home/service/workspace-component.service';

@Component({
  selector: 'app-modal-clone',
  template: `
    <rocket-modal>
      <rocket-modal-header [hideCloseButton]="true">
        <div>
          <h3 class="modal-title">Duplicar</h3>
          <small class="modal-subtitle"> #{{ data.workspace.name }} </small>
        </div>
      </rocket-modal-header>

      <app-rocket-modal-body>
        <span class="mb-2 d-block">
          Deseja duplicar a Workspace:
          <span class="fw-bold"> {{ data.workspace.name }} </span> ?
        </span>
        <form [formGroup]="editControl">
          <fla-input
            #workspaceName
            [placeholder]="data.workspace.name + ' (copy)'"
            formControlName="name"
            [maxLength]="80"
            [max]="80"
            [css]="editControl.get('name')!.value ? '' : 'invalid'"
          ></fla-input>
          <span
            class="bottom-0 fs-6 message-error position-absolute text-feedback-error"
          >
            {{ getErrors() }}
          </span>
        </form>
      </app-rocket-modal-body>

      <rocket-modal-footer>
        <rocket-button
          type="default"
          label="Cancelar"
          css="mr-2"
          type="fill"
          (rocketClick)="onClone()"
        ></rocket-button>

        <rocket-button
          btnType="submit"
          type="default"
          label="Salvar"
          css="mr-2"
          [isDisabled]="editControl.invalid"
          (rocketClick)="onSave()"
        ></rocket-button>
      </rocket-modal-footer>
    </rocket-modal>
  `,
  styles: [
    `
      :host(app-modal-clone) {
        ::ng-deep.invalid {
          .group-suffix-prefix.form-control {
            border-color: var(--vm-feedback-error) !important;
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalCloneComponent extends RocketModalRef implements OnInit {
  @ViewChild('workspaceName', { static: false }) public workspaceName!: any;
  editControl!: FormGroup;

  constructor(
    service: RocketModalService,
    private _homeService: HomeService,
    private _toasService: ToastService,
    @Inject(ROCKET_MODAL_DATA)
    public data: { workspace: IWorkSpace; isSecondModal?: boolean },
    private workspaceComponentService: WorkspaceComponentService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    super(service);
    this.createdForm();
  }

  ngOnInit(): void {
    this._focusOnFieldText();
    this.cdr.detectChanges();
  }

  private createdForm(): void {
    this.editControl = this.formBuilder.group({
      name: [
        {
          value: undefined,
        },
        { validators: [this.nameValidator] },
      ],
    });
  }

  private _focusOnFieldText(): void {
    setTimeout(() => {
      this.editControl.get('name')!.setValue('');
      document.getElementById(this.workspaceName.refId)?.focus();
      this.cdr.detectChanges();
    }, 400);
  }

  onSave() {
    if (this.editControl.invalid) return;
    this._homeService
      .cloneWorkspace(
        this.data.workspace.id,
        this.editControl.get('name')!.value
      )
      .pipe(delay(100))
      .subscribe((workspace) => {
        this.editControl.reset();
        workspace.active = true;
        this.workspaceComponentService.changeActiveWorkspace(workspace);
        this.workspaceComponentService.dispatchWorkspaceUpdate();
        this._toasService.showToast('success', 'Workspace clonada!');
        this.onClone(true, workspace);
      });
  }

  onClone(wsDuplicated: boolean = false, workspace?: IWorkSpace) {
    this.close({ wsDuplicated, workspace }, this.data.isSecondModal);
  }

  public getErrors(): string {
    const error: any = this.editControl.get('name')?.errors;
    if (!error) return '';
    if (error['error']) return error['error'];
    return '';
  }

  private nameValidator = (formGroup: FormGroup): ValidationErrors | null => {
    const message = formGroup.value ? '' : 'Campo obrigatório';
    return message
      ? {
          error: message,
        }
      : null;
  };
}
