import {
  Component,
  ElementRef,
  ChangeDetectionStrategy,
  Renderer2,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('root', { static: true }) root!: ElementRef;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private _renderer: Renderer2,
    private themePreferences: ThemePreferencesService
  ) {}

  ngOnInit() {
    this.themePreferences
      .themeActiveObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(this.changeTheme);
    if (location.pathname.includes('/public/')) return;
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
    this.themePreferences.themeActiveObservable().subscribe(this.changeTheme);
    if (location.pathname.includes('/public/')) return;
    this.removeDefaultContextMenu();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  removeDefaultContextMenu() {
    this._renderer.listen(
      this.root.nativeElement,
      'contextmenu',
      (event: MouseEvent) => {
        const target: any = event.target;
        target.id !== 'workspace_base' && event.preventDefault();
      }
    );
  }

  changeTheme = (theme: any) => {
    const htmlTag = document.body;
    if (htmlTag) {
      const classList = Array.from(htmlTag.classList);
      classList.forEach((cssClass) => htmlTag.classList.remove(cssClass));
      htmlTag.classList.add(theme);
    }
  };
}
