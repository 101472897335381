import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '@shared/services/rest';
import { Observable, catchError, map, of } from 'rxjs';
import { ICustodyChartInfos } from './interface/custody.interface';
import { newDateToVMDate } from 'src/app/utils/utils.functions';
import { IPerformancePeriod } from '@shared/components/performance-report/interface/performance-report.interface';
export const ALL_STOCKS_LABEL = 'Ativos'

@Injectable({
  providedIn: 'root',
})
export class CustodyService extends RestService {
  protected override _url: string = 'api/nitro-custody-ws/v1/custody';
  constructor(private http: HttpClient) {
    super(http);
  }

  public custody(
    period: IPerformancePeriod,
    operation: 'SWING' | 'DAY_TRADE' | 'DEFAULT',
    idBroker: number,
    cdStock: string = 'TOTAL'
  ): Observable<ICustodyChartInfos[]> {
    let query = `${
      period.value
    }?type_profit=${operation}&id_broker=${idBroker}&cd_stock=${
      cdStock === ALL_STOCKS_LABEL ? 'TOTAL' : cdStock
    }`;
    if (period.value === 'monthly') {
      query = `${query}&dt_first=${newDateToVMDate(
        period.startDtBeforeToday
      )}&dt_last=${newDateToVMDate(period.endDtBeforeToday)}`;
    }
    return this.get<ICustodyChartInfos[]>(query).pipe(
      catchError((error) => of(this.handlerServiceError(error))),
      map((response) => {
        if (!response?.data || !response?.data.length) throw response;
        return response.data;
      })
    );
  }
}

