import { ORDERS_HISTORY_ELEMENT_IDS } from '@core/components/orders-history-core/enum/orders-history.enum';
import { GENERIC_INTRO_STEPS } from './gerenric.steps';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';

const ORDERS_HISTORY_INTRO_STEPS = {
  INITIAL: (component: Element) => ({
    title: 'Conheça o Ordens e Posição 🖖',
    intro:
      'Aqui, você consegue criar sua lista de acompanhamento de ativos ou visualizar nossas listas pré-definidas, como maiores altas, maiores baixas e volume. <br /><br /> Use as setas do teclado ou clique no próximo para começar. ',
    element: component,
  }),
  TAB_INTRADAY: (component: Element) => ({
    title: '{DayTrade}',
    intro:
      'Essa aba permite visualizar o desempenho das posições abertas ao longo do dia. Nela, é possível verificar informações como preço médio, preço atual e lucro ou perda intradia.',
    element: component.querySelector(`#${ORDERS_HISTORY_ELEMENT_IDS.INTRADAY}`),
    position: 'bottom',
    preChange: function () {
      this.element = component.querySelector(
        `#${ORDERS_HISTORY_ELEMENT_IDS.INTRADAY}`
      );
      this.position = 'bottom';
      this.title = DaytradeService.getTextWithTradeMode(this.title);
    },
  }),
  TAB_SWING: (component: Element) => ({
    title: '{Swing}',
    intro:
      'A aba {Swing} permite visualizar suas posições de {Swing}, que são posições mantidas por vários dias ou semanas. Nela, também é possível verificar informações como preço médio, preço atual e lucro ou perda do {Swing}.',
    element: component.querySelector(`#${ORDERS_HISTORY_ELEMENT_IDS.SWING}`),
    position: 'bottom',
    preChange: function () {
      this.element = component.querySelector(
        `#${ORDERS_HISTORY_ELEMENT_IDS.SWING}`
      );
      this.position = 'bottom';
      this.title = DaytradeService.getTextWithTradeMode(this.title);
      this.intro = DaytradeService.getTextWithTradeMode(this.intro);
    },
  }),
  TAB_POSITION: (component: Element) => ({
    title: 'Posições',
    intro:
      'Já em Posições, você monitora seus investimentos abertas, tanto {DayTrade} quanto {Swing}, em um único local. Também é possível visualizar informações de preço médio, preço atual e lucro ou perda total.',
    element: component.querySelector(`#${ORDERS_HISTORY_ELEMENT_IDS.POSITION}`),
    position: 'bottom',
    preChange: function () {
      this.element = component.querySelector(
        `#${ORDERS_HISTORY_ELEMENT_IDS.POSITION}`
      );
      this.position = 'bottom';
      this.intro = DaytradeService.getTextWithTradeMode(this.intro);
    },
  }),
  TAB_OPEN_ORDERS: (component: Element) => ({
    title: 'Ordens Abertas',
    intro:
      'Aqui você verá as ordens de compra ou venda de ativos que foram enviadas, mas ainda não foram executadas. Se quiser, é possível editar ou cancelar uma ordem.',
    element: component.querySelector(
      `#${ORDERS_HISTORY_ELEMENT_IDS.OPEN_ORDERS}`
    ),
    position: 'bottom',
    preChange: function () {
      this.element = component.querySelector(
        `#${ORDERS_HISTORY_ELEMENT_IDS.OPEN_ORDERS}`
      );
      this.position = 'bottom';
    },
  }),
  TAB_EXEC_ORDERS: (component: Element) => ({
    title: 'Ordens Executadas',
    intro:
      "A aba 'Executadas' apresenta as ordens de compra ou venda de ativos que foram totalmente executadas.",
    element: component.querySelector(
      `#${ORDERS_HISTORY_ELEMENT_IDS.EXEC_ORDERS}`
    ),
    position: 'bottom',
    preChange: function () {
      this.element = component.querySelector(
        `#${ORDERS_HISTORY_ELEMENT_IDS.EXEC_ORDERS}`
      );
      this.position = 'bottom';
    },
  }),
  TAB_CLOSED_ORDERS: (component: Element) => ({
    title: 'Ordens Fechadas',
    intro:
      "Na aba 'Fechadas', exibimos as ordens de ativos que foram concluídas, com os seguintes status: rejeitada, cancelada, executada ou parcialmente executada.",
    element: component.querySelector(
      `#${ORDERS_HISTORY_ELEMENT_IDS.CLOSED_ORDERS}`
    ),
    position: 'bottom',
    preChange: function () {
      this.element = component.querySelector(
        `#${ORDERS_HISTORY_ELEMENT_IDS.CLOSED_ORDERS}`
      );
      this.position = 'bottom';
    },
  }),
  TAB_ALL_ORDERS: (component: Element) => ({
    title: 'Todas',
    intro:
      "A aba 'Todas' oferece uma visão geral das suas ordens de compra e venda de ativos, independentemente do seu estado atual.",
    element: component.querySelector(
      `#${ORDERS_HISTORY_ELEMENT_IDS.ALL_ORDERS}`
    ),
    position: 'bottom',
    preChange: function () {
      this.element = component.querySelector(
        `#${ORDERS_HISTORY_ELEMENT_IDS.ALL_ORDERS}`
      );
      this.position = 'bottom';
    },
  }),
  TAB_ALERT: (component: Element) => ({
    title: 'Alertas',
    intro:
      'Nessa aba é possível visualizar todos os alertas criados, juntamente com suas configurações. Além disso, você tem a opção de editar, reativar ou excluir um alerta existente.',
    element: component.querySelector(`#${ORDERS_HISTORY_ELEMENT_IDS.ALERT}`),
    position: 'bottom',
    preChange: function () {
      this.element = component.querySelector(
        `#${ORDERS_HISTORY_ELEMENT_IDS.ALERT}`
      );
      this.position = 'bottom';
    },
  }),
  CONTEXT: () => ({
    enum: 'CONTEXT_MENU',
    title: 'Funcionalidades',
    intro:
      'Ao clicar com o botão direito dentro de Ordens & Posições, você verá as configurações de funcionalidades disponíveis. Vamos explicar cada uma delas nos próximos passos.',
    element: document.querySelector(
      `#${ORDERS_HISTORY_ELEMENT_IDS.MENU_CONTEXT}`
    ),
    position: 'right',
    preChange: function () {
      this.element = document.querySelector(
        `#${ORDERS_HISTORY_ELEMENT_IDS.MENU_CONTEXT}`
      );
      this.position = 'right';
    },
  }),
  CONTEXT_HIDE_COLUMN: () => ({
    enum: 'CONTEXT',
    title: 'Ocultar Coluna',
    intro:
      'Aqui, você tem a opção de ocultar uma coluna que não deseja mais visualizar no grid.',
    element: document.querySelector(
      `#${ORDERS_HISTORY_ELEMENT_IDS.HIDE_COLUMN}`
    ),
    position: 'right',
    preChange: function () {
      this.element = document.querySelector(
        `#${ORDERS_HISTORY_ELEMENT_IDS.HIDE_COLUMN}`
      );
      this.position = 'right';
    },
  }),
  CONTEXT_ORDERS: () => ({
    enum: 'CONTEXT',
    title: 'Editar e cancelar',
    intro:
      'Com essas opções, você pode editar ou cancelar uma ordem. Ao editar, é possível ajustar a quantidade, preço, validade, entre outros detalhes. Já ao cancelar a ordem, ela será imediatamente removida da lista de ordens.',
    element: document.querySelector(
      `#${ORDERS_HISTORY_ELEMENT_IDS.EDIT_AND_CANCEL_ORDER}`
    ),
    position: 'right',
    preChange: function () {
      this.element = document.querySelector(
        `#${ORDERS_HISTORY_ELEMENT_IDS.EDIT_AND_CANCEL_ORDER}`
      );
      this.position = 'right';
    },
  }),
  CONTEXT_PREFERENCES: () => ({
    enum: 'CONTEXT',
    title: 'Preferências',
    intro:
      'Aqui, você pode personalizar a exibição das abas dentro do componente, escolhendo quais deseja ocultar ou exibir.',
    element: document.querySelector(
      `#${ORDERS_HISTORY_ELEMENT_IDS.PREFERENCES}`
    ),
    position: 'right',
    preChange: function () {
      this.element = document.querySelector(
        `#${ORDERS_HISTORY_ELEMENT_IDS.PREFERENCES}`
      );
      this.position = 'right';
    },
  }),
  CONTEXT_CLEAR_FILTERS: () => ({
    enum: 'CONTEXT',
    title: 'Limpar filtros',
    intro:
      'Nessa opção, você pode remover todos os filtros que estão aplicados na aba selecionada.',
    element: document.querySelector(
      `#${ORDERS_HISTORY_ELEMENT_IDS.CLEAR_FILTERS}`
    ),
    position: 'right',
    preChange: function () {
      this.element = document.querySelector(
        `#${ORDERS_HISTORY_ELEMENT_IDS.CLEAR_FILTERS}`
      );
      this.position = 'right';
    },
  }),
};

export const ORDERS_HISTORY_STEPS = (component: Element) => [
  ORDERS_HISTORY_INTRO_STEPS.INITIAL(component),
  ORDERS_HISTORY_INTRO_STEPS.TAB_INTRADAY(component),
  ORDERS_HISTORY_INTRO_STEPS.TAB_SWING(component),
  ORDERS_HISTORY_INTRO_STEPS.TAB_POSITION(component),
  ORDERS_HISTORY_INTRO_STEPS.TAB_OPEN_ORDERS(component),
  ORDERS_HISTORY_INTRO_STEPS.TAB_EXEC_ORDERS(component),
  ORDERS_HISTORY_INTRO_STEPS.TAB_CLOSED_ORDERS(component),
  ORDERS_HISTORY_INTRO_STEPS.TAB_ALL_ORDERS(component),
  ORDERS_HISTORY_INTRO_STEPS.TAB_ALERT(component),
  ORDERS_HISTORY_INTRO_STEPS.CONTEXT(),
  ORDERS_HISTORY_INTRO_STEPS.CONTEXT_HIDE_COLUMN(),
  ORDERS_HISTORY_INTRO_STEPS.CONTEXT_ORDERS(),
  ORDERS_HISTORY_INTRO_STEPS.CONTEXT_PREFERENCES(),
  ORDERS_HISTORY_INTRO_STEPS.CONTEXT_CLEAR_FILTERS(),
  GENERIC_INTRO_STEPS.TOUR_END,
];
