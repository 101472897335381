import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { deepClone } from '@shared/rocket-components/utils';
import {
  DrawTools,
  StyleTool,
} from '@shared/tiger-chart/draw-tools/draw-tools.interface';
import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { Observable, Subject, Subscription, delay } from 'rxjs';
import { TOOLS_TYPE_DRAW } from '../tiger-chart-tools.enum';
import { TIGER_CHART_TOOL } from '../tiger-chart-tools.interface';
import { ColorsBaseService } from './colors-base.service';
import { ConfigColorpickerComponent } from './config-colorpicker/config-colorpicker.component';
import { ModalModelSaveComponent } from './modal-model-save/modal-model-save.component';
import { CONFIGS } from './toolbar-draw.const';
import {
  CONFIG,
  TYPE_CLICK,
  TYPE_CONFIG,
  TYPE_EVENT,
  TYPE_VALUE,
} from './toolbar-draw.enum';
import {
  color,
  ToolbarDrawConfigs,
  ToolbarModel,
} from './toolbar-draw.interface';
import { ToolbarDrawService } from './toolbar-draw.service';
import { RocketModalService } from '@shared/rocket-components/components/index';

@Component({
  selector: 'app-toolbar-draw',
  templateUrl: './toolbar-draw.component.html',
  styleUrls: ['./toolbar-draw.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarDrawComponent implements OnDestroy {
  models: ToolbarModel[] = [];
  configs = deepClone(CONFIGS) as Array<ToolbarDrawConfigs>;
  typeConfig = TYPE_CONFIG;
  typeValue = TYPE_VALUE;
  parentRef!: string;
  selectedDraw!: DrawTools;
  selectedTool!: TIGER_CHART_TOOL;
  resetTool: boolean = false;
  dragPosition!: { x: number; y: number };
  @ViewChild('configColorPicker', { static: false })
  configColorPicker!: ConfigColorpickerComponent;
  drawToolsService!: DrawToolsService;
  private _onClose$ = new Subject<ToolbarDrawConfigs>();
  private resetActiveConfigs$!: Subscription;
  private _resetActiveConfigs$ = new Subject<void>();
  public _cdr!: ChangeDetectorRef;
  get onClose(): Observable<ToolbarDrawConfigs | undefined> {
    return this._onClose$.asObservable();
  }

  constructor(
    private _rocketModalService: RocketModalService,
    private customPreferencesService: CustomPreferencesService,
    private colorsBaseService: ColorsBaseService,
    private toolbarDrawService: ToolbarDrawService,
    cdr: ChangeDetectorRef
  ) {
    this._cdr = cdr;
    this.resetActiveConfigs$ = this._resetActiveConfigs$
      .pipe(delay(100))
      .subscribe(() => {
        this.resetTool = false;
        this._cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this._onClose$.complete();
    this.resetActiveConfigs();
    this.resetActiveConfigs$ && this.resetActiveConfigs$.unsubscribe();
    const config = this.getConfigModel();
    if (config && config.options) {
      config.options.splice(3, config.options.length);
    }
  }

  selectConfig(config: ToolbarDrawConfigs) {
    if (!config.active) {
      this.resetActiveConfigs();
    }
    if (config.click && config.click === TYPE_CLICK.CONFIG) {
      return;
    }
    config.active = !config.active;
  }

  resetActiveConfigs() {
    if (!this.configColorPicker) {
      return;
    }
    this.resetTool = true;
    this.configColorPicker.isNewColor = false;
    this.configs
      .filter((config) => config.active)
      .map((config) => {
        config.active = false;
      });
    this._cdr.detectChanges();
    this._resetActiveConfigs$.next();
  }

  selectOption(option: ToolbarDrawConfigs, config?: ToolbarDrawConfigs) {
    const style: StyleTool = {};
    if (config && config.selected) {
      config.selected = deepClone(option);
      config.selected!!.labelHtml = option.labelHtmlShow
        ? option.labelHtmlShow
        : option.labelHtml;
    }
    if (option.typeValue) {
      if (option.typeValue === TYPE_VALUE.STROKE_WIDTH) {
        style.textSize = option.value;
      }
      if (option.typeValue === TYPE_VALUE.STROKE_THICKNESS) {
        style.strokeThickness = option.value;
      }
      if (option.typeValue === TYPE_VALUE.STROKE_DASHARRAY) {
        style.strokeDashArray = option.value;
      }
      if (option.typeValue === TYPE_VALUE.MODELS) {
        if (option.value === 'RESET') {
          this.toolbarDrawService.drawModelsReset(
            this.selectedDraw,
            this.selectedTool
          );
          this.resetTool = true;
          this.setInfoConfig();
          this._cdr.detectChanges();
          this._resetActiveConfigs$.next();
        }
        if (option.value === 'SAVE') {
          this.toolbarDrawService.drawModelsSave(
            this.selectedTool,
            this.models,
            this.configs
          );
        }
        if (option.value === 'UPDATE_DRAW') {
          const selectedModel = this.models.find(
            (model) => model.label === option.title
          );
          if (selectedModel) {
            const styleUpdate: StyleTool = {
              typeColorUpdate: this.selectedTool.typeDraw,
            };
            if (selectedModel.color) {
              const rgbaColor = this.colorsBaseService.hex2Rgba(
                selectedModel.color
              );
              const rgbColor = this.colorsBaseService.rgba2rgb(rgbaColor);
              const hexColor = this.colorsBaseService.rgba2hex(rgbColor);
              const color: color = {
                rgbColor,
                hexColor,
                rgbaHexColor: selectedModel.color,
                rgbaColor: rgbaColor,
                selected: true,
              };
              styleUpdate.color = color;
            }
            if (selectedModel.centerLineColor) {
              const rgbaColorLineCenter = this.colorsBaseService.hex2Rgba(
                selectedModel.centerLineColor
              );
              const rgbColorLineCenter =
                this.colorsBaseService.rgba2rgb(rgbaColorLineCenter);
              const hexColorLineCenter =
                this.colorsBaseService.rgba2hex(rgbColorLineCenter);
              const centerLineColor: color = {
                rgbColor: rgbColorLineCenter,
                hexColor: hexColorLineCenter,
                rgbaHexColor: selectedModel.centerLineColor,
                rgbaColor: rgbaColorLineCenter,
                selected: true,
              };
              styleUpdate.centerLineColor = centerLineColor;
              const rgbaHexCenterLineColor =
                this.colorsBaseService.getOpacityHexByRGB(
                  this.selectedTool,
                  styleUpdate
                );
              const configurationOptions =
                this.selectedTool.configurationOptions!!;
              const centerLineConfig = configurationOptions.find(
                (config) =>
                  config.typeColorUpdate &&
                  config.typeColorUpdate === TOOLS_TYPE_DRAW.CENTER_LINE
              );
              if (!centerLineConfig) {
                styleUpdate.centerLineColor.rgbaHexColor =
                  rgbaHexCenterLineColor;
              }
            }
            if (selectedModel.backgroundColor) {
              const rgbaColorBackground = this.colorsBaseService.hex2Rgba(
                selectedModel.backgroundColor
              );
              const rgbColorBackground =
                this.colorsBaseService.rgba2rgb(rgbaColorBackground);
              const hexColorBackground =
                this.colorsBaseService.rgba2hex(rgbColorBackground);
              const backgroundColor: color = {
                rgbColor: rgbColorBackground,
                hexColor: hexColorBackground,
                rgbaHexColor: selectedModel.backgroundColor,
                rgbaColor: rgbaColorBackground,
                selected: true,
              };
              styleUpdate.backgroundColor = backgroundColor;
              const rgbaHexBackgroundColor =
                this.colorsBaseService.getOpacityHexByRGB(
                  this.selectedTool,
                  styleUpdate
                );
              const configurationOptions =
                this.selectedTool.configurationOptions!!;
              const backgroundConfig = configurationOptions.find(
                (config) =>
                  config.typeColorUpdate &&
                  config.typeColorUpdate === TOOLS_TYPE_DRAW.BACKGROUND
              );
              if (!backgroundConfig) {
                styleUpdate.backgroundColor.rgbaHexColor =
                  rgbaHexBackgroundColor;
              }
            }
            if (selectedModel.textColor) {
              const rgbaColorText = this.colorsBaseService.hex2Rgba(
                selectedModel.textColor
              );
              const rgbColorText =
                this.colorsBaseService.rgba2rgb(rgbaColorText);
              const hexColorText =
                this.colorsBaseService.rgba2hex(rgbaColorText);
              const textColor: color = {
                rgbColor: rgbColorText,
                hexColor: hexColorText,
                rgbaHexColor: selectedModel.textColor,
                rgbaColor: rgbaColorText,
                selected: true,
              };
              styleUpdate.textColor = textColor;
              const rgbaHexTextColor =
                this.colorsBaseService.getOpacityHexByRGB(
                  this.selectedTool,
                  styleUpdate
                );
              const configurationOptions =
                this.selectedTool.configurationOptions!!;
              const textConfig = configurationOptions.find(
                (config) =>
                  config.typeColorUpdate &&
                  config.typeColorUpdate === TOOLS_TYPE_DRAW.TEXT
              );
              if (!textConfig) {
                styleUpdate.textColor.rgbaHexColor = rgbaHexTextColor;
              }
            }
            if (selectedModel.outlineColor) {
              const rgbaColorText = this.colorsBaseService.hex2Rgba(
                selectedModel.outlineColor
              );
              const rgbColorOutline =
                this.colorsBaseService.rgba2rgb(rgbaColorText);
              const hexColorOutline =
                this.colorsBaseService.rgba2hex(rgbaColorText);
              const outlineColor: color = {
                rgbColor: rgbColorOutline,
                hexColor: hexColorOutline,
                rgbaHexColor: selectedModel.outlineColor,
                rgbaColor: rgbaColorText,
                selected: true,
              };
              styleUpdate.outlineColor = outlineColor;
              const rgbaHexOutlineColor =
                this.colorsBaseService.getOpacityHexByRGB(
                  this.selectedTool,
                  styleUpdate
                );
              const configurationOptions =
                this.selectedTool.configurationOptions!!;
              const outlineConfig = configurationOptions.find(
                (config) =>
                  config.typeColorUpdate &&
                  config.typeColorUpdate === TOOLS_TYPE_DRAW.OUTLINE
              );
              if (!outlineConfig) {
                styleUpdate.outlineColor.rgbaHexColor = rgbaHexOutlineColor;
              }
            }
            styleUpdate.strokeDashArray = selectedModel.strokeDashArray;
            styleUpdate.strokeThickness = selectedModel.strokeThickness;
            styleUpdate.textBold = selectedModel.textBold;
            styleUpdate.textItalic = selectedModel.textItalic;
            styleUpdate.textSize = selectedModel.textSize;
            styleUpdate.useTextOutline = selectedModel.useTextOutline;
            styleUpdate.useTextBackground = selectedModel.useTextBackground;
            this.selectedTool.color = selectedModel.color;
            this.selectedTool.backgroundColor = selectedModel.backgroundColor;
            this.selectedTool.outlineColor = selectedModel.outlineColor;
            this.selectedTool.textColor = selectedModel.textColor;
            this.selectedTool.textBold = selectedModel.textBold;
            this.selectedTool.textItalic = selectedModel.textItalic;
            this.selectedTool.textSize = selectedModel.textSize;
            this.selectedTool.strokeDashArray = selectedModel.strokeDashArray;
            this.selectedTool.lineThickness = selectedModel.strokeThickness;
            this.selectedTool.isMultiColors = false;
            this.drawToolsService.updateStyleDraw(
              this.selectedDraw,
              this.selectedTool,
              styleUpdate,
              false
            );
            this._cdr.detectChanges();
            this.setInfoConfig();
          }
        }
        return;
      }
      this.drawToolsService.updateStyleDraw(
        this.selectedDraw,
        this.selectedTool,
        style
      );
    }
  }

  toolbarClick(tool: ToolbarDrawConfigs) {
    if (!tool.click) {
      return;
    }
    if (tool.click === TYPE_CLICK.DELETE) {
      this.drawToolsService.toolEvent$.next({
        componentRef: this.parentRef,
        tool: this.selectedTool,
        typeEvent: TYPE_EVENT.DELETE,
        countStepClick: this.selectedTool.stepClick,
      });
      this._onClose$.next(tool);
    }
  }

  selectedColor(event: { color: color; typeColorUpdate?: TOOLS_TYPE_DRAW }) {
    if (event.typeColorUpdate) {
      if (event.typeColorUpdate === TOOLS_TYPE_DRAW.LINE) {
        const bg = document.querySelector('.colorBg .color')!! as HTMLElement;
        bg.style.background = '';
        bg.style.backgroundColor = event.color.rgbaColor;
        this.selectedTool.color = event.color.rgbaHexColor;
      }
      if (event.typeColorUpdate === TOOLS_TYPE_DRAW.CENTER_LINE) {
        const centerline = document.querySelector(
          '.colorBg-centerline .color'
        )!! as HTMLElement;
        centerline.style.background = '';
        centerline.style.backgroundColor = event.color.rgbaColor;
        this.selectedTool.centerLineColor = event.color.rgbaHexColor;
      }
      if (event.typeColorUpdate === TOOLS_TYPE_DRAW.BACKGROUND) {
        const background = document.querySelector(
          '.colorBg-background .color'
        )!! as HTMLElement;
        background.style.background = '';
        background.style.backgroundColor = event.color.rgbaColor;
        this.selectedTool.backgroundColor = event.color.rgbaHexColor;
      }
      if (event.typeColorUpdate === TOOLS_TYPE_DRAW.TEXT) {
        const text = document.querySelector(
          '.colorBg-text .color'
        )!! as HTMLElement;
        text.style.background = '';
        text.style.backgroundColor = event.color.rgbaColor;
        this.selectedTool.textColor = event.color.rgbaHexColor;
      }
    }
    const style: StyleTool = {
      color:
        event.typeColorUpdate === TOOLS_TYPE_DRAW.LINE
          ? event.color
          : undefined,
      centerLineColor:
        event.typeColorUpdate === TOOLS_TYPE_DRAW.CENTER_LINE
          ? event.color
          : undefined,
      backgroundColor:
        event.typeColorUpdate === TOOLS_TYPE_DRAW.BACKGROUND
          ? event.color
          : undefined,
      textColor:
        event.typeColorUpdate === TOOLS_TYPE_DRAW.TEXT
          ? event.color
          : undefined,
      typeColorUpdate: event.typeColorUpdate,
    };
    this.drawToolsService.updateStyleDraw(
      this.selectedDraw,
      this.selectedTool,
      style,
      true
    );
    this._cdr.detectChanges();
  }

  setInitConfig() {
    this.setInfoConfig();
    this.models = this.drawToolsService.getModels(this.selectedTool);
    const config = this.getConfigModel();
    if (config && config.options) {
      this.models.forEach((model) => {
        config.options!!.push({
          title: model.label,
          hint: '',
          typeValue: TYPE_VALUE.MODELS,
          value: 'UPDATE_DRAW',
          canDelete: true,
        });
      });
    }
  }

  setInfoConfig() {
    if (this.selectedTool.color) {
      const bg = document.querySelector('.colorBg .color')!! as HTMLElement;
      if (bg) {
        if (this.selectedTool.isMultiColors) {
          this.resetActiveConfigs();
          bg.style.background = `linear-gradient(270deg,#ff2e00,#ffe600 27.27%,#61ff00 50%,#00c2ff 72.44%,#8f00ff)`;
          bg.style.backgroundColor = '';
        } else {
          let color = this.selectedTool.color;
          if (this.selectedTool.alwaysTransparent) {
            const lineChild = this.selectedTool.children?.find(
              (child) => !child.isAux && child.typeDraw === TOOLS_TYPE_DRAW.LINE
            );
            if (lineChild) {
              color = lineChild.color!!;
            }
          }
          bg.style.background = '';
          bg.style.backgroundColor = color;
        }
      }
    }
    if (this.selectedTool.centerLineColor) {
      const centerline = document.querySelector(
        '.colorBg-centerline .color'
      ) as HTMLElement;
      if (centerline) {
        if (this.selectedTool.isMultiCenterLineColors) {
          this.resetActiveConfigs();
          centerline.style.background = `linear-gradient(270deg,#ff2e00,#ffe600 27.27%,#61ff00 50%,#00c2ff 72.44%,#8f00ff)`;
          centerline.style.backgroundColor = '';
        } else {
          centerline.style.background = '';
          centerline.style.backgroundColor = this.selectedTool.centerLineColor;
        }
      }
    }
    if (this.selectedTool.backgroundColor) {
      const background = document.querySelector(
        '.colorBg-background .color'
      ) as HTMLElement;
      if (background) {
        if (this.selectedTool.isMultiBackgroundColors) {
          this.resetActiveConfigs();
          background.style.background = `linear-gradient(270deg,#ff2e00,#ffe600 27.27%,#61ff00 50%,#00c2ff 72.44%,#8f00ff)`;
          background.style.backgroundColor = '';
        } else {
          background.style.background = '';
          background.style.backgroundColor = this.selectedTool.backgroundColor;
        }
      }
    }
    if (this.selectedTool.textColor) {
      const text = document.querySelector(
        '.colorBg-text .color'
      ) as HTMLElement;
      if (text) {
        if (this.selectedTool.isMultiTextColors) {
          this.resetActiveConfigs();
          text.style.background = `linear-gradient(270deg,#ff2e00,#ffe600 27.27%,#61ff00 50%,#00c2ff 72.44%,#8f00ff)`;
          text.style.backgroundColor = '';
        } else {
          text.style.background = '';
          text.style.backgroundColor = this.selectedTool.textColor;
        }
      }
    }
    if (this.selectedTool.lineThickness) {
      const config = this.configs.find(
        (config) =>
          config.typeValue && config.typeValue === TYPE_VALUE.STROKE_THICKNESS
      );
      if (config && config.options) {
        const option = config.options.find(
          (option) => option.value === this.selectedTool.lineThickness
        );
        if (option) {
          config.selected = option;
        }
      }
    }
    if (this.selectedTool.strokeDashArray) {
      const config = this.configs.find(
        (config) =>
          config.typeValue && config.typeValue === TYPE_VALUE.STROKE_DASHARRAY
      );
      if (config && config.options) {
        const option = config.options.find(
          (option) =>
            option.value.toString() ===
            this.selectedTool.strokeDashArray!!.toString()
        );
        if (option) {
          config.selected = deepClone(option);
          if (config.selected) {
            config.selected.labelHtml = option.labelHtmlShow;
          }
        }
      }
    }
    if (this.selectedTool.textSize) {
      const config = this.configs.find(
        (config) =>
          config.typeValue && config.typeValue === TYPE_VALUE.STROKE_WIDTH
      );
      if (config && config.options) {
        const option = config.options.find(
          (option) =>
            option.value.toString() === this.selectedTool.textSize!!.toString()
        );
        if (option) {
          config.selected = deepClone(option);
        }
      }
    }
  }

  private getConfigModel(): ToolbarDrawConfigs | undefined {
    return this.configs.find((c) => c.typeConfiguration === CONFIG.MODELS);
  }

  removeModel(e: MouseEvent, model: ToolbarDrawConfigs) {
    e.stopPropagation();
    const ref = this._rocketModalService.open(ModalModelSaveComponent, {
      centered: true,
      backdrop: true,
      keyboard: true,
      scrollable: false,
      data: {
        tool: this.selectedTool,
        models: this.models,
        configs: this.configs,
        isRemove: true,
        model,
      },
      css: 'modal-sized',
    });
    ref.afterDismissed.subscribe((res) => {
      if (res && res.removeModel) {
        const models = this.drawToolsService.getModels(this.selectedTool);
        const modelIndex = models.findIndex((m) => m.label === model.title);
        const config = this.getConfigModel();
        if (config && config.options) {
          const optionIndex = config.options.findIndex(
            (option) => option.title === model.title
          );
          if (optionIndex > -1) {
            config.options.splice(optionIndex, 1);
          }
        }
        if (modelIndex > -1) {
          models.splice(modelIndex, 1);
          const drawModels = this.drawToolsService.getDrawModels();
          drawModels[this.selectedTool.codTool] = models;
          this.customPreferencesService.drawToolModel =
            JSON.stringify(drawModels);
          this.models = models;
        }
      }
    });
  }
}
