import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  Type,
  ViewChild,
} from '@angular/core';
import {
  RocketModalRef,
  RocketModalService,
  ROCKET_MODAL_DATA,
  RocketIModal,
} from '@shared/rocket-components/components';
import { StockService } from '@services/api/trademap/v1/stock.service';
import { IStockTypeConfig } from 'src/app/core/interface';
import { ToastService } from '@shared/services';
import { Dictionary } from '@core/models';
import { IAPIServiceResponse } from '@shared/services/api/trademap/v1/interface/apiResponse';

@Component({
  selector: 'app-stock-search-config-modal',
  templateUrl: './stock-search-config-modal.component.html',
  styleUrls: ['./stock-search-config-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockSearchConfigModalComponent
  extends RocketModalRef
  implements OnInit
{
  stockTypes: IStockTypeConfig[] = [];
  stockTypesDict = new Dictionary<IStockTypeConfig>();
  hiddenStockTypesList: string[] = [];
  stockTypesOrder: string[] = [];
  isLoading = true;
  requisitionsStatus = { list: false, config: false, order: false };

  @ViewChild('placeholder') placeholder!: ElementRef;

  constructor(
    @Inject(ROCKET_MODAL_DATA)
    public data: {
      component: Type<RocketModalRef>;
      settings: RocketIModal;
      isSecondModal?: boolean;
    },
    private _elementRef: ElementRef,
    private _rocketModalService: RocketModalService,
    private _stockService: StockService,
    private _toastService: ToastService,
    private _cdr: ChangeDetectorRef
  ) {
    super(_rocketModalService);
  }

  @HostListener('keyup.esc', ['$event'])
  onEvent(): void {
    this.onCloseModal();
  }

  ngOnInit(): void {
    this._init();
  }

  public onCloseModal(stocks: string[] = []): void {
    this.close(stocks, Boolean(this.data.isSecondModal));
  }

  drop(event: any) {
    this.stockTypes.splice(
      event.container.data.index,
      0,
      this.stockTypes.splice(event.previousContainer.data.index, 1)[0]
    );

    this._cdr.detectChanges();
  }

  changeHiddenStatus(event: any, item: IStockTypeConfig) {
    item.hidden = !item.hidden;
    event.srcElement.parentElement.blur();
    this._cdr.detectChanges();
  }

  onSave() {
    let list: string[] = [];
    this.stockTypes.forEach((type: IStockTypeConfig) => {
      type?.hidden && list.push(type.type);
    });
    if (!list.length) list = ['TODOS'];
    this._stockService
      .saveHiddenStockTypesList(list)
      .subscribe((data: IAPIServiceResponse) => {
        if (!data || !data.success) {
          this._toastService.showToast(
            'error',
            data?.message ||
              'Não foi possivel salvar as alterações! Por favor, tente novamente.'
          );
          return;
        }

        this.onCloseModal();
      });

    this._stockService
      .saveStockTypesOrder(
        this.stockTypes.map((type: IStockTypeConfig) => type?.type)
      )
      .subscribe((data: IAPIServiceResponse) => {
        if (!data || !data.success) {
          this._toastService.showToast(
            'error',
            data?.message ||
              'Não foi possivel salvar as alterações! Por favor, tente novamente.'
          );
          return;
        }
      });
  }

  private _init(): void {
    this.isLoading = true;
    this._stockService.getHiddenStockTypesList().subscribe((data: string[]) => {
      this.hiddenStockTypesList = data;
      this.requisitionsStatus.config = true;
      this._processResponses();
    });

    this._stockService.getStockTypeOrder().subscribe((data: string[]) => {
      this.stockTypesOrder = data;
      this.requisitionsStatus.order = true;
      this._processResponses();
    });
    this._stockService
      .getStockTypesList()
      .subscribe((data: IStockTypeConfig[] | undefined) => {
        if (!data) {
          this._toastService.showToast(
            'error',
            'Não foi possivel listar os tipos de ativo! Por favor, tente novamente.'
          );
          return;
        }
        this.stockTypesDict.bulkData('type', data);
        this.requisitionsStatus.list = true;
        this._processResponses();
      });
  }

  private _processResponses() {
    const { list, config } = this.requisitionsStatus;
    if (!list || !config) return;

    this.hiddenStockTypesList.forEach((hiddenType: string) => {
      hiddenType &&
        this.stockTypesDict.has(hiddenType) &&
        this.stockTypesDict.set(hiddenType, { hidden: true });
    });

    if (this.stockTypesOrder.length > 0) {
      this.stockTypes = [];
      this.stockTypesOrder.forEach((type: string) => {
        this.stockTypesDict.has(type) &&
          this.stockTypes.push(
            this.stockTypesDict.get(type) as IStockTypeConfig
          );
      });
      this.isLoading = false;
      this._cdr.detectChanges();
      return;
    }

    this.stockTypes = this.stockTypesDict.values();
    this.isLoading = false;
    this._cdr.detectChanges();
  }
}
