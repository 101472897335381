import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FlaUsubscribe } from '@shared/rocket-components/decorators';
import {
  STOCK_TRADES_TYPE,
  STOCK_TRADE_ELEMENTS_ID,
} from '../../enum/stock-trade.enum';
import { IWorkSpaceComponet } from '@core/interface';

@Component({
  selector: 'app-stock-trade-header',
  templateUrl: './stock-trade-header.component.html',
  styleUrls: ['./stock-trade-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockTradeHeaderComponent implements OnChanges, OnDestroy {
  @Input() public isEdit = false;
  @Input() typeBuy!: string;
  @Input() component!: IWorkSpaceComponet;
  @Output() changeType = new EventEmitter<string>();
  public listType = [
    {
      id: STOCK_TRADES_TYPE.BUY,
      name: 'Compra',
      active: true,
      isBgSell: false,
    },
    {
      id: STOCK_TRADES_TYPE.BUY_START_STOP,
      name: 'C Start/Stop',
      active: false,
      isBgSell: false,
    },
    {
      id: STOCK_TRADES_TYPE.SELL,
      name: 'Venda',
      active: false,
      isBgSell: true,
    },
    {
      id: STOCK_TRADES_TYPE.SELL_START_STOP,
      name: 'V Start/Stop',
      active: false,
      isBgSell: true,
    },
  ];
  public typeTrade!: any;
  public elementsID = STOCK_TRADE_ELEMENTS_ID;

  private _untilDestroy = new FlaUsubscribe();
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { typeBuy } = changes;
    typeBuy?.currentValue !== this.typeTrade?.id && this.setChangeType();
  }

  ngOnDestroy(): void {
    this._untilDestroy.unsubscribe();
  }

  private setChangeType(): void {
    const type = this.listType.find((item) => item.id === this.typeBuy)!;
    this.listType.forEach((item) => (item.active = false));
    type.active = true;
    this.typeTrade = type;
    this.cdr.detectChanges();
  }

  public onChangeType(event: any): void {
    if (this.isEdit || this.typeTrade?.id === event.id) return;
    this.listType.forEach((item) => (item.active = false));
    event.active = true;
    this.changeType.emit(event.id);
    this.typeTrade = event;
    this.cdr.detectChanges();
  }
}
