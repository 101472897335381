import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
} from '@angular/core';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';
import { ContextMenu } from '@shared/components/popup-root/context-menu.interface';
import { BaseContextMenuComponent } from '@shared/rocket-components/base-context-menu';

export enum SuperDomContextMenuEvent {
  Detail = 'DETAIL',
  CustodyDetail = 'CUSTODY_DETAIL',
  PowerBar = 'POWER_BAR',
  Volume = 'VOLUME',
  Aggressor = 'AGGRESSOR',
  Tour = 'TOUR',
  AtPrice = 'AT_PRICE',
  ColumnBook = 'COLUMN_BOOK',
  ColumnBookLeft = 'COLUMN_BOOK_LEFT',
  Position = 'POSITION',
}

@Component({
  selector: 'app-super-dom-context-menu',
  templateUrl: './super-dom-context-menu.component.html',
  styles: [
    `
      .super-dom-config {
        width: 220px;
        padding: 10px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuperDomContextMenuComponent
  extends BaseContextMenuComponent
  implements ContextMenu
{
  show: boolean = false;
  parent_id!: string;
  showDetail: boolean = false;
  showCustodyDetail: boolean = false;
  showPowerBar: boolean = false;
  showVolume: boolean = false;
  hideAggressor: boolean = false;
  isTour: boolean = false;
  showAtPrice: boolean = false;
  showColumnBook: boolean = false;
  showSubContextBook: boolean = false;
  showPosition: boolean = false;
  isTourView: boolean = false;

  constructor(
    elementRef: ElementRef,
    cdr: ChangeDetectorRef,
    private contextMenuService: ContextMenuService
  ) {
    super(elementRef, cdr);
  }

  static openContextMenu(
    contextMenuService: ContextMenuService,
    parent_id: string,
    event: { clientX: number; clientY: number },
    isTour: boolean,
    showDetail: boolean,
    showDetailCustody: boolean,
    showPowerBar: boolean,
    showVolume: boolean,
    hideAggressor: boolean,
    showAtPrice: boolean,
    showColumnBook: boolean,
    showPosition: boolean,
    isTourView: boolean
  ) {
    if (isTour) {
      const domRect = document
        .getElementsByTagName('app-super-dom')[0]
        .getClientRects()[0];
      event.clientX = domRect.x;
      event.clientY = domRect.y + 100;
    }
    contextMenuService.openContextMenu(parent_id, {
      type: 'SuperDomContextMenuComponent',
      event: event,
      configurationData: {
        isTour,
        showDetail,
        showDetailCustody,
        showPowerBar,
        showVolume,
        hideAggressor,
        showAtPrice,
        showColumnBook,
        showPosition,
        isTourView,
      },
    });
  }

  private emitEvent(action: SuperDomContextMenuEvent) {
    this.contextMenuService.emitEvent(this.parent_id, action);
  }

  onFocusOut() {
    !this.isTourView &&
      this.contextMenuService.closeContextMenu(this.parent_id);
  }

  showOrColseDetalhes(): void {
    this.showDetail = !this.showDetail;
    this.emitEvent(SuperDomContextMenuEvent.Detail);
  }

  showOrColseDetalhesCustody(): void {
    this.showCustodyDetail = !this.showCustodyDetail;
    this.emitEvent(SuperDomContextMenuEvent.CustodyDetail);
  }

  showOrColsePowerBar(): void {
    this.showPowerBar = !this.showPowerBar;
    this.emitEvent(SuperDomContextMenuEvent.PowerBar);
  }

  disableVolume(): void {
    this.showColumnBook = false;
    this.showVolume = false;
    this.emitEvent(SuperDomContextMenuEvent.Volume);
  }

  showAggressor(): void {
    this.hideAggressor = !this.hideAggressor;
    this.emitEvent(SuperDomContextMenuEvent.Aggressor);
  }

  positionShow(): void {
    this.showPosition = !this.showPosition;
    this.emitEvent(SuperDomContextMenuEvent.Position);
  }

  showOrCloseAtPrice(): void {
    this.showAtPrice = !this.showAtPrice;
    this.emitEvent(SuperDomContextMenuEvent.AtPrice);
  }

  selectViewBookType(type: string): void {
    this.showVolume = true;
    if (type === 'BOOK_COLUMN') {
      this.showColumnBook = true;
      this.emitEvent(SuperDomContextMenuEvent.ColumnBook);
      return;
    }
    this.showColumnBook = false;
    this.emitEvent(SuperDomContextMenuEvent.ColumnBookLeft);
  }

  toggleSubContext() {
    this.showSubContextBook = !this.showSubContextBook;
  }

  onTour() {
    this.emitEvent(SuperDomContextMenuEvent.Tour);
    this.contextMenuService.closeContextMenu(this.parent_id);
  }
}
