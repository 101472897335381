import {
  adjustTooltipPosition,
  CursorModifier,
  CursorTooltipSvgAnnotation,
  DpiHelper,
  FastCandlestickRenderableSeries,
  ICursorModifierOptions,
  ModifierMouseArgs,
  OhlcSeriesInfo,
  SeriesInfo,
} from 'scichart';
import { CHART_COLORS, CHART_COLORS_LIGHT } from '../colors';
import { formatByTick } from 'src/app/utils/utils.functions';
import { ISearchStock } from '@core/interface';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { auditTime, debounceTime, Subject, takeUntil } from 'rxjs';
import { THEMES } from '@shared/services/core/custom-preferences/theme/theme-preferences.interface';
import { CANDLE_IDS } from '../constants/tiger-chart.constants';
import { RTTextLengthService } from '../services/text-length.service';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { DrawsService } from '@shared/components/stock-chart/service/draws.service';

export class CursorCandleModifier extends CursorModifier {
  private _onDestroy = new Subject<void>();
  private modifierMouseMove$ = new Subject<ModifierMouseArgs>();
  private modifierMouseDown$ = new Subject<ModifierMouseArgs>();
  private _stock!: ISearchStock;
  private isDarkTheme!: boolean;
  private theme = CHART_COLORS;
  private isShowingTooltip: boolean = false;
  private greenColor!: string;
  constructor(
    private textLengthService: RTTextLengthService,
    private themeService: ThemePreferencesService,
    private stockChartService: StockChartService,
    private drawsService: DrawsService,
    stock: ISearchStock,
    options?: ICursorModifierOptions
  ) {
    super(options);
    this._stock = stock;
    this.themeService
      .themeActiveObservable()
      .pipe(takeUntil(this._onDestroy), auditTime(100))
      .subscribe((theme) => {
        this.isDarkTheme = theme === THEMES.dark;
        this.setTheme();
      });
    this.modifierMouseMove$
      .pipe(takeUntil(this._onDestroy), debounceTime(1000))
      .subscribe((args) => {
        super.modifierMouseMove(args);
      });
    this.modifierMouseDown$
      .pipe(takeUntil(this._onDestroy), debounceTime(500))
      .subscribe((args) => {
        super.modifierMouseDown(args);
      });
    this.isDarkTheme = this.themeService.isDarkTheme();
    this.setTheme();
  }

  private setTheme() {
    this.theme = this.isDarkTheme ? CHART_COLORS : CHART_COLORS_LIGHT;
    this.greenColor = this.isDarkTheme
      ? CHART_COLORS.FEEDBACK_POSITIVE
      : CHART_COLORS.FEEDBACK_SUCCESS;
  }

  override delete(): void {
    super.delete();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  updateStock = (stock: ISearchStock) => {
    this._stock = stock;
  };

  override modifierMouseMove(args: ModifierMouseArgs): void {
    const candleSeries = this.parentSurface.renderableSeries.getById(
      CANDLE_IDS.MAIN_SERIE
    );
    if (
      !candleSeries ||
      !(candleSeries instanceof FastCandlestickRenderableSeries)
    ) {
      super.modifierMouseMove(args);
      this.modifierMouseMove$.next(args);
      return;
    } else {
      const mouseEvent = args.nativeEvent;
      const premultipliedX = mouseEvent.offsetX * DpiHelper.PIXEL_RATIO;
      const premultipliedY = mouseEvent.offsetY * DpiHelper.PIXEL_RATIO;
      const hitTest = candleSeries.hitTestProvider.hitTestXSlice(
        premultipliedX,
        premultipliedY
      );
      const dataSeriesIndex = hitTest.dataSeriesIndex;
      const mouseHit = hitTest.hitTestPointValues;
      const high = candleSeries.getNativeHighValues().get(dataSeriesIndex);
      const low = candleSeries.getNativeLowValues().get(dataSeriesIndex);
      if (!hitTest.isHit || mouseHit.y > high || mouseHit.y < low) {
        super.modifierMouseMove(args);
        this.modifierMouseMove$.next(args);
        return;
      } else if (this.isShowingTooltip) {
        super.modifierMouseMove(args);
        return;
      }
    }
    this.modifierMouseMove$.next(args);
  }

  override modifierMouseDown(args: ModifierMouseArgs): void {
    const candleSeries = this.parentSurface.renderableSeries.getById(
      CANDLE_IDS.MAIN_SERIE
    );
    if (
      !candleSeries ||
      !(candleSeries instanceof FastCandlestickRenderableSeries)
    ) {
      super.modifierMouseDown(args);
      this.modifierMouseDown$.next(args);
    } else {
      const mouseEvent = args.nativeEvent;
      const premultipliedX = mouseEvent.offsetX * DpiHelper.PIXEL_RATIO;
      const premultipliedY = mouseEvent.offsetY * DpiHelper.PIXEL_RATIO;
      const hitTest = candleSeries.hitTestProvider.hitTestXSlice(
        premultipliedX,
        premultipliedY
      );
      const dataSeriesIndex = hitTest.dataSeriesIndex;
      const mouseHit = hitTest.hitTestPointValues;
      const high = candleSeries.getNativeHighValues().get(dataSeriesIndex);
      const low = candleSeries.getNativeLowValues().get(dataSeriesIndex);
      if (!hitTest.isHit || mouseHit.y > high || mouseHit.y < low) {
        super.modifierMouseDown(args);
        this.modifierMouseDown$.next(args);
        return;
      }
      this.stockChartService.emitCandleClicked({
        chartId: this.parentSurface.id,
        dataSeriesIndex,
      });
    }
    this.modifierMouseDown$.next(args);
  }

  private getPercentVariation(close: number, lastClosingPrice: number) {
    /**
     * close = lastClosingPrice
     * 100 = x
     */
    const newLow = close * 100;
    return newLow / lastClosingPrice - 100;
  }

  private getInfoToPlot(
    close: number,
    high: number,
    low: number,
    open: number,
    lastClosingPrice: number
  ) {
    const variation = close - lastClosingPrice;
    const tickSize = this._stock.tick_size_denominator;
    const suffixVariation = this._stock.cd_security_type == 'FUT' ? 'pts' : '';
    const percentVariation = this.getPercentVariation(close, lastClosingPrice);
    const fillVariation =
      close == open
        ? this.theme.BRAND_PRIMARY
        : close > open
        ? this.greenColor
        : this.theme.FEEDBACK_NEGATIVE;
    return [
      {
        label: `Abertura: ${formatByTick(open, tickSize)}`,
        fill: this.theme.NEUTRAL_SMOOTHEST,
      },
      {
        label: `Máxima: ${formatByTick(high, tickSize)}`,
        fill: this.theme.NEUTRAL_SMOOTHEST,
      },
      {
        label: `Mínima: ${formatByTick(low, tickSize)}`,
        fill: this.theme.NEUTRAL_SMOOTHEST,
      },
      {
        label: `Fechamento: ${formatByTick(close, tickSize)}`,
        fill: this.theme.NEUTRAL_SMOOTHEST,
      },
      {
        label: `Amplitude: ${formatByTick(high - low, tickSize)}`,
        fill: this.theme.NEUTRAL_SMOOTHEST,
      },
      {
        label: `Variação: ${formatByTick(
          variation.toString(),
          tickSize
        )} ${suffixVariation} (${formatByTick(
          percentVariation.toString(),
          2
        )}%)`,
        fill: fillVariation,
      },
    ];
  }

  override tooltipSvgTemplate = (
    seriesInfos: SeriesInfo[],
    svgAnnotation: CursorTooltipSvgAnnotation
  ) => {
    //const width = 195;
    const height = 150;
    const ohlcSeries = seriesInfos.find(
      (serie) => serie instanceof OhlcSeriesInfo
    );
    if (
      !ohlcSeries ||
      !ohlcSeries.isWithinDataBounds ||
      !ohlcSeries.isHit ||
      this.drawsService.isDrawingLine
    ) {
      this.isShowingTooltip = false;
      return '<svg></svg>';
    }
    const mouseHit = ohlcSeries.hitTestPointValues;
    const dataSeries =
      ohlcSeries.renderableSeries as FastCandlestickRenderableSeries;

    const dataSeriesIndex = ohlcSeries.dataSeriesIndex;
    const lastClosingPrice =
      dataSeries.getNativeCloseValues().get(dataSeriesIndex - 1) || 1;
    const close = dataSeries.getNativeCloseValues().get(dataSeriesIndex);
    const high = dataSeries.getNativeHighValues().get(dataSeriesIndex);
    const low = dataSeries.getNativeLowValues().get(dataSeriesIndex);
    const open = dataSeries.getNativeOpenValues().get(dataSeriesIndex);
    if (mouseHit.y > high || mouseHit.y < low) {
      this.isShowingTooltip = false;
      return '<svg></svg>';
    }
    const infoToPlot = this.getInfoToPlot(
      close,
      high,
      low,
      open,
      lastClosingPrice
    );
    let texts = '';
    const x = 15;
    const yGlobal = 15;
    const date = new Date(ohlcSeries.xValue);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hrsString = hours.toString().padStart(2, '0');
    const minString = minutes.toString().padStart(2, '0');
    const timer = hours ? `, ${hrsString}:${minString}` : '';
    const dateFormatted = `Resumo de ${day}/${month}/${year}${timer}`;
    let biggerText = dateFormatted;
    this.isShowingTooltip = true;
    for (let i = 0; i < infoToPlot.length; i++) {
      const y = (yGlobal + 5) * (i + 2);
      const info = infoToPlot[i];
      const text = `${info.label}`;
      biggerText = text.length > biggerText.length ? text : biggerText;
      texts += `<text y="${y}" x="${x}" font-size="13">
              <tspan fill="${info.fill}">${text}</tspan>
            </text>`;
    }
    const width =
      this.textLengthService.getBoxLineTextLength(
        biggerText,
        undefined,
        '13px'
      ) + 30;
    adjustTooltipPosition(width, height, svgAnnotation);
    return `<svg width="${width + 30}" height="${height + 30}">
          <rect width="${width}" height="${height}" rx="5" ry="5" fill="${
      this.theme.NEUTRAL_STRONG
    }" />
           <svg width="100%">
            <text y="${yGlobal + 5}" x="${x}" font-size="13" fill="${
      this.theme.NEUTRAL_SMOOTHEST
    }">
            <tspan>${dateFormatted}</tspan>
            </text>
            ${texts}
           </svg>
       </svg>`;
  };
}
