import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  IConditional,
  IIndicator,
  IScreeningFilter,
  ISideConditional,
} from '../interfaces/indicator.interface';
import {
  DATA_POINT,
  INTERVAL_SCREENING_CHART,
  SELECT_CONDITIONS,
  TYPES_MA,
} from '../constants/constants';
import { StockScreeningService } from '../stock-screening.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastService } from '@shared/services/toast.service';
import { ISearchStock } from '@core/interface/stock.interface';

@Component({
  selector: 'app-screening-list-filters',
  templateUrl: './screening-list-filters.component.html',
  styles: [
    `
      :host(screening-graphic) {
      }
      ::ng-deep .margin-bottom {
        margin-bottom: 0px !important;
      }
      .height-header {
        height: 34px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreeningListFiltersComponent implements OnChanges {
  @Input() filtersString!: string;
  @Input() refComponent!: string;
  @Input() headerOptions!: any;
  @Input() component!: any;
  @Output() updateComponent: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendAndSave: EventEmitter<string> = new EventEmitter<string>();
  @Output() openGraphic: EventEmitter<{
    filter: IScreeningFilter;
    filterString: string;
  }> = new EventEmitter<{ filter: IScreeningFilter; filterString: string }>();
  public filters: IScreeningFilter[] = [];
  public isStartFilter = false;
  public filterSelected!: IScreeningFilter;
  public intervalChart = INTERVAL_SCREENING_CHART;
  public intervalSelected!: any;
  public stockSelected!: ISearchStock;
  public formTypeExecFilter!: FormGroup;
  public searchLabel: string = '';

  get labelButton(): string {
    return this.filterSelected?.isStart ? 'Parar' : 'Iniciar';
  }

  get isRealTime(): boolean {
    return this.formTypeExecFilter?.get('typeExecFilter')?.value == 1;
  }

  get isBackTest(): boolean {
    return this.formTypeExecFilter?.get('typeExecFilter')?.value == 2;
  }

  constructor(
    private stockScreeningService: StockScreeningService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {
    this.formTypeExecFilter = this.formBuilder.group({
      typeExecFilter: new FormControl(1),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { filtersString } = changes;
    filtersString?.currentValue && this.mountedFilters();
  }

  private updateFilter(): void {
    const index = this.filters.findIndex(
      (item: IScreeningFilter) => item.id === this.filterSelected.id
    );
    index > -1 && (this.filters[index] = this.filterSelected);
    this.sendAndSave.emit(JSON.stringify(this.filters));
    this.cdr.detectChanges();
  }

  private mountedFilters(): void {
    this.filters = JSON.parse(this.filtersString) || [];
    !this.filterSelected && (this.filterSelected = this.filters[0]);
    this.stockSelected = this.component?.stock;
    if (!this.filterSelected?.stocks?.length) {
      this.filterSelected.stocks = [];
      this.filterSelected.stocks.push(
        `${this.stockSelected.cd_stock}:${this.stockSelected.id_exchange}`
      );
    }
    this.intervalSelected = this.filterSelected?.intervalChart
      ? JSON.parse(this.filterSelected.intervalChart)
      : INTERVAL_SCREENING_CHART[0];
    this.cdr.detectChanges();
  }

  public selectStock(stock: ISearchStock): void {
    const label = `${stock.cd_stock}:${stock.id_exchange}`;
    if (this.filterSelected.stocks.includes(label)) return;
    this.stockSelected = stock;
    // this.component.stock = event;
    // this.updateComponent.emit(this.component);
    this.filterSelected.stocks.push(label);
    !this.filterSelected.tickSize && (this.filterSelected.tickSize = {});
    this.filterSelected.tickSize[label] = {
      cdSegment: stock.cd_segment,
      dsAsset: stock.ds_asset,
      papel: stock.cd_stock,
      tickSize: stock.tick_size_denominator,
    };
    this.updateFilter();
  }

  public selectInterval(event: any): void {
    this.intervalSelected = event;
    this.filterSelected.intervalChart = JSON.stringify(event);
    this.updateFilter();
  }

  public startStopFilter(filter?: IScreeningFilter): void {
    const selected: IScreeningFilter = filter ?? this.filterSelected;
    !selected.isStart && this.sendFilter(selected);
    selected.isStart && this.removeFilter(selected);
  }

  private sendFilter(filter: IScreeningFilter): void {
    this.isStartFilter = true;
    if (!!filter && !filter.stocks.length) {
      filter.stocks = [
        `${this.stockSelected.cd_stock}:${this.stockSelected.id_exchange}`,
      ];
    }
    const selected: IScreeningFilter = filter;
    if (!selected.intervalChart) {
      selected.intervalChart = JSON.stringify(this.intervalSelected);
    }
    const candlePeriod = JSON.parse(selected.intervalChart).apiKeyCode;
    const preparedFilter = this.preparingFilter(selected, candlePeriod);
    selected.isStart && (preparedFilter.request_id = selected?.idScreening);
    console.log(preparedFilter);
    if (
      this.formTypeExecFilter.get('typeExecFilter')?.value == 2 &&
      !selected.isStart
    ) {
      this.screeningBackTest(preparedFilter, selected);
    } else {
      this.screeningRealTime(preparedFilter, selected);
    }
    this.cdr.detectChanges();
  }

  private screeningBackTest(preparedFilter: any, selected: any): void {
    preparedFilter.time_window = 500;
    const filters = {
      filter: selected,
      filterString: JSON.stringify(preparedFilter),
    };
    this.cdr.detectChanges();
    this.openGraphic.emit(filters);
  }

  private removeFilter(selected: IScreeningFilter): void {
    this.stockScreeningService
      .removeRealTime(selected.idScreening)
      .subscribe(() => {
        this.toastService.showToast(
          'success',
          'Seu filtro foi parado com sucesso.'
        );
        selected.isStart = !selected.isStart;
        this.filters.forEach((filter: IScreeningFilter) => {
          if (filter.id === selected.id) {
            filter.isStart = selected.isStart;
          }
        });
        if (this.filterSelected.id === selected.id) {
          this.filterSelected = selected;
        }
        this.updateFilter();
      });
  }

  private screeningRealTime(
    preparedFilter: any,
    selected: IScreeningFilter
  ): void {
    this.stockScreeningService
      .createRealTime(JSON.stringify(preparedFilter))
      .subscribe((item: any) => {
        if (item?.length) {
          selected.idScreening = item[0];
          this.toastService.showToast(
            'success',
            'Seu filtro foi iniciado com sucesso.'
          );
          selected.isStart = !selected.isStart;
          this.filters.forEach((filter: IScreeningFilter) => {
            if (filter.id === selected.id) {
              filter.isStart = selected.isStart;
              filter.intervalChart = selected.intervalChart;
            }
          });
          if (this.filterSelected.id === selected.id) {
            this.filterSelected = selected;
          }
          this.updateFilter();
        }
      });
  }

  private preparingFilter(filter: IScreeningFilter, candlePeriod: string): any {
    const requestCores: any[] = [];
    filter.conditional.forEach((conditional: IConditional) => {
      const indicators: IIndicator[] = [];
      let leftSideName =
        conditional.leftSide.type === SELECT_CONDITIONS[0]
          ? conditional.leftSide.value
          : `${conditional.leftSide.value}_leftSide`;
      let rightSideName =
        conditional.rightSide.type === SELECT_CONDITIONS[0]
          ? conditional.rightSide.value
          : `${conditional.rightSide.value}_rightSide`;
          
      if (
        conditional.leftSide.type === SELECT_CONDITIONS[0] &&
        conditional.rightSide.type === SELECT_CONDITIONS[0]
      ) {
        indicators.push(this.mountParamsAllQuotation(conditional, 'leftSide'));
        leftSideName = `identity`;
        rightSideName = conditional.rightSide.value as string;
      }
      
      if (conditional.leftSide.type === SELECT_CONDITIONS[3]) {
        indicators.push(this.mountParamsIndicators(conditional, 'leftSide'));
      }

      conditional.leftSide.type === SELECT_CONDITIONS[2] &&
        (leftSideName = conditional.leftSide.value as string);
      conditional.rightSide.type === SELECT_CONDITIONS[2] &&
        (rightSideName = conditional.rightSide.value as string);

      conditional.leftSide.type === SELECT_CONDITIONS[1] &&
        indicators.push(this.mountParamsIndicators(conditional, 'leftSide'));
      conditional.rightSide.type === SELECT_CONDITIONS[1] &&
        indicators.push(this.mountParamsIndicators(conditional, 'rightSide'));

      requestCores.push({
        start_here: '',
        indicators: indicators,
        conditional: {
          left_side: leftSideName,
          relation: conditional.relation,
          right_side: rightSideName,
        },
        end_here: '',
      });
    });
    return {
      request_cores: requestCores,
      stocks_data: this.preparingStocks(filter.stocks),
      candle_period: candlePeriod,
      time_window: 1,
      action: filter.isStart ? 'delete' : 'add',
      logical_operator: 'and',
    };
  }

  private preparingStocks(stockList: string[]) {
    const stocks: any = {};
    stockList.forEach((item: string) => {
      const stock = item.split(':');
      if (stocks[stock[1]]) {
        stocks[stock[1]] = [...stocks[stock[1]], stock[0]];
      } else {
        stocks[stock[1]] = [stock[0]];
      }
    });
    return stocks;
  }

  private mountParamsIndicators(
    conditional: IConditional,
    side: 'leftSide' | 'rightSide'
  ): IIndicator {
    const type = conditional[side].value as string;
    return {
      name: [`${conditional[side].value as string}_${side}`],
      type: TYPES_MA[type] || type,
      parameters: !conditional[side]?.filter?.data_point
        ? conditional[side].filter
        : {
            ...conditional[side].filter,
            data_point: [conditional[side]?.filter?.data_point],
          },
    };
  }

  private mountParamsAllQuotation(
    conditional: IConditional,
    side: 'leftSide' | 'rightSide'
  ): IIndicator {
    return {
      name: ['identity'],
      type: 'identity',
      parameters: {
        data_point: [conditional[side].value as string],
      },
    };
  }

  public changeFilter(filter: IScreeningFilter): void {
    this.filterSelected = filter;
    this.intervalSelected = this.filterSelected?.intervalChart
      ? JSON.parse(this.filterSelected.intervalChart)
      : INTERVAL_SCREENING_CHART[0];
    if (!this.filterSelected?.stocks?.length) {
      this.filterSelected.stocks = [];
      this.filterSelected.stocks.push(
        `${this.stockSelected.cd_stock}:${this.stockSelected.id_exchange}`
      );
      !this.filterSelected.tickSize && (this.filterSelected.tickSize = {});
      this.filterSelected.tickSize[
        `${this.stockSelected.cd_stock}:${this.stockSelected.id_exchange}`
      ] = {
        cdSegment: this.stockSelected.cd_segment,
        dsAsset: this.stockSelected.ds_asset,
        papel: this.stockSelected.cd_stock,
        tickSize: this.stockSelected.tick_size_denominator,
      };
      this.updateFilter();
    }
    this.cdr.detectChanges();
  }

  public mountedLabel(conditional: ISideConditional): string {
    let value = conditional.value.toString().toLocaleUpperCase();
    if (SELECT_CONDITIONS[1] === conditional.type) {
      let infosFilter = '';
      if (conditional.filter) {
        infosFilter = '(';
        Object.keys(conditional.filter).forEach(
          (item: string, index: number) => {
            if (item === 'ma_type') return;
            !!index && (infosFilter += ',');
            if (item === 'data_point') {
              infosFilter += DATA_POINT.find(
                (point: any) => point.id === conditional.filter[item]
              )?.label;
            } else {
              infosFilter += conditional.filter[item];
            }
          }
        );
        infosFilter += ')';
        value += ` ${infosFilter}`;
      } else {
        value = conditional.description.split(' (').shift()!;
      }
    } else {
      value = conditional.description;
    }
    return value;
  }

  public removeStock(index: number): void {
    const label = this.filterSelected.stocks[index];
    if (this.filterSelected.tickSize) {
      delete this.filterSelected.tickSize[label];
    }
    this.filterSelected.stocks.splice(index, 1);
    this.updateFilter();
  }

  public labelStock(label: string): string {
    return label.split(':').shift()!;
  }
}
