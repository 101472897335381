import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { catchError, distinctUntilChanged, EMPTY, map, Observable, Subject, throttleTime, throwError } from 'rxjs';
import { ToastService } from '@shared/services/toast.service';
import { TFlaToast } from '@shared/rocket-components/toast';
import { DisconnectService } from '../../shared/services/api/authentication/v1/disconnect.service';
import { AuthService } from '@shared/services';
// import { getErrorCodeByServer } from '@shared/services/rest/api-error';

export const LOGOUT_CODE: any = {
  401: 'ACCESS_DENIED',
  403: 'SESSION EXPIRED',
};

export const ERROR_MESSAGES_BY_CODE: any = {
  401: 'Sessão expirada. Faça login novamente.',
  403: 'Acesso negado.',
  503: 'Serviço temporariamente indisponível.',
  502: 'Serviço temporariamente indisponível.',
};

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  stopRequest: boolean = false;
  _toastCall: Subject<any> = new Subject<any>()
  constructor(
    private toastService: ToastService,
    private authService: AuthService,
    private disconnectService: DisconnectService
  ) {
    this._toastCall.pipe(
      distinctUntilChanged(), 
      throttleTime(5000)
    ).subscribe(({type, text}) => {
      this.toastService.showToast(type, text);
    })
  }
  handleResponseError(err: HttpErrorResponse) {
    this.stopRequest = true;
    const status = err.status;
    const codeMessage = ERROR_MESSAGES_BY_CODE[status];
    const getDebugMessage = () => `${codeMessage}`;
    const logout = () => {
      this.disconnectService.disconnect$.next(`${status} - ${err.url}`);
      this.authService.performLogout({
        reason: LOGOUT_CODE[status],
        systemMessage: getDebugMessage(),
      });
    };

    const errorActions: any = {
      401: () => {
        this._showToast(getDebugMessage(), 'warning', logout);
      },
      403: () => {
        this._showToast(getDebugMessage(), 'warning', logout);
      },
      502: () => {
        this._showToast(codeMessage, 'error');
      },
      503: () => {
        this._showToast(codeMessage, 'error');
      },
      default: () => {
        // console.log('default error', err);
      },
    };

    const action = errorActions[status] || errorActions.default;
    action();

    this.stopRequest = false;
    return throwError(() => err);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (this.stopRequest) {
      return EMPTY;
    }

    return next.handle(req).pipe(
      map((res: HttpEvent<any>) => {
        if (res instanceof HttpResponse) {
          //na existencia do body, ele deve ser um objeto.
          if (res.body && typeof res.body !== 'object') {
            const error = new HttpErrorResponse({
              error: 'Empty',
            });
            throw error as Error;
          }
          if (
            res.body &&
            typeof res.body === 'object' &&
            Object.keys(res.body).length &&
            'success' in res.body &&
            !res.body.success
          ) {
            const error = new HttpErrorResponse({
              error: res.body,
            });
            throw error as Error;
          }
        }
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        return this.handleResponseError(err);
      })
    );
  }

  private _showToast(text: string, type: TFlaToast, callback?: any) {
    this._toastCall.next({type, text})
    this.stopRequest = false;
    if (callback) callback();
  }

}
