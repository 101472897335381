import { ElementRef, Injectable } from '@angular/core';
import { ComponentsService } from '@services/api/nitro-ws/v1/components.service';
import {
  WORKSPACE_TEMPLATES,
  WorkspaceService,
} from '@services/api/nitro-ws/v1/workspace.service';
import {
  BehaviorSubject,
  delay,
  filter,
  Observable,
  ReplaySubject,
  tap,
} from 'rxjs';
import { ToastService } from '@services/toast.service';
import {
  IMetadata,
  IWorkSpace,
  IWorkSpaceComponet,
  IWorkSpaceComponetList,
} from '@core/interface';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { ModalAvisoComponent } from '@shared/components/modal-aviso/modal-aviso.component';

import {
  COMPONENTS_ROUTES,
  CREATE_COMPONENT_LAYOUT,
  DUPLICATE_COMPONENT_LAYOUT,
  COMPONENTS_NAMES_ENUM,
  COMPONENTS_AND_TOOLS,
} from '@core/workspace';
import { HomeHelperService } from './home-helper.service';
import { IContextMenuOptions } from '@shared/components/context-menu/context-menu.component';
import { ModalCloneComponent } from '@core/layout/header/modal-clone/modal-clone.component';
import { RocketModalService } from '@shared/rocket-components/components/index';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { WorkspaceComponentService } from './workspace-component.service';
import { IYoutubeMiniPlayer } from '../youtube-miniplayer/youtube-miniplayer.interface';
import { ModalResetWorkspaceComponent } from '@shared/modals/modal-reset-workspace/modal-reset-workspace.component';
import { StandaloneModalsService } from '@shared/modals/standalone-modals.service';
import { WorkspacesPreferences } from '@shared/services/core/custom-preferences/workspace';
import { ComponentsService as components } from '@core/workspace/service/components.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private _execComponent$ = new ReplaySubject<any>(1);
  private _openContextMenu$ = new ReplaySubject<IContextMenuOptions>(1);
  private _onInitializedComponents$ = new BehaviorSubject<boolean>(false);
  private _miniplayerComponent$ = new ReplaySubject<
    IYoutubeMiniPlayer | undefined
  >();
  get execComponent$() {
    return this._execComponent$;
  }

  get openContextMenu$() {
    return this._openContextMenu$;
  }

  set workspaceComponentsIsInitialized(initialized: boolean) {
    this._onInitializedComponents$.next(initialized);
  }

  set miniplayerComponent(data: IYoutubeMiniPlayer | undefined) {
    this._miniplayerComponent$.next(data);
  }

  private _selectedComponent!: string;
  private componentsRoutes = COMPONENTS_ROUTES;

  set selectedComponent(id: string) {
    this._selectedComponent = id;
  }

  get selectedComponent(): string {
    return this._selectedComponent;
  }

  constructor(
    private _workspaceService: WorkspaceService,
    private _componentService: ComponentsService,
    private _toastService: ToastService,
    private globalStock: GlobalSelectedStockSubscription,
    private modalService: RocketModalService,
    private homeHelper: HomeHelperService,
    private workspaceComponentService: WorkspaceComponentService,
    private _standaloneModalsService: StandaloneModalsService,
    private _workspacePreferences: WorkspacesPreferences,
    private componentsService: components
  ) {}

  onOpenContextMenu = () => this.openContextMenu$.asObservable();
  onExecComponent = () => this.execComponent$.asObservable();
  onInitializedWorkspaceComponents = () =>
    this._onInitializedComponents$.asObservable();
  onMiniplayerComponent = () => this._miniplayerComponent$.asObservable();

  newWorkspace = (name: string) =>
    this._workspaceService.addWorkspace({ name, active: true, type: 'WEB' });

  updateWorkspace = (current: IWorkSpace) =>
    this._workspaceService.update(current);

  warningModal(
    message: string,
    confirmeButton: string,
    elementRef?: ElementRef,
    cancelButton: string = 'Cancelar'
  ) {
    const options: any = {
      data: {
        title: 'Aviso',
        text: message,
        confirmeButton,
        cancelButton,
        isSecondModal: Boolean(elementRef),
        stock: undefined,
        showPriceStock: false,
      },
      scrollable: true,
    };
    if (elementRef) {
      options.toggleBetweenModal = elementRef;
    }
    const ref = this.modalService.open(ModalAvisoComponent, options);
    return ref.afterDismissed;
  }

  removeWorkspace = (selectedWorkspace: IWorkSpace) =>
    this._workspaceService
      .remove(selectedWorkspace.id)
      .pipe(delay(100))
      .subscribe(() => {
        this._toastService.showToast(
          'success',
          `Workspace "${selectedWorkspace.name}" apagada com sucesso.`
        );
        this._removeAllComponentsFromDB(selectedWorkspace.components);
        this.workspaceComponentService.removeWorkspace(selectedWorkspace, true);
      });

  processRemoveWorkspace(
    selectedWorkspace: IWorkSpace[],
    element?: ElementRef
  ) {
    let message = `Tem certeza que deseja apagar a workspace <b>${selectedWorkspace[0].name}</b>?`;
    if (selectedWorkspace.length > 1) {
      let names = '';
      selectedWorkspace.forEach((workspace: IWorkSpace) => {
        names && (names += `, <b>${workspace.name}</b>`);
        !names && (names = `<b>${workspace.name}</b>`);
      });
      message = `Tem certeza que deseja apagar esses workspaces ${names}?`;
    }
    this.warningModal(message, 'Sim', element, 'Não').subscribe((res) => {
      if (res && res.value) {
        selectedWorkspace.forEach((workspace: IWorkSpace) =>
          this.removeWorkspace(workspace)
        );
      }
    });
  }

  removeAllComponentFromCache(
    active_ws_id: string,
    isActive: boolean,
    template: WORKSPACE_TEMPLATES
  ) {
    return this._workspaceService.clear(active_ws_id).then(async () => {
      if (isActive) {
        this.workspaceComponentService.createdOrUpdateWorkspace(
          {
            ...this.workspaceComponentService.getActiveWorkspace(),
            components: [],
          },
          false,
          true
        );
      }
      this._workspaceService.generateDefaultWorkspace(active_ws_id, template);
    });
  }

  // se a workspace que vai ser resetada é a workspace ativa, tem que limpar o cache para resetar
  public resetWorkspace(workspaceData: IWorkSpace) {
    this._standaloneModalsService
      .openStandaloneModal(ModalResetWorkspaceComponent)
      .afterDismissed.pipe(filter((response) => typeof response === 'string'))
      .subscribe((response: WORKSPACE_TEMPLATES) => {
        const { id: default_ws_id } = workspaceData;
        const { id: active_ws_id } =
          this.workspaceComponentService.getActiveWorkspace();
        const isWorkspaceActive = active_ws_id === default_ws_id;
        this.removeAllComponentFromCache(
          default_ws_id,
          isWorkspaceActive,
          response
        );
      });
  }

  private _removeAllComponentsFromDB(
    workspaceComponents: IWorkSpaceComponet[]
  ): void {
    if (!workspaceComponents || !workspaceComponents.length) return;
    this._workspaceService.removeWorkspaceComponents(workspaceComponents);
  }

  openCloneModal(
    selectedWorkspace: IWorkSpace,
    element?: ElementRef
  ): Observable<{
    workspace: IWorkSpace;
    wsDuplicated: boolean;
    closed?: boolean;
  }> {
    const options: any = {
      data: { workspace: selectedWorkspace, isSecondModal: Boolean(element) },
      scrollable: true,
    };
    if (element) {
      options.toggleBetweenModal = element;
    }
    return this.modalService.open(ModalCloneComponent, options).afterDismissed;
  }

  cloneWorkspace = (id: string, name: string): Observable<IWorkSpace> =>
    this._workspaceService.cloneWorkspace(id, name);

  duplicateComponent(doc: any) {
    const newComponent = DUPLICATE_COMPONENT_LAYOUT({ ...doc });
    delete newComponent.idComponent;
    this.addComponent(newComponent, true);
  }

  addComponents(data: any) {
    const components = this.homeHelper.componentsNoOverlapping(data);
    components.forEach((component: any) => {
      this.addComponent(component);
    });
  }

  setComponentToWorkspace(item: IWorkSpaceComponet) {
    return new Promise<IWorkSpaceComponet<IMetadata<any>>>((resolve) => {
      this._componentService.create(item).subscribe((res) => {
        const obj = {
          ...res,
          metadata: item.metadata,
        };
        this.workspaceComponentService.createComponent(obj);
        resolve(obj);
      });
    });
  }

  async removeComp(id: string) {
    this._componentService.remove(id);
    this.workspaceComponentService.removeComponent(id);
  }

  updateComp(item: IWorkSpaceComponet) {
    this._componentService.update(item).subscribe(() => {
      this.workspaceComponentService.updateComponent(item);
    });
  }

  updateMeta<T = any>(item: IWorkSpaceComponet<IMetadata<T>>) {
    if (!item?.id) return;
    this._componentService.updateMetadata(item.id, item.metadata);
    this.workspaceComponentService.updateComponent(item);
  }

  addComponent(component: IWorkSpaceComponetList, isDuplicate?: boolean) {
    if (component?.noPublished) {
      this.modalService.open(ModalAvisoComponent, {
        data: {
          title: component.name,
          text: `O componente <b> ${component.name}</b> não esta disponível ainda.<br />
        Estamos trabalhando para disponibiliza-lo em breve.`,
          showButtons: false,
          stock: undefined,
          showPriceStock: false,
        },
      });
      return;
    }
    const newComponent: any = isDuplicate
      ? component
      : CREATE_COMPONENT_LAYOUT(
          this.workspaceComponentService.getActiveWorkspace().id,
          component,
          component.data && component.data.stock
            ? component.data.stock
            : this.globalStock.getGlobalStockSelected()
        );
    this._workspacePreferences.setLockWorkspace(false);
    return this.setComponentToWorkspace(newComponent);
  }

  hideWorkspace(workspace: IWorkSpace) {
    let isActive = false;
    if (
      workspace.id === this.workspaceComponentService.getActiveWorkspace().id
    ) {
      isActive = true;
      workspace.active = false;
    }

    workspace.visible = false;
    this.workspaceComponentService.createdOrUpdateWorkspace(
      workspace,
      isActive
    );
    return this.updateWorkspace(workspace).pipe(
      tap(() => {
        this.updateWorkspace(
          this.workspaceComponentService.getActiveWorkspace()
        ).subscribe();
      })
    );
  }

  updateComponentNewInfo(res: IWorkSpace<any>): IWorkSpace<any> {
    res?.components?.forEach((component) => {
      const componentConfigs = COMPONENTS_AND_TOOLS.get(component.type);
      if (componentConfigs) {
        component.metadata.headerOptions = {
          ...component.metadata.headerOptions,
          fullscreen: componentConfigs.fullscreen,
        };
        component.metadata.layout = {
          ...component.metadata.layout,
          minWidth: componentConfigs.minWidth,
          minHeight: componentConfigs.minHeight,
        };
      }
      if (component.type === COMPONENTS_NAMES_ENUM.GRAFICO) {
        if (!component.metadata.footerOptions) {
          component.metadata.footerOptions = {};
        }
        const tools = this.componentsService
          .getComponentsList()
          .get('FERRAMENTAS');
        if (isNullOrUndefined(component.metadata.footerOptions.hasFooter)) {
          component.metadata.footerOptions.hasFooter = tools.find(
            (tool: any) => tool.id === COMPONENTS_NAMES_ENUM.GRAFICO
          ).hasFooter;
        }
        if (!parseFloat(component.metadata.layout.height)) {
          component.metadata.layout.height = '520px';
        }
      }
    });
    return res;
  }

  isSelectedComponent = (refId: string): boolean => {
    refId = refId == '' ? '' : ` #${refId}`;
    return !!document
      .getElementById(this.selectedComponent)
      ?.querySelector(`.border-brand-primary${refId}`);
    !!document.querySelector(`#workspace_base `);
  };
  getSizes(
    isMaximized: boolean,
    metadata: any,
    styles: CSSStyleDeclaration
  ): {
    width: string;
    height: string;
    top: string;
    bottom: string;
    right: string;
    left: string;
    transform: { x: number; y: number };
  } {
    const newWidth = isMaximized ? metadata.layout.originalWidth : undefined;
    const newHeight = isMaximized ? metadata.layout.originalHeight : undefined;
    const newTop = isMaximized ? metadata.layout.top : undefined;
    const newBottom = isMaximized ? metadata.layout.bottom : undefined;
    const newRight = isMaximized ? metadata.layout.right : undefined;
    const newLeft = isMaximized ? metadata.layout.left : undefined;
    const newTransform = isMaximized
      ? metadata.layout.originalTransform
      : undefined;
    const transforms = styles.transform.slice(12).split(',');
    const transform = {
      x: parseInt(transforms[0]),
      y: parseInt(transforms[1]),
    };
    return {
      width: newWidth || styles.width,
      height: newHeight || styles.height,
      top: newTop || styles.top,
      bottom: newBottom || styles.bottom,
      right: newRight || styles.right,
      left: newLeft || styles.left,
      transform: newTransform || transform,
    };
  }

  public componentsQty() {
    const componentsQty: any = {};
    const components = this.workspaceComponentService.getComponents();
    if (!components.length) return {};
    for (let i = 0; i < components.length; i++) {
      const key = components[i].name;
      if (componentsQty[key]) {
        const qty = parseInt(componentsQty[key].qty) + 1;
        componentsQty[key].qty = qty >= 10 ? '9+' : qty;
      } else componentsQty[key] = { qty: 1 };
    }
    return componentsQty;
  }
}
