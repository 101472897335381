export const parabolicSarTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  O SAR Parabólico (SAR) é um indicador técnico criado por J. Welles Wilder, Jr.
  O indicador é usado para identificar a direção de uma tendência dos preços de
  um ativo. O SAR é especialmente útil em mercados com tendências fortes, pois
  ajuda a identificar pontos de entrada e saída.
</div>
<div class="mb-3">
  O SAR é baseado em uma fórmula matemática que calcula um valor para cada ponto
  de dados do ativo. O valor é então plotado em um gráfico, criando uma linha
  curva que se aproxima dos preços do ativo. A linha curva é então usada para
  identificar a direção da tendência.
</div>
<div class="mb-3">
  O SAR é um indicador de seguir tendência, o que significa que é mais eficaz em
  mercados com tendências fortes. No entanto, ele pode ser menos eficaz em
  mercados laterais, onde os preços podem oscilar para cima e para baixo sem uma
  direção clara.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">O SAR Parabólico é calculado usando a seguinte fórmula:</div>
<div class="mb-3">
  &bull; O primeiro SAR é o ponto mais baixo do período escolhido (geralmente 2
  ou 3 dias).<br />
  &bull; O AF (fator de aceleração) começa em 0,02 e aumenta em 0,02 cada vez
  que um novo ponto extremo (alto ou baixo) é alcançado.
</div>
<div class="mb-3">
  O cálculo é então feito de acordo com a seguinte fórmula:
</div>
<div class="mb-3">SAR = SAR anterior + AF x (EP - SAR anterior)</div>
<div class="mb-3">Onde:<br /></div>
<div class="mb-3">
  &bull; SAR anterior é o valor do SAR do período anterior. <br />
  &bull; AF é o fator de aceleração. <br />
  &bull; EP é o ponto extremo atual (alto ou baixo) do período atual.
</div>
<div class="mb-3">
  O SAR é então plotado no gráfico, juntamente com os preços do ativo. A linha
  curva criada ajuda a identificar a direção da tendência atual.
</div>
<div class="mb-3">
  O SAR Parabólico é um indicador útil para ajudar a identificar pontos de
  entrada e saída em mercados com tendências fortes. No entanto, é importante
  lembrar que nenhum indicador é perfeito e que o SAR pode fornecer sinais
  falsos em mercados laterais.
</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  Para usar o SAR Parabólico, é importante primeiro entender que ele é um
  indicador de seguir tendência. Isso significa que é mais eficaz em mercados
  com tendências fortes e pode ser menos eficaz em mercados laterais.
</div>
<div class="mb-3">
  Uma estratégia comum para usar o SAR Parabólico é comprar quando o preço do
  ativo está acima do SAR e vender quando o preço está abaixo do SAR. Isso pode
  ajudar a capturar os movimentos da tendência atual.
</div>
<div class="mb-3">
  No entanto, é importante lembrar que nenhum indicador é perfeito e que o SAR
  Parabólico pode fornecer sinais falsos em mercados laterais. Portanto, é
  importante usá-lo em conjunto com outras ferramentas de análise técnica e
  considerar outros fatores, como notícias e eventos do mercado, ao tomar
  decisões de negociação.
</div>
<div class="mb-3">
  <img class="w-75" src="/assets/indicators/sar-como-funciona.png" />
</div>
<div class="fw-bolder">Como configurar</div>
<div class="fw-bolder">Valores</div>
<div class="text-decoration-underline">Incremento e Máx</div>
<div class="mb-3">
  O valor padrão para essa taxa é 0.02, enquanto seu valor máximo padrão é 0.20.
  Porém, ambos os valores podem ser alterados.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/sar-entrada.png"
  />
</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">SAR</div>
<div class="mb-3">
  Pode alternar a visibilidade do SAR. Também pode selecionar a cor, a espessura
  e o estilo da linha do SAR.
</div>
<div class="mb-3">
  <img class="image-indicators-input" src="/assets/indicators/sar-estilo.png" />
</div>
`;
