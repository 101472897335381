import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';
import { OptionsComponents } from '../component-list';

export const NEWS_COMPONENT: any = (options?: OptionsComponents) => {
  return {
    name: 'Notícias',
    label: 'Notícias',
    id: COMPONENTS_NAMES_ENUM.NEWS,
    icon: 'campaign',
    iconType: 'icons-outlined',
    cod: 'NOTICIAS',
    data: null,
    close: true,
    fullscreen: true,
    link: true,
    unitOfMeasurement: options?.unit ?? 'px',
    minWidth: options?.minWidth ?? '534px',
    width: options?.width ?? 720,
    height: options?.height ?? 420,
    minHeight: 420,
    left: options?.left ?? 'calc(15% + 5px)',
    top: options?.top ?? '0px',
    iconSvg: '',
  };
};
