import { INDICATORS_TABS } from '../enum/indicators.enum';
import { IIndicatorsTabs } from '../interface/indicators.interface';

export const INDICATOR_TABS: IIndicatorsTabs[] = [
  {
    title: 'Indicadores',
    ref: INDICATORS_TABS.INDICATORS,
    active: true,
  },
  {
    title: 'Balanço Patrimonial',
    ref: INDICATORS_TABS.BALANCE,
    active: false,
  },
  {
    title: 'DRE',
    ref: INDICATORS_TABS.DRE,
    active: false,
  },
  {
    title: 'Fluxo de Caixa',
    ref: INDICATORS_TABS.CASH_FLOW,
    active: false,
  },
];

export const indicatorDropDownData = (defaultEnum: any) => {
  return {
    data: {
      type: 'DATA',
      active: 'Consolidado',
      activeEnum: defaultEnum,
      items: ['Consolidado', 'Individual'],
      useSmall: false,
    },
  };
};

export const DROPDOWN_INDICATORS_PERIOD = {
  data: {
    type: 'PERIOD',
    active: '3 Meses',
    items: ['3 Meses', '12 Meses', 'Exercício'],
    useSmall: true,
  },
};

export const TABLE_HEADERS = [
  {
    label: 'Data',
    field: 'quarter',
    display: true,
    css: 'px-2 fw-bold text-white fs-5 py-3 position-relative outline-border primary-header-index',
  },
  {
    label: 'Agrupamento',
    field: 'month',
    display: true,
    css: 'px-2 fw-bold text-white fs-5 py-3 position-relative outline-border secundary-header-index ',
  },
];
