import {
  AfterViewInit,
  Component,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentsService } from '@shared/services/api/nitro-ws/v1/components.service';
import { IMetadata, IWorkSpaceComponet } from '@core/interface';
import { TableBookComponent } from '@shared/components/table-book/table-book.component';
import { TimesTradesComponent } from '@shared/components/times-trades/times-trades.component';
import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum/workspace.enum';
import { StockChartComponent } from '@shared/components/stock-chart/stock-chart.component';
import { HeatmapComponent } from '@shared/components/heatmap/heatmap.component';
import { StockAnalysisComponent } from '@shared/components/stock-analysis/stock-analysis.component';
import { NewsComponent } from '@shared/components/news/news.component';
import { OptionsComponent } from '@shared/components/options/options.component';
import { StockTableComponent } from '@shared/components/stock-table/stock-table.component';
import { OrdersHistoryComponent } from '@core/components/orders-history-core/orders-history.component';
import { SuperDomComponent } from '@shared/components/super-dom/super-dom.component';
import { StockTradeComponent } from '@shared/components/stock-trade/stock-trade.component';
import { StockScreeningComponent } from '@shared/components/screening/stock-screening/stock-screening.component';
import { GraphicScreeningComponent } from '@shared/components/screening/graphic-screening/graphic-screening.component';
import { BusinessProfileComponent } from '@shared/components/business-profile/business-profile.component';
import { PerformanceReportComponent } from '@shared/components/performance-report/performance-report.component';
import { AtPriceComponent } from '@shared/components/at-price/at-price.component';
import { RankingBrokerComponent } from '@shared/components/ranking-broker/ranking-broker.component';
import { TradeIdeaComponent } from '@shared/components/trade-idea/trade-idea.component';
import { RentComponent } from '@shared/components/rent/rent.component';
import { FlowInvestorsComponent } from '@shared/components/flow-investors/flow-investors.component';
import { MarketSummaryComponent } from '@shared/components/market-summary/market-summary.component';
import { DynamicControlService } from '@shared/rocket-components/dynamic-form/dynamic-control.service';
import { StockTradeService } from '@shared/components/stock-trade/service/stock-trade.service';
import { ModalRiskManagementComponent } from '@shared/modals/modal-risk-management/modal-risk-management.component';

@Component({
  template: '<div></div>',
  providers: [DynamicControlService, StockTradeService],
})
export class StandaloneWindowComponent implements OnInit, AfterViewInit {
  private workspaceId!: string;
  private componentId!: string;

  static get AVAIABLE_COMPONENT_MAP(): { [key in COMPONENTS_NAMES_ENUM]: any } {
    return {
      [COMPONENTS_NAMES_ENUM.GRAFICO]: StockChartComponent,
      [COMPONENTS_NAMES_ENUM.TIMES_TRADES]: TimesTradesComponent,
      [COMPONENTS_NAMES_ENUM.HEATMAP]: HeatmapComponent,
      [COMPONENTS_NAMES_ENUM.STOCK_ANALYSIS]: StockAnalysisComponent,
      [COMPONENTS_NAMES_ENUM.NEWS]: NewsComponent,
      [COMPONENTS_NAMES_ENUM.OPTIONS]: OptionsComponent,
      [COMPONENTS_NAMES_ENUM.PAPEIS]: StockTableComponent,
      [COMPONENTS_NAMES_ENUM.LISTA_ORDENS]: OrdersHistoryComponent,
      [COMPONENTS_NAMES_ENUM.BOOK]: TableBookComponent,
      [COMPONENTS_NAMES_ENUM.SUPER_DOM]: SuperDomComponent,
      [COMPONENTS_NAMES_ENUM.BOLETA]: StockTradeComponent,
      [COMPONENTS_NAMES_ENUM.SCREENING]: StockScreeningComponent,
      [COMPONENTS_NAMES_ENUM.GRAPHIC_SCREENING]: GraphicScreeningComponent,
      [COMPONENTS_NAMES_ENUM.BUSINESS_PROFILE]: BusinessProfileComponent,
      [COMPONENTS_NAMES_ENUM.PERFORMANCE_REPORT]: PerformanceReportComponent,
      [COMPONENTS_NAMES_ENUM.AT_PRICE]: AtPriceComponent,
      [COMPONENTS_NAMES_ENUM.RANKING_BROKER]: RankingBrokerComponent,
      [COMPONENTS_NAMES_ENUM.TRADE_IDEA]: TradeIdeaComponent,
      [COMPONENTS_NAMES_ENUM.RENT_BTC]: RentComponent,
      [COMPONENTS_NAMES_ENUM.FLOW_INVESTOR]: FlowInvestorsComponent,
      [COMPONENTS_NAMES_ENUM.MARKET_RESUME]: MarketSummaryComponent,
      [COMPONENTS_NAMES_ENUM.RISK_MANAGEMENT]: ModalRiskManagementComponent,
    };
  }

  constructor(
    private componentService: ComponentsService,
    private route: ActivatedRoute,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.workspaceId = params['workspace_id'];
      this.componentId = params['component_id'];
      console.log(
        `[Open Window] Workspace ID: ${this.workspaceId}, Component ID: ${this.componentId}`
      );
    });
  }

  ngAfterViewInit(): void {
    this.initStandaloneComponent();
  }

  private initStandaloneComponent() {
    const availableComponents =
      StandaloneWindowComponent.AVAIABLE_COMPONENT_MAP;
    this.componentService
      .getComponentById(this.componentId)
      .subscribe((data: IWorkSpaceComponet<IMetadata<any>>) => {
        const componentClass = availableComponents[data.type];
        if (componentClass) {
          this.viewContainerRef.clear();
          const componentRef =
            this.viewContainerRef.createComponent<any>(componentClass);
          componentRef.instance.component = data;
          componentRef.instance.height =
            data.metadata.componentResize?.height ??
            data.metadata.layout.height;
          componentRef.instance.width =
            data.metadata.componentResize?.width ?? data.metadata.layout.width;
          componentRef.changeDetectorRef.detectChanges();
        }
      });
  }
}
