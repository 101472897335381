import { Dictionary } from '@core/models';
import { LABELS_QTTY_GROUP_STOCK } from '@shared/components/stock-chart/parts/modal-more-options/const/stock-chart-modal.config.const';
import { ORDER_CONFIRM_ACTIONS_ENUM } from '@shared/enum/buyOrSell.enum';
import {
  HEADER_BUTTONS_ENUM,
  INFO_USER_OPTIONS_ENUM,
} from '../enum/header.enum';
import {
  IConfigTabs,
  IHeaderButtons,
  IHeaderOptions,
  INegotiationPermissions,
  IShortcutTabs,
} from '../interfaces/header.interfaces';

export const HEADER_KEYS = {
  INIT: 'INIT_HEADER',
};

export const TEMP_TAB_HEADER = {
  id: '',
  name: '',
  user_id: '',
  active: false,
  visible: true,
  editMode: true,
  components: [],
};

export const TOOLTIPS = {
  LOCK: `
    <div style="line-height: 17px;">
      <p class="fs-6 mb-1 fw-bold">Travar Workspace</p>
      <span class="fs-7 fw-bold">
        A trava da Workspace evita mudanças acidentais dos componentes.
      </span>
    </div>
  `,
  WITHOUT_TRADING_TOKEN: `
    <div style="line-height: 17px;">
      <p class="fs-6 mb-1 fw-bold">Token de negociação</p>
      <span class="fs-7 fw-bold">
        Quando estiver com sua corretora selecionada, ative seu token para operar durante esta sessão.
      </span>
    </div>
  `,
  WITH_TRADING_TOKEN: `
    <div style="line-height: 17px;">
      <p class="fs-6 mb-1 fw-bold">Token de negociação</p>
      <span class="fs-7 fw-bold">
        Oba! Você já validou o seu token de segurança para operar durante esta sessão.
      </span>
    </div>
  `,
};

const preffixOrderSubLabel = 'Ative ou desative a confirmação ao';

export const ORDERS_PERSMISSIONS = [
  {
    enum: ORDER_CONFIRM_ACTIONS_ENUM.RESET,
    label: 'Zerar posição',
    form: 'reset',
    subLabel: `${preffixOrderSubLabel} zerar posição`,
  },
  {
    enum: ORDER_CONFIRM_ACTIONS_ENUM.INVERT,
    label: 'Inverter posição',
    form: 'invert',
    subLabel: `${preffixOrderSubLabel} inverter a posição`,
  },
  {
    enum: ORDER_CONFIRM_ACTIONS_ENUM.CANCEL,
    label: 'Cancelamento de ordens',
    form: 'cancel',
    subLabel: `${preffixOrderSubLabel} cancelar uma ordem`,
  },
  {
    enum: ORDER_CONFIRM_ACTIONS_ENUM.DISPLAY_SPLIT_CONFIRMATION,
    label: 'Ordem fracionária (split)',
    form: 'display_split_confirmation',
    subLabel: `${preffixOrderSubLabel} enviar uma ordem fracionária`,
  },
];

export const NEGOTIATION_PERMISSIONS: INegotiationPermissions[] = [
  {
    enum: ORDER_CONFIRM_ACTIONS_ENUM.SIMULATOR_VISIBILITY,
    form: 'simulator_visibility',
    label: 'Simulador',
    subLabel: `Ative ou desative a opção do simulador`,
  },
  {
    enum: ORDER_CONFIRM_ACTIONS_ENUM.QTTY_GROUP_STOCK,
    form: 'qtty_group_stock',
    label: LABELS_QTTY_GROUP_STOCK.title,
    subLabel: LABELS_QTTY_GROUP_STOCK.subTitle,
  },
  {
    enum: ORDER_CONFIRM_ACTIONS_ENUM.USE_SPLIT,
    label: 'Ordem fracionada (split)',
    form: 'use_split',
    subLabel: `Ative ou desative o envio de ordens fracionadas`,
  },
];

export const SHORT_CUTS_TABS: IShortcutTabs[] = [
  {
    ref: 'GERAL',
    title: 'Gerais',
    active: true,
  },
  {
    ref: 'CHART',
    title: 'Gráfico',
    active: false,
  },

  {
    ref: 'SUPERDOM',
    title: 'SuperDom',
    active: false,
  },
];

export const SHORT_CUTS = {
  GERAL: [
    {
      label: 'Tela cheia',
      command: 'F11',
    },
    {
      label: 'Terminal de ativos',
      command: 'Qualquer letra do teclado',
    },
    {
      label: 'Abrir Boleta Compra',
      command: 'Ctrl + F5',
    },
    {
      label: 'Abrir Boleta Venda',
      command: 'Ctrl + F9',
    },
    {
      label: 'Movimentar quantidade',
      command: 'Setas para cima/baixo',
    },
  ],
  CHART: [
    {
      label: 'Navegar no gráfico',
      command: 'Setas para os lados e scroll do mouse',
    },
    {
      label: 'Voltar para o candle atual',
      command: 'Ctrl + seta para direita',
    },
    {
      label: 'Navegar entre os indicadores',
      command: 'Setas para cima/baixo',
    },
    {
      label: 'Adicionar indicador',
      command: 'Enter',
    },
    {
      label: 'Ordem de compra',
      command: 'Shift + clique',
    },
    {
      label: 'Ordem de venda',
      command: 'Alt + clique',
    },
    {
      label: 'Alterar Navegação/Régua',
      command: 'Ctrl',
    },
  ],
  SUPERDOM: [
    {
      label: 'Posicionar ordem',
      command: 'Ctrl + clique',
    },
    {
      label: 'Enviar ordem rápida',
      command: 'Duplo clique na coluna quantidade',
    },
    {
      label: 'Navegar com as ordens',
      command: 'Clique + setas para cima/baixo',
    },
    {
      label: 'Confirmar ordem',
      command: 'Enter',
    },
  ],
};

export const TABS: IConfigTabs[] = [
  {
    ref: 'NEGOTIATION',
    title: 'Negociação',
    active: true,
  },
  {
    ref: 'CONFIRMATIONS',
    title: 'Confirmações ordens',
    active: false,
  },
  {
    ref: 'SOUND_SETTINGS',
    title: 'Sons',
    active: false,
  },
];

export const SOUND_LABELS = [
  'Ordem executada',
  'Ordem confirmada',
  'Ordem cancelada',
  'Alerta',
];

export const DEFAULT_HEADER_BUTTONS = new Dictionary<IHeaderButtons>();
DEFAULT_HEADER_BUTTONS.set(HEADER_BUTTONS_ENUM.SEARCH, {
  id: HEADER_BUTTONS_ENUM.SEARCH,
  icon: 'search',
  tooltip: 'Busca de ativos',
  component: 'icon',
  hasEventClick: true,
});
DEFAULT_HEADER_BUTTONS.set(HEADER_BUTTONS_ENUM.AUCTION, {
  id: HEADER_BUTTONS_ENUM.AUCTION,
  component: 'auction',
  hasEventClick: true,
});
DEFAULT_HEADER_BUTTONS.set(HEADER_BUTTONS_ENUM.FULL_SCREEN, {
  id: HEADER_BUTTONS_ENUM.FULL_SCREEN,
  component: 'icon',
  hasEventClick: true,
});
DEFAULT_HEADER_BUTTONS.set(HEADER_BUTTONS_ENUM.LOCK, {
  id: HEADER_BUTTONS_ENUM.LOCK,
  component: 'lock',
});
DEFAULT_HEADER_BUTTONS.set(HEADER_BUTTONS_ENUM.WHATSAPP, {
  id: HEADER_BUTTONS_ENUM.WHATSAPP,
  component: 'whatsapp',
});
DEFAULT_HEADER_BUTTONS.set(HEADER_BUTTONS_ENUM.SYNC_LOCK, {
  id: HEADER_BUTTONS_ENUM.SYNC_LOCK,
  component: 'icon',
  icon: 'sync_lock',
  hasEventClick: true,
  css: '',
});

export const DEFAULT_OPTIONS_INFO_USER = new Dictionary<IHeaderOptions>();

DEFAULT_OPTIONS_INFO_USER.set(INFO_USER_OPTIONS_ENUM.USER_INFO, {
  id: INFO_USER_OPTIONS_ENUM.USER_INFO,
  component: 'img',
  alt: 'Foto do Usuário',
  width: 34,
  height: 34,
});
DEFAULT_OPTIONS_INFO_USER.set(INFO_USER_OPTIONS_ENUM.SPACE_1, {
  isSpace: true,
});
DEFAULT_OPTIONS_INFO_USER.set(INFO_USER_OPTIONS_ENUM.MANAGE_ACCOUNT, {
  id: INFO_USER_OPTIONS_ENUM.MANAGE_ACCOUNT,
  component: 'icon',
  nameIcon: 'manage_accounts',
  subTitle: 'Meus Dados',
  cssLabel: 'cursor-pointer',
  cssSpan: 'w-100',
});
DEFAULT_OPTIONS_INFO_USER.set(INFO_USER_OPTIONS_ENUM.TOKEN, {
  id: INFO_USER_OPTIONS_ENUM.TOKEN,
  component: 'icon',
  nameIcon: 'verified_user',
  subTitle: 'Token',
  cssLabel: 'cursor-pointer',
  cssSpan: 'w-100',
});
DEFAULT_OPTIONS_INFO_USER.set(INFO_USER_OPTIONS_ENUM.SPACE_2, {
  isSpace: true,
});
DEFAULT_OPTIONS_INFO_USER.set(INFO_USER_OPTIONS_ENUM.MENU_VISIBILITY, {
  id: INFO_USER_OPTIONS_ENUM.MENU_VISIBILITY,
  component: 'icon',
  cssLabel: 'cursor-pointer',
  cssSpan: 'w-100',
});
DEFAULT_OPTIONS_INFO_USER.set(INFO_USER_OPTIONS_ENUM.SHORTCUTS, {
  id: INFO_USER_OPTIONS_ENUM.SHORTCUTS,
  component: 'icon',
  nameIcon: 'keyboard_command_key',
  subTitle: 'Atalhos',
  cssLabel: 'cursor-pointer',
  cssSpan: 'w-100',
});
DEFAULT_OPTIONS_INFO_USER.set(INFO_USER_OPTIONS_ENUM.CONFIGS, {
  id: INFO_USER_OPTIONS_ENUM.CONFIGS,
  component: 'icon',
  nameIcon: 'settings',
  subTitle: 'Configurações',
  cssLabel: 'cursor-pointer',
  cssSpan: 'w-100',
});
DEFAULT_OPTIONS_INFO_USER.set(INFO_USER_OPTIONS_ENUM.CONNECTIONS, {
  id: INFO_USER_OPTIONS_ENUM.CONNECTIONS,
  component: 'icon',
  nameIcon: 'sync',
  subTitle: 'Conexões',
  cssLabel: 'cursor-pointer',
  cssSpan: 'w-100',
});
DEFAULT_OPTIONS_INFO_USER.set(INFO_USER_OPTIONS_ENUM.SPACE_3, {
  isSpace: true,
});
DEFAULT_OPTIONS_INFO_USER.set(INFO_USER_OPTIONS_ENUM.RATING, {
  id: INFO_USER_OPTIONS_ENUM.RATING,
  component: 'icon',
  nameIcon: 'thumbs_up_down',
  subTitle: 'Avaliação',
  cssLabel: 'cursor-pointer',
  cssSpan: 'w-100',
});
DEFAULT_OPTIONS_INFO_USER.set(INFO_USER_OPTIONS_ENUM.THEME, {
  id: INFO_USER_OPTIONS_ENUM.THEME,
  component: 'icon',
  nameIcon: 'dark_mode',
  subTitle: 'Dark theme',
  cssLabel: 'cursor-pointer',
  cssSpan: 'w-100',
  isPreventClick: true,
});
DEFAULT_OPTIONS_INFO_USER.set(INFO_USER_OPTIONS_ENUM.DOWNLOADPKG, {
  id: INFO_USER_OPTIONS_ENUM.DOWNLOADPKG,
  component: 'icon',
  nameIcon: 'download',
  subTitle: 'Baixar plataforma',
  cssLabel: 'cursor-pointer',
  cssSpan: 'w-100',
});
DEFAULT_OPTIONS_INFO_USER.set(INFO_USER_OPTIONS_ENUM.SPACE_4, {
  isSpace: true,
});
DEFAULT_OPTIONS_INFO_USER.set(INFO_USER_OPTIONS_ENUM.LOGOUT, {
  id: INFO_USER_OPTIONS_ENUM.LOGOUT,
  component: 'icon',
  nameIcon: 'logout',
  subTitle: 'Sair',
  cssLabel: 'cursor-pointer',
  cssSpan: 'w-100',
});
