import { ORDER_CONFIRM_ACTIONS_ENUM } from '@shared/enum/buyOrSell.enum';
export const AUCTION = 'LEILAO'; // = liga
export const LIMITED = '2';
export const START_STOP = '4';
export const DOUBLE_START_STOP = 'D';

export const isLimited = (value: string) => value === LIMITED;
export const isAuction = (value: string) => value === AUCTION;
export const isStartStop = (value: string) => value === START_STOP;
export const isDoubleStartStop = (value: string) => value === DOUBLE_START_STOP;
export const isTypeStockIndex = (value: string) => value === 'IDX';
export const isStockFuture = (value: string) => value === 'FUT';
export const isStockFractional = (value: string) =>
  value === 'FRC' || value === 'FRA';
export const isStockCashMarket = (value: string) => value === 'VIS';

export const SCHEDULE_WARN_MODAL_PARAMS = {
  title: 'Agendar Ordem',
  text: 'Deseja agendar sua ordem para o próximo pregão?',
  confirmeButton: 'Sim',
  cancelButton: 'Não',
  askAgain: false,
  showButtons: true,
  isSecondModal: false,
  positionActionEnum: ORDER_CONFIRM_ACTIONS_ENUM.SCHEDULE,
  stock: undefined,
  showPriceStock: false,
};

export const CONNECT_BROKER_WARN_MODEL_PARAMS = {
  title: 'Envio de ordens',
  text: 'Para enviar ordens pelo <b>RocketTrader</b> você precisa estar conectado a um dos nossos parceiros ou ative o <b>Modo Simulador</b> clicando no <b>JoyStick</b> no canto superior direito da tela.',
  showButtons: true,
  cancelButton: 'Talvez depois',
  confirmeButton: 'Ver parceiros',
  askAgain: false,
  isSecondModal: false,
  typeAskAgain: '',
  positionActionEnum: ORDER_CONFIRM_ACTIONS_ENUM.CONNECT_BROKER,
  stock: undefined,
  showPriceStock: false,
};

export const ORDER_SIDE: any = {
  Comprar: 1,
  Compra: 1,
  B: 1,
  buy: 1,
  Vender: 2,
  Venda: 2,
  S: 2,
  sell: 2,
};

export const ORDER_AUCTION_VALIDITY: any = {
  AK: 'Para a Abertura',
  '7K': 'Para o Fechamento',
  A1: 'Para a Abertura',
  '71': 'Para o Fechamento',
  '72': 'Para o Fechamento',
};

export const ORDER_AUCTION_TYPE: any = {
  AK: 'Leilão Abertura',
  '7K': 'Leilão Fechamento',
  A1: 'Leilão Abertura',
  '71': 'Leilão Fechamento',
  '72': 'Leilão Limitada',
};

export const DEFAULT_TIMEENFORCE_ORDERTYPE = {
  '11': 'A mercado',
  '12': 'Limitada',
  '14': 'Start/Stop',
  '17': 'Leilão Fech',
  '27': 'Leilão Limitada',
  '31': 'A mercado',
  '32': 'Limitada',
  '34': 'Start/Stop',
  '41': 'A mercado',
  '42': 'Limitada',
  '44': 'Start/Stop',
  '61': 'A mercado',
  '62': 'Limitada',
  '64': 'Start/Stop',
  '02': 'Limitada',
  '01': 'A mercado',
  '0K': 'A mercado',
  '1A': 'Leilão Abr',
  '04': 'Start/Stop',
  '0D': 'Start Duplo',
  '6D': 'Start Duplo',
  '1D': 'Start Duplo',
  '3D': 'Start Duplo',
  '4D': 'Start Duplo',
};

export const ORDER_CHART_TYPE: {[key: string]: string} = {
  '1': 'a mercado',
  '2': 'limitada',
  '3': 'stop protegido',
  '4': 'stop',
  'A': 'fechamento',
  'D': 'start duplo',
  'K': 'a mercado',
  'R': 'termo',
  'S': 'start',
}

export const INFINITY_READ_STREAM: boolean = true;
export const MESSAGE_MODAL_CANCEL_ORDER = {
  ONLY_ORDER: 'ONLY_ORDER',
  ALL_ORDERS: 'ALL_ORDERS',
  ALL_ORDERS_BY_STOCK: 'ALL_ORDERS_BY_STOCK',
};
