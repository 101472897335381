import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { IWorkSpaceComponet } from '@core/interface';
import {
  AVAIABLE_COMPONENT_ROUTES_MAP,
  DocWorkspace,
  WorkSpaceConfigs,
} from '@core/workspace';
import {
  ContextMenuModel,
  IContextMenuOptions,
} from '@shared/components/context-menu/context-menu.component';

import { ToastService } from '@shared/services';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { Subject, filter, takeUntil } from 'rxjs';
import { WorkspaceHeader } from '../header/workspace-header.service';
import { RxEvent } from '@shared/channel/rx-event';
import {
  IntegrationService,
  isWebViewContext,
} from 'src/app/desktop/integration.service';
import { ConfigService } from '@core/config';

@Component({
  selector: 'app-header-commom-buttons',
  templateUrl: './header-commom-buttons.component.html',
  styleUrls: ['./header-commom-buttons.component.scss'],
})
export class HeaderCommomButtonsComponent implements OnInit, OnDestroy {
  @ViewChild(TemplateRef) content!: TemplateRef<any>;
  @Input() workspaceDoc!: DocWorkspace;
  @Input() set focused(isFocused: boolean) {
    this.isFocused = isFocused;
  }
  @Input() dragDisabled!: boolean;
  @Input() ref!: string;
  @Output() contextMenuClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() flaRemoveComponent = new EventEmitter<string>();
  @Output() flaLink = new EventEmitter<{
    key: string;
    comp: IWorkSpaceComponet;
  }>();
  @Output() flaMaximize = new EventEmitter<{
    key: string;
    maximized: boolean;
  }>();

  showSelectWindow: boolean =
    !inject(ConfigService).isWebAuthenticationChannel();
  isFocused: boolean = false;
  isDisplayContextMenu!: boolean;
  rightClickMenuItems: Array<ContextMenuModel> = [];
  rightClickMenuPositionX!: number;
  rightClickMenuPositionY!: number;
  contextMenuOptions!: IContextMenuOptions;
  zIndex: number = 0;
  isBuyBoleta: boolean = false;
  isSellBoleta: boolean = false;
  focusedComponent!: string;
  windowSelect: { icon: string; color: string; enable: boolean } = {
    icon: 'open_in_new',
    color: 'text-neutral-medium',
    enable: false,
  };
  maximized: { icon: string; color: string; enable: boolean } = {
    icon: 'fullscreen',
    color: 'text-neutral-medium',
    enable: false,
  };
  linked: { icon: string; color: string; enable: boolean } = {
    icon: 'link',
    color: 'text-brand-primary',
    enable: true,
  };
  isDesktop = false;

  private untilDestroy$ = new Subject<void>();

  get headerOptions() {
    return this.workspaceDoc.headerOptions;
  }

  constructor(
    private configs: WorkSpaceConfigs,
    @Inject(DOCUMENT) private doc: Document,
    private globalStock: GlobalSelectedStockSubscription,
    private toastService: ToastService,
    private _workspaceHeader: WorkspaceHeader,
    private rxEvent: RxEvent,
    private integrationServie: IntegrationService
  ) {
    this.isDesktop = isWebViewContext();
    this.isDesktop && (this.showSelectWindow = false);
  }

  ngOnInit(): void {
    this.changeLinked();
    this.subscribeExternalMaximizeChanges();
    this.checkMaximized(this.workspaceDoc.layout.isMaximized);
    this.workspaceDoc.name.includes('Boleta') && this._verifyBoletaType();
    this._workspaceHeader.$linkHeaderButton.subscribe((key: string) =>
      this.link(key)
    );
  }

  private _verifyBoletaType(): void {
    this.isBuyBoleta = this.workspaceDoc.name == 'Boleta Compra';
    this.isSellBoleta = this.workspaceDoc.name == 'Boleta Venda';
  }

  ngOnDestroy(): void {
    this.untilDestroy$.next();
    this.untilDestroy$.complete();
  }

  remove(key: string) {
    // todo: implementar sync com o desktop
    isWebViewContext() &&
      this.integrationServie.closeComponent(this.workspaceDoc.id);
    if (!this.dragDisabled) {
      this.flaRemoveComponent.emit(key);
    } else {
      this.disabledWorkspaceToast();
    }
  }

  private disabledWorkspaceToast = () =>
    this.toastService.showToast('warning', `Suas workspaces estão bloqueadas.`);

  handleMenuItemClick(data: any) {
    this.isDisplayContextMenu = false;
    this.contextMenuClick.emit({
      ...data,
      component: { ...this.workspaceDoc.doc },
    });
  }

  onCloseContextMenu = () => {
    this.isDisplayContextMenu = false;
  };

  link(key: string) {
    this.workspaceDoc.headerOptions.link =
      !this.workspaceDoc.headerOptions.link;
    this.changeLinked();
    this.workspaceDoc.headerOptions.link &&
      !this.globalStock.getGlobalStockSelected() &&
      this.globalStock.changeGlobalStock(
        this.workspaceDoc.doc.metadata.component.stock
      );
    this.flaLink.emit({ key, comp: this.workspaceDoc.doc });
  }

  private changeLinked(): void {
    if (this.workspaceDoc?.headerOptions?.link) {
      this.linked = {
        icon: 'link',
        color: 'text-brand-primary',
        enable: true,
      };
    } else {
      this.linked = {
        icon: 'link_off',
        color: 'text-neutral-medium',
        enable: false,
      };
    }
  }

  private subscribeExternalMaximizeChanges() {
    this.configs.maximizedComponent
      .pipe(
        takeUntil(this.untilDestroy$),
        filter((item: any) => item.ref === this.workspaceDoc.id)
      )
      .subscribe((item: any) => {
        this.maximized.enable !== item.isMaximized && this.maximize();
      });
  }

  private checkMaximized(enable?: boolean): void {
    if (enable) {
      this.maximized = {
        icon: 'fullscreen_exit',
        color: 'text-brand-primary',
        enable: true,
      };
      this.workspaceDoc.enterFullScreen();
      this.zIndex = 1;
    } else {
      this.maximized = {
        icon: 'fullscreen',
        color: 'text-neutral-medium',
        enable: false,
      };
      this.workspaceDoc.exitFullScreen();
      this.zIndex = 0;
    }
  }

  maximize() {
    if (isWebViewContext()) {
      this.rxEvent.emit('COMPONENT_FULLSCREEN', {
        id: this.workspaceDoc.id,
      });
      return;
    }

    if (this.dragDisabled) {
      this.disabledWorkspaceToast();
      return;
    }
    this.maximized.enable = !this.maximized.enable;
    this.checkMaximized(this.maximized.enable);
    this.configs.updateSizes([this.workspaceDoc.doc], this.maximized.enable);
    this.flaMaximize.emit({
      key: this.workspaceDoc.id,
      maximized: this.maximized.enable,
    });
  }

  selectWindow() {
    const { id: component_id, type } = this.workspaceDoc.doc;
    const url = `${window.location.origin}/view/${AVAIABLE_COMPONENT_ROUTES_MAP[type]}/${component_id} `;
    const windowFeatures = `left=${1500},top=${300},width=800,height=600,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes`;
    window.open(url, '_blank', windowFeatures);
  }
}
