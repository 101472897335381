export const arrayIntersections = (array1: any[], array2: any[]) => {
  const set1 = new Set(array1);
  const equals = [...new Set(array2.filter((item) => set1.has(item)))];
  const diff = [
    ...new Set([...array1, ...array2].filter((item) => !set1.has(item))),
  ];
  return { equals, diff };
};

export const removeDuplicates = (arr: any) => {
  const unique = arr.split(',').reduce((acc: any, curr: any) => {
    if (!acc.includes(curr)) acc.push(curr);
    return acc;
  }, []);
  return unique;
};

export const toString = (number: any, padLength: any) => {
  return number.toString().padStart(padLength, '0');
};
