export const ORDER_PARAM_HELPER = {
  STOCK_CHART_DRAG_AND_DROP: 'STOCK_CHART_DRAG_AND_DROP',
  STOCK_CHART_HEADER_BUTTON_SELL_MARKET:
    'STOCK_CHART_HEADER_BUTTON_SELL_MARKET',
  STOCK_CHART_HEADER_BUTTON_BUY_MARKET: 'STOCK_CHART_HEADER_BUTTON_BUY_MARKET',
  STOCK_CHART_RESET: 'STOCK_CHART_RESET',
  STOCK_TRADE_BUTTON: 'STOCK_TRADE_BUTTON',
  SUPER_DOM_DOUBLE_CLICK_LINE_AND_INPUT_VALUE_BOOK:
    'SUPER_DOM_DOUBLE_CLICK_LINE_AND_INPUT_VALUE_BOOK',
  SUPER_DOM_DOUBLE_CLICK_LINE_BOOK: 'SUPER_DOM_DOUBLE_CLICK_LINE_BOOK',
  SUPER_DOM_DRAG_AND_DROP_LINE_BOOK: 'SUPER_DOM_DRAG_AND_DROP_LINE_BOOK',
  SUPER_DOM_CTRL_CLICK_LINE_BOOK: 'SUPER_DOM_CTRL_CLICK_LINE_BOOK',
  SUPER_DOM_BUTTON_RESET: 'SUPER_DOM_BUTTON_RESET',
  SUPER_DOM_BUTTON_INVERT: 'SUPER_DOM_BUTTON_INVERT',
  SUPER_DOM_BUTTON_BUY: 'SUPER_DOM_BUTTON_BUY',
  SUPER_DOM_BUTTON_SELL: 'SUPER_DOM_BUTTON_SELL',
  SUPER_DOM_BUTTON_BUY_MARKET: 'SUPER_DOM_BUTTON_BUY_MARKET',
  SUPER_DOM_BUTTON_SELL_MARKET: 'SUPER_DOM_BUTTON_SELL_MARKET',
  STOCK_CHART_RIGHT_CLICK: 'STOCK_CHART_RIGHT_CLICK',
  STOCK_CHART_TRADE_BUTTON_BUY_MARKET: 'STOCK_CHART_TRADE_BUTTON_BUY_MARKET',
  STOCK_CHART_TRADE_BUTTON_SELL_MARKET: 'STOCK_CHART_TRADE_BUTTON_SELL_MARKET',
  STOCK_CHART_TRADE_BUTTON_BUY: 'STOCK_CHART_TRADE_BUTTON_BUY',
  STOCK_CHART_TRADE_BUTTON_SELL: 'STOCK_CHART_TRADE_BUTTON_SELL',
  STOCK_CHART_TRADE_BUTTON_RESET: 'STOCK_CHART_TRADE_BUTTON_RESET',
  STOCK_CHART_TRADE_BUTTON_INVERT: 'STOCK_CHART_TRADE_BUTTON_INVERT',
  RIGHT_CLICK_BUY_STOP: 'RIGHT_CLICK_BUY_STOP',
  RIGHT_CLICK_SELL_STOP: 'RIGHT_CLICK_SELL_STOP',
  RIGHT_CLICK_BUY_LIMITED: 'RIGHT_CLICK_BUY_LIMITED',
  RIGHT_CLICK_SELL_LIMITED: 'RIGHT_CLICK_SELL_LIMITED',
  TRADE_IDEA_BUY_LIMITED: 'TRADE_IDEA_BUY_LIMITED',
  TRADE_IDEA_SELL_LIMITED: 'TRADE_IDEA_SELL_LIMITED',
  STOCK_TABLE_TRADER_VIEW_BUY: 'STOCK_TABLE_TRADER_VIEW_BUTTON_BUY',
  STOCK_TABLE_TRADER_VIEW_SELL: 'STOCK_TABLE_TRADER_VIEW_BUTTON_SELL',
};
