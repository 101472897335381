<rocket-modal>
  <rocket-modal-header [hideCloseButton]="true">
    <div>
      <h3 class="modal-title">Configurações</h3>
    </div>
  </rocket-modal-header>
  <app-rocket-modal-body>
    <fla-tab>
      <fla-tab-header
        *ngFor="let tab of tabHeaders"
        [ref]="tab.ref"
        [title]="tab.title"
        [active]="tab.active"
        (flaChange)="tabChanges(tab.ref)"
      ></fla-tab-header>

      <fla-tab-body [ref]="tabNameEnum.ESTILO" [active]="true">
        <ng-template
          *ngTemplateOutlet="
            inputs;
            context: { data: styles, form: formStyles }
          "
        ></ng-template>
        <div class="d-flex flex-column w-100 pb-2">
          <div class="d-flex gap-3">
            <app-stock-chart-modal-config-candle
              #configCandle
              [configs]="data.config.chart"
              [refComponent]="data.ref"
              class="w-50"
              (color)="reset = false"
            ></app-stock-chart-modal-config-candle>
            <app-stock-chart-modal-config-volume
              #configVolume
              [configs]="data.config.chart"
              [refComponent]="data.ref"
              class="w-50"
              (color)="reset = false"
            ></app-stock-chart-modal-config-volume>
          </div>
        </div>
        <div class="d-flex w-100 border-top border-thin pt-3 border-bottom-0">
          <ng-template *ngTemplateOutlet="lineInput"></ng-template>
        </div>
      </fla-tab-body>
      <fla-tab-body [ref]="tabNameEnum.NEGOCIACAO">
        <ng-template
          *ngTemplateOutlet="
            inputs;
            context: { data: negotiations, form: formNegotiation }
          "
        ></ng-template>
      </fla-tab-body>
      <fla-tab-body [ref]="tabNameEnum.EVENTOS">
        <ng-template
          *ngTemplateOutlet="
            inputs;
            context: { data: events, form: formEvents }
          "
        ></ng-template>
      </fla-tab-body>
      <fla-tab-body [ref]="tabNameEnum.ATALHOS">
        <ng-template
          *ngTemplateOutlet="
            inputs;
            context: { data: shortcuts, form: formShortcut }
          "
        ></ng-template>
      </fla-tab-body>
    </fla-tab>

    <div
      class="d-flex align-items-start my-6 align-flex-start justify-content-between"
    >
      <div>
        <div tooltip="Restaurar padrão original">
          <rocket-button
            label="Restaurar Padrão"
            css="log-button p-0 text-brand-primary"
            type="fill"
            (rocketClick)="resetConfig()"
          ></rocket-button>
        </div>
      </div>
      <div>
        <rocket-button
          label="Cancelar"
          type="fill"
          css="mr-3"
          [isSmall]="true"
          (rocketClick)="onClose(false)"
        ></rocket-button>
        <rocket-button
          label="Confirmar"
          [isSmall]="true"
          (rocketClick)="onClose(true)"
        ></rocket-button>
      </div>
    </div>
  </app-rocket-modal-body>
</rocket-modal>

<ng-template #inputs let-data="data" let-form="form">
  <ng-container [formGroup]="form">
    <div class="inputs-group d-flex justify-content-between flex-wrap w-100">
      <ng-container *ngFor="let input of data">
        <ng-container
          *ngTemplateOutlet="
            singleInput;
            context: { input: input, parent: null }
          "
        ></ng-container>

        <ng-template #singleInput let-input="input" let-parent="parent">
          <div [class]="'input-option ' + input.cssDiv" [ngClass]="{'border-0': input.collapseOpen}">
            <span class="text-neutral-medium fs-5" *ngIf="input.divTitle">
              {{ input.divTitle }}
            </span>
            <ng-container *ngIf="input.type === inputTypes.SELECT">
              <fla-select
                [label]="input.title"
                [formControlName]="input.formControlName"
                bindLabel="label"
                bindValue="cod"
                margin="m-0"
                [isSmall]="true"
                [list]="input.options!"
                [searchable]="false"
                labelPlacement="horizontal"
                [selectCss]="input.css ?? ''"
                labelCss="fs-base text-white fw-normal"
                css="justify-content-between"
                (flaChange)="updateShortcut(input)"
              ></fla-select>
              <span
                *ngIf="input.subTitle"
                class="fs-6 text-neutral-medium pl-0 fw-bold"
                >{{ input.subTitle }}</span
              >
            </ng-container>
            <div
              *ngIf="input.type === inputTypes.CHECKBOX"
              class="d-flex align-items-center justify-content-between"
            >
              <label *ngIf="input.collapseChildren" [class]="input.css">{{
                input.title
              }}</label>
              <div [ngClass]="input.collapseChildren ? 'w-25 d-flex' : 'w-100'">
                <div *ngIf="input.collapseChildren" class="w-50 text-right">
                  <button
                    class="btn btn-icon"
                    [flaCollapse]="input.formControlName + '_collapse'"
                    [flaCollapseIsOpen]="input.collapseOpen"
                    (click)="input.collapseOpen = !input.collapseOpen"
                  >
                    <fla-icon
                      [name]="
                        input.collapseOpen
                          ? 'keyboard_arrow_up'
                          : 'keyboard_arrow_down'
                      "
                    ></fla-icon>
                  </button>
                </div>
                <app-check
                  [label]="input.collapseChildren ? null : input.title"
                  [subLabel]="input.subTitle"
                  [isSwitch]="true"
                  [isTextLeft]="true"
                  [css]="
                    (input.collapseChildren ? 'w-50' : 'w-100') +
                    ' justify-content-between ' +
                    (input.css ? input.css : '')
                  "
                  [formControlName]="input.formControlName"
                  [isChecked]="form.get(input.formControlName)?.value"
                  [disabled]="
                    (parent && !form.get(parent.formControlName)?.value) ||
                    input.isDisable
                  "
                  (flaChange)="updateShortcut(input)"
                  [infoText]="input.hint"
                >
                </app-check>
              </div>
            </div>
            <div
              *ngIf="input.type === inputTypes.INPUT_COUNT"
              class="d-flex align-items-center justify-content-between"
            >
              <div class="d-flex flex-column">
                <div class="d-flex gap-2">
                  <span>{{ input.title }}</span>
                  <div class="top-negative">
                    <fla-icon
                      *ngIf="input.hint"
                      css="user-select-none"
                      [tooltip]="input.hint"
                      size="icon-size-tiny"
                      name="info"
                    ></fla-icon>
                  </div>
                </div>
                <span
                  *ngIf="input.subTitle"
                  class="fs-6 text-neutral-medium fw-bold"
                  >{{ input.subTitle }}</span
                >
              </div>
              <app-input-count
                [title]="input.title"
                margin="m-1"
                [isModal]="true"
                [isSmall]="true"
                [clearable]="false"
                [initialValue]="form.get(input.formControlName)!.value"
                [formControlName]="input.formControlName"
                mask="separator.0"
                css="new-button-size"
                (rtChange)="updateShortcut(input)"
                [symbolSuffix]="input.suffix"
                [incrementValue]="input.incrementValue || 1"
                [max]="input.max || maxInput"
                [maxLength]="input.max || maxInput"
                [useMaxAsLastValue]="true"
                [isDisable]="input.isDisable ?? false"
                [emitEventOnBackToSourceValue]="true"
                [allowNegativeValue]="false"
              ></app-input-count>
            </div>
          </div>
          <ng-container *ngIf="input.children">
            <div
              *ngIf="input.collapseChildren; else children"
              [id]="input.formControlName + '_collapse'"
              class="border-0 w-100 inputs-group justify-content-between flex-wrap"
            >
              <ng-container *ngTemplateOutlet="children"></ng-container>
            </div>
            <ng-template #children>
              <ng-container *ngFor="let child of input.children">
                <ng-container
                  *ngTemplateOutlet="
                    singleInput;
                    context: { input: child, parent: input }
                  "
                ></ng-container>
              </ng-container>
            </ng-template>
          </ng-container>
        </ng-template>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #lineInput>
  <div class="d-flex align-items-center justify-content-between w-100">
    <label>Linhas</label>
    <div class="d-flex">
      <div class="text-right">
        <button class="btn btn-icon" (click)="openChartLinesOptionsModal()">
          <fla-icon name="settings" size="icon-size-tiny"></fla-icon>
        </button>
      </div>
      <!-- <app-check
        [isSwitch]="true"
        [isTextLeft]="true"
        css="w-50 justify-content-between"
        [formControlName]="lines[0].formControlName"
        [isChecked]="form.get(lines[0].formControlName)?.value"
        (flaChange)="updateChartLines()"
        >
      </app-check> -->
    </div>
  </div>
</ng-template>
