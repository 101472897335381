<form
  [formGroup]="formFilter"
  class="position-relative h-100"
  *ngIf="!isCleanForm && formFilter"
>
  <fla-input
    #nameFilter
    fla-focus
    formControlName="name"
    placeholder="Digite o nome do filtro."
    margin="mb-3"
    css="pr-2"
    [requiredText]="nameError"
    [isRequired]="true"
    [max]="70"
    [maxLength]="70"
  >
  </fla-input>

  <div class="w-100 d-flex flex-column mb-3">
    <span class="fw-bold">Condições</span>
    <span class="fs-6">
      Insira as condições de sua estratégia nos campos a abaixo.
    </span>
  </div>

  <div
    formArrayName="filters"
    [ngClass]="{
      'overflow-y-scroll': filtersForm.controls.length > 5
    }"
    style="max-height: calc(100% - 236px)"
  >
    <div
      *ngFor="let filter of filtersForm.controls; let i = index"
      [formGroupName]="i"
      class="w-100 d-inline-flex align-items-center mb-2 position-relative"
      [ngClass]="{
        'opacity-medium': !formFilter.get('name')?.value
      }"
    >
      <span class="mr-1">Se </span>

      <section
        class="border d-flex justify-content-between mr-1 py-1 round-sm cursor-pointer position-relative"
        style="height: 30px"
        [style]="
          !(
            screeningFilterTemp.conditional[i]['leftSide'].type ===
            'CANDLE_PATTERNS'
          )
            ? 'width: 36%;'
            : 'width: 100%;'
        "
        [ngClass]="{
          'border-brand-primary':
            fieldSelect?.line === i && fieldSelect?.side === 'leftSide'
        }"
        [tabindex]="i + '_leftSide'"
        (focus)="selectFieldFocus(i, 'leftSide')"
        (click)="selectField(i, 'leftSide')"
      >
        <div class="pl-1 fs-5 truncate d-block">
          {{ filter.get('leftSide')?.value }}
        </div>
        <div
          class="position-absolute right-0 top-0"
          *ngIf="
            !!screeningFilterTemp.conditional[i]['leftSide'].filter ||
            !!(screeningFilterTemp.conditional[i]['leftSide'].type === 'CUSTOM')
          "
        >
          <rocket-button
            type="icon"
            css="py-1"
            (rocketClick)="editConditions(i, 'leftSide')"
          >
            <fla-icon
              name="edit"
              size="icon-size-micro"
              css="lh-none"
            ></fla-icon>
          </rocket-button>
        </div>
      </section>

      <section
        style="width: 14%"
        *ngIf="
          !(
            screeningFilterTemp.conditional[i]['leftSide'].type ===
            'CANDLE_PATTERNS'
          )
        "
      >
        <app-dropdown menuAlign="dropdown-menu-left">
          <a
            type="button"
            class="nav-link p-1 lh-sm dropdown-toggle fs-5 fw-bold border px-2"
            style="height: 30px; border-radius: 4px"
            appDropdownButton
            [rtDropnAutoClose]="true"
            [rtDropnDisabled]="!isNameValid"
          >
            {{ getRelation(i) }}
          </a>
          <a
            *ngFor="let relation of relationList"
            class="dropdown-item"
            type="button"
            [tooltip]="relation.description"
            (click)="changeRelation(relation, i)"
          >
            {{ relation.label }}
          </a>
        </app-dropdown>
      </section>

      <section
        *ngIf="
          !(
            screeningFilterTemp.conditional[i]['leftSide'].type ===
            'CANDLE_PATTERNS'
          )
        "
        class="border d-flex justify-content-between ml-1 py-1 round-sm cursor-pointer"
        style="height: 30px; width: 36%"
        [ngClass]="{
          'border-brand-primary':
            fieldSelect?.line === i && fieldSelect?.side === 'rightSide'
        }"
        [tabindex]="i + '_rightSide'"
        (focus)="selectFieldFocus(i, 'rightSide')"
        (click)="selectField(i, 'rightSide')"
      >
        <div class="pl-1 fs-5 truncate">
          {{ filter.get('rightSide')?.value }}
        </div>
        <div
          class="d-flex"
          *ngIf="
            !!screeningFilterTemp.conditional[i]['rightSide'].filter ||
            !!(
              screeningFilterTemp.conditional[i]['rightSide'].type === 'CUSTOM'
            )
          "
        >
          <rocket-button
            type="icon"
            css="py-1"
            (rocketClick)="editConditions(i, 'rightSide')"
          >
            <fla-icon
              name="edit"
              size="icon-size-micro"
              css="lh-none"
            ></fla-icon>
          </rocket-button>
        </div>
      </section>

      <div *ngIf="i === 0">
        <rocket-button
          type="icon"
          (rocketClick)="addItens()"
          tooltip="Adicionar condição"
        >
          <fla-icon name="add" size="icon-size-micro"></fla-icon>
        </rocket-button>
      </div>

      <div *ngIf="filtersForm.controls.length > 1 && i !== 0">
        <rocket-button
          type="icon"
          (rocketClick)="removeItems(i)"
          tooltip="Remover condição"
        >
          <fla-icon name="remove" size="icon-size-micro"></fla-icon>
        </rocket-button>
      </div>
    </div>
  </div>

  <div class="w-100 d-flex flex-column mt-3">
    <div class="w-100 d-flex align-items-baseline mt-2">
      <span class="mr-2 fw-bold">Então</span>
      <div
        class="border mr-2 py-1 round-sm fw-bold pl-2 d-flex align-items-center"
        style="height: 30px; width: 118px"
      >
        <fla-icon
          name="notifications"
          size="icon-size-micro"
          css="text-brand-support-secondary mr-2"
        ></fla-icon>
        Alerta
      </div>
    </div>
  </div>

  <div class="w-100 position-absolute bottom-0 pr-2 d-flex justify-content-end">
    <rocket-button
      type="outline"
      label="Cancelar"
      [isDisabled]="!screeningFilter"
      (rocketClick)="cancelEdition()"
    ></rocket-button>
    <rocket-button
      css="ml-2"
      label="Salvar"
      [isDisabled]="!isFormValid || !isNameValid || screeningFilterTemp.isStart"
      (rocketClick)="saveEdition()"
    ></rocket-button>
  </div>
</form>
