import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'noSelectedAccount' })
export class NoSelectedAccountPipe implements PipeTransform {
  transform(value: any[], idBrokerSelected?: any) {
    return !value
      ? []
      : value.filter((item) => item.id_broker !== idBrokerSelected);
  }
}
