import { Injectable } from '@angular/core';
import { AbstractCacheService } from './AbstractCacheService';
import { IStockCompetitors } from '@shared/components/business-profile/business-profile.interface';

@Injectable({
  providedIn: 'root',
})
export class StockCompetitorsCacheService extends AbstractCacheService<
  IStockCompetitors[]
> {}
