import { IOrders } from '@core/interface';
import { deepClone } from '@shared/rocket-components/utils/functions';
import {
  isDoubleStartStop,
  isStartStop,
} from '@shared/constants/general.contant';
import { TYPE_ORDE_SIDE_STRING_ENUM } from '@shared/enum/buyOrSell.enum';
import { SuperDomService } from '@shared/components/super-dom/service/super-dom.service';

export class MountingLineOrders {
  constructor(private superDomService: SuperDomService) {}

  public mountList(
    orders: IOrders[],
    ordersHash: any,
    componentPrice: number,
    tableDefault: any[],
    tableDefaultHash: any
  ): any {
    const table = this.makeTableDefault(tableDefault, tableDefaultHash)
    tableDefault = table.tableDefault
    tableDefaultHash = table.tableDefaultHash
    let ordersList: any = {};

    orders.forEach((item: IOrders) => {
      if (this.checkDoubleStartStop(item)) {
        const gain = this.makeTableDoubleStartStop(
          true, 
          item, 
          ordersList, 
          tableDefault, 
          tableDefaultHash, 
          componentPrice
        )
        ordersList = gain.ordersList
        tableDefaultHash = gain.tableDefaultHash
        tableDefault = gain.tableDefault
        const loss = this.makeTableDoubleStartStop(
          false, 
          item, 
          ordersList, 
          tableDefault, 
          tableDefaultHash, 
          componentPrice
        )
        ordersList = loss.ordersList
        tableDefaultHash = loss.tableDefaultHash
        tableDefault = loss.tableDefault
        return;
      }

      const price = this.checkOrderIsStop(item) ? item.price_stop : item.price;
      const hash = tableDefaultHash[+price];
      const previousOrderHash = ordersHash[item.id_order];
      if (previousOrderHash && previousOrderHash.price != +price) {
        const previousHash = tableDefaultHash[+previousOrderHash.price];
        const previousPrice = this.checkOrderIsStop(previousOrderHash)
          ? previousOrderHash.price_stop
          : previousOrderHash.price;
        const previousInfo = this.makeTable(
          tableDefault,
          tableDefaultHash,
          previousHash,
          previousOrderHash,
          previousPrice,
          componentPrice,
          false
        );
        tableDefaultHash = previousInfo.tableDefaultHash;
        tableDefault = previousInfo.tableDefault;
      }
      const actual = this.makeTable(
        tableDefault,
        tableDefaultHash,
        hash,
        item,
        price,
        componentPrice
      );
      tableDefaultHash = actual.tableDefaultHash;
      tableDefault = actual.tableDefault;
      ordersHash[item.id_order] = item;
      /*if (hash) {
        const orderIsBuySide = item.side === TYPE_ORDE_SIDE_STRING_ENUM.BUY;
        const hasQttyOrder = item.qtty_left;
        const orderIsStopView = this.superDomService.checkOrderIsStopView(
          item,
          item.side,
          componentPrice
        );
        const table = {
          ...tableDefault[hash.arrayIndex],
          hasQttyOrderBuy: orderIsBuySide ? hasQttyOrder : null,
          hasQttyOrderSell: !orderIsBuySide ? hasQttyOrder : null,
          orderIsStopViewBuy: orderIsBuySide ? orderIsStopView : false,
          orderIsStopViewSell: !orderIsBuySide ? orderIsStopView : false,
        };
        tableDefaultHash[+price] = { ...tableDefaultHash[+price], ...table };
        tableDefault[hash.arrayIndex] = table;
      }*/
      ordersList[+price] = item;
    });
    return { ordersList, tableDefaultHash, tableDefault, ordersHash };
  }

  private makeTableDefault(    
    tableDefault: any[],
    tableDefaultHash: any,
  ) {
    tableDefault = tableDefault.map((item) => {
      const price = item.price
      const table = {
        ...item,
        hasQttyOrderBuy: null,
        hasQttyOrderSell: null,
        orderIsStopViewBuy: false,
        orderIsStopViewSell: false,
      }
      tableDefaultHash[+price] = { ...tableDefaultHash[+price], ...table };
      return table
    })
    return { tableDefaultHash, tableDefault };
  }

  private makeTable(
    tableDefault: any[],
    tableDefaultHash: any,
    hash: any,
    item: IOrders,
    price: number,
    componentPrice: number,
    isActual: boolean = true
  ) {
    if (hash) {
      const orderIsBuySide = item.side === TYPE_ORDE_SIDE_STRING_ENUM.BUY;
      const hasQttyOrder = item.qtty_left;
      const orderIsStopView = this.superDomService.checkOrderIsStopView(
        item,
        item.side,
        componentPrice
      );
      const table = {
        ...tableDefault[hash.arrayIndex],
        hasQttyOrderBuy:
          orderIsBuySide && isActual && hasQttyOrder ? hasQttyOrder : null,
        hasQttyOrderSell:
          !orderIsBuySide && isActual && hasQttyOrder ? hasQttyOrder : null,
        orderIsStopViewBuy:
          orderIsBuySide && isActual && hasQttyOrder ? orderIsStopView : false,
        orderIsStopViewSell:
          !orderIsBuySide && isActual && hasQttyOrder ? orderIsStopView : false,
      };
      tableDefaultHash[+price] = { ...tableDefaultHash[+price], ...table };
      tableDefault[hash.arrayIndex] = table;
    }
    return { tableDefaultHash, tableDefault };
  }

  private makeTableDoubleStartStop(isGain: boolean, item: IOrders, ordersList: any, tableDefault: any[], tableDefaultHash: any, componentPrice: number) {
    const key = isGain ? item.personalized_gain_trigger : item.personalized_loss_trigger
    ordersList[+key] = deepClone(item);
    ordersList[+key].isGain = isGain;
    ordersList[+key].price =
      key;
    

    const hash = tableDefaultHash[+key];
    const table = this.makeTable(
      tableDefault,
      tableDefaultHash,
      hash,
      item,
      +key,
      componentPrice
    );

    tableDefault = table.tableDefault
    tableDefaultHash = table.tableDefaultHash

    return {ordersList, tableDefault, tableDefaultHash}
  }

  private checkOrderIsStop(order: any): boolean {
    return !!order && isStartStop(order.cd_order_type);
  }

  private checkDoubleStartStop(order: any): boolean {
    return !!order && isDoubleStartStop(order.cd_order_type);
  }
}
