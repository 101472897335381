import { Injectable } from '@angular/core';
import { AbstractCacheService } from './AbstractCacheService';
import { ISubsidiariesCountries } from '@shared/components/business-profile/business-profile.interface';

@Injectable({
  providedIn: 'root',
})
export class SubsidiariesCountriesCacheService extends AbstractCacheService<
  ISubsidiariesCountries[]
> {}
