import { TFlaToast } from '@shared/rocket-components/toast';
import { Dictionary } from 'src/app/core/models/dictionary.model';

export const NOTIFICATIONS_ORDER_STATUS = new Dictionary<{
  text: string;
  type: TFlaToast;
  isHtml: boolean;
}>();
NOTIFICATIONS_ORDER_STATUS.set('AGUA', {
  text: 'Ordem foi confirmada ;)',
  type: 'success',
});
NOTIFICATIONS_ORDER_STATUS.set('EXEC', {
  text: 'Oba! Sua ordem foi executada ;)',
  type: 'success',
});
NOTIFICATIONS_ORDER_STATUS.set('PARC', {
  text: 'Sua ordem foi parcialmente executada, veja mais em "Ordens & Posição"',
  type: 'info',
});
NOTIFICATIONS_ORDER_STATUS.set('PCAN', {
  text: 'Sua ordem foi parcialmente cancelada, veja mais em "Ordens & Posição"',
  type: 'error',
});
NOTIFICATIONS_ORDER_STATUS.set('REJE', {
  text: 'Sua ordem foi rejeitada, veja mais em " Ordens & Posição"',
  type: 'error',
});
NOTIFICATIONS_ORDER_STATUS.set('PEND', {
  text: 'Oba! Ordem agendada ;)',
  type: 'warning',
});
NOTIFICATIONS_ORDER_STATUS.set('ALTE', {
  text: 'Sua ordem foi alterada',
  type: 'success',
});
NOTIFICATIONS_ORDER_STATUS.set('CANC', {
  text: 'Sua ordem foi cancelada!',
  type: 'success',
});

export const NOTIFICATIONS_ALERT_STATUS = new Dictionary<{
  text: string;
  type: TFlaToast;
  isHtml: boolean;
}>();

NOTIFICATIONS_ALERT_STATUS.set('DEFAULT', {
  text: '',
  type: 'success',
});
