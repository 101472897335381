import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RentChartComponent } from './rent-chart.component';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { RentService } from '../../service/rent.service';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { LoadingModule } from '@shared/components/loading/loading.module';
import { NoInfoModule } from '@shared/components/business-profile/no-info/no-info.module';

@NgModule({
  imports: [CommonModule, TooltipsModule, LoadingModule, NoInfoModule],
  declarations: [RentChartComponent],
  exports: [RentChartComponent],
  providers: [StockChartService, RentService],
})
export class RentChartModule {}
