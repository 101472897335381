import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { FlaTMargin } from '../../types/spacing.types';
import { randomId } from '../../utils/functions';
import { MESSAGES } from '../../utils/message.const';
import { BaseComponent } from '@shared/rocket-components/base-componentes';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SelectService } from './select.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'fla-select[list][bindLabel][bindValue]',
  templateUrl: './select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FlaSelectComponent),
    },
  ],
  styles: [
    `
      ::ng-deep.markArrowKey.ng-select
        ng-dropdown-panel
        .ng-dropdown-panel-items
        .ng-option.ng-option-marked {
        background-color: var(--vm-form-select-dropdown-option-bg);
      }
    `,
  ],
})
export class FlaSelectComponent extends BaseComponent implements AfterViewInit {
  @Input() list!: Array<any>;
  @Input() labelPlacement: 'vertical' | 'horizontal' = 'vertical';
  @Input() bindLabel!: string;
  @Input() bindValue!: string;
  @Input() groupBy!: string;
  @Input() hint!: string;
  @Input() label!: string;
  @Input() css: string = '';
  @Input() selectCss: string = '';
  @Input() labelCss: string = '';
  @Input() margin: FlaTMargin = 'mb-5';
  @Input() placeholder!: string;
  @Input() requiredText: string = MESSAGES['requiredText'];
  @Input() notFoundText: string = MESSAGES['ValueNotFound'];
  @Input() maxItems: number = 10000;
  @Input() multiple: boolean = false;
  @Input() loading: boolean = false;
  @Input() isSmall: boolean = false;
  @Input() searchable: boolean = true;
  @Input() clearable: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() openOnEnter: boolean = false;
  @Input() selectOnTab: boolean = false;
  @Input() hasBorder: boolean = false;
  @Input() isHTMLLabel: boolean = false;
  @Input() markArrowKey: boolean = true;
  @Input() customSearchFn!: any;
  @Input() set isDisable(disabled: boolean) {
    this.disable?.next(disabled);
  }
  @Output() flaOpen = new EventEmitter<any>();
  @Output() flaClose = new EventEmitter<any>();
  @Output() flaSearch = new EventEmitter<any>();
  @Output() flaClear = new EventEmitter<any>();
  @Output() flaAdd = new EventEmitter<any>();
  @Output() flaRemove = new EventEmitter<any>();
  @Output() flaChange = new EventEmitter<any>();
  @ViewChild('selectInput') selectInput!: NgSelectComponent;
  public refId: string = randomId('fla_select');
  public messages = MESSAGES;
  public getLabel = (item: any) => item[this.bindLabel];

  public itemBindLabel(item: any) {
    return item[this.bindLabel];
  }

  public open(): void {
    this.selectService.event({ type: 'OPEN' });
    this.flaOpen.emit();
  }
  public close(): void {
    this.selectService.event({ type: 'CLOSE' });
    this.flaClose.emit();
  }
  public search(event: any): void {
    this.flaSearch.emit(event);
  }
  public clear(): void {
    this.flaClear.emit();
  }
  public add(event: any): void {
    this.flaAdd.emit(event);
  }
  public remove(event: any): void {
    this.flaRemove.emit(event);
  }
  public change(event: any): void {
    this.flaChange.emit(event);
  }

  constructor(
    injector: Injector,
    cdr: ChangeDetectorRef,
    private selectService: SelectService
  ) {
    super(injector, cdr);
  }

  public focus(): void {
    this.selectInput.focus();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.isRequired) {
      this.formControl.setValidators(Validators.required);
    }
  }
}
