import {
  Subject,
  Observable,
  Subscription,
  map,
  filter,
  auditTime,
} from 'rxjs';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ElementRef,
  OnDestroy,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { IMonthDetails, ISate } from './interface/datepicker.interface';
import { isNullOrWhiteSpace } from '../../utils';
import {
  DATE_PICKER_VIEW_TYPE,
  FLA_DATE_PICKER_POSITION,
} from './enum/datepickker.enum';
import { DATE_PICKER_CONFIGS } from './constants/datepicker.constants';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class FlaDatePickerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() initialDate!: Date;
  @Input() useTimepicker: boolean = false;
  @Input() closeClickOutside: boolean = true;
  @Input() closeOnClick: boolean = true;
  @Input() minDate!: Date | undefined;
  @Input() maxDate!: Date | undefined;
  @Input() showDatePicker: boolean = false;
  @Input() isAbsolute: boolean = false;
  @Input() withWeekend: boolean = false;
  @Input() positionAbsolute: FLA_DATE_PICKER_POSITION = 'TOP-RIGHT';
  @Input() showButtons: boolean = true;
  @Output() flaClick = new EventEmitter();
  @Output() closeDatePicker = new EventEmitter();
  refId: string = 'DATE_PICKER';
  todayTimestamp!: number;
  state: ISate = {
    month: 0,
    monthDetails: [],
    selectedDay: 0,
    year: 0,
  };
  daysMap: any = DATE_PICKER_CONFIGS.weekend;
  element!: HTMLElement;
  selectDay!: Date | undefined;
  viewType: DATE_PICKER_VIEW_TYPE = DATE_PICKER_VIEW_TYPE.DAY;
  viewTypeEnum = DATE_PICKER_VIEW_TYPE;
  formTimer!: FormGroup;
  private _dispatchClickOutside$ = new Subject<{ event: any }>();
  private _dispatchClickOutside!: Subscription;
  get onClose(): Observable<{ closed: boolean; value?: string } | undefined> {
    return this._onClose$.asObservable();
  }
  private _onClose$ = new Subject<
    | { closed: boolean; value?: string; hours?: string; minutes?: string }
    | undefined
  >();
  private readonly _monthMap: any = DATE_PICKER_CONFIGS.monthAbrev;
  private readonly _oneDay = 60 * 60 * 24 * 1000;
  private isInitialized: boolean = false;
  private isClose = false;

  constructor(
    //@Inject(DATE_PICKER_TOKEN) private _configs: IDatePickerConfig,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private _fb: FormBuilder
  ) {
    this.formTimer = this._fb.group({
      minutes: [''],
      hours: [''],
    });
  }

  ngOnInit(): void {
    this.todayTimestamp =
      Date.now() -
      (Date.now() % this._oneDay) +
      new Date().getTimezoneOffset() * 1000 * 60;
    const today = new Date(this.todayTimestamp);
    this.selectDay
      ? this._updateState(this.selectDay)
      : this._updateState(today);
    if (this.initialDate) {
      this.setDate(this.initialDate.toString());
    }
    this._dispatchClickOutside = this._dispatchClickOutside$
      .pipe(
        auditTime(100),
        map((data) => {
          const { event } = data;
          let hide = true;
          let offsetParent = event.target.offsetParent;
          for (let i = 0; i <= 10; i++) {
            if (offsetParent?.id === this.refId) {
              hide = false;
            } else {
              offsetParent = offsetParent?.parentElement;
            }
          }
          return hide;
        }),
        filter((hide) => hide)
      )
      .subscribe(() => {
        if (this.showDatePicker) {
          this.showDatePicker = false;
          this.isInitialized = false;
          this._onClose$.next({ closed: true });
          this.flaClick.emit({ closeOutside: true });
          window.removeEventListener('click', this.dispatchClickOutside);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showDatePicker'] && changes['showDatePicker'].currentValue) {
      this.isClose = false;
      if (this.initialDate) {
        if (this.closeClickOutside) {
          setTimeout(() => {
            this._clickOutside();
            this.isInitialized = true;
          }, 200);
        }
        this.setDate(this.initialDate.toString());
      }
    }
  }

  ngOnDestroy(): void {
    this._onClose$.complete();
    window.removeEventListener('click', this.dispatchClickOutside);
  }

  toggle() {
    this.isClose = true;
    this.showDatePicker = false;
    this.isInitialized = false;
    if (!this.showDatePicker) this._onClose$.next(undefined);
    this.closeDatePicker.emit();
  }
  getMonthStr = (month: number) =>
    this._monthMap[Math.max(Math.min(11, month), 0)] || 'Month';

  setDate(dateData: string) {
    /*if (this._configs.format === FLA_DATE_PICKER_FORMAT.DD_MM_YYY) {
      this.selectDay = stringToDate(dateData)!;
    } else {*/
    const dateObject = new Date(dateData);
    dateObject.setSeconds(0, 0);
    const month = dateObject.getMonth();
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();
    this.selectDay = new Date(year, month, day);
    //}
    this._updateState(this.selectDay);
    if (this.useTimepicker && this.showDatePicker) {
      this.setTimer(dateObject);
    }
  }

  setTimer(dateObject: Date) {
    setTimeout(() => {
      this.hoursChanged({ value: dateObject.getHours() });
      this.minutesChanged({ value: dateObject.getMinutes() });
    }, 200);
  }

  onDateClick(day: IMonthDetails): void {
    this.state.selectedDay = day.timestamp;

    if (this.closeOnClick && this.viewType === DATE_PICKER_VIEW_TYPE.DAY) {
      this.showDatePicker = false;
      this._onClose$.next({
        closed: true,
        value: this._getDateStringFromTimestamp(day.timestamp),
      });
    }
    this._onClose$.next({
      closed: false,
      value: this._getDateStringFromTimestamp(day.timestamp),
    });
  }

  setYear(offset: number) {
    if (offset < 0 && this.state.year === this.minDate?.getFullYear()) {
      return;
    }

    if (offset > 1 && this.state.year === this.maxDate?.getFullYear()) {
      return;
    }

    const year = this.state.year + offset;
    this.state.year = year;
    this.state.monthDetails = this._getMonthDetails(year, this.state.month);
  }

  setMonth(offset: number) {
    if (offset < 0 && this.state.month === this.minDate?.getMonth()) {
      return;
    }

    if (offset > 0 && this.state.month === this.maxDate?.getMonth()) {
      return;
    }

    let year = this.state.year;
    let month = this.state.month + offset;
    if (month === -1) {
      month = 11;
      year--;
    } else if (month === 12) {
      month = 0;
      year++;
    }
    this.state.year = year;
    this.state.month = month;
    this.state.monthDetails = this._getMonthDetails(year, month);
  }

  getFormatDate(dateValue: string) {
    const dateData = dateValue.split('-').map((d: string) => parseInt(d, 10));
    if (dateData.length < 3) return null;

    const year = dateData[0];
    const month = dateData[1];
    const date = dateData[2];
    return { year, month, date };
  }

  changeView(value: DATE_PICKER_VIEW_TYPE) {
    this.viewType = value;
  }

  updateView(value: number, type: DATE_PICKER_VIEW_TYPE) {
    this.viewType = DATE_PICKER_VIEW_TYPE.DAY;
    switch (type) {
      case DATE_PICKER_VIEW_TYPE.MONTH:
        this.setMonth(value);
        break;

      case DATE_PICKER_VIEW_TYPE.YEAR:
        this.setYear(value);
        break;
    }
  }

  private _updateState(value: Date) {
    this.state = {
      year: value.getFullYear(),
      month: value.getMonth(),
      selectedDay: value.getTime(),
      monthDetails: this._getMonthDetails(
        value.getFullYear(),
        value.getMonth()
      ),
    };
  }

  private dispatchClickOutside = (event: any) => {
    this.isClose && this._dispatchClickOutside$.next({ event });
  };

  private _clickOutside(): void {
    if (this.closeClickOutside) {
      window.addEventListener('click', this.dispatchClickOutside);
      //this._renderer.listen('window', 'click', this.dispatchClickOutside);
    }
  }

  private _getDayDetails(args: any): IMonthDetails {
    const date = args.index - args.firstDay;
    const day = args.index % 7;
    let prevMonth = args.month - 1;
    let prevYear = args.year;
    if (prevMonth < 0) {
      prevMonth = 11;
      prevYear--;
    }
    const prevMonthNumberOfDays = this._getNumberOfDays(prevYear, prevMonth);
    const _date =
      (date < 0 ? prevMonthNumberOfDays + date : date % args.numberOfDays) + 1;
    const month = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;
    const timestamp = new Date(args.year, args.month, _date).getTime();
    let disabled = month !== 0;

    if (!isNullOrWhiteSpace(this.minDate)) {
      if (
        _date <= this.minDate!.getDate() &&
        args.month <= this.minDate!.getMonth() &&
        this.minDate!.getFullYear() === args.year
      ) {
        disabled = true;
      } else if (
        this.minDate!.getDate() > _date &&
        args.month <= this.minDate!.getMonth() &&
        this.minDate!.getFullYear() <= args.year
      ) {
        disabled = true;
      }
    }
    if (!this.withWeekend && (day === 0 || day === 6)) {
      disabled = true;
    }
    return {
      date: _date,
      day,
      month,
      timestamp,
      dayString: this.daysMap[day],
      disabled,
    };
  }

  private _getNumberOfDays = (year: number, month: number) =>
    40 - new Date(year, month, 40).getDate();

  private _getMonthDetails(year: number, month: number): IMonthDetails[] {
    let currentDay = null;
    let index = 0;
    const firstDay = new Date(year, month).getDay();
    const numberOfDays = this._getNumberOfDays(year, month);
    const monthArray = [];
    const rows = 6;
    const cols = 7;

    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        currentDay = this._getDayDetails({
          index,
          numberOfDays,
          firstDay,
          year,
          month,
        });
        monthArray.push(currentDay);
        index++;
      }
    }
    return monthArray;
  }

  private _getDateStringFromTimestamp(timestamp: number) {
    /*let dateObject = new Date(timestamp);
    let month = dateObject.getMonth() + 1;
    let date = dateObject.getDate();*/
    return timestamp.toString();
    /*switch (this._configs.format) {
      case FLA_DATE_PICKER_FORMAT.DD_MM_YYY:
        return (
          (date < 10 ? '0' + date : date) +
          '/' +
          (month < 10 ? '0' + month : month) +
          '/' +
          dateObject.getFullYear()
        );
      case FLA_DATE_PICKER_FORMAT.YYYY_MM_DD:
        return (
          dateObject.getFullYear().toString() +
          '-' +
          (month < 10 ? '0' + month : month) +
          '-' +
          (date < 10 ? '0' + date : date)
        );
      default:
        return (
          dateObject.getFullYear().toString() +
          '-' +
          (month < 10 ? '0' + month : month) +
          '-' +
          (date < 10 ? '0' + date : date)
        );
    }*/
  }

  minutesChanged(event: any) {
    const minutesControl = this.formTimer.get('minutes');
    const hoursControl = this.formTimer.get('hours');
    minutesControl?.patchValue(this.validityValue(event.value));
    const hourValue = +hoursControl?.value;
    if (event.value == 60) {
      minutesControl?.patchValue(`00`);
      const newHour = this.validityValue(hourValue + 1);
      hoursControl?.setValue(newHour > 24 ? 24 : newHour);
    } else if (event.value == -1) {
      minutesControl?.patchValue(59);
      const newHour = this.validityValue(hourValue - 1);
      hoursControl?.setValue(newHour < 0 ? 0 : newHour);
    }
  }

  hoursChanged(event: any) {
    const hoursControl = this.formTimer.get('hours');
    hoursControl?.patchValue(this.validityValue(event.value));
    if (event.value == 24) {
      hoursControl?.patchValue(`00`);
    } else if (event.value == -1) {
      hoursControl?.patchValue(24);
    }
  }

  private validityValue(value: any) {
    if (value >= 0 && value <= 9) {
      return `0${value}`;
    }
    return value;
  }

  sendNewDate() {
    this.isClose = true;
    this._onClose$.next({
      closed: false,
      value: this._getDateStringFromTimestamp(this.state.selectedDay),
      minutes: this.formTimer.get('minutes')?.value,
      hours: this.formTimer.get('hours')?.value,
    });
    const date = new Date(this.state.selectedDay);
    date.setHours(
      +this.formTimer.get('hours')?.value,
      +this.formTimer.get('minutes')?.value,
      0,
      0
    );
    this.flaClick.emit({
      date,
    });
    window.removeEventListener('click', this.dispatchClickOutside);
  }
}
