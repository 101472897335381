import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'fla-card-info[title][link][body]',
  templateUrl: './card-info.component.html',
})
export class FlaCardInfoComponent {
  @ViewChild(TemplateRef) content!: TemplateRef<any>;
  @Input() title!: string;
  @Input() link!: string;
  @Input() body!: string;
  @Input('css') customClass: string = '';
  @Output() flaClick = new EventEmitter<any>();
}
