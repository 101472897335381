import { Injectable } from '@angular/core';
import { OrsGatewayServiceV2 } from '@shared/services/api/trademap/V2/ors-gateway.service';
import { TYPE_ORDE_SIDE_ENUM } from '@shared/enum/buyOrSell.enum';
import { ToastService } from '@services/toast.service';
import { IAccountSelect, ISearchStock } from 'src/app/core/interface';
import { TFlaToast } from '@shared/rocket-components/toast';
import { ConfigService } from '@core/config';

@Injectable()
export class StockTradeService {
  constructor(
    private _orsGatewayServiceV2: OrsGatewayServiceV2,
    private toastService: ToastService,
    private configService: ConfigService
  ) {}

  public onLoading = () => this._orsGatewayServiceV2.onLoading();

  public creatingAndSendingOrder(
    stock: ISearchStock,
    order: any,
    account: IAccountSelect,
    sideOrder: TYPE_ORDE_SIDE_ENUM,
    refComponent?: string
  ): void {
    if (!account.id_broker || account.id_broker == -99) {
      this.toastService.showToast('warning', 'Selecione uma corretora.');
      return;
    }
    this._orsGatewayServiceV2
      .sendNewOrderSingle(
        stock,
        order,
        account,
        sideOrder,
        false,
        false,
        false,
        refComponent
      )
      .subscribe();
  }

  public showToast(text: string, type: TFlaToast = 'warning'): void {
    this.toastService.showToast(type, text);
  }

  getConfigService() {
    return this.configService;
  }
}
