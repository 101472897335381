import { Injectable } from '@angular/core';
import { CustomPreferencesService } from '@services/api/nitro-ws/v1/custom-preferences.service';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { ToggleIndicatorService } from '@shared/components/stock-chart/service/toggle-indicator.service';
import {
  descendingSort,
  isNullOrUndefined,
} from 'src/app/utils/utils.functions';
import { BaseIndicator } from '../indicators.types';
import { OPTIONS_TYPE } from './settings-modal.enum';
import { SaveConfigurationOptions } from './settings-modal.interfaces';
import { Subject } from 'rxjs';

@Injectable()
export class SettingsModalService {
  public static _instance: SettingsModalService;
  private events$ = new Subject<{ ref: string; indicatorConfig: any }>();
  blockBackspace$ = new Subject<boolean>();
  constructor(
    private stockChartService: StockChartService,
    private customPreferencesService: CustomPreferencesService,
    private toggleIndicatorService: ToggleIndicatorService
  ) {
    if (SettingsModalService._instance == null) {
      SettingsModalService._instance = this;
    } else {
      return SettingsModalService._instance;
    }
  }

  onEvents = () => this.events$;

  updateTemporaryConfig(
    refComponent: string,
    indicator: BaseIndicator,
    value?: any,
    property?: string,
    fullConfig?: any
  ) {
    let previousConfig = '';
    let configIndex = -1;
    const key = `${refComponent}_${indicator.type}`;
    if (this.stockChartService.indicatorsTempConfig.has(key)) {
      configIndex = this.stockChartService.indicatorsTempConfig
        .get(key)!!
        .findIndex((config) => {
          return (
            config.indicator === `${indicator.type}_${indicator.lineNumber}`
          );
        });
      previousConfig =
        configIndex != -1
          ? this.stockChartService.indicatorsTempConfig.get(key)!![configIndex]
              .config
          : '';
    }
    let newConfig = {};
    if (property) {
      const type = typeof indicator[property as keyof typeof indicator];
      const newValue =
        type == 'number' ? (+value as unknown as typeof type) : value;
      newConfig = Object.assign(
        previousConfig == '' ? {} : JSON.parse(previousConfig),
        { [property]: newValue }
      );
    } else {
      let confis: any = {};
      if (previousConfig != '') {
        confis = JSON.parse(previousConfig);
      }
      if (fullConfig!!.propertyColor) {
        confis[fullConfig!!.propertyColor] = fullConfig!!.color;
      }
      if (fullConfig!!.showTypeLine && fullConfig!!.lineTypeId) {
        confis[`${fullConfig!!.property}LineType`] = fullConfig!!.lineTypeId;
      }
      if (fullConfig!!.propertyThickness) {
        confis[fullConfig!!.propertyThickness] = fullConfig!!.thickness;
      }
      if (fullConfig!!.propertyVisible) {
        confis[fullConfig!!.propertyVisible] = fullConfig!!.active;
      }
      newConfig = Object.assign(confis, fullConfig!!);
    }
    let anothersConfig = this.stockChartService.indicatorsTempConfig.get(key);
    if (!anothersConfig) {
      anothersConfig = [];
    }
    const indicatorConfig = {
      indicator: `${indicator.type}_${indicator.lineNumber}`,
      config: JSON.stringify(newConfig),
    };
    if (configIndex == -1) {
      anothersConfig.push(indicatorConfig);
    } else {
      anothersConfig.splice(configIndex, 1, indicatorConfig);
    }
    anothersConfig.sort((a, b) => descendingSort(a.indicator, b.indicator));
    let sessionConfigTemp: any = this.customPreferencesService.chartTempConfig;
    if (isNullOrUndefined(sessionConfigTemp) || sessionConfigTemp === '') {
      sessionConfigTemp = {};
    } else {
      sessionConfigTemp = JSON.parse(sessionConfigTemp);
    }
    sessionConfigTemp[key] = anothersConfig;
    this.customPreferencesService.chartTempConfig =
      JSON.stringify(sessionConfigTemp);
    this.stockChartService.indicatorsTempConfig.set(key, anothersConfig);
    this.events$.next({
      ref: refComponent,
      indicatorConfig: {
        indicator: indicatorConfig.indicator,
        config: JSON.parse(indicatorConfig.config),
      },
    });
  }

  saveIndicatorConfig(
    configurationOption: SaveConfigurationOptions,
    indicator: BaseIndicator,
    refComponent: string
  ) {
    const key = `${refComponent}_${indicator.type}`;
    const type = indicator.type;
    let configs: any = this.customPreferencesService.chartIndicatorsConfig;
    if (configs != '') {
      configs = JSON.parse(configs);
    }
    if (isNullOrUndefined(configs)) {
      configs = {};
    }
    if (configurationOption.cod === OPTIONS_TYPE.RESET) {
      delete configs[type];
      this.toggleIndicatorService.removeTempConfigIndicator(
        refComponent,
        indicator.type,
        indicator.lineNumber
      );
      indicator.resetConfiguration();
    }
    if (configurationOption.cod === OPTIONS_TYPE.SAVE) {
      const configsTemp = this.stockChartService.indicatorsTempConfig.get(key);
      if (configsTemp) {
        const configTemp = configsTemp.find(
          (c) => c.indicator === `${indicator.type}_${indicator.lineNumber}`
        );
        if (configTemp) {
          if (type in configs) {
            configs[type].config = JSON.stringify(
              Object.assign(
                JSON.parse(configs[type].config),
                JSON.parse(configTemp.config)
              )
            );
          } else {
            configs[type] = configTemp;
          }
        }
      }
    }
    this.customPreferencesService.chartIndicatorsConfig =
      JSON.stringify(configs);
    if (isNullOrUndefined(configs[type])) {
      this.stockChartService.indicatorsConfig.delete(type);
    } else {
      this.stockChartService.indicatorsConfig.set(type, configs[type]);
    }
  }
}
