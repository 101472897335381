import { Injectable } from '@angular/core';
import { FlaToastService } from '@shared/rocket-components/toast';
import { StockServiceRT } from '@shared/services/api/nitro-ws/v1/stock.service';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { OrdersService } from '@shared/services/orders.service';
import { OriginAnalysisOrderService } from '@shared/services/origin-analysis-order.service';
import { ITraderViewOrder } from '../interfaces/stock-table-views.interface';
import { IAccountSelect, ISearchStock } from '@core/interface';
import { TYPE_ORDE_ENUM } from '@shared/components/stock-trade/enum/stock-trade.enum';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';

@Injectable({
  providedIn: 'root',
})
export class StockTableOrderService extends ReadStreamBase {
  public account!: IAccountSelect;

  private _waitForChangeAndSendOrder = false;
  private _cdStockToSendOrder = '';
  private _orderParams!: ITraderViewOrder | null;

  constructor(
    private _originAnalysisOrderService: OriginAnalysisOrderService,
    private _ordersService: OrdersService,
    private _stockServiceRT: StockServiceRT,
    private _toast: FlaToastService,
    private _globalStockService: GlobalSelectedStockSubscription
  ) {
    super();
    this._globalStockService.onGlobalStockChange().then(async (data) => {
      this.readStream(data.stream, this.onGlobalStockChange);
    });
  }

  private onGlobalStockChange = (stock: ISearchStock) => {
    if (this._waitForChangeAndSendOrder) {
      if (stock.cd_stock === this._cdStockToSendOrder) {
        this._sendOrder(this._orderParams!, stock);
        this._resetValuesOnSendOrder();
        return;
      }
      this._getStockInfosBeforeSendOrder(
        this._orderParams!,
        this._cdStockToSendOrder
      );
      this._resetValuesOnSendOrder();
    }
  };

  public handleOrder(
    isPersonalList: boolean,
    link: boolean,
    isGlobalStock: boolean,
    params: ITraderViewOrder,
    stock: any
  ): void {
    if (isPersonalList) {
      this._sendOrder(params, stock);
      return;
    }

    if (isGlobalStock) {
      this._getGlobalStockAndSendOrder(params);
      return;
    }

    if (link) {
      this._orderParams = params;
      this._cdStockToSendOrder = stock.cd_stock;
      this._waitForChangeAndSendOrder = true;
      return;
    }

    this._getStockInfosBeforeSendOrder(params, stock.cd_stock);
  }

  private _getGlobalStockAndSendOrder(params: ITraderViewOrder): void {
    const stock = this._globalStockService.getGlobalStockSelected();
    this._sendOrder(params, stock);
  }

  private _getStockInfosBeforeSendOrder(
    params: ITraderViewOrder,
    cdStock: string
  ): void {
    this._stockServiceRT.searchStockByStock(cdStock).subscribe({
      next: (stock) => this._sendOrder(params, stock),
      error: () =>
        this._toast.show({
          isHtml: false,
          text: 'Não foi possível localizar o ativo selecionado para o envio da ordem.',
          type: 'error',
        }),
    });
  }

  private _sendOrder(params: ITraderViewOrder, stock: ISearchStock): void {
    if (!stock.id_exchange_segment)
      stock.id_exchange_segment = stock.id_exchange;
    this._originAnalysisOrderService.setOriginOrder(params.origin);
    this._ordersService.prepareOrder(
      stock,
      {
        qtd: params.qtty,
        price: params.price,
      },
      this.account,
      params.side,
      TYPE_ORDE_ENUM.MARKET
    );
  }

  private _resetValuesOnSendOrder(): void {
    this._waitForChangeAndSendOrder = false;
    this._orderParams = null;
    this._cdStockToSendOrder = '';
  }
}
