import { STOCK_CHART_ELEMENT_IDS } from '@shared/components/stock-chart/enum/stock-chart.enum';

const CHART_INTRO_STEPS = {
  INITIAL: (component: Element) => ({
    title: 'Conheça o Gráfico 🖖',
    intro:
      'Esse é um dos componentes mais importantes para estudo de mercado pela análise técnica. Pelo gráfico, você vê as oscilações de preço, realiza estudos e toma as melhores decisões para suas operações. <br /><br /> Use as setas do teclado ou clique no próximo para começar.',
    element: component,
  }),
  HEADER_PERIOD_AND_STYLE: (component: Element) => ({
    title: 'Tempo e estilo do gráfico',
    intro:
      'Aqui você consegue selecionar o intervalo de tempo que deseja visualizar no gráfico (minutos, hora, dia, semana ou mês). <br /><br /> Além disso, escolha qual estilo gostaria de fazer a sua análise (área, barras, candle ou linha)',
    element: component.querySelector(
      `#${STOCK_CHART_ELEMENT_IDS.HEADER_PERIOD_AND_STYLE}`
    ),
    position: 'bottom',
    preChange: function () {
      this.element = component.querySelector(
        `#${STOCK_CHART_ELEMENT_IDS.HEADER_PERIOD_AND_STYLE}`
      );
      this.position = 'bottom';
    },
  }),
  HEADER_INDICATORS_AND_DRAW: (component: Element) => ({
    title: 'Indicadores e barra de desenho',
    intro:
      'Adicione indicadores no gráfico, como médias móveis, tendências e osciladores. Cada um deles possuem explicações para seus funcionamentos e cálculos.<br /><br />Já na barra de desenhos, do lado esquerdo, você encontrará uma variedade de recursos para aprimorar sua análise. ',
    element: component.querySelector(
      `#${STOCK_CHART_ELEMENT_IDS.HEADER_INDICATORS_AND_DRAW}`
    ),
    position: 'bottom',
    preChange: function () {
      this.element = component.querySelector(
        `#${STOCK_CHART_ELEMENT_IDS.HEADER_INDICATORS_AND_DRAW}`
      );
      this.position = 'bottom';
    },
  }),
  HEADER_BUY_AT_MARKET: (component: Element) => ({
    title: 'Envio de ordens a mercado',
    intro:
      'Digite a quantidade do ativo desejado e clique nos botões <b>CM</b> (compra a mercado) ou <b>VM</b> (venda a mercado) para executar a operação.',
    element: component.querySelector(
      `#${STOCK_CHART_ELEMENT_IDS.HEADER_BUY_AT_MARKET}`
    ),
    position: 'bottom',
    preChange: function () {
      this.element = component.querySelector(
        `#${STOCK_CHART_ELEMENT_IDS.HEADER_BUY_AT_MARKET}`
      );
      this.position = 'bottom';
    },
  }),
  HEADER_X_RAY: (component: Element, forceOpenContextMenu: any) => ({
    title: 'Raio X',
    intro:
      'Clique aqui para ver detalhes da empresa como últimas divulgações, proventos, indicadores, pares e muito mais.',
    element: component.querySelector(
      `#${STOCK_CHART_ELEMENT_IDS.HEADER_X_RAY}`
    ),
    position: 'bottom',
    preChange: function () {
      forceOpenContextMenu();
      this.element = component.querySelector(
        `#${STOCK_CHART_ELEMENT_IDS.HEADER_X_RAY}`
      );
      this.position = 'bottom';
    },
  }),
  CONTEXT_NEGOTIATION_SHORTCUT: () => ({
    enum: 'CONTEXT_MENU',
    title: 'Atalhos de negociação',
    intro:
      'Ao clicar com o botão direito ou no + no canto direito da tela, você poderá enviar ordens de uma maneira prática e fácil no preço selecionado no gráfico. <br /><br /> Para escolher a quantidade, basta adicionar no menu superior do componente. <br /><br /> Outra maneira de negociar com facilidade pelo gráfico é apertando a tecla <b>SHIFT</b> para compra e <b>ALT</b> para venda.',
    element: document.querySelector(
      `#${STOCK_CHART_ELEMENT_IDS.CONTEXT_NEGOTIATION_SHORTCUT}`
    ),
    position: 'right',
    preChange: function () {
      this.element = document.querySelector(
        `#${STOCK_CHART_ELEMENT_IDS.CONTEXT_NEGOTIATION_SHORTCUT}`
      );
      this.position = 'right';
    },
  }),
  CONTEXT_ALERTS: () => ({
    enum: 'CONTEXT_MENU',
    title: 'Alertas de preço',
    intro:
      'Crie um alerta de preço clicando com o botão direito no gráfico ou no + ao lado direito da tela. Você será notificado quando o ativo atingir o preço desejado. <br/><br/> Para gerenciar os seus <b>alertas</b>, basta acessar a aba Alertas no componente <b>Ordens e Posição</b>.',
    element: document.querySelector(
      `#${STOCK_CHART_ELEMENT_IDS.CONTEXT_ALERTS}`
    ),
    position: 'bottom',
    preChange: function () {
      this.element = document.querySelector(
        `#${STOCK_CHART_ELEMENT_IDS.CONTEXT_ALERTS}`
      );
      this.position = 'bottom';
    },
  }),
  FOOTER_BOOK_AND_VOLUME_X_PRICE: (component: Element) => ({
    title: 'Book de ofertas e Volume x Preço',
    intro:
      'Ao adicionar o <b>Book</b> de ofertas no gráfico, você terá acesso ao volume de ordens de compra e venda em aberto para diferentes preços. <br /><br /> Já o <b>Volume x Preço</b> é um indicador técnico que mostra o volume de ações negociadas em um nível de preço específico. Faixas de preço com maior volume indicam maior interesse.',
    position: 'left',
    element: component.querySelector(
      `#${STOCK_CHART_ELEMENT_IDS.FOOTER_BOOK_AND_VOLUME_X_PRICE}`
    ),
  }),
  STOCK_TRADE_EVENTS_AND_VOLUME: (component: Element) => ({
    title: 'Boleta, eventos e gráfico de volume',
    intro:
      'Aqui, você consegue adicionar a <b>boleta rápida</b> para envio de ordens. Já adicionando os <b>eventos</b>, você verá na parte inferir do gráfico as datas de pagamento de proventos, divulgação de fato relevante e outros. É possível também ativar ou desativar a visualização do <b>volume de negociações</b>, que aparece na parte inferior, como um gráfico de barras.',
    element: component.querySelector(
      `#${STOCK_CHART_ELEMENT_IDS.STOCK_TRADE_EVENTS_AND_VOLUME}`
    ),
    position: 'left',
  }),
  TOUR_END: {
    title: 'Agora é com você! 🖖',
    intro:
      'Aproveite a funcionalidade do Gráfico! Se quiser rever esse tour clique em <b>Ajuda</b> no botão direito ou acesse nossa FAQ clicando no menu <b>Ajuda</b>.',
  },
};

export const CHART_STEPS = (
  chartElement: Element,
  forceOpenContextMenu: any
) => [
  CHART_INTRO_STEPS.INITIAL(chartElement),
  CHART_INTRO_STEPS.HEADER_PERIOD_AND_STYLE(chartElement),
  CHART_INTRO_STEPS.HEADER_INDICATORS_AND_DRAW(chartElement),
  CHART_INTRO_STEPS.HEADER_BUY_AT_MARKET(chartElement),
  CHART_INTRO_STEPS.HEADER_X_RAY(chartElement, forceOpenContextMenu),
  CHART_INTRO_STEPS.CONTEXT_NEGOTIATION_SHORTCUT(),
  CHART_INTRO_STEPS.CONTEXT_ALERTS(),
  CHART_INTRO_STEPS.FOOTER_BOOK_AND_VOLUME_X_PRICE(chartElement),
  CHART_INTRO_STEPS.STOCK_TRADE_EVENTS_AND_VOLUME(chartElement),
  CHART_INTRO_STEPS.TOUR_END,
];
