<div
  [id]="'chart-evolution-tooltip-div-id_' + refChart"
  class="position-absolute scichart-tooltip-div-cl"
  [ngClass]="{ 'd-none': !displayTooltip }"
  [style.left.px]="tooltipX"
  [style.top.px]="tooltipY"
></div>

<div class="d-flex justify-content-end align-items-center mt-1">
  <div class="dropdown dropdown-position">
    <a
      class="dropdown-custom-size border btn btn-primary dropdown-toggle fw-bold p-1 round-lg text-white bg-transparent fs-6"
      role="button"
      id="dropdownMenuLink"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ periodSelectedLabel }}
    </a>

    <ul class="dropdown-menu cursor-pointer" aria-labelledby="dropdownMenuLink">
      <li *ngFor="let visualization of periodsEvolution">
        <a
          class="dropdown-item"
          type="button"
          (click)="changePeriod(visualization)"
        >
          {{ visualization.label }}
        </a>
      </li>
    </ul>
  </div>
</div>
<div class="chart-parent">
  <div
    (mousemove)="onMouseMove($event)"
    [id]="refChart"
    #chartContainer
    class="chart-container"
  ></div>
</div>
