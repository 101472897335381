import { Injectable } from '@angular/core';
import { RxEvent } from './rx-event';
import {
  CHEETAH_CHANNEL,
  CUSTODY_PERFORMANCE_FIELDS,
} from '@shared/cheetah/cheetah.const';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { MarketDispatcher } from '@shared/cheetah/dispatcher/market.dispatcher';

@Injectable({
  providedIn: 'root',
})
export class CustodyPerformanceChannel {
  static instance: CustodyPerformanceChannel;

  constructor(
    private _rxEvent: RxEvent,
    private cheetahService: MarketDispatcher
  ) {
    return (CustodyPerformanceChannel.instance =
      CustodyPerformanceChannel.instance || this);
  }

  subscribe(params: SubscribeParam): void {
    params.channel_cheetah = CHEETAH_CHANNEL.CUSTODY_PERFORMANCE;
    params.fields = CUSTODY_PERFORMANCE_FIELDS;
    this.cheetahService.subscribe(params);
  }

  async onEvents() {
    const data = await this._rxEvent.read(CHEETAH_CHANNEL.CUSTODY_PERFORMANCE);
    return data;
  }

  unsubscribe(params: SubscribeParam): void {
    params.channel_cheetah = CHEETAH_CHANNEL.CUSTODY_PERFORMANCE;
    this.cheetahService.unsubscribe(params);
  }
}
