import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoversDirective } from './directive/popovers.directive';

@NgModule({
  declarations: [PopoversDirective],
  imports: [CommonModule],
  exports: [PopoversDirective],
})
export class PopoversModule {}
