import { StockListService } from '@shared/services/api/trademap/V10/stock-list.service';
import {
  IAllStockListSimple,
  IListStockDB,
  ISearchStock,
} from 'src/app/core/interface';
import { StockListRowModel } from '../models/stock-table-row.model';
import { GridISelectRow } from '@shared/rocket-grid';
import { ToastService } from '@shared/services';
import { ListStocksService } from '@shared/services/core/list-stocks/list-stocks.service';

export class UpdatesList {
  constructor(
    private listStocksService: ListStocksService,
    private stockListService: StockListService,
    private toastService: ToastService
  ) {}

  public updateListStocks(listSelected: IListStockDB): void {
    const stocks = listSelected.stocks.map(
      (item: StockListRowModel) => item.idUpdate
    );
    this.updateListStocksService(listSelected.list, stocks);
    this.updateListStocksIndexedDB(listSelected);
  }

  public updateListsStocks(lists: IListStockDB[]): void {
    lists.forEach((list) => {
      const stocks = list.stocks.map(
        (item: StockListRowModel) => item.idUpdate
      );
      this.updateListStocksService(list.list, stocks);
      this.updateListStocksIndexedDB(list);
    });
  }

  public updateListStocksService(
    list: IAllStockListSimple,
    stocks: any[],
    callback?: (list: IAllStockListSimple, isError: boolean) => void
  ): void {
    try {
      this.stockListService.updateStockList(list, stocks).subscribe((data) => {
        if (!data || !data.data.is_success) {
          const msg = data.error
            ? data.error
            : 'Erro ao atualizar lista de ativos, tente novamente.';
          this.toastService.showToast('error', msg);
          if (callback) callback(list, true);
          return;
        }
        if (callback) callback(list, false);
      });
    } catch (e: any) {
      console.log(e);
    }
  }

  public updateListStocksIndexedDB(listSelected: IListStockDB): void {
    this.listStocksService.updateStockList({
      idStockList: listSelected.idStockList.toString(),
      value: listSelected,
    });
  }

  public rowMoved(
    listSelected: IListStockDB,
    event: { id: string; rowIndex: number }
  ): IListStockDB {
    const index = listSelected.stocks.findIndex(
      (item: StockListRowModel) => item.idRow == event.id
    );
    const rowTemp = listSelected.stocks.splice(index, 1);
    listSelected.stocks.splice(event.rowIndex, 0, rowTemp[0]);
    this.updateListStocks(listSelected);
    return listSelected;
  }

  public removeRow(listSelected: IListStockDB, remove: GridISelectRow) {
    const index = listSelected.stocks.findIndex(
      (item: ISearchStock) => item.cd_stock === remove.rowData.cd_stock
    );
    if (index < 0) return;
    listSelected.stocks.splice(index, 1);
    this.updateListStocks(listSelected);
  }
}
