import { Injectable } from '@angular/core';
import { AbstractCacheService } from './AbstractCacheService';
import { ILaminaSectionStatistics } from '@shared/components/business-profile/business-profile.interface';

@Injectable({
  providedIn: 'root',
})
export class StatisticsCacheService extends AbstractCacheService<
  ILaminaSectionStatistics[]
> {}
