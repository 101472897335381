import { Injectable } from '@angular/core';
import { AbstractCacheService } from './AbstractCacheService';
import { IIndicatorsLayoutOffShore } from '@shared/components/business-profile/business-profile.interface';

@Injectable({
  providedIn: 'root',
})
export class IndicatorsLayoutCacheService extends AbstractCacheService<
  IIndicatorsLayoutOffShore[]
> {}
