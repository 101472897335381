<div
  class="d-flex border round p-2 card-broker bg-neutral-strong"
  [ngClass]="{ 'cursor-pointer': !broker.hasAccount }"
  (click)="selectBroker()"
>
  <div class="d-flex align-items-center">
    <app-broker-logo [logo]="svgDataUrl" [imageSize]="32"></app-broker-logo>
  </div>

  <div class="d-flex flex-column justify-content-center pl-3 pr-3 col-13">
    <h4 class="fw-bold">
      {{ broker.nmBrokerNickname }}
    </h4>
    <h5>
      {{ broker.hasAccount ? 'Conectado' : 'Conecte sua conta' }}
    </h5>
  </div>

  <button
    type="button"
    class="btn btn-icon bg-transparent btn btn-icon m-0 p-0 d-flex align-items-center"
    (click)="disconnectBroker()"
  >
    <i class="icons arrow text-brand-primary cursor-pointer icon-size-sm">
      {{ broker.hasAccount ? 'logout' : 'keyboard_arrow_right' }}
    </i>
  </button>
</div>
