<div class="position-relative w-100">
  <div [id]="id" [style.width.%]="100" [style.height.px]="height"></div>
  <app-tiger-chart-tooltip
    #TigerChartTooltip
    class="tooltip-element"
    [theme]="theme"
    [id]="id + '_indicators'"
    [graphContainer]="graphContainer"
    [tickSize]="configs.yAxis.labelPrecision || 0"
    [isSubChart]="true"
    [getIndicators$]="getIndicators$"
    (changeIndicatorSettings)="openIndicatorSettings($event)"
    (changeIndicatorDelete)="deleteIndicator($event)"
    (indicatorVisibility)="indicatorVisibility.emit($event)"
  ></app-tiger-chart-tooltip>
</div>
