<rocket-modal>
  <rocket-modal-header [hideCloseButton]="true">
    <div class="w-100 d-flex justify-content-center">
      <h4 class="modal-title d-flex justify-content-center">Terminal</h4>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <ng-container *ngIf="showHelp">
      <div class="fs-6 mb-4 d-flex justify-content-center">
        Dica: para abrir novamente o terminal basta começar a digitar
      </div>
    </ng-container>
    <div class="w-100 mb-3 position-relative">
      <fla-input
        #inputStock
        cssInput="py-2"
        css="margin-bottom"
        placeholder="Buscar"
        decidePosition="prefix"
        type="text"
        [focusOnInit]="true"
        [formControl]="stockControl"
        [isSmall]="true"
      >
        <div class="prefix">
          <fla-icon name="search" size="icon-size-nano"></fla-icon>
        </div>
      </fla-input>
      <div class="position-absolute pr-3 right-0 custom-suffix">
        <button
          type="button"
          class="btn btn-icon"
          [ngClass]="{ 'text-brand-primary': hasSomeStockFilter }"
          (click)="openConfig()"
        >
          <span
            class="material-icons-outlined icon-size-sm"
            title="Configurações de listagem"
          >
            filter_list
          </span>
        </button>
      </div>
    </div>
    <section [ngClass]="{ 'd-none': loading || modalConfigOpened }">
      <app-most-search
        #mostSearch
        [isSearching]="isSearching"
        [selectToStockList]="false"
        height="90%"
        [noData]="noData"
        [loading]="modalConfigOpened"
        (stockClicked)="onSave($event)"
        (scrollEnd)="scrollEnd()"
      ></app-most-search>
    </section>
    <div *ngIf="loading || modalConfigOpened" class="pt-5">
      <app-loading></app-loading>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
