import { Point } from '@angular/cdk/drag-drop';
import { DrawToolsService } from '@shared/components/stock-chart/service/draw-tools.service';
import { NumberRange } from 'scichart/Core/NumberRange';
import { CoordinateCalculatorBase } from 'scichart/Charting/Numerics/CoordinateCalculators/CoordinateCalculatorBase';
import {
  CustomAnnotation,
  ICustomAnnotationOptions,
} from 'scichart/Charting/Visuals/Annotations/CustomAnnotation';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import {
  DrawToolConfiguration,
  TIGER_CHART_TOOL,
  TIGER_CHART_TOOL_CHILD,
} from '../tiger-chart-tools/tiger-chart-tools.interface';
import { TMouseEventData } from '../types/tiger-chart.types';
import {
  Axis,
  DrawTools,
  DrawToolsReposition,
  EventSubject,
  LineToolsOptions,
  StyleTool,
} from './draw-tools.interface';
import { EDraggingGripPoint } from 'scichart/Charting/Visuals/Annotations/AnnotationBase';
import { TOOLS_METHOD } from '../tiger-chart-tools/tiger-chart-tools.enum';
import { Subject, Subscription, debounceTime } from 'rxjs';

export class FlagTools extends CustomAnnotation implements DrawTools {
  tool!: TIGER_CHART_TOOL;
  children = [];
  isDraw = true;
  startEdit = false;
  baseChart!: TWebAssemblyChart;
  isChildren = false;
  lastPointValues!: Point;
  lastMouseValues!: Point;
  lastXDiff: number = 0;
  isDrawing!: boolean;
  style: StyleTool = {};
  dictIdChildren!: string;
  configuration!: DrawToolConfiguration | undefined;
  hasElements: boolean = false;
  updateChart: boolean = false;
  width: number = 0;
  height: number = 0;
  previousVisibleRange!: NumberRange;
  isAux: boolean = false;
  endDraw: boolean = false;
  lastEventData!: TMouseEventData;
  axisSub!: Subscription;
  isWithinDataBounds: boolean | undefined = true;
  private updateAux$!: Subscription;
  private _updateAux$ = new Subject<EventSubject>();

  constructor(
    options: ICustomAnnotationOptions,
    private drawToolsService: DrawToolsService,
    tool: LineToolsOptions
  ) {
    super(options);
    this.initSubscriptions();
    this.isChildren = tool.isChildren;
    this.baseChart = tool.baseChart;
    this.tool = tool.tool;
    this.dictIdChildren = tool.dictIdChildren;
    this.configuration = tool.configuration;
    this.isAux = tool.isAux;
    this.lastEventData = tool.eventData;
    this.isWithinDataBounds = tool.eventData.isWithinDataBounds;
  }

  initSubscriptions() {
    this.updateAux$ = this._updateAux$
      .pipe(debounceTime(200))
      .subscribe((data) => {
        data.function();
      });
    this.axisSub = this.drawToolsService.axisChanged$.subscribe((data) => {
      if (data.type === 'YAXIS') {
        this.updateAux();
      }
    });
  }

  private destroySubscriptions() {
    this.updateAux$ && this.updateAux$.unsubscribe();
    this.axisSub && this.axisSub.unsubscribe();
  }

  override onDetach(): void {
    super.onDetach();
    this.destroySubscriptions();
  }

  override delete(): void {
    super.delete();
    this.destroySubscriptions();
  }

  override getAnnotationGripSvg(x: number, y: number) {
    const size = this.annotationsGripsRadius;
    return `<rect x="${x - size / 2}" y="${
      y - size / 2
    }" rx="6" width="12" height="12" fill="${
      this.annotationsGripsFill
    }" stroke="${this.annotationsGripsStroke}"/>`;
  }

  override svgStringAdornerTemplate(
    x1: number,
    y1: number,
    x2: number,
    y2: number
  ): string {
    const width = x2 - x1;
    const height = y2 - y1;
    let svg = `<svg xmlns="http://www.w3.org/2000/svg">
    <defs>
        <pattern id="grid1" patternUnits="userSpaceOnUse" width="10" height="10">
            <line x1="0" y1="0" x2="10" y2="10" />
        </pattern>
    </defs>
    <rect x="${x1}" y="${y1}" width="${width}" height="${height}" fill="url(#grid1)"/>
    `;
    const grips = this.getAdornerAnnotationBorders(false, true);
    if (this.canDragPoint(EDraggingGripPoint.x1y1)) {
      svg += this.getAnnotationGripSvg(grips.x1, grips.y1 - 3);
    }
    if (this.canDragPoint(EDraggingGripPoint.x2y2)) {
      svg += this.getAnnotationGripSvg(grips.x2, grips.y2 - 2.5);
    }
    svg += '</svg>';
    return svg;
  }

  unsubscriber(): void {
    this.axisSub.unsubscribe();
  }

  override update(
    xCalc: CoordinateCalculatorBase,
    yCalc: CoordinateCalculatorBase,
    xCoordSvgTrans: number,
    yCoordSvgTrans: number
  ): void {
    super.update(xCalc, yCalc, xCoordSvgTrans, yCoordSvgTrans);
    if (this.style) {
      this.updateStyleTool(this.style);
    }
  }

  protected override setAnnotationBorders(
    x1: number,
    x2: number,
    y1: number,
    y2: number
  ): void {
    super.setAnnotationBorders(x1, x2, y1, y2);
    this.setStyleConfiguration();
  }

  getName() {
    return this.constructor.name;
  }

  updateDrawTool(
    eventData: TMouseEventData,
    // eslint-disable-next-line
    countStepClickTool: number,
    // eslint-disable-next-line
    reposition?: DrawToolsReposition
  ) {
    this.lastEventData = eventData;
    this._updateAux$.next({
      function: () => {
        this.updateAux();
      },
    });
  }

  private updateAux() {
    if (this.tool.hasBoxAux && this.tool.children) {
      const { yAxis, xAxis } = this.getAxis();
      if (!yAxis || !xAxis) {
        return;
      }
      const borders = this.getAnnotationBorders();
      const newY1 = yAxis
          .getCurrentCoordinateCalculator()
          .getDataValue(borders.y1),
        newY2 = yAxis.getCurrentCoordinateCalculator().getDataValue(borders.y2);
      const newX1 = xAxis
          .getCurrentCoordinateCalculator()
          .getDataValue(borders.x1),
        newX2 = xAxis.getCurrentCoordinateCalculator().getDataValue(borders.x2);
      const diffY = newY1 - newY2;
      const diffX = newX1 - newX2;
      const children = this.tool.children.filter((c) => c.isAux)!!;
      children.forEach((child) => {
        const aux = this.baseChart.sciChartSurface.annotations.getById(
          child.dictIdChildren!!
        ) as DrawTools;
        aux.isDrawing = true;
        if (child.method === TOOLS_METHOD.BOX_ANNOTATION) {
          aux.x1 = this.x1;
          aux.y1 = this.y1;
          aux.x2 = this.x1 - diffX;
          aux.y2 = this.y1 - diffY;
        }
        if (child.method === TOOLS_METHOD.CUSTOM_BOX_ANNOTATION) {
          aux.y1 = this.y1 - diffY;
          aux.y2 = this.y1;
          aux.x2 = this.x1 - diffX;
          aux.x1 = this.x1;
        }
        aux.isDrawing = false;
      });
    }
  }

  customClick = () => {
    //do nothing.
  };

  repositionZoomDraw(): void {
    //do nothing.
  }

  customHover = () => {
    //do nothing.
  };

  updateStyleTool(style: StyleTool) {
    const flagElement = document.getElementById(`FLAG_${this.tool.dictId!!}`);
    let childrenDraw!: TIGER_CHART_TOOL_CHILD;
    if (this.isChildren && this.tool.children) {
      childrenDraw = this.tool.children.find(
        (child) => child.dictIdChildren === this.dictIdChildren
      )!!;
    }
    if (flagElement) {
      if (style.backgroundColor) {
        flagElement.setAttribute('fill', style.backgroundColor.rgbaHexColor);
        this.style.backgroundColor = style.backgroundColor;
        if (childrenDraw) {
          childrenDraw.backgroundColor = style.backgroundColor.rgbaHexColor;
          return;
        }
        this.tool.backgroundColor = style.backgroundColor.rgbaHexColor;
      }
    }
  }

  updateTool(tool: TIGER_CHART_TOOL) {
    this.tool = tool;
  }

  private setStyleConfiguration() {
    if (!this.configuration || this.hasElements) {
      return;
    }
    const flagElement = document.getElementById(`FLAG_${this.tool.dictId!!}`);
    if (!flagElement) {
      return;
    } else {
      this.hasElements = true;
    }

    if (flagElement) {
      if (this.configuration.backgroundColor) {
        flagElement.setAttribute('fill', this.configuration.backgroundColor);
      }
    }
  }

  resizeSVG(
    typeResize: string,
    childId: string,
    svgPosition?: { x1: number; x2: number; y1: number; y2: number },
    position?: { x1: number; x2: number; y1: number; y2: number }
  ) {
    if (position) {
      this.x1 = position.x1;
      this.x2 = position.x2;
      this.y1 = position.y1;
      this.y2 = position.y2;
      this.updateAux();
    }
    /*const svgElement = document.getElementById(`SVG_${this.tool.dictId!!}`);
    const element = document.getElementById(`${this.tool.dictId!!}`);
    if (svgElement) {
      if (element) {
        if (svgPosition) {
          element.setAttribute('x2', `${svgPosition.x2}`);
          element.setAttribute('y2', `${svgPosition.y2}`);
        }
        if (position) {
          this.x1 = position.x1;
          this.x2 = position.x2;
          this.y1 = position.y1;
          this.y2 = position.y2;
        }
      }
      this.svgString = svgElement.outerHTML!!;
      this.notifyPropertyChanged('svgString');
    }*/
  }

  private getAxis(): Axis {
    const yAxis = this.baseChart.sciChartSurface.yAxes.getById(this.yAxisId);
    const xAxis = this.baseChart.sciChartSurface.xAxes.getById(this.xAxisId);
    if (!yAxis || !xAxis) {
      return { xAxis: undefined, yAxis: undefined };
    }
    try {
      yAxis.getCurrentCoordinateCalculator();
      xAxis.getCurrentCoordinateCalculator();
    } catch (e: any) {
      return { xAxis: undefined, yAxis: undefined };
    }
    return { yAxis, xAxis };
  }
}
