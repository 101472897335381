import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RentComponent } from './rent.component';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { RocketGridModule } from '@shared/rocket-grid';
import { LoadingModule } from '../loading/loading.module';
import { RentHeaderComponent } from './components/header/rent-header.component';
import { RentDetailsComponent } from './components/rent-details/rent-details.component';
import { RentService } from './service/rent.service';
import {
  FlaIconModule,
  FlaTabModule,
  RocketButtonModule,
} from '@shared/rocket-components/components';
import { RentGridComponent } from './components/rent-grid/rent-grid.component';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { FundsTableModule } from './components/funds-table/funds-table.module';
import { RentChartModule } from './components/rent-chart/rent-chart.module';

@NgModule({
  imports: [
    CommonModule,
    FlaWorkSpaceModule,
    RocketGridModule,
    LoadingModule,
    FlaIconModule,
    RocketButtonModule,
    FlaTabModule,
    TooltipsModule,
    FundsTableModule,
    RentChartModule,
  ],
  declarations: [
    RentComponent,
    RentHeaderComponent,
    RentDetailsComponent,
    RentGridComponent,
  ],
  exports: [RentComponent],
  providers: [RentService],
})
export class RentModule {}
