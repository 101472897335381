import { FormGroup } from '@angular/forms';
import { DATA_POINT } from '@shared/components/screening/stock-screening/constants/constants';
import { DynamicControlService } from '@shared/rocket-components/dynamic-form/dynamic-control.service';
import { FieldsBase } from '@shared/rocket-components/dynamic-form/fields-base';

export class FieldsForm {
  constructor(
    private dynamicControlService: DynamicControlService,
    private typeField: string
  ) {}

  public get existFields() {
    return !!this.fields[this.typeField];
  }

  public createFields(): any[] {
    return this.fields[this.typeField]();
  }

  public createForm(): FormGroup {
    return this.dynamicControlService.toFormGroup(
      this.fields[this.typeField]() as FieldsBase<string>[]
    );
  }

  private fields: any = {
    ma: () => [
      {
        controlType: 'freeContent',
        key: 'time_period',
        label: 'Período',
        initialValue: 9,
        isSmall: true,
        selectOnFocus: true,
        allowNegativeValue: false,
        incrementValue: 1,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
        isModal: true,
      },
      {
        controlType: 'freeContent',
        key: 'data_point',
        label: 'Fonte',
        value: `open`,
        isSmall: true,
        bindLabel: 'name',
        bindValue: 'id',
        options: DATA_POINT,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
      },
      {
        controlType: '',
        key: 'ma_type',
        value: `sma`,
      },
    ],
    ema: () => [
      {
        controlType: 'freeContent',
        key: 'time_period',
        label: 'Período',
        initialValue: 9,
        isSmall: true,
        selectOnFocus: true,
        allowNegativeValue: false,
        incrementValue: 1,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
        isModal: true,
      },
      {
        controlType: 'freeContent',
        key: 'data_point',
        label: 'Fonte',
        value: `close`,
        isSmall: true,
        bindLabel: 'name',
        bindValue: 'id',
        options: DATA_POINT,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
      },
      {
        controlType: '',
        key: 'ma_type',
        value: `ema`,
      },
    ],
    wma: () => [
      {
        controlType: 'freeContent',
        key: 'time_period',
        label: 'Período',
        initialValue: 9,
        isSmall: true,
        selectOnFocus: true,
        allowNegativeValue: false,
        incrementValue: 1,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
        isModal: true,
      },
      {
        controlType: 'freeContent',
        key: 'data_point',
        label: 'Fonte',
        value: `close`,
        isSmall: true,
        bindLabel: 'name',
        bindValue: 'id',
        options: DATA_POINT,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
      },
      {
        controlType: '',
        key: 'ma_type',
        value: `wma`,
      },
    ],
    dema: () => [
      {
        controlType: 'freeContent',
        key: 'time_period',
        label: 'Período',
        initialValue: 9,
        isSmall: true,
        selectOnFocus: true,
        allowNegativeValue: false,
        incrementValue: 1,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
        isModal: true,
      },
      {
        controlType: 'freeContent',
        key: 'data_point',
        label: 'Fonte',
        value: `close`,
        isSmall: true,
        bindLabel: 'name',
        bindValue: 'id',
        options: DATA_POINT,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
      },
      {
        controlType: '',
        key: 'ma_type',
        value: `dema`,
      },
    ],
    tema: () => [
      {
        controlType: 'freeContent',
        key: 'time_period',
        label: 'Período',
        initialValue: 9,
        isSmall: true,
        selectOnFocus: true,
        allowNegativeValue: false,
        incrementValue: 1,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
        isModal: true,
      },
      {
        controlType: 'freeContent',
        key: 'data_point',
        label: 'Fonte',
        value: `open`,
        isSmall: true,
        bindLabel: 'name',
        bindValue: 'id',
        options: DATA_POINT,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
      },
      {
        controlType: '',
        key: 'ma_type',
        value: `tema`,
      },
    ],
    kama: () => [
      {
        controlType: 'freeContent',
        key: 'time_period',
        label: 'Período',
        initialValue: 21,
        isSmall: true,
        selectOnFocus: true,
        allowNegativeValue: false,
        incrementValue: 1,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
        isModal: true,
      },
      {
        controlType: 'freeContent',
        key: 'data_point',
        label: 'Fonte',
        value: `open`,
        isSmall: true,
        bindLabel: 'name',
        bindValue: 'id',
        options: DATA_POINT,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
      },
      {
        controlType: '',
        key: 'ma_type',
        value: `kama`,
      },
    ],
    trima: () => [
      {
        controlType: 'freeContent',
        key: 'time_period',
        label: 'Período',
        initialValue: 10,
        isSmall: true,
        selectOnFocus: true,
        allowNegativeValue: false,
        incrementValue: 1,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
        isModal: true,
      },
      {
        controlType: 'freeContent',
        key: 'data_point',
        label: 'Fonte',
        value: `close`,
        isSmall: true,
        bindLabel: 'name',
        bindValue: 'id',
        options: DATA_POINT,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
      },
      {
        controlType: '',
        key: 'ma_type',
        value: `trima`,
      },
    ],
    // bb: () => [
    //   {
    //     controlType: 'inputCount',
    //     key: 'time_period',
    //     label: 'Período',
    //     value: `20`,
    //     isSmall: true,
    //     selectOnFocus: true,
    //     allowNegativeValue: false,
    //     incrementValue: 1,
    //     classLine: 'col-16',
    //     classComponent: 'margin-bottom',
    //     listErrors: [''],
    //     typeShowError: 'icon',
    //     tooltip: ``,
    //     tooltipDelay: 0,
    //     labelPlacement: 'horizontal',
    //   },
    //   {
    //     controlType: 'inputCount',
    //     key: 'multiplier',
    //     label: 'Mult',
    //     value: `10`,
    //     isSmall: true,
    //     selectOnFocus: true,
    //     allowNegativeValue: false,
    //     incrementValue: 1,
    //     classLine: 'col-16',
    //     classComponent: 'margin-bottom',
    //     listErrors: [''],
    //     typeShowError: 'icon',
    //     tooltip: ``,
    //     tooltipDelay: 0,
    //     labelPlacement: 'horizontal',
    //   },
    // ],
    sar: () => [
      {
        controlType: 'freeContent',
        key: 'acceleration',
        label: 'Incremento',
        initialValue: 2,
        isSmall: true,
        selectOnFocus: true,
        allowNegativeValue: false,
        incrementValue: 1,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
        isModal: true,
      },
      {
        controlType: 'freeContent',
        key: 'maximum',
        label: 'Máx',
        initialValue: 0,
        isSmall: true,
        selectOnFocus: true,
        allowNegativeValue: false,
        incrementValue: 1,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
        isModal: true,
      },
      {
        controlType: 'freeContent',
        key: 'data_point',
        label: 'Fonte',
        value: `open`,
        isSmall: true,
        bindLabel: 'name',
        bindValue: 'id',
        options: DATA_POINT,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
      },
    ],
    rsi: () => [
      {
        controlType: 'freeContent',
        key: 'time_period',
        label: 'Período',
        initialValue: 14,
        isSmall: true,
        selectOnFocus: true,
        allowNegativeValue: false,
        incrementValue: 1,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
        isModal: true,
      },
    ],
    atr: () => [
      {
        controlType: 'freeContent',
        key: 'time_period',
        label: 'Período',
        initialValue: 14,
        isSmall: true,
        selectOnFocus: true,
        allowNegativeValue: false,
        incrementValue: 1,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
        isModal: true,
      },
    ],
    ppo: () => [
      {
        controlType: 'freeContent',
        key: 'slow_period',
        label: 'Período Curto',
        initialValue: 10,
        isSmall: true,
        selectOnFocus: true,
        allowNegativeValue: false,
        incrementValue: 1,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
        isModal: true,
      },
      {
        controlType: 'freeContent',
        key: 'fast_period',
        label: 'Período Longo',
        initialValue: 21,
        isSmall: true,
        selectOnFocus: true,
        allowNegativeValue: false,
        incrementValue: 1,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
        isModal: true,
      },
    ],
    mom: () => [
      {
        controlType: 'freeContent',
        key: 'time_period',
        label: 'Período',
        initialValue: 10,
        isSmall: true,
        selectOnFocus: true,
        allowNegativeValue: false,
        incrementValue: 1,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
        isModal: true,
      },
      {
        controlType: 'freeContent',
        key: 'data_point',
        label: 'Fonte',
        value: `close`,
        isSmall: true,
        bindLabel: 'name',
        bindValue: 'id',
        options: DATA_POINT,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
      },
    ],
    // adx: () => [
    //   {
    //     controlType: 'inputCount',
    //     key: 'softened',
    //     label: 'ADX Suavizado',
    //     value: `14`,
    //     isSmall: true,
    //     selectOnFocus: true,
    //     allowNegativeValue: false,
    //     incrementValue: 1,
    //     classLine: 'col-16',
    //     classComponent: 'margin-bottom',
    //     listErrors: [''],
    //     typeShowError: 'icon',
    //     tooltip: ``,
    //     tooltipDelay: 0,
    //     labelPlacement: 'horizontal',
    //   },
    //   {
    //     controlType: 'inputCount',
    //     key: 'length',
    //     label: 'Comprimento DI',
    //     value: `14`,
    //     isSmall: true,
    //     selectOnFocus: true,
    //     allowNegativeValue: false,
    //     incrementValue: 1,
    //     classLine: 'col-16',
    //     classComponent: 'margin-bottom',
    //     listErrors: [''],
    //     typeShowError: 'icon',
    //     tooltip: ``,
    //     tooltipDelay: 0,
    //     labelPlacement: 'horizontal',
    //   },
    // ],
    hammer: () => [
      {
        controlType: 'freeContent',
        key: 'data_point',
        label: 'Fonte',
        value: `open`,
        isSmall: true,
        bindLabel: 'name',
        bindValue: 'id',
        options: DATA_POINT,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
      }
    ],
    inv_hammer: () => [
      {
        controlType: 'freeContent',
        key: 'data_point',
        label: 'Fonte',
        value: `open`,
        isSmall: true,
        bindLabel: 'name',
        bindValue: 'id',
        options: DATA_POINT,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
      }
    ],
    doji: () => [
      {
        controlType: 'freeContent',
        key: 'data_point',
        label: 'Fonte',
        value: `open`,
        isSmall: true,
        bindLabel: 'name',
        bindValue: 'id',
        options: DATA_POINT,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
      }
    ],
    avg_price: () => [
      {
        controlType: 'freeContent',
        key: 'data_point',
        label: 'Fonte',
        value: `open`,
        isSmall: true,
        bindLabel: 'name',
        bindValue: 'id',
        options: DATA_POINT,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
      }
    ],
    median_price: () => [
      {
        controlType: 'freeContent',
        key: 'data_point',
        label: 'Fonte',
        value: `open`,
        isSmall: true,
        bindLabel: 'name',
        bindValue: 'id',
        options: DATA_POINT,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
      }
    ],
    custom: () => [
      {
        controlType: 'freeContent',
        key: 'custom',
        label: 'Valor',
        initialValue: 1,
        isSmall: true,
        selectOnFocus: true,
        classLine: 'col-14',
        classComponent: 'margin-bottom',
        allowNegativeValue: false,
        incrementValue: 1,
        isModal: true,
      },
    ],
  };
}
