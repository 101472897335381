import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ISearchStock, IWorkSpaceComponet } from '@core/interface';
import { SearchStockComponent } from '@shared/components/search-stock/search-stock.component';
import { ComponentsService } from '@shared/services/api/nitro-ws/v1/components.service';
import {
  RANKING_BROKER_PERIODS,
  RANKING_TABS,
} from '../../enum/ranking-broker.enum';
import { WorkspaceHeader } from '@core/workspace/parts/header/workspace-header.service';
import { ConfigService } from '@core/config';

@Component({
  selector: 'app-header-ranking-broker',
  templateUrl: './header-ranking-broker.component.html',
  styleUrls: ['./header-ranking-broker.component.scss'],
})
export class HeaderRankingBrokerComponent implements AfterViewInit {
  @Input() refComponent!: string;
  @Input() public component!: any;
  @Input() public isExplode: boolean = false;
  @Input() public enableChangePeriod: boolean = true;

  @Output() headerHandler = new EventEmitter();
  @Output() changeView = new EventEmitter<RANKING_TABS>();
  @Output() changePeriod: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeStock = new EventEmitter<ISearchStock | null>();
  @ViewChild('searchStock') searchStock!: SearchStockComponent;
  @Output() flaLink = new EventEmitter<{
    key: string;
    comp: IWorkSpaceComponet;
  }>();
  showSelectWindow: boolean;
  public headers = [
    { label: 'Flash', code: RANKING_TABS.FLASH, active: true },
    { label: 'Ranking', code: RANKING_TABS.RANKING, active: false },
    { label: 'Corretora', code: RANKING_TABS.BROKER, active: false },
  ];
  public searchLabel: string = 'Ativo';
  public stock: any = null;
  public periodSelected: RANKING_BROKER_PERIODS = RANKING_BROKER_PERIODS.TODAY;

  get headerOptions() {
    return this.component.metadata?.headerOptions;
  }

  get statusLink() {
    return this.component.metadata?.headerOptions?.link;
  }

  get stockSelected(): ISearchStock {
    return this.component?.metadata?.component?.stock ?? {};
  }

  constructor(
    private componentService: ComponentsService,
    private _workspaceHeader: WorkspaceHeader,
    private configs: ConfigService
  ) {
    this.showSelectWindow = !this.configs.isWebAuthenticationChannel();
  }

  ngAfterViewInit(): void {
    if (this.component?.metadata?.component?.stock) {
      this.stock = this.component?.metadata?.component?.stock;
      this.changeStock.emit(this.component?.metadata?.component?.stock);
      return;
    }
    this.changeStock.emit(null);
    this.stock = null;
    this.searchStock.searchLabel = 'Ativo';
  }

  selectView(tab: RANKING_TABS) {
    this.headers.map((view) => (view.active = tab === view.code));
    this.changeView.emit(tab);
  }

  public updatePeriod(event: any) {
    if (!this.enableChangePeriod) return;
    this.periodSelected = event.id;
    this.changePeriod.emit(event.id);
  }

  public selectStock = (stock: any) => {
    if (stock?.cd_stock === this.stock) {
      return;
    }

    if (stock) {
      this.stock = stock.cd_stock;
      this.component.metadata.component.stock = stock;
    } else {
      this.stock = null;
      this.searchStock.searchLabel = 'Ativo';
      this.component.metadata.component.stock = null;
    }

    this.componentService.updateMetadata(
      this.component.id,
      this.component.metadata
    );

    this.changeStock.emit(stock);
  };

  public disableLink() {
    this._workspaceHeader.$linkHeaderButton.next(this.component.id);
    this.selectStock(false);
  }
}
