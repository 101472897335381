<div
  class="section-high-low d-flex justify-content-between mt-3 w-100 px-1"
  [ngClass]="{ 'area-expanded': areaExpanded }"
>
  <div class="pl-1 w-50 pr-3">
    <div class="align-items-center d-flex fw-bold">
      <fla-icon
        name="navigation"
        size="icon-size-sm"
        css="text-feedback-success mr-1"
      ></fla-icon>
      Altas
    </div>
    <div id="tags" class="w-100 mt-3 overflow-hidden">
      <div class="gap-2 grid cursor-pointer">
        <ng-container *ngFor="let stock of stockListAltas.values()">
          <app-tag
            [title]="stock.key"
            [titleColor]="
              globalCdStock === stock.key ? 'text-brand-primary' : 'text-white'
            "
            [variation]="stock.custom_variation"
            [bgColor]="stock.arrow_hex"
            [color]="stock.arrow_font_hex"
            [isVariation]="true"
            [useSmallTag]="true"
            (click)="updateGlobalStock(stock)"
          ></app-tag>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="divider"></div>
  <div class="pl-1 w-50 pl-3">
    <div class="align-items-center d-flex fw-bold">
      <fla-icon
        name="navigation"
        size="icon-size-sm"
        css="text-feedback-error mr-1 rotate-vertical"
      ></fla-icon>
      Baixas
    </div>
    <div id="tags" class="w-100 mt-3 overflow-hidden">
      <div class="gap-2 grid cursor-pointer">
        <ng-container *ngFor="let stock of stockListBaixas.values()">
          <app-tag
            [title]="stock.key"
            [titleColor]="
              globalCdStock === stock.key ? 'text-brand-primary' : 'text-white'
            "
            [variation]="stock.custom_variation"
            [bgColor]="stock.arrow_hex"
            [color]="stock.arrow_font_hex"
            [isVariation]="true"
            [useSmallTag]="true"
            (click)="updateGlobalStock(stock)"
          ></app-tag>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div
  (click)="areaExpanded = !areaExpanded"
  class="d-flex justify-content-center mt-1"
>
  <fla-icon
    name="expand_more"
    size="icon-size-sm"
    [css]="
      areaExpanded
        ? 'rotate-vertical text-brand-primary cursor-pointer'
        : 'text-brand-primary cursor-pointer'
    "
  ></fla-icon>
</div>
<div class="d-flex justify-content-end mt-1 mb-3">
  <span class="fs-5"> Índice: </span>
  <rocket-button
    type="fill"
    css="min-width"
    [isSmall]="true"
    [label]="selectedIndex.cd_stock"
    (rocketClick)="openIndexModal()"
  ></rocket-button>
</div>
