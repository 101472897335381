import { ComponentRef, Injectable } from '@angular/core';
import { EventsContextMenuComponent } from './events-context-menu.component';
import { RocketCreateComponentService } from '@shared/rocket-components/services';
import { Observable, Subject } from 'rxjs';
import { IEventsContextMenu } from './events-context-menu.interface';

@Injectable()
export class EventsContextMenuService {
  private _component!: EventsContextMenuComponent;
  private _element!: HTMLElement | undefined;
  private _componentRef!: ComponentRef<EventsContextMenuComponent> | undefined;
  private onClose$ = new Subject<any | undefined>();

  constructor(private _createComponent: RocketCreateComponentService) {}

  show(options: IEventsContextMenu): {
    onClose: Observable<any | undefined>;
  } {
    this._removeBeforeInit();

    const create = this._createComponent.create(EventsContextMenuComponent);
    this._componentRef = create.componentRef;
    this._component = this._componentRef.instance;
    this._element = create.rootNodeElement as HTMLElement;

    this._bindValuesComponent(options);

    this._setComponentView(options.ref);
    this._component.onClose.subscribe((value) => {
      this.onClose$.next(value);
      this._destroyComponent();
    });

    return { onClose: this.onClose$.asObservable() };
  }

  private _bindValuesComponent(value: IEventsContextMenu) {
    this._component.top = value.top;
    this._component.left = value.left;
    this._component.refComponent = value.ref;
    this._component.events = value.events;
    this._component.isVisible = true;
    this._component.isRelevant = value.isRelevant;
    this._component.isTradeIdea = value.isTradeIdea;
  }

  private _setComponentView(id: string) {
    const rootComponent = document.getElementById(id)!;
    rootComponent.append(this._element!!);
  }

  private _removeBeforeInit() {
    const findAll = document.querySelectorAll('events-context-menu');
    if (findAll) {
      findAll.forEach((elem) => elem.remove());
    }
  }

  _destroyComponent() {
    this._element!!.remove();
    this._element = undefined;
    this._createComponent.destroy(this._componentRef!!);
    this._componentRef = undefined;
  }

  hasElement(): boolean {
    return !!this._element;
  }
}
