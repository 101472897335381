export const tripleExponentialMovingAverageTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  A Média Móvel Exponencial Tripla (TEMA) é uma média móvel exponencial que
  fornece uma ponderação tripla para os preços recentes. Ela tem como objetivo
  reduzir o atraso associado às médias móveis simples, duplas ou exponenciais.
</div>
<div class="mb-3">
  A TEMA é uma ferramenta útil para analisar tendências de preços de longo prazo
  e pode ser usada em conjunto com outros indicadores técnicos para ajudar a
  confirmar sinais de compra e venda.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">
  A TEMA é calculada aplicando três vezes a EMA em um conjunto de dados de
  preços. O primeiro cálculo é a EMA do período N. O segundo cálculo é a EMA da
  EMA do período N. Finalmente, o terceiro cálculo é a EMA da EMA da EMA do
  período N. Os resultados desses cálculos são então ponderados e somados para
  produzir um valor TEMA.
</div>
<div class="mb-3">
  Suponha que temos os seguintes preços de fechamento para uma ação ao longo dos
  últimos 7 dias:
</div>
<div class="mb-3">
  &bull; Dia 1: 10<br />
  &bull; Dia 2: 12<br />
  &bull; Dia 3: 13<br /> 
  &bull; Dia 4: 15<br />
  &bull; Dia 5: 14<br />
  &bull; Dia 6: 16<br />
  &bull; Dia 7: 18
</div>
<div class="mb-3">
  Para calcular a TEMA, primeiro precisamos calcular a EMA do período N (no
  nosso caso, 7 dias). A fórmula para calcular a EMA é a seguinte:
</div>
<div class="mb-3">
  EMA = (Preço Atual - EMA Anterior) x (2 / (N + 1)) + EMA Anterior
</div>
<div class="mb-3">
  Usando a fórmula acima e assumindo uma EMA anterior de 12 (a média dos preços
  de fechamento dos primeiros 6 dias), podemos calcular a EMA para o dia 7:
</div>
<div class="mb-3">EMA = (18 - 12) x (2 / (7 + 1)) + 12</div>
<div class="mb-3">EMA = 14,25</div>
<div class="mb-3">
  Agora, precisamos calcular a EMA da EMA do período N. Usando a mesma fórmula
  acima e assumindo uma EMA anterior de 13,5 (a média dos preços de fechamento
  dos primeiros 5 dias), podemos calcular a EMA da EMA para o dia 7:
</div>
<div class="mb-3">EMA da EMA = (14,25 - 13,5) x (2 / (7 + 1)) + 13,5</div>
<div class="mb-3">EMA da EMA = 13,875</div>
<div class="mb-3">
  Por fim, precisamos calcular a EMA da EMA da EMA do período N. Usando a mesma
  fórmula acima e assumindo uma EMA anterior de 13,94 (a média dos preços de
  fechamento dos primeiros 4 dias), podemos calcular a EMA da EMA da EMA para o
  dia 7:
</div>
<div class="mb-3">
  EMA da EMA da EMA = (13,875 - 13,94) x (2 / (7 + 1)) + 13,94
</div>
<div class="mb-3">EMA da EMA da EMA = 13,91875</div>
<div class="mb-3">Agora, podemos calcular a TEMA para o dia 7:</div>
<div class="mb-3">TEMA = (3 x EMA) - (3 x EMA da EMA) + EMA da EMA da EMA</div>
<div class="mb-3">TEMA = (3 x 14,25) - (3 x 13,875) + 13,91875</div>
<div class="mb-3">TEMA = 14,124</div>
<div class="mb-3">Portanto, a TEMA para o dia 7 é de 14,124.</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  A Média Móvel Exponencial Tripla (TEMA) é frequentemente plotada em um gráfico
  de preços ao lado do preço de fechamento para ajudar os traders a identificar
  tendências de preços
</div>
<div class="mb-3">
  Uma média móvel mais curta dará mais peso aos preços mais recentes, enquanto
  uma média móvel mais longa dará mais peso aos preços mais antigos. A TEMA é
  uma média ponderada que dá mais peso aos preços mais recentes e, portanto, é
  mais sensível às mudanças de preço recentes do que uma média móvel simples ou
  uma média móvel exponencial única.
</div>
<div class="mb-3">
  A Média Móvel Exponencial Tripla (TEMA) pode ser usada para identificar
  tendências de alta ou de baixa. Quando a TEMA está subindo, isso indica uma
  tendência de alta. Já quando estiver caindo, isso indica uma tendência de
  baixa. Ela também pode ser usada para identificar pontos de entrada e saída de
  negociações.
</div>
<div class="mb-3">
  No entanto, como com qualquer indicador técnico, a Média Móvel Exponencial
  Tripla (TEMA) não é infalível e deve ser usada em conjunto com outras
  ferramentas de análise técnica e análise fundamental para tomar decisões de
  negociação informadas.
</div>
<div class="mb-3">
  <img class="w-75" src="/assets/indicators/tema-como-funciona.png" />
</div>
<div class="fw-bolder">Como configurar</div>
<div class="fw-bolder">Valores</div>
<div class="text-decoration-underline">Período</div>
<div class="mb-3">
  O período a ser usado no cálculo da média móvel. 9 dias é o padrão.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/tema-entrada.png"
  />
</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">TEMA</div>
<div class="mb-3">
  Pode alternar a visibilidade da TEMA. Também pode selecionar a cor, a
  espessura e o estilo da linha do TEMA.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/tema-estilo.png"
  />
</div>
`;
