import { BaseRenderableSeries } from 'scichart';
import { TigerChartIndicatorCandleCounterBase } from './base/candle-counter-base';
import {
  TTigerChartIndicatorCreateOptions,
  TigerChartIndicatorCandleCounterOptions,
} from './indicators.types';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';

export class TigerChartIndicatorCandleCounter extends TigerChartIndicatorCandleCounterBase {
  constructor(
    options: TigerChartIndicatorCandleCounterOptions,
    themeService: ThemePreferencesService,
    stockChartService: StockChartService
  ) {
    super(options, themeService, stockChartService);
  }

  override create(
    options: TTigerChartIndicatorCreateOptions
  ): BaseRenderableSeries[] {
    this.updatePoints();
    return super.create(options);
  }

  override updatePoints(): void {
    let candleCount: any =
      (this.points.count.length ? this.points.count : this.data.candle_count) ||
      [];
    if (
      this.points.count.length > 0 &&
      this.data.candle_count.length > this.points.count.length
    ) {
      candleCount = this.data.candle_count;
      if (this.data.id_point.length > this.data.candle_count.length) {
        const sizeDifference =
          this.data.id_point.length - this.data.candle_count.length;
        for (let i = 0; i < sizeDifference; i++) {
          const lastIndex = this.data.candle_count.length - 1;
          candleCount.push(`${+candleCount[lastIndex] + 1}`);
        }
      }
    }
    this.points = {
      output: this.data.vl_low,
      count: candleCount.map((item: any) => item.toString()),
    };
  }

  override updateSettings() {
    // this.points = this.service.call('AVGPRICE', {
    //   close: this.data.vl_close,
    //   high: this.data.vl_high,
    //   low: this.data.vl_low,
    //   open: this.data.vl_open
    // });
    const candleCount =
      (this.points.count.length ? this.points.count : this.data.candle_count) ||
      [];
    this.points = {
      output: this.data.vl_low,
      count: candleCount.map((item) => item.toString()),
    };
    super.updateSettings();
  }
}
