import { IWorkSpace } from '@core/interface';
import { ParaComecar } from '../initial-workspaces';
import { Pro } from '../initial-workspaces/Pro';
import { Mercado } from '../initial-workspaces/Mercado';
import { RaioX } from '../initial-workspaces/RaioX';
import { AnaliseAtivos } from '../initial-workspaces/AnaliseAtivos';
import { AnaliseFluxo } from '../initial-workspaces/AnaliseFluxo';
import { Opcoes } from '../initial-workspaces/Opcoes';

export function generateLayoutWorkspace(
  workspace: IWorkSpace,
  stockSelected: any
) {
  switch (workspace.name) {
    case 'Para começar':
      return new ParaComecar(workspace.id, stockSelected);
    case 'Mercado':
      return new Mercado(workspace.id, stockSelected);
    case 'Pro':
      return new Pro(workspace.id, stockSelected);
    case 'Raio X':
      return new RaioX(workspace.id, stockSelected);
    case 'Análise de Ativos':
      return new AnaliseAtivos(workspace.id, stockSelected);
    case 'Análise de Fluxo':
      return new AnaliseFluxo(workspace.id, stockSelected);
    case 'Opções':
      return new Opcoes(workspace.id, stockSelected);
    default:
      return new ParaComecar(workspace.id, stockSelected);
  }
}
