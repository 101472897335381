import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { OptionsComponentService } from '@shared/components/options/options-component.service';
import {
  OPTIONS_CONFIG_MAPPING,
  OPTIONS_TABLE_CONFIG_TABS,
} from '@shared/components/options/types';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { AUTH_LOCAL_KEYS } from '@shared/services/core/const/auth_util.const';

@Component({
  selector: 'app-options-list-preferences-modal',
  templateUrl: './options-list-preferences-modal.component.html',
  styleUrls: ['./options-list-preferences-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsListPreferencesModalComponent
  extends RocketModalRef
  implements OnInit
{
  configs!: any;
  allCheck = {
    [OPTIONS_TABLE_CONFIG_TABS.OPTION]: true,
    [OPTIONS_TABLE_CONFIG_TABS.PRICE]: true,
    [OPTIONS_TABLE_CONFIG_TABS.VOLATILITY]: true,
    [OPTIONS_TABLE_CONFIG_TABS.GREEk]: true,
  };
  groupWidths: any = {
    [OPTIONS_TABLE_CONFIG_TABS.OPTION]: 632,
    [OPTIONS_TABLE_CONFIG_TABS.PRICE]: 394,
    [OPTIONS_TABLE_CONFIG_TABS.VOLATILITY]: 600,
    [OPTIONS_TABLE_CONFIG_TABS.GREEk]: 520,
  };

  tabs: { [key: string]: any } = {};
  tabsBkpMapping: { [key: string]: number } = {};
  tabGroups = [
    OPTIONS_TABLE_CONFIG_TABS.OPTION,
    OPTIONS_TABLE_CONFIG_TABS.PRICE,
    OPTIONS_TABLE_CONFIG_TABS.VOLATILITY,
    OPTIONS_TABLE_CONFIG_TABS.GREEk,
  ];

  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: any,
    private _rocketModalService: RocketModalService,
    private _customPreferencesService: CustomPreferencesService,
    private _optionsComponentService: OptionsComponentService,
    private cdr: ChangeDetectorRef
  ) {
    super(_rocketModalService);
  }

  ngOnInit() {
    this.data.columns.forEach((column: any, index: number) => {
      let { field } = column;
      if (this.data.tab == 'callput') {
        this.tabsBkpMapping[field] = index;
        if (!column.field.endsWith('_call')) return;

        field = field.slice(0, -5);
      }

      const group = OPTIONS_CONFIG_MAPPING[field];
      !this.tabs[group] && (this.tabs[group] = []);
      this.tabs[group].push(column);

      column.hide && (this.allCheck[group] = false);
    });
  }

  setVisibleOrInvisible(event: any, column: any, tab: string) {
    const index = this.tabs[tab].findIndex(
      (col: any) => col.field == column.field
    );
    this.tabs[tab][index].hide = !event;
    this.allCheck[tab] = this._getAllChecked(this.tabs[tab]);
  }

  selectAll(event: any, tab: string) {
    this.allCheck[tab] = !this.allCheck[tab];
    this.tabs[tab].forEach(
      (column: any) => (column.hide = !this.allCheck[tab])
    );
  }

  onSave = () => {
    const configs = JSON.parse(
      this._customPreferencesService.getCustomPreference(
        AUTH_LOCAL_KEYS.OPTIONS_CONFIGURATION
      )
    );

    const columns = this._getColumns();

    this._customPreferencesService.customPreference = {
      ...configs,
      key: AUTH_LOCAL_KEYS.OPTIONS_CONFIGURATION,
      value: JSON.stringify({
        ...configs,
        [this.data.tab]: { columns: columns },
        key: this.data.configKey,
      }),
    };
    this._optionsComponentService.onSettingsUpdate$.next(this.data);
    this.close();
    this.cdr.detectChanges();
  };

  onClose = () => {
    this.close([]);
    this.cdr.detectChanges();
  };

  private _getAllChecked(columns: any) {
    for (let index = 0; index < columns.length; index++) {
      if (columns[index].hide) {
        return false;
      }
    }
    return true;
  }

  private _getColumns() {
    const columns = this.tabGroups.reduce(
      (previousColumns: any, tab: any) => [
        ...previousColumns,
        ...this.tabs[tab],
      ],
      []
    );
    if (this.data.tab != 'callput') return columns;

    columns.map((column: any) => {
      const field = column.field.replace('_call', '_put');
      this.data.columns[this.tabsBkpMapping[column.field]].hide = column.hide;
      this.data.columns[this.tabsBkpMapping[field]].hide = column.hide;
    });

    return this.data.columns;
  }
}
