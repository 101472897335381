import { Routes, RouterModule } from '@angular/router';
import { InvalidBrowserComponent } from './invalid-browser.component';

const routes: Routes = [
  {
    path: '',
    component: InvalidBrowserComponent,
  },
];

export const InvalidBrowserRoutes = RouterModule.forChild(routes);
