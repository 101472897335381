<div class="d-flex justify-content-end align-items-center pt-1">
  <div class="dropdown dropdown-position">
    <a
      class="dropdown-custom-size border btn btn-primary dropdown-toggle fw-bold p-1 round-lg text-white bg-transparent fs-6"
      role="button"
      id="dropdownMenuLink"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ periodSelectedLabel }}
    </a>

    <ul class="dropdown-menu cursor-pointer" aria-labelledby="dropdownMenuLink">
      <li *ngFor="let visualization of periodsEvolution">
        <a
          class="dropdown-item"
          type="button"
          (click)="changePeriod(visualization)"
        >
          {{ visualization.label }}
        </a>
      </li>
    </ul>
  </div>
</div>

<div class="chart-parent">
  <div
    (mousemove)="onMouseMove($event)"
    [id]="refChart"
    #chartContainer
    class="chart-container"
  ></div>
</div>

<div
  [id]="'legend-div-id_' + refChart"
  class="w-100 d-inline-flex align-items-center justify-content-center custom-legend"
></div>

<app-foreigners-chart-tooltip
  [display]="displayTooltip"
  [data]="tooltipParams"
  [leftPosition]="tooltipX"
  [topPosition]="tooltipY"
></app-foreigners-chart-tooltip>
