export enum TYPE_CLICK {
  MARKET_BUY = 'market_buy',
  MARKET_SELL = 'market_sell',
  BUY = 'buy',
  SELL = 'sell',
  SPLIT = 'split',
  RESET = 'reset',
  INVERT = 'invert',
  SCHEDULE = 'schedule_order',
  CANCEL = 'cancel',
  UPDATE_FORM = 'update_form',
}
