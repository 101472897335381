<div
  #TigerChartIndicatorMenu
  class="Tiger-chart-menu"
  [attr.style]="style"
  (mouseleave)="onMouseOut($event)"
>
  <ul>
    <ng-template ngFor let-item [ngForOf]="menu">
      <li [id]="item.id" (mouseover)="onHover(item)" (click)="onClick(item)">
        <span>{{ item.label }}</span>
        <span *ngIf="item.submenu">></span>
      </li>
      <hr *ngIf="item.divisor" class="Tiger-chart-menu-divider" />
    </ng-template>
  </ul>
</div>
