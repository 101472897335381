<div
  class="round bg-neutral-strong position-absolute fs-7"
  [style.width]="'210px'"
  [style.padding]="'10px'"
  [style.top.px]="position.top"
  [style.left.px]="position.left"
  [style.z-index]="9999"
  [style.display]="isTour ? 'none' : 'block'"
  (mouseleave)="onFocusOut()"
>
  <div class="list-group list-group-flush">
    <div class="header-context-menu">
      <span>Lista de Alertas</span>
    </div>

    <ng-container *ngIf="!keyHeader">
      <button
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="handleAction(menuOptions.EDIT_ALERT)"
        [disabled]="!isRowSelect || isCompletedAlert"
      >
        Editar Alerta
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="edit"
        ></fla-icon>
      </button>

      <button
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="handleAction(menuOptions.REMOVE_ALERT)"
        [disabled]="!isRowSelect || isCompletedAlert"
      >
        Excluir Alerta
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="close"
        ></fla-icon>
      </button>

      <button
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="handleAction(menuOptions.REOPEN_ALERT)"
        [disabled]="!isRowSelect || !isCompletedAlert"
      >
        Reativar Alerta
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="refresh"
        ></fla-icon>
      </button>
    </ng-container>

    <hr class="separator-context-menu" />

    <ng-container *ngIf="keyHeader">
      <button
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="handleAction(menuOptions.HIDDEN_COLUMN)"
        [disabled]="!keyHeader"
      >
        Ocultar Coluna
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="visibility_off"
        ></fla-icon>
      </button>

      <button
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="handleAction(menuOptions.PREFERENCES)"
      >
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="settings"
        ></fla-icon>
        Preferências
      </button>
    </ng-container>

    <ng-container *ngIf="!isCardView">
      <button
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="handleAction(menuOptions.RESET_FILTERS)"
        [disabled]="!filterIsActive"
      >
        Limpar Filtros
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="filter_alt_off"
        ></fla-icon>
      </button>

      <button
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="handleAction(menuOptions.RESET_SORT)"
        [disabled]="!isSorted"
      >
        Limpar Ordenação
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="sort"
        ></fla-icon>
      </button>

      <hr class="separator-context-menu" />

      <button
        type="button"
        class="list-group-item list-group-item-action context-item-button"
        (click)="handleAction(menuOptions.EXPORT_DATA)"
      >
        Exportar Dados
        <fla-icon
          size="icon-size-tiny"
          css="position-absolute left-0 text-neutral-medium fs-4"
          name="download"
        ></fla-icon>
      </button>
    </ng-container>

    <hr class="separator-context-menu" />

    <button
      *ngIf="!isCardView"
      type="button"
      class="list-group-item list-group-item-action context-item-button"
      (click)="handleAction(menuOptions.RESET_PRESET)"
    >
      Restaurar
      <fla-icon
        size="icon-size-tiny"
        css="position-absolute left-0 text-neutral-medium fs-4"
        name="refresh"
      ></fla-icon>
    </button>

    <button
      type="button"
      class="list-group-item list-group-item-action context-item-button"
      disabled
      (click)="handleAction(menuOptions.HELP)"
    >
      Ajuda
      <fla-icon
        size="icon-size-tiny"
        css="position-absolute left-0 text-neutral-medium fs-4"
        name="help_outline"
      ></fla-icon>
    </button>
  </div>
</div>
