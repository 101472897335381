import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TTokenAuthenticator } from '../../types';
import { TokenService } from '@shared/services/api/trademap/v1/token.service';
import { ToastService } from '@shared/services';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-token-authenticator-disconnect',
  templateUrl: './token-authenticator-disconnect.component.html',
  styleUrls: ['./token-authenticator-disconnect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TokenAuthenticatorDisconnectComponent {
  @Input() authenticator!: TTokenAuthenticator;
  @Output() cancel: EventEmitter<null> = new EventEmitter();
  @Output() disconnected: EventEmitter<null> = new EventEmitter();

  loading = false;
  showPassword = false;
  userPassword: FormControl = new FormControl('');

  constructor(
    private _tokenService: TokenService,
    private _cdr: ChangeDetectorRef,
    private _toastService: ToastService
  ) {}

  goBack() {
    this.cancel.emit();
  }

  emitDisconnected() {
    this.disconnected.emit();
  }

  disconnect() {
    this.loading = true;
    this._cdr.detectChanges();
    this._tokenService
      .disconnectCurrentToken(this.userPassword.value, this.authenticator.id)
      .subscribe({
        next: () => {
          this._toastService.showToast(
            'success',
            `Sua desconexão com ${this.authenticator.name} foi feita com sucesso`
          );
          this.loading = false;
          this._cdr.detectChanges();
          this.disconnected.emit();
        },
        error: (error) => {
          const msg =
            error.code === 'WRONG_PASSWORD'
              ? error.message
              : 'Falha ao tentar desconectar. Por favor, tentar novamente';
          this._toastService.showToast('error', msg);
          this.loading = false;
          this._cdr.detectChanges();
        },
      });
  }
}
