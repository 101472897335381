import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  HostListener,
  Inject,
  OnDestroy,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
// @ts-ignore
import { Collapse } from '@omni/omnitheme';

@Directive({
  selector: '[flaCollapse]',
})
export class FlaCollapseDirective implements OnInit, AfterViewInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('flaCollapse') ref!: string;
  @Input() flaCollapseRefParent!: string;
  @Input() flaCollapseIsOpen: boolean = false;
  @Input() flaCollapseDelay: number = 0;
  @Input() flaCollapseParentId!: string;
  private _collapse: Collapse;
  private _elementCollapse!: HTMLElement | null;
  private _element!: HTMLElement;
  private set _ariaExpanded(value: boolean) {
    this._renderer2.setAttribute(
      this._element,
      'aria-expanded',
      value.toString()
    );
  }
  constructor(
    private _elementRef: ElementRef,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._element = this._elementRef.nativeElement;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => this._init(), this.flaCollapseDelay);
  }

  ngOnDestroy(): void {
    this._collapse?.dispose();
  }

  @HostListener('click', ['$event'])
  toggle(event: PointerEvent): void {
    this._collapse?.toggle();

    this._closeAllChildren(event.target as HTMLElement);
  }

  private _init(): void {
    this._elementCollapse = this._document.getElementById(this.ref);

    this._renderer2.addClass(this._elementCollapse, 'collapse');
    this._renderer2.setAttribute(
      this._elementCollapse,
      'data-bs-parent',
      this.flaCollapseParentId
    );

    this._collapse = Collapse.getOrCreateInstance(this._elementCollapse, {
      toggle: false,
      parent: this.flaCollapseRefParent,
    });

    this._listenEvents();
    if (this.flaCollapseIsOpen) this._collapse.show();
  }

  private _closeAllChildren(elem: HTMLElement) {
    if (
      !this.flaCollapseParentId ||
      elem.getAttribute('aria-expanded') === 'true'
    )
      return;

    if (elem?.id === this.flaCollapseParentId) {
      const elements = this._document.querySelectorAll(
        `[data-bs-parent=${this.flaCollapseParentId}]`
      );
      elements.forEach((elem) => Collapse.getInstance(elem).hide());
    }
  }

  private _listenEvents(): void {
    this._renderer2.listen(
      this._elementCollapse,
      'hide.bs.collapse',
      (event: CustomEvent) => {
        event.stopPropagation();
        this._ariaExpanded = false;
      }
    );
    this._renderer2.listen(
      this._elementCollapse,
      'show.bs.collapse',
      (event: CustomEvent) => {
        event.stopPropagation();
        this._ariaExpanded = true;
      }
    );
  }
}
