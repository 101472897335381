import { FlaFormatNumberPipe } from './format-number.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [FlaFormatNumberPipe],
  imports: [CommonModule],
  exports: [FlaFormatNumberPipe],
  providers: [],
})
export class FlaFormatNumberModule {}
