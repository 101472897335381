import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  WritableSignal,
  computed,
  signal,
} from '@angular/core';
import {
  OPTIONS_TYPES,
  TStrategyOption,
  TStrategyOptionChartSeries,
} from '../../types';
import { OptionsComponentService } from '../../options-component.service';
import { ISearchStock } from '@core/interface';
import { OptionsService } from '@shared/services/api/trademap/v1/options.service';
import { TWebAssemblyChart } from 'scichart';
import { randomId } from '@rocket-ui/utils';
import { ToastService } from '@shared/services';
import { CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import { OptionsStrategyGraphComponent } from './strategy-graph/options-strategy-graph.component';
import { Subject, Subscription, delay } from 'rxjs';

@Component({
  selector: 'app-options-strategy',
  templateUrl: './options-strategy.component.html',
  styleUrls: ['./options-strategy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsStrategyComponent implements OnInit, OnDestroy {
  @Input() set stock(stock: ISearchStock | undefined) {
    if (stock) {
      this.stockSelected.set(stock);
    }
  }
  stockSelected: WritableSignal<ISearchStock | undefined> = signal(undefined);
  @Input() expirationDate!: number | string | undefined;
  @Input() refId: string = randomId('options');
  @Input() parentHeight!: string;
  @ViewChild('graph') graph!: OptionsStrategyGraphComponent;
  private _visibility$ = new Subject<void>();
  private visibility$!: Subscription;
  typeNames = OPTIONS_TYPES;
  strategyOptions: TStrategyOption[] = [];
  daysToExpire: number = 0;
  strategyExpirationDate: string = '';
  maxLoss: number = 0;
  maxProffit: number = 0;
  SimpleChartSeries: TStrategyOptionChartSeries[] = [];
  SimulationChartSeries: TStrategyOptionChartSeries[] = [];
  vlCost: number = 0;
  theme!: any;
  chart!: TWebAssemblyChart | undefined;
  loading = false;
  fullscreen: {
    enable: boolean;
    label: 'Minimizar' | 'Maximizar';
    icon: 'fullscreen' | 'fullscreen_exit';
  } = {
    enable: false,
    label: 'Maximizar',
    icon: 'fullscreen',
  };
  visibility: {
    enable: boolean;
    label: 'Ocultar' | 'Mostrar';
    icon: 'expand_more' | 'expand_less';
  } = {
    enable: true,
    label: 'Ocultar',
    icon: 'expand_more',
  };
  OPTIONS_SIDE_MAPPING: any = { B: 'C', S: 'V' };
  height = 270;
  heightBackup = 270;
  isDragging = false;
  dragPosition = { x: 0, y: 0 };
  expanded = '';

  standardLot = computed(() => {
    return this.stockSelected()?.standard_lot || 0;
  });

  strategyName = signal('');

  constructor(
    private _optionsComponentService: OptionsComponentService,
    private _optionsService: OptionsService,
    private _toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this._optionsComponentService.onSetStrategy$.subscribe(
      (isEdition: boolean) => {
        this._addOption(isEdition);
        this.strategyName.set(this._optionsComponentService.strategyName);
      }
    );
    this.visibility$ = this._visibility$.pipe(delay(100)).subscribe(() => {
      this.visibility.enable = true;
      this.visibility.icon = 'expand_more';
      this.visibility.label = 'Ocultar';
      this.height = this.heightBackup;
      this.graph && this.graph.initGraph();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.visibility$ && this.visibility$.unsubscribe();
  }

  editStrategy(option: TStrategyOption, event: any) {
    if (option.qtty_lot == event.value) return;

    this._optionsComponentService.setStrategyOption({
      ...option,
      qtty_lot: event.value,
    });
  }

  deleteStrategy(event: TStrategyOption) {
    this._optionsComponentService.removeStrategyOption(event);
  }

  toggleFullscreenMode(): void {
    if (!this.fullscreen.enable) {
      this.fullscreen.enable = true;
      this.fullscreen.icon = 'fullscreen_exit';
      this.fullscreen.label = 'Minimizar';
      this.heightBackup = this.height;
      this.height = parseInt(this.parentHeight) - 147;
      this.graph && this.graph.initGraph();
    } else {
      this.height = this.heightBackup;
      this.fullscreen.icon = 'fullscreen';
      this.fullscreen.label = 'Maximizar';
      this.fullscreen.enable = false;
    }
    this.cdr.detectChanges();
  }

  toggleVisibility(): void {
    if (!this.visibility.enable) {
      this.visibility.enable = true;
      this.visibility.icon = 'expand_more';
      this.visibility.label = 'Ocultar';
      this.height = this.heightBackup;
      this.graph && this.graph.initGraph();
    } else {
      this.heightBackup = this.height;
      this.height = 0;
      this.visibility.icon = 'expand_less';
      this.visibility.label = 'Mostrar';
      this.visibility.enable = false;
    }
    this.cdr.detectChanges();
  }

  onDragStarted() {
    this.isDragging = true;
    this.cdr.detectChanges();
  }

  onDragMoved(event: CdkDragMove) {
    event.event.preventDefault();
    if (event.distance.y < 0) {
      const newHeight = this.height + event.distance.y * -1;
      const parentHeight = parseInt(this.parentHeight) - 92;
      if (newHeight >= parentHeight) {
        document.dispatchEvent(new Event('mouseup'));
        this.height = parentHeight;
        this.isDragging = false;
        this.dragPosition = { x: 0, y: 0 };
      }
    } else {
      const newHeight = this.height - event.distance.y;
      if (newHeight <= 200) {
        document.dispatchEvent(new Event('mouseup'));
        this.height = 200;
        this.isDragging = false;
        this.dragPosition = { x: 0, y: 0 };
      }
    }
    this.cdr.detectChanges();
  }

  onDragEnd(event: CdkDragEnd) {
    const newHeight = this.height + event.distance.y * -1;
    if (newHeight > 200) {
      this.height = newHeight;
    }
    this.isDragging = false;
    this.dragPosition = { x: 0, y: 0 };
    this.cdr.detectChanges();
  }

  confirm() {
    if (
      !this.expirationDate ||
      !this.stockSelected() ||
      this._optionsComponentService.strategyOptions.length == 0
    ) {
      return;
    }
    this.loading = true;
    const { cd_stock } = this.stockSelected()!!;
    const desctription = this._getDescription();
    this._optionsService
      .createOption(cd_stock, this.strategyOptions, desctription, this.vlCost)
      .subscribe((data: any) => {
        if (data) {
          this._toastService.showToast('success', 'Opção criada com sucesso!');
          this._optionsComponentService.clearStrategyOptions();
          this.strategyOptions = [];
          this.daysToExpire = 0;
          this.maxLoss = 0;
          this.maxProffit = 0;
          this.SimpleChartSeries = [];
          this.SimulationChartSeries = [];
          this.vlCost = 0;
        }
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  clearStrategy() {
    this._optionsComponentService.clearStrategyOptions();
    this.cdr.detectChanges();
  }

  private _addOption(isEdition: boolean) {
    if (
      !this.expirationDate ||
      !this.stockSelected() ||
      this._optionsComponentService.strategyOptions.length == 0
    ) {
      this.strategyOptions = [];
      this.daysToExpire = 0;
      this.maxLoss = 0;
      this.maxProffit = 0;
      this.SimpleChartSeries = [];
      this.SimulationChartSeries = [];
      this.vlCost = 0;
      this.chart = undefined;
      this.graph && this.graph.resetLines();
      this.cdr.detectChanges();
      return;
    }
    const { cd_stock, standard_lot } = this.stockSelected()!!;
    const legs = this._optionsComponentService.strategyOptions.map(
      (option) => ({
        ...option,
        qtty_lot: option.qtty_lot ? option.qtty_lot : standard_lot,
      })
    );
    this._optionsService.addOption(cd_stock, legs).subscribe((data: any) => {
      !isEdition && (this.strategyOptions = legs);
      this.daysToExpire = data.days_to_expiration;
      this.maxLoss = data.max_loss;
      this.maxProffit = data.max_proffit;
      this.SimpleChartSeries = data.series_chart_simple;
      this.SimulationChartSeries = data.series_chart_simulation;
      this.vlCost = data.vl_cost;
      let allDays = 0;
      let workingDays = 0;
      for (let i = 1; workingDays < this.daysToExpire; i++) {
        allDays = i;
        const date = new Date();
        date.setDate(date.getDate() + i);
        if (date.getDay() === 6 || date.getDay() === 0) {
          continue;
        }
        workingDays++;
      }
      const date = new Date();
      date.setDate(date.getDate() + allDays);
      this.strategyExpirationDate = date.toLocaleDateString('pt-br', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      });
      this.cdr.detectChanges();
      this._visibility$.next();
    });
  }

  private _getDescription() {
    const today = new Date();
    const formattedDate = today.toLocaleDateString('pt-BR', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    });
    return `${this.stockSelected()?.cd_stock} ${formattedDate}`;
  }
}
