<div [ngClass]="isDesktop ? 'component-height' : 'h-100'">
  <section class="h-100 w-100" [ngClass]="{ 'd-flex': !isBrokerView }">
    <section class="w-100" [ngClass]="{ 'h-100 bg-dark': isBrokerView }">
      <ng-container *ngIf="brokerActive && isBrokerView">
        <rocket-button
          [label]="brokerActive.nm_brokerage_valemobi"
          type="fill"
          (rocketClick)="goBackListBrokers()"
          css="text-white fs-7 m-0 p-2 position-absolute btn-go-back-brokers text-truncate"
        >
          <fla-icon
            name="arrow_back_ios"
            size="icon-size-micro"
            css="text-brand-primary"
          ></fla-icon>
        </rocket-button>
      </ng-container>
      <ng-container *ngIf="!isBrokerView">
        <div
          class="d-flex bg-filter justify-content-between align-items-center position-relative"
        >
          <div class="d-flex pt-2 overflow-auto position-absolute">
            <ng-container *ngFor="let option of filterOptions">
              <a
                type="button"
                class="nav-link p-2 lh-sm round-top nowrap"
                [ngClass]="{ 'text-brand-primary fw-bolder': option.active }"
                (click)="selectViewType(option.code, true)"
              >
                {{ option.label }}
              </a>
            </ng-container>
          </div>

          <ng-container *ngIf="typeRanking === 'FLASH'">
            <app-flash-market-tooltip
              [tooltipX]="tooltipX"
              [tooltipY]="tooltipY"
              [tooltipWidth]="tooltipWidth"
              [seriesToShow]="seriesToShow"
              [hideCurrentValue]="hideCurrentValue"
              [isDarkMode]="isDarkMode"
              [xValueToDisplay]="dateTimeDefaultValue"
              [dateTime]="tooltipDateTime"
            ></app-flash-market-tooltip>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="loading || loadingChart">
        <div class="loading bg-dark">
          <div class="spinner-border" role="status"></div>
        </div>
      </ng-container>

      <ng-container *ngIf="isBrokerView && rowData?.length">
        <div class="names-chart">
          <ng-container *ngIf="!brokerActive">
            <div>Top Compradores</div>
            <div>Top Vendedores</div>
          </ng-container>
          <ng-container *ngIf="brokerActive">
            <div>Top Ativos (Compra)</div>
            <div>Top Ativos (Venda)</div>
          </ng-container>
        </div>
      </ng-container>
      <div
        [id]="refChart"
        #chartContainer
        class="chart-container"
        [ngClass]="{
          'broker-height': isBrokerView,
          'd-none': !(
            (brokersSelected.size() || isBrokerView) &&
            !withoutContent
          )
        }"
      ></div>
      <ng-container
        *ngIf="
          !((brokersSelected.size() || isBrokerView) && !withoutContent) &&
          !loading &&
          !loadingChart
        "
      >
        <div
          class="h-100 align-items-center d-flex justify-content-center fs-6 fw-bold bg-neutral-stronger no-data"
          [ngClass]="{ 'broker-height': isBrokerView }"
        >
          {{
            withoutContent
              ? 'Não há informações para o ativo selecionado'
              : 'Nenhuma corretora selecionada'
          }}
        </div>
      </ng-container>
    </section>

    <section
      [ngClass]="{
        'h-100 position-relative': !isBrokerView,
        'empty-chart':
          !(brokersSelected.size() || isBrokerView) && withoutContent
      }"
    >
      <ng-container *ngIf="!isBrokerView">
        <div
          cdkDrag
          cdkDragLockAxis="x"
          [cdkDragFreeDragPosition]="transform"
          class="divider-chart"
          [ngClass]="{ invisible: !rowData.length }"
          (cdkDragEnded)="moveDivider($event)"
        >
          <div class="position-arrow">
            <fla-icon
              css="text-neutral-strong"
              size="icon-size-md"
              name="chevron_left"
            ></fla-icon>
          </div>
        </div>
      </ng-container>
      <div
        #tableRanking
        [ngClass]="{
          'table-ranking': !loading && rowData.length,
          'bottom-0 broker-height position-absolute w-100': isBrokerView,
          'opacity-0': loading || loadingChart
        }"
        [style.width.px]="divWidth + 1"
      >
        <rocket-grid
          *ngIf="!loading && rowData.length"
          height="100%"
          refComponent="ranking"
          noRowsTemplate="Não há informações para o ativo selecionado"
          rowSelection="single"
          [isConfig]="true"
          [animateRows]="true"
          [rowDragManaged]="false"
          [rowDragEntireRow]="false"
          [suppressDragLeaveHidesColumns]="true"
          [columnDefs]="columnDefs"
          [rowData]="rowData"
          [fieldIndexer]="tableFieldIndexer"
          [sortingOrder]="sortingOrder"
          [sortByField]="viewTypeSelected"
          [onUpdateField]="onUpdateField"
          [resizeToFitWidth]="widthComponent"
          (flaSelectRow)="selectRow($event)"
        ></rocket-grid>
      </div>
    </section>
  </section>
</div>
