import { Component, Input } from '@angular/core';
import { TTradeIdeaEventsStrategyItem } from '@shared/tiger-chart/types/tiger-chart.types';

@Component({
  selector: 'app-table-trade-idea-items',
  templateUrl: './table-trade-idea-items.component.html',
  styleUrls: ['./table-trade-idea-items.component.scss'],
})
export class TableTradeIdeaItemsComponent {
  @Input() items!: TTradeIdeaEventsStrategyItem[];
  @Input() isMediumBorder: boolean = false;
  @Input() isSmall: boolean = false;
}
