import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';
import { OptionsComponents } from '../component-list';

export const FLOW_INVESTOR_COMPONENT: any = (options?: OptionsComponents) => ({
  name: 'Fluxo de investidores',
  id: COMPONENTS_NAMES_ENUM.FLOW_INVESTOR,
  icon: 'groups',
  cod: 'FLOW_INVESTOR',
  data: null,
  close: true,
  fullscreen: true,
  link: false,
  unitOfMeasurement: options?.unit ?? 'px',
  height: options?.height ?? 438,
  width: options?.width ?? 880,
  minWidth: '880px',
  left: options?.left ?? 'calc(15% + 5px)',
  top: options?.top ?? '0px',
  label: 'Fluxo de investidores',
  noPublished: false,
  hasBorder: false,
  divider: false,
  iconSvg: '',
});
