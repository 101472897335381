import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConsensusStockCacheService } from '@shared/services/core/cache/consensus-stock-cache.service';
import { RestService } from '@shared/services/rest';
import { firstValueFrom, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConsensusService extends RestService {
  override _url: string = 'api/trademap/v2';

  constructor(
    private consensusStockCacheService: ConsensusStockCacheService,
    http: HttpClient
  ) {
    super(http);
  }

  public getConsensus(cdStock: string[]) {
    const key = `${cdStock.join()}`;
    let request$: any = this.consensusStockCacheService.getValue(key);
    if (!request$) {
      request$ = this.get(`consensus?stocks=${cdStock.join()}`).pipe(
        shareReplay(1)
      );
      this.consensusStockCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }
}
