export const movingAverageTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  A média móvel é um indicador técnico usado na análise de gráficos financeiros
  para ajudar a identificar tendências de preços ao longo do tempo. A média
  móvel é calculada a partir da média dos preços de um ativo durante um período
  de tempo especificado, como 10 dias, 50 dias ou 200 dias.
</div>
<div class="mb-3">
  Por exemplo, uma média móvel de 50 dias é calculada somando os preços de
  fechamento dos últimos 50 dias e dividindo o resultado por 50. Esse processo é
  repetido diariamente, criando uma linha suavizada que representa a tendência
  de preços ao longo do tempo.
</div>
<div class="mb-3">
  Os traders e analistas usam a média móvel para determinar a direção geral da
  tendência de preços e para identificar pontos de entrada e saída para
  negociações. Uma média móvel ascendente pode indicar uma tendência de alta,
  enquanto uma média móvel em declínio pode indicar uma tendência de baixa. Os
  cruzamentos entre duas médias móveis também podem fornecer sinais de compra ou
  venda para os traders.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">A fórmula matemática para a média móvel seria:</div>
<div class="mb-3">
  Média Móvel = (Preço de fechamento do dia N + Preço de fechamento do dia N-1 +
  ... + Preço de fechamento do dia N-k) / k
</div>
<div class="mb-3">
  Onde:
  <br />
  N é o dia atual
  <br /><br />
  k é o período de tempo selecionado para a média móvel
  <br /><br />
  Suponha que temos uma planilha que contém os preços de fechamento de uma ação
  durante os últimos 10 dias, conforme a tabela abaixo:
</div>

<table class="mb-3 table w-auto table-bordered">
  <tr>
    <th class="fs-5 p-2 fw-bolder text-left" scope="col">Dia</th>
    <td class="fs-5 p-2 text-right">1</td>
    <td class="fs-5 p-2 text-right">2</td>
    <td class="fs-5 p-2 text-right">3</td>
    <td class="fs-5 p-2 text-right">4</td>
    <td class="fs-5 p-2 text-right">5</td>
    <td class="fs-5 p-2 text-right">6</td>
    <td class="fs-5 p-2 text-right">7</td>
    <td class="fs-5 p-2 text-right">8</td>
    <td class="fs-5 p-2 text-right">9</td>
    <td class="fs-5 p-2 text-right">10</td>
  </tr>

  <tr>
    <th style="width: 110px !important;" class="fs-5 p-2 fw-bolder text-left" scope="col">Preço de Fechamento</th>
    <td class="fs-5 p-2 text-right">50,00</td>
    <td class="fs-5 p-2 text-right">51,25</td>
    <td class="fs-5 p-2 text-right">52,50</td>
    <td class="fs-5 p-2 text-right">53,75</td>
    <td class="fs-5 p-2 text-right">54,00</td>
    <td class="fs-5 p-2 text-right">55,25</td>
    <td class="fs-5 p-2 text-right">54,50</td>
    <td class="fs-5 p-2 text-right">53,75</td>
    <td class="fs-5 p-2 text-right">52,25</td>
    <td class="fs-5 p-2 text-right">51,00</td>
  </tr>
</table>

<div class="mb-3">
  Para calcular a média móvel de 3 dias, por exemplo, depois dos 3 primeiros
  fechamentos, fazemos: (50 + 51,25 + 52,50) /3 = 51,25.
</div>
<div class="mb-3">
  Abaixo, a tabela de fechamento dos 10 períodos com a respectiva média móvel de
  3 períodos:
</div>

<table class="mb-3 table w-auto">
  <tr>
    <th class="p-2 fs-5 fw-bolder pr-2 text-left" scope="col">Dia</th>
    <td class="p-2 fs-5 text-right">1</td>
    <td class="p-2 fs-5 text-right">2</td>
    <td class="p-2 fs-5 text-right">3</td>
    <td class="p-2 fs-5 text-right">4</td>
    <td class="p-2 fs-5 text-right">5</td>
    <td class="p-2 fs-5 text-right">6</td>
    <td class="p-2 fs-5 text-right">7</td>
    <td class="p-2 fs-5 text-right">8</td>
    <td class="p-2 fs-5 text-right">9</td>
    <td class="p-2 fs-5 text-right">10</td>
  </tr>
  <tr>
    <th class="p-2 fs-5 fw-bolder pr-2 text-left" scope="col">Preço de Fechamento</th>
    <td class="p-2 fs-5 text-right">50,00</td>
    <td class="p-2 fs-5 text-right">51,25</td>
    <td class="p-2 fs-5 text-right">52,50</td>
    <td class="p-2 fs-5 text-right">53,75</td>
    <td class="p-2 fs-5 text-right">54,00</td>
    <td class="p-2 fs-5 text-right">55,25</td>
    <td class="p-2 fs-5 text-right">54,50</td>
    <td class="p-2 fs-5 text-right">53,75</td>
    <td class="p-2 fs-5 text-right">52,25</td>
    <td class="p-2 fs-5 text-right">51,00</td>
  </tr>
  <tr>
    <th class="p-2 fs-5 fw-bolder text-left" scope="col">MA 3</th>
    <td class="p-2 fs-5 text-right"></td>
    <td class="p-2 fs-5 text-right"></td>
    <td class="p-2 fs-5 text-right">51,25</td>
    <td class="p-2 fs-5 text-right">52,50</td>
    <td class="p-2 fs-5 text-right">53,42</td>
    <td class="p-2 fs-5 text-right">54,33</td>
    <td class="p-2 fs-5 text-right">54,58</td>
    <td class="p-2 fs-5 text-right">54,50</td>
    <td class="p-2 fs-5 text-right">53,50</td>
    <td class="p-2 fs-5 text-right">52,33</td>
  </tr>
</table>

<div class="fw-bolder">Como funciona</div>

<div class="mb-3">
  A Média Móvel é uma espécie de rastreador de tendências, uma ferramenta
  fundamental para promover a análise técnica dos ativos que você pretende
  comprar ou vender. O objetivo é apontar o preço médio de um ativo, em um
  determinado período, além da sua mudança neste tempo observado.
</div>

<div class="mb-3">
  Se você definir um período de 30 dias, a MA (Média Móvel) mostrará o preço
  médio do ativo nestes 30 dias.
</div>

<div class="mb-3">
  Os períodos utilizados podem variar, dependendo do tipo de análise técnica que
  está sendo feita. Um fato é que as médias móveis têm um atraso inerente a
  elas. O que isso significa é realmente é, quanto maior o período de tempo
  usado, maior o atraso. Da mesma forma, quanto menor o prazo, menor será o
  atraso. As médias móveis com prazos mais curtos tendem a permanecer próximas
  aos preços e se moverão logo após a movimentação dos preços.
</div>

<div class="mb-3">
  Quanto a quais períodos de tempo devem ser usados, isso realmente depende do
  critério do analista. Normalmente, qualquer período inferior a 20 dias seria
  considerado de curto prazo, qualquer coisa entre 20 e 60 seria de médio prazo
  e, é claro, qualquer coisa superior a 60 dias seria vista como de longo prazo.
</div>

<div class="mb-3">
  &bull; Curto Prazo: de 10 a 20
  <br />
  &bull; Médio Prazo: de 20 a 50
  <br />
  &bull; Longo Prazo: acima de 50
</div>

<div class="mb-3">
  <img
    class="w-75"
    src="/assets/indicators/moving-averange-como-funciona.png"
  />
</div>

<div class="mb-3">
  A média móvel é muito popular entre traders e investidores porque ilustram de
  uma forma bem fácil e simples as tendências de longo prazo de um determinado
  papel.
</div>

<div class="mb-3">As regras gerais são as seguintes:</div>

<div class="mb-3">
  &bull; Uma média móvel de longo prazo que sobe reflete uma tendência de alta
  de longo prazo.
  <br />
  &bull; Enquanto a média móvel de longo prazo que cai reflete uma tendência de
  baixa de longo prazo.
</div>

<div class="text-decoration-underline">Cruzamentos</div>

<div class="mb-3">
  Os cruzamentos requerem o uso de duas médias móveis de período variável no
  mesmo gráfico.
</div>
<div class="mb-3">
  As duas médias móveis devem ter dois períodos diferentes. Por exemplo, uma
  média móvel simples de 50 dias (médio prazo) e uma média móvel simples de 200
  dias (longo prazo). As possíveis oportunidades de negociação ocorrem quando a
  Média Móvel de curto prazo cruza acima ou abaixo da Média Móvel de longo
  prazo.
</div>
<div class="mb-3">
  Alta - Ocorre quando a MA de curto prazo cruza acima da MA de longo prazo.
</div>
<div class="mb-3">
  Baixa - Ocorre quando a MA de curto prazo cruza abaixo da MA de longo prazo.
</div>

<div class="mb-3">
  No entanto é obrigatório que o trader perceba as deficiências que possam ter
  nesses sinais. Este é um sistema criado combinando não apenas um, mas dois
  indicadores de atraso. Ambos os indicadores reagem apenas ao que já aconteceu
  e não foram projetados para fazer previsões.
</div>

<div class="fw-bolder">Como configurar</div>

<div class="fw-bolder">Valores</div>

<div class="text-decoration-underline">Período</div>

<div class="mb-3">
  O período a ser usado no cálculo da média móvel. 9 dias é o padrão.
</div>

<div class="text-decoration-underline">Fonte</div>

<div class="mb-3">
  Determina quais dados de cada barra serão usados nos cálculos. Fechamento é o
  padrão.
</div>

<div class="text-decoration-underline">Deslocamento</div>

<div class="mb-3">
  A alteração desse número moverá a média móvel para frente ou para trás em
  relação ao mercado atual. 0 é o padrão.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/moving-averange-entrada.png"
  />
</div>

<div class="fw-bolder">Estilo</div>

<div class="mb-3">
  Pode alternar a visibilidade da MA, bem como a visibilidade de uma linha de
  preço que mostra o valor atual real da MA. Também pode selecionar a cor, a
  espessura e o estilo da linha do MA.
</div>
<div class="mb-3">
  <img
    class="image-indicators-input"
    src="/assets/indicators/moving-averange-estilo.png"
  />
</div>
`;
