export enum INFO_USER_OPTIONS_ENUM {
  USER_INFO = 'USER_INFO',
  MANAGE_ACCOUNT = 'MANAGE_ACCOUNT',
  TOKEN = 'TOKEN',
  SPACE_1 = 'SPACE_1',
  MENU_VISIBILITY = 'MENU_VISIBILITY',
  SHORTCUTS = 'SHORTCUTS',
  CONFIGS = 'CONFIGS',
  CONNECTIONS = 'CONNECTIONS',
  SPACE_2 = 'SPACE_2',
  RATING = 'RATING',
  THEME = 'THEME',
  SPACE_3 = 'SPACE_3',
  DOWNLOADPKG = 'DOWNLOADPKG',
  SPACE_4 = 'SPACE_4',
  LOGOUT = 'LOGOUT',
}

export enum HEADER_BUTTONS_ENUM {
  SEARCH = 'SEARCH',
  AUCTION = 'AUCTION',
  FULL_SCREEN = 'FULL_SCREEN',
  LOCK = 'LOCK',
  WHATSAPP = 'WHATSAPP',
  SYNC_LOCK = 'SYNC_LOCK',
}
