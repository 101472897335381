import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, delay, map, Observable, of } from 'rxjs';
import { RestService } from '@shared/services/rest/rest.service';
import {
  IAllStockListSimple,
  IStockListDyanmicPayload,
  IMoversStockListDynamic,
  IStockListDynamic,
} from 'src/app/core/interface';
import { IMoversData } from '@shared/components/stock-table/interfaces/stock-table.interfaces';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import {
  getVolumeText,
  lowerCaseAndNormalizeString,
} from 'src/app/utils/utils.functions';
import { formatterNumber } from '@shared/rocket-components/utils';
import { Dictionary } from '@core/models';
import { MOVERS_TYPE } from '@shared/components/stock-table/constants/stock-table.constants';

@Injectable({
  providedIn: 'root',
})
export class StockListService extends RestService {
  override _url: string = 'api/stock-list/v10';
  private _moversByPeriodCache = new Dictionary<IMoversData[]>();
  private _dynamicLists = new Dictionary<IStockListDynamic[]>();

  private readonly DYNAMIC_LISTS_STOCKLIST: any = {
    acoes: 'STOCKS_ALL',
    fiis: 'FIIS_ALL',
    indices: 'INDICES',
    etfs: 'ETF',
    bdrs: 'BDR',
    commodities: 'COMMODITIES',
    moedas: 'MOEDAS',
    futuros: 'FUTUROS',
    bonds: 'BONDS',
    juros: 'JUROS',
  };

  private readonly DYNAMIC_LIST_GROUP: any = {
    acoes: 'Ações',
    fiis: 'FIIs',
    indices: 'Ativos',
    etfs: 'Ativos',
    bdrs: 'Globais',
    commodities: 'Populares',
    moedas: 'Populares',
    futuros: 'Populares',
    bonds: 'Populares',
    juros: 'Populares',
  };

  constructor(http: HttpClient) {
    super(http);
  }

  public getAllStockListSimple(): Observable<Array<IAllStockListSimple>> {
    return this.post<Array<IAllStockListSimple>>(
      'get-all-stock-list-simple',
      {}
    ).pipe(map((res) => this.filterListStock(res.data)));
  }

  private filterListStock(data: any): Array<IAllStockListSimple> {
    if (data && data.simple) {
      return data.simple;
    }
    return [];
  }

  public getStockListByGroup(group: string): Observable<IStockListDynamic[]> {
    const param = lowerCaseAndNormalizeString(group);
    if (this._dynamicLists.has(param))
      return of(this._dynamicLists.get(param)!).pipe(delay(25));
    return this.post<IStockListDynamic[]>('get-stock-list-items-dynamic', {
      group: this.DYNAMIC_LIST_GROUP[param],
      cd_stocklist_dynamic: this.DYNAMIC_LISTS_STOCKLIST[param],
      type_filter: { limit: 30, offset: 0 },
    }).pipe(
      map((response) => {
        if (!response || !response.data) throw new Error();
        if (!response.data.length) return [];
        const stocks = this._buildDynamicListStocks(response.data);
        this._dynamicLists.set(group, stocks);
        return stocks;
      }),
      catchError(() => of([]))
    );
  }

  private _buildDynamicListStocks(stocks: IStockListDynamic[]) {
    return stocks.map((stock: IStockListDynamic) => {
      const cdStock = stock.is_synonymous
        ? stock.synonymous_nickname
        : stock.cd_stock;
      stock.cd_stock_order = stock.cd_stock;
      stock.cd_stock = cdStock;
      stock.variacao_dia = 0;
      stock.nm_company = stock.nm_company ? stock.nm_company : cdStock;
      stock.idRow = `${cdStock}:${stock.id_exchange}`;
      stock.cd_segment = stock.cd_segment.replace(/\D/g, '');
      stock.isIndex = stock.cd_segment === '90';
      return stock;
    });
  }

  public createStockList(
    nameStockList: string,
    stocks: Array<any>
  ): Observable<number | null> {
    const params = {
      nm_stock_list: nameStockList,
      in_visibility: false,
      stocks: stocks,
      id_stock_list_report_static: 2,
      id_stock_list_report_dynamic: 0,
      is_list_simple: true,
    };
    return this.post<any>('create-stock-list', params).pipe(
      map((res) => res?.data?.id_stock_list)
    );
  }

  public updateStockList(
    list: IAllStockListSimple,
    stocks: Array<any>
  ): Observable<any> {
    const params = {
      cd_emoji: null,
      id_stock_list: list.id_stock_list,
      id_stock_list_report_dynamic: 0,
      id_stock_list_report_static: 2,
      in_visibility: list.in_visibility,
      is_favorite: list.is_favorite,
      nm_stock_list: list.nm_stock_list,
      stocks: stocks,
    };
    return this.post<any>('update-stock-list', params).pipe(
      catchError((err) => {
        return of(err);
      }),
      map((res) => {
        return res;
      })
    );
  }

  public deleteStockList(idList: number): Observable<boolean | null> {
    return this.post<any>('delete-stock-list', { id_stock_list: idList }).pipe(
      map((res) => res?.data?.is_success)
    );
  }

  public getStockListDynamic(
    period: string,
    moversId: number,
    moversIndex: string,
    globalStock: string
  ): Observable<IMoversData[]> {
    const type: any = `MOVERS_${MOVERS_TYPE[moversId]}${
      moversId <= 1000000002 ? 'S' : ''
    }`;
    const cacheKey = `${period}_${type}_${moversIndex}`;
    if (this._moversByPeriodCache.has(cacheKey))
      return of(this._moversByPeriodCache.get(cacheKey)!).pipe(delay(25));
    const params = this._buildStockListDynamicPayload(
      period,
      type,
      moversIndex
    );
    return this.post('get-stock-list-items-dynamic', { ...params }).pipe(
      catchError((err) => this.handlerServiceError(err)),
      map((res: any) => {
        if (!res || !res?.data) throw res;
        const movers = this._buildDynamicMoversFields(
          res.data,
          type,
          globalStock,
          period
        );
        this._moversByPeriodCache.set(cacheKey, movers);
        return movers;
      })
    );
  }

  private _buildStockListDynamicPayload(
    period: string,
    type: 'MOVERS_ALTAS' | 'MOVERS_BAIXAS' | 'MOVERS_VOLUME',
    moversIndex: string
  ): IStockListDyanmicPayload {
    return {
      cd_stocklist_dynamic: type,
      group: 'Movers',
      type_filter: {
        movers_id_exchange: 1,
        movers_cd_index: moversIndex,
        movers_period: period,
        movers_limit: 20,
        limit: 20,
        offset: 0,
        movers_order_by: 'desc',
        movers_market_type: 'acoes',
        movers_call: 'MAIORES_ALTAS',
      },
    };
  }

  private _buildDynamicMoversFields(
    movers: IMoversStockListDynamic[],
    type: 'MOVERS_ALTAS' | 'MOVERS_BAIXAS' | 'MOVERS_VOLUME',
    globalStock: string,
    period: string
  ): IMoversData[] {
    return movers.map((stock) => {
      const idRow = `${stock.cd_stock}:${stock.id_exchange}`;
      const positiveVariation = stock.variation > 0;
      return {
        idRow: idRow,
        key: idRow,
        cd_stock: stock.cd_stock,
        item: type,
        command: 'ADD',
        preco_ultimo: stock.vl_average_price.toString(),
        volume: '0',
        arrow: '',
        arrow_hex: positiveVariation
          ? CHART_COLORS.FEEDBACK_SUCCESS
          : CHART_COLORS.FEEDBACK_ERROR,
        arrow_font_hex: positiveVariation
          ? CHART_COLORS.BLACK
          : CHART_COLORS.NEUTRAL_SMOOTHEST,
        isSelected: idRow === globalStock,
        variacao: stock.variation.toString(),
        custom_variation: stock.variation.toString(),
        variacao_dia: stock.variation.toString(),
        period_variation: this._formatVariation(stock.variation),
        tick_size_denominator: stock.tick_size.toString(),
        vl_close_history: [],
        iosma_key: '',
        vl_close: stock.vl_close.toString(),
        situacao: 'ATIVO',
        ds_asset: stock.ds_segment,
        period,
        company_logo: stock.company_logo,
      };
    });
  }

  private _formatVariation(variation: number): string {
    if (!variation || variation === 0) return '0';
    const useVolume = Math.round(variation).toString().length > 3;
    if (useVolume)
      return getVolumeText('pt-BR', variation, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    return formatterNumber(variation);
  }
}
