<workspace-doc-header
  css="w-100 header-wrapper d-flex justify-content-between nav flex-nowrap border-bottom"
  [fullscreenEnabled]="false"
  [item]="component"
>
  <fla-workspace-doc-header-item
    [enableContent]="true"
    *ngFor="let newsGroup of newsGroups; let i = index"
  >
    <fla-workspace-doc-header-item
      margin="mx-2"
      [hideBorder]="true"
      [name]="newsGroup.nm_news_group"
      [active]="selectedTab === newsGroup.id_news_group"
      (flaClick)="changeTab(newsGroup.id_news_group)"
    >
    </fla-workspace-doc-header-item>
  </fla-workspace-doc-header-item>
</workspace-doc-header>
