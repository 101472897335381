<rocket-modal>
  <rocket-modal-header>
    <div>
      <h3 class="modal-title">Meus Dados</h3>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div id="modal-meus-dados-info">
      <div class="row">
        <ng-container *ngFor="let item of investorKeys">
          <div
            id="meus-dados-fields"
            class="p-0"
            [ngStyle]="{ width: item.width }"
          >
            <app-data-item
              [field]="item.label"
              [value]="profileData[item.key]"
              [displayButton]="item.displayButton"
              [buttonLabel]="item.buttonLabel"
              (handleClick)="handleFieldButtonClick(item.key)"
            ></app-data-item>
          </div>
        </ng-container>
      </div>
      <input #telHiddenInput type="text" hidden readonly />
      <div id="cancel-button-div" class="d-flex justify-content-end mb-4">
        <rocket-button
          type="fill"
          label="Cancelar minha conta"
          (rocketClick)="cancelAccount()"
        ></rocket-button>
      </div>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
