import { RocketIModal } from '@shared/rocket-components/components/modal/interfaces/modal.interface';
import { RocketTModalSize } from '@shared/rocket-components/components/modal/types/modal.types';

export class RocketModalConfig {
  constructor(element: HTMLElement, init?: Partial<RocketIModal>) {
    this.element = element;
    if (init) {
      this.default = init;
    }
    this.setConfig();
  }

  private element: HTMLElement | undefined = undefined;
  private modalDialog: HTMLElement | undefined = undefined;
  private defaultConfig: RocketIModal = {
    size: '',
    hideCloseButton: false,
    centered: true,
    backdrop: 'static',
    keyboard: true,
    scrollable: false,
    css: '',
    appendTo: document.body,
    lockScreen: true
  };

  private set default(value: RocketIModal) {
    this.defaultConfig = Object.assign(this.defaultConfig, value);
  }

  get modalConfig(): RocketIModal {
    return this.defaultConfig;
  }

  private setConfig(): void {
    this.modalDialog = this.element!.firstChild as HTMLElement;
    this.size = this.modalConfig.size as RocketTModalSize;
    this.center = this.defaultConfig.centered as boolean;
    this.scrollable = this.modalConfig.scrollable as boolean;
    this.css = this.modalConfig.css as string;
  }

  private set size(value: RocketTModalSize) {
    if (this.modalDialog) {
      this.modalDialog.classList.add(`modal-${value}`);
    }
  }

  private set center(value: boolean) {
    if (value && this.modalDialog) {
      this.modalDialog.classList.add('modal-dialog-centered');
    }
  }

  private set scrollable(value: boolean) {
    if (value && this.modalDialog) {
      this.modalDialog.classList.add('modal-dialog-scrollable');
    }
  }

  private set css(value: string) {
    if (value && this.modalDialog) {
      this.modalDialog.classList.add(value);
    }
  }
}
