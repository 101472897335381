<ng-template>
  <div class="card" [ngClass]="customClass">
    <div class="hstack">
      <img [src]="src" class="round-md mr-2" width="44" height="44" />
      <div class="mr-auto">
        <p class="card-title">{{ stock }}</p>
        <small class="card-subtitle">{{ qtdPrice }}</small>
      </div>
      <p class="card-title">
        Valor <br />
        {{ price }}
      </p>
    </div>
    <div class="card-footer d-flex">
      <small class="mr-auto">{{ description }}</small>
      <small [ngClass]="classStatus">{{ status }}</small>
    </div>
  </div>
</ng-template>
