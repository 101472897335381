<app-dropdown [css]="'h-100 ' + css">
  <a
    type="button"
    class="nav-link p-0 lh-sm round-top h-100"
    appDropdownButton
    placement="bottom"
    (isOpenClose)="isOpenClose.emit($event)"
  >
    <div class="d-flex btn-fill-color btn-gap-2">
      <button
        id="appDropdownButtonTools"
        type="button"
        class="ml-n1 dropdown d-flex flex-column align-items-center justify-content-center btn btn-fill-primary btn-remove-min-width w-25 p-0"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      ></button>
    </div>
  </a>
  <div class="contents">
    <div
      class="dropdown-item d-flex flex-column gap-3 px-5 py-3 cursor-default"
    >
      <span>Selecione a cor desejada</span>
      <div class="grid-colors justify-items-center">
        <div
          class="d-flex justify-content-center align-items-center w-100"
          *ngFor="let staticColor of staticColors"
          [ngClass]="{ selected: color === staticColor }"
          (click)="selectColor(staticColor)"
        >
          <span
            class="round-pill d-block"
            [style.background]="staticColor"
          ></span>
        </div>
      </div>
    </div>
  </div>
</app-dropdown>
