import { FormGroup } from '@angular/forms';
import {
  ORDER_CONFIRM_ACTIONS_ENUM,
  TYPE_ORDE_SIDE_ENUM,
} from '@shared/enum/buyOrSell.enum';
import { QuoteChannel } from '@shared/channel/quote.channel';
import {
  bigValueFormatter,
  execFormatFinancial,
  formattedStockForFractional,
  formatterNumber,
  getAuctionInfos,
  getOrdinaryTicker,
  isNullOrUndefined,
  orderCheckIsFractionalStock,
  processOrdersFilterQtty,
  toValue,
} from 'src/app/utils/utils.functions';
import { RocketCreateComponentService } from '@shared/rocket-components/services';
import { OrderChannel } from '@shared/channel/order.channel';
import {
  BehaviorSubject,
  delay,
  filter,
  Subject,
  Subscription,
  takeUntil,
  tap,
} from 'rxjs';
import { TYPE_ORDE_ENUM } from '../stock-trade/enum/stock-trade.enum';
import { CustodyChannel } from '@shared/channel/custody.channel';
import {
  IAccountSelect,
  IOrders,
  IPosition,
  ISearchStock,
  IWorkSpaceComponet,
} from 'src/app/core/interface';
import {
  SUPER_DOM_POSITION_INFOS,
  SUPER_DOM_TOOLTIPS,
} from './constants/super-dom.constants';
import { IntrojsService } from '@core/introjs/introjs.service';
import {
  ChangeDetectorRef,
  WritableSignal,
  computed,
  signal,
} from '@angular/core';
import { SuperDomService } from './service/super-dom.service';
import { deepClone } from '@shared/rocket-components/utils';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import { system } from '@core/system/system.service';
import { IStockAuctionInfos } from '@shared/interfaces/stock-auction-infos.interface';
import {
  isStockFractional,
  isStockFuture,
  isStockCashMarket,
} from '@shared/constants/general.contant';
import { OrdersService } from '@shared/services/orders.service';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { ActivatedRoute } from '@angular/router';
import { RocketStreamRead } from '@shared/channel/rx-event';
import { SuperDomContextMenuComponent } from './parts/context-menu/super-dom-context-menu.component';
import { ContextMenuService } from '../popup-root/context-menu.service';

export abstract class SuperDomHelper extends RocketComponentBase {
  form!: FormGroup;
  isFocus: boolean = false;
  isCrypto: boolean = false;
  sideOrders = TYPE_ORDE_SIDE_ENUM;
  typeOrder = TYPE_ORDE_ENUM;
  stock!: any;
  account!: IAccountSelect;
  position = signal<IPosition | undefined>(undefined);
  orders: IOrders[] = [];
  ordersOpen: IOrders[] = [];
  qttyOpen: number = 0;
  ordersOpenInDayTradeMode: number = 0;
  protected refComp!: string;
  standardLot: number = 0;
  tickSizeDenominator: number = 0;
  priceMask: string = 'separator.2';
  tickSizeVolume: number = 0;
  protected idBrokerConsolidated = -99;
  protected componentId!: string;
  protected untilDestroy$ = new Subscription();
  protected destroy$: Subject<void> = new Subject<void>();
  protected _tourSubscription!: Subscription;
  protected _handleComponentTour$ = new Subject<{ refComponent: string }>();
  priceWithoutFormatting: number = 0;
  price: string = '0';
  arrowHex: string = '#fff';
  volume: string = '0';
  vol: number | string = 0;
  variation: string = '0';
  variacao_dia: number | string = 0;
  minimum: string = '-';
  maximum: string = '-';
  minPriceIncrement: string = '1';
  showDetail: boolean = true;
  showDetailCustody: boolean = true;
  showVolume: boolean = true;
  isEditOrder: boolean = false;
  showPowerBar: boolean = true;
  hasQuoteSubscription: boolean = false;
  private _previusPreferences!: any;
  startedComponentTour: boolean = false;
  showAtPrice!: boolean;
  showColumnBook: boolean = false;
  isLineBlock: boolean = false;
  hideAggressor: boolean = false;
  showPosition: boolean = false;
  hasQttdPosition: string = '';
  usingDayTradeMode: boolean = false;
  positionInfos = SUPER_DOM_POSITION_INFOS;
  isFirstTimeLastPrice: boolean = true;
  invertOrReserPostionEnum = ORDER_CONFIRM_ACTIONS_ENUM;
  invertAndResetTooltips: { invert: string; reset: string } = {
    invert: DaytradeService.getTextWithTradeMode(
      SUPER_DOM_TOOLTIPS[`INVERT_DAYTRADE_ON`]
    ),
    reset: DaytradeService.getTextWithTradeMode(
      SUPER_DOM_TOOLTIPS[`RESET_DAYTRADE_ON`]
    ),
  };
  vlPriceAvgSig = signal('');
  qtdSig = signal(0);
  priceSig = signal(0);
  qttyOpenDayTradeMode = signal(0);
  minimumWithoutFormatting!: any;
  maximumWithoutFormatting!: any;
  isTour: boolean = false;
  positions = new Map<string, IPosition>();
  stockAuctionInfos!: IStockAuctionInfos;
  incrementValue: number = 1;
  private configUpdate$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  protected detectChangesSubject = new Subject<void>();
  protected stockFormatedPosition: string = '';
  protected closeStreamQoute!: () => void;
  protected closeStreamCustody!: () => void;
  protected custodySnapshot!: (items: any) => void;
  protected _component: WritableSignal<IWorkSpaceComponet | undefined> =
    signal(undefined);

  get itemSubscribed() {
    return `${this.stock?.cd_stock}:${this.stock?.id_exchange}`;
  }

  total = computed(() => {
    return this.formatCurrenTPrice(this.qtdSig(), this.priceSig());
  });

  totalFormatted = computed(() => {
    const price = this.formatCurrenTPrice(this.qtdSig(), this.priceSig());
    const priceParce = parseInt(price.replace(/[^0-9,]/g, ''));
    return priceParce.toString().length > 3
      ? bigValueFormatter(priceParce)
      : price;
  });

  vlPriceAvg = computed(() => {
    const field = this.vlPriceAvgSig();
    return this.formatPrice(
      (this.position()?.[field as keyof IPosition] as string) ?? '0'
    );
  });

  get positionKey() {
    return `${formattedStockForFractional(this.stock)}.${system.idInvestor}.${
      this.account?.id_broker
    }`;
  }
  protected quoteCurrentSubscription!: RocketStreamRead;
  protected custodyCurrentSubscription!: RocketStreamRead;

  constructor(
    protected quoteChannel: QuoteChannel,
    protected createComponent: RocketCreateComponentService,
    protected orderChannel: OrderChannel,
    protected custodyChannel: CustodyChannel,
    protected introJsService: IntrojsService,
    protected cdr: ChangeDetectorRef,
    protected superDomService: SuperDomService,
    protected ordersService: OrdersService,
    activatedRoute: ActivatedRoute,
    protected contextMenuService: ContextMenuService
  ) {
    super(activatedRoute);
    this.initializeReadStreams();
    this._handleComponentTour$
      .pipe(
        takeUntil(this.destroy$),
        filter((data) => data.refComponent === this.refComp),
        delay(20),
        tap(() => {
          this.forceDisplayContextMenu();
        }),
        delay(100)
      )
      .subscribe(() => {
        this.introJsService.superDomIntro(
          this.componentId,
          this.forceDisplayContextMenu,
          this.lockTable
        );
      });
  }

  private lockTable = (isPre: boolean) => {
    if (isPre) {
      this.isLineBlock = true;
    } else {
      this.isLineBlock = this._previusPreferences.isLineBlock;
    }
    this.cdr.detectChanges();
  };

  private async initializeReadStreams() {
    this.quoteCurrentSubscription = await this.quoteChannel.readEvents();
    this.readStream(this.quoteCurrentSubscription.stream, this.channelHandler);
    this.quoteCurrentSubscription.snapshot([this.itemSubscribed]);

    this.custodyCurrentSubscription = await this.custodyChannel.readEvents();
    this.readStream(
      this.custodyCurrentSubscription.stream,
      this.custodyHandler
    );
    this.custodyCurrentSubscription.snapshot(this.custodyChannel.itemsArray);
  }

  custodyHandler = (payload: any) => {
    const custody = payload.get(this.custodyChannel.itemsArray[0]);
    if (!custody) return;
    custody.forEach((data: any) => {
      if (data.key === 'EOS' || data.isEndOfSnap) return;
      if (data.command === 'DELETE') {
        this.positions.delete(data.key);
        this.positionKey === data.key && this.checkHasPositionByMode(null);
      } else {
        this.positions.set(data.key, data);
        if (!this.positions.has(this.positionKey)) return;
        const positionData: IPosition = this.positions.get(
          this.positionKey
        ) as IPosition;
        positionData && this.position.set(positionData);
        this.checkHasPositionByMode(positionData);
      }
    });

    this.detectChangesSubject.next();
  };
  protected addOrUpdateOrder = (order: IOrders | any) => {
    if (order?.isSnap) {
      const orders = order.values.filter(this.orderChannelFilter);
      orders.forEach((order: any) => {
        this.addOrUpdateOrder(order);
      });
      return;
    }
    const auxOrders = deepClone(this.orders);
    const index = auxOrders.findIndex(
      (item: IOrders) => item.id_order == order.id_order
    );
    index > -1 && (auxOrders[index] = order);
    index < 0 && auxOrders.push(order);
    this.filterOrders(auxOrders);
  };

  protected filterOrders(orders: any[]): void {
    const res = processOrdersFilterQtty(orders);
    this.orders = res.data;
    this.qttyOpen = res.qttyOpen;
    this.ordersOpen = res.data;
    this.ordersOpenInDayTradeMode = res.dayTradeQttyOpen;
    const qty = this.usingDayTradeMode
      ? +this.ordersOpenInDayTradeMode
      : +this.qttyOpen;
    this.qttyOpenDayTradeMode.set(qty);
    this.detectChangesSubject.next();
  }

  protected destroyObservable(): void {
    if (this.untilDestroy$) {
      this.untilDestroy$.unsubscribe();
      this.untilDestroy$ = new Subscription();
    }
  }

  private orderChannelFilter = (order: IOrders | any) => {
    if (order?.isSnap || order?.isReconnect) return true;
    const isSameBroker = this.account?.id_broker === order?.id_broker;
    const isSameStock = orderCheckIsFractionalStock(
      this.stock,
      order?.cd_stock
    );
    const isDayTrade = this.usingDayTradeMode === order?.is_day_trade;
    return !!order && isSameBroker && isSameStock && isDayTrade;
  };

  protected subscribeOrderChannel() {
    this.untilDestroy$.add(
      this.ordersService
        .getOrdersStream()
        .pipe(takeUntil(this.destroy$), filter(this.orderChannelFilter))
        .subscribe(this.addOrUpdateOrder)
    );
    this.ordersService.getOrdersCached();
  }
  setQttyOpenDayTradeMode(positionData: any) {
    let qttyOpen = 0;
    if (positionData) {
      qttyOpen = this.usingDayTradeMode
        ? positionData?.net_day_daytrade
        : positionData?.net_day_swing;
    }
    this.qttyOpenDayTradeMode.set(qttyOpen);
  }

  protected subscribeCustodyChannel() {
    this.custodyCurrentSubscription &&
      this.custodyCurrentSubscription.snapshot(this.custodyChannel.itemsArray);
  }

  //************ METODOS DO CHEETAH */
  protected subscribeCheetah() {
    const items = [this.itemSubscribed];
    const qouteParams = {
      itemsArray: items,
      header: this.componentId,
    };
    this.quoteChannel.subscribe(qouteParams);
    this.hasQuoteSubscription = true;
    this.quoteCurrentSubscription &&
      this.quoteCurrentSubscription.snapshot([this.itemSubscribed]);
  }

  protected unSubscribeCheetah() {
    const items = [this.itemSubscribed];
    const qouteParams = {
      itemsArray: items,
      header: this.componentId,
    };
    this.quoteChannel.unsubscribe(qouteParams);
    this.hasQuoteSubscription = false;
  }

  channelHandler = (payload: any) => {
    const data = payload.get(this.itemSubscribed);
    if (!data) return;

    const clearedData = this.clearCheetahData(data);

    const {
      preco_ultimo,
      variacao_dia,
      arrow_hex,
      volume,
      preco_minimo,
      preco_maximo,
      tick_size_denominator,
      min_price_increment,
    } = clearedData;

    if (preco_ultimo) {
      this.priceWithoutFormatting = +preco_ultimo;
      const control = this.form.controls['price'];
      const formPriceValue = control.value;
      if (!formPriceValue && this.isFirstTimeLastPrice) {
        control.setValue(this.priceWithoutFormatting);
        this.isFirstTimeLastPrice = false;
      }
      this.price = this.formatPrice(preco_ultimo);
    }

    if (variacao_dia) {
      this.variation = formatterNumber(+variacao_dia);
      this.variacao_dia = variacao_dia;
    }

    if (volume) {
      this.volume = bigValueFormatter(volume);
      this.vol = volume;
    }

    if (preco_minimo) {
      this.minimumWithoutFormatting = +preco_minimo;
      this.minimum = this.formatPrice(preco_minimo);
    }

    if (preco_maximo) {
      this.maximumWithoutFormatting = +preco_maximo;
      this.maximum = this.formatPrice(preco_maximo);
    }

    arrow_hex && (this.arrowHex = arrow_hex);
    if (tick_size_denominator) {
      this.tickSizeVolume = tick_size_denominator;
    }
    if (min_price_increment) {
      this.minPriceIncrement = min_price_increment;
    }
    this.stockAuctionInfos = getAuctionInfos(
      clearedData,
      this.stockAuctionInfos
    );
    this.detectChangesSubject && this.detectChangesSubject.next();
  };

  private formatPriceTotal(value: string): string {
    let price = +value;
    if (isStockFuture(this.stock?.cd_security_type)) {
      price = +value * this.stock?.vl_contract_multiplier;
    }
    return formatterNumber(price);
  }

  protected formatPrice(value: string): string {
    const data = {
      id_exchange: this.stock?.id_exchange,
      tick_size_denominator: this.stock?.tick_size_denominator,
    };
    return execFormatFinancial(data, value);
  }
  //************ FIM METODOS DO CHEETAH */

  showConfig = (event: any): boolean => {
    SuperDomContextMenuComponent.openContextMenu(
      this.contextMenuService,
      this._component()!!.id,
      { clientX: event.clientX, clientY: event.clientY },
      this.isTour,
      this.showDetail,
      this.showDetailCustody,
      this.showPowerBar,
      this.showVolume,
      this.hideAggressor,
      this.showAtPrice,
      this.showColumnBook,
      this.showPosition,
      event?.isTour ?? false
    );
    return false;
  };

  onConfigUpdate = () =>
    this.configUpdate$.asObservable().pipe(
      takeUntil(this.destroy$),
      filter((data) => data)
    );

  refreshPrice(): void {
    this.form.get('price')?.setValue(this.priceWithoutFormatting);
  }

  updatePrice(type: string) {
    const formControl = this.form.get('price');
    if (formControl) {
      const value = parseFloat(this.minPriceIncrement) || 1;
      if (type === 'PLUS') {
        const plus = parseFloat(formControl.value) + value;
        formControl.patchValue(plus.toFixed(2));
        return;
      }
      const minus = formControl.value - value;
      formControl.patchValue(minus < 0 ? 0 : minus.toFixed(2));
    }
  }

  private formatCurrenTPrice(qtd: number, price: number): string {
    let total = '0,00';
    if (this.form && this.stock) {
      total = this.formatPriceTotal(`${qtd * price}`);
    }
    return total;
  }

  handleComponentTour = () => {
    this._displayDefaultFeaturesAndSavePreviusPreferences();
    this._handleComponentTour$.next({ refComponent: this.refComp });
    this._tourSubscription = this.introJsService
      .onStart()
      .pipe(
        takeUntil(this.destroy$),
        filter((res) => res)
      )
      .subscribe(
        (res: { action: 'DISPLAY_CONTEXT' | 'HIDE_CONTEXT' | 'CLOSED' }) => {
          const hash: any = { DISPLAY_CONTEXT: true, HIDE_CONTEXT: true };
          if (hash[res.action]) {
            this.handleContextVisibilityOnTour(
              res.action === 'DISPLAY_CONTEXT'
            );
          }
          res.action == 'CLOSED' && this._closeContext();
        }
      );
  };

  private _displayDefaultFeaturesAndSavePreviusPreferences(): void {
    this.startedComponentTour = true;
    this._previusPreferences = Object.assign({
      isLineBlock: this.isLineBlock,
      showDetail: this.showDetail,
      showPowerBar: this.showPowerBar,
      showAtPrice: this.showAtPrice,
      showColumnBook: this.showColumnBook,
    });
    this.isLineBlock = true;
    this.showDetail = true;
    this.showPowerBar = true;
    this.detectChangesSubject.next();
  }

  forceDisplayContextMenu = (isVisible: boolean = true) => {
    if (isVisible) return;
    const componentPositions = document
      .getElementById(this.componentId)
      ?.getBoundingClientRect();

    this.showConfig({
      view: {
        innerHeight: document.body.clientHeight,
        innerWidth: document.body.clientWidth,
      },
      clientY: componentPositions!.y + 100,
      clientX: componentPositions!.x + 20,
      isTour: true,
    });
    setTimeout(() => {
      this.handleContextVisibilityOnTour(isVisible);
    }, 50);
  };

  private handleContextVisibilityOnTour = (displayContext: boolean): void => {
    const doc = document.querySelectorAll<HTMLElement>(
      'app-super-dom-context-menu > div'
    );
    if (!doc || !doc.length) return;
    doc[0].style.zIndex = displayContext ? '99' : '-1';
  };

  private _closeContext(): void {
    const findAll = document.querySelectorAll('app-super-dom-context-menu');
    findAll && findAll.length && findAll.forEach((elem) => elem.remove());
    this._tourSubscription && this._tourSubscription.unsubscribe();
    this._resetToPreviusPreferences();
  }

  private _resetToPreviusPreferences(): void {
    this.isLineBlock = this._previusPreferences.isLineBlock;
    this.showDetail = this._previusPreferences.showDetail;
    this.showPowerBar = this._previusPreferences.showPowerBar;
    this.showAtPrice = this._previusPreferences.showAtPrice;
    this.showColumnBook = this._previusPreferences.showColumnBook;
    this.startedComponentTour = false;
    this.detectChangesSubject.next();
  }

  private getOpenOrdersByAccountAndStock(stock: ISearchStock) {
    const cd_stock = this.getCdStockParam(stock);
    const orders = this.ordersService.filterOrders(
      'filterStockIdBrokerIsDayTrade',
      `${cd_stock}_${this.usingDayTradeMode}`
    );
    return orders;
  }

  private getCdStockParam(stock: ISearchStock): string {
    return stock.cd_crypto ? stock.cd_crypto : stock.cd_stock_order;
  }

  protected processOrders(stock: any) {
    let orders = this.getOpenOrdersByAccountAndStock(stock) || [];
    if (
      isStockFractional(stock.cd_security_type) ||
      isStockCashMarket(stock.cd_security_type)
    ) {
      const defaultMarketCdStock = getOrdinaryTicker(stock);
      const defaultMarketOrders = this.getOpenOrdersByAccountAndStock({
        ...stock,
        cd_stock_order: defaultMarketCdStock,
      });
      orders = defaultMarketOrders
        ? orders.concat(defaultMarketOrders)
        : orders;
    }
    return orders;
  }

  protected checkHasPositionByMode(positionData: any): void {
    if (!positionData) {
      this.hasQttdPosition = '';
      this.cdr.detectChanges();
      return;
    }
    const field = this.usingDayTradeMode
      ? 'qtty_final_daytrade'
      : 'qtty_final_swing';
    this.hasQttdPosition =
      !isNullOrUndefined(+positionData[field]) &&
      !isNaN(+positionData[field]) &&
      positionData[field] != '0'
        ? positionData[field]
        : '';
    this.cdr.detectChanges();
  }

  formatPosition(position: number): string {
    const valuePositive = position.toString().replace('-', '');
    const value = bigValueFormatter(position);
    return +valuePositive > 999 ? value : formatterNumber(+toValue(value));
  }

  protected cleanInfosVariations(): void {
    this.minimum = '-';
    this.maximum = '-';
    this.priceWithoutFormatting = 0;
    this.minimumWithoutFormatting = '-';
    this.maximumWithoutFormatting = '-';
    this.price = '-';
    this.variacao_dia = '0';
    this.vol = '0';
  }
}
