<div
  #stopOrderAlertBox
  *ngIf="isVisible"
  class="round bg-neutral-strong position-absolute stop-alert-wrapper"
  [style.top.px]="top"
  [style.left.px]="left"
>
  <h3>Ordem stop</h3>
  Configure o seu stop {{ label }} para disparar sua ordem.
</div>
