import { Injectable } from '@angular/core';
import {
  CHEETAH_CHANNEL,
  MINI_BOOK_CHEETAH_FIELDS,
} from '@shared/cheetah/cheetah.const';
import { MarketDispatcher } from '@shared/cheetah/dispatcher/market.dispatcher';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { RxEvent } from './rx-event';
import { MinibookFillerTranformer } from 'src/app/utils/minibook-filler.transformer';

@Injectable({
  providedIn: 'root',
})
export class MiniBookChannel {
  static instance: MiniBookChannel;

  constructor(
    private _rxEvent: RxEvent,
    private cheetahService: MarketDispatcher
  ) {
    return (MiniBookChannel.instance = MiniBookChannel.instance || this);
  }

  async onEvents() {
    const data = await this._rxEvent.read(CHEETAH_CHANNEL.MINIBOOK);
    if (data && data.stream) {
      data.stream = MinibookFillerTranformer.transformStream(data.stream);
    }
    return data;
  }

  subscribe(params: SubscribeParam) {
    params.fields = MINI_BOOK_CHEETAH_FIELDS();
    params.channel_cheetah = CHEETAH_CHANNEL.MINIBOOK;
    this.cheetahService.subscribe({
      channel_cheetah: CHEETAH_CHANNEL.MINIBOOK,
      itemsArray: params.itemsArray,
    });
  }

  unsubscribe(params: SubscribeParam) {
    if (!params) return;
    this.cheetahService.unsubscribe({
      channel_cheetah: CHEETAH_CHANNEL.MINIBOOK,
      itemsArray: params.itemsArray,
    });
  }
}
