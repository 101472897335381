export const macdTemplate = `
<div class="fw-bolder">Definição</div>
<div class="mb-3">
  O MACD (Moving Average Convergence Divergence) é um indicador técnico popular
  usado na análise técnica para ajudar a identificar a direção da tendência de
  preços de um ativo e possíveis pontos de entrada e saída para negociações. O
  indicador é composto por duas médias móveis exponenciais (EMA) e uma linha de
  sinal.
</div>
<div class="mb-3">
  O cálculo do MACD é feito subtraindo a EMA de 26 dias da EMA de 12 dias. O
  resultado é plotado em um gráfico de barras, onde os valores positivos e
  negativos são mostrados como barras verdes e vermelhas, respectivamente.
</div>
<div class="mb-3">
  Além disso, uma linha de sinal é traçada usando a EMA de 9 dias do MACD. A
  linha de sinal é usada para gerar sinais de compra ou venda quando cruza acima
  ou abaixo do MACD. Quando a linha de sinal cruza acima do MACD, é considerado
  um sinal de compra, enquanto a linha de sinal cruza abaixo do MACD, é
  considerado um sinal de venda.
</div>
<div class="mb-3">
  Os traders e analistas usam o MACD para confirmar a direção da tendência de
  preços e identificar pontos de entrada e saída para negociações. Quando o MACD
  está acima de zero, é geralmente considerado um sinal de que a tendência de
  preços está em alta, enquanto o MACD está abaixo de zero, é considerado um
  sinal de que a tendência de preços está em baixa. Além disso, os cruzamentos
  do MACD e da linha de sinal também podem indicar possíveis pontos de reversão
  de tendência.
</div>
<div class="fw-bolder">Cálculo</div>
<div class="mb-3">
  A fórmula matemática do MACD é bastante simples e envolve o cálculo da
  diferença entre duas médias móveis exponenciais (EMA). A fórmula é a seguinte:
</div>
<div class="mb-3">MACD = EMA de 12 períodos - EMA de 26 períodos</div>
<div class="mb-3">Onde:<br /></div>
<div class="mb-3">
  EMA de 12 períodos é a média móvel exponencial de 12 dias dos preços de
  fechamento do ativo.<br />
  EMA de 26 períodos é a média móvel exponencial de 26 dias dos preços de
  fechamento do ativo.
</div>
<div class="mb-3">
  Vamos supor que queremos calcular o MACD para uma ação ao longo de um período
  de 30 dias. Para isso, precisamos seguir os seguintes passos:
</div>
<div class="mb-3">Calcule a EMA de 12 dias:</div>
<div class="mb-3">
  Primeiro, precisamos determinar o preço de fechamento médio para os últimos 12
  dias.<br />
  Em seguida, usamos a fórmula da EMA para calcular a média móvel exponencial.
  Por exemplo, suponha que os preços de fechamento dos últimos 12 dias são: 10,
  12, 11, 14, 13, 12, 11, 10, 12, 14, 15 e 16.
</div>
<div class="mb-3">
  Para calcular a EMA de 12 dias, podemos usar a seguinte fórmula:<br />
  EMA = [Preço de fechamento atual - EMA anterior] x (2 / (n + 1)) + EMA
  anterior
</div>
<div class="mb-3">
  Começamos com a primeira EMA, que é simplesmente a média móvel simples dos
  preços de fechamento dos primeiros 12 dias. Nesse caso, é
  (10+12+11+14+13+12+11+10+12+14+15+16) / 12 = 12.
</div>
<div class="mb-3">
  Em seguida, calculamos a EMA para o 13º dia usando a fórmula acima, onde o EMA
  anterior é a EMA calculada para o dia anterior e n é o período de tempo (12
  dias neste caso).
</div>
<div class="mb-3">
  Repetimos esse processo para os dias restantes até chegarmos ao 30º dia.
</div>
<div class="mb-3">
  Calcule a EMA de 26 dias:<br />
  Da mesma forma, calculamos a EMA de 26 dias usando os preços de fechamento dos
  últimos 26 dias.
</div>
<div class="mb-3">
  Suponha que os preços de fechamento dos últimos 26 dias sejam: 9, 10, 12, 11,
  14, 13, 12, 11, 10, 12, 14, 15, 16, 18, 19, 20, 19, 18, 16, 15, 14, 13, 12,
  11, 10 e 9.
</div>
<div class="mb-3">
  Começamos com a primeira EMA de 26 dias, que é a média móvel simples dos
  preços de fechamento dos primeiros 26 dias:
  (9+10+12+11+14+13+12+11+10+12+14+15+16+18+19+20+19+18+16+15+14+13+12+11+10+9)
  / 26 = 14.
</div>
<div class="mb-3">
  Em seguida, calculamos a EMA para o 27º dia usando a fórmula acima, onde o EMA
  anterior é a EMA calculada para o dia anterior e n é o período de tempo (26
  dias neste caso).
</div>
<div class="mb-3">
  Repetimos esse processo para os dias restantes até chegarmos ao 30º dia.
</div>
<div class="fw-bolder">Calcule o MACD:</div>
<div class="mb-3">
  Para calcular o MACD, subtraímos a EMA de 26 dias da EMA de 12 dias.<br />
  Neste exemplo, suponha que a EMA de 12 dias para o 30º dia seja 15 e a EMA de
  26 dias seja 13. O MACD seria, portanto, 15 - 13 = 2.
</div>
<div class="fw-bolder">Como funciona</div>
<div class="mb-3">
  A Média Móvel de Convergência/Divergência é utilizada para negociações de
  compra e venda a curto prazo. Dessa forma, a Média Móvel Convergente e
  Divergente detectaria, com rapidez, tendências significativas a curto prazo.
  Para compreendê-la, é importante considerar como as médias móveis de curto e
  longo prazos estão relacionadas.
</div>
<div class="mb-3">
  Vale lembrar: a calibragem padrão da linha MACD é de 26 e 12. E a linha de
  sinal usa a Média Móvel Exponencial de 9 dias.
</div>
<div class="mb-3">
  Esse indicador é calculado diminuindo a MA 26 (longa) da MA 12 (curta). O
  resultado será algo em torno de zero. A partir disso, podemos presumir que:
</div>
<div class="mb-3">
  1. Indicador maior que zero é porque a média dos preços dos últimos 12 dias é
  maior que a média dos preços dos últimos 26 dias.<br />
  2.Indicador menor que zero é porque a média dos preços dos últimos 12 dias é
  menor que a média dos preços dos últimos 26 dias.
</div>
<div class="mb-3">
  Como a média de 12 dias reflete de forma mais atual o comportamento dos preços
  do que a média de 26 dias, pode-se dizer que a tendência está mais para alta
  no primeiro caso, com o indicador maior que zero.
</div>
<div class="mb-3">
  <img class="w-75" src="/assets/indicators/macd-como-funciona.png" />
</div>
<div class="fw-bolder">Histograma MACD</div>
<div class="mb-3">O histograma funciona da seguinte forma:</div>
<div class="mb-3">
  &bull; Valores positivos (acima do eixo zero) aparecem quando a linha do
  indicador está maior do que a linha da Média Móvel 9. Isso indica uma
  tendência de alta.<br />
  &bull; Valores negativos (abaixo do eixo zero) aparecem quando a linha do
  indicador está menor do que a linha da Média Móvel 9. Isso indica uma
  tendência de baixa.
</div>
<div class="mb-3">
  Para obter o resultado do histograma do MACD, verifica-se a diferença entre a
  curva sinal e a curva MACD. As divergências do MACD são observadas se o
  movimento deste é em sentido oposto ao do preço de um ativo considerado.
</div>
<div class="mb-3">
  Um exemplo de divergências do MACD acontece quando o preço de um ativo
  apresenta uma mínima nova. Ao mesmo tempo, o MACD indica somente uma baixa.
  Essa é uma divergência de alta, e se dá quando a baixa não caracteriza uma
  mínima nova.
</div>
<div class="fw-bolder">Sinais de Compra e Venda</div>
<div class="mb-3">
  A linha MACD é empregada para gerar sinais de transação explícitos,
  demonstrando mudanças de tendências. Ela nos ajuda a identificar pontos de
  entrada e saída do mercado, o procedimento é o seguinte:
</div>
<div class="mb-3">
  &bull; Sinal de compra: É gerado sempre que o MACD cruza para cima sua linha
  de sinalização.<br />
  &bull; Sinal de venda: É gerado sempre que o MACD cruza para baixo sua linha
  de sinalização.
</div>
<div class="mb-3">
  O MACD permite que o investidor identifique uma tendência de curto prazo. É
  obtido ao se conseguir a diferença entre médias móveis de curto e longo
  prazos. Quando uma média cruza sobre a outra, o indicador equivale a zero.
</div>
<div class="fw-bolder">Como configurar</div>
<div class="fw-bolder">Valores</div>
<div class="text-decoration-underline">Período Rápido</div>
<div class="mb-3">
  O período do EMA (Média Móvel Exponencial) de prazo mais curto. 12 dias é o
  padrão.
</div>
<div class="text-decoration-underline">Período Lento</div>
<div class="mb-3">
  O período da EMA (Média Móvel Exponencial) de longo prazo. 26 dias é o padrão.
</div>
<div class="text-decoration-underline">Fonte</div>
<div class="mb-3">
  Determina quais dados de cada barra serão usados ​​nos cálculos. Fechamento
  (Fch) é o padrão.
</div>
<div class="text-decoration-underline">Período Sinal</div>
<div class="mb-3">
  O período para a EMA (Média Móvel Exponencial) da Linha MACD, também conhecida
  como Linha de Sinal. 9 dias é o padrão.
</div>
<div class="mb-3">
  <img class="image-indicators-input" src="/assets/indicators/macd-entrada.png" />
</div>
<div class="fw-bolder">Estilo</div>
<div class="text-decoration-underline">Histograma acima e abaixo</div>
<div class="mb-3">
  Pode alternar a visibilidade do histograma, bem como a visibilidade de uma
  linha de preço mostrando o valor atual real do histograma. Também pode
  selecionar a cor e a espessura da linha do histograma.
</div>
<div class="text-decoration-underline">MACD</div>
<div class="mb-3">
  Pode alternar a visibilidade da Linha MACD, bem como a visibilidade de uma
  linha de preço mostrando o valor atual real da Linha MACD. Também pode
  selecionar a cor, a espessura da linha e o tipo visual da Linha MACD (Linha é
  o padrão).
</div>
<div class="text-decoration-underline">Sinal</div>
<div class="mb-3">
  Pode alternar a visibilidade da Linha de Sinal, bem como a visibilidade de uma
  linha de preço mostrando o valor atual real da Linha de Sinal. Também pode
  selecionar a cor da Linha de Sinal, a espessura da linha e o tipo visual
  (Linha é o padrão).
</div>
<div class="text-decoration-underline">Precisão</div>
<div class="mb-3">
  Define o número de casas decimais a serem deixadas no valor do indicador antes
  do arredondamento. Quanto maior esse número, mais casas decimais haverá no
  valor do indicador.
</div>
<div class="mb-3">
  <img class="image-indicators-input" src="/assets/indicators/macd-estilo.png" />
</div>
`;
