import { Dictionary } from '@core/models';

export const STOCKS_TO_SUB = new Dictionary<string>();
STOCKS_TO_SUB.set('IBOV:1', 'IBOV:1');
STOCKS_TO_SUB.set('DOLFUT:1:SYN', 'DOLFUT:1:SYN');
STOCKS_TO_SUB.set('BITFUT:1', 'BITFUT:1');
STOCKS_TO_SUB.set('SMLL:1', 'SMLL:1');
STOCKS_TO_SUB.set('IFIX:1', 'IFIX:1');
STOCKS_TO_SUB.set('PETR4:1', 'PETR4:1');

export const MODAL_MENU_TYPES: any = {
  calendar: 'Calendário',
  news: 'Notícias',
  proceeds: 'Proventos',
  events: 'Eventos',
  cvm: 'CVM',
};

export const PERIODS_GERAL = [
  { label: 'Próximos', key: 'next' },
  { label: 'Na semana', key: 'this_week' },
  { label: 'No mês', key: 'this_month' },
  { label: 'No ano', key: 'this_year' },
];

export const PERIODS_CALENDAR = [
  { label: 'Hoje', key: 'today' },
  { label: 'Ontem', key: 'yesterday' },
  { label: 'Amanhã', key: 'tomorrow' },
  { label: 'Esta semana', key: 'this_week' },
  { label: 'Próxima semanas', key: 'next_week' },
  { label: 'Próximos', key: 'next' },
  { label: 'Últimos', key: 'latest' },
];

export const PERIODS_CVM = [
  { label: 'Últimos', key: 'next' },
  { label: 'Na semana', key: 'this_week' },
  { label: 'No mês', key: 'this_month' },
  { label: 'No ano', key: 'this_year' },
];

export const TAB_VIEWS = [
  { label: 'Calendário', periods: PERIODS_CALENDAR, key: 'calendar' },
  { label: 'Notícias', periods: PERIODS_GERAL, key: 'news' },
  { label: 'Proventos', periods: PERIODS_GERAL, key: 'proceeds' },
  { label: 'Eventos', periods: PERIODS_GERAL, key: 'events' },
  { label: 'CVM', periods: PERIODS_CVM, key: 'cvm' },
];

export const MARKET_SUMMARY_STOCKS: any = [
  {
    tick_size_denominator: '0',
    preco_ultimo: '0.00',
    day_variation: '0,00',
    variacao_dia: 0,
    arrow_hex: '#0056FF',
    arrow_font_hex: '#FFFFFF',
    vl_close_history: [],
    cd_stock: 'IBOV',
    id_exchange: 1,
    item: STOCKS_TO_SUB.get('IBOV:1'),
    idRow: STOCKS_TO_SUB.get('IBOV:1'),
  },
  {
    tick_size_denominator: '1',
    preco_ultimo: '0.00',
    day_variation: '0,00',
    variacao_dia: 0,
    arrow_hex: '#0056FF',
    arrow_font_hex: '#FFFFFF',
    vl_close_history: [],
    cd_stock: 'DOLFUT',
    id_exchange: 1,
    item: STOCKS_TO_SUB.get('DOLFUT:1:SYN'),
    idRow: STOCKS_TO_SUB.get('DOLFUT:1:SYN'),
  },
  {
    tick_size_denominator: '2',
    preco_ultimo: '0.00',
    day_variation: '0,00',
    variacao_dia: 0,
    arrow_hex: '#0056FF',
    arrow_font_hex: '#FFFFFF',
    vl_close_history: [],
    cd_stock: 'BITFUT',
    id_exchange: 7779,
    item: STOCKS_TO_SUB.get('BITFUT:1'),
    idRow: STOCKS_TO_SUB.get('BITFUT:1'),
  },
  {
    tick_size_denominator: '0',
    preco_ultimo: '0.00',
    day_variation: '0,00',
    variacao_dia: 0,
    arrow_hex: '#0056FF',
    arrow_font_hex: '#FFFFFF',
    vl_close_history: [],
    cd_stock: 'SMLL',
    id_exchange: 1,
    item: STOCKS_TO_SUB.get('SMLL:1'),
    idRow: STOCKS_TO_SUB.get('SMLL:1'),
  },
  {
    tick_size_denominator: '0',
    preco_ultimo: '0.00',
    day_variation: '0,00',
    variacao_dia: 0,
    arrow_hex: '#0056FF',
    arrow_font_hex: '#FFFFFF',
    vl_close_history: [],
    cd_stock: 'IFIX',
    id_exchange: 1,
    item: STOCKS_TO_SUB.get('IFIX:1'),
    idRow: STOCKS_TO_SUB.get('IFIX:1'),
  },
  {
    tick_size_denominator: '2',
    preco_ultimo: '0.00',
    day_variation: '0,00',
    variacao_dia: 0,
    arrow_hex: '#0056FF',
    arrow_font_hex: '#FFFFFF',
    vl_close_history: [],
    cd_stock: 'PETR4',
    id_exchange: 1,
    item: STOCKS_TO_SUB.get('PETR4:1'),
    idRow: STOCKS_TO_SUB.get('PETR4:1'),
  },
];
