class IntegrationServiceResult<T> {
  public isSuccess: boolean;
  public error: string | null;
  public value: T | null;

  private constructor(isSuccess: boolean, error: string | null, value: T | null) {
    this.isSuccess = isSuccess;
    this.error = error;
    this.value = value;
  }

  public static ok<T>(value: T): IntegrationServiceResult<T> {
    return new IntegrationServiceResult<T>(true, null, value);
  }

  public static fail<T>(error: string): IntegrationServiceResult<T> {
    return new IntegrationServiceResult<T>(false, error, null);
  }

  public getValue(): T {
    if (!this.isSuccess) {
      throw new Error('Cannot get the value of a failed result');
    }
    return this.value as T;
  }

  public getError(): string {
    if (this.isSuccess) {
      throw new Error('Cannot get the error of a successful result');
    }
    return this.error as string;
  }
}

export default IntegrationServiceResult;