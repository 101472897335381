import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { system } from '@core/system/system.service';
import { DeviceValidatorService } from '@shared/services/device-validator.service';

@Component({
  selector: 'app-invalid-browser',
  templateUrl: './invalid-browser.component.html',
  styleUrls: ['./invalid-browser.component.scss']
})
export class InvalidBrowserComponent implements OnInit {
  public userFirstName: string = '';
  public browserErrorMsg = '';

  constructor(private router: Router, private deviceValidator: DeviceValidatorService) {}

  ngOnInit() {
    this.browserErrorMsg = this.deviceValidator.browserMessage
    if(system.userAuthenticated && !this.deviceValidator.isChromium()){
      this.userFirstName = system.userAuthenticated.investor.name?.split(' ')[0]
    }else{
      this.router.navigate(['auth/login']);
    }
  }
}
