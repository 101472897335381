import { Component } from '@angular/core';
import { randomId } from '@shared/rocket-components/utils/functions';

@Component({
  selector: 'rocket-modal',
  templateUrl: './modal.component.html',
})
export class RocketModalComponent {
  public id: string = randomId('rocket_modal');
}
