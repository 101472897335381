import { ModifierMouseArgs } from 'scichart/Charting/ChartModifiers/ModifierMouseArgs';
import { Subject } from 'rxjs';
import { BaseStackedCollection } from 'scichart/Charting/Visuals/RenderableSeries/BaseStackedCollection';
import { SeriesSelectionModifier } from 'scichart/Charting/ChartModifiers/SeriesSelectionModifier';
import { ClickableAnnotation } from '@shared/tiger-chart/annotations/clickable-annotation';
import { TMouseEventData } from '@shared/tiger-chart/types/tiger-chart.types';
import { DrawTools } from '@shared/tiger-chart/draw-tools/draw-tools.interface';
import { Point } from 'scichart/Core/Point';
import { IRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/IRenderableSeries';
import { DpiHelper } from 'scichart/Charting/Visuals/TextureManager/DpiHelper';
import { CustomAnnotation } from 'scichart/Charting/Visuals/Annotations/CustomAnnotation';
import { CANDLE_IDS } from '../constants/tiger-chart.constants';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { TigerChartEventsModalService } from '../tiger-chart-events-modal/tiger-chart-events-modal.service';

export class ClickEventsModifier extends SeriesSelectionModifier {
  events$ = new Subject<TMouseEventData | null>();
  drawAnnotationHoverEvents$ = new Subject<{ draw: DrawTools }>();
  private _idX = '';
  private _idY = '';
  private _annotatioHovered!: ClickableAnnotation | null;
  public clickTimer: any = null;
  public indicatorClicked: any = null;
  private clickedOnAnnotationEvent = false;

  constructor(
    private tigerChartEventsModalService?: TigerChartEventsModalService,
    idXAxis?: string,
    idYAxis?: string
  ) {
    super();
    if (this.tigerChartEventsModalService) {
      this.tigerChartEventsModalService
        .onClickAnnotationEvents()
        .subscribe((bool) => {
          this.clickedOnAnnotationEvent = bool;
        });
    }
    this._idX = idXAxis || '';
    this._idY = idYAxis || '';
  }

  override onDetachSeries(rs: IRenderableSeries): void {
    if (rs.isStacked) {
      const stacked = rs as BaseStackedCollection<any>;
      stacked.getVisibleSeries().forEach((item) => {
        super.onDetachSeries(item);
      });
      return;
    }
    super.onDetachSeries(rs);
  }

  override onAttachSeries(rs: IRenderableSeries): void {
    if (rs.isStacked) {
      const stacked = rs as BaseStackedCollection<any>;
      stacked.getVisibleSeries().forEach((item) => {
        super.onAttachSeries(item);
      });
      return;
    }
    super.onAttachSeries(rs);
  }

  override modifierMouseUp(args: ModifierMouseArgs): void {
    return;
  }

  override modifierMouseDown(args: ModifierMouseArgs): void {
    if (this.clickedOnAnnotationEvent) return;
    super.modifierMouseDown(args);
    const annotations = this.parentSurface?.annotations.asArray();
    if (!annotations) {
      return;
    }

    const mousePoint = args.mousePoint;
    this.emitIndicatorClicked(null, 'DONTCLICK', mousePoint);
    const candleSeries = this.parentSurface.renderableSeries.getById(
      CANDLE_IDS.MAIN_SERIE
    );
    const mouseEvent = args.nativeEvent;
    const mutiplierX = mouseEvent.offsetX * DpiHelper.PIXEL_RATIO;
    const mutiplierY = mouseEvent.offsetY * DpiHelper.PIXEL_RATIO;

    if (!isNullOrUndefined(candleSeries)) {
      const hitTest = candleSeries.hitTestProvider.hitTest(
        mutiplierX,
        mutiplierY
      );

      let hitAnnotation: boolean = false;
      if (hitTest.isEmpty) return;
      annotations
        .filter((annotation) => annotation.id.includes('annotation-indicator'))
        .forEach((annotation) => {
          if (annotation.isSvgAnnotation) {
            let customAnnotation = annotation as CustomAnnotation;
            let annotationX2 = this.getAnnotationCoordinate(
              this.parentSurface.xAxes,
              this._idX,
              customAnnotation.getAnnotationBorders().x2
            );

            let annotationY2 = this.getAnnotationCoordinate(
              this.parentSurface.yAxes,
              this._idY,
              customAnnotation.getAnnotationBorders().y2
            );

            const [x1, x2] = [annotation.x1, annotationX2].sort(
              (a, b) => a - b
            );
            const [y1, y2] = [annotation.y1, annotationY2].sort(
              (a, b) => a - b
            );
            const { x, y } = hitTest.hitTestPointValues;
            if (x >= x1 && x <= x2 && y >= y1 && y <= y2) {
              hitAnnotation = true;
              this.emitIndicatorClicked(annotation, 'CLICK', mousePoint);
            }
          }
        });

      if (hitAnnotation) return;
    }
    const rSeries = this.parentSurface?.renderableSeries.asArray();
    if (!rSeries) return;
    rSeries.forEach((series: any) => {
      if (series.isStacked) {
        const stacked = series as BaseStackedCollection<any>;
        stacked.getVisibleSeries().forEach((item) => {
          this.mouseClickDown(args, item);
        });
        return;
      }
      this.mouseClickDown(series, mousePoint);
    });
  }

  private mouseClickDown(series: any, mousePoint: Point) {
    const { id } = series;
    const hitTestProvider = series.hitTestProvider;
    if (
      !hitTestProvider ||
      id === CANDLE_IDS.VOLUME_SERIES ||
      id === CANDLE_IDS.MAIN_SERIE ||
      id === CANDLE_IDS.BOOK_SERIES
    ) {
      return;
    }

    const dataSeries = hitTestProvider.parentSeries.dataSeries;
    const indexes = dataSeries.indexes;

    const index = Math.round(
      hitTestProvider.parentSeries.xAxis
        .getCurrentCoordinateCalculator()
        .getDataValue(mousePoint.x)
    );
    if (index > indexes.size()) return;
    const a = series.hitTestProvider.hitTest(mousePoint.x, mousePoint.y);
    if (a.isEmpty) return;
    if (a.isHit && series.isVisible) {
      if (this.clickTimer) {
        clearTimeout(this.clickTimer);
        this.clickTimer = null;
        this.emitIndicatorClicked(series, 'DOUBLECLICK', mousePoint);
        return;
      }

      this.clickTimer = setTimeout(() => {
        this.clickTimer = null;
        this.indicatorClicked = null;
      }, 200);
      this.emitIndicatorClicked(series, 'CLICK', mousePoint);
    }
  }

  private getAnnotationCoordinate(
    axes: any,
    axisId: string,
    customAnnotation: any
  ): number {
    return axes
      .getById(axisId)
      .getCurrentCoordinateCalculator()
      .getDataValue(customAnnotation);
  }

  private emitIndicatorClicked(
    indicator: any,
    type: string,
    mousePoint: any
  ): void {
    const event: any = {
      indicator,
      type,
      mousePoint,
    };
    this.events$.next(event);
    return;
  }
}
