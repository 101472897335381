<workspace-doc-header
  css="w-100 header-wrapper d-flex justify-content-between"
  [fullscreenEnabled]="headerOptions.fullscreen"
  [item]="component"
>
  <app-search-stock
    #searchStock
    [resetInput]="false"
    [ref]="refComponent"
    [initStock]="stockSelected"
    [headerOptions]="headerOptions"
    (selectStockChange)="selectStock($event)"
  ></app-search-stock>
  <fla-workspace-doc-header-item
    (flaClick)="toggleTradeByOffer()"
    [icon]="icon"
    [iconColor]="iconClass"
    idElement="TOGGLE_BOOK_BY_OFFER"
    [tooltipText]="tooltip"
    alignItem="ml-auto"
  ></fla-workspace-doc-header-item>
</workspace-doc-header>
