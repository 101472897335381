import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import { StockService } from '@shared/services/api/trademap/v1/stock.service';
import { IStockIndex, IStockIndexResponse } from '@core/interface';
import { MarketSummaryService } from '../../market-summary.service';

@Component({
  selector: 'app-indexes-modal',
  templateUrl: './indexes-modal.component.html',
  styleUrls: ['./indexes-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndexesModalComponent extends RocketModalRef implements OnInit {
  indexList: IStockIndex[] = [];
  selectedIndex = '';
  loading = true;

  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: any,
    private _rocketModalService: RocketModalService,
    private _cdr: ChangeDetectorRef,
    private _stockService: StockService,
    private _marketSummaryService: MarketSummaryService
  ) {
    super(_rocketModalService);
  }

  ngOnInit() {
    this.data.selectedIndex &&
      (this.selectedIndex = this.data.selectedIndex.cd_stock);
    this.listIndexes();
  }

  listIndexes() {
    this._stockService
      .getStockIndexList()
      .then((data: IStockIndexResponse) => {
        this.loading = false;
        data.success && (this.indexList = data.data);
        this._cdr.detectChanges();
      });
  }

  selectIndex(index: IStockIndex) {
    this._marketSummaryService.selectedIndex$.next({
      index,
      component: this.data.component,
      linked: this.data.linked,
    });
    this.close();
  }
}
