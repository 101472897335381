import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import { Subject, auditTime, takeUntil } from 'rxjs';
import {
  formatByTick,
  isNullOrUndefined,
  isRowSelectedStock,
} from 'src/app/utils/utils.functions';
import { STOCK_TABLE_VIEW } from '../../enum/stock-table-views.enum';
import { IStockAuctionInfos } from '../../interfaces/stock-table-views.interface';
import { isAuction } from '@shared/constants/general.contant';
import {
  calculateTimeDifference,
  countdown,
  formatAuctionValues,
} from '@shared/rocket-components/components/auction-tooltip/constants/auction.constants';
import { deepClone } from '@shared/rocket-components/utils';
import { IGlobalStock } from '@shared/components/stock-table/interfaces/stock-table.interfaces';

@Component({
  selector: 'app-stock-table-view-card-and-chart',
  templateUrl: './stock-table-view-card-and-chart.component.html',
  styleUrls: ['./stock-table-view-card-and-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockTAbleViewCardAndChartComponent
  implements OnChanges, OnDestroy
{
  @Output() public stockSelected: EventEmitter<void> = new EventEmitter();
  @Output() public removeStock: EventEmitter<void> = new EventEmitter();

  @Input() tableView!: STOCK_TABLE_VIEW;
  @Input() name: string = '';
  @Input() cdStock: string = '';
  @Input() image: string = '';
  @Input() dsAsset?: string = '';
  @Input() companyName: string = '';
  @Input() componentId: string = '';
  @Input() vlCloseHistory!: number[];
  @Input() tickSize: string | number = '0';
  @Input() variation: string = '0';
  @Input() lastPrice: number | string = 0;
  @Input() variationBgColor: string = CHART_COLORS.NEUTRAL_MEDIUM;
  @Input() variationTextColor: string = CHART_COLORS.BLACK;
  @Input() globalStock!: string;
  @Input() situation!: string;
  @Input() displayRemoveStock: boolean = false;
  @Input() auctionInfos!: IStockAuctionInfos | undefined;
  @Input() globalStockSelected!: IGlobalStock;
  public updatePoint!: { value: { y: number }; index: number; cdStock: string };
  public TABLE_VIEW = STOCK_TABLE_VIEW;
  public stockAuctionInfos!: IStockAuctionInfos | null;
  public stockPrice: string = '0';
  public chatLastValue: number = 0;
  public timer: string = '-';
  public disablePriceTooltip: boolean = true;
  public isGlobalStocks: boolean = false;

  private _time: number = 0;
  private _timerSubject = new Subject<void>();
  private destroy$ = new Subject<void>();

  constructor(private _cdr: ChangeDetectorRef) {
    this._timerSubject
      .pipe(auditTime(1000), takeUntil(this.destroy$))
      .subscribe(() => this._handleAuctionTime());
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { lastPrice, auctionInfos, situation, globalStockSelected } = changes;
    if (lastPrice?.currentValue) this._formatStockPrice(lastPrice.currentValue);
    if (globalStockSelected?.currentValue) this._verifyIsGlobalStock();
    if (auctionInfos?.currentValue)
      this._handleAuctionInfos(auctionInfos.currentValue);
    if (
      situation?.currentValue &&
      !isAuction(situation.currentValue) &&
      this.stockAuctionInfos
    ) {
      this.stockAuctionInfos = null;
    }
  }

  ngOnDestroy(): void {
    this._timerSubject.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private _formatStockPrice = (price: string): void => {
    if (price === '' || isNullOrUndefined(price)) {
      this.stockPrice = '0';
      this.disablePriceTooltip = true;
      return;
    }
    const formattedPrice = formatByTick(price, +this.tickSize);
    this.disablePriceTooltip = formattedPrice.length < 6;
    this.stockPrice = formattedPrice;
    this.chatLastValue = parseFloat(price);
    this._cdr.detectChanges();
  };

  private _verifyIsGlobalStock(): void {
    if (this.globalStockSelected) {
      this.isGlobalStocks = isRowSelectedStock(
        this.cdStock,
        this.globalStockSelected.cd_stock,
        this.globalStockSelected.cd_stock_order,
        this.globalStockSelected.synonymous_related
      );
    } else {
      this.isGlobalStocks = false;
    }
    this._cdr.detectChanges();
  }

  private _handleAuctionInfos(auction: IStockAuctionInfos): void {
    if (!auction) return;
    this._timerSubject.closed && (this._timerSubject = new Subject<void>());
    this._formatAuctionValues(auction);
  }

  private _formatAuctionValues(params: any): void {
    const data = deepClone(params);
    this.stockAuctionInfos = formatAuctionValues(data);
    this._time = calculateTimeDifference(data.hora_abert_program);
    this._handleAuctionTime();
  }

  private _handleAuctionTime(): void {
    if (this._timerSubject.closed) return;
    const { timer, timerFormatted } = countdown(this._time);
    this._time = timer;
    this.timer = timerFormatted;
    this._cdr.detectChanges();
    this._timerSubject.next();
  }
}
