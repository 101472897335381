import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

@Component({
  selector: 'best-offer-renderer',
  template: `
    <div
      ref="eLabel"
      class="ag-header-cell-label"
      role="presentation"
      [ngClass]="{
        'ag-header-cell-sorted-asc': ascSort || descSort
      }"
      (click)="onSortRequested(null, $event)"
    >
      <span ref="eText" class="customHeaderLabel ag-header-cell-text"
        >{{ params.displayName }}
        <span [ngClass]="class"> {{ letter }} </span></span
      >
      <span class="ag-sort-indicator-container" ref="eSortIndicator">
        <span
          ref="eSortOrder"
          class="ag-sort-indicator-icon ag-sort-order ag-hidden"
          aria-hidden="true"
          >1</span
        >
        <span
          ref="eSortAsc"
          class="ag-sort-indicator-icon ag-sort-ascending-icon"
          [ngClass]="{ 'ag-hidden': !ascSort }"
          aria-hidden="true"
          ><span
            class="ag-icon ag-icon-asc"
            unselectable="on"
            role="presentation"
          ></span
        ></span>
        <span
          ref="eSortDesc"
          class="ag-sort-indicator-icon ag-sort-descending-icon"
          [ngClass]="{ 'ag-hidden': !descSort }"
          aria-hidden="true"
          ><span
            class="ag-icon ag-icon-desc"
            unselectable="on"
            role="presentation"
          ></span
        ></span>
        <span
          ref="eSortNone"
          class="ag-sort-indicator-icon ag-sort-none-icon"
          [ngClass]="{ 'ag-hidden': !noSort }"
          aria-hidden="true"
          ><span
            class="ag-icon ag-icon-none"
            unselectable="on"
            role="presentation"
          ></span
        ></span>
      </span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BestOfferRenderer implements IHeaderAngularComp {
  params!: IHeaderParams;

  ascSort!: boolean;
  descSort!: boolean;
  noSort!: boolean;

  letter = '';
  class = '';

  @ViewChild('menuButton', { read: ElementRef }) menuButton!: ElementRef;

  agInit(params: IHeaderParams): void {
    this.params = params;
    this.letter = params.displayName == 'Bid' ? 'C' : 'V';
    this.class =
      params.displayName == 'Bid'
        ? 'text-multibroker-buy'
        : 'text-multibroker-sell';

    params.column.addEventListener(
      'sortChanged',
      this.onSortChanged.bind(this)
    );

    this.onSortChanged();
  }

  refresh(params: IHeaderParams): boolean {
    this.params = params;
    this.letter = params.displayName == 'Bid' ? 'C' : 'V';
    this.class =
      params.displayName == 'Bid'
        ? 'text-multibroker-buy'
        : 'text-multibroker-sell';
    return true;
  }

  onMenuClicked() {
    this.params.showColumnMenu(this.menuButton.nativeElement);
  }

  onSortChanged() {
    this.ascSort = this.descSort = this.noSort = false;
    if (this.params.column.isSortAscending()) {
      this.ascSort = true;
    } else if (this.params.column.isSortDescending()) {
      this.descSort = true;
    } else {
      this.noSort = true;
    }
  }

  onSortRequested(order: 'asc' | 'desc' | null, event: any) {
    this.params.progressSort(false);
  }
}
