<rocket-modal [hidden]="isSaveModel" class="modalDrawConfiguration">
  <rocket-modal-header>
    <div>
      <h3 class="modal-title">{{ selectedTool.title }}</h3>
    </div>
  </rocket-modal-header>

  <app-rocket-modal-body>
    <div class="mb-6">
      <div *ngIf="selectedTool.textConfiguration" class="d-flex flex-column">
        <div class="inner d-flex align-items-center">
          <app-dropdown title="">
            <a
              type="button"
              class="nav-link lh-sm round-top h-100"
              appDropdownButton
              placement="bottom-start"
              title=""
            >
              <div class="colorpicker mr-1">
                <div class="cursor-pointer colorpickerWrapper">
                  <div
                    class="color d-flex align-items-center justify-content-center p-1"
                  >
                    <div class="opacitySwatch">
                      <div
                        id="colorpickerText"
                        class="swatch"
                        style="background-color: rgb(255, 255, 255)"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <div class="contents">
              <li>
                <app-config-colorpicker
                  (selectedColor)="selectedColor('text', $event)"
                  [selectedTool]="selectedTool"
                  [typeColorUpdate]="toolsTypeDraw.TEXT"
                  #configColorPicker
                ></app-config-colorpicker>
              </li>
            </div>
          </app-dropdown>

          <ng-select
            class="fontsize-select form-select form-select-sm form-control-sm"
            [clearable]="false"
            [items]="fontSizes"
            [(ngModel)]="fontSize"
            bindLabel="title"
            (change)="setFontStyle('fontsize')"
          >
          </ng-select>

          <div
            (click)="setFontStyle('bold')"
            class="cursor-pointer ml-1 fontStyleButton container-b d-flex align-items-center justify-content-center"
            [ngClass]="{ active: selectedTool.textBold }"
            data-role="button"
            data-name="toggle-bold"
          >
            <span class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 28 28"
                width="28"
                height="28"
              >
                <path
                  fill="currentColor"
                  d="M14 21h-3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3c2 0 4 1 4 3 0 1 0 2-1.5 3 1.5.5 2.5 2 2.5 4 0 2.75-2.638 4-5 4zM12 9l.004 3c.39.026.82 0 
                  1.25 0C14.908 12 16 11.743 16 10.5c0-1.1-.996-1.5-2.5-1.5-.397 0-.927-.033-1.5 0zm0 5v5h1.5c1.5 0 3.5-.5 3.5-2.5S15 14 13.5 14c-.5 0-.895-.02-1.5 0z"
                ></path>
              </svg>
            </span>
          </div>
          <div
            (click)="setFontStyle('italic')"
            class="cursor-pointer ml-1 fontStyleButton container-b d-flex align-items-center justify-content-center"
            [ngClass]="{ active: selectedTool.textItalic }"
            data-role="button"
            data-name="toggle-italic"
          >
            <span class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 28 28"
                width="28"
                height="28"
              >
                <path
                  fill="currentColor"
                  d="M12.143 20l1.714-12H12V7h5v1h-2.143l-1.714 12H15v1h-5v-1h2.143z"
                ></path>
              </svg>
            </span>
          </div>
        </div>

        <div class="inner">
          <textarea
            id="text_textarea"
            [ngClass]="{ autoselect: select('text_textarea') }"
            (input)="setFontStyle('text'); hashSelect['text_textarea'] = true"
            name="text-input"
            rows="9"
            class="configTextarea"
            [(ngModel)]="text"
          ></textarea>
        </div>

        <div *ngIf="false" class="inner d-flex align-items-center gap-3">
          <app-check
            *ngIf="selectedTool.isOpcionalBackground"
            (flaChange)="updateOpcional('useTextBackground')"
            label="Fundo"
          ></app-check>
          <label
            class="form-check-label"
            *ngIf="!selectedTool.isOpcionalBackground"
          >
            Fundo
          </label>
          <app-dropdown
            [ngClass]="{
              disabled:
                !selectedTool.useTextBackground &&
                selectedTool.isOpcionalBackground
            }"
            title=""
          >
            <a
              type="button"
              class="nav-link lh-sm round-top h-100"
              appDropdownButton
              placement="bottom-start"
              title=""
            >
              <div class="colorpicker mr-1">
                <div class="cursor-pointer colorpickerWrapper">
                  <div
                    class="color d-flex align-items-center justify-content-center p-1"
                  >
                    <div class="opacitySwatch">
                      <div
                        id="colorpickerBackground"
                        class="swatch"
                        style="background-color: rgb(255, 255, 255)"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <div class="contents">
              <li>
                <app-config-colorpicker
                  (selectedColor)="selectedColor('background', $event)"
                  [selectedTool]="selectedTool"
                  [typeColorUpdate]="toolsTypeDraw.BACKGROUND"
                  #configColorPicker
                ></app-config-colorpicker>
              </li>
            </div>
          </app-dropdown>
        </div>

        <div *ngIf="false" class="inner d-flex align-items-center gap-3">
          <app-check
            *ngIf="selectedTool.isOpcionalOutline"
            (flaChange)="updateOpcional('useTextOutline')"
            label="Contorno"
          ></app-check>
          <label
            class="form-check-label"
            *ngIf="!selectedTool.isOpcionalOutline"
          >
            Contorno
          </label>
          <app-dropdown
            [ngClass]="{
              disabled:
                !selectedTool.useTextOutline && selectedTool.isOpcionalOutline
            }"
            title=""
          >
            <a
              type="button"
              class="nav-link lh-sm round-top h-100"
              appDropdownButton
              placement="bottom-start"
              title=""
            >
              <div class="colorpicker mr-1">
                <div class="cursor-pointer colorpickerWrapper">
                  <div
                    class="color d-flex align-items-center justify-content-center p-1"
                  >
                    <div class="opacitySwatch">
                      <div
                        id="colorpickerOutline"
                        class="swatch"
                        style="background-color: rgb(255, 255, 255)"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <div class="contents">
              <li>
                <app-config-colorpicker
                  (selectedColor)="selectedColor('outline', $event)"
                  [selectedTool]="selectedTool"
                  [typeColorUpdate]="toolsTypeDraw.OUTLINE"
                  #configColorPicker
                ></app-config-colorpicker>
              </li>
            </div>
          </app-dropdown>
        </div>
      </div>
    </div>
    <div class="mb-4 d-flex justify-content-between configDrawFooter">
      <div>
        <rocket-model-save
          (rocketChange)="updateConfigUser($event)"
        ></rocket-model-save>
      </div>
      <div>
        <rocket-button
          [isSmall]="true"
          label="Ok"
          [isDisabled]="false"
          (rocketClick)="close()"
        >
        </rocket-button>
      </div>
    </div>
  </app-rocket-modal-body>
</rocket-modal>
