import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShareHoldersHistoryCacheService } from '@shared/services/core/cache/shareholders-history-cache.service';
import { RestService } from '@shared/services/rest';
import { firstValueFrom, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShareholdersService extends RestService {
  override _url: string = 'api/trademap/v1/shareHolders';
  constructor(
    private shareHoldersHistoryCacheService: ShareHoldersHistoryCacheService,
    http: HttpClient
  ) {
    super(http);
  }

  public getNewCompanyShareholdersHistory(cdStock: string) {
    const key = `${cdStock}`;
    let request$: any = this.shareHoldersHistoryCacheService.getValue(key);
    if (!request$) {
      request$ = this.get(`history?cd_stock=${cdStock}`).pipe(shareReplay(1));
      this.shareHoldersHistoryCacheService.setValue(request$, key);
    }
    return firstValueFrom(request$);
  }
}
