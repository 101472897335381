export class ConfigModalCandle {
  constructor(init?: Partial<ConfigModalCandle>) {
    if (init) Object.assign(this, init);
  }

  colorsVelaUp: string = '';
  colorsVelaDown: string = '';
  colorsBorderUp: string = '';
  colorsBorderDown: string = '';
  showBorder: boolean = true;
}

export class ConfigModalCloseLine {
  constructor(init?: Partial<ConfigModalCloseLine>) {
    if (init) Object.assign(this, init);
  }

  showLabel: boolean = true;
}
