import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ICustodyChartInfos } from '@shared/services/api/nitro-custody-ws/v1/interface/custody.interface';
import { PERFORMANCE_CHART_DEFAULT_PERIOD } from '../../constants/performance-report.contants';
import { PERFORMANCE_FILTERS } from '../../enum/performance-report.enum';
import { PerformanceReportService } from '../../services/performance-report.service';

@Component({
  selector: 'performance-info-chart',
  templateUrl: './performance-info-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformanceInfoChartComponent implements OnInit, OnChanges {
  @Input() public serviceFilters!: any;
  public data: ICustodyChartInfos[] = [];

  public isIntraday: boolean = false;
  public isLoading: boolean = true;
  public withouDataMessage: string = 'Não há dados para serem exibidos.';

  constructor(
    private _performanceReportService: PerformanceReportService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._verifyIsIntraday();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { serviceFilters } = changes;
    if (serviceFilters && serviceFilters.currentValue) {
      this._verifyIsIntraday();
      this.isLoading = false;
      this._getPerformanceInfos();
    }
  }

  private _getPerformanceInfos(): void {
    this._performanceReportService.getCustody(this.serviceFilters).subscribe({
      next: (response) => {
        this.withouDataMessage = 'Não há dados para serem exibidos.';
        this.isLoading = false;
        this.data = response;
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.isLoading = false;
        this.data = [];
        this.withouDataMessage = error.message;
        this.cdr.detectChanges();
      },
    });
  }

  private _verifyIsIntraday(): void {
    this.isIntraday =
      this.serviceFilters[PERFORMANCE_FILTERS.PERIOD].value ===
      PERFORMANCE_CHART_DEFAULT_PERIOD.value;
  }
}
