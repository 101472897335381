import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TTokenPreferenceAuthenticator } from '@core/layout/header/modal-token-config/types';
import { RestService } from '@shared/services/rest/rest.service';
import { Observable, catchError, map, of } from 'rxjs';
import { InvestorData } from './models/class/investor-data.class';
import { IUserServiceResponse } from './models/interface/user-service-response.interface';
import { UserDetails } from './models/class/user-details.class';

@Injectable({
  providedIn: 'root',
})
export class UserService extends RestService {
  override _url: string = 'api/trademap';

  public static readonly defaultUserPicturePath: string =
    'assets/user-profile.png';

  constructor(http: HttpClient) {
    super(http);
  }

  getInfos = () =>
    this.get<any>('v1/user-details/info').pipe(
      map(
        (res: any) =>
          new UserDetails(
            res.data.uuid,
            res.data.userId,
            res.data.externalId,
            res.data.name,
            res.data.nickname,
            res.data.biography,
            res.data.picture,
            res.data.verified,
            res.data.roleName,
            res.data.canCreateExclusive,
            res.data.amISubscribed,
            res.data.defaulter
          )
      )
    );

  getInvestorData = () =>
    this.get('v1/portal/get-investor-data').pipe(
      map((res: any) => {
        const { data } = res;
        const investor = data.result[0];
        return new InvestorData(
          investor.cnpj_cpf,
          investor.document_type,
          investor.dt_birthday,
          investor.email,
          investor.name,
          investor.phone_mobile,
          investor.username
        ).getInvestorData();
      })
    );

  updateFieldByToken = (param: {
    field: string;
    value: string;
    password: string;
    token: string;
    cdOrigin: TTokenPreferenceAuthenticator;
  }): Observable<IUserServiceResponse> => {
    return this.post('v1/portal/do-update-by-token', { ...param }).pipe(
      map((res: any) => {
        if (!res.data || !res.data.success)
          return this._handleServiceError(res);
        return { isError: false, message: '' };
      }),
      catchError((error) => of(this._handleServiceError(error)))
    );
  };

  updateInfoRequest = (params: {
    field: string;
    value: string;
    password: string;
  }): Observable<{
    next_interaction: number;
    message: string;
    isError: boolean;
  }> => {
    return this.post('v1/portal/update-info-request', { ...params }).pipe(
      map((res: any) => {
        if (!res.data || !res.data.success) throw res;
        return res.data.result;
      })
    );
  };

  checkPhoneUpdatePinCode = (param: {
    field: string;
    pincode: string;
    value: string;
  }): Observable<IUserServiceResponse> => {
    return this.post('v1/portal/check-request-pincode', { ...param }).pipe(
      map((res: any) => {
        if (!res.data || !res.data.success)
          return this._handleServiceError(res);
        return { isError: false, message: res.data.result.next_interaction };
      }),
      catchError((error) => of(this._handleServiceError(error)))
    );
  };

  private _handleServiceError(res: any): IUserServiceResponse {
    let message = res.error ?? res.message;
    const code = res.errorCode ?? 'ERROR';
    message.includes('No eligible members for the call') &&
      (message = 'Houve um erro inesperado');
    return { isError: true, message, code };
  }
}
