import { TIGER_INDICATORS_MENU_ENUM } from '../../enum/tiger-chart.enum';
import MenuItemBase from './menu-item-base';

export default class Copy extends MenuItemBase {
  constructor() {
    super();
    this.label = 'Copiar';
    this.id = TIGER_INDICATORS_MENU_ENUM.COPY;
  }

  override onCLick(): void {}
}
