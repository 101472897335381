import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '@shared/services/rest';
import { Observable, catchError, map, of } from 'rxjs';
import {
  IServiceResponse,
  IRentInfos,
  IResponse,
} from './interface/loan-balance.interface';
import { Dictionary } from '@core/models';
import { ILoanBalanceService } from '@shared/components/rent/interface/rent.interface';
import { RENT_PERIOD } from '@shared/components/rent/enum/rent.enum';
@Injectable({
  providedIn: 'root',
})
export class LoanBalanceService extends RestService {
  override _url: string = 'api/trademap/v6/loanBalance';

  private _rentGridDict: Dictionary<IRentInfos> = new Dictionary<IRentInfos>();
  private _rentBalanceDict: Dictionary<IRentInfos> =
    new Dictionary<IRentInfos>();

  constructor(http: HttpClient) {
    super(http);
  }

  public getRentTable(): Observable<IResponse<IRentInfos[]>> {
    const rentsInDict = this._rentGridDict.values();
    if (rentsInDict && rentsInDict.length)
      return of(this._returnDataInDict(rentsInDict));

    return this.post<IResponse<IRentInfos[]>>('get-rent-table', {
      cd_stocks: null,
      order_by: 'rent_mcap',
      period: null,
      limit: 300,
    }).pipe(
      catchError((error) => of(this.handlerServiceError(error))),
      map((response) => {
        if (!response?.data?.success) throw response;
        this._rentGridDict.bulkData('id_stock', response.data.result);
        return response.data;
      })
    );
  }

  public getStockBalance(
    cdStock: string,
    period: RENT_PERIOD = RENT_PERIOD.one_year
  ): Observable<IResponse<ILoanBalanceService>> {
    const key = `${cdStock}_${period}`;
    if (this._rentBalanceDict.has(key))
      return of(this._returnDataInDict(this._rentBalanceDict.get(key)));

    return this.post<IServiceResponse<ILoanBalanceService>>(
      'get-loan-balance',
      {
        cdStock: cdStock,
        idExchange: 1,
        period,
      }
    ).pipe(
      catchError((error) => of(this.handlerServiceError(error))),
      map((response) => {
        if (!response?.data?.success) throw response;
        this._rentBalanceDict.set(key, response.data.result);
        return response.data;
      })
    );
  }
}
