import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';
import { STATIC_COLORS } from './static-color-picker.const';
import { CommonModule } from '@angular/common';
import { IStaticColor } from './static-color-picker.interface';

@Component({
  selector: 'app-static-color-picker',
  templateUrl: './static-color-picker.component.html',
  styleUrls: ['./static-color-picker.component.scss'],
  standalone: true,
  imports: [CommonModule, RTDropdownModule],
})
export class StaticColorPickerComponent {
  @Input() css: string = '';
  @Input() color: string = '';
  @Output() selectedColor = new EventEmitter<IStaticColor>();
  @Output() isOpenClose = new EventEmitter<boolean>();
  readonly staticColors = STATIC_COLORS;

  selectColor(color: string) {
    this.selectedColor.emit({ color });
  }
}
