import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  IBrokerage,
  IBrokerageDetail,
} from '../interface/ranking-broker.interface';
import { Dictionary } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class RankingBrokerService {
  private _donutDataDict = new Dictionary<IBrokerage[]>();
  private _explodedBrokerInfos = new Dictionary<IBrokerageDetail[]>();
  public selectAllBrokers$ = new BehaviorSubject<{
    ref: string;
    selectedAll: boolean;
  }>({ ref: '', selectedAll: false });
  public viewTypeSelected = '';

  public setDonutDataStock(
    cdStock: string,
    field: 'percentage_buy' | 'percentage_sell',
    data: IBrokerage[]
  ): void {
    const key = `${cdStock}_${field}`;
    this._donutDataDict.set(key, data);
  }

  public getDonutDataStock(
    cdStock: string,
    field: 'percentage_buy' | 'percentage_sell'
  ): IBrokerage[] | null {
    const key = `${cdStock}_${field}`;
    if (this._donutDataDict.has(key)) return this._donutDataDict.get(key)!;
    return null;
  }

  public setExplodedBrokerData(
    nmBroker: string,
    field: 'percentage_buy' | 'percentage_sell',
    data: IBrokerageDetail[]
  ): void {
    const key = `${nmBroker}_${field}`;
    this._explodedBrokerInfos.set(key, data);
  }

  public getExplodedBrokerData(
    nmBroker: string,
    field: 'percentage_buy' | 'percentage_sell'
  ): IBrokerageDetail[] | null {
    const key = `${nmBroker}_${field}`;
    if (this._explodedBrokerInfos.has(key))
      return this._explodedBrokerInfos.get(key)!;
    return null;
  }
}
