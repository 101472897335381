import {
  STOCK_CHART_COMPONENT,
  STOCK_TABLE_COMPONENT,
  SUPER_DOM_COMPONENT,
} from '@core/workspace/constants';
import { LayoutBase } from '../base';
import { AT_PRICE_COMPONENT } from '@core/workspace/constants/components/at-price';

export class Pro extends LayoutBase {
  override name = 'Pro';
  isSmallScreen = window.innerWidth < 1440;

  override components = [
    STOCK_TABLE_COMPONENT({
      width: 19,
      height: 100,
    }),
    STOCK_CHART_COMPONENT({
      left: 'calc(19% + 4px)',
      width: this.isSmallScreen ? 38 : 36.5,
      height: 51,
    }),
    AT_PRICE_COMPONENT({
      left: `calc(${this.isSmallScreen ? 57 : 55.5}% + 8px)`,
      width: 21,
      height: 51,
    }),
    STOCK_CHART_COMPONENT({
      left: 'calc(19% + 4px)',
      top: 'calc(52%)',
      width: 57.5,
      height: 48,
    }),
    SUPER_DOM_COMPONENT({
      left: 'calc(100% - 22.8%)',
      width: 22.8,
      height: 100,
    }),
  ];
}
