import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  JoystickService,
  JOYSTICK_DEF,
} from '@shared/services/joystick.service';
import { BrokerService } from '@services/api/trademap/v1/broker.service';
import { MultibrokerService } from '@services/core/multibroker/multibroker.service';
import { Subscription, filter, map, take, tap } from 'rxjs';
import { IAccountSelect, IBroker } from 'src/app/core/interface';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { AccountPreferences } from '@shared/services/core/custom-preferences/account/account-preferences.services';
import { InitializeBrokerConnectService } from '@shared/services/initialize-broker-connect.service';
import { BrokerAccountModel, Dictionary } from '@core/models';
import { DaytradeSelectorComponent } from '../daytrade-selector/daytrade-selector.component';
import { OrderChannel } from '@shared/channel/order.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { system } from '@core/system/system.service';
import { CustodyChannel } from '@shared/channel/custody.channel';
import { RocketModalService } from '@shared/rocket-components/components';
import { ModalBrokersToConnectComponent } from '@modules/broker-connection/modals/modal-brokers-to-connect/modal-brokers-to-connect.component';
import { OrdersService } from '@shared/services/orders.service';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { isSimulatorOrLeague } from '@shared/constants/simulator-league.constant';
import { MarketDispatcher } from '@shared/cheetah/dispatcher/market.dispatcher';
import { isIdBrokerSimulator } from '../../constants/simulator-league.constant';
import {
  CheetahChannelState,
  STATE,
} from '@shared/channel/base/cheetah-channel.base';
import { AccountSelectorService } from '@shared/services/account-selector.service';
import { TradeTokenPopupService } from '@core/layout/header/trade-token-popup/trade-token-popup.service';
import { AccountSelectService } from './service/account-select.service';
import { RocketStreamRead } from '@shared/channel/rx-event';

@Component({
  selector: 'app-account-selector',
  templateUrl: './account-selector.component.html',
  styleUrls: ['./account-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSelectorComponent
  extends ReadStreamBase
  implements OnInit, OnDestroy
{
  @ViewChild('daytradeSelector') daytradeSelector!: DaytradeSelectorComponent;
  private untilDestroy$ = new Subscription();
  private showAccountSelector$ = new Subscription();
  private onChangeState$!: Subscription;
  public accounts: IAccountSelect[] | any = [];
  public accountSelected!: IAccountSelect | any;
  public isSeletorAccount = false;
  public simulator: any = {
    show: false,
    enabled: false,
    icon: 'videogame_asset_off',
    class: 'text-neutral-medium',
    tooltip: 'Simulador Desativado',
  };
  custodyReady: boolean = false;
  initial: boolean = true;
  showSimulator: boolean = false;
  showComponent: boolean = false;
  showTradeMode: boolean = false;
  displayConnectBroker: boolean = false;
  orderParams!: SubscribeParam;
  orderChannelStream: RocketStreamRead | undefined;
  custodyParams!: SubscribeParam;
  private allBrokers!: Dictionary<IBroker>;
  private isInitCheetah: boolean = false;
  public simulatorTooltip = '';
  public connectBrokersTooltip = `
  <span class="fs-6">Para operar no mercado real e ter a experiência completa, veja as suas corretoras parceiras disponíveis.</span>
  `;

  private get keyChannel() {
    return `${system.idInvestor}.${this.accountSelected.id_broker}`;
  }

  private _accountSelectService = inject(AccountSelectService);
  constructor(
    private _mbService: MultibrokerService,
    private brokerService: BrokerService,
    private _joystick: JoystickService,
    private accPreferences: AccountPreferences,
    private cdr: ChangeDetectorRef,
    private initializeBrokerConnectService: InitializeBrokerConnectService,
    private orderChannel: OrderChannel,
    private custodyChannel: CustodyChannel,
    private _modalService: RocketModalService,
    private _ordersService: OrdersService,
    private _tradeTokenPopupService: TradeTokenPopupService,
    private marketDispatcher: MarketDispatcher,
    private accountSelectorService: AccountSelectorService
  ) {
    super();
  }

  ngOnInit() {
    this.onChangeState$ = this.marketDispatcher
      .onChangeState()
      .pipe(
        filter((state: CheetahChannelState) => state.value != STATE.ERROR),
        take(1)
      )
      .subscribe(() => {
        this.isInitCheetah = true;
        this.subscribeCustody();
      });
    this.showAccountSelector$ =
      this.initializeBrokerConnectService.initializeBroker$.subscribe(
        (info) => {
          this.showSimulator = this._joystick.visibilitySimulatorMenu;
          this.simulator = this.accPreferences.simulatorSelected
            ? JOYSTICK_DEF.ON
            : JOYSTICK_DEF.OFF;
          this.simulatorTooltip =
            this._accountSelectService.getSimulatorTooltip(
              this.simulator.enabled
            );
          this.showComponent = info;
          this.accountSelected = this._mbService.getAccountSelected();
          this.accountSelected &&
            this.accountSelectorService.getAllOrders(this.accountSelected);
          this.brokerService
            .processBrokers()
            .pipe(
              tap((brokers: Dictionary<IBroker>) => {
                this.allBrokers = brokers;
              }),
              filter((brokers: Dictionary<IBroker>) => {
                if (
                  isNullOrUndefined(this.accountSelected) &&
                  this.accPreferences.simulatorSelected
                ) {
                  this._useSimulatorAccount(brokers.get(23)!);
                  return false;
                }
                return true;
              }),
              tap(() => {
                if (!this.accPreferences.simulatorSelected) {
                  const brokers = this._mbService.filterMultibrokerAccounts();
                  this.accounts = brokers;
                  this._verifyBrokerSelected(brokers);
                }
                this.daytradeSelector?.accountChanged();
                this.processInvestorBrokerAccounts();
              })
            )
            .subscribe();
          this._joyStickObservables();
        }
      );
  }

  ngOnDestroy(): void {
    this.untilDestroy$.unsubscribe();
    this.showAccountSelector$ && this.showAccountSelector$.unsubscribe();
    this.onChangeState$ && this.onChangeState$.unsubscribe();
    this.custodyParams && this.custodyChannel.unsubscribe(this.custodyParams);
    this.orderParams && this.orderChannel.unsubscribe(this.orderParams);
  }

  private _joyStickObservables(): void {
    const _simulatorVisibility = this._joystick
      .changesVisibilityMenuJoistick()
      .pipe(
        filter(
          (joystickVisibility: boolean) =>
            !isNullOrUndefined(joystickVisibility) &&
            joystickVisibility !== this.showSimulator
        )
      )
      .subscribe(this.handleVisibility);

    const refreshAccountObservable = this._mbService.refreshAccounts$.subscribe(
      (data) => {
        if (data) {
          const idx = this.accounts.findIndex(
            (item: any) => data.id_broker === item.id_broker
          );
          delete this.accounts[idx];
        }
      }
    );
    const accountObservable = this._mbService.account$
      .pipe(
        filter(
          (data) =>
            data?.id_broker &&
            this.accountSelected?.id_broker &&
            data.id_broker !== this.accountSelected.id_broker
        )
      )
      .subscribe((data) => {
        this.selectAccount(data, false);
      });
    this.untilDestroy$.add(accountObservable);
    this.untilDestroy$.add(refreshAccountObservable);
    this.untilDestroy$.add(_simulatorVisibility);
  }

  public selectAccount(account: IAccountSelect | any, isInit: boolean = false) {
    if (!account || (account == this.accountSelected && !isInit)) return;
    this.accountSelected = account;
    this.processInvestorBrokerAccounts();
    this.accountSelected &&
      this.accountSelectorService.getAllOrders(this.accountSelected);
    this.subscribeCustody();
    this.cdr.detectChanges();
  }

  public processInvestorBrokerAccounts() {
    this.brokerService
      .getInvestorBrokerAccounts(this.accountSelected.id_broker)
      .pipe(
        map((data: any) => data.map((item: any) => item.account_number)),
        tap((numbers: any) => {
          this.accountSelected.accounts = numbers;
          this.accountSelected.account_number = numbers[0];
          this.isSeletorAccount = numbers.length > 1;
          this.displayConnectBroker = !numbers?.length;
          this.cdr.detectChanges();
        })
      )
      .subscribe(() => {
        this._mbService.updateAccountSelected(this.accountSelected);
        this.accountSelectorService.saveAccountSelectedInDB(
          this.accountSelected
        );
        this.cdr.detectChanges();
      });
  }

  public selectAccountWhenMany(accountNumber: string): void {
    this.accountSelected.account_number = accountNumber;
    this.accountSelectorService.saveAccountSelectedInDB(this.accountSelected);
    this.cdr.detectChanges();
  }

  public changeSimulator(): void {
    if (!this.custodyReady && !this.displayConnectBroker) return;
    const simulatorEnabled = !this._joystick.isSimulator;
    this.simulatorTooltip =
      this._accountSelectService.getSimulatorTooltip(simulatorEnabled);
    this.custodyReady = false;
    this.selectBrokerAccount(simulatorEnabled);
    this.cdr.detectChanges();
  }

  private selectBrokerAccount = (simulatorEnabled: boolean) => {
    this._joystick.simulator = simulatorEnabled;
    this.simulator = simulatorEnabled ? JOYSTICK_DEF.ON : JOYSTICK_DEF.OFF;
    if (simulatorEnabled) {
      this.selectSimulatorEnabled();
      return;
    }
    this.selectAccountByBroker();
  };

  private selectAccountByBroker(): void {
    const filteredAccounts = this._mbService.filterMultibrokerAccounts();
    this.accounts = filteredAccounts;
    const accountSelected: any = this.accPreferences.multiBrokerAccountSelected;
    if (accountSelected && !isIdBrokerSimulator(accountSelected['id_broker'])) {
      this.selectAccount(accountSelected);
      return;
    }
    if (!accountSelected && filteredAccounts?.length) {
      this.selectAccount(filteredAccounts[0]);
      return;
    }
    this.accounts = [];
    this.accountSelected = { id_broker: -1 };
    this.displayConnectBroker = true;
    this.accountSelectorService.saveAccountSelectedInDB(this.accountSelected);
    this.cdr.detectChanges();
  }

  private selectSimulatorEnabled(): void {
    let selectedAccount = this.accPreferences
      .simulatorAccountSelected as unknown as IAccountSelect;
    if (
      selectedAccount &&
      this.allBrokers &&
      !isSimulatorOrLeague(
        (
          this.accPreferences
            .simulatorAccountSelected as unknown as IAccountSelect
        ).id_broker
      )
    ) {
      selectedAccount = this.allBrokers.get(23) as unknown as IAccountSelect;
      this.accPreferences.simulatorAccountSelected =
        JSON.stringify(selectedAccount);
    }
    this.displayConnectBroker = false;
    this.selectAccount(selectedAccount);
    this.accounts = [
      this._mbService.brokerAccount.get(this.accountSelected.id_broker),
    ];
  }

  private handleVisibility = (data: boolean): void => {
    this.showSimulator = data;
    this.selectBrokerAccount(this.showSimulator && this._joystick.isSimulator);
    this.cdr.detectChanges();
  };

  private _useSimulatorAccount(broker: IBroker): void {
    this.selectAccount(broker, true);
    this._joystick.simulator = true;
    this.accounts = [this._mbService.brokerAccount.get(broker.id_broker)];
    this.cdr.detectChanges();
  }

  private subscribeCustody() {
    if (!this.isInitCheetah) return;
    this.custodyChannel.onCustodyReady().subscribe(() => {
      this.custodyReady = true;
      this.cdr.detectChanges();
    });
    this.accountSelectorService.subscribeCustodyChannel(this.accountSelected);
  }

  public openModalConnectBrokers(): void {
    this._modalService.open(ModalBrokersToConnectComponent, {
      size: 'xl',
      centered: true,
      backdrop: true,
      keyboard: false,
      scrollable: false,
    });
  }

  private _verifyBrokerSelected(brokers: BrokerAccountModel[]): void {
    if (!brokers.length) {
      this.accountSelected = { id_broker: -1, accounts: [] };
      this.displayConnectBroker = true;
      this.cdr.detectChanges();
      return;
    }

    if (
      !this.accountSelected.id_broker ||
      !brokers.find(
        (broker: BrokerAccountModel) =>
          broker.id_broker === this.accountSelected.id_broker
      )
    ) {
      this.accountSelected = brokers[0];
      this.processInvestorBrokerAccounts();
      this.accountSelectorService.saveAccountSelectedInDB(this.accountSelected);
      this.cdr.detectChanges();
    }
  }
}
