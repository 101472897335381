import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  RocketModalRef,
  RocketModalService,
  ROCKET_MODAL_DATA,
} from '@shared/rocket-components/components/index';
import { TIGER_TYPE_CHART_ENUM } from '@shared/tiger-chart/enum/tiger-chart.enum';
import { ICandleConfigs } from '@shared/tiger-chart/interface/tiger-chart.interface';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { CHART_MODAL_CONFIGS_TAB_NAME } from '../../enum/stock-chart.enum';
import {
  IModalCandleConfigsClose,
  IStockChartConfig,
} from '../../interface/stock-chart.interface';
import { StockChartService } from '../../service/stock-chart.service';
import { StockChartModalConfigCandleComponent } from './candle/stock-chart-modal-config-candle.component';
import { StockChartModalConfigVolumeComponent } from './volume/stock-chart-modal-config-volume.component';
import { StockChartModalService } from './service/stock-chart-modal.service';
import {
  FROM_TYPES,
  INPUT_TYPES,
  TYPE_CHART_UPDATE,
} from './enum/stock-chart-modal.enum';
import { FormBuilder, FormGroup } from '@angular/forms';
import { deepClone } from '@shared/rocket-components/utils';
import { GlobalChartConfiguration } from '../modal-stock/global-chart-configuration';
import { TFooterType } from '@shared/tiger-chart/tiger-chart-footer/tiger-chart-footer.enum';
import { INPUT, ITab } from './interface/stock-chart-modal.interface';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { DEFAULT_MAX } from '@shared/rocket-components/input-count/input-count.const';
import { StockChartModalConfigLinesComponent } from './lines/stock-chart-modal-config-lines.component';
import { LINE_OPTIONS } from './const/stock-chart-modal-lines.config.const';
import { ChartLinesConfiguration } from '../modal-stock/global-chart-line-configuration';
@Component({
  selector: 'app-stock-chart-modal-more-options',
  templateUrl: './stock-chart-modal-more-options.component.html',
  styleUrls: ['./stock-chart-modal-more-options.component.scss'],
})
export class StockChartModalMoreOptionsComponent
  extends RocketModalRef<IModalCandleConfigsClose>
  implements OnInit, AfterViewInit, OnDestroy
{
  maxInput = DEFAULT_MAX;
  shortcuts!: INPUT[];
  negotiations!: INPUT[];
  events!: INPUT[];
  styles!: INPUT[];
  inputTypes = INPUT_TYPES;
  typeChartEnum = TIGER_TYPE_CHART_ENUM;
  tabNameEnum = CHART_MODAL_CONFIGS_TAB_NAME;
  tabName: CHART_MODAL_CONFIGS_TAB_NAME = this.tabNameEnum.ESTILO;
  @ViewChild('configCandle', { static: false })
  configCandle!: StockChartModalConfigCandleComponent;
  @ViewChild('configVolume', { static: false })
  configVolume!: StockChartModalConfigVolumeComponent;
  tabHeaders!: ITab[];
  private _configs!: {
    ref: string;
    config: Partial<ICandleConfigs>;
    save: boolean;
  };
  reset: boolean = false;
  private sub: Subscription[] = [];
  private defaultConfiguration!: GlobalChartConfiguration;
  private defaultLineConfiguration!: ChartLinesConfiguration;
  private defaultConfigChart!: Partial<ICandleConfigs>;
  private defaultChartContextMenu!: any;
  private defaultChartTradingOffset!: number;
  private resetColors!: GlobalChartConfiguration;
  formShortcut!: FormGroup;
  formNegotiation!: FormGroup;
  formEvents!: FormGroup;
  formStyles!: FormGroup;

  get formValues() {
    return {
      ...this.formShortcut.getRawValue(),
      ...this.formNegotiation.getRawValue(),
      ...this.formStyles.getRawValue(),
      ...this.formEvents.getRawValue(),
    };
  }

  onDestroy$ = new Subject<void>()
  constructor(
    @Inject(ROCKET_MODAL_DATA)
    public data: {
      config: IStockChartConfig;
      ref: string;
      isSecondModal: boolean;
    },
    private _cdr: ChangeDetectorRef,
    private _stockService: StockChartService,
    private stockChartModalService: StockChartModalService,
    private _customPreferencesService: CustomPreferencesService,
    private _fb: FormBuilder,
    private _service: RocketModalService,
    private _elementRef: ElementRef
  ) {
    super(_service);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.onDestroy$.next()
    this.onDestroy$.complete()
    this.sub.forEach((s) => {
      s.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.initializeInputForms();
    this.initializeTabs();
    this.defaultConfiguration = deepClone(
      this.stockChartModalService.getGlobalChartConfigs()
    );
    this.defaultChartTradingOffset = deepClone(
      this._customPreferencesService.tradingOffset
    );
    this.defaultConfigChart = deepClone(this.data.config.chart);
    this.defaultLineConfiguration = deepClone(
      this.stockChartModalService.getChartLinesConfigs()
    )
    this.defaultChartContextMenu = JSON.parse(
      deepClone(this._customPreferencesService.chartContextMenu)
    );
    this.sub.push(
      this._stockService.eventConfigs$.subscribe((res) => {
        if (res.ref === this.data.ref) {
          this.data.config.chart = Object.assign(
            this.data.config.chart,
            res.config
          );
          this._configs = res;
        }
      })
    );
    this.formShortcut = this._fb.group(this.buildForm(this.shortcuts));
    this.formNegotiation = this._fb.group(this.buildForm(this.negotiations));
    this.formEvents = this._fb.group(this.buildForm(this.events));
    this.formStyles = this._fb.group(this.buildForm(this.styles));
    this._cdr.detectChanges()
    this.outsideEvent(this.unSaveConfig);
  }

  private initializeInputForms() {
    const { events, negotiations, shortcuts, styles } =
      this.stockChartModalService.getInputForms();
    this.shortcuts = shortcuts;
    this.negotiations = negotiations;
    this.events = events;
    this.styles = styles;
  }

  private initializeTabs() {
    this.tabHeaders = this.stockChartModalService.getModalTabs();
  }

  private buildForm(form: INPUT[]) {
    const controls: any = {};
    const configuration =
      this.stockChartModalService.getGlobalChartConfigs() as any;
    const hashFrom: any = {
      [FROM_TYPES.chart]: this.data.config.chart as any,
      [FROM_TYPES.chart_fastOrder]: this.data.config.chart.fastOrder as any,
      [FROM_TYPES.configuration]: configuration,
      [FROM_TYPES.customPreferences]: this._customPreferencesService as any,
    };
    form.forEach((input) => {
      controls[input.formControlName] = this.validateFormInput(input, configuration, hashFrom)
      if(input.children) {
        input.children.forEach(childInput => {
          controls[childInput.formControlName] = this.validateFormInput(childInput, configuration, hashFrom)
        })
      }
    });
    return controls;
  }

  ngAfterViewInit(): void {
    this.styles.forEach((style) => {
      this.updateChildrenInput(style);
    });
    this._cdr.detectChanges();
  }

  onClose(save: boolean) {
    if (save) {
      if (this.reset) {
        this._customPreferencesService.chartGlobalConfiguration =
          JSON.stringify(this.resetColors);
      }
      this.close(
        {
          save,
          tabName: this.tabName,
          configs: this.data.config.chart,
        },
        this.data.isSecondModal
      );
      return;
    }
    this.resetChartLinesConfig()
    this.unSaveConfig();
  }

  private validateFormInput = (input: INPUT, configuration: any, hashFrom: any) => {
    if (input.cod === TFooterType.CHART_BOOK) {
      const isBook = configuration.showChartBook;
      const isVolumeAtPrice = configuration.showChartVolumeAtPrice;
      const options = input.options!;
      const selected = isBook
        ? options[0].cod
        : isVolumeAtPrice
        ? options[1].cod
        : options[2].cod;
      return selected;
    } else {
      if (input.cod === TFooterType.SHOW_NAVIGATION_CONTROLS) {
        const ty =
          !hashFrom[input.from] ||
          typeof hashFrom[input.from][input.formControlName] === 'boolean';
        const valueInput = ty
          ? 'ONLY_HOVER'
          : hashFrom[input.from][input.formControlName];
        return valueInput;
      } else {
        return hashFrom[input.from]
          ? hashFrom[input.from][input.formControlName] ?? input.defaultValue
          : input.defaultValue;
      }
    }
  }

  private unSaveConfig = () => {
    this.close(
      {
        closed: true,
        defaultConfiguration: {
          ...this.defaultConfiguration,
          chartTradingOffset: this.defaultChartTradingOffset,
          showFastOrder: this.defaultConfigChart.fastOrder?.showFastOrder,
          showEvents: this.defaultConfigChart.showEvents ?? true,
          showVolume: this.defaultChartContextMenu?.volumeVisibility ?? false,
          showExecOrders: this.defaultConfigChart.showExecOrders ?? true,
          showFlagOrders:
            this.defaultConfigChart.showFlagOrders ??
            this.defaultConfigChart.showExecOrders,
        },
      },
      this.data.isSecondModal
    );
  };

  tabChanges(value: string) {
    this.tabName = value as CHART_MODAL_CONFIGS_TAB_NAME;
    if(this.tabName === this.tabNameEnum.ATALHOS) {
      this.shortcuts.forEach((shortcut) => {
        this.updateChildrenInput(shortcut);
      });
    }
    this._cdr.detectChanges();
  }

  resetConfig() {
    if (this.tabName === this.tabNameEnum.ESTILO) {
      this.reset = true;
      this.resetColors = this.stockChartModalService.resetColors();
      const candleFields = [
        'colorsVelaDown',
        'colorsVelaUp',
        'colorsBorderDown',
        'colorsBorderUp',
      ];
      const volumeFields = ['volumeUpColor', 'volumeDownColor'];
      this.updateForm(candleFields, this.configCandle.form, this.resetColors);
      this.updateForm(volumeFields, this.configVolume.form, this.resetColors);
      this.configCandle.updateAllElements();
      this.configVolume.updateAllElements();
      this._stockService.eventConfigs$.next({
        ref: this.data.ref,
        config: {
          ...this.data.config.chart,
          ...{
            colorsVelaDown: this.resetColors.colorsVelaDown,
            colorsVelaUp: this.resetColors.colorsVelaUp,
            colorsBorderDown: this.resetColors.colorsBorderDown,
            colorsBorderUp: this.resetColors.colorsBorderUp,
          },
        },
        save: false,
        global: true,
        typeUpdate: TYPE_CHART_UPDATE.CANDLE_COLOR,
      });
      this._stockService.eventConfigs$.next({
        ref: this.data.ref,
        config: {
          ...this.data.config.chart,
          ...{
            volumeUpColor: this.resetColors.volumeUpColor,
            volumeDownColor: this.resetColors.volumeDownColor,
          },
        },
        save: false,
        global: true,
        typeUpdate: TYPE_CHART_UPDATE.VOLUME_COLOR,
      });
      this.updateChartLines()
    }
  }

  updateForm(
    fields: string[],
    form: FormGroup,
    configuration: GlobalChartConfiguration
  ) {
    fields.forEach((field) => {
      form.controls[field].setValue((configuration as any)[field]);
    });
  }

  updateShortcut(shortcut: INPUT) {
    if (shortcut.notGlobal) {
      if (this.tabName === this.tabNameEnum.NEGOCIACAO) {
        this._customPreferencesService.useSplit =
          this.formNegotiation.get('useSplit')?.value;
        this._customPreferencesService.saveQttyGroupStocks =
          this.formNegotiation.get('saveQttyGroupStocks')?.value;
      }
      return;
    }
    this.updateChildrenInput(shortcut);
    if (shortcut.saveGlobalChartConfig) {
      this.stockChartModalService.updateGlobalChartConfigs({
        [shortcut.formControlName]: this.formValues[shortcut.formControlName],
      });
    }
    this._stockService.eventConfigs$.next({
      ref: this.data.ref,
      config: this.formValues,
      save: false,
      global: true,
      typeUpdate: shortcut.chartUpdate,
    });
  }

  updateChartLines() {
    const children = new ChartLinesConfiguration()
    LINE_OPTIONS.forEach((input) => {
      this._stockService.eventConfigs$.next({
        ref: this.data.ref,
        config: children,
        save: false,
        global: true,
        typeUpdate: input.chartUpdate,
      });
    })
    this.stockChartModalService.updateChartLinesConfigs(children)
  }

  openChartLinesOptionsModal = () => {
    this._service.open(StockChartModalConfigLinesComponent, {
      keyboard: false,
      backdrop: true,
      toggleBetweenModal: this._elementRef,
      data: {
        config: this.data.config,
        ref: this.data.ref,
        isSecondModal: true,
      },
    }).afterDismissed.pipe(takeUntil(this.onDestroy$)).subscribe((modalSaved: boolean) => {
      if(modalSaved){
        this.defaultConfiguration = deepClone(
          this.stockChartModalService.getGlobalChartConfigs()
        );
      }
      this.data.isSecondModal = false
    })
  }

  private updateChildrenInput(shortcut: INPUT) {
    if (!shortcut.children) return;
    switch (this.tabName) {
      case this.tabNameEnum.ATALHOS: {
        const value = this.formShortcut.get(shortcut.formControlName)?.value;
        shortcut.children.map((item) => {
          const input = item
          if (input) {
            input.isDisable = !value;
            this._cdr.detectChanges();
          }
          return input
        });
        break;
      }
      case this.tabNameEnum.ESTILO: {
        if (shortcut.cod == TFooterType.WATER_MARK) {
          const value = this.formStyles.get(shortcut.formControlName)?.value;
          shortcut.children.forEach((item) => {
            const input = item
            if (input) {
              input.isDisable = !value;
              this._cdr.detectChanges();
            }
          });
          break;
        }
      }
    }
  }
  
  private resetChartLinesConfig() {
    this.stockChartModalService.updateChartLinesConfigs(this.defaultLineConfiguration)
    LINE_OPTIONS.forEach((input) => {
      this._stockService.eventConfigs$.next({
        ref: this.data.ref,
        config: {
          [input.formControlName]: (this.defaultLineConfiguration as any)[input.formControlName]
        },
        save: false,
        global: true,
        typeUpdate: input.chartUpdate,
      });
    })
  }
}
