<workspace-doc-header
  css="w-100 d-flex header-wrapper border-bottom"
  [item]="component"
>
  <div
    class="d-flex justify-content-between w-100 hstack"
    [ngClass]="{ 'desktop-spacing': showSelectWindow }"
  >
    <fla-workspace-doc-header-item [enableContent]="true" [hideBorder]="true">
      <section class="hstack">
        <a
          *ngFor="let header of headers"
          type="button"
          class="nav-link p-2 lh-sm border-right fw-bolder text-center col-7"
          [ngClass]="{
            'text-brand-primary': header.active,
            'text-neutral-medium ': !header.active
          }"
          (click)="selectView(header.code)"
        >
          {{ header.label }}
        </a>
      </section>
    </fla-workspace-doc-header-item>
    <fla-workspace-doc-header-item [enableContent]="true" [hideBorder]="true">
      <div [ngClass]="{ 'd-none': isExplode }" class="d-flex hstack">
        <div class="position-relative">
          <app-search-stock
            #searchStock
            [headerOptions]="headerOptions"
            (selectStockChange)="selectStock($event)"
            [initStock]="stockSelected"
            [ref]="component.id"
            [searchLabel]="searchLabel"
            selectCss="stock-select-padding"
            css="border-left border-right"
          ></app-search-stock>
          <div
            class="position-absolute pr-2 right-0 top-0 cursor-pointer"
            *ngIf="stock && !statusLink"
            tooltip="Remover ativo"
          >
            <fla-icon
              name="delete"
              size="icon-size-micro"
              (click)="selectStock(false)"
            ></fla-icon>
          </div>
          <div
            class="position-absolute pr-2 right-0 top-0 cursor-pointer"
            *ngIf="statusLink"
          >
            <fla-icon
              name="delete"
              size="icon-size-micro"
              (click)="disableLink()"
            ></fla-icon>
          </div>
        </div>
        <div
          class="ml-2"
          [ngClass]="{
            'pointer-events-none opacity-medium pr-none': !enableChangePeriod
          }"
        >
          <app-ranking-broker-period
            (changePeriod)="updatePeriod($event)"
          ></app-ranking-broker-period>
        </div>
      </div>
    </fla-workspace-doc-header-item>
  </div>
</workspace-doc-header>
