import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IRentFieldsToSort } from '../../interface/rent.interface';
import { DEFAULT_SORT, FIELDS_TO_SORT } from '../../constants/rent.constant';
import { deepClone } from '@shared/rocket-components/utils';
import { IWorkSpaceComponet } from '@core/interface';

@Component({
  selector: 'app-rent-header',
  templateUrl: './rent-header.component.html',
  styleUrls: ['./rent-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RentHeaderComponent implements OnChanges {
  @Output() public fieldSortChanged: EventEmitter<IRentFieldsToSort> =
    new EventEmitter();

  @Input() public updateCurrentSortField!: string | undefined;
  @Input() public hideGridContent: boolean = false;
  @Input() component!: IWorkSpaceComponet;
  @Input() isDesktop!: boolean;

  private _activeSortIndex: number = -1;
  public sortTabs: IRentFieldsToSort[] = FIELDS_TO_SORT;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    const { updateCurrentSortField } = changes;
    if (
      updateCurrentSortField &&
      updateCurrentSortField?.previousValue !==
        updateCurrentSortField.currentValue
    ) {
      if (updateCurrentSortField.currentValue === 'cd_stock') {
        this.sortTabs = deepClone(FIELDS_TO_SORT);
        this._activeSortIndex = -1;
        return;
      }
      const tabIndex = FIELDS_TO_SORT.findIndex(
        (tab) => tab.field === updateCurrentSortField.currentValue
      );
      if (tabIndex >= 0) {
        this.handlerFieldToSort(FIELDS_TO_SORT[tabIndex], tabIndex);
        return;
      }
      this.handlerFieldToSort(DEFAULT_SORT, 5);
    }
  }

  public handlerFieldToSort(
    tab: IRentFieldsToSort,
    newActiveTabIndex: number
  ): void {
    if (newActiveTabIndex === this._activeSortIndex) return;
    const tabs = deepClone(FIELDS_TO_SORT);
    if (this._activeSortIndex >= 0) tabs[this._activeSortIndex].active = false;
    tabs[newActiveTabIndex].active = true;
    this.sortTabs = tabs;
    this._activeSortIndex = newActiveTabIndex;
    this.fieldSortChanged.emit(tab);
    this.cdr.detectChanges();
  }
}
