import { isWebViewContext } from "src/app/desktop/integration.utils";

class SharedSessionStorage implements Storage {
  private static _instance: SharedSessionStorage;
  private static readonly STORAGE_KEY = 'SHARED_SESSION_STORAGE';

  constructor() {
    if (SharedSessionStorage._instance) {
      return SharedSessionStorage._instance;
    }
    if (!localStorage[SharedSessionStorage.STORAGE_KEY]) {
      localStorage[SharedSessionStorage.STORAGE_KEY] = "{}";
    }
    return (SharedSessionStorage._instance = this);
  }

  get length(): number {
    const storage = JSON.parse(localStorage[SharedSessionStorage.STORAGE_KEY])
    return storage.length;
  }

  public clear(): void {
    localStorage[SharedSessionStorage.STORAGE_KEY] = "{}";
  }

  public key(): string | null {
    throw new Error("Method not implemented.");
  }

  /* Retorna o valor guardado ou null se nao tiver nenhum valor para a key */
  public getItem(key: string) {
    const storage = JSON.parse(localStorage[SharedSessionStorage.STORAGE_KEY]);
    const value = storage[key];
    return value ?? null;
  }

  public setItem(key: string, value: string) {
    const storage = JSON.parse(localStorage[SharedSessionStorage.STORAGE_KEY]);
    storage[key] = value;
    localStorage[SharedSessionStorage.STORAGE_KEY] = JSON.stringify(storage);
  }

  public removeItem(key: string) {
    const storage = JSON.parse(localStorage[SharedSessionStorage.STORAGE_KEY]);
    storage[key] = undefined;
    localStorage[SharedSessionStorage.STORAGE_KEY] = JSON.stringify(storage);
  }
}

export const sharedSessionStorage = isWebViewContext() ? new SharedSessionStorage() : sessionStorage;
