import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Output,
  HostListener,
  EventEmitter,
  Input,
} from '@angular/core';

import { RT_COLOR_EVENT } from '../types';
import { rgbToHex } from '@shared/rocket-components/utils';

@Component({
  selector: 'rt-color-slider',
  templateUrl: './color-slider.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class RTColorSliderComponent implements AfterViewInit {
  @ViewChild('canvas') canvas!: ElementRef<HTMLCanvasElement>;

  @Input() height: number = 180;
  @Output() color: EventEmitter<RT_COLOR_EVENT> = new EventEmitter();

  private ctx!: CanvasRenderingContext2D;
  private mousedown: boolean = false;
  private selectedHeight!: number;

  ngAfterViewInit() {
    this.draw();
  }

  draw() {
    if (!this.ctx) {
      this.ctx = this.canvas.nativeElement.getContext('2d')!;
    }
    const width = this.canvas.nativeElement.width;
    const height = this.canvas.nativeElement.height;

    this.ctx.clearRect(0, 0, width, height);

    const gradient = this.ctx.createLinearGradient(0, 0, 0, height);
    gradient.addColorStop(0, 'rgba(255, 0, 0, 1)');
    gradient.addColorStop(0.17, 'rgba(255, 255, 0, 1)');
    gradient.addColorStop(0.34, 'rgba(0, 255, 0, 1)');
    gradient.addColorStop(0.51, 'rgba(0, 255, 255, 1)');
    gradient.addColorStop(0.68, 'rgba(0, 0, 255, 1)');
    gradient.addColorStop(0.85, 'rgba(255, 0, 255, 1)');
    gradient.addColorStop(1, 'rgba(255, 0, 0, 1)');

    this.ctx.beginPath();
    this.ctx.rect(0, 0, width, height);

    this.ctx.fillStyle = gradient;
    this.ctx.fill();
    this.ctx.closePath();

    if (this.selectedHeight) {
      this.ctx.beginPath();
      this.ctx.strokeStyle = 'white';
      this.ctx.lineWidth = 5;
      this.ctx.rect(0, this.selectedHeight - 5, width, 10);
      this.ctx.stroke();
      this.ctx.closePath();
    }
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(evt: MouseEvent) {
    evt.preventDefault();
    this.mousedown = false;
    this._emitColor(evt.offsetX, evt.offsetY);
  }

  onMouseDown(evt: MouseEvent) {
    this.mousedown = true;
    this.selectedHeight = evt.offsetY;
    this.draw();
    this._emitColor(evt.offsetX, evt.offsetY);
  }

  onMouseMove(evt: MouseEvent) {
    if (this.mousedown) {
      this.selectedHeight = evt.offsetY;
      this.draw();
      this._emitColor(evt.offsetX, evt.offsetY);
    }
  }

  private _emitColor(x: number, y: number) {
    this.color.emit(this._getColorAtPosition(x, y));
  }

  private _getColorAtPosition(x: number, y: number): RT_COLOR_EVENT {
    const imageData = this.ctx.getImageData(x, y, 1, 1).data;
    return {
      rgb:
        'rgb(' + imageData[0] + ',' + imageData[1] + ',' + imageData[2] + ',1)',
      hex: rgbToHex(imageData[0], imageData[1], imageData[2]),
    };
  }
}
