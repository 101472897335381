import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
  HostListener,
  OnDestroy,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { deepClone, formatterNumber } from '@shared/rocket-components/utils';
import { SuperDomService } from '../../service/super-dom.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MiniBookChannel } from '@shared/channel/minibook.channel';
import {
  TYPE_ORDE_SIDE_ENUM,
  TYPE_ORDE_SIDE_STRING_ENUM,
} from '@shared/enum/buyOrSell.enum';
import { ToastService } from '@services/toast.service';
import {
  isDoubleStartStop,
  isStartStop,
  isStockFuture,
} from '@shared/constants/general.contant';
import { TYPE_ORDE_ENUM } from '@shared/components/stock-trade/enum/stock-trade.enum';
import { IMultibrokerBar, IOrders } from 'src/app/core/interface';
import { EditingOrder } from './parts/editing-order';
import { MountingLineOrders } from './parts/mounting-line-orders';
import { OrdersService } from '@shared/services/orders.service';
import { VolumeChannel } from '@shared/channel/volume.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import {
  formatPrice,
  getTextByRegex,
  isNullOrUndefined,
  buildBookInfo,
  createArrayIndex,
} from 'src/app/utils/utils.functions';
import { StockPreferencesService } from '@shared/services/stock-preferences.service';
import { Subject, Subscription, auditTime, filter, map, takeUntil } from 'rxjs';
import { SUPER_DOM_ELEMENT_ID } from '../../enum/super-dom.enum';
import { OriginAnalysisOrderService } from '@shared/services/origin-analysis-order.service';
import { ORDER_PARAM_HELPER } from '@shared/constants/order-param-helper.const';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { RocketStreamRead } from '@shared/channel/rx-event';
import { VolumeMetrics } from '../../constants/super-dom.constants';

@Component({
  selector: 'app-table-negotiation[cdStock]',
  templateUrl: './table-negotiation.component.html',
  styleUrls: ['./table-negotiation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableNegotiationComponent
  extends ReadStreamBase
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('input') elementRefInput!: ElementRef<any>;
  @ViewChild('table') elementRefTable!: ElementRef<any>;
  @ViewChild(CdkVirtualScrollViewport)
  cdkVirtualScrollViewport!: CdkVirtualScrollViewport;
  @Input() qttyForm!: number;
  @Input() refComponent!: string;
  @Input() cdStock!: string;
  @Input() cdStockOrder!: string;
  @Input() idExchange!: number;
  @Input() standardLot!: string | number;
  @Input() height!: any;
  @Input() width!: any;
  @Input() isFocus!: boolean;
  @Input() isCrypto!: boolean;
  @Input() tickSizeDenominator: number = 0;
  @Input() showDetail: boolean = false;
  @Input() showDetailCustody: boolean = false;
  @Input() showVolume: boolean = false;
  @Input() showPosition: boolean | string = false;
  @Input() vlPriceAvg: string = '';
  @Input() dsAsset: string = '';
  @Input() securityType: string = '';
  _showAtPrice: boolean = false;
  @Input() set showAtPrice(showAtPrice: boolean) {
    this._showAtPrice = showAtPrice;
    this.startOrNotVolume();
  }
  _showColumnBook: boolean = false;
  @Input() set showColumnBook(showColumnBook: boolean) {
    this._showColumnBook = showColumnBook;
  }
  @Input() showPowerBar: boolean = true;
  @Input() price: number = 0;
  @Input() typeNearest: string = '';
  @Input() ordersOpen: IOrders[] = [];
  @Input() isLineBlock: boolean = false;
  @Input() precoMinimo!: string;
  @Input() precoMaximo!: string;
  @Input() hideAggressor!: boolean;
  @Input() cdSegment: string = '';
  @Input() qttdPosition: string = '';
  @Input() vlMinPriceIncrement!: string | number;
  @Output() lockChange: EventEmitter<any> = new EventEmitter();
  @Output() sendOrderBook: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeEditOrder: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  private config: any = {
    hideAggressor: true,
    minimumPrice: 0,
    maximumPrice: 0,
  };
  private readonly newArray = createArrayIndex(40, false);
  public sideOrders = TYPE_ORDE_SIDE_ENUM;
  public mouseClicked = false;
  public mouseClickedEvent!: any;
  public rangePrice!: any;
  public rangeVolume!: any;
  public showSeparator = -1;
  public lineEditOrder: any = {
    line: -1,
    side: '',
    idOrder: '',
  };
  public editOrderForm!: FormGroup;
  public forceChartData!: IMultibrokerBar;
  public orderSelect!: any;
  public ordersListObject: any = {};
  public tableDefault: any[] = [];
  public tableDefaultLength: any[] = [];
  public tableDefaultHash: any = {};
  public ordersHash: any = {};
  public outRangeBuy!: any[];
  public outRangeSell!: any[];
  public sideOrderString = TYPE_ORDE_SIDE_STRING_ENUM;
  public classPrice: string = '';
  public priceSeletedMouseMove!: any;
  public lineBlockIcon: string = 'lock_open';
  private initData: any = {};
  private oldStock!: string;
  private bookParams!: SubscribeParam;
  // range da quantidade de campos que vao vir na linha
  private _rangeFieldsCheetah = 40;
  private _mousedown = new Subject<{
    price: string;
    side: TYPE_ORDE_SIDE_ENUM;
    index: number;
  }>();
  public readonly SUPER_DOM_ELEMENT_ID = SUPER_DOM_ELEMENT_ID;
  getRangeFieldsCheetah = () =>
    this.typeNearest === 'US' ? 2 : this._rangeFieldsCheetah;
  private oldIdExchange!: number;
  private priceOld: number = 0;
  private intervalPrice!: any;
  private _createTable = new Subject<void>();
  private _setCentralizeLine = new Subject<void>();
  private isBlockCheckOrders = true;
  private detectChangesSubject = new Subject<void>();
  private focusInInput$ = new Subject<void>();
  private volumeRange: any = {};
  public pricePosition: number = 0;
  tableIsCreated: boolean = false;
  private measureViewportSize = 0;
  valumeChannelSubscription!: RocketStreamRead | any;
  bookChannelSubscription!: RocketStreamRead | any;
  private MIN_INTERVAL = 0.01;
  private MAX_INTERVAL = 1000 * 1000;
  get editBuy() {
    return (
      this.lineEditOrder?.side == this.sideOrders.BUY ||
      (this.mouseClickedEvent == this.sideOrders.BUY && this.mouseClicked)
    );
  }

  get editSell() {
    return (
      this.lineEditOrder?.side == this.sideOrders.SELL ||
      (this.mouseClickedEvent == this.sideOrders.SELL && this.mouseClicked)
    );
  }

  get qtty() {
    const listStocksQtty =
      this._stockPreferencesService.stocksCustomPreferences;
    const key = this.cdStockOrder;
    const hasQttyInList =
      listStocksQtty &&
      listStocksQtty[key] &&
      !isNullOrUndefined(listStocksQtty[key].qtty);
    return this._stockPreferencesService.saveQttyStockEnabled && hasQttyInList
      ? listStocksQtty[key].qtty
      : this.qttyForm ?? 100;
  }

  private get rangeListTable() {
    if (!this.isLineBlock) return 200;
    if (!this.elementRefTable) return 20;
    return +(
      (this.elementRefTable.nativeElement.clientHeight + 12) /
      18 /
      2
    ).toFixed(0);
  }

  private volumeParamsTable!: SubscribeParam | undefined;
  public maxVolumeAtPriceTotal = 0;
  private event$!: Subscription;
  private autoCenter$: Subject<void> = new Subject<void>();
  private initEventScroll = false;
  private autoCenterBlock = false;
  constructor(
    private _bookChannel: MiniBookChannel,
    private superDomService: SuperDomService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private ordersService: OrdersService,
    private _volumeChannel: VolumeChannel,
    private _stockPreferencesService: StockPreferencesService,
    private cdr: ChangeDetectorRef,
    private originAnalysisOrderService: OriginAnalysisOrderService
  ) {
    super();
    this.editOrderForm = this.formBuilder.group({
      qtd: new FormControl(undefined),
      order: new FormControl(undefined),
    });
    this.detectChangesSubject
      .pipe(takeUntil(this.untilDestroy$))
      .subscribe(() => {
        this.cdr.detectChanges();
      });
    this.autoCenter$
      .pipe(takeUntil(this.untilDestroy$), auditTime(1000))
      .subscribe(() => {
        if (
          !this.autoCenterBlock &&
          this.cdkVirtualScrollViewport &&
          !this.cdkVirtualScrollViewport.elementRef.nativeElement.getElementsByClassName(
            'border-top'
          ).length
        ) {
          this.centralizeBookHandler();
        }
      });
  }

  ngOnInit() {
    this.subscribeEventsChannels();
    this._setCentralizeLine
      .pipe(takeUntil(this.untilDestroy$), auditTime(250))
      .subscribe(this.centralizeBookHandler);

    this._createTable
      .pipe(
        takeUntil(this.untilDestroy$),
        filter(() => !this.tableIsCreated)
      )
      .subscribe(() => {
        this.createTable();
        !this.isLineBlock && this._setCentralizeLine.next();
      });

    this._mousedown
      .pipe(
        takeUntil(this.untilDestroy$),
        map((data) => {
          const order = this.ordersListObject[+data.price];
          return {
            order,
            side: data.side,
            price: data.price,
            index: data.index,
          };
        }),
        filter((data) => data.order)
      )
      .subscribe((data) => {
        this.mouseClicked = true;
        this.mouseClickedEvent = data.side;
        this.orderSelect = data.order;
        document
          .getElementById(`table_super_dom_${this.refComponent}`)
          ?.addEventListener('mousemove', this.mousemove);
        this.detectChangesSubject.next();
      });
    this.focusInInput$
      .pipe(takeUntil(this.untilDestroy$))
      .subscribe(() => this.focusInInput());
  }
  startOrNotVolume() {
    this._showAtPrice ? this.streamingVolume() : this.removeStreamingVolume();
  }

  private centralizeBookHandler = () => {
    const price = this._getFirstBuy();
    let height = this.elementRefTable.nativeElement.clientHeight;
    const subtraction = this.isLineBlock ? 1 : 0;
    let index =
      this.tableDefault.findIndex((item: any) => item.priceFloat === price) -
      subtraction;
    if (this.isLineBlock) {
      height < 200 && (height = height - 15);
      index = this.tableDefault.length / 2 - 2;
    } else {
      this.autoCenterBlock = false;
    }
    const view = +(height / 20 / 2).toFixed(0);
    this.cdkVirtualScrollViewport?.scrollToIndex(index - view);
    this.cdkVirtualScrollViewport?.checkViewportSize();
    this.detectChangesSubject.next();
    if (this.cdkVirtualScrollViewport && !this.initEventScroll) {
      this.initEventScroll = true;
      this.cdkVirtualScrollViewport
        ?.elementScrolled()
        ?.pipe(takeUntil(this.untilDestroy$))
        ?.subscribe(() => {
          if (
            !this.cdkVirtualScrollViewport.elementRef.nativeElement.getElementsByClassName(
              'border-top'
            ).length
          ) {
            this.autoCenterBlock = true;
          } else {
            this.autoCenterBlock = false;
          }
        });
    }
  };

  ngOnChanges(changes: SimpleChanges): void {
    const {
      cdStock,
      height,
      showDetail,
      showDetailCustody,
      price,
      ordersOpen,
      showPowerBar,
      isLineBlock,
      vlPriceAvg,
      hideAggressor,
    } = changes;
    isLineBlock?.currentValue !== undefined && this.changeBlockOrUnblockLine();
    cdStock?.currentValue && this.initCheetah(cdStock.currentValue);
    if (height?.currentValue) {
      if (this.isLineBlock) {
        this.createTable();
      }
      this._setCentralizeLine.next();
    }
    price?.currentValue && this.upOrDownPrice();
    ordersOpen?.currentValue && this.checkOrders();
    vlPriceAvg?.currentValue && this.roundPrice();
    !isNullOrUndefined(showDetail?.currentValue) &&
      this._setCentralizeLine.next();
    !isNullOrUndefined(showDetailCustody?.currentValue) &&
      this._setCentralizeLine.next();
    !isNullOrUndefined(showPowerBar?.currentValue) &&
      this._setCentralizeLine.next();
    if (hideAggressor?.currentValue || hideAggressor?.currentValue === false) {
      this.resultProcess(this.buildData(this.initData));
    }
  }

  private async subscribeEventsChannels() {
    this.bookChannelSubscription = await this._bookChannel.onEvents();
    this.readStream(
      this.bookChannelSubscription.stream,
      this.bookChannelHandler
    );
    this.bookChannelSubscription.snapshot(this.bookParams.itemsArray);
  }

  bookChannelHandler = (payload: any) => {
    const itemSubscribed = this.bookParams.itemsArray[0];
    const data = payload.get(itemSubscribed);
    if (!data) return;
    if (data.isEndOfSnap) {
      this.tableIsCreated = false;
      this._createTable.next();
      return;
    }
    const transformed = this.channelHandler(data);
    this.resultProcess(transformed);
  };
  private isOnEventsProcessing = false;
  private async subscribeVolumeChannelSubscription() {
    if (
      !this._showAtPrice ||
      this.valumeChannelSubscription ||
      this.isOnEventsProcessing
    )
      return;
    this.isOnEventsProcessing = true;
    try {
      this.valumeChannelSubscription = await this._volumeChannel.onEvents();
      this.readStream(
        this.valumeChannelSubscription.stream,
        this.volumeHandler
      );
      this.valumeChannelSubscription.snapshot(
        this.volumeParamsTable?.itemsArray
      );
    } catch (error) {
      console.error('Erro ao se inscrever no canal de volume:', error);
    } finally {
      this.isOnEventsProcessing = false;
    }
  }
  private untilDestroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.untilDestroy$.next();
    this.untilDestroy$.complete();
    this.bookChannelSubscription && this.bookChannelSubscription.close();
    this.valumeChannelSubscription && this.valumeChannelSubscription.close();
    this.oldStock && this.removeCheetah();
    this.event$ && this.event$.unsubscribe();
    this.autoCenter$?.unsubscribe();
  }

  private upOrDownPrice(): void {
    this.classPrice =
      this.price > this.priceOld
        ? 'bg-feedback-success text-black'
        : 'bg-feedback-error';
    this.priceOld = this.price;
    this.detectChangesSubject.next();
  }

  private checkOrders = (): void => {
    if (this.isBlockCheckOrders) return;
    const mounted = new MountingLineOrders(this.superDomService);
    const { ordersList, tableDefaultHash, tableDefault, ordersHash } =
      mounted.mountList(
        this.ordersOpen,
        this.ordersHash,
        this.price,
        deepClone(this.tableDefault),
        deepClone(this.tableDefaultHash)
      );
    this.ordersListObject = deepClone(ordersList);
    this.tableDefaultHash = deepClone(tableDefaultHash);
    this.tableDefault = deepClone(tableDefault);
    this.ordersHash = deepClone(ordersHash);
    this.detectChangesSubject.next();
  };

  private initCheetah(cdStock: string) {
    this.removeCheetah();
    this.isBlockCheckOrders = true;
    this.clearLineEdit();
    this.bookParams = {
      itemsArray: [`${cdStock}:${this.idExchange}`],
    };
    this.rangePrice = null;
    this.rangeVolume = {};
    this.tableDefaultLength = [];
    this._bookChannel.subscribe(this.bookParams);
    this.oldStock = cdStock;
    this.oldIdExchange = this.idExchange;
    this.bookChannelSubscription &&
      this.bookChannelSubscription.snapshot(this.bookParams.itemsArray);
    this.startOrNotVolume();
    this.tableIsCreated = false;
    this._createTable.next();
    this.initEventScroll = false;
  }

  private removeCheetah() {
    if (!this.oldStock || !this.oldIdExchange) return;
    const params = {
      itemsArray: [`${this.oldStock}:${this.oldIdExchange}`],
    };
    this._bookChannel.unsubscribe(params);
    this.initData = {};
    this.tableDefault = [];
  }

  private channelHandler = (payload: any) => {
    if (!payload || !Object.keys(payload).length) {
      return;
    }
    this.initData = {
      ...this.initData,
      ...payload,
    };
    return this.buildData(this.initData);
  };

  private resultProcess = (data: any): void => {
    const { buy, sell, biggestPrice, range } = data;
    this.rangePrice = range;
    this.showSeparator = biggestPrice;
    this.forceChartData = { buy: buy, sell: sell };
    this.detectChangesSubject.next();
    this._createTable.next();
    if (
      !this.autoCenterBlock &&
      !this.isLineBlock &&
      this.autoCenter$ &&
      !this.autoCenter$.closed
    ) {
      this.autoCenter$.next();
    }
  };

  private createTable = (): void => {
    if (!Object.keys(this.initData).length) return;
    const initialValue = this._getFirstBuy();
    if (!initialValue) return;
    this.tableDefault = [];
    this.intervalPrice = this.getIntervalPrice();
    let startBuy = +initialValue + this.intervalPrice;
    let startSell = +initialValue;
    const fixed = this.tickSizeDenominator;
    const rangeTable = this.rangeListTable;
    for (let i = 0; i < rangeTable; i++) {
      const buy = (startBuy -= this.intervalPrice).toFixed(fixed);
      const sell = (startSell += this.intervalPrice).toFixed(fixed);
      const orderBuy = this.ordersListObject[+buy];
      const orderSell = this.ordersListObject[+sell];
      const orderIsStopViewBuy = this.superDomService.checkOrderIsStopView(
        orderBuy,
        orderBuy?.side,
        this.price
      );
      const orderIsStopViewSell = this.superDomService.checkOrderIsStopView(
        orderSell,
        orderSell?.side,
        this.price
      );
      const orderBuyIsBuySide = orderBuy?.side === this.sideOrderString.BUY;
      const orderSellIsBuySide = orderSell?.side === this.sideOrderString.BUY;
      startBuy > 0 &&
        this.tableDefault.push({
          price: buy,
          priceFormated: this.formatedPrice(buy),
          priceFloat: parseFloat(buy),
          hasQttyOrderBuy:
            orderBuy && orderBuyIsBuySide ? orderBuy.qtty_left : null,
          hasQttyOrderSell:
            orderBuy && !orderBuyIsBuySide ? orderBuy.qtty_left : null,
          orderIsStopViewBuy:
            orderBuy && orderBuyIsBuySide
              ? orderIsStopViewBuy || orderIsStopViewSell
              : false,
          orderIsStopViewSell:
            orderBuy && !orderBuyIsBuySide
              ? orderIsStopViewSell || orderIsStopViewBuy
              : false,
          typePriceSide: this.sideOrderString.BUY,
        });
      this.tableDefault.unshift({
        price: sell,
        priceFormated: this.formatedPrice(sell),
        priceFloat: parseFloat(sell),
        hasQttyOrderBuy:
          orderSell && orderSellIsBuySide ? orderSell.qtty_left : null,
        hasQttyOrderSell:
          orderSell && !orderSellIsBuySide ? orderSell.qtty_left : null,
        orderIsStopViewBuy:
          orderSell && orderSellIsBuySide
            ? orderIsStopViewBuy || orderIsStopViewSell
            : false,
        orderIsStopViewSell:
          orderSell && !orderSellIsBuySide
            ? orderIsStopViewSell || orderIsStopViewBuy
            : false,
        typePriceSide: this.sideOrderString.SELL,
      });
    }
    this.tableDefaultHash = this.tableDefault.reduce(
      (accumulator: any, currentValue: any, index: number) => ({
        ...accumulator,
        [`${+currentValue.price}`]: {
          ...currentValue,
          arrayIndex: index,
        },
      }),
      {}
    );
    if (this.isBlockCheckOrders) {
      this.isBlockCheckOrders = false;
      this.checkOrders();
    }
    if (
      !this.tableDefaultLength.length ||
      rangeTable * 2 !== this.tableDefaultLength.length
    ) {
      this.tableDefaultLength = this.tableDefault.map(
        (item: any) => item.price
      );
    }
    !this.isLineBlock && (this.tableIsCreated = true);
    this.isLineBlock && this.centralizeBookHandler();
  };

  private _getFirstBuy(): number {
    for (let i = 1; i < 41; i++) {
      const buyValue = this.parseValue(this.initData[`compra_valor${i}`]);
      const sellValue = this.parseValue(this.initData[`venda_valor${i}`]);

      if (buyValue || sellValue) {
        return buyValue || sellValue;
      }
    }
    return 0;
  }

  private getIntervalPrice(): any {
    let minIntervalFound = this.MAX_INTERVAL;

    const range = this.getRangeFieldsCheetah();
    for (let i = 1; i < range; i++) {
      const { buyInterval, sellInterval } = this.calculateIntervals(i);

      minIntervalFound = Math.min(minIntervalFound, buyInterval, sellInterval);
    }

    return minIntervalFound;
  }

  private calculateIntervals(index: number): {
    buyInterval: number;
    sellInterval: number;
  } {
    const buyValue = this.parseValue(this.initData[`compra_valor${index}`]);
    const nextBuyValue = this.parseValue(
      this.initData[`compra_valor${index + 1}`]
    );
    const sellValue = this.parseValue(this.initData[`venda_valor${index}`]);
    const nextSellValue = this.parseValue(
      this.initData[`venda_valor${index + 1}`]
    );

    const buyInterval = this.calculateInterval(buyValue, nextBuyValue);
    const sellInterval = this.calculateInterval(nextSellValue, sellValue);

    return { buyInterval, sellInterval };
  }

  private parseValue(value: string | undefined): number {
    if (!value || value === '-') {
      return this.cdSegment === '9999'
        ? Math.floor(Math.random() * (20000 - 1000 + 1)) + 1000
        : 0;
    }
    return +value;
  }

  private calculateInterval(value: number, nextValue: number): number {
    return nextValue == 0 ? this.MIN_INTERVAL : Math.abs(value - nextValue);
  }

  centralizeBook() {
    this.centralizeBookHandler();
  }

  private removeStreamingVolume() {
    if (!this.oldStock || !this.oldIdExchange) return;
    this.volumeParamsTable &&
      this._volumeChannel.unsubscribe(deepClone(this.volumeParamsTable));
    this.volumeParamsTable = undefined;
    this.volumeRange = {};
    this.rangeVolume = {};

    this.removeVolumeChannelSubscription();
  }
  private removeVolumeChannelSubscription() {
    this.valumeChannelSubscription && this.valumeChannelSubscription.close();
    this.valumeChannelSubscription = undefined;
  }
  private streamingVolume() {
    if (
      !this.cdStock ||
      !this.idExchange ||
      (this.volumeParamsTable &&
        this.volumeParamsTable.itemsArray[0] ==
          `ANALYSIS=${this.cdStock}:${this.idExchange}`)
    )
      return;
    this.removeStreamingVolume();
    this.subscribeVolumeChannelSubscription();
    this.maxVolumeAtPriceTotal = 0;
    this.volumeParamsTable = {
      itemsArray: [`ANALYSIS=${this.cdStock}:${this.idExchange}`],
    };
    this._volumeChannel.subscribe(this.volumeParamsTable);
    this.valumeChannelSubscription &&
      this.valumeChannelSubscription.snapshot(
        this.volumeParamsTable?.itemsArray
      );
  }

  private isRelevantVolumeData = (volumeData: any): boolean => {
    const analysisCondition = `ANALYSIS=${this.cdStock}:${this.idExchange}`;
    return volumeData.get(analysisCondition);
  };

  private isSnapshotEndReceived = false;

  private volumeHandler = (payload: any): void => {
    const volumeData: any = this.isRelevantVolumeData(payload);
    if (!volumeData) return;
    // console.log(
    //   'volumeHandler: ',
    //   this.valumeChannelSubscription.key,
    //   volumeData
    // );
    volumeData.forEach((element: any) => {
      if (!element.isEndOfSnap) {
        this.updateVolumeRange(element);
        this.maxVolumeAtPriceTotal = Math.max(
          this.maxVolumeAtPriceTotal,
          element.qtty_total
        );
        this.processVolume(element.key);
      } else {
        this.processSnapshotVolume(); // Processa todos os dados de volume acumulados
      }
    });
  };

  private processSnapshotVolume = (): void => {
    this.isSnapshotEndReceived = true;
    const range: any = {};
    Object.keys(this.volumeRange).forEach((key) => {
      const price = formatPrice(key, this.tickSizeDenominator);
      range[price] = this.calculateVolumeMetrics(this.volumeRange[key]);
    });
    this.rangeVolume = range;
    this.detectChangesSubject.next();
    // Após o processamento do snapshot, o fluxo continua normalmente
  };
  private updateVolumeRange = (volumeData: any): void => {
    const { key, qtty_buyer, qtty_direct, qtty_rlp, qtty_seller, qtty_total } =
      volumeData;
    this.volumeRange[key] = {
      ...this.volumeRange[key],
      qtty_buyer: parseFloat(qtty_buyer),
      qtty_direct: parseFloat(qtty_direct),
      qtty_rlp: parseFloat(qtty_rlp),
      qtty_seller: parseFloat(qtty_seller),
      qtty_total: parseInt(qtty_total),
    };
  };

  private processVolume(key: string): void {
    if (!this.isSnapshotEndReceived) return;
    const price = formatPrice(key, this.tickSizeDenominator);
    this.rangeVolume[price] = this.calculateVolumeMetrics(
      this.volumeRange[key]
    );
  }

  private calculateVolumeMetrics = (volume: any): VolumeMetrics => {
    const { qtty_buyer, qtty_seller, qtty_rlp, qtty_total } = volume;
    const total = qtty_buyer + qtty_seller + qtty_rlp;
    const percentageOfTotal = (qty: number) =>
      (qty / this.maxVolumeAtPriceTotal) * 100;
    const atpriceHovThreshold = 55;
    const atpriceHovPenalty = -35;
    return {
      qtty_total,
      atpriceHov:
        (total / this.maxVolumeAtPriceTotal) * 100 > atpriceHovThreshold
          ? atpriceHovPenalty
          : 0,
      atpriceBuy: percentageOfTotal(qtty_buyer),
      atpriceSell: percentageOfTotal(qtty_seller),
      atpriceRlp: percentageOfTotal(qtty_rlp),
      atpriceDirect: percentageOfTotal(volume.qtty_direct),
    };
  };

  @HostListener('window:keydown.enter')
  public orderSend(isClickEnter: boolean = false) {
    if (
      (this.isFocus || isClickEnter || this.isLineBlock) &&
      (!!this.editOrderForm.value.qtd || !!this.lineEditOrder?.idOrder)
    ) {
      let price;
      let price_stop;
      let orderDoubleStartStop: any = {};
      this.originAnalysisOrderService.setOriginOrder(
        ORDER_PARAM_HELPER.SUPER_DOM_DOUBLE_CLICK_LINE_AND_INPUT_VALUE_BOOK
      );
      if (
        this.editOrderForm.get('order')?.value &&
        this.editOrderForm.get('order')?.value.id_order
      ) {
        if (this.checkOrderIsStop(this.editOrderForm.value.order)) {
          const editing = new EditingOrder(this.toastService);
          const orderEdit: any = editing.orderStop(
            this.editOrderForm.value.order,
            +this.priceSeletedMouseMove,
            this.price
          );
          if (!orderEdit) {
            this.clearLineEdit();
            return;
          }
          price = orderEdit.price;
          price_stop = orderEdit.price_stop;
        } else if (this.checkDoubleStartStop(this.editOrderForm.value.order)) {
          const editing = new EditingOrder(this.toastService);
          orderDoubleStartStop = editing.orderDoubleStartStop(
            this.editOrderForm.value.order,
            +this.priceSeletedMouseMove,
            this.price
          );
          if (!orderDoubleStartStop) {
            this.clearLineEdit();
            return;
          }
        } else {
          price = this.priceSeletedMouseMove
            ? this.priceSeletedMouseMove
            : this.editOrderForm.get('order')!.value.price;
        }
        this.superDomService.processModificationOrder(
          this.ordersOpen,
          this.editOrderForm.get('order')!.value,
          {
            price: price,
            price_stop: price_stop,
            orderQty: this.editOrderForm.get('qtd')!.value,
            ...orderDoubleStartStop,
          }
        );
      } else {
        price = this.priceSeletedMouseMove
          ? this.priceSeletedMouseMove
          : this.lineEditOrder.price;
        let typeOrder = TYPE_ORDE_ENUM.LIMITADA;
        if (
          (this.lineEditOrder.side === TYPE_ORDE_SIDE_ENUM.BUY &&
            this.lineEditOrder.price > this.price) ||
          (this.lineEditOrder.side === TYPE_ORDE_SIDE_ENUM.SELL &&
            this.lineEditOrder.price < this.price)
        ) {
          typeOrder = TYPE_ORDE_ENUM.START_STOP;
        }
        const paramsOrder = {
          price: price,
          price_stop: price_stop,
          sideOrder: this.lineEditOrder.side,
          qtd: this.editOrderForm.get('qtd')!.value.toString().replace('.', ''),
          typeOrder: typeOrder,
        };
        this.sendOrderBook.emit(paramsOrder);
      }
      this.clearLineEdit();
    }
  }

  @HostListener('window:keydown.esc')
  public clearLineEdit(ismouseleave: boolean = false): void {
    this.removeMousemoveEvent();
    if (ismouseleave && this.lineEditOrder.idOrder) {
      return;
    }
    this.cleanSelectLine();
    this.lineEditOrder = {
      line: -1,
      side: '',
      idOrder: '',
    };
    this.editOrderForm.reset();
  }

  private cleanSelectLine(): void {
    this.priceSeletedMouseMove = null;
    this.mouseClicked = false;
    this.mouseClickedEvent = '';
    this.orderSelect = null;
    this.changeEditOrder.emit(false);
  }

  public closeAllOrders(side: 'right-0' | 'left-0'): void {
    const orderOperationType =
      side === 'left-0'
        ? TYPE_ORDE_SIDE_STRING_ENUM.BUY
        : TYPE_ORDE_SIDE_STRING_ENUM.SELL;
    this.ordersService.cancelOrders(this.ordersOpen, orderOperationType);
  }

  public editOrderDBlClick = (
    price: string,
    side: TYPE_ORDE_SIDE_ENUM
  ): void => {
    if (!side) return;
    this.clearLineEdit();
    const order = this.ordersListObject[+price];
    this.originAnalysisOrderService.setOriginOrder(
      ORDER_PARAM_HELPER.SUPER_DOM_DOUBLE_CLICK_LINE_BOOK
    );
    if (order && order.qtty_left) {
      const price = this.checkOrderIsStop(order)
        ? order.price_stop
        : order.price;
      this.lineEditOrder.side =
        order.side === TYPE_ORDE_SIDE_STRING_ENUM.BUY
          ? this.sideOrders.BUY
          : this.sideOrders.SELL;
      this.lineEditOrder.price = +price;
      this.lineEditOrder.idOrder = order.id_order;
      this.editOrderForm.controls['qtd'].setValue(order.qtty_left);
      this.editOrderForm.controls['order'].setValue(order);
    } else {
      this.lineEditOrder.side = side;
      this.lineEditOrder.price = +price;
      this.editOrderForm.controls['qtd'].setValue(this.qtty);
    }
    this.priceSeletedMouseMove = price;
    this.changeEditOrder.emit(true);
    this.focusInInput$.next();
  };

  private focusInInput(): void {
    this.elementRefInput?.nativeElement.focus();
    this.elementRefInput?.nativeElement.select();
  }

  public sendOrder = (
    price: string,
    sideOrder: TYPE_ORDE_SIDE_ENUM,
    order: any | null,
    origin: string = ORDER_PARAM_HELPER.SUPER_DOM_DOUBLE_CLICK_LINE_BOOK
  ): void => {
    const idOrder = order?.id_order;
    const qttyLeft = order?.qtty_left;
    if (idOrder || (qttyLeft && qttyLeft === 0)) {
      return;
    }
    let typeOrder = TYPE_ORDE_ENUM.LIMITADA;
    if (
      (sideOrder === TYPE_ORDE_SIDE_ENUM.BUY && +price > this.price) ||
      (sideOrder === TYPE_ORDE_SIDE_ENUM.SELL && +price < this.price)
    ) {
      typeOrder = TYPE_ORDE_ENUM.START_STOP;
    }
    this.originAnalysisOrderService.setOriginOrder(origin);
    const paramsOrder = {
      price: price,
      sideOrder: sideOrder,
      qtd: this.qtty.toString().replace('.', ''),
      typeOrder: typeOrder,
    };
    this.sendOrderBook.emit(paramsOrder);
  };

  public mousedown(
    price: string,
    side: TYPE_ORDE_SIDE_ENUM,
    index: number
  ): void {
    if (!price) {
      return;
    }
    this._mousedown.next({
      price,
      side,
      index,
    });
  }

  private removeMousemoveEvent() {
    document
      .getElementById(`table_super_dom_${this.refComponent}`)
      ?.removeEventListener('mousemove', this.mousemove);
  }

  public mouseup(priceEdited: string): void {
    this.removeMousemoveEvent();
    if (this.mouseClicked && +this.orderSelect.price != +priceEdited) {
      this.originAnalysisOrderService.setOriginOrder(
        ORDER_PARAM_HELPER.SUPER_DOM_DRAG_AND_DROP_LINE_BOOK
      );
      const hashs = deepClone(this.tableDefaultHash);
      const hash = hashs[+this.orderSelect.price];
      if (hash) {
        const isBuy = this.orderSelect.side === this.sideOrderString.BUY;
        if (isBuy) {
          hash.hasQttyOrderBuy = null;
          hash.orderIsStopViewBuy = false;
        } else {
          hash.hasQttyOrderSell = null;
          hash.orderIsStopViewSell = false;
        }
        hashs[+this.orderSelect.price] = hash;
        const arrayIndex = hash.arrayIndex;
        const arr = deepClone(this.tableDefault);
        arr[arrayIndex] = deepClone(hash);
        this.tableDefaultHash = deepClone(hashs);
        this.tableDefault = deepClone(arr);
        this.detectChangesSubject.next();
      }
      if (this.checkOrderIsStop(this.orderSelect)) {
        const editing = new EditingOrder(this.toastService);
        const orderEdit: any = editing.orderStop(
          this.editOrderForm.value.order ?? this.orderSelect,
          +this.priceSeletedMouseMove,
          this.price
        );
        !!orderEdit &&
          this.superDomService.processModificationOrder(
            this.ordersOpen,
            this.orderSelect,
            orderEdit
          );
      } else if (this.checkDoubleStartStop(this.orderSelect)) {
        let orderDoubleStartStop: any = {};
        const editing = new EditingOrder(this.toastService);
        orderDoubleStartStop = editing.orderDoubleStartStop(
          this.orderSelect,
          +priceEdited,
          this.price
        );
        !!orderDoubleStartStop &&
          this.superDomService.processModificationOrder(
            this.ordersOpen,
            this.orderSelect,
            orderDoubleStartStop
          );
      } else {
        this.superDomService.processModificationOrder(
          this.ordersOpen,
          this.orderSelect,
          { price: priceEdited }
        );
      }
    }
    this.cleanSelectLine();
  }

  public mousemove = (event: MouseEvent) => {
    const clas = (event.target as any)['className'];
    const regex = getTextByRegex(clas, /SUPER_DOM_\d*_\d*/g);
    const regexSplit = regex.split('_');
    const index = parseInt(regexSplit.pop()!!);
    const item = this.tableDefault[index];
    if (item.price != this.priceSeletedMouseMove) {
      this.priceSeletedMouseMove = item.price;
      this.detectChangesSubject.next();
    }
  };

  @HostListener('window:keydown.ArrowDown', ['$event'])
  @HostListener('window:keydown.ArrowUp', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (this.lineEditOrder) {
      event.code === 'ArrowUp' &&
        (this.priceSeletedMouseMove =
          +this.priceSeletedMouseMove + this.intervalPrice);
      event.code === 'ArrowDown' &&
        (this.priceSeletedMouseMove =
          +this.priceSeletedMouseMove - this.intervalPrice);
      this.priceSeletedMouseMove = this.priceSeletedMouseMove.toFixed(
        this.tickSizeDenominator
      );
    }
  }

  public cancelOrder(price: string): void {
    const order = this.ordersListObject[+price];
    order && this.ordersService.cancelOrders([order]);
    const hashs = deepClone(this.tableDefaultHash);
    const hash = hashs[+price];
    if (hash) {
      const isBuy = order.side === this.sideOrderString.BUY;
      if (isBuy) {
        hash.hasQttyOrderBuy = null;
        hash.orderIsStopViewBuy = false;
      } else {
        hash.hasQttyOrderSell = null;
        hash.orderIsStopViewSell = false;
      }
      hashs[+price] = hash;
      const arrayIndex = hash.arrayIndex;
      const arr = deepClone(this.tableDefault);
      arr[arrayIndex] = deepClone(hash);
      this.tableDefaultHash = deepClone(hashs);
      this.tableDefault = deepClone(arr);
      this.ordersListObject[+price].qtty_left = 0;
      this.detectChangesSubject.next();
    }
  }

  private formatedPrice(price: string): string {
    const options: Intl.NumberFormatOptions = {
      maximumFractionDigits: this.tickSizeDenominator,
      minimumFractionDigits: this.tickSizeDenominator,
    };
    return formatterNumber(+price, options);
  }

  toogleLockComponent = () => {
    this.lockChange.emit({ isLineBlock: !this.isLineBlock });
    this.centralizeBookHandler();
  };

  public changeBlockOrUnblockLine(): void {
    this.tableIsCreated = false;
    this.lineBlockIcon = this.isLineBlock ? 'lock' : 'lock_open';
    this._createTable.next();
  }

  private checkOrderIsStop(order: any): boolean {
    return !!order && isStartStop(order.cd_order_type);
  }

  private checkDoubleStartStop(order: any): boolean {
    return !!order && isDoubleStartStop(order.cd_order_type);
  }

  public handleCtrlClick(price: string, side: any): void {
    this.cleanSelectLine();
    this.sendOrder(
      price,
      side,
      null,
      ORDER_PARAM_HELPER.SUPER_DOM_CTRL_CLICK_LINE_BOOK
    );
  }

  private roundPrice(): void {
    if (!this.vlPriceAvg || this.vlPriceAvg === '-') return;
    const value = +this.vlPriceAvg.replace('.', '').replace(',', '.');
    let roundValue = 0;
    if (
      isStockFuture(this.securityType) &&
      this.dsAsset !== 'DOL' &&
      this.dsAsset !== 'WDO' &&
      this.dsAsset !== 'BIT'
    ) {
      const remainder = value % 5;
      roundValue = value - remainder;
    } else if (
      isStockFuture(this.securityType) &&
      (this.dsAsset === 'DOL' || this.dsAsset === 'WDO')
    ) {
      const remainder = +(value % 0.5).toFixed(1);
      roundValue = value - remainder;
    } else if (isStockFuture(this.securityType) && this.dsAsset === 'BIT') {
      const remainder = +(value % +this.vlMinPriceIncrement).toFixed(1);
      roundValue = value - remainder;
    }
    this.pricePosition = roundValue || value;
  }

  private buildData = (data: any) => {
    this.config.hideAggressor = this.hideAggressor;
    this.config.minimumPrice = this.precoMinimo ?? 0;
    this.config.maximumPrice = this.precoMaximo ?? 0;
    const { hashPrices, biggestPrice } = buildBookInfo(
      this.newArray,
      data,
      {
        hideAggressor: this.hideAggressor,
        minimumPrice: this.precoMinimo ?? 0,
        maximumPrice: this.precoMaximo ?? 0,
      },
      this.tickSizeDenominator,
      this.tickSizeDenominator
    );
    return {
      range: hashPrices,
      buy: data.pressao_compradora,
      sell: data.pressao_vendedora,
      biggestPrice,
    };
  };

  @HostListener('window:resize')
  resizeByLineBlock() {
    if (
      this.cdkVirtualScrollViewport &&
      this.measureViewportSize !==
        this.cdkVirtualScrollViewport.measureViewportSize('vertical')
    ) {
      this.isLineBlock && this.createTable();
      this._setCentralizeLine.next();
      this.measureViewportSize =
        this.cdkVirtualScrollViewport.measureViewportSize('vertical');
    }
  }
}
