import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { RecoveryPasswordForm } from './base';
import { FormBuilder } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { OnboardService } from '@shared/services/api/trademap/onboard';
import { RECOVERY_STEPS } from '@shared/services/api/trademap/onboard/data/recovery/recovery.configs';
import { Subject, filter, merge, takeUntil } from 'rxjs';
import { AuthService } from '@shared/services';
import { RecaptchaComponent } from '../recaptcha/recaptcha.component';
import { TrademapV9Service } from '@shared/services/api/trademap/V9/trademap.service';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecoveryComponent
  extends RecoveryPasswordForm
  implements OnInit, OnDestroy
{
  @ViewChild('recaptcha') public recaptchaElement!: RecaptchaComponent;

  public isLoading = false;
  public authenticating = false;
  public timeRedirect: number = 45;
  public displayCountDown: boolean = true;

  private _authenticated: boolean = false;
  private _pageReloaded: boolean = false;
  private untilDestroy$ = new Subject<void>();

  get isCompleted() {
    return this.onboardService.getIsRecoveryCompleted();
  }

  private _trademapV9Service = inject(TrademapV9Service);
  constructor(
    fb: FormBuilder,
    private router: Router,
    private onboardService: OnboardService,
    private _cdr: ChangeDetectorRef,
    private _authService: AuthService
  ) {
    super(fb);
    this.router.events
      .pipe(
        filter(
          (route): route is NavigationEnd => route instanceof NavigationEnd
        )
      )
      .subscribe((routeEvent) => {
        this._pageReloaded =
          routeEvent.id === 1 &&
          routeEvent.url === routeEvent.urlAfterRedirects;
      });
  }

  ngOnInit() {
    this._pageReloaded = false;
    const onMessage = merge(
      this.onPasswordChanges,
      this.onConfirmPasswordChanges
    );
    onMessage.pipe(takeUntil(this.untilDestroy$)).subscribe(() => {
      this.erroData.hasError = false;
    });
  }

  ngOnDestroy(): void {
    this.untilDestroy$.next();
    this.untilDestroy$.complete();
    !this.isCompleted && this._clearRecoveryDataAndRedirToLogin();
  }

  @HostListener('window:beforeunload')
  canDeactivate() {
    return this.isCompleted && !this._pageReloaded;
  }

  public detectChanges = () => this._cdr.detectChanges();

  public resetPassword() {
    this.onboardService
      .onChangePasswordAfterValidate(this.formValues)
      .subscribe((data: any) => {
        if (data.isError) {
          this.erroData.errorMessage = data.message;
          this.erroData.hasError = true;
          this.setFormInvalid();
        } else this.onboardService.setRecoveryStatus(RECOVERY_STEPS.COMPLETED);
        this._cdr.detectChanges();
      });
  }

  private _clearRecoveryDataAndRedirToLogin(): void {
    this.onboardService.clearRecoveryData();
    this.router.navigateByUrl('auth/login');
  }

  public doRecaptcha(action: 'CLICK' | 'COUNT_DOWN'): void {
    this.authenticating = true;
    if (action === 'CLICK') {
      this.displayCountDown = false;
      this._cdr.detectChanges();
    }
    this.recaptchaElement.execute();
  }

  public onRecaptchaError(): void {
    this._clearRecoveryDataAndRedirToLogin();
  }

  public authenticate(recaptcha: string): void {
    this._authService
      .authenticate({
        username: this.vlDocument,
        password: this.formValues.password,
        recaptcha: recaptcha,
      })
      .subscribe({
        next: () => this._trademapV9Service.saveDeepLinkEvents('NEW_LOGIN'),
        error: () => {
          this._pageReloaded = false;
          this.authenticating = false;
          this._authenticated = false;
          this._cdr.detectChanges();
          this._clearRecoveryDataAndRedirToLogin();
        },
      });
  }
}
