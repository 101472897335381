import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { INews } from '@core/interface/news-agency.interface';
import { Subject } from 'rxjs';

@Component({
  selector: 'news-text',
  templateUrl: './news-text.component.html',
  styleUrls: ['./news-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsTextComponent implements OnChanges {
  @Input() news!: INews | null;
  @Input() height!: number;
  @Input() newsListLength!: number;
  @Input() selectedNewsIndex!: number | null;
  @Output() onClick: EventEmitter<null> = new EventEmitter();
  @Output() onNextNews: EventEmitter<null> = new EventEmitter();
  @Output() onPreviousNews: EventEmitter<null> = new EventEmitter();

  isDocument = false;
  document!: any;

  private _onDestroy = new Subject<void>();

  constructor(protected _sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { news } = changes;

    news?.currentValue && this._processNews();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private _processNews() {
    if (this.news?.agencia == 'CVM') {
      this.isDocument = true;

      if (!this.news.id_cvm_company || !this.news.seq) return;
      const { id_cvm_company, seq } = this.news;

      this.document = `${window.location.origin}/go/get-documents/fundamental/${id_cvm_company}/${seq}`;
    }
  }

  goBack() {
    this.onClick.emit();
  }

  nextNews() {
    if (this.selectedNewsIndex == this.newsListLength - 1) return;
    this.onNextNews.emit();
  }

  previousNews() {
    if (this.selectedNewsIndex == 0) return;
    this.onPreviousNews.emit();
  }
}
