import { NEW_STOCK_MODAL_TABS } from '../../constants/stock-table.constants';
import { IStockTableIndex } from '../../interfaces/movers-index.interface';
import { IDynamicList } from '../../interfaces/stock-table.interfaces';

export enum STOCK_TABLE_TYPES {
  PERSONAL = 'PERSONAL',
  MOVERS = 'MOVERS',
  AUCTION = 'AUCTION',
  PRESET = 'PRESET',
}

export const AUCTION_INDEXES: IStockTableIndex[] = [
  { value: 'IBOV' },
  { value: 'SMLL' },
  { value: 'IFIX' },
  { value: 'IMAT' },
];

export const MOVERS_LISTS: IDynamicList[] = [
  {
    label: '> Altas',
    id: 1000000001,
  },
  {
    label: '> Baixas',
    id: 1000000002,
  },
  {
    label: 'Volume',
    id: 1000000003,
  },
];

export const STOCKS_LISTS = [
  NEW_STOCK_MODAL_TABS.STOCKS,
  NEW_STOCK_MODAL_TABS.FUTURES,
  NEW_STOCK_MODAL_TABS.COINS,
  NEW_STOCK_MODAL_TABS.FEES,
  NEW_STOCK_MODAL_TABS.COMMODITIES,
  NEW_STOCK_MODAL_TABS.FIIS,
  NEW_STOCK_MODAL_TABS.INDEXES,
  NEW_STOCK_MODAL_TABS.ETFS,
  NEW_STOCK_MODAL_TABS.BDRS,
  NEW_STOCK_MODAL_TABS.BONDS,
];

export const STOCK_TABLE_ACTIONS = [
  {
    label: 'Criar lista',
    enum: 'CREATE',
    onlyPersonalList: false,
  },
  {
    label: 'Duplicar lista',
    enum: 'CLONE',
    onlyPersonalList: true,
  },
  {
    label: 'Gerenciar lista',
    enum: 'MANAGE',
    onlyPersonalList: true,
  },
];
