import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  ensurePositiveNumber,
  newBigValueFormatter,
} from 'src/app/utils/utils.functions';
import * as Highcharts from 'highcharts';
import Treemap from 'highcharts/modules/treemap';
import { StockListRowModel } from '@shared/components/stock-table/models/stock-table-row.model';
import { Dictionary } from '@core/models';
import { TOrderBy, TPeriod } from '../types';
import { HEATMAP_ORDER_BY_LIST_MAPPING, PERIOD_KEY_MAPPING } from '../enum';
import { HeatmapService } from './heatmap.service';
import { formatNumber } from '@angular/common';
import { ColorRangeServiceService } from '@shared/services/core/color-range/color-range.service';
import {
  COLOR_RANGE_VARIATION_RANGE,
  TvariationRange,
} from '@shared/services/core/color-range/color-range.interface';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import { Subject } from 'rxjs';

Treemap(Highcharts);
export interface IHeatmapOptions {
  datalabels: Highcharts.PlotAdDataLabelsOptions;
  levels: Highcharts.PlotTreemapLevelsOptions[];
}
@Injectable({
  providedIn: 'root',
})
export class ListsService {
  private _variation: TvariationRange = {
    [COLOR_RANGE_VARIATION_RANGE.NEGATIVE]: 0,
    [COLOR_RANGE_VARIATION_RANGE.POSITIVE]: 0,
  };

  private _onClicked = new Subject<{
    event: Highcharts.SeriesClickEventObject;
    refComponent: string;
  }>();

  get onClick() {
    return this._onClicked.asObservable();
  }

  constructor(
    private _heatmapService: HeatmapService,
    private _colorRangeService: ColorRangeServiceService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  plotData(
    chart: Highcharts.Chart,
    stockList: Dictionary<StockListRowModel>,
    refComponent: string,
    isPersonalList: boolean,
    chartOptions: IHeatmapOptions,
    orderBy?: TOrderBy,
    period?: TPeriod,
    useVolumeField: boolean = false
  ) {
    const stocks = stockList.values();
    const data = stocks.map((stock: StockListRowModel) =>
      this.stockToHighchartItem(
        stockList,
        refComponent,
        isPersonalList,
        chartOptions,
        stock,
        orderBy,
        period,
        useVolumeField
      )
    );
    this.setChartData(chart, data, chartOptions);
  }

  setChartData(
    chart: Highcharts.Chart,
    data: any,
    chartOptions: IHeatmapOptions
  ) {
    chart.series[0].update({
      allowTraversingTree: false,
      type: 'treemap',
      layoutStartingDirection: 'horizontal',
      clip: true,
      layoutAlgorithm: 'squarified',
      label: {
        enabled: false,
      },
      levels: chartOptions.levels,
    });

    chart.update({
      chart: {
        zooming: {
          type: 'xy',
        },
      },
    });
    chart.series[0].setData(data);
    chart.hideLoading();
  }

  stockToHighchartItem(
    stockList: Dictionary<StockListRowModel>,
    refComponent: string,
    isPersonalList: boolean,
    chartOptions: IHeatmapOptions,
    stock: any,
    orderBy?: TOrderBy,
    period?: TPeriod,
    useVolumeField: boolean = false
  ) {
    const stocks = stockList.values();
    const minPercentage = (1 * 100) / stocks.length;
    const total = this._getTotal(stocks, period);
    const smallBoxes = this._getSmallBoxes(stocks, total, minPercentage);

    const locale = this.locale;
    const value = this.getStockValueForList(stock, isPersonalList, orderBy);
    const percentageValue = period
      ? +stock[PERIOD_KEY_MAPPING[period] as keyof StockListRowModel]
      : stock.variacao_dia;
    const labelValue =
      orderBy === 'variation'
        ? ''
        : this._dataLabelValue(stock, useVolumeField, value, locale, orderBy);

    return {
      custom: stock,
      id: `${stock.cd_stock}:1`,
      name: stock.cd_stock,
      value: this._heatmapService.getValue(
        value,
        total,
        minPercentage,
        smallBoxes
      ),
      events: {
        click: (event: Highcharts.SeriesClickEventObject) =>
          this._onClicked.next({ event, refComponent }),
      },
      color: this._colorRangeService.getColor(percentageValue, this._variation),
      dataLabels: {
        ...chartOptions.datalabels,
        formatter: () => {
          return this._buildLabel(
            stock.cd_stock,
            labelValue,
            percentageValue,
            locale,
            stock?.situacao
          );
        },
      },
    };
  }

  private _getTotal(stocks: StockListRowModel[], period?: TPeriod): number {
    return stocks.reduce((previous: number, current: StockListRowModel) => {
      const percentageValue = period
        ? +current[PERIOD_KEY_MAPPING[period] as keyof StockListRowModel]
        : current.variacao_dia;
      percentageValue > this._variation[COLOR_RANGE_VARIATION_RANGE.POSITIVE] &&
        (this._variation[COLOR_RANGE_VARIATION_RANGE.POSITIVE] =
          percentageValue);
      percentageValue < this._variation[COLOR_RANGE_VARIATION_RANGE.NEGATIVE] &&
        (this._variation[COLOR_RANGE_VARIATION_RANGE.NEGATIVE] =
          percentageValue);
      return previous + +current.volume;
    }, 0);
  }

  private _getSmallBoxes(
    stocks: StockListRowModel[],
    total: number,
    minPercentage: number
  ): number {
    return stocks.reduce((previous: number, current: any) => {
      if ((+current.volume * 100) / total < minPercentage) return previous + 1;
      return previous;
    }, 0);
  }

  private _dataLabelValue(
    stock: StockListRowModel,
    useVolumeField: boolean,
    value: number,
    locale: string,
    orderBy?: TOrderBy
  ): string {
    if (useVolumeField)
      return newBigValueFormatter(
        +stock[HEATMAP_ORDER_BY_LIST_MAPPING['volume']]
      );
    if (orderBy == 'variation')
      return `${formatNumber(stock.variacao_dia, locale, `1.2-2`)}%`;
    return newBigValueFormatter(value);
  }

  getStockValueForList(
    stock: StockListRowModel,
    isPersonalList: boolean,
    orderBy?: TOrderBy
  ): number {
    if (orderBy)
      return orderBy == 'variation'
        ? ensurePositiveNumber(stock.variacao_dia)
        : +stock[HEATMAP_ORDER_BY_LIST_MAPPING[orderBy]];

    return isPersonalList
      ? +stock.volume
      : ensurePositiveNumber(stock.variacao_dia);
  }

  private _buildLabel(
    cdStock: string,
    labelValue: string,
    percentageValue: number,
    locale: string,
    situacao?: string
  ): string {
    let styles = 'style="color: #000; font-weight: normal;"';
    if (situacao === 'LEILAO')
      styles = styles
        .replace('#000', CHART_COLORS.FEEDBACK_WARNING)
        .replace('normal', 'bolder');
    const lValue = labelValue ? `${labelValue}<br/>` : '';
    return `<span ${styles}>${cdStock}<br/>${lValue}${formatNumber(
      percentageValue,
      locale,
      `1.2-2`
    )}%</span>`;
  }
}
