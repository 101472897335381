import { Injectable } from '@angular/core';
import { deepClone } from '@shared/rocket-components/utils';
import { CustomAnnotation } from 'scichart/Charting/Visuals/Annotations/CustomAnnotation';
import { ECoordinateMode } from 'scichart/Charting/Visuals/Annotations/AnnotationBase';
import {
  EHorizontalAnchorPoint,
  EVerticalAnchorPoint,
} from 'scichart/types/AnchorPoint';
import { ELabelPlacement } from 'scichart/types/LabelPlacement';
import { HorizontalLineAnnotation } from 'scichart/Charting/Visuals/Annotations/HorizontalLineAnnotation';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { getTextBoxTemplate } from '../templates/tiger-chart.template';
import { NEW_LINE_ALT_SHIFT } from '@shared/components/stock-chart/constants/stock-chart.constant';
import { ORDER_TYPE_DIC } from '@shared/dictionary/order.dictionary';
import { IAltShiftOrder } from '../interface/alt-shift-order.interface';
import { CONTEXT_MENU_ITEM_ENUM, CONTEXT_MENU_ORDER_TYPE } from '../enum';
import { AxisService } from './axis.service';
import { RTTextLengthService } from './text-length.service';
import { getLineBoxAnnotationId } from 'src/app/utils/utils.functions';
import { ScichartService } from './scichart.service';
import { Subject } from 'rxjs';
import { IOrderType } from '@core/interface';

@Injectable({
  providedIn: 'root',
})
export class AltShiftOrderService {
  private _newLineAltShift = NEW_LINE_ALT_SHIFT;
  private orderType!: IOrderType;
  private clickEvents$ = new Subject<{
    menuItem: CONTEXT_MENU_ITEM_ENUM;
    pointValueY: number;
    refComponent: string;
    typeOrder: CONTEXT_MENU_ORDER_TYPE;
    isInverse: boolean;
  }>();
  private scichartService!: ScichartService;
  constructor(
    private axisService: AxisService,
    private textLengthService: RTTextLengthService
  ) {
    this.scichartService = new ScichartService();
  }

  get clickEvents() {
    return this.clickEvents$.asObservable();
  }

  makeShiftAltAux(data: IAltShiftOrder) {
    this.scichartService.setBaseChart(data.baseChart);
    let orderType = ORDER_TYPE_DIC.get('B');
    let text = '';
    if (data.pressAlt) {
      orderType = ORDER_TYPE_DIC.get('S');
    }
    let auxId = 'LIMITADA';
    if (data.eventData.pointValues!!.y >= data.closeline!!.y1) {
      if (data.pressAlt) {
        text = 'Venda Limitada';
      } else {
        auxId = 'STOP';
        text = 'Compra Stop';
      }
    } else {
      if (data.pressAlt) {
        text = 'Venda Stop';
        auxId = 'STOP';
      } else {
        text = 'Compra Limitada';
      }
    }
    this.orderType = orderType!;
    this._newLineAltShift = `${auxId}_${NEW_LINE_ALT_SHIFT}`;
    this.deleteAllAltShiftAux(data.baseChart);
    const width =
      data.baseChart.sciChartSurface.annotationOverlaySurfaceViewRect.width;
    let x1 = width * 0.8;
    const textLength = this.textLengthService.getBoxLineTextLength(text);
    const widthLabel = x1 + textLength + 10;
    const diff = width - widthLabel;
    if (diff < 0) {
      x1 += diff;
    }
    const line = new HorizontalLineAnnotation({
      labelPlacement: ELabelPlacement.Axis,
      xCoordinateMode: ECoordinateMode.Pixel,
      showLabel: true,
      labelValue: this.axisService.yAxisLabelFormatter({
        ...data.yAxisFormatter,
        value: data.eventData.pointValues!!.y,
      }),
      stroke: orderType?.color,
      axisLabelFill: orderType?.color,
      axisLabelStroke: orderType?.boxTextColor,
      strokeThickness: 2,
      y1: data.eventData.pointValues!!.y,
      x1,
      strokeDashArray: [2, 2],
      isEditable: false,
      id: this._newLineAltShift,
      annotationsGripsRadius: 0,
      annotationsGripsFill: 'transparent',
      annotationsGripsStroke: 'transparent',
      selectionBoxStroke: 'transparent',
      selectionBoxDelta: 0,
      selectionBoxThickness: 0,
      xAxisId: `xAxis_${data.ref}`,
    });
    this.scichartService.annotationsAdd = line;
    const textColor = orderType!!.boxTextColor ?? 'white';

    const labelBox = new CustomAnnotation({
      svgString: getTextBoxTemplate(
        text,
        textLength,
        textColor,
        orderType!!.color,
        getLineBoxAnnotationId(this._newLineAltShift),
        false,
        undefined,
        false
      ),
      horizontalAnchorPoint: EHorizontalAnchorPoint.Left,
      verticalAnchorPoint: EVerticalAnchorPoint.Center,
      xCoordinateMode: ECoordinateMode.Pixel,
      x1,
      y1: data.eventData.pointValues!!.y,
      isEditable: false,
      id: getLineBoxAnnotationId(this._newLineAltShift),
      annotationsGripsRadius: 0,
      annotationsGripsFill: 'transparent',
      annotationsGripsStroke: 'transparent',
      selectionBoxStroke: 'transparent',
      selectionBoxDelta: 0,
      selectionBoxThickness: 0,
      xAxisId: `xAxis_${data.ref}`,
    });
    this.scichartService.annotationsAdd = labelBox;
  }

  deleteAltShiftAux(baseChart: TWebAssemblyChart) {
    if (!baseChart) {
      return;
    }
    this.scichartService.setBaseChart(baseChart);
    const annotation = baseChart.sciChartSurface.annotations.getById(
      deepClone(this._newLineAltShift)
    );
    const annotationBox = baseChart.sciChartSurface.annotations.getById(
      `${deepClone(this._newLineAltShift)}_box`
    );
    if (annotation) {
      this.scichartService.annotationsRemove = annotation;
      this.scichartService.annotationsRemove = annotationBox;
    }
  }

  deleteAllAltShiftAux(baseChart: TWebAssemblyChart) {
    this.scichartService.setBaseChart(baseChart);
    baseChart.sciChartSurface.annotations
      .asArray()
      .filter(
        (annotation) =>
          annotation.id.includes(NEW_LINE_ALT_SHIFT) &&
          (annotation.id !== deepClone(this._newLineAltShift) ||
            annotation.id !== `${deepClone(this._newLineAltShift)}_box`)
      )
      .forEach((annotation) => {
        baseChart.sciChartSurface.annotations.remove(annotation);
      });
  }

  listener(baseChart: TWebAssemblyChart, refComponent: string) {
    this.scichartService.setBaseChart(baseChart);
    const doc = document.getElementById(refComponent);
    if (doc) {
      doc.tabIndex = 1;
      doc.addEventListener('click', (event) => {
        const annotation = this.scichartService.getAnnotationsById(
          this._newLineAltShift
        );
        const types = ['LIMITADA', 'STOP'];
        const order = this._newLineAltShift.split('_')[0];
        if (
          annotation &&
          (event.altKey || event.shiftKey) &&
          types.indexOf(order) != -1
        ) {
          const res =
            order === 'STOP'
              ? CONTEXT_MENU_ITEM_ENUM.TYPE_STOP_ORDER
              : CONTEXT_MENU_ITEM_ENUM.FAST_TYPE_ORDER;
          const typeOrder =
            this.orderType.type === 'B'
              ? CONTEXT_MENU_ORDER_TYPE.BUY
              : CONTEXT_MENU_ORDER_TYPE.SELL;
          this.clickEvents$.next({
            menuItem: res,
            pointValueY: annotation.y1,
            refComponent,
            typeOrder,
            isInverse: order === 'STOP',
          });
          this.deleteAllAltShiftAux(baseChart);
        }
      });
    }
  }
}
