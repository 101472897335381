import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IInterval } from '@shared/components/stock-chart/interface/stock-chart.interface';
import { TIGER_INTERVAL_ENUM } from '@shared/tiger-chart/enum';

@Component({
  selector: 'app-dropdown-interval',
  template: `
    <app-dropdown menuAlign="dropdown-menu-right" [title]="tooltipText">
      <button appDropdownButton class="btn btn-icon py-3 fs-6 mx-2">
        {{ label }}
      </button>
      <interval-options
        [options]="options"
        [currentID]="currentOption"
        (onSelectOption)="handleSelectedOption.emit($event)"
      ></interval-options>
    </app-dropdown>
  `,
})
export class DropDownIntervalComponent {
  @Input() tooltipText: string = '';
  @Input() label: string | undefined = '';
  @Input() options: IInterval[] = [];
  @Input() currentOption!: TIGER_INTERVAL_ENUM;
  @Output() handleSelectedOption: EventEmitter<TIGER_INTERVAL_ENUM> =
    new EventEmitter<TIGER_INTERVAL_ENUM>();
}
