export const ORDER = [
  {
    label: 'Ativo',
    field: 'cd_stock',
    isCheck: false,
    width: 123,
  },
  {
    label: 'Data',
    field: 'dh_creation_time',
    isCheck: false,
    width: 95,
  },
  {
    label: 'Lado',
    field: 'side',
    isCheck: false,
    width: 114,
  },
  {
    label: 'Quantidade',
    field: 'qtty',
    isCheck: false,
    width: 147,
  },
  {
    label: 'Preço',
    field: 'price',
    isCheck: false,
    width: 122,
  },
  {
    label: 'Stop',
    field: 'price_stop',
    isCheck: false,
    width: 123,
  },
  {
    label: 'Situação',
    field: 'ds_order_status',
    isCheck: false,
    width: 122,
  },
  {
    label: 'Tipo',
    field: 'ds_order_type',
    isCheck: false,
    width: 95,
  },
  {
    label: 'Exe/Tot',
    field: 'qtty_executed',
    isCheck: false,
    width: 115,
  },
  {
    label: 'Executado',
    field: 'price_average',
    isCheck: false,
    width: 147,
  },
  {
    label: 'Validade',
    field: 'ds_order_time_enforce',
    isCheck: false,
    width: 122,
  },
  {
    label: 'Modo',
    field: 'is_day_trade',
    isCheck: false,
    width: 102,
  },
];

export const POSITION = [
  {
    label: 'Ativo',
    field: 'cd_stock',
    isCheck: false,
    width: 126,
  },
  {
    label: 'Preço médio',
    field: 'vl_price_avg_swing',
    isCheck: false,
    width: 152,
  },
  {
    label: 'Preço atual',
    field: 'preco_ultimo',
    isCheck: false,
    width: 146,
  },
  {
    label: 'Valor total',
    field: 'pl_swing',
    isCheck: false,
    width: 135,
  },
  {
    label: 'Quantidade aberta',
    field: 'net_day_swing',
    isCheck: false,
    width: 204,
  },
  {
    label: 'P&L total',
    field: 'pnl_swing',
    isCheck: false,
    width: 126,
  },
  {
    label: 'P&L dia',
    field: 'pnl_day_swing',
    isCheck: false,
    width: 151,
  },
  {
    label: 'P&L aberto',
    field: 'pnl_open_swing',
    isCheck: false,
    width: 148,
  },
  {
    label: 'Quantidade custódia',
    field: 'qtty_final_swing',
    isCheck: false,
    width: 214,
  },
];

export const ALERTS = [
  {
    label: 'Data',
    field: 'dh_insert',
    isCheck: false,
    width: 126,
  },
  {
    label: 'Ativo',
    field: 'cd_stock',
    isCheck: false,
    width: 126,
  },
  {
    label: 'Preço',
    field: 'vl_current_price',
    isCheck: false,
    width: 126,
  },
  {
    label: 'Alerta',
    field: 'vl_value',
    isCheck: false,
    width: 126,
  },
  {
    label: 'Situação',
    field: 'alert_status',
    isCheck: false,
    width: 126,
  },
];
