import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rocket-modal-body',
  template: `<div [class]="'modal-body ' + css">
    <ng-content></ng-content>
  </div>`,
  styles: [
    `
      :host(app-rocket-modal-body) {
        display: contents;
      }
    `,
  ],
})
export class RocketModalBodyComponent {
  @Input() css = '';
}
