import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TotpService {
  async generateTotp(
    key: string,
    secs: number = 30,
    digits: number = 6
  ): Promise<string> {
    try {
      return await this.hotp(
        this.unbase32(key),
        this.pack64bu(Math.floor(Date.now() / 1000 / secs)),
        digits
      );
    } catch (error) {
      console.error('GENERATE_OTP_ERROR:', error);
      return '';
    }
  }

  private async hotp(
    key: Uint8Array,
    counter: ArrayBuffer,
    digits: number
  ): Promise<string> {
    try {
      const cryptoSubtle = window.crypto.subtle;
      if (!cryptoSubtle) {
        console.error('no window.crypto.subtle object available');
        return '';
      }
      const k = await cryptoSubtle.importKey(
        'raw',
        key,
        { name: 'HMAC', hash: 'SHA-1' },
        false,
        ['sign']
      );
      return this.hotpTruncate(
        await cryptoSubtle.sign('HMAC', k, counter),
        digits
      );
    } catch (error) {
      console.error('HOYP_ERROR', error);
      return '';
    }
  }

  private hotpTruncate(buf: ArrayBuffer, digits: number): string {
    try {
      const a = new Uint8Array(buf);
      const i = a[19] & 0xf;
      const result =
        (((a[i] & 0x7f) << 24) |
          (a[i + 1] << 16) |
          (a[i + 2] << 8) |
          a[i + 3]) %
        10 ** digits;
      return this.fmt(10, digits, result);
    } catch (error) {
      console.error('HOTP_TRUNCATE_ERROR:', error);
      return '';
    }
  }

  private fmt(base: number, width: number, num: number): string {
    try {
      return num.toString(base).padStart(width, '0');
    } catch (error) {
      console.error('FML_ERROR', error);
      return '';
    }
  }

  private unbase32(s: string): Uint8Array {
    try {
      const t = (s.toLowerCase().match(/\S/g) || [])
        .map((c) => {
          const i = 'abcdefghijklmnopqrstuvwxyz234567'.indexOf(c);
          if (i < 0) throw new Error(`bad char '${c}' in key`);
          return this.fmt(2, 5, i);
        })
        .join('');
      if (t.length < 8) throw new Error('key too short');
      return new Uint8Array(t.match(/.{8}/g)!.map((d) => parseInt(d, 2)));
    } catch (error) {
      console.error('UNBASE32_ERROR', error);
      return '' as any;
    }
  }

  private pack64bu(v: number): ArrayBuffer {
    try {
      const b = new ArrayBuffer(8);
      const d = new DataView(b);
      d.setUint32(0, Math.floor(v / 2 ** 32));
      d.setUint32(4, v);
      return b;
    } catch (error) {
      console.error('PACK64_BU_ERROR', error);
      return '' as any;
    }
  }
}
