import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, shareReplay, tap } from 'rxjs';
import { RestService } from '@shared/services/rest/rest.service';
import { ToastService } from '@shared/services/toast.service';
import { SideDict } from '@shared/enum/buyOrSell.enum';
import {
  formatDate,
  getSinonimousByStock,
} from 'src/app/utils/utils.functions';
import { OrderCacheService } from '@shared/services/core/cache/order-cache.service';
import { IOrders } from 'src/app/core/interface';
import { IStrategyParams } from './interface/params';
import {
  IStrategy,
  IStrategyCondition,
} from '@core/layout/header/strategy/interface/strategy.interface';
import { OrderTokenService } from '@shared/rocket-components/modal-order-token/order-token.service';
import { ListOrdersService } from '@shared/services/core/list-orders/orders.service';
import { TermsService } from '../../terms/v2/terms.service';
import { isRequiredTerm } from '@shared/modals/modal-accept-terms/constants/modal-accept-terms.constants';
import {
  IGetRisksPersonal,
  ISendRisksPersonal,
} from './interface/risks-personal.interface';

@Injectable({
  providedIn: 'root',
})
export class OrsGatewayService extends RestService {
  // Serviço para pegar ordens
  override _url: string = 'api/trademap/v1/ors-gateway';

  constructor(
    http: HttpClient,
    private _toastService: ToastService,
    private _orderCacheService: OrderCacheService,
    private _orderToken: OrderTokenService,
    private listOrdersService: ListOrdersService,
    private termsService: TermsService
  ) {
    super(http);
  }

  private getAllOrdersPaged(id_broker: number, period: string) {
    this._orderCacheService.clearCache();
    return this.post<any>('getAllOrdersPaged', {
      filters: {
        period,
      },
      id_broker,
    }).pipe(
      map((response: any) => {
        const { result } = response.data;
        const orders: IOrders[] = [];
        for (let i = result.orders.length - 1; i >= 0; i--) {
          const order = getSinonimousByStock(result.orders[i]);
          order.dt_expirate_date = order.dt_expirate_date
            ? formatDate(order.dt_expirate_date)
            : null;
          orders.push(order);
        }
        return { orders };
      }),
      tap((data: any) => {
        this.listOrdersService.saveOrders(data.orders, data.hash);
      }),
      shareReplay(1)
    );
  }

  getAllOrdersPagedCached(idBroker: number, period: string = 'D3') {
    /*let request$: any = this._orderCacheService.getValue('ORDERS');
    if (!request$) {
      request$ = this.getAllOrdersPaged();
      this._orderCacheService.setValue(request$, 'ORDERS');
    }*/
    return this.getAllOrdersPaged(idBroker, period);
  }

  public setOrderCancelRequest(
    idOrder: number | string,
    idBroker: number
  ): Observable<any> {
    if (this._brokerTermBeforeHandleOrder(idBroker, idOrder)) return of(null);

    const params = {
      idOrdem: idOrder,
      ipMachine: 'NO_IP',
      idBroker: null,
      cdStock: null,
      token: '',
    };
    return this.post<any>('setOrderCancelRequest', params).pipe(
      map((res) => res.data),
      catchError((res) => {
        this._orderToken.isInvalidTradingToken(res);
        this._toastService.showToast('error', res.error);
        return of(res.data);
      })
    );
  }

  public setValidSession(tradingToken: string): Observable<any> {
    return this.post<any>('setValidSession', {
      token: tradingToken,
    }).pipe(
      map((res) => {
        return res.data;
      }),
      catchError((res) => {
        this._toastService.showToast('error', res.error);
        return of(res.data);
      })
    );
  }

  public getAllStrategies(): Observable<any> {
    return this.post<any>('getAllStrategies', {}).pipe(
      map((res) => {
        return res.data.result;
      }),
      catchError((res: any) => {
        this._toastService.showToast(
          'error',
          'Ocorreu um erro ao buscar as estratégias criadas, tente novamente.'
        );
        return of(res.data);
      })
    );
  }

  public createStrategy(strategyParams: IStrategyParams): Observable<any> {
    return this.post<any>('createStrategy', {
      ...strategyParams,
    }).pipe(
      map((res) => {
        if (res.data && res.data.success) {
          this._toastService.showToast(
            'success',
            'Estratégia criada com sucesso!'
          );
        }
        return res.data;
      }),
      catchError((res: any) => {
        this._toastService.showToast(
          'error',
          'Ocorreu um erro ao criar a estratégia, tente novamente.'
        );
        console.error(res.message);
        return of(res.data);
      })
    );
  }

  public createStrategyItem(
    idStrategy: number,
    strategyItemToCreate: IStrategyCondition[]
  ): Observable<any> {
    return this.post<any>('create-strategy-item', {
      idStrategy,
      items: strategyItemToCreate,
    }).pipe(
      map((res) => res.data),
      catchError((res: any) => {
        this._toastService.showToast(
          'error',
          'Ocorreu um erro ao criar os novos itens da estratégia, tente novamente.'
        );
        console.error(res.message);
        return of(res.data);
      })
    );
  }

  public editStrategyInfos(strategyParams: IStrategyParams): Observable<any> {
    return this.post<any>(`updateStrategy/${strategyParams.idStrategy}`, {
      nameStrategy: strategyParams.nameStrategy,
      typeStrategy: strategyParams.typeStrategy,
    }).pipe(
      map((res) => {
        this._toastService.showToast(
          'success',
          'Estratégia editada com sucesso!'
        );
        return res.data;
      }),
      catchError((res: any) => {
        this._toastService.showToast(
          'error',
          'Ocorreu um erro ao editar a estratégia, tente novamente.'
        );
        console.error('EDIT_STRATEGY_ERROR: ', res.message);
        return of(res.data);
      })
    );
  }

  public editStrategyItems(strategyItem: IStrategyCondition): Observable<any> {
    return this.post<any>(`updateStrategyItem/${strategyItem.id_item}`, {
      idStrategy: strategyItem.id_strategy,
      ...strategyItem,
    }).pipe(
      map((res) => res.data),
      catchError((res: any) => {
        this._toastService.showToast(
          'error',
          'Ocorreu um erro ao editar a estratégia, tente novamente.'
        );
        console.error('EDIT_STRATEGY_ITEMS_ERROR: ', res.message);
        return of(res.data);
      })
    );
  }

  public deleteStrategy(strategyParams: IStrategy): Observable<any> {
    return this.post<any>(
      `deleteStrategy/${strategyParams.idStrategy}`,
      {}
    ).pipe(
      map((res) => {
        this._toastService.showToast(
          'success',
          'Estratégia deletada com sucesso!'
        );
        return res.data;
      }),
      catchError((res: any) => {
        this._toastService.showToast(
          'error',
          'Ocorreu um erro ao deletar a estratégia, tente novamente.'
        );
        console.error('DELETE_STRATEGY_ERROR: ', res.message);
        return of(res.data);
      })
    );
  }

  public deleteStrategyItems(idItemToDelete: number): Observable<any> {
    return this.post<any>(`deleteStrategyItem/${idItemToDelete}`, {}).pipe(
      map((res) => res.data),
      catchError((res: any) => {
        this._toastService.showToast(
          'error',
          'Ocorreu um erro ao deletar a estratégia, tente novamente.'
        );
        console.error('DELETE_STRATEGY_ITEMS_ERROR: ', res.message);
        return of(res.data);
      })
    );
  }

  public getDetOrder(idOrder: number): Observable<any> {
    const params = { id_order: idOrder, hist: true };
    return this.post<any>(`get-det-order`, params).pipe(
      map((res) => res.data?.result || []),
      catchError((res: any) => {
        this._toastService.showToast(
          'error',
          'Ocorreu um erro ao buscar os detalhes da ordem, tente novamente.'
        );
        return of(res.data);
      })
    );
  }

  private _brokerTermBeforeHandleOrder(
    idBroker: number,
    idOrder: number | string
  ): boolean {
    const broker = this.termsService.hasBrokerTermToAccept(idBroker);
    if (broker) {
      this.termsService
        .openTermsModal({
          isBrokerTerm: true,
          brokerName: broker.nm_broker_nickname,
          code: broker.term.status,
          content: broker.term.content,
          idBrokerTerm: broker.term.idTerm,
        })!
        .subscribe((accepted) => {
          if (accepted || !isRequiredTerm(broker.term.status)) {
            this.termsService.updateBrokerTermStatus(idBroker);
            this.setOrderCancelRequest(idOrder, idBroker).subscribe();
          }
          this.termsService.disableTerminal(false);
        });
      return true;
    }
    return false;
  }

  public getRisksPersonal(): Observable<IGetRisksPersonal[]> {
    return this.get<any>(`get-risks-personal`).pipe(
      map((res) => res.data?.result || []),
      catchError(() => {
        return of([]);
      })
    );
  }

  public saveRisksPersonal(risk: ISendRisksPersonal): Observable<any> {
    return this.post<any>(`save-risks-personal`, risk).pipe(
      map((res) => {
        const message = risk.block_edit
          ? 'Gerenciamento de Risco salvo com sucesso e bloqueio ativado!'
          : 'Gerenciamento de Risco salvo com sucesso!';
        this._toastService.showToast('success', message);
        return res.data.success;
      }),
      catchError((res: any) => {
        this._toastService.showToast(
          'error',
          'Erro ao salvar Gerenciamento de Risco!'
        );
        return of(res.data.success);
      })
    );
  }

  public updateRisksPersonal(risk: ISendRisksPersonal): Observable<any> {
    return this.post<any>(`update-risks-personal`, risk).pipe(
      map((res) => {
        const message = risk.block_edit
          ? 'Gerenciamento de Risco salvo com sucesso e bloqueio ativado!'
          : 'Gerenciamento de Risco salvo com sucesso!';
        this._toastService.showToast('success', message);
        return res.data.success;
      }),
      catchError((res: any) => {
        this._toastService.showToast(
          'error',
          'Erro ao salvar Gerenciamento de Risco!'
        );
        return of(res.data.success);
      })
    );
  }

  public risksSubscribe(type: string): Observable<any> {
    return this.post<any>(`risk-subscribe`, {
      risk_type: type,
    }).pipe(
      map((res) => res.data.success),
      catchError((res: any) => {
        return of(res.data.success);
      })
    );
  }
}

export const ORS_MESSAGES = {
  CANCEL_SENT: 'Cancelamento da ordem enviado',
  EDIT_SENT: 'Edição da ordem enviado',
  NEWORDER_SENT: (side: any, cd_stock: any) =>
    `Ordem de ${SideDict[side]} de ${cd_stock} Assim que ela for executada, a gente te avisa ;)`,
};
