import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { RocketModalModule } from '@shared/rocket-components/components';
import { NewsModalComponent } from './news-modal.component';

@NgModule({
  imports: [CommonModule, NgxExtendedPdfViewerModule, RocketModalModule],
  declarations: [NewsModalComponent],
  exports: [NewsModalComponent],
})
export class NewsModalModule {}
