import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { system } from '@core/system/system.service';
import { DeviceValidatorService } from '../../services/device-validator.service';

@Component({
  selector: 'app-invalid-device',
  templateUrl: './invalid-device.component.html',
  styleUrls: ['./invalid-device.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvalidDeviceComponent implements OnInit {
  public userFirstName: string = '';

  constructor(private router: Router, private deviceValidator: DeviceValidatorService) {}

  ngOnInit() {
    if(system.userAuthenticated && this.deviceValidator.isMobile()){
      this.userFirstName = system.userAuthenticated.investor.name?.split(' ')[0]
    }else{
      this.router.navigate(['auth/login']);
    }
  }

}
