import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { TrademapCDNService } from '@shared/services/api/cdn/trademap-cdn.service';
import { IBrokersToConnect } from '@shared/services/api/trademap/V9/models/brokers-to-connect.interface';
import { filter } from 'rxjs';

@Component({
  selector: 'app-card-broker',
  templateUrl: './card-broker.component.html',
  styles: [
    `
      .card-broker {
        width: 345px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardBrokerComponent implements OnChanges {
  @Input() public broker!: IBrokersToConnect;

  @Output() brokerSelected: EventEmitter<any> = new EventEmitter();
  @Output() brokerDisconnect: EventEmitter<any> = new EventEmitter();

  public svgDataUrl: SafeResourceUrl = '';
  private _cdr = inject(ChangeDetectorRef);
  private _trademapCDN = inject(TrademapCDNService);

  ngOnChanges(changes: SimpleChanges) {
    if (changes['broker']?.currentValue) this.loadSvgContent();
  }

  loadSvgContent() {
    if (!this.broker.brokerLogo) return;
    this._trademapCDN
      .getBrokerLogo(this.broker.brokerLogo)
      .pipe(filter((logo) => logo !== null))
      .subscribe((logo) => {
        this.svgDataUrl = logo!;
        this._cdr.detectChanges();
      });
  }

  public selectBroker() {
    if (this.broker.hasAccount) return;
    this.brokerSelected.emit(this.broker);
  }

  public disconnectBroker() {
    if (!this.broker.hasAccount) return;
    this.brokerDisconnect.emit(this.broker);
  }
}
