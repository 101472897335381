<div
  #scrollElementRef
  [ngClass]="{ 'overflow-hidden': loading, wrapper: !isChildren }"
  [class]="containerCss"
  [ngStyle]="{ height: height }"
  (scroll)="scrollDebounce($event)"
  (appResized)="onResize()"
>
  <table
    [id]="tableRef"
    [class]="css"
    [ngStyle]="{ width: width }"
    [style.table-layout]="layout"
    [ngClass]="{ 'sub-table': isChildren }"
  >
    <tr *ngIf="tableHeader?.length">
      <ng-container *ngFor="let header of tableHeader">
        <ng-container *ngTemplateOutlet="header.content"></ng-container>
      </ng-container>
    </tr>
    <ng-content></ng-content>
  </table>
</div>
<app-dropdown *ngIf="tableDropdownOptions?.length">
  <button
    class="btn btn-primary dropdown-toggle d-none"
    type="button"
    appDropdownButton
    [rtDropnAutoClose]="'inside'"
  ></button>
  <li *ngFor="let option of tableDropdownOptions">
    <button
      class="dropdown-item"
      [class]="option.css"
      type="button"
      (click)="option.action ? option.action(option) : null"
    >
      <fla-icon *ngIf="option.icon" [name]="option.icon" css="pr-2"></fla-icon>
      {{ option.text }}
    </button>
  </li>
</app-dropdown>

<ng-container *ngIf="loading">
  <div
    class="h-100 w-100 modal-backdrop position-absolute"
    style="background-color: rgb(19 20 27 / 80%); filter: unset"
  >
    <span class="fs-5 text-white fw-bold">
      Carregando conteúdo, por favor aguarde.
    </span>
    <div class="spinner-border text-brand-primary" role="status"></div>
  </div>
</ng-container>
