import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appReplaceTextHtml]',
  standalone: true,
})
export class ReplaceTextHtmlDirective implements OnInit {
  @Input('appReplaceTextHtml') textList!: string[];
  @Input() listVariables!: string[];

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.replaceAllText();
  }

  private replaceAllText(): void {
    this.listVariables.forEach((item: string, index: number) => {
      this.elementRef.nativeElement.innerHTML =
        this.elementRef.nativeElement.innerHTML.replaceAll(
          item,
          this.textList[index]
        );
    });
  }
}
