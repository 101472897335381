import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalOrderTokenComponent } from './modal-order-token.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalOrderTokenHelperComponent } from './modal-order-token-helper/modal-order-token-helper.component';
import {
  FlaCheckModule,
  FlaIconModule,
  FlaInputModule,
  RocketButtonModule,
  RocketModalModule,
} from '@shared/rocket-components/components';

@NgModule({
  declarations: [ModalOrderTokenComponent, ModalOrderTokenHelperComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlaCheckModule,
    FlaInputModule,
    FlaIconModule,
    FormsModule,

    RocketButtonModule,
    RocketModalModule,
  ],
  exports: [ModalOrderTokenComponent],
  providers: [],
})
export class ModalOrderTokenModule {}
