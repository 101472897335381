import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ROCKET_MODAL_DATA,
  RocketModalRef,
  RocketModalService,
} from '@shared/rocket-components/components';
import {
  InvestorData,
  UserService,
} from '@shared/services/api/trademap/v1/user.service';
import { TTokenAuthenticator, TTokenPreferenceAuthenticator } from './types';
import { ModalTokenService } from './modal-token.service';
import { Subscription, take } from 'rxjs';
import { OrderTokenService } from '@shared/rocket-components/modal-order-token/order-token.service';
import { ModalAvisoComponent } from '@shared/components/modal-aviso/modal-aviso.component';
import { system } from '@core/system/system.service';
import { WhatsappService } from '../whatsapp/whatsapp.service';
import { OtpService } from '@shared/services/api/authentication/v3/otp.service';

@Component({
  selector: 'app-token-config',
  templateUrl: './modal-token-config.component.html',
  styleUrls: ['./modal-token-config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalTokenConfigComponent
  extends RocketModalRef
  implements OnInit, OnDestroy
{
  tokenPreference!: TTokenPreferenceAuthenticator;
  data: any;
  investorData: InvestorData | any = null;
  selectedAuthenticator: TTokenAuthenticator | null = null;
  loading = true;
  disconnectingName = '';
  title = '';

  private _defaultTitle = 'Segundo fator de segurança 🔑';

  private reloadConfig$!: Subscription;
  private closeModal$!: Subscription;

  constructor(
    @Inject(ROCKET_MODAL_DATA) data: any,
    service: RocketModalService,
    private _otpService: OtpService,
    private _userService: UserService,
    private _elementRef: ElementRef,
    private _cdr: ChangeDetectorRef,
    private _modalTokenService: ModalTokenService,
    private _orderTokenService: OrderTokenService,
    private _modalService: RocketModalService,
    private _whatsAppService: WhatsappService
  ) {
    super(service);
    this.data = data;
    this.title = this._defaultTitle;
    this._onUpdateTokenPreferences();
  }

  private _onUpdateTokenPreferences = () => {
    this.reloadConfig$ = this._modalTokenService.onReloadConfig$.subscribe(
      (token?: string) => {
        if (token) {
          this._orderTokenService.tradingToken = token;
          this._cdr.detectChanges();
        }

        if (this.data && this.data.callback) {
          this.close();
          return;
        }
        this.loading = true;
        this.selectedAuthenticator = null;
        this.getTokenPreference();
      }
    );
    this.closeModal$ = this._modalTokenService.onCloseModal$.subscribe(() => {
      this.close();
    });
  };

  ngOnInit() {
    this.loadConfig();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.data && this.data.callback && this.data.callback(true);
    this.reloadConfig$ && this.reloadConfig$.unsubscribe();
    this.closeModal$ && this.closeModal$.unsubscribe();
  }

  override close(value?: any, isSecondModal?: boolean): void {
    super.close(value, isSecondModal);
    this.data && this.data.callback && this.data.callback();
  }

  selectAuthenticator(authenticator: TTokenAuthenticator | null) {
    if (!system.userAuthenticated.investor.phone_mobile) {
      this._withoutToken();
      return;
    }
    this.selectedAuthenticator = authenticator;
  }

  onDisconnecting(disconnecting: string) {
    this.disconnectingName = disconnecting;
    this.title = disconnecting
      ? 'Tem certeza que deseja retirar seu vínculo com o ' + disconnecting
      : this._defaultTitle;
    this._cdr.detectChanges();
  }

  loadConfig() {
    this.loading = true;
    this.selectedAuthenticator = null;
    this._cdr.detectChanges();
    this.loadInvestorData();

    if (this.data && this.data.tokenPreference) {
      this.tokenPreference = this.data.tokenPreference;

      if (this.tokenPreference == 'TRADEMAP') {
        this.title = 'Atualize seu token';
        this.selectedAuthenticator = {
          name: 'Trademap',
          icon: '',
          id: 'TRADEMAP',
        };
      }

      this.loading = false;
      this._cdr.detectChanges();
      return;
    }

    this.getTokenPreference();
  }

  getTokenPreference() {
    if (!this.loading) {
      this.loading = true;
      this.selectedAuthenticator = null;
      this._cdr.detectChanges();
    }
    this._otpService.userTokenPreferences().subscribe((data: any) => {
      if (data !== 'UNDEFINED_TOKEN' && this.data.callback) {
        this.close();
      }
      this.tokenPreference = data;
      this.loading = false;
      this._cdr.detectChanges();
    });
  }

  loadInvestorData() {
    this._userService.getInvestorData().subscribe((data: any) => {
      this.investorData = data;
      this._cdr.detectChanges();
    });
  }

  closeModal() {
    this.close();
  }

  private _withoutToken(): void {
    this._modalService
      .open(ModalAvisoComponent, {
        toggleBetweenModal: this._elementRef,
        data: {
          title: 'Notificação Importante',
          text: 'Para garantir a sua segurança, precisamos validar o seu número de celular antes de ativar o segundo fator de segurança. Por favor, entre em contato com nosso atendimento ao cliente.',
          cancelButton: 'Cancelar',
          confirmeButton: 'Entre em contato com atendimento',
          showButtons: true,
        },
      })
      .afterDismissed.pipe(take(1))
      .subscribe((action: { type: string; value: boolean }) => {
        if (action.value === true) {
          this._whatsAppService.openWhatsAppRocket();
          return;
        }
      });
  }
}
