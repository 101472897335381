import { formatNumber } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { deepClone } from '@shared/rocket-components/utils';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import {
  DrawTools,
  LineToolsOptions,
  StyleTool,
} from '@shared/tiger-chart/draw-tools/draw-tools.interface';
import { TIGER_MOUSE_EVENT_DATA_TYPE } from '@shared/tiger-chart/enum';
import { ICandleConfigs } from '@shared/tiger-chart/interface';
import {
  TOOLS,
  fibonacciTextXOffset,
} from '@shared/tiger-chart/tiger-chart-tools/tiger-chart-tools.const';
import {
  TOOLS_CALCULATION,
  TOOLS_ENUM,
  TOOLS_METHOD,
  TOOLS_TYPE_CALCULATION,
  TOOLS_TYPE_DRAW,
} from '@shared/tiger-chart/tiger-chart-tools/tiger-chart-tools.enum';
import {
  DrawDeleted,
  DrawToolConfiguration,
  saveConfiguration,
  TIGER_CHART_TOOL,
  TIGER_CHART_TOOL_CHILD,
} from '@shared/tiger-chart/tiger-chart-tools/tiger-chart-tools.interface';
import { ColorsBaseService } from '@shared/tiger-chart/tiger-chart-tools/toolbar-draw/colors-base.service';
import { TYPE_EVENT } from '@shared/tiger-chart/tiger-chart-tools/toolbar-draw/toolbar-draw.enum';
import { ToolbarDrawService } from '@shared/tiger-chart/tiger-chart-tools/toolbar-draw/toolbar-draw.service';
import { TMouseEventData } from '@shared/tiger-chart/types/tiger-chart.types';
import { Subject, auditTime, debounceTime, delay, map, tap } from 'rxjs';
import {
  EHorizontalAnchorPoint,
  EVerticalAnchorPoint,
} from 'scichart/types/AnchorPoint';
import { EDraggingGripPoint } from 'scichart/Charting/Visuals/Annotations/AnnotationBase';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { Dictionary } from 'src/app/core/models';
import {
  getCdStockParam,
  isNullOrUndefined,
} from 'src/app/utils/utils.functions';
import { StockChartService } from './stock-chart.service';
import { CustomPreferencesService } from '@shared/services/api/nitro-ws/v1/custom-preferences.service';
import { HorizontalLineAnnotation } from 'scichart/Charting/Visuals/Annotations/HorizontalLineAnnotation';
import { VerticalLineAnnotation } from 'scichart/Charting/Visuals/Annotations/VerticalLineAnnotation';
import {
  color,
  ToolbarModel,
} from '@shared/tiger-chart/tiger-chart-tools/toolbar-draw/toolbar-draw.interface';
import { Point } from 'scichart/Core/Point';
import { TextAnnotation } from 'scichart/Charting/Visuals/Annotations/TextAnnotation';
import { FastOhlcRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastOhlcRenderableSeries';
import { ModalConfigDrawComponent } from '@shared/tiger-chart/tiger-chart-tools/toolbar-draw/modal-config-draw/modal-config-draw.component';
import { EChart2DModifierType } from 'scichart/types/ChartModifierType';
import { RocketModalService } from '@shared/rocket-components/components/index';
import { DrawHelperMessageService } from '@shared/tiger-chart/tiger-chart-tools/draw-helper-message/draw-helper-message.service';
import { NumberRange } from 'scichart/Core/NumberRange';
import { DrawsService } from './draws.service';
import { DRAW } from '../interface/draws.interface';
import { StockChartModalService } from '../parts/modal-more-options/service/stock-chart-modal.service';
import { BaseIndicator } from '@shared/tiger-chart/indicators';
import { CANDLE_IDS } from '@shared/tiger-chart/constants/tiger-chart.constants';
import { MAP_INTERVAL_CHART } from '../constants/stock-chart.constant';
import { CategoryCoordinateCalculator } from 'scichart';
import { ThemePreferencesService } from '@shared/services/core/custom-preferences/theme/theme-preferences.service';
import {
  HorizontalLineTools,
  TextTools,
  VerticalLineTools,
} from '@shared/tiger-chart/draw-tools';

@Injectable()
export class DrawToolsService {
  private isAllVisible: boolean = true;
  stock: any;
  isBrush: boolean = false;
  private _isLocked: boolean = false;
  private temporaryToolPosition = 0;
  set isLocked(value: boolean) {
    if (this._isLocked !== value) {
      this._isLocked = value;
      this.isLocked$.next(value);
    }
  }
  get isLocked() {
    return this._isLocked;
  }
  openedModalDrawEdit: boolean = false;
  isLocked$ = new Subject<boolean>();
  toolEvent$ = new Subject<{
    componentRef: string;
    tool: TIGER_CHART_TOOL | null;
    countStepClick: number;
    typeEvent: TYPE_EVENT;
  }>();
  axisChanged$ = new Subject<{
    type: 'YAXIS' | 'XAXIS';
    visibleRange: NumberRange;
  }>();
  blockEditDraw$ = new Subject<boolean>();
  toolsDict = new Dictionary<Array<TIGER_CHART_TOOL>>();
  drawDict = new Dictionary<DrawTools>();
  drawChildrenDic = new Dictionary<Array<DrawTools>>();
  baseChartDic = new Dictionary<TWebAssemblyChart>();
  childrenFatherDic = new Dictionary<DrawTools>();
  refComponentDic = new Dictionary<{ ref: string }>();
  redrawTool = new Dictionary<{
    tool: TIGER_CHART_TOOL;
    draw: DrawTools;
    drawConfig: saveConfiguration;
    configuration?: DrawToolConfiguration;
    isRedraw: boolean;
  }>();
  deletedDraws: DrawDeleted[] = [];
  private lastSavedToolPosition!: number;
  private subCharts = new Dictionary<{
    indicator: BaseIndicator;
    height: number;
    dividerPosition: number;
  }>();
  private resizeChartRenewSVG$ = new Subject<{
    baseChart: TWebAssemblyChart;
    draw: DrawTools;
    refComponent: string;
    configs: ICandleConfigs;
    chartHeight: number;
    stock: any;
    configsTools: saveConfiguration[];
  }>();
  private updateDrawConfig$ = new Subject<{
    stocksDraw: any;
  }>();
  private addDraw$ = new Subject<{
    tap: any;
    function: any;
  }>();
  private updateDrawPosition$ = new Subject<{
    length: number;
  }>();
  private updateCustomSVG$ = new Subject<{
    annotation: DrawTools;
  }>();
  drawResize: DrawTools[] = [];

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private stockChartService: StockChartService,
    private colorsBaseService: ColorsBaseService,
    private toolbarDrawService: ToolbarDrawService,
    private customPreferencesService: CustomPreferencesService,
    private _rocketModalService: RocketModalService,
    private drawHelperMessageService: DrawHelperMessageService,
    private drawsService: DrawsService,
    private stockChartModalService: StockChartModalService,
    private themeService: ThemePreferencesService
  ) {
    this.startSubscriptions();
  }

  private startSubscriptions() {
    this.drawsService.drawClick.subscribe((data) => {
      if (data.type === 'CLICK') {
        this.drawClick(data.tool);
        return;
      }
      this.updateSharedDrawChart(data.tool, data.childrenId);
    });
    this.resizeChartRenewSVG$
      .pipe(
        auditTime(100),
        map((data) => {
          const { baseChart, configsTools } = data;
          const drawConfigs: saveConfiguration[] = [];
          let openEdit = false;
          this.drawResize.forEach((draw) => {
            drawConfigs.push(
              deepClone(
                configsTools.find(
                  (stock) =>
                    stock.position === draw.tool.position &&
                    stock.codTool === draw.tool.codTool
                )!!
              )
            );
            baseChart.sciChartSurface.annotations.remove(draw);
            this.deleteChildren(baseChart, draw.id);
            this.removeDrawStock(draw);
            this.removeTool(draw.id);
            const toolbarTool = this.toolbarDrawService.getDrawTool();
            if (
              toolbarTool &&
              draw.tool.position === toolbarTool.tool.position
            ) {
              openEdit = true;
            }
          });
          return { ...data, drawConfigs, openEdit };
        })
      )
      .subscribe((data) => {
        const {
          baseChart,
          drawConfigs,
          refComponent,
          configs,
          chartHeight,
          stock,
          openEdit,
        } = data;
        drawConfigs.forEach((drawConfig) => {
          this.addDraw(
            baseChart,
            drawConfig,
            refComponent,
            configs,
            chartHeight,
            stock,
            true,
            openEdit
          );
        });
      });
    this.updateDrawConfig$.pipe(debounceTime(50)).subscribe((data) => {
      const { stocksDraw } = data;
      this.customPreferencesService.userDrawStock = JSON.stringify(stocksDraw);
    });
    this.addDraw$
      .pipe(
        delay(50),
        tap((data) => {
          data.tap();
        }),
        delay(200)
      )
      .subscribe((data) => {
        data.function();
      });
    this.updateDrawPosition$.pipe(debounceTime(10)).subscribe((data) => {
      const { length } = data;
      this.drawDict.values().forEach((draw) => {
        draw = this.updatePosition(draw, length);
        draw.children.forEach((cDraw) => {
          // eslint-disable-next-line
          cDraw = this.updatePosition(cDraw, length);
        });
      });
    });
    this.updateCustomSVG$.pipe(debounceTime(100)).subscribe((data) => {
      const { annotation } = data;
      if (annotation.tool.codTool === `${TOOLS_ENUM.ANNOTATION_TOOLS}_NOTE`) {
        if (annotation instanceof TextTools) {
          annotation.updateBoxNote();
        }
      }
    });
  }

  private updatePosition(draw: DrawTools, length: number): DrawTools {
    draw.isDrawing = true;
    draw.x1 = draw.x1 + length;
    if (
      !(draw instanceof HorizontalLineTools) &&
      !(draw instanceof VerticalLineTools) &&
      !(draw instanceof HorizontalLineAnnotation)
    ) {
      draw.x2 = draw.x2 + length;
    }
    draw.isDrawing = false;
    if (!draw.isSvgAnnotation || draw.type === 'SVGTextAnnotation') {
      return draw;
    }
    if (draw && draw.lastPointValues) {
      const point = new Point(
        draw.lastPointValues.x + length,
        draw.lastPointValues.y
      );
      draw.lastPointValues = point;
    }
    return draw;
  }

  makeToolInfo(
    tool: TIGER_CHART_TOOL,
    eventData: TMouseEventData,
    position?: number
  ) {
    const tools = this.toolsDict.get(tool.codTool) || [];
    if (position) {
      tool.position = position;
      this.lastSavedToolPosition = position;
    } else {
      if (tools.length === this.lastSavedToolPosition) {
        tool.position = tools.length + 1;
      } else {
        tool.position = tools.length;
      }
    }
    if (tool.group === TOOLS_ENUM.TEMPORARY) {
      tool.position = structuredClone(this.temporaryToolPosition);
      this.temporaryToolPosition++;
    }
    tool.xCategoryValue = eventData.xCategoryValue;
    tool.pointValues = eventData.pointValues;
    tool.mouseValues = eventData.mousePoint;
    tool.middleXPointValues = eventData.middleXPointValues;
    tool.middleYPointValues = eventData.middleYPointValues;
    tool.dictId = `${tool.codTool}_${tool.position}`.replace(/\s/g, '_');
    tools.push(tool);
    this.toolsDict.set(tool.codTool, tools);
  }

  updateToolInfo(tool: TIGER_CHART_TOOL) {
    const tools = this.toolsDict.get(tool.codTool)!!;
    const index = tools.findIndex((t) => t.dictId === tool.dictId);
    if (index != -1) {
      tools.splice(index, 1, tool);
      this.toolsDict.set(tool.codTool, tools);
    }
  }

  makeChildrenTool(tool: TIGER_CHART_TOOL, draw: DrawTools) {
    const tools = this.drawChildrenDic.get(tool.dictId!!) || [];
    tools.push(draw);
    this.drawChildrenDic.set(tool.dictId!!, tools);
  }

  clearAllDic() {
    this.toolsDict.clear();
    this.drawDict.clear();
    this.drawChildrenDic.clear();
    this.baseChartDic.clear();
    this.refComponentDic.clear();
    this.childrenFatherDic.clear();
    this.redrawTool.clear();
  }

  removeTool(id: string) {
    const draw = this.drawDict.get(id)!!;
    const tools = this.toolsDict.get(draw.tool.codTool) || [];
    const toolIndex = tools.findIndex((tool) => tool.dictId === id);
    if (toolIndex != -1) {
      tools.splice(toolIndex, 1);
      this.toolsDict.set(draw.tool.codTool, tools);
    }
    this.drawDict.delete(id);
    this.drawChildrenDic.delete(id);
    this.baseChartDic.delete(id);
    this.refComponentDic.delete(id);
  }

  repositionConfigurationDraw(
    eventData: TMouseEventData,
    eventDataLast: TMouseEventData,
    baseChart: TWebAssemblyChart,
    refComponent: string,
    configs: ICandleConfigs,
    configuration?: DrawToolConfiguration
  ) {
    const hashMinutes = MAP_INTERVAL_CHART;
    const interval = hashMinutes.get(configs.series.interval!);
    const rend = baseChart.sciChartSurface.renderableSeries.getById(
      CANDLE_IDS.MAIN_SERIE
    ) as FastOhlcRenderableSeries;
    if (!rend || !interval) {
      return { return: true, configuration, eventData, eventDataLast };
    }
    let configurationDiff!: number;
    if (configuration) {
      const xAxis = baseChart.sciChartSurface.xAxes.getById(
        `xAxis_${refComponent}`
      );
      const calculator =
        xAxis.getCurrentCoordinateCalculator() as CategoryCoordinateCalculator;
      const hasX1 = !isNullOrUndefined(configuration.x1);
      const hasX2 = !isNullOrUndefined(configuration.x2);
      if (hasX1) {
        const x1 = calculator.transformDataToIndex(eventData.xCategoryValue!);
        configuration.x1 = x1;
      }
      if (hasX2) {
        const x2 = calculator.transformDataToIndex(
          eventDataLast.xCategoryValue!
        );
        configuration.x2 = x2;
      }
      if (hasX1 && hasX2) {
        configurationDiff = configuration.x2! - configuration.x1!;
      }
    }
    eventDataLast.pointValues!! = new Point(
      !isNullOrUndefined(configurationDiff)
        ? configuration!!.x1!! + configurationDiff!!
        : eventDataLast.pointValues!!.x,
      eventDataLast.pointValues!!.y
    );
    /*if (isEventDataPast || isEventDataLastPast) {
      return { return: true, configuration, eventData, eventDataLast };
    }*/
    return { return: true, configuration, eventData, eventDataLast };
  }

  getDrawTool(
    tool: TIGER_CHART_TOOL,
    eventData: TMouseEventData,
    eventDataLast: TMouseEventData,
    refComponent: string,
    viewBoxWidth: number,
    viewBoxHeight: number,
    stock: any,
    baseChart: TWebAssemblyChart,
    configs: ICandleConfigs,
    isFromSaved: boolean,
    configuration?: DrawToolConfiguration,
    isFather: boolean = false,
    isAux: boolean = false
  ) {
    let isRedraw = false;
    if (isFromSaved) {
      const repositionConfigurationDraw = this.repositionConfigurationDraw(
        eventData,
        eventDataLast,
        baseChart,
        refComponent,
        configs,
        configuration
      );
      isRedraw = repositionConfigurationDraw.return;
      configuration = repositionConfigurationDraw.configuration;
      eventData = repositionConfigurationDraw.eventData;
      eventDataLast = repositionConfigurationDraw.eventDataLast;
    }
    let isChildren = false;
    let childrenId: any = null;
    this.updateToolConfig(tool, configuration);
    if (isNullOrUndefined(tool.position)) {
      this.makeToolInfo(tool, eventData, configuration?.position);
      this.baseChartDic.set(tool.dictId!!, baseChart);
      this.refComponentDic.set(tool.dictId!!, { ref: refComponent });
    } else if (!isFather) {
      isChildren = true;
      const size = this.drawChildrenDic.has(tool.dictId!!)
        ? this.drawChildrenDic.get(tool.dictId!!)!!.length
        : 0;
      childrenId = `${tool.dictId}_CHILD_${size}`;
      this.childrenFatherDic.set(
        childrenId,
        this.drawDict.get(tool.dictId!!)!!
      );
    }
    const toolsOption: LineToolsOptions = {
      tool,
      viewBoxWidth,
      viewBoxHeight,
      stock,
      baseChart,
      isChildren,
      svgFillColor: tool.backgroundColor,
      dictIdChildren: childrenId,
      configuration,
      middleXPointValues: tool.middleXPointValues!!,
      middleYPointValues: tool.middleYPointValues!!,
      configs,
      locale: this.locale,
      isAux,
      updateChildren: tool.updateChildren,
      eventData,
      refComponent,
    };
    const _draw: DRAW = {
      drawToolsService: this,
      eventData,
      refComponent,
      tool,
      toolsOption,
      childrenId,
      configuration,
      toolbarDrawService: this.toolbarDrawService,
    };
    if (!isFromSaved) {
      _draw.tool.textColor = this.themeService.isDarkTheme()
        ? '#ffffff'
        : '#000000';
    }
    const draw = this.drawsService.getDraw(_draw);
    if (draw) {
      draw.customClick = this.customClick;
      if (isChildren) {
        this.makeChildrenTool(tool, draw);
      } else {
        this.drawDict.set(tool.dictId!!, draw);
      }
    }
    return { draw, tool, eventDataLast, isRedraw, configuration };
  }

  hasTool(tool: TIGER_CHART_TOOL): boolean {
    if (!this.toolsDict.has(tool.codTool)) {
      return false;
    }
    return (
      this.toolsDict
        .get(tool.codTool)
        ?.findIndex(
          (t) => t.codTool === tool.codTool && t.position === tool.position
        ) != -1
    );
  }

  getSvgString(
    tool: TIGER_CHART_TOOL,
    viewBoxWidth: number,
    viewBoxHeight: number
  ): string {
    return this.drawsService.getSvgString(tool, viewBoxWidth, viewBoxHeight);
  }

  private customClick = () => {
    /*if (this._lineValueOnDragStart > 0 && labelBox.y1 != this._lineValueOnDragStart) {
      this._lineValueOnDragStart = 0;
      return;
    }
    if (remove) {
      this._base.sciChartSurface.annotations.remove(labelBox);
      this._base.sciChartSurface.annotations.remove(line);
      if (this._lineData[line.id]) delete this._lineData[line.id];
      return;
    }

    if (x && x > textLength + 30) {
      this._boxLineDeleted.emit({
        id: options.boxId,
        value: line.y1,
        data: this._lineData[line.id] || null,
        idTradeSystem: this.lineAlertData[options.boxId] ? this.lineAlertData[options.boxId].idTradeSystem : null,
        boxIdAlert: this.lineAlertData[options.boxId] ? this.lineAlertData[options.boxId].firstBoxId : null,
      });
    }*/
  };

  updateDraw(
    baseChart: TWebAssemblyChart,
    eventData: TMouseEventData | null,
    selectedTool: TIGER_CHART_TOOL | null,
    configs: ICandleConfigs,
    countStepClickTool: number,
    stock: any
  ) {
    if (
      eventData &&
      (eventData.type == TIGER_MOUSE_EVENT_DATA_TYPE.X_DATASERIES_HOVER ||
        eventData.type == TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_LEAVE)
    ) {
      if (selectedTool && !isNullOrUndefined(selectedTool.position)) {
        const draw = this.drawDict.get(selectedTool.dictId!!);
        if (!draw) {
          return { tool: selectedTool };
        }
        draw.updateDrawTool(eventData!!, countStepClickTool, {
          stock,
        });
        selectedTool.maxYValue = eventData.pointValues!!.y;
        const drawChildren = this.drawChildrenDic.get(selectedTool.dictId!!);
        if (drawChildren && selectedTool.updateChildren) {
          drawChildren.forEach((child, k) => {
            if (
              selectedTool!!.children!![k].dontUpdate ||
              selectedTool!!.children!![k].isAux
            ) {
              return;
            }
            let x1 = !isNullOrUndefined(selectedTool!!.children!![k].x1Position)
              ? eval(
                  `${draw!!.x1} ${
                    selectedTool!!.children!![k].typeCalculation
                  } ${selectedTool!!.children!![k].x1Position!!}`
                )
              : undefined;
            const x2 = !isNullOrUndefined(
              selectedTool!!.children!![k].x2Position
            )
              ? eval(
                  `${draw!!.x2} ${
                    selectedTool!!.children!![k].typeCalculation
                  } ${selectedTool!!.children!![k].x2Position!!}`
                )
              : undefined;
            let y1 = !isNullOrUndefined(selectedTool!!.children!![k].y1Position)
              ? eval(
                  `${draw!!.y1} ${
                    selectedTool!!.children!![k].typeCalculation
                  } ${selectedTool!!.children!![k].y1Position!!}`
                )
              : undefined;
            let y2 = !isNullOrUndefined(selectedTool!!.children!![k].y2Position)
              ? eval(
                  `${draw!!.y2} ${
                    selectedTool!!.children!![k].typeCalculation
                  } ${selectedTool!!.children!![k].y2Position!!}`
                )
              : undefined;
            let text = undefined;
            if (
              selectedTool!!.calculation &&
              selectedTool?.calculation ===
                TOOLS_CALCULATION.FIBONACCI_RETRACEMENT
            ) {
              const UR =
                selectedTool.maxYValue!! -
                (selectedTool.maxYValue!! - selectedTool.minYValue!!) *
                  selectedTool!!.children!![k].y1Position!!;
              y1 = UR;
              y2 = UR;
              if (
                selectedTool!!.children!![k].method ===
                TOOLS_METHOD.BOX_ANNOTATION
              ) {
                y2 =
                  selectedTool.maxYValue!! -
                  (selectedTool.maxYValue!! - selectedTool.minYValue!!) *
                    selectedTool!!.children!![k].y2Position!!;
              }
              const formattedUR = formatNumber(
                UR,
                this.locale,
                `1.${configs.yAxis.labelPrecision || 0}-${
                  configs.yAxis.labelPrecision || 0
                }`
              );
              if (
                selectedTool!!.children!![k].method ===
                TOOLS_METHOD.TEXT_ANNOTATION
              ) {
                x1 = draw!!.x1;
                const drawText = baseChart.sciChartSurface.annotations.getById(
                  draw!!.id
                );
                if (x2 < x1) {
                  (drawText as TextAnnotation).verticalAnchorPoint =
                    EVerticalAnchorPoint.Center;
                  (drawText as TextAnnotation).horizontalAnchorPoint =
                    EHorizontalAnchorPoint.Left;
                  (drawText as TextAnnotation).xCoordShift =
                    -fibonacciTextXOffset;
                } else {
                  (drawText as TextAnnotation).verticalAnchorPoint =
                    EVerticalAnchorPoint.Center;
                  (drawText as TextAnnotation).horizontalAnchorPoint =
                    EHorizontalAnchorPoint.Right;
                  (drawText as TextAnnotation).xCoordShift =
                    fibonacciTextXOffset;
                }
              }
              text = `${selectedTool!!.children!![k]
                .y1Position!!}(${formattedUR})`;
            }
            child.updateDrawTool(eventData!!, countStepClickTool, {
              id: child.id,
              x1,
              x2,
              y1,
              y2,
              text,
              stock,
            });
          });
        }
        if (
          selectedTool.hasBoxAux &&
          selectedTool.calculation &&
          selectedTool.calculation === TOOLS_CALCULATION.FIBONACCI_RETRACEMENT
        ) {
          const aux = selectedTool.children?.filter((child) => child.isAux);
          if (aux) {
            aux.forEach((a) => {
              const children = selectedTool.children?.filter(
                (child) => child.method === TOOLS_METHOD.DRAW_LINE
              );
              if (a.method === TOOLS_METHOD.DRAW_LINE) {
                if (children) {
                  const lastChild = children[children.length - 2];
                  const annotations = baseChart.sciChartSurface.annotations;
                  const auxDraw = annotations.getById(a.dictIdChildren!!),
                    firstDraw = annotations.getById(selectedTool.dictId!!),
                    lastDraw = annotations.getById(lastChild.dictIdChildren!!);
                  auxDraw.x1 = firstDraw.x1;
                  auxDraw.x2 = firstDraw.x2;
                  auxDraw.y1 = firstDraw.y1;
                  auxDraw.y2 = lastDraw.y2;
                }
              }
              if (a.method === TOOLS_METHOD.BOX_ANNOTATION) {
                if (children) {
                  const lastChild = children[children.length - 2];
                  const annotations = baseChart.sciChartSurface.annotations;
                  const auxDraw = annotations.getById(a.dictIdChildren!!),
                    firstDraw = annotations.getById(
                      selectedTool.children!![0].dictIdChildren!!
                    ),
                    lastDraw = annotations.getById(lastChild.dictIdChildren!!);
                  auxDraw.x1 = firstDraw.x1;
                  auxDraw.x2 = firstDraw.x2;
                  auxDraw.y1 = lastDraw.y1;
                  auxDraw.y2 = firstDraw.y2;
                }
              }
            });
          }
        }
      }
    }
    return { tool: selectedTool };
  }

  private clearShow(baseChart: TWebAssemblyChart, annotation: DrawTools) {
    if (!this.isAllVisible) {
      baseChart.sciChartSurface.annotations.remove(annotation);
    } else {
      baseChart.sciChartSurface.annotations.add(annotation);
      annotation.initSubscriptions();
      this.updateCustomSVG$.next({ annotation });
    }
  }

  clearShowDraws(baseChart: TWebAssemblyChart, tool: TIGER_CHART_TOOL) {
    if (tool.group === TOOLS_ENUM.VISIBLE) {
      if (this.toolbarDrawService.hasElement()) {
        this.toolbarDrawService.destroyComponent();
      }
      this.isAllVisible = !this.isAllVisible;
      this.drawDict.values().forEach((annotation) => {
        this.clearShow(baseChart, annotation);
        const children = this.drawChildrenDic.get(annotation.id);
        if (children && children.length > 0) {
          children.forEach((child) => {
            this.clearShow(baseChart, child);
          });
        }
      });
    }
    if (tool.group === TOOLS_ENUM.TRASH) {
      this.trashDraw(baseChart);
      if (this.subCharts.size() == 0 || this.drawChildrenDic.size() == 0) {
        this.clearAllDic();
        const stocksDraw: any = this.getSavedDrawConfigurations();
        const key = this.getKeyDraw();
        delete stocksDraw[key];
        this.customPreferencesService.userDrawStock =
          JSON.stringify(stocksDraw);
      }
    }
  }

  trashDraw(baseChart: TWebAssemblyChart) {
    this.drawDict.values().forEach((annotation) => {
      const annota = baseChart.sciChartSurface.annotations.getById(
        annotation.id
      );
      if (annota) {
        baseChart.sciChartSurface.annotations.remove(annota);
      }
      if (this.toolbarDrawService.hasElement()) {
        this.toolbarDrawService.destroyComponent();
      }
      this.deleteChildren(baseChart, annotation.id);
    });
  }

  private updateSharedDrawChart(tool: TIGER_CHART_TOOL, childrenId: any) {
    const children = this.drawChildrenDic.get(tool.dictId!!);
    const father = this.baseChartDic
      .get(tool.dictId!!)!!
      .sciChartSurface.annotations.getById(tool.dictId!!);
    if (children && father) {
      if (!childrenId) {
        children.forEach((child) => {
          child.x1 = father.x1;
        });
      } else {
        const child = children.find((child) => child.id === childrenId);
        if (child && !child.isAux) {
          if (tool.codTool != `${TOOLS_ENUM.TREND_LINES}_LINHA_CRUZADA`) {
            father.x1 = child.x1;
          }
          children
            .filter((child) => child.id !== childrenId && !child.isAux)
            .map((child) => (child.x1 = father.x1));
        }
      }
    }
  }

  private drawClick(tool: TIGER_CHART_TOOL) {
    if (this.isBrush) {
      const baseChart = this.baseChartDic.get(tool.dictId!!)!!;
      const annotation = baseChart.sciChartSurface.annotations.getById(
        tool.dictId!!
      );
      const children = this.drawChildrenDic.has(annotation.id)
        ? [...this.drawChildrenDic.get(annotation.id)!!]
        : [];
      if (annotation) {
        baseChart.sciChartSurface.annotations.remove(annotation);
        this.removeDrawStock(annotation as DrawTools);
      }
      if (children) {
        children.forEach((child) => {
          const childAnnotation = baseChart.sciChartSurface.annotations.getById(
            child.id
          );
          if (childAnnotation) {
            baseChart.sciChartSurface.annotations.remove(childAnnotation);
            const c = this.drawChildrenDic.get(annotation.id)!!;
            if (c) {
              const cIndex = c.findIndex((i) => i.id === child.id);
              if (cIndex != -1) {
                c.splice(cIndex, 1);
                this.drawChildrenDic.set(annotation.id, c);
              }
              if (c.length == 0) {
                this.removeTool(annotation.id);
              }
            }
          }
        });
      } else {
        this.removeTool(tool.dictId!!);
      }
    } else if (this.refComponentDic.get(tool.dictId!!)) {
      this.toolEvent$.next({
        componentRef: this.refComponentDic.get(tool.dictId!!)!!.ref,
        tool,
        typeEvent: TYPE_EVENT.EDIT,
        countStepClick: tool.stepClick,
      });
    }
  }

  getConfigurationDraw(drawConfig: saveConfiguration): DrawToolConfiguration {
    const tools = deepClone(
      TOOLS.get(drawConfig.group)!!
    ) as TIGER_CHART_TOOL[];
    const selectedTool = deepClone(
      tools.find((tool) => tool.codTool === drawConfig.codTool)!!
    ) as TIGER_CHART_TOOL;
    return {
      backgroundColor: selectedTool.alwaysTransparent
        ? 'transparent'
        : drawConfig.backgroundColor,
      color: selectedTool.alwaysTransparent ? 'transparent' : drawConfig.color,
      outlineColor: drawConfig.outlineColor,
      textColor: drawConfig.textColor,
      text: drawConfig.text,
      textBold: drawConfig.textBold,
      textItalic: drawConfig.textItalic,
      textSize: drawConfig.textSize,
      lineThickness: drawConfig.lineThickness,
      strokeThickness: drawConfig.strokeThickness,
      strokeDashArray: drawConfig.strokeDashArray,
      x1: drawConfig.x1Property,
      x2: drawConfig.x2Property,
      y1: drawConfig.y1Property,
      y2: drawConfig.y2Property,
      position: drawConfig.position,
      useTextOutline: drawConfig.useTextOutline,
      useTextBackground: drawConfig.useTextBackground,
    };
  }

  //metodo responsavel para adicionar os desenhos salvos

  addDraw(
    baseChart: TWebAssemblyChart,
    drawConfig: saveConfiguration,
    refComponent: string,
    configs: ICandleConfigs,
    chartHeight: number,
    stock: any,
    saveAnnotation: boolean = false,
    isEdit: boolean = false
  ): saveConfiguration {
    const SVG = document.getElementById(`${refComponent}_SVG`);
    const width = SVG?.getAttribute('width') || configs.width;
    const height = SVG?.getAttribute('height') || chartHeight;
    if (!width || !height) {
      //caso caia no if é pq ainda n carregou o grafico
      return drawConfig;
    }
    const tools = deepClone(
      TOOLS.get(drawConfig.group)!!
    ) as TIGER_CHART_TOOL[];
    let selectedTool = deepClone(
      tools.find((tool) => tool.codTool === drawConfig.codTool)!!
    ) as TIGER_CHART_TOOL;
    const configurationDraw = this.getConfigurationDraw(drawConfig);
    this.defineMinMaxValueTool(selectedTool, drawConfig.firstEventData);
    if (selectedTool.group === TOOLS_ENUM.ICONS) {
      selectedTool.svgString = drawConfig.svgString;
    }
    const { draw, tool, eventDataLast, isRedraw, configuration } =
      this.getDrawTool(
        selectedTool,
        drawConfig.firstEventData,
        drawConfig.lastEventData,
        refComponent,
        parseFloat(width.toString()),
        parseFloat(height.toString()),
        stock,
        baseChart,
        configs,
        true,
        configurationDraw,
        true
      );
    if (draw) {
      if (
        selectedTool.codTool === `${TOOLS_ENUM.GEOMETRIC_SHAPES}_SEQUENCE` &&
        drawConfig.children
      ) {
        selectedTool.children = drawConfig.children as TIGER_CHART_TOOL_CHILD[];
      }
      draw.isDrawing = true;
      drawConfig.position = tool.position!!;
      baseChart.sciChartSurface.annotations.add(draw);
      drawConfig.lastEventData = eventDataLast;
      this.alertSharedTool(
        drawConfig.firstEventData,
        drawConfig.lastEventData,
        draw,
        selectedTool,
        refComponent,
        parseFloat(width.toString()),
        parseFloat(height.toString()),
        true,
        configs
      );
      const tol = this.updateChildrenDraw(
        baseChart,
        drawConfig.firstEventData,
        drawConfig.lastEventData,
        draw,
        tool,
        refComponent,
        parseFloat(width.toString()),
        parseFloat(height.toString()),
        stock,
        configs,
        true,
        drawConfig
      );
      selectedTool = tol.tool;
      draw.updateTool(selectedTool);
      this.addDraw$.next({
        tap: () => {
          if (selectedTool.children && selectedTool.children.length) {
            this.updateDraw(
              baseChart,
              drawConfig.lastEventData,
              selectedTool,
              configs,
              selectedTool.stepClick,
              stock
            );
            const colors: Array<any> = [];
            const centerLinecolors: Array<any> = [];
            const backgroundcolors: Array<any> = [];
            const textcolors: Array<any> = [];
            const outlinecolors: Array<any> = [];
            if (
              !selectedTool.typeDraw ||
              selectedTool.typeDraw === TOOLS_TYPE_DRAW.LINE
            ) {
              colors.push(selectedTool.color);
            }
            selectedTool.children.forEach((child) => {
              if (child.isAux) {
                return;
              }
              const draw = baseChart.sciChartSurface.annotations.getById(
                child.dictIdChildren!!
              );
              if (!isNullOrUndefined(child.isHidden)) {
                draw.isHidden = true;
              }
              if (child.typeDraw === TOOLS_TYPE_DRAW.LINE) {
                colors.push(child.color);
              }
              if (child.typeDraw === TOOLS_TYPE_DRAW.CENTER_LINE) {
                centerLinecolors.push(child.color);
              }
              if (child.typeDraw === TOOLS_TYPE_DRAW.BACKGROUND) {
                backgroundcolors.push(child.backgroundColor);
              }
              if (child.typeDraw === TOOLS_TYPE_DRAW.TEXT) {
                textcolors.push(child.textColor);
              }
              if (child.typeDraw === TOOLS_TYPE_DRAW.OUTLINE) {
                outlinecolors.push(child.outlineColor);
              }
            });
            const auxC = colors.filter((color, i) => {
              return colors.indexOf(color) == i;
            });
            const auxLC = centerLinecolors.filter((color, i) => {
              return centerLinecolors.indexOf(color) == i;
            });
            const auxB = backgroundcolors.filter((color, i) => {
              return backgroundcolors.indexOf(color) == i;
            });
            const auxT = textcolors.filter((color, i) => {
              return textcolors.indexOf(color) == i;
            });
            const auxO = outlinecolors.filter((color, i) => {
              return outlinecolors.indexOf(color) == i;
            });
            selectedTool.isMultiColors = auxC.length > 1;
            selectedTool.isMultiCenterLineColors = auxLC.length > 1;
            selectedTool.isMultiBackgroundColors = auxB.length > 1;
            selectedTool.isMultiTextColors = auxT.length > 1;
            selectedTool.isMultiOutlineColors = auxO.length > 1;
          }
          if (saveAnnotation) {
            this.saveAnnotationStock(baseChart, draw, refComponent);
          }
          if (isEdit) {
            this.startDrawEdit(draw, true, refComponent);
          }
          draw.updateTool(selectedTool);
        },
        function: () => {
          draw.isDrawing = false;
          isRedraw && this.addToRedraw(tool, draw, drawConfig, configuration);
          /*draw.children.forEach((child) => {
            child.isDrawing = false;
          });*/
        },
      });
    }
    return drawConfig;
  }

  updateChildrenDraw(
    baseChart: TWebAssemblyChart,
    eventData: TMouseEventData,
    eventDataLast: TMouseEventData,
    draw: DrawTools,
    selectedTool: TIGER_CHART_TOOL,
    refComponent: string,
    width: number,
    height: number,
    stock: any,
    configs: ICandleConfigs,
    isFromSaved: boolean,
    saveConfiguration?: saveConfiguration
  ) {
    const { tool } = this.addChildren(
      baseChart,
      selectedTool,
      refComponent,
      draw,
      eventData,
      eventDataLast,
      width,
      height,
      stock,
      configs,
      isFromSaved,
      saveConfiguration
    );
    selectedTool = tool;
    return { tool: selectedTool };
  }

  private alertSharedTool(
    eventData: TMouseEventData,
    eventDataLast: TMouseEventData,
    draw: DrawTools,
    selectedTool: TIGER_CHART_TOOL,
    refComponent: string,
    width: number,
    height: number,
    isFromSaved: boolean,
    configs: ICandleConfigs
  ) {
    if (selectedTool.shared) {
      if (this.subCharts.size() > 0) {
        this.stockChartService.sharedTool$.next({
          ref: refComponent,
          tool: selectedTool!!,
          eventData,
          eventDataLast,
          width,
          height,
          isFromSaved,
          configs,
        });
      } else {
        draw.showLabel = true;
        draw.children.forEach((child) => {
          child.showLabel = true;
        });
      }
    }
  }

  defineMinMaxValueTool(
    selectedTool: TIGER_CHART_TOOL,
    eventData: TMouseEventData
  ) {
    selectedTool!!.maxYValue = eventData.pointValues?.y;
    selectedTool!!.minYValue = eventData.pointValues?.y;
  }

  //Metodo responsavel pela criacao do desenho quando clica

  createTool(
    baseChart: TWebAssemblyChart,
    eventData: TMouseEventData,
    selectedTool: TIGER_CHART_TOOL | null,
    refComponent: string,
    countStepClickTool: number,
    configs: ICandleConfigs,
    chartHeight: number,
    stock: any
  ) {
    const SVG = document.getElementById(`${refComponent}_SVG`);
    const SVGWidth = SVG?.getAttribute('width');
    const width = SVGWidth ? parseFloat(SVGWidth) : configs.width;
    const height = SVG?.getAttribute('height') || chartHeight;
    if (
      selectedTool!!.method &&
      countStepClickTool <= selectedTool!!.stepClick
    ) {
      if (isNullOrUndefined(selectedTool!!.position) && eventData.pointValues) {
        this.defineMinMaxValueTool(selectedTool!!, eventData);
        const { draw, tool } = this.getDrawTool(
          selectedTool!!,
          eventData,
          eventData,
          refComponent,
          parseFloat(width.toString()),
          parseFloat(height.toString()),
          stock,
          baseChart,
          configs,
          false,
          {
            backgroundColor: selectedTool!!.alwaysTransparent
              ? 'transparent'
              : selectedTool!!.backgroundColor,
            color: selectedTool!!.alwaysTransparent
              ? 'transparent'
              : selectedTool!!.color,
          },
          true
        );
        if (draw) {
          draw.isDrawing = true;
          selectedTool = tool;
          if (selectedTool.helperMessage) {
            this.drawHelperMessageService.show({
              ref: refComponent,
              helperMessage: selectedTool.helperMessage,
            });
          }
          this.stockChartService.toolSelected$.next({
            componentRef: refComponent,
            tool,
          });
          baseChart.sciChartSurface.annotations.add(draw);
          this.alertSharedTool(
            eventData,
            eventData,
            draw,
            selectedTool,
            refComponent,
            parseFloat(width.toString()),
            parseFloat(height.toString()),
            false,
            configs
          );
          const tol = this.updateChildrenDraw(
            baseChart,
            eventData,
            eventData,
            draw,
            selectedTool,
            refComponent,
            parseFloat(width.toString()),
            parseFloat(height.toString()),
            stock,
            configs,
            false
          );
          selectedTool = tol.tool;
          draw.tool = selectedTool;
          draw.children.forEach((child) => {
            child.isDrawing = true;
          });
        }
      }
      if (countStepClickTool >= 2 && selectedTool!!.stepClick === Infinity) {
        const draw = baseChart.sciChartSurface.annotations.getById(
          selectedTool!!.dictId!!
        ) as DrawTools;
        const children: TIGER_CHART_TOOL_CHILD[] = [];
        children.push({
          typeCalculation: TOOLS_TYPE_CALCULATION.PLUS,
          method: TOOLS_METHOD.DRAW_LINE,
          color: CHART_COLORS.FEEDBACK_WARNING,
          lineThickness: 2,
          strokeDashArray: [0, 0],
          typeDraw: TOOLS_TYPE_DRAW.LINE,
          dragPoints: [EDraggingGripPoint.Body, EDraggingGripPoint.x2y2],
          withBoxAuxCanEdit: true,
        });
        this.updateChildrenDraw(
          baseChart,
          eventData,
          eventData,
          draw,
          { ...selectedTool!, children: children },
          refComponent,
          parseFloat(width.toString()),
          parseFloat(height.toString()),
          stock,
          configs,
          false
        );
        const selectedToolChildren = selectedTool?.children || [];
        if (selectedToolChildren.length) {
          const actualChild =
            selectedToolChildren[selectedToolChildren.length - 1];
          const draw = baseChart.sciChartSurface.annotations.getById(
            actualChild.dictIdChildren!!
          ) as DrawTools;
          draw.endDraw = true;
        }
        selectedTool!!.children = [...selectedToolChildren, ...children];
        draw.updateTool(selectedTool!!);
      }
      if (selectedTool!!.stepClick === 1) {
        this.updateDraw(
          baseChart,
          {
            ...eventData,
            type: TIGER_MOUSE_EVENT_DATA_TYPE.X_DATASERIES_HOVER,
          },
          selectedTool,
          configs,
          1,
          stock
        );
      }
      if (countStepClickTool === selectedTool!!.stepClick) {
        this.dispatchEndDrawClick(selectedTool!!, baseChart, refComponent);
        selectedTool = null;
      }
    }
    return { tool: selectedTool };
  }

  dispatchEndDrawClick(
    selectedTool: TIGER_CHART_TOOL | null,
    baseChart: TWebAssemblyChart,
    refComponent: string
  ) {
    this.endDrawClick(selectedTool!!, baseChart, refComponent);
    selectedTool = null;
    this.toolEvent$.next({
      componentRef: refComponent,
      tool: selectedTool,
      typeEvent: TYPE_EVENT.UPDATE,
      countStepClick: 0,
    });
  }

  openModalConfigDraw(
    selectedTool: TIGER_CHART_TOOL,
    selectAllText: boolean = true
  ) {
    const ref = this._rocketModalService.open(ModalConfigDrawComponent, {
      centered: true,
      backdrop: true,
      keyboard: true,
      scrollable: false,
      data: { selectedTool, selectAllText },
    });
    ref.afterDismissed.subscribe(() => {
      this.openedModalDrawEdit = true;
    });
  }

  openToolbar(
    baseChart: TWebAssemblyChart,
    selectedTool: TIGER_CHART_TOOL,
    refComponent: string
  ) {
    const draw = baseChart.sciChartSurface.annotations.getById(
      selectedTool!!.dictId!!
    ) as DrawTools;
    if (draw) {
      draw.endDraw = true;
      this.startDrawEdit(draw, true, refComponent);
      this.removeMessageHelper(selectedTool);
    }
  }

  endDrawClick(
    selectedTool: TIGER_CHART_TOOL,
    baseChart: TWebAssemblyChart,
    refComponent: string
  ) {
    const draw = baseChart.sciChartSurface.annotations.getById(
      selectedTool!!.dictId!!
    ) as DrawTools;
    this.openToolbar(baseChart, selectedTool, refComponent);
    if (selectedTool!!.openModal) {
      this.openModalConfigDraw(selectedTool);
    }
    this.stockChartService.toolSelected$.next({
      componentRef: refComponent,
      tool: null,
    });
    if (draw) {
      draw.isDrawing = false;
      if (draw.children) {
        draw.children.forEach((child) => {
          child.isDrawing = false;
        });
      }
      if (selectedTool.group !== TOOLS_ENUM.TEMPORARY) {
        this.saveAnnotationStock(baseChart, draw, refComponent);
        this.updateChartZoomModifers(baseChart, true);
      }
    }
  }

  removeMessageHelper(selectedTool: TIGER_CHART_TOOL) {
    if (selectedTool && selectedTool.helperMessage) {
      this.drawHelperMessageService.destroyComponent();
    }
  }

  brushAnnotation(baseChart: TWebAssemblyChart, eventData: TMouseEventData) {
    if (
      !eventData.mousePoint ||
      !eventData.mousePoint.x ||
      !eventData.mousePoint.y
    )
      return;
    baseChart.sciChartSurface.annotations.asArray().forEach((annotation) => {
      if (annotation.isSvgAnnotation && this.drawDict.has(annotation.id)) {
        const borders = (annotation as DrawTools).getAnnotationBorders();
        if (
          eventData.mousePoint!.x >= borders.x1 &&
          eventData.mousePoint!.x <= borders.x2 &&
          eventData.mousePoint!.y >= borders.y1 &&
          eventData.mousePoint!.y <= borders.y2
        ) {
          this.removeAnnotation(baseChart, annotation as DrawTools);
        }
      }
    });
  }

  removeAnnotation(baseChart: TWebAssemblyChart, annotation: DrawTools) {
    if (this.isLocked) {
      return;
    }
    annotation.unsubscriber();
    baseChart.sciChartSurface.annotations.remove(annotation);
    this.deleteChildren(baseChart, annotation.id);
    this.removeTool(annotation.id);
    this.removeDrawStock(annotation);
  }

  private addChildren(
    baseChart: TWebAssemblyChart,
    selectedTool: TIGER_CHART_TOOL,
    refComponent: string,
    fatherDraw: DrawTools,
    eventData: TMouseEventData,
    eventDataLast: TMouseEventData,
    width: number,
    height: number,
    stock: any,
    configs: ICandleConfigs,
    isFromSaved: boolean,
    drawConfig?: saveConfiguration
  ) {
    if (
      selectedTool &&
      selectedTool.children &&
      selectedTool.children.length > 0
    ) {
      selectedTool.children.forEach((child, k) => {
        let childConfiguration!: saveConfiguration;
        if (drawConfig && drawConfig.children && drawConfig.children.length) {
          childConfiguration = drawConfig.children[k];
          if (childConfiguration) {
            eventData = childConfiguration.firstEventData;
            eventDataLast = childConfiguration.lastEventData;
          }
        }
        const x1 = childConfiguration?.x1Property
          ? childConfiguration.x1Property
          : child.x1Position
          ? eval(
              `${eventData.pointValues!!.x!!} ${child.typeCalculation} ${
                child.x1Position
              }`
            )
          : eventData.pointValues!!.x!!;
        const x2 = childConfiguration?.x2Property
          ? childConfiguration.x2Property
          : child.x2Position
          ? eval(
              `${eventData.pointValues!!.x!!} ${child.typeCalculation} ${
                child.x2Position
              }`
            )
          : eventData.pointValues!!.x!!;
        const y1 = childConfiguration?.y1Property
          ? childConfiguration.y1Property
          : child.y1Position
          ? eval(
              `${eventData.pointValues!!.y!!} ${child.typeCalculation} ${
                child.y1Position
              }`
            )
          : eventData.pointValues!!.y!!;
        const y2 = childConfiguration?.y2Property
          ? childConfiguration.y2Property
          : child.y2Position
          ? eval(
              `${eventData.pointValues!!.y!!} ${child.typeCalculation} ${
                child.y2Position
              }`
            )
          : eventData.pointValues!!.y!!;
        const { draw } = this.getDrawTool(
          {
            ...deepClone(selectedTool!!),
            ...{
              method: child.method,
            },
          },
          eventData,
          eventDataLast,
          refComponent,
          parseFloat(width.toString()),
          parseFloat(height.toString()),
          stock,
          baseChart,
          configs,
          isFromSaved,
          {
            x1,
            x2,
            y1,
            y2,
            strokeDashArray:
              childConfiguration?.strokeDashArray || child.strokeDashArray,
            lineThickness:
              childConfiguration?.lineThickness || child.lineThickness,
            color: child.alwaysTransparent
              ? 'transparent'
              : childConfiguration?.color || child.color,
            backgroundColor: child.alwaysTransparent
              ? 'transparent'
              : childConfiguration?.backgroundColor || child.backgroundColor,
            outlineColor: child.alwaysTransparent
              ? 'transparent'
              : childConfiguration?.outlineColor || child.outlineColor,
            verticalAnchorPoint: child.verticalAnchorPoint,
            horizontalAnchorPoint: child.horizontalAnchorPoint,
            xCoordShift: child.xCoordShift,
            yCoordShift: child.yCoordShift,
            dragPoints: child.dragPoints,
            resizeDirections: child.resizeDirections,
            svgString: child.svgString,
            text: childConfiguration?.text || child.text,
            textColor: childConfiguration?.textColor || child.textColor,
            textSize: childConfiguration?.textSize || child.textSize,
            textBold: !isNullOrUndefined(childConfiguration?.textBold)
              ? childConfiguration?.textBold
              : child.textBold,
            textItalic: !isNullOrUndefined(childConfiguration?.textItalic)
              ? childConfiguration?.textItalic
              : child.textItalic,
            isHidden: child.isHidden,
            useTextOutline:
              childConfiguration?.useTextOutline || child.useTextOutline,
            useTextBackground:
              childConfiguration?.useTextBackground || child.useTextBackground,
          },
          false,
          child.isAux
        );
        if (!draw) {
          return;
        }
        child.dictIdChildren = draw.id;
        child.backgroundColor =
          childConfiguration?.backgroundColor || child.backgroundColor;
        child.outlineColor =
          childConfiguration?.outlineColor || child.outlineColor;
        child.textColor = childConfiguration?.textColor || child.textColor;
        child.text = childConfiguration?.text || child.text;
        child.textBold = childConfiguration?.textBold || child.textBold;
        child.textItalic = childConfiguration?.textItalic || child.textItalic;
        child.textSize = childConfiguration?.textSize || child.textSize;
        child.lineThickness =
          childConfiguration?.lineThickness || child.lineThickness;
        child.strokeDashArray =
          childConfiguration?.strokeDashArray || child.strokeDashArray;
        child.color = childConfiguration?.color || child.color;
        child.useTextOutline =
          childConfiguration?.useTextOutline || child.useTextOutline;
        child.useTextBackground =
          childConfiguration?.useTextBackground || child.useTextBackground;
        selectedTool.centerLineColor =
          child.relativePosition === 'middle'
            ? childConfiguration?.color || selectedTool.centerLineColor
            : selectedTool.centerLineColor;
        draw.updateTool(selectedTool);
        baseChart.sciChartSurface.annotations.add(draw);
        fatherDraw.children.push(draw);
      });
    }
    return { tool: selectedTool };
  }

  private deleteChildren(baseChart: TWebAssemblyChart, fatherId: string) {
    const children = this.drawChildrenDic.get(fatherId);
    if (children && children.length > 0) {
      children.forEach((child, k) => {
        this.deleteChild(baseChart, child.id);
        if (k === children.length - 1) {
          this.drawChildrenDic.delete(fatherId);
        }
      });
    }
  }

  deleteChild(baseChart: TWebAssemblyChart, childId: string) {
    const childAnnotation = baseChart.sciChartSurface.annotations.getById(
      childId
    ) as DrawTools;
    if (childAnnotation) {
      childAnnotation.unsubscriber();
      this.childrenFatherDic.delete(childId);
      baseChart.sciChartSurface.annotations.remove(childAnnotation);
    }
  }

  updateStyleDraw(
    draw: string | DrawTools,
    selectedTool: TIGER_CHART_TOOL,
    style: StyleTool,
    validityChildrenTypeDraw: boolean = false
  ): void {
    let selectedDraw!: DrawTools;
    switch (typeof draw) {
      case 'string': {
        selectedDraw = this.drawDict.get(draw)!!;
        break;
      }
    }
    selectedDraw = typeof draw !== 'string' ? draw : selectedDraw;
    selectedTool.centerLineColor = style.centerLineColor
      ? style.centerLineColor.rgbaHexColor
      : selectedTool.centerLineColor;
    selectedTool.backgroundColor = style.backgroundColor
      ? style.backgroundColor.rgbaHexColor
      : selectedTool.backgroundColor;
    if (!selectedTool.alwaysTransparent) {
      selectedDraw.updateStyleTool(style);
    }
    if (
      style.color &&
      selectedTool.calculation === TOOLS_CALCULATION.FIBONACCI_RETRACEMENT
    ) {
      style.backgroundColor = !style.backgroundColor
        ? style.color
        : style.backgroundColor;
      style.textColor = !style.textColor ? style.color : style.textColor;
    }
    selectedDraw.children.forEach((child, k) => {
      if (child.isAux) {
        return;
      }
      const draw = selectedTool.children!![k];
      const validity = validityChildrenTypeDraw
        ? style.typeColorUpdate === draw.typeDraw
        : true;
      if (!draw.dontUpdateStyle) {
        const childAnnotationId = child.id;
        style.childAnnotationId = childAnnotationId;
        const configurationOptions = selectedTool.configurationOptions!!;
        const backgroundConfig = configurationOptions.find(
          (config) =>
            config.typeColorUpdate &&
            config.typeColorUpdate === TOOLS_TYPE_DRAW.BACKGROUND
        );
        if (validity) {
          child.updateStyleTool(style);
        } else if (
          draw.typeDraw &&
          draw.typeDraw === TOOLS_TYPE_DRAW.BACKGROUND &&
          !backgroundConfig
        ) {
          if (style.backgroundColor || style.color) {
            const rgbaHexColor = this.colorsBaseService.getOpacityHexByRGB(
              selectedTool,
              style
            );
            selectedTool.backgroundColor = rgbaHexColor;
            const isBackgroundColor = !!style.backgroundColor;
            child.updateStyleTool({
              backgroundColor: {
                selected: isBackgroundColor
                  ? style.backgroundColor!.selected
                  : style.color!.selected!,
                hexColor: isBackgroundColor
                  ? style.backgroundColor!.hexColor
                  : style.color!.hexColor!,
                rgbaColor: isBackgroundColor
                  ? style.backgroundColor!.rgbaColor
                  : style.color!.rgbaColor!,
                rgbColor: isBackgroundColor
                  ? style.backgroundColor!.rgbColor
                  : style.color!.rgbColor!,
                rgbaHexColor,
              },
              childAnnotationId: style.childAnnotationId,
              typeColorUpdate: style.typeColorUpdate,
            });
            return;
          }
          child.updateStyleTool(style);
        } else if (draw.typeDraw && draw.typeDraw === TOOLS_TYPE_DRAW.TEXT) {
          if (style.textColor || style.color) {
            const isTextColor = !!style.textColor;
            child.updateStyleTool({
              textColor: {
                selected: isTextColor
                  ? style.textColor!.selected
                  : style.color!.selected!,
                hexColor: isTextColor
                  ? style.textColor!.hexColor
                  : style.color!.hexColor!,
                rgbaColor: isTextColor
                  ? style.textColor!.rgbaColor
                  : style.color!.rgbaColor!,
                rgbColor: isTextColor
                  ? style.textColor!.rgbColor
                  : style.color!.rgbColor!,
                rgbaHexColor: isTextColor
                  ? style.textColor!.rgbaHexColor
                  : style.color!.rgbaHexColor!,
              },
              childAnnotationId: style.childAnnotationId,
              typeColorUpdate: style.typeColorUpdate,
            });
            return;
          }
        }
      }
    });
    this.updateDrawConfig(selectedDraw, style);
  }

  saveAnnotationStock(
    baseChart: TWebAssemblyChart,
    draw: DrawTools,
    refComponent: string
  ) {
    if (draw.tool.group === TOOLS_ENUM.TEMPORARY) return;
    const key = this.getKeyDraw();
    const stocksDraw: any = this.getSavedDrawConfigurations();
    const stockDraws = stocksDraw[key]
      ? (stocksDraw[key] as saveConfiguration[])
      : [];
    const drawConfig = this.getDrawConfig(baseChart, draw, refComponent, true);
    const drawIndex = stockDraws.findIndex(
      (d) =>
        d.position === draw.tool.position!! && d.codTool === draw.tool.codTool
    );
    if (drawIndex > -1) {
      stockDraws.splice(drawIndex, 1, drawConfig);
    } else {
      stockDraws.push(drawConfig);
    }
    stocksDraw[key] = stockDraws;
    this.customPreferencesService.userDrawStock = JSON.stringify(stocksDraw);
  }

  private getKeyDraw(): string {
    const cdStock = getCdStockParam(this.stock);
    return `${cdStock}:${this.stock.id_exchange}`;
  }

  private getSavedDrawConfigurations() {
    const stringDraw = this.customPreferencesService.userDrawStock;
    let stocksDraw: any = {};
    if (!isNullOrUndefined(stringDraw) && stringDraw != '') {
      stocksDraw = JSON.parse(stringDraw);
    }
    return stocksDraw;
  }

  getStockDraws(): saveConfiguration[] {
    const key = this.getKeyDraw();
    const stocksDraw: any = this.getSavedDrawConfigurations();
    return stocksDraw[key] ? (stocksDraw[key] as saveConfiguration[]) : [];
  }

  private getDrawConfig(
    baseChart: TWebAssemblyChart,
    draw: DrawTools,
    refComponent: string,
    useFather: boolean = false
  ): saveConfiguration {
    if (draw.isChildren && useFather) {
      const father = baseChart.sciChartSurface.annotations.getById(
        draw.tool.dictId!!
      ) as DrawTools;
      return this.getDrawConfig(baseChart, father, refComponent);
    }
    const positionProperties = this.getPositionProperties(draw);
    const xAxis = baseChart.sciChartSurface.xAxes.getById(
      `xAxis_${refComponent}`
    );
    const calculator =
      xAxis.getCurrentCoordinateCalculator() as CategoryCoordinateCalculator;
    let x1 = undefined,
      x2 = undefined;
    if (
      !(draw instanceof HorizontalLineTools) ||
      draw.tool.codTool === `${TOOLS_ENUM.TREND_LINES}_RAIO_HORIZONTAL`
    ) {
      x1 = draw.x1 ? calculator.transformIndexToData(draw.x1) : undefined;
      if (
        !(draw instanceof VerticalLineTools) &&
        !(draw instanceof HorizontalLineTools)
      ) {
        x2 = draw.x2 ? calculator.transformIndexToData(draw.x2) : undefined;
      }
    }
    const config: saveConfiguration = {
      position: draw.tool.position!!,
      firstEventData: {
        data: null,
        type: TIGER_MOUSE_EVENT_DATA_TYPE.DOMCANVAS_2D_MOUSE_CLICK,
        mousePoint: draw.tool.mouseValues,
        pointValues: draw.tool.pointValues,
        xCategoryValue: x1,
        middleXPointValues: draw.tool.middleXPointValues,
        middleYPointValues: draw.tool.middleYPointValues,
        isWithinDataBounds: draw.isWithinDataBounds,
      },
      lastEventData: {
        data: draw.lastEventData.data,
        type: TIGER_MOUSE_EVENT_DATA_TYPE.X_DATASERIES_HOVER,
        mousePoint: draw.lastEventData.mousePoint,
        pointValues: draw.lastEventData.pointValues,
        xCategoryValue: x2,
        isWithinDataBounds: draw.lastEventData.isWithinDataBounds,
      },
      method: draw.tool.method,
      typeCalculation: TOOLS_TYPE_CALCULATION.PLUS,
      x1Property: positionProperties.x1,
      x2Property: positionProperties.x2,
      y1Property: positionProperties.y1,
      y2Property: positionProperties.y2,
      lineThickness: draw.tool.lineThickness,
      color: draw.tool.color,
      backgroundColor: draw.tool.backgroundColor,
      textColor: draw.tool.textColor,
      outlineColor: draw.tool.outlineColor,
      text: draw.tool.text,
      textSize: draw.tool.textSize,
      textBold: draw.tool.textBold,
      textItalic: draw.tool.textItalic,
      strokeDashArray: draw.tool.strokeDashArray,
      codTool: draw.tool.codTool,
      group: draw.tool.group,
      svgString: draw.tool.svgString,
      useTextOutline: draw.tool.useTextOutline,
      useTextBackground: draw.tool.useTextBackground,
    };
    if (!draw.isChildren) {
      draw.tool.children?.forEach((child) => {
        if (!config.children) {
          config.children = [];
        }
        const childrenDraw = baseChart.sciChartSurface.annotations.getById(
          child.dictIdChildren!!
        ) as DrawTools;
        if (childrenDraw) {
          const toolChildren = draw.tool.children?.find(
            (c) => c.dictIdChildren === childrenDraw.id
          );
          if (toolChildren) {
            childrenDraw.tool = {
              ...childrenDraw.tool,
              ...{
                lineThickness:
                  toolChildren.lineThickness || childrenDraw.tool.lineThickness,
                color: toolChildren.color || childrenDraw.tool.color,
                backgroundColor:
                  toolChildren.backgroundColor ||
                  childrenDraw.tool.backgroundColor,
                outlineColor:
                  toolChildren.outlineColor || childrenDraw.tool.outlineColor,
                textColor:
                  toolChildren.textColor || childrenDraw.tool.textColor,
                text: toolChildren.text || childrenDraw.tool.text,
                textBold: toolChildren.textBold || childrenDraw.tool.textBold,
                textItalic:
                  toolChildren.textItalic || childrenDraw.tool.textItalic,
                textSize: toolChildren.textSize || childrenDraw.tool.textSize,
                strokeDashArray:
                  toolChildren.strokeDashArray ||
                  childrenDraw.tool.strokeDashArray,
                useTextOutline:
                  toolChildren.useTextOutline ||
                  childrenDraw.tool.useTextOutline,
                useTextBackground:
                  toolChildren.useTextBackground ||
                  childrenDraw.tool.useTextBackground,
              },
            };
          }
          if (draw.tool.codTool === `${TOOLS_ENUM.GEOMETRIC_SHAPES}_SEQUENCE`) {
            if (childrenDraw.endDraw) {
              config.children.push(
                this.getDrawConfig(baseChart, childrenDraw, refComponent)
              );
            }
          } else {
            config.children.push(
              this.getDrawConfig(baseChart, childrenDraw, refComponent)
            );
          }
        }
      });
    }

    return config;
  }

  private getPositionProperties(draw: DrawTools): {
    x1?: number;
    x2?: number;
    y1?: number;
    y2?: number;
  } {
    if (
      draw instanceof HorizontalLineAnnotation ||
      draw instanceof VerticalLineAnnotation
    ) {
      return { x1: draw.x1, y1: draw.y1 };
    }
    return { x1: draw.x1, x2: draw.x2, y1: draw.y1, y2: draw.y2 };
  }

  private removeDrawStock(annotation: DrawTools) {
    const stockDraws = deepClone(this.getStockDraws()) as saveConfiguration[];
    const drawIndex = stockDraws.findIndex(
      (draw) =>
        draw.codTool === annotation.tool.codTool &&
        draw.position === annotation.tool.position
    );
    if (drawIndex > -1) {
      stockDraws.splice(drawIndex, 1);
      const stocksDraw: any = this.getSavedDrawConfigurations();
      const key = this.getKeyDraw();
      stocksDraw[key] = stockDraws;
      this.customPreferencesService.userDrawStock = JSON.stringify(stocksDraw);
    }
  }

  setStock(stock: any) {
    this.stock = stock;
  }

  updateDrawPosition(length: number) {
    this.updateDrawPosition$.next({ length });
  }

  getDrawModels() {
    const stringModels = this.customPreferencesService.drawToolModel;
    if (!isNullOrUndefined(stringModels) && stringModels != '') {
      return JSON.parse(stringModels);
    }
    return {};
  }

  getModels(selectedTool: TIGER_CHART_TOOL) {
    const drawModels = this.getDrawModels();
    if (selectedTool.codTool in drawModels) {
      return drawModels[selectedTool.codTool] as ToolbarModel[];
    }
    return [];
  }

  deleteLastDraw(baseChart: TWebAssemblyChart) {
    const drawSize = this.drawDict.size();
    const lastDraw = this.drawDict.values()[drawSize - 1];
    if (lastDraw) {
      let hasToolbarConfig = false;
      if (this.toolbarDrawService.hasElement()) {
        const toolbarDraw = this.toolbarDrawService.getDrawTool();
        if (toolbarDraw) {
          hasToolbarConfig = toolbarDraw.id === lastDraw.id;
        }
      }
      this.deleteDraw(baseChart, lastDraw, hasToolbarConfig);
    }
  }

  resizeChartRenewSVG(
    baseChart: TWebAssemblyChart,
    draw: DrawTools,
    refComponent: string,
    configs: ICandleConfigs,
    chartHeight: number,
    stock: any,
    configsTools: saveConfiguration[]
  ) {
    this.drawResize.push(draw);
    this.resizeChartRenewSVG$.next({
      baseChart,
      draw,
      refComponent,
      configs,
      chartHeight,
      stock,
      configsTools,
    });
  }

  addDeletedLastDraw(
    baseChart: TWebAssemblyChart,
    refComponent: string,
    configs: ICandleConfigs,
    chartHeight: number,
    stock: any
  ) {
    const lastDraw = this.deletedDraws[this.deletedDraws.length - 1];
    if (lastDraw) {
      this.addDraw(
        baseChart,
        lastDraw.drawConfig,
        refComponent,
        configs,
        chartHeight,
        stock,
        true
      );
      this.deletedDraws.pop();
    }
  }

  startDrawEdit(
    selectedDrawAnnotation: DrawTools | undefined,
    isHoverDraw: boolean,
    refComponent: string,
    navigation?: {
      selectedTool: TIGER_CHART_TOOL | null;
      baseChart: TWebAssemblyChart;
      eventData: TMouseEventData;
      configs: ICandleConfigs;
      chartHeight: number;
      stock: any;
      showFastRuler: boolean;
    }
  ) {
    if (selectedDrawAnnotation) {
      const tool = selectedDrawAnnotation.tool;
      if (isHoverDraw) {
        if (!selectedDrawAnnotation.startEdit) {
          this.childrenDrawHidden(selectedDrawAnnotation, false);
          selectedDrawAnnotation.startEdit = true;
          if (this.toolbarDrawService.hasElement()) {
            this.toolbarDrawService.destroyComponent();
          }
          const SVG = document.getElementById(`${refComponent}_SVG`)!!;
          if (selectedDrawAnnotation.tool.group !== TOOLS_ENUM.TEMPORARY) {
            this.toolbarDrawService.show({
              drawToolsService: this,
              ref: refComponent,
              selectedDraw: selectedDrawAnnotation,
              position: {
                x: SVG.getBoundingClientRect().width - 350,
                y: 50 - SVG.getBoundingClientRect().height,
              },
            });
          }
          if (tool.hasBoxAux) {
            const children = tool.children?.filter((c) => c.isAux);
            const childrenEdit = tool.children?.filter(
              (c) => !c.isAux && c.withBoxAuxCanEdit
            );
            if (tool.withBoxAuxCanEdit) {
              this.makeGripsAnnotation(selectedDrawAnnotation);
            }
            if (children) {
              children.forEach((child) => {
                const aux = selectedDrawAnnotation.children.find(
                  (c) => c.id === child?.dictIdChildren
                )!!;
                aux.startEdit = true;
                this.makeGripsAnnotation(aux);
              });
            }
            if (childrenEdit) {
              childrenEdit.forEach((child) => {
                const edit = selectedDrawAnnotation.children.find(
                  (c) => c.id === child?.dictIdChildren
                )!!;
                edit.startEdit = true;
                this.makeGripsAnnotation(edit);
              });
            }
          } else {
            this.makeGripsAnnotation(selectedDrawAnnotation);
          }
        }
      } else {
        selectedDrawAnnotation.startEdit = false;
        selectedDrawAnnotation.isSelected = false;
        this.childrenDrawHidden(selectedDrawAnnotation, true);
        if (tool.hasBoxAux) {
          const children = tool.children?.filter((c) => c.isAux);
          const childrenEdit = tool.children?.filter(
            (c) => !c.isAux && c.withBoxAuxCanEdit
          );
          if (children) {
            children.forEach((child) => {
              const aux = selectedDrawAnnotation.children.find(
                (c) => c.id === child?.dictIdChildren
              )!!;
              aux.startEdit = false;
              aux.isSelected = false;
              //aux.isEditable = false;
            });
          }
          if (childrenEdit) {
            childrenEdit.forEach((child) => {
              const edit = selectedDrawAnnotation.children.find(
                (c) => c.id === child?.dictIdChildren
              )!!;
              edit.startEdit = false;
              edit.isSelected = false;
              //edit.isEditable = false;
            });
          }
        }
        if (this.toolbarDrawService.hasElement()) {
          this.toolbarDrawService.destroyComponent();
        }
      }
    } else {
      if (this.toolbarDrawService.hasElement()) {
        const toolbatDraw = this.toolbarDrawService.getDrawTool();
        this.childrenDrawHidden(toolbatDraw!!, true);
        this.toolbarDrawService.destroyComponent();
      }
      if (navigation && navigation.showFastRuler) {
        const annotation = navigation.baseChart.sciChartSurface.annotations
          .asArray()
          .find((annotation) => {
            const draw = annotation as DrawTools;
            return (
              draw.isDraw &&
              draw.tool &&
              draw.tool.group === TOOLS_ENUM.TEMPORARY
            );
          }) as DrawTools;
        if (annotation) {
          if (annotation.endDraw) {
            this.removeAnnotation(navigation.baseChart, annotation);
          }
          return { drawTool: selectedDrawAnnotation };
        }
        const selectedTool: TIGER_CHART_TOOL = deepClone(
          TOOLS.get(TOOLS_ENUM.TEMPORARY)!![0]
        );
        let step = 1;
        if (navigation.selectedTool) {
          step = selectedTool.stepClick;
        }
        const tool = this.createTool(
          navigation.baseChart,
          navigation.eventData,
          selectedTool,
          refComponent,
          step,
          navigation.configs,
          navigation.chartHeight,
          navigation.stock
        );
        this.toolEvent$.next({
          componentRef: refComponent,
          tool: tool.tool,
          typeEvent: TYPE_EVENT.UPDATE,
          countStepClick: step,
        });
      }
    }
    return { drawTool: selectedDrawAnnotation };
  }

  makeGripsAnnotation(selectedDrawAnnotation: DrawTools) {
    selectedDrawAnnotation.isSelected = true;
    selectedDrawAnnotation.isEditable = this.isLocked ? false : true;
    selectedDrawAnnotation.annotationsGripsFill = 'var(--vm-neutral-strongest)';
    if (selectedDrawAnnotation.annotationsGripsStroke === '#CDCDCD99') {
      selectedDrawAnnotation.annotationsGripsStroke = 'var(--vm-brand-primary)';
    }
    selectedDrawAnnotation.selectionBoxStroke = 'transparent';
    selectedDrawAnnotation.annotationsGripsRadius = 6;
  }

  updateDrawConfig(selectedDraw: DrawTools, style: StyleTool) {
    const stockDraws = this.getStockDraws();
    const drawConfigIndex = stockDraws.findIndex(
      (d) =>
        d.position === selectedDraw.tool.position!! &&
        d.codTool === selectedDraw.tool.codTool
    );
    const cdStock = getCdStockParam(this.stock);
    const key = `${cdStock}:${this.stock.id_exchange}`;
    const stocksDraw: any = this.getSavedDrawConfigurations();
    if (drawConfigIndex > -1) {
      this.updateStyleConfig(stockDraws[drawConfigIndex], style);
      this.updateChildrenStyle(selectedDraw, stockDraws[drawConfigIndex]);
    }
    stocksDraw[key] = stockDraws;
    this.updateDrawConfig$.next({ stocksDraw });
  }

  private setStyleConfig(
    stockDraw: saveConfiguration,
    style: StyleTool,
    field: string
  ): saveConfiguration {
    const colorsField = [
      'textColor',
      'outlineColor',
      'backgroundColor',
      'color',
    ];
    const anyStyle = style as any;
    const anyStockDraw = (stockDraw as any) ?? {};
    if (field in anyStyle && anyStyle[field]) {
      if (colorsField.indexOf(field) !== -1) {
        anyStockDraw[field] = anyStyle[field].rgbaHexColor;
      } else {
        anyStockDraw[field] = anyStyle[field];
      }
    }
    return stockDraw;
  }

  private updateStyleConfig(stockDraw: saveConfiguration, style: StyleTool) {
    stockDraw = this.setStyleConfig(stockDraw, style, 'textColor');
    stockDraw = this.setStyleConfig(stockDraw, style, 'text');
    stockDraw = this.setStyleConfig(stockDraw, style, 'textSize');
    stockDraw = this.setStyleConfig(stockDraw, style, 'textBold');
    stockDraw = this.setStyleConfig(stockDraw, style, 'textItalic');
    stockDraw = this.setStyleConfig(stockDraw, style, 'outlineColor');
    stockDraw = this.setStyleConfig(stockDraw, style, 'backgroundColor');
    stockDraw = this.setStyleConfig(stockDraw, style, 'color');
    stockDraw = this.setStyleConfig(stockDraw, style, 'strokeDashArray');
    stockDraw = this.setStyleConfig(stockDraw, style, 'strokeThickness');
    stockDraw = this.setStyleConfig(stockDraw, style, 'useTextOutline');
    stockDraw = this.setStyleConfig(stockDraw, style, 'useTextBackground');
  }

  private makeChildrenColors(
    child: TIGER_CHART_TOOL_CHILD,
    field: string
  ): color | undefined {
    const anyChild = child as any;
    if (field in anyChild && anyChild[field]) {
      const rgbaColor = this.colorsBaseService.hex2Rgba(anyChild[field]);
      const rgbColor = this.colorsBaseService.rgba2rgb(rgbaColor);
      const hexColor = this.colorsBaseService.rgba2hex(rgbColor);
      return {
        selected: true,
        rgbaColor,
        rgbColor,
        rgbaHexColor: anyChild[field],
        hexColor,
      };
    }
    return;
  }

  private updateChildrenStyle(
    selectedDraw: DrawTools,
    stockDraw: saveConfiguration
  ) {
    if (!selectedDraw.tool.children) {
      return;
    }
    selectedDraw.tool.children.forEach((child, i) => {
      const childConfig = stockDraw.children!![i];
      const color = this.makeChildrenColors(child, 'color');
      const backgroundColor = this.makeChildrenColors(child, 'backgroundColor');
      const textColor = this.makeChildrenColors(child, 'textColor');
      const outlineColor = this.makeChildrenColors(child, 'outlineColor');
      if (childConfig) {
        this.updateStyleConfig(childConfig, {
          text: child.text,
          textBold: child.textBold,
          textItalic: child.textItalic,
          textSize: child.textSize,
          textColor,
          backgroundColor,
          color,
          outlineColor,
          strokeDashArray: child.strokeDashArray,
          strokeThickness: child.strokeThickness,
          useTextOutline: child.useTextOutline,
          useTextBackground: child.useTextBackground,
        });
      }
    });
  }

  updateToolConfig(
    tool: TIGER_CHART_TOOL,
    configuration?: DrawToolConfiguration
  ) {
    if (configuration) {
      if (configuration.color) {
        tool.color = configuration.color;
      }
      if (configuration.backgroundColor) {
        tool.backgroundColor = configuration.backgroundColor;
      }
      if (configuration.outlineColor) {
        tool.outlineColor = configuration.outlineColor;
      }
      if (configuration.textColor) {
        tool.textColor = configuration.textColor;
      }
      if (!isNullOrUndefined(configuration.textBold)) {
        tool.textBold = configuration.textBold;
      }
      if (!isNullOrUndefined(configuration.textItalic)) {
        tool.textItalic = configuration.textItalic;
      }
      if (configuration.textSize) {
        tool.textSize = configuration.textSize;
      }
      if (!isNullOrUndefined(configuration.text)) {
        tool.text = configuration.text;
      }
      if (configuration.lineThickness) {
        tool.lineThickness = configuration.lineThickness;
      }
      if (configuration.strokeDashArray) {
        tool.strokeDashArray = configuration.strokeDashArray;
      }
      if (configuration.useTextOutline) {
        tool.useTextOutline = configuration.useTextOutline;
      }
      if (configuration.useTextBackground) {
        tool.useTextBackground = configuration.useTextBackground;
      }
    }
  }

  deleteDraw(
    baseChart: TWebAssemblyChart,
    drawTool: DrawTools,
    hasToolbarConfig: boolean
  ) {
    const stockDraws = this.getStockDraws();
    const drawConfig = stockDraws.find(
      (draw) => draw.position === drawTool.tool.position
    )!!;
    this.deletedDraws.push({ tool: drawTool.tool, drawConfig });
    this.removeAnnotation(baseChart, drawTool);
    if (hasToolbarConfig) {
      this.toolbarDrawService.destroyComponent();
    }
  }

  private addToRedraw(
    tool: TIGER_CHART_TOOL,
    draw: DrawTools,
    drawConfig: saveConfiguration,
    configuration?: DrawToolConfiguration
  ) {
    this.redrawTool.set(draw.id, {
      tool,
      draw,
      drawConfig,
      configuration,
      isRedraw: true,
    });
  }

  updateChartZoomModifers(baseChart: TWebAssemblyChart, isEnabled: boolean) {
    baseChart.sciChartSurface.chartModifiers
      .asArray()
      .filter((mod) => {
        const json = mod.toJSON!!();
        return (
          json['type'] === EChart2DModifierType.ZoomPan ||
          json['type'] === EChart2DModifierType.ZoomExtents
        );
      })
      .map((mod) => {
        const configuration =
          this.stockChartModalService.getGlobalChartConfigs();
        mod.isEnabled = isEnabled;
        if (!configuration.fastRulerNavigation && configuration.showFastRuler) {
          mod.isEnabled = !configuration.showFastRuler;
        }
      });
  }

  childrenDrawHidden(selectedDrawAnnotation: DrawTools, isHidden: boolean) {
    const tool = selectedDrawAnnotation.tool;
    selectedDrawAnnotation.children.forEach((child) => {
      const toolChild = tool.children?.find(
        (c) => c.dictIdChildren === child.id
      );
      if (!isNullOrUndefined(toolChild?.isHidden)) {
        child.isHidden = isHidden;
      }
    });
  }

  updateSubCharts(
    subCharts: Dictionary<{
      indicator: BaseIndicator;
      height: number;
      dividerPosition: number;
    }>
  ) {
    this.subCharts = subCharts;
  }
}
