export enum RANKING_BROKER_PERIODS {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  ONE_WEEK = 'oneweek',
  ONE_MONTH = 'onemonth',
  THREE_MONTH = 'threemonth',
  SIX_MONTH = 'sixmonth',
  ONE_YEAR = 'oneyear',
  TWO_YEAR = 'twoyear',
}

export enum RANKING_BROKER_FILTERS {
  VL_NET = 'vl_net',
  VL_TOTAL = 'vl_total',
  VL_BUSINESS = 'vl_negocios',
  VL_BUY = 'vl_buuy',
  VL_SELL = 'vl_sell',
  VL_PERCENTAGE_BUY = 'percentage_buy',
  VL_PERCENTAGE_SELL = 'percentage_sell',
  QTTY_SHARES_BUY = 'qtty_shares_buy',
}

export enum RANKING_TABS {
  FLASH = 'FLASH',
  RANKING = 'RANKING',
  BROKER = 'BROKER',
}
