import { ORDER_CONFIRM_ACTIONS_ENUM } from '@shared/enum/buyOrSell.enum';
import { RocketCreateComponentService } from '@shared/rocket-components/services';

import { BehaviorSubject } from 'rxjs';
import { IntrojsService } from '@core/introjs/introjs.service';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { ActivatedRoute } from '@angular/router';
import { ContextMenuService } from '../popup-root/context-menu.service';
import { AtPriceContextMenuComponent } from './parts/at-price-context-menu.component';

export abstract class AtPriceHelper extends RocketComponentBase {
  protected componentId!: string;
  public showDetail: boolean = true;
  private configUpdate$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public invertOrReserPostionEnum = ORDER_CONFIRM_ACTIONS_ENUM;

  constructor(
    protected createComponent: RocketCreateComponentService,
    protected introJsService: IntrojsService,
    activatedRoute: ActivatedRoute,
    protected contextMenuService: ContextMenuService
  ) {
    super(activatedRoute);
  }

  protected showConfig = (event: any) => {
    AtPriceContextMenuComponent.openContextMenu(
      this.contextMenuService,
      this.componentId,
      { clientX: event.clientX, clientY: event.clientY },
      Boolean(event.isTour),
      this.showDetail
    );

    return false;
  };
}
