import { Injectable } from '@angular/core';
import { BrokerService } from '../../api/trademap/v1/broker.service';
import { Observable, Subject, debounceTime, filter, map, of, take } from 'rxjs';
import { StandaloneModalsService } from '@shared/modals/standalone-modals.service';
import { FirstAccessComponent } from '@shared/modals/welcome-modals/first-access/first-access.component';
import { system } from '@core/system/system.service';
import { TrialExpiredComponent } from '@shared/modals/welcome-modals/trial-expired/trial-expired.component';
import { TrademapComponent } from '@shared/modals/welcome-modals/trademap/trademap.component';
import { WorkspacesPreferences } from '../custom-preferences/workspace';
import { RocketModalService } from '@shared/rocket-components/components';
import { InitializeBrokerConnectService } from '../../initialize-broker-connect.service';
import { BrokerPartnerAlreadyConnectedComponent } from '@shared/modals/welcome-modals/broker-partner-already-connected/broker-partner-already-connected.component';
import {
  AUTH_PARTNER_NICKNAME,
  AVAILABLE_PARTNERS_ENUM,
  BROKER_PARTNER_CAMPAING,
} from './partners.const';
import { PartnersPreferencesService } from './partners-preferences.service';
import { HttpClient } from '@angular/common/http';
import { IPartnerInfos } from './partner-preferences.interface';
import { ConnectPartnerComponent } from '@shared/modals/partners/connect-partner/connect-partner.component';
import { WelcomeXpModalComponent } from '@shared/modals/partners/welcome-xp-modal/welcome-xp-modal.component';
import { IBrokersToConnect } from '@shared/services/api/trademap/V9/models/brokers-to-connect.interface';
import { ModalConnectBrokerComponent } from '@modules/broker-connection/modals/modal-connect-broker/modal-connect-broker.component';
import { TrademapV10Service } from '@shared/services/api/trademap/V10/trademap.service';
import {
  IBrokersInfosV10,
  IBrokersToConnectV10,
} from '@shared/services/api/trademap/V10/models/brokers-to-connect.interface';

export enum ONBOARDING_MODALS_EVENTS {
  INIT,
  LOGOUT,
}

@Injectable({
  providedIn: 'root',
})
export class PartnersService extends PartnersPreferencesService {
  private _authEventsSubject = new Subject<ONBOARDING_MODALS_EVENTS>();
  private _intervalToDisplayPartnerNotification = new Subject<void>();
  private _openConnectBrokerAfterLoadSystem = false;

  get partner(): IPartnerInfos | null {
    return this.linkedPartnerInfos;
  }

  set openConnectBrokersAfterLoadSystem(wait: boolean) {
    this._openConnectBrokerAfterLoadSystem = wait;
  }

  get openConnectBrokersAfterLoadSystem(): boolean {
    return this._openConnectBrokerAfterLoadSystem;
  }

  get authEvents$(): Observable<ONBOARDING_MODALS_EVENTS> {
    return this._authEventsSubject.asObservable();
  }

  get displayWelcomeTM(): boolean {
    return !this.partner || this.partner?.key === 'trademappro';
  }

  get canDisplayPartnerCampaingComponents(): boolean {
    return this.partner !== null && BROKER_PARTNER_CAMPAING[this.partner.key!];
  }

  constructor(
    private _brokerService: BrokerService,
    private _standaloneModalsService: StandaloneModalsService,
    private _workspacePreference: WorkspacesPreferences,
    private _rocketModalService: RocketModalService,
    private _initializeBrokerConnectService: InitializeBrokerConnectService,
    private _trademap10Service: TrademapV10Service,
    private http: HttpClient
  ) {
    super(http);
    this._intervalToDisplayPartnerNotification
      .pipe(
        filter(() => this.canDisplayPartnerCampaingComponents),
        debounceTime(60000) // 1min
      )
      .subscribe(() => this._verifyCanDisplayPartnerNotification());
  }

  public verifyPartnerAfterLogin(): void {
    if (
      system.userAuthenticated.investor.alreadyUsedTrial &&
      system.userAuthenticated.investor.isSessionWeak
    ) {
      this.openTrialExpiredModal();
      return;
    }

    if (system.userAuthenticated.investor.isSessionWeak) {
      this.openWelcomeModal();
      return;
    }

    if (!this.partner) return;

    if (this.partner.key === 'trademappro') {
      if (this.shouldDisplayWelcomeTM) this.openTradeMapModal();
      return;
    }

    if (!this.shouldDisplayPartnerModal()) return;

    if (this.partner.key === 'xp') {
      this.openXPModal();
      return;
    }

    this.openConnectionModal();
  }

  public shouldDisplayConnectBrokerButton(): Observable<IPartnerInfos | null> {
    if (!this.canDisplayPartnerCampaingComponents) return of(null);
    return this._brokerService.onUpdateConnectedBrokers$.pipe(
      map(() => {
        const index = system.brokers.findIndex(
          (bkr) =>
            bkr.nm_broker === this.partner!.name ||
            bkr.nm_broker_nickname === this.partner!.name
        );
        if (index >= 0) return null;
        this._intervalToDisplayPartnerNotification.next();
        return this.partner;
      })
    );
  }

  public openWelcomeModal(isAfterOnboarding: boolean = false) {
    this._getBrokersToConnect().subscribe((brokers) => {
      if (this.partner) {
        const broker = this._verifyIsConnected(brokers, this.partner.key);
        if (broker) {
          this.openModalAlreadyConnected(broker);
          return;
        }
      }

      const sub$ = this._standaloneModalsService.openStandaloneModal(
        FirstAccessComponent,
        {
          alreadyUsedTrial: system.userAuthenticated.investor.alreadyUsedTrial,
          brokers: brokers,
          isAfterOnboarding,
        },
        {
          keyboard: false,
          hideCloseButton: true,
          notClosable: true,
          backdrop: 'static',
        },
        '',
        'lg'
      );

      if (isAfterOnboarding) {
        console.log('isAfterOnboarding', isAfterOnboarding);
        sub$.afterDismissed.pipe(take(1)).subscribe(() => {
          this._workspacePreference.waitToDisplayInitialTour = false;
        });
      }
    });
  }

  public openTrialExpiredModal(): void {
    this._getBrokersToConnect().subscribe((brokers) => {
      this._standaloneModalsService.openStandaloneModal(
        TrialExpiredComponent,
        {
          brokers,
        },
        {
          keyboard: false,
          backdrop: 'static',
        },
        '',
        'xl'
      );
    });
  }

  public openTradeMapModal(): void {
    this._standaloneModalsService.openStandaloneModal(
      TrademapComponent,
      {
        trialEnabled: system.userAuthenticated.investor.alreadyUsedTrial,
      },
      {
        keyboard: false,
        backdrop: 'static',
      }
    );
  }

  public openXPModal(): void {
    this._standaloneModalsService
      .openStandaloneModal(
        WelcomeXpModalComponent,
        {},
        {
          keyboard: false,
          backdrop: 'static',
        }
      )
      .afterDismissed.pipe(take(1))
      .subscribe(() => (this.hasSeenModal = { type: 'BROKER', key: 'xp' }));
  }

  public openConnectionModal(): void {
    if (
      !this.partner ||
      this.partner.key === 'trademappro' ||
      this.partner.key === 'rocket'
    )
      return;
    this._getBrokersToConnect().subscribe((brokers) => {
      const broker = this._getBrokerByName(brokers, this.partner!.key);
      if (
        broker &&
        this.partner &&
        !system.userAuthenticated.investor.isSessionWeak
      )
        console.log('BLOCKED_CASE');
      if (broker?.hasAccount) {
        this.openModalAlreadyConnected(broker);
        return;
      }
      if (
        !system.userAuthenticated.investor.isSessionWeak &&
        this._workspacePreference.waitToDisplayInitialTour
      )
        this._workspacePreference.waitToDisplayInitialTour = true;
      if (broker) this._openModalToConnectBroker(broker.idBroker);
    });
  }

  public openModalAlreadyConnected(broker: IBrokersToConnect): void {
    const trialDuration = broker.trialDescription
      ? broker.trialDescription.replace(/\D/g, '') || '30'
      : '30';
    this._standaloneModalsService
      .openStandaloneModal(
        BrokerPartnerAlreadyConnectedComponent,
        { idBroker: broker.idBroker, trialDuration },
        {
          keyboard: false,
          backdrop: 'static',
        },
        '',
        'lg'
      )
      .afterDismissed.pipe(take(1))
      .subscribe(() => {
        this._updateSessionWeak(false);
        this._initializeBrokerConnectService.initializeBroker$.next(true);
        this._authEventsSubject.next(ONBOARDING_MODALS_EVENTS.INIT);
        this._authEventsSubject.complete();
      });
  }

  private _updateSessionWeak(isSessionWeak: boolean): void {
    system.userAuthenticated.investor.isSessionWeak = isSessionWeak;
  }

  private _getBrokersToConnect() {
    return this._trademap10Service.getBrokersToConnect().pipe(
      take(1),
      map((result) => this._verifyShouldDisplayBroker(result))
    );
  }

  private _verifyShouldDisplayBroker(
    params: IBrokersToConnectV10
  ): IBrokersInfosV10[] {
    if (!params.brokerList.length) return [];
    return params.brokerList.filter((bkr) => !this.hideBroker(bkr));
  }

  private _verifyIsConnected(
    brokers: IBrokersToConnect[],
    partner: AVAILABLE_PARTNERS_ENUM
  ): IBrokersToConnect | undefined {
    if (!partner) return undefined;
    const broker = this._getBrokerByName(brokers, partner);
    if (broker && broker.hasAccount) return broker;
    return undefined;
  }

  private _getBrokerByName(
    brokers: IBrokersToConnect[],
    partner: AVAILABLE_PARTNERS_ENUM
  ): IBrokersToConnect | undefined {
    if (!partner) return undefined;
    const partnerName = AUTH_PARTNER_NICKNAME[partner];
    const broker = brokers.find(
      (bkr) =>
        bkr.nmBroker === partnerName || bkr.nmBrokerNickname === partnerName
    );
    return broker;
  }

  private _openModalToConnectBroker(idBroker: number): void {
    const isSessionWeak = system.userAuthenticated.investor.isSessionWeak;
    this._standaloneModalsService
      .openStandaloneModal(
        ModalConnectBrokerComponent,
        { idBroker },
        {},
        '',
        'lg'
      )
      .afterDismissed.pipe(take(1))
      .subscribe(() => {
        if (isSessionWeak) {
          this._authEventsSubject.next(ONBOARDING_MODALS_EVENTS.LOGOUT);
          return;
        }
        this._rocketModalService.resetDataInfos();
        this._initializeBrokerConnectService.initializeBroker$.next(true);
        this._workspacePreference.waitToDisplayInitialTour = false;
        this._authEventsSubject.next(ONBOARDING_MODALS_EVENTS.INIT);
        this._authEventsSubject.complete();
      });
  }

  private _verifyCanDisplayPartnerNotification(): void {
    if (this._rocketModalService.isDisplayingSomeModal) {
      this._intervalToDisplayPartnerNotification.next();
      return;
    }
    this._displayNotificationToConnectPartner();
  }

  private _displayNotificationToConnectPartner(): void {
    this._rocketModalService
      .open(ConnectPartnerComponent, {
        backdrop: false,
        keyboard: false,
        lockScreen: false,
        hideCloseButton: true,
        data: { partner: this.partner },
      })
      .afterDismissed.pipe(take(1))
      .subscribe(() => this._intervalToDisplayPartnerNotification.next());
  }
}
