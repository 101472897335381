import {
  Component,
  Input,
  OnDestroy,
  ViewChild,
  Renderer2,
  ElementRef,
  signal,
  computed,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  COLOR_BOLETA,
  ORDER_TYPES,
  PRICE_COUNTER_FIELDS,
  STOCK_TRADE_TOTAL_LAST_PRICE,
  TYPE_TRADE,
} from './constants/boleta.constants';
import { HomeService } from '@modules/home/service/home.service';
import {
  Subject,
  Subscription,
  auditTime,
  debounceTime,
  delay,
  filter,
  map,
  of,
  tap,
} from 'rxjs';
import { WorkSpaceConfigs } from '@core/workspace/config/workspace.configs';
import { OrsGatewayServiceV2 } from '@shared/services/api/trademap/V2/ors-gateway.service';
import {
  TYPE_ORDE_SIDE_ENUM,
  TYPE_ORDE_SIDE_STRING_ENUM,
} from '@shared/enum/buyOrSell.enum';
import {
  STOCK_TRADES_TYPE,
  STOCK_TRADE_ELEMENTS_ID,
  TYPE_ORDE_ENUM,
  TYPE_VALIDADE_ENUM,
} from './enum/stock-trade.enum';
import { DynamicControlService } from '@shared/rocket-components/dynamic-form/dynamic-control.service';
import { StockTradeService } from './service/stock-trade.service';
import { CreateForm } from './parts/configs/create-form';
import { SendOrder } from './parts/configs/send-order';
import {
  isStartStop,
  isTypeStockIndex,
} from '@shared/constants/general.contant';
import { isDoubleStartStop } from '../../constants/general.contant';
import {
  IAccountSelect,
  IOrders,
  ISearchStock,
  IWorkSpaceComponet,
  IEditOrder,
  IOrderToEditInfos,
} from 'src/app/core/interface';
import {
  formatterNumber,
  getIncrementValue,
  isCrypto,
  isNullOrUndefined,
} from 'src/app/utils/utils.functions';
import { DaytradeService } from '@core/layout/header/daytrade/daytrade.service';
import { BalanceService } from '@shared/services/api/trademap/v1/balance.service';
import { MultibrokerService } from '@shared/services/core/multibroker';
import {
  isIdBrokerSimulator,
  isSimulatorOrConsolidated,
} from '@shared/constants/simulator-league.constant';
import { SearchStockComponent } from '../search-stock/search-stock.component';
import { DragService } from '@shared/rocket-components/services/ag-grid/drag.service';
import { StockTradeContextMenuConfig } from './parts/stock-trade-context-menu/stock-trade-content-menu.config';
import { RocketCreateComponentService } from '@shared/rocket-components/services';
import { IntrojsService } from '@core/introjs/introjs.service';
import { StockPreferencesService } from '@shared/services/stock-preferences.service';
import { OriginAnalysisOrderService } from '@shared/services/origin-analysis-order.service';
import { ORDER_PARAM_HELPER } from '@shared/constants/order-param-helper.const';
import { DatePipe } from '@angular/common';
import { OrderTokenService } from '@shared/rocket-components/modal-order-token/order-token.service';
import { deepClone } from '../../rocket-components/utils/functions';
import { GlobalSelectedStockSubscription } from '@shared/services/core/subscription/global-stock.subscription';
import { ActivatedRoute } from '@angular/router';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { isWebViewContext } from 'src/app/desktop/integration.service';
import { RocketStreamRead } from '@shared/channel/rx-event';
import { RTInputCountComponent } from '@shared/rocket-components/input-count/input-count.component';
import { StockServiceRT } from '@shared/services/api/nitro-ws/v1/stock.service';
import { randomId } from 'src/app/utils/utils.framework';
import { ModalOpenService } from '@shared/rocket-components/components/modal/service/modal-open.service';

@Component({
  selector: 'app-stock-trade',
  templateUrl: './stock-trade.component.html',
  styleUrls: ['./stock-trade.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockTradeComponent
  extends RocketComponentBase
  implements OnDestroy, AfterViewInit
{
  ref: string = randomId('stock_trade');
  @ViewChild('searchStock') searchStock!: SearchStockComponent;
  @ViewChild('stockTradeRef', { static: true }) stockTradeRef!: ElementRef;
  @ViewChild('qtde', { static: false }) qtde!: RTInputCountComponent;
  @ViewChild('gainOrLossPrice', { static: false })
  gainOrLossPrice!: RTInputCountComponent;
  @Input() set component(component: IWorkSpaceComponet) {
    if (component) {
      this._component = component;
    }
  }
  public hashStops: any = {};
  _component!: IWorkSpaceComponet;
  refComponent!: string;
  stock!: ISearchStock;
  form!: FormGroup;
  lastPrice = signal(0);
  orderTypeEnum = TYPE_ORDE_ENUM;
  listTypes = ORDER_TYPES;
  sideOrder = TYPE_ORDE_SIDE_ENUM;
  priceCounterFields = PRICE_COUNTER_FIELDS;
  propsBtnSend = {
    bg: 'bg-multibroker-buy',
    text: 'Comprar',
  };
  isFocus: boolean = false;
  isEdit = false;
  incrementValue = 1;
  incrementPrice = 1;
  fieldsForm!: any[];
  isMarket = false;
  brokerBalance = 'R$ 0,00';
  brokerSelect = '';
  loading = false;
  elementsID = STOCK_TRADE_ELEMENTS_ID;
  qttyChangeEnabled: boolean = false;
  qttyChangedColorEnabled: boolean = false;
  isReseted: boolean = false;
  isDesktop = false;
  private isModalOpen: boolean = false;
  private isSearchStockOpen: boolean = false;
  private stockStreamActive!: RocketStreamRead;
  private checkIsOrderOCOOrDouble$ = new Subject<void>();
  private instanceForm!: CreateForm | null;
  private account!: IAccountSelect;
  private orderEdit!: IOrders;
  private cdSegment = '';
  private dsAsset = '';
  private onAccountChange$: Subscription = new Subscription();
  private _tourSubscription!: Subscription;
  private dragEvents$!: Subscription;
  private configStockTradeEdit$!: Subscription;
  private _configStockTradeEdit$ = new Subject<IEditOrder>();
  private getPreferencesStock$!: Subscription;
  private _getPreferencesStock$ = new Subject<{
    refComponent: string;
  }>();
  private _resetSubscription = new Subscription();
  private _orderTokenSubscription = new Subscription();
  private _formChangeSubscription = new Subscription();
  private _typeBeforeTour!: STOCK_TRADES_TYPE;
  private _typeDuringTour!: STOCK_TRADES_TYPE;
  private tempFormValues: any = {
    lossPriceTrigger: 0,
    lossPrice: 0,
    gainPriceTrigger: 0,
    gainPrice: 0,
    stopPx: 0,
    qtde: 0,
    price: 0,
  };
  private lastPriceQuote = 0;
  private _globalStock!: ISearchStock;
  private focusQtty: boolean = false;
  get priceControl() {
    return this.form.get('price')!;
  }
  get orderSideControl() {
    return this.form!.get('orderSide')!;
  }
  get orderSide() {
    return this.form?.get('orderSide')!.value;
  }
  get refData() {
    return this._component.metadata.headerOptions.component!.data;
  }
  get multiplePriceError() {
    return this.form.errors?.['multiplePriceError'];
  }
  headerOptions = computed(() => {
    return this._component.metadata?.headerOptions;
  });
  standardLot = signal(0);
  get vlClosePrice() {
    return `${this.stock?.stockVariation?.vl_close || 0}`;
  }
  total = computed(() => {
    const contractMultiplier =
      this.stock?.vl_contract_multiplier &&
      this.stock?.vl_contract_multiplier != -1
        ? this.stock?.vl_contract_multiplier
        : 1;
    let value = this.formatFinancial(
      this.qtdeSignal() * this.priceSignal() * contractMultiplier
    );
    if (STOCK_TRADE_TOTAL_LAST_PRICE.includes(this.form.value.orderType)) {
      value = this.formatFinancial(
        this.qtdeSignal() * this.lastPrice() * contractMultiplier
      );
    }
    return value;
  });
  orderOcoOrDoubleControl = computed(() => {
    return (this.orderOcoSignal() || this.orderDoubleSignal()) ?? false;
  });
  get tickSize(): number {
    if (this.stock && isCrypto(this.stock.ds_type!)) {
      return 0;
    }
    return this.stock ? this.stock.tick_size_denominator ?? 2 : 2;
  }
  get quoteParams() {
    return [`${this.stock.cd_stock}:${this.stock.id_exchange}`];
  }
  private orderOcoSignal = signal(false);
  private orderDoubleSignal = signal(false);
  private qtdeSignal = signal(0);
  private priceSignal = signal(0);
  private isSimulator: boolean = false;
  private minPriceIncrement!: number;
  private datePipe: DatePipe = new DatePipe('pt-BR');
  private inputFocus = new Subject<RTInputCountComponent>();
  @HostListener('window:keydown.enter')
  enterHandler() {
    if (
      this.isModalOpen ||
      this.isSearchStockOpen ||
      this.loading ||
      !this.homeService.isSelectedComponent(this.ref)
    ) {
      return;
    }
    this.sendOrder();
  }

  constructor(
    private homeService: HomeService,
    private workSpaceConfigs: WorkSpaceConfigs,
    private _orsGatewayServiceV2: OrsGatewayServiceV2,
    private dynamicControlService: DynamicControlService,
    private stockTradeService: StockTradeService,
    private _dayTradeService: DaytradeService,
    private _balanceService: BalanceService,
    private _multibrokerService: MultibrokerService,
    private dragService: DragService,
    private createComponent: RocketCreateComponentService,
    private _renderer: Renderer2,
    private _introJsService: IntrojsService,
    private _stockPreferencesService: StockPreferencesService,
    private cdr: ChangeDetectorRef,
    private originAnalysisOrderService: OriginAnalysisOrderService,
    private _orderTokenService: OrderTokenService,
    activatedRoute: ActivatedRoute,
    private globalSelectedStockSubscription: GlobalSelectedStockSubscription,
    private _quoteChannel: QuoteChannel,
    private stockService: StockServiceRT,
    private modalOpenService: ModalOpenService
  ) {
    super(activatedRoute);
    this.isDesktop = isWebViewContext();
    this.instanceForm = new CreateForm(this.dynamicControlService, {});
  }

  protected initComponent(component: any): void {
    if (component) this._component = component;
    this.componentInit();
  }

  private enableStockTradeEditObs(): void {
    this.configStockTradeEdit$ = this._configStockTradeEdit$
      .pipe(
        debounceTime(300),
        map((data) => data.data)
      )
      .subscribe((data) => this._setOrderDataToEdit(data));
  }

  ngAfterViewInit() {
    this.dragEvents$ = this.dragService
      .onEvents(this._component.id)
      .subscribe((data) => {
        this.selectStockByCdStock(data.data.cd_stock);
      });

    this.getPreferencesStock$ = this._getPreferencesStock$
      .pipe(
        filter((data) => data.refComponent === this.refComponent),
        tap(() => {
          this.qttyChangeEnabled = false;
          this.cdr.detectChanges();
        }),
        filter(() => this._stockPreferencesService.saveQttyStockEnabled),
        map(() => {
          const indexField = this.fieldsForm?.findIndex(
            (field: any) => field.key === 'qtde'
          );
          return { indexField };
        }),
        tap((data) => {
          data.indexField > -1 &&
            this.fieldsForm[data.indexField].classComponent.replaceAll(
              ' color-modify-qtty',
              ''
            );
        }),
        delay(200)
      )
      .subscribe((data) => {
        if (this.isEdit || !this.stock) return;
        const value = this._stockPreferencesService.getStockSaved(
          this.stock.cd_stock_order,
          this.standardLot(),
          'qtty'
        );
        if (this.standardLot() !== value) {
          this.form.get('qtde')!.setValue(value);
          data.indexField &&
            (this.fieldsForm[data.indexField].classComponent +=
              ' color-modify-qtty');
        }
        this.qttyChangeEnabled = true;
        this.cdr.detectChanges();
      });

    this._toggleContextMenu();
    this.getPreferencesStock();
  }

  ngOnDestroy(): void {
    this.configStockTradeEdit$ && this.configStockTradeEdit$.unsubscribe();
    this.getPreferencesStock$ && this.getPreferencesStock$.unsubscribe();
    this._resetSubscription && this._resetSubscription.unsubscribe;
    this._orderTokenSubscription && this._orderTokenSubscription.unsubscribe();
    this._formChangeSubscription && this._formChangeSubscription.unsubscribe();
    this.dragEvents$ && this.dragEvents$.unsubscribe();
    this.checkIsOrderOCOOrDouble$.unsubscribe();
    this.instanceForm = null;
    this.removeEventChangeAccount();
    this.stockStreamActive && this.stockStreamActive.close();
    this.inputFocus.unsubscribe();
  }

  private async componentInit() {
    this.refComponent = this.refData;
    const openStockTrade = deepClone(this.workSpaceConfigs.openStockTrade);
    if (openStockTrade) {
      this.workSpaceConfigs.openStockTrade = null;
      const order = openStockTrade.order as any;
      openStockTrade.order = order;
      if (openStockTrade.isEditMode) {
        this.enableStockTradeEditObs();
        this.configStockTradeEdit(openStockTrade);
        this.cdr.detectChanges();
        return;
      }
      this.mountComponentMetadate(openStockTrade.order, false);
    }
    this.initUserConfigs();
    this.initializeModalOpen();
    this.cdr.detectChanges();
  }

  private initializeModalOpen() {
    this.modalOpenService.onContentChange$
      .pipe(auditTime(100))
      .subscribe((open) => {
        this.isModalOpen = open;
      });
  }

  private initUserConfigs() {
    this.selectStock(this._component.metadata.component?.stock);
    const type =
      this._component.metadata.component?.type ||
      this._component.metadata.component?.bg;
    this.changeType(type, true);
    this.startEventChangeAccount();
    this.checkIsOrderOCOOrDouble$.pipe(auditTime(100)).subscribe(() => {
      this.checkIsOrderOCOOrDouble(false);
    });
    this._orderTokenSubscription = this._orderTokenService.closedModalToken
      .pipe(filter((data) => data.refComponent === this.refComponent))
      .subscribe(() => {
        this.loading = false;
        this.cdr.detectChanges();
      });
    this._quoteChannel.readEvents().then(async (data: any) => {
      data.snapshot(this.quoteParams);
      this.readStream(data.stream, this._quoteHandler);
    });
    this.inputFocus
      .pipe(
        auditTime(100),
        filter((input) => !!input)
      )
      .subscribe((input) => {
        input.setFocus();
      });
  }

  private _quoteHandler = (payload: any): void => {
    const res = payload.get(this.quoteParams[0]);
    if (!res) return;
    const data = this.clearCheetahData(res);
    this.lastPriceQuote = parseFloat(data.preco_ultimo);
    this.updateMarketValidation();
  };

  private updateMarketValidation() {
    if (this.form.value.typeOdrer == TYPE_ORDE_ENUM.MARKET) {
      this.instanceForm?.setMarketValidations(this.form, this.lastPriceQuote);
    }
  }

  selectStock = (stock: ISearchStock, isInit: boolean = false): void => {
    if (stock && (stock.cd_stock !== this.stock?.cd_stock || this.isEdit)) {
      this._formChangeSubscription &&
        this._formChangeSubscription.unsubscribe();
      this.resetTempForm();
      this.qttyChangeEnabled = false;
      this.cdSegment = stock.cd_segment ?? '';
      this.dsAsset = stock.ds_asset ?? '';
      this.stock = stock;
      this.stock?.stockVariation && (this.stock.stockVariation.vl_close = 0);
      this.incrementPrice = getIncrementValue(this.stock);
      this.setStandardLotStock();
      this.getLastPrice(+this.vlClosePrice);
      const setForm = this.form?.get('orderType')?.value ?? '0';
      !this.isEdit && this.initForm(setForm);
      this.isEdit && this.initConfigStockTradeEdit();
      (!isInit || this.isEdit) && this.updateMetadataStock(stock);
      !isInit && this.getPreferencesStock();
      this.cdr.detectChanges();
    }
  };

  formIsMarket(value: boolean): void {
    this.isMarket = value;
    this.initForm(this.isMarket ? 1 : '0');
    this.cdr.detectChanges();
  }

  private updateConfig() {
    const config = {
      standardLot: this.standardLot(),
      incrementValue: this.incrementValue,
      vlClosePrice: this.vlClosePrice,
      cdSegment: this.cdSegment,
      dsAsset: this.dsAsset,
      lastPrice: this.lastPrice(),
      cdStock: this.stock?.cd_stock ?? '',
      idBroker: this.account?.id_broker ?? '',
      fieldsForm: this.fieldsForm,
      tickSize: this.tickSize,
      form: this.form,
      isDayTrade: this._dayTradeService?.useDayTradeMode ?? false,
      vlMinPriceIncrement:
        this.stock && this.stock.vlMinPriceIncrement
          ? parseFloat(this.stock.vlMinPriceIncrement.toString())
          : undefined,
    };
    this.instanceForm?.updateConfig(config);
    this.form?.updateValueAndValidity();
  }

  private initForm = (typeOrder: string | number = '0'): void => {
    if (!this.instanceForm) return;
    this.focusQtty = false;
    this._resetSubscription.unsubscribe();
    this.qttyChangeEnabled = false;
    this.brokerSelect = this.account?.label ?? '';
    let sideOrder = this._component.metadata?.component?.type?.includes('venda')
      ? this.sideOrder.SELL
      : this.sideOrder.BUY;
    if (this.isEdit) {
      sideOrder =
        this.orderEdit.side === TYPE_ORDE_SIDE_STRING_ENUM.BUY
          ? this.sideOrder.BUY
          : this.sideOrder.SELL;
      if (
        isStartStop(this.orderEdit.cd_order_type) ||
        isDoubleStartStop(this.orderEdit.cd_order_type)
      ) {
        typeOrder = TYPE_ORDE_ENUM.START_STOP;
      } else if (
        typeOrder !== TYPE_ORDE_ENUM.SALE &&
        typeOrder !== TYPE_ORDE_ENUM.SALE_OF_CLOSE &&
        typeOrder !== TYPE_ORDE_ENUM.SALE_OF_OPEN
      ) {
        typeOrder = `${TYPE_ORDE_ENUM.LIMITADA}`;
      }
    }
    this.form?.get('price')?.setValue(this.lastPrice());
    this.stock?.stockVariation &&
      (this.stock.stockVariation.vl_close = this.lastPrice());
    this.updateConfig();
    const { form, fieldsForm } = this.instanceForm!.changeForm(
      typeOrder,
      sideOrder,
      this.form?.get('orderOco')?.value ||
        this.form?.get('orderDouble')?.value ||
        false,
      this.isReseted ? null : this.tempFormValues,
      this.isSimulator
    );
    this.fieldsForm = fieldsForm;
    this.form = form;
    this.form.updateValueAndValidity();
    this._handleDayTradeMode();
    this.form.get('qtde')?.valueChanges.subscribe((selectedValue) => {
      this.qtdeSignal.set(+selectedValue);
      if (!this.focusQtty) {
        this.inputFocus.next(this.qtde);
        this.focusQtty = true;
      }
      this.qttyChange({ value: selectedValue });
    });
    this.form.get('price')?.valueChanges.subscribe((selectedValue) => {
      this.priceSignal.set(+selectedValue);
    });
    this.form
      .get('orderOco')
      ?.valueChanges.pipe(delay(100))
      .subscribe((selectedValue) => {
        selectedValue && this.inputFocus.next(this.gainOrLossPrice);
        this.orderOcoSignal.set(selectedValue);
        this.updateMarketValidation();
      });
    this.form
      .get('orderDouble')
      ?.valueChanges.pipe(delay(100))
      .subscribe((selectedValue) => {
        selectedValue && this.inputFocus.next(this.gainOrLossPrice);
        this.orderDoubleSignal.set(selectedValue);
      });
    this.qtdeSignal.set(+this.form.get('qtde')?.value);
    this.priceSignal.set(+this.form.get('price')?.value);
    this.orderOcoSignal.set(this.form.get('orderOco')?.value ?? false);
    this.orderDoubleSignal.set(this.form.get('orderDouble')?.value ?? false);
    if (
      this.isEdit &&
      !this.form.get('typeOdrer')?.disabled &&
      this.form.get('typeOdrer')?.value
    ) {
      this.form.get('typeOdrer')?.disable();
    }
    this.form.get('typeOdrer')?.valueChanges.subscribe((value: number) => {
      !this.form.get('orderOco')?.value && this.initForm(value);
    });
    this._formChangeSubscription = this.form.valueChanges.subscribe(
      (dataForm) => {
        const keys = Object.keys(dataForm);
        keys.forEach((key) => {
          if (!isNullOrUndefined(this.tempFormValues[key])) {
            this.tempFormValues[key] = dataForm[key];
          }
        });
      }
    );
    this.getPreferencesStock();
    this.updateMarketValidation();
    this.cdr.detectChanges();
    setTimeout(() => {
      this.inputFocus.next(this.qtde);
    }, 10);
  };

  changeType(value: string, isInit = false) {
    const selectForm: string | number = this.isMarket
      ? TYPE_ORDE_ENUM.MARKET
      : `${TYPE_ORDE_ENUM.LIMITADA}`;
    this.isReseted = false;
    switch (value) {
      case STOCK_TRADES_TYPE.BUY:
        this.checkTypeOrder(selectForm);
        this._orderByBuy();
        break;
      case STOCK_TRADES_TYPE.BUY_START_STOP:
        this.isMarket = false;
        this.initForm(TYPE_ORDE_ENUM.START_STOP);
        this._orderByBuy();
        break;
      case STOCK_TRADES_TYPE.SELL:
        this.checkTypeOrder(selectForm);
        this._orderBySell();
        break;
      case STOCK_TRADES_TYPE.SELL_START_STOP:
        this.isMarket = false;
        this.initForm(TYPE_ORDE_ENUM.START_STOP);
        this._orderBySell();
        break;
    }
    this._component.metadata.component = {
      ...this._component.metadata.component,
      type: value,
    };
    !isInit && this.homeService.updateComp(this._component);
    this.checkIsOrderOCOOrDouble$.next();
    this.cdr.detectChanges();
  }

  private checkTypeOrder(selectForm: string | number): void {
    const typeOrder = this.orderEdit
      ? ORDER_TYPES.find(
          (item) => item.cod === this.orderEdit.cd_order_time_enforce
        )
      : null;
    if (typeOrder && typeOrder.type) {
      if (
        typeOrder.cod === '7' &&
        (this.orderEdit.cd_order_type === 'K' ||
          this.orderEdit.cd_order_type === '1')
      ) {
        this.initForm(TYPE_ORDE_ENUM.SALE_OF_CLOSE);
        return;
      }
      this.initForm(typeOrder.id);
    } else {
      this.initForm(selectForm);
    }
  }

  private _orderByBuy(event?: KeyboardEvent) {
    if (this.isFocus && event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.orderSideControl.setValue(this.sideOrder.BUY);
    this._component.metadata.component.bg = COLOR_BOLETA.BUY;
    this._component.name = 'Boleta Compra';
    this.propsBtnSend = {
      bg: 'bg-multibroker-buy',
      text: this.isEdit ? 'Confirmar edição' : 'Comprar',
    };
    this.cdr.detectChanges();
  }

  private _orderBySell(event?: KeyboardEvent) {
    if (this.isFocus && event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.orderSideControl.setValue(this.sideOrder.SELL);
    this._component.metadata.component.bg = COLOR_BOLETA.SELL;
    this._component.name = 'Boleta Venda';
    this.propsBtnSend = {
      bg: 'bg-multibroker-sell',
      text: this.isEdit ? 'Confirmar edição' : 'Vender',
    };
    this.cdr.detectChanges();
  }

  configStockTradeEdit = (data: IEditOrder): void => {
    const param: any = {
      refComponent: this.refComponent,
      data,
    };
    this._configStockTradeEdit$.next(param);
    this.cdr.detectChanges();
  };

  private mountComponentMetadate(order: any, isEdit: boolean = true): void {
    const typeTrade: any = TYPE_TRADE;
    const selectType = `${order.side}${order.cd_order_type || 2}`;
    !isEdit && (order.stockVariation.vl_close = order.price);
    this._component.metadata.component = {
      stock: isEdit ? null : order,
      bg: typeTrade[selectType].bg,
      type: typeTrade[selectType].type,
    };
  }

  private initConfigStockTradeEdit(): void {
    if (!isDoubleStartStop(this.orderEdit.cd_order_type)) {
      this.orderEdit.price &&
        this.form.get('price')!.setValue(this.orderEdit.price);
      isStartStop(this.orderEdit.cd_order_type) &&
        this.form.get('stopPx')!.setValue(this.orderEdit.price_stop);
      isStartStop(this.orderEdit.cd_order_type) &&
        this.form.get('typeValidade')!.disable();
    } else {
      this.form
        .get('gainPrice')!
        .setValue(this.orderEdit.personalized_gain_price);
      this.form
        .get('lossPrice')!
        .setValue(this.orderEdit.personalized_loss_price);
      this.form
        .get('gainPriceTrigger')!
        .setValue(this.orderEdit.personalized_gain_trigger);
      this.form
        .get('lossPriceTrigger')!
        .setValue(this.orderEdit.personalized_loss_trigger);
      this.form.get('orderDouble')!.setValue(true);
      this.form.get('orderType')!.setValue(TYPE_ORDE_ENUM.DOUBLE_START_STOP);
      this.form.get('typeValidade')!.disable();
      this.getLastPrice(+this.orderEdit.last_price);
    }
    this.form
      .get('qtde')!
      .setValue(this.orderEdit.qtty_left ?? this.orderEdit.qtty);
    this.getValidade();
  }

  private getValidade(): void {
    switch (this.orderEdit.ds_order_time_enforce) {
      case 'Até o dia':
      case '6': {
        this.form
          .get('typeValidade')!
          .setValue(TYPE_VALIDADE_ENUM.UNTIL_THE_DAY);
        if (this.orderEdit.dt_expirate_date) {
          const dateArray = this.orderEdit.dt_expirate_date.split('/');
          const date = new Date(
            `${dateArray[2]}-${dateArray[1]}-${dateArray[0]} 00:00`
          );
          this.form
            .get('dueDate')!
            .setValue(this.datePipe.transform(date, 'yyyy-MM-dd'));
        }
        break;
      }
      case 'Até cancelar':
      case '1':
        this.form
          .get('typeValidade')!
          .setValue(TYPE_VALIDADE_ENUM.VALID_UNTIL_CANCEL);
        break;
      case 'Imediata ou cancela':
      case '3':
        this.form
          .get('typeValidade')!
          .setValue(TYPE_VALIDADE_ENUM.EXECUTE_OR_CANCEL);
        break;
      case 'Tudo ou Nada':
      case '4':
        this.form
          .get('typeValidade')!
          .setValue(TYPE_VALIDADE_ENUM.EVERYTHING_OR_NOTHING);
        break;
    }
  }

  private updateMetadataStock = (stock: ISearchStock) => {
    if (
      this._component.metadata.component &&
      this._component.metadata.component.stock === stock
    ) {
      return;
    }
    if (this.account) this.updateMetadata(stock);
  };

  private updateMetadata(stock: ISearchStock): void {
    this._component.metadata.component = Object.assign(
      this._component.metadata.component,
      { stock }
    );
    this.homeService.updateMeta<ISearchStock>(this._component);
  }

  sendOrder(): void {
    if (isTypeStockIndex(this.stock.type)) {
      this.stockTradeService.showToast(
        'Não é possível enviar ordens para índices.'
      );
      return;
    }
    if (this.form.invalid) return;
    this.loading = true;
    this._getLoadingEvent();
    const sendOrder = new SendOrder(
      this.workSpaceConfigs,
      this._orsGatewayServiceV2,
      this.stockTradeService
    );
    this.originAnalysisOrderService.setOriginOrder(
      ORDER_PARAM_HELPER.STOCK_TRADE_BUTTON
    );
    if (this.isEdit) {
      sendOrder.modificationOrder(
        this.form,
        this.orderEdit,
        this._component.id
      );
      return;
    }
    sendOrder.createdOrderByForm(
      this.form,
      this.stock,
      this.account,
      this.refComponent
    );
  }

  public getLastPrice(value: number): void {
    !this.stock.stockVariation.vl_close &&
      (this.stock.stockVariation.vl_close = +value);
    this.lastPrice.set(+value);
    this.updateConfig();
    this.cdr.detectChanges();
  }

  getMinPriceIncrement(value: string): void {
    const min_price_increment = parseFloat(value);
    this.minPriceIncrement = min_price_increment;
  }

  getStandardLot(value: string): void {
    if (!this.fieldsForm || isNullOrUndefined(value)) return;
    const stardard_lot = parseInt(value);
    this.incrementValue = this.stock.standard_lot = stardard_lot;
    const indexField = this.fieldsForm.findIndex(
      (field: any) => field.key === 'qtde'
    );
    this.fieldsForm[indexField].incrementValue = this.incrementValue;
    this.updateConfig();
  }

  updatePrice(field: string, type: string) {
    const formControl = this.form.get(field);
    if (formControl) {
      const value = this.minPriceIncrement || 1;
      if (type === 'PLUS') {
        const plus = parseFloat(formControl.value) + value;
        formControl.patchValue(plus.toFixed(2));
        return;
      }
      const minus = formControl.value - value;
      formControl.patchValue(minus < 0 ? 0 : minus.toFixed(2));
    }
  }

  checkIsOrderOCOOrDouble(changeInputValue: boolean = true): void {
    if (this.isEdit) return;
    let value = false;
    if (this.form.get('orderOco')) {
      value = changeInputValue
        ? !this.form.get('orderOco')!.value
        : !!this.form.get('orderOco')!.value;
      this.form.get('orderOco')!.setValue(value);
    } else if (this.form.get('orderDouble')) {
      value = changeInputValue
        ? !this.form.get('orderDouble')!.value
        : !!this.form.get('orderDouble')!.value;
      this.form.get('orderDouble')!.setValue(value);
      this.form
        .get('orderType')
        ?.setValue(
          value ? TYPE_ORDE_ENUM.DOUBLE_START_STOP : TYPE_ORDE_ENUM.START_STOP
        );
      this.orderDoubleSignal.set(value);
    }
    this.form.get('typeValidade')?.setValue(`${TYPE_VALIDADE_ENUM.TODAY}`);
    /*if (value || this.form.get('is_day_trade')?.value) {
      this.form.get('typeValidade')!.disable();
    } else {
      this.form.get('typeValidade')!.enable();
    }*/
    if (
      this.form.get('typeOdrer')?.value ||
      this.form.get('typeOdrer')?.value === 0
    ) {
      value && this.form.get('typeOdrer')!.disable();
      !value && this.form.get('typeOdrer')!.enable();
    }
  }

  private _handleDayTradeMode(): void {
    this._dayTradeService.dayTradeMode.subscribe((useDayTradeMode) => {
      this.updateConfig();
      this.form.get('is_day_trade')?.setValue(useDayTradeMode);
    });
  }

  private startEventChangeAccount(): void {
    this.onAccountChange$ = this._multibrokerService
      .onUpdateSelectedAccountChannel()
      .subscribe((account) => {
        this.handlerAccount(account);
      });
  }

  private handlerAccount = (detail: any): void => {
    if (this.isEdit || !detail) return;
    this.accountSelect(detail);
    if (isSimulatorOrConsolidated(+detail?.id_broker)) {
      this.brokerBalance = this.formatFinancial(0);
    } else {
      this._balanceService
        .getBalance(detail.id_broker)
        .subscribe(
          (res) => (this.brokerBalance = this.formatFinancial(res || 0))
        );
    }
    this.checkAccountIsSimulator(+detail?.id_broker);
  };

  private checkAccountIsSimulator(idBroker: number): void {
    if (isIdBrokerSimulator(idBroker) !== this.isSimulator) {
      this.isSimulator = isIdBrokerSimulator(idBroker);
      let setForm = this.form?.get('orderType')?.value ?? '0';
      if (
        setForm === TYPE_ORDE_ENUM.SALE ||
        setForm === TYPE_ORDE_ENUM.SALE_OF_OPEN ||
        setForm === TYPE_ORDE_ENUM.SALE_OF_CLOSE
      ) {
        setForm = '0';
      }
      this.initForm(setForm);
    }
  }

  accountSelect = (data: IAccountSelect) => {
    if (this.isEdit) return;
    this.account = data;
    const setForm = this.form?.get('orderType')?.value ?? '0';
    this.initForm(setForm);
    this.cdr.detectChanges();
  };

  private removeEventChangeAccount(): void {
    this.onAccountChange$.unsubscribe();
  }

  selectStockByCdStock(cdStock: string) {
    this.searchStock.selectStockByCdStock(cdStock);
  }

  private _toggleContextMenu = (): void => {
    this._renderer.listen(
      this.stockTradeRef.nativeElement,
      'contextmenu',
      (event: MouseEvent) => {
        event.preventDefault();
        const tableBookConfig = new StockTradeContextMenuConfig(
          this.createComponent
        );
        tableBookConfig.showConfig({
          event: event,
          show: true,
          tourCallback: this._startTour,
          isTour: false,
        });
      }
    );
  };

  private _startTour = (): void => {
    this._verifyStockTradeTypeBeforeTour();
    this._introJsService.stocktrade(this._component.id);
    this._tourSubscription = this._introJsService
      .onStart()
      .subscribe((res: { action: string }) => {
        if (res.action === 'TOGGLE_TYPE') {
          const newType = this._switchStockTradeType(this._typeDuringTour);
          this._typeDuringTour = newType;
          this.changeType(newType);
        }
        if (
          res.action === 'CLOSED' &&
          this._component.metadata.component.type !== this._typeBeforeTour
        ) {
          this._typeDuringTour = this._typeBeforeTour;
          this.changeType(this._typeBeforeTour);
          this._tourSubscription.unsubscribe();
        }
        this.cdr.detectChanges();
      });
  };

  private _verifyStockTradeTypeBeforeTour(): void {
    this._typeBeforeTour = this._component.metadata.component.type;
    this._typeDuringTour = this._component.metadata.component.type;
    if (
      ![STOCK_TRADES_TYPE.BUY, STOCK_TRADES_TYPE.SELL].includes(
        this._typeDuringTour
      )
    ) {
      const newType = this._switchStockTradeType(this._typeBeforeTour);
      this._typeDuringTour = newType;
      this.changeType(newType);
    }
    this.cdr.detectChanges();
  }

  private _switchStockTradeType(type: string): STOCK_TRADES_TYPE {
    switch (type) {
      case STOCK_TRADES_TYPE.BUY:
        return STOCK_TRADES_TYPE.BUY_START_STOP;
      case STOCK_TRADES_TYPE.SELL:
        return STOCK_TRADES_TYPE.SELL_START_STOP;
      case STOCK_TRADES_TYPE.BUY_START_STOP:
        return STOCK_TRADES_TYPE.BUY;
      case STOCK_TRADES_TYPE.SELL_START_STOP:
        return STOCK_TRADES_TYPE.SELL;
      default:
        return STOCK_TRADES_TYPE.BUY;
    }
  }

  private getPreferencesStock(): void {
    this._getPreferencesStock$.next({
      refComponent: this.refComponent,
    });
  }

  private qttyChange = (event: any): void => {
    if (!this.qttyChangeEnabled || isNullOrUndefined(event.value)) return;
    const qtd = event.value;
    this._stockPreferencesService.setStockQtty(
      this.stock.cd_stock_order,
      qtd,
      this.standardLot()
    );
  };

  private resetTempForm() {
    const keys = Object.keys(this.tempFormValues);
    keys.forEach((key) => {
      if (!isNullOrUndefined(this.tempFormValues[key])) {
        this.tempFormValues[key] = 0;
      }
    });
  }

  private formatFinancial(value: number) {
    const price = +value;
    return `R$ ${formatterNumber(price)}`;
  }

  private _getLoadingEvent = () => {
    this._resetSubscription = this.stockTradeService
      .onLoading()
      .subscribe((data) => {
        this.loading = data.loading;
        if (data.resetForm) {
          this.resetTempForm();
          this.isReseted = true;
          const setForm = this.form?.get('orderType')?.value ?? '0';
          this.initForm(setForm);
        } else {
          this._resetSubscription.unsubscribe();
        }
        this.cdr.detectChanges();
      });
  };

  private setStandardLotStock() {
    this.standardLot.set(this.stock?.standard_lot ?? 100);
  }

  private _orderToEditIsSameStockThanGlobalStock(
    orderToEdit: Partial<IOrders>
  ) {
    const globalStock =
      this.globalSelectedStockSubscription.getGlobalStockSelected();
    if (globalStock.cd_stock_order === orderToEdit.cd_stock_order) {
      return of(globalStock);
    } else {
      return this.stockService.searchStockByStock(orderToEdit.cd_stock_order!);
    }
  }

  private _setOrderDataToEdit(data: IOrderToEditInfos): void {
    if (!data.order) return;
    this._orderToEditIsSameStockThanGlobalStock(data.order).subscribe(
      (stock) => {
        this._globalStock = stock;
        const order = data.order!!;
        this.isEdit = data.isEditMode;
        this.stock = {
          ...this._globalStock,
          cd_segment: order.cd_segment ?? this._globalStock.cd_segment,
          ds_asset: this._globalStock.ds_asset,
          cd_stock: order.cd_stock!,
          id_exchange: order.id_exchange,
          tick_size_denominator: order.tick_size_denominator,
          standard_lot:
            isNullOrUndefined(order.standard_lot) ||
            isNaN(parseFloat(order.standard_lot!.toString()))
              ? this._globalStock.standard_lot
              : order.standard_lot,
          vlMinPriceIncrement:
            order.min_price_increment ?? this._globalStock.vlMinPriceIncrement,
        } as ISearchStock;
        this.account = {
          id_broker: order.id_broker!,
          account_number: order.account,
        } as IAccountSelect;
        this.setStandardLotStock();
        this.incrementPrice = getIncrementValue(this.stock);
        this.orderEdit = { ...this.orderEdit, ...data.order };
        this.updateValueStops();
        this.mountComponentMetadate(this.orderEdit);
        this.changeType(this._component.metadata.component.type, true);
        this.initConfigStockTradeEdit();
        this.cdr.detectChanges();
      }
    );
  }

  private updateValueStops() {
    this.hashStops['stopPx'] = this.orderEdit.price_stop;
    this.hashStops['gainPrice'] = this.orderEdit.personalized_gain_price;
    this.hashStops['gainPriceTrigger'] =
      this.orderEdit.personalized_gain_trigger;
    this.hashStops['lossPrice'] = this.orderEdit.personalized_loss_price;
    this.hashStops['lossPriceTrigger'] =
      this.orderEdit.personalized_loss_trigger;
  }

  eventSearchStock(isOpen: boolean) {
    this.isSearchStockOpen = isOpen;
  }
}
