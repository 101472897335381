import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ConfigService } from '@core/config';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styles: [
    `
      :host(app-logo) {
        display: contents;
        .width-broker {
          width: 100px;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  @Input() width: number = 120;
  @Input() height: number = 13;
  @Input() css: string = '';
  @Input() withIcon: boolean = false;
  isCorretora = false;
  viewBox: string = '64 0 374 71';
  constructor(private configService: ConfigService) {
    this.isCorretora = this.configService.isBroker();
  }
}
