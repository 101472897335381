<div class="w-100">
  <div class="w-100 opt-border-top bg-neutral-stronger" *ngIf="strategyOptions && strategyOptions.length > 0">
    <div class="w-100 d-flex justify-content-center">
      <div class="minimize-wrapper bg-neutral-stronger d-flex justify-content-center">
        <a type="button" class="nav-link p-0 m-0" (click)="toggleVisibility()" [tooltip]="visibility.label">
          <i class="icons icon-size-micro">{{ visibility.icon }} </i>
        </a>
      </div>
    </div>
    <div *ngIf="visibility.enable" #dragLine class="w-100 top-dragger" cdkDragLockAxis="y"
      cdkDragBoundary=".drag-boundary" cdkDrag [cdkDragFreeDragPosition]="dragPosition"
      [ngClass]="{ dragging: isDragging }" (cdkDragEnded)="onDragEnd($event)" (cdkDragMoved)="onDragMoved($event)"
      (cdkDragStarted)="onDragStarted()"></div>
    <div class="w-100 p-2 pt-0 d-flex flex-column overflow-hidden" [ngClass]="{ 'p-2': visibility.enable }"
      [style.height.px]="height">
      <div class="w-100 d-flex justify-content-end">
        <span class="fs-5 strategy-title">
          {{ stockSelected()?.cd_stock }} {{ strategyExpirationDate }}
        </span>

        <a type="button" class="nav-link py-0 m-0 pl-2 mb-1" (click)="toggleFullscreenMode()"
          [tooltip]="fullscreen.label">
          <i class="icons icon-size-micro">{{ fullscreen.icon }} </i>
        </a>
      </div>
      <div class="d-flex flex-wrap w-100 h-100 overflow-auto">
        <div class="col-8 pr-3">
          <h5 class="align-text-top pb-2 pt-0">
            Operação
            <fla-icon css="cursor-pointer text-light clear-all-icon" size="icon-size-micro" name="close"
              [tooltip]="'Excluir todas as operações'" (click)="clearStrategy()"></fla-icon>
            <span class="float-right">{{ strategyName() }}</span>
          </h5>
          <table class="w-100 text-center">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col" class="fs-6">Lado</th>
                <th scope="col" class="fs-6">Tipo</th>
                <th scope="col" class="fs-6">Strike</th>
                <th scope="col" class="fs-6">Opção</th>
                <th scope="col" class="fs-6">Qtd.</th>
                <th scope="col" class="fs-6">Preço</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let option of strategyOptions">
                <tr class="align-middle strategy-table-row">
                  <td class="table-action-cell">
                    <fla-icon css="cursor-pointer text-light" size="icon-size-micro"
                      [name]="option.expanded ? 'expand_less' : 'expand_more'"
                      (click)="option.expanded = !option.expanded"></fla-icon>
                  </td>
                  <td scope="row">
                    <span class="side-box py-1 px-2 fw-bold" [class]="
                        option.side === 'B'
                          ? 'bg-multibroker-buy'
                          : 'bg-multibroker-sell text-black'
                      ">{{ OPTIONS_SIDE_MAPPING[option.side] }}</span>
                  </td>
                  <td class="fs-6">
                    {{ typeNames[option.option_type] }}
                  </td>
                  <td class="fs-6">
                    {{ option.strike_price | number : '0.2-2' }}
                  </td>
                  <td class="fs-6">
                    {{ option.cd_stock }}
                  </td>
                  <td class="fs-6">
                    <app-input-count labelPlacement="horizontal" margin="mb-0" css="fs-7 qtty-input"
                      paddingSmallCustom="py-1" mask="99999999" [isSmall]="true" [allowNegativeValue]="false"
                      [incrementValue]="standardLot()" [min]="standardLot()" [selectOnFocus]="true"
                      [initialValue]="option.qtty_lot || 0" (rtChange)="editStrategy(option, $event)"></app-input-count>
                  </td>
                  <td class="fs-6">
                    {{ option.price | number : '0.2-2' }}
                  </td>
                  <td class="table-action-cell">
                    <fla-icon css="cursor-pointer text-light" size="icon-size-micro" name="close"
                      (click)="deleteStrategy(option)" tooltip="Excluir Operação"></fla-icon>
                  </td>
                </tr>
                <tr *ngIf="option.expanded">
                  <td colspan="8">
                    <app-options-strategy-book [refId]="refId" [cdStockOption]="option.cd_stock"
                      [stock]="stockSelected()"></app-options-strategy-book>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>

        <div class="col-8 pl-3 d-flex flex-column">
          <div class="d-flex justify-content-between w-100 pr-3 pt-1">
            <div class="d-flex flex-column fs-6 text-nowrap mb-2 ml-3">
              <span class="text-nowrap">
                Dias Vencimento: {{ daysToExpire }}
              </span>
              <span>Montagem: {{ vlCost | number : '0.2-2' }}</span>
            </div>
            <div class="d-flex flex-column fs-6 text-nowrap mr-3">
              <span>Lucro Max: {{ maxProffit | number : '0.2-2' }}</span>
              <span>Perda Max: {{ maxLoss | number : '0.2-2' }}</span>
            </div>
          </div>
          <div class="w-100 h-100 mb-1">
            <app-options-strategy-graph #graph [stock]="stockSelected()" [refId]="refId"
              [SimpleChartSeries]="SimpleChartSeries"
              [SimulationChartSeries]="SimulationChartSeries"></app-options-strategy-graph>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-end align-items-end">
          <rocket-button css="bg-multibroker-buy mt-3" label="Confirmar" tooltip="Confirmar opção" [isSmall]="true"
            [loading]="loading" (rocketClick)="confirm()"></rocket-button>
        </div>
      </div>
    </div>
  </div>
</div>