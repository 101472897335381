import { TIGER_INDICATORS_MENU_ENUM } from '../../enum/tiger-chart.enum';
import MenuItemBase from './menu-item-base';

export default class FixInScaleFullscreen extends MenuItemBase {
  constructor() {
    super();
    this.label = 'Não fixar (fullscreen)';
    this.id = TIGER_INDICATORS_MENU_ENUM.SCALE_NO_SCALE;
  }

  override onCLick(): void {}
}
