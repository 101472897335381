<div class="h-100 w-100 d-flex justify-content-center align-items-center">
  <ng-container *ngIf="!text; else anotherText">
    <span class="fw-bold">Ativo Vencido.</span>
    <div class="p-1 d-flex align-items-center">
      <span class="pr-1">
        Acesse o atual:
        <span
          class="text-decoration-underline cursor-pointer"
          (click)="goToStock()"
          >{{ stock.ds_asset }}{{ stock.cd_security_type }}</span
        >
      </span>
    </div>
  </ng-container>
</div>
<ng-template #anotherText>
  <div [innerHTML]="text"></div>
</ng-template>
