import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { SearchStockModule } from '../search-stock/search-stock.module';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { BusinessProfileComponent } from './business-profile.component';
import { BusinessProfileHeaderComponent } from './business-profile-header/business-profile-header.component';
import {
  FlaCardModule,
  FlaIconModule,
  FlaTabModule,
  FlaTagModule,
  RocketButtonModule,
  RocketModalModule,
} from '@shared/rocket-components/components';
import { ResumeComponent } from './resume/resume.component';
import { ResumeListComponent } from './resume/resume-list/resume-list.component';
import { DocumentsModalModule } from '@shared/documents-modal/documents-modal.module';
import { PairsComponent } from './pairs/pairs.component';
import { ModalSectorSubsectorSegmentComponent } from './pairs/modal-sector-subsector-segment/modal-sector-subsector-segment.component';
import { ProventsComponent } from './provents/provents.component';
import { LoadingModule } from '../loading/loading.module';
import { FundsTableModule } from '../rent/components/funds-table/funds-table.module';
import { RentChartModule } from '../rent/components/rent-chart/rent-chart.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NoInfoModule } from './no-info/no-info.module';
import { AboutComponent } from './about/about.component';
import { StockInfoComponent } from './about/stock-info/stock-info.component';
import { ExecutiveBoardComponent } from './about/executive-board/executive-board.component';
import { AcionistasSubsidiariaComponent } from './about/acionistas-subsidiaria/acionistas-subsidiaria.component';
import { ModalInfoComponent } from './modal-info/modal-info.component';
import { ConsensusComponent } from './consensus/consensus.component';
import { RecomendationBarsComponent } from './consensus/recomendation-bars/recomendation-bars.component';
import { RepurchaseComponent } from './repurchase/repurchase.component';
import { RepurchaseCardComponent } from './repurchase/parts/repurchase-card/repurchase-card.component';
import { FlaRemoveHostModule } from '@shared/rocket-components/directives/remove-host';
import { FlaTableModule } from '@shared/rocket-components/table';
import { IndicatorsComponent } from './indicators/indicators.component';
import { ExportDataModule } from '../export-data/export-data.module';
import { NewsListComponent } from './resume/news-list/news-list.component';
import { StockIndexesComponent } from './about/stock-indexes/stock-indexes.component';
import { AboutIndexComponent } from './about-index/about-index.component';
import { DistributionComponent } from './resume/distribution/distribution.component';
import { HeatmapChartModule } from '../heatmap-chart/heatmap-chart.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlaWorkSpaceModule,
    SearchStockModule,
    RTDropdownModule,
    TooltipsModule,
    FlaTabModule,
    FlaIconModule,
    FlaCardModule,
    FlaTagModule,
    DocumentsModalModule,
    RocketModalModule,
    TooltipsModule,
    LoadingModule,
    FundsTableModule,
    RentChartModule,
    ScrollingModule,
    NoInfoModule,
    RocketButtonModule,
    FlaRemoveHostModule,
    FlaTableModule,
    ExportDataModule,
    HeatmapChartModule,
  ],
  declarations: [
    BusinessProfileComponent,
    BusinessProfileHeaderComponent,
    ResumeComponent,
    ResumeListComponent,
    PairsComponent,
    ModalSectorSubsectorSegmentComponent,
    ProventsComponent,
    AboutComponent,
    StockInfoComponent,
    ExecutiveBoardComponent,
    AcionistasSubsidiariaComponent,
    ModalInfoComponent,
    ConsensusComponent,
    RecomendationBarsComponent,
    RepurchaseComponent,
    RepurchaseCardComponent,
    IndicatorsComponent,
    NewsListComponent,
    StockIndexesComponent,
    AboutIndexComponent,
    DistributionComponent,
  ],
  exports: [BusinessProfileComponent, BusinessProfileHeaderComponent],
})
export class BusinessProfileModule {}
