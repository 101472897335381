import {
  Component,
  Input,
  ElementRef,
  AfterViewInit,
  OnChanges,
  ChangeDetectionStrategy,
  SimpleChanges,
} from '@angular/core';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import { IProfitBarData } from '../../interface/profit-bar-data.interface';

@Component({
  selector: 'app-profit-bar-chart[data]',
  template: '<canvas></canvas>',
  styleUrls: ['./profit-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfitBarChartComponent implements AfterViewInit, OnChanges {
  @Input() data!: IProfitBarData;
  @Input() small: boolean = false;
  @Input() width!: number;
  private componentHeight: number = 12;
  private chartWidth!: number;
  private ctx!: CanvasRenderingContext2D;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    const canvas = this.el.nativeElement.querySelector('canvas');
    this.ctx = canvas.getContext('2d');
    this.makeChartSize();
    this.draw();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { width, data } = changes;
    if (data && data.currentValue) {
      this.draw();
    }

    if (!width?.isFirstChange() && width?.currentValue) {
      this.makeChartSize();
      this.draw();
    }
  }

  private draw() {
    if (!this.ctx || !this.data) return;
    if (!this.chartWidth) this.makeChartSize();
    const width = this.chartWidth;
    const height = this.componentHeight;

    const lossValue = parseFloat(this.data.loss.toString());
    const wonValue = parseFloat(this.data.won.toString());
    const zeroValue = 100 - lossValue - wonValue;

    const lossWidth = width * (lossValue / 100);
    const wonWidth = width * (wonValue / 100);
    const zeroWidth = width - wonValue - lossValue;

    this.ctx.clearRect(0, 0, width, height);

    // Draw loss bar
    this.ctx.fillStyle = CHART_COLORS.FEEDBACK_ERROR;
    this.ctx.fillRect(0, 0, lossWidth, height);

    // Draw won bar
    this.ctx.fillStyle = CHART_COLORS.FEEDBACK_SUCCESS;
    this.ctx.fillRect(lossWidth, 0, wonWidth, height);

    // Draw zero bar
    if (zeroValue) {
      this.ctx.fillStyle = CHART_COLORS.NEUTRAL_STRONG;
      this.ctx.fillRect(lossWidth + wonValue, 0, zeroWidth, height);
    }

    // Text styling
    this.ctx.font = 'bolder 0.625rem Arial'; // Adjust font-size and font-family as required
    this.ctx.textAlign = 'center';
    this.ctx.textBaseline = 'middle';
    // Draw loss text
    const lossText = lossValue ? `${lossValue.toFixed(2)}%` : '';
    const lossTextX = lossWidth / 2;
    const lossTextY = height / 2;
    this.ctx.fillStyle = CHART_COLORS.NEUTRAL_SMOOTHER; // Choose a suitable color for the text
    this.ctx.fillText(lossText, lossTextX, lossTextY);
    // Draw won text
    const wonText = wonValue ? `${wonValue.toFixed(2)}%` : '';
    const wonTextX = lossWidth + wonWidth / 2;
    const wonTextY = height / 2;
    this.ctx.fillStyle = CHART_COLORS.NEUTRAL_STRONGER; // Choose a suitable color for the text
    this.ctx.fillText(wonText, wonTextX, wonTextY);
  }

  private makeChartSize() {
    const canvas = this.el.nativeElement.querySelector('canvas');
    this.chartWidth = this.width || this.el.nativeElement.offsetWidth;
    const height = this.componentHeight;
    canvas.width = this.chartWidth;
    canvas.height = height;
  }
}
