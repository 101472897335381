/* eslint-disable no-unexpected-multiline */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { ISearchStock, IWorkSpaceComponet } from '@core/interface';
import { Dictionary } from '@core/models';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { BehaviorSubject, Subscription, filter } from 'rxjs';
import { ISectorSubSectorSegmentStocks } from '../business-profile.interface';
import {
  ascendingSort,
  formatByTick,
  getAuctionInfos,
  isNullOrUndefined,
  newBigValueFormatter,
} from 'src/app/utils/utils.functions';
import { BusinessProfileService } from '../business-profile.service';
import {
  FLA_GRAPH_COLORS,
  formatterNumber,
  randomId,
} from '@shared/rocket-components/utils';
import { ScichartService } from '@shared/tiger-chart/services/scichart.service';
import { XyDataSeries } from 'scichart/Charting/Model/XyDataSeries';
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { NumericAxis } from 'scichart/Charting/Visuals/Axis/NumericAxis';
import { FastLineRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastLineRenderableSeries';
import { StockChartService } from '@shared/components/stock-chart/service/stock-chart.service';
import { StockService } from '@shared/services/api/trademap/v1/stock.service';
import { PERIODS_ENUM } from '../business-profile.enum';
import {
  CONFIGURATION_SECTORS,
  PERIODS,
  VISUALIZATION_IOSMA,
} from '../business-profile.const';
import { MarketSectorsService } from '@shared/services/api/trademap/v1/market-sectors.service';
import { WorkSpaceConfigs } from '@core/workspace';
import { CategoryAxis } from 'scichart/Charting/Visuals/Axis/CategoryAxis';
import { DateTimeNumericAxis } from 'scichart/Charting/Visuals/Axis/DateTimeNumericAxis';
import { ReadStreamBase } from '@shared/channel/base/read-stream-base';
import { isAuction } from '@shared/constants/general.contant';
import { IRowData } from '@shared/components/stock-table/interfaces/stock-table.interfaces';

@Component({
  selector: 'app-pairs',
  templateUrl: './pairs.component.html',
  styleUrls: ['./pairs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PairsComponent
  extends ReadStreamBase
  implements OnDestroy, AfterViewInit
{
  @Input() component!: IWorkSpaceComponet;
  @Input() refComponent!: string;
  @Input() set stockSelected(stockSelected: ISearchStock) {
    if (stockSelected) {
      this.started$ && this.started$.unsubscribe();
      this._stockSelected = stockSelected;
      this.loading = true;
      this.loadingPairs = true;
      this.newInfos();
    }
  }
  loading = true;
  loadingPairs = true;
  _stockSelected!: ISearchStock;
  private quoteParams!: SubscribeParam;
  private workspaceConfigs$!: Subscription;
  public refChart: string = randomId('COMPARATOR_PAIRS_CHART');
  companyInfo: any = {};
  itemsSubscribed = new Dictionary<any>();
  private baseChart!: TWebAssemblyChart;
  private xAxis!: CategoryAxis | DateTimeNumericAxis;
  private yAxis!: NumericAxis;
  private xValues: number[] = [];
  private _scichartService!: ScichartService;
  private start$ = new BehaviorSubject<boolean>(false);
  private started$!: Subscription;
  periods = PERIODS;
  periodSelected = PERIODS_ENUM.ONE_YEAR;
  selectedSect = 'sector';
  configuration: any = CONFIGURATION_SECTORS;
  visualizations = VISUALIZATION_IOSMA;
  selectedVisualization = VISUALIZATION_IOSMA[0];
  height = 400;
  public stockAuctionInfos: any = {};
  private _legendVisibility: any = {};
  private _quoteSnapshot!: (stocks: string[]) => void;
  private _callSnapshot: boolean = false;

  constructor(
    private marketSectorsService: MarketSectorsService,
    private stockService: StockService,
    private businessProfileService: BusinessProfileService,
    private _quoteChannel: QuoteChannel,
    private cdr: ChangeDetectorRef,
    private stockChartService: StockChartService,
    private workspaceConfigs: WorkSpaceConfigs
  ) {
    super();
    this._scichartService = new ScichartService(undefined, stockChartService);
    this._quoteChannel.readEvents().then(async (data) => {
      this._quoteSnapshot = data.snapshot;
      if (this._callSnapshot) data.snapshot(this.itemsSubscribed.keys());
      this.readStream(data.stream, this._quoteHandler);
    });
  }

  ngOnDestroy() {
    this.itemsSubscribed.clear();
    this._destroyQuote();
    this.started$ && this.started$.unsubscribe();
    this.workspaceConfigs$ && this.workspaceConfigs$.unsubscribe();
  }

  ngAfterViewInit() {
    this.initPairs();
  }

  initPairs() {
    this.workspaceConfigs$ = this.workspaceConfigs.sizeChanges
      .pipe(filter((data) => data.element.id === this.component?.id))
      .subscribe((data) => {
        const element = data.element;
        const height = element.offsetHeight;
        const quantity = Math.round(height / this.height);
        const parent = document.getElementById('inside-tags')!!;
        parent.style.height = `${quantity * 48}px`;
        element.getElementsByClassName(
          'tags'
        )[0].parentElement!!.style.minHeight = `${quantity * 48}px`;
      });
    this.start$.next(true);
  }

  private _subscribeQuote() {
    this.quoteParams = {
      header: this.refComponent,
      itemsArray: this.itemsSubscribed.keys() as string[],
    };
    this._quoteChannel.subscribe(this.quoteParams);
    if (this._quoteSnapshot) {
      this._callSnapshot = false;
      this._quoteSnapshot(this.itemsSubscribed.keys() as string[]);
      return;
    }
    this._callSnapshot = true;
  }

  private _quoteHandler = (data: any) => {
    data.values().forEach((quote: IRowData) => {
      if (quote.isEndOfSnap || !this.itemsSubscribed.has(quote.item!)) return;
      const previusInfos = this.itemsSubscribed.get(quote.item);
      if (
        quote.variacao_dia &&
        quote.variacao_dia !== previusInfos.last_variation
      ) {
        quote.last_variation = quote.variacao_dia;
        quote.custom_variation = `${formatByTick(quote.variacao_dia)}%`;
      }

      if (
        quote.preco_ultimo &&
        quote.preco_ultimo !== previusInfos.last_price
      ) {
        quote.last_price = quote.preco_ultimo;
        quote.preco_ultimo_formatted = `R$ ${formatByTick(quote.preco_ultimo)}`;
      }

      if (quote.volume && quote.volume !== previusInfos.last_volume) {
        quote.last_volume = quote.volume;
        quote.volume_formatted = newBigValueFormatter(quote.volume, 2);
      }

      this.itemsSubscribed.set(quote.item, quote);
      this.cdr.detectChanges();
      if (isAuction(quote.situacao)) {
        this.stockAuctionInfos[quote.item] = getAuctionInfos(
          this.itemsSubscribed.get(quote.item),
          this.stockAuctionInfos[quote.item]
        );
        this.cdr.detectChanges();
        return;
      }

      if (this.stockAuctionInfos[quote.item])
        delete this.stockAuctionInfos[quote.item];
      this.cdr.detectChanges();
    });
  };

  private isEmpty(obj: any) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }

  private _destroyQuote(): void {
    if (!this._quoteChannel || this.isEmpty(this.companyInfo)) return;
    this.quoteParams && this._quoteChannel.unsubscribe(this.quoteParams);
  }

  private getCompanySectorCompetitor() {
    const id =
      this.companyInfo.market_position[
        this.configuration[this.selectedSect].id
      ];
    const method = this.configuration[this.selectedSect].method;
    (this.marketSectorsService as any)
      [method](id)
      .then((info: any) => {
        const response = info.data;
        if (response.success) {
          const stocks = response.result
            .stocks as ISectorSubSectorSegmentStocks[];
          const orderedStocks = stocks.sort((a, b) =>
            ascendingSort(a.volume, b.volume)
          );
          const indexStock = orderedStocks.findIndex(
            (s) => s.cd_stock === this._stockSelected.cd_stock
          );
          if (indexStock != -1) {
            const stock = orderedStocks[indexStock];
            orderedStocks.splice(indexStock, 1);
            orderedStocks.unshift(stock);
          }
          const slicedStocks = orderedStocks.slice(0, 24);
          slicedStocks.forEach((element: ISectorSubSectorSegmentStocks) => {
            this.itemsSubscribed.set(
              `${element.cd_stock}:${element.id_exchange}`,
              element
            );
          });
        }
      })
      .finally(() => {
        this._subscribeQuote();
        this.started$ = this.start$.subscribe((data) => {
          if (data) {
            if (!this.baseChart) {
              this.buildChart();
            } else {
              this.changeChart();
            }
          }
          this.loading = false;
          this.loadingPairs = false;
          this.cdr.detectChanges();
        });
      });
  }

  private getCompanyInfoByTypeStock() {
    this.businessProfileService
      .getCompanyInfoByTypeStock(this._stockSelected)
      .then((data) => {
        this.companyInfo = data;
        this.getCompanySectorCompetitor();
        this.cdr.detectChanges();
      })
      .catch(() => {
        this.loading = false;
        this.loadingPairs = false;
        this.companyInfo = {};
        this.cdr.detectChanges();
      });
  }

  private getStockIndicatorsChart() {
    return this.stockService
      .getStockIndicatorsChart(
        this.itemsSubscribed.keys().slice(0, 15).join(),
        this.periodSelected
      )
      .then((data: any) => {
        const result = data.data.result.response;
        result[0].data.forEach((element: any) => this.xValues.push(element.x));
        return result;
      });
  }

  private buildChart() {
    this.xValues = [];
    this._scichartService.initSciChart(this.refChart).then((baseChart) => {
      this.baseChart = baseChart;
      this.getStockIndicatorsChart().then((data: any) => {
        const result = data;
        if (result && result.length) {
          this.baseChart.sciChartSurface.chartModifiers.add(
            this.businessProfileService.buildTooltip(
              150,
              120,
              `pairs-tooltip-div-id_${this.refChart}`
            )
          );
          this.yAxis = this.businessProfileService.buildChartYAxis(
            this.baseChart,
            (value: number) => {
              return formatterNumber(value) + '%';
            }
          );
          this.baseChart.sciChartSurface.yAxes.add(this.yAxis);
          this.baseChart.sciChartSurface.chartModifiers.add(
            this.businessProfileService.buildLegend(
              `pairs-legend-div-id_${this.refChart}`,
              (series, isChecked) => {
                this._legendVisibility[series.dataSeries.dataSeriesName] =
                  isChecked;
              }
            )
          );
          this.xAxis = this.businessProfileService.buildChartXAxis(
            this.baseChart,
            'CATEGORY'
          );
          this.baseChart.sciChartSurface.xAxes.add(this.xAxis);
          this.addInfoChart(result);
          this.stockChartService.removeChartLoading$.next({
            remove: true,
            ref: this.refChart,
          });
          this.cdr.detectChanges();
        }
      });
    });
  }

  private addInfoChart(result: any[]) {
    const hashInfo: any = {};
    result.forEach(
      (info: any) => (hashInfo[info.name] = info.data.map((d: any) => d.y))
    );
    result.forEach((info: any, i: number) => {
      const tmColor = FLA_GRAPH_COLORS['theme-trademap'][i];
      const randomColor =
        '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');
      const color = tmColor ? tmColor : randomColor;
      let isVisible = false;
      if (!isNullOrUndefined(this._legendVisibility[info.name]))
        isVisible = this._legendVisibility[info.name];
      else if (i < 5) isVisible = true;
      this.buildInfoChart(hashInfo[info.name], info.name, color, isVisible);
    });
  }

  private buildInfoChart(
    yValues: number[],
    dataSeriesName: string,
    stroke: string,
    isVisible: boolean
  ) {
    const line = new FastLineRenderableSeries(this.baseChart.wasmContext, {
      dataSeries: new XyDataSeries(this.baseChart.wasmContext, {
        xValues: this.xValues,
        yValues,
        dataSeriesName,
        containsNaN: false,
        isSorted: true,
      }),
      stroke,
      strokeThickness: 2,
      opacity: 1,
      isVisible,
    });
    this.baseChart.sciChartSurface.renderableSeries.add(line);
  }

  selectPeriod(period: any) {
    this.periodSelected = period.cod;
    if (this.baseChart) this.changeChart();
    else this.buildChart();
  }

  private changeChart() {
    this.xValues = [];
    this.getStockIndicatorsChart().then((data: any) => {
      this.baseChart.sciChartSurface.renderableSeries.clear();
      this.addInfoChart(data);
    });
  }

  openSectorSubSectorSegmentModal(info: any, position: string) {
    if (this._stockSelected.type !== 'VIS') return;
    if (!info) return;
    this.loading = true;
    this.loadingPairs = true;
    this.selectedSect = position;
    this.cdr.detectChanges();
    this.newInfos();
  }

  private newInfos() {
    this.itemsSubscribed.clear();
    this._destroyQuote();
    this.getCompanyInfoByTypeStock();
  }

  changeVisualization(visualization: any) {
    this.selectedVisualization = visualization;
  }
}
