import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isAdultAge, isValidDateFormat } from './functions/validateDates';

export function adultAgeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) return null;
    const isValidDate = isValidDateFormat(value);
    if (!isValidDate) return { invalidDateFormat: true };
    const isAdult = isAdultAge(value);
    return !isAdult ? { adultAge: true } : null;
  };
}
