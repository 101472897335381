import { COMPONENTS_NAMES_ENUM } from '@core/workspace/enum';
import { OptionsComponents } from '../component-list';

export const STOCK_TABLE_COMPONENT: any = (options?: OptionsComponents) => {
  return {
    name: 'Lista de ativos',
    id: COMPONENTS_NAMES_ENUM.PAPEIS,
    icon: 'list',
    cod: 'LISTA_ATIVOS',
    data: options?.data ?? null,
    close: true,
    fullscreen: true,
    link: true,
    widthDesk: 735,
    heightDesk: 268,
    unitOfMeasurement: '%',
    height: options?.height ?? 100,
    width: options?.width ?? 15,
    top: options?.top ?? '0px',
    minHeight: '150px',
    minWidth: options?.minWidth ?? '270px',
    iconSvg: '',
  };
};
