import { NgModule } from '@angular/core';
import { TokenAuthenticatorInstructionsComponent } from './token-authenticator-instructions/token-authenticator-instructions.component';
import { TokenAuthenticatorOtherAppsComponent } from './token-authenticator-instructions/token-authenticator-other-apps/token-authenticator-other-apps.component';
import { TokenAuthenticatorDisconnectComponent } from './token-authenticators-list/token-authenticators-disconnect/token-authenticator-disconnect.component';
import { TokenAuthenticatorsListComponent } from './token-authenticators-list/token-authenticators-list.component';
import { TokenAuthenticatorsPhoneComponent } from './token-authenticators-phone/token-authenticators-phone.component';
import { ModalTokenConfigComponent } from './modal-token-config.component';
import {
  FlaIconModule,
  FlaInputModule,
  RocketButtonModule,
  RocketModalModule,
} from '@shared/rocket-components/components';
import { LoadingModule } from '@shared/components/loading/loading.module';
import { ModalTokenService } from './modal-token.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { TokenAuthenticatorNitroComponent } from './token-authenticator-instructions/token-authenticator-nitro/token-authenticator-nitro.component';

@NgModule({
  imports: [
    CommonModule,
    RocketModalModule,
    FlaInputModule,
    FlaIconModule,
    FormsModule,
    LoadingModule,
    QRCodeModule,
    ReactiveFormsModule,
    RocketButtonModule,
  ],
  declarations: [
    ModalTokenConfigComponent,
    TokenAuthenticatorDisconnectComponent,
    TokenAuthenticatorInstructionsComponent,
    TokenAuthenticatorsListComponent,
    TokenAuthenticatorsPhoneComponent,
    TokenAuthenticatorOtherAppsComponent,
    TokenAuthenticatorNitroComponent,
  ],
  exports: [
    TokenAuthenticatorDisconnectComponent,
    TokenAuthenticatorInstructionsComponent,
    TokenAuthenticatorsListComponent,
    TokenAuthenticatorsPhoneComponent,
    TokenAuthenticatorOtherAppsComponent,
    TokenAuthenticatorNitroComponent,
  ],
  providers: [ModalTokenService],
})
export class ModalTokenConfigModule {}
