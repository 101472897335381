import { AuctionChannel } from '@shared/channel/auction.channel';
import { MoversChannel } from '@shared/channel/movers.channel';
import { QuoteChannel } from '@shared/channel/quote.channel';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { MOVERS_TYPE } from '../constants/stock-table.constants';
import { IStockTableIndex } from '../interfaces/movers-index.interface';
import { Subject } from 'rxjs';
import { IMoversData } from '../interfaces/stock-table.interfaces';

export class StockTableCheetah {
  typeListMovers!: string;
  private moversType = MOVERS_TYPE;
  private auctionParams!: SubscribeParam | undefined;
  private quoteParams!: SubscribeParam | undefined;
  private moversParams!: SubscribeParam | undefined;

  private readonly INFINITY_READ_STREAM: boolean = true;

  quoteStream$ = new Subject<any>();
  auctionStream$ = new Subject<any>();
  moversStream$ = new Subject<Map<string, IMoversData[]>>();

  get moversListType(): string {
    return this.typeListMovers;
  }

  constructor(
    private quoteChannel: QuoteChannel,
    private auctionChannel: AuctionChannel,
    private moversChannel: MoversChannel,
    private componentId: string,
    private refComponent: string
  ) {}

  public subscribeQuote(
    stocks: { idRow: string }[],
    isAuctionList: boolean = false
  ) {
    if (isAuctionList) return;
    const items = stocks.map((item) => item.idRow);
    this.quoteParams = {
      itemsArray: items,
      header: this.refComponent,
    };
    if (this.quoteStream$.closed) this.quoteStream$ = new Subject<any>();
    this.quoteChannel.subscribe(this.quoteParams);
    this.quoteChannel.readEvents().then((data) => {
      data.snapshot(this.quoteParams?.itemsArray);
      this.readStream(data.stream, this.quoteStream$);
    });
  }

  public unsubscribeQuote(stocks: { idRow: string }[]) {
    const items = stocks.map((item) => item.idRow);
    const quoteParams = { itemsArray: items, header: this.componentId };
    this.quoteChannel.unsubscribe(quoteParams);
  }

  public subscribeAuction(index: string) {
    this.auctionParams = {
      itemsArray: [index],
      header: this.componentId,
    };
    this.auctionChannel.subscribe(this.auctionParams);
    this.auctionChannel.readEvents().then((data) => {
      data.snapshot(this.auctionParams?.itemsArray);
      this.readStream(data.stream, this.auctionStream$);
    });
  }

  private async readStream(stream: ReadableStream, channel: Subject<any>) {
    const reader = stream.getReader();
    let ret;
    do {
      ret = await reader.read();
      if (!ret.done && !channel.closed) {
        channel.next(ret.value.data);
      }
    } while (this.INFINITY_READ_STREAM);
  }

  public unSubscribeAuction() {
    if (this.auctionParams) {
      this.auctionChannel.unsubscribe(this.auctionParams);
      this.auctionParams = undefined;
    }
  }

  public setMoversSubscriptionKey(
    type: number,
    index: IStockTableIndex = { value: 'IBOV' },
    period: string = 'one_day'
  ): void {
    const periodLabel = period ? `.${period}` : '';
    this.typeListMovers = `${this.moversType[type]}${periodLabel}.${index.value}`;
  }

  public subscribeMovers() {
    this.moversParams = {
      itemsArray: [this.typeListMovers],
      header: this.componentId,
    };
    if (this.moversStream$.closed)
      this.moversStream$ = new Subject<Map<string, IMoversData[]>>();
    this.moversChannel.subscribe(this.moversParams);
    this.moversChannel.readEvents().then(async (data) => {
      data.snapshot(this.moversParams?.itemsArray);
      this.readStream(data.stream, this.moversStream$);
    });
  }

  public unSubscribeMovers() {
    if (this.moversParams) {
      this.moversStream$.unsubscribe();
      this.moversChannel.unsubscribe(this.moversParams);
      this.moversParams = undefined;
    }
  }
}
