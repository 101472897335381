import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TIGER_KEYPRESS_ARROWS } from '../enum';

@Component({
  selector: 'app-tiger-chart-controls',
  templateUrl: './tiger-chart-controls.component.html',
  styleUrls: ['./tiger-chart-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TigerChartControlsComponent {
  controls = TIGER_KEYPRESS_ARROWS;
  @Input() width!: number;
  @Input() typeControl: string = 'ONLY_HOVER';
  @Output() control = new EventEmitter<{ control: TIGER_KEYPRESS_ARROWS }>();

  controlClick(control: TIGER_KEYPRESS_ARROWS) {
    this.control.emit({ control });
  }
}
