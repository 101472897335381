import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UpdateFieldOnboardInterface } from '@shared/services/api/trademap/onboard';
import { RECOVERY_STORAGE } from '@shared/services/api/trademap/onboard/data';
import { BrowserStorageBase } from '@shared/services/core/storage/browser-storage-base';
import { sharedSessionStorage } from '@shared/services/core/storage/shared-session.storage';
import {
  confirmPasswordValidator,
  passwordValidator,
} from '@shared/validators/password-validator';
import { Observable, filter, map, tap } from 'rxjs';

export abstract class RecoveryPasswordForm
  implements RecoveryPasswordFormInterface
{
  private storage = new BrowserStorageBase(sharedSessionStorage);
  recoveryPassForm!: FormGroup;
  erroData = {
    hasError: false,
    errorMessage: '',
  };
  errorMessages = {
    password: 'Preencha a senha corretamente',
    confirm_password: 'As senhas estão diferentes',
  };

  get password() {
    return this.recoveryPassForm.get('password')!;
  }

  get confirm_password() {
    return this.recoveryPassForm.get('confirm_password')!;
  }

  get vlDocument() {
    return this.storage.getItem(RECOVERY_STORAGE.VLDOCUMENT);
  }

  get onPasswordChanges(): Observable<UpdateFieldOnboardInterface> {
    return this.password?.valueChanges.pipe(
      map((value) => {
        return {
          field: 'password',
          value,
        };
      }),
      tap(() => this.confirm_password.updateValueAndValidity())
    );
  }

  get onConfirmPasswordChanges(): Observable<UpdateFieldOnboardInterface> {
    return this.confirm_password?.valueChanges.pipe(
      map((value) => {
        return {
          field: 'password',
          value,
        };
      }),
      filter(() => this.confirm_password?.valid ?? false)
    );
  }

  get formValues() {
    return this.recoveryPassForm.value;
  }
  get isFormValid() {
    return this.recoveryPassForm?.valid;
  }

  constructor(private fb: FormBuilder) {
    this.recoveryPassForm = this.fb.group({
      password: [
        '',
        {
          validators: [Validators.required, passwordValidator()],
        },
      ],
      confirm_password: [
        '',
        {
          validators: [
            Validators.required,
            confirmPasswordValidator('password'),
          ],
        },
      ],
    });
  }
  setFormInvalid() {
    this.recoveryPassForm.setErrors({
      customError: true,
      otherError: 'Algum erro ocorreu',
    });
  }
}

export interface RecoveryPasswordFormInterface {
  password: any;
  confirm_password: any;
  onPasswordChanges: Observable<UpdateFieldOnboardInterface>;
  onConfirmPasswordChanges: Observable<UpdateFieldOnboardInterface>;
}
