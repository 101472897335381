import {
  TigerChartIndicatorStochasticIndexOptions,
  TTigerChartIndicatorCreateOptions,
} from './indicators.types';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { StochasticIndexBase } from './base/stochastic-index-base';

export class TigerChartStochasticIndex extends StochasticIndexBase {
  constructor(options: TigerChartIndicatorStochasticIndexOptions) {
    super(options);
  }

  override create(
    options: TTigerChartIndicatorCreateOptions
  ): BaseRenderableSeries[] {
    this.updatePoints();
    return super.create(options);
  }

  override updatePoints(): void {
    this.points = this.service.call('STOCH', {
      high: this.data.vl_high,
      low: this.data.vl_low,
      close: this.data.vl_close,
      fastK_Period: this.fastK_Period,
      slowK_Period: this.slowK_Period,
      slowD_Period: this.slowD_Period,
    });
  }

  override updateSettings() {
    this.points = this.service.call('STOCH', {
      high: this.data.vl_high,
      low: this.data.vl_low,
      close: this.data.vl_close,
      fastK_Period: this.fastK_Period,
      slowK_Period: this.slowK_Period,
      slowD_Period: this.slowD_Period,
    });
    super.updateSettings();
  }
}
