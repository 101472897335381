<div #card class="card w-100 bg-neutral-strong py-2 cursor-pointer">
  <div class="card-body">
    <div class="d-flex justify-content-between">
      <div class="align-items-end" [ngClass]="{'hstack': !isCompact}">
        <div class="w-auto hstack order-first">
          <div
            class="mr-2 logo-container align-items-center d-flex justify-content-center round-sm"
            [ngClass]="{
              'bg-multibroker-buy': side === 'B',
              'bg-multibroker-sell': side === 'S'
            }"
          >
            <span class="fs-5">{{ side === 'B' ? 'C' : 'V' }}</span>
          </div>
          <div>
            <p class="card-title fs-5" [ngClass]="{'text-brand-primary': isSelected }">{{ cdStock }}</p>
            <small class="card-subtitle fw-bold">
              {{ lastPrice }} |
              <span [style.color]="variationColor"> {{ variation }}% </span>
            </small>
          </div>
        </div>
        <div class="w-auto d-flex align-items-end fw-bold" [ngClass]="{'ml-3': !isCompact, 'mt-1': isCompact}">
          <span class="card-subtitle fs-6 text-neutral-medium user-select-none">
            {{ dhCreationTime }} | 
            {{ dsOrderType }} |
            {{ dsOrderTimeEnforce }} | 
            <span [tooltip]="hasDayTrade ? 'DayTrade' : 'Swing'">{{ hasDayTrade ? 'DT' : 'ST'}}</span>
          </span>
        </div>
      </div>

      <div class="w-auto d-flex justify-content-end align-items-center">
        <div class="text-right d-flex flex-column justify-content-between h-100"
          [ngClass]="{'pt-2': isCompact }">
          <p class="card-title fs-5">
            {{ qtty | number : '1.0-0' }} x {{ price }}
          </p>
          <small class="card-subtitle fw-bold" [ngClass]="statusColor">
            {{ dsOrderStatus }}
          </small>
        </div>
        <ng-container *ngIf="showOrderOptions">
          <div class="mx-3">
            <fla-icon name="edit" css="cursor-pointer text-feedback-warning no-select" (click)="editOrder($event)"></fla-icon>
          </div>
          <div>
            <fla-icon name="close" css="cursor-pointer text-feedback-negative no-select" (click)="cancelOrder($event)"></fla-icon>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
