<div #timesAndTradesHeader>
  <workspace-doc-header
    css="w-100 header-wrapper d-flex justify-content-between"
    [fullscreenEnabled]="headerOptions.fullscreen"
    [ref]="refComponent"
    [item]="component"
  >
    <app-search-stock
      #searchStock
      [resetInput]="false"
      (selectStockChange)="selectStock($event)"
      [ref]="refComponent"
      [headerOptions]="headerOptions"
      [initStock]="stockSelected"
      [style.maxWidth]="'95px'"
    ></app-search-stock>

    <ng-container *ngIf="useDefaultHeader; else smallHeader">
      <fla-workspace-doc-header-item
        class="default-negotiation-header"
        (flaClick)="handleOpenTradeSettings.emit()"
        icon="filter_list"
        idElement="OPEN_TRADE_FILTER_SETTINGS"
        alignItem="ml-auto"
        [iconColor]="filterAndHighlightIconColor"
      ></fla-workspace-doc-header-item>
      <fla-workspace-doc-header-item
        class="default-negotiation-header"
        (flaClick)="toggleTradeByOrder()"
        [icon]="'emergency'"
        [iconColor]="
          tradeByOrderActive ? 'text-brand-primary' : 'text-neutral-medium'
        "
        idElement="TOGGLE_TRADE_BY_ORDER"
        [tooltipText]="tooltip"
      ></fla-workspace-doc-header-item>
    </ng-container>

    <ng-template #smallHeader>
      <fla-workspace-doc-header-item
        class="small-negotiation-header"
        [enableContent]="true"
        alignItem="ml-auto"
      >
        <app-dropdown menuAlign="dropdown-menu-right">
          <button
            appDropdownButton
            class="btn btn-icon fs-6 mx-2 border-right-0"
          >
            <fla-icon name="more_vert" css="text-light" size="icon-size-tiny">
            </fla-icon>
          </button>

          <li>
            <button
              type="button"
              class="btn btn-fill-primary fs-6 fw-normal gap-2 text-white text-truncate w-100 text-left"
              (click)="toggleTradeByOrder()"
            >
              <fla-icon
                size="icon-size-tiny"
                name="emergency"
                [css]="
                  tradeByOrderActive
                    ? 'text-brand-primary'
                    : 'text-neutral-medium'
                "
              >
              </fla-icon>
              {{ tradeByOrderActive ? 'Desativar' : 'Ativar' }} trades por ordem
            </button>
          </li>

          <li>
            <button
              type="button"
              class="btn btn-fill-primary fs-6 fw-normal gap-2 text-white text-truncate w-100 text-left"
              (click)="handleOpenTradeSettings.emit()"
            >
              <fla-icon
                size="icon-size-tiny"
                name="filter_list"
                [css]="filterAndHighlightIconColor"
              >
              </fla-icon>
              Abrir filtro de trades
            </button>
          </li>
        </app-dropdown>
      </fla-workspace-doc-header-item>
    </ng-template>
  </workspace-doc-header>
</div>
