import {
  TigerChartIndicatorRelativeStrengthIndexOptions,
  TTigerChartIndicatorCreateOptions,
} from './indicators.types';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import { RelativeStrengthIndexBase } from './base/relative-strength-index-base';

export class TigerChartIndicatorRelativeStrengthIndex extends RelativeStrengthIndexBase {
  constructor(options: TigerChartIndicatorRelativeStrengthIndexOptions) {
    super(options);
  }

  override create(
    options: TTigerChartIndicatorCreateOptions
  ): BaseRenderableSeries[] {
    this.updatePoints();
    return super.create(options);
  }

  override updatePoints(): void {
    this.points = this.service.call('RSI', {
      inReal: this.data[this.source],
      timePeriod: this.timePeriod,
    });
  }

  override updateSettings() {
    this.points = this.service.call('RSI', {
      inReal: this.data[this.source],
      timePeriod: this.timePeriod,
    });
    super.updateSettings();
  }
}
