import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fake-tooltip-scichart',
  templateUrl: './fake-tooltip-scichart.component.html',
  styleUrls: ['./fake-tooltip-scichart.component.scss'],
})
export class FakeTooltipScichartComponent implements OnInit {
  @Input() public seriesToShow: any[] = [];
  @Input() public tooltipX: number = 0;
  @Input() public tooltipY: number = 0;
  @Input() public tooltipWidth: number = 370;

  constructor() {}

  ngOnInit() {}
}
