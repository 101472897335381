import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
} from '@angular/core';
import {
  RocketModalRef,
  RocketModalService,
  ROCKET_MODAL_DATA,
} from '@shared/rocket-components/components/index';
import { StockListStettingsService } from '@services/api/nitro-ws/v1/stock-list-stettings.service';
import { StockListService } from '@shared/services/api/trademap/V10/stock-list.service';
import { ToastService } from '@services/toast.service';
import { DeleteList } from '../../configurations-list/delete-list';
import { UpdatesList } from '../../configurations-list/updates-list';
import {
  IAllStockListSimple,
  IListStockConfig,
  IListStockDB,
} from '@core/interface';
import { ModalAvisoComponent } from '@shared/components/modal-aviso/modal-aviso.component';
import { SearchConfig } from '../../configurations-list/search-config';
import { deepClone } from '@shared/rocket-components/utils';
import { groupBy } from 'src/app/utils/utils.functions';
import { CheckConfigList } from '../../configurations-list/check-config-list';
import { StockService } from '@shared/services/api/trademap/v1/stock.service';
import { ListStocksService } from '@shared/services/core/list-stocks/list-stocks.service';

@Component({
  selector: 'app-manage-list-modal',
  templateUrl: './manage-list-modal.component.html',
  styleUrls: ['./manage-list-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageListModalComponent extends RocketModalRef implements OnInit {
  listStocks: IAllStockListSimple[] = [];
  list: any[] = [];
  selectedList!: IAllStockListSimple;
  private hashListStocks!: Map<any, IAllStockListSimple[]>;
  private deletedActualList = false;
  private editActualList = false;
  private actualListName: string = '';
  private checkConfigList!: CheckConfigList;
  private idStockList!: number;
  private _listQty: number = 1;
  public disableDeleteList: boolean = true;

  constructor(
    @Inject(ROCKET_MODAL_DATA) public data: any,
    private _rocketModalService: RocketModalService,
    private stockListService: StockListService,
    private stockListStettingsService: StockListStettingsService,
    private toastService: ToastService,
    private listStocksService: ListStocksService,
    private _elementRef: ElementRef,
    private stockService: StockService,
    private _cdr: ChangeDetectorRef
  ) {
    super(_rocketModalService);
    this.checkConfigList = new CheckConfigList(
      this.stockListStettingsService,
      this.stockService,
      this.listStocksService
    );
  }

  ngOnInit() {
    this.listStocks = this.data.listStocks;
    this.selectedList = this.data.selectedList;
    this.hashListStocks = groupBy(
      this.listStocks,
      (list: any) => list.id_stock_list
    );
    this._configureLists(this.data.list);
  }

  private _configureLists(list: IAllStockListSimple[]): void {
    this.list = list.filter(
      (list: IAllStockListSimple) => !list.isNotListPersonal
    );
    this._listQty = this.list.length;
    this.disableDeleteList = this._listQty === 1;
    this._cdr.detectChanges();
  }

  public onClose = (goEdit: boolean = true) => {
    const list: IAllStockListSimple[] = [];
    this.hashListStocks.forEach((listS) => {
      list.push(...listS);
    });
    this.close({
      list,
      deletedActualList: this.deletedActualList,
      editActualList: this.editActualList && goEdit,
      actualListName: this.actualListName,
      idStockList: this.idStockList,
    });
  };

  public onSave = () => {
    this.updateList();
  };

  removeList(list: IListStockDB): void {
    if (this.disableDeleteList) return;
    const ref = this._rocketModalService.open(ModalAvisoComponent, {
      data: {
        title: 'Excluir lista',
        text: `Tem certeza de que deseja excluir a lista ${list.list.nm_stock_list}`,
        showButtons: true,
        confirmeButton: 'Deletar',
        isSecondModal: true,
        stock: undefined,
        showPriceStock: false,
      },
      toggleBetweenModal: this._elementRef,
      keyboard: false,
    });

    ref.afterDismissed.subscribe((data) => {
      if (data && data.value) {
        if (!list.configList) {
          const searchConfig = new SearchConfig(this.stockListStettingsService);
          searchConfig
            .getConfigListStock(list.idStockList)
            .then((config: IListStockConfig) => {
              list.configList = config;
              this.deleteList(list);
            });
        } else {
          this.deleteList(list);
        }
        this._cdr.detectChanges();
      }
    });
  }

  private deleteList(list: any) {
    if (this.disableDeleteList) return;
    const deleteList = new DeleteList(
      this.listStocksService,
      this.stockListService,
      this.stockListStettingsService
    );
    deleteList
      .delete(list.idStockList, list.configList!!.id!!)
      .then((result: boolean) => {
        if (result) {
          list.isDeleted = true;
          this.deletedActualList =
            list.idStockList === this.selectedList.id_stock_list;
          this.hashListStocks.delete(list.idStockList);
          this._listQty = --this._listQty;
          this.disableDeleteList = this._listQty === 1;
          this._cdr.detectChanges();
          return;
        }
        this.toastService.showToast(
          'error',
          'Ocorreu um problema ao remover a lista, tente novamente.'
        );
      });
  }

  startEdit(list: any) {
    if (list.idStockList === this.selectedList.id_stock_list) {
      this.editActualList = true;
      this.actualListName = list.list.nm_stock_list;
      this._cdr.detectChanges();
    }
    !list.list.oldNmStockList &&
      (list.list.oldNmStockList = deepClone(list.list.nm_stock_list));
    if (!list.stocks || !list.stocks.length) {
      this.checkConfigList
        .getStocksListItems(list, this.data.refComponent)
        .then((data) => {
          list.stocks = data.stocks;
          this._cdr.detectChanges();
        });
    }
  }

  private updateList(): void {
    const allLists = this.list.filter(
      (l) => !l.isDeleted && l.list.oldNmStockList
    );
    if (this.editActualList) {
      const actual = allLists.find((list) => {
        return list.idStockList === this.selectedList.id_stock_list;
      });
      this.actualListName = actual.list.nm_stock_list;
      this._cdr.detectChanges();
    }
    allLists.forEach((list) => {
      const listStock = this.hashListStocks.get(list.idStockList);
      if (listStock) {
        listStock[0].nm_stock_list = list.list.nm_stock_list;
      }
    });
    const updatesList = new UpdatesList(
      this.listStocksService,
      this.stockListService,
      this.toastService
    );
    updatesList.updateListsStocks(allLists);
    this.onClose();
  }

  createList() {
    this.close({
      openCreateList: true,
    });
  }
}
