export const FIELDS = [
  {
    title: 'Atingir perda financeira máxima',
    description:
      'Escolha o valor máximo que está disposto a perder no dia(P&L Dia), importante para se proteger.',
    formCheck: 'vlMaxLoss',
    formInput: 'vlMaxLossValue',
    min: 1,
    incrementValue: 1,
    minLength: 1,
    symbolPrefix: 'R$ ',
    mask: 'separator.0',
    css: 'custom-input-count',
  },
  {
    title: 'Atingir ganho financeiro máximo',
    description:
      'Escolha o valor máximo que deseja ganhar no dia(P&L Dia), importante para se controlar.',
    formCheck: 'vlMaxGain',
    formInput: 'vlMaxGainValue',
    min: 1,
    incrementValue: 1,
    minLength: 1,
    symbolPrefix: 'R$ ',
    mask: 'separator.0',
    css: 'custom-input-count',
  },
  {
    title: 'Atingir o número de ordens com prejuízo no preço médio',
    description:
      'Escolha quantas ordens abaixo do preço médio da posição quer conseguir enviar no dia.',
    formCheck: 'qttyPreventionAvgPrice',
    formInput: 'qttyPreventionAvgPriceValue',
    min: 1,
    incrementValue: 1,
    minLength: 1,
    symbolPrefix: '',
    mask: 'separator.0',
    css: 'custom-input-count',
  },
  {
    title: 'Atingir quantidade de operações perdedoras consecutivas',
    description:
      'Escolha quantas operações perdedoras(com prejuízo) consecutivas quer ter no dia.',
    formCheck: 'qttyConsecutiveLoss',
    formInput: 'qttyConsecutiveLossValue',
    min: 1,
    incrementValue: 1,
    minLength: 1,
    symbolPrefix: '',
    mask: 'separator.0',
    css: 'custom-input-count',
  },
  {
    title: 'Atingir número de operações fechadas',
    description:
      'Escolha o número máximo de operações fechadas(encerradas) que se deseja ter no dia.',
    formCheck: 'qttyMaxDayOperation',
    formInput: 'qttyMaxDayOperationValue',
    min: 1,
    incrementValue: 1,
    minLength: 1,
    symbolPrefix: '',
    mask: 'separator.0',
    css: 'custom-input-count',
  },
  {
    title: 'Ultrapassar um horário específico',
    description: 'Defina quando bloquear seu aumento de posição no dia.',
    formCheck: 'hrBlockOperation',
    formInput: 'hrBlockOperationValue',
    min: 0,
    incrementValue: 0,
    minLength: 0,
    symbolPrefix: '',
    mask: '00:00',
    css: 'custom-input-count remove-arrows',
  },
];

export const TOOLTIP_HEADER = `Sabemos que no mundo de trading as vezes é muito difícil manter o controle emocional. Para a sua própria segurança, por aqui você pode configurar restrições especificas para se proteger contra atitudes ou decisões impulsivas em suas operações de {MODO}.</br></br>
Use as restrições para se proteger das situações que achar necessário.</br></br>
Com o gerenciamento de risco ativo, você não conseguirá aumentar suas posições, se uma de suas restrições forem atingidas.`;

export const TOOLTIP_BOCK_EDIT =
  'Função Bloquear: Ao Bloquear alterações hoje, você habilita mais uma camada de sugurança para seu {MODO}.<br>Seu Gerenciamento de Risco para o {MODO} será salvo e não será mais possível mudar as restrições hoje. Caso uma das restrições seja atingida, se o bloqueio estiver ativo não será possível aumentar a sua posição alterando a restrilção, mas você ainda porderá mudar ou reduzir suas posições.<br>Você pode utilizar essa funcionalidade para evitar tomar decisões precipitadas no calor do momneto.';
