import { LayoutBase } from '../base';
import { RANKING_BROKER_COMPONENT } from '@core/workspace/constants/components/ranking-broker';
import { RENT_BTC_COMPONENT } from '@core/workspace/constants/components/rent-btc';
import { FLOW_INVESTOR_COMPONENT } from '@core/workspace/constants/components/flow-investor';
import { NEWS_COMPONENT } from '@core/workspace/constants';

export class AnaliseFluxo extends LayoutBase {
  override name = 'Análise de Ativos';
  override components = [
    RANKING_BROKER_COMPONENT({
      unit: '%',
      height: 49.5,
      width: 54,
      left: '0%',
      top: '0%'
    }),
    RENT_BTC_COMPONENT({
      unit: '%',
      height: 49.5,
      width: 45,
      left: '54.5%',
      top: '0%'
    }),
    FLOW_INVESTOR_COMPONENT({
      unit: '%',
      height: 50,
      width: 64.5,
      top: '50%',
      left: '0%',
    }),
    NEWS_COMPONENT({
      unit: '%',
      height: 50,
      width: 35,
      left: '65%',
      top: '50%'
    })
  ];
}
