import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsCardComponent {
  @Input() public cardNotice: any = {
    image_url: '',
    ds_headline: '',
  };
}
