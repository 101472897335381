import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { SIDE_TEXT_COLOR } from '@core/components/orders-history-core/constants/orders-history.const';
import { Observable, Subject, auditTime, delay, tap } from 'rxjs';
import {
  CONTEXT_MENU_ITEM_ENUM,
  CONTEXT_MENU_ORDER_BOLETA_TYPE,
  CONTEXT_MENU_ORDER_TYPE,
} from '../enum/context-menu.enum';
import { STOCK_CHART_ELEMENT_IDS } from '@shared/components/stock-chart/enum/stock-chart.enum';
import { OriginAnalysisOrderService } from '@shared/services/origin-analysis-order.service';
import { ORDER_PARAM_HELPER } from '@shared/constants/order-param-helper.const';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';
import { BaseContextMenuComponent } from '@shared/rocket-components/base-context-menu';

@Component({
  selector: 'app-tiger-chart-context-menu',
  templateUrl: './tiger-chart-context-menu.component.html',
  styleUrls: ['./tiger-chart-context-menu.component.scss'],
})
export class TigerChartContextMenuComponent
  extends BaseContextMenuComponent
  implements OnInit, OnDestroy
{
  refComponent!: string;
  isVisible!: boolean;
  fromPlus!: boolean;
  isCustodyCheepsVisible!: boolean;
  closeClickOutside: boolean = true;
  typeOrder!: CONTEXT_MENU_ORDER_TYPE;
  typeOrderBoleta!: CONTEXT_MENU_ORDER_BOLETA_TYPE;
  standardLot!: number;
  currentValue!: string;
  unformattedCurrentValue!: number;
  contextMenuEnumItem = CONTEXT_MENU_ITEM_ENUM;
  typeOrderClass!: string;
  typeAlertClass!: string;
  isTypeStockIndex!: boolean;
  hasCustody!: boolean;
  disableOrderButtons = false;
  countClick = 0;
  isTour: boolean = false;
  readonly elementsID = STOCK_CHART_ELEMENT_IDS;
  parent_id!: string;
  private emitEvent$ = new Subject<{
    action: CONTEXT_MENU_ITEM_ENUM;
    isInverse: boolean;
    typeOrder?: CONTEXT_MENU_ORDER_TYPE;
  }>();

  get onClose(): Observable<
    { value: CONTEXT_MENU_ITEM_ENUM; isInverse: boolean } | undefined
  > {
    return this._onClose$.asObservable();
  }
  get inverseTypeOrder() {
    return this.typeOrder == CONTEXT_MENU_ORDER_TYPE.BUY
      ? CONTEXT_MENU_ORDER_TYPE.SELL
      : CONTEXT_MENU_ORDER_TYPE.BUY;
  }
  get typeOrderStop() {
    return this.typeOrder == CONTEXT_MENU_ORDER_TYPE.BUY
      ? CONTEXT_MENU_ORDER_TYPE.SELL
      : CONTEXT_MENU_ORDER_TYPE.BUY;
  }
  get inverseTypeOrderStop() {
    return this.typeOrder;
  }
  get typeOrderStopClass() {
    return this.typeOrderClass == SIDE_TEXT_COLOR.S
      ? SIDE_TEXT_COLOR.B
      : SIDE_TEXT_COLOR.S;
  }
  get inverseTypeOrderStopClass() {
    return this.typeOrderClass;
  }
  get inverseTypeOrderClass() {
    return this.typeOrderClass == SIDE_TEXT_COLOR.S
      ? SIDE_TEXT_COLOR.B
      : SIDE_TEXT_COLOR.S;
  }
  private _onClose$ = new Subject<
    { value: CONTEXT_MENU_ITEM_ENUM; isInverse: boolean } | undefined
  >();
  constructor(
    elementRef: ElementRef,
    cdr: ChangeDetectorRef,
    private contextMenuService: ContextMenuService,
    private originAnalysisOrderService: OriginAnalysisOrderService
  ) {
    super(elementRef, cdr);
  }
  ngOnInit(): void {
    this.emitEvent$
      .pipe(
        auditTime(100),
        tap(({ action, isInverse, typeOrder }) => {
          this.contextMenuService.emitEvent(this.parent_id, {
            action,
            isInverse,
            typeOrder,
          });
        }),
        delay(70)
      )
      .subscribe(() => {
        this.contextMenuService.closeContextMenu(this.parent_id);
      });
    this.countClick = 0;
    this.disableOrderButtons = +this.unformattedCurrentValue <= 0;
    this.typeOrderBoleta =
      this.typeOrder === CONTEXT_MENU_ORDER_TYPE.BUY
        ? CONTEXT_MENU_ORDER_BOLETA_TYPE.BUY
        : CONTEXT_MENU_ORDER_BOLETA_TYPE.SELL;
  }

  ngOnDestroy(): void {
    this._onClose$.complete();
    this.emitEvent$.unsubscribe();
  }

  static openContextMenu(
    contextMenuService: ContextMenuService,
    parent_id: string,
    event: { clientX: number; clientY: number },
    isTour: boolean,
    isVisible: boolean,
    isVolumeVisible: boolean,
    typeOrder: CONTEXT_MENU_ORDER_TYPE,
    typeOrderClass: string,
    standardLot: number,
    currentValue: string,
    unformattedCurrentValue: number,
    typeAlertClass: string,
    hasCustody: boolean,
    isCustodyCheepsVisible: boolean,
    isTypeStockIndex: boolean,
    fromPlus: boolean
  ) {
    if (isTour) {
      const domRect = document
        .getElementsByTagName('app-stock-chart')[0]
        .getClientRects()[0];
      event.clientX = domRect.x;
      event.clientY = domRect.y + 100;
    }
    contextMenuService.openContextMenu(parent_id, {
      type: 'TigerChartContextMenuComponent',
      event: event,
      configurationData: {
        isTour,
        isVisible,
        isVolumeVisible,
        typeOrder,
        typeOrderClass,
        standardLot,
        currentValue,
        unformattedCurrentValue,
        typeAlertClass,
        hasCustody,
        isCustodyCheepsVisible,
        isTypeStockIndex,
        fromPlus,
      },
    });
  }

  private emitEvent(
    action: CONTEXT_MENU_ITEM_ENUM,
    isInverse: boolean,
    typeOrder?: CONTEXT_MENU_ORDER_TYPE
  ) {
    this.emitEvent$.next({ action, isInverse, typeOrder });
  }

  reset(): void {
    this.emitEvent(CONTEXT_MENU_ITEM_ENUM.RESET, false);
  }

  onFocusOut() {
    this.contextMenuService.closeContextMenu(this.parent_id);
  }

  onClick(value: CONTEXT_MENU_ITEM_ENUM, isInverse: boolean = false) {
    value === CONTEXT_MENU_ITEM_ENUM.HELP && this.onFocusOut();

    (value === CONTEXT_MENU_ITEM_ENUM.FAST_TYPE_ORDER ||
      value === CONTEXT_MENU_ITEM_ENUM.TYPE_STOP_ORDER) &&
      this.originAnalysisOrderService.setOriginOrder(
        ORDER_PARAM_HELPER.STOCK_CHART_RIGHT_CLICK
      );
    this.emitEvent(value, isInverse, this.typeOrder);
  }
}
