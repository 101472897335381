import { Injectable } from '@angular/core';
import {
  CHEETAH_CHANNEL,
  CUSTODIA_CHEETAH_FIELDS,
} from '@shared/cheetah/cheetah.const';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { Subject, debounceTime } from 'rxjs';
import { Dictionary } from 'src/app/core/models';
import { RxEvent, StreamReadResponse } from './rx-event';
import { MarketDispatcher } from '@shared/cheetah/dispatcher/market.dispatcher';

@Injectable({
  providedIn: 'root',
})
export class CustodyChannel {
  static instance: CustodyChannel;
  private instanciated!: boolean;
  isFirstExecution: boolean = true;
  private _channels: Dictionary<any> = new Dictionary<any>();
  private _itemsArray!: any;
  private custodyReady = new Subject<void>();

  private set channel(data: any) {
    this._channels.set(data.header, data);
  }

  private get channel() {
    return this._channels;
  }

  get itemsArray() {
    return this._itemsArray;
  }

  set itemsArray(itemsArray: any) {
    this._itemsArray = itemsArray;
  }

  constructor(
    private _rxEvent: RxEvent,
    private cheetahService: MarketDispatcher
  ) {
    this.instanciate();
    return (CustodyChannel.instance = CustodyChannel.instance || this);
  }

  async instanciate() {
    if (!this.instanciated) {
      this.instanciated = true;
    }
  }

  readEvents(): StreamReadResponse {
    return this._rxEvent.read(CHEETAH_CHANNEL.CUSTODY);
  }

  subscribe(params: SubscribeParam): void {
    this.channel = params;
    this.itemsArray = params.itemsArray;
    params.fields = CUSTODIA_CHEETAH_FIELDS;
    params.channel_cheetah = CHEETAH_CHANNEL.CUSTODY;
    this.cheetahService.subscribe({
      channel_cheetah: CHEETAH_CHANNEL.CUSTODY,
      itemsArray: params.itemsArray,
    });
    this.custodyReady.next();
  }

  onCustodyReady = () =>
    this.custodyReady.asObservable().pipe(debounceTime(300));

  unsubscribe(params: SubscribeParam): void {
    if (!params) return;
    params.channel_cheetah = CHEETAH_CHANNEL.CUSTODY;
    this.cheetahService.unsubscribe(params);
  }

  onRestart() {
    return this.cheetahService.onRestart();
  }
}
