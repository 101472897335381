import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { formatterNumber } from '@shared/rocket-components/utils';
import {
  dateOrTimeFormatter,
} from 'src/app/utils/cell-format.functions';
import { isNullOrUndefined } from 'src/app/utils/utils.functions';
import { OrdersHistoryService } from '../../../service/orders-history.service';
import { TABS_REF } from '../../../constants/orders-history.const';

@Component({
  selector: 'app-orders-history-order-card',
  templateUrl: './orders-history-order-card.component.html',
  styleUrls: ['./orders-history-order-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersHistoryOrderCardComponent implements OnChanges {
  @Input() cdStock!: string;
  @Input() side!: string;
  @Input() set lastPrice(lastPrice: any) {
    this._lastPrice = parseFloat(lastPrice || 0);
  }
  get lastPrice() {
    return formatterNumber(this._lastPrice, {
      maximumFractionDigits: !isNullOrUndefined(this.tickSize)
        ? parseInt(this.tickSize)
        : 2,
      minimumFractionDigits: !isNullOrUndefined(this.tickSize)
        ? parseInt(this.tickSize)
        : 2,
    });
  }
  @Input() set variation(variation: string) {
    this._variation = variation
      ? formatterNumber(parseFloat(variation))
      : '0,00';
  }
  get variation() {
    return this._variation;
  }
  @Input() variationColor!: string;
  @Input()
  set dhCreationTime(value: string) {
    this._dhCreationTime = dateOrTimeFormatter(value);
  }
  get dhCreationTime() {
    return this._dhCreationTime;
  }
  @Input() dsOrderType!: string;
  @Input() dsOrderTimeEnforce!: string;
  @Input() set cdOrderStatus(value: string) {
    this._cdOrderStatus = value;
    this.statusColor = this.ordersHistoryService.getColorStatus(value);
  }
  get cdOrderStatus(): string {
    return this._cdOrderStatus;
  }
  @Input() dsOrderStatus!: string;
  @Input() set price(price: any) {
    this._price = parseFloat(price);
  }
  get price() {
    return formatterNumber(this._price, {
      maximumFractionDigits: !isNullOrUndefined(this.tickSize)
        ? parseInt(this.tickSize)
        : 2,
      minimumFractionDigits: !isNullOrUndefined(this.tickSize)
        ? parseInt(this.tickSize)
        : 2,
    });
  }
  @Input() qtty!: string;
  @Input() tickSize!: string;
  @Input() hasDayTrade!: boolean;
  @Input() isSelected: boolean = false;
  @Input() tabRef!: string;
  @Input() listContainerWidth!: any;
  @Output() editOrderHandler = new EventEmitter<any>()
  @Output() cancelOrderHandler = new EventEmitter<any>()
  public statusColor!: string | null
  public isCompact: boolean = false;

  private _dhCreationTime!: string;
  private _lastPrice!: number;
  private _variation!: string;
  private _cdOrderStatus!: string;
  private _price!: number;

  constructor(private ordersHistoryService: OrdersHistoryService) {}

  get showOrderOptions() {
    return this.tabRef === TABS_REF['ORDER_OPEN']
  }

  ngOnChanges(changes: SimpleChanges) {
    const { listContainerWidth } = changes;
    if (listContainerWidth?.currentValue) {
      this.isCompact = listContainerWidth.currentValue <= 590;
    }
  }

  editOrder(event: any) {
    event?.stopPropagation()
    this.editOrderHandler.emit()
  }

  cancelOrder(event: any) {
    event?.stopPropagation()
    this.cancelOrderHandler.emit()
  }
}
