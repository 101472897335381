<div
  class="h-100 tooltip-indicator d-flex"
  [ngClass]="{
    'border border-brand-primary round pl-1 pr-1 w-fit': isIndicatorClicked
  }"
>
  <div
    (mouseenter)="isHover = true"
    (mouseleave)="isHover = false"
    class="d-flex position-relative user-select-none"
  >
    <span
      class="cursor-pointer"
      [ngClass]="{
        'text-neutral-medium': !indicator.isVisible,
        'text-neutral-smoothest': isDarkTheme && indicator.isVisible,
        'text-neutral-strongest': !isDarkTheme && indicator.isVisible
      }"
      >{{ abreviations[indicator.type] }} {{ indicator.propertiesText }}</span
    >
    <div *ngIf="indicator.isVisible">
      <span
        *ngFor="let value of indicatorValues"
        class="pl-2"
        [style.color]="value.color"
        >{{
          value.value
            | number : '1.' + value.precision + '-' + value.precision : locale
        }}</span
      >
    </div>
    <div *ngIf="isHover || isIndicatorClicked">
      <button
        *ngIf="false"
        class="btn btn-icon btn-sm p-0 ml-1"
        (click)="onMenuClick($event)"
      >
        <img src="assets/svg/down.svg" alt="Menu do indicador" />
      </button>
      <button
        class="btn btn-icon btn-sm p-0 ml-2"
        (click)="changeVisibility($event)"
        [tooltip]="visibilityTooltip()"
        tooltipPosition="bottom"
      >
        <img src="assets/svg/eye.svg" alt="Exibir/Ocultar" />
      </button>
      <button
        class="btn btn-icon btn-sm p-0 ml-2"
        (click)="onSettings()"
        tooltip="Configurações"
        tooltipPosition="bottom"
      >
        <img src="assets/svg/settings.svg" alt="Configurações" />
      </button>
      <button
        class="btn btn-icon btn-sm p-0 ml-2"
        (click)="onDelete()"
        tooltip="Remover"
        tooltipPosition="bottom"
      >
        <img src="assets/svg/close.svg" alt="Remover" />
      </button>
    </div>
  </div>
</div>
