import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { IWorkSpaceComponet } from '@core/interface';
import { IResumeList } from '../business-profile/business-profile.interface';
import { TrademapCalendarsService } from '@shared/services/api/trademap/v1/calendars.service';
import { catchError, map, of } from 'rxjs';
import { TrademapNewsService as TrademapNewsServiceV8 } from '@shared/services/api/trademap/V8/news.service';
import { TrademapNewsService as TrademapNewsServiceV1 } from '@shared/services/api/trademap/v1/news.service';
import { RocketModalService } from '@shared/rocket-components/components';
import { GeneralModalComponent } from './parts/general-modal/general-modal.component';
import {
  VMDateToFriendlyDate,
  VMDateToHoursAndMin,
  VMDateToYear,
} from 'src/app/utils/utils.functions';
import { RocketComponentBase } from '@shared/channel/base/rocket-component-base';
import { ActivatedRoute } from '@angular/router';
import { isWebViewContext } from 'src/app/desktop/integration.utils';

@Component({
  selector: 'app-market-summary',
  templateUrl: './market-summary.component.html',
  styleUrls: ['./market-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketSummaryComponent
  extends RocketComponentBase
  implements OnInit
{
  @Input() component!: IWorkSpaceComponet;
  @Input() refComponent!: string;
  @Input() link!: boolean;
  public dtComSelected: boolean = true;
  public eventsSelected: boolean = true;
  public calendarSelected: boolean = true;
  isDesktop = false;

  public calendarList: any = [];
  public eventsList: IResumeList[] = [];
  public proceedsList: IResumeList[] = [];
  public cvmList: IResumeList[] = [];
  public lastNewsList: any = [];

  constructor(
    private _trademapCalendarsService: TrademapCalendarsService,
    private _trademapNewsServiceV8: TrademapNewsServiceV8,
    private _trademapNewsServiceV1: TrademapNewsServiceV1,
    private modalService: RocketModalService,
    private cdr: ChangeDetectorRef,
    activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
    this.isDesktop = isWebViewContext();
  }

  protected initComponent(component: any): void {
    if (component) this.component = component;
    this.getCalendar();
    this.getLastNews();
    this.getEvents();
    this.getProceeds();
    this.getCVMNews();
  }

  private getCalendar() {
    this._trademapNewsServiceV1
      .getNewsCalendar('next')
      .subscribe((transformedData: any) => {
        this.calendarList = transformedData.slice(0, 6);
        this.cdr.detectChanges();
      });
  }

  private getLastNews() {
    this._trademapNewsServiceV1
      .getNews(0, undefined, [], [])
      .pipe(
        map((dataArray: any) => {
          return dataArray.slice(0, 8).map((data: any) => {
            return {
              ...data,
              ds_headline: data.headline,
              body: `${data.headline}<br /><span class="fs-7">${data.agencia}</span>`,
              dateString: VMDateToHoursAndMin(
                `${data.data_noticia}${data.hora_noticia.slice(0, 4)}`
              ),
              yearDoc: VMDateToFriendlyDate(data.data_noticia),
            };
          });
        })
      )
      .subscribe((transformedData: any) => {
        this.lastNewsList = transformedData;
        this.cdr.detectChanges();
      });
  }

  private getEvents() {
    this._trademapCalendarsService
      .getEvents(8)
      .pipe(
        map((dataArray: any) => {
          return dataArray.map((data: any) => {
            return {
              body: `${data.company}<br /><span class="fs-7">${data.ds_company_action_type}</span>`,
              yearDoc: data.reference_year,
              dateString: VMDateToFriendlyDate(data.dt_limit),
            };
          });
        })
      )
      .subscribe((transformedData: any) => {
        this.eventsList = transformedData;
        this.cdr.detectChanges();
      });
  }

  public changeDtComSelected(status: boolean) {
    if (status !== this.dtComSelected) {
      this.dtComSelected = status;
      this.getProceeds();
      this.cdr.detectChanges();
    }
  }

  public changeEventsSelected(isEvents: boolean) {
    if (isEvents === this.eventsSelected) return;
    this.eventsSelected = isEvents;
  }

  public changeCalendarSelected(isCalendar: boolean) {
    if (isCalendar === this.calendarSelected) return;
    this.calendarSelected = isCalendar;
  }

  private getProceeds() {
    this._trademapCalendarsService
      .getProceeds(!this.dtComSelected, 8)
      .pipe(
        map((dataArray: any) => {
          return dataArray.map((data: any) => {
            return {
              body: `${data.company}<br /><span class="fs-7">${data.ds_company_action_type}</span>`,
              yearDoc: data.reference_year,
              dateString: VMDateToFriendlyDate(data.dt_limit),
            };
          });
        })
      )
      .subscribe((transformedData: any) => {
        this.proceedsList = transformedData;
        this.cdr.detectChanges();
      });
  }

  private getCVMNews() {
    this._trademapNewsServiceV8
      .getCVMNews(0, 8)
      .pipe(
        map((dataArray: any) => {
          return dataArray[0].news.map((data: any) => {
            return {
              dsDocumentDescription: data.data.ds_document_description,
              dsDocumentCategoryPt: data.ds_headline_category,
              idCvmCompany: data.data.id_cvm_company,
              seq: data.data.seq,
              body: `${data.ds_headline_legal_name}<br /><span class="fs-7">${data.ds_headline_category}</span>`,
              yearDoc: VMDateToYear(data.dt_news),
              dateString: VMDateToFriendlyDate(data.dt_news),
            };
          });
        }),
        catchError(() => of([]))
      )
      .subscribe((transformedData: any) => {
        this.cvmList = transformedData;
        this.cdr.detectChanges();
      });
  }

  public openModal(type: string) {
    this.modalService.open(GeneralModalComponent, {
      data: {
        type,
      },
      centered: true,
      backdrop: true,
      keyboard: false,
      scrollable: false,
    });
  }
}
