<div #showcase class="showcase">
  <div class="rt-products-carousel">
    <div class="px-2 w-100 d-flex flex-column align-items-center">
      <ul #itemList class="rt-carousel-container" *ngIf="cards.length">
        <div
          #carouselItem
          class="rt-carousel-item organism-products-carousel-element"
          *ngFor="let card of cards; let index = index"
          [style.order]="index"
        >
          <app-news-card
            (click)="openNews(card)"
            [cardNotice]="card"
          ></app-news-card>
        </div>
      </ul>
      <ng-container *ngIf="!hideButtons">
        <button class="rt-btn rt-prev" (click)="handleNavigation(false)">
          <fla-icon
            name="arrow_back_ios"
            size="icon-size-sm"
            css="text-brand-primary cursor-pointer"
          ></fla-icon>
        </button>
        <button class="rt-btn rt-next" (click)="handleNavigation(true)">
          <fla-icon
            name="arrow_forward_ios"
            size="icon-size-sm"
            css="text-brand-primary cursor-pointer"
          ></fla-icon>
        </button>
      </ng-container>
    </div>
  </div>
</div>
