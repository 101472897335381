import { Injectable } from '@angular/core';
import { AUTH_SESSION_KEYS } from '@services/core/const/auth_util.const';
import { BrowserStorageBase } from '@services/core/storage/browser-storage-base';
import {
  CHEETAH_CHANNEL,
  ORDER_CHEETAH_FIELDS,
} from '@shared/cheetah/cheetah.const';
import { SubscribeParam } from '@shared/cheetah/service/cheetah.service';
import { BehaviorSubject } from 'rxjs';
import { Dictionary } from 'src/app/core/models';
import { RxEvent, StreamReadResponse } from './rx-event';
import { MarketDispatcher } from '@shared/cheetah/dispatcher/market.dispatcher';
import { ListOrdersService } from '@shared/services/core/list-orders/orders.service';
import { arrayIntersections } from '@shared/cheetah/functions/functions';
import { OrdersService } from '@shared/services/orders.service';
import { sharedSessionStorage } from '@shared/services/core/storage/shared-session.storage';

@Injectable({
  providedIn: 'root',
})
export class OrderChannel {
  private storage = new BrowserStorageBase(sharedSessionStorage);
  private instanciated!: boolean;
  private orderCountChannel$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  static instance: OrderChannel;
  private channel$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private ordersSnapshot$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  channel_cheetah: string = CHEETAH_CHANNEL.ORDER;
  private _itemsArray!: any;
  private _channels: Dictionary<any> = new Dictionary<any>();
  private idBroker!: number;

  private set channel(data: any) {
    this._channels.set(data.header, data);
  }

  private get channel() {
    return this._channels;
  }

  get ordersDict() {
    return this._listOrdersService.ordersDict;
  }

  get id_investor() {
    return this.storage.getItem(AUTH_SESSION_KEYS.ID_INVESTOR);
  }

  get itemsArray() {
    return this._itemsArray;
  }

  set itemsArray(itemsArray: any) {
    this._itemsArray = itemsArray;
  }

  get fields() {
    return ORDER_CHEETAH_FIELDS;
  }

  get subscribeParams(): SubscribeParam {
    return {
      channel_cheetah: CHEETAH_CHANNEL.ORDER,
      itemsArray: this.itemsArray,
    };
  }

  constructor(
    private _rxEvent: RxEvent,
    private _cheetahService: MarketDispatcher,
    private _ordersService: OrdersService,
    private _listOrdersService: ListOrdersService
  ) {
    this.instanciate();
    return (OrderChannel.instance = OrderChannel.instance || this);
  }

  readEvents(): StreamReadResponse {
    return this._rxEvent.read(CHEETAH_CHANNEL.ORDER);
  }

  subscribe(params: SubscribeParam): void {
    // console.log(params);
    const { itemsArray } = params;
    params.fields = ORDER_CHEETAH_FIELDS;
    params.channel_cheetah = CHEETAH_CHANNEL.ORDER;
    this.channel = params;
    this.itemsArray = itemsArray;
    // this.subscribeSubject.next();
    this._cheetahService.subscribe(params);
  }

  unsubscribe(params: SubscribeParam): void {
    // console.log(params);
    if (!params) return;
    params.channel_cheetah = CHEETAH_CHANNEL.ORDER;
    const { header, itemsArray: itensToUnsub } = params;
    this.getChannelToUnsub(header, itensToUnsub);
    this.ordersDict.clear();
    const canUnsub = this.verifyCanUnsubscribeItem(itensToUnsub);
    if (canUnsub?.length) {
      this.processUnsub(params, canUnsub);
    }
  }

  async instanciate() {
    if (!this.instanciated) {
      this.instanciated = true;
      this._cheetahService.onRestart().subscribe(this.restart);
    }
  }

  private logPerforming(time_init_restart: number) {
    const time_final_restart = performance.now();
    const perftime = parseInt(`${time_final_restart - time_init_restart}`);
    const message = `Call to restart cycle from ${this.channel_cheetah} took ${perftime}ms (milliseconds).`;
    console.log(message);
  }

  restart = (): void => {
    this._ordersService.getAllOrdersPaged(this.idBroker).subscribe(() => {
      this._cheetahService.subscribe({
        itemsArray: this.itemsArray ?? [],
        channel_cheetah: CHEETAH_CHANNEL.ORDER,
      });
      // this.dispatchRestart(data.orders);
    });
  };

  verifyCanUnsubscribeItem(itensToUnsub: any) {
    const allArrayItems = this.getAllArrayItens();
    const { diff } = arrayIntersections(allArrayItems.split(','), itensToUnsub);
    return diff;
  }

  getAllArrayItens() {
    const allArrayItems = this.channel
      .keys()
      .map((item: string) => this.channel.get(item).itemsArray)
      .join();
    return allArrayItems;
  }

  processUnsub(params: SubscribeParam, toUnsub: any) {
    if (toUnsub.length) {
      params.itemsArray = toUnsub;
      this._cheetahService.unsubscribe(params);
    }
  }

  private getChannelToUnsub(header: string, itensToUnsub: any): SubscribeParam {
    const canal = this.channel.get(header);
    if (!canal) {
      throw new Error(
        `not channel ${header} to unsubscribe ${CHEETAH_CHANNEL.ORDER}`
      );
    }
    canal.itemsArray = canal.itemsArray.filter(
      (item: string) => !itensToUnsub.includes(item)
    );
    if (!canal.itemsArray.length) this.channel.delete(header);
    return canal;
  }
}
