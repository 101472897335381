import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { GridISelectRow } from '@shared/rocket-grid';
import { ORDERS_HISTORY_ELEMENT_IDS } from '../../enum/orders-history.enum';
import { ContextMenuService } from '@shared/components/popup-root/context-menu.service';
import { OrdersHistoryContextMenuEvent } from '../../service/orders-history-config.service';
import { BaseContextMenuComponent } from '@shared/rocket-components/base-context-menu';

@Component({
  selector: 'app-orders-history-context-menu',
  template: `<div
    class="round bg-neutral-strong position-absolute fs-7"
    [id]="elementIDS.MENU_CONTEXT"
    [ngClass]="{ 'pr-none': isTour }"
    [style.width]="'210px'"
    [style.padding]="'10px'"
    [style.top.px]="position.top"
    [style.left.px]="position.left"
    [style.z-index]="9999"
    [style.display]="isTour ? 'none' : 'block'"
    (mouseleave)="onFocusOut()"
  >
    <div class="list-group list-group-flush">
      <div class="header-context-menu">
        <span>{{ title }}</span>
      </div>

      <ng-container *ngIf="keyHeader">
        <button
          type="button"
          class="list-group-item list-group-item-action context-item-button"
          (click)="handleSetInvisibleContent()"
          [id]="elementIDS.HIDE_COLUMN"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="visibility_off"
          ></fla-icon>
          {{ hiddenContentLabel }}
        </button>
      </ng-container>

      <ng-container *ngIf="!keyHeader">
        <section
          [id]="elementIDS.EDIT_AND_CANCEL_ORDER"
          class="d-flex flex-column"
        >
          <button
            type="button"
            class="list-group-item list-group-item-action context-item-button"
            [disabled]="!isRowSelect || !canCancelOrEdit"
            (click)="editOrder()"
          >
            <fla-icon
              size="icon-size-tiny"
              css="position-absolute left-0 text-neutral-medium fs-4"
              name="edit"
            ></fla-icon>
            Editar Ordem
          </button>

          <button
            type="button"
            class="list-group-item list-group-item-action context-item-button"
            [disabled]="!isRowSelect || !canCancelOrEdit"
            (click)="cancelOrder()"
          >
            <fla-icon
              size="icon-size-tiny"
              css="position-absolute left-0 text-neutral-medium fs-4"
              name="remove"
            ></fla-icon>
            Cancelar Ordem
          </button>
          <button
            type="button"
            class="list-group-item list-group-item-action context-item-button"
            [disabled]="!isDetailsOrder"
            (click)="detailsOrder()"
          >
            <fla-icon
              size="icon-size-tiny"
              css="position-absolute left-0 text-neutral-medium fs-4"
              name="info"
            ></fla-icon>
            Detalhes
          </button>
        </section>
      </ng-container>

      <ng-container *ngIf="keyHeader">
        <button
          [id]="elementIDS.PREFERENCES"
          type="button"
          class="list-group-item list-group-item-action context-item-button"
          (click)="openModalPreferences()"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="settings"
          ></fla-icon>
          Preferências
        </button>
        <hr class="separator-context-menu" />
      </ng-container>
      <ng-container *ngIf="!isCardView">
        <button
          [id]="elementIDS.CLEAR_FILTERS"
          type="button"
          class="list-group-item list-group-item-action context-item-button"
          (click)="resetFilter()"
          [disabled]="!filterIsActive"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="filter_alt_off"
          ></fla-icon>
          Limpar Filtros
        </button>

        <button
          *ngIf="!isCardView"
          [id]="elementIDS.CLEAR_SORTED"
          type="button"
          class="list-group-item list-group-item-action context-item-button"
          (click)="restartSort()"
          [disabled]="!isSorted"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="sort"
          ></fla-icon>
          Limpar Ordenação
        </button>
        <hr class="separator-context-menu" />
        <button
          [id]="elementIDS.EXPORT_DATA"
          type="button"
          class="list-group-item list-group-item-action context-item-button"
          (click)="exportData()"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="download"
          ></fla-icon>
          Exportar Dados
        </button>
      </ng-container>
      <hr class="separator-context-menu" />
      <section class="d-flex flex-column">
        <button
          *ngIf="!isCardView"
          type="button"
          class="list-group-item list-group-item-action border-none fs-6 context-item-button"
          (click)="resetDefault()"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="refresh"
          ></fla-icon>
          Restaurar
        </button>

        <button
          type="button"
          class="list-group-item list-group-item-action border-none fs-6 context-item-button"
          disabled
          (click)="tourHandler()"
        >
          <fla-icon
            size="icon-size-tiny"
            css="position-absolute left-0 text-neutral-medium fs-4"
            name="help_outline"
          ></fla-icon>
          Ajuda
        </button>
      </section>
    </div>
  </div>`,
})
export class OrderTabsContextMenuComponent extends BaseContextMenuComponent {
  refComponent!: string;
  componentId!: string;
  keyHeader!: string | null;
  isVisible!: boolean;
  rowSelect!: GridISelectRow | null | undefined;
  isRowSelect!: boolean;
  filterIsActive!: boolean;
  isSorted!: boolean;
  canHideTab: boolean = true;
  isTour: boolean = false;
  elementIDS = ORDERS_HISTORY_ELEMENT_IDS;
  title: string = 'Ordens';
  hiddenContentLabel: string = 'Ocultar Coluna';
  isDetailsOrder = false;
  parent_id!: string;
  isCardView: boolean = false;
  canCancelOrEdit: boolean = false

  constructor(
    elementRef: ElementRef,
    cdr: ChangeDetectorRef,
    private contextMenuService: ContextMenuService
  ) {
    super(elementRef, cdr);
  }

  static openContextMenu(
    contextMenuService: ContextMenuService,
    parent_id: string,
    event: { clientX: number; clientY: number },
    isTour: boolean,
    isVisible: boolean,
    isRowSelect: boolean,
    filterIsActive: boolean,
    isSorted: boolean,
    canHideTab: boolean,
    isDetailsOrder: boolean,
    keyHeader: string,
    isCardView: boolean,
    canCancelOrEdit: boolean
  ) {
    if (isTour) {
      const domRect = document
        .getElementsByTagName('app-orders-history')[0]
        .getClientRects()[0];
      event.clientX = domRect.x;
      event.clientY = domRect.y + 100;
    }

    contextMenuService.openContextMenu(parent_id, {
      type: 'OrderTabsContextMenuComponent',
      event: event,
      configurationData: {
        isTour,
        isVisible,
        isRowSelect,
        filterIsActive,
        isSorted,
        canHideTab,
        isDetailsOrder,
        keyHeader,
        isCardView,
        canCancelOrEdit
      },
    });
  }

  private emitEvent(action: OrdersHistoryContextMenuEvent) {
    this.contextMenuService.emitEvent(this.parent_id, action);
    setTimeout(() => {
      this.onFocusOut();
    }, 100);
  }

  onFocusOut() {
    this.contextMenuService.closeContextMenu(this.parent_id);
  }

  handleSetInvisibleContent(): void {
    this.emitEvent(OrdersHistoryContextMenuEvent.SetInvisibleColumn);
  }

  editOrder(): void {
    this.emitEvent(OrdersHistoryContextMenuEvent.EditOrder);
  }

  cancelOrder(): void {
    this.emitEvent(OrdersHistoryContextMenuEvent.CancelOrder);
  }

  detailsOrder(): void {
    this.emitEvent(OrdersHistoryContextMenuEvent.DetailsOrder);
  }

  resetDefault(): void {
    this.emitEvent(OrdersHistoryContextMenuEvent.ResetDefault);
  }

  resetFilter(): void {
    this.emitEvent(OrdersHistoryContextMenuEvent.ResetFilter);
  }

  restartSort(): void {
    this.emitEvent(OrdersHistoryContextMenuEvent.RestartSort);
  }

  openModalPreferences(): void {
    this.emitEvent(OrdersHistoryContextMenuEvent.OpenModalPreferences);
  }

  exportData(): void {
    this.emitEvent(OrdersHistoryContextMenuEvent.ExportData);
  }

  tourHandler(): void {
    this.emitEvent(OrdersHistoryContextMenuEvent.TourHandler);
  }
}
