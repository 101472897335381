import { ICustodyChartInfos } from '@shared/services/api/nitro-custody-ws/v1/interface/custody.interface';
import {
  IPerformanceData,
  IPerformanceMarketData,
  IPerformancePercentagesData,
  IPerformancePeriod,
} from '../interface/performance-report.interface';
import { CHART_COLORS } from '@shared/tiger-chart/colors';
import { system } from '@core/system/system.service';

export const DEFAULT_PERFORMANCE_VALUES: IPerformanceData = {
  gross_profit: 0,
  gross_loss: 0,
  win_percent: 0,
  loss_percent: 0,
  zero_percent: 0,
  total_operation: 0,
  win_operation: 0,
  zero_operation: 0,
  loss_operation: 0,
  avg_profit_and_loss: 0,
  avg_profit: 0,
  highest_won_operation: 0,
  highest_lose_operation: 0,
  highest_win_streak: 0,
  highest_loss_streak: 0,
  avg_loss: 0,
  reason_profit_loss: 0,
  has_day_trade: false,
  has_swing: false,
  liquid_profit: 0,
  total_order: 0,
  open_order: 0,
  closed_order: 0,
  executed_order: 0,
  parc_canceled_order: 0,
  canceled_order: 0,
};

export const DEFAULT_PERFORMANCE_MARKETDATA_VALUES: IPerformanceMarketData = {
  mkt_open_order_buy: 0,
  mkt_canc_order_buy: 0,
  mkt_pcanc_order_buy: 0,
  mkt_exec_order_buy: 0,
  lmt_open_order_buy: 0,
  lmt_canc_order_buy: 0,
  lmt_pcanc_order_buy: 0,
  lmt_exec_order_buy: 0,
  stop_open_order_buy: 0,
  stop_canc_order_buy: 0,
  stop_pcanc_order_buy: 0,
  stop_exec_order_buy: 0,
  stop2_open_order_buy: 0,
  stop2_canc_order_buy: 0,
  stop2_pcanc_order_buy: 0,
  stop2_exec_order_buy: 0,
  mkt_open_order_sell: 0,
  mkt_canc_order_sell: 0,
  mkt_pcanc_order_sell: 0,
  mkt_exec_order_sell: 0,
  lmt_open_order_sell: 0,
  lmt_canc_order_sell: 0,
  lmt_pcanc_order_sell: 0,
  lmt_exec_order_sell: 0,
  stop_open_order_sell: 0,
  stop_canc_order_sell: 0,
  stop_pcanc_order_sell: 0,
  stop_exec_order_sell: 0,
  stop2_open_order_sell: 0,
  stop2_canc_order_sell: 0,
  stop2_pcanc_order_sell: 0,
  stop2_exec_order_sell: 0,
};

export const DEFAULT_PERFORMANCE_PERCENTAGES_VALUES: IPerformancePercentagesData =
  {
    win_percent: 0,
    loss_percent: 0,
    zero_percent: 0,
    open_percent: 0,
    loss_operation: 0,
    zero_operation: 0,
    open_operation: 0,
    win_operation: 0,
    won_pnl: 0,
    loss_pnl: 0,
    open_pnl: 0,
    total_operation: 0,
    pnl_open: 0,
    total_percent: 0,
    liquid_profit: 0,
    profit_day_perc: 0,
    loss_day_perc: 0,
  };

export const DEFAULT_PERFORMANCE_VALUES_BY_PERIOD: ICustodyChartInfos = {
  vl_gross_profit: 0, //Lucro Bruto
  vl_gross_loss: 0, //Prejuizo Bruto
  vl_profit: 0, //Saldo Liquido
  qtty_win_streak: 0, //Sequencia Operação Vencedora
  qtty_loss_streak: 0, //Sequencia Operação Perdedora
  vl_highest_win_operation: 0, //Valor Maior Operação Vencedora
  vl_highest_loss_operation: 0, //Valor Maior Operação Perdedora
  total_operation: 0, //Total Operação
  qtty_win_operation: 0, //Total Operação Vencedora
  qtty_loss_operation: 0, //Total Operação Perdedora
  qtty_zero_operation: 0, // Total Operação Zerada
  perc_win_operation: 0, //Percentual Operação Vencedora
  perc_loss_operation: 0, //Percentual Operação Perdedora
  perc_zero_operation: 0, // Percentual Operação Zerada
  total_order: 0, //Total Ordens
  qtty_open_order: 0, //Ordens Abertas
  qtty_exec_order: 0, //Ordens Executadas
  qtty_canc_order: 0, //Ordens Canceladas
  avg_pnl: 0, //Media Lucro/Prejuizo
  avg_win_operation: 0, // Media Vencedoras
  avg_loss_operation: 0, //Media Perdedoras
  vl_reason_pnl: 0, //R. Lucro Prejuizo
  account: 0,
  id_broker: 0,
  id_point: 0,
};

export const PERFORMANCE_OPERATION_TYPE_FILTER = [
  'Posições',
  'Intradia',
  'Swing',
];
export const performanceOperationTypeLabels = (id_broker: number) => {
  const selectedBroker = system.brokers.find(
    (broker) => broker.id_broker === id_broker
  );
  const labels: any = {
    Posições: 'Posições',
  };
  if (selectedBroker?.broker_daytrade)
    labels['Intradia'] = selectedBroker?.nm_broker_daytrade;

  if (selectedBroker?.broker_swing)
    labels['Swing'] = selectedBroker?.nm_broker_swing;

  return labels;
};
export const DEFAULT_OPERATION_TYPE_FILTER = 'Posições';

export const operationTypeHandler = (operationType: string) => {
  const options: any = {
    Posições: 'DEFAULT',
    Intradia: 'DAY_TRADE',
    Swing: 'SWING',
  };
  return options[operationType];
};

export const PERFORMANCE_CHART_PERIODS: IPerformancePeriod[] = [
  {
    value: 'intraday',
    text: 'Um dia',
    label: '1d',
  },
  {
    value: 'weekly',
    text: '1 Semana',
    label: '1s',
  },
  {
    value: 'monthly',
    text: '1 Mês',
    label: '1m',
    startDtBeforeToday: 30,
    endDtBeforeToday: 0,
  },
];

export const PERFORMANCE_CHART_DEFAULT_PERIOD: IPerformancePeriod = {
  value: 'intraday',
  text: 'Um dia',
  label: '1d',
};

export const PERFORMANCE_PERIODS: IPerformancePeriod[] = [
  {
    value: 'today',
    text: 'Hoje',
    label: 'Hoje',
    startDtBeforeToday: 0,
    endDtBeforeToday: 0,
  },
  {
    value: 'monthly',
    text: 'Ontem',
    label: 'Ontem',
    startDtBeforeToday: 1,
    endDtBeforeToday: 1,
  },
];

export const PERFORMANCE_DEFAULT_PERIOD: IPerformancePeriod = {
  value: 'today',
  text: 'Hoje',
  label: 'Hoje',
};

export const PERFOMANCE_CHART_STYLES: Highcharts.CSSObject = {
  fontSize: '9px',
  fontFamily: 'Metropolis',
  color: CHART_COLORS.NEUTRAL_SMOOTH,
};

export const PERFORMANCE_FIELD_PREFIX_TYPE: { [key: string]: string } = {
  mkt: 'Mercado',
  lmt: 'Limitada',
  stop: 'Stop',
  stop2: 'Stop Duplo',
};
