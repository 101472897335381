import { NgModule } from '@angular/core';

import { TableBookModule } from '@shared/components/table-book/table-book.module';
import { TableBookRoutes } from '@shared/components/table-book/table-book.routing';

@NgModule({
  declarations: [],
  imports: [TableBookRoutes, TableBookModule],
})
export class TableBookRouteModule {}
