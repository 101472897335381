import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DocumentsModalComponent } from './documents-modal.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { RocketModalModule } from '@shared/rocket-components/components';

@NgModule({
  imports: [CommonModule, NgxExtendedPdfViewerModule, RocketModalModule],
  declarations: [DocumentsModalComponent],
  exports: [DocumentsModalComponent],
})
export class DocumentsModalModule {}
