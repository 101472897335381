import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { ModalRiskManagementComponent } from '@shared/modals/modal-risk-management/modal-risk-management.component';
import { RocketButtonModule } from '@shared/rocket-components/components/button/button.module';
import { FlaIconModule } from '@shared/rocket-components/components/icon/icon.module';
import { RocketModalService } from '@shared/rocket-components/components/modal/service/modal.service';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { Subject, takeUntil } from 'rxjs';
import { RiskManagementService } from '../service/risk-management.service';

@Component({
  selector: 'app-risk-management-icon',
  templateUrl: './risk-management-icon.component.html',
  standalone: true,
  imports: [CommonModule, RocketButtonModule, FlaIconModule, TooltipsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskManagementIconComponent implements AfterViewInit, OnDestroy {
  public tooltip: string = 'Gerenciamento de risco';
  public iconColor: string = '';
  public active: boolean = false;
  private onDestory$ = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private modalService: RocketModalService,
    private riskManagementService: RiskManagementService
  ) {}

  ngAfterViewInit(): void {
    this.riskManagementService
      .risksPersonalActive()
      .pipe(takeUntil(this.onDestory$))
      .subscribe((isActive: boolean) => {
        this.active = isActive;
        this.checkRisckPersonalByBrokerActive();
      });
    this.riskManagementService.getRisksPersonalDelayTime();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.onDestory$.next();
    this.onDestory$.complete();
  }

  private checkRisckPersonalByBrokerActive(): void {
    this.iconColor = this.active ? 'text-brand-primary' : '';
    this.tooltip = this.active
      ? 'Gerenciamento de risco está ativo'
      : 'Gerenciamento de risco';
    this.cdr.detectChanges();
  }

  public openOlanTradingModal() {
    this.modalService.open(ModalRiskManagementComponent, {
      size: 'lg',
      centered: true,
      keyboard: true,
      backdrop: true,
    });
  }
}
