<div class="datepicker-week">
  <div
    *ngFor="let item of daysMap; let i = index"
    [id]="i"
    class="datepicker-week-name"
  >
    {{ item.substring(0, 3) }}
  </div>
</div>
<div class="datepicker-days">
  <button
    class="datepicker-day"
    *ngFor="let day of monthDetails"
    [ngClass]="[
      day.disabled ? 'disabled' : '',
      day.timestamp === todayTimestamp ? 'is-today' : '',
      day.timestamp === selectedDay && day.month === 0 ? 'highlight-green' : ''
    ]"
    (click)="flaClick.emit(day)"
  >
    {{ day.date }}
  </button>
</div>
