import { NgModule } from '@angular/core';

import { StockChartRoutes } from '@shared/components/stock-chart/stock-chart.routing';
import { StockChartModule } from '@shared/components/stock-chart/stock-chart.module';

@NgModule({
  declarations: [],
  imports: [StockChartRoutes, StockChartModule],
})
export class StockChartRouteModule {}
