import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsComponent } from './news.component';
import { RTDropdownModule } from '@shared/rocket-components/dropdown';
import { FlaWorkSpaceModule } from '@core/workspace/workspace.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingModule } from '../loading/loading.module';
import { MarketSectorsService } from '@shared/services/api/trademap/v1/market-sectors.service';
import {
  FlaCardModule,
  FlaIconModule,
  FlaInputModule,
  RocketButtonModule,
} from '@shared/rocket-components/components/index';
import { NewsContextMenuComponent } from './config/news-context-menu.component';
import { NewsHeaderComponent } from './news-header/news-header.component';
import { NewsCardComponent } from './news-card/news-card.component';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { NewsTextComponent } from './news-text/news-text.component';
import { NewsCardFooterComponent } from './news-card-footer/news-card-footer.component';
import { NewsSearchComponent } from './news-search/news-search.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NewsCardHeaderComponent } from './news-card-header/news-card-header.component';

@NgModule({
  imports: [
    CommonModule,
    FlaCardModule,
    FlaIconModule,
    FlaInputModule,
    FlaWorkSpaceModule,
    FormsModule,
    LoadingModule,
    NgxExtendedPdfViewerModule,
    ReactiveFormsModule,
    RocketButtonModule,
    RTDropdownModule,
    TooltipsModule,
  ],
  declarations: [
    NewsContextMenuComponent,
    NewsCardComponent,
    NewsCardFooterComponent,
    NewsCardHeaderComponent,
    NewsComponent,
    NewsHeaderComponent,
    NewsSearchComponent,
    NewsTextComponent,
  ],
  providers: [MarketSectorsService],
  exports: [NewsComponent],
})
export class NewsModule {}
