import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { BroadcastMessage } from 'src/app/core/broadcast/broadcast-message';
import { BroadcastService } from 'src/app/core/broadcast/broadcast-service';
import { BROADCAST_SERVICE } from 'src/app/core/broadcast/providers';
import { IAllStockListSimple } from 'src/app/core/interface';
import { GLOBAL_STOCK_LIST_CHANGE } from './const/events-name';
import { GlobalStockListPreferences } from '../custom-preferences/global-stock-list/global-stock-list-preferences.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalStockListSubscription {
  private get globalStockList() {
    return this.preferences.globalStockListSelected;
  }

  private set globalStockList(list) {
    this.preferences.globalStockListSelected = list;
  }

  constructor(
    @Inject(BROADCAST_SERVICE) private broadCastService: BroadcastService,
    private preferences: GlobalStockListPreferences
  ) {}

  hasStockList = () => Boolean(this.globalStockList);

  setGlobalStockListSelected(stock: any) {
    this.globalStockList = stock;
  }

  getGlobalStockListSelected() {
    return this.globalStockList;
  }

  changeGlobalStockList(list: IAllStockListSimple, changedInHeatmap?: boolean) {
    if (
      !list ||
      !Object.keys(list).length ||
      this.globalStockList?.nm_stock_list === list.nm_stock_list
    )
      return;
    this.globalStockList = list;
    this.broadCastService.publish({
      type: GLOBAL_STOCK_LIST_CHANGE,
      payload: { list, changedInHeatmap },
    });
  }

  onGlobalStockListChange() {
    return this.broadCastService
      .messagesOfType(GLOBAL_STOCK_LIST_CHANGE)
      .pipe(map((data: BroadcastMessage) => data.payload));
  }
}
