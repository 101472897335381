import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  GridIOpenConfigGrid,
  GridIReferenceService,
  GridISelectRow,
  GridIColumnVisibleInService,
  IStockTableRemoveRow,
  GridUpdateStocksFromSameLists,
} from '@shared/rocket-grid';
import { IStockListItemsRow } from '@shared/components/stock-table/interfaces/stock-table.interfaces';
import { IStockTableIndex } from '@shared/components/stock-table/interfaces/movers-index.interface';
import { IMoversPeriodEvent } from '@shared/components/stock-table/interfaces/movers-period.interface';

@Injectable()
export class RocketGridService {
  private addRow$: BehaviorSubject<{
    refComponent: string;
    row: GridISelectRow | undefined | null;
  }> = new BehaviorSubject<{
    refComponent: string;
    row: GridISelectRow | undefined | null;
  }>({ refComponent: '', row: undefined });
  private removeRow$: BehaviorSubject<GridISelectRow> =
    new BehaviorSubject<GridISelectRow>({
      rowData: null,
      rowIndex: -1,
      refComponent: '',
    });
  private removeRowFromSameLists$ = new Subject<IStockTableRemoveRow>();
  private showEdit$: BehaviorSubject<GridIReferenceService> =
    new BehaviorSubject<GridIReferenceService>({
      refComponent: '',
      value: null,
    });
  private configColumns$: BehaviorSubject<GridIReferenceService> =
    new BehaviorSubject<GridIReferenceService>({
      refComponent: '',
      value: null,
    });
  private showConfig$: BehaviorSubject<GridIOpenConfigGrid> =
    new BehaviorSubject<GridIOpenConfigGrid>({
      event: null,
      refComponent: '',
      rowSelect: undefined,
      isVisible: false,
      keyHeader: null,
      isShowEdit: false,
    });
  private invisibleColumn$: BehaviorSubject<GridIColumnVisibleInService> =
    new BehaviorSubject<GridIColumnVisibleInService>({
      refComponent: '',
      keys: [],
      visible: false,
    });
  private resetFilters$: BehaviorSubject<any> = new BehaviorSubject<any>({
    filter: false,
  });
  private updateStocksFromSameLists$ =
    new Subject<GridUpdateStocksFromSameLists>();
  private _moversIndex = new Subject<{
    index: IStockTableIndex;
    refComponent: string;
  }>();
  private _moversPeriod = new Subject<IMoversPeriodEvent>();
  private _resetTableHeaders = new Subject<{ refComponent: string }>();

  get removeRow(): Observable<GridISelectRow> {
    return this.removeRow$.asObservable();
  }
  get removeRowFromSameLists(): Observable<IStockTableRemoveRow> {
    return this.removeRowFromSameLists$.asObservable();
  }
  get showEdit(): Observable<GridIReferenceService> {
    return this.showEdit$.asObservable();
  }
  get configColumns(): Observable<GridIReferenceService> {
    return this.configColumns$.asObservable();
  }
  get openAddRow(): Observable<{
    refComponent: string;
    row: GridISelectRow | undefined | null;
  }> {
    return this.addRow$.asObservable();
  }
  get invisibleColumn(): Observable<GridIColumnVisibleInService> {
    return this.invisibleColumn$.asObservable();
  }
  get showConfig(): Observable<GridIOpenConfigGrid> {
    return this.showConfig$.asObservable();
  }
  get resetFilters(): Observable<any> {
    return this.resetFilters$.asObservable();
  }
  get updateStocksFromSameLists(): Observable<GridUpdateStocksFromSameLists> {
    return this.updateStocksFromSameLists$.asObservable();
  }

  get moversIndex$(): Observable<{
    index: IStockTableIndex;
    refComponent: string;
  }> {
    return this._moversIndex.asObservable();
  }

  get moversPeriod$(): Observable<IMoversPeriodEvent> {
    return this._moversPeriod.asObservable();
  }

  get resetTableHeaders$(): Observable<{ refComponent: string }> {
    return this._resetTableHeaders.asObservable();
  }

  setRemoveRow(row: GridISelectRow): void {
    this.removeRow$.next(row);
  }

  setRemoveStockFromSameLists(stock: IStockTableRemoveRow): void {
    this.removeRowFromSameLists$.next(stock);
  }

  setEdit(edit: GridIReferenceService): void {
    this.showEdit$.next(edit);
  }

  setConfigColumns(config: GridIReferenceService): void {
    this.configColumns$.next(config);
  }

  setOpenAddRow(
    refComponent: string,
    row: GridISelectRow | undefined | null
  ): void {
    this.addRow$.next({ refComponent, row });
  }

  setInvisibleColumn(invisibleColumn: GridIColumnVisibleInService): void {
    this.invisibleColumn$.next(invisibleColumn);
  }

  public setShowConfig(value: GridIOpenConfigGrid) {
    this.showConfig$.next(value);
  }

  public setResetFilters(value: any) {
    this.resetFilters$.next(value);
  }

  public setUpdateStocksFromSameLists(
    idList: number,
    stocks: IStockListItemsRow[]
  ): void {
    this.updateStocksFromSameLists$.next({ idList, stocks });
  }

  public updateMoversIndex(params: {
    index: IStockTableIndex;
    refComponent: string;
  }): void {
    this._moversIndex.next(params);
  }

  public updateMoversPeriod(params: IMoversPeriodEvent): void {
    this._moversPeriod.next(params);
  }

  public resetTableHeaders(params: { refComponent: string }): void {
    this._resetTableHeaders.next(params);
  }
}
