/* eslint-disable prefer-const */
import { TWebAssemblyChart } from 'scichart/Charting/Visuals/SciChartSurface';
import { BaseRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/BaseRenderableSeries';
import {
  TData,
  TTooltipIndicatorValue,
  TUpdateData,
} from '@shared/tiger-chart/types/tiger-chart.types';
import {
  BaseIndicator,
  BollingerBandsData,
  BollingerBandsOptions,
  TTigerChartIndicatorCreateOptions,
  TTigerChartIndicatorParameter,
  TTigerChartIndicatorRenderSeriesConfig,
} from '../indicators.types';
import {
  TIGER_INDICATORS_ENUM,
  TIGER_INDICATOR_PARAMETER_TYPE,
  TIGER_INDICATOR_SOURCES,
} from '@shared/tiger-chart/enum/tiger-chart.enum';
import { FastBandRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastBandRenderableSeries';
import { XyyDataSeries } from 'scichart/Charting/Model/XyyDataSeries';
import { Subject } from 'rxjs';
import { TalibService } from '@shared/tiger-chart/services/talib.service';
import { XyyScaleOffsetFilter } from 'scichart/Charting/Model/Filters/XyyScaleOffsetFilter';
import { XyDataSeries } from 'scichart/Charting/Model/XyDataSeries';
import { FastLineRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastLineRenderableSeries';
import { TIGER_INDICATOR_PRECISIONS } from '../indicators.constants';
import { setDefaultConfiguration } from '../indicators.functions';
import { LogarithmicAxis } from 'scichart';

export class BollingerBandsBase implements BaseIndicator {
  protected lineDataSeries!: XyDataSeries;
  protected lineUpperDataSeries!: XyDataSeries;
  protected lineLowerDataSeries!: XyDataSeries;
  protected bandsDataSeries!: XyyDataSeries;
  protected movingAverageDataSeries!: XyyScaleOffsetFilter;
  protected lineRenderableSeries!: FastLineRenderableSeries;
  protected bandsRenderableSeries!: FastBandRenderableSeries;
  protected lineUpperRenderableSeries!: FastLineRenderableSeries;
  protected lineLowerRenderableSeries!: FastLineRenderableSeries;
  protected options!: BollingerBandsOptions;
  protected upperColor: string = '#0200A6';
  protected baseColor: string = '#A60000';
  protected bottomColor: string = '#0200A6';
  protected backgroundColor: string = '#00CBFF';
  protected data: TData;
  protected timePeriod: number = 20;
  protected precision: number = 2;
  protected standardDeviation: number = 2;
  protected source: TIGER_INDICATOR_SOURCES = TIGER_INDICATOR_SOURCES.CLOSE;
  protected points: BollingerBandsData;
  protected upperThickness = 1;
  protected baseThickness = 1;
  protected bottomThickness = 1;
  protected upperVisible = true;
  protected baseVisible = true;
  protected bottomVisible = true;
  protected backgroundVisible = true;

  private baseChart!: TWebAssemblyChart;
  lineNumber!: string;
  type: TIGER_INDICATORS_ENUM = TIGER_INDICATORS_ENUM.BOLLINGER_BANDS;
  yAxisId!: string;
  settings: TTigerChartIndicatorParameter[] = [];
  styles: TTigerChartIndicatorParameter[] = [];
  renderSeriesConfig: TTigerChartIndicatorRenderSeriesConfig[] = [];
  onChange = new Subject<null>();
  service: TalibService;
  mainLineId = '';
  isNewOnChart = false;
  visibleStyles: any = {
    upperColor: true,
    baseColor: true,
    bottomColor: true,
    backgroundColor: true,
  };

  get isVisible(): boolean {
    return (
      this.lineRenderableSeries.isVisible ||
      this.bandsRenderableSeries.isVisible ||
      this.lineUpperRenderableSeries.isVisible ||
      this.lineLowerRenderableSeries.isVisible
    );
  }

  set isVisible(visible: boolean) {
    this.lineRenderableSeries.isVisible = visible;
    this.bandsRenderableSeries.isVisible = visible;
    this.lineUpperRenderableSeries.isVisible = visible;
    this.lineLowerRenderableSeries.isVisible = visible;
  }

  get propertiesText(): string {
    return `(${this.timePeriod}, ${this.standardDeviation})`;
  }

  constructor(options: BollingerBandsOptions) {
    this.data = options.data;
    this.service = options.service;
    this.precision = options.tickSize;
    this.options = options;
    if ('upperVisible' in options) {
      this.upperVisible = !!options.upperVisible;
    }
    if ('baseVisible' in options) {
      this.baseVisible = !!options.baseVisible;
    }
    if ('bottomVisible' in options) {
      this.bottomVisible = !!options.bottomVisible;
    }
    if ('backgroundVisible' in options) {
      this.backgroundVisible = !!options.backgroundVisible;
    }
    if (options.upperColor) this.upperColor = options.upperColor;
    if (options.baseColor) this.baseColor = options.baseColor;
    if (options.bottomColor) this.bottomColor = options.bottomColor;
    if (options.backgroundColor) this.backgroundColor = options.backgroundColor;
    if (options.timePeriod) this.timePeriod = options.timePeriod;
    if (options.precision) this.precision = options.precision;
    if (options.upperThickness) this.upperThickness = options.upperThickness;
    if (options.baseThickness) this.baseThickness = options.baseThickness;
    if (options.bottomThickness) this.bottomThickness = options.bottomThickness;
    if (options.standardDeviation)
      this.standardDeviation = options.standardDeviation;

    this.mainLineId = `${this.type}-middle-bands`;
    this.points = {
      upperBand: [],
      middleBand: [],
      lowerBand: [],
    };
    this.renderSeriesConfig = [
      {
        label: 'Superior',
        id: `${this.type}-bbands-upper-line`,
        color: this.upperColor,
        propertyColor: 'upperColor',
        thickness: this.upperThickness,
        propertyThickness: 'upperThickness',
        active: this.upperVisible,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        propertyVisible: 'upperVisible',
      },
      {
        label: 'Base',
        id: this.mainLineId,
        color: this.baseColor,
        propertyColor: 'baseColor',
        thickness: this.baseThickness,
        propertyThickness: 'baseThickness',
        active: this.baseVisible,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        propertyVisible: 'baseVisible',
      },
      {
        label: 'Inferior',
        id: `${this.type}-bbands-lower-line`,
        color: this.bottomColor,
        propertyColor: 'bottomColor',
        thickness: this.bottomThickness,
        propertyThickness: 'bottomThickness',
        active: this.bottomVisible,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        propertyVisible: 'bottomVisible',
      },
      {
        label: 'Fundo',
        id: `${this.type}-background`,
        color: this.backgroundColor,
        propertyColor: 'backgroundColor',
        thickness: 1,
        dontShowTickness: true,
        active: this.backgroundVisible,
        type: TIGER_INDICATOR_PARAMETER_TYPE.COLOR,
        propertyVisible: 'backgroundVisible',
      },
    ];
    this.settings = [
      {
        label: 'Período',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property: 'timePeriod' as keyof BollingerBandsBase,
      },
      {
        label: 'Mult',
        type: TIGER_INDICATOR_PARAMETER_TYPE.NUMBER,
        property: 'standardDeviation' as keyof BollingerBandsBase,
      },
    ];
    this.styles = [
      {
        type: TIGER_INDICATOR_PARAMETER_TYPE.SELECTION,
        property: 'precision',
        label: 'Precisão',
        values: TIGER_INDICATOR_PRECISIONS,
      },
    ];
    this.lineNumber = options.lineNumber;
  }

  create(options: TTigerChartIndicatorCreateOptions): BaseRenderableSeries[] {
    this.baseChart = options.base;

    const xValues = this.data.id_point;
    this.bandsDataSeries = new XyyDataSeries(this.baseChart.wasmContext);
    let { upperBand, middleBand, lowerBand } = this.getOutput(
      this.data.id_point.length
    );
    lowerBand = this.convertZeroLogarithmic(lowerBand);
    this.bandsDataSeries.appendRange(xValues, upperBand, lowerBand);
    this.lineDataSeries = new XyDataSeries(this.baseChart.wasmContext);
    this.lineDataSeries.appendRange(xValues, middleBand);

    this.lineUpperDataSeries = new XyDataSeries(this.baseChart.wasmContext);
    this.lineUpperDataSeries.appendRange(xValues, upperBand);

    this.lineLowerDataSeries = new XyDataSeries(this.baseChart.wasmContext);
    this.lineLowerDataSeries.appendRange(xValues, lowerBand);

    const upperBandConfig = this.renderSeriesConfig.find(
      (config) => config.id == `${this.type}-upper-bands`
    );
    const middleBandConfig = this.renderSeriesConfig.find(
      (config) => config.id == this.mainLineId
    );
    const lowerBandConfig = this.renderSeriesConfig.find(
      (config) => config.id == `${this.type}-lower-bands`
    );
    const backgroundConfig = this.renderSeriesConfig.find(
      (config) => config.id == `${this.type}-background`
    );
    const upperLineBandConfig = this.renderSeriesConfig.find(
      (config) => config.id == `${this.type}-bbands-upper-line`
    );
    const lowerLineBandConfig = this.renderSeriesConfig.find(
      (config) => config.id == `${this.type}-bbands-lower-line`
    );

    this.bandsRenderableSeries = new FastBandRenderableSeries(
      this.baseChart.wasmContext,
      {
        dataSeries: this.bandsDataSeries,
        strokeThickness: 0,
        fill: `${backgroundConfig?.color}47`,
        stroke: upperBandConfig?.color,
        strokeY1: lowerBandConfig?.color,
        fillY1: `${backgroundConfig?.color}47`,
        id: `${this.type}-bbands-bands_${this.lineNumber}`,
        xAxisId: options.xAxisId,
        isVisible: backgroundConfig?.active,
      }
    );

    this.lineRenderableSeries = new FastLineRenderableSeries(
      this.baseChart.wasmContext,
      {
        dataSeries: this.lineDataSeries,
        strokeThickness: middleBandConfig?.thickness,
        stroke: middleBandConfig?.color,
        id: `${this.type}-bbands-line_${this.lineNumber}`,
        xAxisId: options.xAxisId,
        isVisible: middleBandConfig?.active,
      }
    );

    this.lineUpperRenderableSeries = new FastLineRenderableSeries(
      this.baseChart.wasmContext,
      {
        dataSeries: this.lineUpperDataSeries,
        strokeThickness: upperLineBandConfig?.thickness,
        stroke: upperLineBandConfig?.color,
        id: `${this.type}-bbands-upper-line_${this.lineNumber}`,
        xAxisId: options.xAxisId,
        isVisible: upperLineBandConfig?.active,
      }
    );

    this.lineLowerRenderableSeries = new FastLineRenderableSeries(
      this.baseChart.wasmContext,
      {
        dataSeries: this.lineLowerDataSeries,
        strokeThickness: lowerLineBandConfig?.thickness,
        stroke: lowerLineBandConfig?.color,
        id: `${this.type}-bbands-lower-line_${this.lineNumber}`,
        xAxisId: options.xAxisId,
        isVisible: lowerLineBandConfig?.active,
      }
    );
    return [
      this.bandsRenderableSeries,
      this.lineRenderableSeries,
      this.lineUpperRenderableSeries,
      this.lineLowerRenderableSeries,
    ];
  }

  updatePoints(): void {
    //do nothing.
  }

  append(xValue: number, data: TUpdateData, fullData: TData) {
    this.data = fullData;

    this.updatePoints();
    this.bandsDataSeries.append(
      xValue,
      this.points['upperBand'][
        this.points['upperBand'].length - this.timePeriod
      ],
      this.points['lowerBand'][
        this.points['lowerBand'].length - this.timePeriod
      ]
    );
    this.lineDataSeries.append(
      xValue,
      this.points['middleBand'][
        this.points['middleBand'].length - this.timePeriod
      ]
    );
    this.lineUpperDataSeries.append(
      xValue,
      this.points['upperBand'][
        this.points['upperBand'].length - this.timePeriod
      ]
    );
    this.lineLowerDataSeries.append(
      xValue,
      this.points['lowerBand'][
        this.points['lowerBand'].length - this.timePeriod
      ]
    );
  }

  insertRange(xValues: number[], fullData: TData): void {
    const offset = new Array(this.timePeriod - 1).fill(NaN);

    this.data = fullData;
    this.updatePoints();

    const s = this.baseChart.sciChartSurface.suspendUpdates();
    const arrayLength =
      xValues.length <= this.timePeriod
        ? xValues.length
        : xValues.length - this.timePeriod + 1;
    try {
      if (xValues.length <= this.timePeriod) {
        this.bandsDataSeries.insertRange(
          0,
          xValues,
          [...offset.slice(0, arrayLength)],
          [...offset.slice(0, arrayLength)]
        );
        this.lineDataSeries.insertRange(0, xValues, [
          ...offset.slice(0, arrayLength),
        ]);
        this.lineUpperDataSeries.insertRange(0, xValues, [
          ...offset.slice(0, arrayLength),
        ]);
        this.lineLowerDataSeries.insertRange(0, xValues, [
          ...offset.slice(0, arrayLength),
        ]);
      } else {
        this.bandsDataSeries.insertRange(
          0,
          xValues,
          [...offset, ...this.points.upperBand.slice(0, arrayLength)],
          [...offset, ...this.points.lowerBand.slice(0, arrayLength)]
        );
        this.lineDataSeries.insertRange(0, xValues, [
          ...offset,
          ...this.points.middleBand.slice(0, arrayLength),
        ]);
        this.lineUpperDataSeries.insertRange(0, xValues, [
          ...offset,
          ...this.points.upperBand.slice(0, arrayLength),
        ]);
        this.lineLowerDataSeries.insertRange(0, xValues, [
          ...offset,
          ...this.points.upperBand.slice(0, arrayLength),
        ]);
        for (let index = xValues.length - 1; index < this.timePeriod; index++) {
          this.bandsDataSeries.update(index, NaN, NaN);
          this.lineDataSeries.update(index, NaN);
          this.lineUpperDataSeries.update(index, NaN);
          this.lineLowerDataSeries.update(index, NaN);
        }
      }

      const indexInitialValue =
        xValues.length <= this.timePeriod
          ? this.timePeriod - 1
          : xValues.length - this.timePeriod + 1;
      for (
        let index = indexInitialValue;
        index < this.points.middleBand.length - this.timePeriod;
        index++
      ) {
        this.bandsDataSeries.update(
          index,
          this.points.upperBand[index],
          this.points.lowerBand[index]
        );
        this.lineDataSeries.update(index, this.points.middleBand[index]);
        this.lineUpperDataSeries.update(index, this.points.upperBand[index]);
        this.lineLowerDataSeries.update(index, this.points.lowerBand[index]);
      }
    } finally {
      s.resume();
      this.updateSettings();
    }
  }

  changeVisibility(): void {
    const visible = !this.isVisible;
    if (!visible) {
      this.lineRenderableSeries.isVisible = false;
      this.bandsRenderableSeries.isVisible = false;
      this.lineUpperRenderableSeries.isVisible = false;
      this.lineLowerRenderableSeries.isVisible = false;
      return;
    }
    this.lineRenderableSeries.isVisible = this.visibleStyles.baseColor;
    this.bandsRenderableSeries.isVisible = this.visibleStyles.backgroundColor;
    this.lineUpperRenderableSeries.isVisible = this.visibleStyles.upperColor;
    this.lineLowerRenderableSeries.isVisible = this.visibleStyles.bottomColor;
  }

  update(index: number, data: TUpdateData, fullData: TData) {
    const count = this.bandsDataSeries.count();
    if (index > count) {
      return;
    }
    this.data = fullData;
    this.updatePoints();
    let { upperBand, middleBand, lowerBand } = this.getOutput(
      this.data.id_point.length
    );
    lowerBand = this.convertZeroLogarithmic(lowerBand);
    this.bandsDataSeries.update(index, upperBand[index], lowerBand[index]);
    this.lineDataSeries.update(index, middleBand[index]);
    this.lineUpperDataSeries.update(index, upperBand[index]);
    this.lineLowerDataSeries.update(index, lowerBand[index]);
  }

  updateSettings(): void {
    const s = this.baseChart.sciChartSurface.suspendUpdates();
    const dataSeriesCount = this.bandsDataSeries.count();
    /*const upperValues = [
      //...offset,
      ...this.points.upperBand.slice(
        0,
        this.data.id_point.length
      ),
    ];*/
    let { upperBand, middleBand, lowerBand } = this.getOutput(
      this.data.id_point.length
    );
    lowerBand = this.convertZeroLogarithmic(lowerBand);

    /*const middleValues = [
      //...offset,
      ...this.points.middleBand.slice(
        0,
        this.data.id_point.length
      ),
    ];*/
    /*const lowerValues = [
      //...offset,
      ...this.points.lowerBand.slice(
        0,
        this.data.id_point.length
      ),
    ];*/
    /**
     * o offset padrão(timePeriod) vai como NaN no inicio dos arrays
     *
     */

    if (dataSeriesCount > lowerBand.length) {
      this.bandsDataSeries.removeRange(
        lowerBand.length - 1,
        dataSeriesCount - lowerBand.length
      );
      this.lineDataSeries.removeRange(
        lowerBand.length - 1,
        dataSeriesCount - lowerBand.length
      );
      this.lineUpperDataSeries.removeRange(
        lowerBand.length - 1,
        dataSeriesCount - lowerBand.length
      );
      this.lineLowerDataSeries.removeRange(
        lowerBand.length - 1,
        dataSeriesCount - lowerBand.length
      );
    }

    try {
      lowerBand.forEach((lowerPointValue, index) => {
        this.bandsDataSeries.update(index, upperBand[index], lowerPointValue);
        this.lineDataSeries.update(index, middleBand[index]);
        this.lineUpperDataSeries.update(index, upperBand[index]);
        this.lineLowerDataSeries.update(index, lowerBand[index]);
      });
    } finally {
      s.resume();
    }
    this.onChange.next(null);
  }

  updateStyles(
    baseChart: TWebAssemblyChart,
    config: TTigerChartIndicatorRenderSeriesConfig
  ): void {
    const bandsRenderSeries =
      baseChart.sciChartSurface.renderableSeries.getById(
        `${this.type}-bbands-bands_${this.lineNumber}`
      ) as FastBandRenderableSeries;
    switch (config.id) {
      case this.mainLineId: {
        this.lineRenderableSeries.stroke = config.color;
        this.lineRenderableSeries.strokeThickness = config.thickness;
        this.lineRenderableSeries.isVisible = config.active;
        const lineRenderSeries =
          baseChart.sciChartSurface.renderableSeries.getById(
            `${this.type}-bbands-line_${this.lineNumber}`
          );
        lineRenderSeries.stroke = config.color;
        lineRenderSeries.strokeThickness = config.thickness;
        lineRenderSeries.isVisible = config.active;
        this.visibleStyles[config.propertyColor!] = config.active;
        break;
      }

      case `${this.type}-bbands-upper-line`: {
        this.lineUpperRenderableSeries.stroke = config.color;
        this.lineUpperRenderableSeries.strokeThickness = config.thickness;
        this.lineUpperRenderableSeries.isVisible = config.active;
        const lineUpperRenderSeries =
          baseChart.sciChartSurface.renderableSeries.getById(
            `${this.type}-bbands-upper-line_${this.lineNumber}`
          );
        lineUpperRenderSeries.stroke = config.color;
        lineUpperRenderSeries.strokeThickness = config.thickness;
        lineUpperRenderSeries.isVisible = config.active;
        this.visibleStyles[config.propertyColor!] = config.active;
        break;
      }
      case `${this.type}-bbands-lower-line`: {
        this.lineLowerRenderableSeries.stroke = config.color;
        this.lineLowerRenderableSeries.strokeThickness = config.thickness;
        this.lineLowerRenderableSeries.isVisible = config.active;
        const lineLowerRenderSeries =
          baseChart.sciChartSurface.renderableSeries.getById(
            `${this.type}-bbands-lower-line_${this.lineNumber}`
          );
        lineLowerRenderSeries.stroke = config.color;
        lineLowerRenderSeries.strokeThickness = config.thickness;
        lineLowerRenderSeries.isVisible = config.active;
        this.visibleStyles[config.propertyColor!] = config.active;
        break;
      }
      case `${this.type}-background`: {
        this.bandsRenderableSeries.fill = `${config.color}47`;
        this.bandsRenderableSeries.fillY1 = `${config.color}47`;
        this.bandsRenderableSeries.isVisible = config.active;
        bandsRenderSeries.fill = `${config.color}47`;
        bandsRenderSeries.fillY1 = `${config.color}47`;
        bandsRenderSeries.isVisible = config.active;
        this.visibleStyles[config.propertyColor!] = config.active;
        break;
      }
      default:
        break;
    }
    this.onChange.next(null);
  }

  delete() {
    this.baseChart.sciChartSurface.renderableSeries.remove(
      this.lineRenderableSeries
    );
    this.baseChart.sciChartSurface.renderableSeries.remove(
      this.bandsRenderableSeries
    );
    this.baseChart.sciChartSurface.renderableSeries.remove(
      this.lineUpperRenderableSeries
    );
    this.baseChart.sciChartSurface.renderableSeries.remove(
      this.lineLowerRenderableSeries
    );
  }

  setValue(property: keyof this, value: any) {
    const prop = this[property];
    if (typeof this[property] == 'number') {
      this[property] = +value as unknown as typeof prop;
    } else {
      this[property] = value;
    }
    this.updateSettings();
  }

  getValuesByIndex(index?: number): TTooltipIndicatorValue[] {
    const precision = this.precision;
    const upperValues = this.bandsDataSeries.getNativeYValues();
    const upperValue = {
      value: upperValues.get(index || upperValues.size() - 1),
      precision,
      color: this.renderSeriesConfig[0].color,
    };
    const lowerValues = this.bandsDataSeries.getNativeY1Values();
    const lowerValue = {
      value: lowerValues.get(index || lowerValues.size() - 1),
      precision,
      color: this.renderSeriesConfig[2].color,
    };
    const middleValues = this.lineDataSeries.getNativeYValues();
    const middleValue = {
      value: middleValues.get(index || middleValues.size() - 1),
      precision,
      color: this.renderSeriesConfig[1].color,
    };
    return [upperValue, middleValue, lowerValue];
  }

  resetConfiguration(): void {
    setDefaultConfiguration(
      this,
      this.baseChart,
      this.options,
      this.settings,
      this.renderSeriesConfig,
      this.styles,
      this.type
    );
  }

  private sliceIndex(end: number) {
    let sliceStart = 1,
      sliceEnd = end + 1;
    return { sliceStart, sliceEnd };
  }

  private getOutput(length: number) {
    const { sliceStart, sliceEnd } = this.sliceIndex(length);
    return {
      upperBand: this.points.upperBand.slice(sliceStart, sliceEnd),
      middleBand: this.points.middleBand.slice(sliceStart, sliceEnd),
      lowerBand: this.points.lowerBand.slice(sliceStart, sliceEnd),
    };
  }

  public convertZeroLogarithmic(lowerBand: any) {
    const yAxes = this.baseChart.sciChartSurface.yAxes.asArray();
    let isLogarithmic = false;
    for (let i = 0; i < yAxes.length; i++) {
      const yAxis = yAxes[i];
      if (yAxis instanceof LogarithmicAxis) {
        isLogarithmic = true;
        break;
      }
    }

    if (isLogarithmic) {
      for (let i = 0; i < lowerBand.length; i++) {
        if (lowerBand[i] < 0) lowerBand[i] = 0.01;
      }
    }

    return lowerBand;
  }
}
