import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import { FlaCheckModule } from '@shared/rocket-components/components';
import { RocketButtonModule } from '@shared/rocket-components/components/button/button.module';
import { FlaIconModule } from '@shared/rocket-components/components/icon/icon.module';
import { RocketModalRef } from '@shared/rocket-components/components/modal/modal-parts/modal-ref.component';
import { RocketModalModule } from '@shared/rocket-components/components/modal/modal.module';
import { RocketModalService } from '@shared/rocket-components/components/modal/service/modal.service';
import { RTInputCountModule } from '@shared/rocket-components/input-count/input-count.module';
import { TooltipsModule } from '@shared/rocket-components/tooltips/tooltips.module';
import { Subject, takeUntil } from 'rxjs';
import {
  FIELDS,
  TOOLTIP_BOCK_EDIT,
  TOOLTIP_HEADER,
} from './constants/modal-risk-management.constant';
import { LoadingModule } from '@shared/components/loading/loading.module';
import {
  IGetRisksPersonal,
  ISendRisksPersonal,
} from '@shared/services/api/trademap/v1/interface/risks-personal.interface';
import { RiskManagementService } from './service/risk-management.service';
import { ReplaceTextHtmlDirective } from '@shared/rocket-components/directives/replace-text-html/replace-text-html.directive';

@Component({
  selector: 'app-modal-risk-management',
  templateUrl: './modal-risk-management.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RocketModalModule,
    RocketButtonModule,
    FlaIconModule,
    FlaCheckModule,
    RTInputCountModule,
    TooltipsModule,
    LoadingModule,
    ReplaceTextHtmlDirective,
  ],
  providers: [RocketModalService],
  styles: [
    `
      :host(app-modal-risk-management) {
        ::ng-deep app-input-count {
          .mb-5 {
            margin-bottom: 0 !important;
          }
          .custom-input-count {
            width: 150px;
            min-width: 150px;
            margin-left: 15px;
          }
          .prefix {
            font-size: 16px !important;
          }
          .remove-arrows .suffix div {
            visibility: hidden !important;
          }
        }
        .message-error {
          bottom: -8px;
        }
        ::ng-deep.invalid {
          .group-suffix-prefix.form-control,
          .suffix,
          .prefix {
            border-color: var(--vm-feedback-error) !important;
          }
        }
      }
    `,
  ],
})
export class ModalRiskManagementComponent
  extends RocketModalRef
  implements OnInit
{
  public isLockChange = false;
  public iconLock = 'lock_open';
  public standaloneClose!: (params?: any) => void;
  public form!: FormGroup;
  public fields = FIELDS;
  public isConfirmLock = false;
  public loading = true;
  public isDayTrade = false;
  private onDestory$ = new Subject<void>();
  private idBroker!: any;
  private isUpdate = true;
  public modoLabel!: string;

  get tooltipHeader(): string {
    return this.setModoInText(TOOLTIP_HEADER);
  }

  get tooltipBockEdit(): string {
    return this.setModoInText(TOOLTIP_BOCK_EDIT);
  }

  constructor(
    private rocketModalService: RocketModalService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private riskManagementService: RiskManagementService
  ) {
    super(rocketModalService);
  }

  ngOnInit(): void {
    this.getRisksPersonal();
    this.cdr.detectChanges();
  }

  private getRisksPersonal(): void {
    const data: {
      risksPersonal: IGetRisksPersonal;
      isUpdate: boolean;
      idBroker: number;
      isDayTrade: boolean;
      modo: string;
    } = this.riskManagementService.changeRisckPersonalByBroker();
    this.modoLabel = data.modo;
    this.idBroker = data.idBroker;
    this.isUpdate = data.isUpdate;
    this.isDayTrade = data.isDayTrade;
    this.isLockChange = data.risksPersonal?.blockEdit || false;
    this.iconLock = this.isLockChange ? 'lock' : 'lock_open';
    this.createdForm(data.risksPersonal);
    this.loading = false;
    this.cdr.detectChanges();
  }

  private createdForm(params: any): void {
    this.form = this.formBuilder.group({
      qttyPreventionAvgPrice: [!!params.qttyPreventionAvgPrice],
      qttyPreventionAvgPriceValue: [
        {
          value: params.qttyPreventionAvgPrice || 5,
          disabled: !params.qttyPreventionAvgPrice,
        },
        {
          validators: [this.numberValidator],
        },
      ],

      vlMaxLoss: [!!params.vlMaxLoss],
      vlMaxLossValue: [
        { value: params.vlMaxLoss || 1000, disabled: !params.vlMaxLoss },
        {
          validators: [this.moneyValidator],
        },
      ],

      vlMaxGain: [!!params.vlMaxGain],
      vlMaxGainValue: [
        { value: params.vlMaxGain || 3000, disabled: !params.vlMaxGain },
        {
          validators: [this.moneyValidator],
        },
      ],

      qttyConsecutiveLoss: [!!params.qttyConsecutiveLoss],
      qttyConsecutiveLossValue: [
        {
          value: params.qttyConsecutiveLoss || 3,
          disabled: !params.qttyConsecutiveLoss,
        },
        {
          validators: [this.numberValidator],
        },
      ],

      qttyMaxDayOperation: [!!params.qttyMaxDayOperation],
      qttyMaxDayOperationValue: [
        {
          value: params.qttyMaxDayOperation || 10,
          disabled: !params.qttyMaxDayOperation,
        },
        {
          validators: [this.numberValidator],
        },
      ],

      hrBlockOperation: [!!params.hrBlockOperation],
      hrBlockOperationValue: [
        {
          value: params.hrBlockOperation || '1645',
          disabled: !params.hrBlockOperation,
        },
        { validators: [this.timeValidator] },
      ],
    });
    if (this.isLockChange) {
      this.form.disable();
    } else {
      this.form
        .get('vlMaxLossValue')
        ?.valueChanges.pipe(takeUntil(this.onDestory$))
        .subscribe((value: string | number) => {
          if (value == '000') {
            this.form.get('vlMaxLossValue')?.setValue(1);
          }
        });
      this.form
        .get('vlMaxGainValue')
        ?.valueChanges.pipe(takeUntil(this.onDestory$))
        .subscribe((value: string | number) => {
          if (value == '000') {
            this.form.get('vlMaxGainValue')?.setValue(1);
          }
        });
    }
    this.cdr.detectChanges();
  }

  public alertConfirmLock(): void {
    this.isConfirmLock = !this.isConfirmLock;
    if (!this.isConfirmLock) {
      this.form.enable();
      Object.keys(this.form.value).forEach((field: string) => {
        if (
          typeof this.form.value[field] === 'boolean' &&
          !this.form.value[field]
        ) {
          this.form.get(`${field}Value`)?.disable();
        }
      });
    } else {
      this.form.disable();
    }
  }

  public lockChanges(): void {
    this.isConfirmLock = !this.isConfirmLock;
    this.isLockChange = !this.isLockChange;
    this.iconLock = this.isLockChange ? 'lock' : 'lock_open';
    this.form.disable();
    this.save();
    this.cdr.detectChanges();
  }

  public save(): void {
    const params: ISendRisksPersonal = {
      id_broker: this.idBroker,
      vl_max_loss: this.getValueByField('vlMaxLoss'),
      vl_max_gain: this.getValueByField('vlMaxGain'),
      qtty_consecutive_loss: this.getValueByField('qttyConsecutiveLoss'),
      qtty_max_day_operation: this.getValueByField('qttyMaxDayOperation'),
      hr_block_operation: this.getValueByField('hrBlockOperation'),
      qtty_prevention_avg_price: this.getValueByField('qttyPreventionAvgPrice'),
      vl_daily_loss_reset_position: null,
      vl_daily_profit_reset_position: null,
      block_edit: this.isLockChange,
      id_exchange: 1,
      risk_type: this.isDayTrade ? 'DAYTRADE' : 'SWING',
    };
    this.riskManagementService.saveOrUpdate(this.isUpdate, params);
    this.btnClose();
  }

  private getValueByField(field: string): any {
    return this.form.get(field)?.value
      ? `${this.form.get(`${field}Value`)?.value}`.replace(',', '.')
      : null;
  }

  public btnClose(): void {
    this.onDestory$.next();
    this.onDestory$.complete();
    this.standaloneClose();
  }

  public selectCheckBox(field: string): void {
    if (this.isLockChange || this.isConfirmLock) return;
    this.form.get(field)?.setValue(!this.form.get(field)?.value);
    this.disabledOrEnabledInput(field);
  }

  public disabledOrEnabledInput(field: string): void {
    if (!field) return;
    if (this.form.get(field)?.value) {
      this.form.get(`${field}Value`)?.enable();
    } else {
      this.form.get(`${field}Value`)?.disable();
    }
    this.cdr.detectChanges();
  }

  public checkOptionHasSelected(): boolean {
    return !Object.keys(this.form.value).find(
      (item: any) => this.form.value[item]
    );
  }

  private setModoInText(text: string): string {
    return text.replaceAll('{MODO}', this.modoLabel);
  }

  public getErrors(field: string): string {
    const error: any = this.form.get(field)?.errors;
    if (!error) return '';
    if (error['error']) return error['error'];
    return '';
  }

  public getErrorsCss(field: string, css: string): string {
    const error: any = this.form.get(field)?.errors;
    if (!error) return css;
    if (error['error']) return `${css} invalid`;
    return css;
  }

  private numberValidator = (formGroup: FormGroup): ValidationErrors | null => {
    let message = formGroup.value ? '' : 'Campo obrigatório.';
    !message &&
      (message =
        !new RegExp('^[0-9]+$').exec(formGroup.value) || +formGroup.value <= 0
          ? 'Valor inválido'
          : '');
    return message
      ? {
          error: message,
        }
      : null;
  };

  private moneyValidator = (formGroup: FormGroup): ValidationErrors | null => {
    let message = formGroup.value ? '' : 'Campo obrigatório.';
    !message &&
      (message =
        !new RegExp('^[0-9]+$').exec(formGroup.value) || +formGroup.value <= 0
          ? 'Valor inválido'
          : '');
    return message
      ? {
          error: message,
        }
      : null;
  };

  private timeValidator = (formGroup: FormGroup): ValidationErrors | null => {
    let message = formGroup.value ? '' : 'Campo obrigatório.';
    if (!message) {
      message =
        !formGroup.value ||
        formGroup.value.length !== 4 ||
        +formGroup.value > 2359 ||
        +`${formGroup.value[2]}${formGroup.value[3]}` > 59
          ? 'O campo de valor varia de 00:00 a 23:59.'
          : '';
    }
    return message
      ? {
          error: message,
        }
      : null;
  };
}
